import { Flex } from '@rebass/grid';
import { isEmpty, range } from 'lodash';
import React, { useState } from 'react';
import { Button, ErrorContainer, MioSelect } from '../../../components';
import { platformNames } from '../../../utils';
import { CampaignModal, WeekdayTiles } from './PlanCampaign.styles';

const DayTimePicker = ({
  isOpen,
  selectedPlatform,
  setSelectedPlatform,
  setOpen,
  handleSave,
}) => {
  const [selectedWeekDay, setSelectedWeekDay] = useState('');
  const [selectedHour, setSelectedHour] = useState('1');
  const [selectedMin, setSelectedMin] = useState('0');
  const [selectedTimePeriod, setSelectedTimePeriod] = useState('AM');
  const [validationError, setValidationError] = useState('');
  const weekdays = [
    {
      label: 'Sun',
      value: 'Sunday',
    },
    {
      label: 'Mon',
      value: 'Monday',
    },
    {
      label: 'Tue',
      value: 'Tuesday',
    },
    {
      label: 'Wed',
      value: 'Wednesday',
    },
    {
      label: 'Thu',
      value: 'Thursday',
    },
    {
      label: 'Fri',
      value: 'Friday',
    },
    {
      label: 'Sat',
      value: 'Saturday',
    },
  ];

  const handleModalClose = () => {
    setOpen(false);
    setSelectedPlatform('');
    setSelectedWeekDay('');
    setSelectedHour('1');
    setSelectedMin('0');
    setSelectedTimePeriod('AM');
    setValidationError('');
  };

  const handleSubmit = () => {
    if (isEmpty(selectedWeekDay)) {
      setValidationError('Please Select a Day');
    } else {
      handleSave({
        selectedWeekDay,
        selectedHour,
        selectedMin,
        selectedTimePeriod,
      });
      handleModalClose();
    }
  };

  return (
    <CampaignModal
      isOpen={isOpen}
      shouldCloseOnOverlayClick
      onRequestClose={handleModalClose}
      padding="20px"
    >
      <Flex flexDirection="column" width="100%">
        <Flex justifyContent="center" fontSize="18px" width="100%">
          Select Day/Time for {platformNames[selectedPlatform]}
        </Flex>
        <Flex width="100%" paddingTop="15px" alignItems="center">
          <Flex color="#000000" fontSize="14px" alignItems="center">
            Select Day :
          </Flex>
          <Flex paddingLeft="10px" alignItems="center">
            {weekdays.map((item, index) => (
              <WeekdayTiles
                bgColor={selectedWeekDay.value === item.value ? '#6250ED' : ''}
                onClick={() => setSelectedWeekDay(item)}
                color={selectedWeekDay.value === item.value ? '#fff' : ''}
              >
                {item.label}
              </WeekdayTiles>
            ))}
          </Flex>
        </Flex>
        <Flex width="100%" paddingTop="15px" alignItems="center">
          <Flex fontSize="14px" color="#000000" pr="10px">
            Select Hour :
          </Flex>
          <Flex>
            <MioSelect
              background="#fff"
              borderRadius="4px"
              value={selectedHour}
              onChange={(e) => setSelectedHour(e.target.value)}
            >
              {range(1, 13).map((item, index) => (
                <option value={item}>{item}</option>
              ))}
            </MioSelect>
          </Flex>
          <Flex fontSize="14px" color="#000000" padding="0px 10px">
            Select Minutes :
          </Flex>
          <Flex>
            <MioSelect
              background="#fff"
              borderRadius="4px"
              value={selectedMin}
              onChange={(e) => setSelectedMin(e.target.value)}
            >
              {range(0, 61).map((item, index) => (
                <option
                  value={item.toLocaleString('en-US', {
                    minimumIntegerDigits: 2,
                    useGrouping: false,
                  })}
                >
                  {item.toLocaleString('en-US', {
                    minimumIntegerDigits: 2,
                    useGrouping: false,
                  })}
                </option>
              ))}
            </MioSelect>
          </Flex>
          <Flex pl="10px">
            <MioSelect
              background="#fff"
              borderRadius="4px"
              value={selectedTimePeriod}
              onChange={(e) => setSelectedTimePeriod(e.target.value)}
            >
              <option value="AM">AM</option>
              <option value="PM">PM</option>
            </MioSelect>
          </Flex>
        </Flex>
        <Flex justifyContent="center" width="100%">
          <Button onClick={handleSubmit} margin="20px 10px 0px">
            Save
          </Button>
          <Button
            onClick={handleModalClose}
            bgColor="#fff"
            color="#6250ED"
            margin="20px 0px 0px"
          >
            Cancel
          </Button>
        </Flex>
        {!isEmpty(validationError) && (
          <ErrorContainer align="center">{validationError}</ErrorContainer>
        )}
      </Flex>
    </CampaignModal>
  );
};

export default DayTimePicker;
