export const USER_ROLE = {
  admin: 'admin',
  general: 'general',
};

export const ROLE_TYPES = {
  ADVISOR_ENTERPRISE: 'ADVISOR_ENTERPRISE',
  ADVISOR_PREMIUM: 'ADVISOR_PREMIUM',
  ADVISOR_FREE: 'ADVISOR_FREE',
  ADVISOR_ASSET_MANAGER: 'ADVISOR_ASSET_MANAGER',
  ADMIN_ENTERPRISE: 'ADMIN_ENTERPRISE',
  ADMIN_COMPLIANCE: 'ADMIN_COMPLIANCE',
  ADMIN_ASSET_MANAGER: 'ADMIN_ASSET_MANAGER',
};

export const TAB_NAMES = {
  APPROVE_TO_SHARE: 'APPROVE_TO_SHARE',
  EXPLORE: 'EXPLORE',
  TRENDING: 'TRENDING',
  RECENT: 'RECENT',
  SCHEDULED: 'SCHEDULED',
  MY_MIO: 'MY_MIO',
  SEARCH: 'SEARCH',
  INVESTMENTS: 'INVESTMENTS',
};

export const ADVISOR_ROLE_BASED_TABS = {
  [ROLE_TYPES.ADVISOR_ENTERPRISE]: {
    [TAB_NAMES.APPROVE_TO_SHARE]: 0,
    [TAB_NAMES.EXPLORE]: 1,
    [TAB_NAMES.TRENDING]: 2,
    [TAB_NAMES.RECENT]: 3,
    [TAB_NAMES.SCHEDULED]: 4,
    [TAB_NAMES.MY_MIO]: 5,
    [TAB_NAMES.SEARCH]: 6,
    [TAB_NAMES.INVESTMENTS]: 7,
  },
  [ROLE_TYPES.ADVISOR_PREMIUM]: {
    [TAB_NAMES.EXPLORE]: 1,
    [TAB_NAMES.TRENDING]: 2,
    [TAB_NAMES.RECENT]: 3,
    [TAB_NAMES.SCHEDULED]: 4,
    [TAB_NAMES.MY_MIO]: 5,
    [TAB_NAMES.SEARCH]: 6,
    [TAB_NAMES.INVESTMENTS]: 7,
  },
  [ROLE_TYPES.ADVISOR_FREE]: {
    [TAB_NAMES.EXPLORE]: 1,
    [TAB_NAMES.TRENDING]: 2,
    [TAB_NAMES.RECENT]: 3,
    [TAB_NAMES.SCHEDULED]: 4,
    [TAB_NAMES.MY_MIO]: 5,
    [TAB_NAMES.SEARCH]: 6,
    [TAB_NAMES.INVESTMENTS]: 7,
  },
};

export const subscriptionScreenContent = {
  impact: {
    title: 'Impact',
    descriptionLine1: 'Get audience engagement data that will help you sharpen',
    descriptionLine2: 'your thought leadership',
    content: [
      {
        imgUrl: '/assets/images/engmnt.png',
        title: 'Track Engagement',
        description:
          'Easy-to-use feedback data tracks audience engagement and provides added insight',
      },
      {
        imgUrl: '/assets/images/download-report.png',
        title: 'Download Reports in a single click',
        description:
          'Create automatically updated reports in a single click and share them as PDFs.',
      },
    ],
  },
  insightScore: {
    title: 'My Clout',
    descriptionLine1:
      'Find your online social influence via the "My Clout", ',
    descriptionLine2: 'which was a numerical value between 1 and 100.',
    content: [
      {
        imgUrl: '/assets/images/insight1.png',
        description:
          'Learn how many people you are reaching, which topics and publishers content is being appreciated by your network.',
      },
      {
        imgUrl: '/assets/images/insight2.png',
        description:
          "In determining the user score, MIO measures the size of a user's network and correlated the content created to measure how other users interact with that content.",
      },
    ],
  },
  portfolio: {
    title: 'Investments',
    descriptionLine1: 'Get insights tailored to your portfolio',
    content: [
      {
        imgUrl: '/assets/images/sync-invest.png',
        title: 'Sync Your Investments',
        description:
          'Choose a portfolio from our model marketplace. Upload your own portfolio or just add a fund ticker!',
      },
      {
        imgUrl: '/assets/images/result.png',
        title: 'Get the results',
        description: 'Content curated based on your portfolio.',
      },
    ],
  },
};

export const Article = {
  APPROVE: 'approve',
  REJECT: 'reject',
};

export const logo = {
  '01':'/assets/images/clout-logos/clout-logo-new.png',
  '02':'/assets/images/clout-logos/screen/clout-logos-02.png',
  '03':'/assets/images/clout-logos/screen/clout-logos-03.png',
  '04':'/assets/images/clout-logos/screen/clout-logos-04.png',
  '05':'/assets/images/clout-logos/screen/clout-logos-05.png',
  '06':'/assets/images/clout-logos/screen/clout-logos-06.png',
  '07':'/assets/images/clout-logos/screen/clout-logos-07.png',
  '08':'/assets/images/clout-logos/screen/clout-logos-08.png',
  '09':'/assets/images/clout-logos/screen/clout-logos-09.png',
  '10':'/assets/images/clout-logos/screen/clout-logos-10.png',
  '11':'/assets/images/clout-logos/screen/clout-logos-11.png',
  '12':'/assets/images/clout-logos/screen/clout-logos-12.png',
  navbar:'/assets/images/clout-logos/clout-logo-new.png',
  newNavbar: '/assets/images/TIFIN-Clout-Logo-Color.png',
}

export const company = {
  name: 'Clout'
}

export const themesData = {
  white: '#ffffff',
  purpleishBlue: '#6351ed',
  charcoalGrey: '#49484a',
  greyWhite: '#ececec',
  lightGrey: '#eeeeee',
  article:{
    background: '#ececec'
  },
  purpleyGrey: '#969198',
  teal: '#7dc5d0', 
  black: '#000000'
}

export const salesforceSettingsMapper = {
  'Content Viewed': "sync_sf_content_viewed_event",
  'Clout score changed': "sync_sf_clout_score_changed_event",
  'Email Unsubscribed': "sync_sf_email_unsubscribed_event",
  'Email Sent': "sync_sf_email_sent_event",
  'Email Clicked': "sync_sf_email_clicked_event",
  'Email Opened': "sync_sf_email_opened_event",
  'Lead Generated': "sync_sf_leads",
  'Webinar Registered' : "sync_sf_webinar_registered_event",
  'Webinar Attended' : "sync_sf_webinar_attended_event"
}

export const salesforceTogglesMapper = {
  'Enabled': 'enabled',
  'Re-sync': 'resync',
  'Skip duplicates': 'skip_duplicates'
}

export const oktaConfig = {
  issuer: process.env.REACT_APP_OKTA_ISSUER,
  clientId: process.env.REACT_APP_OKTA_CLIENT_ID,
  redirectUri: window.location.origin + '/login/callback',
  tokenManager: {
    storage: 'sessionStorage'
  }
}

export const nurtureFlag = window.sessionStorage.getItem('redirect_type');