/* eslint-disable react-hooks/exhaustive-deps */
import { get, isEmpty } from 'lodash';
import React, { useState, useEffect } from 'react';
import {
  getContactList,
  getContentLibrary,
  getPremiumAdvisorChannelId,
  getPopularTheme,
  getTrendingTopics,
  getBookmarkedArticles,
  getExploreArticles,
} from '../../services/dashboard';
import CreateCampaign from './CreateCampaign/CreateCampaign';
import DripCampaignDashboard from './DripCampaignDashboard/DripCampaignDashboard';
import { connect } from 'react-redux';
import { actions as campaignActions } from '../../redux/modules/campaign.module';
import { actions as campaignDetailsActions } from '../../redux/modules/campaignDetails.module';
import { getChannelId, getRoleType } from '../../redux/selector';
import { ROLE_TYPES, getMediaUrl } from '../../utils';
import {
  getCampaignDetails,
  getSelectedArticles,
} from '../../services/campaign/index';
import { getSourceNwId } from '../../components/ShareContentContainerV2/functions';

const DripCampaignContainer = ({
  socialNetworkData,
  selectedThemes,
  themes,
  campaignSetState,
  campaignCreateFlow,
  setObjectivesTabData,
  campaignRequest,
  campaignsData,
  campaignScheduleTimings,
  campaignSelectedArticles,
  campaignSelectedWeek,
  campaignSelectedDays,
  campaignArticles,
  campaignFetching,
  campaignId,
  channelId,
  setTopicsTabData,
  isPremiumAdvisor,
  contentWeeks,
  planCampaignData,
  publishedContent,
  campaignDetailsData,
  campaignBucketData,
  campaignDetailsResponse
}) => {
  const [createFlow, setCreateFlow] = useState(false);
  const [createTabs, setCreateTabs] = useState(0);
  const [contactGroups, setContactGroups] = useState([]);
  const [height, setHeight] = useState('0px');
  const [trendingTopics, setTrendingTopics] = useState([]);
  const [mostSubscribedTopics, setMostSubscribedTopics] = useState([]);
  const [suggestedArticles, setSuggestedArticles] = useState([]);
  const [createScreenIndex, setCreateScreenIndex] = useState(0);
  const [editLoader, toggleEditLoader] = useState(false);
  const [suggestedArticlesLoader, toggleSuggestedArticlesLoader] = useState(false);

  useEffect(() => {
    if (isEmpty(trendingTopics)) {
      getTrendingTopics()
        .then((response) => {
          let trendingTopics = get(response, 'result.data.topics', []);
          if (trendingTopics.length > 0 && !isEmpty(themes))
            trendingTopics = trendingTopics.map((topic) =>
              themes.find((ele) => ele.id === topic.id)
            );
          setTrendingTopics(trendingTopics);
        })
        .catch((err) => console.log(err));
    }

   
  }, []);

  useEffect(() => {
    if (isEmpty(mostSubscribedTopics)) {
      getPopularTheme()
        .then((res) => {
          setMostSubscribedTopics(get(res, 'result.data', []));
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, []);

  useEffect(() => {
    getContactList()
      .then((res) => {
        const data = get(res, 'result.data', []).filter(
          (c) =>
            ![
              'GMAIL',
              'HUBSPOT',
              'OUTLOOK',
              'WEALTHBOX',
              'SALESFORCE',
              'REDTAIL',
            ].includes(get(c, 'name')) && get(c, 'contacts.count', 0) > 0
        );
        setContactGroups(data);
      })
      .catch((err) => {
        setContactGroups([]);
      });
    const windowBodyClassList = document.body.classList;
    if (!windowBodyClassList.contains('campaign')) {
      windowBodyClassList.add('campaign');
    }

    campaignRequest();
    setHeight(
      `${
        window.innerHeight -
        (document.getElementById('header-div').offsetHeight + 14)
      }px`
    );
    return () => {
      windowBodyClassList.remove('campaign');
    };
  }, []);

  // useEffect(() => {
  //   if (isPremiumAdvisor) {
  //     // console.log('IS PREMIUM ADVISOR - - -', isPremiumAdvisor);
  //     getPremiumAdvisorChannelId()
  //       .then((res) => {
  //         // console.log('RESPONSE ');
  //         if (res && res.result && res.result.data) {
  //           const channel_id =
  //             res.result.data[Object.keys(res.result.data)[0]][0].id;
  //           getMyContentArticles(channel_id);
  //         }
  //       })
  //       .catch((err) => console.log('ERROR - - - - ', err));
  //   } else {
  //     getMyContentArticles(channelId);
  //   }
  // }, []);

  const getMyContentArticles = channel_id => {
    // console.log('GET MY CONTENT ARTICLES');
    toggleSuggestedArticlesLoader(true);
    if (!isEmpty(channel_id)) {
      getContentLibrary(channel_id)
        .then((res) => {
          let articleData = [];
          let topicId = '';
          let publisherId = '';
          if (
            res.result &&
            res.result.data &&
            res.result.data.channel_content
          ) {
            const topicNames = get(res, 'result.data.details.theme');
            const publisherNames = get(res, 'result.data.details.channel');
            articleData = res.result.data.channel_content.filter(ele => get(ele, 'auxiliary.source')!=='img').map((item) => {
              topicId = get(item, 'ids.theme', []).map(theme => theme.id)[0];
              publisherId = get(item, 'ids.account', '');
              console.log('TOPIC ID - - - ', topicId, 'PUBLISHER ID - - -', publisherId);
              console.log()
              return {
                id: item ? item.id : '',
                title:
                  item && item.auxiliary && item.auxiliary.title
                    ? item.auxiliary.title
                    : '',
                description: item && item.text ? item.text : '',
                imgUrl: item && item.media_urls && getMediaUrl(item.media_urls),
                channel_id:
                  item && item.ids && item.ids.channel && item.ids.channel[0]
                    ? item.ids.channel[0]
                    : '',
                theme_id:
                  item && item.ids && item.ids.theme && item.ids.theme[0]
                    ? item.ids.theme[0].id
                    : '',
                sponsored: get(item, 'auxiliary.is_sponsored', false),
                topic_name: topicId && !isEmpty(topicNames) && topicNames[topicId] ? topicNames[topicId].name : '',
                sponsor_name: publisherId && !isEmpty(publisherNames) ? (publisherId[0].length === 1 ? publisherNames[publisherId].title : publisherNames[publisherId[0]].title) : ''
              };
            });
            console.log('ARTICLE DATA my content - - - - - ', articleData);
          }
          setSuggestedArticles(articleData);
          toggleSuggestedArticlesLoader(false);
        })
        .catch((err) => {
          console.log('ERROR - - - - ', err);
          toggleSuggestedArticlesLoader(false);
        });
    }
  };


  const fetchBookmarkedArticles = () => {
    toggleSuggestedArticlesLoader(true);
      getBookmarkedArticles()
      .then((res) => {
        let articleData = [];
        let topicId = '';
        let publisherId = '';
        if (
          res.result &&
          res.result.data &&
          res.result.data.feed
        ) {
          const topicNames = get(res, 'result.data.details.theme');
          const publisherNames = get(res, 'result.data.details.account');
          articleData = res.result.data.feed.map((item) => {
            console.log('BOOKMARK ARTICLE  - - - -  ', item);
            topicId = get(item, 'ids.theme', [])[0];
            publisherId = get(item, 'ids.account', '');
            return {
              id: item ? item.id : '',
              title:
                item && item.auxiliary && item.auxiliary.title
                  ? item.auxiliary.title
                  : '',
              description: item && item.text ? item.text : '',
              imgUrl: item && item.media_urls && getMediaUrl(item.media_urls),
              channel_id:
                item && item.ids && item.ids.channel && item.ids.channel[0]
                  ? item.ids.channel[0]
                  : '',
              theme_id:
                item && item.ids && item.ids.theme && item.ids.theme[0]
                  ? item.ids.theme[0]
                  : '',
              sponsored: get(item, 'auxiliary.is_sponsored', false),
              topic_name: topicId && !isEmpty(topicNames) && topicNames[topicId] ? topicNames[topicId].name : '',
              sponsor_name: publisherId && !isEmpty(publisherNames) ? (publisherId[0].length === 1 ? publisherNames[publisherId].title : publisherNames[publisherId[0]].title) : ''
            };
          });
        }
        console.log('ARTICLE DATA - - - - - ', articleData);
        setSuggestedArticles(articleData);
        toggleSuggestedArticlesLoader(false);
      })
      .catch((err) => {
        console.log('ERROR - - - - ', err);
        toggleSuggestedArticlesLoader(false);
      });
  };

  const fetchRecommendedArticles = () => {
    toggleSuggestedArticlesLoader(true);
    const topicIds = setTopicsTabData.selectedTopics.map(topic => topic.id);
    let articleData = [];
    let topicId = '';
    let publisherId = '';
    console.log('TOPICS - -- -', topicIds);
    getExploreArticles(topicIds)
    .then(response => {
      console.log('RESPONSE -- - - ', response);
      const resObj = get(response, 'result.data.feed', []);
      if(!isEmpty(resObj)) {
        const topicNames = get(response, 'result.data.details.theme');
        const publisherNames = get(response, 'result.data.details.account');
        articleData = resObj.map(item => {
          topicId = get(item, 'ids.theme', [])[0];
          publisherId = get(item, 'ids.account', '');
          return {
            id: item ? item.id : '',
            title:
              item && item.auxiliary && item.auxiliary.title
                ? item.auxiliary.title
                : '',
            description: item && item.text ? item.text : '',
            imgUrl: item && item.media_urls && getMediaUrl(item.media_urls),
            channel_id:
              item && item.ids && item.ids.channel && item.ids.channel[0]
                ? item.ids.channel[0]
                : '',
            theme_id:
              item && item.ids && item.ids.theme && item.ids.theme[0]
                ? item.ids.theme[0]
                : '',
            sponsored: get(item, 'auxiliary.is_sponsored', false),
            topic_name: topicId && !isEmpty(topicNames) && topicNames[topicId] ? topicNames[topicId].name : '',
            sponsor_name: publisherId && !isEmpty(publisherNames) ? (publisherId[0].length === 1 ? publisherNames[publisherId].title : publisherNames[publisherId[0]].title) : ''
          };
        })
      }
      console.log('ARTICLE DATA - - - - - ', articleData);
      setSuggestedArticles(articleData);
      toggleSuggestedArticlesLoader(false);
    })
    .catch(err => {
      console.log('ERROR - - - -', err);
      toggleSuggestedArticlesLoader(false);
    });
  }

  const getAccountId = (platform, pageId) => {
    let selectedAcountId = '';

    if (!selectedAcountId) {
      if (platform === 'em') {
        if (
          !isEmpty(get(socialNetworkData, `${platform}`, [])) &&
          get(socialNetworkData, `${platform}`, []).length > 1 &&
          get(socialNetworkData, `${platform}`, []).filter(
            (s) => s.snetworkProvider === 'sg'
          ).length > 0
        ) {
          selectedAcountId = get(socialNetworkData, `${platform}`, []).filter(
            (s) => s.snetworkProvider === 'sg'
          )[0].accountId;
        } else {
          selectedAcountId = get(socialNetworkData, `${platform}[0].accountId`);
        }
      } else {
        selectedAcountId = get(socialNetworkData, `${platform}[0].accountId`);
      }
    }
    return selectedAcountId;
  };

  const detailsHandler = (campaignId) => {
    // toggleEditLoader(true);
    let resObj = {};
    let selected_topics = [];
    const platformMapper = {
      em: 'Email',
      tw: 'Twitter',
      fb: 'Facebook',
      ln: 'Linkedin',
    };
    const weekDataPresent = {};

    getCampaignDetails(campaignId)
      .then((res) => {
        resObj = get(res, 'result.data', {});
        selected_topics = themes.filter((item) =>
          get(resObj, 'themes', []).includes(item.id)
        );
        // console.log('SELECTED THEMES - - -- ', selectedThemes);
        return getSelectedArticles(campaignId);
      })
      .then((res1) => {
        console.log('RES1 - - - - ', res1);
        let res1Obj = get(res1, 'result.data', {});
        let fetchedSelectedArticles = [];
        if (!isEmpty(res1Obj)) {
          fetchedSelectedArticles = res1Obj.feed.map((article) => {
            return {
              id: get(article, 'id', ''),
              title: get(article, 'auxiliary.title', ''),
              description: get(article, 'text', ''),
              imgUrl:
                !isEmpty(get(article, 'media_urls')) &&
                getMediaUrl(get(article, 'media_urls')),
              channel_id: get(article, 'ids.channel[0]'),
              theme_id: get(article, 'ids.theme[0]'),
              summaries: !isEmpty(get(article, 'auxiliary.extra.summary'))
                ? get(article, 'auxiliary.extra.summary')
                : null,
            };
          });
        }
        if (!isEmpty(resObj)) {
          const duration = get(resObj, 'duration', []);
          const frequency = get(resObj, 'frequency', []);
          // const frequency = ['Every', 'Other', 'Week'];
          let contentWeeks = [];
          const posts = get(resObj, 'posts', {});
          const currentCampaignArticles = [];
          const emptyCampaignArticles = [];
          let allCampaignArticles = [];
          let tempArticle = {};
          const scheduleTimings = {
            fbTime: [],
            emTime: [],
            lnTime: [],
            twTime: [],
          };
          const publishStatus = {};

          if (duration && duration.length > 0) {
            for (let i = 1; i <= parseInt(duration[0]); i++)
              contentWeeks.push(`Week ${i}`);
          }
          if (frequency && frequency.length === 3)
            contentWeeks = contentWeeks.filter(
              (week, index) => index % 2 === 0
            );

          contentWeeks.forEach((week) => {
            weekDataPresent[week.split(' ').join('')] = false;
          });

          if (!isEmpty(get(resObj, 'schedule'))) {
            Object.keys(resObj.schedule).forEach((platformKey) => {
              resObj.schedule[platformKey].forEach((time) => {
                if (platformKey === 'email')
                  scheduleTimings.emTime.push(time.schedule_time);
                else if (platformKey === 'facebook')
                  scheduleTimings.fbTime.push(time.schedule_time);
                else if (platformKey === 'linkedin')
                  scheduleTimings.lnTime.push(time.schedule_time);
                else if (platformKey === 'twitter')
                  scheduleTimings.twTime.push(time.schedule_time);
              });
            });
          }

          if (!isEmpty(posts)) {
            let articleObj = {};
            Object.keys(posts).forEach((weekKey) => {
              weekDataPresent[weekKey] = true;
              Object.keys(posts[weekKey]).forEach((platform) => {
                posts[weekKey][platform].forEach((post) => {
                  scheduleTimings[`${post.destination_snetwork}Time`] = [
                    post.publish_time,
                  ];
                  if (post.status === 'draft')
                    publishStatus[weekKey.toLowerCase()] = true;
                  articleObj = {
                    platform: platformMapper[post.destination_snetwork],
                    day: post.publish_time && post.publish_time.split(',')[0],
                    time: post.publish_time && post.publish_time.split(', ')[1],
                    schedule_time: post.publish_time,
                    destination_snetwork: post.destination_snetwork,
                    engagement_type: post.engagement_type,
                    destination_id:
                      post.destination_snetwork !== 'em'
                        ? post.destination_id
                        : null,
                    contactlist_id:
                      post.destination_snetwork === 'em'
                        ? post.contactlist_id
                        : null,
                    snetwork_act_id: post.snetwork_act_id,
                    content: true,
                    week: `Week ${post.week[post.week.length - 1]}`,
                    id: get(post, 'articles.feed[0].id', ''),
                    title: get(post, 'articles.feed[0].auxiliary.title', ''),
                    description: get(post, 'articles.feed[0].text', ''),
                    imgUrl: getMediaUrl(
                      get(post, 'articles.feed[0].media_urls')
                    ),
                    channel_id: post.channel_id,
                    theme_id: post.theme_id,
                    override_text: post.summary,
                    source_snetwork: !isEmpty(tempArticle.id)
                      ? getSourceNwId(tempArticle.id)
                      : null,
                    repost: false,
                  };
                  currentCampaignArticles.push(articleObj);
                });
              });
            });
          }

          console.log('SCHEDULE TIMINGS - - - - ', scheduleTimings);

          Object.keys(weekDataPresent)
            .filter((key) => !weekDataPresent[key])
            .forEach((key) => {
              Object.keys(scheduleTimings).forEach((platform) => {
                // console.log('ACCOUNT ID  - - - -- ', getAccountId(platform));
                scheduleTimings[platform].forEach((time) => {
                  emptyCampaignArticles.push({
                    platform: platformMapper[platform.slice(0, 2)],
                    day: time && time.split(',')[0],
                    time: time && time.split(',')[1],
                    content: false,
                    schedule_time: time,
                    destination_snetwork: platform.slice(0, 2),
                    engagement_type: 'share',
                    destination_id:
                      platform.slice(0, 2) !== 'em'
                        ? resObj[
                            platformMapper[platform.slice(0, 2)].toLowerCase()
                          ].map((item) => get(item, 'destination_id', 0))
                        : null,
                    contactlist_id:
                      platform.slice(0, 2) === 'em'
                        ? resObj.email.map((item) =>
                            get(item, 'destination_id', 0)
                          )
                        : null,
                    snetwork_act_id: getAccountId(platform.slice(0, 2)),
                    week: `Week ${key[key.length - 1]}`,
                  });
                });
              });
            });

          console.log('EMPTY ARTICLES - - -', emptyCampaignArticles);
          allCampaignArticles = currentCampaignArticles
            .concat(emptyCampaignArticles)
            .map((article, index) => {
              return {
                ...article,
                ref_id: index,
              };
            });

          console.log('POSTS - - - ', allCampaignArticles);

          campaignSetState({
            campaignId: resObj.campaign_id,
            setObjectives: {
              name: resObj.name,
              purpose: resObj.purpose,
              target: resObj.target,
              emList: resObj.email.map((item) => {
                return {
                  ...item,
                  id: item.destination_id,
                };
              }),
              fbList: resObj.facebook.map((item) =>
                get(item, 'destination_id', 0)
              ),
              lnList: resObj.linkedin.map((item) =>
                get(item, 'destination_id', 0)
              ),
              twList: resObj.twitter.map((item) =>
                get(item, 'destination_id', 0)
              ),
            },
            setTopics: {
              selectedTopics: selected_topics,
            },
            createFlow: true,
            planCampaign: {
              startDate: resObj.start_date,
              duration: !isEmpty(get(resObj, 'duration'))
                ? resObj.duration.join(' ')
                : null,
              frequency: !isEmpty(get(resObj, 'frequency'))
                ? resObj.frequency.join(' ')
                : null,
              engagementScore: 60,
            },
            contentWeeks: contentWeeks ? contentWeeks : null,
            scheduleTimings: scheduleTimings,
            campaignArticles: allCampaignArticles,
            selectedArticles: fetchedSelectedArticles,
            publishedContent: publishStatus,
          });
          // setCreateFlow(true);
          // toggleEditLoader(false);
          campaignDetailsResponse(resObj);
        }
      })
      .catch((err) => {
        console.log('ERR - -- - - ', err);
        campaignDetailsResponse(resObj);
        // toggleEditLoader(false);
      });
  };

  const changeCreateFlow = create => {
    console.log('CHANGE CREATE FLOW - - - - -', );
    setCreateFlow(create ? true : false);
    setCreateScreenIndex(0);
    setCreateTabs(0);
    campaignSetState({
      campaignId: '',
      createFlow: create ? true : false,
      setObjectives: {},
      setTopics: {},
      contentWeeks: [],
      planCampaign: {},
      campaignArticles: [],
      scheduleTimings: {},
      selectedWeek: '',
      selectedDays: '',
      selectedArticles: [],
      publishedContent: {},
    });
    if(!create)
      campaignRequest();
  };

  return (
    <div style={{ height: height, width: '100%' }}>
      {!createFlow && (
        <DripCampaignDashboard
          createFlow={createFlow}
          setCreateFlow={setCreateFlow}
          campaignSetState={campaignSetState}
          campaignFetching={campaignFetching}
          campaignsData={campaignsData}
          socialThemes={themes}
          editCampaignHandler={() => setCreateFlow(true)}
          editLoader={editLoader}
          campaignRequest={campaignRequest}
          detailsHandler={detailsHandler}
          changeCreateFlow={changeCreateFlow}
        />
      )}
      {createFlow && (
        <CreateCampaign
          createFlow={createFlow}
          setCreateFlow={setCreateFlow}
          createTabs={createTabs}
          setCreateTabs={setCreateTabs}
          contactGroups={contactGroups}
          socialNetworkData={socialNetworkData}
          selectedThemes={selectedThemes}
          themes={themes}
          campaignSetState={campaignSetState}
          campaignCreateFlow={campaignCreateFlow}
          setObjectivesTabData={setObjectivesTabData}
          campaignId={campaignId}
          trendingTopics={trendingTopics}
          mostSubscribedTopics={mostSubscribedTopics}
          setTopicsTabData={setTopicsTabData}
          suggestedArticles={suggestedArticles}
          getMyContentArticles={getMyContentArticles}
          fetchBookmarkedArticles={fetchBookmarkedArticles}
          fetchRecommendedArticles={fetchRecommendedArticles}
          createScreenIndex={createScreenIndex}
          setCreateScreenIndex={setCreateScreenIndex}
          getAccountId={getAccountId}
          campaignScheduleTimings={campaignScheduleTimings}
          campaignSelectedArticles={campaignSelectedArticles}
          campaignSelectedWeek={campaignSelectedWeek}
          campaignSelectedDays={campaignSelectedDays}
          campaignArticles={campaignArticles}
          contentWeeks={contentWeeks}
          planCampaignData={planCampaignData}
          publishedContent={publishedContent}
          endCreateFlow={() => changeCreateFlow()}
          isPremiumAdvisor={isPremiumAdvisor}
          channelId={channelId}
          suggestedArticlesLoader={suggestedArticlesLoader}
          toggleSuggestedArticlesLoader={toggleSuggestedArticlesLoader}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  const roleType = getRoleType(state);
  const isPremiumAdvisor = roleType === ROLE_TYPES.ADVISOR_PREMIUM;
  return {
    socialNetworkData: state.socialNetwork.data,
    selectedThemes: state.theme.yourTopics,
    themes: state.theme.data,
    campaignCreateFlow: state.campaign.createFlow,
    setObjectivesTabData: state.campaign.setObjectives,
    campaignId: state.campaign.campaignId,
    campaignsData: get(state, 'campaign.data', []),
    campaignScheduleTimings: state.campaign.scheduleTimings,
    campaignSelectedArticles: state.campaign.selectedArticles,
    campaignSelectedWeek: state.campaign.selectedWeek,
    campaignSelectedDays: state.campaign.selectedDays,
    campaignArticles: state.campaign.campaignArticles,
    campaignFetching: state.campaign.isFetching,
    campaignDetailsData: get(state, 'campaignDetails.data', {}),
    campaignBucketData: get(state, 'campaignDetails.campaignBucket', {}),
    channelId: getChannelId(state),
    setTopicsTabData: state.campaign.setTopics,
    isPremiumAdvisor: isPremiumAdvisor,
    contentWeeks: state.campaign.contentWeeks,
    planCampaignData: state.campaign.planCampaign,
    publishedContent: state.campaign.publishedContent,
  };
};

const mapDispatchToProps = {
  campaignSetState: campaignActions.api.campaign.setstate,
  campaignRequest: campaignActions.api.campaign.request,
  campaignDetailsResponse: campaignDetailsActions.api.campaignDetails.response
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DripCampaignContainer);
