import React, { useState, useEffect } from 'react';
import { ResponsiveImg } from '../../../components';
import {
  FilterIconDiv,
  EngagementCheckboxWrapper,
  FilterLi,
} from './EngagementTab.styles';
import { Box, Flex } from '@rebass/grid';
import {
  AdminCheckBox,
  GrayCheckBox,
} from '../../../components/SocialCheckBoxes/SocialCheckBoxes.styles';
import { get, isEmpty, map, filter, orderBy, difference } from 'lodash';

const FilterContacts = ({
  contactGroup,
  handleClick,
  selectedContacts,
  type,
  isAdmin,
  top,
  left,
  height,
  dropdownLeft,
  dropdownRight,
  padding,
}) => {
  const handleMouseEnter = () => {
    document.getElementById('engagement-filter').style.display = 'block';
  };
  const handleMouseLeave = () => {
    document.getElementById('engagement-filter').style.display = 'none';
  };

  const [listType, setListType] = useState('all');
  const [emailList, setEmailList] = useState([]);

  useEffect(() => {
    console.log('selectedContacts', selectedContacts);
    let customContactsCopy = [];

    let defaultList = filter(contactGroup, function (o) {
      return o.contact_type === 'default';
    });
    console.log('defaultList', defaultList);

    if (!isEmpty(defaultList)) {
      let defaultIDs = defaultList.map((ids) => {
        return ids.id;
      });
      defaultList.unshift({
        description: 'group_header',
        external_uuid: '-1',
        id: '-1',
        name: 'Others',
        type: 'default',
        contact_type: 'default',
        checked: defaultIDs.every((ai) => selectedContacts.includes(ai)),
      });

      customContactsCopy = customContactsCopy.concat(defaultList);
    }

    let prospectList = filter(contactGroup, function (o) {
      return o.contact_type === 'prospect';
    });
    console.log('prospectList', prospectList);

    if (!isEmpty(prospectList)) {
      let prospectIDs = prospectList.map((ids) => {
        return ids.id;
      });
      prospectList.unshift({
        description: 'group_header',
        external_uuid: '-1',
        id: '-1',
        name: 'Prospects',
        type: 'prospect',
        contact_type: 'prospect',
        checked: prospectIDs.every((ai) => selectedContacts.includes(ai)),
      });

      customContactsCopy = customContactsCopy.concat(prospectList);
    }

    let clientList = filter(contactGroup, function (o) {
      return o.contact_type === 'client';
    });
    console.log('clientList', clientList);

    if (!isEmpty(clientList)) {
      let clientIDs = clientList.map((ids) => {
        return ids.id;
      });
      clientList.unshift({
        description: 'group_header',
        external_uuid: '-1',
        id: '-1',
        name: 'Clients',
        type: 'client',
        contact_type: 'client',
        checked: clientIDs.every((ai) => selectedContacts.includes(ai)),
      });

      customContactsCopy = customContactsCopy.concat(clientList);
    }
    console.log('customContactsCopy', customContactsCopy);
    setEmailList(customContactsCopy.filter((item) => item));
  }, [contactGroup, selectedContacts]);

  const onSelectEmailGroup = (item) => {
    console.log('onSelectEmailGroup', item);
    console.log('emailList', emailList);
    // item.checked = !item.checked;

    emailList.forEach((emailItem) => {
      if (
        emailItem.contact_type === item.type &&
        (emailItem.description !== 'group_header' ||
          emailItem.description !== 'all_contacts')
      ) {
        emailItem.checked = item.checked;
      }
    });

    // console.log('onSelectEmailGroup', emailList);
    setEmailList([...emailList]);
  };

  useEffect(() => {
    console.log('emailList', emailList);
  }, [emailList]);

  console.log(dropdownLeft);
  return (
    <FilterIconDiv
      type={type}
      top={top}
      left={left}
      isAdmin={isAdmin}
      dropdownLeft={dropdownLeft}
      dropdownRight={dropdownRight}
      onMouseEnter={handleMouseEnter}
    >
      <ResponsiveImg
        src="/assets/images/Filter.svg"
        style={{ height: height || '', padding: padding || '' }}
      />
      <ul
        onMouseLeave={handleMouseLeave}
        id="engagement-filter"
        className="engagement-filter-ul"
        style={{ boxShadow: '0 5px 15px rgb(0 0 0 / 50%)' }}
      >
        {emailList &&
          emailList.length > 0 &&
          emailList.map((item, index) => (
            <>
              {item.description === 'group_header' ? (
                <FilterLi onClick={() => handleClick(item.contact_type)}>
                  <GrayCheckBox borderRadius="0px" color="#7dc5d0">
                    <AdminCheckBox
                      id={'all'}
                      type="checkbox"
                      className={item && item.checked ? 'checked' : ''}
                      checked={item && item.checked}
                    />
                    <label style={{ marginLeft: '10px', cursor: 'pointer' }}>
                      {item.name}
                    </label>
                  </GrayCheckBox>
                </FilterLi>
              ) : (
                <FilterLi
                  key={'contact_group_' + index}
                  onClick={() => handleClick(item.id)}
                  bgColor="#fff"
                >
                  <GrayCheckBox borderRadius="0px" color="#7dc5d0">
                    <AdminCheckBox
                      id={item.id}
                      type="checkbox"
                      className={
                        selectedContacts.includes(item.id) ? 'checked' : ''
                      }
                      checked={selectedContacts.includes(item.id)}
                    />
                    <label style={{ marginLeft: '10px', cursor: 'pointer' }}>
                      {item.name}
                    </label>
                  </GrayCheckBox>
                </FilterLi>
              )}
            </>
          ))}
      </ul>
    </FilterIconDiv>
  );
};

export default FilterContacts;
