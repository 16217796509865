import styled from 'styled-components';
import { Modal } from '@mui/material';

export const ModalRoot = styled(Modal)`
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Poppins' !important;
`;

export const ModalStyle = styled('div')`
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    background-color: white,
    border-radius: 8px;
    background: #FFFFFF;
    box-shadow: 0px 4px 8px rgba(16, 24, 40, 0.1);
    border-radius: 8px;
    padding: 10px 20px 30px;
    min-height: 200px;
    max-height: 98vh;
    overflow-y: auto;
`;

export const ModalHeader = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ModalTitle = styled('h4')`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  text-align: left;
  color: #101828;
`;

export const CloseIcon = styled('img')`
  width: 20px;
  height: 20px;
  cursor: pointer;
  margin: 5px 0 0 15px;
  :hover {
    opacity: 0.7;
  }
`;
