import { Flex, Box } from '@rebass/grid';
import React from 'react';
import { VerticalSeparator } from '../DripDashboardListItem/DripDashboardListItem.styles';
import moment from 'moment';

const CampaignInfo = ({
  title,
  start_date,
  duration,
  frequency,
  target,
  status,
}) => {
  const detailsLabel = [
    { name: 'Start Date', img: '/assets/images/drip/calendar.svg' },
    { name: 'Target', img: '/assets/images/drip/target.svg' },
    { name: 'Frequency', img: '/assets/images/drip/reload.svg' },
    { name: 'Duration', img: '/assets/images/drip/clock.svg' },
  ];
  const details = {
    startDate: start_date ? moment(start_date).format('DD MMM YYYY') : '-',
    target: target ? target.charAt(0).toUpperCase() + target.slice(1) : '-',
    frequency: frequency ? frequency.join(' ') : '-',
    duration: duration ? duration.join(' ') : '-',
  };

  return (
    <Flex flexWrap="wrap" mx={-2}>
      {Object.keys(details).map((ele, index) => (
        <Box px={2} py={2} width={1 / 2}>
          <>
            <Flex
              flexDirection="row"
              justifyContent="flex-start"
              alignItems="flex-start"
            >
              <img
                src={detailsLabel[index].img}
                alt=""
                style={{ paddingRight: '3px' }}
              />
              <Flex
                flexDirection="column"
                justifyContent="center"
                alignItems="flex-start"
              >
                <span style={{ color: '#4E6780', fontSize: '10px' }}>
                  {detailsLabel[index].name}
                </span>
                <span
                  style={{
                    color: '#000000',
                    fontSize: '9px',
                    fontWeight: '600',
                  }}
                >
                  {details[ele] ? details[ele] : '-'}
                </span>
              </Flex>
            </Flex>
            {/* {index !== detailsLabel.length - 1 && <VerticalSeparator />} */}
          </>
        </Box>
      ))}
    </Flex>
  );
};

export default CampaignInfo;
