import React, { useState } from 'react';
import Button from 'src/componentsV2/Button';
import SearchContainer from 'src/componentsV2/SearchContainer';
import useRESTQuery from 'src/hooks/useRESTQuery';
import { checkEmailListName } from '../../../../services/configure';
import {
  Description,
  Form,
  FormField,
  InputField,
  Separator,
  Label,
  Wrapper,
  RadioButton,
  RadioButtonGroup,
  RadioButtonWrapper,
  FooterActions,
  FormFields,
} from './styles';

function CreateEmailList({ setDisplayState, handleContactListSubmission }) {
  const [title, setTitle] = useState('');
  const [contactType, setContactType] = useState('');
  const [selectedTags, setSelectedTags] = useState([]);
  const [searchString, setSearchString] = useState('');

  const { data: topics } = useRESTQuery({
    key: 'all-topics',
    api: 'api.clout',
    endpoint: `theme/list_all?custom_tags=true`,
    options: {
      select: (res) => res?.result?.data ?? [],
    },
  });

  const updateListTypeAndTag = (type) => {
    if (contactType === type) {
      setContactType(null);
      const newChips = selectedTags.filter((item) => item !== type);
      setSelectedTags([...newChips]);
    } else {
      let output = [];
      if (!selectedTags.includes(type)) {
        if (type === 'prospect') {
          setContactType('prospect');
          output = selectedTags.filter((oldTags) => oldTags !== 'client');
        } else {
          setContactType('client');
          output = selectedTags.filter((oldTags) => oldTags !== 'prospect');
        }
        setContactType(type);
        setSelectedTags([...output, type]);
      }
    }
  };

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      const contact = await checkEmailListName(title, contactType);

      if (!!contact?.result.data.id) {
        handleContactListSubmission(contact?.result.data.id, selectedTags);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Wrapper>
      <Form onSubmit={handleSubmit}>
        <FormFields>
          <FormField>
            <Label>List name*</Label>
            <InputField
              type="text"
              id="name"
              name="Firm"
              value={title}
              placeholder={'List name'}
              onChange={(e) => setTitle(e.target.value)}
              required
            />
          </FormField>
          <Separator />
          <FormField>
            <Label>
              Do you want to add any tags to the contacts in this list?
            </Label>
            <Description>
              Tags can be used to segment your contacts for target campaigns.
              Any tags you select here will be assigned to all contacts in this
              list.
            </Description>
          </FormField>
          <FormField>
            <Label>Contact Type</Label>
            <RadioButtonGroup>
              <RadioButtonWrapper>
                <RadioButton
                  type="radio"
                  checked={contactType === 'prospect'}
                  value={contactType}
                  onChange={() => updateListTypeAndTag('prospect')}
                />
                <Label>Prospect</Label>
              </RadioButtonWrapper>
              <RadioButtonWrapper>
                <RadioButton
                  type="radio"
                  checked={contactType === 'client'}
                  value={contactType}
                  onChange={() => updateListTypeAndTag('client')}
                />
                <Label>Client</Label>
              </RadioButtonWrapper>
            </RadioButtonGroup>
          </FormField>
          <FormField>
            <SearchContainer
              title={
                'Search and Select from Custom and Clout-automated theme Tags'
              }
              selectedTags={selectedTags}
              searchString={searchString}
              setSearchString={setSearchString}
              setSelectedTags={setSelectedTags}
              searchResults={topics ?? []}
            />
          </FormField>
        </FormFields>
        <FooterActions>
          <Button
            type="button"
            variant="outline"
            fullWidth
            onClick={() => setDisplayState('CONTACT_LIST')}
          >
            Back
          </Button>
          <Button type="submit" fullWidth>
            Create
          </Button>
        </FooterActions>
      </Form>
    </Wrapper>
  );
}

export default CreateEmailList;
