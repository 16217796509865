/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable react-hooks/exhaustive-deps */
import { Flex } from '@rebass/grid';
import { get, isEmpty } from 'lodash';
import React, { useRef } from 'react';
import { useEffect, useState } from 'react';
import {
  Button,
  LoaderWrapper,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalHeader,
} from '../../../components';
import {
  getCampaignDetails,
  getSeriesSubCampaigns,
  postUseCascadedCampaign,
} from '../../../services/campaign';
import { getMediaImage, themesData } from '../../../utils';
import { CreateContainer } from '../CreateCampaign/CreateCampaign.styles';
import { Chips } from '../SocialPreview/SocialPreview.styles';
import {
  PreviewContainer,
  PreviewTooltip,
  ReviewBody,
  TemplateContainer,
} from './Modal.styles';
import ConfirmationModal from '../../../components/ConfirmationModal/ConfirmationModal';

const PreviewModal = ({
  isOpen,
  handleClose,
  campaignId,
  seriesId,
  type,
  subCampaigns,
  isCompliance,
  complianceCampaignData,
  compliancePlatform,
  diffHtml,
  isATSPreviewModalVisible,
  handleTestEmail,
  campaignSetState,
  detailsHandler,
  onFilterTabChange,
  campaigns,
  topLevel,
  editSeriesLevel
}) => {
  console.log('HANDLE CLOSE - - -- ', campaignId, seriesId);
  console.log('campaigns', campaigns)
  const [loading, setLoading] = useState(false);
  const [activePlatform, setActivePlatform] = useState('fb');
  const [curPlatformCampaign, setCurPlatformCampaign] = useState({});
  const iframeRef = useRef(null);
  const [approveToShareLoading, setApproveToShareLoading] = useState(false);
  const [error, setError] = useState('');
  const [newCampaignId, setNewCampaignId] = useState('');
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [modalType, setModalType] = useState('');

  //   const platformMapper = {
  //     fb: 'Facebook',
  //     tw: 'Twitter',
  //     em: 'Email',
  //     ln: 'Linkedin',
  //   };

  const curComplianceCampaignHandler = (resObj) => {
    let emList = get(resObj, 'posts.email', []);
    let twList = get(resObj, 'posts.twitter', []);
    let lnList = get(resObj, 'posts.linkedin', []);
    let fbList = get(resObj, 'posts.facebook', []);

    const templateHTML = get(resObj, 'template_html', '');
    let fetchedArticles = [];

    if (get(resObj, 'posts_details.fb', false))
      fetchedArticles.push(
        get(resObj, 'posts_details.fb[0].articles.feed[0]', {})
      );
    else if (get(resObj, 'posts_details.tw', false))
      fetchedArticles.push(
        get(resObj, 'posts_details.tw[0].articles.feed[0]', {})
      );
    else if (get(resObj, 'posts_details.ln', false))
      fetchedArticles.push(
        get(resObj, 'posts_details.ln[0].articles.feed[0]', {})
      );
    else fetchedArticles = get(resObj, 'newsletter.articles.feed', []);

    let socialArticleId = '';

    if (get(resObj, 'posts.facebook.articles[0].id', ''))
      socialArticleId = get(resObj, 'posts.facebook.articles[0].id', '');
    else if (get(resObj, 'posts.twitter.articles[0].id', false))
      socialArticleId = get(resObj, 'posts.twitter.articles[0].id', '');
    else if (get(resObj, 'posts.linkedin.articles[0].id', false))
      socialArticleId = get(resObj, 'posts.linkedin.articles[0].id', '');

    // console.log('fetchedArticles', fetchedArticles);

    let campaign = {
      attachment: get(resObj, 'attachment', ''),
      template: templateHTML,
      subject: get(resObj, 'customized_data.subject', ''),
      em: {
        platform: get(resObj, 'is_email', 'false') === 'true',
      },
      selectedArticles: fetchedArticles
        .filter((article) => article.id === socialArticleId)
        .map((article) => ({
          id: article.id,
          url: getMediaImage(get(resObj, 'media_urls', []), 'original'),
          themeId: get(article, 'ids.theme[0]', ''),
          topic: get(article, 'auxiliary.title', ''),
          link: get(article, 'links.social_permalink', ''),
        })),
      fb: {
        platform: get(resObj, 'is_facebook', 'false') === 'true',
        summary: get(resObj, 'posts.facebook.override_text', ''),
      },
      tw: {
        platform: get(resObj, 'is_twitter', 'false') === 'true',
        summary: get(resObj, 'posts.twitter.override_text', ''),
      },
      ln: {
        platform: get(resObj, 'is_linkedin', 'false') === 'true',
        summary: get(resObj, 'posts.linkedin.override_text', ''),
      },
    };
    return campaign;
  };

  const curCampaignDetailsHandler = (resObj) => {
    let emList = get(resObj, 'email', []);
    let twList = get(resObj, 'twitter', []);
    let lnList = get(resObj, 'linkedin', []);
    let fbList = get(resObj, 'facebook', []);

    const templateHTML = get(resObj, 'template.template_html', '');
    const templateJSON = get(resObj, 'template.template_json', '');
    let fetchedArticles = [];

    if (get(resObj, 'posts_details.fb', false))
      fetchedArticles.push(
        get(resObj, 'posts_details.fb[0].articles.feed[0]', {})
      );
    else if (get(resObj, 'posts_details.tw', false))
      fetchedArticles.push(
        get(resObj, 'posts_details.tw[0].articles.feed[0]', {})
      );
    else if (get(resObj, 'posts_details.ln', false))
      fetchedArticles.push(
        get(resObj, 'posts_details.ln[0].articles.feed[0]', {})
      );
    else fetchedArticles = get(resObj, 'newsletter.articles.feed', []);

    let socialArticleId = '';

    if (get(resObj, 'posts.facebook.articles[0].id', ''))
      socialArticleId = get(resObj, 'posts.facebook.articles[0].id', '');
    else if (get(resObj, 'posts.twitter.articles[0].id', false))
      socialArticleId = get(resObj, 'posts.twitter.articles[0].id', '');
    else if (get(resObj, 'posts.linkedin.articles[0].id', false))
      socialArticleId = get(resObj, 'posts.linkedin.articles[0].id', '');

    // console.log('fetchedArticles', fetchedArticles);

    let campaign = {
      id: get(resObj, 'id', ''),
      campaign_id: get(resObj, 'campaign_id', ''),
      attachment: get(resObj, 'attachment', ''),
      template: templateHTML,
      templateJson: templateJSON,
      subject: get(resObj, 'newsletter.customized_data.subject', ''),
      action: get(resObj, 'feedback.email.action', ''),
      em: {
        platform:
          !isEmpty(emList) || get(resObj, 'is_email', 'false') === 'true',
        timings:
          resObj?.posts?.email?.schedule === 'immediate'
            ? [{ time: 'Immediate' }]
            : get(resObj, 'posts.email.schedule_time', []),
      },
      selectedArticles: fetchedArticles
        .filter((article) => article.id === socialArticleId)
        .map((article) => ({
          id: article.id,
          url: getMediaImage(get(resObj, 'media_urls', []), 'original'),
          themeId: get(article, 'ids.theme[0]', ''),
          topic: get(article, 'auxiliary.title', ''),
          link: get(article, 'links.social_permalink', ''),
        })),
      action: get(resObj, 'feedback.social.action', ''),
      fb: {
        timings:
          resObj?.posts?.facebook?.schedule === 'immediate'
            ? [{ time: 'Immediate' }]
            : get(resObj, 'posts.facebook.schedule_time', []),
        platform:
          !isEmpty(fbList) || get(resObj, 'is_facebook', 'false') === 'true',
        summary: get(resObj, 'posts.facebook.override_text', ''),
      },
      tw: {
        timings:
          resObj?.posts?.twitter?.schedule === 'immediate'
            ? [{ time: 'Immediate' }]
            : get(resObj, 'posts.twitter.schedule_time', []),
        platform:
          !isEmpty(twList) || get(resObj, 'is_twitter', 'false') === 'true',
        summary: get(resObj, 'posts.twitter.override_text', ''),
      },
      ln: {
        timings:
          resObj?.posts?.linkedin?.schedule === 'immediate'
            ? [{ time: 'Immediate' }]
            : get(resObj, 'posts.linkedin.schedule_time', []),
        platform:
          !isEmpty(lnList) || get(resObj, 'is_linkedin', 'false') === 'true',
        summary: get(resObj, 'posts.linkedin.override_text', ''),
      },
      url: get(resObj, 'webinar_custom_image', ''),
    };
    return campaign;
  };

  const curPlatformCampaignHandler = (activeCampaign) => {
    console.log('ACTIVE CAMPAIGN - - - - ', activeCampaign);
    if (activeCampaign) {
      setCurPlatformCampaign(activeCampaign);
      if (activeCampaign.em && activeCampaign.em.platform) {
        setActivePlatform('em');
      } else {
        for (let key in activeCampaign) {
          if (activeCampaign[key] && activeCampaign[key].platform) {
            setActivePlatform(key);
            break;
          }
        }
      }
    }
  };

  const handleCascadedCampaign = (openingType) => {
    const cascadeId = seriesId;
    setApproveToShareLoading(true);

    postUseCascadedCampaign({ cascadeId, recordType: 'series' })
      .then(res => {
        console.log('res', res)
        if (res?.result?.success) {
            campaignSetState({
              reviewCampaign: {
                campaigns: res.result.data.campaigns,
                fromReview: true
              },
              reviewType: openingType
            })
          editSeriesLevel(get(res, 'result.data.id', {}))
        }
      })
      .catch(err => {
        console.log('ERR = = = = = =', err);
        setApproveToShareLoading(false);
        setError('*Oops! Some error occurred. Please try again later.');
      });
  }

  const handleEditCampaign = () => {
    setOpenConfirmationModal(true);
    setModalType('edit');
    // handleCascadedCampaign('edit');
  };

  const handleStartCampaign = () => {
    setOpenConfirmationModal(true);
    setModalType('start');
  };

  const addToDashboard = () => {
    const cascadeId = type === 'series' ? seriesId : campaignId;
    setApproveToShareLoading(true);

    postUseCascadedCampaign({ cascadeId, recordType: type === 'series' ? 'series' : '' })
      .then(res => {
        console.log(res);
        if (res?.result?.success) {
          handleClose();
          onFilterTabChange(0);
        }

      });
  }

  useEffect(() => {
    (async () => {
      let resObj = {};
      console.log(complianceCampaignData);
      if (get(complianceCampaignData, 'platform', '') === 'web_page') {
        setCurPlatformCampaign({
          template: get(complianceCampaignData, 'template_html', ''),
        });
        setActivePlatform('em');
      } else if (get(complianceCampaignData, 'source_snetwork', '')) {
        setCurPlatformCampaign({
          template: get(complianceCampaignData, 'template_html', ''),
        });
        setActivePlatform('em');
      } else if (campaignId && !isCompliance) {
        //   setCurrentCampaignID(campaignId);
        setLoading(true);
        if (isATSPreviewModalVisible) {
          setNewCampaignId(topLevel && campaigns ? campaigns[0] : campaignId);
        }
        getCampaignDetails(topLevel && type === 'series' && campaigns && isATSPreviewModalVisible ? campaigns[0] : campaignId)
          .then((res) => {
            setLoading(false);
            resObj = get(res, 'result.data', {});

            let campaign = curCampaignDetailsHandler(resObj);
            //   setPlatformCampaigns(campaigns);

            curPlatformCampaignHandler(campaign);
          })
          .catch((err) => {
            setLoading(false);
            console.log(err);
          });
      } else if (type === 'series' && !isCompliance) {
        let filteredSubCampaigns = [];
        if (!isEmpty(subCampaigns))
          filteredSubCampaigns = subCampaigns.filter(
            (campaign) => campaign.status === 'completed'
          );
        else {
          const res = await getSeriesSubCampaigns({ id: seriesId });
          const fetchedSubCampaigns = get(res, 'result.data.campaigns', []);
          filteredSubCampaigns = fetchedSubCampaigns.filter(
            (campaign) => campaign.status === 'completed'
          );
        }
        let totalCampaigns = [];
        filteredSubCampaigns.forEach((campaign) => {
          let campaigns = curCampaignDetailsHandler(campaign);
          totalCampaigns.push(...campaigns);
        });
        //   setPlatformCampaigns(totalCampaigns);
        curPlatformCampaignHandler(totalCampaigns[0]);
      } else if (isCompliance) {
        let campaign = curComplianceCampaignHandler(complianceCampaignData);
        curPlatformCampaignHandler(campaign);
      }
    })();
  }, [campaignId, type, subCampaigns, isCompliance]);

  useEffect(() => {
    if (activePlatform === 'em') {
      setTimeout(() => {
        if (iframeRef.current) {
          var css = document.createElement('style');
          var st = '::-webkit-scrollbar { width: 5px }';
          st += ' ::-webkit-scrollbar-track { background: #e9f6e6 !important }';
          st += '::-webkit-scrollbar-thumb { background: #bccdd4 !important }';
          css.appendChild(document.createTextNode(st));
          iframeRef.current.contentDocument.head.append(css);
        }
      }, 100);
    }
  }, [activePlatform]);

  const handleCarouselClick = (type) => {
    let id;
    let index = campaigns.findIndex(c => c === newCampaignId);
    console.log(index);
    if (type === 'prev') {
      if (index === 0) {
        return;
      }
      id = campaigns[index - 1];
      setNewCampaignId(id);
    } else if (type === 'next') {
      if (index + 1 === campaigns.length) {
        return;
      }
      id = campaigns[index + 1];
      setNewCampaignId(id);
    }
    setLoading(true);
    getCampaignDetails(id)
      .then((res) => {
        setLoading(false);
        let resObj = get(res, 'result.data', {});

        let campaign = curCampaignDetailsHandler(resObj);
        //   setPlatformCampaigns(campaigns);

        curPlatformCampaignHandler(campaign);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  }

  return (
    <>
    <Modal
      onRequestClose={handleClose}
      isOpen={isOpen}
      size="medium"
      height={isCompliance && '95%'}
    >
      <ModalHeader style={{ textAlign: isATSPreviewModalVisible ? 'left' : '' }} paddingBottom={isCompliance && '0px'}>
        <div
          data-testid="campaign-preview-modal"
          style={{
            color: themesData.charcoalGrey,
            fontSize: isCompliance ? '1rem' : '1.5rem',
          }}
        >
          {isATSPreviewModalVisible ? 'Campaign Preview' : 'Preview'}
        </div>
        <ModalCloseButton onClick={handleClose} />
      </ModalHeader>

      <ModalBody
        style={{
          height: isCompliance && '85%',
          overflowY: isCompliance && 'auto',
        }}
        padding={isCompliance && '0px 15px'}
      >
        <LoaderWrapper isLoading={loading}>
          <CreateContainer
            justifyContent="flex-start"
            color="black"
            marginTop="5px"
            marginBottom="5px"
            height={isCompliance ? '58vh' : '68vh'}
            style={{ backgroundColor: 'white', width: '100%' }}
          >
            <PreviewContainer>
              {activePlatform === 'em' && (
                <div style={{ height: '85%' }}>
                  {get(complianceCampaignData, 'platform', '') !==
                    'web_page' && (
                      <>
                        <div style={{ marginBottom: '10px', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                          <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                            Subject: {curPlatformCampaign?.subject || ''}
                          </div>
                          {isATSPreviewModalVisible && type === 'series' ? (
                            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                              <img alt="" src='/assets/images/left-navigation.svg' onClick={() => handleCarouselClick('prev')} style={{ cursor: 'pointer' }} />
                              <span style={{ display: 'flex', alignItems: 'center', padding: '0px 16px' }}>Showing {campaigns.findIndex(c => c === newCampaignId) + 1} / {campaigns?.length} </span>
                              <img alt="" src="/assets/images/right-navigation.svg" onClick={() => handleCarouselClick('next')} style={{ cursor: 'pointer' }} />
                            </div>
                          ) : (
                            null
                          )}

                        </div>
                      </>
                    )}

                  <TemplateContainer style={{ height: isCompliance && '46vh' }}>
                    <iframe
                      srcDoc={
                        diffHtml ? diffHtml : curPlatformCampaign?.template
                      }
                      frameBorder="no"
                      ref={iframeRef}
                      title="email_preview"
                    />
                  </TemplateContainer>
                </div>
              )}
              {(activePlatform === 'fb' ||
                activePlatform === 'ln' ||
                activePlatform === 'tw') && isATSPreviewModalVisible && type === 'series' ? (
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <img alt="" src='/assets/images/left-navigation.svg' onClick={() => handleCarouselClick('prev')} style={{ cursor: 'pointer' }} />
                  <span style={{ display: 'flex', alignItems: 'center', padding: '0px 16px' }}>Showing {campaigns.findIndex(c => c === newCampaignId) + 1} / {campaigns?.length} </span>
                  <img alt="" src="/assets/images/right-navigation.svg" onClick={() => handleCarouselClick('next')} style={{ cursor: 'pointer' }} />
                </div>
              ) : (
                null
              )}
              {(activePlatform === 'fb' ||
                activePlatform === 'ln' ||
                activePlatform === 'tw') && (
                  <div
                    style={
                      curPlatformCampaign &&
                        curPlatformCampaign.attachment &&
                        curPlatformCampaign.attachment.length > 0
                        ? { height: '85%', cursor: 'pointer' }
                        : { height: '85%' }
                    }
                    onClick={
                      curPlatformCampaign &&
                        curPlatformCampaign.attachment &&
                        curPlatformCampaign.attachment.length > 0
                        ? () =>
                          window.open(curPlatformCampaign?.attachment, '_blank')
                        : null
                    }
                  >
                    <Flex
                      width="100%"
                      justifyContent="center"
                      alignItems="center"
                      mb="10px"
                    >
                      <img
                        alt="social"
                        src={
                          !isEmpty(curPlatformCampaign) &&
                            !isEmpty(get(curPlatformCampaign, 'url', ''))
                            ? get(curPlatformCampaign, 'url', '')
                            : !isEmpty(curPlatformCampaign.selectedArticles)
                              ? curPlatformCampaign.selectedArticles[0].url
                              : ''
                        }
                        style={{
                          // width: 'inherit',
                          height: '200px',
                          borderRadius: '5px',
                        }}
                      />
                    </Flex>
                    <ReviewBody
                      fontWeight="700"
                      fontSize={isCompliance ? '1rem' : '1.1rem'}
                    >
                      {!isEmpty(curPlatformCampaign.selectedArticles) &&
                        curPlatformCampaign.selectedArticles[0].topic}
                    </ReviewBody>
                    <ReviewBody
                      fontSize={isCompliance ? '0.8rem' : '0.9rem'}
                      style={{
                        marginTop: isCompliance ? '5px' : '10px',
                        height: isCompliance ? '15%' : '17vh',
                        overflowY: 'auto',
                      }}
                    >
                      {curPlatformCampaign[activePlatform]?.summary}
                    </ReviewBody>
                  </div>
                )}
              <div
                style={{
                  marginTop: '20px',
                  position: 'absolute',
                  bottom: ' 0',
                  left: '0px',
                }}
              >
                <Flex display="flex" flexDirection="column">
                  <Flex
                    alignItems="center"
                    justifyContent="flex-start"
                    style={{
                      backgroundColor: '#eee',
                      borderRadius: '5px',
                      margin: '10px',
                      width: 'fit-content',
                    }}
                  >
                    {curPlatformCampaign.em &&
                      curPlatformCampaign.em.platform && (
                        <Chips
                          onClick={() => {
                            setActivePlatform('em');
                          }}
                          active={activePlatform === 'em'}
                        // style={{ marginRight: '5px' }}
                        >
                          <div className="chip" style={{ cursor: 'pointer' }}>
                            <img
                              src="/assets/images/campaign-platform-email.svg"
                              alt=""
                            />
                            Email
                          </div>
                        </Chips>
                      )}
                    {curPlatformCampaign.fb &&
                      curPlatformCampaign.fb.platform && (
                        <Chips
                          onClick={() => {
                            setActivePlatform('fb');
                          }}
                          active={activePlatform === 'fb'}
                        // style={{ marginRight: '5px' }}
                        >
                          <div className="chip">
                            <img
                              src="/assets/images/campaign-platform-facebook.png"
                              alt=""
                            />
                            Facebook
                          </div>
                        </Chips>
                      )}
                    {curPlatformCampaign.ln &&
                      curPlatformCampaign.ln.platform && (
                        <Chips
                          onClick={() => {
                            setActivePlatform('ln');
                          }}
                          active={activePlatform === 'ln'}
                        // style={{ marginRight: '5px' }}
                        >
                          <div className="chip">
                            <img
                              src="/assets/images/campaign-platform-linkedIn.png"
                              alt=""
                            />
                            LinkedIn
                          </div>
                        </Chips>
                      )}
                    {curPlatformCampaign.tw &&
                      curPlatformCampaign.tw.platform && (
                        <Chips
                          onClick={() => {
                            setActivePlatform('tw');
                          }}
                          active={activePlatform === 'tw'}
                        // style={{ marginRight: '5px' }}
                        >
                          <div className="chip">
                            <img
                              src="/assets/images/campaign-platform-twitter.png"
                              alt=""
                            />
                            Twitter
                          </div>
                        </Chips>
                      )}
                  </Flex>
                </Flex>
              </div>
            </PreviewContainer>
          </CreateContainer>
          {isATSPreviewModalVisible ? (
            <LoaderWrapper isLoading={approveToShareLoading}>
              <Flex
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
              >
                <Flex display="flex" justifyContent="flex-start">
                  <Button
                    color="#101010"
                    bgColor="white"
                    borderColor="#6351ed"
                    borderRadius="4px"
                    onClick={handleEditCampaign}
                  >
                    <Flex display="flex" flexDirection="row" alignItems="center">
                      <span
                        style={{ display: 'flex', margin: '0px 10px 0px -12px' }}
                      >
                        <img alt="" src="/assets/images/pencil-edit-icon.svg" />
                      </span>
                      Edit Campaign
                    </Flex>
                  </Button>
                </Flex>
                <Flex display="flex" justifyContent="flex-end">
                  <Flex margin="20px 12px 0px 0px" style={{ cursor: 'pointer', width: '50px', height: '40px', display: 'flex', justifyContent: 'center', alignItems: 'center', border: '1px solid #6351ed', borderRadius: '4px', backgroundColor: 'white', }} onClick={addToDashboard}>
                    <PreviewTooltip style={{ marginTop: '4px' }}>
                      <img alt="" src='/assets/images/add-to-dashboard-icon.svg' />
                      <span className="tooltiptext">Add to my dashboard</span>
                    </PreviewTooltip>
                  </Flex>
                  {activePlatform === 'em' && (
                    <Button
                      margin="20px 15px 0px 0px"
                      color="#101010"
                      bgColor="white"
                      borderColor="#6351ed"
                      borderRadius="4px"
                      onClick={() => handleTestEmail(newCampaignId, curPlatformCampaign?.subject, curPlatformCampaign)}
                    >
                      <Flex display="flex" flexDirection="row" alignItems="center">
                        <span
                          style={{ display: 'flex', margin: '0px 10px 0px -12px' }}
                        >
                          <img alt=" " src="/assets/images/simple-msg-icon.svg" />
                        </span>
                        Test Email
                      </Flex>
                    </Button>
                  )}
                  <Button borderRadius="4px" onClick={handleStartCampaign}>
                    <Flex display="flex" flexDirection="row" alignItems="center">
                      <span
                        style={{ display: 'flex', margin: '0px 10px 0px -12px' }}
                      >
                        <img alt="" src="/assets/images/forward-icon.svg" />
                      </span>
                      Start Campaign
                    </Flex>
                  </Button>
                </Flex>
              </Flex>
            </LoaderWrapper>
          ) : (
            <div
              style={{
                position: isCompliance && 'absolute',
                left: isCompliance && '0',
                right: isCompliance && '0',
              }}
            >
              <Button
                onClick={handleClose}
                margin={isCompliance && '8px'}
                fontSize={isCompliance && '10px'}
              >
                Close
              </Button>
            </div>
          )}
        </LoaderWrapper>
      </ModalBody>
    </Modal>
    {openConfirmationModal && (
      <ConfirmationModal 
        closeHandler={() => {
          setOpenConfirmationModal(false);
          setModalType('');
        }}
        loader={approveToShareLoading}
        modalTitle={modalType === 'edit' ? <span style={{ wordBreak: 'break-word', padding: '10px 30px'}}>Selecting to edit this campaign will require compliance review and approval before sending</span> : <span style={{ wordBreak: 'break-word', padding: '10px 30px'}}>Selecting to start this campaign will not require additional compliance approval as you will not be able to customize the campaigns provided</span>}
        clickHandler={() => { 
          if(modalType === 'edit') {
            handleCascadedCampaign('edit');
          } else {
            handleCascadedCampaign('start');
          }
        }}
        btnMargin="0px"
        modalWidth="55%"
      />
    )}
    </>
  );
};

export default PreviewModal;
