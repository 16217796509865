import React, { useState, useEffect } from 'react';

import { Flex, Box } from '@rebass/grid';

import {
  CreateContainer,
  TitleSpan,
  CardText,
  CardTitle,
  CardSubtitle,
  TopicsParent,
} from './Survey.styles';
import { Button, LoaderWrapper } from '..';
import Article from './Article';
import { ArticleData } from './dummyData';
import { ceil, isEmpty, get } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretRight, faCaretLeft } from '@fortawesome/free-solid-svg-icons';
import { getMediaUrl } from '../../utils';

const ArticlesPage = ({
  nextHandler,
  articles,
  paginationAction,
  articleViewedAction,
  articleLikedAction,
  articleDislikedAction,
  surveyDoneAction,
  paginationLoader,
  redirectUrl,
}) => {
  const [totalDataLength, setTotalDataLength] = useState(0);
  const [pageLimit, setPageLimit] = useState(12);
  const [currentPage, setCurrentPage] = useState(1);
  const [paginatedData, setPaginatedData] = useState([]);
  const [clickLoader, setClickLoader] = useState(false);
  const [likedArticles, setLikedArticles] = useState([]);
  const [dislikedArticles, setDislikedArticles] = useState([]);
  const [doneButtonState, setDoneButtonState] = useState(true);
  const [pageDuration, setPageDuration] = useState(new Date());

  setTimeout(function () {
    setDoneButtonState(false);
  }, 20 * 60000);

  const onClickURL = () => {
    window.location.href = redirectUrl;
  };

  // useEffect(() => {
  //   console.log('likedArticles', likedArticles);
  // }, [likedArticles]);

  useEffect(() => {
    if (!isEmpty(articles)) {
      setPaginatedData(articles && articles.slice(0, pageLimit));
      setTotalDataLength(articles && articles.length);
      setCurrentPage(1);
    }
  }, [articles]);

  const handlePagination = (mode) => {
    window.scrollTo({ top: 0, behavior: 'smooth' });

    let td = articles;
    let filteredData = [];

    if (mode === 'next') {
      filteredData = td.slice(
        currentPage * pageLimit,
        currentPage * pageLimit + pageLimit
      );
      setCurrentPage(currentPage + 1);
      setPaginatedData(filteredData);
      paginationAction({
        from_page: currentPage,
        to_page: currentPage + 1,
        duration: new Date() - pageDuration,
      });
    }

    if (mode === 'prev') {
      filteredData = td.slice(
        (currentPage - 2) * pageLimit,
        (currentPage - 2) * pageLimit + pageLimit
      );

      setCurrentPage(currentPage - 1);
      setPaginatedData(filteredData);
      paginationAction({
        from_page: currentPage,
        to_page: currentPage - 1,
        duration: new Date() - pageDuration,
      });
    }

    setPageDuration(new Date());
  };

  const handleDone = () => {
    setClickLoader(true);
    surveyDoneAction()
      .then((res) => {
        // console.log('surveyDoneAction', res);
        // nextHandler();
        onClickURL();
        // setClickLoader(false);
      })
      .catch((err) => {
        console.log('surveyDoneAction', err);
        // nextHandler();
        setClickLoader(false);
      });
  };

  return (
    <Flex justifyContent="center" alignItems="center" flexDirection="column">
      <CreateContainer width="1250px">
        <TopicsParent width="1200px">
          <Flex
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            flexWrap="wrap"
            // width="550px"
            // margin="20px"
          >
            <TitleSpan>Articles</TitleSpan>
            <LoaderWrapper isLoading={paginationLoader} loaderHeight="400px">
              <Flex
                flexDirection="row"
                flexWrap="wrap"
                alignItems="center"
                justifyContent="center"
                data-testid="all_articles"
              >
                {paginatedData.map((item, index) => {
                  return (
                    <Article
                      // topic={item.topicName}
                      testId={'article_' + index}
                      articleId={item.id}
                      topic={item.topicName || item.topic || '-'}
                      title={item.title}
                      desc={item.fullDescription}
                      // image={getMediaUrl(get(item, 'media_urls'))}
                      image={item.url}
                      likedArticles={likedArticles}
                      setLikedArticles={setLikedArticles}
                      dislikedArticles={dislikedArticles}
                      setDislikedArticles={setDislikedArticles}
                      articleViewedAction={articleViewedAction}
                      articleLikedAction={articleLikedAction}
                      articleDislikedAction={articleDislikedAction}
                    />
                  );
                })}
              </Flex>
            </LoaderWrapper>
            {ArticleData && ArticleData.length > 0 && (
              <Flex
                justifyContent="space-between"
                alignItems="center"
                display="flex"
                flexDirection="row"
                pb="20px"
                pt="30px"
              >
                {/* <span style={{ paddingTop: '15px' }}>
                  {(currentPage - 1) * pageLimit + 1} -{' '}
                  {currentPage * pageLimit < totalDataLength
                    ? currentPage * pageLimit
                    : totalDataLength}{' '}
                  of {totalDataLength}
                </span> */}
                <span
                  style={{
                    color: '#000',
                    fontSize: '12px',
                    marginRight: '10px',
                  }}
                >
                  Page&nbsp;&nbsp;&nbsp;
                  <span
                    style={{
                      border: '1px solid #8493A6',
                      borderRadius: '5px',
                      padding: '4px 13px',
                      width: '40.5px',
                      height: '28px',
                      color: '#6250ED',
                    }}
                  >
                    {'0' + currentPage}
                  </span>
                  &nbsp;&nbsp; of {'0' + ceil(totalDataLength / pageLimit)}
                </span>
                <Button
                  testId="articles_pagination_prev"
                  onClick={() => handlePagination('prev')}
                  disabled={currentPage <= 1}
                  borderRadius="5px 0px 0px 5px"
                  bgColor="#fff"
                  borderColor="#8493A6"
                  margin="0px"
                  padding="0px"
                  height="28px"
                  width="40.5px"
                >
                  {/* <FontAwesomeIcon
                    icon={faCaretLeft}
                    size="1.5x"
                    // style={{ marginRight: '5px' }}
                    color="#000"
                  /> */}
                  <img
                    alt="share"
                    src="/assets/images/pr-left.svg"
                    style={{ marginTop: '5px' }}
                  />
                </Button>
                <Button
                  testId="articles_pagination_next"
                  onClick={() => handlePagination('next')}
                  disabled={currentPage * pageLimit >= totalDataLength}
                  borderRadius="0px 5px 5px 0px"
                  bgColor="#fff"
                  borderColor="#8493A6"
                  margin="0px"
                  padding="0px"
                  height="28px"
                  width="40.5px"
                >
                  {/* <FontAwesomeIcon
                    icon={faCaretRight}
                    size="1.5x"
                    // style={{ marginRight: '5px' }}
                    color="#000"
                  /> */}
                  <img
                    alt="share"
                    src="/assets/images/pr-right.svg"
                    style={{ marginTop: '5px' }}
                  />
                </Button>
              </Flex>
            )}
          </Flex>
        </TopicsParent>
        <Flex style={{ marginBottom: '15px', marginTop: '5px' }}>
          <LoaderWrapper isLoading={clickLoader}>
            <Button
              id
              borderRadius="5px"
              onClick={handleDone}
              padding="7px 75px"
              disabled={doneButtonState}
            >
              I'm Done!
            </Button>
          </LoaderWrapper>
        </Flex>
        <CardSubtitle
          style={{ marginBottom: '20px', color: '#000000', fontWeight: '600' }}
        >
          After you have spent 20 minutes or more reading and rating articles,
          <br />
          you can click "I'm Done!" to complete the task.
        </CardSubtitle>
      </CreateContainer>
    </Flex>
  );
};

export default ArticlesPage;
