import { handleActions } from 'redux-actions';
import { apiModuleDefaultState, createApiActions } from './helpers';

export const actions = createApiActions('complianceTrail');

const reducer = handleActions(
  {
    [actions.api.complianceTrail.request]: () => ({
      isFetching: true,
      isError: false,
    }),
    [actions.api.complianceTrail.response]: (_, { payload }) => ({
      isFetching: false,
      isError: false,
      data: payload,
    }),
    [actions.api.complianceTrail.error]: (_, { payload }) => ({
      isFetching: false,
      isError: true,
      error: payload,
    }),
  },
  { ...apiModuleDefaultState, data: [], error: null }
);

export default reducer;
