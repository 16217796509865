import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Flex } from '@rebass/grid';
import React from 'react';
import ReactModal from 'react-modal';
import { Button, ButtonNoBorderBackground, ModalCloseButton } from '../../../../components';
import CustomizeCampaign from '../../../AutomatedCampaigns/CampaignCreateSteps/CustomizeCampaign';

const PreviewModal = ({
  campaignId,
  isOpen,
  handleClose,
  campaignAction,
  campaignSetState,
  automatedCampaignDetails,
  footer,
  modalWidth,
  setCurrentStepIndex,
  currentStepIndex
}) => {
  console.log('Modal --------')
  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={handleClose}
      style={{
        content: {
          height: '600px',
          ...!footer && {
            overflowY: 'scroll',
          }, 
          padding: '15px',
          width: modalWidth || '500px',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        },
      }}
    >
      <Flex justifyContent={'center'}>
        <p>Preview</p>
        <ModalCloseButton onClick={handleClose} />
      </Flex>

      {footer ? (
        <Flex height="80%" style={{ overflowY: 'auto' }}>
          <CustomizeCampaign
            width="100%"
            forDashboardPreview={true}
            campaignAction={campaignAction}
            campaignSetState={campaignSetState}
            campaignId={campaignId}
            automatedCampaignDetails={automatedCampaignDetails}
          />
        </Flex>
      ) : (
        <CustomizeCampaign
          width="100%"
          forDashboardPreview={true}
          campaignAction={campaignAction}
          campaignSetState={campaignSetState}
          campaignId={campaignId}
          automatedCampaignDetails={automatedCampaignDetails}
        />
      )}
  
      {footer && (
        <Flex backgroundColor="#fff" style={{ position: 'fixed', bottom: '5px', left: '40%' }} justifyContent="space-between">
          <Flex>
            <Button
              styles={{ borderRadius: '4px' }}
              color="#fff"
              bgColor="#4E6780"
              margin="0px 10px"
              onClick={() => setCurrentStepIndex(currentStepIndex + 1)}
              id="btn_campaign_next"
            >
              Next
            </Button>
            
          </Flex>
        </Flex>
      )}


    </ReactModal>
  );
};

export default PreviewModal;
