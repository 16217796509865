import { Flex } from '@rebass/grid';
import React from 'react';
import styled from 'styled-components';

const ChipStyles = styled(Flex)`
display: flex;
padding: ${({ padding }) => padding || '6px 12px'};
width: ${({ width }) => width || 'max-content'};
flex-direction: row;
height: ${({ height}) => height || 'fit-content'};
margin: ${({ margin }) => margin || '0px 15px 15px 0px'};
border: 1px solid;
border-color: ${({ borderColor }) => borderColor || '#B6CFF3'};
box-shadow: ${({ boxShadow }) => boxShadow || '0px 1px 2px 0px #1018281F'};
border-radius: ${({ borderRadius }) => borderRadius || '20px'};
background-color: ${({ bgColor }) => bgColor || '#ECF2FC'};

`;

const TopicChip = ({
    children,
    bgColor,
    borderColor,
    borderRadius,
    height,
    width,
    margin,
    boxShadow
}) => {
  return (
    <ChipStyles bgColor={bgColor} borderColor={borderColor} borderRadius={borderRadius} width={width} height={height} margin={margin} boxShadow={boxShadow}>
        {children}
    </ChipStyles>
  )
};

export default TopicChip;