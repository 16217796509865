import React, { useState } from 'react';
import { Flex } from '@rebass/grid';
import {
  PublisherCardWrapper,
  StyledCross,
  StyledTitle,
  StyledTitleDiv,
} from '../ManagePublisher/styles';
import { LoaderWrapper } from '../../../components';
import { Tooltip, Zoom } from '@mui/material';

const MostSubscribedTopic = ({
  loader,
  setLoader,
  subscribe,
  popularTopics,
}) => {
  return (
    <Flex
      display="flex"
      width="30%"
      height="240px"
      padding="0px 0px 10px 0px"
      flexDirection="column"
      style={{
        border: '1px solid #e4e7ec',
        boxShadow: '0px 1px 2px 0px #1018281F',
        borderRadius: '4px',
      }}
    >
      <Flex
        display="flex"
        justifyContent="flex-start"
        padding="10px"
        style={{ color: '#101828', fontSize: '16px', alignItems: 'center' }}
      >
        <img
          alt=""
          src="/assets/images/most-subs-icon.svg"
          style={{
            alignItems: 'center',
            padding: '3px',
            marginRight: '5px',
          }}
        />
        Most Subscribed
      </Flex>
      {popularTopics.length > 0 ? (
        <PublisherCardWrapper
          style={{
            flexWrap: 'wrap',
            justifyContent: 'start',
            padding: '3px 0px',
            maxHeight: '250px',
          }}
        >
          {popularTopics.map((topic) => (
            <>
              <Flex width="100%" padding="10px" height="45px">
                <StyledTitleDiv
                  style={{
                    width: '80%',
                    alignItems: 'center',
                    fontSize: '14px',
                    padding: '5px 27px',
                  }}
                >
                  <LoaderWrapper isLoading={loader === topic.name}>
                    <StyledTitle style={{ color: '#000000', fontSize: '14px' }}>
                      {topic?.name && topic?.name?.length > 18 ? (
                          <Tooltip
                            TransitionComponent={Zoom}
                            arrow
                            placement="top"
                            title={topic.name}
                            componentsProps={{
                              tooltip: {
                                sx: {
                                  fontFamily: 'Poppins',
                                },
                              },
                            }}
                          >
                            {`${topic?.name.substring(0, 18)}...`}
                          </Tooltip>
                        ) : (
                          topic?.name
                        )}
                    </StyledTitle>
                  </LoaderWrapper>
                </StyledTitleDiv>
                {!loader ? (
                  <StyledCross
                    style={{ width: '20%', justifyContent: 'center', cursor: 'pointer' }}
                    onClick={() => {
                      setLoader(topic.name);
                      subscribe(topic);
                    }}
                  >
                    <img alt="" src="/assets/images/grey-plus-icon.svg" />
                  </StyledCross>
                ) : null}
              </Flex>
              <hr
                style={{
                  border: '0.5px solid #e4e7ec',
                  width: '85%',
                  marginRight: '7%',
                  marginTop: '0px',
                  marginBottom: '0px',
                }}
              />
            </>
          ))}
        </PublisherCardWrapper>
      ) : (
        'No Topics found'
      )}
    </Flex>
  );
};

export default MostSubscribedTopic;
