import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const IconBlock = styled('div')`
    background: #FFFFFF;
    border: 1px solid #E4E7EC;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.12);
    border-radius: 8px;
    width: 23%;
    min-width: 230px;
    margin: 30px 10px 0 0;
    padding: 10px 0 0;
`;

export const LongIcon = styled('div')`
    background: #FFFFFF;
    border: 1px solid #E4E7EC;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.12);
    border-radius: 8px;
    display: flex;
    width: 49%;
    margin: 10px 0 0;
    padding: 15px;
    justify-content: space-between;
`;

export const PurpleCircle = styled('div')`
    border-radius: 50%;
    padding: 8px;
    background: #816EF8;
    height: 50px;
    width: 50px;
    margin: 30px auto 33px;
`;

export const IconContainer = styled('div')`
  margin: 0px auto 10px;
  display: flex;
  justify-content: space-between;
  
`;

export const IntegrationIcon = styled('img')`
  width: 40px;
  height: 40px;
  margin: 20px 10px 0 20px;
`;

export const ImportedIcon = styled(FontAwesomeIcon)`
    width: 50px !important;
    height: 50px;
    margin: 30px auto 20px;
    border-radius: 50%;
    background: #816EF8;
    color: white;
    padding: 10px;
`;

export const ConnectButton = styled('button')`
  background: #FFFFFF;
  border: 1px solid #E4E7EC;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.12);
  border-radius: 4px;
  cursor: pointer;
  width: 90%;
  display: flex;
  justify-content: center;
  margin: 10px auto;
  text-transform: none;
  padding: 8px 0;
  text-align: center;
  transition: 0.5s;
  :hover {
    opacity: 0.7;
  }
`;