import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { navItems, reInitializeState } from './utils';
import { HeaderContainer, MenuContainer, Profile } from './styles';
import { actions } from 'src/redux/modules/campaign.module';
import CreateCampaignModal from 'src/containers/DripCampaignContainerV2/DripDashboardTable/CreateCampaignModalsContainer/CreateCampaignModal';
import { connect } from 'react-redux';
import ProfileIcon from 'src/assets/icons/navIcons/ProfileIcon';
import CustomPopover from './CustomPopover';
import { actions as enterpriseActions } from 'src/redux/modules/enterprise.module';

function Navbar({
  customLogoURL = '',
  userRole = 'ADVISOR',
  profileItems = { children: [{ title: '', action: () => {} }] },
  userDetails,
  campaignSetState,
  clearState
}) {
  const history = useHistory();
  const [openCreateCampaignModal, setOpenCreateCampaignModal] = useState(false);
  const [popoverState, setPopoverState] = useState({
    profile: {
      anchorEl: null,
    },
    customDropDown: {
      anchorEl: null,
    },
  });

  const pathname = history?.location?.pathname ?? '';
  const redirectType = window.sessionStorage.getItem('redirect_type');
  const navItemsList = navItems(userRole === 'ADMIN');
  const open = Boolean(popoverState?.customDropDown?.anchorEl);
  const id = open ? 'user-popover' : undefined;
  const openProfile = Boolean(popoverState?.profile?.anchorEl);
  const profileId = open ? 'profile-popover' : undefined;
  const userName = userDetails?.details?.user?.name;

  const handleMenuSelection = (item) => {
    const isCampaigns = item?.element === 'CAMPAIGNS';
    const isInsights = item?.element === 'ANALYTICS';

    if(!isInsights) {
      clearState();
    }
    if (isCampaigns && !open) {
      reInitializeState(campaignSetState);
    }
    history.push(item?.path ?? '');

    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const handleDropDownItems = (event, item) => {
    event.stopPropagation();

    if (item?.type === 'CREATE_CAMPAIGN') {
      reInitializeState(campaignSetState);
      setOpenCreateCampaignModal(true);
    } else {
      reInitializeState(campaignSetState);
      history.push(item?.path ?? '');
    }
  };

  const handlePopoverToggle = (event, item, type = 'CUSTOM') => {
    const { profile, customDropDown } = popoverState;
    const isCampaigns = item?.element === 'CAMPAIGNS';

    const setProfilePopover = (anchorEl) => {
      setPopoverState((prevState) => ({
        ...prevState,
        profile: {
          anchorEl,
        },
      }));
    };

    const setCustomDropdownPopover = (anchorEl) => {
      setPopoverState((prevState) => ({
        ...prevState,
        customDropDown: {
          anchorEl,
        },
      }));
    };

    switch (type) {
      case 'PROFILE':
        const newProfileAnchor = profile.anchorEl ? null : event.currentTarget;
        setProfilePopover(newProfileAnchor);
        break;

      case 'CUSTOM':
      default:
        const shouldOpenCustomDropdown =
          isCampaigns && !customDropDown.anchorEl;

        const newCustomAnchor = shouldOpenCustomDropdown
          ? event.currentTarget
          : null;
        setCustomDropdownPopover(newCustomAnchor);
        break;
    }
  };

  const getInitials = () => {
    if (userName) {
      let firstInitial = userName?.first ? userName?.first?.[0] : '';
      let lastInitial = userName?.last ? userName?.last?.[0] : '';
      return `${firstInitial}${lastInitial}`;
    } else {
      return <ProfileIcon />;
    }
  };

  const reInitializePopover = {
    profile: {
      anchorEl: null,
    },
    customDropDown: {
      anchorEl: null,
    },
  };

  return (
    <HeaderContainer
      container
      justifyContent="space-between"
      gap={2}
      alignItems="center"
      padding="0 32px 10px"
    >
      <Grid item width="fit-content" container alignItems="center">
        <img
          className="logo"
          src={customLogoURL || '/assets/images/clout-logos/MA-logo.png'}
          alt="mio-logo"
          onClick={() => {
            redirectType !== 'nurture' && history.push('/');
          }}
        />
      </Grid>
      <Grid item xs container gap={2} justifyContent="center">
        {Array.isArray(navItemsList) &&
          navItemsList?.length > 0 &&
          navItemsList?.map((item) => (
            <MenuContainer
              id={id}
              key={item?.title}
              isActive={item?.path === pathname}
              onClick={() => handleMenuSelection(item)}
              onMouseEnter={(event) => {
                handlePopoverToggle(event, item);
              }}
              onMouseLeave={() => {
                setPopoverState(reInitializePopover);
              }}
            >
              {item?.title}

              {item?.children && (
                <CustomPopover
                  open={open}
                  id={id}
                  anchorEl={popoverState?.customDropDown?.anchorEl}
                  items={item?.children ?? []}
                  handlePopoverClose={() =>
                    setPopoverState(reInitializePopover)
                  }
                  handleClick={handleDropDownItems}
                  anchorHorizontalPosition="center"
                />
              )}
            </MenuContainer>
          ))}
      </Grid>
      {redirectType !== 'nurture' && (
        <Grid
          item
          width="fit-content"
          container
          alignItems="center"
          onMouseEnter={(event) => handlePopoverToggle(event, null, 'PROFILE')}
          onMouseLeave={() => setPopoverState(reInitializePopover)}
        >
          <Profile>{getInitials()}</Profile>
          <CustomPopover
            id={profileId}
            open={openProfile}
            anchorEl={popoverState?.profile?.anchorEl}
            items={profileItems?.children ?? []}
            handlePopoverClose={() => setPopoverState(reInitializePopover)}
          />
        </Grid>
      )}

      {openCreateCampaignModal && (
        <CreateCampaignModal
          isOpen={openCreateCampaignModal}
          handleClose={() => {
            setOpenCreateCampaignModal(false);
          }}
          campaignSetState={campaignSetState}
        />
      )}
    </HeaderContainer>
  );
}

const mapStateToProps = (state) => {
  return {
    userDetails: state.user.data,
  };
};
const mapDispatchToProps = {
  campaignSetState: actions?.api?.campaign?.setstate,
  clearState: enterpriseActions.clearState
};

export default connect(mapStateToProps, mapDispatchToProps)(Navbar);
