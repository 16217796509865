import React from 'react';
import {
  Container,
  UL,
  SecondaryUL,
  TertiaryUL,
  Link,
  PrimaryHeader,
  PrimaryItem,
  Input,
  Label,
  SecondaryItem,
  TertiaryItem,
  SecondaryLink,
  TertiaryLink,
} from './Accordion.styles';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';

const Accordion = ({ list = [], handleListItemClick }) => (
  <Container  screenWidth={window.innerWidth}>
    <UL>
      {list
        .filter((item) => parseInt(item.parent_id) === 0)
        .map((primaryItem, index) => (
          <PrimaryItem id={primaryItem.id} key={index}>
            <Input type="checkbox" id={primaryItem.id + 'input'} />
            <Label htmlFor={primaryItem.id + 'input'}>
              <PrimaryHeader>
                <Link>
                  {primaryItem.name}
                  <FontAwesomeIcon icon={faCaretDown} color='#c95cfc' size="1x" />
                </Link>
              </PrimaryHeader>{' '}
            </Label>
            <SecondaryUL>
              {list
                .filter((item) => item.parent_id === primaryItem.id)
                .map((secondaryItem, index) => (
                  <SecondaryItem id={secondaryItem.id} key={index}>
                    <Input type="checkbox" id={secondaryItem.id + 'input'} />
                    <Label htmlFor={secondaryItem.id + 'input'}>
                      <SecondaryLink>
                        {secondaryItem.name}
                        <FontAwesomeIcon icon={faCaretDown} color='#c95cfc' size="1x" />
                      </SecondaryLink>
                    </Label>
                    <TertiaryUL>
                      {list
                        .filter((item) => item.parent_id === secondaryItem.id)
                        .map((tertiaryItem, index) => (
                          <TertiaryItem
                            id={tertiaryItem.id}
                            key={index}
                            onClick={() => handleListItemClick(tertiaryItem)}
                          >
                            <TertiaryLink title={tertiaryItem.name}>{tertiaryItem.name}</TertiaryLink>
                          </TertiaryItem>
                        ))}
                    </TertiaryUL>
                  </SecondaryItem>
                ))}
            </SecondaryUL>
          </PrimaryItem>
        ))}
    </UL>
  </Container>
);

export default Accordion;
