/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import {
  LoaderWrapper,
} from '../../../components';
import GeneralTable from '../../../components/GeneralTable';
import {
  getContactSublistForCrm,
  updateContactSubListForCrm,
  updateSFContactSubListForCrm,
} from '../../../services/configure';
import { 
  AddButton, 
  RadioLabel,
  RadioStyle,
} from '../EmailList/EmailList.styles';
import { get } from 'lodash';
import {
  ModalBody,
} from '../../../components';
import { Flex } from '@rebass/grid';
import { ColorPallete } from 'src/utils';

const ContactListModal = ({
  contactOpen,
  setContactOpen,
  selectedNetwork,
  setSelectedNetwork,
  setUploadType,
  closeModal,
  setIntegrationsHeader
}) => {
  const [subList, setSubList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [onSave, setOnSave] = useState(false);
  const [saleforceView, setSalesforceView] = useState(false);
  const [sfImportType, setSfImportType] = useState('account');

  useEffect(() => {
    if (selectedNetwork && selectedNetwork.length) {
      if(selectedNetwork === 'sf') {
        setSalesforceView(true);
        setIntegrationsHeader('Select contact lists by the following type')
      } else {
        setLoading(true);
        fetchContactSubList()
      }
    }
  }, [selectedNetwork]);

  const clearState = () => {
      setSelectedNetwork('');
      setSalesforceView(false);
      window.history.replaceState(
        '',
        '',
        window.location.protocol +
          '//' +
          window.location.host +
          window.location.pathname
      );
      setSubList([]);
      setUploadType('');
      setContactOpen(false);
      setMessage('');
      window.sessionStorage.removeItem('snetwork');
  }
  const fetchContactSubList = async() => {
    let subCrmLists = [],
      selectAll = [];

    try{
      const res = await getContactSublistForCrm(selectedNetwork)
      if (res.result.success) {
        const subLists = get(res.result.data[0], selectedNetwork, {});
        if (Object.keys(subLists).length > 0) {
          Object.keys(subLists).forEach((id) => {
            subCrmLists.push({
              name: subLists[id].name,
              checked: subLists[id].in_system === 'true' ? true : false,
              id: id,
              type:
                subLists[id].contact_type === 'prospect' ||
                subLists[id].contact_type === 'client'
                  ? subLists[id].contact_type
                  : 'prospect',
            });
          });
          if (
            subCrmLists.filter((s) => s.checked).length ===
            subCrmLists.length
          ) {
            selectAll.push({
              id: 'selectAll',
              name: 'Select All',
              checked: true,
            });
          } else {
            selectAll.push({
              id: 'selectAll',
              name: 'Select All',
              checked: false,
            });
          }
          setLoading(false);
          setSubList([...selectAll, ...subCrmLists]);
        } else {
          setLoading(false);
          setMessage('No List Found');
        }
      } else {
        setLoading(false);
        setMessage('Unable to fetch records');
      }
    }catch(err){
      console.log('getContactSublistForCrm',err)
      setMessage('Unable to fetch records');
    }
  }

  const handleSubmit = async() => {
    setOnSave(true);
    let finalSubList = {};
    let finalSubListTypes = {};
    const checkedSubList = subList.filter(
      (s) => s.id !== 'selectAll' && s.checked
    );
    for (let i = 0; i < checkedSubList.length; i++) {
      finalSubList[checkedSubList[i].id] = checkedSubList[i].name;
    }

    for (let i = 0; i < checkedSubList.length; i++) {
      finalSubListTypes[checkedSubList[i].id] = checkedSubList[i].type;
    }

    let payload = {
      crmName: selectedNetwork,
      listId: finalSubList,
      contact_types: finalSubListTypes,
    };

    if(payload.crmName === 'sf') {
      payload.sf_import_type = sfImportType;
    }
   
    try{
      const response = payload.crmName === 'sf' ? await updateSFContactSubListForCrm(payload) :  await updateContactSubListForCrm(payload)
      if (response.result.success) {
        if(payload.crmName === 'sf') {
          setMessage('Contacts sync process initiated, please check back after [5] mins');
        }
        setTimeout(function () {
          setOnSave(false);
          clearState();
          closeModal();
          window.location.reload();
        }, 5000);
      } else {
        setOnSave(false);
        setMessage('Oops! Something went wrong');
      }
    }catch(err){
      console.log('updateContactSubListForCrm err:',err)
      setMessage('Oops! Something went wrong');
    }
  };

  const handleTypeChange = (id, mode) => {
    const itemState = subList.find((obj) => {
      return obj.id === id;
    });
    itemState.type = mode;
    console.log('item', itemState);
    setSubList([...subList]);
  };

  const handleSaleforceAddClick = () => {
    setLoading(true);
    let subCrmLists = [],
    selectAll = [];
    getContactSublistForCrm('sf', sfImportType)
      .then((res) => {
        if (res.result.success) {
          const subLists = get(res.result.data[0], selectedNetwork, {});
          if (Object.keys(subLists).length > 0) {
            Object.keys(subLists).forEach((id) => {
              subCrmLists.push({
                name: subLists[id].name,
                checked: subLists[id].in_system === 'true' ? true : false,
                id: id,
                type:
                  subLists[id].contact_type === 'prospect' ||
                    subLists[id].contact_type === 'client'
                    ? subLists[id].contact_type
                    : 'prospect',
              });
            });
            if (
              subCrmLists.filter((s) => s.checked).length ===
              subCrmLists.length
            ) {
              selectAll.push({
                id: 'selectAll',
                name: 'Select All',
                checked: true,
              });
            } else {
              selectAll.push({
                id: 'selectAll',
                name: 'Select All',
                checked: false,
              });
            }
            setLoading(false);
            setSubList([...selectAll, ...subCrmLists]);
            setSalesforceView(false);
          } else {
            setLoading(false);
            setSalesforceView(false);
            setMessage('No List Found');
          }
        } else {
          setLoading(false);
          setSalesforceView(false);
          setMessage('Unable to fetch records');
        }
      })
      .catch((err) => {
        setLoading(false);
        setSalesforceView(false);
        setMessage('Unable to fetch records');
      });
  }

  const handleChange = (e, data) => {
    if (data.name === 'Select All' && !data.checked) {
      subList.forEach((s) => {
        s.checked = true;
      });
      setSubList([...subList]);
    } else if (data.name === 'Select All' && data.checked) {
      subList.forEach((s) => {
        s.checked = false;
      });
      setSubList([...subList]);
    } else {
      if (data.checked) {
        subList.forEach((l) => {
          if (l.id === data.id) {
            l.checked = false;
          }
          if (l.id === 'selectAll' && l.checked) {
            l.checked = false;
          }
        });
        setSubList([...subList]);
      } else {
        subList.forEach((l) => {
          if (l.id === data.id) {
            l.checked = true;
          }
        });
        if (subList.filter((s) => s.checked).length === subList.length - 1) {
          subList.forEach((l) => {
            if (l.id === 'selectAll' && !l.checked) {
              l.checked = true;
            }
          });
        }
        setSubList([...subList]);
      }
    }
  };

  console.log('sublist', subList)

  const headerData = [
    {
      type: 'checkbox',
      key: '',
      sortKey: '',
    },
    {
      header: 'Contact List Name',
      key: 'name',
    },
    {
      header: 'Type',
      formatData: (row, index) => {
        if(index > 0) {
          return(
            <div style={{display: 'flex', justifyContent: 'start', margin: '0px auto'}} >
              <RadioLabel value="Prospect" control={<RadioStyle 
                onClick={() => handleTypeChange(row.id, 'prospect')} 
                checked={row.type === 'prospect'} 
                id={`enterprise_radio_on`}
              />} label="Prospect" />
              <RadioLabel value="Client" control={<RadioStyle 
                id={`enterprise_radio_off`}
                checked={row.type === 'client'}
                onClick={() => handleTypeChange(row.id, 'client')}
              />} label="Client" />
            </div>
          )
        }
      },
      key: 'actions',
    },
  ];

  const salesforceDisplay = () => {
    return(
      <Flex
        width="100%"
        pt="8px"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <Flex justifyContent='space-between' width="57%">
          <RadioLabel value="Account Level" control={<RadioStyle 
            onClick={() => setSfImportType('account')}
            checked={sfImportType === 'account'} 
            id={`sf_account`}
          />} label="Account Level" />
          <RadioLabel value="Campaign Level" control={<RadioStyle 
            id={`sf_campaign`}
            checked={sfImportType === 'campaign'}
            onClick={() => setSfImportType('campaign')}
          />} label="Campaign Level" />
        </Flex>
        <Flex justifyContent="center">
          <LoaderWrapper isLoading={loading}>
            <AddButton style={{height: 40, margin: '40px 0 -10px'}} onClick={handleSaleforceAddClick}>Add</AddButton>
          </LoaderWrapper>
        </Flex>
      </Flex>
    )
  };

  return (
    <div style={{width: 650}}>
      <ModalBody>
        {saleforceView ? (
          salesforceDisplay()
        ) : (
          <Flex
            width="100%"
            pt="8px"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
          <LoaderWrapper isLoading={loading}>
            <div style={{maxHeight: 250, overflowY: 'auto'}}>
              <div style={{width: message ? '100%' : '95%', margin: '0px auto 20px'}}>
                {message ? (
                  <div style={{ fontSize: '16px', color: ColorPallete.primaryBlue }}>{message}</div>
                ) : (
                  <GeneralTable 
                    tableData={subList}
                    headerData={headerData}
                    checkBoxClick={handleChange}
                  />
                )}
              </div>
            </div>
          </LoaderWrapper>
         
          {message ? null : (
            <LoaderWrapper isLoading={onSave}>
              <AddButton
                onClick={handleSubmit}
                style={{height: 40, margin: '10px auto 0'}}
                disabled={
                  subList.filter((s) => s.checked).length > 0 ? false : true
                }
              >
                Add
              </AddButton>
            </LoaderWrapper>
          )}
            </Flex>
        )}
        
      </ModalBody>
    </div>
  );
};

export default ContactListModal;

