import React from 'react';
import { Flex } from '@rebass/grid';
import {
    Button,
    Input,
    LoaderWrapper,
    ErrorContainer,
    ModalHeader,
    ModalCloseButton,
    ModalV2,
    ModalBody,
} from '../../../components'
import { get, isEmpty } from 'lodash';
import { connect } from 'react-redux';


const SendTestMailModal = ({
    isOpen,
    handleModalClose,
    subject,
    handleChange,
    value,
    modalButtonOnClick,
    validation,
    sendTestErrorMsg,
    loading,
    detailsLoading,
    emailPlatformsData
}) => {

    const emailPlatforms = {
        outlook: 'Outlook',
        sg: 'SendGrid',
        google: 'Gmail',
        smtp: 'smtp',
        none: 'No Email Platforms are Connected, Please Connect'
    }

    return (

        <ModalV2
            isOpen={isOpen}
            onRequestClose={handleModalClose}
            bgColor="#fff"
            style={{
                content: {
                    height: 'fit-content',
                    padding: '15px 35px',
                    width: '45%',
                    top: '20%',
                    left: '25%',
                    transform: 'translate(-50%, -50%)',
                },
            }}
            ariaHideApp={false}
        >

            <ModalHeader style={{ fontSize: '15px' }}>
                <p>Send a test email</p>
                <p>Send this email as a test to up to 5 recipients.</p>
                <ModalCloseButton onClick={handleModalClose} />
            </ModalHeader>

            <LoaderWrapper isLoading={detailsLoading}>
                <ModalBody>
                    <p style={{textAlign: 'left'}}>Subject: TEST - ({subject})</p>
                    <Input
                        id="email"
                        name="email"
                        placeholder="Type in email address(es), separated by commas"
                        onChange={handleChange}
                        value={value}
                    />

                    <Flex mt="15px" color="#101828">
                        Email Account Connected:  <Flex ml="5px">{!isEmpty(emailPlatformsData) && emailPlatformsData[0].email} {isEmpty(emailPlatformsData) ? `${emailPlatforms['none']}` : `(${emailPlatforms[emailPlatformsData[0].snetworkProvider]})`}</Flex>  
                    </Flex>    

                    <LoaderWrapper isLoading={loading}>
                        <Flex width='100%' margin='10px 0px' justifyContent='space-between' alignItems='center'>

                            <Button width="45%"
                                padding="5px 10px"
                                height="40px"
                                bgColor="#fff"
                                color="#101828"
                                borderColor="#e4e7ec" onClick={handleModalClose}
                            >
                                Cancel
                            </Button>

                            <Button width="45%"
                                padding="5px 10px"
                                height="40px"
                                bgColor="#816EF8"
                                color="#fff"
                                borderColor="#e4e7ec"
                                disabled={validation || isEmpty(emailPlatformsData)}
                                onClick={modalButtonOnClick}
                            >
                                Confirm
                            </Button>
                        </Flex>
                    </LoaderWrapper>
                    
                    {sendTestErrorMsg && <ErrorContainer align='center'>
                        {sendTestErrorMsg}
                    </ErrorContainer>}
                </ModalBody>

            </LoaderWrapper>
            
        </ModalV2>
    );
};

const mapStateToProps = state => {
    return {
        emailPlatformsData: get(state, 'socialNetwork.data.em', [])
    }
}

export default connect(mapStateToProps, null)(SendTestMailModal);
