import React, { useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';

import useRESTQuery from 'src/hooks/useRESTQuery';

import withDashboardLayout from 'src/hoc/withDashboardLayout';
import { ErrorDisplay } from 'src/componentsV2/ErrorDisplay';
import BreadCrumbs from './common/BreadCrumbs';
import CampaignSummary from './CampaignSummary';
import { transformSummaryData } from './CampaignSummary/utils';
import Skeleton from './Skeleton';
import SubCampaignList from './SubCampaignList';
import CampaignInsights from './CampaignInsights';

import { CampaignOverviewWrapper } from './styles';
import { generateBreadcrumbItems } from './utils';

function CampaignOverview() {
  const { id } = useParams();
  const { search,state } = useLocation();
  const queryParams = new URLSearchParams(search);
  const type = queryParams.get('type');

  const [selectedSubCampaign, setSelectedSubCampaign] = useState({});

  const { data, isLoading, isError, refetch } = useRESTQuery({
    key: 'campaign-overview',
    endpoint: `/analytics/detailed/campaign/${id}/summary?campaign_type=${type}`,
    dependencies: [id, type],
    options: {
      select: (res) => {
        if (res?.result) return transformSummaryData(res?.result ?? {});
        else return {};
      },
      retry: 1,
    },
  });

  if (isLoading) return <Skeleton />;
  if (isError) return <ErrorDisplay height="70vh" onClick={refetch} />;

  return (
    <CampaignOverviewWrapper>
      <div className="campaign-detail-section">
        <BreadCrumbs breadCrumbItems={generateBreadcrumbItems(state?.from,data?.campaignName ?? '')} />
        <CampaignSummary
          campaignInfo={data}
          selectedSubCampaign={selectedSubCampaign}
        />
        <div className="campaign-insights-container">
          <SubCampaignList
            selectedSubCampaign={selectedSubCampaign}
            setSelectedSubCampaign={setSelectedSubCampaign}
          />
          <CampaignInsights
            selectedSubCampaign={selectedSubCampaign}
            campaignInfo={data}
          />
        </div>
      </div>
    </CampaignOverviewWrapper>
  );
}

export default withDashboardLayout(CampaignOverview);
