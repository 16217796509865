import styled from 'styled-components';

export const SidebarWrapper = styled.div`
	background-color: #fff;
	position: fixed;
	height: 93vh;
	top: 61px;
	left: 20%;
	bottom: 0%;
	right: 0%;
	z-index: 10;
`;

export const SidebarUL = styled.ul`
	list-style: none;
	margin: 0;
	padding: 0;
`;

export const SidebarLI = styled.li`
	 display: flex;
	 border-bottom: 1px solid #ddcdfd;
   a {
		 text-decoration: none;
		 padding: 14px 10px 8px;
		 width: 100%;
		 text-align: left;
	 } 
	 i {
    display: inline-block;
    width: 35px;
    font-size: 16px;
    img {
      width: 18px;
      height: 18px;
    }
  }
`;

export const SidebarSubMenu = styled.ul`
	margin: 0;
	padding: 0;
	list-style: none;
	box-shadow: 0px 1px 2px #ddd;
	-webkit-box-shadow: 0px 1px 2px #ddd;
	li {
		background: #e4e4e4;
		border-bottom: 1px solid #ddd;
		border-right: 1px solid #ddd;
		border-left: 1px solid #ddd;
		border-top: none;
		padding: 10px 5px;
		cursor: pointer;

		transition: all 0.2s;
		width: 100%;
		a {
			display: block;
			width: 100%;
			height: 100%;
			font-size: 12px;
			padding: 0px;
			border: 0px;
			justify-content: center;
			text-decoration: none;
			text-align: left;
		}
		&:hover {
			background: #6351ed;
			a {
				color: #fff;
				font-size: 12px;
			}
		}
	}
`;


