export const METRICS = [
  {
    id: 1,
    name: 'Open Rate',
    dataKey: 'open_rate',
  },
  {
    id: 2,
    name: 'Total Unique Clicks',
    dataKey: 'total_clicks',
  },
  {
    id: 3,
    name: 'Email Sent',
    dataKey: 'email_sent',
  },
  {
    id: 4,
    name: 'Social Posts',
    dataKey: 'social_posts',
  },
];
