import { Flex } from '@rebass/grid';
import React, { useState, useEffect } from 'react';
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalHeader,
  ModalFooter,
  LoaderWrapper,
} from '..';
import { onImageError, themesData } from '../../utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar, faTimes } from '@fortawesome/free-solid-svg-icons';
import { ArticleModalFooter } from './Survey.styles';
import { replace } from 'lodash';

const ArticleDetailPopup = ({
  handleClose,
  articleId,
  topic,
  title,
  desc,
  image,
  likedArticles,
  setLikedArticles,
  dislikedArticles,
  setDislikedArticles,
  articleLikedAction,
  articleDislikedAction,
}) => {
  const [clickLoader, setClickLoader] = useState(false);

  const handleLike = () => {
    setClickLoader(true);

    let newLikedArray = [...likedArticles, articleId];
    setLikedArticles(newLikedArray);

    let newDislikedArray = dislikedArticles.filter(
      (item) => item !== articleId
    );
    setDislikedArticles(newDislikedArray);

    articleLikedAction({ articleId: articleId })
      .then((res) => {
        // console.log('articleLikedAction', res);
        setClickLoader(false);
        handleClose();
      })
      .catch((err) => {
        console.log('articleLikedAction', err);
        setClickLoader(false);
        handleClose();
      });
  };

  const handleDislike = () => {
    setClickLoader(true);

    let newDislikedArray = [...dislikedArticles, articleId];
    setDislikedArticles(newDislikedArray);

    let newLikedArray = likedArticles.filter((item) => item !== articleId);
    setLikedArticles(newLikedArray);

    articleDislikedAction({ articleId: articleId })
      .then((res) => {
        // console.log('articleDislikedAction', res);
        setClickLoader(false);
        handleClose();
      })
      .catch((err) => {
        console.log('articleDislikedAction', err);
        setClickLoader(false);
        handleClose();
      });
  };

  return (
    <Modal
      style={{
        overlay: {
          backgroundColor: 'rgb(0,0,0,0.5)',
        },
      }}
      bgColor="white"
      height="80%"
      size="medium"
      onRequestClose={handleClose}
      shouldCloseOnOverlayClick={false}
      isOpen
    >
      <ModalHeader
        id="header"
        style={{ textAlign: 'center', color: themesData.charcoalGrey }}
      >
        {/* <Flex fontSize="0.9rem" color="black" style={{ fontWeight: '600' }}>
          Select Content
        </Flex> */}
        <ModalCloseButton
          onClick={handleClose}
          size="17px"
          style={{ position: 'relative', top: '-4px' }}
        />
        {/* <hr style={{ width: '100%' }} /> */}
      </ModalHeader>
      <ModalBody
        style={{
          overflowY: 'auto',
          height: 'inherit',
        }}
        padding="5px ​!important"
      >
        <Flex
          width="100%"
          style={{
            fontSize: '20px',
            color: '#033D51',
            fontWeight: '600',
            textAlign: 'left',
            padding: '13px',
          }}
          mb="10px"
          flexDirection="column"
          justifyContent="flex-start"
          alignItems="flex-start"
        >
          {title}
          <img
            alt="share"
            src={image}
            width="400px"
            height="215px"
            onError={onImageError}
            style={{
              marginTop: '10px',
              objectFit: 'cover',
            }}
          />
        </Flex>

        <Flex
          width="100%"
          style={{
            fontSize: '12px',
            color: '#75899D',
            fontWeight: '400',
            textAlign: 'left',
            whiteSpace: 'pre-wrap',
            padding: '13px',
          }}
          flexDirection="column"
          justifyContent="flex-start"
          alignItems="flex-start"
        >
          {desc}
        </Flex>
      </ModalBody>
      <ArticleModalFooter>
        <Flex
          width="100%"
          style={{ fontSize: '12px', color: '#000', fontWeight: '800' }}
          mb="10px"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          Did you find this article particularly interesting, helpful,
          educational, or you simply enjoyed reading it?
        </Flex>
        <Flex justifyContent="center" py="10px">
          <LoaderWrapper isLoading={clickLoader}>
            <Button
              testId="article_liked"
              styles={{ borderRadius: '4px' }}
              color="#fff"
              bgColor="#6351ed"
              margin="0px 10px"
              padding="7px 40px"
              borderColor="#6351ed"
              onClick={() => {
                // articleLikedAction({ articleId: articleId });
                // handleClose(true);
                handleLike();
              }}
            >
              <FontAwesomeIcon
                icon={faStar}
                size="0.5x"
                style={{ marginRight: '5px' }}
              />
              Yes! I Liked It!
            </Button>
            <Button
              testId="article_disliked"
              styles={{ borderRadius: '4px' }}
              color="#4e6780"
              bgColor="#eee"
              margin="0px 10px"
              padding="7px 40px"
              borderColor="#4e6780"
              onClick={() => {
                handleDislike();
              }}
            >
              <FontAwesomeIcon
                icon={faTimes}
                size="0.5x"
                style={{ marginRight: '5px', marginTop: '3px' }}
                color="#4e6780"
              />
              Not For Me.
            </Button>
          </LoaderWrapper>
        </Flex>
      </ArticleModalFooter>
    </Modal>
  );
};

export default ArticleDetailPopup;
