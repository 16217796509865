import React, { useState } from 'react';
import { Tooltip } from '@mui/material';
import { format, subDays } from 'date-fns';
import { useHistory } from 'react-router-dom';
import Button from 'src/componentsV2/Button';
import CreateCampaignModal from 'src/containers/DripCampaignContainerV2/DripDashboardTable/CreateCampaignModalsContainer/CreateCampaignModal';
import ZeroState from './ZeroState';
import CampaignCountSkeleton from './CampaignCountSkeleton';
import CampaignMetricsSkeleton from './CampaignMetricsSkeleton';
import InfoCircle from 'src/assets/icons/InfoCircle';
// import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
// import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import useRESTQuery from 'src/hooks/useRESTQuery';
import { reInitializeState } from 'src/componentsV2/Navbar/utils';
import { METRICS } from './constants';
import {
  Content,
  ContentWrapper,
  Count,
  Footer,
  FooterActions,
  InteractionRateCard,
  InteractionRateCardContent,
  // InteractionRateChange,
  InteractionsRates,
  OthersCount,
  VerticalSeparator,
  Wrapper,
} from './styles';

function CampaignCount({
  timePeriod,
  campaignSetState,
  additionalQueryParams,
}) {
  const history = useHistory();
  const [showCreateCampaignDialog, setShowCreateCampaignDialog] =
    useState(false);

  const {
    data: campaignCount,
    isLoading: isCampaignCountLoading,
    isFetching: isCampaignCountBeingFetched,
  } = useRESTQuery({
    key: 'campaign-count',
    dependencies: [timePeriod, additionalQueryParams],
    endpoint: `/campaign/summary/count?from_date=${format(
      subDays(new Date(), timePeriod),
      'yyyy-MM-dd'
    )}${!!additionalQueryParams ? `&${additionalQueryParams}` : ''}`,
    options: {
      refetchOnMount: true,
    },
  });

  const {
    data: campaignMetrics,
    isLoading: isCampaignMetricsLoading,
    isFetching: isCampaignMetricsBeingFetched,
  } = useRESTQuery({
    key: 'campaign-metrics',
    dependencies: [timePeriod, additionalQueryParams],
    endpoint: `/campaign/summary/analytics?from_date=${format(
      subDays(new Date(), timePeriod),
      'yyyy-MM-dd'
    )}${!!additionalQueryParams ? `&${additionalQueryParams}` : ''}`,
    options: {
      select: (res) => {
        return {
          open_rate: res?.open_rate,
          total_clicks: res?.total_clicks,
          email_sent: res?.email_sent,
          social_posts: res?.social_posts,
        };
      },
      refetchOnMount: true,
    },
  });

  const handleCampaignCreation = () => {
    reInitializeState(campaignSetState);
    setShowCreateCampaignDialog(true);
  };

  return (
    <div>
      {!isCampaignCountLoading &&
      !isCampaignMetricsLoading &&
      !isCampaignCountBeingFetched &&
      !isCampaignMetricsBeingFetched &&
      !campaignCount?.all_campaigns ? (
        <ZeroState handleClick={handleCampaignCreation} />
      ) : (
        <Wrapper>
          {isCampaignCountLoading || isCampaignCountBeingFetched ? (
            <CampaignCountSkeleton />
          ) : (
            <ContentWrapper>
              <Content>
                <Count>
                  <h5>{campaignCount?.all_campaigns}</h5>
                  <p>All Campaigns</p>
                </Count>
                <VerticalSeparator />
                <Count>
                  <h5>{campaignCount?.completed}</h5>
                  <p>Completed</p>
                </Count>
                <Count>
                  <h5>{campaignCount?.in_progress}</h5>
                  <p>In Progress</p>
                </Count>
                <Count>
                  <h5>{campaignCount?.scheduled}</h5>
                  <p>Scheduled</p>
                </Count>
                <Count>
                  <h5>{campaignCount?.others}</h5>
                  <OthersCount>
                    <p>Others</p>
                    <Tooltip
                      title="Includes campaigns in draft, pending compliance, etc."
                      arrow
                    >
                      <div className='others-info'>
                        <InfoCircle />
                      </div>
                    </Tooltip>
                  </OthersCount>
                </Count>
              </Content>
            </ContentWrapper>
          )}

          {isCampaignMetricsLoading || isCampaignMetricsBeingFetched ? (
            <CampaignMetricsSkeleton />
          ) : (
            <Footer>
              <InteractionsRates>
                {METRICS.map(({ id, name, dataKey }) => (
                  <InteractionRateCard key={id}>
                    <p>{name ?? '-'}</p>
                    <InteractionRateCardContent>
                      <h6>
                        {campaignMetrics?.[dataKey]?.value ?? '-'}
                        {dataKey === 'open_rate' && '%'}
                      </h6>
                      {/* {+campaignMetrics?.[dataKey]?.change !== 0 && (
                        <InteractionRateChange
                          isPositiveChange={
                            +campaignMetrics?.[dataKey]?.change > 0
                          }
                        >
                          {+campaignMetrics?.[dataKey]?.change > 0 ? (
                            <ArrowDropUpIcon />
                          ) : (
                            <ArrowDropDownIcon />
                          )}

                          <h6>
                            {Math.abs(campaignMetrics?.[dataKey]?.change?.toFixed(0))}{' '}
                            {dataKey === 'open_rate' && '%'}
                          </h6>
                        </InteractionRateChange>
                      )} */}
                    </InteractionRateCardContent>
                  </InteractionRateCard>
                ))}
              </InteractionsRates>
              <FooterActions>
                <Button fullWidth onClick={handleCampaignCreation}>
                  Start New Campaign
                </Button>
                <Button
                  variant="outline"
                  fullWidth
                  onClick={() => history.push('/campaignV2')}
                >
                  View All Campaigns
                </Button>
              </FooterActions>
            </Footer>
          )}
        </Wrapper>
      )}

      {showCreateCampaignDialog && (
        <CreateCampaignModal
          isOpen={showCreateCampaignDialog}
          handleClose={() => {
            setShowCreateCampaignDialog(false);
          }}
          campaignSetState={campaignSetState}
        />
      )}
    </div>
  );
}

export default CampaignCount;
