import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ErrorBoundary } from '@sentry/react';
import Routes from './routes';
import history from './history';
import './App.css';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    },
  },
});

function App() {
  return (
    <div className="App">
      <ErrorBoundary fallback={'An error has occurred'}>
        <QueryClientProvider client={queryClient}>
          <Router history={history}>
            <Routes />
          </Router>
        </QueryClientProvider>
      </ErrorBoundary>
    </div>
  );
}

export default App;
