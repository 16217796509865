import React, { useCallback, useState } from 'react';
import { useMutation } from 'react-query';
import { useForm } from 'react-hook-form';
import { useDropzone } from 'react-dropzone';
import { CircularProgress } from '@mui/material';
import SnackbarComponent from 'src/components/Snackbar';
import Button from 'src/componentsV2/Button';
import File from 'src/assets/icons/File';
import Multiply from 'src/assets/icons/Multiply';
import CloudArrowUp from 'src/assets/icons/CloudArrowUp';
import {
  createPreviewContentFromPDF,
  createPreviewContentFromImage,
} from './mutations';
import {
  ErrorText,
  Form,
  Input,
  InputWithLabel,
  Label,
  SelectedFileContainer,
  SelectedFileContent,
  SelectedFileDescription,
  UploadFileContainer,
  UploadFileContent,
  UploadFileIcon,
} from './styles';

function AddContentFromFile({ channelId, setStep, setContent }) {
  const {
    watch,
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();
  const [file, setFile] = useState(null);
  const [showSnackbar, toggleSnackbar] = useState(false);
  const [error, setError] = useState('');
  const watchValues = watch();

  const { mutate: createContentFromPDF, isLoading: pdfPreviewLoader } =
    useMutation(createPreviewContentFromPDF);
  const { mutate: createContentFromImage, isLoading: imagePreviewLoader } =
    useMutation(createPreviewContentFromImage);

  const onDrop = useCallback((acceptedFiles) => {
    setFile(acceptedFiles?.[0] ?? null);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: {
      'application/pdf': ['.pdf'],
      'image/*': ['.jpeg', '.png'],
    },
    onDrop,
  });

  const handleSuccess = (res, formData) => {
    if (res?.result?.success) {
      setContent({
        ...res?.result?.data,
        text: {
          ...res?.result?.data?.text,
          title: formData?.title,
        },
      });
      setStep(1);
    } else {
      setError(res?.result?.error?.message ?? 'Something went wrong');
      toggleSnackbar(true);
    }
  };

  const handleError = (err) => {
    setError(
      err?.response?.data?.result?.error?.message ?? 'Something went wrong'
    );
    toggleSnackbar(true);
  };

  const handlePreview = (formData) => {
    if (file?.type === 'application/pdf') {
      createContentFromPDF(
        {
          channelId,
          payload: {
            title: formData?.title,
            link: '',
            message: ' ',
            source: 'pdf',
            attachment1: file,
            skip_compliance: false,
          },
        },
        {
          onSuccess: (res) => handleSuccess(res, formData),
          onError: (err) => handleError(err),
        }
      );
    } else {
      createContentFromImage(
        {
          channelId,
          payload: {
            title: formData?.title,
            link: '',
            message: ' ',
            source: 'img',
            media1: file,
            skip_compliance: false,
          },
        },
        {
          onSuccess: (res) => handleSuccess(res, formData),
          onError: (err) => handleError(err),
        }
      );
    }
  };

  return (
    <Form onSubmit={handleSubmit(handlePreview)}>
      <InputWithLabel>
        <Label>
          Title <span className="required">*</span>
        </Label>
        <Input
          placeholder="Enter title here"
          {...register('title', {
            required: {
              value: true,
              message: 'Title is required',
            },
          })}
        />
        {errors && errors?.title && (
          <ErrorText>{errors?.title?.message ?? ''}</ErrorText>
        )}
      </InputWithLabel>

      {!!file ? (
        <SelectedFileContainer>
          <SelectedFileContent>
            <File />
            <SelectedFileDescription>
              <h5>{file?.name ?? '--'}</h5>
              <p>{file?.size ? Math.round(file?.size / 1024) : '--'} KB</p>
            </SelectedFileDescription>
          </SelectedFileContent>
          <div onClick={() => setFile(null)}>
            <Multiply />
          </div>
        </SelectedFileContainer>
      ) : (
        <div {...getRootProps()}>
          <input {...getInputProps()} />
          {isDragActive ? (
            <UploadFileContainer>
              <UploadFileIcon>
                <CloudArrowUp />
              </UploadFileIcon>
              <UploadFileContent>
                <h5>Drop the PDF or Image here</h5>
                <p>PDF or Image only</p>
              </UploadFileContent>
            </UploadFileContainer>
          ) : (
            <UploadFileContainer>
              <UploadFileIcon>
                <CloudArrowUp />
              </UploadFileIcon>
              <UploadFileContent>
                <h5>Click to upload</h5>
                <p>PDF or Image only</p>
              </UploadFileContent>
            </UploadFileContainer>
          )}
        </div>
      )}

      <Button
        type="submit"
        disabled={
          !!!watchValues?.title ||
          !file ||
          imagePreviewLoader ||
          pdfPreviewLoader
        }
        fullWidth
      >
        {imagePreviewLoader || pdfPreviewLoader ? (
          <CircularProgress size={15} />
        ) : (
          'Preview Content'
        )}
      </Button>
      <SnackbarComponent
        open={showSnackbar}
        variant="error"
        handleClose={() => toggleSnackbar(false)}
        content={error}
      />
    </Form>
  );
}

export default AddContentFromFile;
