import React, { useEffect, useState } from 'react';
import { Formik } from 'formik';
import { 
  TextInput,
  TextLabel,
  InfoIcon,
  TextArea,
  SwitchStyle,
  EditRow,
  EditColumn,
  Heading,
  LeadRow,
  LeadRowText,
  LeadRowTitle,
 } from './Profile.styles';
import {
  SaveButton,
  WhiteButton,
  ButtonContainer,
  HeadingSection
} from '../AdminConfigureContainer.styles';
import CustomDropdown from '../../../componentsV2/CustomDropdown';
import { ErrorBox } from '../../../components/EditPassword/EditPassword.styles';
import CardModal from './CardModal';
import { get } from 'lodash';
import { ROLE_TYPES } from '../../../utils';
import { Tooltip, Zoom } from '@mui/material';
import infoIcon from '../../../assets/icons/infoIcon.svg';
import ModalContainer from '../../../componentsV2/ModalContainer';
import visibilityIcon from '../../../assets/icons/visibilityIcon.svg';
import { LoaderWrapper, ModalBody, ModalCloseButton, ModalHeader, ModalV2 } from '../../../components';
import { NewTooltip } from '../../DripCampaignContainerV2/SetObjectivesV2/SetObjectivesV2.styles';
import LeadCapturePreviewModal from './LeadCapturePreviewModal';

const EditProfile = ({
  data,
  onEditProfile,
  errorSchema,
  userData,
  logo,
  socialLinks,
  roleType,
  user,
  leadCaptureSetting,
  setLeadCaptureSetting,
  loader,
  selectedTimeZone, 
  setSelectedTimeZone
}) => {
  const [profileCard, setProfileCard] = useState(false);
  const [digitalBannerCounter, setDigitalBannerCounter] = useState(
    get(data, 'digital_banner.length', 0)
  );
  const [aboutYourselfCounter, setAboutYourselfCounter] = useState(
    get(data, 'about_me.length', 0)
  );

  const digitalBannerHandleChange = (e, handleChange) => {
    handleChange(e);
    setDigitalBannerCounter(e.target.value.length);
  };

  const aboutYourselfHandleChange = (e, handleChange) => {
    handleChange(e);
    setAboutYourselfCounter(e.target.value.length);
  };

  const getArrayFromObj = (input) => {
    if(input){
      let outputArr = [];
      for(let obj in input){
        input[obj] && outputArr.push({zone: obj, name: input[obj]})
      };
      return outputArr;
    }
    return []
  }

  return (
    <div style={{ width: '90%', margin: '0', position: 'relative' }}>
      <Formik
        enableReinitialize
        initialValues={{
          first_name: '',
          last_name: '',
          email: '',
          contact_email: '',
          mobile: '',
          city: '',
          timezone: userData.timezone,
          about_me: '',
          digital_banner: '',
          firm_name: '',
          ...data,
        }}
        validationSchema={errorSchema}
        onSubmit={onEditProfile}
      >
        {({ handleSubmit, handleChange, errors, values, touched }) => (
          <div>
            <div>
              <EditRow>
                <EditColumn>
                  <TextLabel>
                    <div>First Name*</div>
                    <TextInput
                      style={{ margin: '2px 0px 8px' }}
                      type="text"
                      name="first_name"
                      id="first_name"
                      onChange={handleChange}
                      placeholder="First Name"
                      value={values.first_name}
                      width={'100%'}
                    />
                  </TextLabel>
                  {errors.first_name && touched.first_name ? (
                    <ErrorBox>{errors.first_name}</ErrorBox>
                  ) : null}
                </EditColumn>
                <EditColumn>
                  <TextLabel>
                    <div>Last Name* </div>
                    <TextInput
                      style={{ margin: '2px 0px 8px' }}
                      type="text"
                      name="last_name"
                      id="last_name"
                      onChange={handleChange}
                      placeholder="Last Name"
                      value={values.last_name}
                      width={'100%'}
                    />
                  </TextLabel>
                  {errors.last_name && touched.last_name ? (
                    <ErrorBox>{errors.last_name}</ErrorBox>
                  ) : null}
                </EditColumn>
              </EditRow>

              <EditRow>
                <EditColumn>
                  <TextLabel>
                    <div>Enterprise Name</div>
                    <TextInput
                      style={{ margin: '2px 0px 8px' }}
                      type="text"
                      name="firm_name"
                      id="firm_name"
                      onChange={handleChange}
                      value={values.firm_name}
                      placeholder="Enterprise Name"
                      disabled
                      width={'100%'}
                    />
                  </TextLabel>
                </EditColumn>
                <EditColumn>
                  <TextLabel>
                    <div>Phone Number</div>
                    <TextInput
                      style={{ margin: '2px 0px 8px' }}
                      type="text"
                      name="mobile"
                      id="mobile"
                      onChange={handleChange}
                      placeholder="Phone Number"
                      value={values.mobile}
                      width={'100%'}
                    />
                  </TextLabel>
                </EditColumn>
              </EditRow>
              <EditRow>
                <EditColumn style={{ margin: '10px 0 0' }}>
                  <TextLabel>
                    <div>City </div>
                    <TextInput
                      style={{ margin: '2px 0px 8px' }}
                      type="text"
                      name="city"
                      id="city"
                      onChange={handleChange}
                      value={values.city}
                      placeholder="City"
                      width={'100%'}
                    />
                  </TextLabel>
                  {errors.city && touched.city ? (
                    <ErrorBox>{errors.city}</ErrorBox>
                  ) : null}
                </EditColumn>
                <EditColumn>
                  <CustomDropdown
                    dropdownLabel={'Timezone'}
                    dropdownItems={getArrayFromObj(userData?.google_timezones)}
                    selectedDropdown={
                      selectedTimeZone?.name
                        ? selectedTimeZone
                        : userData?.google_timezones
                        ? {
                            name: userData.google_timezones[values.timezone],
                            zone: values.timezone,
                          }
                        : { name: '', zone: '' }
                    }
                    setSelectedDropdown={(option) =>
                      setSelectedTimeZone(option)
                    }
                  />
                </EditColumn>
              </EditRow>

              <Heading
                style={{
                  margin: '20px 0 10px',
                  borderTop: '1px solid #E4E7EC',
                  paddingTop: 20,
                }}
              >
                Lead Capture Settings
              </Heading>

              <EditRow style={{ margin: '5px 0 15px' }}>
                <EditColumn>
                  <TextLabel>
                    <div>Contact Email*</div>
                    <div style={{ display: 'flex' }}>
                      <TextInput
                        style={{ margin: '2px 0px 10px' }}
                        type="email"
                        name="contact_email"
                        id="contact_email"
                        error={errors.contact_email}
                        onChange={handleChange}
                        placeholder="Contact Email"
                        value={values.contact_email}
                        width={'100%'}
                      />
                      <Tooltip
                        TransitionComponent={Zoom}
                        arrow
                        placement="top"
                        title={`This email address will appear on your digital card for
                            prospective leads. It will not change your username or
                            the email that is connected for sending.`}
                        componentsProps={{
                          tooltip: {
                            sx: {
                              fontFamily: 'Poppins',
                            },
                          },
                        }}
                      >
                        <InfoIcon
                          style={{ margin: '20px 0 0 -20px' }}
                          src={infoIcon}
                          alt="info"
                        />
                      </Tooltip>
                    </div>
                  </TextLabel>
                  {errors.contact_email && touched.contact_email ? (
                    <ErrorBox>{errors.contact_email}</ErrorBox>
                  ) : null}
                </EditColumn>
              </EditRow>
            </div>
            <TextLabel>
              <div style={{ display: 'flex' }}>
                <label>About Yourself:</label>&nbsp;
                <div>{aboutYourselfCounter}/150</div>
              </div>
              <TextArea
                style={{ margin: '1px 0px 28px' }}
                maxLength="150"
                id="about_me"
                name="about_me"
                onChange={(e) => aboutYourselfHandleChange(e, handleChange)}
                value={values.about_me}
              />
            </TextLabel>

            <div style={{ margin: '10px 0 0' }}>
              <TextLabel>
                <div style={{ display: 'flex' }}>
                  <label>Digital Banner:</label>&nbsp;
                  <div>{digitalBannerCounter}/125</div>
                </div>
                <TextArea
                  style={{ margin: '1px 0px 30px' }}
                  maxLength="125"
                  placeholder=""
                  id="digital_banner"
                  name="digital_banner"
                  onChange={(e) => digitalBannerHandleChange(e, handleChange)}
                  value={values.digital_banner}
                />
              </TextLabel>
            </div>

            <div>
              {roleType === ROLE_TYPES.ADVISOR_ENTERPRISE ? (
                <div>{leadCaptureSetting ? 'Smart' : 'Classic'}</div>
              ) : (
                <>
                  <LeadRow style={{ margin: '15px 0 20px' }}>
                    <div style={{ display: 'table' }}>
                      <LeadRowTitle>Smart Lead Capture</LeadRowTitle>
                      <LeadRowText>
                        Enables one-click lead capture for your email contacts.
                      </LeadRowText>
                    </div>
                    <div style={{ display: 'flex' }}>
                      <SwitchStyle
                        className="react-switch"
                        onChange={() =>
                          setLeadCaptureSetting(!leadCaptureSetting)
                        }
                        checked={leadCaptureSetting}
                      />
                      <div style={{ margin: '8px 0 0', color: '#475467' }}>
                        {leadCaptureSetting ? 'On' : 'Off'}
                      </div>
                    </div>
                  </LeadRow>

                  <LeadRow>
                    <div style={{ display: 'table' }}>
                      <LeadRowTitle>Classic Lead Capture</LeadRowTitle>
                      <LeadRowText>
                        Allows them to review/edit contact details before
                        submitting.
                      </LeadRowText>
                    </div>
                    <div style={{ display: 'flex' }}>
                      <SwitchStyle
                        className="react-switch"
                        onChange={() =>
                          setLeadCaptureSetting(!leadCaptureSetting)
                        }
                        checked={!leadCaptureSetting}
                      />
                      <div style={{ margin: '8px 0 0', color: '#475467' }}>
                        {leadCaptureSetting ? 'Off' : 'On'}
                      </div>
                    </div>
                  </LeadRow>
                </>
              )}

              <WhiteButton
                onClick={() => setProfileCard(true)}
                margin={'20px 0'}
                border={'1px solid #CABEFF'}
                color={'#4634D0'}
              >
                <img src={visibilityIcon} alt="show" />
                <span style={{ margin: '2px 6px' }}>
                  Preview Lead Capture Card
                </span>
              </WhiteButton>
              <LoaderWrapper isLoading={loader}>
                <ButtonContainer>
                  <SaveButton
                    onClick={handleSubmit}
                    children={`Save`}
                    style={{ width: '120px' }}
                  />
                </ButtonContainer>
              </LoaderWrapper>
            </div>

            {/* <ModalContainer
              modalStatus={profileCard}
              closeModal={() => setProfileCard(!profileCard)}
              children={
                <CardModal
                  handleModalClose={() => {
                    setProfileCard(false);
                  }}
                  name={values?.first_name + ' ' + values?.last_name}
                  firm_name={values?.firm_name}
                  mobile={values?.mobile}
                  email={values?.contact_email}
                  about_me={values?.about_me}
                  digital_banner={
                    values?.digital_banner && values.digital_banner.length > 0
                      ? values.digital_banner
                      : values?.firm_name && values.firm_name.length > 0
                      ? `Want to hear more from ${values.firm_name}?`
                      : `Want to hear more from ${values?.first_name} ${values?.last_name}?`
                  }
                  logo={logo}
                  socialLinks={socialLinks}
                  userData={userData}
                />
              }
              title="Profile Preview"
            /> */}
            
            <LeadCapturePreviewModal
              profileCard={profileCard}
              setProfileCard={setProfileCard}
              profileName={values?.first_name + ' ' + values?.last_name}
              firm_name={values?.firm_name}
              mobile={values?.mobile}
              email={values?.contact_email}
              about_me={values?.about_me}
              digital_banner={
                values?.digital_banner && values.digital_banner.length > 0
                  ? values.digital_banner
                  : values?.firm_name && values.firm_name.length > 0
                  ? `Want to hear more from ${values.firm_name}?`
                  : `Want to hear more from ${values?.first_name} ${values?.last_name}?`
              }
              logo={logo}
              socialLinks={socialLinks}
              userData={userData}
            />
          </div>
        )}
      </Formik>
    </div>
  );
};

export default EditProfile;
