/* eslint-disable react-hooks/exhaustive-deps */
import { Flex } from '@rebass/grid';
import { get, isEmpty, upperFirst } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { DripIconButton, LoaderWrapper, MioDatePicker, Modal, ModalBody, ModalCloseButton, ModalHeader } from '../../../components';
import { deleteScheduledArticles } from '../../../services/dashboard';
import { getNewsletterDetails, shareNewsletter } from '../../../services/newsletter';
import { getNewsletterSnetworkActId, themesData } from '../../../utils';
import { DateWrapper } from '../PreviewTab/PreviewTab.styles';
import { DeleteModal } from './NewsletterModals';

const ScheduledNewsletterModal = ({
    open,
    setOpen,
    selectedId,
    setSelectedId,
    socialNetworkData,
    selectedTopic,
    setSelectedTopic,
    onAccept,
    contactGroups,
    complianceStatus,
    isPremiumAdvisor,
    userData
}) => {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState({});
    const [errMsg, setErrMsg] = useState('');
    const [openDateModal, setOpenDateModal] = useState(false);
    const [selectedEngagementId, setSelectedEngagementId] = useState('');
    const [openModal, setOpenModal] = useState(false);
    const [selectedItem, setSelectedItem] = useState([]);

    const fetchDetails = () => {
        setLoading(true);
        getNewsletterDetails({ id: selectedId }).then((res) => {
            if (res.result.success) {
                setData(res.result.data);
                setLoading(false);
            } else {
                setLoading(false);
            }
        }).catch(err => {
            setLoading(false);
            setErrMsg('Oops! Something Went Wrong');
        })
    }

    useEffect(() => {
        fetchDetails();
    }, []);

    const handleModalClose = () => {
        setOpen(false);
        setSelectedTopic('');
        setSelectedId('');
    }

    const getDestinationName = (item) => {
        const contactLists = get(data, 'contactlists', []);
        const contactName = contactLists.filter(c => c.id === item.contactlist);
        if (!isEmpty(contactLists) && !isEmpty(contactName)) {
            return contactName[0].name;
        } else {
            return '-';
        }
    }

    const getLeadReferralStatus = (item) => {
        if (item.lead_capture === 'false' && item.referral === 'false') {
            return 'N/A';
        } else if (item.lead_capture === 'true') {
            return 'Leads';
        } else if (item.referral === 'true') {
            return 'Referrals';
        } else {
            return 'N/A';
        }
    }

    const handleDateModalOpen = (item) => {
        setOpenDateModal(true);
        setSelectedEngagementId(item.id);
    }

    const onReject = () => {
        setSelectedItem([]);
        setOpenModal(false);
    }

    const closeDateModal = () => {
        setOpenDateModal(false);
        setSelectedEngagementId('');
    }

    const handleDateSubmit = (date) => {
        let params = {
            re_schedule: 'true',
            engagement_id: selectedEngagementId,
            schedule_time: date,
            snetwork_act_id: getNewsletterSnetworkActId(socialNetworkData)
        };

        shareNewsletter({ id: selectedId, params }).then(res => {
            if (res.result[0].success) {
                closeDateModal();
                fetchDetails();
            }
        }).catch(err => {
            closeDateModal();
            console.log(err);
        })
    }

    const onDeleteButtonClick = (item) => {
        setSelectedItem(item);
        setOpenModal(true);
    }

    const handleDelete = () => {
        if(!isEmpty(selectedItem)) {
            if (get(data, 'engagement_details', []).filter(e => e.status !== 'completed' && e.status !== 'deleted').length > 1) {
                deleteScheduledArticles(selectedItem.id).then(res => {
                    if (res === '') {
                        onReject();
                        fetchDetails();
                    } else {
    
                    }
                }).catch(err => {
                    console.log(err);
                })
            } else {
                onAccept();
            }
        }
    }

    return (
        <>
            <Modal size="medium77" isOpen={open} onRequestClose={handleModalClose}>
                <ModalHeader>
                    <div style={{
                        fontSize: '18px',
                        color: '#030303',
                        fontWeight: 500,
                        padding: '0px 86px'
                    }}>{selectedTopic}</div>
                    <ModalCloseButton onClick={handleModalClose} />
                </ModalHeader>
                <ModalBody>
                    <LoaderWrapper isLoading={loading}>
                        <Flex width="90%" justifyContent={isPremiumAdvisor ? 'space-around' : 'center'} style={{ margin: '0px 12.3px 0px 20px' }}>
                            <Flex width="15%" justifyContent='center' fontSize='12px' color='#6351ed'>Destination</Flex>
                            {!isPremiumAdvisor && (
                                <Flex width="20%" justifyContent='center' fontSize='12px' color='#6351ed'>Compliance Review</Flex>
                            )}
                            <Flex width="25%" justifyContent='center' fontSize='12px' color='#6351ed'>Schedule Date/Time</Flex>
                            <Flex width="20%" justifyContent='center' fontSize='12px' color='#6351ed'>Lead / Referral Capture?</Flex>
                            <Flex width="20%" justifyContent='center' fontSize='12px' color='#6351ed'>Status</Flex>
                        </Flex>
                        <Flex flexDirection='column' style={{ maxHeight: '300px', overflowX: 'hidden', overflowY: 'auto' }}>
                            {!isEmpty(data) && get(data, 'engagement_details', []).filter(e => e.status !== 'completed' && e.status !== 'deleted').map((item, index) => (
                                <Flex width="100%" style={{ minHeight: "54px" }}>
                                    {console.log('COMPLIANCE STATUS SCHEDULE - - - - - ', complianceStatus)}
                                    <Flex width="95%" justifyContent={isPremiumAdvisor ? 'space-around' : 'center'} style={{ backgroundColor: '#fff', border: '1px solid #ececec', margin: '7px 12.3px 7px 20px', height: '40.4px', padding: '7px 12.3px 7px 0' }}>
                                        <Flex width="15%" justifyContent='center' fontSize='12px' color={themesData.charcoalGrey}>{getDestinationName(item)}</Flex>
                                        {!isPremiumAdvisor && (
                                            <Flex width="20%" justifyContent='center' fontSize='12px' color={themesData.charcoalGrey}>{!complianceStatus ? 'N/A' : complianceStatus === 'approved' ? 'Approved' : complianceStatus === 'rejected' ? 'Rejected' : 'Pending'}</Flex>
                                        )}
                                        <Flex width="25%" justifyContent='center' fontSize='12px' color={themesData.charcoalGrey}>{moment(new Date(item.publish_time)).format('Do MMMM YYYY, h:mm a')}</Flex>
                                        <Flex width="20%" justifyContent='center' fontSize='12px' color={themesData.charcoalGrey}>{getLeadReferralStatus(item)}</Flex>
                                        <Flex width="20%" justifyContent='center' fontSize='12px' color={themesData.charcoalGrey}>{item.status === 'em_in_progress' ? 'In Progress' : upperFirst(item.status.replace('compliance_', ''))}</Flex>
                                    </Flex>
                                    {(complianceStatus && complianceStatus !== 'rejected') || !complianceStatus ? (
                                        <Flex width="2%" alignItems="center">
                                            <DateWrapper left="76% !important" width="2%" alignItems="center">
                                                <img alt='calender' src="/assets/images/calender-default.svg" height='14.2px' width='14.2px' style={{ cursor: 'pointer' }} onClick={() => handleDateModalOpen(item)} />
                                                {openDateModal && selectedEngagementId === item.id && (
                                                    <MioDatePicker
                                                        isOpen={openDateModal}
                                                        onDateClose={closeDateModal}
                                                        minDate={new Date()}
                                                        timeZone={get(userData, 'details.user.timezone', '')}
                                                        scheduleValue={item.publish_time}
                                                        onSubmit={(dateTime) => handleDateSubmit(dateTime)}
                                                    />
                                                )}
                                            </DateWrapper>
                                        </Flex>
                                    ) : (
                                        <Flex width="2%" alignItems="center">
                                            <img alt='calender' src="/assets/images/calender-disable.svg" height='14.2px' width='14.2px' />
                                        </Flex>
                                    )}

                                    <Flex width="3%" alignItems="center">

                                        <DripIconButton
                                            icon="trash"
                                            styles={{ position: 'relative', top: '2px' }}
                                            onClick={() => onDeleteButtonClick(item)}
                                        />

                                    </Flex>
                                </Flex>
                            ))}
                        </Flex>
                    </LoaderWrapper>
                </ModalBody>
            </Modal>
            {openModal && (
                <DeleteModal
                    open={openModal}
                    setOpen={setOpenModal}
                    onAccept={handleDelete}
                    onReject={onReject}
                    type="Contact List"
                />
            )}
        </>
    )
}

export default ScheduledNewsletterModal;
