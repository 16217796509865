import { useMutation, useQueryClient } from 'react-query';
import apiInstance from 'src/services/api';

function useMutationForRemovingBookmark() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: 'removing-bookmark',
    mutationFn: async (bookmarkId) => {
      const response = await apiInstance().delete(`/bookmark/${bookmarkId}`);
      return response;
    },
    onSuccess: () => {
      queryClient.refetchQueries(['discover-bookmarks']);
      queryClient.refetchQueries(['discover-my-content']);
      queryClient.refetchQueries(['news-feed']);
      queryClient.refetchQueries(['branded-articles']);
      queryClient.refetchQueries(['discover-recommended-articles']);
    },
  });
}

export default useMutationForRemovingBookmark;
