import { Flex } from '@rebass/grid';
import styled from 'styled-components';

export const CreateNewWrapper = styled(Flex).attrs({
  className: 'create-new-wrapper',
})`
  .newsletter-image {
    width: 58px;
    height: 54.2px;
  }
`;

export const DraftWrapper = styled(Flex).attrs({
  className: 'draft-wrapper',
})`
  .newsletter-image {
    width: 58px;
    height: 54.2px;
  }
`;

export const GetStartedSubTitle = styled.div.attrs({
  className: 'get-started-subtitle',
})`
  font-size: 14px;
  color: #49484a;
`;

export const GetStartedTitle = styled.div.attrs({
  className: 'get-started-title',
})`
  font-size: 16px;
  color: #6351ed;
`;

export const NewletterInput = styled.input.attrs({
  type: 'text',
  className: 'newletter-input',
})`
  height: 39px;
  background-color: #eeeeee;
  border: none;
  width: ${({ width }) => width || '80%'};
  font-family: Poppins;
  padding: 5px 10px;
  ::placeholder {
    text-align: ${({ placeholderTextAlign }) =>
      placeholderTextAlign || 'center'};
    color: #757575;
  }
  :focus::placeholder {
    color: transparent;
  }
`;

export const OutboxText = styled.div.attrs({
  className: 'outbox-box',
})`
  background-color: #eeeeee;
  padding: 10px 15px;
`;

export const GraphColorCircle = styled.div.attrs({
  className: 'graph-color-circle',
})`
  width: 15px;
  height: 15px;
  background-color: ${({ bgColor }) => bgColor || ''};
  border-radius: 50%;
  margin-right: 5px;
`;

export const TooltipMode = styled(Flex).attrs({
  className: 'tooltip',
})`
  /* Tooltip container */
  .tooltip {
    position: relative;
    display: inline-block;
    color: #006080;
  }

  /* Tooltip text */
  .tooltip .tooltiptext {
    visibility: hidden;
    position: absolute;
    ${'' /* width: 150px; */}
    min-width: 300px;
    max-width: 300px;
    background-color: #555;
    color: #fff;
    text-align: center;
    padding: 8px 2px;
    border-radius: 6px;
    z-index: 1;
    opacity: 0;
    transition: opacity 0.6s;
    font-size: 10px;
    font-family: 'Poppins';
  }

  /* Show the tooltip text when you mouse over the tooltip container */
  .tooltip:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
  }

  .tooltip-right {
    top: -5px;
    left: 150%;
  }
  .tooltip-right::after {
    content: '';
    position: absolute;
    top: 15px;
    right: 100%;
    margin-top: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent #555 transparent transparent;
  }
`;
