import { styled } from '@mui/material';

export const ContentPageWrapper = styled('div')(() => ({
  display: 'flex',
  flexWrap: 'wrap',
  gap: '1rem',
  width: '100%',
}));

export const ChartContainer = styled('div')(() => ({
  boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.12)',
  border: '1px solid #E4E7EC',
  background: '#fff',
  borderRadius: '0.5rem',
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  flex: 0.8,
  minWidth: '600px',
}));

export const ChartHeader = styled('h1')(() => ({
  font: '500 1.25rem Poppins,sans-serif',
  color: '#000',
  borderBottom: '1px solid #E4E7EC',
  padding: '1.25rem',
  textAlign: 'left',
  margin: 0,
}));
