import * as React from "react";

function Twitter(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 18 18" fill="none" {...props}>
      <path
        d="M9 6.188c0-1.547 1.3-2.834 2.848-2.813a2.812 2.812 0 012.545 1.688h2.482l-2.271 2.27a8.993 8.993 0 01-8.98 8.417c-2.25 0-2.812-.844-2.812-.844s2.25-.844 3.375-2.531c0 0-4.5-2.25-3.375-8.437 0 0 2.813 2.812 6.188 3.375V6.188z"
        stroke="currentColor"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

const MemoTwitter = React.memo(Twitter);
export default MemoTwitter;
