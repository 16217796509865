/* eslint-disable react-hooks/exhaustive-deps */
import { Flex } from '@rebass/grid';
import { get, isEmpty } from 'lodash';
import React, { useState, useEffect, Suspense } from 'react';
import CampaignInfo from '../CampaignInfo/CampaignInfo';
import CampaignPlatforms from '../CampaignPlatforms/CampaignPlatforms';
import { getRoleType, getUserRole } from '../../../../redux/selector';
import { ButtonNoBorderBackground, LoaderWrapper } from '../../../../components';
import {
  getCascadeInfoEnterpriseLevelAdmin,
  getSeriesSubCampaigns,
  postCampaignCascade,
  postCampaignCascadeEnterpriseLevel,
  sendTestEmailOneTime,
} from '../../../../services/campaign';

import {
  SubCampaignSeparator,
  SubCampaignVerticalSep,
  VerticalSeparator,
} from './DripDashboardListItem.styles';
import { connect } from 'react-redux';
import { getMediaImage, onImageError, ROLE_TYPES } from '../../../../utils';
import CampaignMenu from '../CampaignMenu/CampaignMenu';
import { Button } from '../../../../components';
import { useHistory } from 'react-router';
import CampaignEngagementStatus from '../CampaignEngagementStatus/CampaignEngagementStatus';
import CampaignPurpose from '../CampaignPurpose/CampaignPurpose';
import CascadeModalEnterpriseAdmin from '../../CascadeModalEnterpriseAdmin/CascadeModalEnterpriseAdmin';
import PreviewModal from '../../Preview&ViewInfoModal/PreviewModal';
import { getAdvisorRows } from '../../../../components/RichTextEditor/BeeEditorHelper';
import SendTestMailModal from '../../../AutomatedCampaigns/CampaignCreateSteps/SendTestMailModal';

const MemberModal = React.lazy(() => import('../../MemberModal/MemberModal'));

const DripDashboardListItem = ({
  name,
  status,
  email,
  facebook,
  twitter,
  linkedin,
  themes,
  isFirst,
  start_date,
  duration,
  webinar,
  frequency,
  target,
  contact_types,
  type,
  socialThemes,
  posts,
  id,
  platforms,
  campaignDetailsView,
  setDetailsView,
  campaignsDetailsData,
  campaignDetailsRequest,
  socialNetworkData,
  detailsHandler,
  media_urls,
  editLoader,
  campaigns,
  campaignRequest,
  addCampaignFlow,
  series_id,
  filters,
  selectedCampaignTab,
  category,
  setFilters,
  setSelectedCampaignTab,
  isadvMode,
  campaignSetState,
  feedback,
  pending_count,
  can_duplicate,
  userData,
  webinar_custom_image,
  engagement_status,
  series_subcampaigns_engagement_statuses,
  contactGroups,
  campaignsRequest,
  membersList,
  isAdmin,
  approved_to_share,
  campaign_cascade,
  campaign_id,
  isEnterpriseAdvisor,
  is_email,
  is_twitter,
  is_facebook,
  is_linkedin,
  onFilterTabChange,
  is_cascaded,
  blockCreate,
  cascaded_advisors,
  cascaded_firms,
  is_member_only,
  platform_types,
  record_type,
  purpose,
  isEnterpriseLevelAdmin,
  isFirmLevelAdmin,
  firmSettings,
  firmsArray,
  memberAdvisorsArray,
  segmentList,
  segment,
  seriesSubcampaigns,
  item,
  paginatedData,
  has_email_template,
  is_global_compliance,
  is_partially_completed,
  editSeriesLevel,
  is_auto_post,
  isTurnkey
}) => {
  const [fetchedDetails, setFetchedDetails] = useState({});
  const [showSeries, setShowSeries] = useState(false);
  const history = useHistory();
  const [isOpen, setIsOpen] = useState(false);
  const [selectedAdvisors, setSelectedAdvisors] = useState([]);
  const [OGSelectedAdvisors, setOGSelectedAdvisors] = useState([]);
  const [selectedFirmAdvisors, setSelectedFirmAdvisors] = useState([]);
  const [OGselectedFirmAdvisors, setOGSelectedFirmAdvisors] = useState([]);
  const [cascadeOption, setCascadeOption] = useState('advisors');
  const [OGCascadeOption, setOGCascadeOption] = useState('');
  const [selectedFirms, setSelectedFirms] = useState([]);
  const [OGSelectedFirms, setOGSelectedFirms] = useState([]);
  const [modalLoader, toggleModalLoader] = useState(false);
  const [fetchingCascadeInfo, toggleFetchingCascadeInfo] = useState(false);
  const [shareDisabled, toggleShareDisabled] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const [showTooltip, toggleTooltip] = useState(false);
  const [seriesDropdownLoader, toggleSeriesDropdownLoader] = useState(false);
  const [reviewLoader, toggleReviewLoader] = useState(false);
  const [subCampaignData, setSubCampaignData] = useState([]);
  const [openPreviewModal, setOpenPreviewModal] = useState(false);
  const [openSendTestMailModal, setOpenSendTestMailModal] = useState(false);
  const [testEmails, setTestEmails] = useState('');
  const [openSubPreview, setOpenSubPreview] = useState(false);
  const [campaignPreviewId, setCampaignPreviewId] = useState('');
  const [campaignTestMailId, setCampaignTestMailId] = useState('');
  const [sendTestErrorMsg, setSendTestErrorMsg] = useState('');
  const [sendTestLoading, setSendTestLoading] = useState(false);
  const [detailsLoading, setDetailsLoading] = useState(false);
  const [selectedSubject, setSelectedSubject] = useState('');
  const [seriesLoader, setSeriesLoader] = useState(false);
  const [newTemplateJSON, setNewTemplateJSON] = useState('');

  useEffect(() => {
    if (campaignsDetailsData) {
      setFetchedDetails(campaignsDetailsData);
      let el = document.getElementById(campaignsDetailsData?.campaign_id);
      campaignDetailsView &&
        el &&
        el.scrollIntoView({ behavior: 'smooth', block: 'start' });
    } else {
      setFetchedDetails({});
    }
  }, [campaignsDetailsData]);

  useEffect(() => {
    setSubCampaignData(get(seriesSubcampaigns, `${series_id}`, []));
  }, [paginatedData, seriesSubcampaigns]);

  const onClickHandler = () => {
    setIsOpen(true);
    let payloadId = id || series_id;
    if (isEnterpriseLevelAdmin) {
      toggleFetchingCascadeInfo(true);
      if (
        cascaded_advisors &&
        membersList.length - 1 === cascaded_advisors.length
      ) {
        setSelectedAdvisors([...cascaded_advisors, 'selectAll'] || []);
      } else {
        setSelectedAdvisors(cascaded_advisors || []);
      }
      setOGSelectedAdvisors(cascaded_advisors || []);

      getCascadeInfoEnterpriseLevelAdmin({ payloadId, type })
        .then((res) => {
          if (res.result.success) {
            const cascadeToAdvisors =
              get(res, 'result.data.cascade_to_advisors', 'true') === 'true';
            // const updateList = cascadeToAdvisors ? setSelectedFirmAdvisors : setSelectedFirms;
            // const setOGList = cascadeToAdvisors ? setOGSelectedFirmAdvisors : setOGSelectedFirms;
            // setOGList(get(res, 'result.data.firm_ids', []));
            // updateList(get(res, 'result.data.firm_ids', []));
            setSelectedFirmAdvisors(
              get(res, 'result.data.firm_ids_advisor', [])
            );
            setOGSelectedFirmAdvisors(
              get(res, 'result.data.firm_ids_advisor', [])
            );
            setSelectedFirms(get(res, 'result.data.firm_ids_admin', []));
            setOGSelectedFirms(get(res, 'result.data.firm_ids_admin', []));
            setCascadeOption(cascadeToAdvisors ? 'advisors' : 'admins');
            setOGCascadeOption(
              !isEmpty(get(res, 'result.data.cascade_to_advisors', ''))
                ? cascadeToAdvisors
                  ? 'advisors'
                  : 'admins'
                : ''
            );
          }
          toggleFetchingCascadeInfo(false);
        })
        .catch((err) => console.log('ERROR FETCHING CASCADE INFO - - -', err));
    } else {
      if (
        cascaded_advisors &&
        membersList.length - 1 === cascaded_advisors.length
      ) {
        setSelectedAdvisors([...cascaded_advisors, 'selectAll'] || []);
      } else {
        setSelectedAdvisors(cascaded_advisors || []);
      }
      setOGSelectedAdvisors(cascaded_advisors || []);
    }
  };

  const shareValidationCheck = () => {
    let disableShare = true;
    if (selectedAdvisors.includes('selectAll'))
      disableShare =
        selectedAdvisors.filter((adv) => adv !== 'selectAll').length ===
        OGSelectedAdvisors.length;
    else if (selectedAdvisors.length !== OGSelectedAdvisors.length)
      disableShare = false;
    else
      disableShare = selectedAdvisors.every((adv) =>
        OGSelectedAdvisors.includes(adv)
      );
    return disableShare;
  };

  const selectMembersHandler = (e, selectedMember) => {
    let updatedAdvisorsList = [...selectedAdvisors];
    if (selectedMember.id === 'selectAll') {
      if (e.target.checked)
        updatedAdvisorsList = membersList.map((member) => member.id);
      else updatedAdvisorsList = [];
    } else if (selectedAdvisors.includes(selectedMember.id))
      updatedAdvisorsList = [...selectedAdvisors].filter(
        (advisorId) =>
          advisorId !== selectedMember.id && advisorId !== 'selectAll'
      );
    else {
      updatedAdvisorsList = [...selectedAdvisors, selectedMember.id];
      if (updatedAdvisorsList.length === membersList.length - 1)
        updatedAdvisorsList.push(membersList[0].id);
    }
    setSelectedAdvisors(updatedAdvisorsList);
  };

  const closeModalHandler = () => {
    toggleShareDisabled(false);
    setModalMessage('');
    setIsOpen(false);
    setSelectedAdvisors([]);
    setOGSelectedAdvisors([]);
    if (isEnterpriseLevelAdmin) {
      setSelectedFirms([]);
      setSelectedFirmAdvisors([]);
      setOGSelectedFirms([]);
      setOGSelectedFirmAdvisors([]);
    }
  };

  const getPlatformData = () => {
    let platforms = [];
    if (is_email === 'true') {
      platforms.push('email');
    }
    if (is_facebook === 'true') {
      platforms.push('facebook');
    }
    if (is_twitter === 'true') {
      platforms.push('twitter');
    }
    if (is_linkedin === 'true') {
      platforms.push('linkedin');
    }
    return platforms;
  };

  const getPlatformDataSeries = (item) => {
    let platforms = [];
    if (item.is_email === 'true') {
      platforms.push('email');
    }
    if (item.is_facebook === 'true') {
      platforms.push('facebook');
    }
    if (item.is_twitter === 'true') {
      platforms.push('twitter');
    }
    if (item.is_linkedin === 'true') {
      platforms.push('linkedin');
    }
    return platforms;
  };

  const handleAdvisorShare = () => {
    toggleModalLoader(true);
    setModalMessage('');
    let advisorList = selectedAdvisors;

    if (advisorList.includes('selectAll')) {
      advisorList = advisorList.filter((s) => s !== 'selectAll');
    }
    const cascadeId = series_id || id;
    postCampaignCascade(
      {
        advisors: JSON.stringify(advisorList),
        is_email,
        is_facebook,
        is_linkedin,
        is_twitter,
        is_completed: status !== 'draft' && selectedAdvisors.length > 0,
        ...(!isEmpty(series_id) && {
          record_type: 'series',
        }),
      },
      cascadeId
    )
      .then((response) => {
        if (get(response, 'result.success', '')) {
          toggleModalLoader(true);
          setModalMessage('Done!');
          setTimeout(() => {
            closeModalHandler();
            campaignRequest();
          }, 1500);
          toggleShareDisabled(true);
        } else {
          toggleModalLoader(false);
          setModalMessage('Campaign not shared. Check for errors');
        }
      })
      .catch((err) => {
        setModalMessage('Oops..some error occurred. Please try again.');
        toggleModalLoader(false);
      });
  };

  const handleEnterpriseLevelAdminShare = () => {
    toggleModalLoader(true);
    setModalMessage('');
    if (cascadeOption === 'onlyAdvisors') {
      handleAdvisorShare();
    } else {
      const payload = {
        firms:
          cascadeOption === 'advisors'
            ? JSON.stringify(selectedFirmAdvisors)
            : JSON.stringify(selectedFirms),
        cascade_to_advisors: cascadeOption === 'advisors',
      };
      postCampaignCascadeEnterpriseLevel({
        payload,
        id: series_id || id,
        campaignType: type,
      })
        .then((res) => {
          toggleModalLoader(false);
          setModalMessage('Cascaded Successfully!');
          setTimeout(() => {
            closeModalHandler();
            setModalMessage('');
          }, 3000);
        })
        .catch((err) => {
          console.log('ERROR - - -- ', err);
          setModalMessage('Oops..Some error occurred! Please try again later.');
          toggleModalLoader(false);
        });
    }
  };

  const postinfo = {
    postsPerWeek: posts?.week,
    totalPosts: posts?.total,
    scheduled: posts?.scheduled,
    needContent: posts?.draft,
    completed: posts?.completed,
    failed: posts?.failed,
    missed: posts?.missed,
  };

  const redirectToReview = async (campaignId) => {
    toggleReviewLoader(true);
    let subCampaigns = [];
    if (type === 'series') {
      if (isEmpty(seriesSubcampaigns[series_id])) {
        const res = await getSeriesSubCampaigns({ id: series_id, limit: 100 });
        subCampaigns = get(res, 'result.data.campaigns', []);
      } else subCampaigns = seriesSubcampaigns[series_id];
    }
    campaignSetState({
      campaignId,
      type,
      ...((type === 'series' || record_type === 'series') &&
        !campaignId && { subCampaigns: subCampaigns }),
    });
    toggleReviewLoader(false);
    history.push('/campaignV2?type=review');
  };

  const handleSeriesDropdown = () => {
    if (isEmpty(seriesSubcampaigns[series_id])) {
      toggleSeriesDropdownLoader(true);
      getSeriesSubCampaigns({ id: series_id })
        .then((res) => {
          toggleSeriesDropdownLoader(false);
          if (get(res, 'result.success', false)) {
            campaignSetState({
              seriesSubcampaigns: {
                ...seriesSubcampaigns,
                [series_id]: get(res, 'result.data.campaigns', []),
                pagination: get(res, 'result.data.pagination')
              },
            });
          }
          setShowSeries(!showSeries);
        })
        .catch((err) => {
          console.log('ERROR FETCHING SUBCAMPAIGNS - - - -', err);
          toggleSeriesDropdownLoader(false);
        });
    } else setShowSeries(!showSeries);
  };

  const handleTestEmail = async (id, subject, campaign) => {
    const formattedTemplateJson = await getAdvisorRows(JSON.parse(campaign?.templateJson));
    setNewTemplateJSON(formattedTemplateJson);
    //alert('Please! Send Test Email');
    setCampaignTestMailId(id);
    setOpenSendTestMailModal(true);
    setSelectedSubject(subject);
  };


  const handleSendTestInputValidation = () => {
    if (!testEmails) {
      return true;
    }
  };

  const handleSendClick = () => {
    // TO DO HANDLE EMAIL VALIDATION AND COUNT VALIDATION
    setSendTestErrorMsg('');
    let msg = 'Email is not valid';
    const isEmailRegEx =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,3}))$/;
    let valid = true;
    if (valid && !isEmpty(testEmails)) {
      valid = testEmails
        .split(',')
        .map((em) => em.trim())
        .every((em) => isEmailRegEx.test(em.toLowerCase()));
      if (testEmails.split(',').length > 5 && valid) {
        valid = false;
        msg = 'Maximum 5 emails can be added';
      }
    }
    if (!valid) {
      setSendTestErrorMsg(msg);
      return;
    };
    setSendTestLoading(true);
    const payload = {
      campaign_id: campaignTestMailId,
      email_ids: JSON.stringify(testEmails.split(',')),
      cascaded: 'true',
      template_json: new File([JSON.stringify(newTemplateJSON)], 'template.json')
    }
    sendTestEmailOneTime(payload)
      .then((res) => {
        if (get(res, 'result.success', false)) {
          setSendTestLoading(false);
          setSendTestErrorMsg(
            'Sent Successfully'
          );
          setTimeout(() => {
            setSendTestErrorMsg('');
            setSendTestLoading(false);
            setOpenSendTestMailModal(false);
            setNewTemplateJSON('');
          }, 3000);
        } else {
          setSendTestLoading(false);
          setSendTestErrorMsg(
            res?.result?.message
          );
        }
        setTestEmails('');
      })
      .catch((error) => {
        console.log(error)
        setSendTestLoading(false);

      });
    setSendTestErrorMsg();
  };

  const viewMoreSubCampaigns = () => {
    triggerLoadSubCampaigns();
  }

  const triggerLoadSubCampaigns = () => {
    setSeriesLoader(true);
    getSeriesSubCampaigns({ id: series_id, after: seriesSubcampaigns.pagination.after, limit: 100 })
      .then((res) => {
        if (get(res, 'result.success', false)) {
          campaignSetState({
            seriesSubcampaigns: {
              ...seriesSubcampaigns,
              [series_id]: [...get(seriesSubcampaigns, [series_id], []), ...get(res, 'result.data.campaigns', [])],
              pagination: get(res, 'result.data.pagination')
            },
          });
        }
        setSeriesLoader(false);
      })
      .catch((err) => {
        console.log('ERROR FETCHING SUBCAMPAIGNS - - - -', err);
        setSeriesLoader(false);
      });
  }

  return (
    <>
      <Flex
        data-testid="campaignlist-item"
        justifyContent="space-between"
        id={id}
        alignItems="center"
        // width={campaignDetailsView ? '38%' : '100%'}
        style={{
          border: '1px solid #DBE0E6',
          borderRadius: '4px',
          // borderRight: `6px solid ${statusColorCodes[status]}`,
          margin: isFirst ? '0px 0px 10px' : '10px 0px',
          boxShadow: campaignDetailsView
            ? fetchedDetails?.campaign_id === id
              ? 'rgba(0, 0, 0, 0.24) 0px 3px 8px'
              : ''
            : '',
          backgroundColor: campaignDetailsView
            ? fetchedDetails?.campaign_id === id
              ? '#fff'
              : '#eee'
            : '#fff',
        }}
      >
        <Flex width="100vw" alignItems="center">
          <img
            alt=""
            // src={getMediaImage(media_urls)}
            src={
              (type === 'series' || record_type === 'series') &&
              !isEmpty(campaigns)
                ? getMediaImage(get(campaigns[0], 'media_urls', ''))
                : webinar_custom_image
                ? webinar_custom_image
                : getMediaImage(media_urls)
            }
            width="140px"
            height="100px"
            style={{ borderRadius: '5px' }}
            onError={onImageError}
          />

          <CampaignInfo
            target={target}
            title={name}
            status={status}
            start_date={start_date}
            duration={duration}
            webinar={webinar}
            frequency={frequency}
            type={
              isEnterpriseAdvisor && selectedCampaignTab === 9
                ? record_type
                : type
            }
            campaignDetailsView={campaignDetailsView}
            id={id}
            campaignDetailsRequest={campaignDetailsRequest}
            setDetailsView={setDetailsView}
            detailsHandler={detailsHandler}
            isadvMode={isadvMode}
            feedback={feedback}
            campaigns={campaigns}
            engagement_status={engagement_status}
            approved_to_share={approved_to_share}
            is_cascaded={is_cascaded}
            campaign_cascade={campaign_cascade}
            isEnterpriseAdvisor={isEnterpriseAdvisor}
            purpose={purpose}
            is_global_compliance={is_global_compliance}
            partially_completed={is_partially_completed}
            is_auto_post={is_auto_post}
            seriesLevel
            selectedCampaignTab={selectedCampaignTab}
          />
          {(type === 'series' || record_type === 'series') &&
            !isEmpty(series_subcampaigns_engagement_statuses) && (
              <>
                <VerticalSeparator
                  height="30px"
                  style={{ marginTop: '27px' }}
                />
                <CampaignEngagementStatus
                  engagementDetails={series_subcampaigns_engagement_statuses}
                />
              </>
            )}

          {type !== 'series' && record_type !== 'series' && (
            <>
              <VerticalSeparator
                style={{ height: '58px', marginLeft: '60px' }}
              />
              <CampaignPlatforms platforms={getPlatformData()} />
              <VerticalSeparator height="58px" />
              {/* <CampaignTarget contact_types={contact_types} /> */}
              {/* <VerticalSeparator height="58px" /> */}
              <CampaignPurpose purpose={purpose} />
            </>
          )}

          {isadvMode && status === 'approval_pending' && (
            <Flex
              justifyContent="flex-end"
              // width="100%"
              style={{ marginLeft: 'auto' }}
            >
              <div
                style={{
                  margin:
                    type !== 'series' && record_type !== 'series' && '0px 80px',
                }}
              >
                <LoaderWrapper isLoading={reviewLoader}>
                  <Button
                    borderRadius="10px"
                    color="white"
                    bgColor="#cc6ac0"
                    borderColor="#cc6ac0"
                    margin="0px"
                    onClick={() => redirectToReview(id)}
                    testId="campaign-review-btn"
                  >
                    Review
                  </Button>
                </LoaderWrapper>
              </div>
            </Flex>
          )}

          {selectedCampaignTab === 9 && (
            <Flex
              justifyContent="flex-end"
              // width="100%"
              style={{ marginLeft: 'auto', position: 'absolute', left: 0, right: type === 'series' ? '11%' : '5%' }}
            >
              <div
                style={{
                  margin:
                    type !== 'series' && record_type !== 'series' && '0px 86px',
                }}
              >
                <LoaderWrapper isLoading={reviewLoader}>
                  <Button
                    borderRadius="4px"
                    color="#6351ed"
                    bgColor="white"
                    borderColor="#6351ed"
                    margin="0px"
                    onClick={() => setOpenPreviewModal(true)}
                    testId="campaign-preview-btn"
                  >
                    Preview
                  </Button>
                </LoaderWrapper>
              </div>
            </Flex>
          )}
          <Flex
            justifyContent="end"
            alignItems="center"
            width={
              (approved_to_share === 'true' || is_cascaded === 'true') &&
              (engagement_status === 'paused' ||
                engagement_status === 'cancelled')
                ? '18%'
                : type === 'series' || record_type === 'series'
                ? '38%'
                : '11.3%'
            }
            onMouseEnter={
              (type === 'series' || record_type === 'series') &&
              campaigns &&
              !campaigns.every((campaign) => campaign.status !== 'draft')
                ? () => toggleTooltip(true)
                : () => {}
            }
            onMouseLeave={() => toggleTooltip(false)}
            style={{ position: 'relative' }}
          >
            {isAdmin &&
              (!isFirmLevelAdmin ||
                get(
                  firmSettings,
                  'firm_general.advisor_cascade_campaign',
                  'true'
                ) === 'true') &&
              (status === 'scheduled' ||
                status === 'in_progress' ||
                status === 'completed' ||
                (status === 'draft' && approved_to_share === 'true')) && (
                <>
                  <img
                    src={
                      (type === 'series' || record_type === 'series') &&
                      campaigns &&
                      !campaigns.every(
                        (campaign) => campaign.status !== 'draft'
                      )
                        ? 'assets/images/forward-disabled.svg'
                        : 'assets/images/forward.svg'
                    }
                    alt=""
                    onClick={onClickHandler}
                    // onMouseEnter={() => toggleTooltip('')}
                    style={{
                      cursor: 'pointer',
                      height: '30px',
                      width: '30px',
                      ...((type === 'series' || record_type === 'series') &&
                      campaigns &&
                      !campaigns.every(
                        (campaign) => campaign.status !== 'draft'
                      )
                        ? { pointerEvents: 'none' }
                        : {}),
                    }}
                  />
                  {showTooltip && (
                    <ul
                      style={{
                        textAlign: 'left',
                        padding: '0px',
                        backgroundColor: '#49484a',
                        color: '#fff',
                        borderRadius: '4px',
                        fontSize: '10px',
                        position: 'absolute',
                        top: '25px',
                        right: '0',
                        padding: '2px 5px',
                        listStyleType: 'none',
                      }}
                    >
                      <li>
                        Templates within this series are in draft and can't be
                        shared until complete.
                      </li>
                    </ul>
                  )}
                </>
              )}
          </Flex>
        </Flex>
        {openPreviewModal && (
          <PreviewModal
            isOpen={openPreviewModal}
            handleClose={() => setOpenPreviewModal(false)}
            campaignId={id}
            seriesId={series_id}
            type={type}
            subCampaigns={seriesSubcampaigns[series_id]}
            isATSPreviewModalVisible
            handleTestEmail={handleTestEmail}
            campaignSetState={campaignSetState}
            detailsHandler={detailsHandler}
            onFilterTabChange={onFilterTabChange}
            campaigns={campaigns}
            topLevel={type === 'series' ? true : false}
            editSeriesLevel={editSeriesLevel}
          />
        )}
        {openSendTestMailModal && (
          <SendTestMailModal
            isOpen={openSendTestMailModal}
            handleModalClose={() => {
              setOpenSendTestMailModal(false);
              setSendTestLoading('');
              setSendTestErrorMsg('');
              setNewTemplateJSON('');
            }}
            handleChange={(e) => setTestEmails(e.target.value)}
            subject={selectedSubject}
            value={testEmails}
            validation={handleSendTestInputValidation()}
            modalButtonOnClick={handleSendClick}
            sendTestErrorMsg={sendTestErrorMsg}
            loading={sendTestLoading}
            detailsLoading={detailsLoading}
          />
        )}
        {isOpen && isEnterpriseLevelAdmin && (
          <CascadeModalEnterpriseAdmin
            firmsArray={firmsArray || []}
            memberAdvisorsArray={memberAdvisorsArray}
            closeHandler={closeModalHandler}
            selectedFirms={selectedFirms}
            setSelectedFirms={setSelectedFirms}
            cascadeOption={cascadeOption}
            OGCascadeOption={OGCascadeOption}
            setOGCascadeOption={setOGCascadeOption}
            setCascadeOption={setCascadeOption}
            OGSelectedFirms={OGSelectedFirms}
            loader={modalLoader}
            cascadeHandler={handleEnterpriseLevelAdminShare}
            handleAdvisorShare={handleAdvisorShare}
            fetchingInfo={fetchingCascadeInfo}
            modalMessage={modalMessage}
            membersList={membersList}
            shareDisabled={shareDisabled}
            shareValidationCheck={shareValidationCheck}
            selectMembersHandler={selectMembersHandler}
            selectedAdvisors={selectedAdvisors}
            OGSelectedAdvisors={OGSelectedAdvisors}
            selectedFirmAdvisors={selectedFirmAdvisors}
            setSelectedFirmAdvisors={setSelectedFirmAdvisors}
            OGSelectedFirmAdvisors={OGselectedFirmAdvisors}
          />
        )}
        {isOpen && !isEnterpriseLevelAdmin && (
          <Suspense fallback={null}>
            <MemberModal
              isOpen={isOpen}
              closeModalHandler={closeModalHandler}
              membersList={membersList}
              selectMembersHandler={selectMembersHandler}
              selectedAdvisors={selectedAdvisors}
              OGSelectedAdvisors={OGSelectedAdvisors}
              modalMessage={modalMessage}
              modalLoader={modalLoader}
              shareDisabled={shareDisabled}
              shareValidationCheck={shareValidationCheck}
              handleAdvisorShare={handleAdvisorShare}
              buttonName="Share"
              isEnterpriseLevelAdmin={isEnterpriseLevelAdmin}
            />
          </Suspense>
        )}

        <CampaignMenu
          testId="dpmenu"
          detailsHandler={detailsHandler}
          id={id}
          editLoader={editLoader}
          type={type}
          setShowSeries={setShowSeries}
          handleDropdown={handleSeriesDropdown}
          seriesDropdownLoader={seriesDropdownLoader}
          showSeries={showSeries}
          title={name}
          campaignRequest={campaignRequest}
          addCampaignFlow={addCampaignFlow}
          status={status}
          platforms={platforms}
          seriesId={series_id}
          filters={filters}
          selectedCampaignTab={selectedCampaignTab}
          category={category}
          setFilters={setFilters}
          setSelectedCampaignTab={setSelectedCampaignTab}
          isadvMode={isadvMode}
          campaigns={campaigns}
          canDuplicate={can_duplicate}
          userData={userData}
          engagement_status={engagement_status}
          socialNetworkData={socialNetworkData}
          contactGroups={contactGroups}
          isEnterpriseAdvisor={isEnterpriseAdvisor}
          onFilterTabChange={onFilterTabChange}
          is_cascaded={is_cascaded}
          approved_to_share={approved_to_share}
          blockCreate={blockCreate}
          is_member_only={is_member_only}
          record_type={record_type}
          campaignSetState={campaignSetState}
          is_email={is_email}
          is_twitter={is_twitter}
          is_facebook={is_facebook}
          is_linkedin={is_linkedin}
          isEnterpriseLevelAdmin={isEnterpriseLevelAdmin}
          isFirmLevelAdmin={isFirmLevelAdmin}
          firmSettings={firmSettings}
          segmentList={segmentList}
          segment={segment}
          seriesSubcampaigns={seriesSubcampaigns}
          has_email_template={has_email_template}
          seriesLevel
          editSeriesLevel={editSeriesLevel}
          is_auto_post={is_auto_post}
          isTurnkey={isTurnkey}
        />
      </Flex>

      

      {showSeries && (type === 'series' || record_type === 'series')
        ? !isEmpty(subCampaignData) &&
          subCampaignData.map((item, key) => {
            return (
              <Flex justifyContent="flex-start">
                <div style={{ width: '10%' }}>
                  <SubCampaignSeparator />
                  {((seriesSubcampaigns[series_id] &&
                    seriesSubcampaigns[series_id]?.length - 1 !== key)) && (
                      <SubCampaignVerticalSep />
                    )}
                </div>
                <Flex
                  justifyContent="space-between"
                  id={id}
                  alignItems="center"
                  width={'90%'}
                  alignSelf="flex-end"
                  style={{
                    animation: showSeries
                      ? 'slide-up 0.4s ease'
                      : 'slide-down 0.4s ease',
                    border: '1px solid #DBE0E6',
                    borderRadius: '4px',
                    // borderRight: `6px solid ${statusColorCodes[status]}`,
                    margin: isFirst ? '0px 0px 10px' : '10px 0px',
                    boxShadow: campaignDetailsView
                      ? fetchedDetails?.campaign_id === id
                        ? 'rgba(0, 0, 0, 0.24) 0px 3px 8px'
                        : ''
                      : '',
                    backgroundColor: campaignDetailsView
                      ? fetchedDetails?.campaign_id === id
                        ? '#fff'
                        : '#eee'
                      : '#fff',
                  }}
                >
                  <Flex width="100vw" alignItems="center">
                    <img
                      alt=""
                      src={getMediaImage(get(item, 'media_urls', ''))}
                      width="140px"
                      height="100px"
                      style={{ borderRadius: '5px' }}
                      onError={onImageError}
                    />

                    <CampaignInfo
                      target={item.target}
                      title={item.name}
                      status={item.status}
                      purpose={item.purpose}
                      start_date={item.start_date}
                      duration={item.duration}
                      frequency={item.frequency}
                      type={item.type === 'series' ? 'series' : 'automated'}
                      campaignDetailsView={campaignDetailsView}
                      id={item.id}
                      campaignDetailsRequest={campaignDetailsRequest}
                      setDetailsView={setDetailsView}
                      detailsHandler={detailsHandler}
                      mode={'series'}
                      platforms={platforms}
                      isadvMode={isadvMode}
                      feedback={item.feedback}
                      engagement_status={item.engagement_status}
                      campaigns={campaigns}
                      approved_to_share={approved_to_share}
                      is_cascaded={is_cascaded}
                      campaign_cascade={campaign_cascade}
                      isEnterpriseAdvisor={isEnterpriseAdvisor}
                      isSubCampaign={true}
                      is_global_compliance={item.is_global_compliance}
                      partially_completed={item.is_partially_completed}
                      selectedCampaignTab={selectedCampaignTab}
                    />

                    <VerticalSeparator
                      style={{ height: '58px', marginLeft: '60px' }}
                    />
                    <CampaignPlatforms
                      platforms={getPlatformDataSeries(item)}
                    />
                    <VerticalSeparator height="58px" />
                    {/* <CampaignTarget contact_types={item.contact_types || []} /> */}
                    {/* <VerticalSeparator height="58px" /> */}
                    <CampaignPurpose purpose={item.purpose} />
                    {selectedCampaignTab === 9 && (
                      <Flex
                        justifyContent="flex-end"
                        // width="100%"
                        style={{ marginLeft: 'auto', position: 'absolute', left: 0, right: type === 'series' ? '11%' : '5%' }}
                      >
                        <div
                          style={{
                            margin:
                              type !== 'series' && record_type !== 'series' && '0px 86px',
                          }}
                        >
                          <LoaderWrapper isLoading={reviewLoader}>
                            <Button
                              borderRadius="4px"
                              color="#6351ed"
                              bgColor="white"
                              borderColor="#6351ed"
                              margin="0px"
                              onClick={() => {
                                setOpenSubPreview(true);
                                setCampaignPreviewId(item.id)
                              }}
                              testId="campaign-preview-btn"
                            >
                              Preview
                            </Button>
                          </LoaderWrapper>
                        </div>
                      </Flex>
                    )}

                    {openSubPreview && campaignPreviewId === item.id && (
                      <PreviewModal
                        isOpen={openSubPreview}
                        handleClose={() => setOpenSubPreview(false)}
                        campaignId={item.id}
                        seriesId={series_id}
                        type={type}
                        subCampaigns={seriesSubcampaigns[series_id]}
                        isATSPreviewModalVisible
                        handleTestEmail={handleTestEmail}
                        campaignSetState={campaignSetState}
                        detailsHandler={detailsHandler}
                        onFilterTabChange={onFilterTabChange}
                        campaigns={campaigns}
                        editSeriesLevel={editSeriesLevel}
                      />
                    )}
                    {isadvMode && item.status === 'approval_pending' && (
                      <Flex
                        justifyContent="flex-end"
                        // width="50%"
                        style={{ marginLeft: 'auto' }}
                      >
                        <div style={{ margin: '0px 80px' }}>
                          <LoaderWrapper isLoading={reviewLoader}>
                            <Button
                              borderRadius="10px"
                              color="white"
                              bgColor="#cc6ac0"
                              borderColor="#cc6ac0"
                              margin="0px"
                              onClick={() => redirectToReview(item.id)}
                              testId="campaign-review-btn"
                            >
                              Review
                            </Button>
                          </LoaderWrapper>
                        </div>
                      </Flex>
                    )}
                  </Flex>
                  <CampaignMenu
                    testId="dpmenu-series"
                    detailsHandler={detailsHandler}
                    id={item.id}
                    title={item.name}
                    editLoader={editLoader}
                    mode={'one_time'}
                    type={'series'}
                    campaignRequest={campaignRequest}
                    status={item.status}
                    platforms={platforms}
                    isadvMode={isadvMode}
                    category={category}
                    setFilters={setFilters}
                    setSelectedCampaignTab={setSelectedCampaignTab}
                    selectedCampaignTab={selectedCampaignTab}
                    canDuplicate={item.can_duplicate}
                    userData={userData}
                    engagement_status={item.engagement_status}
                    socialNetworkData={socialNetworkData}
                    is_cascaded={item.is_cascaded}
                    approved_to_share={item.approved_to_share}
                    blockCreate={blockCreate}
                    is_member_only={item.is_member_only}
                    record_type={record_type}
                    seriesId={series_id}
                    campaignSetState={campaignSetState}
                    is_email={item.is_email}
                    is_twitter={item.is_twitter}
                    is_facebook={item.is_facebook}
                    is_linkedin={item.is_linkedin}
                    isEnterpriseLevelAdmin={isEnterpriseLevelAdmin}
                    isFirmLevelAdmin={isFirmLevelAdmin}
                    firmSettings={firmSettings}
                    segmentList={segmentList}
                    segment={item.segment}
                    seriesSubcampaigns={seriesSubcampaigns}
                    showSeries={showSeries}
                    has_email_template={item.has_email_template}
                    setShowSeries={setShowSeries}
                    is_auto_post={is_auto_post}
                    isTurnkey={isTurnkey}
                  />
                </Flex>
              </Flex>
            );
          })
        : null}
        {showSeries && (type === 'series' || record_type === 'series') && seriesSubcampaigns && seriesSubcampaigns.pagination && seriesSubcampaigns.pagination.after &&  (
        <Flex width="100%" justifyContent="center">
          <LoaderWrapper isLoading={seriesLoader}>
            <ButtonNoBorderBackground fontSize="18px" fontWeight="bold" onClick={viewMoreSubCampaigns}>
              View All
            </ButtonNoBorderBackground>
          </LoaderWrapper>
        </Flex>
        )}
    </>
  );
};
const mapStateToProps = (state) => {
  const isAdmin = getUserRole(state) === 'admin';
  const isEnterpriseAdvisor =
    getRoleType(state) === ROLE_TYPES.ADVISOR_ENTERPRISE;
    const isTurnkey =
    state.user.data.details &&
    state.user.data.details.user.turnkey &&
    state.user.data.details.user.turnkey === 'true';
  return {
    membersList: state.user.membersList && [
      {
        id: 'selectAll',
        details: {
          name: { email: 'Select All', first: 'Select', last: 'All' },
        },
      },
      ...state.user.membersList,
    ],
    emailData: get(state, 'user.data.details.user.email_personalization', null),
    isAdmin: isAdmin,
    isEnterpriseAdvisor: isEnterpriseAdvisor,
    campaignsDetailsData: state.campaignDetails.data,
    userData: state.user.data,
    socialNetworkData: state.socialNetwork.data,
    contactGroups: state.contacts.contactGroups,
    isTurnkey: isTurnkey,
  };
};

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DripDashboardListItem);
