import React from 'react';
import ReactSkeleton from 'react-loading-skeleton';
import {
  Card,
  CardHeader,
  Content,
  CardIcon,
  SocialMediaLinks,
  Analytics,
  AnalyticsItem,
} from './styles';

function Skeleton() {
  return (
    <Content>
      {Array.from(Array(4).keys())?.map((key) => (
        <Card key={key}>
          <CardIcon>
            <ReactSkeleton width={60} height={60} />
          </CardIcon>
          <CardHeader>
            <ReactSkeleton width={150} height={20} />
            <SocialMediaLinks>
              <ReactSkeleton width={18} height={18} />
              <ReactSkeleton width={18} height={18} />
              <ReactSkeleton width={18} height={18} />
              <ReactSkeleton width={18} height={18} />
            </SocialMediaLinks>
          </CardHeader>
          <Analytics>
            <AnalyticsItem>
              <ReactSkeleton width={100} height={20} />
              <ReactSkeleton width={30} height={20} />
            </AnalyticsItem>
            <AnalyticsItem>
              <ReactSkeleton width={100} height={20} />
              <ReactSkeleton width={30} height={20} />
            </AnalyticsItem>
            <AnalyticsItem>
              <ReactSkeleton width={100} height={20} />
              <ReactSkeleton width={30} height={20} />
            </AnalyticsItem>
          </Analytics>
        </Card>
      ))}
    </Content>
  );
}

export default Skeleton;
