/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import { Button, LoaderWrapper } from '..';
import { getDayMonthYear } from '../../utils';
import { isEmpty } from 'lodash';
import { MioDatePickerWrapper } from '../MioDatePicker/MioDatePicker.styles';

const generateNumerArray = (start = 1, end, currentHour = null, showTwelve=null) => {
  let arr = [];
  for (let i = showTwelve ? 1 : start; i < end; i++) {
    arr.push(i);
  }

  if(currentHour) {
    if(showTwelve) {
      arr.sort((x, y) => 12 === x ? -1 : 12 === y)
    } else {
      arr = arr.filter(a => a !== 12)
    }
  }
  return arr;
};


const DatePickerV2 = ({
  customInput,
  isOpen,
  onDateClose,
  onSubmit,
  popperModifiers,
  popperPlacement,
  fromMyClients,
  scheduleValue,
  excludeDates,
  filterDate,
  timeZone,
  maxDate,
  loading,
  hourAndMins
}) => {
  const currentDate = !isEmpty(timeZone)
    ? new Date(new Date().toLocaleString('en-US', { timeZone: timeZone }))
    : new Date();
  const currentHour = (currentDate.getHours() + 24) % 12 || 12;
  const currentMin = currentDate.getMinutes();
  const currentMeridian = currentDate.getHours() > 11 ? 'PM' : 'AM';
  const [selectedDate, setStartDate] = useState(scheduleValue ? new Date(scheduleValue) : currentDate);
  const [startHours, setStartHour] = useState(currentHour);
  const [startMins, setStartMinute] = useState(currentMin);
  const [hours, setHour] = useState(scheduleValue ? (new Date(scheduleValue).getHours() + 24) % 12 || 12 : currentHour);
  const [mins, setMinute] = useState(scheduleValue ? new Date(scheduleValue).getMinutes() : currentMin);
  const [mt, setMt] = useState(currentMeridian);
  const [meridianTimes, setMeridianTimes] = useState(['AM', 'PM']);
  const [showTwelve, setShowTwelve] = useState(false);

  console.log(hours, currentHour, timeZone);

  const onScheduleClick = () => {
    const [selectedMonth, selectedDay, selectedYear] =
      getDayMonthYear(selectedDate);
    let checkHours;
    console.log(typeof hours);
    if(mt === 'AM') {
      checkHours = hours === '12' || hours === 12 ? `00` : hours.toString().length === 1 ? `0${hours}` : hours;
    } else {
      checkHours = hours === '12' || hours === 12 ? 12 : +hours + 12;
    }
    const checkMin = mins.toString().length === 1 ? `0${mins}` : mins;
    console.log(`${selectedYear}-${selectedMonth}-${selectedDay}T${checkHours}:${checkMin}:00`);
    onSubmit(
      `${selectedYear}-${selectedMonth}-${selectedDay}T${checkHours}:${checkMin}:00`
    );
  };

  
  const onDateChange = (date) => {
    console.log(date);
    setStartDate(date);
    setHour(currentHour);
    setMinute(currentMin);
    setMt(currentMeridian);
  };

  const onHourChange = (e) => {
    const value = e.target.value;
    setHour(value);
  };

  const onMinChange = (e) => {
    const value = e.target.value;
    setMinute(value);
  };

  const onTimeChange = (e) => {
    const value = e.target.value;
    setMt(value);
  }

  useEffect(() => {
    if (!isEmpty(scheduleValue)) {
      setStartDate(new Date(scheduleValue));
      setStartHour((new Date(scheduleValue).getHours() + 24) % 12 || 12);
      setMinute(new Date(scheduleValue).getMinutes());
      setStartMinute(new Date(scheduleValue).getMinutes());
      setMt(new Date(scheduleValue).getHours() > 11 ? 'PM' : 'AM');
      setHour((new Date(scheduleValue).getHours() + 24) % 12 || 12);
    }
  }, [scheduleValue]);

  useEffect(() => {
    const [currentMonth, currentDay, currentYear] =
      getDayMonthYear(currentDate);
    const [selectedMonth, selectedDay, selectedYear] =
      getDayMonthYear(selectedDate);

    if (
      new Date(`${currentYear}-${currentMonth}-${currentDay}`) <
      new Date(`${selectedYear}-${selectedMonth}-${selectedDay}`)
    ) {
      setStartHour(1);
      setStartMinute(0);
      setShowTwelve(true);
      setMeridianTimes(['AM', 'PM']);
    } else if (
      new Date(`${currentYear}-${currentMonth}-${currentDay}`).getTime() ===
      new Date(`${selectedYear}-${selectedMonth}-${selectedDay}`).getTime()
    ) {
      if(currentMeridian === 'AM') {
        setMeridianTimes(['AM', 'PM']);
        if(currentMeridian !== mt) {
            setStartHour(1);
            setStartMinute(0);
            setShowTwelve(true);
        } else {
          if(currentHour === 12) {
            setShowTwelve(true);
          } else {
            setShowTwelve(false);
          }

          if((+hours > currentHour) || (currentHour === 12 && hours >= 1 && +hours !== 12)) {
            setStartMinute(0);
          } else {
            setStartMinute(currentMin)
          }
          console.log(currentHour, hours)
          setStartHour(currentHour);   
        }
        
          
        // if (hours !== 12 && currentHour < hours && currentMeridian === mt) {
        //   setStartMinute(0);
        //   setStartHour(currentHour);
        // } else if(hours === currentHour && currentMeridian === mt) {
        //   setStartHour(0);
        //   setStartMinute(currentMin);
        // } else if(currentMeridian !== mt) {
        //   setStartHour(0);
        //   setStartMinute(0);
        // } else {
          
        // }
      } else {
        setMeridianTimes(['PM']);
        if(currentMeridian !== mt) {
          setStartHour(1);
          setStartMinute(0);
          setShowTwelve(true);
      } else {
        if(currentHour === 12) {
          setShowTwelve(true);
        } else {
          setShowTwelve(false);
        }
        setStartHour(currentHour);
        if((+hours > currentHour) || (currentHour === 12 && hours >= 1 && +hours !== 12)) {
          setStartMinute(0);
        } else {
          setStartMinute(currentMin)
        }
      }
        // if (hours !== 12 && currentHour < hours) {
        //   setStartMinute(0);
        //   setStartHour(currentHour);
        // } else if(hours === 12 && currentHour < hours) {
        //   setStartHour(0);
        //   setStartMinute(currentMin);
        // } else {
          
        // }
      }
      
    } else {
      setStartHour(currentHour);
      setStartMinute(currentMin);
      setMeridianTimes(['AM', 'PM']);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDate, hours, mt]);
  console.log('currentDate', currentDate);
  console.log('selectedDate', selectedDate)
  return (
    <DatePicker
      open={isOpen}
      selected={selectedDate}
      onChange={onDateChange}
      shouldCloseOnSelect={false}
      onClickOutside={onDateClose}
      onCalendarClose={onDateClose}
      customInput={customInput}
      showMonthDropdown={true}
      showYearDropdown={true}
      dropdownMode="select"
      filterDate={filterDate || null}
      popperPlacement={popperPlacement || 'top'}
      minDate={
        !isEmpty(timeZone)
          ? new Date(new Date().toLocaleString('en-US', { timeZone: timeZone }))
          : new Date()
      }
      maxDate={maxDate || null}
      // adjustDateOnChange
      excludeDates={excludeDates}
      popperModifiers={
        {
          ...popperModifiers,
          computeStyle: { gpuAcceleration: false },
        }
      }
      calendarContainer={({ children }) => (
        <MioDatePickerWrapper fromMyClients={fromMyClients}>
          <div>
            <div className="date-picker-container">{children}</div>
            {hourAndMins && (
              <div>
                <select id="time-hr" onChange={onHourChange} value={hours}>
                  {generateNumerArray(startHours, 13, currentHour, showTwelve).map((hour) => (
                    <option value={hour} selected={hours === hour}>
                      {hour}
                    </option>
                  ))}
                </select>
                <select id="time-min" onChange={onMinChange} value={mins}>
                  {generateNumerArray(startMins, 60).map((min) => (
                    <option value={min} selected={mins === min}>
                      {min}
                    </option>
                  ))}
                </select>
                <select id="time-select" onChange={onTimeChange} value={mt}>
                  {meridianTimes.map((item) => (
                    <option value={item} selected={mt === item}>
                      {item}
                    </option>
                  ))}
                </select>
              </div>
            )}
            <div
              style={{
                display: 'flex',
                justifyContent:
                  'center',
              }}
            >
              <LoaderWrapper isLoading={loading}>
                <Button
                  width="80px"
                  color="#6351ed"
                  onClick={onScheduleClick}
                  className="schedule-button"
                >
                  Schedule
                </Button>
              </LoaderWrapper>
            </div>
          </div>
        </MioDatePickerWrapper>
      )}
    />
  );
};

export default DatePickerV2;
