import React from 'react'
import CampaignEmailTemplateContainer from '../../containers/CampaignEmailTemplateContainer/CampaignEmailTemplateContainer';
import withDripCampaignLayout from '../../hoc/withDripCampaignLayout';

const CampaignEmailTemplateLayout = (props) => {
    return (
        <CampaignEmailTemplateContainer {...props} />
    )
}

export default withDripCampaignLayout(CampaignEmailTemplateLayout);
