import React, { useState, useEffect } from 'react';
import { Modal, ModalHeader } from '../';

export const ErrorModal = ({ children, onClose }) => {
  const [isOpen, setOpen] = useState(true);
  useEffect(() => {
    window.setTimeout(() => {
      onModalClose();
    }, 3000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onModalClose = () => {
    setOpen(false);
    if (onClose) {
      onClose();
    }
  };

  return (
    <Modal
      onRequestClose={onModalClose}
      shouldCloseOnOverlayClick
      isOpen={isOpen}
    >
      <ModalHeader>{children}</ModalHeader>
    </Modal>
  );
};

export default ErrorModal;
