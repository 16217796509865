import React from 'react';

function Communication() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 33 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.333 20C18.5421 20 20.333 18.2091 20.333 16C20.333 13.7909 18.5421 12 16.333 12C14.1239 12 12.333 13.7909 12.333 16C12.333 18.2091 14.1239 20 16.333 20Z"
        stroke="currentColor"
        stroke-width="2.4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9.96977 22.3627C9.14855 21.5424 8.49474 20.5702 8.04477 19.5002C7.57444 18.3933 7.33203 17.2029 7.33203 16.0002C7.33203 14.7975 7.57444 13.6071 8.04477 12.5002C8.49474 11.4302 9.14855 10.458 9.96977 9.6377"
        stroke="currentColor"
        stroke-width="2.4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M22.6953 9.6377C23.5165 10.458 24.1703 11.4302 24.6203 12.5002C25.0906 13.6071 25.333 14.7975 25.333 16.0002C25.333 17.2029 25.0906 18.3933 24.6203 19.5002C24.1703 20.5702 23.5165 21.5424 22.6953 22.3627"
        stroke="currentColor"
        stroke-width="2.45"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6.4331 25.8996C5.15767 24.6218 4.13926 23.1112 3.4331 21.4496C2.70703 19.7244 2.33301 17.8714 2.33301 15.9996C2.33301 14.1278 2.70703 12.2749 3.4331 10.5496C4.13926 8.88804 5.15767 7.37739 6.4331 6.09961"
        stroke="currentColor"
        stroke-width="2.45"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M26.2334 6.09961C27.5088 7.37739 28.5272 8.88804 29.2334 10.5496C29.9595 12.2749 30.3335 14.1278 30.3335 15.9996C30.3335 17.8714 29.9595 19.7244 29.2334 21.4496C28.5272 23.1112 27.5088 24.6218 26.2334 25.8996"
        stroke="currentColor"
        stroke-width="2.45"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

export default Communication;
