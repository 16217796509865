import React from 'react';
import {
    OuterBox,
    FileUploadBox,
    UploadHeader,
    BrowseFiles,
    UploadSubheader,
    InputTitle,
    FileStyle,
    TitleInput
} from './styles';

const UploadFile = ({
    selectedFile,
    setSelectedFile,
    allowDrop,
    drop,
    setPreviewTitle,
    format
}) => {
    return(
        <div style={{display: 'table', width: '100%'}}>
            <OuterBox>
                <label style={{width: "100%"}} htmlFor="upload-file-button">
                <FileUploadBox
                    onDrop={drop}
                    onDragOver={allowDrop}
                >
                    <img alt="upload" src="/assets/images/upload-icon.svg" />
                    <UploadHeader>
                        <BrowseFiles htmlFor="upload-file-button">
                            Click to upload 
                        </BrowseFiles>
                        <input
                            type="file"
                            id="upload-file-button"
                            accept={format.types}
                            hidden
                            onChange={(e) => setSelectedFile(e.target.files[0])}
                        />
                        or drag to drop
                    </UploadHeader>
                    <UploadSubheader>Supported formats: {format.name}</UploadSubheader>
                </FileUploadBox>
                </label>
            </OuterBox>
            {selectedFile?.name && <FileStyle>{selectedFile.name}</FileStyle>}
            {setPreviewTitle && (
                <>
                    <InputTitle>Add Title</InputTitle>
                    <TitleInput onChange={(e)=>setPreviewTitle(e.target.value)}></TitleInput>
                </>)
            }
        </div>
    )
};

export default UploadFile;