/* eslint-disable react-hooks/exhaustive-deps */
import { faCheck, faPlus, faStar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Flex } from '@rebass/grid';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button, LoaderWrapper, ModalBody, ModalCloseButton, ModalHeader, ModalV2 } from '../../components';
import { TemplateCard, TemplateCardWrapper, TemplateWrapper } from '../CampaignEmailTemplateContainer/CampaignEmailTemplateContainer.styles';
import { cascadeCustomRow, deleteCustomRow, getCustomRows, postDefaultRow } from '../../services/bee-editor';
import { getRoleType, getUserRole } from '../../redux/selector';
import { MembersCheckBox } from '../../components/ShareContentContainerV2/Elements/ShareCheckBoxes';
import { getUsersList } from '../../services/analytics';
import { actions as loginActions} from '../../redux/modules/login.module';
import { get, isEmpty } from 'lodash';
import { AdvisorsListWrapper } from './CustomRowsContainer.styles';
import CustomRowsFilterBar from '../../components/CustomRowsFilterBar/CustomRowsFilterBar';
import { ROLE_TYPES } from '../../utils';
import { actions as campaignActions } from '../../redux/modules/campaign.module';
import CascadeToAdvisorsModal from '../../components/CascadeToAdvisorsModal/CascadeToAdvisorsModal';

const CustomRowsContainer = ({ 
    userData, 
    isAdmin, 
    membersList, 
    loginSetState, 
    isEnterpriseAdvisor,
    campaignSetState
 }) => {

    const [openModal, toggleModal] = useState(false);
    const [selectedRowType, setSelectedRowType] = useState('');
    const [selectedLayout, setSelectedLayout] = useState('one-column');
    const [customUserRows, setCustomUserRows] = useState([]);
    // const [selectedCustomRow, setSelectedCustomRow] = useState('');
    // const [selectedAdvisors, setSelectedAdvisors] = useState([]);
    // const [OGSelectedAdvisors, setOGSelectedAdvisors] = useState([]);
    const [pageLoader, togglePageLoader] = useState(true);
    const [shareModal, toggleShareModal] = useState({status: false, id: ''});
    const [deleteModal, toggleDeleteModal] = useState({status: false, id: ''});
    const [deleteLoader, toggleDeleteLoader] = useState(false);
    const [shareLoader, toggleShareLoader] = useState(false);
    const [shareModalMessage, setShareModalMessage] = useState('');
    const [shareDisabled, toggleShareDisabled] = useState(false);
    const [showDefaultBtn, toggleDefaultBtn] = useState('');
    const [defaultLoader, toggleDefaultLoader] = useState(false);
    const [selectedFilter, setSelectedFilter] = useState('all');
    const history = useHistory();


    useEffect(() => {
        const windowBodyClassList = document.body.classList;
            if (!windowBodyClassList.contains('campaign')) {
                windowBodyClassList.add('campaign');
            }
        
        return () => {
            windowBodyClassList.remove('campaign');
        };
    }, []);


    useEffect(() => {
        getUsersList('all')
        .then((data) => {
            const membersList = get(data, 'result.data.users', []);
            loginSetState({ membersList });
        })
        .catch(err => console.log('ERR- - - - - -', err));
        campaignSetState({
            campaignId: '',
            createFlow: false,
            createMode: false,
            curFlow: '',
            selectedIndex: 0,
            setObjectives: {},
            setTopics: {},
            contentWeeks: [],
            planCampaign: {},
            campaignArticles: [],
            scheduleTimings: {},
            selectedWeek: '',
            selectedDays: '',
            selectedArticles: [],
            publishedContent: {},
            defaultTemplates: [],
            setTemplate: '',
            savedTemplate: null,
            scheduleTypes: {},
            isEdit: false,
            seriesId: '',
            isFromAddCampaign: false,
            reviewCampaign:{}
        });
    }, []);


    useEffect(() => {
        fetchCustomRows();
    }, []);


    useEffect(() => {
        if(selectedRowType !== 'article_block')
            setSelectedLayout('one-column');
    }, [selectedRowType]);


    useEffect(() => {
        fetchCustomRows(selectedFilter);
    }, [selectedFilter]);

    
    const fetchCustomRows = filter => {
        togglePageLoader(true);
        getCustomRows(filter)
        .then(response => {
            console.log('CUSTOM ROWS RESPONSE -- - - - ', response);
            if(response && response.data) 
                setCustomUserRows(response.data);
            togglePageLoader(false);
        })
        .catch(err => {
            console.log('ERROR - - - - - --  -', err);
            togglePageLoader(false);
        });
    }

    // const selectMembersHandler = (e, selectedMember) => {
    //     console.log('SELECTED MEMBER - - - -', selectedMember, '  ', e.target.checked);
    //     let updatedAdvisorsList = [...selectedAdvisors];
    //     if(selectedMember.id === 'selectAll') {
    //         if(e.target.checked)
    //             updatedAdvisorsList = membersList.map(member => member.id);
    //         else
    //             updatedAdvisorsList = [];
    //         // else
    //         //     updatedAdvisorsList = [...OGSelectedAdvisors];
    //     }
    //     else if(selectedAdvisors.includes(selectedMember.id))
    //         updatedAdvisorsList = [...selectedAdvisors].filter(advisorId => advisorId!==selectedMember.id && advisorId!=='selectAll');
    //     else {
    //         updatedAdvisorsList = [...selectedAdvisors, selectedMember.id];
    //         if(updatedAdvisorsList.length === membersList.length-1) 
    //             updatedAdvisorsList.push(membersList[0].id); // push 'Select All' into advisorsList to check selectAll checkbox
    //     }
    //     setSelectedAdvisors(updatedAdvisorsList);
    // }

    // const cascadeClickHandler = id => {
    //     console.log('MEMBERS LIST - -- -', membersList);
    //     let advisorsList = [];
    //     const selectedCustomRow = customUserRows.find(row => row._id === id);
    //     if(selectedCustomRow.cascaded_to)
    //         advisorsList = [...selectedCustomRow.cascaded_to];
    //     setSelectedCustomRow(id);
    //     setOGSelectedAdvisors(advisorsList);
    //     if(membersList && advisorsList.length === membersList.length-1)
    //         setSelectedAdvisors(membersList.map(member => member.id));
    //     else
    //         setSelectedAdvisors(advisorsList);
    //     // console.log('SELECTED TEMPLATE -- - - ', selectedTemplate, 'ADVISORS LIST -- - - ', advisorsList);
    //     toggleShareModal(true);
    // }

    // const shareValidationCheck = () => {
    //     // console.log('IS SAME = = = =', selectedAdvisors.filter(adv => adv!=='selectAll').length === OGSelectedAdvisors.length)
    //     let disableShare = true;
    //     if(selectedAdvisors.includes('selectAll')) 
    //         disableShare = selectedAdvisors.filter(adv => adv!=='selectAll').length === OGSelectedAdvisors.length;
    //     else if(selectedAdvisors.length !== OGSelectedAdvisors.length)
    //         disableShare = false;
    //     else
    //         disableShare = selectedAdvisors.every(adv => OGSelectedAdvisors.includes(adv));
    //     return disableShare;
    // }

    const closeModalHandler = () => {
        toggleModal(false);
        setSelectedRowType('');
        setSelectedLayout('one-column');
    }

    const closeShareModalHandler = () => {
        toggleShareLoader(false);
        // setSelectedAdvisors([]);
        // setOGSelectedAdvisors([]);
        setShareModalMessage('');
        toggleShareModal({status: false, id: ''});
        toggleShareDisabled(false);
    }

    const cascadeCustomRowHandler = advArr => {
        toggleShareLoader(true);
        setShareModalMessage('');
        let advisorsArray = [...advArr];
        console.log(advArr)
        if(advisorsArray.includes('selectAll'))
            advisorsArray = advisorsArray.filter(c => c !== "selectAll");
        console.log(advisorsArray);
        const payload = {
            users: {
                cascaded_to: JSON.stringify(advisorsArray)
            },
            id: shareModal.id
        };
        cascadeCustomRow(payload)
        .then(response => {
            console.log('RESPONSE - - - -- ', response);
            closeShareModalHandler();
            fetchCustomRows();
        })
        .catch(err => {
            console.log('CASCADE ERROR - - --', err);
            setShareModalMessage('Error cascading! Please try again later.');
            toggleShareLoader(false);
        });
    }

    const nextHandler = () => {
        if(selectedRowType === 'article_block')
            history.push(`/campaign/custom-row/new-${selectedRowType}?layout=${selectedLayout}`);
        else
            history.push(`/campaign/custom-row/new-${selectedRowType}`);
    }

    const deleteHandler = () => {
        console.log('ID = = - - ', deleteModal.id);
        toggleDeleteLoader(true);
        deleteCustomRow({id: deleteModal.id})
        .then(response => {
            toggleDeleteLoader(false);
            toggleDeleteModal({status: false, id: ''});
            fetchCustomRows();
        })
        .catch(err => console.log('DELETE ERROR - -- - ', err));
    }

    const makeDefaultHandler = (id, rowCategory) => {
        console.log('MAKE DEFAULT - -  --', id);
        toggleDefaultLoader(true);
        const payload = {
            category: rowCategory
        }
        postDefaultRow({ id, payload })
        .then(response => {
            toggleDefaultLoader(false);
            fetchCustomRows();
        })
        .catch(err => {
            console.log('ERROR - - - - -', err);
            toggleDefaultLoader(false);
        })
    }

    const getRowCategoryText = category => {
        if (category) {
            let [first, second] = category.split('_');
            // console.log('FIRST - -- - ', first[0], 'SECOND - -- - - -', second);
            first = first[0].toUpperCase() + first.slice(1);
            if (second) {
                second = second[0].toUpperCase() + second.slice(1);
                return `${first} ${second}`;
            }
            return first;
        } else {
            return '';
        }
    }

    return (
        <TemplateWrapper width="100%" flexDirection='column'>
            <CustomRowsFilterBar selected={selectedFilter} changeHandler={e => setSelectedFilter(e.target.value)} />
            <TemplateCardWrapper>
                <TemplateCard onClick={() => !defaultLoader ? toggleModal(true) : {}} style={{cursor: 'pointer'}}>
                    <Flex height="85%" alignItems="center" justifyContent="center">
                        <FontAwesomeIcon icon={faPlus} size="3x" color='#000000' />
                    </Flex>
                    <Flex justifyContent="center">
                        Create New Custom Row
                    </Flex>
                </TemplateCard>
                <LoaderWrapper 
                    isLoading={pageLoader} 
                    styles={{position: 'relative', top: '-70%', left: '2%'}}
                >
                    {customUserRows && customUserRows.map(rowObj => (
                        <TemplateCard 
                            id={rowObj._id} 
                            style={{cursor: 'pointer', position: 'relative'}}
                            onMouseEnter={() => !defaultLoader ? toggleDefaultBtn(rowObj._id) : {}}
                            onMouseLeave={() => !defaultLoader ? toggleDefaultBtn('') : {}}
                        >
                            <Flex 
                                justifyContent={rowObj.is_default ? 'flex-end' : 'center'}
                                alignItems='center'
                                padding='0px 6px' 
                                width='100%' 
                                style={{height: '10%', marginTop: '8px'}}
                            >
                                <span style={{width: '62%', fontSize: '12px', fontStyle: 'italic'}}>
                                    {getRowCategoryText(rowObj.category)} {!isAdmin && !isEmpty(rowObj.cascaded_to) && '(Cascaded)'}
                                </span>
                                {rowObj.is_default && (
                                    <>
                                        <p style={{fontSize: '10px'}}>Default</p>
                                        <FontAwesomeIcon 
                                            style={{margin: '0px 5px', width: '11px'}} 
                                            color='#6351ed' 
                                            icon={faCheck} 
                                        />
                                    </>
                                )}
                            </Flex>
                            <img 
                                height='75%' 
                                src={rowObj.thumbnail_url} 
                                style={{
                                    objectFit: 'contain'
                                    // objectFit: rowObj.category === 'article_block' ? 'contain' : 'none'
                                }}
                                alt="" 
                            />
                            {showDefaultBtn === rowObj._id && (
                                <Flex 
                                    justifyContent='center'
                                    alignItems='flex-start'
                                    paddingTop='20px'
                                    style={{
                                        position: 'absolute', 
                                        top: '0', 
                                        left: '0', 
                                        backgroundColor: '#000', 
                                        opacity: defaultLoader ? '0.8' : '0.5',
                                        height: '85%',
                                        width: '100%'
                                    }}
                                >
                                    <LoaderWrapper isLoading={defaultLoader}>
                                        {(isAdmin || isEmpty(rowObj.cascaded_to)) && !rowObj.is_default && (
                                            <Button 
                                                bgColor='transparent' 
                                                styles={{ 
                                                    border: '1px solid #fff', color: '#fff', marginRight: '20px' }}
                                                onClick={() => makeDefaultHandler(rowObj._id, rowObj.category)}
                                            >
                                                Make Default
                                            </Button>
                                        )}
                                        <Button
                                            bgColor='#fff' 
                                            styles={{ border: '1px solid #fff', color: '#000', opacity: '1 !important' }}
                                            onClick={() => {
                                                history.push(`/campaign/custom-row/${rowObj._id}?category=${rowObj.category}&layout=${rowObj.tag}`);
                                            }}
                                        >
                                            Open/Edit
                                        </Button>
                                    </LoaderWrapper>
                                </Flex>
                            )}
                            <Flex 
                                padding='0px 15px' 
                                justifyContent='space-between' 
                                width='100%' 
                                alignItems='center'
                            >
                                <div style={{ width: isAdmin && rowObj.category === 'article_block' ? '75%' : '85%' }}>
                                    {rowObj?.name.length > 29 ? `${rowObj.name.substring(26)}...` : rowObj.name}
                                </div>
                                <Flex 
                                    width={isAdmin ? '25%' : '15%'} 
                                    justifyContent={isAdmin ? 'space-evenly' : 'flex-end'} 
                                    alignItems='center'
                                >
                                    {(isAdmin || isEmpty(rowObj.cascaded_to)) && !rowObj.is_default && (
                                        <img 
                                            alt="delete"
                                            src='/assets/images/delete.svg' 
                                            onClick={() => toggleDeleteModal({status: true, id: rowObj._id})} 
                                            style={{cursor: 'pointer'}} 
                                        />
                                    )}
                                    {isAdmin && (
                                        <img 
                                            src='/assets/images/forward.svg' 
                                            alt=""
                                            onClick={() => toggleShareModal({status: true, id: rowObj._id})} 
                                            // onClick={() => cascadeClickHandler(rowObj._id)} 
                                            // onMouseEnter={() => toggleTooltip('')}
                                            style={{
                                                cursor: 'pointer', 
                                                width: '22px', 
                                            }} 
                                        />
                                    )}
                                </Flex>
                            </Flex>
                        </TemplateCard>
                    ))}
                </LoaderWrapper>
            </TemplateCardWrapper>
            {shareModal.status && (
                <>
                {/* <ModalV2 
                    isOpen={shareModal} 
                    width='27%' 
                    height='auto' 
                    onRequestClose={closeShareModalHandler}
                    style={{maxHeight: '90%'}}
                    bgColor='#fff'
                >
                    <ModalHeader fontColor='#000'>
                        Share Custom Row with the following advisors
                        <ModalCloseButton onClick={closeShareModalHandler} />
                    </ModalHeader>
                    <ModalBody>
                        <AdvisorsListWrapper>
                            {!isEmpty(membersList) && (
                                <MembersCheckBox
                                    membersList={membersList}
                                    handleAdvisorChange={selectMembersHandler}
                                    selectedMembers={selectedAdvisors}
                                    ogSelectedMembers={OGSelectedAdvisors}
                                    fontSize='12px'
                                    fromCampaignTemplate={true}
                                    maxHeight='400px'
                                    overflowY='auto'
                                />
                            )}
                        </AdvisorsListWrapper>
                        {shareModalMessage && (
                            <div style={{color: '#6351ed', fontSize: '12px', paddingTop: '10px'}}>*{shareModalMessage}</div>
                        )}
                        <LoaderWrapper isLoading={shareLoader}>
                            <Button disabled={shareDisabled || shareValidationCheck()} onClick={cascadeCustomRowHandler}>Share</Button>
                        </LoaderWrapper>
                    </ModalBody>
                </ModalV2> */}
                <CascadeToAdvisorsModal 
                    modalTitle='Share Custom Row with the following advisors'
                    loader={shareLoader}
                    OGSelectedAdvisors={customUserRows.find(row => row._id === shareModal.id)?.cascaded_to || []}
                    membersList={membersList}
                    disableShare={shareDisabled}
                    shareHandler={cascadeCustomRowHandler}
                    modalMessage={shareModalMessage}
                    closeHandler={closeShareModalHandler}
                    isOpen={shareModal.status}
                    modalWidth='31%'
                />
                </>
            )}
            {deleteModal.status && (
                <ModalV2 width='40%' isOpen={deleteModal} onRequestClose={() => toggleDeleteModal({status: false, id: ''})}>
                    <ModalHeader>
                        Are you sure you want to delete this row?
                        <ModalCloseButton onClick={() => toggleDeleteModal({status: false, id: ''})} />
                    </ModalHeader>
                    <ModalBody>
                        <Flex justifyContent='space-evenly' alignItems='center' width='35%' margin='auto'>
                            <LoaderWrapper isLoading={deleteLoader}>
                                <Button onClick={deleteHandler}>Yes</Button>
                                <Button onClick={() => toggleDeleteModal({status: false, id: ''})}>No</Button>
                            </LoaderWrapper>
                        </Flex>
                    </ModalBody>
                </ModalV2>
            )}
            {openModal && (
                <ModalV2 
                    bgColor='#fff' 
                    width='40%' 
                    isOpen={openModal} 
                    onRequestClose={closeModalHandler}
                >
                    <ModalHeader>
                        Choose Row Type
                        <ModalCloseButton onClick={closeModalHandler} />
                    </ModalHeader>
                    <ModalBody>
                        <Flex 
                            justifyContent='space-around' 
                            alignItems='center' 
                            width='50%' 
                            margin='auto'
                        >
                            {/* <p style={{fontSize: '14px'}}>Select Row Type: </p> */}
                            <select 
                                style={{
                                    color: '#6351ed', 
                                    border: '1px solid #adadad', 
                                    fontSize: '14px',
                                    padding: '4px',
                                    width: '65%'
                                }} 
                                name="row-type" 
                                id="row_type"
                                defaultValue='none'
                                onChange={e => setSelectedRowType(e.target.value)}
                            >
                                <option value="none" disabled hidden>Select an Option</option>
                                <option value="article_block">Article Row</option>
                                <option value='content_block'>Content Block</option>
                                <option value="disclaimer">Disclaimer Row</option>
                                <option value="footer">Footer Row</option>
                                <option value="header">Header Row</option>
                                <option value="signature">Signature Row</option>
                                <option value="webinar_block">Webinar Block</option>
                                <option value="landing_page">Landing Page</option>
                                <option value="disclosure">Disclosure Page</option>
                            </select>
                        </Flex>
                        {selectedRowType === 'article_block' && (
                            <Flex 
                                justifyContent='center' 
                                alignItems='center' 
                                width='100%' 
                                margin='auto'
                                fontSize='14px' 
                            >
                                <p style={{marginRight: '10px'}}>Select Layout: </p>
                                <input 
                                    type='radio' 
                                    id="one_column" 
                                    name="layout" 
                                    value="one-column" 
                                    onChange={e => setSelectedLayout(e.target.value)} 
                                    style={{marginBottom: '3px'}}
                                    defaultChecked
                                />
                                <label 
                                    htmlFor='one_column' 
                                    style={{marginRight: '10px', cursor: 'pointer'}}
                                >
                                    One Column
                                </label>
                                <input 
                                    type='radio' 
                                    id="two_columns" 
                                    name="layout" 
                                    value="two-columns" 
                                    onChange={e => setSelectedLayout(e.target.value)} 
                                    style={{marginBottom: '3px'}} 
                                />
                                <label 
                                    htmlFor='two_columns' 
                                    style={{marginRight: '10px', cursor: 'pointer'}}
                                >
                                    Two Columns
                                </label>
                                <input 
                                    type='radio' 
                                    id="one_column_reverse" 
                                    name="layout" 
                                    value="one-column-reverse" 
                                    onChange={e => setSelectedLayout(e.target.value)} 
                                    style={{marginBottom: '3px'}} 
                                />
                                <label 
                                    style={{cursor: 'pointer'}} 
                                    htmlFor='one_column_reverse'
                                >
                                    One Column Reverse
                                </label>
                            </Flex>
                        )}
                        <Button 
                            disabled={!selectedRowType || (selectedRowType === 'article_block' && !selectedLayout)}
                            onClick={nextHandler}
                        >
                            Next
                        </Button>
                    </ModalBody>
                </ModalV2>
            )}
        </TemplateWrapper>
    );
}

const mapStateToProps = state => {
    const isAdmin = getUserRole(state) === 'admin';
    const roleType = getRoleType(state);
    const isEnterpriseAdvisor = roleType === ROLE_TYPES.ADVISOR_ENTERPRISE;
    return {
        membersList: state.user.membersList && [
            {
              id: 'selectAll',
              details: {
                name: { email: 'Select All', first: 'Select', last: 'All' },
              },
            },
            ...state.user.membersList,
        ],
        userData: state.user.data,
        isAdmin: isAdmin,
        isEnterpriseAdvisor: isEnterpriseAdvisor
    };
}

export const mapDispatchToProps = {
    campaignSetState: campaignActions.api.campaign.setstate,
    loginSetState: loginActions.api.login.setstate,
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomRowsContainer);