import React from 'react';
import { IconButton, Tooltip } from '@mui/material';
import InfoIcon from 'src/assets/icons/Insights/InfoIcon';
import { TableTooltip, TooltipBody, TooltipStyles } from './styles';

function EngagementScoreTooltip({ selectedTab }) {
  const tooltipContent = () => {
    if (selectedTab === 'CONTENT') {
      return (
        <p>
          Content engagement is the average score of clicks for a specific piece
          of content. <br /> Example: A content has engagement score of 70 and 0
          across two campaigns. The content engagement score is 35.
        </p>
      );
    } else
      return (
        <p>
          Contact Engagement score is the cumulative engagement across all
          campaigns sent to a contact or contact group. <br />
          Example: A user has engagement score of 50 and 60 across two
          campaigns. Their contact engagement score is 55.
        </p>
      );
  };

  return (
    <TableTooltip>
      <p>Engagement Score</p>
      <Tooltip
        PopperProps={{
          sx: TooltipStyles,
        }}
        title={<TooltipBody>{tooltipContent()}</TooltipBody>}
        arrow
        placement="bottom"
      >
        <IconButton>
          <InfoIcon />
        </IconButton>
      </Tooltip>
    </TableTooltip>
  );
}

export default EngagementScoreTooltip;
