/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { 
    WhiteButton,
    NewListCard,
    NewListIcon,
    NewListTitle,
    NewListBody,
    NewListSubtitle,
    TextInput,
    TextLabel,
    DeleteIcon,
    AddMoreText,
    AddIconCircle,
    AddContainer,
    AddButton,
    ErrorMessage,
    ButtonContainer,
    SpaceSaver
} from './EmailList.styles';
import {
    RadioLabel,
    RadioStyle
} from '../AdminConfigureContainer.styles'
import uploadIcon from './uploadIcon.svg';
import emailIcon from './emailIcon.svg';
import trashIcon from './trashIcon.svg';
import addMore from './addMore.svg';
import { listAllTopics, uploadCsvContactList } from '../../../services/segment';
import { checkEmailListName, updateContacts } from '../../../services/configure';
import { objectToFormData } from '../../../services/helpers';
import { parse } from 'json2csv';
import SearchContainer from '../../../componentsV2/SearchContainer';
import UploadFile from '../../../components/UploadFile';
import Papa from 'papaparse';
import { convertToCsv } from '../../../services/configure';
import { Flex } from '@rebass/grid';
import { get, isEmpty } from 'lodash';
import  ReactSelect from 'react-select';
import { LoaderWrapper } from '../../../components';
import uniq from 'lodash/uniq';

const landingContent = [
    {
        title: 'Upload a List',
        icon: uploadIcon,
        body: 'Upload a .csv file of contacts to create an email list',
        button: 'upload'
    },
    {
        title: 'Manually Add Contacts',
        icon: emailIcon,
        body: 'Add individual email IDs to create an email list',
        button: 'manual'
    }
];

const validateEmail = (email) => {
    const pattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return pattern.test(email);
};

const getSelectStyles = (border) => ({
    container: (base) => ({
      ...base,
      width: '100%',
    }),
    control: (base) => ({
      ...base,
      border: border,
    }),
    dropdownIndicator: (base) => ({
      ...base,
      color: '#848484',
    }),
});

const AddNewList = ({ 
    setTitle,
    closeModal,
    setTableLoading,
    editList = false,
    editContact,
    selectedEmailList,
    fetchContacts,
    setListLoading,
    modalDisplay, 
    setModalDisplay,
    contactsCreate,
    setSelectedEmailListItem,
    setNewContacts,
    setContactsTrigger
}) => {
    const [newListName, setNewListName] = useState();
    const [searchString, setSearchString] = useState('');
    const [emailListType, setEmailListType] = useState('prospect');
    const [selectedTags, setSelectedTags] = useState(['prospect']);
    const [listNameError, setListNameError] = useState('');
    const [searchTagsArr, setSearchTagsArr] = useState([]);
    const [emailColumnName, setEmailColumnName] = useState('');
    const [firstNameColumnName, setFirstNameColumnName] = useState('');
    const [lastNameColumnName, setLastNameColumnName] = useState('');
    const [suffixColumnName, setSuffixColumnName] = useState('');
    const [csvHeaders, setCsvHeaders] = useState([]);  
    const [csvData, setCsvData] = useState([]);
    const [csvLoading, setCsvLoading] = useState(false);
    const [contactType, setContactType] = useState('');
    const [previewData, setPreviewData] = useState([
        {first_name: '', last_name: '', email: '', suffix: '', tags: ''},
        {first_name: '', last_name: '', email: '', suffix: '', tags: ''},
        {first_name: '', last_name: '', email: '', suffix: '', tags: ''},
        {first_name: '', last_name: '', email: '', suffix: '', tags: ''},
        {first_name: '', last_name: '', email: '', suffix: '', tags: ''},
      ])
    const [tagsColumnName, setTagsColumnName] = useState('');
    const [newEmailData, setNewEmailData] = useState({
        0: {email: '', first_name: '', last_name: ''},
        1: {email: '', first_name: '', last_name: ''},
        2: {email: '', first_name: '', last_name: ''}
    });
    const [selectedFile, setSelectedFile] = useState(null);
    const [emphasis, setEmphasis] = useState(false);
    const [fileName, setFileName] = useState();

    const hiddenFileInput = React.useRef(null);

    const options = csvHeaders.map((item, index) => {
        return {
          label: item,
          value: item,
        };
    });

    useEffect(()=> {
        getTopics()
        if(editList) {
            setModalDisplay('manual')
            setNewEmailData({0: {
                email: editContact[0]?.email, 
                current_email: editContact[0]?.email,
                first_name: editContact[0]?.name?.first, 
                last_name: editContact[0]?.name?.last}
            })
            setSelectedTags(editContact[0].explicit_tags)
            if(editContact[0].explicit_tags.includes('prospect')) setEmailListType('prospect')
            else if(editContact[0].explicit_tags.includes('client')) setEmailListType('client')
        } else if(modalDisplay === 'addList'){
            setNewEmailData({0: {
                email: "", 
                first_name: "", 
                last_name: ""}
            })
        }
        setContactsTrigger(false);
    },[]);

    useEffect(()=> {
       if(selectedFile) parseSelectedFile(selectedFile)
    },[selectedFile]);

    const handleChange = (event) => {
        if (!isEmpty(event.target.files)) {
            setSelectedFile(event.target.files);
            let file = event.target.files[0];
            setFileName(file.name)
            if(file?.type !== 'text/csv') {
                let payload = {
                    media1: file
                }
                setCsvLoading(true);
                convertToCsv(objectToFormData(payload)).then(res => {
                    processCsv(res);
                    setCsvLoading(false);
                }).catch(err => {
                    setCsvLoading(false);
                });
            } else {
                processCsv(file);
            } 
        }
    };

    const changeColumnName = (setValue, value, type) => {
        if(value) {
          setValue(value);
          if(type === 'email') {
            let newEmailColumnData = csvData.map((item, index) => {
              return {
                'email': item[value.value]
              }
            }).slice(0, 5);
            let newPreviewDataOne = previewData.map((item, index) => {
              return {
                ...item,
                'email': get(newEmailColumnData[index], 'email', ''),
              }
            });
            setPreviewData(newPreviewDataOne)
          }
    
          if(type === 'firstName') {
            let newFirstNameColumnData = csvData.map((item, index) => {
              return {
                'first_name': item[value.value]
              }
            }).slice(0, 5);
            let newPreviewDataTwo = previewData.map((item, index) => {
              return {
                ...item,
                'first_name': get(newFirstNameColumnData[index], 'first_name', ''),
              }
            });
            setPreviewData(newPreviewDataTwo)
          }
    
          if(type === 'lastName') {
            let newLastNameColumnData = csvData.map((item, index) => {
              return {
                'last_name': item[value.value]
              }
            }).slice(0, 5);
            let newPreviewDataThree = previewData.map((item, index) => {
              return {
                ...item,
                'last_name': get(newLastNameColumnData[index], 'last_name', ''),
              }
            });
            setPreviewData(newPreviewDataThree)
          }
    
          if(type === 'suffix') {
            let newSuffixColumnData = csvData.map((item, index) => {
              return {
                'suffix': item[value.value]
              }
            }).slice(0, 5);
            let newPreviewDataFour = previewData.map((item, index) => {
              return {
                ...item,
                'suffix': get(newSuffixColumnData[index], 'suffix', ''),
              }
            });
            setPreviewData(newPreviewDataFour)
          }
    
          if(type === 'tags') {
            let newTagColumnData = []
            value.forEach((element, index) => {
              if (index === 0) {
                newTagColumnData = csvData.map((item, csvIndex) => {
                  return {
                    'tags': item[element.value]
                  }
                }).slice(0, 5);
              } else if(!isEmpty(newTagColumnData)) {
                newTagColumnData = csvData.map((item, csvIndex) => {
                  return {
                    'tags': item[element.value] && !isEmpty(get(newTagColumnData[csvIndex], 'tags', ''))  ? get(newTagColumnData[csvIndex], 'tags', '') + ',' + item[element.value] : item[element.value] && isEmpty(get(newTagColumnData[csvIndex], 'tags', '')) ? item[element.value] : get(newTagColumnData[csvIndex], 'tags', '')
                  }
                }).slice(0, 5);
              }
             
            });
    
            let newPreviewDataFive = previewData.map((item, index) => {
              return {
                ...item,
                'tags': get(newTagColumnData[index], 'tags', ''),
              }
            });
    
            setPreviewData(newPreviewDataFive);
          }
    
        } else {
          setValue(null)
          if(type === 'tags') {
            let clearTags = previewData.map((item, index) => {
              return {
                ...item,
                'tags' : ''
              }
            });
            setPreviewData(clearTags);
          }
          if(type === 'email') {
            let clearEmail = previewData.map((item, index) => {
              return {
                ...item,
                'email' : ''
              }
            });
            setPreviewData(clearEmail);
          }
          if(type === 'firstName') {
            let clearFirstName = previewData.map((item, index) => {
              return {
                ...item,
                'first_name' : ''
              }
            });
            setPreviewData(clearFirstName);
          }
          if(type === 'lastName') {
            let clearLastName = previewData.map((item, index) => {
              return {
                ...item,
                'last_name' : ''
              }
            });
            setPreviewData(clearLastName);
          }
          if(type === 'suffix') {
            let clearSuffix = previewData.map((item, index) => {
              return {
                ...item,
                'suffix' : ''
              }
            });
            setPreviewData(clearSuffix);
          }
        }
       
      }

    const parseSelectedFile = async(file) => {
        if (file) {
            file.name && setFileName(file.name)
            if(file.type === 'text/csv' || typeof file === 'string'){
                Papa.parse(file, {
                    header: true,
                    skipEmptyLines: true,
                    complete: function (results) {
                        setCsvData(get(results, 'data', []));
                        setCsvHeaders(Object.keys(results?.data[0]));
                        setPreviewData([
                            {first_name: '', last_name: '', email: '', suffix: '', tags: ''},
                            {first_name: '', last_name: '', email: '', suffix: '', tags: ''},
                            {first_name: '', last_name: '', email: '', suffix: '', tags: ''},
                            {first_name: '', last_name: '', email: '', suffix: '', tags: ''},
                            {first_name: '', last_name: '', email: '', suffix: '', tags: ''},
                        ]);
                        setFirstNameColumnName('');
                        setEmailColumnName('');
                        setSuffixColumnName('');
                        setLastNameColumnName('');
                        setTagsColumnName('')
                    }
                });
            } else {
                try{
                    const response = await convertToCsv(objectToFormData({media1: file}))
                    parseSelectedFile(response)
                } catch(err){
                    console.log('xlsx upload:', err)
                }
                
            }
        }
    };


  const handleCreateContacts = async() => {
    let tagsColumnData = [];
        if(!isEmpty(tagsColumnName)) {
            tagsColumnName.forEach((element, index) => {
                if (index === 0) {
                    tagsColumnData = csvData.map((item, csvIndex) => {
                        return {
                        'tags': item[element.value]
                        }
                    });
                } else if(!isEmpty(tagsColumnData)) {
                    tagsColumnData = csvData.map((item, csvIndex) => {
                        return {
                        'tags': item[element.value] && !isEmpty(get(tagsColumnData[csvIndex], 'tags', ''))  ? get(tagsColumnData[csvIndex], 'tags', '') + '|' + item[element.value] : item[element.value] && isEmpty(get(tagsColumnData[csvIndex], 'tags', '')) ? item[element.value] : get(tagsColumnData[csvIndex], 'tags', '')
                        }
                    });
                }
            });
        }

        let reformatedCsvData = csvData.map(item => {
            return {
                'first_name': get(item, `${[firstNameColumnName?.value]}`, ''), 
                'last_name': get(item, `${[lastNameColumnName?.value]}`, ''),
                'email': get(item, `${[emailColumnName?.value]}`, ''), 
                'suffix': get(item, `${[suffixColumnName?.value]}`, ''),
            }
        });

        if(!isEmpty(tagsColumnData)) {
            reformatedCsvData = reformatedCsvData.map((item, index) => {
                return {
                    ...item,
                    'tags': get(tagsColumnData[index], 'tags', '')
                }
            })
        }
        let finalCsv;
        if(!isEmpty(tagsColumnData)) {
            console.log('Tag Csv');
            finalCsv = parse(reformatedCsvData, {
                fields: ['first_name', 'last_name', 'email', 'suffix', 'tags'],
            });
        } else {
            console.log('normal csv');
            finalCsv = parse(reformatedCsvData, {
                fields: ['first_name', 'last_name', 'email', 'suffix'],
            });
        }

        const csvBlob = new Blob([finalCsv], { type: 'text/csv' });
        const csvFile = new File([csvBlob], 'email_list.csv', {
            type: 'text/csv',
            lastModified: new Date(),
        });

        let list = await objectToFormData({
            media1: csvFile,
            tags: JSON.stringify(uniq(selectedTags))
        });

        let idResponse;
        try{
            setTableLoading(true)
            setListLoading(true)
            idResponse = await checkEmailListName(newListName, emailListType)
            if(idResponse.result.success){
                closeModal()
                const submitId = idResponse.result.data.id;
                await uploadCsvContactList(submitId, list)
            }
            await fetchContacts();
            setTableLoading(false)
            setListLoading(false)
            setContactsTrigger(true);
        } catch(err){
            console.log('upload error:', err)
            setListNameError(idResponse.message)
        }
    };


    const processCsv = (file) => {
        Papa.parse(file, {
          header: true,
          skipEmptyLines: true,
          complete: function (results) {
            setCsvData(get(results, 'data', []));
            setCsvHeaders(Object.keys(results?.data[0]));
            setPreviewData([
              {first_name: '', last_name: '', email: '', suffix: '', tags: ''},
              {first_name: '', last_name: '', email: '', suffix: '', tags: ''},
              {first_name: '', last_name: '', email: '', suffix: '', tags: ''},
              {first_name: '', last_name: '', email: '', suffix: '', tags: ''},
              {first_name: '', last_name: '', email: '', suffix: '', tags: ''},
            ]);
            setFirstNameColumnName('');
            setEmailColumnName('');
            setSuffixColumnName('');
            setLastNameColumnName('');
            setTagsColumnName('')
          }
        });
      }

    const allowDrop = (event) => {
        setEmphasis(!emphasis);
        event.preventDefault();
    };

    const drop = (event) => {
        event.preventDefault();
        const data = event.dataTransfer.files[0];
        setSelectedFile(data);
        setEmphasis(false);
    };


    const getTopics = async() => {
        const topics = await listAllTopics();
        setSearchTagsArr(topics.result.data)
    }

    const modalFlow = () => {
        switch(modalDisplay){
            case 'start':
                return initialDisplay();
            case 'manual':
                setTitle(editList ? 'Edit Contact' : 'Add Contacts')
                return manualDisplay();
            case 'addList':
                setTitle('Add Contacts')
                return addDisplay();
            case 'selectFields':
                setTitle('Match Column Titles')
                return selectFieldsDisplay();
            case 'upload':
                setTitle('Upload Contacts')
                return uploadDisplay();
            case 'submitList':
                setTitle('Create Email List')
                return submitListDisplay();
            default:
                return initialDisplay();
        }
    };

    const deleteRow = (index) => {
        let output = newEmailData
        delete output[index]
        setNewEmailData({...output}) 
    };

    const uploadDisplay = () => {
        return(
            <div style={{width: 550}}>
                <UploadFile
                    selectedFile={selectedFile} 
                    setSelectedFile={setSelectedFile}
                    allowDrop={allowDrop}
                    drop={drop}
                    format={{name: 'CSV, XLSX', types: '.csv, .xlsx'}}
                />
                <ButtonContainer>
                    <WhiteButton 
                        style={{width: '49%', height: 40, padding: '10px 0 0px'}} 
                        children={'Back'} 
                        onClick={()=>setModalDisplay('select')} 
                    />
                    <AddButton 
                        style={{ width: '49%', height: 40, margin: '10px 0 0'}} 
                        children={'Upload'} 
                        onClick={()=>setModalDisplay('selectFields')} 
                        disabled={!selectedFile || selectedFile.length < 1}
                    />
                </ButtonContainer>
            </div>
        )
    };

    const selectFieldsDisplay = () => {
        return(
            <div style={{width: '1100px'}}>
                <LoaderWrapper isLoading={csvLoading}>
                    <Flex
                        display="flex"
                        justifyContent="flex-start"
                        flexDirection="row"
                        width="100%"
                        margin="0px auto"
                        padding="5px 0px"
                    >
                    <strong
                        style={{
                        color: '#101010',
                        fontSize: '16px',
                        display: 'flex',
                        justifyContent: 'center',
                        }}
                    >
                        File Name:
                    </strong>
                    <span
                        style={{ padding: '0px 8px', color: '#101010', fontSize: '15px' }}
                    >
                        {fileName}
                    </span>
                    <ul
                        style={{ margin: 'auto 0px', color: '#101010', padding: '0px 20px' }}
                    >
                        <li>
                        <span
                            style={{ color: '#0B5CD7', fontSize: '16px', cursor: 'pointer' }}
                            onClick={(event) => {
                                hiddenFileInput.current.click();
                            }}
                        >
                            Change File
                        </span>
                        </li>
                    </ul>
                    <input
                        type="file"
                        style={{ visibility: 'hidden', height: '0px', width: '0px' }}
                        ref={hiddenFileInput}
                        multiple={false}
                        onChange={handleChange}
                        accept=".csv, .xlsx"
                    />
                    </Flex>
                </LoaderWrapper>
                <hr
                    style={{
                    width: '100%',
                    border: '1px solid #E4E7EC',
                    }}
                />
                <strong>
                    Select the column name from your file from the dropdown that you would
                    like to match to our corresponding column
                </strong>
                <Flex
                    width="100%"
                    style={{
                        display: 'flex',
                        border: '1px solid #E4E7EC',
                        borderRadius: '8px',
                        padding: '10px 5px',
                        justifyContent: 'center',
                        margin: '15px auto',
                        flexDirection: 'column',
                    }}
                    height="auto"
                >
                    <Flex
                        style={{ display: 'flex', justifyContent: 'space-evenly' }}
                        width="100%"
                        height="auto"
                    >
                    <Flex width="21%">
                        <ReactSelect
                            onChange={(newValue) =>
                                changeColumnName(setEmailColumnName, newValue, 'email')
                            }
                            options={options}
                            placeholder="Select"
                            styles={getSelectStyles(emailColumnName ? '1px solid #8493A6' : '1px solid #FF0000')}
                            value={emailColumnName}
                            isClearable
                        />
                    </Flex>
                    <Flex width="15%">
                        <ReactSelect
                            onChange={(newValue) =>
                                changeColumnName(setFirstNameColumnName, newValue, 'firstName')
                            }
                            options={options}
                            placeholder="Select"
                            styles={getSelectStyles('1px solid #8493A6')}
                            value={firstNameColumnName}
                            isClearable
                        />
                    </Flex>
                    <Flex width="15%">
                        <ReactSelect
                            onChange={(newValue) =>
                                changeColumnName(setLastNameColumnName, newValue, 'lastName')
                            }
                            options={options}
                            placeholder="Select"
                            styles={getSelectStyles('1px solid #8493A6')}
                            value={lastNameColumnName}
                            isClearable
                        />
                    </Flex>
                    <Flex width="15%">
                        <ReactSelect
                            onChange={(newValue) =>
                                changeColumnName(setSuffixColumnName, newValue, 'suffix')
                            }
                            options={options}
                            placeholder="Select"
                            styles={getSelectStyles('1px solid #8493A6')}
                            value={suffixColumnName}
                            isClearable
                        />
                    </Flex>
                    <Flex width="30%">
                    <ReactSelect
                        onChange={(newValue) =>
                            changeColumnName(setTagsColumnName, newValue, 'tags')
                        }
                        options={options}
                        closeMenuOnSelect={false}
                        hideSelectedOptions={false}
                        placeholder="Select"
                        value={tagsColumnName}
                        styles={getSelectStyles('1px solid #8493A6')}
                        isMulti
                    />
                    </Flex>    
                    </Flex>
                    <Flex
                        width="100%"
                        height="auto"
                        display="flex"
                        justifyContent="space-evenly"
                        margin="10px auto"
                        backgroundColor="rgb(245, 245, 245)"
                    >
                    <Flex
                        width="21%"
                        style={{
                            height: '30px',
                            color: ' #7b7b7b',
                            fontSize: '14px',
                            display: 'flex',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                        }}
                    >
                        Email Address*
                        {!isEmpty(previewData[0].email) ? (
                        <Flex style={{ color: 'green', padding: '5px 10px' }}>
                            <img alt=" " src="/assets/images/circle-check-icon.svg" />
                        </Flex>
                        ) : (
                        <Flex style={{ color: 'red', padding: '5px 10px' }}>
                            <img alt=" " src="/assets/images/circle-warning.svg" />
                        </Flex>
                        )}
                    </Flex>
                    <Flex
                        width="15%"
                        style={{
                            height: '30px',
                            color: ' #7b7b7b',
                            fontSize: '14px',
                            display: 'flex',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                        }}
                    >
                        First Name
                    </Flex>
                    <Flex
                        width="15%"
                        style={{
                        height: '30px',
                        color: ' #7b7b7b',
                        fontSize: '14px',
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        }}
                    >
                        Last Name
                    </Flex>
                    <Flex
                        width="15%"
                        style={{
                            height: '30px',
                            color: ' #7b7b7b',
                            fontSize: '14px',
                            display: 'flex',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                        }}
                    >
                        Suffix
                    </Flex>
                    <Flex
                        width="30%"
                        style={{
                            height: '30px',
                            color: ' #7b7b7b',
                            fontSize: '14px',
                            display: 'flex',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                        }}
                    >
                        Tags
                    </Flex>
                </Flex>
                {!isEmpty(previewData) &&
                    previewData.map((item, index) => (
                    <Flex
                        key={index}
                        width="100%"
                        height="auto"
                        display="flex"
                        justifyContent="space-evenly"
                        margin="10px auto"
                        backgroundColor="#fff"
                    >
                    <Flex
                        width="21%"
                        title={item.email && item.email.length > 16 ? item.email : ''}
                        style={{
                            height: '30px',
                            color: '#101010',
                            fontSize: '14px',
                            display: 'flex',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                        }}
                    >
                        {item.email ? item.email.length > 16 ? item.email.substring(0, 16) + '...' : item.email : '-'}
                    </Flex>
                    <Flex
                        width="15%"
                        title={item.first_name && item.first_name.length > 12 ? item.first_name : ''}
                        style={{
                            height: '30px',
                            color: '#101010',
                            fontSize: '14px',
                            display: 'flex',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                        }}
                    >
                        {item.first_name ? item.first_name.length > 12 ? item.first_name.substring(0, 12) + '...' : item.first_name : '-'}
                    </Flex>
                    <Flex
                        width="15%"
                        title={item.last_name && item.last_name.length > 12 ? item.last_name : ''}
                        style={{
                            height: '30px',
                            color: '#101010',
                            fontSize: '14px',
                            display: 'flex',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                        }}
                    >
                        {item.last_name ? item.last_name.length > 12 ? item.last_name.substring(0, 12) + '...' : item.last_name : '-'}
                    </Flex>
                    <Flex
                        width="15%"
                        title={item.suffix && item.suffix.length > 10 ? item.suffix : ''}
                        style={{
                            height: '30px',
                            color: '#101010',
                            fontSize: '14px',
                            display: 'flex',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                        }}
                    >
                        {item.suffix ? item.suffix.length > 10 ? item.suffix.substring(0, 10) + '...' : item.suffix : '-'}
                    </Flex>
                    <Flex
                        width="30%"
                        title={item.tags && item.tags.length > 30 ? item.tags : ''}
                        style={{
                            height: '30px',
                            color: '#101010',
                            fontSize: '14px',
                            display: 'flex',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                        }}
                    >
                        {item.tags ? item.tags.length > 30 ? item.tags.substring(0, 30) + '...' : item.tags : '-'}
                    </Flex>
                    </Flex>
                    ))}
                </Flex>
                {csvData && (
                    <Flex
                        color="#101010"
                        fontSize="14px"
                        justifyContent="flex-end"
                        width="100%"
                        margin="8px auto"
                        padding="0px 114px"
                    >
                    Showing preview of {(emailColumnName || firstNameColumnName || lastNameColumnName || suffixColumnName || tagsColumnName) ? csvData.length > 5 ? 5 : csvData.length : 0}/{csvData.length} contacts uploaded
                    </Flex>
                )}

                <ButtonContainer>
                    <WhiteButton 
                        style={{width: '49%', height: 40, padding: '10px 0 0px'}} 
                        children={'Back'} 
                        onClick={()=>setModalDisplay('upload')} 
                    />
                    <AddButton 
                        style={{ width: '49%', height: 40, margin: '10px 0 0'}} 
                        children={'Continue'} 
                        onClick={()=>submitNewList('selectFields')} 
                        disabled={!validateEmail(previewData[0]?.email)}
                    />
                </ButtonContainer>
            </div>
        )
    };

    const initialDisplay = () => {
        return landingContent.map((card, index) => 
            <NewListCard key={index}>
                <NewListIcon src={card.icon} alt='icon' />
                <NewListTitle>{card.title}</NewListTitle>
                <NewListBody>{card.body}</NewListBody>
                <WhiteButton 
                    style={{margin: '20px auto 10px'}}
                    children={'Select'}
                    onClick={()=>setModalDisplay(card.button)}
                    // onClick={() => card.title === 'Upload a List' ? history.push('/upload-csv') : setModalDisplay(card.button)}
                />
            </NewListCard>
        )
    };

    const submitNewList = (type) => {
        if(type === 'selectFields'){
            if(validateEmail(previewData[0].email)) {
                let uploadTags = []
                setModalDisplay('submitList')
                setContactType('upload')
                for(let item in previewData){
                    if(previewData[item].tags && !uploadTags.includes(previewData[item].tags)){
                        uploadTags.push(previewData[item].tags)
                    }
                }
                // setSelectedTags([...uploadTags])
            }
        }
        else {
            if(validateEmail(newEmailData[0].email)) setModalDisplay('submitList')
        }
    };

    const submitHandler = async() => {
        // let outputData = contactType === 'upload' ? previewData : newEmailData
        let outputData = newEmailData
        let emailKeys = Object.keys(outputData);
        let validEmailList = [];
        for(let obj in emailKeys){
            if(validateEmail(outputData[emailKeys[obj]].email)){
                validEmailList.push(outputData[emailKeys[obj]])
            }
        }

        let finalCsv;
        if(editList){
            finalCsv = parse(validEmailList, {
                fields: ['first_name', 'last_name', 'email', 'current_email'],
            });
        } else {
            finalCsv = parse(validEmailList, {
                fields: ['first_name', 'last_name', 'email'],
            });
        }

        const csvBlob = new Blob([finalCsv], { type: 'text/csv' });
        const csvFile = new File([csvBlob], 'email_list.csv', {
            type: 'text/csv',
            lastModified: new Date(),
        });
        let outputArr;
        if(emailListType.length > 0) outputArr = [...selectedTags, emailListType]
        else outputArr = [...selectedTags]

        let list = await objectToFormData({
            media1: csvFile,
            tags: JSON.stringify(uniq(outputArr))
        });

        try{
            if(editList){
                setTableLoading(true)
                await updateContacts(selectedEmailList, selectedTags, [csvFile])
                setSelectedEmailListItem([]);
            } else if(modalDisplay === 'addList'){
                setTableLoading(true)
                await contactsCreate({
                        name: selectedEmailList, 
                        tags: uniq(selectedTags), 
                        media1: [csvFile]
                    }, true, true
                )
                closeModal()
                setTimeout(()=>setListLoading(false),1000)
                setNewContacts(true)
            } else {
                setTableLoading(true)
                setListLoading(true)
                closeModal()
                const idResponse = await checkEmailListName(newListName, emailListType)
                const submitId = idResponse.result.data.id;
                await uploadCsvContactList(submitId, list)
            }
            await fetchContacts()
            //loading is closing before contacts are shown
            setTimeout(()=>setListLoading(false),1000)
            closeModal()
        }catch(err){
            console.log('new list error:', err)
            setListNameError(err.message)
        }    
    
    }

    const updateRowData = (index, type, input) => {
        let updatedRow = {...newEmailData[index], [type]: input}
        setNewEmailData(
            {...newEmailData, [index]: updatedRow}
        )
    };

    const addNewRow = () => {
        let index = Object.keys(newEmailData).length
        setNewEmailData({...newEmailData, [index]: {email: '', first_name: '', last_name: ''}})
    };

    const goBackHandler = () => {
        if(modalDisplay === 'manual'){
            closeModal()
        } else if(modalDisplay !== 'upload' && modalDisplay !== 'submitList' && contactType === 'upload') {
            setModalDisplay('upload')
        } else if(modalDisplay === 'submitList' && contactType === 'upload') {
            setModalDisplay('selectFields')
        } 
        else setModalDisplay('manual')
    }

    const manualDisplay = () => {
        let emailKeys = Object.keys(newEmailData);
        return(
            <div style={{maxWidth: 910}}> 
                {!editList && <NewListSubtitle>Add Email Recipients</NewListSubtitle>}
                    <div>
                        {emailKeys.map((emailRow, index)=> {
                            return(
                            <div style={{display: 'flex', width: '100%'}} key={index}>
                                <div style={{display: 'flex', width: '60vw', maxWidth: 900}}>
                                    <TextLabel> Email Address
                                        <TextInput
                                            width="90%"
                                            type="text"
                                            id="name"
                                            name="Firm"
                                            disabled={selectedFile}
                                            value={newEmailData[emailRow]?.email}
                                            onChange={(e) => updateRowData(emailRow, 'email', e.target.value)}
                                        />
                                        {newEmailData[emailRow]?.email && !validateEmail(newEmailData[emailRow].email) && <ErrorMessage>Please enter a valid email address.</ErrorMessage>}
                                    </TextLabel>
                                    <TextLabel> First Name
                                        <TextInput
                                            width="90%"
                                            type="text"
                                            disabled={selectedFile}
                                            id="name"
                                            name="Firm"
                                            value={newEmailData[emailRow]?.first_name}
                                            onChange={(e) => updateRowData(emailRow, 'first_name', e.target.value)}
                                        />
                                    </TextLabel>
                                    <TextLabel> Last Name
                                        <TextInput
                                            width="90%"
                                            type="text"
                                            disabled={selectedFile}
                                            id="name"
                                            name="Firm"
                                            value={newEmailData[emailRow]?.last_name}
                                            onChange={(e) => updateRowData(emailRow, 'last_name', e.target.value)}
                                        />
                                    </TextLabel>
                                </div>
                                {
                                    index > 0 ? <DeleteIcon 
                                        src={trashIcon} 
                                        alt='delete' 
                                        onClick={()=>deleteRow(emailRow)} />
                                    : <SpaceSaver />
                                }
                            </div>
                        )} 
                    )}
                </div>
                {
                    !editList ? (
                    <>
                        {!selectedFile && <AddContainer 
                            onClick={()=>addNewRow()}>
                            <AddIconCircle src={addMore} alt='add' />
                            <AddMoreText>Add More</AddMoreText>
                        </AddContainer>}
                        <ButtonContainer>
                            <WhiteButton style={{width: '49%', height: 40, padding: '10px 0 0px'}} children={'Back'} onClick={()=>setModalDisplay('select')} />
                            <AddButton 
                                style={{ width: '49%', height: 40, margin: '10px 0 0'}} 
                                children={'Continue'} 
                                onClick={()=>submitNewList('manual')} 
                                disabled={!validateEmail(newEmailData[0]?.email)}
                            />
                        </ButtonContainer>
                    </>
                    ) : <div style={{width: '100%'}}>{submitListDisplay()}</div>
                }
            </div>
        )
    };

    const addDisplay = () => {
        let emailKeys = Object.keys(newEmailData);
        return(
            <div style={{maxWidth: 910}}> 
                <NewListSubtitle>Add Email Recipients</NewListSubtitle>
                    <div style={{ maxHeight: 380, overflowY: 'auto'}}>
                        {emailKeys.map((emailRow, index)=> {
                            return(
                            <div style={{display: 'flex', width: '100%'}} key={index}>
                                <div style={{display: 'flex', width: '60vw', maxWidth: 900}}>
                                    <TextLabel> Email Address
                                        <TextInput
                                            width="90%"
                                            type="text"
                                            id="name"
                                            name="Firm"
                                            value={newEmailData[emailRow].email}
                                            onChange={(e) => updateRowData(emailRow, 'email', e.target.value)}
                                        />
                                        {newEmailData[emailRow].email && !validateEmail(newEmailData[emailRow].email) && <ErrorMessage>Please enter a valid email address.</ErrorMessage>}
                                    </TextLabel>
                                    <TextLabel> First Name
                                        <TextInput
                                            width="90%"
                                            type="text"
                                            id="name"
                                            name="Firm"
                                            value={newEmailData[emailRow].first_name}
                                            onChange={(e) => updateRowData(emailRow, 'first_name', e.target.value)}
                                        />
                                    </TextLabel>
                                    <TextLabel> Last Name
                                        <TextInput
                                            width="90%"
                                            type="text"
                                            id="name"
                                            name="Firm"
                                            value={newEmailData[emailRow].last_name}
                                            onChange={(e) => updateRowData(emailRow, 'last_name', e.target.value)}
                                        />
                                    </TextLabel>
                                </div>
                                {
                                    index > 0 ? <DeleteIcon 
                                        src={trashIcon} 
                                        alt='delete' 
                                        onClick={()=>deleteRow(emailRow)} />
                                    : <SpaceSaver />
                                }
                            </div>
                        )} 
                    )}
                </div>
                <AddContainer  
                    style={{marginBottom: 5}}
                    onClick={()=>addNewRow()}>
                    <AddIconCircle src={addMore} alt='add' />
                <AddMoreText>Add More</AddMoreText>
                </AddContainer>
                {submitListDisplay()}
            </div>
        )
    };

    const updateListTypeAndTag = (type) => {
        if(emailListType === type){
            setEmailListType(type)
            // const newChips = selectedTags.filter((item) => item !== type);
            // setSelectedTags([...newChips])
        }
        else {
            let output = []
            if(type === 'prospect'){
                setEmailListType('prospect');
                output = selectedTags.filter((oldTags) => oldTags !== 'client');
            } else {
                setEmailListType('client');
                output = selectedTags.filter((oldTags) => oldTags !== 'prospect');
            }
            setEmailListType(type)
            setSelectedTags([...output, type])
            
        }
    };

    const submitListDisplay = () => {
        return(
            <div style={{width: (editList || modalDisplay === 'addList') ? '100%' : 600}}>
                {modalDisplay !== 'addList'  && !editList && (
                    <>
                        <NewListSubtitle>Enter email list name</NewListSubtitle>
                        <TextLabel> List Name*
                            <TextInput
                                width="80%"
                                type="text"
                                id="name"
                                name="Firm"
                                value={newListName}
                                placeholder={'List name'}
                                onChange={(e) => setNewListName(e.target.value)}
                            />
                            {listNameError && <ErrorMessage>{listNameError}</ErrorMessage>}
                        </TextLabel>
                    </>)
                }
                <div style={{maxHeight: 350, overflowY: 'auto'}}>
                    <NewListSubtitle 
                        style={{margin: '12px 0 6px', borderTop: '1px solid #E4E7EC', paddingTop: 10}}
                    >Do you want to add any tags to the contacts in this list?</NewListSubtitle>
                    <AddMoreText style={{width: '90%'}}>
                        Tags can be used to segment your contacts for target campaigns. Any tags you select here will be assigned to all contacts in this list.
                    </AddMoreText>
                    <NewListSubtitle style={{margin: '12px 0 6px'}}>Contact Type</NewListSubtitle>
                    <div style={{display: 'flex', justifyContent: 'start', margin: '-10px 0'}} >
                        <RadioLabel value="Prospect" control={<RadioStyle 
                            onClick={() => updateListTypeAndTag('prospect')} 
                            checked={emailListType === 'prospect'} 
                            id={`email_list_prospect`}
                        />} label="Prospect" />
                        <RadioLabel value="Client" control={<RadioStyle 
                            id={`email_list_client`}
                            checked={emailListType === 'client'}
                            onClick={() => updateListTypeAndTag('client')} 
                        />} label="Client" />
                    </div>
                    <SearchContainer 
                        title={'Search and Select from Custom and Clout-automated theme Tags'}
                        selectedTags={selectedTags}
                        searchString={searchString}
                        setSearchString={setSearchString}
                        setSelectedTags={setSelectedTags}
                        searchResults={searchTagsArr}
                    />
                </div>
                <ButtonContainer style={{margin: '5px 0 -10px'}}>
                    <WhiteButton 
                        style={{width: '49%', height: 40, padding: '10px 0 0px'}} 
                        children={'Back'} 
                        onClick={()=>goBackHandler()} 
                    />
                    <AddButton 
                        style={{ width: '49%', height: 40, margin: '10px 0 0'}} 
                        children={editList ? 'Update Contact' : 'Create'} 
                        onClick={()=> contactType === 'upload' ? handleCreateContacts() : submitHandler()} 
                        disabled={
                            ((!editList && !newListName) && (modalDisplay === 'addList' && !validateEmail(newEmailData[0]?.email)))
                            || (contactType === 'upload' && !newListName)
                        } 
                    />
                </ButtonContainer>
            </div>
        )
    }

    return(
        <div style={{display: 'flex', justifyContent: 'space-between', margin: '10px auto'}}>
            {
                modalFlow()
            }
        </div>
    )
};

export default AddNewList;