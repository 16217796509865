import { takeLatest, all, call, put } from 'redux-saga/effects';
import { getScheduleArticleDetail } from '../../services/dashboard';
import { get } from 'lodash';
import { actions as articleDetailsActions } from '../modules/article-detail.module';

function* articleDetailRequest({ payload }) {
  try {
    const response = yield call(getScheduleArticleDetail, payload);
    const data = get(response, 'result.data', {});
    yield put(articleDetailsActions.api.articleDetails.response(data))

  } catch (error) {
    yield put(articleDetailsActions.api.articleDetails.error(error));
  }
}

function* articleDetailSaga() {
  yield all([takeLatest(articleDetailsActions.api.articleDetails.request, articleDetailRequest)]);
}

export default articleDetailSaga;