import { Flex } from '@rebass/grid';
import { get, isEmpty } from 'lodash';
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Button,
  DripIconButton,
  Loader,
  LoaderWrapper,
} from '../../../components';
import useQuery from '../../../customHooks/useQuery';
import { getNewsletterDetails } from '../../../services/newsletter';
import {
  formatNewsletterDropdownItem,
  getBucketArticleData,
  getMediaUrl,
  themesData,
} from '../../../utils';
import { DeleteModal } from '../NewsletterModals/NewsletterModals';
import NewsletterOutboxArticle from '../NewsletterOutboxArticle';

const DraftOutbox = ({
  outBoxData,
  outBoxLoading,
  page,
  setPage,
  newsletterOutboxRequest,
  newsletterSetState,
  newsletterDeleteRequest,
  newsletterId,
  isArticleDeleting,
  setShowTabs,
  setShowOutbox,
  setSelectedTabIndex,
  toggleCloneModal,
  setSelectedNewsletter,
  isPremiumAdvisor,
}) => {
  let query = useQuery();
  const history = useHistory();
  const [openModal, setOpenModal] = useState(false);
  const [selectedId, setSelectedId] = useState('');
  const [loading, setLoading] = useState(false);
  const [source, setSource] = useState('');
  const [expandedId, setExpandedId] = useState('');
  const [initialIndex, setInitialIndex] = useState(0);
  const [endIndex, setEndIndex] = useState(0);
  const [lastAfterToken, setLastAfterToken] = useState('');
  const [lastBeforeToken, setLastBeforeToken] = useState('');

  useEffect(() => {
    if (!isEmpty(outBoxData) && outBoxData.pagination) {
      if (+outBoxData.pagination.total_count > 0 && page === 0) {
        setInitialIndex(1);
        setEndIndex(
          +outBoxData.pagination.total_count < 6
            ? +outBoxData.pagination.total_count
            : 6
        );
      } else if (+outBoxData.pagination.total_count > 0 && page > 0) {
        setInitialIndex(page * 6 + 1);
        setEndIndex(
          (page + 1) * 6 >= +outBoxData.pagination.total_count
            ? +outBoxData.pagination.total_count
            : (page + 1) * 6
        );
      }
    } else {
      setInitialIndex(0);
      setEndIndex(0);
    }
  }, [outBoxData, page]);

  const onPageClick = (type) => {
    if (type === 'after') {
      setPage(page + 1);
      setLastAfterToken(get(outBoxData, 'pagination.after', null));
      setLastBeforeToken('');
      newsletterOutboxRequest({
        params: {
          filter: 'draft',
          afterToken: get(outBoxData, 'pagination.after', null),
        },
      });
    } else {
      setPage(page - 1);
      setLastBeforeToken(get(outBoxData, 'pagination.before', null));
      setLastAfterToken('');
      newsletterOutboxRequest({
        params: {
          filter: 'draft',
          beforeToken: get(outBoxData, 'pagination.before', null),
        },
      });
    }
  };

  const onAccept = () => {
    setOpenModal(false);
    let params = {
      id: newsletterId,
      filter: 'draft',
    };

    if (page !== 0 && !isEmpty(lastAfterToken) && isEmpty(lastBeforeToken)) {
      params.afterToken = lastAfterToken;
    } else if (
      page !== 0 &&
      !isEmpty(lastBeforeToken) &&
      isEmpty(lastAfterToken)
    ) {
      params.beforeToken = lastBeforeToken;
    }
    newsletterDeleteRequest({
      params,
    });
  };

  const onReject = () => {
    setOpenModal(false);
    newsletterSetState({
      newsletterId: '',
    });
  };

  const handleDelete = (id) => {
    setOpenModal(true);
    newsletterSetState({
      newsletterId: id,
    });
  };

  const onEditClick = (id, index) => {
    setSelectedId(id);
    setLoading(true);

    query.delete('type');

    history.replace({
      search: query.toString(),
    });

    if (index === 0) {
      setSource('Edit');
    } else {
      setSource('Send');
    }

    getNewsletterDetails({ id })
      .then((res) => {
        if (res.result.success) {
          const data = get(res, 'result.data', {});
          console.log(data);
          newsletterSetState({
            newsletterName: data && data.name && data.name,
            isEditMode: true,
            scheduledTime: '',
            tab: 'draft',
            newsletterId: id,
            selectedContacts: data && data.contactlists,
            emailTemplateSelected: data && data.template,
            ccContacts: data && data.cc_email_ids ? data.cc_email_ids : [],
            newsletterArticles: data && data.articles && getBucketArticleData(data.articles),
            customData: {
              title: data && data.customized_data && data.customized_data.title,
              subject: data && data.customized_data && data.customized_data.subject,
              salutation: data && data.customized_data && data.customized_data.salutation,
              salutation_text: data &&
                data.customized_data &&
                formatNewsletterDropdownItem(
                  data.customized_data.salutation_text
                ),
              message:
                data && data.customized_data && data.customized_data.message,
              media1:
                data &&
                data.customized_data &&
                data.customized_data.media_urls &&
                !isEmpty(data.customized_data.media_urls) &&
                getMediaUrl(data.customized_data.media_urls),
              toggle: data && data.customized_data && data.customized_data.toggle  
            },
            leadCapture: {
              lead_gen: data && data.lead_gen,
              referral: data && data.referral,
            },
            cloneDetails:
              data && data.original_newsletter_details
                ? data.original_newsletter_details
                : null,
          });
          setSelectedId('');
          setLoading(false);
          setShowTabs(true);
          setShowOutbox(false);
          setSource('');
          setSelectedTabIndex(index);
        } else {
          setSelectedId('');
          setSource('');
          setLoading(false);
        }
      })
      .catch((err) => {
        setSelectedId('');
        setSource('');
        setLoading(false);
      });
  };

  const triggerExpand = (contacts, id) => {
    if (!isEmpty(contacts) && expandedId !== id) {
      setExpandedId(id);
    } else if (expandedId === id) {
      setExpandedId('');
    }
  };

  return (
    <LoaderWrapper
      isLoading={outBoxLoading}
      styles={{ position: 'absolute', bottom: '5%' }}
    >
      <Flex
        width="100%"
        flexDirection="column"
        justifyContent="center"
        padding="15px 35px"
      >
        {outBoxData && !isEmpty(outBoxData.newsletters) ? (
          <Flex justifyContent="center" width="100%" flexWrap="wrap">
            {outBoxData.newsletters
              .slice(page * 6, (page + 1) * 6)
              .map((item, index) => (
                <NewsletterOutboxArticle
                  {...item}
                  handleDelete={handleDelete}
                  onEditClick={onEditClick}
                  source={source}
                  selectedId={selectedId}
                  loading={loading}
                  isArticleDeleting={isArticleDeleting}
                  newsletterId={newsletterId}
                  tabSource="Draft"
                  toggleCloneModal={toggleCloneModal}
                  newsletter={item}
                  setSelectedNewsletter={setSelectedNewsletter}
                  isPremiumAdvisor={isPremiumAdvisor}
                />
              ))}
            {outBoxData.newsletters.slice(page * 6, (page + 1) * 6).length %
              3 !==
              0 &&
              outBoxData.newsletters.slice(page * 6, (page + 1) * 6).length %
                3 ===
                1 && (
                <>
                  <i
                    aria-hidden="true"
                    style={{
                      width: '215px',
                      height: '208px',
                      margin: '14px 10px 35px 8.2px',
                      padding: '0px',
                    }}
                  ></i>
                  <i
                    aria-hidden="true"
                    style={{
                      width: '215px',
                      height: '208px',
                      margin: '14px 10px 35px 8.2px',
                      padding: '0px',
                    }}
                  ></i>
                </>
              )}
            {outBoxData.newsletters.slice(page * 6, (page + 1) * 6).length %
              3 !==
              0 &&
              outBoxData.newsletters.slice(page * 6, (page + 1) * 6).length %
                3 ===
                2 && (
                <i
                  aria-hidden="true"
                  style={{
                    width: '215px',
                    height: '208px',
                    margin: '14px 10px 35px 8.2px',
                    padding: '0px',
                  }}
                ></i>
              )}
          </Flex>
        ) : (
          <div style={{ textAlign: 'center' }}>No Records Found</div>
        )}
      </Flex>
      <Flex
        width="100%"
        justifyContent="space-between"
        padding="15px 70px"
        alignItems="center"
      >
        <Button
          padding="5px 20px"
          height="30px"
          margin="0px"
          onClick={() => setPage(page - 1)}
          disabled={page < 1 ? true : false}
        >
          Previous
        </Button>
        <Flex color={themesData.charcoalGrey} fontSize="13px">
          {initialIndex}-{endIndex} of{' '}
          {outBoxData &&
            outBoxData.pagination &&
            +outBoxData.pagination.total_count}
        </Flex>
        <Button
          padding="5px 20px"
          height="30px"
          margin="0px"
          onClick={() => setPage(page + 1)}
          disabled={
            get(outBoxData, 'pagination.total_count', '0') ===
            endIndex.toString()
          }
        >
          Next
        </Button>
      </Flex>
      {openModal && (
        <DeleteModal
          open={openModal}
          setOpen={setOpenModal}
          onAccept={onAccept}
          onReject={onReject}
        />
      )}
    </LoaderWrapper>
  );
};

export default DraftOutbox;
