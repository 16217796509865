import React from 'react';
import ReactSkeleton from 'react-loading-skeleton';
import {
  Content,
  Count,
  VerticalSeparator,
  ContentWrapper,
} from './styles';

function CampaignCountSkeleton() {
  return (
    <ContentWrapper>
      <Content>
        <Count>
          <ReactSkeleton width={120} height={20} />
          <ReactSkeleton width={100} height={20} />
        </Count>
        <VerticalSeparator />
        <Count>
          <ReactSkeleton width={80} height={20} />
          <ReactSkeleton width={100} height={20} />
        </Count>
        <Count>
          <ReactSkeleton width={80} height={20} />
          <ReactSkeleton width={100} height={20} />
        </Count>
        <Count>
          <ReactSkeleton width={80} height={20} />
          <ReactSkeleton width={100} height={20} />
        </Count>
        <Count>
          <ReactSkeleton width={80} height={20} />
          <ReactSkeleton width={100} height={20} />
        </Count>
      </Content>
    </ContentWrapper>
  );
}

export default CampaignCountSkeleton;
