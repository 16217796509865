import styled from 'styled-components';

export const ListContainer = styled.div`
  cursor: ${(props) => (props.showCursor ? 'pointer' : props.showDefault ? 'default' : 'none')};
  display: inline-block;
  width: ${({ width }) => width || '120px'};
  margin-right: 5px;
`;
export const ListItem = styled.div`
  margin: 5px 5px;
  border-radius: 5px;
  color: #6351ed;
  width: 100%;
  display: flex;
  padding: 4px 5px 0;
  background-color: #ececec;
`;
export const ImageBox = styled.div`
  float: left;
`;
export const Image = styled.img`
  width: 30px;
  font-size: 0px;
  height: 30px;
  padding: 2px;
  border-radius: 5px;
  object-fit: cover;

`;
export const ListContent = styled.div`
  font-size: 11px;
  text-align: left;
  font-family: 'Poppins';
  padding: 0px 5px 0 0;
  line-height: 28px;
  width: 60%;
  padding: 0;
  overflow: hidden;
  position: relative;
  display: inline-block;
  margin: 0 5px 0 5px;
  text-align: center;
  text-decoration: none;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #6351ed;
`;
