import * as React from "react";

function File(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 16 16" fill="none" {...props}>
      <path
        d="M12.5 14h-9a.5.5 0 01-.5-.5v-11a.5.5 0 01.5-.5h6L13 5.5v8a.5.5 0 01-.5.5z"
        stroke="currentColor"
        strokeWidth={1.6}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.5 2v3.5H13"
        stroke="currentColor"
        strokeWidth={1.6}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

const MemoFile = React.memo(File);
export default MemoFile;
