import React from 'react';
import { CircularProgress } from '@mui/material';
import BookmarkIcon from 'src/assets/icons/BookmarkSimple';
import BookmarkFilledIcon from 'src/assets/icons/BookmarkSimpleFilled';
import useMutationForAddingBookmark from './useMutationForAddingBookmark';
import useMutationForRemovingBookmark from './useMutationForRemovingBookmark';
import { getMediaImage,onImageError } from 'src/utils';
import {
  Actions,
  BookmarkContainer,
  Card,
  Content,
  ContentWrapper,
  Description,
  ImageContainer,
  Pill,
  Pills,
  PrimaryActions,
  Section,
} from './styles';

function Article({
  article,
  primaryActions,
  secondaryActions,
  invalidateQueries = () => {},
}) {
  const { isLoading: addBookmarkLoader, mutate: addBookmarkMutate } =
    useMutationForAddingBookmark();
  const { isLoading: removeBookmarkLoader, mutate: removeBookmarkMutate } =
    useMutationForRemovingBookmark();

  return (
    <Card>
      <ImageContainer
        onClick={() => {
          window.open(`${article?.content_url}`, '_blank');
        }}
      >
        <img
          src={getMediaImage(article?.media_url, 'original')}
          onError={onImageError}
          alt="article-img"
        />
      </ImageContainer>
      <Section>
        <ContentWrapper>
          {Array.isArray(article?.tags) && (
            <Pills>
              {[...article?.tags]?.splice(0, 4)?.map((tag) => (
                <Pill key={tag}>{tag}</Pill>
              ))}
              {/* {article?.tags?.length - 4 > 0 && (
                <Pill>+{article?.tags?.length - 4}</Pill>
              )} */}
            </Pills>
          )}

          <Content
            onClick={() => {
              window.open(`${article?.content_url}`, '_blank');
            }}
          >
            <h4>{article?.name}</h4>
            <p>{article?.description}</p>
            <Description>
              <span>{article?.created_date}</span>
              <div className="separator"></div>
              <span>{article?.publisher_name ?? ''}</span>
            </Description>
          </Content>
        </ContentWrapper>
        <Actions>
          <PrimaryActions>
            <>
              {addBookmarkLoader || removeBookmarkLoader ? (
                <BookmarkContainer variant="outline" width={190}>
                  <CircularProgress size={15} />
                </BookmarkContainer>
              ) : (
                <>
                  {article?.is_bookmarked ? (
                    <BookmarkContainer
                      variant="outline"
                      bookmarked={true}
                      onClick={() => {
                        removeBookmarkMutate(article?.bookmark_id, {
                          onSuccess: () => invalidateQueries(article?.id),
                        });
                      }}
                    >
                      <BookmarkFilledIcon />
                      <h4>Bookmarked</h4>
                    </BookmarkContainer>
                  ) : (
                    <BookmarkContainer
                      variant="outline"
                      bookmarked={false}
                      onClick={() => {
                        addBookmarkMutate(article?.article_bookmark_id, {
                          onSuccess: () => invalidateQueries(article?.id),
                        });
                      }}
                    >
                      <BookmarkIcon />
                      <h4>Bookmark</h4>
                    </BookmarkContainer>
                  )}
                </>
              )}
            </>

            {primaryActions && <>{primaryActions}</>}
          </PrimaryActions>
          {secondaryActions && <>{secondaryActions}</>}
        </Actions>
      </Section>
    </Card>
  );
}

export default Article;
