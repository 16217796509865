import apiInstance from '../api';
import { objectToFormData } from '../helpers';

export const postBillDetails = (payload) => {
  const { transactionId } = payload;
  const data = objectToFormData({
    transaction_id: transactionId,
    external_uuid: 'asdsadas',
    amount: '100',
    payment_type: 'recurring',
    currency: 'USD',
    plan_validity: '1',
    payment_processor: 'stripe',
  });
  return apiInstance().post('/billing/processed', data);
};
