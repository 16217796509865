import React, { useState, useMemo } from 'react';
import { get, isEmpty } from 'lodash';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import { Button, LoaderWrapper } from '../';
import useResponsiveFontSize from './useResponsiveFontSize';
import { StripeComponentWrapper } from './StripeComponent.styles';
import { ErrorContainer } from '../CommonStyles';
import { postBillDetails } from '../../services/subscribe';

const useOptions = () => {
  const fontSize = useResponsiveFontSize();
  const options = useMemo(
    () => ({
      style: {
        base: {
          color: '#424770',
          letterSpacing: '0.025em',
          '::placeholder': {
            color: '#aab7c4',
          },
          border: '1px solid #c1c1c1',
          borderRadius: '5px',
        },
        invalid: {
          color: '#9e2146',
        },
      },
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [fontSize]
  );

  return options;
};

const StripeComponent = () => {
  const [isPaying, setIsPaying] = useState(false);
  const [paymentError, setPaymentError] = useState('');
  const stripe = useStripe();
  const elements = useElements();
  const options = useOptions();

  const handleSubmit = async (event) => {
    event.preventDefault();
    setPaymentError('');
    setIsPaying(true);

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    const payload = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardElement),
    });

    if (!isEmpty(get(payload, 'error'))) {
      setIsPaying(false);
      setPaymentError('Your card has been declined.');
    } else {
      postBillDetails({
        transactionId: get(payload, 'paymentMethod.id', ''),
      })
        .then((res) => {
          setIsPaying(false);
          window.location.href = `${window.location.origin}/discover`;
        })
        .catch(() => {
          setIsPaying(false);
          setPaymentError('Your card has been declined.');
        });
    }
  };
  return (
    <form onSubmit={handleSubmit}>
      <StripeComponentWrapper>
        <CardElement options={options} onChange={() => setPaymentError('')} />
      </StripeComponentWrapper>
      <LoaderWrapper isLoading={isPaying}>
        <Button type="submit" disabled={!stripe} isSecondary>
          Subscribe
        </Button>
      </LoaderWrapper>
      <ErrorContainer align="center">{paymentError}</ErrorContainer>
    </form>
  );
};

export default StripeComponent;
