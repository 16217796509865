import React, { useState } from 'react';
import {
    IconBlock,
    IntegrationIcon,
    IconContainer,
    ConnectButton,
    ImportedIcon,
    SyncIconStyle
  } from './styles';
import Skeleton from './Skeleton';
import ModalContainer from '../../componentsV2/ModalContainer';
import PurpleButton from '../../componentsV2/PurpleButton';
import WhiteButton from '../../componentsV2/WhiteButton';

const IconCards = ({ isLoading, iconInput, iconClick, buttonClick, syncModalActions }) => {
    const [showDisconnectModal, setShowDisconnectModal] = useState(false);
    const [disconnectItems, setDisconnectItems] = useState({});

    if(isLoading) return <IconContainer><Skeleton /></IconContainer>

    const disconnectDisplay = () => {
        return(
            <div>
                <div style={{margin: '20px 0', fontSize: 14, color: '#475467'}}>Are you sure you want to disconnect from this integration?</div>
                <div style={{display: 'flex', justifyContent: 'space-between', width: '100%'}}>
                    <WhiteButton
                        width={'49%'}
                        children={'Cancel'}
                        onClick={()=>setShowDisconnectModal(false)}
                    />
                    <PurpleButton
                        width={'49%'}
                        children={'Continue'}
                        onClick={()=>{
                            buttonClick(disconnectItems?.e, disconnectItems?.icon)
                            setShowDisconnectModal(false)
                        }}
                    />
                </div>
            </div>
        )
    };

    return(
        <IconContainer>
            {iconInput.map((icon, index) => {
            return (
                <IconBlock key={icon.id} style={{width: `${(1/iconInput.length*100)-1}%`}}>
                    {icon.isDisconnected && syncModalActions && <SyncIconStyle onClick={(e)=>syncModalActions(e, icon)} />}
                    <div onClick={() =>iconClick(icon)}>
                        {icon.icon ? (
                        <ImportedIcon
                            icon={icon.icon}
                            style={{
                            cursor: 'pointer',
                            backgroundColor: icon.isDisconnected
                                ? '#816EF8'
                                : '#e3e5e4',
                            }}
                        /> )
                        : (
                        <IntegrationIcon
                            src={icon.src}
                            style={{
                            cursor: 'pointer',
                            backgroundColor: icon.isDisconnected
                                ? '#816EF8'
                                : '#e3e5e4',
                            }}
                            alt={icon.id}
                        />
                        )
                        }
                    </div>
                    {buttonClick && <ConnectButton
                        children={icon.bottomText}
                        style={{
                            color: icon.isDisconnected ? '#101828' : 'white',
                            background: icon.isDisconnected ? 'white': '#816EF8'
                        }}
                        onClick={(e) => {
                            if(!icon.isDisconnected){
                                buttonClick(
                                    e,
                                    icon
                                )
                            } else {
                                setShowDisconnectModal(true)
                                setDisconnectItems({e: e, icon: icon})
                            }
                        }}
                    />}
                </IconBlock>
            );
            })
        }
        <ModalContainer
            title={'Confirm Disconnect'}
            modalStatus={showDisconnectModal}
            closeModal={()=>setShowDisconnectModal(!showDisconnectModal)}
            children={disconnectDisplay()}
        />
      </IconContainer>
    )
};

export default IconCards;