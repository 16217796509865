/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import parseHtml from 'html-react-parser';
import { get, isEmpty } from 'lodash';
import { CustomButton, PostRejectUl, PostRejectListItem, CustomTextArea, TextAreaWrapper, CustomInputField } from './../ShareContentContainer.styles';
import { getUserDetails } from '../../../redux/selector/login.selectors';
import { getMediaUrl, themesData } from '../../../utils';
import { getComplianceEmailData } from '../../../services/dashboard';
import { EmailPreview } from '../../';

const EmailSummary = ({
    backToPreviousScreen,
    summaryText,
    userData,
    articleData,
    setEmailTextData,
    articleId,
    pdfImage,
    isFromRecentTab,
    isFromScheduledTab,
    selectedItem,
    setIsContentChanged,
    emailTextData,
    image,
    rest,
    engagementId,
    isFromCompliance,
    isSummaryFieldPreviewClick,
    subjectText,
    messageText,
    setIsSummaryFieldPreviewClick,
    complianceCustomisedData
}) => {
    
    const salutationTextOptions = [
        {label: 'First Name', value: '<first_name>'}, 
        {label: 'Last Name', value: '<last_name>'}, 
        {label: 'Full Name', value: '<full_name>'}
    ];
    const [isEmailPreviewOpen, setEmailPreviewOpen] = useState(false);
    const [emailNameText, setEmailNameText] = useState({label: 'First Name', value: '<first_name>'});
    const [emailData, setEmailData] = useState({subject: 'Generating subject...', message: '', salutation: 'Hi', placeholderText: 'Generating message...'});
    const [base64Logo, setBase64Logo] = useState('');
    const [themeColor, setThemeColor] = useState('#6351ed');
    const [salutationStyling, setSalutationStyling] = useState({});

    const emailPersonalization = get(userData, 'email_personalization');
    const editorSettingsMedia = get(userData, 'personalization.corporate_identity.logo.main.media_urls');
    const bannerColor = get(emailPersonalization, 'banner_hex_color');
    const signature = get(emailPersonalization, 'signature', '');
    const description = get(emailPersonalization, 'footer_text', '');
    const logoUrl = getMediaUrl(get(emailPersonalization, 'media_urls') ? get(emailPersonalization, 'media_urls') : editorSettingsMedia ? editorSettingsMedia[0].path + editorSettingsMedia[0].name.system + '.' + editorSettingsMedia[0].ext : '');
    
    
    // useEffect(() => {
    //     if(isSummaryFieldPreviewClick) {
    //       setEmailPreviewOpen(true);
    //     }
    //   }, [isSummaryFieldPreviewClick])

    useEffect(() => {
        // console.log('[email personalization] - ',get(emailPersonalization, 'media_urls') ? 'true' : 'false');
        let logo = emailPersonalization && emailPersonalization.media_urls && emailPersonalization.media_urls.length>0 ? 
            emailPersonalization.media_urls[0].path + emailPersonalization.media_urls[0].name.system + '.' + emailPersonalization.media_urls[0].ext : 
                editorSettingsMedia ? editorSettingsMedia[0].path + editorSettingsMedia[0].name.system + '.' + editorSettingsMedia[0].ext : '';
        // console.log('[logo url] - ',logo);
        setThemeColor(bannerColor);
        setTimeout(() => {
            setBase64Logo(logo ? logo : '/assets/images/fideate-logo.svg');    
        }, 1000);
    }, [logoUrl,bannerColor]);

    useEffect(() => {
        const savedEmailData = JSON.parse(localStorage.getItem('emailData'));
        if(savedEmailData !== null && (((parseHtml(savedEmailData.message) !== parseHtml(emailData.message)) || 
            (savedEmailData.subject.trim() !== emailData.subject.trim()) || (emailData.salutation !== savedEmailData.salutation) || 
            (savedEmailData.salutation_text !== emailData.salutation_text)))) {
            console.log('true')
          } else {
           console.log('false')
          }
    }, [emailData]);

    useEffect(() => {
        const savedEmailData = JSON.parse(localStorage.getItem('emailData'));
        console.log('LOCAL STORAGE DATA - ', savedEmailData);
        if(savedEmailData){
            const element = parseHtml(savedEmailData.message);
            let styleObj = {};
            setEmailData({
                ...emailData,
                ...savedEmailData
            });
            const result = salutationTextOptions.filter(object => object.value === savedEmailData.salutation_text);
            setEmailNameText(result.length > 0 && result[0]);
            
            if(element && element[0] && element[0].props && element[0].props.children && (element[0].props.children.props || element[0].props.children[0].props))
                styleObj = element[0].props.children.props ? element[0].props.children.props.style : element[0].props.children[0].props.style;
            setSalutationStyling(styleObj);
        }else if(isFromCompliance){
            if(!isEmpty(engagementId)) {
                getComplianceEmailData(engagementId).then(res => {
                    if(res.success){
                        setEmailData({
                            ...emailData,
                            salutation_text: res.data.salutation_text,
                            salutation: res.data.salutation,
                            subject: res.data.subject,
                            signature: res.data.signature,
                            message: res.data.message,
                            disclaimer: res.data.footer_text,
                            themeColor: res.data.banner_hex_color,
                            logo: `${res.data.media_urls[0].path}${get(res.data.media_urls[0], 'name.system')}.${
                                res.data.media_urls[0].ext
                              }`,
                            placeholderText: "Type your email intro message here..."
                        });
                    }
                });
            } else if(complianceCustomisedData) {
                console.log('customised_data',complianceCustomisedData);
                setEmailData({
                    ...emailData,
                    salutation_text: complianceCustomisedData.salutation_text,
                    subject: complianceCustomisedData.subject,
                    message: complianceCustomisedData.message,
                    salutation: complianceCustomisedData.salutation,
                    placeholderText: "Type your email intro message here..."
                })
            }
            

        }else{
            // getEmailData(rest.id).then(res => {
            //     console.log('server STORAGE DATA - ', res);
            //     if(res.data.message && res.data.subject){
            //         setEmailData({
            //             ...emailData,
            //             salutation_text: res.data.salutation_text,
            //             salutation: res.data.salutation,
            //             subject: res.data.subject,
            //             signature: res.data.signature,
            //             message: res.data.message,
            //             disclaimer: res.data.footer_text,
            //             logo: `${res.data.media_urls[0].path}${get(res.data.media_urls[0], 'name.system')}.${
            //                 res.data.media_urls[0].ext
            //               }`,
            //             placeholderText: "Type your email intro message here..."
            //         });
            //     }
            // });
        }
    }, []);

 

    const handleTextAreaChange = (e) => {
         console.log('emailData - ', emailData, 'CURRENT VALUE - ', e.target.value);
         const stateChange = e.target.value;
        setEmailData({
            ...emailData,
            [e.target.name]: stateChange.replace(/&#8232;/g," "),
        });
    }


    const handleClick = (type) => {
        switch (type) {
            case 'preview':
                setEmailPreviewOpen(true);
                return;
            case 'save':
                const obj = {...emailData, salutation_text: emailNameText.value};
                setEmailTextData(obj);
                localStorage.setItem('emailData', JSON.stringify(obj));
                backToPreviousScreen(false);
                return;
            case 'previousScreen':
                backToPreviousScreen(false);
                return;
            default:
                return;
        }
    };

    
      const buttons = isFromRecentTab ? [
        {name: 'Preview', id: 'preview', width: '70px', background: '#ffffff', color: themesData.purpleishBlue}
    ] : [
        //{name: 'Preview', id: 'preview', background: '#ffffff', marginLeft: '30%', color: themesData.purpleishBlue}
    ];

    const getSalutationName = (name) => {
        if(!isEmpty(name)) {
            let label = salutationTextOptions.filter(s => s.value === name);
            if(!isEmpty(label)) {
                return `${label[0].label},`;
            }
        } else {
            return emailNameText.label;
        }

    }

    return (
        <>
            <PostRejectUl isFromAddContent={false}>
                <PostRejectListItem>
                    
                        <div
                            style={{color: '#6351ed', fontSize: '16px', fontWeight: 'bold', textAlign: 'center'}}
                        ></div>
                        <div style={{color: '#6351ed', margin: '10px 0px 5px'}}>Subject</div>
                        <CustomTextArea style={{color: '#848484', height: isFromCompliance ? '61px' : '41px'}} id="subject" name="subject" rows="3" disabled onChange={handleTextAreaChange} value={emailData.subject} />
                        {!emailData.subject && <div style={{color: '#000', fontSize: '12px'}}>Subject cannot be empty!</div>}
                        <div style={{color: '#6351ed', margin: '5px 0px'}}>Message</div>
                        <TextAreaWrapper style={{backgroundColor: 'transparent', padding: '0px 10px 10px 0px'}}>
                            <div style={{display: 'flex'}}>
                                <CustomInputField 
                                    width='14%' 
                                    value={emailData.salutation ? emailData.salutation : 'Hi'} 
                                    name='salutation' 
                                    style={{padding: '12px 10px'}}
                                    onChange={handleTextAreaChange} 
                                    disabled
                                />
                                <div style={{zIndex: 2, marginLeft: '10px', marginTop: '10px'}}>
                                    {getSalutationName(emailData.salutation_text)}
                                </div>
                            </div>
                        </TextAreaWrapper>
                        {emailData.message &&
                            <TextAreaWrapper style={{padding: '10px 10px 10px 10px', overflowY: 'auto', height: '100px'}}> 
                                <div style={{wordBreak: 'break-word'}}>
                                    {emailData.message && parseHtml(emailData.message)}
                                </div>
                            </TextAreaWrapper>
                        }
                   
                </PostRejectListItem>
            </PostRejectUl>
         
            { buttons.map(button => 
            <CustomButton
                id={button.id}
                margin={'25px 0px 15px 41%'}
                padding={'5px 10px'}
                width='auto'
                height='auto'
                background={button.background}
                color={button.color}
                onClick={() => handleClick(button.id)}
                style={(!emailTextData.subject && button.id === 'save') ? {cursor: 'not-allowed', opacity: '0.5', fontFamily: 'Poppins'} : {fontFamily: 'Poppins'}}
                disabled={(!emailTextData.subject && button.id === 'save')}
            >{button.name}</CustomButton>)}

            {<EmailPreview
                isModalOpen={isSummaryFieldPreviewClick}
                setModalOpen={setIsSummaryFieldPreviewClick}
                themeColor={themeColor || emailData.themeColor}
                emailNameText={`${emailData.salutation || 'Hi'} ${getSalutationName(emailData.salutation_text)}`}
                salutationStyling={salutationStyling}
                messageText={emailData.message}
                summaryText={summaryText}
                subjectText={subjectText || emailData.subject}
                signature={signature || emailData.signature}
                disclaimer={description || emailData.disclaimer}
                logo={emailData.logo}
                articleData={articleData}
                onSave={() => handleClick('save')}
                fromShareContent={true}
                pdfImage={image}
                isFromRecentTab={isFromRecentTab}
                imageWidth={get(emailPersonalization, 'image_width', '150')}
                imageHeight={get(emailPersonalization, 'image_height', 'auto')}
                isFromCompliance={isFromCompliance}
            /> }
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        userData: getUserDetails(state),
    };
  };
  
const mapDispatchToProps = {
};

export default connect(
mapStateToProps,
mapDispatchToProps
)(EmailSummary);