import React from 'react'
import { ComplianceApproveAllContainer } from '../../containers';
import withContentLayout from '../../hoc/withContentLayout';

const ComplianceApproveAllLayout = () => {
  return (
    <ComplianceApproveAllContainer />
  )
}

export default withContentLayout(ComplianceApproveAllLayout);