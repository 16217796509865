import { Flex } from '@rebass/grid';
import React from 'react';
import { IconButton, Tooltip } from '@mui/material';
import {
  ColorPallete,
  Typography,
  getMediaImage,
  onImageError,
} from '../../../utils';
import { useHistory } from 'react-router-dom';
import InfoIcon from 'src/assets/icons/Insights/InfoIcon';
import { TooltipBody, TooltipStyles } from './styles';

const CampaignCard = ({
  media_url,
  type,
  name,
  no_contacts,
  platforms,
  engagement_email,
  engagement_social,
  open_rate,
  total_clicks,
  email_blocks,
  social_blocks,
  id,
}) => {
  const { push: changeRoute } = useHistory();
  const { is_email, is_facebook, is_linkedin, is_twitter } = platforms;
  const campaignType = type === 'series' ? 1 : 2;

  return (
    <Flex
      width="100%"
      alignItems="center"
      style={{
        borderRadius: '8px',
        border: `1px solid ${ColorPallete.neutral200}`,
        boxShadow: '0px 1px 2px 0px #1018281F',
        cursor: 'pointer',
      }}
      padding="16px"
      margin="12px 0px"
      onClick={() =>
        changeRoute(`/analytics/campaign/${id}?type=${campaignType}`, {
          from: '/analytics',
        })
      }
    >
      <Flex width="8%" height="101px">
        <img
          src={getMediaImage(media_url)}
          alt="Campaign"
          onError={onImageError}
          style={{
            width: '100%',
            objectFit: 'cover',
            height: '100%',
            borderRadius: '4px',
          }}
        />
      </Flex>

      <Flex width="35%" flexDirection="column" pl="12px" height="101px">
        <Flex width="100%">
          <img src="/assets/images/Analytics/campaign-logo.svg" alt="" />
          <Flex
            pl="5px"
            fontSize={Typography.TextSmall}
            color={ColorPallete.neutral500}
          >
            {type === 'series' ? 'One Time & Series' : 'Automated'}
          </Flex>
        </Flex>
        <Flex
          padding="2px 0px"
          width="100%"
          color={ColorPallete.black}
          fontSize={Typography.TextLarge}
        >
          {name}
        </Flex>

        <Flex width="100%">
          {is_email && (
            <Flex>
              <img
                src="/assets/images/Analytics/Email-Grey.svg"
                alt=""
                style={{ margin: '0px 4px 0px 0px' }}
              />
              <Flex
                fontSize={Typography.TextSmall}
                color={ColorPallete.neutral500}
              >
                {email_blocks} {email_blocks > 1 ? 'Emails' : 'Email'}
              </Flex>
            </Flex>
          )}
          {is_email && (is_facebook || is_linkedin || is_twitter) && (
            <Flex
              fontSize={Typography.TextSmall}
              color={ColorPallete.neutral500}
              mr="4px"
              ml="4px"
            >
              |
            </Flex>
          )}
          {(is_facebook || is_linkedin || is_twitter) && (
            <Flex>
              <img
                src="/assets/images/Analytics/Share-Grey.svg"
                alt=""
                style={{ margin: '0px 4px 0px 0px' }}
              />
              <Flex
                fontSize={Typography.TextSmall}
                color={ColorPallete.neutral500}
              >
                {social_blocks}{' '}
                {social_blocks > 1 ? 'Social Media Posts' : 'Social Media Post'}
              </Flex>
            </Flex>
          )}
        </Flex>

        <Flex padding={is_email ? '8px 0px 4px' : '10px 0px 18px'} width="100%">
          {is_email && (
            <>
              <Flex
                color={ColorPallete.neutral500}
                fontSize={Typography.TextSmall}
              >
                Contacts sent to:
              </Flex>
              <Flex
                pl="3px"
                color={ColorPallete.neutral900}
                fontSize={Typography.TextSmall}
              >
                {no_contacts}
              </Flex>
            </>
          )}
        </Flex>

        <Flex width="100%">
          {is_email && (
            <img
              src="/assets/images/Analytics/email.svg"
              alt="email"
              style={{ margin: '0px 4px 0px 0px' }}
            />
          )}
          {is_facebook && (
            <img
              src="/assets/images/Analytics/facebookOutline.svg"
              alt="facebook"
              style={{ margin: '0px 4px 0px 0px' }}
            />
          )}
          {is_linkedin && (
            <img
              src="/assets/images/Analytics/linkedin.svg"
              alt="linkedin"
              style={{ margin: '0px 4px 0px 0px' }}
            />
          )}
          {is_twitter && (
            <img
              src="/assets/images/Analytics/twitterOutline.svg"
              alt="twitter"
              style={{ margin: '0px 4px 0px 0px' }}
            />
          )}
        </Flex>
      </Flex>

      <Flex
        width="57%"
        backgroundColor={ColorPallete.neutral50}
        style={{
          borderRadius: '8px',
        }}
        ml="12px"
        padding="0px 12px"
        height="101px"
      >
        <Flex
          width="20%"
          flexDirection="column"
          padding="12px"
          style={{ whiteSpace: 'nowrap' }}
        >
          <img
            src={
              is_email
                ? '/assets/images/Analytics/click-icon.svg'
                : '/assets/images/Analytics/click-rate-grey.svg'
            }
            alt="click"
            height="24"
            width="24"
          />
          <Flex
            width="100%"
            color={is_email ? ColorPallete.neutral600 : ColorPallete.neutral400}
            fontSize={Typography.TextMedium}
            padding="5px 0px"
          >
            Total Clicks
          </Flex>
          <Flex
            width="100%"
            padding="3px 0px 0px"
            color={is_email ? ColorPallete.neutral900 : ColorPallete.neutral400}
            fontSize={is_email ? Typography.Heading : Typography.TextMedium}
          >
            {is_email ? total_clicks : 'Coming Soon'}
          </Flex>
        </Flex>

        <Flex
          width="20%"
          flexDirection="column"
          padding="12px"
          style={{ whiteSpace: 'nowrap' }}
        >
          <img
            src={
              is_email
                ? '/assets/images/Analytics/open-icon.svg'
                : '/assets/images/Analytics/open-rate-grey.svg'
            }
            alt="open"
            height="24"
            width="24"
          />
          <Flex
            width="100%"
            color={is_email ? ColorPallete.neutral600 : ColorPallete.neutral400}
            fontSize={Typography.TextMedium}
            padding="5px 0px"
          >
            Open Rate
          </Flex>
          <Flex
            width="100%"
            padding="3px 0px 0px"
            color={is_email ? ColorPallete.neutral900 : ColorPallete.neutral400}
            fontSize={is_email ? Typography.Heading : Typography.TextMedium}
          >
            {is_email ? `${open_rate}%` : '--'}
          </Flex>
        </Flex>

        <Flex
          width="30%"
          flexDirection="column"
          padding="12px"
          style={{ whiteSpace: 'nowrap' }}
        >
          <img
            src={
              is_email
                ? '/assets/images/Analytics/email-engagement.svg'
                : '/assets/images/Analytics/email-engagement-grey.svg'
            }
            alt="email"
            height="24"
            width="24"
          />
          <Flex
            width="100%"
            color={is_email ? ColorPallete.neutral600 : ColorPallete.neutral400}
            fontSize={Typography.TextMedium}
            padding="5px 0px"
            alignItems="center"
          >
            Email Engagement
            <Tooltip
              PopperProps={{
                sx: TooltipStyles,
              }}
              arrow="top"
              title={
                <TooltipBody>
                  Differing from traditional metrics, TIFIN's email engagement
                  score cuts through the noise to extrapolate meaningful
                  insights by only considering the most valuable engagement
                  factors. The score is an average of all contacts for each
                  email sent and content included.
                  <br />
                  Values: Open = 10, Click = 60, Lead Captured = 30
                  <br />
                  Consider scores above 50 as highly engaged!
                </TooltipBody>
              }
            >
              <IconButton
                style={{
                  height: '1rem',
                }}
              >
                <InfoIcon />
              </IconButton>
            </Tooltip>
          </Flex>
          <Flex
            width="100%"
            padding="3px 0px 0px"
            color={is_email ? ColorPallete.neutral900 : ColorPallete.neutral400}
            fontSize={Typography.Heading}
          >
            {is_email ? `${engagement_email}%` : '--'}
          </Flex>
        </Flex>

        <Flex
          width="30%"
          flexDirection="column"
          padding="12px"
          style={{ whiteSpace: 'nowrap' }}
        >
          <img
            src="/assets/images/Analytics/social-engagement-grey.svg"
            alt="social"
            height="24"
            width="24"
          />
          <Flex
            width="100%"
            color={ColorPallete.neutral400}
            fontSize={Typography.TextMedium}
            padding="5px 0px"
          >
            Social Engagement
          </Flex>

          <Flex
            width="100%"
            padding="3px 0px 0px"
            color={ColorPallete.neutral400}
            fontSize={Typography.TextMedium}
          >
            {is_email ? '--' : 'Coming Soon'}
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default CampaignCard;
