import styled from 'styled-components';

export const Tooltip = styled.div.attrs({
  className: 'tooltip',
})`
  position: relative;
  display: inline-block;

  & .tooltiptext {
    visibility: hidden;
    width: 100px;
    background-color: #ffffff;
    color: #4e6780;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    border: 1px solid #4e6780;
    font-size: 10px;
    font-weight: 600;
    /* Position the tooltip */
    position: absolute;
    z-index: 1;
    top: 100%;
    left: 50%;
    margin-left: -50px;
    ${'' /* margin-top: 5px; */}
  }

  &:hover .tooltiptext {
    visibility: visible;
  }
`;
