import React from 'react';
import LandingPageEditorContainer from '../../containers/LandingPageEditorContainer';
import withDripCampaignLayout from '../../hoc/withDripCampaignLayout';

const LandingPageEditorLayout = (props) => {
  const { roleType } = props;
  return (
    <LandingPageEditorContainer roleType={roleType} />
  )
}

export default withDripCampaignLayout(LandingPageEditorLayout);