import { get } from 'lodash';
import { createActions, handleActions } from 'redux-actions';
import { apiModuleDefaultState, createApiActions } from './helpers';
import { getScheduledArticleDataTypeWise, getRecentArticlesTypeWise } from '../../utils';

export const actions = createApiActions('explore');

export const moreActions = createActions({
  custom: {
    explore: {
      addToNewsletter: (payload) => payload,
      removeFromNewsletter: (payload) => payload,
      addToBookmarks: payload => payload,
      removeFromBookmarks: payload => payload
    },
  },
});

const reducer = handleActions(
  {
    [actions.api.explore.request]: (state) => ({
      ...state,
      isFetching: true,
      isError: false,
      isTicker: false,
      source: state.source
    }),
    [actions.api.explore.delete]: (state) => ({
      ...state,
      isDeleting: true,
    }),
    [actions.api.explore.response]: (state, { payload }) => ({
      ...state,
      isFetching: false,
      isError: false,
      data: payload.source === 'scheduled' ? getScheduledArticleDataTypeWise(payload) : payload.source === 'recent' ?  getRecentArticlesTypeWise(payload) : get(payload, 'data', {}),
      [get(payload, 'source')]: payload.source === 'scheduled' ? getScheduledArticleDataTypeWise(payload) : get(payload, 'data', {}),
      isDeleting: false,
      ticker: get(payload, 'selectedTicker', ''),
      isTicker: payload.selectedTicker ? true : false,
      isChangeTheme: false,
      contactName: payload.contactName ? payload.contactName : '',
      isNewsletterFetching: false,
      newsletterId: '',
      isBookmarksFetching: false,
      bookmarkArticleId: ''
    }),
    [actions.api.explore.error]: (state, { payload }) => ({
      ...state,
      isFetching: false,
      isError: true,
      data: {},
      error: payload,
      source: state.source,
    }),
    [actions.api.explore.setstate]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
    [moreActions.custom.explore.addToNewsletter]: (state, { payload }) => ({
      ...state,
      isNewsletterFetching: true,
      newsletterId: payload.params.newsletterId
    }),
    [moreActions.custom.explore.removeFromNewsletter]: (state, { payload }) => {
      return {
        ...state,
        isNewsletterFetching: true,
        newsletterId: payload.params.newsletterId
      }
    },
    [moreActions.custom.explore.addToBookmarks]: (state, {payload}) => {
      return {
        ...state,
        isBookmarksFetching: true,
        bookmarkArticleId: payload.params.articleId
      }
    },
    [moreActions.custom.explore.removeFromBookmarks]: (state, {payload}) => {
      return {
        ...state,
        isBookmarksFetching: true,
        bookmarkArticleId: payload.params.newsletterId
      }

    }
  },
  { ...apiModuleDefaultState, data: {}, error: null }
);

export default reducer;
