import React, { useState } from 'react';
import Popover from 'react-popover';
import moment from 'moment';
import { Formik } from 'formik';
import {
  MioDropdown,
  ButtonNoBorderBackground,
  SimpleDatePicker,
  Button,
  ErrorContainer,
} from '../';
import { formatMomentIsoDate } from '../../utils';
import './DateDropdown.styles.css';
import { PopoverWrapper, DatePickersWrapper } from './DateDropdown.styles';

const DateDropdown = ({
  value,
  onDateChange,
  position,
  currentPopUp,
  type,
  top,
  isExportPDF,
  right,
}) => {
  const [isDatePopoverOpen, setDatePopoverOpen] = useState(false);
  const [startDate, setStartDate] = useState(false);
  const [endDate, setEndDate] = useState(false);
  const [error, setError] = useState(false);
  const [selectedValue, setSelectedValue] = useState(value || 'Last 30 days');

  const onDropdownItemSelect = (val) => {
    setSelectedValue(val);
    onDateChange({
      selectedDate: val,
    });
  };

  const formatToUIDate = (momentDate) => {
    const uiDateFormat = 'MM/DD/YYYY';
    return momentDate.format(uiDateFormat);
  };

  const validate = () => {
    return startDate && endDate;
  };

  const onDateSubmit = () => {
    if (validate()) {
      const momentStartDate = moment(startDate);
      const momentEndDate = moment(endDate);
      const fromDate = formatMomentIsoDate(momentStartDate);
      const toDate = formatMomentIsoDate(momentEndDate);
      const customDate = `${formatToUIDate(momentStartDate)} - ${formatToUIDate(
        momentEndDate
      )}`;
      setSelectedValue(customDate);
      onDateChange({
        selectedDate: customDate,
        fromDate,
        toDate,
      });
      setDatePopoverOpen(false);
      setError(false);
    } else {
      setError(true);
    }
  };

  const onStartDateChange = (date) => {
    setStartDate(date);
  };

  const onEndDateChange = (date) => {
    setEndDate(date);
  };

  return (
    <Popover
      isOpen={isDatePopoverOpen}
      place="below"
      preferPlace="below"
      onOuterAction={() => setDatePopoverOpen(false)}
      style={{
        zoom: type === 'analytics' || type === 'members-tab' ? '1.3' : '',
        zIndex: '9999999999',
      }}
      body={
        <Formik>
          <DatePickersWrapper type={type}>
            <SimpleDatePicker
              maxDate={new Date()}
              handleDateChange={onStartDateChange}
              placeholder="From"
              type={type}
            />
            <SimpleDatePicker
              maxDate={!isExportPDF && new Date()}
              minDate={new Date(startDate)}
              handleDateChange={onEndDateChange}
              placeholder="To"
              type={type}
            />
            {error && <ErrorContainer>Both dates are required.</ErrorContainer>}
            <Button onClick={onDateSubmit}>Select</Button>
          </DatePickersWrapper>
        </Formik>
      }
    >
      <PopoverWrapper
        screenWidth={window.innerWidth}
        type={type}
        position={position}
        top={top}
        right={right}
      >
        <MioDropdown
          type="date"
          dropdownMenu={
            <ul>
              <li
                style={{ fontFamily: 'Poppins' }}
                onClick={() =>
                  onDropdownItemSelect(
                    currentPopUp === 'isSharedArticle'
                      ? 'Next 7 days'
                      : 'Last 7 days'
                  )
                }
              >
                {currentPopUp === 'isSharedArticle'
                  ? 'Next 7 days'
                  : 'Last 7 days'}
              </li>
              <li
                style={{ fontFamily: 'Poppins' }}
                onClick={() =>
                  onDropdownItemSelect(
                    currentPopUp === 'isSharedArticle'
                      ? 'Next 30 days'
                      : 'Last 30 days'
                  )
                }
              >
                {currentPopUp === 'isSharedArticle'
                  ? 'Next 30 days'
                  : 'Last 30 days'}
              </li>
              <li
                style={{ fontFamily: 'Poppins' }}
                onClick={() => setDatePopoverOpen(!isDatePopoverOpen)}
              >
                Custom
              </li>
            </ul>
          }
        >
          <input
            type="text"
            className="date-input"
            value={selectedValue}
            style={{
              border: '1px solid #6351ed',
              fontFamily: 'Poppins',
            }}
            disabled
            readOnly
          />{' '}
          <ButtonNoBorderBackground>
            <img src='/assets/images/calender2.svg' alt="" style={{ position: 'relative', top: '4px' }} />
          </ButtonNoBorderBackground>
        </MioDropdown>
      </PopoverWrapper>
    </Popover>
  );
};

export default DateDropdown;
