import { Flex } from '@rebass/grid';
import styled from 'styled-components';
import { Switch, Radio, FormControlLabel } from '@mui/material';
import { colors } from '../../styles';

export const RadioInput = styled.input`
  border: 2px solid white;
  box-shadow: 0 0 0 1px #aaa;
  appearance: none;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  background-color: #fff;
  transition: all ease-in 0.2s;

  &:checked {
    background-color: #C95CFC;
    box-shadow: 0 0 0 1px #aaa;
  }
`;

export const RadioInputLabel = styled.label`
  color: #000;
  font-size: 14px;
  margin-top: 5px;
  margin-left: 5px;
`;

export const InfoIcon = styled('img')`
  height: 14px;
  width: 14px;
  cursor: pointer;
  margin: 9px 6px;
  :hover { 
    opacity: 0.7;
  }
`;

export const ScrollWrapper = styled(Flex)`
  flex-direction: column;
  max-height: ${({ maxHeight }) => maxHeight || ''};
  height:  ${({ height }) => height || ''};
  overflow-x: hidden;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 5px;
  } 
  &::-webkit-scrollbar-track {
    background: #e9f6e6 !important;
  }
  &::-webkit-scrollbar-thumb {
    background: #bccdd4 !important;
  }
`;

export const ConfigureContainerStyle = styled('div')`
  font-family: 'Poppins' !important;
  margin: 0px auto;
  padding-top: 10px;
  background: ${colors.white};
`;

export const SwitchStyle = styled(Switch)`
  & .MuiSwitch-thumb {
    boxShadow: 0px 0px 25px 0px rgba(63, 106, 194, 0.15);
    color: #fff;
    border: 1px solid #6351ed;
  };
  & .MuiSwitch-switchBase {
    &.Mui-checked {
      & + .MuiSwitch-track {
        opacity: 1;
        background-color: #816EF8 !important;  
      }
    }
  };
  & .MuiSwitch-track {
    background-color: #797d83,
  };
`;

export const ButtonContainer = styled('div')`
  font-family: 'Poppins' !important;
  display: flex;
  justify-content: space-between;
  margin: 30px auto 0px;
  width: 100%;
`;


export const WhiteButton = styled('button')`
  font-family: 'Poppins' !important;
  background: ${({ disabled }) => disabled ? '#F2F4F7' : '#FFF'};
  border: ${({ disabled, border }) => disabled ? '1px solid #F2F4F7' : border ? border : '1px solid #E4E7EC'};
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.12);
  border-radius: 4px;
  cursor: ${({ disabled }) => disabled ? 'text' : 'pointer'};
  width: fit-content;
  display: flex;
  justify-content: center;
  margin: ${({ margin }) => margin ?? '10px 5px'};
  text-transform: none;
  padding: 8px 16px;
  text-align: center;
  color: ${({ color }) => color ?? color};
  transition: 0.5s;
  :hover {
    background: ${({ disabled }) => disabled ? '#F2F4F7' : '#f1f1f1'};
  }
`;

export const ErrorMessage = styled('p')`
  font-weight: 400;
  font-size: 10px;
  color: #E00000;
  text-align: left;
  margin: -5px 0 5px;
`;

export const TextLabel = styled('label')`
    font-weight: 500;
    font-size: 14px;
    color: #101828;
    text-align: left;
    width: 100%;
`;

export const HeadingSection = styled('h4')`
  font-family: 'Poppins' !important;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  text-align: left;
  display: flex;
  color: #101828;
  margin: ${({ margin }) => margin || '10px 0'};
`;

export const SubHeading = styled('h4')`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  text-align: left;
  color: #101828;
  margin: 20px 0 10px;
`;

export const RadioLabel = styled(FormControlLabel)`
  font-weight: 500;
  font-size: 14px;
  color: #101828;
`;

export const RadioStyle = styled(Radio)`
  color: #816EF8 !important;  
`;

export const FloatingLabel = styled('h4')`
  font-weight: 500;
  font-size: 14px;
  color: #101828;
  margin: 9px 0 22px;
  text-align: left;
`;

export const GroupingContainer = styled('div')`
  width: 100%;
  margin: 20px auto;
  border: 1px solid #ECF2FC;
  padding: 20px;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.12);
  border-radius: 8px;
  overflow: hidden;
`;


export const SaveButton = styled('button')`
  background: #816EF8;
  color: white;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  margin: 10px auto;
  padding: 12px 24px;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.12);
  border-radius: 4px;
  transition: 0.5s;
  border: 0;
  text-transform: none;
  :hover{
    background: #6351ed;
  }
`;

export const TextInput = styled('input')`
  width: ${({ width }) => width || '300px'};
  margin: ${({ margin }) => margin || '5px 0px'};
  border: 1px solid #e3e5e4;
  padding: 10px;
  border-radius: 4px;
  background: ${colors.white};
  box-shadow: ${({ boxShadow }) => boxShadow || '1px 2px 2px 0px #1018281F inset'};
  font-family: Poppins !important;

  
`;

export const SubHeaderSection = styled('div')`
  font-weight: 400;
  font-size: 14px;
  color: ${colors.gray_text};
  text-align: left;
`;

export const BorderBottomContainer = styled('div')`
  margin: 0 0 20px;
  padding: 0 0 20px;
  width: 100%;
  border-bottom: 1px solid ${colors.gray_border};
  display: table;
`;

export const CheckBoxContent = styled('div')`
  background: #FFFFFF;
  border: 1px solid #E4E7EC;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.12);
  border-radius: 4px;
  padding: 12px 18px;
  color: ${colors.black_01};
  display: flex;
  margin: 10px 22px 10px 0;
`;
