import React from 'react';
import styled from 'styled-components';
import { Flex } from '@rebass/grid';
import { Radio, FormControlLabel, Chip } from '@mui/material';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import SyncIcon from '@mui/icons-material/Sync';

export const NoEmailsWrapper = styled(Flex)`
  color: #757575;
  button {
    font-size: 16px;
    text-decoration: underline;
  }
`;

export const CaretIcon = styled.span`
  border: solid #c95cfc;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  float: right;
  margin-top: ${({ marginTop }) => marginTop || '3px'};
`;

export const HoverButton = styled.button`
  font-size: 13px;
  padding: ${({ padding }) => padding || '7px 20px;'};
  font-family: 'Poppins';
  background-color: ${({ bgColor }) => bgColor || '#eeeeee'};
  color: ${({ color }) => color || '#6351ed'};
  border: ${({ borderColor }) => borderColor ? `1px solid ${borderColor}` : '1px solid #a0a0a0'};
  margin: ${({ margin }) => margin || '20px 0px 0px 0px'};
  cursor: pointer;
  height: ${({ height }) => height || 'auto'};
  width: ${({ width }) => width || 'auto'};
  ${({ isSecondary }) =>
    isSecondary &&
    `
    background-color: #6351ed;
    border: 1px solid #6351ed;
    color: #fff;
  `}
  &:hover {
    color: ${({ hoverColor }) => hoverColor ? hoverColor : ''};
    border: 1px solid #6351ed;
    background-color: ${({ hoverBackground }) => hoverBackground ? hoverBackground : ''}
  }
`;

export const RadioInput = styled.input`
  border: 2.7px solid white;
  box-shadow: 0 0 0 1px #aaa;
  appearance: none;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  background-color: #fff;
  transition: all ease-in 0.2s;
  cursor: ${({ disabled }) => (!disabled ? 'pointer' : '')};

  &:checked {
    background-color: ${({ disabled }) => (!disabled ? '#6351ed' : '#ccc')};
    box-shadow: 0 0 0 1px #aaa;
  }
`;

export const RadioInputLabel = styled.label`
  color: #000;
  font-size: 14px;
  margin-top: 5px;
  margin-left: 5px;
  cursor: ${({ disabled }) => (!disabled ? 'pointer' : '')};
`;

export const SearchInput = styled.input.attrs({
  className: 'email-search',
})`
  font-family: 'Poppins' !important;
  border: 0px;
  padding: 2px 0px;
  width: 88%;
  font-size: 12px;
  font-family: Poppins;
  color: #7b7b7b;
  margin-left: 6px;

  ::placeholder {
    text-align: left;
    color: #667085;
    font-size: 12px;
  }
`;

export const EditTooltip = styled.div.attrs({
  className: 'tooltip',
})`
  position: relative;
  display: inline-block;

  & .tooltiptext {
    visibility: hidden;
    width: 180px;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    border: 1px solid #4e6780;
    font-size: 10px;
    font-weight: 600;
    /* Position the tooltip */
    position: absolute;
    z-index: 1;
    bottom: 157%;
    left: 15%;
    margin-left: -86px;
    ${'' /* margin-top: 5px; */}
  }

  & .tooltiptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: black transparent transparent transparent;
  }

  &:hover .tooltiptext {
    visibility: visible;
  }
`;

export const OuterTableContainer = styled('div')`
  font-family: 'Poppins' !important;
  display: flex;
  border: 1px solid #E4E7EC;
  border-radius: 4px;
  overflow-y: hidden;
`;

export const EmailListContainer = styled('div')`
  font-family: 'Poppins' !important;
  width: 25%;
  background: #FCFDFD;;
  border-right: 1px solid #E4E7EC;
  padding-bottom: 5px;
`;

export const EmailListInnerContainer = styled('div')`
  font-family: 'Poppins' !important;
  position: relative;
  height: 454px;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 5px !important;
  }
  &::-webkit-scrollbar-track {
    background: #E9F6E6 !important;
  }
  &::-webkit-scrollbar-thumb {
    background: #BCCDD4 !important;
  }
`;


export const TagContainer = styled('div')`
  font-family: 'Poppins' !important;
  display: flex;
  max-width: 25vw;
  overflow-x: auto;
  &::-webkit-scrollbar {
    height: 5px !important;
  }
  &::-webkit-scrollbar-track {
    background: #E9F6E6 !important;
  }
  &::-webkit-scrollbar-thumb {
    background: #BCCDD4 !important;
  }
`;

export const EmailAddressContainer = styled('div')`
  width: 75%;
  font-family: 'Poppins' !important;
  background: #FCFDFD;
`;

export const EditIcon = styled('img')`
  height: 24px;
  padding: 4px 6px;
  cursor: pointer;
  border-radius: 4px;
  transition: 0.5s;
  :hover {
    background: #f1f1f1;
  }
`;

export const TagStyle = styled('div')`
  font-family: 'Poppins' !important;
  padding: 4px 16px;
  background: #ECF2FC;
  color: #0B5CD7;
  border: 1px solid #B6CFF3;
  border-radius: 20px;
  margin: 5px 5px 0 0;
  height: 32px;
  white-space: nowrap;
`;

export const OrangeTagStyle = styled('div')`
  font-family: 'Poppins' !important;
  padding: 4px 16px;
  background: #FFF2E7;
  border: 1px solid #FFCA99;
  border-radius: 20px;
  color: #EA780E;
  margin: 5px 5px 0 0;
  height: 32px;
`;

export const TableHeader = styled('div')`
font-family: 'Poppins' !important;

  width: 100%;
  margin: 0;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #E4E7EC;
`;

export const TableTitle = styled('h4')`
  font-family: 'Poppins' !important;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  color: #101828;
  width: 95%;
  text-align: left;
  margin: 15px auto 0px;
`;

export const TableSubTitle = styled('p')`
  font-weight: 500;
  font-size: 14px;
  color: #475467;
  width: 95%;
  text-align: left;
  margin: 0 auto 15px;
  display: flex;
  height: 14px;
`;

export const TableActionContainer = styled('div')`
  width: 100%;
  margin: 0;
  border-bottom: 1px solid #E4E7EC;
  padding: 0px;
`;

export const InnerActionContainer = styled('div')`
  font-family: 'Poppins' !important;
  width: 95%;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  padding: 0;
  overflow-x: auto;
`;

export const SearchContainer = styled('div')`
  font-family: 'Poppins' !important;
  display: flex;
  width: 40%;
  justify-content: start;
  margin: ${({ searchActive }) => searchActive ? 0 : '5px 0 0'};
`;

export const AddButton = styled('button')`
  font-family: 'Poppins' !important;
  background: ${({ disabled }) => disabled ? '#F2F4F7' : '#816EF8'};
  border: ${({ disabled }) => disabled ? '1px solid #F2F4F7' : '1px solid #816EF8'};
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.12);
  border-radius: 4px;
  cursor: ${({ disabled }) => disabled ? 'text' : 'pointer'};
  color: ${({ disabled }) => disabled ? '#98A2B3' : 'white'};
  width: 140px;
  display: flex;
  justify-content: center;
  margin: 0px auto 20px;
  text-transform: none;
  padding: 10px 14px 5px;
  text-align: center;
  transition: 0.5s;
  :hover {
    background: ${({ disabled }) => disabled ? '#F2F4F7' : '#6351ed'};
  }
`;

export const WhiteButton = styled('button')`
  font-family: 'Poppins' !important;
  background: ${({ disabled }) => disabled ? '#F2F4F7' : '#FFF'};
  border: ${({ disabled }) => disabled ? '1px solid #F2F4F7' : '1px solid #E4E7EC'};
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.12);
  border-radius: 4px;
  cursor: ${({ disabled }) => disabled ? 'text' : 'pointer'};
  width: fit-content;
  display: flex;
  justify-content: center;
  margin: 10px 5px;
  text-transform: none;
  padding: 8px 16px;
  text-align: center;
  transition: 0.5s;
  :hover {
    background: ${({ disabled }) => disabled ? '#F2F4F7' : '#f1f1f1'};
  }
`;

export const EditButton = styled('button')`
    height: 40px;
    width: 30px;
    padding: 4px 0px 2px 0px;
    background: ${({ disabled }) => disabled ? '#F2F4F7' : '#FFF'};
    border: ${({ disabled }) => disabled ? '1px solid #F2F4F7' : '1px solid #E4E7EC'};
    border-radius: 4px;
    cursor: ${({ disabled }) => disabled ? 'text' : 'pointer'};
    margin: 10px 5px;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.12);
    transition: 0.5s;
    :hover {
      background: ${({ disabled }) => disabled ? '#F2F4F7' : '#f1f1f1'};
    }
`;

export const EmptyStyle = styled('div')`
  height: 60vh;
  width: 100%;
  margin: 40px 40px 40px 0;
  text-align: center;
  font-weight: 500;
  max-height: 60vh;
`;

export const TextInput = styled('input')`
  width: ${({ width }) => (width ? width : '80%')};
  display: flex;
  background: #FFFFFF;
  border: 1px solid #E4E7EC;
  box-shadow: inset 1px 2px 2px rgba(16, 24, 40, 0.12);
  border-radius: 4px;
  padding: 8px 4px;
  margin: 8px 0;
  font-family: 'Poppins';
`;

export const TextLabel = styled('label')`
  font-weight: 500;
  font-size: 14px;
  color: #101828;
  text-align: left;
  width: 100%;
`;


export const RadioLabel = styled(FormControlLabel)`
  font-weight: 500;
  font-size: 14px;
  color: #101828;
`;

export const RadioStyle = styled(Radio)`
  color: #816EF8 !important;  
`;

export const TooltipRow = styled('div')`
  width: 130px;
  padding: 10px;
  cursor: pointer;
  font-weight: 400;
  font-size: 14px;
  background: #fff;
  transition: 0.5s;
  color: #101828;
  :hover {
    background: #F2F4F7;
  }
`;

export const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} tooltipPlacementLeft placement="right-start" classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#FFFFFF',
    boxShadow: '0px 4px 8px rgba(16, 24, 40, 0.2)',
    padding: 0,
    borderRadius: '8px !important',
  },
}));

export const TagRow = styled('div')`
  font-family: 'Poppins';
  font-style: normal;
  width: 100%;
  font-weight: 400;
  font-size: 14px;
  background: #fff;
  transition: 0.5s;
  color: #101828;
  margin: 0px 5px;
  display: flex;
  justify-content: start;
`;

export const TagTitle = styled('div')`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #101828;
  width: 120px;
  margin: 10px 0;
  text-align: left;
`;

export const NewListCard = styled('div')`
  background: #FFFFFF;
  border: 1px solid #E4E7EC;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.12);
  border-radius: 8px;
  padding: 20px 10px;
  width: 49%;
`;

export const NewListIcon = styled('img')`
  height: 60px;
  width: 60px;
  margin: 10px auto;
  display: flex;
`;

export const NewListTitle = styled('h4')`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  color: #101828;
  margin: 10px auto;
  text-align: center;
`;

export const NewListBody = styled('div')`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #475467;
  margin: auto;
  text-align: center;
  width: 90%;
`;

export const NewListSubtitle = styled('h4')`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #101828;
  margin: -20px auto 10px; 
  text-align: left;
  width: 100%;
`;

export const DeleteIcon = styled('img')`
  height: 32px;
  width: 32px;
  cursor: pointer;
  border: 1px solid #E4E7EC;
  border-radius: 4px;
  padding: 6px;
  margin: 30px 0 -10px;
  transition: 0.5s;
  :hover {
    background: #F2F4F7;
  }
`;

export const SpaceSaver = styled('div')`
  height: 32px;
  width: 32px;
  padding: 6px;
  margin: 30px 0 -10px;
`;

export const AddIconCircle = styled('img')`
  height: 18px;
  width: 18px;
  margin: 1px 6px 0px;
  transition: 0.5s;
`;

export const AddContainer = styled('div')`
  font-family: 'Poppins';
  font-style: normal;
  width: fit-content;
  cursor: pointer;
  display: flex;
  margin: 10px 0 -10px;
  padding: 6px 12px;
  transition: 0.5s;
  border: 1px solid #E4E7EC;
  border-radius: 4px;
  transition: 0.5s;
  :hover {
    background: #F2F4F7;
  }
`;

export const AddMoreText = styled('p')`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #475467;
  text-align: left;
  margin: 0px;
`;

export const ErrorMessage = styled('p')`
  font-weight: 400;
  font-size: 10px;
  color: #E00000;
  text-align: left;
  margin: -5px 0 5px;
`;

export const ButtonContainer = styled('div')`
  font-family: 'Poppins' !important;
  display: flex;
  justify-content: space-between;
  margin: 30px auto 0px;
  width: 100%;
`;

export const ChipStyle = styled(Chip)`
  font-family: 'Poppins';
  font-style: normal;
  margin: 0px 5px 5px 0px;
  border: 1px solid #B6CFF3 !important;
  background: #ECF2FC !important;
  color: #0B5CD7;
  display: inline;
  .MuiChip-root {
    color: #404B50;
    opacity: 1;
  };
  .MuiChip-outlined {
    color: #B6CFF3;
  };
  .MuiChip-deleteIcon {
    color: #0B5CD7;
    opacity: 1;
    margin: 0;
  };
`;

export const AddChipStyle = styled(Chip)`
  font-family: 'Poppins';
  font-style: normal;
  margin: 5px 5px 5px 0px !important;
  border: 1px solid #E4E7EC !important;
  background: #FFF !important;
  color: #101828;
  display: inline;
  .MuiChip-root {
    color: #101828;
    opacity: 1;
  };
  .MuiChip-deleteIcon {
    color: #101828;
    opacity: 1;
    padding: 0 6px 0 0;
  };
`;


export const SearchResults = styled('div')`
  font-family: 'Poppins';
  font-style: normal;
  width: 100%;
  display: flex;
  justify-content: start;
  padding: 5px;
  margin: 0px auto;
  flex-flow: rows;
  flex-wrap: wrap;
`;

export const SearchBox = styled('input')`
  font-family: 'Poppins';
  font-style: normal;
  width: 100%;
  height: 40px;
  background: #FCFDFD;
  padding: 0 10px;
  border: 1px solid #E4E7EC;
  box-shadow: inset 1px 2px 2px rgba(16, 24, 40, 0.12);
  border-radius: 4px;
  color: #667085;
`;

export const SearchTitle = styled('h4')`
  font-family: 'Poppins';
  font-style: normal;
  font-size: 14px;
  font-weight: 400;
  color: #101828;
  margin: 10px 0 5px;
  text-align: left;
`;

export const TableBodyContainer = styled('div')`
  font-family: 'Poppins' !important;
  height: 479px;
  overflow-y: auto;
  min-height: 200px;
  &::-webkit-scrollbar {
    width: 5px !important;
  }
  &::-webkit-scrollbar-track {
    background: #E9F6E6 !important;
  }
  &::-webkit-scrollbar-thumb {
    background: #BCCDD4 !important;
  }
`;


export const SyncIconStyle = styled(SyncIcon)`
  margin: 10px !important;
  cursor: pointer;
  padding: 3px !important; 
  border-radius: 4px;
  height: 30px !important;
  width: 30px !important;
  transition: 0.5s !important;
  :hover {
    background: #E4E7EC;
  }
`;

export const ATSListContainer = styled(Flex)`
  font-family: 'Poppins' !important;
  display: flex;
  width: 100%;
  justify-content: ${({ justifyContent }) => justifyContent || 'flex-start'};
  align-items: ${({ alignItems }) => alignItems || 'center'};
  flex-direction: ${({ flexDirection }) => flexDirection || 'column'};
  background-color: #FFFFFF;
`;


export const ContactsCheckBox = styled.input.attrs({ type: 'checkbox' })`
  border: ${({ checked, bgColor }) =>
    checked && bgColor
      ? 'none'
      : checked && !bgColor
      ? '1px solid #6351ed'
      : '1px solid #c9c9c9'};
  opacity: 0.00000001;
  z-index: 1;
  position: relative;
  .checked::after {
    transform: rotate(-45deg) scale(1);
    margin-left: 3px;
  }
  ${({ disabled }) =>
    disabled && 'opacity: 0.5;pointer-events:none;cursor:default;border: none;'}
`;

export const ContactsCheckBoxWrapper = styled.div`
  position: relative;
    & label:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      margin: ${({ margin }) => margin || '4px'}; 
      width: 20px;
      height: 20px;
      transition: transform 0.28s ease;
      border-radius: ${({ borderRadius }) => borderRadius || '2px'};
      border: 1px solid #9a9a9a;
      z-index:0;
      background-color: ${({ checked, bgColor }) =>
        checked && bgColor ? bgColor : '#fff'};
  }
  & label:after {
      content: '';
      display: block;
      width: 5px;
      height: 3px;
      border-bottom: 1px solid #e4e4e4;
      border-left: 1px solid #e4e4e4;
      transform: rotate(-45deg) scale(0);
      transition: transform ease 0.25s;
      position: absolute;
      top: 7px;
      left: 20px;
      z-index: 0;
  }
  & input.checked+label:after{
    transform: rotate(-45deg) scale(2) !important;
    margin-left:3px;
    border-bottom: ${({ checked, bgColor }) =>
      checked && bgColor ? `1px solid #fff` : '1px solid #6351ed'};
      border-left: ${({ checked, bgColor }) =>
        checked && bgColor ? `1px solid #fff` : '1px solid #6351ed'};
  }
  & input.checked+label:before{
    border: ${({ color, bgColor, checked }) =>
      color
        ? `1px solid ${color}`
        : bgColor && checked
        ? 'none'
        : '1px solid #6351ed'};
  }
  }
`;

export const TabContainer = styled('div')`
  display: flex;
  border-top: 1px solid #E4E7EC;
  border-bottom: 1px solid #E4E7EC;
  background: #fff;
  justify-content: start;
`;

export const ContactsTabItem = styled.div.attrs({
  className: 'chips',
})`
  .chip {
    position: relative;
    display: flex;
    padding: 7px 10px 7px 9px;
    height: 40px;
    font-size: 12px;
    background-color: #fff;
    font-weight: 600;
    align-items: center;
    cursor: pointer;
  }
`;

export const ContactListBody = styled.div.attrs({
  className: 'contact-list-body',
})`
  display: flex;
  flex-direction: column;
  height: ${({ height }) => height || '150px'};
  max-height: ${({ height }) => height || '150px'};
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background: #e9f6e6 !important;
  }

  &::-webkit-scrollbar-thumb {
    background: #bccdd4 !important;
  }
`;

export const ContactsListWrapper = styled.div.attrs({
  className: 'recipients-list-wrapper',
})`
  padding: ${({ padding }) => padding || '8px'};
  width: 100%;
  height: 170px;
  margin-top: 10px;
  background-color: #fff;
`;

export const CheckBoxSpan = styled.span`
  border-radius: 7px;
  position: relative;
  font-size: 12px;
  width: ${({ screenWidth }) => (screenWidth < 768 ? '95%' : '70%')};
  display: inline-block;
  height: 22px;
  display: inline-block;
  text-align: center;
  border: 0px solid #959595;

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 100%;
    margin-top: -5px;
  }
  &:hover .tooltiptext {
    visibility: visible;
  }
`;

export const CheckBox = styled.input.attrs({ type: 'checkbox' })`
  position: absolute;
  left: -9999px;
  box-sizing: border-box;
  padding: 0;
  margin: 4px 0 0;
  line-height: normal;
  max-width: 100%;
  margin-bottom: 5px;
  padding: 0;
  font-size: 10px;
  text-align: left;
  &:not(:checked) + label:before {
    content: '';
    top: 4px;
    width: 20px;
    height: 20px;
    border: ${({ border, disabled }) =>
      border && disabled ? border : '1px solid #959595'};
    background: ${({ disabled }) => (disabled ? '#bcb8be' : 'transparent')};
    position: absolute;
    z-index: 1;
    box-sizing: border-box;
    border-radius: ${({ admin }) => (admin ? '3px' : '2px')};
  }
  &:checked + label:before {
    content: '';
    position: absolute;
    z-index: 1;
    transition: 0.2s;
    box-sizing: border-box;
    top: 5px;
    width: 9px;
    height: 15px;
    border-top: 1px solid transparent;
    border-left: 1px solid transparent;
    border-right: ${({ border, disabled }) =>
      border && disabled ? border : '3px solid #fff'};
    border-bottom: ${({ border, disabled }) =>
      border && disabled ? border : '3px solid #fff'};
    transform: rotateZ(37deg);
    transform-origin: 100% 100%;
    border-radius: ${({ admin }) => (admin ? '3px' : '0px')};
  }
  &:not(:checked) + label:after {
    top: 4px;
    width: 20px;
    height: 20px;
    border: 1px solid #959595;
    z-index: 0;
    content: '';
    position: absolute;
    transition: 0.2s;
    box-sizing: border-box;
    border-radius: ${({ admin }) => (admin ? '3px' : '2px')};
  }
  &:checked + label:after {
    top: 4px;
    width: 20px;
    height: 20px;
    border: ${({ border, disabled }) =>
      border && disabled ? border : '1px solid #816EF8'};
    z-index: 0;
    content: '';
    color: #816EF8;
    background-color: #816EF8;
    position: absolute;
    transition: 0.2s;
    box-sizing: border-box;
    border-radius: ${({ admin }) => (admin ? '3px' : '2px')};
  }
  &:checked + label {
    color: ${({ border, disabled }) =>
      border && disabled ? '#c9c9c9' : "#816EF8"};
  }
`;

export const CheckBoxLabel = styled.label`
  position: relative;
  overflow: hidden;
  cursor: ${({ disconnected }) => (disconnected ? 'default' : 'pointer')};
  display: inline-block;
  height: 30px;
  line-height: 21px;
  color: ${({ disconnected, color }) =>
    disconnected === true ? (color ? color : '#b3b3b3') : '#49484a'};
  font-weight: ${({ disconnected }) => (disconnected === true ? 0 : 400)};
  border-radius: 7px;
  width: 100%;
  text-align: left;
  &:before,
  &:after {
    left: 8px;
  }
  padding-left: ${({ screenWidth, fb }) =>
    screenWidth < 768 ? '20px' : '25px'};
  font-size: ${({ fontSize }) => fontSize || '10px'};
`;

export const EmailSegmentListWrapper = styled.div.attrs({
  className: 'email-segment-list-wrapper',
})`
  padding: ${({ padding }) => padding || '0px'};
  width: 100%;
  margin-top: 10px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  height: ${({ height }) => height || '330px'};
  max-height: ${({ height }) => height || '330px'};
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background: #e9f6e6 !important;
  }

  &::-webkit-scrollbar-thumb {
    background: #bccdd4 !important;
  }
`;

export const ApproveToShareFinalBlockWrapper = styled.div.attrs({
  className: 'final-data-block-wrapper'
})`
display: flex;
width: ${({ width }) => width || '100%'};
flex-direction: column;
background-color: #fff;
height: ${({ height }) => height || '500px'};
max-height: ${({ height }) => height || '500px'};
position: relative;
  overflow-x: hidden;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background: #e9f6e6 !important;
  }

  &::-webkit-scrollbar-thumb {
    background: #bccdd4 !important;
  } 
`;



















