import React from 'react';
import { DripCampaignContainer } from '../../containers';

import withDripCampaignLayout from '../../hoc/withDripCampaignLayout';

const DripCampaignLayout = (props) => {
    console.log('Drip Campaign Layout');
    return (
        <DripCampaignContainer {...props} />    
    )
}

export default withDripCampaignLayout(DripCampaignLayout);

