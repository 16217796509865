import { styled } from '@mui/material';
import { tooltipPositionForTriangleShape } from './utils';

export const ContactsPageWrapper = styled('div')(() => ({
  display: 'flex',
  flexWrap: 'wrap',
  gap: '1rem',
  width: '100%',
}));

export const ChartContainer = styled('div')(() => ({
  boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.12)',
  border: '1px solid #E4E7EC',
  background: '#fff',
  borderRadius: '0.5rem',
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  flex: 0.8,
  minWidth: '600px',
}));

export const ChartHeader = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  columnGap: '4px',
  borderBottom: '1px solid #E4E7EC',
  padding: '1.25rem',

  '& svg': {
    color: '#101828',
    fontSize: '1.5rem',
  },
}));

export const DropdownMenuContainer = styled('div')(() => ({
  margin: 0,
  color: '#101828',
  font: '500 1rem/1rem Poppins,sans-serif',
  textAlign: 'left',
  padding: '0.625rem 1rem',
}));

export const dropdownStyles = {
  '& .MuiSelect-select': {
    fontFamily: 'Poppins,sans-serif',
    boxShadow: 'none',
    width: 'fit-content',
    padding: '0',
  },
  '.MuiOutlinedInput-notchedOutline': { border: 0 },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    border: 0,
  },
};

export const ContactsRecommendationWrapper = styled('div')(() => ({
  display: 'flex',
  width: '100%',
  flex: 0.3,
  minWidth: '300px',
}));

export const ContactMetricsContainer = styled('div')(() => ({
  height: '100%',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '1rem',
  padding: '1rem',
  borderRadius: '0.5rem',
  border: '1px solid #BBBEF7',
  background: '#F8F7FF',
}));

export const InsightsTitle = styled('h3')(() => ({
  font: '500 1rem Poppins, sans-serif',
  color: '#000',
  margin: 0,
}));

export const ContactsMetricContainer = styled('div')(() => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'flex-start',
  gap: '1rem',

  h5: {
    font: '600 2.25rem Poppins, sans-serif',
    color: '#000',
    margin: '0 0.5rem',
  },
}));

export const ContactEngagementInsights = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  alignItems: 'flex-start',
  gap: '1rem',
  flexDirection: 'row',
  justifyContent: 'space-between',

  h5: {
    font: '500 1.75rem Poppins, sans-serif',
    color: '#000',
    margin: '0 0.5rem',
  },
}));

export const ContactsCount = styled('div')(({ bgColor }) => ({
  background: bgColor ?? '',
  borderRadius: '100%',
  cursor: 'pointer',
  width: '50px',
  height: '50px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  font: '500 1.75rem Poppins, sans-serif',
  color: '#000',
  marginBottom: '4px',
}));

export const Description = styled('p')(() => ({
  font: '400 0.75rem Poppins, sans-serif',
  color: '#475467',
  margin: 0,
  textAlign: 'left',
}));

export const Message = styled('div')(({ bgColor, borderColor }) => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  position: 'relative',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '1rem',
  padding: '1rem',
  backgroundColor: bgColor,
  borderRadius: '0.5rem',
  border: `1px solid ${borderColor}`,

  h5: {
    font: '500 0.875rem/1.5rem Poppins, sans-serif',
    color: '#475467',
    margin: 0,
    textAlign: 'left',
  },

  strong: {
    color: '#101828',
  },

  svg: {
    color: borderColor,
    fontSize: '2.25rem',
  },
}));

export const TriangleShape = styled('div')(({ borderColor, position }) => ({
  width: 10,
  height: 10,
  position: 'absolute',
  border: '10px solid transparent',
  borderBottom: `10px solid ${borderColor}`,
  top: -20,
  ...tooltipPositionForTriangleShape(position)?.shape1,
}));

export const TriangleShape2 = styled('div')(({ borderColor, position }) => ({
  width: 10,
  height: 10,
  position: 'absolute',
  top: -18,
  border: '9px solid transparent',
  borderBottom: `9px solid ${borderColor}`,
  ...tooltipPositionForTriangleShape(position)?.shape2,
}));

export const ContactsCountDataKey = styled('p')(() => ({
  color: '#667085',
  font: '500 0.75rem/1rem Poppins, sans-serif',
  margin: 0,
  textTransform: 'capitalize',
}));

export const ContactsEngagementTableWrapper = styled('div')(() => ({
  boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.12)',
  border: '1px solid #E4E7EC',
  background: '#fff',
  borderRadius: '0.5rem',
  width: '100%',
  marginTop: '30px',
}));

export const ContactsEngagementTableHeader = styled('div')(() => ({
  padding: '1rem',
  borderBottom: '1px solid #E4E7EC',
  h2: {
    color: '#101828',
    font: '500 1.25rem Poppins, sans-serif',
    margin: 0,
    textAlign: 'left',
  },
  p: {
    color: '#475467',
    font: '500 0.875rem Poppins, sans-serif',
    margin: 0,
    textAlign: 'left',
  },
}));

export const TableBody = styled('p')(() => ({
  font: '500 0.875rem Poppins,sans-serif',
  margin: 0,
  textAlign: 'left',
  color: '#101828',
}));

export const EngagementScoreColumn = styled('p')(() => ({
  font: '500 1.25rem Poppins,sans-serif',
  margin: 0,
  textAlign: 'left',

  '&.above-fifty': {
    color: '#261A83',
  },

  '&.below-fifty': {
    color: '#816EF8',
  },
}));

export const PaginationContainer = styled('div')(() => ({
  border: '1px solid #E4E7EC',
  background: '#FCFDFD',
  width: '100%',
  borderBottomLeftRadius: '0.5rem',
  borderBottomRightRadius: '0.5rem',
}));

export const TruncatedTableBody = styled(TableBody)(() => ({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitLineClamp: 2,
  WebkitBoxOrient: 'vertical',
}));
export const TruncatedEmailText = styled(TruncatedTableBody)(() => ({
  WebkitLineClamp: 1,
}));

export const TooltipStyles = {
  '& .MuiTooltip-tooltip': {
    boxShadow: '0px 0px 100px rgba(63, 106, 194, 0.15)',
    padding: '10px 5px',
    border: '1px solid #ccc',
    background: '#fff',
    borderRadius: '4px',
    color: '#101828',
    font: '500 0.875rem Poppins,sans-serif',
  },
};
