import React, { useState, useEffect } from 'react';

import { Box, Flex } from '@rebass/grid';
import { Button, LoaderWrapper } from '../../../components';
import { StepperV2 } from '../../../components/StepperV2/StepperV2';
import SetObjectivesV2 from '../SetObjectivesV2/SetObjectivesV2';
import { useHistory } from 'react-router-dom';
import CustomizeCampaignV3 from '../CustomizeCampaignV3/CustomizeCampaignV3';
import CampaignOverviewOnetimeSeries from '../CampaignOverviewOnetime&Series.jsx/CampaignOverviewOnetimeSeries';
import { scheduleCampaign } from '../../../services/campaign';
import { isEmpty } from 'lodash';

const EmailAndSocialContainer = ({
  socialNetworkData,
  segmentList,
  userData,
  curFlow,
  contactGroups,
  campaignSetState,
  endCreateFlow,
  membersList,
  setObjectivesTabData,
  setConListRecArticlesLoader,
  campaignId,
  isAdmin,
  isEnterpriseLevelAdmin,
  campaignStatus,
  reviewType,
  savedTemplate,
  createMode,
  seriesId,
  isFromAddCampaign,
  series,
  userRole,
  curFlowHandler,
  campaignCreateFlow,
  activeIndex,
  subscriptionType,
  getAccountId,
  isFirmLevelAdmin,
  companyId,
  firmSettings,
  firmsArray,
  compliance,
  isEnterpriseAdvisor,
  contactsLoading,
  segmentsLoading,
  isAdvisorApprovalMode,
  advisorApprovalRequired,
  isadvMode
}) => {
  const [step, setStep] = useState(activeIndex ? activeIndex : 0);
  const [loading, setLoading] = useState(false);
  const [saveAsDraftLoading, setSaveAsDraftLoading] = useState(false);
  const [timeBlocks, setTimeBlocks] = useState([]);
  const [blocks, setBlocks] = useState([]);

  const stepListLines = [
    {
      label: 'Campaign Details',
    },
    {
      label: 'Customize Campaign',
    },
    {
      label: 'Campaign Overview',
    },
  ];

  const history = useHistory();

  useEffect(() => {
    setStep(activeIndex);
  }, [activeIndex])
  

  const handleSaveAsDraft = () => {
    let campaigns = {};

    blocks.forEach((item, index) => {
      let currentTimeBlock = timeBlocks.filter(t => t.id === item.id)[0];
      campaigns[item.id] = {
        ...(item.is_email === "true" && !isEmpty(setObjectivesTabData.emList)) && {
          "email" : {
            schedule: currentTimeBlock.schedule,
            ...(currentTimeBlock.schedule === 'immediate') && {
              schedule_time: setObjectivesTabData.emList.map((item, index) => {
                return {
                  destination_id: item.id
                }
              })
            },
            ...(currentTimeBlock.schedule === 'specific_time') && {
              schedule_time:  setObjectivesTabData.emList.map((item, index) => {
                return {
                  destination_id: item.id,
                  time: currentTimeBlock.schedule_time
                }
              })
            },
            ...(currentTimeBlock.schedule === 'different_time') && {
              schedule_time: currentTimeBlock.schedule_time
            },
            snetwork_act_id: getAccountId('em')
          }
        },
        ...(item.is_email === 'true' && !isEmpty(setObjectivesTabData.segmentList)) && {
          "segment" : {
            schedule: currentTimeBlock.schedule,
            ...(currentTimeBlock.schedule === 'immediate') && {
              schedule_time: setObjectivesTabData.segmentList.map((item, index) => {
                return {
                  destination_id: item.id
                }
              })
            },
            ...(currentTimeBlock.schedule === 'specific_time') && {
              schedule_time:  setObjectivesTabData.segmentList.map((item, index) => {
                return {
                  destination_id: item.id,
                  time: currentTimeBlock.schedule_time
                }
              })
            },
            ...(currentTimeBlock.schedule === 'different_time') && {
              schedule_time: currentTimeBlock.schedule_time
            },
            snetwork_act_id: getAccountId('em')
          }
        },
        ...(item.is_facebook === "true") && {
          "facebook" : {
            schedule: currentTimeBlock.schedule,
            ...(currentTimeBlock.schedule === 'immediate') && {
              schedule_time: setObjectivesTabData.fbList.map((item, index) => {
                return {
                  destination_id: item
                }
              })
            },
            ...(currentTimeBlock.schedule === 'specific_time') && {
              schedule_time: setObjectivesTabData.fbList.map((item, index) => {
                return {
                  destination_id: item,
                  time: currentTimeBlock.schedule_time
                }
              })
            },
            snetwork_act_id: getAccountId('fb')
          }
        },
        ...(item.is_linkedin === "true") && {
          "linkedin" : {
            schedule: currentTimeBlock.schedule,
            ...(currentTimeBlock.schedule === 'immediate') && {
              schedule_time: setObjectivesTabData.lnList.map((item, index) => {
                return {
                  destination_id: item
                }
              })
            },
            ...(currentTimeBlock.schedule === 'specific_time') && {
              schedule_time: setObjectivesTabData.lnList.map((item, index) => {
                return {
                  destination_id: item,
                  time: currentTimeBlock.schedule_time
                }
              })
            },
            snetwork_act_id: getAccountId('ln')
          }
        },
        ...(item.is_twitter === "true") && {
          "twitter" : {
            schedule: currentTimeBlock.schedule,
            ...(currentTimeBlock.schedule === 'immediate') && {
              schedule_time: setObjectivesTabData.twList.map((item, index) => {
                return {
                  destination_id: item
                }
              })
            },
            ...(currentTimeBlock.schedule === 'specific_time') && {
              schedule_time: setObjectivesTabData.twList.map((item, index) => {
                return {
                  destination_id: item,
                  time: currentTimeBlock.schedule_time
                }
              })
            },
            snetwork_act_id: getAccountId('tw')
          }
        }
      }
      
    });

    let payload = {
      campaigns: JSON.stringify(campaigns)
    }
    setSaveAsDraftLoading(true);
    scheduleCampaign(seriesId, payload).then(res => {
      setSaveAsDraftLoading(false);
      endCreateFlow();
      history.push('/campaignV2');
    }).catch(err => {
      setSaveAsDraftLoading(false)
      console.log(err);
    })
  } 

  const handleSaveAsDraftClick = () => {
    console.log('clicked');
    if(step === 1) {
      handleSaveAsDraft()
    } else {
      campaignSetState({ fromDashboard: true });
      history.push('/campaignV2');
    }
  };


  const handleNextClick = (payload) => {

    if (step === 1) {

    } else {
      setStep(step + 1);
      campaignSetState({
        activeIndex: step + 1
      })
    }

  };



  return (
    <Box width={1}>
      <Flex
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        width="80%"
        margin="14px auto 0px"
      >
        <Flex
          display="flex"
          justifyContent="flex-start"
          padding="10px 10px 0px 0px"
          style={{ color: '#000000', fontSize: '26px', fontWeight: 500 }}
        >
          New Campaign
        </Flex>
        {step === 1 && (
          <LoaderWrapper isLoading={saveAsDraftLoading}>
            <Flex
              display="flex"
              justifyContent="flex-end"
              style={{ cursor: 'pointer' }}
            >
              <Button
                styles={{
                  borderRadius: '4px',
                  border: '1px solid #E4E7EC',
                  boxShadow: '',
                }}
                color={blocks?.length === 0 ? '#fff' : "#101828"}
                bgColor={blocks?.length === 0 ? '#C9C9C9' : "#FFFFFF"} 
                margin="10px auto 0px"
                onClick={handleSaveAsDraftClick}
                boxShadow="0px 1px 2px 0px rgba(16, 24, 40, 0.12)"
                disabled={blocks?.length === 0}
              >
                Save As Draft
              </Button>
            </Flex>
          </LoaderWrapper>
        )}


      </Flex>
      <StepperV2
        stepperList={stepListLines}
        selectedIndex={step} // TODO: Replace with REDUX state.
      />
      {step === 0 && (
        <SetObjectivesV2
          step={step}
          setStep={setStep}
          socialNetworkData={socialNetworkData}
          segmentList={segmentList}
          curFlow={curFlow}
          seriesId={seriesId}
          isFromAddCampaign={isFromAddCampaign}
          series={series}
          contactGroups={contactGroups}
          onSaveAsDraftClick={handleSaveAsDraftClick}
          endCreateFlow={endCreateFlow}
          membersList={membersList}
          setObjectivesTabData={setObjectivesTabData}
          setConListRecArticlesLoader={setConListRecArticlesLoader}
          campaignId={campaignId}
          isAdmin={isAdmin}
          isEnterpriseLevelAdmin={isEnterpriseLevelAdmin}
          campaignStatus={campaignStatus}
          handleNext={handleNextClick}
          reviewType={reviewType}
          savedTemplate={savedTemplate}
          createMode={createMode}
          loading={loading}
          setLoading={setLoading}
          campaignCreateFlow={campaignCreateFlow}
          campaignSetState={campaignSetState}
          subscriptionType={subscriptionType}
          isFirmLevelAdmin={isFirmLevelAdmin}
          isEnterpriseAdvisor={isEnterpriseAdvisor}
          contactsLoading={contactsLoading}
          segmentsLoading={segmentsLoading}
        />
      )}
      {step === 1 && (
        <CustomizeCampaignV3
          step={step}
          userData={userData}
          setStep={setStep}
          onSaveAsDraftClick={handleSaveAsDraftClick}
          endCreateFlow={endCreateFlow}
          seriesId={seriesId}
          campaignSetState={campaignSetState}
          setObjectivesTabData={setObjectivesTabData}
          getAccountId={getAccountId}
          timeBlocks={timeBlocks}
          setTimeBlocks={setTimeBlocks}
          blocks={blocks}
          setBlocks={setBlocks}
          reviewType={reviewType}
        />
      )}
      {step === 2 && (
        <CampaignOverviewOnetimeSeries
          step={step}
          setStep={setStep}
          onSaveAsDraftClick={handleSaveAsDraftClick}
          endCreateFlow={endCreateFlow}
          seriesId={seriesId}
          setObjectivesTabData={setObjectivesTabData}
          campaignSetState={campaignSetState}
          isFirmLevelAdmin={isFirmLevelAdmin}
          firmSettings={firmSettings}
          companyId={companyId}
          compliance={compliance}
          reviewType={reviewType}
          userData={userData}
          isAdvisorApprovalMode={isAdvisorApprovalMode}
          advisorApprovalRequired={advisorApprovalRequired}
          isadvMode={isadvMode}
          blocks={blocks}
        />
      )}
    </Box>
  );
};

export default EmailAndSocialContainer;
