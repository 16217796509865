import { Flex } from '@rebass/grid';
import React from 'react';
import { ColorPallete, Typography } from '../../../utils';
import { isEmpty } from 'lodash';
import { ProgressBar } from '../../../componentsV2';
import { Button } from '../../../components';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { Tooltip } from '@mui/material';

const AnalyticsContentTable = ({
  headers,
  data,
  onPageChange,
  maxPage,
  loading,
  paginationData,
  page,
  onPageClick,
  children,
  pageIndex,
  setPageIndex,
  handleNextClick,
  handlePreviousClick,
  tooltip,
  tooltipText
}) => {
  return (
    <Flex width="100%" flexDirection="column">
      <Flex
        width="100%"
        alignItems="center"
        style={{
          borderTop: `1px solid ${ColorPallete.neutral200}`,
          borderBottom: `1px solid ${ColorPallete.neutral200}`,
        }}
        backgroundColor={ColorPallete.neutral50}
      >
        {headers.map((item, index) => (
          <Flex
            padding="16px"
            fontSize={Typography.TextLarge}
            color={ColorPallete.neutral900}
            width={item.width}
          >
            {item.name}
            {item.name === 'Engagement Score' && (
              <Tooltip title="The content engagement score is calculated by taking average of 
              the number of clicks and leads captured for a specific piece of content. One click = a score of 70, 
              and additional lead capture = a score of 30. We will consider each contacts engagement with a 
              specific piece of content individually and use the average of all contact scores in a given 
              distribution to display the overall content engagement result." arrow>
                <Flex>
                  <img
                    style={{
                      width: '16px',
                      margin: '0px 0px 0px 4px',
                    }}
                    src="/assets/images/Analytics/Grey-Info.svg"
                    alt=""
                  />
                </Flex>
              </Tooltip>
            )}
          </Flex>
        ))}
      </Flex>
      {loading && (
        <SkeletonTheme>
          <Skeleton
            height={49}
            count={10}
            baseColor={ColorPallete.neutral100}
          />
        </SkeletonTheme>
      )}

      {children}

      <Flex
        width="100%"
        justifyContent="space-between"
        padding="16px"
        style={{
          border: `1px solid ${ColorPallete.neutral200}`,
        }}
      >
        <Button
          margin="0px"
          bgColor={ColorPallete.white}
          color={ColorPallete.neutral900}
          borderRadius="4px"
          borderColor={ColorPallete.neutral200}
          onClick={() => onPageChange('prev')}
          disabled={loading || !paginationData?.has_previous_page}
          styles={{
            display: 'flex',
            alignItems: 'center',
            boxShadow: '0px 1px 2px 0px #1018281F',
          }}
        >
          <img
            src="/assets/images/Analytics/left-arrow-black.svg"
            alt=""
            style={{ margin: '0px 4px 0px 0px' }}
          />
          Previous
        </Button>
        <Flex width="33%" alignItems="center" style={{ position: 'relative', left: '10%' }}>
          {loading && (
            <Skeleton
              width={200}
              height={21}
              baseColor={ColorPallete.neutral100}
            />
          )}
          <Flex width="5%">
            {pageIndex > 0 && !loading && (
              <img
                src="/assets/images/Analytics/ChevronLeft.svg"
                alt=""
                style={{ cursor: 'pointer' }}
                onClick={handlePreviousClick}
              />
            )}
          </Flex>

          <Flex width="90%" justifyContent="center" marginRight="55%">
            {maxPage > 0 &&
              !loading &&
              Array.from({ length: maxPage }, (_, i) => i + 1)
                .slice(pageIndex, pageIndex + 8)
                .map((item, index) => (
                  <Flex
                    width="10px"
                    onClick={() => onPageClick(item)}
                    color={
                      item === page
                        ? ColorPallete.neutral900
                        : ColorPallete.neutral600
                    }
                    fontSize={Typography.TextMedium}
                    padding="0px 16px"
                    style={{ cursor: 'pointer' }}
                  >
                    {item}
                  </Flex>
                ))}
          </Flex>
          <Flex width="5%">
            {pageIndex < maxPage && maxPage > 8 && !loading && (
              <img
                src="/assets/images/Analytics/ChevronRight.svg"
                alt=""
                style={{ cursor: 'pointer' }}
                onClick={handleNextClick}
              />
            )}
          </Flex>
        </Flex>
        <Button
          margin="0px"
          bgColor={ColorPallete.white}
          color={ColorPallete.neutral900}
          borderRadius="4px"
          borderColor={ColorPallete.neutral200}
          disabled={loading || !paginationData?.has_next_page}
          styles={{
            display: 'flex',
            alignItems: 'center',
            boxShadow: '0px 1px 2px 0px #1018281F',
          }}
          onClick={() => onPageChange('next')}
        >
          Next
          <img
            src="/assets/images/Analytics/right-arrow-black.svg"
            alt=""
            style={{ margin: '0px 0px 0px 4px' }}
          />
        </Button>
      </Flex>
    </Flex>
  );
};

export default AnalyticsContentTable;
