import useRESTQuery from './useRESTQuery';

function useQueryForSocialKeyInsights(email) {
  return useRESTQuery({
    key: 'social-key-insights',
    dependencies: [email],
    endpoint: `/recommendation/social-insights?email=${email}`,
    options: {
      select: (res) => {
        const result = res?.result;
        const most_popular_time = result?.created_hr?.[0] ?? null;
        const most_popular_day = result?.created_day?.[0] ?? null;
        const title_sentiment = result?.['caption sentiment'] ?? null;
        const title_length = result?.['caption length'] ?? null;
        return {
          most_popular_time,
          most_popular_day,
          title_sentiment,
          title_length,
          result
        };
      },
      enabled: !!email,
      refetchOnMount: true,
    },
  });
}

export default useQueryForSocialKeyInsights;
