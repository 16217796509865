import styled from 'styled-components';

export const StyledArticle = styled.article`
  width: 100%;
  min-height: 190px;
  max-height: ${({isFromAddContent}) => isFromAddContent ? '205px' : '190px'};
  background-color: white;
  border-radius: 10px;
  text-align: left;
  margin: 0 auto;
  .mini-img {
    background-size: cover;
    width: 100%;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    height: 94px;
    object-fit: cover;
    display: block;
  }
  .mini-details-wrapper {
    padding: 0 10px;
    // border: 1px solid #cacaca;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    background-color: #e4e4e4;
    min-height: 98px;
    a {
      word-break: break-all;
      overflow: hidden;
      text-decoration: none;
      color: #030303;
      font-weight: bold;
      font-size: 13px;
      line-height: 15px;
      padding: 10px 0 0;
      display: block;
      line-height: 1.46;
    }
    .place-date {
      color: #49484a;
      font-size: 11px;
      padding: 10px 0 5px;
      font-family: 'Poppins';
    }
  }
`;
