import { get } from 'lodash';
import { takeLatest, all, call, put } from 'redux-saga/effects';
import { getCampaignDetails, getSelectedArticles } from '../../services/campaign';
import { actions as campaignActions, moreActions as campaignMoreActions } from '../modules/campaignDetails.module';

function* campaignDetailsRequest({ payload }) {
  console.log('campaignDetailsRequest', payload.id);
  try {
    const response = yield call(getCampaignDetails, payload.id);
    const bucketResponse = yield call(getSelectedArticles, payload.id);
    const data = get(response, 'result.data', {});
    const bucketData = get(bucketResponse, 'result', {});
    console.log('BUCKET DATA- - - - ', bucketData);
    yield put(campaignActions.api.campaignDetails.response(data));
    yield put(campaignMoreActions.custom.campaignDetails.bucketArticles(bucketData.data));
  } catch (error) {
    console.log('ERROR - - - ', error);
    yield put(campaignActions.api.campaignDetails.error(error));
  }
}

function* campaignDetailsSaga() {
  // yield all([
  //   takeLatest(
  //     campaignActions.api.campaignDetails.request,
  //     campaignDetailsRequest
  //   ),
  // ]);
}

export default campaignDetailsSaga;
