import React, { useState } from 'react';
import './ClientResults.css';
import { LoaderWrapper } from '../../../components';
import { Modal, ModalBody, ModalHeader, ModalCloseButton } from '../../Modal';
import { getAllPSTVScores, getAllFilteredPSTVScores, postSendInvite } from '../../../services/dashboard/index';
import { parse } from 'json2csv';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { Flex } from '@rebass/grid';
import { isEmpty, map } from 'lodash';
import FilterContacts from '../../../containers/AnalyticsContainer/EnagagementTab/FilterContacts';
import { getMonthDate } from '../../../utils';

const ClientResults = props => {

    const [showFilterOptions, toggleFilterOptions] = useState(false);
    const [showAllClients, toggleAllClients] = useState(false);
    const [allClients, setAllClients] = useState([]);
    const [allClientsFilter, toggleAllClientsFilter] = useState(false);
    const [currentPage, setCurrentPage] = useState(0);
    const [loading, toggleLoading] = useState(false);
    const [sendEmailModal, toggleSendEmailModal] = useState(false);
    const [resendEmailResponse, setResendEmailResponse] = useState('');
    const [pagination, setPagination] = useState({ before: null, after: null, totalArticles: 0 });
    const [selectedFilter, setSelectedFilter] = useState(null);
    const [allEmailListFilter, toggleAllEmailListFilter] = useState(false);
    const [selectedContacts, setSelectedContacts] = useState([]);
    const [showNameTooltip, toggleNameTooltip] = useState(false);

    const filterHandler = flag => {
        toggleFilterOptions(false);
        console.log('[FILTER FLAG] - ', flag.toLowerCase());
        props.filter(flag.toLowerCase());
    }

    const filterByContactList = (id) => {
        toggleLoading(true);
        setCurrentPage(0);

        if (id === 'all' && props.contactList.length === selectedContacts.length) {
            setSelectedContacts([]);
            getAllFilteredPSTVScores(props.filterFlag.toLowerCase(), null, null)
                .then(response => {
                    console.log('[ALL CLIENTS RESULTS] - ', response.result.data.scores);
                    setAllClients(response.result.data.scores);
                    setPagination({ before: response.result.data.pagination.before, after: response.result.data.pagination.after, totalArticles: response.result.data.total_article });
                    toggleLoading(false);
                })
                .catch(err => console.log('[ERROR] - ', err));

        } else if (id === 'all' && props.contactList.length !== selectedContacts.length) {
            setSelectedContacts(map(props.contactList, 'id'));
            getAllFilteredPSTVScores(props.filterFlag.toLowerCase(), null, null, 'all')
                .then(response => {
                    console.log('[ALL CLIENTS RESULTS] - ', response.result.data.scores);
                    setAllClients(response.result.data.scores);
                    setPagination({ before: response.result.data.pagination.before, after: response.result.data.pagination.after, totalArticles: response.result.data.total_article });
                    toggleLoading(false);
                })
                .catch(err => console.log('[ERROR] - ', err));
        } else if (!selectedContacts.includes(id)) {
            setSelectedContacts([
                ...selectedContacts,
                id
            ]);
            getAllFilteredPSTVScores(props.filterFlag.toLowerCase(), null, null, [...selectedContacts, id])
                .then(response => {
                    console.log('[ALL CLIENTS RESULTS] - ', response.result.data.scores);
                    setAllClients(response.result.data.scores);
                    setPagination({ before: response.result.data.pagination.before, after: response.result.data.pagination.after, totalArticles: response.result.data.total_article });
                    toggleLoading(false);
                })
                .catch(err => console.log('[ERROR] - ', err));
        } else {
            const filterContacts = selectedContacts.filter(
                (item) => item !== id
            );
            setSelectedContacts([...filterContacts]);
            getAllFilteredPSTVScores(props.filterFlag.toLowerCase(), null, null, [...filterContacts])
                .then(response => {
                    console.log('[ALL CLIENTS RESULTS] - ', response.result.data.scores);
                    setAllClients(response.result.data.scores);
                    setPagination({ before: response.result.data.pagination.before, after: response.result.data.pagination.after, totalArticles: response.result.data.total_article });
                    toggleLoading(false);
                })
                .catch(err => console.log('[ERROR] - ', err));
            if (isEmpty(filterContacts)) {
                getAllFilteredPSTVScores(props.filterFlag.toLowerCase(), null, null)
                    .then(response => {
                        console.log('[ALL CLIENTS RESULTS] - ', response.result.data.scores);
                        setAllClients(response.result.data.scores);
                        setPagination({ before: response.result.data.pagination.before, after: response.result.data.pagination.after, totalArticles: response.result.data.total_article });
                        toggleLoading(false);
                    })
                    .catch(err => console.log('[ERROR] - ', err));
            }
        }
    }

    const allClientsFilterHandler = (flag, beforeId, afterId) => {
        toggleAllClientsFilter(false);
        toggleLoading(true);
        setSelectedFilter(flag);
        let contacts = !isEmpty(selectedContacts) && props.contactList.length === selectedContacts.length ? 'all' : !isEmpty(selectedContacts) ? selectedContacts : [];
        console.log('[CONTACTS] - ', selectedContacts);
        props.setFilterFlag(flag.toLowerCase());
        if (!beforeId && !afterId) {
            setCurrentPage(0);
        }
        getAllFilteredPSTVScores(flag.toLowerCase(), beforeId, afterId, contacts)
            .then(response => {
                console.log('[ALL CLIENTS RESULTS] - ', response.result.data.scores);
                setAllClients(response.result.data.scores);
                setPagination({ before: response.result.data.pagination.before, after: response.result.data.pagination.after, totalArticles: response.result.data.total_article });
                toggleLoading(false);
            })
            .catch(err => console.log('[ERROR] - ', err));
        // console.log('[ALL CLIENTS FLAG] - ', flag);
    }

    const viewMoreHandler = (beforeId, afterId) => {
        toggleLoading(true);
        setSelectedFilter(null);
        props.setFilterFlag('all');
        let contacts = !isEmpty(selectedContacts) && props.contactList.length === selectedContacts.length ? 'all' : !isEmpty(selectedContacts) ? selectedContacts : [];
        console.log('[CONTACTS] - ', selectedContacts);
        if (!beforeId && !afterId) {
            setCurrentPage(0);
            toggleAllClients(true);
        }
        else if (beforeId)
            setCurrentPage(currentPage - 1);
        else if (afterId)
            setCurrentPage(currentPage + 1);
        getAllPSTVScores(beforeId, afterId, contacts)
            .then(response => {
                console.log('[ALL CLIENTS RESULTS] - ', response.result.data.scores);
                setAllClients(response.result.data.scores);
                setPagination({ before: response.result.data.pagination.before, after: response.result.data.pagination.after, totalArticles: response.result.data.total_article });
                toggleLoading(false);
            })
            .catch(err => {
                toggleLoading(false);
                console.log('[ERROR] - ', err);
            })
    }

    const getScoreComponents = (result, isPage = false) => {
        let sortedScores = result && result.result && !isEmpty(result.result.attitude) ? result.result.attitude : [];
        let componentArray = [];
        let emailSentDate = new Date(result.last_email_sent);
        let lastEmailSent = getMonthDate(emailSentDate);
        if (isEmpty(sortedScores)) {
            componentArray.push(
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: '0.56rem' }} className="tooltip-div-name tooltip-name">
                    {
                        !isPage && <p style={{ color: '#acacac', marginLeft: '0px' }}>Sent {lastEmailSent[0]} {lastEmailSent[1]} {lastEmailSent[2]},</p>
                    }
                    {
                      isPage && <span className='tooltip-text-date tooltiptext-name'>
                          Sent {lastEmailSent[0]} {lastEmailSent[1]} {lastEmailSent[2]}
                          </span>
                    }
                    <p style={{ color: '#acacac', margin: '0px', marginLeft: '4px' }}>Pending,</p>
                    <strong style={{ color: '#6351ed', margin: '0px', marginLeft: '4px', cursor: 'pointer' }} onClick={() => sendInviteHandler([result])}>Resend</strong>
                </div>
            );
        } else {
            sortedScores.every(score => (
                componentArray.push(getScoreComponent(score))
            ))
        }

        return componentArray;
    }

    const getScoreComponent = key => {
        let insertChar;
        let styles = {}
        switch (key) {
            case 'p':
                insertChar = 'P';
                styles = { backgroundColor: '#C95CFC' };
                break;

            case 's':
                insertChar = 'S';
                styles = { backgroundColor: '#969198' };
                break;

            case 't':
                insertChar = 'T';
                styles = { backgroundColor: '#7DC5D0' };
                break;

            case 'v':
                insertChar = 'V';
                styles = { backgroundColor: '#6351ED' };
                break;

            default: break;
        }
        return (
            <div className='score-block' style={styles}>{insertChar}</div>
        );
    }

    const sendInviteHandler = contact => {
        toggleSendEmailModal(true);
        toggleLoading(true);
        console.log('CONTACT - ', contact);
        const form = {
            snetwork_act_id: props.snId
        }
        let contactsCsv = getEmailListCsv(contact);
        form.media1 = contactsCsv;
        console.log('[FORM]', form);
        postSendInvite(form)
            .then(response => {
                toggleLoading(false);
                setResendEmailResponse('Invite Sent successfully');
                console.log('[RESPONSE] - ', response);
                setTimeout(() => { toggleSendEmailModal(false) }, 2000);
            })
            .catch(err => {
                console.log('[ERROR] - ', err);
                toggleLoading(false);
                setResendEmailResponse('Unable to Send Invite, Try after sometime');
                setTimeout(() => { toggleSendEmailModal(false) }, 2000);
                // setError('Some error occured...please try again.');
            });
    }

    const getEmailListCsv = (values) => {
        const csvData = parse(values, {
            fields: ['first_name', 'last_name', 'email'],
        });
        const csvBlob = new Blob([csvData], { type: 'text/csv' });
        const csvFile = new File([csvBlob], 'email_list.csv', {
            type: 'text/csv',
            lastModified: new Date(),
        });
        return csvFile;
    };

    const getPaginatedResults = (beforeId, afterId) => {
        if (props.filterFlag != 'all')
            allClientsFilterHandler(props.filterFlag, beforeId, afterId);
        else
            viewMoreHandler(beforeId, afterId);
    }

    return (
        <div className='main-container-div'>
            <Modal
                onRequestClose={() => toggleAllClients(false)}
                isOpen={showAllClients}
                size="medium65"
                type='invite-clients'>
                <ModalHeader paddingBottom='0px'>
                    <strong>All Clients</strong>
                    <ModalCloseButton onClick={() => toggleAllClients(false)} />
                </ModalHeader>
                <ModalBody>
                    <LoaderWrapper isLoading={loading}>
                        <div style={{ maxHeight: '28rem', overflowY: 'auto', minHeight: '9rem' }}>
                            <table style={{ backgroundColor: '#e4e4e5', width: '97%', margin: '0px 14px', borderCollapse: 'collapse' }}>
                                <thead>
                                    <th style={{ width: '37%', textAlign: 'left', padding: '8px 10px', height: '34px', display: 'flex' }} >Email
                                        <FilterContacts
                                            isAdmin={false}
                                            handleClick={filterByContactList}
                                            contactGroup={props.contactList}
                                            selectedContacts={selectedContacts}
                                            height="10px"
                                            top='auto'
                                            left="11%"
                                            dropdownLeft="12px"
                                            dropdownRight="0px"
                                            padding='0 0 0 5px'
                                        />
                                    </th>
                                    <th style={{ width: '27%', padding: '7px 10px' }}>
                                        Score
                                        <img
                                            style={{ cursor: 'pointer', marginLeft: '5px', height: '10px' }}
                                            src='/assets/images/Filter.svg'
                                            alt='Filter'
                                            onMouseEnter={() => toggleAllClientsFilter(true)}
                                            onClick={() => toggleAllClientsFilter(!allClientsFilter)}
                                        />
                                    </th>
                                </thead>
                                <tbody style={{ backgroundColor: 'white' }}>
                                    {allClients.length > 0 ? (
                                        allClients.map(client => (
                                            <tr style={{ borderBottom: '1px solid #e4e4e5', height: '34px' }}>
                                                <td className='tooltip-div-name tooltip-name' style={{ border: 'none', textAlign: 'left', padding: '0 10px' }}>
                                                    {client.email}
                                                    <span className='tooltip-text-name tooltiptext-name'>{`${client.first_name.charAt(0).toUpperCase() + client.first_name.slice(1)} ${client.last_name.charAt(0).toUpperCase() + client.last_name.slice(1)}`}</span>
                                                </td>
                                                <td style={{ border: 'none', textAlign: 'left', padding: '5px 10px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>{getScoreComponents(client)}</td>
                                            </tr>
                                        ))
                                    ) : (
                                        null
                                    )}
                                </tbody>
                            </table>
                            {allClients.length === 0 ? (
                                <p>No Records Found</p>
                            ) : null}
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '20px', padding: '0 15px' }}>
                            <p style={{ color: 'black', fontSize: '0.6rem', marginRight: '2.3rem' }}>{parseInt(pagination.totalArticles) > 0 ? currentPage * 10 + 1 : 0}-{parseInt(pagination.totalArticles) > 0 ? (currentPage * 10 + 10 <= parseInt(pagination.totalArticles) ? currentPage * 10 + 10 : pagination.totalArticles) : 0} of {pagination.totalArticles ? pagination.totalArticles : 0}</p>
                            <div className='pagination-buttons-div'>
                                <button className='pagination-buttons' disabled={pagination.before === null} onClick={() => getPaginatedResults(pagination.before, null)}>Previous</button>
                                <button className='pagination-buttons' disabled={pagination.after === null} onClick={() => getPaginatedResults(null, pagination.after)}>Next</button>
                            </div>
                            {allClientsFilter ? (
                                <div className='filter-div' style={{ position: 'absolute', color: '#726f6f', backgroundColor: '#e6e6e6', border: '0px', borderRadius: '2px', width: '143px', height: '136px', right: '50px', top: '71px' }} onMouseLeave={() => toggleAllClientsFilter(false)}>
                                    {['All', 'Purpose', 'Touch', 'Security', 'Viewpoint'].map(flag => (
                                        <p className='filter-option' style={flag == 'Viewpoint' ? (flag.toLowerCase() == props.filterFlag ? { borderBottom: '0px', color: '#565353', backgroundColor: '#bebdc2' } : { borderBottom: '0px' }) : (flag.toLowerCase() == props.filterFlag ? { color: '#565353', backgroundColor: '#bebdc2' } : {})} onClick={() => allClientsFilterHandler(flag, null, null)} >{flag} Clients</p>
                                    ))}
                                </div>
                            ) : null}
                        </div>
                    </LoaderWrapper>
                </ModalBody>
            </Modal>
            <Modal
                onRequestClose={() => toggleSendEmailModal(false)}
                isOpen={sendEmailModal}
                size="small"
                type='invite-clients'>
                <ModalHeader paddingBottom='0px'>
                    <strong>Resend Email</strong>
                    <ModalCloseButton onClick={() => toggleSendEmailModal(false)} />
                </ModalHeader>
                <ModalBody>
                    <LoaderWrapper isLoading={loading}>
                        <p>{resendEmailResponse}</p>
                    </LoaderWrapper>
                </ModalBody>
            </Modal>
            <Flex mb="10px">
                <FontAwesomeIcon style={{ cursor: 'pointer' }} onClick={props.close} icon={faArrowLeft} />
            </Flex>
            <ul style={{ listStyle: 'none', paddingLeft: '4px', margin: 0 }}>
                <li className='flex-container' style={{ justifyContent: 'space-between', height: '14px', alignItems: 'flex-end', marginTop: '-5px', marginBottom: '2px' }}>
                    <div className='flex-container name-field' style={{ justifyContent: 'center', fontSize: '0.6rem' }}>
                        <p style={{ color: 'black' }}><strong>Client Results</strong></p>
                    </div>
                    <div className='score-field' style={{ position: 'relative' }}>
                        <img src='/assets/images/Expand.svg' alt='Filter' width='12px' style={{ cursor: 'pointer' }} onClick={() => viewMoreHandler(null, null)} />
                        {/* {showFilterOptions ? (
                            <div className='filter-div' style={{ position: 'absolute', color: '#726f6f', backgroundColor: '#e6e6e6', border: '0px', borderRadius: '2px', width: '143px', height: '136px', right: '0px', top: '19px' }}>
                                {['All', 'Purpose', 'Touch', 'Security', 'Viewpoint'].map(flag => (
                                    <p className='filter-option' style={flag == 'Viewpoint' ? (flag.toLowerCase() == props.filterFlag ? { borderBottom: '0px', color: '#565353', backgroundColor: '#bebdc2' } : { borderBottom: '0px' }) : (flag.toLowerCase() == props.filterFlag ? { color: '#565353', backgroundColor: '#bebdc2' } : {})} onClick={() => filterHandler(flag)} >View {flag} Clients</p>
                                ))}
                            </div>
                        ) : null} */}
                    </div>
                </li>
                {props.results.length > 0 ?
                    props.results.map(result => {
                        return (
                            <li className='flex-container' style={{ justifyContent: 'space-between', height: '23px', marginBottom: '5px' }}>
                                <div className='name-field' style={{ width: '40%', textAlign: 'left', paddingTop: '6px', marginLeft: '8px' }}>
                                    <p style={{ fontSize: '0.6rem', color: 'grey', marginTop: '0px' }}>{result.first_name} {result.last_name}</p>
                                </div>
                                <div className='flex-container score-field' style={{ paddingTop: '1px', justifyContent: 'flex-end' }}>
                                    {getScoreComponents(result, true)}
                                </div>
                            </li>
                        )
                    }) : (
                        <p style={{ fontSize: '11px' }}>No Records Found</p>
                    )}
            </ul>
        </div>
    );
}

export default ClientResults;