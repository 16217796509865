
import styled from 'styled-components';

export const OuterTableContainer = styled('div')`
  font-family: 'Poppins' !important;
  display: table;
  border: 1px solid #E4E7EC;
  border-radius: 4px;
  overflow: hidden;
  background: white;
  width: 100%;
  box-shadow: 0px 1px 2px 0px #1018281F;
`;

export const InnerTableContainer = styled('div')`
  font-family: 'Poppins' !important;
  width: 100%;
`;

export const TableHeader = styled('div')`
  width: 100%;
  margin: 0;
  border-bottom: 1px solid #E4E7EC;
  padding: 10px 0;
  display: flex;
  justify-content: space-between;
`;

export const TableTitle = styled('h4')`
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  color: #101828;
  width: 95%;
  text-align: left;
  margin: 15px auto 0px;
`;

export const SubTitle = styled('div')`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #475467;
  width: 95%;
  text-align: left;
  margin: 0px 15px 5px;
`;


export const EmptyStyle = styled('div')`
  min-height: 80px;
  width: 100%;
  margin: 40px 40px 40px 0;
  text-align: center;
  font-weight: 500;
`;

export const DefaultRow = styled('div')`
    font-size: 12;
    font-weight: 400;
    color: #101828; 
    margin: 10px 0;
`;

export const SearchContainer = styled('div')`
  font-family: 'Poppins' !important;
  display: flex;
  width: 40%;
  justify-content: start;
  margin: ${({ searchActive }) => searchActive ? 0 : '8px 0 0'};
`;
