import React from 'react';
import withDashboardLayout from '../../hoc/withDashboardLayout';
import { ROLE_TYPES } from '../../utils';
import {
  FreeUserDashboardContainer,
  AdminDashboardContainer,
  EnterpriseAdvisorDashboardContainer,
  AssetAdvisorDashboardContainer,
  PremiumAdvisorDashboardContainer,
} from '../../containers';

const DashboardLayout = (props) => {

  const { roleType } = props;

  // console.log('Dashboard Role Type:');
  // console.log(roleType);


  if (roleType === ROLE_TYPES.ADVISOR_ASSET_MANAGER) {
    return <AssetAdvisorDashboardContainer {...props} />;
  }

  if (roleType === ROLE_TYPES.ADVISOR_ENTERPRISE) {
    return <EnterpriseAdvisorDashboardContainer {...props} />;
  }


  if (roleType === ROLE_TYPES.ADVISOR_FREE) {
    return <FreeUserDashboardContainer {...props} />;
  }


  if ( [ROLE_TYPES.ADVISOR_PREMIUM].includes( roleType )) {
    // return <AdvisorDashboardContainer {...props} />;
    return <PremiumAdvisorDashboardContainer {...props} />;
  }

  if (
    [
      ROLE_TYPES.ADMIN_COMPLIANCE,
      ROLE_TYPES.ADMIN_ENTERPRISE,
      ROLE_TYPES.ADMIN_ASSET_MANAGER,
    ].includes(roleType)
  ) {
    return <AdminDashboardContainer {...props} setSelectedTabIndex />;
  }


  return null;
};


export default withDashboardLayout(DashboardLayout);
