import React from 'react';
import { Wrapper } from './styles';

function Button({
  id = '',
  type = 'button',
  width,
  height,
  children,
  variant = 'primary',
  fullWidth = false,
  className = '',
  onClick,
  disabled = false,
  style = {},
}) {
  return (
    <Wrapper
      id={id}
      type={type}
      variant={variant}
      width={width}
      height={height}
      fullWidth={fullWidth}
      className={className}
      onClick={onClick}
      disabled={disabled}
      style={style}
    >
      {children}
    </Wrapper>
  );
}

export default Button;
