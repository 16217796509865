import React, { useMemo } from 'react';
import { PieChart, Pie, Tooltip, Cell } from 'recharts';
import { EmptyState } from 'src/componentsV2';
import Skeleton from './Skeleton';
import useRESTQuery from 'src/hooks/useRESTQuery';
import { DONUT_COLORS } from '../constants';
import {
  Content,
  ContentWrapper,
  Footer,
  Header,
  TooltipContainer,
  TooltipName,
  TooltipValue,
  TopEngagement,
  TopEngagements,
  Wrapper,
} from './styles';

const CustomTooltip = ({ payload, active }) => {
  if (active && Array.isArray(payload) && payload.length) {
    const data = payload?.[0] ?? {};
    return (
      <TooltipContainer>
        <TooltipName> {data?.name ?? ''}:</TooltipName>
        <TooltipValue>{data?.payload?.percentage} %</TooltipValue>
      </TooltipContainer>
    );
  }

  return null;
};

function SourceEngagement({ isEnterprise, startDate, selectedUsers }) {
  const { data, isLoading, isFetching } = useRESTQuery({
    key: 'insights-content-source-engagement',
    endpoint: `/analytics/content/source_engagement?from_date=${startDate}&is_enterprise=${isEnterprise}${
      selectedUsers ? `&user_ids=${JSON.stringify(selectedUsers)}` : ''
    }`,
    dependencies: [isEnterprise, startDate, selectedUsers?.join(',')],
    options: {
      select: (res) => {
        if (Array.isArray(res?.result?.data) && res?.result?.data.length > 0) {
          const total = res?.result?.data?.reduce(
            (acc, val) => acc + (+val?.engagement_count ?? 0),
            0
          );

          const data = res?.result?.data?.reduce((acc, curr) => {
            const isCustomizedContent =
              curr?.source_name === 'Customized Content';
            const item = {
              name: isCustomizedContent ? 'Branded Content' : curr?.source_name,
              value: +curr?.engagement_count,
              percentage: +(
                (+curr?.engagement_count / (total ?? 1)) *
                100
              )?.toFixed(2),
            };

            return [...acc, item];
          }, []);

          return data;
        }

        return [];
      },
    },
  });

  const top3Sources = useMemo(() => {
    if (Array.isArray(data) && data?.length > 0) {
      const top3 = [...data]?.sort((a, b) => b?.value - a?.value)?.splice(0, 3);
      return top3 ?? [];
    }

    return [];
  }, [data]);

  const totalValue = useMemo(() => {
    if (Array.isArray(data) && data?.length > 0) {
      return data?.reduce((acc, val) => acc + (+val?.value ?? 0), 0);
    }
  }, [data]);

  if (isLoading || isFetching) return <Skeleton />;

  return (
    <Wrapper>
      <Header>
        <h4>Content Source Engagement</h4>
      </Header>
      {totalValue ? (
        <>
          <ContentWrapper>
            <Content>
              <PieChart width={200} height={200}>
                <Pie
                  dataKey="value"
                  data={data ?? []}
                  width={200}
                  height={200}
                  innerRadius={40}
                  outerRadius={80}
                  fill="#82ca9d"
                >
                  {data?.map((_, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={DONUT_COLORS[index % DONUT_COLORS.length]}
                    />
                  ))}
                </Pie>
                <Tooltip content={<CustomTooltip />} />
              </PieChart>
              <TopEngagements>
                {Array.isArray(top3Sources) &&
                  top3Sources?.map((item) => (
                    <TopEngagement key={item?.name}>
                      <h4>{item?.percentage}%</h4>
                      <p>{item?.name}</p>
                    </TopEngagement>
                  ))}
              </TopEngagements>
            </Content>
          </ContentWrapper>
          <Footer>
            <p>
              We recommend using more content from high performing sources to
              boost your engagement.
            </p>
          </Footer>
        </>
      ) : (
        <EmptyState
          height="250px"
          title="No Analytics Data"
          subTitle="Send out campaigns to get insights"
        />
      )}
    </Wrapper>
  );
}

export default SourceEngagement;
