import styled from 'styled-components';
import { Box } from '@rebass/grid';

export const MioEditorText = styled.div`
  color: #bbbbbb;
  font-size: 12px;
`;

export const StyledMioEditorBoxOne = styled(Box)`
  margin: 2em 0;
`;

export const StyledMioEditorBoxTwo = styled(Box)`
  margin: 10px 0;
`;

export const StyledMioEditorBoxThree = styled(Box)`
  margin: 10px 0;
`;

export const SuccessMessage = styled.h3`  
  font-size: 18px;
`;

export const ModalTitle = styled.h3`
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  margin: 2px 0;
  font-size: 20px;
`;

export const ModalSubTitle = styled.h3`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  margin: 2px 0;
`;

export const StyledMioEditorBoxFour = styled(Box)`
  margin: ${(props) => props.type === 'edit' ? '0.3em 0' : '0.5em 0'};
`;

export const ResponsiveMioEditorImg = styled.img`
  width: 109px;
`;