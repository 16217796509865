import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { get, isEmpty } from 'lodash';
import { Flex, Box } from '@rebass/grid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import {
  Tabs,
  TabList,
  TabTypeTwo,
  TabTypeThree,
  LoaderWrapper,
  Loader,
  StyledHeading,
} from '../../components';
import ExploreTab from './ExploreTab';
import TrendingTab from './TrendingTab';
import Personalized from './Personalized';
import Discover from './Discover';
import Popular from './Popular';
import FeaturedPosts from './FeaturedPosts';
import MyMioTab from './MyMioTab';
import RecentSharedTab from './RecentSharedTab';
import ScheduledTab from './ScheduledTab';
import SearchResultsTab from './SearchResultsTab';
import InvestmentsTab from './InvestmentsTab';
import AdvisorSearch from './Discover/AdvisorSearch';
import { useHistory } from 'react-router-dom';
import ApproveToShareTab from './ApproveToShareTab';
import AddContent from './AddContent';
import { getChannelId, getUserRole } from '../../redux/selector';

import {
  AddInvetmentsButton,
  StyledTooltip,
  HoldingsIcon,
  HoldingsWrapper,
  StyledTabPanelTwo,
  BorderWhiteBox,
} from './DashboardContainer.styles';

import { getPopularTheme, geYourTheme } from '../../services/dashboard';

import { actions as inviteActions } from '../../redux/modules/invite.module';
import { actions as exploreActions } from '../../redux/modules/explore-tab.module';
import { actions as advisorGroupsActions } from '../../redux/modules/advisor-group.module';
import { actions as contentlibActions } from '../../redux/modules/content-lib.module';
import { actions as themeActions } from '../../redux/modules/theme.module';
import { actions as shareActions } from '../../redux/modules/share.module';
import { company, emptyMessages } from '../../utils';

import {
  StyledTabPanel,
  NoContentWrapper,
  ActicleCount,
  AdminSubTab,
  AbsoluteLoader,
  ExploreRelativeDiv,
} from './DashboardContainer.styles';

const AdvisorDashboardContainer = ({
  exploreArticleRequest,
  exploreArticles,
  deleteArticleRequest,
  exploreSetState,
  themesList,
  channelData,
  exploreArticlesFetching,
  searchResults,
  investmentResults,
  themeNames,
  exploreArticleDeleting,
  isFreeUser,
  roleType,
  myMioData,
  tickerName,
  isTicker,
  exploreArticleError,
  selectedTickers,
  isInvEmpty,
  searchFilters,
  themeSetState,
  yourTopics,
  isAdmin,
  currentPortfolio,
  holdingsType,
  shareArticleSetState,
}) => {
  const [showInvestmentTab, setShowInvestmentTab] = useState(false);
  const [showSearchTab, setShowSearchTab] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [moreSearchParams, setMoreSearchParams] = useState({});
  const [selectedTabIndex, setTabIndex] = useState(0);
  const [filteredSearchEmpty, setFilteredSearchEmpty] = useState(false);
  const [trendingTopicDetails, setTrendingTopicDetails] = useState({});
  const [popularTopicDetails, setPopularTopicDetails] = useState({});
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [subscribedTopicDetails, setSubscribedTopicDetails] = useState({});
  const [investmentTopic, setInvestmentTopic] = useState('');
  const [selectedExploreTabIndex, setSelectedExploreTabIndex] = useState(0);
  const [selectedRecentTabIndex, setSelectedRecentTabIndex] = useState(0);
  const [selectedScheduledTabIndex, setSelectedScheduledTabIndex] = useState(0);
  const [isSearchFilters, setIsSearchFilters] = useState(true);
  const [mostSubscribed, setMostSubscribed] = useState([]);
  const [widgetTabIndes, setWdigetTabIndex] = useState(0);
  const [discoverTabIndex, setDiscoverTabIndex] = useState(0);
  const [isFilteringStocksByTicker, setIsFilteringStocksByTicker] =
    useState(false);
  const [popularTabIndex, setPopularTabIndex] = useState(0);

  const history = useHistory();

  const onDiscoverTabChange = (index) => {
    setTabIndex(0);
    if (index === 0) {
      setSelectedExploreTabIndex(2);
      // onExploreTabSelect(1);
    } else {
      setSelectedExploreTabIndex(3);
    }
    setDiscoverTabIndex(index);
    // setWdigetTabIndex(index);
  };

  const onWidgetTabChange = (index) => {
    console.log('onWidgetTabChange');
    if (index === 0) {
      setTabIndex(0);
      setSelectedExploreTabIndex(0);
    } else {
      setInvestmentTopic('');
      setSelectedExploreTabIndex(1);
    }
    setWdigetTabIndex(index);
  };
  const [showArticleLoader, setShowArticleLoader] = useState(true);
  useEffect(() => {
    exploreSetState({ holdingsType: 'themes' });
  }, []);
  useEffect(() => {
    if (selectedTabIndex === 0 && selectedExploreTabIndex === 0) {
      if (!isEmpty(popularTopicDetails)) {
        onExploreTabClick();
      }
    } else if (selectedTabIndex === 0 && selectedExploreTabIndex === 4) {
      onMyMioTabClick();
    } else if (selectedExploreTabIndex === 2 && selectedTabIndex === 0) {
      if (!isEmpty(trendingTopicDetails)) {
        onTrendingTabClick();
      }
    }
    setScreenWidth(window.innerWidth);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [popularTopicDetails, trendingTopicDetails]);

  useEffect(() => {
    if (!searchValue) {
      setShowArticleLoader(true);
      setTabIndex(0);
      setShowSearchTab(false);
      onExploreTabClick();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue]);

  useEffect(() => {
    // if(isAdmin){
    if (isAdmin !== 'admin') {
      getPopularTheme().then((response) => {
        let themeResponse = get(response, 'result.data', []);
        themeResponse =
          themeResponse &&
          themeResponse.length > 0 &&
          themeResponse.map(({ name }) => name && name);
        setMostSubscribed(themeResponse);
      });
    } else {
      geYourTheme().then((response) => {
        let yourThemeResponse = get(response, 'result.data', []).filter(
          (theme) =>
            get(theme, 'subscription.status.application_status') === 'approved'
        );
        yourThemeResponse = yourThemeResponse.map(({ name }) => name);
        setMostSubscribed(yourThemeResponse);
      });
    }
    // }
  }, [isAdmin]);

  const onSearchClick = (moreParams) => {
    console.log("more param")
    setShowArticleLoader(true);
    setShowSearchTab(true);
    setTabIndex(0);
    setSelectedExploreTabIndex(3);
    setMoreSearchParams(moreParams);
    exploreArticleRequest({
      params: {
        id: searchValue,
        source: 'search',
        ...moreParams,
      },
    });
  };

  const onSubscribeTabClick = (topic) => {
    setShowArticleLoader(true);
    setSelectedExploreTabIndex(0);
    exploreArticleRequest({
      params: {
        id: topic && topic.length > 0 ? topic : subscribedTopicDetails.id,
        source: 'explore',
      },
    });
  };

  const onExploreTabClick = (topic) => {
    setShowArticleLoader(true);
    if (selectedExploreTabIndex === 0) {
      exploreArticleRequest({
        params: {
          id: get(topic, 'id', popularTopicDetails.id),
          source: 'explore',
        },
      });
    } else if (selectedExploreTabIndex === 2) {
      onTrendingTabClick();
    } else if (selectedExploreTabIndex === 3) {
      onSearchTabClick();
    } else if (selectedExploreTabIndex === 4) {
      onMyMioTabClick();
    }
    // setSelectedExploreTabIndex(0);
  };

  const onTrendingTabClick = () => {
    console.log('I am here');
    setShowArticleLoader(true);
    exploreArticleRequest({
      params: {
        id: trendingTopicDetails.id,
        date: trendingTopicDetails.date,
        source: 'trending',
      },
    });
  };

  const onRecentTabClick = () => {
    setShowArticleLoader(true);
    setSelectedRecentTabIndex(0);
    exploreArticleRequest({
      params: {
        id: popularTopicDetails.id,
        source: 'recent',
      },
    });
  };

  const onRecentLastmonthTabClick = () => {
    setShowArticleLoader(true);
    exploreArticleRequest({
      params: {
        id: popularTopicDetails.id,
        source: 'recent',
        type: 'last_month',
      },
    });
  };

  const onScheduledTabClick = () => {
    setShowArticleLoader(true);
    setSelectedScheduledTabIndex(0);
    exploreArticleRequest({
      params: {
        id: popularTopicDetails.id,
        source: 'scheduled',
        type: 'recommendation_engine',
      },
    });
  };

  const onScheduledBySelfTabClick = () => {
    setShowArticleLoader(true);
    exploreArticleRequest({
      params: {
        id: popularTopicDetails.id,
        source: 'scheduled',
        type: 'user',
      },
    });
  };

  const onInvestmentClick = (id, holdingsType = '') => {
    // if (showSearchTab) {
    //   setTabIndex(4);
    // } else {
    //   setTabIndex(3);
    // }
    //currentPortfolio
    setShowArticleLoader(true);
    setIsFilteringStocksByTicker(false);
    exploreArticleRequest({
      params: {
        id,
        source: 'investments',
        holdingsType,
      },
    });
    console.log('ID:');
    console.log(id);
    setShowInvestmentTab(true);
    setSelectedExploreTabIndex(1);
    onWidgetTabChange(1);
  };

  const onMyMioTabClick = () => {
    setShowArticleLoader(true);
    exploreArticleRequest({
      params: {
        id: popularTopicDetails.id,
        source: 'mymio',
      },
    });
  };

  const onExploreTabSelect = (index) => {
    setShowArticleLoader(true);
    setSelectedExploreTabIndex(index);
    if (index === 0) {
      // onExploreTabClick();
      exploreArticleRequest({
        params: {
          id: popularTopicDetails.id,
          source: 'explore',
        },
      });
      onWidgetTabChange(0);
    } else if (index === 1) {
      setShowInvestmentTab(true);
      onWidgetTabChange(1);
    } else if (index === 2) {
      onTrendingTabClick();
      onDiscoverTabChange(0);
    } else if (index === 3) {
      onSearchTabClick();
      onDiscoverTabChange(1);
      // onInvestmentClick();

      // onInvestmentTabClick();
    } else if (index === 4) {
      onMyMioTabClick();
    }
  };

  const onSearchTabClick = () => {
    onSearchClick();
  };

  const onInvestmentTabClick = () => {
    console.log('WWWWWW');
    // setTabIndex(0);
    setShowInvestmentTab(true);
    onInvestmentClick();
  };

  const onRecentTabSelect = (index) => {
    setShowArticleLoader(true);
    setSelectedRecentTabIndex(index);
  };

  const onScheduledTabSelect = (index) => {
    setShowArticleLoader(true);
    setSelectedScheduledTabIndex(index);
  };

  const onDeleteArticleClick = (id) => {
    let params = {};
    setShowArticleLoader(true);
    if (selectedTabIndex === 0 && selectedExploreTabIndex === 0) {
      params = {
        deleteId: id,
        id: popularTopicDetails.id,
        source: 'explore',
      };
    } else if (selectedTabIndex === 0 && selectedExploreTabIndex === 2) {
      params = {
        deleteId: id,
        id: trendingTopicDetails.id,
        source: 'trending',
        date: trendingTopicDetails.date,
      };
    } else if (selectedTabIndex === 2 && selectedScheduledTabIndex === 0) {
      params = {
        deleteId: id,
        id: trendingTopicDetails.id,
        source: 'scheduled',
        date: trendingTopicDetails.date,
        type: 'recommendation_engine',
      };
    } else if (selectedTabIndex === 2 && selectedScheduledTabIndex === 1) {
      params = {
        deleteId: id,
        id: trendingTopicDetails.id,
        source: 'scheduled',
        date: trendingTopicDetails.date,
        type: 'user',
      };
    } else if (selectedTabIndex === 0 && selectedExploreTabIndex === 4) {
      params = {
        deleteId: id,
        id: trendingTopicDetails.id,
        source: 'mymio',
        date: trendingTopicDetails.date,
      };
    } else if (selectedTabIndex === 0 && selectedExploreTabIndex === 3) {
      params = {
        deleteId: id,
        id: searchValue,
        source: 'search',
        date: trendingTopicDetails.date,
        ...moreSearchParams,
      };
    } else if (selectedTabIndex === 0 && selectedExploreTabIndex === 1) {
      params = {
        deleteId: id,
        id: currentPortfolio,
        source: 'investments',
        date: trendingTopicDetails.date,
      };
    }

    deleteArticleRequest({ params });
  };

  const onTabSelect = (index) => {
    if (selectedTabIndex == index) {
      return false;
    }

    setShowArticleLoader(true);
    exploreSetState({ error: {}, isError: false });
    setTabIndex(index);
  };

  const handleTickerChange = (ticker) => {
    // exploreArticleRequest({
    //   params: {
    //     selectedTicker: ticker,
    //     source: 'investments',
    //   },
    // });
    setIsFilteringStocksByTicker(holdingsType === 'stocks' ? true : false);
    exploreSetState({ ticker }); //filtering from list based on selected tickerName
  };

  const handleTopicChange = (topic) => {
    setShowArticleLoader(true);
    setTabIndex(0);
    setInvestmentTopic(topic);
    onExploreTabClick(topic);
  };

  const exploreArticleFeed = get(exploreArticles, 'feed', []);
  const myMioFeed = get(myMioData, 'feed', []);
  const showTabLoader = !exploreArticleDeleting && exploreArticlesFetching;

  const investmentResultsFeed = get(investmentResults, 'feed', []);
  const searchResultsFeed = get(searchResults, 'feed', []);

  console.log('[ADVISORDASHBOARD] rendering...');

  return (
    <Box width={1}>
      <Flex
        flexDirection={`${screenWidth < 768 ? 'column' : 'row'}`}
        justifyContent="space-between"
        width={1}
        my="25px"
      >
        <Personalized
          exploreSetState={exploreSetState}
          themesList={themesList}
          setTabIndex={setTabIndex}
          setSelectedExploreTabIndex={setSelectedExploreTabIndex}
          setPopularTopicDetails={setPopularTopicDetails}
          setTrendingTopicDetails={setTrendingTopicDetails}
          trendingTopicDetails={trendingTopicDetails}
          onInvestmentClick={onInvestmentClick}
          tickerName={tickerName}
          tabIndex={selectedTabIndex}
          topicFromInvestment={investmentTopic}
          setInvestmentTopic={setInvestmentTopic}
          themeSetState={themeSetState}
          yourTopics={yourTopics}
          onWidgetTabChange={onWidgetTabChange}
          widgetTabIndes={widgetTabIndes}
          setWdigetTabIndex={setWdigetTabIndex}
          setShowArticleLoader={setShowArticleLoader}
          currentPortfolio={currentPortfolio}
          holdingsType={holdingsType}
        />
        <Discover
          setTabIndex={setTabIndex}
          setSelectedExploreTabIndex={setSelectedExploreTabIndex}
          setTrendingTopicDetails={setTrendingTopicDetails}
          onSearchClick={onSearchClick}
          setSearchValue={setSearchValue}
          themesList={themesList}
          channelData={channelData}
          exploreSetState={exploreSetState}
          setShowArticleLoader={setShowArticleLoader}
          discoverTabIndex={discoverTabIndex}
          onDiscoverTabChange={onDiscoverTabChange}
          onTrendingTabClick={onTrendingTabClick}
        />
        <Popular
          setTabIndex={() => setTabIndex(0)}
          onExploreTabSelect={onExploreTabSelect}
          setPopularTopicDetails={setSubscribedTopicDetails}
          setShowArticleLoader={setShowArticleLoader}
          onExploreTabClick={onSubscribeTabClick}
          setSelectedExploreTabIndex={setSelectedExploreTabIndex}
          popularTabIndex={popularTabIndex}
          setPopularTabIndex={setPopularTabIndex}
        />
        <FeaturedPosts shareArticleSetState={shareArticleSetState} />
      </Flex>
      <Flex width={1}>
        <Tabs
          onSelect={onTabSelect}
          selectedIndex={selectedTabIndex}
          forceRenderTabPanel
        >
          <BorderWhiteBox type="admin-tab" padding="5px 5px">
            <TabList>
              <TabTypeThree onClick={onExploreTabClick}>Explore</TabTypeThree>
              <TabTypeThree onClick={onRecentTabClick}>Recent</TabTypeThree>
              <TabTypeThree onClick={onScheduledTabClick}>
                Scheduled
              </TabTypeThree>
              <TabTypeThree>Add Content</TabTypeThree>
              {/* {showSearchTab && <TabTypeTwo>Search</TabTypeTwo>} */}
              {/* {showInvestmentTab && <TabTypeTwo  title='Investment Holdings'>Holdings</TabTypeTwo>} */}
            </TabList>

            {selectedTabIndex === 0 && (
              <StyledTabPanelTwo>
                <Tabs onSelect={onExploreTabSelect} forceRenderTabPanel>
                  <TabList>
                    <AdminSubTab
                      color="#49484a"
                      className={`${
                        selectedExploreTabIndex === 0 ? 'active' : 'inactive'
                      }`}
                    >
                      Topics
                    </AdminSubTab>
                    <AdminSubTab
                      color="#49484a"
                      className={`${
                        selectedExploreTabIndex === 1 ? 'active' : 'inactive'
                      }`}
                    >
                      Holdings{' '}
                      {holdingsType && (
                        <HoldingsWrapper className="tooltip">
                          <HoldingsIcon
                            style={{
                              ...(holdingsType === 'stocks' && {
                                width: '9px',
                              }),
                            }}
                            src={`/assets/images/${holdingsType}.png`}
                          />
                          <StyledTooltip
                            right={holdingsType === 'stocks' ? '38%' : '40%'}
                            className="tooltiptext"
                            width={holdingsType === 'stocks' ? '80px' : null}
                          >
                            {holdingsType === 'stocks'
                              ? 'STOCK SPECIFIC'
                              : 'THEMATIC'}
                          </StyledTooltip>
                        </HoldingsWrapper>
                      )}
                    </AdminSubTab>
                    <AdminSubTab
                      color="#49484a"
                      className={`${
                        selectedExploreTabIndex === 2 ? 'active' : 'inactive'
                      }`}
                    >
                      Trending
                    </AdminSubTab>
                    <AdminSubTab
                      color="#49484a"
                      className={`${
                        selectedExploreTabIndex === 3 ? 'active' : 'inactive'
                      }`}
                    >
                      Search
                    </AdminSubTab>
                    <AdminSubTab
                      color="#49484a"
                      className={`${
                        selectedExploreTabIndex === 4 ? 'active' : 'inactive'
                      }`}
                    >
                      my{company.name}{' '}
                      <span>
                        {myMioFeed.length || (
                          <FontAwesomeIcon icon={faExclamationCircle} />
                        )}
                      </span>
                    </AdminSubTab>
                  </TabList>
                  {selectedExploreTabIndex === 0 && (
                    <StyledTabPanel
                      type="admin"
                      className="react-tabs__tab-panel--selected"
                      padding="5px 5px"
                    >
                      {!isFreeUser &&
                        !exploreArticlesFetching &&
                        isEmpty(exploreArticleFeed) && (
                          <NoContentWrapper data-testid="no-content-message">
                            {emptyMessages.default}
                          </NoContentWrapper>
                        )}
                      {!isEmpty(exploreArticleFeed) && showArticleLoader && (
                        <ExploreRelativeDiv>
                          <AbsoluteLoader>
                            <Loader height="25px" />
                          </AbsoluteLoader>
                        </ExploreRelativeDiv>
                      )}

                      {!showTabLoader && (
                        <ExploreTab
                          exploreArticleDeleting={exploreArticleDeleting}
                          selectedTopic={popularTopicDetails.topicName}
                          exploreArticles={exploreArticles}
                          deleteArticleRequest={onDeleteArticleClick}
                          themeNames={themeNames}
                          isFreeUser={isFreeUser}
                          showTabLoader={showTabLoader}
                          setShowArticleLoader={setShowArticleLoader}
                        />
                      )}
                    </StyledTabPanel>
                  )}
                  {selectedExploreTabIndex === 2 && (
                    <StyledTabPanel
                      type="admin"
                      className="react-tabs__tab-panel--selected"
                      padding="5px 5px"
                    >
                      {!isFreeUser &&
                        !exploreArticlesFetching &&
                        isEmpty(exploreArticleFeed) && (
                          <NoContentWrapper data-testid="no-content-message">
                            {emptyMessages.default}
                          </NoContentWrapper>
                        )}
                      {showArticleLoader && (
                        <ExploreRelativeDiv>
                          <AbsoluteLoader>
                            <Loader height="25px" />
                          </AbsoluteLoader>
                        </ExploreRelativeDiv>
                      )}
                      {!showTabLoader && (
                        <TrendingTab
                          exploreArticleDeleting={exploreArticleDeleting}
                          selectedTopic={trendingTopicDetails.topicName}
                          exploreArticles={exploreArticles}
                          deleteArticleRequest={onDeleteArticleClick}
                          themeNames={themeNames}
                          isFreeUser={isFreeUser}
                          setShowArticleLoader={setShowArticleLoader}
                        />
                      )}
                    </StyledTabPanel>
                  )}
                  {selectedExploreTabIndex === 4 && (
                    <StyledTabPanel
                      type="admin"
                      className="react-tabs__tab-panel--selected"
                      padding="5px 5px"
                    >
                      {!exploreArticlesFetching &&
                        isEmpty(exploreArticleFeed) && (
                          <NoContentWrapper>
                            {emptyMessages.myMioTab}
                          </NoContentWrapper>
                        )}
                      {exploreArticlesFetching && showArticleLoader && (
                        <ExploreRelativeDiv>
                          <AbsoluteLoader>
                            <Loader height="25px" />
                          </AbsoluteLoader>
                        </ExploreRelativeDiv>
                      )}
                      <MyMioTab
                        exploreArticleDeleting={exploreArticleDeleting}
                        selectedTopic={popularTopicDetails.topicName}
                        exploreArticles={exploreArticles}
                        deleteArticleRequest={onDeleteArticleClick}
                        themeNames={themeNames}
                        hideDelete
                        setShowArticleLoader={setShowArticleLoader}
                      />
                    </StyledTabPanel>
                  )}
                  {/***********************************************************/}
                  {selectedExploreTabIndex === 1 && showInvestmentTab && (
                    <StyledTabPanel
                      type="admin"
                      className="react-tabs__tab-panel--selected"
                    >
                      {exploreArticlesFetching && (
                        <ExploreRelativeDiv>
                          <AbsoluteLoader>
                            <Loader height="25px" />
                          </AbsoluteLoader>
                        </ExploreRelativeDiv>
                      )}
                      {!exploreArticlesFetching &&
                        (isInvEmpty ||
                          isEmpty(get(investmentResults, 'feed'))) && (
                          <NoContentWrapper>
                            <Flex
                              width={1}
                              justifyContent="center"
                              alignItems="center"
                              data-testid="no-content-message"
                            >
                              No articles found
                              {/* <Box>
                              <AddInvetmentsButton
                                src="/assets/images/briefcase.png"
                                alt="add invetments"
                                onClick={() => history.push('/portfolio')}
                              />
                              <StyledHeading onClick={() => history.push('/portfolio')}>
                                Add Your Investments
                              </StyledHeading>
                            </Box> */}
                            </Flex>
                          </NoContentWrapper>
                        )}

                      {!exploreArticleError ? (
                        <LoaderWrapper isLoading={showTabLoader}>
                          {selectedTickers && selectedTickers.length > 0 && (
                            <InvestmentsTab
                              exploreArticleDeleting={exploreArticleDeleting}
                              selectedTopic={trendingTopicDetails.topicName}
                              exploreArticles={investmentResults}
                              deleteArticleRequest={onDeleteArticleClick}
                              themeNames={themeNames}
                              handleTickerChange={handleTickerChange}
                              handleTopicChange={handleTopicChange}
                              isTicker={isTicker}
                              tickerName={tickerName}
                              selectedTickers={selectedTickers}
                              exploreSetState={exploreSetState}
                              setShowArticleLoader={setShowArticleLoader}
                              holdingsType={holdingsType}
                              isFilteringStocksByTicker={
                                isFilteringStocksByTicker
                              }
                            />
                          )}
                        </LoaderWrapper>
                      ) : null}
                    </StyledTabPanel>
                  )}
                  {/***********************************************************/}
                  {selectedExploreTabIndex === 3 && (
                    <StyledTabPanel
                      type="admin"
                      className="react-tabs__tab-panel--selected"
                    >
                      {!isFreeUser &&
                        !exploreArticlesFetching &&
                        (isEmpty(searchResultsFeed) ||
                          filteredSearchEmpty ||
                          !isSearchFilters) && (
                          <NoContentWrapper>
                            {searchValue &&
                            searchResults &&
                            searchResults.feed &&
                            searchResults.feed.length > 0 &&
                            searchFilters &&
                            searchFilters.length > 0 ? (
                              <Flex
                                width={1}
                                justifyContent="center"
                                alignItems="center"
                                data-testid="no-content-message"
                              >
                                No articles found
                              </Flex>
                            ) : (
                              <div style={{ width: '50%', margin: 'auto' }}>
                                <AdvisorSearch
                                  onSearchClick={onSearchClick}
                                  setSearchValue={setSearchValue}
                                  setTabIndex={setTabIndex}
                                  themesList={themesList}
                                  channelData={channelData}
                                  exploreSetState={exploreSetState}
                                />
                              </div>
                            )}
                          </NoContentWrapper>
                        )}

                      <LoaderWrapper isLoading={showTabLoader}>
                        <SearchResultsTab
                          exploreArticleDeleting={exploreArticleDeleting}
                          selectedTopic={trendingTopicDetails.topicName}
                          exploreArticles={searchResults}
                          deleteArticleRequest={onDeleteArticleClick}
                          themeNames={themeNames}
                          isFreeUser={isFreeUser}
                          searchFilters={searchFilters}
                          exploreSetState={exploreSetState}
                          setIsSearchFilters={setIsSearchFilters}
                          mostSubscribed={mostSubscribed}
                          yourTopics={yourTopics}
                          setShowArticleLoader={setShowArticleLoader}
                          setFilteredSearchEmpty={setFilteredSearchEmpty}
                        />
                      </LoaderWrapper>
                    </StyledTabPanel>
                  )}
                </Tabs>
              </StyledTabPanelTwo>
            )}
            {selectedTabIndex === 1 && (
              <StyledTabPanelTwo>
                <Tabs onSelect={onRecentTabSelect} forceRenderTabPanel>
                  <TabList>
                    <AdminSubTab
                      className={`${
                        selectedRecentTabIndex === 0 ? 'active' : 'inactive'
                      }`}
                      onClick={onRecentTabClick}
                    >
                      All posts
                    </AdminSubTab>
                    <AdminSubTab
                      className={`${
                        selectedRecentTabIndex === 1 ? 'active' : 'inactive'
                      }`}
                      onClick={onRecentLastmonthTabClick}
                    >
                      This Month
                    </AdminSubTab>
                  </TabList>
                  {selectedRecentTabIndex === 0 && (
                    <StyledTabPanel
                      type="admin"
                      className="react-tabs__tab-panel--selected"
                      padding="5px 5px"
                    >
                      {!exploreArticlesFetching &&
                        isEmpty(exploreArticleFeed) && (
                          <NoContentWrapper>
                            {emptyMessages.recentTab}
                          </NoContentWrapper>
                        )}
                      {exploreArticlesFetching && showArticleLoader && (
                        <ExploreRelativeDiv>
                          <AbsoluteLoader>
                            <Loader height="25px" />
                          </AbsoluteLoader>
                        </ExploreRelativeDiv>
                      )}
                      {!showTabLoader && (
                        <RecentSharedTab
                          selectedTopic={popularTopicDetails.topicName}
                          exploreArticles={exploreArticles}
                          deleteArticleRequest={onDeleteArticleClick}
                          themeNames={themeNames}
                          roleType={roleType}
                          setShowArticleLoader={setShowArticleLoader}
                        />
                      )}
                    </StyledTabPanel>
                  )}
                  {selectedRecentTabIndex === 1 && (
                    <StyledTabPanel
                      type="admin"
                      className="react-tabs__tab-panel--selected"
                      padding="5px 5px"
                    >
                      {!exploreArticlesFetching &&
                        isEmpty(exploreArticleFeed) && (
                          <NoContentWrapper>
                            {emptyMessages.recentTab}
                          </NoContentWrapper>
                        )}
                      {exploreArticlesFetching && showArticleLoader && (
                        <ExploreRelativeDiv>
                          <AbsoluteLoader>
                            <Loader height="25px" />
                          </AbsoluteLoader>
                        </ExploreRelativeDiv>
                      )}
                      {!showTabLoader && (
                        <RecentSharedTab
                          selectedTopic={popularTopicDetails.topicName}
                          exploreArticles={exploreArticles}
                          deleteArticleRequest={onDeleteArticleClick}
                          themeNames={themeNames}
                          roleType={roleType}
                          setShowArticleLoader={setShowArticleLoader}
                        />
                      )}
                    </StyledTabPanel>
                  )}
                </Tabs>
              </StyledTabPanelTwo>
            )}
            {selectedTabIndex === 2 && (
              <StyledTabPanelTwo>
                <Tabs onSelect={onScheduledTabSelect} forceRenderTabPanel>
                  <TabList>
                    <AdminSubTab
                      className={`${
                        selectedScheduledTabIndex === 0 ? 'active' : 'inactive'
                      }`}
                      onClick={onScheduledBySelfTabClick}
                      data-testid="my_scheduled_content"
                    >
                      My Scheduled Content
                    </AdminSubTab>
                    <AdminSubTab
                      className={`${
                        selectedScheduledTabIndex === 1 ? 'active' : 'inactive'
                      }`}
                      onClick={onScheduledTabClick}
                      data-testid="recommended_by_company"
                    >
                      Recommended by {company.name}
                    </AdminSubTab>
                  </TabList>
                  {selectedScheduledTabIndex === 1 && (
                    <StyledTabPanel
                      type="admin"
                      className="react-tabs__tab-panel--selected"
                      padding="5px 5px"
                    >
                      {!exploreArticlesFetching &&
                        isEmpty(exploreArticleFeed) && (
                          <NoContentWrapper>
                            {emptyMessages.scheduledByMioTab}
                          </NoContentWrapper>
                        )}
                      {exploreArticlesFetching && showArticleLoader && (
                        <ExploreRelativeDiv>
                          <AbsoluteLoader>
                            <Loader height="25px" />
                          </AbsoluteLoader>
                        </ExploreRelativeDiv>
                      )}
                      {!showTabLoader && (
                        <ScheduledTab
                          exploreArticleDeleting={exploreArticleDeleting}
                          selectedTopic={popularTopicDetails.topicName}
                          exploreArticles={exploreArticles}
                          deleteArticleRequest={onDeleteArticleClick}
                          themeNames={themeNames}
                          exploreArticleRequest={exploreArticleRequest}
                          setShowArticleLoader={setShowArticleLoader}
                        />
                      )}
                    </StyledTabPanel>
                  )}
                  {selectedScheduledTabIndex === 0 && (
                    <StyledTabPanel
                      type="admin"
                      className="react-tabs__tab-panel--selected"
                      padding="5px 5px"
                    >
                      {!exploreArticlesFetching &&
                        isEmpty(exploreArticleFeed) && (
                          <NoContentWrapper>
                            {emptyMessages.scheduledTab}
                          </NoContentWrapper>
                        )}
                      {exploreArticlesFetching && showArticleLoader && (
                        <ExploreRelativeDiv>
                          <AbsoluteLoader>
                            <Loader height="25px" />
                          </AbsoluteLoader>
                        </ExploreRelativeDiv>
                      )}
                      {!showTabLoader && (
                        <ScheduledTab
                          exploreArticleDeleting={exploreArticleDeleting}
                          selectedTopic={popularTopicDetails.topicName}
                          exploreArticles={exploreArticles}
                          deleteArticleRequest={onDeleteArticleClick}
                          themeNames={themeNames}
                          exploreArticleRequest={exploreArticleRequest}
                          setShowArticleLoader={setShowArticleLoader}
                        />
                      )}
                    </StyledTabPanel>
                  )}
                </Tabs>
              </StyledTabPanelTwo>
            )}
          </BorderWhiteBox>
        </Tabs>
      </Flex>
    </Box>
  );
};

const mapStateToProps = (state) => ({
  groupsList: state.advisorGroups.data,
  exploreArticles: state.explore.data,
  contentLibArticles: state.contentlib.data,
  themesList: state.theme.data || [],
  channelData: get(state, 'channel.data', []),
  exploreArticlesFetching: state.explore.isFetching,
  searchResults: get(state, 'explore.search', {}),
  investmentResults: get(state, 'explore.investments', {}),
  themeNames: get(state, 'theme.themeNames', {}),
  exploreArticleDeleting: state.explore.isDeleting,
  exploreArticleError: state.explore.isError,
  myMioData: get(state, 'explore.mymio', {}),
  tickerName: state.explore.ticker,
  isTicker: state.explore.isTicker,
  selectedTickers: state.explore.selectedTickers,
  isInvEmpty: state.explore.isInvEmpty,
  searchFilters: state.explore.searchFilters,
  yourTopics: state.theme.yourTopics,
  isAdmin: state.user.data.details.user.role,
  currentPortfolio: state.explore.currentPortfolio,
  holdingsType: state.explore.holdingsType,
});

const mapDispatchToProps = {
  exploreArticleRequest: exploreActions.api.explore.request,
  advisorGroupsRequest: advisorGroupsActions.api.groups.request,
  deleteArticleRequest: exploreActions.api.explore.delete,
  exploreSetState: exploreActions.api.explore.setstate,
  inviteRequest: inviteActions.api.invite.request,
  contentlibRequest: contentlibActions.api.contentlib.request,
  contentlibDeleteRequest: contentlibActions.api.contentlib.delete,
  themeSetState: themeActions.api.theme.setstate,
  contentlibSetState: contentlibActions.api.contentlib.setstate,
  shareArticleSetState: shareActions.api.share.setstate,
};

AdvisorDashboardContainer.defaultProps = {
  groupsList: [],
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdvisorDashboardContainer);
