import styled from 'styled-components';

export const StripeComponentWrapper = styled.div`
  .StripeElement {
    padding: 12px;
    border: 1px solid #c1c1c1;
    height: 44px;
    width: 100%;
    border-radius: 5px;
    color: #32325d;

    input,
    .ElementsApp,
    .ElementsApp .InputElement {
      color: #32325d;
      font-size: 16px;
    }
  }
`;
