import React, { useEffect, useState } from 'react';
import { Flex } from '@rebass/grid';
import { ModalV2, ModalBody, ModalHeader, ModalCloseButton, Input, Button, FileUpload, LoaderWrapper } from '../../../components';
import { cloneDeep, get } from 'lodash-es';
import { postUpdateContent } from '../../../services/dashboard';
import { toBase64 } from '../../../utils';

const EditContentModal = ({ articlesList, updateArticlesList, index, isOpen, closeModal }) => {
    
    const [article, updateArticle] = useState({});
    const [userImage, setUserImage] = useState('');
    const [loading, toggleLoading] = useState(false);
    const [error, setError] = useState('');

    useEffect(() => {
        const clickedArticle = cloneDeep(articlesList[index]);
        updateArticle(clickedArticle);
    }, []);

    useEffect(() => {
        if(userImage)
            updateArticle({...article, url: userImage});
    }, [userImage]);

    useEffect(() => {
        if(article && article.topic)
            setError('');
        else
            setError('Title cannot be empty!');
    }, [article]);

    const saveHandler = () => {
        setError('');
        toggleLoading(true);
        const articlesListCopy = [...articlesList];
        articlesListCopy[index] = article;
        const payload = {
            contentId: article.id.split('_')[2],
            title: article.topic,
            message: article.description,
            fromEditModal: true
            // themes: article.themeIds,
            // internalUse: article.auxiliary.internal_content === 'true',
            // autoshare_on_subscribers_snetworks_datetime: get(article, 'auxiliary.autoshare_on_subscribers_snetworks_datetime', ''),
            // autoshare_on_subscribers_snetworks: get(article, 'auxiliary.autoshare_on_subscribers_snetworks', ''),
        }
        if(userImage) 
            payload.media1 = userImage;
        postUpdateContent(payload)
        .then(response => {
            toggleLoading(false);
            closeModal();
        })
        .catch(err => {
            console.log('POST UPDATE API ERROR');
            toggleLoading(false);
            setError('There was an error saving content!');
        });
        updateArticlesList(articlesListCopy);
    }

    const editImageHandler = thumbnailImage => {
        // article.url = 
        const fileSize = thumbnailImage[0].size / 1024 / 1024;
        if(fileSize > 10) {
            console.log('ERROR- - - - ');
            // return setError('File size should be less than or equal to 10 MB');
        }
        toBase64(thumbnailImage[0])
        .then(file => {
            setUserImage(file);
            setError('');
		})
        .catch(err => console.log('ERROR TO BASE 64 - - -', err));
    }

    const errorHandler = err => {
        console.log('ERROR - - - - - - - -', err);
        setError(err);
    }
    

    return (
        <ModalV2 
            style={{textAlign: 'left', backgroundColor: '#fff'}} 
            width='500px' 
            isOpen={isOpen} 
            onRequestClose={closeModal}
            bgColor='#fff'
        >
            <ModalHeader>
                Edit Content
                <ModalCloseButton onClick={closeModal} />
            </ModalHeader>
            <ModalBody>
                <div style={{width: '100%', textAlign: 'left', marginBottom: '10px'}}>
                    <label htmlFor='title-input' style={{textAlign: 'left', color: '#6351ed'}}>Title</label>
                    <Input 
                        id='title-input' 
                        value={article.topic || ''} 
                        largeInput={true}
                        bgColor='#fff'
                        borderRadius='4px'
                        border='1px solid #49484A'
                        color='#49484A'
                        onChange={e => updateArticle({...article, topic: e.target.value})}
                    />
                </div>
                <div style={{width: '100%', textAlign: 'left', marginBottom: '10px'}}>
                    <label htmlFor='summary-input' style={{color: '#6351ed'}}>Summary</label>
                    <br/>
                    <textarea 
                        id='summary-input' 
                        value={article.description || ''} 
                        onChange={e => updateArticle({...article, description: e.target.value})}
                        style={{
                            width: '100%', 
                            height: '80px', 
                            resize: 'none', 
                            borderRadius: '4px',
                            color: '#49484A'
                        }}
                    />
                </div>
                <p style={{fontSize: '10px', textAlign: 'left', color: '#7b7b7b'}}>
                    Summary can be of any length, but the maximum number of characters that will be displayed on the article card is 120.
                </p>
                <Flex width='47%' justifyContent='space-between' alignItems='center'>
                    <div style={{height: '50px', width: '100px', border: !article.url ? '1px solid #49484a': '0px'}}>
                        {article.url ? (
                            <img 
                                style={{height: '50px', width: '101px', border: '1px solid #49484a'}} 
                                src={article.url || ''} 
                                alt='article-image' 
                            />
                        ) : (
                            <p style={{color: '#49484a', fontSize: '9px', margin: '17px auto'}}>No Image Selected</p>
                        )}
                    </div>
                    {/* <a href='#' onClick={}>Change Image</a> */}
                    <FileUpload
                        id="edit-image"
                        name="edit-image"
                        type="button"
                        onClick={() => {}}
                        onChange={file => editImageHandler(file)}
                        maxSizeMB={10}
                        asLabel={true}
                        label="Change Image"
                        color='#6351ed'
                        borderRadius='0px'
                        border='none'
                        padding='7px 28px'
                        styles={{
                            textDecoration: 'underline', 
                            background: 'transparent', 
                            fontSize: '12px'
                        }}
                        wrapperStyles={{ display: 'flex', justifyContent: 'center' }}
                        accept=".png,.jpeg,.jpg,.svg,.bmp"
                        onError={err => errorHandler(err)}
                    />
                </Flex>
                {error && (
                    <div style={{fontSize: '10px', color: '#6351ed', marginTop: '10px'}}>
                        {error}
                    </div>
                )}
                <LoaderWrapper isLoading={loading}>
                    <Flex width='34%' justifyContent='space-between' margin='auto'>
                        <Button 
                            padding='5px' 
                            width='70px' 
                            onClick={saveHandler}
                            disabled={!article || !article.topic}
                        >
                            Save
                        </Button>
                        <Button 
                            padding='5px' 
                            width='70px' 
                            bgColor='transparent' 
                            color='#6351ed' 
                            onClick={closeModal}
                        > 
                            Cancel
                        </Button>
                    </Flex>
                </LoaderWrapper>
            </ModalBody>
        </ModalV2>
    );
};

export default EditContentModal;