import React from 'react';
import { Pagination as MuiPagination, PaginationItem } from '@mui/material';
import PreviousArrow from '@mui/icons-material/ArrowBackOutlined';
import NextArrow from '@mui/icons-material/ArrowForwardOutlined';
import { PaginationWrapper } from './styles';

function Pagination({ pageNumber, handlePageChange, pageSize }) {
  return (
    <PaginationWrapper>
      <MuiPagination
        count={pageSize ?? 1}
        renderItem={(item) => (
          <PaginationItem
            components={{
              previous: PreviousArrow,
              next: NextArrow,
            }}
            {...item}
          />
        )}
        page={pageNumber}
        onChange={handlePageChange}
      />
    </PaginationWrapper>
  );
}

export default Pagination;
