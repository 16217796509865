/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Flex } from '@rebass/grid';
import React, { useEffect } from 'react';
import { Line, WhiteCircle } from '../../../components/StepperV2/Stepper.styles';
import { AUTOMATED_CAMPAIGN_ACTIONS } from '../AutomatedCampaignsContainer';
import { TimeInput } from './CampaignCreateSteps.styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { MioDatePickerWrapper } from '../../../components/MioDatePicker/MioDatePicker.styles';
import { get } from 'lodash';
import { MioDatePicker } from '../../../components';
import styled from 'styled-components';
import moment from 'moment';

const DateTimeWrapper = styled(Flex)`
  background-color: hsl(0, 0%, 100%);
  border-color: hsl(0, 0%, 80%);
  border-radius: 4px;
  margin: 0 auto;
  padding: 2px 8px;
`;

const DateTimeText = styled.div`
  width: 85%;
  font-size: 0.9rem;
  display: flex;
  align-items: center;
  justify-content: center;
  &:empty:before {
    content:attr(data-placeholder);
    color:gray
  }
`;

const StepTwoHeadSection = ({ campaignAction, userData, eventDate, handleStartDate, automatedCampaignTargetInfo }) => {
  const [isDatepickerOpen, setIsDatepickerOpen] = useState(false);
  const [startDate, setStartDate] = useState(null);

  let maxEventDate;
  if (eventDate) {
    maxEventDate = new Date(eventDate);
    maxEventDate.setDate(maxEventDate.getDate() - 1);
  }

  useEffect(() => {
    console.log(automatedCampaignTargetInfo);
    setStartDate(automatedCampaignTargetInfo?.start_date)
  }, []);

  const convertDateToRequiredFormat = ({ date, format = 'YYYY-MM-DD' }) =>
    moment(date).format(format);

  return (
    <Flex width="100%" alignItems="center" style={{ position: 'relative' }}>
      <Flex width="5%" flexDirection="column">
        <WhiteCircle background="#E4E7EC" height="16px" width="16px" style={{ border: 'none'}} />
        <Line style={{ left: '6px', top: '20px' }} />
      </Flex>
      <Flex width="53.5%" flexDirection="column" paddingLeft="15px">
        <Flex
          width="95%"
          color="#101828"
          fontSize="16px"
          style={{ fontWeight: 500 }}
        >
          Contact Enters Campaign
        </Flex>
        <Flex
          width={(campaignAction === AUTOMATED_CAMPAIGN_ACTIONS.NURTURE_PROSPECTS ||
            campaignAction === AUTOMATED_CAMPAIGN_ACTIONS.WELCOME_CLIENTS) ? "90%" : (campaignAction === AUTOMATED_CAMPAIGN_ACTIONS.EVENT) ? "85%" : null}
          fontSize="14px"
          color="#667085"
          style={{ textAlign: 'initial' }}
        >
          This campaign will be sent to the selected list or segment. Any time a
          new contact is added to the particular list or segment they will start
          this automated campaign
        </Flex>
      </Flex>
      {(campaignAction === AUTOMATED_CAMPAIGN_ACTIONS.NURTURE_PROSPECTS ||
        campaignAction === AUTOMATED_CAMPAIGN_ACTIONS.WELCOME_CLIENTS) && (
        <Flex width="50%">
          <Flex width="5%" alignItems="center">
            <Line border="1px solid #E4E7EC" style={{ top: '2px' }} />
          </Flex>
          <Box width={'45%'}>
            {/* <Box width={'49%'}> */}
            <Box className="field__title" color="#101828" fontSize="14px" alignItems="center">
              <Box display="flex">Set Activation Date</Box>
              <Box
                width="100%"
                mt="8px"
                style={{
                  border: '1px solid #E4E7EC', 
                  boxShadow: '1px 2px 2px 0px rgba(16, 24, 40, 0.12) inset',
                  borderRadius: '4px',
                  height: '35px'
                }}
              >
                <DateTimeWrapper style={{ padding: '2px 0px', height: '32px' }}>
                  <DateTimeText style={{ width: '86%', display: 'flex', justifyContent: 'flex-start', paddingLeft: '8px',}} contenteditable="true" data-placeholder="MM/DD/YYYY">
                    {startDate &&
                      convertDateToRequiredFormat({
                        date: startDate,
                        format: 'MM/DD/YYYY',
                      })}
                  </DateTimeText>
                  <img
                    alt="date"
                    src="/assets/images/Campaign/date&time-picker-icon.svg"
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      console.log('clicked');
                      setIsDatepickerOpen(true);
                    }}
                  />
                  <MioDatePicker
                    isOpen={isDatepickerOpen}
                    customInput={<div />}
                    minDate={new Date()}
                    onDateClose={() => setIsDatepickerOpen(false)}
                    onSubmit={(date) => {
                      setStartDate(date);
                      setIsDatepickerOpen(false);
                      handleStartDate(date)
                    }}
                    timeZone={get(userData, 'details.user.timezone', '')}
                    scheduleValue={startDate ? new Date(startDate) : ''}
                    isFromCampaign
                    isFromAC
                  />
                </DateTimeWrapper>
              </Box>
            </Box>
          </Box>
          {/* <Flex flexDirection="column" width="45%">
            <Flex color="#101828" fontSize="14px" alignItems="center">
              Set Activation Date
            </Flex>
            <Flex
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '89%',
                backgroundColor: '#FFFFFF',
                border: '1px solid #E4E7EC',
                borderRadius: '4px',
                padding: '0px 5px',
                margin: '9px 0px 9px 7px',
                height: '40px',
              }}
            >
              <TimeInput style={{ border: '0px' }} />
              <img
                alt="date"
                src="/assets/images/Campaign/date&time-picker-icon.svg"
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  console.log('clicked');
                  setIsDatepickerOpen(true);
                }}
              />
            </Flex>
          </Flex> */}
        </Flex>
      )}
    </Flex>
  );
};

export default StepTwoHeadSection;
