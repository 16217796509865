import { Flex } from '@rebass/grid';
import { get, isEmpty, sortBy } from 'lodash';
import React, { useState, useEffect } from 'react';
import { LoaderWrapper } from '../../components';
import {
  ArticlesPage,
  Instructions,
  SurveyTopics,
  Thankyou,
} from '../../components/Survey';
import {
  getPRArticles,
  getPRTopics,
  savePRTopics,
  firePRUserAction,
} from '../../services/professional-research';
import { getExploreArticleData, getSurveyArticleData } from '../../utils';
import useQuery from '../../customHooks/useQuery';
import './bg.css';

const ProfessionalResearchContainer = () => {
  const [currentPage, setCurrentPage] = useState(0);
  const [selectedTopics, setSelectedTopics] = useState([]);
  const [customTopicName, setCustomTopicName] = useState('');
  const [topics, setTopics] = useState([]);
  const [articles, setArticles] = useState([]);
  const [loader, setLoader] = useState(false);
  const [buttonLoader, setButtonLoader] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const query = useQuery();

  let UUID = query.get('uid');
  let PATH = query.get('path');
  let STATE = query.get('state');

  let REDIRECT_URL = `http://survey-d.dynata.com/survey/${PATH}?state=${STATE}`;

  useEffect(() => {
    if (UUID && PATH && STATE) {
      setErrorMessage('');
      setLoader(true);
      getPRTopics()
        .then((res) => {
          // console.log('res', res);
          if (res.result.success) setTopics(res.result.data.srtopics);
          setLoader(false);
        })
        .catch((err) => {
          console.log('err', err);
          setLoader(false);
        });
    } else {
      setErrorMessage('Invalid URL, please check and try again.');
    }
  }, []);

  const beginAction = () => {
    let action = { external_uid: UUID, action: 'begin' };
    firePRUserAction(action)
      .then((res) => {
        setCurrentPage(2);
      })
      .catch((err) => {
        console.log('errfirePRUserAction', err);
        // setCurrentPage(2);
      });
  };

  const articleViewedAction = (payload) => {
    let action = {
      external_uid: UUID,
      action: 'article_view',
      articleId: payload.articleId,
      duration: payload.duration,
    };

    firePRUserAction(action)
      .then((res) => {
        console.log('articleViewedAction');
      })
      .catch((err) => {
        console.log('articleViewedAction', err);
      });
  };

  const articleLikedAction = (payload) => {
    let action = {
      external_uid: UUID,
      action: 'liked',
      articleId: payload.articleId,
    };
    return firePRUserAction(action);
  };

  const articleDislikedAction = (payload) => {
    let action = {
      external_uid: UUID,
      action: 'disliked',
      articleId: payload.articleId,
    };
    return firePRUserAction(action);
  };

  const paginationAction = (payload) => {
    setButtonLoader(true);
    let action = {
      external_uid: UUID,
      action: 'article_list_view',
      from_page: payload.from_page,
      to_page: payload.to_page,
      duration: payload.duration,
    };
    firePRUserAction(action)
      .then((res) => {
        setButtonLoader(false);
      })
      .catch((err) => {
        setButtonLoader(false);
      });
  };

  const surveyDoneAction = () => {
    let action = {
      external_uid: UUID,
      action: 'done',
    };
    return firePRUserAction(action);
  };

  const SurveyNextHandler = () => {
    setButtonLoader(true);
    let topicIds =
      selectedTopics &&
      selectedTopics
        .filter((res) => res.id !== 'custom')
        .map((data) => {
          return data.id;
        });

    // console.log('topicIds', topicIds.join(','));
    let payload = {
      external_uid: UUID,
      topic_id: topicIds.join(','),
      ...(customTopicName ? { other_topic_name: customTopicName } : {}),
    };

    savePRTopics(payload)
      .then((res) => {
        setButtonLoader(false);
        setCurrentPage(1);
      })
      .catch((err) => {
        console.log('saveTopicserr', err);
        setButtonLoader(false);
      });
  };

  const InstructionNextHandler = () => {
    setLoader(true);
    getPRArticles({ uuid: UUID })
      .then((res) => {
        if (res.result.success) {
          let trimmedArticles = getSurveyArticleData(res.result.data.data);
          let sortedArticles = sortBy(trimmedArticles, ['article_order_num']);
          // getExploreArticleData
          // console.log('sortedArticles', sortedArticles);
          setArticles(sortedArticles);
          beginAction();
          setCurrentPage(2);
        }
        setLoader(false);
      })
      .catch((err) => {
        console.log('err', err);
        setLoader(false);
      });
  };

  const ArticlesNextHandler = () => {
    setCurrentPage(3);
  };

  // useEffect(() => {
  //   console.log('selectedTopics', selectedTopics);
  // }, [selectedTopics]);

  return (
    <>
      <LoaderWrapper isLoading={loader} loaderHeight="100vh">
        {errorMessage ? (
          <Flex
            justifyContent="center"
            alignItems="center"
            style={{ width: 'inherit', height: '50vh' }}
          >
            {errorMessage}
          </Flex>
        ) : (
          <Flex justifyContent="center" alignItems="center">
            {currentPage === 0 && (
              <SurveyTopics
                nextHandler={() => {
                  SurveyNextHandler();
                }}
                topics={topics}
                setSelectedTopics={setSelectedTopics}
                selectedTopics={selectedTopics}
                customTopicName={customTopicName}
                setCustomTopicName={setCustomTopicName}
                setButtonLoader={setButtonLoader}
                buttonLoader={buttonLoader}
              />
            )}
            {currentPage === 1 && (
              <Instructions
                nextHandler={() => {
                  InstructionNextHandler();
                }}
              />
            )}
            {currentPage === 2 && (
              <ArticlesPage
                nextHandler={() => {
                  ArticlesNextHandler();
                }}
                articles={articles}
                paginationAction={paginationAction}
                articleViewedAction={articleViewedAction}
                articleLikedAction={articleLikedAction}
                articleDislikedAction={articleDislikedAction}
                surveyDoneAction={surveyDoneAction}
                paginationLoader={buttonLoader}
                redirectUrl={REDIRECT_URL}
              />
            )}
            {currentPage === 3 && <Thankyou redirectUrl={REDIRECT_URL} />}
          </Flex>
        )}
      </LoaderWrapper>
    </>
  );
};

export default ProfessionalResearchContainer;
