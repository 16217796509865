import { isEmpty } from 'lodash';
import apiInstance from '../api';
import { campaignViewEndpoints, cloutV2Endpoints, objectToFormData } from '../helpers';

export const createCampaign = (payload) => {
  return apiInstance().post(`/campaign`, objectToFormData(payload));
};

export const getCampaigns = (payload) => {
  let paginationString = (payload?.next || payload?.prev) ? 
    `&${payload?.next ? `after=${payload?.next}` : `before=${payload?.prev}`}` : '';
  if (payload && payload !== 'approved_to_share' && payload !== 'ats_automated') {
    const keys = Object.keys(payload);
    let tmpPd = { ...payload };
    if(tmpPd.next)
      delete tmpPd.next;
    else if(tmpPd.prev)
      delete tmpPd.prev;
    for (const key of keys) {
      if (isEmpty(tmpPd[key])) {
        delete tmpPd[key];
      }
    }

    if(tmpPd?.webinar){
        tmpPd.webinar = "yes";
    }

    if (!isEmpty(payload?.duration)) {
      let durationMold = payload?.duration.map((value, key) => {
        return JSON.parse(value);
      });
      tmpPd.duration = durationMold;
    }

    if (!isEmpty(payload?.frequency)) {
      let frequencyMold = payload?.frequency.map((value, key) => {
        return JSON.parse(value);
      });
      tmpPd.frequency = frequencyMold;
    }
    tmpPd.limit = 10;
    let stfy = JSON.stringify(tmpPd);

    return apiInstance().get(`/campaign?filter=${stfy}${paginationString}`);
  } 
  else 
    return apiInstance().get(`/campaign?filter=${JSON.stringify({limit: 10})}`);
};

export const getCampaignDetails = (id) => {
  return apiInstance().get(`/campaign/${id}`);
};

export const updateCampaign = ({ id, payload }) => {
  return apiInstance().post(`/campaign/${id}`, objectToFormData(payload));
};

export const updateStatusOfCampaign = ({ id, payload }) => {
  return apiInstance().post(
    `/campaign/${id}/update_status`,
    objectToFormData(payload)
  );
};

export const deleteCampaign = ({ id, type }) => {
  return apiInstance().delete(`/campaign/${id}?type=${type}`);
};

export const getEmailTemplates = (filterArray) => {
  return apiInstance().get(
    `/email/templates?filter={%22type%22:${JSON.stringify(filterArray)}}`
  );
};

export const postSelectedArticles = ({ payload }) => {
  return apiInstance().post(`campaign/bucket`, objectToFormData(payload));
};

export const getSelectedArticles = (campaignId) => {
  return apiInstance().get(`campaign/bucket/${campaignId}`);
};

export const publishCampaign = (payload) => {
  return apiInstance().post('/campaign/publish', objectToFormData(payload));
};

export const publishCampaignV2 = ({ id, payload }) => {
  return apiInstance().post(`/campaign/${id}/share`, objectToFormData(payload));
};

export const updateHtml = ({ id, payload }) => {
  return apiInstance().post(`/campaign/${id}/update_template`, objectToFormData(payload));
}

export const cloneOrSaveCampaign = (payload) => {
  return apiInstance().post(`/campaign/clone`, objectToFormData(payload));
};

export const postCampaignReview = ({ id, payload }) => {
  return apiInstance().post(
    `/campaign/${id}/review`,
    objectToFormData(payload)
  );
};

export const postEmailTemplates = (payload) => {
  return apiInstance().post('/email/templates', objectToFormData(payload));
};

export const nextCampaignReview = (id) => {
  return apiInstance().get(`/campaign/${id}/pending`);
};

export const getEmailTemplate = (id) => {
  return apiInstance().get(`/email/templates/${id}`);
};

// export const postCascadeEmailTemplate = (payload) => {
//   return apiInstance().post(
//     `/email/template/cascade`,
//     objectToFormData(payload)
//   );
// };

export const viewCampaignInfo = (recordId, recordType) => {
  return apiInstance().get(
    `/campaign/view_info?record_id=${recordId}&record_type=${recordType}`
  );
};

export const exportPdf = (payload) => {
  return apiInstance().post('/campaign/export', objectToFormData(payload));
};

export const postCascadeEmailTemplate = (payload) => {
  return apiInstance().post(`/email/template/cascade`, objectToFormData(payload));
};

export const postCampaignCascade = (payload, id) => {
  return apiInstance().post(`/campaign/cascade/${id}`, objectToFormData(payload));
};

export const postCampaignCascadeEnterpriseLevel = ({payload, id, campaignType}) => {
  return apiInstance().post(`/campaign/cascade_to_firm/${id}?campaign_type=${campaignType}`, objectToFormData(payload))
}

export const deleteEmailTemplate = ({ id }) => {
  return apiInstance().delete(`/email/templates/${id}`);
};

export const beeAuth = (type) => {
  if(type) {
    return apiInstance().post(`/editor/login?type=${type}`);
  } else {
    return apiInstance().post('/editor/login');
  }
};

export const getWebinarList = () => {
  return apiInstance().get('/user/webinars');
}

export const getWebinarDetails = ({ id }) => {
  return apiInstance().get(`/user/webinars/details?wid=${id}`);
}
export const postCascadeCampaign = ({ id, payload }) => {
  return apiInstance().post(`/campaign/cascade/${id}`, objectToFormData(payload));
}

export const getCascadedCampaigns = (payload) => {

  if(payload && payload.next) {
    return apiInstance().get(`/campaign/cascaded?limit=10&after=${payload.next}`);
  } else if (payload && payload.prev) {
    return apiInstance().get(`/campaign/cascaded?limit=10&before=${payload.prev}`);
  } else {
    return apiInstance().get(`/campaign/cascaded?limit=10`);
  }
  
}

export const postUseCascadedCampaign = ({ cascadeId, recordType }) => {
  if(recordType === 'series')
    return apiInstance().post(`/campaign/process_cascaded/${cascadeId}?record_type=series`);
  else
    return apiInstance().post(`/campaign/process_cascaded/${cascadeId}`);
}

export const postIgnoreCascade = ({ cascadeId, recordType }) => {
  if (recordType === 'series')
    return apiInstance().post(`/campaign/ignore_cascaded/${cascadeId}?record_type=series`);
  else
    return apiInstance().post(`/campaign/ignore_cascaded/${cascadeId}`);
}

export const getBasicCampaignViewDetails = (id) => {
  return apiInstance().get(`${campaignViewEndpoints.baseUrl}/details?campaign=${id}`);
}

export const getEmailSendData = (id) => {
  return apiInstance().get(`${campaignViewEndpoints.baseUrl}/sent?campaign=${id}`);
}

export const getEmailEngagementData = (id) => {
  return apiInstance().get(`${campaignViewEndpoints.baseUrl}/sent/details?campaign=${id}`);
}

export const getSocialSendData = (id) => {
  return apiInstance().get(`${campaignViewEndpoints.baseUrl}/social?campaign=${id}`);
}

export const getCascadeInfoEnterpriseLevelAdmin = ({payloadId, type}) => {
  return apiInstance().get(`/campaign/firm_cascaded/${payloadId}?campaign_type=${type}`);
}

export const getSeriesSubCampaigns = ({ id, after, limit=10 }) => {
  if(after) {
    return apiInstance().get(`/series_campaigns?filter={"series_id":${id}, "limit": ${limit}}&after=${after}`);
  } else {
    return apiInstance().get(`/series_campaigns?filter={"series_id":${id}, "limit":  ${limit}}`);
  }
}

export const getCanDuplicateSeries = ({ id }) => {
  return apiInstance().get(`/campaign_can_duplicate?is_series=true&series_id=${id}`)
}

export const sendTestEmailOneTime = (payload) => {
  return apiInstance().post('/campaign/send_test_email', objectToFormData(payload));
}

export const cascadedEmailTemplate = ({ id, payload }) => {
  return apiInstance().post(`email/cascade_to_firm/${id}`, objectToFormData(payload));
}

export const getCascadeEmailTemplate = (id) => {
  return apiInstance().get(`email/firms_cascaded/${id}`);
}

export const lpImageUpload = ({ id, campaignId, media1, ac_block_id }) => {
  return apiInstance().post(`landing_pages/upload/${id}`, objectToFormData({
    ...campaignId && {
      campaign_id: campaignId,
    }, 
    ...ac_block_id && {
      ac_block_id
    }, 
    media1
  }))
}

export const createOnetimeSeriesCampaign = (payload) => {
  return apiInstance().post(`/campaign/series`, objectToFormData(payload));
}

export const getSeriesDetails = (id) => {
  return apiInstance().get(`/campaign/series/${id}`);
}

export const updateOneTimeSeriesCampaign = (id, payload) => {
  return apiInstance().post(`/campaign/series/${id}`, objectToFormData(payload));
}

export const updateSocialBlock = (id, payload) => {
  return apiInstance().post(`/campaign/${id}/add_social`, objectToFormData(payload));
}

export const scheduleCampaign = (id, payload) => {
  return apiInstance().post(`campaign/series/${id}/schedule`, objectToFormData(payload));
}

export const shareSeriesCampaign = (id, payload) => {
  return apiInstance().post(`campaign/series/${id}/share`, objectToFormData(payload))
}

export const campaignSummary = (id) => {
  return apiInstance().get(`/campaign/series/${id}/summary`);
}

export const getCompanyData = ({ companyId, type }) => {
  return apiInstance().get(`/company/${companyId}/entities/?entity=${type}`);
}

export const postAutoPost = ({ seriesId, payload }) => {
  return apiInstance().post(`/campaign/${seriesId}/auto_post`, objectToFormData(payload))
}

export const saveDuplicateTemplate = ({ name, id }) => {
  return apiInstance().post(
    `${cloutV2Endpoints.baseUrl}/newsletter/copy_email_template`,
    { name, copied_from:id }
  );
};

export const fetchOpenAiSuggestions = (payload) => {
  return apiInstance().post(`${cloutV2Endpoints.baseUrl}/recommendation/ai_suggestions`, payload);
}

export const fetchOpenAiStreamingSuggestions = (payload) => {
  return apiInstance().post(`${cloutV2Endpoints.baseUrl}/recommendation/openai_recommendations`, payload);
}