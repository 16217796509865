import styled from 'styled-components';
import { colors } from '../../styles';

export const FlexContainer = styled('div')`
  display: flex;
  justifyContent: ${({ justify }) => justify ? justify : 'space-between'};
  width: ${({ width }) => width ? width : '100%'};
  margin: ${({ margin }) => margin ? margin : 0};
`;

export const PageContainer = styled('div')`
  width: 100%;
  display: table;
  text-align: left;
  margin: ${({ margin }) => margin || '10px 0 0'};
`;

export const CardContainer = styled('div')`
  display: flex;
  justify-content: start;
  margin: 0;
  width: 100%;
  flex-flow: rows;
  flex-wrap: wrap;
`;

export const SearchContainer = styled('div')`
  font-family: 'Poppins' !important;
  display: flex;
  width: 100%;
  justify-content: end;
  margin: ${({ searchActive }) => searchActive ? '2px 5px 0 0' : '10px 5px 0 0'};
`;

export const ErrorMessage = styled('div')`
  color: ${colors.red};
  font-size: 10px;
  margin: 4px 0 0;
  text-align: left;
`;

export const ButtonIcons = styled('img')`
  height: 20px;
  width: 20px;
  transition: 1s;
  margin: ${({ margin }) => margin ? margin : '0px 6px 0 0'};
  transform: ${({ selected }) => selected && 'rotate(180deg)'};
`;

export const ButtonContainer = styled('div')`
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
  width: 100%;
`;

export const BodyText = styled('p')`
  font-size: 16px;
  font-weight: 400;
  margin: 10px 0;
  width: 90%;
  color: ${colors.black};
`;

export const DarkText = styled('div')`
  font-size: 16px;
  font-weight: 500;
  margin: 20px 10px;
  line-height: 1.2;
  width: 90%;
  color: ${colors.black_01};
`;

export const CreateContainer = styled('div')`
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-flow: rows;
  flex-wrap: wrap;
  max-width: 700px;
`;

export const OuterCreateContainer = styled('div')`
  width: 100%;
  display: table;
`;

export const CreateCards = styled('div')`
  font-size: 16px;
  font-weight: 400;
  margin: 10px 0;
  width: 48%;
  padding: 10px;
  display: flex;
  border-radius: 8px;
  cursor: pointer;
  border: 1px solid ${colors.gray_border};
  color: ${colors.black};
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.12);
  transition: 0.75s;
  :hover {
    box-shadow: ${({ border }) => !border ? '0px 1px 2px rgba(16, 24, 40, 0.72)' : 0};
  }
`;

export const CreateIcons = styled('img')`
  height: 60px;
  width: 80px;
  object-fit: cover;
  border: 1px solid ${colors.gray_border};
  border-radius: 8px;
  overflow: hidden;
  margin: ${({ margin }) => margin ? margin : '3px 6px 0 0'};
`;

export const DropdownItems = styled('div')`
    box-shadow: 0px 4px 8px rgba(16, 24, 40, 0.1);
    position: absolute;
    top: 50px;
    background: ${colors.white};
    transition: 0.5s;
    overflow: hidden;
    border-radius: 8px;
    z-index: 99999;
    width: 200px;
    border: 1px solid ${colors.gray_border};
    box-shadow: 0px 4px 8px rgba(16, 24, 40, 0.1);
    left: ${({ left }) => left || ''};
`;

export const DropdownRow = styled('div')`
    width: 100%;
    display: flex;
    font-weight: 400;
    font-size: 14px;
    padding: 8px 12px;
    color: ${colors.black_01};
    cursor: pointer;
    width: 200px;
    text-align: left;
    transition: 0.5s;
    min-width: 120px;
    :hover {
        background: #F6F2FF;
        color: ${colors.header_purple};
    }
`;

export const LayoutCard = styled('div')`
    width: 100%;
    transition: 0.5s;
    display: flex;
    border: 1px solid ${colors.gray_border};
    margin: 15px auto;
    border-radius: 4px;
    cursor: pointer;
    padding: 10px;
    :hover {
      background: #F6F2FF;
    }
`;

export const LayoutImg = styled('img')`
  height: 40px;
  width: 40px;
  margin: 0 15px 0 0;
`;

export const LayoutText = styled('div')`
    font-size: 16px;
    font-weight: 500;
    margin: 9px 0 0;
    color: ${colors.black_01};
`;
