import { get, isEmpty, values } from 'lodash';
import _ from 'lodash';
import { getLnPageId, getDayMonthYear } from '../../utils';
import { deleteScheduledArticles } from '../../services/dashboard';
import { textBoxes } from './constants';

const advisorCheckValidation = (
  isAllSummary,
  isChecked,
  selectedContactGroup,
  setEnableShare,
  setShareModalMsgs,
  isFetchingSummary,
  source
) => {
  const isAllSummaryExist = values(isAllSummary).every(Boolean);
  console.log('isAllSummaryExist - - - ', isAllSummaryExist);
  if(isChecked.Email && !selectedContactGroup && source!=='img'){
    const msg = ['Please select email list'];
    setEnableShare(false);
    !isFetchingSummary && !isAllSummaryExist && msg.push('Please share all summaries');
    setShareModalMsgs(msg);
  }else{
    setEnableShare(isAllSummaryExist);
    !isFetchingSummary && !isAllSummaryExist ? setShareModalMsgs(['Please share all summaries']) : setShareModalMsgs([]);
  }
};

// FOR MYCONNECTION AND ADVISORS VALIDATION
const myConnectionCheckValidation = (
  warningMsg,
  isChecked,
  selectedContactGroup,
  platformObj,
  summaryData,
  setShareModalMsgs,
  setEnableShare,
  scheduleTime,
  selectedFbPage,
  setScheduleState
) => {
  let validationMessages = [...warningMsg];
  const checkedMyConnections = _.keys(_.pickBy(isChecked)).filter(k => k !== 'All');
  const emailChecked = checkedMyConnections.includes('Email') && !selectedContactGroup;
  let isSummaryData = {};
  // console.log('here 1.1', checkedMyConnections);
  const errorFields = [];
  checkedMyConnections.forEach(myConnection => {
    const summaryKey = `${platformObj[myConnection]}Summary`;
    const checkForSummary = !!(summaryData[summaryKey] && summaryData[summaryKey].length > 0);
    isSummaryData[summaryKey] = checkForSummary;
    if(!checkForSummary)
      errorFields.push(myConnection)
    // !checkForSummary && validationMessages.push(`Please share ${myConnection} summary`);
  });
  // console.log('FAULTY PLATFORMS------', errorFields);
  const isSummaryExist = values(isSummaryData).every(Boolean);
  const facebookScheduleCheck = isChecked.Facebook === true && scheduleTime && (selectedFbPage === 0 || isEmpty(selectedFbPage) || selectedFbPage === '0');
  // console.log('here 1.2', values(isSummaryData).every(Boolean));
  if(emailChecked){
    // validationMessages.push('Please select email list');
    // setShareModalMsgs(_.union(validationMessages));
    errorFields.push('select email list');
    // setEnableShare(false);
  } else if(facebookScheduleCheck) {
    validationMessages.push('You cannot schedule Facebook post on your news feed');
    // setShareModalMsgs(_.union(validationMessages));
    // setEnableShare(false);
    setScheduleState({ clicked: false, scheduleIsOpen: false });
  }
  else{
    setEnableShare(isSummaryExist);
    !isSummaryExist ? setShareModalMsgs(_.union(validationMessages)) : setShareModalMsgs([]);
  }
  if(errorFields.length>0 || validationMessages.length>0) {
    // console.log('ERROR FIELDS = ', `To enable sharing, complete missing fields: ${errorFields[0] + (!errorFields[0].includes('select') ? ' summary' : '')}`);
    if(errorFields.length>1)
      validationMessages.push(`To enable sharing, complete missing fields: ${errorFields.join(' summary, ')} ${!errorFields[errorFields.length-1].includes('select') ? ' summary' : ''}`);
    else if(errorFields.length === 1) 
      validationMessages.push(`To enable sharing, complete missing fields: ${errorFields[0] + (!errorFields[0].includes('select') ? ' summary' : '')}`);
    setShareModalMsgs(_.union(validationMessages));
    setEnableShare(false);
  }
}

export const validator = (
  summaryData,
  isAdvisorChecked,
  setShareModalMsgs,
  isChecked,
  selectedContactGroup,
  setEnableShare,
  platformObj,
  warningMsg,
  setWarningMsg,
  isFromScheduledTab,
  scheduleTime,
  selectedFbPage,
  setScheduleState,
  isFetchingSummary,
  isAdmin,
  canAddMembers,
  sponsoredFunds,
  source
) => {
  console.log('SOURCE - - -- ', source);
    let isAllSummary = {
      twSummary: !!(summaryData.twSummary && summaryData.twSummary.length > 0),
      fbSummary: !!(summaryData.fbSummary && summaryData.fbSummary.length > 0),
      lnSummary: !!(summaryData.lnSummary && summaryData.lnSummary.length > 0),
    }
    if(source!=='img')
      isAllSummary.emSummary = !!(summaryData.emSummary && summaryData.emSummary.length > 0);
    if(window.location.pathname.includes('compliance')){
      return;
    }else{
      if(values(isAdvisorChecked).includes(true) && isAdmin && !isFromScheduledTab && canAddMembers === 'true'){
      advisorCheckValidation(
        isAllSummary,
        isChecked,
        selectedContactGroup,
        setEnableShare,
        setShareModalMsgs,
        isFetchingSummary,
        source
      );
    }else if(!values(isAdvisorChecked).includes(true) && values(isChecked).includes(true)){
      myConnectionCheckValidation(
        warningMsg,
        isChecked,
        selectedContactGroup,
        platformObj,
        summaryData,
        setShareModalMsgs,
        setEnableShare,
        scheduleTime,
        selectedFbPage,
        setScheduleState
      );
    } else if(isFromScheduledTab && (new Date().getTime() > new Date(scheduleTime).getTime())) {
      return;
    } else{
      setEnableShare(false);
      setShareModalMsgs([...warningMsg]);
    }
  }
}

export const validateOnSchedule = (isChecked, setShareModalMsgs, showComplianceCheck, selectedComplianceCheck, shareModalMsgs) => {
    let isFormValid = true;
    if (!_.values(isChecked).includes(true)) {
      const warningPlatformMsg = 'Please select Twitter, LinkedIn, Facebook or Email option';
      setShareModalMsgs([
        warningPlatformMsg,
      ]);
      isFormValid = false;
      setTimeout(() => {
        setShareModalMsgs(_.without(shareModalMsgs, warningPlatformMsg));
      }, 3000);
    } else if (showComplianceCheck && !selectedComplianceCheck) {
      setShareModalMsgs(['Please choose one compliance review option']);
      isFormValid = false;
    }
    return isFormValid;
};

export const getSummaryData = (
    rest,
    setPlaceholerText,
    setIsFetchingSummary,
    getContactsGroups,
    setContactGroups,
    getContactListForEmail,
    shareDetails,
    getArticleSummary,
    setAllSummaries,
    setSummaryData,
    summaryData,
    isFromScheduledTab,
    summaries,
    selectedItem,
    isFromRecentTab,
    setMediumSummary,
    sponsoredFunds,
    adminChannelId,
    fetchedThemes,
    isFromAddContent
) => {
    const { id, themeId } = rest;
    const defaultEditableContentPlaceholder =
      'Share your thoughts about this article...';
    setPlaceholerText('Generating Summary...');
    setIsFetchingSummary(true);
    getContactsGroups().then((response) => {
      const data = get(response, 'result.data', []);
      setContactGroups(getContactListForEmail(data));
    });

    // Formatting sponsored funds
    let tickers = sponsoredFunds && Object.keys(sponsoredFunds);
    tickers = tickers && tickers.length > 0 && tickers.map(i => '#' + i);
    tickers = tickers &&  tickers.length > 0 && tickers.join(' ');

    // SET SUMMARY DATA TO INPUT FIELD AND ONLY IF ITS NOT FROM ADD CONTENT
    if (!shareDetails && !isFromScheduledTab && !isFromRecentTab) {
    getArticleSummary({ articleId: isFromAddContent ? `CC_${adminChannelId}_${id}` : id, themeId: isFromAddContent && fetchedThemes && fetchedThemes[0].id ? fetchedThemes[0].id : isFromAddContent && fetchedThemes ? fetchedThemes[0] :  themeId })
        .then((response) => {
            const resultData = get(response, 'result.data.summaries');
            setAllSummaries(resultData);
            const summaryObj = {
                shortSummary: sponsoredFunds? get(resultData, 'short[0].summary', get(resultData, 'short[0]')) + ' ' + tickers  : get(resultData, 'short[0].summary', get(resultData, 'short[0]')),
                mediumSummary: sponsoredFunds ? get(resultData, 'medium[0].summary', get(resultData, 'medium[0]')) + ' ' + tickers : get(resultData, 'medium[0].summary', get(resultData, 'medium[0]')),
                longSummary: sponsoredFunds ? get(resultData, 'long[0].summary', get(resultData, 'long[0]')) + ' ' + tickers : get(resultData, 'long[0].summary', get(resultData, 'long[0]'))
            }
            // console.log('[SUMMARUY DATA] - ', summaryData);
            setSummaryData({
                ...summaryData,
                twSummary: summaryData.twSummary ? summaryData.twSummary : summaryObj.shortSummary,
                fbSummary: summaryObj.longSummary,
                lnSummary: summaryObj.mediumSummary,
                emSummary: summaryObj.longSummary,
                itSummary: summaryObj.longSummary,
                
            });
            setMediumSummary(summaryObj.mediumSummary);
            setPlaceholerText(defaultEditableContentPlaceholder);
            setIsFetchingSummary(false);
        })
        .catch((error) => {
            setPlaceholerText(defaultEditableContentPlaceholder);
            setIsFetchingSummary(false);
        });
    } else if(isFromScheduledTab) {
      const {text, destination} = selectedItem;
      setSummaryData({
        ...summaryData,
        [`${destination.network}Summary`]: text
      });
      setPlaceholerText(defaultEditableContentPlaceholder);
      setIsFetchingSummary(false);
    } else if(isFromRecentTab) {
      console.log(selectedItem);
      const {text, destination} = selectedItem;
      setSummaryData({
        ...summaryData,
        [`${destination.network}Summary`]: text
      });
      setPlaceholerText(defaultEditableContentPlaceholder);
      setIsFetchingSummary(false);
    }
     else {
        const resultDestination = get(shareDetails, 'destination.snetwork', '');
        if (resultDestination) {
            setSummaryData({
                ...summaryData,
                [`${resultDestination}Summary`]: shareDetails.summary,
            });
        }
        setPlaceholerText(defaultEditableContentPlaceholder);
        setIsFetchingSummary(false);
    }
}

export const getPlaceHolder = (platform, placeholerText, fromMyClients) => {
  console.log('[FROM MY CLIENTS] functions.js - ', fromMyClients);
    if(fromMyClients)
      return placeholerText;
    else if (platform === 'ln') {
      return placeholerText;
    }
    return '';
};

// CONVERTING IMAGE URL TO BASE64
export const toDataURL = (src, callback, outputFormat) => {
    var img = new Image();
    img.crossOrigin = 'Anonymous';
    img.onload = function() {
      var canvas = document.createElement('CANVAS');
      var ctx = canvas.getContext('2d');
      var dataURL;
      canvas.height = this.naturalHeight;
      canvas.width = this.naturalWidth;
      ctx.drawImage(this, 0, 0);
      dataURL = canvas.toDataURL(outputFormat);
      callback(dataURL);
    };
    img.onerror = function() {
      callback(false);
    }
    img.src = src;
    if (img.complete || img.complete === undefined) {
      img.src = "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///ywAAAAAAQABAAACAUwAOw==";
      img.src = src;
    }
}

const getOverrideText = (platform, summaryData) => get(summaryData, `${platform}Summary`, '');

// GENERATE SOURCE_SNETWORK VALUE FOR SHARE API FORM DATA
export const getSourceNwId = (id = '') => {
    const idSplit = id.split('_');
    return _.toLower(get(idSplit, 0));
};

// SNETWORK_ACT_ID FOR SHARE API FORM DATA
const getAccountId = (platform, hasMultipleEmail, socialNetworkData) => {
    let selectedAcountId = '';
    if (hasMultipleEmail && platform === 'em') {
        selectedAcountId = '';
    }
    const lnPageId = getLnPageId();
    if (platform === 'ln' && lnPageId) {
        selectedAcountId =
        get(socialNetworkData, `${platform}[0].accountId`) + `#${lnPageId}`;
    }
    if (!selectedAcountId) {
      if(platform === 'em') {
        if(!isEmpty(get(socialNetworkData, `${platform}`, [])) && get(socialNetworkData, `${platform}`, []).length > 1 
        && get(socialNetworkData, `${platform}`, []).filter(s => s.snetworkProvider === 'sg').length > 0) {
          selectedAcountId = get(socialNetworkData, `${platform}`, []).filter(s => s.snetworkProvider === 'sg')[0].accountId;
        } else {
          selectedAcountId = get(socialNetworkData, `${platform}[0].accountId`);
        }  
      } else {
        selectedAcountId = get(socialNetworkData, `${platform}[0].accountId`);
      }
        
    }
    return selectedAcountId;
};

// Change Time if scheule time is less than current time
const changeScheduleTime = (time) => {
  let newTime = new Date(time);
  newTime.setMinutes(newTime.getMinutes() + 5);
  console.log(newTime);
  const [selectedMonth, selectedDay, selectedYear] = getDayMonthYear(
    newTime
  );
  const checkHours = newTime.getHours().toString().length === 1 ? `0${newTime.getHours()}` : newTime.getHours();
  const checkMin = newTime.getMinutes().toString().length === 1 ? `0${newTime.getMinutes()}` : newTime.getMinutes();
  return `${selectedYear}-${selectedMonth}-${selectedDay}T${checkHours}:${checkMin}:00`;
}

// For current Time

const jsonFormattedCurrentTime = () => {
  let newTime = new Date();
  newTime.setMinutes(newTime.getMinutes() + 5);
  console.log(newTime);
  const [selectedMonth, selectedDay, selectedYear] = getDayMonthYear(
    newTime
  );
  const checkHours = newTime.getHours().toString().length === 1 ? `0${newTime.getHours()}` : newTime.getHours();
  const checkMin = newTime.getMinutes().toString().length === 1 ? `0${newTime.getMinutes()}` : newTime.getMinutes();
  return `${selectedYear}-${selectedMonth}-${selectedDay}T${checkHours}:${checkMin}:00`;
}


// CREATES FORM DATA FOR SHARE API
const getDefaulParamsForApi = (
    platform, dateTime, autoPostPlatforms, summaryData, rest, adminChannelId,
    fetchedThemes, selectedContactGroup, scheduleTime, userData, selectedComplianceCheck, hasMultipleEmail,
    selectedFbPage, isPremiumAdvisor, emailTextData, isAdmin, isAutoPostChecked, socialNetworkData,
    premiumAdvisorChannelId, isFromAddContent, showComplianceCheck, selectedLnPage, isFromScheduledTab, 
    fromMyClients, contactId, approvedArticles, isRepost, allSumarries, selectedDropdownOption, 
    selectedCRMContactList, selectedLeadCaptureOption, showLeadCaptureOption, selectedReferralCaptureOption, isCloning, article , isContentChanged, isOGArticleComplianced, isFromImageOnly
) => {
  const { id, themeId, channelId } = rest;
  let newTime = scheduleTime || dateTime;
  let newSummaryTxt;
  const summaryTxt = getOverrideText(platform, summaryData);
  const originalSummary = get(allSumarries, textBoxes[platform].editableContent.summaryOption, []);
  console.log('original summary', originalSummary, allSumarries);
  if(isFromScheduledTab || (originalSummary && !isEmpty(originalSummary) && !isEmpty(originalSummary[0]) && originalSummary[0].length !== summaryTxt.length) || isEmpty(originalSummary)) {
    newSummaryTxt = summaryTxt;
  } else {
    newSummaryTxt = summaryTxt.replace(/-/g, '').replace(/\\/g, "");
  }
  
  let selectedChannelId = channelId ? channelId : adminChannelId;
  console.log('[SCHEDULE TIME] - ', scheduleTime, '[DATE TIME] - ', dateTime);
  console.log('[selected CRM ] - ', selectedCRMContactList, selectedDropdownOption);
  let obj = {
    channel_id: channelId ? channelId : adminChannelId,
    destination_snetwork: platform,
    isScheduled: false,
    engagement_type: 'share',
    id : isCloning ? article.articleId : ((selectedChannelId || premiumAdvisorChannelId) && isFromAddContent && !rest.isContentLibrary) ? 'CC_'+adminChannelId+'_'+id : (showComplianceCheck && isFromAddContent) ? 'CC_'+adminChannelId+'_'+id : id,
    override_text: newSummaryTxt,
    source_snetwork: (channelId) ? isCloning ? getSourceNwId(article.articleId) : getSourceNwId(id) : 'cc', //getSourceNwId(id),
    ...(fetchedThemes && fetchedThemes.length > 0 && { theme_id: fetchedThemes[0].id ? fetchedThemes[0].id : fetchedThemes[0] }),
    ...(themeId && { theme_id: themeId && themeId.id ? themeId.id : themeId}),
    ...(platform === 'em' && {
      contactlist_id: selectedContactGroup,
    }),
    snetwork_act_id: getAccountId(platform, hasMultipleEmail, socialNetworkData),
    ...((newTime) && {
      schedule_time: new Date(newTime).getTime() < new Date().getTime() ? changeScheduleTime(newTime) : newTime,
    }),
    skip_compliance: isCloning ? ((isOGArticleComplianced && isContentChanged) || (!isOGArticleComplianced && (userData.compliance === 'on' || selectedComplianceCheck === 'yes')) ? false : true) : (isFromScheduledTab || approvedArticles) ? true : userData.compliance_admin && userData.compliance === 'on' 
    ? !userData.compliance_admin 
    : userData.compliance_admin && userData.compliance === 'optional' 
    ? selectedComplianceCheck !== 'yes' 
    : userData.compliance === 'on' || selectedComplianceCheck === 'yes' ? false : true,
    autoshare_on_subscribers_snetworks: isAutoPostChecked ? autoPostPlatforms : '',
    ...((localStorage.getItem('emailData') && (approvedArticles || emailTextData.subject !== '')) && {email_data: approvedArticles ? localStorage.getItem('emailData') : JSON.stringify(emailTextData)}),
    repost: isRepost
  };

  if(showLeadCaptureOption) {
    if(selectedLeadCaptureOption) {
      obj.lead_gen = 'true';
    } else if(selectedReferralCaptureOption && platform === 'em') {
      console.log('checking');
      obj.referral = 'true';
    }
  }
  
  if(fromMyClients) {
    obj.schedule_time = dateTime || scheduleTime;
    console.log(obj.schedule_time);
    if(!obj.schedule_time)
      delete obj.schedule_time;
    obj.contactlist_id = selectedDropdownOption==='crm' ? selectedCRMContactList.id : contactId; 
  }
  if((selectedFbPage !== 0 && selectedFbPage !== '0' && selectedFbPage !== '') && platform === 'fb') obj.destination_id = selectedFbPage;
  if(platform === 'ln' && selectedLnPage !== 0 && selectedLnPage !== '0' && selectedLnPage !== '') obj.destination_id = selectedLnPage;
  return obj;
};

// CREATES FORM DATA FOR CONTENT API
export const contentCreateUpdate = ({rest, adminChannelId, summaryData, userData, selectedComplianceCheck, isFromAddContent, isAutoPostChecked, autoPostPlatforms, emailTextData, allSumarries, selectedLeadCaptureOption, showLeadCaptureOption, selectedReferralCaptureOption, fromContentLibTab, isAdminUser, description }) => {
  // console.log('[EMAIL TEXT DATA]', message);
  const {
    id,
    themeId,
    link,
    topic,
    url,
    message
  } = rest;
  console.log('MESSAGE contentCreateUpdate- -- - - - - - - ', description);
  let adminContentId = id.split('_')[2];
  let obj;
  if (isFromAddContent || (fromContentLibTab && isAdminUser)) {
    obj = {
      contentId: fromContentLibTab && isAdminUser ? adminContentId : id,
      isMioShare: true,
      summary: {
        short: summaryData.twSummary,
        medium: summaryData.lnSummary,
        long: summaryData.emSummary,
      },
      skip_compliance: userData.compliance_admin && userData.compliance === 'on' ?
        !userData.compliance_admin : userData.compliance_admin && userData.compliance === 'optional' ?
        selectedComplianceCheck !== 'yes' : true,
      // skip_compliance:  isComplianceOn==='optional' ? true : false,
      message: description,
      sharing: true,
      media1: url && (url.includes('https') || url.includes('http') || url.includes('asset')) ? '' : url,
      title: topic,
      autoshare_on_subscribers_snetworks: isAutoPostChecked ? autoPostPlatforms : '',
      ...((localStorage.getItem('emailData') && emailTextData.subject !== '') && {email_data: JSON.stringify(emailTextData)})
    };
  } else {
    const originalTwSummary = get(allSumarries, textBoxes['tw'].editableContent.summaryOption, []);
    const originalLnSummary = get(allSumarries, textBoxes['ln'].editableContent.summaryOption, []); 
    const originalEmSummary = get(allSumarries, textBoxes['em'].editableContent.summaryOption, []);  
    obj = {
      channelId: adminChannelId,
      source: 'manual',
      link,
      summary: {
        short: !isEmpty(originalTwSummary) && !isEmpty(originalTwSummary[0].summary) &&  originalTwSummary[0].summary.length !== summaryData.twSummary.length ? summaryData.twSummary : summaryData.twSummary.replace(/-/g, '').replace(/\\/g, ""),
        medium: !isEmpty(originalLnSummary) && !isEmpty(originalLnSummary[0].summary) &&  originalLnSummary[0].summary.length !== summaryData.lnSummary.length ? summaryData.lnSummary : summaryData.lnSummary.replace(/-/g, '').replace(/\\/g, ""),
        long: !isEmpty(originalEmSummary) && !isEmpty(originalEmSummary[0].summary) &&  originalEmSummary[0].summary.length !== summaryData.emSummary.length ? summaryData.emSummary : summaryData.emSummary.replace(/-/g, '').replace(/\\/g, ""),
      },
      title: topic,
      original_content_id: id,
      publish_status: 'published',
      skip_compliance: userData.compliance_admin && userData.compliance === 'on' ?
        !userData.compliance_admin : userData.compliance_admin && userData.compliance === 'optional' ?
        selectedComplianceCheck !== 'yes' : true,
      // skip_compliance: isComplianceOn==='optional' ? true : false,
      ...(themeId && {
        themes: JSON.stringify({
          add: [themeId],
        }),
      }),
      ...((localStorage.getItem('emailData') && emailTextData.subject !== '') && {email_data: JSON.stringify(emailTextData)})
    };
  }

  if(showLeadCaptureOption) {
    if(selectedLeadCaptureOption) {
      obj.lead_gen = 'true';
    } else if(selectedReferralCaptureOption) {
      obj.referral = 'true';
    }
  }

  return obj;
};

export const shareApiCall = (
    platform, dateTime, autoPostPlatforms, summaryData, rest, adminChannelId,
    fetchedThemes, selectedContactGroup, scheduleTime, userData, selectedComplianceCheck, hasMultipleEmail,
    selectedFbPage, isPremiumAdvisor, emailTextData, isAdmin, isAutoPostChecked, socialNetworkData,
    premiumAdvisorChannelId, isFromAddContent, showComplianceCheck, selectedLnPage, deleteArticleRequest,
    scheduledIds, isFromScheduledTab, fromMyClients, contactId, approvedArticles, isRepost, allSumarries, 
    selectedDropdownOption, selectedCRMContactList, selectedLeadCaptureOption, showLeadCaptureOption, selectedReferralCaptureOption, isCloning, article, isContentChanged, isOGArticleComplianced, isFromImageOnly
) => {
    console.log('[SHARE API CALL FROM MYCLIENTS]...', fromMyClients);
    const params = getDefaulParamsForApi(
      platform, dateTime, autoPostPlatforms, summaryData, rest, adminChannelId,
      fetchedThemes, selectedContactGroup, scheduleTime, userData, selectedComplianceCheck, hasMultipleEmail,
      selectedFbPage, isPremiumAdvisor, emailTextData, isAdmin, isAutoPostChecked, socialNetworkData,
      premiumAdvisorChannelId, isFromAddContent, showComplianceCheck, selectedLnPage, isFromScheduledTab, fromMyClients, contactId, approvedArticles, 
      isRepost, allSumarries, selectedDropdownOption, selectedCRMContactList, selectedLeadCaptureOption, showLeadCaptureOption, selectedReferralCaptureOption, isCloning, article, isContentChanged, isOGArticleComplianced, isFromImageOnly
    );

    return params;
};

export const displayArticleSuccessMsgs = (
  shareError,
  platformObj,
  shareData,
  isComplianceOn,
  showComplianceCheck,
  selectedComplianceCheck,
  isAdmin,
  selectedFbPage,
  setIsCreatingContent,
  scheduleState,
  setScheduleState,
  setShareModalMsgs,
  closeModal,
  isFromScheduledTab,
  isEnterpriseAdvisor,
  approvedArticles,
  isCloning,
  isOGArticleComplianced,
  isContentChanged
) => {
  let sMessage = [];
  let result = [];
  if(shareError && shareError.length > 0){
    result = platformObj.connected.map(x => shareError.find(y => y.platform == platformObj[x]));
    result = result.filter( Boolean );
  }
  const sharedPlatforms = _.keys(_.pickBy(shareData));
  sharedPlatforms && sharedPlatforms.length > 0 && sharedPlatforms.map((data) => {
    console.log('[ISENTERPRISE] - ', isEnterpriseAdvisor);
    if(isComplianceOn === 'on' && !isFromScheduledTab && !approvedArticles && !isCloning){
        sMessage.push('Sent to compliance for approval');
    }
    if ((showComplianceCheck || isEnterpriseAdvisor) && !isCloning && !approvedArticles && selectedComplianceCheck !== 'no'){
        sMessage.push('Sent to compliance for approval');
    }
    else if (isCloning) {
      if((isOGArticleComplianced && isContentChanged) || (!isOGArticleComplianced && (isComplianceOn === 'on' || selectedComplianceCheck === 'yes')))
        sMessage.push('Sent to compliance for approval');
      else 
        sMessage.push(`Thank you for sharing/scheduling this post on ${platformObj[data]}`);
    }
    else {
        if(data === 'mio') sMessage.push('Done!')
        else {
          if(isComplianceOn !== 'on' || approvedArticles) {
            sMessage.push(`Thank you for sharing/scheduling this post on ${platformObj[data]}`);
          }
        }
      }
  });
  
  if (!isAdmin && (selectedFbPage === 0 || selectedFbPage === '' || selectedFbPage === '0') && shareData && shareData.fb && !_.isEmpty(get(shareData.fb, 'data'))) {
    const reqParams = {
      url:
        !_.isEmpty(get(shareData.fb, 'data.params.link')) &&
        encodeURIComponent(shareData.fb.data.params.link),
      summary: encodeURI(shareData.fb.summary),
    };
    window.open(
      `https://facebook.com/sharer/sharer.php?u=${reqParams.url}&quote=${reqParams.summary}`,
      'targetWindow',
      'toolbar=no,location=0,status=no,menubar=no,scrollbars=yes,resizable=yes,width=600,height=250'
    );
  }
  localStorage.setItem('shared', true);
  setIsCreatingContent(false);
  scheduleState.clicked && shareError.length === 0 && setScheduleState({...scheduleState, scheduleIsOpen: true});
  setShareModalMsgs(sMessage);
  shareError.length === 0 && closeModal();
}

const rescheduleApiCall = ({
  scheduleTime,
  platform, 
  summaryData, 
  hasMultipleEmail, 
  socialNetworkData, 
  selectedFbPage, 
  isAdmin, 
  selectedLnPage, 
  isPremiumAdvisor, 
  selectedContactGroup, 
  selectedItem,
  emailTextData, 
  rest,
  selectedLeadCaptureOption, 
  showLeadCaptureOption, 
  selectedReferralCaptureOption,
  isContentChanged
  }) => {
  const summaryTxt = getOverrideText(platform, summaryData);
  let obj = {
    schedule_time: (scheduleTime && (new Date(scheduleTime).getTime() < new Date().getTime())) ? changeScheduleTime(scheduleTime) : scheduleTime === null ? jsonFormattedCurrentTime() : scheduleTime,
    override_text: summaryTxt,
    snetwork_act_id : getAccountId(platform, hasMultipleEmail, socialNetworkData),
    destination_snetwork : selectedItem.destination.network,
    isScheduled: true,
    id: selectedItem.id,
    ...((localStorage.getItem('emailData') && emailTextData.subject !== '') && {email_data: JSON.stringify(emailTextData)}) 
  };

  if((isAdmin || (selectedFbPage !== 0 && selectedFbPage !== '0' && selectedFbPage !== '' && isPremiumAdvisor)) && platform === 'fb') obj.destination_id = selectedFbPage;
  if(platform === 'ln' && selectedLnPage !== 0 && selectedLnPage !== '0' && selectedLnPage !== '') obj.destination_id = selectedLnPage;
  if(platform === 'em') obj.destination_id = selectedContactGroup;

  if(showLeadCaptureOption) {
    if(selectedLeadCaptureOption) {
      obj.lead_gen = 'true';
      obj.referral = 'false';
    } else if(selectedReferralCaptureOption && platform === 'em') {
      obj.referral = 'true';
      obj.lead_gen = 'false';
    } else {
      obj.referral = 'false';
      obj.lead_gen = 'false';
    }
  }
  
  if(selectedItem.is_complianced && isContentChanged) {
    obj.skip_compliance = 'false';
  }
  
  return obj;
}

export const shareApiRequest = ({
  enableShare,
  isAdvisorChecked,
  reqData,
  reqObj,
  socialNetworkData,
  setPlatformObj,
  platformObj,
  isAdmin,
  dateTime,
  advisorReqObj,
  summaryData,
  rest,
  adminChannelId,
  fetchedThemes,
  selectedContactGroup,
  scheduleTime,
  userData,
  selectedComplianceCheck,
  hasMultipleEmail,
  selectedFbPage,
  isPremiumAdvisor,
  emailTextData,
  isAutoPostChecked,
  selectedMembers,
  shareArticleRequest,
  disabledPlatforms,
  premiumAdvisorChannelId,
  isFromAddContent,
  showComplianceCheck,
  fromMyClients,
  contactId,
  selectedDropdownOption,
  selectedCRMContactList,
  selectedLnPage,
  deleteArticleRequest,
  scheduledIds,
  isFromScheduledTab,
  selectedItem,
  approvedArticles,
  isRepost,
  allSumarries,
  selectedLeadCaptureOption,
  showLeadCaptureOption,
  selectedReferralCaptureOption,
  isContentChanged,
  fromContentLibTab,
  isAdminUser,
  isCloning,
  article,
  isOGArticleComplianced,
  isFromImageOnly,
  description
}) => {
  console.log('[DESCRIPTION]...', description);
  // console.log(selectedReferralCaptureOption);
  // console.log('[REQ OBJ] - ', reqObj, '[ADV REQ OBJ] - ', advisorReqObj);
  if (reqObj.length !== 0) {
    let connected = [];
    let nonConnected = [];
    reqObj.map((value) => {
      if (
        (!socialNetworkData[value] ||
          socialNetworkData[value].length === 0) &&
        value !== 'mio'
      ) {
        nonConnected.push(platformObj[value]);
      } else {
        connected.push(platformObj[value]);
      }
    });
    setPlatformObj({
      ...platformObj,
      connected,
      nonConnected,
    });

    reqObj.map((platform) => {
      if(isFromScheduledTab && selectedItem.destination.network === platform) {
        reqData[platform] = rescheduleApiCall({isContentChanged, scheduleTime, platform, summaryData, hasMultipleEmail, socialNetworkData, selectedFbPage, isAdmin, selectedLnPage, isPremiumAdvisor, selectedContactGroup, selectedItem, emailTextData, rest, selectedLeadCaptureOption, showLeadCaptureOption, selectedReferralCaptureOption });
      } else {
        reqData[platform] = shareApiCall(
          platform, isAdmin && !fromMyClients ? dateTime.scheduleTime : dateTime, advisorReqObj, summaryData, rest, adminChannelId,
          fetchedThemes, selectedContactGroup, scheduleTime, userData, selectedComplianceCheck,
          hasMultipleEmail, selectedFbPage, isPremiumAdvisor, emailTextData, isAdmin, isAutoPostChecked, socialNetworkData,
          premiumAdvisorChannelId, isFromAddContent, showComplianceCheck, selectedLnPage, deleteArticleRequest,
          scheduledIds, isFromScheduledTab, fromMyClients, contactId, approvedArticles, isRepost, allSumarries, selectedDropdownOption, selectedCRMContactList,
          selectedLeadCaptureOption, showLeadCaptureOption, selectedReferralCaptureOption, isCloning, article, isContentChanged, isOGArticleComplianced, isFromImageOnly
        );
      }
    });
    console.log('[REQ DATA] - ', reqData);
    if(advisorReqObj.length !== 0){
      console.log('***** BLOCK 1 ******');
      reqData.mio = contentCreateUpdate({rest, adminChannelId, summaryData, userData, selectedComplianceCheck, isFromAddContent, isAutoPostChecked, advisorReqObj, emailTextData, allSumarries, selectedLeadCaptureOption, showLeadCaptureOption, selectedReferralCaptureOption, fromContentLibTab, isAdminUser, description });
      reqData.mio.autoshare_on_subscribers_snetworks_datetime = dateTime.advisorScheduleTime;
    }
    console.log('[before IF(REQDATA.MIO)] - ');
    if(reqData.mio) {
      console.log('[inside IF(REQDATA.MIO)] - ');
      toDataURL(rest.url, function(dataUrl) {
        if(dataUrl){
          reqData.mio.url = dataUrl;
        }
        if(isAutoPostChecked) {
          reqData.mio.autoshare_on_subscribers_snetworks = advisorReqObj;
          reqData.mio.autoshare_on_subscribers_snetworks_datetime = dateTime.advisorScheduleTime;
          reqData.mio.autoshare_on_subscriber_ids = selectedMembers.includes('selectAll') ? 'all' : selectedMembers.length === 0 ? 'none' : selectedMembers.join(',');
          reqData.mio.sharing=true 
        }

        shareArticleRequest(reqData)     
      });
    } else {
      console.log('[inside else(REQDATA.MIO)] - ');
      shareArticleRequest(reqData);
    } 
  }else if(advisorReqObj.length !== 0 && enableShare){
    console.log('[inside ELSE IF(ADV REQ OBJ.LENGTH] - ');
    console.log('***** BLOCK 2 ******')
    const data = {mio: contentCreateUpdate({rest, adminChannelId, summaryData, userData, selectedComplianceCheck, isFromAddContent, emailTextData, allSumarries, selectedLeadCaptureOption, showLeadCaptureOption, selectedReferralCaptureOption, fromContentLibTab, isAdminUser, description })};
    toDataURL(rest.url, function(dataUrl) {
      if(dataUrl){
        data.mio.url = dataUrl;
      }
      if(isAutoPostChecked) {
        advisorReqObj = isAdvisorChecked.All && disabledPlatforms && disabledPlatforms.length > 0 ?  _.difference(advisorReqObj, disabledPlatforms) : advisorReqObj;
        data.mio.autoshare_on_subscribers_snetworks = advisorReqObj;
        data.mio.autoshare_on_subscribers_snetworks_datetime = dateTime.advisorScheduleTime;
        data.mio.autoshare_on_subscriber_ids = selectedMembers.includes('selectAll') ? 'all' : selectedMembers.length === 0 ? 'none' : selectedMembers.join(',');
        data.mio.sharing=true 
      }
      shareArticleRequest(data);
    });
  }
}