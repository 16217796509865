import { Box, Flex } from '@rebass/grid';
import React, { useEffect } from 'react';
import {
  Line,
  WhiteCircle,
} from '../../../components/StepperV2/Stepper.styles';

const StepTwoHeaderPart = () => {
  return (
    <Flex width="100%" alignItems="center" style={{ position: 'relative' }}>
      <Flex width="3%" flexDirection="column">
        <WhiteCircle
          background="#E4E7EC"
          height="16px"
          width="16px"
          style={{ border: 'none' }}
        />
        <Line style={{ left: '6px', top: '20px' }} />
      </Flex>
      <Flex width="52%" flexDirection="column" paddingLeft="15px" paddingTop="10px">
        <Flex
          width="95%"
          color="#101828"
          fontSize="16px"
          style={{ fontWeight: 500 }}
        >
          Create your campaign
        </Flex>
        <Flex
          width="90%"
          fontSize="14px"
          color="#667085"
          style={{ textAlign: 'initial' }}
        >
          Start by selecting "Add New Block" to create a new email or social post. Once finished, be sure to select Next and review all campaign details before finalizing.
        </Flex>
      </Flex>
    </Flex>
  );
};

export default StepTwoHeaderPart;
