import styled from 'styled-components';
import {
  StyledInput,
  ButtonNoBorderBackground,
  VerticalTab,
  Button,
} from '../../../components';

import { themesData } from '../../../utils';

export const FeedInput = styled(StyledInput)`
  width: 100%;
  background-color: #fff;
  border: 1px solid #707070;
  border-radius: 4px;
  text-align: center;
  padding-left: 35px;

  &:read-only {
    background-color: #e4e4e4;
  }
  &&::placeholder{
    color: #666666;
  }
`;

export const FeedWrapper = styled.div`
  width: 95%;
  box-sizing: border-box;
  // padding: 12px 0px;
  margin: 0 auto;
  font-size: 14px;
`;

export const InputWrapper = styled.div`
  width: 80%;
  margin: 0 auto;
  position: relative;
  #post-icon,
  .fileUploaderButton {
    position: absolute;
  }
  #post-icon {
    left: 10px;
    height: 20px;
    width: 20px;
    top: 5px;
  }
  .fileUploaderButton {
    right: 10px;
    width: 20px;
    height: 20px;
    background: transparent;
    border: 0;
    margin: 0px;
    padding: 0;
    top: 7px;
    :hover {
      background: transparent;
      color: #6351ed;
    }
    img {
      height: auto;
      width: 20px;
    }
  }
`;

export const RssFeedButton = styled(Button)`
  width: ${({ width }) => width || '100px;'}; 
  height: 30px;
  margin: ${({ margin }) => margin || ''};
  background: ${({background}) => background || ''};
  color: ${({color}) => color || ''};
  border-radius: 0px;
`;

export const AddContentTab = styled(VerticalTab)`
  background-color: #ececec;
  cursor: pointer;
  color: #49484a;
  // &:first-child {
  //   border-radius: 8px 0px 0px 0px;
  // }

  &:last-child {
    border-radius: 0px 0px 0px 8px;
  }

  &.react-tabs__tab--selected {
    background-color: ${({}) => themesData.purpleishBlue || '#e6e6e6'};
    // min-height: 60%;
    color: white;
  }
`;
