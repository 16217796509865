import { Flex } from '@rebass/grid';
import React from 'react';
import styled from 'styled-components';

const ChipStyles = styled(Flex)`
display: flex;
width: ${({ width }) => width || '20%'};
flex-direction: row;
height: ${({ height}) => height || '70px'};
margin-right: ${({ mr }) => mr || '15px'};
border: ${({ border }) => border || '1px solid'};
border-color: ${({ borderColor }) => borderColor || '#E4E7EC'};
box-shadow: ${({ boxShadow }) => boxShadow || ''};
border-radius: ${({ borderRadius }) => borderRadius || '4px'};
background-color: ${({ bgColor }) => bgColor || '#fff'};
margin-bottom: ${({ mb }) => mb || '15px'};
padding: ${({ padding }) => padding || '0px'};
justify-content: ${({ justifyContent }) => justifyContent || ''};
`;

const Chip = ({
    children,
    bgColor,
    borderColor,
    borderRadius,
    height,
    width,
    mb,
    mr,
    border,
    padding,
    justifyContent,
    color,
    fontSize,
    boxShadow
}) => {
  return (
    <ChipStyles 
      fontSize={fontSize} 
      color={color} 
      bgColor={bgColor} 
      borderColor={borderColor} 
      borderRadius={borderRadius} 
      width={width} 
      height={height} 
      mb={mb} 
      mr={mr} 
      border={border} 
      padding={padding} 
      justifyContent={justifyContent}
      boxShadow={boxShadow}
    >
        {children}
    </ChipStyles>
  )
};

export default Chip;