import styled from 'styled-components';
import { colors } from '../../styles';

export const StyledFileUploadWrapper = styled.div`
  display: flex;
  justify-content: ${(props) => (props.asButton ? 'center' : 'flex-start')};
  button {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: ${(props) => (props.asButton ? '4px' : props.borderRadius || '8px')};
    font-size: ${(props) => (props.asLabel ? '15px' : props.fontSize || '14px')};
    padding: ${(props) =>
      props.asLabel ? '0px' : props.asButton ? '5px 10px' : props.padding || '7px 20px'};
    border: ${(props) => (props.asLabel ? 'none' : props.border ||'1px solid #a0a0a0')};
    background-color: ${(props) => (props.asLabel ? '#e4e4e5' : props.asMioEditorButton ? '#6351ed' : props.background || '#eeeeee')};
    font-weight: ${(props) => (props.asLabel ? '600' : '400')};
    margin: ${(props) =>
      props.fromInviteClients ? '0 17px 0 0' : props.margin ? props.margin : props.asLabel ? '10px 0px 0px' : props.asButton ? '10px 0 0 0' : '0px'};
    float: ${(props) => (props.asLabel ? 'left' : 'none')};
    width: ${(props) => (props.asButton ? '130px' : props.width || 'auto')};
    font-family: 'Poppins';
    color: ${(props) => (props.asMioEditorButton ? '#eeeeee' : props.color ||'#6351ed')};
    cursor: pointer;
    height: ${({ height }) => height || ''};
    &:hover {
      background-color: ${(props) => (props.asLabel ? 'transparent' : '#eee')};
      color: ${(props) =>
        props.asLabel ? '#6351ed' : props.asButton ? '#6351ed' : 'initial'};
    }
    &:before {
      padding-right: 4px;
      font-size: 18px;
    }
  }
`;

export const BrowseFiles = styled('label')`
  font-family: 'Poppins';
  display: flex;
  cursor: pointer;
  justify-content: space-between;
  margin: 0px 4px 0px 0px;
  font-size: 13px;
  padding: 5px 20px;
  background-color: #6351ed;
  color: #ffffff;
  border: 1px solid #6351ed;
`;

export const UploadHeader = styled('p')`
  font-family: 'Poppins';
  font-size: 14px;
  color: #404B50;
  display: flex;
`;

export const Underlined = styled('label')`
  font-family: 'Poppins';
  font-size: 10px;
  color: #6351ed;
  text-decoration: underline;
  cursor: pointer;
  font-weight: bold;
  background: white;
`;

export const WhiteButton = styled('label')`
  font-family: 'Poppins' !important;
  background: ${({ disabled }) => disabled ? '#F2F4F7' : colors.white};
  border: ${({ disabled }) => disabled ? `1px solid ${colors.hover_background}` : `1px solid ${colors.purple_border_light}`};
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.12);
  border-radius: 4px;
  cursor: ${({ disabled }) => disabled ? 'text' : 'pointer'};
  color: ${({ disabled }) => disabled ? '#98A2B3' : colors.header_purple};
  display: flex;
  justify-content: space-between;
  margin: 1px auto;
  text-transform: none;
  font-size: 14;
  font-weight: 500;
  padding: 10px 14px 5px;
  white-space: nowrap;
  height: 40px;
  text-align: center;
  transition: 0.5s;
  :hover {
    background: ${({ disabled }) => disabled ? colors.hover_background : colors.off_white_background};
  }
`;

export const ButtonIcon = styled('img')`
  height: 20px;
  width: 20px;
  margin: 0px 10px 0 0;
`;
