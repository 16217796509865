import * as React from "react";

function Users(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 36 36" fill="none" {...props}>
      <path
        d="M12.375 22.5a7.312 7.312 0 100-14.625 7.312 7.312 0 000 14.625z"
        stroke="currentColor"
        strokeWidth={2.4}
        strokeMiterlimit={10}
      />
      <path
        d="M21.853 8.142a7.664 7.664 0 011.983-.267 7.312 7.312 0 110 14.625M2.25 27.76a12.375 12.375 0 0120.25 0M23.836 22.5a12.36 12.36 0 0110.125 5.26"
        stroke="currentColor"
        strokeWidth={2.4}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

const MemoUsers = React.memo(Users);
export default MemoUsers;
