const initialState = {
    form: {
        media1: '',
        footer: '',
        signature: '',
        publication_date: false
    }
}

const reducer = (state=initialState, action) => {
    switch(action.type) {
        case 'FETCH_EDITOR_SETTINGS_SUCCESS': 
            console.log(action.payload);
            return {
                ...state,
                form: action.payload
            }
        default: return state
    }
}

export default reducer;