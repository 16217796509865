import React from 'react';
import { Flex } from '@rebass/grid';
import {
  Button,
  ButtonNoBorderBackground,
  LoaderWrapper,
} from '../../../components';
import { AutomatedCampaignStepsFooterSection } from '../styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router-dom';

const StepsFooter = ({
  requestLoading,
  currentStepIndex,
  setCurrentStepIndex,
  disableNext,
  disableSaveAndClose,
  handleNext,
  handleSaveAndClose,
  isEditMode,
  campaignSetState,
  endCreateFlow
}) => {
  const history = useHistory();
  return (
    <AutomatedCampaignStepsFooterSection>
      <LoaderWrapper isLoading={requestLoading}>
        <Flex>
          <Button
            styles={{ borderRadius: '4px' }}
            color="#fff"
            bgColor="#4E6780"
            margin="0px 10px"
            onClick={() => handleSaveAndClose()}
            disabled={disableSaveAndClose}
            id="btn_campaign_save_close"
          >
            Save & Close
          </Button>
        </Flex>
        <Flex>
          <ButtonNoBorderBackground
            color="#000000"
            onClick={() => {
              if (currentStepIndex === 0) {
                campaignSetState({ automatedDashboard: true });
                endCreateFlow();
                history.push('/campaignV2');
                
              } // Reload the create page.
              setCurrentStepIndex(currentStepIndex - 1);
            }}
          >
            <FontAwesomeIcon icon={faChevronLeft} />
            &nbsp; Back &nbsp; &nbsp;
          </ButtonNoBorderBackground>

          {currentStepIndex !== 2 && (
            <Button
              styles={{ borderRadius: '4px' }}
              color="#fff"
              bgColor="#4E6780"
              margin="0px 10px"
              onClick={() => handleNext()}
              disabled={disableNext}
              id="btn_campaign_next"
            >
              Next
            </Button>
          )}
        </Flex>
      </LoaderWrapper>
    </AutomatedCampaignStepsFooterSection>
  );
};

export default StepsFooter;
