/* eslint-disable no-useless-escape */
import { keys, get, padStart, findKey, split, isEmpty } from 'lodash';
import { saveAs } from 'file-saver';
import qs from 'query-string';
import { parse } from 'json2csv';
import { company } from './constants';
import { salesforceSettingsMapper, salesforceTogglesMapper } from '.';
import CryptoJS from 'crypto-js';
import moment from 'moment';

export const getMediaUrl = (media, source, type) => {
  // console.log('media', media);
  if (media && media.length > 0 && media[0]) {
    const mediaDetails = get(media, '[0][0]', get(media, '[0]', ''));
    // console.log('mediaDetails', mediaDetails);

    if (type === 'original') {
      return `${mediaDetails.path}${get(mediaDetails, 'name.system')}.${
        mediaDetails.ext
      }`;
    } else {
      return `${mediaDetails.path}${get(mediaDetails, 'name.resized')}.${
        mediaDetails.ext
      }`;
    }
  } else if (source === 'pdf') {
    return ``;
  } else {
    return '';
  }
};

export const getPdfUrl = (media, source) => {
  if (media && media.length) {
    const mediaDetails = get(media, '[0][0]', get(media, '[0]', ''));

    return `${mediaDetails.path}${get(mediaDetails, 'name.system')}.${
      mediaDetails.ext
    }`;
  } else if (source === 'pdf') {
    return `/assets/images/PDF_icon.png`;
  } else {
    return '';
  }
};

export const getMediaImage = (media, type = '') => {
  let mode = 'name.resized';

  if (type === 'original') mode = 'name.system';

  if (media && !isEmpty(media) && !Array.isArray(media)) {
    return `${media.path}${get(media, mode)}.${media.ext}`;
  } else if (media && !isEmpty(media) && Array.isArray(media)) {
    return `${media[0].path}${get(media[0], mode)}.${media[0].ext}`;
  } else {
    const randomNumber = Math.floor(Math.random() * 4) + 1;
    return `https://apicdn.myclout.com/assets/emailer/postDefault${randomNumber}.jpg`;
  }
};

export const getLpImage = (media) => {
  if (media && !isEmpty(media) && Array.isArray(media)) {
    return `${media[0].path}`;
  } else {
    const randomNumber = Math.floor(Math.random() * 4) + 1;
    return `https://apicdn.myclout.com/assets/emailer/postDefault${randomNumber}.jpg`;
  }
  
}

export const getMonthDate = (date, type) => {
  let formattedDate;
  if (type && type === 'scheduled') {
    formattedDate = date + 'T00:00:00';
  } else {
    formattedDate = date;
  }
  const jsDate = new Date(formattedDate);
  const monthMapping = {
    0: 'Jan',
    1: 'Feb',
    2: 'Mar',
    3: 'Apr',
    4: 'May',
    5: 'June',
    6: 'Jul',
    7: 'Aug',
    8: 'Sep',
    9: 'Oct',
    10: 'Nov',
    11: 'Dec',
  };
  const month = monthMapping[jsDate.getMonth()];
  const day = jsDate.getDate();
  const fullYear = jsDate.getFullYear();
  return [month, day, fullYear];
};

export const getDayMonthYear = (date) => {
  const jsDate = new Date(date);
  const month = jsDate.getMonth() + 1;
  const day = jsDate.getDate();
  const fullYear = jsDate.getFullYear();
  return [padStart(month, 2, 0), padStart(day, 2, 0), fullYear];
};

export const dashboardSliderSettings = {
  infinite: false,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 4,
  accessibility: false,
  draggable: false,
  //lets keep it, might be helpful
  // rows: window.innerWidth < 768 ? 4 : 1,
  responsive: [
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

export const overviewTabSliderSettings = {
  infinite: false,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 3,
  accessibility: false,
  draggable: false,
  centerMode: false,
  //lets keep it, might be helpful
  // rows: window.innerWidth < 768 ? 4 : 1,
  responsive: [
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

export const transformDonutData = (data) => {
  return data.map((item) => ({
    value: item.value === 0 ? 3 : item.value,
    name: item.name,
  }));
};

export const transformMemberTableData = (data) => {
  return (data || []).map((item) => ({
    name: item.member_name,
    score: item.member_score,
  }));
};

export const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export const formatMomentIsoDate = (momentDate) =>
  momentDate.format('YYYY-MM-DD');

export const parseAnalyticsDataForCharts = (data, repType) => {
  const isPlatform = get(data, 'analytics.platform');
  const isChannel = get(data, 'analytics.channel');
  const total = get(data, 'analytics.total');
  const isInsightScore = get(data, 'analytics.score');
  const isUsers = get(data, 'details.users');
  const tranformedData = [];
  const previousObject = get(data, 'analytics.previous', {});
  let previousValue = 0;
  if (isPlatform) {
    const platformAnalytics = get(data, 'analytics.platform', {});
    if (!platformAnalytics.fb) {
      platformAnalytics.fb = { mine: 0 };
      const engagementExist = get(platformAnalytics, `tw.engagement`);
      if (engagementExist) {
        platformAnalytics.fb = {
          engagement: { mine: { like: 0, comment: 0 } },
        };
      }
    }
    Object.keys(platformAnalytics).forEach((key) => {
      const isEngagement = get(platformAnalytics, `${key}.engagement`);
      if (isEngagement) {
        const myEnganements = get(isEngagement, 'mine', {});
        const otherEnganements = get(isEngagement, 'others', {});
        const engagementActions =
          Object.keys(myEnganements).length >
          Object.keys(otherEnganements).length
            ? myEnganements
            : otherEnganements;
        Object.keys(engagementActions).forEach((action) => {
          tranformedData.push({
            id: action,
            total: get(otherEnganements, action, 0),
            value: get(myEnganements, action, 0),
            name: action,
            platform: key,
          });
        });
      } else {
        const currentAnalyticData = platformAnalytics[key];
        tranformedData.push({
          id: key,
          total: currentAnalyticData.others,
          value: currentAnalyticData.mine || 0,
          name: key,
        });
      }
    });
    previousValue =
      get(previousObject, 'mine', 0) + get(previousObject, 'others', 0);
  } else if (isChannel) {
    const channelAnalytics = get(data, 'analytics.channel', {});
    const details = get(data, 'details.channel', {});
    if (['user_activity_by_partner'].includes(repType)) {
      Object.keys(channelAnalytics).forEach((key) => {
        const currentAnalyticData = channelAnalytics[key];
        tranformedData.push({
          id: key,
          value: currentAnalyticData,
          name: details[key].name,
        });
        previousValue =
          get(previousObject, 'mine', 0) + get(previousObject, 'others', 0);
      });
    } else {
      Object.keys(channelAnalytics).forEach((key) => {
        const currentAnalyticData = channelAnalytics[key];
        tranformedData.push({
          id: key,
          total: currentAnalyticData.others,
          value: currentAnalyticData.mine || 0,
          name: details[key].name,
        });
      });
    }
    previousValue =
      get(previousObject, 'mine', 0) + get(previousObject, 'others', 0);
  } else if (isInsightScore) {
    const channelAnalytics = get(data, 'analytics.score.current', {});
    Object.keys(channelAnalytics).forEach((key, index) => {
      const insightScoreData = channelAnalytics[key];
      tranformedData.push({
        id: index,
        value: insightScoreData,
        name: key,
      });
    });
    previousValue =
      get(previousObject, 'mine', 0) + get(previousObject, 'others', 0);
  } else if (isUsers) {
    const memebersAnalytics = get(data, 'analytics.theme', {});
    const themeDetails = get(data, 'details.theme', {});
    const userDetails = get(data, 'details.users', {});

    Object.keys(userDetails).forEach((userId, index) => {
      const themeId = findKey(memebersAnalytics, (users) => {
        return get(users, `users.${userId}`);
      });
      tranformedData.push({
        id: userId,
        name: get(userDetails, `${userId}.name`),
        themeName: get(themeDetails, `${themeId}.name`),
      });
    });
    previousValue =
      get(previousObject, 'mine', 0) + get(previousObject, 'others', 0);
  } else {
    const analytics = get(data, 'analytics.theme', {});
    const details = get(data, 'details.theme', {});

    if (['user_activity_by_topic'].includes(repType)) {
      Object.keys(analytics).forEach((key) => {
        const currentAnalyticData = analytics[key];
        tranformedData.push({
          id: key,
          value: currentAnalyticData,
          name: details[key].name,
        });
        previousValue = previousObject;
      });
    } else {
      Object.keys(analytics).forEach((key) => {
        const currentAnalyticData = analytics[key];
        tranformedData.push({
          id: key,
          total: currentAnalyticData.others,
          value: currentAnalyticData.mine || 0,
          name: details[key].name,
        });
        previousValue =
          get(previousObject, 'mine', 0) + get(previousObject, 'others', 0);
        previousValue = previousValue === 0 ? previousObject : previousValue;
      });
    }
  }
  let slicedData = tranformedData.sort((a, b) => b.value - a.value).slice(0, 5);
  return {
    data: tranformedData,
    slicedData: slicedData.filter((s) => s.value > 0 || s.total > 0),
    previousValue,
    total,
  };
};

export const getTabCount = (tabData, previousValue, isAdmin) => {
  const tabCount = (tabData || []).reduce((tot, curr) => {
    if (isAdmin) {
      return tot + parseInt(get(curr, 'value', 0));
    } else {
      return (
        tot +
        (parseInt(get(curr, 'total', 0), 10) + parseInt(get(curr, 'value', 0)))
      );
    }
  }, 0);
  const trend = previousValue
    ? ((tabCount - previousValue) / previousValue) * 100
    : 0;
  return {
    tabCount,
    trend: Math.round(trend) || 0,
  };
};

export const getActivityTabCount = (tabCount, previousValue) => {
  const trend = previousValue
    ? ((tabCount - previousValue) / previousValue) * 100
    : 0;

  return {
    tabCount,
    trend: Math.round(trend) || 0,
  };
};

export const newTabCount = (total, previousValue, isAdvisor) => {
  let tabCount = 0;
  console.log(total);
  console.log(previousValue);
  if (total && Object.keys(total).length > 0 && !isAdvisor) {
    tabCount =
      get(total, 'tw.mine', 0) +
      get(total, 'fb.mine', 0) +
      get(total, 'ln.mine', 0) +
      get(total, 'em.mine', 0);
  } else if (total && Object.keys(total).length > 0) {
    tabCount =
      get(total, 'tw', 0) +
      get(total, 'fb', 0) +
      get(total, 'ln', 0) +
      get(total, 'em', 0);
  }

  const trend = previousValue
    ? ((tabCount - previousValue) / previousValue) * 100
    : 0;

  return {
    tabCount,
    trend: Math.round(trend) || 0,
  };
};

export const parseValueToCsv = (data = [], repType = '', isAdvisor) => {
  const isPlatform = repType.indexOf('platform') !== -1;
  const column1Name = isPlatform ? 'Platform' : 'Topic';
  const column2Name = isPlatform ? 'My Content Shares' : 'My Content';
  const column3Name = isPlatform ? 'Other Content Shares' : 'Other Content';
  const fields = [column1Name, column2Name];

  if (!isAdvisor) {
    fields.push(column3Name);
  }
  const csvData = parse(
    data.map((item) => ({
      [column1Name]: item.name,
      [column2Name]: item.value,
      ...(!isAdvisor && { [column3Name]: item.total }),
    })),
    {
      fields,
    }
  );
  return csvData;
};

export const saveFile = (data, fineName, ext = 'csv') => {
  const typesMapping = {
    csv: 'text/csv;charset=ISO-8859-1',
    pdf: 'application/pdf',
    txt: 'text/plain;charset=utf-8',
  };

  var blob = new Blob([data], { type: typesMapping[ext] });
  saveAs(blob, `${fineName}.${ext}`);
};

export const getTabNameFromRepType = (repType) => {
  const repTypeMapping = {
    em: 'Email',
    tw: 'Twitter',
    ln: 'LinkedIn',
    fb: 'Facebook',
    reach_by_topic: 'TopicReach',
    reach_by_platform: 'PlatformReach',
    click_by_topic: 'TopicClick',
    click_by_platform: 'PlatformClick',
    topic_analysis: 'MostSubscribed',
    most_shared: 'MostShared',
    user_topic_reach: 'TopicReach',
    user_reach_by_platform: 'PlatformReach',
    user_partner_reach: 'PartnerReach',
    user_topic_click: 'TopicClick',
    user_click_by_platform: 'PlatformClick',
    user_network_engagement: 'NetworkEngagement',
    email_share_by_leads: 'EmailShareByLeads',
    email_share_by_topics: 'EmailShareByTopics',
    email_share_overview: 'EmailShareOverview',
    newsletter_share_by_articles_webinar: 'Newsletter',
  };

  return repTypeMapping[repType];
};

export const getApprovedThemeList = (themesList) =>
  themesList.filter((theme) => {
    return get(theme, 'subscription.status.application_status') === 'approved';
  });

export const getSpecificThemeItem = (themesList, id) =>
  themesList.filter((theme) => {
    return get(theme, 'id') === id;
  });

export const extractChannelListForSearch = (channelList) => {
  return channelList.filter((channel) => {
    return get(channel, 'is_hidden') === 'false';
  });
};

export const getCsvBase64 = (pasrsedString) => {
  const csvBlob = new Blob([pasrsedString], { type: 'text/csv' });
  const csvFile = new File([csvBlob], 'sample.csv', {
    type: 'text/csv',
    lastModified: new Date(),
  });
  return csvFile;
};

export const createCsvForHubspotInvite = (emailList = [], role) => {
  const transformedData = parse(
    emailList.map((item) => ({
      first_name: item.first || '',
      last_name: item.last || '',
      email: item.email,
      role,
      mobile: '',
    })),
    {
      fields: ['first_name', 'last_name', 'email', 'role', 'mobile'],
    }
  );
  return getCsvBase64(transformedData);
};

export const createCsvFileFromJson = (emailList = [], role) => {
  const emailListArray = split(emailList, ',');
  const arr = emailListArray.map((email) => ({
    first_name: '',
    last_name: '',
    email,
    role,
    mobile: '',
  }));
  console.log('ARR = ', arr);
  const transformedData = parse(arr, {
    fields: ['first_name', 'last_name', 'email', 'role', 'mobile'],
  });
  return getCsvBase64(transformedData);
};

export const emptyMessages = {
  searchTab: 'No search results found',
  myMioTab: `Start sharing articles from the web by sending them to share@my${company.name}.com`,
  scheduledTab:
    'Content that you have scheduled to share at a future date appears here',
  sharedTab: 'No articles Shared yet',
  investmentsTab: 'No articles found',
  contentLibraryTab: 'to get started',
  recentTab: 'No articles shared yet',
  exploreTab: 'Please select a topic',
  mioEditorTab: 'No articles found',
  scheduledByMioTab: `Content that ${company.name} has scheduled to share at a future date appears here`,
  scheduledByCompanyTab:
    'Content that your company has scheduled to share at a future date appears here',
  default: 'No articles found',
  notAddedByAdmin: 'Admin has not added any content',
  notAddedByPremiumAdvisor: 'Add your own content',
};

export const themeNameIdMapping = (data) => {
  const themeMapping = {};
  data.forEach((item) => {
    themeMapping[item.id] = item.name;
  });
  return themeMapping;
};

export const getThemesObjectsFromFetched = (response) => {
  const selectedThemeList = [];
  const themeVertical = get(response, 'themes.vertical');
  keys(themeVertical).forEach((k1) => {
    const k1Object = themeVertical[k1];
    keys(k1Object).forEach((k2) => {
      if (get(k1Object, `${k2}.frequency`) >= 3) {
        selectedThemeList.push({ ...k1Object[k2], name: k2 });
      }
    });
  });
  if(!isEmpty(selectedThemeList)) {
    return selectedThemeList.sort((a, b) => b.frequency - a.frequency).slice(0, 4); 
  } else {
    return [];
  }
 
};

export const clearSeassionStorage = (oktaAuth) => {
  oktaAuth.signOut();
  sessionStorage.clear();
  window.location.href = '/';
};

export const getSocialAccountStatusData = (data) => {
  const accountData = {};
  Object.keys(data).forEach((key) => {
    const socialAccount = data[key];
    accountData[key] = [];
    if (key === 'sg') {
      if (!Array.isArray(socialAccount)) accountData[key].push(socialAccount);
    } else {
      // console.log('SOCAIL ACCOUNT - ', socialAccount);
      Object.keys(socialAccount).forEach((accountId) => {
        const tokenDetails = get(socialAccount[accountId], 'token', {});
        const snetworkProvider = get(tokenDetails, 'snetwork_provider', '');
        const isExpired = get(tokenDetails, 'expired', true);
        const details = get(socialAccount[accountId], 'details', {});
        const page = get(socialAccount[accountId], 'accounts_pages', {});
        !isEmpty(page) &&
          !isEmpty(Object.keys(page)) &&
          Object.keys(page).forEach((item, key) => {
            page[item].id = item;
          });
        if (!isExpired) {
          accountData[key].push({
            accountId,
            ...details,
            snetworkProvider,
            page,
          });
        }
      });
    }
  });
  return accountData;
};

export const getCookie = (cname = 'userToken') => {
  let name = cname + '=';
  console.log('=##= document.cookie', document.cookie);
  const ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
};

export const setToken = () => {
  // const cookie = getCookie('userToken');
  // const tokenFromSession = sessionStorage.getItem('token');

  // console.log('=##= cookie', cookie);
  // if (!tokenFromSession) {
  //   console.log('=##= invalid');
  //   if (cookie) {
  //     console.log('=##= in cook');
  //     sessionStorage.setItem('token', cookie);
  //   } else {
  //     console.log('=##= falied');
  //     // redirectToLogin();
  //   }
  // }
  let authToken = '';
  const userToken = get(
    qs.parse(qs.extract(window.location.href)),
    'userToken'
  );

  const generalToken = get(
    qs.parse(qs.extract(window.location.href)),
    'generalToken'
  );

  const superAdminEmail = get(
    qs.parse(qs.extract(window.location.href)),
    'super_admin_email'
  );


  if ((userToken?.indexOf('#') > -1 || !generalToken)) {
    authToken = (userToken || '').replace(/#/g, '').slice(0, -1);
  } else {
    authToken = userToken;
  }

  if (generalToken) {
    const advisorToken = (generalToken || '').replace(/#/g, '').slice(0, -1);
    sessionStorage.setItem('advisorToken', advisorToken);
  }

  if (superAdminEmail) {
    sessionStorage.setItem('superAdminEmail', superAdminEmail);
  }

  sessionStorage.setItem('token', authToken);
  return authToken;
};

export const redirectToLogin = () => {
  // window.location = '/login';
  sessionStorage.clear();
  window.location.href = window.location.origin + '/login';
};

export const onImageError = (e, source) => {
  const el = e.target;
  const randomNumber = Math.floor(Math.random() * 4) + 1;
  let imgSrc = `/assets/images/postDefault${randomNumber}.jpg`;
  // if (source === 'pdf') {
  //   imgSrc = `/assets/images/PDF_icon.png`;
  // }
  el.src = imgSrc;
};

export const getContactListForEmail = (contactList = []) => {
  let contacts = contactList.filter((contact) => {
    return (
      ![
        'GMAIL',
        'HUBSPOT',
        'OUTLOOK',
        'WEALTHBOX',
        'SALESFORCE',
        'REDTAIL',
      ].includes(get(contact, 'name')) && get(contact, 'contacts.count', 0) > 0
    );
  });
  let sortByType = contacts.sort((a, b) => {
    let fa = a.type.toLowerCase(),
      fb = b.type.toLowerCase();

    if (fa < fb) {
      return -1;
    }
    if (fa > fb) {
      return 1;
    }
    return 0;
  });

  // console.log(sortByType);
  //let sortedContactList = sortBy(sortByType, (c) => c.is_default);
  //console.log('sortedContactList sorting data', sortedContactList);
  return sortByType;
};

export const getContactListDefaultState = (contactList = [], contactListId) => {
  let contacts = contactList.filter((contact) => {
    return (
      !['GMAIL', 'HUBSPOT', 'OUTLOOK', 'WEALTHBOX', 'SALESFORCE'].includes(
        get(contact, 'name')
      ) && get(contact, 'contacts.count', 0) > 0
    );
  });
  if (isEmpty(contacts)) {
    return '';
  } else {
    let defaultState = contacts.find((c) => c.id === contactListId);
    if (defaultState === undefined) {
      return false;
    } else {
      return defaultState.is_default;
    }
  }
};

export const getAuxilaryChannelId = (artcle) => {
  let originalContentId = get(
    artcle,
    'auxiliary.original_content.ids.channel[0].id'
  );
  if (originalContentId && originalContentId.length > 0) {
    return get(artcle, 'auxiliary.original_content.ids.channel[0].id');
  } else if (get(artcle, 'ids.channel.id')) {
    return get(artcle, 'ids.channel.id');
  } else {
    return get(artcle, 'ids.channel[0]');
  }
};

export const getQuotedText = (summaryList = [], platform) => {
  return (summaryList || []).map(({ summary }) => {
    if (!summary) {
      return '';
    }
    if (platform === 'tw') {
      return '';
    }
    const firstQuoteIndex = summary.indexOf('-');
    if (firstQuoteIndex === -1) {
      return summary.replace(/&quot;/g, '"');
    }
    const lastQuoteIndex = summary.lastIndexOf('-');
    const subSet2 = summary
      .substring(firstQuoteIndex + 1, lastQuoteIndex)
      .replace(/\\/g, '');
    return subSet2;
  });
};

export const replaceSpanWithQuate = (summary = '', platform) => {
  let txt;
  let span = document.createElement('span');
  span.innerHTML = summary;
  console.log('[REPLACE FUNCTION TXT]:before - ', summary);
  txt = summary
    .replace(/<span [a-zA-z0-9(),;.:!#=\/%'" -]*>/gm, '')
    .replace(/<\/span>/gm, '')
    .replace(/<object [a-zA-z0-9(),;.:!#=\/%'" -]*>/gm, '')
    .replace(/<\/object>/gm, '')
    .replace(/<font [a-zA-z0-9(),;.:!#=\/%'" -]*>/gm, '')
    .replace(/<\/font>/gm, '')
    .replace(/<h[123456] [a-zA-z0-9(),;.:!#=\/%'" -]*>/gm, '')
    .replace(/<\/h[1234567]>/gm, '')
    .replace(/<p [a-zA-z0-9(),;.:!#=\/%'" -]*>/gm, '')
    .replace(/<\/p>/gm, '')
    .replace(/<strong [a-zA-z0-9(),;.:!#=\/%'" -]*>/gm, '')
    .replace(/<\/strong>/gm, '')
    .replace(/&amp;/gm, '&')
    .replace(/<*>*<\/*>/g, ' ')
    .replace(/<div>/g, '\n')
    .replace(/<\/div>/g, '')
    .replace(/<br>/g, '')
    .replace(/&nbsp;/g, ' ');
  console.log('[REPLACE FUNCTION TXT]:after - ', txt);
  return txt;
};

export const getFideatorScore = (score) => {
  if (`${score}` === '0') {
    return '100%';
  }

  if (score % 25 === 0) {
    return '10%';
  }

  const normalisedScore = score % 25;
  if (normalisedScore >= 0 && normalisedScore <= 2.5) {
    return '100%';
  } else if (normalisedScore > 2.5 && normalisedScore <= 5) {
    return '90%';
  } else if (normalisedScore > 5 && normalisedScore <= 7.5) {
    return '80%';
  } else if (normalisedScore > 7.5 && normalisedScore <= 10) {
    return '70%';
  } else if (normalisedScore > 10 && normalisedScore <= 12.5) {
    return '60%';
  } else if (normalisedScore > 12.5 && normalisedScore <= 15) {
    return '50%';
  } else if (normalisedScore > 15 && normalisedScore <= 17.5) {
    return '40%';
  } else if (normalisedScore > 17.5 && normalisedScore <= 20) {
    return '30%';
  } else if (normalisedScore > 20 && normalisedScore <= 22.5) {
    return '20%';
  } else if (normalisedScore > 22.5 && normalisedScore <= 25) {
    return '10%';
  }
};

export const sortArticleByDate = (articles) => {
  return articles.sort(function (artcileA, articleB) {
    return new Date(articleB.created_time) - new Date(artcileA.created_time);
  });
};

export const removeLnPageId = () => {
  sessionStorage.removeItem('lnpage');
};

export const getLnPageId = () => {
  return sessionStorage.getItem('lnpage');
};

export const platforms = {
  em: 'Email',
  fb: 'Facebook',
  ln: 'LinkedIn',
  tw: 'Twitter',
};

export const getAccountId = (platform, hasMultipleEmail, socialNetworkData) => {
  let selectedAcountId = '';
  if (hasMultipleEmail && platform === 'em') {
    selectedAcountId = '';
  }
  const lnPageId = getLnPageId();
  if (platform === 'ln' && lnPageId) {
    selectedAcountId =
      get(socialNetworkData, `${platform}[0].accountId`) + `#${lnPageId}`;
  }
  if (!selectedAcountId) {
    selectedAcountId = get(socialNetworkData, `${platform}[0].accountId`);
  }
  return selectedAcountId;
};

export const leadEventHandlers = ({
  eventName,
  userId,
  eid,
  form_type,
  dnetwork,
  email_id,
  first_name,
  last_name,
  client_email,
  client_first_name,
  client_last_name,
  am_firm_name,
  user_type,
  investable_assets,
  provider,
  ticker,
  page_tag,
  device_type,
  visitor_type,
  firm_name,
}) => {
  window.addKrakenEvent &&
    typeof window.addKrakenEvent === 'function' &&
    window.addKrakenEvent({
      name: eventName,
      body: {
        userId,
        eid,
        form_type,
        dnetwork,
        email_id,
        first_name,
        last_name,
        client_email,
        client_first_name,
        client_last_name,
        am_firm_name,
        user_type,
        investable_assets: investable_assets || null,
        provider: provider || null,
        ticker: ticker || null,
        page_tag: page_tag || null,
        device_type: device_type || null,
        visitor_type: visitor_type || null,
        firm_name: firm_name || null,
      },
    });
};

export const eventHandlers = (
  eventName,
  popularThemes,
  articleId,
  section,
  publisherId,
  publisherName,
  topicId,
  topicName,
  title,
  userEmail,
  fund,
  url
) => {
  let myTopics =
    popularThemes &&
    popularThemes.length > 0 &&
    popularThemes.map((item, index) => {
      return {
        id: item.id,
        name: item.name,
      };
    });
  window.addKrakenEvent &&
    typeof window.addKrakenEvent === 'function' &&
    window.addKrakenEvent({
      name: eventName,
      body: {
        id: articleId,
        section: section,
        title: title,
        publisher: {
          id: publisherId,
          name: publisherName,
        },
        topic: {
          id: topicId,
          name: topicName,
        },
        user_email_id: userEmail && userEmail,
        ...(fund && { fund: fund }),
        ...(url && { magnifiUrl: url }),
        ...(sessionStorage.getItem('superAdminEmail') && {
          super_user_email_id: sessionStorage
            .getItem('superAdminEmail')
            .toLowerCase(),
        }),
        my_topics: myTopics,
      },
    });
};

export const copyToClipboard = (str) => {
  let el = document.createElement('textarea');
  el.value = str;
  el.setAttribute('readonly', '');
  el.style.position = 'absolute';
  el.style.left = '-9999px';
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
};

export const formatNewsletterDropdownItem = (value) => {
  let formattedValue;
  console.log('TYPE = = = ', typeof value);
  if (typeof value === 'object') formattedValue = value;
  else {
    if (value.includes('<first_name>')) {
      formattedValue = { label: 'First Name', value: '<first_name>' };
    } else if (value.includes('<last_name>')) {
      formattedValue = { label: 'Last Name', value: '<last_name>' };
    } else {
      formattedValue = { label: 'Full Name', value: '<full_name>' };
    }
  }

  return formattedValue;
};

export const getNewsletterSnetworkActId = (socialNetworkData) => {
  const emailAccounts = get(socialNetworkData, 'em', []);
  let snetworkId;
  if (emailAccounts.filter((e) => e.snetworkProvider === 'sg').length > 0) {
    snetworkId = emailAccounts.filter((e) => e.snetworkProvider === 'sg')[0]
      .accountId;
  } else {
    snetworkId = emailAccounts[0].accountId;
  }

  return snetworkId;
};



export const platformNames = {
  tw: 'Twitter',
  em: 'Email',
  fb: 'Facebook',
  ln: 'Linkedin',
};

export const ignoredFunds = ['PMPEX'];

export const getSalesforceSettings = userData => {
  const fetchedSfSettings = get(userData, 'details.user.extra_settings.salesforce_settings', {});
      const fetchedSfSettingsLabels = Object.keys(fetchedSfSettings);
      const localSfSettingsLabels = Object.keys(salesforceSettingsMapper);
      const localSfTogglesLabels = Object.keys(salesforceTogglesMapper);
      let newSfSettings = {};
      // const newSfSettings = {};
      if(!isEmpty(fetchedSfSettings)) {
        newSfSettings = fetchedSfSettingsLabels.map(sfSetting => {
          const returnObj = {};
          const settingIndex = localSfSettingsLabels.findIndex(setting => salesforceSettingsMapper[setting] === sfSetting);
          returnObj.label = localSfSettingsLabels[settingIndex];
          const fetchedSfToggles = fetchedSfSettings[sfSetting];
          const fetchedSfTogglesLabels = Object.keys(fetchedSfToggles);
          fetchedSfTogglesLabels.forEach(sfToggle => {
            const toggleIndex = localSfTogglesLabels.findIndex(toggle => salesforceTogglesMapper[toggle] === sfToggle);
            returnObj[localSfTogglesLabels[toggleIndex]] = fetchedSfToggles[sfToggle] === 'true'
          });
          // newSfSettings[localSfSettingsLabels[settingIndex]] = newSfToggles;
          return returnObj;
        });
        // updateSalesforceSettings(newSfSettings);
      };
      // console.log('USERDATA ****  - - - - ****', userData);
      const userDataCopy = {...userData};
      userDataCopy.details.user.salesforce_settings = newSfSettings;
      return userDataCopy;
      // console.log('UPDATED SETTINGS - - - - -', newSfSettings);
      // loginSetState({data: userDataCopy});
}

export const encryptPassword = (password) => {
  const salt = CryptoJS.enc.Hex.parse("a8f556b3cb926c09c33aa9257a531590");
  const iv = CryptoJS.enc.Hex.parse("d45bedbbec2a1f26aabcd2cbc97b048d");

  let encrypted = CryptoJS.AES.encrypt(password, salt, {iv:iv,  padding: CryptoJS.pad.ZeroPadding});
  encrypted = encrypted.ciphertext.toString(CryptoJS.enc.Base64);

  return encrypted;
}

export const convertDateToRequiredFormat = ({ date, format = 'YYYY-MM-DD' }) =>
  moment(date).format(format);

export  const timeZoneAbbreviated = (timeZone) => {
    return  new Date().toLocaleTimeString('en-us',{timeZone: timeZone, timeZoneName:'short'}).split(' ')[2];
  };

export const groupBy = (input, key) => {
  return input.reduce((acc, currentValue) => {
    let groupKey = currentValue[key];
    if (!acc[groupKey]) {
      acc[groupKey] = [];
    }
    acc[groupKey].push(currentValue);
    return acc;
  }, {});
};
