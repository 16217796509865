/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Flex } from '@rebass/grid';
import { type } from 'jquery';
import { get, isEmpty } from 'lodash';
import React, { useState, useEffect } from 'react';
import ReactSelect from 'react-select';
import {
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalHeader,
    Button
  } from '../../../components';
import {
    Table,
    Tr,
    TableBody,
    LoaderWrapper,
    EmailListTh,
    EmailListTableBodyTr,
    EmailListTableBodyTd,
    EmailListTableHeading,
} from '../../../components';
import { getContactsList } from '../../../services/configure';
import { getSegmentContactList } from '../../../services/segment';

export const ContactListModal = ({
    isOpen,
    handleContactListClose,
    selectedTrailData,
    page,
    setPage,
    selectedEmailListItem
}) => {
    console.log(selectedTrailData);
    const [mailingList, setMailingList] = useState([]);
    const [mailingListData, setMailingListData] = useState([]);
    const [selectedMailingList, setSelectedMailingList] = useState('');
    const [startIndex, setStartIndex] = useState(0);
    const [endIndex, setEndIndex] = useState(0);
    const [loading, setLoading] = useState(false);
    const [totalCount, setTotalCount] = useState(0);
    const [navigateAfter, setNavigateAfter] = useState('');
    const [paginatedData, setPaginatedData] = useState('');

    useEffect(() => {
        if(!isEmpty(get(selectedTrailData, 'campaign_data.newsletter.contactlists', []))) {
            setLoading(true);
            let list = get(selectedTrailData, 'campaign_data.newsletter.contactlists', []).map((item) => {
                return {
                    label: item.name,
                    value: item.id,
                    type: get(selectedTrailData, 'campaign_data.newsletter.destination_snetwork', '') === 'cs' ? 'segment' : 'email'
                }
            });
            setSelectedMailingList(list[0]);
            setMailingList(list);
            if(list[0].type === 'email') {
                fetchEmailContacts(list[0].value);
            } else {
                fetchSegmentContacts(list[0].value);
            }
            
        }
    }, [])

    const fetchEmailContacts = (id, type = null, currentPage) => {
        setLoading(true);
        let payload = {
            contactGroup: id,
            for_compliance: true,
            ...(type && ({
                navigateTo: type,
                pagination: paginatedData[type]
            }))
        }
        getContactsList(payload).then(res => {
            setLoading(false);
            setMailingListData(get(res, `result.data.contacts.${id}`, []));
            setTotalCount(get(res, 'result.data.contacts.count.count', 0));
            setPaginatedData(get(res, 'result.data.pagination', ''));
            initialPageSetup(get(res, `result.data.contacts.${id}`, []), get(res, 'result.data.contacts.count.count', 0), currentPage);
        }).catch(err => {
            console.log(err);
            setLoading(false);
        });
    }

    const fetchSegmentContacts = (id) => {
        getSegmentContactList(id).then(res => {
            setLoading(false);
            setPage(0);
            setMailingListData(get(res, `result.data`, []));
            setStartIndex(!isEmpty(get(res, `result.data`, [])) ? 1 : 0);
            setEndIndex(!isEmpty(get(res, `result.data`, [])) ? get(res, `result.data`, []).length : 0);
            setTotalCount(!isEmpty(get(res, `result.data`, [])) ? get(res, `result.data`, []).length : 0);
        }).catch(err => {
            console.log(err);
            setLoading(false);
        })
    }
      const getSelectStyles = () => ({
        container: (base) => ({
          ...base,
          width: '100%',
        }),
        control: (base) => ({
          ...base,
          height: '32px',
          minHeight: '32px',
          border: '1px solid #8493A6',
        }),
        dropdownIndicator: (base) => ({
          ...base,
          paddingTop: 0,
          paddingBottom: 0,
          color: '#848484',
        }),
       option: (base, state) => ({
        ...base,
        backgroundColor: state.isSelected ? '#6453EB' : '',
       }),
      });

    useEffect(() => {
        setNavigateAfter(+endIndex < +totalCount);
    }, [endIndex, totalCount, mailingListData])
      



    const initialPageSetup = (data, count, currentPage = 0) => {
        if (data.length < 250 && page === 0) {
            setPage(0);
          }
          // console.log(page);
          if (currentPage === 0) {
            setStartIndex(count === 0 ? 0 : 1);
            setEndIndex(
                count === 0
                ? 0
                : data.length < 250
                  ? count
                  : 250
            );
          } else {
            setStartIndex(currentPage * 250 + 1);
            setEndIndex(
              (currentPage + 1) * 250 >= count ? count : (currentPage + 1) * 250
            );
          }
    }
    
   
    const paginationClickHandler = (type) => {
      const currentPage = type === 'before' ? (page === 0 ? 0 : page - 1) : page + 1;
      setPage(currentPage);
      fetchEmailContacts(selectedMailingList.value, type, currentPage);
    };

    const handleMailingListChnage = (newValue) => {
        const { value, type } = newValue;
        setPage(0);
        setStartIndex(0);
        setEndIndex(0);
        setSelectedMailingList(newValue);
        setPaginatedData('');
        setLoading(true);
        if(type === 'email') {
            fetchEmailContacts(value);
        } else {
            fetchSegmentContacts(value);
        }
        
    }
    

    return (
        <Modal
            onRequestClose={handleContactListClose}
            isOpen={isOpen}
            size="medium"
            height="auto"
        >
            <ModalHeader>
                <div
                    data-testid="campaign-contacts-modal"
                    style={{
                        color: 'black',
                        fontSize: '18px',
                    }}
                >
                    Campaign Contacts
                </div>
                <ModalCloseButton onClick={handleContactListClose} />
            </ModalHeader>
            <ModalBody>
                <Box width="40%" pt="8px" mb="10px">
                    <ReactSelect
                        onChange={(newValue) => {
                            handleMailingListChnage(newValue)
                            
                            // setNewsletterFrequency(getNewOptionValue(newValue));
                        }}
                        options={mailingList}
                        placeholder={'Choose a List|Segment'}
                        styles={getSelectStyles()}
                        value={selectedMailingList}
                    />
                </Box>
                <EmailListTableHeading style={{ flex: '0 0 auto' }}>
                    <Tr>
                        <EmailListTh
                            align="left"
                            style={{ paddingLeft: '12px' }}
                            width="50%"
                        >
                            Email
                        </EmailListTh>
                        <EmailListTh align="left" width="21.5%">
                            First Name
                        </EmailListTh>
                        <EmailListTh align="left" width="21.5%">
                            Last Name
                        </EmailListTh>
                        <EmailListTh align="left" width="17%">
                            List | Segment
                        </EmailListTh>
                    </Tr>
                </EmailListTableHeading>
                <LoaderWrapper isLoading={loading}>
                    <TableBody
                        maxHeight="206px"
                        minHeight="100px"
                        height="auto"
                        style={{ position: 'relative', bottom: '1px' }}
                    >
              {!isEmpty(mailingListData) ? mailingListData.map(item => (
                <EmailListTableBodyTr
                  style={{ flex: '0 0 auto' }}
                >
                  <EmailListTableBodyTd
                    width="50%"
                    align="left"
                    style={{
                      paddingLeft: selectedMailingList.type === 'email' ? '12px' : '12px',
                    }}
                    className="contactListOverflow"
                  >
                    {item?.email}
                  </EmailListTableBodyTd>
                  <EmailListTableBodyTd
                    align="left"
                    width="21.5%"
                    style={{
                      paddingLeft: selectedMailingList.type === 'email' ? '9px' : '13px',
                    }}
                  // className="contactListOverflow"
                  >
                    {selectedMailingList.type === 'email' ? item?.name?.first : item?.first_name}
                  </EmailListTableBodyTd>
                  <EmailListTableBodyTd
                    align="left"
                    width="21.5%"
                    style={{
                      paddingLeft: selectedMailingList.type === 'email' ? '9px' : '16px',
                    }}
                  // className="contactListOverflow"
                  >
                    {selectedMailingList.type === 'email' ? item?.name?.last : item?.last_name}
                  </EmailListTableBodyTd>
                  <EmailListTableBodyTd
                    align="left"
                    width="17%"
                    style={{
                      paddingLeft: selectedMailingList.type === 'email' ? '9px' : '20px' 
                    }}
                  // className="contactListOverflow"
                  >
                    {selectedMailingList?.label}
                  </EmailListTableBodyTd>
                </EmailListTableBodyTr>
              )) : 'No Records Found'}

            </TableBody>
          </LoaderWrapper>
          {/* <Box
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginLeft: window.innerWidth < 768 ? '14%' : 'auto',
                  }}
                  width={window.innerWidth < 768 ? '61%' : '43%'}
                > */}
          <Flex
            justifyContent="space-between"
            alignItems="center"
            display="flex"
            flexDirection="row"
            paddingBottom="10px"
            marginTop="35px"
          >
            <Button
              type="button"
              width="96px"
              height="34px"
              styles={{ border: 'none' }}
              bgColor={page < 1 ? '#AFAFAF' : '#7dc4d0'}
              margin="0px"
              onClick={() => paginationClickHandler('before')}
              disabled={page < 1 ? true : false}
            >
              Previous
            </Button>


            {/* <Box
                  width={window.innerWidth < 768 ? '55%' : '50%'}
                  style={{
                    paddingBottom: '5px',
                    //marginLeft: window.innerWidth < 768 ? '-9%' : '-15px',
                    fontSize: '14px',
                    color: '#49484a',
                    marginTop: '8px',
                    position: 'absolute',
                    marginLeft: '9px',
                  }}
                > */}

            <span style={{ paddingTop: '15px' }}>
              {startIndex}-{endIndex} of {totalCount}
              {/* </Box> */}
            </span>

                  {/* <Box width={1} style={{ marginLeft: '10px' }}> */}
                    <Button
                      height="34px"
                      width="106px"
                      styles={{ border: 'none' }}
                      bgColor={!navigateAfter ? '#AFAFAF' : '#7dc4d0'}
                      type="button"
                      onClick={() => paginationClickHandler('after')}
                      margin="0px"
                      disabled={!navigateAfter}
                    >
                      Next
                    </Button>
                  {/* </Box> */}
                  </Flex>
                {/* </Box> */}
            </ModalBody>
        </Modal>
    );
};
