import { handleActions } from 'redux-actions';
import { apiModuleDefaultState, createApiActions } from './helpers';
import { getSocialAccountStatusData } from '../../utils';

export const actions = createApiActions('socialNetwork');

const reducer = handleActions(
  {
    [actions.api.socialNetwork.request]: () => ({
      isFetching: true,
      isError: false,
    }),
    [actions.api.socialNetwork.response]: (_, { payload }) => {
      return ({
        isFetching: false,
        isError: false,
        data: getSocialAccountStatusData(payload),
      })
    },
    [actions.api.socialNetwork.error]: (_, { payload }) => ({
      isFetching: false,
      isError: true,
      error: payload,
    }),
  },
  { ...apiModuleDefaultState, data: {}, error: null }
);

export default reducer;
