/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import {
    PageContainer,
    CardContainer,
    DarkText,
    CreateContainer,
    CreateCards,
    CreateIcons,
    OuterCreateContainer,
} from './styles';
import {
    cascadedEmailTemplate,
    deleteEmailTemplate,
    saveDuplicateTemplate,
    getCascadeEmailTemplate,
    getEmailTemplates,
    postCascadeEmailTemplate
} from '../../services/campaign';
import { connect } from 'react-redux';
import { actions as campaignActions } from '../../redux/modules/campaign.module';
import { actions as loginActions } from '../../redux/modules/login.module';
import { LoaderWrapper } from '../../components';
import { useHistory } from 'react-router-dom';
import TemplateCards from '../../componentsV2/TemplateCards';
import CardSkeleton from './CardSkeleton';
import ModalContainer from '../../componentsV2/ModalContainer';
// import WhiteButton from '../../componentsV2/WhiteButton';
// import PurpleButton from '../../componentsV2/PurpleButton';
import defaultEmail from '../../assets/templates/defaultEmail.svg';
import { getUsersList } from '../../services/analytics';
import { ConfirmationModalV2 } from '../../componentsV2';
import CascadeModal from './CascadeModal';
import { get, isEmpty } from 'lodash';

const EmailTemplates = ({
    showCreateModal,
    setShowCreateModal,
    searchString,
    showDeleteModal,
    setShowDeleteModal,
    selectedTemplate,
    setSelectedTemplate,
    membersList,
    loginSetState,
    isEnterpriseLevelAdmin,
    firmsArray,
    isAdmin,
    showDuplicateModal,
    setShowDuplicateModal
}) => {
    const [userTemplates, setUserTemplates] = useState([]);
    const [baseTemplates, setBaseTemplates] = useState([]);
    const [pageLoader, setPageLoader] = useState(false);
    const [emailTemplateTypes, setEmailTemplateTypes] = useState([]);
    const [selectedCard, setSelectedCard] = useState({});
    const [modalLoading, setModalLoading] = useState(false);
    const [selectedAdvisors, setSelectedAdvisors] = useState([]);
    const [showShareModal, setShowShareModal] = useState(false);
    const [selectedFirms, setSelectedFirms] = useState([]);
    const [selectedFirmAdvisors, setSelectedFirmAdvisors] = useState([]);
    const [message, setMessage] = useState(false);
    const [duplicateTemplateName, setDuplicateTemplateName] = useState('');
    const history = useHistory();

    useEffect(() => {
        getTemplates();
        getUsers()
    }, []);


    const basicDefault = [{
        name: 'Basic Default',
        thumbnail_url: defaultEmail,
        id: 0,
    }];

    const shareModalAction = (e, row) => {
        e.stopPropagation();
        console.log(row);
        setSelectedTemplate(row);
        setSelectedAdvisors(row?.cascaded_to || []);
        if(isEnterpriseLevelAdmin) {
            getCascadeEmailTemplate(row.id).then(res => {
                setSelectedFirmAdvisors(get(res, 'result.data.firm_ids_advisor', []));
                setSelectedFirms(get(res, 'result.data.firm_ids_admin', []));
                setShowShareModal(true);
            }).catch(err => {
                console.log(err);
            });
        } else {
            setShowShareModal(true);
        }        
    }

    const getUsers = async () => {
        try {
            const data = await getUsersList('all')
            const membersList = data?.result?.data?.users
            await loginSetState({ membersList });
        } catch (err) {
            console.log('Get users error:', err)
        }
    }

    const handleContinue = (card) => {
        if (card.id === 0) {
            history.push(`/campaign/template/new`);
        } else {
            history.push(`/campaign/template/${card.id}?type=new`)
        }
    };

    const shareTemplateHandler = async () => {
        let advisorsArray = [...selectedAdvisors];

        if (advisorsArray.includes('selectAll'))
            advisorsArray = advisorsArray.filter(a => a !== 'selectAll');

        const payload = {
            users: JSON.stringify(advisorsArray),
            template_id: selectedTemplate
        };

        try {
            const response = await postCascadeEmailTemplate(payload)
            if (response?.result?.success) {
                setShowShareModal(false);
                setPageLoader(true)
                await getTemplates();
                setPageLoader(false);
            }
        } catch (err) {
            console.log('ERROR - - - -', err);
        }
    }


    const handleSelectedAdvisors = (item) => {
		if (item === 'all') {
			if (selectedAdvisors.length === membersList.length) {
				setSelectedAdvisors([]);
			} else {
				setSelectedAdvisors(membersList.map(m => m.id));
			}
		}
		else if (isEmpty(selectedAdvisors)) {
			setSelectedAdvisors([item])
		} else if (!isEmpty(selectedAdvisors.filter(s => s === item))) {
			setSelectedAdvisors(selectedAdvisors.filter(s => s !== item));
		} else {
			setSelectedAdvisors([...selectedAdvisors, item])
		}
	}

	const handleSelectedAdmin = (item) => {
		if (item === 'all') {
			if (selectedFirms.length === firmsArray.length) {
				setSelectedFirms([]);
			} else {
				setSelectedFirms(firmsArray.map(m => m.id));
			}
		}
		else if (isEmpty(selectedFirms)) {
			setSelectedFirms([item])
		} else if (!isEmpty(selectedFirms.filter(s => s === item))) {
			setSelectedFirms(selectedFirms.filter(s => s !== item));
		} else {
			setSelectedFirms([...selectedFirms, item])
		}
	}

	const handleSelectedFirmAdvisors = (item) => {
		if (item === 'all') {
			if (selectedFirmAdvisors.length === firmsArray.length) {
				setSelectedFirmAdvisors([]);
			} else {
				setSelectedFirmAdvisors(firmsArray.map(m => m.id));
			}
		}
		else if (isEmpty(selectedFirmAdvisors)) {
			setSelectedFirmAdvisors([item])
		} else if (!isEmpty(selectedFirmAdvisors.filter(s => s === item))) {
			setSelectedFirmAdvisors(selectedFirmAdvisors.filter(s => s !== item));
		} else {
			setSelectedFirmAdvisors([...selectedFirmAdvisors, item])
		}
	}

    const handleShare = () => {
        setModalLoading(true);

        if(isEnterpriseLevelAdmin) {
            const myAdvisorPayload = {
                users: JSON.stringify(selectedAdvisors),
                template_id: selectedTemplate.id
            };
            const adminPayload = {
                firms: JSON.stringify(selectedFirms),
                cascade_to_advisors: 'false',
            };
            const advisorPayload = {
                firms: JSON.stringify(selectedFirmAdvisors),
                cascade_to_advisors: 'true',
            };
            
            Promise.all([
                postCascadeEmailTemplate(myAdvisorPayload),
                cascadedEmailTemplate({
                    payload: adminPayload,
                    id: selectedTemplate.id
                }),
                cascadedEmailTemplate({
                    payload: advisorPayload,
                    id: selectedTemplate.id
                })
            ]).then(res => {
                setMessage('Success!');
                setTimeout(() => {
                    getTemplates();
                    handleShareModalClose();
                        
                }, 3000);
                
            }).catch(err => {
                setMessage('Internal Server Error!!');
                setModalLoading(false);
            })
        } else {
            const payload = {
                users: JSON.stringify(selectedAdvisors),
                template_id: selectedTemplate.id
            };

            postCascadeEmailTemplate(payload).then(res => {
                if (res?.result?.success) {
                    setMessage('Success!');
                    setTimeout(() => {
                        getTemplates();
                        handleShareModalClose();
                        
                    }, 3000);
                }
            }).catch(err => {
                setMessage('Internal Server Error!!');
                setModalLoading(false);
            })
        }
    }

    const handleShareModalClose = () => {
        setSelectedAdvisors([]);
        setSelectedFirmAdvisors([]);
        setSelectedFirms([]);
        setMessage('');
        setModalLoading(false);
        setShowShareModal(false)
    }




    const createEmailTemplate = () => {
        return (
            <OuterCreateContainer>
                <CreateContainer>
                    {
                        emailTemplateTypes.map((card, index) => {
                            return (
                                <CreateCards key={index} onClick={() => handleContinue(card)}>
                                    <CreateIcons src={card.thumbnail_url} />
                                    <DarkText>{card.name}</DarkText>
                                </CreateCards>
                            )
                        })
                    }
                </CreateContainer>
                {/* <ButtonContainer>
                    <WhiteButton
                        width="49%"
                        children={'Cancel'}
                        onClick={()=>setShowCreateModal(false)}
                    />
                    <PurpleButton
                        width="49%"
                        children={'Continue'}
                        disabled={selectedTemplate?.length}
                        onClick={handleContinue}
                    />
                </ButtonContainer> */}
            </OuterCreateContainer>
        )
    }

    const getTemplates = async () => {
        setPageLoader(true);
        try {
            const response = await getEmailTemplates(['default', 'cascade'])
            const templates = response?.result?.data?.templates.filter(c => c.created_by === "user")
                .sort((a, b) => new Date(b.created_time) - new Date(a.created_time));
            setUserTemplates(templates);
            const baseTemplates = response?.result?.data?.templates.filter(c => c.created_by !== "user");
            setBaseTemplates(baseTemplates);
            setEmailTemplateTypes([...basicDefault, ...response?.result?.data?.templates.filter((c) => c.owner === 'clout')])
            setPageLoader(false);
        } catch (err) {
            console.log('Get email templates error:', err);
            setPageLoader(false);
        }
    };

    const deleteTemplateHandler = async (cardData) => {
        setModalLoading(true)
        try {
            await deleteEmailTemplate({ id: cardData.id })
            setModalLoading(false)
            setShowDeleteModal(false);
            getTemplates();
        } catch (err) {
            console.log('Delete template error', err)
            setShowDeleteModal(false);
            setModalLoading(false)
        }
    };

    const saveDuplicateModalHandler = () => {
      const id = selectedTemplate?.id ?? 0;
      const name = duplicateTemplateName ?? '';
      saveDuplicateTemplate({ name, id }).then((res) => {
        if (res?.result?.success) {
          setShowDuplicateModal(!showDuplicateModal);
          getTemplates();
        }
      });
    };



    return (
      <PageContainer>
        <CardContainer>
          {!pageLoader && userTemplates ? (
            userTemplates
              .filter((item) =>
                item.name.toLowerCase().includes(searchString.toLowerCase())
              )
              .map((card, index) => {
                return (
                  <TemplateCards
                    key={index}
                    cardData={card}
                    cardType={'email'}
                    setSelectedCard={setSelectedCard}
                    selectedCard={selectedCard}
                    setShowDeleteModal={setShowDeleteModal}
                    setSelectedTemplate={setSelectedTemplate}
                    setShowShareModal={setShowShareModal}
                    shareModalAction={shareModalAction}
                    isAdmin={isAdmin}
                    toggleDuplicateModal={setShowDuplicateModal}
				    setName={setDuplicateTemplateName}
                  />
                );
              })
          ) : (
            <div style={{ width: '100%' }}>
              <CardSkeleton />
            </div>
          )}
        </CardContainer>
        <ModalContainer
          title={'Create New Email Template'}
          subTitle={
            'Create using a default template or select from the available presets'
          }
          modalStatus={showCreateModal}
          closeModal={() => setShowCreateModal(!showCreateModal)}
          children={createEmailTemplate()}
        />
        {/* <ModalContainer
                title="Confirm Delete"
                modalStatus={showDeleteModal}
                closeModal={()=>setShowDeleteModal(!showDeleteModal)}
                children={deleteDisplay()}
            /> */}
        {showDeleteModal && (
          <ConfirmationModalV2
            isModalOpen={showDeleteModal}
            title="Are you sure you want to delete this template?"
            subTitle="If any campaigns are scheduled to go that use this template, they will not be sent."
            loading={modalLoading}
            btnCancelName="No"
            btnOkName="Yes"
            onClose={() => setShowDeleteModal(!showDeleteModal)}
            onSubmit={() => deleteTemplateHandler(selectedCard)}
          />
        )}
        {showShareModal && (
          <CascadeModal
            isModalOpen={showShareModal}
            title="Cascade Email Template"
            onClose={handleShareModalClose}
            subTitle="Select users to share your email template with"
            isEnterpriseAdmin={isEnterpriseLevelAdmin}
            advisorList={membersList}
            firmsArray={firmsArray}
            selectedAdvisors={selectedAdvisors}
            handleSelectedAdvisors={handleSelectedAdvisors}
            selectedAdmin={selectedFirms}
            handleSelectedAdmin={handleSelectedAdmin}
            selectedFirmAdvisors={selectedFirmAdvisors}
            handleSelectedFirmAdvisors={handleSelectedFirmAdvisors}
            onSubmit={handleShare}
            loading={modalLoading}
            disable={false}
            message={message}
          />
        )}

        {showDuplicateModal && (
          <ConfirmationModalV2
            isModalOpen={showDuplicateModal}
            btnCancelName="No"
            btnOkName="Yes"
            title={`Are you sure you want to duplicate ${selectedTemplate?.name}?`}
            onClose={() => setShowDuplicateModal(!showDuplicateModal)}
            loading={modalLoading}
            // message={message}
            onSubmit={saveDuplicateModalHandler}
            inputField
            placeHolder="Enter Email Template Name"
            name={duplicateTemplateName}
            setName={setDuplicateTemplateName}
            modalWidth="25%"
          />
        )}
      </PageContainer>
    );
};

const mapStateToProps = state => {
    return {
        membersList: state.user.membersList ? [
            ...state.user.membersList,
        ] : [],
    };
}

const mapDispatchToProps = {
    campaignSetState: campaignActions.api.campaign.setstate,
    loginSetState: loginActions.api.login.setstate,
}

export default connect(mapStateToProps, mapDispatchToProps)(EmailTemplates);