import React, { useEffect, useRef, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { find, isEmpty, get } from 'lodash';

import {
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  ModalCloseButton,
  Table,
  Tr,
  TableBody,
  LoaderWrapper,
  EmailListTh,
  EmailListTableBodyTr,
  EmailListTableBodyTd,
  EmailListTableHeading,
  ErrorContainer,
} from '../';
import { createCsvForHubspotInvite } from '../../utils';
import { getContactsGroups } from '../../services/configure';

const InviteFromHubSpotModal = ({
  isModalOpen,
  inviteRequest,
  setModalOpen,
  inviteSending,
  inviteError,
  channelId,
  contactsRequest,
  contactsList,
  isFromDashboard,
  history,
  contactsFetching
}) => {
  const didMountRef = useRef(null);
  const [isAllCheckSelected, setAllCheckSelected] = useState(false);
  const [emailTableData, setEmailTableData] = useState([]);
  const [successMessage, setSuccessMesage] = useState('');
  const [errorMessage, setErrorMesage] = useState('');
  const [idsToDelete, setIdsToDelete] = useState([]);

  useEffect(() => {
    if (isModalOpen) {
      getContactsGroups()
        .then((response) => {
          const contactGroupList = find(get(response, 'result.data', []), {
            name: 'HUBSPOT',
          });
          const contactGroupId = get(contactGroupList, 'id');
          return contactGroupId;
        })
        .then((contactGroupId) => {
          contactsRequest({
            params: {
              contactGroup: contactGroupId,
            },
          });
        });
    }
  }, [isModalOpen, contactsRequest]);

  useEffect(() => {
    if (!didMountRef.current) {
      didMountRef.current = true;
    } else {
      if (!inviteSending) {
        if (inviteError) {
          setErrorMesage('Please upload as per sample file');
        } else {
          setSuccessMesage('✓ Invitation Sent Successfully');
          window.setTimeout(() => {
            setModalOpen(false);
            setSuccessMesage('');
            setErrorMesage('');
            window.history.replaceState('','',window.location.protocol + '//' + window.location.host + window.location.pathname);
          }, 2000);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inviteSending]);

  useEffect(() => {
    setEmailTableData(contactsList);
  }, [contactsList]);

  const onInviteClick = () => {
    if (idsToDelete.length === 0) {
      setErrorMesage('please select contact(s) to send invite');
      return;
    }

    const formData = new FormData();

    let textContent = '';
    const groupId = sessionStorage.getItem('inviteMemberGroupId');
    const dataToParse = [];
    emailTableData.forEach((item) => {
      if (item.checked) {
        dataToParse.push({
          first: get(item, 'name.first'),
          last: get(item, 'name.last'),
          email: item.email,
        });
      }
    });
    textContent = createCsvForHubspotInvite(
      dataToParse,
      groupId ? 'general' : 'admin'
    );
    formData.append('media1', textContent);

    // formData.append('custom_message', values.emailList);
    formData.append('groups_json', `["${groupId}"]`);
    formData.append('channels_json', `["${channelId}"]`);
    inviteRequest({
      params: formData,
    });
  };

  const onAllCheckboxClick = () => {
    setErrorMesage('');
    setAllCheckSelected(!isAllCheckSelected);
    const allIds = [];
    const transformedData = emailTableData.map((list, index) => {
      allIds.push(list.email);
      return {
        ...list,
        checked: !isAllCheckSelected,
      };
    });
    setIdsToDelete(allIds);
    setEmailTableData(transformedData);
  };

  const onIndividualCheckboxClick = (itemIndex) => {
    setErrorMesage('');
    let checkCnt = 0;
    const transformedData = emailTableData.map((list, index) => {
      const checked = index === itemIndex ? !list.checked : list.checked;
      if (checked) {
        setIdsToDelete([...idsToDelete, list.email]);
        checkCnt++;
      }
      return {
        ...list,
        checked,
      };
    });
    setAllCheckSelected(checkCnt === transformedData.length);
    setEmailTableData(transformedData);
  };

  const handleClose = () => {
    setModalOpen(false);
    window.history.replaceState('','',window.location.protocol + '//' + window.location.host + window.location.pathname);
  }

  return (
    isModalOpen && (
      <Modal
        onRequestClose={handleClose}
        shouldCloseOnOverlayClick
        isOpen
        size="medium"
      >
        <ModalHeader>Hubspot Contact List</ModalHeader>
        <ModalCloseButton onClick={handleClose} />
        <ModalBody>
          <Table>
            <EmailListTableHeading>
              <Tr>
                <EmailListTh width="10%">
                  <input
                    type="checkbox"
                    checked={isAllCheckSelected}
                    onChange={onAllCheckboxClick}
                  />
                </EmailListTh>
                <EmailListTh align="left">Name</EmailListTh>
                <EmailListTh align="left">Email</EmailListTh>
              </Tr>
            </EmailListTableHeading>
            <TableBody height="335px">
              <LoaderWrapper isLoading={contactsFetching}>
              {emailTableData && emailTableData.length && emailTableData.length > 0 && emailTableData.map((item, index) => (
                <EmailListTableBodyTr
                  alternate={false}
                  key={item.email + index}
                >
                  <EmailListTableBodyTd width="10%">
                    <input
                      id={`email${index}`}
                      type="checkbox"
                      checked={item.checked}
                      onChange={() => onIndividualCheckboxClick(index)}
                    />
                  </EmailListTableBodyTd>
                  <EmailListTableBodyTd align="left">
                    {item.name.first} {item.name.last}
                  </EmailListTableBodyTd>
                  <EmailListTableBodyTd align="left">
                    {item.email}
                  </EmailListTableBodyTd>
                </EmailListTableBodyTr>
              ))}
              </LoaderWrapper>
            </TableBody>
          </Table>
          <LoaderWrapper isLoading={inviteSending}>
            <Button onClick={onInviteClick} disabled={contactsFetching}> Send Invite </Button>
          </LoaderWrapper>
          <ErrorContainer align="center">
            {successMessage || errorMessage}
          </ErrorContainer>
        </ModalBody>
      </Modal>
    )
  );
};

export default withRouter(InviteFromHubSpotModal);
