/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import BeePlugin from '@mailupinc/bee-plugin';
import axios from 'axios';
import { Button, ButtonNoBorderBackground, LoaderWrapper, ModalBody, ModalCloseButton, ModalHeader, ModalV2 } from '../../components';
import { connect } from 'react-redux';
import { cloneDeep, get } from 'lodash-es';
import { Flex } from '@rebass/grid';
import BeeHeader from '../../components/RichTextEditor/BeeHeader';
import { createCustomRow, getCustomRowTemplate, getDefaultRowTemplate, updateCustomRow } from '../../services/bee-editor';
import useQuery from '../../customHooks/useQuery';
import { beeAuth } from '../../services/campaign';
import { thumbnailEndpoints } from '../../services/helpers';
import { TemplateFooter } from '../TemplateContainer';
import { ConfirmationModalV2 } from '../../componentsV2';

const CustomRowsEditorContainer = ({ userData }) => {

    const history = useHistory();

    const { id } = useParams();
    // const query = useLocation().search;
    const query = useQuery();
    const [pageLoader, togglePageLoader] = useState(true);
    const [saveLoader, toggleSaveLoader] = useState(false);
    const [rowCategory, setRowCategory] = useState('');
    const [rowLayout, setRowLayout] = useState('');
    const [beeInstance, setBeeInstance] = useState({});
    const [loadHeader, setLoadHeader] = useState(false);
    const [openSaveModal, toggleSaveModal] = useState(false);
    const [openErrorModal, toggleErrorModal] = useState(false);
    const [customRowName, setCustomRowName] = useState('');
    const [templateHtml, setTemplateHtml] = useState('');
    const [templateJson, setTemplateJson] = useState('');
    const [progressMessage, setProgressMessage] = useState('');
    const [rowData, setRowData] = useState({});
    const [errorMessage, setErrorMessage] = useState('');

    const DEFAULT_CONFIGURATION = {   
        uid: get(userData, 'uid', ''),               
        container: 'bee-plugin-container',
        language: 'en-US',
        autosave: true,
        roleHash: 'nopermissions',
        advancedPermissions: {
            rows: {
                behaviors: {
                    canClone: false,
                    canDelete: false,
                }
            }
        },
        onLoad: () => {
            setLoadHeader(true)
        },
        // mergeTags: [
        //     {name: 'First Name', value: '{$first_name}'}, 
        //     {name: 'Last Name', value: '{$last_name}'},
        //     {name: 'Full Name', value: '{$full_name}'},
        //     {name: 'Title', value: '{$title}'},
        //     {name: 'Email', value: '{$email}'}
        // ],
        contentDefaults: {
            text: {
                styles: {
                    color: '#000'
                }
            }
        },
        rowsConfiguration: {
            defaultRows: false,
            emptyRows: false,
            // externalContentURLs: [{
            //     name: "My Rows",
            //     value: "https://92bm0vkugf.execute-api.us-east-2.amazonaws.com/dev/user-rows"
            // }]
        },
        onchange: msg => console.log('ONCHANGE:  ', msg),
    };

    const fetchBaseTemplates = async (rowType, layout) => {
        let url;
        console.log(rowType)

        if(rowType === 'article_block') {
            url = `https://92bm0vkugf.execute-api.us-east-2.amazonaws.com/dev/base-templates?category=${rowType}&tags=${layout}`;
        }  else {
            url = `https://92bm0vkugf.execute-api.us-east-2.amazonaws.com/dev/base-templates?category=${rowType}`;
        }

        const baseTemplate = await axios.get(url);
        return get(baseTemplate, 'data.data.template_json', '');
        
    }

    useEffect(() => {
        const windowBodyClassList = document.body.classList;
            if (!windowBodyClassList.contains('campaign')) {
                windowBodyClassList.add('campaign');
            }
        
        return () => {
            windowBodyClassList.remove('campaign');
        };
    }, []);

    useEffect(() => {
        console.log('QUERY - - -- ', query.get('layout'));
        let layout = '';
        let templateToLoad = {};
        if(query.get('layout')) {
            layout = query.get('layout');
            console.log('UE LAYOUT -- - -', layout);
            setRowLayout(layout);
        }
        if(id.includes('new')) {
            const rowType = id.split('-')[1];
            setRowCategory(rowType);
            // fetchBaseTemplates(rowType, layout).then(template => {
            //     loadEditor(template)
            // })
            if(rowType === 'content_block' || rowType === 'disclosure')
                loadEditor({});
            else {
                getDefaultRowTemplate({rowType, layout})
                .then(response => {
                    if(response.data)
                        loadEditor(get(response, 'data.template_json', {}));
                    else 
                        loadEditor({});
                })
                .catch(err => console.log('GET DEFAULT ROW ERROR - - - ', err));
            }
        }
        else {
            console.log('****** INSIDE ELSE *****');
            // axios.get(`https://92bm0vkugf.execute-api.us-east-2.amazonaws.com/dev/get-row-template/${id}`)
            getCustomRowTemplate({ id })
            .then(res => {
                console.log('RES = = = ', res);
                templateToLoad = get(res, 'data.template_json', {});
                loadEditor(templateToLoad);
                setRowData(get(res, 'data', {}));
            })
            .catch(err => console.log('ERROR - - -- ', err));
        }
    }, []);

    const loadEditor = template => {
        const beeEditor = new BeePlugin();
        beeAuth()
        .then(res => {
            togglePageLoader(false);
            console.log('RES DATA - - -- ', res.result.data);
            beeEditor.token = res.result.data;
            beeEditor.start({ ...DEFAULT_CONFIGURATION, onSave }, template);
            setBeeInstance(beeEditor);
            // return beeEditor.start({ ...DEFAULT_CONFIGURATION, onSave }, template);
        })
        .catch(err => {
            console.log(err)
        });
    }

    const onSave = (templateJson, templateHtml) => {
        const isValid = isJsonValid(templateJson);
        console.log('template json - - - -', templateJson);
        console.log('template html - - - - - -', templateHtml);
        let row_category = ''
        if(id.includes('new'))
            row_category = id.split('-')[1];
        else
            row_category = query.get('category');

        if(
            !row_category || 
            (row_category!=='article_block' && row_category!=='webinar_block' && row_category !== 'landing_page') || 
            (row_category === 'article_block' && isValid) ||
            (row_category === 'webinar_block' && isValid) ||
            (row_category === 'landing_page' && isValid)
        ) {
            toggleSaveModal(true);
            setTemplateHtml(templateHtml);
            setTemplateJson(templateJson);
        }
        else {
            setErrorMessage('Invalid Row. Please do not change placeholders on individual content blocks!');
            toggleErrorModal(true);
        }
    }

    const closeModalHandler = () => {
        setCustomRowName('');
        setProgressMessage('');
        toggleSaveModal(false);
        toggleErrorModal(false);
        setTemplateHtml('');
        setTemplateJson('');
        setErrorMessage('');
    }

    const createHandler = () => {
        console.log('SAVE HANDLER - -- - ', customRowName);
        console.log('USER DATA - - -- ', userData);
        toggleSaveLoader(true);
        const modifiedJson = cloneDeep(JSON.parse(templateJson).page.rows[0]);
        if(modifiedJson)
            modifiedJson.metadata = {
                name: customRowName
            }
        const payload = {
            name: customRowName,
            category: rowCategory || get(rowData, 'category', ''),
            tag: rowLayout || get(rowData, 'tag', ''),
            row_json: JSON.stringify(modifiedJson),
            template_json: templateJson,
            template_html: templateHtml,
            uid: get(userData, 'uid', ''),
            cid: get(userData, 'details.user.company', ''),
            owned_by: 'user',
            thumbnail_url: '',
            original_uid: get(rowData, 'uid', '')
        }
        setProgressMessage('Creating thumbnail ...');
        axios.post(`${thumbnailEndpoints.baseUrl}/image`, {
            html: templateHtml
        })
        .then(res => {
            if(res.status && res.status === 200)
                payload.thumbnail_url = get(res, 'data.url', '');
            setProgressMessage('Saving Row ...');
            // return axios.post('https://92bm0vkugf.execute-api.us-east-2.amazonaws.com/dev/create-rows', payload)
            return createCustomRow({ payload });
        })
        .then(response => {
            toggleSaveLoader(false);
            closeModalHandler();
            history.push('/templates?tab=rows');

        })
        .catch(err => console.log('ERROR creating row- - - - - -- ', err));
        
    }

    const updateHandler = () => {
        toggleSaveLoader(true);
        const modifiedJson = cloneDeep(JSON.parse(templateJson).page.rows[0]);
        if(modifiedJson)
            modifiedJson.metadata = {
                name: customRowName || get(rowData, 'name', ''),
            }
        const payload = {
            template_json: templateJson,
            template_html: templateHtml,
            row_json: JSON.stringify(modifiedJson),
            thumbnail_url: ''
        };
        setProgressMessage('Creating thumbnail ...');
        axios.post(`${thumbnailEndpoints.baseUrl}/image`, {
            html: templateHtml
        })
        .then(res => {
            console.log('THUMBNAIL RESPOSE = = = =', res);
            if(res.status && res.status === 200)
                payload.thumbnail_url = get(res, 'data.url', '');
            console.log('FINAL PAYLOAD - -- - - ', payload);
            setProgressMessage('Saving Row ...');
            // return axios.post(`https://92bm0vkugf.execute-api.us-east-2.amazonaws.com/dev/update-rows/${id}`, payload)
            return updateCustomRow({ id, payload });
        })
        .then(response => {
            console.log('RESPONSE - - -- - -', response);
            toggleSaveLoader(false);
            closeModalHandler();
            history.push('/templates?tab=rows');
        })
        .catch(err => {
            console.log('ERROR - - - - - -- ', err);
            toggleSaveLoader(false);
        });
    }

    const isJsonValid = templateJson => {
        // const parsedJson = JSON.parse(templateJson);
        // parsedJson.rows.columns.forEach(column => {
        //     column.modules.every(module => {
        //         if(module.descriptor.text)
        //             module.descriptor.text.html 
        //     })
        // })
        let row_layout = '';
        let row_category = '';
        if(id.includes('new'))
            row_category = id.split('-')[1];
        else
            row_category = query.get('category');
        if(query && query.get('layout')) 
            row_layout = query.get('layout');
        console.log('ROW CATEGORY - - -- ', row_category);
        console.log('templateJson.search(/web_page_url/)', templateJson.search(/web_page_url/));
        // console.log('MATCH - - - -', templateJson.match(/https:\/\/static\.pexels\.com\/photos\/248280\/pexels-photo-248280\.jpeg/g))
        if(row_layout && row_layout.includes('one-column')) {
            // console.log('%!%!%!%!%!%%!% INSIDE IF !%!%%!%!%%!%!%!');
            return !(
                templateJson.search(/Article headline/) === -1 || 
                templateJson.search(/Article description/) === -1 || 
                templateJson.search(/Article link/) === -1 ||
                templateJson.search(/https:\/\/static\.pexels\.com\/photos\/248280\/pexels-photo-248280\.jpeg/) === -1
            );
        }
        else if(row_layout && row_layout.includes('two-columns'))
            return !(
                templateJson.search(/Article I headline/) === -1 || 
                templateJson.search(/Article II headline/) === -1 || 
                templateJson.search(/Article I description/) === -1 || 
                templateJson.search(/Article II description/) === -1 || 
                templateJson.search(/Article I link/) === -1 ||
                templateJson.search(/Article II link/) === -1 ||
                templateJson.search(/https:\/\/static\.pexels\.com\/photos\/248280\/pexels-photo-248280\.jpeg/) === -1 ||
                templateJson.match(/https:\/\/static\.pexels\.com\/photos\/248280\/pexels-photo-248280\.jpeg/g).length!==2
            );
        else if(row_category==='webinar_block') {
           return !(
                templateJson.search(/My Webinar Title: How to Host a Great Webinar/) === -1 ||
                templateJson.search(/Friday, March 11, 2022/) === -1 ||
                templateJson.search(/5:00 PM Eastern Time/) === -1 ||
                templateJson.search(/My Description/) === -1 ||
                templateJson.search(/registration_link/) === -1 
            )
        }
        else if(row_category === 'landing_page')
            return templateJson.search(/web_page_url/) !== -1;
    }

    return (
        <>
            <LoaderWrapper isLoading={pageLoader}>
                <Flex flexDirection='column' width="100%">
                    {/* <BeeHeader
                        beeEditorInstance={beeInstance}
                        enable={true}
                        oldView
                        fromCustomRow={true}
                        backHandler={() => history.push('/campaign/custom-row')}
                    /> */}
                    <div id='bee-plugin-container' style={{width: '100%', height: '80vh', margin: '15px 0px' }} />
                    <TemplateFooter 
                        enable={loadHeader}
                        beeInstance={beeInstance}
                        backHandler={() => history.push('/templates?tab=rows')}
                    />
                </Flex>
                {openSaveModal && (
                    <ConfirmationModalV2 
                        isModalOpen={openSaveModal}
                        onClose={closeModalHandler}
                        btnCancelName="Cancel"
                        btnOkName="Save"
                        title={(id.includes('new') || (get(rowData, 'is_cascade', false) === true)) ? 'Create a New Row Template' : 'Do you want to update current Row?'}
                        loading={saveLoader}
                        progressMessage={progressMessage}
                        onSubmit={() => {
                            if((id.includes('new') || (get(rowData, 'is_cascade', false) === true))) {
                                createHandler();
                            } else {
                                updateHandler();
                            }
                        }}
                        disabled={openErrorModal}
                        modalWidth="30%"
                        message={errorMessage}
                        inputField={(id.includes('new') || (get(rowData, 'is_cascade', false) === true))}
                        name={customRowName}
                        setName={setCustomRowName}
                        placeHolder="Add A Row Name"
                    />
                )}
            </LoaderWrapper>
        </>
    );
}

const mapStateToProps = state => ({
    userData: state.user.data
})

export default connect(mapStateToProps, null)(CustomRowsEditorContainer);