/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { 
    WhiteButton,
    NewListSubtitle,
    TextInput,
    TextLabel,
    ErrorMessage,
    ButtonContainer,
    AddButton,
    EmptyStyle
} from './EmailList.styles';
import { 
    ChipStyle,
    SearchBox, 
    SearchResultRow,
    SearchTitle,
    SearchResults,
    ActionIcon,
    SegmentContainer,
    InnerContainer
} from './CreateSegmentStyles.styles';
import { listAllTags, updateSegment } from '../../../services/segment';
import includeIcon from './includeIcon.svg';
import excludeIcon from './excludeIcon.svg';
import { Select, MenuItem } from '@mui/material';
import { createSegment } from '../../../services/segment';
import { objectToFormData } from '../../../services/helpers';
import { Flex } from '@rebass/grid';
import { ColorPallete, Typography } from '../../../utils';

const CreateSegmentModal = ({ 
    closeModal, 
    selectedSegmentObj = {}, 
    isNewSegment = false, 
    fetchSegments,
    setTableLoading,
    setListLoading,
    campaignFlow=false
}) => {
    const [newSegmentName, setNewSegmentName] = useState();
    const [searchString, setSearchString] = useState('');
    const [includedTags, setIncludedTags] = useState([]);
    const [excludedTags, setExcludedTags] = useState([]);
    const [searchTagsArr, setSearchTagsArr] = useState([]);
    const [nameError, setNameError] = useState(false);
    const [excludeType, setExcludeType] = useState('Any of these tags');
    const [includeType, setIncludeType] = useState('Any of these tags');
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(()=> {
        getTopics()
        if(selectedSegmentObj && !isNewSegment){
            setNewSegmentName(selectedSegmentObj?.name)
            parseAllTags()
        }
    },[]);

    const getTopics = async() => {
        const topics = await listAllTags();
        setSearchTagsArr(topics.result.data)
    }

    const addTopic = (element, type) => {
        if(type === 'include' && !includedTags.includes(element) && !excludedTags.includes(element)){
            setIncludedTags([...includedTags, element])
        } else if(type === 'exclude' && !includedTags.includes(element) && !excludedTags.includes(element)){
            setExcludedTags([...excludedTags, element])
        }
    };

    const deleteChip = (input, type) => {
        if(type === 'include'){
            const newChips = includedTags.filter((item) => item !== input)
            setIncludedTags([...newChips])
        } else if(type === 'exclude'){
            const newChips = excludedTags.filter((item) => item !== input)
            setExcludedTags([...newChips])
        }
       
    };

    const submitHandler = async() => {
        setErrorMessage('');
        let includeObj = {};
        if(includeType === 'Any of these tags'){
            includeObj = {
                tags: {
                    any: includedTags,
                    all: [],
                }
            }
        } else if(includeType === 'All of these tags'){
            includeObj = {
                tags: {
                    all: includedTags,
                    any: [],
                }
            }
        };

        let excludeObj = {};
        if(excludeType === 'Any of these tags'){
            excludeObj = {
                tags: {
                    any: excludedTags,
                    all: [],
                }
            }
        } else if(excludeType === 'All of these tags'){
            excludeObj = {
                tags: {
                    all: excludedTags,
                    any: [],
                }
            }
        };

        const payload = {
            name: newSegmentName,
            search_rule: JSON.stringify({
              include: includeObj,
              exclude: excludeObj
            }),
        };

        try{
            if(!campaignFlow) {
                setTableLoading(true)
                setListLoading(true)
            }
            
            if(selectedSegmentObj && !isNewSegment){
                await updateSegment(selectedSegmentObj.id, objectToFormData(payload))
            }
            else await createSegment(objectToFormData(payload))
            closeModal();
            await fetchSegments();
            if(!campaignFlow) {
                setTableLoading(false)
                setListLoading(false)
            }  
        }catch(err){
            setErrorMessage(err?.response?.data?.result?.error?.message);
            setTableLoading(false)
            setListLoading(false)
        }
    };

    const parseAllTags = () => {
        let excludedAny = selectedSegmentObj?.search_rule?.exclude?.tags?.any;
        let includedAny = selectedSegmentObj?.search_rule?.include?.tags?.any;
        let excludedAll = selectedSegmentObj?.search_rule?.exclude?.tags?.all;
        let includedAll = selectedSegmentObj?.search_rule?.include?.tags?.all;

        let parseIncluded = [];
        let parseExcluded = [];

        for(let obj in includedAny){
            parseIncluded.push(includedAny[obj])
        }

        for(let obj in excludedAny){
            parseExcluded.push(excludedAny[obj])
        }

        for(let obj in includedAll){
            parseIncluded.push(includedAll[obj])
        }

        for(let obj in excludedAll){
            parseExcluded.push(excludedAll[obj])
        }
        setIncludedTags([...parseIncluded])
        setExcludedTags([...parseExcluded])
    };

    const emptyDisplay = () => {
        return(
            <div>
                <EmptyStyle style={{height: 40, margin: '20px auto 0'}}>No Results Found</EmptyStyle>
            </div>
        )
    };

    const dropdownDisplay = (type) => {
        const dropdownItems = ['Any of these tags', 'All of these tags'];
        const outputFunction = {
            "Include": setIncludeType,
            "Exclude": setExcludeType
        }
        return(
            <TextLabel>
                <div style={{margin: '12px 20px 0 0'}}>{type} Contacts With:</div>
                <Select
                  id="inputType"
                  name="inputType"
                  required={true}
                  sx={{margin: '4px 0 6px', height: 40}}
                  onChange={(e) => {
                    outputFunction[type](e.target.value);
                  }}
                  value={type === 'Include' ? includeType : excludeType}
                >
                    {dropdownItems.map((item, index) => (
                        <MenuItem key={index} value={item}>
                            {item}
                        </MenuItem>
                    ))}
                </Select>
            </TextLabel>
        )
    }

    const modalFlow = () => {
        let searchDisplayData;
        let dataStartingWith = searchTagsArr.filter((item) => item.toLowerCase().startsWith(searchString.toLowerCase()))
        if(dataStartingWith.length > 0) {
            searchDisplayData = dataStartingWith
        } else {
            searchDisplayData = searchTagsArr.filter((item) => item.toLowerCase().includes(searchString.toLowerCase()))
        }

        return(
            <SegmentContainer>
                <InnerContainer>
                    <TextLabel> Add Segment Name
                        <TextInput
                            width="50%"
                            type="text"
                            id="name"
                            name="Name"
                            placeholder="Segment name"
                            value={newSegmentName}
                            onChange={(e) => setNewSegmentName(e.target.value)}
                        />
                        {nameError && <ErrorMessage>Please create a name for your new segment.</ErrorMessage>}
                    </TextLabel>
                    <div>
                        <SearchTitle style={{margin: '12px 0 6px'}}>
                            {'Search and Select from Custom and Clout-automated Tags'}
                        </SearchTitle>
                        <div style={{display: 'flex'}}>
                            <SearchBox 
                                value={searchString} 
                                placeholder={"Search..."} 
                                onChange={(e)=>setSearchString(e.target.value)}
                            ></SearchBox>
                             <AddButton 
                                style={{height: 40, margin: '0 20px', width: 'fit-content', whiteSpace: 'no-wrap', padding: '10px 12px 0px'}} 
                                children={'Create Custom Tag'} 
                                onClick={()=>addTopic(searchString, 'include')} 
                                disabled={!searchString}
                            />
                        </div>
                        {
                            searchString.length > 0 && <>
                                <SearchResults style={{height: '120px', maxHeight: '120px', overflowY: 'auto'}}>
                                    {
                                        searchDisplayData.map((theme, index) => {                                           
                                            if(index < 6){
                                                return (
                                                    <SearchResultRow key={index}>
                                                        <div style={{margin: '5px 0 0'}}>{theme}</div>
                                                        <div>
                                                            <ActionIcon
                                                                onClick={()=>addTopic(theme, 'include')} 
                                                                src={includeIcon} 
                                                                alt="add" 
                                                            />
                                                            <ActionIcon
                                                                onClick={()=>addTopic(theme, 'exclude')} 
                                                                src={excludeIcon} 
                                                                alt="delete" 
                                                            />
                                                        </div>
                                                    </SearchResultRow>
                                                )
                                            }
                                        })
                                    }
                                    {
                                        !searchDisplayData.length && emptyDisplay()
                                    }
                                </SearchResults>
                            </>
                        }
                    </div>
                    
                    <NewListSubtitle style={{margin: '10px 0'}}>Included Tags</NewListSubtitle>
                   
                    <div>
                        {
                            includedTags && includedTags.map((item, index) =>{
                                return (
                                    <ChipStyle 
                                        style={{
                                            margin: '0px 5px 5px 0',
                                            color: '#00B186',
                                            border: '1px solid #00B186',
                                            background: '#f3fffc',
                                        }} 
                                        variant="outlined" 
                                        key={index} 
                                        label={item} 
                                        onDelete={()=>deleteChip(item, 'include')} 
                                    />
                                )
                            })
                        }
                    </div>
                    {
                        dropdownDisplay('Include')
                    }
                    <NewListSubtitle style={{margin: '10px 0'}}>Excluded Tags</NewListSubtitle>
                    <div>
                        {
                            excludedTags && excludedTags.map((item, index) =>{
                                return (
                                    <ChipStyle 
                                        style={{
                                            margin: '0px 5px 5px 0',
                                            border: '1px solid #E00000',
                                            color: '#FA2238',
                                            background: '#fff6f7'
                                        }} 
                                        variant="outlined" 
                                        key={index} 
                                        label={item} 
                                        onDelete={()=>deleteChip(item, 'exclude')} 
                                    />
                                )
                            })
                        }
                    </div>
                    {
                        dropdownDisplay('Exclude')
                    }
                </InnerContainer>
                <ButtonContainer style={{margin: '10px 0 -10px', borderTop: '1px solid #E4E7EC'}}>
                    <WhiteButton 
                        style={{width: '49%', height: 40, padding: '10px 0 0px'}} 
                        children={'Cancel'} 
                        onClick={()=>closeModal()} 
                    />
                    <AddButton 
                        style={{ width: '49%', height: 40, margin: '10px 0 0'}} 
                        children={selectedSegmentObj && !isNewSegment ? 'Update Segment' : 'Create Segment'} 
                        onClick={()=> submitHandler()} 
                        disabled={!newSegmentName || (includedTags.length < 1 && excludedTags.length < 1)} 
                    />
                </ButtonContainer>
                {errorMessage && (
                    <Flex justifyContent="center" fontSize={Typography.TextMedium} color={ColorPallete.primaryPurple} mt="5px">
                        {errorMessage}
                    </Flex>
                )}
            </SegmentContainer>
        )
    };

  
    return(
        <div>
            {
                modalFlow()
            }
        </div>
    )
};

export default CreateSegmentModal;