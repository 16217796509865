import { default as userReducer } from './modules/login.module';
import { default as exploreReducer } from './modules/explore-tab.module';
import { default as advisorGroupsReducer } from './modules/advisor-group.module';
import { default as inviteReducer } from './modules/invite.module';
import { default as contentlibReducer } from './modules/content-lib.module';
import { default as shareReducer } from './modules/share.module';
import { default as groupReducer } from './modules/group.module';
import { default as portfolioReducer } from './modules/portfolio.module';
import { default as contactsReducer } from './modules/contacts.module';
import { default as themeReducer } from './modules/theme.module';
import { default as membersReducer } from './modules/members.module';
import { default as channelReducer } from './modules/channel.module';
import { default as profileReducer } from './modules/profile.module';
import { default as analyticsReducer } from './modules/analytics.module';
import { default as complianceReducer } from './modules/compliance.module';
import { default as socialNetworkReducer } from './modules/socialNetwork.module';
import { default as editorReducer } from './modules/editor-settings.module';
import { default as articleDetailReducer } from './modules/article-detail.module';
import { default as newsletterReducer } from './modules/newsletter.module';
import { default as campaignReducer } from './modules/campaign.module';
import { default as campaignDetailsReducer } from './modules/campaignDetails.module';
import { default as complianceTrailReducer } from './modules/compliance-trail.module';
import { default as enterpriseReducer } from './modules/enterprise.module';
export const reducers = {
  user: userReducer,
  explore: exploreReducer,
  advisorGroups: advisorGroupsReducer,
  invite: inviteReducer,
  contentlib: contentlibReducer,
  share: shareReducer,
  group: groupReducer,
  portfolio: portfolioReducer,
  contacts: contactsReducer,
  theme: themeReducer,
  members: membersReducer,
  channel: channelReducer,
  profile: profileReducer,
  analytics: analyticsReducer,
  compliance: complianceReducer,
  socialNetwork: socialNetworkReducer,
  editorReducer: editorReducer,
  articleDetail: articleDetailReducer,
  newsletter: newsletterReducer,
  campaign: campaignReducer,
  campaignDetails: campaignDetailsReducer,
  complianceTrail: complianceTrailReducer,
  enterprise: enterpriseReducer,
};
