import React, { useState, useEffect } from 'react';
import {
	PageContainer,
	CardContainer
} from './styles';
import TemplateCards from '../../componentsV2/TemplateCards';
import { get, isEmpty } from 'lodash';
import { parse } from 'json2csv';
import {
	cascadeEnterpriseUsers,
	cascadeLandingPage,
	cloneLandingPage,
	complianceLandingPageCreate,
	deleteComplianceLandingPages,
	deleteLandingPage,
	downloadFormDataCSV,
	getApproveToSharePages,
	getLandingPages,
	rejectComplianceLandingPage
} from '../../services/bee-editor';
import { useHistory } from 'react-router-dom';
import ExportToPdf from '../DripCampaignContainerV2/DripDashboardTable/CampaignMenu/ExportToPdf';
import CardSkeleton from './CardSkeleton';
import ConfirmationModal from '../../components/ConfirmationModal/ConfirmationModal';
import { ROLE_TYPES, saveFile } from '../../utils';
import { connect } from 'react-redux';
import { getRoleType, getUserRole } from '../../redux/selector';
import CascadeModal from './CascadeModal';
import { ConfirmationModalV2, RadioButton } from '../../componentsV2';
import { Flex } from '@rebass/grid';


const LandingPages = ({
	searchString,
	showCreateModal,
	setShowCreateModal,
	isEnterpriseLevelAdmin,
	membersList,
	firmsArray,
	isAdmin
}) => {
	const [pageList, setPageList] = useState([]);
	const [pageLoader, setPageLoader] = useState(false);
	const [csvLoader, toggleCsvLoader] = useState(false);
	const [deleteLoader, toggleDeleteLoader] = useState(false);
	const [deleteModal, toggleDeleteModal] = useState({ status: false, id: '' });
	const [downloadCsvModal, toggleDownloadCsvModal] = useState({ status: false, id: '' })
	const [shareLoader, toggleShareLoader] = useState(false);
	const [showCascadeModal, toggleCascadeModal] = useState({ status: false, id: '' });
	const [modalMessage, setModalMessage] = useState('');
	const [openExportPdfModal, toggleExportToPdfModal] = useState({ status: false, pageObj: {} });
	const [openDuplicateModal, toggleDuplicateModal] = useState({ status: false, pageObj: {} });
	const [selectedFirms, setSelectedFirms] = useState([]);
	const [modalLoader, toggleModalLoader] = useState(false);
	const [OGSelectedAdvisors, setOGSelectedAdvisors] = useState([]);
	const [selectedAdvisors, setSelectedAdvisors] = useState([]);
	const [cascadeModalOpen, setCascadeModalOpen] = useState({ status: false, id: '' });
	const [selectedFirmAdvisors, setSelectedFirmAdvisors] = useState([]);
	const [complianceModal, setComplianceModal] = useState(false);
	const [selectedLandingPage, setSelectedLandingPage] = useState(null);
	const [selectedTab, setSelectedTab] = useState(0);
	const [lpName, setLpName] = useState('');

	const history = useHistory();

	useEffect(() => {
		fetchLandingPages();
	}, []);



	const leadFormMapper = {
		'Option_0': '',
		'Option_1': 'I already work with a financial advisor but am interested to discuss this topic in more detail',
		'Option_2': 'I am not looking for a financial advisor but am interested to discuss this topic in more detail',
		'Option_3': 'I am looking for a financial advisor and am interested to discuss this topic in more detail',
	}


	const rejectLandingPage = () => {
		toggleModalLoader(true);
		rejectComplianceLandingPage({
			id: selectedLandingPage._id,
			payload: {
				action: 'rejected',
				message: 'User has done'
			}
		}).then(res => {
			toggleModalLoader(false);
			history.push(`/templates/landing-pages/${selectedLandingPage._id}`);
		}).catch(err => {
			console.log(err);
			toggleModalLoader(false);
		})
	}

	const fetchLandingPages = async () => {
		setPageLoader(true);
		try {
			const response = await getLandingPages()
			setPageList(response?.data)
			setPageLoader(false);
		} catch (err) {
			console.log('Landing Page template fetch err:', err)
			setPageLoader(false);

		}
	};


	const handleSelectedAdvisors = (item) => {
		if (item === 'all') {
			if (selectedAdvisors.length === membersList.length) {
				setSelectedAdvisors([]);
			} else {
				setSelectedAdvisors(membersList.map(m => m.id));
			}
		}
		else if (isEmpty(selectedAdvisors)) {
			setSelectedAdvisors([item])
		} else if (!isEmpty(selectedAdvisors.filter(s => s === item))) {
			setSelectedAdvisors(selectedAdvisors.filter(s => s !== item));
		} else {
			setSelectedAdvisors([...selectedAdvisors, item])
		}
	}

	const handleSelectedAdmin = (item) => {
		if (item === 'all') {
			if (selectedFirms.length === firmsArray.length) {
				setSelectedFirms([]);
			} else {
				setSelectedFirms(firmsArray.map(m => m.id));
			}
		}
		else if (isEmpty(selectedFirms)) {
			setSelectedFirms([item])
		} else if (!isEmpty(selectedFirms.filter(s => s === item))) {
			setSelectedFirms(selectedFirms.filter(s => s !== item));
		} else {
			setSelectedFirms([...selectedFirms, item])
		}
	}

	const handleSelectedFirmAdvisors = (item) => {
		if (item === 'all') {
			if (selectedFirmAdvisors.length === firmsArray.length) {
				setSelectedFirmAdvisors([]);
			} else {
				setSelectedFirmAdvisors(firmsArray.map(m => m.id));
			}
		}
		else if (isEmpty(selectedFirmAdvisors)) {
			setSelectedFirmAdvisors([item])
		} else if (!isEmpty(selectedFirmAdvisors.filter(s => s === item))) {
			setSelectedFirmAdvisors(selectedFirmAdvisors.filter(s => s !== item));
		} else {
			setSelectedFirmAdvisors([...selectedFirmAdvisors, item])
		}
	}

	const deleteHandler = () => {
		setModalMessage('');
		toggleDeleteLoader(true);
		deleteLandingPage({ id: deleteModal.id })
			.then(res => {
				if (!res.data.skip_compliance) {
					return deleteComplianceLandingPages({ id: res.data._id });
				} else {
					console.log('RES DELETE - - ', res);
					toggleDeleteLoader(false);
					toggleDeleteModal({ status: false, id: '' });
					fetchLandingPages();
				}
			})
			.then(res => {
				console.log('RES DELETE - - ', res);
				toggleDeleteLoader(false);
				toggleDeleteModal({ status: false, id: '' });
				fetchLandingPages();
			})
			.catch(err => {
				toggleDeleteLoader(false);
				setModalMessage('Oops! An error occurred. Please try again later...')
				console.log('ERROR - - - -- ', err);
			})
	}

	const closeModalHandler = source => {
		setModalMessage('');
		const updateObj = { status: false, id: '' }
		if (source === 'delete')
			toggleDeleteModal(updateObj);
		else if (source === 'cascade')
			toggleCascadeModal(updateObj);
		else if (source === 'csv')
			toggleDownloadCsvModal(updateObj);
		else if (source === 'duplicate')
			toggleDuplicateModal({ status: false, pageObj: {} })
	}

	const onShareClick = () => {
		setModalMessage('');
		toggleShareLoader(true);
		if (isEnterpriseLevelAdmin) {
			let adminPayload = {
				cascaded_to: selectedFirms,
				type: 'admin'
			}
			let advisorPayload = {
				cascaded_to: selectedFirmAdvisors,
				type: 'advisor',
			}

			cascadeLandingPage({
				id: showCascadeModal.id || cascadeModalOpen.id,
				payload: {
					cascaded_to: !isEmpty(selectedAdvisors) ? JSON.stringify(selectedAdvisors) : []
				}
			}).then(res => {
				return cascadeEnterpriseUsers({
					payload: adminPayload,
					id: cascadeModalOpen.id
				})
			}).then(res => {
				return cascadeEnterpriseUsers({
					payload: advisorPayload,
					id: cascadeModalOpen.id
				})
			}).then(res => {
				console.log('res ---', res);
				setModalMessage('Success!');
				setTimeout(() => {
					setCascadeModalOpen({ status: false, id: '' });
					setModalMessage('');
					toggleShareLoader(false);
					toggleModalLoader(false);
					toggleCascadeModal({ status: false, id: '' });
					fetchLandingPages();
				}, 3000);
			}).catch(err => {
				console.log('ERROR - - --  -', err);
				setModalMessage('Oops..! An error occurred. Please try again...');
				toggleShareLoader(false);
				toggleModalLoader(false);
			})
		} else {
			cascadeLandingPage({
				id: showCascadeModal.id || cascadeModalOpen.id,
				payload: {
					cascaded_to: !isEmpty(selectedAdvisors) ? JSON.stringify(selectedAdvisors) : []
				}
			})
				.then(res => {
					console.log('RESPONSE -- - - ', res);
					setModalMessage('Success!');
					setTimeout(() => {
						setCascadeModalOpen({ status: false, id: '' });
						setModalMessage('');
						toggleShareLoader(false);
						toggleModalLoader(false);
						toggleCascadeModal({ status: false, id: '' });
						fetchLandingPages();
					}, 3000);
				})
				.catch(err => {
					console.log('ERROR - - --  -', err);
					setModalMessage('Oops..! An error occurred. Please try again...');
					toggleShareLoader(false);
					toggleModalLoader(false);
				});
		}


	}

	const downloadCsvHandler = () => {
		console.log('CSV PAGE - -- - ', downloadCsvModal.id);
		toggleCsvLoader(true);
		setModalMessage('');
		downloadFormDataCSV({ id: downloadCsvModal.id })
			// axios.get(`http://localhost:4000/download/csv/${downloadCsvModal.id}`)
			.then(res => {
				console.log('RESPONSE - -- - ', res);
				const formDataArray = get(res, 'formData', []).map(ele => ele.form_data);
				console.log('FORM DATA ARRAY - - - --  ', formDataArray);
				toggleCsvLoader(false);
				let csvData;
				if (formDataArray.length > 0) {
					if (formDataArray[0].form_type === 'event_form') {
						csvData = parse(formDataArray, {
							fields: ['first_name', 'last_name', 'email', 'phone_number', 'dropdown_1', 'dropdown_2', 'dropdown_3', 'dropdown_4', 'dropdown_5', 'privacy-policy', 'rsvp'],
						});
					} else if (formDataArray[0].form_type === 'lead_form') {
						formDataArray.forEach(ele => {
							if (ele.preferrence_drop_down) {
								ele.preferrence_drop_down = leadFormMapper[ele.preferrence_drop_down] ? leadFormMapper[ele.preferrence_drop_down] : ele.preferrence_drop_down && ele.preferrence_drop_down > 0 ? ele.preferrence_drop_down[0] : ele.preferrence_drop_down;
							}
						})
						csvData = parse(formDataArray, {
							fields: ['first_name', 'last_name', 'email', 'preferrence_drop_down', 'privacy-policy'],
						})
					} else if(formDataArray[0].form_type === 'webinar_form') {
						csvData = parse(formDataArray, {
							fields: ['first_name', 'last_name', 'email', 'privacy-policy', 'rsvp'],
						});
					} else {
						csvData = parse(formDataArray, {
							fields: ['first_name', 'last_name', 'email', 'privacy-policy'],
						});
					}

					const clickedPage = pageList.find(page => page._id === downloadCsvModal.id);
					const pageName = clickedPage.name
					saveFile(csvData, `${pageName}_${new Date().getTime()}`, 'csv');
					toggleDownloadCsvModal({ status: false, id: '' });
				}
				else
					setModalMessage('No data found!');
			})
			.catch(err => {
				console.log('ERROR DOWNLOADING CSV - - - ', err);
				toggleCsvLoader(false);
				setModalMessage('Oops! Some error occurred...');
			});

	}

	const duplicateHandler = () => {
		console.log(openDuplicateModal.pageObj);
		toggleCsvLoader(true);
		cloneLandingPage({ id: openDuplicateModal.pageObj._id, name: lpName }).then(res => {
			complianceLandingPageCreate({
				name: res.data.name,
				record_id: res.data._id,
				page_html: res.data.page_html,
				s3_url: res.data.template_url,
				skip_compliance: 'true'
			}).then(res => {
				toggleCsvLoader(false);
				toggleDuplicateModal({ status: false, pageObj: {} });
				fetchLandingPages();
			}).catch(err => {
				toggleCsvLoader(false);
				setModalMessage('Oops! Something went wrong');
			})
		}).catch(err => {
			toggleCsvLoader(false);
			setModalMessage('Oops! Something went wrong');
		})
	}

	const handleShareModalOpen = (e, row) => {
		console.log(row);
		let currentPage = pageList.filter(p => p._id === row._id);
		setOGSelectedAdvisors(currentPage[0].cascaded_to);
		setSelectedAdvisors(currentPage[0].cascaded_to);
		console.log(currentPage);
		if (isEnterpriseLevelAdmin) {
			setSelectedFirms(currentPage[0].cascaded_to_admins);
			setSelectedFirmAdvisors(currentPage[0].cascaded_to_advisors);
			setCascadeModalOpen({ status: true, id: row._id })
		} else {
			setCascadeModalOpen({ status: true, id: row._id })
		}
	}

	const handleShareModalClose = () => {
		setCascadeModalOpen({ status: false, id: '' })
	}

	const fetchApproveToSharePages = async () => {
		setPageLoader(true);
		try {
			const response = await getApproveToSharePages()
			setPageList(response?.data)
			setPageLoader(false);
		} catch (err) {
			console.log('Landing Page template fetch err:', err)
			setPageLoader(false);

		}
	};

	const handleRadioButtonClick = (tab) => {
		setSelectedTab(tab);
		if(tab === 0) {
			fetchLandingPages();
		} else {
			fetchApproveToSharePages();
		}
	}


	return (
		<PageContainer>
			{!isAdmin && (
				<Flex width="100%">
					<RadioButton 
						checked={selectedTab === 0}
						label="My Landing Pages"
						onClick={() => {
							if(selectedTab !== 0) {
								handleRadioButtonClick(0)
							}
						}}
					/>

					<RadioButton 
						checked={selectedTab === 1}
						label="Approved To Share"
						onClick={() => {
							if(selectedTab !== 1) {
								handleRadioButtonClick(1)
							}
						}}
					/>
				</Flex>
			)}
			<CardContainer>
				{
					!pageLoader && pageList ? pageList.filter((item) => item.name.toLowerCase().includes(searchString.toLowerCase())).map((card, index) => {
						return (
							<TemplateCards
								key={index}
								cardData={card}
								cardType={'pages'}
								setShowDeleteModal={toggleDeleteModal}
								toggleDownloadCsvModal={toggleDownloadCsvModal}
								toggleDuplicateModal={toggleDuplicateModal}
								shareModalAction={handleShareModalOpen}
								setName={setLpName}
								isAdmin={isAdmin}
								tab={selectedTab}
							/>
						)
					}) : <div style={{ width: '100%' }}><CardSkeleton /></div>
				}
			</CardContainer>
			{/* {(deleteModal.status || downloadCsvModal.status) && (
                <ConfirmationModal 
                    modalTitle={
                        downloadCsvModal.status ? 
                        'Do you want to download CSV?' : 
                        'Are you sure you want to delete this Campaign Page? If any campaigns are scheduled to go that use this campaign page, they will not be sent.'
                    }
                    modalWidth='42%'
                    closeHandler={() => closeModalHandler(downloadCsvModal.status ? 'csv' : 'delete')}
                    clickHandler={downloadCsvModal.status ? downloadCsvHandler : deleteHandler}
                    loader={downloadCsvModal.status ? csvLoader : deleteLoader}
                    message={modalMessage}
                />
            )} */}

			{downloadCsvModal.status && (
				<ConfirmationModalV2
					isModalOpen={downloadCsvModal.status}
					btnCancelName="No"
					btnOkName="Yes"
					title="Do you want to download CSV?"
					onClose={() => closeModalHandler('csv')}
					onSubmit={downloadCsvHandler}
					loading={csvLoader}
					message={modalMessage}
					modalWidth="25%"
				/>
			)}

			{deleteModal.status && (
				<ConfirmationModalV2
					isModalOpen={deleteModal.status}
					btnCancelName="No"
					btnOkName="Yes"
					title="Are you sure you want to delete this Campaign Page?"
					subTitle="If any campaigns are scheduled to go that use this campaign page, they will not be sent."
					onClose={() => closeModalHandler('delete')}
					onSubmit={deleteHandler}
					loading={deleteLoader}
					message={modalMessage}
					modalWidth="25%"
				/>
			)}

			{openDuplicateModal.status && (
				<ConfirmationModalV2
					isModalOpen={openDuplicateModal.status}
					btnCancelName="No"
					btnOkName="Yes"
					title={`Are you sure you want to duplicate ${openDuplicateModal.pageObj.name}?`}
					onClose={() => closeModalHandler('duplicate')}
					loading={csvLoader}
					message={modalMessage}
					onSubmit={duplicateHandler}
					inputField
					placeHolder="Enter Landing Page Name"
					name={lpName}
					setName={setLpName}
					modalWidth="25%"
				/>
			)}
			{/* {openDuplicateModal.status && (
                <ConfirmationModal
                    modalTitle={`Are you sure you want to duplicate ${openDuplicateModal.pageObj.name}?`}
                    modalWidth='38%'
                    closeHandler={() => closeModalHandler('duplicate')}
                    clickHandler={duplicateHandler}
                    loader={csvLoader}
                    message={modalMessage}
                    nameField
                    name={lpName}
                    setName={setLpName}
                />
            )} */}
			{/* {
                cascadeModalOpen.status && isEnterpriseLevelAdmin && (
                    <CascadeEnterpriseModal
                        firmsArray={firmsArray}
                        memberAdvisorsArray={membersList}
                        closeHandler={closeCascadeModalHandler}
                        selectedFirms={selectedFirms}
                        setSelectedFirms={setSelectedFirms}
                        cascadeOption={cascadeOption}
                        OGCascadeOption={OGCascadeOption}
                        setOGCascadeOption={setOGCascadeOption}
                        setCascadeOption={setCascadeOption}
                        OGSelectedFirms={OGSelectedFirms}
                        loader={modalLoader}
                        cascadeHandler={handleEnterpriseLevelAdminShare}
                        fetchingInfo={fetchingCascadeInfo}
                        modalMessage={modalMessage}
                        membersList={membersList}
                        shareDisabled={shareDisabled}
                        shareValidationCheck={shareValidationCheck}
                        selectMembersHandler={selectMembersHandler}
                        selectedAdvisors={selectedAdvisors}
                        OGSelectedAdvisors={OGSelectedAdvisors}
                        selectedFirmAdvisors={selectedFirmAdvisors}
                        OGSelectedFirmAdvisors={OGSelectedFirmAdvisors}
                        setSelectedselectedFirmAdvisors={setSelectedselectedFirmAdvisors}
                        setOGSelectedFirmAdvisors={setOGSelectedFirmAdvisors}
                        modalTitle="Share Landing Page Template with the following advisors" 
                  />
                )
            } */}
			{/* {showCascadeModal.status && !isEnterpriseLevelAdmin && (
                <CascadeToAdvisorsModal
                    isOpen={showCascadeModal.status}
                    closeHandler={() => closeModalHandler('cascade')}
                    shareHandler={onShareClick}
                    modalTitle='Share Landing Page Template with the following advisors'
                    modalWidth='32%'
                    loader={shareLoader}
                    membersList={membersList}
                    OGSelectedAdvisors={pageList.find(page => page._id === showCascadeModal.id)?.cascaded_to || []}
                />
            )} */}

			{cascadeModalOpen.status && (
				<CascadeModal
					isModalOpen={cascadeModalOpen.status}
					title="Cascade Landing Page"
					onClose={handleShareModalClose}
					subTitle="Select users to share your landing page with"
					isEnterpriseAdmin={isEnterpriseLevelAdmin}
					advisorList={membersList}
					firmsArray={firmsArray}
					OGSelectedAdvisors={OGSelectedAdvisors}
					selectedAdvisors={selectedAdvisors}
					handleSelectedAdvisors={handleSelectedAdvisors}
					selectedAdmin={selectedFirms}
					handleSelectedAdmin={handleSelectedAdmin}
					selectedFirmAdvisors={selectedFirmAdvisors}
					handleSelectedFirmAdvisors={handleSelectedFirmAdvisors}
					disable={false}
					loading={shareLoader}
					onSubmit={onShareClick}
					message={modalMessage}
				/>
			)}

			{openExportPdfModal.status && (
				<ExportToPdf
					pageId={openExportPdfModal.pageObj._id}
					title={openExportPdfModal.pageObj.name}
					complianceStatus={openExportPdfModal?.pageObj?.compliance_status}
					handleClose={() => toggleExportToPdfModal({ status: false, pageObj: {} })}
					isOpen={openExportPdfModal.status}
					isLandingPage={true}
				/>
			)}
			{complianceModal && (
				<ConfirmationModal
					modalTitle="Are you sure you want to edit the Campaign Page?"
					closeHandler={() => {
						setComplianceModal(false);
						setSelectedLandingPage(null);
					}}
					clickHandler={rejectLandingPage}
					loader={modalLoader}
					modalWidth="40%"
					btnMargin="0px"
				/>
			)}
		</PageContainer>
	)
};

const mapStateToProps = state => {
	const isEnterpriseLevelAdmin = get(state, 'user.data.details.company.enterprise', "false") === "true";
	const isFirmLevelAdmin = get(state, 'user.data.details.company.firm', "false") === "true";
	const roleType = getRoleType(state);
	const isAdvisorEnterprise = roleType === ROLE_TYPES.ADVISOR_ENTERPRISE;
	return {
		isAdmin: getUserRole(state) === 'admin',
		membersList: state.user.membersList ? [
			...state.user.membersList,
		] : [],
		isEnterpriseLevelAdmin,
		isFirmLevelAdmin,
		firmsArray: get(state, 'user.data.details.company.firms', []),
		isAdvisorEnterprise
	};
};

export default connect(mapStateToProps)(LandingPages);