import styled from 'styled-components';

export const ArticleListWrapper = styled.div.attrs({
  className: 'article-list-wrapper',
})`
  display: flex;
  flex-direction: column;
  height: 346px;
  width: 100%;
  background-color: #ffffff;
  border: 1px solid #e4e4e4;
`;

export const NewsLetterArticleWrapper = styled.div.attrs({
  className: 'newsletter-article-wrapper',
})`
  display: flex;
  height: auto;
  flex-direction: column;
  flex: 0 0 auto;
  height: 100%;

  i {
    width: 240px;
    height: 275px;
    margin: 10px 0px;
  }
`;

export const BucketArticleWrapper = styled.div.attrs({
  className: 'bucket-article-wrapper',
})`
  display: flex;
  height: auto;

  i {
    width: 240px;
    height: 275px;
    margin: 10px 0px;
  }
`;
