import React, { useState } from 'react';
import { Flex } from '@rebass/grid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { LoaderWrapper } from '../../../../components';
import { SearchInput } from '../../Theme.styles';
import { IconWrapper } from '../../../../components/ListItemTile/ListItemTile.styles';

const ResultSearch = ({ allTopics, shortenTitle, loader, setLoader, subscribe }) => {
    
    const [searchString, setSearchString] = useState('');
    const [showSearchResults, toggleSearchResults] = useState(false);
    const [filteredTopics, setFilteredTopics] = useState([]);
    const [showGhostBtn, toggleGhostBtn] = useState(false);

    const searchHandler = () => {
        let topics = allTopics.filter(topic => topic.name.toLowerCase().includes(searchString.toLowerCase()));
        setFilteredTopics(topics);
        toggleSearchResults(true);
    }

    return (
        <div style={{width: '100%', textAlign: 'center' }}>
            <Flex justifyContent='space-between' alignItems='center' style={{ backgroundColor: '#e4e4e4', border: '1px solid #ccc', padding: '5px', borderRadius: '4px', width: '80%', margin: 'auto' }}>
                <SearchInput
                    placeholder="Search for a topic (e.g., 'Technology')"
                    margin='auto'
                    marginRight='auto'
                    value={searchString}
                    onChange={e => setSearchString(e.target.value)}
                    onKeyPress={e => e.key==='Enter' ? searchHandler() : {}}
                />
                <Flex alignItems='end'>
                    <div style={{height: '12px', width: '12px', marginRight: '4px', cursor: 'pointer'}} onClick={() => setSearchString('')}>
                        <img src='/assets/images/iconfinder_close.svg' />
                    </div>
                    <img src='/assets/images/iconfinder_search_blue.svg' onClick={() => searchHandler()} style={{cursor: 'pointer'}} />
                </Flex>
            </Flex>
            {showSearchResults && (
                <div style={{ textAlign: 'center', fontSize: '11px', marginTop: '25px' }}>
                    {filteredTopics.length>0 ? (
                        <ul 
                            style={{
                                display: 'inline-block',
                                width: '100%',
                                listStyleType: 'none',
                                overflow: 'hidden auto',
                                margin: '0px auto',
                                paddingLeft: '60px',
                                textAlign: 'left'
                            }}
                        >
                            {filteredTopics.map(topic => (
                                <div 
                                    style={{display: 'inline-block', marginBottom: '15px', position: 'relative'}}
                                    onMouseEnter={() => toggleGhostBtn(topic.name)} 
                                    onMouseLeave={() => toggleGhostBtn('')}
                                >
                                    <Flex 
                                        justifyContent='flex-start' 
                                        alignItems='center' 
                                        style={{
                                            border: '0px', 
                                            borderRadius: '4px', 
                                            backgroundColor: '#ececec', 
                                            color: '#6351ed', 
                                            fontSize: '10px',
                                            padding: '5px 10px 5px 1px',
                                            margin: '0px 20px',
                                            height: '40px',
                                            minWidth: '115px',
                                            maxWidth: '115px',
                                            cursor: 'pointer'
                                        }}
                                    >
                                        <img 
                                            src={topic.media_urls[0].path + topic.media_urls[0].id + '.' + topic.media_urls[0].ext}
                                            style={{
                                                width: '32px', 
                                                height: '32px', 
                                                borderRadius: '4px',
                                                margin: '0px 4px'
                                            }} 
                                        />
                                        <LoaderWrapper isLoading={loader === topic.name}>
                                            <p>{shortenTitle(topic.name)}</p>
                                        </LoaderWrapper>
                                    </Flex>
                                    {showGhostBtn===topic.name && !loader ? (
                                        <div 
                                            style={{ 
                                                position: 'absolute', 
                                                backgroundColor: '#fffc', 
                                                height: '40px', 
                                                width: '135px', 
                                                top: '0px', 
                                                left: '6px', 
                                                borderRadius: '4px',
                                                fontSize: '10px', 
                                                cursor: 'pointer',
                                                display: 'flex',
                                                justifyContent: 'flex-start',
                                                alignItems:'center',
                                                paddingLeft: '27px'
                                            }}
                                            onClick={() => {
                                                setLoader(topic.name);
                                                subscribe(topic);
                                            }}
                                        >
                                            <IconWrapper style={{marginBottom: '9px', marginRight: '10px'}}>
                                                <FontAwesomeIcon icon={faPlus} />
                                            </IconWrapper>
                                            <p>{topic.name}</p>
                                        </div>
                                    ) : null}
                                </div>
                            ))}
                        </ul>
                    ) : 'No Topics found' }
                </div>
            )}
        </div>
    );
}

export default ResultSearch;