import React from 'react'
import { ContentContainer } from '../../containers';
import withContentLayout from '../../hoc/withContentLayout';

const Content = (props) => {
    return (
        <ContentContainer {...props} />
    )
}

export default withContentLayout(Content, true);
