import { Flex } from '@rebass/grid';
import { get, isEmpty, uniq } from 'lodash';
import React, { useEffect, useState } from 'react';
import { LoaderWrapper } from '../../../components';
import { postSelectedArticles } from '../../../services/campaign';
import { getPremiumAdvisorChannelId } from '../../../services/dashboard';
import CampaignFooterButtons from '../CampaignFooterButtons/CampaignFooterButtons';
import {
  ContentArticlesContainer,
  SuggestedArticlesTab,
} from '../DripCampaignContainer.styles';
import CampaignCard from '../PlanCampaign/CampaignCard';
import { LineHorizontalSeparator } from '../PlanCampaign/PlanCampaign.styles';
import {
  SelectArticlesWrapper,
  SelectedArticlesContainer,
} from './SelectArticles.styles';

const SelectArticles = ({
  campaignSelectedArticles,
  suggestedArticles,
  getMyContentArticles,
  fetchBookmarkedArticles,
  fetchRecommendedArticles,
  toggleEnableNext,
  enableNext,
  setCreateScreenIndex,
  campaignSetState,
  campaignSelectedWeek,
  campaignId,
  onAssignContentTabChange,
  endCreateFlow,
  setAssignContentTab,
  isPremiumAdvisor,
  channelId,
  suggestedArticlesLoader,
  toggleSuggestedArticlesLoader,
}) => {
  const suggestedArticlesLabels = ['RECOMMENDED', 'BOOKMARKED', 'MY CONTENT'];
  const [activeSuggestedArticlesTab, setActiveSuggestedArticlesTab] =
    useState(2);
  const [loading, toggleLoading] = useState(false);
  const [selectedArticles, setSelectedArticles] = useState([]);

  useEffect(() => {
    if (!isEmpty(campaignSelectedArticles)) {
      toggleEnableNext(true);
      setSelectedArticles(campaignSelectedArticles);
    }
  }, []);

  useEffect(() => {
    if (activeSuggestedArticlesTab === 0) fetchRecommendedArticles();
    else if (activeSuggestedArticlesTab === 1) fetchBookmarkedArticles();
    else {
      toggleSuggestedArticlesLoader(true);
      if (isPremiumAdvisor) {
        // console.log('IS PREMIUM ADVISOR - - -', isPremiumAdvisor);
        getPremiumAdvisorChannelId()
          .then((res) => {
            // console.log('RESPONSE ');
            if (res && res.result && res.result.data) {
              const channel_id =
                res.result.data[Object.keys(res.result.data)[0]][0].id;
              getMyContentArticles(channel_id);
            }
          })
          .catch((err) => console.log('ERROR - - - - ', err));
      } else getMyContentArticles(channelId);
    }
  }, [activeSuggestedArticlesTab]);

  const addArticleHandler = (article) => {
    console.log('ARTICLE = = == = ', article);
    setSelectedArticles(uniq([...selectedArticles, article]));
    toggleEnableNext(true);
  };

  const removeArticleHandler = (index) => {
    const selectedArticlesCopy = [...selectedArticles];
    selectedArticlesCopy.splice(index, 1);
    if (selectedArticlesCopy.length === 0) toggleEnableNext(false);
    setSelectedArticles(selectedArticlesCopy);
  };

  const backHandler = () => {
    toggleEnableNext(true);
    setCreateScreenIndex(0);
  };

  const getPayloadObject = () => {
    const bucketArticles = selectedArticles.map((article) => {
      const returnObj = {
        article_id: article.id,
        theme_id: article.theme_id,
      };
      if (article.summaries) returnObj.summary = article.summaries;
      return returnObj;
    });
    const payloadObj = {
      campaign_id: campaignId,
      articles: JSON.stringify(bucketArticles),
    };
    return payloadObj;
  };

  const nextHandler = (save) => {
    toggleLoading(true);
    const payloadObj = getPayloadObject();
    campaignSetState({
      selectedArticles: selectedArticles,
      selectedWeek: campaignSelectedWeek,
      // selectedWeek: 'Week 1',
      // campaignArticles: [
      //     {
      //         platform: 'Email',
      //         day: 'Monday',
      //         time: '5:00 AM',
      //         content: false,
      //         schedule_time: 'Monday, 5:00 AM',
      //         engagement_type: 'share',
      //         week: 'Week 1',
      //         ref_id: 96,
      //         destination_snetwork: 'em',
      //     },
      //     {
      //         platform: 'Email',
      //         day: 'Monday',
      //         time: '5:00 AM',
      //         content: false,
      //         schedule_time: 'Monday, 5:00 AM',
      //         engagement_type: 'share',
      //         week: 'Week 2',
      //         ref_id: 42,
      //         destination_snetwork: 'em',
      //     },
      //     {
      //         platform: 'Email',
      //         day: 'Monday',
      //         time: '5:00 AM',
      //         content: false,
      //         schedule_time: 'Monday, 5:00 AM',
      //         engagement_type: 'share',
      //         week: 'Week 3',
      //         ref_id: 17,
      //         destination_snetwork: 'em',
      //     },
      //     {
      //         platform: 'Email',
      //         day: 'Monday',
      //         time: '5:00 AM',
      //         content: false,
      //         schedule_time: 'Monday, 5:00 AM',
      //         engagement_type: 'share',
      //         week: 'Week 4',
      //         ref_id: 98,
      //         destination_snetwork: 'em',
      //     },
      //     {
      //         platform: 'Facebook',
      //         day: 'Tuesday',
      //         time: '1:00 AM',
      //         content: false,
      //         schedule_time: 'Tuesday, 1:00 AM',
      //         engagement_type: 'share',
      //         week: 'Week 1',
      //         ref_id: 10,
      //         destination_snetwork: 'fb',
      //     },
      //     {
      //         platform: 'Twitter',
      //         day: 'Friday',
      //         time: '1:15 PM',
      //         content: false,
      //         schedule_time: 'Friday, 1:15 PM',
      //         engagement_type: 'share',
      //         week: 'Week 1',
      //         ref_id: 63,
      //         destination_snetwork: 'tw',
      //     },
      // ],
      // selectedDays: 'Mon-Tue-Fri'
    });
    postSelectedArticles({ payload: payloadObj })
      .then((res) => {
        toggleLoading(false);
        console.log('SAVE- - -- - ', save);
        if (save) {
          setAssignContentTab(0);
          endCreateFlow();
        } else {
          toggleEnableNext(false);
          onAssignContentTabChange('next');
        }
      })
      .catch((err) => {
        toggleLoading(false);
      });
    // toggleEnableNext(false);
    // toggleLoading(false);
    // onAssignContentTabChange('next');
  };

  return (
    <>
      <SelectArticlesWrapper>
        <ContentArticlesContainer className="selected-articles">
          <span
            style={{
              color: '#000000',
              width: '100%',
              textAlign: 'left',
              fontSize: '14px',
            }}
          >
            <strong>SELECTED CONTENT</strong>
          </span>
          <LineHorizontalSeparator style={{ margin: '10px 0px' }} />
          <Flex
            justifyContent="center"
            alignItems="center"
            width="95%"
            style={{
              overflowX: 'auto',
              overflowY: 'hidden',
              marginTop: '10px',
            }}
          >
            <SelectedArticlesContainer scrollHeight="10px">
              {selectedArticles.map((article, index) => (
                <CampaignCard
                  isClickable
                  isDraggable={false}
                  content={true}
                  {...article}
                  clickHandler={() => removeArticleHandler(index)}
                  fromAssignContent={true}
                />
              ))}
            </SelectedArticlesContainer>
          </Flex>
        </ContentArticlesContainer>

        <ContentArticlesContainer className="suggested-articles" mb="0px">
          <Flex
            justifyContent="space-between"
            alignItems="baseline"
            width="100%"
            padding="3px 10px 0px"
          >
            <Flex justifyContent="center" alignItems="center">
              {suggestedArticlesLabels.map((label, index) => (
                <SuggestedArticlesTab
                  onClick={() => setActiveSuggestedArticlesTab(index)}
                  active={activeSuggestedArticlesTab === index}
                >
                  {label}
                </SuggestedArticlesTab>
              ))}
            </Flex>
            <Flex justifyContent="center" alignItems="center">
              {/* <span style={{ color: '#4E6780', fontSize: '12px' }}>Filtered Articles By: </span> */}
            </Flex>
          </Flex>
          <LineHorizontalSeparator style={{ margin: '0px 0px 10px' }} />
          <Flex
            justifyContent="center"
            width="95%"
            alignItems="center"
            style={{
              overflowX: 'auto',
              overflowY: 'hidden',
              marginTop: '10px',
              minHeight: '250px',
            }}
          >
            <LoaderWrapper isLoading={suggestedArticlesLoader}>
              <SelectedArticlesContainer scrollHeight="10px">
                {suggestedArticles.length > 0 &&
                  suggestedArticles.map((article) => (
                    <CampaignCard
                      content={true}
                      {...article}
                      isClickable
                      clickHandler={() => addArticleHandler(article)}
                      fromAssignContent={true}
                    />
                  ))}
              </SelectedArticlesContainer>
            </LoaderWrapper>
          </Flex>
        </ContentArticlesContainer>
      </SelectArticlesWrapper>
      <CampaignFooterButtons
        saveAndCloseHandler={() => nextHandler(true)}
        loading={loading}
        nextHandler={() => nextHandler()}
        enableNext={enableNext}
        backHandler={backHandler}
      />
    </>
  );
};

export default SelectArticles;
