import React, { useState } from 'react';
import { Flex } from '@rebass/grid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { IconWrapper } from '../../../../components/ListItemTile/ListItemTile.styles';
import { LoaderWrapper } from '../../../../components';

const ResultBrowse = ({ allTopics, shortenTitle, loader, setLoader, subscribe }) => {

    const [selectedChar, setSelectedChar] = useState('A');
    const [showGhostBtn, toggleGhostBtn] = useState('');


    const getButtonsRow = () => {
        let btns = [];
        for(let i=65; i<=90; i++)
            btns.push(
                <div 
                    style={{
                        border: '1px solid #6351ed', 
                        backgroundColor: String.fromCharCode(i)===selectedChar ? '#6351ed' : '#fff', 
                        color: String.fromCharCode(i)===selectedChar ? '#fff' : '#6351ed', 
                        borderRadius: '4px',
                        padding: '0px 1px',
                        cursor: 'pointer'
                    }}
                    onClick={() => setSelectedChar(String.fromCharCode(i))}
                >
                    <div style={{ height: '22px', width: '22px', textAlign: 'center', margin: '2px auto -1px' }}>
                        <strong>{String.fromCharCode(i)}</strong>
                    </div>
                </div>
            );
        return btns;
    }
    console.log('ALL TOPICS = ', allTopics);
    return (
        <>
            <div style={{width: '100%', textAlign: 'center'}}>
                <Flex justifyContent='space-evenly' alignItems='center' style={{width: '85%', margin: '10px auto'}}>{getButtonsRow().slice(0, 13).map(ele => ele)}</Flex>
                <Flex justifyContent='space-evenly' alignItems='center' style={{width: '85%', margin: 'auto'}}>{getButtonsRow().slice(13, 26).map(ele => ele)}</Flex>
            </div>
            <div>
                <ul 
                    style={{
                        display: 'inline-block',
                        width: '100%',
                        listStyleType: 'none',
                        overflow: 'hidden auto',
                        margin: '25px auto 0px',
                        paddingLeft: '80px',
                        textAlign: 'left'
                    }}
                >
                    {allTopics.filter(topic => topic.name[0]===selectedChar).map(topic => (
                        <div 
                            style={{display: 'inline-block', marginBottom: '15px', position: 'relative'}}
                            onMouseEnter={() => toggleGhostBtn(topic.name)} 
                            onMouseLeave={() => toggleGhostBtn('')}
                        >
                            <Flex 
                                justifyContent='flex-start' 
                                alignItems='center' 
                                style={{
                                    border: '0px', 
                                    borderRadius: '4px', 
                                    backgroundColor: '#ececec', 
                                    color: '#6351ed', 
                                    fontSize: '10px',
                                    padding: '5px 10px 5px 1px',
                                    margin: '0px 20px',
                                    height: '40px',
                                    minWidth: '115px',
                                    maxWidth: '115px',
                                    cursor: 'pointer'
                                }}
                            >
                                <img 
                                    src={topic.media_urls[0].path + topic.media_urls[0].id + '.' + topic.media_urls[0].ext}
                                    style={{
                                        width: '32px', 
                                        height: '32px', 
                                        borderRadius: '4px',
                                        margin: '0px 4px'
                                    }} 
                                />
                                <LoaderWrapper isLoading={loader === topic.name}>
                                    <p>{shortenTitle(topic.name)}</p>
                                </LoaderWrapper>
                            </Flex>
                            {showGhostBtn===topic.name && !loader ? (
                                <div 
                                    style={{ 
                                        position: 'absolute', 
                                        backgroundColor: '#fffc', 
                                        height: '40px', 
                                        width: '135px', 
                                        top: '0px', 
                                        left: '0px', 
                                        borderRadius: '4px',
                                        fontSize: '10px', 
                                        cursor: 'pointer',
                                        display: 'flex',
                                        justifyContent: 'flex-start',
                                        alignItems:'center',
                                        paddingLeft: '33px'
                                    }}
                                    onClick={() => {
                                        setLoader(topic.name);
                                        subscribe(topic);
                                    }}
                                >
                                    <IconWrapper style={{marginBottom: '9px', marginRight: '10px'}}>
                                        <FontAwesomeIcon icon={faPlus} />
                                    </IconWrapper>
                                    <p>{topic.name}</p>
                                </div>
                            ) : null}
                        </div>
                    ))}
                </ul>
            </div>
        </>
    );
}

export default ResultBrowse;