import { Flex } from '@rebass/grid';
import styled from 'styled-components';

export const ExportPDFButton = styled(Flex)`
  padding: 0px 25px;
  padding-bottom: 5px;
  justify-content: center;
  align-items: center;
  width: max-content;
  float: right;
  font-size: 0.7rem;
  &:hover {
    cursor: pointer;
  }
`;

export const BottomBtns = styled.div`
  position: absolute;
  bottom: 0;
  margin-bottom: 2vw;
`;
