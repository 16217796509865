import { get, isEmpty } from 'lodash';
import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import { DashboardArticle, FreeArticle, Loader } from '../../../components';

import { getExploreArticleData, dashboardSliderSettings, eventHandlers } from '../../../utils';

import { ArticleSliderWrapper } from '../DashboardContainer.styles';

const BookmarksTab = ({
  selectedTopic,
  exploreArticles,
  deleteArticleRequest,
  themeNames,
  exploreArticleDeleting,
  isFreeUser,
  isShareBlocked,
  isDeleteBlocked,
  showMio,
  showTabLoader,
  setShowArticleLoader,
  newsletterArticleFetching,
  bookmarksFetching,
  bookmarksId,
  exploreNewsletterId,
  onNewsletterArticleClick,
  onRemoveFromNewletterClick
}) => {
  console.log('bookmarks tab...');
  const [imageCounter, setImageCounter] = useState([]);
  const [localExploreArticles, setLocalExploreArticles] = useState([]);
  const [totalArticles, setTotalArticles] = useState(0);

  useEffect(() => {
    console.log('explore articles = ', exploreArticles);
    let articles = getExploreArticleData(exploreArticles);
    console.log('articles - ', articles);
    setLocalExploreArticles(articles);
    setTotalArticles(articles.length);
    if(articles.length>0) {
      console.log('Setting article loader to false');
      setShowArticleLoader(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exploreArticles]);

  
  return (
    <React.Fragment>
      <ArticleSliderWrapper>
        <Slider {...dashboardSliderSettings}>
          {isFreeUser && <FreeArticle />}
          {localExploreArticles.map((article, index) => (
            <DashboardArticle
              key={'article_' + index}
              fromBookmarksTab={true}
              article={article}
              bookmarksFetching={bookmarksFetching}
              newsletterArticleFetching={newsletterArticleFetching}
              bookmarksId={bookmarksId}
              onNewsletterArticleClick={onNewsletterArticleClick}
              onRemoveFromNewletterClick={onRemoveFromNewletterClick}
              {...{
                ...article,
                selectedTopic,
                isFreeUser,
                deleteArticleRequest,
                themeNames,
                exploreArticleDeleting,
                isDeleteBlocked,
                showMio,
                index,
                setShowArticleLoader,
                totalArticles,
                setImageCounter,
                imageCounter,
                isShareBlocked,
                exploreNewsletterId
              }
              }
            />
          ))}
        </Slider>
      </ArticleSliderWrapper>
    </React.Fragment>
  );
};

export default BookmarksTab;
