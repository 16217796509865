import React, { useEffect, useRef } from 'react';
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalHeader,
  LoaderWrapper,
  ModalV2,
  Button,
} from '../../components';
import { Flex } from '@rebass/grid';
import { ContactListInput, ContactSearchInput } from './styles';
import {
  RadioInput,
  RadioInputLabel,
} from '../../components/CreateEmailListModal/V2/CreateEmailListModal.style';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { isEmpty } from 'lodash';
import ClearIcon from './icon/clear';
import { ErrorMessage } from 'formik';

const ContactListModal = ({
  isOpen,
  handleClose,
  tagList,
  handleClick,
  contactType,
  setContactType,
  loading,
  value,
  setValue,
  errorMessage,
  //listNamePresent
}) => {
  const [searchBoxInput, setSearchBoxInput] = useState('');
  
  const [searchResults, setSearchResults] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [dropdown, setDropdownOpen] = useState(true);
  const [contactTypeFlag1, setContactTypeFlag1] = useState(false);
  const [contactTypeFlag2, setContactTypeFlag2] = useState(false);
  const wrapperRef = useRef(null);

  useEffect(() => {
      /**
     * Alert if clicked on outside of element
     */
      function handleClickOutside(event) {
        if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
          setDropdownOpen(false);
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
  }, [wrapperRef])
  

  const handleSearch = (value) => {
    setDropdownOpen(true);
    setSearchResults(
      tagList.filter((t) => t.toUpperCase().includes(value.toUpperCase()))
    );
  };

  const handleDeleteClick = (item) => {
    const newSelectedTags = selectedTags.filter((i) => i !== item);
    setSelectedTags(newSelectedTags);
  };

  const handleDropdownClick = (item) => {
    if (isEmpty(selectedTags)) {
      setSelectedTags([item]);
    } else if (!isEmpty(selectedTags.filter((s) => s === item))) {
    } else {
      setSelectedTags([...selectedTags, item]);
    }
  };

  const contactTypeFlag1Handler = () => {
    if (contactTypeFlag1) {
      setContactTypeFlag1(!contactTypeFlag1);
      setContactType('');
    } else {
      setContactTypeFlag1(!contactTypeFlag1);
      setContactTypeFlag2(false);
      setContactType('prospect');
    }
  };

  const contactTypeFlag2Handler = () => {
    if (contactTypeFlag2) {
      setContactTypeFlag2(!contactTypeFlag2);
      setContactType('');
    } else {
      setContactTypeFlag2(!contactTypeFlag2);
      setContactTypeFlag1(false);
      setContactType('client');
    }
  };


  return (
    <ModalV2
      isOpen={isOpen}
      onRequestClose={handleClose}
      shouldCloseOnOverlayClick
      bgColor="white"
      width="40%"
    >
      <ModalCloseButton onClick={handleClose} />
      <ModalHeader
        style={{
          display: 'flex',
          justifyContent: 'flex-start',
          color: '#101010',
          fontSize: '20px',
          padding: '10px 10px',
        }}
      >
        Create Email list
      </ModalHeader>
      <ModalBody>
        <Flex display="flex" flexDirection="column" justifyContent="center">
          <label
            htmlFor="email-list-name"
            style={{
              color: '#101010',
              display: 'flex',
              justifyContent: 'flex-start',
              padding: '5px 2px',
              fontSize: '16px',
            }}
          >
            List Name*
          </label>
          <ContactListInput
            type="text"
            name="email-list-name"
            value={value}
            placeholder="Enter Email list name here"
            onChange={(e) => setValue(e.target.value)}
            //disabled={listNamePresent}
          />
        </Flex>
        <hr
          style={{
            width: '100%',
            border: '1px solid #E4E7EC',
          }}
        />
        <Flex display="flex" justifyContent="center" flexDirection="column">
          <Flex
            display="flex"
            justify="center"
            fontSize="16px"
            color="#101010"
            padding="5px 0px"
          >
            <strong>
              Do you want to add any tags to the contacts in this list?
            </strong>
          </Flex>
          <Flex style={{ textAlign: 'left' }}>
            Tags can be used to segment your contacts for targeted campaigns.
            Any tags you select here will be assigned to all contacts in this
            list.
          </Flex>
        </Flex>
        <Flex flexDirection="column" alignItems="flex-start" padding="15px 0px">
          <Flex
            style={{
              color: '#101010',
              fontSize: '16px',
              padding: '0px 0px 4px 0px',
            }}
          >
            Contact Type
          </Flex>
          <Flex display="flex" justifyContent="center" flexDirection="row">
            <Flex marginRight="5px" alignItems="center" justifyContent="center">
              {/* <RadioInput
                type="radio"
                name="contact_type"
                id="contact_type_prospect"
                onClick={() => {
                  setContactType('prospect');
                }}
                checked={contactType === 'prospect'}
                style={{ cursor: 'pointer' }}
              />
              <RadioInputLabel
                htmlFor="contact_type_prospect"
                style={{ cursor: 'pointer' }}
              >
                Prospects
              </RadioInputLabel>
            </Flex>
            <Flex marginLeft="5px" alignItems="center" justifyContent="center">
              <RadioInput
                type="radio"
                name="contact_type"
                id="contact_type_client"
                onClick={() => {
                  setContactType('client');
                }}
                checked={contactType === 'client'}
                style={{ cursor: 'pointer' }}
              />
              <RadioInputLabel
                htmlFor="contact_type_client"
                style={{ cursor: 'pointer' }}
              >
                Clients
              </RadioInputLabel> */}
              <Button
                margin="0px 10px 0 0"
                onClick={contactTypeFlag1Handler}
                bgColor={
                  contactTypeFlag1 ? 'rgb(99, 81, 237)' : '#fff'
                }
                borderColor={
                  contactTypeFlag1 ? 'rgb(99, 81, 237)' : 'rgb(212, 212, 212)'
                }
                color={contactTypeFlag1 ? '#fff' : '#000'}
                borderRadius="4px"
              >
                Prospect
              </Button>
              <Button
                margin="0px 10px 0 0"
                style={{ border: '0px' }}
                onClick={contactTypeFlag2Handler}
                bgColor={
                  contactTypeFlag2 ? 'rgb(99, 81, 237)' : '#fff'
                }
                borderColor={
                  contactTypeFlag2 ? 'rgb(99, 81, 237)' : 'rgb(212, 212, 212)'
                }
                color={contactTypeFlag2 ? '#fff' : '#000'}
                borderRadius="4px"
              >
                Client
              </Button>
            </Flex>
          </Flex>
        </Flex>
        <Flex fontSize="16px" color="#101010">
          Select and Search from Custom and Clout-automated Tags
        </Flex>
        <Flex
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            backgroundColor: '#FFFFFF',
            border: '1px solid #E4E7EC',
            borderRadius: '4px',
            padding: '0px 5px',
            margin: '9px 0px 9px',
            height: '30px',
            position: 'relative',
          }}
        >
          <img
            alt="search contact"
            src="/assets/images/color-search-icon.svg"
            style={{
              cursor: 'pointer',
              padding: '6px 6px 6px 0px',
              height: '32px',
            }}
            // onClick={
            //   searchString.length > 0 ? () => searchHandler() : () => { }
            // }
            onClick={handleSearch}
          />
          <ContactSearchInput
            placeholder="Search"
            spellCheck={false}
            onChange={(e) => setSearchBoxInput(e.target.value)}
            onClick={() => {setDropdownOpen(true);}}
            onKeyUp={(e) => {
              if (e.key === 'Backspace') {
                console.log('Backspace');
                handleSearch(e.target.value.substring(0, e.target.value - 1));
              } else {
                handleSearch(e.target.value);
                
              }
              
            }}
            value={searchBoxInput}
          />
          {!isEmpty(searchResults) && !isEmpty(searchBoxInput) && dropdown && (
            <div
              style={{ display: 'block', top: '29px' }}
              className="search-bar-dropdown"
              ref={wrapperRef}
            >
              <ul>
                {searchResults.map((element) => (
                  <li
                    key={element}
                    style={{
                      backgroundColor:
                        !isEmpty(selectedTags) && selectedTags.includes(element)
                          ? '#6351ed'
                          : '#fff',
                      color:
                        !isEmpty(selectedTags) && selectedTags.includes(element)
                          ? '#fff'
                          : 'black',
                    }}
                    onClick={() => {
                      handleDropdownClick(element);
                       //setSearchBoxInput('');
                    }}
                  >
                    {element}
                  </li>
                ))}
              </ul>
            </div>
          )}
        </Flex>
        <Flex
          flexWrap="wrap"
          width="100%"
          height={selectedTags.length !== 0 ? '70px' : '0px'}
          overflowY="auto"
          overflowX="hidden"
        >
          {!isEmpty(selectedTags) &&
            selectedTags.map((taggedElement, index) => (
              <Flex
                key={taggedElement}
                style={{
                  width: 'max-content',
                  padding: '6px 12px',
                  fontSize: '12px',
                  height: '30px',
                  backgroundColor: 'rgb(236, 242, 252)',
                  color: 'rgb(11, 92, 215)',
                  border: '1px solid rgb(182, 207, 243)',
                  borderRadius: '20px',
                  margin: '0px 6px 5px 0px',
                }}
              >
                {taggedElement}
                <Flex
                  style={{
                    left: '3px',
                    position: 'relative',
                    cursor: 'pointer',
                  }}
                  onClick={() => handleDeleteClick(taggedElement)}
                >
                  <ClearIcon />
                </Flex>
              </Flex>
            ))}
        </Flex>
        <hr
          style={{
            width: '100%',
            border: '1px solid #E4E7EC',
          }}
        />
        {errorMessage ? (
          <Flex
          justifyContent="center"
          alignItems="center"
          style={{ width: '100%', color: "#6351ed", padding: '5px', marginBottom: '7px' }}
        >
          {errorMessage}
        </Flex>
        ) : null}
        <LoaderWrapper isLoading={loading}>
          <Flex
            display="flex"
            justifyContent="space-between"
            flexDirection="row"
          >
            <Button
              style={{ borderRadius: '4px' }}
              height="40px"
              width="250px"
              margin="9px 10px 0px 0px"
              padding="5px 28px"
              bgColor="#fff"
              color="#101010"
              borderColor="#E4E7EC"
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button
              style={{ borderRadius: '4px' }}
              height="40px"
              width="250px"
              margin="9px 0px 0px 0px"
              padding="5px 28px"
              bgColor="#6351ed"
              color="#fff"
              borderColor="#fff"
              onClick={() => handleClick(selectedTags)}
              disabled={!value}
            >
              Create
            </Button>
          </Flex>
        </LoaderWrapper>
      </ModalBody>
    </ModalV2>
  );
};

export default ContactListModal;
