import React from 'react';
import {
  ModalBody,
  ModalCloseButton,
  ModalHeader,
  ModalV2,
} from '../../../components';
import CardModal from './CardModal';

const LeadCapturePreviewModal = ({
  profileCard,
  setProfileCard,
  profileName,
  firm_name,
  mobile,
  email,
  about_me,
  digital_banner,
  logo,
  socialLinks,
  userData,
}) => {
  return (
    <ModalV2
      onRequestClose={() => setProfileCard(!profileCard)}
      shouldCloseOnOverlayClick
      isOpen={profileCard}
      bgColor="white"
      width="30%"
    >
      <ModalHeader
        style={{
          padding: '20px 0px 0px 15px',
          fontSize: '20px',
          color: '#101828',
        }}
      >
        Profile Preview
      </ModalHeader>
      <ModalCloseButton onClick={() => setProfileCard(false)} />
      <ModalBody>
        <CardModal
          handleModalClose={() => {
            setProfileCard(false);
          }}
          name={profileName}
          firm_name={firm_name}
          mobile={mobile}
          email={email}
          about_me={about_me}
          digital_banner={digital_banner}
          logo={logo}
          socialLinks={socialLinks}
          userData={userData}
        />
      </ModalBody>
    </ModalV2>
  );
};

export default LeadCapturePreviewModal;
