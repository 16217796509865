import { Flex } from '@rebass/grid';
import React from 'react';

const CustomRowsFilterBar = ({ selected, changeHandler }) => {
    return (
        <Flex width='97%' justifyContent='flex-end'>
            <span style={{ marginRight: '18px', color: '#6351ed' }}>Filter</span>
            <select 
                style={{ width: '120px', textAlign: 'center', color: '#6351ed' }} 
                value={selected} 
                onChange={changeHandler}
            >
                <option value='all'>All</option>
                <option value='article_block'>Article block</option>
                <option value='signature'>Signature</option>
                <option value='disclaimer'>Disclaimer</option>
                <option value='header'>Header</option>
                <option value='footer'>Footer</option>
                <option value='landing_page'>Landing Page</option>
                <option value='webinar_block'>Webinar block</option>
                <option value='content_block'>Content block</option>
                <option value="disclosure">Disclosure Page</option>
            </select>
        </Flex>
    );
};

export default CustomRowsFilterBar;