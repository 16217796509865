import { Flex } from '@rebass/grid';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { get, result } from 'lodash'
import { Button, Input } from '../../../components/Form';
import { ThemeWrapper, ThemeContainer, ActiveTheme } from '../Theme.styles';
import { getPopularTheme, geYourTheme, getTrendingTopics } from '../../../services/dashboard/index';
import { getUserRole } from '../../../redux/selector/login.selectors';
import { USER_ROLE } from '../../../utils';
import ResultSearch from './ResultSearch/ResultSearch';
import ResultBrowse from './ResultBrowse/ResultBrowse';
import ResultPopular from './ResultPopular/ResultPopular';
import { LoaderWrapper, Modal, ModalBody, ModalCloseButton, ModalHeader } from '../../../components';
import { IconWrapper } from '../../../components/ListItemTile/ListItemTile.styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinus } from '@fortawesome/free-solid-svg-icons';
import { subscribeTheme, unsubscribeTheme } from '../../../services/dashboard/index';

const Theme = ({ isAdmin, roleType }) => {
    const [selectedTab, setSelectedTab] = useState('MY_TOPICS');
    const[selectedResultTab, setSelectedResultTab] = useState('search');
    const [myTopics, setMyTopics] = useState([]);
    const [allTopics, setAllTopics] = useState([]);
    const [popularTopics, setPopularTopics] = useState([]);
    const [trendingTopics, setTrendingTopics] = useState([]);
    const [submitTopicModal, toggleSubmitTopicModal] = useState(false);
    const [showGhostBtn, toggleGhostBtn] = useState('');
    const [topicLoader, toggleLoader] = useState('');
    const [searchTopicLoader, toggleSearchTopicLoader] = useState('');
    const [browseTopicLoader, toggleBrowseTopicLoader] = useState('');
    const [popularTopicLoader, togglePopularTopicLoader] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        let allTopics = [];
        geYourTheme()
        .then(response => {
            allTopics = get(response, 'result.data', [])
            setAllTopics(allTopics);
            const yourThemeResponse = allTopics.filter(theme => get(theme, 'subscription.status.application_status') === 'approved');
            if (yourThemeResponse.length > 0) {
                const midIndex = parseInt(yourThemeResponse.length / 2);
                const midTopic = yourThemeResponse[midIndex];
                const newThemeList = [
                    ...yourThemeResponse.slice(0, midIndex),
                    {
                        id:'FxxTyveTos',
                        name:'Featured Topics',
                        description:'Featured Topics',
                        media_url:'https://apicdn.myclout.com/theme/FeaturedTopics.svg'
                    },
                         ...yourThemeResponse.slice(midIndex)
                    ];    
                // if (newThemeList.length > 15) {
                //     newThemeList.pop();
                // }
                setMyTopics(newThemeList);
            } else {
                setMyTopics([
                    {
                        id:'FxxTyveTos',
                        name:'Featured Topics',
                        description:'Featured Topics',
                        media_url:'https://apicdn.myclout.com/theme/FeaturedTopics.svg'
                    },
                ]);
            }
            return getPopularTheme()
        })
        .then(response => {
            setPopularTopics(get(response, 'result.data', []));
            return getTrendingTopics()
        })
        .then(response => {
            let trendingTopics = get(response, 'result.data.topics', []);
            if(trendingTopics.length>0)
                trendingTopics = trendingTopics.map(topic => allTopics.find(ele => ele.id===topic.id))
            setTrendingTopics(trendingTopics);
        })
        .catch(err => console.log(err));
    }, []);


    const shortenTitle = title => {
        if (title.length > 11) 
            return title.substr(0, 11) + '...';
        else 
            return title;
        // if (title.includes(' ')) {
        //     let arr = title.split(' ');
        //     if(arr.length>2)
        //         return arr[0] + ' ' + '...';
        //     else if (arr[1].length > 11) 
        //         return arr[0] + ' ' + arr[1].substr(0, 6) + '...';
        //     else 
        //     return title;
        // } 
        // else {
        //   if (title.length > 11) 
        //     return title.substr(0, 11) + '...';
        //   else 
        //     return title;
        // }
    };


    const unsubscribeHandler = topic => {
        // setTimeout(() => {toggleLoader('')}, 3000);
        setErrorMessage('');
        toggleLoader(topic.name);
        unsubscribeTheme(topic.id)
        .then(response => {
            toggleLoader('');
            // toggleBrowseTopicLoader('');
            // togglePopularTopicLoader('');
            // toggleSearchTopicLoader('');
            let topics = myTopics.filter(top => top.id!==topic.id);
            setMyTopics(topics);
        })
        .catch(err => {
            toggleLoader('');
            // toggleBrowseTopicLoader('');
            // togglePopularTopicLoader('');
            // toggleSearchTopicLoader('');
            setErrorMessage('*An error Occurred...please try again.');
        });
        
    }

    const subscribeHandler = topic => {
        setErrorMessage('');
        let newTopicList = myTopics.filter(topic=>topic.name!=='Featured Topics');
        if(newTopicList.length<15) {
            let duplicateTopic = !(myTopics.every(top => top.id!==topic.id));
            if(!duplicateTopic) {
                // toggleLoader(topic.name);
                subscribeTheme(topic.id)
                .then(response => {
                    toggleLoader('');
                    toggleBrowseTopicLoader('');
                    togglePopularTopicLoader('');
                    toggleSearchTopicLoader('');
                    let topics = [...myTopics];
                    let addedTopic = allTopics.find(ele => ele.name === topic.name);
                    let insertIndex = -1;
                    topics.every((top, index) => {
                        if (top.name>addedTopic.name)
                            insertIndex = index;
                        return top.name<addedTopic.name;
                    });
                    topics.splice(insertIndex, 0, addedTopic);
                    setMyTopics(topics);
                })
                .catch(err => {
                    toggleLoader('');
                    toggleBrowseTopicLoader('');
                    togglePopularTopicLoader('');
                    toggleSearchTopicLoader('');
                    setErrorMessage('An error Occurred...please try again.');
                });
            }
            else {
                toggleBrowseTopicLoader('');
                togglePopularTopicLoader('');
                toggleSearchTopicLoader('');
                setErrorMessage('*You are already subscribed to this topic.');
            }
        }
        else {
            toggleBrowseTopicLoader('');
            togglePopularTopicLoader('');
            toggleSearchTopicLoader('');   
            setErrorMessage('*You have subscribed to maximum number of topics allowed(16).');
        }
    }

    const submitTopicHandler = () => {

    }


    return (
        <ThemeWrapper>
            <ThemeContainer>
                <ActiveTheme>
                    <div
                        className='outer-container'
                        style={{
                            background: 'linear-gradient(to bottom, rgba(252,252,252,1) 0%,rgba(247,247,247,1) 50%,rgba(247,247,247,1) 50%,rgba(227,227,227,1) 100%)',
                            border: '1px solid #cacaca',
                            borderRadius: '4px',
                            textAlign: 'center',
                            paddingBottom: '15px'
                        }}
                    >
                        {isAdmin ? (
                            <Flex justifyContent='center' alignItems='center'>
                                <Button
                                    bgColor={selectedTab === 'MY_TOPICS' ? '#7dc5d0' : '#ececec'}
                                    color={selectedTab === 'MY_TOPICS' ? 'white' : '#616062'}
                                    onClick={() => setSelectedTab('MY_TOPICS')}
                                    fontSize='11px'
                                    width='120px'
                                    styles={{ border: '0px' }}
                                >
                                    My Topics
                                </Button>
                                <Button
                                    bgColor={selectedTab === 'MEMBER_TOPICS' ? '#7dc5d0' : '#ececec'}
                                    color={selectedTab === 'MEMBER_TOPICS' ? 'white' : '#616062'}
                                    onClick={() => setSelectedTab('MEMBER_TOPICS')}
                                    fontSize='11px'
                                    width='auto'
                                    styles={{ border: '0px', pointerEvents: 'none' }}
                                >
                                    Member Topics (Coming soon)
                                </Button>
                            </Flex>
                        ) : null}
                        <div 
                            className='my_topics_container_div' 
                            style={{ 
                                borderRadius: '6px',
                                borderTopLeftRadius: '10px', 
                                borderTopRightRadius: '10px',  
                                width: '85%', 
                                border: '1px solid #e5e5e5',
                                margin: '20px auto 3px',
                            }}
                        >
                            <div 
                                className='my_topics_header'
                                style={{
                                    fontSize: '12px', 
                                    backgroundColor: '#e5e5e5', 
                                    marginTop: '-1px',
                                    borderTopLeftRadius: '10px', 
                                    borderTopRightRadius: '10px', 
                                    border: '0px',
                                    padding: '10px'
                                }}
                            >
                                <strong>My Topics</strong>
                            </div>
                            <div style={{border: '0px', backgroundColor: '#fcfcfc', maxHeight: '210px', overflowY: 'auto'}}>
                                <ul 
                                    style={{
                                        display: 'inline-block',
                                        width: '100%',
                                        listStyleType: 'none',
                                        overflow: 'hidden auto',
                                        margin: '25px auto 10px',
                                        textAlign: 'left',
                                        paddingLeft: '30px'
                                    }}
                                >
                                    {myTopics.length>0 ? myTopics.map((topic,index) => (
                                        <div 
                                            style={{
                                                display: 'inline-block', 
                                                marginBottom: '10px', 
                                                position: 'relative'
                                            }} 
                                            onMouseEnter={() => toggleGhostBtn(topic.name)} 
                                            onMouseLeave={() => toggleGhostBtn('')}
                                        >
                                            <Flex 
                                                justifyContent='flex-start' 
                                                alignItems='center' 
                                                style={{
                                                    border: '0px', 
                                                    borderRadius: '4px', 
                                                    backgroundColor: '#ececec', 
                                                    color: '#6351ed', 
                                                    fontSize: '10px',
                                                    padding: '5px 10px 5px 1px',
                                                    margin: '0px 10px',
                                                    height: '40px',
                                                    minWidth: '115px',
                                                    maxWidth: '115px',
                                                    overflow: 'hidden',
                                                }}
                                            >
                                                <img 
                                                    src={topic.name==='Featured Topics'?topic.media_url:topic.media_urls[0].path + topic.media_urls[0].id + '.' + topic.media_urls[0].ext}
                                                    style={{
                                                        width: '32px', 
                                                        height: '32px', 
                                                        borderRadius: '4px',
                                                        margin: '0px 4px'
                                                    }} 
                                                />
                                                <LoaderWrapper isLoading={topicLoader === topic.name}>
                                                    <p>{shortenTitle(topic.name)}</p>
                                                </LoaderWrapper>
                                            </Flex>
                                            {showGhostBtn===topic.name && !topicLoader ? (
                                                <div 
                                                    style={{ 
                                                        position: 'absolute', 
                                                        backgroundColor: '#fffc', 
                                                        height: '40px', 
                                                        width: '125px', 
                                                        top: '0px', 
                                                        left: '5px', 
                                                        borderRadius: '4px',
                                                        fontSize: '10px', 
                                                        cursor: 'pointer',
                                                        display: 'flex',
                                                        justifyContent: 'flex-start',
                                                        alignItems:'center',
                                                        paddingLeft: '17px'
                                                    }}
                                                    onClick={() => {
                                                        if(topic.name !== 'Featured Topics') {
                                                            unsubscribeHandler(topic)
                                                        }
                                                    }}
                                                    >
                                                    {topic.name !== 'Featured Topics' && <IconWrapper style={{marginBottom: '9px', marginRight: '10px'}}>
                                                        <FontAwesomeIcon icon={faMinus} />
                                                    </IconWrapper>}
                                                    <p style={topic.name === 'Featured Topics' ? {position: 'absolute', top: '-5px', width: '50px', left: '46px'} : null}>{topic.name}</p>
                                                </div>
                                            ) : null}
                                        </div>
                                    )) : null}
                                </ul>
                            </div>
                        </div>
                        {errorMessage ? (
                            <div style={{fontSize: '10px'}}>{errorMessage}</div>
                        ) : null}
                        <p style={{fontSize: '10px'}}>You have {myTopics.length} out of a max of 16 topics selected.</p>
                        <div 
                            className='result_div_container'
                            style={{
                                backgroundColor: '#fff',
                                borderRadius: '6px', 
                                borderTopLeftRadius: '10px', 
                                borderTopRightRadius: '10px', 
                                border: '1px solid #ececec',
                                width: '85%',
                                margin: 'auto',
                                paddingBottom: '20px'
                            }}
                        >
                            <div 
                                className='add_topic_header'
                                style={{
                                    fontSize: '12px', 
                                    backgroundColor: '#e5e5e5', 
                                    marginTop: '-1px',
                                    borderTopLeftRadius: '10px', 
                                    borderTopRightRadius: '10px', 
                                    border: '0px',
                                    padding: '10px'
                                }}
                            >
                                <strong>Add Topics</strong>
                            </div>
                            <Flex 
                                justifyContent='center' 
                                alignItems='center' 
                                style={{
                                    borderBottom: '1px solid #ededed', 
                                    padding: '0px', 
                                    width: '130px',
                                    margin: 'auto',
                                    marginBottom: '20px'
                                }}
                            >
                                <div 
                                    style={{
                                        border: '0px',
                                        borderBottom:  selectedResultTab==='search' ? '1.9px solid #6351ed' : '0px',
                                        color: selectedResultTab==='search' ? '#6351ed' : '#616062',
                                        padding: selectedResultTab==='search' ? '10px 0px 1px 0px' : '10px 0px 3px 0px',
                                        margin: '0px',
                                        marginRight: '12px',
                                        fontSize: '10px',
                                        cursor: 'pointer'
                                    }}
                                    onClick={() => setSelectedResultTab('search')}
                                >
                                    Search
                                </div>
                                <div 
                                    style={{
                                        border: '0px',
                                        borderBottom:  selectedResultTab==='popular' ? '1.9px solid #6351ed' : '0px',
                                        color: selectedResultTab==='popular' ? '#6351ed' : '#616062',
                                        padding: selectedResultTab==='popular' ? '10px 0px 1px 0px' : '10px 0px 3px 0px',
                                        margin: '0px',
                                        marginRight: '12px',
                                        fontSize: '10px',
                                        cursor: 'pointer'
                                    }}
                                    onClick={() => setSelectedResultTab('popular')}
                                >
                                    Popular
                                </div>
                                <div 
                                    style={{
                                        border: '0px',
                                        borderBottom:  selectedResultTab==='browse' ? '1.9px solid #6351ed' : '0px',
                                        color: selectedResultTab==='browse' ? '#6351ed' : '#616062',
                                        padding: selectedResultTab==='browse' ? '10px 0px 1px 0px' : '10px 0px 3px 0px',
                                        margin: '0px',
                                        fontSize: '10px',
                                        cursor: 'pointer'
                                    }}
                                    onClick={() => setSelectedResultTab('browse')}
                                >
                                    Browse
                                </div>
                            </Flex>
                            {selectedResultTab === 'search' ? (
                                <ResultSearch
                                    allTopics={allTopics} 
                                    shortenTitle={shortenTitle}
                                    loader={searchTopicLoader} 
                                    setLoader={toggleSearchTopicLoader}
                                    subscribe={subscribeHandler} 
                                />
                            ) : selectedResultTab === 'popular' ? (
                                <ResultPopular 
                                    trendingTopics={trendingTopics} 
                                    popularTopics={popularTopics} 
                                    shortenTitle={shortenTitle}
                                    loader={popularTopicLoader}
                                    setLoader={togglePopularTopicLoader} 
                                    subscribe={subscribeHandler}
                                />
                            ) : (
                                <ResultBrowse 
                                    allTopics={allTopics} 
                                    shortenTitle={shortenTitle}
                                    loader={browseTopicLoader} 
                                    setLoader={toggleBrowseTopicLoader}
                                    subscribe={subscribeHandler}
                                />
                            )}
                        </div>
                        {/* <Flex justifyContent='center' alignItems='baseline'>
                            <p style={{ fontSize: '11px', marginRight: '5px' }}>Can't find the topic you are looking for?</p>
                            <Button bgColor='#7dc5d0' fontSize='11px' padding='5px 12px' styles={{border: '1px solid #7dc5d0'}} onClick={() => toggleSubmitTopicModal(true)}>Submit a Topic</Button>
                        </Flex> */}
                        <Modal size='small' isOpen={submitTopicModal} onRequestClose={() => toggleSubmitTopicModal(false)}>
                            <ModalHeader>
                                Submit a Topic
                                <ModalCloseButton onClick={() => toggleSubmitTopicModal(false)} />
                            </ModalHeader>
                            <ModalBody>
                                <Input largeInput={true} placeholder='Enter your topic' placeholderAlignment='center' placeholderColor='#cacaca' />
                                <Button onClick={() => submitTopicHandler()}>Submit</Button>
                            </ModalBody>
                        </Modal>
                    </div>
                </ActiveTheme>
            </ThemeContainer>
        </ThemeWrapper>
    );
}

const mapStateToProps = state => {
    const isAdmin = getUserRole(state) === USER_ROLE.admin;
    return {
        isAdmin: isAdmin
    }
}

export default connect(mapStateToProps)(Theme);