import styled from 'styled-components';

export const PopoverWrapper = styled.div`
  position: ${({ position }) => position || 'absolute'};
  right: ${({ right }) => right || '0px'};
  top: ${(props) =>
    props.type === 'analytics' || props.type === 'members-tab'
      ? props.top || '-39px'
      : '0px'};
  zoom: ${(props) => (props.type === 'analytics' ? '1.3' : '')};
  .date-input {
    height: 18px;
    color: #666666;
    font-size: 11px;
    font-family: 'Poppins';
    background: #ffffff;
    border-radius: 4px;
    border: solid 1px #707070;
  }
  ul {
    color: #757575;
    font-size: 11px;
    li {
      padding: 1px 12px;
      text-align: left;
      border-bottom: 1px solid #8b8b8b;
      border-right: 1px solid #8b8b8b;
      border-left: 1px solid #8b8b8b;
      &:hover {
        background-color: #dcdcdc;
      }
    }
  }
  @media (max-width: 768px) {
    display: ${(props) => (props.type === 'analytics' ? 'flex' : '')};
    justify-content: ${(props) => (props.type === 'analytics' ? 'center' : '')};
    position: ${(props) => (props.type === 'analytics' ? 'relative' : '')};
    top: ${(props) => (props.type === 'analytics' ? '-70px' : '')};
  }
`;

export const DatePickersWrapper = styled.div`
  display: flex;
  z-index: 15;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  line-break: auto;
  padding: 8px;
  margin-left: -10px;
  font-size: 11px;
  max-width: 230px;
  cursor: pointer;
  input[type='text'] {
    height: 27px;
    font-size: 11px;
    margin-bottom: 5px;
    color: #6351ed;
    padding: 3px 5px;
    font-family: 'Poppins';
    border: 1px solid #6351ed;
    width: 100%;
  }
  button {
    font-size: 11px;
    padding: 2px 10px;
    margin: 0px;
    border-radius: 4px;
  }
`;
