import React, { useState, useEffect } from 'react';
import { get, union } from 'lodash';
import { Flex, Box } from '@rebass/grid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter, faTimes } from '@fortawesome/free-solid-svg-icons';

import { ButtonNoBorderBackground } from '../CommonStyles';

import {
  StyledWrapper,
  SelectedItemBox,
  NewStyledWrapper,
} from './SearchFilterMenu.styles';
import ErrorModal from '../ErrorModal';

const SelectListItem = ({ id, label, checked, onClick }) => {
  return (
    <li
      style={{ backgroundColor: checked ? '#1B0983' : '#6351ed' }}
      onClick={onClick}
    >
      <Flex width={1} alignItems="center">
        <Box pr="5px">
          <input checked={checked} type="checkbox" id={id} />
        </Box>
        <Box>
          <label htmlFor={id}>{label}</label>
        </Box>
      </Flex>
    </li>
  );
};

const SearchFilterMenu = ({
  channelData,
  setSelectedItems,
  setTopicSelected,
  selectedItems,
  topicSelected,
  exploreSetState,
}) => {
  // console.log('CHANNEL DATA = ', channelData);
  const [showErroModal, setErrorModalDisplay] = useState(false);
  const selectedChannelIds = selectedItems.map(({ id }) => id);
  // useEffect(() => {
  //   let filterTags = [];
  //   if(((selectedItems && selectedItems.length > 0) || topicSelected) && exploreSetState) {
  //     console.log('here 3.4', {selectedItems, filterTags, topicSelected});
  //     selectedItems.forEach(item => {
  //       if(item.name.includes('(')) {
  //         let name = item.name.split(/[()]/);
  //         filterTags.push(name[0].toUpperCase().trim());
  //         filterTags.push(name[1].toUpperCase().trim());
  //       } else {
  //       filterTags.push(item.name.toUpperCase().trim())
  //       }
  //     });
  //     exploreSetState({searchFilters: union(filterTags, topicSelected)});
  //   }
  // },[selectedItems, topicSelected])

  const onItemSelect = (channel) => {
    if (!selectedChannelIds.includes(channel.id)) {
      if (selectedChannelIds.length >= 5) {
        setErrorModalDisplay(true);
        return;
      }
      setSelectedItems([
        ...selectedItems,
        {
          id: channel.id,
          name: channel.name,
        },
      ]);
    } else {
      const filterChannel = selectedItems.filter(
        (item) => item.id !== channel.id
      );
      setSelectedItems(filterChannel);
    }
  };

  const onTopicsSelected = (topic) => {
    if (!topicSelected.includes(topic)) {
      setTopicSelected([...topicSelected, topic]);
    } else {
      setTopicSelected(topicSelected.filter((item) => item !== topic));
    }
  };
  
  return (
    <>
    <Flex
      width="17%"
      ml="12px"
      flexDirection="row-reverse"
      flexWrap="wrap"
      alignItems="center"
      // mt="5px"
    >
      {/* <StyledWrapper background='#6351ed'> */}
      <NewStyledWrapper
        style={{
          width: '100%',
          height: '35px',
          borderRadius: '4px',
          display: 'flex',
          marginTop: '8px',
        }}
      >
        <ButtonNoBorderBackground
          onClick={(e) => e.preventDefault()}
          style={{
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
            padding: '8px 5px',
          }}
        >
          {/* <FontAwesomeIcon icon={faFilter} size="10px" color='#ffffff' />
          &nbsp;&nbsp;Filter */}
          <img
            alt=""
            src="/assets/images/color-filter-icon.svg"
            style={{
              cursor: 'pointer',
              height: '18px',
              marginRight: '5px',
            }}
          />
          <Flex style={{ fontSize: '12px' }}>Filter</Flex>
        </ButtonNoBorderBackground>
        <div>
          <ul className="parent-ul"  style={{zIndex: 1}}>
            <li>
              Topics
              <ul className="child-ul"  style={{zIndex: 2}}>
                <SelectListItem
                  label="Your Topics"
                  id="yourTopics"
                  checked={topicSelected.includes('Your Topics')}
                  onClick={() => onTopicsSelected('Your Topics')}
                />
                <SelectListItem
                  label="Most Subscribed"
                  id="mostSubscribed"
                  checked={topicSelected.includes('Most Subscribed')}
                  onClick={() => onTopicsSelected('Most Subscribed')}
                />
              </ul>
            </li>
            <li>
              Publishers
              <ul className="child-ul">
                {channelData.map((channel, index) => (
                  <SelectListItem
                    key={'list_item_' + index}
                    id={get(channel, 'id')}
                    label={channel.name}
                    checked={selectedChannelIds.includes(channel.id)}
                    onClick={() => onItemSelect(channel)}
                  />
                ))}
              </ul>
            </li>
          </ul>
        </div>
        {/* </StyledWrapper> */}
      </NewStyledWrapper>
      {showErroModal && (
        <ErrorModal onClose={() => setErrorModalDisplay(false)}>
          You can select only 5 Partners
        </ErrorModal>
      )}
    </Flex>
    </>
  );
};

export default SearchFilterMenu;
