import React from 'react';
import { useClickAway } from 'src/hooks/useClickAway';
import Calendar from 'src/assets/icons/Calendar';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { DATE_DIFFERENCE_VALUES } from './constants';
import {
  Action,
  ActionContent,
  ActionWrapper,
  DropdownElement,
  DropdownElements,
} from './styles';

function TimePeriod({ timePeriod, setTimePeriod }) {
  const {
    active: isTimePeriodDropdownActive,
    ref: timePeriodRef,
    toggle: toggleTimePeriod,
  } = useClickAway();

  return (
    <ActionWrapper ref={timePeriodRef}>
      <Action onClick={() => toggleTimePeriod()}>
        <ActionContent>
          <Calendar />
          <h6>{timePeriod?.label}</h6>
        </ActionContent>
        {isTimePeriodDropdownActive ? (
          <KeyboardArrowUpIcon />
        ) : (
          <KeyboardArrowDownIcon />
        )}
      </Action>

      {isTimePeriodDropdownActive && (
        <DropdownElements>
          {DATE_DIFFERENCE_VALUES.map(({ label, value }) => (
            <DropdownElement
              key={value}
              onClick={(e) => {
                e.stopPropagation();
                setTimePeriod({ label, value });
                toggleTimePeriod();
              }}
            >
              <p>{label}</p>
            </DropdownElement>
          ))}
        </DropdownElements>
      )}
    </ActionWrapper>
  );
}

export default TimePeriod;
