import { styled } from '@mui/material';

export const PaginationWrapper = styled('div')(() => ({
  padding: '1rem',
  width: '100%',

  '.MuiPagination-root': {
    width: '100%',
    color: '#475467',
    background: 'transparent',

    // Style for the selected Page
    '.Mui-selected': {
      color: '#fff',
      background: '#101828',

      '&:hover': {
        color: '#475467',
        background: '#F9FAFB',
      },
    },

    '.MuiPagination-ul': {
      flexWrap: 'nowrap',

      li: {
        font: '500 0.875rem/1.0625rem Poppins,sans-serif',

        button: {
          width: 40,
          height: 40,
          borderRadius: '50%',
          textAlign: 'center',
          fontFamily: 'Poppins,sans-serif',
        },

        // Common Styling for both Previous and Next Button for Pagination
        '&:first-child, &:last-child': {
          flexBasis: '10%',
          display: 'flex',
          alignItems: 'center',

          button: {
            minHeight: '40px',
            padding: '0.75rem 1rem',
            border: '1px solid #E4E7EC',
            borderRadius: '0.25rem',
            margin: 0,
          },
        },

        // Styling for Previous Button for Pagination
        '&:first-child': {
          justifyContent: 'flex-start',
          marginRight: 'auto',

          button: {
            width: '100%',
          },

          '> button::after': {
            padding: '0.75rem',
            marginLeft: '0.75rem',
            content: "'Previous'",
          },
        },

        // Styling for Next Button for Pagination
        '&:last-child': {
          justifyContent: 'flex-end',
          margin: '0 0.5rem 0 auto',

          button: {
            width: '100%',
          },

          '> button::before': {
            padding: '0.75rem',
            marginRight: '0.75rem',
            content: "'Next'",
          },
        },
      },
    },
  },
}));
