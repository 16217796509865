/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { get, isEmpty } from 'lodash';
import Profile from './Profile';
import { actions as contactsActions } from '../../redux/modules/contacts.module';
import { actions as loginActions } from '../../redux/modules/login.module';
import { getContactsGroups } from '../../services/configure';
import { getUserDetails } from '../../redux/selector/login.selectors';
import {
  getContactListForEmail,
  ROLE_TYPES,
} from '../../utils';
import Platforms from './Platforms/Platforms';
import { getRoleType } from '../../redux/selector';
import { GeneralSettings } from './AdvisorGenSettings/GeneralSettings';
import { ConfigureContainerStyle, GroupingContainer } from './AdminConfigureContainer.styles';
import ToggleBar from '../../components/ToggleBar';
import ManageTopics from './ManageTopics/ManageTopics';
import ManagePublisher from './ManagePublisher';

const toggleItems = [
  {
    name: 'Profile',
    key: 0
  },
  {
    name: 'Platforms',
    key: 1
  },
  {
    name: 'Settings',
    key: 2
  },
  {
    name: 'Manage Topics',
    key: 3
  },
  {
    name: 'Manage Publishers',
    key: 4
  },
];

const ConfigureContainer = ({
  contactsRequest,
  userData,
  socialNetworkData,
  loginRequest,
  roleType,
  selectedTabIndex,
  userId,
  isPremiumAdvisor,
  isAdvisorEnterprise,
  loginSetState,
}) => {
  const [socialAccountURL, setSocialAccountURL] = useState(null);
  const [bccList, setBccList] = useState(!isEmpty(get(userData, 'bcc_emails', [])) ? get(userData, 'bcc_emails', []).join() : '');
  const [selectedConfigureIndex, setSelectedConfigureIndex] = useState(0);
  const [salesforceSettings, updateSalesforceSettings] = useState(
    !isEmpty(get(userData, 'salesforce_settings'))
      ? userData.salesforce_settings
      : [
          { 
            label: 'Email Sent', 
            Enabled: true, 
            // 'Re-sync': false 
          },
          {
            label: 'Email Opened',
            Enabled: true,
            // 'Re-sync': false,
            'Skip duplicates': false,
          },
          {
            label: 'Email Clicked',
            Enabled: true,
            // 'Re-sync': false,
            'Skip duplicates': false,
          },
          { 
            label: 'Email Unsubscribed', 
            Enabled: true, 
            // 'Re-sync': false 
          },
          {
            label: 'Content Viewed',
            Enabled: true,
            // 'Re-sync': false,
            'Skip duplicates': false,
          },
          { 
            label: 'Lead Generated', 
            Enabled: true, 
            // 'Re-sync': false 
          },
          // { 
          //   label: 'Clout score changed', 
          //   Enabled: true, 
          //   // 'Re-sync': false 
          // },
          { 
            label: 'Webinar Registered', 
            Enabled: true, 
            // 'Re-sync': false 
          },
          { 
            label: 'Webinar Attended', 
            Enabled: true, 
            // 'Re-sync': false 
          },
        ]
  );

  const retrieveGroupsList = () => {
    getContactsGroups().then((response) => {
      const data = getContactListForEmail(get(response, 'result.data', []));
      if (!isEmpty(data)) {
        const hasIsDefault = data.filter((d) => d.is_default);
        const firstGroup = isEmpty(hasIsDefault)
          ? get(data, `[0].id`, '')
          : hasIsDefault[0].id;
        contactsRequest({ params: { contactGroup: firstGroup } });
      } else {
        contactsRequest({ params: { contactGroup: '' } });
      }
    });
  };

  useEffect(() => {
    retrieveGroupsList();
    if (window.sessionStorage.getItem('selectedIndex')) {
      setSelectedConfigureIndex(
        +window.sessionStorage.getItem('selectedIndex')
      );
    } else {
      setSelectedConfigureIndex(selectedTabIndex);
    }
  }, [selectedTabIndex]);

  useEffect(() => {
    const windowBodyClassList = document.body.classList;
    if (!windowBodyClassList.contains('campaign')) {
      windowBodyClassList.add('campaign');
    }
  }, [])
  
  const pageDisplay = () => {
    switch(selectedConfigureIndex){
      case 0:
        return(
          <Profile
            userData={userData}
            socialAccountURL={socialAccountURL}
            setSocialAccountURL={setSocialAccountURL}
            roleType={roleType}
          />
        );
      case 1:
        return(
            <Platforms
              userData={userData}
              socialAccountURL={socialAccountURL}
              setSocialAccountURL={setSocialAccountURL}
              roleType={roleType}
            />
        )
      case 2: 
          return(
              <GeneralSettings
                userId={userId}
                companyId={userData?.company}
                loginRequest={loginRequest}
                advisorApproval={userData?.extra_settings?.advisor_approval}
                advPartnerEmail={userData?.as_partner_email}
                turnkey={userData?.turnkey}
                salesforceSettings={salesforceSettings}
                updateSalesforceSettings={updateSalesforceSettings}
                socialNetworkData={socialNetworkData}
                isPremiumAdvisor={isPremiumAdvisor}
                isAdvisorEnterprise={isAdvisorEnterprise}
                bccList={bccList}
                setBccList={setBccList}
                userData={userData}
                loginSetState={loginSetState}
              />
          )
          case 3:
        return <ManageTopics />;
      case 4:
        return <ManagePublisher />;
        default:
          break;
      }
    }

  return (
    <ConfigureContainerStyle>
      <div style={{margin: '0 0 10px'}}>
        <ToggleBar 
          toggleItems={toggleItems} 
          activeToggle={selectedConfigureIndex} 
          toggleClick={(key) => {
            setSelectedConfigureIndex(key);
          }} 
        />
      </div>
      {
        pageDisplay()
      }
    </ConfigureContainerStyle>
  );
};

const mapStateToProps = (state) => {
  const roleType = getRoleType(state);
  const isPremiumAdvisor = roleType === ROLE_TYPES.ADVISOR_PREMIUM;
  const isAdvisorEnterprise = roleType === ROLE_TYPES.ADVISOR_ENTERPRISE;
  return {
    userId: state.user.data && state.user.data.uid,
    selectedTabIndex: state.contacts.selectedTabIndex,
    userData: getUserDetails(state),
    socialNetworkData: state.socialNetwork.data,
    isPremiumAdvisor: isPremiumAdvisor,
    isAdvisorEnterprise: isAdvisorEnterprise,
  };
};

const mapDispatchToProps = {
  contactsRequest: contactsActions.api.contacts.request,
  loginRequest: loginActions.api.login.request,
  loginSetState: loginActions.api.login.setstate
};

export default connect(mapStateToProps, mapDispatchToProps)(ConfigureContainer);