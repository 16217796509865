import { styled } from '@mui/material';

export const Content = styled('div')(() => ({
  width: '100%',
  height: '100%',
  minHeight: '25rem',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '1rem',
  padding: '1rem',
  backgroundColor: '#FDFCFF',
  border: '1px solid #BBBEF7',
  borderRadius: '0.5rem',
}));

export const Header = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  gap: '1rem',
  justifyContent: 'space-between',

  '.info-icon': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    svg: {
      color: '#816EF8',
      fontSize: '1.5rem',
      cursor: 'pointer',
    },
  },
}));

export const Heading = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '0.38rem',

  svg: {
    color: '#816EF8',
    fontSize: '2rem',
  },

  h3: {
    font: '500 1.1875rem Poppins, sans-serif',
    color: '#101828',
    margin: 0,
  },
}));

export const Cards = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '1rem',
}));

export const Card = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '1.5625rem',
  padding: '0.75rem',
  backgroundColor: '#fff',
  border: '1px solid #BBACFF',
  borderRadius: '0.25rem',
}));

export const CardContent = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '0.75rem',

  h5: {
    font: '500 0.9375rem Poppins, sans-serif',
    color: '#344054',
    margin: 0,
    textAlign: 'left',
  },

  p: {
    font: '400 0.75rem Poppins, sans-serif',
    color: '#475467',
    margin: 0,
    textAlign: 'left',
  },
}));

export const Pills = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '0.5rem',
  flexWrap: 'wrap',
}));

export const Pill = styled('div')(({ color, bgColor, borderColor }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '0.25rem',
  padding: '0.25rem 0.5rem',
  border: `1px solid ${borderColor}`,
  backgroundColor: `${bgColor}`,
  borderRadius: '1.5rem',
  font: '500 0.75rem Poppins, sans-serif',
  color,
  textAlign: 'left',
  margin: 0,

  svg: {
    fontSize: '1rem',
    color,
  },
}));
