import { styled } from '@mui/material';

export const Wrapper = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: '1rem',
  padding: '1rem',
}));

export const Form = styled('form')(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: '1rem',
}));

export const InputWithLabel = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '0.25rem',
}));

export const Label = styled('label')(() => ({
  fontSize: '0.875rem',
  fontWeight: '400',
  color: '#101828',
  margin: 0,
  textAlign: 'left',

  '.required': {
    color: '#e00',
  },
}));

export const Input = styled('input')(() => ({
  fontFamily: 'Poppins',
  width: '100%',
  height: '2.5rem',
  padding: '0.625rem',
  fontSize: '0.75rem',
  fontWeight: '400',
  fontFamily: 'Poppins',
  color: '#101828',
  margin: 0,
  textAlign: 'left',
  borderRadius: '0.25rem',
  border: '1px solid #E4E7EC',
  background: '#FFF',
  boxShadow: '1px 2px 2px 0px rgba(16, 24, 40, 0.12) inset',
}));

export const TextArea = styled('textarea')(() => ({
  padding: '0.625rem',
  fontSize: '0.75rem',
  fontWeight: '400',
  color: '#101828',
  margin: 0,
  textAlign: 'left',
  border: '1px solid #E4E7EC',
  background: '#FFF',
  boxShadow: '1px 2px 2px 0px rgba(16, 24, 40, 0.12) inset',
}));

export const ErrorText = styled('p')(() => ({
  fontSize: '0.75rem',
  fontWeight: '400',
  color: '#e00',
  margin: 0,
  textAlign: 'left',
}));

export const UploadFileContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '1rem',
  padding: '1.5rem',
  background: '#FCFDFD',
  border: '1px dashed #E4E7EC',
  borderRadius: '0.25rem',
}));

export const UploadFileIcon = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '0.5rem',
  background: '#7DC5D0',
  border: '8px solid #E0F1F4',
  borderRadius: '50%',

  svg: {
    fontSize: '1.5rem',
    color: '#FCFDFD',
  },
}));

export const UploadFileContent = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '0.5rem',

  h5: {
    color: '#101828',
    fontSize: '1rem',
    fontWeight: '500',
    margin: 0,
    textAlign: 'center',
  },

  p: {
    color: '#475467',
    fontSize: '0.875rem',
    fontWeight: '400',
    margin: 0,
    textAlign: 'center',
  },
}));

export const SelectedFileContainer = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: '1rem',
  padding: '1.44rem 1rem',
  background: '#FFF',
  border: '1px solid #E4E7EC',
  borderRadius: '0.5rem',
  boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.12)',

  svg: {
    color: '#101828',
    fontSize: '1.5rem',
    cursor: 'pointer',
  },
}));

export const SelectedFileContent = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '1rem',

  svg: {
    color: '#101828',
    fontSize: '1.5rem',
    cursor: 'default',
  },
}));

export const SelectedFileDescription = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',

  h5: {
    color: '#101828',
    fontSize: '0.875rem',
    fontWeight: '500',
    margin: 0,
    textAlign: 'left',
    maxWidth: '20ch',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },

  p: {
    color: '#475467',
    fontSize: '0.75rem',
    fontWeight: '400',
    margin: 0,
    textAlign: 'left',
    maxWidth: '30ch',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
}));
