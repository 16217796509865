import { Flex } from '@rebass/grid';
import { isEmpty } from 'lodash';
import React from 'react';

import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalHeader,
} from '../../../../components';

import { themesData } from '../../../../utils';
import FeedbackItem from './FeedbackItem';

const CampaignFeedbackPopup = ({ handleClose, feedback, title, campaigns }) => {
  const platformMapper = {
    email: 'Email',
    social: 'Social Media',
  };

  return (
    <Modal
      bgColor="white"
      height="70%"
      size="medium"
      onRequestClose={handleClose}
      shouldCloseOnOverlayClick={false}
      isOpen
    >
      <ModalHeader
        id="header"
        style={{ textAlign: 'center', color: themesData.charcoalGrey }}
      >
        <Flex fontSize="0.9rem" color="black" style={{ fontWeight: '600' }}>
          Feedback
        </Flex>
        <ModalCloseButton onClick={handleClose} size="25px" />
        <hr style={{ width: '100%' }} />
      </ModalHeader>
      <ModalBody style={{ overflowY: 'auto', height: 'inherit' }}>
        <Flex
          width="100%"
          justifyContent="space-evenly"
          style={{ fontSize: '1rem', color: 'black', fontWeight: '600' }}
          mb="10px"
          flexDirection="column"
        >
          {!isEmpty(campaigns)
            ? campaigns.map(
                (campaign) =>
                  campaign.feedback &&
                  Object.keys(campaign.feedback).length > 0 && (
                    <>
                      {Object.keys(campaign.feedback).map(
                        (platformFeedback) =>
                          campaign.feedback[platformFeedback]?.action === 'rejected' && (
                            <FeedbackItem
                              feedback={
                                campaign.feedback[platformFeedback]?.message
                              }
                              title={`${campaign.name} - ${platformMapper[platformFeedback]}`}
                              key={`${platformFeedback} - ${campaign.id}`}
                            />
                          )
                      )}
                    </>
                  )
              )
            : feedback &&
              Object.keys(feedback).length > 0 &&
              Object.keys(feedback).map(
                (platformFeedback) =>
                  feedback[platformFeedback].action === 'rejected' && (
                    <FeedbackItem
                      feedback={feedback[platformFeedback].message}
                      title={`${title} - ${platformMapper[platformFeedback]}`}
                      key={platformFeedback}
                    />
                  )
              )}

          {/* <FeedbackItem />
          <FeedbackItem /> */}
        </Flex>

        <div
          style={{
            position: 'absolute',
            bottom: '-12px',
            left: '0',
            right: '0',
            padding: '10px',
            paddingBottom: '12px',
          }}
        >
          <hr style={{ width: '100%' }} />
          <Flex justifyContent="flex-end" py="10px">
            <Button
              styles={{ borderRadius: '4px' }}
              color="#fff"
              bgColor="#4E6780"
              margin="0px 10px"
              borderColor="#4E6780"
              onClick={() => {
                handleClose(false);
              }}
            >
              Cancel
            </Button>
            {/* <Button
              styles={{ borderRadius: '4px' }}
              color="#fff"
              bgColor="#33b21b"
              margin="0px 10px"
              borderColor="#33b21b"
              disabled={isEmpty(selectedContent)}
              onClick={() => handleClose(true)}
            >
              Save
            </Button> */}
          </Flex>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default CampaignFeedbackPopup;
