/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Flex } from '@rebass/grid';
import { parse } from 'json2csv';
import { get, isEmpty } from 'lodash';
import moment from 'moment';
import React, { useState, useEffect } from 'react'
import { Button, DateDropdown, DownloadButton, LoaderWrapper, ResponsiveImg, VerticalTabs } from '../../../components';
import { saveFile } from '../../../utils';
import { AnalyticsVerticalTab, AnalyticsVerticalTabList, AnalyticsVerticalTabPanel, CommonVerticalTabPanel, DownloadButtonFlex, TabContent, TabLabel } from '../AnalyticsContainer.styles';
import LeadCaptureCard from './LeadCaptureCard';

const LeadCaptureTab = ({
    selectedDate,
    setSelectedDate,
    selectedRepType,
    analyticsRequest,
    analyticsData,
    isAnalyticsDataFetching,
    analyticsObject,
    userData,
    option,
    isAssetAdmin,
    isAssetAdvisor 
}) => {

    const [verticalTabIndex, setVerticalTabIndex] = useState(0);
    const [page, setPage] = useState(0);
    const [startIndex, setStartIndex] = useState(0);
    const [endIndex, setEndIndex] = useState(0);

    const onTabSelect = (index) => {
        setPage(0);
        let repType = 'leadgen_data';
        let leadType = 'leadgen';
        // if(option===1)
        //     repType = 'leadgen_data_member';
        if(index === 1) 
            leadType = 'referral';
        analyticsRequest({
            params: {
                repType: repType,
                leadType: leadType
            }
        });
        setVerticalTabIndex(index);
    }

    const handleDownloadCsv = (type) => {
        const tabName = type;
        const { startDate, endDate } = analyticsObject || {};
        const fileName = `${tabName}-${startDate}_${endDate}`;
        const columnName1 = 'Full Name';
        const columnName2 = 'Email';
        const columnName3 = 'Mobile';
        const columnName4 = type === 'leadgen' ? 'Investable Assets' : 'Referral Name';
        const columnName5 = 'ZipCode';
        const columnName6 = 'Date';
    
        const fields = [columnName1, columnName2, columnName3, columnName4, columnName5, columnName6];
        const parsedData = parse(
          analyticsData.leadgen.length > 0 && analyticsData.leadgen.map((item, index) => ({
            [columnName1]: item.fullname,
            [columnName2]: item.email,
            [columnName3]: item.mobile,
            [columnName4]: type === 'leadgen' ? item.investable_assets : item.referrer_fullname ? item.referrer_fullname : '',
            [columnName5]: item.zip,
            [columnName6]: moment(item.created_time).format('MMMM Do, YYYY')
          })), {
          fields
        }
        )
    
        if (analyticsData.leadgen.length > 0) {
          saveFile(parsedData, fileName);
        }
    }

    useEffect(() => {
        analyticsRequest({
            params: {
                repType: 'leadgen_data',
                leadType: 'leadgen'
            }
        });
    }, []);

    useEffect(() => {
        if (analyticsData.pagination && analyticsData.pagination.total_count) {
          if (page === 0) {
            setStartIndex(analyticsData.pagination.total_count === "0" ? 0 : 1);
            setEndIndex(get(analyticsData, 'leadgen', []).length < 6 ? analyticsData.pagination.total_count : 6);
          } else {
            setStartIndex((page * 6) + 1);
            setEndIndex(((page + 1) * 6) >= analyticsData.pagination.total_count ? analyticsData.pagination.total_count : (page + 1) * 6);
          }
        }
      }, [page, analyticsData]);

    return (
        <VerticalTabs selectedIndex={verticalTabIndex} onSelect={onTabSelect}>
            <AnalyticsVerticalTabList screenWidth={window.innerWidth}>
                <AnalyticsVerticalTab disabled={isAnalyticsDataFetching}>
                    <TabContent>
                        <ResponsiveImg
                            src="/assets/images/recipients.svg"
                            alt="socialMedia"
                        />
                        <div style={{ padding: '2px' }}>
                            <TabLabel color="#6351ed" fontSize="16px">
                                Leads
                            </TabLabel>
                        </div>
                    </TabContent>
                </AnalyticsVerticalTab>
                <AnalyticsVerticalTab>
                    <TabContent>
                        <ResponsiveImg
                            src="/assets/images/recipients.svg"
                            alt="socialMedia"
                        />
                        <div style={{ padding: '2px' }}>
                            <TabLabel color="#6351ed" fontSize="16px">
                                Referrals
                            </TabLabel>
                        </div>
                    </TabContent>
                </AnalyticsVerticalTab>    
            </AnalyticsVerticalTabList>
            <AnalyticsVerticalTabPanel
                screenWidth={window.innerWidth}
                paddingTop='0'
            >
                <Box width={1}>
                    <CommonVerticalTabPanel height="685px">
                        {userData && userData.details && userData.details.user && userData.details.user.enable_lead_capture === "true" ? (
                            <LoaderWrapper isLoading={isAnalyticsDataFetching}>
                                <Flex flexWrap="wrap" width="100%" pl="5px" style={{minHeight: '490px'}}>
                                    {!isEmpty(get(analyticsData, 'leadgen', [])) ? get(analyticsData, 'leadgen', []).slice(page*6, (page+1)*6).map((item, index) => (
                                        <LeadCaptureCard 
                                            key={index} 
                                            {...item}
                                            isAssetAdmin={isAssetAdmin}
                                            isAssetAdvisor={isAssetAdvisor}
                                            userData={userData} 
                                        />
                                    )) : (
                                        <div style={{ display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center', height: '480px', fontSize: '16px' }}>
                                            No Records Found
                                        </div>
                                    )}
                                </Flex>
                                <Flex width='100%' mt='-18px' padding='0px 18px 0px 24px' alignItems="center" height="83px">
                                    <Flex width='67%'>
                                        {analyticsData.pagination && analyticsData.pagination.total_count && !isAnalyticsDataFetching && <span style={{ color: '#49484a', marginTop: '23px', fontSize: '14px', fontWeight: 'bold', marginLeft: '6px' }}>
                                            {startIndex}-{endIndex} of {analyticsData.pagination.total_count}
                                        </span>}
                                    </Flex>
                                    <Flex width='33%' justifyContent='space-between' alignItems='flex-end'>
                                        <Button styles={{border: 'none'}} margin="10px 0px 0px 0px" type="button" bgColor={page < 1 ? '#AFAFAF' : '#6351ed'} width='106px' onClick={() => setPage(page - 1)} disabled={page < 1 ? true : false}>Previous</Button>
                                        <Button styles={{border: 'none'}} margin="10px 0px 0px 0px" width='106px' bgColor={get(analyticsData, 'pagination.total_count', '0') === endIndex.toString() ? '#AFAFAF' : '#6351ed'} onClick={() => setPage(page + 1)} type="button" disabled={get(analyticsData, 'pagination.total_count', '0') === endIndex.toString()}>Next</Button>
                                    </Flex>
                                </Flex>
                            </LoaderWrapper>
                        ) : (<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '480px', fontSize: '16px' }}>
                            Contact Your Advisor Success Partner to Subscribe <br /> to Get Access to Leads.
                        </div>)}
                    </CommonVerticalTabPanel>
                    <DownloadButtonFlex>
                        <DownloadButton disabled={userData && userData.details && userData.details.user && userData.details.user.enable_lead_capture === "true" && get(analyticsData, 'leadgen', []).length > 0 ? false : true} onClick={() => handleDownloadCsv('leadgen')} fontSize='18px'/>
                    </DownloadButtonFlex>
                </Box>
            </AnalyticsVerticalTabPanel>
            <AnalyticsVerticalTabPanel
                screenWidth={window.innerWidth}
                paddingTop='0'
            >
                <Box width={1}>
                    <CommonVerticalTabPanel height="685px">
                        {userData && userData.details && userData.details.user && userData.details.user.enable_lead_capture === "true" ? (
                            <LoaderWrapper isLoading={isAnalyticsDataFetching}>
                                <Flex flexWrap="wrap" width="100%" pl="5px" style={{minHeight: '490px'}}>
                                    {!isEmpty(get(analyticsData, 'leadgen', [])) ? get(analyticsData, 'leadgen', []).slice(page*6, (page+1)*6).map((item, index) => (
                                        <LeadCaptureCard 
                                            key={index} 
                                            {...item} 
                                            isAssetAdmin={isAssetAdmin}
                                            isAssetAdvisor={isAssetAdvisor}
                                            userData={userData} 
                                        />
                                    )) : (
                                        <div style={{ display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center', height: '480px', fontSize: '16px' }}>
                                            No Records Found
                                        </div>
                                    )}
                                </Flex>
                                <Flex width='100%' mt='-18px' padding='0px 18px 0px 24px' alignItems="center" height="83px">
                                    <Flex width='67%'>
                                        {analyticsData.pagination && analyticsData.pagination.total_count && !isAnalyticsDataFetching && <span style={{ color: '#49484a', marginTop: '23px', fontSize: '14px', fontWeight: 'bold', marginLeft: '6px' }}>
                                            {startIndex}-{endIndex} of {analyticsData.pagination.total_count}
                                        </span>}
                                    </Flex>
                                    <Flex width='33%' justifyContent='space-between' alignItems='flex-end'>
                                        <Button styles={{border: 'none'}} margin="10px 0px 0px 0px" type="button" bgColor={page < 1 ? '#AFAFAF' : '#6351ed'} width='106px' onClick={() => setPage(page - 1)} disabled={page < 1 ? true : false}>Previous</Button>
                                        <Button styles={{border: 'none'}} margin="10px 0px 0px 0px" width='106px' bgColor={get(analyticsData, 'pagination.total_count', '0') === endIndex.toString() ? '#AFAFAF' : '#6351ed'} onClick={() => setPage(page + 1)} type="button" disabled={get(analyticsData, 'pagination.total_count', '0') === endIndex.toString()}>Next</Button>
                                    </Flex>
                                </Flex>
                            </LoaderWrapper>
                        ) : (<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '480px', fontSize: '16px' }}>
                            Contact Your Advisor Success Partner to Subscribe <br /> to Get Access to Leads.
                        </div>)}
                    </CommonVerticalTabPanel>
                    <DownloadButtonFlex>
                        <DownloadButton 
                            disabled={
                                userData && 
                                userData.details && 
                                userData.details.user && 
                                userData.details.user.enable_lead_capture === "true" && 
                                get(analyticsData, 'leadgen', []).length > 0 ? false : true
                            } 
                            onClick={() => handleDownloadCsv('referral')} 
                            fontSize='18px'
                        />
                    </DownloadButtonFlex>
                </Box>
            </AnalyticsVerticalTabPanel>
        </VerticalTabs>
    )
}

export default LeadCaptureTab;
