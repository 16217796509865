import { Flex } from '@rebass/grid';
import React from 'react';
import { Button, LoaderWrapper, ModalBody, ModalCloseButton, ModalHeader, ModalV2 } from '../../../../components';

const IgnoreCascadeModal = ({ close, yesHandler, loader, error, success, okHandler }) => {
    return (
        <ModalV2 bgColor='#fff' width='40%' isOpen={true} onRequestClose={success ? () => {} : close}>
            <ModalHeader>
                {success ? 'Success' : 'Confirm'}
                <ModalCloseButton onClick={success ? () => {} : close} />
            </ModalHeader>
            <ModalBody>
                <div style={{fontSize: '13px', color: '#49484a', marginBottom: '10px'}}>
                    {success ? 'Done!' : 'Are you sure you want to ignore this cascaded campaign?'}
                </div>
                {error && (
                    <div style={{fontSize: '10px', color: '#6351ed'}}>{error}</div>
                )}
                <LoaderWrapper isLoading={loader}>
                    <Flex width='33%' margin='auto' justifyContent='space-around' alignItems='center'>
                        <Button onClick={yesHandler}>Yes</Button>
                        <Button onClick={close}>No</Button>
                    </Flex>
                </LoaderWrapper>
            </ModalBody>
        </ModalV2>
    );
}

export default IgnoreCascadeModal;