import styled from 'styled-components';

export const InternalUseTextArea = styled.textarea.attrs({
  className: 'InternalUseTextArea'
})` 
  width: 89%;
  height: 150px;
  margin: 27px auto 10px;
  border: 1px solid #D8D8D8;
  resize: none;
  border-radius: 8px;
  text-align: left;
  max-height: 150px;
  overflow-y: auto;
  overflow-x: hidden;
  color: #777777;
  padding: 10px;

  &::placeholder {
    text-align: center;
  }
`;