import React from 'react';

import { MemberTileWrapper, MemberTileTitle } from './MemberTile.styles';

const MemberTile = ({ onClick, title, children }) => (
  <MemberTileWrapper onClick={onClick}>
    <MemberTileTitle>{title}</MemberTileTitle>
    {children}
  </MemberTileWrapper>
);

export default MemberTile;
