import React, { useState } from 'react';
import { Flex, Box } from '@rebass/grid';
import { MiniArticle, MioRadioSpan, MioRadio, Button, LoaderWrapper } from '../../../components';
import { InternalUseTextArea } from './InternalUse.styles';

const InternalUse = ({
  pdfImage,
  isPdf,
  userData,
  isAdmin,
  onUpdateTheme,
  ...rest
}) => {
  const [selectedComplianceCheck, setSelectedComplianceCheck] = useState('no');
  const [summary, setSummary] = useState('');
  const [loading, setLoading] = useState(false);

  const handleComplianceCheckChange = (val) => {
    setSelectedComplianceCheck(val);
  };

  const handleSummaryChange = (e) => {
    setSummary(e.target.value)
  }

  const handleSubmit = () => {
    setLoading(true);
    const summaryData = {
      short: '',
      medium: '',
      long: summary
    }
    const skip_compliance = (userData.compliance === 'on') ? false : (userData.compliance === 'off') ? true : selectedComplianceCheck === 'no' ? true : false; 
    onUpdateTheme([], 'Share Internal Issue', '', summaryData, skip_compliance);
  }
  return (
    <div>
      <Flex width={1}>
        <Box width='75%' style={{border: '1px solid #D8D8D8', borderRadius: '8px', height: '275px'}}>
          <InternalUseTextArea value={summary} onChange={handleSummaryChange} placeholder="Add a summary to this article"/>
          {isAdmin && userData.compliance_admin && userData.compliance === 'optional' && (
            <Flex
              width={1}
              alignItems="center"
              justifyContent="center"
              px="0px"
              pt="0px"
            >
              <Box style={{ textAlign: 'center', fontSize: '12px', marginRight: '6px' }}>Does this require compliance review?</Box>
              <Box style={{ marginRight: '13px' }}>
                <Flex width={1} alignItems="center" pl="20px">
                  <MioRadioSpan width="auto">
                    <MioRadio
                      id={'comlianceCheckYes'}
                      name="comlianceCheck"
                      onClick={() => handleComplianceCheckChange('yes')}
                      defaultChecked={selectedComplianceCheck === 'yes'}
                    />
                    <label htmlFor="comlianceCheckYes">Yes</label>
                  </MioRadioSpan>
                  <MioRadioSpan defaultChecked={selectedComplianceCheck === 'no'} width="auto">
                    <MioRadio
                      id={'comlianceCheckNo'}
                      name="comlianceCheck"
                      onClick={() => handleComplianceCheckChange('no')}
                      defaultChecked={selectedComplianceCheck === 'no'}
                    />
                    <label htmlFor="comlianceCheckNo">No</label>
                  </MioRadioSpan>
                </Flex>
              </Box>
            </Flex>
          )}
          <LoaderWrapper isLoading={loading} loaderHeight='50px'>
            <Button padding='3px 20px' bgColor={summary.length > 0 ? '#6351ed' : '#eeeeee'} disabled={summary.length === 0} onClick={handleSubmit}>
              {userData.compliance_admin && userData.compliance === 'on' ? 'Share After Compliance Approval' : 'Submit'}  
            </Button>
          </LoaderWrapper>
        </Box>
        <Box width='22%' style={{marginLeft: '15px'}}>
        <MiniArticle
          {...rest}
          pdfImage={pdfImage}
          isPdf={isPdf}
        />
        </Box>
      </Flex>
    </div>
  )
}

export default InternalUse;
