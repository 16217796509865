import { Grid, styled } from '@mui/material';

export const HeaderWrapper = styled(Grid)(() => ({
  width: '85%',

  '.title': {
    color: '#101828',
    fontWeight: 400,
    margin: 0,
    textAlign: 'left',
  },
  '.subTitle': {
    font: '400 0.75rem Poppins, sans-serif',
    margin: 0,
    color: '#101828',
    textAlign: 'left',
  },
}));
