import React from 'react';
import { ButtonStyle } from './styles';

const PurpleButton = ({children, onClick, width, height, margin, disabled, padding}) => {
    return(
        <ButtonStyle 
            onClick={onClick} 
            width={width} 
            height={height} 
            margin={margin} 
            disabled={disabled}
            padding={padding}
        >
            {children}
        </ButtonStyle>
    )
};

export default PurpleButton;