import { get } from 'lodash';
import { handleActions } from 'redux-actions';
import { apiModuleDefaultState, createApiActions } from './helpers';
import { themeNameIdMapping } from '../../utils';

export const actions = createApiActions('theme');

const reducer = handleActions(
  {
    [actions.api.theme.request]: (_, { payload }) => {
      return {
        isFetching: get(payload, 'params.noRefresh', false) ? false : true,
        isError: false,
      };
    },
    [actions.api.theme.response]: (_, { payload }) => ({
      isFetching: false,
      isError: false,
      data: payload,
      themeNames: themeNameIdMapping(payload),
    }),
    [actions.api.theme.error]: (_, { payload }) => ({
      isFetching: false,
      isError: true,
      error: payload,
    }),
    [actions.api.theme.setstate]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
  },
  { ...apiModuleDefaultState, data: [], error: null, themeNames: {} }
);

export default reducer;
