import React from 'react';
import { Flex } from '@rebass/grid';
import {
  Button,

  LoaderWrapper,
} from '../../../components';

import { useHistory } from 'react-router-dom';
import { AutomatedCampaignStepsFooterSectionV2 } from '../styles';
import { isEmpty } from 'lodash';
import { platforms } from '../../../utils';

const StepsFooterV2 = ({
  requestLoading,
  loader,
  currentStepIndex,
  setCurrentStepIndex,
  disableNext,
  disableBack,
  handleNext,
  isEditMode,
  campaignSetState,
  endCreateFlow,
  automatedCampaignDetails,
  requiredApproval,
  targetInfo,
  segmentListValid,
  campaignFlow,
  reviewType
}) => {

  const footerPlatforms = (platforms) => {
    console.log(platforms);
    const newplatforms = []
    if(platforms && !isEmpty(platforms)) {
      platforms.forEach((item) => {
        if(item === 'em') {
          newplatforms.push('segment');
        }
        if(item === 'fb') {
          newplatforms.push('facebook');
        }
        if(item === 'tw') {
          newplatforms.push('twitter');
        }
        if(item === 'ln') {
          newplatforms.push('linkedin');
        }
      })
  
      return newplatforms.toString()
    } else {
      return '';
    }
   
  }
  const history = useHistory();
  return (
    <AutomatedCampaignStepsFooterSectionV2 style={{ border: '1px solid #E4E7EC', }}>
      <LoaderWrapper isLoading={loader}>
        <Flex>
          <Button
            styles={{ borderRadius: '4px', border: '1px solid #E4E7EC' }}
            color="#101828"
            bgColor="#FFF"
            margin="0px 10px"
            onClick={() => {
              if (currentStepIndex === 0) {
                if(!campaignFlow) {
                  campaignSetState({ automatedDashboard: true });
                }
                endCreateFlow();
                history.push('/campaignV2');

              } // Reload the create page.
              if(!campaignFlow) {
                campaignSetState({
                  automatedCampaigns: {
                    ...automatedCampaignDetails,
                    activeStepIndex: currentStepIndex - 1,
                  },
                });
              } else {
                campaignSetState({
                  activeIndex: currentStepIndex - 1
                })
              }
              
              setCurrentStepIndex(currentStepIndex - 1);
            }}
            disabled={disableBack}
            id="btn_campaign_save_close"
          >
            Back
          </Button>
        </Flex>
        {currentStepIndex === 0 &&  targetInfo && targetInfo?.parent_platforms && !isEmpty(targetInfo?.parent_platforms) && !segmentListValid ? (
          <Flex fontSize="12px">
            Please select these platforms ({footerPlatforms(targetInfo?.parent_platforms)})
          </Flex>
        ) : null}
        <Flex>
          {currentStepIndex === 2 ? (
            <Button
              styles={{ borderRadius: '4px', boxShadow: '0px 1px 2px 0px #1018281F', display: 'flex', alignItems: 'center' }}
              color="#fff"
              bgColor="#816EF8"
              margin="0px 10px"
              onClick={() => handleNext()}
              disabled={disableNext}
              id="btn_campaign_next"
            >
              {requiredApproval || (reviewType === 'edit') ? 'Send To Compliance' : 'Create Campaign'}
              <img alt="" src="/assets/images/Campaign/create-campaign-icon.svg" style={{ marginLeft: '3px' }} />
            </Button>

          ) : (
            <Button
              styles={{ borderRadius: '4px', boxShadow: '0px 1px 2px 0px #1018281F' }}
              color="#fff"
              bgColor="#816EF8"
              margin="0px 10px"
              onClick={() => handleNext()}
              disabled={disableNext}
              id="btn_campaign_next"
            >
              Next
            </Button>
          )}
        </Flex>
      </LoaderWrapper>
    </AutomatedCampaignStepsFooterSectionV2>
  );
};

export default StepsFooterV2;
