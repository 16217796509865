import React, { useState, useEffect } from 'react';
import { get } from 'lodash';
import { Box } from '@rebass/grid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faComment, faUsers } from '@fortawesome/free-solid-svg-icons';
import FileSaver from 'file-saver';
import {
  Tabs,
  StyledTabListLeft,
  VerticalTabs,
  StackedBarChart,
  NumbersWithArrows,
  DownloadButton,
} from '../../../components';
import {
  DashboardAdminSubTab,
} from '../../DashboardContainer/DashboardContainer.styles';
import {
  getTabCount,
  getTabNameFromRepType,
  newTabCount,
  parseValueToCsv,
  saveFile,
} from '../../../utils';

import {
  AnalyticsVerticalTab,
  AnalyticsVerticalTabList,
  AnalyticsVerticalTabPanel,
  TabCount,
  TabLabel,
  TabContent,
  CommonTabPanel,
  DownloadButtonFlex,
  platformChartIcons,
} from '../AnalyticsContainer.styles';
import { downloadPdf } from '../../../services/analytics';

const styles = {
  adminSubTab: { fontSize: '14px', marginBottom: '20px' },
};

const ImpactTab = ({
  selectedDate,
  setSelectedDate,
  analyticsRequest,
  isAnalyticsDataFetching,
  analyticsObject,
  selectedRepType,
}) => {
  const [pdfUrl, setPdfUrl] = useState('');
  const [reachTabIndex, setReachTabIndex] = useState(0);
  const [clicksTabIndex, setClicksTabIndex] = useState(0);
  const [engagementTabIndex, setEngagementTabIndex] = useState(0);

  const apiRequest = (repType) => {
    analyticsRequest({
      params: {
        repType,
      },
    });
  };

  const refreshAllTabs = () => {
    onTabSelect(2);
    onTabSelect(1);
    onTabSelect(0);
  };

  const onReachTabSelect = (tabIndex) => {
    setReachTabIndex(tabIndex);
    let repType = 'user_topic_reach';
    if (tabIndex === 1) {
      repType = 'user_reach_by_platform';
    } else if (tabIndex === 2) {
      repType = 'user_partner_reach';
    }
    apiRequest(repType);
  };

  const onClicksTabSelect = (tabIndex) => {
    setClicksTabIndex(tabIndex);
    let repType =
      tabIndex === 0 ? 'user_topic_click' : 'user_click_by_platform';
    apiRequest(repType);
  };

  const onTabSelect = (tabIndex) => {
    if (tabIndex === 0) {
      onReachTabSelect(reachTabIndex);
    } else if (tabIndex === 1) {
      onClicksTabSelect(clicksTabIndex);
    } else if (tabIndex === 2) {
      apiRequest('user_network_engagement');
    }
  };

  useEffect(() => {
    refreshAllTabs();
    const { startDate, endDate } = analyticsObject;
    downloadPdf({
      startDate,
      endDate,
      asAdvisor: true,
    }).then((response) => {
      if (
        response.result.data &&
        response.result.data.analytics &&
        response.result.data.analytics.pdf
      ) {
        setPdfUrl(response.result.data.analytics.pdf.link);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDate]);
  const reachByTopicData = get(analyticsObject, `user_topic_reach.slicedData`);
  const engagementPreviousValue = get(
    analyticsObject,
    'user_network_engagement.previousValue'
  );
 
  const reachByPlatformData = get(
    analyticsObject,
    `user_reach_by_platform.data`
  );
  const reachByPartner = get(analyticsObject, `user_partner_reach.slicedData`);
  const clickByTopicData = get(analyticsObject, `user_topic_click.slicedData`);
  const clickByPlatformData = get(
    analyticsObject,
    `user_click_by_platform.data`
  );
  const engagementData = get(analyticsObject, `user_network_engagement.data`);
  let engagementPlatformData = { tw: [], fb: [] };
  let csvData = get(analyticsObject, `${selectedRepType}.data`, []);
  csvData = csvData.filter((c) => c.total > 0 || c.value > 0);
  const reactTabCountData =
    reachTabIndex === 0
      ? newTabCount(
          get(analyticsObject, `user_topic_reach.total`),
          get(analyticsObject, `user_topic_reach.previousValue`),
          true
        )
      : reachTabIndex === 1
      ? newTabCount(
          get(analyticsObject, `user_reach_by_platform.total`),
          get(analyticsObject, `user_reach_by_platform.previousValue`),
          true
        )
      : newTabCount(
          get(analyticsObject, `user_partner_reach.total`),
          get(analyticsObject, `user_partner_reach.previousValue`),
          true
        );

  const clicksTabCountData =
    clicksTabIndex === 0
      ? newTabCount(
          get(analyticsObject, `user_topic_click.total`),
          get(analyticsObject, `user_topic_click.previousValue`),
          true
        )
      : newTabCount(
          get(analyticsObject, `user_click_by_platform.total`),
          get(analyticsObject, `user_click_by_platform.previousValue`),
          true
        );

  const engagementTabCountData = getTabCount(
    get(analyticsObject, `user_network_engagement.data`),
    engagementPreviousValue
  );

  const onEngagmentTabSelect = (tabIndex) => {
    setEngagementTabIndex(tabIndex);
    let repType = tabIndex === 0 ? 'twitter' : 'facebook';
  };

  if (engagementData && engagementData.length > 0) {
    engagementPlatformData.tw = engagementData.filter(
      (value) => value.platform === 'tw'
    );
    engagementPlatformData.fb = engagementData.filter(
      (value) => value.platform === 'fb'
    );
  }

  const onDownloadCSVClick = () => {
    const tabName = getTabNameFromRepType(selectedRepType);
    const { startDate, endDate } = analyticsObject || {};
    const fileName = `${tabName}-${startDate}_${endDate}`;
    const parsedData = parseValueToCsv(csvData, selectedRepType, true);
    if (csvData.length > 0) {
      saveFile(parsedData, fileName);
    }
  };

  const onDownloadPdf = () => {
    if (pdfUrl) {
      FileSaver.saveAs(pdfUrl, 'analytics.pdf');
    }
  };

  const legends = {
    tw: [
      { id: 'Likes', type: 'square', value: 'Likes', color: '#7DC4D0' },
      { id: 'Retweets', type: 'square', value: 'Retweets', color: '#C95CFC' },
      { id: 'Replies', type: 'square', value: 'Replies', color: '#8F84E3' },
    ],
    fb: [
      { id: 'Likes', type: 'square', value: 'Likes', color: '#7DC4D0' },
      { id: 'Comments', type: 'square', value: 'Comments', color: '#C95CFC' },
      { id: 'Reactions', type: 'square', value: 'Reactions', color: '#8F84E3' },
    ],
  };

  return (
    <VerticalTabs screenWidth={window.innerWidth} onSelect={onTabSelect}>
      <AnalyticsVerticalTabList screenWidth={window.innerWidth}>
        <AnalyticsVerticalTab>
          <TabContent>
            {/* <ReachIcon /> */}
            <div style={{ padding: '2px' }}>
              <TabLabel fontSize="16px">Reach</TabLabel>
            </div>
            <div style={{ padding: '2px' }}>
              <TabCount fontSize="28px">{reactTabCountData.tabCount}</TabCount>
            </div>
            <div style={{ padding: '2px' }}>
              {reactTabCountData.tabCount !== 0 ? (
                <TabLabel fontSize="16px">
                  <NumbersWithArrows
                    value={reactTabCountData.trend}
                    suffix="%"
                  />
                </TabLabel>
              ) : null}
            </div>
          </TabContent>
        </AnalyticsVerticalTab>
        <AnalyticsVerticalTab>
          <TabContent>
            <FontAwesomeIcon icon={faComment} />
            <div style={{ padding: '2px' }}>
              <TabLabel fontSize="16px">Clicks</TabLabel>
            </div>
            <div style={{ padding: '2px' }}>
              <TabCount fontSize="28px">{clicksTabCountData.tabCount}</TabCount>
            </div>
            <div style={{ padding: '2px' }}>
              {clicksTabCountData.tabCount !== 0 ? (
                <TabLabel fontSize="16px">
                  <NumbersWithArrows
                    value={clicksTabCountData.trend}
                    suffix="%"
                  />
                </TabLabel>
              ) : null}
            </div>
          </TabContent>
        </AnalyticsVerticalTab>
        <AnalyticsVerticalTab>
          <TabContent>
            <FontAwesomeIcon icon={faUsers} />
            <div style={{ padding: '2px' }}>
              <TabLabel fontSize="16px">Engagement</TabLabel>
            </div>
            <div style={{ padding: '2px' }}>
              <TabCount fontSize="28px">
                {engagementTabCountData.tabCount}
              </TabCount>
            </div>
            <div style={{ padding: '2px' }}>
              {engagementTabCountData.tabCount !== 0 ? (
                <TabLabel fontSize="16px">
                  <NumbersWithArrows
                    value={engagementTabCountData.trend}
                    suffix="%"
                  />
                </TabLabel>
              ) : null}
            </div>
          </TabContent>
        </AnalyticsVerticalTab>
      </AnalyticsVerticalTabList>
      <AnalyticsVerticalTabPanel paddingTop='0' screenWidth={window.innerWidth}>
        <Tabs onSelect={onReachTabSelect}>
          <StyledTabListLeft marginLeft='0' screenWidth={window.innerWidth}>
            <DashboardAdminSubTab
              style={styles.adminSubTab}
              color="#49484a"
              padding="5px 23px 3px"
              type="analytics-tab"
              className={`${reachTabIndex === 0 ? 'active' : 'inactive'}`}
            >
              By Topic
            </DashboardAdminSubTab>
            <DashboardAdminSubTab
              style={styles.adminSubTab}
              color="#49484a"
              padding="5px 23px 3px"
              type="analytics-tab"
              className={`${reachTabIndex === 1 ? 'active' : 'inactive'}`}
            >
              By Platform
            </DashboardAdminSubTab>
            <DashboardAdminSubTab
              style={styles.adminSubTab}
              color="#49484a"
              padding="5px 23px 3px"
              type="analytics-tab"
              className={`${reachTabIndex === 2 ? 'active' : 'inactive'}`}
            >
              By Publisher
            </DashboardAdminSubTab>
          </StyledTabListLeft>
          <CommonTabPanel>
            
            <StackedBarChart
              yKey={'Reach'}
              loading={isAnalyticsDataFetching}
              data={reachByTopicData}
            />
          </CommonTabPanel>
          <CommonTabPanel>
            
            <StackedBarChart
              yKey={'Reach'}
              loading={isAnalyticsDataFetching}
              data={reachByPlatformData}
              xAxisIcons={platformChartIcons}
            />
          </CommonTabPanel>
          <CommonTabPanel>
            
            <StackedBarChart
              yKey={'Reach'}
              loading={isAnalyticsDataFetching}
              data={reachByPartner}
            />
          </CommonTabPanel>
          <DownloadButtonFlex justifyContent="space-between">
            <DownloadButton
              text="PDF"
              onClick={onDownloadPdf}
              fontSize="18px"
            />
            <DownloadButton
              disabled={csvData.length > 0 ? false : true}
              onClick={onDownloadCSVClick}
              fontSize="18px"
            />
          </DownloadButtonFlex>
        </Tabs>
      </AnalyticsVerticalTabPanel>
      <AnalyticsVerticalTabPanel paddingTop='0' screenWidth={window.innerWidth}>
        <Tabs onSelect={onClicksTabSelect}>
          <StyledTabListLeft>
            <DashboardAdminSubTab
              style={styles.adminSubTab}
              color="#49484a"
              padding="5px 23px 3px"
              type="analytics-tab"
              className={`${clicksTabIndex === 0 ? 'active' : 'inactive'}`}
            >
              By Topic
            </DashboardAdminSubTab>
            <DashboardAdminSubTab
              style={styles.adminSubTab}
              color="#49484a"
              padding="5px 23px 3px"
              type="analytics-tab"
              className={`${clicksTabIndex === 1 ? 'active' : 'inactive'}`}
            >
              By Platform
            </DashboardAdminSubTab>
          </StyledTabListLeft>
          <CommonTabPanel>
            
            <StackedBarChart
              yKey={'Clicks'}
              loading={isAnalyticsDataFetching}
              data={clickByTopicData}
            />
          </CommonTabPanel>
          <CommonTabPanel>
            
            <StackedBarChart
              yKey={'Clicks'}
              loading={isAnalyticsDataFetching}
              data={clickByPlatformData}
              xAxisIcons={platformChartIcons}
            />
          </CommonTabPanel>
          <DownloadButtonFlex>
            <DownloadButton
              disabled={csvData.length > 0 ? false : true}
              onClick={onDownloadCSVClick}
              fontSize="18px"
            />
          </DownloadButtonFlex>
        </Tabs>
      </AnalyticsVerticalTabPanel>
      <AnalyticsVerticalTabPanel paddingTop='0' screenWidth={window.innerWidth}>
        <Box width={1}>
          <Tabs onSelect={onEngagmentTabSelect}>
            <StyledTabListLeft>
              <DashboardAdminSubTab
                style={styles.adminSubTab}
                color="#49484a"
                padding="5px 23px 3px"
                type="analytics-tab"
                className={`${
                  engagementTabIndex === 0 ? 'active' : 'inactive'
                }`}
              >
                Twitter
              </DashboardAdminSubTab>
              <DashboardAdminSubTab
                style={styles.adminSubTab}
                color="#49484a"
                padding="5px 23px 3px"
                type="analytics-tab"
                className={`${
                  engagementTabIndex === 1 ? 'active' : 'inactive'
                }`}
              >
                Facebook
              </DashboardAdminSubTab>
            </StyledTabListLeft>
            {/* <CommonVerticalTabPanel>
              <DateDropdown value={selectedDate} onDateChange={setSelectedDate} type='analytics'/>
              <StackedBarChart
                yKey={''}
                loading={isAnalyticsDataFetching}
                data={engagementData}
                tabContentType={'Engagement'}
                xAxisIcons={platformChartIcons}
              />
            </CommonVerticalTabPanel> */}
            <CommonTabPanel>
              
              <StackedBarChart
                yKey={''}
                loading={isAnalyticsDataFetching}
                data={engagementPlatformData.tw}
                tabContentType={'twEngagement'}
                xAxisIcons={platformChartIcons}
                legends={legends.tw}
              />
            </CommonTabPanel>
            <CommonTabPanel>
              
              <StackedBarChart
                yKey={''}
                loading={isAnalyticsDataFetching}
                data={engagementPlatformData.fb}
                tabContentType={'fbEngagement'}
                xAxisIcons={platformChartIcons}
                legends={legends.fb}
              />
            </CommonTabPanel>
            <DownloadButtonFlex>
              <DownloadButton
                disabled={csvData.length > 0 ? false : true}
                onClick={onDownloadCSVClick}
                fontSize="18px"
              />
            </DownloadButtonFlex>
          </Tabs>
        </Box>
      </AnalyticsVerticalTabPanel>
    </VerticalTabs>
  );
};

export default ImpactTab;
