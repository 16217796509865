import { useMutation, useQueryClient } from 'react-query';
import apiInstance from 'src/services/api';
import { objectToFormData } from 'src/services/helpers';

function useMutationForAddingBookmark() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: 'adding-bookmark',
    mutationFn: async (articleIds, themeId) => {
      const stringifiedArray = JSON.stringify([articleIds]);
      const rawData = themeId
        ? { article_ids: stringifiedArray, theme_id: themeId }
        : { article_ids: stringifiedArray };
      const response = await apiInstance().post(
        '/bookmark',
        objectToFormData(rawData)
      );
      return response;
    },
    onSuccess: () => {
      queryClient.refetchQueries(['discover-bookmarks']);
      queryClient.refetchQueries(['discover-my-content']);
      queryClient.refetchQueries(['news-feed']);
      queryClient.refetchQueries(['branded-articles']);
      queryClient.refetchQueries(['discover-recommended-articles']);
    },
  });
}

export default useMutationForAddingBookmark;
