import { styled } from '@mui/material';

export const ZeroStateContainer = styled('div')(() => ({
  marginTop: '30px',
  '& svg': {
    fontSize: '0.75rem',
    marginBottom: '10px',
  },

  '& p': {
    font: '400 1rem Poppins, sans-serif',
    margin: 0,
    color: '#000',
  },
}));
