import React from 'react';
import BulbIcon from 'src/assets/icons/Lightbulb';
import ZeroStateBackground from 'src/assets/background/home/key-insights-zero-state.svg';
import {
  ZeroStateContainer,
  ZeroStateWrapper,
  ZeroStateContent,
} from '../styles';

function ZeroState() {
  return (
    <ZeroStateWrapper>
      <ZeroStateContainer backgroundUrl={ZeroStateBackground}>
        <ZeroStateContent height="25rem">
          <div className="circled-icon">
            <BulbIcon />
          </div>
          <h5 className="title">
            Start your very first campaign to see your key insights{' '}
          </h5>
        </ZeroStateContent>
      </ZeroStateContainer>
    </ZeroStateWrapper>
  );
}

export default ZeroState;
