import styled from 'styled-components';
import { ModalBody } from '../../components';

export const SummaryContainer = styled.div`
  height: 100%;
  margin-top: 30px;
  font-family: 'Poppins';
  margin-right: 15px;
  margin-left: 15px;
  width: 970px;

  @media (max-width: 768px) {
    margin-right: 5px;
    margin-left: 5px;
  }  
`;
export const ContainerList = styled.div`
  display: flex;
  list-style-type: none;
  span:first-child {
    width: 28%;
    margin-right: 2%;
  }
  span:nth-child(2) {
    width: 69%;
  }
  &:last-child {
    margin-top: 20px;
  }
  @media (max-width: 768px) {
    flex-direction: column;

    span:first-child {
      width: 86%;
      margin-right: 2%;
    }
    span:nth-child(2) {
      width: 86%;
    }
  }
`;
export const ListItem = styled.span`
  width: 100%;
`;
export const UserAccount = styled.div`
  display: block;
  border-radius: 8px;
  padding: 10px 10px;
  text-align: center;
  min-height: 252px;
  background: linear-gradient(
    to bottom,
    rgba(252, 252, 252, 1) 0%,
    rgba(247, 247, 247, 1) 50%,
    rgba(247, 247, 247, 1) 50%,
    rgba(227, 227, 227, 1) 100%
  ) !important;
  border: 1px solid #e1e1e1 !important;

  @media (max-width: 768px) {
    margin-top: 19%;
    margin-bottom: 5%;
  }  
`;
export const UserName = styled.h4`
  // color: #6351ed !important;
  font-size: 14px !important;
  margin-top: 10px;
  margin-bottom: 10px;
  font-weight: 500;
  line-height: 1.1;
`;

export const Overview = styled.div`
  border-radius: 8px;
  background-color: #c8c8c8;
  width: 100%;
`;
export const OverviewTitle = styled.h4`
  margin: 0;
  padding: 10px 0;
  font-size: 13px;
  height: 33px;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  font-weight: 600;
  background: #e4e4e5;
`;

export const OverviewList = styled.div`
  background: linear-gradient(
    to bottom,
    rgba(250, 250, 250, 1) 0%,
    rgba(242, 242, 242, 1) 50%,
    rgba(242, 242, 242, 1) 50%,
    rgba(227, 227, 227, 1) 100%
  );
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  position: relative;
  min-height: 218px;
`;
export const OverlayBox = styled.div`
  height: 100%;
  width: 100%;
  background: #636363;
  position: absolute;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
  opacity: 0.7;
  display: table;
  z-index: 1;
`;
export const OverlayFooter = styled.h2`
  font-weight: 500;
  color: #fff !important;
  font-size: 15px;
  display: block;
  position: absolute;
  bottom: 0;
  text-align: center;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 10px;
`;
export const OverlayLink = styled.a`
  color: #fff !important;
  text-decoration: underline;
  cursor: pointer;
`;

export const OverviewInnerList = styled.ul`
  padding: 20px 10px 24px;
  height: 100%;
  margin: 0;
  text-align: center;
  li {
    margin: 0 10px;
    padding: 0;
  }
`;
export const OverviewListItem = styled.li`
  text-align: center;
  display: inline-block;
`;
export const ListItemHeader = styled.h4`
  font-size: 12px;
  // color: #6351ed;
  font-weight: 600;
  margin-top: 10px;
  margin-bottom: 10px;
`;
export const ListItemLink = styled.a``;
export const ListItemLinkDiv = styled.div`
  padding: 18px 0;
  border-radius: 10px;
  width: 110px;
  height: 106px;
  background-color: #e4e4e4 !important;
`;
export const ListItemLinkImage = styled.img`
  font-size: 10px;
  border-radius: 6px;
  width: 70px;
  height: 70px;
`;
export const ListItemLinkFooter = styled(ListItemHeader)``;

export const InsightScore = styled.div`
  border: none;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  
`;
export const InsightScoreHeader = styled.h4`
  margin: 0;
  // color: #6351ed !important;
  text-align: center;
  font-family: varela round;
  font-size: 13px;
  height: 39px;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  padding: 10px 0;
  font-weight: normal;
  background-color: #e4e4e5 !important;
`;
export const InsightScoreBody = styled.div`
  text-align: center;
  border-bottom-left-radius: 8px;
  background: linear-gradient(
    to bottom,
    rgba(252, 252, 252, 1) 0%,
    rgba(247, 247, 247, 1) 50%,
    rgba(247, 247, 247, 1) 50%,
    rgba(227, 227, 227, 1) 100%
  ) !important;
  display: flex;
  flex-direction: column;
`;

export const InsightScorePointContainer = styled.div`
  width: 100px;
  margin: 0 auto;
  padding: 30px 0;
`;
export const InsightScorePoint = styled.h2`
  font-weight: 600;
  // border: 2px solid #6351ed;
  background: #ececec;
  padding: 22px 0;
  color: #6351ed;
  font-size: 38px;
  border-radius: 10px;
  width: 100px;
  height: 90px;
  display: table-cell;
  vertical-align: middle;
`;
export const InsightScoreContent = styled.h3`
  color: #49484a;
  font-size: 14px;
  padding: 15px 0;
  background-color: transparent !important;
  margin: 0;
  font-weight: normal;
  line-height: 1.43;
`;
export const InsightScoreFooter = styled.h6`
  padding: 15px 0 12px;
  color: #757575;
  font-size: 9px;
  text-decoration: underline;
  font-weight: 600;
  background-color: transparent;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  margin: 0px;
`;
export const InsightScoreFooterLink = styled.a`
  color: #6351ed;
  transition: all 0.3s ease;
  cursor: pointer;
`;

export const InsightScoreGraph = styled.div`
  background-color: #d5cce6;
  margin-bottom: 10px;
  border-radius: 10px;

  @media (max-width: 768px) {
    margin-top: 5%;
  }  
`;
export const ComponentSection = styled.div`
  text-align: justify;
  border: 1px solid #a1a1a1;
  background-color: transparent;
  display: block;
  font-family: 'Poppins';
  width: 100%;
  height: 34px;
  padding: 6px 12px;
  line-height: 1.42857143;
  color: #555;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
`;

export const CustomModalBody = styled(ModalBody)`
  display: inline-block;
  border: none;
  div {
    border: none;
  }
`;

export const AnalyticsValue = styled.div`
  background: linear-gradient(
    to bottom,
    rgba(252, 252, 252, 1) 1%,
    rgba(244, 244, 244, 1) 50%,
    rgba(244, 244, 244, 1) 50%,
    rgba(227, 227, 227, 1) 100%
  );
  border-bottom-right-radius: 8px;
`;
export const TabsContainer = styled.div`
  padding: 5px 30px;
  border-bottom: 1px solid #e1e1e1 !important;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  background: linear-gradient(
    to bottom,
    rgba(252, 252, 252, 1) 0%,
    rgba(247, 247, 247, 1) 50%,
    rgba(247, 247, 247, 1) 50%,
    rgba(227, 227, 227, 1) 100%
  ) !important;
`;
export const ResponsiveTabs = styled.div`
  margin-bottom: 20px !important;
`;

export const ChartBoxes = styled.div`
  padding: 5px;
  display: flex;
  // background-color: #e4e4e4;
`;
export const ChartBox = styled.div`
  width: 75%;
  box-shadow: none;
  margin-right: 1%;
`;
export const ChartInfo = styled.div`
  width: 24%;
  padding: 5px;
`;

export const InfoBox = styled.div`
  background: #e4e4e5 !important;
  color: #6351ed;
  border-bottom-right-radius: 5px !important;
  box-shadow: none;
  margin-right: 5%;
  text-align: center;
  width: 100% !important;
  border-radius: 6px;
  padding: 5px 0;
  margin-bottom: 10px;
  &:nth-child(2) {
    h6,
    h5 {
      color: #49484a !important;
    }
  }
  &:nth-child(3) {
    h6,
    h5 {
      color: #49484a;
    }
  }
`;
export const InfoBoxHeader = styled.h6`
  font-size: 10px;
  margin: 3px 0;
  font-weight: 500;
  line-height: 1.1;
`;
export const InfoBoxData = styled.h5`
  font-weight: 700;
  margin: 3px 0;
`;

export const LoaderContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const TotalImpactMineContainer = styled.h3`
  font-weight: 600;
  color: #6351ed;
  font-size: 28px;
  margin: 20px auto;
`;

export const PlaformLogoContainer = styled.div`
  display: inline-block;
  background-color: #6351ed;
  border-radius: 6px;
  padding: 15px 16px;
`;

export const Image = styled.img`
  width: 100px;
  height: 100px;
  margin: 20px auto 25px;
  display: block;
  object-fit: cover;
  border-radius: 50%;
`;
