import { Flex } from "@rebass/grid";
import styled from "styled-components";

export const AssignArticlesWrapper = styled(Flex).attrs({
    className: 'write-summary-wrapper'
})`
    flex-direction: column;
    align-items: center;
    padding: 30px 30px 0px;
    background-color: #fff; 
    height: 73%;
    max-height: 73%;
    overflow-x: hidden;
    overflow-y: auto;

    &::-webkit-scrollbar {
        width: 5px;
    }

    &::-webkit-scrollbar-track {
        background: #E9F6E6 !important;
    }

    &::-webkit-scrollbar-thumb {
        background: #BCCDD4 !important;
    }

`;