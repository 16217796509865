import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Flex } from '@rebass/grid'
import React from 'react'
import { Button, LoaderWrapper } from '../../../components'
import { CampaignModal } from './PlanCampaign.styles'

const FrequencyModal = ({
    isOpen,
    handleModalClose,
    onAccept,
    loader
}) => {
    return (
        <CampaignModal 
            isOpen={isOpen}
            shouldCloseOnOverlayClick
            onRequestClose={handleModalClose}
            padding="0px"
            width="40%"
        >
            <Flex flexDirection="column" width="100%">
                <Flex width="100%" justifyContent="flex-end" padding="20px" style={{borderBottom: '1px solid #CFD9E4'}}>
                    <FontAwesomeIcon icon={faTimes} color="#000000" style={{cursor: 'pointer'}} onClick={handleModalClose} />
                </Flex>
                <Flex width="100%" padding="20px 20px 5px" fontSize="16px" color="#000000" justifyContent="center">
                    Are you sure you want to continue ?
                </Flex>
                <Flex width="100%" fontSize="12px" color="#000000" justifyContent="center">
                    * These settings cannot be changed in future
                </Flex>
                <Flex mt="20px" padding="20px" justifyContent="center" style={{borderTop: '1px solid #CFD9E4'}}>
                   <LoaderWrapper isLoading={loader}>
                        <Button margin="0px 10px" color="#fff" bgColor="#6250ED" styles={{borderRadius: '4px'}} onClick={onAccept}>
                            Yes                   
                        </Button>
                        <Button margin="0px 10px" color="#fff" bgColor="#BCCDD4" styles={{borderRadius: '4px', border: 'none'}} onClick={handleModalClose}>
                            No                   
                        </Button>
                   </LoaderWrapper>
                </Flex>
            </Flex>
            
        </CampaignModal>
    )
}

export default FrequencyModal
