/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Button, LoaderWrapper } from '../../../../../components';
import './ConnectedComponent.css';
import PurpleButton from '../../../../../componentsV2/PurpleButton';
import WhiteButton from '../../../../../componentsV2/WhiteButton';

const connectedComponent = ({ 
    showDisconnected, 
    updateConnection, 
    showDelete, 
    toggleDelete, 
    disconnectLoader, 
    deleteLoader,
    close,
    setErrorMessage 
}) => {
    return (
        <>
            {
                showDisconnected && !showDelete ? (
                    <div style={{ margin: 'auto', textAlign: 'center' }}>
                        <WhiteButton margin='10px auto' onClick={close}>Back</WhiteButton>
                        <br/>
                        <a href='#' className='connected-delete-btn' onClick={() => toggleDelete(true)} >Delete and add a new connection</a>
                    </div>
                ) : showDelete ? (
                    <div style={{ margin: 'auto', textAlign: 'center' }}>
                        <LoaderWrapper isLoading={deleteLoader}>
                            <PurpleButton margin={'10px auto'} onClick={() => updateConnection('delete')}>Delete the connection</PurpleButton>
                        </LoaderWrapper>
                        <WhiteButton margin={'10px auto'} onClick={() => {
                            setErrorMessage('');
                            toggleDelete(false);
                        }}>Back</WhiteButton>
                    </div>
                ) : (
                    <div style={{ margin: 'auto', textAlign: 'center' }}>
                        <LoaderWrapper isLoading={disconnectLoader}>
                            <PurpleButton margin='15px auto 0' onClick={() => updateConnection('disconnect')}>Disconnect the connection</PurpleButton>
                        </LoaderWrapper>
                        <p style={{ color: '#555', margin: '6px auto' }}>or</p>
                        <PurpleButton margin='10px auto' onClick={() => toggleDelete(true)}>Delete the connection</PurpleButton>
                    </div>
                )
            }
        </>
    );
}

export default connectedComponent;