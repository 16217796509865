

export const ScriptJson = process.env.REACT_APP_API_ENV === 'dev' || process.env.REACT_APP_API_ENV  === 'qa' || process.env.REACT_APP_API_ENV  === 'stage' ?
          {
            "container":{
               "style":{
                  "background-color":"transparent",
                  "background-image":"none",
                  "background-repeat":"no-repeat",
                  "background-position":"top left"
               }
            },
            "content":{
               "style":{
                  "background-color":"transparent",
                  "color":"#000000",
                  "width":"800px",
                  "background-image":"none",
                  "background-repeat":"no-repeat",
                  "background-position":"top left"
               },
               "computedStyle":{
                  "rowColStackOnMobile":true,
                  "rowReverseColStackOnMobile":false
               }
            },
            "columns":[
               {
                  "style":{
                     "background-color":"transparent",
                     "border-bottom":"0px solid transparent",
                     "border-left":"0px solid transparent",
                     "border-right":"0px solid transparent",
                     "border-top":"0px solid transparent",
                     "padding-bottom":"5px",
                     "padding-left":"0px",
                     "padding-right":"0px",
                     "padding-top":"5px"
                  },
                  "modules":[
                     {
                        "type":"mailup-bee-newsletter-modules-html",
                        "descriptor":{
                           "html":{
                              "html":"<script src=\"https://clout-widget.s3.us-east-2.amazonaws.com/form-qa.js\"></script>"
                           },
                           "style":{
                              "padding-top":"0px",
                              "padding-right":"0px",
                              "padding-bottom":"0px",
                              "padding-left":"0px"
                           },
                           "computedStyle":{
                              "hideContentOnMobile":false
                           }
                        },
                        "uuid":"cafc8f34-9779-4903-b81f-e3d1af43927c"
                     }
                  ],
                  "grid-columns":12,
                  "uuid":"06b21e20-531f-4d02-ae2e-77621ccdc11e"
               }
            ],
            "type":"one-column-empty",
            "uuid":"5dcafc1f-7a04-402e-8bd0-4bcff12360fc",
            "metadata": {
                "name": "script"
            }
         } : {
            "container":{
               "style":{
                  "background-color":"transparent",
                  "background-image":"none",
                  "background-repeat":"no-repeat",
                  "background-position":"top left"
               }
            },
            "content":{
               "style":{
                  "background-color":"transparent",
                  "color":"#000000",
                  "width":"800px",
                  "background-image":"none",
                  "background-repeat":"no-repeat",
                  "background-position":"top left"
               },
               "computedStyle":{
                  "rowColStackOnMobile":true,
                  "rowReverseColStackOnMobile":false
               }
            },
            "columns":[
               {
                  "style":{
                     "background-color":"transparent",
                     "border-bottom":"0px solid transparent",
                     "border-left":"0px solid transparent",
                     "border-right":"0px solid transparent",
                     "border-top":"0px solid transparent",
                     "padding-bottom":"5px",
                     "padding-left":"0px",
                     "padding-right":"0px",
                     "padding-top":"5px"
                  },
                  "modules":[
                     {
                        "type":"mailup-bee-newsletter-modules-html",
                        "descriptor":{
                           "html":{
                              "html":"<script src=\"https://clout-widget.s3.us-east-2.amazonaws.com/form.js\"></script>"
                           },
                           "style":{
                              "padding-top":"0px",
                              "padding-right":"0px",
                              "padding-bottom":"0px",
                              "padding-left":"0px"
                           },
                           "computedStyle":{
                              "hideContentOnMobile":false
                           }
                        },
                        "uuid":"cafc8f34-9779-4903-b81f-e3d1af43927c"
                     }
                  ],
                  "grid-columns":12,
                  "uuid":"06b21e20-531f-4d02-ae2e-77621ccdc11e"
               }
            ],
            "type":"one-column-empty",
            "uuid":"5dcafc1f-7a04-402e-8bd0-4bcff12360fc",
            "metadata": {
                "name": "script"
            }
         }