import React, { useState } from 'react';
import { Flex } from '@rebass/grid';
import { SearchContainer } from '../EmailList/EmailList.styles';
import SearchBar from '../SearchBar';
import {
  PublisherCardWrapper,
  StyledCross,
  StyledImgDiv,
  StyledTitle,
  StyledTitleDiv,
} from '../ManagePublisher/styles';
import { Chip } from '../../../componentsV2/Chip';
import _, { isEmpty } from 'lodash';
import { Tooltip, Zoom } from '@mui/material';

const PublisherSearch = ({
  publishers,
  searchInput,
  setSearchInput,
  myPublisherList,
  postMyPublisherSubscribeChannelClick,
  isAdmin,
  handlePublisherSubscribeClick,
}) => {
  const [showSearchResults, toggleSearchResults] = useState(false);
  const [filteredPublishers, setFilteredPublishers] = useState([]);

  const getImages = (channel) => {
    if (
      channel.media_urls &&
      channel.media_urls[0] &&
      channel.media_urls[0].path &&
      channel.media_urls[0].name.resized &&
      channel.media_urls[0].ext
    )
      return `${channel.media_urls[0].path}/${channel.media_urls[0].name.resized}.${channel.media_urls[0].ext}`;
    else return '';
  };

  // const searchHandler = (collection, text) => {
  //   if (text === '') {
  //     toggleSearchResults(null);
  //     return;
  //   }
  //   text = _.toLower(text);
  //   const result = _.filter(collection, function (object) {
  //     return _(object).some(function (string) {
  //       return _(string).toLower().includes(text);
  //     });
  //   });
  //   if (
  //     result.length === 1 &&
  //     _.find(myPublisherList, { id: result[0].id }) !== undefined
  //   ) {
  //     toggleSearchResults([
  //       { alert: 'Your are already subscribed to this partner' },
  //     ]);
  //     return;
  //   }
  //   toggleSearchResults(result);
  // };

  const searchHandler = () => {
    let searchResult = publishers.filter((pub) =>
      pub.name.toLowerCase().includes(searchInput.toLowerCase())
    );
    setFilteredPublishers(searchResult);
    toggleSearchResults(true);
  };

  console.log("searh result--->", filteredPublishers)

  return (
    <div style={{ width: '98%', textAlign: 'center' }}>
      <SearchContainer
        style={{ width: '97%', alignSelf: 'flex-start' }}
      >
        <SearchBar
          searchString={searchInput}
          setSearchString={setSearchInput}
          searchHandler={searchHandler}
          //searchHandler={(value) => searchHandler(publishers, value)}
          placeholder="Find More Publishers"
        />
      </SearchContainer>
      {!isEmpty(searchInput) && showSearchResults ? (
        <Flex
          width="100%"
          flexDirection="column"
          height={showSearchResults.length === 0 ? '100px' : '300px'}
          maxHeight="300px"
        >
          <Flex
            width="100%"
            justifyContent="flex-start"
            padding="8px 0px 10px 0px"
            marginBottom="5px"
            style={{
              fontSize: '16px',
              color: '#000000',
              alignItems: 'center',
            }}
          >
            {`Showing results for "${searchInput}"`}
          </Flex>
          <PublisherCardWrapper
            style={{
              flexWrap: 'wrap',
              justifyContent: 'start',
              maxHeight: '300px',
              marginBottom: '30px',
            }}
          >
            {filteredPublishers ? (
              filteredPublishers.map((channel, index) => {
                if (channel.is_hidden === 'true') {
                  return;
                }
                return (
                  (channel?.subscription?.status?.application_status ===
                  'approved' || channel?.subscription?.status?.application_status ===
                  'applied') ? (
                    <Chip width="30%" height="80px" boxShadow="0px 1px 2px 0px #1018281F">
                    <StyledImgDiv style={{ width: '35%' }}>
                      <img
                        alt=""
                        src={getImages(channel)}
                        style={{ width: '100%' }}
                      />
                    </StyledImgDiv>
                    <StyledTitleDiv style={{ width: '50%' }}>
                      <StyledTitle
                        style={{
                          padding: '0px 0px 0px 10px',
                          fontSize: '14px',
                        }}
                      >
                        {channel?.name && channel?.name?.length > 10 ? (
                          <Tooltip
                            TransitionComponent={Zoom}
                            arrow
                            placement="top"
                            title={channel.name}
                            componentsProps={{
                              tooltip: {
                                sx: {
                                  fontFamily: 'Poppins',
                                },
                              },
                            }}
                          >
                            {`${channel?.name.substring(0, 10)}...`}
                          </Tooltip>
                        ) : (
                          channel?.name
                        )}
                      </StyledTitle>
                    </StyledTitleDiv>
                    <StyledCross
                      style={{
                        marginRight: '7px',
                        width: '15%',
                        justifyContent: 'center',
                        cursor: 'pointer',
                      }}
                      // onClick={() => {
                      //   console.log('add clicked');
                      //   if (isAdmin) {
                      //     handlePublisherSubscribeClick(channel.id, index);
                      //   } else {
                      //     postMyPublisherSubscribeChannelClick(
                      //       channel.id,
                      //       index
                      //     );
                      //   }
                      // }}
                    >
                      <img alt="" src="/assets/images/color-tick-icon.svg" />
                    </StyledCross>
                  </Chip>
                  ) : (
                    <Chip width="30%" height="80px" boxShadow="0px 1px 2px 0px #1018281F">
                    <StyledImgDiv style={{ width: '35%' }}>
                      <img
                        alt=""
                        src={getImages(channel)}
                        style={{ width: '100%' }}
                      />
                    </StyledImgDiv>
                    <StyledTitleDiv style={{ width: '50%' }}>
                      <StyledTitle
                        style={{
                          padding: '0px 0px 0px 10px',
                          fontSize: '14px',
                        }}
                      >
                        {channel?.name && channel?.name?.length > 10 ? (
                          <Tooltip
                            TransitionComponent={Zoom}
                            arrow
                            placement="top"
                            title={channel.name}
                            componentsProps={{
                              tooltip: {
                                sx: {
                                  fontFamily: 'Poppins',
                                },
                              },
                            }}
                          >
                            {`${channel?.name.substring(0, 10)}...`}
                          </Tooltip>
                        ) : (
                          channel?.name
                        )}
                      </StyledTitle>
                    </StyledTitleDiv>
                    <StyledCross
                      style={{
                        marginRight: '7px',
                        width: '15%',
                        justifyContent: 'center',
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        console.log('add clicked');
                        if (isAdmin) {
                          handlePublisherSubscribeClick(channel.id, index);
                        } else {
                          postMyPublisherSubscribeChannelClick(
                            channel.id,
                            index
                          );
                        }
                      }}
                    >
                      <img alt="" src="/assets/images/plus.svg" />
                    </StyledCross>
                  </Chip>
                  )
                
                );
              })
            ) : (
              <div>No Publishers Found</div>
            )}
          </PublisherCardWrapper>
        </Flex>
      ) : null}
    </div>
  );
};

export default PublisherSearch;
