import { Flex } from '@rebass/grid';
import React from 'react';
import { ColorPallete, Typography } from '../../../utils';
import grayEmail from '../../../assets/icons/grayEmail.svg';
import purpleEmail from '../../../assets/icons/purpleEmail.svg';
import graySocial from '../../../assets/icons/graySocial.svg';
import purpleSocial from '../../../assets/icons/purpleSocial.svg';
import MostEngagedCard from './MostEngagedCard';

const EmailAndSocialBox = ({
  emailAndSocialTab,
  handleToggle,
  campaignMostEngagedData,
  engagedDataError,
  loading,
  handleRetryClick,
  userDetails
}) => {
  return (
    <Flex
      width="100%"
      height="212px"
      flexDirection="column"
      padding="12px 20px 20px"
      style={{
        border: `1px solid ${ColorPallete.neutral200}`,
        borderRadius: '8px',
        boxShadow: '0px 1px 2px 0px #1018281F',
        position: 'relative',
      }}
    >
      <Flex width="100%" flexDirection="row" paddingBottom="15px">
        <Flex
          pr="16px"
          style={{ cursor: 'pointer' }}
          fontSize={Typography.TextLarge}
          color={
            emailAndSocialTab === 'email'
              ? ColorPallete.primaryPurple
              : ColorPallete.neutral400
          }
          onClick={() => handleToggle('email')}
        >
          <img
            alt="email"
            src={emailAndSocialTab === 'email' ? purpleEmail : grayEmail}
            style={{ margin: '0px 5px 0px 0px', alignItems: 'center' }}
          />
          Email
        </Flex>
        {/* <Flex
          pr="16px"
          style={{ cursor: 'pointer' }}
          fontSize={Typography.TextLarge}
          color={
            emailAndSocialTab === 'social'
              ? ColorPallete.primaryPurple
              : ColorPallete.neutral400
          }
          onClick={() => handleToggle('social')}
        >
          <img
            alt="email"
            src={emailAndSocialTab === 'social' ? purpleSocial : graySocial}
            style={{ margin: '0px 5px 0px 0px', alignItems: 'center' }}
          />
          Social
        </Flex> */}
      </Flex>
      <MostEngagedCard
        campaignMostEngagedData={campaignMostEngagedData}
        emailAndSocialTab={emailAndSocialTab}
        loading={loading}
        engagedDataError={engagedDataError}
        handleRetryClick={handleRetryClick}
        userDetails={userDetails}
      />
    </Flex>
  );
};

export default EmailAndSocialBox;
