import styled from 'styled-components';
import { Flex } from '@rebass/grid';

export const TopToolTipWrapper = styled.div.attrs({
  className: 'right-tooltip-wrapper',
})`
  position: relative;
  .tooltiptext {
    visibility: hidden;
    width: 120%;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 5px;
    position: absolute;
    z-index: 1;
    bottom: 110%;
    font-size: 12px;
    left: 0%;
  }

  .tooltiptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: black transparent transparent transparent;
  }
  &:hover .tooltiptext {
    visibility: visible;
  }
`;
