import React from 'react';

import withAppLayout from '../../hoc/withAppLayout';
import { BlockScreen } from '../../components';
import { ROLE_TYPES } from '../../utils';
import { PortfolioContainer } from '../../containers';

const Portfolio = ({ roleType }) => {
  if (roleType === ROLE_TYPES.ADVISOR_FREE) {
    return <BlockScreen screen="portfolio" />;
  }
  return <PortfolioContainer roleType={roleType}/>;
};

export default withAppLayout(Portfolio);
