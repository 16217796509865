import styled from 'styled-components';


export const OuterBox = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #EFF6F5;
  border-radius: 4px;
  padding: 10px 10px;
  font-family: Inter;
`;

export const FileUploadBox = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 300px;
  background-color: #EFF6F5;
  border: 1px dashed #758790;
  border-radius: 4px;
  padding: 4px;
  cursor: pointer;
  border: 1px dashed #75879;
`;

export const BrowseFiles = styled('label')`
  font-family: 'Poppins';
  display: flex;
  cursor: pointer;
  justify-content: space-between;
  margin: 0px 4px 0px 0px;
  color: #0B5CD7;
`;

export const UploadSubheader = styled('div')`
  font-family: 'Poppins';
  font-family: Inter;
  font-size: 12px;
  margin-top: -7px;
  color: #758790;
`;

export const UploadHeader = styled('p')`
  font-family: 'Poppins';
  font-size: 14px;
  color: #404B50;
  display: flex;
`;

export const TitleInput = styled('input')`
  font-family: 'Poppins';
  width: 100%;
  height: 40px;
  background: #FCFDFD;
  padding: 0 10px;
  border: 1px solid #E4E7EC;
  box-shadow: inset 1px 2px 2px rgba(16, 24, 40, 0.12);
  border-radius: 4px;
  color: #667085;
  margin: 0 0 4px;
`;

export const FileStyle = styled('h4')`
  font-size: 11px;
  font-weight: 400;
  color: #B6CFF3;
  text-align: left;
`;

export const InputTitle = styled('h4')`
  font-family: 'Poppins';
  font-weight: 400;
  font-size: 14px;
  color: #101828;
  text-align: left;
  margin: 0px 0 2px;
`;
