import articleMock from '../../../assets/icons/articleMock.svg';

export const contentGraphData = [
  {
    name: 'Topic Name',
    pv: 2400,
    fill: '#00B186',
  },
  {
    name: 'Topic Name',
    pv: 1398,
    fill: '#FFA32C',
  },
  {
    name: 'Topic Name',
    pv: 9800,
    fill: '#2196F3',
  },
  {
    name: 'Topic Name',
    pv: 3908,
    fill: '#FDD835',
  },
  {
    name: 'Topic Name',
    pv: 4800,
    fill: '#7B73E4',
  },
  {
    name: 'Topic Name',
    pv: 3800,
    fill: '#311B92',
  },
  {
    name: 'Topic Name',
    pv: 4300,
    fill: '#F21D6B',
  },
  {
    name: 'Topic Name',
    pv: 4300,
    fill: '#CE93D8',
  },
  {
    name: 'Others',
    pv: 4300,
    fill: '#19D6DB',
  },
];

export const TopicDataForMapping = [
  {
    id: 1,
    name: 'Retirement',
  },
  {
    id: 2,
    name: 'Technology',
  },
  {
    id: 3,
    name: 'Investment',
  },
];

export const EngagementTopicData = [
  {
    id: 1,
    name: 'PDF Content',
  },
];

export const contentEngagementData = [
  {
    name: 'My Content',
    pv: 2400,
    fill: '#00B186',
  },
  {
    name: 'Clout Content',
    pv: 2200,
    fill: '#FFA32C',
  },
  {
    name: 'Customized Content',
    pv: 1800,
    fill: '#2196F3',
  },
  {
    name: 'Approved to Share',
    pv: 1800,
    fill: '#FDD835',
  },
  {
    name: 'Landing Pages',
    pv: 1800,
    fill: '#7B73E4',
  },
  {
    name: 'Hyperlinks',
    pv: 1500,
    fill: '#27525E',
  },
];

export const TopicTableData = [
  {
    name: 'Topic Name',
    contacts: '123',
    content: '321',
    mom: '10.00%',
    qoq: '10.00%',
    engagement: 30,
  },
  {
    name: 'Topic Name',
    contacts: '123',
    content: '321',
    mom: '10.00%',
    qoq: '10.00%',
    engagement: 30,
  },
  {
    name: 'Topic Name',
    contacts: '123',
    content: '321',
    mom: '10.00%',
    qoq: '10.00%',
    engagement: 30,
  },
  {
    name: 'Topic Name',
    contacts: '123',
    content: '321',
    mom: '10.00%',
    qoq: '10.00%',
    engagement: 30,
  },
  {
    name: 'Topic Name',
    contacts: '123',
    content: '321',
    mom: '10.00%',
    qoq: '10.00%',
    engagement: 30,
  },
  {
    name: 'Topic Name',
    contacts: '123',
    content: '321',
    mom: '10.00%',
    qoq: '10.00%',
    engagement: 30,
  },
  {
    name: 'Topic Name',
    contacts: '123',
    content: '321',
    mom: '10.00%',
    qoq: '10.00%',
    engagement: 30,
  },
  {
    name: 'Topic Name',
    contacts: '123',
    content: '321',
    mom: '10.00%',
    qoq: '10.00%',
    engagement: 30,
  },
  {
    name: 'Topic Name',
    contacts: '123',
    content: '321',
    mom: '10.00%',
    qoq: '10.00%',
    engagement: 30,
  },
  {
    name: 'Topic Name',
    contacts: '123',
    content: '321',
    mom: '10.00%',
    qoq: '10.00%',
    engagement: 30,
  },
];

export const TopPerformingData = [
  {
    name: 'Article Title',
    subText:
      'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.',
    score: 30,
    image: articleMock,
  },
  {
    name: 'Article Title 1',
    subText:
      'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.',
    score: 30,
    image: articleMock,
  },
  {
    name: 'Article Title 2',
    subText:
      'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.',
    score: 30,
    image: articleMock,
  },
  {
    name: 'Article Title 3',
    subText:
      'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.',
    score: 30,
    image: articleMock,
  },
  {
    name: 'Article Title 4',
    subText:
      'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.',
    score: 30,
    image: articleMock,
  },
  {
    name: 'Article Title 5',
    subText:
      'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.',
    score: 30,
    image: articleMock,
  },
  {
    name: 'Article Title 6',
    subText:
      'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.',
    score: 30,
    image: articleMock,
  },
  {
    name: 'Article Title 7',
    subText:
      'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.',
    score: 30,
    image: articleMock,
  },
];
