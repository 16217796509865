import styled from 'styled-components';
import { colors } from '../../styles';

export const CardContainer = styled('div')`
    display: ${({ gridDisplay }) => !gridDisplay ? 'flex' : 'table'};
    width: ${({ width }) => width ? width : '100%'};
    min-width: ${({ minWidth }) => minWidth && minWidth};
    background: ${colors.white};
    border: ${({ border }) => !border ? `1px solid ${colors.gray_border}` : 0};
    box-shadow: ${({ border }) => !border ? '0px 1px 2px rgba(16, 24, 40, 0.12)' : 0};
    border-radius: 8px;
    cursor: ${({ border }) => !border && 'pointer'};
    margin: 20px 20px 0 0;
    transition: 0.5s;
    :hover {
        box-shadow: ${({ border }) => !border ? '0px 1px 2px rgba(16, 24, 40, 0.72)' : 0};
    }
`;

export const DefaultStyle = styled('div')`
    border-left: 1px solid ${colors.gray_border};
    padding-left: 5px;
    margin-left: 5px;
    display: flex;
    font-size: 11px;
    justify-content: space-between;
    color: ${colors.purple};
`;

export const HeaderGroup = styled('div')`
    display: flex;
    width: calc(100% - 40px);
    margin: 10px 20px 0px;
    padding: 10px 0 20px;
`;

export const IconActions = styled('div')`
    display: flex;
    width: 100%;
    justify-content: space-betwen;
    position: absolute;
    bottom: 0; 
    left: 10px;
`;

export const MappedIcons = styled('div')`
    display: flex;
    width: 85%;
    justify-content: start;
`;

export const EditStyle = styled('img')`
    height: 40px;
    padding: 6px 2px;
    margin: 0 10px 0 0;
    cursor: pointer;
    transition: 0.75s;
    border-radius: 4px;
    :hover {
        background: ${colors.gray_border};
    }
`;

export const IconStyle = styled('img')`
    height: 32px;
    width: 32px;
    padding: 6px;
    margin: 0 10px 0 0;
    cursor: pointer;
    transition: 0.75s;
    border-radius: 4px;
    :hover {
        background: ${colors.gray_border};
    }
`;

export const ArticleImage = styled('img')`
    height: 135px;
    width: 140px;
    margin: 0 10px 0 0;
    border-radius: 4px;
    object-fit: contain;
    overflow: hidden;
    border: 1px solid ${colors.gray_border};
`;

export const EngagementTitle = styled('div')`
    font-weight: 400;
    font-size: 14px;
    color: ${colors.gray_text};
    margin: 0;
    text-align: left;
`;

export const EngagementValue = styled('div')`
    font-weight: 500;
    font-size: 20px;
    color: ${colors.black_01};
    margin: 0;
    text-align: left;
`;

export const EngagementGroup = styled('div')`
    display: flex;
    width: 30%;
    margin: 10px 0;
`;

export const BodyText = styled('div')`
    font-weight: 400;
    font-size: 12px;
    color: ${colors.light_gray_text};
    text-align: left;
    margin: 0 0 2px;
`;

export const DarkText = styled('div')`
    font-weight: 500;
    font-size: 16px;
    color: ${colors.black_01};
    text-align: left;
    margin: 0px 0 10px 5px;
    word-break: break-all;
`;

export const BodyContainer = styled('div')`
    display: table; 
    width: 100%;
    padding: 0px 0 10px;
    position: relative;
    height: 135px;
`;

export const ButtonContainer = styled('div')`
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
  width: 100%;
`;

export const ModalText = styled('p')`
  font-size: 16px;
  font-weight: 400;
  margin: 10px 0;
  width: 90%;
  color: ${colors.black};
`;