import { Flex } from '@rebass/grid';
import React from 'react';
import { Button } from '../../components';
import { ColorPallete } from '../../utils';

const ErrorDisplay = ({ onClick, height }) => {
  return (
    <Flex
      width="100%"
      justifyContent="center"
      alignItems="center"
      margin="0px auto"
      height={height}
      flexDirection="column"
    >
      <Flex fontSize="18px" color="#101828" padding="5px">
        A Problem Occured
      </Flex>
      <Flex fontSize="14px" color="#475467" padding="5px">
        We couldn't fetch your data
      </Flex>
      <Flex>
        <Button
          width="102px"
          bgColor={ColorPallete.white}
          color={ColorPallete.neutral900}
          borderRadius="4px"
          margin="15px 0px 0px 0px"
          borderColor={ColorPallete.neutral200}
          styles={{
            display: 'flex',
            alignItems: 'center',
            boxShadow: '0px 1px 2px 0px #1018281F',
          }}
          onClick={onClick}
        >
          <img
            alt=""
            src="/assets/images/Analytics/redo-solid.svg"
            style={{ height: '18px', width: '18px', marginRight: '8px' }}
          />
          Retry
        </Button>
      </Flex>
    </Flex>
  );
};

export default ErrorDisplay;
