/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Flex } from '@rebass/grid';
import React, { useEffect } from 'react';
import StyledTooltip from '../../../components/Tooltip';

import { useState } from 'react';
import { AUTOMATED_CAMPAIGN_ACTIONS } from '../AutomatedCampaignsContainer';
import Modal from 'react-modal';
import ReactSelect from 'react-select';
import {
  Button,
  ErrorContainer,
  Input,
  Loader,
  ModalCloseButton,
  ModalHeader,
} from '../../../components';
import { useHistory } from 'react-router-dom';
import StepsFooter from './StepsFooter';
import { AutomatedCampaignFormWrapper } from '../styles';
import {
  getAutomatedCampaignBlocks,
  updateCampaignBlock,
  getAutomatedBlocksById,
  deleteCampaignBlock,
  automatedCampaignSendTestModal,
} from '../../../services/automated-campaigns';
import ActionBox from './ActionBox';
import { CustomizeCampaignWrapper, getSelectStyles } from '../styles';
import EmailBlockPreview from '../../DripCampaignContainerV2/DripDashboardTable/AutomatedCampaigns/EmailBlockPreview';
import { themesData } from '../../../utils';
import { LoaderWrapper } from '../../../components';
import { isEmpty } from 'lodash';
import SendTestMailModal from './SendTestMailModal';
import moment from 'moment';
import ConfirmationModal from '../../../components/ConfirmationModal/ConfirmationModal';
import PreviewModal from '../../DripCampaignContainerV2/DripDashboardTable/AutomatedCampaigns/EmailListPreviewModal';


const getAllowedWaitTimeUnits = () => [
  {
    label: 'prior',
    value: 'prior',
  },
  {
    label: 'after',
    value: 'after',
  },
];

const getAllowedWaitTimeDurations = () => [
  {
    label: 'days',
    value: 'days',
  },
  // {
  //   label: 'hours',
  //   value: 'hours',
  // },
  // {
  //   label: 'minutes',
  //   value: 'minutes',
  // },
];

const DELETE__ACTIONS = {
  WAIT_TIME: 'wait_time',
  EMAIL: 'email',
};

const CustomizeCampaign = ({
  width = '50%',
  height = '100%',
  campaignAction,
  campaignSetState,
  forDashboardPreview = false,
  currentStepIndex,
  setCurrentStepIndex,
  automatedCampaignId,
  automatedCampaignTargetInfo,
  automatedCampaignDetails,
  userData
  // campaignId,
  // setTemplateData,
  // setTemplateBase,
  // defaultTemplates,
  // selectedEmailCreationIndex
}) => {
  const history = useHistory();

  // Comp Utils
  const [openWaitTimeEditModal, setOpenWaitTimeEditModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openSendTestEmailModal, setOpenSendTestEmailModal] = useState(false);
  const [sendTestEmail, setSendTestEmail] = useState('');
  const [deleteActionType, setDeleteActionType] = useState(
    DELETE__ACTIONS.WAIT_TIME
  );
  const DISABLE_CUSTOMIZATION = forDashboardPreview;

  // Component States.
  const [fetchingCampaignBlocks, setFetchingCampaignBlocks] = useState(true);
  const [preConversionBlocks, setPreConversionBlocks] = useState([]);
  const [postConversionBlocks, setPostConversionBlocks] = useState([]);
  const [onConversionBlocks, setOnConversionBlocks] = useState([]);
  const [currentActiveBlock, setCurrentActiveBlock] = useState({});
  const [waitingTimeToBeEdited, setWaitingTimeToBeEdited] = useState({
    label: 0,
    value: 0,
  });
  const [waitingTimeUnit, setWaitingTimeUnit] = useState({
    label: 'prior',
    value: 'prior',
  });
  const [waitingTimeDuration, setWaitingTimeDuration] = useState({
    label: 'days',
    value: 'days',
  });
  const [showEmailPreview, setShowEmailPreview] = useState(false);
  const [requestLoading, setRequestLoading] = useState(false);
  const [testEmail, setTestEmail] = useState('');
  const [sendTestErrorMsg, setSendTestErrorMsg] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [eventFirstBlockDate, setEventFirstBlockDate] = useState('');
  const [openEventWarningModal, setOpenEventWarningModal] = useState(false);
  const [waitBlockErrorMessage, setWaitBlockErrorMessage] = useState('');
  const [isDatepickerOpen, setIsDatepickerOpen] = useState(false);
  const [showPreview, setShowPreview] = useState(false);

  const openEditModal = () => setOpenWaitTimeEditModal(true);
  const showDeleteModal = () => setOpenDeleteModal(true);
  const openSendTestModal = () => setOpenSendTestEmailModal(true);
  const closeEditModal = () => {
    setOpenWaitTimeEditModal(false);
    setWaitBlockErrorMessage('');
  }
  const closeDeleteModal = () => setOpenDeleteModal(false);
  const closeSendTestModal = () => setOpenSendTestEmailModal(false);

  const isUltimateItem = ({ blocks, index }) => index === blocks.length - 1;
  const isPenUltimateItem = ({ blocks, index }) => index === blocks.length - 2;

  const isPreConversion = currentActiveBlock.b_type === 'pre_conversion';

  // duration: days/minutes/hours
  const getAllowedWaitTimes = () => {
    let ARRAY_SIZE_TO_RETURN = 91;
    if (waitingTimeDuration.value === 'hours') ARRAY_SIZE_TO_RETURN = 25;
    if (waitingTimeDuration.value === 'minutes') ARRAY_SIZE_TO_RETURN = 61;

    return Array(ARRAY_SIZE_TO_RETURN)
      .fill('')
      .map((num, i) => ({
        label: i,
        value: i,
      }));
  };

  const getTooltipText = () => {
    if (campaignAction === AUTOMATED_CAMPAIGN_ACTIONS.NURTURE_PROSPECTS)
      return 'At any time, if a prospect fills out your lead form, they will exit the main series above and will receive the following emails.';
    if (campaignAction === AUTOMATED_CAMPAIGN_ACTIONS.NEWSLETTER)
      return 'At any time, if a contact unsubscribes from your newsletter, they will stop receiving any future newsletters.';
    if (campaignAction === AUTOMATED_CAMPAIGN_ACTIONS.EVENT)
      return 'Emails created below will only be sent to registered contacts';  
    return 'At any time, if a contact registers for the event, they will exit the main series above and will receive the following emails.';
  };

  const getBlockByIndex = (indexToReturn) => {
    if (!isPreConversion)
      return postConversionBlocks.find(
        (block, index) => index === indexToReturn
      );
    return preConversionBlocks.find((block, index) => index === indexToReturn);
  };

  const getDeleteModalConfig = () => {
    const blocksToDeleteFrom = isPreConversion
      ? [...preConversionBlocks]
      : [...postConversionBlocks];

    const active_block_index = blocksToDeleteFrom.findIndex(
      (block) => currentActiveBlock === block
    );

    if (deleteActionType === DELETE__ACTIONS.WAIT_TIME) {
      const correspondingEmailBlock = getBlockByIndex(active_block_index + 1);

      const requestBody = [currentActiveBlock?.id, correspondingEmailBlock?.id];

      return {
        title: 'wait block',
        action: () => deleteBlockFromDB({ requestBody }),
      };
    }
    if (deleteActionType === DELETE__ACTIONS.EMAIL) {
      let requestBody;
      if (
        active_block_index === 0 &&
        isPreConversion &&
        (currentActiveBlock.b_action !== 'wait_till_date' ||
          currentActiveBlock.b_action !== 'wait_for_duration')
      ) {
        requestBody = [currentActiveBlock?.id];
      } else {
        const correspondingWaitTimeBlock = getBlockByIndex(
          active_block_index - 1
        );

        requestBody = [currentActiveBlock?.id, correspondingWaitTimeBlock?.id];
      }

      return {
        title: 'email',
        action: () => deleteBlockFromDB({ requestBody }),
      };
    }
  };

  const getPreConversionBlocks = (blocks = []) =>
    blocks
      .sort((b, a) => b.b_index - a.b_index)
      .filter((block) => block.b_type === 'pre_conversion');
  const getPostConversionBlocks = (blocks = []) =>
    blocks
      .sort((b, a) => b.b_index - a.b_index)
      .filter((block) => block.b_type === 'post_conversion');
  const getOnConversionBlocks = (blocks = []) =>
    blocks
      .sort((b, a) => b.b_index - a.b_index)
      .filter((block) => block.b_type === 'on_conversion');

  const getNewOptionValue = (value) => (value ? value : { label: 0, value: 0 });

  const getEditWaitTimeActions = ({ block, disableDelete }) => {
    const hide_delete = disableDelete || false;
    const edit_wait_time_actions = [
      {
        title: 'Edit',
        onClick: () => {
          setCurrentActiveBlock(block);
          // Ex: block.b_details = "28 days prior" || "wait 7 days"

          const wait__time__chunks = block.b_details.split(' ');
          if (block.b_action === 'wait_till_date') {
            setWaitingTimeToBeEdited({
              label: parseInt(wait__time__chunks[0]),
              value: parseInt(wait__time__chunks[0]),
            });
            setWaitingTimeUnit({
              label: wait__time__chunks[2],
              value: wait__time__chunks[2],
            });
            setWaitingTimeDuration({
              label: wait__time__chunks[1],
              value: wait__time__chunks[1],
            });
            openEditModal();
            return;
          }

          if (block.b_action === 'wait_for_duration') {
            setWaitingTimeToBeEdited({
              label: wait__time__chunks[1],
              value: wait__time__chunks[1],
            });

            setWaitingTimeDuration({
              label: wait__time__chunks[2],
              value: wait__time__chunks[2],
            });
            openEditModal();
            return;
          }
        },
      },

      {
        title: 'Delete',
        onClick: () => {
          setCurrentActiveBlock(block);
          setDeleteActionType(DELETE__ACTIONS.WAIT_TIME);
          showDeleteModal();
        },
      },
    ];

    return !hide_delete
      ? edit_wait_time_actions
      : edit_wait_time_actions.filter((action) => action.title !== 'Delete');
  };

  const getSendCampaignActions = ({ disableDelete, block }) => {
    console.log('block-', block)
    const hide_delete = disableDelete || false;

    const sendCampaignActions = [
      {
        title: 'Create Email',
        onClick: async (blockObj) => {
          console.log('ID ON CLICK - --  ', blockObj);
          const { campaignId } = automatedCampaignDetails;
          try {
            const blockData = await getAutomatedBlocksById(
              campaignId,
              blockObj.id
            );
            campaignSetState({
              fromCreateEmail: true,
              selectedBlockId: blockData.data.id,
              selectedBlock: {
                name: blockData && blockData.data && blockData.data.name,
                purpose: blockData && blockData.data && blockData.data.purpose,
                subject: blockData && blockData.data && blockData.data.subject,
                template_id:
                  blockData && blockData.data && blockData.data.template_id,
                template_html:
                  blockData && blockData.data && blockData.data.template_html,
                template_json:
                  blockData && blockData.data && blockData.data.template_json,
                block_type:
                  blockData && blockData.data && blockData.data.b_type,
              },
            });
          } catch (error) {
            console.log(error);
          }

          history.push('/create-email');
        },
      },
      {
        title: 'Preview',
        onClick: (blockObj) => {
          setCurrentActiveBlock(blockObj);
          setShowEmailPreview(true);
        },
      },
     

      {
        title: 'Delete',
        onClick: (block) => {
          setCurrentActiveBlock(block);
          setDeleteActionType(DELETE__ACTIONS.EMAIL);
          showDeleteModal();
        },
      },
    ];

    if (block.newsletter_id) {
      sendCampaignActions.push({
        title: 'Send Test',
        onClick: (block) => {
          console.log('block-', block);
          setCurrentActiveBlock(block);
          openSendTestModal();
        },
      })
    }

    return !hide_delete
      ? sendCampaignActions
      : sendCampaignActions.filter((action) => action.title !== 'Delete');
  };

  const getCampaignBlocksByCampaignId = async () => {
    try {
      const { campaignId } = automatedCampaignDetails;
      const { data } = (await getAutomatedCampaignBlocks(campaignId)) || {};
      setPreConversionBlocks(getPreConversionBlocks(data));
      setPostConversionBlocks(getPostConversionBlocks(data));
      setOnConversionBlocks(getOnConversionBlocks(data));
      campaignSetState({
        automatedCampaigns: {
          ...automatedCampaignDetails,
          notificationBlocks: data,
          activeStepIndex: 1,
        },
      });
    } catch (error) {
      console.log('Error Fetching Notification blocks ', error);
    } finally {
      setFetchingCampaignBlocks(false);
    }
  };

  const deleteBlockFromDB = async ({ requestBody }) => {
    setRequestLoading(true);
    try {
      const { status } = await deleteCampaignBlock({
        block_ids: requestBody, // [blockId]
        campaignId: currentActiveBlock.ac_id,
      });

      if (status === 200) {
        // Fetch all the campaigns by campaign Id.
        await getCampaignBlocksByCampaignId();
        setRequestLoading(false);
        setOpenDeleteModal(false);
      }
    } catch (error) {
      setRequestLoading(false);
      console.error('Error deleting selected block from DB ', error);
    }
  };

  const updateWaitTimeInDB = async () => {
    try {
      let b_details = '';
      if(campaignAction === AUTOMATED_CAMPAIGN_ACTIONS.EVENT) {
        const currentBlockIndex = currentActiveBlock.b_index;
        const previousBlockIndex = currentBlockIndex - 2;
        const nextBlockIndex = currentBlockIndex + 2;
        // Other Pre Conversion Except First Block
        if(currentActiveBlock.b_type === 'pre_conversion' && currentActiveBlock.b_index !== preConversionBlocks[0].b_index) {
          const previousBlock = preConversionBlocks.find(p => p.b_index === previousBlockIndex);
          const nextBlock = preConversionBlocks.find(p => p.b_index === nextBlockIndex);
          if(previousBlock && currentActiveBlock.b_details) {
            if(nextBlock) {
              if((waitingTimeToBeEdited.value >= +previousBlock.b_details.split(' ')[0]) || (waitingTimeToBeEdited.value <= +nextBlock.b_details.split(' ')[0])) {
                setWaitBlockErrorMessage(`Please set duration for less than ${previousBlock.b_details} and more than ${nextBlock.b_details}`);
                return;
              }
            } else {
              if(waitingTimeToBeEdited.value >= +previousBlock.b_details.split(' ')[0]) {
                setWaitBlockErrorMessage(`Please set duration for less than ${previousBlock.b_details}`);
                return;
              }
            }
          }
          // Other Post Conversion Except First block
        } else if(currentActiveBlock.b_type === 'post_conversion' && currentActiveBlock.b_index !== postConversionBlocks[0].b_index) {
          const previousBlock = postConversionBlocks.find(p => p.b_index === previousBlockIndex);
          const nextBlock = postConversionBlocks.find(p => p.b_index === nextBlockIndex);
          if(previousBlock && currentActiveBlock.b_details) {
            if(nextBlock) {
              if(waitingTimeUnit.value === 'after' && previousBlock.b_details.split(' ')[2] === 'after' && nextBlock.b_details.split(' ')[2] === 'after') {
                if((waitingTimeToBeEdited.value <= +previousBlock.b_details.split(' ')[0]) || (waitingTimeToBeEdited.value >= +nextBlock.b_details.split(' ')[0])) {
                  setWaitBlockErrorMessage(`Please set duration for more than ${previousBlock.b_details} and less than ${nextBlock.b_details}`);
                  return;
                } 
              } else if(waitingTimeUnit.value === 'prior' && previousBlock.b_details.split(' ')[2] === 'prior' && nextBlock.b_details.split(' ')[2] === 'prior'){
                if((waitingTimeToBeEdited.value >= +previousBlock.b_details.split(' ')[0]) || (waitingTimeToBeEdited.value <= +nextBlock.b_details.split(' ')[0])) {
                  setWaitBlockErrorMessage(`Please set duration for less than ${previousBlock.b_details} and more than ${nextBlock.b_details}`);
                  return;
                } 
              } else if(waitingTimeUnit.value === 'after' && previousBlock.b_details.split(' ')[2] === 'prior' && nextBlock.b_details.split(' ')[2] === 'after') {
                if((waitingTimeToBeEdited.value >= +previousBlock.b_details.split(' ')[0]) || (waitingTimeToBeEdited.value >= +nextBlock.b_details.split(' ')[0])) {
                  setWaitBlockErrorMessage(`Please set duration for less than ${previousBlock.b_details} and less than ${nextBlock.b_details}`);
                  return;
                } 
              } else if(waitingTimeUnit.value === 'prior' && previousBlock.b_details.split(' ')[2] === 'prior' && nextBlock.b_details.split(' ')[2] === 'after') {
                if((waitingTimeToBeEdited.value >= +previousBlock.b_details.split(' ')[0]) || (waitingTimeToBeEdited.value >= +nextBlock.b_details.split(' ')[0])) {
                  setWaitBlockErrorMessage(`Please set duration for less than ${previousBlock.b_details} and less than ${nextBlock.b_details}`);
                  return;
                } 
              } else if(waitingTimeUnit.value === 'after' && previousBlock.b_details.split(' ')[2] === 'after' && nextBlock.b_details.split(' ')[2] === 'prior') {
                if((waitingTimeToBeEdited.value <= +previousBlock.b_details.split(' ')[0]) || (waitingTimeToBeEdited.value >= +nextBlock.b_details.split(' ')[0])) {
                  setWaitBlockErrorMessage(`Please set duration for more than ${previousBlock.b_details} and less than ${nextBlock.b_details}`);
                  return;
                } 
              }
            } else {
              if(waitingTimeUnit.value === 'after') {
                if(waitingTimeToBeEdited.value <= +previousBlock.b_details.split(' ')[0]) {
                  setWaitBlockErrorMessage(`Please set duration for more than ${previousBlock.b_details}`);
                  return;
                }
              } else if(waitingTimeUnit.value === 'prior') {
                if(waitingTimeToBeEdited.value >= +previousBlock.b_details.split(' ')[0]) {
                  setWaitBlockErrorMessage(`Please set duration for less than ${previousBlock.b_details}`);
                  return;
                }
              }
            }
          }
          // First Pre Conversion
        } else if(currentActiveBlock.b_type === 'pre_conversion' && currentActiveBlock.b_index === preConversionBlocks[0].b_index) {
          const nextBlock = preConversionBlocks.find(p => p.b_index === nextBlockIndex);
          if(nextBlock && currentActiveBlock.b_details) {
            if(waitingTimeToBeEdited.value <= +nextBlock.b_details.split(' ')[0]) {
              setWaitBlockErrorMessage(`Please set duration for more than ${nextBlock.b_details}`);
              return;
            }
          }
          // First Post Conversion
        } else if(currentActiveBlock.b_type === 'post_conversion' && currentActiveBlock.b_index === postConversionBlocks[0].b_index) {
          const nextBlock = postConversionBlocks.find(p => p.b_index === nextBlockIndex);
          if(nextBlock && currentActiveBlock.b_details) {
            if(waitingTimeUnit.value === 'after') {
              if(waitingTimeToBeEdited.value >= +nextBlock.b_details.split(' ')[0]) {
                setWaitBlockErrorMessage(`Please set duration for less than ${nextBlock.b_details}`);
                return;
              }
            } else if(waitingTimeUnit.value === 'prior') {
              if(waitingTimeToBeEdited.value <= +nextBlock.b_details.split(' ')[0]) {
                setWaitBlockErrorMessage(`Please set duration for more than ${nextBlock.b_details}`);
                return;
              }
            }
          }
        }
      }
      if (currentActiveBlock.b_action === 'wait_till_date') {
        b_details = `${waitingTimeToBeEdited.value} ${waitingTimeDuration.value} ${waitingTimeUnit.value}`;
      }
      if (currentActiveBlock.b_action === 'wait_for_duration') {
        b_details = `wait ${waitingTimeToBeEdited.value} ${waitingTimeDuration.value}`;
      }

      const waitTimeResponse = await updateCampaignBlock({
        campaignId: automatedCampaignDetails.campaignId,
        blockId: currentActiveBlock.id,
        payload: {
          b_details,
        },
      });
      if (waitTimeResponse) {
        // Refetch all the updated blocks.
        getCampaignBlocksByCampaignId();
        setOpenWaitTimeEditModal(false);
        setWaitBlockErrorMessage('');
      }
    } catch (error) {
      console.error('Error updating wait time in DB ', error);
    }
  };

  const changeEventBlockTime = (date, block) => {
    console.log("date and block-", date, block);
    setIsLoading(true);
    let b_details;
    const { targetInfo, campaignId } = automatedCampaignDetails;
    let eventDate = new Date(targetInfo.event_date);
    eventDate = new Date(eventDate.getFullYear(), eventDate.getMonth(), eventDate.getDate());
    let scheduledDate = new Date(date);
    scheduledDate = new Date(scheduledDate.getFullYear(), scheduledDate.getMonth(), scheduledDate.getDate());

    console.log(eventDate, scheduledDate);
    if(scheduledDate.getTime() > eventDate.getTime()) {
      let diffTime = Math.abs(scheduledDate - eventDate);
      let diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      b_details = diffDays > 1 ? `${diffDays} days after` : `${diffDays} day after`;
    } else if(scheduledDate.getTime() < eventDate.getTime()) {
      let diffTime = Math.abs(eventDate - scheduledDate);
      let diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      console.log(diffDays + " days");
      b_details = diffDays > 1 ? `${diffDays} days prior` : `${diffDays} day prior`;
    } else {
      b_details = '0 day after';
    }

    updateCampaignBlock({
      campaignId: campaignId,
      blockId: block.id,
      payload: {
        b_details,
        schedule_time: date
      },
    }).then(res => {
      getCampaignBlocksByCampaignId();
      setIsLoading(false);
      setIsDatepickerOpen(false);
    }).catch(err => {
      console.log(err);
      setIsLoading(false);
      setIsDatepickerOpen(false);
    });
  }

  const handleValidation = () => {
    let isNotValid = false;
    const blocks = [
      ...preConversionBlocks,
      ...postConversionBlocks,
      ...onConversionBlocks,
    ];
    const sendCampaignBlocks = blocks.filter(
      (b) => b.b_action === 'send_campaign'
    );

    sendCampaignBlocks.forEach((item) => {
      if (!item.newsletter_id) {
        isNotValid = true;
      }
    });

    return isNotValid;
  };

  const handleSendClick = () => {
    console.log('clicked');
    // TO DO HANDLE EMAIL VALIDATION AND COUNT VALIDATION
    setSendTestErrorMsg('');
    let msg = 'Email is not valid';
    const isEmailRegEx =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,3}))$/;
    let valid = true;
    if (valid && !isEmpty(sendTestEmail)) {
      valid = sendTestEmail
        .split(',')
        .map((em) => em.trim())
        .every((em) => isEmailRegEx.test(em.toLowerCase()));
      if (sendTestEmail.split(',').length > 5 && valid) {
        valid = false;
        msg = 'Maximum 5 emails can be added';
      }
    }
    if (!valid) {
      setSendTestErrorMsg(msg);
      return;
    };
    setRequestLoading(true);
    const payload = {
      block_id: currentActiveBlock.id,
      email_ids: sendTestEmail
    }
    automatedCampaignSendTestModal(payload)
      .then((res) => {
        console.log('send')
        if (res?.result?.success) {
          setRequestLoading(false);
          setSendTestErrorMsg(
            'Sent Successfully'
          );
          setTimeout(() => {
            setSendTestErrorMsg('');
            setOpenSendTestEmailModal(false);
          }, 3000);
        } else {
          setRequestLoading(false);
          setSendTestErrorMsg(
            res?.result?.success
          );
        }
        setSendTestEmail('');
      })
      .catch((error) => {
        console.log(error)
        setRequestLoading(false);

      });
    setSendTestErrorMsg();
  };

  const handleSendTestValidation = () => {
    if (!sendTestEmail) {
      return true;
    }
  };

  console.log('showPreview', showPreview);

  const handleNextClick = () => {
    if(!isEmpty(preConversionBlocks) && campaignAction === AUTOMATED_CAMPAIGN_ACTIONS.EVENT) {
      console.log('check 1')
      const { targetInfo } = automatedCampaignDetails;
      let satisfied = true; 
      const firstWaitBlock = preConversionBlocks.filter(p => p.b_action === "wait_till_date");
      console.log(firstWaitBlock);
      if(!isEmpty(firstWaitBlock)) {
        let firstBlockDays = +firstWaitBlock[0].b_details.split(' ')[0];
        let firstDate = new Date();
        firstDate.setDate(firstDate.getDate() - firstBlockDays);
        firstDate.setHours(0,0,0,0);
        console.log(firstDate);
        let eventDate = new Date(targetInfo.event_date);
        eventDate = new Date(eventDate.getTime() + Math.abs(eventDate.getTimezoneOffset()*60000));
        eventDate.setHours(0,0,0,0);
        console.log(eventDate);
        if(eventDate.getTime() >= firstDate.getTime()) {
          satisfied = true;
        } else {
          satisfied = false;
        }
      }
      console.log(satisfied);
      console.log(postConversionBlocks.filter(p => p.b_details.includes('prior')));
      if(!isEmpty(postConversionBlocks) && !isEmpty(postConversionBlocks.filter(p => p.b_details.includes('prior')))) {
        const postPriorBlocks = postConversionBlocks.filter(p => p.b_details.includes('prior'));
        let firstDate = new Date();
        firstDate.setHours(0,0,0,0);
        let eventDate = new Date(targetInfo.event_date);
        eventDate = new Date(eventDate.getTime() + Math.abs(eventDate.getTimezoneOffset()*60000));
        eventDate.setHours(0,0,0,0);
        console.log(firstDate);
        console.log(eventDate);
        postPriorBlocks.forEach(ele => {
          let blockDays = +ele.b_details.split(' ')[0];
          if(satisfied && eventDate.getTime() >= new Date(firstDate.setDate(firstDate.getDate() - blockDays)).getTime()) {
            satisfied = true;
          } else {
            satisfied = false;
          }
        })
        
      }
      console.log('satisfied', satisfied)
      if(!satisfied) {
        setOpenEventWarningModal(true);
      } else {
        setShowPreview(true);
        // setCurrentStepIndex(currentStepIndex + 1);
      }
    } else {
      console.log('check 2')
      setCurrentStepIndex(currentStepIndex + 1)
    }
  }


  // Hooks
  useEffect(() => {
    getCampaignBlocksByCampaignId();
  }, []);

  useEffect(() => {
   
  }, [preConversionBlocks])

  return !fetchingCampaignBlocks ? (
    <>
      <AutomatedCampaignFormWrapper forDashboardPreview={forDashboardPreview}>
        <CustomizeCampaignWrapper
          width={width}
          height={height}
          forDashboardPreview={forDashboardPreview}
        >
          <Box className="email_series__wrapper">
            {/* <Box className="vertical__line"></Box> */}
            <Flex
              width={1}
              alignItems={'center'}
              justifyContent="center"
              marginBottom={'20px'}
            >
              <span>{campaignAction === AUTOMATED_CAMPAIGN_ACTIONS.EVENT ? 'Create Event Promotional Emails' : 'Contact Enters Campaign Series'}</span>
              {!forDashboardPreview && (
                <StyledTooltip
                  text={campaignAction === AUTOMATED_CAMPAIGN_ACTIONS.EVENT ? 'Promotional emails will be sent only to non-registered contacts' : `This campaign will be sent to the selected segment. Any time a contact
     is added to that segment, they will start this automated campaign.`}
                />
              )}
            </Flex>

            {React.Children.toArray(
              preConversionBlocks.map((block, index) => {
                return (
                  <>
                    {(block.b_action === 'wait_till_date' ||
                      block.b_action === 'wait_for_duration') && (
                        <ActionBox
                          styles={{
                            width: '200px',
                          }}
                          mainTitle={block.b_details}
                          actions={getEditWaitTimeActions({
                            block,
                            disableDelete:
                              !isPenUltimateItem({
                                blocks: preConversionBlocks,
                                index,
                              }) ||
                              preConversionBlocks.length === 1 ||
                              preConversionBlocks.length === 2,
                          })}
                          disableCutomization={DISABLE_CUSTOMIZATION}
                          forWaitTimeEdit={true}
                          index={index}
                          preConversionBlock={true}
                          eventDate={automatedCampaignDetails.targetInfo?.event_date}
                          userData={userData}
                          blockObj={block}
                          changeEventBlockTime={changeEventBlockTime}
                          isLoading={isLoading}
                          isDatepickerOpen={isDatepickerOpen}
                          setIsDatepickerOpen={setIsDatepickerOpen}
                        />
                      )}
                    {block.b_action === 'send_campaign' && (
                      <ActionBox
                        styles={{
                          width: '100%',
                        }}
                        mainTitle={
                          block.name
                            ? block.name
                            : `Empty – Please Create Email`
                        }
                        actions={getSendCampaignActions({
                          disableDelete:
                            !isUltimateItem({
                              blocks: preConversionBlocks,
                              index,
                            }) ||
                            preConversionBlocks.length === 1 ||
                            preConversionBlocks.length === 2,
                            block
                        })}
                        blockObj={block}
                        disableCutomization={DISABLE_CUSTOMIZATION}
                      />
                    )}
                  </>
                );
              })
            )}

            <Flex width={1} alignItems={'center'} justifyContent="center">
              <span>
                {campaignAction === AUTOMATED_CAMPAIGN_ACTIONS.EVENT
                  ? 'EVENT START'
                  : 'Contact Exits Campaign Series'}
              </span>
            </Flex>
          </Box>

          {(postConversionBlocks.length > 0 ||
            onConversionBlocks.length > 0) && (
            <>
              <hr className="line__breaker" />

              <Box className="post_register_actions__holder">
                <Flex width={1} alignItems={'center'} justifyContent="center" pb="10px">
                  <span>
                    {campaignAction === AUTOMATED_CAMPAIGN_ACTIONS.NEWSLETTER
                      ? 'Action: Contact unsubscribes from newsletters'
                      : campaignAction === AUTOMATED_CAMPAIGN_ACTIONS.EVENT
                      ? 'Create Emails for Registered Contacts'
                      : 'Action: Contact fills out lead form'}
                  </span>
                  {!forDashboardPreview && (
                    <StyledTooltip text={getTooltipText()} />
                  )}
                </Flex>

                {/*  Show On Conversion blocks if they exist*/}
                {React.Children.toArray(
                  onConversionBlocks.map((block) => (
                    <ActionBox
                      styles={{
                        width: '100%',
                      }}
                      mainTitle={
                        block.name ? block.name : `Empty – Please Create Email`
                      }
                      actions={getSendCampaignActions({
                        disableDelete: true,
                        block
                      })}
                      blockObj={block}
                      disableCutomization={DISABLE_CUSTOMIZATION}
                      onConversionBlock={true}
                      forDashboardPreview={forDashboardPreview}
                    />
                  ))
                )}

                {React.Children.toArray(
                  postConversionBlocks.map((block, index) => {
                    const hide_delete =
                      postConversionBlocks.length === 1 ||
                      postConversionBlocks.length === 2;
                    return (
                      <>
                        {(block.b_action === 'wait_till_date' ||
                          block.b_action === 'wait_for_duration') && (
                          <ActionBox
                            styles={{
                              width: '200px',
                            }}
                            mainTitle={block.b_details}
                            actions={getEditWaitTimeActions({
                              block,
                              disableDelete:
                                !isPenUltimateItem({
                                  blocks: postConversionBlocks,
                                  index,
                                }) || hide_delete,
                            })}
                            disableCutomization={DISABLE_CUSTOMIZATION}
                            forWaitTimeEdit={true}
                            index={index}
                            postConversionBlock={true}
                            blockObj={block}
                            postConversionBlocks={postConversionBlocks}
                            eventDate={automatedCampaignDetails.targetInfo?.event_date}
                            changeEventBlockTime={changeEventBlockTime}
                            isLoading={isLoading}
                            isDatepickerOpen={isDatepickerOpen}
                            setIsDatepickerOpen={setIsDatepickerOpen}
                            userData={userData}
                          />
                        )}
                        {block.b_action === 'send_campaign' && (
                          <ActionBox
                            styles={{
                              width: '100%',
                            }}
                            mainTitle={
                              block.name
                                ? block.name
                                : `Empty – Please Create Email`
                            }
                            actions={getSendCampaignActions({
                              disableDelete:
                                !isUltimateItem({
                                  blocks: postConversionBlocks,
                                  index,
                                }) || hide_delete,
                                block
                            })}
                            blockObj={block}
                            disableCutomization={DISABLE_CUSTOMIZATION}
                          />
                        )}
                      </>
                    );
                  })
                )}
              </Box>
            </>
          )}

          {/* Modals */}
          <Modal
            isOpen={openWaitTimeEditModal}
            onRequestClose={() => closeEditModal()}
            style={{
              content: {
                height: '260px',
                overflow: 'unset',
                width: 'max-content',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
              },
            }}
            ariaHideApp={false}
          >
            <ModalHeader style={{ fontSize: '15px' }}>
              <p>Select when to send the next Email</p>
              <ModalCloseButton onClick={closeEditModal} />
            </ModalHeader>

            {currentActiveBlock && (
              <>
                {/* Ex: 28 days proir */}
                {currentActiveBlock.b_action === 'wait_till_date' && (
                  <Flex alignItems={'center'} style={{ margin: '10px 0 20px' }}>
                    <span>Send </span>

                    {/* Days count */}
                    <div>
                      <ReactSelect
                        onChange={(newValue) =>
                          setWaitingTimeToBeEdited(getNewOptionValue(newValue))
                        }
                        options={getAllowedWaitTimes()}
                        placeholder="days"
                        styles={getSelectStyles()}
                        value={waitingTimeToBeEdited}
                      />
                    </div>

                    {/* days, hours, minutes */}
                    <div>
                      <ReactSelect
                        onChange={(newValue) =>
                          setWaitingTimeDuration(getNewOptionValue(newValue))
                        }
                        options={getAllowedWaitTimeDurations()}
                        styles={getSelectStyles()}
                        value={waitingTimeDuration}
                      />
                    </div>

                    {/* prior, after */}
                    <div>
                      <ReactSelect
                        onChange={(newValue) =>
                          setWaitingTimeUnit(getNewOptionValue(newValue))
                        }
                        options={getAllowedWaitTimeUnits()}
                        styles={getSelectStyles()}
                        value={waitingTimeUnit}
                      />
                    </div>
                    <span> to the event</span>
                  </Flex>
                )}

                {/* Ex: Wait 7 days */}
                {currentActiveBlock.b_action === 'wait_for_duration' && (
                  <Flex alignItems={'center'} style={{ margin: '10px 0 20px' }}>
                    <span>Wait </span>
                    <div>
                      <ReactSelect
                        onChange={(newValue) =>
                          setWaitingTimeToBeEdited(getNewOptionValue(newValue))
                        }
                        options={getAllowedWaitTimes()}
                        styles={getSelectStyles()}
                        value={waitingTimeToBeEdited}
                      />
                    </div>
                    <div>
                      <ReactSelect
                        onChange={(newValue) =>
                          setWaitingTimeDuration(getNewOptionValue(newValue))
                        }
                        options={getAllowedWaitTimeDurations()}
                        styles={getSelectStyles()}
                        value={waitingTimeDuration}
                      />
                    </div>
                  </Flex>
                )}
              </>
            )}

            {waitBlockErrorMessage && campaignAction === AUTOMATED_CAMPAIGN_ACTIONS.EVENT && (
              <Flex fontSize="14px" justifyContent="center">
                {waitBlockErrorMessage}
              </Flex>
            )}

            <Flex alignItems={'center'} justifyContent="center">
              <Button
                borderRadius="4px"
                onClick={() => closeEditModal()}
                width="80px"
                padding="5px 10px"
                margin="20px 10px 0px 0px"
                height="40px"
              >
                Cancel
              </Button>
              <Button
                borderRadius="4px"
                onClick={() => updateWaitTimeInDB()}
                width="80px"
                padding="5px 10px"
                margin="20px 10px 0px 0px"
                height="40px"
              >
                Save
              </Button>
            </Flex>
          </Modal>

          {/* DELETE WAITING BLOCK modal */}
          <Modal
            isOpen={openDeleteModal}
            onRequestClose={() => closeDeleteModal()}
            style={{
              content: {
                height: '175px',
                padding: '15px',
                width: 'max-content',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
              },
            }}
            ariaHideApp={false}
          >
            <ModalHeader style={{ fontSize: '15px', marginTop: '15px' }}>
              <p style={{ margin: 0 }}>
                Are you sure you want to delete this{' '}
                {getDeleteModalConfig().title}?
              </p>
              <ModalCloseButton onClick={closeDeleteModal} />
            </ModalHeader>

            {requestLoading ? (
              <Box style={{ marginTop: '35px' }}>
                <Loader />
              </Box>
            ) : (
              <Flex alignItems={'center'} justifyContent="center">
                <Button
                  borderRadius="4px"
                  onClick={() => getDeleteModalConfig().action()}
                  width="80px"
                  padding="5px 10px"
                  margin="20px 10px 0px 0px"
                  height="40px"
                >
                  Yes
                </Button>
                <Button
                  borderRadius="4px"
                  onClick={() => closeDeleteModal()}
                  width="80px"
                  padding="5px 10px"
                  margin="20px 10px 0px 0px"
                  height="40px"
                >
                  No
                </Button>
              </Flex>
            )}
          </Modal>

          {/* OPEN SEND TEST EMAIL modal */}
          {openSendTestEmailModal && (
            <SendTestMailModal
              handleModalClose={closeSendTestModal}
              isOpen={openSendTestEmailModal}
              subject={currentActiveBlock.subject}
              handleChange={(e) => setSendTestEmail(e.target.value)}
              value={sendTestEmail}
              modalButtonOnClick={handleSendClick}
              validation={handleSendTestValidation()}
              sendTestErrorMsg={sendTestErrorMsg}
              loading={requestLoading}
            />
          )}
        

          {showEmailPreview && (
            <EmailBlockPreview
              isOpen={showEmailPreview}
              handleClose={() => setShowEmailPreview(false)}
              block_id={currentActiveBlock.id}
              campaign_id={currentActiveBlock.ac_id}
            />
          )}
          {openEventWarningModal && (
            <ConfirmationModal 
              modalTitle={<span style={{ wordBreak: 'break-word', padding: '10px 30px'}}>{`Current Event Date is on ${moment(automatedCampaignDetails.targetInfo.event_date).format('MMM Do')}.  Please select a wait block date that hasn’t passed.`}</span>}
              modalWidth="55%"
              noButton
              clickHandler={() => {setCurrentStepIndex(currentStepIndex - 1)}}
              okBtnText="Change Event Date"
              onlyShowOk
              btnMargin="0px"
              closeHandler={() => setOpenEventWarningModal(false)} 
              mt="35px"
            />
          )}
        </CustomizeCampaignWrapper>
      </AutomatedCampaignFormWrapper>

      {/* Hide in the preview from Dashboard */}
      {!forDashboardPreview && (
        <StepsFooter
          currentStepIndex={currentStepIndex}
          setCurrentStepIndex={setCurrentStepIndex}
          handleNext={handleNextClick}
          disableNext={handleValidation()}
          disableSaveAndClose={false}
          handleSaveAndClose={() => {
            campaignSetState({ automatedDashboard: true });
            history.push('/campaignV2');
          }}
        />
      )}

      {showPreview && campaignAction === AUTOMATED_CAMPAIGN_ACTIONS.EVENT && (
        <PreviewModal
          handleClose={() => setShowPreview(false)}
          isOpen={showPreview}
          campaignSetState={campaignSetState}
          campaignAction={campaignAction}
          campaignId={automatedCampaignDetails.campaignId}
          automatedCampaignDetails={automatedCampaignDetails}
          footer
          modalWidth="45%"
          setCurrentStepIndex={setCurrentStepIndex}
          currentStepIndex={currentStepIndex}
        />
      )}
    </>
  ) : (
    <AutomatedCampaignFormWrapper>
      <Loader />
    </AutomatedCampaignFormWrapper>
  );
};

export default CustomizeCampaign;
