import React from 'react'
import { CreateEmailContainerV2 } from '../../containers'
import withDripCampaignLayout from '../../hoc/withDripCampaignLayout';

const CreateEmailBlockLayout = () => {
  return (
    <CreateEmailContainerV2 />
  )
}

export default  withDripCampaignLayout(CreateEmailBlockLayout);