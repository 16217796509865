import { get } from 'lodash';
import { takeLatest, all, call, put } from 'redux-saga/effects';
import { actions as complianceActions } from '../modules/compliance.module';
import { getComplianceArticles } from '../../services/dashboard';

function* complianceRequest() {
  try {
    const response = yield call(getComplianceArticles);
    const data = get(response, 'result.data', {});
    yield put(complianceActions.api.compliance.response(data));
  } catch (error) {
    yield put(complianceActions.api.compliance.error(error));
  }
}

function* complianceSaga() {
  yield all([
    takeLatest(complianceActions.api.compliance.request, complianceRequest),
  ]);
}

export default complianceSaga;
