import { styled } from '@mui/material';

export const Content = styled('div')(() => ({
  width: '100%',
  display: 'grid',
  gridTemplateColumns: 'repeat(4, 1fr)',
  gap: '1rem',
}));

export const Card = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '0.75rem',
  padding: '0.75rem',
  backgroundColor: '#fff',
  border: '1px solid #BBBEF7',
  borderRadius: '0.5rem',
  boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.12)',
  cursor:'pointer'
}));

export const CardIcon = styled('div')(() => ({
  display: 'flex',
  alignItems: 'flex-start',

  img: {
    objectFit: 'cover',
    borderRadius: '0.5rem',
    overflow: 'hidden',
  },
  svg: {
    width: 60,
    height: 60,
  },
}));

export const CardContent = styled('div')(() => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyContent: 'space-between',
  gap: '0.75rem',
}));

export const CardHeader = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '0.5rem',

  h3: {
    font: '500 0.875rem Poppins, sans-serif',
    color: '#101828',
    margin: 0,
    textAlign: 'left',
    maxWidth: '15ch',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    cursor: 'pointer'
  },
}));

export const SocialMediaLinks = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  gap: '0.3rem',

  svg: {
    fontSize: '1.125rem',
    color: '#98A2B3',
  },
}));

export const Analytics = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
}));

export const AnalyticsItem = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '0.5rem 0',
  borderBottom: '1px solid #E4E7EC',

  '&:last-child': {
    borderBottom: 'none',
  },

  p: {
    font: '400 0.75rem Poppins, sans-serif',
    color: '#667085',
    margin: 0,
    textAlign: 'left',
  },

  h6: {
    font: '500 0.75rem Poppins, sans-serif',
    color: '#475467',
    margin: 0,
    textAlign: 'left',
  },
}));
