import React from 'react';
import { TableRowContent } from './styles';

export const tableColumnStructure = () => {
  return [
    {
      header: {
        key: 'name',
        title: 'Name',
        enableSort: false,
      },
      width: '200px',
      render: (row) => (
        <div>
          <TableRowContent>{row?.name ?? '-'}</TableRowContent>
        </div>
      ),
    },
    {
      header: {
        key: 'email',
        title: 'Email',
        enableSort: false,
      },
      width: '400px',
      render: (row) => (
        <div>
          <TableRowContent>{row?.email ?? '-'}</TableRowContent>
        </div>
      ),
    },
  ];
};
