import { styled } from '@mui/material';

export const ContactListContainer = styled('section')(() => ({
  borderRadius: '0.5rem',
  marginTop: '1.5rem',
}));

export const TableRowContent = styled('h2')(() => ({
  margin: 0,
  textAlign: 'left',
  color: '#101828',
  font: '500 0.875rem/1rem Poppins,sans-serif',
  overflowWrap: 'break-word',
  wordBreak: 'break-word',
  whiteSpace: 'normal',
  width: '100%',
}));

export const EmailInfo = styled('p')(() => ({
  margin: 0,
  textAlign: 'left',
  color: '#475467',
  font: '500 0.75rem/1rem Poppins,sans-serif',
  overflowWrap: 'break-word',
  wordBreak: 'break-word',
  whiteSpace: 'normal',
  width: '100%',
}));

export const TableHeader = styled('header')(() => ({
  padding: '1rem',
  background: '#fff',
  border: '1px solid #E4E7EC',
  borderBottom: 'none',
  borderTopLeftRadius: '0.5rem',
  borderTopRightRadius: '0.5rem',
  '& h2': {
    color: '#101828',
    margin: 0,
    textAlign: 'left',
    font: '500 1.25rem/1rem Poppins,sans-serif',
  },
}));
