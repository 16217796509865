import React, { useEffect } from 'react'
import { redirectToLogin } from 'src/utils'

const PageNotFound = () => {
  useEffect(() => {
    redirectToLogin()
  }, [])
  
  return (
    <div>PageNotFound</div>
  )
}

export default PageNotFound;