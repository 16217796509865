import React from 'react';
import ReactSkeleton from 'react-loading-skeleton';
import {
  EmailDeliverabilityContainer,
  TableHeader,
} from './styles';
import TableSkeleton from 'src/componentsV2/Table/Skeleton';

function Skeleton() {
  return (
    <EmailDeliverabilityContainer>
      <TableHeader>
        <h2>
          <ReactSkeleton height={16} width={100} />
        </h2>
      </TableHeader>
      <TableSkeleton />
    </EmailDeliverabilityContainer>
  );
}

export default Skeleton;
