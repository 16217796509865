import { styled } from '@mui/material';

export const EmailDataWrapper = styled('section')(() => ({
  border: '1px solid #E4E7EC',
  background: '#fff',
  borderRadius: '0.5rem',
  padding: '1rem',
  margin: '1.5rem 0',
}));

export const EmailHeader = styled('div')(() => ({
  display: 'flex',
  gap: '0.25rem',
  alignItems: 'center',
  marginBottom: '0.625rem',

  '& h2': {
    color: '#6250ED',
    font: '500 1rem/1rem Poppins,sans-serif',
    margin: 0,
    textAlign: 'left',
  },
  color: '#6250ED',
}));

export const EmailDataInfoContainer = styled('div')(() => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(2,1fr)',
  gap: '1rem',
}));

export const EmailDataInfo = styled('div')(() => ({
  background: '#F9FAFB',
  borderRadius: '0.25rem',
  padding: '0.75rem',
  display: 'flex',
  justifyContent: 'space-between',
  gap: '1rem',
  flex: 1,

  '& .label, .value': {
    margin: 0,
    textAlign: 'left',
    color: '#475467',
    font: '500 0.875rem/1rem Poppins,sans-serif',
  },

  '& .value': {
    color: '#101828',
  },
}));
