import styled from 'styled-components';

export const SelectArticlesWrapper = styled.div.attrs({
  className: 'select-articles-wrapper',
})`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 73%;
  max-height: 73%;
  padding: 30px 30px 0px;
  background-color: #fff;
  overflow-x: hidden;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background: #e9f6e6 !important;
  }

  &::-webkit-scrollbar-thumb {
    background: #bccdd4 !important;
  }
`;

export const SelectedArticlesContainer = styled.div.attrs({
  className: 'selected-articles-container',
})`
  display: flex;
  width: 100%;
  height: 300px;
  padding: 5px;
  overflow-y: hidden;
  overflow-x: auto;

  &::-webkit-scrollbar {
    height: ${({ scrollHeight }) => scrollHeight ? scrollHeight : '5px'};
  }

  &::-webkit-scrollbar-track {
    background: #e9f6e6 !important;
  }

  &::-webkit-scrollbar-thumb {
    background: #bccdd4 !important;
  }
`;
