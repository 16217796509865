import React, { useState } from 'react';
import { LoaderWrapper } from '../../components';

import { Flex } from '@rebass/grid';
import { getContactsList } from '../../services/configure/index';
import { ErrorContainer } from '../';
import './InviteModals.css';
import { MioSelect } from '../CommonStyles';
import { Button } from '../Form';

const InviteSelectEmailList = ({ setSelectedIndex, setInviteData, isConnected, contactList, fromPositivlyInvite }) => {
    
    const [listId, setListId] = useState('-1');
    const [clickedLoader, toggleClickedLoader] = useState(false);


    const sendInviteHandler = () => {
        toggleClickedLoader(true);
        if (listId !== '-1') {
            getContactsList({contactGroup: listId})
                .then(response => {
                    toggleClickedLoader(false);
                    const allEmails = response.result.data.contacts[listId];
                    console.log('getContactsList', allEmails,)
                    setInviteData(allEmails.map(item => ({
                        email: item.email,
                        first_name: item.name.first,
                        last_name: item.name.last,
                        valid: true
                    })));
                    setSelectedIndex(2);
                }, err => {
                toggleClickedLoader(false);
            })
        }
    }

    const handleDropdownChange = (e) => {
        setListId(e.target.value); 
        setInviteData(e.target.value)
    }

    return (
        <Flex>
            <div className='invite-div'>
                <div className="select-list">Select Email List</div>
                <div className='flex-container'>
                    <MioSelect
                        id="groups"
                        name="current-list"
                        onChange={handleDropdownChange}
                        style={{background: '#fff', width: '28%'}}
                    >
                        <option value='-1'>Select List</option>
                        {contactList.filter(c => c.contacts.count > 0 && !c.name.includes('positivly_')).map(contact => (
                            <option key={contact.id} value={contact.id}>{contact.name}</option>
                        ))}
                    </MioSelect>
                </div>
                <div style={{marginTop: '80px', marginBottom: '15px'}}>
                    <LoaderWrapper isLoading={clickedLoader}>
                        <Button padding="7px 30px" disabled={(listId === '-1') || !isConnected} onClick={sendInviteHandler}>Next</Button>
                    </LoaderWrapper> 
                </div>
                
                {!isConnected && (
                    <ErrorContainer style={{fontSize: '11px', marginBottom: '15px'}} align='center'>*Please Connect to Email Platform</ErrorContainer>
                )}
            </div>
        </Flex>
    );
}

export default InviteSelectEmailList;