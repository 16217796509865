import { Flex } from '@rebass/grid';
import { get, isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { company, getMediaUrl, themesData } from '../../../../utils';
import { NewsletterArticle } from '../../../../containers/NewsletterContainer/NewsletterArticle';
import {
  MessageWrapper,
  SignatureSection,
} from '../../../../containers/NewsletterContainer/PreviewTab/PreviewTab.styles';
import parse from 'html-react-parser';
import { LoaderWrapper } from '../../../Loader';
import { getNewsletterData } from '../../../../services/dashboard';

const ComplianceReviewContainer = ({
  open,
  setOpen,
  newsletterName,
  newsletterArticles,
  customizeData,
  userData,
  themeNames,
}) => {
  console.log('CUSTOMIZE DATA - ', customizeData);
  const [salutationCss, setSalutationCss] = useState({});
  const [newsletterComplianceData, setNewsletterComplianceData] =
    useState(null);
  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    // const parsedMessage = parse(customizeData.message);
    const parsedMessage =
      customizeData && customizeData.message
        ? parse(customizeData.message)
        : '';

    if (
      parsedMessage &&
      parsedMessage[0] &&
      parsedMessage[0].props &&
      parsedMessage[0].props.children &&
      parsedMessage[0].props.children.type === 'span'
    ) {
      setSalutationCss(parsedMessage[0].props.children.props.style);
    }
  }, []);

  useEffect(() => {
    if (customizeData) {
      getNewsletterData(customizeData.engagementId)
        .then((res) => {
          let obj = {
            footer_text: res.data.footer_text,
            signature: res.data.signature,
            media_urls: res.data.media_urls,
            banner_hex_color: res.data.banner_hex_color,
            image_height:
              res.data &&
              res.data.media_urls &&
              res.data.media_urls[0] &&
              res.data.media_urls[0].size &&
              res.data.media_urls[0].size.resized &&
              res.data.media_urls[0].size.resized.height,
            image_width:
              res.data &&
              res.data.media_urls &&
              res.data.media_urls[0] &&
              res.data.media_urls[0].size &&
              res.data.media_urls[0].size.resized &&
              res.data.media_urls[0].size.resized.width,
          };
          setNewsletterComplianceData(obj);
        })
        .catch((err) =>
          console.log('ERROR COMPLIANCE REVIEW CONTAINER - - - - - ', err)
        );
    }
  }, [customizeData]);

  const signature =
    newsletterComplianceData && newsletterComplianceData.signature;

  const footerText =
    newsletterComplianceData && newsletterComplianceData.footer_text;

  const image = newsletterComplianceData && newsletterComplianceData.media_urls;

  // const image = newsletterComplianceData && newsletterComplianceData.media_urls;

  const imageWidth =
    newsletterComplianceData && newsletterComplianceData.image_width;

  const imageHeight =
    newsletterComplianceData && newsletterComplianceData.image_height;

  console.log('personalizeImage -------------- ', image);
  // console.log('editorImage --------------- ', editorImage);
  console.log('imageWidth -------------- ', imageWidth);
  console.log('imageHeight -------------- ', imageHeight);

  return (
    <LoaderWrapper isLoading={!newsletterComplianceData}>
      <div style={{ overflowX: 'hidden', overflowY: 'auto' }}>
        <div style={{ padding: '0px 30px' }}>
          {customizeData && customizeData.subject
            ? `Subject: ${customizeData.subject}`
            : 'Subject'}
        </div>
        <Flex
          width="100%"
          justifyContent="center"
          alignItems="center"
          padding="30px 0px"
          style={{
            flex: '0 0 auto',
            // height: 'auto',
            height: image ? 'auto' : '0px',
            backgroundColor:
              newsletterComplianceData &&
              newsletterComplianceData.banner_hex_color,
          }}
        >
          {newsletterComplianceData && newsletterComplianceData.media_urls ? (
            <img
              alt="logo"
              width={imageWidth ? imageWidth : '150'}
              height={imageHeight ? imageHeight : 'auto'}
              src={getMediaUrl(
                newsletterComplianceData.media_urls,
                '',
                'original'
              )}
            />
          ) : null}
        </Flex>

        <Flex
          width="100%"
          padding="10px 30px"
          color={themesData.charcoalGrey}
          fontSize="13px"
          style={{ flex: '0 0 auto' }}
        >
          <p style={{ marginBlock: '0em', color: '#848484' }}>
            <span style={salutationCss} id="salutation_text">
              {customizeData && customizeData.salutation}{' '}
              {customizeData && customizeData.salutation_text && '{{'}{' '}
              {customizeData &&
                customizeData.salutation_text &&
                customizeData.salutation_text.label}{' '}
              {customizeData && customizeData.salutation_text && '}}'}
            </span>
          </p>
        </Flex>
        {customizeData && customizeData.message && (
          <MessageWrapper
            id="message"
            style={{
              textAlign: 'left',
              color: themesData.charcoalGrey,
              padding: '0px 30px 10px',
            }}
            dangerouslySetInnerHTML={{ __html: customizeData.message }}
          />
        )}

        <SignatureSection
          style={{ padding: '0px 30px' }}
          dangerouslySetInnerHTML={{ __html: signature && signature }}
        ></SignatureSection>

        <Flex width="100%" padding="10px 30px" style={{ flex: '0 0 auto' }}>
          {customizeData &&
            customizeData.media1 &&
            !isEmpty(customizeData.media1) && (
              <img
                alt="customize"
                width="100%"
                src={customizeData.media1}
                style={{ maxHeight: '300px', objectFit: 'cover' }}
              />
            )}
        </Flex>
        <Flex
          width="100%"
          justifyContent="space-between"
          flexWrap="wrap"
          padding="0px 50px"
          style={{ flex: '0 0 auto' }}
        >
          {!isEmpty(newsletterArticles) &&
            newsletterArticles.map((item, index) => (
              <NewsletterArticle
                key={index}
                {...item}
                themeNames={themeNames}
                article={item}
                newsletterArticles={newsletterArticles}
                deleteBlocked
                openUrl
                width={'308px'}
                height={'300px'}
                margin="10px 8px"
              />
            ))}
        </Flex>

        <SignatureSection
          style={{ padding: '0px 30px' }}
          dangerouslySetInnerHTML={{ __html: footerText && footerText }}
        ></SignatureSection>

        <hr style={{ width: '100%', height: '2px' }} />
        <Flex
          width="100%"
          color="#666666"
          fontSize="12px"
          padding="0px 30px"
          style={{ flex: '0 0 auto' }}
        >
          © {company.name} 2021
        </Flex>
        <Flex
          width="100%"
          padding="10px 30px"
          color="#666666"
          fontSize="10px"
          style={{ flex: '0 0 auto', textAlign: 'left' }}
        >
          This message was sent to {'{{ '}email{' }}'} and intended for {'{{ '}
          {customizeData &&
            customizeData.salutation_text &&
            customizeData.salutation_text.label}
          {' }}'}.<br></br>
          Not your account? Remove your email from this account.
        </Flex>
      </div>
    </LoaderWrapper>
  );
};

const mapStateToProps = (state) => ({
  newsletterName: state.newsletter.newsletterName,
  newsletterArticles: state.newsletter.newsletterArticles,
  customizeData: state.newsletter.customData,
  userData: state.user.data,
  themeNames: get(state, 'theme.themeNames', {}),
});

export default connect(mapStateToProps, null)(ComplianceReviewContainer);
