/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable react-hooks/exhaustive-deps */
import { Flex } from '@rebass/grid';
import { isEmpty, get } from 'lodash';
import React, { useRef } from 'react';
import { useEffect, useState } from 'react';
import { getMediaImage, themesData } from '../../utils';
import { Modal, ModalHeader, ModalCloseButton } from '../../components';
import { ReviewBody } from '../../containers/DripCampaignContainerV2/Preview&ViewInfoModal/Modal.styles';
import {
  PreviewContainer,
  TemplateContainer,
} from '../../containers/DripCampaignContainerV2/ReviewCampaign/ReviewCampaigns.styles';
import { Chips } from '../../containers/DripCampaignContainerV2/SocialPreview/SocialPreview.styles';
import { ModalFooterButton } from '../Modal/Modal.styles';
import { SocialBox } from '../ShareContentContainerV2/ShareContentContainer.styles';
import {
  CampaignFeedbackContainer,
  ReviewContainer,
} from './SocialCompRevModal.styles';
import { LoaderWrapper } from '../Loader';
import { getCampaignDetails } from '../../services/campaign';
import { postComplianceRequest } from '../../services/dashboard';

const SocialCompRevModal = ({
  handleClose,
  isOpen,
  curCampaign,
  setDetails,
  curTab,
  curDate,
  rejectCampaign,
}) => {
  console.log('CUR CAMPAIGN - - -', curCampaign);
  const [loading, setLoading] = useState(false);
  const [feedback, setFeedback] = useState('');
  const [activePlatform, setActivePlatform] = useState('fb');
  const [curPlatformCampaign, setCurPlatformCampaign] = useState({});
  const [buttonLoader, setButtonLoader] = useState(false);
  const [feedbackError, setFeedbackError] = useState('');
  const iframeRef = useRef(null);

  const handleSubmit = (reqType) => {
    const reqObj =
      reqType === 'approved'
        ? { action: 'approved', message: 'Approved' }
        : { action: 'rejected', message: feedback };
    if (
      (feedback.length <= 500 && reqType === 'rejected') ||
      reqType === 'approved'
    ) {
      setButtonLoader(true);
      postComplianceRequest(get(curCampaign, 'id', ''), reqObj)
        .then((res) => {
          setButtonLoader(false);
          handleClose();
          setDetails(curTab, undefined, curDate);
          // if(get(res, 'result.success', false) && get(curCampaign, 'share_details.platform', '') === 'web_page') {
          //   let payload = {
          //     type: reqType === 'approved' ? 'approved' : 'rejected'
          //   }
          //   complianceStatusUpdate({id: get(curCampaign, 'ids.content.id', ''), payload});
          // }
        })
        .catch((err) => {
          //error here
          setButtonLoader(false);
        });
    }
  };

  //   const platformMapper = {
  //     fb: 'Facebook',
  //     tw: 'Twitter',
  //     em: 'Email',
  //     ln: 'Linkedin',
  //   };

  const curCampaignDetailsHandler = (resObj) => {
    console.log('RES OBJ - - -', resObj);
    const emList = get(resObj, 'email', []);
    const twList = get(resObj, 'twitter', []);
    const lnList = get(resObj, 'linkedin', []);
    const fbList = get(resObj, 'facebook', []);
    const templateHTML = get(resObj, 'template.template_html', '');
    let fetchedArticles = [];

    if (get(resObj, 'posts_details.fb', false))
      fetchedArticles.push(
        get(resObj, 'posts_details.fb[0].articles.feed[0]', {})
      );
    else if (get(resObj, 'posts_details.tw', false))
      fetchedArticles.push(
        get(resObj, 'posts_details.tw[0].articles.feed[0]', {})
      );
    else if (get(resObj, 'posts_details.ln', false))
      fetchedArticles.push(
        get(resObj, 'posts_details.ln[0].articles.feed[0]', {})
      );
    else fetchedArticles = get(resObj, 'newsletter.articles.feed', []);

    let socialArticleId = '';

    if (get(resObj, 'posts.facebook.articles[0].id', ''))
      socialArticleId = get(resObj, 'posts.facebook.articles[0].id', '');
    else if (get(resObj, 'posts.twitter.articles[0].id', false))
      socialArticleId = get(resObj, 'posts.twitter.articles[0].id', '');
    else if (get(resObj, 'posts.linkedin.articles[0].id', false))
      socialArticleId = get(resObj, 'posts.linkedin.articles[0].id', '');

    // console.log('fetchedArticles', fetchedArticles);

    let campaign = {
      id: get(resObj, 'id', ''),
      attachment: get(resObj, 'attachment', ''),
      template: templateHTML,
      subject: get(resObj, 'newsletter.customized_data.subject', ''),
      action: get(resObj, 'feedback.email.action', ''),
      em: {
        platform:
          get(resObj, 'is_email', 'false') === 'true' || (get(curCampaign, 'share_details.platform') === 'email' &&
          !isEmpty(emList)),
        timings:
          resObj?.posts?.email?.schedule === 'immediate'
            ? [{ time: 'Immediate' }]
            : get(resObj, 'posts.email.schedule_time', []),
      },
      selectedArticles: fetchedArticles
        .filter((article) => article.id === socialArticleId)
        .map((article) => ({
          id: article.id,
          url: getMediaImage(get(resObj, 'media_urls', []), 'original'),
          themeId: get(article, 'ids.theme[0]', ''),
          topic: get(article, 'auxiliary.title', ''),
          link: get(article, 'links.social_permalink', ''),
        })),
      action: get(resObj, 'feedback.social.action', ''),
      fb: {
        timings:
          resObj?.posts?.facebook?.schedule === 'immediate'
            ? [{ time: 'Immediate' }]
            : get(resObj, 'posts.facebook.schedule_time', []),
        platform:
          get(resObj, 'is_facebook', 'false') === 'true' || (get(curCampaign, 'share_details.platform') === 'social' &&
          !isEmpty(fbList)),
        summary: get(resObj, 'posts.facebook.override_text', ''),
      },
      tw: {
        timings:
          resObj?.posts?.twitter?.schedule === 'immediate'
            ? [{ time: 'Immediate' }]
            : get(resObj, 'posts.twitter.schedule_time', []),
        platform:
          get(resObj, 'is_twitter', 'false') === 'true' || (get(curCampaign, 'share_details.platform') === 'social' &&
          !isEmpty(twList)),
        summary: get(resObj, 'posts.twitter.override_text', ''),
      },
      ln: {
        timings:
          resObj?.posts?.linkedin?.schedule === 'immediate'
            ? [{ time: 'Immediate' }]
            : get(resObj, 'posts.linkedin.schedule_time', []),
        platform:
          get(resObj, 'is_linkedin', 'false') === 'true' || (get(curCampaign, 'share_details.platform') === 'social' &&
          !isEmpty(lnList)),
        summary: get(resObj, 'posts.linkedin.override_text', ''),
      },
    };
    return campaign;
  };

  const curPlatformCampaignHandler = (activeCampaign) => {
    console.log('ACTIVE CAMPAIGN - - - - - ', activeCampaign);
    if (activeCampaign) {
      setCurPlatformCampaign(activeCampaign);
      if ((get(curCampaign, 'share_details.platform', '') === 'email' || get(curCampaign, 'share_details.platform', '') === 'clout') && activeCampaign.em && activeCampaign.em.platform) {
        console.log('SETTING EMAIL');
        setActivePlatform('em');
      }
      else {
        for (let key in activeCampaign) {
          if (activeCampaign[key] && key !== 'em' && activeCampaign[key].platform) {
            console.log('SETTING PLATFORM - - - -', key);
            setActivePlatform(key);
            break;
          }
        }
      }
    }
  };

  useEffect(() => {
    let resObj = {};
    const campaignId = get(curCampaign, 'ids.content.id', '');
    console.log('CAMPAIGN ID - - - -', campaignId);
    if (campaignId && campaignId.length > 0 && get(curCampaign, 'share_details.platform', '') !== 'web_page') {
      //   setCurrentCampaignID(campaignId);
      setLoading(true);
      getCampaignDetails(campaignId)
        .then((res) => {
          setLoading(false);
          resObj = get(res, 'result.data', {});

          let campaign = curCampaignDetailsHandler(resObj);
          //   setPlatformCampaigns(campaigns);

          curPlatformCampaignHandler(campaign);
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
        });
    } 
    else if(get(curCampaign, 'share_details.platform', '') === 'web_page') {
      console.log('SETTING EMAIL')
      setCurPlatformCampaign({
        template: get(curCampaign, 'page_html', '')
      });
      setActivePlatform('em');
    } else if(get(curCampaign, 'acb', false)) {
      setCurPlatformCampaign({
        template: get(curCampaign, 'page_html', '')
      });
      setActivePlatform('em');
    }
  }, [curCampaign]);

  useEffect(() => {
    if (feedback.length > 500) {
      setFeedbackError('Please enter feedback up to 500 characters');
    } else {
      setFeedbackError('');
    }
  }, [feedback]);

  useEffect(() => {
    if (activePlatform === 'em') {
      setTimeout(() => {
        if (iframeRef.current) {
          var css = document.createElement('style');
          var st = '::-webkit-scrollbar { width: 5px }';
          st += ' ::-webkit-scrollbar-track { background: #e9f6e6 !important }';
          st += '::-webkit-scrollbar-thumb { background: #bccdd4 !important }';
          css.appendChild(document.createTextNode(st));
          iframeRef.current.contentDocument.head.append(css);
        }
      }, 100);
    }
  }, [activePlatform]);

  return (
    <Modal
      bgColor="#f5f7fb"
      size="medium"
      onRequestClose={handleClose}
      shouldCloseOnOverlayClick
      isOpen={isOpen}
      height="95%"
    >
      <ModalHeader
        style={{
          textAlign: 'center',
          color: '#859094',
          paddingBottom: '0px',
        }}
      >
        <div>Content Review</div>
        <ModalCloseButton onClick={handleClose} />
        <hr style={{ width: '100%' }} />
      </ModalHeader>

      <ReviewContainer padding={'5px 15px 15px'}>
        <LoaderWrapper isLoading={loading}>
          <div>
            <Flex
              width="100%"
              justifyContent="flex-end"
              alignItems="center"
              style={{ position: 'relative', zIndex: '10' }}
            >
              <div>
                <Flex display="flex" flexDirection="column">
                  <Flex
                    alignItems="center"
                    justifyContent="flex-start"
                    style={{
                      // backgroundColor: '#eee',
                      // borderRadius: '5px',
                      // marginBottom: '25px',
                      width: 'fit-content',
                    }}
                  >
                    {(get(curCampaign, 'share_details.platform', '') === 'email' || get(curCampaign, 'share_details.platform', '') === 'clout') && curPlatformCampaign.em && curPlatformCampaign.em.platform && (
                      <Chips
                        // onClick={() => {
                        //   setActivePlatform('tw');
                        // }}
                        active={true}
                        style={{
                          marginRight: '5px',
                          display: 'flex',
                        }}
                      >
                        {console.log('ACTIVE PLATFORM - EMAIL')}
                        <div
                          className="chip"
                          style={{
                            padding: '0px',
                            height: 'max-content',
                            width: 'max-content',
                            borderRadius: ' 50%',
                            border: '1px solid transparent',
                            cursor: 'auto',
                          }}
                        >
                          <img
                            src="/assets/images/campaign-platform-email.svg"
                            alt=""
                            style={{
                              margin: ' 0px',
                              width: '25px',
                              height: '25px',
                            }}
                          />
                          {/* Email */}
                        </div>
                      </Chips>
                    )}
                    {(get(curCampaign, 'share_details.platform', '') === 'social' || get(curCampaign, 'share_details.platform', '') === 'clout') && curPlatformCampaign.fb && curPlatformCampaign.fb.platform && (
                      <Chips
                        // onClick={() => {
                        //   setActivePlatform('tw');
                        // }}
                        active={true}
                        style={{
                          marginRight: '5px',
                          display: 'flex',
                        }}
                      >
                        <div
                          className="chip"
                          style={{
                            padding: '0px',
                            height: 'max-content',
                            width: 'max-content',
                            borderRadius: ' 50%',
                            border: '1px solid transparent',
                            cursor: 'auto',
                          }}
                        >
                          <img
                            src="/assets/images/campaign-platform-facebook.png"
                            alt=""
                            style={{
                              margin: ' 0px',
                              width: '25px',
                              height: '25px',
                            }}
                          />
                          {/* Facebook */}
                        </div>
                      </Chips>
                    )}
                    {(get(curCampaign, 'share_details.platform', '') === 'social' || get(curCampaign, 'share_details.platform', '') === 'clout') && curPlatformCampaign.ln && curPlatformCampaign.ln.platform && (
                      <Chips
                        // onClick={() => {
                        //   setActivePlatform('tw');
                        // }}
                        active={true}
                        style={{
                          marginRight: '5px',
                          display: 'flex',
                        }}
                      >
                        <div
                          className="chip"
                          style={{
                            padding: '0px',
                            height: 'max-content',
                            width: 'max-content',
                            borderRadius: ' 50%',
                            border: '1px solid transparent',
                            cursor: 'auto',
                          }}
                        >
                          <img
                            src="/assets/images/campaign-platform-linkedIn.png"
                            alt=""
                            style={{
                              margin: ' 0px',
                              width: '25px',
                              height: '25px',
                            }}
                          />
                          {/* LinkedIn */}
                        </div>
                      </Chips>
                    )}
                    {(get(curCampaign, 'share_details.platform', '') === 'social' || get(curCampaign, 'share_details.platform', '') === 'clout') && curPlatformCampaign.tw && curPlatformCampaign.tw.platform && (
                      <Chips
                        // onClick={() => {
                        //   setActivePlatform('tw');
                        // }}
                        active={true}
                        style={{
                          marginRight: '5px',
                          display: 'flex',
                        }}
                      >
                        {console.log('ACTIVE PLATFORM - TWITTER')}
                        <div
                          className="chip"
                          style={{
                            padding: '0px',
                            height: 'max-content',
                            width: 'max-content',
                            borderRadius: ' 50%',
                            border: '1px solid transparent',
                            cursor: 'auto',
                          }}
                        >
                          <img
                            src="/assets/images/campaign-platform-twitter.png"
                            alt=""
                            style={{
                              margin: ' 0px',
                              width: '25px',
                              height: '25px',
                            }}
                          />
                          {/* Twitter */}
                        </div>
                      </Chips>
                    )}
                  </Flex>
                </Flex>
              </div>
            </Flex>
            <div
              style={{
                border: '1px solid rgb(222 222 224)',
                position: 'relative',
                top: '-15px',
                borderRadius: '6px',
              }}
            >
              <PreviewContainer style={{ width: '100%', border: 'none' }}>
                {activePlatform === 'em' && (
                  <div>
                    {get(curCampaign, 'share_details.platform', '') !== 'web_page' && (
                      <div style={{ marginBottom: '10px', color: 'black' }}>
                        Subject: {curPlatformCampaign?.subject || ''}
                      </div>
                    )}
                    
                    <TemplateContainer
                      style={{ height: curTab === 'rejected' && '46vh' }}
                    >
                      <iframe
                        srcDoc={curPlatformCampaign?.template}
                        frameBorder="no"
                        ref={iframeRef}
                      />
                    </TemplateContainer>
                  </div>
                )}
                {console.log('ACTIVE PLATFORM SOCIAL COMP REV MODAL - - - ', activePlatform)}
                {(activePlatform === 'fb' ||
                  activePlatform === 'ln' ||
                  activePlatform === 'tw') && (
                  <div
                    onClick={() =>
                      window.open(curPlatformCampaign?.attachment, '_blank')
                    }
                    style={{
                      cursor: 'pointer',
                      height: curTab === 'rejected' ? '50vh' : '85%',
                    }}
                  >
                    <Flex
                      width="100%"
                      justifyContent="center"
                      alignItems="center"
                      mb="10px"
                    >
                      <img
                        alt="social"
                        src={
                          !isEmpty(curPlatformCampaign.selectedArticles) &&
                          curPlatformCampaign.selectedArticles[0].url
                        }
                        style={{
                          // width: 'inherit',
                          height: '200px',
                          borderRadius: '5px',
                        }}
                      />
                    </Flex>
                    <ReviewBody
                      fontWeight="700"
                      fontSize="1.2rem"
                      style={{ color: 'black' }}
                    >
                      {!isEmpty(curPlatformCampaign.selectedArticles) &&
                        curPlatformCampaign.selectedArticles[0].topic}
                    </ReviewBody>
                    <ReviewBody
                      fontSize="0.8rem"
                    //   style={{
                    //     paddingTop: '5px',
                    //     paddingBottom: '5px',
                    //     height: '18%',
                    //   }}
                    // due to overlapping of social buttons over compliance heading
                      style={{
                        paddingTop: '10px',
                        paddingBottom: '50px',
                        color: 'black',
                      }}
                    >
                      {curPlatformCampaign[activePlatform]?.summary}
                    </ReviewBody>
                  </div>
                )}
                <div
                  style={{
                    marginTop: '20px',
                    marginBottom: '10px',
                    position: 'absolute',
                    bottom: ' 0',
                  }}
                >
                  <Flex display="flex" flexDirection="column" pt="0px" pl="0px">
                    <Flex
                      alignItems="center"
                      justifyContent="flex-start"
                      style={{
                        // backgroundColor: '#eee',
                        borderRadius: '5px',
                      }}
                    >
                      {(get(curCampaign, 'share_details.platform', '') === 'email' || get(curCampaign, 'share_details.platform', '') === 'clout') && curPlatformCampaign.em &&
                        curPlatformCampaign.em.platform && (
                          <Chips
                            onClick={() => {
                              setActivePlatform('em');
                            }}
                            active={activePlatform === 'em'}
                            data-testid="em-chip"
                          >
                            <div className="chip">
                              <img
                                src="/assets/images/campaign-platform-email.svg"
                                alt=""
                              />
                              Email
                            </div>
                          </Chips>
                        )}
                      {(get(curCampaign, 'share_details.platform', '') === 'social' || get(curCampaign, 'share_details.platform', '') === 'clout') && curPlatformCampaign.fb &&
                        curPlatformCampaign.fb.platform && (
                          <Chips
                            onClick={() => {
                              setActivePlatform('fb');
                            }}
                            active={activePlatform === 'fb'}
                            data-testid="fb-chip"
                          >
                            <div className="chip">
                              <img
                                src="/assets/images/campaign-platform-facebook.png"
                                alt=""
                              />
                              Facebook
                            </div>
                          </Chips>
                        )}
                      {(get(curCampaign, 'share_details.platform', '') === 'social' || get(curCampaign, 'share_details.platform', '') === 'clout') && curPlatformCampaign.ln &&
                        curPlatformCampaign.ln.platform && (
                          <Chips
                            onClick={() => {
                              setActivePlatform('ln');
                            }}
                            active={activePlatform === 'ln'}
                            data-testid="ln-chip"
                          >
                            <div className="chip">
                              <img
                                src="/assets/images/campaign-platform-linkedIn.png"
                                alt=""
                              />
                              LinkedIn
                            </div>
                          </Chips>
                        )}
                      {(get(curCampaign, 'share_details.platform', '') === 'social' || get(curCampaign, 'share_details.platform', '') === 'clout') && curPlatformCampaign.tw &&
                        curPlatformCampaign.tw.platform && (
                          <Chips
                            onClick={() => {
                              setActivePlatform('tw');
                            }}
                            active={activePlatform === 'tw'}
                            data-testid="tw-chip"
                          >
                            <div className="chip">
                              <img
                                src="/assets/images/campaign-platform-twitter.png"
                                alt=""
                              />
                              Twitter
                            </div>
                          </Chips>
                        )}
                    </Flex>
                  </Flex>
                </div>
              </PreviewContainer>
              {curTab !== 'rejected' && (
                <Flex>
                  <SocialBox
                    style={{ width: '100%', border: 'none' }}
                    padding="10px"
                  >
                    <div
                      style={{
                        fontSize: '10px',
                        color: themesData.purpleishBlue,
                        textAlign: 'center',
                      }}
                    >
                      {feedbackError}
                    </div>
                    <CampaignFeedbackContainer
                      placeholder="Please share your feedback on the content."
                      onChange={(e) => setFeedback(e.target.value)}
                      value={feedback}
                      data-testid="compliance-review-feedback-container"
                    />
                  </SocialBox>
                </Flex>
              )}
            </div>
            <LoaderWrapper isLoading={buttonLoader}>
              {curTab === 'rejected' ? (
                <div
                  style={{
                    position: 'absolute',
                    bottom: '10px',
                    left: '0',
                    right: '0',
                  }}
                >
                  <ModalFooterButton
                    onClick={() =>
                      window.open(
                        '/campaignV2?tab=compliance_rejected',
                        '_self'
                      )
                    }
                    style={{
                      marginRight: '18px',
                      borderRadius: '6px',
                      fontSize: '10px',
                      paddingLeft: '20px',
                      paddingRight: '20px',
                    }}
                    data-testid="compliance-review-modal-redirect"
                  >
                    Go To Campaign Dashboard
                  </ModalFooterButton>
                </div>
              ) : (
                <div>
                  {!rejectCampaign && (
                    <ModalFooterButton
                      onClick={() => handleSubmit('approved')}
                      style={{
                        marginRight: '18px',
                        borderRadius: '6px',
                        fontSize: '10px',
                        paddingLeft: '20px',
                        paddingRight: '20px',
                      }}
                      data-testid="compliance-review-modal-approve"
                    >
                      Approve
                    </ModalFooterButton>
                  )}
                  <ModalFooterButton
                    onClick={() => handleSubmit('rejected')}
                    disabled={isEmpty(feedback) || feedback.length > 500}
                    style={{
                      backgroundColor: '#4e6780',
                      borderRadius: '6px',
                      borderColor: '#4e6780',
                      fontSize: '10px',
                      paddingLeft: '20px',
                      paddingRight: '20px',
                    }}
                    data-testid="compliance-review-sendFB"
                  >
                    Send Feedback
                  </ModalFooterButton>
                </div>
              )}
            </LoaderWrapper>
          </div>
        </LoaderWrapper>
      </ReviewContainer>
    </Modal>
  );
};

export default SocialCompRevModal;
