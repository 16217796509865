import { get } from 'lodash';
import { takeLatest, all, call, put } from 'redux-saga/effects';
import { actions as profileActions } from '../modules/profile.module';
import { actions as loginActions } from '../modules/login.module';
import { loginRequest } from './login.saga';
import { updateProfile } from '../../services/configure';

function* profileUpdateRequest({ payload }) {
  try {
    // const {} = get(payload, 'params', {});
    const response = yield call(updateProfile, payload);

    const status = get(response, 'result.success');
    if (status === true) {
      const data = get(response, 'result.data', {});
      console.log(data);
      yield put(profileActions.api.profile.response(data));
    } else {
      yield put(profileActions.api.profile.error(response.error));
    }
  } catch (error) {
    console.log(error);
    yield put(profileActions.api.profile.error(error));
  }
}

function* profileSaga() {
  yield all([
    takeLatest(profileActions.api.profile.request, profileUpdateRequest),
  ]);
}

export default profileSaga;
