import React, { useState } from 'react';
import { format, isValid, parseISO } from 'date-fns';

import Button from 'src/componentsV2/Button';
import CampaignInfoCards from '../CampaignInsights/CampaignInfoCards';
import Preview from './Preview';

import {
  CampaignInfoCardsContainer,
  CampaignPurposeContainer,
  CampaignStatusPill,
  CampaignSummaryHeader,
  CampaignSummaryOverview,
  CampaignSummaryTitleInfo,
  CampaignSummaryWrapper,
  CampaignTitleContainer,
  CampaignTypeContainer,
} from './styles';

import Calender from 'src/assets/icons/campaignOverview/Calender';
import Communication from 'src/assets/icons/campaignOverview/Communication';
import Contacts from 'src/assets/icons/campaignOverview/Contacts';

import { getMediaImage, onImageError } from 'src/utils';
import { renderPlatforms } from '../utils';

function CampaignSummary({ campaignInfo = {}, selectedSubCampaign = {} }) {
  const [open, toggleModal] = useState(false);

  const toggleModalHandler = () => {
    toggleModal((prev) => !prev);
  };

  const campaignDurationSuffix =
    campaignInfo?.campaignDuration <= 1 ? 'Day' : 'Days';
  const startDate = campaignInfo?.startDate
    ? parseISO(campaignInfo?.startDate)
    : '';

  return (
    <CampaignSummaryWrapper>
      <CampaignSummaryHeader>
        <CampaignSummaryTitleInfo>
          <img
            src={getMediaImage(campaignInfo?.media_url)}
            alt="campaign-logo"
            className="campaign-logo"
            onError={onImageError}
          />

          <CampaignTitleContainer>
            <h3>{campaignInfo?.campaignName || '-'}</h3>

            <CampaignTypeContainer>
              <img src="/assets/images/Analytics/campaign-logo.svg" alt="" />
              <p>{campaignInfo?.campaignType || '-'}</p>
            </CampaignTypeContainer>

            <CampaignStatusPill status={campaignInfo?.status ?? ''}>
              {campaignInfo?.status?.toLowerCase() === 'completed'
                ? 'Completed'
                : 'In Progress' ?? ''}
            </CampaignStatusPill>
          </CampaignTitleContainer>
        </CampaignSummaryTitleInfo>
        {selectedSubCampaign &&
          Object.keys(selectedSubCampaign)?.length > 0 && (
            <Button variant="outline" onClick={toggleModalHandler}>
              Preview Content
            </Button>
          )}
      </CampaignSummaryHeader>

      <CampaignSummaryOverview>
        <CampaignPurposeContainer>
          <div>
            <h4 className="label">Purpose</h4>
            <p className="value">{campaignInfo?.purpose || '-'}</p>
          </div>

          <div>
            <h4 className="label">Platforms</h4>
            <div className="platform-icon-container">
              {renderPlatforms(campaignInfo?.platforms)}
            </div>
          </div>
        </CampaignPurposeContainer>

        <CampaignInfoCardsContainer>
          <CampaignInfoCards
            campaignInfo={{
              icon: <Calender />,
              label: 'Start Date',
              value: isValid(startDate) ? format(startDate, 'MM/dd/yy') : '-',
            }}
          />
          <CampaignInfoCards
            campaignInfo={{
              icon: <Communication />,
              label: 'Campaign Duration',
              value: `${
                campaignInfo?.campaignDuration ?? ''
              } ${campaignDurationSuffix}`,
            }}
          />
          <CampaignInfoCards
            campaignInfo={{
              icon: <Contacts />,
              label: 'Total Contacts',
              value: `${campaignInfo?.totalContacts ?? '-'}`,
            }}
          />
        </CampaignInfoCardsContainer>
      </CampaignSummaryOverview>
      {open && (
        <Preview
          open={open}
          toggleModal={toggleModalHandler}
          selectedSubCampaign={selectedSubCampaign}
          
        />
      )}
    </CampaignSummaryWrapper>
  );
}

export default CampaignSummary;
