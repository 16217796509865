import React from 'react';
import {
    SwitchContainerRow,
    SwitchAction,
    EnabledText,
    SwitchStyle,
    SwitchLabel,
    SwitchSubTitle,
    SwitchContainerIndividual
} from './styles';

const SwitchRow = ({ cardData, index, mapped = false }) => {
    if(mapped){
        return(
            <SwitchContainerRow showBorder={true} index={index}>
                <SwitchLabel>{cardData.title}</SwitchLabel>
                <SwitchAction>
                    <SwitchStyle 
                        onClick={() => {
                            cardData.switchAction(cardData.switchActive === true ? false : cardData.switchActive === false ? true : cardData.switchActive === 'true' ? 'false' : 'true', index)
                        }}
                        checked={cardData.switchActive === true || cardData.switchActive === 'true'}
                    />
                    <EnabledText>{cardData.switchActive === true || cardData.switchActive === 'true' ? 'Enabled' : 'Disabled'}</EnabledText>
                </SwitchAction>
            </SwitchContainerRow>
        )
    }
    return(
        <SwitchContainerIndividual margin="10px 0px 14px">
            <div style={{display: 'table'}}>
                <SwitchLabel>{cardData.title}</SwitchLabel>
                <SwitchSubTitle>{cardData?.subTitle}</SwitchSubTitle>
            </div>
            <SwitchAction style={{margin: '18px 20px 0 0'}}>
                <SwitchStyle 
                    onClick={() => {
                        cardData.switchAction(cardData.switchActive === true ? false : cardData.switchActive === false ? true : cardData.switchActive === 'true' ? 'false' : 'true', index)
                    }}
                    checked={cardData.switchActive === true || cardData.switchActive === 'true'}
                />
                <EnabledText>{cardData.switchActive === true || cardData.switchActive === 'true' ? 'On' : 'Off'}</EnabledText>
            </SwitchAction>
        </SwitchContainerIndividual>
    )
};

export default SwitchRow;