import { pick, get } from 'lodash';

export const getAnalyticsDates = (state) =>
  pick(get(state, 'analytics', {}), ['startDate', 'endDate']);

export const getRepType = (state) => get(state, 'analytics.repType', '');
export const getSelectedContactList = (state) => get(state, 'analytics.contactList', []);
export const getSelectedEmail = (state) => get(state, 'analytics.selectedEmail', '');
export const getAfterToken = (state) => get(state, 'analytics.afterData', '');
export const getLeadType = (state) => get(state, 'analytics.leadType', '');
export const getFilterParams = (state) => get(state, 'analytics.filterParams', {})