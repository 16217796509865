/* eslint-disable react-hooks/exhaustive-deps */
import {
  faAngleLeft,
  faAngleRight,
  faArrowRight,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Flex } from '@rebass/grid';
import {
  get,
  isEmpty,
  map,
  uniqBy,
  intersectionWith,
  isEqual,
  intersectionBy,
} from 'lodash';
import React, { useState, useEffect } from 'react';
import {
  Button,
  ButtonNoBorderBackground,
  LoaderWrapper,
} from '../../../components';
import { getBucketArticleData, themesData } from '../../../utils';
import {
  CreateNewWrapper,
  DraftWrapper,
  GetStartedTitle,
} from '../GetStartedTab/GetStartedTab.styles';
import { NewsletterArticle } from '../NewsletterArticle';
import {
  ArticleListWrapper,
  BucketArticleWrapper,
  NewsLetterArticleWrapper,
} from './AddArticle.styles';
import { useHistory } from 'react-router-dom';
import { NewsletterCheckBoxDiv } from '../Recipients/Recipients.styles';
import { updateNewsletter } from '../../../services/newsletter';
import { getCRMContactList } from '../../../services/configure/index';

const AddArticleTab = ({
  setSelectedIndex,
  newsletterSetState,
  bucketData,
  themeNames,
  newsletterBucketDeleteRequest,
  isAdmin,
  selectedNewsletterArticles,
  isEditMode,
  isDeleting,
  newsletterId,
  selectedContacts,
  ccContacts,
  recommendedData,
  exploreArticleFetching,
  exploreArticleRequest,
  setTouched,
  userData,
  channelId,
  popularThemes
}) => {
  const history = useHistory();
  const [newsletterArticles, setNewletterArticles] = useState([]);
  const [dragId, setDragId] = useState('');
  const [deleteId, setDeleteId] = useState('');
  const [loading, setLoading] = useState(false);
  const [showNewsletterArticles, setShowNewsletterArticles] = useState(true);
  const [contactList, updateContactList] = useState([]);
  const [showRecommendedArticles, setShowRecommendedArticles] = useState(false);

  const [recommendedArticlesSelected, setRecommendedArticlesSelected] =
    useState([]);
  const [bucketArticlesSelected, setBucketArticlesSelected] = useState([]);
  const [testLoopCounter, updateTestLoopCounter] = useState(0);

  useEffect(() => {
    if (!isEditMode && !isEmpty(selectedNewsletterArticles)) {
      setNewletterArticles(selectedNewsletterArticles);
    } else if (isEditMode && !isEmpty(selectedNewsletterArticles)) {
      setNewletterArticles(selectedNewsletterArticles);
    }

    fetchData();
  }, []);

  useEffect(() => {
    if (!isEmpty(selectedNewsletterArticles)) {
      const bucketArticles = getBucketArticleData(bucketData);
      const recommendedArticles = getBucketArticleData(recommendedData).slice(
        0,
        10
      );

      var difbucketArticles = intersectionBy(
        selectedNewsletterArticles,
        bucketArticles,
        'id'
      );

      var difrecommendedArticles = intersectionBy(
        selectedNewsletterArticles,
        recommendedArticles,
        'id'
      );

      setBucketArticlesSelected(difbucketArticles);
      setRecommendedArticlesSelected(difrecommendedArticles);
    }
  }, [selectedNewsletterArticles]);

  const fetchData = () => {
    getCRMContactList()
      .then((response) => {
        const contactList = response.result.data
          ? Object.keys(response.result.data).map((key) => {
              const recommendedThemesArray = response.result.data[key]
                .recommended_themes
                ? Object.keys(
                    response.result.data[key].recommended_themes.themes
                  ).map(
                    (theme) =>
                      response.result.data[key].recommended_themes.themes[theme]
                  )
                : [];
              const userThemesArray = response.result.data[key].user_themes
                ? Object.keys(response.result.data[key].user_themes.themes).map(
                    (theme) =>
                      response.result.data[key].user_themes.themes[theme]
                  )
                : [];
              return {
                id: key,
                name: response.result.data[key].name,
                themes:
                  userThemesArray &&
                  userThemesArray.length > 0 &&
                  userThemesArray[0]
                    ? userThemesArray
                    : recommendedThemesArray,
                recommendedThemes: recommendedThemesArray,
                userThemes: userThemesArray,
                disabledThemes: [],
              };
            })
          : [];
        updateContactList(contactList);
        fetchArticles(contactList);
      })
      .catch((err) => console.log('ERROR - ', err));
  };

  const fetchArticles = (contactArray) => {
    const themesList = contactArray.length > 0 ? getThemeIds(contactArray) : [];
    exploreArticleRequest({
      params: {
        source: 'crmClients',
        themesList,
      },
    });
    if (themesList.length > 0) {
      getCRMContactList()
        .then((response) => {
          const contactList = response.result.data
            ? Object.keys(response.result.data).map((key) => {
                const recommendedThemesArray = response.result.data[key]
                  .recommended_themes
                  ? Object.keys(
                      response.result.data[key].recommended_themes.themes
                    ).map(
                      (theme) =>
                        response.result.data[key].recommended_themes.themes[
                          theme
                        ]
                    )
                  : [];
                const userThemesArray = response.result.data[key].user_themes
                  ? Object.keys(
                      response.result.data[key].user_themes.themes
                    ).map(
                      (theme) =>
                        response.result.data[key].user_themes.themes[theme]
                    )
                  : [];
                return {
                  id: key,
                  name: response.result.data[key].name,
                  themes:
                    userThemesArray &&
                    userThemesArray.length > 0 &&
                    userThemesArray[0]
                      ? userThemesArray
                      : recommendedThemesArray,
                  recommendedThemes: recommendedThemesArray,
                  userThemes: userThemesArray,
                  disabledThemes: [],
                };
              })
            : [];
          updateContactList(contactList);
        })
        .catch((err) => console.log('ERROR - ', err));
    } else {
      // exploreArticleRequest({
      //   params: {
      //     source: 'recommended',
      //     isAdmin: isAdmin
      //   }
      // });
    }
  };

  const getThemeIds = (contactListArray) => {
    let theme_ids;
    const contactListCopy = [...contactListArray];
    const selectedContactNames = selectedContacts.map(
      (contact) => contact.name
    );
    theme_ids = contactListCopy
      .filter((contact) => selectedContactNames.includes(contact.name))
      .map((item) => item.themes)
      .flat()
      .map((theme) => (theme.id_hash ? theme.id_hash : []));
    return theme_ids;
  };

  const handleBackButtonClick = () => {
    setSelectedIndex(1);
  };

  const handleSaveClick = () => {
    // setLoading(false);
    //     newsletterSetState({
    //       newsletterArticles: newsletterArticles
    //     })
    //     setSelectedIndex(2);

    setLoading(true);
    let formattedNewsletterArticles = [];
    formattedNewsletterArticles = newsletterArticles.map((element, index) => {
      let obj = {
        article_id: element.id,
      };

      if (element.themeId) {
        obj.theme_id = element.themeId;
      }
      return obj;
    });

    let formattedSelectedContacts = map(selectedContacts, 'id');
    let params = {
      articles: JSON.stringify(formattedNewsletterArticles),
      contactlists: JSON.stringify(formattedSelectedContacts),
    };
    if (ccContacts.length > 0) params.cc_email_ids = JSON.stringify(ccContacts);

    if (!isEmpty(newsletterArticles)) {
      updateNewsletter({ id: newsletterId, params: params })
        .then((res) => {
          if (res.result.success) {
            setLoading(false);
            newsletterSetState({
              newsletterArticles: newsletterArticles,
            });
            setSelectedIndex(3);
          } else {
            setLoading(false);
          }
        })
        .catch((err) => {
          setLoading(false);
        });
    } else {
      setLoading(false);
      newsletterSetState({
        newsletterArticles: newsletterArticles,
      });
      setSelectedIndex(3);
    }
  };

  const handleNextClick = () => {
    const idsArray = selectedNewsletterArticles.map((item) => item.id);
    if (selectedNewsletterArticles.length !== newsletterArticles.length) {
      setTouched(true);
    } else {
      const match = !newsletterArticles.every((item) =>
        idsArray.includes(item.id)
      );
      if (match) setTouched(true);
    }
    newsletterSetState({
      newsletterArticles: newsletterArticles,
    });
    setSelectedIndex(3);
  };

  const handleDrag = (ev) => {
    setDragId(ev.currentTarget.id);
  };

  const handleDrop = (ev) => {
    const dragBox = newsletterArticles.find(
      (article) => article.bucketId === dragId
    );

    const dropBox = newsletterArticles.find(
      (article) => article.bucketId === ev.currentTarget.id
    );

    const dragBoxOrder = dragBox.order;
    const dropBoxOrder = dropBox.order;

    const newBoxState = newsletterArticles.map((box) => {
      if (box.bucketId === dragId) {
        box.order = dropBoxOrder;
      }
      if (box.bucketId === ev.currentTarget.id) {
        box.order = dragBoxOrder;
      }
      return box;
    });

    setNewletterArticles([...newBoxState]);
  };

  const goToDashboard = () => {
    if (isAdmin) {
      history.push('/admin/discover');
    } else {
      history.push('/discover');
    }
  };

  const handleBucketArticleClick = (item, mode = 0) => {
    console.log('NEW TEST LOOP COUNTER - -- - ', testLoopCounter + 1);
    updateTestLoopCounter(testLoopCounter + 1);
    console.log('ITEM - - - - - -', item);
    console.log('MODE - - - - - -', mode);
    if (isEmpty(newsletterArticles)) {
      console.log('- - - - - INSIDE IF - - - - ');
      item.order = 1;
      setNewletterArticles([item]);
      if (mode === 1) {
        setRecommendedArticlesSelected([item]);
        // setRecommendedArticlesSelected([...bucketArticlesSelected, item]);
      }
      if (mode === 2) {
        // setBucketArticlesSelected([...bucketArticlesSelected, item]);
        setBucketArticlesSelected([item]);
      }
    } 
    else if (
      !isEmpty(newsletterArticles) &&
      newsletterArticles.filter((n) => n.bucketId === item.bucketId).length > 0
    ) {
      console.log('- - - - - INSIDE ELSE IF - - - - ');
      const articles = newsletterArticles.filter(
        (n) => n.bucketId !== item.bucketId
      );
      let recArticles = recommendedArticlesSelected.filter(
        (n) => n.bucketId !== item.bucketId
      );
      let bucketArticles = bucketArticlesSelected.filter(
        (n) => n.bucketId !== item.bucketId
      );
      setRecommendedArticlesSelected([...recArticles]);
      setBucketArticlesSelected([...bucketArticles]);
      setNewletterArticles([...articles]);

    } 
    else {
      console.log('- - - - - INSIDE ELSE - - - - ');
      item.order = newsletterArticles.length + 1;
      if (mode === 1) {
        setRecommendedArticlesSelected([...recommendedArticlesSelected, item]);
      }
      if (mode === 2) {
        setBucketArticlesSelected([...bucketArticlesSelected, item]);
      }
      setNewletterArticles([...newsletterArticles, item]);
    }
  };

  const handleSelectAll = (mode) => {
    const bucketArticles = getBucketArticleData(bucketData);
    const recommendedArticles = getBucketArticleData(recommendedData).slice(
      0,
      10
    );
    let finalset;

    if (mode === 1) {
      if (
        isEmpty(newsletterArticles) ||
        recommendedArticlesSelected.length !==
          [...get(recommendedData, 'feed', [])].length
      ) {
        setRecommendedArticlesSelected(recommendedArticles);
        finalset = uniqBy(
          [...bucketArticlesSelected, ...recommendedArticles],
          'id'
        );
        finalset.forEach((element, index) => {
          element.order = index + 1;
        });
        setNewletterArticles(finalset);
      } else {
        setRecommendedArticlesSelected([]);
        setNewletterArticles(bucketArticlesSelected);
      }
    }

    if (mode === 2) {
      if (
        isEmpty(newsletterArticles) ||
        bucketArticlesSelected.length !==
          [...get(bucketData, 'feed', [])].length
      ) {
        setBucketArticlesSelected(bucketArticles);
        finalset = uniqBy(
          [...recommendedArticlesSelected, ...bucketArticles],
          'id'
        );
        finalset.forEach((element, index) => {
          element.order = index + 1;
        });
        setNewletterArticles(finalset);
      } else {
        setBucketArticlesSelected([]);
        setNewletterArticles(recommendedArticlesSelected);
      }
    }
  };

  console.log(newsletterArticles);

  const handleDeleteArticle = (id) => {
    if (
      !isEmpty(newsletterArticles) &&
      newsletterArticles.filter((n) => n.bucketId === id).length > 0
    ) {
      const articles = newsletterArticles.filter((n) => n.bucketId !== id);
      setNewletterArticles([...articles]);
    }

    setDeleteId(id);
    let params = {
      id,
    };

    newsletterBucketDeleteRequest({
      params,
    });
  };

  return (
    <Flex width="100%" padding="50px 25px 0px" flexDirection="column">
      <Flex width="100%" justifyContent="center">
        <Button
          styles={{ border: 'none' }}
          bgColor={
            !showRecommendedArticles && showNewsletterArticles
              ? themesData.teal
              : '#ECECEC'
          }
          color={
            !showRecommendedArticles && showNewsletterArticles
              ? '#fff'
              : themesData.charcoalGrey
          }
          onClick={() => {
            !showNewsletterArticles &&
              setShowNewsletterArticles(!showNewsletterArticles);
            setShowRecommendedArticles(false);
          }}
        >
          In This Newsletter
        </Button>
        <Button
          styles={{ border: 'none' }}
          bgColor={
            !showRecommendedArticles && !showNewsletterArticles
              ? themesData.teal
              : '#ECECEC'
          }
          color={
            !showRecommendedArticles && !showNewsletterArticles
              ? '#fff'
              : themesData.charcoalGrey
          }
          onClick={() => {
            showNewsletterArticles &&
              setShowNewsletterArticles(!showNewsletterArticles);
            setShowRecommendedArticles(false);
          }}
        >
          Available To Include
        </Button>
        <Button
          styles={{ border: 'none' }}
          bgColor={showRecommendedArticles ? themesData.teal : '#ECECEC'}
          color={showRecommendedArticles ? '#fff' : themesData.charcoalGrey}
          onClick={() => {
            // showNewsletterArticles &&
            // setShowNewsletterArticles(!showNewsletterArticles) &&
            setShowRecommendedArticles(true);
            setShowNewsletterArticles(false);
          }}
        >
          Recommended
        </Button>
      </Flex>
      <ArticleListWrapper>
        {showNewsletterArticles ? (
          <NewsLetterArticleWrapper>
            {isEmpty(newsletterArticles) ? (
              <Flex
                width="100%"
                height="100%"
                justifyContent="center"
                padding="0px 0px"
                alignItems="center"
              >
                <CreateNewWrapper width="50%" flexDirection="column">
                  <Flex width="100%" justifyContent="center" pt="5px">
                    <img src="/assets/images/Group 979.svg" alt="new" />
                  </Flex>
                  <Flex width="100%" justifyContent="center" pt="5px">
                    <GetStartedTitle>No Content Selected</GetStartedTitle>
                  </Flex>

                  <Flex width="100%" justifyContent="center">
                    <div
                      style={{
                        color: '#49484A',
                        fontSize: '10px',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                      }}
                    >
                      <span>
                        Select Content to include in this newsletter from the
                        “Available To Include” tab (Content you have chosen to
                        add to newsletters)
                      </span>
                      <span>or</span>
                      <span>
                        “Recommended” tab (recommended by Clout for the
                        recipients of this newsletter).
                      </span>
                    </div>
                  </Flex>
                </CreateNewWrapper>
              </Flex>
            ) : (
              <Flex width="100%" flexDirection="column">
                <div
                  style={{
                    marginTop: '15px',
                    color: '#49484A',
                    fontSize: '10px',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                  }}
                >
                  <label>
                    You can drag-and-drop the tiles to change the order that the
                    content will appear in the newsletter.
                  </label>
                  <Flex
                    width="100%"
                    justifyContent="center"
                    color={themesData.purpleishBlue}
                    style={{ fontWeight: '600', height: '28px' }}
                    // pt="10px"
                  >
                    <ButtonNoBorderBackground
                      onClick={() => {
                        setNewletterArticles([]);
                        setRecommendedArticlesSelected([]);
                        setBucketArticlesSelected([]);
                      }}
                    >
                      Clear All
                    </ButtonNoBorderBackground>
                  </Flex>
                </div>

                <Flex
                  width="100%"
                  justifyContent="space-between"
                  flexWrap="wrap"
                  padding="5px 5px 10px"
                  style={{
                    height: '285px',
                    maxHeight: '285px',
                    overflowX: 'hidden',
                    overflowY: 'auto',
                  }}
                >
                  {newsletterArticles
                    .sort((a, b) => a.order - b.order)
                    .map((item, index) => (
                      <NewsletterArticle
                        key={index}
                        {...item}
                        themeNames={themeNames}
                        checkbox
                        article={item}
                        handleBucketArticleClick={handleBucketArticleClick}
                        newsletterArticles={newsletterArticles}
                        handleDeleteArticle={handleDeleteArticle}
                        draggable
                        deleteBlocked
                        handleDrag={handleDrag}
                        handleDrop={handleDrop}
                      />
                    ))}
                  {newsletterArticles.length % 3 !== 0 && (
                    <i aria-hidden="true"></i>
                  )}
                </Flex>
              </Flex>
            )}
          </NewsLetterArticleWrapper>
        ) : (
          <BucketArticleWrapper>
            <LoaderWrapper isLoading={exploreArticleFetching}>
              {isEmpty(get(bucketData, 'feed', [])) &&
              isEmpty(get(recommendedData, 'feed', [])) ? (
                <Flex width="100%" flexDirection="column" pt="20px">
                  <Flex width="100%" justifyContent="center" pt="25px">
                    <img
                      alt="newsletter-add"
                      src="/assets/images/Newsletter-add.svg"
                      onClick={goToDashboard}
                      style={{ position: 'relative' }}
                    />
                  </Flex>
                  <Flex
                    width="100%"
                    color={themesData.charcoalGrey}
                    fontSize="20px"
                    justifyContent="center"
                    pt="5px"
                  >
                    Add Articles
                  </Flex>
                </Flex>
              ) : (
                <Flex width="100%" flexDirection="column">
                  {showRecommendedArticles ? (
                    <>
                      <div
                        style={{
                          marginTop: '15px',
                          color: '#49484A',
                          fontSize: '10px',
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'center',
                        }}
                      >
                        <label>
                          Recommended by Clout for the recipients of this
                          newsletter, based on the content they’ve engaged with
                          and topics of interest.
                        </label>
                        <Flex
                          width="100%"
                          justifyContent="center"
                          // padding="2px 0px 5px"
                        >
                          <Flex width="100%" justifyContent="center">
                            <NewsletterCheckBoxDiv fontWeight="600">
                              <label
                                style={{ color: themesData.purpleishBlue }}
                                class="container"
                              >
                                <input
                                  type="checkbox"
                                  onChange={() => handleSelectAll(1)}
                                  checked={
                                    newsletterArticles &&
                                    recommendedArticlesSelected.length ===
                                      uniqBy(
                                        [
                                          // ...getBucketArticleData(bucketData),
                                          ...getBucketArticleData(
                                            recommendedData
                                          ).slice(0, 10),
                                        ],
                                        'id'
                                      ).length
                                  }
                                />
                                <span class="checkmark"></span>Select All
                              </label>
                            </NewsletterCheckBoxDiv>
                          </Flex>
                          {/* <Flex
                      width="50%"
                      color={themesData.purpleishBlue}
                      style={{ fontWeight: '600' }}
                      fontSize="14px"
                    >
                      Articles Available To Select For Newsletter
                    </Flex> */}
                        </Flex>
                      </div>
                      <Flex
                        width="100%"
                        flexDirection="column"
                        style={{
                          height: '285px',
                          maxHeight: '285px',
                          overflowX: 'hidden',
                          overflowY: 'auto',
                        }}
                      >
                        {/* <Flex
                          justifyContent="center"
                          style={{ fontWeight: 'bold' }}
                        >
                          Recommended Articles
                        </Flex> */}
                        <Flex
                          width="100%"
                          justifyContent="space-between"
                          flexWrap="wrap"
                          padding="5px 5px 10px"
                        >
                          {recommendedData &&
                            getBucketArticleData(recommendedData)
                              .slice(0, 10)
                              .map((item, index) => (
                                <NewsletterArticle
                                  key={index}
                                  {...item}
                                  themeNames={themeNames}
                                  checkbox
                                  article={item}
                                  handleBucketArticleClick={() => {
                                    handleBucketArticleClick(item, 1);
                                  }}
                                  newsletterArticles={newsletterArticles}
                                  deleteBlocked
                                  userData={userData}
                                  section='NEWSLETTER_ADD_ARTICLE'
                                  channelId={channelId}
                                  popularThemes={popularThemes}
                                />
                              ))}
                          {recommendedData &&
                            get(recommendedData, 'feed', []).length % 3 !==
                              0 && <i aria-hidden="true"></i>}
                        </Flex>
                      </Flex>
                    </>
                  ) : (
                    <>
                      <div
                        style={{
                          marginTop: '15px',
                          color: '#49484A',
                          fontSize: '10px',
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'center',
                        }}
                      >
                        <label>
                          Select Content to include in this newsletter.
                        </label>
                        {!isEmpty(get(bucketData, 'feed', {})) && (
                          <Flex
                          width="100%"
                          justifyContent="center"
                          // padding="2px 0px 5px"
                        >
                          <Flex width="100%" justifyContent="center">
                            <NewsletterCheckBoxDiv fontWeight="600">
                              <label
                                style={{ color: themesData.purpleishBlue }}
                                class="container"
                              >
                                <input
                                  type="checkbox"
                                  onChange={() => handleSelectAll(2)}
                                  checked={
                                    newsletterArticles &&
                                    bucketArticlesSelected.length ===
                                      uniqBy(
                                        [
                                          ...getBucketArticleData(bucketData),
                                          // ...getBucketArticleData(
                                          //   recommendedData
                                          // ).slice(0, 10),
                                        ],
                                        'id'
                                      ).length
                                  }
                                />
                                <span class="checkmark"></span>
                                Select All
                              </label>
                            </NewsletterCheckBoxDiv>
                          </Flex>
                          {/* <Flex
                      width="50%"
                      color={themesData.purpleishBlue}
                      style={{ fontWeight: '600' }}
                      fontSize="14px"
                    >
                      Articles Available To Select For Newsletter
                    </Flex> */}
                        </Flex>
                        )}
                        
                      </div>
                      <Flex
                        width="100%"
                        flexDirection="column"
                        style={{
                          height: '285px',
                          maxHeight: '285px',
                          overflowX: 'hidden',
                          overflowY: 'auto',
                        }}
                      >
                        <Flex
                          width="100%"
                          justifyContent="space-between"
                          flexWrap="wrap"
                          padding="5px 5px 10px"
                        >
                          {bucketData &&
                            getBucketArticleData(bucketData).map(
                              (item, index) => (
                                <NewsletterArticle
                                  key={index}
                                  {...item}
                                  themeNames={themeNames}
                                  checkbox
                                  article={item}
                                  handleBucketArticleClick={() => {
                                    handleBucketArticleClick(item, 2);
                                  }}
                                  newsletterArticles={newsletterArticles}
                                  handleDeleteArticle={handleDeleteArticle}
                                  isDeleting={isDeleting}
                                  deleteId={deleteId}
                                  height="300px"
                                  userData={userData}
                                  section='NEWSLETTER_ADD_ARTICLE'
                                  channelId={channelId}
                                  popularThemes={popularThemes}
                                />
                              )
                            )}
                          {get(bucketData, 'feed', []).length % 3 !== 0 && (
                            <i aria-hidden="true"></i>
                          )}
                        </Flex>
                      </Flex>
                    </>
                  )}
                </Flex>
              )}
            </LoaderWrapper>
          </BucketArticleWrapper>
        )}
      </ArticleListWrapper>

      <Flex
        width="100%"
        alignSelf="flex-end"
        justifyContent="space-between"
        pb="10px"
        pt="5px"
      >
        <LoaderWrapper isLoading={loading}>
          <Flex width="10%">
            <ButtonNoBorderBackground
              padding="5px 0px 0px"
              onClick={handleBackButtonClick}
            >
              <FontAwesomeIcon icon={faAngleLeft} />
              &nbsp; Back
            </ButtonNoBorderBackground>
          </Flex>

          <Flex width="80%" justifyContent="center">
            <Button
              height="36px"
              width="146px"
              margin="10px 10px 0px"
              onClick={handleNextClick}
              disabled={isEmpty(newsletterArticles)}
            >
              Next
            </Button>
          </Flex>

          <Flex width="10%">
            <ButtonNoBorderBackground
              padding="5px 0px 0px"
              onClick={handleSaveClick}
              disabled={isEmpty(newsletterArticles)}
            >
              Save
            </ButtonNoBorderBackground>
          </Flex>
        </LoaderWrapper>
      </Flex>
    </Flex>
  );
};

export default AddArticleTab;
