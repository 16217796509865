import React from 'react';
import './StepStatus.css';

const stepStatus = props => {
    return (
        <div className='numbered-steps'>
            <div className='step' style={{ padding: '2px 6px 0px 5.5px' }}>1</div>
            <div className='horizontal-step-connector'></div>
            <div className='horizontal-step-connector' style={props.stepCompleted < 2 ? { backgroundColor: '#cacaca' } : {}}></div>
            <div className='step' style={props.stepCompleted < 2 ? { backgroundColor: '#adadad' } : {}}>2</div>
            <div className='horizontal-step-connector' style={props.stepCompleted < 2 ? { backgroundColor: '#cacaca' } : {}}></div>
            <div className='horizontal-step-connector' style={props.stepCompleted < 3 ? { backgroundColor: '#cacaca' } : {}}></div>
            <div className='step' style={props.stepCompleted < 3 ? { backgroundColor: '#adadad' } : {}}>3</div>
        </div>
    );
}

export default stepStatus;