import { Flex } from '@rebass/grid';
import React from 'react';
import { Button } from '../../Form';
import { Modal, ModalBody, ModalCloseButton, ModalHeader } from '../../Modal';

const confirmationModal = ({ click, handleModalClose }) => {
  return (
    <Modal
      onRequestClose={handleModalClose}
      shouldCloseOnOverlayClick
      isOpen
      size="small"
    >
      <ModalCloseButton onClick={handleModalClose} />
      <ModalBody>
        <h3>Approve this Content?</h3>
        <Flex justifyContent="center">
          <Button
            onClick={click}
            margin="20px 10px 0px 0px"
            testId="compliance-approve-send"
          >
            Approve
          </Button>
          <Button onClick={handleModalClose} testId="compliance-approve-cancel">
            Cancel
          </Button>
        </Flex>
      </ModalBody>
    </Modal>
  );
};

export default confirmationModal;
