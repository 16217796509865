/* eslint-disable react-hooks/exhaustive-deps */
import { Flex } from '@rebass/grid';
import React, { useEffect, useState } from 'react'
import { Button, DripIconButton, LoaderWrapper, ModalBody, ModalCloseButton, ModalHeader, ModalV2 } from 'src/components';
import Checkbox from 'src/componentsV2/Checkbox';
import { ColorPallete, Typography, convertDateToRequiredFormat, timeZoneAbbreviated } from 'src/utils';
import { DateTimeText, DateTimeWrapper } from '../Preview&Schedule/PreviewAndSchedule.styles';
import { StyledTextInput } from 'src/containers/AutomatedCampaigns/styles';
import { get } from 'lodash';
import { createUpdatePageSettings, getLandingPageSettings, stopRegistrationLp } from 'src/services/bee-editor';
import AlertBox from 'src/componentsV2/AlertBox/AlertBox';
import { connect } from 'react-redux';
import DatePickerV2 from 'src/components/DatePickerV2/DatePickerV2';

const SettingsModal = ({
    userData,
    isOpen,
    id,
    type,
    showManual,
    onClose
}) => {
    const [settingsLoader, setSettingsLoader] = useState(false);
    const [showTime, setShowTime] = useState(false);
    const [showCount, setShowCount] = useState(false);
    const [count, setCount] = useState(0);
    const [eventDate, setEventDate] = useState();
    const [isDatepickerOpen, setIsDatepickerOpen] = useState(false);
    const [message, setMessage] = useState('');
    const [stopRegistration, setStopRegistration] = useState(false);
    const [showSettings, setShowSettings] = useState(false);
    const [settingsData, setSettingsData] = useState();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setSettingsLoader(true);
        getLandingPageSettings({ campaignId: id }).then(res => {
            setSettingsLoader(false);
            if (get(res, 'data.stop_registration', false)) {
                setShowSettings(false);
                setMessage('This Campaign has registration turned off');
            } else {
                setSettingsData(res.data);
                setShowSettings(true);
                setCount(get(res, 'data.number_of_registrants', 0));
                setShowCount(get(res, 'data.consider_count', false));
                setShowTime(get(res, 'data.consider_date', false));
                setEventDate(get(res, 'data.event_date', '').split('.')[0]);
            }
        }).catch(err => {
            console.log(err);
            setSettingsLoader(false);
        });
    }, []);

    const handleStopRegistration = (value) => {
        if (value) {
            setMessage('Once the registration is stopped it cannot be switched on again')
        }
        setStopRegistration(value);

    }

    const handleValidation = () => {
        if (stopRegistration) {
            return false;
        }
        if (!showTime && !showCount) {
            return false;
        }
        if (showTime) {
            if (eventDate) {
                return false;
            } else {
                return true;
            }
        }

        if (showCount) {
            if (count && count > 0 && count < 5001) {
                return false;
            } else {
                return true;
            }
        }
    }

    const handleSubmit = () => {
        setLoading(true)
        if (showManual && stopRegistration) {
            stopRegistrationLp(id).then(res => {
                setLoading(false);
                onClose();
            }).catch(err => {
                setLoading(false);
                setMessage('Oops! Something went wrong!')
            })
        } else {
            let selectedCampaignId = id;
                let payload = {
                    consider_count: showCount,
                    consider_date: showTime,
                    page_id: get(settingsData, 'page_id', ''),
                    time_zone: get(
                        userData,
                        'details.user.timezone',
                        ''
                    )
                }
                if(showCount) {
                    payload.no_of_registrants = count;
                }
                if(showTime) {
                    payload.event_date = eventDate
                }
                createUpdatePageSettings(selectedCampaignId, payload).then(res => {
                    setLoading(false);
                    onClose();
                }).catch(err => {
                    setLoading(false);
                    setMessage('Oops! Something went wrong!')
                })
        }


    }

    return (
        <ModalV2
            isOpen={isOpen}
            bgColor="#fff"
            width="60%"
            onRequestClose={onClose}
            // shouldCloseOnOverlayClick
        >
            <ModalHeader fontColor={ColorPallete.black}>
                Edit Registration Settings
                <ModalCloseButton top="15px" right="15px" size="20px" color={ColorPallete.black} onClick={onClose}/>
            </ModalHeader>
            <ModalBody>
                <Flex flexDirection="column" padding="0px 10px" height="auto">
                    <LoaderWrapper isLoading={settingsLoader}>
                        {showSettings ? (
                            <>
                                <Flex margin="10px 0px" width='100%' alignItems='center' color={ColorPallete.neutral900} fontSize={Typography.TextMedium}>
                                    <Checkbox checked={showTime} onClick={() => setShowTime(!showTime)} />
                                    <Flex pl="9px">
                                        Close Registration after closing date
                                    </Flex>
                                </Flex>
                                {showTime && (
                                    <DateTimeWrapper width="35%" color={ColorPallete.black} style={{
                                        border: `1px solid #8493a6`
                                    }}>
                                        <DateTimeText>
                                            {eventDate &&
                                                convertDateToRequiredFormat({
                                                    date: eventDate,
                                                    format: 'MM/DD/YYYY h:mm a',
                                                }) + ' (' + timeZoneAbbreviated(get(
                                                    userData,
                                                    'details.user.timezone',
                                                    ''
                                                )) + ')'}
                                        </DateTimeText>
                                        <DripIconButton
                                            onClick={() => setIsDatepickerOpen(true)}
                                            icon="clock"
                                            color="#8493A6"
                                            styles={{ marginTop: '5px' }}
                                            forAutomatedCampaigns={true}
                                        />
                                        <DatePickerV2 
                                            isOpen={isDatepickerOpen}
                                            customInput={<div />}
                                            minDate={new Date()}
                                            onDateClose={() => {
                                              setIsDatepickerOpen(false);
                                              
                                            }}
                                            onSubmit={(date) => {
                                                setEventDate(date);
                                                setIsDatepickerOpen(false);
                                            }}
                                            timeZone={get(
                                              userData,
                                              'details.user.timezone',
                                              ''
                                            )}
                                            scheduleValue={eventDate}
                                            isFromAC
                                            hourAndMins
                                        />
                                        {/* <MioDatePicker
                                            isOpen={isDatepickerOpen}
                                            customInput={<div />}
                                            minDate={new Date()}
                                            onDateClose={() => setIsDatepickerOpen(false)}
                                            onSubmit={(date) => {
                                                setEventDate(moment(date).format('YYYY/MM/DD'));
                                                setIsDatepickerOpen(false);
                                            }}
                                            timeZone={get(userData, 'details.user.timezone', '')}
                                            scheduleValue={eventDate ? new Date(eventDate) : ''}
                                            isFromCampaign
                                            isFromAC
                                        /> */}
                                    </DateTimeWrapper>
                                )}
                                <Flex margin="10px 0px" width='100%' alignItems='center' color={ColorPallete.neutral900} fontSize={Typography.TextMedium}>
                                    <Checkbox checked={showCount} onClick={() => setShowCount(!showCount)} />
                                    <Flex pl="9px">
                                        Restrict Number of Registrants
                                    </Flex>
                                </Flex>
                                {showCount && (
                                    <StyledTextInput
                                        style={{
                                            width: '35%'
                                        }}
                                        type="number"
                                        placeholder="Registrants Count"
                                        value={count}
                                        onChange={(e) => setCount(e.target.value)}
                                        name="registrants_count"
                                    />
                                )}

                                {showCount && (
                                    <Flex pt="3px">
                                        You can set the limit up to 5000
                                    </Flex>
                                )}

                                {showManual && (
                                    <Flex margin="10px 0px" width='100%' alignItems='center' color={ColorPallete.neutral900} fontSize={Typography.TextMedium}>
                                        <Checkbox checked={stopRegistration} onClick={() => handleStopRegistration(!stopRegistration)} />
                                        <Flex pl="9px">
                                            Stop Registration
                                        </Flex>
                                    </Flex>
                                )}

                                {stopRegistration && message && (
                                    <AlertBox severity='warning' message={message} />
                                )}

                                <Flex margin="10px 0px" justifyContent="center">
                                    <LoaderWrapper isLoading={loading}>
                                        <Flex justifyContent="center">
                                            <Button bgColor="#fff" borderRadius="4px" color={ColorPallete.black} margin="0px 10px" onClick={onClose}>
                                                Cancel
                                            </Button>
                                            <Button disabled={handleValidation()} borderRadius="4px" margin="0px" onClick={handleSubmit}>
                                                Save
                                            </Button>
                                        </Flex>
                                    </LoaderWrapper>
                                </Flex>
                            </>
                        ) : (
                            <AlertBox severity='warning' message={message} />
                        )}
                    </LoaderWrapper>
                </Flex>
            </ModalBody>
        </ModalV2>
    )
}

const mapStateToProps = state => {
    return {
        userData: state?.user?.data
    }
}

export default connect(mapStateToProps, null)(SettingsModal);