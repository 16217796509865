import { Flex } from '@rebass/grid';
import React, { useState } from 'react';
import {
  CampaignFilterButton,
  VerticalSeparator,
} from './DripDashboardFilter.styles';
import DripDashboardFilterPopup from './DripDashboardFilterPopup/DripDashboardFilterPopup';
import DripDashboardFilterTab from './DripDashboardFilterTab/DripDashboardFilterTab';
import { isEmpty } from 'lodash';

const DripDashboardFilter = ({
  createFlow,
  setCreateFlow,
  filterTabs,
  filterBtns,
  selectedCampaignTab,
  setSelectedCampaignTab,
  setCampaignDetailsView,
  campaignDetailsView,
  setFilters,
  filters,
  campaignRequest,
  changeCreateFlow,
  isadvMode,
  userData,
  isEnterpriseAdvisor,
}) => {
  // console.log('isadvMode', isadvMode);
  const [filterModal, toggleFilterModal] = useState(false);
  return (
    <Flex
      width="100%"
      padding="20px 50px"
      justifyContent="space-between"
      backgroundColor="#E4E8EB"
    >
      {filterTabs.map((tab, index) => (
        <DripDashboardFilterTab
          key={index}
          isadvMode={isadvMode}
          tab={tab}
          selected={selectedCampaignTab === tab.id}
          click={() => {
            setSelectedCampaignTab(tab.id);
            setCampaignDetailsView(false);
          }}
          totalTabs={filterTabs?.length}
        />
      ))}
      {/* <VerticalSeparator /> */}
      {filterBtns.map((btn, index) => (
        <CampaignFilterButton
          key={index}
          isadvMode={isadvMode}
          data-testid={'topTab-Filter'}
          filterBtn={true}
          selected={index === 0 && !isEmpty(filters)}
          onClick={
            index === 1
              ? () => {
                  console.log('TOGGLE CREATE FLOW - - -');
                  changeCreateFlow(true);
                }
              : () => {
                  toggleFilterModal(true);
                }
          }
          isEnterpriseAdvisor={isEnterpriseAdvisor}
        >
          <Flex alignItems="center" justifyContent="center" height="100%">
            <img alt="" src={btn.img} style={{ marginRight: !isEnterpriseAdvisor ? '7px' : '' }} />
            {!isEnterpriseAdvisor && btn.name}
          </Flex>
        </CampaignFilterButton>
      ))}
      {filterModal && (
        <DripDashboardFilterPopup
          handleModalClose={() => {
            toggleFilterModal(false);
          }}
          setFilters={setFilters}
          filters={filters}
          campaignRequest={campaignRequest}
          setSelectedCampaignTab={setSelectedCampaignTab}
          isadvMode={isadvMode}
          userData={userData}
        />
      )}
    </Flex>
  );
};

export default DripDashboardFilter;
