import React, { useRef, useEffect } from 'react';
import { Flex } from '@rebass/grid';
import { get, isEmpty } from 'lodash';
import {
  Table,
  TableTitle,
  Tr,
  Th,
  Td,
  TableHeading,
} from '../../../components';
import { MemberTableBody } from './MembersTab.styles';

const RegisteredMembers = ({ data, label, noDataInfo, isInvitedMembers }) => {
  const tablBodyRef = useRef(null);
  useEffect(() => {
    if (tablBodyRef.current) {
      tablBodyRef.current.scrollTop = 0;
    }
  }, [data]);
  return (
    <Table>
      <TableTitle>{label}</TableTitle>
      <TableHeading>
        <Tr alternate={false}>
          <Th width={isInvitedMembers ? '50%' : '20%'} align='center'>Name</Th>
          {!isInvitedMembers && <Th width='40%' align='center'>Email</Th>}
          {!isInvitedMembers && <Th width='15%' align='center' style={{padding: '20px 0px'}}>Account Type</Th>}
          <Th width={isInvitedMembers ? '50%' : '15%'}>Clout</Th>
          <Th width='15%' align='center'>Actions</Th>

        </Tr>
      </TableHeading>
      <MemberTableBody ref={tablBodyRef}>
        {isEmpty(data) && (
          <Tr>
            <Td>{noDataInfo}</Td>
          </Tr>
        )}
        {data.map((memeber) => {
          return (
            <Tr>
              <Td align="center" 
                title={!isInvitedMembers && (get(memeber, 'details.name.first') + ' ' + get(memeber, 'details.name.last')).length > 25 ? get(memeber, 'details.name.first') + ' ' + get(memeber, 'details.name.last') : ''} 
                width={isInvitedMembers ? '50%' : '25%'} 
                style={{whiteSpace: 'nowrap', padding: isInvitedMembers ? '15px' : '15px 10px', fontSize: isInvitedMembers ? '12px' : '10px'}}>
                {isInvitedMembers
                  ? get(memeber, 'details.email')
                  : (get(memeber, 'details.name.first') + ' ' + get(memeber, 'details.name.last')).length > 25 ? (get(memeber, 'details.name.first') + ' ' + get(memeber, 'details.name.last')).substring(0, 25) + '...' : get(memeber, 'details.name.first') + ' ' + get(memeber, 'details.name.last')}
              </Td>
              {!isInvitedMembers && 
                <Td width='30%' 
                  style={{whiteSpace: 'nowrap', fontSize: '10px'}} 
                  title={get(memeber, 'details.email', '').length > 30 ? get(memeber, 'details.email', '') : ''} 
                  align="center">
                    {get(memeber, 'details.email', '').length > 30 ? 
                      get(memeber, 'details.email', '').substring(0, 30) + '...' 
                      : get(memeber, 'details.email', '')}
                </Td>
              }
              {!isInvitedMembers && <Td width='20%' align="center" style={{fontSize: '10px'}}>{get(memeber, 'details.role')==='general' ? 'member' : get(memeber, 'details.role')}</Td>}
              <Td width={isInvitedMembers ? '50%' : '10%'} align="center" style={{fontSize: '10px'}}>
                {isInvitedMembers 
                  ? get(memeber, 'details.score')
                  ? get(memeber, 'details.score')
                  : '--'
                  : get(memeber, 'details.score')
                }
              </Td>
              <Td width='15%' align='center'>
                <img style={{ paddingRight: '12px', cursor: 'pointer', height: '14px', }} alt="" src="/assets/images/edit_icon.svg" />
                <img style={{ cursor: 'pointer', height: '15px', }} alt="" src="/assets/images/delete.svg" />
              </Td>
            </Tr>
          );
        })}
      </MemberTableBody>
    </Table>
  );
};

export default RegisteredMembers;
