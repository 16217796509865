import { styled } from '@mui/material';

export const PaginationContainer = styled('div')(() => ({
  border: '1px solid #E4E7EC',
  background: '#FCFDFD',
  width: '100%',
  borderBottomLeftRadius: '0.5rem',
  borderBottomRightRadius: '0.5rem',
}));

export const TopicsEngagementTableWrapper = styled('div')(() => ({
  boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.12)',
  border: '1px solid #E4E7EC',
  background: '#fff',
  borderRadius: '0.5rem',
  width: '100%',
  marginTop: '30px',
}));

export const TopicsEngagementTableHeader = styled('div')(() => ({
  padding: '1rem',
  borderBottom: '1px solid #E4E7EC',
  h2: {
    color: '#101828',
    font: '500 1.25rem Poppins, sans-serif',
    margin: 0,
    textAlign: 'left',
  },
  p: {
    color: '#475467',
    font: '500 0.875rem Poppins, sans-serif',
    margin: 0,
    textAlign: 'left',
  },
}));

export const TableBody = styled('p')(({ cursor = 'default' }) => ({
  font: '500 0.875rem Poppins,sans-serif',
  margin: 0,
  textAlign: 'left',
  color: '#101828',
  cursor: cursor,
}));

export const EngagementScoreColumn = styled('p')(() => ({
  font: '500 1.25rem Poppins,sans-serif',
  margin: 0,
  textAlign: 'left',

  '&.above-fifty': {
    color: '#261A83',
  },

  '&.below-fifty': {
    color: '#816EF8',
  },
}));

export const TruncatedTableBody = styled(TableBody)(() => ({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitLineClamp: 2,
  WebkitBoxOrient: 'vertical',
}));

export const TooltipStyles = {
  '& .MuiTooltip-tooltip': {
    boxShadow: '0px 0px 100px rgba(63, 106, 194, 0.15)',
    padding: '10px 5px',
    border: '1px solid #ccc',
    background: '#fff',
    borderRadius: '4px',
    color: '#101828',
    font: '500 0.875rem Poppins,sans-serif',
  },
};
