import React, { useState } from 'react'
import { Button, LoaderWrapper, ModalBody, ModalCloseButton, ModalV2 } from '../../components';
import { Flex } from '@rebass/grid';
import { ColorPallete } from '../../utils';
import Checkbox from '../../componentsV2/Checkbox';
import { isEmpty } from 'lodash';
import { ScrollWrapper } from '../../componentsV2';

const CascadeModal = ({
  onClose,
  isModalOpen,
  title,
  subTitle,
  isEnterpriseAdmin,
  advisorList,
  firmsArray,
  OGSelectedAdvisors,
  selectedAdvisors,
  handleSelectedAdvisors,
  handleSelectedAdmin,
  selectedAdmin,
  selectedFirmAdvisors,
  handleSelectedFirmAdvisors,
  onSubmit,
  loading,
  disable,
  message
}) => {

  const [selectedTab, setSelectedTab] = useState('advisor');

  console.log('advisorList', advisorList, firmsArray);

  const handleTabChange = tab => {
    setSelectedTab(tab);
  }
  return (
    <ModalV2
      onRequestClose={onClose}
      shouldCloseOnOverlayClick
      isOpen={isModalOpen}
      bgColor="white"
      width="60%"
    >
      <ModalCloseButton onClick={onClose} />
      <ModalBody>
        <Flex pl="10px" width="100%" fontSize="20px" color={ColorPallete.black}>
          {title}
        </Flex>
        {subTitle && (
          <Flex pl="10px" width="100%" fontSize="14px" color={ColorPallete.neutral500} mt="5px">
            {subTitle}
          </Flex>
        )}

        <Flex mt="10px" width="100%" flexDirection="column" style={{ border: '1px solid #E4E7EC', borderRadius: '8px' }}>
          <Flex padding="10px 10px 15px" color={ColorPallete.neutral900} fontSize="20px">
            Select Advisors
          </Flex>
          <Flex style={{ borderTop: '1px solid #E4E7EC' }}>
            <Flex
              padding="10px"
              fontSize="14px"
              color={ColorPallete.neutral900}
              onClick={() => handleTabChange('advisor')}
              style={{ cursor: 'pointer', borderBottom: selectedTab === 'advisor' ? '3px solid #000000' : 'none' }}
            >
              Advisors
            </Flex>
            {isEnterpriseAdmin && (
              <>
                <Flex
                  padding="10px"
                  fontSize="14px"
                  color={ColorPallete.neutral900}
                  onClick={() => handleTabChange('admin')}
                  style={{ cursor: 'pointer', borderBottom: selectedTab === 'admin' ? '3px solid #000000' : 'none' }}
                >
                  Administrators
                </Flex>
                <Flex
                  padding="10px"
                  fontSize="14px"
                  color={ColorPallete.neutral900}
                  onClick={() => handleTabChange('firm')}
                  style={{ cursor: 'pointer', borderBottom: selectedTab === 'firm' ? '3px solid #000000' : 'none' }}
                >
                  Firms
                </Flex>
              </>
            )}
          </Flex>
          {selectedTab === 'advisor' && (
            <>
              <Flex alignItems="center" padding="10px" backgroundColor={ColorPallete.neutral50} style={{ borderBottom: '1px solid #E4E7EC' }}>
                <Flex width="5%">
                  <Checkbox checked={selectedAdvisors.length === advisorList.length} onClick={() => handleSelectedAdvisors('all')}/>
                </Flex>
                <Flex width="45%" color={ColorPallete.neutral900}>
                  Name
                </Flex>
                <Flex width="50%" color={ColorPallete.neutral900}>
                  Email
                </Flex>
              </Flex>
              {!isEmpty(advisorList) && (
                <ScrollWrapper height="300px" maxHeight="300px">
                  {advisorList.map((item, index, arr) => (
                    <Flex padding="10px" key={item.index} width="100%" style={{ borderBottom: arr.length === index + 1 ? 'none' : '1px solid #E4E7EC' }}>
                      <Flex width="5%">
                        <Checkbox checked={selectedAdvisors.includes(item.id)} onClick={() => handleSelectedAdvisors(item.id)}/>
                      </Flex>
                      <Flex width="45%" color={ColorPallete.neutral900}>
                        {item?.details?.name?.first} {item?.details?.name?.last}
                      </Flex>
                      <Flex width="50%" color={ColorPallete.neutral900}>
                        {item?.details?.name?.email}
                      </Flex>
                    </Flex>
                  ))}
                </ScrollWrapper>
              )}
            </>
          )}

          {selectedTab === 'admin' && (
            <>
              <Flex alignItems="center" padding="10px" backgroundColor={ColorPallete.neutral50} style={{ borderBottom: '1px solid #E4E7EC' }}>
                <Flex width="5%">
                  <Checkbox checked={selectedAdmin.length === firmsArray.length} onClick={() => handleSelectedAdmin('all')}/>
                </Flex>
                <Flex width="95%" color={ColorPallete.neutral900}>
                  Firm
                </Flex>
              </Flex>
              {!isEmpty(firmsArray) && (
                <ScrollWrapper height="300px" maxHeight="300px">
                  {firmsArray.map((item, index, arr) => (
                    <Flex padding="10px" key={item.index} width="100%" style={{ borderBottom: arr.length === index + 1 ? 'none' : '1px solid #E4E7EC' }}>
                      <Flex width="5%">
                        <Checkbox checked={selectedAdmin.includes(item.id)} onClick={() => handleSelectedAdmin(item.id)}/>
                      </Flex>
                      <Flex width="95%" color={ColorPallete.neutral900}>
                        {item?.name}
                      </Flex>
                    </Flex>
                  ))}
                </ScrollWrapper>
              )}
            </>
          )}

          {selectedTab === 'firm' && (
            <>
              <Flex alignItems="center" padding="10px" backgroundColor={ColorPallete.neutral50} style={{ borderBottom: '1px solid #E4E7EC' }}>
                <Flex width="5%">
                  <Checkbox checked={selectedFirmAdvisors.length === firmsArray.length} onClick={() => handleSelectedFirmAdvisors('all')}/>
                </Flex>
                <Flex width="95%" color={ColorPallete.neutral900}>
                  Firm
                </Flex>
              </Flex>
              {!isEmpty(firmsArray) && (
                <ScrollWrapper height="300px" maxHeight="300px">
                  {firmsArray.map((item, index, arr) => (
                    <Flex padding="10px" key={item.index} width="100%" style={{ borderBottom: arr.length === index + 1 ? 'none' : '1px solid #E4E7EC' }}>
                      <Flex width="5%">
                        <Checkbox checked={selectedFirmAdvisors.includes(item.id)} onClick={() => handleSelectedFirmAdvisors(item.id)}/>
                      </Flex>
                      <Flex width="95%" color={ColorPallete.neutral900}>
                        {item?.name}
                      </Flex>
                    </Flex>
                  ))}
                </ScrollWrapper>
              )}
            </>
          )}

          <Flex width="100%" justifyContent="center">
            <LoaderWrapper isLoading={loading}>
              <Button
                margin="10px 20px 10px 0px"
                bgColor={ColorPallete.white}
                color={ColorPallete.primaryBlue}
                borderRadius="4px"
                borderColor={ColorPallete.pink}
                onClick={onClose}
              >
                Cancel
              </Button>
              <Button
                margin="10px 0px"
                bgColor={ColorPallete.primaryBlue}
                color={ColorPallete.white}
                onClick={onSubmit}
                disabled={disable}
                borderRadius="4px"
                borderColor={ColorPallete.primaryBlue}
              >
                Save
              </Button>
            </LoaderWrapper>
          </Flex>
          {message && (
            <Flex width="100%" justifyContent="center" fontSize="14px" color={ColorPallete.neutral500} mt="5px">
              {message}
            </Flex>
          )}
        </Flex>

      </ModalBody>

    </ModalV2>
  )
}

export default CascadeModal;