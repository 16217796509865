import React, { useState } from 'react';
import { Flex } from '@rebass/grid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { IconWrapper } from '../../../../components/ListItemTile/ListItemTile.styles';
import { LoaderWrapper } from '../../../../components';

const ResultPopular = ({ trendingTopics, popularTopics, shortenTitle, loader, setLoader, subscribe }) => {
    
    const [showTrendingGhostBtn, toggleTrendingGhostBtn] = useState('');
    const [showPopularGhostBtn, togglePopularGhostBtn] = useState('');

    console.log('TRENDING - ', trendingTopics);
    console.log('POPULAR - ', popularTopics);
    return (
        <div style={{width: '95%', margin: 'auto', display: 'flex', justifyContent: 'space-evenly', alignItems: 'baseline'}}>
            <div 
                className='trending-topics'
                style={{
                    backgroundColor: '#fff',
                    borderRadius: '6px', 
                    borderTopLeftRadius: '10px', 
                    borderTopRightRadius: '10px', 
                    width: '45%'
                }}
            >
                <div 
                    className='popular-tab-div-header'
                    style={{
                        fontSize: '14px', 
                        marginTop: '-1px',
                        borderTopLeftRadius: '10px', 
                        borderTopRightRadius: '10px', 
                        border: '0px',
                        padding: '10px'
                    }}
                >
                    Trending Topics
                </div>
                <div 
                    className='popular-tab-div-contents' 
                    style={{border: '0px', backgroundColor: '#fcfcfc', maxHeight: '210px', overflowY: 'auto', textAlign: 'center', fontSize: '10px' }}
                >
                    {trendingTopics.length>0 ? (
                        <ul 
                            style={{
                                display: 'inline-block',
                                width: '100%',
                                listStyleType: 'none',
                                overflow: 'hidden auto',
                                margin: '25px auto 10px',
                                textAlign: 'left',
                                paddingLeft: '20px'
                            }}
                        >
                            {trendingTopics.slice(0, 6).map(topic => (
                                <div 
                                    style={{display: 'inline-block', marginBottom: '10px', position: 'relative'}}
                                    onMouseEnter={() => toggleTrendingGhostBtn(topic.name)} 
                                    onMouseLeave={() => toggleTrendingGhostBtn('')}
                                >
                                    <Flex 
                                        justifyContent='flex-start' 
                                        alignItems='center' 
                                        style={{
                                            border: '0px', 
                                            borderRadius: '4px', 
                                            backgroundColor: '#ececec', 
                                            color: '#6351ed', 
                                            fontSize: '10px',
                                            padding: '5px 10px 5px 1px',
                                            margin: '0px 10px',
                                            height: '40px',
                                            minWidth: '115px',
                                            maxWidth: '115px',
                                            cursor: 'pointer'
                                        }}
                                    >
                                        <img 
                                            src={topic.media_urls[0].path + topic.media_urls[0].id + '.' + topic.media_urls[0].ext}
                                            style={{
                                                width: '32px', 
                                                height: '32px', 
                                                borderRadius: '4px',
                                                margin: '0px 4px'
                                            }} 
                                        />
                                        <LoaderWrapper isLoading={loader === topic.name}>
                                            <p>{shortenTitle(topic.name)}</p>
                                        </LoaderWrapper>
                                    </Flex>
                                    {showTrendingGhostBtn===topic.name && !loader ? (
                                        <div 
                                            style={{ 
                                                position: 'absolute', 
                                                backgroundColor: '#fffc', 
                                                height: '40px', 
                                                width: '125px', 
                                                top: '0px', 
                                                left: '6px', 
                                                borderRadius: '4px',
                                                fontSize: '10px', 
                                                cursor: 'pointer',
                                                display: 'flex',
                                                justifyContent: 'flex-start',
                                                alignItems:'center',
                                                paddingLeft: '17px'
                                            }}
                                            onClick={() => {
                                                setLoader(topic.name);
                                                subscribe(topic);
                                            }}
                                        >
                                            <IconWrapper style={{marginBottom: '9px', marginRight: '10px'}}>
                                                <FontAwesomeIcon icon={faPlus} />
                                            </IconWrapper>
                                            <p>{topic.name}</p>
                                        </div>
                                    ) : null}
                                </div>
                            ))}
                        </ul> 
                    ) : 'No Topics found' }
                </div>
            </div>
            <div 
                className='most-subscribed'
                style={{
                    backgroundColor: '#fff',
                    borderRadius: '6px', 
                    borderTopLeftRadius: '10px', 
                    borderTopRightRadius: '10px', 
                    width: '45%'
                }}
            >
                <div 
                    className='popular-tab-div-header'
                    style={{
                        fontSize: '14px', 
                        marginTop: '-1px',
                        borderTopLeftRadius: '10px', 
                        borderTopRightRadius: '10px', 
                        border: '0px',
                        padding: '10px'
                    }}
                >
                    Most Subscribed
                </div>
                <div 
                    className='popular-tab-div-contents' 
                    style={{ border: '0px', backgroundColor: '#fcfcfc', maxHeight: '210px', overflowY: 'auto', textAlign: 'center', fontSize: '10px' }}
                >
                    {popularTopics.length>0 ? (
                        <ul 
                            style={{
                                display: 'inline-block',
                                width: '100%',
                                listStyleType: 'none',
                                overflow: 'hidden auto',
                                margin: '25px auto 10px',
                                textAlign: 'left',
                                paddingLeft: '20px'
                            }}
                        >
                            {popularTopics.map(topic => (
                                <div 
                                    style={{ display: 'inline-block', marginBottom: '10px', position: 'relative' }}
                                    onMouseEnter={() => togglePopularGhostBtn(topic.name)} 
                                    onMouseLeave={() => togglePopularGhostBtn('')}
                                >
                                    <Flex 
                                        justifyContent='flex-start' 
                                        alignItems='center' 
                                        style={{
                                            border: '0px', 
                                            borderRadius: '4px', 
                                            backgroundColor: '#ececec', 
                                            color: '#6351ed', 
                                            fontSize: '10px',
                                            padding: '5px 10px 5px 1px',
                                            margin: '0px 10px',
                                            height: '40px',
                                            minWidth: '115px',
                                            maxWidth: '115px',
                                            cursor: 'pointer'
                                        }}
                                    >
                                        <img 
                                            src={topic.media_urls[0].path + topic.media_urls[0].id + '.' + topic.media_urls[0].ext}
                                            style={{
                                                width: '32px', 
                                                height: '32px', 
                                                borderRadius: '4px',
                                                margin: '0px 4px'
                                            }} 
                                        />
                                        <LoaderWrapper isLoading={loader === topic.name}>
                                            <p>{shortenTitle(topic.name)}</p>
                                        </LoaderWrapper>
                                    </Flex>
                                    {showPopularGhostBtn===topic.name && !loader ? (
                                        <div 
                                            style={{ 
                                                position: 'absolute', 
                                                backgroundColor: '#fffc', 
                                                height: '40px', 
                                                width: '125px', 
                                                top: '0px', 
                                                left: '6px', 
                                                borderRadius: '4px',
                                                fontSize: '10px', 
                                                cursor: 'pointer',
                                                display: 'flex',
                                                justifyContent: 'flex-start',
                                                alignItems:'center',
                                                paddingLeft: '17px'
                                            }}
                                            onClick={() => {
                                                setLoader(topic.name);
                                                subscribe(topic);
                                            }}
                                        >
                                            <IconWrapper style={{marginBottom: '9px', marginRight: '10px'}}>
                                                <FontAwesomeIcon icon={faPlus} />
                                            </IconWrapper>
                                            <p>{topic.name}</p>
                                        </div>
                                    ) : null}
                                </div>
                            ))}
                        </ul>
                    ) : 'No Topics found'}
                </div>
            </div>
        </div>
    );
}

export default ResultPopular;