import React from 'react';
import ProfessionalResearchContainer from '../../containers/ProfessionalResearchContainer';

import withContentLayout from '../../hoc/withContentLayout';

const ProfessionalResearch = () => {
    return (
        <ProfessionalResearchContainer />
    )
}

export default withContentLayout(ProfessionalResearch);
