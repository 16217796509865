/* eslint-disable react-hooks/exhaustive-deps */
import { Flex } from '@rebass/grid';
import { get, isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalHeader,
} from '../../../components';
import { company, getMediaUrl, themesData } from '../../../utils';
import { NewsletterArticle } from '../NewsletterArticle';
import {
  MessageWrapper,
  SignatureSection,
} from '../PreviewTab/PreviewTab.styles';
import parse from 'html-react-parser';

const PreviewNewsletterModal = ({
  open,
  setOpen,
  newsletterName,
  newsletterArticles,
  customizeData,
  userData,
  themeNames,
  emailTemplateSelected,
  channelId,
  popularThemes
}) => {
  
  const [salutationCss, setSalutationCss] = useState({});
  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    let parsedMessage =
      customizeData && customizeData.message && parse(customizeData.message);
    parsedMessage = Array.isArray(parsedMessage) ? parsedMessage[0] : parsedMessage;
    let salutationStyle;
    if (
      parsedMessage && 
      parsedMessage.props &&
      parsedMessage.props.style
    ) {
      salutationStyle = parsedMessage.props.style;
      if(parsedMessage.props.children && 
        parsedMessage.props.children[0] && 
        parsedMessage.props.children[0].type && 
        parsedMessage.props.children[0].props.style) {
          salutationStyle = {...salutationStyle, ...parsedMessage.props.children[0].props.style};
          if(parsedMessage.props.children[0].props.children && 
            parsedMessage.props.children[0].props.children[0] && 
            parsedMessage.props.children[0].props.children[0].props && 
            parsedMessage.props.children[0].props.children[0].props.type &&
            parsedMessage.props.children[0].props.children[0].props.style
          ) {
            salutationStyle = {...salutationStyle, ...parsedMessage.props.children[0].props.children[0].props.style};
          }
      }
      setSalutationCss(salutationStyle);
    }
  }, []);

  const signature =
    userData &&
    userData.details &&
    userData.details.user &&
    userData.details.user.email_personalization &&
    userData.details.user.email_personalization.signature;

  const footerText =
    userData &&
    userData.details &&
    userData.details.user &&
    userData.details.user.email_personalization &&
    userData.details.user.email_personalization.footer_text;

  const personalizeImage =
    userData &&
    userData.details &&
    userData.details.user &&
    userData.details.user.email_personalization &&
    userData.details.user.email_personalization.media_urls;

  const editorImage =
    userData &&
    userData.details &&
    userData.details.user &&
    userData.details.user.personalization &&
    userData.details.user.personalization.corporate_identity &&
    userData.details.user.personalization.corporate_identity.logo &&
    userData.details.user.personalization.corporate_identity.logo.main &&
    userData.details.user.personalization.corporate_identity.logo.main
      .media_urls;

  const imageWidth =
    userData &&
    userData.details &&
    userData.details.user &&
    userData.details.user.email_personalization &&
    userData.details.user.email_personalization.image_width;

  const imageHeight =
    userData &&
    userData.details &&
    userData.details.user &&
    userData.details.user.email_personalization &&
    userData.details.user.email_personalization.image_height;

  return (
    <Modal
      type="sponsored"
      size="medium800"
      onRequestClose={handleClose}
      shouldCloseOnOverlayClick
      isOpen={open}
    >
      <ModalHeader
        id="header"
        style={{ textAlign: 'center', color: themesData.charcoalGrey }}
      >
        <ModalCloseButton onClick={handleClose} />
        Newsletter Preview
      </ModalHeader>

      <ModalBody
        style={{
          height: '500px',
          maxHeight: '500px',
          overflowX: 'hidden',
          overflowY: 'auto',
        }}
      >
        <Flex
          width="100%"
          justifyContent="center"
          alignItems="center"
          padding="30px 0px"
          style={{
            flex: '0 0 auto',
            height: personalizeImage || editorImage ? 'auto' : '0px',
            backgroundColor:
              userData &&
              userData.details &&
              userData.details.user &&
              userData.details.user.email_personalization &&
              userData.details.user.email_personalization.banner_hex_color,
          }}
        >
          {userData &&
          userData.details &&
          userData.details.user &&
          userData.details.user.email_personalization &&
          userData.details.user.email_personalization.media_urls ? (
            <img
              alt="logo"
              width={imageWidth ? imageWidth : '150'}
              height={imageHeight ? imageHeight : 'auto'}
              src={getMediaUrl(
                userData.details.user.email_personalization.media_urls,
                '',
                'original'
              )}
            />
          ) : userData &&
            userData.details &&
            userData.details.user &&
            userData.details.user.personalization &&
            userData.details.user.personalization.corporate_identity &&
            userData.details.user.personalization.corporate_identity.logo &&
            userData.details.user.personalization.corporate_identity.logo
              .main &&
            userData.details.user.personalization.corporate_identity.logo.main
              .media_urls ? (
            <img
              alt="logo"
              width={imageWidth ? imageWidth : '150'}
              height={imageHeight ? imageHeight : 'auto'}
              src={getMediaUrl(
                userData.details.user.personalization.corporate_identity.logo
                  .main.media_urls,
                '',
                'original'
              )}
            />
          ) : null}
        </Flex>

        <h1
          style={{
            color: '#848484',
            textAlign: 'left',
            padding: '5px 30px',
          }}
          dangerouslySetInnerHTML={{ __html: customizeData.title }}
        />
        <Flex width="100%" padding="10px 30px" style={{ flex: '0 0 auto' }}>
          {customizeData && customizeData.media1 && (
            <img
              alt="customize"
              width="100%"
              src={customizeData.media1}
              style={{ maxHeight: '300px', objectFit: 'cover' }}
            />
          )}
        </Flex>
        <Flex
          width="100%"
          padding="10px 30px"
          color={themesData.charcoalGrey}
          fontSize="16px"
          style={{ flex: '0 0 auto' }}
        >
          <p style={{ marginBlock: '0em', color: '#848484' }}>
            <span id="salutation_text" style={salutationCss}>
              {customizeData && customizeData.salutation}{' '}
              {customizeData && customizeData.salutation_text && '{{'}{' '}
              {customizeData &&
                customizeData.salutation_text &&
                customizeData.salutation_text.label}{' '}
              {customizeData && customizeData.salutation_text && '}}'}
            </span>
          </p>
        </Flex>
        {customizeData && customizeData.message && (
          <MessageWrapper
            id="message"
            style={{
              textAlign: 'left',
              color: '#848484',
              padding: '0px 30px 15px',
            }}
            dangerouslySetInnerHTML={{ __html: customizeData.message }}
          />
        )}
        {emailTemplateSelected !== 'no-content' && (
          <Flex
            width="100%"
            justifyContent="space-between"
            flexWrap="wrap"
            style={{ flex: '0 0 auto' }}
            padding="0px 30px"
          >
            {!isEmpty(newsletterArticles) &&
              newsletterArticles.map((item, index) => (
                <NewsletterArticle
                  key={index}
                  {...item}
                  themeNames={themeNames}
                  article={item}
                  newsletterArticles={newsletterArticles}
                  deleteBlocked
                  openUrl={!isEmpty(item.sponsoredFunds) ? false : true}
                  width={'308px'}
                  height={'300px'}
                  emailTemplateSelected={emailTemplateSelected}
                  section="NEWSLETTER_PREVIEW_MODAL"
                  userData={userData}
                  channelId={channelId}
                  popularThemes={popularThemes}
                />
              ))}
          </Flex>
        )}

        <SignatureSection
          style={{padding: '0px 30px'}}
          dangerouslySetInnerHTML={{ __html: signature && signature }}
        ></SignatureSection>

        <SignatureSection
          style={{ padding: '0px 30px' }}
          dangerouslySetInnerHTML={{ __html: footerText && footerText }}
        ></SignatureSection>

        <hr style={{ width: '100%', height: '2px' }} />
        <Flex
          width="100%"
          color="#666666"
          fontSize="12px"
          padding="0px 30px"
          style={{ flex: '0 0 auto' }}
        >
          © {company.name} 2021
        </Flex>
        <Flex
          width="100%"
          padding="10px 30px"
          color="#666666"
          fontSize="10px"
          style={{ flex: '0 0 auto', textAlign: 'left' }}
        >
          This message was sent to {'{{ '}email{' }}'} and intended for {'{{ '}
          {customizeData &&
            customizeData.salutation_text &&
            customizeData.salutation_text.label}
          {' }}'}.<br></br>
          Not your account? Remove your email from this account.
        </Flex>
      </ModalBody>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  newsletterName: state.newsletter.newsletterName,
  newsletterArticles: state.newsletter.newsletterArticles,
  customizeData: state.newsletter.customData,
  userData: state.user.data,
  emailTemplateSelected: state.newsletter.emailTemplateSelected,
  themeNames: get(state, 'theme.themeNames', {}),
});

export default connect(mapStateToProps, null)(PreviewNewsletterModal);
