import React from 'react';
import {
  BodyText,
  BottomGroup,
  CardContainer,
  CardTitle,
  DataColumns,
  DataRow,
  DataTitle,
  DataValue,
  HeaderGroup,
  IconStyle,
} from '../../../componentsV2/RecommendationCard/styles';
import lightbulb from '../../../assets/icons/lightbulb.svg';
import PurpleButton from '../../../componentsV2/PurpleButton';
import { Flex } from '@rebass/grid';
import { get, isEmpty } from 'lodash';
import ErrorDisplay from '../../../componentsV2/ErrorDisplay/ErrorDisplay';
import Skeleton from 'react-loading-skeleton';
import { EmptyState } from '../../../componentsV2';
import { ColorPallete, Typography } from '../../../utils';
import { LoaderWrapper } from '../../../components';
import { Tooltip } from '@mui/material';
import { EnterpriseActivityCardWrapper } from '../ContentTab/ContentTab.styles';

const ContactsRecommendationCard = ({
  title,
  graphData,
  loading,
  error,
  onRetryClick,
  csvLoading,
  onDownload,
  csvError,
  recommendationData,
  showEnterpriseDropdownSelectedOption,
  highlyEngagedEntContacts,
  entContactsLoading,
  handleHighlyEngagedContactsRetryClick,
  highlyEngagedEntContactsError,
  userData,
  selectedFirms,
}) => {
  const firmName = userData && userData.firm_name ? userData.firm_name : '[]';
  const entUserData = {
    firm_name: firmName,
  };
  return (
    <CardContainer
      margin="0px"
      style={{
        borderLeft:
          showEnterpriseDropdownSelectedOption === 'My Enterprise' ||
          !isEmpty(selectedFirms)
            ? '0px'
            : '1px solid #E4E7EC',
        borderBottomLeftRadius:
          showEnterpriseDropdownSelectedOption === 'My Enterprise' ||
          !isEmpty(selectedFirms)
            ? '0px'
            : '8px',
        borderTopLeftRadius:
          showEnterpriseDropdownSelectedOption === 'My Enterprise' ||
          !isEmpty(selectedFirms)
            ? '0px'
            : '8px',
        boxShadow:
          showEnterpriseDropdownSelectedOption === 'My Enterprise' ||
          !isEmpty(selectedFirms)
            ? '0px 1px 2px 0px #1018281F'
            : null,
      }}
    >
      {(showEnterpriseDropdownSelectedOption === 'My Enterprise' ||
        !isEmpty(selectedFirms)) && (
        <Flex
          width="100%"
          height="465px"
          flexDirection="column"
          padding={
            entContactsLoading ? '18px 0px 0px 0px' : '18px 0px 0px 18px'
          }
        >
          {entContactsLoading && (
            <Skeleton height={443} baseColor={ColorPallete.neutral100} />
          )}
          {!entContactsLoading &&
            highlyEngagedEntContactsError === false &&
            isEmpty(highlyEngagedEntContacts) && (
              <EmptyState
                title="No Analytics Data"
                subTitle="Send out campaigns to get insights"
                height="465px"
              />
            )}
          {!entContactsLoading && highlyEngagedEntContactsError === true && (
            <ErrorDisplay
              height="465px"
              onClick={handleHighlyEngagedContactsRetryClick}
            />
          )}
          {!entContactsLoading &&
            highlyEngagedEntContactsError === false &&
            !isEmpty(highlyEngagedEntContacts) && (
              <>
                <Flex
                  fontSize="16px"
                  color="#475467"
                  style={{ paddingBottom: '25px', textAlign: 'left' }}
                >
                  {`${entUserData?.firm_name}’s top advisors and highly engaged contacts`}
                </Flex>
                <EnterpriseActivityCardWrapper>
                  {highlyEngagedEntContacts.map((item, index) => (
                    <Flex
                      width="100%"
                      flexDirection="row"
                      marginBottom="10px"
                      style={{
                        borderBottom: '1px solid #E4E7EC',
                        paddingBottom: '10px',
                      }}
                    >
                      <Flex
                        justifyContent="flex-start"
                        fontSize="14px"
                        color="#475467"
                        width="80%"
                      >
                        {item?.advisor_name}
                      </Flex>
                      <Flex
                        width="20%"
                        justifyContent="flex-end"
                        color="#475467"
                      >
                        {item?.count}
                      </Flex>
                    </Flex>
                  ))}
                </EnterpriseActivityCardWrapper>
              </>
            )}
        </Flex>
      )}
      {showEnterpriseDropdownSelectedOption === 'My Activity' &&
        isEmpty(selectedFirms) && (
          <>
            <HeaderGroup>
              <IconStyle src={lightbulb} alt="recommendation" />
              <CardTitle>{title}</CardTitle>
            </HeaderGroup>

            {loading && (
              <Skeleton height={296} baseColor={ColorPallete.neutral100} />
            )}

            {!loading && !error && isEmpty(graphData) && (
              <EmptyState
                height="296px"
                title="No Analytics Data"
                subTitle="Send out campaigns to get insights"
              />
            )}
            {!loading && error && (
              <ErrorDisplay height="296px" onClick={onRetryClick} />
            )}
            {!loading && !isEmpty(graphData) && !error && (
              <>
                {graphData.filter((g) => g.name === 'High')[0][
                  'Engaged Contacts'
                ] > 0 ? (
                  <BottomGroup>
                    <BodyText>
                      You have{' '}
                      <span style={{ color: 'black' }}>
                        {
                          graphData.filter((g) => g.name === 'High')[0][
                            'Engaged Contacts'
                          ]
                        }
                      </span>{' '}
                      highly engaged contacts.
                    </BodyText>
                    <BodyText>
                      We suggest that you{' '}
                      <span style={{ color: 'black' }}>create a new list</span>{' '}
                      for more targeted outreach to this group.
                    </BodyText>
                  </BottomGroup>
                ) : (
                  <>
                    <EmptyState
                      title="No Analytics Data"
                      subTitle="Send out campaigns to get insights"
                    />
                    <Flex
                      width="100%"
                      style={{
                        borderBottom: `1px solid ${ColorPallete.neutral200}`,
                      }}
                    ></Flex>
                  </>
                )}

                <DataColumns style={{ marginBottom: '15px', height: '135px' }}>
                  <DataRow>
                    <DataTitle style={{ fontSize: '12px', fontWeight: 400 }}>
                      Number of Contacts
                    </DataTitle>
                    <DataValue style={{ fontSize: '14px', fontWeight: 500 }}>
                      {
                        graphData.filter((g) => g.name === 'High')[0][
                          'Engaged Contacts'
                        ]
                      }
                    </DataValue>
                  </DataRow>
                  <DataRow>
                    <DataTitle
                      style={{
                        width: '180px',
                        textAlign: 'left',
                        fontSize: '12px',
                        fontWeight: 400,
                      }}
                    >
                      Recommended Name
                    </DataTitle>
                    {recommendationData?.recommended_name &&
                    recommendationData?.recommended_name > 10 ? (
                      <Tooltip>
                        <DataValue
                          style={{
                            textAlign: 'right',
                            fontSize: '14px',
                            fontWeight: 500,
                          }}
                        >
                          {`${recommendationData?.recommended_name.substring(
                            0,
                            10
                          )}...`}
                        </DataValue>
                      </Tooltip>
                    ) : (
                      <DataValue
                        style={{
                          textAlign: 'right',
                          fontSize: '14px',
                          fontWeight: 500,
                        }}
                      >
                        {recommendationData?.recommended_name}
                      </DataValue>
                    )}
                  </DataRow>
                </DataColumns>

                {csvError && (
                  <Flex
                    padding="0px 20px"
                    fontSize={Typography.TextSmall}
                    color={ColorPallete.neutral900}
                  >
                    {csvError}
                  </Flex>
                )}
                <LoaderWrapper isLoading={csvLoading}>
                  <PurpleButton
                    children={
                      <Flex style={{ alignItems: 'center' }}>
                        Download List
                        <img
                          src="/assets/images/Analytics/right-arrow-color.svg"
                          alt=""
                          style={{ margin: '0px 0px 0px 4px' }}
                        />
                      </Flex>
                    }
                    width={'90%'}
                    onClick={onDownload}
                    margin={'10px auto 20px'}
                    disabled={
                      graphData.filter((g) => g.name === 'High')[0][
                        'Engaged Contacts'
                      ] === 0
                    }
                  />
                </LoaderWrapper>
              </>
            )}
          </>
        )}
    </CardContainer>
  );
};

export default ContactsRecommendationCard;
