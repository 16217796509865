import { styled } from '@mui/material';

export const Wrapper = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '1rem',
}));

export const Link = styled('a')(() => ({
  display: 'flex',
  alignItems: 'center',
  color: '#0B5CD7',
  font: '400 0.75rem Poppins, sans-serif',
  textDecoration: 'none',
}));

export const Header = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  gap: '1rem',
}));

export const ContentContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '1rem',
  width: '100%',
}));

export const ArticleContainer = styled('div')(() => ({
  boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.12)',
  background: '#fff',
  height: '100%',
  width: '100%',
  borderRadius: '0.5rem',
  display: 'flex',
  alignItems: 'center',
  gap: '1rem',
  border: '1px solid #BBBEF7',
  padding: '1rem',
}));

export const Article = styled('div')(() => ({
  display: 'flex',
  alignItems: 'flex-start',
  gap: '1rem',
  width: '100%',
}));

export const ArticleInfoContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  rowGap: '1rem',
}));

export const ArticleEngagementContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  rowGap: '4px',
}));

export const ArticleTitle = styled('h2')(() => ({
  font: '500 1rem/1.4rem Poppins, sans-serif',
  textAlign: 'left',
  margin: 0,
  color: '#101828',
}));

export const EngagementScoreContainer = styled('div')(() => ({
  '& p': {
    color: '#667085',
    font: '500 0.875rem/1rem Poppins, sans-serif',
    margin: 0,
  },
  display: 'flex',
  alignItems: 'center',
  gap: '0.5rem',
}));

export const Divider = styled('div')(() => ({
  height: '16px',
  width: '1px',
  background: '#667085',
}));

export const ArticleIcon = styled('div')(() => ({
  '& svg': {
    height: '80px',
    width: '80px',
  },
  '& img': {
    objectFit: 'cover',
    borderRadius: '0.5rem',
  },
}));

export const Pills = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '0.5rem',
  flexWrap: 'wrap',
}));

export const Pill = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '0.625rem',
  padding: '0.25rem 0.5rem',
  border: `1px solid #B6CFF3`,
  backgroundColor: `#ECF2FC`,
  borderRadius: '1.5rem',
  font: '500 0.75rem Poppins, sans-serif',
  color: '#0B5CD7',
  textAlign: 'left',
  margin: 0,

  svg: {
    fontSize: '1rem',
    color: '#0B5CD7',
  },
}));
