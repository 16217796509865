import React from 'react';
import {
  UserName,
  UserProfileInfo,
  TwitterUserInfo,
  LinkedinUserInfo,
} from './styles';


export const platformKeys = {
  FACEBOOK: 'fb',
  LINKEDIN: 'ln',
  TWITTER: 'tw',
};

export const sliceUrlToDotCom = (url) => {
  const match = url.match(/^(https?:\/\/[^\\/]*\.com\/?)/);
  return match ? match?.[0] : url;
};

export const renderUserProfileInfo = (platform, userName, postTitle) => {
  const userFirstName = userName?.first ?? '';
  const userLastName = userName?.last ?? '';

  switch (platform?.value) {
    case platformKeys.LINKEDIN:
      return (
        <LinkedinUserInfo>
          <UserName margin={0}>{`${userFirstName} ${userLastName}`}</UserName>
          <UserProfileInfo>Creative Director</UserProfileInfo>
          <UserProfileInfo>5d</UserProfileInfo>
        </LinkedinUserInfo>
      );
    case platformKeys.TWITTER:
      return (
        <>
          <TwitterUserInfo>
            <UserName>{`${userFirstName} ${userLastName}`}</UserName>
            <UserProfileInfo>
              <span>@{`${userFirstName}${userLastName}`}</span> 3h
            </UserProfileInfo>
          </TwitterUserInfo>
          <div>{postTitle ?? ''}</div>
        </>
      );

    default:
      return (
        <>
          <UserName>{`${userFirstName} ${userLastName}`}</UserName>
          <UserProfileInfo>
            <span>Date of post</span> Amsterdam, Netherlands
          </UserProfileInfo>
        </>
      );
  }
};

export const getSeriesPostInfo = (data, platform) => {
  const firstKey = Object.keys(data.post_details || {})[0] || '';
  const feed = data.post_details?.[firstKey]?.[0]?.articles?.feed?.[0] || {};
  return {
    mediaURL: feed.media_urls?.[0] || '',
    postLink: feed.links?.social_permalink || null,
    postTitle: feed.auxiliary?.title || '',
    articleInfo: feed.text || '',
    userMessage: data.posts?.[platform?.mappingKey]?.override_text || '',
  };
};

export const getAutomatedPostInfo = (data, platform) => {
  const article = (Array.isArray(data.articles) ? data.articles?.[0] : {}) || {};
  const mediaURL = data?.mediaURL || {};
  const image = mediaURL?.path
    ? `${mediaURL?.path}${mediaURL?.name?.system}.${mediaURL?.ext}`
    : '';
  return {
    mediaURL: image || '',
    postLink: article?.link || null,
    postTitle: article?.title || '',
    articleInfo: article?.description || '',
    userMessage: data?.overrideText?.[platform?.value] || '',
  };
};
