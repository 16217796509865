import React from 'react';
import { TooltipContainer, TooltipInfoContainer, TooltipTitle } from './styles';

function CustomTooltip({ payload, active }) {
  const label = (name) => {
    switch (name) {
      case 'open_rate':
        return 'Open Rate';
      case 'click_rate':
        return 'Click Rate';
      default:
        return name;
    }
  };

  if (active && Array.isArray(payload) && payload?.length > 0) {
    return (
      <TooltipContainer>
        <TooltipTitle>{payload?.[0].payload?.name ?? ''}</TooltipTitle>
        {payload?.map((row, index) => {
          return (
            <TooltipInfoContainer key={index}>
              <p>{label(row?.name ?? '')}:</p>
              <p>{row?.value}%</p>
            </TooltipInfoContainer>
          );
        })}
      </TooltipContainer>
    );
  }

  return null;
}

export default CustomTooltip;
