import React from 'react';
import { useLocation } from 'react-router-dom';
import Table from 'src/componentsV2/Table';
import useRESTQuery from 'src/hooks/useRESTQuery';
import { tableColumnStructure } from './TableColumnStructure';
import { ContactListContainer, TableHeader } from './styles';
import Skeleton from './Skeleton';
import { transformData } from './utils';
import { EmptyState } from 'src/componentsV2';

function ContactList({ campaignId = '', hasSubCampaignSelected = false }) {
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const type = queryParams.get('type');

  const { isLoading, data } = useRESTQuery({
    key: 'contacts',
    endpoint: `/analytics/detailed/campaign/${campaignId}/contacts?campaign_type=${type}&is_block=${hasSubCampaignSelected}`,
    dependencies: [campaignId, type],
    options: {
      select: (res) => {
        if (Array.isArray(res?.result)) {
          return res?.result?.map((result) => transformData(result));
        }
        return [];
      },
    },
  });

  if (isLoading) return <Skeleton />;

  return (
    <ContactListContainer>
      <TableHeader>
        <h2>Engaged Contacts</h2>
      </TableHeader>
      <Table
        data={data}
        columnStructure={tableColumnStructure()}
        customStyles={{
          borderRadius: '0 0 0.5rem 0.5rem',
        }}
        height="550px"
         customEmptyState={<EmptyState height="192px" title="No Analytics Data" />}
      />
    </ContactListContainer>
  );
}

export default ContactList;
