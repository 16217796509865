import React from 'react';
import {
  CampaignInfoCardsWrapper,
  CardIconContainer,
  CardInfoContent,
} from './styles';

function CampaignInfoCards({
  campaignInfo = { icon: <></>, label: '', value: '' },
}) {
  return (
    <CampaignInfoCardsWrapper>
      <CardIconContainer>{campaignInfo?.icon ?? <></>}</CardIconContainer>
      <CardInfoContent>
        <p className="label">{campaignInfo?.label ?? ''}</p>
        <p className="value">{campaignInfo?.value ?? ''}</p>
      </CardInfoContent>
    </CampaignInfoCardsWrapper>
  );
}

export default CampaignInfoCards;
