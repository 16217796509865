import { Flex } from '@rebass/grid';
import React from 'react';
import {
  Button,
  LoaderWrapper,
  ModalBody,
  ModalCloseButton,
  ModalHeader,
  ModalV2,
} from '../../../components';
import { TemplateContainer } from '../../DripCampaignContainerV2/Preview&ViewInfoModal/Modal.styles';
import { useState } from 'react';
import { useRef } from 'react';
import { getAutomatedBlocksById } from '../../../services/automated-campaigns';
import { useEffect } from 'react';
import { isEmpty } from 'lodash';
import { nurtureFlag } from '../../../utils';

const AutomatedCampaignEmailPreviewModal = ({
  isOpen,
  handleClose,
  template_html,
  block_id,
  campaign_id,
  subject,
  name,
  campaignFlow
}) => {
  const iframeRef = useRef(null);
  const [blockData, setBlockData] = useState({});
  const [loading, setLoading] = useState(false);


  const getBlockById = async () => {
    try {
      setLoading(true);
      if(nurtureFlag === 'nurture' && process.env.REACT_APP_API_ENV === 'stage') {
        setBlockData({
          template_html,
          name,
          subject
        })
      } else {
        const { data } = await getAutomatedBlocksById(campaign_id, block_id);
        setBlockData(data);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(`Error Fetching Block for the id ${block_id} --> `, error);
    }
  };

  useEffect(() => {
    if (!isEmpty(template_html) && !isEmpty(subject) && !isEmpty(name)) {
      setBlockData({
        template_html,
        subject,
        name,
      });
    } else {
      if(!campaignFlow) {
        getBlockById();
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ModalV2
      isOpen={isOpen}
      onRequestClose={handleClose}
      width="50%"
      style={{ padding: '20px' }}
    >
      <ModalHeader justifyContent={'center'}>
        <Flex
          justifyContent="center"
          style={{ color: '#000000', fontSize: '18px' }}
        >
          Preview
        </Flex>
        <ModalCloseButton onClick={handleClose} />
      </ModalHeader>
      <ModalBody>
        <LoaderWrapper isLoading={loading}>
          {blockData.template_html ? (
            <>
              <Flex
                style={{
                  background: 'white',
                  padding: '10px 45px',
                  color: '#000000',
                  fontSize: '16px',
                  fontWeight: 500,
                  justifyContent: 'center',
                  display: 'flex', 
                  flexDirection: 'row',
                  width: '100%'
                }}
              >
                Article Title : 
                <Flex style={{ color: '#475467', fontSize: '14px', display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '0px 7px'}}>{blockData.name}</Flex>
              </Flex>
              <Flex
                style={{
                  background: 'white',
                  padding: '10px 45px',
                  color: '#000000',
                  fontSize: '16px',
                  fontWeight: 500,
                  justifyContent: 'center',
                  width: '100%'
                }}
              > 
                Subject : 
                <Flex style={{ color: '#475467', fontSize: '14px', display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '0px 7px'}}>{blockData.subject}</Flex>
              </Flex>
              <TemplateContainer>
                <iframe
                  srcDoc={blockData.template_html}
                  frameBorder="no"
                  ref={iframeRef}
                  title="Email Preview"
                />
              </TemplateContainer>
            </>
          ) : (
            <Flex
              justifyContent={'center'}
              alignItems={'center'}
              style={{ height: '300px' }}
            >
              Please create an email to preview.
            </Flex>
          )}

          <Flex justifyContent={'center'}>
            <Button onClick={handleClose}>Close</Button>
          </Flex>
        </LoaderWrapper>
      </ModalBody>
    </ModalV2>
  );
};

export default AutomatedCampaignEmailPreviewModal;
