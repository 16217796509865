import React from 'react';
import ReactSkeleton from 'react-loading-skeleton';
import {
  EmailDataInfoContainer,
  EmailDataWrapper,
  EmailHeader,
} from './styles';

function Skeleton() {
  return (
    <EmailDataWrapper>
      <EmailHeader>
        <ReactSkeleton height={20} width={20} />
        <ReactSkeleton height={20} width={60} />
      </EmailHeader>
      <EmailDataInfoContainer>
        <ReactSkeleton height={40} />
        <ReactSkeleton height={40} />
        <ReactSkeleton height={40} />
        <ReactSkeleton height={40} />
      </EmailDataInfoContainer>
    </EmailDataWrapper>
  );
}

export default Skeleton;
