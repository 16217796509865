import { useQuery } from 'react-query';
import {
  apiDetails,
  automatedCampaignLambdaEndpoints,
  cloutV2Endpoints,
} from 'src/services/helpers';
import apiInstance from 'src/services/api';

function useRESTQuery({
  key,
  api = 'fastapi',
  useAdvisorToken = false,
  dependencies = [],
  endpoint,
  options = {},
}) {
  const getBaseUrl = () => {
    switch (api) {
      case 'api.clout':
        return apiDetails?.baseUrl;
      case 'api.automatedCampaign':
        return automatedCampaignLambdaEndpoints?.baseUrl;
      default:
        return cloutV2Endpoints?.baseUrl;
    }
  };

  return useQuery({
    queryKey: [key, ...dependencies],
    queryFn: async () => {
      const response = await apiInstance({ asAdvisor: useAdvisorToken })?.get(
        `${getBaseUrl()}${endpoint}`
      );
      return response;
    },
    ...options,
  });
}

export default useRESTQuery;
