import React from 'react';
import { useState } from 'react';
import styled from 'styled-components';

const StyledTooltipWrapper = styled.div`
  position: relative;
  .campaign__tooltip-icon {
    width: 15px;
    height: 13px;
    cursor: pointer;
    margin-left: 10px;
  }

  .campaign__tooltip-text {
    width: 250px;
    background-color: #696969;
    color: #ffffff;
    padding: 5px 10px;
    text-align: left;
    font-size: 10px;

    position: absolute;
    top: 15px;
    left: 30px;
  }
`;

const StyledTooltip = ({ text, styles = {} }) => {
  const [showTooltip, setShowTooltip] = useState(false);
  return (
    <StyledTooltipWrapper className="campaign__tooltip" style={{ ...styles }}>
      <img
        src="/assets/images/information-grey.svg"
        alt="info tooltip"
        className="campaign__tooltip-icon"
        onMouseEnter={() => setShowTooltip(true)}
        onMouseLeave={() => setShowTooltip(false)}
      />
      {showTooltip && <span className="campaign__tooltip-text">{text}</span>}
    </StyledTooltipWrapper>
  );
};

export default StyledTooltip;
