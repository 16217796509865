import { styled } from '@mui/material';

export const Wrapper = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  alignItems: 'flex-start',
  gap: '1.5rem',
}));

export const ZeroStateWrapper = styled('div')(() => ({
  width: '100%',
  minHeight: '300px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));
