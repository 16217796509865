import styled from 'styled-components';
import { Box } from '@rebass/grid';

export const ForgotPasswordModalContent = styled(Box)`
  font-size: 14px;
  color: #6351ed;
  .body-content {
    padding: 20px 0px;
  }
  .input-wrapper {
    text-align: left;
  }
`;
