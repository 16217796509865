import { Box, Flex } from '@rebass/grid';
import styled from 'styled-components';

const AutomatedCampaignListItemWrapper = styled(Flex)`
  border: 1px solid #dbe0e6;
  border-radius: 4px;
  margin: ${({ isFirst }) => (isFirst ? '0px 0px 10px' : '10px 0px')};
  background-color: #fff;
`;

const AutomatedCampaignInfoWrapper = styled(Box)`
  .campaign__name {
    font-size: 18px;
    color: #000000;
    font-weight: 600;
    /* margin-bottom: 10px; */
  }

  .date_holder,
  .type_holder {
    min-width: 120px;
  }

  .status_holder {
    min-width: 150px;
  }

  .chunk_title {
    font-size: 12px;
    color: #4e6780;
  }

  .chunk_info {
    font-size: 13px;
    color: #000000;
  }
`;

const AutomatedCampaignImageGeneratorWrapper = styled(Box)`
  width: 160px;
  height: 100px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 5px;
  }
`;

const ActionsWrapper = styled(Flex)`
  position: relative;

  .cursor-pointer {
    cursor: pointer;
  }

  .actions {
    width: 200px;
    position: absolute;
    top: 30px;
    right: 20px;

    background: #ffffff;
    box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px,
      rgba(17, 17, 26, 0.1) 0px 0px 8px;
    -webkit-box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px,
      rgba(17, 17, 26, 0.1) 0px 0px 8px;
    z-index: 1;

    .action-item {
      padding: 6px 0px 6px 20px;
      color: black;
      font-size: 12px;
      margin: 0px;
      text-align: left;
      transition: all 0.2s;

      :hover {
        background: #6351ed;
        color: #fff;
      }
    }
  }
`;

export {
  AutomatedCampaignListItemWrapper,
  AutomatedCampaignImageGeneratorWrapper,
  AutomatedCampaignInfoWrapper,
  ActionsWrapper,
};
