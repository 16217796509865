import React, { useState } from 'react';
import DigitalCard from '../../ContentContainer/DigitalCard';
import { ConnectMeWrapper } from './Profile.styles';
import {
  SaveButton,
  WhiteButton
} from '../AdminConfigureContainer.styles';
import { 
  SwitchStyle
} from './Profile.styles';

export default function CardModal({
  handleModalClose,
  name,
  firm_name,
  mobile,
  email,
  about_me,
  digital_banner,
  logo,
  socialLinks,
  userData,
}) {
  const [isMobileview, setIsMobileview] = useState(false);
  return (
    <div style={{width: 400}}>
        <div style={{display: 'flex', margin: '10px auto', justifyContent: 'center'}}>
          {isMobileview ? (
            <ConnectMeWrapper
              right="1.5%"
              width="190px"
              padding="0px 10px 10px"
              height="250px"
              border="1px solid #E4E7EC"
            >
              <div style={{fontSize: 12, textAlign: 'center', margin: '20px auto 0', color: '#101828'}}>
                {digital_banner}
              </div>
              <SaveButton 
                cursor='auto' 
                className="mobilePdfFormButton" 
                children="Yes, I'm interested"
                style={{display: 'flex', margin: '10px auto 0', justifyContent: 'center'}}
              />
            </ConnectMeWrapper>
          ) : (
            <DigitalCard
              handleButtonClick={() => {}}
              mode="profile"
              name={name}
              firm_name={firm_name}
              mobile={mobile}
              email={email}
              about_me={about_me}
              digital_banner={digital_banner}
              logo={logo}
              linkedIn={socialLinks?.linkedIn ? socialLinks?.linkedIn : null}
              facebook={socialLinks?.facebook ? socialLinks?.facebook : null}
              twitter={socialLinks?.twitter ? socialLinks?.twitter : null}
              userData={userData}
            />
          )}
        </div>
        <div style={{fontSize: 11, textAlign: 'center', margin: '20px auto 0', color: '#101828'}}>This is how your profile will appear to prospective leads.</div>
        <div style={{display: 'flex', width: 'fit-content', margin: '10px auto'}}>
          <p style={{color: isMobileview ? '#101828' : '#816EF8', margin: '6px 0'}}>Desktop</p>
          <SwitchStyle
            className="react-switch"
            onChange={() => setIsMobileview(!isMobileview)}
            checked={isMobileview}
          />
          <p style={{color: !isMobileview ? '#101828' : '#816EF8', margin: '6px 0'}}>Mobile</p>
        </div>
        <div>
          <WhiteButton
            onClick={handleModalClose}
            children="Close"
            style={{display: 'flex', margin: '20px auto 0', justifyContent: 'center'}}
          />
        </div>
      </div>
  );
}
