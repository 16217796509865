import React from 'react';
import { useLocation, useParams } from 'react-router-dom';

import useRESTQuery from 'src/hooks/useRESTQuery';
import CampaignPerformanceChart from './CampaignPerformanceChart';
import EmailData from './EmailData';
import CampaignContent from './CampaignContent';
import EmailDeliverability from './EmailDeliverability';
import ContactList from './ContactList';
import TopLeads from './TopLeads';
import SubCampaignInsightsDisplay from './SubCampaignInsightsDisplay';

import { transformCampaignInsightsData } from './utils';
import ForwardTable from './ForwardTable';

function CampaignInsights({ selectedSubCampaign, campaignInfo }) {
  const { id } = useParams();
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const type = queryParams.get('type');
  const hasSubCampaignSelected =
    selectedSubCampaign && Object.keys(selectedSubCampaign).length > 0;

  const campaignId = selectedSubCampaign?.id ? selectedSubCampaign?.id : id;

  const currentPlatforms = hasSubCampaignSelected
    ? selectedSubCampaign.platforms
    : campaignInfo?.platforms;

  const {
    is_email = false,
    is_facebook = false,
    is_linkedin = false,
    is_twitter = false,
  } = currentPlatforms || {};

  const hasSocialPlatforms =
    is_email || is_facebook || is_linkedin || is_twitter;

  const { data: campaignInsightsData, isLoading } = useRESTQuery({
    key: 'campaign-insights',
    endpoint: `/analytics/detailed/campaign/${campaignId}/scores?campaign_type=${type}&is_block=${hasSubCampaignSelected}`,
    dependencies: [campaignId, type],
    options: {
      enabled: is_email,
      select: (res) => {
        if (res?.result)
          return transformCampaignInsightsData(res?.result ?? {});
        else return {};
      },
    },
  });

  return (
    <section>
      {is_email &&
        selectedSubCampaign &&
        Object.keys(selectedSubCampaign)?.length > 0 && (
          <SubCampaignInsightsDisplay
            campaignInsightsData={campaignInsightsData}
            isLoading={isLoading}
          />
        )}
      {is_email && (
        <ContactList
          campaignId={campaignId}
          hasSubCampaignSelected={hasSubCampaignSelected}
        />
      )}
      {is_email && (
        <EmailData
          emailData={campaignInsightsData?.emailData}
          isLoading={isLoading}
        />
      )}
      {is_email && <EmailDeliverability campaignId={campaignId} hasSubCampaignSelected={hasSubCampaignSelected}/>}
      {is_email && <ForwardTable campaignId={campaignId} hasSubCampaignSelected={hasSubCampaignSelected}/>}
      {is_email && (
        <CampaignPerformanceChart
          hasSubCampaignSelected={hasSubCampaignSelected}
          campaignId={campaignId}
        />
      )}
      {is_email && (
        <TopLeads
          campaignId={campaignId}
          hasSubCampaignSelected={hasSubCampaignSelected}
        />
      )}
      {hasSocialPlatforms && (
        <CampaignContent
          campaignId={campaignId}
          hasSubCampaignSelected={hasSubCampaignSelected}
        />
      )}
    </section>
  );
}

export default CampaignInsights;
