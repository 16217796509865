import { padStart } from 'lodash';
import sha256 from 'js-sha256';
import apiInstance from '../api';
import { objectToFormData } from '../helpers';
import axios from 'axios';
import { encryptPassword } from '../../utils';

export const getAuthToken = (payload, social) => {
  const date = new Date();
  const year = date.getFullYear();
  const month = padStart(date.getMonth() + 1, 2, '0');
  const day = padStart(date.getDate(), 2, '0');
  const preEncryptionString = social
    ? `${year}/${month}/${day}`
    : `${payload.email}-${year}/${month}/${day}`;
  const authToken = sha256(preEncryptionString);

  return authToken;
};

export const authenticate = (payload) => {
  const authToken = getAuthToken(payload);

  payload.password = encryptPassword(payload.password);
  payload.encrypted = 'true';

  return apiInstance({ isReactLogin: true }).post(
    '/user/login',
    objectToFormData(payload),
    {
      headers: { Authorization: `Bearer ${authToken}` },
    }
  );
};

export const socialLogin = (payload) => {
  const obj = {
    sso_provider: payload.sso_provider,
    token: payload.token,
  };

  if (payload.token_secret) obj['token_secret'] = payload.token_secret;
  const authToken = getAuthToken(null, true);
  return apiInstance().post(`/user/ssologin/?`, objectToFormData(obj), {
    headers: { Authorization: `Bearer ${authToken}` },
  });
};

export const getUserDetails = (_, token) => {
  return apiInstance({ token: token }).get('/user');
};

export const getMultiUserDetails = async ({token}) => {
  console.log(token)
  const response = await axios.get('/user', {
    headers: { Authorization: `Bearer ${token}` }
  })

  return response;
}

export const getUserDetail = () => {
  return apiInstance().get('/user');
};

export const listUsers = () => {
  return apiInstance().get('/user/list_users');
}

export const redirectImpersonateUser = (payload) => {
  return apiInstance().post('/user/login/email/', objectToFormData(payload))
}
