import { Chip } from '@mui/material';
import styled from 'styled-components';

export const ChipStyle = styled(Chip)`
  font-family: 'Poppins';
  margin: 0px 5px 5px 0px;
  border: 1px solid #B6CFF3;
  background: #ECF2FC;
  color: #0B5CD7;
  display: inline;
  .MuiChip-root {
    color: #404B50;
    opacity: 1;
  };
  .MuiChip-outlined {
    color: #B6CFF3;
  };
  .MuiChip-deleteIcon {
    color: #0B5CD7;
    opacity: 1;
    margin: 0;
  };
`;

export const AddChipStyle = styled(Chip)`
  margin: 5px 5px 5px 0px !important;
  border: 1px solid #E4E7EC !important;
  background: #FFF !important;
  color: #101828;
  display: inline;
  .MuiChip-root {
    color: #101828;
    opacity: 1;
  };
  .MuiChip-deleteIcon {
    color: #101828;
    opacity: 1;
    padding: 0 6px 0 0;
  };
`;


export const SearchResults = styled('div')`
  width: 50%;
  display: flex;
  justify-content: start;
  padding: 5px;
  margin: 0px;
  flex-flow: rows;
  flex-wrap: wrap;
  background: white;
  border: 1px solid #E4E7EC !important;
  border-radius: 4px;
  box-shadow: inset 1px 2px 2px rgba(16, 24, 40, 0.12);
  ::-webkit-scrollbar {
    width: 5px;
  }
  &::-webkit-scrollbar-track {
    background: #E9F6E6 !important;
  }
  &::-webkit-scrollbar-thumb {
    background: #BCCDD4 !important;
  }
`;

export const SearchBox = styled('input')`
  font-family: 'Poppins';
  width: 50%;
  height: 40px;
  background: #FCFDFD;
  padding: 0 10px;
  border: 1px solid #E4E7EC;
  box-shadow: inset 1px 2px 2px rgba(16, 24, 40, 0.12);
  border-radius: 4px;
  color: #667085;
`;

export const SearchTitle = styled('h4')`
  font-family: 'Poppins';
  font-size: 14px;
  font-weight: 400;
  color: #101828;
  margin: 10px 0 5px;
  text-align: left;
`;

export const SearchResultRow = styled('div')`
  font-family: 'Poppins';
  font-size: 14px;
  font-weight: 400;
  width: 100%;
  padding: 6px;
  color: #101828;
  display: flex;
  justify-content: space-between;
  margin: 4px 0 0;
  text-align: left;
  transition: 0.5s;
  border-radius: 4px;
  :hover{
    background: #fafafa;
  }
`;

export const ActionIcon = styled('img')`
  width: 28px;
  height: 28px;
  margin: 2px 5px 0 0;
  padding: 5px;
  border-radius: 4px;
  cursor: pointer;
  :hover{
    background: #E4E7EC;
  }
`;

export const SegmentContainer = styled('div')(() => ({
  minHeight: 300,
  width: '60vw', 
  maxWidth: 600, 
  margin: '-20px 0 0px'
}));

export const InnerContainer = styled('div')`
  min-height: 300px;
  width: 100%;
  padding: '0 0 10px;
  ::-webkit-scrollbar {
    width: 5px;
  }
  &::-webkit-scrollbar-track {
    background: #E9F6E6 !important;
  }
  &::-webkit-scrollbar-thumb {
    background: #BCCDD4 !important;
  }
`;
