import React from 'react'
import { StackedACBlock } from '../../AutomatedCampaigns/CampaignCreateSteps/CampaignCreateSteps.styles';
import { Flex } from '@rebass/grid';
import { isEmpty } from 'lodash';
import { getMediaImage } from '../../../utils';
import { Tooltip } from '@mui/material';
const StackedDataCard = ({
  item
}) => {

  const getImage = () => {
    if (!isEmpty(item.email)) {
      if (!isEmpty(item?.customized_data?.subject)) {
        if (!isEmpty(item?.media_urls)) {
          return getMediaImage(item.media_urls);
        } else {
          const randomNumber = Math.floor(Math.random() * 4) + 1;
          return `/assets/images/postDefault${randomNumber}.jpg`;
        }
      } else {
        return '/assets/images/Campaign/Default-image.png';
      }
    } else {
      if (item?.posts_details) {
        let socialkeys = Object.keys(item?.posts_details);
        if(!isEmpty(item?.posts_details[socialkeys[0]]) && !isEmpty(item?.posts_details[socialkeys[0]][0].articles.feed) && !isEmpty(item?.posts_details[socialkeys[0]][0].articles.feed[0].media_urls)) {
          return getMediaImage(item?.posts_details[socialkeys[0]][0].articles.feed[0].media_urls[0]);
        } else {
          const randomNumber = Math.floor(Math.random() * 4) + 1;
          return `/assets/images/postDefault${randomNumber}.jpg`;
        }
      } else {
        return '/assets/images/Campaign/Default-image.png';
      }
    }
  }
  return (
    <StackedACBlock>
      <Flex width="40%" height="150px">
        <img
          style={{ height: '100%', width: '100%' }}
          src={
            getImage()
          }
          alt=""
        />
      </Flex>
      <Flex width="60%" pl="10px" flexDirection="column">
        <Flex color="#101828" fontSize="16px" style={{ fontWeight: '500', textAlign: 'left' }}>
          {item?.name}
        </Flex>
        {!isEmpty(item.email) && (
          <Flex color="#667085" padding="10px 0px 10px" fontSize="12px" style={{ textAlign: 'left' }}>
            {item?.customized_data?.subject}
          </Flex>
        )}

        {item?.is_email === 'false' && item.posts && !isEmpty(item.posts) && (
          <Tooltip title={item?.posts[Object.keys(item?.posts)[0]].override_text.length > 250 ? item?.posts[Object.keys(item?.posts)[0]].override_text : null} arrow>
            <Flex color="#667085" padding="10px 0px 10px" fontSize="12px" style={{ textAlign: 'left' }}>
              {item?.posts[Object.keys(item?.posts)[0]].override_text.length > 250 ? item?.posts[Object.keys(item?.posts)[0]].override_text.substring(0, 250) + '...' : item?.posts[Object.keys(item?.posts)[0]].override_text}
            </Flex>
          </Tooltip>
        )}
        <Flex>
          {/* <Button borderColor="#E4E7EC" color="#101828" bgColor="#fff" margin="10px 0px 0px">
                        <Flex justifyContent="space-between" width="100%" alignItems="center">
                            <img src="assets/images/Campaign/Pencil-Icon.svg" alt="Pencil" />
                            <Flex ml="5px">Customize Block</Flex>
                        </Flex>
                    </Button> */}
        </Flex>
      </Flex>
    </StackedACBlock>
  )
}

export default StackedDataCard;