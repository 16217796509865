import { isEmpty } from 'lodash';
import React from 'react';
import { useState } from 'react';
import {
  LoaderWrapper,
  Modal,
  ModalBody,
  ModalCloseButton,
} from '../../../../components';
import { deleteCampaign } from '../../../../services/campaign';
import { HoverButton } from '../../../ConfigureContainer/EmailList/EmailList.styles';

export const DeleteCampaignModal = ({
  open,
  handleClose,
  campaignId,
  seriesId,
  campaignRequest,
  filters,
  type,
  mode,
  clearSeriesSubcampaign
}) => {

  const [loading, setLoading] = useState(false);

  const handleSubmit = () => {
    const payload = { id: campaignId || seriesId };
    if(type === 'one_time' || mode)
      payload.type = 'campaign';
    else
      payload.type = 'series';

    if (campaignId || seriesId) {
      setLoading(true);
      deleteCampaign(payload)
        .then((res) => {
          setLoading(false);
          if(seriesId) 
            clearSeriesSubcampaign();
          if (isEmpty(filters)) 
            campaignRequest();
          else 
            campaignRequest({ ...filters });
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
        });
    }
  };
  return (
    <Modal
      onRequestClose={handleClose}
      shouldCloseOnOverlayClick
      isOpen={open}
      size="smail"
    >
      <ModalCloseButton onClick={handleClose} />
      <ModalBody>
        <div
          style={{
            fontWeight: 'bold',
            fontSize: '16px',
            color: '#6351ed',
            fontFamily: 'Poppins',
            wordBreak: 'break-all',
          }}
        >
          Are you sure you want to delete ?
        </div>
        <LoaderWrapper isLoading={loading}>
          <HoverButton
            margin="9px 10px 0px 0px"
            padding="5px 28px"
            bgColor="#fff"
            hoverBackground="#6351ed"
            hoverColor="#fff"
            color="#6351ed"
            borderColor="#fff"
            onClick={handleSubmit}
          >
            Yes
          </HoverButton>
          <HoverButton
            margin="9px 0px 0px 0px"
            padding="5px 28px"
            bgColor="#fff"
            color="#6351ed"
            hoverBackground="#6351ed"
            hoverColor="#fff"
            borderColor="#fff"
            onClick={handleClose}
          >
            No
          </HoverButton>
        </LoaderWrapper>
      </ModalBody>
    </Modal>
  );
};
