import { Flex } from '@rebass/grid';
import React, { useState } from 'react';
import { Button, LoaderWrapper, Modal, ModalBody, ModalCloseButton, ModalHeader } from '../../../components';
import { SelectedArticlesContainer } from '../SelectArticles/SelectArticles.styles';
import CampaignCard from './CampaignCard';

const PublishErrorModal = ({ modalOpen, closeModal, failedArticles, click }) => {

    console.log('FAILED ARTICLES = = == ', failedArticles);
    const [loader, toggleLoader] = useState(false);

    const publishHandler = () => {
        toggleLoader(true);
        click();
    }

    return (
        <Modal isOpen={modalOpen} onRequestClose={closeModal} size='medium68'>
            <ModalHeader>
                <ModalCloseButton onClick={closeModal} />
            </ModalHeader>
            <ModalBody>
                <SelectedArticlesContainer scrollHeight='10px' style={{marginBottom: '20px', backgroundColor: '#fff'}}>
                    {failedArticles && failedArticles.map(article => (
                        <CampaignCard {...article} content={true} fromErrorModal={true} />
                    ))}
                </SelectedArticlesContainer>
                <div>Some articles have already been shared on the mentioned platforms. Do you want to reshare the above articles?</div>
                <Flex justifyContent='center' >
                    <LoaderWrapper isLoading={loader}>
                        <Button margin='20px 20px 0px 0px' onClick={() => publishHandler()} >Publish</Button>
                        <Button margin='20px 20px 0px 0px' onClick={closeModal}>Edit Campaign Articles</Button>
                    </LoaderWrapper>
                </Flex>
            </ModalBody>
        </Modal>
    )
}

export default PublishErrorModal;