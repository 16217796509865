import { Flex } from '@rebass/grid';
import React from 'react'
import { LoaderWrapper } from '../../components';
import ClearIcon from './icon/clear';

const StepOneComponent = ({
  hiddenFileInput,
  handleChange,
  handleClose,
  file,
  csvLoading
}) => {
  return (
    <>
      <Flex
        style={{
          display: 'flex',
          justifyContent: 'flex-start',
          width: '83%',
          padding: '40px 5px 0px 0px',
          color: 'black',
          fontSize: '22px',
          margin: '0px auto',
        }}
      >
        Upload CSV File
      </Flex>
      <hr
        style={{
          width: '83%',
          border: '1px solid #E4E7EC',
        }}
      />
      <Flex
        style={{
          display: 'flex',
          justifyContent: 'center',
          width: '60%',
          height: '250px',
          border: '2px dashed #E4E7EC',
          borderRadius: '8px',
          cursor: 'pointer',
          margin: '100px auto',
          textAlign: 'center',
          alignItems: 'center',
          backgroundColor: 'rgb(249, 250, 251)',
        }}
        onClick={(event) => {
          hiddenFileInput.current.click();
        }}
      >
        <LoaderWrapper isLoading={csvLoading}>
          <Flex display="flex" justifyContent="center" flexDirection="column">
            <Flex display="flex" justifyContent="center">
              <img alt=" " src="/assets/images/upload-icon.svg" />
            </Flex>
            <Flex
              style={{
                display: 'flex',
                justifyContent: 'center',

                color: '#0B5CD7',
                fontSize: '16px',
                fontWeight: 500,
              }}
            >
              Click to upload
            </Flex>
            <Flex
              style={{
                color: '#475467',
                fontSize: '12px',
                justifyContent: 'center',
                display: 'flex',
              }}
            >
              Supported formats : CSV, XLSX
            </Flex>
            {file && file[0]?.name ? (
              <Flex
                style={{
                  width: 'max-content',
                  padding: '6px 12px',
                  fontSize: '12px',
                  height: '30px',
                  backgroundColor: 'rgb(236, 242, 252)',
                  color: 'rgb(11, 92, 215)',
                  border: '1px solid rgb(182, 207, 243)',
                  borderRadius: '20px',
                  margin: '10px auto',
                }}
              >
                {file && file[0]?.name}
                <Flex style={{ left: '3px', position: 'relative' }}>
                  <ClearIcon />
                </Flex>
              </Flex>
            ) : null}

          </Flex>
        </LoaderWrapper>
      </Flex>
      <input
        type="file"
        style={{ visibility: 'hidden', height: '0px', width: '0px' }}
        ref={hiddenFileInput}
        multiple={false}
        onChange={handleChange}
        accept=".csv, .xlsx"
      />
    </>
  );
}

export default StepOneComponent