import React from 'react';
import ReactSkeleton from 'react-loading-skeleton';
import {
  Footer,
  InteractionsRates,
  InteractionRateCard,
  FooterActions,
} from './styles';

function CampaignMetricsSkeleton() {
  return (
    <Footer>
      <InteractionsRates>
        <InteractionRateCard>
          <ReactSkeleton width={140} height={20} />
          <ReactSkeleton width={30} height={20} />
        </InteractionRateCard>
        <InteractionRateCard>
          <ReactSkeleton width={140} height={20} />
          <ReactSkeleton width={30} height={20} />
        </InteractionRateCard>
        <InteractionRateCard>
          <ReactSkeleton width={140} height={20} />
          <ReactSkeleton width={30} height={20} />
        </InteractionRateCard>
        <InteractionRateCard>
          <ReactSkeleton width={140} height={20} />
          <ReactSkeleton width={30} height={20} />
        </InteractionRateCard>
      </InteractionsRates>
      <FooterActions>
        <ReactSkeleton width="100%" height={30} />
        <ReactSkeleton width="100%" height={30} />
      </FooterActions>
    </Footer>
  );
}

export default CampaignMetricsSkeleton;
