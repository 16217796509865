import React, { useState } from 'react';
import { Flex } from '@rebass/grid';
import { isEmpty } from 'lodash-es';
import { Button, LoaderWrapper } from '../../../components';
import NewsletterArticleV2 from '../../NewsletterContainer/NewsletterArticle/NewsletterArticleV2';
import { ModalArticlesContainer, AddContentButton, TabContainer } from '../ArticleModal.styles';

const selectTabs = [
    {
        title: 'Bookmarked',
        key: 'bookmarked'
    },
    {
        title: 'Recommended',
        key: 'recommended'
    },
    {
        title: 'My Content',
        key: 'my-content'
    }
];

const AddContentArticlesV2 = ({ 
    articleType, 
    setArticleType, 
    articleData, 
    selectedArticles, 
    themeNames,
    handleArticleClick,
    showLoadMore,
    loadMoreHandler,
    loader,
    updateStep,
    setModalTitle
}) => {
    setModalTitle('Select Content')
    return (
        <Flex width="98%" flexDirection='column' style={{ overflowX: 'auto', overflowY: 'hidden', margin: 'auto' }}>
            <Flex justifyContent='space-between' alignItems='center'>
                <TabContainer>
                    {
                        selectTabs.map((item, index) => {
                            return(
                                <div
                                    key={index}
                                    style={{
                                        color: articleType === item.key ? '#ffffff' : '#000000',
                                        background: articleType === item.key ? '#816EF8' : '#ffffff',
                                        cursor: 'pointer',
                                        padding: '10px 15px',
                                        borderLeft: index > 0 ? '1px solid #e4e7ec' : 0
                                    }}
                                    onClick={() => setArticleType(item.key)}
                                >
                                    {item.title}
                                </div>
                            )
                        })
                    }
                </TabContainer>
                <AddContentButton onClick={()=>updateStep(3)}>+ Add New Content</AddContentButton>
            </Flex>
            <ModalArticlesContainer  justifyContent="space-between" bgColor='#ffffff' border='none'>
                {!isEmpty(articleData[articleType]) ? articleData[articleType].map((item, index) => (
                    <div
                        style={{
                            textAlign: 'center',
                            marginRight: '12px',
                            marginBottom: '10px',
                            borderRadius: '8px',
                            border: selectedArticles.includes(item.id) ? '1px solid #6351ed' : '1px solid #E4E7EC',
                            cursor: 'pointer',
                            width: '23%',
                        }}
                    >
                        <NewsletterArticleV2
                            key={index}
                            articleIndex={index}
                            margin="0px"
                            {...item}
                            themeNames={themeNames}
                            newsletterArticles={selectedArticles}
                            article={item}
                            deleteBlocked
                            width='100%'
                            height='200px'
                            imgWidth='100%'
                            maxImgWidth='100%'
                            articleType={articleType}
                            handleBucketArticleClick={handleArticleClick}
                        />
                    </div>
                )) : (
                    <Flex justifyContent='center' alignItems='center' style={{ width: '100%', height: '300px' }}>No articles found</Flex>
                )}
            </ModalArticlesContainer>
            {articleType === 'my-content' && (
                <LoaderWrapper isLoading={loader}>
                    <Button 
                       margin='20px auto 0px' 
                       width='110px' 
                       borderRadius='4px'
                       bgColor='#ffffff'
                       borderColor='#E4E7EC'
                       color='black'
                       onClick={loadMoreHandler}
                       disabled={!showLoadMore}
                    >
                        Load More
                    </Button>
                </LoaderWrapper>
            )}
        </Flex>
    );
};

export default AddContentArticlesV2;