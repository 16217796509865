import React, { useState } from 'react';
import { CircularProgress, Tooltip } from '@mui/material';
import { useQueryClient } from 'react-query';
import useMutationForAddingBookmark from 'src/pages/Discover/common/components/Article/useMutationForAddingBookmark';
import useMutationForRemovingBookmark from 'src/pages/Discover/common/components/Article/useMutationForRemovingBookmark';
import BookmarkFilledIcon from 'src/assets/icons/BookmarkSimpleFilled';
import Skeleton from './Skeleton';
import BookmarkIcon from 'src/assets/icons/BookmarkSimple';
import { getMediaImage, onImageError } from 'src/utils';
import {
  BookmarkContainer,
  Card,
  CardContent,
  CardContentWrapper,
  CardDescription,
  Cards,
  Container,
  Content,
  Header,
  Image,
  Pill,
  Pills,
  TopCard,
  TopCardContent,
  TopCardDescription,
  TopCardOverlay,
  TopCardsContainer,
} from './styles';
import ZeroState from 'src/pages/Discover/common/components/ZeroState';

function TopRecommended({
  recommendationsData,
  recommendationsLoader,
  recommendationsFetching,
  setSelectedArticle,
}) {
  const queryClient = useQueryClient();
  const [activeBookmark, setActiveBookmark] = useState('');

  const { isLoading: addBookmarkLoader, mutate: addBookmarkMutate } =
    useMutationForAddingBookmark();
  const { isLoading: removeBookmarkLoader, mutate: removeBookmarkMutate } =
    useMutationForRemovingBookmark();

  const isLoading = (recommendedData) => {
    return (
      activeBookmark === recommendedData?.id &&
      (addBookmarkLoader || removeBookmarkLoader || recommendationsFetching)
    );
  };

  const invalidateQueries = () => {
    queryClient.refetchQueries(['discover-recommended-articles']);
    setSelectedArticle('');
    setActiveBookmark('');
  };

  const redirectLink = (recommendedData) => {
    window.open(`${recommendedData?.content_url}`, '_blank');
  };

  if (recommendationsLoader) return <Skeleton />;
  if (
    !recommendationsLoader &&
    recommendationsData &&
    recommendationsData?.length === 0
  ) {
    return (
      <Container>
        <Header>
          <h3>Top Recommended</h3>
          <p>
            Based on engagement, consider using this content for your upcoming
            campaigns
          </p>
        </Header>
        <ZeroState />
      </Container>
    );
  }

  return (
    <Container>
      <Header>
        <h3>Top Recommended</h3>
        <p>
          Based on engagement, consider using this content for your upcoming
          campaigns
        </p>
      </Header>
      <Content>
        <Cards>
          <TopCardsContainer>
            {Array.isArray(recommendationsData) ? (
              [...recommendationsData]?.splice(0, 2)?.map((recommendedData) => (
                <TopCard
                  background={getMediaImage(
                    recommendedData?.media_url,
                    'original'
                  )}
                  key={recommendedData?.id}
                >
                  <TopCardOverlay
                    className="overlay"
                    onClick={() => redirectLink(recommendedData)}
                  >
                    <Pills>
                      {[...recommendedData?.tags]?.splice(0, 4)?.map((tag) => (
                        <Pill key={tag}>{tag}</Pill>
                      ))}
                      {/* {recommendedData?.tags?.length - 3 > 0 && (
                        <Pill>+{recommendedData?.tags?.length}</Pill>
                      )} */}
                    </Pills>
                    <TopCardContent>
                      <div>
                        <h5>{recommendedData?.name ?? ''}</h5>
                        <TopCardDescription>
                          <p>{recommendedData?.created_date ?? ''}</p>
                          <div className="separator"></div>
                          <p className="publisher-name">
                            {recommendedData?.publisher_name ?? ''}
                          </p>
                        </TopCardDescription>
                      </div>
                      <>
                        {isLoading(recommendedData) ? (
                          <BookmarkContainer variant="outline" width={190}>
                            <CircularProgress size={15} />
                          </BookmarkContainer>
                        ) : (
                          <>
                            {recommendedData?.is_bookmarked ? (
                              <BookmarkContainer
                                variant="outline"
                                bookmarked={false}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setActiveBookmark(recommendedData?.id);
                                  removeBookmarkMutate(
                                    recommendedData?.bookmark_id,
                                    {
                                      onSuccess: () => invalidateQueries(),
                                    }
                                  );
                                }}
                              >
                                <BookmarkFilledIcon />
                                <h4>Bookmarked</h4>
                              </BookmarkContainer>
                            ) : (
                              <BookmarkContainer
                                variant="outline"
                                bookmarked={true}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setActiveBookmark(recommendedData?.id);
                                  addBookmarkMutate(
                                    recommendedData?.article_bookmark_id,
                                    {
                                      onSuccess: () => invalidateQueries(),
                                    }
                                  );
                                }}
                              >
                                <BookmarkIcon />
                                <h4>Bookmark</h4>
                              </BookmarkContainer>
                            )}
                          </>
                        )}
                      </>
                    </TopCardContent>
                  </TopCardOverlay>
                  <h5 className="main-title">{recommendedData?.name ?? ''}</h5>
                </TopCard>
              ))
            ) : (
              <></>
            )}
          </TopCardsContainer>
        </Cards>
        <Cards>
          {Array.isArray(recommendationsData) ? (
            [...recommendationsData]?.splice(2, 1)?.map((recommendedData) => (
              <Card
                key={recommendedData?.id}
                onClick={() => redirectLink(recommendedData)}
              >
                <Image
                  src={getMediaImage(recommendedData?.media_url, 'original')}
                  alt=""
                  width="100"
                  height="90"
                  onError={onImageError}
                />
                <CardContentWrapper>
                  <CardContent>
                    <h5>{recommendedData?.name ?? ''}</h5>
                    <CardDescription>
                      <p>{recommendedData?.created_date ?? ''}</p>
                      <div className="separator"></div>
                      <Tooltip
                        title={recommendedData?.publisher_name ?? ''}
                        arrow="top"
                      >
                        <p className="publisher-name">
                          {recommendedData?.publisher_name ?? ''}
                        </p>
                      </Tooltip>
                    </CardDescription>
                  </CardContent>
                  {isLoading(recommendedData) ? (
                    <BookmarkContainer variant="outline" width={42}>
                      <CircularProgress size={15} />
                    </BookmarkContainer>
                  ) : (
                    <>
                      {recommendedData?.is_bookmarked ? (
                        <BookmarkContainer
                          variant="outline"
                          bookmarked={true}
                          onClick={(e) => {
                            e.stopPropagation();
                            setActiveBookmark('');
                            removeBookmarkMutate(recommendedData?.bookmark_id, {
                              onSuccess: () => invalidateQueries(),
                            });
                          }}
                        >
                          <BookmarkFilledIcon />
                        </BookmarkContainer>
                      ) : (
                        <BookmarkContainer
                          variant="outline"
                          bookmarked={false}
                          onClick={(e) => {
                            e.stopPropagation();
                            setActiveBookmark(recommendedData?.id);
                            addBookmarkMutate(
                              recommendedData?.article_bookmark_id,
                              {
                                onSuccess: () => invalidateQueries(),
                              }
                            );
                          }}
                        >
                          <BookmarkIcon />
                        </BookmarkContainer>
                      )}
                    </>
                  )}
                </CardContentWrapper>
              </Card>
            ))
          ) : (
            <></>
          )}
        </Cards>
      </Content>
    </Container>
  );
}

export default TopRecommended;
