import { Flex } from '@rebass/grid';
import get from 'lodash/get';
import React, { useState } from 'react';
import { DripIconButton, LoaderWrapper, MioDatePicker } from '../../../components';
import { LandingPageCard, LandingPageCardWrapper, LandingPageTooltip } from 'src/containers/DripCampaignContainerV2/AddContent/AddContent.styles';
import { isEmpty } from 'lodash';
import { ColorPallete, Typography, convertDateToRequiredFormat, timeZoneAbbreviated } from 'src/utils';
import Checkbox from 'src/componentsV2/Checkbox';
import { DateTimeText, DateTimeWrapper } from 'src/containers/DripCampaignContainerV2/Preview&Schedule/PreviewAndSchedule.styles';
import moment from 'moment';
import { StyledTextInput } from 'src/containers/AutomatedCampaigns/styles';
import DatePickerV2 from 'src/components/DatePickerV2/DatePickerV2';


const AddContentCustomRowV2 = ({
    layout,
    step,
    loader,
    customRows,
    selectedLandingPage,
    selectedCustomRow,
    setSelectedCustomRow,
    setSelectedLandingPage,
    createMode,
    automatedFlowFromDashboard,
    selectedPage,
    showSettings,
    showTime,
    setShowTime,
    showCount,
    setShowCount,
    count,
    setCount,
    eventDate,
    setEventDate,
    userData,
    settingsLoader
}) => {

    const [isDatepickerOpen, setIsDatepickerOpen] = useState(false);
    return (
        <div>
            {!showSettings ? (
                <>
                    <Flex width='100%' justifyContent='center' alignItems='center'>
                        {layout === 'landing-pages' && step === 1 ? 'Select A Landing Page' : 'Select Custom Row Style'}
                    </Flex>
                    <Flex
                        width="100%"
                        justifyContent="center"
                        alignItems="center"
                        style={{ overflowX: 'auto' }}
                    >
                        <LandingPageCardWrapper
                            style={{
                                height: '348px',
                                maxHeight: '348px',
                                overflowY: 'auto',
                                padding: '0px 15px',
                                position: 'relative',
                                margin: '40px 0px 0px 50px'
                            }}
                        >
                            <LoaderWrapper
                                isLoading={loader}
                            >
                                {
                                    !isEmpty(customRows) ? (
                                        customRows.map((row, index) => {
                                            const rowName = ((createMode === 'automated' || automatedFlowFromDashboard) && layout === 'landing-pages') ? selectedLandingPage.name : row.name;
                                            console.log(row, rowName)
                                            return (
                                                <LandingPageCard
                                                    key={index}
                                                    borderColor={
                                                        (layout === 'landing-pages' && step === 1 && get(selectedLandingPage, '_id', '') === row?._id) ||
                                                            selectedCustomRow === row?._id ?
                                                            ColorPallete.primaryPurple
                                                            : ''
                                                    }
                                                    style={{ cursor: 'pointer' }}
                                                    onClick={() => {
                                                        layout === 'landing-pages' && step === 1 ?
                                                            setSelectedLandingPage(row) :
                                                            setSelectedCustomRow(row._id)
                                                    }}
                                                >
                                                    <Flex
                                                        display="flex"
                                                        justifyContent="center"
                                                        padding="5px"
                                                        width="100%"
                                                        height="84%"
                                                        style={{
                                                            alignItems: 'center',
                                                            borderBottom: '1px solid #E4E7EC',
                                                        }}
                                                    >
                                                        <img
                                                            height="100%"
                                                            width="100%"
                                                            alt=""
                                                            style={{ objectFit: 'contain' }}
                                                            src={
                                                                row?.thumbnail_url ||
                                                                '/assets/images/postDefault4.jpg'
                                                            }
                                                        />
                                                    </Flex>
                                                    <Flex
                                                        justifyContent="center"
                                                        style={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            padding: '8px',
                                                            fontSize: '12px',
                                                            height: '36px',
                                                            justifyContent: 'left',
                                                        }}
                                                    >
                                                        <LandingPageTooltip>
                                                            <div
                                                                style={{
                                                                    width: '100%',
                                                                    display: 'flex',
                                                                    justifyContent: 'left',
                                                                    color: '#101828',
                                                                }}
                                                            >
                                                                {rowName && rowName.length > 33
                                                                    ? `${rowName.substring(0, 33)}...`
                                                                    : rowName}
                                                            </div>
                                                            {rowName && rowName.length > 33 && (
                                                                <span className="tooltiptext">{rowName}</span>
                                                            )}
                                                        </LandingPageTooltip>
                                                    </Flex>
                                                </LandingPageCard>
                                            )
                                        })
                                    ) : (
                                        <Flex
                                            style={{
                                                width: '100%',
                                                height: '388px',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                            }}
                                        >
                                            No Content to show
                                        </Flex>
                                    )
                                }
                            </LoaderWrapper>


                        </LandingPageCardWrapper>

                    </Flex>
                </>
            ) : (
                <Flex flexDirection="column" padding="0px 10px" height="250px">
                    <LoaderWrapper isLoading={settingsLoader}>
                        <Flex margin="10px 0px" width='100%' alignItems='center' color={ColorPallete.neutral900} fontSize={Typography.TextMedium}>
                            <Checkbox checked={showTime} onClick={() => setShowTime(!showTime)} />
                            <Flex pl="9px">
                                Close Registration after closing date
                            </Flex>
                        </Flex>
                        {showTime && (
                            <DateTimeWrapper width="35%" color={ColorPallete.black} style={{
                                border: `1px solid #8493a6`
                            }}>
                                <DateTimeText>
                                    {eventDate &&
                                        convertDateToRequiredFormat({
                                            date: eventDate,
                                            format: 'MM/DD/YYYY h:mm a',
                                        }) + ' (' + timeZoneAbbreviated(get(
                                            userData,
                                            'details.user.timezone',
                                            ''
                                        )) + ')'}
                                </DateTimeText>
                                <DripIconButton
                                    onClick={() => setIsDatepickerOpen(true)}
                                    icon="clock"
                                    color="#8493A6"
                                    styles={{ marginTop: '5px' }}
                                    forAutomatedCampaigns={true}
                                />
                                <DatePickerV2
                                    isOpen={isDatepickerOpen}
                                    customInput={<div />}
                                    minDate={new Date()}
                                    onDateClose={() => {
                                        setIsDatepickerOpen(false);

                                    }}
                                    onSubmit={(date) => {
                                        setEventDate(date);
                                        setIsDatepickerOpen(false);
                                    }}
                                    timeZone={get(
                                        userData,
                                        'details.user.timezone',
                                        ''
                                    )}
                                    scheduleValue={eventDate}
                                    isFromAC
                                    hourAndMins
                                />
                            </DateTimeWrapper>
                        )}
                        <Flex margin="10px 0px" width='100%' alignItems='center' color={ColorPallete.neutral900} fontSize={Typography.TextMedium}>
                            <Checkbox checked={showCount} onClick={() => setShowCount(!showCount)} />
                            <Flex pl="9px">
                                Restrict Number of Registrants
                            </Flex>
                        </Flex>
                        {showCount && (
                            <StyledTextInput
                                style={{
                                    width: '35%'
                                }}
                                type="number"
                                placeholder="Registrants Count"
                                value={count}
                                onChange={(e) => setCount(e.target.value)}
                                name="registrants_count"
                            />
                        )}
                        {showCount && (
                            <Flex pt="3px">
                                You can set the limit up to 5000
                            </Flex>
                        )}
                    </LoaderWrapper>
                </Flex>

            )}

            {/* <Flex flexDirection='column' justifyContent='flex-start' alignItems='center' style={{ maxHeight: '300px', overflowY: 'auto' }}>
                <LoaderWrapper isLoading={loader}>
                    {customRows.length > 0 ? customRows.map((row, index) => (
                        <Flex
                            style={{
                                cursor: 'pointer',
                                border:
                                    (layout === 'landing-pages' && step === 1 && get(selectedLandingPage, '_id', '') === row._id) ||
                                        selectedCustomRow === row._id ?
                                        '1px solid #6351ed'
                                        : '1px solid #cacaca',
                                borderRadius: '2px',
                                marginBottom: '12px',
                            }}
                            onClick={() =>
                                layout === 'landing-pages' && step === 1 ?
                                    setSelectedLandingPage(row) :
                                    setSelectedCustomRow(row._id)
                            }
                            id={row._id}
                            width='76%'
                            margin='auto'
                            justifyContent='center'
                            alignItems='center'
                            flexDirection='column'
                            htmlFor={`row_${index}`}
                            key={index}
                        >
                            <img
                                alt="custom_row"
                                style={{
                                    width: '100%',
                                    height: layout === 'landing-pages' && step === 1 ? '220px' : '150px',
                                    objectFit: 'contain'
                                }}
                                src={row.thumbnail_url}
                            />
                            <div style={{ color: selectedCustomRow === row._id ? '#6351ed' : '' }}>{((createMode === 'automated' || automatedFlowFromDashboard) && layout === 'landing-pages') ? selectedLandingPage.name : row.name}</div>
                        </Flex>
                    )) : (
                        <div>
                            No Rows found for the selectedLayout
                        </div>
                    )}
                </LoaderWrapper>
            </Flex> */}
        </div>
    );
};

export default AddContentCustomRowV2;