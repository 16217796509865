import styled from 'styled-components';

export const SearchInputWrapper = styled.div`
  position: relative;
  input[type='text'] {
    text-align: center;
  }
  .btn-clear,
  .btn-search {
    top: 10px;
    position: absolute;
  }
  .btn-search {
    right: 2px;
  }
  .btn-clear {
    right: 20px;
  }
`;
