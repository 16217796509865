import React, { useMemo, useState } from 'react';
import SearchIcon from 'src/assets/icons/Search';
import Multiply from 'src/assets/icons/Multiply';
import Plus from 'src/assets/icons/PlusIcon';
import {
  Wrapper,
  SearchContainer,
  Pills,
  Pill,
  SearchResultsPillsWrapper,
} from './styles';
import SnackbarComponent from 'src/components/Snackbar';

function TopicsSearch({
  topics,
  selectedTopics,
  setSelectedTopics,
  showSelectedTopics = true,
  topicSelectionLimit = 100,
}) {
  const [searchString, setSearchString] = useState('');
  const [showSnackbar, toggleSnackbar] = useState(false);

  const searchResults = useMemo(() => {
    if (!!searchString) {
      const filteredTopics = Array.isArray(topics)
        ? topics?.filter((topic) =>
            topic?.theme_name?.toLowerCase()?.includes(searchString)
          )
        : [];

      return filteredTopics;
    }

    return topics;
  }, [searchString, topics]);

  const handleClose = () => {
    toggleSnackbar(false);
  };

  return (
    <Wrapper>
      {showSelectedTopics &&
        Array.isArray(selectedTopics) &&
        selectedTopics?.length > 0 && (
          <Pills>
            {selectedTopics?.map((topic) => (
              <Pill
                key={topic?.theme_name}
                selected
                onClick={() => {
                  const filtered = selectedTopics?.filter(
                    (t) => t?.theme_name !== topic?.theme_name
                  );
                  setSelectedTopics(filtered);
                }}
              >
                {topic?.theme_name} <Multiply />
              </Pill>
            ))}
          </Pills>
        )}

      <SearchContainer>
        <SearchIcon className="search-icon" />
        <input
          value={searchString}
          onChange={(e) => setSearchString(e?.target?.value?.toString())}
          placeholder="Search Topics"
        />
      </SearchContainer>
      <SearchResultsPillsWrapper>
        {Array.isArray(searchResults) && searchResults?.length > 0 && (
          <Pills>
            {searchResults?.map((topic) => (
              <Pill
                key={topic?.theme_name}
                onClick={() => {
                  if (selectedTopics?.length < topicSelectionLimit) {
                    setSelectedTopics([
                      ...selectedTopics?.filter(
                        (t) => t?.theme_name !== topic?.theme_name
                      ),
                      topic,
                    ]);
                  } else {
                    toggleSnackbar(true);
                  }
                }}
              >
                <Plus /> {topic?.theme_name}
              </Pill>
            ))}
          </Pills>
        )}
      </SearchResultsPillsWrapper>
      <SnackbarComponent
        variant="warning"
        open={showSnackbar}
        handleClose={handleClose}
        content={`You have subscribed to maximum number of topics allowed (${topicSelectionLimit}).`}
      />
    </Wrapper>
  );
}

export default TopicsSearch;
