import React from 'react';
import { Box, Flex } from '@rebass/grid';
import { ActionsWrapper, AutomatedCampaignListItemWrapper } from './styles';
import AutomatedCampaignImageGenerator from './AutomatedCampaignImageGenerator';
import AutomatedCampaignInfo from './AutomatedCampaignInfo';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import { useState } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import { exportToPdfAc, getAutomatedBlocksById, automatedCampaignSendTestModal } from '../../../../services/automated-campaigns';
import { useHistory } from 'react-router-dom';
import ViewInfoModal from './ViewInfoModal';
import EmailBlockPreview from './EmailBlockPreview';
import AcExportToPdf from './AcExportToPdf';
import { isEmpty } from 'lodash';
import AutomatedCampaignEmailPreviewModal from '../../../AutomatedCampaigns/CampaignCreateSteps/AutomatedCampaignEmailPreviewModal';
import AutomatedCampaignSocialPreviewModal from '../../../AutomatedCampaigns/CampaignCreateSteps/AutomatedCampaignSocialPreviewModal';
import SendTestMailModal from '../../../AutomatedCampaigns/CampaignCreateSteps/SendTestMailModal';


const EmailSeriesBlock = ({ 
  block, 
  showSeries, 
  campaignSetState, 
  segment, 
  isEnterpriseAdvisor, 
  selectedCampaignTab 
}) => {
  const [showIndividualActions, setShowIndividualActions] = useState(false);
  const [showViewInfo, setShowViewInfo] = useState(false);
  const [showEmailPreview, setShowEmailPreview] = useState(false);
  const [showExport, setShowExport] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [emailList, setEmailList] = useState([]);
  const [invalidEmailMsg, setInvalidEmailMsg] = useState(false);
  const [loading, setLoading] = useState('');
  const [openSendTestEmailModal, setOpenSendTestEmailModal] = useState(false);
  const [sendTestEmail, setSendTestEmail] = useState('');
  const [requestLoading, setRequestLoading] = useState(false);
  const [sendTestErrorMsg, setSendTestErrorMsg] = useState('');
  //const [openSendTestEmailModal, setOpenSendTestEmailModal] = useState(false);
  const [showSocialPreview, setShowSocialPreview] = useState(false);

  const closeSendTestModal = () => setOpenSendTestEmailModal(false);
  const openSendTestModal = () => setOpenSendTestEmailModal(true);
  
  const history = useHistory();
  //   TODO: Display respective actions based on the status of campaign.
  const getIndividualActions = () => {
    let individualActions = isEnterpriseAdvisor && selectedCampaignTab === 9 ? [
      {
        title: 'Preview',
        onClick: () => {block?.platform === "email" ? setShowEmailPreview(true) : setShowSocialPreview(true)},
      }
    ] :[
      {
        title: 'Edit',
        onClick: () => handleEdit(block.id, block.ac_id),
      },
      // {
      //   title: 'Delete',
      //   onClick: () => alert('Delete action'),
      // },
      {
        title: 'Preview',
        onClick: () => {block?.platform === "email" ? setShowEmailPreview(true) : setShowSocialPreview(true)},
      },
      {
        title: 'View Info',
        onClick: () => setShowViewInfo(true),
      },
      {
        title: 'Export to PDF',
        onClick: () => setShowExport(true),
      },
    ];

    if (block.newsletter_id) {
      individualActions.push({
        title: 'Send Test',
        onClick: () => {
         openSendTestModal(true);
        },
      })
    }

    if(block.status === 'draft') {
      individualActions = individualActions.filter((action) => action.title !== 'Export to PDF');
    }

    return block.status === 'active' || block.status === 'completed' || block.status === 'compliance_pending' || block.status === 'Completed (cancelled)' || block.status === 'partially_completed'
      ? individualActions.filter((action) => action.title !== 'Edit')
      : individualActions;
  };

  const handleEdit = async (id, acId) => {
    try {
      const selectedBlockItem = await getAutomatedBlocksById(acId, id);
      campaignSetState({
        fromCreateEmail: true,
        selectedBlockId: selectedBlockItem.data.id,
        automatedCampaigns: {
          campaignId: acId,
          targetInfo: {
            target_page_id:
              selectedBlockItem &&
              selectedBlockItem.data &&
              selectedBlockItem.data.target_page_id,
            type:
              selectedBlockItem &&
              selectedBlockItem.data &&
              selectedBlockItem.type,
          },
        },
        selectedBlock: {
          name:
            selectedBlockItem &&
            selectedBlockItem.data &&
            selectedBlockItem.data.name,
          purpose:
            selectedBlockItem &&
            selectedBlockItem.data &&
            selectedBlockItem.data.purpose,
          subject:
            selectedBlockItem &&
            selectedBlockItem.data &&
            selectedBlockItem.data.subject,
          template_id:
            selectedBlockItem &&
            selectedBlockItem.data &&
            selectedBlockItem.data.template_id,
          template_html:
            selectedBlockItem &&
            selectedBlockItem.data &&
            selectedBlockItem.data.template_html,
          template_json:
            selectedBlockItem &&
            selectedBlockItem.data &&
            selectedBlockItem.data.template_json,
          block_type:
            selectedBlockItem &&
            selectedBlockItem.data &&
            selectedBlockItem.data.b_type,
            status:
            selectedBlockItem &&
            selectedBlockItem.data &&
            selectedBlockItem.data.status,
        },
        automatedFlowFromDashboard: true,
      });
      if(selectedBlockItem?.data?.platform === 'email') {
        history.push(`/create-email/${id}/${acId}`);
      } else {
        history.push(`/create-social/${id}/${acId}`);
      }
      
    } catch (error) {
      console.log(error);
    }
  };

  const handlePdfClose = () => {
    setShowExport(false);
  }

  const handleExportSubmit = () => {
    setLoading(true);
    setErrorMsg('');
    setInvalidEmailMsg(false);
    let payload = {
      block_id: block.id,
      email_ids: JSON.stringify(
        emailList.length > 0
          ? emailList.split(',').map((email) => email.trim())
          : []
      )
    }
    exportToPdfAc(payload).then(res => {
      setLoading(false);
      if (res.result.success) {
        setIsSubmit(true);
        setTimeout(() => {
          handlePdfClose();
        }, 3000);
      } 
      else {
        setInvalidEmailMsg(true);
        setErrorMsg('Something went wrong!');
      }
    }).catch(err => {
      setLoading(false);
      setInvalidEmailMsg(true);
      setErrorMsg('Something went wrong!');
      console.log(err);
    })
  }


  const handleSendClick = () => {
    console.log('clicked');
    // TO DO HANDLE EMAIL VALIDATION AND COUNT VALIDATION
    setSendTestErrorMsg('');
    let msg = 'Email is not valid';
    const isEmailRegEx =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,3}))$/;
    let valid = true;
    if (valid && !isEmpty(sendTestEmail)) {
      valid = sendTestEmail
        .split(',')
        .map((em) => em.trim())
        .every((em) => isEmailRegEx.test(em.toLowerCase()));
      if (sendTestEmail.split(',').length > 5 && valid) {
        valid = false;
        msg = 'Maximum 5 emails can be added';
      }
    }
    if (!valid) {
      setSendTestErrorMsg(msg);
      return;
    };
    setRequestLoading(true);
    const payload = {
      block_id: block.id,
      email_ids: sendTestEmail
    }
    automatedCampaignSendTestModal(payload)
      .then((res) => {
        console.log('send')
        if (res?.result?.success) {
          setRequestLoading(false);
          setSendTestErrorMsg(
            'Sent Successfully'
          );
          setTimeout(() => {
            setSendTestErrorMsg('');
            setOpenSendTestEmailModal(false);
          }, 3000);
        } else {
          setRequestLoading(false);
          setSendTestErrorMsg(
            res?.result?.message
          );
        }
        setSendTestEmail('');
      })
      .catch((error) => {
        console.log(error)
        setRequestLoading(false);

      });
    setSendTestErrorMsg();
  };

  const handleSendTestValidation = () => {
    if (!sendTestEmail) {
      return true;
    }
  };

  return (
    <AutomatedCampaignListItemWrapper
      justifyContent="space-between"
      id={block.id}
      alignItems="center"
      width={'90%'}
      alignSelf="flex-end"
      style={{
        animation: showSeries ? 'slide-up 0.4s ease' : 'slide-down 0.4s ease',
      }}
    >
      <Flex width="100%" alignItems="center">
        <AutomatedCampaignImageGenerator media_urls={block?.media_urls} />

        <AutomatedCampaignInfo
          name={block?.name || '-'}
          start_date={''}
          status={block?.status || '-'}
          purpose={block?.purpose || '-'}
          social_pages={block?.social_pages}
          // isEmailBlock={true}
          platform={block?.platform}
          isEnterpriseAdvisor={isEnterpriseAdvisor}
          selectedCampaignTab={selectedCampaignTab}
          is_global_compliance={block?.is_global_compliance}
          is_partially_completed={block?.is_partially_completed}
          blockLevel
        />

        <ActionsWrapper style={{ margin: '0px 10px' }} alignItems="center">
          <FontAwesomeIcon
            style={{ cursor: 'pointer', fontSize: '25px' }}
            icon={faEllipsisV}
            onClick={() => setShowIndividualActions(!showIndividualActions)}
          />

          {showIndividualActions && (
            <OutsideClickHandler
              onOutsideClick={() =>
                setShowIndividualActions(!showIndividualActions)
              }
            >
              <Box className="actions" style={{top: '1px'}}>
                {React.Children.toArray(
                  getIndividualActions().map((action, index) => (
                    <p
                      className="action-item cursor-pointer"
                      onClick={() => action.onClick()}
                    >
                      {action.title}
                    </p>
                  ))
                )}
              </Box>
            </OutsideClickHandler>
          )}
        </ActionsWrapper>
      </Flex>

      {showViewInfo && (
        <ViewInfoModal
          individualEmailBlock={true}
          isOpen={showViewInfo}
          handleClose={() => setShowViewInfo(false)}
          blocks={[block]}
          segment={segment || '-'}
        />
      )}

      {showEmailPreview && (
        <AutomatedCampaignEmailPreviewModal
          isOpen={showEmailPreview}
          handleClose={() => setShowEmailPreview(false)}
          block_id={block.id}
          campaign_id={block.ac_id}
          template_html={block?.template_html}
          subject={block?.subject}
          name={block?.name}
        />
      )}

      {showSocialPreview && (
        <AutomatedCampaignSocialPreviewModal
          isOpen={showSocialPreview}
          handleClose={() => setShowSocialPreview(false)}
          block_id={block?.id}
          media_urls={block?.media_urls}
          subject={block?.articles?.[0]?.description}
          title={block?.article?.[0]?.title}
          campaign_id={block?.ac_id}
          is_facebook={block?.social_pages?.facebook}
          is_twitter={block?.social_pages?.twitter}
          is_linkedin={block?.social_pages?.linkedin}
        />
      )}

      {showExport && (
        <AcExportToPdf
          isOpen={showExport}
          handleClose={handlePdfClose}
          title={block?.name || '-'}
          isSubmit={isSubmit}
          errorMsg={errorMsg}
          setEmailList={setEmailList}
          setErrorMsg={setErrorMsg}
          setInvalidEmailMsg={setInvalidEmailMsg}
          emailList={emailList}
          loading={loading}
          invalidEmailMsg={invalidEmailMsg}
          handleSubmit={handleExportSubmit}
        />
      )}

      {openSendTestEmailModal && (
            <SendTestMailModal
              handleModalClose={closeSendTestModal}
              isOpen={openSendTestEmailModal}
              subject={block?.subject}
              handleChange={(e) => setSendTestEmail(e.target.value)}
              value={sendTestEmail}
              modalButtonOnClick={handleSendClick}
              validation={handleSendTestValidation()}
              sendTestErrorMsg={sendTestErrorMsg}
              loading={requestLoading}
            />
          )}
    </AutomatedCampaignListItemWrapper>
  );
};

export default EmailSeriesBlock;
