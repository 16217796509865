import {
  BAR_GRAPH_COLORS,
  CONTACTS,
  EMAIL_LIST,
  SEGMENT_LIST,
} from './constants';

export const dropdownOptions = [
  {
    label: 'Contacts',
    value: CONTACTS,
  },
  {
    label: 'Email List',
    value: EMAIL_LIST,
  },
  {
    label: 'Segment List',
    value: SEGMENT_LIST,
  },
];

export const transformGraphResponse = (res) => {
  if (Array.isArray(res) && res?.length > 0) {
    return res.map((data, index) => ({
      ...data,
      name: data?.list_name ?? '',
      label: 'Engagement Score',
      fill: BAR_GRAPH_COLORS[index] || '#EBD0FC',
    }));
  }
  return [];
};

export const transformListsResponse = (res) => {
  let transformedData = [];
  if (Array.isArray(res?.data) && res?.data?.length > 0) {
    transformedData = res?.data.map((data) => ({
      ...data,
      name: data?.list_name ?? '',
    }));
  }
  return {
    data: transformedData,
    pagination: res?.pagination ?? {},
  };
};

export const tooltipPositionForTriangleShape = (position) => {
  switch (position) {
    case 'MIDDLE':
      return {
        shape1: { left: '125px' },
        shape2: { left: '126px' },
      };
    case 'RIGHT':
      return {
        shape1: { right: '10px' },
        shape2: { right: '11px' },
      };
    default:
      return {
        shape1: { left: '11px' },
        shape2: { left: '12px' },
      };
  }
};
