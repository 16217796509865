import { Flex } from '@rebass/grid'
import React from 'react'
import { ColorPallete, Typography } from '../../utils'
import { Tooltip } from '@mui/material'

const ProgressBar = ({
    value,
    label,
    pb,
    tooltip,
    tooltipText
}) => {
  return (
    <Flex 
        width="100%"
        height="8px"
        backgroundColor={ColorPallete.neutral100} 
        style={{
            borderRadius: '4px',
            position: 'relative',
            top: '8px'
        }}
    >
        <Flex 
            width={`${value}%`}
            style={{
                borderRadius: '4px'
            }}
            height="8px"
            backgroundColor={ColorPallete.neutral900}    
        >
        </Flex>
        <Flex width="100%" justifyContent="space-between" style={{
            position: 'absolute',
            bottom: '10px'
        }}>
            <Flex color={ColorPallete.neutral600} fontSize={Typography.TextMedium} paddingBottom={pb}>
                {label || 'Engagement'}
                  {tooltip && (
                      <Tooltip placement="right" title={tooltipText} arrow>
                          <Flex>
                              <img
                                  style={{
                                      width: '16px',
                                      margin: '0px 0px 0px 4px',
                                  }}
                                  src="/assets/images/Analytics/Grey-Info.svg"
                                  alt=""
                              />
                          </Flex>
                      </Tooltip>
                  )}
            </Flex>
            <Flex color={ColorPallete.neutral600} fontSize={Typography.TextMedium}>
                {value}
            </Flex>
        </Flex>
    </Flex>
  )
}

export default ProgressBar