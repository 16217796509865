import styled from 'styled-components';

export const PdfWrapper = styled.div`
  background-color: #fff;
`;

export const PdfHeader = styled.div`
  width: 100%;
  background-color: #e4e4e4;
  text-align: right;
  height: 85px;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 88px 40px 0px 40px;
  height: 950px;
`;

export const Title = styled.div`
  color: #6351ed;
  font-size: 25px;
  font-weight: bold;
  text-align: left;
  margin-left: 7%;
  margin-top: 5%;
`;

export const DateRange = styled.div`
  color: #6351ed;
  font-size: 22px;
  text-align: left;
  margin-left: 7%;
  margin-top: 1%;
`;

export const CardContainer = styled.div`
  margin: 30px 40px 0px 40px;
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

export const Card = styled.div`
  height: auto;
  border: 2px solid #E4E4E4;
  border-radius: 8px;
  margin: 1% 3% 0 1%;
  width: 30%;
`;

export const CardBody = styled.div`
  display: flex;
  flex-direction: column;
  padding: 5px 10px;
  align-items: center;
  justify-content: center;
`;

export const CardCount = styled.div`
  text-align: center;
  color: #6351ed;
  font-weight: bold;
`;

export const CardTitle = styled.div`
  color: #6351ed;
  font-size: 16px;
  text-align: center;
  padding-top: 10px;
`;

export const PageSection = styled.div`
  display: flex;
  flex-direction: row;
  margin: 40px 20px 0px 20px;
  justify-content: space-between;
  align-items: center;
`;

export const OtherPageSection = styled.div`
display: flex;
flex-direction: row;
margin: -80px 20px 0px 20px;
justify-content: space-between;
align-items: center;
`;

export const OtherPageTitle = styled.div`
color: #6351ed;
font-size: 25px;
font-weight: bold;
text-align: left;
margin-left: 7%;
`;

export const GraphWrapper = styled.div`
text-align: center;
margin-top: 1%;
margin-left: 18%;
`;

export const GraphTitle = styled.div`
color: #6351ed;
font-size: 18px;
font-weight: bold;
padding-top: 1%;
`

export const SubTitle = styled.p`
color: #6351ed;
font-size: 15px;
text-align: left;
margin-left: 7%;
`;