import React, { useRef, useEffect } from 'react';
import { EmailPreviewContainer } from './styles';

function EmailPreview({ data = {} }) {
  const iframeRef = useRef(null);

  useEffect(() => {
    setTimeout(() => {
      if (iframeRef.current) {
        const css = document.createElement('style');
        let st = '::-webkit-scrollbar { width: 5px }';
        st += ' ::-webkit-scrollbar-track { background: #e9f6e6 !important }';
        st += '::-webkit-scrollbar-thumb { background: #bccdd4 !important }';
        css.appendChild(document.createTextNode(st));
        iframeRef.current.contentDocument.head.append(css);
      }
    }, 100);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [iframeRef?.current]);

  return (
    <EmailPreviewContainer>
      <h2>
        Subject : <span>{data?.subject ?? ''}</span>
      </h2>
      <div className="template-container">
        <iframe ref={iframeRef} srcDoc={data?.template} title="email_preview" />
      </div>
    </EmailPreviewContainer>
  );
}

export default EmailPreview;
