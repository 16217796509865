import React from 'react';
import { isEmpty } from 'lodash';
import { 
  IconContainer, 
  IconBlock,
  IntegrationIcon,
  ConnectButton,
} from '../SocialCheckBoxes/styles';
import { Tooltip } from '@mui/material';
import ThemeBubbles from '../../componentsV2/ThemeBubbles';
import linkIcon from '../../assets/icons/linkIcon.svg';
import disconnectIcon from '../../assets/icons/disconnectIcon.svg';

const SocialLoginButtons = ({ buttons, onBottomTextClick }) => {
  const onBackButtonClick = (e, platform, emailPlaftform) => {
    e.stopPropagation();
    onBottomTextClick(platform, emailPlaftform);
  };

  if (!isEmpty(buttons)) {
    return (
      <IconContainer style={{flexFlow: 'rows', flexWrap: 'wrap', justifyContent: 'start'}}>
        {buttons.map((button, index) => {
          return (
            <IconBlock style={{ margin: '16px 16px 0px 0px'}} key={index}>
                {button.icon && (
                  <div style={{display: 'flex', justifyContent: 'start'}}>
                    <IntegrationIcon
                      src={button.icon}
                      alt={button.id}
                    />     
                    <div style={{margin: '30px 0 0 0px', color: '#000000', fontSize: 16}}>{button.name}</div>
                  </div>           
                )}
                {button.bottomText && (
                  <div>
                    {
                      button.isDisconnected ? (
                        <div style={{display: 'flex', justifyContent: 'space-between', margin: 0}}>
                          <div style={{display: 'table'}}>
                            <Tooltip title={button.account}>
                              <div
                                style={{
                                  margin: '10px 0 0 20px',
                                  textOverflow: 'ellipsis',
                                  overflow: 'hidden',
                                  height: 20,
                                  whiteSpace: 'nowrap',
                                  maxWidth: '135px',
                                  textAlign: 'left',
                                  color: '#000000',
                                  fontSize: 12
                                }}
                              >
                                {button.account}
                              </div>
                            </Tooltip>
                            <div style={{margin: '2px 0px 0 10px', paddingBottom: 10}}>
                              <ThemeBubbles cardData={{title: 'Connected', color: '#008243', background: '#EBF5F0', border: '1px solid #B3DAC7'}} />
                            </div>
                          </div>
                          <ConnectButton
                            onClick={button.onClick}
                            style={{
                              color: '#101828',
                              background: 'white',
                              fontSize: 14,
                              margin: '14px 20px 0',
                              height: 40,
                              width: 'fit-content',
                              padding: '8px'
                            }}
                          > 
                            <img style={{height: 22, width: 22, margin: '0px'}} src={disconnectIcon} alt='disconnect' />
                          </ConnectButton>
                        </div>
                      ) : (
                        <ConnectButton
                          onClick={button.onClick}
                          style={{
                            color: '#101828',
                            background: 'white',
                            fontSize: 14,
                            margin: '25px auto 15px',
                            height: 40
                          }}
                        > 
                          <img style={{height: 20, width: 20, margin: '0px'}} src={linkIcon} alt='connect' />
                          <div style={{margin: '3px'}}>{button.bottomText}</div>
                        </ConnectButton>
                      )
                    }
                    
                  </div>
                )}
            </IconBlock>
          );
        })}
      </IconContainer>
    );
  }
  return null;
};

export default SocialLoginButtons;
