/* eslint-disable no-unused-vars */
/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { get, filter, isEmpty, upperFirst } from 'lodash';
import { useHistory, withRouter } from 'react-router-dom';
import {
  ResponsiveImg,
  DripIconButton,
  LoaderWrapper,
  ButtonNoBorderBackground,
  Modal,
  ModalCloseButton,
  ModalBody,
} from '../';
import { StyledArticle } from './DashboardArticle.styles';
import ShareContentModal from '../ShareContentModal';
import {
  eventHandlers,
  ignoredFunds,
  onImageError,
  ROLE_TYPES,
  USER_ROLE,
} from '../../utils';
import { actions as shareActions } from '../../redux/modules/share.module';
import { actions as exploreActions } from '../../redux/modules/explore-tab.module';
import { actions as campaignActions } from '../../redux/modules/campaign.module';
import { getPdfLink } from '../../services/dashboard';
import { Flex } from '@rebass/grid';
import { HoverButton } from '../../containers/ConfigureContainer/EmailList/EmailList.styles';
import { getChannelId, getRoleType, getUserRole } from '../../redux/selector';
import MemberApproveShareModal from './MemberApproveShareModal';

// id, link, url, topic, place, date, year, channelId, themeId
const DashboardArticle = ({
  url,
  topic,
  place,
  date,
  title,
  description,
  link,
  id,
  year,
  channelId,
  themeId,
  deleteArticleRequest,
  isContentLibrary,
  themeNames,
  exploreArticleDeleting,
  isShareBlocked,
  isDeleteBlocked,
  isInvestmentTab,
  tickers,
  isCascade,
  isGivingCompass,
  auxiliary,
  auxiliarySharing,
  index,
  themeIds,
  handleTickerChange,
  isTicker,
  isComplainceAdmin,
  mioTab,
  hideDelete,
  setShowArticleLoader,
  totalArticles,
  imageCounter,
  setImageCounter,
  shareArticleSetState,
  sponsoredContent,
  holdingsType,
  internalContent,
  width,
  margin,
  height,
  type,
  fromMyClients,
  article,
  isFromEditorTab,
  contactName,
  approvedToShare,
  isEnterpriseAdvisor,
  fromContentLibTab,
  toggleEditModal,
  isAssetAdvisor,
  approvedArticles,
  isFromCompliance,
  section,
  popularThemes,
  userData,
  sponsoredFunds,
  onNewsletterArticleClick,
  onRemoveFromNewletterClick,
  bucketId,
  approveToShare,
  fromBookmarksTab,
  bookmarksFetching,
  isBookmark,
  bookmarksId,
  bookmarkId,
  isNewsletterBlocked,
  fromMyTopics,
  selectedDropdownOption,
  selectedCRMContactList,
  curatedTopicType,
  selectedTheme,
  approvedCustomizedData,
  testId,
  isAdmin,
  adminChannelId,
  updated_time,
  campaignSetState,
  isAdvisorEnterprise,
}) => {
  const history = useHistory();
  const [deleteId, setDeleteId] = useState('');
  const [isShareModalOpen, setShareModalOpen] = useState(false);
  const [clientLoading, toggleClientLoading] = useState(false);
  const [contactId, setContactId] = useState('-1');
  const [pdfUrl, setPdfUrl] = useState('');
  const [showContent, setShowContent] = useState(false);
  const [articleClicked, toggleArticleClicked] = useState(false);
  const [articleShared, toggleArticleShared] = useState(false);
  const [showTooltip, setShowToolTip] = useState(false);
  const [positivlyContactsCount, setPositivlyContactsCount] = useState(false);
  const [positivlyName, setPositivlyName] = useState('SECURITY');
  const [openModal, setOpenModal] = useState(false);
  const [memberModal, setMemberModal] = useState(false);

  const postDesc = useRef(null);
  let trimmedTopic =
    (topic || '').length > 60 ? `${topic.substring(0, 60)}...` : topic;

  // trimmedTopic = mioTab ? decodeURI(trimmedTopic) : trimmedTopic;
  let span = document.createElement('span');
  span.innerHTML = trimmedTopic;
  const txt = span.textContent.trim() || span.innerText.trim();
  trimmedTopic = mioTab ? txt : trimmedTopic;

  let trimmedDesc =
    (description || '').length > 150
      ? `${description.substring(0, 150)}...`
      : description;

  try {
    trimmedDesc = decodeURI(trimmedDesc);
  } catch {
    // eslint-disable-next-line no-self-assign
    trimmedDesc = trimmedDesc;
  }

  useEffect(() => {
    const element = postDesc;

    if (element.current) {
      if (element.current.scrollHeight > 50) setShowToolTip(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postDesc.current]);

  // DELETE LOADER NEEDS FIX

  const showHoldingTickers = () => {
    let tickerString = '';
    let tickerArray =
      !isEmpty(themeIds) &&
      themeIds.map((item, index) => {
        let themeName = get(themeNames, item, '');
        return themeName;
      });
    tickerString = tickerArray && tickerArray.join(', ');

    if (tickerString && !isEmpty(tickerString)) {
      return (
        <div
          className="investment-themeid"
          style={{ paddingLeft: '13px' }}
          title={tickerString && tickerString.length > 15 ? tickerString : ''}
        >
          <strong className="investment-themeid">
            <span onClick={() => ''} style={{ cursor: 'default' }}>
              {tickerString && tickerString.length > 15
                ? `${tickerString.substring(0, 15)}...`
                : tickerString}
            </span>
          </strong>
        </div>
      );
    } else {
      return null;
    }
  };

  const handleMemberPostModal = () => {
    setMemberModal(true);
  };

  const handleMemberModalClose = () => {
    setMemberModal(false);
  };

  // trimmedDesc = mioTab ? decodeURI(trimmedDesc) : trimmedDesc;
  const onDeleteButtonClick = () => {
    setDeleteId(id);
    deleteArticleRequest(id);
    setOpenModal(false);
    setTimeout(() => {
      setShowArticleLoader(false);
    }, 1000);
  };

  const handleModalOpen = () => {
    setOpenModal(true);
  };

  const handleModalClose = () => {
    setOpenModal(false);
  };

  const onAddToNewsletterClick = () => {
    setDeleteId('');
    onNewsletterArticleClick(id, themeId, 'newsLetter');
  };

  const handleDeleteFromNewsletter = () => {
    setDeleteId('');
    onRemoveFromNewletterClick(bucketId, id, 'newsLetter');
  };

  const allowShare = auxiliarySharing;
  const articleHeading = themeNames[themeId] || 'Miscellaneous';

  const handleTickers = (ticker) => {
    handleTickerChange(ticker);
  };

  const onImageLoad = () => {
    setShowContent(true);
    let arr = imageCounter;
    if (totalArticles === arr.length + 1) {
      setTimeout(() => {
        setShowArticleLoader(false);
      }, 1000);
    } else {
      arr.push(index);
      setImageCounter(arr);
    }
  };

  const bookmarkHandler = () => {
    if (isBookmark) onRemoveFromNewletterClick(bookmarkId, id, 'bookmarks');
    else onNewsletterArticleClick(id, themeId.id, 'bookmarks');
  };

  const redirectToMagnifi = (key) => {
    if (!ignoredFunds.includes(key)) {
      const userEmail =
        userData &&
        userData.details &&
        userData.details.user &&
        userData.details.user.email;
      let url;
      if (key === 'LTG/AQDCT') {
        url =
          'https://www.wealthmanagement.com/investment/swift-chat-ariel-acuna-creator-aqueduct-strategy';
      } else if (key === 'Cult Wines') {
        url = 'https://www.cultwines.com/';
      } else if (key === 'Investments') {
        url = 'https://www.franklintempleton.com/';
      } else if (key === 'WisdomTree') {
        url =
          'https://www.wisdomtree.com/strategies/on-the-markets?utm_source=clout&utm_medium=referral&utm_campaign=marketnews&utm_content=otm&utm_term=resource';
      } else if (key === 'Hamilton Lane') {
        url = 'https://www.hamiltonlane.com/privatewealth';
      } else {
        url = `https://magnifi.com/securities?query=${key}&utm_source=clout&utm_medium=clout_sponsored_articles&utm_campaign=clout_sponsored_funds&buy=${key}`;
      }
      window.open(url, '_blank');
      eventHandlers(
        'SPONSORED_TICKER_CLICKED',
        popularThemes,
        id,
        section,
        channelId,
        title,
        themeId,
        themeNames[themeId],
        topic,
        userEmail,
        key,
        url
      );
    }
  };

  const onArticleShare = (value, source, link) => {
    let selectedArticlesFromDashboard = [];
    selectedArticlesFromDashboard.push(article);
    const userEmail =
      userData &&
      userData.details &&
      userData.details.user &&
      userData.details.user.email;
    if (source) {
      const urlObj =
        value.mediaUrl && value.mediaUrl[0] ? value.mediaUrl[0] : '';
      if (urlObj) {
        setShareModalOpen(true);
      }
    } else if (isFromEditorTab || (place && place === 'Third party articles')) {
      if (id.split('_')[0] === 'CC') {
        campaignSetState({
          selectedArticles: selectedArticlesFromDashboard,
          fromDashboard: true,
        });
        history.push('/campaignV2?type=create');
        // window.open("/campaignV2?type=create&articleObj=" + encodeURIComponent(JSON.stringify(article)), "_self")
      } else {
        toggleArticleClicked(true);
        getPdfLink(value)
          .then((response) => {
            toggleArticleClicked(false);
            eventHandlers('ARTICLE_CLICKED', popularThemes, userEmail, article);
            campaignSetState({
              selectedArticles: selectedArticlesFromDashboard,
              fromDashboard: true,
            });
            history.push('/campaignV2?type=create');
            // window.open("/campaignV2?type=create&articleObj=" + encodeURIComponent(JSON.stringify(article)), "_self")
          })
          .catch((err) => console.log('[ERROR] - ', err));
      }
    } else {
      if (
        value.includes('https://') ||
        value.includes('http://') ||
        value.includes('https')
      ) {
        if (sponsoredContent) {
          eventHandlers(
            'SPONSORED_ARTICLE_CLICKED',
            popularThemes,
            userEmail,
            article
          );
        } else {
          eventHandlers('ARTICLE_CLICKED', popularThemes, article);
        }
        campaignSetState({
          selectedArticles: selectedArticlesFromDashboard,
          fromDashboard: true,
        });
        history.push('/campaignV2?type=create');
        // window.open("/campaignV2?type=create&articleObj=" + encodeURIComponent(JSON.stringify(article)), "_self")
        // window.open(value, '_blank');
      } else {
        if (sponsoredContent) {
          eventHandlers(
            'SPONSORED_ARTICLE_CLICKED',
            popularThemes,
            userEmail,
            article
          );
        } else {
          eventHandlers('ARTICLE_CLICKED', popularThemes, userEmail, article);
        }
        campaignSetState({
          selectedArticles: selectedArticlesFromDashboard,
          fromDashboard: true,
        });
        history.push('/campaignV2?type=create');
        // window.open("/campaignV2?type=create&articleObj=" + encodeURIComponent(JSON.stringify(article)), "_self")
      }
    }
  };

  const onArticleClick = (value, source, link) => {
    const userEmail =
      userData &&
      userData.details &&
      userData.details.user &&
      userData.details.user.email;
    if (source) {
      const urlObj =
        value.mediaUrl && value.mediaUrl[0] ? value.mediaUrl[0] : '';
      if (urlObj) {
        const imgUrl = urlObj.path + urlObj.name.system + '.' + urlObj.ext;
        window.open(imgUrl, '_blank');
      }
    } else if (isFromEditorTab || (place && place === 'Third party articles')) {
      if (id.split('_')[0] === 'CC') {
        window.open(link, '_blank');
      } else {
        toggleArticleClicked(true);
        getPdfLink(value)
          .then((response) => {
            toggleArticleClicked(false);
            eventHandlers(
              'ARTICLE_CLICKED',
              popularThemes,
              id,
              section,
              channelId,
              title,
              themeId,
              themeNames[themeId],
              topic,
              userEmail
            );
            window.open(response.result.data.url, '_blank');
          })
          .catch((err) => console.log('[ERROR] - ', err));
      }
    } else {
      if (
        value.includes('https://') ||
        value.includes('http://') ||
        value.includes('https')
      ) {
        if (sponsoredContent) {
          eventHandlers(
            'SPONSORED_ARTICLE_CLICKED',
            popularThemes,
            id,
            section,
            channelId,
            title,
            themeId,
            themeNames[themeId],
            topic,
            userEmail
          );
        } else {
          eventHandlers(
            'ARTICLE_CLICKED',
            popularThemes,
            id,
            section,
            channelId,
            title,
            themeId,
            themeNames[themeId],
            topic,
            userEmail
          );
        }
        window.open(value, '_blank');
      } else {
        if (sponsoredContent) {
          eventHandlers(
            'SPONSORED_ARTICLE_CLICKED',
            popularThemes,
            id,
            section,
            channelId,
            title,
            themeId,
            themeNames[themeId],
            topic,
            userEmail
          );
        } else {
          eventHandlers(
            'ARTICLE_CLICKED',
            popularThemes,
            id,
            section,
            channelId,
            title,
            themeId,
            themeNames[themeId],
            topic,
            userEmail
          );
        }
        window.open(`https://${value}`, '_blank');
      }
    }
  };
  return (
    <StyledArticle
      width={width}
      type={type}
      height={height}
      margin={margin}
      index={index}
      holdingsType={holdingsType}
      fromContentLibTab={fromContentLibTab}
    >
      <a
        href="#"
        rel="noopener noreferrer"
        onClick={
          auxiliary && auxiliary.source === 'img'
            ? () => onArticleClick(article, 'img')
            : isFromEditorTab || (place && place === 'Third party articles')
            ? () => onArticleClick(id, '', link)
            : () => onArticleClick(link)
        }
        data-testid={testId}
      >
        <div>
          <ResponsiveImg
            src={url}
            onError={onImageError}
            onLoad={onImageLoad}
          />
        </div>
      </a>
      {showContent && (
        <React.Fragment>
          <div className="details-wrapper">
            {!isTicker ? (
              <div className="selected-topic">
                {isInvestmentTab ? (
                  <>
                    <Flex>
                      <span>
                        {holdingsType && holdingsType === 'stocks'
                          ? 'UNDERLYING Stocks : '
                          : 'Portfolio Holdings : '}
                        &nbsp;
                      </span>
                      <div
                        className="post-tickers"
                        title={
                          sponsoredContent
                            ? tickers.slice(0, 3).map((ticker, index, arr) => {
                                return ticker;
                              })
                            : tickers.map((ticker, index, arr) => {
                                return ticker;
                              })
                        }
                      >
                        {sponsoredContent
                          ? tickers.slice(0, 3).map((ticker, index, arr) => (
                              <React.Fragment key={index}>
                                <span
                                  onClick={() => handleTickers(ticker)}
                                  style={{ cursor: 'pointer' }}
                                >
                                  {ticker}
                                </span>
                                {arr.length - 1 === index ? null : (
                                  <span>&nbsp;|</span>
                                )}
                                &nbsp;
                              </React.Fragment>
                            ))
                          : tickers.slice(0, 2).map((ticker, index, arr) => (
                              <React.Fragment key={index}>
                                <span
                                  onClick={() => handleTickers(ticker)}
                                  style={{ cursor: 'pointer' }}
                                >
                                  {ticker}
                                </span>
                                {arr.length - 1 === index ? null : (
                                  <span>&nbsp;|</span>
                                )}
                                &nbsp;
                              </React.Fragment>
                            ))}
                        {!sponsoredContent && tickers && tickers.length > 2 ? (
                          <span style={{ cursor: 'pointer' }}>...</span>
                        ) : (
                          ''
                        )}
                      </div>
                    </Flex>
                    {sponsoredContent && (
                      <span
                        style={{
                          backgroundColor: '#555555',
                          color: '#d5d5d5',
                          fontSize: '8px',
                          borderRadius: '3px',
                          padding: '0px 4px',
                          float: 'right',
                          textTransform: 'none',
                          fontWeight: '500',
                          height: '15px',
                        }}
                      >
                        Sponsored
                      </span>
                    )}
                  </>
                ) : (
                  <>
                    {/* <div className='tooltip-div tooltip-disclosure' style={{position: 'unset'}}>
                      <p style={{cursor: topic.name.length > 7 ? 'pointer': '', color: selectedTiles.has(topic.name?.toLowerCase()) ? '#ffffff' : '#49484a'}}>{shortenTitle(topic.name)}</p>
                      {topic.name.length > 7 && <span className='tooltip-text tooltiptext-disclosure' style={{left: '90px', top: '10px', whiteSpace: 'nowrap', width: 'initial', backgroundColor: '#404040', color: '#ffffff'}}>{topic.name}</span>}
                  </div> */}
                    <div
                      className="tooltip-div tooltip-disclosure"
                      style={{ position: 'unset', paddingTop: '0px' }}
                    >
                      {themeIds && (
                        <span>
                          {auxiliary && auxiliary.source === 'img'
                            ? `Image: 
                      ${
                        themeIds.map((id) => themeNames[id]).join(' | ')
                          ?.length > 30
                          ? themeIds
                              .map((id) => themeNames[id])
                              .join(' | ')
                              ?.substring(0, 30) + '...'
                          : themeIds.map((id) => themeNames[id]).join(' | ')
                      }`
                            : themeIds.map((id) => themeNames[id]).join(' | ')
                                ?.length > 30
                            ? themeIds
                                .map((id) => themeNames[id])
                                .join(' | ')
                                ?.substring(0, 30) + '...'
                            : themeIds.map((id) => themeNames[id]).join(' | ')}
                          {themeIds.map((id) => themeNames[id]).join(' | ')
                            ?.length > 30 && (
                            <span
                              className="tooltip-text tooltiptext-disclosure"
                              style={{
                                left: '90px',
                                top: '123px',
                                whiteSpace: 'nowrap',
                                width: 'initial',
                                backgroundColor: '#404040',
                                color: '#ffffff',
                              }}
                            >
                              {themeIds.map((id) => themeNames[id]).join(' | ')}
                            </span>
                          )}
                        </span>
                      )}
                    </div>

                    {approveToShare &&
                      !sponsoredContent &&
                      updated_time &&
                      new Date(updated_time).getTime() <=
                        new Date().getTime() &&
                      new Date(updated_time).getTime() >
                        new Date(Date.now() - 86400000 * 30).getTime() && (
                        <span
                          style={{
                            backgroundColor: '#0098A7',
                            color: '#f5f5f5',
                            fontSize: '9px',
                            borderRadius: '3px',
                            padding: '1px 7px',
                            textTransform: 'none',
                            fontWeight: '500',
                            height: '14px',
                            position: 'absolute',
                            left: '119px',
                          }}
                        >
                          NEW
                        </span>
                      )}

                    {approveToShare && !sponsoredContent && (
                      <span
                        style={{
                          backgroundColor: '#555555',
                          color: '#d5d5d5',
                          fontSize: '8px',
                          borderRadius: '3px',
                          padding: '0px 4px',
                          float: 'right',
                          textTransform: 'none',
                          fontWeight: '500',
                          height: '14px',
                        }}
                      >
                        Approved To Share
                      </span>
                    )}
                    {sponsoredContent && (
                      <span
                        style={{
                          backgroundColor: '#555555',
                          color: '#d5d5d5',
                          fontSize: '8px',
                          borderRadius: '3px',
                          padding: '0px 4px',
                          float: 'right',
                          textTransform: 'none',
                          fontWeight: '500',
                          height: '15px',
                        }}
                      >
                        Sponsored
                      </span>
                    )}
                    {internalContent &&
                      internalContent.length > 0 &&
                      internalContent === 'true' && (
                        <span
                          style={{
                            backgroundColor: '#555555',
                            color: '#d5d5d5',
                            fontSize: '8px',
                            borderRadius: '3px',
                            padding: '0px 4px',
                            float: 'right',
                            textTransform: 'none',
                            fontWeight: '500',
                            height: '15px',
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          Internal Use
                        </span>
                      )}
                  </>
                )}
              </div>
            ) : null}
            {(isFromEditorTab || (place && place === 'Third party articles')) &&
            articleClicked ? (
              <LoaderWrapper
                isLoading={articleClicked}
                isFromEditorTab
              ></LoaderWrapper>
            ) : (
              <a
                href="#"
                rel="noopener noreferrer"
                onClick={
                  auxiliary && auxiliary.source === 'img'
                    ? () => onArticleClick(article, 'img')
                    : isFromEditorTab ||
                      (place && place === 'Third party articles')
                    ? () => onArticleClick(id, '', link)
                    : () => onArticleClick(link)
                }
              >
                <div
                  className={`${isTicker ? 'selected-topic' : 'place-date'}`}
                >
                  {mioTab  
                    ? `${!sponsoredContent ? date : ''}`
                    : !isContentLibrary
                    ? `${
                        place && place.length > 12
                          ? (sponsoredContent ? place : place.substring(0, 12) + '...')
                          : place
                      } ${!sponsoredContent ? ', ' : ''} ${!sponsoredContent ? date : ''}`
                    : `Published ${date}`}
                  {section === 'MY_CONTENT' &&
                    approveToShare &&
                    sponsoredContent && (
                      <span
                        style={{
                          backgroundColor: '#555555',
                          color: '#d5d5d5',
                          fontSize: '8px',
                          borderRadius: '3px',
                          padding: '0px 4px',
                          float: 'right',
                          textTransform: 'none',
                          fontWeight: '500',
                          height: '14px',
                        }}
                      >
                        Approved To Share
                      </span>
                    )}
                </div>
                <div
                  className="post-heading"
                  title={topic && topic.length > 60 ? topic : ''}
                >
                  {trimmedTopic}
                </div>
                <div ref={postDesc} className="post-desc" title={description}>
                  {description}
                </div>
                {/* <Text hideArrow={true} style={{color: '#545454', fontSize: '10px', fontFamily: 'Poppins'}} tooltipStyle={{ color: 'black', backgroundColor: 'white', fontSize: '8px'}} >{description}</Text> */}
              </a>
            )}
          </div>

          <div
            className="btn-wrapper"
            style={{
              justifyContent: sponsoredFunds ? 'flex-start' : 'space-between',
              alignItems: fromMyClients ? 'baseline' : 'center',
            }}
          >
            <div
              className="left-btns-container"
              style={{
                display: 'flex',
                justifyContent:
                  section === 'SPONSORED_BOX_EXPAND' ||
                  isNewsletterBlocked ||
                  fromBookmarksTab ||
                  section === 'RECOMMENDED' ||
                  !isAdmin
                    ? 'flex-start'
                    : 'space-between',
                alignItems: 'center',
                width: fromContentLibTab
                  ? auxiliary.source === 'img'
                    ? '25%'
                    : approveToShare
                    ? '30%'
                    : '38%'
                  : '30%',
              }}
            >
              {isCascade === 'false' && (
                <div className="delete-btn-wrapper">
                  <div
                    style={{
                      display: 'flex',
                      alignItems: fromContentLibTab ? 'inherit' : 'center',
                    }}
                  >
                    {deleteId !== id &&
                      section !== 'SPONSORED_BOX_EXPAND' &&
                      !fromBookmarksTab &&
                      !isComplainceAdmin &&
                      !(isAdvisorEnterprise && approveToShare) &&
                      (!isDeleteBlocked || !hideDelete) && (
                        <LoaderWrapper
                          isLoading={deleteId === id && exploreArticleDeleting}
                        >
                          <DripIconButton
                            icon="trash"
                            onClick={handleModalOpen}
                            disable={
                              section === 'MY_CONTENT'
                                ? false
                                : sponsoredContent ||
                                  id.split('_')[0] === 'CC' ||
                                  section === 'MY_EDITOR'
                            }
                            // disable={
                            //   sponsoredContent ||
                            //   id.split('_')[0] === 'CC' ||
                            //   section === 'MY_EDITOR'
                            // }
                            styles={{ position: 'relative', left: '-6px' }}
                          />
                        </LoaderWrapper>
                      )}
                  </div>
                </div>
              )}

              {isAdmin &&
                !isShareBlocked &&
                !approveToShare &&
                isCascade === 'false' && (
                  <Flex>
                    <img
                      title="Approve to Share for Members"
                      src="/assets/images/file-upload-icon-light.svg"
                      style={{
                        cursor: 'pointer',
                        borderRadius: '0px',
                        height: '15px',
                        width: '15px',
                        position: 'relative',
                        left:
                          fromBookmarksTab || section === 'RECOMMENDED'
                            ? '10px'
                            : '',
                      }}
                      alt=""
                      onClick={handleMemberPostModal}
                    />
                  </Flex>
                )}
              {fromContentLibTab &&
                ((isAdvisorEnterprise && !approveToShare) ||
                  !isAdvisorEnterprise) && isCascade === 'false' && (
                  <LoaderWrapper isLoading={false}>
                    <img
                      src="/assets/images/Icon-metro-pencil.svg"
                      alt=""
                      style={{
                        height: '15px',
                        width: '15px',
                        position: 'relative',
                        cursor: 'pointer',
                        marginBottom: '1px',
                      }}
                      onClick={() =>
                        toggleEditModal({ status: true, articleIndex: index })
                      }
                    />
                  </LoaderWrapper>
                )}
              {!isShareBlocked &&
              auxiliary &&
              auxiliary.source !== 'img' &&
              (!internalContent || internalContent === 'false') ? (
                <div
                  className="bookmark-btn-wrapper"
                  style={{
                    cursor: 'pointer',
                    marginTop: '3px',
                    position: 'relative',
                    left:
                      section === 'SPONSORED_BOX_EXPAND' || !isAdmin
                        ? '5px'
                        : isAdmin &&
                          (fromBookmarksTab || section === 'RECOMMENDED')
                        ? '15px'
                        : '1px',
                  }}
                  onClick={bookmarkHandler}
                >
                  <LoaderWrapper
                    isLoading={bookmarksId === id && bookmarksFetching}
                  >
                    <img
                      src={
                        isBookmark
                          ? '/assets/images/bookmark-filled.svg'
                          : '/assets/images/bookmark.svg'
                      }
                      alt=""
                      style={{
                        height: '17px',
                        position: 'relative',
                        left: fromBookmarksTab ? '2px' : '',
                      }}
                    />
                  </LoaderWrapper>
                </div>
              ) : null}
            </div>

            <div className="center-btns-container" style={{ display: 'flex' }}>
              {(section === 'MY_TOPICS' ||
                section === 'SPONSORED_BOX_EXPAND') &&
                sponsoredFunds && (
                  <Flex
                    fontSize="11px"
                    style={{
                      padding:
                        sponsoredFunds &&
                        Object.keys(sponsoredFunds).length === 1
                          ? '0px 0px 0px 30px'
                          : '0px 0px 0px 5px',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    <strong>
                      {Object.keys(sponsoredFunds).map((key, index, funds) => {
                        return (
                          <span
                          key={index}
                            style={{
                              cursor: ignoredFunds.includes(key)
                                ? 'default'
                                : 'pointer',
                            }}
                            title={sponsoredFunds[key]}
                            onClick={() => redirectToMagnifi(key)}
                          >
                            {key}
                            {index < funds.length - 1 && ', '}
                          </span>
                        );
                      })}
                    </strong>
                  </Flex>
                )}

              {isInvestmentTab &&
                themeIds &&
                themeIds.length > 0 &&
                showHoldingTickers()}

              {themeIds && themeIds.length < 1 && isGivingCompass && (
                <div className="power-by-wrapper">
                  <span>Powered By</span>{' '}
                  <span>
                    <ResponsiveImg src="/assets/images/giving-compass.png" />
                  </span>
                </div>
              )}
            </div>

            <div
              className="right-btns-container"
              style={{ display: 'flex', marginLeft: 'auto' }}
            >
              {/* {isContentLibrary && (
                <div>
                  <DripIconButton icon="graph-bar" onClick={onForwardClick} />
                </div>
              )} */}
              {/* {((allowShare &&
                !isShareBlocked &&
                (!internalContent || internalContent !== 'true') &&
                !fromMyClients &&
                !isFromEditorTab) ||
                (fromMyTopics && approveToShare)) && (
                <div style={{ left: hideDelete ? '86%' : '' }}>
                  <ButtonNoBorderBackground
                    className="share-article"
                    onClick={
                      auxiliary && auxiliary.source === 'img'
                        ? () => onArticleShare(article, 'img')
                        : isFromEditorTab ||
                          (place && place === 'Third party articles')
                        ? () => onArticleShare(id, '', link)
                        : () => onArticleShare(link)
                    }
                  >
                    <ResponsiveImg
                      src="/assets/images/forward.svg"
                      style={{ position: 'relative', top: '2px' }}
                    />
                  </ButtonNoBorderBackground>
                </div>
              )} */}
              {/* {fromMyClients && !isShareBlocked && (
                <LoaderWrapper
                  isLoading={clientLoading}
                  styles={{
                    position: 'absolute',
                    right: '15px',
                    width: '23px',
                    top: '4px',
                  }}
                >
                  <div style={{ left: hideDelete ? '86%' : '' }}>
                    <ButtonNoBorderBackground
                      className="share-article"
                      onClick={
                        auxiliary && auxiliary.source === 'img'
                          ? () => onArticleShare(article, 'img')
                          : isFromEditorTab ||
                            (place && place === 'Third party articles')
                          ? () => onArticleShare(id, '', link)
                          : () => onArticleShare(link)
                      }
                    >
                      <ResponsiveImg
                        src="/assets/images/forward.svg"
                        style={{ position: 'relative', top: '2px' }}
                      />
                    </ButtonNoBorderBackground>
                  </div>
                </LoaderWrapper>
              )} */}
              {/* {allowShare &&
                !isShareBlocked &&
                (!internalContent || internalContent !== 'true') &&
                isFromEditorTab && (
                  <div style={{ left: hideDelete ? '86%' : '' }}>
                    {articleShared ? (
                      <LoaderWrapper
                        isLoading={articleShared}
                        styles={{ marginRight: '20px' }}
                      ></LoaderWrapper>
                    ) : (
                      <ButtonNoBorderBackground
                        className="share-article"
                        onClick={
                          auxiliary && auxiliary.source === 'img'
                            ? () => onArticleShare(article, 'img')
                            : isFromEditorTab ||
                              (place && place === 'Third party articles')
                            ? () => onArticleShare(id, '', link)
                            : () => onArticleShare(link)
                        }
                      >
                        <ResponsiveImg
                          src="/assets/images/forward.svg"
                          style={{ position: 'relative', top: '2px' }}
                        />
                      </ButtonNoBorderBackground>
                    )}
                  </div>
                )} */}
            </div>
          </div>
        </React.Fragment>
      )}

      {memberModal && (
        <MemberApproveShareModal
          isOpen={memberModal}
          onRequestClose={handleMemberModalClose}
          userData={userData}
          fromContentLibTab={fromContentLibTab}
          id={id}
          description={description}
          url={url}
          title={topic}
          adminChannelId={adminChannelId}
          themeId={themeId}
          link={link}
          sponsoredFunds={sponsoredFunds}
        />
      )}

      {isShareModalOpen && (
        <ShareContentModal
          editable
          fromMyClients={fromMyClients}
          selectedDropdownOption={selectedDropdownOption}
          selectedCRMContactList={selectedCRMContactList}
          contactId={contactId}
          fromContentLibTab={fromContentLibTab}
          approvedToShare={approvedToShare}
          approvedArticles={approvedArticles}
          isEnterpriseAdvisor={isEnterpriseAdvisor}
          isAssetAdvisor={isAssetAdvisor}
          isFromCompliance={isFromCompliance}
          handleClose={() => {
            setShareModalOpen(false);
            isShareModalOpen && shareArticleSetState({ data: {}, error: [] });
          }}
          open={isShareModalOpen}
          {...{
            id,
            link,
            topic,
            place,
            date,
            year,
            channelId,
            themeId,
            mioTab,
            isContentLibrary,
            url,
            positivlyContactsCount,
            positivlyName,
            sponsoredFunds,
            curatedTopicType,
            approvedCustomizedData,
            description,
          }}
          isFromEditorTab={isFromEditorTab}
          pdfUrl={pdfUrl}
          source={auxiliary ? auxiliary.source : ''}
          mediaUrl={
            article.mediaUrl && article.mediaUrl[0] ? article.mediaUrl[0] : ''
          }
        />
      )}
      <Modal
        onRequestClose={handleModalClose}
        shouldCloseOnOverlayClick
        isOpen={openModal}
        size="smail"
      >
        <ModalCloseButton onClick={handleModalClose} />
        <ModalBody>
          <div
            style={{
              fontWeight: 'bold',
              fontSize: '16px',
              color: '#6351ed',
              fontFamily: 'Poppins',
              wordBreak: 'break-all',
            }}
          >
            Are you sure you want to delete ?
          </div>
          {/* {selectedItem && (
            <div style={{color: '#6351ed',marginTop: '8px'}}>{selectedItem.name ? selectedItem.name : contactGroupsList.find(c => c.id === selectedContactGroup) && contactGroupsList.find(c => c.id === selectedContactGroup).name}</div>
          )} */}
          <HoverButton
            margin="9px 10px 0px 0px"
            padding="5px 28px"
            bgColor="#fff"
            hoverBackground="#6351ed"
            hoverColor="#fff"
            color="#6351ed"
            borderColor="#fff"
            onClick={onDeleteButtonClick}
          >
            Yes
          </HoverButton>
          <HoverButton
            margin="9px 0px 0px 0px"
            padding="5px 28px"
            bgColor="#fff"
            color="#6351ed"
            hoverBackground="#6351ed"
            hoverColor="#fff"
            borderColor="#fff"
            onClick={handleModalClose}
          >
            No
          </HoverButton>
        </ModalBody>
      </Modal>
    </StyledArticle>
  );
};

const mapStateToProps = (state) => {
  const isAdmin = getUserRole(state) === USER_ROLE.admin;
  const roleType = getRoleType(state);
  const isAdvisorEnterprise = roleType === ROLE_TYPES.ADVISOR_ENTERPRISE;
  return {
    popularThemes: state.theme.popularThemes,
    contactName: state.explore.contactName,
    userData: state.user.data,
    selectedTheme: state?.explore?.explore?.details?.theme,
    isAdmin,
    adminChannelId: getChannelId(state),
    isAdvisorEnterprise,
  };
};

const mapDispatchToProps = {
  shareArticleSetState: shareActions.api.share.setstate,
  exploreArticleRequest: exploreActions.api.explore.request,
  campaignSetState: campaignActions.api.campaign.setstate,
};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardArticle);
