/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Flex } from '@rebass/grid';
import React from 'react';
import StepsFooterV2 from '../../AutomatedCampaigns/CampaignCreateSteps/StepsFooterV2';
import {
  AutomatedCampaignFormWrapper,
  LineBreaker,
  StyledTextInput,
} from '../../AutomatedCampaigns/styles';
import { Loader, LoaderWrapper } from '../../../components';
import { useState } from 'react';
import { WhiteCircle } from '../../../components/StepperV2/Stepper.styles';
import {
  Header,
  NewTooltip,
  SocialTabItem,
  TabContainer,
  TabItem,
} from './SetObjectivesV2.styles';
import {
  RecipientsListBody,
  RecipientsListWrapper,
} from '../../DripCampaignContainer/SetObjectives/SetObjectives.styles';
import { get, isEmpty, keys, map } from 'lodash';
import { useHistory } from 'react-router-dom';
import { FilterRowChips } from '../../DripCampaignContainer/DripDashboardFilter/DripDashboardFilterPopup/DripDashboardFilterPopup.styles';
import { useEffect } from 'react';
import { ToggleButton } from '../../ConfigureContainer/screenConfigure.styles';
import { connect } from 'react-redux';
import { getUserRole } from '../../../redux/selector';
import {
  createOnetimeSeriesCampaign,
  getCompanyData,
  postAutoPost,
  updateOneTimeSeriesCampaign,
} from '../../../services/campaign';
import { getCRMContactList } from '../../../services/configure';
import { getCRMArticles } from '../../../services/dashboard';
import { getAccountId } from '../../../utils';
import {
  CheckBox,
  CheckBoxLabel,
  CheckBoxSpan,
  ContactListBody,
  ContactsListWrapper,
  SearchContainer,
} from '../../ConfigureContainer/EmailList/EmailList.styles';

import AutoPostSearchBar from './AutoPostSearchBar/AutoPostSearchBar';
import Tooltip from '@mui/material/Tooltip';
import Zoom from '@mui/material/Zoom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faArrowUp } from '@fortawesome/free-solid-svg-icons';

const SetObjectivesV2 = ({
  step,
  setStep,
  campaignSetState,
  endCreateFlow,
  segmentList,
  socialNetworkData,
  userData,
  contactGroups,
  curFlow,
  onSaveAsDraftClick,
  membersList,
  createMode,
  seriesId,
  isFromAddCampaign,
  series,
  setObjectivesTabData,
  setConListRecArticlesLoader,
  subscriptionType,
  isAdmin,
  isEnterpriseLevelAdmin,
  campaignStatus,
  handleNext,
  reviewType,
  savedTemplate,
  loading,
  setLoading,
  campaignsList,
  campaignCreateFlow,
  campaignId,
  userId,
  isFirmLevelAdmin,
  isEnterpriseAdvisor,
  campaignsDetailsData,
  contactsLoading,
  segmentsLoading,
}) => {
  const history = useHistory();
  const [requestLoading, setRequestLoading] = useState(false);
  const [segmentListLoader, setSegmentListLoader] = useState(false);

  const [segmentTabActive, setSegmentTabActive] = useState(false);
  const [selectedSocialPlatform, setSelectedSocialPlatform] = useState('fb');
  const [selectedSegmentList, setSelectedSegmentList] = useState([]);
  const [emailList, setEmailList] = useState([]);
  const [selectedEmailContacts, setSelectedEmailContacts] = useState([]);
  const [selectSubscriptionType, setSelectSubscriptionType] = useState();

  const [subscriptionValid, setSubscriptionValid] = useState(true);
  const [emailListValid, setEmailListValid] = useState(true);
  const [segmentListValid, setSegmentListValid] = useState(true);
  const [campaignNameValid, setCampaignNameValid] = useState(true);
  const [autoPostDataValid, setAutoPostDataValid] = useState(true);

  const [subscriptionTypeOptions, setSubscriptionTypeOptions] = useState([]);
  const [showSubscription, setShowSubscription] = useState(false);
  const [showAutoPost, setShowAutoPost] = useState(false);

  const [selectedFbContacts, setSelectedFbContacts] = useState([]);
  const [ascFbOrder, setAscFbOrder] = useState(false);
  const [selectedTwContacts, setSelectedTwContacts] = useState([]);
  const [selectedLnContacts, setSelectedLnContacts] = useState([]);
  const [ascLnOrder, setAscLnOrder] = useState(false);

  const [selectedAdvisors, setSelectedAdvisors] = useState([]);
  const [approveToShare, setApproveToShare] = useState('false');
  const [OGSelectedAdvisors, setOGSelectedAdvisors] = useState([]);

  const [campaignName, setCampaignName] = useState('Untitled');
  const [purpose, setPurpose] = useState('');
  const [webinar, setWebinar] = useState(false);

  const [toggleAutoPost, setToggleAutoPost] = useState(false);
  const [showAutoPostList, setShowAutoPostList] = useState(false);

  const [webinar_registrants, setWebinarRegistrants] = useState(false);
  const [webinarDetails, setWebinarDetails] = useState({});
  const [selectedWebinar, setSelectedWebinar] = useState('');
  const [seriesStatus, setSeriesStatus] = useState('draft');
  const [toggleTabs, setToggleTabs] = useState('advisor');
  const [selectedAdvisorData, setSelectedAdvisorData] = useState([]);
  const [selectedAdminData, setSelectedAdminData] = useState([]);
  const [selectedFirmData, setSelectedFirmData] = useState([]);
  const [advisorData, setAdvisorData] = useState([]);
  const [adminData, setAdminData] = useState([]);
  const [firmData, setFirmData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [search, setSearch] = useState('');
  const [searchBox, setSearchBox] = useState('');
  const [searchResult, setSearchResult] = useState('');
  const [openSearch, setOpenSearch] = useState(false);
  const [filteredAdvisorData, setFilteredAdvisorData] = useState([]);
  const [filteredAdminData, setFilteredAdminData] = useState([]);

  const [ascEmailOrder, setAscEmailOrder] = useState(false);

  useEffect(() => {
    if (!isEnterpriseAdvisor) {
      fetchCompanyData();
    }
  }, [toggleTabs]);

  useEffect(() => {
    let subscriptionOptions = [];
    if (get(userData, 'details.company.marketing_pref_settings', {})) {
      if (
        get(
          userData,
          'details.company.marketing_pref_settings.general_newsletters',
          'false'
        ) === 'true'
      ) {
        subscriptionOptions.push({
          value: 'general_newsletters',
          label: 'Newsletters',
          description:
            'Insights and commentary on the markets, economy, financial planning and other relevant topics.',
        });
      }
      if (
        get(
          userData,
          'details.company.marketing_pref_settings.firm_updates',
          'false'
        ) === 'true'
      ) {
        subscriptionOptions.push({
          value: 'firm_updates',
          label: 'Firm Updates',
          description:
            'Updates about our business such as personnel changes and office closures.',
        });
      }
      if (
        get(
          userData,
          'details.company.marketing_pref_settings.ecards',
          'false'
        ) === 'true'
      ) {
        subscriptionOptions.push({
          value: 'ecards',
          label: 'E-cards',
          description:
            'Well-wishes for birthdays, holidays and other special occasions.',
        });
      }
      if (
        get(
          userData,
          'details.company.marketing_pref_settings.events',
          'false'
        ) === 'true'
      ) {
        subscriptionOptions.push({
          value: 'events',
          label: 'Event Updates',
          description:
            'Invitations and related communications for in-person and virtual webcast events.',
        });
      }
      setSubscriptionTypeOptions(subscriptionOptions);
      if (
        (get(
          userData,
          'details.company.marketing_pref_settings.general_newsletters',
          'false'
        ) === 'true' ||
          get(
            userData,
            'details.company.marketing_pref_settings.ecards',
            'false'
          ) === 'true' ||
          get(
            userData,
            'details.company.marketing_pref_settings.ecards',
            'false'
          ) === 'true' ||
          get(
            userData,
            'details.company.marketing_pref_settings.events',
            'false'
          ) === 'true') &&
        (!isEmpty(selectedEmailContacts) || !isEmpty(selectedSegmentList))
      ) {
        console.log(selectedEmailContacts, selectedSegmentList);
        setShowSubscription(true);
      }
      if (!isEmpty(selectedEmailContacts) || !isEmpty(selectedSegmentList)) {
        setShowAutoPost(true);
        setShowAutoPostList(true);
      }
    }
  }, [selectedEmailContacts, selectedSegmentList]);

  useEffect(() => {
    if (campaignCreateFlow && !isEmpty(setObjectivesTabData)) {
      setSelectedEmailContacts(get(setObjectivesTabData, 'emList', []));
      setSelectedFbContacts(get(setObjectivesTabData, 'fbList', []));
      setSelectedLnContacts(get(setObjectivesTabData, 'lnList', []));
      setSelectedTwContacts(get(setObjectivesTabData, 'twList', []));
      setSelectedSegmentList(get(setObjectivesTabData, 'segmentList', []));
      if (!isEmpty(get(setObjectivesTabData, 'segmentList', []))) {
        setSegmentTabActive(true);
      }
      setCampaignName(get(setObjectivesTabData, 'seriesName', 'abcs'));
      setPurpose(get(setObjectivesTabData, 'seriesPurpose', ''));
      setSelectSubscriptionType(subscriptionType);
      setToggleAutoPost(get(setObjectivesTabData, 'autoPostToggle', false));
      if (get(setObjectivesTabData, 'autoPostToggle', false)) {
        setShowAutoPostList(true);
      }
      setSelectedAdminData(get(setObjectivesTabData, 'autoPost.adminData', []));
      setSelectedAdvisorData(
        get(setObjectivesTabData, 'autoPost.advisorData', [])
      );
      setSelectedFirmData(get(setObjectivesTabData, 'autoPost.firmData', []));
      // if (
      //   get(setObjectivesTabData, 'webinar.wuid', null) &&
      //   get(setObjectivesTabData, 'webinar.isWebinar', false)
      // ) {
      //   handleRadioButtonClick(get(setObjectivesTabData, 'webinar.wuid', null));
      // }
    }

    if (!isEmpty(setObjectivesTabData)) {
      setApproveToShare(get(setObjectivesTabData, 'approvedToShare', 'false'));
      setSelectedAdvisors(get(setObjectivesTabData, 'selectedAdvisors', []));
      setOGSelectedAdvisors(get(setObjectivesTabData, 'selectedAdvisors', []));
    }

    if (!isEmpty(setObjectivesTabData) && setObjectivesTabData?.cascaded) {
      if (setObjectivesTabData?.platforms?.is_facebook) {
        setSelectedSocialPlatform('fb');
      } else if (setObjectivesTabData?.platforms?.is_linkedin) {
        setSelectedSocialPlatform('ln');
      } else {
        setSelectedSocialPlatform('tw');
      }
    }

    // if (seriesId) {
    //   const campaignSeries = campaignsList.find(
    //     (campaign) => campaign.series_id === seriesId
    //   );
    //   if (
    //     campaignSeries ||
    //     get(setObjectivesTabData, 'campaignSeriesStatus', 'draft')
    //   )
    //     setSeriesStatus(
    //       campaignSeries
    //         ? campaignSeries.status
    //         : get(setObjectivesTabData, 'campaignSeriesStatus', 'draft')
    //     );
    // }
  }, []);

  useEffect(() => {
    setEmailList(contactGroups);
  }, [contactGroups]);

  const handleValidation = () => {
    if (!campaignName) {
      setCampaignNameValid(false);
    } else {
      setCampaignNameValid(true);
    }

    if (!showSubscription || (!selectSubscriptionType && showSubscription)) {
      setSubscriptionValid(false);
    } else {
      setSubscriptionValid(true);
    }

    if (
      isEmpty(selectedFbContacts) &&
      isEmpty(selectedLnContacts) &&
      isEmpty(selectedTwContacts) &&
      isEmpty(selectedSegmentList) &&
      isEmpty(selectedEmailContacts)
    ) {
      setSegmentListValid(false);
    } else {
      setSegmentListValid(true);
    }

    if (
      (!isEnterpriseAdvisor && !showAutoPost) ||
      (!isEnterpriseAdvisor && showAutoPost && toggleAutoPost === 'true')
    ) {
      if (
        isEmpty(selectedAdvisorData) &&
        isEmpty(selectedAdminData) &&
        isEmpty(selectedFirmData)
      ) {
        setAutoPostDataValid(false);
      } else {
        setAutoPostDataValid(true);
      }
    }
    // if((!isEmpty(setObjectivesTabData?.emList) && isEmpty(selectedEmailContacts)) || (!isEmpty(setObjectivesTabData?.segmentList) && isEmpty(selectedSegmentList))) {
    //   setSegmentListValid(false);
    // }

    // if(!isEmpty(setObjectivesTabData?.fbList) && isEmpty(selectedFbContacts)) {
    //   setSegmentListValid(false);
    // }

    // if(!isEmpty(setObjectivesTabData?.lnList) && isEmpty(selectedLnContacts)) {
    //   setSegmentListValid(false);
    // }

    // if(!isEmpty(setObjectivesTabData?.twList) && isEmpty(selectedTwContacts)) {
    //   setSegmentListValid(false);
    // }
    return (
      !campaignName ||
      (showSubscription && !selectSubscriptionType) ||
      (isEmpty(selectedFbContacts) &&
        isEmpty(selectedLnContacts) &&
        isEmpty(selectedTwContacts) &&
        isEmpty(selectedSegmentList) &&
        isEmpty(selectedEmailContacts)) ||
      (toggleAutoPost === 'true' &&
        showAutoPost &&
        !isEnterpriseAdvisor &&
        isEmpty(selectedAdvisorData) &&
        isEmpty(selectedAdminData) &&
        isEmpty(selectedFirmData))
      // ((!isEmpty(setObjectivesTabData?.emList) && isEmpty(selectedEmailContacts)) || ((!isEmpty(setObjectivesTabData?.segmentList) && isEmpty(selectedSegmentList)))) ||
      // (!isEmpty(setObjectivesTabData?.fbList) && isEmpty(selectedFbContacts)) ||
      // (!isEmpty(setObjectivesTabData?.lnList) && isEmpty(selectedLnContacts)) ||
      // (!isEmpty(setObjectivesTabData?.twList) && isEmpty(selectedTwContacts))
    );
  };

  const handleEmailToggle = (flag) => {
    if (!flag) {
      setSelectedSegmentList([]);
    } else {
      setSelectedEmailContacts([]);
    }
    setShowSubscription(false);
    setShowAutoPost(false);
    setShowAutoPostList(false);
    setSegmentTabActive(flag);
  };

  const fetchRecommendedArticles = () => {
    setConListRecArticlesLoader(true);
    getCRMContactList()
      .then((response) => {
        const contactList = response.result.data
          ? Object.keys(response.result.data).map((key) => {
              const recommendedThemesArray = response.result.data[key]
                .recommended_themes
                ? Object.keys(
                    response.result.data[key].recommended_themes.themes
                  ).map(
                    (theme) =>
                      response.result.data[key].recommended_themes.themes[theme]
                  )
                : [];
              const userThemesArray = response.result.data[key].user_themes
                ? Object.keys(response.result.data[key].user_themes.themes).map(
                    (theme) =>
                      response.result.data[key].user_themes.themes[theme]
                  )
                : [];
              return {
                id: key,
                name: response.result.data[key].name,
                themes:
                  userThemesArray &&
                  userThemesArray.length > 0 &&
                  userThemesArray[0]
                    ? userThemesArray
                    : recommendedThemesArray,
                recommendedThemes: recommendedThemesArray,
                userThemes: userThemesArray,
                disabledThemes: [],
              };
            })
          : [];
        fetchArticles(contactList);
      })
      .catch((err) => {
        setConListRecArticlesLoader(false);
      });
  };

  const getThemeIds = (contactListArray) => {
    let theme_ids;
    const contactListCopy = [...contactListArray];
    const selectedContactNames = selectedEmailContacts.filter(
      (ele) => ele.id !== '-1'
    );
    theme_ids = contactListCopy
      .filter((contact) =>
        selectedContactNames.some(({ id }) => id === contact.id)
      )
      .map((item) => item.themes)
      .flat()
      .map((theme) => (theme.id_hash ? theme.id_hash : []));
    return theme_ids;
  };

  const fetchArticles = (contactArray) => {
    const themesList = contactArray.length > 0 ? getThemeIds(contactArray) : [];
    getCRMArticles(themesList)
      .then((response) => {
        setConListRecArticlesLoader(false);
        if (response.result.success) {
          campaignSetState({
            recommendedFetchedData: response.result.data,
          });
        }
      })
      .catch((err) => {
        setConListRecArticlesLoader(false);
        // console.log(err);
      });
  };

  const handleNextClick = ({
    forSaveAndClose = false,
    forSaveAsDraft = false,
  }) => {
    console.log('clicked');
    const validation = handleValidation();
    if (validation) {
      return;
    }
    if (!forSaveAndClose) {
      createCampaign('next');
    } else if (!forSaveAsDraft) {
      onSaveAsDraftClick();
    }
  };

  const createCampaign = async (mode) => {
    setLoading(true);
    let emLists = [],
      fbLists = [],
      twLists = [],
      lnLists = [];

    emLists = !isEmpty(selectedEmailContacts)
      ? selectedEmailContacts
          .filter((ele) => ele.id !== '-1' && keys(ele).length > 0)
          .map((item, index) => {
            return {
              destination_id: item.id,
              snetwork_act_id: getAccountId('em'),
            };
          })
      : [];

    twLists = !isEmpty(selectedTwContacts)
      ? selectedTwContacts.map((item, index) => {
          return {
            destination_id: item,
            snetwork_act_id: getAccountId('tw'),
          };
        })
      : [];

    fbLists = !isEmpty(selectedFbContacts)
      ? selectedFbContacts.map((item, index) => {
          return {
            destination_id: item,
            snetwork_act_id: getAccountId('fb'),
          };
        })
      : [];

    lnLists = !isEmpty(selectedLnContacts)
      ? selectedLnContacts.map((item, index) => {
          return {
            destination_id: item,
            snetwork_act_id: getAccountId('ln', item),
          };
        })
      : [];

    let destination = {
      email: !segmentTabActive ? emLists : [],
      facebook: fbLists,
      twitter: twLists,
      linkedin: lnLists,
      segment:
        segmentTabActive &&
        !isEmpty(selectedSegmentList) &&
        selectedSegmentList.map((s) => {
          return {
            destination_id: s.id,
          };
        }),
    };

    let webinarID = null;
    if (webinarDetails?.id) {
      webinarID = {
        webinar_id: encodeURIComponent(webinarDetails.id),
      };
    }

    let payload = {
      name: campaignName,
      webinar_registrants: webinar_registrants,
      // ...(toggleAutoPost && {is_auto_post: toggleAutoPost}),
      ...(!isEnterpriseAdvisor && { is_auto_post: toggleAutoPost }),
      ...(purpose && { purpose: purpose }),
      platforms: JSON.stringify({
        is_email:
          !isEmpty(selectedEmailContacts) || !isEmpty(selectedSegmentList),
        is_facebook: !isEmpty(selectedFbContacts),
        is_linkedin: !isEmpty(selectedLnContacts),
        is_twitter: !isEmpty(selectedTwContacts),
      }),
      destination: JSON.stringify(destination),
      ...webinarID,
      ...(selectSubscriptionType && {
        subscription_type: selectSubscriptionType,
      }),
    };
    let selectedEmailList = !isEmpty(selectedEmailContacts)
      ? selectedEmailContacts.filter(
          (ele) => ele.id !== '-1' && keys(ele).length > 0
        )
      : [];

    if (get(setObjectivesTabData, 'isCascaded', 'false') !== 'true') {
      fetchRecommendedArticles();
    }
    if (!seriesId) {
      createOnetimeSeriesCampaign(payload)
        .then(async (res) => {
          if (res.result.success) {
            let payload = {
              entity_data: {
                advisor: [],
                admin: [],
                firm: [],
              },
            };
            if (selectedAdvisorData.length > 0) {
              payload.entity_data.advisor = selectedAdvisorData.map(
                (i) => i.id
              );
            }
            if (selectedAdminData.length > 0) {
              payload.entity_data.admin = selectedAdminData.map((i) => i.id);
            }
            if (selectedFirmData.length > 0) {
              payload.entity_data.firm = selectedFirmData.map((i) => i.id);
            }

            payload.entity_data = JSON.stringify(payload.entity_data);

            if (!isEnterpriseAdvisor && toggleAutoPost) {
              await postAutoPost({
                seriesId: res?.result?.data?.seriesId,
                payload,
              });
            }

            campaignSetState({
              createFlow: true,
              seriesId: get(res.result.data, 'seriesId', ''),
              ...(selectSubscriptionType && {
                subscriptionType: selectSubscriptionType,
              }),
              setObjectives: {
                createMode: createMode,
                is_facebook: !isEmpty(selectedFbContacts) ? 'true' : 'false',
                is_twitter: !isEmpty(selectedTwContacts) ? 'true' : 'false',
                is_linkedin: !isEmpty(selectedLnContacts) ? 'true' : 'false',
                is_email:
                  !isEmpty(selectedEmailList) || !isEmpty(selectedSegmentList)
                    ? 'true'
                    : 'false',
                campaignType: get(setObjectivesTabData, 'campaignType', ''),
                approvedToShare:
                  approveToShare === 'true' && selectedAdvisors.length > 0
                    ? 'true'
                    : 'false',
                campaignSeriesStatus: seriesStatus,
                isCascaded: get(setObjectivesTabData, 'isCascaded', 'false'),
                cascaded: get(setObjectivesTabData, 'cascaded', false),
                platforms: get(setObjectivesTabData, 'platforms', {}),
                skip_compliance: setObjectivesTabData?.skip_compliance,
                autoPostToggle: toggleAutoPost,
                autoPost: {
                  advisorData: selectedAdvisorData,
                  adminData: selectedAdminData,
                  firmData: selectedFirmData,
                },
                selectedAdvisors:
                  approveToShare === 'true' ? selectedAdvisors : [],
                targetField:
                  isEmpty(selectedEmailList) &&
                  isEmpty(selectedFbContacts) &&
                  isEmpty(selectedLnContacts) &&
                  isEmpty(selectedTwContacts) &&
                  isEmpty(selectedSegmentList)
                    ? false
                    : true,
                emList: !segmentTabActive ? selectedEmailList : [],
                fbList: selectedFbContacts,
                lnList: selectedLnContacts,
                twList: selectedTwContacts,
                segmentList: segmentTabActive ? selectedSegmentList : [],
                seriesName: campaignName,
                seriesPurpose: purpose,
                webinar: {
                  wuid: selectedWebinar,
                  topic: get(webinarDetails, 'topic', ''),
                  description: get(webinarDetails, 'agenda', ''),
                  url: get(
                    setObjectivesTabData,
                    'webinar.url',
                    get(webinarDetails, 'url', '')
                  ),
                  link: get(webinarDetails, 'registration_url', ''),
                  isWebinar: webinar,
                  startDate: get(webinarDetails, 'start_date', ''),
                  startTime: get(webinarDetails, 'start_time', ''),
                  timezone: get(webinarDetails, 'timezone', ''),
                },
              },
            });
            setLoading(false);
            if (mode === 'next') {
              handleNext();
            } else if (mode !== 'next') {
              endCreateFlow();
              history.push('/campaignV2');
            }
          } else setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
        });
    } else {
      updateOneTimeSeriesCampaign(seriesId, payload)
        .then(async (res) => {
          if (res?.result?.success) {
            let payload = {
              entity_data: {
                advisor: [],
                admin: [],
                firm: [],
              },
            };
            if (selectedAdvisorData.length > 0) {
              payload.entity_data.advisor = selectedAdvisorData.map(
                (i) => i.id
              );
            }
            if (selectedAdminData.length > 0) {
              payload.entity_data.admin = selectedAdminData.map((i) => i.id);
            }
            if (selectedFirmData.length > 0) {
              payload.entity_data.firm = selectedFirmData.map((i) => i.id);
            }

            payload.entity_data = JSON.stringify(payload.entity_data);
            if (!isEnterpriseAdvisor && toggleAutoPost) {
              await postAutoPost({
                seriesId: res?.result?.data?.seriesId || seriesId,
                payload,
              });
            }

            // if (res.result.success) {
            campaignSetState({
              createFlow: true,
              seriesId,
              ...(selectSubscriptionType && {
                subscriptionType: selectSubscriptionType,
              }),
              setObjectives: {
                createMode: createMode,
                is_facebook: !isEmpty(selectedFbContacts) ? 'true' : 'false',
                is_twitter: !isEmpty(selectedTwContacts) ? 'true' : 'false',
                is_linkedin: !isEmpty(selectedLnContacts) ? 'true' : 'false',
                is_email:
                  !isEmpty(selectedEmailList) || !isEmpty(selectedSegmentList)
                    ? 'true'
                    : 'false',
                campaignType: get(setObjectivesTabData, 'campaignType', ''),
                approvedToShare:
                  approveToShare === 'true' && selectedAdvisors.length > 0
                    ? 'true'
                    : 'false',
                campaignSeriesStatus: seriesStatus,
                isCascaded: get(setObjectivesTabData, 'isCascaded', 'false'),
                autoPostToggle: toggleAutoPost,
                autoPost: {
                  advisorData: selectedAdvisorData,
                  adminData: selectedAdminData,
                  firmData: selectedFirmData,
                },
                cascaded: get(setObjectivesTabData, 'cascaded', false),
                platforms: get(setObjectivesTabData, 'platforms', {}),
                selectedAdvisors:
                  approveToShare === 'true' ? selectedAdvisors : [],
                targetField:
                  isEmpty(selectedEmailList) &&
                  isEmpty(selectedFbContacts) &&
                  isEmpty(selectedLnContacts) &&
                  isEmpty(selectedTwContacts) &&
                  isEmpty(selectedSegmentList)
                    ? false
                    : true,
                emList: !segmentTabActive ? selectedEmailList : [],
                fbList: selectedFbContacts,
                lnList: selectedLnContacts,
                twList: selectedTwContacts,
                segmentList: segmentTabActive ? selectedSegmentList : [],
                seriesName: campaignName,
                seriesPurpose: purpose,
                skip_compliance: setObjectivesTabData?.skip_compliance,
                webinar: {
                  wuid: selectedWebinar,
                  topic: get(webinarDetails, 'topic', ''),
                  description: get(webinarDetails, 'agenda', ''),
                  url: get(
                    setObjectivesTabData,
                    'webinar.url',
                    get(webinarDetails, 'url', '')
                  ),
                  link: get(webinarDetails, 'registration_url', ''),
                  isWebinar: webinar,
                  startDate: get(webinarDetails, 'start_date', ''),
                  startTime: get(webinarDetails, 'start_time', ''),
                  timezone: get(webinarDetails, 'timezone', ''),
                },
              },
            });

            setLoading(false);

            // } else setLoading(false);
            if (mode === 'next') {
              handleNext();
            } else if (mode !== 'next') {
              endCreateFlow();
              history.push('/campaignV2');
            }
          } else setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
        });
    }
  };

  const redirectToConfigure = (platform) => {
    if (isAdmin) {
      window.sessionStorage.setItem('selectedIndex', 2);
      history.push('/admin/configure');
    } else {
      window.sessionStorage.setItem('selectedIndex', 1);
      history.push('/configure');
    }

    endCreateFlow();
  };

  const handleSegmentCheckboxClick = (item) => {
    if (isEmpty(selectedSegmentList)) {
      setSelectedSegmentList([...selectedSegmentList, item]);
    } else if (
      !isEmpty(selectedSegmentList) &&
      isEmpty(selectedSegmentList.filter((s) => s.id === item.id))
    ) {
      setSelectedSegmentList([...selectedSegmentList, item]);
    } else {
      let removeItems = selectedSegmentList.filter((s) => s.id !== item.id);
      if (isEmpty(removeItems)) {
        setShowSubscription(false);
        setShowAutoPost(false);
        setShowAutoPostList(false);
      }
      setSelectedSegmentList(removeItems);
    }
  };

  const handleCheckboxClick = (item, type) => {
    if (type === 'em') {
      if (isEmpty(selectedEmailContacts)) {
        setSelectedEmailContacts([...selectedEmailContacts, item]);
      } else if (
        !isEmpty(selectedEmailContacts) &&
        isEmpty(selectedEmailContacts.filter((s) => s.id === item.id))
      ) {
        setSelectedEmailContacts([...selectedEmailContacts, item]);
      } else {
        let removeItems = selectedEmailContacts.filter((s) => s.id !== item.id);
        if (isEmpty(removeItems)) {
          setShowSubscription(false);
          setShowAutoPost(false);
          setShowAutoPostList(false);
        }
        setSelectedEmailContacts(removeItems);
      }
    } else if (type === 'ln') {
      if (isEmpty(selectedLnContacts)) {
        setSelectedLnContacts([...selectedLnContacts, item]);
      } else if (
        !isEmpty(selectedLnContacts) &&
        isEmpty(selectedLnContacts.filter((s) => s === item))
      ) {
        setSelectedLnContacts([...selectedLnContacts, item]);
      } else {
        let removeItems = selectedLnContacts.filter((s) => s !== item);
        setSelectedLnContacts(removeItems);
      }
    } else if (type === 'tw') {
      if (isEmpty(selectedTwContacts)) {
        setSelectedTwContacts([...selectedTwContacts, item]);
      } else if (
        !isEmpty(selectedTwContacts) &&
        isEmpty(selectedTwContacts.filter((s) => s === item))
      ) {
        setSelectedTwContacts([...selectedTwContacts, item]);
      } else {
        let removeItems = selectedTwContacts.filter((s) => s !== item);
        setSelectedTwContacts(removeItems);
      }
    } else {
      if (isEmpty(selectedFbContacts)) {
        setSelectedFbContacts([...selectedFbContacts, item]);
      } else if (
        !isEmpty(selectedFbContacts) &&
        isEmpty(selectedFbContacts.filter((s) => s === item))
      ) {
        setSelectedFbContacts([...selectedFbContacts, item]);
      } else {
        let removeItems = selectedFbContacts.filter((s) => s !== item);
        setSelectedFbContacts(removeItems);
      }
    }
  };

  const handleAutoPostToggle = (checked) => {
    if (checked) {
      setToggleAutoPost('true');
      setShowAutoPostList(true);
    } else {
      setToggleAutoPost('false');
      setShowAutoPostList(false);
    }
  };

  const targetTabs = () => {
    return (
      <TabContainer style={{ width: '100%' }}>
        <SocialTabItem
          onClick={() => {
            setToggleTabs('advisor');
            setOpenSearch(false);
            fetchCompanyData();
            setSearch('');
            setSearchResult('');
            //setFilteredAdminData(adminData);
            setFilteredAdvisorData(advisorData);
          }}
          active={toggleTabs === 'advisor'}
        >
          <div className="chip" style={{ fontSize: '14px' }}>
            Advisors
          </div>
        </SocialTabItem>
        {isEnterpriseLevelAdmin && !isFirmLevelAdmin && (
          <>
            <SocialTabItem
              onClick={() => {
                setToggleTabs('admin');
                fetchCompanyData();
                setOpenSearch(false);
                setSearch('');
                setSearchBox('');
                //setFilteredAdvisorData(advisorData);
                setFilteredAdminData(adminData);
              }}
              active={toggleTabs === 'admin'}
            >
              <div className="chip" style={{ fontSize: '14px' }}>
                Administrators
              </div>
            </SocialTabItem>
            {/* <SocialTabItem
          onClick={() => {
            setToggleTabs('firm');
            fetchCompanyData();
            setOpenSearch(false);
            setSearchBox('');
            setSearchResult('');
          }}
          active={toggleTabs === 'firm'}
        >
          <div className="chip" style={{ fontSize: '14px' }}>
            Firms
          </div>
        </SocialTabItem> */}
          </>
        )}
      </TabContainer>
    );
  };

  const handleSelectAll = (e) => {
    if (toggleTabs === 'advisor') {
      if (e.target.checked) {
        setSelectedAdvisorData([...eligibleAdvisorData]);
      } else {
        setSelectedAdvisorData([]);
      }
    } else if (toggleTabs === 'admin') {
      if (e.target.checked) {
        setSelectedAdminData([...eligibleAdminData]);
      } else {
        setSelectedAdminData([]);
      }
    } else {
      if (e.target.checked) {
        setSelectedFirmData([...eligibleFirmData]);
      } else {
        setSelectedFirmData([]);
      }
    }
  };

  const handleCheckBoxChange = (item, type) => {
    if (type === 'advisor') {
      if (isEmpty(selectedAdvisorData)) {
        setSelectedAdvisorData([...selectedAdvisorData, item]);
      } else if (
        !isEmpty(selectedAdvisorData) &&
        isEmpty(selectedAdvisorData.filter((l) => l.id === item.id))
      ) {
        setSelectedAdvisorData([...selectedAdvisorData, item]);
      } else {
        let discardItems = selectedAdvisorData.filter((l) => l.id !== item.id);
        setSelectedAdvisorData(discardItems);
      }
    } else if (type === 'admin') {
      if (isEmpty(selectedAdminData)) {
        setSelectedAdminData([...selectedAdminData, item]);
      } else if (
        !isEmpty(selectedAdminData) &&
        isEmpty(selectedAdminData.filter((l) => l.id === item.id))
      ) {
        setSelectedAdminData([...selectedAdminData, item]);
      } else {
        let discardItems = selectedAdminData.filter((l) => l.id !== item.id);
        setSelectedAdminData(discardItems);
      }
    } else {
      if (isEmpty(selectedFirmData)) {
        setSelectedFirmData([...selectedFirmData, item]);
      } else if (
        !isEmpty(selectedFirmData) &&
        isEmpty(selectedFirmData.filter((l) => l.id === item.id))
      ) {
        setSelectedFirmData([...selectedFirmData, item]);
      } else {
        let discardItems = selectedFirmData.filter((l) => l.id !== item.id);
        setSelectedFirmData(discardItems);
      }
    }
  };

  const fetchCompanyData = () => {
    setLoader(true);
    getCompanyData({
      companyId: userId,
      type: toggleTabs,
    })
      .then((res) => {
        if (res) {
          if (toggleTabs === 'advisor') {
            let advisors = !isEmpty(res?.data) ? res?.data.map((item) => {
              return {
                ...item,
                name: `${item?.first_name} ${item?.last_name}`,
                status: (item?.auto_post_eligibility.is_default_contactlist && item?.auto_post_eligibility?.is_token) === true ? 'eligible' : 'not eligible'
              }
            }) : []
            setAdvisorData(advisors);
            setFilteredAdvisorData(advisors);
          } else if (toggleTabs === 'admin') {
            let admins = !isEmpty(res?.data) ? res?.data.map((item) => {
              return {
                ...item,
                status: (item?.auto_post_eligibility.is_default_contactlist && item?.auto_post_eligibility?.is_token) === true ? 'eligible' : 'not eligible'
              }
            }) : []
            setAdminData(admins);
            setFilteredAdminData(admins);
          } else {
            setFirmData(res?.data);
          }
        }
        setLoader(false);
      })
      .catch((err) => {
        console.log('ERROR--->', err);
        setLoader(false);
      });
  };

  const eligibleAdvisorData = !isEmpty(filteredAdvisorData) && filteredAdvisorData.filter(
    (item) =>
      item.auto_post_eligibility.is_default_contactlist &&
      item.auto_post_eligibility.is_token === true
  );
  const eligibleAdminData = !isEmpty(filteredAdminData) && filteredAdminData.filter(
    (item) =>
      item.auto_post_eligibility.is_default_contactlist &&
      item.auto_post_eligibility.is_token === true
  );
  const eligibleFirmData = firmData.filter(
    (item) => item.eligible_advisor_count > 0 && item.eligible_advisor_count > 0
  );

  const totalContactsCount = [
    ...selectedAdvisorData,
    ...selectedAdminData,
    ...selectedFirmData,
  ];

  const handleUnselectAll = () => {
    setSelectedAdvisorData([]);
    setSelectedAdminData([]);
    setSelectedFirmData([]);
  };

  const eligibleStatusPill = () => {
    return (
      <Flex
        style={{
          width: 'max-content',
          padding: '6px 12px',
          fontSize: '12px',
          height: '30px',
          backgroundColor: '#B3DAC7',
          color: '#008243',
          border: '1px solid rgb(182, 207, 243)',
          borderRadius: '20px',
          margin: '-6px 6px 5px 0px',
        }}
      >
        Eligible
      </Flex>
    );
  };

  const uneligibleStatusPill = () => {
    return (
      <Flex
        style={{
          width: 'max-content',
          padding: '6px 12px',
          fontSize: '12px',
          height: '30px',
          backgroundColor: '#FFCA99',
          color: '#EA780E',
          border: '1px solid rgb(182, 207, 243)',
          borderRadius: '20px',
          margin: '-6px 6px 5px 0px',
        }}
      >
        Not Eligible
      </Flex>
    );
  };

  const tooltipEligibleBody = (item) => {
    return (
      <Flex
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '250px',
          height: '150px',
          maxHeight: '150px',
          backgroundColor: '#816EF8',
          border: '1px solid #816EF8',
          borderRadius: '8px',
          padding: '10px',
          color: '#FFFFFF',
        }}
      >
        <Flex dislay="flex" width="100%">
          {(item?.auto_post_eligibility.is_default_contactlist ||
            item?.auto_post_eligibility.is_token) === true && (
            <span style={{ fontSize: '14px' }}>
              {toggleTabs === 'advisor'
                ? `${item.first_name} ${item.last_name} is eligible for auto posts`
                : toggleTabs === 'admin'
                ? `${item.name} is eligible for auto posts`
                : null}
            </span>
          )}
        </Flex>
        <hr style={{ color: 'white', width: '100%' }} />
        <Flex display="flex" width="100%" style={{ flexDirection: 'column' }}>
          <Flex display="flex" width="100%">
            {item?.auto_post_eligibility.is_default_contactlist === true && (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  width: '100%',
                  padding: '3px 0px 5px',
                  marginBottom: '8px',
                }}
              >
                <img
                  alt=""
                  src="/assets/images/checked-icon.svg"
                  style={{ marginRight: '6px' }}
                />
                <span
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    fontSize: '14px',
                  }}
                >
                  Email Service Connected
                </span>
              </div>
            )}
          </Flex>
          <Flex display="flex" width="100%">
            {item?.auto_post_eligibility.is_token === true && (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  width: '100%',
                  padding: '3px 0px 5px',
                }}
              >
                <img
                  alt=""
                  src="/assets/images/checked-icon.svg"
                  style={{ marginRight: '6px' }}
                />
                <span
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    fontSize: '14px',
                  }}
                >
                  Default List Created
                </span>
              </div>
            )}
          </Flex>
        </Flex>
      </Flex>
    );
  };

  const tooltipNonEligibleBody = (item) => {
    return (
      <Flex
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '250px',
          height: '180px',
          maxHeight: '180px',
          backgroundColor: '#816EF8',
          border: '1px solid #816EF8',
          borderRadius: '8px',
          padding: '10px',
          color: '#FFFFFF',
        }}
      >
        <Flex dislay="flex" width="100%">
          {item?.auto_post_eligibility.is_default_contactlist === false &&
          item?.auto_post_eligibility.is_token === false ? (
            <span style={{ fontSize: '14px' }}>
              {toggleTabs === 'advisor'
                ? `${item.first_name} ${item.last_name} is not eligible for auto posts as they have not created a default list and not connected to a platform`
                : toggleTabs === 'admin'
                ? `${item.name} is not eligible for auto posts as they have not created a default list and not connected to a platform`
                : ''}
            </span>
          ) : item?.auto_post_eligibility?.is_default_contactlist === false &&
            item?.auto_post_eligibility?.is_token === true ? (
            <span style={{ fontSize: '14px' }}>
              {toggleTabs === 'advisor'
                ? `${item.first_name} ${item.last_name} is not eligible for auto posts as they have not created a default list`
                : toggleTabs === 'admin'
                ? `${item.name} is not eligible for auto posts as they have not created a default list`
                : ''}
            </span>
          ) : item?.auto_post_eligibility.is_token === false &&
            item?.auto_post_eligibility.is_default_contactlist === true ? (
            <span style={{ fontSize: '14px' }}>
              {toggleTabs === 'advisor'
                ? `${item.first_name} ${item.last_name} is not eligible for auto posts as they have not connected to a platform`
                : toggleTabs === 'admin'
                ? `${item.name} is not eligible for auto posts as they have not connected to a platform`
                : ''}
            </span>
          ) : null}
        </Flex>
        <hr style={{ color: 'white', width: '100%' }} />
        <Flex display="flex" width="100%" style={{ flexDirection: 'column' }}>
          <Flex display="flex" width="100%">
            {item?.auto_post_eligibility.is_token === true ? (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  width: '100%',
                  padding: '3px 0px 5px',
                  marginBottom: '8px',
                }}
              >
                <img
                  alt=""
                  src="/assets/images/checked-icon.svg"
                  style={{ marginRight: '6px' }}
                />
                <span
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    fontSize: '14px',
                  }}
                >
                  Email Service Connected
                </span>
              </div>
            ) : (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  width: '100%',
                  padding: '3px 0px 5px',
                  marginBottom: '8px',
                }}
              >
                <img
                  alt=""
                  src="/assets/images/unchecked-icon.svg"
                  style={{ marginRight: '6px' }}
                />
                <span
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    fontSize: '14px',
                  }}
                >
                  Email Service Connected
                </span>
              </div>
            )}
          </Flex>
          <Flex display="flex" width="100%">
            {item?.auto_post_eligibility.is_default_contactlist === true ? (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  width: '100%',
                  padding: '3px 0px 5px',
                }}
              >
                <img
                  alt=""
                  src="/assets/images/checked-icon.svg"
                  style={{ marginRight: '6px' }}
                />
                <span
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    fontSize: '14px',
                  }}
                >
                  Default List Created
                </span>
              </div>
            ) : (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  width: '100%',
                  padding: '3px 0px 5px',
                }}
              >
                <img
                  alt=""
                  src="/assets/images/unchecked-icon.svg"
                  style={{ marginRight: '6px' }}
                />
                <span
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    fontSize: '14px',
                  }}
                >
                  Default List Created
                </span>
              </div>
            )}
          </Flex>
        </Flex>
      </Flex>
    );
  };

  const advisorEligibilityInfo = () => {
    return (
      <Flex
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '250px',
          height: '120px',
          maxHeight: '150px',
          backgroundColor: '#816EF8',
          border: '1px solid #816EF8',
          borderRadius: '8px',
          padding: '10px',
          color: '#FFFFFF',
        }}
      >
        <Flex dislay="flex" width="100%">
          <span style={{ fontSize: '14px' }}>
            Advisors eligible for auto-post
          </span>
        </Flex>
        <hr style={{ color: 'white', width: '100%' }} />
        <Flex display="flex" width="100%" style={{ flexDirection: 'column' }}>
          <Flex display="flex" width="100%">
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                width: '100%',
                padding: '3px 0px 5px',
                marginBottom: '8px',
              }}
            >
              <img
                alt=""
                src="/assets/images/checked-icon.svg"
                style={{ marginRight: '6px' }}
              />
              <span
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  fontSize: '14px',
                }}
              >
                Email Service Connected
              </span>
            </div>
          </Flex>
          <Flex display="flex" width="100%">
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                width: '100%',
                padding: '3px 0px 5px',
              }}
            >
              <img
                alt=""
                src="/assets/images/checked-icon.svg"
                style={{ marginRight: '6px' }}
              />
              <span
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  fontSize: '14px',
                }}
              >
                Default List Created
              </span>
            </div>
          </Flex>
        </Flex>
      </Flex>
    );
  };

  const adminEligibilityInfo = () => {
    return (
      <Flex
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '250px',
          height: '120px',
          maxHeight: '150px',
          backgroundColor: '#816EF8',
          border: '1px solid #816EF8',
          borderRadius: '8px',
          padding: '10px',
          color: '#FFFFFF',
        }}
      >
        <Flex dislay="flex" width="100%">
          <span style={{ fontSize: '14px' }}>
            Administrators eligible for auto-post
          </span>
        </Flex>
        <hr style={{ color: 'white', width: '100%' }} />
        <Flex display="flex" width="100%" style={{ flexDirection: 'column' }}>
          <Flex display="flex" width="100%">
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                width: '100%',
                padding: '3px 0px 5px',
                marginBottom: '8px',
              }}
            >
              <img
                alt=""
                src="/assets/images/checked-icon.svg"
                style={{ marginRight: '6px' }}
              />
              <span
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  fontSize: '14px',
                }}
              >
                Email Service Connected
              </span>
            </div>
          </Flex>
          <Flex display="flex" width="100%">
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                width: '100%',
                padding: '3px 0px 5px',
              }}
            >
              <img
                alt=""
                src="/assets/images/checked-icon.svg"
                style={{ marginRight: '6px' }}
              />
              <span
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  fontSize: '14px',
                }}
              >
                Default List Created
              </span>
            </div>
          </Flex>
        </Flex>
      </Flex>
    );
  };

  const handleSort = (colName, type) => {
    let sortedData;
    if (toggleTabs === 'advisor') {
      if (type === 'asc') {
        sortedData = filteredAdvisorData.sort((a, b) => (
         a[colName].localeCompare(b[colName], 'en', {numeric: true})
       ))
     } else {
        sortedData = filteredAdvisorData.sort((a, b) => (
         b[colName].localeCompare(a[colName], 'en', {numeric: true})
       ))
     }
     setAdvisorData([...sortedData]);
    }
    if (toggleTabs === 'admin') {
      if (type === 'asc') {
        sortedData = filteredAdminData.sort((a, b) => (
         a[colName].localeCompare(b[colName], 'en', {numeric: true})
       ))
     } else {
        sortedData = filteredAdminData.sort((a, b) => (
         b[colName].localeCompare(a[colName], 'en', {numeric: true})
       ))
     }
     setAdminData([...sortedData]);
    }
   
  };


  const AdvisorList = () => {
    return (
      <Flex flexDirection="column" width="100%">
        <ContactsListWrapper
          padding="0px"
          style={{ marginTop: '0px', height: '425px' }}
        >
          <LoaderWrapper isLoading={loader}>
            <ContactListBody height="425px" maxHeight="437px">
              {!isEmpty(filteredAdvisorData) ? (
                filteredAdvisorData.length > 0 &&
                filteredAdvisorData
                  .map((item, index) => (
                    <>
                      <Flex width="100%" style={{ flexDirection: 'row' }}>
                        <CheckBoxSpan
                          key={item.id}
                          screenWidth={window.innerWidth}
                          style={{
                            border: 'none',
                            margin: '9px 0px 4px 6px',
                            textAlign: 'left',
                            width: '25%',
                          }}
                        >
                          <CheckBox
                            id={item.id}
                            value={item.id}
                            disabled={
                              (item?.auto_post_eligibility
                                .is_default_contactlist ||
                                item?.auto_post_eligibility.is_token) ===
                                false ||
                              (item?.auto_post_eligibility
                                .is_default_contactlist &&
                                item?.auto_post_eligibility.is_token) === false
                            }
                            checked={
                              !isEmpty(selectedAdvisorData) &&
                              selectedAdvisorData.filter(
                                (l) => l.id === item.id
                              ).length > 0
                            }
                            onChange={() => {
                              handleCheckBoxChange(item, 'advisor');
                            }}
                          />
                          <CheckBoxLabel
                            fontSize="14px"
                            style={{
                              paddingLeft: '18%',
                              paddingTop: '5px',
                              width: '100%',
                            }}
                            htmlFor={item.id}
                            color="#848484"
                          >
                            {item?.name}
                          </CheckBoxLabel>
                        </CheckBoxSpan>
                        <Flex
                          style={{
                            width: '35%',
                            display: 'flex',
                            height: '25px',
                            marginTop: '5px',
                            color: '#101828',
                            paddingTop: '9px',
                            fontSize: '14px',
                          }}
                        >
                          {item?.email}
                        </Flex>
                        <Flex
                          style={{
                            width: '25%',
                            display: 'flex',
                            height: '25px',
                            marginTop: '5px',
                            color: '#101828',
                            paddingTop: '9px',
                            fontSize: '14px',
                          }}
                        >
                          {item?.company_name}
                        </Flex>
                        <NewTooltip
                          TransitionComponent={Zoom}
                          arrow
                          placement="right"
                          title={
                            (item?.auto_post_eligibility
                              .is_default_contactlist &&
                              item?.auto_post_eligibility.is_token) === true
                              ? tooltipEligibleBody(item)
                              : tooltipNonEligibleBody(item)
                          }
                        >
                          <Flex
                            style={{
                              width: '15%',
                              display: 'flex',
                              height: '25px',
                              marginTop: '5px',
                              color: '#101828',
                              paddingTop: '9px',
                              fontSize: '14px',
                            }}
                          >
                            {(item?.auto_post_eligibility
                              .is_default_contactlist ||
                              item?.auto_post_eligibility.is_token) === false ||
                            (item?.auto_post_eligibility
                              .is_default_contactlist &&
                              item?.auto_post_eligibility.is_token) === false
                              ? uneligibleStatusPill()
                              : eligibleStatusPill()}
                          </Flex>
                        </NewTooltip>
                      </Flex>
                      <hr
                        style={{
                          width: '100%',
                          border: '0.5px solid #e4e7ec',
                          marginBottom: '0px',
                        }}
                      />
                    </>
                  ))
              ) : (
                <span
                  style={{
                    display: 'flex',
                    padding: '10px',
                    margin: '150px auto',
                  }}
                >
                  No Records Found
                </span>
              )}
            </ContactListBody>
          </LoaderWrapper>
        </ContactsListWrapper>
      </Flex>
    );
  };

  const AdminList = () => {
    return (
      <Flex flexDirection="column" width="100%">
        <ContactsListWrapper
          padding="0px"
          style={{ marginTop: '0px', height: '430px' }}
        >
          <LoaderWrapper isLoading={loader}>
            <ContactListBody height="437px" maxHeight="440px">
              {!isEmpty(filteredAdminData) ? (
                filteredAdminData.length > 0 &&
                filteredAdminData
                  .map((item, index) => (
                    <>
                      <Flex width="100%" style={{ flexDirection: 'row' }}>
                        <CheckBoxSpan
                          key={item.id}
                          screenWidth={window.innerWidth}
                          style={{
                            border: 'none',
                            margin: '9px 0px 4px 6px',
                            textAlign: 'left',
                            width: '25%',
                          }}
                        >
                          <CheckBox
                            id={item.id}
                            value={item.name}
                            disabled={
                              (item?.auto_post_eligibility
                                .is_default_contactlist ||
                                item?.auto_post_eligibility.is_token) ===
                                false ||
                              (item?.auto_post_eligibility
                                .is_default_contactlist &&
                                item?.auto_post_eligibility.is_token) === false
                            }
                            checked={
                              !isEmpty(selectedAdminData) &&
                              selectedAdminData.filter((l) => l.id === item.id)
                                .length > 0
                            }
                            onChange={() => {
                              handleCheckBoxChange(item, 'admin');
                            }}
                          />
                          <CheckBoxLabel
                            fontSize="14px"
                            style={{
                              paddingLeft: '18%',
                              paddingTop: '5px',
                              width: '100%',
                            }}
                            htmlFor={item.id}
                            color="#848484"
                          >
                            {item.name}
                          </CheckBoxLabel>
                        </CheckBoxSpan>
                        <Flex
                          style={{
                            width: '35%',
                            display: 'flex',
                            height: '25px',
                            marginTop: '5px',
                            color: '#101828',
                            paddingTop: '9px',
                            fontSize: '14px',
                          }}
                        >
                          {item?.email}
                        </Flex>
                        <Flex
                          style={{
                            width: '25%',
                            display: 'flex',
                            height: '25px',
                            marginTop: '5px',
                            color: '#101828',
                            paddingTop: '9px',
                            fontSize: '14px',
                          }}
                        >
                          {item?.firm_name}
                        </Flex>
                        <NewTooltip
                          TransitionComponent={Zoom}
                          arrow
                          placement="right"
                          title={
                            (item?.auto_post_eligibility
                              .is_default_contactlist &&
                              item?.auto_post_eligibility.is_token) === true
                              ? tooltipEligibleBody(item)
                              : tooltipNonEligibleBody(item)
                          }
                        >
                          <Flex
                            style={{
                              width: '15%',
                              display: 'flex',
                              height: '25px',
                              marginTop: '5px',
                              color: '#101828',
                              paddingTop: '9px',
                              fontSize: '14px',
                            }}
                          >
                            {(item?.auto_post_eligibility
                              .is_default_contactlist ||
                              item?.auto_post_eligibility.is_token) === false ||
                            (item?.auto_post_eligibility
                              .is_default_contactlist &&
                              item?.auto_post_eligibility.is_token) === false
                              ? uneligibleStatusPill()
                              : eligibleStatusPill()}
                          </Flex>
                        </NewTooltip>
                      </Flex>
                      <hr
                        style={{
                          width: '100%',
                          border: '0.5px solid #e4e7ec',
                          marginBottom: '0px',
                        }}
                      />
                    </>
                  ))
              ) : (
                <span
                  style={{
                    display: 'flex',
                    padding: '10px',
                    margin: '150px auto',
                  }}
                >
                  No Records Found
                </span>
              )}
            </ContactListBody>
          </LoaderWrapper>
        </ContactsListWrapper>
      </Flex>
    );
  };

  /* FIRM TAB STARTS */

  // const FirmList = () => {
  //   return (
  //     <Flex flexDirection="column" width="100%">
  //       <ContactsListWrapper
  //         padding="0px"
  //         style={{ marginTop: '0px', height: '430px' }}
  //       >
  //         <LoaderWrapper isLoading={loader}>
  //           <ContactListBody height="437px" maxHeight="440px">
  //             {!isEmpty(firmData) &&
  //               firmData.filter((item) => item?.name.toLowerCase().includes(search)).map((item, index) => (
  //                 <>
  //                   <Flex width="100%" style={{ flexDirection: 'row' }}>
  //                     <CheckBoxSpan
  //                       key={item.id}
  //                       screenWidth={window.innerWidth}
  //                       style={{
  //                         border: 'none',
  //                         margin: '9px 0px 4px 6px',
  //                         textAlign: 'left',
  //                         width: '33.33%',
  //                       }}
  //                     >
  //                       <CheckBox
  //                         id={item.id}
  //                         value={item.name}
  //                         disabled={(item?.eligible_advisor_count && item.eligible_admin_count) === 0}
  //                         checked={
  //                           !isEmpty(selectedFirmData) &&
  //                           selectedFirmData.filter((s) => s.id === item.id)
  //                             .length > 0
  //                         }
  //                         onChange={() => {
  //                           console.log('ITEN---->', item);
  //                           handleCheckBoxChange(item, 'firm');
  //                         }}
  //                       />
  //                       <CheckBoxLabel
  //                         fontSize="14px"
  //                         style={{
  //                           paddingLeft: '13.5%',
  //                           paddingTop: '5px',
  //                         }}
  //                         htmlFor={item.id}
  //                         color="#848484"
  //                       >
  //                         {item.name}
  //                       </CheckBoxLabel>
  //                     </CheckBoxSpan>
  //                     <Flex
  //                       style={{
  //                         width: '33.33%',
  //                         display: 'flex',
  //                         height: '25px',
  //                         marginTop: '5px',
  //                         color: '#101828',
  //                         paddingTop: '9px',
  //                         fontSize: '14px',
  //                         paddingLeft: '2px'
  //                       }}
  //                     >
  //                       {item?.eligible_advisor_count}

  //                     </Flex>
  //                     <Flex
  //                       style={{
  //                         width: '33.33%',
  //                         display: 'flex',
  //                         height: '25px',
  //                         marginTop: '5px',
  //                         color: '#101828',
  //                         paddingTop: '9px',
  //                         fontSize: '14px',
  //                         paddingLeft: '2px'
  //                       }}
  //                     >
  //                       {item?.eligible_admin_count}
  //                     </Flex>
  //                   </Flex>
  //                   <hr
  //                     style={{
  //                       width: '100%',
  //                       border: '0.5px solid #e4e7ec',
  //                       marginBottom: '0px',
  //                     }}
  //                   />
  //                 </>
  //               ))}
  //           </ContactListBody>
  //         </LoaderWrapper>
  //       </ContactsListWrapper>
  //     </Flex>
  //   );
  // };

  /* FIRM TAB ENDS */

  const emailTargetTabs = () => {
    if (
      (setObjectivesTabData?.cascaded &&
        setObjectivesTabData?.platforms?.is_email) ||
      !setObjectivesTabData?.cascaded
    ) {
      return (
        <TabContainer>
          <TabItem
            onClick={() => handleEmailToggle(false)}
            style={
              !segmentTabActive ? { borderBottom: '1px solid #101828' } : {}
            }
          >
            Email Lists
          </TabItem>
          <TabItem
            onClick={() => handleEmailToggle(true)}
            style={
              segmentTabActive ? { borderBottom: '1px solid #101828' } : {}
            }
          >
            Segments
          </TabItem>
        </TabContainer>
      );
    } else {
      return null;
    }
  };

  const socialTargetTabs = () => {
    if (
      (setObjectivesTabData?.cascaded &&
        (setObjectivesTabData?.platforms?.is_facebook ||
          setObjectivesTabData?.platforms?.is_linkedin ||
          setObjectivesTabData?.platforms?.is_twitter)) ||
      !setObjectivesTabData?.cascaded
    ) {
      return (
        <TabContainer style={{ width: '100%' }}>
          {((setObjectivesTabData?.cascaded &&
            setObjectivesTabData?.platforms?.is_facebook) ||
            !setObjectivesTabData?.cascaded) && (
            <SocialTabItem
              onClick={() => setSelectedSocialPlatform('fb')}
              active={selectedSocialPlatform === 'fb'}
              style={{ width: '33.33%' }}
            >
              <div className="chip" style={{ fontSize: '14px' }}>
                <img src="/assets/images/Campaign/new-fb-icon.svg" alt="" />
                Facebook
              </div>
            </SocialTabItem>
          )}
          {((setObjectivesTabData?.cascaded &&
            setObjectivesTabData?.platforms?.is_linkedin) ||
            !setObjectivesTabData?.cascaded) && (
            <SocialTabItem
              onClick={() => setSelectedSocialPlatform('ln')}
              active={selectedSocialPlatform === 'ln'}
              style={{ width: '33.33%' }}
            >
              <div className="chip" style={{ fontSize: '14px' }}>
                <img src="/assets/images/Campaign/ln-icon.svg" alt="" />
                LinkedIn
              </div>
            </SocialTabItem>
          )}
          {((setObjectivesTabData?.cascaded &&
            setObjectivesTabData?.platforms?.is_twitter) ||
            !setObjectivesTabData?.cascaded) && (
            <SocialTabItem
              onClick={() => setSelectedSocialPlatform('tw')}
              active={selectedSocialPlatform === 'tw'}
              style={{ width: '33.33%' }}
            >
              <div className="chip" style={{ fontSize: '14px' }}>
                <img src="/assets/images/Campaign/new-tw-icon.svg" alt="" />
                Twitter
              </div>
            </SocialTabItem>
          )}
        </TabContainer>
      );
    } else {
      return null;
    }
  };

  const emailListBox = () => {
    return (
      <Flex flexDirection="column" width="100%" marginRight="20px">
        <RecipientsListWrapper
          padding="0px"
          border={emailListValid ? '0px solid #bccdd4' : '1px solid #FF0000'}
          style={{ marginTop: '0px' }}
        >
          {isEmpty(get(socialNetworkData, 'em', [])) ? (
            <Flex
              height="100%"
              justifyContent="center"
              width="100%"
              fontSize="12px"
              alignItems="center"
              style={{
                cursor: 'pointer',
              }}
              onClick={() => redirectToConfigure('em')}
            >
              Please Connect to Email
            </Flex>
          ) : (
            <LoaderWrapper isLoading={contactsLoading}>
              <RecipientsListBody height="168px">
                {!isEmpty(emailList) &&
                  emailList.map((item, index) => (
                    <React.Fragment key={index}>
                      <FilterRowChips
                        style={{
                          fontSize: '12px',
                          padding: '5px 7px',
                          backgroundColor:
                            item.description === 'group_header'
                              ? '#E3E7EE'
                              : '',
                        }}
                      >
                        <label
                          className="checkbox-button"
                          style={{
                            cursor: 'pointer',
                          }}
                        >
                          {item.description !== 'group_header' && (
                            <>
                              <input
                                type="checkbox"
                                className="checkbox-button__input"
                                name="draft"
                                id={`email_${item.name}`}
                                onChange={() => handleCheckboxClick(item, 'em')}
                                checked={
                                  !isEmpty(selectedEmailContacts) &&
                                  selectedEmailContacts.filter(
                                    (s) => s.id === item.id
                                  ).length > 0
                                }
                                value={item.name}
                              />
                              <span
                                className="checkbox-button__control"
                                style={{
                                  cursor: 'pointer',
                                }}
                              ></span>
                            </>
                          )}
                          <span
                            className="checkbox-button__label"
                            style={{
                              color:
                                item.description === 'group_header'
                                  ? '#000'
                                  : '#49484a',
                              fontWeight:
                                item.description === 'group_header'
                                  ? '800'
                                  : '100',
                              // fontWeight: '100',
                              marginLeft:
                                item.description === 'group_header'
                                  ? '32px'
                                  : '',
                              cursor: 'pointer',
                            }}
                          >
                            {item.name}
                          </span>
                        </label>
                      </FilterRowChips>
                    </React.Fragment>
                  ))}
              </RecipientsListBody>
            </LoaderWrapper>
          )}
        </RecipientsListWrapper>
      </Flex>
    );
  };

  const segmentListBox = () => {
    return (
      <Flex flexDirection="column" width="100%" marginRight="20px">
        <RecipientsListWrapper
          border="0px solid #bccdd4"
          style={{ marginTop: '0px' }}
          padding="0px"
        >
          <LoaderWrapper isLoading={segmentsLoading}>
            {segmentList.length > 0 &&
            !isEmpty(get(socialNetworkData, 'em', [])) ? (
              <RecipientsListBody height="168px">
                {segmentList.map((item, index) => (
                  <>
                    <FilterRowChips
                      style={{
                        fontSize: '12px',
                        padding: '5px 7px',
                      }}
                    >
                      <label
                        className="checkbox-button"
                        style={{
                          cursor: 'pointer',
                        }}
                      >
                        <input
                          type="checkbox"
                          className="checkbox-button__input"
                          name="draft"
                          id={`segment_${item.name}`}
                          onChange={() => handleSegmentCheckboxClick(item)}
                          checked={
                            !isEmpty(selectedSegmentList) &&
                            selectedSegmentList.filter((s) => s.id === item.id)
                              .length > 0
                          }
                          value={item.name}
                        />
                        <span
                          className="checkbox-button__control"
                          style={{
                            cursor: 'pointer',
                          }}
                        ></span>
                        <span
                          className="checkbox-button__label"
                          style={{
                            color: '#49484a',
                            fontWeight: '100',
                            cursor: 'pointer',
                          }}
                        >
                          {item.name}
                        </span>
                      </label>
                    </FilterRowChips>
                  </>
                ))}
              </RecipientsListBody>
            ) : (
              <Flex
                height="100%"
                justifyContent="center"
                width="100%"
                fontSize="12px"
                alignItems="center"
                style={{
                  cursor: 'pointer',
                }}
                onClick={() =>
                  isEmpty(get(socialNetworkData, 'em', []))
                    ? redirectToConfigure('em')
                    : redirectToConfigure('segment')
                }
              >
                {isEmpty(get(socialNetworkData, 'em', []))
                  ? 'Please Connect to Email'
                  : 'Create a segment in your Contacts section'}
              </Flex>
            )}
          </LoaderWrapper>
        </RecipientsListWrapper>
      </Flex>
    );
  };

  const FacebookListBox = () => {
    return (
      <Flex flexDirection="column" width="100%" marginRight="20px">
        <RecipientsListWrapper
          padding="0px"
          border="0px solid #bccdd4"
          style={{ marginTop: '0px' }}
        >
          {isEmpty(get(socialNetworkData, 'fb', [])) ? (
            <Flex
              height="100%"
              justifyContent="center"
              width="100%"
              fontSize="12px"
              alignItems="center"
              style={{
                cursor: 'pointer',
              }}
              onClick={() => redirectToConfigure('fb')}
            >
              Please Connect to Facebook
            </Flex>
          ) : (
            <RecipientsListBody height="168px">
              {!isEmpty(get(socialNetworkData, 'fb[0].page', [])) && !ascFbOrder
                ? map(get(socialNetworkData, 'fb[0].page', []), (item, key) => (
                    <>
                      <FilterRowChips
                        style={{
                          fontSize: '12px',
                          padding: '5px 7px',
                        }}
                      >
                        <label
                          className="checkbox-button"
                          style={{
                            cursor: 'pointer',
                          }}
                        >
                          <input
                            type="checkbox"
                            className="checkbox-button__input"
                            name="draft"
                            onChange={() => handleCheckboxClick(key, 'fb')}
                            checked={
                              !isEmpty(
                                selectedFbContacts.filter((s) => s === key)
                              )
                            }
                            value={item.details.name}
                          />
                          <span
                            className="checkbox-button__control"
                            style={{
                              cursor: 'pointer',
                            }}
                          ></span>
                          <span
                            className="checkbox-button__label"
                            style={{
                              color: '#49484a',
                              fontWeight: '100',
                              cursor: 'pointer',
                            }}
                          >
                            {item.details.name}
                          </span>
                        </label>
                      </FilterRowChips>
                    </>
                  ))
                : map(get(socialNetworkData, 'fb[0].page', []))
                    .sort((a, b) =>
                      a.details.name.localeCompare(b.details.name)
                    )
                    .map((item, index) => (
                      <>
                        <FilterRowChips
                          style={{
                            fontSize: '12px',
                            padding: '5px 0px',
                          }}
                        >
                          <label
                            className="checkbox-button"
                            style={{
                              cursor: 'pointer',
                            }}
                          >
                            <input
                              type="checkbox"
                              className="checkbox-button__input"
                              name="draft"
                              onChange={() =>
                                handleCheckboxClick(item.id, 'fb')
                              }
                              checked={
                                !isEmpty(
                                  selectedFbContacts.filter(
                                    (s) => s === item.id
                                  )
                                )
                              }
                              value={item.details.name}
                            />
                            <span
                              className="checkbox-button__control"
                              style={{
                                cursor: 'pointer',
                              }}
                            ></span>
                            <span
                              className="checkbox-button__label"
                              style={{
                                color: '#49484a',
                                fontWeight: '100',
                                cursor: 'pointer',
                              }}
                            >
                              {item.details.name}
                            </span>
                          </label>
                        </FilterRowChips>
                      </>
                    ))}
            </RecipientsListBody>
          )}
        </RecipientsListWrapper>
      </Flex>
    );
  };

  const LinkedinListBox = () => {
    return (
      <Flex flexDirection="column" width="100%" marginRight="20px">
        <RecipientsListWrapper
          padding="0px"
          border="0px solid #bccdd4"
          style={{ marginTop: '0px' }}
        >
          {isEmpty(get(socialNetworkData, 'ln', [])) ? (
            <Flex
              height="100%"
              justifyContent="center"
              width="100%"
              fontSize="12px"
              alignItems="center"
              style={{
                cursor: 'pointer',
              }}
              onClick={() => redirectToConfigure('ln')}
            >
              Please Connect to LinkedIn
            </Flex>
          ) : (
            <RecipientsListBody height="168px">
              <FilterRowChips style={{ fontSize: '12px', padding: '5px 7px' }}>
                <label
                  className="checkbox-button"
                  style={{
                    cursor: 'pointer',
                  }}
                >
                  <input
                    type="checkbox"
                    className="checkbox-button__input"
                    name="draft"
                    onChange={() => handleCheckboxClick(0, 'ln')}
                    checked={!isEmpty(selectedLnContacts.filter((s) => !s))}
                    value={'news-feed'}
                  />
                  <span
                    className="checkbox-button__control"
                    style={{
                      cursor: 'pointer',
                    }}
                  ></span>
                  <span
                    className="checkbox-button__label"
                    style={{
                      color: '#49484a',
                      fontWeight: '100',
                      cursor: 'pointer',
                    }}
                  >
                    On News Feed
                  </span>
                </label>
              </FilterRowChips>
              {!isEmpty(get(socialNetworkData, 'ln[0].page', [])) && !ascLnOrder
                ? map(get(socialNetworkData, 'ln[0].page', []), (item, key) => (
                    <>
                      <FilterRowChips
                        style={{
                          fontSize: '12px',
                          padding: '5px 7px',
                        }}
                      >
                        <label
                          className="checkbox-button"
                          style={{
                            cursor: 'pointer',
                          }}
                        >
                          <input
                            type="checkbox"
                            className="checkbox-button__input"
                            name="draft"
                            onChange={() => handleCheckboxClick(key, 'ln')}
                            checked={
                              !isEmpty(
                                selectedLnContacts.filter((s) => s === key)
                              )
                            }
                            value={item.details.name}
                          />
                          <span
                            className="checkbox-button__control"
                            style={{
                              cursor: 'pointer',
                            }}
                          ></span>
                          <span
                            className="checkbox-button__label"
                            style={{
                              color: '#49484a',
                              fontWeight: '100',
                              cursor: 'pointer',
                            }}
                          >
                            {item.details.name}
                          </span>
                        </label>
                      </FilterRowChips>
                    </>
                  ))
                : map(get(socialNetworkData, 'ln[0].page', []))
                    .sort((a, b) =>
                      a.details.name.localeCompare(b.details.name)
                    )
                    .map((item, index) => (
                      <>
                        <FilterRowChips
                          style={{
                            fontSize: '12px',
                            padding: '5px 0px',
                          }}
                        >
                          <label
                            className="checkbox-button"
                            style={{
                              cursor: 'pointer',
                            }}
                          >
                            <input
                              type="checkbox"
                              className="checkbox-button__input"
                              name="draft"
                              onChange={() =>
                                handleCheckboxClick(item.id, 'ln')
                              }
                              checked={
                                !isEmpty(
                                  selectedLnContacts.filter(
                                    (s) => s === item.id
                                  )
                                )
                              }
                              value={item.details.name}
                            />
                            <span
                              className="checkbox-button__control"
                              style={{
                                cursor: 'pointer',
                              }}
                            ></span>
                            <span
                              className="checkbox-button__label"
                              style={{
                                color: '#49484a',
                                fontWeight: '100',
                                cursor: 'pointer',
                              }}
                            >
                              {item.details.name}
                            </span>
                          </label>
                        </FilterRowChips>
                      </>
                    ))}
            </RecipientsListBody>
          )}
        </RecipientsListWrapper>
      </Flex>
    );
  };

  const TwitterListBox = () => {
    return (
      <Flex flexDirection="column" width="100%" marginRight="20px">
        <RecipientsListWrapper
          padding="0px"
          border="0px solid #bccdd4"
          style={{ marginTop: '0px' }}
        >
          {isEmpty(get(socialNetworkData, 'tw', [])) ? (
            <Flex
              height="100%"
              justifyContent="center"
              width="100%"
              fontSize="12px"
              alignItems="center"
              style={{
                cursor: 'pointer',
              }}
              onClick={() => redirectToConfigure('tw')}
            >
              Please Connect to Twitter
            </Flex>
          ) : (
            <RecipientsListBody height="168px">
              <FilterRowChips style={{ fontSize: '12px', padding: '5px 7px' }}>
                <label
                  className="checkbox-button"
                  style={{
                    cursor: 'pointer',
                  }}
                >
                  <input
                    type="checkbox"
                    className="checkbox-button__input"
                    name="draft"
                    onChange={() => handleCheckboxClick(0, 'tw')}
                    checked={!isEmpty(selectedTwContacts.filter((s) => !s))}
                    id="select_all_tw"
                    value="news-feed"
                  />
                  <span
                    className="checkbox-button__control"
                    style={{
                      cursor: 'pointer',
                    }}
                  ></span>
                  <span
                    className="checkbox-button__label"
                    style={{
                      color: '#49484a',
                      fontWeight: '100',
                      cursor: 'pointer',
                    }}
                  >
                    On News Feed
                  </span>
                </label>
              </FilterRowChips>
            </RecipientsListBody>
          )}
        </RecipientsListWrapper>
      </Flex>
    );
  };

  return !requestLoading ? (
    <Box>
      <AutomatedCampaignFormWrapper
        style={{
          width: '84%',
          margin: '0px auto 100px',
          border: 'none',
          paddingTop: '0px',
        }}
      >
        <Flex width="100%" alignItems="center">
          <WhiteCircle height="40px" width="40px" background="#816EF8">
            <span
              style={{
                color: '#fff',
                fontSize: '16px',
                position: 'absolute',
                top: '6px',
                left: '0px',
                width: '100%',
              }}
            >
              1
            </span>
          </WhiteCircle>
          <Flex fontSize="20px" color="#101828" style={{ fontWeight: 500 }}>
            Campaign details
          </Flex>
        </Flex>
        <hr
          style={{
            width: '100%',
            border: '1px solid #E4E7EC',
            marginBottom: '20px',
          }}
        ></hr>
        <Flex width="100%" justifyContent="center">
          <Box width="35%" fontSize="18px" mr="15px">
            <Flex className="field__title" width="100%" alignItems={'center'}>
              <span> Campaign Name*</span>
            </Flex>
            <Box width="100%" pt="8px">
              <StyledTextInput
                maxLength="100"
                placeholder="Campaign name"
                value={campaignName}
                onChange={(e) => setCampaignName(e.target.value)}
                name="campaign_name"
                border={
                  campaignNameValid ? '1px solid #8493a6' : '1px solid #FF0000'
                }
              />
            </Box>
          </Box>
          <Box width="41%" fontSize="18px">
            <Box width="100%" className="field__title">
              Purpose
            </Box>
            <Box width="100%" pt="8px">
              <StyledTextInput
                maxLength="1000"
                placeholder="Campaign Purpose"
                value={purpose}
                onChange={(e) => setPurpose(e.target.value)}
              />
            </Box>
          </Box>
        </Flex>
        <LineBreaker style={{ margin: '20px auto 10px auto', width: '77%' }} />
        <Flex
          flexDirection="column"
          width="77%"
          fontSize="18px"
          color="#000000"
          justifyContent="center"
          style={{
            padding: '0px 10px 10px 0px',
            fontSize: '16px',
            marginTop: '15px',
            marginLeft: 'auto',
            marginRight: 'auto',
          }}
        >
          <Flex width="100%" style={{ fontWeight: '800' }}>
            Target
          </Flex>
        </Flex>
        {/* <EmailPlatformContainer style={{border: '1px solid #E4E7EC', }}> */}
        <Flex
          width="100%"
          display="flex"
          flexDirection="row"
          justifyContent="flex-start"
          ml="11%"
        >
          {((setObjectivesTabData?.cascaded &&
            setObjectivesTabData?.platforms?.is_email) ||
            !setObjectivesTabData?.cascaded) && (
            <Flex
              display="flex"
              justifyContent="center"
              flexDirection="column"
              marginRight="30px"
              style={{
                border: segmentListValid
                  ? '1px solid #E4E7EC'
                  : '1px solid #FF0000',
                width: '35%',
                borderRadius: '4px',
                backgroundColor: '#fff',
              }}
            >
              <Header>
                <img
                  style={{ height: 20, width: 20, margin: '2px 8px 0 0' }}
                  src="/assets/images/Campaign/new-email-icon.svg"
                  alt="email"
                />
                <span style={{ fontSize: '18px' }}>Email</span>
              </Header>
              {emailTargetTabs()}
              {/* {curFlow === 'emailAndSocial' && ( */}
              <>{segmentTabActive ? segmentListBox() : emailListBox()}</>
              {/* )} */}
            </Flex>
          )}
          {((setObjectivesTabData?.cascaded &&
            (setObjectivesTabData?.platforms?.is_facebook ||
              setObjectivesTabData?.platforms?.is_linkedin ||
              setObjectivesTabData?.platforms?.is_twitter)) ||
            !setObjectivesTabData?.cascaded) && (
            <Flex
              display="flex"
              justifyContent="center"
              flexDirection="column"
              style={{
                border: segmentListValid
                  ? '1px solid #E4E7EC'
                  : '1px solid #FF0000',
                borderRadius: '4px',
                width: '40%',
              }}
            >
              <Header
                style={{
                  width: '100%',
                  WebkitBorderTopRightRadius: '4px',
                  borderTopLeftRadius: '4px',
                }}
              >
                <img
                  style={{ height: 20, width: 20, margin: '2px 8px 0 0' }}
                  src="/assets/images/Campaign/social-icon.svg"
                  alt="email"
                />
                <span style={{ fontSize: '18px' }}>Social</span>
              </Header>
              {socialTargetTabs()}

              {selectedSocialPlatform === 'fb' && FacebookListBox()}
              {selectedSocialPlatform === 'ln' && LinkedinListBox()}
              {selectedSocialPlatform === 'tw' && TwitterListBox()}
            </Flex>
          )}
        </Flex>
        <LineBreaker style={{ margin: '20px auto 10px auto', width: '77%' }} />
        {showSubscription && (
          <>
            <Flex
              flexDirection="row"
              display="flex"
              width="100%"
              fontSize="14px"
              color="#000000"
              mr="15px"
              style={{
                position: 'relative',
                paddingBottom: '5px',
              }}
            >
              <Flex
                width="77%"
                margin="0px auto"
                style={{ fontWeight: '600', fontSize: '18px' }}
              >
                Subscription Type*
              </Flex>
            </Flex>
            <Flex
              width="77%"
              fontSize="14px"
              color="#667085"
              margin="0px auto"
              style={{ position: 'relative' }}
            >
              Designate the subscription type. This will be used to manage the
              contact's email preferences.
            </Flex>
            <Flex
              display="flex"
              flexDirection="row"
              justifyContent="center"
              flexWrap="wrap"
            >
              {!isEmpty(subscriptionTypeOptions) &&
                subscriptionTypeOptions.map((item, index) => (
                  <Flex
                    width="38%"
                    height="85px"
                    marginTop="10px"
                    padding="10px"
                    bgColor="#fff"
                    mr="16px"
                    display="flex"
                    flexDirection="column"
                    style={{
                      borderRadius: '6px',
                      border: subscriptionValid
                        ? '1px solid #bccdd4'
                        : '1px solid #FF0000',
                      backgroundColor: '#FFFFFF',
                    }}
                  >
                    <Flex>
                      <FilterRowChips
                        style={{
                          fontSize: '12px',
                          padding: '5px 7px',
                        }}
                      >
                        <label
                          className="checkbox-button"
                          style={{
                            cursor: 'pointer',
                            marginLeft: '-7px',
                            marginTop: '-4px',
                          }}
                        >
                          <input
                            type="checkbox"
                            className="checkbox-button__input"
                            name="newsletters"
                            onChange={(e) =>
                              setSelectSubscriptionType(
                                e.target.checked ? item.value : ''
                              )
                            }
                            checked={selectSubscriptionType === item.value}
                            value={selectSubscriptionType}
                          />
                          <span
                            className="checkbox-button__control"
                            style={{
                              cursor: 'pointer',
                              marginRight: '5px',
                              marginTop: '-2px',
                            }}
                          ></span>
                          <span
                            className="checkbox-button__label"
                            style={{
                              color: '#000000',
                              fontWeight: '100',
                              cursor: 'pointer',
                              fontSize: '14px',
                            }}
                          >
                            {item.label}
                          </span>
                        </label>
                      </FilterRowChips>
                    </Flex>
                    <Flex
                      style={{
                        fontSize: '12px',
                        paddingLeft: '12px',
                        color: '#475467',
                        textAlign: 'left',
                        marginLeft: '12px',
                        paddingRight: '10px',
                      }}
                    >
                      {item.description}
                    </Flex>
                  </Flex>
                ))}
            </Flex>
          </>
        )}
        {/* </EmailPlatformContainer> */}
        {/* <LineBreaker style={{ margin: '20px auto 10px 0px' }} /> */}
        {/* <Flex
          display="flex"
          justifyContent="center"
          flexDirection="column"
          width="100%"
        >
          <Flex
            display="flex"
            fontSize="18px"
            color="#101828"
            flexDirection="row"
            marginBottom="12px"
          >
            Webinars
            <span display="flex" style={{ color: '#98A2B3', fontSize: '18px' }}>
              (optional)
            </span>
          </Flex>
          <Button
            styles={{ borderRadius: '4px', border: '1px solid #E4E7EC' }}
            color="#101828"
            bgColor="#FFF"
            margin="0px 10px 0px 0px"
            width="15%"
          >
            Connect to vendor
          </Button>
        </Flex> */}

        {(isFirmLevelAdmin || isEnterpriseLevelAdmin) &&
          !isEnterpriseAdvisor &&
          showAutoPost && (
            <>
              <LineBreaker
                style={{ margin: '20px auto 10px 120px', width: '78%' }}
              />
              <Flex
                width="100%"
                display="flex"
                justifyContent="flex-start"
                flexDirection="row"
                marginLeft="11%"
              >
                <Flex
                  width="50%"
                  justifyContent="flex-start"
                  alignItems="center"
                  style={{
                    color: toggleAutoPost === 'true' ? '#000000' : '#98A2B3',
                    fontSize: '18px',
                  }}
                >
                  Auto Post
                </Flex>
                <Flex
                  width="50%"
                  justifyContent="flex-start"
                  alignItems="center"
                  flexDirection="row"
                >
                  <Flex
                    display="flex"
                    width="100%"
                    style={{ justifyContent: 'center', marginRight: '10px' }}
                  >
                    <ToggleButton
                      className="react-switch"
                      onChange={(checked) => handleAutoPostToggle(checked)}
                      checked={toggleAutoPost === 'true'}
                      onColor="#6351ed"
                      offColor="#ccc"
                      offHandleColor="#fff"
                      onHandleColor="#fff"
                      handleDiameter={10}
                      uncheckedIcon=""
                      checkedIcon=""
                      boxShadow=""
                      activeBoxShadow=""
                      type="autoPost"
                      left="5px"
                      margin="-3px 20px 0px 0px"
                      top="10px"
                    />
                    <Flex
                      style={{
                        padding: '3px 0px 0px',
                        fontSize: '15px',
                        color: '#000',
                      }}
                    >
                      {toggleAutoPost === 'true' ? 'On' : ' Off'}
                    </Flex>
                  </Flex>
                </Flex>
              </Flex>
              <Flex
                width="100%"
                justifyContent="flex-start"
                alignItems="center"
                style={{
                  color: '#98A2B3',
                  fontSize: '14px',
                  marginLeft: '11%',
                }}
              >
                Select users to automatically share on behalf of
              </Flex>
            </>
          )}

        {toggleAutoPost === 'true' &&
          showAutoPost &&
          showAutoPostList &&
          (isFirmLevelAdmin || isEnterpriseLevelAdmin) &&
          !isEnterpriseAdvisor && (
            <>
              <Flex
                width="78%"
                marginTop="10px"
                bgColor="#fff"
                display="flex"
                flexDirection="column"
                style={{
                  borderRadius: '6px',
                  border: autoPostDataValid
                    ? '1px solid #bccdd4'
                    : '1px solid #FF0000',
                  backgroundColor: '#FFFFFF',
                  height: '610px',
                  marginLeft: '11%',
                  boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.12)',
                }}
              >
                <Flex
                  display="flex"
                  justifyContent="space-between"
                  flexDirection="row"
                  width="100%"
                >
                  <Flex
                    display="flex"
                    justifyContent="center"
                    flexDirection="column"
                    padding="5px 0px 8px 12px"
                  >
                    <Flex color="#101828" fontSize="18px">
                      {toggleTabs === 'advisor'
                        ? 'Select Advisors'
                        : toggleTabs === 'admin'
                        ? 'Select Administrators'
                        : toggleTabs === 'firm'
                        ? 'Select Firms'
                        : ''}
                    </Flex>
                    <Flex
                      display="flex"
                      flexDirection="row"
                      justifyContent="center"
                      alignItems="center"
                      style={{ height: '30px', padding: '5px 0px' }}
                    >
                      <Flex color="#475467" fontSize="14px">
                        {`${totalContactsCount.length} Selected`}
                      </Flex>
                      <Flex onClick={handleUnselectAll}>
                        <ul>
                          <li
                            style={{
                              fontSize: '14px',
                              cursor: 'pointer',
                              marginLeft: '-10px',
                            }}
                          >
                            Unselect All
                          </li>
                        </ul>
                      </Flex>
                    </Flex>
                    {/* <Flex color="#475467" fontSize="14px">
                    {toggleTabs === 'advisor' &&
                      `${advisorData.length} ${
                        advisorData.length <= 1 ? 'Advisor' : 'Advsiors'
                      }`}
                    {toggleTabs === 'admin' &&
                      `${adminData.length} ${
                        adminData.length <= 1
                          ? 'Administrator'
                          : 'Administrators'
                      }`}
                    {toggleTabs === 'firm' &&
                      `${firmData.length} ${
                        firmData.length <= 1 ? 'Firm' : 'Firms'
                      }`}
                  </Flex> */}
                  </Flex>
                  {/* <Flex
                  display="flex"
                  justifyContent="center"
                  style={{ marginTop: '11px', paddingRight: '7px' }}
                > */}
                  {/* <img
                    alt=""
                    src="/assets/images/Campaign/new-search-icon.svg"
                    style={{
                      marginRight: '5px',
                      height: '40px',
                      width: '40px',
                    }}
                  /> */}
                  <SearchContainer
                    style={{
                      justifyContent: 'end',
                      margin: openSearch ? '9px 15px' : '10px 15px 10px',
                      width: '30%',
                      alignItems: 'center',
                    }}
                    searchActive={openSearch}
                  >
                    <AutoPostSearchBar
                      setActiveSearch={setOpenSearch}
                      activeSearch={openSearch}
                      search={search}
                      setSearch={setSearch}
                      searchResult={searchResult}
                      setSearchResult={(value) => {
                        setSearchResult(value);
                        setFilteredAdminData(
                          adminData.filter((item) =>
                            `${item?.name} ${item?.email}`
                              .toLowerCase()
                              .includes(value)
                          )
                        );
                      }}
                      searchBox={searchBox}
                      setSearchBox={(value) => {
                        setSearchBox(value);
                        setFilteredAdvisorData(
                          advisorData.filter((item) =>
                            `${item?.name} ${item?.email}`
                              .toLowerCase()
                              .includes(value)
                          )
                        );
                      }}
                      toggleTabs={toggleTabs}
                      //searchHandler={handleSearch}
                    />

                    {/* <Flex
                    display="flex"
                    justifyContent="center"
                    style={{ paddingLeft: '7px' }}
                  >
                    {toggleTabs !== 'firm' && (
                      <img
                        alt=""
                        src="/assets/images/Campaign/new-filter-icon.svg"
                        style={{ height: '40px', width: '40px' }}
                      />
                    )}
                  </Flex> */}
                  </SearchContainer>
                  {/* {toggleTabs !== 'firm' && (
                    <img
                      alt=""
                      src="/assets/images/Campaign/new-filter-icon.svg"
                      style={{ height: '40px', width: '40px' }}
                    />
                  )} */}
                  {/* </Flex> */}
                </Flex>
                <Flex
                  display="flex"
                  padding="10px 5px 10px 10px"
                  flexDirection="column"
                  height="40px"
                  style={{
                    borderBottom: '1px solid #e4e7ec',
                    borderTop: '1px solid #e4e7ec',
                    justifyContent: 'center',
                    color: '#101828',
                    textAlign: 'left',
                  }}
                >
                  {targetTabs()}
                </Flex>
                <Flex
                  display="flex"
                  width="100%"
                  flexDirection="row"
                  style={{
                    backgroundColor: '#F9FAFB',
                    fontSize: '15px',
                    color: '#101828',
                    height: '60px',
                  }}
                >
                  <CheckBoxSpan
                    key={''}
                    screenWidth={window.innerWidth}
                    style={{
                      border: 'none',
                      margin: '0px 0px 4px 6px',
                      textAlign: 'left',
                      display: 'flex',
                      height: '60px',
                      alignItems: 'center',
                      width:
                        toggleTabs === 'firm'
                          ? '33.33%'
                          : toggleTabs === 'admin'
                          ? '25%'
                          : '25%',
                    }}
                  >
                    <CheckBox
                      id="selectAll"
                      value="selectAll"
                      checked={
                        toggleTabs === 'advisor'
                          ? !isEmpty(selectedAdvisorData) &&
                            selectedAdvisorData.length ===
                              eligibleAdvisorData.length
                          : toggleTabs === 'admin'
                          ? !isEmpty(selectedAdminData) &&
                            selectedAdminData.length ===
                              eligibleAdminData.length
                          : toggleTabs === 'firm'
                          ? !isEmpty(selectedFirmData) &&
                            selectedFirmData.length === eligibleFirmData.length
                          : null
                      }
                      onClick={handleSelectAll}
                    />
                    <CheckBoxLabel
                      fontSize="14px"
                      style={{
                        paddingLeft:
                          toggleTabs === 'firm'
                            ? '13.5%'
                            : toggleTabs === 'admin'
                            ? '18%'
                            : '18%',
                        //paddingTop: '5px',
                        width: '40%',
                        display: 'flex',
                        alignItems: 'center',
                      }}
                      color="#848484"
                      htmlFor="selectAll"
                    >
                      Name
                    </CheckBoxLabel>
                    <div
                      style={{
                        display: 'flex',
                        margin: '5px 0 5px',
                        flexDirection: 'row',
                        width: '60%',
                      }}
                    >
                      <span
                        style={{ margin: '0px 0 0px 8px', fontSize: '13px' }}
                      >
                        <span
                          style={{ cursor: 'pointer' }}
                          onClick={() => handleSort('name', 'asc')}
                        >
                          <FontAwesomeIcon icon={faArrowDown} />
                        </span>
                        &nbsp;
                        <span
                          style={{ cursor: 'pointer' }}
                          onClick={() => handleSort('name', 'desc')}
                        >
                          <FontAwesomeIcon icon={faArrowUp} />
                        </span>
                      </span>
                    </div>
                  </CheckBoxSpan>
                  {toggleTabs === 'firm' ? (
                    <Flex
                      width="33.33%"
                      display="flex"
                      style={{ alignItems: 'center' }}
                    >
                      Eligible Advisors
                      <NewTooltip
                        TransitionComponent={Zoom}
                        arrow
                        placement="top"
                        title={advisorEligibilityInfo()}
                      >
                        <img
                          alt=""
                          src="/assets/images/info-grey.svg"
                          style={{ marginLeft: '8px', cursor: 'pointer' }}
                        />
                      </NewTooltip>
                    </Flex>
                  ) : (
                    <Flex
                      width="35%"
                      display="flex"
                      style={{ alignItems: 'center' }}
                    >
                      Email
                      <div
                        style={{
                          display: 'flex',
                          margin: '5px 0 5px',
                          flexDirection: 'row',
                        }}
                      >
                        <span
                          style={{ margin: '0px 0 0px 12px', fontSize: '13px' }}
                        >
                          <span
                            style={{ cursor: 'pointer' }}
                            onClick={() => handleSort('email', 'asc')}
                          >
                            <FontAwesomeIcon icon={faArrowDown} />
                          </span>
                          &nbsp;
                          <span
                            style={{ cursor: 'pointer' }}
                            onClick={() => handleSort('email', 'desc')}
                          >
                            <FontAwesomeIcon icon={faArrowUp} />
                          </span>
                        </span>
                      </div>
                    </Flex>
                  )}
                  {toggleTabs === 'firm' ? (
                    <Flex
                      width="33.33%"
                      display="flex"
                      style={{ alignItems: 'center' }}
                    >
                      Eligible Administrators
                      <NewTooltip
                        TransitionComponent={Zoom}
                        arrow
                        placement="top"
                        title={adminEligibilityInfo()}
                      >
                        <img
                          alt=""
                          src="/assets/images/info-grey.svg"
                          style={{ marginLeft: '8px', cursor: 'pointer' }}
                        />
                      </NewTooltip>
                    </Flex>
                  ) : toggleTabs !== 'firm' ? (
                    <Flex
                      width="25%"
                      display="flex"
                      style={{
                        alignItems: 'center',
                        paddingLeft: toggleTabs === 'advisor' ? '4px' : '0px',
                      }}
                    >
                      Firm
                      <div
                        style={{
                          display: 'flex',
                          margin: '5px 0 5px',
                          flexDirection: 'row',
                        }}
                      >
                        <span
                          style={{ margin: '0px 0 0px 12px', fontSize: '13px' }}
                        >
                          <span
                            style={{ cursor: 'pointer' }}
                            onClick={() => {toggleTabs === 'advisor' ? handleSort('company_name', 'asc') : handleSort('firm_name', 'asc')}}
                          >
                            <FontAwesomeIcon icon={faArrowDown} />
                          </span>
                          &nbsp;
                          <span
                            style={{ cursor: 'pointer' }}
                            onClick={() => {toggleTabs === 'advisor' ? handleSort('company_name', 'desc') : handleSort('firm_name', 'desc')}}
                          >
                            <FontAwesomeIcon icon={faArrowUp} />
                          </span>
                        </span>
                      </div>
                    </Flex>
                  ) : null}
                  {toggleTabs !== 'firm' && (
                    <Flex
                      width="15%"
                      display="flex"
                      style={{
                        alignItems: 'center',
                        paddingLeft: toggleTabs !== 'firm' ? '2px' : '0px',
                      }}
                    >
                      Status
                      <div
                        style={{
                          display: 'flex',
                          margin: '5px 0 5px',
                          flexDirection: 'row',
                        }}
                      >
                        <span
                          style={{ margin: '0px 0 0px 12px', fontSize: '13px' }}
                        >
                          <span
                            style={{ cursor: 'pointer' }}
                            onClick={() => handleSort('status', 'asc')}
                          >
                            <FontAwesomeIcon icon={faArrowDown} />
                          </span>
                          &nbsp;
                          <span
                            style={{ cursor: 'pointer' }}
                            onClick={() => handleSort('status', 'desc')}
                          >
                            <FontAwesomeIcon icon={faArrowUp} />
                          </span>
                        </span>
                      </div>
                    </Flex>
                  )}
                </Flex>
                {toggleTabs === 'advisor' && AdvisorList()}
                {toggleTabs === 'admin' && AdminList()}
                {/* {toggleTabs === 'firm' && FirmList()} */}
              </Flex>
              <Flex
                display="flex"
                justifyContent="flex-start"
                width="100%"
                marginLeft="11%"
                padding="20px 5px 5px"
                style={{ color: '#667085', fontSize: '14px' }}
              >
                Note: Users must have default lists and platforms selected
              </Flex>
            </>
          )}

        {/* <Flex
          width="100%"
          display="flex"
          justifyContent="flex-start"
          flexDirection="row"
        >
          <Flex
            width="50%"
            justifyContent="flex-start"
            alignItems="center"
            style={{
              color: toggleCascadeCampaign === 'true' ? '#000000' : '#98A2B3',
              fontSize: '18px',
            }}
          >
            Cascade Campaign
          </Flex>
          <Flex
            width="50%"
            justifyContent="flex-start"
            alignItems="center"
            flexDirection="row"
          >
            <Flex display="flex" width="100%">
              <ToggleButton
                className="react-switch"
                onChange={(checked) => handleCascadeToggle(checked)}
                checked={toggleCascadeCampaign === 'true'}
                onColor="#6351ed"
                offColor="#ccc"
                offHandleColor="#fff"
                onHandleColor="#fff"
                handleDiameter={10}
                uncheckedIcon=""
                checkedIcon=""
                boxShadow=""
                activeBoxShadow=""
                type="autoPost"
                left="5px"
                margin="-3px 20px 0px 0px"
                top="10px"
              />
              <Flex
                style={{
                  padding: '3px 0px 0px',
                  fontSize: '15px',
                  color: '#000',
                }}
              >
                {toggleCascadeCampaign === 'true' ? 'On' : ' Off'}
              </Flex>
            </Flex>
          </Flex>
        </Flex> */}
        {/* <Flex
          width="100%"
          justifyContent="flex-start"
          alignItems="center"
          style={{ color: '#98A2B3', fontSize: '14px' }}
        >
          Placeholder Description
        </Flex> */}
        {/* {toggleCascadeCampaign && showMembersList && (
          <Flex
            width="80%"
            height="300px"
            marginTop="10px"
            bgColor="#fff"
            display="flex"
            flexDirection="column"
            style={{
              borderRadius: '6px',
              border: '1px solid #bccdd4',
              backgroundColor: '#FFFFFF',
            }}
          >
            <Flex
              display="flex"
              justifyContent="space-between"
              flexDirection="row"
              width="100%"
            >
              <Flex
                display="flex"
                justifyContent="center"
                flexDirection="column"
                padding="5px 0px 8px 12px"
              >
                <Flex color="#101828" fontSize="18px">
                  Select Advisors
                </Flex>
                <Flex color="#475467" fontSize="14px">
                  {`${membersList.length} Advisors`}
                </Flex>
              </Flex>
              <Flex
                display="flex"
                justifyContent="center"
                style={{ marginTop: '11px', paddingRight: '7px' }}
              >
                <img
                  alt=""
                  src="/assets/images/Campaign/new-search-icon.svg"
                  style={{ marginRight: '5px', height: '40px', width: '40px' }}
                />
                <img
                  alt=""
                  src="/assets/images/Campaign/new-filter-icon.svg"
                  style={{ height: '40px', width: '40px' }}
                />
              </Flex>
            </Flex>
            <Flex
              display="flex"
              padding="10px 5px 10px 10px"
              flexDirection="column"
              height="40px"
              style={{
                borderBottom: '1px solid #e4e7ec',
                borderTop: '1px solid #e4e7ec',
                justifyContent: 'center',
                color: '#101828',
                textAlign: 'left',
              }}
            >
              Advisors
            </Flex>
            <Flex
              width="100%"
              display="flex"
              flexDirection="row"
              height="40px"
              style={{
                borderBottom: '1px solid #e4e7ec',
                justifyContent: 'center',
                color: '#101828',
                textAlign: 'left',
                backgroundColor: '#F9FAFB',
              }}
            >
              <Flex
                width="35%"
                display="flex"
                style={{
                  textAlign: 'left',
                  alignItems: 'center',
                  padding: '5px',
                }}
              >
                <FilterRowChips
                  style={{
                    fontSize: '12px',
                    padding: '5px 7px',
                  }}
                >
                  <label
                    className="checkbox-button"
                    style={{
                      cursor: 'pointer',
                      marginLeft: '-7px',
                      marginTop: '-4px',
                    }}
                  >
                    <input
                      type="checkbox"
                      className="checkbox-button__input"
                      name="newsletters"
                    />
                    <span
                      className="checkbox-button__control"
                      style={{
                        cursor: 'pointer',
                        marginRight: '5px',
                        marginTop: '-2px',
                      }}
                    ></span>
                    <span
                      className="checkbox-button__label"
                      style={{
                        color: '#000000',
                        fontWeight: '100',
                        cursor: 'pointer',
                        fontSize: '14px',
                      }}
                    >
                      Name
                    </span>
                  </label>
                </FilterRowChips>
              </Flex>
              <Flex
                width="40%"
                display="flex"
                style={{ alignItems: 'center', padding: '8px' }}
              >
                Email
              </Flex>
              <Flex width="25%" display="flex" style={{ alignItems: 'center' }}>
                Firm Name
              </Flex>
            </Flex>
            <AdvisorListsBoxScrollWrapper height="300px" maxHeight="300px">
              <Flex
                width="100%"
                display="flex"
                flexDirection="row"
                height="40px"
                style={{
                  justifyContent: 'center',
                  color: '#101828',
                  textAlign: 'left',
                  backgroundColor: '#fff',
                }}
              >
                <Flex
                  width="35%"
                  display="flex"
                  style={{
                    textAlign: 'left',
                    alignItems: 'center',
                    padding: '5px',
                    flexDirection: 'column',
                  }}
                >
                  {!isEmpty(membersList) &&
                    membersList.map((data) => (
                      <Flex
                        width="100%"
                        display="flex"
                        flexDirection="row"
                        justifyContent="center"
                        style={{
                          textAlign: 'left',
                          alignItems: 'flex-start',
                          display: 'flex',
                          justifyContent: 'flex-start',
                        }}
                      >
                        <FilterRowChips
                          style={{
                            fontSize: '12px',
                            padding: '5px 7px 9px',
                          }}
                        >
                          <label
                            className="checkbox-button"
                            style={{
                              cursor: 'pointer',
                              marginLeft: '-7px',
                              marginTop: '-4px',
                            }}
                          >
                            <input
                              type="checkbox"
                              className="checkbox-button__input"
                              name="newsletters"
                              onChange={(e) => selectMembersHandler(e, data)}
                              checked={selectedAdvisors.includes(data.id)}
                              // value={selectSubscriptionType}
                            />
                            <span
                              className="checkbox-button__control"
                              style={{
                                cursor: 'pointer',
                                marginRight: '5px',
                                marginTop: '-2px',
                              }}
                            ></span>
                            <span
                              className="checkbox-button__label"
                              style={{
                                color: '#000000',
                                fontWeight: '100',
                                cursor: 'pointer',
                                fontSize: '14px',
                              }}
                            >
                              {`${data.details.name.first}\u00A0\u00A0${data.details.name.last}`}
                            </span>
                          </label>
                        </FilterRowChips>
                      </Flex>
                    ))}
                </Flex>
                <Flex
                  width="40%"
                  display="flex"
                  flexDirection="column"
                  style={{ alignItems: 'center', padding: '5px' }}
                >
                  {!isEmpty(membersList) &&
                    membersList.map((data) => (
                      <Flex
                        width="100%"
                        display="flex"
                        flexDirection="row"
                        justifyContent="center"
                        style={{
                          textAlign: 'left',
                          alignItems: 'center',
                          display: 'flex',
                          justifyContent: 'flex-start',
                        }}
                      >
                        <Flex
                          style={{
                            fontSize: '12px',
                            padding: '5px 7px',
                          }}
                        >
                          <span
                            className="checkbox-button__label"
                            style={{
                              color: '#000000',
                              fontWeight: '100',
                              cursor: 'pointer',
                              fontSize: '14px',
                            }}
                          >
                            {data.details.name.email}
                          </span>
                        </Flex>
                      </Flex>
                    ))}
                </Flex>
                <Flex
                  width="25%"
                  display="flex"
                  flexDirection="column"
                  style={{ alignItems: 'center' }}
                >
                  {!isEmpty(membersList) &&
                    membersList.map((data) => (
                      <Flex
                        width="100%"
                        display="flex"
                        flexDirection="row"
                        justifyContent="center"
                        style={{
                          textAlign: 'left',
                          alignItems: 'center',
                          display: 'flex',
                          justifyContent: 'flex-start',
                        }}
                      >
                        <Flex
                          style={{
                            fontSize: '12px',
                            padding: '5px 7px',
                          }}
                        >
                          <span
                            className="checkbox-button__label"
                            style={{
                              color: '#000000',
                              fontWeight: '100',
                              cursor: 'pointer',
                              fontSize: '14px',
                            }}
                          >
                
                          </span>
                        </Flex>
                      </Flex>
                    ))}
                </Flex>
              </Flex>
            </AdvisorListsBoxScrollWrapper>
          </Flex>
        )} */}
      </AutomatedCampaignFormWrapper>
      <StepsFooterV2
        loader={loading}
        isEditMode={false}
        currentStepIndex={step}
        setCurrentStepIndex={setStep}
        handleNext={() =>
          handleNextClick({ forSaveAndClose: false, forSaveAsDraft: false })
        }
        disableNext={false}
        endCreateFlow={endCreateFlow}
        campaignSetState={campaignSetState}
        campaignFlow
      />
    </Box>
  ) : (
    <AutomatedCampaignFormWrapper>
      <Loader />
    </AutomatedCampaignFormWrapper>
  );
};

const mapStateToProps = (state) => {
  const isAdmin = getUserRole(state) === 'admin';
  return {
    membersList: state.user.membersList && [
      // {
      //   id: 'selectAll',
      //   details: {
      //     name: { email: 'Select All', first: 'Select', last: 'All' },
      //   },
      // },
      ...state.user.membersList,
    ],
    emailData: get(state, 'user.data.details.user.email_personalization', null),
    isAdmin: isAdmin,
    campaignsDetailsData: state.campaignDetails.data,
    userData: state.user.data,
    campaignsList: state.campaign.data.campaigns,
    subscriptionType: state?.campaign?.subscriptionType,
    userId: state?.user.data.details.user.company,
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(SetObjectivesV2);
