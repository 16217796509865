import { Flex } from '@rebass/grid';
import styled from 'styled-components';
import { themesData } from '../../../utils';

export const ArticlesContainer = styled(Flex)`
  height: 348px;
  max-height: 348px;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0px 15px;
  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background: #e9f6e6 !important;
  }

  &::-webkit-scrollbar-thumb {
    background: #bccdd4 !important;
  }
`;

export const SelectedContentContainer = styled(Flex)`
  overflow-x: hidden;
  overflow-y: auto;
  height: 385px;
  margin-top: 10px;
  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background: #e9f6e6 !important;
  }

  &::-webkit-scrollbar-thumb {
    background: #bccdd4 !important;
  }
`;

export const SelectedContentTopic = styled.div`
  font-size: 0.9rem;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

export const LandingPageCardWrapper = styled.div.attrs({
  className: 'template-card-wrapper'
})`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    padding: 15px 0px;
      &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background: #e9f6e6 !important;
  }

  &::-webkit-scrollbar-thumb {
    background: #bccdd4 !important;
  }
`;

export const LandingPageCard = styled.div.attrs({
  className: 'filtered-template-card',
})`
  display: flex;
  flex-direction: column;
  height: 275px;
  width: 30%;
  margin: 10px;
  background-color: ${themesData.white};
  border-radius: 8px;
  border: ${({ borderColor }) => `1px solid ${borderColor || themesData.greyWhite}`};
`;

export const LandingPageTooltip = styled.div.attrs({
  className: 'tooltip',
})`
  position: relative;
  display: inline-block;

  & .tooltiptext {
    visibility: hidden;
    width: 232px;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    border: 1px solid #4e6780;
    font-size: 10px;
    font-weight: 600;
    /* Position the tooltip */
    position: absolute;
    z-index: 1;
    bottom: 150%;
    left: 26%;
    margin-left: -60px;
    ${'' /* margin-top: 5px; */}
  }

  & .tooltiptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: black transparent transparent transparent;
  }

  &:hover .tooltiptext {
    visibility: visible;
  }
`;