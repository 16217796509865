import React, { useEffect, useState } from 'react';
import { get, isEmpty } from 'lodash';
import { Flex, Box } from '@rebass/grid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import {
  Modal,
  ModalHeader,
  ModalCloseButton,
  TableTitle,
  TableBody,
  Tr,
  Td,
  Input,
  ModalBody,
  LoaderWrapper,
  ButtonNoBorderBackground,
  Button,
} from '../';
import {
  getFundForInvestment,
  postPortfolioFund,
  putInvetmentsTabList,
} from '../../services/dashboard';
import {
  EditPortfolioTable,
  TickerErrorMessage,
  DeleteTickerMessage,
} from './EditPortfolioModal.styles';
import { themesData } from '../../utils';

const EditPortfolioModal = ({
  setModalOpen,
  isModalOpen,
  invetmentDetails,
  onInvestmentClick,
  exploreSetState,
}) => {
  const [newTikcer, setNewTicker] = useState('');
  const [newTikcerList, setNewTickerList] = useState([]);
  const [isInvestmentFundFetching, setIsInvestmentFundFetching] = useState(
    true
  );
  const [investmentList, setInvestmentFundList] = useState([]);
  const [ticklistToDelete, setTicklistToDelete] = useState([]);
  const [tickerError, setTickerError] = useState(false);
  const [message, setMessage] = useState(false);
  const { id, name } = invetmentDetails;

  useEffect(() => {
    if (!isEmpty(invetmentDetails)) {
      getFundForInvestment(id)
        .then((response) => {
          setIsInvestmentFundFetching(false);
          setInvestmentFundList(get(response, `result.data.${id}`, []));
        })
        .catch((err) => {
          setIsInvestmentFundFetching(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invetmentDetails]);

  const investmentDelete = (ticker) => {
    setMessage(false);

    if ([...investmentList, ...newTikcerList].length > 1) {
      setTicklistToDelete([...ticklistToDelete, ticker]);
      setInvestmentFundList(
        investmentList.filter((investment) => investment.ticker !== ticker)
      );
      setNewTickerList(
        newTikcerList.filter((investment) => investment.ticker !== ticker)
      );
    } else {
      setMessage(true);
      setTimeout(() => {
        setMessage(false);
      }, 2500);
    }
  };

  const onAddTickerClick = () => {
    if (
      [...investmentList, ...newTikcerList].some(
        (s) => s.ticker.toUpperCase() === newTikcer.toUpperCase()
      )
    ) {
      setTickerError(true);
    } else if (newTikcer.length === 0) {
      setTickerError(true);
    } else {
      setTickerError(false);
      const newTickerObject = {
        ticker: newTikcer.toUpperCase(),
      };
      setNewTickerList([...newTikcerList, newTickerObject]);
      setNewTicker('');
    }
  };

  const onSaveButtonClick = () => {
    setModalOpen(false);
    const addFund = postPortfolioFund(id, {
      funds: newTikcerList.map((item) => {
        return [item.ticker, ''];
      }),
    });
    let deleteFund;

    if ([...investmentList, ...newTikcerList].length >= 1) {
      deleteFund = putInvetmentsTabList({
        id,
        tickers: ticklistToDelete,
      });
    }
    let selectedTickers = [];
    [...investmentList, ...newTikcerList].forEach(data => selectedTickers.push(data.ticker.toUpperCase()));
    if(exploreSetState){
      exploreSetState({selectedTickers, holdingsType: 'themes'})
    }
    Promise.all([addFund, deleteFund]).then(() => {
      onInvestmentClick(id);
    });
  };

  return (
    <Modal
      onRequestClose={() => setModalOpen(false)}
      shouldCloseOnOverlayClick
      isOpen={isModalOpen}
      size="medium"
    >
      <ModalHeader>Edit Portfolio</ModalHeader>
      <ModalCloseButton onClick={() => setModalOpen(false)} />
      <ModalBody>
        <LoaderWrapper isLoading={isInvestmentFundFetching}>
          <Flex width={1} flexDirection="column">
            <Flex width={1}>
              <EditPortfolioTable>
                <TableTitle style={{color: themesData.purpleishBlue, fontFamily: 'Poppins'}}>{name}</TableTitle>
                <TableBody>
                  <Tr alignItems="center">
                    <Td align="left">
                      <Input
                        color={themesData.purpleishBlue}
                        placeholder="Add ticker"
                        onChange={(e) => setNewTicker(e.target.value)}
                        value={newTikcer}
                      />
                    </Td>
                    {tickerError ? (
                      <Td align="left">
                        <TickerErrorMessage>
                          Duplicate Ticker or Empty Ticker
                        </TickerErrorMessage>
                      </Td>
                    ) : null}
                    <Td width="10%" align="right">
                      <ButtonNoBorderBackground onClick={onAddTickerClick}>
                        <FontAwesomeIcon icon={faPlus} />
                      </ButtonNoBorderBackground>
                    </Td>
                  </Tr>
                  {[...investmentList, ...newTikcerList].map((item) => (
                    <Tr>
                      <Td align="left" style={{color: themesData.purpleishBlue, fontFamily: 'Poppins'}}>{item.ticker}</Td>
                      <Td width="10%" align="right">
                        <ButtonNoBorderBackground
                          onClick={() => investmentDelete(item.ticker)}
                        >
                          <img src='/assets/images/delete.svg'/>
                        </ButtonNoBorderBackground>
                      </Td>
                    </Tr>
                  ))}
                </TableBody>
              </EditPortfolioTable>
            </Flex>
            <Box>
              <Button onClick={onSaveButtonClick} styles={{background: themesData.purpleishBlue, color: themesData.white, borderRadius: '0', fontFamily: 'Poppins'}} >Save and Close</Button>
            </Box>
            {message ? (
              <DeleteTickerMessage>
                Atleast 1 Fund is required in Portfolio
              </DeleteTickerMessage>
            ) : null}
          </Flex>
        </LoaderWrapper>
      </ModalBody>
    </Modal>
  );
};

export default EditPortfolioModal;
