import React, { useEffect, useState } from 'react';
import { isEmpty, uniqBy } from 'lodash';
import UserDropdownAnalytics from 'src/containers/AnalyticsContainer/UserDropdownAnalytics/UserDropdownAnalytics';
import { groupBy } from 'src/utils';
import useRESTQuery from 'src/hooks/useRESTQuery';
import { Grid } from '@mui/material';

function UserDropdown({
  enterprise,
  setEnterprise,
  selectedUsers,
  setSelectedUsers,
}) {
  const [showEnterpriseDropdown, setShowEnterpriseDropdown] = useState(false);
  const [
    showEnterpriseDropdownSelectedOption,
    setShowEnterpriseDropdownSelectedOption,
  ] = useState('My Activity');
  const [userList, setUserList] = useState([]);
  const [selectedDropdownFirms, setSelectedDropdownFirms] = useState([]);
  const [selectedAdvisors, setSelectedAdvisors] = useState([]);
  const [advisorList, setAdvisorList] = useState([]);
  const [searchString, setSearchString] = useState('');

  const { data } = useRESTQuery({
    key: 'company-users',
    dependencies: [],
    endpoint: `/analytics/company/users`,
  });

  useEffect(() => {
    if (data) {
      const users = Array.isArray(data?.result)
        ? data?.result?.flatMap((user) => {
            const usersOfCurrentUser = user?.users?.map((u) => ({
              ...u,
              firm_name: user.name,
              firm_id: user.id,
            }));
            return usersOfCurrentUser;
          })
        : [];

      setAdvisorList(users);
      setUserList(groupBy(users, 'firm_name'));
    }
  }, [data]);

  const searchHandler = () => {
    if (!isEmpty(searchString)) {
      let searchedAdvisors = advisorList.filter((f) =>
        f.name.toLowerCase().includes(searchString.toLowerCase())
      );
      console.log(searchedAdvisors);
      setUserList(groupBy(searchedAdvisors, 'firm_name'));
    } else {
      setUserList(groupBy(advisorList, 'firm_name'));
    }
  };

  const handleEnterpriseDropdownChange = (type) => {
    if (type === 'My Activity') {
      setShowEnterpriseDropdown(false);
      setShowEnterpriseDropdownSelectedOption(type);
      setEnterprise(false);
      setSelectedUsers([]);
      setSelectedAdvisors([]);
    } else {
      setEnterprise(true);
    }
  };

  const handleSelectAll = () => {
    if (selectedAdvisors.length < advisorList.length) {
      setSelectedAdvisors(advisorList);
    } else {
      setSelectedAdvisors([]);
    }
  };

  const handleAdvisorSelection = (item, firmName = null, topLevel = false) => {
    if (!topLevel) {
      if (isEmpty(selectedAdvisors)) {
        setSelectedAdvisors([item]);
      } else if (isEmpty(selectedAdvisors.filter((s) => s.id === item.id))) {
        setSelectedAdvisors([...selectedAdvisors, item]);
      } else {
        let removeItems = selectedAdvisors.filter((s) => s.id !== item.id);
        setSelectedAdvisors(removeItems);
      }
    } else {
      let selectedFirmUsers = userList[item];
      console.log(selectedFirmUsers);
      if (
        selectedFirmUsers.length ===
        selectedAdvisors.filter((s) => s.firm_name === item).length
      ) {
        let removeItems = selectedAdvisors.filter((s) => s.firm_name !== item);
        setSelectedAdvisors(removeItems);
      } else {
        setSelectedAdvisors(
          uniqBy([...selectedFirmUsers, ...selectedAdvisors], 'id')
        );
      }
    }
  };

  const handleExpand = (item) => {
    if (isEmpty(selectedDropdownFirms)) {
      setSelectedDropdownFirms([item]);
    } else if (isEmpty(selectedDropdownFirms.filter((s) => s === item))) {
      setSelectedDropdownFirms([...selectedDropdownFirms, item]);
    } else {
      let removeItems = selectedDropdownFirms.filter((s) => s !== item);
      setSelectedDropdownFirms(removeItems);
    }
  };

  const handleApply = () => {
    if (selectedAdvisors.length === advisorList.length) {
      setShowEnterpriseDropdownSelectedOption('My Enterprise');
      setSelectedUsers([]);
    } else {
      setSelectedUsers(selectedAdvisors.map((s) => s.id));
      setShowEnterpriseDropdownSelectedOption('');
    }
    setShowEnterpriseDropdown(false);
    setSelectedDropdownFirms([]);
  };

  return (
    <Grid item>
      <UserDropdownAnalytics
        searchHandler={searchHandler}
        searchString={searchString}
        selectedAdvisors={selectedAdvisors}
        selectedDropdownFirms={selectedDropdownFirms}
        selectedUsers={selectedUsers}
        setSearchString={setSearchString}
        setShowEnterpriseDropdown={setShowEnterpriseDropdown}
        showEnterpriseDropdown={showEnterpriseDropdown}
        showEnterpriseDropdownSelectedOption={
          showEnterpriseDropdownSelectedOption
        }
        handleEnterpriseDropdownChange={handleEnterpriseDropdownChange}
        enterprise={enterprise}
        userList={userList}
        handleSelectAll={handleSelectAll}
        advisorList={advisorList}
        handleAdvisorSelection={handleAdvisorSelection}
        handleExpand={handleExpand}
        handleApply={handleApply}
      />
    </Grid>
  );
}

export default UserDropdown;
