import React from 'react';
import $ from 'jquery';
import './jquery.flipster';
import { StyledFlipster } from './Flipster.styles';

export default class Flipster extends React.PureComponent {
  constructor(props) {
    super(props);
    this.elRef = React.createRef();
    this.flipsterEl = null;
  }

  componentDidUpdate(prevProps) {
    const { children, jump } = this.props;
    if (children !== prevProps.children) {
      this.flipster.flipster({
        style: 'carousel',
        start: 'center',
        spacing: -0.3,
        fadeIn: 0
      });
    }
    if (children && jump !== prevProps.jump && jump) {
      const jumpMethod = this.flipster.data('methods').jump;
      jumpMethod(jump);
    }
  }

  componentDidMount() {
    const el$ = $(this.elRef.current);
    this.flipster = el$;

    // setTimeout(() => {
    //   const el$ = $(this.elRef.current);
    //   console.log("this.flipster : ", this.elRef.current);
    //   this.flipster = el$;
    //   console.log("this.flipster : ", this.flipster);
    // }, 0);
  }

  render() {
    const { children, type, transition, isFromMyClients } = this.props;
    //console.log('Flipster', transition, type, transition, isFromMyClients);
    //console.log("this.elRef : ", this.elRef);
    return (
      <StyledFlipster type={type} isFromMyClients={isFromMyClients} checktransition={transition}>
        <div ref={this.elRef}>
          <ul className="flip-items most_subscribed_topics">{children}</ul>
        </div>
      </StyledFlipster>
    );
  }
}
