import { Flex } from '@rebass/grid';
import React,{useEffect} from 'react';
import axios from 'axios';
import { Button } from '../../Form';
import { Modal, ModalBody, ModalCloseButton, ModalHeader } from '../../Modal';
import {
  SortTable,
  TableHeader,
  ThSmall,
  TableData,
  TableRow,
} from '../SortTable';
import { HeaderSpan, TableWrapper } from '../SortTable.styles';
import { isEmpty, capitalize, get } from 'lodash';
import moment from 'moment';
import PreviewModal from '../../../containers/DripCampaignContainerV2/Preview&ViewInfoModal/PreviewModal';
import { useState } from 'react';
import { diffHtmlEndPoints, objectToFormData } from '../../../services/helpers';
import { LoaderWrapper } from '../../Loader';
import { ContactListModal } from './ContactListModal';

export const HistoryModal = ({
  historyDetails,
  handleModalClose,
  platformMapper,
}) => {
  const [curCampaignPreview, setCurCampaignPreview] = useState({});
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);
  const [diffHtml, setDiffHtml] = useState('');
  const [loading, setLoading] = useState(false);
  const [isContactListOpen, setIsContactListOpen] = useState(false);
  const [selectedTrailData, setSelectedTrailData] = useState({});
  const [page, setPage] = useState(0);
  const [contactsLoading, setContactsLoading] = useState(false);

  let toggleImg = {
    expand: '/assets/images/expand_more_black.svg',
    collapse: '/assets/images/expand_less_black.svg',
  };

  const generateDiffPayload = (item, index) => {
    const lastIndexData = historyDetails && historyDetails.trail && historyDetails.trail.data && historyDetails.trail.data && historyDetails.trail.data[index - 1];
    if(capitalize(item.action) === 'Submitted' && capitalize(item.platform) === 'Email' && lastIndexData && lastIndexData.action === 'rejected'){
        const payload = {
            media1: new File([lastIndexData.campaign_data.template_html], 'template2.html'),
            media2: new File([item.campaign_data.template_html], 'template1.html'),
            diff_color: 'yellow',
        }
        setLoading(true);
        axios.post(`${diffHtmlEndPoints.baseUrl}/html-diff`, objectToFormData(payload))
        .then((response) => {
            const res = response.data;
            setDiffHtml(res.data);
            setCurCampaignPreview(
                get(item, 'campaign_data', {})
              );
              setIsPreviewOpen(true);
              setLoading(false);
          })
          .catch((error) => {
            console.log(error);
            setLoading(false);
          });
    }
    else{
        setCurCampaignPreview(
            get(item, 'campaign_data', {})
          );
          setIsPreviewOpen(true);
    }
  }

  return (
    <Modal
      // onRequestClose={handleModalClose}
      shouldCloseOnOverlayClick
      isOpen
      size="medium"
      bgColor="white"
      style={{ maxHeight: '95%' }}
    >
      <ModalHeader style={{ paddingBottom: '0px' }}>
        <Flex flexDirection="column">
          <Flex>
            <span
              style={{
                color: '#000',
                fontSize: '13px',
              }}
              data-testid="compliance-history-header"
            >
              Compliance Audit Trail History For
            </span>
          </Flex>
          <ModalCloseButton onClick={handleModalClose} />
        </Flex>
        <hr style={{ width: '100%', marginBottom: '0px' }} />
      </ModalHeader>

      <ModalBody style={{ paddingBottom: '15px' }} padding="0px 15px">
        <Flex display="flex" flexDirection="column">
          <Flex style={{ height: '35px' }}>
            <h4
              style={{
                fontWeight: '500',
                marginTop: '10px',
                color: 'black',
                fontFamily: 'Poppins',
              }}
              data-testid="compliance-history-title"
            >
              {historyDetails?.insight}
            </h4>
          </Flex>
          <Flex>
            <span
              style={{ color: '#000', marginBottom: '10px' }}
              data-testid="compliance-history-platform"
            >
              {platformMapper[historyDetails?.channel]}
            </span>
          </Flex>
        </Flex>
        <LoaderWrapper isLoading={loading}>
        <TableWrapper>
          <SortTable style={{ backgroundColor: '#cfd9e4' }}>
            <TableHeader
              style={{ borderRadius: '30px', backgroundColor: '#cfd9e4' }}
            >
              <tr data-testid="compliance-history-table-header">
                <ThSmall width="4.33%">
                  <HeaderSpan style={{ color: '#000' }}>Action</HeaderSpan>
                </ThSmall>
                <ThSmall width="4.33%">
                  <HeaderSpan style={{ color: '#000' }}>By</HeaderSpan>
                </ThSmall>
                <ThSmall width="4.33%">
                  <HeaderSpan style={{ color: '#000' }}>Date/Time</HeaderSpan>
                </ThSmall>
                {platformMapper[historyDetails?.channel] === 'Email' && (
                  <ThSmall width="4.33%">
                    <HeaderSpan style={{ color: '#000' }}>Contacts</HeaderSpan>
                  </ThSmall>
                )}
                <ThSmall width="5%">
                  <HeaderSpan style={{ color: '#000' }}>Feedback</HeaderSpan>
                </ThSmall>
                <ThSmall width="5%">
                  <HeaderSpan style={{ color: '#000' }} />
                </ThSmall>
              </tr>
            </TableHeader>
            <tbody>
              {historyDetails?.trail?.isFetching && (
                <TableRow>
                  <TableData colSpan="5">
                    <span>Loading...</span>
                  </TableData>
                </TableRow>
              )}
              {!historyDetails?.trail?.isFetching &&
                isEmpty(historyDetails?.trail?.data) && (
                  <TableRow>
                    <TableData colSpan="5">
                      <span>No records found</span>
                    </TableData>
                  </TableRow>
                )}
              {!isEmpty(historyDetails?.trail?.data) &&
                historyDetails?.trail?.data.map((item, index) => {
                  return (
                    <>
                      <TableRow>
                        <TableData width="15%" style={{ color: '#000' }}>
                          {capitalize(item.action)}
                        </TableData>
                        <TableData width="20%" style={{ color: '#000' }}>
                          <div> {item.action_by}</div>
                          <div style={{ whiteSpace: 'nowrap' }}>
                            {item.super_admin
                              ? `(${item.super_admin})`
                              : item.impersonator
                              ? `(${item.impersonator})`
                              : item.email
                              ? `(${item.email})`
                              : ''}
                          </div>
                        </TableData>
                        <TableData width="20%" style={{ color: '#000' }}>
                          {moment(item.created_time).format(
                            'DD-MMM-YYYY, HH:mm'
                          )}
                        </TableData>
                        {platformMapper[historyDetails?.channel] === 'Email' && (
                          <TableData
                            width='15%'
                            style={{
                              color: '#000',
                              textAlign: 'center',
                              textDecoration: 'underline',
                              padding: '5px 10px',
                            }}
                          >
                            {item.email && (
                              <span
                                style={{
                                  cursor: 'pointer',
                                }}
                                onClick={() => {
                                  setSelectedTrailData(item);
                                  setIsContactListOpen(true);
                                }}
                              >
                                View List
                              </span>
                            )}
                          </TableData>
                        )}
                        <TableData
                          width="50%"
                          style={{ padding: '3px 10px', color: '#000' }}
                        >
                          {item.feedback && item.feedback.length > 50
                            ? item.feedback.slice(0, 50) + ' .......'
                            : item.feedback}
                        </TableData>
                        <TableData
                          colSpan="2"
                          style={{
                            color: '#000',
                            textAlign: 'left',
                            textDecoration: 'underline',
                            padding: '5px 10px',
                          }}
                        >
                          {item.campaign_data && (
                            <span
                              style={{
                                cursor: 'pointer',
                              }}
                              onClick={() => {
                                generateDiffPayload(item, index);

                              }}
                            >
                              Preview
                            </span>
                          )}
                        </TableData>
                        {item.feedback && item.feedback.length > 50 ? (
                          <TableData
                            style={{ color: '#000' }}
                            onClick={() => {
                              if (
                                document.getElementById(index).style.display ===
                                'none'
                              ) {
                                document.getElementById(index).style.display =
                                  '';
                                document
                                  .getElementById('chev-' + index)
                                  .setAttribute('src', toggleImg.collapse);
                              } else {
                                document.getElementById(index).style.display =
                                  'none';
                                document
                                  .getElementById('chev-' + index)
                                  .setAttribute('src', toggleImg.expand);
                              }
                            }}
                          >
                            <img id={'chev-' + index} src={toggleImg.expand} alt=''/>
                          </TableData>
                        ) : (
                          <TableData />
                        )}
                      </TableRow>
                      <TableRow />
                      <TableRow id={index} style={{ display: 'none' }}>
                        <TableData colSpan="3" />
                        <TableData
                          colSpan="2"
                          style={{
                            color: '#000',
                            textAlign: 'left',
                            padding: '5px 0px',
                          }}
                        >
                          <span>{item.feedback}</span>
                        </TableData>
                        <TableData colSpan="3" />
                      </TableRow>
                    </>
                  );
                })}
            </tbody>
            <tfoot style={{ borderCollapse: 'inherit' }}></tfoot>
          </SortTable>
        </TableWrapper>
        </LoaderWrapper>
        <Flex width="100%" justifyContent="flex-end">
          <Button
            onClick={handleModalClose}
            bgColor={'#4e6780'}
            borderRadius="6px"
            borderColor={'#4e6780'}
            fontSize="10px"
          >
            Close
          </Button>
        </Flex>
      </ModalBody>
      {isContactListOpen && (
        <ContactListModal
          isOpen={isContactListOpen}
          handleContactListClose={() => {
            setIsContactListOpen(false);
            setSelectedTrailData({});
          }}
          selectedTrailData={selectedTrailData}
          page={page}
          setPage={setPage}
        />
      )}
      {isPreviewOpen && (
        <PreviewModal
          isCompliance={true}
          complianceCampaignData={curCampaignPreview}
          isOpen={isPreviewOpen}
          diffHtml={diffHtml}
          handleClose={() => {
            setIsPreviewOpen(false)
            setDiffHtml('')
          } 
          }
          compliancePlatform={get(historyDetails, 'channel', '')}
        />
      )}
    </Modal>
  );
};
