/* eslint-disable react-hooks/exhaustive-deps */
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Flex } from '@rebass/grid';
import { get, isEmpty, map } from 'lodash';
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { MioRadio, MioRadioSpan } from '../../../components';
import { createCampaign, updateCampaign } from '../../../services/campaign';
import { NewsletterCheckBoxDiv } from '../../NewsletterContainer/Recipients/Recipients.styles';
import CampaignFooterButtons from '../CampaignFooterButtons/CampaignFooterButtons';
import { FilterRowChips } from '../DripDashboardFilter/DripDashboardFilterPopup/DripDashboardFilterPopup.styles';
import {
  RecipientsListBody,
  RecipientsListWrapper,
  SetObjectivesInput,
  SetObjectivesWrapper,
} from './SetObjectives.styles';

const SetObjectives = ({
  contactGroups,
  setCreateFlow,
  setCreateTabs,
  socialNetworkData,
  campaignSetState,
  campaignCreateFlow,
  setObjectivesTabData,
  getAccountId,
  campaignId,
  endCreateFlow,
}) => {
  const history = useHistory();
  const [target, setTarget] = useState('prospects');
  const [selectedEmailContacts, setSelectedEmailContacts] = useState([]);
  const [selectedFbContacts, setSelectedFbContacts] = useState([]);
  const [selectedLnContacts, setSelectedLnContacts] = useState([]);
  const [selectedTwContacts, setSelectedTwContacts] = useState([]);
  const [campaignName, setCampaignName] = useState('');
  const [purpose, setPurpose] = useState('');
  const [loading, setLoading] = useState(false);
  const [ascEmailOrder, setAscEmailOrder] = useState(false);
  const [ascFbOrder, setAscFbOrder] = useState(false);
  const [ascLnOrder, setAscLnFbOrder] = useState(false);

  useEffect(() => {
    if (campaignCreateFlow && !isEmpty(setObjectivesTabData)) {
      setTarget(get(setObjectivesTabData, 'target', 'prospects'));
      setSelectedEmailContacts(get(setObjectivesTabData, 'emList', []));
      setSelectedFbContacts(get(setObjectivesTabData, 'fbList', []));
      setSelectedLnContacts(get(setObjectivesTabData, 'lnList', []));
      setSelectedTwContacts(get(setObjectivesTabData, 'twList', []));
      setCampaignName(get(setObjectivesTabData, 'name', ''));
      setPurpose(get(setObjectivesTabData, 'purpose', ''));
    }
  }, []);

  const handleCheckboxClick = (item, type) => {
    if (type === 'em') {
      if (isEmpty(selectedEmailContacts)) {
        setSelectedEmailContacts([...selectedEmailContacts, item]);
      } else if (
        !isEmpty(selectedEmailContacts) &&
        isEmpty(selectedEmailContacts.filter((s) => s.id === item.id))
      ) {
        setSelectedEmailContacts([...selectedEmailContacts, item]);
      } else {
        let removeItems = selectedEmailContacts.filter((s) => s.id !== item.id);
        setSelectedEmailContacts(removeItems);
      }
    } else if (type === 'ln') {
      if (isEmpty(selectedLnContacts)) {
        setSelectedLnContacts([...selectedLnContacts, item]);
      } else if (
        !isEmpty(selectedLnContacts) &&
        isEmpty(selectedLnContacts.filter((s) => s === item))
      ) {
        setSelectedLnContacts([...selectedLnContacts, item]);
      } else {
        let removeItems = selectedLnContacts.filter((s) => s !== item);
        setSelectedLnContacts(removeItems);
      }
    } else if (type === 'tw') {
      if (isEmpty(selectedTwContacts)) {
        setSelectedTwContacts([...selectedTwContacts, item]);
      } else if (
        !isEmpty(selectedTwContacts) &&
        isEmpty(selectedTwContacts.filter((s) => s === item))
      ) {
        setSelectedTwContacts([...selectedTwContacts, item]);
      } else {
        let removeItems = selectedTwContacts.filter((s) => s !== item);
        setSelectedTwContacts(removeItems);
      }
    } else {
      if (isEmpty(selectedFbContacts)) {
        setSelectedFbContacts([...selectedFbContacts, item]);
      } else if (
        !isEmpty(selectedFbContacts) &&
        isEmpty(selectedFbContacts.filter((s) => s === item))
      ) {
        setSelectedFbContacts([...selectedFbContacts, item]);
      } else {
        let removeItems = selectedFbContacts.filter((s) => s !== item);
        setSelectedFbContacts(removeItems);
      }
    }
  };

  const handleNextClick = () => {
    setLoading(true);
    let emLists = [],
      fbLists = [],
      twLists = [],
      lnLists = [];
    emLists = !isEmpty(selectedEmailContacts)
      ? selectedEmailContacts.map((item, index) => {
          return {
            destination_id: item.id,
            snetwork_act_id: getAccountId('em'),
          };
        })
      : [];

    twLists = !isEmpty(selectedTwContacts)
      ? selectedTwContacts.map((item, index) => {
          return {
            destination_id: item,
            snetwork_act_id: getAccountId('tw'),
          };
        })
      : [];

    fbLists = !isEmpty(selectedFbContacts)
      ? selectedFbContacts.map((item, index) => {
          return {
            destination_id: item,
            snetwork_act_id: getAccountId('fb'),
          };
        })
      : [];

    lnLists = !isEmpty(selectedLnContacts)
      ? selectedLnContacts.map((item, index) => {
          return {
            destination_id: item,
            snetwork_act_id: getAccountId('ln', item),
          };
        })
      : [];

    let destination = {
      email: emLists,
      facebook: fbLists,
      twitter: twLists,
      linkedin: lnLists,
    };
    let payload = {
      name: campaignName,
      purpose: purpose,
      target: target,
      destination: JSON.stringify(destination),
    };

    if (isEmpty(campaignId)) {
      createCampaign(payload)
        .then((res) => {
          if (res.result.success) {
            campaignSetState({
              createFlow: true,
              campaignId: get(res.result.data, 'campaign_id', ''),
              setObjectives: {
                name: campaignName,
                purpose: purpose,
                target: target,
                emList: selectedEmailContacts,
                fbList: selectedFbContacts,
                lnList: selectedLnContacts,
                twList: selectedTwContacts,
              },
            });
            setLoading(false);
            setCreateTabs(1);
          } else {
            setLoading(false);
          }
        })
        .catch((err) => {
          setLoading(false);
        });
    } else {
      updateCampaign({ id: campaignId, payload })
        .then((res) => {
          if (res.result.success) {
            campaignSetState({
              createFlow: true,
              campaignId: campaignId,
              setObjectives: {
                name: campaignName,
                purpose: purpose,
                target: target,
                emList: selectedEmailContacts,
                fbList: selectedFbContacts,
                lnList: selectedLnContacts,
                twList: selectedTwContacts,
              },
            });
            setLoading(false);
            setCreateTabs(1);
          } else {
            setLoading(false);
          }
        })
        .catch((err) => {
          setLoading(false);
        });
    }
  };

  const handleBackButtonClick = () => {
    endCreateFlow();
  };

  const handleValidation = () => {
    let isNotValid = true;
    let isNameNotValid = true,
      isListNotValid = true,
      isPurposeNotValid = true;

    if (!isEmpty(campaignName)) {
      isNameNotValid = false;
    } else {
      isNameNotValid = true;
    }

    if (
      !isEmpty(selectedEmailContacts) ||
      !isEmpty(selectedFbContacts) ||
      !isEmpty(selectedLnContacts) ||
      !isEmpty(selectedTwContacts)
    ) {
      isListNotValid = false;
    } else {
      isListNotValid = true;
    }

    if (!isEmpty(purpose)) {
      isPurposeNotValid = false;
    } else {
      isPurposeNotValid = true;
    }

    if (!isNameNotValid && !isListNotValid && !isPurposeNotValid) {
      isNotValid = false;
    }

    return isNotValid;
  };

  const handleSelectAll = (platform) => {
    if (platform === 'em' && !isEmpty(get(socialNetworkData, 'em', []))) {
      if (selectedEmailContacts.length === contactGroups.length) {
        setSelectedEmailContacts([]);
      } else {
        setSelectedEmailContacts(contactGroups);
      }
    }

    if (platform === 'fb' && !isEmpty(get(socialNetworkData, 'fb', []))) {
      if (
        Object.keys(get(socialNetworkData, 'fb[0].page', [])).length + 1 ===
        selectedFbContacts.length
      ) {
        setSelectedFbContacts([]);
      } else {
        setSelectedFbContacts([
          ...Object.keys(get(socialNetworkData, 'fb[0].page', [])),
        ]);
      }
    }

    if (platform === 'ln' && !isEmpty(get(socialNetworkData, 'ln', []))) {
      console.log(Object.keys(get(socialNetworkData, 'ln[0].page', [])));
      if (
        Object.keys(get(socialNetworkData, 'ln[0].page', [])).length + 1 ===
        selectedLnContacts.length
      ) {
        setSelectedLnContacts([]);
      } else {
        setSelectedLnContacts([
          0,
          ...Object.keys(get(socialNetworkData, 'ln[0].page', [])),
        ]);
      }
    }
  };

  const handleAlphabeticalSort = (platform) => {
    switch (platform) {
      case 'em':
        console.log('NEW STATE - - -', !ascEmailOrder);
        setAscEmailOrder(!ascEmailOrder);
        break;

      case 'fb':
        console.log('NEW STATE - - -', !ascFbOrder);
        setAscFbOrder(!ascFbOrder);
        break;

      case 'ln':
        console.log('NEW STATE - - -', !ascLnOrder);
        setAscLnFbOrder(!ascLnOrder);
        break;

      default:
        break;
    }

    console.log();
  };

  const handleSaveAndClose = () => {
    setLoading(true);
    let emLists = [],
      fbLists = [],
      twLists = [],
      lnLists = [];
    emLists = !isEmpty(selectedEmailContacts)
      ? selectedEmailContacts.map((item, index) => {
          return {
            destination_id: item.id,
            snetwork_act_id: getAccountId('em'),
          };
        })
      : [];

    twLists = !isEmpty(selectedTwContacts)
      ? selectedTwContacts.map((item, index) => {
          return {
            destination_id: item === 0 ? null : item,
            snetwork_act_id: getAccountId('tw'),
          };
        })
      : [];

    fbLists = !isEmpty(selectedFbContacts)
      ? selectedFbContacts.map((item, index) => {
          return {
            destination_id: item === 0 ? null : item,
            snetwork_act_id: getAccountId('fb'),
          };
        })
      : [];

    lnLists = !isEmpty(selectedLnContacts)
      ? selectedLnContacts.map((item, index) => {
          return {
            destination_id: item === 0 ? null : item,
            snetwork_act_id: getAccountId('ln', item),
          };
        })
      : [];

    let destination = {
      email: emLists,
      facebook: fbLists,
      twitter: twLists,
      linkedin: lnLists,
    };
    let payload = {
      name: campaignName,
      purpose: purpose,
      target: target,
      destination: JSON.stringify(destination),
    };

    if (isEmpty(campaignId)) {
      createCampaign(payload)
        .then((res) => {
          if (res.result.success) {
            setLoading(false);
            endCreateFlow();
          } else {
            setLoading(false);
          }
        })
        .catch((err) => {
          setLoading(false);
        });
    } else {
      updateCampaign({ id: campaignId, payload })
        .then((res) => {
          if (res.result.success) {
            setLoading(false);
            endCreateFlow();
          } else {
            setLoading(false);
          }
        })
        .catch((err) => {
          setLoading(false);
        });
    }
  };

  const redirectToConfigure = (platform) => {
    // TODO: when admins will have campaign option
    history.push('/configure');
    window.sessionStorage.setItem('selectedIndex', 1);
    endCreateFlow();
  };

  return (
    <div style={{ height: '83%' }}>
      <SetObjectivesWrapper>
        <Flex
          width="100%"
          padding="15px 10px"
          style={{ border: '1px solid #CFD9E4', borderRadius: '4px' }}
          backgroundColor="#FBFBFB"
          flexDirection="column"
        >
          <Flex width="100%">
            <Flex
              flexDirection="column"
              width="15%"
              fontSize="14px"
              color="#000000"
              mr="15px"
              style={{ position: 'relative' }}
            >
              <Flex width="100%">Name</Flex>
              <Flex width="100%" pt="8px">
                <SetObjectivesInput
                  placeholder="Enter Here"
                  value={campaignName}
                  onChange={(e) => setCampaignName(e.target.value)}
                />
              </Flex>
            </Flex>
            <Flex
              width="15%"
              flexDirection="column"
              fontSize="14px"
              color="#000000"
            >
              <Flex width="100%">Purpose</Flex>
              <Flex width="100%" pt="8px">
                <SetObjectivesInput
                  placeholder="Enter Here"
                  value={purpose}
                  onChange={(e) => setPurpose(e.target.value)}
                />
              </Flex>
            </Flex>
          </Flex>
          <Flex width="100%" pt="20px">
            <Flex
              flexDirection="column"
              width="15%"
              fontSize="14px"
              color="#000000"
              mr="15px"
            >
              <Flex width="100%">Target</Flex>
              <Flex width="100%" pt="8px">
                <MioRadioSpan noBorder width="auto">
                  <MioRadio
                    id={'target-prospects'}
                    name="target"
                    onClick={() => setTarget('prospects')}
                    checked={target === 'prospects'}
                    margin="19% 19% 0%"
                  />
                  <label htmlFor="target-prospects">Prospects</label>
                </MioRadioSpan>
                <MioRadioSpan noBorder width="auto">
                  <MioRadio
                    id={'target-clients'}
                    name="target"
                    onClick={() => setTarget('clients')}
                    checked={target === 'clients'}
                    margin="19% 15% 0%"
                  />
                  <label htmlFor="target-clients">Clients</label>
                </MioRadioSpan>
              </Flex>
            </Flex>
          </Flex>
          <Flex width="100%" justifyContent="space-between" pt="20px">
            <Flex flexDirection="column" width="20%">
              <Flex
                justifyContent="space-between"
                fontSize="14px"
                color="#000000"
                width="100%"
              >
                <Flex>
                  Email Lists
                  {isEmpty(campaignId) && (
                    <span
                      style={{
                        color: isEmpty(get(socialNetworkData, 'em', []))
                          ? '#AFAFAF'
                          : '#6250ED',
                        fontSize: '10px',
                        position: 'relative',
                        top: '4px',
                        paddingLeft: '10px',
                        cursor: isEmpty(get(socialNetworkData, 'em', []))
                          ? 'default'
                          : 'pointer',
                      }}
                      onClick={() => handleSelectAll('em')}
                    >
                      Select ALL
                    </span>
                  )}
                </Flex>
                <Flex
                  fontSize="10px"
                  style={{
                    position: 'relative',
                    top: '6px',
                    cursor: 'pointer',
                  }}
                  onClick={() => handleAlphabeticalSort('em')}
                >
                  <span>Sort: Alphabetical</span>
                  <span style={{ paddingLeft: '3px' }}>
                    <FontAwesomeIcon color="#000000" icon={faChevronDown} />
                  </span>
                </Flex>
              </Flex>
              <RecipientsListWrapper>
                {isEmpty(get(socialNetworkData, 'em', [])) ? (
                  <Flex
                    height="100%"
                    justifyContent="center"
                    width="100%"
                    fontSize="12px"
                    alignItems="center"
                    style={{
                      cursor: isEmpty(campaignId) ? 'pointer' : 'default',
                    }}
                    onClick={() => redirectToConfigure('em')}
                  >
                    Please Connect to Email
                  </Flex>
                ) : (
                  <RecipientsListBody>
                    {!isEmpty(contactGroups) && ascEmailOrder === true
                      ? map(contactGroups)
                          .sort((a, b) => a.name.localeCompare(b.name))
                          .map((item, index) => (
                            <>
                              {/* <NewsletterCheckBoxDiv>
                                <label class="container">
                                  <input
                                    type="checkbox"
                                    onChange={() =>
                                      handleCheckboxClick(item, 'em')
                                    }
                                    checked={
                                      selectedEmailContacts.filter(
                                        (s) => s.id === item.id
                                      ).length > 0
                                    }
                                  />
                                  <span class="checkmark"></span>
                                  {item.name}
                                </label>
                              </NewsletterCheckBoxDiv> */}
                              <FilterRowChips
                                style={{ fontSize: '12px', padding: '5px 0px' }}
                              >
                                <label
                                  className="checkbox-button"
                                  style={{
                                    cursor: isEmpty(campaignId)
                                      ? 'pointer'
                                      : 'default',
                                  }}
                                >
                                  <input
                                    type="checkbox"
                                    className="checkbox-button__input"
                                    name="draft"
                                    onChange={() =>
                                      handleCheckboxClick(item, 'em')
                                    }
                                    disabled={!isEmpty(campaignId)}
                                    checked={
                                      selectedEmailContacts.filter(
                                        (s) => s.id === item.id
                                      ).length > 0
                                    }
                                    value={item.name}
                                  />
                                  <span
                                    className="checkbox-button__control"
                                    style={{
                                      cursor: isEmpty(campaignId)
                                        ? 'pointer'
                                        : 'default',
                                    }}
                                  ></span>
                                  <span
                                    className="checkbox-button__label"
                                    style={{
                                      color: '#49484a',
                                      fontWeight: '100',
                                      cursor: isEmpty(campaignId)
                                        ? 'pointer'
                                        : 'default',
                                    }}
                                  >
                                    {item.name}
                                  </span>
                                </label>
                              </FilterRowChips>
                            </>
                          ))
                      : !isEmpty(contactGroups) &&
                        contactGroups.map((item, index) => (
                          <>
                            {/* <NewsletterCheckBoxDiv>
                              <label class="container">
                                <input
                                  type="checkbox"
                                  onChange={() => handleCheckboxClick(item, 'em')}
                                  checked={
                                    selectedEmailContacts.filter(
                                      (s) => s.id === item.id
                                    ).length > 0
                                  }
                                />
                                <span class="checkmark"></span>
                                {item.name}
                              </label>
                            </NewsletterCheckBoxDiv> */}
                            <FilterRowChips
                              style={{ fontSize: '12px', padding: '5px 0px' }}
                            >
                              <label
                                className="checkbox-button"
                                style={{
                                  cursor: isEmpty(campaignId)
                                    ? 'pointer'
                                    : 'default',
                                }}
                              >
                                <input
                                  type="checkbox"
                                  className="checkbox-button__input"
                                  name="draft"
                                  onChange={() =>
                                    handleCheckboxClick(item, 'em')
                                  }
                                  disabled={!isEmpty(campaignId)}
                                  checked={
                                    selectedEmailContacts.filter(
                                      (s) => s.id === item.id
                                    ).length > 0
                                  }
                                  value={item.name}
                                />
                                <span
                                  className="checkbox-button__control"
                                  style={{
                                    cursor: isEmpty(campaignId)
                                      ? 'pointer'
                                      : 'default',
                                  }}
                                ></span>
                                <span
                                  className="checkbox-button__label"
                                  style={{
                                    color: '#49484a',
                                    fontWeight: '100',
                                    cursor: isEmpty(campaignId)
                                      ? 'pointer'
                                      : 'default',
                                  }}
                                >
                                  {item.name}
                                </span>
                              </label>
                            </FilterRowChips>
                          </>
                        ))}
                  </RecipientsListBody>
                )}
              </RecipientsListWrapper>
            </Flex>
            <Flex flexDirection="column" width="20%">
              <Flex
                fontSize="14px"
                color="#000000"
                width="100%"
                justifyContent="space-between"
              >
                <Flex>
                  Facebook
                  {isEmpty(campaignId) && (
                    <span
                      style={{
                        color: isEmpty(get(socialNetworkData, 'fb', []))
                          ? '#AFAFAF'
                          : '#6250ED',
                        fontSize: '10px',
                        position: 'relative',
                        top: '4px',
                        paddingLeft: '10px',
                        cursor: isEmpty(get(socialNetworkData, 'fb', []))
                          ? 'default'
                          : 'pointer',
                      }}
                      onClick={() => handleSelectAll('fb')}
                    >
                      Select ALL
                    </span>
                  )}
                </Flex>
                <Flex
                  fontSize="10px"
                  style={{
                    position: 'relative',
                    top: '4px',
                    cursor: 'pointer',
                  }}
                  onClick={() => handleAlphabeticalSort('fb')}
                >
                  <span>Sort: Alphabetical</span>
                  <span style={{ paddingLeft: '3px' }}>
                    <FontAwesomeIcon color="#000000" icon={faChevronDown} />
                  </span>
                </Flex>
              </Flex>
              <RecipientsListWrapper>
                {isEmpty(get(socialNetworkData, 'fb', [])) ? (
                  <Flex
                    height="100%"
                    justifyContent="center"
                    width="100%"
                    fontSize="12px"
                    alignItems="center"
                    style={{
                      cursor: isEmpty(campaignId) ? 'pointer' : 'default',
                    }}
                    onClick={() => redirectToConfigure('fb')}
                  >
                    Please Connect to Facebook
                  </Flex>
                ) : (
                  <RecipientsListBody>
                    {!isEmpty(get(socialNetworkData, 'fb[0].page', [])) &&
                    !ascFbOrder
                      ? map(
                          get(socialNetworkData, 'fb[0].page', []),
                          (item, key) => (
                            <>
                              {/* <NewsletterCheckBoxDiv>
                              <label class="container">
                                <input
                                  type="checkbox"
                                  onChange={() => handleCheckboxClick(key, 'fb')}
                                  checked={
                                    !isEmpty(
                                      selectedFbContacts.filter((s) => s === key)
                                    )
                                  }
                                />
                                <span class="checkmark"></span>
                                {item.details.name}
                              </label>
                            </NewsletterCheckBoxDiv> */}
                              <FilterRowChips
                                style={{ fontSize: '12px', padding: '5px 0px' }}
                              >
                                <label
                                  className="checkbox-button"
                                  style={{
                                    cursor: isEmpty(campaignId)
                                      ? 'pointer'
                                      : 'default',
                                  }}
                                >
                                  <input
                                    type="checkbox"
                                    className="checkbox-button__input"
                                    name="draft"
                                    onChange={() =>
                                      handleCheckboxClick(key, 'fb')
                                    }
                                    disabled={!isEmpty(campaignId)}
                                    checked={
                                      !isEmpty(
                                        selectedFbContacts.filter(
                                          (s) => s === key
                                        )
                                      )
                                    }
                                    value={item.details.name}
                                  />
                                  <span
                                    className="checkbox-button__control"
                                    style={{
                                      cursor: isEmpty(campaignId)
                                        ? 'pointer'
                                        : 'default',
                                    }}
                                  ></span>
                                  <span
                                    className="checkbox-button__label"
                                    style={{
                                      color: '#49484a',
                                      fontWeight: '100',
                                      cursor: isEmpty(campaignId)
                                        ? 'pointer'
                                        : 'default',
                                    }}
                                  >
                                    {item.details.name}
                                  </span>
                                </label>
                              </FilterRowChips>
                            </>
                          )
                        )
                      : map(get(socialNetworkData, 'fb[0].page', []))
                          .sort((a, b) =>
                            a.details.name.localeCompare(b.details.name)
                          )
                          .map((item, index) => (
                            <>
                              <FilterRowChips
                                style={{ fontSize: '12px', padding: '5px 0px' }}
                              >
                                <label
                                  className="checkbox-button"
                                  style={{
                                    cursor: isEmpty(campaignId)
                                      ? 'pointer'
                                      : 'default',
                                  }}
                                >
                                  <input
                                    type="checkbox"
                                    className="checkbox-button__input"
                                    name="draft"
                                    onChange={() =>
                                      handleCheckboxClick(item.id, 'fb')
                                    }
                                    disabled={!isEmpty(campaignId)}
                                    checked={
                                      !isEmpty(
                                        selectedFbContacts.filter(
                                          (s) => s === item.id
                                        )
                                      )
                                    }
                                    value={item.details.name}
                                  />
                                  <span
                                    className="checkbox-button__control"
                                    style={{
                                      cursor: isEmpty(campaignId)
                                        ? 'pointer'
                                        : 'default',
                                    }}
                                  ></span>
                                  <span
                                    className="checkbox-button__label"
                                    style={{
                                      color: '#49484a',
                                      fontWeight: '100',
                                      cursor: isEmpty(campaignId)
                                        ? 'pointer'
                                        : 'default',
                                    }}
                                  >
                                    {item.details.name}
                                  </span>
                                </label>
                              </FilterRowChips>
                            </>
                          ))}
                  </RecipientsListBody>
                )}
              </RecipientsListWrapper>
            </Flex>
            <Flex flexDirection="column" width="20%">
              <Flex
                fontSize="14px"
                color="#000000"
                width="100%"
                justifyContent="space-between"
              >
                <Flex>
                  LinkedIn
                  {isEmpty(campaignId) && (
                    <span
                      style={{
                        color: isEmpty(get(socialNetworkData, 'ln', []))
                          ? '#AFAFAF'
                          : '#6250ED',
                        fontSize: '10px',
                        position: 'relative',
                        top: '4px',
                        paddingLeft: '10px',
                        cursor: isEmpty(get(socialNetworkData, 'ln', []))
                          ? 'default'
                          : 'pointer',
                      }}
                      onClick={() => handleSelectAll('ln')}
                    >
                      Select ALL
                    </span>
                  )}
                </Flex>
                <Flex
                  fontSize="10px"
                  style={{
                    position: 'relative',
                    top: '4px',
                    cursor: 'pointer',
                  }}
                  onClick={() => handleAlphabeticalSort('ln')}
                >
                  <span>Sort: Alphabetical</span>
                  <span style={{ paddingLeft: '3px' }}>
                    <FontAwesomeIcon color="#000000" icon={faChevronDown} />
                  </span>
                </Flex>
              </Flex>
              <RecipientsListWrapper>
                {isEmpty(get(socialNetworkData, 'ln', [])) ? (
                  <Flex
                    height="100%"
                    justifyContent="center"
                    width="100%"
                    fontSize="12px"
                    alignItems="center"
                    style={{
                      cursor: isEmpty(campaignId) ? 'pointer' : 'default',
                    }}
                    onClick={() => redirectToConfigure('ln')}
                  >
                    Please Connect to LinkedIn
                  </Flex>
                ) : (
                  <RecipientsListBody>
                    {/* <NewsletterCheckBoxDiv>
                      <label class="container">
                        <input
                          type="checkbox"
                          onChange={() => handleCheckboxClick(0, 'ln')}
                          checked={
                            !isEmpty(selectedLnContacts.filter((s) => s === 0))
                          }
                        />
                        <span class="checkmark"></span>
                        On News Feed
                      </label>
                    </NewsletterCheckBoxDiv>
                     */}
                    <FilterRowChips
                      style={{ fontSize: '12px', padding: '5px 0px' }}
                    >
                      <label
                        className="checkbox-button"
                        style={{
                          cursor: isEmpty(campaignId) ? 'pointer' : 'default',
                        }}
                      >
                        <input
                          type="checkbox"
                          className="checkbox-button__input"
                          name="draft"
                          onChange={() => handleCheckboxClick(0, 'ln')}
                          disabled={!isEmpty(campaignId)}
                          checked={
                            !isEmpty(selectedLnContacts.filter((s) => !s))
                          }
                          value={'news-feed'}
                        />
                        <span
                          className="checkbox-button__control"
                          style={{
                            cursor: isEmpty(campaignId) ? 'pointer' : 'default',
                          }}
                        ></span>
                        <span
                          className="checkbox-button__label"
                          style={{
                            color: '#49484a',
                            fontWeight: '100',
                            cursor: isEmpty(campaignId) ? 'pointer' : 'default',
                          }}
                        >
                          On News Feed
                        </span>
                      </label>
                    </FilterRowChips>
                    {!isEmpty(get(socialNetworkData, 'ln[0].page', [])) &&
                    !ascLnOrder
                      ? map(
                          get(socialNetworkData, 'ln[0].page', []),
                          (item, key) => (
                            <>
                              {/* <NewsletterCheckBoxDiv>
                              <label class="container">
                                <input
                                  type="checkbox"
                                  onChange={() => handleCheckboxClick(key, 'ln')}
                                  checked={
                                    !isEmpty(
                                      selectedLnContacts.filter((s) => s === key)
                                    )
                                  }
                                />
                                <span class="checkmark"></span>
                                {item.details.name}
                              </label>
                            </NewsletterCheckBoxDiv> */}
                              <FilterRowChips
                                style={{ fontSize: '12px', padding: '5px 0px' }}
                              >
                                <label
                                  className="checkbox-button"
                                  style={{
                                    cursor: isEmpty(campaignId)
                                      ? 'pointer'
                                      : 'default',
                                  }}
                                >
                                  <input
                                    type="checkbox"
                                    className="checkbox-button__input"
                                    name="draft"
                                    onChange={() =>
                                      handleCheckboxClick(key, 'ln')
                                    }
                                    disabled={!isEmpty(campaignId)}
                                    checked={
                                      !isEmpty(
                                        selectedLnContacts.filter(
                                          (s) => s === key
                                        )
                                      )
                                    }
                                    value={item.details.name}
                                  />
                                  <span
                                    className="checkbox-button__control"
                                    style={{
                                      cursor: isEmpty(campaignId)
                                        ? 'pointer'
                                        : 'default',
                                    }}
                                  ></span>
                                  <span
                                    className="checkbox-button__label"
                                    style={{
                                      color: '#49484a',
                                      fontWeight: '100',
                                      cursor: isEmpty(campaignId)
                                        ? 'pointer'
                                        : 'default',
                                    }}
                                  >
                                    {item.details.name}
                                  </span>
                                </label>
                              </FilterRowChips>
                            </>
                          )
                        )
                      : map(get(socialNetworkData, 'ln[0].page', []))
                          .sort((a, b) =>
                            a.details.name.localeCompare(b.details.name)
                          )
                          .map((item, index) => (
                            <>
                              <FilterRowChips
                                style={{ fontSize: '12px', padding: '5px 0px' }}
                              >
                                <label
                                  className="checkbox-button"
                                  style={{
                                    cursor: isEmpty(campaignId)
                                      ? 'pointer'
                                      : 'default',
                                  }}
                                >
                                  <input
                                    type="checkbox"
                                    className="checkbox-button__input"
                                    name="draft"
                                    onChange={() =>
                                      handleCheckboxClick(item.id, 'ln')
                                    }
                                    disabled={!isEmpty(campaignId)}
                                    checked={
                                      !isEmpty(
                                        selectedLnContacts.filter(
                                          (s) => s === item.id
                                        )
                                      )
                                    }
                                    value={item.details.name}
                                  />
                                  <span
                                    className="checkbox-button__control"
                                    style={{
                                      cursor: isEmpty(campaignId)
                                        ? 'pointer'
                                        : 'default',
                                    }}
                                  ></span>
                                  <span
                                    className="checkbox-button__label"
                                    style={{
                                      color: '#49484a',
                                      fontWeight: '100',
                                      cursor: isEmpty(campaignId)
                                        ? 'pointer'
                                        : 'default',
                                    }}
                                  >
                                    {item.details.name}
                                  </span>
                                </label>
                              </FilterRowChips>
                            </>
                          ))}
                  </RecipientsListBody>
                )}
              </RecipientsListWrapper>
            </Flex>
            <Flex flexDirection="column" width="20%">
              <Flex fontSize="14px" color="#000000" width="100%">
                Twitter
              </Flex>
              <RecipientsListWrapper>
                {isEmpty(get(socialNetworkData, 'tw', [])) ? (
                  <Flex
                    height="100%"
                    justifyContent="center"
                    width="100%"
                    fontSize="12px"
                    alignItems="center"
                    style={{
                      cursor: isEmpty(campaignId) ? 'pointer' : 'default',
                    }}
                    onClick={() => redirectToConfigure('tw')}
                  >
                    Please Connect to Twitter
                  </Flex>
                ) : (
                  <RecipientsListBody>
                    {/* <NewsletterCheckBoxDiv>
                      <label class="container">
                        <input
                          type="checkbox"
                          onChange={() => handleCheckboxClick(0, 'tw')}
                          checked={
                            !isEmpty(selectedTwContacts.filter((s) => s === 0))
                          }
                        />
                        <span class="checkmark"></span>
                        On News Feed
                      </label>
                    </NewsletterCheckBoxDiv> */}
                    <FilterRowChips
                      style={{ fontSize: '12px', padding: '5px 0px' }}
                    >
                      <label
                        className="checkbox-button"
                        style={{
                          cursor: isEmpty(campaignId) ? 'pointer' : 'default',
                        }}
                      >
                        <input
                          type="checkbox"
                          className="checkbox-button__input"
                          name="draft"
                          onChange={() => handleCheckboxClick(0, 'tw')}
                          disabled={!isEmpty(campaignId)}
                          checked={
                            !isEmpty(selectedTwContacts.filter((s) => !s))
                          }
                          value="news-feed"
                        />
                        <span
                          className="checkbox-button__control"
                          style={{
                            cursor: isEmpty(campaignId) ? 'pointer' : 'default',
                          }}
                        ></span>
                        <span
                          className="checkbox-button__label"
                          style={{
                            color: '#49484a',
                            fontWeight: '100',
                            cursor: isEmpty(campaignId) ? 'pointer' : 'default',
                          }}
                        >
                          On News Feed
                        </span>
                      </label>
                    </FilterRowChips>
                  </RecipientsListBody>
                )}
              </RecipientsListWrapper>
            </Flex>
          </Flex>
        </Flex>
      </SetObjectivesWrapper>
      <CampaignFooterButtons
        enableNext={!handleValidation()}
        nextHandler={handleNextClick}
        backHandler={handleBackButtonClick}
        loading={loading}
        saveAndCloseHandler={handleSaveAndClose}
      />
    </div>
  );
};

export default SetObjectives;
