import React from 'react';
import { DialogTitle as MuiDialogTitle, IconButton } from '@mui/material';
import Multiply from 'src/assets/icons/Multiply';

const DialogTitle = (props) => {
  const { children, onClose, showCloseButton, showTitle, ...other } = props;

  return (
    <MuiDialogTitle
      sx={{
        m: 0,
        p: showTitle ? 2 : 0,
        backgroundColor: '#fff',
      }}
      {...other}
    >
      {showTitle && children}
      {showCloseButton && onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 11,
          }}
        >
          <Multiply />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
};

export default DialogTitle;
