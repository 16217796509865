import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Flex } from '@rebass/grid';
import { get, isEmpty, map, uniqBy } from 'lodash';
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Button,
  ButtonNoBorderBackground,
  LoaderWrapper,
  MioDatePicker,
  ModalCloseButton,
  ModalHeader,
} from '../../../components';
import {
  shareNewsletter,
  updateNewsletter,
} from '../../../services/newsletter';
import {
  company,
  getDayMonthYear,
  getMediaUrl,
  getNewsletterSnetworkActId,
  themesData,
} from '../../../utils';
import { NewsletterArticle } from '../NewsletterArticle';
import {
  MessageWrapper,
  NewsletterScheduledToolTip,
  NewsletterScheduleWrapper,
  PreviewBox,
  PreviewButtonWrapper,
  PreviewWrapper,
  SignatureSection,
} from './PreviewTab.styles';
import {
  SuccessModal,
  SuccessScheduleModal,
  CloneComplianceModal,
} from '../NewsletterModals/NewsletterModals';
import moment from 'moment';
import LeadCaptureOption from '../../../components/ShareContentContainerV2/Elements/LeadCaptureOption';
import ComplianceReview from '../../../components/ShareContentContainerV2/Elements/ComplianceReview';
import {
  CheckBox,
  CheckBoxLabel,
  CheckBoxSpan,
  ScheduleModal,
} from '../../../components/ShareContentContainerV2/ShareContentContainer.styles';
import { RepostCheckBox } from '../../../components/ShareContentContainerV2/Elements/ShareCheckBoxes';
import { resubmitArticle } from '../../../services/dashboard';
import useQuery from '../../../customHooks/useQuery';

const PreviewTab = ({
  setSelectedIndex,
  newsletterArticles,
  selectedContacts,
  customizeData,
  userData,
  themeNames,
  newsletterSetState,
  setShowTabs,
  newsletterId,
  socialNetworkData,
  newsletterScheduledTime,
  isEditMode,
  outBoxTabSource,
  setShowOutbox,
  newsletterOutboxRequest,
  setOutBoxIndex,
  leadCaptureDetails,
  engagement_details,
  ccContacts,
  isFromCompliance,
  touched,
  setTouched,
  complianceStatus,
  isPremiumAdvisor,
  cloneDetails,
  setPage,
  emailTemplateSelected,
  channelId,
  popularThemes
}) => {
  const [open, setOpen] = useState(false);
  const [scheduleOpen, setScheduleOpen] = useState(false);
  const [scheduleValue, setScheduleValue] = useState(
    newsletterScheduledTime ? newsletterScheduledTime : null
  );
  const [loading, setLoading] = useState(false);
  const [successOpen, setSuccessOpen] = useState(false);
  const [pdfCount, setPdfCount] = useState(0);
  const [selectedLeadCaptureOption, setSelectedLeadCaptureOption] = useState(false);
  const [selectedComplianceCheck, setSelectedComplianceCheck] = useState('no');
  const [selectedReferralCaptureOption, setSelectedReferralCaptureOption] = useState(false);
  const [openResentModal, setOpenResentModal] = useState(false);
  const [resendContacts, setResendContacts] = useState([]);
  const [openComplianceModal, toggleComplianceModal] = useState(false);

  const history = useHistory();
  let query = useQuery();

  useEffect(() => {
    if (isEditMode && outBoxTabSource && newsletterScheduledTime) {
      setScheduleValue(changeScheduleTime(newsletterScheduledTime));
    }

    let salutationStyle;
    let messageContent =
      document.getElementById('message') &&
      document.getElementById('message').querySelectorAll('p');


    if (messageContent && messageContent[0]) {
      salutationStyle = messageContent[0].style.cssText;
      if(!isEmpty(get(messageContent[0], 'children', []))) {
        salutationStyle = salutationStyle + messageContent[0].children[0].style.cssText;
          if(!isEmpty(get(messageContent[0], 'children[0].children', []))) {
            salutationStyle = salutationStyle + messageContent[0].children[0].children[0].style.cssText;
            if(!isEmpty(get(messageContent[0], 'children[0].children[0].children'))) {
              salutationStyle = salutationStyle + messageContent[0].children[0].children[0].children[0].style.cssText;
              if(!isEmpty(get(messageContent[0], 'children[0].children[0].children[0].children', []))) {
                salutationStyle = salutationStyle + messageContent[0].children[0].children[0].children[0].children[0].style.cssText;
              }
            }
          }
      }
      document.getElementById('salutation_text').style.cssText = salutationStyle;
    }

    if (!isEmpty(newsletterArticles)) {
      setPdfCount(
        newsletterArticles.filter((n) => n.link.includes('article_link')).length
      );
    }

    if (leadCaptureDetails && leadCaptureDetails.lead_gen === 'true') {
      setSelectedLeadCaptureOption(true);
    }

    if (leadCaptureDetails && leadCaptureDetails.referral === 'true') {
      setSelectedReferralCaptureOption(true);
    }

    if (outBoxTabSource === 'sent') {
      setSelectedLeadCaptureOption(false);
      setSelectedReferralCaptureOption(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps


  }, []);

  const handleBackButtonClick = () => {
    if (outBoxTabSource === 'sent') {
      setSelectedIndex(0);
    } else {
      setSelectedIndex(3);
    }
  };

  const handleDateSubmit = (date) => {
    setScheduleValue(date);
    // if (complianceStatus === 'rejected' && date !== newsletterScheduledTime)
    //   setTouched(true);
    setOpen(false);
  };

  const handleSave = () => {
    setLoading(true);
    let params = {};
    if (
      userData &&
      userData.details &&
      userData.details.user &&
      userData.details.user.enable_lead_capture === 'true'
    ) {
      if (selectedLeadCaptureOption) {
        params.lead_gen = 'true';
        params.referral = 'false';
      } else if (selectedReferralCaptureOption) {
        params.referral = 'true';
        params.lead_gen = 'false';
      }
    }
    if (!isEmpty(params)) {
      updateNewsletter({ id: newsletterId, params })
        .then((res) => {})
        .catch((err) => {
          setLoading(false);
        });
    }
    newsletterSetState({
      newsletterId: '',
      newsletterName: '',
      selectedContacts: [],
      ccContacts: [],
      emailTemplateSelected: '',
      newsletterArticles: [],
      customData: {},
      scheduledTime: '',
      tab: '',
      leadCapture: {},
    });
    setLoading(false);
    setShowTabs(false);
    setShowOutbox(true);
    history.push('/newsletter?type=dashboard');
    setSelectedIndex(0);
  };

  const changeScheduleTime = (time) => {
    let newTime = new Date(time);
    newTime.setMinutes(newTime.getMinutes() + 5);
    const [selectedMonth, selectedDay, selectedYear] = getDayMonthYear(newTime);
    const checkHours =
      newTime.getHours().toString().length === 1
        ? `0${newTime.getHours()}`
        : newTime.getHours();
    const checkMin =
      newTime.getMinutes().toString().length === 1
        ? `0${newTime.getMinutes()}`
        : newTime.getMinutes();
    return `${selectedYear}-${selectedMonth}-${selectedDay}T${checkHours}:${checkMin}:00`;
  };

  const handleSend = () => {
    if (
      (outBoxTabSource === 'draft' &&
        cloneDetails &&
        cloneDetails.compliance_status &&
        touched) ||
      complianceStatus
    ) {
      let formattedSelectedContacts = map(selectedContacts, 'id');
      let formattedNewsletterArticles = [];

      let customDataObj = {
        subject: customizeData.subject,
        salutation: customizeData.salutation,
        salutation_text: customizeData.salutation_text.value,
        message: customizeData.message,
        title: customizeData.title,
      };
      // delete customDataObj.media1
      const updateParams = {
        contactlists: JSON.stringify(formattedSelectedContacts),
        custom_data: JSON.stringify(customDataObj),
        template: JSON.stringify(emailTemplateSelected),
      };

      if (!isEmpty(newsletterArticles)) {
        formattedNewsletterArticles = newsletterArticles.map((item, index) => {
          let obj = {
            article_id: item.id,
          };

          if (item.themeId) {
            obj.theme_id = item.themeId;
          }
          return obj;
        });
        updateParams.articles = JSON.stringify(formattedNewsletterArticles);
      }
      if (ccContacts.length > 0)
        updateParams.cc_email_ids = JSON.stringify(ccContacts);
      updateNewsletter({
        id: newsletterId,
        params: updateParams,
        media1: customizeData.media1,
      });
    }


    if (outBoxTabSource === 'sent') {
      const resendIds = map(engagement_details, 'contactlist');
      const resendContacts = selectedContacts.filter((s) =>
        resendIds.includes(s.id)
      );
      if (!isEmpty(resendContacts)) {
        resendContacts.forEach((item) => {
          item.checked = true;
        });
        setResendContacts(resendContacts);
        setOpenResentModal(true);
      } else {
        handleReshare();
      }
    } else {
      setLoading(true);
      let params = {
        snetwork_act_id: getNewsletterSnetworkActId(socialNetworkData),
      };

      // Create Flow
      if (!cloneDetails && !isEditMode && query.get('type') === 'create') {
        if (
          userData &&
          userData.compliance &&
          (userData.compliance === 'optional' || userData.compliance === 'off')
        ) {
          params.skip_compliance = selectedComplianceCheck === 'no';
        } else if (isPremiumAdvisor) {
          params.skip_compliance = true;
        } else {
          params.skip_compliance = false;
        }
      }

      if (
        (!cloneDetails || (cloneDetails && !cloneDetails.compliance_status)) &&
        !isPremiumAdvisor &&
        outBoxTabSource === 'draft' &&
        (!isEditMode || !complianceStatus)
      ) {
        if (
          userData &&
          userData.compliance &&
          (userData.compliance === 'optional' || userData.compliance === 'off')
        )
          params.skip_compliance = selectedComplianceCheck === 'no';
        else params.skip_compliance = false;
      } else if (cloneDetails && cloneDetails.compliance_status) {
        console.log('CLONE DETAILS - - --  ', cloneDetails);
        if (cloneDetails.compliance_status === 'approved')
          params.skip_compliance = !touched;
        else
          params.skip_compliance =
            userData &&
            userData.compliance &&
            (userData.compliance === 'optional' ||
              userData.compliance === 'off')
              ? selectedComplianceCheck === 'no'
              : false;
      } else if (outBoxTabSource === 'scheduled') {
        console.log('COMPLIANCE STATUS - - - - ', complianceStatus);
        if (
          complianceStatus === 'rejected' ||
          (complianceStatus === 'approved' && touched) ||
          (!complianceStatus &&
            !isPremiumAdvisor &&
            userData &&
            userData.compliance &&
            (userData.compliance === 'on' ||
              (userData.compliance === 'optional' &&
                selectedComplianceCheck === 'yes')))
        )
          params.skip_compliance = false;
        else params.skip_compliance = true;
      }

      if (scheduleValue && scheduleValue.includes('T')) {
        params.schedule_time = scheduleValue;
      } else if (scheduleValue && !scheduleValue.includes('T')) {
        params.schedule_time = changeScheduleTime(scheduleValue);
      }

      if (isEditMode && outBoxTabSource === 'scheduled') {
        params.re_schedule = 'true';
      }

      if (
        userData &&
        userData.details &&
        userData.details.user &&
        userData.details.user.enable_lead_capture === 'true'
      ) {
        if (selectedLeadCaptureOption) {
          params.lead_gen = 'true';
          updateNewsletter({
            id: newsletterId,
            params: { lead_gen: 'true', referral: 'false' },
          })
            .then((res) => {})
            .catch((err) => {
              setLoading(false);
            });
        } else if (selectedReferralCaptureOption) {
          params.referral = 'true';
          updateNewsletter({
            id: newsletterId,
            params: { referral: 'true', lead_gen: 'false' },
          })
            .then((res) => {})
            .catch((err) => {
              setLoading(false);
            });
        }
      }
      if (complianceStatus && touched) {
        params.compliance_id = customizeData.complianceId;
        newsletterSetState({ isFromCompliance: false });
        delete params.re_schedule;
        resubmitArticle(params)
          .then((res) => {
            if (res.result.success) {
              if (scheduleValue) {
                setScheduleOpen(true);
              } else {
                setSuccessOpen(true);
              }
              setLoading(false);
              window.setTimeout(() => {
                setSuccessOpen(false);
                setScheduleOpen(false);
                setShowTabs(false);
                setShowOutbox(true);

                if (outBoxTabSource === 'scheduled') {
                  query.delete('source');
                  query.delete('tab');
                  query.delete('id');
                  history.replace({
                    search: query.toString(),
                  });

                  history.push('/newsletter?type=dashboard&tab=scheduled');
                } else {
                  history.push('/newsletter?type=dashboard');
                }
                setSelectedIndex(0);
              }, 2000);
            } else {
              setLoading(false);
            }
          })
          .catch((err) => console.log('ERR', err));
      } else {
        shareNewsletter({ id: newsletterId, params: params })
          .then((response) => {
            if (response.result[0].success) {
              if (scheduleValue) {
                setScheduleOpen(true);
              } else {
                setSuccessOpen(true);
              }
              setLoading(false);
              window.setTimeout(() => {
                setSuccessOpen(false);
                setScheduleOpen(false);
                setShowTabs(false);
                setShowOutbox(true);
                if (outBoxTabSource === 'scheduled') {
                  history.push('/newsletter?type=dashboard&tab=scheduled');
                } else {
                  setPage(0);
                  history.push('/newsletter?type=dashboard');
                }
                setSelectedIndex(0);
              }, 2000);
            } else {
              setLoading(false);
            }
          })
          .catch((err) => {
            setLoading(false);
          });
      }
    }
  };

  const handleClose = () => {
    setOpenResentModal(false);
  };

  const closeEdit = () => {
    // setShowTabs(false);
    // setShowOutbox(true);
    history.push('/newsletter?type=dashboard');
  };

  const handleCheckBoxChange = (e, item) => {
    let tempContacts = resendContacts;
    if (item && item.checked) {
      tempContacts.forEach((element) => {
        if (element.id === item.id) {
          item.checked = false;
        }
      });
      setResendContacts([...tempContacts]);
    } else {
      tempContacts.forEach((element) => {
        if (element.id === item.id) {
          item.checked = true;
        }
      });
      setResendContacts([...tempContacts]);
    }
  };

  const handleReshare = () => {
    setLoading(true);
    let params = {
      re_send: 'true',
      snetwork_act_id: getNewsletterSnetworkActId(socialNetworkData),
    };

    if (!complianceStatus && !isPremiumAdvisor) {
      if (
        userData &&
        userData.compliance &&
        (userData.compliance === 'optional' || userData.compliance === 'off')
      )
        params.skip_compliance = selectedComplianceCheck === 'no';
      else params.skip_compliance = false;
    }

    if (
      userData &&
      userData.details &&
      userData.details.user &&
      userData.details.user.enable_lead_capture === 'true'
    ) {
      if (selectedLeadCaptureOption) {
        params.lead_gen = 'true';
        params.referral = 'false';
      } else if (selectedReferralCaptureOption) {
        params.referral = 'true';
        params.lead_gen = 'false';
      }
    }

    let checkedContactLists = resendContacts.filter((s) => s.checked);

    checkedContactLists.forEach((ele) => {
      delete ele.checked;
    });

    let contactlists = JSON.stringify(
      map(uniqBy([...checkedContactLists, ...selectedContacts], 'id'), 'id')
    );

    params.contactlists = contactlists;
    if (!isEmpty(ccContacts)) {
      params.cc_email_ids = JSON.stringify(ccContacts);
    }

    if (scheduleValue) {
      params.schedule_time = scheduleValue;
    }

    shareNewsletter({ id: newsletterId, params: params })
      .then((response) => {
        if (response.result[0].success) {
          if (scheduleValue) {
            setScheduleOpen(true);
          } else {
            setSuccessOpen(true);
          }
          setLoading(false);
          window.setTimeout(() => {
            setSuccessOpen(false);
            setScheduleOpen(false);
            setOpenResentModal(false);
            setResendContacts([]);
            setShowTabs(false);
            setShowOutbox(true);
            if (outBoxTabSource === 'scheduled') {
              history.push('/newsletter?type=dashboard&tab=scheduled');
            } else {
              history.push('/newsletter?type=dashboard');
            }
            setSelectedIndex(0);
          }, 2000);
        } else {
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const displayComplianceOptions = () => {
    if (userData && userData.compliance && userData.compliance === 'optional') {
      if (
        outBoxTabSource === 'draft' &&
        cloneDetails &&
        !cloneDetails.compliance_status
      ) {
        return true;
      } else if (!isEditMode && query.get('type') === 'create') {
        return true;
      } else if (outBoxTabSource === 'sent' && !complianceStatus) {
        return true;
      } else if (outBoxTabSource === 'scheduled' && !complianceStatus) {
        return true;
      }
    } else {
      return false;
    }
  };

  const signature =
    userData &&
    userData.details &&
    userData.details.user &&
    userData.details.user.email_personalization &&
    userData.details.user.email_personalization.signature;

  const footerText =
    userData &&
    userData.details &&
    userData.details.user &&
    userData.details.user.email_personalization &&
    userData.details.user.email_personalization.footer_text;

  const personalizeImage =
    userData &&
    userData.details &&
    userData.details.user &&
    userData.details.user.email_personalization &&
    userData.details.user.email_personalization.media_urls;

  const imageWidth =
    userData &&
    userData.details &&
    userData.details.user &&
    userData.details.user.email_personalization &&
    userData.details.user.email_personalization.image_width;

  const imageHeight =
    userData &&
    userData.details &&
    userData.details.user &&
    userData.details.user.email_personalization &&
    userData.details.user.email_personalization.image_height;

  const editorImage =
    userData &&
    userData.details &&
    userData.details.user &&
    userData.details.user.personalization &&
    userData.details.user.personalization.corporate_identity &&
    userData.details.user.personalization.corporate_identity.logo &&
    userData.details.user.personalization.corporate_identity.logo.main &&
    userData.details.user.personalization.corporate_identity.logo.main
      .media_urls;

  const getComplianceStatusAndMsg = () => {
    // TODO: Will need to clean up a little more of this mess
    let complianceMessage;
    if (cloneDetails && cloneDetails.compliance_status && !isFromCompliance) {
      if (touched) {
        complianceMessage = 'Send To Compliance';
      } else {
        complianceMessage = 'Send';
      }
    } else if (
      (!isEditMode && query.get('type') === 'create') ||
      !complianceStatus
    ) {
      if (
        (userData && userData.compliance && userData.compliance === 'on') ||
        selectedComplianceCheck === 'yes'
      ) {
        complianceMessage = 'Send To Compliance';
      } else {
        complianceMessage = 'Send';
      }
    } else if (!isFromCompliance && complianceStatus === 'rejected') {
      complianceMessage = 'Resubmit To Compliance';
    } else if (
      isEditMode &&
      complianceStatus === 'approved' &&
      !get(cloneDetails, 'compliance_status', false)
    ) {
      if (touched) {
        complianceMessage = 'Send To Compliance';
      } else {
        complianceMessage = 'Send';
      }
    } else if (isFromCompliance) {
      complianceMessage = 'Resubmit To Compliance';
    } else {
      complianceMessage = 'Send';
    }

    return {
      complianceMessage,
      status: complianceMessage === 'Send' ? false : true,
    };
  };

  return (
    <LoaderWrapper
      styles={{ position: 'absolute' }}
      isLoading={isFromCompliance && !userData}
    >
      <Flex width="100%" padding="50px 20px 0px" flexDirection="column">
        <PreviewWrapper>
          <PreviewBox>
            <Flex
              width="100%"
              justifyContent="center"
              padding="30px 0px"
              alignItems="center"
              style={{
                flex: '0 0 auto',
                height: personalizeImage || editorImage ? 'auto' : '0px',
                backgroundColor:
                  userData &&
                  userData.details &&
                  userData.details.user &&
                  userData.details.user.email_personalization &&
                  userData.details.user.email_personalization.banner_hex_color,
              }}
            >
              {userData &&
              userData.details &&
              userData.details.user &&
              userData.details.user.email_personalization &&
              userData.details.user.email_personalization.media_urls &&
              userData.details.user.email_personalization.use_logo === 'true' ? (
                <img
                  alt="logo"
                  width={imageWidth && imageWidth!=='0' ? imageWidth : '150'}
                  height={imageHeight && imageHeight!=='0' ? imageHeight : 'auto'}
                  src={getMediaUrl(
                    userData.details.user.email_personalization.media_urls,
                    '',
                    'original'
                  )}
                />
              ) : userData &&
                userData.details &&
                userData.details.user &&
                userData.details.user.personalization &&
                userData.details.user.personalization.corporate_identity &&
                userData.details.user.personalization.corporate_identity.logo &&
                userData.details.user.personalization.corporate_identity.logo.main &&
                userData.details.user.personalization.corporate_identity.logo.main.media_urls &&
                userData.details.user.email_personalization.use_logo === 'true' ? (
                <img
                  alt="logo"
                  width={imageWidth && imageWidth!=='0' ? imageWidth : '150'}
                  height={imageHeight && imageHeight!=='0' ? imageHeight : 'auto'}
                  src={getMediaUrl(
                    userData.details.user.personalization.corporate_identity
                      .logo.main.media_urls,
                    '',
                    'original'
                  )}
                />
              ) : null}
            </Flex>
            <h1
              style={{
                color: '#848484',
                textAlign: 'left',
                padding: '5px 30px',
              }}
              dangerouslySetInnerHTML={{ __html: customizeData.title }}
            />
            <Flex width="100%" padding="10px 30px" style={{ flex: '0 0 auto' }}>
              {customizeData && customizeData.media1 && (
                <img
                  alt="customize"
                  width="100%"
                  src={customizeData.media1}
                  style={{ maxHeight: '300px', objectFit: 'cover' }}
                />
              )}
            </Flex>
            <Flex
              width="100%"
              padding="10px 30px"
              color={themesData.charcoalGrey}
              fontSize="16px"
              style={{ flex: '0 0 auto' }}
            >
              <p style={{ marginBlock: '0em', color: 'black' }}>
                <span id="salutation_text">
                  {customizeData && customizeData.salutation}{' '}
                  {customizeData && customizeData.salutation_text && '{{'}{' '}
                  {customizeData &&
                    customizeData.salutation_text &&
                    customizeData.salutation_text.label}{' '}
                  {customizeData && customizeData.salutation_text && '}}'}
                </span>
              </p>
            </Flex>
            {customizeData && customizeData.message && (
              <MessageWrapper
                id="message"
                style={{
                  textAlign: 'left',
                  color: themesData.charcoalGrey,
                  padding: '0px 30px 15px',
                }}
                dangerouslySetInnerHTML={{ __html: customizeData.message }}
              />
            )}
            {emailTemplateSelected !== 'no-content' && (
              <Flex
                width="100%"
                justifyContent="space-between"
                flexWrap="wrap"
                style={{ flex: '0 0 auto' }}
                padding="0px 30px"
              >
                {!isEmpty(newsletterArticles) &&
                  newsletterArticles.map((item, index) => (
                    <NewsletterArticle
                      key={index}
                      {...item}
                      themeNames={themeNames}
                      article={item}
                      newsletterArticles={newsletterArticles}
                      deleteBlocked
                      openUrl={!isEmpty(item.sponsoredFunds) ? false : true}
                      width={'308px'}
                      height={'300px'}
                      emailTemplateSelected={emailTemplateSelected}
                      section="NEWSLETTER_PREVIEW_TAB"
                      userData={userData}
                      channelId={channelId}
                      popularThemes={popularThemes}
                    />
                  ))}
              </Flex>
            )}
            <SignatureSection
              style={{ padding: '0px 30px' }}
              dangerouslySetInnerHTML={{ __html: signature && signature }}
            ></SignatureSection>
            <SignatureSection
              style={{ padding: '0px 30px' }}
              dangerouslySetInnerHTML={{ __html: footerText && footerText }}
            ></SignatureSection>
            <Flex width="100%" padding="0px 30px">
              <hr style={{ width: '100%', height: '2px' }} />
            </Flex>
            <Flex
              width="100%"
              color="#666666"
              fontSize="12px"
              padding="0px 30px"
              style={{ flex: '0 0 auto' }}
            >
              © {company.name} 2021
            </Flex>
            <Flex
              width="100%"
              padding="10px 30px"
              color="#666666"
              fontSize="10px"
              style={{ flex: '0 0 auto', textAlign: 'left' }}
            >
              This message was sent to {'{{ '}email{' }}'} and intended for{' '}
              {'{{ '}
              {customizeData &&
                customizeData.salutation_text &&
                customizeData.salutation_text.label}
              {' }}'}.<br></br>
              Not your account? Remove your email from this account.
            </Flex>
          </PreviewBox>
        </PreviewWrapper>

        {userData &&
          userData.details &&
          userData.details.user &&
          userData.details.user.enable_lead_capture === 'true' &&
          pdfCount > 0 && (
            <LeadCaptureOption
              selectedLeadCaptureOption={selectedLeadCaptureOption}
              setSelectedLeadCaptureOption={setSelectedLeadCaptureOption}
              setSelectedReferralCaptureOption={
                setSelectedReferralCaptureOption
              }
              selectecdReferralCaptureOption={selectedReferralCaptureOption}
              top="3px"
            />
          )}

        {userData &&
          userData.details &&
          userData.details.user &&
          userData.details.user.enable_lead_capture === 'true' && (
            <Flex
              justifyContent="center"
              padding="5px"
              color={themesData.charcoalGrey}
              fontSize="12px"
            >
              {pdfCount > 0 ? (
                <span>
                  {pdfCount} article(s) in this newsletter are eligible for lead
                  or referral capture.
                </span>
              ) : (
                <span>
                  There are no articles in this newsletter eligible for lead or
                  referral capture
                </span>
              )}
            </Flex>
          )}
        {displayComplianceOptions() ? (
          <ComplianceReview
            isFromCompliance={false}
            showComplianceCheck={true}
            userData={userData}
            setSelectedComplianceCheck={setSelectedComplianceCheck}
            selectedComplianceCheck={selectedComplianceCheck}
            isFromScheduledTab={false}
            paddingTop="5px"
            jt="center"
          />
        ) : null}

        <Flex
          width="100%"
          alignSelf="flex-end"
          justifyContent="space-between"
          pb="10px"
        >
          <LoaderWrapper isLoading={loading}>
            <Flex width="10%">
              <ButtonNoBorderBackground
                padding="5px 0px 0px"
                onClick={handleBackButtonClick}
              >
                <FontAwesomeIcon icon={faAngleLeft} />
                &nbsp; Back
              </ButtonNoBorderBackground>
            </Flex>

            <Flex width="90%" justifyContent="center">
              {outBoxTabSource !== 'sent' && (
                <Button
                  height="36px"
                  width={
                    isFromCompliance ||
                    (isEditMode && complianceStatus && touched)
                      ? 'auto'
                      : '165px'
                  }
                  margin="10px 10px 0px -68px"
                  onClick={isFromCompliance ? closeEdit : handleSave}
                  bgColor="#ffffff"
                  color={themesData.purpleishBlue}
                >
                  {!isEditMode && query.get('type') === 'create'
                    ? 'Save & Close'
                    : 'Cancel'}
                </Button>
              )}
              <Button
                height="36px"
                width={
                  cloneDetails && cloneDetails.compliance_status
                    ? cloneDetails.compliance_status
                      ? touched
                        ? 'auto'
                        : '165px'
                      : (userData &&
                          userData.compliance &&
                          userData.compliance === 'on') ||
                        selectedComplianceCheck === 'yes'
                      ? 'auto'
                      : '165px'
                    : isFromCompliance ||
                      (isEditMode && complianceStatus && touched) ||
                      complianceStatus === 'rejected' ||
                      (userData &&
                        userData.compliance &&
                        (userData.compliance === 'on' ||
                          (userData.compliance === 'optional' &&
                            selectedComplianceCheck === 'yes')))
                    ? 'auto'
                    : '165px'
                }
                margin="10px 10px 0px"
                onClick={
                  cloneDetails &&
                  !isFromCompliance &&
                  cloneDetails.compliance_status &&
                  touched
                    ? () => toggleComplianceModal(true)
                    : handleSend
                }
                disabled={
                  !isEditMode || !complianceStatus
                    ? isEmpty(get(socialNetworkData, 'em', [])) ||
                      isEmpty(selectedContacts) ||
                      isEmpty(get(customizeData, 'subject', ''))
                    : complianceStatus === 'rejected' && !touched
                    ? true
                    : false
                }
              >
                {getComplianceStatusAndMsg().complianceMessage}
              </Button>
              <PreviewButtonWrapper
                padding="5px 0px 0px"
                style={{ position: 'relative', left: '8%' }}
              >
                {scheduleValue || isFromCompliance ? (
                  <NewsletterScheduleWrapper>
                    {console.log(
                      'IS FROM COMPLIANCE - - - ',
                      isFromCompliance,
                      'COMPLIANCE STATUS - - - - -  ',
                      complianceStatus
                    )}
                    <img
                      alt="schedule"
                      onClick={
                        complianceStatus !== 'rejected' ||
                        (complianceStatus === 'rejected' && touched)
                          ? () => setOpen(true)
                          : () => {}
                      }
                      src={
                        complianceStatus !== 'rejected' ||
                        (complianceStatus === 'rejected' && touched)
                          ? '/assets/images/Schedule-Purple.svg'
                          : '/assets/images/Schedule-Disabled.svg'
                      }
                      className="schedule-clock"
                      style={{
                        cursor:
                          complianceStatus !== 'rejected' ||
                          (complianceStatus === 'rejected' && touched)
                            ? 'pointer'
                            : 'default',
                      }}
                    />
                    {scheduleValue && (
                      <NewsletterScheduledToolTip className="tooltiptext">
                        {moment(scheduleValue).format('Do MMM YYYY | h:mm A')}
                      </NewsletterScheduledToolTip>
                    )}
                  </NewsletterScheduleWrapper>
                ) : (
                  <NewsletterScheduleWrapper>
                    <img
                      alt="schedule"
                      onClick={() => setOpen(true)}
                      src="/assets/images/Schedule-Grey.svg"
                      style={{ cursor: 'pointer' }}
                    />
                  </NewsletterScheduleWrapper>
                )}
                <MioDatePicker
                  isOpen={open}
                  customInput={<div style={{ position: 'relative' }} />}
                  minDate={new Date()}
                  scheduleValue={scheduleValue}
                  onDateClose={() => setOpen(false)}
                  onSubmit={(dateTime) => handleDateSubmit(dateTime)}
                  isFromNewsletter
                  popperPlacement="top"
                  popperProps={{ positionFixed: true }}
                  timeZone={get(userData, 'details.user.timezone', '')}
                  popperModifiers={{
                    offset: {
                      enabled: false,
                      offset: '0px, 0px',
                    },
                    preventOverflow: {
                      rootBoundary: 'viewport',
                      tether: false,
                      altAxis: true,
                    },
                  }}
                />
              </PreviewButtonWrapper>
              <Flex style={{ position: 'relative' }}>
                {scheduleValue &&
                  (complianceStatus !== 'rejected' ||
                    (complianceStatus === 'rejected' && touched)) && (
                    <div
                      style={{
                        position: 'absolute',
                        color: 'red',
                        fontSize: '12px',
                        left: '55px',
                        top: '2px',
                        cursor: 'pointer',
                      }}
                      onClick={() => setScheduleValue(null)}
                    >
                      x
                    </div>
                  )}
              </Flex>
            </Flex>
          </LoaderWrapper>
        </Flex>
        <Flex width="100%" justifyContent="center" fontSize="14px" pb="10px">
          {isEmpty(get(socialNetworkData, 'em', []))
            ? 'Please connect email to start sharing'
            : isEmpty(selectedContacts)
            ? 'Please add recipients to start sharing'
            : isEmpty(get(customizeData, 'subject', ''))
            ? 'Please add subject to start sharing'
            : ''}
        </Flex>
        {scheduleOpen && (
          <SuccessScheduleModal
            open={scheduleOpen}
            setOpen={setScheduleOpen}
            scheduleValue={scheduleValue}
            setShowTabs={setShowTabs}
            setSelectedIndex={setSelectedIndex}
            showComplianceMessage={getComplianceStatusAndMsg().status}
          />
        )}
        {successOpen && (
          <SuccessModal
            open={successOpen}
            setOpen={setSuccessOpen}
            setShowTabs={setShowTabs}
            setSelectedIndex={setSelectedIndex}
            showComplianceMessage={getComplianceStatusAndMsg().status}
            // showComplianceMessage={complianceStatus==='rejected' || complianceStatus === 'approved' && touched && ((userData && userData.compliance && userData.compliance === 'on') || selectedComplianceCheck === 'yes') || !complianceStatus }
          />
        )}
        {openComplianceModal && (
          <CloneComplianceModal
            show={openComplianceModal}
            toggleShow={toggleComplianceModal}
            click={handleSend}
          />
        )}

        {openResentModal && (
          <ScheduleModal
            onRequestClose={handleClose}
            shouldCloseOnOverlayClick
            isOpen={openResentModal}
            paddingBottom="32px"
            padding="0px"
            pb="20px"
            width="38%"
          >
            <ModalHeader
              style={{
                textAlign: 'center',
                fontWeight: 'bold',
                fontSize: '13px',
                color: '#49484a',
              }}
            >
              <p>
                You've already sent this newsletter to the following email
                list(s).
                <br />
                Select the list(s) you want to resend it to.
              </p>
              <ModalCloseButton onClick={handleClose} />
            </ModalHeader>
            {resendContacts &&
              resendContacts.map((item, index) => (
                <CheckBoxSpan
                  key={item.id}
                  screenWidth={window.innerWidth}
                  // checked={data.check}
                  style={{
                    border: 'none',
                    background: '#ececec',
                    marginBottom: '4px',
                    textAlign: 'left',
                  }}
                >
                  <CheckBox
                    id={item.id}
                    checked={item.checked}
                    onChange={(e) => handleCheckBoxChange(e, item)}
                    screenWidth={window.innerWidth}
                  />
                  <CheckBoxLabel
                    style={{ paddingLeft: '11%' }}
                    htmlFor={item.id}
                    color="#848484"
                  >
                    {item.name}
                  </CheckBoxLabel>
                </CheckBoxSpan>
              ))}

            <LoaderWrapper isLoading={loading}>
              <Button
                margin="15px 0px 0px 0px"
                onClick={handleReshare}
                height={'35px'}
                bgColor={
                  isEmpty(resendContacts.filter((s) => s.checked))
                    ? '#AFAFAF'
                    : ''
                }
                padding={'4px 28px 5px 29px'}
                disabled={isEmpty(resendContacts.filter((s) => s.checked))}
              >
                Resend
              </Button>
            </LoaderWrapper>
          </ScheduleModal>
        )}
      </Flex>
    </LoaderWrapper>
  );
};

export default PreviewTab;
