import React from 'react';
import { IconButton } from '@mui/material';
import checkbox_true from './images/checkbox_true.svg';
import checkbox_false from './images/checkbox_false.svg';

const PurpleCheckbox = ({ checked, onClick, size, margin, disabled = false }) => {
  return (
    <IconButton sx={{height: 18, width: 18}} onClick={onClick}>
      <img
        style={{ height: size, width: size, transition: '1s', margin: margin ?? 0, cursor: disabled ? 'not-allowed' : 'pointer'}}
        src={checked ? checkbox_true : checkbox_false}
        alt="checkBox"
      />
    </IconButton>
  );
};

export default PurpleCheckbox;
