/* eslint-disable react-hooks/exhaustive-deps */
import { Flex } from '@rebass/grid';
import React, { useEffect, useRef, useState } from 'react';
import {
  Line,
  WhiteCircle,
} from '../../../components/StepperV2/Stepper.styles';
import StepTwoHeaderPart from './StepTwoHeaderPart';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faPlus } from '@fortawesome/free-solid-svg-icons';
import { Button, LoaderWrapper } from '../../../components';
import { get, isEmpty } from 'lodash';
import StepsFooterV2 from '../../AutomatedCampaigns/CampaignCreateSteps/StepsFooterV2';
import DataCard from './DataCard';
import CustomDateTimeModal from './CustomDateTimeModal';
import { createCampaign, deleteCampaign, getSeriesSubCampaigns, scheduleCampaign, updateHtml } from '../../../services/campaign';
import { connect } from 'react-redux';
import ConfirmationModal from '../../../components/ConfirmationModal/ConfirmationModal';
import { cloneOrSaveCampaign } from '../../../services/campaign';
import AutomatedCampaignEmailPreviewModal from '../../AutomatedCampaigns/CampaignCreateSteps/AutomatedCampaignEmailPreviewModal';
import SocialPreviewModal from './SocialPreviewModal';
import { getAdvisorRows } from '../../../components/RichTextEditor/BeeEditorHelper';
import { getDefaultRows } from '../../../services/bee-editor';
import useQueryForKeyInsights from 'src/hooks/useQueryForKeyInsights';
import EmailDateBlock from './EmailDateBlock';
import SocialDateBlock from './SocialDateBlock';
import useQueryForSocialKeyInsights from 'src/hooks/useQueryForSocialKeyInsights';

const CustomizeCampaignV3 = ({
  userData,
  step,
  setStep,
  onSaveAsDraftClick,
  endCreateFlow,
  seriesId,
  campaignSetState,
  setObjectivesTabData,
  selectedTimeBlocks,
  getAccountId,
  setTimeBlocks,
  timeBlocks,
  blocks,
  setBlocks,
  reviewType
}) => {

  const [showDropdown, setShowDropdown] = useState(false);
  const [openCustomDateTimeModal, setOpenCustomDateTimeModal] = useState(false);
  const [isDatepickerOpen, setIsDatepickerOpen] = useState(false);
  const dropdownRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [requestLoading, setRequestLoading] = useState(false);
  const [selectedId, setSelectedId] = useState();
  const [openCloneModal, setOpenCloneModal] = useState(false);
  const [openPreviewModal, setOpenPreviewModal] = useState(false);
  const [openSocialPreviewModal, setOpenSocialPreviewModal] = useState(false);
  const [nextPageLoading, setNextPageLoading] = useState(false);
  const [selectedTimeBlock, setSelectedTimeBlock] = useState();
  const [defaultRows, setDefaultRows] = useState([]);

  const { data } = useQueryForKeyInsights(userData?.details?.user?.email);
  const { data: socialInsights } = useQueryForSocialKeyInsights(userData?.details?.user?.email);

  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownRef]);

  useEffect(() => {
    setLoading(true);
    fetchSubCampaigns(true);
  }, []);

  useEffect(() => {
    if(!isEmpty(timeBlocks) && !isEmpty(blocks)) {
      blocks.forEach((block, index) => {
        if(isEmpty(timeBlocks.filter(t => t.id === block.id))) {
          let timeObj = {
            id: block.id,
            schedule: 'immediate'
          }
          setTimeBlocks([...timeBlocks, timeObj]);
          campaignSetState({
            selectedTimeBlocks: [...timeBlocks, timeObj]
          })
        } 
      })
    }
  }, [blocks])
  

  const fetchSubCampaigns = (flag=false) => {
    getSeriesSubCampaigns({ id: seriesId, limit: 100 }).then(res => {
      setBlocks(get(res, 'result.data.campaigns', []));
      if(flag) {
        processTimeBlocks(get(res, 'result.data.campaigns', []));
      }
      if(reviewType === 'start') {
        getDefaultRows().then(res => {
          setDefaultRows(res);
          setLoading(false);
          setRequestLoading(false);
        }).catch(err => {
          setLoading(false);
          setRequestLoading(false);
        });
      } else {
        setLoading(false);
        setRequestLoading(false);
      }
    }).catch(err => {
      console.log(err);
      setLoading(false);
      setRequestLoading(false);
    });
  }

  const closeConfModal = () => {
    setSelectedId();
    setOpenDeleteModal(false);
    setOpenCloneModal(false);
    setOpenPreviewModal(false);
    setOpenSocialPreviewModal(false);
  }

  const handleDeleteClick = (id) => {
    setSelectedId(id);
    setOpenDeleteModal(true);
  }

  const handleCloneClick = (id) => {
    setSelectedId(id);
    setOpenCloneModal(true);
  }

  const handleEmailPreviewClick = (item) => {
    console.log('item---', item)
    setOpenPreviewModal(true);
    setSelectedId(item);
  }

  const handleSocialPreviewClick = (item) => {
    console.log('item---', item)
    setOpenSocialPreviewModal(true);
    setSelectedId(item);
  }


  const campaignBlockDeleteHandler = () => {
    setRequestLoading(true);
    deleteCampaign({ id: selectedId, type: 'one_time' }).then(res => {
      setLoading(true);
      fetchSubCampaigns();
      let newTimeBlocks = timeBlocks.filter(t => t.id !== selectedId);
      setTimeBlocks(newTimeBlocks);
      campaignSetState({
        selectedTimeBlocks: newTimeBlocks
      })
      setOpenDeleteModal(false);
    }).catch(err => {
      console.log(err);
      setRequestLoading(false);
    })
  }

  const campaignBlockCloneHandler = () => {
    setRequestLoading(true);
    cloneOrSaveCampaign({ campaign_id: selectedId, series_id: seriesId, template: 'false' }).then(res => {
      setLoading(true);
      fetchSubCampaigns();
      setOpenCloneModal(false);
    }).catch(err => {
      console.log(err);
      setRequestLoading(false);
    })

  }

  const handleDifferentTimes = (date, item) => {
    let selectedTimeBlock = timeBlocks.filter(t => t.id === selectedId);
    let otherTimeBlocks = timeBlocks.filter(t => t.id !== selectedId);
    let timeArr = [];
    if(!isEmpty(selectedTimeBlock)) {
      timeArr = (selectedTimeBlock[0]?.schedule_time && !isEmpty(selectedTimeBlock[0]?.schedule_time)) ? selectedTimeBlock[0]?.schedule_time.filter(t => t.destination_id !== item.id) : [];
      
      let timeObj = {
        destination_id: item.id,
        time: date
      } 

      timeArr = [...timeArr, timeObj];
      selectedTimeBlock[0].schedule_time = timeArr;
      console.log(selectedTimeBlock);
      setTimeBlocks([...otherTimeBlocks, ...selectedTimeBlock]);
      campaignSetState({
        selectedTimeBlocks: [...otherTimeBlocks, ...selectedTimeBlock]
      })
    }
  }

  const getPlatforms = (item) => {
    let socialArr = [];

    if(item.is_facebook === "true" && !socialArr.includes('facebook')) {
      socialArr = [...socialArr, 'facebook'];
    }

    if(item.is_twitter === "true" && !socialArr.includes('twitter')) {
      socialArr = [...socialArr, 'twitter'];
    }

    if(item.is_linkedin === "true" && !socialArr.includes('linkedin')) {
      socialArr = [...socialArr, 'linkedin'];
    }

    return socialArr;
  }

  const handleValidation = () => {
    let isNotValid = false;
    const emailBlocks = blocks.filter(b => b.is_email === "true");
    const socialBlocks = blocks.filter(b => b.is_email === "false");
    
    if(isEmpty(blocks)) {
      isNotValid = true;
    }

    if(!isEmpty(timeBlocks)) {
      let specificTimeBlocks = timeBlocks.filter(t => t.schedule === 'specific_time');
      if(!isEmpty(specificTimeBlocks)) {
        specificTimeBlocks.forEach((item, index) => {
          if(!item?.schedule_time || isEmpty(item.schedule)) {
            if(!isNotValid) {
              isNotValid = true;
            }
          }
        })
      } else {
        isNotValid = false;
      }
      
    }
    if(reviewType === 'start' && isEmpty(defaultRows)) {
      if(!isNotValid) {
        isNotValid = true;
      }
    }

    if(!isEmpty(blocks)) {
      if(!isEmpty(emailBlocks) && isEmpty(setObjectivesTabData?.emList) && isEmpty(setObjectivesTabData?.segmentList)) {
        if(!isNotValid) {
          isNotValid = true;
        }
      }

      if(!isEmpty(socialBlocks) && isEmpty(setObjectivesTabData?.fbList) && isEmpty(setObjectivesTabData?.lnList) && isEmpty(setObjectivesTabData?.twList)) {
        if(!isNotValid) {
          isNotValid = true;
        }
      }
    }

    if(!isEmpty(blocks)) {
      if(!isEmpty(emailBlocks)) {
        emailBlocks.forEach((item, index) => {
          if(!item?.template?.template_html) {
            if(!isNotValid) {
              isNotValid = true;
            }
          }
        })
      } 

      if(!isEmpty(socialBlocks)) {
        socialBlocks.forEach((item, index) => {
          if(!item?.posts || isEmpty(item?.posts)) {
            if(!isNotValid) {
              isNotValid = true;
            }
          }
        })
      } 
    }

    return isNotValid;
  }

  console.log('validation---', handleValidation());

  const changeTimeSlots = (item, type) => {
    let newTimeBlock = timeBlocks.filter(t => t.id === item.id)[0];
    let otherTimeBlocks = timeBlocks.filter(t => t.id !== item.id);
    if(type !== newTimeBlock.schedule) {
      delete newTimeBlock.schedule_time;
    } 

    newTimeBlock.schedule = type;

    if(type === 'different_time') {
      setSelectedId(item.id);
      setOpenCustomDateTimeModal(true);
      
    }


    setTimeBlocks([...otherTimeBlocks, newTimeBlock]);
    campaignSetState({
      selectedTimeBlocks: [...otherTimeBlocks, newTimeBlock]
    })
  }

  const updateSpecificeTimeBlock = (date, id) => {
    let newTimeBlock = timeBlocks.filter(t => t.id === id)[0];
    newTimeBlock.schedule_time = date;
    setIsDatepickerOpen(false);
    setSelectedTimeBlock();
    setTimeBlocks([...timeBlocks, newTimeBlock]);
    campaignSetState({
      selectedTimeBlocks: timeBlocks
    })
  }

  const processTimeBlocks = (campaigns) => {
    if (selectedTimeBlocks && isEmpty(selectedTimeBlocks)) {
      let timeArr = []
      console.log(campaigns);
      campaigns.forEach((item) => {
        if(!item.posts) {
          let timeObj = {
            id: item.id,
            schedule: 'immediate',
          }
  
          timeArr = [...timeArr, timeObj];
        } else {
          let postArr = Object.keys(item.posts);
          if(item.posts[postArr[0]]?.schedule !== 'different_time') {
            if(item.posts[postArr[0]]?.schedule === 'specific_time') {
              let timeObj = {
                id: item.id,
                schedule: (item.posts[postArr[0]]?.schedule_time && !isEmpty(item.posts[postArr[0]]?.schedule_time)) ? item.posts[postArr[0]]?.schedule : 'immediate',
                ...(item.posts[postArr[0]]?.schedule_time && !isEmpty(item.posts[postArr[0]]?.schedule_time)) && {
                  schedule_time: item.posts[postArr[0]]?.schedule_time[0]?.time
                }
              }
              console.log('timeObj', timeObj);
              timeArr = [...timeArr, timeObj];
            } else {
              let timeObj = {
                id: item.id,
                schedule: 'immediate',
              }
      
              timeArr = [...timeArr, timeObj];
            }
          } else {
            let timeObj = {
              id: item.id,
              schedule: (item.posts[postArr[0]]?.schedule_time && !isEmpty(item.posts[postArr[0]]?.schedule_time)) ? item.posts[postArr[0]]?.schedule : 'immediate',
              ...(item.posts[postArr[0]]?.schedule_time && !isEmpty(item.posts[postArr[0]]?.schedule_time)) && {
                schedule_time: item.posts[postArr[0]]?.schedule_time
              }
            }

            timeArr = [...timeArr, timeObj];
          }
        }
       
      })
      console.log(timeArr)
      setTimeBlocks(timeArr);
      campaignSetState({
        selectedTimeBlocks: timeArr
      })
    } else {
      setTimeBlocks(selectedTimeBlocks)
    }

  }

  const createSubCampaigns = (type) => {
    setLoading(true);
    let payload = {
      series: JSON.stringify({
        id: seriesId
      }),
      block: type
    }
    createCampaign(payload).then(res => {
      fetchSubCampaigns();
      let timeObj = {
        id: res?.result?.data?.campaign_id,
        schedule: 'immediate',
      }

      if(isEmpty(timeBlocks)) {
        setTimeBlocks([timeObj]);
        campaignSetState({
          selectedTimeBlocks: [timeObj]
        })
      } else {
        setTimeBlocks([...timeBlocks, timeObj]);
        campaignSetState({
          selectedTimeBlocks: [...timeBlocks, timeObj]
        })
      }
      setShowDropdown(!showDropdown);
    }).catch(err => {
      setShowDropdown(!showDropdown);
      setLoading(false);
      console.log(err)
    });
  }


  const handleNextClick = async () => {
    if(isEmpty(timeBlocks)) {
      return
    }
    setNextPageLoading(true);
    if(reviewType === 'start') {
      const emailBlocks = blocks.filter(b => b.is_email === 'true');
      if(!isEmpty(emailBlocks)) {
        console.log('defaultRows', defaultRows)
        emailBlocks.forEach(async (e) => {
          const template = await getAdvisorRows(JSON.parse(e?.template?.template_json), defaultRows);
          let updateHtmlPayload = {
            template_json: new File(
              [JSON.stringify(template)],
              'template.json'
            ),
          };
          if (template) {
            await updateHtml({ id: e.id, payload: updateHtmlPayload });
          }
        })
      }
    }
    let campaigns = {};

    blocks.forEach((item, index) => {
      let currentTimeBlock = timeBlocks.filter(t => t.id === item.id)[0];
      campaigns[item.id] = {
        ...(item.is_email === "true" && !isEmpty(setObjectivesTabData.emList)) && {
          "email" : {
            schedule: currentTimeBlock.schedule,
            ...(currentTimeBlock.schedule === 'immediate') && {
              schedule_time: setObjectivesTabData.emList.map((item, index) => {
                return {
                  destination_id: item.id
                }
              })
            },
            ...(currentTimeBlock.schedule === 'specific_time') && {
              schedule_time:  setObjectivesTabData.emList.map((item, index) => {
                return {
                  destination_id: item.id,
                  time: currentTimeBlock.schedule_time
                }
              })
            },
            ...(currentTimeBlock.schedule === 'different_time') && {
              schedule_time: currentTimeBlock.schedule_time
            },
            snetwork_act_id: getAccountId('em')
          }
        },
        ...(item.is_email === 'true' && !isEmpty(setObjectivesTabData.segmentList)) && {
          "segment" : {
            schedule: currentTimeBlock.schedule,
            ...(currentTimeBlock.schedule === 'immediate') && {
              schedule_time: setObjectivesTabData.segmentList.map((item, index) => {
                return {
                  destination_id: item.id
                }
              })
            },
            ...(currentTimeBlock.schedule === 'specific_time') && {
              schedule_time:  setObjectivesTabData.segmentList.map((item, index) => {
                return {
                  destination_id: item.id,
                  time: currentTimeBlock.schedule_time
                }
              })
            },
            ...(currentTimeBlock.schedule === 'different_time') && {
              schedule_time: currentTimeBlock.schedule_time
            },
            snetwork_act_id: getAccountId('em')
          }
        },
        ...(item.is_facebook === "true") && {
          "facebook" : {
            schedule: currentTimeBlock.schedule,
            ...(currentTimeBlock.schedule === 'immediate') && {
              schedule_time: setObjectivesTabData.fbList.map((item, index) => {
                return {
                  destination_id: item
                }
              })
            },
            ...(currentTimeBlock.schedule === 'specific_time') && {
              schedule_time: setObjectivesTabData.fbList.map((item, index) => {
                return {
                  destination_id: item,
                  time: currentTimeBlock.schedule_time
                }
              })
            },
            snetwork_act_id: getAccountId('fb')
          }
        },
        ...(item.is_linkedin === "true") && {
          "linkedin" : {
            schedule: currentTimeBlock.schedule,
            ...(currentTimeBlock.schedule === 'immediate') && {
              schedule_time: setObjectivesTabData.lnList.map((item, index) => {
                return {
                  destination_id: item
                }
              })
            },
            ...(currentTimeBlock.schedule === 'specific_time') && {
              schedule_time: setObjectivesTabData.lnList.map((item, index) => {
                return {
                  destination_id: item,
                  time: currentTimeBlock.schedule_time
                }
              })
            },
            snetwork_act_id: getAccountId('ln')
          }
        },
        ...(item.is_twitter === "true") && {
          "twitter" : {
            schedule: currentTimeBlock.schedule,
            ...(currentTimeBlock.schedule === 'immediate') && {
              schedule_time: setObjectivesTabData.twList.map((item, index) => {
                return {
                  destination_id: item
                }
              })
            },
            ...(currentTimeBlock.schedule === 'specific_time') && {
              schedule_time: setObjectivesTabData.twList.map((item, index) => {
                return {
                  destination_id: item,
                  time: currentTimeBlock.schedule_time
                }
              })
            },
            snetwork_act_id: getAccountId('tw')
          }
        }
      }
      
    });


    let payload = {
      campaigns: JSON.stringify(campaigns)
    }

    scheduleCampaign(seriesId, payload).then(res => {
      setNextPageLoading(false);
      setStep(step + 1);
      campaignSetState({
        activeIndex: step + 1
      })
    }).catch(err => {
      console.log(err);
      setNextPageLoading(false);
    })
    
  };
  
  return (
    <Flex width="80%" margin="0px auto 100px" flexDirection="column">
      <Flex width="100%" alignItems="center">
        <WhiteCircle height="40px" width="40px" background="#816EF8">
          <span
            style={{
              color: '#fff',
              fontSize: '16px',
              position: 'absolute',
              top: '6px',
              left: '0px',
              width: '100%',
            }}
          >
            2
          </span>
        </WhiteCircle>
        <Flex fontSize="20px" color="#101828" style={{ fontWeight: 500 }}>
          Customize Campaign
        </Flex>
      </Flex>
      <hr style={{ width: '100%', border: '1px solid #E4E7EC' }}></hr>
      <Flex width="100%" flexDirection="column">
        <StepTwoHeaderPart />
        <LoaderWrapper isLoading={loading}>
          {
            !isEmpty(blocks) &&
            blocks
              .map((item, index) => (
                <Flex
                  margin="15px 0px 0px"
                  alignItems="center"
                  width="100%"
                  style={{ position: 'relative' }}
                >
                  <Flex width="5%" flexDirection="column">
                    <WhiteCircle
                      background="#E4E7EC"
                      height="16px"
                      width="16px"
                      style={{ zIndex: 5, border: 'none' }}
                    />
                    <Line style={{ left: '6px', top: '5px' }} />
                  </Flex>
                  <Flex width="45%" flexDirection="column">
                    <DataCard 
                      item={item} 
                      step={step}
                      onDeleteClick={handleDeleteClick}
                      onCloneClick={handleCloneClick}
                      onPreviewClick={handleEmailPreviewClick}
                      onSocialPreviewClick={handleSocialPreviewClick}
                      reviewType={reviewType}
                      setObjectivesTabData={setObjectivesTabData}
                    />
                  </Flex>
                  <Flex width="5%" alignItems="center">
                    <Line />
                  </Flex>
                  {item?.is_email === "true" ? (
                    <EmailDateBlock 
                      item={item}
                      timeBlocks={timeBlocks}
                      changeTimeSlots={changeTimeSlots}
                      data={data}
                      userData={userData}
                      isDatepickerOpen={isDatepickerOpen}
                      selectedTimeBlock={selectedTimeBlock}
                      setIsDatepickerOpen={setIsDatepickerOpen}
                      setSelectedTimeBlock={setSelectedTimeBlock}
                      updateSpecificeTimeBlock={updateSpecificeTimeBlock}
                    />
                  ) : (
                    <SocialDateBlock 
                      item={item}
                      timeBlocks={timeBlocks}
                      changeTimeSlots={changeTimeSlots}
                      userData={userData}
                      isDatepickerOpen={isDatepickerOpen}
                      selectedTimeBlock={selectedTimeBlock}
                      setIsDatepickerOpen={setIsDatepickerOpen}
                      setSelectedTimeBlock={setSelectedTimeBlock}
                      updateSpecificeTimeBlock={updateSpecificeTimeBlock}
                      socialInsights={socialInsights}
                    />
                  )}

                </Flex>
              ))}
        </LoaderWrapper>


        <Flex
          pb="100px"
          alignItems="center"
          width="100%"
          style={{ position: 'relative' }}
        >
          <Flex width="6%" flexDirection="column">
            <WhiteCircle
              background="#E4E7EC"
              height="16px"
              width="16px"
              style={{ zIndex: 5, border: 'none' }}
            />
            {/* <Line
              border="3px dashed #E4E7EC"
              style={{ left: '6px', top: '5px' }}
            /> */}
          </Flex>
          {!reviewType && (
            <Flex width="95%" style={{ position: 'relative' }}>
              <Button
                borderColor="#E4E7EC"
                color="#fff"
                bgColor="#816EF8"
                margin="20px 0px 0px"
                borderRadius="8px"
                onClick={() => setShowDropdown(!showDropdown)}
              >
                <Flex
                  justifyContent="space-between"
                  width="100%"
                  alignItems="center"
                >
                  <FontAwesomeIcon style={{ color: '#fff' }} icon={faPlus} />
                  <Flex ml="5px" mr="5px">
                    Add New Block
                  </Flex>
                  <FontAwesomeIcon style={{ color: '#fff' }} icon={faAngleDown} />
                </Flex>
              </Button>
              {showDropdown && (
                <Flex
                  width="15.4%"
                  flexDirection="column"
                  backgroundColor="#fff"
                  alignItems="center"
                  ref={dropdownRef}
                  style={{
                    position: 'absolute',
                    top: '111%',
                    border: '1px solid #E4E7EC',
                    boxShadow: '0px 4px 8px 0px #1018281A',
                  }}
                >
                  {setObjectivesTabData?.is_email === "true" && (
                    <Flex
                      width="100%"
                      padding="12px"
                      fontSize="14px"
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        createSubCampaigns('email')
                      }}
                      color="#101828"
                    >
                      Email Block
                    </Flex>
                  )}

                  {(setObjectivesTabData?.is_facebook === "true" || setObjectivesTabData?.is_twitter === "true" || setObjectivesTabData?.is_linkedin === "true") && (
                    <Flex
                      width="100%"
                      padding={setObjectivesTabData?.is_email === "true" ? "0px 12px 12px" : "12px"}
                      fontSize="14px"
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        createSubCampaigns('social')
                      }}
                      color="#101828"
                    >
                      Social Media Block
                    </Flex>
                  )}

                </Flex>
              )}
            </Flex>
          )}
         
        </Flex>
      </Flex>
      {/* <Flex alignItems="center" width="100%" style={{ position: 'relative' }}>
        <Flex width="5%" flexDirection="column">
          <WhiteCircle
            background="#E4E7EC"
            height="16px"
            width="16px"
            style={{ zIndex: 2, border: 'none' }}
          />
        </Flex>
        <Flex width="95%" flexDirection="column">
          <Flex fontSize="16px" color="#101828" style={{ fontWeight: 500 }}>
            Contact Exits Campaign
          </Flex>
        </Flex>
      </Flex> */}
      {openCustomDateTimeModal && (
        <CustomDateTimeModal 
          isOpen={openCustomDateTimeModal} 
          handleClose={() => {
            setOpenCustomDateTimeModal(false);
            changeTimeSlots(timeBlocks.filter(t => t.id === selectedId && t.schedule === 'different_time')[0], 'immediate')
            setSelectedId();
          }}
          handleNext={() => {
            setOpenCustomDateTimeModal(false);
            setSelectedId();
          }} 
          setIsDatepickerOpen={setIsDatepickerOpen} 
          isDatepickerOpen={isDatepickerOpen} 
          userData={userData}
          setObjectivesTabData={setObjectivesTabData} 
          handleDifferentTimes={handleDifferentTimes}
          timeBlock={timeBlocks.filter(t => t.id === selectedId && t.schedule === 'different_time')}
        />
      )}
       {openDeleteModal && (
        <ConfirmationModal
          modalTitle="Are you sure you want to delete this block?"
          modalWidth="30%"
          clickHandler={campaignBlockDeleteHandler}
          okBtnText="Yes"
          noButton={false}
          onlyShowOk={false}
          noClose
          btnMargin="0px"
          closeHandler={() => closeConfModal()}
          mt="35px"
          loader={requestLoading}
        />
      )}

      {openCloneModal && (
        <ConfirmationModal
          modalTitle="Are you sure you want to clone this block?"
          modalWidth="30%"
          clickHandler={campaignBlockCloneHandler}
          okBtnText="Yes"
          noButton={false}
          onlyShowOk={false}
          btnMargin="0px"
          closeHandler={() => closeConfModal()}
          mt="35px"
          noClose
          loader={requestLoading}
        />
      )}

      {openPreviewModal && (
        <AutomatedCampaignEmailPreviewModal
          isOpen={openPreviewModal}
          handleClose={() => closeConfModal()}
          campaign_id={selectedId?.id}
          template_html={selectedId?.template?.template_html}
          subject={selectedId?.customized_data?.subject}
          name={selectedId?.name}
          campaignFlow
        />
      )}
      {openSocialPreviewModal && (
        <SocialPreviewModal 
          isOpen={openSocialPreviewModal}
          handleClose={() => closeConfModal()}
          campaign_id={selectedId?.id}
          title={''}
          is_facebook={selectedId?.is_facebook}
          is_twitter={selectedId?.is_twitter}
          is_linkedin={selectedId?.is_linkedin}
          campaignFlow
        />
      )}
      <StepsFooterV2
        loader={nextPageLoading}
        currentStepIndex={step}
        setCurrentStepIndex={setStep}
        handleNext={() =>
          handleNextClick()
        }
        disableNext={handleValidation()}
        endCreateFlow={endCreateFlow}
        campaignFlow
        campaignSetState={campaignSetState}
      />
    </Flex>
  );
};

const mapStateToProps = state => {
  return {
    selectedTimeBlocks: state?.campaign?.selectedTimeBlocks
  }
}

export default connect(mapStateToProps, null)(CustomizeCampaignV3);
