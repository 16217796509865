import React, { useState, useEffect } from 'react';
import { Flex } from '@rebass/grid';
import {
  ArticleCard,
  ArticleCardTopic,
  ArticleCardTitle,
  ArticleCardDescription,
} from './Survey.styles';
import { onImageError, getMediaUrl } from '../../utils';
import ArticleDetailPopup from './ArticleDetailPopup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar, faTimes } from '@fortawesome/free-solid-svg-icons';
import { isEmpty } from 'lodash';

const Article = ({
  articleId,
  topic,
  title,
  desc,
  image,
  likedArticles,
  setLikedArticles,
  dislikedArticles,
  setDislikedArticles,
  articleViewedAction,
  articleLikedAction,
  articleDislikedAction,
  testId,
}) => {
  const [detailPopup, setDetailPopup] = useState(false);
  const [openTimestamp, setOpenTimestamp] = useState();

  // console.log('image', image);

  const handleClose = () => {
    let duration = new Date() - openTimestamp;
    // console.log('duration', duration);
    articleViewedAction({ articleId: articleId, duration: duration });
    setDetailPopup(false);
  };

  return (
    <>
      <ArticleCard
        data-testid={testId}
        onClick={() => {
          setOpenTimestamp(new Date());
          setDetailPopup(true);
        }}
        style={{ cursor: 'pointer' }}
      >
        <img
          alt="share"
          src={image}
          height="100px"
          onError={onImageError}
          style={{
            borderTopLeftRadius: '5px',
            borderTopRightRadius: '5px',
            objectFit: 'cover',
          }}
        />
        <Flex flexDirection="column" padding="10px">
          <Flex justifyContent="space-between" alignItems="center">
            <ArticleCardTopic>{topic}</ArticleCardTopic>
            {!isEmpty(likedArticles) && likedArticles.includes(articleId) && (
              <FontAwesomeIcon
                icon={faStar}
                size="0.3x"
                style={{ marginRight: '5px' }}
              />
            )}
            {!isEmpty(dislikedArticles) &&
              dislikedArticles.includes(articleId) && (
                <FontAwesomeIcon
                  icon={faTimes}
                  size="0.3x"
                  style={{ marginRight: '5px' }}
                  color="red"
                />
              )}
          </Flex>

          <ArticleCardTitle>
            {title && title.length > 45 ? (
              <span>
                {title.substring(0, 45)}
                ...
              </span>
            ) : (
              title
            )}
          </ArticleCardTitle>
          <ArticleCardDescription>
            {desc && desc.length > 75 ? (
              <span>
                {desc.substring(0, 75)}
                <span style={{ color: '#6351ed' }}>... Read more</span>
              </span>
            ) : (
              desc
            )}
          </ArticleCardDescription>
        </Flex>
      </ArticleCard>
      {detailPopup && (
        <ArticleDetailPopup
          handleClose={handleClose}
          articleId={articleId}
          topic={topic}
          title={title}
          desc={desc}
          image={image}
          likedArticles={likedArticles}
          setLikedArticles={setLikedArticles}
          dislikedArticles={dislikedArticles}
          setDislikedArticles={setDislikedArticles}
          articleLikedAction={articleLikedAction}
          articleDislikedAction={articleDislikedAction}
        />
      )}
    </>
  );
};

export default Article;
