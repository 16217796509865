import React, { useState, useRef, useEffect } from 'react';

import { EmailListDropdownMenu, EmailListDropdownWrapper } from './EmailListDropDown.styles';

const EmailListDropDown = ({
  children,
  openOnHover,
  fullWidth,
  type,
  background,
  dropdownMenu: DropdownMenu,
  hoverBackground,
  customStyle,
  paddingStyle,
  notClose,
  setClickCheck
}) => {
  const menuWrapper = useRef(null);
  const menuListWrapper = useRef(null);
  const [menuOpenClass, setMenuOpenClass] = useState(notClose);
  const [menuListStyle, setMenuListStyle] = useState({});
  const targetEventsProps = {};
  console.log(menuOpenClass);
  console.log(notClose);
  const handleClickOutside = (e) => {
    if (menuWrapper.current && !menuWrapper.current.contains(e.target)) {
      setClickCheck('');
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setMenuOpenClass(notClose);
  }, [notClose])

  const onMenuMouseOver = () => {
    const menuParentWrapper = menuWrapper.current.clientWidth;
    const menuItemsWrapper = menuListWrapper.current.clientWidth;
    if(type !== 'date') {
    /*setMenuListStyle({
      left: `${(menuParentWrapper - menuItemsWrapper) / 2}px`,
    });*/
    }
  };

  return (
    <EmailListDropdownWrapper
      ref={menuWrapper}
      fullWidth={fullWidth}
      openOnHover={openOnHover}
      style={paddingStyle}
      onMouseOver={onMenuMouseOver}
      {...targetEventsProps}
    >
      {children}
      <EmailListDropdownMenu
        background={background}
        hoverBackground={hoverBackground}
        ref={menuListWrapper}
        className={`dropdown-menu ${menuOpenClass}`}
        style={customStyle}
        type={type}
      >
        {DropdownMenu}
      </EmailListDropdownMenu>
    </EmailListDropdownWrapper>
  );
};

export default EmailListDropDown;
