import React, { useState } from 'react';
import { Formik } from 'formik';
import {
  EmailModalBody,
  EmailModalBox,
  TextInput,
  TextLabel,
  ButtonContainer,
} from './EmailSettingsModal.styles';
import PurpleButton from '../../componentsV2/PurpleButton';
import WhiteButton from '../../componentsV2/WhiteButton';
import CustomDropdown from '../../componentsV2/CustomDropdown';
import * as Yup from 'yup';

const smtpPorts = [
  {
    name: 'SSL',
    port: 'ssl',
  },
  {
    name: 'TLS',
    port: 'tls',
  },
  {
    name: 'None',
    port: 'none',
  },
];

const loginSchema = Yup.object().shape({
  name: Yup.string()
    .required('This field is Required'),
  email: Yup.string().email('Invalid email').required('This field is Required'),
  username: Yup.string()
    .required('This field is Required'),
  password: Yup.string()
    .min(4, 'The password is too short')
    .max(12, 'The password is too long')
    .required('This field is Required'),
  smtpHost: Yup.string()
    .required('This field is Required'),
  smtpPort: Yup.string()
    .required('This field is Required'),
});

const EmailSettingsModal = ({
  handleModalSubmit,
  emailErrorMessage,
  closeModal,
}) => {
  const [portSmtp, setPort] = useState({
    name: 'SSL',
    port: 'ssl',
  });

  const handlePortChange = (port) => {
    setPort(port);
  };

  const handleSubmit = (payload) => {
    payload.port = portSmtp.port;
    handleModalSubmit(payload);
  };

  return (
    <div>
      <EmailModalBody>
        <Formik
          initialValues={{
            name: '',
            email: '',
            username: '',
            password: '',
            smtpHost: '',
            smtpPort: '',
          }}
          onSubmit={handleSubmit}
          validationSchema={loginSchema}
        >
          {({ setFieldValue, handleSubmit, handleChange, values, errors, touched }) => (
            <form name="emailsettings">
              <div style={{display: 'table', margin: '10px auto 0'}}>
                <EmailModalBox>
                  <TextLabel>
                    Name
                    <TextInput
                      name="name"
                      id="name"
                      type="text"
                      width='100%'
                      placeholder="From Name"
                      onChange={handleChange}
                      value={values.name}
                      //error={errors.name}
                    />
                    {errors.name && touched.name ? (
                      <div
                        style={{
                          fontSize: '12px',
                          color: 'red',
                          padding: '0px 0px 10px 2px',
                        }}
                      >
                        *{errors.name}
                      </div>
                    ) : null}
                  </TextLabel>
                  <TextLabel>
                    Username
                    <TextInput
                      name="username"
                      id="username"
                      type="text"
                      width='100%'
                      placeholder="Username"
                      onChange={handleChange}
                      value={values.username}
                      //error={errors.username}
                    />
                    {errors.username && touched.username ? (
                      <div
                        style={{
                          fontSize: '12px',
                          color: 'red',
                          padding: '0px 0px 10px 2px',
                        }}
                      >
                        *{errors.username}
                      </div>
                    ) : null}
                  </TextLabel>
                </EmailModalBox>
                <EmailModalBox>
                  <TextLabel>
                    Email
                    <TextInput
                      name="email"
                      id="email"
                      type="text"
                      width='100%'
                      placeholder="From Email"
                      onChange={handleChange}
                      value={values.email}
                      //error={errors.email}
                    />
                    {errors.email && touched.email ? (
                      <div
                        style={{
                          fontSize: '12px',
                          color: 'red',
                          padding: '0px 0px 10px 2px',
                        }}
                      >
                        *{errors.email}
                      </div>
                    ) : null}
                  </TextLabel>
                  <TextLabel>
                    Password
                    <TextInput
                      name="password"
                      id="password"
                      type="password"
                      width='100%'
                      placeholder="Password"
                      onChange={handleChange}
                      value={values.password}
                      //error={errors.password}
                    />
                    {errors.password && touched.password ? (
                      <div
                        style={{
                          fontSize: '12px',
                          color: 'red',
                          padding: '0px 0px 10px 2px',
                        }}
                      >
                        *{errors.password}
                      </div>
                    ) : null}
                  </TextLabel>
                </EmailModalBox>
                <TextLabel style={{ margin: '0px 0 0 10px' }}>
                  <div
                    style={{
                      margin: '-10px 0 0',
                      textAlign: 'left',
                      width: '100%',
                    }}
                  >
                    SMTP Host
                  </div>
                  <TextInput
                    name="smtpHost"
                    id="smptpHost"
                    type="text"
                    width='100%'
                    placeholder="SMTP Host"
                    onChange={handleChange}
                    value={values.smtpHost}
                    //error={errors.smtpHost}
                  />
                  {errors.smtpHost && touched.smtpHost ? (
                    <div
                      style={{
                        fontSize: '12px',
                        color: 'red',
                        padding: '0px 0px 10px 2px',
                      }}
                    >
                      *{errors.smtpHost}
                    </div>
                  ) : null}
                </TextLabel>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    margin: '10px auto 0',
                  }}
                >
                  <TextLabel width='48%'>
                    SMTP Port
                    <TextInput
                      name="smtpPort"
                      id="smtpPort"
                      type="text"
                      width='100%'
                      placeholder="SMTP Port"
                      onChange={handleChange}
                      value={values.smtpPort}
                      //error={errors.smtpPort}
                    /> 
                  </TextLabel>
                  <div style={{ margin: '0px auto', width: '48%' }}>
                    <CustomDropdown
                      dropdownLabel='SMTP Encryption'
                      dropdownItems={smtpPorts}
                      selectedDropdown={portSmtp}
                      setSelectedDropdown={handlePortChange}
                      margin='0 auto 8px'
                      height='40px'
                    />
                  </div>
                </div>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                   {errors.smtpPort && touched.smtpPort ? (
                      <div
                        style={{
                          fontSize: '12px',
                          color: 'red',
                        }}
                      >
                        *{errors.smtpPort}
                      </div>
                    ) : null}
                </div>
              </div>
              {/* {emailErrorMessage && emailErrorMessage.length > 0 && (
                <ErrorContainer style={{ textAlign: 'center' }}>
                  {emailErrorMessage}
                </ErrorContainer>
              )} */}
              <ButtonContainer>
                <WhiteButton
                  width='49%'
                  margin="10px 0"
                  type="submit"
                  onClick={() => closeModal()}
                >
                  Cancel
                </WhiteButton>
                <PurpleButton
                  width='49%'
                  margin="10px 0"
                  type="submit"
                  onClick={handleSubmit}
                >
                  Save Settings
                </PurpleButton>
              </ButtonContainer>
            </form>
          )}
        </Formik>
      </EmailModalBody>
    </div>
  );
};

export default EmailSettingsModal;
