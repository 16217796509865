/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Flex, Box } from '@rebass/grid';
import _, { get } from 'lodash';
import { connect } from 'react-redux';
import { getRoleType, getUserRole } from '../../redux/selector/login.selectors'; 
import {
  Tabs,
  TabList,
  TabTypePublisher,
  TabTypeMemberPublisher,
} from '../../components';
import {
  StyledTabPanelPublisher,
} from '../ChannelContainer/Channel.styles';
import {
  ChannelWrapper,
  ChannelContainer,
  ActiveTheme,
  SelectThemes,
  SearchCategory,
  AddedChannels,
  SMP,
  SearchResultsContainer,
  SearchResults,
  ChannelsPage,
  IP,
  IPHeader,
  List,
  PremiumPartners,
  PremiumPartnersHeader,
  Suggest,
  SuggestHeader,
  SuggestLink,
  ListWrapper,
  CustomInput,
  CustomModalButton,
  ComponentSection,
  PremiumModalContent,
  SuccessMessage,
} from './Channel.styles';
import {
  Modal,
  ModalBody,
  ModalHeader,
  ModalCloseButton,
  ListItemTile,
} from '../../components';

import { actions as channelActions } from '../../redux/modules/channel.module';
// import loader from 'public/assets/loaderV.gif';

import { SearchInput } from '../../components';
import { Formik } from 'formik';
import {
  postUnsubscribeChannel,
  postSubscribeChannel,
  postSuggestPartner,
  getChannelList,
  getAdminChannelData,
  getMyMemberChannel,
  postMyMemberChannel,
  postMyPublisherSubscribeChannel,
  postMyPublisherUnsubscribeChannel
} from '../../services/dashboard';
import { ROLE_TYPES, USER_ROLE } from '../../utils/constants';

const APPROVED = 'approved';
const APPLIED = 'applied';
const PREMIUM = 'freemium';
const FREE = 'free';

const Channel = ({ channelRequest, roleType, isAdmin, isEnterpriseAdvisor }) => {
  const [channels, setChannels] = useState([]);
  const [InactivePartnerList, setInactivePartnerList] = useState([]);
  const [YourPartnerList, setYourPartnerList] = useState(null);
  const [YourPartnerMemberList, setYourPartnerMemberList] = useState(null);
  const [YourMemberPartnerList, setYourMemberPartnerList] = useState(null);
  const [PremiumPartnerList, setPremiumPartnerList] = useState(null);
  const [updateList, setUpdatedList] = useState(false);
  const [suggestModal, setSuggestModal] = useState(false);
  const [premiumModal, setPremiumModal] = useState(false);
  const [searchResults, setSearchResults] = useState(null);
  const [suggestPartner, setSuggestPartner] = useState(null);
  const [showMoreInactivePartners, setShowMoreInactivePartners] = useState(false);
  const [selectedIndex, setTabIndex] = useState(0);
  const [allowed, setAllowed] = useState(0);

  console.log("YourMemberPartnerList--->", YourMemberPartnerList);
  console.log("YourPartnerList--->", YourPartnerList);
  console.log("YourPartnerMemberList--->", YourPartnerMemberList)
  console.log("channel --->", channels)

  useEffect(() => {
    if (isAdmin) {
      getAdminChannelData().then((res) => {
        setChannels(_.map(_.values(res.result.data), (item) => item[0]));
      });
    }
    else {
      getChannelList().then((res) => {
        setChannels(_.map(_.values(res.result.data), (item) => item[0]));
      });
    }
  }, []);

  useEffect(() => {
    let ipList = [],
      ypList = [],
      pList = [];

    channels.forEach((channel) => {
      if (selectedIndex === 1) {
        if (get(channel, 'allowed') === true) {
          ypList.push(channel);
        } else if (get(channel, 'allowed') === false &&
          get(channel, 'subscription_type') === FREE && get(channel, 'is_hidden') === "false") {
          ipList.push(channel);
        } else if (get(channel, 'allowed') === true) {
          ipList.push(channel);
        }
        if (get(channel, 'subscription_type') === PREMIUM) {
          pList.push(channel);
        }
      } else if (selectedIndex === 0) {
        if (isEnterpriseAdvisor) {
          if (get(channel, 'subscription_type') === PREMIUM) {
            pList.push(channel);
          } else if (
            get(channel, 'subscription_type') === FREE &&
            !get(channel, 'subscription.status.application_status') &&
            get(channel, 'is_hidden') === 'false'
          ) {
            ipList.push(channel);
          } else if (
            get(channel, 'subscription_type') === FREE &&
            get(channel, 'subscription.status.application_status') === APPROVED &&
            get(channel, 'allowed')
          ) {
            ypList.push(channel);
          }
        } else {
          if (get(channel, 'subscription_type') === PREMIUM) {
            pList.push(channel);
          } else if (
            get(channel, 'subscription_type') === FREE &&
            !get(channel, 'subscription.status.application_status') &&
            get(channel, 'is_hidden') === 'false'
          ) {
            ipList.push(channel);
          } else if (
            get(channel, 'subscription_type') === FREE &&
            get(channel, 'subscription.status.application_status') === APPROVED
          ) {
            ypList.push(channel);
          }
        }
      }

      //For match my publishers
      /*if (selectedIndex === 1 & isChecked && 
        get(channel, 'allowed') === false && 
        get(channel, 'subscription_type') === FREE &&
        get(channel, 'subscription.status.application_status') === APPROVED) {
          ypList.push(channel);
      }*/

      // else if (
      //   get(channel, 'allowed') === true
      // ) {
      //   ypList.push(channel);
      // }

      setInactivePartnerList(ipList);
      if (selectedIndex === 0) {
        setYourPartnerList(ypList);
      }
      if (selectedIndex === 1) {
        setYourPartnerMemberList(ypList);
      }
      setPremiumPartnerList(pList);
    });
  }, [channels, selectedIndex]);

  const toggleSuggestPartnerModal = () => {
    setSuggestModal(!suggestModal);
  };

  const togglePremiumModal = () => {
    setPremiumModal(!premiumModal);
    setTimeout(() => {
      setPremiumModal(false);
    }, 2000);
  };

  const handleSuggestPartnerSubmit = (values) => {
    const { name, email, sc_Link } = values;
    const payload = {
      to_json: [{ name: name, email: email }],
      subject: 'New Partner',
      body: 'subscribe here',
      sc_Link: sc_Link,
    };
    postSuggestPartner(payload)
      .then((res) => {
        setSuggestPartner(res);
        setTimeout(() => {
          setSuggestModal(!suggestModal);
        }, 3000);
      })
      .catch((err) => { });
  };

  const handleYourPartnerClick = (id, index) => {
    postUnsubscribeChannel(id)
      .then((res) => {
        if (isAdmin) {
          getAdminChannelData().then((res) => {
            // console.log('CHANNEL API RESULT - ', res);
            setChannels(_.map(_.values(res.result.data), (item) => item[0]));
          });
        }
        else {
          getChannelList().then((res) => {
            setChannels(_.map(_.values(res.result.data), (item) => item[0]));
          });
        }
      })
      .catch((err) => { });
  };

  const handleYourMyMemberPartnerClick = (id, index) => {
    postMyMemberChannel(id, {
      allowed: false
    }).then(res => {
      getMyMemberChannelCall();
    });
  };

  const postMyPublisherSubscribeChannelClick = (id, index) => {
    postMyPublisherSubscribeChannel(id)
      .then((res) => {
        getAdminChannelData().then((res) => {
          console.log('subscriberesponse', res);
          setChannels(_.map(_.values(res.result.data), (item) => item[0]));
        });
      });
  };

  const postMyPublisherUnsubscribeChannelClick = (id, index) => {
    postMyPublisherUnsubscribeChannel(id)
      .then((res) => {
        getAdminChannelData().then((res) => {
          console.log('unsubscriberesponse', res);
          setChannels(_.map(_.values(res.result.data), (item) => item[0]));
        });
      });
  };

  const handleInactiveMyPartnerClick = (id, index, type) => {
    postMyMemberChannel(id, {
      allowed: true
    }).then(res => {
      getMyMemberChannelCall();
    });
  };

  const handleInactivePartnerClick = (id, index) => {
    postSubscribeChannel(id)
      .then((res) => {
        if (isAdmin) {
          getAdminChannelData().then((res) => {
            // console.log('CHANNEL API RESULT - ', res);
            setChannels(_.map(_.values(res.result.data), (item) => item[0]));
          });
        }
        else {
          getChannelList().then((res) => {
            setChannels(_.map(_.values(res.result.data), (item) => item[0]));
          });
        }
      })
      .catch((err) => {
        togglePremiumModal(get(err, 'result.error.message'));
      });
  };

  const getMyMemberChannelCall = () => {
    getMyMemberChannel().then((res) => {
      const newResponse = Object.keys(res.result.data).map(key => {
        return { ...res.result.data[key][0], channelId: key[allowed] }
      })
      setChannels(newResponse);
    });
  }

  const onTabClick = (index) => {
    setTabIndex(index);
    if (index === 0) {
      getAdminChannelData().then((res) => {
        setChannels(_.map(_.values(res.result.data), (item) => item[0]));
      });
    }
    if (index === 1) {
      //setIsChecked(false);
      getMyMemberChannelCall();
    }
  };

  /*const handleMatchMyPublisher = (isChecked) => {
    if (selectedIndex === 1) {
      setIsChecked(isChecked);
      if (isChecked === true) {
        getAdminChannelData().then((res) => {
          setChannels(_.map(_.values(res.result.data), (item) => item[0]));
        });
      } else {
        getMyMemberChannelCall();
      }
    }
  }*/

  const handlePremiumPartnerClick = (channel, index) => {
    if (!get(channel, 'subscription.status')) {
      postSubscribeChannel(channel.id)
        .then((res) => {
          setUpdatedList(!updateList);
          togglePremiumModal('Requested access to this partner.');
        })
        .catch((err) => { });
    } else if (
      get(channel, 'subscription.status.application_status') === APPLIED
    ) {
      togglePremiumModal('You have already requested access to this partner.');
    } else if (
      get(channel, 'subscription.status.application_status') === APPROVED
    ) {
      togglePremiumModal('You have already subscribed to this channel.');
    }
  };

  const searchByText = (collection, text) => {
    if (text === '') {
      setSearchResults(null);
      return;
    }
    text = _.toLower(text);
    const result = _.filter(collection, function (object) {
      return _(object).some(function (string) {
        return _(string).toLower().includes(text);
      });
    });
    if (
      result.length === 1 &&
      _.find((selectedIndex === 0 ? YourPartnerList : YourMemberPartnerList), { id: result[0].id }) !== undefined
    ) {
      setSearchResults([
        { alert: 'Your are already subscribed to this partner' },
      ]);
      return;
    }
    setSearchResults(result);
  };

  const getImages = (channel) => {
    if (channel.media_urls && channel.media_urls[0] && channel.media_urls[0].path && channel.media_urls[0].name.resized && channel.media_urls[0].ext)
      return `${channel.media_urls[0].path}/${channel.media_urls[0].name.resized}.${channel.media_urls[0].ext}`;
    else
      return '';
  }

  return (
    <ChannelWrapper>
      <ChannelContainer>
        <Tabs onSelect={onTabClick}>
          <TabList>
            {!isAdmin ?
              <TabTypePublisher style={{color: '#6351ed'}}>My Publishers</TabTypePublisher> :
              <><TabTypePublisher currentTab={selectedIndex === 0}>My Publishers</TabTypePublisher>
                <TabTypeMemberPublisher currentTab={selectedIndex === 1}>My Members' Publishers</TabTypeMemberPublisher></>
            }
          </TabList>

          <StyledTabPanelPublisher>
            <ActiveTheme>
              <SelectThemes>
                <SearchCategory>
                  <AddedChannels>
                    <Flex
                      justifyContent="center"
                      alignItems="center"
                      width={1}
                      flexWrap="wrap"
                    >
                      {YourPartnerList ? (
                        YourPartnerList.map((channel, index) => {
                          if (channel.is_hidden === "true") {
                            return;
                          }
                          return (
                            <Box width={`${window.innerWidth < 768 ? '1 / 4' : '1 / 5'}`} key={channel.id}>
                              <ListItemTile
                                id={channel.id}
                                url={getImages(channel)}
                                imageStyle={{background: 'none'}}
                                topic={channel.name}
                                type="minus"
                                handleClick={() => {
                                  if (isAdmin) {
                                    handleYourPartnerClick(channel.id, index)
                                  } else {
                                    postMyPublisherUnsubscribeChannelClick(channel.id, index)
                                  }
                                }
                                }
                              />
                            </Box>
                          )
                        })
                      ) : (
                        <img src="/assets/images/loaderV.gif" alt="loader" />
                      )}
                    </Flex>
                  </AddedChannels>
                </SearchCategory>
              </SelectThemes>
            </ActiveTheme>
          </StyledTabPanelPublisher>

          <StyledTabPanelPublisher>
            <ActiveTheme>
              <SelectThemes>
                <SearchCategory>
                  <AddedChannels>
                    {/*(isAdmin ?
                      <Flex width="100%" flexDirection="column">
                        <Flex width="100%" justifyContent="center" padding="10px 0px 5px" >
                          <Flex width="100%" justifyContent="center">
                            <PublisherCheckBox>
                              <label class="container">
                                <input
                                  type="checkbox"
                                  checked={isChecked}
                                  onChange={() => handleMatchMyPublisher(!isChecked)}
                                />
                                <span class="checkmark"></span>
                                  Match My Publishers
                              </label>
                            </PublisherCheckBox>
                          </Flex>
                        </Flex>
                      </Flex> : <></>
                    )*/}
                    <Flex
                      justifyContent="center"
                      alignItems="center"
                      width={1}
                      flexWrap="wrap"
                    >
                      {console.log('YourPartnerList', YourPartnerList)}
                      {YourPartnerMemberList ? (
                        YourPartnerMemberList.map((channel, index) => {

                          return (
                            <Box width={`${window.innerWidth < 768 ? '1 / 4' : '1 / 5'}`} key={channel.id}>
                              <ListItemTile
                                id={channel.id}
                                url={getImages(channel)}
                                topic={channel.name}
                                imageStyle={{background: 'none'}}
                                type="minus"
                                handleClick={() => {
                                  if (isAdmin) {
                                    if (selectedIndex === 0) {
                                      handleYourPartnerClick(channel.id, index)
                                    } else {
                                      handleYourMyMemberPartnerClick(channel.id, index, 'active')
                                    }
                                  }
                                }
                                }
                              />
                            </Box>
                          )
                        })
                      ) : (
                        <img src="/assets/images/loaderV.gif" alt="loader" />
                      )}
                    </Flex>
                  </AddedChannels>
                </SearchCategory>
              </SelectThemes>
            </ActiveTheme>
          </StyledTabPanelPublisher>
        </Tabs>

        {/* <ActiveTheme>
          <ChannelsHeader>My Publishers</ChannelsHeader>
          <SelectThemes>
            <SearchCategory>
              <AddedChannels>
                <Flex
                  justifyContent="center"
                  alignItems="center"
                  width={1}
                  flexWrap="wrap"
                >
                  {YourPartnerList ? (
                    YourPartnerList.map((channel, index) => {
                      if(channel.is_hidden === "true") {
                        return ;
                      }
                      return (
                        <Box width={`${window.innerWidth < 768 ? '1 / 4' : '1 / 5'}`} key={channel.id}>
                          <ListItemTile
                            id={channel.id}
                            url={getImages(channel)}
                            topic={channel.name}
                            type="minus"
                            handleClick={() =>
                              handleYourPartnerClick(channel.id, index)
                            }
                          />
                        </Box>
                      )
                    })
                  ) : (
                    <img src="/assets/images/loaderV.gif" alt="loader" />
                  )}
                </Flex>
              </AddedChannels>
            </SearchCategory>
          </SelectThemes>
        </ActiveTheme> */}
        <SMP>Find More Publishers</SMP>

        <SearchInput
          name="partnersearch"
          id="partnersearch"
          placeholder="Looking for a particular publisher? ('e.g.' : 'Seeking Alpha')"
          largeInput
          onSearchClick={(value) => searchByText(channels, value)}
          onChange={(value) => {
            if (value.trim() === '') {
              searchByText(channels, value);
            }
          }}
         
        />
        <SearchResultsContainer>
          <ChannelsPage>
            <SearchResults>
              <List>
                <Flex
                  justifyContent="center"
                  alignItems="center"
                  width={1}
                  flexWrap="wrap"
                >
                  {searchResults ? (
                    searchResults.length !== 0 ? (
                      searchResults.length === 1 && !!searchResults[0].alert ? (
                        searchResults[0].alert
                      ) : (
                        searchResults.map((channel, index) => {
                           if (channel.is_hidden === "true") {
                             return;
                           }

                          return (
                            <Box width={`${window.innerWidth < 768 ? 1 / 2 : 1 / 5}`} key={channel.id}>
                              <ListItemTile
                                id={channel.id}
                                url={getImages(channel)}
                                imageStyle={{background: 'none'}}
                                topic={channel.name}
                                type="add"
                                handleClick={() => {
                                  if (isAdmin) {
                                    if (selectedIndex === 0) {
                                      handleInactivePartnerClick(channel.id, index)
                                    } else {
                                      handleInactiveMyPartnerClick(channel.id, index, 'inactive')
                                    }
                                  } else {
                                    postMyPublisherSubscribeChannelClick(channel.id, index)
                                  }
                                }}
                              />
                            </Box>
                          )
                        })
                      )
                    ) : (
                      <div>No Publishers Found</div>
                    )
                  ) : null}
                </Flex>
              </List>
            </SearchResults>
          </ChannelsPage>
        </SearchResultsContainer>
        {console.log('IP LIST = ', InactivePartnerList)}
        {InactivePartnerList.length > 0 &&
          <IP>
            <IPHeader>Inactive Publishers</IPHeader>
            <ListWrapper>
              <List>
                <Flex
                  justifyContent="center"
                  alignItems="center"
                  width={1}
                  flexWrap="wrap"
                >
                  {InactivePartnerList ? (
                    showMoreInactivePartners ?
                      InactivePartnerList.map((channel, index) => {
                        return (
                          <Box width={`${window.innerWidth < 768 ? 1 / 2 : 1 / 5}`} key={channel.id} >
                            <ListItemTile
                              id={channel.id}
                              url={getImages(channel)}
                              topic={channel.name}
                              bgColor=''
                              /*type={isAdmin ? "add" : 'no-icon'}*/
                              type={(isAdmin || (isEnterpriseAdvisor && channel.allowed === true)) ? "add" : 'no-icon'}
                              isMember={(isEnterpriseAdvisor && channel.allowed === false)}
                              handleClick={() => {
                                if (isAdmin) {
                                  if (selectedIndex === 0) {
                                    handleInactivePartnerClick(channel.id, index)
                                  } else {
                                    handleInactiveMyPartnerClick(channel.id, index, 'inactive')
                                  }
                                } else {
                                  postMyPublisherSubscribeChannelClick(channel.id, index)
                                }
                              }}
                            />
                          </Box>
                        )
                      })
                      :
                      InactivePartnerList.slice(0, 5).map((channel, index) => {
                        return (
                          <Box width={`${window.innerWidth < 768 ? 1 / 2 : 1 / 5}`} key={channel.id}>
                            <ListItemTile
                              id={channel.id}
                              url={getImages(channel)}
                              imageStyle={{background: 'none'}}
                              topic={channel.name}
                              /*type={isAdmin ? "add" : 'no-icon'}*/
                              type={(isAdmin || (isEnterpriseAdvisor && channel.allowed === true)) ? "add" : 'no-icon'}
                              isMember={(isEnterpriseAdvisor && channel.allowed === false)}
                              handleClick={() => {
                                if (isAdmin) {
                                  if (selectedIndex === 0) {
                                    handleInactivePartnerClick(channel.id, index)
                                  } else {
                                    handleInactiveMyPartnerClick(channel.id, index, 'inactive')
                                  }
                                } else {
                                  postMyPublisherSubscribeChannelClick(channel.id, index)
                                }
                              }}
                            />
                          </Box>
                        )
                      })
                  ) : (
                    <img src="/assets/images/loaderV.gif" alt="loader" />
                  )}
                </Flex>
              </List>
            </ListWrapper>
            {showMoreInactivePartners ?
              <Box style={{ cursor: 'pointer' }} onClick={() => setShowMoreInactivePartners(false)}>Show Less...</Box>
              :
              <Box style={{ cursor: 'pointer' }} onClick={() => setShowMoreInactivePartners(true)}>Show More...</Box>
            }
          </IP>
        }
        <PremiumPartners>
          <PremiumPartnersHeader>Premium Publishers</PremiumPartnersHeader>

          <List>
            <Flex
              justifyContent="center"
              alignItems="center"
              width={1}
              flexWrap="wrap"
            >
              {PremiumPartnerList ? (
                PremiumPartnerList.map((channel, index) => {
                  if (channel.is_hidden === "true") {
                    return;
                  }

                  return (
                    <Box width={`${window.innerWidth < 768 ? 1 / 2 : 1 / 5}`} key={channel.id}>
                      <ListItemTile
                        id={channel.id}
                        url={getImages(channel)}
                        imageStyle={{background: 'none'}}
                        topic={channel.name}
                        type="add"
                        handleClick={() => {
                          if (selectedIndex === 0) {
                            handlePremiumPartnerClick(channel, index)
                          }
                        }}
                      />
                    </Box>
                  )
                }
                )
              ) : (
                <img src="/assets/images/loaderV.gif" alt="loader" />
              )}
            </Flex>
            <Modal
              onRequestClose={() => setPremiumModal(false)}
              shouldCloseOnOverlayClick
              isOpen={premiumModal}
              style={{
                overlay: {
                  backgroundColor: '#000000d1',
                },
                fontSize: 12,
              }}
              size="medium"
            >
              <ModalHeader>
                <PremiumModalContent>
                  You have already requested access to this publisher.
                </PremiumModalContent>
              </ModalHeader>
            </Modal>
          </List>
        </PremiumPartners>
        <Suggest>
          <SuggestHeader>
            Can't find a publisher you are looking for?
            <Modal
              onRequestClose={() => setSuggestModal(false)}
              shouldCloseOnOverlayClick
              isOpen={suggestModal}
              style={{
                overlay: {
                  backgroundColor: '#000000d1',
                },
              }}
            >
              <ModalHeader>
                Suggest a Publisher
                <ModalCloseButton onClick={toggleSuggestPartnerModal} />
              </ModalHeader>
              <ModalBody>
                <Formik
                  validateOnChange={false}
                  initialValues={{
                    name: '',
                    email: sessionStorage.getItem('email'),
                    sc_Link: '',
                  }}
                  onSubmit={handleSuggestPartnerSubmit}
                >
                  {({ handleSubmit, handleChange, values = {} }) => (
                    <ComponentSection>
                      <form name="suggestform">
                        <CustomInput
                          name="name"
                          id="name"
                          type="text"
                          placeholder="Enter Publisher Name"
                          onChange={handleChange}
                          value={values.name}
                        />
                        <CustomInput
                          name="sc_Link"
                          id="sc_Link"
                          type="text"
                          placeholder="Enter Publisher Source Link"
                          onChange={handleChange}
                          value={values.sc_link}
                        />
                        <CustomModalButton onClick={handleSubmit} style={{ borderRadius: '0' }}>
                          SUBMIT
                        </CustomModalButton>
                        {suggestPartner ? (
                          <SuccessMessage>
                            Thanks for suggesting a Partner. It has been sent to
                            the administrator for approval.
                          </SuccessMessage>
                        ) : null}
                      </form>
                    </ComponentSection>
                  )}
                </Formik>
              </ModalBody>
            </Modal>
            <SuggestLink onClick={toggleSuggestPartnerModal}>
              Suggest Publisher
            </SuggestLink>
          </SuggestHeader>
        </Suggest>
      </ChannelContainer>
    </ChannelWrapper>
  );
};

const mapStateToProps = (state) => {
  const roleType = getRoleType(state);
  const isAdmin = getUserRole(state) === USER_ROLE.admin;
  const isEnterpriseAdvisor = roleType === ROLE_TYPES.ADVISOR_ENTERPRISE;
  return {
    channels: state.channel.data,
    roleType: roleType,
    isAdmin: isAdmin,
    isEnterpriseAdvisor: isEnterpriseAdvisor
  }
};

const mapDispatchToProps = {
  channelRequest: channelActions.api.channel.request,
};

export default connect(mapStateToProps, mapDispatchToProps)(Channel);

