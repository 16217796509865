import React from 'react';
import ReactSkeleton from 'react-loading-skeleton';
import {
  Contacts,
  ContactsDescription,
  Container,
  Content,
  Header,
} from './styles';

function Skeleton() {
  return (
    <Container>
      <Header>
        <ReactSkeleton width={100} height={20} />
        <ReactSkeleton width={150} height={20} />
      </Header>
      <Content>
        <Contacts>
          <ReactSkeleton width={100} height={20} />
          <ReactSkeleton width={50} height={40} />
          <ContactsDescription>
            <ReactSkeleton width={150} height={20} />
            <ReactSkeleton width={400} height={20} />
          </ContactsDescription>
        </Contacts>
        <ReactSkeleton width={400} height={40} />
      </Content>
    </Container>
  );
}

export default Skeleton;
