import { Box, Flex } from '@rebass/grid';
import React from 'react';
import { ActionsDropdown, StyledCampaignActionBox } from '../styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import OutsideClickHandler from 'react-outside-click-handler';
import { useState } from 'react';
import moment from 'moment';
import { themesData } from '../../../utils';
import styled from 'styled-components';
import { MioDatePickerWrapper } from '../../../components/MioDatePicker/MioDatePicker.styles';
import { MioDatePicker } from '../../../components';
import { get } from 'lodash';

const ActionBox = ({
  styles,
  actions = [],
  mainTitle,
  disableCutomization,
  blockObj,
  forWaitTimeEdit = false,
  eventDate,
  preConversionBlock,
  postConversionBlock,
  index,
  onConversionBlock,
  postConversionBlocks,
  forDashboardPreview,
  userData,
  changeEventBlockTime,
  isLoading,
  isDatepickerOpen,
  setIsDatepickerOpen
}) => {
  const [showActions, setShowActions] = useState(false);
  const [selectedId, setSelectedId] = useState('');

  const calulateScheduleDate = () => {
    if(mainTitle.includes('prior')) {
      let currentDate = new Date(eventDate);
      currentDate = new Date(currentDate.getTime() + Math.abs(currentDate.getTimezoneOffset()*60000));
      let firstBlockDays = +mainTitle.split(' ')[0];
      currentDate.setDate(currentDate.getDate() - firstBlockDays);
      return moment(currentDate).format('MM/DD/YYYY');
    } else {
      let calculatedEventDate = new Date(eventDate);
      calculatedEventDate = new Date(calculatedEventDate.getTime() + Math.abs(calculatedEventDate.getTimezoneOffset()*60000));
      let firstBlockDays = +mainTitle.split(' ')[0];
      calculatedEventDate.setDate(calculatedEventDate.getDate() + firstBlockDays);
      console.log("event date-", moment(calculatedEventDate).format('MM/DD/YYYY'));
      return moment(calculatedEventDate).format('MM/DD/YYYY');
    }
  }

  const postConversionText = () => {
    return `Post-registration Email ${index === 0 ? 1 : (index / 2) + 1}`;
  }

  let maxEventDate;
  if(eventDate) {
    maxEventDate = new Date(eventDate);
    maxEventDate.setDate(maxEventDate.getDate() - 1);
  }

  return (
    <StyledCampaignActionBox
      style={{ ...styles, position: onConversionBlock ? 'relative' : '' }}
      alignItems="center"
    >
      {onConversionBlock && !disableCutomization && (
        <span
          style={{
            position: 'absolute',
            bottom: '105%',
            left: '14%',
            color: themesData.black,
          }}
        >
          Registration Confirmation Email: Sent Immediately
        </span>
      )}
      <Flex width={1} alignItems="center"  justifyContent={eventDate && !disableCutomization ? 'center' : 'space-between'}>
        {/* {eventFirstBlock || eventThirdBlock || eventFifthBlock || eventSeventhBlock || eventNinthBlock ? <span style={{ cursor: 'pointer'}} onClick={() => setIsDatepickerOpen(true)}>Schedule Date & Time</span> : <span>{mainTitle}?</span>} */}
        {eventDate && !disableCutomization ? 
          <span style={{ cursor: 'pointer' }} onClick={() => {
            setSelectedId(blockObj.id);  
            setIsDatepickerOpen(true);
          }}>
            Schedule Date & Time
          </span> 
          : 
          <span>{mainTitle}</span>
        }
        {eventDate && selectedId === blockObj.id && !disableCutomization && ( 
          <MioDatePicker
            isOpen={isDatepickerOpen}
            customInput={<div />}
            minDate={new Date()}
            onDateClose={() => {
              setIsDatepickerOpen(false);
              setSelectedId('');
            }}
            onSubmit={(date) => {
              changeEventBlockTime(date, blockObj);
              setSelectedId('');
            }}
            timeZone={get(userData, 'details.user.timezone', '')}
            scheduleValue={blockObj.schedule_time}
            isFromAC
            maxDate={preConversionBlock ? maxEventDate : null}
            loading={isLoading}
          />
        )}
        {!disableCutomization && (
          <Box className="actions__holder">
            {eventDate && !forDashboardPreview ? (
              ''
            ) : (
              <FontAwesomeIcon
                icon={faEllipsisV}
                color="blue"
                className="cursor-pointer"
                onClick={() => setShowActions(!showActions)}
              />
            )}
            {showActions && (
              <OutsideClickHandler
                onOutsideClick={() => setShowActions(!showActions)}
              >
                <ActionsDropdown
                  className="actions"
                  forWaitTimeEdit={forWaitTimeEdit}
                >
                  {React.Children.toArray(
                    actions.map((action, index) => (
                      <p
                        className="action-item cursor-pointer"
                        onClick={() => action.onClick(blockObj)}
                      >
                        {index === 0 && blockObj && blockObj.newsletter_id
                          ? 'Edit Email'
                          : action.title}
                      </p>
                    ))
                  )}
                </ActionsDropdown>
              </OutsideClickHandler>
            )}
          </Box>
        )}
      </Flex>
      {eventDate && !disableCutomization && (
        <span
          style={{
            position: 'absolute',
            color: themesData.black,
            left: '-7%',
            textAlign:  'left',
          }}
        >
          {preConversionBlock
            ? `Promotional Email ${index === 0 ? 1 : index / 2 + 1}`
            : postConversionText()}
        </span>
      )}

      {eventDate && !disableCutomization && (
        <>
        <Flex display="flex" justifyContent="center" flexDirection="column">
        <span
          style={{ position: 'absolute', left: '73%', width: '72%', color: themesData.black }}
        >
          Scheduled: {mainTitle}
          <br />
          <span style={{ color: '#6351ed'}}>{moment(new Date(blockObj.schedule_time)).format('Do MMMM YYYY, h:mm a')} ({get(userData, `details.user.google_timezones[${get(userData, 'details.user.timezone', '')}]`, '')})
        </span>
        </span>
        
        </Flex>
        </>
      )}
    </StyledCampaignActionBox>
  );
};

export default ActionBox;
