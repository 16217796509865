import useRESTQuery from 'src/hooks/useRESTQuery';
import { transformTopicsResult } from './utils';

function useQueryForTopics() {
  return useRESTQuery({
    key: 'discover-topics',
    api: 'api.clout',
    endpoint:
      '/theme/?fields=theme_id,name,status,description,media_urls,created_time,updated_time',
    options: {
      select: (res) => {
        const result = res?.result?.data;
        if (Array.isArray(result) && result.length > 0) {
          return transformTopicsResult(result);
        }
      },
    },
  });
}

export default useQueryForTopics;
