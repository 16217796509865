import { handleActions } from 'redux-actions';
import { apiModuleDefaultState, createApiActions } from './helpers';

export const actions = createApiActions('campaign');

const reducer = handleActions(
  {
    [actions.api.campaign.request]: state => ({
      ...state,
      isFetching: true,
      isError: false,
    }),
    [actions.api.campaign.response]: (state, { payload }) => ({
      ...state,
      isFetching: false,
      isError: false,
      data: payload,
    }),
    [actions.api.campaign.error]: (state, { payload }) => ({
      ...state,
      isFetching: false,
      isError: true,
      error: payload,
    }),
    [actions.api.campaign.setstate]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
    [actions.api.campaign.detailsRequest]: state => ({
      ...state,
      isFetching: true,
      isError: false,
    }),
    [actions.api.campaign.detailsResponse]: (state, { payload }) => ({
      ...state,
      isFetching: false,
      isError: false,
      data: payload,
    }),
    [actions.api.campaign.detailsError]: (state, { payload }) => ({
      ...state,
      isFetching: false,
      isError: true,
      error: payload,
    }),
  },
  { ...apiModuleDefaultState, data: [], error: null }
);

export default reducer;
