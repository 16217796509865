import React, { useEffect, useState } from 'react';
import { Box } from '@rebass/grid';
import { get, find, isEmpty } from 'lodash';
import { getSocialAccountURLs } from '../../../services/dashboard';
import { connect } from 'react-redux';
import { getUserId } from '../../../redux/selector';
import { actions as profileActions } from '../../../redux/modules/profile.module';
import { actions as loginActions } from '../../../redux/modules/login.module';
import { actions as socialNetworkActions } from '../../../redux/modules/socialNetwork.module';
import { updateProfile, emailSmtpConnect, verifyDeactivate } from '../../../services/configure';
import { ROLE_TYPES } from '../../../utils/constants';
import { HeadingSection } from '../AdminConfigureContainer.styles';
import {
  LoaderWrapper,
  SocialLoginButtons,
} from '../../../components';
import { 
  SwitchStyle
 } from '../Profile/Profile.styles';
import { ToggleButton } from '../screenConfigure.styles';
import { EmailSettingsModal } from '../../../components/';
import ModalContainer from '../../../componentsV2/ModalContainer';
import SendgridModal from '../../../containers/ConfigureContainer/SendgridModal/SendgridModal';
import linkedInIcon from '../../../assets/logos/linkedInIcon.svg'; 
import twitter from '../../../assets/logos/twitter.svg'; 
import sendgrid from '../../../assets/logos/sendgrid.svg'; 
import gmail from '../../../assets/logos/gmail.svg'; 
import facebook from '../../../assets/logos/facebook.svg'; 
import outlook from '../../../assets/logos/outlook.svg'; 
import zoom from '../../../assets/logos/zoom.svg'; 
import blankEmail from '../../../assets/logos/blankEmail.svg';
import DeactivatePlatformModal from '../DeactivatePlatformModal/DeactivatePlatformModal';

const Platforms = ({
  userData,
  socialAccountURL,
  setSocialAccountURL,
  roleType,
  userId,
  loginRequest,
  socialNetworkData,
  socialNetworkDelete,
  socialNetworkDataFetching,
  socialNetworkRequest,
}) => {
  const [allowSharingOnMyBehalf, setAllowSharingOnMyBehalf] = useState(
    get(userData.sharing, 'allow_sharing_on_my_behalf', '')
  );
  const [allowAutoschedulingOnMyBehalf, setAllowAutoschedulingOnMyBehalf] =
    useState(get(userData.sharing, 'allow_autoscheduling_on_my_behalf', ''));
  const [emailModal, setEmailModal] = useState(false);
  const [sendGridModal, toggleSendGridModal] = useState(false);
  const [emailErrorMessage, setEmailErrorMessage] = useState('');
  const [deactivateModalSettings, setDeactivateModalSettings] = useState({
    isOpen: false,
    accountId: '',
    platform: '',
    no_of_campaigns: ''
  })

  useEffect(() => {
    getSocialAccountURLs()
      .then((res) => {
        setSocialAccountURL(res.result.data);
      })
      .catch((err) => {});
    window.sessionStorage.setItem('selectedIndex', 1);
  }, [setSocialAccountURL]);

  const update = async (data) => {
    const payload = {
      userId,
      data,
    };
    return new Promise(async (resolve, reject) => {
      const { result } = await updateProfile(payload);
      if (result.success) {
        await loginRequest();
        resolve({ success: true });
      } else {
        reject({ success: true });
      }
    });
  };

  const handleSharingOnMyBehalf = async (checked) => {
    setAllowSharingOnMyBehalf(checked);
    await update({ allow_sharing_on_my_behalf: checked });
  };

  const handleAutoSchedulingOnMyBehalf = async (checked) => {
    setAllowAutoschedulingOnMyBehalf(checked);
    await update({ allow_autoscheduling_on_my_behalf: checked });
  };

  const handleSocialIconClick = (id, network, isDisconnected) => {
    if (isDisconnected) {
      window.sessionStorage.removeItem('platform');
      get(socialNetworkData, id, []).forEach((item) => {
        if (
          (network && network === item.snetworkProvider) ||
          network.length === 0
        ) {
          socialNetworkDelete({
            params: {
              id: `${id}_${item.accountId}`,
            },
          });
          // verifyDeactivate({
          //   "snetwork_uid": item.accountId,
          //   "platform": id === 'em' ? network : id,
          //   "snetwork": id
          // }).then(res => {
          //   if(res.response.can_be_deactivated) {
          //     socialNetworkDelete({
          //       params: {
          //         id: `${id}_${item.accountId}`,
          //       },
          //     });
          //   } else {
          //     setDeactivateModalSettings({
          //       isOpen: true,
          //       accountId: item.accountId,
          //       platform: id === 'em' ? network : id,
          //       no_of_campaigns: res.response.ongoing_campaigns
          //     })
          //   }
            
          // }).catch(err => {
          //   console.log(err);
          // })
        }
      });
    } else {
      if (id === 'ln') {
        window.sessionStorage.setItem('platform', 'ln');
      }
      let link = get(socialAccountURL, `${id}.oauth_url.${network}`, '');
      if (!link) {
        link = get(socialAccountURL, `${id}.oauth_url`, '');
      }

      if (link) {
        window.sessionStorage.setItem('selectedIndex', 1);
        if (link) window.location.href = link;
      }
    }
  };

  const getBottomText = (platform, emailPlaftform) => {
    let platformDetails = get(socialNetworkData, platform, []);
    if (emailPlaftform) {
      platformDetails = find(platformDetails, {
        snetworkProvider: emailPlaftform,
      });
    }
    const text = isEmpty(platformDetails) ? 'Connect' : 'Disconnect';
    let account = '';
    if (platform !== 'em' && platformDetails && platformDetails.length > 0) {
      if (platform === 'tw') {
        account = platformDetails[0].name.account;
      } else if (platform === 'ln' || platform === 'fb') {
        account =
          platformDetails[0].name.first + ' ' + platformDetails[0].name.last;
      }
    }
    if (
      platform === 'em' &&
      platformDetails &&
      (emailPlaftform === 'google' ||
        emailPlaftform === 'outlook' ||
        emailPlaftform === 'smtp')
    ) {
      account = platformDetails.email;
    }
    if (
      (platform === 'sg' || platform === 'zm') &&
      platformDetails &&
      platformDetails.length > 0
    ) {
      account = platformDetails[0].email;
    }
    return {
      bottomText: text,
      isDisconnected: text === 'Disconnect',
      account,
    };
  };

  const onBottomTextClick = (
    e,
    id,
    platform,
    emailPlaftform,
    isDisconnected
  ) => {
    e.stopPropagation();
    if (isDisconnected) {
      window.sessionStorage.removeItem('platform');
      get(socialNetworkData, platform, []).forEach((item) => {
        if (
          (emailPlaftform && emailPlaftform === item.snetworkProvider) ||
          !emailPlaftform
        ) {
          socialNetworkDelete({
            params: {
              id: `${platform}_${item.accountId}`,
            },
          });
        }
      });
    } else {
      handleSocialIconClick(id, isDisconnected, platform, emailPlaftform);
    }
  };
  const handleEmailModalSubmit = (payload) => {
    const data = {
      encryption: payload.port,
      name: payload.name,
      password: payload.password,
      port: payload.smtpPort,
      snetwork: 'em',
      snetwork_provider: 'smtp',
      url: payload.smtpHost,
      username: payload.email,
    };

    emailSmtpConnect(data)
      .then((res) => {
        if (res && res.result.success) {
          setEmailModal(false);
          socialNetworkRequest();
        }
      })
      .catch((err) => {
        setEmailErrorMessage(err.message);
      });
  };

  const onEmailClick = (isDisconnected) => {
    if (isDisconnected) {
      get(socialNetworkData, 'em', []).forEach((item) => {
        if ('smtp' === item.snetworkProvider) {
          verifyDeactivate({
            "snetwork_uid": item.accountId,
            "platform": 'em',
            "snetwork": 'em'
          }).then(res => {
            if(res.response.can_be_deactivated) {
              socialNetworkDelete({
                params: {
                  id: `em_${item.accountId}`,
                },
              });
            } else {
              setDeactivateModalSettings({
                isOpen: true,
                accountId: item.accountId,
                platform: 'em',
                no_of_campaigns: res.response.ongoing_campaigns
              })
            }
          }).catch(err => {
            console.log(err);
          })
        }
      });
    } else {
      setEmailModal(true);
    }
  };

  const onSendGridClick = (isDisconnected) => {
    if (isDisconnected) toggleSendGridModal(true);
  };
  return (
    <div>
      <div>
        <div>
          <HeadingSection>Connect my digital platforms</HeadingSection>
          <LoaderWrapper isLoading={socialNetworkDataFetching}>
            <SocialLoginButtons
              onBottomTextClick={onBottomTextClick}
              buttons={[
                {
                  name: 'LinkedIn',
                  icon: linkedInIcon,
                  bottomText: getBottomText('ln').bottomText,
                  onClick: () =>
                    handleSocialIconClick(
                      'ln',
                      '',
                      getBottomText('ln').isDisconnected
                    ),
                  isDisconnected: getBottomText('ln').isDisconnected,
                  platform: 'ln',
                  account: getBottomText('ln').account,
                },

                {
                  name: 'Twitter',
                  icon: twitter,
                  bottomText: getBottomText('tw').bottomText,
                  onClick: () =>
                    handleSocialIconClick(
                      'tw',
                      '',
                      getBottomText('tw').isDisconnected
                    ),
                  isDisconnected: getBottomText('tw').isDisconnected,
                  platform: 'tw',
                  account: getBottomText('tw').account,
                },
                {
                  name: 'Facebook',
                  icon: facebook,
                  bottomText: getBottomText('fb').bottomText,
                  onClick: () =>
                    handleSocialIconClick(
                      'fb',
                      '',
                      getBottomText('fb').isDisconnected
                    ),
                  isDisconnected: getBottomText('fb').isDisconnected,
                  platform: 'fb',
                  account: getBottomText('fb').account,
                },
                {
                  name: 'Google',
                  icon: gmail,
                  bottomText: getBottomText('em', 'google').bottomText,
                  onClick: () =>
                    handleSocialIconClick(
                      'em',
                      'google',
                      getBottomText('em', 'google').isDisconnected
                    ),
                  isDisconnected: getBottomText('em', 'google').isDisconnected,
                  platform: 'em',
                  emailPlaftform: 'google',
                  account: getBottomText('em', 'google').account,
                },
                {
                  name: 'Outlook',
                  icon: outlook,
                  bottomText: getBottomText('em', 'outlook').bottomText,
                  onClick: () =>
                    handleSocialIconClick(
                      'em',
                      'outlook',
                      getBottomText('em', 'outlook').isDisconnected
                    ),
                  isDisconnected: getBottomText('em', 'outlook').isDisconnected,
                  iconHeight: 50,
                  platform: 'em',
                  emailPlaftform: 'outlook',
                  account: getBottomText('em', 'outlook').account,
                },

                {
                  name: 'Zoom',
                  icon: zoom,
                  bottomText: getBottomText('zm').bottomText,
                  onClick: () =>
                    handleSocialIconClick(
                      'zm',
                      '',
                      getBottomText('zm').isDisconnected
                    ),
                  isDisconnected: getBottomText('zm').isDisconnected,
                  iconHeight: 50,
                  iconWidth: 100,
                  platform: 'zm',
                  account: getBottomText('zm').account,
                },

                {
                  name: 'Email (SMTP)',
                  icon: blankEmail,
                  bottomText: getBottomText('em', 'smtp').bottomText,
                  onClick: () =>
                    onEmailClick(getBottomText('em', 'smtp').isDisconnected),
                  isDisconnected: getBottomText('em', 'smtp').isDisconnected,
                  platform: 'em',
                  emailPlaftform: 'smtp',
                  iconHeight: 50,
                  account: getBottomText('em', 'smtp').account,
                },
                {
                  name: 'Sendgrid',
                  icon: sendgrid,
                  bottomText:
                    socialNetworkData &&
                    socialNetworkData['sg'] &&
                    socialNetworkData['sg'].length > 0 &&
                    socialNetworkData['sg'][0].verification_status ===
                      'verified' &&
                    socialNetworkData['sg'][0].status === 'active'
                      ? 'Disconnect'
                      : 'Connect',
                  onClick: () => onSendGridClick(true),
                  isDisconnected:
                    socialNetworkData &&
                    socialNetworkData['sg'] &&
                    socialNetworkData['sg'].length > 0 &&
                    socialNetworkData['sg'][0].verification_status ===
                      'verified' &&
                    socialNetworkData['sg'][0].status === 'active'
                      ? true
                      : false,
                  platform: 'sg',
                  iconHeight: 50,
                  account: getBottomText('sg').account,
                },
              ]}
            />
          </LoaderWrapper>
          <Box mt={2} mb={20}>
            <div style={{display: 'flex', margin: '30px auto 10px', width: 'fit-content'}}>
              <p style={{color: !allowAutoschedulingOnMyBehalf ? '#101828' : '#816EF8', margin: '6px 0'}}>Clout Recommendations?</p>
              <SwitchStyle
                className="react-switch"
                onChange={()=>handleAutoSchedulingOnMyBehalf(!allowAutoschedulingOnMyBehalf)}
                checked={allowAutoschedulingOnMyBehalf}
              />
            </div>
            
            {roleType &&
              (roleType === 'ADVISOR_ENTERPRISE' ||
                roleType === 'ADVISOR_ASSET_MANAGER') && (
                <>
                  <span style={{ fontSize: '14px' }}>
                    Allow Company to Post?
                  </span>{' '}
                  &nbsp;{' '}
                  <ToggleButton
                    className="react-switch"
                    onChange={handleSharingOnMyBehalf}
                    checked={allowSharingOnMyBehalf}
                    onColor="#6351ed"
                    offColor="#cccccc"
                    offHandleColor="#fff"
                    onHandleColor="#fff"
                    handleDiameter={12}
                    uncheckedIcon=""
                    checkedIcon=""
                    boxShadow=""
                    activeBoxShadow=""
                    type="configure"
                  />
                </>
              )}
          </Box>
        </div>
      </div>
       <ModalContainer
          headerBorder={true}
          modalStatus={emailModal} 
          padding='10px 20px 0'
          closeModal={() => setEmailModal(!emailModal)}
          children={ <EmailSettingsModal
            closeModal={() => setEmailModal(!emailModal)}
            handleModalSubmit={handleEmailModalSubmit}
            emailErrorMessage={emailErrorMessage}
          />}
          title={'Email Settings'}
        />
       <ModalContainer
          headerBorder={true}
          modalStatus={sendGridModal} 
          padding='10px 20px 0'
          closeModal={() => toggleSendGridModal(!sendGridModal)}
          children={  <SendgridModal
            adminModal={roleType === ROLE_TYPES.ADVISOR_PREMIUM ? true : false}
            handleClose={() => toggleSendGridModal(!sendGridModal)}
          />}
          title={'Connect with Sendgrid'}
        />
        {deactivateModalSettings.isOpen && (
          <DeactivatePlatformModal
            isOpen={deactivateModalSettings.isOpen}
            onClose={setDeactivateModalSettings}
            socialNetworkDelete={socialNetworkDelete}
            deactivateModalSettings={deactivateModalSettings}
          />
        )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  userId: getUserId(state),
  socialNetworkData: state.socialNetwork.data,
  socialNetworkDataFetching: state.socialNetwork.isFetching,
});

const mapDispatchToProps = {
  profileUpdateRequest: profileActions.api.profile.request,
  loginRequest: loginActions.api.login.request,
  socialNetworkDelete: socialNetworkActions.api.socialNetwork.delete,
  socialNetworkRequest: socialNetworkActions.api.socialNetwork.request,
};

export default connect(mapStateToProps, mapDispatchToProps)(Platforms);
