import { Flex } from '@rebass/grid';
import React, { useEffect, useState } from 'react';
import { Button, Input, Modal, ModalBody, ModalCloseButton, ModalHeader } from '../../../components';
import { themesData } from '../../../utils';

const EditTitleModal = ({close, isOpen, save, defaultTitle}) => {
    const [title, setTitle] = useState('');

    useEffect(() => {
        setTitle(defaultTitle);
    }, []);

    return (
        <Modal 
            onRequestClose={close}
            size='small'
            isOpen={isOpen}
        >
            <ModalHeader>
                Edit Title
                <ModalCloseButton onClick={close} />
            </ModalHeader>
            <ModalBody>
                <div style={{position: 'relative'}}>
                    <Input
                        isInputWithFloatingLabel={true}
                        placeholder='New Title'
                        value={title}
                        onChange={e => setTitle(e.target.value)}
                        color={themesData.purpleishBlue} 
                    />
                </div>
                {!title && (
                    <div style={{fontSize: '10px', color: '#6351ed', marginTop: '10px'}}>*Title cannot be empty!</div>
                )}
                <Flex justifyContent='center'>
                    <Button disabled={!title} onClick={() => save(title)} styles={{marginRight: '10px'}}>Save</Button>
                    <Button bgColor="#eeeeee" color={themesData.purpleishBlue} onClick={close}>Cancel</Button>
                </Flex>
            </ModalBody>
        </Modal>
    );
}

export default EditTitleModal;