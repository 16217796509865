import React from 'react';
import {
  TileWrapper,
  TileContainer,
  FirstItem,
  Image,
  SecondItem,
  Title,
} from './Tile.styles';

const findImageSrc = (src) => {
  let index = src.lastIndexOf('/');
  if (src.substr(index + 1).split('.')[0] === `null`) {
    return `/assets/images/GaBdRGkV_a7b04f53_r.jpg`;
  }
  return src;
};

const Tile = ({ src, alt = 'image', title = 'Name', styles, children, className = '', imageStyle }) => {
  return (
    <TileWrapper>
      <TileContainer style={styles ? styles : {}} className={className}>
        <FirstItem>
          <Image style={imageStyle || {}} src={findImageSrc(src)} alt={alt} />
        </FirstItem>
        <SecondItem>
          <Title>{title}</Title>
        </SecondItem>
      </TileContainer>
      {children}
    </TileWrapper>
  );
};
export default Tile;
