import { styled } from '@mui/material';

export const Wrapper = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '0.5rem',
  padding: '1rem',
}));

export const Header = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: '0.5rem',
}));

export const HeaderTitle = styled('h3')(() => ({
  font: '500 1.25rem Poppins, sans-serif',
  color: '#101828',
}));

export const List = styled('div')(() => ({
  width: '100%',
  maxHeight: '20rem',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '0.5rem',
  overflow: 'auto',
}));

export const ListItem = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: '1rem',
  padding: '1rem',
  backgroundColor: '#F9FAFB',
  borderRadius: '0.5rem',

  svg: {
    color: '#101828',
    fontSize: '1.5rem',
  },
}));

export const PeopleIconContainer = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '1rem',
  backgroundColor: '#DBF2FC',
  borderRadius: '50%',

  svg: {
    color: '#457F8F',
    fontSize: '1.5rem',
  },
}));

export const IconContainer = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '1rem',
  cursor: 'pointer',

  svg: {
    color: '#101828',
    fontSize: '1.5rem',
  },
}));

export const ListItemContent = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '0.25rem',
  flexGrow: 1,

  h5: {
    font: '500 1rem Poppins, sans-serif',
    color: '#000',
    margin: 0,
  },

  p: {
    font: '400 0.75rem Poppins, sans-serif',
    color: '#667085',
    margin: 0,
  },
}));

export const Footer = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: '0.5rem',
}));
