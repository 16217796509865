import styled from 'styled-components';
import { Chip } from '@mui/material';

export const ChipStyle = styled(Chip)`
  font-family: 'Poppins';
  margin: 0px 5px 5px 0px;
  border: 1px solid #b6cff3 !important;
  background: #ecf2fc !important;
  color: #0b5cd7;
  /* display: inline; */
  .MuiChip-root {
    color: #404b50;
    opacity: 1;
  }
  .MuiChip-outlined {
    color: #b6cff3;
  }
  .MuiChip-deleteIcon {
    color: #0b5cd7;
    opacity: 1;
    margin: 0;
    padding: 0 6px 0 0;
    display: ${({ hideDelete }) => hideDelete ? 'none' : ''}
  }
`;

export const AddChipStyle = styled(Chip)`
  margin: 5px 5px 5px 0px !important;
  border: 1px solid #e4e7ec !important;
  background: #fff !important;
  color: #101828;
  /* display: inline; */
  .MuiChip-root {
    color: #101828;
    opacity: 1;
  }
  .MuiChip-deleteIcon {
    color: #101828;
    opacity: 1;
    padding: 0 6px 0 0;
  }
`;

export const SearchResults = styled('div')`
  width: 100%;
  display: flex;
  justify-content: start;
  padding: 5px;
  margin: 0px auto;
  flex-flow: rows;
  flex-wrap: wrap;
`;

export const SearchBox = styled('input')`
  font-family: 'Poppins';
  width: 100%;
  height: 40px;
  background: #fcfdfd;
  padding: 0 10px;
  border: 1px solid #e4e7ec;
  box-shadow: inset 1px 2px 2px rgba(16, 24, 40, 0.12);
  border-radius: 4px;
  color: #667085;
`;

export const SearchTitle = styled('h4')`
  font-family: 'Poppins';
  font-size: 14px;
  font-weight: 400;
  color: #101828;
  margin: 10px 0 5px;
  text-align: left;
`;
