import { get, isEmpty } from 'lodash';
import React, { useState, useEffect } from 'react';
import {
  DashboardArticle,
  Modal,
  ModalCloseButton,
  ModalHeader,
} from '../../../components';
import {
  getSponsorList,
  postArticlesToNewsletterBucket,
  postAddToBookmarks,
  deleteFromBookmarks,
} from '../../../services/dashboard';
import { deleteBucketArticle } from '../../../services/newsletter';
import { eventHandlers, sponsoredArticleData } from '../../../utils';
import { SponsoredArticlesWrapper } from './SponsoredModal.styles';

const SponsoredModal = ({
  open,
  setModalOpen,
  sponsoredArticles,
  themeNames,
  isDeleteBlocked,
  isShareBlocked,
  popularThemes,
  userData,
  setSponsoredArticles,
  bucketCountRequest,
}) => {
  const [imageCounter, setImageCounter] = useState([]);
  const [articleId, setArticleId] = useState('');
  const [loading, setLoading] = useState(false);
  const [bookmarksLoading, toggleBookmarksLoading] = useState(false);

  // console.log(sponsoredArticles);
  const handleModalClose = () => {
    setModalOpen(false);
  };

  const handleAddNewsletter = (id, themeId, source) => {
    if (source === 'newsLetter') setLoading(true);
    else toggleBookmarksLoading(true);
    setArticleId(id);
    if (source === 'newsLetter') {
      postArticlesToNewsletterBucket({ articleId: id, themeId })
        .then((res) => {
          if (res.result.success) {
            getSponsorList()
              .then((res) => {
                if (res.result.success) {
                  setSponsoredArticles(
                    sponsoredArticleData(get(res, 'result.data', []))
                  );
                  setLoading(false);
                  setArticleId('');
                } else {
                  setLoading(false);
                  setArticleId('');
                }
              })
              .catch((err) => {
                setLoading(false);
                setArticleId('');
              });
            bucketCountRequest();
          } else {
            setLoading(false);
            setArticleId('');
          }
        })
        .catch((err) => {
          setLoading(false);
          setArticleId('');
        });
    } else {
      postAddToBookmarks(id, themeId)
        .then((response) => {
          if (response.result.success) {
            return getSponsorList();
          } else {
            toggleBookmarksLoading(false);
            setArticleId('');
          }
        })
        .then((response) => {
          if (response.result.success)
            setSponsoredArticles(
              sponsoredArticleData(get(response, 'result.data', []))
            );
          toggleBookmarksLoading(false);
          setArticleId('');
        })
        .catch((err) => {
          toggleBookmarksLoading(false);
          setArticleId('');
        });
    }
  };

  const handleRemoveNewsletter = (bucketId, id, source) => {
    if (source === 'newsLetter') setLoading(true);
    else toggleBookmarksLoading(true);
    setArticleId(id);
    if (source === 'newsLetter') {
      deleteBucketArticle({ id: bucketId })
        .then((res) => {
          if (res === '') {
            getSponsorList()
              .then((res) => {
                if (res.result.success) {
                  setSponsoredArticles(
                    sponsoredArticleData(get(res, 'result.data', []))
                  );
                  setLoading(false);
                  setArticleId('');
                } else {
                  setLoading(false);
                  setArticleId('');
                }
              })
              .catch((err) => {
                setLoading(false);
                setArticleId('');
              });
            bucketCountRequest();
          } else {
            setLoading(false);
            setArticleId('');
          }
        })
        .catch((err) => {
          setLoading(false);
          setArticleId('');
        });
    } else {
      deleteFromBookmarks(bucketId)
        .then((response) => {
          if (response === '') {
            return getSponsorList();
          } else {
            toggleBookmarksLoading(false);
            setArticleId('');
          }
        })
        .then((response) => {
          if (response.result.success)
            setSponsoredArticles(
              sponsoredArticleData(get(response, 'result.data', []))
            );
          toggleBookmarksLoading(false);
          setArticleId('');
        })
        .catch((err) => {
          toggleBookmarksLoading(false);
          setArticleId('');
        });
    }
  };

  useEffect(() => {
    if (!isEmpty(sponsoredArticles) && open) {
      const userEmail =
        userData &&
        userData.details &&
        userData.details.user &&
        userData.details.user.email;
      sponsoredArticles.forEach((article) => {
        eventHandlers(
          'SPONSORED_ARTICLE_DISPLAYED',
          popularThemes,
          article.id,
          'SPONSORED_BOX_EXPAND',
          get(article, 'channelId', ''),
          article.title,
          article.themeId,
          themeNames[article.themeId],
          article.topic,
          userEmail
        );
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sponsoredArticles, open]);

  return (
    <Modal
      shouldCloseOnOverlayClick
      isOpen={open}
      onRequestClose={handleModalClose}
      size="big"
      type="sponsored"
    >
      <ModalHeader data-testid="relevant_insights_modal">
        Relevant Insights
        <ModalCloseButton onClick={handleModalClose} />
      </ModalHeader>
      <SponsoredArticlesWrapper data-testid="relevant_insights_data">
        {sponsoredArticles &&
          sponsoredArticles.length > 0 &&
          sponsoredArticles.map((article, index) => (
            <DashboardArticle
              testId={`relevant_insights_article${index}`}
              key={'dashboardArticle_' + index}
              article={article}
              {...{
                ...article,
                themeNames,
                imageCounter,
                setImageCounter,
                hideDelete: true,
                width: '22%',
                margin: '0px auto 2% auto',
                isShareBlocked,
                height: '265px',
                type: 'sponsored',
                sponseredContent: true,
                section: 'SPONSORED_BOX_EXPAND',
                onNewsletterArticleClick: (id, themeId, source) =>
                  handleAddNewsletter(id, themeId, source),
                onRemoveFromNewletterClick: (bucketId, id, source) =>
                  handleRemoveNewsletter(bucketId, id, source),
                exploreNewsletterId: articleId,
                newsletterArticleFetching: loading,
                bookmarksFetching: bookmarksLoading,
                bookmarksId: articleId,
              }}
            />
          ))}
        <i aria-hidden="true"></i>
        <i aria-hidden="true"></i>
        <i aria-hidden="true"></i>
      </SponsoredArticlesWrapper>
    </Modal>
  );
};

export default SponsoredModal;
