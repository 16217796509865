import React from 'react';
import ReactSkeleton from 'react-loading-skeleton';

import { CampaignInfoCardsContainer } from './styles';

import OpenEnvolope from 'src/assets/icons/campaignOverview/OpenEnvolope';
import Cursor from 'src/assets/icons/campaignOverview/Cursor';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import CampaignInfoCards from '../CampaignInfoCards';

function SubCampaignInsightsDisplay({ campaignInsightsData, isLoading }) {
  return (
    <CampaignInfoCardsContainer>
      {isLoading ? (
        <>
          <ReactSkeleton height={148} width={230} />
          <ReactSkeleton height={148} width={230} />
          <ReactSkeleton height={148} width={230} />
        </>
      ) : (
        <>
          <CampaignInfoCards
            campaignInfo={{
              icon: <OpenEnvolope />,
              label: 'Open Rate',
              value: `${campaignInsightsData?.openRate ?? '-'}`,
            }}
          />

          <CampaignInfoCards
            campaignInfo={{
              icon: <Cursor />,
              label: 'Total Clicks',
              value: `${campaignInsightsData?.totalClicks ?? '-'}`,
            }}
          />
          <CampaignInfoCards
            campaignInfo={{
              icon: <EmailOutlinedIcon />,
              label: 'Email Engagement',
              value: `${campaignInsightsData?.emailEngagement ?? '-'}`,
            }}
          />
        </>
      )}
    </CampaignInfoCardsContainer>
  );
}

export default SubCampaignInsightsDisplay;
