import styled from 'styled-components';

export const SearchContainer = styled('div')`
    width: 100%;
    display: flex;
    background: #FFFFFF;
    border: 1px solid #E4E7EC;
    box-shadow: inset 1px 2px 2px rgba(16, 24, 40, 0.12);
    border-radius: 4px;
    padding: 4px;
    margin: 8px 0;
    max-width: ${({ maxWidth }) => maxWidth && maxWidth};

`;

export const SearchInput = styled('input')`
    width: 90%;
    background: #FFFFFF;
    border: 0;
    box-shadow: 0;
`;

export const SearchIcon = styled('img')`
    height: 24px;
    width: 24px;
    margin: 4px 10px 4px 5px;
`;

export const ActivateSearch = styled('button')`
    height: 40px;
    width: 40px;
    padding: 5px;
    background: #FFFFFF;
    border: 1px solid #E4E7EC;
    border-radius: 4px;
    cursor: pointer;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.12);
    transition: 0.5s;
    :hover {
        background: #f1f1f1;
    }
`;
