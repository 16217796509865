import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { actions as contactActions } from '../../redux/modules/contacts.module';
import { actions as analyticsActions } from '../../redux/modules/analytics.module';
import { actions as campaignActions } from '../../redux/modules/campaign.module';
import { get } from 'lodash';
import { actions as loginActions } from '../../redux/modules/login.module';
import { useOktaAuth } from '@okta/okta-react';
import Navbar from 'src/componentsV2/Navbar';
import SwitchProfileModal from './SwitchProfileModal';

const AdvisorHeader = ({
  pathName,
  userName,
  roleType,
  type,
  setSelectedTabIndex,
  setAnalyticsTabIndex,
  user,
  campaignSetState,
  userDetails,
  loginRequest,
}) => {
  const history = useHistory();
  const [showSwitchProfile, setShowSwitchProfile] = useState(false);

  const { oktaAuth } = useOktaAuth();

  const switchBackToAdmin = () => {
    let ogToken = window.sessionStorage.getItem('ogToken');
    window.sessionStorage.setItem('token', ogToken);
    window.sessionStorage.removeItem('ogToken');
    window.sessionStorage.removeItem('switch-user');
    loginRequest();
    window.open('/admin/home', '_self');
  };

  const goToProfilePage = (index) => {
    window.sessionStorage.removeItem('selectedIndex');
    setSelectedTabIndex({ selectedTabIndex: index });
    history.push('/configure');
  };

  let profileItems = {
    title: 'JL',
    children: [
      {
        title: 'Profile and Settings',
        action: () => goToProfilePage(0),
      },
      // {
      //   name: 'Topics',
      //   action: () => history.push('theme')
      // },
      // {
      //   name: 'Publishers',
      //   action: () => history.push('channel')
      // },
      // {
      //   name: 'Subscribe',
      //   action: () => history.push('subscription')
      // },
      // {
      //   name: 'Platforms',
      //   action: ()=>  goToProfilePage(1)
      // },
      // {
      //   name: 'General Settings',
      //   action: ()=> goToProfilePage(2)
      // },
      {
        title: window.sessionStorage.getItem('switch-user')
          ? 'Switch User'
          : 'Logout',
        action: async () => {
          if (window.sessionStorage.getItem('switch-user')) {
            switchBackToAdmin();
          } else {
            await oktaAuth.signOut({
              revokeAccessToken: true,
              revokeRefreshToken: true,
              postLogoutRedirectUri: window.location.origin,
            });
            await oktaAuth.closeSession();
            localStorage.clear();
            sessionStorage.clear();
            window.location.href = '/';
          }
        },
      },
    ],
  };

  if(get(userDetails, 'details.user.is_impersonator', 'false') ===
  'true' && !window.sessionStorage.getItem('switch-user')){
    const switchProfileItem = {
      title: 'Switch Profile',
      action: ()=> setShowSwitchProfile(true)
    }
    profileItems = {...profileItems, ['children']: [...profileItems.children, switchProfileItem]}
  }


  return (
    <>
      <Navbar
        customLogoUrl={get(userDetails, 'company.extra_settings.logo', '')}
        userRole="ADVISOR"
        profileItems={profileItems}
      />

      {showSwitchProfile ? (
        <SwitchProfileModal
          handleModalClose={() => {
            setShowSwitchProfile(false);
          }}
          userData={userDetails}
          loginRequest={loginRequest}
          roleType={roleType}
        />
      ) : null}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
    userDetails: state.user.data,
  };
};

const mapDispatchToProps = {
  setSelectedTabIndex: contactActions.api.contacts.selectedtabindex,
  setAnalyticsTabIndex: analyticsActions.api.analytics.selectedtabindex,
  campaignSetState: campaignActions.api.campaign.setstate,
  loginRequest: loginActions.api.login.request,
};

export default connect(mapStateToProps, mapDispatchToProps)(AdvisorHeader);
