import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import Pencil from 'src/assets/icons/Pencil';
import { Form, Label } from '../styles';
import {
  Content,
  EditIcon,
  ImageContainer,
  ImageSection,
  ImageUploadOverlay,
  Pill,
  Pills,
  Section,
} from './styles';
import { format } from 'date-fns';

function Preview({
  content,
  selectedTopics,
  setImage,
  previewImage,
  setPreviewImage,
}) {
  const onDrop = useCallback((acceptedFiles) => {
    setImage(acceptedFiles[0] ?? null);
    setPreviewImage(URL?.createObjectURL(acceptedFiles[0]) ?? null);
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'image/*': ['.jpeg', '.png'],
    },
    onDrop,
  });

  return (
    <Form>
      <Label>Preview</Label>
      <Section>
        <div {...getRootProps()}>
          <input {...getInputProps()} />
          <ImageSection>
            <ImageUploadOverlay className="overlay">
              <h3>Click to replace the image</h3>
            </ImageUploadOverlay>
            <ImageContainer className="image-container">
              <img src={previewImage ?? null} alt="" width="150" height="120" />
            </ImageContainer>
            <EditIcon>
              <Pencil />
            </EditIcon>
          </ImageSection>
        </div>

        <Content>
          <Pills>
            {Array.isArray(selectedTopics) ? (
              <>
                {[...selectedTopics]?.splice(0, 2)?.map((topic) => (
                  <Pill>{topic?.theme_name}</Pill>
                ))}
                {selectedTopics?.length - 2 > 0 && (
                  <Pill>+{selectedTopics?.length - 2}</Pill>
                )}
              </>
            ) : (
              <></>
            )}
          </Pills>
          <h5>{content?.text?.title ?? ''}</h5>
          <p>{content?.text?.description ?? ''}</p>
          <span>{format(new Date(), 'MMM dd')}</span>
        </Content>
      </Section>
    </Form>
  );
}

export default Preview;
