/* eslint-disable react-hooks/exhaustive-deps */
import { Flex } from '@rebass/grid';
import React, { useEffect, useState } from 'react';

import {
  ATSListContainer,
  ApproveToShareFinalBlockWrapper,
  ContactsTabItem,
  SearchContainer,
  TabContainer,
} from './EmailList.styles';
import { Button, LoaderWrapper } from '../../../components';
import ContactListATSModal from './ContactListATSModal';
import { get, isEmpty } from 'lodash';

import {
  approvedContactList,
  getContactsGroups,
} from '../../../services/configure';
import { getSegments, getSingularSegments } from '../../../services/segment';
import './style.css';
import SearchBar from '../../../componentsV2/SearchBar';
import { Tooltip, Zoom } from '@mui/material';

const ContactsATSList = ({ contactsTrigger }) => {
  const [openATSModal, setOpenATSModal] = useState(false);
  const [showLastDataBlock, setShowLastDataBlock] = useState(false);
  const [segmentAtsTabActive, setSegmentAtsTabActive] = useState(false);
  const [selectedEmailContacts, setSelectedEmailContacts] = useState([]);
  const [selectedSegmentContacts, setSelectedSegmentContacts] = useState([]);
  const [openAtsSearch, setOpenAtsSearch] = useState(false);
  const [atsSearchString, setAtsSearchString] = useState('');
  const [atsSearchBoxInput, setAtsSearchBoxInput] = useState('');
  const [filteredEmailList, setFilteredEmailList] = useState([]);
  const [filteredSegmentList, setFilteredSegmentList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [emailLoading, setEmailLoading] = useState(false);
  const [segmentLoading, setSegmentLoading] = useState(false);
  const [emailList, setEmailList] = useState([]);
  const [segmentList, setSegmentList] = useState([]);
  const [deselectedEmailContacts, setDeselectedEmailContacts] = useState([]);
  const [deselectedSegmentContacts, setDeselectedSegmentContacts] = useState(
    []
  );
  const [currentFilteredPage, setCurrentFilteredPage] = useState(1);
  const [filteredItemsPerPage, setFilteredItemsPerPage] = useState(50);
  const [filteredPageNumberLimit, setFilteredpageNumberLimit] = useState(5);
  const [maxFilteredPageNumberLimit, setmaxFilteredPageNumberLimit] =
    useState(5);
  const [minFilteredPageNumberLimit, setminFilteredPageNumberLimit] =
    useState(0);
  const [pagesForFilteredEmailResult, setPagesForFilteredEmailResult] =
    useState([]);
  const [pagesForFilteredSegmentResult, setPagesForFilteredSegmentResult] =
    useState([]);
  const [approvedEmailList, setApprovedEmailList] = useState([]);
  const [approvedSegmentList, setApprovedSegmentList] = useState([]);

  useEffect(() => {
    fetchEmail();
    fetchSegments();
  }, []);

  useEffect(() => {
    if (contactsTrigger) {
      fetchEmail();
    }
  }, [contactsTrigger]);

  const fetchEmail = async () => {
    setLoading(true);
    try {
      const response = await getContactsGroups();
      setEmailList(
        get(response, 'result.data', []).filter(
          (c) =>
            ![
              'GMAIL',
              'HUBSPOT',
              'OUTLOOK',
              'WEALTHBOX',
              'SALESFORCE',
              'REDTAIL',
            ].includes(get(c, 'name'))
        )
      );
      let approvedEmailItems = response?.result?.data.filter(
        (approved) => approved.is_default_approved === true
      );
      setFilteredEmailList(
        response?.result?.data.filter(
          (approved) => approved.is_default_approved === true
        )
      );
      setSelectedEmailContacts(
        response?.result?.data.filter(
          (approved) => approved.is_default_approved === true
        )
      );
      setApprovedEmailList(
        response?.result?.data.filter(
          (approved) => approved.is_default_approved === true
        )
      );
      let filteredPageNumbers = [];
      for (
        let i = 1;
        i <= Math.ceil(approvedEmailItems.length / filteredItemsPerPage);
        i++
      ) {
        filteredPageNumbers.push(i);
      }
      setPagesForFilteredEmailResult(filteredPageNumbers);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setEmailList([]);
    }
  };

  const fetchSegments = async () => {
    setLoading(true);
    try {
      const response = await getSegments();
      setLoading(false);
      setSegmentList(response.result.data);
      let approvedSegmentItems = response?.result?.data.filter(
        (approved) => approved.is_default_approved === true
      );
      setSelectedSegmentContacts(
        response?.result?.data.filter(
          (approved) => approved.is_default_approved === true
        )
      );
      setFilteredSegmentList(
        response?.result?.data.filter(
          (approved) => approved.is_default_approved === true
        )
      );
      setApprovedSegmentList(
        response?.result?.data.filter(
          (approved) => approved.is_default_approved === true
        )
      );
      let filteredPageNumbers = [];
      for (
        let i = 1;
        i <= Math.ceil(approvedSegmentItems.length / filteredItemsPerPage);
        i++
      ) {
        filteredPageNumbers.push(i);
      }

      setPagesForFilteredSegmentResult(filteredPageNumbers);
      // setSelectedSegmentContacts(selectedSegmentContacts);
      // setFilteredSegmentList(selectedSegmentContacts);
    } catch (err) {
      setLoading(false);
      console.log('fetch segments error:', err);
    }
  };

  const indexOfLastItem = currentFilteredPage * filteredItemsPerPage;
  const indexOfFirstItem = indexOfLastItem - filteredItemsPerPage;

  const currenListtItems = !segmentAtsTabActive
    ? filteredEmailList.slice(indexOfFirstItem, indexOfLastItem)
    : filteredSegmentList.slice(indexOfFirstItem, indexOfLastItem);

  const handleClick = (e) => {
    setCurrentFilteredPage(Number(e.target.id));
  };

  const renderEmailPageNumbers = pagesForFilteredEmailResult.map((number) => {
    if (
      number < maxFilteredPageNumberLimit + 1 &&
      number > minFilteredPageNumberLimit
    ) {
      return (
        <li
          key={number}
          id={number}
          onClick={handleClick}
          className={currentFilteredPage === number ? 'active' : null}
        >
          {number}
        </li>
      );
    } else {
      return null;
    }
  });

  const renderSegmentPageNumbers = pagesForFilteredSegmentResult.map(
    (number) => {
      if (
        number < maxFilteredPageNumberLimit + 1 &&
        number > minFilteredPageNumberLimit
      ) {
        return (
          <li
            key={number}
            id={number}
            onClick={handleClick}
            className={currentFilteredPage === number ? 'active' : null}
          >
            {number}
          </li>
        );
      } else {
        return null;
      }
    }
  );

  const handleToggle = (flag) => {
    setmaxFilteredPageNumberLimit(5);
    setminFilteredPageNumberLimit(0);
    setAtsSearchString('');
    setCurrentFilteredPage(1);
    setOpenAtsSearch(false);
    if (flag) {
      setFilteredSegmentList(filteredSegmentList);
      handleSegmentATSSearch([]);

      let filteredPageNumbers = [];
      for (
        let i = 1;
        i <= Math.ceil(filteredEmailList.length / filteredItemsPerPage);
        i++
      ) {
        filteredPageNumbers.push(i);
      }
      setPagesForFilteredEmailResult(filteredPageNumbers);
    } else {
      setFilteredEmailList(filteredEmailList);
      handleEmailATSSearch([]);

      let filteredPageNumbers = [];
      for (
        let i = 1;
        i <= Math.ceil(filteredSegmentList.length / filteredItemsPerPage);
        i++
      ) {
        filteredPageNumbers.push(i);
      }
      setPagesForFilteredSegmentResult(filteredPageNumbers);
    }
    setSegmentAtsTabActive(flag);
  };

  const handleEdit = () => {
    setOpenATSModal(true);
  };

  const handleApproveToShare = () => {
    setOpenATSModal(true);
  };

  const handleDiscardEmailContact = (item) => {
    setLoading(true);
    let payload = {
      contactlist_ids: {
        email_list_ids: {},
        segment_list_ids: {},
      },
    };
    payload.contactlist_ids.email_list_ids.add = [];
    if (item) {
      payload.contactlist_ids.email_list_ids.remove = [item?.id];
    }
    payload.contactlist_ids.segment_list_ids.add = [];
    payload.contactlist_ids.segment_list_ids.remove = [];

    payload.contactlist_ids = JSON.stringify(payload.contactlist_ids);
    approvedContactList(payload)
      .then((res) => {
        fetchEmail();
      })
      .catch((error) => {
        console.log('ERROR-->', error);
        setLoading(false);
      });
  };

  const handleDiscardSegmentContact = (item) => {
    setLoading(true);
    let payload = {
      contactlist_ids: {
        email_list_ids: {},
        segment_list_ids: {},
      },
    };

    payload.contactlist_ids.email_list_ids.add = [];

    payload.contactlist_ids.email_list_ids.remove = [];

    payload.contactlist_ids.segment_list_ids.add = [];

    if (item) {
      payload.contactlist_ids.segment_list_ids.remove = [item?.id];
    }
    payload.contactlist_ids = JSON.stringify(payload.contactlist_ids);
    approvedContactList(payload)
      .then((res) => {
        fetchSegments();
      })
      .catch((error) => {
        console.log('ERROR-->', error);
        setLoading(false);
      });
  };

  const fetchEmailSearchData = async (searhTerm) => {
    setEmailLoading(true);
    try {
      const response = await getContactsGroups(searhTerm);
      if (response?.result?.data.length < 1) {
        setFilteredEmailList([]);
        setEmailLoading(false);
      } else {
        setFilteredEmailList(
          response?.result?.data.filter(
            (approved) => approved.is_default_approved === true
          )
        );
        let filteredPageNumbers = [];
        for (
          let i = 1;
          i <=
          Math.ceil(
            response?.result?.data.filter(
              (approved) => approved.is_default_approved === true
            ).length / filteredItemsPerPage
          );
          i++
        ) {
          filteredPageNumbers.push(i);
        }
        setPagesForFilteredEmailResult(filteredPageNumbers);
        setEmailLoading(false);
      }
    } catch (err) {
      console.log('email list search error:', err);
      setEmailLoading(false);
    }
  };

  const fetchSegmentSearchData = async (searchText = null) => {
    setSegmentLoading(true);
    try {
      const response = await getSingularSegments(searchText);
      if (response?.result?.data.length < 1) {
        setFilteredSegmentList([]);
        setSegmentLoading(false);
      } else {
        setFilteredSegmentList(
          response?.result?.data.filter(
            (approved) => approved.is_default_approved === true
          )
        );
        let filteredPageNumbers = [];
        for (
          let i = 1;
          i <=
          Math.ceil(
            response?.result?.data.filter(
              (approved) => approved.is_default_approved === true
            ).length / filteredItemsPerPage
          );
          i++
        ) {
          filteredPageNumbers.push(i);
        }
        setPagesForFilteredSegmentResult(filteredPageNumbers);
        setSegmentLoading(false);
      }
    } catch (err) {
      console.log('segment list search err:', err);
      setSegmentLoading(false);
    }
  };

  const handleSegmentATSSearch = (value = null) => {
    if (segmentAtsTabActive) {
      fetchSegmentSearchData(value || atsSearchBoxInput);
    }
    setmaxFilteredPageNumberLimit(5);
    setminFilteredPageNumberLimit(0);
    setCurrentFilteredPage(1);
  };

  const handleEmailATSSearch = (value = null) => {
    if (!segmentAtsTabActive) {
      fetchEmailSearchData(value || atsSearchBoxInput);
    }
    setmaxFilteredPageNumberLimit(5);
    setminFilteredPageNumberLimit(0);
    setCurrentFilteredPage(1);
  };

  const handleAtsNextPageClick = () => {
    setCurrentFilteredPage(currentFilteredPage + 1);
    if (currentFilteredPage + 1 > maxFilteredPageNumberLimit) {
      setmaxFilteredPageNumberLimit(
        maxFilteredPageNumberLimit + filteredPageNumberLimit
      );
      setminFilteredPageNumberLimit(
        minFilteredPageNumberLimit + filteredPageNumberLimit
      );
    }
  };

  const handleAtsPrevPageClick = () => {
    setCurrentFilteredPage(currentFilteredPage - 1);
    if ((currentFilteredPage - 1) % filteredPageNumberLimit === 0) {
      setmaxFilteredPageNumberLimit(
        maxFilteredPageNumberLimit - filteredPageNumberLimit
      );
      setminFilteredPageNumberLimit(
        minFilteredPageNumberLimit - filteredPageNumberLimit
      );
    }
  };

  let pageAtsIncrementBtn = null;
  if (!segmentAtsTabActive) {
    if (pagesForFilteredEmailResult.length > maxFilteredPageNumberLimit) {
      pageAtsIncrementBtn = (
        <li onClick={handleAtsNextPageClick}> &hellip; </li>
      );
    }
  } else {
    if (pagesForFilteredSegmentResult.length > maxFilteredPageNumberLimit) {
      pageAtsIncrementBtn = (
        <li onClick={handleAtsNextPageClick}> &hellip; </li>
      );
    }
  }

  let pageAtsDecrementBtn = null;
  if (minFilteredPageNumberLimit >= 1) {
    pageAtsDecrementBtn = <li onClick={handleAtsPrevPageClick}> &hellip; </li>;
  }

  return (
    <>
      {openATSModal && (
        <ContactListATSModal
          isOpen={openATSModal}
          closeModalHandler={() => setOpenATSModal(false)}
          segmentListData={segmentList}
          emailListData={emailList}
          setShowLastDataBlock={setShowLastDataBlock}
          selectedEmailContacts={selectedEmailContacts}
          setSelectedEmailContacts={setSelectedEmailContacts}
          selectedSegmentContacts={selectedSegmentContacts}
          setSelectedSegmentContacts={setSelectedSegmentContacts}
          getApprovedEmailList={fetchEmail}
          getApprovedSegmentList={fetchSegments}
          deselectedEmailContacts={deselectedEmailContacts}
          setDeselectedEmailContacts={setDeselectedEmailContacts}
          deselectedSegmentContacts={deselectedSegmentContacts}
          setDeselectedSegmentContacts={setDeselectedSegmentContacts}
        />
      )}

      {isEmpty(approvedEmailList) && isEmpty(approvedSegmentList) ? (
        <LoaderWrapper isLoading={loading}>
          <ATSListContainer style={{ margin: '100px auto' }}>
            <Flex display="flex" justifyContent="center">
              <img
                alt=""
                src="/assets/images/ats-media-icon.svg"
                style={{ paddingBottom: '10px' }}
              />
            </Flex>
            <Flex
              style={{
                width: '100%',
                fontSize: '18px',
                color: '#000000',
                fontWeight: 500,
                justifyContent: 'center',
                paddingBottom: '5px',
              }}
            >
              You have not created an approved to share list
            </Flex>
            <Flex
              style={{
                width: '100%',
                fontSize: '14px',
                color: '#475467',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              Select your default approved to share list for any auto posts
              across email
            </Flex>
            <Flex style={{ width: '100%', justifyContent: 'center' }}>
              <Button
                styles={{ borderRadius: '4px' }}
                onClick={handleApproveToShare}
                bgColor="#816EF8"
                borderColor="#816EF8"
              >
                <Flex flexDirection="row">
                  <img
                    alt=""
                    src="/assets/images/new-plus-icon.svg"
                    style={{ marginRight: '8px' }}
                  />
                  <span style={{ display: 'flex', alignItems: 'center' }}>
                    Create Approved to Share List
                  </span>
                </Flex>
              </Button>
            </Flex>
          </ATSListContainer>
        </LoaderWrapper>
      ) : (
        <Flex
          width="100%"
          height="600px"
          marginTop="10px"
          bgColor="#fff"
          display="flex"
          flexDirection="column"
          style={{
            borderRadius: '6px',
            border: '1px solid #bccdd4',
            backgroundColor: '#FFFFFF',
            marginBottom: '10px',
          }}
        >
          <Flex
            display="flex"
            justifyContent="space-between"
            flexDirection="row"
            width="100%"
          >
            <Flex
              display="flex"
              justifyContent="center"
              flexDirection="column"
              padding="5px 0px 8px 12px"
            >
              <Flex color="#101828" fontSize="18px">
                Approved to Share List
              </Flex>
              <Flex color="#475467" fontSize="14px">
                Select your default approved to share list for any auto posts
                across email
              </Flex>
            </Flex>
            <SearchContainer
              style={{
                margin: openAtsSearch ? '9px 15px' : '10px 15px 10px',
                justifyContent: 'end',
                width: '20%',
                alignItems: 'center',
              }}
              searchActive={openAtsSearch}
            >
              <SearchBar
                setActiveSearch={setOpenAtsSearch}
                activeSearch={openAtsSearch}
                searchString={atsSearchString}
                setSearchString={setAtsSearchString}
                searchHandler={
                  !segmentAtsTabActive
                    ? handleEmailATSSearch
                    : handleSegmentATSSearch
                }
              />
              <Flex
                display="flex"
                justifyContent="center"
                style={{ paddingLeft: '7px' }}
              >
                <img
                  alt=""
                  src="/assets/images/ats-edit-button-icon.svg"
                  style={{
                    marginRight: '5px',
                    height: '41px',
                    width: '84px',
                    cursor: 'pointer',
                  }}
                  onClick={handleEdit}
                />
              </Flex>
            </SearchContainer>
          </Flex>
          <TabContainer style={{ width: '100%' }}>
            <ContactsTabItem
              onClick={() => handleToggle(false)}
              style={{
                borderBottom: !segmentAtsTabActive
                  ? '1px solid #101828'
                  : '0px',
                color: !segmentAtsTabActive ? '#000000' : '#aaa',
              }}
            >
              <div className="chip" style={{ fontSize: '14px' }}>
                Email Lists
              </div>
            </ContactsTabItem>
            <ContactsTabItem
              onClick={() => handleToggle(true)}
              style={{
                borderBottom: segmentAtsTabActive ? '1px solid #101828' : '0px',
                color: segmentAtsTabActive ? '#000000' : '#aaa',
              }}
            >
              <div className="chip" style={{ fontSize: '14px' }}>
                Segments
              </div>
            </ContactsTabItem>
          </TabContainer>
          <Flex
            display="flex"
            width="100%"
            flexDirection="row"
            style={{
              backgroundColor: '#F9FAFB',
              fontSize: '15px',
              color: '#101828',
              height: '60px',
            }}
          >
            <Flex
              width="60%"
              display="flex"
              style={{ paddingLeft: '20px', alignItems: 'center' }}
            >
              List Name
            </Flex>
            <Flex width="25%" display="flex" style={{ alignItems: 'center' }}>
              No. of Contacts
            </Flex>
            <Flex
              width="15%"
              display="flex"
              style={{ marginTop: '8px' }}
            ></Flex>
          </Flex>
          {!segmentAtsTabActive ? (
            <>
              <ApproveToShareFinalBlockWrapper>
                <LoaderWrapper isLoading={emailLoading}>
                  {!isEmpty(currenListtItems) ? (
                    currenListtItems.length > 0 &&
                    currenListtItems.map((item, index) => (
                      <>
                        <Flex
                          display="flex"
                          flexDirection="row"
                          width="100%"
                          alignitems="center"
                          padding="5px"
                        >
                          <Flex
                            width="60%"
                            display="flex"
                            justifyContent="left"
                            style={{
                              color: '#101828',
                              fontSize: '14px',
                              padding: '5px 10px 3px 14px',
                              alignItems: 'center',
                              wordBreak: 'break-all',
                              textAlign: 'left',
                            }}
                          >
                            {item?.name && item?.name?.length > 70 ? (
                              <Tooltip
                                title={item?.name}
                                placement="top"
                                arrow
                                TransitionComponent={Zoom}
                              >
                                {`${item?.name.substring(0, 70)}...`}
                              </Tooltip>
                            ) : (
                              item?.name
                            )}
                          </Flex>
                          <Flex
                            style={{
                              width: '25%',
                              display: 'flex',
                              height: '35px',
                              padding: '5px 5px 3px 6px',
                              color: '#101828',
                            }}
                          >
                            {item?.contacts?.count}
                          </Flex>
                          <Flex
                            width="15%"
                            display="flex"
                            justifyContent="center"
                            style={{ padding: '5px 5px 3px 0px' }}
                          >
                            <img
                              alt=""
                              src="/assets/images/new-delete-icon.svg"
                              style={{
                                height: '24px',
                                width: '24px',
                                cursor: 'pointer',
                                display: 'flex',
                                alignItems: 'center',
                              }}
                              onClick={() => handleDiscardEmailContact(item)}
                            />
                          </Flex>
                        </Flex>
                        <hr
                          style={{
                            width: '100%',
                            border: '0.5px solid #e4e7ec',
                            marginBottom: '0px',
                            marginTop: '0px',
                          }}
                        />
                      </>
                    ))
                  ) : (
                    <span
                      style={{
                        display: 'flex',
                        padding: '10px',
                        margin: '50px auto',
                      }}
                    >
                      No Records Found
                    </span>
                  )}
                </LoaderWrapper>
              </ApproveToShareFinalBlockWrapper>
              <hr
                style={{
                  width: '100%',
                  border: '0.5px solid #E4E7EC',
                  margin: 'auto',
                }}
              />
              <ul className="pageNumbers">
                <Flex
                  justifyContent="space-between"
                  alignItems="center"
                  display="flex"
                  flexDirection="row"
                  padding="8px 14px 8px 14px"
                  width="100%"
                >
                  <Button
                    type="button"
                    width="118px"
                    height="38px"
                    bgColor="#FFFFFF"
                    styles={{
                      border: '1px solid #E4E7EC',
                      borderRadius: '4px',
                      boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.12)',
                      color: '#101828',
                      display: 'flex',
                      alignItems: 'center',
                      padding: '0px',
                    }}
                    margin="0px"
                    onClick={handleAtsPrevPageClick}
                    disabled={
                      currentFilteredPage === pagesForFilteredEmailResult[0]
                        ? true
                        : false
                    }
                    //onClick={() => paginationClickHandler('before')}
                    //disabled={page < 1 ? true : false}
                  >
                    <Flex
                      display="flex"
                      flexDirection="row"
                      alignItems="center"
                    >
                      <img
                        alt=""
                        src="/assets/images/ats-back-arrow.svg"
                        style={{ marginLeft: '6px', marginRight: '2px' }}
                      />
                      <Flex
                        style={{
                          display: 'flex',
                          padding: '5px',
                          fontSize: '14px',
                        }}
                      >
                        Previous
                      </Flex>
                    </Flex>
                  </Button>
                  <Flex
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    padding="3px 5px 3px"
                    height="40px"
                    sytle={{ fontSize: '12px' }}
                  >
                    <li style={{ display: 'flex' }}>
                      {pageAtsDecrementBtn}
                      {renderEmailPageNumbers}
                      {pageAtsIncrementBtn}
                    </li>
                  </Flex>
                  <Button
                    type="button"
                    width="94px"
                    height="38px"
                    styles={{
                      border: '1px solid #E4E7EC',
                      borderRadius: '4px',
                      boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.12)',
                      color: '#101828',
                      display: 'flex',
                      alignItems: 'center',
                      padding: '8px',
                    }}
                    bgColor="#FFFFFF"
                    margin="0px"
                    onClick={handleAtsNextPageClick}
                    disabled={
                      currentFilteredPage ===
                      pagesForFilteredEmailResult[
                        pagesForFilteredEmailResult.length - 1
                      ]
                        ? true
                        : false
                    }
                  >
                    <Flex
                      display="flex"
                      flexDirection="row"
                      alignItems="center"
                    >
                      <Flex
                        style={{
                          display: 'flex',
                          padding: '7px',
                          fontSize: '14px',
                        }}
                      >
                        Next
                      </Flex>
                      <img
                        alt=""
                        src="/assets/images/ats-next-arrow.svg"
                        style={{ marginLeft: '2px', marginRight: '6px' }}
                      />
                    </Flex>
                  </Button>
                </Flex>
              </ul>
            </>
          ) : (
            <>
              <ApproveToShareFinalBlockWrapper>
                <LoaderWrapper isLoading={segmentLoading}>
                  {!isEmpty(currenListtItems) ? (
                    currenListtItems.length > 0 &&
                    currenListtItems.map((item, index) => (
                      <>
                        <Flex
                          display="flex"
                          flexDirection="row"
                          width="100%"
                          alignItems="center"
                          padding="5px"
                        >
                          <Flex
                            width="60%"
                            display="flex"
                            justifyContent="left"
                            style={{
                              color: '#101828',
                              fontSize: '14px',
                              padding: '9px 5px 8px 14px',
                              alignItems: 'center',
                            }}
                          >
                            {item?.name && item?.name?.length > 70 ? (
                              <Tooltip
                                title={item?.name}
                                placement="top"
                                arrow
                                TransitionComponent={Zoom}
                              >
                                {`${item?.name.substring(0, 70)}...`}
                              </Tooltip>
                            ) : (
                              item?.name
                            )}
                          </Flex>
                          <Flex
                            style={{
                              width: '25%',
                              display: 'flex',
                              height: '35px',
                              padding: '9px 5px 8px 6px',
                              color: '#101828',
                              alignItems: 'center',
                            }}
                          >
                            {item?.number_of_contact}
                          </Flex>
                          <Flex
                            width="15%"
                            display="flex"
                            justifyContent="center"
                            style={{ padding: '9px 5px 8px 0px' }}
                          >
                            <img
                              alt=""
                              src="/assets/images/new-delete-icon.svg"
                              style={{
                                height: '24px',
                                width: '24px',
                                cursor: 'pointer',
                                display: 'flex',
                                alignItems: 'center',
                              }}
                              onClick={() => handleDiscardSegmentContact(item)}
                            />
                          </Flex>
                        </Flex>
                        <hr
                          style={{
                            width: '100%',
                            border: '0.5px solid #e4e7ec',
                            marginBottom: '0px',
                            marginTop: '0px',
                          }}
                        />
                      </>
                    ))
                  ) : (
                    <span
                      style={{
                        display: 'flex',
                        padding: '10px',
                        margin: '50px auto',
                      }}
                    >
                      No Records Found
                    </span>
                  )}
                </LoaderWrapper>
              </ApproveToShareFinalBlockWrapper>
              <hr
                style={{
                  width: '100%',
                  border: '0.5px solid #E4E7EC',
                  margin: 'auto',
                }}
              />
              <ul className="pageNumbers">
                <Flex
                  justifyContent="space-between"
                  alignItems="center"
                  display="flex"
                  flexDirection="row"
                  padding="8px 14px 8px 14px"
                  width="100%"
                >
                  <Button
                    type="button"
                    width="118px"
                    height="38px"
                    bgColor="#FFFFFF"
                    styles={{
                      border: '1px solid #E4E7EC',
                      borderRadius: '4px',
                      boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.12)',
                      color: '#101828',
                      display: 'flex',
                      alignItems: 'center',
                      padding: '0px',
                    }}
                    margin="0px"
                    onClick={handleAtsPrevPageClick}
                    disabled={
                      currentFilteredPage === pagesForFilteredSegmentResult[0]
                        ? true
                        : false
                    }
                    //onClick={() => paginationClickHandler('before')}
                    //disabled={page < 1 ? true : false}
                  >
                    <Flex
                      display="flex"
                      flexDirection="row"
                      alignItems="center"
                    >
                      <img
                        alt=""
                        src="/assets/images/ats-back-arrow.svg"
                        style={{ marginLeft: '6px', marginRight: '2px' }}
                      />
                      <Flex
                        style={{
                          display: 'flex',
                          padding: '5px',
                          fontSize: '14px',
                        }}
                      >
                        Previous
                      </Flex>
                    </Flex>
                  </Button>
                  <Flex
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    padding="3px 5px 3px"
                    height="40px"
                    sytle={{ fontSize: '12px' }}
                  >
                    <li style={{ display: 'flex' }}>
                      {pageAtsDecrementBtn}
                      {renderSegmentPageNumbers}
                      {pageAtsIncrementBtn}
                    </li>
                  </Flex>
                  <Button
                    type="button"
                    width="94px"
                    height="38px"
                    styles={{
                      border: '1px solid #E4E7EC',
                      borderRadius: '4px',
                      boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.12)',
                      color: '#101828',
                      display: 'flex',
                      alignItems: 'center',
                      padding: '8px',
                    }}
                    bgColor="#FFFFFF"
                    margin="0px"
                    onClick={handleAtsNextPageClick}
                    disabled={
                      currentFilteredPage ===
                      pagesForFilteredSegmentResult[
                        pagesForFilteredSegmentResult.length - 1
                      ]
                        ? true
                        : false
                    }
                  >
                    <Flex
                      display="flex"
                      flexDirection="row"
                      alignItems="center"
                    >
                      <Flex
                        style={{
                          display: 'flex',
                          padding: '7px',
                          fontSize: '14px',
                        }}
                      >
                        Next
                      </Flex>
                      <img
                        alt=""
                        src="/assets/images/ats-next-arrow.svg"
                        style={{ marginLeft: '2px', marginRight: '6px' }}
                      />
                    </Flex>
                  </Button>
                </Flex>
              </ul>
            </>
          )}
        </Flex>
      )}
    </>
  );
};

export default ContactsATSList;
