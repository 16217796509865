import { Flex } from '@rebass/grid';
import { isEmpty } from 'lodash-es';
import React, { useState, useEffect } from 'react';
import CustomCloutCheckbox from '../../../../../components/CustomCloutCheckbox/CustomCloutCheckbox';
import { CampaignsDropdownList } from './CampaignAnalyticsDropdown.styles';

const CampaignAnalyticsDropdown = ({ campaignData, selectedCampaigns, setSelectedCampaigns }) => {

    const [showDropdown, toggleDropdown] = useState(false);
    const [totalCampaigns, setTotalCampaigns] = useState(0);

    useEffect(() => {
        let count = 0;
        campaignData.forEach(campaign => {
            if(campaign.type === 'series')
                count+=campaign.campaigns.length
            else
                ++count;
        });
        setTotalCampaigns(count);
    }, []);

    const campaignSelectHandler = (e, metaInfoObj) => {
        let updatedSelection = [];
        if(e.target.checked) {
            if(e.target.value === 'selectAll'){
                campaignData.forEach(campaign => {
                    if(campaign.type === 'series') 
                        updatedSelection = updatedSelection.concat(campaign.campaigns.map(subCam => subCam.id)) 
                    else
                        updatedSelection.push(campaign.id)
                });
            }
            else {
                if(metaInfoObj.type === 'series' && !metaInfoObj.isSub) {
                    const selectedSeriesCampaign = campaignData.find(campaign => campaign.id === metaInfoObj.seriesId);
                    if(selectedSeriesCampaign) {
                        updatedSelection = selectedCampaigns.concat(selectedSeriesCampaign.campaigns.map(campaign => campaign.id));
                        updatedSelection = [...new Set(updatedSelection)];
                    } 
                }  
                else
                    updatedSelection = [...selectedCampaigns, e.target.value];
            }
        }
        else {
            if(e.target.value === 'selectAll') 
                updatedSelection = [];
            else {
                if(metaInfoObj.type === 'series' && !metaInfoObj.isSub) {
                    const selectedSeriesCampaign = campaignData.find(campaign => campaign.id === metaInfoObj.seriesId);
                    if(selectedSeriesCampaign)
                        updatedSelection = selectedCampaigns.filter(campaignId => !selectedSeriesCampaign.campaigns.map(campaign => campaign.id).includes(campaignId))
                }
                else
                    updatedSelection = selectedCampaigns.filter(ele => ele !== e.target.value)
            }
        }
        setSelectedCampaigns(updatedSelection)
    }


    return (
        <Flex 
            width='200px' 
            justifyContent='center' 
            flexDirection='column' 
            style={{ 
                position: 'relative', 
                marginLeft: '5px',
                cursor: 'pointer',
                zIndex: '9999'
            }}
            onMouseLeave={() => toggleDropdown(false)}
        >
            <div
                style={{
                    textAlign: 'left',
                    padding: '5px 10px 5px 25px',
                    border: '1px solid #6351ed',
                    borderRadius: '4px',
                    color: selectedCampaigns.length > 0 ? '#666666' : '#adadad'
                }}
                onMouseEnter={() => toggleDropdown(true)}
            >
                Select Campaign(s)
            </div>
            {showDropdown && !isEmpty(campaignData) && (
            // {true && (
                <Flex 
                    justifyContent='center' 
                    flexDirection='column' 
                    style={{ 
                        position: 'absolute', 
                        border: '1px solid #adadad', 
                        borderRadius: '4px', 
                        top: '83%',
                        width: '250px'
                    }}
                    onMouseLeave={() => toggleDropdown(false)}
                    backgroundColor='#fff'
                >
                    <Flex justifyContent='flex-start' padding='4px 10px' alignItems='flex-start'>
                        <CustomCloutCheckbox 
                            changeHandler={campaignSelectHandler} 
                            inputId='option-select-all'
                            inputValue='selectAll'
                            borderRadius='2px'
                            boxChecked={selectedCampaigns.length === totalCampaigns}
                        />
                        <label 
                            htmlFor='option-select-all' 
                            style={{ 
                                fontWeight: 'bold', 
                                color: '#000', 
                                marginLeft: '5px',
                                cursor: 'pointer'
                            }}
                        >
                            Select All
                        </label>
                    </Flex>
                    <CampaignsDropdownList>
                        {!isEmpty(campaignData) && campaignData.map(campaign => (
                        // {!isEmpty(campaignData) && campaignData.filter(campaign => campaign.type === 'series').map(campaign => (
                            <li key={`campaign-${campaign.id}`} style={{ marginTop: '8px' }}>
                                <Flex justifyContent='flex-start' padding='4px 10px' alignItems='flex-start'>
                                    <CustomCloutCheckbox 
                                        changeHandler={e => campaignSelectHandler(e, {type: campaign.type, isSub: false, seriesId: campaign.id})} 
                                        inputId={`option-${campaign.id}`}
                                        inputValue={campaign.id}
                                        borderRadius='2px'
                                        boxChecked={selectedCampaigns.includes(campaign.id) ||
                                            (campaign.type === 'series' && 
                                             campaign.campaigns.every(sub => selectedCampaigns.includes(sub.id))
                                            )
                                        } 
                                    />
                                    <label 
                                        htmlFor={`option-${campaign.id}`} 
                                        style={{ marginLeft: '5px', cursor: 'pointer' }} 
                                        color='#49484a'>
                                        {campaign.name}
                                    </label>
                                </Flex>
                                {campaign.campaigns && (
                                    <ul style={{ listStyleType: 'none', padding: '0px 25px' }}>
                                        {campaign.campaigns.map(subCampaign => (
                                            <li key={`subcampaign-${subCampaign.id}`}>
                                                <Flex justifyContent='flex-start' padding='4px 10px' alignItems='flex-start'>
                                                    <CustomCloutCheckbox 
                                                        changeHandler={e => campaignSelectHandler(e, {type: 'series', isSub: true, seriesId: campaign.id})} 
                                                        inputId={`option-${subCampaign.id}`}
                                                        inputValue={subCampaign.id}
                                                        borderRadius='2px'
                                                        boxChecked={selectedCampaigns.includes(subCampaign.id)} 
                                                    />
                                                    <label 
                                                        htmlFor={`option-${subCampaign.id}`} 
                                                        style={{ marginLeft: '5px', cursor: 'pointer' }} 
                                                        color='#49484a'>
                                                        {subCampaign.name}
                                                    </label>
                                                </Flex>
                                            </li>
                                        ))}
                                    </ul>
                                )}
                            </li>
                        ))}
                    </CampaignsDropdownList>
                </Flex>
            )}
        </Flex>
    )
}

export default CampaignAnalyticsDropdown