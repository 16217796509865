import React, { useEffect, useState } from 'react';
import { useInfiniteQuery, useMutation, useQueryClient } from 'react-query';
import { CircularProgress, Tooltip } from '@mui/material';
import InfiniteScroll from 'react-infinite-scroll-component';
import DialogModal from 'src/componentsV2/DialogModal';
import Button from 'src/componentsV2/Button';
import Article from '../common/components/Article';
import ArticleSkeleton from '../common/components/Article/Skeleton';
import FinalStep from '../HeroSection/AddContent/FinalStep';
import Cascade from './Cascade';
import Share from 'src/assets/icons/Share';
import Pencil from 'src/assets/icons/Pencil';
import TrashCan from 'src/assets/icons/TrashCan';
import { cloutV2Endpoints } from 'src/services/helpers';
import apiInstance from 'src/services/api';
import { getMediaImage } from 'src/utils';
import { transformMyContentData } from './utils';
import { deleteArticle } from './mutation';
import { TABS } from './constants';
import {
  Articles,
  CascadeButton,
  DeleteModalWrapper,
  EditContent,
  IconButton,
  Label,
  ModalActions,
  PrimaryActions,
  RadioButton,
  RadioButtonGroup,
  RadioButtonWrapper,
  Wrapper,
} from './styles';
import ZeroState from '../common/components/ZeroState';
import { ArticlesSkeleton } from '../styles';

function MyContent({
  isAdmin = false,
  channelId = '',
  firmSettings,
  isEnterpriseLevelAdmin,
  isFirmLevelAdmin,
  userData,
}) {
  const queryClient = useQueryClient();
  const [showEditContentModal, setShowEditContentModal] = useState(false);
  const [showCascadeModal, setShowCascadeModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedTab, setSelectedTab] = useState(
    isEnterpriseLevelAdmin ? 'my_library' : 'all'
  );
  const [selectedArticle, setSelectedArticle] = useState(null);
  const [selectedBookmarkArticle, setSelectedBookmarkArticle] = useState('');

  const LIMIT = 10;

  useEffect(() => {
    if (isEnterpriseLevelAdmin) {
      setSelectedTab('my_library');
    }
  }, [isEnterpriseLevelAdmin]);

  const {
    data,
    isLoading,
    isFetching,
    isSuccess,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    refetch,
  } = useInfiniteQuery(
    ['discover-my-content', selectedTab, channelId],
    async ({ pageParam = 1 }) => {
      const response = await apiInstance()?.get(
        `${cloutV2Endpoints?.baseUrl}/discover/channel/${channelId}/content?page_no=${pageParam}&limit=${LIMIT}&filter=${selectedTab}`
      );
      return transformMyContentData(response?.result);
    },
    {
      getNextPageParam: (lastPage) => {
        return lastPage?.pagination?.next_page;
      },
    }
  );

  const { mutate, isLoading: deleteLoader } = useMutation(deleteArticle, {
    onSuccess: () => {
      refetch();
    },
  });

  const handleDeleteArticle = () => {
    try {
      mutate(selectedArticle?.id, {
        onSuccess: () => {
          setShowDeleteModal(false);
        },
      });
    } catch (error) {
      console.log(error);
    }
  };

  const invalidateQueries = (articleId) => {
    setSelectedBookmarkArticle(articleId);
    queryClient.refetchQueries(['discover-my-content', selectedTab]);
  };

  const contentData =
    isSuccess &&
    data?.pages.map((page) => {
      if (page?.articles?.length === 0) return <ZeroState />;
      return page?.articles?.map((article) => {
        return (
          <>
            {isFetching && selectedBookmarkArticle === article?.id ? (
              <ArticleSkeleton />
            ) : (
              <Article
                article={{
                  ...article,
                  tags: article?.tags?.map((tag) => tag?.name) ?? [],
                }}
                key={article?.id}
                primaryActions={
                  (selectedTab === 'my_library' || article?.is_library) && (
                    <PrimaryActions>
                      {isAdmin && (
                        <CascadeButton
                          variant="outline"
                          onClick={() => {
                            setShowCascadeModal(true);
                            setSelectedArticle(article);
                          }}
                        >
                          <Share /> <h4>Cascade</h4>
                        </CascadeButton>
                      )}
                      <Tooltip title="Edit Article">
                        <IconButton
                          onClick={() => {
                            setShowEditContentModal(true);
                            setSelectedArticle({
                              content_id: article?.id,
                              text: {
                                title: article?.name,
                                description: article?.description,
                                createdDate: article?.created_date,
                                tags: article?.tags ?? [],
                                image: getMediaImage(
                                  article?.media_url,
                                  'original'
                                ),
                              },
                              themes: article?.tags ?? [],
                            });
                          }}
                        >
                          <Pencil />
                        </IconButton>
                      </Tooltip>
                    </PrimaryActions>
                  )
                }
                secondaryActions={
                  (selectedTab === 'my_library' || article?.is_library) && (
                    <Tooltip title="Delete">
                      <IconButton
                        onClick={() => {
                          setShowDeleteModal(true);
                          setSelectedArticle(article);
                        }}
                      >
                        <TrashCan />
                      </IconButton>
                    </Tooltip>
                  )
                }
                invalidateQueries={invalidateQueries}
              />
            )}
          </>
        );
      });
    });

  const loader = () => {
    return (
      <ArticlesSkeleton>
        {Array.from(Array(6)?.keys())?.map((article) => (
          <ArticleSkeleton key={article} />
        ))}
      </ArticlesSkeleton>
    );
  };

  return (
    <>
      <Wrapper>
        {!(isEnterpriseLevelAdmin && isAdmin) && (
          <RadioButtonGroup>
            {TABS.map(({ label, value }) => (
              <RadioButtonWrapper
                key={value}
                onClick={() => setSelectedTab(value)}
              >
                <RadioButton
                  type="radio"
                  checked={selectedTab === value}
                  value={selectedTab}
                  onChange={() => setSelectedTab(value)}
                />
                <Label>{label}</Label>
              </RadioButtonWrapper>
            ))}
          </RadioButtonGroup>
        )}

        {isLoading ? (
          loader()
        ) : (
          <InfiniteScroll
            dataLength={+data?.pages?.length * 9 || 0}
            next={() => fetchNextPage()}
            hasMore={hasNextPage && !isLoading}
            loader={loader()}
          >
            <Articles>{contentData}</Articles>
          </InfiniteScroll>
        )}
        {isFetchingNextPage && loader()}
      </Wrapper>

      <DialogModal
        show={showCascadeModal}
        toggleModalVisibility={(value) => {
          setShowCascadeModal(value);
          setSelectedArticle(null);
        }}
        maxWidth="sm"
        title="Cascading to all members in your firm"
        showTitle
        showCloseButton
      >
        <Cascade
          content={selectedArticle}
          setShowCascadeModal={setShowCascadeModal}
          refetch={refetch}
          isFirmLevelAdmin={isFirmLevelAdmin}
          firmSettings={firmSettings}
          userData={userData}
        />
      </DialogModal>

      <DialogModal
        show={showEditContentModal}
        toggleModalVisibility={(value) => {
          setShowEditContentModal(value);
          setSelectedArticle(null);
        }}
        maxWidth="xs"
        title="Edit Content"
        showTitle
        showCloseButton
      >
        <EditContent>
          <FinalStep
            content={selectedArticle}
            setShowAddContentModal={setShowEditContentModal}
            isEdit={true}
          />
        </EditContent>
      </DialogModal>

      <DialogModal
        show={showDeleteModal}
        toggleModalVisibility={(value) => {
          setShowDeleteModal(value);
          setSelectedArticle(null);
        }}
        maxWidth="xs"
        title="Are you sure you want to delete the article?"
        showTitle
        showCloseButton
      >
        <DeleteModalWrapper>
          <ModalActions>
            <Button
              variant="outline"
              fullWidth
              onClick={() => setShowDeleteModal(false)}
            >
              Cancel
            </Button>
            <Button fullWidth onClick={handleDeleteArticle}>
              {deleteLoader ? <CircularProgress size={15} /> : 'Delete'}
            </Button>
          </ModalActions>
        </DeleteModalWrapper>
      </DialogModal>
    </>
  );
}

export default MyContent;
