import React, { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useQueryClient } from 'react-query';
import ReactSkeleton from 'react-loading-skeleton';
import Article from '../common/components/Article';
import Sidebar from '../common/components/Sidebar';
import ArticleSkeleton from '../common/components/Article/Skeleton';
import ZeroState from '../common/components/ZeroState';
import useQueryForSearchResults from './useQueryForSearchResults';
import { Articles } from '../styles';
import {
  Results,
  SearchResultsWrapper,
  Title,
  ZeroStateWrapper,
} from './styles';

function SearchResults({ searchString }) {
  const queryClient = useQueryClient();
  const [selectedTopics, setSelectedTopics] = useState([]);
  const [selectedArticleId, setSelectedArticleId] = useState('');
  const { register, watch } = useForm({
    defaultValues: {
      link: 0,
      pdf: 0,
      image: 0,
      topics: '',
      bookmark: false,
      filter: [],
    },
  });
  const watchedValues = watch();

  const formats = useMemo(() => {
    const { link, pdf, image } = watchedValues;

    return Object.keys({ link, pdf, image })?.reduce((acc, curr) => {
      if (watchedValues?.[curr]) {
        const value = curr === 'image' ? 'img' : curr;
        return [...acc, value];
      }
      return acc;
    }, []);
  }, [watchedValues]);

  const { data, isLoading, isFetching } = useQueryForSearchResults({
    searchString,
    filter: watchedValues?.filter ?? [],
    formats,
    selectedTopics: selectedTopics?.map((topic) => topic?.id) ?? [],
  });

  const invalidateQueries = (articleId) => {
    queryClient.refetchQueries(['discover-search-results']);
    setSelectedArticleId(articleId);
  };

  return (
    <>
      <Title>
        {isLoading ? (
          <ReactSkeleton width={300} height={30} />
        ) : (
          <>
            Showing {data?.length ?? 0} results for “{searchString}”
          </>
        )}
      </Title>

      <SearchResultsWrapper>
        <Sidebar
          register={register}
          selectedTopics={selectedTopics}
          setSelectedTopics={setSelectedTopics}
          section="search"
        />

        <Results>
          {isLoading ? (
            <Articles>
              {Array.from(Array(3)?.keys())?.map((article) => (
                <div key={article}>
                  <ArticleSkeleton />
                </div>
              ))}
            </Articles>
          ) : (
            <>
              {Array.isArray(data) && data?.length > 0 ? (
                <Articles>
                  {data?.map((article) => {
                    if (isFetching && article?.id === selectedArticleId) {
                      return (
                        <Articles>
                          <ArticleSkeleton />
                        </Articles>
                      );
                    }
                    return (
                      <Article
                        key={article?.id}
                        article={article}
                        invalidateQueries={invalidateQueries}
                      />
                    );
                  })}
                </Articles>
              ) : (
                <ZeroStateWrapper>
                  <ZeroState title="No Results found." />
                </ZeroStateWrapper>
              )}
            </>
          )}
        </Results>
      </SearchResultsWrapper>
    </>
  );
}

export default SearchResults;
