import { Box, Flex } from '@rebass/grid';
import React from 'react'
import { ToggleButton } from '../../../containers/ConfigureContainer/screenConfigure.styles';
import { MioRadio, MioRadioSpan } from '../../CommonStyles/EmailTableStyles';

const LeadCaptureOption = ({ 
  fromClients, 
  selectedLeadCaptureOption, 
  setSelectedLeadCaptureOption, 
  setSelectedReferralCaptureOption,
  selectecdReferralCaptureOption,
  top,
  leadStyles,
  referralStyles,
  paddingTop,
  switchHandleTop,
  fromSocialPreview,
  leadCaptureText,
}) => {

  const handleLeadCaptureToggle = (checked) => {
    setSelectedLeadCaptureOption(checked);
    if(selectecdReferralCaptureOption) {
      setSelectedReferralCaptureOption(!checked);
    }
  }

  const handleReferralCaptureToggle = (checked) => {
    setSelectedReferralCaptureOption(checked);
    if(selectedLeadCaptureOption) {
      setSelectedLeadCaptureOption(!checked);
    } 
  }

  return (
    <>
    {!fromClients ? (
        <Flex
          width={1}
          alignItems="center"
          justifyContent="center"
          px="0px"
          pt={paddingTop || "17px"}
        >
          <Box style={{ marginRight: '13px' }}>
            <Flex width={1} alignItems="center" pb="5px">
              <span style={leadStyles || {padding: "1px 0px 0px"}}>{leadCaptureText || 'Enable Lead Capture'}</span>
              <ToggleButton
                className="react-switch"
                onChange={(checked) => handleLeadCaptureToggle(checked)}
                checked={selectedLeadCaptureOption}
                onColor="#6351ed"
                offColor="#ccc"
                offHandleColor="#fff"
                onHandleColor="#fff"
                handleDiameter={10}
                uncheckedIcon=""
                checkedIcon=""
                boxShadow=""
                activeBoxShadow=""
                type="autoPost"
                left='0px'
                margin="-3px 5px 0px"
                top={top}
                switchHandleTop={switchHandleTop}
              />
              &nbsp;
              {/* {!fromSocialPreview && (
                <>
                  <span style={referralStyles || {padding: "1px 0px 0px"}}>Enable Referral Capture</span>
                  <ToggleButton
                    className="react-switch"
                    onChange={(checked) => handleReferralCaptureToggle(checked)}
                    checked={selectecdReferralCaptureOption}
                    onColor="#6351ed"
                    offColor="#ccc"
                    offHandleColor="#fff"
                    onHandleColor="#fff"
                    handleDiameter={10}
                    uncheckedIcon=""
                    checkedIcon=""
                    boxShadow=""
                    activeBoxShadow=""
                    type="autoPost"
                    left='0px'
                    margin="-3px 5px 0px"
                    top={top}
                    switchHandleTop={switchHandleTop}
                  />
                </>
              )} */}
            </Flex>
          </Box>
        </Flex>
    ) : (
      null
    //   <Flex
    //   width={1}
    //   alignItems="center"
    //   justifyContent="center"
    //   px="0px"
    //   pt="17px"
    // >
    //   <Box style={{ textAlign: 'center', fontSize: '12px', marginRight: '6px' }}>Do you want to enable Lead capture or Referral?</Box>
    //   <Box style={{ marginRight: '13px' }}>
    //     <Flex width={1} alignItems="center">
    //       <MioRadioSpan width="auto" noBorder>
    //         <MioRadio
    //           id={'leadCheckOther'}
    //           name="leadCheck"
    //           onClick={() => handleLeadOptionCheck('other')}
    //           defaultChecked={selectedLeadCaptureOption === 'other'}
    //         />
    //         <label htmlFor="leadCheckOther">No</label>
    //       </MioRadioSpan>
    //       <MioRadioSpan width="auto" noBorder defaultChecked={selectedLeadCaptureOption === 'yes'}>
    //         <MioRadio
    //           id={'leadCheckYes'}
    //           name="leadCheck"
    //           onClick={() => handleLeadOptionCheck('yes')}
    //           defaultChecked={selectedLeadCaptureOption === 'yes'}
    //         />
    //         <label htmlFor="leadCheckYes">Lead Capture</label>
    //       </MioRadioSpan>
    //       <MioRadioSpan noBorder  width="auto">
    //         <MioRadio
    //           id={'leadCheckNo'}
    //           name="leadCheck"
    //           onClick={() => handleLeadOptionCheck('no')}
    //           defaultChecked={selectedLeadCaptureOption === 'no'}
    //         />
    //         <label htmlFor="leadCheckNo">Referral Capture</label>
    //       </MioRadioSpan>
    //     </Flex>
    //   </Box>
    // </Flex>
    )}
      
    </>
  )
}

export default LeadCaptureOption;