import React from 'react';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import ReactSkeleton from 'react-loading-skeleton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { ArticleIcon } from 'src/pages/Home/TopPerformingContent/styles';
import { PillSkeleton } from 'src/pages/Home/styles';

import {
  Article,
  ArticleAndFormatContainer,
  ArticleContainer,
  ArticleFormat,
  ArticleInfoContainer,
  ArticleTitle,
  CampaignContentHeader,
  CampaignContentWrapper,
  Pills,
} from './styles';

function Skeleton() {
  return (
    <CampaignContentWrapper>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <CampaignContentHeader>
            <h2>
              <ReactSkeleton width={179} height={16} />
            </h2>
            <p>
              <ReactSkeleton width={70} height={16} />
            </p>
          </CampaignContentHeader>
        </AccordionSummary>
        <AccordionDetails>
          <ArticleContainer>
            <Article>
              <ArticleIcon>
                <ReactSkeleton width={80} height={80} />
              </ArticleIcon>
              <ArticleInfoContainer>
                <ArticleAndFormatContainer>
                  <ArticleTitle>
                    <ReactSkeleton height={10} width={200} />
                  </ArticleTitle>
                  <ArticleFormat>
                    <ReactSkeleton height={10} width={70} />
                  </ArticleFormat>
                </ArticleAndFormatContainer>
                <Pills>
                  <PillSkeleton width={80} height={24} />
                  <PillSkeleton width={80} height={24} />
                  <PillSkeleton width={80} height={24} />
                  <PillSkeleton width={80} height={24} />
                </Pills>
              </ArticleInfoContainer>
            </Article>
          </ArticleContainer>
        </AccordionDetails>
      </Accordion>
    </CampaignContentWrapper>
  );
}

export default Skeleton;
