import {
    faLinkedinIn,
    faTwitter,
    faFacebook,
  } from '@fortawesome/free-brands-svg-icons';
  import { faEnvelope } from '@fortawesome/free-solid-svg-icons';

export  const textBoxes = {
    tw: {
      color: '#00aeef',
      icon: faTwitter,
      editableContent: {
        maxLength: 280,
        summaryOption: 'short',
      }
    },
    fb: {
      color: '#4C6EF5',
      icon: faFacebook,
      editableContent: {
        maxLength: 2950,
        summaryOption: 'long',
      }
    },
    ln: {
      color: '#0054a6',
      icon: faLinkedinIn,
      editableContent: {
        maxLength: 2950,
        summaryOption: 'medium',
      }
    },
    em: {
      color: '#ea5946',
      icon: faEnvelope,
      editableContent: {
        maxLength: 2000,
        summaryOption: 'long',
      }
    },
    it: {
      color: '#6351ed',
      image: '/assets/images/internalUse.png',
      editableContent: {
        maxLength: 2000,
        summaryOption: 'long',
      }
    }
};

export const membersIcons = {
    select: '/assets/images/advisor2.svg',
    selected: '/assets/images/advisor1.svg'
}