/* eslint-disable react-hooks/exhaustive-deps */
import { Flex } from '@rebass/grid';
import React from 'react';
import { Line, WhiteCircle } from '../../../components/StepperV2/Stepper.styles';
import StepsFooterV2 from './StepsFooterV2';
import BlockCard from './BlockCard';
import { AUTOMATED_CAMPAIGN_ACTIONS } from '../AutomatedCampaignsContainer';
import { get, isEmpty } from 'lodash';
import CampaignDetails from './CampaignDetails';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import { StyledAccordianButton } from '../../../components/CreateTopicModal/CreateTopicModal.styles';
import StackedBlockCard from './StackedBlockCard';
import { useState } from 'react';
import {
  activateCampaign,
  automatedCampaignSendTestModal,
  complianceSendCampaign,
  getActivationDetails,
  getAutomatedCampaignBlocks,
} from '../../../services/automated-campaigns';
import { useEffect } from 'react';
import { LoaderWrapper } from '../../../components';
import SendTestMailModal from './SendTestMailModal';
import { useHistory } from 'react-router-dom';
//import "react-accessible-accordion/dist/fancy-example.css";
import AutomatedCampaignEmailPreviewModal from './AutomatedCampaignEmailPreviewModal';
import AutomatedCampaignSocialPreviewModal from './AutomatedCampaignSocialPreviewModal';
import SuccessModal from './SuccessModal';
import { nurtureFlag } from '../../../utils';

const demoPreconversionBlocks = [
  {
    id: 1,
    name: 'Market Moments',
    subject: 'Market Moments',
    b_type: 'pre_conversion',
    b_index: 1,
    platform: 'email',
    b_action: 'send_campaign',
    media_urls: '/assets/images/Analytics/Market-Moments.jpg',
    template_html: `<!DOCTYPE html><html xmlns:v="urn:schemas-microsoft-com:vml" xmlns:o="urn:schemas-microsoft-com:office:office" lang="en"><head><title></title><meta http-equiv="Content-Type" content="text/html; charset=utf-8"><meta name="viewport" content="width=device-width,initial-scale=1"><!--[if mso]><xml><o:OfficeDocumentSettings><o:PixelsPerInch>96</o:PixelsPerInch><o:AllowPNG/></o:OfficeDocumentSettings></xml><![endif]--><style>
    *{box-sizing:border-box}body{margin:0;padding:0}a[x-apple-data-detectors]{color:inherit!important;text-decoration:inherit!important}#MessageViewBody a{color:inherit;text-decoration:none}p{line-height:inherit}.desktop_hide,.desktop_hide table{mso-hide:all;display:none;max-height:0;overflow:hidden}.image_block img+div{display:none} @media (max-width:620px){.mobile_hide{display:none}.row-content{width:100%!important}.stack .column{width:100%;display:block}.mobile_hide{min-height:0;max-height:0;max-width:0;overflow:hidden;font-size:0}.desktop_hide,.desktop_hide table{display:table!important;max-height:none!important}}
    </style></head><body style="background-color:#fff;margin:0;padding:0;-webkit-text-size-adjust:none;text-size-adjust:none"><table class="nl-container" width="100%" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace:0;mso-table-rspace:0;background-color:#fff"><tbody><tr><td><table class="row row-1" align="center" width="100%" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace:0;mso-table-rspace:0"><tbody><tr><td><table 
    class="row-content stack" align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace:0;mso-table-rspace:0;color:#000;width:600px;margin:0 auto" width="600"><tbody><tr><td class="column column-1" width="100%" style="mso-table-lspace:0;mso-table-rspace:0;font-weight:400;text-align:left;padding-bottom:5px;padding-top:5px;vertical-align:top;border-top:0;border-right:0;border-bottom:0;border-left:0"><table class="text_block block-1" width="100%" border="0" 
    cellpadding="10" cellspacing="0" role="presentation" style="mso-table-lspace:0;mso-table-rspace:0;word-break:break-word"><tr><td class="pad"><div style="font-family:sans-serif"><div class style="font-size:14px;font-family:Arial,Helvetica Neue,Helvetica,sans-serif;mso-line-height-alt:16.8px;color:#000;line-height:1.2"><p style="margin:0;font-size:14px;mso-line-height-alt:16.8px">Hello {$first_name},&nbsp;</p></div></div></td></tr></table></td></tr></tbody></table></td></tr></tbody>
    </table><table class="row row-2" align="center" width="100%" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace:0;mso-table-rspace:0"><tbody><tr><td><table class="row-content stack" align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace:0;mso-table-rspace:0;border-radius:0;color:#000;width:600px;margin:0 auto" width="600"><tbody><tr><td class="column column-1" width="100%" 
    style="mso-table-lspace:0;mso-table-rspace:0;font-weight:400;text-align:left;padding-bottom:5px;padding-top:5px;vertical-align:top;border-top:0;border-right:0;border-bottom:0;border-left:0"><table class="image_block block-1" width="100%" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace:0;mso-table-rspace:0"><tr><td class="pad" style="width:100%"><div class="alignment" align="center" style="line-height:10px"><img 
    src="https://d15k2d11r6t6rl.cloudfront.net/public/users/Integrators/f70d53fc-ac51-490a-9877-f6d8e3bf2318/VOdD6lGQ/editor_images/102.jpg" style="display:block;height:auto;border:0;max-width:600px;width:100%" width="600"></div></td></tr></table></td></tr></tbody></table></td></tr></tbody></table><table class="row row-3" align="center" width="100%" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace:0;mso-table-rspace:0"><tbody><tr><td><table 
    class="row-content stack" align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace:0;mso-table-rspace:0;color:#000;width:600px;margin:0 auto" width="600"><tbody><tr><td class="column column-1" width="100%" style="mso-table-lspace:0;mso-table-rspace:0;font-weight:400;text-align:left;padding-bottom:5px;padding-top:5px;vertical-align:top;border-top:0;border-right:0;border-bottom:0;border-left:0"><table class="button_block block-1" width="100%" 
    border="0" cellpadding="10" cellspacing="0" role="presentation" style="mso-table-lspace:0;mso-table-rspace:0"><tr><td class="pad"><div class="alignment" align="center">
    <!--[if mso]><v:roundrect xmlns:v="urn:schemas-microsoft-com:vml" xmlns:w="urn:schemas-microsoft-com:office:word" data-web-page="web_page_merge_tag" href="https://pages-stage.myclout.com/DP435Bdk/VOdD6lGQ/651d5477311a0ac4b32ea113.html?page_id=651d5477311a0ac4b32ea113&ac_id=920" style="height:42px;width:99px;v-text-anchor:middle;" arcsize="10%" stroke="false" fillcolor="#3AAEE0"><w:anchorlock/><v:textbox inset="0px,0px,0px,0px"><center style="color:#ffffff; font-family:Arial, sans-serif; font-size:16px"><![endif]-->
    <a href="https://pages-stage.myclout.com/DP435Bdk/VOdD6lGQ/651d5477311a0ac4b32ea113.html?page_id=651d5477311a0ac4b32ea113&ac_id=920" target="_blank" style="text-decoration:none;display:inline-block;color:#ffffff;background-color:#3AAEE0;border-radius:4px;width:auto;border-top:0px solid transparent;font-weight:400;border-right:0px solid transparent;border-bottom:0px solid transparent;border-left:0px solid transparent;padding-top:5px;padding-bottom:5px;font-family:Arial, Helvetica Neue, Helvetica, sans-serif;font-size:16px;text-align:center;mso-border-alt:none;word-break:keep-all;" data-web-page="web_page_merge_tag"><span style="padding-left:20px;padding-right:20px;font-size:16px;display:inline-block;letter-spacing:normal;"><span style="word-break: break-word; line-height: 32px;">Register</span></span></a>
    <!--[if mso]></center></v:textbox></v:roundrect><![endif]--></div></td></tr></table></td></tr></tbody></table></td></tr></tbody></table><table class="row row-4" align="center" width="100%" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace:0;mso-table-rspace:0"><tbody><tr><td><table class="row-content stack" align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" 
    style="mso-table-lspace:0;mso-table-rspace:0;color:#000;width:600px;margin:0 auto" width="600"><tbody><tr><td class="column column-1" width="100%" style="mso-table-lspace:0;mso-table-rspace:0;font-weight:400;text-align:left;padding-bottom:5px;padding-top:5px;vertical-align:top;border-top:0;border-right:0;border-bottom:0;border-left:0"><table class="text_block block-1" width="100%" border="0" cellpadding="10" cellspacing="0" role="presentation" 
    style="mso-table-lspace:0;mso-table-rspace:0;word-break:break-word"><tr><td class="pad"><div style="font-family:sans-serif"><div class style="font-size:14px;font-family:Arial,Helvetica Neue,Helvetica,sans-serif;mso-line-height-alt:16.8px;color:#000;line-height:1.2"><p style="margin:0;font-size:14px;mso-line-height-alt:16.8px">©2018 - 2023</p></div></div></td></tr></table><table class="text_block block-2" width="100%" border="0" cellpadding="10" cellspacing="0" role="presentation" 
    style="mso-table-lspace:0;mso-table-rspace:0;word-break:break-word"><tr><td class="pad"><div style="font-family:sans-serif"><div class style="font-size:14px;font-family:Arial,Helvetica Neue,Helvetica,sans-serif;mso-line-height-alt:16.8px;color:#000;line-height:1.2"><p style="margin:0;font-size:14px;mso-line-height-alt:16.8px">This message was sent to {$email} and intended for {$first_name}&nbsp;&nbsp;.<br>
    Not your account? <a href="{$unsubscribe_link}" target="_blank" rel="noopener" style="color: #0068A5;">Remove your email</a> from this account.</p></div></div></td></tr></table></td></tr></tbody></table></td></tr></tbody></table></td></tr></tbody></table><!-- End --></body></html>`
  }, {
    id: 2,
    name: 'Weekly Markets Commentary',
    subject: 'Weekly Markets Commentary',
    b_type: 'pre_conversion',
    b_index: 2,
    platform: 'email',
    b_action: 'send_campaign',
    media_urls: '/assets/images/Analytics/markets-commentary.jpg',
    template_html: `<!DOCTYPE html><html xmlns:v="urn:schemas-microsoft-com:vml" xmlns:o="urn:schemas-microsoft-com:office:office" lang="en"><head><title></title><meta http-equiv="Content-Type" content="text/html; charset=utf-8"><meta name="viewport" content="width=device-width,initial-scale=1"><!--[if mso]><xml><o:OfficeDocumentSettings><o:PixelsPerInch>96</o:PixelsPerInch><o:AllowPNG/></o:OfficeDocumentSettings></xml><![endif]--><style>
    *{box-sizing:border-box}body{margin:0;padding:0}a[x-apple-data-detectors]{color:inherit!important;text-decoration:inherit!important}#MessageViewBody a{color:inherit;text-decoration:none}p{line-height:inherit}.desktop_hide,.desktop_hide table{mso-hide:all;display:none;max-height:0;overflow:hidden}.image_block img+div{display:none} @media (max-width:620px){.mobile_hide{display:none}.row-content{width:100%!important}.stack .column{width:100%;display:block}.mobile_hide{min-height:0;max-height:0;max-width:0;overflow:hidden;font-size:0}.desktop_hide,.desktop_hide table{display:table!important;max-height:none!important}}
    </style></head><body style="background-color:#fff;margin:0;padding:0;-webkit-text-size-adjust:none;text-size-adjust:none"><table class="nl-container" width="100%" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace:0;mso-table-rspace:0;background-color:#fff"><tbody><tr><td><table class="row row-1" align="center" width="100%" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace:0;mso-table-rspace:0"><tbody><tr><td><table 
    class="row-content stack" align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace:0;mso-table-rspace:0;color:#000;width:600px;margin:0 auto" width="600"><tbody><tr><td class="column column-1" width="100%" style="mso-table-lspace:0;mso-table-rspace:0;font-weight:400;text-align:left;padding-bottom:5px;padding-top:5px;vertical-align:top;border-top:0;border-right:0;border-bottom:0;border-left:0"><table class="text_block block-1" width="100%" border="0" 
    cellpadding="10" cellspacing="0" role="presentation" style="mso-table-lspace:0;mso-table-rspace:0;word-break:break-word"><tr><td class="pad"><div style="font-family:sans-serif"><div class style="font-size:14px;font-family:Arial,Helvetica Neue,Helvetica,sans-serif;mso-line-height-alt:16.8px;color:#000;line-height:1.2"><p style="margin:0;font-size:14px;mso-line-height-alt:16.8px">Hello {$first_name},&nbsp;</p></div></div></td></tr></table></td></tr></tbody></table></td></tr></tbody>
    </table><table class="row row-2" align="center" width="100%" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace:0;mso-table-rspace:0"><tbody><tr><td><table class="row-content stack" align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace:0;mso-table-rspace:0;border-radius:0;color:#000;width:600px;margin:0 auto" width="600"><tbody><tr><td class="column column-1" width="100%" 
    style="mso-table-lspace:0;mso-table-rspace:0;font-weight:400;text-align:left;padding-bottom:5px;padding-top:5px;vertical-align:top;border-top:0;border-right:0;border-bottom:0;border-left:0"><table class="image_block block-1" width="100%" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace:0;mso-table-rspace:0"><tr><td class="pad" style="width:100%"><div class="alignment" align="center" style="line-height:10px"><img 
    src="https://d15k2d11r6t6rl.cloudfront.net/public/users/Integrators/f70d53fc-ac51-490a-9877-f6d8e3bf2318/VOdD6lGQ/editor_images/102.jpg" style="display:block;height:auto;border:0;max-width:600px;width:100%" width="600"></div></td></tr></table></td></tr></tbody></table></td></tr></tbody></table><table class="row row-3" align="center" width="100%" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace:0;mso-table-rspace:0"><tbody><tr><td><table 
    class="row-content stack" align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace:0;mso-table-rspace:0;color:#000;width:600px;margin:0 auto" width="600"><tbody><tr><td class="column column-1" width="100%" style="mso-table-lspace:0;mso-table-rspace:0;font-weight:400;text-align:left;padding-bottom:5px;padding-top:5px;vertical-align:top;border-top:0;border-right:0;border-bottom:0;border-left:0"><table class="button_block block-1" width="100%" 
    border="0" cellpadding="10" cellspacing="0" role="presentation" style="mso-table-lspace:0;mso-table-rspace:0"><tr><td class="pad"><div class="alignment" align="center">
    <!--[if mso]><v:roundrect xmlns:v="urn:schemas-microsoft-com:vml" xmlns:w="urn:schemas-microsoft-com:office:word" data-web-page="web_page_merge_tag" href="https://pages-stage.myclout.com/DP435Bdk/VOdD6lGQ/651d5477311a0ac4b32ea113.html?page_id=651d5477311a0ac4b32ea113&ac_id=920" style="height:42px;width:99px;v-text-anchor:middle;" arcsize="10%" stroke="false" fillcolor="#3AAEE0"><w:anchorlock/><v:textbox inset="0px,0px,0px,0px"><center style="color:#ffffff; font-family:Arial, sans-serif; font-size:16px"><![endif]-->
    <a href="https://pages-stage.myclout.com/DP435Bdk/VOdD6lGQ/651d5477311a0ac4b32ea113.html?page_id=651d5477311a0ac4b32ea113&ac_id=920" target="_blank" style="text-decoration:none;display:inline-block;color:#ffffff;background-color:#3AAEE0;border-radius:4px;width:auto;border-top:0px solid transparent;font-weight:400;border-right:0px solid transparent;border-bottom:0px solid transparent;border-left:0px solid transparent;padding-top:5px;padding-bottom:5px;font-family:Arial, Helvetica Neue, Helvetica, sans-serif;font-size:16px;text-align:center;mso-border-alt:none;word-break:keep-all;" data-web-page="web_page_merge_tag"><span style="padding-left:20px;padding-right:20px;font-size:16px;display:inline-block;letter-spacing:normal;"><span style="word-break: break-word; line-height: 32px;">Register</span></span></a>
    <!--[if mso]></center></v:textbox></v:roundrect><![endif]--></div></td></tr></table></td></tr></tbody></table></td></tr></tbody></table><table class="row row-4" align="center" width="100%" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace:0;mso-table-rspace:0"><tbody><tr><td><table class="row-content stack" align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" 
    style="mso-table-lspace:0;mso-table-rspace:0;color:#000;width:600px;margin:0 auto" width="600"><tbody><tr><td class="column column-1" width="100%" style="mso-table-lspace:0;mso-table-rspace:0;font-weight:400;text-align:left;padding-bottom:5px;padding-top:5px;vertical-align:top;border-top:0;border-right:0;border-bottom:0;border-left:0"><table class="text_block block-1" width="100%" border="0" cellpadding="10" cellspacing="0" role="presentation" 
    style="mso-table-lspace:0;mso-table-rspace:0;word-break:break-word"><tr><td class="pad"><div style="font-family:sans-serif"><div class style="font-size:14px;font-family:Arial,Helvetica Neue,Helvetica,sans-serif;mso-line-height-alt:16.8px;color:#000;line-height:1.2"><p style="margin:0;font-size:14px;mso-line-height-alt:16.8px">©2018 - 2023</p></div></div></td></tr></table><table class="text_block block-2" width="100%" border="0" cellpadding="10" cellspacing="0" role="presentation" 
    style="mso-table-lspace:0;mso-table-rspace:0;word-break:break-word"><tr><td class="pad"><div style="font-family:sans-serif"><div class style="font-size:14px;font-family:Arial,Helvetica Neue,Helvetica,sans-serif;mso-line-height-alt:16.8px;color:#000;line-height:1.2"><p style="margin:0;font-size:14px;mso-line-height-alt:16.8px">This message was sent to {$email} and intended for {$first_name}&nbsp;&nbsp;.<br>
    Not your account? <a href="{$unsubscribe_link}" target="_blank" rel="noopener" style="color: #0068A5;">Remove your email</a> from this account.</p></div></div></td></tr></table></td></tr></tbody></table></td></tr></tbody></table></td></tr></tbody></table><!-- End --></body></html>`
  }]

const ActivateCampaignV2 = ({
  campaignAction,
  currentStepIndex,
  automatedCampaignId,
  campaignPurpose,
  campaignSeriesName,
  firmSettings,
  isFirmLevelAdmin,
  companyId,
  compliance,
  automatedCampaignDetails,
  automatedCampaignTargetInfo,
  setCurrentStepIndex,
  campaignSetState,
  endCreateFlow
}) => {
  const [preConversionBlocks, setPreConversionBlocks] = useState([]);
  const [postConversionBlocks, setPostConversionBlocks] = useState([]);
  const [onConversionBlocks, setOnConversionBlocks] = useState([]);
  const [fetchingBlocks, setFetchingBlocks] = useState(false);
  const [accordionExpanded, setAccordionExpanded] = useState(false);
  const [openPreviewModal, setOpenPreviewModal] = useState(false);
  const [selectedBlock, setSelectedBlock] = useState(false);
  const [openSendTestEmailModal, setOpenSendTestEmailModal] = useState(false);
  const [sendTestEmail, setSendTestEmail] = useState('');
  const [sendTestErrorMsg, setSendTestErrorMsg] = useState('');
  const [requestLoading, setRequestLoading] = useState(false);
  const [complianceSettings, setComplianceSettings] = useState('off');
  const [requiredApproval, setRequiredApproval] = useState(false);
  const [toogleCompliance, setToggleCompliance] = useState('false');
  const [loading, setLoading] = useState(false);
  const [openSocialPreviewModal, setOpenSocialPreviewModal] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  const [campaignDetails, setCampaignDetails] = useState();

  const showPreviewModal = () => setOpenPreviewModal(true);
  const closePreviewModal = () => setOpenPreviewModal(false);

  const showSendTestModal = () => setOpenSendTestEmailModal(true);
  const closeSendTestModal = () => setOpenSendTestEmailModal(false);

  const showSocialPreviewModal = () => setOpenSocialPreviewModal(true);
  const closeSocialPreviewModal = () => setOpenSocialPreviewModal(false);

  useEffect(() => {
    setFetchingBlocks(true);
    fetchBlocks();
  }, []);

  useEffect(() => {
    const complianceOption = isFirmLevelAdmin && !isEmpty(firmSettings) ? 
        get(firmSettings, `firm_compliance.${[companyId]}.compliance_is`, 'always_off')
          : compliance;
    setComplianceSettings(complianceOption);
    if(complianceOption === 'on' || complianceOption === 'always_on') {
      setRequiredApproval(true);
    }
  }, []);

  const handleComplianceToggle = (checked) => {
    if (checked) {
      setToggleCompliance('true');
      setRequiredApproval(true);
    }
    else {
      setToggleCompliance('false');
      setRequiredApproval(false);
    }
  };

  const fetchBlocks = async () => {
    if(nurtureFlag !== 'nurture' || (nurtureFlag === 'nurture' && process.env.REACT_APP_API_ENV !== 'stage')) {
      getAutomatedCampaignBlocks(automatedCampaignId)
      .then(async (res) => {
        setPreConversionBlocks(
          res.data.sort((b, a) => b.b_index - a.b_index).filter((b) => b.b_type === 'pre_conversion')
        );
        setOnConversionBlocks(
          res.data.sort((b, a) => b.b_index - a.b_index).filter((b) => b.b_type === 'on_conversion')
        );
        setPostConversionBlocks(
          res.data.sort((b, a) => b.b_index - a.b_index).filter((b) => b.b_type === 'post_conversion')
        );
        const { data } = await getActivationDetails(automatedCampaignId);
        console.log(data);
        setCampaignDetails(data)
        setFetchingBlocks(false);
      })
      .catch((err) => {
        setFetchingBlocks(false);
      });
    } else {
      setPreConversionBlocks(
        demoPreconversionBlocks.sort((b, a) => b.b_index - a.b_index).filter((b) => b.b_type === 'pre_conversion')
      );
      setOnConversionBlocks(
        demoPreconversionBlocks.sort((b, a) => b.b_index - a.b_index).filter((b) => b.b_type === 'on_conversion')
      );
      setPostConversionBlocks(
        demoPreconversionBlocks.sort((b, a) => b.b_index - a.b_index).filter((b) => b.b_type === 'post_conversion')
      );
      setFetchingBlocks(false);
    }
    
  };

  const blocks = [...preConversionBlocks, ...postConversionBlocks, ...onConversionBlocks];

  const onlyEmailNumber =
    !isEmpty(blocks) &&
    blocks.filter(
      (b) => b.b_action === 'send_campaign' && b.platform === 'email'
    ).length;

  const onlySocialNumber =
    !isEmpty(preConversionBlocks) &&
    preConversionBlocks.filter(
      (b) => b.b_action === 'send_campaign' && b.platform === 'social'
    ).length;

  const socialCampaigns =
    !isEmpty(preConversionBlocks) &&
    preConversionBlocks.filter(
      (b) => b.b_action === 'send_campaign' && b.platform === 'social'
    );

  const emailCampaigns =
    !isEmpty(preConversionBlocks) &&
    preConversionBlocks.filter(
      (b) => b.b_action === 'send_campaign' && b.platform === 'email'
    );

  const bothPostAndOnConversionBlocks = [
    ...onConversionBlocks,
    ...postConversionBlocks,
  ];

  const sendBothConversionBlocks = bothPostAndOnConversionBlocks.filter(
    (b) => b.b_action === 'send_campaign'
  );

  const handleOpenPreviewModal = (block) => {
    setSelectedBlock(block);
    showPreviewModal();
  };

  const handleSendTestModalOpen = (block) => {
    setSelectedBlock(block);
    showSendTestModal();
  };

  const handleOpenSocialPreviewModal = (block) => {
    setSelectedBlock(block);
    showSocialPreviewModal();
  };

  const handleSendClick = () => {
    console.log('clicked');
    // TO DO HANDLE EMAIL VALIDATION AND COUNT VALIDATION
    setSendTestErrorMsg('');
    let msg = 'Email is not valid';
    const isEmailRegEx =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,3}))$/;
    let valid = true;
    if (valid && !isEmpty(sendTestEmail)) {
      valid = sendTestEmail
        .split(',')
        .map((em) => em.trim())
        .every((em) => isEmailRegEx.test(em.toLowerCase()));
      if (sendTestEmail.split(',').length > 5 && valid) {
        valid = false;
        msg = 'Maximum 5 emails can be added';
      }
    }
    if (!valid) {
      setSendTestErrorMsg(msg);
      return;
    }
    setRequestLoading(true);
    const payload = {
      block_id: selectedBlock.id,
      email_ids: sendTestEmail,
    };
    automatedCampaignSendTestModal(payload)
      .then((res) => {
        console.log('send');
        if (res?.result?.success) {
          setRequestLoading(false);
          setSendTestErrorMsg('Sent Successfully');
          setTimeout(() => {
            setSendTestErrorMsg('');
            setOpenSendTestEmailModal(false);
          }, 3000);
        } else {
          setRequestLoading(false);
          setSendTestErrorMsg(res?.result?.success);
        }
        setSendTestEmail('');
      })
      .catch((error) => {
        console.log(error);
        setRequestLoading(false);
      });
    setSendTestErrorMsg();
  };

  const handleSendTestValidation = () => {
    if (!sendTestEmail) {
      return true;
    }
  };

  const history = useHistory();
  const handleSend = async () => {
    if(nurtureFlag === 'nurture' && process.env.REACT_APP_API_ENV === 'stage') {
      setSuccessModal(true);
    } else {
      setLoading(true);
      try {
        const { campaignId } = automatedCampaignDetails;
        (requiredApproval) ? await complianceSendCampaign(campaignId) : await activateCampaign(campaignId);
        setLoading(false);
        campaignSetState({ automatedDashboard: true });
        setSuccessModal(true);
        // history.push('/campaignV2');
      } catch (error) {
        setLoading(false);
        // campaignSetState({ automatedDashboard: true });
        // history.push('/campaignV2');
      }
    }
   
    
  };

  console.log("post conversion blocks---->", postConversionBlocks);
  return (
    <>
      <LoaderWrapper isLoading={fetchingBlocks}>
        <Flex width="80%" margin="0px auto 100px" flexDirection="column">
          <Flex width="100%" alignItems="center">
            <WhiteCircle height="40px" width="40px" background="#816EF8">
              <span
                style={{
                  color: '#fff',
                  fontSize: '16px',
                  position: 'absolute',
                  top: '6px',
                  left: '0px',
                  width: '100%',
                }}
              >
                3
              </span>
            </WhiteCircle>
            <Flex fontSize="20px" color="#101828" style={{ fontWeight: 500 }}>
              Campaign Overview
            </Flex>
          </Flex>
          <hr style={{ width: '100%', border: '1px solid #E4E7EC' }}></hr>
          <Flex display="flex" flexDirection="row" width="100%">
            <Flex
              margin="15px 0px 0px"
              //alignItems="center"
              width="45%"
              style={{ position: 'relative' }}
            >
              <Flex width="100%" flexDirection="column">
                <Accordion
                  allowZeroExpanded
                  onChange={() => setAccordionExpanded(!accordionExpanded)}
                >
                  <AccordionItem>
                    <AccordionItemHeading>
                      <StyledAccordianButton
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          border: 'none',
                          backgroundColor: '#F6F9FB',
                          margin: '0px',
                        }}
                      >
                        {!accordionExpanded ? (
                          <Flex
                            width="100%"
                            justifyContent="flex-start"
                            marginBottom="10px"
                            flexDirection="row"
                          >
                            <img
                              alt=""
                              src="/assets/images/Campaign/expand-icon.svg"
                              style={{ marginRight: '10px' }}
                            />
                            <Flex
                              fontSize="14px"
                              color="#101828"
                              style={{ fontWeight: 500 }}
                            >
                              {`${
                                !isEmpty(blocks) &&
                                blocks.filter(
                                  (b) => b.b_action === 'send_campaign'
                                ).length
                              } Promotional Blocks`}
                            </Flex>
                          </Flex>
                        ) : (
                          <Flex
                            width="100%"
                            justifyContent="flex-start"
                            marginBottom="10px"
                            flexDirection="row"
                          >
                            <img
                              alt=""
                              src="/assets/images/Campaign/expand-more-icon.svg"
                              style={{ marginRight: '5px' }}
                            />
                            <Flex
                              fontSize="14px"
                              color="#101828"
                              style={{ fontWeight: 500, marginTop: '6px' }}
                            >
                              {`${
                                !isEmpty(blocks) &&
                                blocks.filter(
                                  (b) => b.b_action === 'send_campaign'
                                ).length
                              } Promotional Blocks`}
                            </Flex>
                          </Flex>
                        )}

                        {!accordionExpanded && (
                          <StackedBlockCard
                            campaignAction={campaignAction}
                            item={
                              !isEmpty(preConversionBlocks) &&
                              preConversionBlocks.filter((b) => b.b_action === 'send_campaign')[0]
                            }
                            currentStepIndex={currentStepIndex}
                          />
                        )}
                      </StyledAccordianButton>
                    </AccordionItemHeading>
                    {!isEmpty(preConversionBlocks) &&
                      preConversionBlocks
                        .filter((b) => b.b_action === 'send_campaign')
                        .map((item) => (
                          <AccordionItemPanel style={{ marginBottom: '20px' }}>
                            <BlockCard
                              campaignAction={campaignAction}
                              item={item}
                              currentStepIndex={currentStepIndex}
                              onPreviewClick={handleOpenPreviewModal}
                              onSendTestModalClick={handleSendTestModalOpen}
                              onSocialPreviewClick={
                                handleOpenSocialPreviewModal
                              }
                            />
                          </AccordionItemPanel>
                        ))}
                    {campaignAction === AUTOMATED_CAMPAIGN_ACTIONS.EVENT && (nurtureFlag !== 'nurture' || (nurtureFlag === 'nurture' && process.env.REACT_APP_API_ENV !== 'stage')) && (
                      <AccordionItemPanel style={{ marginBottom: '20px' }}>
                        <Flex display="flex" width="100%" flexDirection="row">
                          <Flex width="28%" marginLeft="5px">
                            <hr style={{ width: '78%' }} />
                          </Flex>
                          <Flex
                            width="40%"
                            fontSize="12px"
                            color="#667085"
                            padding="0px 15px 0px 15px"
                          >
                            After filling registration form
                          </Flex>
                          <Flex width="28%">
                            <hr style={{ width: '78%' }} />
                          </Flex>
                        </Flex>
                      </AccordionItemPanel>
                    )}
                    {campaignAction ===
                      AUTOMATED_CAMPAIGN_ACTIONS.NURTURE_PROSPECTS && (
                      <AccordionItemPanel style={{ marginBottom: '20px' }}>
                        <Flex display="flex" width="100%" flexDirection="row">
                          <Flex width="25%" marginLeft="5px">
                            <hr style={{ width: '78%' }} />
                          </Flex>
                          <Flex
                            width="45%"
                            fontSize="12px"
                            color="#667085"
                            padding="0px 15px 0px 15px"
                          >
                            After filling Lead Capture form
                          </Flex>
                          <Flex width="25%">
                            <hr style={{ width: '78%' }} />
                          </Flex>
                        </Flex>
                      </AccordionItemPanel>
                    )}
                    {campaignAction ===
                      AUTOMATED_CAMPAIGN_ACTIONS.NURTURE_PROSPECTS && onConversionBlocks.length > 0 && (
                      <AccordionItemPanel style={{ marginBottom: '20px' }}>
                        <BlockCard
                          campaignAction={campaignAction}
                          item={
                            !isEmpty(onConversionBlocks) &&
                            onConversionBlocks.filter((b) => b.b_action === 'send_campaign')[0]
                          }
                          currentStepIndex={currentStepIndex}
                          onPreviewClick={handleOpenPreviewModal}
                          onSendTestModalClick={handleSendTestModalOpen}
                          onSocialPreviewClick={handleOpenSocialPreviewModal}
                        />
                      </AccordionItemPanel>
                    )}
                    {campaignAction === AUTOMATED_CAMPAIGN_ACTIONS.EVENT &&
                      sendBothConversionBlocks.map((item) => (
                        <AccordionItemPanel style={{ marginBottom: '20px' }}>
                          <BlockCard
                            campaignAction={campaignAction}
                            item={item}
                            currentStepIndex={currentStepIndex}
                            onPreviewClick={handleOpenPreviewModal}
                            onSendTestModalClick={handleSendTestModalOpen}
                            onSocialPreviewClick={handleOpenSocialPreviewModal}
                          />
                        </AccordionItemPanel>
                      ))}
                  </AccordionItem>
                </Accordion>
              </Flex>
            </Flex>

            <Flex display="flex" flexDirection="row" width="55%">
              <Flex width="3%" alignItems="center">
                <Line border="1px solid #E4E7EC" style={{ height: '53%' }} />
              </Flex>
              <Flex width="100%" flexDirection="column" padding="25px 5px">
                <Flex>
                  <CampaignDetails
                    onlyEmailNumber={onlyEmailNumber}
                    onlySocialNumber={onlySocialNumber}
                    campaignSeriesName={campaignSeriesName}
                    campaignPurpose={campaignPurpose}
                    campaignAction={campaignAction}
                    complianceSettings={complianceSettings}
                    handleComplianceToggle={handleComplianceToggle}
                    toogleCompliance={toogleCompliance}
                    automatedCampaignTargetInfo={automatedCampaignTargetInfo}
                    campaignDetails={campaignDetails}
                  />
                </Flex>
              </Flex>
            </Flex>
          </Flex>

          {/* OPEN PREVIEW MODAL */}
          {openPreviewModal && selectedBlock.id && (
            <AutomatedCampaignEmailPreviewModal
              isOpen={handleOpenPreviewModal}
              handleClose={() => closePreviewModal()}
              block_id={selectedBlock?.id}
              campaign_id={selectedBlock?.ac_id}
              template_html={selectedBlock?.template_html}
              subject={selectedBlock?.subject}
              name={selectedBlock?.name}
            />
          )}

          {/* OPEN SEND TEST EMAIL modal */}
          {openSendTestEmailModal && (
            <SendTestMailModal
              handleModalClose={() => closeSendTestModal()}
              isOpen={handleSendTestModalOpen}
              subject={selectedBlock.subject}
              handleChange={(e) => setSendTestEmail(e.target.value)}
              value={sendTestEmail}
              modalButtonOnClick={handleSendClick}
              validation={handleSendTestValidation()}
              sendTestErrorMsg={sendTestErrorMsg}
              loading={requestLoading}
            />
          )}

          {/* OPEN SOCIAL PREVIEW MODAL*/}
          {openSocialPreviewModal && (
            <AutomatedCampaignSocialPreviewModal
              isOpen={handleOpenSocialPreviewModal}
              handleClose={() => closeSocialPreviewModal()}
              block_id={selectedBlock?.id}
              media_urls={selectedBlock?.media_urls}
              subject={selectedBlock?.articles?.[0]?.description}
              title={selectedBlock?.article?.[0]?.title}
              campaign_id={selectedBlock?.ac_id}
              is_facebook={selectedBlock?.social_pages?.facebook}
              is_twitter={selectedBlock?.social_pages?.twitter}
              is_linkedin={selectedBlock?.social_pages?.linkedin}
            />
          )}

          {successModal && (
            <SuccessModal
              isOpen={successModal}
              handleModalClose={() => history.push('/campaignV2')}
              requiredApproval={requiredApproval}
              campaignSetState={campaignSetState}
            />
          )}
         

          <StepsFooterV2
            requestLoading={loading}
            isEditMode={false}
            currentStepIndex={currentStepIndex}
            setCurrentStepIndex={setCurrentStepIndex}
            handleNext={() => handleSend()}
            disableNext={fetchingBlocks}
            disableSaveAndClose={false}
            campaignSetState={campaignSetState}
            endCreateFlow={endCreateFlow}
            handleSaveAndClose={async () => {}}
            automatedCampaignDetails={automatedCampaignDetails}
            requiredApproval={requiredApproval}
          />
        </Flex>
      </LoaderWrapper>
    </>
  );
};

export default ActivateCampaignV2;
