import { Flex } from '@rebass/grid';
import styled from 'styled-components';

export const TemplateSelectorDiv = styled(Flex)`
    cursor: pointer; 
    justify-content: ${({ loneTemplate }) => loneTemplate ? 'center' : 'flex-start'};
    align-items: flex-end;
    width: 100%;
    max-width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    flex-wrap: wrap;

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background: #e9f6e6 !important;
  }

  &::-webkit-scrollbar-thumb {
    background: #bccdd4 !important;
  }
`;