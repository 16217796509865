import { styled } from '@mui/material';

export const Wrapper = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  background: '#FFF',
  border: '1px solid #E4E7EC',
  borderRadius: '0.5rem',
  boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.12)',
  flex: 0.3,
}));

export const Header = styled('div')(() => ({
  width: '100%',
  padding: '1.5rem',
  background: '#FCFDFD',
  borderBottom: '1px solid #E4E7EC',
  borderTopLeftRadius: '0.5rem',
  borderTopRightRadius: '0.5rem',

  h4: {
    fontSize: '1.25rem',
    fontWeight: '500',
    textAlign: 'left',
    color: '#000',
    margin: 0,
    textWrap: 'noWrap',
  },
}));

export const ContentWrapper = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: '0.5rem',
  alignItems: 'flex-start',
  padding: '1rem',
}));

export const Content = styled('div')(() => ({
  display: 'flex',
  alignItems: 'flex-start',
  gap: '1.5rem',
}));

export const TopEngagements = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '1rem',
}));

export const TopEngagement = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',

  h4: {
    fontSize: '1.25rem',
    fontWeight: '500',
    textAlign: 'left',
    color: '#475467',
    margin: 0,
  },
  p: {
    fontSize: '0.75rem',
    fontWeight: '500',
    lineHeight: '1rem',
    textAlign: 'left',
    letterSpacing: '-0.015rem',
    color: '#667085',
    margin: 0,
  },
}));

export const Footer = styled('div')(() => ({
  width: '100%',
  padding: '0.75rem',
  background: '#FCFDFD',
  borderTop: '1px solid #E4E7EC',
  borderBottomLeftRadius: '0.5rem',
  borderBottomRightRadius: '0.5rem',

  p: {
    fontSize: '0.8125rem',
    fontWeight: '400',
    textAlign: 'left',
    color: '#667085',
    margin: 0,
  },
}));

export const TooltipContainer = styled('div')(() => ({
  boxShadow: '0px 0px 100px rgba(63, 106, 194, 0.15)',
  padding: '10px 5px',
  border: '1px solid #ccc',
  background: '#fff',
  borderRadius: '4px',
  display: 'flex',
}));

export const TooltipName = styled('div')(() => ({
  fontWeight: 500,
  fontSize: '0.875rem',
  color: '#000',
  textAlign: 'left',
  margin: '0 6px 0 0',
}));

export const TooltipValue = styled('div')(() => ({
  fontWeight: 500,
  fontSize: '0.875rem',
  textAlign: 'left',
  color: '#101828',
}));
