import React from 'react';
import ContentLoader from 'react-content-loader';
import { Container, Grid } from '@mui/material';

function SkeletonLoader(props) {
  return (
    <Container>
      <Grid width="100%" height="250px" margin="0px 0 20px" padding={'0px'}>
        <ContentLoader
          viewBox="0 0 100% 100%"
          height="100%"
          width="100%"
          margin={'10px 0 20px'}
          padding={'0px'}
        >
          <rect x="0" y="2%" rx="10" ry="4" width="300px" height="120px" />
          <rect x="310px" y="2%" rx="10" ry="4" width="300px" height="120px" />
          <rect x="620px" y="2%" rx="10" ry="4" width="300px" height="120px" />
          <rect x="930px" y="2%" rx="10" ry="4" width="300px" height="120px" />
          <rect x="0" y="140px" rx="10" ry="4" width="300px" height="120px" />
          <rect x="310px" y="140px" rx="10" ry="4" width="300px" height="120px" />
          <rect x="620px" y="140px" rx="10" ry="4" width="300px" height="120px" />
          <rect x="930px" y="140px" rx="10" ry="4" width="300px" height="120px" />
        </ContentLoader>
      </Grid>
    </Container>
  );
}

export default SkeletonLoader;
