import React from 'react';
import { PopoverItem, StyledPopover } from './styles';

function CustomPopover({
  open = false,
  id = '',
  anchorEl = null,
  handlePopoverClose = () => {},
  items = [{ title: '', action: () => {} }],
  handleClick = () => {},
  anchorHorizontalPosition = 'right',
}) {
  const handleMenuClick = (event, item) => {
    if (item?.action && typeof item.action === 'function') {
      item.action();
    } else {
      handleClick(event, item);
    }
  };

  return (
    <StyledPopover
      id={id}
      open={open}
      anchorEl={anchorEl}
      disableScrollLock
      onClose={handlePopoverClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: anchorHorizontalPosition || 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: anchorHorizontalPosition || 'right',
      }}
      disablePortal
      style={{ pointerEvents: 'none' }}
      slotProps={{
        paper: {
          style: { pointerEvents: 'all' },
          onMouseLeave: handlePopoverClose,
        },
      }}
    >
      {Array.isArray(items) &&
        items?.length > 0 &&
        items?.map((menuItem, index) => (
          <PopoverItem
            key={index}
            onClick={(event) => handleMenuClick(event, menuItem)}
          >
            {menuItem?.title ?? ''}
          </PopoverItem>
        ))}
    </StyledPopover>
  );
}

export default CustomPopover;
