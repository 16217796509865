import { TemplateContainer } from '../../containers';
import React from 'react';
import withDripCampaignLayout from '../../hoc/withDripCampaignLayout';

const TemplatesLayout = () => {
    return (
        <TemplateContainer />
    );
}

export default withDripCampaignLayout(TemplatesLayout);