/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/iframe-has-title */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAngleLeft,
  faAngleRight,
  faCheckCircle,
} from '@fortawesome/free-solid-svg-icons';
import { Flex } from '@rebass/grid';
import { get, isEmpty } from 'lodash';
import React, { useEffect } from 'react';
import { useState } from 'react';
import { useHistory } from 'react-router';
import { Button, LoaderWrapper } from '../../../components';
import { getMediaImage, themesData } from '../../../utils';
import { CreateContainer } from '../CreateCampaign/CreateCampaign.styles';
import { VerticalSeparator } from '../DripDashboardTable/DripDashboardListItem/DripDashboardListItem.styles';
import {
  CheckboxInput,
  Chips,
  InputLabel,
  ShareCard,
} from '../SocialPreview/SocialPreview.styles';
import RejectCampaignModal from './RejectCampaignModal';
// import ApproveCamapignModal from './ApproveCampaignModal';
import {
  TemplateContainer,
  ModalFooterBtnsWrapper,
  PaginationBtn,
  PaginationInfo,
  PreviewContainer,
  ReviewBody,
  ReviewHeader,
} from './ReviewCampaigns.styles';
import {
  getCampaignDetails,
  nextCampaignReview,
  postCampaignReview,
  publishCampaignV2,
} from '../../../services/campaign';
import { connect } from 'react-redux';
import moment from 'moment';

const ReviewCampaigns = ({
  campaignId,
  type,
  subCampaigns,
  contactlistNames,
  socialAccountPages,
  checkboxStatus,
  segmentList
}) => {
  const [isRejectModalOpen, setIsRejectModalOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  // const [isApproveModalOpen, setIsApproveModalOpen] = useState(false);
  const [activePlatform, setActivePlatform] = useState('fb');
  const [isApproved, setIsApproved] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [campaignReviewLoading, setCampaignReviewLoading] = useState(false);
  const [currentCampaignID, setCurrentCampaignID] = useState('');
  const [curIndex, setCurIndex] = useState(0);
  const [remaining, setRemaining] = useState(true);
  // const [campaignType, setCampaignType] = useState('one_time');
  const [noCampaigns, setNoCampaigns] = useState('');
  const [platformCampaigns, setPlatformCampaigns] = useState([]);
  const [curPlatformCampaign, setCurPlatformCampaign] = useState({});
  const [msgCopy, setMsgCopy] = useState({ fb: '', ln: '', tw: '' });
  const [isMsgCopyAllPlatform, setIsMsgCopyAllPlatform] = useState(
    !isEmpty(checkboxStatus)
      ? get(checkboxStatus, 'message_copy', false)
      : false
  );
  const history = useHistory();

  console.log(curPlatformCampaign);

  const platformMapper = {
    fb: 'Facebook',
    tw: 'Twitter',
    em: 'Email',
    ln: 'Linkedin',
  };

  const manageAllPlatformCheck = (checked) => {
    if (!checked) {
      setCurPlatformCampaign({
        ...curPlatformCampaign,
        fb: {
          ...curPlatformCampaign?.fb,
          summary: curPlatformCampaign[activePlatform].summary,
        },
        ln: {
          ...curPlatformCampaign?.ln,
          summary: curPlatformCampaign[activePlatform].summary,
        },
        tw: {
          ...curPlatformCampaign?.tw,
          summary: curPlatformCampaign[activePlatform].summary,
        },
      });
    } else {
      setCurPlatformCampaign({
        ...curPlatformCampaign,
        [activePlatform]: {
          ...curPlatformCampaign[activePlatform],
          summary: curPlatformCampaign[activePlatform].summary,
        },
      });
    }

    setIsMsgCopyAllPlatform(!isMsgCopyAllPlatform);
  };

  const getMessageCopyMaxLength = () => {
    if (activePlatform === 'fb' || activePlatform === 'ln') return 2950;
    else if (activePlatform === 'tw') return 250;
    else return '';
  };

  const platformForReview = () => {
    if (activePlatform === 'em') {
      return 'email';
    }
    return 'social';
  };

  const getImmediateTimings = (resObj, platform) => {
    let immediateTimings = get(resObj, platform, []);
    let timings = [];
    if (immediateTimings.length > 0) {
      immediateTimings.forEach((timing) => {
        if (timing.destination_id) {
          let obj = {
            time: 'Immediate',
            destination_id: get(timing, 'destination_id', ''),
          };
          timings.push(obj);
        }
      });
    }

    return timings;
  };

  const curCampaignDetailsHandler = (resObj) => {
    console.log(resObj);
    const emList = !isEmpty(get(resObj, 'email', [])) ? get(resObj, 'email', []) : get(resObj, 'segment', []);
    const twList = get(resObj, 'twitter', []);
    const lnList = get(resObj, 'linkedin', []);
    const fbList = get(resObj, 'facebook', []);
    const templateHTML = get(resObj, 'template.template_html', '');
    let fetchedArticles = [];

    console.log(emList);

    if (get(resObj, 'posts_details.fb', false))
      fetchedArticles.push(
        get(resObj, 'posts_details.fb[0].articles.feed[0]', {})
      );
    else if (get(resObj, 'posts_details.tw', false))
      fetchedArticles.push(
        get(resObj, 'posts_details.tw[0].articles.feed[0]', {})
      );
    else if (get(resObj, 'posts_details.ln', false))
      fetchedArticles.push(
        get(resObj, 'posts_details.ln[0].articles.feed[0]', {})
      );
    else fetchedArticles = get(resObj, 'newsletter.articles.feed', []);

    let socialArticleId = '';

    if (get(resObj, 'posts.facebook.articles[0].id', ''))
      socialArticleId = get(resObj, 'posts.facebook.articles[0].id', '');
    else if (get(resObj, 'posts.twitter.articles[0].id', false))
      socialArticleId = get(resObj, 'posts.twitter.articles[0].id', '');
    else if (get(resObj, 'posts.linkedin.articles[0].id', false))
      socialArticleId = get(resObj, 'posts.linkedin.articles[0].id', '');

    let emailCampaign = {
      id: get(resObj, 'id', ''),
      campaign_id: get(resObj, 'campaign_id', ''),
      name: get(resObj, 'name'),
      template: templateHTML,
      subject:
        get(resObj, 'newsletter.customized_data.subject', '') ||
        get(resObj, 'customized_data.subject', ''),
      action: get(resObj, 'feedback.email.action', ''),
      em: {
        platform: !isEmpty(emList),
        timings:
        !isEmpty(get(resObj, 'email', [])) ? (
          resObj?.posts?.email?.schedule === 'immediate'
            ? [...getImmediateTimings(resObj, 'email')]
            : get(resObj, 'posts.email.schedule_time', [])) : (resObj?.posts?.segment?.schedule === 'immediate'
            ? [...getImmediateTimings(resObj, 'segment')]
            : get(resObj, 'posts.segment.schedule_time', [])),
        emailType: !isEmpty(get(resObj, 'email', [])) ? 'contacts' : 'segments'    
      },
      type: 'email',
      
      // contactlistNames: get(resObj, 'contactlist_names', {}),
    };

    console.log(emailCampaign);
    let socialCampaign = {
      id: get(resObj, 'id', ''),
      campaign_id: get(resObj, 'campaign_id', ''),
      name: get(resObj, 'name'),
      selectedArticles: fetchedArticles
        .filter((article) => article.id === socialArticleId)
        .map((article) => ({
          id: article.id,
          url: getMediaImage(get(resObj, 'media_urls', []), 'original'),
          themeId: get(article, 'ids.theme[0]', ''),
          topic: get(article, 'auxiliary.title', ''),
          link: get(article, 'links.social_permalink', ''),
        })),
      action: get(resObj, 'feedback.social.action', ''),
      fb: {
        timings:
          resObj?.posts?.facebook?.schedule === 'immediate'
            ? [...getImmediateTimings(resObj, 'posts.facebook.schedule_time')]
            : get(resObj, 'posts.facebook.schedule_time', []),
        platform: !isEmpty(fbList),
        summary: get(resObj, 'posts.facebook.override_text', ''),
      },
      tw: {
        timings:
          resObj?.posts?.twitter?.schedule === 'immediate'
            ? [
                {
                  time: 'Immediate',
                  destination_id: get(
                    resObj,
                    'posts.twitter.schedule_time[0].destination_id',
                    ''
                  ),
                },
              ]
            : get(resObj, 'posts.twitter.schedule_time', []),
        platform: !isEmpty(twList),
        summary: get(resObj, 'posts.twitter.override_text', ''),
      },
      ln: {
        timings:
          resObj?.posts?.linkedin?.schedule === 'immediate'
            ? [
                {
                  time: 'Immediate',
                  destination_id: get(
                    resObj,
                    'posts.linkedin.schedule_time[0].destination_id',
                    ''
                  ),
                },
              ]
            : get(resObj, 'posts.linkedin.schedule_time', []),
        platform: !isEmpty(lnList),
        summary: get(resObj, 'posts.linkedin.override_text', ''),
      },
      attachment: get(resObj, 'attachment', ''),
      type: 'social',
      posts: get(resObj, 'posts', {}),
      // socialAccountPages: get(resObj, 'social_account_pages', {}),
    };

    let campaigns = [];
    // console.log('emailCampaign', emailCampaign);

    if (emailCampaign.em.platform && emailCampaign.action === 'pending') {
      campaigns.push(emailCampaign);
    }
    if (
      (socialCampaign.fb.platform ||
        socialCampaign.ln.platform ||
        socialCampaign.tw.platform) &&
      socialCampaign.action === 'pending'
    ) {
      campaigns.push(socialCampaign);
    }

    console.log(campaigns);

    return campaigns;
  };

  const curPlatformCampaignHandler = (activeCampaign) => {
    console.log(activeCampaign);
    if (activeCampaign) {
      setCurPlatformCampaign(activeCampaign);
      setMsgCopy({
        fb: get(activeCampaign, 'fb.summary', ''),
        ln: get(activeCampaign, 'ln.summary', ''),
        tw: get(activeCampaign, 'tw.summary', ''),
      });
      if (!isEmpty(get(activeCampaign, 'id', ''))) {
        setCurrentCampaignID(get(activeCampaign, 'id', ''));
      } else if (!isEmpty(get(activeCampaign, 'campaign_id', ''))) {
        setCurrentCampaignID(get(activeCampaign, 'campaign_id', ''));
      }
      // console.log(activeCampaign);
      if (activeCampaign.em && activeCampaign.em.platform) {
        setActivePlatform('em');
      } else {
        for (let key in activeCampaign) {
          if (activeCampaign[key] && activeCampaign[key].platform) {
            setActivePlatform(key);
            break;
          }
        }
      }
    }
  };

  useEffect(() => {
    let resObj = {};
    console.log('CAMPAIGN ID - -- - ', campaignId);
    if (campaignId) {
      setCurrentCampaignID(campaignId);
      setCampaignReviewLoading(true);
      getCampaignDetails(campaignId)
        .then((res) => {
          setCampaignReviewLoading(false);
          resObj = get(res, 'result.data', {});
          // console.log('resObj', resObj);
          // setRemaining(get(resObj, 'remaining', true));
          let campaigns = curCampaignDetailsHandler(resObj);
          console.log(campaigns)
          setPlatformCampaigns(campaigns);
          curPlatformCampaignHandler(campaigns[0]);
        })
        .catch((err) => {
          setCampaignReviewLoading(false);
          console.log(err);
        });
    } else if (type === 'series' && !isEmpty(subCampaigns)) {
      let filteredSubCampaigns = subCampaigns.filter(
        (campaign) => campaign.status === 'approval_pending'
      );
      let totalCampaigns = [];
      filteredSubCampaigns.forEach((campaign) => {
        let campaigns = curCampaignDetailsHandler(campaign);
        totalCampaigns.push(...campaigns);
      });
      setPlatformCampaigns(totalCampaigns);
      curPlatformCampaignHandler(totalCampaigns[0]);
      // console.log(totalCampaigns);
    }
  }, [campaignId, type, subCampaigns]);

  useEffect(() => {
    if (activePlatform === 'em') {
      const iframe = document.querySelector('iframe');
      iframe.addEventListener(
        'load',
        (evt) => {
          var css = document.createElement('style');
          var st = '::-webkit-scrollbar { width: 5px }';
          st += ' ::-webkit-scrollbar-track { background: #e9f6e6 !important }';
          st += '::-webkit-scrollbar-thumb { background: #bccdd4 !important }';
          css.appendChild(document.createTextNode(st));
          iframe.contentDocument.head.append(css);
        },
        { once: true }
      );
    }
  }, [activePlatform]);

  const handleRejectModalClose = () => {
    setIsRejectModalOpen(false);
  };

  const handleCancelEditPost = () => {
    setCurPlatformCampaign({
      ...curPlatformCampaign,
      fb: {
        ...curPlatformCampaign?.fb,
        summary: msgCopy.fb,
      },
      ln: {
        ...curPlatformCampaign?.ln,
        summary: msgCopy.ln,
      },
      tw: {
        ...curPlatformCampaign?.tw,
        summary: msgCopy.tw,
      },
    });
    setIsEdit(false);
  };

  const handleSaveEditPost = () => {
    setIsLoading(true);
    let posts = curPlatformCampaign.posts;
    if (get(curPlatformCampaign, 'fb.platform', false)) {
      posts.facebook.override_text = get(curPlatformCampaign, 'fb.summary', '');
    }
    if (get(curPlatformCampaign, 'tw.platform', false)) {
      posts.twitter.override_text = get(curPlatformCampaign, 'tw.summary', '');
    }
    if (get(curPlatformCampaign, 'ln.platform', false)) {
      posts.linkedin.override_text = get(curPlatformCampaign, 'ln.summary', '');
    }

    let payload = {
      advisor_approval: true,
      posts: JSON.stringify({ ...posts }),
    };

    publishCampaignV2({ id: currentCampaignID, payload })
      .then((res) => {
        setIsLoading(false);
        if (res.result.success) {
          setMsgCopy({
            fb: get(curPlatformCampaign, 'fb.summary', ''),
            ln: get(curPlatformCampaign, 'ln.summary', ''),
            tw: get(curPlatformCampaign, 'tw.summary', ''),
          });
          let allCampaigns = platformCampaigns;
          if (get(allCampaigns[curIndex], 'fb.platform', false)) {
            allCampaigns[curIndex].fb.summary = get(
              curPlatformCampaign,
              'fb.summary',
              ''
            );
          }
          if (get(allCampaigns[curIndex], 'tw.platform', false)) {
            allCampaigns[curIndex].tw.summary = get(
              curPlatformCampaign,
              'tw.summary',
              ''
            );
          }
          if (get(allCampaigns[curIndex], 'ln.platform', false)) {
            allCampaigns[curIndex].ln.summary = get(
              curPlatformCampaign,
              'ln.summary',
              ''
            );
          }
          setPlatformCampaigns(allCampaigns);
          setIsEdit(false);
        } else {
          handleCancelEditPost();
        }
      })
      .catch((err) => {
        setIsLoading(false);
        handleCancelEditPost();
      });
  };

  const handleApproval = () => {
    // console.log(totalCampaignPlatforms);
    let payload = {
      action: 'approved',
      platform: platformForReview(),
    };
    setIsLoading(true);
    postCampaignReview({ id: currentCampaignID, payload })
      .then((res) => {
        if (res.result.success) {
          setIsLoading(false);
          filterCampaignsHandler(res);
          // setIsApproved(true);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  const nextCampaignApprovalHander = () => {
    setIsApproved(false);
    // if (curIndex < platformCampaigns.length - 1) {
    //   oneTimeCampaignHandler(curIndex + 1);
    //   if (!disableBackBtn) {
    //     setDisableBackBtn(true);
    //   }
    // } else {
    /* one-time flow goes here */
    setCampaignReviewLoading(true);
    nextCampaignReview(currentCampaignID)
      .then((res) => {
        let resObj = {};
        if (res.result.success) {
          setCampaignReviewLoading(false);
          resObj = get(res, 'result.data', {});
          if (resObj.length > 0) {
            setNoCampaigns('No pending campaigns!');
          } else {
            setNoCampaigns('');
            if (!isEmpty(get(resObj, 'campaign_id', ''))) {
              setCurrentCampaignID(get(resObj, 'campaign_id', ''));
              let campaigns = curCampaignDetailsHandler(resObj);
              setPlatformCampaigns(campaigns);
              curPlatformCampaignHandler(campaigns[0]);
            } else if (
              get(resObj, 'type', '') === 'series' &&
              !isEmpty(get(resObj, 'campaigns', []))
            ) {
              let filteredSubCampaigns = get(resObj, 'campaigns', []).filter(
                (campaign) => campaign.status === 'approval_pending'
              );
              let totalCampaigns = [];
              filteredSubCampaigns.forEach((campaign) => {
                let campaigns = curCampaignDetailsHandler(campaign);
                totalCampaigns.push(...campaigns);
              });
              setPlatformCampaigns(totalCampaigns);
              curPlatformCampaignHandler(totalCampaigns[0]);
            }
          }
        }
      })
      .catch((err) => {
        setCampaignReviewLoading(false);
        console.log(err);
      });
    // }
  };

  const oneTimeCampaignHandler = (pageIndex) => {
    if (pageIndex < platformCampaigns.length && pageIndex >= 0) {
      setCurIndex(pageIndex);
      curPlatformCampaignHandler(platformCampaigns[pageIndex]);
    }
  };

  const filterCampaignsHandler = (res) => {
    setRemaining(get(res, 'result.data.remaining', true));
    const campaigns = [
      ...platformCampaigns.filter((campaign) => {
        if ((campaign.id || campaign.campaign_id) !== currentCampaignID) {
          return true;
        } else {
          if (campaign.type !== platformForReview()) {
            return true;
          }
          return false;
        }
      }),
    ];
    // setIsApproveModalOpen(false);
    if (campaigns.length > 0) {
      setPlatformCampaigns(campaigns);
      curPlatformCampaignHandler(campaigns[0]);
      setCurIndex(0);
    } else {
      setIsApproved(true);
    }
  };

  console.log(curPlatformCampaign);

  return (
    <LoaderWrapper isLoading={campaignReviewLoading}>
      <Flex justifyContent="center" alignItems="center">
        {isApproved ? (
          <CreateContainer
            justifyContent="center"
            alignItems="center"
            color="black"
            marginTop="10px"
            marginBottom="10px"
            height="75vh"
            style={{
              backgroundColor: '#f5f6fa',
              width: '60%',
            }}
          >
            <Flex
              width="60%"
              backgroundColor="white"
              flexDirection="column"
              p="30px 20px"
            >
              {/* <Flex alignItems="center" justifyContent="center">
                <img
                  src="/assets/images/Congratulations_icon.svg"
                  height="65%"
                />
              </Flex> */}
              <ReviewBody
                fontWeight="700"
                fontSize="1.5rem"
                style={{ marginBottom: '20px' }}
              >
                Congratulations!
              </ReviewBody>
              <div
                style={{ textAlign: 'left', color: '#768389', margin: 'auto' }}
              >
                <Flex mb="10px">
                  <div style={{ marginRight: '10px' }}>
                    <FontAwesomeIcon icon={faCheckCircle} color="#67b548" />
                  </div>
                  <div>You've reviewed all content for this campaign</div>
                </Flex>
                <Flex mb="10px">
                  <div style={{ marginRight: '10px' }}>
                    <FontAwesomeIcon icon={faCheckCircle} color="#67b548" />
                  </div>
                  <div>All approved content will go out as scheduled</div>
                </Flex>
                <Flex mb="10px">
                  <div style={{ marginRight: '10px' }}>
                    <FontAwesomeIcon icon={faCheckCircle} color="#67b548" />
                  </div>
                  <div>
                    <div>Any rejected content will not go out and your</div>
                    <div>
                      feedback will be sent to your Clout success partner
                    </div>
                  </div>
                </Flex>
              </div>
              <Flex mt="30px" justifyContent="center" alignItems="center">
                <Button
                  onClick={() => history.push('/campaignV2')}
                  styles={{
                    marginRight: '10px',
                  }}
                  bgColor="#53667d"
                  color="white"
                  borderRadius="5px"
                  testId={'review-close'}
                >
                  Close
                </Button>
                {remaining && (
                  <Button
                    onClick={nextCampaignApprovalHander}
                    styles={{
                      marginRight: '10px',
                    }}
                    bgColor="white"
                    color={themesData.purpleishBlue}
                    border={`1px solid ${themesData.purpleishBlue}`}
                    borderRadius="5px"
                    testId={'review-next'}
                  >
                    Review Next Campaign
                  </Button>
                )}
              </Flex>
            </Flex>
          </CreateContainer>
        ) : (
          <>
            <CreateContainer
              justifyContent="flex-start"
              color="black"
              marginTop="10px"
              marginBottom="10px"
              height="75vh"
              style={{ backgroundColor: 'white', width: '60%' }}
            >
              {noCampaigns.length > 0 ? (
                <Flex
                  flexDirection="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  flexWrap="wrap"
                  p="10px"
                  width="100%"
                >
                  <div
                    style={{
                      margin: '25% auto',
                      color: themesData.purpleishBlue,
                    }}
                  >
                    {noCampaigns}
                  </div>
                </Flex>
              ) : (
                <Flex
                  flexDirection="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  flexWrap="wrap"
                  p="10px"
                  width="100%"
                >
                  <Flex width="100%">
                    <Flex
                      width="50%"
                      justifyContent="flex-start"
                      alignItems="center"
                    >
                      <div>
                        <Flex display="flex" flexDirection="column">
                          <Flex
                            alignItems="center"
                            justifyContent="flex-start"
                            style={{
                              // backgroundColor: '#eee',
                              // borderRadius: '5px',
                              // marginBottom: '25px',
                              width: 'fit-content',
                            }}
                          >
                            {curPlatformCampaign.em &&
                              curPlatformCampaign.em.platform && (
                                <Chips
                                  // onClick={() => {
                                  //   setActivePlatform('tw');
                                  // }}
                                  active={true}
                                  style={{
                                    marginRight: '5px',
                                    display: 'flex',
                                  }}
                                >
                                  <div
                                    className="chip"
                                    style={{
                                      padding: '0px',
                                      height: 'max-content',
                                      width: 'max-content',
                                      borderRadius: ' 50%',
                                      border: '1px solid transparent',
                                      cursor: 'auto',
                                    }}
                                  >
                                    <img
                                      src="/assets/images/campaign-platform-email.svg"
                                      alt=""
                                      style={{
                                        margin: ' 0px',
                                        width: '25px',
                                        height: '25px',
                                      }}
                                    />
                                    {/* Email */}
                                  </div>
                                </Chips>
                              )}
                            {curPlatformCampaign.fb &&
                              curPlatformCampaign.fb.platform && (
                                <Chips
                                  // onClick={() => {
                                  //   setActivePlatform('tw');
                                  // }}
                                  active={true}
                                  style={{
                                    marginRight: '5px',
                                    display: 'flex',
                                  }}
                                >
                                  <div
                                    className="chip"
                                    style={{
                                      padding: '0px',
                                      height: 'max-content',
                                      width: 'max-content',
                                      borderRadius: ' 50%',
                                      border: '1px solid transparent',
                                      cursor: 'auto',
                                    }}
                                  >
                                    <img
                                      src="/assets/images/campaign-platform-facebook.png"
                                      alt=""
                                      style={{
                                        margin: ' 0px',
                                        width: '25px',
                                        height: '25px',
                                      }}
                                    />
                                    {/* Facebook */}
                                  </div>
                                </Chips>
                              )}
                            {curPlatformCampaign.ln &&
                              curPlatformCampaign.ln.platform && (
                                <Chips
                                  // onClick={() => {
                                  //   setActivePlatform('tw');
                                  // }}
                                  active={true}
                                  style={{
                                    marginRight: '5px',
                                    display: 'flex',
                                  }}
                                >
                                  <div
                                    className="chip"
                                    style={{
                                      padding: '0px',
                                      height: 'max-content',
                                      width: 'max-content',
                                      borderRadius: ' 50%',
                                      border: '1px solid transparent',
                                      cursor: 'auto',
                                    }}
                                  >
                                    <img
                                      src="/assets/images/campaign-platform-linkedIn.png"
                                      alt=""
                                      style={{
                                        margin: ' 0px',
                                        width: '25px',
                                        height: '25px',
                                      }}
                                    />
                                    {/* LinkedIn */}
                                  </div>
                                </Chips>
                              )}
                            {curPlatformCampaign.tw &&
                              curPlatformCampaign.tw.platform && (
                                <Chips
                                  // onClick={() => {
                                  //   setActivePlatform('tw');
                                  // }}
                                  active={true}
                                  style={{
                                    marginRight: '5px',
                                    display: 'flex',
                                  }}
                                >
                                  <div
                                    className="chip"
                                    style={{
                                      padding: '0px',
                                      height: 'max-content',
                                      width: 'max-content',
                                      borderRadius: ' 50%',
                                      border: '1px solid transparent',
                                      cursor: 'auto',
                                    }}
                                  >
                                    <img
                                      src="/assets/images/campaign-platform-twitter.png"
                                      alt=""
                                      style={{
                                        margin: ' 0px',
                                        width: '25px',
                                        height: '25px',
                                      }}
                                    />
                                    {/* Twitter */}
                                  </div>
                                </Chips>
                              )}
                          </Flex>
                        </Flex>
                      </div>
                      <VerticalSeparator />
                      <ReviewHeader>
                        {get(curPlatformCampaign, 'name', '-')} - Preview
                      </ReviewHeader>
                    </Flex>
                    <Flex
                      width="50%"
                      justifyContent="flex-end"
                      alignItems="center"
                    >
                      <Flex>
                        <PaginationBtn
                          disabled={curIndex <= 0}
                          // onClick={() => seriesCampaignHandler(curIndex - 1)}
                          onClick={() => oneTimeCampaignHandler(curIndex - 1)}
                          data-testid="prev-platform-btn"
                        >
                          <FontAwesomeIcon icon={faAngleLeft} />
                        </PaginationBtn>
                        <PaginationInfo>
                          <span
                            style={{ color: themesData.purpleishBlue }}
                            data-testid="current-campaign"
                          >
                            {curIndex + 1}
                          </span>
                          <span style={{ color: '#55667b', margin: '0px 5px' }}>
                            of
                          </span>
                          <span
                            style={{ color: '#55667b' }}
                            data-testid="total-campaigns"
                          >
                            {platformCampaigns.length}
                          </span>
                        </PaginationInfo>
                        <PaginationBtn
                          disabled={curIndex >= platformCampaigns.length - 1}
                          // onClick={() => seriesCampaignHandler(curIndex + 1)}
                          onClick={() => oneTimeCampaignHandler(curIndex + 1)}
                          data-testid="next-platform-btn"
                        >
                          <FontAwesomeIcon icon={faAngleRight} />
                        </PaginationBtn>
                      </Flex>
                    </Flex>
                  </Flex>
                  <hr style={{ width: '100%', marginTop: '10px' }} />

                  {/* <ReviewHeader>Preview</ReviewHeader> */}
                  {/* <hr
                    style={{
                      width: '90%',
                      marginTop: '10px',
                      marginLeft: '0px',
                    }}
                  /> */}
                  <PreviewContainer>
                    {activePlatform === 'em' && (
                      <div>
                        <div style={{ marginBottom: '10px' }}>
                          Subject: {curPlatformCampaign?.subject || ''}
                        </div>
                        <TemplateContainer>
                          <iframe
                            srcDoc={curPlatformCampaign?.template}
                            frameBorder="no"
                          />
                        </TemplateContainer>
                      </div>
                    )}
                    {(activePlatform === 'fb' ||
                      activePlatform === 'ln' ||
                      activePlatform === 'tw') && (
                      <div
                        style={{
                          cursor: 'pointer',
                          height: '85%',
                          marginBottom: '50px',
                        }}
                      >
                        <Flex
                          width="100%"
                          justifyContent="center"
                          alignItems="center"
                          mb="10px"
                          onClick={() =>
                            window.open(
                              curPlatformCampaign?.attachment,
                              '_blank'
                            )
                          }
                        >
                          <img
                            alt="social"
                            src={
                              !isEmpty(curPlatformCampaign.selectedArticles) &&
                              curPlatformCampaign.selectedArticles[0].url
                            }
                            style={{
                              // width: 'inherit',
                              height: '200px',
                              borderRadius: '5px',
                            }}
                          />
                        </Flex>
                        <ReviewBody
                          fontWeight="700"
                          fontSize="1.2rem"
                          onClick={() =>
                            window.open(
                              curPlatformCampaign?.attachment,
                              '_blank'
                            )
                          }
                        >
                          {!isEmpty(curPlatformCampaign.selectedArticles) &&
                            curPlatformCampaign.selectedArticles[0].topic}
                        </ReviewBody>
                        {isEdit ? (
                          <div>
                            {(get(curPlatformCampaign, 'fb.platform', false) ||
                              get(curPlatformCampaign, 'ln.platform', false) ||
                              get(
                                curPlatformCampaign,
                                'tw.platform',
                                false
                              )) && (
                              <Flex
                                alignItems="center"
                                justifyContent="flex-start"
                                alignSelf="flex-start"
                                m="10px 0px"
                              >
                                <CheckboxInput
                                  type="checkbox"
                                  id="samePlatform"
                                  name="samePlatform"
                                  onClick={() =>
                                    manageAllPlatformCheck(isMsgCopyAllPlatform)
                                  }
                                  checked={isMsgCopyAllPlatform}
                                  disabled={activePlatform === ''}
                                />
                                <InputLabel
                                  fontSize="10px"
                                  fontWeight="400"
                                  color="#bbb"
                                  marginLeft="5px"
                                >
                                  Use same copy for all platforms
                                </InputLabel>
                              </Flex>
                            )}
                            <ShareCard
                              style={{
                                margin: ' 0px',
                                width: '100%',
                                height: '200px',
                              }}
                            >
                              <textarea
                                maxLength={getMessageCopyMaxLength()}
                                placeholder="Message Copy"
                                name={`share_copy_${activePlatform}`}
                                style={{
                                  width: '100%',
                                  height: '100%',
                                  borderRadius: '4px',
                                  padding: '10px 15px',
                                  color: '#4E6780',
                                  border: '1px solid #c9d0d8',
                                  resize: 'none',
                                  maxHeight: '260px',
                                  maxWidth: '760px',
                                }}
                                disabled={activePlatform === ''}
                                value={
                                  curPlatformCampaign[activePlatform].summary
                                }
                                onChange={(e) => {
                                  if (isMsgCopyAllPlatform) {
                                    setCurPlatformCampaign({
                                      ...curPlatformCampaign,
                                      fb: {
                                        ...curPlatformCampaign?.fb,
                                        summary: e.target.value,
                                      },
                                      ln: {
                                        ...curPlatformCampaign?.ln,
                                        summary: e.target.value,
                                      },
                                      tw: {
                                        ...curPlatformCampaign?.tw,
                                        summary: e.target.value,
                                      },
                                    });
                                  } else {
                                    setCurPlatformCampaign({
                                      ...curPlatformCampaign,
                                      [activePlatform]: {
                                        ...curPlatformCampaign[activePlatform],
                                        summary: e.target.value,
                                      },
                                    });
                                  }
                                }}
                              />
                            </ShareCard>
                          </div>
                        ) : (
                          <ReviewBody
                            fontSize="0.8rem"
                            style={{
                              paddingTop: '10px',
                              // paddingBottom: '50px',
                            }}
                          >
                            {curPlatformCampaign[activePlatform]?.summary}
                          </ReviewBody>
                        )}
                      </div>
                    )}
                    <div
                      style={{
                        // marginTop: '20px',
                        marginBottom: '10px',
                        position: 'absolute',
                        bottom: ' 0',
                      }}
                    >
                      <Flex
                        display="flex"
                        flexDirection="column"
                        pt="0px"
                        pl="0px"
                      >
                        <Flex
                          alignItems="center"
                          justifyContent="flex-start"
                          style={{
                            // backgroundColor: '#eee',
                            borderRadius: '5px',
                            // marginBottom: '25px',
                            // width: 'fit-content',
                          }}
                        >
                          {/* {curPlatformCampaign.em &&
                            curPlatformCampaign.em.platform && (
                              <Chips
                                onClick={() => {
                                  setActivePlatform('em');
                                }}
                                active={activePlatform == 'em'}
                                // style={{
                                //   marginRight: '5px',
                                //   display: 'flex',
                                // }}
                              >
                                <div
                                  className="chip"
                                  // style={{
                                  //   padding: '5px',
                                  //   height: 'max-content',
                                  //   width: 'max-content',
                                  //   borderRadius: ' 50%',
                                  // }}
                                >
                                  <img
                                    src="/assets/images/campaign-platform-email.svg"
                                    alt=""
                                    // style={{
                                    //   margin: ' 0px',
                                    //   width: '25px',
                                    //   height: '25px',
                                    // }}
                                  />
                                  Email
                                </div>
                              </Chips>
                            )} */}
                          {curPlatformCampaign.fb &&
                            curPlatformCampaign.fb.platform && (
                              <Chips
                                onClick={() => {
                                  setActivePlatform('fb');
                                }}
                                active={activePlatform === 'fb'}
                                // style={{
                                //   marginRight: '5px',
                                //   display: 'flex',
                                // }}
                                data-testid="fb-chip"
                              >
                                <div
                                  className="chip"
                                  // style={{
                                  //   padding: '5px',
                                  //   height: 'max-content',
                                  //   width: 'max-content',
                                  //   borderRadius: ' 50%',
                                  // }}
                                >
                                  <img
                                    src="/assets/images/campaign-platform-facebook.png"
                                    alt=""
                                    // style={{
                                    //   margin: ' 0px',
                                    //   width: '25px',
                                    //   height: '25px',
                                    // }}
                                  />
                                  Facebook
                                </div>
                              </Chips>
                            )}
                          {curPlatformCampaign.ln &&
                            curPlatformCampaign.ln.platform && (
                              <Chips
                                onClick={() => {
                                  setActivePlatform('ln');
                                }}
                                active={activePlatform === 'ln'}
                                // style={{
                                //   marginRight: '5px',
                                //   display: 'flex',
                                // }}
                                data-testid="ln-chip"
                              >
                                <div
                                  className="chip"
                                  // style={{
                                  //   padding: '5px',
                                  //   height: 'max-content',
                                  //   width: 'max-content',
                                  //   borderRadius: ' 50%',
                                  // }}
                                >
                                  <img
                                    src="/assets/images/campaign-platform-linkedIn.png"
                                    alt=""
                                    // style={{
                                    //   margin: ' 0px',
                                    //   width: '25px',
                                    //   height: '25px',
                                    // }}
                                  />
                                  LinkedIn
                                </div>
                              </Chips>
                            )}
                          {curPlatformCampaign.tw &&
                            curPlatformCampaign.tw.platform && (
                              <Chips
                                onClick={() => {
                                  setActivePlatform('tw');
                                }}
                                active={activePlatform === 'tw'}
                                // style={{
                                //   marginRight: '5px',
                                //   display: 'flex',
                                // }}
                                data-testid="tw-chip"
                              >
                                <div
                                  className="chip"
                                  // style={{
                                  //   padding: '5px',
                                  //   height: 'max-content',
                                  //   width: 'max-content',
                                  //   borderRadius: ' 50%',
                                  // }
                                >
                                  <img
                                    src="/assets/images/campaign-platform-twitter.png"
                                    alt=""
                                    // style={{
                                    //   margin: ' 0px',
                                    //   width: '25px',
                                    //   height: '25px',
                                    // }}
                                  />
                                  Twitter
                                </div>
                              </Chips>
                            )}
                        </Flex>
                      </Flex>
                    </div>
                  </PreviewContainer>
                  <hr
                    style={{
                      width: '90%',
                      marginTop: '10px',
                      marginLeft: '0px',
                    }}
                  />
                  <ReviewHeader
                    style={{
                      width: '100%',
                      textAlign: 'left',
                      marginBottom: '10px',
                    }}
                  >
                    Schedule
                  </ReviewHeader>
                  {!isEmpty(
                    get(curPlatformCampaign[activePlatform], 'timings', [])
                  ) &&
                    curPlatformCampaign[activePlatform].timings.map(
                      (timing, idx) => (
                        <Flex
                          backgroundColor="#f4f6fa"
                          p="10px"
                          mr="10px"
                          mb="10px"
                          style={{
                            borderRadius: '5px',
                            border: '1px solid #edeff3',
                          }}
                          key={idx}
                        >
                          <Flex
                            backgroundColor="white"
                            p="10px"
                            style={{ borderRadius: '5px' }}
                            alignItems="center"
                          >
                            <ReviewBody>
                              {console.log(curPlatformCampaign[activePlatform]?.emailType)}
                              {activePlatform === 'em' && curPlatformCampaign[activePlatform]?.emailType === 'contacts'
                                ? contactlistNames
                                  ? contactlistNames[timing?.destination_id]
                                  : platformMapper[activePlatform]
                                : activePlatform === 'em' && curPlatformCampaign[activePlatform]?.emailType === 'segments' ?
                                  !isEmpty(segmentList.filter(s => s.id === timing?.destination_id)) && segmentList.filter(s => s.id === timing?.destination_id)[0].name : 
                                timing.destination_id === 0
                                ? 'On News Feed'
                                : socialAccountPages
                                ? socialAccountPages[activePlatform][
                                    Number(timing?.destination_id)
                                  ]
                                : platformMapper[activePlatform]}
                              {/* {timing.destination_name ||
                                platformMapper[activePlatform]} */}
                            </ReviewBody>
                            <VerticalSeparator />
                            <ReviewBody fontWeight="700">
                              {timing.time === 'Immediate'
                                ? 'Immediately after approval'
                                : moment(timing.time).format(
                                    'DD MMM YYYY, h:mm a'
                                  )}
                            </ReviewBody>
                          </Flex>
                        </Flex>
                      )
                    )}
                </Flex>
              )}
            </CreateContainer>
            <ModalFooterBtnsWrapper style={{ backgroundColor: 'white' }}>
              <LoaderWrapper isLoading={isLoading}>
                <Flex>
                  {/* <Flex justifyContent="flex-start" py="10px" width="50%">
                    {noCampaigns.length === 0 && (
                      <Button
                        styles={{
                          borderRadius: '4px',
                        }}
                        color="red"
                        bgColor="white"
                        margin="0px 10px"
                        borderColor="red"
                        onClick={() => setIsRejectModalOpen(true)}
                        // disabled={noCampaigns.length > 0}
                        testId="campaign-reject"
                      >
                        Reject With Feedback
                      </Button>
                    )}
                  </Flex> */}
                  <Flex justifyContent="center" py="10px" width="100%">
                    {isEdit ? (
                      <div>
                        <Button
                          styles={{ borderRadius: '4px' }}
                          color="#fff"
                          bgColor="#4E6780"
                          margin="0px 10px"
                          borderColor="#4E6780"
                          onClick={handleCancelEditPost}
                          testId="review-campaign-cancel"
                        >
                          Cancel
                        </Button>
                        <Button
                          styles={{
                            borderRadius: '4px',
                          }}
                          color="#fff"
                          bgColor="#33b21b"
                          margin="0px 10px"
                          borderColor="#33b21b"
                          // disabled={}
                          onClick={handleSaveEditPost}
                          testId="campaign-save"
                        >
                          Save
                        </Button>
                      </div>
                    ) : (
                      <div>
                        <Button
                          styles={{ borderRadius: '4px' }}
                          color="#fff"
                          bgColor="#4E6780"
                          margin="0px 10px"
                          borderColor="#4E6780"
                          onClick={() => history.push('/campaignV2')}
                          testId="review-campaign-close"
                        >
                          Close
                        </Button>
                        {noCampaigns.length === 0 && (
                          <Button
                            styles={{
                              borderRadius: '4px',
                            }}
                            color="red"
                            bgColor="white"
                            margin="0px 10px"
                            borderColor="red"
                            onClick={() => setIsRejectModalOpen(true)}
                            // disabled={noCampaigns.length > 0}
                            testId="campaign-reject"
                          >
                            Reject With Feedback
                          </Button>
                        )}
                        {(get(curPlatformCampaign, 'fb.platform', false) ||
                          get(curPlatformCampaign, 'ln.platform', false) ||
                          get(curPlatformCampaign, 'tw.platform', false)) && (
                          <Button
                            styles={{
                              borderRadius: '4px',
                            }}
                            color={themesData.purpleishBlue}
                            bgColor="white"
                            margin="0px 10px"
                            borderColor={themesData.purpleishBlue}
                            onClick={() => setIsEdit(true)}
                            // disabled={noCampaigns.length > 0}
                            testId="campaign-edit-post"
                          >
                            Edit Post
                          </Button>
                        )}
                        {noCampaigns.length === 0 && (
                          <Button
                            styles={{
                              borderRadius: '4px',
                            }}
                            color="#fff"
                            bgColor="#33b21b"
                            margin="0px 10px"
                            borderColor="#33b21b"
                            onClick={handleApproval}
                            testId={'campaign-approve'}
                            // disabled={noCampaigns.length > 0}
                          >
                            Approve
                          </Button>
                        )}
                      </div>
                    )}
                  </Flex>
                </Flex>
              </LoaderWrapper>
            </ModalFooterBtnsWrapper>
            {currentCampaignID && (
              <>
                {isRejectModalOpen && (
                  <RejectCampaignModal
                    campaignId={currentCampaignID}
                    isOpen={isRejectModalOpen}
                    handleClose={handleRejectModalClose}
                    setIsApproved={filterCampaignsHandler}
                    platformForReview={platformForReview}
                  />
                )}
                {/* <ApproveCamapignModal
                  isOpen={isApproveModalOpen}
                  handleClose={() => setIsApproveModalOpen(false)}
                  handleApproval={handleApproval}
                  isLoading={isLoading}
                /> */}
              </>
            )}
          </>
        )}
      </Flex>
    </LoaderWrapper>
  );
};

const mapStateToProps = (state) => {
  return {
    campaignId: state.campaign.campaignId,
    type: state.campaign.type,
    subCampaigns: state.campaign.subCampaigns,
    contactlistNames: state.campaign.data.contactlist_names,
    socialAccountPages: state.campaign.data.social_account_pages,
  };
};

export default connect(mapStateToProps, null)(ReviewCampaigns);
