import React from 'react';
import { Flex } from '@rebass/grid';
import styled from 'styled-components';


const FooterStyles = styled(Flex)`
    background-color: ${({ bgColor }) => bgColor || '#fff'};
    border-width: 1px, 1px, 0px, 1px;
    border-style: solid;
    border-color: #E4E7EC;
    position: fixed;
    left: 0;
    bottom: 0;
`;

const Footer = ({
    children,
    bgColor
}) => {
  return (
    <FooterStyles width="100%" bgColor={bgColor}>
        {children}
    </FooterStyles>
  )
}

export default Footer;