import { Flex } from '@rebass/grid';
import { get, isEmpty } from 'lodash';
import React, { useState, useEffect } from 'react';
import { Button, LoaderWrapper } from '../../../components';
import {
  formatNewsletterDropdownItem,
  getBucketArticleData,
  getMediaUrl,
  themesData,
} from '../../../utils';
import { getNewsletterDetails } from '../../../services/newsletter';
import PreviewNewsletterModal from '../NewsletterModals/PreviewNewsletterModal';
import NewsletterOutboxArticle from '../NewsletterOutboxArticle';
import SentNewsletterModal from '../NewsletterModals/SentNewsletterModal';
import useQuery from '../../../customHooks/useQuery';
import { useHistory } from 'react-router-dom';

const SentOutbox = ({
  outBoxData,
  outBoxLoading,
  page,
  setPage,
  newsletterOutboxRequest,
  newsletterSetState,
  setShowTabs,
  setShowOutbox,
  setSelectedTabIndex,
  contactGroups,
  toggleCloneModal,
  setSelectedNewsletter,
  isPremiumAdvisor,
  channelId,
  popularThemes
}) => {
  let query = useQuery();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [selectedId, setSelectedId] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [initialIndex, setInitialIndex] = useState(0);
  const [endIndex, setEndIndex] = useState(0);
  const [openSentModal, setOpenSentModal] = useState(false);
  const [source, setSource] = useState('');
  const [selectedTopic, setSelectedTopic] = useState('');
  const [complianceStat, setComplianceStat] = useState(null);

  useEffect(() => {
    if (!isEmpty(outBoxData) && outBoxData.pagination) {
      if (+outBoxData.pagination.total_count > 0 && page === 0) {
        setInitialIndex(1);
        setEndIndex(
          +outBoxData.pagination.total_count < 6
            ? +outBoxData.pagination.total_count
            : 6
        );
      } else if (+outBoxData.pagination.total_count > 0 && page > 0) {
        setInitialIndex(page * 6 + 1);
        setEndIndex(
          (page + 1) * 6 >= +outBoxData.pagination.total_count
            ? +outBoxData.pagination.total_count
            : (page + 1) * 6
        );
      }
    } else {
      setInitialIndex(0);
      setEndIndex(0);
    }
  }, [outBoxData, page]);

  const handleModalOpen = (id, name, complianceStatus) => {
    setSelectedId(id);
    setOpenSentModal(true);
    setSelectedTopic(name);
    setComplianceStat(complianceStatus);
  };

  const onEditClick = (id, index, source, time, complianceStatus) => {
    setSelectedId(id);
    setLoading(true);
    setSource('edit');

    query.delete('type');

    history.replace({
      search: query.toString(),
    });

    getNewsletterDetails({ id })
      .then((res) => {
        if (res.result.success) {
          const data = get(res, 'result.data', {});
          newsletterSetState({
            newsletterName: data && data.name && data.name,
            scheduledTime: '',
            isEditMode: true,
            tab: 'sent',
            newsletterId: id,
            selectedContacts: data && data.contactlists,
            ccContacts: data && data.cc_email_ids ? data.cc_email_ids : [],
            emailTemplateSelected: data && data.template,
            newsletterArticles:
              data && data.articles && getBucketArticleData(data.articles),
            engagement_details: data && data.engagement_details,
            customData: {
              subject:
                data && data.customized_data && data.customized_data.subject,
              salutation:
                data && data.customized_data && data.customized_data.salutation,
              salutation_text:
                data &&
                data.customized_data &&
                data.customized_data.salutation_text
                  ? formatNewsletterDropdownItem(
                      data.customized_data.salutation_text
                    )
                  : '',
              message:
                data && data.customized_data && data.customized_data.message,
              media1:
                data &&
                data.customized_data &&
                data.customized_data.media_urls &&
                !isEmpty(data.customized_data.media_urls) &&
                getMediaUrl(data.customized_data.media_urls),
              compliance_status: complianceStatus,
              title: data && data.customized_data && data.customized_data.title,
            },
            leadCapture: {
              lead_gen: data && data.lead_gen,
              referral: data && data.referral,
            },
          });
          setSelectedId('');
          setLoading(false);
          setSource('');
          setShowTabs(true);
          setShowOutbox(false);
          setSelectedTabIndex(0);
        } else {
          setSelectedId('');
          setSource('');
          setLoading(false);
        }
      })
      .catch((err) => {
        setSelectedId('');
        setSource('');
        setLoading(false);
      });
  };

  const getDetails = (id) => {
    setSelectedId(id);
    setLoading(true);

    getNewsletterDetails({ id })
      .then((res) => {
        if (res.result.success) {
          const data = get(res, 'result.data', {});
          newsletterSetState({
            newsletterName: data && data.name && data.name,
            isEditMode: true,
            newsletterId: id,
            scheduledTime: '',
            selectedContacts: [],
            emailTemplateSelected: data && data.template,
            tab: '',
            newsletterArticles:
              data && data.articles && getBucketArticleData(data.articles),
            customData: {
              subject:
                data && data.customized_data && data.customized_data.subject,
              salutation:
                data && data.customized_data && data.customized_data.salutation,
              salutation_text:
                data &&
                data.customized_data &&
                data.customized_data.salutation_text
                  ? formatNewsletterDropdownItem(
                      data.customized_data.salutation_text
                    )
                  : '',
              message:
                data && data.customized_data && data.customized_data.message,
              media1:
                data &&
                data.customized_data &&
                data.customized_data.media_urls &&
                !isEmpty(data.customized_data.media_urls) &&
                getMediaUrl(data.customized_data.media_urls, '', 'original'),
              title: data && data.customized_data && data.customized_data.title,
            },
            cloneDetails:
              data && data.original_newsletter_details
                ? data.original_newsletter_details
                : null,
          });
          setSelectedId('');
          setLoading(false);
          setOpenModal(true);
        } else {
          setSelectedId('');
          setLoading(false);
        }
      })
      .catch((err) => {
        setSelectedId('');
        setLoading(false);
      });
  };

  return (
    <LoaderWrapper
      isLoading={outBoxLoading}
      styles={{ position: 'absolute', bottom: '5%' }}
    >
      <Flex width="100%" flexDirection="column" padding="15px 35px">
        {outBoxData && !isEmpty(outBoxData.newsletters) ? (
          <Flex justifyContent="center" width="100%" flexWrap="wrap">
            {outBoxData.newsletters
              .slice(page * 6, (page + 1) * 6)
              .map((item, index) => (
                <NewsletterOutboxArticle
                  {...item}
                  selectedId={selectedId}
                  loading={loading}
                  tabSource="Sent"
                  getPreviewDetails={getDetails}
                  handleOpen={handleModalOpen}
                  onEditClick={onEditClick}
                  source={source}
                  toggleCloneModal={toggleCloneModal}
                  newsletter={item}
                  setSelectedNewsletter={setSelectedNewsletter}
                  isPremiumAdvisor={isPremiumAdvisor}
                />
              ))}
            {outBoxData.newsletters.slice(page * 6, (page + 1) * 6).length %
              3 !==
              0 &&
              outBoxData.newsletters.slice(page * 6, (page + 1) * 6).length %
                3 ===
                1 && (
                <>
                  <i
                    aria-hidden="true"
                    style={{
                      width: '215px',
                      height: '208px',
                      margin: '14px 10px 35px 8.2px',
                      padding: '0px',
                    }}
                  ></i>
                  <i
                    aria-hidden="true"
                    style={{
                      width: '215px',
                      height: '208px',
                      margin: '14px 10px 35px 8.2px',
                      padding: '0px',
                    }}
                  ></i>
                </>
              )}
            {outBoxData.newsletters.slice(page * 6, (page + 1) * 6).length %
              3 !==
              0 &&
              outBoxData.newsletters.slice(page * 6, (page + 1) * 6).length %
                3 ===
                2 && (
                <i
                  aria-hidden="true"
                  style={{
                    width: '215px',
                    height: '208px',
                    margin: '14px 10px 35px 8.2px',
                    padding: '0px',
                  }}
                ></i>
              )}
          </Flex>
        ) : (
          <div style={{ textAlign: 'center' }}>No Records Found</div>
        )}
        <Flex
          width="100%"
          justifyContent="space-between"
          pt="10px"
          alignItems="center"
        >
          <Button
            padding="5px 20px"
            height="30px"
            margin="0px"
            onClick={() => setPage(page - 1)}
            disabled={page < 1 ? true : false}
          >
            Previous
          </Button>
          <Flex color={themesData.charcoalGrey} fontSize="13px">
            {initialIndex}-{endIndex} of{' '}
            {outBoxData &&
              outBoxData.pagination &&
              +outBoxData.pagination.total_count}
          </Flex>
          <Button
            padding="5px 20px"
            height="30px"
            margin="0px"
            onClick={() => setPage(page + 1)}
            disabled={
              get(outBoxData, 'pagination.total_count', '0') ===
              endIndex.toString()
            }
          >
            Next
          </Button>
        </Flex>
      </Flex>

      {openModal && (
        <PreviewNewsletterModal 
          open={openModal} 
          setOpen={setOpenModal} 
          channelId={channelId}
          popularThemes={popularThemes}
        />
      )}

      {openSentModal && (
        <SentNewsletterModal
          open={openSentModal}
          setOpen={setOpenSentModal}
          selectedId={selectedId}
          setSelectedId={setSelectedId}
          selectedTopic={selectedTopic}
          setSelectedTopic={setSelectedTopic}
          contactGroups={contactGroups}
          complianceStatus={complianceStat}
          isPremiumAdvisor={isPremiumAdvisor}
        />
      )}
    </LoaderWrapper>
  );
};

export default SentOutbox;
