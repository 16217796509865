import React, { useState } from 'react'
import { SnippetTextBox, SnippetTitle, SnippetWrapper } from '../ShareContentContainer.styles';
import { isEmpty } from 'lodash';

const SummaryBox = ({
  summary,
  isFetchingSummary,
  isAdmin
}) => {

  const [initialIndex, setInitialIndex] = useState(isAdmin ? 330 : 290);
  const [isExpanded, setIsExpanded] = useState(false);

  const expandSection = () => {
    if(!isExpanded) {
      setInitialIndex(isAdmin ? 480 : 440);
      setIsExpanded(true);
    }
  }
  const displaySummary = () => {
    if(isFetchingSummary) {
      return (
        <div style={{height: '100px', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%'}}>
          Generating Summary...
        </div>
      )
    } else if(!isFetchingSummary && isEmpty(summary)) {
      return (
        <div style={{height: '100px', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%'}}>
          No Summary
        </div>
      )
    } else {
      if(summary.length > initialIndex) {
        return (
          <div style={{overflowX: 'hidden', textAlign: 'justify', wordSpacing: '-2px'}}>
            {summary.substring(0, initialIndex)}
            <span onClick={expandSection} style={{cursor: !isExpanded ? 'pointer' : 'default'}}> ...</span>
          </div>
        ) 
      } else {
        return (
          <div style={{overflowX: 'hidden', textAlign: 'justify'}}>
            {summary}
          </div>
        ) 
      }
    }
  }

  return (
    <SnippetWrapper>
      <SnippetTitle>Summary</SnippetTitle>
      <SnippetTextBox id="text-box">
        {displaySummary()}
      </SnippetTextBox>
    </SnippetWrapper>
  )
}

export default SummaryBox;
