import React from 'react'
import CampaignViewContainer from '../../containers/CampaignViewContainer/CampaignViewContainer';
import withDripCampaignLayout from '../../hoc/withDripCampaignLayout'

const CampaignContainerViewLayout = () => {
    return (
        <CampaignViewContainer />
    )
}

export default withDripCampaignLayout(CampaignContainerViewLayout);
