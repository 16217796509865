import React from 'react';

function Contacts() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 33 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.667 18C19.8761 18 21.667 16.2091 21.667 14C21.667 11.7909 19.8761 10 17.667 10C15.4579 10 13.667 11.7909 13.667 14C13.667 16.2091 15.4579 18 17.667 18Z"
        stroke="currentColor"
        stroke-width="2.4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M4.66699 13.5H7.66699"
        stroke="currentColor"
        stroke-width="2.4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M4.66699 8.5H7.66699"
        stroke="currentColor"
        stroke-width="2.45"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M4.66699 18.499H7.66699"
        stroke="currentColor"
        stroke-width="2.45"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M4.66699 23.5H7.66699"
        stroke="currentColor"
        stroke-width="2.45"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M11.667 21.001C12.3656 20.0695 13.2715 19.3135 14.3129 18.7928C15.3543 18.2721 16.5027 18.001 17.667 18.001C18.8313 18.001 19.9797 18.2721 21.0211 18.7928C22.0625 19.3135 22.9684 20.0695 23.667 21.001"
        stroke="currentColor"
        stroke-width="2.45"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M27.667 27V5C27.667 4.44772 27.2193 4 26.667 4L8.66699 4C8.11471 4 7.66699 4.44772 7.66699 5V27C7.66699 27.5523 8.11471 28 8.66699 28H26.667C27.2193 28 27.667 27.5523 27.667 27Z"
        stroke="currentColor"
        stroke-width="2.45"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}


export default Contacts