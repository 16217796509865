import React, { useState, useEffect } from 'react';
import { get, isEmpty } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Flex, Box } from '@rebass/grid';
import {
  faComment,
  faSignal,
  faUserFriends,
} from '@fortawesome/free-solid-svg-icons';
import { parse } from 'json2csv';

import {
  VerticalTabs,
  DonutChart,
  NumbersWithArrows,
  Table,
  Tr,
  Th,
  Td,
  TableHeading,
  MemberTile,
  InviteModal,
  CreateAdminModal,
  Tabs,
  StyledTabListLeft,
  TabTypeTwo,
  DateDropdown,
  DownloadButton,
  LoaderWrapper,
  ErrorModal,
  Button,
} from '../../../components';
import {
  transformDonutData,
  getTabNameFromRepType,
  parseValueToCsv,
  saveFile,
} from '../../../utils';
import {
  AnalyticsVerticalTab,
  AnalyticsVerticalTabList,
  AnalyticsVerticalTabPanel,
  TabLabel,
  TabContent,
  CommonTabPanel,
  DownloadButtonFlex,
} from '../AnalyticsContainer.styles';
import {
  WeekInfoWrapper,
  LegendBox,
  TopMemberTableBody,
  MemberInviteImg,
} from './MembersTab.styles';
import {
  getUsersList,
  getAllSubscribes,
  postUpdateRequesteduserStatus,
} from '../../../services/analytics';
import RequestedMembers from './RequestedMembers';
import RegisteredMembers from './RegisteredMembers';

const mockData = {
  status: 1,
  data: {
    '0': 75,
    '1': 9,
    '2': 8,
    '3': 8,
    highestVal: 75,
    highestKey: 0,
    high: '278',
    low: '29',
    h_perc: -0,
    l_perc: 4,
  },
};

const legendColorList = [
  '#6351ED',
  '#c95cfc',
  '#6699FF',
  '#7dc4d0',
  '#E2DFFB',
]

const MembersTab = ({
  groupsList,
  inviteRequest,
  advisorGroupsRequest,
  selectedDate,
  setSelectedDate,
  isAnalyticsDataFetching,
  membersList,
  membersRequest,
  analyticsRequest,
  analyticsData,
  channelId,
  analyticsObject,
  selectedRepType,
  inviteSending,
  inviteError,
  inviteSetState,
  isAdminEnterprise,
  isEnterpriseLevelAdmin,
  isFirmLevelAdmin
}) => {
  const [isInviteModalOpen, setInviteModalOpen] = useState(false);
  const [isCreateAdminModalOpen, setCreateAdminModalOpen] = useState(false);
  const [memberTableData, setMemberTableData] = useState([]);
  const [invitedMembers, setInvitedMembersData] = useState([]);
  const [requestedMembers, setRequestedMembersData] = useState([]);
  const [selectedUsersTab, setSelectedUsersTab] = useState({
    id: 'registered',
    label: 'Registered members',
    noDataInfo: 'No registered members found',
  });
  const [onSavingRegUserAction, setOnSavingRegUserAction] = useState(false);
  const [onSavingComplete, setonSavingComplete] = useState(false);
  const [topMembersPage, setTopMembersPage] = useState(0);

  const dataObj = get(mockData, 'data');

  useEffect(() => {
    setMemberTableData(membersList);
  }, [membersList]);

  const apiRequest = (repType) => {
    analyticsRequest({
      params: {
        repType,
      },
    });
  };

  const onInsightScoreTabClick = () => {
    apiRequest('mio_score');
  };

  const usersRequest = () => {
    Promise.all([getUsersList('invited'), getAllSubscribes(channelId)]).then(
      (responses) => {
        const invitedMembersData = responses[0];
        const requestedMembersData = responses[1];
        setInvitedMembersData(get(invitedMembersData, 'result.data.users', []));
        setRequestedMembersData(requestedMembersData);
      }
    );
  };

  const onMembershipsTabClick = () => {
    if (isEmpty(membersList)) {
      membersRequest();
    }

    if (isEmpty(invitedMembers) || isEmpty(requestedMembers)) {
      usersRequest();
    }
  };

  const requestMembersTabClick = (e) => {
    e.stopPropagation();
    setSelectedUsersTab({
      id: 'requested',
      label: 'Requested Members',
      noDataInfo: 'No requested members found',
    });
    setMemberTableData(requestedMembers);
  };

  const invitedMembersTabClick = (e) => {
    e.stopPropagation();
    setSelectedUsersTab({
      id: 'invited',
      label: 'Invited Members',
      noDataInfo: 'No invited members found',
    });
    setMemberTableData(invitedMembers);
  };

  const registeredMembersTabClick = (e) => {
    e.stopPropagation();
    setSelectedUsersTab({
      id: 'registered',
      label: 'Registered members',
      noDataInfo: 'No registered members found',
    });
    setMemberTableData(membersList);
  };

  const onTopMembersTabClick = () => {
    apiRequest('top_members');
  };

  const onRequestedUsersActionClick = (userId, status) => {
    setOnSavingRegUserAction(true);
    postUpdateRequesteduserStatus({
      channelId,
      userId,
      status,
    })
      .then((response) => {
        const isSuccess = get(response, 'result.success');
        if (isSuccess) {
          setonSavingComplete(true);
          setOnSavingRegUserAction(false);
          usersRequest();
        }
      })
      .catch((err) => {
        setOnSavingRegUserAction(false);
      });
  };

  const onTabSelect = (tabIndex) => {
    if (tabIndex === 0) {
      onInsightScoreTabClick();
    } else if (tabIndex === 1) {
      onMembershipsTabClick();
    } else if (tabIndex === 2) {
      onTopMembersTabClick();
    }
  };

  useEffect(() => {
    onTabSelect(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const analyticsData = get(
  //   analyticsObject,
  //   `${selectedRepType}.slicedData`,
  //   []
  // );

  const graphData = get(
    analyticsObject,
    `${selectedRepType}.data`,
    []
  );

  const onDownloadCSVClick = () => {
    const { startDate, endDate } = analyticsObject || {};
    const fileName = `Topuser-${startDate}_${endDate}`;
    const column1Name = 'Sr.No';
    const column2Name = 'Member';
    const column3Name = 'Score';
    const fields = [column1Name, column2Name, column3Name];
    const users = Object.keys(analyticsData.analytics.users).map(key => ({
      name: analyticsData.analytics.users[key].name,
      score: Math.trunc(parseFloat(analyticsData.analytics.users[key].score))
    }));
    const csvData = parse(
      users.map((item, index) => ({
        [column1Name]: index + 1,
        [column2Name]: item.name,
        [column3Name]: item.score
      })),
      {
        fields,
      }
    );
    saveFile(csvData, fileName);
  };

  return (
    <VerticalTabs type='members-tab' screenWidth={window.innerWidth} onSelect={onTabSelect}>
      <AnalyticsVerticalTabList screenWidth={window.innerWidth}>
        <AnalyticsVerticalTab>
          <TabContent>
            <FontAwesomeIcon icon={faSignal} />
            <div>
              <TabLabel>Member Clout</TabLabel>
            </div>
          </TabContent>
        </AnalyticsVerticalTab>
        <AnalyticsVerticalTab>
          <TabContent>
            <FontAwesomeIcon icon={faComment} />
            <div>
              <TabLabel>Memberships</TabLabel>
            </div>
          </TabContent>
        </AnalyticsVerticalTab>
        <AnalyticsVerticalTab>
          <TabContent>
            <FontAwesomeIcon icon={faUserFriends} />
            <div>
              <TabLabel>Top Members</TabLabel>
            </div>
          </TabContent>
        </AnalyticsVerticalTab>
      </AnalyticsVerticalTabList>

      <AnalyticsVerticalTabPanel screenWidth={window.innerWidth}>
        <Flex flexDirection="column" width={1}>
          <Flex width={1} justifyContent="center">
            <Box height="250px" width={8 / 10}>
              <DonutChart
                loading={isAnalyticsDataFetching}
                data={transformDonutData(graphData)}
                colors={[
                  '#6351ED',
                  '#c95cfc',
                  '#6699FF',
                  '#7dc4d0',
                  '#E2DFFB',
                ]}
              />
            </Box>
            <Box width={2 / 10}>
              <WeekInfoWrapper>
                <div className="heading-text">This week</div>
                <div className="data-wrapper">
                  <div className="data-type">High</div>
                  <div className="data-value">
                    <NumbersWithArrows value={dataObj.h_perc} suffix="%" />
                  </div>
                </div>
                <div className="data-wrapper">
                  <div className="data-type">Low</div>
                  <div className="data-value">
                    <NumbersWithArrows value={dataObj.l_perc} suffix="%" />
                  </div>
                </div>
              </WeekInfoWrapper>
            </Box>
          </Flex>
          {!isAnalyticsDataFetching && (
            <Flex width={1} justifyContent="flex-start" mx="auto" style={{ paddingBottom: '30px', paddingTop: '40px' }}>
              {graphData.map((item, index) => {
                const rangeValue = get(item, 'value', '').toString();
                const rangeName = item && item.name && get(item, 'name', '').toString();
                return (
                  <LegendBox legendColor={legendColorList[index]} style={{ paddingRight: '30px' }}>
                    <Flex width={1} alignItems="center" marginRight="8px">
                      <div className="legend-color"></div>&nbsp;&nbsp;
                      <div className="range-label">Range {rangeName}</div>
                    </Flex>
                    <div className="range-value">
                      <strong>{rangeValue}%</strong>
                    </div>
                  </LegendBox>
                );
              })}
            </Flex>
          )}
        </Flex>
      </AnalyticsVerticalTabPanel>
      <AnalyticsVerticalTabPanel screenWidth={window.innerWidth}>
        <Flex width={1} flexDirection="column">
          <Flex width={1} mb="20px" justifyContent="space-between">
            <Box width={1} mx="5px" >
              <MemberTile
                title="My members"
                onClick={registeredMembersTabClick}
              >
                <Flex width={1} justifyContent="center" py="20px">
                  <Box width={1} onClick={invitedMembersTabClick}>
                    <div style={{ textAlign: 'left', paddingLeft: '21px', fontSize: '13px' }}>Invited</div>
                    <h2>{invitedMembers.length}</h2>
                  </Box>
                  <Box width={1} onClick={requestMembersTabClick}>
                    <div style={{ textAlign: 'left', paddingRight: '10px', fontSize: '13px' }}>Requested</div>
                    <h2 style={{ paddingRight: '15px' }}>{requestedMembers.length}</h2>
                  </Box>
                </Flex>
              </MemberTile>
            </Box>
            <Box width={1} mx="5px" >
              <MemberTile
                title="Membership"
                onClick={() => setInviteModalOpen(true)}
              >
                <Flex width={1} justifyContent="center" py="8px">
                  <MemberInviteImg src="/assets/images/Member_icon.png" style={{padding: '38px 0px'}}/>
                </Flex>
              </MemberTile>
              <InviteModal
                isModalOpen={isInviteModalOpen}
                setModalOpen={() => setInviteModalOpen(false)}
                groupsList={groupsList}
                inviteRequest={inviteRequest}
                advisorGroupsRequest={advisorGroupsRequest}
                inviteSending={inviteSending}
                inviteError={inviteError}
                channelId={channelId}
                isEnterpriseLevelAdmin={isEnterpriseLevelAdmin}
                isFirmLevelAdmin={isFirmLevelAdmin}
              />
            </Box>
            <Box width={1} mx="5px">
              <MemberTile
                title="Create Admin"
                onClick={() => setCreateAdminModalOpen(true)}
              >
                <Flex width={1} justifyContent="center" py="8px">
                  <MemberInviteImg src="/assets/images/admin.png" style={{padding: '38px 0px'}}/>
                </Flex>
              </MemberTile>
              <CreateAdminModal
                isModalOpen={isCreateAdminModalOpen}
                closeModal={() => {
                  inviteSetState({isError: false, error: ''});
                  setCreateAdminModalOpen(false);
                }}
                inviteRequest={inviteRequest}
                inviteSending={inviteSending}
                inviteError={inviteError}
                inviteSetState={inviteSetState}
              />
            </Box>
          </Flex>
          <Flex width={get(selectedUsersTab, 'id') !== 'requested' ? 1 : 8 / 10} mx="auto">
            <LoaderWrapper isLoading={onSavingRegUserAction}>
              {get(selectedUsersTab, 'id') === 'requested' && (
                <RequestedMembers
                  label={get(selectedUsersTab, 'label')}
                  data={memberTableData}
                  noDataInfo={get(selectedUsersTab, 'noDataInfo')}
                  onActionClick={onRequestedUsersActionClick}
                />
              )}
              {get(selectedUsersTab, 'id') !== 'requested' && (
                <RegisteredMembers
                  data={memberTableData}
                  label={get(selectedUsersTab, 'label')}
                  noDataInfo={get(selectedUsersTab, 'noDataInfo')}
                  isInvitedMembers={get(selectedUsersTab, 'id') === 'invited'}
                />
              )}
            </LoaderWrapper>
          </Flex>
        </Flex>
        {onSavingComplete && (
          <ErrorModal onClose={() => setonSavingComplete(false)}>
            User action completed
          </ErrorModal>
        )}
      </AnalyticsVerticalTabPanel>
      <AnalyticsVerticalTabPanel screenWidth={window.innerWidth}>
        <Tabs>
          <StyledTabListLeft>
            <TabTypeTwo type='members' style={{ borderBottom: '2px solid #6351ed', padding: '0px', left: '-20px', marginBottom: '10px' }}>By Score</TabTypeTwo>
          </StyledTabListLeft>
          <CommonTabPanel padding="0px" style={{border: '0px'}}>
            <DateDropdown value={selectedDate} onDateChange={setSelectedDate} type='members-tab' />
            <LoaderWrapper isLoading={isAnalyticsDataFetching}>
              <Table>
                <TableHeading>
                  <Tr style={{ border: '0px' }}>
                    {/* <Th width="20%"></Th> */}
                    <Th style={{padding: '15px 15px'}} align="center">User Name</Th>
                    <Th style={{padding: '15px 15px'}} align="center">Scores</Th>
                  </Tr>
                </TableHeading>
                <TopMemberTableBody>
                  {analyticsData.analytics && analyticsData.analytics.users && Object.keys(analyticsData.analytics.users).slice(topMembersPage*10, topMembersPage*10+10).map((key, index) => {
                    console.log('SCORE - ', analyticsData.analytics.users[key].score);
                    return (
                      <Tr>
                        <Td align="center" padding='8px 15px'>{analyticsData.analytics.users[key].name}</Td>
                        <Td align="center" padding='8px 15px'>{Math.trunc(parseFloat(analyticsData.analytics.users[key].score))}</Td>
                      </Tr>
                    );
                  })}
                  {analyticsData.analytics && analyticsData.analytics.users && Object.keys(analyticsData.analytics.users).length>10 && (
                    <Tr style={{borderBottom: '0px', position: 'absolute', bottom: '0px', left: '0px'}}>
                      <Td width='100%' align='center' padding='14px'>
                        <Flex justifyContent='space-between' alignItems='baseline'>
                          <div style={{marginLeft: '30px', color: '#49484a'}}>{topMembersPage*10+1}-{Object.keys(analyticsData.analytics.users).length<(topMembersPage+1)*10 ? Object.keys(analyticsData.analytics.users).length : (topMembersPage+1)*10} of {Object.keys(analyticsData.analytics.users).length}</div>
                          <Flex>
                            <Button width='75px' padding='7px' margin='0px' styles={{marginRight: '12px'}} onClick={() => setTopMembersPage(topMembersPage-1)} disabled={topMembersPage===0}>Previous</Button>
                            <Button width='75px' padding='7px' margin='0px' onClick={() => setTopMembersPage(topMembersPage+1)} disabled={Object.keys(analyticsData.analytics.users).length<=(topMembersPage+1)*10}>Next</Button>
                          </Flex>
                        </Flex>
                      </Td>
                    </Tr>
                  )}
                </TopMemberTableBody>
              </Table>
            </LoaderWrapper>
          </CommonTabPanel>
          <DownloadButtonFlex>
            <DownloadButton onClick={onDownloadCSVClick} />
          </DownloadButtonFlex>
        </Tabs>
      </AnalyticsVerticalTabPanel>
    </VerticalTabs>
  );
};

export default MembersTab;
