import React from 'react';
import { Flex } from '@rebass/grid';
import { Button, LoaderWrapper } from '../../components';

const ThankyouPage = ({
  handleClick,
  email,
  generalNewsletters,
  ecards,
  firmUpdates,
  events,
}) => {
  return (
    <Flex
      style={{
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        width: window.innerWidth < 600 ? '75%' : '100%',
        margin: '0 auto',
      }}
    >
      <div
        style={{
          padding: '10px 20px 10px',
        }}
      >
        <img alt="" src="/assets/images/thankyoutick.svg" />
      </div>
      <div style={{ padding: '10px', lineHeight: '25px', fontSize: '35px' }}>
        Thank You
      </div>
      <div style={{ padding: '20px', fontSize: '20px', color: '#1D2939' }}>
        Your email preferences have been successfully saved.
        {window.innerWidth < 600 ? (
          <Flex
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            <Flex
              color="#101828"
              mt="15px"
              fontSize="11px"
              justifyContent="center"
            >
              Your Email Address:
            </Flex>
            <Flex>
              <a
                style={{ fontSize: '11px', wordBreak: 'break-all' }}
                href={`mailto:${email}`}
              >
                {email}
              </a>
            </Flex>
          </Flex>
        ) : (
          <Flex
            color="#101828"
            mt="15px"
            fontSize="11px"
            justifyContent="center"
          >
            Your Email Address: &nbsp; <a href={`mailto:${email}`}>{email}</a>
          </Flex>
        )}
      </div>
      <Flex width="100%" justifyContent="center">
        <div
          style={{
            width: window.innerWidth < 600 ? '100%' : '50%',
            height: 'auto',
            border: '1px solid #6250ED',
            borderRadius: '8px',
            marginLeft: window.innerWidth < 600 ? '0px' : '0px',
            marginTop: '10px',
            boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.12)',
            background: 'rgb(88, 80, 237, 0.1)',
            fontSize: '14px',
            color: '#101828',
            padding: '13px 10px 10px 10px',
            textAlign: 'left',
          }}
        >
          <img
            alt=""
            src="/assets/images/information-blue.svg"
            style={{
              width: '15px',
              height: '15px',
              marginLeft: '3px',
              marginRight: '9px',
              marginBottom: '-3px',
            }}
          />
          <span>
            Based on your saved preferences you will no longer receive:
          </span>
          <ul
            style={{
              marginTop: '5px',
              marginBottom: '5px',
              marginLeft: '-21px',
            }}
          >
            {ecards && (
              <li
                color="black"
                style={{
                  width: '100%',
                  color: '#475467',
                  fontSize: '11px',
                  textAlign: 'left',
                }}
              >
                E-Cards: Well-wishes for birthdays, holidays and other special
                occasions
              </li>
            )}
            {events && (
              <li
                style={{
                  width: '100%',
                  color: '#475467',
                  fontSize: '11px',
                  textAlign: 'left',
                }}
              >
                Event Invitations: Invitations and related communications for
                in-person and virtual webcast events
              </li>
            )}
            {firmUpdates && (
              <li
                style={{
                  width: '100%',
                  color: '#475467',
                  fontSize: '11px',
                  textAlign: 'left',
                }}
              >
                Firm Updates: Updates about our business such as personnel
                changes and office closures.
              </li>
            )}
            {generalNewsletters && (
              <li
                style={{
                  width: '100%',
                  color: '#475467',
                  fontSize: '11px',
                  textAlign: 'left',
                }}
              >
                Newsletters: Insights and commentary on the markets, economy,
                financial planning and other relevant topics.
              </li>
            )}
          </ul>
        </div>
      </Flex>
      <hr
        style={{
          marginTop: '25px',
          marginBottom: '25px',
          width: window.innerWidth < 600 ? '100%' : '100%',
          marginLeft: window.innerWidth < 600 ? '0px' : '0px',
          border: '1px solid #E4E7EC',
        }}
      />
      <div
        style={{
          fontSize: '11px',
          width: '100%',
          color: '#475467',
          padding: '0px 0px 10px 0px',
        }}
      >
        To edit your preferences or resubscribe to any of the above, please
        click here
      </div>
      <div style={{ marginBottom: '20px' }}>
        <Button
          styles={{ borderRadius: '4px', width: '180px', fontSize: '12px' }}
          type="button"
          margin="0px 40px 0px 60px"
          onClick={handleClick}
        >
          Manage Subscriptions
        </Button>
      </div>
    </Flex>
  );
};

export default ThankyouPage;
