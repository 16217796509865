import { Flex } from '@rebass/grid';
import React, { useEffect, useState } from 'react';
import { Button, ModalBody, ModalCloseButton, ModalHeader, ModalV2 } from '../../../components';

const EditSummaryModal = ({ isOpen, close, userSummary, saveHandler }) => {

    const [summary, setSummary] = useState('');

    useEffect(() => {
        setSummary(userSummary);
    }, [])

    return (
        <ModalV2 width='45%' isOpen={isOpen} onRequestClose={close}>
            <ModalHeader>
                Edit Summary
                <ModalCloseButton onClick={close} />
            </ModalHeader>
            <ModalBody>
                <Flex flexDirection='column' alignItems='flex-start' justifyContent='space-evenly' >
                    <textarea 
                        style={{
                            borderRadius: '5px', 
                            backgroundColor: '#fff', 
                            color: '#49484a', 
                            width: '100%',
                            height: '100px',
                            resize: 'none',
                            overflow: 'auto',
                            padding: '5px 8px'
                        }} 
                        onChange={e => setSummary(e.target.value)}
                        value={summary}
                    />
                    <p style={{fontSize: '10px', textAlign: 'left', color: '#7b7b7b'}}>
                        Summary can be of any length, but the maximum number of characters that will be displayed on the article card is 120.
                    </p>
                    <Flex width='40%' margin='auto' justifyContent='space-evenly'>
                        <Button 
                            bgColor='#6351ed' 
                            color='#fff'
                            onClick={() => saveHandler(summary)}
                        >
                            Save
                        </Button>
                        <Button 
                            bgColor='#ececec' 
                            borderColor='#adadad' 
                            color='#6351ed'
                            onClick={close}
                        >
                            Cancel
                        </Button>
                    </Flex>
                </Flex>
            </ModalBody>
        </ModalV2>
    );
};

export default EditSummaryModal;