import { styled } from '@mui/material';
import ReactSkeleton from 'react-loading-skeleton';

export const Card = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '0.75rem',
  padding: '0.75rem',
  border: '1px solid #BBACFF',
  backgroundColor: '#FFFFFF',
  borderRadius: '0.25rem',
}));

export const CardHeader = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  gap: '1rem',
  justifyContent: 'space-between',

  '.info-icon': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    svg: {
      color: '#816EF8',
      fontSize: '1.5rem',
      cursor: 'pointer',
    },
  },
}));

export const CardHeading = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '0.4rem',

  svg: {
    color: '#816EF8',
    fontSize: '1.5rem',
    cursor: 'default',
  },

  h4: {
    fontSize: '0.875rem',
    fontWeight: '500',
    color: '#101828',
    margin: 0,
  },
}));

export const Pills = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  flexWrap: 'wrap',
  gap: '0.5rem',
}));

export const Pill = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '0.25rem',
  padding: '0.25rem 0.5rem',
  background: '#ECF2FC',
  border: '1px solid #B6CFF3',
  borderRadius: '1.5rem',
  fontSize: '0.75rem',
  fontWeight: '500',
  color: '#0B5CD7',
  margin: 0,
}));

export const PillSkeleton = styled(ReactSkeleton)(() => ({
  borderRadius: '0.5rem',
}));
