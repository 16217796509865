import React from 'react';
import ReactSkeleton from 'react-loading-skeleton';
import { ContactListContainer, TableHeader } from './styles';
import TableSkeleton from 'src/componentsV2/Table/Skeleton';

function Skeleton() {
  return (
    <ContactListContainer>
      <TableHeader>
        <h2>
          <ReactSkeleton height={16} width={100} />
        </h2>
      </TableHeader>
      <TableSkeleton />
    </ContactListContainer>
  );
}

export default Skeleton;
