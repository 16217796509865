export const BAR_GRAPH_COLORS = [
  '#244049',
  '#36616E',
  '#488192',
  '#5AA1B7',
  '#7BB4C5',
  '#9CC7D4',
  '#BDD9E2',
  '#CDE3E9',
];

export const DONUT_COLORS = [
  '#0C333D',
  '#2B5C69',
  '#39707E',
  '#6092A1',
  '#9CC1CC',
  '#CDE3E9',
];

export const LIMIT = 10;
