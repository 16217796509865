import { Flex } from '@rebass/grid';
import { isEmpty } from 'lodash';
import React from 'react'
import { platforms } from '../../utils';
import { Modal, ModalBody, ModalCloseButton, ModalHeader } from '../Modal';
import { connect } from 'react-redux';
import moment from 'moment';
import { DripIconButton } from '../Form';

const RecentDetailsModal = ({
  open,
  setOpen,
  topic,
  articles,
  socialData,
  handleClick,
  setSelectedItem,
  isPremiumAdvisor
}) => {
  // console.log('ARTICLES RECENT DETAILS MODAL - - --  ', articles);
  const handleModalClose = () => {
    setSelectedItem({});
    setOpen(false);
  }

  const getDestination = (item) => {
    if(item.type === 'contactlist') {
      return item.name;
    } else if(item.type === 'TWITTER_FEED') {
      return 'News Feed';
    } else if(item.type === 'FACEBOOK_Pages' && item.id.length > 1) {
      let page = isEmpty(socialData['fb'])  ? 'Connect Facebook' : !isEmpty(socialData['fb'][0].page) && socialData['fb'][0].page[item.id] ? socialData['fb'][0].page[item.id].details.name : '-';
      return page;
    } else if(item.type === 'LINKEDIN_Pages' && item.id.length > 1) {
      let page = isEmpty(socialData['ln']) ? 'Connect Linkedin' : !isEmpty(socialData['ln'][0].page) && socialData['ln'][0].page[item.id] ?  socialData['ln'][0].page[item.id].details.name : '-';
      return page; 
    } else if(item.type === 'LINKEDIN_Pages' && item.id.length === 1) {
      return 'News Feed';
    } else if(item.type === 'FACEBOOK_Pages' && item.id.length === 1) {
      return 'News Feed';
    }
  }

  const getPostEngagement = (item) => {
    let networkDetails = {};
    networkDetails.engagement = 0;
    Object.keys(item).map(ele => {
      if(ele!=='reach')
        networkDetails.engagement+= item[ele];
    });

    return networkDetails.engagement;
  }

  const getLeadReferralStatus = (destination) => {
    if(destination) {
      if(isEmpty(destination.lead_capture) && isEmpty(destination.referral)) {
        return 'None';
      } else if(destination.lead_capture && destination.lead_capture === "false" && destination.referral && destination.referral === "false") {
        return 'None';
      } else if(destination.lead_capture === "true") {
        return 'Leads';
      } else if(destination.referral === "true") {
        return 'Referrals';
      }
    }
  }

  const getEmailSentAndFailedStatus = (item) => {
 
    if(['fb', 'tw', 'ln'].includes(item?.destination?.network)) {
      return 'N/A';
    }
    
    if(item.destination.network === 'em') {
      const totalFailedMails = item?.destination?.ids?.[0]?.total_failed_emails ?? '-';
      const totalSuccessdMails = item?.destination?.ids?.[0]?.total_success_emails ?? '-';
      return totalSuccessdMails + '/' + totalFailedMails;
    }
  }

  return (
    <Modal size="medium75" isOpen={open} onRequestClose={handleModalClose}>
      <ModalHeader>
        <div
          style={{
            fontSize: '18px',
            color: '#030303',
            fontWeight: 500,
            padding: '0px 86px',
          }}
        >
          {topic}
        </div>
        <ModalCloseButton onClick={handleModalClose} />
      </ModalHeader>
      <ModalBody>
        <Flex
          width="95%"
          justifyContent="space-evenly"
          style={{ margin: '0px 12.3px 0px 15px' }}
        >
          <Flex
            width={!isPremiumAdvisor ? '10%' : '15%'}
            justifyContent="center" 
            fontSize="12px"
            color="#6351ed"
          >
            Platforms
          </Flex>
          <Flex
            width={!isPremiumAdvisor ? '10%' : '18%'}
            justifyContent="center"
            fontSize="12px"
            color="#6351ed"
            pl="8px"
          >
            Destination
          </Flex>
          {/*<Flex
            width='10%'
            justifyContent="center"
            fontSize="12px"
            color="#6351ed"
          >
            Reach
          </Flex>
          <Flex
            width='20%'
            justifyContent="center"
            fontSize="12px"
            color="#6351ed"
          >
            Engagement
          </Flex>*/}
          {!isPremiumAdvisor && (
            <Flex
              width='17%'
              justifyContent="center"
              fontSize="12px"
              color="#6351ed"
            >
              Compliance Review
            </Flex>
          )}
          <Flex
            width={!isPremiumAdvisor ? '18%' : '20%'}
            justifyContent="center"
            fontSize="12px"
            color="#6351ed"
          >
            Lead/Referral Capure?
          </Flex>
          <Flex
            width={!isPremiumAdvisor ? '18%' : '20%'}
            justifyContent="center"
            fontSize="12px"
            color="#6351ed"
          >
            Emails Sent / Failed
          </Flex>
          <Flex
            width={!isPremiumAdvisor ? '24%' : '31%'}
            justifyContent="center"
            fontSize="12px"
            color="#6351ed"
            pr={!isPremiumAdvisor ?  '22px' : '33px'}
          >
            Published Date/Time
          </Flex>
        </Flex>
        <Flex flexDirection='column' style={{maxHeight: '300px', overflowX: 'hidden', overflowY: 'auto'}}>
        {!isEmpty(articles) &&
            articles.map((item, index, arr) => (
              <Flex width="100%" key={index}>
                <Flex
                  width="95%"
                  justifyContent="space-evenly"
                  style={{
                    backgroundColor: '#fff',
                    border: '1px solid #ececec',
                    margin: '7px 12.3px 7px 20px',
                    height: '40.4px',
                    padding: '7px 12.3px 7px 0',
                  }}
                >
                  <Flex
                    width={!isPremiumAdvisor ? '11%' : '16%'}
                    alignItems="center"
                    justifyContent="center"
                    fontSize="12px"
                    color="#030303"
                    style={{ fontWeight: 600, paddingRight: '13px' }}
                  >
                    {platforms[item.destination.network]}
                  </Flex>
                  <Flex
                    width={!isPremiumAdvisor ? '10%' : '18%'}
                    alignItems="center"
                    justifyContent="center"
                    fontSize="12px"
                    color="#030303"
                  >
                    {getDestination(item.destination.ids[0])}
                  </Flex>
                 {/* <Flex
                    width='10%'
                    alignItems="center"
                    justifyContent="center"
                    fontSize="12px"
                    color="#030303"
                    pl="13px"
                  >
                    {item.engagement && item.engagement.network ? item.engagement.network.reach : '-'}
                  </Flex> 
                  <Flex
                    width='20%'
                    alignItems="center"
                    justifyContent="center"
                    fontSize="12px"
                    color="#030303"
                    pl="18px"
                  >
                    {getPostEngagement(item.engagement.network)}
                  </Flex>*/}
                  {!isPremiumAdvisor && (
                    <Flex
                      width='17%'
                      alignItems="center"
                      justifyContent="center"
                      fontSize="12px"
                      color="#030303"
                      pl={isPremiumAdvisor ? "18px" : ''}
                  >
                      {item.is_complianced ? 'Yes' : 'N/A'}
                    </Flex>
                  )}
                  <Flex
                    width={!isPremiumAdvisor ? '18%' : '20%'}
                    alignItems="center"
                    justifyContent="center"
                    fontSize="12px"
                    color="#030303"
                    pl={isPremiumAdvisor ? "18px" : ''}
                 >
                    {getLeadReferralStatus(item.destination)}
                  </Flex>
                  <Flex
                    width={!isPremiumAdvisor ? '18%' : '20%'}
                    alignItems="center"
                    justifyContent="center"
                    fontSize="12px"
                    color="#030303"
                    pl={isPremiumAdvisor ? "18px" : '10px'}
                  >
                    {getEmailSentAndFailedStatus(item)}
                  </Flex>
                  <Flex
                    width={!isPremiumAdvisor ? '23%' : '30%'}
                    alignItems="center"
                    justifyContent={!isPremiumAdvisor ? 'flex-end' : "center"}
                    fontSize="12px"
                    color="#030303"
                    pl={isPremiumAdvisor ? "23px" : ''}
                  >
                    {moment(item.date.published).format('Do MMMM YYYY, h:mm a')}
                  </Flex>
                </Flex>
                <Flex width="5%" alignItems="center">
                <DripIconButton
                    icon="information"
                    styles={{color: '#49484a'}}
                    onClick={() => handleClick(item)}
                  />
                </Flex>
              </Flex>
            ))}
          </Flex>  
      </ModalBody>
    </Modal>
  );
}

const mapStateToProps = (state) => ({
  socialData: state.socialNetwork.data
})

export default connect(mapStateToProps, null)(RecentDetailsModal);
