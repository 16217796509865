import { Flex } from '@rebass/grid';
import { isEmpty } from 'lodash';
import React from 'react';
import  ReactSelect, { components } from 'react-select';
import { LoaderWrapper } from '../../components';

const getSelectStyles = (border) => ({
  container: (base) => ({
    ...base,
    width: '100%',
  }),
  control: (base) => ({
    ...base,
    border: border,
  }),
  dropdownIndicator: (base) => ({
    ...base,
    color: '#848484',
  }),
});


const StepTwoComponent = ({
  file,
  hiddenFileInput,
  handleChange,
  csvHeaders,
  changeColumnName,
  emailColumnName,
  firstNameColumnName,
  lastNameColumnName,
  suffixColumnName,
  setEmailColumnName,
  setFirstNameColumnName,
  setLastNameColumnName,
  setSuffixColumnName,
  previewData,
  csvData,
  csvLoading,
  tagsColumnName,
  setTagsColumnName
}) => {
  const options = csvHeaders.map((item, index) => {
    return {
      label: item,
      value: item,
    };
  });

  return (
    <>
      <Flex
        style={{
          display: 'flex',
          justifyContent: 'flex-start',
          width: '83%',
          padding: '40px 5px 0px 0px',
          color: '#101010',
          fontSize: '22px',
          margin: '0px auto',
        }}
      >
        Match Column Titles
      </Flex>
      <LoaderWrapper isLoading={csvLoading}>
        <Flex
          display="flex"
          justifyContent="flex-start"
          flexDirection="row"
          width="83%"
          margin="0px auto"
          padding="5px 0px"
        >
          <strong
            style={{
              color: '#101010',
              fontSize: '16px',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            File Name:
          </strong>
          <span
            style={{ padding: '0px 8px', color: '#101010', fontSize: '15px' }}
          >
            {file && file[0]?.name}
          </span>
          <ul
            style={{ margin: 'auto 0px', color: '#101010', padding: '0px 20px' }}
          >
            <li>
              <span
                style={{ color: '#0B5CD7', fontSize: '16px', cursor: 'pointer' }}
                onClick={(event) => {
                  console.log('fire');
                  hiddenFileInput.current.click();
                }}
              >
                Change File
              </span>
            </li>
          </ul>
          <input
            type="file"
            style={{ visibility: 'hidden', height: '0px', width: '0px' }}
            ref={hiddenFileInput}
            multiple={false}
            onChange={handleChange}
            accept=".csv, .xlsx"
          />
        </Flex>
      </LoaderWrapper>
      <hr
        style={{
          width: '83%',
          border: '1px solid #E4E7EC',
        }}
      />
      <Flex
        display="flex"
        justifyContent="flex-start"
        width="83%"
        color="#101010"
        fontSize="16px"
        margin="0px auto"
        padding="5px 0px"
      >
        <strong>
          Select the column name from your file from the dropdown that you would
          like to match to our corresponding column
        </strong>
      </Flex>
      <Flex
        width="84%"
        style={{
          display: 'flex',
          border: '1px solid #E4E7EC',
          borderRadius: '8px',
          padding: '10px 5px',
          justifyContent: 'center',
          margin: '15px auto',
          flexDirection: 'column',
        }}
        height="auto"
      >
        <Flex
          style={{ display: 'flex', justifyContent: 'space-evenly' }}
          width="100%"
          height="auto"
        >
          <Flex width="21%">
            <ReactSelect
              onChange={(newValue) =>
                changeColumnName(setEmailColumnName, newValue, 'email')
              }
              options={options}
              placeholder="Select"
              styles={getSelectStyles(emailColumnName ? '1px solid #8493A6' : '1px solid #FF0000')}
              value={emailColumnName}
              isClearable
            />
          </Flex>
          <Flex width="15%">
            <ReactSelect
              onChange={(newValue) =>
                changeColumnName(setFirstNameColumnName, newValue, 'firstName')
              }
              options={options}
              placeholder="Select"
              styles={getSelectStyles('1px solid #8493A6')}
              value={firstNameColumnName}
              isClearable
            />
          </Flex>
          <Flex width="15%">
            <ReactSelect
              onChange={(newValue) =>
                changeColumnName(setLastNameColumnName, newValue, 'lastName')
              }
              options={options}
              placeholder="Select"
              styles={getSelectStyles('1px solid #8493A6')}
              value={lastNameColumnName}
              isClearable
            />
          </Flex>
          <Flex width="15%">
            <ReactSelect
              onChange={(newValue) =>
                changeColumnName(setSuffixColumnName, newValue, 'suffix')
              }
              options={options}
              placeholder="Select"
              styles={getSelectStyles('1px solid #8493A6')}
              value={suffixColumnName}
              isClearable
            />
          </Flex>
          <Flex width="30%">
          <ReactSelect
            onChange={(newValue) =>
                changeColumnName(setTagsColumnName, newValue, 'tags')
            }
            options={options}
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
            placeholder="Select"
            value={tagsColumnName}
            styles={getSelectStyles('1px solid #8493A6')}
            isMulti
          />
          </Flex>    
        </Flex>
        <Flex
          width="100%"
          height="auto"
          display="flex"
          justifyContent="space-evenly"
          margin="10px auto"
          backgroundColor="rgb(245, 245, 245)"
        >
          <Flex
            width="21%"
            style={{
              height: '30px',
              color: ' #7b7b7b',
              fontSize: '14px',
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
            }}
          >
            Email Address*
            {!isEmpty(previewData[0].email) ? (
              <Flex style={{ color: 'green', padding: '5px 10px' }}>
                <img alt=" " src="/assets/images/circle-check-icon.svg" />
              </Flex>
            ) : (
              <Flex style={{ color: 'red', padding: '5px 10px' }}>
                <img alt=" " src="/assets/images/circle-warning.svg" />
              </Flex>
            )}
          </Flex>
          <Flex
            width="15%"
            style={{
              height: '30px',
              color: ' #7b7b7b',
              fontSize: '14px',
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
            }}
          >
            First Name
          </Flex>
          <Flex
            width="15%"
            style={{
              height: '30px',
              color: ' #7b7b7b',
              fontSize: '14px',
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
            }}
          >
            Last Name
          </Flex>
          <Flex
            width="15%"
            style={{
              height: '30px',
              color: ' #7b7b7b',
              fontSize: '14px',
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
            }}
          >
            Suffix
          </Flex>
          <Flex
            width="30%"
            style={{
              height: '30px',
              color: ' #7b7b7b',
              fontSize: '14px',
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
            }}
          >
            Tags
          </Flex>
        </Flex>
        {!isEmpty(previewData) &&
          previewData.map((item, index) => (
            <Flex
              width="100%"
              height="auto"
              display="flex"
              justifyContent="space-evenly"
              margin="10px auto"
              backgroundColor="#fff"
            >
              <Flex
                width="21%"
                title={item.email && item.email.length > 20 ? item.email : ''}
                style={{
                  height: '30px',
                  color: '#101010',
                  fontSize: '14px',
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                }}
              >
                {item.email ? item.email.length > 20 ? item.email.substring(0, 20) + '...' : item.email : '-'}
              </Flex>
              <Flex
                width="15%"
                title={item.first_name && item.first_name.length > 16 ? item.first_name : ''}
                style={{
                  height: '30px',
                  color: '#101010',
                  fontSize: '14px',
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                }}
              >
                {item.first_name ? item.first_name.length > 16 ? item.first_name.substring(0, 16) + '...' : item.first_name : '-'}
              </Flex>
              <Flex
                width="15%"
                title={item.last_name && item.last_name.length > 16 ? item.last_name : ''}
                style={{
                  height: '30px',
                  color: '#101010',
                  fontSize: '14px',
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                }}
              >
                {item.last_name ? item.last_name.length > 16 ? item.last_name.substring(0, 16) + '...' : item.last_name : '-'}
              </Flex>
              <Flex
                width="15%"
                title={item.suffix && item.suffix.length > 10 ? item.suffix : ''}
                style={{
                  height: '30px',
                  color: '#101010',
                  fontSize: '14px',
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                }}
              >
                {item.suffix ? item.suffix.length > 10 ? item.suffix.substring(0, 10) + '...' : item.suffix : '-'}
              </Flex>
              <Flex
                width="30%"
                title={item.tags && item.tags.length > 45 ? item.tags : ''}
                style={{
                  height: '30px',
                  color: '#101010',
                  fontSize: '14px',
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                }}
              >
                {item.tags ? item.tags.length > 45 ? item.tags.substring(0, 45) + '...' : item.tags : '-'}
              </Flex>
            </Flex>
          ))}
      </Flex>
      {csvData && (
        <Flex
          color="#101010"
          fontSize="14px"
          justifyContent="flex-end"
          width="100%"
          margin="8px auto"
          padding="0px 114px"
        >
          Showing preview of {(emailColumnName || firstNameColumnName || lastNameColumnName || suffixColumnName || tagsColumnName) ? csvData.length > 5 ? 5 : csvData.length : 0}/{csvData.length} contacts uploaded
        </Flex>
      )}
    </>
  );
};

export default StepTwoComponent;
