import { get } from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import BeeFreeEmailEditor from '../../../components/RichTextEditor/BeeFreeEmailEditor';
import { actions as campaignActions } from '../../../redux/modules/campaign.module';
import { getRoleType } from '../../../redux/selector';

const BeeFreeEditorWrapper = ({
  userData,
  campaignSelectedArticles,
  themeNames,
  setTemplateData,
  setTemplateBase,
  setTemplateCascade,
  campaignId,
  campaignSetState,
  savedTemplate,
  defaultTemplates,
  selectedIndex,
  roleType,
  owner,
  bookmarkArticles,
  recommendedArticles,
  myContentArticles,
  recommendedFetchedData,
  webinarData,
  setObjectivesTabData,
  fromDashboard,
  forAutomatedCampaigns,
  selectedBlock,
  campaignRequest,
  automatedCampaignsData,
  selectedBlockId,
  automatedFlowFromDashboard,
  targetInfo,
  reviewType,
  automatedCampaignId,
  seriesId,
  template_id
}) => {
  const history = useHistory();

  const handleNextClick = () => {
    campaignSetState({ selectedIndex: selectedIndex + 1 });
    history.push('campaignV2?type=create');
  };

  const handleBackButtonClick = () => {
    if (!forAutomatedCampaigns || get(targetInfo, 'is_cascaded', 'false') !== 'true') {
      if (get(setObjectivesTabData, 'isCascaded', 'false') === 'true') {
        campaignSetState({ selectedIndex: 0 });
        history.push('campaignV2?type=create');
      } else {
        let baseTemplate;
        if (
          setTemplateData === 'no-content' ||
          setTemplateData === 'one-column' ||
          setTemplateData === 'two-columns'
        )
          baseTemplate = setTemplateData;
        else
          baseTemplate = defaultTemplates
            .filter((temp) => temp.name !== 'no_content' && temp.name !== 'content')
            .find((temp) => temp.name === setTemplateData).original_template;
        let newIndex = selectedIndex - 1;
        // if(setTemplateData === 'no-content' || baseTemplate === 'no-content')
        //     newIndex = selectedIndex - 2;
        campaignSetState({ selectedIndex: newIndex });
      }

    }
    if(automatedFlowFromDashboard) {
      history.push('/campaignV2');
    }
    if (forAutomatedCampaigns) {
      const newIndex = get(targetInfo, 'is_cascaded', 'false') === 'true' ? selectedIndex - 2 : selectedIndex - 1;
      // const newIndex = get(targetInfo, 'is_cascadedd', 'true') === 'true' ? selectedIndex - 2 : selectedIndex - 1;
      campaignSetState({ selectedIndex: newIndex });
      return history.push('create-email');
    }
    history.push('campaignV2?type=create');
  };

  const endCreateFlow = () => {
    console.log('CREATE FLOW ENDED - -- - - - ');
  };

  return (
    <BeeFreeEmailEditor
      userData={userData}
      selectedArticles={campaignSelectedArticles}
      themeNames={themeNames}
      handleBack={handleBackButtonClick}
      handleNext={handleNextClick}
      setTemplateData={setTemplateData}
      setTemplateBase={setTemplateBase}
      setTemplateCascade={setTemplateCascade}
      campaignId={campaignId}
      campaignSetState={campaignSetState}
      campaignRequest={campaignRequest}
      savedTemplate={savedTemplate}
      defaultTemplates={defaultTemplates}
      endCreateFlow={endCreateFlow}
      roleType={roleType}
      owner={owner}
      bookmarkArticles={bookmarkArticles || []}
      recommendedArticles={recommendedArticles || []}
      myContentArticles={myContentArticles || []}
      recommendedFetchedData={recommendedFetchedData}
      webinarData={webinarData}
      setObjectivesTabData={setObjectivesTabData}
      fromDashboard={fromDashboard}
      forAutomatedCampaigns={forAutomatedCampaigns}
      selectedBlock={selectedBlock}
      automatedCampaignsData={automatedCampaignsData}
      selectedBlockId={selectedBlockId}
      automatedFlowFromDashboard={automatedFlowFromDashboard}
      selectedIndex={selectedIndex}
      targetInfo={targetInfo}
      reviewType={reviewType}
      automatedCampaignId={automatedCampaignId}
      seriesId={seriesId}
      template_id={template_id}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    userData: state.user.data,
    themeNames: state.theme.themeNames,
    campaignSelectedArticles: state.campaign.selectedArticles,
    setTemplateData: state.campaign.setTemplate,
    template_id: state?.campaign?.template_id,
    setTemplateBase: state.campaign.setBaseTemplate,
    setTemplateCascade: state.campaign.setIsCascade,
    campaignId: state.campaign.campaignId,
    savedTemplate: state.campaign.savedTemplate,
    defaultTemplates: state.campaign.defaultTemplates,
    selectedIndex: state.campaign.selectedIndex,
    roleType: getRoleType(state),
    owner: state.campaign.owner,
    bookmarkArticles: state.campaign.bookmarkArticles,
    recommendedArticles: state.campaign.recommendedArticles,
    myContentArticles: state.campaign.myContentArticles,
    recommendedFetchedData: state.campaign.recommendedFetchedData,
    webinarData: get(state.campaign, 'setObjectives.webinar', ''),
    setObjectivesTabData: state.campaign.setObjectives,
    fromDashboard: get(state, 'campaign.fromDashboard', false),
    forAutomatedCampaigns: get(state, 'campaign.forAutomatedCampaigns', false),
    selectedBlock: get(state, 'campaign.selectedBlock', {}),
    automatedCampaignsData: get(state, 'campaign.automatedCampaigns', {}),
    selectedBlockId: get(state, 'campaign.selectedBlockId', ''),
    automatedFlowFromDashboard: get(state, 'campaign.automatedFlowFromDashboard', false),
    targetInfo: get(state, 'campaign.automatedCampaigns.targetInfo', {}),
    reviewType: get(state, 'campaign.reviewType', ''),
    automatedCampaignId: get(
      state,
      'campaign.automatedCampaigns.campaignId',
      0
    ),
    seriesId: get(state, 'campaign.seriesId', '')
  };
};

const mapDispatchToProps = {
  campaignSetState: campaignActions.api.campaign.setstate,
  campaignRequest: campaignActions.api.campaign.request,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BeeFreeEditorWrapper);
