/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { Flex } from '@rebass/grid';
import React from 'react';
import {
  Button,
  LoaderWrapper,
  ModalBody,
  ModalCloseButton,
  ModalHeader,
  ModalV2,
} from '../../../components';
import { useState } from 'react';
import { useRef } from 'react';
import { getAutomatedBlocksById } from '../../../services/automated-campaigns';
import { useEffect } from 'react';
import { isEmpty } from 'lodash';
import { TemplateContainer } from '../../DripCampaignContainerV2/Preview&ViewInfoModal/Modal.styles';
import ReactSelect from 'react-select';
import { getMediaImage } from '../../../utils';
import { Link } from 'react-router-dom';

const getSelectStyles = () => ({
  container: (base) => ({
    ...base,
    width: '60%',
  }),
  control: (base) => ({
    ...base,
    height: '40px',
    minHeight: '40px',
    border: '1px solid #8493A6',
  }),
  dropdownIndicator: (base) => ({
    ...base,
    paddingTop: 0,
    paddingBottom: 0,
    color: '#848484',
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? '#192E49' : 'inherit',
    '&:hover': {
      backgroundColor: state.isSelected ? '#192E49' : 'rgb(222, 235, 255)',
    },
  }),
});

const AutomatedCampaignSocialPreviewModal = ({
  isOpen,
  handleClose,
  block_id,
  campaign_id,
  subject,
  title,
  is_facebook,
  is_linkedin,
  is_twitter
}) => {
  const [blockData, setBlockData] = useState({});
  const [loading, setLoading] = useState(false);
  const [socialMediaOptions, setSocialMediaOptions] = useState([]);
  const [values, setValues] = useState([]);

  useEffect(() => {
    const SOCIAL_MEDIA_PLATFORMS = []
    if(is_facebook) {
      SOCIAL_MEDIA_PLATFORMS.push({
        label: 'Facebook',
        value: 'fb',
        icon: (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z"
              stroke="#667085"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M15.75 8.25002H14.25C13.9542 8.24878 13.6611 8.30613 13.3875 8.41876C13.114 8.5314 12.8654 8.69708 12.6563 8.90626C12.4471 9.11544 12.2814 9.36397 12.1688 9.63752C12.0561 9.91106 11.9988 10.2042 12 10.5V21"
              stroke="#667085"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M9 13.5H15"
              stroke="#667085"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        ),
      })
    }

    if(is_linkedin) {
      SOCIAL_MEDIA_PLATFORMS.push(
        {
          label: 'LinkedIn',
          value: 'ln',
          icon: (
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M19.875 3.375H4.125C3.71079 3.375 3.375 3.71079 3.375 4.125V19.875C3.375 20.2892 3.71079 20.625 4.125 20.625H19.875C20.2892 20.625 20.625 20.2892 20.625 19.875V4.125C20.625 3.71079 20.2892 3.375 19.875 3.375Z"
                stroke="#667085"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M11.25 10.5V16.5"
                stroke="#667085"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M8.25 10.5V16.5"
                stroke="#667085"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M11.25 13.125C11.25 12.4288 11.5266 11.7611 12.0188 11.2688C12.5111 10.7766 13.1788 10.5 13.875 10.5C14.5712 10.5 15.2389 10.7766 15.7312 11.2688C16.2234 11.7611 16.5 12.4288 16.5 13.125V16.5"
                stroke="#667085"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M8.25 8.625C8.87132 8.625 9.375 8.12132 9.375 7.5C9.375 6.87868 8.87132 6.375 8.25 6.375C7.62868 6.375 7.125 6.87868 7.125 7.5C7.125 8.12132 7.62868 8.625 8.25 8.625Z"
                fill="#667085"
              />
            </svg>
          )
        }
      )
    }
        
    if(is_twitter) {
      SOCIAL_MEDIA_PLATFORMS.push(
        {
          label: 'Twitter',
          value: 'tw',
          icon: (
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.0005 8.25034C12.0005 6.18784 13.7349 4.47222 15.7974 4.50034C16.5197 4.50868 17.2242 4.72545 17.8262 5.1246C18.4283 5.52375 18.9022 6.08828 19.1911 6.75034H22.5005L19.4724 9.77847C19.277 12.8203 17.9305 15.6734 15.7066 17.758C13.4828 19.8425 10.5486 21.0018 7.50051 21.0003C4.50051 21.0003 3.75051 19.8753 3.75051 19.8753C3.75051 19.8753 6.75051 18.7503 8.25051 16.5003C8.25051 16.5003 2.25051 13.5003 3.75051 5.25034C3.75051 5.25034 7.50051 9.00034 12.0005 9.75034V8.25034Z"
                stroke="#667085"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          ),
        },
      )
    }   
    console.log(SOCIAL_MEDIA_PLATFORMS[0]);
    setValues(SOCIAL_MEDIA_PLATFORMS);
    setSocialMediaOptions(SOCIAL_MEDIA_PLATFORMS[0]);
  }, []);

  const getBlockById = async () => {
    try {
      setLoading(true);
      const { data } = await getAutomatedBlocksById(campaign_id, block_id);
      setBlockData(data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(`Error Fetching Block for the id ${block_id} --> `, error);
    }
  };

  useEffect(() => {
    if (!isEmpty(subject) && !isEmpty(title)) {
      setBlockData({
        subject,
        title,
      });
    } else {
      getBlockById();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getNewOptionValue = (value) =>
    value ? value : { label: '', value: '' };

console.log(" block data--->", blockData);
  return (
    <ModalV2
      isOpen={isOpen}
      onRequestClose={handleClose}
      width="45%"
      padding="22px"
      bgColor="#FFFFFF"
    >
      <ModalHeader
        justifyContent={'center'}
        style={{ padding: '10px 10px 10px 0px' }}
      >
        <Flex
          justifyContent="left"
          style={{ color: '#000000', fontSize: '18px' }}
        >
          Social Media Preview
        </Flex>
        <ModalCloseButton onClick={handleClose} />
      </ModalHeader>
      <ModalBody style={{ border: '1px solid #e4e7ec', borderRadius: '4px' }}>
        <LoaderWrapper isLoading={loading}>
          {blockData?.media_urls ? (
            <>
              <Flex
                width="100%"
                style={{
                  position: 'relative',
                  display: 'flex',
                  justifyContent: 'left',
                  flexDirection: 'column',
                }}
              >
                <Flex disply="flex" width="63%">
                  <ReactSelect
                    onChange={(newValue) => {
                      setSocialMediaOptions(newValue);
                    }}
                    options={values}
                    getOptionLabel={(e) => (
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'left',
                        }}
                      >
                        {e.icon}
                        <span style={{ marginLeft: '10px' }}>{e.label}</span>
                      </div>
                    )}
                    placeholder=""
                    styles={getSelectStyles()}
                    value={socialMediaOptions}
                  />
                </Flex>
                <Flex
                  width="100%"
                  padding="10px 20px 10px 0px"
                  display="flex"
                  flexDirection="row"
                >
                  <Flex padding="10px 10px 10px 0px">
                    <img
                      alt=""
                      src="/assets/images/Campaign/grey-circle-icon.svg"
                    />
                  </Flex>
                  <Flex display="flex" flexDirection="column" padding="15px">
                    <Flex marginBottom="10px">
                      <img
                        alt=""
                        src="/assets/images/Campaign/grey-line-icon.svg"
                      />
                    </Flex>
                    <Flex>
                      <img
                        alt=""
                        src="/assets/images/Campaign/grey-rec-icon.svg"
                      />
                    </Flex>
                  </Flex>
                </Flex>
                <TemplateContainer padding="15px" style={{ height: '30vh' }}>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={blockData?.articles?.[0]?.link}
                  >
                    <img
                      style={{ height: '100%'}}
                      alt=""
                      src={
                        !isEmpty(blockData?.media_urls)
                          ? getMediaImage(blockData?.media_urls)
                          : 'assets/images/Campaign/Default-image.png'
                      }
                    />
                  </a>
                </TemplateContainer>
                <Flex
                  alignItems="flex-start"
                  style={{
                    background: 'white',
                    padding: '10px 5px',
                    color: '#000000',
                    fontSize: '16px',
                    fontWeight: 500,
                    display: 'flex',
                    width: '100%',
                    alignItems: 'flex-start',
                  }}
                >
                  {blockData?.articles?.[0]?.title}
                </Flex>
                {/* <Flex
                  style={{
                    background: 'white',
                    padding: '10px 5px',
                    color: '#485467',
                    fontSize: '14px',
                    fontWeight: 500,
                    width: '100%',
                    display: 'inline-block',
                    textAlign: 'left',
                  }}
                >
                  {blockData?.articles?.[0]?.description}
                </Flex> */}
                <Flex
                  style={{
                    background: 'white',
                    padding: '10px 5px',
                    color: '#485467',
                    fontSize: '14px',
                    fontWeight: 500,
                    width: '100%',
                    marginBottom: '15px',
                    display: 'flex',
                    alignItems: 'flex-start',
                  }}
                >
                  {socialMediaOptions?.[0]?.label === 'Facebook'
                    ? blockData?.override_text?.fb
                    : socialMediaOptions?.label === 'Facebook'
                    ? blockData?.override_text?.fb
                    : socialMediaOptions?.label === 'LinkedIn'
                    ? blockData?.override_text?.ln
                    : null}
                </Flex>
                <Flex
                  display="flex"
                  flexDirection="column"
                  padding="0px 15px 10px 3px"
                >
                  <Flex marginBottom="10px">
                    <img
                      alt=""
                      src="/assets/images/Campaign/grey-line-icon.svg"
                    />
                  </Flex>
                  <Flex>
                    <img
                      alt=""
                      src="/assets/images/Campaign/grey-rec-icon.svg"
                    />
                  </Flex>
                </Flex>
              </Flex>
            </>
          ) : (
            <Flex
              justifyContent={'center'}
              alignItems={'center'}
              style={{ height: '300px' }}
            >
              Please create one to preview.
            </Flex>
          )}
        </LoaderWrapper>
      </ModalBody>
    </ModalV2>
  );
};

export default AutomatedCampaignSocialPreviewModal;
