import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Flex } from '@rebass/grid';
import React from 'react';
import {
  Button,
  ButtonNoBorderBackground,
  LoaderWrapper,
} from '../../../components';
import { DripFooterSection } from '../CreateCampaign/CreateCampaign.styles';

const CampaignFooterButtons = ({
  enableNext,
  nextHandler,
  backHandler,
  saveAndCloseHandler,
  loading,
  isPublished,
  enablePublish,
  publishHandler,
  padding,
  isLastStep = false,
  hideBackButton = false,
  isCompliance,
  enableBack,
  complianceStyle,
}) => {
  return isCompliance ? (
    <DripFooterSection style={complianceStyle}>
      <LoaderWrapper isLoading={loading}>
        <Flex>
          {!hideBackButton && (
            <ButtonNoBorderBackground
              color="#000000"
              onClick={backHandler}
              disabled={!enableBack}
              id="btn_compliance_back"
              style={{ fontSize: '10px' }}
            >
              <FontAwesomeIcon icon={faChevronLeft} />
              &nbsp; Back &nbsp; &nbsp;
            </ButtonNoBorderBackground>
          )}
          {!isLastStep && (
            <Button
              styles={{ borderRadius: '4px' }}
              color="#fff"
              bgColor="#816EF8"
              margin="0px 10px"
              onClick={nextHandler}
              disabled={!enableNext}
              id="btn_compliance_next"
              fontSize="10px"
            >
              Next
            </Button>
          )}
        </Flex>
      </LoaderWrapper>
    </DripFooterSection>
  ) : (
    <DripFooterSection style={{ padding: padding }}>
      <LoaderWrapper isLoading={loading}>
        <Flex>
          {isPublished && (
            <Button
              styles={{ borderRadius: '4px' }}
              color="#fff"
              bgColor="#816EF8"
              margin="0px 10px"
              disabled={!enablePublish}
              onClick={publishHandler}
            >
              Publish All
            </Button>
          )}
          <Button
            styles={{ borderRadius: '4px' }}
            color="#fff"
            bgColor="#816EF8"
            margin="0px 10px"
            onClick={saveAndCloseHandler}
            disabled={!enableNext}
            id="btn_campaign_save_close"
          >
            Save & Close
          </Button>
        </Flex>
        <Flex>
          {!hideBackButton && (
            <ButtonNoBorderBackground color="#000000" onClick={backHandler}>
              <FontAwesomeIcon icon={faChevronLeft} />
              &nbsp; Back &nbsp; &nbsp;
            </ButtonNoBorderBackground>
          )}
          {!isLastStep && (
            <Button
              styles={{ borderRadius: '4px' }}
              color="#fff"
              bgColor="#816EF8"
              margin="0px 10px"
              onClick={nextHandler}
              disabled={!enableNext}
              id="btn_campaign_next"
            >
              {isPublished ? 'Publish' : 'Next'}
            </Button>
          )}
        </Flex>
      </LoaderWrapper>
    </DripFooterSection>
  );
};

export default CampaignFooterButtons;
