/* eslint-disable react-hooks/exhaustive-deps */
import OktaSignIn from '@okta/okta-signin-widget';
import React, { useEffect, useRef } from 'react';
import { oktaConfig } from '../../utils';
import '@okta/okta-signin-widget/dist/css/okta-sign-in.min.css';
import { LoginOktaWrapper } from './Login.styles';

const OktaLoginWidget = ({ onSuccess, onError }) => {
    const widgetRef = useRef();

    useEffect(() => {
        const currentRef = widgetRef.current;
        const widget = currentRef && new OktaSignIn(oktaConfig);
    
        widget
          .showSignInToGetTokens({
            el: widgetRef.current,
          })
          .then(onSuccess)
          .catch(onError);
    
        return () => {
          currentRef && widget.remove();
        };
      }, [oktaConfig, onSuccess, onError]);
    
    return (
      <LoginOktaWrapper>
        <div ref={widgetRef} />
      </LoginOktaWrapper>
    ) 
    
}

export default OktaLoginWidget;