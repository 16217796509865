import React from 'react';
import { Modal, ModalBody, ModalHeader, ModalCloseButton, Input, Button, ErrorContainer } from '../../../components';
import { Formik } from 'formik';
import { RedTailInputWrapper, RedTailResponsiveImg, RedTailSubTitle } from './RedTailModal.styles';
import { Flex } from '@rebass/grid';
import * as Yup from 'yup';

const redTailSchema = Yup.object().shape({
  username: Yup.string()
    .required('Please enter Username'),
  password: Yup.string().required('Please enter Password'),
});

const RedTailModal = ({open, handleClose, handleModalSubmit, modalError}) => {
  return (
    <Modal size='medium-small' isOpen={open} onRequestClose={handleClose}>
      <ModalHeader>
        <ModalCloseButton onClick={handleClose} />
      </ModalHeader>
      <ModalBody>
        <RedTailResponsiveImg src='/assets/images/Redtail.svg'/>
        <RedTailSubTitle>Sign in to your Redtail account to import contacts</RedTailSubTitle>
        <Formik
          validateOnChange={false}
          initialValues={{
            username: '',
            password: '',
          }}
          onSubmit={handleModalSubmit}
          validationSchema={redTailSchema}
        >
          {({
            setFieldValue,
            handleSubmit,
            handleChange,
            values,
            errors = {},
          }) => (
              <form>
                <Flex width={1} pl='22px' pt='20px' flexDirection='column' pr='22px'>
                  {modalError && (
                    <ErrorContainer align="center">
                      Invalid Username or Password
                    </ErrorContainer>
                  )}
                  <RedTailInputWrapper>
                    <Input
                      id='username'
                      name='username'
                      largeInput
                      bgColor='#fff'
                      placeholder='Username*'
                      onChange={handleChange}
                      value={values.username}
                      error={errors.username}
                      marginBottom='10px'
                    />
                    <Input
                      id='password'
                      largeInput
                      bgColor='#fff'
                      placeholder='Password*'
                      type='password'
                      name='password'
                      onChange={handleChange}
                      value={values.password}
                      error={errors.password}
                      marginBottom='10px'
                    />
                  </RedTailInputWrapper>
                  <Button type='submit' margin='5px 0px 0px 0px' bgColor='#6351ed' color='#eeeeee' onClick={handleSubmit}>Sign In</Button>
                </Flex>
              </form>
            )}
        </Formik>
      </ModalBody>
    </Modal>
  )
}

export default RedTailModal;
