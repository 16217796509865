import React from 'react';
import Button from 'src/componentsV2/Button';
import PeopleIcon from 'src/assets/icons/People';
import TrashCan from 'src/assets/icons/TrashCan';
import {
  Footer,
  List,
  ListItem,
  ListItemContent,
  PeopleIconContainer,
  IconContainer,
  Wrapper,
} from './styles';

function ContactList({ list, setList, setDisplayState }) {
  const handleDelete = (index) => {
    const items = [...(list ?? [])];
    items.splice(index, 1);
    setList(items);
  };

  return (
    <Wrapper>
      <List>
        {list?.map((contact, index) => (
          <ListItem key={index}>
            <PeopleIconContainer>
              <PeopleIcon />
            </PeopleIconContainer>
            <ListItemContent>
              <h5>
                {contact?.first_name ?? ''} {contact?.last_name ?? ''}
              </h5>
              <p>{contact?.email ?? ''}</p>
            </ListItemContent>
            <IconContainer onClick={() => handleDelete(index)}>
              <TrashCan />
            </IconContainer>
          </ListItem>
        ))}
      </List>
      <Footer>
        <Button fullWidth onClick={() => setDisplayState('CREATE_EMAIL_LIST')} disabled={!list || list?.length === 0}>
          Create Email List
        </Button>
      </Footer>
    </Wrapper>
  );
}

export default ContactList;
