import React from 'react';
import articleMock from '../../../assets/icons/articleMock.svg';
import { colors } from '../../../styles';

export const emailAndSocialData = {
  email: [
    {
      title: 'Unique Content',
      value: 53,
    },
    {
      title: 'Unique Opens',
      value: 33,
    },
    {
      title: 'Unique Clicks',
      value: 21,
    },
    {
      title: 'Unique Bounces',
      value: 19,
    },
    {
      title: 'Unique Forwards',
      value: 7,
    },
  ],
  social: [
    {
      title: 'Unique Content',
      value: 61,
    },
    {
      title: 'Unique Opens',
      value: 31,
    },
    {
      title: 'Unique Clicks',
      value: 21,
    },
    {
      title: 'Unique Bounces',
      value: 14,
    },
    {
      title: 'Unique Forwards',
      value: 6,
    },
  ],
};

export const campaignPerformanceSummary = {
  title: 'Campaign Performance',
  keys: [
    {
      title: 'Open Rate',
      color: '#FFA32C',
    },
    {
      title: 'Click Rate',
      color: '#7B73E4',
    },
  ],
};

export const recommendationData = {
  title: 'Recommendation',
  cardText: [
    {
      text: 'Your top-performing campaigns shared the following characteristics.',
      type: 'light',
    },
    {
      text: 'Put the data to use and construct your next campaign with these in mind.',
      type: 'dark',
    },
  ],
  cardDisplay: [
    {
      label: 'Most Popular Topic',
      content: 'Retirement',
      type: 'bubble',
    },
    {
      label: 'Most Popular Format',
      content: 'PDF',
      type: 'text',
    },
    {
      label: 'Best Time',
      content: '10:00AM',
      type: 'text',
    },
  ],
  buttonContent: {
    title: 'Start New Campaign',
    //action: console.log('Add action here')
  },
};

export const contactsRecommendationData = {
  title: 'Recommendation',
  cardText: [
    {
      text: 'You have 24 highly engaged contacts',
      type: 'light',
    },
    {
      text: 'Put the data to use and construct your next campaign with these in mind.',
      type: 'dark',
    },
  ],
};

export const articleData = {
  completed: [
    {
      title: 'Series Campaign 1',
      subTitle: 'First Campaign Name',
      articleLink: articleMock,
      contactsSent: 24,
      completed: true,
      engagement: [
        {
          title: 'Open Rate',
          key: 'openRate',
          value: 24,
          type: 'percent',
        },
        {
          title: 'Total Clicks',
          key: 'totalClicks',
          value: 240,
          type: 'number',
        },
        {
          title: 'Engagement',
          key: 'engagement',
          value: 27,
          type: 'percent',
        },
      ],
      socials: [
        {
          title: 'email',
          link: '',
        },
        {
          title: 'facebook',
          link: '',
        },
        {
          title: 'linkedin',
          link: '',
        },
        {
          title: 'twitter',
          link: '',
        },
      ],
    },
    {
      title: 'Series Campaign 2',
      subTitle: 'Second Campaign Name',
      articleLink: articleMock,
      contactsSent: 24,
      completed: true,
      engagement: [
        {
          title: 'Open Rate',
          key: 'openRate',
          value: 24,
          type: 'percent',
        },
        {
          title: 'Total Clicks',
          key: 'totalClicks',
          value: 240,
          type: 'number',
        },
        {
          title: 'Engagement',
          key: 'engagement',
          value: 27,
          type: 'percent',
        },
      ],
      socials: [
        {
          title: 'email',
          link: '',
        },
        {
          title: 'facebook',
          link: '',
        },
        {
          title: 'linkedin',
          link: '',
        },
        {
          title: 'twitter',
          link: '',
        },
      ],
    },
    {
      title: 'Series Campaign 3',
      subTitle: 'Third Campaign Name',
      articleLink: articleMock,
      contactsSent: 24,
      completed: true,
      engagement: [
        {
          title: 'Open Rate',
          key: 'openRate',
          value: 24,
          type: 'percent',
        },
        {
          title: 'Total Clicks',
          key: 'totalClicks',
          value: 240,
          type: 'number',
        },
        {
          title: 'Engagement',
          key: 'engagement',
          value: 27,
          type: 'percent',
        },
      ],
      socials: [
        {
          title: 'email',
          link: '',
        },
        {
          title: 'facebook',
          link: '',
        },
        {
          title: 'linkedin',
          link: '',
        },
        {
          title: 'twitter',
          link: '',
        },
      ],
    },
    {
      title: 'Series Campaign 4',
      subTitle: 'Test Campaign Name',
      articleLink: articleMock,
      contactsSent: 24,
      completed: true,
      engagement: [
        {
          title: 'Open Rate',
          key: 'openRate',
          value: 24,
          type: 'percent',
        },
        {
          title: 'Total Clicks',
          key: 'totalClicks',
          value: 240,
          type: 'number',
        },
        {
          title: 'Engagement',
          key: 'engagement',
          value: 27,
          type: 'percent',
        },
      ],
      socials: [
        {
          title: 'email',
          link: '',
        },
        {
          title: 'facebook',
          link: '',
        },
        {
          title: 'linkedin',
          link: '',
        },
        {
          title: 'twitter',
          link: '',
        },
      ],
    },
    {
      title: 'Series Campaign 5',
      subTitle: 'Test Campaign Name',
      articleLink: articleMock,
      contactsSent: 24,
      completed: true,
      engagement: [
        {
          title: 'Open Rate',
          key: 'openRate',
          value: 24,
          type: 'percent',
        },
        {
          title: 'Total Clicks',
          key: 'totalClicks',
          value: 240,
          type: 'number',
        },
        {
          title: 'Engagement',
          key: 'engagement',
          value: 27,
          type: 'percent',
        },
      ],
      socials: [
        {
          title: 'email',
          link: '',
        },
        {
          title: 'facebook',
          link: '',
        },
        {
          title: 'linkedin',
          link: '',
        },
        {
          title: 'twitter',
          link: '',
        },
      ],
    },
    {
      title: 'Series Campaign 6',
      subTitle: 'Test Campaign Name',
      articleLink: articleMock,
      contactsSent: 24,
      completed: true,
      engagement: [
        {
          title: 'Open Rate',
          key: 'openRate',
          value: 24,
          type: 'percent',
        },
        {
          title: 'Total Clicks',
          key: 'totalClicks',
          value: 240,
          type: 'number',
        },
        {
          title: 'Engagement',
          key: 'engagement',
          value: 27,
          type: 'percent',
        },
      ],
      socials: [
        {
          title: 'email',
          link: '',
        },
        {
          title: 'facebook',
          link: '',
        },
        {
          title: 'linkedin',
          link: '',
        },
        {
          title: 'twitter',
          link: '',
        },
      ],
    },
    {
      title: 'Series Campaign 7',
      subTitle: 'Test Campaign Name',
      articleLink: articleMock,
      contactsSent: 24,
      completed: true,
      engagement: [
        {
          title: 'Open Rate',
          key: 'openRate',
          value: 24,
          type: 'percent',
        },
        {
          title: 'Total Clicks',
          key: 'totalClicks',
          value: 240,
          type: 'number',
        },
        {
          title: 'Engagement',
          key: 'engagement',
          value: 27,
          type: 'percent',
        },
      ],
      socials: [
        {
          title: 'email',
          link: '',
        },
        {
          title: 'facebook',
          link: '',
        },
        {
          title: 'linkedin',
          link: '',
        },
        {
          title: 'twitter',
          link: '',
        },
      ],
    },
    {
      title: 'Series Campaign 8',
      subTitle: 'Test Campaign Name',
      articleLink: articleMock,
      contactsSent: 24,
      completed: true,
      engagement: [
        {
          title: 'Open Rate',
          key: 'openRate',
          value: 24,
          type: 'percent',
        },
        {
          title: 'Total Clicks',
          key: 'totalClicks',
          value: 240,
          type: 'number',
        },
        {
          title: 'Engagement',
          key: 'engagement',
          value: 27,
          type: 'percent',
        },
      ],
      socials: [
        {
          title: 'email',
          link: '',
        },
        {
          title: 'facebook',
          link: '',
        },
        {
          title: 'linkedin',
          link: '',
        },
        {
          title: 'twitter',
          link: '',
        },
      ],
    },
    {
      title: 'Series Campaign 9',
      subTitle: 'Test Campaign Name',
      articleLink: articleMock,
      contactsSent: 24,
      completed: true,
      engagement: [
        {
          title: 'Open Rate',
          key: 'openRate',
          value: 24,
          type: 'percent',
        },
        {
          title: 'Total Clicks',
          key: 'totalClicks',
          value: 240,
          type: 'number',
        },
        {
          title: 'Engagement',
          key: 'engagement',
          value: 27,
          type: 'percent',
        },
      ],
      socials: [
        {
          title: 'email',
          link: '',
        },
        {
          title: 'facebook',
          link: '',
        },
        {
          title: 'linkedin',
          link: '',
        },
        {
          title: 'twitter',
          link: '',
        },
      ],
    },
    {
      title: 'Series Campaign 10',
      subTitle: 'Test Campaign Name',
      articleLink: articleMock,
      contactsSent: 24,
      completed: true,
      engagement: [
        {
          title: 'Open Rate',
          key: 'openRate',
          value: 24,
          type: 'percent',
        },
        {
          title: 'Total Clicks',
          key: 'totalClicks',
          value: 240,
          type: 'number',
        },
        {
          title: 'Engagement',
          key: 'engagement',
          value: 27,
          type: 'percent',
        },
      ],
      socials: [
        {
          title: 'email',
          link: '',
        },
        {
          title: 'facebook',
          link: '',
        },
        {
          title: 'linkedin',
          link: '',
        },
        {
          title: 'twitter',
          link: '',
        },
      ],
    },
  ],
  ongoing: [
    {
      title: 'Ongoing Series Campaign 1',
      subTitle: 'Test Campaign Name',
      articleLink: articleMock,
      contactsSent: 24,
      completed: false,
      engagement: [
        {
          title: 'Open Rate',
          key: 'openRate',
          value: 24,
          type: 'percent',
        },
        {
          title: 'Total Clicks',
          key: 'totalClicks',
          value: 240,
          type: 'number',
        },
        {
          title: 'Engagement',
          key: 'engagement',
          value: 27,
          type: 'percent',
        },
      ],
      socials: [
        {
          title: 'email',
          link: '',
        },
        {
          title: 'facebook',
          link: '',
        },
        {
          title: 'linkedin',
          link: '',
        },
        {
          title: 'twitter',
          link: '',
        },
      ],
    },
    {
      title: 'Ongoing Series Campaign 2',
      subTitle: 'Test Campaign Name',
      articleLink: articleMock,
      contactsSent: 24,
      completed: false,
      engagement: [
        {
          title: 'Open Rate',
          key: 'openRate',
          value: 24,
          type: 'percent',
        },
        {
          title: 'Total Clicks',
          key: 'totalClicks',
          value: 240,
          type: 'number',
        },
        {
          title: 'Engagement',
          key: 'engagement',
          value: 27,
          type: 'percent',
        },
      ],
      socials: [
        {
          title: 'email',
          link: '',
        },
        {
          title: 'facebook',
          link: '',
        },
        {
          title: 'linkedin',
          link: '',
        },
        {
          title: 'twitter',
          link: '',
        },
      ],
    },
    {
      title: 'Ongoing Series Campaign 3',
      subTitle: 'Test Campaign Name',
      articleLink: articleMock,
      contactsSent: 24,
      completed: false,
      engagement: [
        {
          title: 'Open Rate',
          key: 'openRate',
          value: 24,
          type: 'percent',
        },
        {
          title: 'Total Clicks',
          key: 'totalClicks',
          value: 240,
          type: 'number',
        },
        {
          title: 'Engagement',
          key: 'engagement',
          value: 27,
          type: 'percent',
        },
      ],
      socials: [
        {
          title: 'email',
          link: '',
        },
        {
          title: 'facebook',
          link: '',
        },
        {
          title: 'linkedin',
          link: '',
        },
        {
          title: 'twitter',
          link: '',
        },
      ],
    },
    {
      title: 'Series Campaign 4',
      subTitle: 'Test Campaign Name',
      articleLink: articleMock,
      contactsSent: 24,
      completed: false,
      engagement: [
        {
          title: 'Open Rate',
          key: 'openRate',
          value: 24,
          type: 'percent',
        },
        {
          title: 'Total Clicks',
          key: 'totalClicks',
          value: 240,
          type: 'number',
        },
        {
          title: 'Engagement',
          key: 'engagement',
          value: 27,
          type: 'percent',
        },
      ],
      socials: [
        {
          title: 'email',
          link: '',
        },
        {
          title: 'facebook',
          link: '',
        },
        {
          title: 'linkedin',
          link: '',
        },
        {
          title: 'twitter',
          link: '',
        },
      ],
    },
    {
      title: 'Ongiong Series Campaign 5',
      subTitle: 'Test Campaign Name',
      articleLink: articleMock,
      contactsSent: 24,
      completed: false,
      engagement: [
        {
          title: 'Open Rate',
          key: 'openRate',
          value: 24,
          type: 'percent',
        },
        {
          title: 'Total Clicks',
          key: 'totalClicks',
          value: 240,
          type: 'number',
        },
        {
          title: 'Engagement',
          key: 'engagement',
          value: 27,
          type: 'percent',
        },
      ],
      socials: [
        {
          title: 'email',
          link: '',
        },
        {
          title: 'facebook',
          link: '',
        },
        {
          title: 'linkedin',
          link: '',
        },
        {
          title: 'twitter',
          link: '',
        },
      ],
    },
    {
      title: 'Ongoing Series Campaign 6',
      subTitle: 'Test Campaign Name',
      articleLink: articleMock,
      contactsSent: 24,
      completed: false,
      engagement: [
        {
          title: 'Open Rate',
          key: 'openRate',
          value: 24,
          type: 'percent',
        },
        {
          title: 'Total Clicks',
          key: 'totalClicks',
          value: 240,
          type: 'number',
        },
        {
          title: 'Engagement',
          key: 'engagement',
          value: 27,
          type: 'percent',
        },
      ],
      socials: [
        {
          title: 'email',
          link: '',
        },
        {
          title: 'facebook',
          link: '',
        },
        {
          title: 'linkedin',
          link: '',
        },
        {
          title: 'twitter',
          link: '',
        },
      ],
    },
    {
      title: 'Ongoing Series Campaign 7',
      subTitle: 'Test Campaign Name',
      articleLink: articleMock,
      contactsSent: 24,
      completed: false,
      engagement: [
        {
          title: 'Open Rate',
          key: 'openRate',
          value: 24,
          type: 'percent',
        },
        {
          title: 'Total Clicks',
          key: 'totalClicks',
          value: 240,
          type: 'number',
        },
        {
          title: 'Engagement',
          key: 'engagement',
          value: 27,
          type: 'percent',
        },
      ],
      socials: [
        {
          title: 'email',
          link: '',
        },
        {
          title: 'facebook',
          link: '',
        },
        {
          title: 'linkedin',
          link: '',
        },
        {
          title: 'twitter',
          link: '',
        },
      ],
    },
  ],
};

export const campaignEngagementData = [
  {
    title: 'Series Campaign 1',
    name: 'Campaign Name 1',
    score: 64,
    image: articleMock,
  },
  {
    title: 'Series Campaign 2',
    name: 'Campaign Name 2',
    score: 43,
    image: articleMock,
  },
  {
    title: 'Series Campaign 3',
    name: 'Campaign Name 3',
    score: 33,
    image: articleMock,
  },
];

export const socialAndEmailData = {
  email: [
    {
      title: 'Most Engaged Time',
      body: 'Based on your engagement activity, the best time to post is on -',
      content: 'Mondays at 11:10 AM EST',
      colors: { color: colors.blue, background: colors.light_blue },
    },
    {
      title: 'Most Engaged Format',
      body: 'You are seeing higher levels of engagement when you post:',
      content: 'PDF Content',
      colors: { color: colors.orange, background: colors.light_orange },
    },
  ],
  social: [
    {
      title: 'Most Engaged Time',
      body: 'Based on your engagement activity, the best time to post is on -',
      content: 'Sunday at 2:30 PM EST',
      colors: { color: colors.blue, background: colors.light_blue },
    },
    {
      title: 'Most Engaged Format',
      body: 'You are seeing higher levels of engagement when you post:',
      content: 'Photos',
      colors: { color: colors.orange, background: colors.light_orange },
    },
  ],
};

export const mostEngagedData = {
  email: [
    {
      title: 'Most Engaged Time',
      body: 'Based on your engagement activity, the best time to post is on -',
      content: 'Mondays at 11:10 AM EST',
      colors: { color: colors.blue, background: colors.light_blue },
    },
    {
      title: 'Most Engaged Format',
      body: 'You are seeing higher levels of engagement when you post:',
      content: 'PDF Content',
      colors: { color: colors.orange, background: colors.light_orange },
    },
  ],
  social: [
    {
      title: 'Most Engaged Time',
      body: 'Based on your engagement activity, the best time to post is on -',
      content: 'Sunday at 2:30 PM EST',
      colors: { color: colors.blue, background: colors.light_blue },
    },
    {
      title: 'Most Engaged Format',
      body: 'You are seeing higher levels of engagement when you post:',
      content: 'Photos',
      colors: { color: colors.orange, background: colors.light_orange },
    },
  ],
};

export default recommendationData;
