/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { 
    EmailListContainer, 
    EmailListInnerContainer,
    TableHeader,
    TableTitle,
    TableActionContainer,
    InnerActionContainer,
    EmptyStyle,
    WhiteButton,
    SyncIconStyle
} from './EmailList.styles';
import {
    Table,
    TableBody,
    TableCell,
    TableRow,
} from '@mui/material';
import { getAllUnsubscribes } from '../../../services/dashboard/index';
import { tableCellClasses } from '@mui/material/TableCell';
import SearchBar from '../../../componentsV2/SearchBar';
import TableLoader from './TableLoader';
import { saveFile } from '../../../utils/common.helper';
import { get } from 'lodash';
import { Tooltip } from '@mui/material';

const ListTable = ({ 
    tableData, 
    searchBoxInput, 
    setSearchBoxInput,
    listCheckAction,
    headerData,
    searchHandler,
    selectedRow,
    segmentTabActive,
    tableLoading, 
    setTableLoading,
    listLoading, 
    userData,
    refreshDataAction,
    setSelectedRow,
    showEmptyDisplay
}) => {
  const [downloadLoading, setDownloadLoading] = useState(false);

  useEffect(() => {
    setSearchBoxInput('')
    if(!selectedRow && segmentTabActive){
      setSelectedRow(tableData[0])
    }
  },[]);

  useEffect(() => {
    if(searchBoxInput.length > 0 & segmentTabActive){
      if(tableData && tableData.length > 1){
        setSelectedRow(tableData[0])
      }
    }
  },[tableData]);

  const onDownloadUnsubscribes = async() => {
    setDownloadLoading(true)
    try{
      let name = get(userData, 'name.first', 'unknown') + get(userData, 'name.last', '');
      const response = await getAllUnsubscribes()
      await saveFile(response, `Unsubscribed_emails_${name}`);
      setDownloadLoading(false)
    } catch(err){
      console.log('Download unsubscribe error:', err);
      setDownloadLoading(false)
    }
  };

  const tableDisplay = () => {
      return(
          <TableBody>
              {tableData.map((contact, index) => {
                  return (
                      <TableRow
                          key={index}
                          onClick={()=>{
                            setTableLoading(true)
                            listCheckAction(contact)
                          }}
                          id={`selection-row-${index}`}
                          sx={{
                            padding: '2px auto 2px auto',
                            height: 20,
                            cursor: 'pointer',
                            background: selectedRow?.name === contact?.name ? '#f1f1f1' : 'white'
                          }}
                      >
                      {headerData.emailLists.map((headerInfo, headerIndex) => {
                          if(headerInfo.formatData){
                            return <TableCell key={headerIndex}>{headerInfo.formatData(contact)}</TableCell>;
                          } else {
                          return (
                            <TableCell key={headerIndex}>
                              <p>{contact[headerInfo.key]}</p>
                            </TableCell>
                          )
                          }
                      })}
                      </TableRow>
                  );
                  })
              }
          </TableBody>
      )
    };

    const emptyDisplay = () => {
      return(
          <TableBody  style={{ display: 'flex', justifyContent: 'center'}}>
              <EmptyStyle>No Results Found</EmptyStyle>
          </TableBody>
      )
  };

    const tableOutput = () => {
      return(
        <Table
          stickyHeader
          size="small"
          sx={{
            [`& .${tableCellClasses.root}`]: {
              padding: '4px auto 4px auto',
              height: 20,
              fontFamily: 'Poppins !important'
            },
          }}
        >
        {
          tableDisplay()
        }
        </Table>
      )
    }

    return(
      <EmailListContainer>
        <TableHeader>
          <TableTitle style={{margin: '15px'}}>My {segmentTabActive ? 'Segments' : 'Lists'}</TableTitle>
          <Tooltip title="Reload data">
            <SyncIconStyle onClick={()=>refreshDataAction()} />
          </Tooltip>
        </TableHeader>
        <TableActionContainer>
          <InnerActionContainer style={{width: '85%'}}>
            <SearchBar 
                searchString={searchBoxInput} 
                setSearchString={setSearchBoxInput} 
                searchHandler={searchHandler}
            />
          </InnerActionContainer>
        </TableActionContainer>
        <EmailListInnerContainer>
        {
          tableData.length && !listLoading ? 
          tableOutput() : showEmptyDisplay ? emptyDisplay() :
          searchBoxInput.length && !segmentTabActive ? emptyDisplay() :
          <TableLoader />
        }
        </EmailListInnerContainer>
        {userData && !segmentTabActive && 
          <WhiteButton 
            children={downloadLoading ? 'Loading...' : 'Download Unsubscribed'} 
            onClick={()=>onDownloadUnsubscribes()} 
            style={{margin: '75px auto 10px'}}
          /> 
        }
      </EmailListContainer>
    )
  };

  export default ListTable;