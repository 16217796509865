import React, { useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { CircularProgress } from '@mui/material';
import { Loader } from 'src/components';
import Button from 'src/componentsV2/Button';
import DialogModal from 'src/componentsV2/DialogModal';
import TopicsSearch from 'src/componentsV2/complex/TopicsSearch';
import Multiply from 'src/assets/icons/Multiply';
import Check from 'src/assets/icons/Check';
import useRESTQuery from 'src/hooks/useRESTQuery';
import { subscribeTopics } from './mutation';
import {
  AddTopicsContainer,
  DarkerPill,
  Link,
  ModalFooter,
  ModalWrapper,
  MyTopics,
  MyTopicsHeader,
  MyTopicsPill,
  MyTopicsPills,
  Pill,
  Pills,
  Wrapper,
} from './styles';

function Topics({
  selectedTopics,
  setSelectedTopics,
  subscribedTopics,
  refetchSubscribedTopics,
}) {
  const [showCustomizeTopics, setShowCustomizeTopics] = useState(false);
  const [tempSubscribedTopics, setTempSubscribedTopics] = useState([]);
  const { mutate, isLoading } = useMutation(subscribeTopics, {
    onSuccess: () => {
      refetchSubscribedTopics();
      setShowCustomizeTopics(false);
    },
  });

  useEffect(() => {
    if (Array.isArray(subscribedTopics)) {
      setTempSubscribedTopics([...subscribedTopics]);
    }
  }, [subscribedTopics]);

  const { isLoading: topicsLoader, data: topics } = useRESTQuery({
    key: 'topics',
    api: 'api.clout',
    useAdvisorToken: true,
    endpoint:
      'theme/?fields=theme_id,name,status,description,media_urls,created_time,updated_time',
    options: {
      select: (res) => {
        const result = res?.result?.data;
        if (Array.isArray(result) && result.length > 0) {
          return result?.map((topic) => ({
            ...topic,
            theme_name: topic?.name,
          }));
        }
      },
    },
  });

  const handleTopicSelection = (topicId) => {
    if (Array.isArray(selectedTopics) && selectedTopics?.includes(topicId)) {
      const filtered = selectedTopics?.filter((t) => t !== topicId);
      setSelectedTopics(filtered ?? []);
    } else {
      setSelectedTopics([...(selectedTopics ?? []), topicId]);
    }
  };

  const handleSelectAllTopics = () => {
    if(subscribedTopics?.length === selectedTopics?.length) {
      return;
    } else {
      setSelectedTopics([...subscribedTopics]?.map((topic) => topic?.id))
    }
  }

  const handleTopicsSave = () => {
    const topicsToBeAdded = [...tempSubscribedTopics]?.map(
      (topic) => topic?.id
    );

    const topicsToBeRemoved = [...subscribedTopics]
      ?.filter(
        (topic) => !tempSubscribedTopics?.some((t) => t?.id === topic?.id)
      )
      ?.map((topic) => topic?.id)
      ?.filter((topic) => topic);

    mutate({
      addThemes: JSON.stringify(topicsToBeAdded),
      removeThemes: JSON.stringify(topicsToBeRemoved),
    });
  };

  return (
    <>
      <Wrapper>
        {Array.isArray(subscribedTopics) && subscribedTopics?.length > 0 && (
          <Pills>
            <DarkerPill
               key={'select-all'}
               selected={subscribedTopics?.length === selectedTopics?.length}
               onClick={() => handleSelectAllTopics()}
            >
              Select All
            </DarkerPill>
            {subscribedTopics?.map((topic) => (
              <Pill
                key={topic?.id}
                selected={selectedTopics?.includes(topic?.id)}
                onClick={() => handleTopicSelection(topic?.id)}
              >
                {selectedTopics?.includes(topic?.id) && <Check />}{' '}
                {topic?.theme_name}
              </Pill>
            ))}
          </Pills>
        )}

        <Link onClick={() => setShowCustomizeTopics(true)}>
          Customize Topics
        </Link>
      </Wrapper>

      <DialogModal
        show={showCustomizeTopics}
        toggleModalVisibility={setShowCustomizeTopics}
        maxWidth="md"
        title="Customize Topics"
        showTitle
        showCloseButton
      >
        <ModalWrapper>
          <MyTopics>
            <MyTopicsHeader
              isLimitReached={tempSubscribedTopics?.length === 15}
            >
              <h3>My Topics</h3>
              <h6>{tempSubscribedTopics?.length}/15</h6>
            </MyTopicsHeader>
            <MyTopicsPills>
              {tempSubscribedTopics?.map((topic) => (
                <MyTopicsPill
                  key={topic?.id}
                  onClick={() =>
                    setTempSubscribedTopics([
                      ...tempSubscribedTopics?.filter(
                        (t) => t?.id !== topic?.id
                      ),
                    ])
                  }
                >
                  <span>{topic?.theme_name}</span> <Multiply />
                </MyTopicsPill>
              ))}
            </MyTopicsPills>
          </MyTopics>
          <AddTopicsContainer>
            <h3>Add Topics</h3>
            {topicsLoader ? (
              <Loader />
            ) : (
              <TopicsSearch
                topics={topics}
                selectedTopics={tempSubscribedTopics}
                setSelectedTopics={setTempSubscribedTopics}
                showSelectedTopics={false}
                topicSelectionLimit={15}
              />
            )}
          </AddTopicsContainer>
          <ModalFooter>
            <Button
              variant="outline"
              fullWidth
              onClick={() => setShowCustomizeTopics(false)}
            >
              Cancel
            </Button>
            <Button fullWidth disabled={isLoading} onClick={handleTopicsSave}>
              {isLoading ? <CircularProgress size={15} /> : 'Save'}
            </Button>
          </ModalFooter>
        </ModalWrapper>
      </DialogModal>
    </>
  );
}

export default Topics;
