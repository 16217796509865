import styled from 'styled-components';
import { colors } from '../../styles';

export const ButtonStyle = styled('button')`
  font-family: 'Poppins' !important;
  background: ${({ disabled }) => disabled ? colors.disabled_button : colors.white};
  border: ${({ disabled }) => disabled ? `1px solid ${colors.disabled_button}` : `1px solid ${colors.gray_border}`};
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.12);
  border-radius: 4px;
  cursor: ${({ disabled }) => disabled ? 'text' : 'pointer'};
  width: ${({ width }) => width ? width : 'fit-content'};
  height: ${({ height }) => height ? height : '40px'};
  display: flex;
  justify-content: center;
  margin: ${({ margin }) => margin ? margin : '10px 5px'};
  text-transform: none;
  padding: 8px 16px;
  text-align: center;
  white-space:nowrap;
  transition: 0.5s;
  :hover {
    background: ${({ disabled }) => disabled ? colors.disabled_button : colors.white_02};
  }
`;