/* eslint-disable react-hooks/exhaustive-deps */
import { Flex } from '@rebass/grid';
import { get, isEmpty } from 'lodash';
import React, { useState, useEffect } from 'react';
import { getSourceNwId } from '../../../components/ShareContentContainerV2/functions';
import { updateCampaign } from '../../../services/campaign';
import CampaignFooterButtons from '../CampaignFooterButtons/CampaignFooterButtons';
import { ContentArticlesContainer, SuggestedArticlesTab } from '../DripCampaignContainer.styles';
import CampaignCard from '../PlanCampaign/CampaignCard';
import { LineHorizontalSeparator } from '../PlanCampaign/PlanCampaign.styles';
import { SelectedArticlesContainer } from '../SelectArticles/SelectArticles.styles';
import { AssignArticlesWrapper } from './AssignArticles.styles';
import EmailModal from './EmailModal';
import FacebookModal from './FacebookModal';
import LinkedInModal from './LinkedinModal';
import TwitterModal from './TwitterModal';

const AssignArticles = ({ 
    campaignSelectedArticles, 
    campaignSelectedWeek, 
    campaignArticles, 
    getAccountId,
    enableNext,
    toggleEnableNext, 
    onAssignContentTabChange,
    campaignSetState,
    setCreateScreenIndex,
    setAssignContentTab,
    campaignId,
    publishedContent,
    setObjectivesTabData,
    socialNetworkData,
    endCreateFlow
}) => {

    // console.log('CAMPAIGN ARTICLES - -- - ', campaignArticles);
    const [activePlatformTab, setActivePlatformTab] = useState('EMAIL');
    const [displayCardsData, setDisplayCardsData] = useState([]);
    const [allTabData, setAllTabData] = useState([]);
    const [otherTabsData, setOtherTabsData] = useState([]);
    const [filteredSelectedArticles, setFilteredSelectedArticles] = useState(campaignSelectedArticles);
    const [draggedArticle, setDraggedArticle] = useState('');
    const [openModal, toggleModal] = useState({status: false, article: ''});
    const [loading, toggleLoading] = useState(false);
    const platformListMapper = {
        EMAIL: 'emList',
        FACEBOOK: 'fbList',
        TWITTER: 'twList',
        LINKEDIN: 'lnList'
    }
    // const socialNetworkData = {
    //     em: [],
    //     fb: [
    //         {
    //             page: {
    //                 ['101009138403202']: {
    //                     details: {
    //                         name: 'QA Page'
    //                     }
    //                 }
    //             }
    //         }
    //     ],
    //     ln: [
    //         {
    //             page: {
    //                 ['101009138403202']: {
    //                     details: {
    //                         name: 'LN Page'
    //                     }
    //                 }
    //             }
    //         }
    //     ],
    //     tw: [{
    //         page: {
    //             ['101009138403202']: {
    //                 details: {
    //                     name: 'TW Page'
    //                 }
    //             }
    //         }
    //     }]
    // }

    useEffect(() => {
        const platformInfo = {};
        // const weekFilteredData = campaignArticles.filter(article => article.week === campaignSelectedWeek);
        let enable = true;
        let cardsData = campaignArticles
        .filter(article => article.week === campaignSelectedWeek)
        .filter(article => {
            if(!article.content)
                enable = false;
            if(platformInfo[article.platform]) {
                platformInfo[article.platform]++;
                return false;
            }
            else {
                platformInfo[article.platform] = 1;
                return true;
            }
        })
        .map((article, index) => {
            return {
                platform: article.platform,
                count: platformInfo[article.platform],
                content: false,
                ref_id: index
            }
        });

        setAllTabData(cardsData);
        setOtherTabsData([...campaignArticles]);
        setDisplayCardsData([...campaignArticles]);
        toggleEnableNext(enable);
    }, []);


    useEffect(() => {
        let filteredSelectedData;
        if(activePlatformTab === 'ALL') {
            if(allTabData.length > 0)
                setDisplayCardsData(allTabData);
            setFilteredSelectedArticles(campaignSelectedArticles);
        }
        else {
            console.log('else active platfform tab');
            filteredSelectedData = campaignSelectedArticles.filter(article => article.summaries[activePlatformTab]);
            setFilteredSelectedArticles(filteredSelectedData);
            if(otherTabsData.length>0)
                setDisplayCardsData(otherTabsData);
        }
    }, [activePlatformTab]);

    
    const dragHandler = id => {
        console.log('ID - -- - - ', id);
        if(activePlatformTab === 'ALL') {
            // const otherTabsDataCopy = [...otherTabsData];
            // const insertPlatform = allTabDataCopy[insertIndex].platform;
            const allTabDataCopy = [...allTabData];
            const insertIndex = allTabDataCopy.findIndex(article => article.ref_id === id);
            allTabDataCopy[insertIndex].content = true;
            allTabDataCopy[insertIndex] = {
                ...allTabDataCopy[insertIndex], 
                ...draggedArticle,
                content: true
            };
            // otherTabsDataCopy.forEach(article => {
            //     if(article.platform === insertPlatform)
            //         article.content = draggedArticle;
            // });
            setAllTabData(allTabDataCopy);
            setDisplayCardsData(allTabDataCopy);
            // setOtherTabsData(otherTabsDataCopy);            
        }
        else {
            const otherTabsDataCopy = [...otherTabsData];
            const insertIndex = otherTabsDataCopy.findIndex(article => article.ref_id === id);
            // otherTabsDataCopy[insertIndex].content = true;
            otherTabsDataCopy[insertIndex] = {
                ...otherTabsDataCopy[insertIndex], 
                ...draggedArticle,
                content: true,
                override_text: draggedArticle.override_text[otherTabsDataCopy[insertIndex].platform.toUpperCase()],
                source_snetwork: getSourceNwId(draggedArticle.id),
                repost: 'false'
            };
            console.log('FILTERED DATA= = = = ', otherTabsDataCopy.filter(article => article.week === campaignSelectedWeek));
            const notEmpty = otherTabsDataCopy.filter(article => article.week === campaignSelectedWeek).every(article => {
                // console.log('CONTENT - - - ', article.content);
                return article.content
            });
            if(notEmpty)
                toggleEnableNext(true);
            else
                toggleEnableNext(false);
            setDraggedArticle('');
            setOtherTabsData(otherTabsDataCopy);
            setDisplayCardsData(otherTabsDataCopy);
        }
    }

    const getFinalPayload = (item, platform) => {
        const returnObj = {
            ref_id: item.ref_id,
            id: item.id,
            channel_id: item.channel_id,
            destination_snetwork: item.destination_snetwork,
            engagement_type: item.engagement_type,
            override_text: item.override_text,
            source_snetwork: item.source_snetwork,
            theme_id: item.theme_id,
            contactlist_id: platform === 'Email' ? item.contactlist_id : null,
            snetwork_act_id: item.snetwork_act_id,
            schedule_time: item.schedule_time,
            repost: item.repost,
            destination_id: platform !== 'Email' ? item.destination_id : null,
        }
        if(platform==='Email' && item.email_data)
            returnObj.email_data = item.email_data;
        return returnObj; 
    }

    const nextHandler = save => {
        toggleLoading(true);
        const emailData = otherTabsData.filter(article => article.week === campaignSelectedWeek).filter(item => item.platform === 'Email').map(item => getFinalPayload(item, 'Email'));
        const facebookData = otherTabsData.filter(article => article.week === campaignSelectedWeek).filter(item => item.platform === 'Facebook').map(item => getFinalPayload(item, 'Facebook'));
        const twitterData = otherTabsData.filter(article => article.week === campaignSelectedWeek).filter(item => item.platform === 'Twitter').map(item => getFinalPayload(item, 'Twitter'));
        const linkedInData = otherTabsData.filter(article => article.week === campaignSelectedWeek).filter(item => item.platform === 'Linkedin').map(item => getFinalPayload(item, 'Linkedin'));

        let contentData = {
            email: emailData,
            facebook: facebookData,
            twitter: twitterData,
            linkedin: linkedInData,
        }
        console.log('CONTENT DATA - - - ', contentData);

        const week = campaignSelectedWeek.replace(' ', '');
        const content = {
            [week]: contentData
        }
        const payloadObj = {
            posts: JSON.stringify(content)
        };

        const modifiedWeek = week.toLowerCase();
        updateCampaign({id: campaignId, payload: payloadObj}).then(res => {
            toggleLoading(false);
            if(res && res.result.success) {
                campaignSetState({
                    campaignArticles: otherTabsData,
                    publishedContent: {
                        ...publishedContent,
                        [modifiedWeek]: true
                    }
                });
                setAssignContentTab(0);
                if(save)
                    endCreateFlow();
                else
                    setCreateScreenIndex(0);
            }
        })
        .catch(err => {
            console.log(err);
            toggleLoading(false)
        });
        // console.log('PAYLOAD OBJECT - - -- ', payloadObj);
    }

    // const demoNext = () => {

    // }

    const backHandler = () => {
        onAssignContentTabChange('back');
        toggleEnableNext(true);
    }

    const emailModalSaveHandler = ({data, uploadedImg}) => {
        const otherTabsDataCopy = [...otherTabsData];
        const insertIndex = otherTabsDataCopy.findIndex(article => article.ref_id === openModal.article.ref_id);
        console.log('article id - - ', openModal.article.ref_id);
        otherTabsDataCopy[insertIndex].email_data = data;
        otherTabsDataCopy[insertIndex].media1 = uploadedImg;
        console.log('EMAIL DATA APPENDED - -- ', otherTabsDataCopy);
        setOtherTabsData(otherTabsDataCopy);
        toggleModal({status: false, articleId: ''});
    }

    const otherModalsSaveHandler = summaryText => {
        const otherTabsDataCopy = [...otherTabsData];
        const insertIndex = otherTabsDataCopy.findIndex(article => article.ref_id === openModal.article.ref_id);
        otherTabsDataCopy[insertIndex].override_text = summaryText;
        toggleModal({ status: false, article: '' });
        console.log('SUMMARY TEXT UPDATED - - - - ', otherTabsDataCopy);
    }


    const saveAndCloseHandler = () => {
        
    }

    return (
        <>
            <AssignArticlesWrapper>
                <ContentArticlesContainer className='selected-articles'>
                    <span style={{ color: '#000000', width: '100%', textAlign: 'left', fontSize: '14px' }}>
                        <strong>SELECTED CONTENT</strong>
                    </span>
                    <LineHorizontalSeparator style={{margin: '10px 0px'}} />
                    <Flex justifyContent='center' alignItems='center' width='95%' style={{overflowX: 'auto', overflowY: 'hidden'}}>
                        <SelectedArticlesContainer scrollHeight='10px'>
                            {filteredSelectedArticles && filteredSelectedArticles.length > 0 ? (
                                filteredSelectedArticles.map((article, index) => (
                                    <CampaignCard 
                                        {...article} 
                                        clickHandler={() => {}} 
                                        showPlatformBubbles={true}
                                        isDraggable={true}
                                        setDraggedArticle={setDraggedArticle}
                                        fromAssignArticle={true}
                                        content={true}
                                        setObjectivesTabData={setObjectivesTabData}
                                        platformListMapper={platformListMapper}
                                    />
                                ))
                            ) : (
                                <span style={{width: '100%', textAlign: 'center', margin: 'auto', color: '#BCCDD4'}}>No Summary was entered for this platform</span>
                            )}
                        </SelectedArticlesContainer>
                    </Flex>
                </ContentArticlesContainer>
                <ContentArticlesContainer className='platform-tabs' >
                    <Flex justifyContent='flex-start' alignItems='center' width='100%'>
                        {/* <SuggestedArticlesTab
                            onClick={() => setActivePlatformTab('ALL')}
                            active={activePlatformTab === 'ALL'}
                            style={{ padding: '2px 35px', fontSize: '15px', height: '35px' }}
                        >
                            ALL
                        </SuggestedArticlesTab> */}
                        {campaignSelectedArticles && campaignSelectedArticles[0] && Object.keys(campaignSelectedArticles[0].summaries).map(label => (
                            <SuggestedArticlesTab
                                onClick={() => setActivePlatformTab(label)}
                                active={activePlatformTab === label}
                                disabled={isEmpty(get(setObjectivesTabData, platformListMapper[label], []))}
                                style={{ 
                                    padding: '2px 35px', 
                                    fontSize: '15px', 
                                    height: '35px',
                                    pointerEvents: isEmpty(get(setObjectivesTabData, platformListMapper[label], [])) ? 'none' : 'auto'
                                }}
                            >
                                {label}
                            </SuggestedArticlesTab>
                        ))}
                    </Flex>
                    <SelectedArticlesContainer scrollHeight='10px' style={{ height: activePlatformTab !== 'ALL' ? '380px' : '' }}>
                        {displayCardsData && displayCardsData.length > 0 ? (displayCardsData.map((article, index) => (
                            <Flex flexDirection='column' justifyContent='flex-start'>
                                {activePlatformTab !== 'ALL' && article.platform.toUpperCase() === activePlatformTab && article.week === campaignSelectedWeek && (
                                    <>
                                        <div 
                                            style={{ 
                                                width: '93%', 
                                                textAlign: 'left', 
                                                fontSize: '14px', 
                                                color: '#474747', 
                                                borderBottom: '1px solid #a9b7be',
                                                padding: '0px 4px 3px' 
                                            }}
                                        >
                                            {article.day}
                                        </div>
                                        {activePlatformTab === 'EMAIL' && (
                                            <div style={{ width: '94%', textAlign: 'right', cursor: article.override_text ? 'pointer' : 'default' }} onClick={article.override_text ? () => toggleModal({status: true, article: article}) : () => {}} >
                                                <img style={{height: '11px'}} alt='' src={article.override_text ? './assets/images/edit_Icon.svg' : './assets/images/edit_Icon-disabled.svg'} />
                                            </div>
                                        )}
                                    </>
                                )}
                                {(activePlatformTab === 'ALL' || article.platform.toUpperCase() === activePlatformTab) && article.week === campaignSelectedWeek && (
                                    <CampaignCard 
                                        {...article} 
                                        clickHandler={() => {}}
                                        fromAssignArticle={true}
                                        dragHandler={dragHandler}
                                    />
                                )}
                            </Flex>
                        ))) : (
                            <span style={{width: '100%', textAlign: 'center', margin: 'auto', color: '#BCCDD4'}}>This platform</span>
                        )}
                    </SelectedArticlesContainer>
                </ContentArticlesContainer>
                {openModal.status && (
                    activePlatformTab === 'EMAIL' ? (
                        <EmailModal 
                            isOpen={openModal.status} 
                            handleModalClose={() => toggleModal({status: false, article: ''})}
                            saveHandler={emailModalSaveHandler} 
                            article={openModal.article}
                        />
                    ) : 
                    activePlatformTab === 'FACEBOOK' ? (
                        <FacebookModal 
                            isOpen={openModal.status} 
                            handleModalClose={() => toggleModal({status: false, article: ''})}
                            saveHandler={otherModalsSaveHandler}  
                            article={openModal.article}
                            pageList={setObjectivesTabData && setObjectivesTabData.fbList}
                            socialNetworkData={socialNetworkData}
                        />
                    ) :
                    activePlatformTab === 'TWITTER' ? (
                        <TwitterModal 
                            isOpen={openModal.status} 
                            handleModalClose={() => toggleModal({status: false, article: ''})}
                            saveHandler={otherModalsSaveHandler}  
                            article={openModal.article}
                            pageList={setObjectivesTabData && setObjectivesTabData.twList}
                            socialNetworkData={socialNetworkData}
                        />
                    ) : (
                        <LinkedInModal 
                            isOpen={openModal.status} 
                            handleModalClose={() => toggleModal({status: false, article: ''})}
                            saveHandler={otherModalsSaveHandler} 
                            article={openModal.article}
                            pageList={setObjectivesTabData && setObjectivesTabData.lnList}
                            socialNetworkData={socialNetworkData}
                        />
                    )
                )}
            </AssignArticlesWrapper>
            <CampaignFooterButtons 
                nextHandler={() => nextHandler()} 
                enableNext={enableNext} 
                backHandler={backHandler} 
                loading={loading}
                saveAndCloseHandler={() => nextHandler(true)}
            />
        </>
    );
}

export default AssignArticles;