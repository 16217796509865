import styled from 'styled-components';

export const RadioInput = styled.input`
  border: 2.7px solid white;
  box-shadow: 0 0 0 1px #aaa;
  appearance: none;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  background-color: #fff;
  transition: all ease-in 0.2s;
  cursor: ${({ disabled }) => (!disabled ? 'pointer' : '')};

  &:checked {
    background-color: ${({ disabled }) => (!disabled ? '#6351ed' : '#ccc')};
    box-shadow: 0 0 0 1px #aaa;
  }
`;

// export const CheckboxInput = styled.input`
//    border: 2px solid white;
//   box-shadow: 0 0 0 1px #aaa;
//   appearance: none;
//   width: 12px;
//   height: 12px;
//   background-color: #fff;
//   transition: all ease-in 0.2s;

//    &:checked {
//     background-color: #6351ed;
//     box-shadow: 0 0 0 1px #aaa;
//   }
// `;

export const CheckboxInput = styled.div`
  .container {
    display: block;
    position: relative;
    padding-left: 25px;
    cursor: pointer;
    color: #000;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  /* Hide the browser's default checkbox */
  .container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: 1px;
    left: 6px;
    height: 15px;
    width: 15px;
    background-color: #eee;
    border: 1px solid #c9c9c9;
    border-radius: 4px;
  }

  .container input ~ .checkmark {
    background-color: #fff;
  }
  /* On mouse-over, add a grey background color */
  .container:hover input ~ .checkmark {
    background-color: #fff;
  }

  /* When the checkbox is checked, add a blue background */
  .container input:checked ~ .checkmark {
    background-color: #c95cfc;
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: '';
    position: absolute;
    display: none;
  }

  /* Show the checkmark when checked */
  .container input:checked ~ .checkmark:after {
    display: block;
  }

  /* Style the checkmark/indicator */
  .container .checkmark:after {
    left: 3px;
    top: 0px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`;

export const RadioInputLabel = styled.label`
  color: #000;
  font-size: 14px;
  margin-top: 5px;
  margin-left: 5px;
  cursor: ${({ disabled }) => (!disabled ? 'pointer' : '')};
`;
