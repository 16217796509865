import React, { useEffect, useState, useRef } from 'react';
import { Formik } from 'formik';
import { isEmpty, get, toPlainObject } from 'lodash';
import $ from 'jquery';
import {
  ResponsiveImg,
  ErrorContainer,
  LoaderWrapper,
  ShareArticleContainer,
  ShareContentContainer,
  theme,
  Modal,
} from '../../../components';
import {
  FeedInput,
  FeedWrapper,
  InputWrapper,
  RssFeedButton,
} from './AddContent.styles';
import UploadSuccess from './UploadSuccess';
import {
  getThemesObjectsFromFetched,
  getContentLibArticleData,
  themesData,
} from '../../../utils';
import InternalUse from './InternalUse';
import ImagePreviewTab from './ImagePreviewTab';

const isRss = (url) =>
  url.toLowerCase().indexOf('rss') !== -1 ||
  url.toLowerCase().indexOf('xml') !== -1;

const validate = (values) => {
  const errors = {};
  const { rssurl } = values;
  const regex =
    /\b(?:(?:https?):\/\/|www\.|ftp\.)[-A-Z0-9+&@#\/%=~_-|$?!:,.]*[A-Z0-9+&@#\/%=~_-|$]/gi;

  let linkList = rssurl.match(regex);
  let availableLink = '';
  let availableText = '';
  if (linkList) {
    availableLink = linkList[0];
    var re = new RegExp(linkList[0], 'g');
    // availableText = rssurl.replace(re, '');
    if (availableText === availableLink) {
      availableText = '';
    }
  }
  if (availableLink.length === 0) {
    errors.formError = 'RSS Feed/HTML Link is required';
  }

  return errors;
};

const RssFeed = ({
  postCreateContent,
  postThemeFetch,
  channelId,
  postUpdateContent,
  setTabIndex,
  themesList,
  setIsComplete,
  onTabSelect,
  hasTopicSelected,
  setIsShareContent,
  showThemes,
  showShare,
  setShowShare,
  setShowThemes,
  onYesClick,
  userData,
  contentlibRequest,
  isAdmin,
  setSelectedExploreTabIndex,
  isEnterpriseAdvisor,
  exploreArticleRequest,
  popularTopicDetails,
  setCuratedTopicType,
  setWdigetTabIndex,
  showImagePreview,
  setShowImagePreview,
  showContentOverview,
  setShowContentOverview,
  reset,
  updateReset,
}) => {
  const [contentId, setContentId] = useState('');
  const [secondaryId, setSecondaryId] = useState('');
  const [contentCreateError, setContentCreateError] = useState('');
  const [isSaving, setIsSaving] = useState(false);
  const [isUpdating, setisUpdating] = useState(false);
  // const [showThemes, setShowThemes] = useState(false);
  const [showContent, setShowContent] = useState(false);
  const [fetchedThemes, setFetchedThemes] = useState([]);
  // const [showShare, setShowShare] = useState(false);

  const [themeMessage, setThemeMessage] = useState('');
  const [userSummary, setUserSummary] = useState('');
  const [autoPostVisibilityFlag, setAutoPostVisibilityFlag] = useState(false);
  const [showTryAgain, setShowTryAgain] = useState(false);
  const [showRssUrl, setShowRssUrl] = useState('');
  const [link, setLink] = useState({ url: '', source: '' });
  const [errorMessge, setErrorMessage] = useState('');
  const [showInternalUse, setShowInternalUse] = useState(false);
  const formikRef = useRef();

  useEffect(() => {
    setShowRssUrl('');
  }, []);

  useEffect(() => {
    if (!showThemes && !showShare) {
      setIsShareContent(false);
    }
  }, [showThemes, showShare]);

  useEffect(() => {
    if (reset) {
      // setErrorMessage('');
      if (formikRef.current) formikRef.current.resetForm();
    }
  }, [reset]);

  const toDataURL = (src, callback, outputFormat) => {
    var img = new Image();
    img.crossOrigin = 'Anonymous';
    img.onload = function () {
      var canvas = document.createElement('CANVAS');
      var ctx = canvas.getContext('2d');
      var dataURL;
      canvas.height = this.naturalHeight;
      canvas.width = this.naturalWidth;
      ctx.drawImage(this, 0, 0);
      dataURL = canvas.toDataURL(outputFormat);
      callback(dataURL);
    };
    img.onerror = function () {
      callback(false);
    };
    img.src = src;
    if (img.complete || img.complete === undefined) {
      img.src =
        'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///ywAAAAAAQABAAACAUwAOw==';
      img.src = src;
    }
  };

  const getTitleFromUrl = async (url) => {
    return $.get(url);
  };

  const titleForUrl = async (url) => {
    try {
      let title = await getTitleFromUrl(url);
      console.log('TITLE - ', title);
      title = title.match(/<title[^>]*>([^<]+)<\/title>/im);
      if (title && title[1].length > 0) {
        setShowThemes(true);
        return title[1];
      } else {
        setShowTryAgain(true);
        setShowThemes(false);
        setContentCreateError(
          'Unable to get data from this URL. Please try again with another URL.'
        );
      }
    } catch (err) {
      setShowTryAgain(true);
      setShowThemes(false);
      setContentCreateError(
        'Unable to get data from this URL. Please try again with another URL.'
      );
    }
  };

  const onRssSubmit = (values, { resetForm }) => {
    // setSubmitting(false)
    localStorage.removeItem('emailData');
    setIsComplete(false);
    setContentCreateError('');
    const source = isRss(values.rssurl) ? 'rss' : 'link';
    setLink({ url: values.rssurl, source: source });
    setIsSaving(true);
    postCreateContent({
      channelId,
      title: '', //values.rssurl,
      link: values.rssurl,
      source,
      skip_compliance: false,
    })
      .then((response) => {
        setContentId(get(response, 'result.data.content_id'));
        setSecondaryId(get(response, 'result.data.id'));
        updateReset(false);
        // setIsSaving(false);
        // setShowThemes(true);
        return postThemeFetch({
          url: values.rssurl,
        });
      })
      .then(async (response) => {
        const themeResponse = get(response, 'result.data', {});
        const themeText = get(themeResponse, 'text', {});
        setIsSaving(false);
        setFetchedThemes(getThemesObjectsFromFetched(themeResponse));
        setThemeMessage({
          url: get(themeText, 'image'),
          topic:
            get(themeText, 'title') && get(themeText, 'title').length > 0
              ? get(themeText, 'title')
              : await titleForUrl(values.rssurl),
          message: get(themeText, 'description'),
        });
        setShowThemes(true);
        setShowImagePreview(true);
      })
      .catch((err) => {
        console.log(err);
        setShowTryAgain(true);
        setContentCreateError(
          'Unable to get data from this URL. Please try again with another URL.'
        );
        setIsSaving(false);
      });
  };

  const onUpdateTheme = (
    themes,
    type = '',
    optionType = '',
    summary = {},
    skip_compliance = ''
  ) => {
    // console.log(optionType);
    let reqObj = {
      contentId,
      themes,
      message: userSummary || get(themeMessage, 'message', ''),
      title: get(themeMessage, 'topic', ''),
      // user_summary: userSummary
    };
    // console.log('INSIDE ONUPDATE THEME - ', reqObj);
    // console.log('THEMES - ', themes);
    // if (reqObj.title.length > 100) reqObj.title = reqObj.title.substring(0, 90);
    // reqObj.title = !reqObj.title ? '' : reqObj.title.replace(/[“"”']/g, '');
    reqObj.title = !reqObj.title ? '' : reqObj.title;
    // reqObj.message = !reqObj.message ? '' : reqObj.message.replace(/[“"”']/g, '');
    reqObj.message = !reqObj.message ? '' : reqObj.message;
    if (type === 'Content Overview') {
      reqObj.unpublished = true;
    }

    if (type === 'Share Internal Issue') {
      reqObj.sharing = true;
      reqObj.internalSharing = true;
      reqObj.skip_compliance = skip_compliance;
      reqObj.summary = summary;
    }

    if (type === 'Internal Use') {
      reqObj.internalUse = true;
    }
    setisUpdating(true);
    // toDataURL(themeMessage.url, function (dataUrl) {
    //   if (dataUrl) {
    //     reqObj.media1 = dataUrl;
    //   }
    // });
    reqObj.media1 =
      themeMessage.url &&
      (themeMessage.url.includes('https') ||
        themeMessage.url.includes('http') ||
        themeMessage.url.includes('asset'))
        ? ''
        : themeMessage.url;
    postUpdateContent(reqObj)
      .then((res) => {
        setisUpdating(false);
        setShowRssUrl('');
        // if(type!=='next')
        //   setUserSummary('');
        if (
          type !== 'next' &&
          type !== 'Content Overview' &&
          type !== 'Internal Use' &&
          type !== 'Share Internal Issue'
        ) {
          setShowThemes(false);
          setShowShare(true);
        } else if (type === 'Content Overview' || type === 'Internal Use') {
          handleContentOverviewClick(optionType);
        } else if (type === 'Share Internal Issue') {
          setShowThemes(false);
          setShowShare(false);
          setIsShareContent(false);
          setShowInternalUse(false);
          setShowContent(false);
          onYesClick();
        } else {
          setShowContentOverview(true);
        }
        if (
          type !== 'Content Overview' &&
          type !== 'Internal Use' &&
          type !== 'Share Internal Issue'
        ) {
          setIsShareContent(true);
          setAutoPostVisibilityFlag(true);
          if (fetchedThemes.length === 0) {
            setFetchedThemes(themes.add);
          }
        }
      })
      .catch((err) => {
        if (err.response.status === 403 || err.response.status === 413) {
          delete reqObj.media1;
          postUpdateContent(reqObj)
            .then((res) => {
              setisUpdating(false);
              // if(type!=='next')
              //   setUserSummary('');
              if (
                type !== 'next' &&
                type !== 'Content Overview' &&
                type !== 'Internal Use' &&
                type !== 'Share Internal Issue'
              ) {
                setShowThemes(false);
                setShowShare(true);
              } else if (
                type === 'Content Overview' ||
                type === 'Internal Use'
              ) {
                handleContentOverviewClick(optionType);
              } else if (type === 'Share Internal Issue') {
                setShowThemes(false);
                setShowShare(false);
                setIsShareContent(false);
                onYesClick();
              } else {
                setShowContentOverview(true);
              }
              if (
                type !== 'Content Overview' &&
                type !== 'Internal Use' &&
                type !== 'Share Internal Issue'
              ) {
                setIsShareContent(true);
                setAutoPostVisibilityFlag(true);
                if (fetchedThemes.length === 0) {
                  setFetchedThemes(themes.add);
                }
              }
            })
            .catch((err) => {
              setContentCreateError(
                'This article cannot be added, please try to add another article'
              );
              setisUpdating(false);
              setTimeout(() => {
                setShowThemes(false);
                setShowShare(false);
                setContentCreateError('');
              }, 5000);
            });
        } else {
          setContentCreateError('Oops... somenthing went wrong');
          setisUpdating(false);
        }
      });
    setIsShareContent(false);
  };

  const onExitClick = () => {
    onTabSelect(0, 'exitClick');
  };

  const onShareSuccess = () => {
    setShowThemes(false);
    setShowShare(false);
    setIsComplete(true);
    setShowImagePreview(false);
    setShowContentOverview(false);
    setIsShareContent(false);
  };

  const handleContentOverviewClick = (type) => {
    switch (type) {
      case 'shareNow':
        setShowThemes(false);
        setShowShare(true);
        setIsShareContent(true);
        return;
      case 'viewContent':
        if (isEnterpriseAdvisor) {
          setCuratedTopicType('My Added Content');
        } else {
          contentlibRequest({ type: 'all' });
        }
        setWdigetTabIndex(0);
        setTabIndex(0);
        setSelectedExploreTabIndex(2);
        return;
      case 'addContent':
        setShowThemes(false);
        setShowShare(false);
        setIsShareContent(false);
        setShowContent(false);
        onYesClick();
        return;
      case 'internalUse':
        setShowInternalUse(true);
        return;
      default:
        return;
    }
  };

  const changeAnotherLink = (resetForm) => {
    resetForm({
      rssurl: '',
      rssUpload: null,
      uploadType: '',
    });
    setLink('');
    setShowTryAgain(false);
  };

  const onNextClickImagePreview = (resetForm) => {
    postUpdateContent({
      contentId,
      title: themeMessage.topic, //values.rssurl,,
      message: userSummary || themeMessage.message,
      // user_summary: userSummary,
      link: link.url,
      source: link.source,
      skip_compliance: false,
      media1:
        themeMessage.url &&
        (themeMessage.url.includes('https') ||
          themeMessage.url.includes('http') ||
          themeMessage.url.includes('asset'))
          ? ''
          : themeMessage.url,
    }).then((response) => {
      // setContentId(get(response, 'result.data.content_id'));
      // setSecondaryId(get(response, 'result.data.id'));
      setIsSaving(false);
      setShowImagePreview(false);
      setShowThemes(true);
      resetForm({
        rssurl: '',
        rssUpload: null,
        uploadType: '',
      });
    });
  };

  return (
    <Formik
      enableReinitialize={false}
      initialValues={{
        rssurl: '',
        rssUpload: null,
        uploadType: '',
      }}
      validateOnChange={false}
      onSubmit={onRssSubmit}
      validate={validate}
      innerRef={formikRef}
    >
      {({
        handleChange,
        setFieldValue,
        handleSubmit,
        errors,
        values,
        resetForm,
        setSubmitting,
      }) => {
        // console.log(values);
        const onMioButtonClick = (e) => {
          setFieldValue('uploadType', 'mioUpload');
          handleSubmit(e);
        };
        // const feedWrapper = document.getElementById("myId");
        // if (feedWrapper && [442, '442'].includes(feedWrapper.clientHeight)) {
        //   document.getElementById('myId').style.margin = "auto";
        // }
        return (
          <FeedWrapper id="myId">
            {!showThemes && !showShare && (
              <>
                <InputWrapper>
                  <ResponsiveImg
                    id="post-icon"
                    src="/assets/images/link_icon.png"
                    style={{ top: '8px' }}
                  />
                  <FeedInput
                    id="rssurl"
                    name="rssurl"
                    type="text"
                    onChange={handleChange}
                    placeholder="Insert HTML link here…"
                    value={values.rssurl !== '' ? values.rssurl : ''}
                    readOnly={showTryAgain ? true : false}
                    data-testid="rss-html-link"
                  />
                </InputWrapper>
                <ErrorContainer align="center">
                  {errors.formError}
                </ErrorContainer>
                <div style={{ margin: '20px 5px 0px 0px' }}>
                  <LoaderWrapper isLoading={isSaving}>
                    <RssFeedButton
                      type="button"
                      onClick={(e) => onMioButtonClick(e)}
                      width="88px"
                      background={themesData.purpleishBlue}
                      color={themesData.white}
                      padding="5px 20px"
                    >
                      {showTryAgain ? 'Try Again' : 'Next'}
                    </RssFeedButton>
                    {showTryAgain && (
                      <RssFeedButton
                        type="button"
                        width="145px"
                        margin={'0px 0px 0px 10px'}
                        onClick={() => changeAnotherLink(resetForm)}
                        padding="5px 20px"
                      >
                        Try Another URL
                      </RssFeedButton>
                    )}
                  </LoaderWrapper>
                </div>
              </>
            )}

            {showImagePreview && (
              <>
                {!errors.fileSize &&
                  !isEmpty(values.rssurl) &&
                  '✓ HTML Link Successfully Submitted'}
                <ImagePreviewTab
                  articleData={{
                    id: contentId,
                    ...themeMessage,
                    link: link && link.url,
                  }}
                  isFromRssFeed={true}
                  handleContentOverviewClick={handleContentOverviewClick}
                  onUpdateTheme={onUpdateTheme}
                  isAdmin={isAdmin}
                  nextClick={() => onNextClickImagePreview(resetForm)}
                  themeMessage={themeMessage}
                  setThemeMessage={setThemeMessage}
                  userSummary={userSummary}
                  setUserSummary={setUserSummary}
                  setError={setErrorMessage}
                  error={errorMessge}
                />
              </>
            )}

            {!contentCreateError && showThemes && !showImagePreview && (
              <UploadSuccess
                hasTopicSelected={hasTopicSelected}
                isFromAddContent={autoPostVisibilityFlag}
                fetchedThemes={fetchedThemes}
                themesList={themesList}
                onUpdateTheme={onUpdateTheme}
                isUpdating={isUpdating}
                onExitClick={onExitClick}
                contentCreateError={contentCreateError}
                isFromRssFeed
                articleData={{
                  id: contentId,
                  ...themeMessage,
                  link: link && link.url,
                }}
                handleContentOverviewClick={handleContentOverviewClick}
                showContent={showContent}
                setTabIndex={setTabIndex}
                setShowContent={setShowContent}
                showInternalUse={showInternalUse}
                userData={userData}
                isAdmin={isAdmin}
                showContentOverview={showContentOverview}
                setShowContentOverview={setShowContentOverview}
                userSummary={userSummary}
                setUserSummary={setUserSummary}
                setError={setErrorMessage}
                error={errorMessge}
                // userSummary={userSummary || get(themeMessage, 'message', '')}
              />
            )}
            {showShare && (
              <Modal
                onRequestClose={() => {
                  setShowShare(false);
                  setUserSummary('');
                  localStorage.removeItem('emailData');
                }}
                shouldCloseOnOverlayClick
                isOpen={showShare}
                size="medium68"
              >
                <ShareContentContainer
                  fetchedThemes={fetchedThemes}
                  editable
                  {...themeMessage}
                  id={contentId}
                  secondaryId={secondaryId}
                  link={link.url}
                  submitButtonText="Share"
                  isFromAddContent
                  handleClose={onShareSuccess}
                  source="link"
                  asModal
                  description={userSummary}
                />
              </Modal>
            )}
            {contentCreateError && (
              <ErrorContainer align="center">
                {contentCreateError}
              </ErrorContainer>
            )}
          </FeedWrapper>
        );
      }}
    </Formik>
  );
};

export default RssFeed;
