import { styled } from '@mui/material';

export const HeaderTitle = styled('h4')(() => ({
  color: '#101828',
  font: '500 1rem/1.2rem Poppins,sans-serif',
  margin: 0,
  marginBottom: '0.25rem',
  textAlign: 'left',
}));

export const HeaderSubtitle = styled('p')(() => ({
  font: '400 0.875rem/1rem Poppins,sans-serif',
  margin: 0,
  textAlign: 'left',
  color: '#101828',
}));

export const SubCampaignListContainer = styled('div')(() => ({
  marginTop: '1rem',
  display: 'flex',
  flexDirection: 'column',
  gap: '1rem',
  overflowY: 'auto',
  overflowX: 'hidden',
  position: 'sticky',
  maxHeight: '360px',
  '&::-webkit-scrollbar': {
    width: '5px',
  },
}));

export const SubCampaignItem = styled('div')(({ isSelected = false }) => ({
  border: isSelected ? '3px solid #6250ED' : '1px solid #E4E7EC',
  background: '#fff',
  boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.12)',
  borderRadius: '0.5rem',
  display: 'flex',
  cursor: 'pointer',
}));

export const SubCampaignDetailsWrapper = styled('div')(() => ({
  flex: 1,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '1rem',
}));

export const SubCampaignName = styled('h2')(() => ({
  font: '500 0.875rem/1.2rem Poppins,sans-serif',
  margin: 0,
  marginBottom: '0.25rem',
  textAlign: 'left',
  color: '#101828',
}));

export const SubCampaignDateCreated = styled('p')(() => ({
  color: '#475467',
  font: '400 0.75rem/1rem Poppins,sans-serif',
  margin: 0,
  marginTpp: '0.25rem',
  textAlign: 'left',
}));

export const Platforms = styled('div')(() => ({
  display: 'flex',
  gap: '0.5rem',
  '& svg': {
    color: '#667085',
    fontSize: '1.5rem',
  },
}));

export const CampaignLogo = styled('img')(() => ({
  width: '80px',
  objectFit: 'cover',
  borderTopLeftRadius: '0.5rem',
  borderBottomLeftRadius: '0.5rem',
}));

export const SubCampaignHeader = styled('div')(() => ({
  display: 'flex',
  alignItems: 'flex-end',
  justifyContent: 'space-between',
  '& p': {
    cursor: 'pointer',
  },
}));
