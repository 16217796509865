/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import { Flex } from '@rebass/grid';
import React, { useState, useEffect } from 'react';
import { ColorPallete, Typography } from '../../../utils';
import { ContactGraphDisplayContainer, HeaderContainer } from '../../../componentsV2/GraphDisplay/styles';
import { CardTitle } from '../../../componentsV2/BarChartDisplay/styles';
import { BarGraph } from '../../../componentsV2/BarChart';
import {
  contentEngagementData
} from './content-data';
import ContentRecommendationCard from './ContentRecommendationCard';
import { TopicChipStyle } from './ContentTab.styles';
import ContentEngagementCard from './ContentEngagementCard';
import AnalyticsContentTable from '../AnalyticsTable/AnalyticsContentTable';
import TopPerformingContent from './TopPerformingContent';
import { getAnalyticsContentList, getAnalyticsLinkList, getAnalyticsTopicsList, getContentEntAnalyticsCampaignActivity, getContentGraphData, getContentSourceEngagement, getContentTopicsGraphData, getRecommendationData, getTopPerformingContent } from '../../../services/analyticsV2';
import { get, isEmpty } from 'lodash';
import Skeleton from 'react-loading-skeleton';
import ErrorDisplay from '../../../componentsV2/ErrorDisplay/ErrorDisplay';
import { graphColorPalette } from '../ContactsTab/contacts-data';
import { EmptyState, ProgressBar } from '../../../componentsV2';
import RecommendationModal from './RecommendationModal';
import { Tooltip } from '@mui/material';

const TopicDataTableHeaders = [
  {
    name: 'Topic',
    width: '35%',
  },
  {
    name: 'No. of Contacts',
    width: '18%',
  },
  {
    name: 'Content',
    width: '17%',
  },
  // {
  //   name: 'MoM',
  //   width: '15%',
  // },
  // {
  //   name: 'QoQ',
  //   width: '15%',
  // },
  {
    name: 'Engagement Score',
    width: '30%',
  },
];

const ContentDataTableHeaders = [
  {
    name: 'Name',
    width: '35%',
  },
  {
    name: 'No. of Contacts',
    width: '18%',
  },
  {
    name: 'Clicks',
    width: '17%',
  },
  {
    name: 'Engagement Score',
    width: '30%',
  },
];

const LinkDataTableHeaders = [
  {
    name: 'Link URL',
    width: '35%',
  },
  {
    name: 'No. of Contacts',
    width: '18%',
  },
  {
    name: 'Clicks',
    width: '17%',
  },
  {
    name: 'Engagement Score',
    width: '30%',
  },
];

const ContentTab = ({
  startDate,
  type,
  showEnterpriseDropdownSelectedOption,
  selectedUsers
}) => {
  const [contentTab, setContentTab] = useState(0);
  const [engagementSourceData, setEngagementSourceData] = useState([]);
  const [topPerformingContent, setTopPerformingContent] = useState([]);
  const [topContentLoading, setTopContentLoading] = useState(false);
  const [topContentError, setTopContentError] = useState(false);
  const [engagementSourceLoading, setEnagagementSourceLoading] = useState(false);
  const [engagementSourceError, setEngagementSourceError] = useState(false);
  const [mostEngagedFormatData, setMostEngagedFormatData] = useState([]);
  const [mostEngagedFormatLoading, setMostEngagedFormatLoading] = useState(false);
  const [mostEngagedFormatError, setMostEngagedFormatError] = useState(false);
  const [contentGraphLoading, setContentGraphLoading] = useState(false);
  const [contentGraphError, setContentGraphError] = useState(false);
  const [contentPerformanceGraphData, setContentPerformanceGraphData] = useState([]);
  const [contentRecommendationData, setContentRecommendationData] = useState([]);
  const [contentRecommendationLoading, setContentRecommendationLoading] = useState(false);
  const [contentRecommendationError, setContentRecommendationError] = useState(false);
  const [openRecommendationModal, setOpenRecommendationModal] = useState(false);
  const [contentTableLoading, setContentTableLoading] = useState(false);
  const [paginationData, setPaginationData] = useState(null);
  const [contentTableData, setContentTableData] = useState([]);
  const [maxPage, setMaxPage] = useState(null);
  const [errorDisplay, setErrorDisplay] = useState(false);
  const [pageIndex, setPageIndex] = useState(0);
  const [page, setPage] = useState(1);
  const [contentEntAnalyticsCampaignActivityData, setContentEntAnalyticsCampaignActivityData] = useState([]);

  const isEnterprise = showEnterpriseDropdownSelectedOption === 'My Enterprise' ? true : false;

  const limit = 10;

  const fetchEngagementSource = () => {
    setEnagagementSourceLoading(true);
    getContentSourceEngagement(startDate, isEnterprise, selectedUsers).then(res => {
      let engagementBarData = [];
      if(!isEmpty(get(res, 'result.data', []))) {
        get(res, 'result.data', []).forEach((item, index) => {
          console.log(contentEngagementData.filter(a => a.name === item?.source_name));
          engagementBarData.push({
            name: item?.source_name,
            'Engagement Count': item?.engagement_count,
            fill: !isEmpty(contentEngagementData.filter(a => a.name === item?.source_name)) ? contentEngagementData.filter(a => a.name === item?.source_name)[0].fill : null
          })
        });
        setEngagementSourceData(engagementBarData);
        setEnagagementSourceLoading(false);
        setEngagementSourceError(false);
      }  
    }).catch(err => {
      setEnagagementSourceLoading(false);
      setEngagementSourceError(true);
    })
  } 

  const fetchTopPerformingContent = () => {
    setTopContentLoading(true);
    getTopPerformingContent(startDate, 5, isEnterprise, selectedUsers)
      .then((res) => {
        setTopPerformingContent(get(res, 'result.data', []));
        setTopContentLoading(false);
        setTopContentError(false);
      })
      .catch((err) => {
        console.log('ERROR--->', err);
        setTopContentLoading(false);
        setTopContentError(true);
      });
  };

  const fetchMostEngagedContentFormat = () => {
    setMostEngagedFormatLoading(true);
    getRecommendationData("email", startDate, isEnterprise, selectedUsers)
      .then((res) => {
        setMostEngagedFormatData(get(res, 'result', []));
        setMostEngagedFormatLoading(false);
        setMostEngagedFormatError(false);
      })
      .catch((err) => {
        setMostEngagedFormatLoading(false);
        setMostEngagedFormatError(true);
      });
  };

  const processContentPerformanceGraphData = (data) => {
    let contentBarData = [];
    if (!isEmpty(Object.keys(data))) {
      Object.keys(data).forEach((item, index) => {
        contentBarData.push({
          name: data[item].theme_name,
          'Engagement Score': data[item].engagement_score,
          fill: graphColorPalette[index],
        });
      });
      setContentPerformanceGraphData(contentBarData);
    } else {
      setContentPerformanceGraphData([])
    }
  };

  const fetchContentGraph = () => {
    setContentGraphLoading(true);
    getContentGraphData(startDate, 9, isEnterprise, selectedUsers)
      .then((res) => {
        processContentPerformanceGraphData(get(res, 'result', []));
        setContentGraphLoading(false);
        setContentGraphError(false);
      })
      .catch((err) => {
        setContentGraphLoading(false);
        setContentGraphError(true);
      });
  };

  const fetchContentRecommendationData = () => {
    setContentRecommendationLoading(true);
    getContentGraphData(startDate, 3, isEnterprise)
      .then((res) => {
        setContentRecommendationData(get(res, 'result', []));
        setContentRecommendationLoading(false);
        setContentRecommendationError(false);
      })
      .catch((err) => {
        console.log('ERROR--->', err);
        setContentRecommendationLoading(false);
        setContentRecommendationError(true);
      });
  };

  useEffect(() => {
    if(startDate) {
      loadContentData(contentTab, 1);
      fetchTopPerformingContent();
      fetchEngagementSource();
      fetchMostEngagedContentFormat();
      fetchContentGraph();
      fetchContentRecommendationData();
      if (showEnterpriseDropdownSelectedOption === "My Enterprise" || !isEmpty(selectedUsers)) {
        fetchContentEntAnalyticsCampaignActivity();
      }
    }
  }, [startDate, selectedUsers, showEnterpriseDropdownSelectedOption]);

  const handleContentGraphRetryClick = () => {
    fetchContentGraph();
  };

  const handleContentRecommendationRetryClick = () => {
    fetchContentRecommendationData();
  };

  const handleContentEntAnalyticsCampaignActivityRetryClick = () => {
    fetchContentEntAnalyticsCampaignActivity();
  };

  const handleReleventClick = () => {
    setOpenRecommendationModal(true);
  }

  const onRecommendationModalClose = () => {
    setOpenRecommendationModal(false);
  }
  const handleContentTabChange = (tab) => {
    setContentTab(tab);
    setPage(1);
    setMaxPage(null);
    setPageIndex(0);
    setErrorDisplay(false);
    setPaginationData(null);

    loadContentData(tab, 1);
  };

  const fetchContentAnalyticsTopicsList = (pageNo) => {
    setContentTableLoading(true);
    getAnalyticsTopicsList(startDate, limit, pageNo, isEnterprise, selectedUsers).then(res => {
      setContentTableData(get(res, 'result.result', []));
      setPaginationData(get(res, 'result.pagination', {}));
      setMaxPage(Math.ceil(get(res, 'result.pagination.total_results', 0)/limit));
      setContentTableLoading(false);
      setErrorDisplay(false);
    }).catch(err => {
      console.log("ERROR--->", err);
      setContentTableLoading(false);
      setErrorDisplay(true);
    })
  };

  const fetchContentAnalyticsContentList = (pageNo) => {
    setContentTableLoading(true);
    getAnalyticsContentList(startDate, limit, pageNo, isEnterprise, selectedUsers).then(res => {
      setContentTableData(get(res, 'result.data', []));
      setPaginationData(get(res, 'result.pagination', {}));
      setMaxPage(Math.ceil(get(res, 'result.pagination.total_results', 0)/limit));
      setContentTableLoading(false);
      setErrorDisplay(false);
    }).catch(err => {
      console.log("ERROR--->", err);
      setContentTableLoading(false);
      setErrorDisplay(true);
    })
  };

  const fetchContentAnalyticsLinkList = (pageNo) => {
    setContentTableLoading(true);
    getAnalyticsLinkList(startDate, limit, pageNo, isEnterprise, selectedUsers).then(res => {
      setContentTableData(get(res, 'result.data', []));
      setPaginationData(get(res, 'result.pagination', {}));
      setMaxPage(Math.ceil(get(res, 'result.pagination.total_results', 0)/limit));
      setContentTableLoading(false);
      setErrorDisplay(false);
    }).catch(err => {
      console.log("ERROR--->", err);
      setContentTableLoading(false);
      setErrorDisplay(true);
    })
  };


  const fetchContentEntAnalyticsCampaignActivity = () => {
    setContentRecommendationLoading(true);
    getContentEntAnalyticsCampaignActivity(startDate, 10, selectedUsers)
      .then((res) => {
        console.log('campaign activity res--->', res);
        setContentEntAnalyticsCampaignActivityData(get(res, 'result', []));
        setContentRecommendationLoading(false);
        setContentRecommendationError(false);
      })
      .catch((err) => {
        console.log('ERROR', err);
        setContentRecommendationLoading(false);
        setContentRecommendationError(true);
      });
  };

  const loadContentData = (tab, pageNo) => {
    switch (tab) {
      case 0:
        fetchContentAnalyticsTopicsList(pageNo);
        break;
      case 1:
        fetchContentAnalyticsContentList(pageNo);
        break;
      case 2:
        fetchContentAnalyticsLinkList(pageNo);
        break;
      default:
        fetchContentAnalyticsTopicsList(pageNo);
        break;
    }
  };

  const handleRetryClick = () => {
    if (contentTab === 0) {
      fetchContentAnalyticsTopicsList();
    } else if (contentTab === 1) {
      fetchContentAnalyticsContentList();
    } else {
      fetchContentAnalyticsLinkList();
    }
  };

  const handlePageChange = (type) => {
    let pageNo;
    if (type === 'next') {
      pageNo = page + 1;
      setPage(pageNo);
    } else {
      pageNo = page - 1;
      setPage(pageNo);
    }

    loadContentData(contentTab, pageNo);
  };

  const handlePageClick = (pageNo) => {
    setPage(pageNo);

    loadContentData(contentTab, pageNo);
  };

  const onNextClick = () => {
    setPageIndex(pageIndex + 8);
  };

  const onPreviousClick = () => {
    setPageIndex(pageIndex - 8);
  };


  const tableViewForTopicsList = () => {
    return (
      <>
        {!contentTableLoading && errorDisplay === false && isEmpty(contentTableData) && (
          <EmptyState
            height="680px"
            title="No Analytics Data"
            subTitle="Send out campaigns to get insights"
          />
        )}

        {!isEmpty(contentTableData) &&
          !contentTableLoading &&
          errorDisplay === false &&
          contentTableData.map((item, index) => (
            <Flex
              width="100%"
              alignItems="center"
              backgroundColor={ColorPallete.white}
              style={{
                borderTop:
                  index !== 0 && `1px solid ${ColorPallete.neutral200}`,
                borderBottom:
                  index !== 9 && `1px solid ${ColorPallete.neutral200}`,
              }}
            >
              {item?.theme_name && (
                <Flex
                  width="35%"
                  padding="16px"
                  color={ColorPallete.neutral900}
                  fontSize={Typography.TextMedium}
                >
                  {item?.theme_name}
                </Flex>
              )}

              {(item?.contacts || item?.contacts === 0) && (
                <Flex
                  width="18%"
                  padding="16px"
                  color={ColorPallete.neutral900}
                  fontSize={Typography.TextMedium}
                >
                  {item.contacts}
                </Flex>
              )}

              {(item?.content || item?.content === 0) && (
                <Flex
                  width="17%"
                  padding="16px"
                  color={ColorPallete.neutral900}
                  fontSize={Typography.TextMedium}
                >
                  {item.content}
                </Flex>
              )}

              {(item?.engagement_score || item?.engagement_score === 0) && (
                <Flex width="30%" padding="16px">
                  <ProgressBar value={item.engagement_score} />
                </Flex>
              )}
            </Flex>
          ))}
        {!contentTableLoading && errorDisplay === true && (
          <ErrorDisplay height="680px" onClick={handleRetryClick} />
        )}
      </>
    );
  };

  const tableViewForContentList = () => {
    return (
      <>
        {!contentTableLoading &&
          errorDisplay === false &&
          isEmpty(contentTableData) && (
            <EmptyState
              height="680px"
              title="No Analytics Data"
              subTitle="Send out campaigns to get insights"
            />
          )}
        {!isEmpty(contentTableData) &&
          !contentTableLoading &&
          errorDisplay === false &&
          contentTableData.map((item, index) => (
            <Flex
              width="100%"
              alignItems="center"
              backgroundColor={ColorPallete.white}
              style={{
                borderTop:
                  index !== 0 && `1px solid ${ColorPallete.neutral200}`,
                borderBottom:
                  index !== 9 && `1px solid ${ColorPallete.neutral200}`,
              }}
            >
              <Flex
                width="35%"
                padding="16px"
                color={ColorPallete.neutral900}
                fontSize={Typography.TextMedium}
                textAlign="left"
              >
                {item?.name && item?.name?.length > 55 ? (
                  <Tooltip title={item.name} arrow placement="top">
                    <Flex>{`${item?.name.substring(0, 55)}...`}</Flex>
                  </Tooltip>
                ) : (
                  <Flex>{item?.name}</Flex>
                )}
              </Flex>
              {(item?.contact_count || item?.contact_count === 0) && (
                <Flex
                  width="18%"
                  padding="16px"
                  color={ColorPallete.neutral900}
                  fontSize={Typography.TextMedium}
                >
                  {item.contact_count}
                </Flex>
              )}

              {(item?.click || item?.click === 0) && (
                <Flex
                  width="17%"
                  padding="16px"
                  color={ColorPallete.neutral900}
                  fontSize={Typography.TextMedium}
                >
                  {item.click}
                </Flex>
              )}
              {(item?.engagement_score || item?.engagement_score === 0) && (
                <Flex width="30%" padding="16px">
                  <ProgressBar value={item.engagement_score} />
                </Flex>
              )}
            </Flex>
          ))}
        {!contentTableLoading && errorDisplay === true && (
          <ErrorDisplay height="680px" onClick={handleRetryClick} />
        )}
      </>
    );
  };

  const tableViewForLinkList = () => {
    return (
      <>
        {!contentTableLoading &&
          errorDisplay === false &&
          isEmpty(contentTableData) && (
            <EmptyState
              height="680px"
              title="No Analytics Data"
              subTitle="Send out campaigns to get insights"
            />
          )}
        {!isEmpty(contentTableData) &&
          !contentTableLoading &&
          errorDisplay === false &&
          contentTableData.map((item, index) => (
            <Flex
              width="100%"
              alignItems="center"
              backgroundColor={ColorPallete.white}
              style={{
                borderTop:
                  index !== 0 && `1px solid ${ColorPallete.neutral200}`,
                borderBottom:
                  index !== 9 && `1px solid ${ColorPallete.neutral200}`,
              }}
            >
              <Flex
                width="35%"
                padding="16px"
                color={ColorPallete.neutral900}
                fontSize={Typography.TextMedium}
                style={{ textAlign: 'left', textDecoration: 'none' }}
              >
                {item?.link && item?.link?.length > 53 ? (
                  <Tooltip title={item.link} arrow placement="top">
                    <Flex>
                      <a
                        href={item?.link}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ color: '#000',  textDecoration: 'none' }}
                      >
                        {`${item?.link.substring(0, 53)}...`}
                      </a>
                    </Flex>
                  </Tooltip>
                ) : (
                  <Flex>
                    <a
                      href={item?.link}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ color: '#000',  textDecoration: 'none' }}
                    >
                      {item?.link}
                    </a>
                  </Flex>
                )}
              </Flex>
              {(item?.contacts || item?.contacts === 0) && (
                <Flex
                  width="18%"
                  padding="16px"
                  color={ColorPallete.neutral900}
                  fontSize={Typography.TextMedium}
                >
                  {item?.contacts}
                </Flex>
              )}
              {(item?.clicks || item?.clicks === 0) && (
                <Flex
                  width="17%"
                  padding="16px"
                  color={ColorPallete.neutral900}
                  fontSize={Typography.TextMedium}
                >
                  {item?.clicks}
                </Flex>
              )}
              {(item?.engagement_score || item?.engagement_score === 0) && (
                <Flex width="30%" padding="16px">
                  <ProgressBar value={item?.engagement_score} />
                </Flex>
              )}
            </Flex>
          ))}
        {!contentTableLoading && errorDisplay === true && (
          <ErrorDisplay height="680px" onClick={handleRetryClick} />
        )}
      </>
    );
  };

  console.log("content graph data--->", contentPerformanceGraphData)

  return (
    <Flex width="100%" flexDirection="column">
      <Flex width="100%" justifyContent="space-between">
        <Flex
          width={
            showEnterpriseDropdownSelectedOption === 'My Enterprise' || !isEmpty(selectedUsers)
              ? '75%'
              : '73%'
          }
          borderRadius="8px"
          flexDirection="column"
          style={{
            border: `1px solid ${ColorPallete.neutral200}`,
            boxShadow: '0px 1px 2px 0px #1018281F',
            borderTopRightRadius:
              showEnterpriseDropdownSelectedOption === 'My Enterprise' || !isEmpty(selectedUsers)
                ? '0px'
                : '8px',
            borderBottomRightRadius:
              showEnterpriseDropdownSelectedOption === 'My Enterprise' || !isEmpty(selectedUsers)
                ? '0px'
                : '8px',
          }}
        >
          <HeaderContainer>
            <CardTitle
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              Topic Engagement
            </CardTitle>
          </HeaderContainer>
          <ContactGraphDisplayContainer width="100%" height="350px">
            {contentGraphLoading && (
              <Skeleton height={346} baseColor={ColorPallete.neutral100} />
            )}
            {!contentGraphLoading &&
              !contentGraphError &&
              isEmpty(contentPerformanceGraphData) && (
                <EmptyState
                  height="350px"
                  title="No Analytics Data"
                  subTitle="Send out campaigns to get insights"
                />
              )}
            {!contentGraphLoading &&
              contentGraphError === false &&
              !isEmpty(contentPerformanceGraphData) && (
                <BarGraph
                  data={contentPerformanceGraphData}
                  dataKey={['name', 'Engagement Score']}
                  noInterval
                />
              )}
            {!contentGraphLoading && contentGraphError === true && (
              <ErrorDisplay
                height={350}
                onClick={handleContentGraphRetryClick}
              />
            )}
          </ContactGraphDisplayContainer>
        </Flex>
        <Flex width="25%" style={{ boxShadow: '0px 1px 2px 0px #1018281F' }}>
          <ContentRecommendationCard
            title="Top Performing Topics"
            loading={contentRecommendationLoading}
            contentRecommendationData={contentRecommendationData}
            contentRecommendationError={contentRecommendationError}
            handleRetryClick={handleContentRecommendationRetryClick}
            onClick={handleReleventClick}
            showEnterpriseDropdownSelectedOption={
              showEnterpriseDropdownSelectedOption
            }
            selectedFirms={selectedUsers}
            handleCampaignActivityRetryClick={handleContentEntAnalyticsCampaignActivityRetryClick}
            contentEntAnalyticsCampaignActivityData={contentEntAnalyticsCampaignActivityData}
          />
        </Flex>
      </Flex>
      <Flex flexDirection="column" width="100%" mt="20px">
        <Flex color={ColorPallete.black} fontSize={Typography.Heading}>
          Insights
        </Flex>
        <Flex color={ColorPallete.neutral600} fontSize={Typography.TextMedium}>
          Uncover top performing content and what characteristics are driving
          the most engagement.
        </Flex>
      </Flex>
      <TopPerformingContent
        data={topPerformingContent}
        loading={topContentLoading}
        topPerformingContentError={topContentError}
        fetchTopContent={fetchTopPerformingContent}
      />
      <Flex width="100%" flexDirection="row" justifyContent="space-between">
        <Flex
          width="20%"
          mt="15px"
          height="270px"
          padding="16px"
          flexDirection="column"
          style={{
            border: `1px solid ${ColorPallete.neutral200}`,
            borderRadius: '8px',
            boxShadow: '0px 1px 2px 0px #1018281F',
            position: 'relative',
          }}
        >
          <Flex
            width="100%"
            paddingBottom="15px"
            color={ColorPallete.black}
            fontSize={Typography.TextLarge}
          >
            Most Engaged Format
          </Flex>
          {mostEngagedFormatLoading && (
            <Skeleton height={175} baseColor={ColorPallete.neutral100} />
          )}
          {!mostEngagedFormatLoading && mostEngagedFormatError === true && (
            <ErrorDisplay
              height="175px"
              onClick={fetchMostEngagedContentFormat}
            />
          )}
          {!mostEngagedFormatLoading && mostEngagedFormatError === false && (
            <Flex
              width="100%"
              flexDirection="column"
              style={{
                backgroundColor: '#F9FAFB',
                textAlign: 'left',
              }}
              padding="15px"
              height="175px"
            >
              <Flex
                width="100%"
                marginBottom="15px"
                style={{
                  textAlign: 'left',
                  fontSize: '14px',
                  color: '#475467',
                }}
              >
                You are seeing higher levels of engagement when you utilize:
              </Flex>
              <Flex
                style={{
                  flexDirection: 'row',
                  display: 'flex',
                  flexWrap: 'wrap',
                }}
              >
                <TopicChipStyle
                  style={{
                    fontSize: '15px',
                    alignItems: 'center',
                    display: 'flex',
                    border: '1px solid #FFCA99',
                    backgroundColor: '#FFF2E7',
                    color: '#EA780E',
                  }}
                >
                  {mostEngagedFormatData?.most_popular_format === 'N/A'
                    ? mostEngagedFormatData?.most_popular_format
                    : `${mostEngagedFormatData?.most_popular_format}s`}
                </TopicChipStyle>
              </Flex>
            </Flex>
          )}
        </Flex>
        <Flex width="78.2%" marginTop="15px">
          <ContentEngagementCard
            data={engagementSourceData}
            loading={engagementSourceLoading}
            error={engagementSourceError}
            onRetryClick={fetchEngagementSource}
          />
        </Flex>
      </Flex>

      <Flex flexDirection="column" width="100%" mt="20px">
        <Flex color={ColorPallete.black} fontSize={Typography.Heading}>
          Topic Data
        </Flex>
        <Flex
          color={ColorPallete.neutral600}
          fontSize={Typography.TextMedium}
          pt="3px"
        >
          Staying varied by topic is important, but be mindful of which topics
          are trending in the right direction.
        </Flex>
      </Flex>

      <Flex
        flexDirection="column"
        width="100%"
        mt="20px"
        style={{
          border: `1px solid ${ColorPallete.neutral200}`,
          boxShadow: '0px 1px 2px 0px #1018281F',
          borderRadius: '8px',
        }}
      >
        <Flex
          padding="16px 16px 5px"
          color={ColorPallete.neutral900}
          fontSize={Typography.Heading}
        >
          Topic Engagement
        </Flex>
        <Flex>
          {contentTableLoading && (
            <Skeleton width={100} baseColor={ColorPallete.neutral100} />
          )}
          {!isEmpty(paginationData) && !contentTableLoading && (
            <Flex
              padding="0px 16px 16px"
              color={ColorPallete.neutral600}
              fontSize={Typography.TextMedium}
              pt="3px"
            >
              {paginationData?.total_results}{' '}
              {paginationData?.total_results > 1 ? 'Records' : 'Record'}
            </Flex>
          )}
        </Flex>
        <Flex
          width="100%"
          style={{ borderTop: `1px solid ${ColorPallete.neutral200}` }}
        >
          <Flex
            padding="16px"
            style={{
              cursor: 'pointer',
              borderBottom:
                contentTab === 0 && `3px solid ${ColorPallete.neutral900}`,
            }}
            fontSize={Typography.TextLarge}
            color={
              contentTab === 0
                ? ColorPallete.neutral900
                : ColorPallete.neutral600
            }
            onClick={() => handleContentTabChange(0)}
          >
            Topics
          </Flex>
          <Flex
            padding="16px"
            style={{
              cursor: 'pointer',
              borderBottom:
                contentTab === 1 && `3px solid ${ColorPallete.neutral900}`,
            }}
            fontSize={Typography.TextLarge}
            color={
              contentTab === 1
                ? ColorPallete.neutral900
                : ColorPallete.neutral600
            }
            onClick={() => handleContentTabChange(1)}
          >
            Content
          </Flex>
          <Flex
            padding="16px"
            style={{
              cursor: 'pointer',
              borderBottom:
                contentTab === 2 && `3px solid ${ColorPallete.neutral900}`,
            }}
            fontSize={Typography.TextLarge}
            color={
              contentTab === 2
                ? ColorPallete.neutral900
                : ColorPallete.neutral600
            }
            onClick={() => handleContentTabChange(2)}
          >
            Links
          </Flex>
        </Flex>
        <AnalyticsContentTable
          headers={
            contentTab === 0
              ? TopicDataTableHeaders
              : contentTab === 1
              ? ContentDataTableHeaders
              : LinkDataTableHeaders
          }
          data={contentTableData}
          onPageChange={handlePageChange}
          page={page}
          maxPage={maxPage}
          loading={contentTableLoading}
          paginationData={paginationData}
          onPageClick={handlePageClick}
          pageIndex={pageIndex}
          setPageIndex={setPageIndex}
          handleNextClick={onNextClick}
          handlePreviousClick={onPreviousClick}
        >
          {contentTab === 0
            ? tableViewForTopicsList()
            : contentTab === 1
            ? tableViewForContentList()
            : tableViewForLinkList()}
        </AnalyticsContentTable>
      </Flex>

      {openRecommendationModal && (
        <RecommendationModal
          isOpen={openRecommendationModal}
          closeHandler={onRecommendationModalClose}
          startDate={startDate}
          data={contentRecommendationData}
        />
      )}
    </Flex>
  );
};

export default ContentTab;
