import styled from 'styled-components';
import { Flex, Box } from '@rebass/grid';

export const UploadFlex = styled(Flex)`
  border-bottom: 1px solid #a9adb0;
`;

export const UploadBox = styled(Box)`
  border-top: 1px solid #a9adb0;
  b {
    top: -8px;
    background-color: #e4e4e4;
    position: relative;
    padding: 0px 20px;
  }
`;

export const GroupBox = styled(Box)`
  border-left: 1px solid #a9adb0;
`;

export const DownloadSampleDiv = styled.div`
  text-align: left;
  padding-left: 20px;
  padding-top: 30px;
`;
export const AddMemberFlex = styled(Flex)`
  padding-top: 20px;
`;
