import React from 'react';
import { TableRowContent } from './styles';

export const tableColumnStructure = () => {
  return [
    {
      header: {
        key: 'name',
        title: 'Email List',
        enableSort: false,
      },
      width: '200px',
      render: (row) => (
        <div>
          <TableRowContent>{row?.name ?? ''}</TableRowContent>
        </div>
      ),
    },
    {
      header: {
        key: 'totalRecipients',
        title: 'Sent',
        enableSort: false,
      },
      width: '100px',
      render: (row) => (
        <div>
          <TableRowContent>{row?.totalRecipients ?? '-'}</TableRowContent>
        </div>
      ),
    },
    {
      header: {
        key: 'failed',
        title: 'Failed',
        enableSort: false,
      },
      width: '100px',
      render: (row) => (
        <div>
          <TableRowContent>{row?.failed ?? '-'}</TableRowContent>
        </div>
      ),
    },
  ];
};
