import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Flex } from '@rebass/grid';
import {
  Button,
  Modal,
  ModalCloseButton,
  ModalBody,
  LoaderWrapper,
} from '../../components';
import { getCompliancePDF } from '../../services/dashboard';
import { CCInput } from '../NewsletterContainer/Recipients/Recipients.styles';
import { get } from 'lodash';
// import { LoaderWrapper } from '../Loader';

const ExportModal = ({ dates, status, handleModalClose, userData }) => {
  const [emailList, setEmailList] = useState('');
  const [invalidEmailMsg, setInvalidEmailMsg] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [statusModal, setStatusModal] = useState({ visible: false, msg: '' });
  const [isLoading, setIsLoading] = useState(false);

  console.log(dates);

  const validateEmails = (value) => {
    setEmailList(value);
    const pattern =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,3}))$/;
    const isRecipientsValid = value
      .split(',')
      .map((email) => email.trim())
      .every((em) => pattern.test(em.toLowerCase()));
    // const validRecipients = recipients.filter((recipient) =>
    //   pattern.test(recipient)
    // );
    if (value.length == 0) {
      setErrorMsg('');
      setInvalidEmailMsg(false);
    } else if (!isRecipientsValid) {
      setInvalidEmailMsg(true);
      setErrorMsg('Invalid Email Address');
    } else {
      setInvalidEmailMsg(false);
      setErrorMsg('');
    }
  };

  const onDownloadPdf = () => {
    setIsLoading(true);
    let emails = [];
    if (emailList) {
      emails = emailList.split(',');
    }
    // emails.push(get(userData, 'details.user.email', 'advisor_email_address'));

    getCompliancePDF({
      startDate: dates.startDate,
      endDate: dates.endDate,
      status: status,
      emails: emails,
    })
      .then((res) => {
        setIsLoading(false);
        setStatusModal({
          visible: true,
          msg: 'Report generated successfully!',
        });
      })
      .catch((err) => {
        setIsLoading(false);
        setStatusModal({ visible: true, msg: 'Error! Something went wrong' });
      });
  };
  return (
    <>
      <Modal
        // onRequestClose={handleModalClose}
        shouldCloseOnOverlayClick
        isOpen
        size="medium"
      >
        <ModalCloseButton onClick={handleModalClose} />
        <ModalBody>
          <Flex width="100%" flexDirection="column" alignItems="center">
            <Flex>
              <span
                style={{
                  color: '#000',
                  fontSize: '22px',
                  padding: '5px 5px 0px',
                }}
              >
                Compliance Review Report
              </span>
            </Flex>
            <Flex>
              <span
                style={{ color: '#000', fontSize: '14px', padding: '0px 5px' }}
              >
                PDF Report of pending compliance items
              </span>
            </Flex>
          </Flex>
          <Flex
            width="100%"
            flexDirection="column"
            fontSize="12px"
            alignItems="center"
            style={{ fontWeight: 600 }}
            color="#000"
            padding="5px"
          >
            <div
              style={{
                padding: '5px 10px',
                width: '85%',
              }}
            >
              The report will be sent to{' '}
              {get(userData, 'details.user.email', 'advisor_email_address')}.
              List any additional email addresses to send it to.
            </div>
            <div
              style={{
                color: '#6351ed',
                fontSize: '0.7rem',
                fontWeight: '500',
              }}
            >
              {errorMsg}
            </div>
            <CCInput
              value={emailList}
              spellCheck="false"
              id="email_field"
              name="email_field"
              onChange={(e) => validateEmails(e.target.value)}
              placeholder="Type in email address(es), separated by commas"
              style={{
                width: '80%',
                backgroundColor: 'white',
                color: 'black',
              }}
            />
            <Flex>
              <span style={{ color: '#000', fontSize: '16px', padding: '5px' }}>
                Please allow up to 30 minutes for report generation.
              </span>
            </Flex>
          </Flex>
          <LoaderWrapper isLoading={isLoading}>
            <Button
              onClick={onDownloadPdf}
              bgColor={invalidEmailMsg ? '#AFAFAF' : ''}
              disabled={invalidEmailMsg}
              margin="0px"
            >
              Send Report
            </Button>
          </LoaderWrapper>
        </ModalBody>
      </Modal>
      <Modal
        // onRequestClose={handleModalClose}
        shouldCloseOnOverlayClick
        isOpen={statusModal.visible}
        size="small"
      >
        <ModalCloseButton
          onClick={() => {
            setStatusModal({ visible: false, msg: '' });
            handleModalClose();
          }}
        />
        <ModalBody>
          <Flex width="100%" flexDirection="column" alignItems="center">
            <Flex>
              <span style={{ color: '#000', fontSize: '16px', padding: '5px' }}>
                {statusModal.msg}
              </span>
            </Flex>
          </Flex>

          <Button
            onClick={() => {
              setStatusModal({ visible: false, msg: '' });
              handleModalClose();
            }}
          >
            Close
          </Button>
        </ModalBody>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => ({
  userData: state.user.data,
});

export default connect(mapStateToProps, null)(ExportModal);
