import { Flex } from '@rebass/grid';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from '../../../components';
import {
    TitleSpan,
  } from '../../DripCampaignContainerV2/CreateCampaign/CreateCampaign.styles';
  import {
    CongratsContainer,
    ContainerSubtitle,
  } from './CampaignCompleteCongrats.styles';


  const CampaignCompleteCongrats = ({
    endCreateFlow
  }) => {
    const history = useHistory();
    
    const redirectToDashboard = () => {
      endCreateFlow();
      history.push('/campaignv2');
    }
    return (
        <Flex justifyContent="center" alignItems="center">
            <CongratsContainer>
                <TitleSpan>Congrats!</TitleSpan>
                <ContainerSubtitle>The entire campaign series has been scheduled</ContainerSubtitle>
                <Button
                  borderRadius="5px"
                  margin="20px 0px 20px 0px;"
                  id="btn_one_time"
                  onClick={redirectToDashboard}
                >
                  Return to Dashboard
                </Button>
            </CongratsContainer>
        </Flex>
    );
};


export default CampaignCompleteCongrats;