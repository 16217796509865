import React from 'react';
import MemoFacebook from 'src/assets/icons/social-media/Facebook';
import MemoLinkedIn from 'src/assets/icons/social-media/LinkedIn';
import MemoTwitter from 'src/assets/icons/social-media/Twitter';

export const transformSeriesCampaignPreview = (res) => {
  return {
    template: res?.template?.template_html,
    subject: res?.newsletter?.customized_data.subject ?? '',
    platforms: {
      is_email: res?.is_email === 'true' ?? false,
      is_facebook: res?.is_facebook === 'true' ?? false,
      is_linkedin: res?.is_linkedin === 'true' ?? false,
      is_twitter: res?.is_twitter === 'true' ?? false,
    },
    posts: res?.posts ?? {},
    post_details: res?.posts_details ?? {},
  };
};
export const transformAutomatedCampaignPreview = (res) => {
  return {
    template: res?.template_html,
    subject: res?.name ?? '',
    platforms: {
      is_email: res?.platform === 'email' ?? false,
      is_facebook: res?.social_pages?.facebook ?? false,
      is_linkedin: res?.social_pages?.linkedin ?? false,
      is_twitter: res?.social_pages?.twitter ?? false,
    },
    articles: res?.articles ?? [],
    path: res?.path ?? '',
    mediaURL: res?.media_urls ?? {},
    overrideText: res?.override_text ?? {},
  };
};

export const previewPlatforms = [
  {
    label: 'Email',
    value: 'EMAIL',
  },
  {
    label: 'Social',
    value: 'SOCIAL',
  },
];

export const dropdownStyles = {
  '& .MuiSelect-select': {
    display: 'flex',
    alignItems: 'center',
    width: '100px',
    padding:'0.625rem !important',
    fontFamily: 'Poppins,sans-serif',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: 'rgba(0, 0, 0, 0.23)',
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: 'rgba(0, 0, 0, 0.23)',
    borderWidth: '1px',
  },
};

export const socialMediaPlatforms = (platforms) => [
  {
    label: 'Facebook',
    value: 'fb',
    icon: <MemoFacebook />,
    show: platforms?.is_facebook ?? false,
    mappingKey: 'facebook',
  },
  {
    label: 'LinkedIn',
    value: 'ln',
    icon: <MemoLinkedIn />,
    show: platforms?.is_linkedin ?? false,
    mappingKey: 'linkedin',
  },
  {
    label: 'Twitter',
    value: 'tw',
    icon: <MemoTwitter />,
    show: platforms?.is_twitter ?? false,
    mappingKey: 'twitter',
  },
];
