import { get } from 'lodash';
import { takeLatest, all, call, put } from 'redux-saga/effects';
import { actions as socialNetworkActions } from '../modules/socialNetwork.module';
import {
  getSocialAccountStatus,
  deleteAccountId,
} from '../../services/dashboard';

function* socialNetworkStatusRequest() {
  try {
    const response = yield call(getSocialAccountStatus);
    const data = get(response, 'result.data', {});
    yield put(socialNetworkActions.api.socialNetwork.response(data));
  } catch (error) {
    yield put(socialNetworkActions.api.socialNetwork.error(error));
  }
}

function* socialNetworkStatusDelete({ payload }) {
  try {
    yield call(deleteAccountId, get(payload, 'params', {}));
    yield put(socialNetworkActions.api.socialNetwork.request());
  } catch (error) {
    yield put(socialNetworkActions.api.socialNetwork.error(error));
  }
}

function* socialNetworkSaga() {
  yield all([
    takeLatest(
      socialNetworkActions.api.socialNetwork.request,
      socialNetworkStatusRequest
    ),
    takeLatest(
      socialNetworkActions.api.socialNetwork.delete,
      socialNetworkStatusDelete
    ),
  ]);
}

export default socialNetworkSaga;
