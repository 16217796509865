import { ColorPallete } from 'src/utils';
import styled from 'styled-components';

export const ForwardInput = styled.input`
    width: 100%;
    padding: 10px;
    border-radius: 4px;
    border: 1px solid ${ColorPallete.neutral200};
    box-shadow: 1px 2px 2px 0px rgba(16, 24, 40, 0.12) inset;
    font-size: 14px;
    color: ${ColorPallete.neutral900};
    ::placeholder {
        color: ${ColorPallete.neutral400};
        opacity: 1; /* Firefox */
    }
`;

export const ForwardTextArea = styled.textarea`
    width: 100%;
    height: 150px;
    padding: 10px;
    border-radius: 4px;
    border: 1px solid ${ColorPallete.neutral200};
    box-shadow: 1px 2px 2px 0px rgba(16, 24, 40, 0.12) inset;
    font-size: 14px;
    color: ${ColorPallete.neutral900};
    resize: none;
    ::placeholder {
        color: ${ColorPallete.neutral400};
        opacity: 1; /* Firefox */
    }
`;