import { styled } from '@mui/material';

export const Section = styled('div')(() => ({
  display: 'flex',
  alignItems: 'stretch',
  gap: '1rem',
}));

export const ImageSection = styled('div')(() => ({
  position: 'relative',
  width: '150px',
  height: '120px',
  background: '#ECF2FC',

  '&:hover': {
    '.overlay': {
      display: 'flex',
    },
  },
}));

export const ImageContainer = styled('div')(() => ({
  position: 'relative',
  width: '100%',
  height: '120px',
  borderRadius: '0.25rem',
  overflow: 'hidden',

  img: {
    objectFit: 'cover',
  },
}));

export const ImageUploadOverlay = styled('div')(() => ({
  position: 'absolute',
  inset: 0,
  width: '100%',
  height: '100%',
  display: 'none',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '0.5rem',
  background: 'rgba(25, 18, 86, 0.80)',
  cursor: 'pointer',
  zIndex: 2,

  h3: {
    fontSize: '0.75rem',
    fontWeight: '500',
    color: '#F9FAFB',
    margin: 0,
    textAlign: 'center',
  },
}));

export const EditIcon = styled('div')(() => ({
  position: 'absolute',
  top: '-0.625rem',
  right: '-0.625rem',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '0.12rem',
  background: '#F9FAFB',
  borderRadius: '0.25rem',
  zIndex: 2,

  svg: {
    fontSize: '1rem',
    color: '#6250ED',
  },
}));

export const Content = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '0.25rem',

  h5: {
    fontSize: '1rem',
    fontWeight: '500',
    color: '#101828',
    margin: 0,
    textAlign: 'left',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    maxWidth: '20ch',
    overflow: 'hidden',
  },

  p: {
    fontSize: '0.75rem',
    fontWeight: '400',
    color: '#101828',
    margin: 0,
    marginBottom: '1rem',
    textAlign: 'left',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    maxWidth: '30ch',
    overflow: 'hidden',
  },

  span: {
    fontSize: '0.75rem',
    fontWeight: '400',
    color: '#667085',
    margin: 0,
    textAlign: 'left',
  },
}));

export const Pills = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  flexWrap: 'wrap',
  gap: '0.5rem',
}));

export const Pill = styled('div')(() => ({
  width: 'fit-content',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: '0.25rem',
  padding: '0.25rem 0.5rem',
  fontSize: '0.75rem',
  fontWeight: '500',
  color: '#101828',
  margin: 0,
  textAlign: 'left',
  whiteSpace: 'nowrap',
  background: '#F9FAFB',
  border: '1px solid #E4E7EC',
  borderRadius: '0.75rem',
  userSelect: 'none',
}));
