import React, { useState } from 'react';
import { Wrapper } from './styles';
import { ADD_CONTENT_TABS } from './constants';
import TabList from 'src/componentsV2/Tablist';
import AddContentFromArticle from './AddContentFromArticle';
import FinalStep from './FinalStep';
import AddContentFromFile from './AddContentFromFile';

function AddContent({ channelId, setShowAddContentModal }) {
  const [step, setStep] = useState(0);
  const [selectedAddContentTab, setSelectedAddContentTab] = useState('article');
  const [content, setContent] = useState(null);

  return (
    <Wrapper>
      <TabList
        data={ADD_CONTENT_TABS.map((tab) => {
          if (step === 1 && tab?.value !== selectedAddContentTab) {
            return { ...tab, disabled: true };
          } else {
            return { ...tab, disabled: false };
          }
        })}
        activeTab={selectedAddContentTab}
        setActiveTab={(newValue) => {
          setSelectedAddContentTab(newValue);
          setStep(0);
        }}
        fullWidth={true}
        tabFullWidth={true}
      />
      {(() => {
        switch (step) {
          case 1:
            return (
              <FinalStep
                content={content}
                setShowAddContentModal={setShowAddContentModal}
                type={selectedAddContentTab}
              />
            );

          default:
            switch (selectedAddContentTab) {
              case 'file':
                return (
                  <AddContentFromFile
                    channelId={channelId}
                    setStep={setStep}
                    setContent={setContent}
                  />
                );
              default:
                return (
                  <AddContentFromArticle
                    channelId={channelId}
                    setStep={setStep}
                    setContent={setContent}
                  />
                );
            }
        }
      })()}
    </Wrapper>
  );
}

export default AddContent;
