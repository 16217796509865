import { Flex } from '@rebass/grid';
import React, { useEffect, useState } from 'react';
import {
  Button,
  LoaderWrapper,
  ModalBody,
  ModalCloseButton,
  ModalHeader,
  ModalV2,
} from '../../../components';
import {
  CheckBox,
  CheckBoxLabel,
  CheckBoxSpan,
  ContactListBody,
  ContactsListWrapper,
  ContactsTabItem,
  EmailSegmentListWrapper,
  SearchContainer,
  TabContainer,
} from './EmailList.styles';
import { get, isEmpty } from 'lodash';

import {
  approvedContactList,
  getContactsGroups,
} from '../../../services/configure';
import { getSegments, getSingularSegments } from '../../../services/segment';
import './style.css';
import AtsSearchBar from './AtsSearchBar/AtsSearchBar';
import ConfirmationMsg from './ConfirmationMsg';
import { Tooltip, Zoom } from '@mui/material';

const ContactListATSModal = ({
  isOpen,
  closeModalHandler,
  segmentListData,
  emailListData,
  setShowLastDataBlock,
  selectedEmailContacts,
  setSelectedEmailContacts,
  selectedSegmentContacts,
  setSelectedSegmentContacts,
  setDeselectedSegmentContacts,
  deselectedSegmentContacts,
  setDeselectedEmailContacts,
  deselectedEmailContacts,
  getApprovedEmailList,
  getApprovedSegmentList,
}) => {
  const [segmentContactsList, setSegmentContactsList] = useState([]);
  const [emailContactsList, setEmailContactsList] = useState([]);
  const [showFinalList, setShowFinalList] = useState(false);
  const [loading, setLoading] = useState(false);
  const [emailLoading, setEmailLoading] = useState(false);
  const [segmentLoading, setSegmentLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(50);
  const [pageNumberLimit, setpageNumberLimit] = useState(5);
  const [maxPageNumberLimit, setmaxPageNumberLimit] = useState(5);
  const [minPageNumberLimit, setminPageNumberLimit] = useState(0);
  const [emailPages, setEmailPages] = useState([]);
  const [segmentPages, setSegmentPages] = useState([]);
  const [searchString, setSearchString] = useState('');
  const [searchBoxInput, setSearchBoxInput] = useState('');
  const [openSearch, setOpenSearch] = useState(false);
  const [segmentTabActive, setSegmentTabActive] = useState(false);
  const [searchInput, setSearchInput] = useState('');
  const [show, setShow] = useState(false);

  useEffect(() => {
    setSearchBoxInput('');
  }, []);

  useEffect(() => {
    fetchEmail();
    fetchSegments();
  }, []);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  const currentItems = !segmentTabActive
    ? emailContactsList.slice(indexOfFirstItem, indexOfLastItem)
    : segmentContactsList.slice(indexOfFirstItem, indexOfLastItem);

  const handleClick = (e) => {
    setCurrentPage(Number(e.target.id));
  };

  const renderEmailPageNumbers = emailPages.map((number) => {
    if (number < maxPageNumberLimit + 1 && number > minPageNumberLimit) {
      return (
        <li
          key={number}
          id={number}
          onClick={handleClick}
          className={currentPage === number ? 'active' : null}
        >
          {number}
        </li>
      );
    } else {
      return null;
    }
  });

  const renderSegmentPageNumbers = segmentPages.map((number) => {
    if (number < maxPageNumberLimit + 1 && number > minPageNumberLimit) {
      return (
        <li
          key={number}
          id={number}
          onClick={handleClick}
          className={currentPage === number ? 'active' : null}
        >
          {number}
        </li>
      );
    } else {
      return null;
    }
  });

  const fetchEmail = async () => {
    setEmailLoading(true);
    try {
      const response = await getContactsGroups();
      setEmailContactsList(
        get(response, 'result.data', []).filter(
          (c) =>
            ![
              'GMAIL',
              'HUBSPOT',
              'OUTLOOK',
              'WEALTHBOX',
              'SALESFORCE',
              'REDTAIL',
            ].includes(get(c, 'name'))
        )
      );
      let pageNumbers = [];
      for (
        let i = 1;
        i <= Math.ceil(response.result.data.length / itemsPerPage);
        i++
      ) {
        pageNumbers.push(i);
      }
      setEmailPages(pageNumbers);
      setEmailLoading(false);
    } catch (err) {
      setEmailContactsList([]);
      setEmailLoading(false);
    }
  };

  const fetchSegments = async () => {
    setSegmentLoading(true);
    try {
      const response = await getSegments();
      setSegmentContactsList(response.result.data);
      let pageNumbers = [];
      for (
        let i = 1;
        i <= Math.ceil(response.result.data.length / itemsPerPage);
        i++
      ) {
        pageNumbers.push(i);
      }
      setSegmentPages(pageNumbers);
      setSegmentLoading(false);
    } catch (err) {
      setSegmentContactsList([]);
      setSegmentLoading(false);
    }
  };

  const handleContactsToggle = (flag) => {
    setmaxPageNumberLimit(5);
    setminPageNumberLimit(0);
    setSearchString('');
    setCurrentPage(1);
    setOpenSearch(false);
    if (flag) {
      setSegmentContactsList(segmentContactsList);
      handleSegmentSearch([]);
      //setSearchInput('');
      let pageNumbers = [];
      for (
        let i = 1;
        i <= Math.ceil(emailContactsList.length / itemsPerPage);
        i++
      ) {
        pageNumbers.push(i);
      }
      setEmailPages(pageNumbers);
    } else {
      setEmailContactsList(emailContactsList);
      handleEmailSearch([]);
      //setSearchString('');
      let pageNumbers = [];
      for (
        let i = 1;
        i <= Math.ceil(segmentContactsList.length / itemsPerPage);
        i++
      ) {
        pageNumbers.push(i);
      }
      setSegmentPages(pageNumbers);
    }
    setSegmentTabActive(flag);
  };

  const handleEmailCheckBoxChange = (item) => {
    if (isEmpty(selectedEmailContacts)) {
      let removeItems = deselectedEmailContacts.filter((s) => s.id !== item.id);
      setSelectedEmailContacts([...selectedEmailContacts, item]);
      setDeselectedEmailContacts(removeItems);
    } else if (
      !isEmpty(selectedEmailContacts) &&
      isEmpty(selectedEmailContacts.filter((s) => s.id === item.id))
    ) {
      let removeItems = deselectedEmailContacts.filter((s) => s.id !== item.id);
      setSelectedEmailContacts([...selectedEmailContacts, item]);
      setDeselectedEmailContacts(removeItems);
    } else {
      let removeItems = selectedEmailContacts.filter((s) => s.id !== item.id);
      setSelectedEmailContacts(removeItems);
      setDeselectedEmailContacts([...deselectedEmailContacts, item]);
    }
  };

  const handleSegmentCheckBoxChange = (item) => {
    if (isEmpty(selectedSegmentContacts)) {
      let removeItems = deselectedSegmentContacts.filter(
        (s) => s.id !== item.id
      );
      setSelectedSegmentContacts([...selectedSegmentContacts, item]);
      setDeselectedSegmentContacts(removeItems);
    } else if (
      !isEmpty(selectedSegmentContacts) &&
      isEmpty(selectedSegmentContacts.filter((s) => s.id === item.id))
    ) {
      let removeItems = deselectedSegmentContacts.filter(
        (s) => s.id !== item.id
      );
      setSelectedSegmentContacts([...selectedSegmentContacts, item]);
      setDeselectedSegmentContacts(removeItems);
    } else {
      let removeItems = selectedSegmentContacts.filter((s) => s.id !== item.id);
      setSelectedSegmentContacts(removeItems);
      setDeselectedSegmentContacts([...deselectedSegmentContacts, item]);
    }
  };

  const handleNextClick = () => {
    if (!showFinalList) {
      setShowFinalList(true);
    } else {
      //setShowLastDataBlock(true);
      createApprovedContacts();
    }
  };
  // --form 'contactlist_ids="{
  //   \"email_list_ids\":{\"add\": [\"Dw2ojQBZ\"]}, \"segment_list_ids\": {}

  const createApprovedContacts = () => {
    setLoading(true);
    let payload = {
      contactlist_ids: {
        email_list_ids: {},
        segment_list_ids: {},
      },
    };
    if (selectedEmailContacts.length > 0) {
      payload.contactlist_ids.email_list_ids.add = selectedEmailContacts.map(
        (i) => i.id
      );
    }
    if (deselectedEmailContacts.length > 0) {
      payload.contactlist_ids.email_list_ids.remove =
        deselectedEmailContacts.map((i) => i.id);
    }

    if (selectedSegmentContacts.length > 0) {
      payload.contactlist_ids.segment_list_ids.add =
        selectedSegmentContacts.map((i) => i.id);
    }
    if (deselectedSegmentContacts.length > 0) {
      payload.contactlist_ids.segment_list_ids.remove =
        deselectedSegmentContacts.map((i) => i.id);
      //setLoading(true);
    }

    payload.contactlist_ids = JSON.stringify(payload.contactlist_ids);
    approvedContactList(payload)
      .then((res) => {
        if (res) {
          setShow(true);
          setTimeout(() => {
            // fetchEmail();
            // fetchSegments();
            getApprovedEmailList();
            getApprovedSegmentList();
            closeModalHandler();
            setLoading(false);
            setShow(false);
          }, 10000);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log('ERROR-->', error);
      });
  };

  const handleSelectAll = (e) => {
    if (!segmentTabActive) {
      if (e.target.checked) {
        setSelectedEmailContacts([...emailContactsList]);
        setDeselectedEmailContacts([]);
      } else {
        setSelectedEmailContacts([]);
        setDeselectedEmailContacts(
          [...emailContactsList].filter((i) => i !== e.target.checked)
        );
      }
    } else {
      if (e.target.checked) {
        setSelectedSegmentContacts([...segmentContactsList]);
        setDeselectedSegmentContacts([]);
      } else {
        setSelectedSegmentContacts([]);
        setDeselectedSegmentContacts(
          [...segmentContactsList].filter((i) => i !== e.target.checked)
        );
      }
    }
  };

  const modalBackHandler = () => {
    setShowFinalList(false);
    setSelectedEmailContacts(selectedEmailContacts);
    setSelectedSegmentContacts(selectedSegmentContacts);
  };

  const getSegmentSearchData = async (searchText = null) => {
    setSegmentLoading(true);
    try {
      const response = await getSingularSegments(searchText);
      if (response?.result?.data.length < 1) {
        setSegmentContactsList([]);
        setLoading(false);
      } else {
        setSegmentContactsList(response?.result?.data);
        let pageNumbers = [];
        for (
          let i = 1;
          i <= Math.ceil(response?.result?.data?.length / itemsPerPage);
          i++
        ) {
          pageNumbers.push(i);
        }
        setSegmentPages(pageNumbers);
        setSegmentLoading(false);
      }
    } catch (err) {
      console.log('segment list search err:', err);
      setSegmentLoading(false);
    }
  };

  const getEmailSearchData = async (searhTerm) => {
    setEmailLoading(true);
    try {
      const response = await getContactsGroups(searhTerm);
      if (response?.result?.data.length < 1) {
        setEmailContactsList([]);
        setEmailLoading(false);
      } else {
        setEmailContactsList(response?.result?.data);
        let pageNumbers = [];
        for (
          let i = 1;
          i <= Math.ceil(response?.result?.data.length / itemsPerPage);
          i++
        ) {
          pageNumbers.push(i);
        }
        setEmailPages(pageNumbers);
        setEmailLoading(false);
      }
    } catch (err) {
      console.log('email list search error:', err);
      setEmailLoading(false);
    }
  };

  // const handleEmailSearch = (value = null) => {
  //   console.log('email search clicked--->');
  //   console.log('value', value);
  //   getEmailSearchData(value || searchBoxInput);
  //   let pageNumbers = [];
  //   for (
  //     let i = 1;
  //     i <=
  //     Math.ceil(emailListData.length / itemsPerPage);
  //     i++
  //   ) {
  //     pageNumbers.push(i);
  //   }
  //   setPages(pageNumbers);
  // };

  // const handleSegmentSearch = (value = null) => {
  //   getSegmentSearchData(value || searchBoxInput);
  //   let pageNumbers = [];
  //   for (
  //     let i = 1;
  //     i <=
  //     Math.ceil(segmentListData.length / itemsPerPage);
  //     i++
  //   ) {
  //     pageNumbers.push(i);
  //   }
  //   setPages(pageNumbers);

  // };

  const handleEmailListDelete = (item) => {
    setSelectedEmailContacts(
      selectedEmailContacts.filter((i) => i.id !== item.id)
    );
    setDeselectedEmailContacts([...deselectedEmailContacts, item]);
  };

  const handleSegmentListDelete = (item) => {
    setSelectedSegmentContacts(
      selectedSegmentContacts.filter((i) => i.id !== item.id)
    );
    setDeselectedSegmentContacts([...deselectedSegmentContacts, item]);
  };

  const handleNextPageClick = () => {
    setCurrentPage(currentPage + 1);
    if (currentPage + 1 > maxPageNumberLimit) {
      setmaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit);
      setminPageNumberLimit(minPageNumberLimit + pageNumberLimit);
    }
  };

  const handlePrevPageClick = () => {
    setCurrentPage(currentPage - 1);
    if ((currentPage - 1) % pageNumberLimit == 0) {
      setmaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit);
      setminPageNumberLimit(minPageNumberLimit - pageNumberLimit);
    }
  };

  const handleSegmentSearch = (value = null) => {
    if (segmentTabActive) {
      getSegmentSearchData(value || searchBoxInput);
    }
    setmaxPageNumberLimit(5);
    setminPageNumberLimit(0);
    setCurrentPage(1);
  };

  const handleEmailSearch = (value = null) => {
    if (!segmentTabActive) {
      getEmailSearchData(value || searchBoxInput);
    }
    setmaxPageNumberLimit(5);
    setminPageNumberLimit(0);
    setCurrentPage(1);
  };

  let pageIncrementBtn = null;
  if (!segmentTabActive) {
    if (emailPages.length > maxPageNumberLimit) {
      pageIncrementBtn = <li onClick={handleNextPageClick}> &hellip; </li>;
    }
  } else {
    if (segmentPages.length > maxPageNumberLimit) {
      pageIncrementBtn = <li onClick={handleNextPageClick}> &hellip; </li>;
    }
  }

  let pageDecrementBtn = null;
  if (minPageNumberLimit >= 1) {
    pageDecrementBtn = <li onClick={handlePrevPageClick}> &hellip; </li>;
  }

  return (
    <ModalV2
      onRequestClose={closeModalHandler}
      shouldCloseOnOverlayClick
      isOpen={isOpen}
      bgColor="#fff"
      width="50%"
    >
      <ModalHeader
        fontColor="#101828"
        paddingBottom="0px"
        padding="10px 10px 5px 15px"
        style={{
          fontSize: '18px',
          display: 'flex',
          justifyContent: 'flex-start',
        }}
      >
        {!showFinalList
          ? 'Create Approved to Share List'
          : 'Preview Approved to Share List'}
      </ModalHeader>
      <ModalCloseButton onClick={closeModalHandler} />
      {!showFinalList ? (
        <ModalBody padding="0px 15px">
          <Flex
            width="100%"
            height="520px"
            marginTop="10px"
            bgColor="#fff"
            display="flex"
            flexDirection="column"
            style={{
              borderRadius: '6px',
              border: '1px solid #bccdd4',
              backgroundColor: '#FFFFFF',
              paddingBottom: '12px',
            }}
          >
            <Flex
              display="flex"
              justifyContent="space-between"
              flexDirection="row"
              width="100%"
              padding="10px 12px"
            >
              <Flex
                display="flex"
                justifyContent="center"
                flexDirection="column"
              >
                <Flex color="#101828" fontSize="18px">
                  My Contact List & Segments
                </Flex>
                <Flex display="flex" flexDirection="row">
                  <Flex style={{ fontSize: '14px', color: '#475467' }}>
                    {/* 15 selected */}
                    {segmentTabActive
                      ? `${selectedSegmentContacts.length} selected`
                      : `${selectedEmailContacts.length} selected`}
                  </Flex>
                </Flex>
              </Flex>
              <SearchContainer
                style={{ justifyContent: 'end', width: '30%' }}
                searchActive={openSearch}
              >
                <AtsSearchBar
                  setActiveSearch={setOpenSearch}
                  activeSearch={openSearch}
                  searchString={searchString}
                  setSearchString={setSearchString}
                  searchHandler={
                    !segmentTabActive ? handleEmailSearch : handleSegmentSearch
                  }
                />
              </SearchContainer>
            </Flex>
            <TabContainer style={{ width: '100%' }}>
              <ContactsTabItem
                onClick={() => handleContactsToggle(false)}
                style={{
                  borderBottom: !segmentTabActive ? '1px solid #101828' : '0px',
                  color: !segmentTabActive ? '#000000' : '#aaa',
                }}
              >
                <div className="chip" style={{ fontSize: '14px' }}>
                  Email Lists
                </div>
              </ContactsTabItem>
              <ContactsTabItem
                onClick={() => handleContactsToggle(true)}
                style={{
                  borderBottom: segmentTabActive ? '1px solid #101828' : '0px',
                  color: segmentTabActive ? '#000000' : '#aaa',
                }}
              >
                <div className="chip" style={{ fontSize: '14px' }}>
                  Segments
                </div>
              </ContactsTabItem>
            </TabContainer>
            <Flex
              display="flex"
              width="100%"
              flexDirection="row"
              style={{
                backgroundColor: '#F9FAFB',
                fontSize: '15px',
                color: '#101828',
                height: '60px',
              }}
            >
              <CheckBoxSpan
                key={''}
                screenWidth={window.innerWidth}
                style={{
                  border: 'none',
                  margin: '0px 0px 4px 6px',
                  textAlign: 'left',
                  display: 'flex',
                  height: '60px',
                  alignItems: 'center',
                }}
              >
                <CheckBox
                  id="selectAll"
                  value="selectAll"
                  checked={
                    !segmentTabActive
                      ? !isEmpty(selectedEmailContacts) &&
                        selectedEmailContacts.length ===
                          emailContactsList.length
                      : !isEmpty(selectedSegmentContacts) &&
                        selectedSegmentContacts.length ===
                          segmentContactsList.length
                  }
                  onClick={handleSelectAll}
                />
                <CheckBoxLabel
                  fontSize="14px"
                  style={{ paddingLeft: '10%', paddingTop: '5px' }}
                  color="#848484"
                  htmlFor="selectAll"
                >
                  List Name
                </CheckBoxLabel>
              </CheckBoxSpan>
              <Flex
                width="30%"
                display="flex"
                style={{ marginTop: '8px', alignItems: 'center' }}
              >
                No. of Contacts
              </Flex>
            </Flex>
            {segmentTabActive ? (
              <>
                <Flex flexDirection="column" width="100%">
                  <ContactsListWrapper
                    padding="0px"
                    style={{ marginTop: '0px', height: '280px' }}
                  >
                    <LoaderWrapper isLoading={segmentLoading}>
                      <ContactListBody height="280px" maxHeight="280px">
                        {!isEmpty(currentItems) ? (
                          currentItems.length > 0 &&
                          currentItems.map((item, index) => (
                            <>
                              <Flex
                                width="100%"
                                style={{ flexDirection: 'row' }}
                              >
                                <CheckBoxSpan
                                  key={item.id}
                                  screenWidth={window.innerWidth}
                                  // checked={data.check}
                                  style={{
                                    border: 'none',
                                    margin: '9px 0px 4px 6px',
                                    textAlign: 'left',
                                  }}
                                >
                                  <CheckBox
                                    id={item.id}
                                    value={item.name}
                                    checked={
                                      !isEmpty(selectedSegmentContacts) &&
                                      selectedSegmentContacts.filter(
                                        (s) => s.id === item.id
                                      ).length > 0
                                    }
                                    onChange={() =>
                                      handleSegmentCheckBoxChange(item)
                                    }
                                  />
                                  <CheckBoxLabel
                                    fontSize="14px"
                                    style={{
                                      paddingLeft: '10%',
                                      paddingTop: '5px',
                                    }}
                                    htmlFor={item.id}
                                    color="#848484"
                                  >
                                    {item?.name && item?.name?.length > 50 ? (
                                      <Tooltip
                                        title={item?.name}
                                        placement="top"
                                        arrow
                                        TransitionComponent={Zoom}
                                      >
                                        {`${item?.name.substring(0, 50)}...`}
                                      </Tooltip>
                                    ) : (
                                      item?.name
                                    )}
                                  </CheckBoxLabel>
                                </CheckBoxSpan>
                                <Flex
                                  style={{
                                    width: '10%',
                                    display: 'flex',
                                    height: '25px',
                                    marginTop: '5px',
                                    marginLeft: '44px',
                                    color: '#101828',
                                    paddingTop: '11px',
                                    fontSize: '14px',
                                    alignItems: 'center',
                                  }}
                                >
                                  {item?.number_of_contact}
                                </Flex>
                              </Flex>
                              <hr
                                style={{
                                  width: '100%',
                                  border: '0.5px solid #e4e7ec',
                                  marginBottom: '0px',
                                }}
                              />
                            </>
                          ))
                        ) : (
                          <span
                            style={{
                              display: 'flex',
                              padding: '10px',
                              margin: '50px auto',
                            }}
                          >
                            No Records Found
                          </span>
                        )}
                      </ContactListBody>
                    </LoaderWrapper>
                  </ContactsListWrapper>
                </Flex>
                <hr
                  style={{
                    width: '100%',
                    border: '0.5px solid #E4E7EC',
                    margin: 'auto',
                  }}
                />
                <ul className="pageNumbers">
                  <Flex
                    justifyContent="space-between"
                    alignItems="center"
                    display="flex"
                    flexDirection="row"
                    padding="8px 14px 0px 14px"
                    width="100%"
                  >
                    <Button
                      type="button"
                      width="118px"
                      height="38px"
                      bgColor="#FFFFFF"
                      styles={{
                        border: '1px solid #E4E7EC',
                        borderRadius: '4px',
                        boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.12)',
                        color: '#101828',
                        display: 'flex',
                        alignItems: 'center',
                        padding: '0px',
                      }}
                      margin="0px"
                      onClick={handlePrevPageClick}
                      disabled={currentPage === segmentPages[0] ? true : false}
                      //onClick={() => paginationClickHandler('before')}
                      //disabled={page < 1 ? true : false}
                    >
                      <Flex
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                      >
                        <img
                          alt=""
                          src="/assets/images/ats-back-arrow.svg"
                          style={{ marginLeft: '6px', marginRight: '2px' }}
                        />
                        <Flex
                          style={{
                            display: 'flex',
                            padding: '5px',
                            fontSize: '14px',
                          }}
                        >
                          Previous
                        </Flex>
                      </Flex>
                    </Button>
                    <Flex
                      display="flex"
                      flexDirection="row"
                      alignItems="center"
                      padding="3px 5px 3px"
                      height="40px"
                      sytle={{ fontSize: '12px' }}
                    >
                      <li style={{ display: 'flex' }}>
                        {pageDecrementBtn}
                        {renderSegmentPageNumbers}
                        {pageIncrementBtn}
                      </li>
                    </Flex>
                    <Button
                      type="button"
                      width="94px"
                      height="38px"
                      styles={{
                        border: '1px solid #E4E7EC',
                        borderRadius: '4px',
                        boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.12)',
                        color: '#101828',
                        display: 'flex',
                        alignItems: 'center',
                        padding: '8px',
                      }}
                      bgColor="#FFFFFF"
                      margin="0px"
                      onClick={handleNextPageClick}
                      disabled={
                        currentPage === segmentPages[segmentPages.length - 1]
                          ? true
                          : false
                      }
                    >
                      <Flex
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                      >
                        <Flex
                          style={{
                            display: 'flex',
                            padding: '7px',
                            fontSize: '14px',
                          }}
                        >
                          Next
                        </Flex>
                        <img
                          alt=""
                          src="/assets/images/ats-next-arrow.svg"
                          style={{ marginLeft: '2px', marginRight: '6px' }}
                        />
                      </Flex>
                    </Button>
                  </Flex>
                </ul>
              </>
            ) : (
              <>
                <Flex flexDirection="column" width="100%">
                  <ContactsListWrapper
                    padding="0px"
                    style={{ marginTop: '0px', height: '280px' }}
                  >
                    <LoaderWrapper isLoading={emailLoading}>
                      <ContactListBody height="280px" maxHeight="280px">
                        {!isEmpty(currentItems) ? (
                          currentItems.length > 0 &&
                          currentItems.map((item, index) => (
                            <>
                              <Flex
                                width="100%"
                                style={{ flexDirection: 'row' }}
                              >
                                <CheckBoxSpan
                                  key={item.id}
                                  screenWidth={window.innerWidth}
                                  style={{
                                    border: 'none',
                                    margin: '9px 0px 4px 6px',
                                    textAlign: 'left',
                                  }}
                                >
                                  <CheckBox
                                    id={item.id}
                                    value={item.name}
                                    checked={
                                      !isEmpty(selectedEmailContacts) &&
                                      selectedEmailContacts.filter(
                                        (s) => s.id === item.id
                                      ).length > 0
                                    }
                                    onChange={() => {
                                      handleEmailCheckBoxChange(item);
                                    }}
                                  />
                                  <CheckBoxLabel
                                    fontSize="14px"
                                    style={{
                                      paddingLeft: '10%',
                                      paddingTop: '5px',
                                    }}
                                    htmlFor={item.id}
                                    color="#848484"
                                  >
                                    {item?.name && item?.name?.length > 50 ? (
                                      <Tooltip
                                        title={item?.name}
                                        placement="top"
                                        arrow
                                        TransitionComponent={Zoom}
                                      >
                                        {`${item?.name.substring(0, 50)}...`}
                                      </Tooltip>
                                    ) : (
                                      item?.name
                                    )}
                                  </CheckBoxLabel>
                                </CheckBoxSpan>
                                <Flex
                                  style={{
                                    width: '10%',
                                    display: 'flex',
                                    height: '25px',
                                    marginTop: '5px',
                                    marginLeft: '44px',
                                    color: '#101828',
                                    paddingTop: '9px',
                                    fontSize: '14px',
                                  }}
                                >
                                  {item?.contacts?.count}
                                </Flex>
                              </Flex>
                              <hr
                                style={{
                                  width: '100%',
                                  border: '0.5px solid #e4e7ec',
                                  marginBottom: '0px',
                                }}
                              />
                            </>
                          ))
                        ) : (
                          <span
                            style={{
                              display: 'flex',
                              padding: '10px',
                              margin: '50px auto',
                            }}
                          >
                            No Records Found
                          </span>
                        )}
                      </ContactListBody>
                    </LoaderWrapper>
                  </ContactsListWrapper>
                </Flex>
                <hr
                  style={{
                    width: '100%',
                    border: '0.5px solid #E4E7EC',
                    margin: 'auto',
                  }}
                />
                <ul className="pageNumbers">
                  <Flex
                    justifyContent="space-between"
                    alignItems="center"
                    display="flex"
                    flexDirection="row"
                    padding="8px 14px 0px 14px"
                    width="100%"
                  >
                    <Button
                      type="button"
                      width="118px"
                      height="38px"
                      bgColor="#FFFFFF"
                      styles={{
                        border: '1px solid #E4E7EC',
                        borderRadius: '4px',
                        boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.12)',
                        color: '#101828',
                        display: 'flex',
                        alignItems: 'center',
                        padding: '0px',
                      }}
                      margin="0px"
                      onClick={handlePrevPageClick}
                      disabled={currentPage === emailPages[0] ? true : false}
                      //onClick={() => paginationClickHandler('before')}
                      //disabled={page < 1 ? true : false}
                    >
                      <Flex
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                      >
                        <img
                          alt=""
                          src="/assets/images/ats-back-arrow.svg"
                          style={{ marginLeft: '6px', marginRight: '2px' }}
                        />
                        <Flex
                          style={{
                            display: 'flex',
                            padding: '5px',
                            fontSize: '14px',
                          }}
                        >
                          Previous
                        </Flex>
                      </Flex>
                    </Button>
                    <Flex
                      display="flex"
                      flexDirection="row"
                      alignItems="center"
                      padding="3px 5px 3px"
                      height="40px"
                      sytle={{ fontSize: '12px' }}
                    >
                      <li style={{ display: 'flex' }}>
                        {pageDecrementBtn}
                        {renderEmailPageNumbers}
                        {pageIncrementBtn}
                      </li>
                    </Flex>
                    <Button
                      type="button"
                      width="94px"
                      height="38px"
                      styles={{
                        border: '1px solid #E4E7EC',
                        borderRadius: '4px',
                        boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.12)',
                        color: '#101828',
                        display: 'flex',
                        alignItems: 'center',
                        padding: '8px',
                      }}
                      bgColor="#FFFFFF"
                      margin="0px"
                      onClick={handleNextPageClick}
                      disabled={
                        currentPage == emailPages[emailPages.length - 1]
                          ? true
                          : false
                      }
                    >
                      <Flex
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                      >
                        <Flex
                          style={{
                            display: 'flex',
                            padding: '7px',
                            fontSize: '14px',
                          }}
                        >
                          Next
                        </Flex>
                        <img
                          alt=""
                          src="/assets/images/ats-next-arrow.svg"
                          style={{ marginLeft: '2px', marginRight: '6px' }}
                        />
                      </Flex>
                    </Button>
                  </Flex>
                </ul>
              </>
            )}
          </Flex>
        </ModalBody>
      ) : (
        <ModalBody>
          <EmailSegmentListWrapper>
            <Flex
              display="flex"
              width="100%"
              justifyContent="center"
              flexDirection="column"
            >
              <Flex display="flex" flexDirection="row">
                <Flex>Email Lists</Flex>
                <hr style={{ width: '88%', marginTop: '9px' }} />
              </Flex>
              <Flex display="flex" flexDirection="column" width="100%">
                {!isEmpty(selectedEmailContacts) &&
                  selectedEmailContacts.length > 0 &&
                  selectedEmailContacts.map((item, index) => (
                    <Flex display="flex" flexDirection="row" width="100%">
                      <Flex
                        width="80%"
                        display="flex"
                        justifyContent="flex-start"
                        style={{
                          color: '#101828',
                          fontSize: '14px',
                          padding: '10px 5px',
                          wordBreak: 'break-all',
                          textAlign: 'left',
                        }}
                      >
                        {item?.name && item?.name?.length > 60 ? (
                          <Tooltip
                            title={item?.name}
                            placement="top"
                            arrow
                            TransitionComponent={Zoom}
                          >
                            {`${item?.name.substring(0, 60)}...`}
                          </Tooltip>
                        ) : (
                          item?.name
                        )}
                      </Flex>
                      <Flex
                        width="20%"
                        display="flex"
                        justifyContent="flex-end"
                        style={{ padding: '10px 20px 10px 0px' }}
                      >
                        <img
                          key={`string${index}`}
                          alt=""
                          src="/assets/images/new-delete-icon.svg"
                          style={{
                            height: '24px',
                            width: '24px',
                            cursor: 'pointer',
                          }}
                          onClick={() => handleEmailListDelete(item)}
                        />
                      </Flex>
                    </Flex>
                  ))}
                {isEmpty(selectedEmailContacts) && (
                  <Flex
                    display="flex"
                    width="100%"
                    alignItems="center"
                    height="150px"
                    padding="10px"
                    fontSize="14px"
                    color="#101828"
                    justifyContent="center"
                  >
                    Please add some Email Contacts to view list
                  </Flex>
                )}
              </Flex>
            </Flex>
            <Flex
              display="flex"
              width="100%"
              justifyContent="center"
              flexDirection="column"
            >
              <Flex display="flex" flexDirection="row">
                <Flex>Segment Lists</Flex>
                <hr style={{ width: '85%', marginTop: '9px' }} />
              </Flex>
              <Flex display="flex" flexDirection="column" width="100%">
                {!isEmpty(selectedSegmentContacts) &&
                  selectedSegmentContacts.length > 0 &&
                  selectedSegmentContacts.map((item, index) => (
                    <Flex display="flex" flexDirection="row" width="100%">
                      <Flex
                        width="80%"
                        display="flex"
                        justifyContent="flex-start"
                        style={{
                          color: '#101828',
                          fontSize: '14px',
                          padding: '10px 5px',
                          wordBreak: 'break-all',
                          textAlign: 'left',
                        }}
                      >
                        {item?.name && item?.name?.length > 60 ? (
                          <Tooltip
                            title={item?.name}
                            placement="top"
                            arrow
                            TransitionComponent={Zoom}
                          >
                            {`${item?.name.substring(0, 60)}...`}
                          </Tooltip>
                        ) : (
                          item?.name
                        )}
                      </Flex>
                      <Flex
                        width="20%"
                        display="flex"
                        justifyContent="flex-end"
                        style={{ padding: '10px 20px 10px 0px' }}
                      >
                        <img
                          alt=""
                          src="/assets/images/new-delete-icon.svg"
                          style={{
                            height: '24px',
                            width: '24px',
                            cursor: 'pointer',
                          }}
                          onClick={() => handleSegmentListDelete(item)}
                        />
                      </Flex>
                    </Flex>
                  ))}
                {isEmpty(selectedSegmentContacts) && (
                  <Flex
                    display="flex"
                    width="100%"
                    alignItems="center"
                    height="150px"
                    padding="10px"
                    fontSize="14px"
                    color="#101828"
                    justifyContent="center"
                  >
                    Please add some Segment Contacts to view list
                  </Flex>
                )}
              </Flex>
            </Flex>
          </EmailSegmentListWrapper>
        </ModalBody>
      )}

      <hr
        style={{
          width: '100%',
          border: '1px solid #E4E7EC',
          margin: '20px auto 8px',
        }}
      />
      {show && (
        <ConfirmationMsg
          severity="success"
          title="Approved to Share List Updated Successfully"
        />
      )}
      <LoaderWrapper isLoading={showFinalList ? loading : null}>
        <Flex
          display="flex"
          justifyContent="space-between"
          flexDirection="row"
          style={{ marginBottom: '10px' }}
        >
          <Button
            style={{ borderRadius: '4px' }}
            height="40px"
            width="250px"
            margin="9px 10px 0px 15px"
            padding="5px 28px"
            bgColor="#fff"
            color="#101010"
            borderColor="#E4E7EC"
            borderRadius="4px"
            onClick={!showFinalList ? closeModalHandler : modalBackHandler}
          >
            Back
          </Button>
          <Button
            style={{ borderRadius: '4px' }}
            height="40px"
            width="250px"
            margin="9px 12px 0px 0px"
            padding="5px 28px"
            bgColor="#816EF8"
            color="#fff"
            borderColor="#816EF8"
            borderRadius="4px"
            onClick={handleNextClick}
            disabled={
              isEmpty(selectedEmailContacts) && isEmpty(selectedSegmentContacts)
            }
          >
            {!showFinalList ? 'Continue' : 'Confirm'}
          </Button>
        </Flex>
      </LoaderWrapper>
    </ModalV2>
  );
};

export default ContactListATSModal;
