import React from 'react';
import { Flex } from '@rebass/grid';
import { Button } from '../../components';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { LoaderWrapper } from '../../components';
import { useState } from 'react';
import { ROLE_TYPES } from '../../utils';

const ConfirmationPage = ({
  csvData,
  roleType
}) => {
  const [loading, setLoading] = useState(false);

  const history = useHistory();

  useEffect(() => {
    const windowBodyClassList = document.body.classList;
    console.log('body-', windowBodyClassList);
    if (!windowBodyClassList.contains('campaign')) {
      windowBodyClassList.add('campaign');
    }

    return () => {
      windowBodyClassList.remove('campaign');
    };
  }, []);
  return (
    <Flex
      width="100%"
      color="#101010"
      display="flex"
      justifyContent="center"
      flexDirection="column"
    >
      <Flex
        width="100%"
        color="#101010"
        display="flex"
        justifyContent="center"
        margin="150px auto 0px"
        fontSize="18px"
      >
        Email list created successfully!
      </Flex>
      <Flex justifyContent="center" padding="20px">
        <img alt=" " src="/assets/images/Success.svg" />
      </Flex>
      <Flex
        width="20%"
        color="#101010"
        fontSize="14px"
        justifyContent="center"
        margin="0px auto"
      >
        Your email list with ({csvData.length}) of contact was created successfully.
      </Flex>
      <LoaderWrapper isLoading={loading}>
        <Flex justifyContent="center">
          <Button
            width="23%"
            borderRadius="4px"
            onClick={() => {
              setLoading(true);
              if (
                [
                  ROLE_TYPES.ADMIN_COMPLIANCE,
                  ROLE_TYPES.ADMIN_ENTERPRISE,
                  ROLE_TYPES.ADMIN_ASSET_MANAGER,
                ].includes(roleType)
              ) {
                history.push('/admin/configure');
              } else {
                history.push('/configure');
              }
              
            }}
          >
            Continue
          </Button>
        </Flex>
      </LoaderWrapper>
    </Flex>
  );
};

export default ConfirmationPage;
