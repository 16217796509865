import { createSelector } from 'reselect';
import { get, toLower, isEmpty } from 'lodash';
import { USER_ROLE, ROLE_TYPES } from '../../utils';

export const getLoginDetails = (state) => get(state, 'user.data', {});

export const getUserDetails = createSelector(getLoginDetails, (state) =>
  get(state, 'details.user', {})
);

export const getUserPermissions = createSelector(getLoginDetails, (state) =>
  get(state, 'permissions', {})
);

export const getUserRole = createSelector(getUserDetails, (details) => {
  return toLower(get(details, 'role', ''));
});

export const getUserId = (state) => get(state, 'user.data.uid', '');

export const getChannelId = (state) => get(state, 'channel.premiumAdvisorChannelId', '') || get(state, 'channel.data[0].id', '');

export const getRoleType = createSelector(
  [getLoginDetails, getUserDetails, getUserRole, getUserPermissions],
  (loginDetails, userDetails, role, permissions) => {
    const billingDetails = get(userDetails, 'billing', {});
    const membershipType = get(billingDetails, 'membership_type');
    const subscriptionType = get(billingDetails, 'subscription_type');
    const complianceContent = get(loginDetails, 'only_compliance_content');
    let roleType = '';
    if (toLower(role) === USER_ROLE.general) {
      // TODO: the default once the API issue is resolved
      roleType = ROLE_TYPES.ADVISOR_FREE;
      if (toLower(membershipType) === 'paid' && !complianceContent) {
        roleType = ROLE_TYPES.ADVISOR_PREMIUM;
      }

      if (toLower(membershipType) === 'paid' && complianceContent) {
        roleType = ROLE_TYPES.ADVISOR_ENTERPRISE;
      }
      if (toLower(membershipType) === 'free') {
        roleType = ROLE_TYPES.ADVISOR_FREE;
      }
      if (
        !complianceContent &&
        subscriptionType === 'asset_manager_sponsored'
      ) {
        roleType = ROLE_TYPES.ADVISOR_ASSET_MANAGER;
      }
    }

    if (toLower(role) === USER_ROLE.admin) {
      const roleId = get(permissions, 'details.role_id');
      if ([roleId].includes(107) && complianceContent) {
        roleType = ROLE_TYPES.ADMIN_COMPLIANCE;
      } else if (complianceContent && subscriptionType === 'platform_sponsored') {
        roleType = ROLE_TYPES.ADMIN_ENTERPRISE;
      } else {
        roleType = ROLE_TYPES.ADMIN_ASSET_MANAGER;
      }
    }
    // console.log('===roleType', roleType);
    return roleType;
  }
);

export const getFirmSettings = ({ isEnterpriseLevelAdmin, isFirmLevelAdmin, state}) => {
  let firmSettings = {};
  if(isEnterpriseLevelAdmin) 
    firmSettings = get(state, 'user.data.details.user.extra_settings.firm_settings', {});
  else if(isFirmLevelAdmin) {
    firmSettings = get(state, 'user.data.details.company.settings.firm_settings', {});
    // if(!isEmpty(firmSettings)){
    //   try {
    //     firmSettings = get(JSON.parse(firmSettings), 'firm_settings', {});
    //   }
    //   catch(e) {
    //     firmSettings = {};
    //   }
    // }
  }
  return firmSettings;
}
