import { get } from 'lodash';
import moment from 'moment';
import apiInstance from '../api';

export const getArticlesData = (dates, currentPopUp) => {
  let dateRange = JSON.stringify({
    from_date: moment(dates.startDate).format('YYYY-MM-DD'),
    to_date: moment(dates.endDate).format('YYYY-MM-DD'),
  });
  let articleType = currentPopUp === 'isSharedArticle' ? 'scheduled' : 'shared';
  return apiInstance()
    .get(`/user/${articleType}/content/?filter=${dateRange}`)
    .then((response) => {
      return get(response, 'result.data', []);
    });
};

export const sendReportData = (articleIds, emailIds, dates) => {
  let emails = emailIds.split(',').map((email) => email.trim());
  let formData = new FormData();
  formData.append('engagement_ids', JSON.stringify(articleIds));
  formData.append('email_ids', JSON.stringify(emails));
  formData.append('start_date', moment(dates.startDate).format('YYYY-MM-DD'));
  formData.append('end_date', moment(dates.endDate).format('YYYY-MM-DD'));
  return apiInstance()
    .post('sharereport', formData)
    .then((response) => {
      return get(response, 'result', '');
    });
};
