import styled from 'styled-components';

export const StatusBubble = styled.div`
  height: 10px;
  width: 10px;
  border-radius: 5px;
  padding: 2px;
  border: 0px;
  margin-right: 5px;
  background-color: ${({ status }) =>
    status === 'Completed'
      ? '#33B21B'
      : status === 'Scheduled'
      ? '#00CCF2'
      : status === 'In Progress'
      ? '#326DE6'
      : '#FFAA00'};
`;

export const StatBubbleWrapper = styled.div`
  border: 1px solid #b8cdd6;
  border-radius: 20px;
  padding: 1px;
  background-color: #eff2f5;
  margin-top: 8px;
`;

export const StatBubble = styled.div`
  width: 20px;
  height: 20px;
  color: #4e6780;
  font-size: 14px;
`;

export const VerticalSeparator = styled.div`
  border: 0.1px solid #cfd9e4;
  height: ${({ height }) => height || '30px'};
  margin-left: 10px;
  margin-right: 10px;
  margin: ${({margin}) => margin ? margin : '0px 10px 0px 10px'};
`;

export const SubCampaignSeparator = styled.div`
  width: 100%;
  height: 65px;
  /* background: black; */
  border-left: 2px solid #d6d6d6;
  border-bottom: 2px solid #d6d6d6;
  margin-left: 20px;
`;

export const SubCampaignVerticalSep = styled.div`
  width: 100%;
  height: 65px;
  /* background: black; */
  border-left: 2px solid #d6d6d6;
  margin-left: 20px;
`;

export const PendingCount = styled.div`
  border: 2px solid #f6d184;
  padding: 5px 0px;
  width: 35px;
  /* height: 27px; */
  border-radius: 5px;
  margin-left: 5px;
  /* margin-right: 15px; */
  background-color: #fdf6e7;
`;

export const TooltipDiv = styled.div.attrs({className: 'new-tooltip'})`
  position: relative;

  .newtooltiptext {
    visibility: hidden;
    width: 300px;
    background-color: #000;
    color: #fff;
    text-align: center;
    justify-content: center;
    border-radius: 6px;
    padding: 0px 0px 0px 20px; 
    position: absolute;
    bottom: 65%;
    right: -14.9em;
    margin-left: -16px;
  }

  .newtooltiptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: black transparent transparent transparent;
  }

  &:hover .newtooltiptext {
    visibility: visible;
  }
`;
