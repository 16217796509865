import React from 'react';
import { WidgetTile, Tabs, TabList, Tab, TabPanel } from '../../../components';
import { IconDiv } from '../DashboardContainer.styles';
import { company } from '../../../utils/constants';

import FromYourSponsor from './FromYourSponsor';

const FeaturedPosts = ({
  shareArticleRequest,
  shareError,
  isShareBlocked,
  setModalOpen,
  shareArticleSetState,
  themeNames,
  popularThemes,
  userData,
  sponsoredArticles,
  setSponsoredArticles,
  bucketCountRequest,
}) => (
  <WidgetTile heading="Relevant Insights" theme="light">
    <IconDiv
      isClickable
      style={{ top: '6px', color: '#6351ed' }}
      onClick={() => setModalOpen(true)}
      data-testid="relevant_insights"
    >
      <img
        src="/assets/images/Expand.svg"
        alt="Expand"
        style={{ height: '16px' }}
      />
    </IconDiv>
    <Tabs>
      <TabList style={{ display: 'none' }}>
        <Tab>From {company.name} Sponsors</Tab>
      </TabList>
      <TabPanel>
        <FromYourSponsor
          isShareBlocked={isShareBlocked}
          shareArticleSetState={shareArticleSetState}
          themeNames={themeNames}
          popularThemes={popularThemes}
          userData={userData}
          sponsoredArticles={sponsoredArticles}
          setSponsoredArticles={setSponsoredArticles}
          bucketCountRequest={bucketCountRequest}
        />
      </TabPanel>
    </Tabs>
  </WidgetTile>
);

export default FeaturedPosts;
