import styled from 'styled-components';

export const MioDatePickerWrapper = styled.div`
  background: #f7f7f7;
  color: #6351ed;
  padding: ${({ type }) => (type === 'resheduled' ? '10px 14px' : '10px 15px')};
  border-radius: ${({ type }) => (type === 'resheduled' ? '11px' : '5px')};
  text-align: center;
  margin-top: ${({ type }) => (type === 'resheduled' ? '-130px' : '')};
  margin-left: ${({ type }) => (type === 'resheduled' ? '-90px' : '')};
  .date-picker-container {
    position: relative;
    margin-bottom: ${({ type }) => (type === 'resheduled' ? '2px' : '10px')};
    overflow-y: auto;
    border: 1px solid #d4d4d4 !important;
    border-radius: 3px !important;
    -webkit-border-radius: 3px !important;
    &:after {
      clear: both;
      display: table;
      content: ' ';
    }
  }
  .react-datepicker__header {
    background-color: transparent;
    border-bottom: 1px solid #ededed;
  }
  .react-datepicker__day--disabled {
    opacity: 0.35;
  }
  .react-datepicker__day {
    color: #929292;
    width: 25px;
    height: 9px;
    line-height: 9px;
    font-size: 10px;
    padding: 5px 5px;
  }
  .react-datepicker__day-name {
    text-align: center;
    font-weight: 100;
    border: 0;
    color: #929292;
    font-size: 12px;
  }
  .react-datepicker__day--today {
    color: #ffffff;
    background: #b1a1d0 !important;
    border-radius: 40%;
    height: 16px;
  }
  .react-datepicker__day--selected {
    border-radius: 50%;
    background-color: #6351ed !important;
    color: #fff;
    height: 21px;
    padding: ${({ fromMyClients }) => (fromMyClients ? '6px' : '')};
    width: ${({ fromMyClients }) => (fromMyClients ? '21px' : '25px')};
    margin: 0 auto;
  }
  .react-datepicker__current-month {
    color: #6351ed;
    font-weight: normal;
    font-size: 1.3em;
  }
  .schedule-button {
    background-color: #6351ed;
    color: #fff;
    padding: 5px;
    border: 0;
  }
  select {
    color: #6351ed;
    padding: 4px 3px;
    border-radius: 10px;
    margin-right: 8px;
    font-size: 11px;
    font-weight: normal;
    font-family: 'Poppins';
  }
  .date-picker-container .react-datepicker__navigation {
    padding: 0px !important;
  }
  .react-datepicker__day--keyboard-selected {
    background: transparent;
  }
`;
