/* eslint-disable react-hooks/exhaustive-deps */
import { Flex } from '@rebass/grid';
import { get, isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  ApprovalCard,
  CheckboxInput,
  Chips,
  InputLabel,
  RadioInput,
  RadioInputLabel,
  ShareCard,
} from '../../containers/DripCampaignContainerV2/SocialPreview/SocialPreview.styles';
import { getFirmSettings } from '../../redux/selector';
import {
  getArticleSummary,
  postCreateContent,
  postUpdateContent,
  postUpdateContentEnterpriseAdmin,
} from '../../services/dashboard';
import { themesData } from '../../utils';
import { ErrorContainer } from '../CommonStyles';
import { Button } from '../Form';
import { LoaderWrapper } from '../Loader';
import { ModalBody, ModalCloseButton, ModalV2 } from '../Modal';

const MemberApproveShareModal = ({
  isOpen,
  onRequestClose,
  userData,
  fromContentLibTab,
  id,
  description,
  url,
  title,
  adminChannelId,
  themeId,
  link,
  sponsoredFunds,
  isEnterpriseLevelAdmin,
  isFirmLevelAdmin,
  firmSettings,
  companyId
}) => {
  const [activePlatform, setActivePlatform] = useState('fb');
  const [msgCopy, setMsgCopy] = useState({ fb: '', ln: '', tw: '', em: '' });
  const [isMsgCopyAllPlatform, setIsMsgCopyAllPlatform] = useState(false);
  const [skipCompliance, setSkipCompliance] = useState(false);
  const [loading, setLoading] = useState(false);
  const [msg, setMsg] = useState('');
  const [summaryLoading, setSummaryLoading] = useState(false);
  const [showCompliance, toggleShowCompliance] = useState(false);

  useEffect(() => {
    setSummaryLoading(true);
    let tickers = sponsoredFunds && Object.keys(sponsoredFunds);
    tickers = tickers && tickers.length > 0 && tickers.map((i) => '#' + i);
    tickers = tickers && tickers.length > 0 && tickers.join(' ');

    getArticleSummary({ articleId: id, themeId: themeId })
      .then((res) => {
        setSummaryLoading(false);
        if (res.result.success) {
          const resultData = get(res, 'result.data.summaries');
          setMsgCopy({
            fb: sponsoredFunds
              ? get(resultData, 'long[0].summary', get(resultData, 'long[0]')) +
                ' ' +
                tickers
              : get(resultData, 'long[0].summary', get(resultData, 'long[0]')),
            tw: sponsoredFunds
              ? get(
                  resultData,
                  'short[0].summary',
                  get(resultData, 'short[0]')
                ) +
                ' ' +
                tickers
              : get(
                  resultData,
                  'short[0].summary',
                  get(resultData, 'short[0]')
                ),
            ln: sponsoredFunds
              ? get(
                  resultData,
                  'medium[0].summary',
                  get(resultData, 'medium[0]')
                ) +
                ' ' +
                tickers
              : get(
                  resultData,
                  'medium[0].summary',
                  get(resultData, 'medium[0]')
                ),
            em: sponsoredFunds
              ? get(resultData, 'long[0].summary', get(resultData, 'long[0]')) +
                ' ' +
                tickers
              : get(resultData, 'long[0].summary', get(resultData, 'long[0]')),
          });
        } else {
          setSummaryLoading(false);
          setMsgCopy({
            fb: '',
            tw: '',
            ln: '',
            em: '',
          });
        }
      })
      .catch((err) => {
        setMsgCopy({
          fb: '',
          tw: '',
          ln: '',
          em: '',
        });
      });
  }, []);
  

  useEffect(() => {
    // getUserDetails()
    // .then(res => {
      // console.log('RESPONSE USER DETAILS API -- - - ', res);
      const complianceOption = isFirmLevelAdmin && !isEmpty(firmSettings) ? 
        get(firmSettings, `firm_compliance.${[companyId]}.compliance_is`, 'always_off') 
          : get(userData, 'compliance', '');
      console.log(complianceOption);
      if(complianceOption === 'always_on' || complianceOption === 'on')
        setSkipCompliance(false);
      else if(complianceOption === 'always_off' || complianceOption === 'off')
        setSkipCompliance(true);
    // })
    // .catch(err => console.log('ERROR FETCHING USER DETAILS - - -- ', err));
  }, []);

  useEffect(() => {
    let show = true;
    if(show) {
      if((isFirmLevelAdmin) && !isEmpty(firmSettings)) {
        show = get(firmSettings, `firm_compliance.${[companyId]}.compliance_is`, '') === 'optional';
      } 
       else {
        show = get(userData, 'compliance', '') === 'optional'
       }
        
    }
    toggleShowCompliance(show);
  }, []);

  const getMessageCopyMaxLength = () => {
    if (
      activePlatform === 'fb' ||
      activePlatform === 'ln' ||
      activePlatform === 'em'
    )
      return 2950;
    else if (activePlatform === 'tw') return 250;
    else return '';
  };

  const manageAllPlatformCheck = (checked) => {
    if (!checked) {
      setMsgCopy({
        ...msgCopy,
        fb: msgCopy[activePlatform].substring(0, 2950),
        tw: msgCopy[activePlatform].substring(0, 250),
        ln: msgCopy[activePlatform].substring(0, 2950),
        em: msgCopy[activePlatform].substring(0, 2950),
      });
    } else {
      setMsgCopy({
        ...msgCopy,
        fb:
          activePlatform === 'fb'
            ? msgCopy[activePlatform].substring(0, 2950)
            : '',
        tw:
          activePlatform === 'tw'
            ? msgCopy[activePlatform].substring(0, 250)
            : '',
        ln:
          activePlatform === 'ln'
            ? msgCopy[activePlatform].substring(0, 2950)
            : '',
        em:
          activePlatform === 'em'
            ? msgCopy[activePlatform].substring(0, 2950)
            : '',
      });
    }

    setIsMsgCopyAllPlatform(!isMsgCopyAllPlatform);
  };

  const handleShareHandler = () => {
    setLoading(true);
    let obj;
    if (fromContentLibTab) {
      obj = {
        contentId: fromContentLibTab ? id.split('_')[2] : id,
        isMioShare: true,
        summary: {
          short: msgCopy.tw,
          medium: msgCopy.ln,
          long: msgCopy.em,
        },
        skip_compliance: skipCompliance,
        message: description,
        sharing: true,
        media1:
          url &&
          (url.includes('https') ||
            url.includes('http') ||
            url.includes('asset'))
            ? ''
            : url,
        title: title,
      };
    } else {
      obj = {
        channelId: adminChannelId,
        source: 'manual',
        link,
        summary: {
          short: msgCopy.tw,
          medium: msgCopy.ln,
          long: msgCopy.em,
        },
        title: title,
        original_content_id: id,
        publish_status: 'published',
        skip_compliance: skipCompliance,
        ...(themeId && {
          themes: JSON.stringify({
            add: [themeId],
          }),
        }),
      };
    }

    if (obj.channelId) {
      postCreateContent(obj)
        .then((res) => {
          if (res.result.success) {
            setLoading(false);
            setMsg('Shared Successfully');
            setTimeout(() => {
              onRequestClose();
            }, 1500);
          } else {
            setMsg('Error while Sharing');
            setLoading(false);
            setTimeout(() => {
              onRequestClose();
            }, 1500);
          }
        })
        .catch((err) => {
          setMsg('Error while Sharing');
          setLoading(false);
          setTimeout(() => {
            onRequestClose();
          }, 1500);
        });
    } else {
      postUpdateContent(obj)
        .then((res) => {
          if (res.result.success) {
            setLoading(false);
            setMsg('Shared Successfully');
            setTimeout(() => {
              onRequestClose();
            }, 1500);
          } else {
            setMsg('Error while Sharing');
            setLoading(false);
            setTimeout(() => {
              onRequestClose();
            }, 1500);
          }
        })
        .catch((err) => {
          setMsg('Error while Sharing');
          setLoading(false);
          setTimeout(() => {
            onRequestClose();
          }, 1500);
        });
    }
  };

  const handleShare = () => {
    handleShareHandler();
  };

  return (
    <ModalV2
      bgColor="#fff"
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      shouldCloseOnOverlayClick
      width="75%"
    >
      <ModalCloseButton onClick={onRequestClose} />
      <ModalBody>
        <Flex justifyContent="center" color={themesData.black}>
          Approve to Share for Members
        </Flex>
        <Flex display="flex" flexDirection="column">
          <Flex
            alignItems="center"
            justifyContent="flex-start"
            style={{
              backgroundColor: '#eee',
              borderRadius: '5px',
              marginBottom: '10px',
              marginLeft: '25px',
              marginTop: '25px',
              width: 'fit-content',
            }}
          >
            <Chips
              onClick={() => {
                setActivePlatform('fb');
              }}
              active={activePlatform === 'fb'}
            >
              <div className="chip">
                <img
                  src="/assets/images/campaign-platform-facebook.png"
                  alt=""
                />
                Facebook
              </div>
            </Chips>
            <Chips
              onClick={() => {
                setActivePlatform('ln');
              }}
              active={activePlatform === 'ln'}
            >
              <div className="chip">
                <img
                  src="/assets/images/campaign-platform-linkedIn.png"
                  alt=""
                />
                LinkedIn
              </div>
            </Chips>
            <Chips
              onClick={() => {
                setActivePlatform('tw');
              }}
              active={activePlatform === 'tw'}
            >
              <div className="chip">
                <img
                  src="/assets/images/campaign-platform-twitter.png"
                  alt=""
                />
                Twitter
              </div>
            </Chips>
            <Chips
              onClick={() => {
                setActivePlatform('em');
              }}
              active={activePlatform === 'em'}
            >
              <div className="chip">
                <img src="/assets/images/campaign-platform-email.svg" alt="" />
                Email
              </div>
            </Chips>
          </Flex>
        </Flex>
        <Flex
          alignItems="center"
          justifyContent="flex-start"
          alignSelf="flex-start"
          ml="25px"
        >
          <CheckboxInput
            type="checkbox"
            id="samePlatform"
            name="samePlatform"
            onClick={() => manageAllPlatformCheck(isMsgCopyAllPlatform)}
            checked={isMsgCopyAllPlatform}
            disabled={
              msgCopy.fb.length === 0 &&
              msgCopy.ln.length === 0 &&
              msgCopy.tw.length === 0 &&
              msgCopy.em.length === 0
            }
          />
          <InputLabel
            fontSize="10px"
            fontWeight="400"
            color="#bbb"
            marginLeft="5px"
          >
            Use same copy for all platforms
          </InputLabel>
        </Flex>
        <ShareCard height="210px">
          <textarea
            maxLength={getMessageCopyMaxLength()}
            placeholder={
              summaryLoading ? 'Generating Summary ...' : 'Message Copy'
            }
            name={`share_copy_${activePlatform}`}
            style={{
              width: '100%',
              height: '100%',
              borderRadius: '4px',
              padding: '10px 15px',
              color: '#4E6780',
              border: '1px solid #c9d0d8',
              resize: 'none',
              maxHeight: '200px',
              maxWidth: '760px',
            }}
            disabled={summaryLoading}
            value={msgCopy[activePlatform]}
            onChange={(e) => {
              if (isMsgCopyAllPlatform) {
                setMsgCopy({
                  ...msgCopy,
                  fb: e.target.value.substring(0, 2950),
                  ln: e.target.value.substring(0, 2950),
                  tw: e.target.value.substring(0, 250),
                  em: e.target.value.substring(0, 2950),
                });
                if (e.target.value.length === 0) {
                  setIsMsgCopyAllPlatform(false);
                }
              } else {
                setMsgCopy({ ...msgCopy, [activePlatform]: e.target.value });
              }
            }}
          />
        </ShareCard>
        {showCompliance && (
          <ApprovalCard>
            <Flex
              alignItems="center"
              justifyContent="center"
              mr="15px"
              mt="3px"
            >
              <label
                style={{
                  color: 'black',
                  fontSize: '14px',
                  fontWeight: '600',
                }}
                data-testid="compliance_approval_label"
              >
                Compliance Approval Required?
              </label>
            </Flex>
            <Flex alignItems="center" justifyContent="center" mr="5px">
              <RadioInput
                type="radio"
                id="compliance_approval_yes"
                name="compliance_approval"
                checked={!skipCompliance}
                onClick={(e) => {
                  setSkipCompliance(false);
                }}
                style={{ cursor: 'pointer' }}
              />
              <RadioInputLabel
                htmlFor="compliance_approval_yes"
                style={{ cursor: 'pointer' }}
              >
                Yes
              </RadioInputLabel>
            </Flex>
            <Flex alignItems="center" justifyContent="center">
              <RadioInput
                type="radio"
                id="compliance_approval_no"
                name="compliance_approval"
                checked={skipCompliance}
                onClick={(e) => {
                  setSkipCompliance(true);
                }}
                style={{ cursor: 'pointer' }}
              />
              <RadioInputLabel
                htmlFor="compliance_approval_no"
                style={{ cursor: 'pointer' }}
              >
                No
              </RadioInputLabel>
            </Flex>
          </ApprovalCard>
        )}
        <Flex justifyContent="center" width="100%">
          <LoaderWrapper isLoading={loading}>
            <Button
              borderRadius="5px"
              padding="7px 100px"
              onClick={handleShare}
              disabled={
                msgCopy.em.length === 0 ||
                msgCopy.fb.length === 0 ||
                msgCopy.tw.length === 0 ||
                msgCopy.ln.length === 0
                  ? true
                  : false
              }
            >
              Share
            </Button>
          </LoaderWrapper>
        </Flex>
        {msg && <ErrorContainer align="center">{msg}</ErrorContainer>}
      </ModalBody>
    </ModalV2>
  );
};

const mapStateToProps = (state) => {
  const isEnterpriseLevelAdmin = get(state, 'user.data.details.company.enterprise', "false") === "true";
  const isFirmLevelAdmin = get(state, 'user.data.details.company.firm', "false") === "true";
  const firmSettings = getFirmSettings({ isEnterpriseLevelAdmin, isFirmLevelAdmin, state });

  return {
    isEnterpriseLevelAdmin,
    isFirmLevelAdmin,
    firmSettings: firmSettings,
    companyId: state?.user?.data?.details?.company?.id
  }
}

export default connect(mapStateToProps)(MemberApproveShareModal);
