import { styled } from '@mui/material';

export const Title = styled('h5')(() => ({
  color: '#344054',
  fontSize: '1.25rem',
  fontWeight: '500',
  margin: 0,
  textAlign: 'left',
}));

export const SearchResultsWrapper = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  alignItems: 'flex-start',
  gap: '1.5rem',
  overflow: 'hidden',
}));

export const Results = styled('div')(() => ({
  width: '100%',
  maxWidth: '800px',
}));

export const ZeroStateWrapper = styled('div')(() => ({
  width: '100%',
  minHeight: '300px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));
