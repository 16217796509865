import styled from 'styled-components';
import { themesData } from '../../../utils';
import { Flex } from '@rebass/grid';

export const DropdownSection = styled.div.attrs({
  className: 'dropdown-section',
})`
  display: block;
  width: 100%;
  height: 255px;
  border: 1px solid #cccccc;
  background-color: #ffffff;
  max-height: 255px;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0px 10px;
  flex-direction: column;

  & > div:not(:first-child) {
    border-top: 2px solid #dcdcdc;
  }
`;

export const NewsletterCheckBoxDiv = styled.div.attrs({
  className: 'newsletter-checkbox-div',
})`
  position: relative;
  padding: 5px 0px;

  /* The container */
  .container {
    display: block;
    position: relative;
    padding-left: 25px;
    font-size: 12px;
    color: ${themesData.charcoalGrey};
    text-align: left;
    font-weight: ${({ fontWeight }) => fontWeight || ''};
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .containerhead {
    display: block;
    position: relative;
    padding-left: 5px;
    font-size: 12px;
    color: ${themesData.charcoalGrey};
    text-align: left;
    font-weight: ${({ fontWeight }) => fontWeight || ''};
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  /* Hide the browser's default checkbox */
  .container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: 2px;
    left: 6px;
    height: 13px;
    width: 13px;
    background-color: #eee;
    border: 1px solid #c9c9c9;
    border-radius: 4px;
  }

  .container input ~ .checkmark {
    background-color: #fff;
  }

  /* On mouse-over, add a grey background color */
  .container:hover input ~ .checkmark {
    background-color: #fff;
  }

  /* When the checkbox is checked, add a blue background */
  .container input:checked ~ .checkmark {
    background-color: #c95cfc;
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: '';
    position: absolute;
    display: none;
  }

  /* Show the checkmark when checked */
  .container input:checked ~ .checkmark:after {
    display: block;
  }

  /* Style the checkmark/indicator */
  .container .checkmark:after {
    left: 3px;
    top: -1px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`;

export const CaretUpIcon = styled.span.attrs({
  className: 'caret-down',
})`
  border: solid #c95cfc;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(224deg);
  -webkit-transform: rotate(224deg);
  float: right;
  margin-top: ${({ marginTop }) => marginTop || '3px'};
`;

export const CCInput = styled.input.attrs({
  className: 'cc_input',
})`
  color: #757575;
  font-family: Poppins;
  font-size: 13px;
  background-color: #eeeeee;
  border: 0px;
  height: 39px;
  padding: 0px 20px;
  margin-top: 10px;

  ::placeholder {
    color: #757575;
  }
`;

export const TooltipMode = styled(Flex).attrs({
  className: 'tooltip',
})`
  /* Tooltip container */
  .tooltip {
    position: relative;
    display: inline-block;
    color: #006080;
  }

  /* Tooltip text */
  .tooltip .tooltiptext {
    visibility: hidden;
    position: absolute;
    width: ${({ width }) => width || '300px'};

    ${'' /* min-width: 310px;
    max-width: 350px; */}
    background-color: #555;
    color: #fff;
    text-align: left;
    padding: 8px 8px;
    border-radius: 6px;
    z-index: 1;
    opacity: 0;
    transition: opacity 0.6s;
    font-size: 10px;
    font-family: 'Poppins';
  }

  /* Show the tooltip text when you mouse over the tooltip container */
  .tooltip:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
  }

  .tooltip-right {
    top: ${({ center }) => (center ? '-15px' : '-5px')};
    left: 150%;
  }
  .tooltip-right::after {
    content: '';
    position: absolute;
    top: ${({ center }) => (center ? '25px' : '15px')};
    right: 100%;
    margin-top: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent #555 transparent transparent;
  }
`;
