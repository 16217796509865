import React from 'react';
import {
    ModalHeader,
    ModalStyle,
    ModalRoot,
    ModalTitle,
    CloseIcon
} from './styles';
import closeModalIcon from './closeModalIcon.svg';

const ModalContainer = ({
  modalStatus,
  closeModal,
  children,
  title
}) => {
  return (
    <ModalRoot
      open={modalStatus}
      onClose={closeModal}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <ModalStyle>
        <ModalHeader>
            <ModalTitle>{title}</ModalTitle>
            <CloseIcon src={closeModalIcon} alt='close' onClick={()=>closeModal()} />
        </ModalHeader>
       {children}
      </ModalStyle>
    </ModalRoot>
  );
};

export default ModalContainer;