import React from 'react';
import { CustomCheckboxInput, CustomCheckboxLabel, CustomCheckboxSpan } from './CustomCloutCheckbox.styles';

const CustomCloutCheckbox = ({ changeHandler, inputId, inputValue, boxChecked, borderRadius }) => {
    return (
        <CustomCheckboxLabel>
            <CustomCheckboxInput
                name={inputId} 
                // ref={} 
                value={inputValue}
                id={inputId} 
                onChange={e => changeHandler(e)}
                checked={boxChecked}
            />
            <CustomCheckboxSpan
                id={inputId}
                htmlFor={inputId}
                borderRadius={borderRadius || '4px'}
            />
        </CustomCheckboxLabel>
    )
}

export default CustomCloutCheckbox