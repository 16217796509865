import { styled } from '@mui/material';

export const CampaignOverviewWrapper = styled('div')(() => ({
  background: '#F9FAFB',
  minHeight: '100vh',
  '& .campaign-detail-section': {
    width: '80%',
    margin: 'auto',
  },
  '& .campaign-insights-container': {
    padding: '1.5rem 0',
    display: 'grid',
    gridTemplateColumns:'35% 1fr',
    gap: '2rem',
  },
}));
