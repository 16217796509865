import { Flex } from '@rebass/grid';
import styled from 'styled-components';

export const ACBlock = styled(Flex)`
  width: 90%;
  border: 1px solid #e4e7ec;
  padding: 16px;
  position: relative;
  background-color: #fff;
`;

export const TimeInput = styled.input`
  background-color: #fff;
  color: #e4e7ec;
  border: 1px solid #e4e7ec;
  padding: 10px;
  font-size: 14px;
  width: 100%;
`;

export const SuggestedBlock = styled(Flex)`
  flex-direction: column;
  border: 1px solid #8c8beb;
  background-color: #8c8beb;
  border-radius: 8px;
  width: 100%;
  padding: 20px;
  margin-top: 10px;
`;

export const StackedACBlock = styled(Flex)`
  background-color: #fff;
  display: flex;
  flex-direction: row;
  width: 85%;
  min-height: 90px;
  border: 1px solid #e4e7ec;
  padding: 15px;
  margin: calc(6vh - 50px) 0 35px 0;
  box-shadow: -2px 5px 0 -3px #e4e7ec, 2px 1px #e4e7ec, 10px 10px 0 -3px #fff,
    10px 10px #e4e7ec, 20px 20px 0 -3px #fff, 20px 20px #e4e7ec;
  transition: box-shadow 1s, top 1s, left 1s;
  position: relative;
  top: 0;
  left: 0;
  cursor: pointer;
  border-radius: 8px;
`;
