import React from 'react';
import { WhiteCircle } from './Stepper.styles';
import { Flex } from '@rebass/grid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';


export const StepperV2 = ({ stepperList, selectedIndex, }) => {
  return (
    <Flex width="80%" margin="20px auto">
      {stepperList.map((item, index) => (
        <Flex 
          width="33.33%" 
          padding="16px" 
          backgroundColor="#fff"
          alignItems="center" 
          style={{ 
            border: '1px solid #E4E7EC',
            borderBottom: selectedIndex >= index ? '3px solid #816EF8' : '' 
          }}>
          <Flex>
            {selectedIndex > index ? (
              <WhiteCircle background="#816EF8">
                <FontAwesomeIcon style={{ position: 'absolute', left: "3px", top: '3px' }} size='2xs' color="#fff" icon={faCheck}/>
              </WhiteCircle>
            ) : <WhiteCircle />}
            
          </Flex>
          <Flex fontSize="14px" color="#101828">
            {item.label}
          </Flex>
        </Flex>
      ))}
    </Flex>
  );
};
