import React, { useEffect, useState } from 'react';
import {
  Button,
  LoaderWrapper,
  ModalBody,
  ModalCloseButton,
  ModalV2,
} from '../../components';
import { Flex } from '@rebass/grid';
import { get, isEmpty } from 'lodash';
import { getEmailTemplates } from '../../services/campaign';
import {
  FilteredTemplateCard,
  TemplateCardWrapper,
  TemplateTooltip,
} from './CampaignEmailTemplateContainer.styles';
import { useHistory } from 'react-router-dom';
import { themesData } from '../../utils';

const CampaignCreateEmailTemplateModal = ({
  isModalOpen,
  handleClose,
  isSelected,
}) => {
  const [templateLoader, setTemplateLoader] = useState(false);
  const [filteredTemplates, setFilteredTemplates] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState();

  const history = useHistory();

  useEffect(() => {
    getTemplates();
  }, []);

  const getTemplates = () => {
    setTemplateLoader(true);
    getEmailTemplates(['default', 'cascade'])
      .then((res) => {
        const newTemplates = get(res, 'result.data.templates', [])
          .filter((c) => c.owner === 'clout')
          .sort((a, b) => new Date(b.created_time) - new Date(a.created_time));
        console.log('NEW TEMPLATES - - - - - ', newTemplates);
        setFilteredTemplates(newTemplates);
        setTemplateLoader(false);
      })
      .catch((err) => {
        setTemplateLoader(false);
        console.log('ERROR - - - - ', err);
      });
  };

  const handleTemplateSelection = (item) => {
    console.log('item', item);
    setSelectedTemplate(item);
  }

  // const handleContinue = (item) => {
  //   history.push(`/campaign/template/${item.id}`)
  // }

  const handleContinue = () => {
    if(selectedTemplate.id === 0) {
      history.push(`/campaign/template/new`);
    } else {
      history.push(`/campaign/template/${selectedTemplate.id}?type=new`)
    }
  }


  return (
    <ModalV2
      onRequestClose={() => handleClose()}
      shouldCloseOnOverlayClick
      isOpen={isModalOpen}
      bgColor="white"
      width="60%"
    >
      <ModalCloseButton onClick={() => handleClose()} />
      <ModalBody>
        <Flex display="flex" justifyContent="center" flexDirection="column">
          <Flex
            display="flex"
            justifyContent="flex-start"
            color="#101828"
            fontSize="20px"
          >
            Create New Email Template
          </Flex>
          <span
            style={{
              color: '#101828',
              fontSize: '14px',
              display: 'flex',
              paddingBottom: '10px',
            }}
          >
            Create using a default template or select from the available presets
          </span>
        </Flex>
        {templateLoader ? (
          <LoaderWrapper
            isLoading={templateLoader}
            styles={{ position: 'relative', top: '100%', right: '0%' }}
          ></LoaderWrapper>
        ) : (
          <TemplateCardWrapper style={{ height: '300px', overflowY: 'auto' }}>
            <FilteredTemplateCard
              borderColor={selectedTemplate && selectedTemplate.id === 0 ? themesData.purpleishBlue : ''}
              onClick={() => {
                handleTemplateSelection({
                  id: 0
                })
              }}
              style={{ cursor: 'pointer' }}
            >
              <Flex
                height="84%"
                alignItems="center"
                justifyContent="center"
                backgroundColor="#E4E7EC"
                // style={{ borderTopLeftRadius: '8px', BorderTopRightRadius: '8px'}}
                style={{ borderRadius: '8px', borderBottomLeftRadius: '0px', borderBottomRightRadius: '0px'}}
              >
                <img
                  height="72%"
                  alt=""
                  style={{
                    objectFit: 'cover',
                    objectPosition: '0px 0px',
                    borderBottom: '1px solid #E4E7EC',
                  }}
                  src="/assets/images/media-icon.svg"
                />
              </Flex>
              <Flex
                justifyContent="center"
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'left',
                  color: '#101828',
                  padding: '8px',
                  height: '36px',
                  alignItems: 'center'
                }}
              >
                Basic Default
              </Flex>
            </FilteredTemplateCard>
            {filteredTemplates.map(
              (item, index) => (
                  <FilteredTemplateCard
                    borderColor={selectedTemplate && selectedTemplate.id === item.id ? themesData.purpleishBlue : ''}
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      handleTemplateSelection(item);
                    }}
                  >
                    <Flex display="flex" justifyContent="center" padding="5px" width="100%" height="84%" style={{ alignItems: 'center', borderBottom: "1px solid #E4E7EC"}}>
                    <img
                      height="100%"
                      width="100%"
                      alt=""
                      // style={{
                      //   borderTopLeftRadius: '9%',
                      //   borderTopRightRadius: '9%',
                      //   objectFit: 'cover',
                      //   objectPosition: '0px 0px',
                      // }}
                      src={
                        item.thumbnail_url || '/assets/images/postDefault4.jpg'
                      }
                    />
                    </Flex>
                    <Flex
                      justifyContent="center"
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        padding: '8px',
                        fontSize: '12px',
                        height: '36px',
                        justifyContent: 'left'
                      }}
                    >
                      <TemplateTooltip>
                        <div
                          style={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'left',
                            color: '#101828',
                          }}
                        >
                          {item.name && item.name.length > 33
                            ? `${item.name.substring(0, 33)}...`
                            : item.name}
                        </div>
                        {item.name && item.name.length > 33 && (
                          <span className="tooltiptext">{item.name}</span>
                        )}
                      </TemplateTooltip>
                    </Flex>
                  </FilteredTemplateCard>
                )
            )}
          </TemplateCardWrapper>
        )}
        <hr
          style={{
            width: '100%',
            border: '1px solid #E4E7EC',
          }}
        />
        <Flex display="flex" justifyContent="space-between">
          <Button
            width="45%"
            height="40px"
            bgColor="#fff"
            color="#101010"
            borderColor="#E4E7EC"
            borderRadius="4px"
            margin="20px 0px 0px 10px"
            onClick={() => handleClose()}
          >
            Cancel
          </Button>
          <Button
            width="45%"
            borderRadius="4px"
            margin="20px 39px 0px 0px"
            disabled={isEmpty(selectedTemplate)}
            onClick={handleContinue}
          >
            Continue
          </Button>
        </Flex>
      </ModalBody>
    </ModalV2>
  );
};

export default CampaignCreateEmailTemplateModal;
