import React, { useState, useEffect, useRef } from 'react';
import Slider from 'react-slick';
import { Flex, Box } from '@rebass/grid';
import { get, toLower, difference, isEmpty } from 'lodash';
import {
  SearchInput,
  ListItemTile,
  Button,
  ErrorModal,
  ErrorContainer,
  PremiumModalContent,
  LoaderWrapper,
} from '../../../components';
import { getMediaUrl, themesData } from '../../../utils';

import { TopicListWrapper } from './SelectThemes.styles';

const styles = {
  Button: {
    color: themesData.white,
    background: themesData.purpleishBlue,
    borderRadius: '0px',
    fontSize: '11px',
    fontFamily: 'Poppins'
  }
}

const SelectThemes = ({
  fetchedThemes,
  themesList,
  onUpdateTheme,
  isUpdating,
  contentCreateError,
  hasTopicSelected,
  setShowContentOverview,
  resetImageUpload,
  isFromImageOnly
}) => {
  const didMountRef = useRef(null);
  const defaultThemeIds = fetchedThemes.map((item) => {
    return item.id;
  });
  const [topicList, setTopicList] = useState([]);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [selectedTopicItems, setSelectedTopicItems] = useState(fetchedThemes);
  const [selectedTopicIdList, setSelectedTopicIdList] = useState(
    defaultThemeIds
  );
  const [isPublishSuccess, setIsPublishSuccess] = useState(false);
  const [noThemesMessage, setNoThemesMessage] = useState('');
  const [searchText, setSearchText] = useState('');

  useEffect(() => {
    localStorage.removeItem('preSelectedTopicsView');
  }, []);

  useEffect(() => {
    if (selectedTopicItems.length > 0 && hasTopicSelected) {
      hasTopicSelected(true);
    }
  }, [selectedTopicItems, hasTopicSelected]);

  useEffect(() => {
    if(!searchText && topicList && topicList.length > 0){
      setTopicList([]);
    }
  }, [searchText]);

  useEffect(() => {
    if (!didMountRef.current) {
      didMountRef.current = true;
    } else {
      if (!contentCreateError && !isUpdating) {
        setIsPublishSuccess(true);
      }
    }
  }, [isUpdating]);
  const handleYourTopicsClick = (id, topic) => {
    if (selectedTopicIdList.length >= 4) {
      setIsErrorModalOpen(true);
      return;
    }

    if (!selectedTopicIdList.includes(id)) {
      setSelectedTopicIdList([...selectedTopicIdList, id]);
      setSelectedTopicItems([...selectedTopicItems, topic]);
    }
  };

  const handleYourTopicsRemove = (id) => {
    setSelectedTopicIdList(selectedTopicIdList.filter((item) => item !== id));
    setSelectedTopicItems(selectedTopicItems.filter((item) => item.id !== id));
  };

  const onTopicSearch = (value) => {
    const filteredThemes = themesList.filter(
      (theme) => toLower(get(theme, 'name', '')).indexOf(toLower(value)) !== -1
    );

    if (!value) {
      setTopicList([]);
      setSelectedTopicIdList([]);
      setNoThemesMessage('Search another topic');
    }
    if (isEmpty(filteredThemes)) {
      setNoThemesMessage('no result found... please try another topic');
    } else {
      setTopicList(filteredThemes);
    }
  };

  const onSubmitClick = () => {
    onUpdateTheme(
      {
        add: selectedTopicIdList,
        remove: difference(defaultThemeIds, selectedTopicIdList),
      },
      'next'
    );
  };
  
  return (
    <Flex width={1} flexDirection="column">
      <Flex width={1} flexDirection="column">
        {isFromImageOnly && (
          <span style={{marginBottom: '10px', color: '#7b7b7b', fontSize: '13px'}}>Choose at least one topic for your image.</span>
        )}
        <SearchInput
          placeholder="Search for a Topic (For example : “Technology”)"
          onSearchClick={onTopicSearch}
          onChange={() => setNoThemesMessage('')}
          setSearchText={setSearchText}
          noBlur
        />
      </Flex>
      <TopicListWrapper
        height="62px"
        width={1}
        alignItems="center"
        py="5px"
        flexDirection="column"
        flex="1 1 auto"
      >
        {searchText && noThemesMessage}
        {searchText && !noThemesMessage && (
          <Slider
            {...{
              infinite: false,
              speed: 500,
              slidesToShow: 6,
              slidesToScroll: 6,
              variableWidth: true,
            }}
          >
            {topicList.map((topic) => (
              <ListItemTile
                handleClick={() => handleYourTopicsClick(get(topic, 'id'), topic)}
                id={get(topic, 'id')}
                url={getMediaUrl(get(topic, 'media_urls'))}
                topic={get(topic, 'name')}
                type="add"
              />
            ))}
          </Slider>
        )}
      </TopicListWrapper>
      {selectedTopicItems.length > 0 && (
        <div>
          <Flex justifyContent="center">Selected Topics</Flex>
          <TopicListWrapper alignItems="center" py="5px" flexDirection="column">
            <Flex width={1} flexWrap="wrap" justifyContent="center">
              {selectedTopicItems.map((topic) => (
                <Box width={1 / 5} height="45px" mb="5px">
                  <ListItemTile
                    handleClick={() => handleYourTopicsRemove(get(topic, 'id'))}
                    id={get(topic, 'id')}
                    url={getMediaUrl(get(topic, 'media_urls'))}
                    topic={get(topic, 'name')}
                    type="remove"
                  />
                </Box>
              ))}
            </Flex>
            {isErrorModalOpen && (
              <ErrorModal onClose={() => setIsErrorModalOpen(false)}>
                <ErrorContainer align="center">
                  <PremiumModalContent>
                    You can't select more than 4 topics
                  </PremiumModalContent>
                </ErrorContainer>
              </ErrorModal>
            )}
          </TopicListWrapper>
          {/* {isFromImageOnly ? (
            <Flex justifyContent='center' alignItems={isUpdating ? 'flex-end' : 'center'}>
              <LoaderWrapper styles={{width: 'auto', marginRight: '10px'}} isLoading={isUpdating}>
                <Button styles={{...styles.Button, marginRight: '10px'}} onClick={onSubmitClick}>Submit</Button>
              </LoaderWrapper>
              <Button onClick={resetImageUpload} styles={{width: '80px', fontSize: '11px', color: '#6351ed', backgroundColor: '#fff', border: '1px solid #6351ed'}} >Exit</Button>
            </Flex>
          ) : (
            <Box>
              <LoaderWrapper isLoading={isUpdating}>
                <Button styles={styles.Button} onClick={onSubmitClick}>Submit</Button>
              </LoaderWrapper>
            </Box>
          )} */}
          <Box>
            <LoaderWrapper isLoading={isUpdating}>
              <Button styles={styles.Button} onClick={onSubmitClick}>Submit</Button>
            </LoaderWrapper>
          </Box>
        </div>
      )}
      <Box>{isPublishSuccess && '✓ Content published successfully'}</Box>
    </Flex>
  );
};

export default SelectThemes;
