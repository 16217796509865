import { get } from 'lodash';
import { takeLatest, all, call, put } from 'redux-saga/effects';
import { actions as complianceActions } from '../modules/compliance-trail.module';
import { getComplianceTrail } from '../../services/dashboard';

function* complianceTrailRequest({ payload }) {
  try {
    const response = yield call(getComplianceTrail, payload.compliance_id);
    const data = get(response, 'result.data', {});
    yield put(complianceActions.api.complianceTrail.response(data));
  } catch (error) {
    yield put(complianceActions.api.complianceTrail.error(error));
  }
}

function* complianceTrailSaga() {
  yield all([
    takeLatest(
      complianceActions.api.complianceTrail.request,
      complianceTrailRequest
    ),
  ]);
}

export default complianceTrailSaga;
