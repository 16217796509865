import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { HeaderDiv } from './Header.styles';
import {
  HeaderLogoNew,
  NavBarItems,
  Profile,
  DropdownItem,
  HeaderContainer,
  ItemContainer,
} from './Header.styles';
import userIcon from './userIcon.svg';
import { connect } from 'react-redux';
import Popover from '@mui/material/Popover';

const Header = ({
  children,
  type,
  customLogoUrl,
  profile,
  userName,
  redirectType,
}) => {
  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'user-popover' : undefined;

  const getInitials = () => {
    if (userName) {
      let firstInitial = userName.first ? userName.first[0] : '';
      let lastInitial = userName.last ? userName.last[0] : '';
      return firstInitial + lastInitial;
    } else {
      return (
        <img
          src={userIcon}
          alt="user"
          style={{ height: 18, width: 18, marginTop: 2 }}
        />
      );
    }
  };

  return (
    <HeaderDiv type={type}>
      <HeaderContainer>
        <HeaderLogoNew
          src={customLogoUrl || '/assets/images/clout-logos/MA-logo.png'}
          alt="mio-logo"
          onClick={() => redirectType !== 'nurture' && history.push('/')}
          style={{
            position: !customLogoUrl && 'relative',
            bottom: !customLogoUrl && '13px',
          }}
        />
        <NavBarItems>{children}</NavBarItems>
        {redirectType !== 'nurture' && (
          <>
            <ItemContainer id={id} onClick={handlePopoverOpen}>
              <Profile>{getInitials()}</Profile>
              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handlePopoverClose}
              >
                {profile?.children.map((innerLink, index) => {
                  return (
                    <DropdownItem
                      key={index}
                      onClick={() => innerLink.action()}
                    >
                      {innerLink.name}
                    </DropdownItem>
                  );
                })}
              </Popover>
            </ItemContainer>
          </>
        )}
      </HeaderContainer>
    </HeaderDiv>
  );
};

const mapStateToProps = (state) => {
  return {
    userName: state.user?.data?.details?.user?.name,
  };
};

export default connect(mapStateToProps)(Header);
