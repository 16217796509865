import { Flex } from '@rebass/grid';
import React from 'react'
import AssignContent from '../AssignContent/AssignContent';
import CreatePlanCampaign from '../CreatePlanCamapaign/CreatePlanCampaign';

const CreateCampaign = ({
    createFlow,
    setCreateFlow,
    createTabs,
    setCreateTabs,
    contactGroups,
    socialNetworkData,
    selectedThemes,
    themes,
    campaignSetState,
    campaignCreateFlow,
    setObjectivesTabData,
    campaignId,
    trendingTopics,
    mostSubscribedTopics,
    setTopicsTabData,
    suggestedArticles,
    getMyContentArticles,
    fetchBookmarkedArticles,
    fetchRecommendedArticles,
    createScreenIndex,
    setCreateScreenIndex,
    getAccountId,
    campaignScheduleTimings,
    campaignSelectedArticles,
    campaignSelectedWeek,
    campaignSelectedDays,
    campaignArticles,
    contentWeeks,
    planCampaignData,
    publishedContent,
    endCreateFlow,
    isPremiumAdvisor,
    channelId,
    suggestedArticlesLoader,
    toggleSuggestedArticlesLoader,
}) => {

    const getDescription = () => {
        let description;
        switch (createTabs) {
            case 0:
                description = `Specify the purpose of this campaign, and who you are targeting: clients or prospects. 
                    Also, narrow down the universe of email lists, Facebook/LinkedIn pages, and Twitter handles to a few. 
                    You can later assign one (or more) of these to specific posts.`
                break;
            case 1:
                description = `Specify the topics that this campaign is going to focus on. 
                    Our recommend topics are personalized to the purpose, target, and selected email lists 
                    (and the content that clients in them engaged with). You can also add popular topics or search/browse 
                    for any other topics.`
                break;
            case 2:
                description = `Specify the start date, duration, frequency, and engagement score opt-out. 
                    Also, specify the "structure" of the campaign, i.e. how many emails and 
                    Facebook/LinkedIn/Twitter posts have to be sent out per week, and at what times.`
                break;
            default:
                description = `Specify the purpose of this campaign, and who you are targeting: clients or prospects. 
                Also, narrow down the universe of email lists, Facebook/LinkedIn pages, and Twitter handles to a few. 
                You can later assign one (or more) of these to specific posts.`
                break;
        }
        return description;
    }

    return (
        <Flex width="90%" height="100%" margin="0 auto" flexDirection="column">
            {createScreenIndex === 0 ? (
                <CreatePlanCampaign
                    setCreateFlow={setCreateFlow}
                    createFlow={createFlow}
                    createTabs={createTabs}
                    getDescription={getDescription}
                    contactGroups={contactGroups}
                    setCreateTabs={setCreateTabs}
                    socialNetworkData={socialNetworkData}
                    campaignSetState={campaignSetState}
                    campaignCreateFlow={campaignCreateFlow}
                    setObjectivesTabData={setObjectivesTabData}
                    selectedThemes={selectedThemes}
                    themes={themes}
                    campaignId={campaignId}
                    trendingTopics={trendingTopics}
                    mostSubscribedTopics={mostSubscribedTopics}
                    setTopicsTabData={setTopicsTabData}
                    getAccountId={getAccountId}
                    setCreateScreenIndex={setCreateScreenIndex}
                    createScreenIndex={createScreenIndex}
                    campaignArticles={campaignArticles}
                    contentWeeks={contentWeeks}
                    campaignScheduleTimings={campaignScheduleTimings}
                    planCampaignData={planCampaignData}
                    publishedContent={publishedContent}
                    endCreateFlow={endCreateFlow}
                />
            ) : (
                <AssignContent 
                    setCreateFlow={setCreateFlow}
                    createFlow={createFlow} 
                    setCreateScreenIndex={setCreateScreenIndex}
                    suggestedArticles={suggestedArticles}
                    getMyContentArticles={getMyContentArticles}
                    fetchBookmarkedArticles={fetchBookmarkedArticles}
                    fetchRecommendedArticles={fetchRecommendedArticles}
                    getAccountId={getAccountId}
                    campaignSetState={campaignSetState}
                    campaignSelectedArticles={campaignSelectedArticles}
                    campaignSelectedWeek={campaignSelectedWeek}
                    campaignSelectedDays={campaignSelectedDays}
                    campaignArticles={campaignArticles}
                    campaignId={campaignId}
                    publishedContent={publishedContent}
                    setObjectivesTabData={setObjectivesTabData}
                    socialNetworkData={socialNetworkData}
                    endCreateFlow={endCreateFlow}
                    isPremiumAdvisor={isPremiumAdvisor}
                    channelId={channelId}
                    suggestedArticlesLoader={suggestedArticlesLoader}
                    toggleSuggestedArticlesLoader={toggleSuggestedArticlesLoader}
                />
            )}
        </Flex>
    )
}

export default CreateCampaign;

