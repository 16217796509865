export const NoContentTemplate = {
    "page": {
        "body": {
            "container": {
                "style": {
                    "background-color": "#FFFFFF"
                }
            },
            "content": {
                "computedStyle": {
                    "linkColor": "#0068A5",
                    "messageBackgroundColor": "transparent",
                    "messageWidth": "600px"
                },
                "style": {
                    "color": "#000000",
                    "font-family": "Arial, Helvetica Neue, Helvetica, sans-serif"
                }
            },
            "type": "mailup-bee-page-proprerties",
            "webFonts": []
        },
        "description": "Test template for BEE",
        "rows": [{
            "columns": [{
                "grid-columns": 12,
                "modules": [{
                    "descriptor": {
                        "computedStyle": {
                            "hideContentOnMobile": false
                        },
                        "style": {
                            "padding-bottom": "10px",
                            "padding-left": "10px",
                            "padding-right": "10px",
                            "padding-top": "10px"
                        },
                        "text": {
                            "computedStyle": {
                                "linkColor": "#0068A5"
                            },
                            "html": "<div class=\"txtTinyMce-wrapper\" style=\"font-size: 14px; line-height: 16px;\" data-mce-style=\"font-size: 14px; line-height: 16px;\"><p style=\"font-size: 14px; line-height: 16px; word-break: break-word;\" data-mce-style=\"font-size: 14px; line-height: 16px; word-break: break-word;\">Hello <code spellcheck=\"false\" data-bee-type=\"mergetag\" data-bee-code=\"\" data-bee-name=\"First Name\">{$first_name}</code>,&nbsp;</p></div>",
                            "style": {
                                "color": "#000",
                                "font-family": "inherit",
                                "line-height": "120%"
                            }
                        }
                    },
                    "locked": true,
                    "type": "mailup-bee-newsletter-modules-text",
                    "uuid": "e8960e47-625b-48a8-810a-bbd5bf869105"
                }],
                "style": {
                    "background-color": "transparent",
                    "border-bottom": "0px solid transparent",
                    "border-left": "0px solid transparent",
                    "border-right": "0px solid transparent",
                    "border-top": "0px solid transparent",
                    "padding-bottom": "5px",
                    "padding-left": "0px",
                    "padding-right": "0px",
                    "padding-top": "5px"
                },
                "uuid": "36d32c39-7689-43d2-a562-1de3df0dd93e"
            }],
            "container": {
                "style": {
                    "background-color": "transparent",
                    "background-image": "none",
                    "background-position": "top left",
                    "background-repeat": "no-repeat"
                }
            },
            "content": {
                "computedStyle": {
                    "rowColStackOnMobile": true,
                    "rowReverseColStackOnMobile": false
                },
                "style": {
                    "background-color": "transparent",
                    "background-image": "none",
                    "background-position": "top left",
                    "background-repeat": "no-repeat",
                    "color": "#000000",
                    "width": "500px"
                }
            },
            "metadata": {
                "name": "salutation"
            },
            "locked": true,
            "type": "one-column-empty",
            "uuid": "d2ece104-1330-4d63-affa-28393ef712e4"
        }, {
            "columns": [{
                "grid-columns": 12,
                "modules": [{
                    "descriptor": {
                        "computedStyle": {
                            "hideContentOnMobile": false
                        },
                        "style": {
                            "padding-bottom": "10px",
                            "padding-left": "10px",
                            "padding-right": "10px",
                            "padding-top": "10px"
                        },
                        "text": {
                            "computedStyle": {
                                "linkColor": "#0068A5"
                            },
                            "html": "<div class=\"txtTinyMce-wrapper\" style=\"font-size: 14px; line-height: 16px;\" data-mce-style=\"font-size: 14px; line-height: 16px;\"><p style=\"font-size: 14px; line-height: 16px; word-break: break-word;\" data-mce-style=\"font-size: 14px; line-height: 16px; word-break: break-word;\">I'm a new Text block ready for your content.</p></div>",
                            "style": {
                                "color": "#000",
                                "font-family": "inherit",
                                "line-height": "120%"
                            }
                        }
                    },
                    "locked": true,
                    "type": "mailup-bee-newsletter-modules-text",
                    "uuid": "11899f69-c06d-4de9-9502-25d2a28a495e"
                }],
                "style": {
                    "background-color": "transparent",
                    "border-bottom": "0px solid transparent",
                    "border-left": "0px solid transparent",
                    "border-right": "0px solid transparent",
                    "border-top": "0px solid transparent",
                    "padding-bottom": "5px",
                    "padding-left": "0px",
                    "padding-right": "0px",
                    "padding-top": "5px"
                },
                "uuid": "09dfb035-3ee2-424b-904d-72825db38d63"
            }],
            "container": {
                "style": {
                    "background-color": "transparent",
                    "background-image": "none",
                    "background-position": "top left",
                    "background-repeat": "no-repeat"
                }
            },
            "content": {
                "computedStyle": {
                    "rowColStackOnMobile": true,
                    "rowReverseColStackOnMobile": false
                },
                "style": {
                    "background-color": "transparent",
                    "background-image": "none",
                    "background-position": "top left",
                    "background-repeat": "no-repeat",
                    "color": "#000000",
                    "width": "500px"
                }
            },
            "metadata": {
                "name": "empty-block"
            },
            "type": "one-column-empty",
            "uuid": "aca1ee65-e292-4f78-8c49-31fb8c6054de"
        }, {
            "container": {
                "style": {
                    "background-color": "transparent",
                    "background-image": "none",
                    "background-repeat": "no-repeat",
                    "background-position": "top left"
                }
            },
            "content": {
                "style": {
                    "background-color": "transparent",
                    "color": "#000000",
                    "width": "500px",
                    "background-image": "none",
                    "background-repeat": "no-repeat",
                    "background-position": "top left"
                },
                "computedStyle": {
                    "rowColStackOnMobile": true,
                    "rowReverseColStackOnMobile": false
                }
            },
            "columns": [{
                "style": {
                    "background-color": "transparent",
                    "border-bottom": "0px solid transparent",
                    "border-left": "0px solid transparent",
                    "border-right": "0px solid transparent",
                    "border-top": "0px solid transparent",
                    "padding-bottom": "5px",
                    "padding-left": "0px",
                    "padding-right": "0px",
                    "padding-top": "5px"
                },
                "modules": [{
                    "type": "mailup-bee-newsletter-modules-text",
                    "descriptor": {
                        "text": {
                            "html": "<div class=\"txtTinyMce-wrapper\" style=\"font-size: 14px; line-height: 16px;\" data-mce-style=\"font-size: 14px; line-height: 16px;\"><p style=\"font-size: 14px; line-height: 16px; word-break: break-word;\" data-mce-style=\"font-size: 14px; line-height: 16px;\">©2018 - 2023 CLOUT</p></div>",
                            "style": {
                                "color": "#000",
                                "line-height": "120%",
                                "font-family": "inherit"
                            },
                            "computedStyle": {
                                "linkColor": "#0068A5"
                            }
                        },
                        "style": {
                            "padding-top": "10px",
                            "padding-right": "10px",
                            "padding-bottom": "10px",
                            "padding-left": "10px"
                        },
                        "computedStyle": {
                            "hideContentOnMobile": false
                        }
                    },
                    "locked": true,
                    "uuid": "96498b29-c452-4dda-9599-c1ef8e2113d3"
                }, {
                    "type": "mailup-bee-newsletter-modules-text",
                    "descriptor": {
                        "text": {
                            "html": "<div class=\"txtTinyMce-wrapper\" style=\"font-size: 14px; line-height: 16px;\" data-mce-style=\"font-size: 14px; line-height: 16px;\"><p style=\"font-size: 14px; line-height: 16px; word-break: break-word;\" data-mce-style=\"font-size: 14px; line-height: 16px;\">This message was sent to {$email} and intended for <code spellcheck=\"false\" data-bee-type=\"mergetag\" data-bee-code=\"\" data-bee-name=\"First Name\">{$first_name}</code>&nbsp;&nbsp;.<br>Not your account? <a href=\"{$unsubscribe_link}\" target=\"_blank\" rel=\"noopener\">Remove your email</a> from this account.</p></div>",
                            "style": {
                                "color": "#000",
                                "line-height": "120%",
                                "font-family": "inherit"
                            },
                            "computedStyle": {
                                "linkColor": "#0068A5"
                            }
                        },
                        "style": {
                            "padding-top": "10px",
                            "padding-right": "10px",
                            "padding-bottom": "10px",
                            "padding-left": "10px"
                        },
                        "computedStyle": {
                            "hideContentOnMobile": false
                        }
                    },
                    "uuid": "2ec1f5a7-fa5a-4b26-a2fa-7d2294b08ea4"
                }],
                "grid-columns": 12,
                "uuid": "5ffb28c0-3b6c-45b4-a61d-b15b442ae184"
            }],
            "metadata": {
                "name": "unsubscribe-block"
            },
            "type": "one-column-empty",
            "uuid": "1c781ba6-a56f-4714-8cdd-5552a3053c2f"
        }],
        "template": {
            "name": "template-base",
            "type": "basic",
            "version": "0.0.1"
        },
        "title": "Template Base"
    },
    "comments": {}
}