import React from 'react';
import ReactSkeleton from 'react-loading-skeleton';
import { Header, List, Wrapper } from './styles';

function Skeleton() {
  return (
    <Wrapper>
      <Header>
        <h3>Recommended Topics</h3>
        <p>Based on your engagement</p>
      </Header>
      <List>
        {Array.from(Array(5).keys())?.map((index) => (
          <ReactSkeleton width={200} height={40} key={index} />
        ))}
      </List>
    </Wrapper>
  );
}

export default Skeleton;
