// Neutrals
export const white = '#FFFFFF';
export const white_01 = '#FAFAFA';
export const white_02 = '#F1F1F1';
export const white_03 = '#E9E0FF';

export const black = '#000000';
export const black_01 = '#101828';

export const light_gray_02 = '#98A2B3';

// Colors
export const green = '#00B186';
export const light_green = '#EBF5F0';

export const orange = '#FFA32C';
export const light_orange = '#FFF2E7';

export const blue = '#2196F3';
export const dark_blue = '#6250ED';
export const light_blue = '#ECF2FC';

export const yellow = '#FDD835';

export const purple = '#7B73E4';
export const clout_purple = '#816EF8';
export const light_purple = '#F8F7FF';
export const header_purple = '#4634D0';

export const red = '#FA2238';
export const light_red = '#FFD7D7';

//Features
export const gray_border = '#E4E7EC';
export const light_gray_text = '#667085';
export const gray_background = '#F9FAFB';
export const gray_background_01 = '#FCFDFD';
export const gray_text = '#475467';

export const blue_link_text = '#0B5CD7';
export const hover_background = '#F2F4F7';
export const hover_purple = '#6351ed';
export const disabled_button = '#F2F4F7';


export const off_white_background = '#F6F2FF';


export const purple_border = '#98A2B3';
export const purple_border_light = '#CABEFF';
export const purple_hover = '#5946ea';
export const purple_gradient = `linear-gradient(${clout_purple},${purple})`;
