import { styled, Grid } from '@mui/material';

export const TablistWrapper = styled(Grid)(({ variant = 'primary' }) =>
  variant === 'outline'
    ? {
        borderBottom: '1px solid #E4E7EC',
        userSelect: 'none',
        overflowX: 'auto',
      }
    : {
        userSelect: 'none',
        overflowX: 'auto',
      }
);

const tabVariant = (variant, isActive, singleTabMode = false) => {
  if (variant === 'outline') {
    return {
      width: 'fit-content',
      color: isActive ? '#6250ED' : '#475467',
      textTransform: 'capitalize',
      borderBottom: `2px solid ${isActive ? '#6250ED' : 'transparent'}`,
    };
  } else
    return {
      width: 'fit-content',
      color: isActive ? '#FCFDFD' : '#6250ED',
      backgroundColor: isActive ? '#6250ED' : 'transparent',
      border: '1px solid #E4E7EC',

      '&:first-child': {
        borderRadius: singleTabMode ? '0.5rem' : '0.5rem 0 0 0.5rem',
      },
      '&:last-child': {
        borderRadius: singleTabMode ? '0.5rem' : '0 0.5rem 0.5rem 0',
      },
    };
};

export const TablistWrapperButton = styled(Grid)(
  ({
    isActive,
    variant = 'primary',
    singleTabMode = false,
    disabled = false,
  }) => ({
    cursor: disabled ? 'not-allowed' : 'pointer',
    padding: '0.75rem 1rem',
    fontSize: '0.875rem',
    fontWeight: '500',
    userSelect: 'none',
    transition: 'all 0.25s ease-in-out',

    ...(disabled
      ? {
          color: '#BEC4CC',
          backgroundColor: '#F2F4F8',
        }
      : tabVariant(variant, isActive, singleTabMode)),
  })
);
