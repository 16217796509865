import React from 'react';
import { useLocation } from 'react-router-dom';

import useRESTQuery from 'src/hooks/useRESTQuery';
import { transformData } from './utils';
import Skeleton from './Skeleton';
import { TableHeader } from '../EmailDeliverability/styles';
import { tableColumnStructure } from './TableColumnStructure';
import { EmptyState } from 'src/componentsV2';
import Table from 'src/componentsV2/Table';
import { ForwardFriendWrapper } from './styles';

function ForwardTable({ campaignId = '', hasSubCampaignSelected = false }) {
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const type = queryParams.get('type');

  const { isLoading, data } = useRESTQuery({
    key: 'forward-lists',
    endpoint: `/forward_friend/list/${campaignId}?campaign_type=${type}&is_block=${hasSubCampaignSelected}`,
    dependencies: [campaignId, type],
    options: {
      select: (res) => {
        if (Array.isArray(res?.result.data)) {
            console.log(res?.result.data)
          return res?.result?.data?.map((result) => transformData(result));
        }
        return [];
      },
    },
  });

  console.log(data);
  if (isLoading) return <Skeleton />;
  
  return (
    <ForwardFriendWrapper>
      <TableHeader>
        <h2>Forward A Friend</h2>
      </TableHeader>
      <Table
        data={data}
        columnStructure={tableColumnStructure()}
        customStyles={{
          borderRadius: '0 0 0.5rem 0.5rem',
        }}
        fixedHeader
        height="550px"
         customEmptyState={<EmptyState height="192px" title="No Analytics Data" />}
      />
    </ForwardFriendWrapper>
  );
}

export default ForwardTable;
