import React from 'react'
import ComplianceApproveContainer from '../../containers/ComplianceApproveContainer/ComplianceApproveContainer';
import withContentLayout from '../../hoc/withContentLayout';

const ComplianceApproveContainerLayout = () => {
    return (
        <ComplianceApproveContainer />
    )
}

export default withContentLayout(ComplianceApproveContainerLayout);
