import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import useRESTQuery from 'src/hooks/useRESTQuery';
import Skeleton from './Skeleton';

import {
  Article,
  ArticleAndFormatContainer,
  ArticleContainer,
  ArticleFormat,
  ArticleIcon,
  ArticleInfoContainer,
  ArticleTitle,
  CampaignContentHeader,
  CampaignContentWrapper,
  Pill,
  Pills,
} from './styles';

import BookArticle from 'src/assets/icons/BookArticleIcon';
import { getLpImage, getMediaImage, onImageError } from 'src/utils';

function CampaignContent({ campaignId = '', hasSubCampaignSelected = false }) {
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const type = queryParams.get('type');
  const [isExpanded, setIsExpanded] = useState(false);

  const { isLoading, data } = useRESTQuery({
    key: 'campaign-content',
    endpoint: `/analytics/detailed/campaign/${campaignId}/content?campaign_type=${type}&is_block=${hasSubCampaignSelected}`,
    dependencies: [campaignId, type],
    options: {
      select: (res) => {
        if (Array.isArray(res?.result)) return res?.result;
        else return [];
      },
    },
  });
  const dataToDisplay = Array.isArray(data) && data?.length > 0 ? data : [];

  const handleExpandChange = (_, newExpanded) => {
    if (data.length > 0) {
      setIsExpanded(newExpanded);
    }
  };

  if (isLoading) return <Skeleton />;

  return (
    <CampaignContentWrapper>
      <Accordion
        elevation={0}
        expanded={isExpanded}
        onChange={handleExpandChange}
      >
        <AccordionSummary
          expandIcon={data?.length ? <ExpandMoreIcon /> : <></>}
          aria-controls="campaign-content"
          id="campaign-content"
        >
          <CampaignContentHeader>
            <h2>Campaign Content</h2>
            <p>
              {dataToDisplay?.length ?? 0}{' '}
              {data?.length > 1 ? 'Articles' : 'Article'}
            </p>
          </CampaignContentHeader>
        </AccordionSummary>
        <AccordionDetails>
          {dataToDisplay?.map((item, index) => {
            const totalTags = item?.tags?.length ?? 0;
            const minimumTags = item?.tags?.slice(0, 5);
            const remainingTags = totalTags - minimumTags?.length;
            const articleImage = item?.format === 'Landing Page' ? getLpImage(item?.media_url) : getMediaImage(item?.media_url);
            return (
              <ArticleContainer key={index}>
                <Article>
                  <ArticleIcon>
                    {item?.media_url ? (
                      <img
                        src={articleImage}
                        alt="article-img"
                        width="80"
                        height="80"
                        onError={onImageError}
                      />
                    ) : (
                      <BookArticle />
                    )}
                  </ArticleIcon>
                  <ArticleInfoContainer>
                    <ArticleAndFormatContainer>
                      <ArticleTitle>{item?.name ?? '-'}</ArticleTitle>
                      <ArticleFormat>
                        <p>{item?.format ?? '-'}</p>
                      </ArticleFormat>
                    </ArticleAndFormatContainer>
                    <Pills>
                      {minimumTags?.map((tag) => (
                        <Pill key={tag?.id}>{tag ?? '-'}</Pill>
                      ))}
                      {remainingTags ? <Pill>+{remainingTags}</Pill> : <></>}
                    </Pills>
                  </ArticleInfoContainer>
                </Article>
              </ArticleContainer>
            );
          })}
        </AccordionDetails>
      </Accordion>
    </CampaignContentWrapper>
  );
}

export default CampaignContent;
