import React, { useMemo } from 'react';
import { connect } from 'react-redux';

import {
  ArticleInfo,
  PostLink,
  PostPreviewContainer,
  PostTitle,
  SocialMediaImageContainer,
  SocialMediaPost,
  SocialMediaPostInfoContainer,
  SocialMediaPreviewWrapper,
  SocialPreviewContainer,
  UserMessage,
  UserProfile,
  UserProfileContainer,
} from './styles';

import ProfileIcon from 'src/assets/icons/navIcons/ProfileIcon';
import {
  getAutomatedPostInfo,
  getSeriesPostInfo,
  platformKeys,
  renderUserProfileInfo,
  sliceUrlToDotCom,
} from './utils';
import { getMediaImage } from 'src/utils';

function SocialPreview({
  data,
  seriesTypeCampaign = false,
  userDetails,
  selectedPlatform,
}) {
  const userName = userDetails?.details?.user?.name ?? '';

  const postTitle = useMemo(() => {
    return seriesTypeCampaign
      ? data?.posts?.[selectedPlatform?.mappingKey]?.override_text
      : data?.articles?.[0]?.title;
  }, [data, selectedPlatform, seriesTypeCampaign]);

  const postInfo = useMemo(
    () =>
      seriesTypeCampaign
        ? getSeriesPostInfo(data, selectedPlatform)
        : getAutomatedPostInfo(data, selectedPlatform),
    [data, selectedPlatform, seriesTypeCampaign]
  );

  return (
    <SocialPreviewContainer>
      <SocialMediaPreviewWrapper>
        <UserProfile>
          <UserProfileContainer>
            <ProfileIcon />
          </UserProfileContainer>
          <div>
            {renderUserProfileInfo(selectedPlatform, userName, postTitle)}
          </div>
        </UserProfile>
        {selectedPlatform?.value !== platformKeys.TWITTER && (
          <UserMessage>{postInfo?.userMessage ?? ''}</UserMessage>
        )}

        <PostPreviewContainer>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={postInfo?.postLink ?? ''}
          >
            <SocialMediaImageContainer>
              <SocialMediaPost
                src={
                  seriesTypeCampaign
                    ? getMediaImage(postInfo?.mediaURL)
                    : postInfo?.mediaURL ||
                      'assets/images/Campaign/Default-image.png'
                }
                alt="preview"
              />
            </SocialMediaImageContainer>
            <SocialMediaPostInfoContainer>
              <PostLink>{sliceUrlToDotCom(postInfo?.postLink ?? '')}</PostLink>
              <PostTitle>{postInfo?.postTitle ?? ''}</PostTitle>
              <ArticleInfo>{postInfo?.articleInfo ?? ''}</ArticleInfo>
            </SocialMediaPostInfoContainer>
          </a>
        </PostPreviewContainer>
      </SocialMediaPreviewWrapper>
    </SocialPreviewContainer>
  );
}

const mapStateToProps = (state) => {
  return {
    userDetails: state.user.data,
  };
};

export default connect(mapStateToProps)(SocialPreview);
