import { Flex } from '@rebass/grid';
import { get } from 'lodash';
import React from 'react';
import { Button } from '../../../components';
import {
    TitleSpan,
  } from '../../DripCampaignContainerV2/CreateCampaign/CreateCampaign.styles';
  import {
    CongratsContainer,
  } from './CampaignNextCongrats.styles';

  const CampaignNextCongrats = ({
    reviewCampaign,
    detailsHandler
  }) => {

    return (
        <Flex justifyContent="center" alignItems="center">
            <CongratsContainer>
                <TitleSpan>Congrats!</TitleSpan>
                <Flex width="100%" color="#000" fontSize="12px" justifyContent="center" mt="5px">The first campaign in the series has been scheduled. Click below to schedule the next campaign.</Flex>
                <Flex width="100%" color="#000" fontSize="14px" justifyContent="center" mt="5px">Schedule the next campaign in the series by clicking on the button below.</Flex>
    
                <Button
                  borderRadius="5px"
                  margin="20px 0px 20px 0px;"
                  id="btn_one_time"
                  onClick={() => detailsHandler(get(reviewCampaign, 'campaigns[0]', ''), null)}
                >
                  Schedule Next Campaign
                </Button>
            </CongratsContainer>
        </Flex>
    );
};


export default CampaignNextCongrats;