/* eslint-disable react-hooks/exhaustive-deps */
import { Flex } from '@rebass/grid';
import React, { useState, useEffect } from 'react';
import DripDashboardFilter from '../DripDashboardFilter/DripDashboardFilter';
import DripDashboardTable, {
  typeObj,
} from '../DripDashboardTable/DripDashboardTable';
import { get, isEmpty } from 'lodash';
import useQuery from '../../../customHooks/useQuery';
import { getCustomRows } from '../../../services/bee-editor';
import CreateCampaignModal from '../DripDashboardTable/CreateCampaignModalsContainer/CreateCampaignModal';

const DripCampaignDashboard = ({
  createFlow,
  setCreateFlow,
  campaignSetState,
  campaignFetching,
  campaignsData,
  socialThemes,
  editCampaignHandler,
  editLoader,
  setCategory,
  category,
  campaignRequest,
  automatedDashboard,
  detailsHandler,
  memberAdvisorsArray,
  changeCreateFlow,
  addCampaignFlow,
  isadvMode,
  userData,
  isEnterpriseAdvisor,
  isEnterpriseLevelAdmin,
  isFirmLevelAdmin,
  firmSettings,
  firmsArray,
  segmentList,
  seriesSubcampaigns,
  editSeriesLevel
}) => {
  const filterBtns = [
    { name: 'FILTER', img: '/assets/images/campaign-filter.svg' },
    // { name: 'CAMPAIGN', img: '/assets/images/campaign-plus.svg' },
  ];

  const [openCreateCampaignModal, setOpenCreateCampaignModal] = useState(false);

  const [campaignDetailsView, setCampaignDetailsView] = useState(false);
  const [filterTabs, updateFilterTabs] = useState([
    {
      id: 0,
      name: 'All Campaigns',
      img: {
        active: '/assets/images/campaign-mic.svg',
        disabled: '/assets/images/campaign-mic-disabled.svg',
      },
      count: 0,
      tab: 'all',
    },
    {
      id: 1,
      name: 'Drafts',
      img: {
        active: '/assets/images/drip/campaign-drafts.svg',
        disabled: '/assets/images/campaign-drafts-disabled.svg',
      },
      count: 0,
      tab: 'draft',
    },
    ...(isadvMode
      ? [
          {
            id: 2,
            name: 'Approval Pending',
            img: {
              active: '/assets/images/campaign-pending-approval.svg',
              disabled: '/assets/images/campaign-pending-approval-disabled.svg',
            },
            count: 0,
            tab: 'approval_pending',
          },
          {
            id: 3,
            name: 'Approval Rejected',
            img: {
              active: '/assets/images/campaign-rejected-active.svg',
              disabled: '/assets/images/campaign-rejected-disabled.svg',
            },
            count: 0,
            tab: 'approval_rejected',
          },
        ]
      : []),
    ...(userData &&
    (get(userData, 'compliance', '') === 'optional' ||
      get(userData, 'compliance', '') === 'on')
      ? [
          {
            id: 4,
            name: 'Compliance Pending',
            img: {
              active: '/assets/images/compliance_pending_active.svg',
              disabled: '/assets/images/compliance_pending_disabled.svg',
            },
            count: 0,
            tab: 'compliance_pending',
          },
          {
            id: 5,
            name: 'Compliance Rejected',
            img: {
              active: '/assets/images/compliance_rejected_active.svg',
              disabled: '/assets/images/compliance_rejected_disabled.svg',
            },
            count: 0,
            tab: 'compliance_rejected',
          },
        ]
      : []),
    {
      id: 6,
      name: 'Scheduled',
      img: {
        active: '/assets/images/drip/campaign-scheduled.svg',
        disabled: '/assets/images/campaign-scheduled-disabled.svg',
      },
      count: 0,
      tab: 'scheduled',
    },
    {
      id: 7,
      name: 'In Progress',
      img: {
        active: '/assets/images/drip/campaign-in-progress.svg',
        disabled: '/assets/images/campaign-in-progress-disabled.svg',
      },
      count: 0,
      tab: 'in_progress',
    },
    {
      id: 8,
      name: 'Completed',
      img: {
        active: '/assets/images/drip/campaign-completed.svg',
        disabled: '/assets/images/campaign-completed-disabled.svg',
      },
      count: 0,
      tab: 'completed',
    },
    ...(isEnterpriseAdvisor
      ? [
          {
            id: 9,
            name: 'Approved To Share',
            img: {
              active: '/assets/images/drip/campaign-completed.svg',
              disabled: '/assets/images/campaign-completed-disabled.svg',
            },
            count: 0,
            tab: 'approved_to_share',
          },
        ]
      : []),
  ]);

  const [selectedCampaignTab, setSelectedCampaignTab] = useState(0);
  const [fullCampaignData, setFullCampaignData] = useState([]);
  const [filters, setFilters] = useState();
  const [blockCreate, toggleBlockCreate] = useState(true);

  useEffect(() => {
    if (!isEmpty(filters) && !automatedDashboard) {
      campaignRequest(filters);
      setSelectedCampaignTab();
      setCategory('');
    }
  }, [filters]);

  useEffect(() => {
    if (!isEmpty(campaignsData)) {
      const filterTabCopy = [...filterTabs];
      filterTabCopy.forEach((filterTab) => {
        filterTab.count =
          (campaignsData &&
            campaignsData?.details &&
            campaignsData?.details[filterTab.tab]) ||
          0;
      });

      // setFullCampaignData(campaignsData?.campaigns);

      setFullCampaignData(
        category === typeObj.automated
          ? campaignsData?.automatedCampaigns
          : campaignsData?.campaigns
      );
      updateFilterTabs(filterTabCopy);
    }
    campaignSetState({ seriesSubcampaigns: {} });
  }, [campaignsData]);

  useEffect(() => {
    campaignSetState({
      editMode: false,
      createMode: false,
      curFlow: '',
      selectedIndex: 0,
      fromDashboard: false,
      selectedArticles: [],
    });
  }, []);

  useEffect(() => {
    getCustomRows('signature')
      .then((response) => {
        // console.log('CUSTOM ROWS RESPONSE - - - - -', response);
        if (get(response, 'data', []).length > 0) toggleBlockCreate(false);
      })
      .catch((err) => console.log('ERR- - -- - - ', err));
  }, []);

  const onFilterTabChange = (index) => {
    // if (index === 1) {
    //   campaignSetState({
    //     createFlow: true,
    //     campaignId: '',
    //     setObjectives: {},
    //   });
    // }
    setSelectedCampaignTab(index);
    setFilters();
    // setCategory('');
    // filterTabs[index].name.toLowerCase()
    let tabSelectedData =
      !isEmpty(campaignsData?.campaigns) && campaignsData?.campaigns;

    if (index === 0) campaignRequest();
    else if (index === 9) 
      campaignRequest('approved_to_share');
    else {
      let selectedTab = filterTabs.filter(
        (filterTab) => filterTab.id === index
      );
      if (!isEmpty(selectedTab)) {
        campaignRequest({ status: [selectedTab[0].tab] });
        tabSelectedData =
          !isEmpty(campaignsData?.campaigns) &&
          campaignsData.campaigns.filter(
            (ele) => ele.status === selectedTab[0].tab
          );
      }
    }
    if (index !== 9) setFullCampaignData(tabSelectedData);
    setCategory('');
  };

  const query = useQuery();
  let advisor_notification = query.get('advisor_notification');
  let compliance_redirect = query.get('tab');

  useEffect(() => {
    if (advisor_notification) {
      window.setTimeout(() => {
        onFilterTabChange(2);
      }, 2000);
    }
    if (compliance_redirect === 'compliance_rejected') {
      window.setTimeout(() => {
        onFilterTabChange(5);
      }, 2000);
    }
  }, []);

  const handleCreateCampaignClick = () => {
    setOpenCreateCampaignModal(true);
  };

  return (
    <Flex width="100%" height="100%" flexDirection="column">
      {!campaignDetailsView && (
        <DripDashboardFilter
          createFlow={createFlow}
          setCreateFlow={setCreateFlow}
          filterTabs={filterTabs}
          filterBtns={filterBtns}
          selectedCampaignTab={selectedCampaignTab}
          setSelectedCampaignTab={(index) => onFilterTabChange(index)}
          setCampaignDetailsView={setCampaignDetailsView}
          campaignDetailsView={campaignDetailsView}
          setFilters={setFilters}
          filters={filters}
          campaignRequest={campaignRequest}
          changeCreateFlow={changeCreateFlow}
          isadvMode={isadvMode}
          userData={userData}
          isEnterpriseAdvisor={isEnterpriseAdvisor}
        />
      )}
      <DripDashboardTable
        data={fullCampaignData}
        isFetching={campaignFetching}
        paginationData={get(campaignsData, 'pagination', {
          before: null,
          after: null,
        })}
        activeTab={
          filterTabs.filter((tab) => tab.id === selectedCampaignTab)[0]
        }
        socialThemes={socialThemes}
        setCampaignDetailsView={setCampaignDetailsView}
        campaignDetailsView={campaignDetailsView}
        editCampaignHandler={editCampaignHandler}
        editLoader={editLoader}
        filters={filters}
        setFilters={setFilters}
        campaignRequest={campaignRequest}
        automatedDashboard={automatedDashboard}
        detailsHandler={detailsHandler}
        setSelectedCampaignTab={setSelectedCampaignTab}
        onFilterTabChange={onFilterTabChange}
        setCategory={setCategory}
        category={category}
        selectedCampaignTab={selectedCampaignTab}
        campaignsData={campaignsData}
        addCampaignFlow={addCampaignFlow}
        isadvMode={isadvMode}
        campaignSetState={campaignSetState}
        blockCreate={blockCreate}
        isEnterpriseLevelAdmin={isEnterpriseLevelAdmin}
        isEnterpriseAdvisor={isEnterpriseAdvisor}
        isFirmLevelAdmin={isFirmLevelAdmin}
        firmSettings={firmSettings}
        firmsArray={firmsArray}
        memberAdvisorsArray={memberAdvisorsArray}
        segmentList={segmentList}
        seriesSubcampaigns={seriesSubcampaigns}
        handleCreateCampaignClick={handleCreateCampaignClick}
        editSeriesLevel={editSeriesLevel}
      />
      {openCreateCampaignModal && (
        <CreateCampaignModal 
          isOpen={openCreateCampaignModal}
          handleClose={() => setOpenCreateCampaignModal(false)}
          campaignSetState={campaignSetState}
        />
      )}
    </Flex>
  );
};

export default DripCampaignDashboard;
