import { createActions, handleActions } from 'redux-actions';

export const actions = createActions({
  setEnterpriseStatus: (isEnterprise, advisors) => ({ isEnterprise, advisors }),
  setAnalyticsDate: (date) => ({ date }),
  clearState: () => ({})
});

const initialState = { isEnterprise: false };

const reducer = handleActions(
  {
    [actions.setEnterpriseStatus]: (state, { payload }) => ({
      ...state,
      isEnterprise: payload.isEnterprise,
      advisors: payload.advisors || []
    }),
    [actions.setAnalyticsDate]: (state, { payload }) => {
      return {
        ...state,
        date: payload.date
      }
      
    },
    [actions.clearState]: () => ({
      ...initialState
    })
  },
  { ...initialState }
);

export default reducer;
