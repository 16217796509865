import React from 'react';
import { Tooltip } from '@mui/material';
import EngagementScoreTooltip from '../../common/EngagementScoreTooltip';
import {
  EngagementScoreColumn,
  TableBody,
  TooltipStyles,
  TruncatedTableBody,
} from './styles';

const engagementScoreClassName = (score) => {
  if (score > 50) return 'above-fifty';
  else return 'below-fifty';
};

export const tableColumnStructureForTopics = () => {
  return [
    {
      header: {
        key: 'engagementScore',
        title: <EngagementScoreTooltip />,
      },
      maxWidth: '300px',
      render: (row) => {
        const score = row?.engagement_score ?? 0;
        return (
          <EngagementScoreColumn className={engagementScoreClassName(score)}>
            {score ?? '-'}%
          </EngagementScoreColumn>
        );
      },
    },
    {
      header: {
        key: 'topic',
        title: 'Topic',
      },
      maxWidth: '450px',
      render: (row) => (
        <Tooltip
          title={row?.topic || '-'}
          PopperProps={{
            sx: TooltipStyles,
          }}
        >
          <TruncatedTableBody>
            {row?.topic || '-'}
          </TruncatedTableBody>
        </Tooltip>
      ),
    },
    {
      header: {
        key: 'contacts',
        title: 'No. of Contacts',
      },
      maxWidth: '300px',
      render: (row) => <TableBody>{row?.contacts || '-'}</TableBody>,
    },
    {
      header: {
        key: 'content',
        title: 'Content',
      },
      maxWidth: '300px',
      render: (row) => <TableBody>{row?.content || 0}</TableBody>,
    },
  ];
};
export const tableColumnStructureForContent = () => {
  return [
    {
      header: {
        key: 'engagementScore',
        title: <EngagementScoreTooltip selectedTab="CONTENT" />,
      },
      maxWidth: '300px',
      render: (row) => {
        const score = row?.engagement_score ?? 0;
        return (
          <EngagementScoreColumn className={engagementScoreClassName(score)}>
            {score ?? '-'}%
          </EngagementScoreColumn>
        );
      },
    },
    {
      header: {
        key: 'name',
        title: 'Name',
      },
      maxWidth: '800px',
      render: (row) => (
        <Tooltip
          title={row?.name || '-'}
          PopperProps={{
            sx: TooltipStyles,
          }}
        >
          <TruncatedTableBody>
            {row?.name || '-'}
          </TruncatedTableBody>
        </Tooltip>
      ),
    },
    {
      header: {
        key: ' contact_count',
        title: 'No. of Contacts',
      },
      maxWidth: '200px',
      render: (row) => <TableBody>{row?.contact_count || '-'}</TableBody>,
    },
    {
      header: {
        key: 'click',
        title: 'Clicks',
      },
      maxWidth: '200px',
      render: (row) => <TableBody>{row?.click || '-'}</TableBody>,
    },
  ];
};
export const tableColumnStructureForLinks = () => {
  return [
    {
      header: {
        key: 'engagementScore',
        title: <EngagementScoreTooltip selectedTab="CONTENT" />,
      },
      maxWidth: '400px',
      render: (row) => {
        const score = row?.engagement_score ?? 0;
        return (
          <EngagementScoreColumn className={engagementScoreClassName(score)}>
            {score ?? '-'}%
          </EngagementScoreColumn>
        );
      },
    },
    {
      header: {
        key: 'link',
        title: 'Link URL',
      },
      maxWidth: '800px',
      render: (row) => (
        <Tooltip
          title={row?.link || '-'}
          PopperProps={{
            sx: TooltipStyles,
          }}
        >
          <TruncatedTableBody
            cursor="pointer"
            onClick={() => window.open(row?.link)}
          >
            {row?.link || '-'}
          </TruncatedTableBody>
        </Tooltip>
      ),
    },
    {
      header: {
        key: 'contacts',
        title: 'No. of Contacts',
      },
      maxWidth: '200px',
      render: (row) => <TableBody>{row?.contacts || '-'}</TableBody>,
    },
    {
      header: {
        key: 'clicks',
        title: 'Clicks',
      },
      maxWidth: '200px',
      render: (row) => <TableBody>{row?.clicks || '-'}</TableBody>,
    },
  ];
};
