import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import {
  ThemeContainer,
  themesPage,
  ThemeWrapper,
  ActiveTheme,
  TopicsHeader,
  SearchCategory,
  SearchResults,
  SearchResultsContainer,
  SelectThemes,
  AddedChannels,
  ListItem,
  SMP,
  SearchInspiration,
  SearchInspirationDiv,
  SearchInspirationHeader,
  Popular,
  PopularHeader,
  List,
  ListWrapper,
  PopularModalContent,
  CustomModal,
} from './Theme.styles';

import {
  Tile,
  SearchInput,
  ModalHeader,
  Modal,
  ModalCloseButton,
  ListItemTile,
  ModalBody,
} from '../../components';
import {
  geYourTheme,
  getPopularTheme,
  subscribeTheme,
  unsubscribeTheme,
  getSearchTheme,
  getTrendingTopics,
} from '../../services/dashboard';
import { actions as themeActions } from '../../redux/modules/theme.module';
import {
  Suggest,
  SuggestHeader,
  ComponentSection,
  CustomInput,
  CustomModalButton,
  SuggestLink,
} from '../ChannelContainer/Channel.styles';
import { Formik } from 'formik';

const Theme = (props) => {
  const [yourTopics, setYourTopics] = useState(null);
  const [popular, setPopular] = useState(null);
  const [searchResults, setSearchResults] = useState(null);
  const [searchInspiration, setSearchInspiration] = useState([]);
  const [suggestModal, setSuggestModal] = useState(false);
  const [searchPlaceholder, setSearchPlaceholder] = useState(
    "Search for a topic ('e.g.' : 'Technology')"
  );
  const [messageModal, setMessageModal] = useState({
    message: '',
    isOpen: false,
  });

  useEffect(() => {
    const getThemes = async () => {
      const [YourThemes, trendingThemes,popularThemes] = await Promise.all([
        geYourTheme(),
        getTrendingTopics(),
        getPopularTheme(),
      ]);
      if (YourThemes.result.data.length === 0) setPopular([]);
      else {
        setPopular(popularThemes.result.data)
        let themes = _.filter(
          YourThemes.result.data,
          (theme) =>
            theme.subscription !== undefined &&
            theme.subscription.status.application_status === 'approved'
        );
        setYourTopics(themes);
      };

      if (trendingThemes.result.data.length === 0) setSearchInspiration([]);
      else setSearchInspiration(trendingThemes.result.data.topics);
    };
    getThemes();
    return () => {
      props.themesRequest();
    };
  }, []);

  const closeMessageModal = () => {
    setMessageModal({ message: '', isOpen: false });
  };

  const handleYourTopicsClick = (theme, index) => {
    //remove from yourtopics
    if (yourTopics.length === 1) {
      setMessageModal({
        message: 'You must be subscribed to atleast one topic',
        isOpen: true,
      });
      return;
    }
    unsubscribeTheme(theme.id)
      .then((res) => {
        let yTopics = [...yourTopics];
        yTopics.splice(index, 1);
        setYourTopics(yTopics);
        props.themesRequest({
          params: {
            noRefresh: true,
          },
        });
      })
      .catch((err) => {});
  };

  const searchByText = (collection, text) => {
    if (text === '') {
      setSearchResults(null);
      return;
    }
    getSearchTheme(text)
      .then((res) => {
        setSearchResults(res.result.data.splice(0, 5));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handlePopularClick = (theme, index) => {
    subscribeTheme(theme.id)
      .then((res) => {
        if (res.result.success) {
          let yTopics = [...yourTopics];
          yTopics.push(theme);
          setYourTopics(yTopics);
        }
        props.themesRequest({
          params: {
            noRefresh: true,
          },
        });
      })
      .catch((err) => {
        if(err.code == 2515) {
          setMessageModal({
            message: 'You have subscribed to maximum number of topics allowed (15).',
            isOpen: true,
          });
        }
        else if(err.code == 2511) {
          setMessageModal({
            message: 'You have Already Subscribed to this Topic.',
            isOpen: true,
          });
        }
        else {
          setMessageModal({
            message: err.message,
            isOpen: true,
          });
        }
      });
  };

  const handleSearchInspirationClick = (theme, index) => {
    console.log(theme, index);
    getSearchTheme(theme.name)
      .then((res) => {
        setSearchPlaceholder(theme.name);
        setSearchResults(res.result.data.splice(0, 5));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleSuggestTopicSubmit = (values) => {
    console.log(values);
  };

  const getImages = (theme) => {
    if (theme && theme.media_urls && theme.media_urls[0].name.resized)
      return `${theme.media_urls[0].path}/${theme.media_urls[0].name.resized}.${theme.media_urls[0].ext}`;
    return `/assets/images/Investing.png`;
  };

  const searchHandler = value => {
    if(value.length>=3) {
      const filteredThemes = props.themes.filter(theme => theme.name.toLowerCase().includes(value.toLowerCase()));
      setSearchResults(filteredThemes);
    }
    else {
      setSearchResults(null);
    }
  }

  return (
    <ThemeWrapper>
      <ThemeContainer>
        <ActiveTheme>
          <TopicsHeader>My Topics</TopicsHeader>
          <SelectThemes>
            <SearchCategory>
              <AddedChannels>
                {yourTopics ? (
                  yourTopics.map((theme, index) => (
                    <ListItemTile
                      key={theme.id}
                      id={theme.id}
                      url={getImages(theme)}
                      topic={theme.name}
                      type="minus"
                      handleClick={() => handleYourTopicsClick(theme, index)}
                    />
                  ))
                ) : (
                  <img src="/assets/images/loaderV.gif" alt="loader" />
                )}
              </AddedChannels>
            </SearchCategory>
          </SelectThemes>
        </ActiveTheme>
        <SMP>Find More Topics</SMP>

        <SearchInput
          name="partnersearch"
          id="partnersearch"
          placeholder={searchPlaceholder}
          largeInput
          onSearchClick={(value) => searchByText(props.themes, value)}
          // onChange={(value) => {
          //   if (value.trim() === '') {
          //     searchByText(props.themes, value);
          //   }
          // }}
          onChange={value => searchHandler(value)}
        />
        <SearchResultsContainer>
          <themesPage>
            <SearchResults>
              <List>
                {searchResults ? (
                  searchResults.length !== 0 ? (
                    searchResults.map((theme, index) => (
                      <ListItemTile
                        key={theme.id}
                        id={theme.id}
                        url={getImages(theme)}
                        topic={theme.name}
                        type="add"
                        handleClick={() => handlePopularClick(theme, index)}
                      />
                    ))
                  ) : (
                    <div>
                      <Suggest>
                        <SuggestHeader>
                          Can't find a partner you are looking for?
                          <Modal
                            onRequestClose={() => setSuggestModal(false)}
                            shouldCloseOnOverlayClick
                            size="medium"
                            isOpen={suggestModal}
                            style={{
                              overlay: {
                                backgroundColor: '#000000d1',
                              },
                            }}
                          >
                            <ModalHeader>
                              Suggest a Partner
                              <ModalCloseButton
                                onClick={() => setSuggestModal(false)}
                              />
                            </ModalHeader>
                            <ModalBody>
                              <Formik
                                validateOnChange={false}
                                initialValues={{ name: '', email: '' }}
                                onSubmit={handleSuggestTopicSubmit}
                              >
                                {({
                                  handleSubmit,
                                  handleChange,
                                  values = {},
                                }) => (
                                  <ComponentSection>
                                    <form name="suggestform">
                                      <CustomInput
                                        name="name"
                                        id="name"
                                        type="text"
                                        placeholder="Email Topic Name"
                                        onChange={handleChange}
                                        value={values.name}
                                      />
                                      <CustomInput
                                        name="email"
                                        id="email"
                                        type="email"
                                        placeholder="Enter Topic Source Link (Optional)"
                                        onChange={handleChange}
                                        value={values.email}
                                      />
                                      <CustomModalButton onClick={handleSubmit}>
                                        SUBMIT
                                      </CustomModalButton>
                                    </form>
                                  </ComponentSection>
                                )}
                              </Formik>
                            </ModalBody>
                          </Modal>
                          <SuggestLink onClick={() => setSuggestModal(true)}>
                            Suggest Topics
                          </SuggestLink>
                        </SuggestHeader>
                      </Suggest>
                    </div>
                  )
                ) : null}
              </List>
            </SearchResults>
          </themesPage>
        </SearchResultsContainer>
        <SearchInspirationDiv>
          <SearchInspiration>
            <SearchInspirationHeader>
              Some search inspiration
            </SearchInspirationHeader>
            <ListWrapper>
              <List>
                {searchInspiration ? (
                  searchInspiration
                    .slice(0, 5)
                    .map((theme, index) => (
                      <ListItemTile
                        key={theme.id}
                        id={theme.id}
                        url={getImages(theme)}
                        topic={theme.name}
                        noCover
                        handleClick={() =>
                          handleSearchInspirationClick(theme, index)
                        }
                      />
                    ))
                ) : (
                  <img src="/assets/images/loaderV.gif" alt="loader" />
                )}
              </List>
            </ListWrapper>
          </SearchInspiration>
          <Popular>
            <PopularHeader>Popular</PopularHeader>

            <List>
              {popular ? (
                popular.map((theme, index) => (
                  <ListItemTile
                    key={theme.id}
                    id={theme.id}
                    url={getImages(theme)}
                    topic={theme.name}
                    type="add"
                    handleClick={() => handlePopularClick(theme, index)}
                  />
                ))
              ) : (
                <img src="/assets/images/loaderV.gif" alt="loader" />
              )}
            </List>
            <Modal
              onRequestClose={() =>
                setMessageModal({
                  isOpen: false,
                })
              }
              shouldCloseOnOverlayClick
              size="medium"
              isOpen={messageModal.isOpen}
              style={{
                overlay: {
                  backgroundColor: '#000000d1',
                },
                fontSize: 12,
              }}
            >
              <ModalHeader>
                <PopularModalContent>
                  {messageModal.message}
                  <ModalCloseButton onClick={closeMessageModal} />
                </PopularModalContent>
              </ModalHeader>
            </Modal>
          </Popular>
        </SearchInspirationDiv>
      </ThemeContainer>
    </ThemeWrapper>
  );
};

const mapStateToProps = (state) => {
  return {
    themes: state.theme.data,
  };
};

const mapDispatchToProps = {
  themesRequest: themeActions.api.theme.request,
};

export default connect(mapStateToProps, mapDispatchToProps)(Theme);
