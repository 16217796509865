import { format, parseISO } from 'date-fns';

export const transformGraphData = (data) => {
  if (data && Object.keys(data) && Object.keys(data)?.length > 0) {
    return Object.keys(data).map((key) => ({
      date: key,
      open_rate: data?.[key]?.open_rate || null,
      click_rate: data?.[key]?.click_rate || null,
    }));
  }
};

export function formatDateString(dateString) {
  const date = parseISO(dateString);
  return format(date, 'dd MMM');
}
