/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import moment from 'moment';
import _, { get, toLower, isEmpty } from 'lodash';
import { connect } from 'react-redux';
import { DateDropdown, TabTypeThree } from '../../components/';
import {
  SelectContainer,
  SelectBox,
  LoaderContainer,
  ShowAllButton,
  ShowAllButtonContainer,
  NoArticlesMsg,
} from '../../components/SortTable/SortTable.styles';
import Slider from 'react-slick';
import {
  ComplianceSliderWrapper,
  AdminSubTab,
  DashboardAdminSubTab,
} from '../DashboardContainer/DashboardContainer.styles';
import {
  TabsThree,
  TabListThree,
  TabPanel,
} from '../../components/Tabs/TabsTypeThree';
import {
  ArticleContainer,
  ArticleContent,
} from '../../components/ArticleBox/ArticleBox';
import ArticleList from '../../components/ArticleList/ArticleList';
import { Image } from '../../components/ArticleList/ArticleList.styles';

import ComplianceSortTable from '../../components/SortTable/ComplianceSortTable';
import { actions as complianceActions } from '../../redux/modules/compliance.module';
import { getComplianceArticles } from '../../services/dashboard';
import { List } from '../ChannelContainer/Channel.styles';
import { getRoleType } from '../../redux/selector';
import {
  ROLE_TYPES,
  getMediaUrl,
  getAuxilaryChannelId,
  formatMomentIsoDate,
} from '../../utils';
import { actions as complianceTrailActions } from '../../redux/modules/compliance-trail.module';

import {
  DownloadPDFContainer,
  ExportPDFButton,
} from '../ComplianceContainer/screenContainer.styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import ExportModal from '../ComplianceContainer/exportModal';

const Articles = {
  REVIEW: 'pending',
  APPROVED: 'approved',
  REJECTED: 'rejected',
};

const styles = {
  tabTypeThree: {
    paddingTop: '5px',
    paddingBottom: '5px',
  },
  adminSubTab: {
    fontSize: '14px',
  },
  tabsThree: {
    margin: '0 0 20px',
  },
};

const ComplianceContainer = ({
  complianceRequest,
  showComplianceActions,
  showAdminActions,
  adminPermission,
  userDetails,
  complianceTrailRequest,
  complianceTrailDetails,
}) => {
  const [articles, setArticles] = useState(null);
  const [exportModal, setExportModal] = useState(false);
  const [themeArticles, setThemeArticles] = useState(null);
  const [publisherArticles, setPublisherArticles] = useState(null);

  const [currTab, setCurrTab] = useState('pending');
  const [customDate, setCustomDate] = useState({ selectedDate: 'Last 7 days' });
  const [themes, setThemes] = useState(null);
  const [publishers, setPublishers] = useState(null);
  const [selectedSubTab, setSelectedSubTab] = useState(0);
  // const [members, setMembers] = useState(null);
  const [pagination, setPagination] = useState(null);
  // const [postsPerPage, setPostsPerPage]=useState(5);
  // const [currentPage,setCurrentPage]=useState(1)

  // const indexOfLastPost =currentPage * postsPerPage;
  // const indexOfFirstPost =indexOfLastPost - postsPerPage;
  const [compliance, setCompliance] = useState(
    get(userDetails, 'compliance', '')
  );

  console.log('Compliance: ', compliance);

  const complianceApiDate = (selectedDate, fromDate, toDate, source) => {
    const currentMomentDate = moment();
    const currentFormattedDate = formatMomentIsoDate(moment());
    let startDate = '';
    let endDate = '';

    if (selectedDate) {
      if (toLower(selectedDate) === 'last 30 days') {
        startDate = formatMomentIsoDate(currentMomentDate.subtract(29, 'd'));
        endDate = currentFormattedDate;
      } else if (toLower(selectedDate) === 'last 7 days') {
        startDate = formatMomentIsoDate(currentMomentDate.subtract(6, 'd'));
        endDate = currentFormattedDate;
      }
    }

    if (source === 'export' && fromDate && toDate) {
      startDate = fromDate;
      endDate = toDate;
    }
    return { startDate: startDate, endDate: endDate };
  };

  const handleDateChange = (val) => {
    console.log(val);
    setCustomDate(val);
    setDetails(currTab, undefined, val);
  };

  const handleSubTabChange = (index) => setSelectedSubTab(index);

  const setDetails = (type, page, customDate) => {
    setArticles(null);
    setThemes(null);
    setPublishers(null);
    // setMembers(null);
    let complianceDate =
      customDate == null
        ? undefined
        : customDate && !customDate.fromDate
        ? complianceApiDate(customDate.selectedDate)
        : { startDate: customDate.fromDate, endDate: customDate.toDate };
    console.log(complianceDate, customDate);
    getComplianceArticles(type, page, complianceDate)
      .then((res) => {
        console.log(res);
        for (let key of Object.entries(res.result.data.details.theme)) {
          _.assign(res.result.data.details.theme[key[0]], { id: key[0] });
        }
        for (let key of Object.entries(res.result.data.details.account)) {
          _.assign(res.result.data.details.account[key[0]], { id: key[0] });
        }
        setThemes(_.values(res.result.data.details.theme));
        let publishers = _.values(res.result.data.details.account);
        setPublishers(publishers.filter((p) => p.type === 'channel'));
        // setMembers([]);
        setPagination(res.result.data.pagination);
        setArticles(res.result.data);
      })
      .catch((err) => console.log(err));
  };

  const handleTabChange = (value) => {
    setThemeArticles(null);
    setPublisherArticles(null);

    let type = '';
    type =
      value === 1
        ? Articles.APPROVED
        : value === 2
        ? Articles.REJECTED
        : Articles.REVIEW;

    setCurrTab(type);
    setDetails(type, undefined, customDate);
  };

  const handlePageChange = (page) => {
    setThemeArticles(null);
    setPublisherArticles(null);

    setDetails(currTab, pagination.page);
  };

  const handleThemeClick = (theme) => {
    let filteredArticles = { ...articles };
    filteredArticles.compliance_content = _.filter(
      [...articles.compliance_content],
      (article) => {
        return (
          article.ids.theme[0].id === theme.id ||
          article.ids.theme[0] === theme.id
        );
      }
    );
    setThemeArticles({ theme, articles: filteredArticles });
  };

  const handlePublisherClick = (publisher) => {
    let filteredArticles = { ...articles };
    filteredArticles.compliance_content = _.filter(
      [...articles.compliance_content],
      (article) => {
        return getAuxilaryChannelId(article) === publisher.id;
      }
    );
    setPublisherArticles({ publisher, articles: filteredArticles });
  };

  const clearThemeFilter = () => {
    setThemeArticles(null);
  };

  const clearPublisherFilter = () => {
    setPublisherArticles(null);
  };
  // const paginate =(pageNumber)=>{
  //  setCurrentPage(pageNumber)
  // }

  useEffect(() => {
    handleTabChange(0);
  }, []);
  var settings = {
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 5,
  };
  // const history = useHistory();
  // const goToProfilePage = (index) => {
  //   window.sessionStorage.removeItem('selectedIndex');
  //   history.push('/admin/configure');
  //   window.sessionStorage.setItem('selectedIndex', index);
  // };
  //  console.log(articles)

  // let currentArticles = {...articles}
  // const currentpageArticles = _.slice(currentArticles.compliance_content,indexOfFirstPost,indexOfLastPost)
  // console.log(currentArticles)

  // let total = _.size(currentArticles)

  const TabContent = (
    hasActions,
    hasAdmin,
    tabType,
    hasAdminPermission,
    openArticle,
    currentTab
  ) => {
    return (
      <>
        <ArticleContainer
          style={{
            background: '#f5f7fb',
            margin: '5px',
          }}
        >
          <TabsThree onSelect={handleSubTabChange} style={styles.tabsThree}>
            <TabListThree
              style={{
                background: '#f5f7fb',
              }}
            >
              <DownloadPDFContainer>
                {currTab === 'pending' && (
                  <ExportPDFButton
                    onClick={() => {
                      console.log('articles', articles);
                      if (articles?.dataCount && articles?.dataCount > 0)
                        setExportModal(true);
                    }}
                    disabled={articles?.dataCount && articles?.dataCount > 0}
                  >
                    <FontAwesomeIcon
                      icon={faDownload}
                      color="#6351ed"
                      size="1x"
                    />
                    <div style={{ marginLeft: '5px' }}>Export To PDF</div>
                  </ExportPDFButton>
                )}
              </DownloadPDFContainer>
              {/* <AdminSubTab
                style={styles.adminSubTab}
                color="#49484a"
                padding="5px 23px 3px"
                type="compliance-tab"
              >
                <span
                  className={`${selectedSubTab === 0 ? 'active' : 'inactive'}`}
                >
                  By Topic
                </span>
              </AdminSubTab>
              <AdminSubTab
                style={styles.adminSubTab}
                color="#49484a"
                padding="5px 23px 3px"
                type="compliance-tab"
                twoTabs
              >
                <span
                  className={`${selectedSubTab === 1 ? 'active' : 'inactive'}`}
                >
                  By Publisher
                </span>
              </AdminSubTab> */}
              <SelectContainer>
                <SelectBox>
                  <DateDropdown
                    value={
                      !isEmpty(customDate) && customDate.selectedDate
                        ? customDate.selectedDate
                        : ''
                    }
                    position="static"
                    type="complaince"
                    selectedDate={customDate}
                    onDateChange={handleDateChange}
                  />
                </SelectBox>
              </SelectContainer>
            </TabListThree>

            <TabPanel>
              {/* <ComplianceSliderWrapper>
                <ArticleContent>
                  <List>
                    {!!themes ? (
                      themes.length !== 0 ? (
                        !!themeArticles ? (
                          <ArticleList
                            imgsrc={`${getMediaUrl(
                              _.get(themeArticles, 'theme.media_urls')
                            )}`}
                            title={themeArticles.theme.name}
                            showCursor={true}
                          />
                        ) : (
                          <div
                            style={{
                              width: '100%',
                            }}
                          >
                            <Slider {...settings}>
                              {themes.map((theme, index) => (
                                <ArticleList
                                  imgsrc={`${getMediaUrl(
                                    _.get(theme, 'media_urls')
                                  )}`}
                                  title={theme.name}
                                  key={index}
                                  showCursor={true}
                                  handleClick={() => handleThemeClick(theme)}
                                />
                              ))}
                            </Slider>
                          </div>
                        )
                      ) : (
                        <NoArticlesMsg>No topics found</NoArticlesMsg>
                      )
                    ) : (
                      <LoaderContainer>
                        <Image src="/assets/images/loaderV.gif" alt="loader" />
                      </LoaderContainer>
                    )}
                  </List>

                  {!!themeArticles && (
                    <ShowAllButtonContainer>
                      <ShowAllButton onClick={clearThemeFilter}>
                        All
                      </ShowAllButton>
                    </ShowAllButtonContainer>
                  )}
                </ArticleContent>
              </ComplianceSliderWrapper> */}
              <ComplianceSortTable
                tabType={tabType}
                data={!!themeArticles ? themeArticles.articles : articles}
                // pageArticles={currentpageArticles}
                handlePageChange={handlePageChange}
                hasActions={hasActions}
                handleActions={handleTabChange}
                hasAdmin={hasAdmin}
                hasAdminPermission={hasAdminPermission}
                showComplianceActions={showComplianceActions}
                openArticle={openArticle}
                currentTab={currentTab}
                date={customDate}
                setDetails={setDetails}
                complianceTrailRequest={complianceTrailRequest}
                complianceTrailDetails={complianceTrailDetails}
              />

              {/* <TablePagination postsPerPage={postsPerPage} totalPosts={total} paginate={paginate}/> */}
            </TabPanel>
            <TabPanel>
              {/* <ComplianceSliderWrapper>
                <ArticleContent>
                  <List>
                    {!!publishers ? (
                      publishers.length !== 0 ? (
                        !!publisherArticles ? (
                          <ArticleList
                            imgsrc={`${getMediaUrl(
                              _.get(publisherArticles, 'publisher.media_urls')
                            )}`}
                            title={publisherArticles.publisher.title}
                            showCursor={true}
                          />
                        ) : (
                          <div
                            style={{
                              width: '100%',
                            }}
                          >
                            <Slider {...settings}>
                              {publishers.map((publisher, index) => (
                                <ArticleList
                                  imgsrc={`${getMediaUrl(
                                    _.get(publisher, 'media_urls')
                                  )}`}
                                  title={publisher.title}
                                  key={index}
                                  handleClick={() =>
                                    handlePublisherClick(publisher)
                                  }
                                  showCursor={true}
                                />
                              ))}
                            </Slider>
                          </div>
                        )
                      ) : (
                        <NoArticlesMsg>No publishers Found</NoArticlesMsg>
                      )
                    ) : (
                      <LoaderContainer>
                        <Image src="/assets/images/loaderV.gif" alt="loader" />
                      </LoaderContainer>
                    )}
                  </List>
                  {!!publisherArticles && (
                    <ShowAllButtonContainer>
                      <ShowAllButton onClick={clearPublisherFilter}>
                        All
                      </ShowAllButton>
                    </ShowAllButtonContainer>
                  )}
                </ArticleContent>
              </ComplianceSliderWrapper> */}
              <ComplianceSortTable
                data={
                  !!publisherArticles ? publisherArticles.articles : articles
                }
                // pageArticles={currentpageArticles}
                tabType={tabType}
                handlePageChange={handlePageChange}
                hasActions={hasActions}
                hasAdmin={hasAdmin}
                hasAdminPermission={hasAdminPermission}
                openArticle={openArticle}
                complianceTrailRequest={complianceTrailRequest}
                complianceTrailDetails={complianceTrailDetails}
              />
              {/* <TablePagination postsPerPage={postsPerPage} totalPosts={total} paginate={paginate} /> */}
            </TabPanel>
            {/* <TabPanel>
            <ArticleContainer>
              <ArticleHeader>Members</ArticleHeader>
              <ArticleContent>
                {!!themes ? (
                  themes.length !== 0 ? (
                    themes.map((theme, index) => (
                      <ArticleList
                        imgsrc={`${theme.media_urls[0].path}/${theme.media_urls[0].name.resized}.${theme.media_urls[0].ext}`}
                        title={theme.name}
                        key={theme.media_urls[0].id}
                      />
                    ))
                  ) : (
                    'No members found'
                  )
                ) : (
                  <LoaderContainer>
                    <Image src="/assets/images/loaderV.gif" alt="loader" />
                  </LoaderContainer>
                )}
              </ArticleContent>
              <ComplianceSortTable
                data={articles}
                handlePageChange={handlePageChange}
                hasActions={hasActions}
              />
            </ArticleContainer>
          </TabPanel> */}
          </TabsThree>
        </ArticleContainer>
      </>
    );
  };
  return (
    <>
      <TabsThree
        onSelect={handleTabChange}
        style={{
          background: '#f5f7fb',
          border: '2px solid #e4e4e4',
          borderRadius: '6px',
        }}
      >
        <TabListThree
          style={{
            background: '#f5f7fb',
          }}
        >
          <DashboardAdminSubTab
            color="#49484a"
            padding="10px 0px"
            margin="0px 14px"
            className={`${currTab === 'pending' ? 'active' : ''}`}
            style={{
              fontWeight: '600',
              fontSize: '13px',
              top: '1px',
              position: 'relative',
            }}
            data-testid="pending-review"
          >
            PENDING REVIEW
          </DashboardAdminSubTab>
          <DashboardAdminSubTab
            color="#49484a"
            padding="10px 0px"
            margin="0px 14px"
            className={`${currTab === 'approved' ? 'active' : ''}`}
            style={{
              fontWeight: '600',
              fontSize: '13px',
              top: '1px',
              position: 'relative',
            }}
            data-testid="approved"
          >
            APPROVED
          </DashboardAdminSubTab>
          <DashboardAdminSubTab
            color="#49484a"
            padding="10px 0px"
            margin="0px 14px"
            className={`${currTab === 'rejected' ? 'active' : ''}`}
            style={{
              fontWeight: '600',
              fontSize: '13px',
              top: '1px',
              position: 'relative',
            }}
            data-testid="rejected"
          >
            REJECTED
          </DashboardAdminSubTab>
        </TabListThree>
        <div
          style={{
            background: '#f5f7fb',
          }}
        >
          <hr
            style={{
              margin: '0px auto',
              width: '90%',
              borderTop: '1px solid#d1dae5',
            }}
          />
        </div>

        <TabPanel
          style={{
            background: '#f5f7fb',
          }}
        >
          {TabContent(showComplianceActions, null, null, false, true, currTab)}
        </TabPanel>
        <TabPanel
          style={{
            background: '#f5f7fb',
          }}
        >
          {TabContent(null, null, null, false, true, currTab)}
        </TabPanel>
        <TabPanel
          style={{
            background: '#f5f7fb',
          }}
        >
          {TabContent(null, showAdminActions, 'rejected', true, false, currTab)}
        </TabPanel>
      </TabsThree>
      {exportModal ? (
        <ExportModal
          handleModalClose={() => {
            setExportModal(false);
          }}
          dates={complianceApiDate(
            customDate.selectedDate,
            customDate.fromDate,
            customDate.toDate,
            'export'
          )}
          status={currTab}
        />
      ) : null}
    </>
  );
};

const mapStateToProps = (state) => {
  const roleType = getRoleType(state);
  console.log(roleType);
  const showComplianceActions = ROLE_TYPES.ADMIN_COMPLIANCE === roleType;
  const showAdminActions =
    ROLE_TYPES.ADMIN_ENTERPRISE === roleType ||
    ROLE_TYPES.ADMIN_COMPLIANCE === roleType;

  console.log('showAdminActions: ', showAdminActions);
  console.log('showComplianceActions: ', showComplianceActions);
  return {
    complianceData: state.compliance.data,
    showComplianceActions,
    showAdminActions,
    adminPermission: get(state, 'user.data.permissions.name', '') === 'Admin',
    userDetails: state.user.data,
    complianceTrailDetails: state.complianceTrail,
  };
};

const mapDispatchToProps = {
  complianceRequest: complianceActions.api.compliance.request,
  complianceTrailRequest: complianceTrailActions.api.complianceTrail.request,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ComplianceContainer);
