import React, { useState } from 'react';
import {
  Button,
  ModalBody,
  ModalCloseButton,
  ModalHeader,
  ModalV2,
} from '../../../components';
import { Box, Flex } from '@rebass/grid';
import { convertDateToRequiredFormat, timeZoneAbbreviated } from '../../../utils';
import { get, isEmpty } from 'lodash';
import DatePickerV2 from '../../../components/DatePickerV2/DatePickerV2';
import styled from 'styled-components';
import { ScrollWrapper } from '../DripCampaignContainerV2.styles';
import { LineBreaker } from '../../AutomatedCampaigns/styles';

const DateTimeWrapper = styled(Flex)`
  background-color: hsl(0, 0%, 100%);
  border-color: hsl(0, 0%, 80%);
  border-radius: 4px;
  margin: 0 auto;
  padding: 2px 8px;
`;

const DateTimeText = styled.div`
  width: 85%;
  font-size: 0.9rem;
  display: flex;
  align-items: center;
  justify-content: center;
  &:empty:before {
    content: attr(data-placeholder);
    color: gray;
  }
`;

const CustomDateTimeModal = ({
  isOpen,
  handleClose,
  setIsDatepickerOpen,
  isDatepickerOpen,
  userData,
  setObjectivesTabData,
  handleDifferentTimes,
  timeBlock,
  handleNext
}) => {

  console.log('timeBlock', timeBlock);
  const [selectedId, setSelectedId] = useState();
  let list = !isEmpty(setObjectivesTabData?.emList) ? setObjectivesTabData?.emList : setObjectivesTabData?.segmentList;
  return (
    <ModalV2
      isOpen={isOpen}
      onRequestClose={handleClose}
      shouldCloseOnOverlayClick={false}
      bgColor="white"
      width="40%"
    >
      <ModalHeader
        style={{
          display: 'flex',
          justifyContent: 'flex-start',
          color: '#101010',
          fontSize: '18px',
          padding: '10px 10px',
        }}
      >
        Set Date & Time for Email Lists
      </ModalHeader>
      <ModalBody>
        <ScrollWrapper height="400px" maxHeight='400px'>
        <Flex
          display="flex"
          width="100%"
          flexDirection="column"
          justifyContent="center"
        >
          {!isEmpty(list) && list.map((item, index) => (
            <>
              <Flex
                display="flex"
                justifyContent="flex-start"
                alignItems="center"
                color="#000000"
                fontSize="16px"
                paddingBottom="5px"
                style={{ fontWeight: '600' }}
              >
                {item?.name}
              </Flex>
              <Flex
                display="flex"
                justifyContent="flex-start"
                alignItems="center"
                fontSize="13px"
                color="#101828"
              >
                Set date & time
              </Flex>
              <Box
                width="56%"
                mt="8px"
                style={{
                  border: '1px solid #E4E7EC',
                  borderRadius: '4px',
                  height: '35px',
                  boxShadow: '1px 2px 2px 0px rgba(16, 24, 40, 0.12) inset',
                }}
              >
                <DateTimeWrapper
                  style={{
                    padding: '2px 5px',
                    height: '32px',
                  }}
                >
                  <DateTimeText
                    style={{
                      width: '86%',
                      display: 'flex',
                      justifyContent: 'flex-start',
                      paddingLeft: '8px',
                    }}
                    contenteditable="true"
                    data-placeholder="MM/DD/YYYY, 00:00"
                  >
                    {timeBlock[0] && timeBlock[0].schedule_time && timeBlock[0]?.schedule_time.filter(t => t?.destination_id === item.id)[0]?.time && convertDateToRequiredFormat({
                      date: timeBlock[0] && timeBlock[0].schedule_time && timeBlock[0]?.schedule_time.filter(t => t?.destination_id === item.id)[0]?.time,
                      format: 'MM/DD/YYYY, h:mm a',
                    }) + ' (' + timeZoneAbbreviated(get(
                      userData,
                      'details.user.timezone',
                      ''
                    )) + ')'}
                  </DateTimeText>
                  <img
                    alt="date"
                    src="/assets/images/Campaign/date&time-picker-icon.svg"
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      setSelectedId(item.id);
                      setIsDatepickerOpen(true);
                    }}
                  />
                  {isDatepickerOpen && item.id === selectedId && (
                    <DatePickerV2
                      isOpen={isDatepickerOpen}
                      customInput={<div />}
                      minDate={new Date()}
                      onDateClose={() => {
                        setIsDatepickerOpen(false);
                        setSelectedId();
                      }}
                      onSubmit={(date) => {
                        handleDifferentTimes(date, item);
                        setIsDatepickerOpen(false);
                        setSelectedId();
                      }}
                      timeZone={get(userData, 'details.user.timezone', '')}
                      hourAndMins
                      scheduleValue={timeBlock[0] && timeBlock[0].schedule_time && timeBlock[0]?.schedule_time.filter(t => t?.destination_id === item.id)[0]?.time}
                    />
                  )}
                </DateTimeWrapper>
              </Box>
              {list.length > 1 && (
                <LineBreaker />
              )}
            </>
          ))}
        </Flex>
        </ScrollWrapper>
        <hr style={{ width: '100%', border: '1px solid #E4E7EC', marginTop: '25px' }}></hr>
        <Flex
          alignItems={'center'}
          justifyContent="space-between"
          display="flex"
          width="100%"
        >
          <Button
            borderRadius="4px"
            onClick={handleClose}
            disabled={false}
            width="50%"
            padding="5px 10px"
            margin="20px 10px 0px 0px"
            height="40px"
            bgColor="#fff"
            color="#101828"
            borderColor="#e4e7ec"
          >
            Cancel
          </Button>
          <Button
            borderRadius="4px"
            onClick={handleNext}
            disabled={list?.length !== timeBlock[0].schedule_time?.length}
            width="50%"
            padding="5px 10px"
            margin="20px 0px 0px 0px"
            height="40px"
            bgColor="#816EF8"
            color="#fff"
          >
            Save
          </Button>
        </Flex>
      </ModalBody>
    </ModalV2>
  );
};

export default CustomDateTimeModal;
