import React from 'react';
import Topics from './Topics';
import TopRecommended from './TopRecommended';
import { Wrapper } from './styles';

function RecommendedContent({
  recommendationsData,
  topics,
  recommendationsLoader,
  topicsLoader,
  recommendationsFetching,
  setSelectedArticle
}) {
  return (
    <Wrapper>
      <TopRecommended
        recommendationsData={recommendationsData}
        recommendationsLoader={recommendationsLoader}
        recommendationsFetching={recommendationsFetching}
        setSelectedArticle={setSelectedArticle}
      />
      <Topics topics={topics ?? []} topicsLoader={topicsLoader} />
    </Wrapper>
  );
}

export default RecommendedContent;
