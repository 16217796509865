import React from 'react';
import SwitchRow from '../../componentsV2/SwitchRow';

const AdvisorSettings = ({ settingsArray, changeHandler }) => {
    settingsArray.forEach(element => {
        delete element['Re-sync'];
        delete element['Skip duplicates'];
    });
    return (
        <div>
            {settingsArray.map((setting, index) => {
                return(
                    <SwitchRow 
                        cardData={{title: setting.label, switchActive: setting.Enabled, switchAction: changeHandler}}
                        index={index}
                        mapped={true}
                    />
                )
            })}
        </div>
    );
}

export default AdvisorSettings;