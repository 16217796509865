import { get, isEmpty } from 'lodash';
import { takeLatest, all, call, put, delay, select } from 'redux-saga/effects';
import { actions as exploreActions } from '../modules/explore-tab.module';
import { moreActions as exploreMoreActions } from '../modules/explore-tab.module';
import { moreActions as newsletterMoreActions } from '../modules/newsletter.module';
import {
  getExploreArticles,
  getSearchInsights,
  getTrendingArticles,
  deleteArticle,
  getScheduledArticles,
  getMyMioArticles,
  getRecentSharedArticles,
  deleteScheduledArticles,
  getInvetmentsArticles,
  getApproveToShareArticles,
  getThirdPartyContent,
  getMyClientsArticles,
  getBookmarkedArticles,
  postArticlesToNewsletterBucket,
  postAddToBookmarks,
  deleteFromBookmarks,
  getRecommendedArticles,
  getAdminRecommendedArticles,
  getCRMArticles
} from '../../services/dashboard';

import { deleteBucketArticle } from '../../services/newsletter';

function* exploreArticlesRequest({ payload }) {
  // console.log('EXPLORE SAGA PAYLOAD - -- - -', payload);
  try {
    const { source, id, date, selectedTicker, mode, token, holdingsType, thirdPartyThemeId, contactName, isAdmin, themesList, ...rest } = get(payload, 'params', {});
    // console.log(payload);
    if (['trending', 'investments'].includes(source) && !id) {
      // console.log('inside if [ID] - ', id);
      return;
    }
    
    const serviceMapping = {
      explore: getExploreArticles,
      search: getSearchInsights,
      trending: getTrendingArticles,
      scheduled: getScheduledArticles,
      mymio: getMyMioArticles,
      recent: getRecentSharedArticles,
      investments: getInvetmentsArticles,
      approveToShare: getApproveToShareArticles,
      mioEditorResult: getThirdPartyContent,
      myClients: getMyClientsArticles,
      crmClients: getCRMArticles,
      bookmarks: getBookmarkedArticles,
      recommended: getRecommendedArticles 
    };
    const serviceName = serviceMapping[source];
    let response, data;
    // console.log('image',typeof(window.localStorage.getItem('ImageUploadFirst')))
    if(source === 'mioEditorResult') {
      // window.localStorage.setItem('ImageUploadFirst', false)
      response = yield call(serviceName, id, date, selectedTicker, mode, token, thirdPartyThemeId, rest);
      data = get(response, 'result.data', {});  
    } 
    else if(holdingsType){
      // console.log('[INSIDE HOLDINGS TYPE] - ');
      response = yield call(serviceName, id, holdingsType, date, selectedTicker, mode, token, rest);
      data = get(response, 'result.data', {});
      // console.log('[RESPONSE DATA] - ', data);
    } else if(source === 'search' && !id) {

    }
    else if (source === 'myClients') {
      response = yield call(serviceName, id, contactName);
      data = get(response, 'result.data', {});
    } 
    else if (source === 'crmClients') {
      response = yield call(serviceName, themesList);
      data = get(response, response.result.data.data ? 'result.data.data' : 'result.data', {});
    }
    else if (source === 'approveToShare' && date === 'My Added Content') {
      // console.log('EXPLORE TAB SAGA - SOURCE ats');
      data = {};
    } 
    else if(source === 'bookmarks') {
      response = yield call(serviceName);
      data = get(response, 'result.data', {});
    }
    else {
      response = yield call(serviceName, id, date, selectedTicker, mode, token, rest);
      data = get(response, 'result.data', {});
    }
    if(window.localStorage.getItem('ImageUploadFirst') === 'true') {
      window.localStorage.setItem('ImageUploadFirst', false)
    }

    let flag = false;
    // if(source === 'mioEditorResult') {
    //   response = yield call(serviceName, id, date, selectedTicker, mode, token, thirdPartyThemeId, rest);
    //   // while (flag === false) {
    //   //    window.localStorage.setItem('ImageUploadFirst', true)
    //   //    data = get(response, 'result.data', {});
    //   //    if(!isEmpty(data.feed) && data.feed.length >= 4) {
    //   //      flag = true;
    //   //      window.localStorage.setItem('ImageUpload', false)
    //   //    }
    //   // }
    // }
    if(source === 'search' && data && data.feed) {
      data.feed.forEach(feedData => {
        // if(feedData.auxiliary.original_content && 
        //   feedData.auxiliary.original_content.ids && 
        //   feedData.auxiliary.original_content.ids.account
        // ){
        //   feedData.filterTag = {
        //     topic: data.details.theme[feedData.auxiliary.original_content.ids.].name,
        //     partner: data.details.account[feedData.auxiliary.original_content.ids.account[0].id],
        //   }
        // }else 
        if(feedData.ids && feedData.ids.account && feedData.ids.theme && feedData.ids.theme.length > 0){
          feedData.filterTag = {
            topic: data.details.theme[feedData.ids.theme[0]],
            partner: data.details.account[feedData.ids.account],
          }
        }
      });
    }
    // console.log('DATA - ', data);
    yield put(exploreActions.api.explore.response({ data, source, selectedTicker, ...(holdingsType && {holdingsType}),thirdPartyThemeId, contactName, ...rest }));
  } catch (error) {
    yield put(exploreActions.api.explore.error(error));
  }
}

function* deleteArticeRequest({ payload }) {
  try {
    const { deleteId, source } = get(payload, 'params', {});
    const apiCall =
      source === 'scheduled' ? deleteScheduledArticles : deleteArticle;
    const response = yield call(apiCall, deleteId);
    const status = get(response, 'result.success');
    if (status === true || response === '') {
      yield put(exploreActions.api.explore.request(payload));
    }
  } catch (error) {
    yield put(exploreActions.api.explore.error(error));
  }
}

function* addToNewsletterBucketRequest({ payload }) {
  try {
    const {articleId, themeId} = get(payload, 'params', {});
    // console.log('explore-tab-saga............ARTICLE ID - ', articleId, ' THEME ID - ', themeId);
    const apiCall = postArticlesToNewsletterBucket;
    const response = yield call(apiCall, {articleId, themeId});
    if(response && response.result && response.result.success) {
      yield put(exploreActions.api.explore.request(payload));
      yield put(newsletterMoreActions.custom.newsletter.bucketCountRequest());
    }
  } catch (error) {
    yield put(exploreActions.api.explore.error(error));
  }
}

function* removeFromNewsletterRequest({ payload }) {
  try {
    const { articleId } = get(payload, 'params', {});
    const apiCall = deleteBucketArticle;
    const response = yield call(apiCall, { id: articleId });
    if(response === '') {
      yield put(exploreActions.api.explore.request(payload));
      yield put(newsletterMoreActions.custom.newsletter.bucketCountRequest());
    }
  } catch (error) {
    yield put(exploreActions.api.explore.error(error));
  }
}

function* addToBookmarksRequest({ payload }) {
  try {
    const {articleId, themeId} = get(payload, 'params', {});
    const apiCall = postAddToBookmarks;
    const response = yield call(apiCall, articleId, themeId);
    if(response && response.result && response.result.success) {
      yield put(exploreActions.api.explore.request(payload));
    }
  } catch (error) {
    yield put(exploreActions.api.explore.error(error));
  }
}

function* removeFromBookmarksRequest({ payload }) {
  // console.log('REMOVE BM SAGA - - -- ', payload);
  try {
    const { articleId } = get(payload, 'params', {});
    const apiCall = deleteFromBookmarks;
    const response = yield call(apiCall, articleId);
    if(response === '') {
      yield put(exploreActions.api.explore.request(payload));
    }
  } catch (error) {
    yield put(exploreActions.api.explore.error(error));
  }
}

function* exploreArticelsSaga() {
  yield all([
    takeLatest(exploreActions.api.explore.request, exploreArticlesRequest),
    takeLatest(exploreActions.api.explore.delete, deleteArticeRequest),
    takeLatest(exploreMoreActions.custom.explore.addToNewsletter, addToNewsletterBucketRequest),
    takeLatest(exploreMoreActions.custom.explore.removeFromNewsletter, removeFromNewsletterRequest),
    takeLatest(exploreMoreActions.custom.explore.addToBookmarks, addToBookmarksRequest),
    takeLatest(exploreMoreActions.custom.explore.removeFromBookmarks, removeFromBookmarksRequest),
  ]);
}

export default exploreArticelsSaga;
