import styled from 'styled-components';

export const ContentContainer = styled.div`
  display: flex;
  height: 80px;
  justify-content: space-evenly;
`;

export const ContentBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #eeeeee;
  padding: 0px 20px;
`;

export const BoxTitle = styled.div`
  background-color: white;
`;
