import React, { useRef, useEffect } from 'react';
import { get, isEmpty } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import {
  Table,
  TableTitle,
  Tr,
  Th,
  Td,
  TableHeading,
  ButtonNoBorderBackground,
} from '../../../components';
import { MemberTableBody } from './MembersTab.styles';

const RequestedMembers = ({ data, noDataInfo, onActionClick, label }) => {
  const tablBodyRef = useRef(null);
  useEffect(() => {
    if (tablBodyRef.current) {
      tablBodyRef.current.scrollTop = 0;
    }
  }, [data]);
  return (
    <Table>
      <TableTitle>{label}</TableTitle>
      <TableHeading>
        <Tr alternate={false}>
          <Th width='30%'>Name</Th>
          <Th width='50%'>Email</Th>
          <Th width='20%'>Action</Th>
        </Tr>
      </TableHeading>
      <MemberTableBody ref={tablBodyRef}>
        {isEmpty(data) && (
          <Tr>
            <Td>{noDataInfo}</Td>
          </Tr>
        )}
        {data.map((memeber) => {
          return (
            <Tr>
              <Td width='30%'>{get(memeber, 'name')}</Td>
              <Td width='50%'>{get(memeber, 'email')}</Td>
              <Td width='20%'>
                <ButtonNoBorderBackground
                  onClick={() => {
                    onActionClick(memeber.id, 'approved');
                  }}
                >
                  <FontAwesomeIcon icon={faCheck} />
                </ButtonNoBorderBackground>
                &nbsp;&nbsp;
                <ButtonNoBorderBackground
                  onClick={() => {
                    onActionClick(memeber.id, 'rejected');
                  }}
                >
                  <FontAwesomeIcon icon={faTimes} />
                </ButtonNoBorderBackground>
              </Td>
            </Tr>
          );
        })}
      </MemberTableBody>
    </Table>
  );
};

export default RequestedMembers;
