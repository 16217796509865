import { Flex } from '@rebass/grid';
import React from 'react';
import { TopShareWrapper, TopShareName, TopPublisherName, SponsoredWrapper, OverviewBadge, OverviewShareTitle, ScoreWrapper } from './EngagementTab.styles';
import { isEmpty, get } from 'lodash';
import { getMonthDate, onImageError } from '../../../utils';
import { DisplayScore } from '../../../components';

const TopShareArticle = ({
  engagement_score,
  suggested_investments,
  title,
  topic,
  publisher,
  date,
  media_urls,
  id,
  handleRowClick
}) => {
  let trimmedPublisherName = ((publisher && publisher.name) || '').length > 15 ? publisher && `${publisher.name.substring(0, 15)}...` : publisher && publisher.name;
  let trimmedTitle =
  (title || '').length > 80
    ? `${title.substring(0, 80)}...`
    : title;
  
  const getEngagementMediaImage = (media) => {
    const mediaUrl = Array.isArray(media) ? get(media, '[0]', {}) : media;
    if (!isEmpty(mediaUrl)) {
      return `${mediaUrl.path}${get(mediaUrl, 'name.resized')}.${mediaUrl.ext
        }`;
    } else {
      return '';
    }
  }

  const extractedDate = getMonthDate(date);
  const formatted_date = `${extractedDate[0]} ${extractedDate[1]}`;  
  
  return (
    <TopShareWrapper style={{cursor: 'pointer'}} onClick={() => handleRowClick(id, {
      engagement_score,
      suggested_investments,
      title,
      topic,
      publisher,
      date,
      media_urls,
      id,
    }, 'insights')}>
      <Flex style={{height: '99px', width: '100%'}}>
        <img alt='share' width='213px' src={getEngagementMediaImage(media_urls)} onError={onImageError} style={{borderTopLeftRadius: '8px', borderTopRightRadius: '8px', objectFit: 'cover'}}/>
      </Flex>
      <Flex flexDirection='column' padding='8px 8px 10px 8px'>
        <Flex width='100%' justifyContent='space-between' padding='0px 6px'> 
          <Flex flexDirection='column' justifyContent='flex-start'>
            <TopShareName>{topic && topic.name}</TopShareName>
            <TopPublisherName title={((publisher && publisher.name) || '').length > 20 ? publisher && publisher.name : ''}>{trimmedPublisherName}{date ? `, ${formatted_date}` : ''}</TopPublisherName>
          </Flex>
          <Flex>
            <DisplayScore score={engagement_score} />
          </Flex>
        </Flex>
        <OverviewShareTitle title={title}>
          {trimmedTitle}
        </OverviewShareTitle>
        {!isEmpty(suggested_investments) ? (
          suggested_investments.map((item, index) => (
            <SponsoredWrapper mt='10px'>
              <Flex width='100%' justifyContent='flex-start' padding='2px 0px' style={{height: '13px'}}>
                <img src={item.logo_path}  style={{objectFit:'contain', objectPosition:'left'}} alt='Company' />
              </Flex>
              <Flex width='100%' fontSize='10px' padding='2px 0px' style={{ textAlign: 'left', color: '#49484a', fontWeight: '500', whiteSpace: 'nowrap', height: '15px'}}>
                <span style={{marginTop: '-10px'}}>
                  <div className='tooltip-div tooltip-disclosure' style={{paddingTop: 'unset'}}>
                      <p style={{cursor: item.name.length > 32 ? 'pointer': 'default'}}>{item.name.length > 32 ? item.name.substring(0, 32) + '...' : item.name}</p>
                        {item.name.length > 32 && 
                      <span className='tooltip-text tooltiptext-disclosure' style={{left: '185px', top: '-26px', whiteSpace: 'normal', width: 'initial', backgroundColor: '#404040', color: '#ffffff'}}>{item.name}</span>}
                    </div>
                </span>
              </Flex>
              <Flex justifyContent="space-between" style={{paddingTop: '2px'}}>
                <Flex>
                  <span style={{ color: '#49484a', fontWeight: 'bold', fontSize: '10px' }}>{item.ticker}</span>
                  &nbsp;
                  {item.matched_topics && item.matched_topics.length > 0 && item.matched_topics.slice(0, 1).map((item, index) => (
                    <span key={'top_share_article_' + index} style={{ color: '#49484a', fontSize: '8px', fontWeight: 500, border: '1px solid #49484a', borderRadius: '2px', marginRight: '4px', height: '11px', display: 'flex', alignItems: 'center', padding: '0px 2px', marginTop: '2px' }}>
                   
                   <div className='tooltip-div tooltip-disclosure' style={{paddingTop: 'unset'}}>
                      <p style={{cursor: item.length > 10 ? 'pointer': 'default'}}>{item.length > 10 ? item.substring(0, 10) + '...' : item}</p>
                        {item.length > 10 && 
                      <span className='tooltip-text tooltiptext-disclosure' style={{left: '58px', top: '2px', whiteSpace: 'nowrap', width: 'initial', backgroundColor: '#404040', color: '#ffffff'}}>{item}</span>}
                    </div>
                    </span>
                  ))}
                </Flex>
                {item.is_sponsored && (
                  <OverviewBadge><span>Sponsored</span></OverviewBadge>
                )}
              </Flex>
            </SponsoredWrapper>
          ))
        ) : null}
      </Flex>
    </TopShareWrapper>
  )
}

export default TopShareArticle;
