import React from 'react';
import { Link } from 'react-router-dom';
import { ErrorContainer } from '../../';

const ModalMessages = ({
    shareError,
    shareModalMsgs,
    isAdmin,
    userData,
    isChecked,
    selectedComplianceCheck,
    isEnterpriseAdvisor,
    padding
}) => {

    // TO DISPLAY ERROR MESSAGE IF SOCIAL PLATFORM IS NOT CONNECTED
    const getSocialMediaErrorMessage = (platform, isAdmin) => {
        const toUrl = isAdmin ? '/admin/configure' : '/configure';
        return (
        <>
            <span key={[platform]}>
            Please <Link to={toUrl}>Connect</Link> your {platform} account to
            continue posting.
            </span>
            <br />
        </>
        );
    };

    const MessagesBody = (
        <>
            {shareError &&
                shareError.length > 0 &&
                shareError.map(
                  (error) =>
                  !['SocialShareException', 'SocialTokenException'].includes(
                    error.type
                  ) && (
                    <ErrorContainer padding={padding} align="center">
                      Some error occured. Please try again later
                    </ErrorContainer>
                  )
              )}
              {shareError &&
                shareError.length > 0 &&
                shareError.map(
                  (err) =>
                    err.type === 'SocialTokenException' && (
                      <ErrorContainer padding={padding} align="center">
                        {getSocialMediaErrorMessage(err.name, isAdmin)}
                      </ErrorContainer>
                    )
                )}
              {/* {shareError &&
                shareError.length > 0 &&
                shareError.map(
                  (err) =>
                    err.type === 'SocialShareException' && (
                      <ErrorContainer padding={padding} align="center">
                        This post has already been shared on ({err.name})
                      </ErrorContainer>
                    )
                )} */}
              {shareModalMsgs && shareModalMsgs.length > 0 && (
                shareModalMsgs.map((msg) => (
                  <ErrorContainer padding={padding} align="center" key={msg}>
                    {msg}
                  </ErrorContainer>
                ))
              )}
        </>
    );
    return MessagesBody;
}

export default ModalMessages;