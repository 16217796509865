import React from 'react';
import { parseISO, isValid, format } from 'date-fns';

import { useParams, useLocation } from 'react-router-dom';

import useRESTQuery from 'src/hooks/useRESTQuery';
import Skeleton from './Skeleton';

import {
  CampaignLogo,
  HeaderSubtitle,
  HeaderTitle,
  Platforms,
  SubCampaignDateCreated,
  SubCampaignDetailsWrapper,
  SubCampaignItem,
  SubCampaignListContainer,
  SubCampaignName,
  SubCampaignHeader,
} from './styles';

import { onImageError } from 'src/utils';
import { renderPlatforms } from '../utils';
import { transformData } from './utils';

function SubCampaignList({ selectedSubCampaign = '', setSelectedSubCampaign }) {
  const { id } = useParams();
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const type = queryParams.get('type');

  const { isLoading, data } = useRESTQuery({
    key: 'campaign-blocks',
    endpoint: `/analytics/detailed/campaign/${id}/blocks?campaign_type=${type}`,
    dependencies: [id, type],
    options: {
      select: (res) => {
        if (
          res?.result &&
          Array.isArray(res.result) &&
          res?.result?.length > 0
        ) {
          return res?.result?.map((item) => transformData(item ?? {}));
        }
        return [];
      },
    },
  });

  if (isLoading) return <Skeleton />;

  const onClickHandler = (campaign) => {
    if (campaign?.id === selectedSubCampaign?.id) {
      setSelectedSubCampaign({});
      return;
    }
    setSelectedSubCampaign(campaign);
  };

  return (
    <div>
      <SubCampaignHeader>
        <div>
          <HeaderTitle>Campaign List</HeaderTitle>
          <HeaderSubtitle>
            Select a campaign to view more details
          </HeaderSubtitle>
        </div>
        <p onClick={() => setSelectedSubCampaign({})}>Reset</p>
      </SubCampaignHeader>
      <SubCampaignListContainer>
        {Array.isArray(data) &&
          data.length > 0 &&
          data.map((item, index) => {
            const createdDate = item?.createdDate
              ? parseISO(item.createdDate)
              : '';
            return (
              <SubCampaignItem
                key={index}
                onClick={() => onClickHandler(item)}
                isSelected={item?.id === selectedSubCampaign?.id}
              >
                <CampaignLogo
                  src={item?.mediaURL ?? ''}
                  alt="Campaign Logo"
                  onError={onImageError}
                />
                <SubCampaignDetailsWrapper>
                  <div>
                    <SubCampaignName>
                      {item?.campaignName ?? ''}
                    </SubCampaignName>
                    <SubCampaignDateCreated>
                      Created on:
                      {isValid(createdDate)
                        ? format(createdDate, 'MM/dd/yy')
                        : '-'}
                    </SubCampaignDateCreated>
                  </div>
                  <Platforms>{renderPlatforms(item?.platforms)}</Platforms>
                </SubCampaignDetailsWrapper>
              </SubCampaignItem>
            );
          })}
      </SubCampaignListContainer>
    </div>
  );
}

export default SubCampaignList;
