import styled from 'styled-components';
import { Flex } from '@rebass/grid';

export const CampaignFilterButton = styled(Flex).attrs({
  className: 'campaign-filter-btn',
})`
  flex-direction: column;
  justify-content: center;
  /* height: fit-content; */
  font-size: ${({ filterBtn }) => (filterBtn ? '12px' : '10px')};
  width: ${({ totalTabs, isEnterpriseAdvisor }) =>
    isEnterpriseAdvisor ? '5%' : totalTabs >= 9 ? '9%' : totalTabs >= 7 ? '11%' : '15%'};
  background-color: ${({ selected }) => (selected ? '#fff' : '#eeeeee')};
  cursor: pointer;
  border-radius: 4px;
  ${({ selected, name }) => {
    let colorHex = '#6351ed';
    if (name === 'All Campaigns') {
      colorHex = '#6351ed';
    } else if (name === 'Drafts') {
      colorHex = '#ffbf3e';
    } else if (name === 'Scheduled') {
      colorHex = '#00d7ff';
    } else if (name === 'In Progress') {
      colorHex = '#326DE6';
    } else if (name === 'Completed') {
      colorHex = '#33B21B';
    } else if (name === 'Approval Rejected') {
      colorHex = '#ff2323';
    } else if (name === 'Approval Pending') {
      colorHex = '#cc6ac0';
    } else if (name === 'Compliance Rejected') {
      colorHex = '#ff2323';
    } else if (name === 'Compliance Pending') {
      colorHex = '#cc6ac0';
    }

    return (
      selected &&
      `
        border-bottom: 4px solid ${colorHex};
    `
    );
  }}
`;

export const VerticalSeparator = styled.div`
  border: 0.1px solid #cfd9e4;
  height: ${({ height }) => height || '60px'};
  margin-left: 10px;
  margin-right: 10px;
`;
