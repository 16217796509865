import React from 'react';
import {
  BodyText,
  BottomGroup,
  CardContainer,
  CardTitle,
  DataColumns,
  DataRow,
  DataTitle,
  DataValue,
  HeaderGroup,
  IconStyle,
} from '../../../componentsV2/RecommendationCard/styles';
import lightbulb from '../../../assets/icons/lightbulb.svg';
import PurpleButton from '../../../componentsV2/PurpleButton';
import { Flex } from '@rebass/grid';
import { TopicChipStyle } from '../ContentTab/ContentTab.styles';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { isEmpty } from 'lodash';
import ErrorDisplay from '../../../componentsV2/ErrorDisplay/ErrorDisplay';
import { ColorPallete, Typography, timeZoneAbbreviated } from '../../../utils';
import { get } from 'lodash';
import { EmptyState } from '../../../componentsV2';
import Note from 'src/assets/icons/Note';
import FileIcon from 'src/assets/icons/File';
import ImageIcon from 'src/assets/icons/Image';
import Link from 'src/assets/icons/Link';
import { Pill, Pills } from 'src/pages/Home/KeyInsights/styles';
import CalendarBlank from 'src/assets/icons/CalendarBlank';
import Alarm from 'src/assets/icons/Alarm';

const CampaignRecommendationCard = ({
  title,
  onClick,
  campaignRecommendationData,
  loading,
  recommendationError,
  handleRetryClick,
  userDetails,
  showEnterpriseDropdownSelectedOption,
  recommendedTopics,
  campaignPerformanceData,
  handleCampaignperformanceScoreRetryClick,
  selectedFirms,
  campaignPerformanceLoading,
  campaignPerformanceError
}) => {
  const getFormat = (format) => {
    switch (format) {
      case 'lp':
        return {
          name: 'Landing Page',
          icon: <Note />,
        };
      case 'pdf':
        return {
          name: 'PDF',
          icon: <FileIcon />,
        };
      case 'img':
        return {
          name: 'Image',
          icon: <ImageIcon />,
        };
      case 'link':
        return {
          name: 'Links',
          icon: <Link />,
        };
      default:
        return { name: '', icon: <></> };
    }
  };
  return (
    <CardContainer
      margin="0px"
      height={
        showEnterpriseDropdownSelectedOption === 'My Enterprise' || !isEmpty(selectedFirms)
          ? '432px'
          : '465px'
      }
      style={{
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        borderLeft:
          showEnterpriseDropdownSelectedOption === 'My Enterprise' || !isEmpty(selectedFirms)
            ? '0px'
            : '1px solid #E4E7EC',
        borderBottomLeftRadius:
          showEnterpriseDropdownSelectedOption === 'My Enterprise' || !isEmpty(selectedFirms)
            ? '0px'
            : '8px',
        borderTopLeftRadius:
          showEnterpriseDropdownSelectedOption === 'My Enterprise' || !isEmpty(selectedFirms)
            ? '0px'
            : '8px',
        boxShadow:
          showEnterpriseDropdownSelectedOption === 'My Enterprise' || !isEmpty(selectedFirms)
            ? '0px 1px 2px 0px #1018281F'
            : null,
      }}
    >
      {(showEnterpriseDropdownSelectedOption === 'My Enterprise' || !isEmpty(selectedFirms)) && (
        <Flex width="100%" height="432px" flexDirection="column" padding="25px">
          {campaignPerformanceLoading && (
            <Skeleton height={375} baseColor={ColorPallete.neutral100} />
          )}
          {!campaignPerformanceLoading &&
            campaignPerformanceError === false &&
            isEmpty(campaignPerformanceData) && (
              <EmptyState
                title="No Analytics Data"
                subTitle="Send out campaigns to get insights"
                height="432px"
              />
            )}
          {!campaignPerformanceLoading && campaignPerformanceError === true && (
            <ErrorDisplay
              height="432px"
              onClick={handleCampaignperformanceScoreRetryClick}
            />
          )}
          {!campaignPerformanceLoading &&
            !isEmpty(campaignPerformanceData) &&
            campaignPerformanceError === false && (
              <>
                <Flex
                  width="100%"
                  flexDirection="row"
                  justifyContent="space-between"
                  marginBottom="20px"
                >
                  <Flex
                    width="46.5%"
                    height="180px"
                    padding="12px"
                    alignItems="center"
                    justifyContent="center"
                    style={{
                      border: '1px solid #F2F4F7',
                      borderRadius: '8px',
                      backgroundColor: '#FFFFFF',
                    }}
                    flexDirection="column"
                  >
                    <Flex margin="0px 0px 10px 0px">
                      <img alt="" src="/assets/images/Analytics/openRate.svg" />
                    </Flex>
                    <Flex fontSize="12px" color="#475467">
                      Open Rate
                    </Flex>
                    <Flex
                      fontSize="20px"
                      style={{ fontWeight: 500, color: '#101828' }}
                    >
                      {campaignPerformanceData?.open_rate} %
                    </Flex>
                  </Flex>
                  <Flex
                    width="46.5%"
                    height="180px"
                    padding="12px"
                    alignItems="center"
                    justifyContent="center"
                    style={{
                      border: '1px solid #F2F4F7',
                      borderRadius: '8px',
                      backgroundColor: '#FFFFFF',
                    }}
                    flexDirection="column"
                  >
                    <Flex margin="0px 0px 10px 0px">
                      <img
                        alt=""
                        src="/assets/images/Analytics/clickRate.svg"
                      />
                    </Flex>
                    <Flex fontSize="12px" color="#475467">
                      Click Rate
                    </Flex>
                    <Flex
                      fontSize="20px"
                      style={{ fontWeight: 500, color: '#101828' }}
                    >
                      {campaignPerformanceData?.click_rate} %
                    </Flex>
                  </Flex>
                </Flex>
                <Flex
                  width="100%"
                  flexDirection="row"
                  justifyContent="space-between"
                >
                  <Flex
                    width="46.5%"
                    height="180px"
                    padding="12px"
                    alignItems="center"
                    justifyContent="center"
                    style={{
                      border: '1px solid #F2F4F7',
                      borderRadius: '8px',
                      backgroundColor: '#FFFFFF',
                    }}
                    flexDirection="column"
                  >
                    <Flex margin="0px 0px 10px 0px">
                      <img
                        alt=""
                        src="/assets/images/Analytics/totalContactIcon.svg"
                      />
                    </Flex>
                    <Flex fontSize="12px" color="#475467">
                      Total Contacts Reached
                    </Flex>
                    <Flex
                      fontSize="20px"
                      style={{ fontWeight: 500, color: '#101828' }}
                    >
                      {campaignPerformanceData?.total_contacts_reached}
                    </Flex>
                  </Flex>
                  <Flex
                    width="46.5%"
                    height="180px"
                    padding="12px"
                    alignItems="center"
                    justifyContent="center"
                    style={{
                      border: '1px solid #F2F4F7',
                      borderRadius: '8px',
                      backgroundColor: '#FFFFFF',
                    }}
                    flexDirection="column"
                  >
                    <Flex margin="0px 0px 10px 0px">
                      <img
                        alt=""
                        src="/assets/images/Analytics/totalCampaignIcon.svg"
                      />
                    </Flex>
                    <Flex fontSize="12px" color="#475467">
                      Total Campaigns Sent
                    </Flex>
                    <Flex
                      fontSize="20px"
                      style={{ fontWeight: 500, color: '#101828' }}
                    >
                      {campaignPerformanceData?.total_campaign_sent}
                    </Flex>
                  </Flex>
                </Flex>
              </>
            )}
        </Flex>
      )}

      {showEnterpriseDropdownSelectedOption === 'My Activity' && (
        <>
          <HeaderGroup>
            <IconStyle src={lightbulb} alt="recommendation" />
            <CardTitle>{title}</CardTitle>
          </HeaderGroup>
          <BottomGroup
            style={{
              padding: '0px 0px 3px 0px',
              display: 'flex',
              height: '70px',
            }}
          >
            <BodyText style={{ margin: '10px 0px 15px' }}>
              Your top-performing campaigns shared the following
              characteristics.
            </BodyText>
          </BottomGroup>
          {loading && (
            <Skeleton height={121} baseColor={ColorPallete.neutral100} />
          )}
          {!loading &&
            !isEmpty(campaignRecommendationData) &&
            recommendationError === false && (
              <DataColumns style={{ marginBottom: '0px', height: '120px' }}>
                <DataRow style={{ height: '25px', margin: '5px auto 10px' }}>
                  <DataTitle style={{ fontSize: '12px', fontWeight: 400 }}>
                    Most Engaged Topic
                  </DataTitle>
                  {!isEmpty(recommendedTopics) ? (
                    <TopicChipStyle
                      style={{
                        height: '30px',
                        fontSize: '12px',
                        padding: '5px 8px',
                        alignItems: 'center',
                        display: 'flex',
                        margin: '0px',
                        fontWeight: 500,
                      }}
                    >
                      {recommendedTopics[0]}
                    </TopicChipStyle>
                  ) : (
                    '-'
                  )}
                </DataRow>
                <DataRow style={{ height: '25px', margin: '5px auto 10px' }}>
                  <DataTitle style={{ fontSize: '12px', fontWeight: 400 }}>
                    Most Engaging Format
                  </DataTitle>
                  <DataValue style={{ fontSize: '12px', fontWeight: 500 }}>
                    <Pill
                      color=" #101828"
                      bgColor="#ffffff"
                      borderColor="#e4e7ec"
                    >
                      {getFormat(campaignRecommendationData?.most_popular_formats[0])?.icon} {getFormat(campaignRecommendationData?.most_popular_formats[0])?.name}
                    </Pill>
                  </DataValue>
                </DataRow>

                <DataRow style={{ height: '25px', margin: '5px auto 10px' }}>
                  <DataTitle style={{ fontSize: '12px', fontWeight: 400 }}>
                    Best Time To Send
                  </DataTitle>
                <DataValue style={{ fontSize: '12px', fontWeight: 500 }}>
                  <Flex justifyContent="space-between">
                    <Pill 
                      color=" #101828"
                      bgColor="#ffffff"
                      borderColor="#e4e7ec"
                    >
                      <CalendarBlank /> {campaignRecommendationData?.most_popular_day ?? ''} ({campaignRecommendationData?.most_popular_time ?? ''})
                    </Pill>
                  </Flex>
                </DataValue>
                </DataRow>
              </DataColumns>
            )}
          {!loading && recommendationError === true && (
            <ErrorDisplay height="125px" onClick={handleRetryClick} />
          )}
          <BottomGroup
            style={{
              padding: '10px 0px 30px',
              margin: '0px auto 5px',
              display: 'flex',
              height: '100px',
            }}
          >
            <BodyText
              style={{
                margin: '0px',
              }}
            >
              <span style={{ color: ColorPallete.black }}>
                Put the data to use and construct your next campaign with these
                in mind.
              </span>
            </BodyText>
          </BottomGroup>
          <Flex height="66px" alignItems="center">
            <PurpleButton
              children={
                <Flex>
                  Start New Campaign
                  <img
                    src="/assets/images/Analytics/right-arrow-color.svg"
                    alt=""
                    style={{ margin: '0px 0px 0px 4px' }}
                  />
                </Flex>
              }
              width="90%"
              onClick={onClick}
              margin="15px auto 20px"
            />
          </Flex>
        </>
      )}
    </CardContainer>
  );
};

export default CampaignRecommendationCard;
