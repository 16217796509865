export const transformSummaryData = (data) => {
  return {
    platforms: data?.platforms ?? {
      is_email: false,
      is_facebook: false,
      is_linkedin: false,
      is_twitter: false,
    },
    purpose: data?.purpose ?? null,
    status: data?.status ?? '',
    campaignType: data?.type ?? '',
    campaignName: data?.name ?? '',
    startDate: data?.start_date ?? '-',
    campaignDuration: data?.duration ?? '-',
    totalContacts: data?.total_contacts ?? '-',
    media_url: data?.media_url ?? '',
  };
};
