import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import { DashboardArticle, FreeArticle } from '../../../components';

import { getExploreArticleData, dashboardSliderSettings, eventHandlers } from '../../../utils';

import { ArticleSliderWrapper } from '../DashboardContainer.styles';
import { isEmpty, get } from 'lodash';

const TrendingTab = ({
  selectedTopic,
  exploreArticles,
  deleteArticleRequest,
  themeNames,
  exploreArticleDeleting,
  isFreeUser,
  isShareBlocked,
  isDeleteBlocked,
  showMio,
  setShowArticleLoader,
  popularThemes,
  userData,
  refresh,
  onNewsletterArticleClick,
  onRemoveFromNewletterClick,
  newsletterArticleFetching,
  bookmarksFetching,
  exploreNewsletterId,
  bookmarksId
}) => {
    const [localExploreArticles, setLocalExploreArticles] = useState([]);
    const [totalArticles, setTotalArticles] = useState(0);
    const [imageCounter, setImageCounter] = useState([]);

    dashboardSliderSettings.afterChange = (index) => {
      let articles = getExploreArticleData(exploreArticles).slice(index, index + 4);
      let sponsoredArticle = articles.filter(a => a.sponseredContent);
      const userEmail = userData && userData.details && userData.details.user && userData.details.user.email;
      if (!isEmpty(sponsoredArticle)) {
        sponsoredArticle.forEach((article) => {
          eventHandlers(
            'SPONSORED_ARTICLE_DISPLAYED',
            popularThemes,
            article.id,
            'POPULAR',
            article.channelId,
            article.title,
            article.themeId,
            themeNames[article.themeId],
            article.topic,
            userEmail
          );
        });
      }
      if (!isEmpty(articles)) {
        articles.forEach((article) => {
          eventHandlers(
            'ARTICLE_DISPLAYED',
            popularThemes,
            article.id,
            'POPULAR',
            article.channelId,
            article.title,
            article.themeId,
            themeNames[article.themeId],
            article.topic,
            userEmail
          );
        });
      }
    }

     useEffect(() => {
       if(exploreArticles && isEmpty(exploreArticles)) {
         setShowArticleLoader(false);
       }
       let articles = getExploreArticleData(exploreArticles);
       setLocalExploreArticles(articles);
       setTotalArticles(articles.length);
        // eslint-disable-next-line react-hooks/exhaustive-deps
     }, [exploreArticles]);

     useEffect(() => {
      if(!isEmpty(get(exploreArticles, 'feed', []))) {
        let articles = getExploreArticleData(exploreArticles).slice(0, 4);
        let sponsoredArticle = articles.filter(a => a.sponseredContent);
        const userEmail = userData && userData.details && userData.details.user && userData.details.user.email;
        if (!isEmpty(sponsoredArticle)) {  
          sponsoredArticle.forEach((article) => {
            eventHandlers(
              'SPONSORED_ARTICLE_DISPLAYED',
              popularThemes,
              article.id,
              'POPULAR',
              article.channelId,
              article.title,
              article.themeId,
              themeNames[article.themeId],
              article.topic,
              userEmail
            );
          });
        }
        if(!isEmpty(articles)) {
          console.log('[article]')
          articles.forEach((article) => {
           eventHandlers(
             'ARTICLE_DISPLAYED',
             popularThemes,
             article.id,
             'POPULAR',
             article.channelId,
             article.title,
             article.themeId,
             themeNames[article.themeId],
             article.topic,
             userEmail
           );
          });
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

  return (

    <ArticleSliderWrapper>
      <Slider {...dashboardSliderSettings}>
        {isFreeUser && <FreeArticle />}
        {localExploreArticles.map((article,index) => (
          <DashboardArticle
            key={'dashboard_article_'+index}
            refresh={refresh}
            bookmarksFetching={bookmarksFetching}
            bookmarksId={bookmarksId}
            article={article}
            {...{
              ...article,
              selectedTopic,
              isFreeUser,
              deleteArticleRequest,
              themeNames,
              exploreArticleDeleting,
              isShareBlocked,
              isDeleteBlocked,
              showMio,
              index,
              setShowArticleLoader,
              totalArticles,
              setImageCounter,
              imageCounter,
              section: 'POPULAR',
              onNewsletterArticleClick,
              onRemoveFromNewletterClick,
              newsletterArticleFetching,
              exploreNewsletterId
            }}
          />
        ))}
      </Slider>
    </ArticleSliderWrapper>
  );
};

export default TrendingTab;
