/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { get, isEmpty } from 'lodash';
import * as Yup from 'yup';
import {
  ProfileImage,
} from '../../../components/AdvisorProfile/AdvisorProfile';
import { 
  ProfileContainer,
  GroupingContainer,
  Heading,
  TextInput,
  TextLabel,
  ErrorMessage,
  SuccessDisplay,
  ButtonContainer,
  InvertedButton,
  ButtonIcon,
  HeadingSmall,
  HeadingLarge
 } from './Profile.styles';
import {
  FileUpload,
} from '../../../components';
import ModalContainer from '../../../componentsV2/ModalContainer';
import { getMediaUrl, ROLE_TYPES } from '../../../utils';
import EditProfile from './EditProfile';
import ProfileEditor from '../../../components/ProfileEditor/ProfileEditor';
import { updatePassword, updateProfile } from '../../../services/configure';
import { getUserDetails, getUserId } from '../../../redux/selector';
import { actions as loginActions } from '../../../redux/modules/login.module';
import { getSocialAccountURLs } from '../../../services/dashboard';
import { getRoleType } from '../../../redux/selector';
import { AddButton } from '../EmailList/EmailList.styles';
import editPen from '../../../assets/icons/editPen.svg';
import refreshIcon from '../../../assets/icons/refreshIcon.svg';

const profileErrorSchema = () => {
  return Yup.object().shape({
    first_name: Yup.string().required('First Name is required.'),
    last_name: Yup.string().required('Last Name is required'),
    city: Yup.string().required('City is required'),
    contact_email: Yup.string().required('Contact Email is required'),
  });
};

const Profile = ({
  userData,
  userId,
  loginRequest,
  socialNetworkData,
  setSocialAccountURL,
  roleType,
  userDetails,
  isPremiumAdvisor,
  user,
}) => {
  const [isEditProfile, setIsEditProfile] = useState(true);
  const [uploadedImage, setUploadedImage] = useState('');
  const [currentPasswordErr, setCurrentPasswordErr] = useState(false);
  const [confirmPasswordErr, setConfirmPasswordErr] = useState(false);
  const [passwordSuccess, setPasswordSuccess] = useState(false);
  const [loader, toggleLoader] = useState(false);
  const [leadCaptureSetting, setLeadCaptureSetting] = useState(true);
  const [showPasswordModal, setShowPasswordModal] = useState(false);
  const [selectedTimeZone, setSelectedTimeZone] = useState({name: '', zone: ''});
  const [passwordValues, setPasswordValues] = useState({
    fAUserPasswordCh: '', 
    fAUserPasswordChN: '',
    fAUserPasswordChNC: '',
  });

  useEffect(() => {
    getSocialAccountURLs()
      .then((res) => {
        setSocialAccountURL(res.result.data);
      })
      .catch((err) => {});
  }, [setSocialAccountURL]);

  useEffect(() => {
    if(get(userData, 'lead_capture', '') === 'smart') {
      setLeadCaptureSetting(true);
    } else if(get(userData, 'lead_capture', '') === 'classic') {
      setLeadCaptureSetting(false);
    }
    setSelectedTimeZone({
      name: userData && !isEmpty(userData.timezone) ? userData.google_timezones[userData.timezone] : '', 
      zone: userData ? userData.timezone : ''
    })
  }, [])

  const update = async (data, isChangePswrd) => {
    toggleLoader(true);
    const payload = {
      userId,
      data,
    };

    return new Promise(async (resolve, reject) => {
      try {
        const { result } = await updateProfile(payload);
        if (result.success) {
          await loginRequest();
          toggleLoader(false);
          resolve({ success: true });
        } else {
          toggleLoader(false);
          reject({ success: true });
        }
      } catch (err) {
        if (
          isChangePswrd &&
          get(err, 'response.data.result.error.code', '') === 1000
        ) {
          setCurrentPasswordErr('Invalid Current Password');
        }
        console.log(err);
        toggleLoader(false);
      }
    });
  };

  const updatePasswordHandler = data => {
    const payload = {
      uid: userId,
      ...data
    };
    return updatePassword(payload)
  }

  const handleSavePasswordClick = (values) => {
    setCurrentPasswordErr('');
    setConfirmPasswordErr('');
    toggleLoader(true);
    updatePasswordHandler(
      {
        force_password_change: false,
        current_password: encodeURIComponent(values.fAUserPasswordCh).replace(
          /'/g,
          '%27'
        ),
        password: encodeURIComponent(values.fAUserPasswordChN).replace(
          /'/g,
          '%27'
        ),
      },
      // true
    )
      .then((res) => {
        if (res && res.result.success) {
          toggleLoader(false);
          loginRequest({noRefresh: false})
          setPasswordSuccess(true)
        }
      })
      .catch((err) => {
        toggleLoader(false);
        if(get(err, 'response.data.result.error.code', '') === 1000)
          setCurrentPasswordErr('Invalid Current Password');
        else if(passwordValues['fAUserPasswordCh'] !== passwordValues['fAUserPasswordChN']){
          setConfirmPasswordErr('Passwords do not match')
        }
        else
          setConfirmPasswordErr('Failed to change password. Please try again later.');
      });
  };

  const handleAvatarChange = (file) => {
    setUploadedImage(file[0]);
  };

  const handleChangeProfile = async (values) => {
    if (isEmpty(values.about_me)) values.about_me = 'empty';
    // if (isEmpty(values.digital_banner)) values.digital_banner = 'empty';
    if (isEmpty(values.firm_name)) values.firm_name = 'empty';
    if(selectedTimeZone){
      values.timezone = selectedTimeZone.zone;
    }
    delete values.email;
    await update({
      ...values,
      lead_capture: leadCaptureSetting ? 'smart' : 'classic'
    });
  };

  const { first, last } = get(userData, 'name', {});
  const city = userData ? userData.city : '';
  const mobile = userData ? userData.mobile : '';
  const email = userData ? userData.email : '';
  const contact_email = get(userData, 'extra_settings.contact_email', ''); 
  const timezone = userData ? userData.timezone : '';

  const firmName = userData && userData.firm_name ? userData.firm_name : '';
  const about = userData && userData.about_me ? userData.about_me : '';
  const digital_banner = userData && userData.digital_banner ? userData.digital_banner : ''; 

  const data = {
    first_name:
      get(userDetails, 'is_impersonator', '') === 'true'
        ? get(userDetails, 'impersonate.first_name', '')
        : first,
    last_name:
      get(userDetails, 'is_impersonator', '') === 'true'
        ? get(userDetails, 'impersonate.last_name', '')
        : last,
    city: city,
    mobile: mobile,
    email:
      get(userDetails, 'is_impersonator', '') === 'true'
        ? get(userDetails, 'impersonate.email', '')
        : email,
    contact_email : contact_email,
    timezone: timezone,
    about_me: about,
    digital_banner: digital_banner,
    firm_name: firmName 
  };
  const logo = getMediaUrl(userData.media_urls);

  const handleImage = async (imageFile) => {
    await update({
      media1: imageFile,
    });
    setUploadedImage('');
  };

  const profileImageDisplay = () => {
    return(
      <div style={{display: 'table'}}>
        {logo.length > 0 ? (
            <ProfileImage src={logo} />
          ) : (
            <ProfileImage src={`/assets/images/profilePicDef.jpg`} />
          )}
      </div>
    )
  };

  const toggleModal = () => {
    setShowPasswordModal(!showPasswordModal)
  }

  const passwordResetDisplay = () => {
    if(passwordSuccess){
      return(
        <div style={{width: '400px'}}>
          <SuccessDisplay>You have successfully changed your password</SuccessDisplay>
          <AddButton 
            children={'Return'} 
            onClick={()=>setShowPasswordModal(false)} 
            style={{height: 40, margin: '20px auto 0'}} 
          />
        </div>
      )
    }
    return(
      <div style={{width: '400px'}}>
         <TextLabel>
          Current Password
          <TextInput
            placeholder=""
            width='100%'
            type="password"
            id="currentPassword"
            name="currentPassword"
            onChange={(e) => setPasswordValues({...passwordValues, ['fAUserPasswordCh']: e.target.value})}
            value={passwordValues['fAUserPasswordCh']}
          />
          {currentPasswordErr && <ErrorMessage>{currentPasswordErr}</ErrorMessage>}
        </TextLabel>
        <TextLabel>
          New Password
          <TextInput
            placeholder=""
            width='100%'
            type="password"
            id="newPassword"
            name="newPassword"
            onChange={(e) => setPasswordValues({...passwordValues, ['fAUserPasswordChN']: e.target.value})}
            value={passwordValues['fAUserPasswordChN']}
          />
          <div style={{fontSize: 11, margin: '0 0 6px'}}>Your password must contain at least 8 characters</div>
        </TextLabel>
        <TextLabel>
          Confirm Password
          <TextInput
            placeholder=""
            width='100%'
            type="password"
            id="confirmPassword"
            name="confirmPassword"
            onChange={(e) => setPasswordValues({...passwordValues, ['fAUserPasswordChNC']: e.target.value})}
            value={passwordValues['fAUserPasswordChNC']}
          />
          {confirmPasswordErr && <ErrorMessage>{confirmPasswordErr}</ErrorMessage>}
        </TextLabel>
        <AddButton 
          children={'Submit'} 
          onClick={()=>handleSavePasswordClick(passwordValues)} 
          style={{height: 40, margin: '20px auto 0'}} 
          disabled={
            !passwordValues['fAUserPasswordCh'].length || 
            passwordValues['fAUserPasswordChN'].length < 7 || 
            passwordValues['fAUserPasswordChNC'].length < 1 ||
            passwordValues['fAUserPasswordChN'] !== passwordValues['fAUserPasswordChNC']
          }
        />
      </div>
    )
  };

  const editPictureButton = () => {
    return(
      <>
        {!(
            get(user, 'details.user.is_impersonator', 'false') === 'true' &&
            (roleType === ROLE_TYPES.ADMIN_ENTERPRISE ||
              roleType === ROLE_TYPES.ADMIN_COMPLIANCE)
          ) && (
            <div>
              <FileUpload
                asButton
                styles={{margin: '-14px 10px 0 0'}}
                name="avatar"
                icon="icon dripicons-camera"
                id="avatar"
                onClick={() => {}}
                label="Edit Picture"
                onChange={(file) => handleAvatarChange(file)}
                accept=".png,.jpeg,.jpg,.svg,.bmp"
                customStyle={'whiteButton'}
                customImage={editPen}
              />
            </div>
          )}
          </>
    )
  };

  const topProfileDisplay = () => {
    return(
      <div style={{margin: '20px 0px', display:'flex', justifyContent: 'start', width: '100%'}}>
        <div>
          {uploadedImage === '' ? (
            profileImageDisplay()
          ) : (
            <ProfileEditor
              image={uploadedImage}
              handleImage={(image) => handleImage(image)}
              width={142}
              height={142}
              maxScale={5}
              background={[255, 255, 255, 1]}
            />
          )}
        </div>
        <div style={{width: 'fit-content', margin: '0 0 0 20px'}}>
          <div>
            <HeadingSmall>Account Email (Username): </HeadingSmall>
            <HeadingLarge>{data?.email}</HeadingLarge>
            {roleType &&
              roleType === 'ADVISOR_ENTERPRISE' &&
              userDetails &&
              userDetails.company_name && (
                <div>
                  <div>
                    <span style={{ color: '#7b7b7b', fontSize: '12px' }}>
                      Invited By: {userDetails.company_name}
                    </span>
                  </div>
                </div>
            )}
            <ButtonContainer>
              {
                editPictureButton()
              }
              <InvertedButton 
                onClick={()=>setShowPasswordModal(true)} 
              >
                <ButtonIcon src={refreshIcon} alt='edit' />
                <span style={{margin: '0px 0 2px'}}>Reset Password</span>
              </InvertedButton>
            </ButtonContainer>
          </div>
        </div>
      </div>
      )
    }

    return (
      <ProfileContainer>
        <Heading>Account Profile</Heading>
          {
            topProfileDisplay()
          }
          {isEditProfile && (
            <EditProfile
              errorSchema={profileErrorSchema(isPremiumAdvisor)}
              data={data}
              userData={userData}
              onEditProfile={(values) => handleChangeProfile(values)}
              isPremiumAdvisor={isPremiumAdvisor}
              logo={logo}
              selectedTimeZone={selectedTimeZone}
              setSelectedTimeZone={setSelectedTimeZone}
              socialLinks={{
                linkedIn:
                  socialNetworkData?.ln && !isEmpty(socialNetworkData?.ln)
                    ? true
                    : false,
                facebook:
                  socialNetworkData?.fb && !isEmpty(socialNetworkData?.fb)
                    ? true
                    : false,
                twitter:
                  socialNetworkData?.tw && !isEmpty(socialNetworkData?.tw)
                    ? true
                    : false,
              }}
              roleType={roleType}
              user={user}
              leadCaptureSetting={leadCaptureSetting}
              setLeadCaptureSetting={setLeadCaptureSetting}
              loader={loader}
            />
        )}
        {get(userDetails, 'is_impersonator', '') === 'true' && (
          <div>
            <div>
              <span style={{ color: '#7b7b7b', fontSize: '12px' }}>
                Main Account - {get(userDetails, 'email', '')}
              </span>
            </div>
          </div>
        )}
        <ModalContainer
          modalStatus={showPasswordModal}
          closeModal={toggleModal}
          title="Reset Password"
          children={passwordResetDisplay()}
        />
    </ProfileContainer>
  );
};

const mapStateToProps = (state) => {
  const roleType = getRoleType(state);
  const isPremiumAdvisor = roleType === ROLE_TYPES.ADVISOR_PREMIUM;

  return {
    isPremiumAdvisor,
    userId: getUserId(state),
    userDetails: getUserDetails(state),
    user: state.user.data,
    socialNetworkData: state.socialNetwork.data,
    roleType: roleType,
  };
};

const mapDispatchToProps = {
  loginRequest: loginActions.api.login.request,
};

export default connect(mapStateToProps, mapDispatchToProps)(Profile);