/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { get, isEmpty, find } from 'lodash';
import qs from 'querystring';
import {
  ErrorContainer,
  ErrorModal,
  Modal,
  ModalHeader,
  ModalCloseButton,
} from '../../../components';
import ToggleBar from '../../../components/ToggleBar';
import IconCards from '../../../components/IconCards';
import ModalContainer from '../../../componentsV2/ModalContainer';
import EmailListTable from './EmailListTable';
import { getContactList } from '../../../services/dashboard';
import { 
  EditIcon, 
  TagStyle, 
  AddButton, 
  TooltipRow,
  CustomTooltip,
  TagContainer
} from './EmailList.styles';
import {
  getContactsGroups,
  updateContactList,
  redTailLogin,
} from '../../../services/configure';
import { getSocialAccountURLs } from '../../../services/dashboard';
import RedTailModal from '../RedTailModal/RedTailModal';
import {
  faGoogle,
  faSalesforce,
  faHubspot,
} from '@fortawesome/free-brands-svg-icons';
import wealthBoxIcon from '../../../assets/logos/wealthbox-logo-light.png';
import redTailIcon from '../../../assets/logos/redtail_logo.png';
import ContactListModal from '../ContactListModal';
import SegmentTable from './SegmentTable';
import { getSegments } from '../../../services/segment';
import AddNewList from './AddNewList';
import CreateSegment from './CreateSegment';
import Tooltip from '@mui/material/Tooltip';
import editIconDots from './editIconDots.svg';
import ContactsATSList from './ContactsATSList';
import { connect } from 'react-redux';



const socialIcons = [
  { id: 'hs', platform: 'hs', icon: faHubspot, name: 'HubSpot' },
  { id: 'sf', platform: 'sf', icon: faSalesforce, name: 'Salesforce' },
  { id: 'wb', platform: 'wb', src: wealthBoxIcon, name: 'WealthBox' },
  { id: 'rt', platform: 'rt', src: redTailIcon, name: 'RedTail' },
  { id: 'go', platform: 'em', icon: faGoogle, name: 'Google' },
];

const EmailList = ({
  contactError,
  contactsList,
  contactGroupsList,
  contactListChange,
  onContactDelete,
  contactsCreate,
  setIsUpdateContact,
  isContactsFetching,
  selectedContactGroup,
  socialAccountURL,
  socialNetworkData,
  retrieveGroupsList,
  userData,
  userId,
  loginRequest,
  contactsRequest,
  paginationData,
  navigateAfter,
  socialNetworkStatusRequest,
  socialNetworkDataFetching,
  socialNetworkDelete,
  setSocialAccountURL,
  isAdmin,
  contactsCount,
  contactGroups,
  noRecords,
  isEnterpriseLevelAdmin,
  showContactType
}) => {
  const [contactErrorMessage, setContactError] = useState(false);
  const [isContactCreate, setIsContactCreate] = useState(false);
    useState(false);
  const [errorFromCustomeEmail, setErrorFromCustomeEmail] = useState('');
  const [selectedEmailListItem, setSelectedEmailListItem] = useState([]);
  const [multipleConnectionMessage, setMultipleConnectionMessage] =
    useState('');
  const [page, setPage] = useState(0);
  const [isOpen, setIsOpen] = useState(true);
  const [contactOpen, setContactOpen] = useState(false);
  const [selectedNetwork, setSelectedNetwork] = useState('');
  const [sortFlag, setSortFlag] = useState('asc');
  const [fromEdit, toggleFromEdit] = useState(false);
  const [openDisconnectModal, setDisconnectModal] = useState(false);
  const [currentlyEditingContactList, setCurrentlyEditingContactList] = useState({});
  //Segment Constants
  const [segmentTabActive, setSegmentTabActive] = useState(false);
  const [segmentList, setSegmentList] = useState([]);
  const [emailList, setEmailList] = useState([]);
  const [renameModal, setRenameModal] = useState(false);
  const [modalDisplay, setModalDisplay] = useState('edit');
  const [showListModal, setShowListModal] = useState(false);
  const [showSegmentModal, setShowSegmentModal] = useState(false);
  const [addListTitle, setAddListTitle] = useState('Add New List');
  const [addSegmentTitle, setAddSegmentTitle] = useState('Create New Segment');
  const [selectedSegmentObj, setSelectedSegmentObj] = useState(null);
  const [isNewSegment, setIsNewSegment] = useState(false);
  //New table fetch from parent
  const [emailTableData, setEmailTableData] = useState(contactsList || []);
  const [emailTableLoading, setEmailTableLoading] = useState(false);
  const [segmentTableLoading, setSegmentTableLoading] = useState(false);
  const [editList, setEditList] = useState(false);
  const [editContact, setEditContact] = useState();
  const [selectedEmailList, setSelectedEmailList] = useState(null);
  const [segmentListLoading, setSegmentListLoading] = useState(false);
  const [emailListLoading, setEmailListLoading] = useState(false);
  const [addModalDisplay, setAddModalDisplay] = useState('select');
  const [integrationsHeader, setIntegrationsHeader] = useState('Select Contact Lists to Import');
  const [gmContact, setGmContact] = useState([]);
  const [newContacts, setNewContacts] = useState(false);
  const [show, setShow] = useState(false);
  const [selectedId, setSelectedId] = useState();
  const [contactsTrigger, setContactsTrigger] = useState(false);
  const [tabIndex, setTabIndex] = useState('emailLists');

  const toggleItems = [
    {
      name: 'Email Lists',
      key: 'emailLists'
    },
    {
      name: 'Segments',
      key: 'segments'
    }
  ];

  if(!isEnterpriseLevelAdmin) {
    toggleItems.push({
      name: 'Approve To Share',
      key: 'approveToShare'
    })
  }

  useEffect(() => {
    loginRequest({ noRefresh: true });
    fetchContactsGroup()
    if (isAdmin) {
      window.sessionStorage.setItem('selectedIndex', 3);
    } else window.sessionStorage.setItem('selectedIndex', 2);
    // tiggerLoginRequest();
    fetchSegments()
  }, []);

  useEffect(() => {
    setEmailTableLoading(true)
    if (isEmpty(selectedEmailListItem)) {
      setEmailTableData(contactsList);
      setEmailTableLoading(false)
    } else if(!isEmpty(selectedEmailListItem) && !isEmpty(contactsList)) {
      selectedEmailListItem.forEach((selectedItem) => {
        contactsList.forEach((element) => {
          if (selectedItem.email === element.email) {
            element.checked = true;
          }
        });
      });
      setEmailTableData(contactsList);
      !newContacts && setEmailTableLoading(false)
    }
  }, [contactsList]);

  useEffect(() => {
    if (
      contactError &&
      contactError.message &&
      contactError.type === 'FieldValidationException'
    ) {
      setContactError('Please upload as per sample file.');
    } else if (
      contactError &&
      contactError.message &&
      contactError.type === 'ContactListException' &&
      contactError.code === 2903
    ) {
      setContactError('Add/Delete on Imported List is Not permitted');
    } else if (
      contactError &&
      contactError.message &&
      contactError.type !== 'FieldValidationException'
    ) {
      setContactError(
        'List name is not valid, please try with the other list name'
      );
    } else {
      setContactError(false);
    }
    if (!contactError) {
      retrieveGroupsList();
    }
  }, [contactError]);

  useEffect(() => {
    getSocialAccountURLs()
      .then((res) => {
        setSocialAccountURL(res.result.data);
      })
      .catch((err) => {});
  }, [setSocialAccountURL]);

  useEffect(() => {
    if (isContactCreate) {
      setIsContactCreate(false);
      setUploadType('');
    }
  }, [isContactsFetching]);

  useEffect(() => {
    if (!isEmpty(socialNetworkData)) {
      const { snetwork } = qs.parse(window.location.href);
      if (
        snetwork &&
        window.sessionStorage.getItem('snetwork') &&
        window.sessionStorage.getItem('snetwork') !== 'go' &&
        window.sessionStorage.getItem('snetwork') !== 'ol'
      ) {
        setSelectedNetwork(window.sessionStorage.getItem('snetwork'));
        setContactOpen(true);
      }
    }
  }, [socialNetworkData]);

  const [uploadType, setUploadType] = useState('');
  const [socialContactGroups, setSocialContactGroups] = useState([]);
  const [redTailModal, setRedTailModal] = useState(false);
  const [redTailError, setRedTailError] = useState(false);

  const fetchContactsGroup = async() => {
    try{
      const response = await getContactsGroups()
      setSocialContactGroups(get(response, 'result.data', []));
    }
    catch(err){
      setSocialContactGroups([]);
    }
  }

  const fetchSegments = async() => {
    try{
      const response = await getSegments()
      setSegmentList(response.result.data);
    }catch(err){
      console.log('fetch segments error:', err)
    }
  }

  const handleEmailSort = async(flag) => {
    setEmailTableLoading(true)    
    setSortFlag(flag);
    await contactsRequest({
      params: {
        contactGroup: selectedContactGroup,
        sortOptions: flag,
      },
    });
    setEmailTableLoading(false)   
  };
  
  const onModalClose = () => {
    setMultipleConnectionMessage('');
    setIsOpen(false);
    setUploadType('');
  };

  const handleSocialIconClick = (id, network) => {
    let link = get(socialAccountURL, `${id}.oauth_url.${network}`, '');
    window.sessionStorage.setItem('snetwork', id);
    if (!link) {
      link = get(socialAccountURL, `${id}.oauth_url`, '');
    }

    if (link) {
      if (link) window.location.href = link;
    }
  };

  const handleContactListChange = (value, searchTerm = null) => {
    setPage(0);
    setUploadType('');
    setSelectedEmailListItem([]);

    if (value === 'hs') {
      if (get(socialNetworkData, 'hs', []).length >= 1) {
        setSelectedNetwork('hs');
        setContactOpen(true);
      } else {
        handleSocialIconClick('hs');
      }
    } else if (value === 'newLst') {
      setIsUpdateContact(true);
    } else if (value === 'custom') {
      setUploadType('custom');
    } else if (value === 'sf') {
      if (get(socialNetworkData, 'sf', []).length >= 1) {
        setSelectedNetwork('sf');
        setContactOpen(true);
      } else {
        handleSocialIconClick('sf');
      }
    } else if (value === 'wb') {
      if (get(socialNetworkData, 'wb', []).length >= 1) {
        setSelectedNetwork('wb');
        setContactOpen(true);
      } else {
        handleSocialIconClick('wb');
      }
    } else if (value === 'rt') {
      if (get(socialNetworkData, 'rt', []).length >= 1) {
        setSelectedNetwork('rt');
        setContactOpen(true);
      } else {
        setRedTailModal(true);
      }
    } else if (value === 'static') {
    } else {
      setSortFlag('asc');
      contactListChange(value, searchTerm);
    }
  };

  const handleRedTailLogin = ({ username, password }) => {
    redTailLogin(username, password)
      .then((res) => {
        if (res.result.success) {
          setSelectedNetwork('rt');
          socialNetworkStatusRequest();
          setRedTailModal(false);
          setContactOpen(true);
        }
      })
      .catch((err) => {
        console.log(err);
        setRedTailError(true);
      });
  };

  const handleContactListUpdate = (name, selectedContactGrp) => {
    if (selectedContactGrp !== undefined) {
      // name = selectedContact.name;
      updateContactList(name, selectedContactGrp)
        .then((res) => {
          if (res.result.success) {
            setPage(0);
            contactListChange(selectedContactGrp);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const reloadGroupList = (e, input) => {
    e.stopPropagation();
    const item = input.id;
    let sNetwork;
    switch (item.toLowerCase()) {
      case 'hs':
        sNetwork = 'hs';
        break;
      case 'sf':
        sNetwork = 'sf';
        break;
      case 'wb':
        sNetwork = 'wb';
        break;
      case 'rt':
        sNetwork = 'rt';
        break;
      case 'go':
        sNetwork = 'gm';
        break;
      case 'ol':
        sNetwork = 'ol';
        break;
      default:
        break;
    }

    if (sNetwork) {
      setSelectedNetwork(sNetwork);
      if (item === 'hs' || item === 'sf' || item === 'wb' || item === 'rt')
        setContactOpen(true);
    }
  };

  const handleClientsSocialIconClick = (
    id,
    isDisconnected,
    platform,
    emailPlatform
  ) => {
    let link = '',
      type = '';

    if (socialAccountURL && isDisconnected === false) {
      if (isAdmin) {
        window.sessionStorage.setItem('selectedIndex', 3);
      } else window.sessionStorage.setItem('selectedIndex', 2);
      window.sessionStorage.setItem('snetwork', id);
      switch (id) {
        case 'go':
          link = socialAccountURL.em.oauth_url.google;
          break;
        case 'ol':
          link = socialAccountURL.em.oauth_url.outlook;
          break;
        case 'wb':
          link = socialAccountURL.wb.oauth_url;
          break;
        case 'sf':
          link = socialAccountURL.sf.oauth_url;
          break;
        case 'em':
          link = '';
          break;
        case 'hs':
          link = socialAccountURL.hs.oauth_url;
          break;
        case 'rt':
          link = '';
          type = 'redTail';
        /* fallthrough */
        default:
          link = '';
      }
      if (link) window.location.href = link;
      else if (type && type === 'redTail') {
        setRedTailModal(true);
      }
    } else {
      if(platform === 'sf') {
        setDisconnectModal(true);
      } else {
        get(socialNetworkData, platform, []).forEach((item) => {
          if (
            (emailPlatform && emailPlatform === item.snetworkProvider) ||
            !emailPlatform
          ) {
            socialNetworkDelete({
              params: {
                id: `${platform}_${item.accountId}`,
              },
            });
          }
        });
      }
    }
  }

  const clearState = () => {
    setSelectedNetwork('');

    window.history.replaceState(
      '',
      '',
      window.location.protocol +
      '//' +
      window.location.host +
      window.location.pathname
    );

    setUploadType('');
    setContactOpen(false);

    window.sessionStorage.removeItem('snetwork');
  }

  const formatSocialIcons = () => {
    let outputIcons = [];
    for(let icon in socialIcons){
      const buttonDetails = getBottomText(
        socialIcons[icon].platform,
        socialIcons[icon].emailPlatform
      )
      outputIcons.push({
        ...socialIcons[icon],
        bottomText: buttonDetails.bottomText,
        isDisconnected: buttonDetails.isDisconnected,
      })
    }
    return outputIcons
  };

  const getBottomText = (platform, emailPlaftform) => {
    let platformDetails = get(socialNetworkData, platform, []);
    if (emailPlaftform) {
      platformDetails = find(platformDetails, {
        snetworkProvider: emailPlaftform,
      });
    }

    const text = isEmpty(platformDetails) ? 'Connect' : 'Disconnect';
    return {
      bottomText: text,
      isDisconnected: text === 'Disconnect',
    };
  };

  const tabChange = async(flag) => {
    setTabIndex(flag);
    if(flag === 'segments') {
      try{
        setSegmentTabActive(flag);
        const response = await getSegments();
        setSegmentList(response.result.data);
      }catch(err){
        console.log('tab change error:', err)
      }
    } else {
      setSegmentTabActive(false);
    } 
  }

  const onEmailListCheckBtnClick = async (row) => {
    await handleContactListUpdate(
      row.name,
      row.id
    );
  };

  const addContactAction = () => {
    setAddModalDisplay('addList')
    setShowListModal(true)
  }

  const customRows = (row, type) => {
    return(
      <div onMouseLeave={() => {
        setShow(false);
        setSelectedId('')}} style={{borderRadius: '8px !important'}}>
        <TooltipRow onClick={(e)=>{
          e.stopPropagation();
          if(type === 'segment'){    
            setIsNewSegment(false) 
            setSelectedSegmentObj(row)     
            setAddSegmentTitle('Update Segment Details')
            setShowSegmentModal(true);
          } else {
            setCurrentlyEditingContactList({
              item: row,
              value: row.name,
            })
            setModalDisplay('edit')
            setRenameModal(true);
          }
        }}>Edit {type === 'segment' ? 'Segment' : 'Name'}</TooltipRow>
        <TooltipRow onClick={(e)=>{
          e.stopPropagation();
          setCurrentlyEditingContactList({
            item: row,
            value: row.name,
          })
          setModalDisplay('delete')
          setRenameModal(true);
        }}>Delete</TooltipRow>
        {type === "list" && !row.is_default &&
          <TooltipRow 
            onClick={()=>onEmailListCheckBtnClick(row)}
          >Make Default
          </TooltipRow>
        }
      </div>
    )
  };

  const editListModal = (contact) => {
    setEditContact(contact)
    setEditList(true)
    setShowListModal(true)
  }


  const displayTable = () => {
    switch (tabIndex) {
      case 'emailLists':
        return emailListTable();
      case 'segments': 
        return segmentTable();
      case 'approveToShare':
        return (
          <ContactsATSList 
            contactsTrigger={contactsTrigger}
          />
        )
      default:
        return emailListTable();
    }
  }

  const emailListTable = () => {
    const headerData = {
      emailAddresses: [
        {
          type: 'checkbox',
          key: '',
          sortKey: '',
        },
        {
          header: 'Email',
          formatData: (row) => (
            <div style={{display: 'table'}}>
              <div style={{ display: 'table', margin: 0 }}>
                {row.email.length > 20 ? (
                  <Tooltip placement="bottom" title={row.email}>
                    <p style={{fontSize: 12, fontWeight: 400, color: '#101828'}}>
                      {`${row.email.substring(0, 20)}...`}
                    </p>
                  </Tooltip>
                ) : (
                  <p style={{fontSize: 12, fontWeight: 400, color: '#101828'}}>
                    {row.email}
                  </p>
                )}
                {row.subscription_status !== 'subscribed' && <p style={{color: '#999', fontSize: 10, margin: '-10px 0 2px'}}>Unsubscribed</p>}
              </div>
            </div>
          ),
          sortKey: 'email',
        },
        {
          header: 'First Name',
          formatData: (row) => (
            <div style={{display: 'table'}}>
              <div style={{ fontSize: '14px', fontWeight: 500, color: '#242B2F', margin: 0  }}>{row.name['first']}</div>
            </div>
          ),
        },
        {
          header: 'Last Name',
          formatData: (row) => (
            <div style={{display: 'table'}}>
              <div style={{ fontSize: '14px', fontWeight: 500, color: '#242B2F', margin: 0  }}>{row.name['last']}</div>
            </div>
          ),
        },
        {
          header: 'Contact Owner',
          formatData: (row) => (
            <div style={{display: 'table'}}>
              <div style={{ fontSize: '14px', fontWeight: 500, color: '#242B2F', margin: 0  }}>{row.owner_id}</div>
            </div>
          ),
        },
        {
          header: 'Tags',
          formatData: (row) => (
            <TagContainer>
              {row.explicit_tags.map((item, index)=>{
                if(item.length > 8){
                  return(
                    <Tooltip key={index} placement="top" title={item}>
                      <TagStyle 
                      >{item.substring(0, 8)}...</TagStyle>
                    </Tooltip>)
                 }
                return(<TagStyle key={index}>{item}</TagStyle>)
              })}
               {row.implicit_tags.map((item, index)=>{
                 if(item.length > 8){
                  return(
                    <Tooltip key={index} placement="top" title={item}>
                      <TagStyle 
                        style={{color: '#9B51E0', background: '#EBDCF9', border: '1px solid #DEC5F5'}}
                      >{item.substring(0, 8)}...</TagStyle>
                    </Tooltip>)
                 }
                return(
                  <TagStyle 
                    style={{color: '#9B51E0', background: '#EBDCF9', border: '1px solid #DEC5F5'}}
                    key={index}
                  >{item}</TagStyle>)
              })}
            </TagContainer>
          ),
          sortKey: 'tags',
        }
      ],
      emailLists: [
        {
          header: '',
          formatData: (row) => (
            <div style={{display: 'flex', justifyContent: 'space-between'}}>
              {row.name.length > 20 ? (
                <Tooltip placement="left" title={row.name}>
                  <div 
                    style={{ 
                      fontSize: '14px', 
                      fontWeight: 500, 
                      color: '#101828', 
                      margin: '2px 0 0',
                      width: '92%',
                      overflow: 'hidden',
                      display: 'flex',
                      justifyContent: 'space-between'
                    }}
                  >{row.name.length > 20 ? `${row.name.substring(0, 20)}...` : row.name}
                  {row.is_default && <div style={{fontSize: 8, margin: '6px 10px 0 0'}}>Default</div>}
                  </div>
                </Tooltip>
              ) : (
                <div 
                  style={{ 
                    fontSize: '14px', 
                    fontWeight: 500, 
                    color: '#101828', 
                    margin: '2px 0 0',
                    width: '92%',
                    overflow: 'hidden',
                    display: 'flex',
                    justifyContent: 'space-between'
                  }}
                >{row.name.length > 20 ? `${row.name.substring(0, 20)}...` : row.name}
                {row.is_default && <div style={{fontSize: 8, margin: '6px 10px 0 0'}}>Default</div>}
                </div>
              ) } 
              <CustomTooltip 
                open={show && selectedId === row?.id} 
                title={customRows(row, 'list')}
                onOpen={() => {
                  setShow(true);
                  setSelectedId(row?.id);
                }}
              >
                <EditIcon src={editIconDots} alt='edit' />
              </CustomTooltip>
            </div>
          ),
          key: 'name',
        }
      ]
    };
    if(showContactType) {
      const contactTypeCol = {
        header: 'Contact Type',
        formatData: (row) => (
          <div style={{ display: 'table' }}>
            <div style={{ fontSize: '14px', fontWeight: 500, color: '#242B2F', margin: 0 }}>{row.contact_status}</div>
          </div>
        ),
      }
      headerData.emailAddresses.splice(5, 0 , contactTypeCol);
    }
    

    return (
      <>
        {uploadType !== 'custom' && (
          <div>
            <EmailListTable
              gmContact={gmContact}
              setGmContact={setGmContact}
              headerData={headerData}
              data={contactsList}
              paginationData={paginationData}
              onContactDelete={onContactDelete}
              isContactsFetching={isContactsFetching}
              selectedEmailListItem={selectedEmailListItem}
              setSelectedEmailListItem={setSelectedEmailListItem}
              contactGroupsList={contactGroupsList}
              contactsRequest={contactsRequest}
              selectedContactGroup={selectedContactGroup}
              page={page}
              setPage={setPage}
              contactsCount={contactsCount}
              handleEmailSort={handleEmailSort}
              sortFlag={sortFlag}
              handleContactListChange={handleContactListChange}
              contactGroups={contactGroups}
              socialNetworkData={socialNetworkData}
              toggleFromEdit={toggleFromEdit}
              userData={userData}
              segmentTabActive={segmentTabActive}
              emailTableData={emailTableData}
              setEmailTableData={setEmailTableData}
              currentlyEditingContactList={currentlyEditingContactList}
              setCurrentlyEditingContactList={setCurrentlyEditingContactList}
              renameModal={renameModal}
              setRenameModal={setRenameModal}
              setModalDisplay={setModalDisplay}
              modalDisplay={modalDisplay}
              tableLoading={emailTableLoading} 
              setTableLoading={setEmailTableLoading}
              editListModal={editListModal}
              selectedEmailList={selectedEmailList}
              setSelectedEmailList={setSelectedEmailList}
              fetchContactsGroup={fetchContactsGroup}
              setListLoading={setEmailListLoading}
              listLoading={emailListLoading}
              fetchContacts={retrieveGroupsList}
              addContactAction={addContactAction}
              contactsCreate={contactsCreate}
              emailList={emailList}
              setEmailList={setEmailList}
              refreshData={refreshData}
              newContacts={newContacts}
              setNewContacts={setNewContacts}
              noRecords={noRecords}
            />
          </div>
        )}
      </>
    );
  };

  const segmentTable = () => {
    const headerData = {
      emailAddresses: [
        {
          header: 'Email',
          formatData: (row) => (
            <div style={{display: 'table'}}>
              <div style={{ display: 'table', margin: 0 }}>
                {row.email.length > 20 ? (
                  <Tooltip placement="bottom" title={row.email}>
                    <p style={{fontSize: 12, fontWeight: 400, color: '#101828'}}>
                      {`${row.email.substring(0, 20)}...`}
                    </p>
                  </Tooltip>
                ) : (
                  <p style={{fontSize: 12, fontWeight: 400, color: '#101828'}}>
                    {row.email}
                  </p>
                )}
                {row.subscription_status !== 'subscribed' && <p style={{color: '#999', fontSize: 10, margin: '-10px 0 2px'}}>Unsubscribed</p>}
              </div>
            </div>
          ),
          sortKey: 'email',
        },
        {
          header: 'First Name',
          formatData: (row) => (
            <div style={{display: 'table'}}>
              <h4 style={{ fontSize: '14px', fontWeight: 500, color: '#242B2F', margin: 0  }}>{row['first_name']}</h4>
            </div>
          ),
        },
        {
          header: 'Last Name',
          formatData: (row) => (
            <div style={{display: 'table'}}>
              <h4 style={{ fontSize: '14px', fontWeight: 500, color: '#242B2F', margin: 0  }}>{row['last_name']}</h4>
            </div>
          ),
        },
        {
          header: 'Contact Owner',
          formatData: (row) => (
            <div style={{display: 'table'}}>
              <div style={{ fontSize: '14px', fontWeight: 500, color: '#242B2F', margin: 0  }}>{row.owner_id}</div>
            </div>
          ),
        },
        // {
        //   header: 'Contact Type',
        //   formatData: (row) => (
        //     <div style={{display: 'table'}}>
        //       <div style={{ fontSize: '14px', fontWeight: 500, color: '#242B2F', margin: 0  }}>{row.contact_status}</div>
        //     </div>
        //   ),
        // },
        {
          header: 'Tags',
          formatData: (row) => (
            <TagContainer>
              {row.explicit_tags.map((item, index)=>{
                if(item.length > 8){
                  return(
                    <Tooltip key={index} placement="top" title={item}>
                      <TagStyle 
                      >{item.substring(0, 8)}...</TagStyle>
                    </Tooltip>)
                 }
                return(<TagStyle key={index}>{item}</TagStyle>)
              })}
               {row.implicit_tags.map((item, index)=>{
                 if(item.length > 8){
                  return(
                    <Tooltip key={index} placement="top" title={item}>
                      <TagStyle 
                        style={{color: '#9B51E0', background: '#EBDCF9', border: '1px solid #DEC5F5'}}
                      >{item.substring(0, 8)}...</TagStyle>
                    </Tooltip>)
                 }
                return(
                  <TagStyle 
                    style={{color: '#9B51E0', background: '#EBDCF9', border: '1px solid #DEC5F5'}}
                    key={index}
                  >{item}</TagStyle>)
              })}
            </TagContainer>
          ),
          sortKey: 'tags',
        }
      ],
      emailLists: [
        {
          header: '',
          formatData: (row) => (
            <div style={{display: 'flex', justifyContent: 'space-between'}}>
              {row.name.length > 16 ? (
                <Tooltip placement="left" title={row.name}>
                  <h4 
                    style={{ 
                      fontSize: '14px', 
                      fontWeight: 500, 
                      color: '#101828', 
                      margin: '2px 0 0',
                      width: '92%',
                      overflow: 'hidden'
                    }}
                  >{row.name.length > 16 ? `${row.name.substring(0, 16)}...` : row.name}
                  </h4>
                </Tooltip>
              ) : (
                <h4 
                  style={{ 
                    fontSize: '14px', 
                    fontWeight: 500, 
                    color: '#101828', 
                    margin: '2px 0 0',
                    width: '92%',
                    overflow: 'hidden'
                  }}
                >{row.name.length > 16 ? `${row.name.substring(0, 16)}...` : row.name}
                </h4>
              ) } 
                <CustomTooltip 
                  open={show && selectedId === row?.id} 
                  title={customRows(row, 'segment')}
                  onOpen={() => {
                    setShow(true);
                    setSelectedId(row?.id);
                  }}
                >
                  <EditIcon src={editIconDots} alt='edit' />
                </CustomTooltip>
            </div>
          ),
          key: 'name',
        }
      ]
    };
    if(showContactType) {
      const contactTypeCol = {
        header: 'Contact Type',
        formatData: (row) => (
          <div style={{ display: 'table' }}>
            <div style={{ fontSize: '14px', fontWeight: 500, color: '#242B2F', margin: 0 }}>{row.contact_status}</div>
          </div>
        ),
      }
      headerData.emailAddresses.splice(4, 0 , contactTypeCol);
    }
    return (
      <>
        <div>
          <SegmentTable
            segmentList={segmentList}
            headerData={headerData}  
            renameModal={renameModal}
            setRenameModal={setRenameModal}
            modalDisplay={modalDisplay}
            selectedSegmentObj={selectedSegmentObj} 
            setSelectedSegmentObj={setSelectedSegmentObj}
            isNewSegment={isNewSegment}
            segmentTabActive={segmentTabActive}
            fetchSegments={fetchSegments}
            setTableLoading={setSegmentTableLoading}
            tableLoading={segmentTableLoading}
            listLoading={segmentListLoading} 
            setListLoading={setSegmentListLoading}
            setSegmentList={setSegmentList}
            emailList={emailList}
          />
        </div>
      </>
    );
  };

  // const refreshData = async() => {
  //   try{    
  //     setEmailTableLoading(true)
  //     setEmailListLoading(true)
  //     const response = await getContactList()
  //     setEmailList(response?.result?.data.filter((item) => item))
  //     console.log("data bin--->",setEmailList(response?.result?.data.filter((item) => item)))
  //     setEmailTableLoading(false)
  //   }catch(err){
  //     console.log('Refresh email error:',err)
  //   }
  // }

  const refreshData = async () => {
    try {
      setEmailTableLoading(true);
      setEmailListLoading(true);
      const response = await getContactList();
      if (response.result.success) {
        window.location.reload();
        setTimeout(() => {
          setEmailTableLoading(false);
          setEmailListLoading(false);
        }, 500);
      }
    } catch (err) {
      console.log('Refresh email error:', err);
    }
  };

  const modalFinder = () => {
    if(segmentTabActive){
      setIsNewSegment(true)
      setShowSegmentModal(true) 
      setAddSegmentTitle('Create Segment')
    } else {
      setAddModalDisplay('select')
      setShowListModal(true)
      setAddListTitle('Add New List')
    }
  }

  const onBottomTextClick = (
    e,
    id,
    platform,
    emailPlaftform,
    isDisconnected
  ) => {
    e.stopPropagation();
    if (isDisconnected) {
      get(socialNetworkData, platform, []).forEach((item) => {
        if (
          (emailPlaftform && emailPlaftform === item.snetworkProvider) ||
          !emailPlaftform
        ) {
          socialNetworkDelete({
            params: {
              id: `${platform}_${item.accountId}`,
            },
          });
        }
      });
    } else {
      handleClientsSocialIconClick(id, isDisconnected, platform, emailPlaftform);
    }
  };

  return (
    <div>
      <div>
        <div style={{width: '100%'}}>
          <IconCards 
            iconInput={formatSocialIcons()} 
            isLoading={socialNetworkDataFetching} 
            syncModalActions={(e, item)=>reloadGroupList(e, item)}
            iconClick={(icon)=> {
              handleClientsSocialIconClick(
                icon.id,
                icon.isDisconnected,
                icon.platform,
                icon.emailPlatform
              )
            }}
            buttonClick={(e, icon) => {
              onBottomTextClick(
                e,
                icon.id,
                icon.platform,
                icon.emailPlaftform,
                icon.isDisconnected
              ) 
            }}
          />
        </div>

        {/* Segment and Email List Toggle*/}
        <div style={{width: '100%', display: 'flex', justifyContent: 'space-between'}}>
          <ToggleBar 
            toggleItems={toggleItems} 
            activeToggle={tabIndex} 
            toggleClick={(toggle)=>tabChange(toggle)} 
          />
          {tabIndex !== 'approveToShare' && (
            <AddButton
              children={`+ Add New ${segmentTabActive ? 'Segment' : 'List'}`}
              onClick={() => modalFinder()}
              style={{ width: segmentTabActive && '200px', height: 40 }}
            />
          )}
         
        </div>

        {displayTable()}

        {/* End Segment and Email List */}
      </div>
      
      
     
      {isContactCreate && (
        <ErrorContainer align="center">
          Email list created successfully!
        </ErrorContainer>
      )}

      {contactErrorMessage && (
        <ErrorContainer
          style={{ position: 'relative', left: '70px', top: '5px' }}
          align="center"
        >
          {contactErrorMessage}
        </ErrorContainer>
      )}

      {errorFromCustomeEmail && (
        <ErrorModal onClose={() => setErrorFromCustomeEmail('')}>
          {errorFromCustomeEmail}
        </ErrorModal>
      )}

      <RedTailModal
        open={redTailModal}
        handleClose={() => setRedTailModal(false)}
        handleModalSubmit={handleRedTailLogin}
        modalError={redTailError}
      />

      {multipleConnectionMessage && (
        <Modal
          isOpen={isOpen}
          onRequestClose={onModalClose}
          shouldCloseOnOverlayClick
        >
          <ModalHeader fontSize="15px">
            <ModalCloseButton onClick={onModalClose} />
            {multipleConnectionMessage}
          </ModalHeader>
        </Modal>
      )}

      <ModalContainer
        modalStatus={contactOpen}
        closeModal={()=> {
          clearState();
        }}
        children={
          <ContactListModal
            contactOpen={contactOpen}
            setContactOpen={setContactOpen}
            selectedNetwork={selectedNetwork}
            setSelectedNetwork={setSelectedNetwork}
            setUploadType={setUploadType}
            selectedContactGroup={selectedContactGroup}
            contactsRequest={contactsRequest}
            closeModal={()=>setContactOpen(!contactOpen)}
            setIntegrationsHeader={setIntegrationsHeader}
          />
        }
        title={integrationsHeader} />


      <ModalContainer
        modalStatus={showListModal}
        closeModal={()=>{
          setShowListModal(!showListModal)
          setEditList(false)
        }}
        children={
          <AddNewList 
            closeModal={()=>{
              setShowListModal(!showListModal)
              setEditList(false)
            }} 
            setTitle={setAddListTitle} 
            setTableLoading={setEmailTableLoading}
            editContact={editContact}
            editList={editList}
            contactsRequest={contactsRequest}
            selectedEmailList={selectedEmailList?.id}
            fetchContacts={retrieveGroupsList}
            setListLoading={setEmailListLoading}
            modalDisplay={addModalDisplay}
            setModalDisplay={setAddModalDisplay}
            contactsCreate={contactsCreate}
            setSelectedEmailListItem={setSelectedEmailListItem}
            refreshData={refreshData}
            newContacts={newContacts}
            setNewContacts={setNewContacts}
            setContactsTrigger={setContactsTrigger}
          />
        }
        title={addListTitle}
      />

      <ModalContainer
        modalStatus={showSegmentModal}
        closeModal={()=>setShowSegmentModal(!showSegmentModal)}
        children={
        <CreateSegment 
          closeModal={()=>{
            setIsNewSegment(false)
            setShowSegmentModal(!showSegmentModal)
          }} 
          selectedSegmentObj={selectedSegmentObj} 
          isNewSegment={isNewSegment} 
          fetchSegments={fetchSegments}
          setTableLoading={setSegmentTableLoading}
          setListLoading={setSegmentListLoading}
        />}
        title={addSegmentTitle}
      />
    </div>
  );
};

const mapStateToProps = state => {
  return {
    showContactType: get(state, 'user.data.show_contact_type', false)
  }
}

export default connect(mapStateToProps)(EmailList);
