import { styled } from '@mui/material';

export const CampaignInfoCardsWrapper = styled('div')(() => ({
  border: '1px solid #E4E7EC',
  borderRadius: '0.5rem',
  background: '#fff',
  padding: '1.5rem',
  display: 'flex',
  flexDirection: 'column',
  gap: '0.75rem',
  flex: 0.6,
  alignItems: 'flex-start',
}));

export const CardIconContainer = styled('div')(() => ({
  '& svg': {
    height: '2rem',
    width: '2rem',
    objectFit: 'cover',
    color: '#4634D0',
  },
}));

export const CardInfoContent = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '1rem',

  '& .label': {
    color: '#475467',
    font: '400 0.875rem/1rem Poppins,sans-serif',
    margin: 0,
    textAlign: 'left',
  },
  '& .value': {
    color: '#101828',
    font: '500 1.25rem/1rem Poppins,sans-serif',
    margin: 0,
    textAlign: 'left',
  },
}));
