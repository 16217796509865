import React, { useState } from 'react';
import { LoaderWrapper, DownloadSampleDiv } from '../../components';
import { Flex, Box } from '@rebass/grid';
import FileUpload from '../../components/FileUpload/index';
import { UploadBox } from '../InviteModal/InviteModal.styles';
import { themesData } from '../../utils';
import { ResponsiveImg } from '..';
import { ErrorContainer } from '../';
import './InviteModals.css';
import { Button } from '../Form';
//import DragAndDrop from './DragAndDrop';

const InviteUploadCSVFile = ({ setInviteData, setSelectedIndex, isConnected, setUploadedFile, fromPositivlyInvite }) => {
    const [file, setFile] = useState('');
    const [clickedLoader, toggleClickedLoader] = useState(false);
    const [hasFileUploadError, setHasFileUploadError] = useState(false);
    const [userContactList, setUserContactList] = useState([
        { email: '', first_name: '', last_name: '', valid: false },
        { email: '', first_name: '', last_name: '', valid: false },
        { email: '', first_name: '', last_name: '', valid: false }
    ]);

    const sendInviteHandler = () => {
        toggleClickedLoader(true);
        // getEmailListCsv(file);
        setUploadedFile(file);
        setSelectedIndex(2);
    }

    // const getEmailListCsv = (values) => {
    //     const reader = new FileReader();
    //     reader.onload = function (e) {
    //         console.log(e.target.result);
    //         let rows = e.target.result.split('\n');
    //         let fileHeader = rows[0];
    //         let inviteData = [];
    //         for (let i = 1; i < rows.slice(1).length; i++) {
    //             let cells = rows[i].split(',');
    //             inviteData.push({ email: cells[2], firstName: cells[0], lastName: cells[1] })
    //         }
    //         console.log(rows, inviteData);
    //         setInviteData(inviteData);
    //     }
    //     reader.readAsText(values[0]);
    // };

    // const handleFileUpload = (file) => {

    // }

    return (
        <Flex>
            <div className='invite-div'>
                <div className="upload-csv">Upload a CSV</div>
                <div className="upload-content">
                    <div className="upload-text"><span className="">&nbsp;1.&nbsp;</span>Download the pre-formatted CSV template.
                    <DownloadSampleDiv className="download-template"
                            download
                            href={fromPositivlyInvite ? "/assets/sample/email_sample.csv" : "/assets/sample/email_sample-2.csv"}
                            padding="5px 10px"
                        >
                            Download Template <i className="icon dripicons-download"></i>
                        </DownloadSampleDiv>
                    </div>
                    <div className="upload-text"><span className="">2.&nbsp;</span>Open the file and add in email addresses to which you want to send invitations.</div>
                    <div className="upload-text"><span className="">3.&nbsp;</span>Upload the file for precessing.</div>
                </div>
                {/*<DragAndDrop />*/}
                <Flex width="30%" ml="112px" style={{}}>
                    <FileUpload
                        name="inviteUpload"
                        id="inviteUpload"
                        padding='4px 5px'
                        background='#ffffff'
                        fontSize='0.68rem'
                        onClick={() => { }}
                        width="140px"
                        styles={{
                            padding: '8px 20px 8px 9px', backgroundColor: '#ffffff', color: '#6351ed',
                            borderRadius: '0', marginTop: '-16px', border: '1px solid lightgray',
                        }}
                        label="Upload File Here"
                        onChange={file => setFile(file)}
                        accept=".csv" />
                        <div><ResponsiveImg src="/assets/images/upload.svg" style={{ height: 'auto', width: 'auto', marginTop: '-7px', position: 'absolute', marginLeft: '-19px' }} /></div>
                </Flex>
                <Flex justifyContent='center' width="100%" style={{ position: 'relative', left: '-21%' , top: '-6%'}} color={themesData.purpleishBlue}>
                    {hasFileUploadError ? 'Please upload a valid csv file' : file && file[0] && file[0].name}
                </Flex>
                <LoaderWrapper isLoading={clickedLoader}>
                    <Button margin="0px 0px 10px" padding="7px 30px" disabled={!isConnected || file === ''} onClick={sendInviteHandler}>Next</Button>
                </LoaderWrapper>
                {!isConnected && (
                    <ErrorContainer style={{fontSize: '11px', marginBottom: '10px'}} align='center'>*Please Connect to Email Platform</ErrorContainer>
                )}
            </div>
        </Flex>
    );
}

export default InviteUploadCSVFile