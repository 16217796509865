import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import { DashboardArticle, FreeArticle } from '../../../components';

import { getExploreArticleData, dashboardSliderSettings, eventHandlers } from '../../../utils';

import { ArticleSliderWrapper } from '../DashboardContainer.styles';
import { isEmpty, get } from 'lodash';

const RecommendedTab = ({
  selectedTopic,
  exploreArticles,
  deleteArticleRequest,
  themeNames,
  exploreArticleDeleting,
  isFreeUser,
  isShareBlocked,
  isDeleteBlocked,
  showMio,
  showTabLoader,
  setShowArticleLoader,
  popularThemes,
  userData,
  exploreArticleRequest,
  popularTopicDetails,
  refresh,
  onNewsletterArticleClick,
  onRemoveFromNewletterClick,
  newsletterArticleFetching,
  bookmarksFetching,
  exploreNewsletterId,
  toggleLoaderBlocked,
  bookmarksId,
  isNewsletterBlocked,
  hideDelete
}) => {

  const [imageCounter, setImageCounter] = useState([]);
  const [localExploreArticles, setLocalExploreArticles] = useState([]);
  const [totalArticles, setTotalArticles] = useState(0);

  dashboardSliderSettings.afterChange = (index) => {
    let articles = getExploreArticleData(exploreArticles).slice(index, index + 4);
    let sponsoredArticle = articles.filter(a => a.sponseredContent);
    const userEmail = userData && userData.details && userData.details.user && userData.details.user.email;
    if (!isEmpty(sponsoredArticle)) {
      sponsoredArticle.forEach((article) => {
        eventHandlers(
          'SPONSORED_ARTICLE_DISPLAYED',
          popularThemes,
          article.id,
          'RECOMMENDED',
          article.channelId,
          article.title,
          article.themeId,
          themeNames[article.themeId],
          article.topic,
          userEmail
        );
      });
    }
    if (!isEmpty(articles)) {
      articles.forEach((article) => {
        eventHandlers(
          'ARTICLE_DISPLAYED',
          popularThemes,
          article.id,
          'RECOMMENDED',
          article.channelId,
          article.title,
          article.themeId,
          themeNames[article.themeId],
          article.topic,
          userEmail
        );
      });
    }
  }
 
   useEffect(() => {
    console.log(exploreArticles);
    if(isEmpty(get(exploreArticles, 'feed', []))) {
      console.log('check');
      setShowArticleLoader(false);
    }
     let articles = getExploreArticleData(exploreArticles);
     setLocalExploreArticles(articles);
     setTotalArticles(articles.length);
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [exploreArticles]);

   useEffect(() => {
     if(!isEmpty(get(exploreArticles, 'feed', []))) {
       let articles = getExploreArticleData(exploreArticles).slice(0, 4);
       let sponsoredArticle = articles.filter(a => a.sponseredContent);
       const userEmail = userData && userData.details && userData.details.user && userData.details.user.email;
       if (!isEmpty(sponsoredArticle)) {
         sponsoredArticle.forEach((article) => {
           eventHandlers(
             'SPONSORED_ARTICLE_DISPLAYED',
             popularThemes,
             article.id,
             'RECOMMENDED',
             article.channelId,
             article.title,
             article.themeId,
             themeNames[article.themeId],
             article.topic,
             userEmail
           );
         });
       }
       if(!isEmpty(articles)) {
         articles.forEach((article) => {
          eventHandlers(
            'ARTICLE_DISPLAYED',
            popularThemes,
            article.id,
            'RECOMMENDED',
            article.channelId,
            article.title,
            article.themeId,
            themeNames[article.themeId],
            article.topic,
            userEmail
          );
         });
       }
     }
     // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

  return (
    <React.Fragment>
        <ArticleSliderWrapper>
          <Slider {...dashboardSliderSettings}>
            {isFreeUser && <FreeArticle />}
            {localExploreArticles.map((article,index) => (
              <DashboardArticle
                key={'article_' + index}
                article={article}
                refresh={refresh}
                exploreArticleRequest={exploreArticleRequest}
                popularTopicDetails={popularTopicDetails}
                bookmarksFetching={bookmarksFetching}
                bookmarksId={bookmarksId}
                {...{
                  ...article,
                  selectedTopic,
                  isFreeUser,
                  deleteArticleRequest,
                  themeNames,
                  exploreArticleDeleting,
                  isShareBlocked,
                  isDeleteBlocked,
                  showMio,
                  index,
                  setShowArticleLoader,
                  totalArticles,
                  setImageCounter,
                  imageCounter,
                  onNewsletterArticleClick,
                  onRemoveFromNewletterClick,
                  section: 'RECOMMENDED',
                  newsletterArticleFetching,
                  exploreNewsletterId,
                  isNewsletterBlocked,
                  hideDelete
                }
              }
              />
            ))}
          </Slider>
        </ArticleSliderWrapper>
      </React.Fragment>
  );
};

export default RecommendedTab;

