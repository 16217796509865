import React from 'react'
import withDripCampaignLayout from '../../hoc/withDripCampaignLayout';
import { CreateSocialContainer } from '../../containers/CreateSocialContainer';



const CreateSocialLayout = () => {
  return (
    <CreateSocialContainer />
  )
}

export default  withDripCampaignLayout(CreateSocialLayout);