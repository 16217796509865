import React from 'react';
import withAppLayout from '../../hoc/withAppLayout';

import {
  AdvisorConfigureContainer,
  AdminConfigureContainer,
} from '../../containers';
import { ROLE_TYPES } from '../../utils';
import { useHistory } from 'react-router-dom';

const AdvisorConfigureLayout = (props) => {
  const { roleType } = props;
  const history = useHistory();

  if(roleType === ROLE_TYPES.ADVISOR_ENTERPRISE || roleType === ROLE_TYPES.ADVISOR_PREMIUM) {
    return (
      <AdvisorConfigureContainer {...props}/>
    )
  } else {
    history.push({
      pathName: '/admin/configure',
      search: window.location.search
     });
    return (
      <AdminConfigureContainer {...props} />
    )
  }


};

const AdminConfigureLayout = (props) => {
  const { roleType } = props;
  const history = useHistory();
  if(roleType === ROLE_TYPES.ADVISOR_ENTERPRISE || roleType === ROLE_TYPES.ADVISOR_PREMIUM) {
    history.push({
      pathName: '/configure',
      search: window.location.search
     });
    return (
      <AdvisorConfigureContainer {...props}/>
    )
  } else {
    return (
      <AdminConfigureContainer {...props}/>
    )
  }
 };

export const AdvisorConfigure = withAppLayout(AdvisorConfigureLayout);

export const AdminConfigure = withAppLayout(AdminConfigureLayout);
