import useRESTQuery from './useRESTQuery';

function useQueryForKeyInsights(email) {
  return useRESTQuery({
    key: 'key-insights',
    dependencies: [email],
    endpoint: `/recommendation/insights?email=${email}`,
    options: {
      select: (res) => {
        const result = res?.result;

        const most_popular_formats = [...(result?.article_format ?? [])]?.splice(0, 1);
        const most_popular_time = result?.created_hr?.[0] ?? null;
        const most_popular_day = result?.created_day?.[0] ?? null;
        const topics = result?.theme_name ?? [];
        const subject_length = result?.subject_no_of_words ?? null;
        const suggested_subject = result?.subject_sentiment ?? null;

        return {
          most_popular_formats,
          most_popular_time,
          most_popular_day,
          topics,
          subject_length,
          suggested_subject,
          result
        };
      },
      enabled: !!email,
      refetchOnMount: true,
    },
  });
}

export default useQueryForKeyInsights;
