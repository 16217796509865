import React from 'react'
import CustomRowsEditorContainer from '../../containers/CustomRowsEditorContainer/CustomRowsEditorContainer';
import withDripCampaignLayout from '../../hoc/withDripCampaignLayout';

const CustomRowsEditorLayout = (props) => {
    return (
        <CustomRowsEditorContainer {...props} />
    )
}

export default withDripCampaignLayout(CustomRowsEditorLayout);
