import { isEmpty } from 'lodash';
import apiInstance from '../api';
import { cloutV2Endpoints } from '../helpers';

export const getEmailList = (page, limit, fromDate, isEnterprise, selectedFirms) => {
    if(!isEmpty(selectedFirms)) {
        return apiInstance().get(`${cloutV2Endpoints.baseUrl}/analytics/contacts/emaillists?page_no=${page}&limit=${limit}&from_date=${fromDate}&is_enterprise=${isEnterprise}&user_ids=${JSON.stringify(selectedFirms)}`);
    } else {
        return apiInstance().get(`${cloutV2Endpoints.baseUrl}/analytics/contacts/emaillists?page_no=${page}&limit=${limit}&from_date=${fromDate}&is_enterprise=${isEnterprise}`);
    }
}

export const getCampaignAnalyticsList = (page, limit, type, fromDate, isEnterprise, selectedFirms) => {
    if(!isEmpty(selectedFirms)) {
        return apiInstance().get(`${cloutV2Endpoints.baseUrl}/analytics/campaigns?page_no=${page}&limit=${limit}&ongoing=${type}&from_date=${fromDate}&is_enterprise=${isEnterprise}&user_ids=${JSON.stringify(selectedFirms)}`);
    } else {
        return apiInstance().get(`${cloutV2Endpoints.baseUrl}/analytics/campaigns?page_no=${page}&limit=${limit}&ongoing=${type}&from_date=${fromDate}&is_enterprise=${isEnterprise}`);
    }
    
}

export const getSegmentAnalyticsList = (page, limit, fromDate, isEnterprise, selectedFirms) => {
    if(!isEmpty(selectedFirms)) {
        return apiInstance().get(`${cloutV2Endpoints.baseUrl}/analytics/contacts/segmentlists?page_no=${page}&limit=${limit}&from_date=${fromDate}&is_enterprise=${isEnterprise}&user_ids=${JSON.stringify(selectedFirms)}`);
    } else {
        return apiInstance().get(`${cloutV2Endpoints.baseUrl}/analytics/contacts/segmentlists?page_no=${page}&limit=${limit}&from_date=${fromDate}&is_enterprise=${isEnterprise}`);
    }   
}

export const getContactsList = (fromDate, limit, page=1, isEnterprise, selectedFirms) => {
    if(!isEmpty(selectedFirms)) {
        return apiInstance().get(`${cloutV2Endpoints.baseUrl}/analytics/contacts?page_no=${page}&limit=${limit}&from_date=${fromDate}&is_enterprise=${isEnterprise}&user_ids=${JSON.stringify(selectedFirms)}`);
    } else {
        return apiInstance().get(`${cloutV2Endpoints.baseUrl}/analytics/contacts?page_no=${page}&limit=${limit}&from_date=${fromDate}&is_enterprise=${isEnterprise}`);
    }  
}

export const getCampaignPerformance = (fromDate, isEnterprise, selectedFirms) => {
    if(!isEmpty(selectedFirms)) {
        return apiInstance().get(`${cloutV2Endpoints.baseUrl}/analytics/campaigns/performance?from_date=${fromDate}&is_enterprise=${isEnterprise}&user_ids=${JSON.stringify(selectedFirms)}`);
    } else {
        return apiInstance().get(`${cloutV2Endpoints.baseUrl}/analytics/campaigns/performance?from_date=${fromDate}&is_enterprise=${isEnterprise}`);
    }
}

export const getCampaignPerformanceScore = (fromDate, isEnterprise, selectedFirms) => {
    if(!isEmpty(selectedFirms)) {
        return apiInstance().get(`${cloutV2Endpoints.baseUrl}/analytics/campaigns/performance/scores?from_date=${fromDate}&is_enterprise=${isEnterprise}&user_ids=${JSON.stringify(selectedFirms)}`);
    } else {
        return apiInstance().get(`${cloutV2Endpoints.baseUrl}/analytics/campaigns/performance/scores?from_date=${fromDate}&is_enterprise=${isEnterprise}`);
    } 
}

export const getTopCampaigns = (fromDate, limit, isEnterprise, selectedFirms) => {
    if(!isEmpty(selectedFirms)) {
        return apiInstance().get(`${cloutV2Endpoints.baseUrl}/analytics/campaigns/top?from_date=${fromDate}&limit=${limit}&ongoing=false&is_enterprise=${isEnterprise}&user_ids=${JSON.stringify(selectedFirms)}`);
    } else {
        return apiInstance().get(`${cloutV2Endpoints.baseUrl}/analytics/campaigns/top?from_date=${fromDate}&limit=${limit}&ongoing=false&is_enterprise=${isEnterprise}`);
    }
}

export const getRecommendationData = (type, fromDate, isEnterprise, selectedFirms) => {
    if(!isEmpty(selectedFirms)) {
        return apiInstance().get(`${cloutV2Endpoints.baseUrl}/analytics/campaigns/recommendations?platform=${type}&from_date=${fromDate}&is_enterprise=${isEnterprise}&user_ids=${JSON.stringify(selectedFirms)}`)
    } else {
        return apiInstance().get(`${cloutV2Endpoints.baseUrl}/analytics/campaigns/recommendations?platform=${type}&from_date=${fromDate}&is_enterprise=${isEnterprise}`)
    } 
}

export const getTopEmailLists = (fromDate, limit, isEnterprise, selectedFirms) => {
    if(!isEmpty(selectedFirms)) {
        return apiInstance().get(`${cloutV2Endpoints.baseUrl}/analytics/contacts/emaillists/top?from_date=${fromDate}&limit=${limit}&is_enterprise=${isEnterprise}&user_ids=${JSON.stringify(selectedFirms)}`)
    } else {
        return apiInstance().get(`${cloutV2Endpoints.baseUrl}/analytics/contacts/emaillists/top?from_date=${fromDate}&limit=${limit}&is_enterprise=${isEnterprise}`)
    }
}

export const getTopSegmentLists = (fromDate, limit, isEnterprise, selectedFirms) => {
    if(!isEmpty(selectedFirms)) {
        return apiInstance().get(`${cloutV2Endpoints.baseUrl}/analytics/contacts/segmentlists/top?from_date=${fromDate}&limit=${limit}&is_enterprise=${isEnterprise}&user_ids=${JSON.stringify(selectedFirms)}`)
    } else {
        return apiInstance().get(`${cloutV2Endpoints.baseUrl}/analytics/contacts/segmentlists/top?from_date=${fromDate}&limit=${limit}&is_enterprise=${isEnterprise}`)
    }
    
}

export const getTopLeadsAnalytics = (fromDate, limit) => {
    return apiInstance().get(`${cloutV2Endpoints.baseUrl}/analytics/contacts/leads/top?from_date=${fromDate}&limit=${limit}`)
}

export const getContactsEngagement = (fromDate, isEnterprise, selectedFirms) => {
    if(!isEmpty(selectedFirms)) {
        return apiInstance().get(`${cloutV2Endpoints.baseUrl}/analytics/contacts/engagement?from_date=${fromDate}&is_enterprise=${isEnterprise}&user_ids=${JSON.stringify(selectedFirms)}`);
    } else {
        return apiInstance().get(`${cloutV2Endpoints.baseUrl}/analytics/contacts/engagement?from_date=${fromDate}&is_enterprise=${isEnterprise}`);
    }
}

export const getRecommendedContacts = (fromDate) => {
    return apiInstance().get(`${cloutV2Endpoints.baseUrl}/analytics/contacts/recommendation?from_date=${fromDate}`);
}

export const getHighlyEngagedContacts = (fromDate, isEnterprise, selectedFirms) => {
    if(!isEmpty(selectedFirms)) {
        return apiInstance().get(`${cloutV2Endpoints.baseUrl}/analytics/contacts/high_engaged/advisors?from_date=${fromDate}&is_enterprise=${isEnterprise}&user_ids=${JSON.stringify(selectedFirms)}`);
    } else {
        return apiInstance().get(`${cloutV2Endpoints.baseUrl}/analytics/contacts/high_engaged/advisors?from_date=${fromDate}&is_enterprise=${isEnterprise}`);
    } 
}

export const getContentSourceEngagement = (fromDate, isEnterprise, selectedFirms) => {
    if(!isEmpty(selectedFirms)) {
        return apiInstance().get(`${cloutV2Endpoints.baseUrl}/analytics/content/source_engagement?from_date=${fromDate}&is_enterprise=${isEnterprise}&user_ids=${JSON.stringify(selectedFirms)}`);
    } else {
        return apiInstance().get(`${cloutV2Endpoints.baseUrl}/analytics/content/source_engagement?from_date=${fromDate}&is_enterprise=${isEnterprise}`);
    }
}
export const getTopPerformingContent = (fromDate, limit, isEnterprise, selectedFirms) => {
    if(!isEmpty(selectedFirms)) {
        return apiInstance().get(`${cloutV2Endpoints.baseUrl}/analytics/content/performance/top?from_date=${fromDate}&limit=${limit}&is_enterprise=${isEnterprise}&user_ids=${JSON.stringify(selectedFirms)}`)
    } else {
        return apiInstance().get(`${cloutV2Endpoints.baseUrl}/analytics/content/performance/top?from_date=${fromDate}&limit=${limit}&is_enterprise=${isEnterprise}`)
    }
}

export const getContentGraphData = (fromDate, limit, isEnterprise, selectedFirms) => {
    if(!isEmpty(selectedFirms)) {
        return apiInstance().get(`${cloutV2Endpoints.baseUrl}/analytics/content/topics?ongoing=false&from_date=${fromDate}&limit=${limit}&is_enterprise=${isEnterprise}&user_ids=${JSON.stringify(selectedFirms)}`);
    } else {
        return apiInstance().get(`${cloutV2Endpoints.baseUrl}/analytics/content/topics?ongoing=false&from_date=${fromDate}&limit=${limit}&is_enterprise=${isEnterprise}`);
    }
}

export const getAnalyticsTopicsList = (fromDate , limit, page, isEnterprise, selectedFirms) => {
    if(!isEmpty(selectedFirms)) {
        return apiInstance().get(`${cloutV2Endpoints.baseUrl}/analytics/content/topics/list?ongoing=false&from_date=${fromDate}&limit=${limit}&page_no=${page}&is_enterprise=${isEnterprise}&user_ids=${JSON.stringify(selectedFirms)}`);
    } else {
        return apiInstance().get(`${cloutV2Endpoints.baseUrl}/analytics/content/topics/list?ongoing=false&from_date=${fromDate}&limit=${limit}&page_no=${page}&is_enterprise=${isEnterprise}`);
    } 
}

export const getAnalyticsContentList = (fromDate, limit, page, isEnterprise, selectedFirms) => {
    if(!isEmpty(selectedFirms)) {
        return apiInstance().get(`${cloutV2Endpoints.baseUrl}/analytics/content?from_date=${fromDate}&limit=${limit}&page_no=${page}&is_enterprise=${isEnterprise}&user_ids=${JSON.stringify(selectedFirms)}`)
    } else {
        return apiInstance().get(`${cloutV2Endpoints.baseUrl}/analytics/content?from_date=${fromDate}&limit=${limit}&page_no=${page}&is_enterprise=${isEnterprise}`)
    }
}

export const getAnalyticsLinkList = (fromDate, limit, page, isEnterprise, selectedFirms) => {
    if(!isEmpty(selectedFirms)) {
        return apiInstance().get(`${cloutV2Endpoints.baseUrl}/analytics/content/hyperlinks/list?from_date=${fromDate}&limit=${limit}&page_no=${page}&is_enterprise=${isEnterprise}&user_ids=${JSON.stringify(selectedFirms)}`);
    } else {
        return apiInstance().get(`${cloutV2Endpoints.baseUrl}/analytics/content/hyperlinks/list?from_date=${fromDate}&limit=${limit}&page_no=${page}&is_enterprise=${isEnterprise}`);
    }
}

export const getContactsRecommendationData = (fromDate) => {
    return apiInstance().get(`${cloutV2Endpoints.baseUrl}/analytics/contacts/recommended_name?from_date=${fromDate}`);
}

export const getContentEntAnalyticsCampaignActivity = (fromDate, limit, selectedFirms) => {
    if(!isEmpty(selectedFirms)) {
        return apiInstance().get(`${cloutV2Endpoints.baseUrl}/analytics/campaigns/topics?from_date=${fromDate}&limit=${limit}&is_enterprise=false&user_ids=${JSON.stringify(selectedFirms)}`)
    } else {
        return apiInstance().get(`${cloutV2Endpoints.baseUrl}/analytics/campaigns/topics?from_date=${fromDate}&limit=${limit}&is_enterprise=true`)
    }
}

export const getRelatedArticles = (fromDate, data) => {
    const topicArrlength = data?.length;
    let filterSTring;
    if(topicArrlength === 1) {
        filterSTring = `&theme_ids=${data[0]}`;
    } else if(topicArrlength === 2) {
        filterSTring = `&theme_ids=${data[0]}&theme_ids=${data[1]}`;
    } else {
        filterSTring = `&theme_ids=${data[0]}&theme_ids=${data[1]}&theme_ids=${data[2]}`;
    }
    return apiInstance().get(`${cloutV2Endpoints.baseUrl}/analytics/content/related_articles?from_date=${fromDate}${filterSTring}`);
}

export const getAnalyticsUsers = () => {
    return apiInstance().get(`${cloutV2Endpoints.baseUrl}/analytics/company/users`);
}

export const getContactTags = (fromDate, email) => {
    return apiInstance().get(`${cloutV2Endpoints.baseUrl}/analytics/detailed/contact/tags?from_date=${fromDate}&email=${email}`)
}

export const getMostEngagedTopics = (fromDate, email) => {
    return apiInstance().get(`${cloutV2Endpoints.baseUrl}/analytics/detailed/contact/topics?from_date=${fromDate}&email=${email}&limit=5`);
}

export const getMostEngagedCampaign = (fromDate, email) => {
    return apiInstance().get(`${cloutV2Endpoints.baseUrl}/analytics/detailed/contact/campaign?from_date=${fromDate}&email=${email}`)
}

export const getContactSummary = (fromDate, email) => {
    return apiInstance().get(`${cloutV2Endpoints.baseUrl}/analytics/detailed/contact/summary?from_date=${fromDate}&email=${email}&limit=10`)
}

export const getContactEngagedContent = (fromDate, email) => {
    return apiInstance().get(`${cloutV2Endpoints.baseUrl}/analytics/detailed/contact/content?from_date=${fromDate}&email=${email}&limit=3`);
}

export const getListTags = (fromDate, list_id, type) => {
    return apiInstance().get(`${cloutV2Endpoints.baseUrl}/analytics/lists/tags?from_date=${fromDate}&list_id=${list_id}&type=${type}`)
}

export const getListCampaign = (fromDate, list_id, type) => {
    return apiInstance().get(`${cloutV2Endpoints.baseUrl}/analytics/lists/campaign?from_date=${fromDate}&list_id=${list_id}&type=${type}`)
}

export const getListMostEngagedContact = (fromDate, list_id, type, page_no) => {
    return apiInstance().get(`${cloutV2Endpoints.baseUrl}/analytics/lists/contacts?from_date=${fromDate}&list_id=${list_id}&type=${type}&page_no=${page_no}`)
}

export const getListSummary = (fromDate, list_id, type) => {
    return apiInstance().get(`${cloutV2Endpoints.baseUrl}/analytics/lists/summary?from_date=${fromDate}&list_id=${list_id}&type=${type}`);
}