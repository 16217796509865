import styled from 'styled-components';

export const SubscribeScreenTitle = styled.h3`
  font-weight: 600;
  color: #6351ed;
  text-align: center;
  font-size: 24px;
  margin-bottom: 10px;
`;

export const SubscribeScreenSecureText = styled.p`
  color: #6351ed;
  font-weight: 600;
  font-size: 15px;
`;

export const StyledUl = styled.ul`
  text-align: left;
  list-style-type: none;
  padding: 0px;
  li {
    font-size: 15px;
    padding-left: 15px;
    margin-bottom: 20px;
    position: relative;
    ::before {
      content: '';
      position: absolute;
      left: 0;
      top: 7px;
      width: 7px;
      height: 7px;
      background-color: #6351ed;
      border-radius: 50%;
      -moz-border-radius: 50%;
      -webkit-border-radius: 50%;
    }
  }
`;
