import React from 'react';
import ZeroState from './ZeroState';
import { format, subDays } from 'date-fns';
import { useHistory } from 'react-router-dom';
import { Tooltip } from '@mui/material';
import { ChevronRight } from '@mui/icons-material';
import Skeleton from './Skeleton';
import Mail from 'src/assets/icons/social-media/Mail';
import Facebook from 'src/assets/icons/social-media/Facebook';
import LinkedIn from 'src/assets/icons/social-media/LinkedIn';
import Twitter from 'src/assets/icons/social-media/Twitter';
import CustomMail from 'src/assets/icons/social-media/CustomMail';
import useRESTQuery from 'src/hooks/useRESTQuery';
import { getMediaImage } from 'src/utils';
import { ContentWrapper, Link, Title, TitleWithLink } from '../styles';
import {
  Analytics,
  AnalyticsItem,
  Card,
  CardContent,
  CardHeader,
  CardIcon,
  Content,
  SocialMediaLinks,
} from './styles';

function TopPerformingCampaigns({
  timePeriod,
  additionalQueryParams,
  isAdmin = false,
}) {
  const { push: changeRoute } = useHistory();

  const { data, isLoading, isFetching } = useRESTQuery({
    key: 'top-performing-campaigns',
    dependencies: [timePeriod, additionalQueryParams],
    endpoint: `/analytics/campaigns/top?from_date=${format(
      subDays(new Date(), timePeriod),
      'yyyy-MM-dd'
    )}&limit=4&ongoing=false${
      !!additionalQueryParams ? `&${additionalQueryParams}` : ''
    }`,
    options: {
      select: (res) => (Array.isArray(res?.result) ? res?.result : []),
      refetchOnMount: true,
    },
  });

  return (
    <ContentWrapper>
      <TitleWithLink>
        <Title>Top Performing Campaigns</Title>
        <Link href={isAdmin ? '/admin/analytics' : '/analytics'}>
          <span>Campaign Analytics</span>
          <ChevronRight />
        </Link>
      </TitleWithLink>
      {isLoading || isFetching ? (
        <Skeleton />
      ) : (
        <>
          {data && Array.isArray(data) && data?.length > 0 ? (
            <Content>
              {data?.map((campaign) => {
                const campaignType = campaign?.type === 'series' ? 1 : 2;
                return (
                  <Card
                    key={campaign?.id}
                    onClick={() =>
                      changeRoute(
                        `/analytics/campaign/${campaign?.id}?type=${campaignType}`,
                        { from: '/home' }
                      )
                    }
                  >
                    <CardIcon>
                      {!!getMediaImage(campaign?.media_url) ? (
                        <img
                          src={getMediaImage(campaign?.media_url)}
                          alt=""
                          width="60"
                          height="60"
                        />
                      ) : (
                        <CustomMail />
                      )}
                    </CardIcon>
                    <CardContent>
                      <CardHeader>
                        <Tooltip title={campaign?.name ?? ''} arrow>
                          <h3>{campaign?.name}</h3>
                        </Tooltip>
                        <SocialMediaLinks>
                          {campaign?.platforms?.is_email && <Mail />}
                          {campaign?.platforms?.is_facebook && <Facebook />}
                          {campaign?.platforms?.is_linkedin && <LinkedIn />}
                          {campaign?.platforms?.is_twitter && <Twitter />}
                        </SocialMediaLinks>
                      </CardHeader>
                      <Analytics>
                        {/* <AnalyticsItem>
                        <p>Contacts</p>
                        <h6>{campaign?.contacts ?? '-'}</h6>
                      </AnalyticsItem>
                      <AnalyticsItem>
                        <p>Clicks</p>
                        <h6>{campaign?.clicks ?? '-'}</h6>
                      </AnalyticsItem> */}
                        <AnalyticsItem>
                          <p>Engagement Score</p>
                          <h6>{campaign?.engagement_email ?? '-'}</h6>
                        </AnalyticsItem>
                      </Analytics>
                    </CardContent>
                  </Card>
                );
              })}
            </Content>
          ) : (
            <ZeroState />
          )}
        </>
      )}
    </ContentWrapper>
  );
}

export default TopPerformingCampaigns;
