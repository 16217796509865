import styled from 'styled-components';
import { themesData } from '../../utils/constants';

export const PagenumberWrapper = styled.li`
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
  padding-right: 5px;
  list-style-type: none;
  text-align: center;
`;
export const ActivePageStyle = styled.a.attrs((props) => ({
  className: props.className,
}))`
  border-radius: 2px;
  width: 100%;
  text-align: center;
  cursor: pointer;
  color: #6351ed;
  font-size: 14px;
  padding: 2px 7px;
  &.active-num {
    background: #c95cfc;
    color: #ffffff;
    border-radius: 3px;
  }
`;
