import React from 'react';
import {
  ResponsiveContainer,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  Cell,
  Text,
} from 'recharts';
import { themesData } from '../../utils';
import theme from '../CommonStyles/colors';

const axisFontStyle = {
  fontSize: '7.5px',
  stroke: themesData.purpleyGrey,
  strokeWidth: 0.5,
};

const CustomizedAxisTick = (props) => {
  const { x, y, payload } = props;

  return (
    <Text
      style={axisFontStyle}
      x={x}
      y={y}
      width={75}
      textAnchor="middle"
      verticalAnchor="start"
    >
      {payload.value}
    </Text>
  );
};

const BasicBarChart = ({ data }) => (
  <ResponsiveContainer width="100%" minHeight="180px">
    <BarChart
      data={data}
      margin={{
        left: -30,
        right: 20,
      }}
    >
      <CartesianGrid horizontal vertical={false} />
      <XAxis
        dataKey="name"
        style={axisFontStyle}
        interval={0}
        tick={<CustomizedAxisTick />}
      />
      <YAxis tickCount={3} style={axisFontStyle} />
      <Tooltip cursor={false} />
      <Bar dataKey="value" maxBarSize={25}>
        {data.map((v, i) => (
          <Cell
            key={`cell-${i}`}
            fill={`rgba(120,120,120,${1 - parseFloat('0.' + i)})`}
          />
        ))}
      </Bar>
    </BarChart>
  </ResponsiveContainer>
);

export default BasicBarChart;
