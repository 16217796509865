import styled from 'styled-components';

export const AdvisorsListWrapper = styled.div`
    max-height: 400px;
    overflow-y: auto;

    &::-webkit-scrollbar {
        width: 5px;
    }

    &::-webkit-scrollbar-track {
        background: #e9f6e6 !important;
    }

    &::-webkit-scrollbar-thumb {
        background: #bccdd4 !important;
    }
`;