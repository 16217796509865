import React from 'react';
import { Wrapper, Range } from './Slider.styles';

const Slider = ({ value, max, min, id, handleChange, step }) => {
  return (
    <Wrapper>
      <Range
        value={value}
        step={step}
        type="range"
        id={id}
        name="points"
        min={min}
        max={max}
        onChange={handleChange}
      />
    </Wrapper>
  );
};

export default Slider;
