import { format, isValid } from 'date-fns';
import useRESTQuery from 'src/hooks/useRESTQuery';
// import useQueryForTopics from '../common/hooks/useQueryForTopics';

function useQueryForSearchResults({
  searchString,
  filter,
  selectedTopics,
  formats,
}) {
  // const {
  //   data: topics,
  //   isLoading: topicsLoader,
  //   isFetching: fetchingTopics,
  //   isSuccess,
  // } = useQueryForTopics();

  // const getTopicNamesFromIds = (listOfTopics, ids) => {
  //   const transformedTopics = [...ids]?.reduce((acc, curr) => {
  //     const foundTopic = listOfTopics?.find((t) => t.id === curr);
  //     if (foundTopic) {
  //       return [...acc, foundTopic?.theme_name];
  //     }
  //     return acc;
  //   }, []);

  //   return transformedTopics;
  // };

  const {
    data: searchResults,
    isLoading: searchLoader,
    isFetching: fetchingSearchResults,
  } = useRESTQuery({
    key: 'discover-search-results',
    dependencies: [
      searchString,
      filter,
      [...(selectedTopics ?? [])]?.join(','),
      formats,
    ],
    api: 'fastapi',
    endpoint: `/discover/search/content?query=${searchString}&filter=${encodeURIComponent(
      JSON.stringify({
        type: filter,
        format: formats,
        theme_ids: selectedTopics,
      })
    )}`,
    options: {
      enabled: true,
      select: (res) => {
        const transformedResponse = Array.isArray(res?.result?.data)
          ? res?.result?.data?.map((article) => ({
              id: article?.id ?? '',
              name: article?.name ?? '',
              description: article?.description ?? '',
              created_date: isValid(new Date(article?.created_date))
                ? format(new Date(article?.created_date), 'MMM dd yyyy')
                : '',
              publisher_name: article?.publisher_name ?? '',
              media_url: article?.media_url ?? [],
              tags:
                article?.tags ?? [],
              format: article?.format ?? '',
              bookmark_id: article?.bookmark_id ?? '',
              is_bookmarked: article?.is_bookmarked ?? false,
              content_url: article?.content_url ?? '',
              article_bookmark_id: article?.article_bookmark_id ?? '',
            }))
          : [];

        return transformedResponse;
      },
      refetchOnMount: true,
    },
  });

  return {
    data: searchResults,
    isLoading: searchLoader,
    isFetching: fetchingSearchResults,
  };
}

export default useQueryForSearchResults;
