import styled from 'styled-components';
import { Modal, ModalHeader, ModalBody, Input } from '../';
import { Radio, FormControlLabel } from '@mui/material';
import { colors } from '../../styles';

export const EmailSettingModal = styled(Modal)`
  display: inline-block;
  text-align: left;
  vertical-align: middle;
  position: relative;
  width: auto;
  margin: 10px;
  @media (min-width: 768px) {
    width: 600px;
    margin: 30px auto;
  }
`;

export const TextInput = styled('input')`
  width: ${({ width }) => width || '300px'};
  margin: ${({ margin }) => margin || '5px 0px'};
  border: 1px solid #e3e5e4;
  padding: 10px;
  border-radius: 4px;
  font-family: Poppins !important;
`;

export const TextLabel = styled('label')`
    font-weight: 500;
    font-size: 14px;
    color: #101828;
    text-align: left;
    width: ${({ width }) => width || '49%'};
`;

export const EmailModalHeader = styled(ModalHeader)`
  padding: 0 15px;
  text-transform: capitalize;
  color: #6351ed;
  font-weight: 700;
  text-align: center;
  font-family: 'Poppins';
`;
export const EmailModalBody = styled(ModalBody)`
  position: relative;
  padding: 15px 0;
`;
export const EmailModalBox = styled.div`
  width: 100%;
  position: relative;
  padding: 0;
  display: flex;
  justify-content: space-between;
`;
export const EmailModalInput = styled(Input)``;
export const Image = styled.img`
  width: 100px;
  height: 100px;
  margin: 20px auto 25px;
  display: block;
  object-fit: cover;
  border-radius: 50%;
`;

export const RadioButtonList = styled.ul`
  display: flex;
  margin: 0;
  padding-left: 5px;
  padding-bottom: 12px;
`;


export const RadioLabel = styled(FormControlLabel)`
  font-weight: 500;
  font-size: 14px;
  color: #101828;
`;

export const RadioStyle = styled(Radio)`
  color: #816EF8 !important;  
`;

export const ButtonContainer = styled('div')`
    width: 100%;
    margin: 20px auto 0px;
    padding: 10px 0 0;
    display: flex;
    justify-content: space-between;
    border-top: 1px solid ${colors.gray_border};
`;

