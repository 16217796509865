import { Flex } from "@rebass/grid";
import { ColorPallete } from "src/utils";
import styled from "styled-components";

export const SuggestionScrollWrapper = styled(Flex)`
    height: 100%;
    max-height: 100%;
    flex-direction: column;
    overflow-x: hidden;
    overflow-y: auto;
    text-align: left;
    #suggestedCaption, #caption {
        margin: 0px 0px 10px;
        color: ${ColorPallete.neutral900};
        font-family: 'Poppins-medium';
    }

    p {
        margin: 0px 0px 10px;
        color: ${ColorPallete.neutral900};
        font-family: 'Poppins-medium';
    }

    h3 {
        margin: 0px 0px 5px;
        color: ${ColorPallete.neutral900};
        font-family: 'Poppins-medium';
    }
    ul {
        margin: 0;
        padding: 0 20px;
        font-family: 'Poppins';
    }
    li {
        margin-bottom: 10px;
        font-family: 'Poppins';
    }
    &::-webkit-scrollbar {
        width: 5px;
    }
    &::-webkit-scrollbar-track {
        background: #e9f6e6 !important;
    }
    &::-webkit-scrollbar-thumb {
        background: #bccdd4 !important;
    }
`