import styled from 'styled-components';

export const MioDropdownWrapper = styled.div`
  position: relative;
  width: ${(props) => (props.fullWidth ? '100%' : 'auto')};
  .dropdown-menu {
    display: none;
    &.open {
      display: block;
      width: ${(props) => (props.optionsWidth ? props.optionsWidth : '')};
      margin-right: ${(props) => (props.optionsMarginRight ? props.optionsMarginRight : '')};;
    }
  }
  ${({ openOnHover }) =>
    openOnHover &&
    `:hover {
        .dropdown-menu {
            display: block;
        }
    }
  `}
`;

export const MioDropdownMenu = styled.div`
  position: absolute;
  top: ${({ top, type }) => (top ? top : type === 'campaignDashbaord' ? '0%' : '100%')};
  z-index: 100;
  right: ${({ right, type }) =>
    type === 'login'
      ? '8%'
      : type === 'date' && !right
      ? '20%'
      : type === 'emailList'
      ? ''
      : type === 'campaignDashbaord'
      ? '15px' :
      right ? right :
      '0px'};
  left: ${({ left }) => left || ''};
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    box-shadow: ${(props) =>
      props.type === 'campaignDashbaord'
        ? 'rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px'
        : '0px 1px 2px #ddd'};
    -webkit-box-shadow: ${(props) =>
      props.type === 'campaignDashbaord'
        ? 'rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px'
        : '0px 1px 2px #ddd'};
    max-height: ${(props) => (props.type === 'emailList' ? '280px' : '')};
    overflow-y: ${(props) => (props.type === 'emailList' ? 'auto' : '')};
    li {
      -webkit-box-sizing: border-box; /* Safari, other WebKit */
      -moz-box-sizing: border-box; /* Firefox, other Gecko */
      box-sizing: border-box; /* Opera/IE 8+ */
      background: ${({ background }) => background || '#ffffff'};
      border-bottom: ${(props) =>
        props.type === 'campaignDashbaord' ? 'none' : '1px solid #ddd'};
      border-right: ${(props) =>
        props.type === 'campaignDashbaord' ? 'none' : '1px solid #ddd'};
      border-left: ${(props) =>
        props.type === 'campaignDashbaord' ? 'none' : '1px solid #ddd'};
      border-top: none;
      padding: ${(props) =>
        props.type === 'campaignDashbaord' ? '5px 10px' : '4px 5px'};
      cursor: pointer;
      text-align: ${(props) =>
        props.type === 'emailList' || props.type === 'campaignDashbaord'
          ? 'left'
          : ''};
      font-size: ${(props) =>
        props.type === 'emailList' || props.type === 'campaignDashbaord'
          ? '12px'
          : ''};
      transition: all 0.2s;
      width: ${(props) =>
        props.type === 'date'
          ? '12.9em'
          : props.type === 'analytics'
          ? '10em'
          : props.type === 'emailList' && !props.width
          ? 'auto'
          : props.width
          ? props.width
          : '8em'};
      min-width: ${({ type }) => (type === 'emailList' ? '19em' : '')};
      max-width: ${({ type, width }) =>
        type === 'emailList' && !width ? '25em' : width ? width : ''};
      color: ${(props) => (props.type === 'campaignDashbaord' ? '#000' : '')};
      a {
        display: block;
        width: 100%;
        height: 100%;
        font-size: ${(props) => (props.type === 'analytics' ? '16px' : '12px')};
        padding: 0px;
        border: 0px;
        justify-content: center;
        text-decoration: none;
        color: ${(props) => (props.type === 'campaignDashbaord' ? '#000' : '')};
      }
      &:hover {
        background: ${({ hoverBackground }) => hoverBackground || '#6351ed'};
        color: #fff;
        font-size: ${(props) => (props.type === 'emailList' ? '12px' : '')};
        a {
          color: #fff;
          font-size: ${(props) =>
            props.type === 'analytics'
              ? '16px'
              : props.type === 'emailList'
              ? '8px'
              : '12px'};
        }
      }
    }
  }
`;
