import React from 'react';
import MioDatePicker from '../../MioDatePicker';
import moment from 'moment';
import {
  CheckBoxWrapper,
  ScheduleToolTip,
  ScheduleSection
} from '../ShareContentContainer.styles';
import { DripIconButton } from '../../Form';
import { Flex } from '@rebass/grid';

const AdminScheduler = ({
  scheduleValue, 
  connectionType,
  disableScheduleClick,
  clearSchedule,
  onClockButtonClick,
  isAdvisorOpen,
  isDateOpen,
  setIsAdvisorOpen,
  setDateOpen,
  onScheduleClick,
  isFromScheduledTab,
  fromMyClients,
  timezone
}) => {
  return (
    <ScheduleSection>
      <Flex alignItems="center" style={{cursor: disableScheduleClick ? 'pointer' : 'default'}}>
        {/* <DripIconButton
          onClick={(e) => onClockButtonClick(e, connectionType)}
          icon="clock"
          title="Schedule Post"
          styles={{height: '19px', fontSize: 17, color: !!scheduleValue && disableScheduleClick ? '#6351ed' : '#848484'}}
          disable={!disableScheduleClick}
        /> */}
        {!disableScheduleClick ? 
          <img 
            src="/assets/images/Schedule-Disabled.svg"
            alt="Clock"
            style={{ height: '16px', width: '16px' }}
          />
          :
          !!scheduleValue && disableScheduleClick ?
          <img 
            src="/assets/images/Schedule-Purple.svg"
            alt="Clock"
            style={{ height: '16px', width: '16px' }}
            onClick={(e) => onClockButtonClick(e, connectionType)}
          />
          :
          <img 
            src="/assets/images/Schedule-Grey.svg"
            alt="Clock"
            style={{ height: '16px', width: '16px' }}
            onClick={(e) => onClockButtonClick(e, connectionType)}
          />
        }
        <MioDatePicker
          isOpen={connectionType === 'advisor' ? isAdvisorOpen : isDateOpen}
          customInput={<div />}
          minDate={new Date()}
          scheduleValue={scheduleValue}
          onDateClose={() => connectionType === 'advisor' ? setIsAdvisorOpen(false) : setDateOpen(false)}
          onSubmit={(dateTime) => onScheduleClick(dateTime, connectionType)}
          popperModifiers={{
            offset: {
            enabled: true,
            offset: "130px, 0px"
          }}}
          isFromScheduledTab={isFromScheduledTab}
          timeZone={timezone}
        />
          <div 
            className='tooltip'
              
            onClick={(e) => !disableScheduleClick ? {} : onClockButtonClick(e, connectionType)} 
            style={{fontSize: 9, color: !!scheduleValue && disableScheduleClick ? '#6351ed' : !disableScheduleClick ? '#cccccc' : '#49484a', paddingLeft: '5px'}}>Schedule
          {scheduleValue && disableScheduleClick && (!isAdvisorOpen && !isDateOpen) && <ScheduleToolTip width={fromMyClients ? 'auto' : null} right={fromMyClients ? '91%' : null} top={fromMyClients ? '-4px' : null} className='tooltiptext'>{moment(scheduleValue).format("Do MMM YYYY | h:mm A")}</ScheduleToolTip>}
          </div>
      </Flex>
    </ScheduleSection>
  );
}

const OthersScheduler = ({
  onClockButtonClick,
  isDateOpen,
  setDateOpen,
  onScheduleClick,
  scheduleValue,
  clearSchedule,
  disableScheduleClick,
  isChecked,
  shareModalMsgs,
  fromMyClients,
  isFromScheduledTab
}) => {
  console.log('[SCHEDULE VALUE] - ', scheduleValue)
  return(
    <>
      <CheckBoxWrapper className="mio-calendar" style={{cursor: disableScheduleClick ? 'pointer' : 'default'}}>
        <div style={{position: 'relative', paddingTop: '5px'}}>
          {scheduleValue && <div style={fromMyClients ? {position: 'absolute', left: '29px', bottom: '10px', color: 'red', cursor: 'pointer'} : {position: 'absolute', right: '1px', bottom: '20px', color: 'red', cursor: 'pointer'}} onClick={() => clearSchedule('')}>x</div>}
          <DripIconButton
            onClick={onClockButtonClick}
            icon="clock"
            styles={fromMyClients ? {position: 'absolute', top: '10px', border: '1px solid #cacaca', borderRadius: '5px', padding: '4px 5px 1px', color: '#6351ed'} : {color: !!scheduleValue && disableScheduleClick ? '#6351ed' : '#848484'}}
            disable={!shareModalMsgs.length === 0}
            />
            <div className='tooltip' style={{fontSize: 9, color: !!scheduleValue && disableScheduleClick ? '#6351ed' : '#848484'}}>{!fromMyClients ? 'Schedule' : ''}
              {scheduleValue && disableScheduleClick && !isDateOpen && <ScheduleToolTip style={fromMyClients ? {width: 'auto', top: '8px', right: '13px', height: '22px', textAlign: 'center', paddingBottom: '19px'} : {width: 'auto', bottom: '10px', right: '43px', height: '22px', textAlign: 'center', paddingBottom: '19px'}} className='tooltiptext'>{moment(scheduleValue).format("Do MMM YYYY | h:mm A")}</ScheduleToolTip>}
            </div>
        </div>
        <MioDatePicker
          isOpen={isDateOpen}
          customInput={<div />}
          minDate={new Date()}
          onDateClose={() => setDateOpen(false)}
          onSubmit={onScheduleClick}
          fromMyClients={fromMyClients}
          scheduleValue={scheduleValue}
          isFromScheduledTab={isFromScheduledTab}
        />
      </CheckBoxWrapper>
    </>
  )
}

export {
  AdminScheduler,
  OthersScheduler,
};
