import React from 'react';
import { Flex } from '@rebass/grid';

import { ButtonNoBorderBackground } from '../';

const DownloadButton = ({ onClick, text = 'CSV', className, disabled, fontSize, paddingTop, title }) => {
  return (
    <ButtonNoBorderBackground
      onClick={onClick}
      className={`${className} download-${text}`}
      disabled={disabled}
      fontSize={fontSize}
    >
      <Flex width={1}>
        <div className="icon dripicons-download" style={{paddingTop: paddingTop || '2px'}}></div>&nbsp;
        <div style={{fontFamily: 'Poppins', fontWeight: '600'}}>{title || `Download ${text}`}</div>
      </Flex>
    </ButtonNoBorderBackground>
  );
};

export default DownloadButton;
