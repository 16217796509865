import styled from 'styled-components';

export const StyledBlockContent = styled.div`
  color: #6351ed;
  text-align: center;
  border: 1px solid #a1a1a1;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  padding: 6px 12px;
  border-radius: 4px;
  margin-bottom: 20px;
`;

export const FileUploadWrapper = styled.div`
  float: right;
  button {
    margin: 0px;
  }
`;

export const ErrorFileUploadMessage = styled.div`
  margin-top: -10px;
  margin-bottom: 5px;
`;
