import { Flex } from '@rebass/grid';
import styled from 'styled-components';

export const NewsletterArticleWrapper = styled.div.attrs({
  className: 'newsletter-article-wrapper',
})`
  width: ${({ width }) => width || '240px'};
  height: ${({ height }) => height || '275px'};
  border-radius: 8px;
  background-color: ${({ bgColor }) => bgColor || '#eeeeee'};
  display: flex;
  flex-direction: ${({ flexDirection }) => flexDirection};
  margin: ${({ margin }) => margin || '10px 0'};

  img {
    height: ${({ imgHeight }) => imgHeight};
    width: ${({ imgWidth }) => imgWidth};
    border-top-left-radius: 8px;
    border-top-right-radius: ${({ rightImgBorder }) => rightImgBorder};
    border-bottom-left-radius: ${({ rightImgBorder }) =>
      rightImgBorder === '0px' ? '8px' : '0px'};
    object-fit: cover;
    max-height: ${({ maxImgHeight }) => maxImgHeight};
    max-width: ${({ maxImgWidth }) => maxImgWidth};
  }
`;

export const OneColumnLayout = styled(Flex)`
  font-weight: ${({ fontWeight }) => fontWeight};
  text-align: left;
  min-height: ${({ minHeight }) => minHeight};
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: ${({ lineClamp }) => lineClamp || 0};
  -webkit-box-orient: vertical;
  padding: 2px 5px;
  padding-top: 10px;
`;
export const DefaultLayout = styled(Flex)`
  font-weight: ${({ fontWeight }) => fontWeight};
  text-align: left;
  min-height: ${({ minHeight }) => minHeight};
`;
