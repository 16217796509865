import React, { useState } from 'react'
import { ACBlock } from '../../AutomatedCampaigns/CampaignCreateSteps/CampaignCreateSteps.styles';
import { Flex } from '@rebass/grid';
import { camelCase, isEmpty, startCase } from 'lodash';
import { Button } from '../../../components';
import { useHistory } from 'react-router-dom';
import { getMediaImage } from '../../../utils';
import { statusColorCodes } from '../DripDashboardTable/CampaignInfo/CampaignInfo';
import { Tooltip } from '@mui/material';
import ConfirmationModal from '../../../components/ConfirmationModal/ConfirmationModal';
const DataCard = ({
  item,
  step,
  onDeleteClick,
  onCloneClick,
  onPreviewClick,
  onSocialPreviewClick,
  onSendTestModalClick,
  reviewType,
  setObjectivesTabData
}) => {
  const history = useHistory();
  const [scheduleEditModal, setScheduleEditModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState();

  const redirectEdit = () => {
    if (selectedItem?.is_email === "true") {
      history.push(`/create-email/${selectedItem?.series_ref}/${selectedItem?.id}?type=campaign`)
    } else {
      history.push(`/create-social/${selectedItem?.series_ref}/${selectedItem?.id}?type=campaign`)
    }
  }
  const getImage = () => {
    if (item.is_email === "true") {
      if (!isEmpty(item?.customized_data?.subject)) {
        if (!isEmpty(item?.media_urls)) {
          return getMediaImage(item.media_urls);
        } else {
          const randomNumber = Math.floor(Math.random() * 4) + 1;
          return `/assets/images/postDefault${randomNumber}.jpg`;
        }
      } else {
        return '/assets/images/Campaign/Default-image.png';
      }
    } else {
      if (item?.posts_details) {
        let socialkeys = Object.keys(item?.posts_details);
        return getMediaImage(!isEmpty(item?.posts_details[socialkeys[0]][0].articles.feed) ? item?.posts_details[socialkeys[0]][0].articles.feed[0].media_urls[0] : '');
      } else {
        return '/assets/images/Campaign/Default-image.png';
      }
    }
  }
  return (
    <ACBlock>
      <Flex width="25%">
        <img
          style={{
            width: '100%',
            objectFit: 'contain',
          }}
          src={getImage()}
          alt=""
        />
      </Flex>
      <Flex width="75%" pl="10px" flexDirection="column">
        <Flex alignItems="center" flexDirection="row" justifyContent="space-between">
          <Flex color="#101828" fontSize="16px" style={{ fontWeight: '500', display: 'inline-block', textAlign: 'left', }}>
            {item.name}
          </Flex>
          
          {step === 1 && (
            <Flex alignItems="center" flexDirection="row">
              <Flex>
                <Tooltip title="Delete Block" arrow>
                  <img
                    alt=""
                    src="assets/images/Campaign/delete-icon.svg"
                    onClick={() => {
                      console.log('item id-->', item.id);
                      onDeleteClick(item.id);
                    }}
                    style={{ cursor: 'pointer' }}
                  />
                </Tooltip>
              </Flex>
              {(!reviewType || isEmpty(reviewType)) && (
                <Flex>
                  <Tooltip title="Duplicate Block" arrow>
                  <img
                    alt=""
                    src="assets/images/Campaign/copy-icon.svg"
                    onClick={() => onCloneClick(item.id)}
                    style={{ cursor: 'pointer' }}
                  />
                  </Tooltip>
                </Flex>
              )}


              {item.is_email === 'true' && (
                <Flex>
                  <Tooltip title="Preview" arrow>
                  <img
                    alt=""
                    src="assets/images/Campaign/eye-icon.svg"
                    onClick={() => onPreviewClick(item)}
                    style={{ cursor: 'pointer ' }}
                  />
                  </Tooltip>
                </Flex>
              )}
              {item.is_email !== 'true' && (
                <Flex>
                  <Tooltip title="Preview" arrow>
                    <img
                      alt=""
                      src="assets/images/Campaign/eye-icon.svg"
                      onClick={() => onSocialPreviewClick(item)}
                      style={{ cursor: 'pointer' }}
                    />
                  </Tooltip>
                </Flex>
              )}
            </Flex>
          )}

          {step === 2 && item.is_email === 'true' ? (
            <Flex alignItems="center" flexDirection="row">
              <Flex>
                <Tooltip title="Send Test Email" arrow>
                  <img
                    alt=""
                    src="assets/images/Campaign/Send-Test-Email.svg"
                    onClick={() => {
                      onSendTestModalClick(item);
                    }}
                    style={{ cursor: 'pointer' }}
                  />
                </Tooltip>
              </Flex>
              <Flex>
                <Tooltip title="Preview" arrow>
                  <img
                    alt=""
                    src="assets/images/Campaign/eye-icon.svg"
                    onClick={() => {
                      console.log('preview clicked');
                      onPreviewClick(item);
                    }}
                    style={{ cursor: 'pointer' }}
                  />
                </Tooltip>
              </Flex>
            </Flex>
          ) : step === 2 && item.is_email !== 'social' ? (
              <Flex alignItems="center" flexDirection="row">
                <Flex>
                  <Tooltip title="Preview" arrow>
                    <img
                      alt=""
                      src="assets/images/Campaign/eye-icon.svg"
                      onClick={() => {
                        console.log('social preview clicked');
                        onSocialPreviewClick(item);
                      }}
                      style={{ cursor: 'pointer' }}
                    />
                  </Tooltip>
                </Flex>
              </Flex>
          ) : null}
        </Flex>
        <Flex 
          style={{
            borderRadius: '8px',
          }}
          backgroundColor={statusColorCodes[item?.status] ? statusColorCodes[item?.status] : '#6351ed'}
          color="#fff"
          fontSize="12px"
          width="fit-content"
          padding="2px 8px"
        >
          {startCase(camelCase(item?.status))}
        </Flex>
        {!isEmpty(item.email) && (
          <Flex color="#667085" padding="10px 0px 10px" fontSize="12px" style={{textAlign: 'left'}}>
            {item?.customized_data?.subject}
          </Flex>
        )}

        {item?.is_email === 'false' && item.posts && !isEmpty(item.posts) && (
          <Tooltip title={item?.posts[Object.keys(item?.posts)[0]].override_text.length > 250 ? item?.posts[Object.keys(item?.posts)[0]].override_text : null} arrow>
            <Flex color="#667085" padding="10px 0px 10px" fontSize="12px" style={{textAlign: 'left'}}>
              {item?.posts[Object.keys(item?.posts)[0]].override_text.length > 250 ? item?.posts[Object.keys(item?.posts)[0]].override_text.substring(0, 250) + '...' : item?.posts[Object.keys(item?.posts)[0]].override_text}
            </Flex>
          </Tooltip>
        )}
        
        {step === 1 && (
          <Flex>
            <Button disabled={(reviewType === 'start') || (item?.status !== 'draft' && item?.status !== 'compliance_rejected' && item?.status !== 'approval_rejected')} onClick={() => {
              // if(item?.status === 'scheduled' && !setObjectivesTabData?.skip_compliance) {
              //   setScheduleEditModal(true);
              //   setSelectedItem(item);
              // } else {
                if (item?.is_email === "true") {
                  history.push(`/create-email/${item?.series_ref}/${item?.id}?type=campaign`)
                } else {
                  history.push(`/create-social/${item?.series_ref}/${item?.id}?type=campaign`)
                }
              
            }} borderColor="#E4E7EC" color="#101828" bgColor="#fff" margin="10px 0px 0px">
              <Flex justifyContent="space-between" width="100%" alignItems="center">
                <img src="assets/images/Campaign/Pencil-Icon.svg" alt="Pencil" />
                <Flex ml="5px">
                  {(item?.is_email === "true") ? 'Customize Email Block' : 'Customize Social Block'}
                </Flex>
              </Flex>
            </Button>
          </Flex> 
        )}
      
      </Flex>
      {scheduleEditModal && item?.id === selectedItem?.id && (
        <ConfirmationModal
          modalTitle="By selecting to Edit this campaign you will be required to submit again for compliance approval. Do you wish to continue?"
          modalWidth="30%"
          clickHandler={redirectEdit}
          okBtnText="Yes"
          noButton={false}
          onlyShowOk={false}
          btnMargin="0px"
          closeHandler={() => {
            setScheduleEditModal(true);
            setSelectedItem();
          }}
          mt="35px"
          noClose
          loader={false}
        />
      )}
    </ACBlock>
  )
}

export default DataCard;