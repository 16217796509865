import { get } from 'lodash';
import { takeLatest, all, call, put } from 'redux-saga/effects';
import { actions as portfolioActions } from '../modules/portfolio.module';
import { getPortfolioList, postUserPortfolio } from '../../services/dashboard';

function* portfolioRequest({ payload }) {
  try {
    const response = yield call(getPortfolioList);
    const data = get(response, 'result.data', {});
    yield put(portfolioActions.api.portfolio.response(data));
  } catch (error) {
    yield put(portfolioActions.api.portfolio.error(error));
  }
}

// function* setUserPortfolioRequest({ payload }) {
//   try {
//     console.log(payload);
//     // const {} = get(payload, 'params', {});
//     const response = yield call(postUserPortfolio, payload);
//     const status = get(response, 'result.success');
//     if (status === 'true') {
//       const data = get(response, 'response.data', {});
//       yield put(portfolioActions.api.portfolio.response(data));
//     }
//   } catch (error) {
//     yield put(portfolioActions.api.portfolio.error(error));
//   }
// }

function* portfolioSaga() {
  yield all([
    takeLatest(portfolioActions.api.portfolio.request, portfolioRequest),
    // takeLatest(portfolioActions.api.portfolio.post, setUserPortfolioRequest),
  ]);
}

export default portfolioSaga;
