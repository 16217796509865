import React from 'react';
import { Button, LoaderWrapper } from '../../../../../components'
import './StepThreeComponent.css';

const stepThreeComponent = ({ close, updateVerificationStatus, updateConnection, toggleDelete, deleteLoader, showDelete, setErrorMessage }) => {

    const disconnectHandler = () => {
        updateVerificationStatus('verified');
        updateConnection('disconnect');
    }

    // const deleteHandler = () => {
    //     updateVerificationStatus('verified');

    // }

    return (
        <>
            {
                showDelete ? (
                    <div style={{ margin: 'auto', textAlign: 'center' }}>
                        <LoaderWrapper isLoading={deleteLoader}>
                            <Button onClick={() => updateConnection('delete')}>Delete the connection</Button>
                        </LoaderWrapper>
                        <br />
                        <a href='#' className='connected-delete-btn' onClick={() => {
                            setErrorMessage('');
                            toggleDelete(false);
                        }}>Back</a>
                    </div>
                ) :
                    (<div>
                        <Button onClick={close}>Get Started</Button>
                        <div style={{ display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', width: '50%', margin: '10px auto 0' }}>
                            <a className='sendgrid-link' href='#' onClick={disconnectHandler}>Disconnect the connection</a>
                            <div style={{ height: '10px', border: '0.1px solid #cacaca' }}></div>
                            <a className='sendgrid-link' href='#' onClick={() => toggleDelete(true)}>Delete the connection</a>
                        </div>
                    </div>)
            }
        </>
    );
}

export default stepThreeComponent;