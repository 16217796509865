import styled from 'styled-components';
import { Flex, Box } from '@rebass/grid';
import themes from '../CommonStyles/colors';
import { themesData } from '../../utils/constants';

export const StyledArticle = styled.article`
  width: ${({ width }) => width || '250px'};
  background-color: ${themesData.article.background};
  border-radius: 10px;
  text-align: left;
  margin: ${({ margin }) => margin || '0 auto'};
  height: ${({ height }) => height || '275px'};
  position: relative;
  a {
    text-decoration: none;
    display: block;
  }
  img:not(.loader) {
    width: 100%;
    border-radius: 10px 10px 0px 0px;
    height: 116px;
    background-size: cover;
    object-fit: cover;
  }
  .details-wrapper {
    padding: ${({ type }) =>
      type && type === 'sponsored' ? '0px 10px' : '3px 10px'};
    //fix height to accomodate btn-wrapper using 275px - height of the card
    height: ${({ type }) =>
      type && type === 'sponsored'
        ? 'calc(275px - 161px)'
        : 'calc(275px - 150px)'};
    overflow: hidden;
  }
  .selected-topic {
    font-size: 10px;
    font-weight: bold;
    text-transform: uppercase;
    margin: 2px 0;
    margin-top: ${({ type }) => (type && type === 'sponsored' ? '1px' : '5px')};
    line-height: 14px;
  }
  .place-date {
    font-size: 8px;
    color: #49484a;
    font-weight: 700;
    text-transform: uppercase;
    margin: 3px 0 4px 0;
    // margin-top: 5px;
    line-height: 1.5;
    font-weight: normal;
  }
  .post-heading,
  .post-desc {
    color: #545454;
    font-weight: 200;
    font-size: 11px;
    line-height: 12px;
    padding-bottom: 3px;
    height: ${({ holdingsType }) => (holdingsType ? '30px' : '45px')};
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: ${({ holdingsType }) => (holdingsType ? '2' : '3')};
    word-break: break-word;
    -webkit-box-orient: vertical;
    white-space: pre-wrap;
    text-overflow: ellipsis;
  }
  .post-heading {
    font-weight: bold;
    height: ${({ fromContentLibTab }) => (fromContentLibTab ? 'auto' : '36px')};
    line-height: 1.5;
    color: #030303;
  }
  .post-tickers {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: ${({ holdingsType }) => (holdingsType ? '2' : '3')};
    word-break: break-word;
    -webkit-box-orient: vertical;
    white-space: pre-wrap;
    text-overflow: ellipsis;
  }
  .post-desc {
    font-size: 10px;
    line-height: 1.6;
  }
  .btn-wrapper {
    height: 25px;
    position: relative;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: space-between;
    button[data-btn-type] {
      color: ${themes.color.font.primary};
      cursor: pointer;
      z-index: 1;
    }
    .delete-btn-wrapper {
      position: relative;
      bottom: -1px;
      left: 10px;
    }
    .investment-themeid {
      span {
        cursor: pointer;
      }
    }
    button[data-btn-type='icon-information-btn']:not(.info-scheduled) {
      left: 5px;
      top: 11px;
    }
    button[data-btn-type='icon-forward-btn'],
    button[data-btn-type='icon-graph-bar-btn'],
    button.info-scheduled {
      right: 5px;
      top: 12px;
    }
    .share-article {
      cursor: pointer;
      img {
        height: 16px;
        width: auto;
      }
    }
    img {
      height: 25px;
    }
    //explicitly position if only one child present to the left
    & > div:only-child {
      position: absolute;
    }
    .investment-themeid {
      width: 100%;
    }
  }
  h4.free-article-text {
    text-align: center;
    color: #545454;
    font-weight: 700;
    font-size: 13px;
    line-height: 16px;
  }
  .more-options {
    position: absolute;
    height: 50px;
    width: 124px;
    background-color: #fff;
    top: -50px;
    left: 10px;
    display: flex;
    flex-direction: column;
  }
  .free-article-buttonwrapper {
    text-align: center;
  }
  .power-by-wrapper {
    display: flex;
    align-items: flex-start;
    z-index: 0;
    img {
      height: 15px;
      width: 83px;
    }
  }
  @media (max-width: 768px) {
    width: 275px;
    margin-top: ${(props) => (props.index !== 0 ? '20px' : '0px')};
  }
  @media (max-width: 1680px) {
    margin: ${({ type }) =>
      type === 'sponsored' ? '0 auto 4% auto' : '0 auto'};
    height: 275px;
    .details-wrapper {
      padding: 3px 10px;
      //fix height to accomodate btn-wrapper using 275px - height of the card
      height: calc(275px - 150px);
    }
    .selected-topic {
      margin-top: 5px;
    }
  }
  .react-datepicker__tab-loop {
    position: absolute;
    top: 129px;
    right: 80px;
  }
`;

export const FlipBackContent = styled(Flex)`
  background-color: #666666;
  padding: 10px 0 15px;
  border-radius: 10px;
  color: #fff;
  // should be as the same height of the cards
  height: 275px;
`;

export const BackContentRowData = styled.div`
  margin-top: 9px;
  text-align: center;
`;

export const IconBox = styled(Box)`
  height: 15px;
  width: 15px;
  background-color: ${({ background }) => background || '#fff'};
  color: ${({ color }) => color || '#666666'};
  border-radius: 5px;
  margin-top: 5px;
  margin-right: 3px;
  margin-left: 3px;
  padding: 0px;
`;
