import { Flex } from '@rebass/grid';
import { get, isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { Button, LoaderWrapper } from '../../components';
import { listUsers, redirectImpersonateUser } from '../../services/login';
import { themesData } from '../../utils';
import { actions as loginActions } from '../../redux/modules/login.module';
import { SetObjectivesInput } from '../DripCampaignContainer/SetObjectives/SetObjectives.styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

const ManageUserContainer = ({
  loginRequest
}) => {

  const [data, setData] = useState([]);
  const [searchString, setSearchString] = useState('');
  const [page, setPage] = useState(1);
  const [paginatedData, setPaginatedData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchData, setSearchData] = useState([]);
  const limit = 8;

  const userRoles = {
    admin: 'Admin',
    general: 'Advisor'
  }

  useEffect(() => {
    const windowBodyClassList = document.body.classList;
    if (!windowBodyClassList.contains('campaign')) {
      windowBodyClassList.add('campaign');
    }
    setLoading(true);
    listUsers().then(res => {
      if(get(res, 'result.success', false)) {
        setLoading(false);
        setData(get(res, 'result.data', []));
        if(!isEmpty(get(res, 'result.data', []))) {
          setPaginatedData(get(res, 'result.data', []).slice(0, limit));
        }
      }
    }).catch(err => {
      console.log(err);
      setLoading(false);
    });

    console.log('token', window.sessionStorage.getItem('token'));

    return () => {
      windowBodyClassList.remove('campaign');
    };
  }, []);

  console.log(data);
  const handlePagination = (mode) => {
    let newPage;
    let newData = !isEmpty(searchData) ? searchData : data;
    if(mode === 'prev') {
      newPage = page - 1;
      console.log('newPage', newPage);
      setPage(newPage);
      setPaginatedData(newData.slice((newPage === 1 ? 0 : (newPage - 1)), (newPage * limit)));
    } else {
      newPage = page + 1;
      console.log('newPage', newPage);
      setPage(newPage);
      setPaginatedData(newData.slice((page * limit), ((page + 1) * limit)));
    }
  }

  const handleSearch = (value) => {
    setSearchString(value);
    setPage(1);
    if(!isEmpty(value)) {
      let searchArray = data.filter(d => d.email.toLowerCase().includes(value.toLowerCase()) || d.first_name.toLowerCase().includes(value.toLowerCase()) || d.last_name.toLowerCase().includes(value.toLowerCase()));
      if(!isEmpty(searchArray)) {
        setSearchData(searchArray);
        setPaginatedData(searchArray.slice(0, limit));
      }
    } else {
      setSearchData([]);
      setPaginatedData(data.slice(0, limit));
    }
  }

  const redirectUser = (email, role_type) => {
    redirectImpersonateUser({
      user_email: email
    }).then(res => {
      console.log(res, 'asdasd ');
      if (res.result.success) {
        let userToken = res.result.data.token;
        let token = window.sessionStorage.getItem('token');
        console.log('token', token);
        window.sessionStorage.setItem('ogToken', token);
        
        window.sessionStorage.setItem('token', userToken);
        window.sessionStorage.setItem('switch-user', true);
        if (role_type === "general") {
          window.sessionStorage.removeItem('advisorToken');
          loginRequest({ token: userToken });
          setTimeout(() => {
            window.open('/home', '_self');
          }, 2000);
        } else {
          const generalToken = res.result.data.secondary_account.data.token;
          window.sessionStorage.setItem('advisorToken', generalToken);
          window.open('/admin/home', '_self');
        }
      }
    }).catch(err => {
      console.log(err);
    })
  }

  const handleValidation = () => {
    if (!isEmpty(searchData)) {
      return page * limit > searchData.length;
    } else {
      return page * limit > data.length;
    }
  }

  return (
    <Flex width="90%" justifyContent="center" flexDirection="column" mt="3%" ml="auto" mr="auto" style={{
      border: '1px solid #E4E7EC',
      borderRadius: '4px'
    }}>
      <Flex padding="10px 15px 5px" backgroundColor="#fff" width="100%" justifyContent="space-between">
        <Flex fontSize="20px" style={{fontWeight: '500'}} color={themesData.black}>
          Manage Users
        </Flex>
        <Flex width="20%">
          <SetObjectivesInput
            placeholder='Search'
            onChange={(e) => handleSearch(e.target.value)}
            value={searchString} 
          />
          <FontAwesomeIcon icon={faSearch} style={{ position: 'relative', top: '8px', right: '23px'}}/>
        </Flex>  
      </Flex>
      <Flex padding="0px 15px 10px" backgroundColor="#fff" width="100%" justifyContent="space-between">
        <Flex fontSize="14px" style={{ fontWeight: '500' }} color={themesData.black}>
          {isEmpty(data) ? 0 : data.length} Users
        </Flex>
      </Flex>
      <Flex padding="15px" width="100%" backgroundColor="#F9FAFB" style={{
        border: '1px solid #E4E7EC',
      }}>
        <Flex width="20%" fontSize="18px" color={themesData.black}>
          Name
        </Flex>
        <Flex width="20%" fontSize="18px" color={themesData.black}>
          Role
        </Flex>
        <Flex width="20%" fontSize="18px" color={themesData.black}>
          Firm
        </Flex>
        <Flex width="35%" fontSize="18px" color={themesData.black}>
          Email
        </Flex>
      </Flex>
      <LoaderWrapper isLoading={loading}>
        {!isEmpty(paginatedData) && paginatedData.map(({ first_name, last_name, firm_name, email, role_type }) => (
          <Flex alignItems="center" padding="15px" width="100%" backgroundColor="#fff" style={{
            borderBottom: '1px solid #E4E7EC',
          }}>
            <Flex width="20%" fontSize="14px" color={themesData.black}>
              {first_name + ' ' + last_name}
            </Flex>
            <Flex width="20%" fontSize="14px" color={themesData.black}>
              {userRoles[role_type]}
            </Flex>
            <Flex width="20%" fontSize="14px" color={themesData.black}>
              {firm_name}
            </Flex>
            <Flex width="35%" fontSize="14px" color={themesData.black}>
              {email}
            </Flex>
            <Flex width="5%">
              <img style={{
                cursor: 'pointer'
              }} alt="redirect" src="assets/images/redirect.svg"
                onClick={() => redirectUser(email, role_type)}
              />
            </Flex>
          </Flex>
        ))}
      </LoaderWrapper>
      <Flex width="100%" padding="0px 15px 15px" backgroundColor="#F9FAFB" justifyContent="space-between">
        <Button borderRadius="4px" onClick={() => handlePagination('prev')} disabled={page === 1}>
          Previous
        </Button>
        <Button onClick={() => handlePagination('next')} disabled={handleValidation()} borderRadius="4px">
          Next
        </Button>
      </Flex>
    </Flex>
  )
}
const mapDispatchToProps = {
  loginRequest: loginActions.api.login.request,
};

export default connect(null, mapDispatchToProps)(ManageUserContainer);