/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Flex } from '@rebass/grid';
import React, { useEffect } from 'react';
import { useState } from 'react';
import { AUTOMATED_CAMPAIGN_ACTIONS } from '../AutomatedCampaignsContainer';
import { LineBreaker, StyledTextInput } from '../styles';
import ReactSelect from 'react-select';
import { AutomatedCampaignFormWrapper } from '../styles';
import {
  createAutomatedCampaign,
  getTargetSegments,
  updateAutomatedCampaign,
} from '../../../services/automated-campaigns';
import { getLandingPages } from '../../../services/bee-editor';
import {
  DripIconButton,
  Loader,
  LoaderWrapper,
  MioDatePicker,
} from '../../../components';
import moment from 'moment';
import { get, isEmpty, map, snakeCase } from 'lodash';
import styled from 'styled-components';
import StyledTooltip from '../../../components/Tooltip';
import { useHistory } from 'react-router-dom';
import { FilterRowChips } from '../../DripCampaignContainer/DripDashboardFilter/DripDashboardFilterPopup/DripDashboardFilterPopup.styles';
import {
  RecipientsListBody,
  RecipientsListWrapper,
} from '../../DripCampaignContainer/SetObjectives/SetObjectives.styles';
import { WhiteCircle } from '../../../components/StepperV2/Stepper.styles';
import StepsFooterV2 from './StepsFooterV2';
import { getCompanyData } from '../../../services/campaign';
import {
  CheckBox,
  CheckBoxLabel,
  CheckBoxSpan,
  ContactListBody,
  ContactsListWrapper,
  SearchContainer,
} from '../../ConfigureContainer/EmailList/EmailList.styles';
import AutoPostSearchBar from '../../DripCampaignContainerV2/SetObjectivesV2/AutoPostSearchBar/AutoPostSearchBar';
import { ToggleButton } from '../../ConfigureContainer/screenConfigure.styles';
import {
  NewTooltip,
  SocialTabItem,
  TabContainer,
} from '../../DripCampaignContainerV2/SetObjectivesV2/SetObjectivesV2.styles';
import CreateSegment from '../../ConfigureContainer/EmailList/CreateSegment';
import ModalContainer from '../../../componentsV2/ModalContainer';

const DateTimeWrapper = styled(Flex)`
  background-color: hsl(0, 0%, 100%);
  border-color: hsl(0, 0%, 80%);
  border-radius: 4px;
  margin: 0 auto;
  padding: 2px 8px;
`;

const DateTimeText = styled.div`
  width: 85%;
  font-size: 0.9rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const getSelectStyles = (valid) => ({
  container: (base) => ({
    ...base,
    width: '100%',
  }),
  control: (base) => ({
    ...base,
    height: '32px',
    minHeight: '32px',
    border: valid ? '1px solid #8493A6' : '1px solid #FF0000',
  }),
  dropdownIndicator: (base) => ({
    ...base,
    paddingTop: 0,
    paddingBottom: 0,
    color: '#848484',
  }),
});

const NEWSLETTER_FREQUENCIES = [
  {
    label: 'Weekly',
    value: 'weekly',
  },
  {
    label: 'Monthly',
    value: 'monthly',
  },
  {
    label: 'Quarterly',
    value: 'quarterly',
  },
];

const SelectTarget = ({
  automatedCampaignDetails,
  targetInfo,
  campaignSetState,
  campaignAction,
  currentStepIndex,
  setCurrentStepIndex,
  userData,
  userRole,
  isEditMode,
  socialNetworkData,
  setObjectivesTabData,
  endCreateFlow,
  segmentList,
  setSegmentList,
  getAccountId,
  campaignSeriesName,
  campaignPurpose,
  setCampaignPurpose,
  setCampaignSeriesName,
  onSaveAsDraftClick,
  isEnterpriseLevelAdmin,
  isFirmLevelAdmin,
  isEnterpriseAdvisor,
  fetchSegments,
  segmentsLoading,
  isAdmin
}) => {
  // Component Utils
  console.log('user data main--->', userData);
  const history = useHistory();
  const isEventType = campaignAction === AUTOMATED_CAMPAIGN_ACTIONS.EVENT;
  const isProspectType =
    campaignAction === AUTOMATED_CAMPAIGN_ACTIONS.NURTURE_PROSPECTS;
  const isClientType =
    campaignAction === AUTOMATED_CAMPAIGN_ACTIONS.WELCOME_CLIENTS;
  const isNewsletterType =
    campaignAction === AUTOMATED_CAMPAIGN_ACTIONS.NEWSLETTER;

  // States
  const [ccList, setCCList] = useState('');

  const [targetPage, setTargetPage] = useState(null);
  const [openSegmentModal, setOpenSegmentModal] = useState(false);
  
  const [newsletterFrequency, setNewsletterFrequency] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [eventDate, setEventDate] = useState(null);
  const [isCascaded, setIsCascaded] = useState(false);
  const [convertedTag, setConvertedTag] = useState(
    isEventType
      ? `registered_${snakeCase(campaignSeriesName)}`
      : 'lead_captured'
  );
  const [isDatepickerOpen, setIsDatepickerOpen] = useState(false);
  const [targetSegmentOptions, setTargetSegmentOptions] = useState([
    {
      label: '',
      value: '',
    },
  ]);
  const [targetPageOptions, setTargetPageOptions] = useState([
    {
      label: '',
      value: '',
    },
  ]);

  const [requestLoading, setRequestLoading] = useState(false);
  const [subscriptionTypeOptions, setSubscriptionTypeOptions] = useState([]);
  const [selectSubscriptionType, setSelectSubscriptionType] = useState(
    isEventType ? 'events' : null
  );
  const [campaignNameValid, setCampaignNameValid] = useState(true);
  const [segmentListValid, setSegmentListValid] = useState(true);
  const [eventPageValid, setEventPageValid] = useState(true);
  const [subscriptionValid, setSubscriptionValid] = useState(true);
  const [eventDateValid, setEventDateValid] = useState(true);
  const [acAutoPostDataValid, setAcAutoPostDataValid] = useState(true);
  const [showSubscription, setShowSubscription] = useState(false);

  const [selectedTwContacts, setSelectedTwContacts] = useState([]);
  const [socialListValid, setSocialListValid] = useState(true);
  const [selectedLnContacts, setSelectedLnContacts] = useState([]);
  const [ascLnOrder, setAscLnOrder] = useState(false);
  const [selectedFbContacts, setSelectedFbContacts] = useState([]);
  const [ascFbOrder, setAscFbOrder] = useState(false);
  const [selectedSegmentList, setSelectedSegmentList] = useState([]);
  const [segmentListLoader, setSegmentListLoader] = useState(false);

  const [loader, setLoader] = useState(false);

  const [ACToggleTabs, setACToggleTabs] = useState('advisor');
  const [selectedACAdvisorData, setSelectedACAdvisorData] = useState([]);
  const [selectedACAdminData, setSelectedACAdminData] = useState([]);
  const [selectedACFirmData, setSelectedACFirmData] = useState([]);
  const [ACAdvisorData, setACAdvisorData] = useState([]);
  const [ACAdminData, setACAdminData] = useState([]);
  const [ACFirmData, setACFirmData] = useState([]);
  const [ACLoader, setACLoader] = useState(false);
  const [ACSearch, setACSearch] = useState('');
  const [ACSearchBox, setACSearchBox] = useState('');
  const [ACSearchResult, setACSearchResult] = useState('');
  const [openSearch, setOpenSearch] = useState(false);
  const [ACToggleAutoPost, setACToggleAutoPost] = useState(false);
  const [showACAutoPostList, setShowACAutoPostList] = useState(false);

  const mappingDropdownValues = {
    general_newsletters: 'Newsletters',
    ecards: 'E-cards',
    events: 'Event Updates',
    firm_updates: 'Firm Updates',
  };

  const convertDateToRequiredFormat = ({ date, format = 'YYYY-MM-DD' }) =>
    moment(date).format(format);

  const getNewOptionValue = (value) =>
    value ? value : { label: '', value: '' };

  const shouldDisableNext = () => {
    let cascaded = get(targetInfo, 'is_cascaded', 'false');
    const { parent_platforms } = targetInfo;
    if (!campaignSeriesName) {
      setCampaignNameValid(false);
    } else {
      setCampaignNameValid(true);
    }

    // if (!isEventType && !startDate) {
    //   setStartDateValid(false);
    // } else {
    //   setStartDateValid(true);
    // }

    if (!isClientType && !targetPage) {
      setEventPageValid(false);
    } else {
      setEventPageValid(true);
    }

    if (!showSubscription || (!selectSubscriptionType && showSubscription)) {
      setSubscriptionValid(false);
    } else {
      setSubscriptionValid(true);
    }

    if (isEventType && !eventDate) {
      setEventDateValid(false);
    } else {
      setEventDateValid(true);
    }

    if (!isEventType && isEmpty(selectedSegmentList)) {
      setSegmentListValid(false);
    } else {
      setSegmentListValid(true);
    }

    if (isEventType && (!parent_platforms || isEmpty(parent_platforms))) {
      if (
        isEmpty(selectedFbContacts) &&
        isEmpty(selectedLnContacts) &&
        isEmpty(selectedTwContacts) &&
        isEmpty(selectedSegmentList)
      ) {
        setSegmentListValid(false);
      } else {
        setSegmentListValid(true);
      }
    }

    let platformMapper = {
      em: selectedSegmentList,
      fb: selectedFbContacts,
      tw: selectedTwContacts,
      ln: selectedLnContacts,
    };

    let validArray = [];

    if (isEventType && parent_platforms && !isEmpty(parent_platforms)) {
      parent_platforms.forEach((item) => {
        if (isEmpty(platformMapper[item])) {
          validArray = [...validArray, true];
        }
      });

      if (!isEmpty(validArray)) {
        setSegmentListValid(false);
      } else {
        setSegmentListValid(true);
      }
    }

    // if (ACToggleAutoPost === 'true') {
    //   if (
    //     isEmpty(selectedACAdvisorData) &&
    //     isEmpty(selectedACAdminData) &&
    //     isEmpty(selectedACFirmData)
    //     ) {
    //       setAcAutoPostDataValid(false)
    //     } else {
    //       setAcAutoPostDataValid(true)
    //     }
    // }

    return (
      !campaignSeriesName ||
      (!isEventType && isEmpty(selectedSegmentList)) ||
      (isNewsletterType && !newsletterFrequency) ||
      (isEventType && !targetPage) ||
      (isProspectType && !targetPage) ||
      (isEventType && !eventDate) ||
      (showSubscription && !selectSubscriptionType) ||
      (isEventType &&
        (!parent_platforms || isEmpty(parent_platforms)) &&
        isEmpty(selectedFbContacts) &&
        isEmpty(selectedLnContacts) &&
        isEmpty(selectedTwContacts) &&
        isEmpty(selectedSegmentList)) ||
      (isEventType &&
        parent_platforms &&
        !isEmpty(parent_platforms) &&
        validArray.includes(true)) 
        // ||
        // (ACToggleAutoPost === 'true' &&
        // isEmpty(selectedACAdvisorData) &&
        // isEmpty(selectedACAdminData) &&
        // isEmpty(selectedACFirmData))
    );
  };

  const getCampaignInfo = () => {
    if (isEventType)
      return {
        name: 'Event Promotion',
        purpose: 'Promote and invite contacts to your event',
        targetPagePlaceholder: 'Choose a campaign page for this event',
      };
    if (isProspectType)
      return {
        name: 'Nurture Prospects',
        purpose: 'Engage new prospects.',
        targetPagePlaceholder: 'My Campaign Page for Prospects',
      };
    if (isClientType)
      return {
        name: 'Welcome New Clients',
        purpose: 'Send a series of communications to new clients.',
        targetPagePlaceholder: 'Choose a campaign page',
      };
    if (isNewsletterType)
      return {
        name: 'Newsletter Series',
        purpose: 'Send newsletters to contacts',
        targetPagePlaceholder: 'Choose a frequency',
      };

    return { name: '', purpose: '' };
  };

  const fetchLandingPages = async () => {
    setRequestLoading(true);
    try {
      const { data = [] } = (await getLandingPages('campaign')) || {};
      campaignSetState({ landingPages: data });
      setTargetPageOptions(
        data.map((page) => ({
          label: page.name,
          value: page.id || page._id,
        }))
      );
    } catch (error) {
      console.log('Error fetching Landing pages ', error);
    } finally {
      setRequestLoading(false);
    }
  };

  const fetchTargetSegments = async () => {
    setRequestLoading(true);
    try {
      const {
        result: { data },
      } = await getTargetSegments();
      campaignSetState({ segmentList: data });
      setTargetSegmentOptions(
        data.map((page) => ({
          label: page.name,
          value: page.id,
        }))
      );
    } catch (error) {
      console.log('Error fetching target segments ', error);
    } finally {
      setRequestLoading(false);
    }
  };

  const prefillTargetPage = ({ target_page }) => {
    setRequestLoading(true);
    if (!target_page.name && targetPageOptions) {
      // Get the name from Existing Landing Pages fetched from API.
      const filteredName =
        targetPageOptions.find((page) => page.value === target_page.id)
          ?.label || '';
      setTargetPage(
        getNewOptionValue({
          label: filteredName,
          value: target_page.id,
        })
      );
    } else {
      setTargetPage(
        getNewOptionValue({
          label: target_page.name,
          value: target_page.id,
        })
      );
    }
    setRequestLoading(false);
  };

  const brewTargetRequestBody = () => {
    let fbLists = [],
      twLists = [],
      lnLists = [];

    twLists = !isEmpty(selectedTwContacts)
      ? selectedTwContacts.map((item, index) => {
          return {
            destination_id: item,
            snetwork_act_id: getAccountId('tw'),
          };
        })
      : [];

    fbLists = !isEmpty(selectedFbContacts)
      ? selectedFbContacts.map((item, index) => {
          return {
            destination_id: item,
            snetwork_act_id: getAccountId('fb'),
          };
        })
      : [];

    lnLists = !isEmpty(selectedLnContacts)
      ? selectedLnContacts.map((item, index) => {
          return {
            destination_id: item,
            snetwork_act_id: getAccountId('ln', item),
          };
        })
      : [];

    let payload = {
      type: campaignAction,
      name: campaignSeriesName,
      purpose: campaignPurpose,
      target_page_id: (targetPage && targetPage.value) || '', // value -> ID. TODO: Find better way.
      ...(!isEmpty(selectedSegmentList) && {
        segment_id: selectedSegmentList[0].id, // value -> ID. TODO: Find better way.
      }),
      cc_email_addresses: ccList,
      converted_tag: convertedTag && targetPage ? convertedTag : '',
      event_date: eventDate
        ? convertDateToRequiredFormat({ date: eventDate })
        : null,
      start_date: startDate
        ? convertDateToRequiredFormat({ date: startDate })
        : null,
      is_cascaded: get(targetInfo, 'is_cascaded', 'false'),
      is_global_compliance: get(targetInfo, 'is_global_compliance', 'false'),
      ...(selectSubscriptionType &&
        showSubscription && {
          subscription_type: selectSubscriptionType,
        }),
      ...((!isEmpty(lnLists) || !isEmpty(fbLists) || !isEmpty(twLists)) && {
        social_pages: {
          ...(!isEmpty(fbLists) && {
            facebook: fbLists,
          }),
          ...(!isEmpty(twLists) && {
            twitter: twLists,
          }),
          ...(!isEmpty(lnLists) && {
            linkedin: lnLists,
          }),
        },
      }),
    };

    return payload;

    // ...(isNewsletterType ? { newsletterFrequency } : {}),
    // ...(isEventType ? { event_date: eventDate } : { start_date: startDate }),
  };

  const submitTargetInfo = async ({
    forSaveAndClose = false,
    forSaveAsDraft = false,
  }) => {
    const validation = shouldDisableNext();
    console.log('validation--->', validation);
    if (validation) {
      return;
    }
    window.sessionStorage.removeItem('segment_id');
    setLoader(true);
    try {
      let response;
      if (targetInfo) {
        // Update API
        response = await updateAutomatedCampaign({
          campaignId: automatedCampaignDetails.campaignId,
          payload: brewTargetRequestBody(),
        });
      } else {
        // Create API
        response = await createAutomatedCampaign({
          payload: brewTargetRequestBody(),
        });
      }
      campaignSetState({
        automatedCampaigns: {
          ...automatedCampaignDetails,
          activeStepIndex: 1,
          campaignId: response.data.ac_campaign_id,
          targetInfo: brewTargetRequestBody(),
        },
      });
      if (!forSaveAndClose) {
        setCurrentStepIndex(currentStepIndex + 1);
      } else if (!forSaveAsDraft) {
        onSaveAsDraftClick();
      }
    } catch (error) {
      console.error(
        'Something went wrong while creating a campaign for you. ',
        error
      );
    } finally {
      setLoader(false);
    }
  };

  // useEffect(() => {
  //   fetchCompanyData();
  // }, [ACToggleTabs]);

  useEffect(() => {
    fetchLandingPages();
    if (!targetInfo) {
      fetchTargetSegments();
      const { name, purpose } = getCampaignInfo();
      setCampaignSeriesName(name);
      setCampaignPurpose(purpose);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isEventType && campaignSeriesName)
      setConvertedTag(`registered_${snakeCase(campaignSeriesName)}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaignSeriesName]);

  useEffect(() => {
    if (targetInfo && targetInfo?.target_page) {
      prefillTargetPage({ target_page: targetInfo.target_page });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [targetPageOptions, targetInfo]);

  useEffect(() => {
    if(!isEmpty(segmentList) && window.sessionStorage.getItem('redirect_type') === 'nurture' && window.sessionStorage.getItem('segment_id')) {
      let filterSegment = segmentList.filter(s => s.id === window.sessionStorage.getItem('segment_id'));
      if(!isEmpty(filterSegment)) {
        setSelectedSegmentList(filterSegment);
      } else {
        setSelectedSegmentList([segmentList[0]])
      }
      
    }
  }, [segmentList])
  

  useEffect(() => {
    if (targetInfo) {
      // fetchLandingPages();
      const {
        name,
        purpose,
        segment_id,
        target_page,
        start_date,
        event_date,
        cc_email_addresses: ccList,
        converted_tag,
        subscription_type,
        social_pages,
      } = targetInfo || {};
      setCampaignSeriesName(name);
      setCampaignPurpose(purpose);

      if (social_pages) {
        if (social_pages?.facebook) {
          setSelectedFbContacts(
            social_pages?.facebook.map((item) => item.destination_id)
          );
        }

        if (social_pages?.twitter) {
          setSelectedTwContacts(
            social_pages?.twitter.map((item) => item.destination_id)
          );
        }

        if (social_pages?.linkedin) {
          setSelectedLnContacts(
            social_pages?.linkedin.map((item) => item.destination_id)
          );
        }
      }

      if (segment_id) {
        setSelectedSegmentList(segmentList.filter((s) => s.id === segment_id));
      }
      if (target_page) {
        prefillTargetPage({ target_page });
      }

      if (subscription_type) {
        setSelectSubscriptionType(subscription_type);
      }

      if (start_date) setStartDate(start_date);
      if (event_date) setEventDate(event_date);
      if (converted_tag) setConvertedTag(converted_tag);
      if (ccList) setCCList(ccList);
      setIsCascaded(get(targetInfo, 'is_cascaded', 'false') === 'true');
      // setIsCascaded(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [targetInfo]);

  useEffect(() => {
    let subscriptionOptions = [];
    if (get(userData, 'details.company.marketing_pref_settings', {})) {
      if (!isEventType) {
        if (
          get(
            userData,
            'details.company.marketing_pref_settings.general_newsletters',
            'false'
          ) === 'true'
        ) {
          subscriptionOptions.push({
            value: 'general_newsletters',
            label: 'Newsletters',
            description:
              'Insights and commentary on the markets, economy, financial planning and other relevant topics.',
          });
        }

        if (
          get(
            userData,
            'details.company.marketing_pref_settings.firm_updates',
            'false'
          ) === 'true'
        ) {
          subscriptionOptions.push({
            value: 'firm_updates',
            label: 'Firm Updates',
            description:
              'Updates about our business such as personnel changes and office closures.',
          });
        }
        if (
          get(
            userData,
            'details.company.marketing_pref_settings.ecards',
            'false'
          ) === 'true'
        ) {
          subscriptionOptions.push({
            value: 'ecards',
            label: 'E-cards',
            description:
              'Well-wishes for birthdays, holidays and other special occasions.',
          });
        }
        if (
          get(
            userData,
            'details.company.marketing_pref_settings.events',
            'false'
          ) === 'true'
        ) {
          subscriptionOptions.push({
            value: 'events',
            label: 'Event Updates',
            description:
              'Invitations and related communications for in-person and virtual webcast events.',
          });
        }
      } else {
        if (
          get(
            userData,
            'details.company.marketing_pref_settings.events',
            'false'
          ) === 'true'
        ) {
          subscriptionOptions.push({
            value: 'events',
            label: 'Event Updates',
            description:
              'Invitations and related communications for in-person and virtual webcast events.',
          });
        }
      }

      // subscriptionOptions.push({
      //   value: 'general_marketing',
      //   label: 'All Digital Marketing Communications'
      // });
      setSubscriptionTypeOptions(subscriptionOptions);
      if (
        get(
          userData,
          'details.company.marketing_pref_settings.general_newsletters',
          'false'
        ) === 'true' ||
        get(
          userData,
          'details.company.marketing_pref_settings.ecards',
          'false'
        ) === 'true' ||
        get(
          userData,
          'details.company.marketing_pref_settings.ecards',
          'false'
        ) === 'true' ||
        get(
          userData,
          'details.company.marketing_pref_settings.events',
          'false'
        ) === 'true'
      ) {
        if (
          isEventType &&
          get(
            userData,
            'details.company.marketing_pref_settings.events',
            'false'
          ) === 'true'
          && !isEmpty(selectedSegmentList)
        ) {
          setShowSubscription(true);
        } else if (!isEventType && !isEmpty(selectedSegmentList)) {
          setShowSubscription(true);
        } else {
          setShowSubscription(false);
        }
      }
    }
  }, [selectedSegmentList]);

  const handleSelectAll = (platform) => {
    if (platform === 'fb' && !isEmpty(get(socialNetworkData, 'fb', []))) {
      if (
        Object.keys(get(socialNetworkData, 'fb[0].page', [])).length + 1 ===
        selectedFbContacts.length
      ) {
        setSelectedFbContacts([]);
      } else {
        setSelectedFbContacts([
          ...Object.keys(get(socialNetworkData, 'fb[0].page', [])),
        ]);
      }
    }

    if (platform === 'ln' && !isEmpty(get(socialNetworkData, 'ln', []))) {
      console.log(Object.keys(get(socialNetworkData, 'ln[0].page', [])));
      if (
        Object.keys(get(socialNetworkData, 'ln[0].page', [])).length + 1 ===
        selectedLnContacts.length
      ) {
        setSelectedLnContacts([]);
      } else {
        setSelectedLnContacts([
          0,
          ...Object.keys(get(socialNetworkData, 'ln[0].page', [])),
        ]);
      }
    }
  };

  const handleCheckboxClick = (item, type) => {
    if (type === 'ln') {
      if (isEmpty(selectedLnContacts)) {
        setSelectedLnContacts([...selectedLnContacts, item]);
      } else if (
        !isEmpty(selectedLnContacts) &&
        isEmpty(selectedLnContacts.filter((s) => s === item))
      ) {
        setSelectedLnContacts([...selectedLnContacts, item]);
      } else {
        let removeItems = selectedLnContacts.filter((s) => s !== item);
        setSelectedLnContacts(removeItems);
      }
    } else if (type === 'tw') {
      if (isEmpty(selectedTwContacts)) {
        setSelectedTwContacts([...selectedTwContacts, item]);
      } else if (
        !isEmpty(selectedTwContacts) &&
        isEmpty(selectedTwContacts.filter((s) => s === item))
      ) {
        setSelectedTwContacts([...selectedTwContacts, item]);
      } else {
        let removeItems = selectedTwContacts.filter((s) => s !== item);
        setSelectedTwContacts(removeItems);
      }
    } else {
      if (isEmpty(selectedFbContacts)) {
        setSelectedFbContacts([...selectedFbContacts, item]);
      } else if (
        !isEmpty(selectedFbContacts) &&
        isEmpty(selectedFbContacts.filter((s) => s === item))
      ) {
        setSelectedFbContacts([...selectedFbContacts, item]);
      } else {
        let removeItems = selectedFbContacts.filter((s) => s !== item);
        setSelectedFbContacts(removeItems);
      }
    }
  };

  const redirectToConfigure = (platform) => {
    if(isAdmin) {
      window.sessionStorage.setItem('selectedIndex', 2);
      history.push('/admin/configure');
    } else {
      window.sessionStorage.setItem('selectedIndex', 1);
      history.push('/configure');
    }
    
    endCreateFlow();
  };

  const handleSegmentCheckboxClick = (item) => {
    setSelectedSegmentList([item]);
    if(!item) {
      setShowSubscription(false);
    }
    // USE BELOW CODE LATER
    // if (isEmpty(selectedSegmentList)) {
    //   setSelectedSegmentList([...selectedSegmentList, item]);
    // } else if (
    //   !isEmpty(selectedSegmentList) &&
    //   isEmpty(selectedSegmentList.filter((s) => s.id === item.id))
    // ) {
    //   setSelectedSegmentList([...selectedSegmentList, item]);
    // } else {
    //   let removeItems = selectedSegmentList.filter((s) => s.id !== item.id);
    //   setSelectedSegmentList(removeItems);
    // }
  };

  const segmentListBox = () => {
    if (
      get(targetInfo, 'is_cascaded', 'false') === 'false' ||
      (get(targetInfo, 'is_cascaded', 'false') === 'true' &&
        targetInfo?.parent_platforms?.includes('em'))
    ) {
      return (
        <Flex
          flexDirection="column"
          width={isEventType ? '300px' : '35%'}
          marginRight="20px"
        >
          {!isEmpty(get(socialNetworkData, 'em', [])) && (
            <Flex
              justifyContent="space-between"
              fontSize="14px"
              color="#000000"
              width="100%"
              marginLeft="3px"
            >
              <Flex
                display="flex"
                justifyContent="space-between"
                flexDirection="row"
                width="100%"
              >
                <Flex
                  display="flex"
                  justifyContent="flex-start"
                  alignItems="center"
                  padding="2px 0px"
                  style={{
                    color: '#101828',
                    fontSize: '14px',
                  }}
                >
                  Segments
                </Flex>
                <Flex
                  display="flex"
                  justifyContent="flex-end"
                  alignItems="center"
                  padding="2px 8px 0px 0px"
                  style={{
                    color: '#0B5CD7',
                    fontSize: '12px',
                    cursor: 'pointer'
                  }}
                  onClick={() => {
                    setOpenSegmentModal(true);
                  }}
                >
                  <img
                    alt="plus-icon"
                    src="/assets/images/Campaign/color-plus-icon.svg"
                    style={{ height: '16px' }}
                  />
                  &nbsp;Create New Segment
                </Flex>
              </Flex>
            </Flex>
          )}
          <RecipientsListWrapper
            border={
              segmentListValid ? '1px solid #bccdd4' : '1px solid #FF0000'
            }
            padding="0px"
          >
            <LoaderWrapper isLoading={segmentsLoading}>
              {segmentList?.length > 0 &&
              !isEmpty(get(socialNetworkData, 'em', [])) ? (
                <RecipientsListBody height="168px">
                  {segmentList.map((item, index) => (
                    <>
                      <FilterRowChips
                        style={{
                          fontSize: '12px',
                          padding: '5px 7px',
                        }}
                      >
                        <label
                          className="checkbox-button"
                          style={{
                            cursor: 'pointer',
                          }}
                        >
                          <input
                            type="checkbox"
                            className="checkbox-button__input"
                            name="draft"
                            id={`segment_${item.name}`}
                            onChange={() => handleSegmentCheckboxClick(item)}
                            checked={
                              !isEmpty(selectedSegmentList) &&
                              selectedSegmentList.filter(
                                (s) => s.id === item.id
                              ).length > 0
                            }
                            value={item.name}
                          />
                          <span
                            className="checkbox-button__control"
                            style={{
                              cursor: 'pointer',
                            }}
                          ></span>
                          <span
                            className="checkbox-button__label"
                            style={{
                              color: '#49484a',
                              fontWeight: '100',
                              cursor: 'pointer',
                            }}
                          >
                            {item.name}
                          </span>
                        </label>
                      </FilterRowChips>
                    </>
                  ))}
                </RecipientsListBody>
              ) : (
                <Flex
                  height="100%"
                  justifyContent="center"
                  width="100%"
                  fontSize="12px"
                  alignItems="center"
                  style={{
                    cursor: 'pointer',
                  }}
                  onClick={() =>
                    isEmpty(segmentList) ?
                    setOpenSegmentModal(true) : redirectToConfigure('em')
                  }
                >
                  {isEmpty(segmentList) ? 'Create a segment in your Contacts section' : 'Please Connect to Email'}
                </Flex>
              )}
            </LoaderWrapper>
          </RecipientsListWrapper>
        </Flex>
      );
    } else {
      return null;
    }
  };

  const sortSegmentList = (isAscending) => {
    setSegmentListLoader(true);
    var sortedSegmentArr = segmentList.sort((a, b) => {
      if (a.email < b.email) {
        return -1;
      }
      if (a.email > b.email) {
        return 1;
      }
      return 0;
    });
    if (!isAscending) {
      sortedSegmentArr.reverse();
    }
    setSegmentList(sortedSegmentArr);
    setTimeout(() => setSegmentListLoader(false), 200);
  };

  // const handleACAutoPostToggle = (checked) => {
  //   if (checked) {
  //     setACToggleAutoPost('true');
  //     setShowACAutoPostList(true);
  //   } else {
  //     setACToggleAutoPost('false');
  //     setShowACAutoPostList(false);
  //   }
  // };

  // const ACTargetTabs = () => {
  //   return (
  //     <TabContainer style={{ width: '100%' }}>
  //       <SocialTabItem
  //         onClick={() => {
  //           setACToggleTabs('advisor');
  //           setOpenSearch(false);
  //           fetchCompanyData();
  //           setACSearch('');
  //           setACSearchResult('');
  //         }}
  //         active={ACToggleTabs === 'advisor'}
  //       >
  //         <div className="chip" style={{ fontSize: '14px' }}>
  //           Advisors
  //         </div>
  //       </SocialTabItem>
  //       {isEnterpriseLevelAdmin && !isFirmLevelAdmin && (
  //         <>
  //           <SocialTabItem
  //             onClick={() => {
  //               setACToggleTabs('admin');
  //               fetchCompanyData();
  //               setOpenSearch(false);
  //               setACSearch('');
  //               setACSearchBox('');
  //             }}
  //             active={ACToggleTabs === 'admin'}
  //           >
  //             <div className="chip" style={{ fontSize: '14px' }}>
  //               Administrators
  //             </div>
  //           </SocialTabItem>
  //           <SocialTabItem
  //             onClick={() => {
  //               setACToggleTabs('firm');
  //               fetchCompanyData();
  //               setOpenSearch(false);
  //               setACSearchBox('');
  //               setACSearchResult('');
  //             }}
  //             active={ACToggleTabs === 'firm'}
  //           >
  //             <div className="chip" style={{ fontSize: '14px' }}>
  //               Firms
  //             </div>
  //           </SocialTabItem>
  //         </>
  //       )}
  //     </TabContainer>
  //   );
  // };

  // const handleAPSelectAll = (e) => {
  //   console.log('clicked all list name');
  //   if (ACToggleTabs === 'advisor') {
  //     if (e.target.checked) {
  //       setSelectedACAdvisorData([...eligibleACAdvisorData]);
  //     } else {
  //       setSelectedACAdvisorData([]);
  //     }
  //   } else if (ACToggleTabs === 'admin') {
  //     if (e.target.checked) {
  //       setSelectedACAdminData([...eligibleACAdminData]);
  //     } else {
  //       setSelectedACAdminData([]);
  //     }
  //   } else {
  //     if (e.target.checked) {
  //       setSelectedACFirmData([...eligibleACFirmData]);
  //     } else {
  //       setSelectedACFirmData([]);
  //     }
  //   }
  // };

  // const handleCheckBoxChange = (item, type) => {
  //   console.log('single checkbox clicked');
  //   if (type === 'advisor') {
  //     if (isEmpty(selectedACAdvisorData)) {
  //       setSelectedACAdvisorData([...selectedACAdvisorData, item]);
  //     } else if (
  //       !isEmpty(selectedACAdvisorData) &&
  //       isEmpty(selectedACAdvisorData.filter((l) => l.id === item.id))
  //     ) {
  //       setSelectedACAdvisorData([...selectedACAdvisorData, item]);
  //       console.log('set email check second loop--->', selectedACAdvisorData);
  //     } else {
  //       let discardItems = selectedACAdvisorData.filter(
  //         (l) => l.id !== item.id
  //       );
  //       setSelectedACAdvisorData(discardItems);
  //     }
  //   } else if (type === 'admin') {
  //     if (isEmpty(selectedACAdminData)) {
  //       setSelectedACAdminData([...selectedACAdminData, item]);
  //     } else if (
  //       !isEmpty(selectedACAdminData) &&
  //       isEmpty(selectedACAdminData.filter((l) => l.id === item.id))
  //     ) {
  //       setSelectedACAdminData([...selectedACAdminData, item]);
  //       console.log('set email check second loop--->', selectedACAdminData);
  //     } else {
  //       let discardItems = selectedACAdminData.filter((l) => l.id !== item.id);
  //       setSelectedACAdminData(discardItems);
  //     }
  //   } else {
  //     if (isEmpty(selectedACFirmData)) {
  //       setSelectedACFirmData([...selectedACFirmData, item]);
  //     } else if (
  //       !isEmpty(selectedACFirmData) &&
  //       isEmpty(selectedACFirmData.filter((l) => l.id === item.id))
  //     ) {
  //       setSelectedACFirmData([...selectedACFirmData, item]);
  //       console.log('set email check second loop--->', selectedACFirmData);
  //     } else {
  //       let discardItems = selectedACFirmData.filter((l) => l.id !== item.id);
  //       setSelectedACFirmData(discardItems);
  //     }
  //   }
  // };

  // const fetchCompanyData = () => {
  //   setACLoader(true);
  //   getCompanyData({
  //     companyId: userData?.details?.user?.company,
  //     type: ACToggleTabs,
  //   })
  //     .then((res) => {
  //       console.log('response---->', res);
  //       if (res) {
  //         if (ACToggleTabs === 'advisor') {
  //           setACAdvisorData(res?.data);
  //         } else if (ACToggleTabs === 'admin') {
  //           setACAdminData(res?.data);
  //         } else {
  //           setACFirmData(res?.data);
  //         }
  //       }
  //       setACLoader(false);
  //     })
  //     .catch((err) => {
  //       console.log('ERROR--->', err);
  //       setACLoader(false);
  //     });
  // };

  const eligibleACAdvisorData = ACAdvisorData.filter((item) => (item.auto_post_eligibility.is_default_contactlist) && (item.auto_post_eligibility.is_token) === true)
  const eligibleACAdminData = ACAdminData.filter((item) => (item.auto_post_eligibility.is_default_contactlist) && (item.auto_post_eligibility.is_token) === true)
  const eligibleACFirmData = ACFirmData.filter((item) => (item.eligible_advisor_count > 0) && (item.eligible_advisor_count > 0))

  const totalContactsCount = [...selectedACAdvisorData, ...selectedACAdminData, ...selectedACFirmData];
  console.log("total count--->", totalContactsCount.length)

  // const handleUnselectAll = () => {
  //   console.log("unselect clicked")
  //     setSelectedACAdvisorData([]);
  //     setSelectedACAdminData([]);
  //     setSelectedACFirmData([]);
  // };

  // const acAdvisorEligibilityInfo = () => {
  //   return (
  //     <Flex
  //       style={{
  //         display: 'flex',
  //         flexDirection: 'column',
  //         width: '250px',
  //         height: '120px',
  //         maxHeight: '150px',
  //         backgroundColor: '#816EF8',
  //         border: '1px solid #816EF8',
  //         borderRadius: '8px',
  //         padding: '10px',
  //         color: '#FFFFFF',
  //       }}
  //     >
  //       <Flex dislay="flex" width="100%">
  //       <span style={{ fontSize: '14px' }}>
  //       Advisors eligible for auto-post
  //       </span>
  //       </Flex>
  //       <hr style={{ color: 'white', width: '100%' }} />
  //       <Flex display="flex" width="100%" style={{ flexDirection: 'column' }}>
  //         <Flex display="flex" width="100%">
  //           <div
  //             style={{
  //               display: 'flex',
  //               flexDirection: 'row',
  //               width: '100%',
  //               padding: '3px 0px 5px',
  //               marginBottom: '8px',
  //             }}
  //           >
  //             <img
  //               alt=""
  //               src="/assets/images/checked-icon.svg"
  //               style={{ marginRight: '6px' }}
  //             />
  //             <span
  //               style={{
  //                 display: 'flex',
  //                 justifyContent: 'center',
  //                 alignItems: 'center',
  //                 fontSize: '14px',
  //               }}
  //             >
  //               Email Service Connected
  //             </span>
  //           </div>
  //         </Flex>
  //         <Flex display="flex" width="100%">
  //           <div
  //             style={{
  //               display: 'flex',
  //               flexDirection: 'row',
  //               width: '100%',
  //               padding: '3px 0px 5px',
  //             }}
  //           >
  //             <img
  //               alt=""
  //               src="/assets/images/checked-icon.svg"
  //               style={{ marginRight: '6px' }}
  //             />
  //             <span
  //               style={{
  //                 display: 'flex',
  //                 justifyContent: 'center',
  //                 alignItems: 'center',
  //                 fontSize: '14px',
  //               }}
  //             >
  //               Default List Created
  //             </span>
  //           </div>
  //         </Flex>
  //       </Flex>
  //     </Flex>
  //   );
  // };

  // const acAdminEligibilityInfo = () => {
  //   return (
  //     <Flex
  //       style={{
  //         display: 'flex',
  //         flexDirection: 'column',
  //         width: '250px',
  //         height: '120px',
  //         maxHeight: '150px',
  //         backgroundColor: '#816EF8',
  //         border: '1px solid #816EF8',
  //         borderRadius: '8px',
  //         padding: '10px',
  //         color: '#FFFFFF',
  //       }}
  //     >
  //       <Flex dislay="flex" width="100%">
  //       <span style={{ fontSize: '14px' }}>
  //         Administrators eligible for auto-post
  //       </span> 
  //       </Flex>
  //       <hr style={{ color: 'white', width: '100%' }} />
  //       <Flex display="flex" width="100%" style={{ flexDirection: 'column' }}>
  //         <Flex display="flex" width="100%">
  //           <div
  //             style={{
  //               display: 'flex',
  //               flexDirection: 'row',
  //               width: '100%',
  //               padding: '3px 0px 5px',
  //               marginBottom: '8px',
  //             }}
  //           >
  //             <img
  //               alt=""
  //               src="/assets/images/checked-icon.svg"
  //               style={{ marginRight: '6px' }}
  //             />
  //             <span
  //               style={{
  //                 display: 'flex',
  //                 justifyContent: 'center',
  //                 alignItems: 'center',
  //                 fontSize: '14px',
  //               }}
  //             >
  //               Email Service Connected
  //             </span>
  //           </div>
  //         </Flex>
  //         <Flex display="flex" width="100%">
  //           <div
  //             style={{
  //               display: 'flex',
  //               flexDirection: 'row',
  //               width: '100%',
  //               padding: '3px 0px 5px',
  //             }}
  //           >
  //             <img
  //               alt=""
  //               src="/assets/images/checked-icon.svg"
  //               style={{ marginRight: '6px' }}
  //             />
  //             <span
  //               style={{
  //                 display: 'flex',
  //                 justifyContent: 'center',
  //                 alignItems: 'center',
  //                 fontSize: '14px',
  //               }}
  //             >
  //               Default List Created
  //             </span>
  //           </div>
  //         </Flex>
  //       </Flex>
  //     </Flex>
  //   );
  // };

  // const tooltipEligibleBody = (item) => {
  //   return (
  //     <Flex
  //       style={{
  //         display: 'flex',
  //         flexDirection: 'column',
  //         width: '230px',
  //         height: '150px',
  //         maxHeight: '150px',
  //         backgroundColor: '#816EF8',
  //         border: '1px solid #816EF8',
  //         borderRadius: '8px',
  //         padding: '10px',
  //         color: '#FFFFFF',
  //       }}
  //     >
  //       <Flex dislay="flex" width="100%">
  //         {(item?.auto_post_eligibility.is_default_contactlist ||
  //           item?.auto_post_eligibility.is_token) === true && (
  //           <span style={{ fontSize: '14px' }}>
  //             {ACToggleTabs === 'advisor'
  //               ? `${item.first_name} ${item.last_name} is eligible for auto posts`
  //               : ACToggleTabs === 'admin'
  //               ? `${item.name} is eligible for auto posts`
  //               : null}
  //           </span>
  //         )}
  //       </Flex>
  //       <hr style={{ color: 'white', width: '100%' }} />
  //       <Flex display="flex" width="100%" style={{ flexDirection: 'column' }}>
  //         <Flex display="flex" width="100%">
  //           {item?.auto_post_eligibility.is_default_contactlist === true && (
  //             <div
  //               style={{
  //                 display: 'flex',
  //                 flexDirection: 'row',
  //                 width: '100%',
  //                 padding: '3px 0px 5px',
  //                 marginBottom: '8px',
  //               }}
  //             >
  //               <img
  //                 alt=""
  //                 src="/assets/images/checked-icon.svg"
  //                 style={{ marginRight: '6px' }}
  //               />
  //               <span
  //                 style={{
  //                   display: 'flex',
  //                   justifyContent: 'center',
  //                   alignItems: 'center',
  //                   fontSize: '14px',
  //                 }}
  //               >
  //                 Email Service Connected
  //               </span>
  //             </div>
  //           )}
  //         </Flex>
  //         <Flex display="flex" width="100%">
  //           {item?.auto_post_eligibility.is_token === true && (
  //             <div
  //               style={{
  //                 display: 'flex',
  //                 flexDirection: 'row',
  //                 width: '100%',
  //                 padding: '3px 0px 5px',
  //               }}
  //             >
  //               <img
  //                 alt=""
  //                 src="/assets/images/checked-icon.svg"
  //                 style={{ marginRight: '6px' }}
  //               />
  //               <span
  //                 style={{
  //                   display: 'flex',
  //                   justifyContent: 'center',
  //                   alignItems: 'center',
  //                   fontSize: '14px',
  //                 }}
  //               >
  //                 Default List Created
  //               </span>
  //             </div>
  //           )}
  //         </Flex>
  //       </Flex>
  //     </Flex>
  //   );
  // };

  // const tooltipNonEligibleBody = (item) => {
  //   return (
  //     <Flex
  //       style={{
  //         display: 'flex',
  //         flexDirection: 'column',
  //         width: '230px',
  //         height: '180px',
  //         maxHeight: '180px',
  //         backgroundColor: '#816EF8',
  //         border: '1px solid #816EF8',
  //         borderRadius: '8px',
  //         padding: '10px',
  //         color: '#FFFFFF',
  //       }}
  //     >
  //       <Flex dislay="flex" width="100%">
  //         {((item?.auto_post_eligibility.is_default_contactlist === false) &&
  //           (item?.auto_post_eligibility.is_token === false)) ? (
  //           <span style={{ fontSize: '14px' }}>
  //             {ACToggleTabs === 'advisor'
  //               ? `${item.first_name} ${item.last_name} is not eligible for auto posts as they have not created a default list and not connected to a platform`
  //               : ACToggleTabs === 'admin'
  //               ? `${item.name} is not eligible for auto posts as they have not created a default list and not connected to a platform`
  //               : ''}
  //           </span>
  //         ) 
  //         : ((item?.auto_post_eligibility?.is_default_contactlist === false) && (item?.auto_post_eligibility?.is_token === true)) ? (
  //           <span style={{ fontSize: '14px' }}>
  //             {ACToggleTabs === 'advisor'
  //               ? `${item.first_name} ${item.last_name} is not eligible for auto posts as they have not created a default list`
  //               : ACToggleTabs === 'admin'
  //               ? `${item.name} is not eligible for auto posts as they have not created a default list`
  //               : ''}
  //           </span>
  //         ) : ((item?.auto_post_eligibility.is_token === false) && (item?.auto_post_eligibility.is_default_contactlist === true)) ? (
  //           <span style={{ fontSize: '14px' }}>
  //             {ACToggleTabs === 'advisor'
  //               ? `${item.first_name} ${item.last_name} is not eligible for auto posts as they have not connected to a platform`
  //               : ACToggleTabs === 'admin'
  //               ? `${item.name} is not eligible for auto posts as they have not connected to a platform`
  //               : ''}
  //           </span>
  //         ) : null
  //         }
  //       </Flex>
  //       <hr style={{ color: 'white', width: '100%' }} />
  //       <Flex display="flex" width="100%" style={{ flexDirection: 'column' }}>
  //         <Flex display="flex" width="100%">
  //           {item?.auto_post_eligibility.is_token === true ? (
  //             <div
  //               style={{
  //                 display: 'flex',
  //                 flexDirection: 'row',
  //                 width: '100%',
  //                 padding: '3px 0px 5px',
  //                 marginBottom: '8px',
  //               }}
  //             >
  //               <img
  //                 alt=""
  //                 src="/assets/images/checked-icon.svg"
  //                 style={{ marginRight: '6px' }}
  //               />
  //               <span
  //                 style={{
  //                   display: 'flex',
  //                   justifyContent: 'center',
  //                   alignItems: 'center',
  //                   fontSize: '14px',
  //                 }}
  //               >
  //                 Email Service Connected
  //               </span>
  //             </div>
  //           ) : (
  //             <div
  //               style={{
  //                 display: 'flex',
  //                 flexDirection: 'row',
  //                 width: '100%',
  //                 padding: '3px 0px 5px',
  //                 marginBottom: '8px',
  //               }}
  //             >
  //               <img
  //                 alt=""
  //                 src="/assets/images/unchecked-icon.svg"
  //                 style={{ marginRight: '6px' }}
  //               />
  //               <span
  //                 style={{
  //                   display: 'flex',
  //                   justifyContent: 'center',
  //                   alignItems: 'center',
  //                   fontSize: '14px',
  //                 }}
  //               >
  //                 Email Service Connected
  //               </span>
  //             </div>
  //           )}
  //         </Flex>
  //         <Flex display="flex" width="100%">
  //           {item?.auto_post_eligibility.is_default_contactlist === true ? (
  //             <div
  //               style={{
  //                 display: 'flex',
  //                 flexDirection: 'row',
  //                 width: '100%',
  //                 padding: '3px 0px 5px',
  //               }}
  //             >
  //               <img
  //                 alt=""
  //                 src="/assets/images/checked-icon.svg"
  //                 style={{ marginRight: '6px' }}
  //               />
  //               <span
  //                 style={{
  //                   display: 'flex',
  //                   justifyContent: 'center',
  //                   alignItems: 'center',
  //                   fontSize: '14px',
  //                 }}
  //               >
  //                 Default List Created
  //               </span>
  //             </div>
  //           ) : (
  //             <div
  //               style={{
  //                 display: 'flex',
  //                 flexDirection: 'row',
  //                 width: '100%',
  //                 padding: '3px 0px 5px',
  //               }}
  //             >
  //               <img
  //                 alt=""
  //                 src="/assets/images/unchecked-icon.svg"
  //                 style={{ marginRight: '6px' }}
  //               />
  //               <span
  //                 style={{
  //                   display: 'flex',
  //                   justifyContent: 'center',
  //                   alignItems: 'center',
  //                   fontSize: '14px',
  //                 }}
  //               >
  //                 Default List Created
  //               </span>
  //             </div>
  //           )}
  //         </Flex>
  //       </Flex>
  //     </Flex>
  //   );
  // };

  // const eligibleStatusPill = () => {
  //   return (
  //     <Flex
  //       style={{
  //         width: 'max-content',
  //         padding: '6px 12px',
  //         fontSize: '12px',
  //         height: '30px',
  //         backgroundColor: '#B3DAC7',
  //         color: '#008243',
  //         border: '1px solid rgb(182, 207, 243)',
  //         borderRadius: '20px',
  //         margin: '-6px 6px 5px 0px',
  //       }}
  //     >
  //       Eligible
  //     </Flex>
  //   );
  // };

  // const uneligibleStatusPill = () => {
  //   return (
  //     <Flex
  //       style={{
  //         width: 'max-content',
  //         padding: '6px 12px',
  //         fontSize: '12px',
  //         height: '30px',
  //         backgroundColor: '#FFCA99',
  //         color: '#EA780E',
  //         border: '1px solid rgb(182, 207, 243)',
  //         borderRadius: '20px',
  //         margin: '-6px 6px 5px 0px',
  //       }}
  //     >
  //       Not Eligible
  //     </Flex>
  //   );
  // };

  // const ACAdvisorList = () => {
  //   return (
  //     <Flex flexDirection="column" width="100%">
  //       <ContactsListWrapper
  //         padding="0px"
  //         style={{ marginTop: '0px', height: '430px' }}
  //       >
  //         <LoaderWrapper isLoading={ACLoader}>
  //           <ContactListBody height="437px" maxHeight="440px">
  //             {!isEmpty(ACAdvisorData) &&
  //               ACAdvisorData.filter((item) =>
  //                 `${item?.first_name} ${item?.last_name} ${item?.email}`
  //                   .toLowerCase()
  //                   .includes(ACSearchBox)
  //               ).map((item, index) => (
  //                 <>
  //                   <Flex width="100%" style={{ flexDirection: 'row' }}>
  //                     <CheckBoxSpan
  //                       key={item.id}
  //                       screenWidth={window.innerWidth}
  //                       style={{
  //                         border: 'none',
  //                         margin: '9px 0px 4px 6px',
  //                         textAlign: 'left',
  //                         width: '35%',
  //                       }}
  //                     >
  //                       <CheckBox
  //                         id={item.id}
  //                         value={item.id}
  //                         disabled={
  //                           (item?.auto_post_eligibility
  //                             .is_default_contactlist ||
  //                             item?.auto_post_eligibility.is_token) === false ||
  //                           (item?.auto_post_eligibility
  //                             .is_default_contactlist &&
  //                             item?.auto_post_eligibility.is_token) === false
  //                         }
  //                         checked={
  //                           !isEmpty(selectedACAdvisorData) &&
  //                           selectedACAdvisorData.filter(
  //                             (l) => l.id === item.id
  //                           ).length > 0
  //                         }
  //                         onChange={() => {
  //                           console.log('ITEN---->', item);
  //                           handleCheckBoxChange(item, 'advisor');
  //                         }}
  //                       />
  //                       <CheckBoxLabel
  //                         fontSize="14px"
  //                         style={{
  //                           paddingLeft: '13%',
  //                           paddingTop: '5px',
  //                           width: '100%',
  //                         }}
  //                         htmlFor={item.id}
  //                         color="#848484"
  //                       >
  //                         {`${item?.first_name} ${item?.last_name}`}
  //                       </CheckBoxLabel>
  //                     </CheckBoxSpan>
  //                     <Flex
  //                       style={{
  //                         width: '45%',
  //                         display: 'flex',
  //                         height: '25px',
  //                         marginTop: '5px',
  //                         color: '#101828',
  //                         paddingTop: '9px',
  //                         fontSize: '14px',
  //                       }}
  //                     >
  //                       {item?.email}
  //                     </Flex>
  //                     {/* <Flex
  //                       style={{
  //                         width: ACToggleTabs === 'advisor' ? '25%' : '30%',
  //                         display: 'flex',
  //                         height: '25px',
  //                         marginTop: '5px',
  //                         color: '#101828',
  //                         paddingTop: '9px',
  //                         fontSize: '14px',
  //                       }}
  //                     >
  //                       {item?.firm_name}
  //                     </Flex> */}

  //                     <NewTooltip
  //                       TransitionComponent={Zoom}
  //                       arrow
  //                       placement="right"
  //                       title={
  //                         (item?.auto_post_eligibility.is_default_contactlist &&
  //                           item?.auto_post_eligibility.is_token) === true
  //                           ? tooltipEligibleBody(item)
  //                           : tooltipNonEligibleBody(item)
  //                       }
  //                     >
  //                       <Flex
  //                         style={{
  //                           width: '20%',
  //                           display: 'flex',
  //                           height: '25px',
  //                           marginTop: '5px',
  //                           color: '#101828',
  //                           paddingTop: '9px',
  //                           fontSize: '14px',
  //                         }}
  //                       >
  //                         {(item?.auto_post_eligibility
  //                           .is_default_contactlist ||
  //                           item?.auto_post_eligibility.is_token) === false ||
  //                         (item?.auto_post_eligibility.is_default_contactlist &&
  //                           item?.auto_post_eligibility.is_token) === false
  //                           ? uneligibleStatusPill()
  //                           : eligibleStatusPill()}
  //                       </Flex>
  //                     </NewTooltip>
  //                   </Flex>
  //                   <hr
  //                     style={{
  //                       width: '100%',
  //                       border: '0.5px solid #e4e7ec',
  //                       marginBottom: '0px',
  //                     }}
  //                   />
  //                 </>
  //               ))}
  //           </ContactListBody>
  //         </LoaderWrapper>
  //       </ContactsListWrapper>
  //     </Flex>
  //   );
  // };

  // const ACAdminList = () => {
  //   return (
  //     <Flex flexDirection="column" width="100%">
  //       <ContactsListWrapper
  //         padding="0px"
  //         style={{ marginTop: '0px', height: '430px' }}
  //       >
  //         <LoaderWrapper isLoading={ACLoader}>
  //           <ContactListBody height="437px" maxHeight="440px">
  //             {!isEmpty(ACAdminData) &&
  //               ACAdminData.filter((item) =>
  //               `${item?.name} ${item?.email}`.toLowerCase().includes(ACSearchResult)
  //               ).map((item, index) => (
  //                 <>
  //                   <Flex width="100%" style={{ flexDirection: 'row' }}>
  //                     <CheckBoxSpan
  //                       key={item.id}
  //                       screenWidth={window.innerWidth}
  //                       style={{
  //                         border: 'none',
  //                         margin: '9px 0px 4px 6px',
  //                         textAlign: 'left',
  //                         width: '25%',
  //                       }}
  //                     >
  //                       <CheckBox
  //                         id={item.id}
  //                         value={item.name}
  //                         disabled={
  //                           (item?.auto_post_eligibility
  //                             .is_default_contactlist ||
  //                             item?.auto_post_eligibility.is_token) ===
  //                             false ||
  //                           (item?.auto_post_eligibility
  //                             .is_default_contactlist &&
  //                             item?.auto_post_eligibility.is_token) === false
  //                         }
  //                         checked={
  //                           !isEmpty(selectedACAdminData) &&
  //                           selectedACAdminData.filter((l) => l.id === item.id)
  //                             .length > 0
  //                         }
  //                         onChange={() => {
  //                           console.log('ITEN---->', item);
  //                           handleCheckBoxChange(item, 'admin');
  //                         }}
  //                       />
  //                       <CheckBoxLabel
  //                         fontSize="14px"
  //                         style={{
  //                           paddingLeft: '18%',
  //                           paddingTop: '5px',
  //                           width: '100%',
  //                         }}
  //                         htmlFor={item.id}
  //                         color="#848484"
  //                       >
  //                         {item.name}
  //                       </CheckBoxLabel>
  //                     </CheckBoxSpan>
  //                     <Flex
  //                       style={{
  //                         width: '35%',
  //                         display: 'flex',
  //                         height: '25px',
  //                         marginTop: '5px',
  //                         color: '#101828',
  //                         paddingTop: '9px',
  //                         fontSize: '14px',
  //                       }}
  //                     >
  //                       {item?.email}
  //                     </Flex>
  //                     <Flex
  //                       style={{
  //                         width: '30%',
  //                         display: 'flex',
  //                         height: '25px',
  //                         marginTop: '5px',
  //                         color: '#101828',
  //                         paddingTop: '9px',
  //                         fontSize: '14px',
  //                       }}
  //                     >
  //                       {item?.firm_name}
  //                     </Flex>
  //                     <NewTooltip
  //                         TransitionComponent={Zoom}
  //                         arrow
  //                         placement="right"
  //                         title={
  //                           (item?.auto_post_eligibility
  //                             .is_default_contactlist &&
  //                             item?.auto_post_eligibility.is_token) === true
  //                             ? tooltipEligibleBody(item)
  //                             : tooltipNonEligibleBody(item)
  //                         }
  //                       >
  //                         <Flex
  //                           style={{
  //                             width: '15%',
  //                             display: 'flex',
  //                             height: '25px',
  //                             marginTop: '5px',
  //                             color: '#101828',
  //                             paddingTop: '9px',
  //                             fontSize: '14px',
  //                           }}
  //                         >
  //                           {(item?.auto_post_eligibility
  //                             .is_default_contactlist ||
  //                             item?.auto_post_eligibility.is_token) === false ||
  //                           (item?.auto_post_eligibility
  //                             .is_default_contactlist &&
  //                             item?.auto_post_eligibility.is_token) === false
  //                             ? uneligibleStatusPill()
  //                             : eligibleStatusPill()}
  //                         </Flex>
  //                       </NewTooltip>
  //                   </Flex>
  //                   <hr
  //                     style={{
  //                       width: '100%',
  //                       border: '0.5px solid #e4e7ec',
  //                       marginBottom: '0px',
  //                     }}
  //                   />
  //                 </>
  //               ))}
  //           </ContactListBody>
  //         </LoaderWrapper>
  //       </ContactsListWrapper>
  //     </Flex>
  //   );
  // };

  // const ACFirmList = () => {
  //   return (
  //     <Flex flexDirection="column" width="100%">
  //       <ContactsListWrapper
  //         padding="0px"
  //         style={{ marginTop: '0px', height: '430px' }}
  //       >
  //         <LoaderWrapper isLoading={ACLoader}>
  //           <ContactListBody height="437px" maxHeight="440px">
  //             {!isEmpty(ACFirmData) &&
  //               ACFirmData.filter((item) =>
  //                 item?.name.toLowerCase().includes(ACSearch)
  //               ).map((item, index) => (
  //                 <>
  //                   <Flex width="100%" style={{ flexDirection: 'row' }}>
  //                     <CheckBoxSpan
  //                       key={item.id}
  //                       screenWidth={window.innerWidth}
  //                       style={{
  //                         border: 'none',
  //                         margin: '9px 0px 4px 6px',
  //                         textAlign: 'left',
  //                         width: '33.33%',
  //                       }}
  //                     >
  //                       <CheckBox
  //                         id={item.id}
  //                         value={item.name}
  //                         disabled={(item?.eligible_advisor_count && item.eligible_admin_count) === 0}
  //                         checked={
  //                           !isEmpty(selectedACFirmData) &&
  //                           selectedACFirmData.filter((s) => s.id === item.id)
  //                             .length > 0
  //                         }
  //                         onChange={() => {
  //                           console.log('ITEN---->', item);
  //                           handleCheckBoxChange(item, 'firm');
  //                         }}
  //                       />
  //                       <CheckBoxLabel
  //                         fontSize="14px"
  //                         style={{
  //                           paddingLeft: '13.5%',
  //                           paddingTop: '5px',
  //                         }}
  //                         htmlFor={item.id}
  //                         color="#848484"
  //                       >
  //                         {item.name}
  //                       </CheckBoxLabel>
  //                     </CheckBoxSpan>
  //                     <Flex
  //                       style={{
  //                         width: '33.33%',
  //                         display: 'flex',
  //                         height: '25px',
  //                         marginTop: '5px',
  //                         color: '#101828',
  //                         paddingTop: '9px',
  //                         fontSize: '14px',
  //                         paddingLeft: '2px',
  //                       }}
  //                     >
  //                       {item?.eligible_advisor_count}
  //                     </Flex>
  //                     <Flex
  //                       style={{
  //                         width: '33.33%',
  //                         display: 'flex',
  //                         height: '25px',
  //                         marginTop: '5px',
  //                         color: '#101828',
  //                         paddingTop: '9px',
  //                         fontSize: '14px',
  //                         paddingLeft: '2px',
  //                       }}
  //                     >
  //                       {item?.eligible_admin_count}
  //                     </Flex>
  //                   </Flex>
  //                   <hr
  //                     style={{
  //                       width: '100%',
  //                       border: '0.5px solid #e4e7ec',
  //                       marginBottom: '0px',
  //                     }}
  //                   />
  //                 </>
  //               ))}
  //           </ContactListBody>
  //         </LoaderWrapper>
  //       </ContactsListWrapper>
  //     </Flex>
  //   );
  // };

  return !requestLoading ? (
    <Box>
      <AutomatedCampaignFormWrapper
        style={{
          width: '84%',
          margin: '0px auto 100px',
          border: 'none',
          paddingTop: '0px',
        }}
      >
        <Flex width="100%" alignItems="center">
          <WhiteCircle height="40px" width="40px" background="#816EF8">
            <span
              style={{
                color: '#fff',
                fontSize: '16px',
                position: 'absolute',
                top: '6px',
                left: '0px',
                width: '100%',
              }}
            >
              1
            </span>
          </WhiteCircle>
          <Flex fontSize="20px" color="#101828" style={{ fontWeight: 500 }}>
            Select Target
          </Flex>
        </Flex>
        <hr
          style={{
            width: '100%',
            border: '1px solid #E4E7EC',
            marginBottom: '20px',
          }}
        ></hr>
        <Flex width={'100%'}>
          <Box width="35%" fontSize="14px" mr="15px">
            <Flex className="field__title" width="100%" alignItems={'center'}>
              <span> Campaign Name*</span>
              <StyledTooltip
                text={
                  'This campaign will be sent to the selected segment. Any time a contact is added to that segment, they will start this automated campaign.'
                }
              />
            </Flex>
            <Box width="100%" pt="8px">
              <StyledTextInput
                maxLength="100"
                placeholder="Campaign name"
                value={campaignSeriesName}
                onChange={(e) => setCampaignSeriesName(e.target.value)}
                name="campaign_series_name"
                border={
                  campaignNameValid ? '1px solid #8493a6' : '1px solid #FF0000'
                }
              />
            </Box>
          </Box>
          <Box width="65%" fontSize="14px">
            <Box width="100%" className="field__title">
              Purpose
            </Box>
            <Box width="100%" pt="8px">
              <StyledTextInput
                maxLength="1000"
                placeholder="Campaign Purpose"
                value={campaignPurpose}
                onChange={(e) => setCampaignPurpose(e.target.value)}
              />
            </Box>
          </Box>
        </Flex>
        {campaignAction === AUTOMATED_CAMPAIGN_ACTIONS.EVENT ? (
          <LineBreaker style={{ margin: '20px auto 10px 0px' }} />
        ) : campaignAction === AUTOMATED_CAMPAIGN_ACTIONS.NURTURE_PROSPECTS ? (
          <LineBreaker style={{ margin: '20px auto 10px 0px' }} />
        ) : campaignAction === AUTOMATED_CAMPAIGN_ACTIONS.WELCOME_CLIENTS ? (
          <LineBreaker style={{ margin: '20px auto 0px 0px' }} />
        ) : null}

        {/* {campaignAction !== AUTOMATED_CAMPAIGN_ACTIONS.EVENT ? <br /> : ''} */}
        <Flex
          fontSize="14px"
          justifyContent={
            AUTOMATED_CAMPAIGN_ACTIONS.EVENT ? 'flex-start' : 'space-between'
          }
          alignItems="center"
        >
          {campaignAction === AUTOMATED_CAMPAIGN_ACTIONS.NEWSLETTER ? (
            <Box width="35%">
              {/* <Box width="49%"> */}
              <Box className="field__title">Newsletter Frequency</Box>
              <Box width="100%" pt="8px">
                <ReactSelect
                  onChange={(newValue) => {
                    setNewsletterFrequency(getNewOptionValue(newValue));
                  }}
                  options={NEWSLETTER_FREQUENCIES}
                  placeholder={'Choose a frequency'}
                  styles={getSelectStyles()}
                  value={newsletterFrequency}
                />
              </Box>
            </Box>
          ) : (
            <Box width={'35%'}>
              <Flex
                width="100%"
                className="field__title"
                justifyContent={'space-between'}
              >
                <span>
                  {isEventType
                    ? 'Event Page*'
                    : isProspectType
                    ? 'Landing Page*'
                    : ''}
                </span>
                {!isCascaded && (
                  <span
                    onClick={() => {
                      campaignSetState({
                        acBack: true,
                      });
                      history.push('/campaign/landing-pages/new');
                    }}
                    style={{ color: '#6351ed', cursor: 'pointer' }}
                  >
                    {isEventType
                      ? 'Create Event Page'
                      : isProspectType
                      ? 'Create Landing Page'
                      : ''}
                  </span>
                )}
              </Flex>
              {(isEventType || isProspectType) && (
                <Box width="100%" pt="8px">
                  <ReactSelect
                    onChange={(newValue) => {
                      setTargetPage(getNewOptionValue(newValue));
                    }}
                    options={targetPageOptions}
                    placeholder={getCampaignInfo().targetPagePlaceholder}
                    styles={getSelectStyles(eventPageValid)}
                    value={targetPage}
                    isDisabled={isCascaded}
                  />
                </Box>
              )}
            </Box>
          )}

          {isEventType && (
            <Box width={'25%'} marginLeft="15px">
              <Box width={'100%'} className="field__title">
                <Box>Event Date*</Box>
                <Box
                  width="100%"
                  mt="8px"
                  style={{
                    border: eventDateValid
                      ? '1px solid #8493A6'
                      : '1px solid #FF0000',
                    borderRadius: '4px',
                  }}
                >
                  <DateTimeWrapper>
                    <DateTimeText>
                      {eventDate &&
                        convertDateToRequiredFormat({
                          date: eventDate,
                          format: 'MM/DD/YYYY',
                        })}
                    </DateTimeText>
                    <DripIconButton
                      onClick={() => setIsDatepickerOpen(true)}
                      icon="clock"
                      color="#8493A6"
                      styles={{ marginTop: '5px' }}
                      forAutomatedCampaigns={true}
                    />
                    <MioDatePicker
                      isOpen={isDatepickerOpen}
                      customInput={<div />}
                      minDate={new Date()}
                      onDateClose={() => setIsDatepickerOpen(false)}
                      onSubmit={(date) => {
                        setEventDate(moment(date).format('YYYY/MM/DD'));
                        setIsDatepickerOpen(false);
                      }}
                      timeZone={get(userData, 'details.user.timezone', '')}
                      scheduleValue={eventDate ? new Date(eventDate) : ''}
                      isFromCampaign
                      isFromAC
                    />
                  </DateTimeWrapper>
                </Box>
              </Box>
            </Box>
          )}
        </Flex>
        {campaignAction === AUTOMATED_CAMPAIGN_ACTIONS.EVENT ||
        campaignAction === AUTOMATED_CAMPAIGN_ACTIONS.NURTURE_PROSPECTS ? (
          <LineBreaker style={{ margin: '20px 20px 10px 0px' }} />
        ) : null}
        {(campaignAction === AUTOMATED_CAMPAIGN_ACTIONS.WELCOME_CLIENTS ||
          campaignAction === AUTOMATED_CAMPAIGN_ACTIONS.NURTURE_PROSPECTS) && (
          <>
            <Flex
              flexDirection="column"
              width="30%"
              fontSize="14px"
              color="#000000"
              style={{
                padding: '0px 10px 10px 0px',
                fontSize: '16px',
                marginTop: '15px',
              }}
            >
              <Flex width="100%" style={{ fontWeight: '800' }}>
                Target
              </Flex>
            </Flex>
          </>
        )}
        <Flex
          display="flex"
          flexDirection="row"
          width="100%"
          alignItems="center"
          justifyContent="flex-start"
        >
          {campaignAction !== AUTOMATED_CAMPAIGN_ACTIONS.EVENT && (
            <>{!isEventType ? segmentListBox() : {}}</>
          )}
        </Flex>
        {campaignAction === AUTOMATED_CAMPAIGN_ACTIONS.EVENT && (
          <>
            <Flex
              flexDirection="column"
              width="30%"
              fontSize="14px"
              color="#000000"
              style={{ fontSize: '16px' }}
            >
              <Flex width="100%" style={{ fontWeight: '800' }}>
                Target
              </Flex>
            </Flex>
          </>
        )}
        <Flex
          width="100%"
          justifyContent="space-between"
          style={{ padding: '0px 10px 0px 0px' }}
          mt="10px"
        >
          <Flex
            display="flex"
            flexDirection="row"
            width="100%"
            alignItems="center"
            justifyContent="flex-start"
          >
            {campaignAction === AUTOMATED_CAMPAIGN_ACTIONS.EVENT && (
              <>{isEventType ? segmentListBox() : {}}</>
            )}
            {campaignAction === AUTOMATED_CAMPAIGN_ACTIONS.EVENT && (
              <>
                {(get(targetInfo, 'is_cascaded', 'false') === 'false' ||
                  (get(targetInfo, 'is_cascaded', 'false') === 'true' &&
                    targetInfo?.parent_platforms?.includes('fb'))) && (
                  <Flex flexDirection="column" width="300px" marginRight="20px">
                    <Flex
                      fontSize="14px"
                      color="#000000"
                      // width="100%"
                      justifyContent="space-between"
                    >
                      <Flex>
                        Facebook
                        <span
                          style={{
                            color: isEmpty(get(socialNetworkData, 'fb', []))
                              ? '#AFAFAF'
                              : '#6250ED',
                            fontSize: '10px',
                            position: 'relative',
                            top: '4px',
                            paddingLeft: '10px',
                            cursor: isEmpty(get(socialNetworkData, 'fb', []))
                              ? 'default'
                              : 'pointer',
                          }}
                          onClick={() => handleSelectAll('fb')}
                        >
                          Select ALL
                        </span>
                      </Flex>
                    </Flex>
                    <RecipientsListWrapper
                      padding="0px"
                      border={
                        segmentListValid
                          ? '1px solid #bccdd4'
                          : '1px solid #FF0000'
                      }
                    >
                      {isEmpty(get(socialNetworkData, 'fb', [])) ? (
                        <Flex
                          height="100%"
                          justifyContent="center"
                          width="100%"
                          fontSize="12px"
                          alignItems="center"
                          style={{
                            cursor: 'pointer',
                          }}
                          onClick={() => redirectToConfigure('fb')}
                        >
                          Please Connect to Facebook
                        </Flex>
                      ) : (
                        <RecipientsListBody height="168px">
                          {!isEmpty(get(socialNetworkData, 'fb[0].page', [])) &&
                          !ascFbOrder
                            ? map(
                                get(socialNetworkData, 'fb[0].page', []),
                                (item, key) => (
                                  <>
                                    <FilterRowChips
                                      style={{
                                        fontSize: '12px',
                                        padding: '5px 7px',
                                      }}
                                    >
                                      <label
                                        className="checkbox-button"
                                        style={{
                                          cursor: 'pointer',
                                        }}
                                      >
                                        <input
                                          type="checkbox"
                                          className="checkbox-button__input"
                                          name="draft"
                                          onChange={() =>
                                            handleCheckboxClick(key, 'fb')
                                          }
                                          checked={
                                            !isEmpty(
                                              selectedFbContacts.filter(
                                                (s) => s === key
                                              )
                                            )
                                          }
                                          value={item.details.name}
                                        />
                                        <span
                                          className="checkbox-button__control"
                                          style={{
                                            cursor: 'pointer',
                                          }}
                                        ></span>
                                        <span
                                          className="checkbox-button__label"
                                          style={{
                                            color: '#49484a',
                                            fontWeight: '100',
                                            cursor: 'pointer',
                                          }}
                                        >
                                          {item.details.name}
                                        </span>
                                      </label>
                                    </FilterRowChips>
                                  </>
                                )
                              )
                            : map(get(socialNetworkData, 'fb[0].page', []))
                                .sort((a, b) =>
                                  a.details.name.localeCompare(b.details.name)
                                )
                                .map((item, index) => (
                                  <>
                                    <FilterRowChips
                                      style={{
                                        fontSize: '12px',
                                        padding: '5px 0px',
                                      }}
                                    >
                                      <label
                                        className="checkbox-button"
                                        style={{
                                          cursor: 'pointer',
                                        }}
                                      >
                                        <input
                                          type="checkbox"
                                          className="checkbox-button__input"
                                          name="draft"
                                          onChange={() =>
                                            handleCheckboxClick(item.id, 'fb')
                                          }
                                          checked={
                                            !isEmpty(
                                              selectedFbContacts.filter(
                                                (s) => s === item.id
                                              )
                                            )
                                          }
                                          value={item.details.name}
                                        />
                                        <span
                                          className="checkbox-button__control"
                                          style={{
                                            cursor: 'pointer',
                                          }}
                                        ></span>
                                        <span
                                          className="checkbox-button__label"
                                          style={{
                                            color: '#49484a',
                                            fontWeight: '100',
                                            cursor: 'pointer',
                                          }}
                                        >
                                          {item.details.name}
                                        </span>
                                      </label>
                                    </FilterRowChips>
                                  </>
                                ))}
                        </RecipientsListBody>
                      )}
                    </RecipientsListWrapper>
                  </Flex>
                )}
                {(get(targetInfo, 'is_cascaded', 'false') === 'false' ||
                  (get(targetInfo, 'is_cascaded', 'false') === 'true' &&
                    targetInfo?.parent_platforms?.includes('ln'))) && (
                  <Flex flexDirection="column" width="300px" marginRight="20px">
                    <Flex
                      fontSize="14px"
                      color="#000000"
                      // width="100%"
                      justifyContent="space-between"
                    >
                      <Flex>
                        LinkedIn
                        {isEmpty(automatedCampaignDetails?.campaignId) && (
                          <span
                            style={{
                              color: isEmpty(get(socialNetworkData, 'ln', []))
                                ? '#AFAFAF'
                                : '#6250ED',
                              fontSize: '10px',
                              position: 'relative',
                              top: '4px',
                              paddingLeft: '10px',
                              cursor: isEmpty(get(socialNetworkData, 'ln', []))
                                ? 'default'
                                : 'pointer',
                            }}
                            onClick={() => handleSelectAll('ln')}
                          >
                            Select ALL
                          </span>
                        )}
                      </Flex>
                    </Flex>
                    <RecipientsListWrapper
                      padding="0px"
                      border={
                        segmentListValid
                          ? '1px solid #bccdd4'
                          : '1px solid #FF0000'
                      }
                    >
                      {isEmpty(get(socialNetworkData, 'ln', [])) ? (
                        <Flex
                          height="100%"
                          justifyContent="center"
                          width="100%"
                          fontSize="12px"
                          alignItems="center"
                          style={{
                            cursor: 'pointer',
                          }}
                          onClick={() => redirectToConfigure('ln')}
                        >
                          Please Connect to LinkedIn
                        </Flex>
                      ) : (
                        <RecipientsListBody height="168px">
                          <FilterRowChips
                            style={{ fontSize: '12px', padding: '5px 7px' }}
                          >
                            <label
                              className="checkbox-button"
                              style={{
                                cursor: 'pointer',
                              }}
                            >
                              <input
                                type="checkbox"
                                className="checkbox-button__input"
                                name="draft"
                                onChange={() => handleCheckboxClick(0, 'ln')}
                                checked={
                                  !isEmpty(selectedLnContacts.filter((s) => !s))
                                }
                                value={'news-feed'}
                              />
                              <span
                                className="checkbox-button__control"
                                style={{
                                  cursor: 'pointer',
                                }}
                              ></span>
                              <span
                                className="checkbox-button__label"
                                style={{
                                  color: '#49484a',
                                  fontWeight: '100',
                                  cursor: 'pointer',
                                }}
                              >
                                On News Feed
                              </span>
                            </label>
                          </FilterRowChips>
                          {!isEmpty(get(socialNetworkData, 'ln[0].page', [])) &&
                          !ascLnOrder
                            ? map(
                                get(socialNetworkData, 'ln[0].page', []),
                                (item, key) => (
                                  <>
                                    <FilterRowChips
                                      style={{
                                        fontSize: '12px',
                                        padding: '5px 7px',
                                      }}
                                    >
                                      <label
                                        className="checkbox-button"
                                        style={{
                                          cursor: 'pointer',
                                        }}
                                      >
                                        <input
                                          type="checkbox"
                                          className="checkbox-button__input"
                                          name="draft"
                                          onChange={() =>
                                            handleCheckboxClick(key, 'ln')
                                          }
                                          checked={
                                            !isEmpty(
                                              selectedLnContacts.filter(
                                                (s) => s === key
                                              )
                                            )
                                          }
                                          value={item.details.name}
                                        />
                                        <span
                                          className="checkbox-button__control"
                                          style={{
                                            cursor: 'pointer',
                                          }}
                                        ></span>
                                        <span
                                          className="checkbox-button__label"
                                          style={{
                                            color: '#49484a',
                                            fontWeight: '100',
                                            cursor: 'pointer',
                                          }}
                                        >
                                          {item.details.name}
                                        </span>
                                      </label>
                                    </FilterRowChips>
                                  </>
                                )
                              )
                            : map(get(socialNetworkData, 'ln[0].page', []))
                                .sort((a, b) =>
                                  a.details.name.localeCompare(b.details.name)
                                )
                                .map((item, index) => (
                                  <>
                                    <FilterRowChips
                                      style={{
                                        fontSize: '12px',
                                        padding: '5px 0px',
                                      }}
                                    >
                                      <label
                                        className="checkbox-button"
                                        style={{
                                          cursor: 'pointer',
                                        }}
                                      >
                                        <input
                                          type="checkbox"
                                          className="checkbox-button__input"
                                          name="draft"
                                          onChange={() =>
                                            handleCheckboxClick(item.id, 'ln')
                                          }
                                          checked={
                                            !isEmpty(
                                              selectedLnContacts.filter(
                                                (s) => s === item.id
                                              )
                                            )
                                          }
                                          value={item.details.name}
                                        />
                                        <span
                                          className="checkbox-button__control"
                                          style={{
                                            cursor: 'pointer',
                                          }}
                                        ></span>
                                        <span
                                          className="checkbox-button__label"
                                          style={{
                                            color: '#49484a',
                                            fontWeight: '100',
                                            cursor: 'pointer',
                                          }}
                                        >
                                          {item.details.name}
                                        </span>
                                      </label>
                                    </FilterRowChips>
                                  </>
                                ))}
                        </RecipientsListBody>
                      )}
                    </RecipientsListWrapper>
                  </Flex>
                )}
                {(get(targetInfo, 'is_cascaded', 'false') === 'false' ||
                  (get(targetInfo, 'is_cascaded', 'false') === 'true' &&
                    targetInfo?.parent_platforms?.includes('tw'))) && (
                  <Flex flexDirection="column" width="300px" marginRight="20px">
                    <Flex fontSize="14px" color="#000000" width="100%">
                      Twitter
                    </Flex>
                    <RecipientsListWrapper
                      padding="0px"
                      border={
                        segmentListValid
                          ? '1px solid #bccdd4'
                          : '1px solid #FF0000'
                      }
                    >
                      {isEmpty(get(socialNetworkData, 'tw', [])) ? (
                        <Flex
                          height="100%"
                          justifyContent="center"
                          width="100%"
                          fontSize="12px"
                          alignItems="center"
                          style={{
                            cursor: 'pointer',
                          }}
                          onClick={() => redirectToConfigure('tw')}
                        >
                          Please Connect to Twitter
                        </Flex>
                      ) : (
                        <RecipientsListBody height="168px">
                          <FilterRowChips
                            style={{ fontSize: '12px', padding: '5px 7px' }}
                          >
                            <label
                              className="checkbox-button"
                              style={{
                                cursor: 'pointer',
                              }}
                            >
                              <input
                                type="checkbox"
                                className="checkbox-button__input"
                                name="draft"
                                onChange={() => handleCheckboxClick(0, 'tw')}
                                checked={
                                  !isEmpty(selectedTwContacts.filter((s) => !s))
                                }
                                id="select_all_tw"
                                value="news-feed"
                              />
                              <span
                                className="checkbox-button__control"
                                style={{
                                  cursor: 'pointer',
                                }}
                              ></span>
                              <span
                                className="checkbox-button__label"
                                style={{
                                  color: '#49484a',
                                  fontWeight: '100',
                                  cursor: 'pointer',
                                }}
                              >
                                On News Feed
                              </span>
                            </label>
                          </FilterRowChips>
                        </RecipientsListBody>
                      )}
                    </RecipientsListWrapper>
                  </Flex>
                )}
              </>
            )}
          </Flex>
        </Flex>
        {campaignAction === AUTOMATED_CAMPAIGN_ACTIONS.EVENT ? (
          <LineBreaker />
        ) : null}
        {(isEventType || isProspectType) && (
          <Box width="35%" fontSize="14px" mt="20px">
            <Flex className="field__title" width="100%" alignItems={'center'}>
              <span>
                {isEventType ? 'Event Registration Tag' : 'Lead Capture Tag'}
              </span>
              <StyledTooltip
                text={
                  isEventType
                    ? 'This tag will be applied to contacts who fill out the event registration form on the Event Page.'
                    : 'This tag will be applied to contacts who fill out the lead capture form on the Target Page.'
                }
              />
            </Flex>
            <Box width="100%" pt="8px">
              <StyledTextInput
                maxLength="100"
                placeholder="Campaign series name"
                value={convertedTag}
                onChange={({ target: { value } }) => setConvertedTag(value)}
                name="converted_tag"
                disabled={isEventType}
              />
            </Box>
          </Box>
        )}

        <LineBreaker />

        {/* <-- SUBSCRIPTION TYPE STARTS --> */}
        {showSubscription && (
          <>
            <Flex
              flexDirection="row"
              display="flex"
              width="100%"
              fontSize="14px"
              color="#000000"
              mr="15px"
              style={{
                position: 'relative',
                paddingBottom: '5px',
              }}
            >
              <Flex width="25%" style={{ fontWeight: '600', fontSize: '14px' }}>
                Subscription Type*
              </Flex>
            </Flex>
            <Flex
              width="100%"
              fontSize="14px"
              color="#667085"
              mr="15px"
              style={{ position: 'relative' }}
            >
              Designate the subscription type. This will be used to manage the
              contact's email preferences.
            </Flex>
            <Flex display="flex" flexDirection="row" flexWrap="wrap">
              {!isEmpty(subscriptionTypeOptions) &&
                subscriptionTypeOptions.map((item, index) => (
                  <Flex
                    width="45%"
                    height="85px"
                    marginTop="10px"
                    padding="10px"
                    bgColor="#fff"
                    mr="16px"
                    display="flex"
                    flexDirection="column"
                    style={{
                      borderRadius: '6px',
                      border: subscriptionValid
                        ? '1px solid #bccdd4'
                        : '1px solid #FF0000',
                      backgroundColor: '#FFFFFF',
                    }}
                  >
                    <Flex>
                      <FilterRowChips
                        style={{
                          fontSize: '12px',
                          padding: '5px 7px',
                        }}
                      >
                        <label
                          className="checkbox-button"
                          style={{
                            cursor: 'pointer',
                            marginLeft: '-7px',
                            marginTop: '-4px',
                          }}
                        >
                          <input
                            type="checkbox"
                            className="checkbox-button__input"
                            name="newsletters"
                            onChange={(e) =>
                              setSelectSubscriptionType(
                                e.target.checked ? item.value : ''
                              )
                            }
                            checked={selectSubscriptionType === item.value}
                            value={selectSubscriptionType}
                            disabled={isEventType}
                          />
                          <span
                            className="checkbox-button__control"
                            style={{
                              cursor: 'pointer',
                              marginRight: '5px',
                              marginTop: '-2px',
                            }}
                          ></span>
                          <span
                            className="checkbox-button__label"
                            style={{
                              color: '#000000',
                              fontWeight: '100',
                              cursor: 'pointer',
                              fontSize: '14px',
                            }}
                          >
                            {item.label}
                          </span>
                        </label>
                      </FilterRowChips>
                    </Flex>
                    <Flex
                      style={{
                        fontSize: '12px',
                        paddingLeft: '12px',
                        color: '#475467',
                        textAlign: 'left',
                        marginLeft: '12px',
                        paddingRight: '10px',
                      }}
                    >
                      {item.description}
                    </Flex>
                  </Flex>
                ))}
            </Flex>
          </>
        )}
        {/* {(isFirmLevelAdmin || isEnterpriseLevelAdmin) &&
          !isEnterpriseAdvisor && (
            <>
              <LineBreaker style={{ margin: '20px auto 10px 0px' }} />
              <Flex
                width="92%"
                display="flex"
                justifyContent="flex-start"
                flexDirection="row"
              >
                <Flex
                  width="50%"
                  justifyContent="flex-start"
                  alignItems="center"
                  style={{
                    color: ACToggleAutoPost === 'true' ? '#000000' : '#98A2B3',
                    fontSize: '18px',
                  }}
                >
                  Auto Post
                </Flex>
                <Flex
                  width="50%"
                  justifyContent="flex-start"
                  alignItems="center"
                  flexDirection="row"
                >
                  <Flex
                    display="flex"
                    width="100%"
                    style={{
                      justifyContent: 'end',
                      marginRight: '10px',
                    }}
                  >
                    <ToggleButton
                      className="react-switch"
                      onChange={(checked) => handleACAutoPostToggle(checked)}
                      checked={ACToggleAutoPost === 'true'}
                      onColor="#6351ed"
                      offColor="#ccc"
                      offHandleColor="#fff"
                      onHandleColor="#fff"
                      handleDiameter={10}
                      uncheckedIcon=""
                      checkedIcon=""
                      boxShadow=""
                      activeBoxShadow=""
                      type="autoPost"
                      left="5px"
                      margin="-3px 20px 0px 0px"
                      top="10px"
                    />
                    <Flex
                      style={{
                        padding: '3px 0px 0px',
                        fontSize: '15px',
                        color: '#000',
                      }}
                    >
                      {ACToggleAutoPost === 'true' ? 'On' : ' Off'}
                    </Flex>
                  </Flex>
                </Flex>
              </Flex>
              <Flex
                width="100%"
                justifyContent="flex-start"
                alignItems="center"
                style={{
                  color: '#98A2B3',
                  fontSize: '14px',
                }}
              >
                Select users to automatically share on behalf of
              </Flex>
            </>
          )} */}

        {/* {ACToggleAutoPost &&
          showACAutoPostList &&
          (isFirmLevelAdmin || isEnterpriseLevelAdmin) &&
          !isEnterpriseAdvisor && (
            <Flex
              marginTop="10px"
              bgColor="#fff"
              display="flex"
              flexDirection="column"
              style={{
                borderRadius: '6px',
                border: acAutoPostDataValid
                  ? '1px solid #bccdd4'
                  : '1px solid #FF0000',
                backgroundColor: '#FFFFFF',
                height: '605px',
                width: '92%',
              }}
            >
              <Flex
                display="flex"
                justifyContent="space-between"
                flexDirection="row"
                width="100%"
              >
                <Flex
                  display="flex"
                  justifyContent="center"
                  flexDirection="column"
                  padding="5px 0px 8px 12px"
                >
                  <Flex color="#101828" fontSize="18px">
                    {ACToggleTabs === 'advisor'
                      ? 'Select Advisors'
                      : ACToggleTabs === 'admin'
                      ? 'Select Administrators'
                      : ACToggleTabs === 'firm'
                      ? 'Select Firms'
                      : ''}
                  </Flex>
                  <Flex
                    display="flex"
                    flexDirection="row"
                    justifyContent="center"
                    alignItems="center"
                    style={{ height: '30px', padding: '5px 0px' }}
                  >
                    <Flex color="#475467" fontSize="14px">
                      {`${totalContactsCount.length} Selected`}
                    </Flex>
                    <Flex onClick={handleUnselectAll}>
                      <ul>
                        <li
                          style={{
                            fontSize: '14px',
                            cursor: 'pointer',
                            marginLeft: '-10px',
                          }}
                        >
                          Unselect All
                        </li>
                      </ul>
                    </Flex>
                  </Flex>
                </Flex>
                <SearchContainer
                  style={{
                    justifyContent: 'end',
                    margin: openSearch ? '9px 15px' : '10px 15px 10px',
                    width: '30%',
                    alignItems: 'center',
                  }}
                  searchActive={openSearch}
                >
                  <AutoPostSearchBar
                    setActiveSearch={setOpenSearch}
                    activeSearch={openSearch}
                    search={ACSearch}
                    setSearch={setACSearch}
                    searchResult={ACSearchResult}
                    setSearchResult={setACSearchResult}
                    searchBox={ACSearchBox}
                    setSearchBox={setACSearchBox}
                    toggleTabs={ACToggleTabs}
                  />
                </SearchContainer>
              </Flex>
              <Flex
                display="flex"
                padding="10px 5px 10px 10px"
                flexDirection="column"
                height="40px"
                style={{
                  borderBottom: '1px solid #e4e7ec',
                  borderTop: '1px solid #e4e7ec',
                  justifyContent: 'center',
                  color: '#101828',
                  textAlign: 'left',
                }}
              >
                {ACTargetTabs()}
              </Flex>
              <Flex
                display="flex"
                width="100%"
                flexDirection="row"
                style={{
                  backgroundColor: '#F9FAFB',
                  fontSize: '15px',
                  color: '#101828',
                  height: '60px',
                }}
              >
                <CheckBoxSpan
                  key={''}
                  screenWidth={window.innerWidth}
                  style={{
                    border: 'none',
                    margin: '0px 0px 4px 6px',
                    textAlign: 'left',
                    display: 'flex',
                    height: '60px',
                    alignItems: 'center',
                    width:
                      ACToggleTabs === 'firm'
                        ? '33.33%'
                        : ACToggleTabs === 'admin'
                        ? '25%'
                        : '35%',
                  }}
                >
                  <CheckBox
                    id="selectAll"
                    value="selectAll"
                    checked={
                      ACToggleTabs === 'advisor'
                        ? !isEmpty(selectedACAdvisorData) &&
                          selectedACAdvisorData.length ===
                            eligibleACAdvisorData.length
                        : ACToggleTabs === 'admin'
                        ? !isEmpty(selectedACAdminData) &&
                          selectedACAdminData.length ===
                            eligibleACAdminData.length
                        : ACToggleTabs === 'firm'
                        ? !isEmpty(selectedACFirmData) &&
                          selectedACFirmData.length ===
                            eligibleACFirmData.length
                        : null
                    }
                    onClick={handleAPSelectAll}
                  />
                  <CheckBoxLabel
                    fontSize="14px"
                    style={{
                      paddingLeft:
                        ACToggleTabs === 'firm'
                          ? '13.5%'
                          : ACToggleTabs === 'admin'
                          ? '18%'
                          : '13%',
                      //paddingTop: '5px',
                      width: '100%',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                    color="#848484"
                    htmlFor="selectAll"
                  >
                    Name
                  </CheckBoxLabel>
                </CheckBoxSpan>
                {ACToggleTabs === 'firm' ? (
                  <Flex
                    width="33.33%"
                    display="flex"
                    style={{ alignItems: 'center' }}
                  >
                    Eligible Advisors
                    <NewTooltip
                      TransitionComponent={Zoom}
                      arrow
                      placement="top"
                      title={acAdvisorEligibilityInfo()}
                    >
                      <img
                        alt=""
                        src="/assets/images/info-grey.svg"
                        style={{ marginLeft: '8px', cursor: 'pointer' }}
                      />
                    </NewTooltip>
                  </Flex>
                ) : (
                  <Flex
                    width={ACToggleTabs === 'advisor' ? '45%' : '35%'}
                    display="flex"
                    style={{ alignItems: 'center' }}
                  >
                    Email
                  </Flex>
                )}
                {ACToggleTabs === 'firm' ? (
                  <Flex
                    width="33.33%"
                    display="flex"
                    style={{ alignItems: 'center' }}
                  >
                    Eligible Administrators
                    <NewTooltip
                      TransitionComponent={Zoom}
                      arrow
                      placement="top"
                      title={acAdminEligibilityInfo()}
                    >
                      <img
                        alt=""
                        src="/assets/images/info-grey.svg"
                        style={{ marginLeft: '8px', cursor: 'pointer' }}
                      />
                    </NewTooltip>
                  </Flex>
                ) : ACToggleTabs === 'admin' ? (
                  <Flex
                    width="25%"
                    display="flex"
                    style={{
                      alignItems: 'center',
                      paddingLeft: ACToggleTabs === 'advisor' ? '4px' : '0px',
                    }}
                  >
                    Firm
                  </Flex>
                ) : null}
                {ACToggleTabs !== 'firm' && (
                  <Flex
                    width={ACToggleTabs === 'advisor' ? '20%' : '15%'}
                    display="flex"
                    style={{
                      alignItems: 'center',
                      paddingLeft: ACToggleTabs !== 'firm' ? '2px' : '0px',
                    }}
                  >
                    Status
                  </Flex>
                )}
              </Flex>
              {ACToggleTabs === 'advisor' && ACAdvisorList()}
              {ACToggleTabs === 'admin' && ACAdminList()}
              {ACToggleTabs === 'firm' && ACFirmList()}
            </Flex>
          )} */}

        <ModalContainer
          modalStatus={openSegmentModal}
          closeModal={() => setOpenSegmentModal(!openSegmentModal)}
          children={
            <CreateSegment
              closeModal={() => {
                setOpenSegmentModal(!openSegmentModal);
              }}
              isNewSegment
              fetchSegments={fetchSegments}
              setTableLoading={false}
              setListLoading={false}
              campaignFlow
            />
          }
          title="Create New Segment"
        />
      </AutomatedCampaignFormWrapper>

      <StepsFooterV2
        loader={loader}
        isEditMode={isEditMode}
        currentStepIndex={currentStepIndex}
        setCurrentStepIndex={setCurrentStepIndex}
        handleNext={() =>
          submitTargetInfo({ forSaveAndClose: false, forSaveAsDraft: false })
        }
        disableNext={false}
        //disableSaveAndClose={false}
        campaignSetState={campaignSetState}
        endCreateFlow={endCreateFlow}
        automatedCampaignDetails={automatedCampaignDetails}
        segmentListValid={segmentListValid}
        targetInfo={targetInfo}
        // handleSaveAndClose={async () => {
        //   await submitTargetInfo({ forSaveAndClose: true });
        //   const validation = shouldDisableNext();
        //   if (!validation) {
        //     campaignSetState({ automatedDashboard: true });
        //     history.push('/campaignV2');
        //   }
        // }}
      />
    </Box>
  ) : (
    <AutomatedCampaignFormWrapper>
      <Loader />
    </AutomatedCampaignFormWrapper>
  );
};

export default SelectTarget;
