import { takeLatest, put } from 'redux-saga/effects'
import { postEditorSettings } from '../../services/dashboard/index'
import { actions as loginActions } from '../modules/login.module';


export function* editorSettingsSaga(action) {
    yield takeLatest('SAVE_EDITOR_SETTINGS_INIT', saveEditorSettings);
}

function* saveEditorSettings(action) {
    // console.log('[FROM SAGA] ACTION - ', action);
    const response = yield postEditorSettings(action.payload);
    yield put(loginActions.api.login.request({noRefresh: true}));
}
