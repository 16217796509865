import React, { useEffect, useMemo, useState } from 'react';
import { parse } from 'json2csv';
import { format, subDays } from 'date-fns';
import { useHistory } from 'react-router-dom';
import { ChevronRight } from '@mui/icons-material';
import Button from 'src/componentsV2/Button';
import DialogModal from 'src/componentsV2/DialogModal';
import ZeroState from './ZeroState';
import Skeleton from './Skeleton';
import ContactList from './ContactList';
import CreateEmailList from './CreateEmailList';
import PeopleIcon from 'src/assets/icons/Users';
import UsersThree from 'src/assets/icons/UsersThree';
import UserFocus from 'src/assets/icons/UserFocus';
import useRESTQuery from 'src/hooks/useRESTQuery';
import { objectToFormData } from '../../../services/helpers';
import { uploadCsvContactList } from '../../../services/segment';
import { Link } from '../styles';
import {
  Contacts,
  Container,
  Header,
  Message,
  Title,
  TriangleShape,
  TriangleShape2,
} from './styles';

function TopContacts({ timePeriod, additionalQueryParams, isAdmin = false }) {
  const history = useHistory();
  const [showContactListModal, setShowContactListModal] = useState(false);
  const [displayState, setDisplayState] = useState('CONTACT_LIST');
  const [list, setList] = useState([]);

  const {
    data,
    isLoading,
    isFetching,
    refetch: refetchTopContacts,
  } = useRESTQuery({
    key: 'top-contacts',
    dependencies: [timePeriod, additionalQueryParams],
    endpoint: `/analytics/contacts/engagement?from_date=${format(
      subDays(new Date(), timePeriod),
      'yyyy-MM-dd'
    )}${!!additionalQueryParams ? `&${additionalQueryParams}` : ''}`,
    options: {
      select: (res) => res?.result,
      refetchOnMount: true,
    },
  });

  const contactsMetrics = useMemo(() => {
    if (data?.high_count > 0) {
      return {
        icon: <UsersThree />,
        count: data?.high_count,
        dataKey: 'HIGH',
        title: (
          <>
            You have <strong>{data?.high_count}</strong> contacts with{' '}
            <strong>High engagement</strong>
          </>
        ),
        description: `Create a new contact list for more targeted outreach to this group`,
        backgroundColor: '#E9EEEF',
        borderColor: '#457F8F',
        popupTitle: 'High Engagement',
      };
    } else if (data?.medium_count > 0) {
      return {
        icon: <PeopleIcon />,
        count: data?.medium_count,
        dataKey: 'MEDIUM',
        title: (
          <>
            You have <strong>{data?.medium_count}</strong> contacts with{' '}
            <strong>Average engagement</strong>
          </>
        ),
        description: `Create a new contact list for more targeted outreach to this group`,
        backgroundColor: '#FEF4E6',
        borderColor: '#F8C37E',
        popupTitle: 'Average Engagement',
      };
    } else if (data?.low_count > 0) {
      return {
        icon: <UserFocus />,
        count: data?.low_count,
        dataKey: 'LOW',
        title: (
          <>
            You have <strong>{data?.low_count}</strong> contacts with{' '}
            <strong>Low engagement</strong>
          </>
        ),
        description: `Create a new contact list for more targeted outreach to this group`,
        backgroundColor: '#FEF4E6',
        borderColor: '#F8C37E',
        popupTitle: 'Low Engagement',
      };
    } else {
      return null;
    }
  }, [data]);

  const {
    data: recommendedContacts,
    refetch: refetchRecommendedContacts,
    isSuccess,
  } = useRESTQuery({
    key: 'top-contacts-list',
    dependencies: [timePeriod, contactsMetrics?.dataKey],
    endpoint: `/analytics/contacts/recommendation?from_date=${format(
      subDays(new Date(), timePeriod),
      'yyyy-MM-dd'
    )}&engagements=["${contactsMetrics?.dataKey}"]&is_csv=false${
      !!additionalQueryParams ? `&${additionalQueryParams}` : ''
    }`,
    options: {
      select: (res) => res?.result ?? [],
      enabled: !!contactsMetrics?.dataKey,
      refetchOnMount: true,
    },
  });

  useEffect(() => {
    if (Array.isArray(recommendedContacts)) {
      setList(recommendedContacts);
    }
  }, [recommendedContacts]);

  const getDialogTitle = () => {
    switch (displayState) {
      case 'CREATE_EMAIL_LIST':
        return 'Create Email list';

      default:
        return `${list?.length || 0} Contacts - ${contactsMetrics?.popupTitle}`;
    }
  };

  const handleContactListSubmission = async (id, selectedTags) => {
    try {
      const data = parse(list, {
        fields: ['first_name', 'last_name', 'email'],
      });

      const csvBlob = new Blob([data], { type: 'text/csv' });
      const csvFile = new File([csvBlob], 'email_list.csv', {
        type: 'text/csv',
        lastModified: new Date(),
      });

      const payload = await objectToFormData({
        media1: csvFile,
        tags: JSON.stringify(selectedTags),
      });

      const response = await uploadCsvContactList(id, payload);

      if (response) {
        setShowContactListModal(false);
        setDisplayState('CONTACT_LIST');
        setList(recommendedContacts);
        refetchTopContacts();
        refetchRecommendedContacts();
        history.push(isAdmin ? '/admin/contacts' : '/contacts');
      }
    } catch (error) {
      console.log(error);
    }
  };

  if (isLoading || isFetching) return <Skeleton />;
  return (
    <div>
      {contactsMetrics ? (
        <Container>
          <Header>
            <Title>Top Contacts</Title>
            <Link
              href={`${isAdmin ? '/admin' : ''}/analytics?section=contacts`}
            >
              <span>Contacts Engagement</span>
              <ChevronRight />
            </Link>
          </Header>
          <Contacts>
            <h5>{contactsMetrics?.count ?? '-'}</h5>
            <Message
              bgColor={contactsMetrics?.backgroundColor}
              borderColor={contactsMetrics?.borderColor}
            >
              <TriangleShape borderColor={contactsMetrics?.borderColor} />
              <TriangleShape2 borderColor={contactsMetrics?.backgroundColor} />
              {contactsMetrics?.icon ?? <></>}
              <div>
                <h5>{contactsMetrics?.title}</h5>
                <p>{contactsMetrics?.description}</p>
              </div>
            </Message>
          </Contacts>
          <Button
            variant="outline"
            fullWidth
            onClick={() => setShowContactListModal(true)}
            disabled={!isSuccess}
          >
            Create a Contact list
          </Button>
        </Container>
      ) : (
        <ZeroState isAdmin={isAdmin} />
      )}

      <DialogModal
        show={showContactListModal}
        toggleModalVisibility={(showModal) => {
          if (!showModal) {
            setShowContactListModal(false);
            setDisplayState('CONTACT_LIST');
            setList(recommendedContacts);
          }
        }}
        title={getDialogTitle()}
        maxWidth="xs"
        showTitle
      >
        <div>
          {(() => {
            switch (displayState) {
              case 'CREATE_EMAIL_LIST':
                return (
                  <CreateEmailList
                    setDisplayState={setDisplayState}
                    handleContactListSubmission={handleContactListSubmission}
                  />
                );

              default:
                return (
                  <ContactList
                    list={list}
                    setList={setList}
                    setDisplayState={setDisplayState}
                  />
                );
            }
          })()}
        </div>
      </DialogModal>
    </div>
  );
}

export default TopContacts;
