import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Flex } from '@rebass/grid';
import React from 'react';
import {
  Button,
  ButtonNoBorderBackground,
  LoaderWrapper,
} from '../../../components';
import { DripFooterSection } from './CreateCampaign.styles';

const CreateCampaignFooter = ({ backHandler, loading, padding }) => {
  return (
    <DripFooterSection style={{ padding: padding }}>
      <LoaderWrapper isLoading={loading}>
        <Flex />
        <Flex>
          <ButtonNoBorderBackground
            color="#000000"
            onClick={backHandler}
            style={{ margin: '5px' }}
          >
            <FontAwesomeIcon icon={faChevronLeft} />
            &nbsp; Back &nbsp;
          </ButtonNoBorderBackground>
        </Flex>
      </LoaderWrapper>
    </DripFooterSection>
  );
};

export default CreateCampaignFooter;
