import React from 'react'
import { Modal, ModalBody, ModalHeader, ModalCloseButton, ResponsiveImg } from '../../../components'
import { SuccessMessage } from './MioEditor.styles';

const MioSuccessModal = ({ openModal, onRequestClose }) => {
  return (
    <Modal size='small' isOpen={openModal} onRequestClose={onRequestClose}>
      <ModalHeader>
        <ModalCloseButton onClick={onRequestClose} />
      </ModalHeader>
      <ModalBody>
        <ResponsiveImg src='/assets/images/Group 310.svg' alt='Upload Success' asMioSuccessModal/>
        <SuccessMessage>Logo updated successfully</SuccessMessage>
      </ModalBody>
    </Modal>
  )
}

export default MioSuccessModal;
