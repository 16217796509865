import React from 'react';
import { getMediaUrl, themesData } from '../../utils';
import { ConnectMeWrapper } from './ContentContainer.styles';
import { Button } from '../../components';

const DigitalCard = ({
  handleButtonClick,
  position,
  top,
  right,
  mode,
  name,
  firm_name,
  mobile,
  email,
  about_me,
  logo,
  facebook,
  linkedIn,
  twitter,
  userData,
  is_mobile,
  bottom
}) => {
  const getLeadImages = () => {
    // const images = getMediaImage(logo);
    if (logo) {
      return logo;
    } else {
      return '/assets/images/profilePicDef.jpg';
    }
  };

  const getProfileImages = () => {
    if (logo && logo.length > 0) {
      return logo;
    } else if (
      userData &&
      userData.email_personalization &&
      userData.email_personalization.media_urls &&
      userData.email_personalization.use_logo === 'true'
    ) {
      return getMediaUrl(
        userData.email_personalization.media_urls,
        '',
        'original'
      );
    } else if (
      userData &&
      userData.personalization &&
      userData.personalization.corporate_identity &&
      userData.personalization.corporate_identity.logo &&
      userData.personalization.corporate_identity.logo.main &&
      userData.personalization.corporate_identity.logo.main.media_urls &&
      userData.email_personalization.use_logo === 'true'
    ) {
      return getMediaUrl(
        userData.personalization.corporate_identity.logo.main.media_urls,
        '',
        'original'
      );
    } else {
      return '/assets/images/profilePicDef.jpg';
    }
  };

  return (
    <ConnectMeWrapper
      height="auto"
      position={position}
      top={top}
      right={right}
      bottom={bottom}
    >
      {!is_mobile ? <img
        width="75%"
        height="50%"
        className="img-wrapper"
        src={mode === 'lead' ? getLeadImages() : getProfileImages()}
        alt=""
        style={{ borderRadius: '5px', width: '85%' }}
      /> : ''}
      <div
        style={{
          fontWeight: '600',
          fontSize: '14px',
          fontFamily: 'Poppins',
          //   paddingBottom: '5px',
          color: '#000',
          textAlign: 'left',
        }}
      >
        {name ? name : '-'}
      </div>
      {firm_name && (
        <div
          style={{
            fontSize: '12px',
            fontFamily: 'Poppins',
            textAlign: 'left',
            color: '#000',
          }}
        >
          {firm_name ? firm_name : ''}
        </div>
      )}
      <div
        style={{
          fontSize: '12px',
          fontFamily: 'Poppins',
          textAlign: 'left',
          color: '#000',
        }}
      >
        {mobile ? mobile : ''}
      </div>
      <div
        style={{
          fontSize: '12px',
          fontFamily: 'Poppins',
          textAlign: 'left',
          paddingBottom: '5px',
          color: '#6351ed',
          textDecoration: 'underline',
          wordBreak: 'break-all'
        }}
      >
        {email ? (
          mode === 'profile' ? (
            email
          ) : (
            <a href={'mailto:' + email}>{email}</a>
          )
        ) : (
          ''
        )}
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'flex-start',
          alignSelf: 'flex-start',
          paddingBottom: '10px',
        }}
      >
        {facebook && (
          <img
            alt=""
            src="/assets/images/campaign-platform-facebook.png"
            style={{
              marginRight: '5px',
              width: '10%',
              cursor: mode === 'lead' ? 'pointer' : '',
            }}
            onClick={
              mode === 'lead' ? () => window.open(facebook, '_blank') : ''
            }
          />
        )}
        {linkedIn && (
          <img
            alt=""
            src="/assets/images/campaign-platform-linkedIn.png"
            style={{
              marginRight: '5px',
              width: '10%',
              cursor: mode === 'lead' ? 'pointer' : '',
            }}
            onClick={
              mode === 'lead' ? () => window.open(linkedIn, '_blank') : ''
            }
          />
        )}
        {twitter && (
          <img
            alt=""
            src="/assets/images/campaign-platform-twitter.png"
            style={{
              marginRight: '5px',
              width: '10%',
              cursor: mode === 'lead' ? 'pointer' : '',
            }}
            onClick={
              mode === 'lead' ? () => window.open(twitter, '_blank') : ''
            }
          />
        )}
      </div>
      <div
        style={{
          fontSize: '10px',
          fontWeight: '600',
          fontFamily: 'Poppins',
          textAlign: 'left',
          color: themesData.charcoalGrey,
        }}
      >
        {about_me ? about_me : null}
      </div>
      <Button
        onClick={mode === 'lead' ? handleButtonClick : ''}
        height="32px"
        width="185px"
        cursor={mode === 'lead' ? 'pointer' : 'auto'}
        margin='10px 0px 0px 0px'
      >
        Get Connected
      </Button>
    </ConnectMeWrapper>
  );
};

export default DigitalCard;
