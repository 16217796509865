import * as React from "react";

function Note(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 16 16" fill="none" {...props}>
      <path
        d="M6 6h4M6 8h4M6 10h2M9.794 13.5H3a.5.5 0 01-.5-.5V3a.5.5 0 01.5-.5h10a.5.5 0 01.5.5v6.794a.496.496 0 01-.144.35l-3.212 3.212a.496.496 0 01-.35.144v0z"
        stroke="currentColor"
        strokeWidth={1.6}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.456 10H10v3.456"
        stroke="currentColor"
        strokeWidth={1.6}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

const MemoNote = React.memo(Note);
export default MemoNote;
