/* eslint-disable jsx-a11y/anchor-is-valid */
import { Flex } from '@rebass/grid';
import React from 'react';
import { Button } from '../../components';

const UnsubscribeConfirmation = ({
  email,
  handleClick,
  generalNewsletters,
  ecards,
  firmUpdates,
  events,
  redirectToUnsubscribe,
  digitalMarketing,
}) => {
  return (
    <>
      <div
        style={{
          padding: '30px 20px 0px',
        }}
      >
        <img alt="" src="/assets/images/unsubscribebag.svg" />
      </div>
      <div
        style={{
          fontSize: window.innerWidth < 600 ? '18px' : '30px',
          fontWeight: '500',
          marginTop: '20px',
          padding: '10px 10px',
          lineHeight: '34px',
          width: window.innerWidth < 600 ? '90%' : '100%',
          height: '34px',
          textAlign: 'center',
          marginBottom: '20px',
          color: '#816EF8',
          marginLeft: window.innerWidth < 600 ? 'auto' : '0px',
          marginRight: window.innerWidth < 600 ? 'auto' : '0px',
        }}
      >
        We are sorry to see you go!
      </div>
      {window.innerWidth < 600 ? (
        <Flex
          color="#101828"
          mt="15px"
          fontSize="11px"
          justifyContent="center"
          flexDirection="column"
        >
          <Flex display="flex" justifyContent="center">
            Your Email Address: &nbsp;
          </Flex>
          <Flex marginLeft="auto" marginRight="auto">
            <a href={`mailto:${email}`}>{email}</a>
          </Flex>
        </Flex>
      ) : (
        <Flex color="#101828" mt="15px" fontSize="11px" justifyContent="center">
          Your Email Address: &nbsp; <a href={`mailto:${email}`}>{email}</a>
        </Flex>
      )}
      <Flex width="100%" justifyContent="center">
        <div
          style={{
            width: window.innerWidth < 600 ? '95%' : '55%',
            height: window.innerWidth < 600 ? '68px' : '55px',
            border: '1px solid #6250ED',
            borderRadius: '8px',
            marginLeft: window.innerWidth < 600 ? 'auto' : '0px',
            marginRight: window.innerWidth < 600 ? 'auto' : '0px',
            marginTop: '60px',
            boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.12)',
            background: 'rgb(88, 80, 237, 0.1)',
            fontSize: '14px',
            color: '#101828',
            padding: '13px 10px 10px 10px',
            textAlign: 'left',
          }}
        >
          <img
            alt=""
            src="/assets/images/information-blue.svg"
            style={{
              width: '15px',
              height: '15px',
              marginLeft: '3px',
              marginRight: '12px',
              marginBottom: '-3px',
            }}
          />
          Based on your saved preferences you will no longer receive any
          communications
        </div>
      </Flex>
      <hr
        style={{
          border: '1px solid #E4E7EC',
          width: '100%',
          marginTop: '25px',
          marginBottom: '25px',
        }}
      />
      <div
        style={{
          fontSize: '11px',
          width: window.innerWidth < 600 ? '90%' : '100%',
          color: '#475467',
          padding: '0px 0px 10px 0px',
          marginLeft: window.innerWidth < 600 ? 'auto' : '0px',
          marginRight: window.innerWidth < 600 ? 'auto' : '0px',
        }}
      >
        To edit your preferences or resubscribe to any of the above, please
        click here
      </div>
      <div style={{ marginBottom: '20px' }}>
        <Button
          styles={{ borderRadius: '4px', width: '180px', fontSize: '12px' }}
          type="button"
          margin="10px 40px 0px 60px"
          onClick={handleClick}
        >
          Manage Subscriptions
        </Button>
      </div>
    </>
  );
};

export default UnsubscribeConfirmation;
