import { Flex } from '@rebass/grid';
import styled from 'styled-components';

export const ContentArticlesContainer = styled(Flex)`
  flex-direction: column;
  border: 1px solid #cfd9e4;
  border-radius: 4px;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding: 10px;
  height: 460px;
  margin-bottom: ${({ mb }) => mb || '25px'};
`;

export const SuggestedArticlesTab = styled.div`
  border: 0px;
  border-bottom: ${({ active }) => (active ? '3px solid #6351ed' : '0')};
  color: ${({ active, disabled }) => (active ? '#6351ed' : disabled ? '#adadad' : '#000000')};
  font-weight: 600;
  font-size: 14px;
  margin-right: 35px;
  cursor: pointer;
  height: 30px;
`;

export const SummaryInstructionDiv = styled.div`
  height: 299px;
  overflow-y: auto;
  text-align: left;
  color: #4e6780;
  font-size: 12px;
  border: 1px solid #cfd9e4;
  margin-top: 10px;
  background-color: #fff;
  border-radius: 4px;
  width: 100%;
  padding: 5px;
`;
