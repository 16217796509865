import React, { useState } from 'react';
import {
  LoaderWrapper,
  Modal,
  ModalBody,
  ModalCloseButton,
} from '../../../../components';
import { cloneOrSaveCampaign } from '../../../../services/campaign';
import { HoverButton } from '../../../ConfigureContainer/EmailList/EmailList.styles';

export const SaveDupCampaignModal = ({
  isSave,
  isOpen,
  handleClose,
  campaignId,
  campaignRequest,
  seriesId,
  setFilters,
  setSelectedCampaignTab,
  canDuplicate,
}) => {
  console.log('CAN DUPLICATE (SAVE DUP MODAL) - - - ', canDuplicate);
  const [loading, setLoading] = useState(false);
  const clickHandler = (isAgreed) => {
    if (isAgreed) {
      let payload = {
        ...(campaignId ? { campaign_id: campaignId } : {}),
        ...(seriesId ? { series_id: seriesId } : {}),
        // campaign_id: campaignId,
      };
      if (isSave) 
        payload.template = 'true';
      else 
        payload.template = 'false';
      
      setLoading(true);

      cloneOrSaveCampaign(payload)
        .then((res) => {
          if (res.result.success) {
            setLoading(false);
            setFilters();
            setSelectedCampaignTab(0);
            campaignRequest();
          }
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
        });
    } else {
      handleClose(true);
    }
  };
  return (
    <Modal
      onRequestClose={handleClose}
      shouldCloseOnOverlayClick
      isOpen={isOpen}
      size={canDuplicate === 'true' ? 'small' : 'medium-small'}
    >
      <ModalCloseButton onClick={handleClose} />
      <ModalBody>
        <div
          style={{
            fontWeight: 'bold',
            fontSize: '16px',
            color: '#6351ed',
            fontFamily: 'Poppins',
          }}
        >
          {canDuplicate === 'true' ? (isSave ? 'Are you sure you want to save as template?' : 'Are you sure you want to duplicate this campaign?') : 'Campaign cannot be duplicated! Template used in this campaign has been deleted.'}
        </div>
        {canDuplicate === 'true' && (
          <LoaderWrapper isLoading={loading}>
            <HoverButton
              margin="9px 10px 0px 0px"
              padding="5px 28px"
              bgColor="#fff"
              hoverBackground="#6351ed"
              hoverColor="#fff"
              color="#6351ed"
              borderColor="#fff"
              onClick={() => clickHandler(true)}
            >
              Yes
            </HoverButton>
            <HoverButton
              margin="9px 0px 0px 0px"
              padding="5px 28px"
              bgColor="#fff"
              color="#6351ed"
              hoverBackground="#6351ed"
              hoverColor="#fff"
              borderColor="#fff"
              onClick={() => clickHandler(false)}
            >
              No
            </HoverButton>
          </LoaderWrapper>
        )}
      </ModalBody>
    </Modal>
  );
};
