/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import { ResponsiveImg } from '../';
import { StyledArticle } from './MiniArticle.styles';
import { onImageError } from '../../utils';
import moment from 'moment';
import { Modal, ModalBody, ModalCloseButton, ModalHeader } from '../Modal';
import { ImagePreviewModal } from '../ShareContentContainerV2/Elements/Modals';

const MiniArticle = ({
  link,
  url = '/',
  topic,
  created,
  image,
  place,
  date,
  year,
  source,
  pdfImage,
  isPdf,
  isFromAddContent,
  isFromEditorTab,
  pdfUrl,
  fromCustomizeShareModal,
  isComplianceAdmin,
  mediaUrl,
  complianceTab,
  isFromImageOnly,
  fromImagePreviewTab,
  userSummary,
  fromUploadSuccess,
}) => {
  const time = moment(created).format('LL');
  console.log(pdfUrl);
  const [openImageModal, toggleImageModal] = useState(false);

  //  console.log('IS COMPLIANCE ADMIN - - - - -', isComplianceAdmin);
  //  console.log('IMAGE - - - - -', mediaUrl[0].path + mediaUrl[0].name.system + mediaUrl[0].ext);
  //  console.log('COMPLIANCE TAB - - - - -', complianceTab);
  // console.log('IS FROM IMAGE ONLY - - - ', isFromImageOnly);

  const redirectHandler = () => {
    // console.log('MEDIA URL - - - - ', mediaUrl);
    const urlObj = mediaUrl && mediaUrl[0] ? mediaUrl[0] : mediaUrl;
    if (urlObj) {
      const imgUrl = urlObj.path + urlObj.name.system + '.' + urlObj.ext;
      window.open(imgUrl, '_blank');
    }
  };

  return (
    <>
      <StyledArticle
        onClick={
          (isComplianceAdmin &&
            source === 'img' &&
            complianceTab === 'pending') ||
          (!isComplianceAdmin &&
            source === 'img' &&
            complianceTab === 'rejected') ||
          isFromImageOnly
            ? () => toggleImageModal(true)
            : !complianceTab && source === 'img'
            ? redirectHandler
            : () => {}
        }
        isFromAddContent={isFromAddContent}
        style={
          fromCustomizeShareModal
            ? { width: '90%' }
            : {
                cursor:
                  (isComplianceAdmin &&
                    source === 'img' &&
                    complianceTab === 'pending') ||
                  (!isComplianceAdmin && complianceTab === 'rejected') ||
                  source === 'img'
                    ? 'pointer'
                    : 'default',
              }
        }
      >
        {/* {console.log('here 1', isFromAddContent)} */}
        <div>
          <ResponsiveImg
            src={image ? image : pdfImage ? pdfImage : url}
            className="mini-img"
            onError={(e) => onImageError(e, source)}
          />
          <div
            className="mini-details-wrapper"
            style={
              isFromAddContent
                ? { background: '#e5e5e5', maxHeight: '115px' }
                : { maxHeight: '98px' }
            }
          >
            <a
              href={
                source === 'img' || isFromImageOnly
                  ? '#'
                  : isFromEditorTab && pdfUrl
                  ? pdfUrl
                  : link
              }
              target={source === 'img' || isFromImageOnly ? '' : '_blank'}
              rel="noopener noreferrer"
              style={{ padding: isFromAddContent ? '7px 0px 0px' : '' }}
            >
              <div
                className={`post-heading ${
                  isFromAddContent ? 'article-style' : ''
                }`}
                title={topic}
                style={{
                  cursor: isPdf ? 'default' : 'pointer',
                  wordBreak: 'break-word',
                  overflow: 'hidden',
                  display: '-webkit-box',
                  // WebkitLineClamp: fromUploadSuccess && userSummary && userSummary.length > 0 ? 1 : 3,
                  WebkitLineClamp:
                    userSummary && userSummary.length > 0 ? 1 : 3,
                  WebkitBoxOrient: 'vertical',
                }}
              >
                <div
                  className={`post-heading ${
                    isFromAddContent ? 'article-style' : ''
                  }`}
                  title={topic}
                  style={{
                    cursor: isPdf ? 'default' : 'pointer',
                    wordBreak: 'break-word',
                    overflow: 'hidden',
                    display: '-webkit-box',
                    WebkitLineClamp: 3,
                    WebkitBoxOrient: 'vertical',
                  }}
                >
                  {topic}
                </div>
              </div>
              {/* <div className="place-date">
                {time}
              </div> */}
              <div
                className="place-date"
                style={{ padding: isFromAddContent ? '2px 0px' : '' }}
              >
                {time}
                {/* {date && `Published ${date}, ${year}`} */}
              </div>
            </a>

            {/* {fromUploadSuccess && userSummary && userSummary.length > 0 && ( */}
            {userSummary && userSummary.length > 0 && (
              <div
                title={userSummary}
                style={{
                  cursor: 'pointer',
                  wordBreak: 'break-word',
                  overflow: 'hidden',
                  display: '-webkit-box',
                  WebkitLineClamp: 3,
                  WebkitBoxOrient: 'vertical',
                  fontSize: '11px',
                  color: '#7b7b7b',
                }}
              >
                {userSummary.length < 70
                  ? userSummary
                  : `${userSummary.substring(0, 67)}...`}
              </div>
            )}
          </div>
        </div>
      </StyledArticle>
      {openImageModal && (
        <ImagePreviewModal
          openImageModal={openImageModal}
          toggleImageModal={toggleImageModal}
          isFromImageOnly={isFromImageOnly}
          mediaUrl={isFromImageOnly ? pdfImage : mediaUrl}
        />
        // <Modal size='flexible' height='auto' shouldCloseOnOverlayClick isOpen={openImageModal} onRequestClose={e => toggleImageModal(false)} >
        //   <ModalHeader>
        //       <ModalCloseButton onClick={() => {
        //         console.log('CLICKED - - - - ');
        //         toggleImageModal(false);
        //       }} />
        //   </ModalHeader>
        //   <ModalBody>
        //       <div style={{textAlign: 'center', overflow: 'auto', maxHeight: '500px', maxWidth: '1000px', margin: 'auto'}}>
        //           <img onError={onImageError} src={mediaUrl && mediaUrl[0].path + mediaUrl[0].name.system + '.' + mediaUrl[0].ext} />
        //           {console.log('IMAGE MODAL RENDERING- -- -- - -', image)}
        //       </div>
        //   </ModalBody>
        // </Modal>
      )}
    </>
  );
};

export default MiniArticle;
