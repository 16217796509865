import styled from 'styled-components';
import { Flex } from '@rebass/grid';

export const CampaignFilterButton = styled(Flex).attrs({
  className: 'campaign-filter-btn',
})`
  justify-content: center;
  align-items: center;
  height: 60px;
  font-size: ${({ filterBtn }) => (filterBtn ? '14px' : '12px')};
  width: 12%;
  background-color: ${({ selected }) => (selected ? '#fff' : '#eeeeee')};
  cursor: pointer;
  border-radius: 4px;
  ${({ selected, name }) => {
    let colorHex = '#6351ed';
    if (name === 'All Campaigns') {
      colorHex = '#6351ed';
    } else if (name === 'Drafts') {
      colorHex = '#FFAA00';
    } else if (name === 'Scheduled') {
      colorHex = '#00d7ff';
    } else if (name === 'In Progress') {
      colorHex = '#326DE6';
    } else if (name === 'Completed') {
      colorHex = '#33B21B';
    }

    return (
      selected &&
      `
        border-bottom: 4px solid ${colorHex};
    `
    );
  }}
`;
