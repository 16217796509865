import styled from 'styled-components';
import { colors } from '../../styles';
import { Switch } from '@mui/material';

export const SwitchContainerRow = styled('div')`
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: ${({ padding }) => padding ? padding : '10px 0'};
    background: ${colors.white};
    border-top: ${({ index, showBorder }) => index > 0 && showBorder ? `1px solid ${colors.gray_border}` : 0};
    margin: ${({ margin }) => margin ? margin : 0};
`;

export const SwitchContainerIndividual = styled('div')`
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: ${({ padding }) => padding ? padding : '10px 0'};
    background: ${colors.white};
    border: 1px solid ${colors.gray_border};
    margin: ${({ margin }) => margin ? margin : '10px 0'};
    border-radius: ${({ borderRadius }) => borderRadius ? borderRadius : '8px'};
    box-shadow: ${({ boxShadow }) => boxShadow || '0px 1px 2px 0px #1018281F'};
`;

export const SwitchAction = styled('div')`
   display: flex;
   width: fit-content;
   margin: 0 20px 0 0;
`;

export const SwitchLabel = styled('div')`
    font-weight: 500;
    text-align: left;
    font-size: 16px;
    margin: 11px 0 0 20px;
    color: ${colors.black};
`;

export const SwitchSubTitle = styled('div')`
    font-weight: 400;
    font-size: 14px;
    text-align: left;
    margin: 0px 0 15px 20px;
    color: ${colors.gray_text};
`;

export const EnabledText = styled('div')`
    font-weight: 400;
    font-size: 14px;
    margin: 10px 3px;
    color: ${colors.black_01};
`;

export const SwitchStyle = styled(Switch)`
    margin: 2px 0 0;
    & .MuiSwitch-thumb {
        boxShadow: 0px 0px 25px 0px rgba(63, 106, 194, 0.15);
        color: #fff;
        border: 1px solid #6351ed;
    };
    & .MuiSwitch-switchBase {
        &.Mui-checked {
        & + .MuiSwitch-track {
            opacity: 1;
            background-color: #816EF8 !important;  
        }
        }
    };
    & .MuiSwitch-track {
        background-color: #797d83,
    };
`;


export const ThemeStyle = styled('div')`
    color: ${({ color }) => color ? color : '#0B5CD7'};
    background: ${({ background }) => background ? background : `${colors.light_blue}`};
    border: ${({ color, border }) => color && !border ? `1px solid ${color}` : border ? border : `${colors.blue_link_text}`};
    padding: 5px 8px; 
    border-radius: 20px;
    white-space: nowrap;
    width: fit-content;
    font-size: 12px;
    font-weight: 400;
    margin: 4px;
    height: 30px;
`;


