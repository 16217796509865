import React from 'react';
import { MioRadio, MioRadioSpan } from '../../';
import { Flex, Box } from '@rebass/grid';

const ComplianceReview = ({
  isFromCompliance,
  showComplianceCheck,
  userData,
  setSelectedComplianceCheck,
  selectedComplianceCheck,
  isFromScheduledTab,
  approvedArticles,
  paddingTop,
  jt
}) => {
  const handleComplianceCheckChange = (val) => {
    setSelectedComplianceCheck(val);
  };
  return (
    <>
      {(!isFromScheduledTab && !approvedArticles && !isFromCompliance && (( showComplianceCheck && userData.compliance_admin && userData.compliance === 'optional') || userData.compliance === 'optional')) && (
        <Flex
          width={1}
          alignItems="center"
          justifyContent={jt || "center"}
          px="0px"
          pt={paddingTop || '17px'}
        >
          <Box style={{ textAlign: 'center', fontSize: '12px', marginRight: '6px' }}>Does this require compliance review?</Box>
          <Box style={{ marginRight: '13px' }}>
            <Flex width={1} alignItems="center" pl="20px">
              <MioRadioSpan noBorder width="auto">
                <MioRadio
                  id={'comlianceCheckYes'}
                  name="comlianceCheck"
                  onClick={() => handleComplianceCheckChange('yes')}
                  defaultChecked={selectedComplianceCheck === 'yes'}
                  margin="19% 16% 5%"
                />
                <label htmlFor="comlianceCheckYes">Yes</label>
              </MioRadioSpan>
              <MioRadioSpan noBorder defaultChecked={selectedComplianceCheck === 'no'} width="auto">
                <MioRadio
                  id={'comlianceCheckNo'}
                  name="comlianceCheck"
                  onClick={() => handleComplianceCheckChange('no')}
                  defaultChecked={selectedComplianceCheck === 'no'}
                  margin="19% 16% 5%"
                />
                <label htmlFor="comlianceCheckNo">No</label>
              </MioRadioSpan>
            </Flex>
          </Box>
        </Flex>
      )}
    </>
  )
}

export default ComplianceReview;
