/* eslint-disable react-hooks/exhaustive-deps */
import { Flex } from '@rebass/grid';
import React, { useState, useEffect } from 'react';
import DripDashboardFilter from '../DripDashboardFilter/DripDashboardFilter';
import DripDashboardTable from '../DripDashboardTable/DripDashboardTable';
import { isEmpty } from 'lodash';

const DripCampaignDashboard = ({
  createFlow,
  setCreateFlow,
  campaignSetState,
  campaignFetching,
  campaignsData,
  socialThemes,
  editCampaignHandler,
  editLoader,
  campaignRequest,
  detailsHandler,
  changeCreateFlow,
}) => {
  const filterBtns = [
    { name: 'FILTER', img: '/assets/images/campaign-filter.svg' },
    { name: 'CAMPAIGN', img: '/assets/images/campaign-plus.svg' },
  ];
  const [campaignDetailsView, setCampaignDetailsView] = useState(false);
  const [filterTabs, updateFilterTabs] = useState([
    {
      name: 'All Campaigns',
      img: {
        active: '/assets/images/campaign-mic.svg',
        disabled: '/assets/images/campaign-mic-disabled.svg',
      },
      count: 0,
    },
    {
      name: 'Drafts',
      img: {
        active: '/assets/images/drip/campaign-drafts.svg',
        disabled: '/assets/images/campaign-drafts-disabled.svg',
      },
      count: 0,
    },
    {
      name: 'Scheduled',
      img: {
        active: '/assets/images/drip/campaign-scheduled.svg',
        disabled: '/assets/images/campaign-scheduled-disabled.svg',
      },
      count: 0,
    },
    {
      name: 'In Progress',
      img: {
        active: '/assets/images/drip/campaign-in-progress.svg',
        disabled: '/assets/images/campaign-in-progress-disabled.svg',
      },
      count: 0,
    },
    {
      name: 'Completed',
      img: {
        active: '/assets/images/drip/campaign-completed.svg',
        disabled: '/assets/images/campaign-completed-disabled.svg',
      },
      count: 0,
    },
  ]);

  const [selectedCampaignTab, setSelectedCampaignTab] = useState(0);
  const [fullCampaignData, setFullCampaignData] = useState([]);
  const [filters, setFilters] = useState();

  useEffect(() => {
    if (!isEmpty(filters)) {
      campaignRequest(filters);
      setSelectedCampaignTab();
    }
  }, [filters]);

  useEffect(() => {
    if (!isEmpty(campaignsData)) {
      const filterTabCopy = [...filterTabs];
      console.log('campaignsData', campaignsData);

      // filterTabCopy[0].count =
      //   !isEmpty(campaignsData?.campaigns) && campaignsData.campaigns.length;
      // filterTabCopy[1].count =
      //   !isEmpty(campaignsData?.campaigns) &&
      //   campaignsData.campaigns.filter((ele) => ele.status === 'draft').length;
      // filterTabCopy[2].count =
      //   !isEmpty(campaignsData?.campaigns) &&
      //   campaignsData.campaigns.filter((ele) => ele.status === 'scheduled')
      //     .length;
      // filterTabCopy[3].count =
      //   !isEmpty(campaignsData?.campaigns) &&
      //   campaignsData.campaigns.filter((ele) => ele.status === 'in_progress')
      //     .length;
      // filterTabCopy[4].count =
      //   !isEmpty(campaignsData?.campaigns) &&
      //   campaignsData.campaigns.filter((ele) => ele.status === 'completed')
      //     .length;

      filterTabCopy[0].count =
        campaignsData && campaignsData?.details && campaignsData?.details.all;
      filterTabCopy[1].count =
        campaignsData && campaignsData?.details && campaignsData?.details.draft;
      filterTabCopy[2].count =
        campaignsData &&
        campaignsData?.details &&
        campaignsData?.details.scheduled;
      filterTabCopy[3].count =
        campaignsData &&
        campaignsData?.details &&
        campaignsData?.details.in_progress;
      filterTabCopy[4].count =
        campaignsData &&
        campaignsData?.details &&
        campaignsData?.details.completed;

      setFullCampaignData(campaignsData?.campaigns);
      updateFilterTabs(filterTabCopy);
    }
  }, [campaignsData]);

  const onFilterTabChange = (index) => {
    // if (index === 1) {
    //   campaignSetState({
    //     createFlow: true,
    //     campaignId: '',
    //     setObjectives: {},
    //   });
    // }
    setSelectedCampaignTab(index);
    setFilters();
    // filterTabs[index].name.toLowerCase()
    let tabSelectedData =
      !isEmpty(campaignsData?.campaigns) && campaignsData?.campaigns;
    if (index === 0) {
      campaignRequest();
    } else if (index === 1) {
      campaignRequest({ status: ['draft'] });
      tabSelectedData =
        !isEmpty(campaignsData?.campaigns) &&
        campaignsData.campaigns.filter((ele) => ele.status === 'draft');
    } else if (index === 2) {
      campaignRequest({ status: ['scheduled'] });
      tabSelectedData =
        !isEmpty(campaignsData?.campaigns) &&
        campaignsData.campaigns.filter((ele) => ele.status === 'scheduled');
    } else if (index === 3) {
      campaignRequest({ status: ['in_progress'] });
      tabSelectedData =
        !isEmpty(campaignsData?.campaigns) &&
        campaignsData.campaigns.filter((ele) => ele.status === 'in_progress');
    } else if (index === 4) {
      campaignRequest({ status: ['completed'] });
      tabSelectedData =
        !isEmpty(campaignsData?.campaigns) &&
        campaignsData.campaigns.filter((ele) => ele.status === 'completed');
    }
    setFullCampaignData(tabSelectedData);
  };

  return (
    <Flex width="100%" height="100%" flexDirection="column">
      {!campaignDetailsView && (
        <DripDashboardFilter
          createFlow={createFlow}
          setCreateFlow={setCreateFlow}
          filterTabs={filterTabs}
          filterBtns={filterBtns}
          selectedCampaignTab={selectedCampaignTab}
          setSelectedCampaignTab={(index) => onFilterTabChange(index)}
          setCampaignDetailsView={setCampaignDetailsView}
          campaignDetailsView={campaignDetailsView}
          setFilters={setFilters}
          filters={filters}
          campaignRequest={campaignRequest}
          changeCreateFlow={changeCreateFlow}
        />
      )}
      <DripDashboardTable
        data={fullCampaignData}
        isFetching={campaignFetching}
        activeTab={filterTabs[selectedCampaignTab]}
        socialThemes={socialThemes}
        setCampaignDetailsView={setCampaignDetailsView}
        campaignDetailsView={campaignDetailsView}
        editCampaignHandler={editCampaignHandler}
        editLoader={editLoader}
        filters={filters}
        setFilters={setFilters}
        campaignRequest={campaignRequest}
        detailsHandler={detailsHandler}
        setSelectedCampaignTab={setSelectedCampaignTab}
      />
    </Flex>
  );
};

export default DripCampaignDashboard;
