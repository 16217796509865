/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { createModal } from 'react-modal-promise';
import { ModalBody, ModalCloseButton, ModalHeader, ModalV2 } from '../../components';
import { getExploreArticleData, themesData, getMyContentArticles } from '../../utils';
import { ROLE_TYPES } from '../../utils/constants.js';
import { isEmpty, get } from 'lodash';
import { connect } from 'react-redux';
import { getChannelId, getRoleType } from '../../redux/selector';
import { createUpdatePageSettings, getLandingPageSettings, getLandingPages, getRowThumbnails } from '../../services/bee-editor';
import AddContentCustomRowV2 from './AddContentSteps/AddContentCustomRowV2';
import AddContentButtonV2 from './AddContentSteps/AddContentButtonV2';
import { actions as campaignActions } from '../../redux/modules/campaign.module';
import AddContentLayoutV2 from './AddContentSteps/AddContentLayoutV2';
import AddContentArticlesV2 from './AddContentSteps/AddContentArticlesV2';
import UploadContentLayout from './AddContentSteps/UploadContentLayout';
import { lambdaEndpoints } from '../../services/helpers';
import { oktaHelper } from '../../utils';

const ArticleModalV2 = ({ 
    isOpen, 
    onResolve, 
    onReject, 
    themeNames, 
    themesList,
    channelId, 
    userData, 
    bookmarkArticles, 
    recommendedArticles, 
    recommendedFetchedData, 
    myContentArticles,
    fromDashboard,
    campaignSelectedArticles,
    webinarData,
    templateType,
    automatedCampaigns,
    createMode,
    automatedFlowFromDashboard,
    selectedBlock,
    landingPagesData,
    myContentPaginationData,
    myContentPendingQueue,
    isEnterpriseAdmin,
    isEnterpriseAdvisor,
    isPremiumAdvisor,
    campaignSetState,
    campaignId,
 }) => {
    const [layout, setLayout] = useState('one-column');
    const [articleType, setArticleType] = useState('bookmarked');
    const [selectedArticles, setSelectedArticles] = useState([]);
    const [articleData, setArticleData] = useState({ bookmarked: [], recommended: [], "my-content": [] });
    const [step, updateStep] = useState(0);
    const [loader, toggleLoader] = useState(false);
    const [customRows, setCustomRows] = useState([]);
    const [selectedCustomRow, setSelectedCustomRow] = useState('');
    const [selectedLandingPage, setSelectedLandingPage] = useState('');
    const [predefinedArticleId, setPredefinedArticleId] = useState(null);
    const [modalTitle, setModalTitle] = useState('Select Content');
    const [showSettings, setShowSettings] = useState(false);
    const [showTime, setShowTime] = useState(false);
    const [showCount, setShowCount] = useState(false);
    const [count, setCount] = useState(0);
    const [eventDate, setEventDate] = useState();
    const [settingsLoader, setSettingsLoader] = useState(false);
    const [loading, setLoading] = useState(false);

    const fetchCustomRows = ({category, tag}) => {
        getRowThumbnails({ layout: tag, category })
        .then(thumbnails => {
            if(thumbnails) {
                setCustomRows(thumbnails);
                setSelectedCustomRow(thumbnails[0]._id);
            }
            toggleLoader(false);
        })
        .catch(err => {
            console.log('ERROR - - - -- - ', err);
            toggleLoader(false);
        });
    }

    useEffect(() => {
        if ((createMode === 'automated' || automatedFlowFromDashboard) && automatedCampaigns && automatedCampaigns.targetInfo && (automatedCampaigns.targetInfo.target_page_id || (automatedCampaigns.targetInfo.target_page && automatedCampaigns.targetInfo.target_page.id)) && !isEmpty(landingPagesData)) {
            const pageId = automatedCampaigns.targetInfo.target_page_id || automatedCampaigns.targetInfo.target_page.id;
            const selectedPage = landingPagesData.filter(p => p._id === pageId);
            console.log('checking');
            console.log(selectedPage);
            if (!isEmpty(selectedPage)) {
                setSelectedLandingPage(selectedPage[0]);
            }
        }
    }, [])
    

    useEffect(() => {
        if (fromDashboard && !isEmpty(campaignSelectedArticles)) {
            setPredefinedArticleId(campaignSelectedArticles[0].id);
        }

        var newStateObject = {
            bookmarked: [],
            'my-content': [],
            recommended: [],
        }
        if (bookmarkArticles && !isEmpty(bookmarkArticles))
            newStateObject.bookmarked = bookmarkArticles;
        if (myContentArticles && !isEmpty(myContentArticles))
            newStateObject['my-content'] = myContentArticles;
        if (recommendedFetchedData && !isEmpty(get(recommendedFetchedData, 'data.feed', [])))
            newStateObject.recommended = getExploreArticleData(
                get(recommendedFetchedData, 'data', {})
            );
        else if (recommendedArticles && !isEmpty(recommendedArticles))
            newStateObject.recommended = recommendedArticles;

        if (webinarData && webinarData.isWebinar) {
            setLayout('webinar-block');
        }


        setArticleData(newStateObject);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bookmarkArticles, myContentArticles, recommendedArticles, recommendedFetchedData])

    useEffect(() => {
        let category, tag;
        if (step === 0)
            setSelectedCustomRow('');
        else if (step === 1) {
            toggleLoader(true);
            if(layout === 'landing-pages' && !(createMode === 'automated' || automatedFlowFromDashboard)) {
                let type = 'campaign';
                getLandingPages(type)
                .then(response => {
                    setCustomRows(get(response, 'data', []));
                    if (!isEmpty(get(response, 'data', []))) {
                        setSelectedLandingPage(get(response, 'data', [])[0]);
                    }
                    toggleLoader(false);
                })
                .catch(err => {
                    console.log('ERROR - - - - -', err);
                    toggleLoader(false);
                });
            }
            else {
                if(layout === 'webinar-block') {
                    category = 'webinar_block';
                    tag = '';
                } 
                else {
                    category = 'article_block';
                    tag = layout;
                }
                // axios.get(`https://92bm0vkugf.execute-api.us-east-2.amazonaws.com/dev/article-thumbnails?uid=${get(userData, 'uid', '')}&tag=${layout}`)
                fetchCustomRows({category, tag});
            } 
        }
        else if (step === 2 && layout === 'landing-pages') {
            toggleLoader(true);
            fetchCustomRows({category: 'landing_page', tag: ''});
        }

    }, [layout, step]);



    const handleArticleClick = item => {
        if (selectedArticles.includes(item.id)){
            setSelectedArticles(selectedArticles.filter(articleId => articleId !== item.id));
        }
        else{
            setSelectedArticles([...selectedArticles, item.id]);
        }
    }

    const handleBack = () => {
        if((createMode === 'automated' || automatedFlowFromDashboard)) {
            if(showSettings) {
                setShowSettings(false);
                updateStep(step - 1);
            } else if (layout === 'landing-pages' && step === 2) {
                updateStep(step - 1);
                setShowSettings(true);
                setSettingsLoader(true);
                let selectedCampaignId = campaignId || get(automatedCampaigns, 'campaignId', '');
                getLandingPageSettings({ pageId: get(selectedLandingPage, '_id', ''), campaignId: selectedCampaignId }).then(res => {
                    setShowCount(get(res, 'data.consider_count', false));
                    setShowTime(get(res, 'data.consider_date', false));
                    setCount(get(res, 'data.number_of_registrants', 0));
                    setEventDate(get(res, 'data.event_date', '').split('.')[0]);
                    setSettingsLoader(false);
                }).catch(err => {
                setSettingsLoader(false);
            });
            }else  {
                updateStep(step - 1);
            }
        } else {
            if(showSettings) {
                setShowSettings(false);
            } else {
                updateStep(step - 1);
            }
        }
    }

    const handleValidation = () => {
        if (step === 1) {
            if(layout === 'landing-pages' && !showSettings)
                return selectedLandingPage === '';

            if (layout === 'landing-pages' && showSettings) {
                if(!showTime && !showCount) {
                    return false;
                }
                if(showTime) {
                    if(eventDate) {
                        return false;
                    } else {
                        return true;
                    }
                }
               
                if(showCount) {
                    if(count && count > 0 && count < 5001) {
                        return false;
                    } else {
                        return true;
                    }
                }
            }
            
            return selectedCustomRow === '';
        }
        else if (step === 2) {
            if(layout === 'landing-pages')
                return selectedCustomRow === ''
            else
                return (selectedArticles.length === 0 || selectedArticles.length > 30);
        }
    }

    const manageTabSelection = type => {
        setArticleType(type);
    }

    const loadMoreMyContent = () => {
        toggleLoader(true);
        getMyContentArticles({
            payload: {
                userType: isEnterpriseAdvisor ? 'isEnterpriseAdvisor' : isPremiumAdvisor ? 'isPremiumAdvisor' : 'isEnterpriseAdmin', 
                channelId: channelId, 
                beforeToken: null, 
                afterToken: myContentPaginationData.after,
                pendingArticles: myContentPendingQueue,
            },
            localData: {
                currentArticles: myContentArticles,
            },
            source: 'campaign',
        })
        .then(res => {
            const newState = get(res, 'newState', {});
            if(!isEmpty(newState))
                campaignSetState(newState);
            toggleLoader(false);
        })
        .catch(err => {
            console.log('******* ERROR my-content-articles [ARTICLE MODAL] ******');
            toggleLoader(false);
        })
    };

    const refreshMyContentData = () => {
        getMyContentArticles({
            payload: {
                userType: isEnterpriseAdvisor ? 'isEnterpriseAdvisor' : isPremiumAdvisor ? 'isPremiumAdvisor' : 'isEnterpriseAdmin', 
                channelId: channelId, 
                beforeToken: null, 
                afterToken: null,
                pendingArticles: myContentPendingQueue,
            },
            localData: {
                currentArticles: myContentArticles,
            },
            source: 'campaign',
        })
        .then(res => {
            const newState = get(res, 'newState', {});
            if(!isEmpty(newState))
                campaignSetState(newState);
            toggleLoader(false);
        })
        .catch(err => {
            console.log('******* ERROR my-content-articles [ARTICLE MODAL] ******');
            toggleLoader(false);
        })
    }

    const onResolveHandler = (inputId = false) => {
        if(predefinedArticleId && (layout === 'one-column' || layout === 'two-columns' || layout === 'one-column-reverse')) {
            if(step !== 1)
                updateStep(step + 1);
            else{
                onResolve({
                    name: `Selected Articles`,
                    value: layout === 'webinar-block' ?
                        `${lambdaEndpoints.baseUrl}customized-webinar-rows?id=${selectedCustomRow}&webinarId=${get(webinarData, 'wuid', '')}&token=${window.sessionStorage.getItem('token') || oktaHelper.getAccessToken()}` :
                        `${lambdaEndpoints.baseUrl}customized-article-rows?layout=${selectedCustomRow}&articleId=${JSON.stringify(predefinedArticleId)}&uid=${get(userData, 'uid', '')}&cid=${get(userData, 'details.user.company', '')}`
                })
            }
        } else if(layout === 'landing-pages' && step === 0 && (createMode === 'automated' || automatedFlowFromDashboard)) {
            updateStep(step + 1);
            setShowSettings(true);
            setSettingsLoader(true);
            let selectedCampaignId = campaignId || get(automatedCampaigns, 'campaignId', '');
            getLandingPageSettings({ pageId: get(selectedLandingPage, '_id', ''), campaignId: selectedCampaignId}).then(res => {
                setShowCount(get(res, 'data.consider_count', false));
                setShowTime(get(res, 'data.consider_date', false));
                setCount(get(res, 'data.number_of_registrants', 0));
                setEventDate(get(res, 'data.event_date', '').split('.')[0]);
                setSettingsLoader(false);
            }).catch(err => {
                setSettingsLoader(false);
            });
        }
        else {
            if(step === 1 && layout === 'webinar-block')
                onResolve({
                    name: `Selected Webinar Block`,
                    value: `${lambdaEndpoints.baseUrl}customized-webinar-rows?id=${selectedCustomRow}&webinarId=${get(webinarData, 'wuid', '')}&token=${window.sessionStorage.getItem('token') || oktaHelper.getAccessToken()}`
                });
            else if(step === 1 && layout === 'landing-pages' && !showSettings) {
                setShowSettings(true);
                setSettingsLoader(true);
                let selectedCampaignId = campaignId || get(automatedCampaigns, 'campaignId', '');
                getLandingPageSettings({ pageId: get(selectedLandingPage, '_id', ''), campaignId: selectedCampaignId}).then(res => {
                    setShowCount(get(res, 'data.consider_count', false));
                    setShowTime(get(res, 'data.consider_date', false));
                    setCount(get(res, 'data.number_of_registrants', 0));
                    setEventDate(get(res, 'data.event_date', ''))
                    setSettingsLoader(false);
                }).catch(err => {
                    setSettingsLoader(false);
                });
            } else if (step === 1 && layout === 'landing-pages' && showSettings) {
                let selectedCampaignId = campaignId || get(automatedCampaigns, 'campaignId', '');
                let payload = {
                    consider_count: showCount,
                    consider_date: showTime,
                    page_id: get(selectedLandingPage, '_id', ''),
                    time_zone: get(
                        userData,
                        'details.user.timezone',
                        ''
                    )
                }
                if(showCount) {
                    payload.no_of_registrants = count;
                }
                if(showTime) {
                    payload.event_date = eventDate
                }
                setLoading(true);
                createUpdatePageSettings(selectedCampaignId, payload).then(res => {
                    updateStep(step + 1);
                    setShowSettings(false);
                    console.log(res);
                    setLoading(false);
                }).catch(err => {
                    console.log(err);
                    setLoading(false);
                })
                
            }
            else if(step !== 2 && step !== 3) {
                updateStep(step + 1);
            }
            else{
                onResolve({
                    name: `Selected Articles`,
                    value: layout === 'landing-pages' 
                    ? `${lambdaEndpoints.baseUrl}customized-web-page-row?pageUrl=${get(selectedLandingPage, 'redirect_page_url', 'www.google.com')}&rowId=${selectedCustomRow}&page_id=${get(selectedLandingPage, '_id', '')}&ac_id=${get(automatedCampaigns, 'campaignId', '')}&campaignId=${campaignId}` 
                    : `${lambdaEndpoints.baseUrl}customized-article-rows?layout=${selectedCustomRow}&articleId=${JSON.stringify(inputId && inputId.length ? inputId : selectedArticles)}&uid=${get(userData, 'uid', '')}&cid=${get(userData, 'details.user.company', '')}`
                }); 
            }
                
        }
    }

    console.log(step, layout);
    return (
        <ModalV2 bgColor="#fff" overflowY={'auto'} maxHeight={'650px'} width={step === 0 ? "525px" : "60%"} isOpen={isOpen} onRequestClose={() => onReject('rejected')}>
            <ModalHeader style={{
                'textAlign' : 'left',
                'color' : '#101828',
                'paddingLeft' : '25px',
                'fontSize' : '20px',
                'paddingBottom' : '0'
            }}>
                    {step === 0 ? "Select Layout" : step === 1 && !showSettings ? "Select Custom Row" : step === 1 && showSettings ? 'Registration Settings' : modalTitle}
                <ModalCloseButton top='15px' right='15px' color='#000000' size='20px' onClick={() => onReject('rejected')} />
            </ModalHeader>

            <ModalBody>
                {step === 0 ? (
                    <AddContentLayoutV2
                        layout={layout} 
                        setLayout={setLayout} 
                        themesData={themesData}
                        webinarData={webinarData}
                        selectedLandingPage={selectedLandingPage}
                        acFlow={createMode === 'automated' || automatedFlowFromDashboard}

                    />
                ) : step === 1 || (step === 2 && layout === 'landing-pages') ? (
                        <AddContentCustomRowV2 
                            layout={layout} 
                            step={step}
                            loader={loader}
                            customRows={customRows}
                            selectedLandingPage={selectedLandingPage}
                            selectedCustomRow={selectedCustomRow} 
                            setSelectedCustomRow={setSelectedCustomRow}
                            setSelectedLandingPage={setSelectedLandingPage}
                            createMode={createMode}
                            automatedFlowFromDashboard={automatedFlowFromDashboard}
                            showSettings={showSettings}
                            showTime={showTime} 
                            setShowTime={setShowTime}
                            showCount={showCount} 
                            setShowCount={setShowCount}
                            count={count} 
                            setCount={setCount}
                            eventDate={eventDate} 
                            setEventDate={setEventDate}
                            userData={userData}
                            settingsLoader={settingsLoader}
                        />
                    ) :  step === 3 ? (
                        <UploadContentLayout 
                            setIsOpen={onReject} 
                            channelId={channelId} 
                            updateStep={updateStep} 
                            setModalTitle={setModalTitle} 
                            themesList={themesList}
                            onResolveHandler={onResolveHandler}
                            onRefreshMyContentData={refreshMyContentData}
                        />
                    ) : (
                        <AddContentArticlesV2 
                            step={step}
                            layout={layout}
                            updateStep={updateStep}
                            articleType={articleType}
                            setArticleType={manageTabSelection} 
                            articleData={articleData} 
                            selectedArticles={selectedArticles} 
                            themeNames={themeNames}
                            handleArticleClick={handleArticleClick}
                            showLoadMore={myContentPaginationData.after}
                            loadMoreHandler={loadMoreMyContent}
                            loader={loader}
                            setModalTitle={setModalTitle}
                        />
                    )}
                {step === 2 && layout !== 'landing-pages' && selectedArticles.length > 30 && (
                    <div>* You can select only 30 articles at a time!</div>
                )}
                {step < 3 && <AddContentButtonV2
                    step={step} 
                    updateStep={updateStep}
                    layout={layout}
                    handleValidation={handleValidation}
                    onResolveHandler={onResolveHandler}
                    loading={loading}
                    handleBack={handleBack}
                />}
            </ModalBody>
        </ModalV2>
    );
}
const mapStateToProps = (state) => {
    const roleType = getRoleType(state);
    const isPremiumAdvisor = roleType === ROLE_TYPES.ADVISOR_PREMIUM;
    const isEnterpriseAdvisor = roleType === ROLE_TYPES.ADVISOR_ENTERPRISE;
    const isEnterpriseAdmin = roleType === ROLE_TYPES.ADMIN_ENTERPRISE;
    return {
        themeNames: state.theme.themeNames,
        channelId: getChannelId(state),
        userData: state.user.data,
        bookmarkArticles: state.campaign.bookmarkArticles,
        recommendedArticles: state.campaign.recommendedArticles,
        recommendedFetchedData: state.campaign.recommendedFetchedData,
        myContentArticles: get(state, 'campaign.myContentArticles', []),
        myContentPaginationData: get(state, 'campaign.myContentPaginationData', { before: null, after: null }),
        myContentPendingQueue: get(state, 'campaign.pendingArticles', []),
        webinarData: get(state.campaign, 'setObjectives.webinar', ''),
        templateType: state.campaign.setTemplate,
        themesList: state.theme.data || [],
        campaignSelectedArticles: state.campaign.selectedArticles,
        fromDashboard: get(state, 'campaign.fromDashboard', false),
        automatedCampaigns: get(state, 'campaign.automatedCampaigns', {}),
        createMode: get(state, 'campaign.createMode', ''),
        automatedFlowFromDashboard: get(state, 'campaign.automatedFlowFromDashboard', ''),
        selectedBlock: get(state, 'campaign.selectedBlock', {}),
        landingPagesData: get(state, 'campaign.landingPages', []),
        campaignId: get(state, 'campaign.campaignId', ''),
        isEnterpriseAdmin,
        isEnterpriseAdvisor,
        isPremiumAdvisor
    }
};

const mapDispatchToProps = {
    campaignSetState: campaignActions.api.campaign.setstate,
  };

const ArticlePromiseModal = createModal(connect(mapStateToProps, mapDispatchToProps)(ArticleModalV2));

export default ArticlePromiseModal;


