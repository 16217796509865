import React from 'react';
import { 
    ChipStyle,
    SearchBox, 
    AddChipStyle,
    SearchTitle,
    SearchResults,
} from './styles';
import AddIcon from '@mui/icons-material/Add';

const SearchContainer = ({ 
    searchString = "", 
    setSearchString,
    selectedTags, 
    setSelectedTags,
    searchResults,
    title,
    singleTopic = false,
    chipType = 'tag',
    limit = false
}) => {
    const addTopic = (element) => {
        if(!singleTopic && !selectedTags.includes(element)){
            if(limit && selectedTags.length < limit){
                setSelectedTags([...selectedTags, element])
            }else if(!limit){
                setSelectedTags([...selectedTags, element])
            }
        }
        else if(singleTopic) setSelectedTags([element])
    };

    const deleteChip = (input) => {
        const newChips = selectedTags.filter((item) => item !== input)
        setSelectedTags([...newChips])
    }

    const searchDisplay = () => {
        let searchDisplayData;
        let dataStartingWith = searchResults.filter((item) => item.toLowerCase().startsWith(searchString.toLowerCase()))
        if(dataStartingWith.length > 0) {
            searchDisplayData = dataStartingWith
        } else {
            searchDisplayData = searchResults.filter((item) => item.toLowerCase().includes(searchString.toLowerCase()))
        }

        return(
            <div>
                {title && <SearchTitle style={{margin: '12px 0 6px'}}>
                    {title}
                </SearchTitle>}
                <div style={{margin: '0px 0 10px'}}>
                    {
                        selectedTags ? selectedTags.map((item, index) =>{
                            return <ChipStyle style={{margin: '0px 5px 5px 0'}} variant="outlined" key={index} label={item} hideDelete={item === 'prospect' || item === 'client'} onDelete={()=> item === 'prospect' || item === 'client'? {} : deleteChip(item)} />
                        }) : <p>Please select at least one {chipType}</p>
                    }
                </div>
                <SearchBox value={searchString} placeholder={"Search..."} onChange={(e)=>setSearchString(e.target.value)}></SearchBox>
                    {
                        searchString.length > 0 && <>
                            <SearchTitle>Showing results for "{searchString}"</SearchTitle>
                            <SearchResults>
                                {
                                    searchDisplayData.map((theme, index) => {
                                        if(index < 8){
                                            return (
                                                <AddChipStyle 
                                                    key={index} 
                                                    label={theme} 
                                                    deleteIcon={<AddIcon />} 
                                                    onClick={()=>addTopic(theme)} 
                                                    onDelete={()=>addTopic(theme)} 
                                                />
                                            )
                                        }
                                    })
                                }
                            </SearchResults>
                        </>
                    }
            </div>
        )
    }

    return(
        <div>
            {
                searchDisplay()
            }
        </div>
    )
};

export default SearchContainer;