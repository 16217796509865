import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Flex } from '@rebass/grid';
import React from 'react';
import {
  Button,
  ButtonNoBorderBackground,
  LoaderWrapper,
} from '../../../components';
import { DripFooterSection } from '../CreateCampaign/CreateCampaign.styles';

const CampaignFooterButtons = ({
  enableNext,
  nextHandler,
  backHandler,
  saveAndCloseHandler,
  loading,
  isPublished,
  enablePublish,
  publishHandler,
  padding,
  isLastStep = false,
  fontSize = '13px',
  fromEmailEditor,
}) => {
  return (
    <DripFooterSection style={{ padding: padding }}>
      <LoaderWrapper isLoading={loading}>
        <Flex>
          {isPublished && (
            <Button
              styles={{ borderRadius: '4px', fontSize: fontSize }}
              color="#fff"
              bgColor="#6250ED"
              margin="0px 10px"
              disabled={!enablePublish}
              onClick={publishHandler}
            >
              Publish All
            </Button>
          )}
          {!fromEmailEditor && (
              <Button
                styles={{ borderRadius: '4px', fontSize: fontSize }}
                color="#fff"
                bgColor="#4E6780"
                margin="0px 10px"
                onClick={saveAndCloseHandler}
                disabled={!enableNext}
              >
                Save & Close
            </Button>
          )}
        </Flex>
        <Flex>
          {/* <ButtonNoBorderBackground color="#000000" onClick={backHandler} style={{ fontSize: fontSize }}>
            <FontAwesomeIcon icon={faChevronLeft} />
            &nbsp; Back &nbsp; &nbsp;
          </ButtonNoBorderBackground> */}
          {!isLastStep && !fromEmailEditor && (
            <Button
              styles={{ borderRadius: '4px', fontSize: fontSize }}
              color="#fff"
              bgColor="#4E6780"
              margin="0px 10px"
              onClick={nextHandler}
              disabled={!enableNext}
            >
              {isPublished ? 'Publish' : 'Next'}
            </Button>
          )}
        </Flex>
      </LoaderWrapper>
    </DripFooterSection>
  );
};

export default CampaignFooterButtons;
