/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import {
  NewButton,
  PublisherCardWrapper,
  PublisherTitle,
  PublisherTopicWrapper,
  StyledCross,
  StyledImgDiv,
  StyledTitle,
  StyledTitleDiv,
} from './styles';
import { Flex } from '@rebass/grid';
import { Chip } from '../../../componentsV2/Chip';
import { SearchContainer } from '../EmailList/EmailList.styles';
import SearchBar from '../SearchBar';
import { Button, LoaderWrapper } from '../../../components';
import AddPublisherModal from './AddPublisherModal';
import { connect } from 'react-redux';
import { actions as channelActions } from '../../../redux/modules/channel.module';
import { getRoleType, getUserRole } from '../../../redux/selector';
import { ROLE_TYPES, USER_ROLE } from '../../../utils';
import {
  getAdminChannelData,
  getChannelList,
  postMyPublisherSubscribeChannel,
  postMyPublisherUnsubscribeChannel,
  postSubscribeChannel,
  postUnsubscribeChannel,
} from '../../../services/dashboard';
import _, { get } from 'lodash';
import { Tooltip } from '@mui/material';

const APPROVED = 'approved';
const PREMIUM = 'freemium';
const FREE = 'free';

const ManagePublisher = ({ isAdmin, isEnterpriseAdvisor }) => {
  const [openAddPublisherModal, setOpenAddPublisherModal] = useState(false);
  const [publishers, setPublishers] = useState([]);
  const [myPublisherList, setMyPublisherList] = useState(null);
  const [premiumPublisherList, setPremiumPublisherList] = useState(null);
  const [listViewLoader, setListViewLoader] = useState('');
  const [publisherLoader, setPublisherLoader] = useState('');
  const [search, setSearch] = useState('');
  const [newFilteredPublishers, setNewFilteredPublishers] = useState([]);

  useEffect(() => {
    if (isAdmin) {
      getAdminChannelData().then((res) => {
        setPublishers(_.map(_.values(res.result.data), (item) => item[0]));
      });
    } else {
      getChannelList().then((res) => {
        setPublishers(_.map(_.values(res.result.data), (item) => item[0]));
      });
    }
  }, []);

  useEffect(() => {
    let mpList = [],
      ppList = [];

    publishers.forEach((publisher) => {
      if (get(publisher, 'subscription_type') === PREMIUM) {
        ppList.push(publisher);
      } else if (
        get(publisher, 'subscription_type') === FREE &&
        get(publisher, 'subscription.status.application_status') === APPROVED
      ) {
        mpList.push(publisher);
      }
      setMyPublisherList(mpList);
      setNewFilteredPublishers(mpList);
      setPremiumPublisherList(ppList);
    });
  }, [publishers]);

  const searchHandler = () => {
    let searchResult = myPublisherList.filter((pub) =>
      pub.name.toLowerCase().includes(search.toLowerCase())
    );
    setNewFilteredPublishers(searchResult);
  };

  console.log("searh result--->", newFilteredPublishers)

  const handleMyPublisherClick = (id, index) => {
    setPublisherLoader(id);
    postUnsubscribeChannel(id)
      .then((res) => {
        setPublisherLoader('');
        if (isAdmin) {
          getAdminChannelData().then((res) => {
            // console.log('CHANNEL API RESULT - ', res);
            setPublishers(_.map(_.values(res.result.data), (item) => item[0]));
          });
        } else {
          getChannelList().then((res) => {
            setPublishers(_.map(_.values(res.result.data), (item) => item[0]));
          });
        }
      })
      .catch((err) => {
        setPublisherLoader('');
        console.log('ERR--->', err);
      });
  };

  const postMyPublisherUnsubscribeChannelClick = (id, index) => {
    setPublisherLoader(id);
    postMyPublisherUnsubscribeChannel(id).then((res) => {
      getAdminChannelData().then((res) => {
        console.log('unsubscriberesponse', res);
        setPublishers(_.map(_.values(res.result.data), (item) => item[0]));
        setPublisherLoader('');
      });
    });
  };

  const getImages = (channel) => {
    if (
      channel.media_urls &&
      channel.media_urls[0] &&
      channel.media_urls[0].path &&
      channel.media_urls[0].name.resized &&
      channel.media_urls[0].ext
    )
      return `${channel.media_urls[0].path}/${channel.media_urls[0].name.resized}.${channel.media_urls[0].ext}`;
    else return '';
  };

  const handleAddPublisherModalOpen = () => {
    console.log('clicked');
    setOpenAddPublisherModal(true);
  };
  return (
    <>
      <PublisherTopicWrapper>
        <Flex
          display="flex"
          flexDirection="row"
          width="95.5%"
          justifyContent="space-between"
          height="40px"
        >
          <PublisherTitle style={{ fontSize: '20px', height: '40px' }}>
            My Publishers
          </PublisherTitle>
          <Flex width="24%" height="40px" justifyContent="end">
            <SearchContainer
              style={{
                width: '100%',
                alignSelf: 'flex-start',
                margin: '0px 10px 0px 0px',
              }}
            >
              <SearchBar
                searchString={search}
                setSearchString={setSearch}
                searchHandler={searchHandler}
                //searchHandler={(value) => searchHandler(publishers, value)}
                placeholder="Find from chosen Publishers"
              />
            </SearchContainer>
          </Flex>
          <Flex display="flex" width="16%" height="40px" justifyContent="end">
            <Button
              borderColor="#CABEFF"
              color="#4634D0"
              bgColor="#FFFFFF"
              borderRadius="4px"
              margin="0px"
              onClick={handleAddPublisherModalOpen}
            >
              <Flex
                justifyContent="space-between"
                width="100%"
                alignItems="center"
              >
                <img alt="" src="/assets/images/color-plus-icon.svg" />
                <Flex ml="5px" mr="5px">
                  Add Publishers
                </Flex>
              </Flex>
            </Button>
          </Flex>
        </Flex>
        <Flex
          display="flex"
          width="100%"
          margin="-3px 0px 10px 0px"
          padding="5px"
          style={{ fontSize: '12px', color: '#475467' }}
        >
          Manage Discover page content
        </Flex>
        {myPublisherList && myPublisherList.length < 10 && (
          <Flex
            width="95.5%"
            margin="-3px 0px 10px 0px"
            padding="5px"
            style={{
              fontSize: '12px',
              color: '#EA780E',
              backgroundColor: '#FFF3E8',
              border: '1px solid #FFCA99',
              borderRadius: '2px',
              alignItems: 'center',
            }}
          >
            <img
              alt=""
              src="/assets/images/color-warn-icon.svg"
              style={{ marginLeft: '5px', marginRight: '5px' }}
            />
            You have less than 10 publishers, we suggest you to add more for a
            better feed on your Discover page.
          </Flex>
        )}
        <PublisherCardWrapper
          style={{
            flexWrap: 'wrap',
            justifyContent: 'start',
            maxHeight: '600px',
            //padding: '3px 15px',
            // maxHeight: '250px',
            // alignContent: 'flex-start'
          }}
        >
          {newFilteredPublishers ? (
            newFilteredPublishers.map((channel, index) => {
              if (channel.is_hidden === 'true') {
                return null;
              }
              return (
                //<LoaderWrapper isLoading={loader === getImages(channel)}>
                <Chip width="23%" height="80px" boxShadow="0px 1px 2px 0px #1018281F">
                  <StyledImgDiv style={{ width: '35%' }}>
                    <img
                      alt=""
                      src={getImages(channel)}
                      style={{ width: '100%' }}
                    />
                  </StyledImgDiv>
                  <StyledTitleDiv style={{ width: '50%' }}>
                    <StyledTitle
                      style={{
                        padding: '0px 0px 0px 10px',
                        fontSize: '14px',
                      }}
                    >
                      <LoaderWrapper
                        isLoading={publisherLoader === channel.name}
                      >
                        {channel?.name && channel?.name?.length > 12 ? (
                          <Tooltip
                            arrow
                            placement="top"
                            title={channel.name}
                            componentsProps={{
                              tooltip: {
                                sx: {
                                  fontFamily: 'Poppins',
                                },
                              },
                            }}
                          >
                            {`${channel?.name.substring(0, 12)}...`}
                          </Tooltip>
                        ) : (
                          channel?.name
                        )}
                      </LoaderWrapper>
                    </StyledTitle>
                  </StyledTitleDiv>
                  {!publisherLoader ? (
                    <StyledCross
                      style={{
                        marginRight: '7px',
                        width: '15%',
                        justifyContent: 'flex-end',
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        if (isAdmin) {
                          handleMyPublisherClick(channel.id, index);
                          setPublisherLoader(channel.name);
                        } else {
                          postMyPublisherUnsubscribeChannelClick(
                            channel.id,
                            index
                          );
                        }
                        setPublisherLoader(channel.name);
                      }}
                    >
                      <img alt="" src="/assets/images/red-cross-icon.svg" />
                    </StyledCross>
                  ) : null}
                </Chip>
                //</LoaderWrapper>
              );
            })
          ) : (
            <Flex justifyContent="center" width="100%">
              <img src="/assets/images/loaderV.gif" alt="loader" />
            </Flex>
          )}
          {newFilteredPublishers && (
            <Chip height="80px" boxShadow="0px 1px 2px 0px #1018281F">
              <Flex
                justifyContent="center"
                width="100%"
                alignItems="center"
                onClick={handleAddPublisherModalOpen}
                style={{ cursor: 'pointer' }}
              >
                <img alt="" src="/assets/images/color-plus-icon.svg" />
                <Flex ml="5px" mr="5px" style={{ fontSize: '14px' }}>
                  Add Publishers
                </Flex>
              </Flex>
            </Chip>
          )}
        </PublisherCardWrapper>
        {openAddPublisherModal && (
          <AddPublisherModal
            isOpen={openAddPublisherModal}
            closeModalHandler={() => setOpenAddPublisherModal(false)}
            myPublisherList={newFilteredPublishers}
            isAdmin={isAdmin}
            setPublishers={setPublishers}
            publishers={publishers}
            premiumPublisherList={premiumPublisherList}
            loader={listViewLoader}
            setLoader={setListViewLoader}
          />
        )}
      </PublisherTopicWrapper>
    </>
  );
};

const mapStateToProps = (state) => {
  const roleType = getRoleType(state);
  const isAdmin = getUserRole(state) === USER_ROLE.admin;
  const isEnterpriseAdvisor = roleType === ROLE_TYPES.ADVISOR_ENTERPRISE;
  return {
    channels: state.channel.data,
    roleType: roleType,
    isAdmin: isAdmin,
    isEnterpriseAdvisor: isEnterpriseAdvisor,
  };
};

const mapDispatchToProps = {
  channelRequest: channelActions.api.channel.request,
};

export default connect(mapStateToProps, mapDispatchToProps)(ManagePublisher);
