import * as React from 'react';

const ClearIcon = props => (
  <svg
    width={18}
    height={18}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="m12.5 3.5-9 9M12.5 12.5l-9-9"
      stroke="#0B5CD7"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default ClearIcon;
