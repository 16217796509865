import { Flex } from '@rebass/grid';
import styled from 'styled-components';

export const TopicChipStyle = styled('div')`
  font-family: 'Poppins' !important;
  padding: 4px 16px;
  background: #ffffff;
  color: #101828;
  border: 1px solid #e4e7ec;
  border-radius: 20px;
  margin: 5px 5px 0 0;
  height: 32px;
  white-space: nowrap;
  width: fit-content;
`;

export const EnterpriseActivityCardWrapper = styled(Flex)`
  height: 385px;
  max-height: 385px;
  width: 100%;
  padding-right: 15px;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: auto;
  position: relative;

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background: #e9f6e6 !important;
  }

  &::-webkit-scrollbar-thumb {
    background: #bccdd4 !important;
  }
`;