import { Flex } from '@rebass/grid'
import React from 'react'
import { ColorPallete, Typography } from '../../utils'

const EmptyState = ({
    height,
    width,
    title,
    subTitle
}) => {
  return (
    <Flex 
        width={width || "100%"}
        flexDirection="column"
        height={height}
        alignItems="center"
        justifyContent="center"
    >
        <Flex width="100%" justifyContent="center" margin="10px 0px 15px">
            <img src="/assets/images/Analytics/loading.svg" alt="loading"/>
        </Flex>
        
        <Flex fontSize={Typography.TextLarge} color={ColorPallete.neutral900} width="100%" justifyContent="center" pb="4px">
            {title}
        </Flex>

        <Flex fontSize={Typography.TextMedium} color={ColorPallete.neutral600} width="100%" justifyContent="center" pb="4px">
            {subTitle}
        </Flex>
    </Flex>
  )
}

export default EmptyState