import * as React from 'react';

function Multiply(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" {...props}>
      <path
        d="M18.75 5.25l-13.5 13.5M18.75 18.75L5.25 5.25"
        stroke="currentColor"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

const MemoMultiply = React.memo(Multiply);
export default MemoMultiply;
