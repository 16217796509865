import React from 'react';
import styled from 'styled-components';
import { Flex } from '@rebass/grid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitter, faLinkedinIn, faFacebook } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import {
  VerticalTab,
  VerticalTabList,
  VerticalTabPanel,
} from '../../components';
import { TabPanel as TabThreePanel } from '../../components/Tabs/TabsTypeThree';

export const AnalyticsVerticalTab = styled(VerticalTab)`
  background-color: #fff;
  position: relative;
  filter: grayscale(1);
  min-height: 123px;
  &:after {
    content: ' ';
    position: absolute;
    z-index: 1;
    background: #fff;
    opacity: 0.7;
    height: 100%;
    width: 100%;
  }
  overflow-y: hidden !important;
  &.react-tabs__tab--selected {
    filter: none;
    background-color: transparent;
    &:after {
      content: unset;
    }
  }
`;

export const AnalyticsWebinarVerticalTabList = styled(VerticalTabList)`
    width: ${({ screenWidth }) => (screenWidth < 768 ? '100%' :' 24.7%')};
`

export const AnalyticsVerticalTabList = styled(VerticalTabList)`
  width: ${({ screenWidth }) => (screenWidth < 768 ? '100%' :' 24.7%')};
  .transparent {
    background-color: transparent !important;
  }
  .transparent:hover {
    background-color: lightgray !important;
  }
  .selected {
    background-color: white !important;
  }
  .selected:hover {
    background-color: lightgray !important;
  }
`;

export const AnalyticsVerticalTabPanel = styled(VerticalTabPanel)`
  margin-top: ${({ screenWidth }) => (screenWidth < 768 ? '35px' : null)};
  padding: ${({ screenWidth }) => (screenWidth < 768 ? '40px 15px' :' 42px 65px 30px')};
  overflow-x: hidden;
  overflow-y: auto;
  padding-top: ${({paddingTop}) => paddingTop || ''};
`;

export const TabContent = styled.div`
  padding: 24px 20px;
`;

export const TabLabel = styled.div`
  font-size: ${({ fontSize}) => fontSize || '12px'};
  line-height: 18px;
  color: ${({ color }) => color || '#49484a'};
`;

export const TabCount = styled.span`
  font-weight: bold;
  font-size: ${({ fontSize}) => fontSize || '24px'};
  margin: 0px 0;
  line-height: 25px;
  color: ${({ color }) => color || '#49484a'};
`;

const commonPanelStyles = `
background-color: #fff;
padding: 15px 5px;
border: 1px solid #e6e6e6;`;

export const CommonTabPanel = styled(TabThreePanel)`
  height: ${({ height }) => height || '475px'};
  display: none;
  position: relative;
  ${commonPanelStyles}
  margin-top: -3px;
  &.react-tabs__tab-panel--selected {
    display: block;
  }
  ${({ padding }) => padding && `padding:${padding};`}
  ${({ margin }) => margin && `margin:${margin};`}
`;

export const EngagementTabPanel = styled(TabThreePanel)`
  height: ${({ height }) => height || '525px'};
  display: none;
  background-color: #ffffff;
  
  margin-top: -3px;
  &.react-tabs__tab-panel--selected {
  display: block;
  }
  ${({ padding }) => padding && `padding:${padding};`}
  ${({ margin }) => margin && `margin:${margin};`}
`;

export const CommonVerticalTabPanel = styled.div`
  height: ${({ height }) => height || '475px'};
  width: 100%;
  position: relative;
  ${commonPanelStyles}
`;

export const DownloadButtonFlex = styled(Flex)`
  justify-content: ${({ justifyContent }) => {
    return justifyContent || 'flex-end';
  }};
  width: 100%;
  padding-top: ${({ paddingTop }) => paddingTop || '12px'};
  font-family: 'Poppins' !important;
`;

export const platformChartIcons = {
  tw: <FontAwesomeIcon icon={faTwitter} height="15px" width="15px" />,
  ln: <FontAwesomeIcon icon={faLinkedinIn} height="15px" width="15px" />,
  em: <FontAwesomeIcon icon={faEnvelope} height="15px" width="15px" />,
  fb: <FontAwesomeIcon icon={faFacebook} height="15px" width="15px" />
};

export const AnalyticsContainer = styled('div')`
  background: #FFFFFF;
  width: 100%;
  margin: 20px auto;
  padding-top: 10px;
`;

export const DropDownWrapper = styled(Flex)`
  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background: #e9f6e6 !important;
  }

  &::-webkit-scrollbar-thumb {
    background: #bccdd4 !important;
  }
`;

