import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import {get, union, remove, without, filter, isEmpty } from 'lodash';
import { DashboardArticle, FreeArticle } from '../../../components';

import { getExploreArticleData, dashboardSliderSettings, eventHandlers } from '../../../utils';

import { ArticleSliderWrapper } from '../DashboardContainer.styles';

const SearchResultsTab = ({
  selectedTopic,
  exploreArticles,
  deleteArticleRequest,
  themeNames,
  exploreArticleDeleting,
  isFreeUser,
  isShareBlocked,
  isDeleteBlocked,
  showMio,
  searchFilters,
  setIsSearchFilters,
  yourTopics,
  mostSubscribed,
  setShowArticleLoader,
  setFilteredSearchEmpty,
  popularThemes,
  userData,
  refresh,
  onNewsletterArticleClick,
  onRemoveFromNewletterClick,
  newsletterArticleFetching,
  bookmarksFetching,
  exploreNewsletterId,
  bookmarksId
}) => {
  const [articles, setArticles] = useState({});
  const [localExploreArticles, setLocalExploreArticles] = useState([]);
  const [totalArticles, setTotalArticles] = useState(0);
  const [imageCounter, setImageCounter] = useState([]);

  dashboardSliderSettings.afterChange = (index) => {
    let articles = localExploreArticles.slice(index, index + 4);
    let sponsoredArticle = articles.filter(a => a.sponseredContent);
    const userEmail = userData && userData.details && userData.details.user && userData.details.user.email;
    if (!isEmpty(sponsoredArticle)) {
      sponsoredArticle.forEach((article) => {
        eventHandlers(
          'SPONSORED_ARTICLE_DISPLAYED',
          popularThemes,
          article.id,
          'SEARCH',
          article.channelId,
          article.title,
          article.themeId,
          themeNames[article.themeId],
          article.topic,
          userEmail
        );
      });
    }
    if (!isEmpty(articles)) {
      articles.forEach((article) => {
        eventHandlers(
          'ARTICLE_DISPLAYED',
          popularThemes,
          article.id,
          'SEARCH',
          article.channelId,
          article.title,
          article.themeId,
          themeNames[article.themeId],
          article.topic,
          userEmail
        );
      });
    }
  }

   useEffect(() => {
     let localArticles = getExploreArticleData(articles);
     setLocalExploreArticles(localArticles);
     setTotalArticles(localArticles.length);
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [articles]);

  useEffect(() => {
    if(isEmpty(get(exploreArticles, 'feed', []))) {
      setShowArticleLoader(false);
    }
    const feeds = get(exploreArticles, 'feed') || [];
    console.log(yourTopics);
    console.log(searchFilters);
    let result = [];
    if(searchFilters && searchFilters.length > 0) {
      const filterByTopic = searchFilters.includes('Your Topics') ? yourTopics : [];
      console.log(filterByTopic);
      const filterByMostSubscribed = searchFilters.includes('Most Subscribed') ? mostSubscribed.map(v => v.toUpperCase()) : [];
      const publishers = without(searchFilters, 'Your Topics', 'Most Subscribed');
      const filtering = {        
        byTopic: filterByTopic && filterByTopic.length > 0,
        byMostSubs: filterByMostSubscribed && filterByMostSubscribed.length > 0,
        byPublisher: publishers && publishers.length > 0,
      }
      feeds.forEach((data, idx) =>  {
        console.log(data.filterTag);
        if(data.filterTag && data.filterTag.topic && data.filterTag.partner &&
          data.filterTag.topic.name && data.filterTag.partner.title
        ){
          const partnerTitle = data.filterTag.partner.title.toUpperCase();
          const topicName = data.filterTag.topic.name.toUpperCase();
          console.log(topicName);
          if(filtering.byTopic && filtering.byMostSubs && filtering.byPublisher && (
            filterByTopic.includes(topicName) && filterByMostSubscribed.includes(topicName) && publishers.includes(partnerTitle)
          )){
            result.push(data);
          }else if(((filtering.byTopic && filterByTopic.includes(topicName) || (filtering.byMostSubs && filterByMostSubscribed.includes(topicName)))) && (filtering.byPublisher && searchFilters.includes(partnerTitle))
          ){
            result.push(data);
          }
          else if(((filtering.byTopic && filterByTopic.includes(topicName) || (filtering.byMostSubs && filterByMostSubscribed.includes(topicName)))) && !filtering.byPublisher)
          {
            result.push(data);
          }
          else if((filtering.byTopic && filterByTopic.includes(topicName)) && !filtering.byMostSubs && !filtering.byPublisher){
            result.push(data);
          }
          else if(filtering.byMostSubs && filterByMostSubscribed.includes(topicName) && !filtering.byTopic && !filtering.byPublisher){
            result.push(data);
          }
          else if((filtering.byPublisher && searchFilters.includes(partnerTitle)) && !filtering.byTopic && !filtering.byMostSubs){
            result.push(data);
          }
        }
      });
    }else{
      result = feeds;
    }
    setArticles({ ...exploreArticles, feed: union(result) });
    if(union(result).length > 0) {
      let searchArticles = getExploreArticleData({ ...exploreArticles, feed: union(result) });
      let sponsoredArticle = searchArticles.slice(0, 4).filter(a => a.sponseredContent);
      const userEmail = userData && userData.details && userData.details.user && userData.details.user.email;
      if (!isEmpty(sponsoredArticle)) {
        sponsoredArticle.forEach((article) => {
          eventHandlers(
            'SPONSORED_ARTICLE_DISPLAYED',
            popularThemes,
            article.id,
            'SEARCH',
            article.channelId,
            article.title,
            article.themeId,
            themeNames[article.themeId],
            article.topic,
            userEmail
          );
        });
      }

      searchArticles.slice(0, 4).forEach((article) => {
        eventHandlers(
          'ARTICLE_DISPLAYED',
          popularThemes,
          article.id,
          'SEARCH',
          article.channelId,
          article.title,
          article.themeId,
          themeNames[article.themeId],
          article.topic,
          userEmail
        );
       });
    }
    if (union(result).length === 0 && get(exploreArticles, 'feed') !== undefined) {
      setIsSearchFilters(false);
      setFilteredSearchEmpty(true);
      setShowArticleLoader(false);
    }else{
      setFilteredSearchEmpty(false);
      setShowArticleLoader(false);
      setIsSearchFilters(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exploreArticles, searchFilters, setIsSearchFilters, yourTopics, mostSubscribed]);

  return (
    <ArticleSliderWrapper>
      <Slider {...dashboardSliderSettings}>
        {isFreeUser && <FreeArticle />}
        {localExploreArticles.map((article, index) => (
          <DashboardArticle
            article={article}
            bookmarksFetching={bookmarksFetching}
            bookmarksId={bookmarksId}
            {...{
              ...article,
              isFreeUser,
              selectedTopic,
              deleteArticleRequest,
              themeNames,
              exploreArticleDeleting,
              isShareBlocked,
              isDeleteBlocked,
              showMio,
              index,
              setShowArticleLoader,
              totalArticles,
              imageCounter,
              setImageCounter,
              section: 'SEARCH',
              onNewsletterArticleClick,
              onRemoveFromNewletterClick,
              newsletterArticleFetching,
              exploreNewsletterId
            }}
          />
        ))}
      </Slider>
    </ArticleSliderWrapper>
  );
};

export default SearchResultsTab;
