import React from 'react';
import { Tooltip } from '@mui/material';
import Skeleton from './Skeleton';
import ZeroState from './ZeroState';
import LightbulbFilament from 'src/assets/icons/LightbulbFilament';
import InfoCircle from 'src/assets/icons/InfoCircle';
import Link from 'src/assets/icons/Link';
import Alarm from 'src/assets/icons/Alarm';
import Note from 'src/assets/icons/Note';
import FileIcon from 'src/assets/icons/File';
import ImageIcon from 'src/assets/icons/Image';
import CalendarBlank from 'src/assets/icons/CalendarBlank';
import useQueryForKeyInsights from 'src/hooks/useQueryForKeyInsights';
import {
  Card,
  CardContent,
  Cards,
  Content,
  Header,
  Heading,
  Pill,
  Pills,
} from './styles';
import useQueryForSocialKeyInsights from 'src/hooks/useQueryForSocialKeyInsights';
import { Flex } from '@rebass/grid';
import { ColorPallete, Typography } from 'src/utils';

function KeyInsights({ email }) {
  const { data, isLoading, isFetching } = useQueryForKeyInsights(email);
  const { data: socialData, isLoading: isSocialLoading, isFetching: isSocialFetching } = useQueryForSocialKeyInsights(email);

  const getFormat = (format) => {
    switch (format) {
      case 'lp':
        return {
          name: 'Landing Page',
          icon: <Note />,
        };
      case 'pdf':
        return {
          name: 'PDF',
          icon: <FileIcon />,
        };
      case 'img':
        return {
          name: 'Image',
          icon: <ImageIcon />,
        };
      case 'link':
        return {
          name: 'Links',
          icon: <Link />,
        };
      default:
        return { name: '', icon: <></> };
    }
  };

  if (isLoading || isFetching || isSocialLoading || isSocialFetching) return <Skeleton />;
  if (
    !(
      Array.isArray(data?.most_popular_formats) &&
      data?.most_popular_formats?.length > 0
    ) &&
    !data?.most_popular_time &&
    !data?.most_popular_day
  ) {
    return <ZeroState />;
  }

  return (
    <div>
      <Content>
        <Header>
          <Heading>
            <LightbulbFilament />
            <h3>Key Insights</h3>
          </Heading>
          <Tooltip
            title="Key Insight recommendations are produced by analyzing your historical campaign and content engagement metrics to surface the optimal time of day and ideal campaign formats to drive better outcomes."
            arrow
          >
            <div className="info-icon">
              <InfoCircle />
            </div>
          </Tooltip>
        </Header>
        <Cards>
          {/* {Array.isArray(data?.most_popular_formats) &&
            data?.most_popular_formats?.length > 0 && (
              <Card>
                <CardContent>
                  <h5>Format</h5>
                  <p>
                    You’re seeing higher level of engagements when you utilize
                  </p>
                  <Pills>
                    {data?.most_popular_formats?.map((format, index) => (
                      <Pill
                        key={index}
                        color="#0B5CD7"
                        bgColor="#ECF2FC"
                        borderColor="#B6CFF3"
                      >
                        {getFormat(format)?.icon} {getFormat(format)?.name}
                      </Pill>
                    ))}
                  </Pills>
                </CardContent>
              </Card>
            )} */}
          <Card>
            <CardContent>
              <h5>Email</h5>
              <p>Recommendations are based upon engagement with prior email campaigns.</p>
              <Flex width="100%" justifyContent="space-between" alignItems="center">
                <Flex color={ColorPallete.neutral700} fontSize={Typography.TextSmall}>Best Time</Flex>
                <Pill
                  color="#0B5CD7"
                  bgColor="#ECF2FC"
                  borderColor="#B6CFF3">
                  <Alarm /> {data?.most_popular_time}
                </Pill>
              </Flex>
              <Flex width="100%" justifyContent="space-between" alignItems="center">
                <Flex color={ColorPallete.neutral700} fontSize={Typography.TextSmall}>Best Day</Flex>
                <Pill
                  color="#0B5CD7"
                  bgColor="#ECF2FC"
                  borderColor="#B6CFF3">
                  <CalendarBlank /> {data?.most_popular_day}
                </Pill>
              </Flex>
              <Flex width="100%" justifyContent="space-between" alignItems="center">
                <Flex color={ColorPallete.neutral700} fontSize={Typography.TextSmall}>Most Popular Format</Flex>
                <Pill
                  color="#0B5CD7"
                  bgColor="#ECF2FC"
                  borderColor="#B6CFF3">
                  {getFormat(data?.most_popular_formats[0])?.icon} {getFormat(data?.most_popular_formats[0])?.name}
                </Pill>
              </Flex>
            </CardContent>

          </Card>

          <Card>
            <CardContent>
              <h5>Social</h5>
              <p>Recommendations are based upon engagement with prior social media posts.</p>

              <Flex width="100%" justifyContent="space-between" alignItems="center">
                <Flex color={ColorPallete.neutral700} fontSize={Typography.TextSmall}>Best Time</Flex>
                <Pill
                  color="#0B5CD7"
                  bgColor="#ECF2FC"
                  borderColor="#B6CFF3">
                  <Alarm /> {socialData?.most_popular_time}
                </Pill>
              </Flex>
              <Flex width="100%" justifyContent="space-between" alignItems="center">
                <Flex color={ColorPallete.neutral700} fontSize={Typography.TextSmall}>Best Day</Flex>
                <Pill
                  color="#0B5CD7"
                  bgColor="#ECF2FC"
                  borderColor="#B6CFF3">
                  <CalendarBlank /> {socialData?.most_popular_day}
                </Pill>
              </Flex>
              {/* <Flex width="100%" justifyContent="space-between" alignItems="center">
                <Flex color={ColorPallete.neutral700} fontSize={Typography.TextSmall}>Most Popular Format</Flex>
                <Pill
                  color="#0B5CD7"
                  bgColor="#ECF2FC"
                  borderColor="#B6CFF3">
                  {getFormat(data?.most_popular_formats[0])?.icon} {getFormat(data?.most_popular_formats[0])?.name}
                </Pill>
              </Flex> */}
            </CardContent>

          </Card>



        </Cards>
      </Content>
    </div>
  );
}

export default KeyInsights;
