import React from 'react';
import { NewsletterContainer } from '../../containers/NewsletterContainer';

import withAppLayout from '../../hoc/withAppLayout';

const Newsletter = (props) =>  {
  return (
    <NewsletterContainer {...props}/>
  )
}

export default withAppLayout(Newsletter);
