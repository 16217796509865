import styled from 'styled-components';
import { Tab, TabList, Tabs } from 'react-tabs';
import { themesData } from '../../utils/constants';
const { white, purpleishBlue, charcoalGrey, greyWhite } = themesData;

export const StyledTabsThree = styled(Tabs)`
  margin: 30px 0 20px;
  display: inline-block;
  width: 100%;
  position: relative;
  @media (max-width: 768px) {
    margin: 90px 0 20px;
  }
`;

export const StyledTabListThree = styled(TabList)`
  justify-content: center;
  list-style: none;
  margin: 0px;
  padding: 0px;
  background-color: ${({ bgColor }) => bgColor || 'white'}
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  width: 100%;
  margin: 0 auto;
  display: inline-flex;
  // border: 1px solid #e4e4e4;
  border-bottom: 0px;
  clear: both;
  padding-top: 10px;
`;

export const StyledTabThree = styled(Tab)`
  font-weight: ${({ type }) =>
    type === 'compliance'
      ? '600 !important'
      : type === 'compliance-tab'
      ? 'bold !important'
      : 'normal !important'};
  left: 0 !important;
  display: inline-block !important;
  border-bottom: 0px solid #efefef !important;
  border-right: 0px solid #efefef;
  font-family: 'Poppins';
  cursor: pointer;
  float: left;
  position: relative;
  font-size: ${({ type }) =>
    type === 'compliance-tab'
      ? '12px !important'
      : type === 'analytics-tab'
      ? '18px'
      : '14px !important'};
  color: #6351ed !important;
  padding: 8px 0px !important;
  border-top-left-radius: 6px;
  flex: 1 1 0px;

  &:nth-child(even) {
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    background-color: #e4e4e5;
  }
  &:last-child {
    border-top-left-radius: 0px;
    border-top-right-radius: 6px;
  }

  &.react-tabs__tab--selected:after {
    color: ${({ theme }) => (theme === 'light' ? '#6351ed' : '#fff')};
  }
  &.react-tabs__tab--selected {
    background-color: ${({ theme }) =>
      theme === 'light' ? '#d5cce6' : '#fff'};
  }
`;

export const StyledTabFour = styled(Tab)`
  font-weight: ${({ type }) =>
    type === 'compliance'
      ? '600 !important'
      : type === 'compliance-tab'
      ? 'bold !important'
      : 'normal !important'};
  left: 0 !important;
  display: inline-block !important;
  border-bottom: 0px solid #efefef !important;
  border-right: 0px solid #efefef;
  font-family: 'Poppins';
  cursor: pointer;
  float: left;
  position: relative;
  font-size: ${({ type }) =>
    type === 'compliance-tab'
      ? '12px !important'
      : type === 'analytics-tab'
      ? '18px'
      : '14px !important'};
  color: ${purpleishBlue};
  padding: ${({ padding }) => padding || '8px 0px !important'};
  // border-top-left-radius: 6px;
  flex: 1 1 0px;
  ${({ currentTab }) =>
    currentTab
      ? `
  background: #7dc5d0;
  color: ${white};
  border: 1.3px solid #7dc5d0;
`
      : `
  background: ${greyWhite};
  color: ${charcoalGrey};
  border: 1px solid white;
`}
`;
