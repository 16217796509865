import { styled } from '@mui/material';

export const Wrapper = styled('div')(() => ({
  width: '300px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  background: '#fff',
  border: '1px solid #BBBEF7',
  borderRadius: '0.5rem',
}));

export const Header = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '0.5rem',
  padding: '1.5rem',
  background:
    'radial-gradient(161% 76.6% at 23.21% 26.44%, rgba(233, 224, 255, 0.49) 0%, rgba(233, 224, 255, 0.00) 100%)',
  borderBottom: '1px solid #BBBEF7',

  svg: {
    color: '#8C8BEB',
    fontSize: '2rem',
  },
}));

export const Heading = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '0.13rem',

  h4: {
    fontSize: '1.25rem',
    fontWeight: '500',
    color: '#101828',
    margin: 0,
    textAlign: 'left',
  },
  p: {
    fontSize: '0.875rem',
    fontWeight: '500',
    color: '#475467',
    margin: 0,
    textAlign: 'left',
    marginBottom: '0.5rem',
  },
}));

export const Content = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '1.5rem',
  padding: '1.5rem',
}));

export const Section = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '1rem',

  h4: {
    fontSize: '1rem',
    fontWeight: '500',
    color: '#101828',
    margin: 0,
    textAlign: 'left',
  },
}));

export const SectionContent = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '1rem',
}));

export const CheckboxWrapper = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '0.5rem',

  label: {
    color: '#101828',
    fontSize: '0.875rem',
    fontWeight: '400',
    margin: 0,
  },
}));

export const Checkbox = styled('input')(() => ({
  width: '1.25rem',
  height: '1.25rem',
  backgroundColor: '#FCFDFD',
  color: '#6250ED',
  border: '1px solid #E4E7EC',
  borderRadius: '0.25rem',
  userSelect: 'none',

  '&:checked': {
    backgroundColor: '#6250ED',
    border: 'none',
  },
}));

export const Separator = styled('div')(() => ({
  width: '100%',
  height: '1px',
  background: '#D0D5DD',
}));

export const TopicsContainer = styled('div')(() => ({
  width: '250px',
  fontSize: '0.875rem',
  backgroundColor: '#fff',
}));
