import { styled } from '@mui/material';

export const BreadCrumbWrapper = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  gap: '1rem',
  alignItems: 'center',
  padding: '1rem 2rem',
}));

export const LeftIconWrapper = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  paddingRight: '1rem',
  color: '#475467',
  borderRight: '1px solid #98A2B3',
  '& svg': {
    cursor: 'pointer',
  },
}));

export const BreadCrumbsItemWrapper = styled('div')(() => ({
  '& .previous-route-link': {
    color: '#667085',
    padding: '0.5rem',
    textDecoration: 'none',
    fontFamily:'Poppins,sans-serif'
  },

  '& .active-route-link': {
    background: 'rgba(16, 24, 40, 0.04)',
    padding: '0.5rem',
    color: '#101828',
    borderRadius: '0.25rem',
    textTransform: 'capitalize',
    fontFamily:'Poppins,sans-serif'
  },

  '& .MuiBreadcrumbs-separator': {
    fontSize: '24px',
    color: '#98A2B3',
  },

  '& .MuiBreadcrumbs-ol': {
    gap: '0.5rem',
  },
}));
