import { Flex } from '@rebass/grid';
import styled from 'styled-components';

export const ModalFooterBtnsWrapper = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 10px;
`;

export const FeedbackInputLabel = styled.div`
  text-align: left;
  margin-bottom: 5px;
  color: black;
`;

export const ReviewHeader = styled.div`
  font-size: 0.9rem;
  font-weight: 700;
  width: 100%;
  text-align: left;
`;

export const ReviewBody = styled.div`
  font-size: ${({ fontSize }) => fontSize || '0.9rem'};
  font-weight: ${({ fontWeight }) => fontWeight};
  margin: ${({ margin }) => margin};

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background: #e9f6e6 !important;
  }

  &::-webkit-scrollbar-thumb {
    background: #bccdd4 !important;
  }
`;

export const PreviewContainer = styled.div`
  padding: 10px;
  width: 100%;
  height: 100%;
  text-align: left;
  position: relative;
`;

export const PaginationInfo = styled(Flex)`
  border: 1px solid #c4c6ca;
  border-radius: 5px;
  padding: 2px 20px;
  height: 25px;
  align-items: center;
  justify-content: center;
  margin: 0px 5px;
`;

export const PaginationBtn = styled.button`
  border: 1px solid #edeff3;
  border-radius: 50%;
  height: 25px;
  width: 25px;
  align-items: center;
  justify-content: center;
  display: flex;
  color: ${({ disabled }) => (disabled ? '#c1c8d0' : '#55667b')};
  background-color: white;
  font-weight: 700;
  box-shadow: ${({ disabled }) =>
    disabled || '0px 2px 5px -1px rgba(0, 0, 0, 0.2)'};
  cursor: pointer;
`;

export const TemplateContainer = styled.div`
  width: 100%;
  height: 48vh;

  iframe {
    width: inherit;
    height: inherit;
  }
`;

export const PreviewTooltip = styled.div.attrs({
  className: 'tooltip',
})`
  position: relative;
  display: inline-block;

  & .tooltiptext {
    visibility: hidden;
    width: 138px;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    border: 1px solid #4e6780;
    font-size: 10px;
    font-weight: 600;
    /* Position the tooltip */
    position: absolute;
    z-index: 1;
    bottom: 150%;
    left: 15%;
    margin-left: -60px;
    ${'' /* margin-top: 5px; */}
  }

  & .tooltiptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: black transparent transparent transparent;
  }

  &:hover .tooltiptext {
    visibility: visible;
  }
`;

