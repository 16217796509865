import * as React from 'react';

function People(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" {...props}>
      <path
        d="M12 15a6 6 0 100-12 6 6 0 000 12z"
        stroke="currentColor"
        strokeWidth={2}
        strokeMiterlimit={10}
      />
      <path
        d="M2.906 20.25a10.5 10.5 0 0118.188 0"
        stroke="currentColor"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

const MemoPeople = React.memo(People);
export default MemoPeople;
