import React from 'react';

import { FlipWrapper, FlipInner, FlipFront, FlipBack } from './FlipCard.styles';

const FlipCard = ({ flipFront, flipBack, shouldFlip, height }) => {
  return (
    <FlipWrapper height={height}>
      <FlipInner shouldFlip={shouldFlip} height={height}>
        <FlipFront height={height}>{flipFront}</FlipFront>
        <FlipBack height={height}>{flipBack}</FlipBack>
      </FlipInner>
    </FlipWrapper>
  );
};

export default FlipCard;
