
import styled from 'styled-components';

export const AdvisorProfileImage = styled.img`
  border-radius: 20px;
  width: 142px;
  height: 142px;
  object-fit: cover;
  margin: 0 auto;
  display: block;
  vertical-align: middle;
  border: 1px solid #e4e7ec;
`;
export const AdvisorEditButton = styled.button`
  background-color: #eee !important;
  border: 1px solid #a0a0a0 !important;
  color:  #6351ed !important;
  padding: 5px 10px;
  font-size: 14px;
  width: 130px;
  margin-top: 10px;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  cursor: pointer;
  border-radius: 4px;
`;
export const AdvisorInputWrapper = styled.div`
  position: relative;
  margin-top: 17px;
`;

export const AdvisorContactEmailInputWrapper = styled(AdvisorInputWrapper)`
  min-width: 100%;
`;
  
export const EmailToolTipWrapper = styled.div.attrs({
    className: 'email-tooltip-wrapper',
  })`
    .tooltip-text {
      visibility: hidden;
    width: 282.4px;
      background-color: #49484a;
      color: #cccccc;
      text-align: center;
      border-radius: 6px;
      padding: 5px 5px;
      font-size: 10px;
      text-align: left;
      /* Position the tooltip */
      position: absolute;
      z-index: 1;
      top: 124%;
      left: -63px;
      margin-left: -60px;
      display: flex;
      align-items: center;
    }
  
    &:hover .tooltip-text {
      visibility: visible;
    }
  `;

export const AdvisorInputIcon = styled.span`
  color: #757575;
  cursor: pointer;
  line-height: 0;
  font-size: 13px;
  position: relative;
  padding: 10px;
  position: absolute;
  right: 5px;
  top: 0px;
`;

export const HorizontalLine = styled.hr`
  border-top: 2px solid #e4e4e4 !important;
  width: 70%;
  display: block;
  margin: 0 auto;
  clear: both;
  border-left: 0;
  border-right: 0;
  border-bottom: 0;
  margin-top: 20px;
  margin-bottom: 20px;
`;

