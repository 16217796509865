import { all, fork } from 'redux-saga/effects';
import loginSaga from './login.saga';
import exploreSaga from './explore-tab.saga';
import advisorGroupsSaga from './advisor-group.saga';
import inviteSaga from './invite.saga';
import contentlibSaga from './content-lib.saga';
import shareSaga from './share.saga';
import groupSaga from './group.saga';
import portfolioSaga from './portfolio.saga';
import contactsSaga from './contacts.saga';
import themeSaga from './theme.saga';
import membersSaga from './members.saga';
import channelSaga from './channel.saga';
import profileSaga from './profile.saga';
import analyticsSaga from './analytics.saga';
import complianceSaga from './compliance.saga';
import socialNetworkSaga from './socialNetwork.saga';
import { editorSettingsSaga } from './editor-settings.saga';
import articleDetailSaga from './article-detail.saga';
import newsletterSaga from './newsletter.saga';
import campaignSaga from './campaign.saga';
import campaignDetailsSaga from './campaignDetails.saga';
import complianceTrailSaga from './compliance-trail.saga';

export default function* () {
  yield all([
    fork(loginSaga),
    fork(exploreSaga),
    fork(advisorGroupsSaga),
    fork(inviteSaga),
    fork(contentlibSaga),
    fork(shareSaga),
    fork(groupSaga),
    fork(portfolioSaga),
    fork(contactsSaga),
    fork(themeSaga),
    fork(membersSaga),
    fork(channelSaga),
    fork(profileSaga),
    fork(analyticsSaga),
    fork(complianceSaga),
    fork(socialNetworkSaga),
    fork(editorSettingsSaga),
    fork(articleDetailSaga),
    fork(newsletterSaga),
    fork(campaignSaga),
    fork(campaignDetailsSaga),
    fork(complianceTrailSaga),
  ]);
}
