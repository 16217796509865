import React from 'react';
import {
  Button,
  ModalBody,
  ModalCloseButton,
  ModalV2,
} from '../../../../components';
import { Flex } from '@rebass/grid';
import {
  CardSubtitle,
  CardText,
  CardTitle,
  CreateContainerCards,
} from '../../CreateCampaign/CreateCampaign.styles';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { AUTOMATED_CAMPAIGN_ACTIONS } from '../../../AutomatedCampaigns/AutomatedCampaignsContainer';


const CreateCampaignModal = ({ isOpen, handleClose, campaignSetState }) => {
  const history = useHistory();
  const [showACModalOptions, setShowACModalOptions] = useState(false);
  const [showNormalCampaignModalOptions, setShowNormalCampaignModalOptions] =
    useState(false);

  return (
    <ModalV2
      onRequestClose={() => handleClose()}
      shouldCloseOnOverlayClick
      isOpen={isOpen}
      bgColor="white"
      width={
        !showACModalOptions && !showNormalCampaignModalOptions ? '50%' : '73%'
      }
    >
      <ModalCloseButton onClick={() => handleClose()} />
      {!showACModalOptions && !showNormalCampaignModalOptions ? (
        <ModalBody>
          <Flex
            display="flex"
            justifyContent="center"
            width="100%"
            flexDirection="column"
          >
            <Flex
              display="flex"
              justifyContent="center"
              width="100%"
              fontSize="20px"
              color="#101828"
            >
              Create a Campaign
            </Flex>
            <Flex
              display="flex"
              flexDirection="row"
              justifyContent="center"
              alignItems="center"
              flexWrap="wrap"
            >
              <CreateContainerCards style={{ width: '273px', height: '275px' }}>
                <Flex
                  display="flex"
                  justifyContent="center"
                  marginTop="10px"
                  marginBottom="20px"
                >
                  <img
                    alt="one-time"
                    src="/assets/images/Campaign/Onetime-Campaign.svg"
                  />
                </Flex>
                <CardText>
                  <CardTitle>One Time & Series</CardTitle>
                  <CardSubtitle style={{ textAlign: 'center' }}>
                    Create a one-time email or social post, or group several together to send over time.
                  </CardSubtitle>
                </CardText>
                <Button
                  borderRadius="5px"
                  id="btn_one_time"
                  bgColor="#FFFFFF"
                  color="#101828"
                  borderColor="#E4E7EC"
                  onClick={() => {
                    campaignSetState({
                      selectedIndex: 0,
                      createMode: 'series',
                      curFlow: 'emailAndSocial',
                      subscriptionType: '',
                      activeIndex: 0,
                      campaignId: '',
                      createFlow: true,
                      setObjectives: {},
                      setTopics: {},
                      contentWeeks: [],
                      planCampaign: {},
                      campaignArticles: [],
                      scheduleTimings: {},
                      selectedWeek: '',
                      selectedDays: '',
                      selectedArticles: [],
                      publishedContent: {},
                      defaultTemplates: [],
                      setTemplate: '',
                      savedTemplate: null,
                      scheduleTypes: {},
                      isEdit: false,
                      isFromAddCampaign: false,
                      summaries: null,
                      seriesId: '',
                      leadGenReferralData: null,
                      fromDashboard: false,
                      reviewCampaign:{},
                      reviewType: '',
                      acBack: false,
                      selectedTimeBlocks: [],
                      automatedCampaigns: {}
                    });
                    handleClose();
                    history.push('/campaignV2?type=create');
                  }}
                  // onClick={() => {
                  //   // setCreateMode('oneTime');
                  //   //     campaignSetState({
                  //   //       selectedIndex: 0,
                  //   //       createMode: 'oneTime',
                  //   //       curFlow: '',
                  //   //     });
                  //   //   }}
                  //   //setShowNormalCampaignModalOptions(true);
                  // }}
                >
                  Create
                </Button>
              </CreateContainerCards>
              {/* <CreateContainerCards style={{ width: '273px', height: '275px' }}>
                <Flex
                  display="flex"
                  justifyContent="center"
                  marginTop="10px"
                  marginBottom="20px"
                >
                  <img
                    alt="series"
                    src="/assets/images/Campaign/Series-Campaign.svg"
                  />
                </Flex>
                <CardText>
                  <CardTitle>Series</CardTitle>
                  <CardSubtitle style={{ textAlign: 'center' }}>
                    Create a series of campaigns which you would group together
                    logically.
                  </CardSubtitle>
                </CardText>
                <Button
                  borderRadius="5px"
                  id="btn_one_time"
                  bgColor="#FFFFFF"
                  color="#101828"
                  borderColor="#E4E7EC"
                  onClick={() => {
                    // setCreateMode('oneTime');
                    //     campaignSetState({
                    //       selectedIndex: 0,
                    //       createMode: 'oneTime',
                    //       curFlow: '',
                    //     });
                    //   }}
                    console.log('clicked series button');
                  }}
                >
                  Create
                </Button>
              </CreateContainerCards> */}
              <CreateContainerCards style={{ width: '273px', height: '275px' }}>
                <Flex
                  display="flex"
                  justifyContent="center"
                  marginTop="10px"
                  marginBottom="20px"
                >
                  <img
                    alt="automated"
                    src="/assets/images/Campaign/Series-Campaign.svg"
                  />
                </Flex>
                <CardText>
                  <CardTitle>Automated</CardTitle>
                  <CardSubtitle style={{ textAlign: 'center' }}>
                    Select this option if you want to create an automated drip
                    campaign.
                  </CardSubtitle>
                </CardText>
                <Button
                  borderRadius="5px"
                  id="btn_one_time"
                  bgColor="#FFFFFF"
                  color="#101828"
                  borderColor="#E4E7EC"
                  onClick={() => {
                    // setCreateMode('oneTime');
                    //     campaignSetState({
                    //       selectedIndex: 0,
                    //       createMode: 'oneTime',
                    //       curFlow: '',
                    //     });
                    //   }}
                    console.log('clicked automated button');
                    setShowACModalOptions(true);
                  }}
                >
                  Create
                </Button>
              </CreateContainerCards>
            </Flex>
          </Flex>
        </ModalBody>
      ) : showACModalOptions && !showNormalCampaignModalOptions && (
        <ModalBody>
          <Flex
            display="flex"
            justifyContent="center"
            width="100%"
            flexDirection="column"
          >
            <Flex
              display="flex"
              justifyContent="center"
              width="100%"
              fontSize="20px"
              color="#101828"
            >
              Create an Automated Campaign
            </Flex>
            <Flex
              display="flex"
              flexDirection="row"
              justifyContent="center"
              alignItems="center"
              flexWrap="wrap"
            >
              <CreateContainerCards style={{ width: '275px', height: '300px' }}>
                <Flex
                  display="flex"
                  justifyContent="center"
                  marginTop="10px"
                  marginBottom="20px"
                >
                  <img
                    alt="one-time"
                    src="/assets/images/Campaign/new-welcome-clients.svg"
                  />
                </Flex>
                <CardText>
                  <CardTitle>Welcome New Clients</CardTitle>
                  <CardSubtitle style={{ textAlign: 'center' }}>
                    Send a series of communications to new clients.
                  </CardSubtitle>
                </CardText>
                <Button
                  borderRadius="5px"
                  id="btn_one_time"
                  bgColor="#FFFFFF"
                  color="#101828"
                  borderColor="#E4E7EC"
                  onClick={() => {
                    campaignSetState({
                      selectedIndex: 0,
                      createMode: 'automated',
                      curFlow: AUTOMATED_CAMPAIGN_ACTIONS.WELCOME_CLIENTS,
                      subscriptionType: '',
                      campaignId: '',
                      createFlow: true,
                      setObjectives: {},
                      setTopics: {},
                      contentWeeks: [],
                      planCampaign: {},
                      campaignArticles: [],
                      scheduleTimings: {},
                      selectedWeek: '',
                      selectedDays: '',
                      selectedArticles: [],
                      publishedContent: {},
                      defaultTemplates: [],
                      setTemplate: '',
                      savedTemplate: null,
                      scheduleTypes: {},
                      isEdit: false,
                      isFromAddCampaign: false,
                      summaries: null,
                      seriesId: '',
                      leadGenReferralData: null,
                      fromDashboard: false,
                      reviewCampaign:{},
                      reviewType: '',
                      acBack: false,
                      selectedTimeBlocks: [],
                      automatedCampaigns: {
                        activeStepIndex: 0
                      }
                    });
                    handleClose();
                    history.push('/campaignV2?type=create');
                  }}
                >
                  Select
                </Button>
              </CreateContainerCards>
              <CreateContainerCards style={{ width: '275px', height: '300px' }}>
                <Flex
                  display="flex"
                  justifyContent="center"
                  marginTop="10px"
                  marginBottom="20px"
                >
                  <img
                    alt="series"
                    src="/assets/images/Campaign/new-nurture-prospects.svg"
                  />
                </Flex>
                <CardText style={{ marginBottom: '17px' }}>
                  <CardTitle>Nurture Prospects</CardTitle>
                  <CardSubtitle style={{ textAlign: 'center' }}>
                    Engage new prospects.
                  </CardSubtitle>
                </CardText>
                <Button
                  borderRadius="5px"
                  id="btn_one_time"
                  bgColor="#FFFFFF"
                  color="#101828"
                  borderColor="#E4E7EC"
                  onClick={() => {
                    campaignSetState({
                      selectedIndex: 0,
                      createMode: 'automated',
                      curFlow: AUTOMATED_CAMPAIGN_ACTIONS.NURTURE_PROSPECTS,
                      subscriptionType: '',
                      campaignId: '',
                      createFlow: true,
                      setObjectives: {},
                      setTopics: {},
                      contentWeeks: [],
                      planCampaign: {},
                      campaignArticles: [],
                      scheduleTimings: {},
                      selectedWeek: '',
                      selectedDays: '',
                      selectedArticles: [],
                      publishedContent: {},
                      defaultTemplates: [],
                      setTemplate: '',
                      savedTemplate: null,
                      scheduleTypes: {},
                      isEdit: false,
                      isFromAddCampaign: false,
                      summaries: null,
                      seriesId: '',
                      leadGenReferralData: null,
                      fromDashboard: false,
                      reviewCampaign:{},
                      reviewType: '',
                      acBack: false,
                      selectedTimeBlocks: [],
                      automatedCampaigns: {
                        activeStepIndex: 0
                      }
                    });
                    handleClose();
                    history.push('/campaignV2?type=create');
                  }}
                >
                  Select
                </Button>
              </CreateContainerCards>
              <CreateContainerCards style={{ width: '275px', height: '300px' }}>
                <Flex
                  display="flex"
                  justifyContent="center"
                  marginTop="10px"
                  marginBottom="20px"
                >
                  <img
                    alt="automated"
                    src="/assets/images/Campaign/new-events.svg"
                  />
                </Flex>
                <CardText>
                  <CardTitle>Event</CardTitle>
                  <CardSubtitle style={{ textAlign: 'center' }}>
                    Promote and invite contacts to a webinar or live event.
                  </CardSubtitle>
                </CardText>
                <Button
                  borderRadius="5px"
                  id="btn_one_time"
                  bgColor="#FFFFFF"
                  color="#101828"
                  borderColor="#E4E7EC"
                  onClick={() => {
                    campaignSetState({
                      selectedIndex: 0,
                      createMode: 'automated',
                      curFlow: AUTOMATED_CAMPAIGN_ACTIONS.EVENT,
                      subscriptionType: '',
                      campaignId: '',
                      createFlow: true,
                      setObjectives: {},
                      setTopics: {},
                      contentWeeks: [],
                      planCampaign: {},
                      campaignArticles: [],
                      scheduleTimings: {},
                      selectedWeek: '',
                      selectedDays: '',
                      selectedArticles: [],
                      publishedContent: {},
                      defaultTemplates: [],
                      setTemplate: '',
                      savedTemplate: null,
                      scheduleTypes: {},
                      isEdit: false,
                      isFromAddCampaign: false,
                      summaries: null,
                      seriesId: '',
                      leadGenReferralData: null,
                      fromDashboard: false,
                      reviewCampaign:{},
                      reviewType: '',
                      acBack: false,
                      selectedTimeBlocks: [],
                      automatedCampaigns: {
                        activeStepIndex: 0
                      }
                    });
                    handleClose();
                    history.push('/campaignV2?type=create');
                  }}
                >
                  Create
                </Button>
              </CreateContainerCards>
            </Flex>
          </Flex>
        </ModalBody>
      )}
    </ModalV2>
  );
};

export default CreateCampaignModal;
