/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { get } from 'lodash';
import { Formik } from 'formik';
import * as Yup from 'yup';

import { SearchFilterMenu, ErrorContainer } from '../../../components';
import {
  getApprovedThemeList,
} from '../../../utils';
import { getPopularTheme } from '../../../services/dashboard';

import {
  SearchWrapper,
} from '../DashboardContainer.styles';
import {
  getChannelList,
  getAdminChannelData,
} from '../../../services/dashboard/index';
import { SearchInput } from '../../ConfigureContainer/EmailList/EmailList.styles';

const searchShema = Yup.object().shape({
  searchText: Yup.string().required('Please enter text'),
});

const AdvisorSearch = ({
  onSearchClick,
  setSearchValue,
  setTabIndex,
  themesList,
  channelData,
  exploreSetState,
  isComplianceAdmin,
  searchValue,
  isAdmin,
  selectedItems,
  setSelectedItems,
  topicSelected,
  setTopicSelected,
  selectedPublishers,
  setSelectedPublishers
}) => {
  
  const [mostSubscribedData, setMostSubscribedData] = useState([]);
  const approvedThemeList = getApprovedThemeList(themesList);
  const [filteredChannelList, setFilteredChannelList] = useState([]);

  useEffect(() => {
    if (isAdmin) {
      getAdminChannelData()
        .then((response) => {
          if (response && response.result && response.result.data) {
            let filteredData = Object.keys(response.result.data)
              .map((key) => {
                return {
                  ...response.result.data[key][0],
                  publisher_id: key,
                };
              })
              .filter((channel) => channel.is_hidden === 'false');
            setFilteredChannelList(filteredData);
          }
        })
        .catch((err) => console.log('ERROR - ', err));
    } else {
      getChannelList()
        .then((response) => {
          if (response && response.result && response.result.data) {
            let filteredData = Object.keys(response.result.data)
              .map((key) => {
                return {
                  ...response.result.data[key][0],
                  publisher_id: key,
                };
              })
              .filter((channel) => channel.is_hidden === 'false');
            setFilteredChannelList(filteredData);
          }
        })
        .catch((err) => console.log('ERROR - ', err));
    }
  }, []);

  useEffect(() => {
    exploreSetState({
      searchFilters: [...selectedPublishers, ...topicSelected],
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [topicSelected, selectedPublishers]);

  const onSearch = () => {
    let topic = [];
    if (topicSelected.includes('Your Topics')) {
      approvedThemeList.forEach(({ id }) => {
        topic.push(id);
      });
    }
    if (topicSelected.includes('Most Subscribed')) {
      mostSubscribedData.forEach(({ id }) => {
        topic.push(id);
      });
    }

    const inputParams = {
      channel: selectedItems.map(({ id }) => id),
      topic,
    };
    onSearchClick(inputParams);
  };

  const setTopicSelectedRequest = (data) => {
    setTopicSelected(data);
    let newTopics = data;
    let topic = [];
    if (newTopics.includes('Your Topics')) {
      approvedThemeList.forEach(({ id }) => {
        topic.push(id);
      });
    }
    if (newTopics.includes('Most Subscribed')) {
      mostSubscribedData.forEach(({ id }) => {
        topic.push(id);
      });
    }
    const inputParams = {
      channel: selectedItems.map(({ id }) => id),
      topic,
    };
    onSearchClick(inputParams);
  };
  const setSelectedItemsRequest = (data) => {
    let filterTags = [];
    if (exploreSetState) {
      data.forEach((item) => {
        if (item.name.includes('(')) {
          let name = item.name.split(/[()]/);
          filterTags.push(name[0].toUpperCase().trim());
          filterTags.push(name[1].toUpperCase().trim());
        } else {
          filterTags.push(item.name.toUpperCase().trim());
        }
      });
    }
    setSelectedPublishers(filterTags);
    setSelectedItems(data);
    let newSelectedItems = data;
    let topic = [];
    if (topicSelected.includes('Your Topics')) {
      approvedThemeList.forEach(({ id }) => {
        topic.push(id);
      });
    }
    if (topicSelected.includes('Most Subscribed')) {
      mostSubscribedData.forEach(({ id }) => {
        topic.push(id);
      });
    }
    const inputParams = {
      channel: newSelectedItems.map(({ id }) => id),
      topic,
    };
    onSearchClick(inputParams);
  };

  useEffect(() => {
    getPopularTheme().then((response) => {
      setMostSubscribedData(get(response, 'result.data', []));
    });
  }, []);

  const onFormSubmit = (ss) => {
    onSearch();
  };
  return (
    <div>
      <Formik
        validateOnChange={false}
        initialValues={{ searchText: '' }}
        onSubmit={onFormSubmit}
        validationSchema={searchShema}
      >
        {({ handleSubmit, handleChange, values, errors }) => (
          // <SearchWrapper width="100%" mt="3.7em" mb={errors.searchText && errors.searchText.length > 0 ? "1.2em" : "2.2em"}flexDirection="column" pl='3%' pr='3%'>
          // <Flex width={1} as="form" flexDirection="column">
          //   <StyledSearchInput
          //     autoComplete="off"
          //     type="text"
          //     name="searchText"
          //     placeholder="What are you looking for?"
          //     value={searchValue}
          //     onChange={(e) => {
          //       handleChange(e);
          //       setSearchValue(e.target.value);
          //     }}
          //   />
          //   <SearchButton type="submit" onClick={handleSubmit}>
          //     <FontAwesomeIcon icon={faSearch} />
          //   </SearchButton>
          //   <ErrorContainer align="center">
          //     {errors.searchText}
          //   </ErrorContainer>
          //   {!isComplianceAdmin && (
          //     <SearchFilterMenu
          //       topics={approvedThemeList}
          //       channelData={filteredChannelList}
          //       selectedItems={selectedItems}
          //       setSelectedItems={setSelectedItemsRequest}
          //       topicSelected={topicSelected}
          //       setTopicSelected={setTopicSelectedRequest}
          //       exploreSetState={exploreSetState}
          //     />
          //   )}

          // </Flex>
          // </SearchWrapper>
          <>
          <SearchWrapper
            width="100%"
            mt={(topicSelected && topicSelected.length > 0) || (selectedItems && selectedItems.length > 0) ? "0.7em" : "1.7em"}
            mb={
              errors.searchText && errors.searchText.length > 0
                ? '0em'
                : (topicSelected) || (selectedItems && selectedItems.length > 0)
                ? '0.3em'
                : '0.5em'
            }
            flexDirection="row"
            pl="3%"
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '76%',
                backgroundColor: '#FFFFFF',
                border: '1px solid #E4E7EC',
                borderRadius: '4px',
                padding: '0px',
                margin: '9px 0px 0px 7px',
                height: '35px',
              }}
            >
              <img
                alt="search content"
                src="/assets/images/grey-search-icon.svg"
                type="submit"
                style={{
                  cursor: 'pointer',
                  padding: '6px 0px 6px 0px',
                  height: '32px',
                }}
                onClick={handleSubmit}
              />
              <SearchInput
                placeholder="What are you looking for?"
                name="searchText"
                type="text"
                autoComplete="off"
                spellCheck={false}
                // onChange={(e) => {
                //   handleChange(e);
                //   setSearchValue(e.target.value);
                // }}
                // onKeyUp={(e) => {
                //   if (e.key === 'Backspace') {
                //     console.log('Backspace');
                //     handleChange(
                //       e.target.value.substring(0, e.target.value - 1)
                //     );
                //   } else {
                //     handleChange(e.target.value);
                //   }
                // }}
                onKeyPress={(e) => (e.key === 'Enter' ? handleSubmit() : {})}
                onChange={(e) => {
                        handleChange(e);
                        setSearchValue(e.target.value);
                      }}
                value={searchValue}
              />
            </div>
            {!isComplianceAdmin && (
              <SearchFilterMenu
                topics={approvedThemeList}
                channelData={filteredChannelList}
                selectedItems={selectedItems}
                setSelectedItems={setSelectedItemsRequest}
                topicSelected={topicSelected}
                setTopicSelected={setTopicSelectedRequest}
                exploreSetState={exploreSetState}
              />
            )}
          </SearchWrapper>
          <ErrorContainer align="center" style={{ marginBottom: errors.searchText && errors.searchText.length > 0
                ? '0.5em'
                : (topicSelected && topicSelected.length > 0) || (selectedItems && selectedItems.length > 0)
                ? '0em'
                : '0.5em'
            }}>{errors.searchText}</ErrorContainer>
          </>
        )}
      </Formik>
    </div>
  );
};

export default AdvisorSearch;
