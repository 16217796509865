import { styled } from '@mui/material';
import Button from 'src/componentsV2/Button';
import ReactSkeleton from 'react-loading-skeleton';

export const Card = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  alignItems: 'stretch',
  gap: '1rem',
  overflow: 'hidden',
}));

export const ImageContainer = styled('div')(() => ({
  width: '30%',
  minWidth: '250px',
  maxWidth: '350px',
  height: '225px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  background: '#ECF2FC',
  border: '1px solid #E4E7EC',
  borderRadius: '0.25rem',
  overflow: 'hidden',
  cursor: 'pointer',

  img: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
}));

export const Section = styled('div')(() => ({
  flex: 1,
  width: '100%',
  minHeight: '200px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  gap: '1rem',
  overflow: 'hidden',
}));

export const ContentWrapper = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '0.75rem',
  overflow: 'hidden',
}));

export const Content = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '0.5rem',
  overflow: 'hidden',
  cursor: 'pointer',

  h4: {
    fontSize: '1.25rem',
    fontWeight: '500',
    color: '#101828',
    margin: 0,
    textAlign: 'left',
    display: '-webkit-box',
    '-webkit-line-clamp': '2',
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
  },

  p: {
    fontSize: '0.875rem',
    fontWeight: '400',
    color: '#475467',
    margin: 0,
    textAlign: 'left',
    display: '-webkit-box',
    '-webkit-line-clamp': '2',
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
  },
}));

export const Description = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '0.5rem',
  width: 'fit-content',

  span: {
    fontSize: '0.75rem',
    fontWeight: '400',
    color: '#667085',
    margin: 0,
  },

  '.separator': {
    width: 4,
    height: 4,
    borderRadius: '50%',
    backgroundColor: '#667085',
  },
}));

export const Pills = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  gap: '0.25rem',
  flexWrap: 'wrap',
}));

export const Pill = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '0.25rem 0.5rem',
  fontSize: '0.75rem',
  fontWeight: '500',
  lineHeight: 1,
  color: '#101828',
  backgroundColor: '#F9FAFB',
  border: '1px solid #E4E7EC',
  borderRadius: '0.75rem',
}));

export const Actions = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: '0.75rem',
}));

export const PrimaryActions = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '0.75rem',
}));

export const SecondaryActions = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '0.75rem',
}));

export const BookmarkContainer = styled(Button)(({ bookmarked = false }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '0.25rem',
  padding: '0.5rem',
  background: '#fff',
  border: '1px solid #E4E7EC',
  boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.12)',

  svg: {
    color: bookmarked ? '#6250ED' : '#101828',
    fontSize: '1.5rem',
  },

  h4: {
    fontSize: '0.875rem',
    fontWeight: '500',
    color: bookmarked ? '#6250ED' : '#101828',
    margin: 0,
    textAlign: 'left',
  },
}));

export const PillSkeleton = styled(ReactSkeleton)(() => ({
  borderRadius: '0.75rem',
}));
