import React, { useState, useEffect } from 'react';
import { get, isEmpty } from 'lodash';

import { InsightsArticle, LoaderWrapper } from '../../../components';
import { eventHandlers, getExploreArticleData } from '../../../utils';
import { getSponsorList, postArticlesToNewsletterBucket } from '../../../services/dashboard';
import { TopInsightsWrapper } from '../DashboardContainer.styles';
import { deleteBucketArticle } from '../../../services/newsletter';

const FromYourSponsor = ({ isShareBlocked, shareArticleSetState, themeNames, popularThemes, userData, sponsoredArticles, setSponsoredArticles, bucketCountRequest }) => {
  const [popularThemesFetching, setTopInsightsFetching] = useState(false);

  useEffect(() => { 
      if (
        sponsoredArticles &&
        sponsoredArticles.length > 0 &&
        popularThemes &&
        popularThemes.length > 0
      ) {
        const userEmail =
          userData &&
          userData.details &&
          userData.details.user &&
          userData.details.user.email;
        sponsoredArticles.forEach((ele) => {
          eventHandlers(
            'SPONSORED_ARTICLE_DISPLAYED',
            popularThemes,
            ele.id,
            'SPONSORED_BOX',
            get(ele, 'channelId', ''),
            ele.title,
            ele.themeId,
            themeNames[ele.themeId],
            ele.topic,
            userEmail
          );
        });
      }
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [popularThemes]);

  const addToNewsletterBucket = (articleId, themeId) => {
    postArticlesToNewsletterBucket({articleId, themeId}).then((response) => {
      if(response.result.success) {
        setTopInsightsFetching(true);
        getSponsorList().then(res => {
          setTopInsightsFetching(false);
          setSponsoredArticles(getExploreArticleData(res.result.data));
        }).catch(err => {
          setTopInsightsFetching(false);
        })
        bucketCountRequest();
      }
    }).catch(err => {
      console.log(err)
    })
  }

  const removeToNewsletterBucket = (bucketId) => {
    deleteBucketArticle({ id: bucketId }).then((response) => {
      if(response === '') {
        setTopInsightsFetching(true);
        getSponsorList().then(res => {
          setTopInsightsFetching(false);
          setSponsoredArticles(getExploreArticleData(res.result.data));
        }).catch(err => {
          setTopInsightsFetching(false);
        });
        bucketCountRequest();
      }
    })
  }
  // console.log('sponsoredArticles - ', sponsoredArticles);
  return (
    <TopInsightsWrapper style={{ height: '198px' }}>
      <LoaderWrapper isLoading={popularThemesFetching}>
        {!isEmpty(sponsoredArticles) && sponsoredArticles.map((insight, index) => (
          <InsightsArticle
            key={'insight_' + index}
            {...insight}
            isShareBlocked={isShareBlocked}
            shareArticleSetState={shareArticleSetState}
            themeNames={themeNames}
            popularThemes={popularThemes}
            addToNewsletterBucket={addToNewsletterBucket}
            removeToNewsletterBucket={removeToNewsletterBucket}
            userData={userData}
            isSponsored
            article={insight}
          />
        ))}
      </LoaderWrapper>
    </TopInsightsWrapper>
  );
};

export default FromYourSponsor;
