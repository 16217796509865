import styled from 'styled-components';

export const ColorPick = styled.div`
  float: right;
  width: 20px;
  height: 20px;
  border: none;
  padding: 0;
  zoom: 0.77;
  position: relative;
  cursor: pointer;
  z-index: 20;
`;
export const ColorPickBox = styled.div`
  position: absolute;
  right: 25px;
  top: -15px;
`;
