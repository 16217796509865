import React from 'react';
import { ManageUserContainer } from '../../containers/ManageUserContainer';

import withDripCampaignLayout from '../../hoc/withDripCampaignLayout';

const ManageUserLayout = () => {
  return (
    <ManageUserContainer />
  )
}

export default withDripCampaignLayout(ManageUserLayout)