import { landingPagesEndpoints } from "../../../services/helpers";

export const eventForm = {
   "structure": {
      "fields": {
         "first_name": {
            "type": "text",
            "canBeRemovedFromLayout": true,
            "attributes": {
               "required": true,
               "value": "",
               "placeholder": "First Name"
            }
         },
         "last_name": {
            "type": "text",
            "canBeRemovedFromLayout": true,
            "attributes": {
               "required": true,
               "value": "",
               "placeholder": "Last Name"
            }
         },
         "email": {
            "type": "email",
            "canBeRemovedFromLayout": false,
            "attributes": {
               "required": true,
               "value": "",
               "placeholder": "Email"
            }
         },
         "phone_number": {
            "type": "tel",
            "canBeRemovedFromLayout": true,
            "attributes": {
               "required": true,
               "value": "",
               "placeholder": "Phone Number (9994445555)",
               "pattern": "[0-9]{10,15}$"
            }
         },
         "dropdown_1": {
            "type": "select",
            "attributes": {
               "placeholder": "Dropdown One (Optional)",
               "required": false
            },
            "options": [
               {
                  "type": "option",
                  "label": "Dropdown One (Optional)",
                  "value": ""
               },
               {
                  "type": "option",
                  "label": "A1",
                  "value": "A1"
               },
               {
                  "type": "option",
                  "label": "B1",
                  "value": "B1"
               },
               {
                  "type": "option",
                  "label": "C1",
                  "value": "C1"
               }
            ]
         },
         "dropdown_2": {
            "type": "select",
            "attributes": {
               "placeholder": "Dropdown Two (Optional)",
               "required": false
            },
            "options": [
               {
                  "type": "option",
                  "label": "Dropdown Two (Optional)",
                  "value": ""
               },
               {
                  "type": "option",
                  "label": "A2",
                  "value": "A2"
               },
               {
                  "type": "option",
                  "label": "B2",
                  "value": "B2"
               },
               {
                  "type": "option",
                  "label": "C2",
                  "value": "C2"
               }
            ]
         },
         "dropdown_3": {
            "type": "select",
            "attributes": {
               "placeholder": "Select One (Optional)",
               "required": false
            },
            "options": [
               {
                  "type": "option",
                  "label": "Dropdown Three (Optional)",
                  "value": ""
               },
               {
                  "type": "option",
                  "label": "A3",
                  "value": "A3"
               },
               {
                  "type": "option",
                  "label": "B3",
                  "value": "B3"
               },
               {
                  "type": "option",
                  "label": "C3",
                  "value": "C3"
               }
            ]
         },
         "dropdown_4": {
            "type": "select",
            "attributes": {
               "placeholder": "Select One (Optional)",
               "required": false
            },
            "options": [
               {
                  "type": "option",
                  "label": "Dropdown Four (Optional)",
                  "value": ""
               },
               {
                  "type": "option",
                  "label": "A4",
                  "value": "A4"
               },
               {
                  "type": "option",
                  "label": "B4",
                  "value": "B4"
               },
               {
                  "type": "option",
                  "label": "C4",
                  "value": "C4"
               }
            ]
         },
         "dropdown_5": {
            "type": "select",
            "attributes": {
               "placeholder": "Select One (Optional)",
               "required": false
            },
            "options": [
               {
                  "type": "option",
                  "label": "Dropdown Five (Optional)",
                  "value": ""
               },
               {
                  "type": "option",
                  "label": "A5",
                  "value": "A5"
               },
               {
                  "type": "option",
                  "label": "B5",
                  "value": "B5"
               },
               {
                  "type": "option",
                  "label": "C5",
                  "value": "C5"
               }
            ]
         },
         "rsvp": {
            "type": "radio",
            "canBeRemovedFromLayout": false,
            "attributes": {
               "required": true,
               "value": "yes",
               "id": "radio_rsvp"
            },
            "label": "Will you be attending?",
            "options": [
               {
                  "type": "option",
                  "label": "Yes",
                  "value": "yes",
                  "id": "radio_yes"
               },
               {
                  "type": "option",
                  "label": "No",
                  "value": "no",
                  "id": "radio_no"
               },
            ]
         },
         // "privacy_policy": {
         //    "type": "checkbox",
         //    "label": "I agree to the Privacy Policy",
         //    "canBeRemovedFromLayout": true,
         //    "attributes": {
         //       "name": "privacy-policy",
         //       "required": true
         //    }
         // },
         "Register": {
            "type": "submit",
            "label": "",
            "canBeRemovedFromLayout": false,
            "attributes": {
               "value": "Register",
               "id": "event_button"
            }
         },
         "url": {
             "type": "hidden",
             "canBeRemovedFromLayout": false,
             "attributes": {
                 "value": "",
                 "id": "event_url"
             }
         },
         "form_type": {
             "type": "hidden",
             "canBeRemovedFromLayout": false,
             "attributes": {
                 "value": "event_form",
             }
         }
      },
      "layout": [
         ["first_name", "url"],
         ["last_name", "form_type"],
         ["email"],
         ["phone_number"],
         ["dropdown_1"],
         ["dropdown_2"],
         ["dropdown_3"],
         ["dropdown_4"],
         ["dropdown_5"],
         ["rsvp"],
         // ["privacy_policy"],
         ["Register"]
      ],
      "attributes": {
         "accept-charset": "UTF-8",
         "action": `${landingPagesEndpoints.baseUrl}/page-form`,
         "autocomplete": "on",
         "enctype": "multipart/form-data",
         "method": "post",
         "novalidate": false,
         "target": "_blank"
      },
      "title": "Login form",
      "description": "A BEE test form"
   },
   "style": {
       "fields":{
          "color":"#000000",
          "backgroundColor":"#ffffff",
          "outlineColor":"#3AAEE0",
          "border-top":"1px solid #D0D0D0",
          "border-right":"1px solid #D0D0D0",
          "border-bottom":"1px solid #D0D0D0",
          "border-left":"1px solid #D0D0D0",
          "padding-top":"5px",
          "padding-right":"5px",
          "padding-bottom":"5px",
          "padding-left":"5px",
          "border-radius":"4px"
       }
   }
}