import { styled } from '@mui/material';

export const SocialPreviewContainer = styled('div')(() => ({
  borderRadius: '0.25rem',
  marginTop: '0.625rem',
}));

export const SocialMediaPreviewWrapper = styled('div')(() => ({
  border: '1px solid #E4E7EC',
  borderRadius: '0.5rem',
  boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.12)',
  padding: '1rem',
}));

export const UserProfile = styled('header')(() => ({
  display: 'flex',
  gap: '0.75rem',
  marginBottom: '1.5rem',
}));

export const UserProfileContainer = styled('div')(() => ({
  background: '#F9FAFB',
  borderRadius: '100%',
  width: '60px',
  height: '60px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',

  '& svg': {
    fontSize: '1.5rem',
  },
}));

export const UserName = styled('h3')(({ margin = '0 0 0.625rem 0' }) => ({
  color: '#000',
  font: '500 1rem/1rem Poppins,sans-serif',
  margin: margin,
}));

export const UserProfileInfo = styled('p')(() => ({
  color: '#475467',
  font: '400 0.875rem/1rem Poppins,sans-serif',
  margin: 0,
  '& span::after': {
    content: "''",
    width: '6px',
    height: '6px',
    background: '#475467',
    position: 'relative',
    margin: '0 0.375rem',
    bottom: '0.0625rem',
    display: 'inline-block',
    borderRadius: '100%',
  },
}));

export const UserMessage = styled('p')(() => ({
  color: '#101828',
  font: '400 0.875/1rem Poppins,sans-serif',
  margin: '0.75rem 0',
}));

export const PostPreviewContainer = styled('div')(() => ({
  background: '#F9FAFB',
  borderRadius: '0.5rem',
  '& a': {
    textDecoration: 'none',
  },
}));

export const SocialMediaImageContainer = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'center',
}));

export const SocialMediaPost = styled('img')(() => ({
  width: '300px',
  objectFit: 'contain',
}));

export const SocialMediaPostInfoContainer = styled('div')(() => ({
  padding: '1rem',
  display: 'flex',
  flexDirection: 'column',
  gap: '0.5rem',
}));

export const PostLink = styled('p')(() => ({
  color: '#475467',
  font: '400 0.875rem/1rem Poppins,sans-serif',
  margin: 0,
  marginBottom: '0.5rem',
  textDecoration: 'underline',
}));

export const PostTitle = styled('h4')(() => ({
  color: '#101828',
  font: '500 1rem/1rem Poppins,sans-serif',
  margin: 0,
  marginBottom: '0.5rem',
}));

export const ArticleInfo = styled('p')(() => ({
  color: '#101828',
  font: '400 0.75rem/1rem Poppins,sans-serif',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  maxWidth: '800px',
  margin: 0,
}));

export const TwitterUserInfo = styled('div')(() => ({
  display: 'flex',
  gap: '0.5rem',
  marginBottom: '0.3125rem',
}));

export const LinkedinUserInfo = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '0.25rem',
}));
