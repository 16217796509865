/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import ToggleBar from '../../componentsV2/ToggleBar';
import EmailTemplates from '../../containers/TemplateContainer/EmailTemplates';
import ManageRows from '../../containers/TemplateContainer/ManageRows';
import LandingPages from '../../containers/TemplateContainer/LandingPages';
import { useHistory } from 'react-router-dom';
import {
    FlexContainer,
    SearchContainer,
    ButtonIcons,
    DropdownItems, 
    DropdownRow,
    LayoutCard,
    LayoutImg,
    LayoutText
} from '../../containers/TemplateContainer/styles';
import SearchBar from '../../componentsV2/SearchBar';
import WhiteButton from '../../componentsV2/WhiteButton';
import ModalContainer from '../../componentsV2/ModalContainer';
import PurpleButton from '../../componentsV2/PurpleButton';
// import sortArrows from '../../assets/icons/sortArrows.svg';
import addIcon from '../../assets/icons/addIcon.svg';
import downArrow from '../../assets/icons/downArrowWhite.svg';
import filter from '../../assets/icons/filterPurple.svg';
import purpleCheck from '../../assets/icons/purpleCheck.svg';
import oneColumn from '../../assets/icons/oneColumn.svg';
import twoColumns from '../../assets/icons/twoColumns.svg';
import oneColumnReverse from '../../assets/icons/oneColumnReverse.svg';
import { colors } from '../../styles';
import { connect } from 'react-redux';
import { get } from 'lodash';
import useQuery from '../../customHooks/useQuery';
import { USER_ROLE } from '../../utils';
import { getUserRole } from '../../redux/selector';

const toggleItems = [
    {
        name: 'Email Templates',
        key: 0
    },
    {
        name: 'Manage Rows',
        key: 1
    },
    {
        name: 'Landing Pages',
        key: 2
    }
];

const layoutDisplayOptions = [
    {
        title: 'One Column',
        icon: oneColumn,
        key: 'one-column'
    },
    {
        title: 'Two Columns',
        icon: twoColumns,
        key: 'two-columns'
    },
    {
        title: 'One Column Reverse',
        icon: oneColumnReverse,
        key: 'one-column-reverse'
    }
];

const TemplateContainer = ({
    isEnterpriseLevelAdmin,
    isFirmLevelAdmin,
    firmsArray,
    isAdmin
}) => {
    const [selectedToggle, setSelectedToggle] = useState(0)
    const [openSearch, setOpenSearch] = useState(false);
    const [showCreateModal, setShowCreateModal] = useState(false);
    const [searchString, setSearchString] = useState('');
    // const [showSortDropdown, setShowSortDropdown] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showCreateDropdown, setShowCreateDropdown] = useState(false);
    const [selectedFilter, setSelectedFilter] = useState('all');
    const [showFilterDropdown, setShowFilterDropdown] = useState(false);
    const [selectedTemplate, setSelectedTemplate] = useState();
    const [expandModalOpen, setExpandModalOpen] = useState(false);
    const [showDuplicateModal, setShowDuplicateModal] = useState(false);

    const history = useHistory();
    const query = useQuery();

    useEffect(() => {
        if(query.get('tab')) {
            if(query.get('tab') === 'rows') {
                setSelectedToggle(1);
            } else {
                setSelectedToggle(2);
            }
        } else {
            setSelectedToggle(0);
        }

        const windowBodyClassList = document.body.classList;
        if (!windowBodyClassList.contains('campaign')) {
            windowBodyClassList.add('campaign');
        }

        return () => {
            windowBodyClassList.remove('campaign');
        };

    }, [])

    const handleToggle = (tab) => {
        setSearchString('');
        setSelectedToggle(tab);
        setOpenSearch(false);

    }

    const pageDisplay = () => {
        switch(selectedToggle){
            case 0:
                return (
                    <EmailTemplates 
                        showCreateModal={showCreateModal}
                        setShowCreateModal={setShowCreateModal}
                        searchString={searchString}
                        showDeleteModal={showDeleteModal}
                        setShowDeleteModal={setShowDeleteModal}
                        selectedTemplate={selectedTemplate} 
                        setSelectedTemplate={setSelectedTemplate}
                        isEnterpriseLevelAdmin={isEnterpriseLevelAdmin}
                        firmsArray={firmsArray}
                        isAdmin={isAdmin}
                        showDuplicateModal={showDuplicateModal}
                        setShowDuplicateModal={setShowDuplicateModal}
                    />
                )
            case 1:
                return(
                    <ManageRows 
                        showCreateModal={showCreateModal}
                        setShowCreateModal={setShowCreateModal}
                        searchString={searchString}
                        selectedFilter={selectedFilter}
                        showDeleteModal={showDeleteModal}
                        setShowDeleteModal={setShowDeleteModal}
                        isAdmin={isAdmin}
                    />
                )
            case 2: 
                return(
                    <LandingPages 
                        showCreateModal={showCreateModal}
                        setShowCreateModal={setShowCreateModal}
                        searchString={searchString}
                        isAdmin={isAdmin}
                    />
                )
            default:
                return (
                    <EmailTemplates
                        showCreateModal={showCreateModal}
                        setShowCreateModal={setShowCreateModal}
                        searchString={searchString}
                        showDeleteModal={showDeleteModal}
                        setShowDeleteModal={setShowDeleteModal}
                        selectedTemplate={selectedTemplate}
                        setSelectedTemplate={setSelectedTemplate}
                        isEnterpriseLevelAdmin={isEnterpriseLevelAdmin}
                        firmsArray={firmsArray}
                        isAdmin={isAdmin}
                        showDuplicateModal={showDuplicateModal}
                        setShowDuplicateModal={setShowDuplicateModal}
                    />
                )
        }
    };

    const actionDisplay = () => {
        switch(selectedToggle){
            case 0:
                return(
                    <FlexContainer width={'50%'} margin={'-10px 0 0'} justify={'end'}>
                        {
                            searchDisplay()
                        }
                        {/* {
                            sortDisplay()
                        } */}
                        <PurpleButton
                            width={"120px"}
                            onClick={()=>setShowCreateModal(true)}
                        >
                            <ButtonIcons margin={'-3px 6px 0 0'} src={addIcon} alt='new' />
                            <span style={{paddingRight: 5}}>Create New</span>
                        </PurpleButton>
                    </FlexContainer>
                )
            case 1:
                return(
                    <FlexContainer width={'70%'} margin={'-10px 0 0'} justify={'end'}>
                        {
                            searchDisplay()
                        }
                        {/* {
                            sortDisplay()
                        } */}
                        {
                            filterDisplay()
                        }
                        {
                            createNewDropdown()
                        }
                    </FlexContainer>
                )
            case 2: 
            return(
                    <FlexContainer width={'50%'} margin={'-10px 0 0'} justify={'end'}>
                        {
                            searchDisplay()
                        }
                        {/* {
                            sortDisplay(),
                        } */}
                        <PurpleButton
                            width={"120px"}
                            onClick={()=>history.push('/campaign/landing-pages/new')}
                        >
                            <ButtonIcons margin={'-3px 6px 0 0'} src={addIcon} alt='new' />
                            <span style={{paddingRight: 5}}>Create New</span>
                        </PurpleButton>
                    </FlexContainer>
                )
            default:
                return <div />
        }
    };

    const filterDisplay = () => {
        const dropdownItems = [
            {
                title: 'All',
                key: 'all',
                action: (row) => setSelectedFilter(row.key)
            },
            {
                title: 'Article Block',
                key: 'article_block',
                action: (row) => setSelectedFilter(row.key)
            },
            {
                title: 'Signature',
                key: 'signature',
                action: (row) => setSelectedFilter(row.key)
            },
            {
                title: 'Disclaimer',
                key: 'disclaimer',
                action: (row) => setSelectedFilter(row.key)
            },
            {
                title: 'Header',
                key: 'header',
                action: (row) => setSelectedFilter(row.key)
            },
            {
                title: 'Footer',
                key: 'footer',
                action: (row) => setSelectedFilter(row.key)
            },
            {
                title: 'Landing Page',
                key: 'lading_page',
                action: (row) => setSelectedFilter(row.key)
            },
            {
                title: 'Webinar Block',
                key: 'webinar_block',
                action: (row) => setSelectedFilter(row.key)
            },
            {
                title: 'Content Block',
                key: 'content_block',
                action: (row) => setSelectedFilter(row.key)
            },
            {
                title: 'Disclosure Page',
                key: 'disclosure_page',
                action: (row) => setSelectedFilter(row.key)
            }
        ];

        return(
            <div style={{position: 'relative'}}>
                <WhiteButton
                    width={"120px"}
                    onClick={()=>{
                        setShowCreateDropdown(false)
                        setShowFilterDropdown(!showFilterDropdown)
                    }}>
                    <ButtonIcons src={filter} alt='sort' />
                    <span style={{color: colors.header_purple}}>Filter</span>
                </WhiteButton>
                { showFilterDropdown && <DropdownItems>
                    {
                        dropdownItems.map((row, index) => {
                            return(
                                <DropdownRow 
                                    key={index} 
                                    style={{
                                        justifyContent: 'space-between',
                                        color: selectedFilter === row.key && '#4634D0',
                                        background: selectedFilter === row.key && '#F6F2FF'
                                    }}
                                    onClick={()=>{
                                        row.action(row)
                                        setShowFilterDropdown(false)
                                    }
                                }>
                                    <div>{row.title}</div>
                                    {selectedFilter === row.key && <img src={purpleCheck} alt='selected' />}
                                </DropdownRow>
                            )
                        })
                    }
                </DropdownItems>}
            </div>
        )
    }

    // const sortDisplay = () => {
    //     const dropdownItems = [
    //         {
    //             title: 'Most Recent',
    //             action: () => console.log('most recent')
    //         },
    //         {
    //             title: 'A-Z',
    //             action: () => console.log('az')
    //         },
    //         {
    //             title: 'Compliance Status',
    //             action: () => console.log('compliance status')
    //         }
    //     ];

    //     return(
    //         <div style={{position: 'relative'}}>
    //             <WhiteButton
    //                 width={"120px"}
    //                 onClick={()=> setShowSortDropdown(!showSortDropdown)}
    //             >
    //                 <ButtonIcons src={sortArrows} alt='sort' />
    //                 <span style={{color: colors.header_purple}}>Sort By</span>
    //             </WhiteButton>
    //             { showSortDropdown && <DropdownItems>
    //                 {
    //                     dropdownItems.map((row, index) => {
    //                         return(
    //                             <DropdownRow key={index} onClick={()=>{
    //                                 row.action(row)
    //                                 setShowSortDropdown(false)
    //                             }}>
    //                                 <div>{row.title}</div>
    //                             </DropdownRow>
    //                         )
    //                     })
    //                 }
    //             </DropdownItems>}
    //         </div>
    //     )
    // }

    const createNewDropdown = () => {
        const dropdownItems = [
            {
                title: 'Article Row',
                action: () => setExpandModalOpen(true)
            },
            {
                title: 'Content Block',
                action: () => history.push('/campaign/custom-row/new-content_block')
            },
            {
                title: 'Disclaimer Row',
                action: () => history.push('/campaign/custom-row/new-disclaimer')
            },
            {
                title: 'Footer Row',
                action: () => history.push('/campaign/custom-row/new-footer')
            },
            {
                title: 'Header Row',
                action: () => history.push('/campaign/custom-row/new-header')
            },
            {
                title: 'Signature Row',
                action: () => history.push('/campaign/custom-row/new-signature')
            },
            {
                title: 'Webinar Block',
                action: () => history.push('/campaign/custom-row/new-webinar_block')
            },
            {
                title: 'Landing Page',
                action: () => history.push('/campaign/custom-row/new-landing_page')
            },
            {
                title: 'Disclosure Page',
                action: () => history.push('/campaign/custom-row/new-disclosure')
            }
        ];
        
        return(
            <div style={{position: 'relative'}}>
                <PurpleButton
                    width={"150px"}
                    onClick={()=>{
                        setShowFilterDropdown(false)
                        setShowCreateDropdown(!showCreateDropdown)
                    }}>
                    <ButtonIcons margin={'-3px 6px 0 0'} src={addIcon} alt='new' />
                    <span style={{paddingRight: 5}}>Create New</span>
                    <ButtonIcons selected={showCreateDropdown} margin={'-3px 0px 0 6px'} src={downArrow} alt='hover' />
                </PurpleButton>
                { showCreateDropdown && <DropdownItems>
                    {
                        dropdownItems.map((row, index) => {
                            return(
                                <DropdownRow key={index} onClick={()=>{
                                    row.action(row.key)
                                    setShowCreateDropdown(false)
                                }}>
                                    <div>{row.title}</div>
                                </DropdownRow>
                            )
                        })
                    }
                </DropdownItems>}
            </div>
        )
    };

    const layoutOptions = () => {
        return(
            <div style={{width: 400}}>
                {
                    layoutDisplayOptions.map((row, index) => {
                        return(
                            <LayoutCard key={index} onClick={()=>history.push(`/campaign/custom-row/new-article_block?layout=${row.key}`)}>
                                <LayoutImg src={row.icon} alt='icon' />
                                <LayoutText>{row.title}</LayoutText>
                            </LayoutCard>
                        )
                    })
                }
            </div>
        )
    }

    const searchDisplay = () => {
        return(
            <SearchContainer searchActive={openSearch}>
                <SearchBar 
                    setActiveSearch={setOpenSearch} 
                    activeSearch={openSearch} 
                    searchString={searchString} 
                    setSearchString={setSearchString} 
                    purpleIcon
                />
            </SearchContainer>
        )
    };

    return(
        <div style={{width: '100%', margin: '20px 0px 0px'}}>
            <FlexContainer width={'calc(100% - 30px)'}>
                <ToggleBar 
                    toggleItems={toggleItems} 
                    activeToggle={selectedToggle} 
                    toggleClick={handleToggle} 
                />
                {
                    actionDisplay()
                }
            </FlexContainer>
            <div style={{width: '100%'}}>
            {
                pageDisplay()
            }
            </div>
           <ModalContainer
                modalStatus={expandModalOpen}
                closeModal={()=>setExpandModalOpen(!expandModalOpen)}
                title={'Select Layout'}
                children={layoutOptions()}
           />
        </div>
    )
};

const mapStateToProps = state => {
    const isEnterpriseLevelAdmin = get(state, 'user.data.details.company.enterprise', "false") === "true";
    const isFirmLevelAdmin = get(state, 'user.data.details.company.firm', "false") === "true";
    const isAdmin = getUserRole(state) === USER_ROLE.admin;
    return {
        isEnterpriseLevelAdmin,
        isFirmLevelAdmin,
        firmsArray: get(state, 'user.data.details.company.firms', []),
        isAdmin
    }
}

export default connect(mapStateToProps, null)(TemplateContainer);