import { get, isEmpty, map, pick } from 'lodash';
import {
  takeEvery,
  all,
  call,
  put,
  select,
  takeLatest,
} from 'redux-saga/effects';
import {
  actions as analyticsActions,
  moreActions as analyticsMoreActions,
} from '../modules/analytics.module';
import {
  getAnalyticDataByRepType,
  getLeadReferalData,
} from '../../services/analytics';
import {
  getChannelId,
  getAnalyticsDates,
  getRepType,
  getUserRole,
  getUserId,
  getSelectedContactList,
  getSelectedEmail,
  getAfterToken,
  getLeadType,
  getFilterParams
} from '../selector';

function* analyticsRequest({ payload }, meta) {
  try {
    const inputParams = get(payload, 'params', {});
    const useRole = yield select(getUserRole);
    const channelId = yield select(getChannelId);
    console.log(channelId);
    const analyticsDate =
      get(inputParams, 'selectedDate', '') &&
      get(inputParams, 'selectedDate', '').length > 0
        ? pick(get(payload, 'params', {}), ['startDate', 'endDate'])
        : yield select(getAnalyticsDates);
    const uid = yield select(getUserId);
    const afterToken = yield select(getAfterToken);
    
    const filterParams = yield select(getFilterParams)

    console.log(inputParams);
    const params = {
      ...filterParams,
      channelId,
      ...analyticsDate,
      ...inputParams,
      noChannelId: useRole !== 'admin',
      uid,
      afterToken,
    };
    const { repType, email_id } = inputParams;
    let data, emailsData;
    const response = yield call(getAnalyticDataByRepType, params);
    data = get(response, 'result.data', {});
    emailsData = map(get(data, 'leads', []), 'email');
    if (repType === 'email_share_by_leads' && !email_id) {
      const leadRefResponse = yield call(getLeadReferalData, params);
      const leadRefData = get(leadRefResponse, 'result.data', {});
      let filterLeadRefData, filterRefEmData, filterLeadEmData;
      if (!isEmpty(get(leadRefData, 'leads_referrals', []))) {
        filterLeadRefData = get(leadRefData, 'leads_referrals', []).filter(
          (l) => l.lead_source !== 'em'
        );
        filterLeadEmData = get(leadRefData, 'leads_referrals', []).filter(
          (l) =>
            l.lead_source === 'em' &&
            !emailsData.includes(l.email) &&
            l.lead_type === 'leadgen'
        );
        filterRefEmData = get(leadRefData, 'leads_referrals', []).filter(
          (l) => l.lead_source === 'em' && l.lead_type === 'referral'
        );
        data.leads = [
          ...data.leads,
          ...filterLeadRefData,
          ...filterRefEmData,
          ...filterLeadEmData,
        ];
        data.pagination.total_count =
          +data.pagination.total_count +
          +filterLeadRefData.length +
          +filterRefEmData.length +
          +filterLeadEmData.length;
        yield put(
          analyticsActions.api.analytics.response({ data, repType, params })
        );
      } else {
        yield put(
          analyticsActions.api.analytics.response({ data, repType, params })
        );
      }
    } else {
      yield put(
        analyticsActions.api.analytics.response({ data, repType, params })
      );
    }
  } catch (error) {
    yield put(analyticsActions.api.analytics.error(error));
  }
}

function* analyticsDateChange({ payload }) {
  const repType = yield select(getRepType);
  const contactList = yield select(getSelectedContactList);
  const selectedEmail = yield select(getSelectedEmail);
  const leadType = yield select(getLeadType);
  if (
    !isEmpty(contactList) &&
    (isEmpty(selectedEmail) || isEmpty(payload.selectedEmail))
  ) {
    yield put(
      analyticsActions.api.analytics.request({
        params: {
          repType,
          contact_list: contactList,
        },
      })
    );
  } else if (!isEmpty(selectedEmail)) {
    yield put(
      analyticsActions.api.analytics.request({
        params: {
          repType,
          email_id: selectedEmail,
        },
      })
    );
  } else if (repType === 'leadgen_data' || repType === 'leadgen_data_member') {
    yield put(
      analyticsActions.api.analytics.request({
        params: {
          repType,
          leadType,
        },
      })
    );
  } else {
    yield put(
      analyticsActions.api.analytics.request({
        params: {
          repType,
        },
      })
    );
  }
}

function* analyticsSaga() {
  const repType = yield select(getRepType);

  if (
    [
      'email_share_by_leads',
      'email_share_by_topics',
      'email_share_overview',
    ].includes(get(repType, 'repType', ''))
  ) {
    yield all([
      takeLatest(analyticsActions.api.analytics.request, analyticsRequest),
      takeLatest(
        analyticsMoreActions.custom.analytics.selectDate,
        analyticsDateChange
      ),
    ]);
  } else {
    yield all([
      takeEvery(analyticsActions.api.analytics.request, analyticsRequest),
      takeEvery(
        analyticsMoreActions.custom.analytics.selectDate,
        analyticsDateChange
      ),
    ]);
  }
}

export default analyticsSaga;
