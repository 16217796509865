import React from 'react';
import TopicsSearch from 'src/componentsV2/complex/TopicsSearch';
import Button from 'src/componentsV2/Button';
import { Loader } from 'src/components';
import Star from 'src/assets/icons/Star';
import {
  Checkbox,
  CheckboxWrapper,
  Content,
  Header,
  Heading,
  Section,
  SectionContent,
  Separator,
  TopicsContainer,
  Wrapper,
} from './styles';
import useQueryForTopics from '../../hooks/useQueryForTopics';

function Sidebar({
  register,
  section = 'any',
  selectedTopics,
  setSelectedTopics,
  toggleEditorModal = () => {},
}) {
  const { isLoading: topicsLoader, data: topics } = useQueryForTopics();

  return (
    <Wrapper>
      {section === 'branded_content' && (
        <Header>
          <Star />
          <Heading>
            <h4>Branded Content</h4>
            <p>
              White-labeled content created by our inhouse writing team provides
              you with evergreen articles that look and feel as if you wrote
              them.
            </p>
            <Button onClick={() => toggleEditorModal()}>
              Personalize Content
            </Button>
          </Heading>
        </Header>
      )}

      <Content>
        {section === 'search' && (
          <>
            <Section>
              <h4>Filters</h4>
              <SectionContent>
                <CheckboxWrapper>
                  <Checkbox
                    type="checkbox"
                    value={'bookmark'}
                    {...register('filter')}
                  />
                  <label>Show Bookmarks</label>
                </CheckboxWrapper>
                <CheckboxWrapper>
                  <Checkbox
                    type="checkbox"
                    value={'my_content'}
                    {...register('filter')}
                  />
                  <label>Show My Content</label>
                </CheckboxWrapper>
              </SectionContent>
            </Section>
            <Separator />
            <Section>
              <h4>Formats</h4>
              <SectionContent>
                <CheckboxWrapper>
                  <Checkbox type="checkbox" value={1} {...register('link')} />
                  <label>Link</label>
                </CheckboxWrapper>
                <CheckboxWrapper>
                  <Checkbox type="checkbox" value={2} {...register('pdf')} />
                  <label>PDF</label>
                </CheckboxWrapper>
                <CheckboxWrapper>
                  <Checkbox type="checkbox" value={3} {...register('image')} />
                  <label>Image</label>
                </CheckboxWrapper>
              </SectionContent>
            </Section>
            <Separator />
          </>
        )}

        <Section>
          <SectionContent>
            <TopicsContainer>
              {topicsLoader ? (
                <Loader />
              ) : (
                <TopicsSearch
                  topics={topics}
                  selectedTopics={selectedTopics}
                  setSelectedTopics={setSelectedTopics}
                  showSelectedTopics={true}
                />
              )}
            </TopicsContainer>
          </SectionContent>
        </Section>
      </Content>
    </Wrapper>
  );
}

export default Sidebar;
