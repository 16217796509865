import React, { useState, useEffect } from 'react';

import { Flex, Box } from '@rebass/grid';
import * as yup from 'yup';
import Papa from 'papaparse';
import {
  PortfolioWrapper,
  PortfolioContainer as PortfolioBox,
  Header,
  HeaderLi,
  HeaderAnchor,
  Blocks,
  BlockContainer,
  SelectionBlock,
  BlockFirstSpan,
  BlockSecondSpan,
  Image,
  DottedInput,
  SeeExampleCSV,
  UploadedFile,
  FileUploadCSV,
  SpecifyFundInput,
  CreateNewLink,
  SpecifyFundHeader,
  ModelMarketplaceHeader,
  SaveButton,
  SavePortfolioInput,
  SelectedListItem,
  NextButtonDiv,
  NextButton,
  TickerContainer,
  TickerDiv,
  TickerTitle,
  TickerTitleSpan,
  TickerListDiv,
  TickerTable,
  TickerThead,
  TickerTr,
  TickerTh,
  TickerTbody,
  TickerTd,
  TickerActions,
  PortfolioCreatedMsg,
  SaveWrapper,
} from './Portfolio.styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faTrash } from '@fortawesome/free-solid-svg-icons';
import { Formik } from 'formik';
import {
  DripIconButton,
  MioDropdown,
  Accordion,
  Button,
} from '../../components';
import { get } from 'lodash';
import { connect } from 'react-redux';
import { ErrorContainer } from '../../components/CommonStyles';

import {
  postPortfolioFund,
  postUserPortfolio,
  postCSVPortolioFund,
  getUserPortfolioFund,
} from '../../services/dashboard';
import { actions as portfolioActions } from '../../redux/modules/portfolio.module';
import { useHistory, Redirect } from 'react-router-dom';
import { ROLE_TYPES } from '../../utils';

const csvPortfolioSchema = yup.object().shape({
  portfolioName: yup.string().required(),
});

const PortfolioContainer = ({ portfolioRequest, accordionData, roleType }) => {
  const history = useHistory();
  const [showAccordion, setShowAccordion] = useState(false);
  const [selectedListItem, setSelectedListItem] = useState(null);
  const [showNextButton, setShowNextButton] = useState(false);
  const [fund, setFund] = useState(null);
  const [csvFile, setCSVFile] = useState('');
  const [csvPortfolioName, setCsvPortfolioName] = useState('');
  const [csvError, setCsvError] = useState(false);
  const [showSavePortfolio, setShowSaveportfolio] = useState(false);
  const [tickerBlock, setTickerBlock] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [newPortfolioCreated, setNewPortfolioCreated] = useState(false);
  const [fundInput, setFundInput] = useState('');
  const [tickerErrorMessage, setTickerErrorMessage] = useState('');
  const [emptyCsvError, setEmptyCsvError] = useState(false);
  const [emptyError, setEmptyError] = useState(false);
  const [goto, setGoto] = useState(false);
  const [isClick, setisClick] = useState(false);
  const [FormatError, setFormatError] = useState(false);
  const [formatCsvError, setformatCsvError] = useState(false);

  useEffect(() => {
    portfolioRequest();
  }, []);

  const showPortfolioCreated = () => {
    setNewPortfolioCreated(true);
    return new Promise((resolve) =>
      setTimeout(() => {
        setNewPortfolioCreated(false);
        resolve();
      }, 2500)
    );
  };

  const handleSpecifyInputChange = (e) => {
    const specifyFundInput = e.target.value.toUpperCase();

    setShowAccordion(false);
    if (specifyFundInput.length >= 3) {
      setCSVFile('');
      setCsvError(false);
      setCsvPortfolioName(' ');
      setShowNextButton(false);
      setSelectedListItem(null);
      setShowSaveportfolio(true);
    } else {
      setShowSaveportfolio(false);
    }

    let [ticker = '', security_name = ''] = specifyFundInput.split(',');
    const reqObj = {
      ticker,
      security_name: security_name.trim(),
    };
    setFund(reqObj);
  };

  const toggleAccordion = () => {
    setShowAccordion(!showAccordion);
  };

  const handleListItemClick = (item) => {
    setSelectedListItem(item);
    setCSVFile('');
    setCsvError(false);
    setCsvPortfolioName('');
    setShowSaveportfolio(false);
    setShowAccordion(false);
    setShowNextButton(true);
  };

  const addFund = async (values, redirect) => {
    const valid = await csvPortfolioSchema.isValid({
      portfolioName: csvPortfolioName,
    });
    if (!valid) {
      setCsvError(true);
    } else {
      setCsvError(false);
      setisClick(true);
      const portfolioObj = {
        name: values.portfolioName,
        description: values.portfolioName,
      };
      const fundObj = {
        funds: [],
      };

      if (fund.ticker && fund.security_name != '') {
        fundObj.funds = [[fund.ticker], [fund.security_name]];
      } else if (fund.ticker && fund.security_name == '') {
        fundObj.funds.push([fund.ticker]);
      }
      const res = await postUserPortfolio(portfolioObj);
      await postPortfolioFund(res.result.data.id, fundObj);
      setShowSaveportfolio(false);
      setisClick(false);
      showPortfolioCreated().then(() => {
        if (redirect === true) {
          if (
            [
              ROLE_TYPES.ADMIN_COMPLIANCE,
              ROLE_TYPES.ADMIN_ENTERPRISE,
              ROLE_TYPES.ADMIN_ASSET_MANAGER,
            ].includes(roleType)
          ) {
            history.push('/admin/discover?tab=holdings');
          } else {
            history.push('/discover?tab=holdings');
          }
        }
      });
      setFundInput('');
    }
  };

  const onCSVUpload = (file) => {
    setCsvError(false);
    setFundInput('');
    if (file === '') {
      setCSVFile(file);
    } else if (file && file.csvUpload) {
      console.log(file.csvUpload[0]);
      setShowNextButton(false);
      setShowSaveportfolio(false);
      setSelectedListItem(null);
      setShowAccordion(false);

      Papa.parse(file.csvUpload[0], {
        header: false,
        skipEmptyLines: true,
        complete: function (results) {
          let columns = results.data.slice(0, 1);
          let body = results.data.slice(1);
          console.log(columns);
          console.log(body.length);
          if (results.data.length === 0) {
            setformatCsvError(false);
            setEmptyCsvError(true);
            setCSVFile('');
          } else if (columns.length === 1) {
            let desiredcol1 = 'ticker';
            let column1 = columns.toString().split(',').slice(0, 1).toString();
            let desiredcol2 = 'shares';
            let column2 = columns.toString().split(',').slice(1, 2).toString();
            if (desiredcol1 == column1 && desiredcol2 == column2) {
              if (body.length === 0 || body.length === 1) {
                setCSVFile('');
                setEmptyCsvError(true);
                setformatCsvError(false);
              } else {
                setEmptyCsvError(false);
                setCSVFile(JSON.stringify(results.data.slice(1)));
                // console.log(JSON.stringify(results.data));

                setformatCsvError(false);
              }
            } else {
              setCSVFile('');
              setEmptyCsvError(false);
              setformatCsvError(true);
            }
          }

          // } else if (results.meta.fields.length ===2) {
          //   let desiredcol1 = 'ticker';
          //   let column1 = results.meta.fields.slice(0, 1).toString();
          //   let desiredcol2 = 'shares';
          //   let column2 = results.meta.fields.slice(1, 2).toString();
          //   if (desiredcol1 == column1 && desiredcol2 == column2) {
          //     setEmptyCsvError(false);
          //     setCSVFile(JSON.stringify(results.data));
          //     setformatCsvError(false);
          //   } else {
          //     setCSVFile('');
          //     setEmptyCsvError(false);
          //     setformatCsvError(true);
          //   }
          // } else {
          else {
            setEmptyCsvError(false);
            setformatCsvError(false);
            setCSVFile(JSON.stringify(results.data.slice(1)));
            console.log(JSON.stringify(results.data));
            console.log('Column names:', results.meta.fields);
            console.log('Errors:', results.errors);
          }
        },
      });
    } else {
      setShowNextButton(false);
      setShowSaveportfolio(false);
      setSelectedListItem(null);
      setShowAccordion(false);

      Papa.parse(file[0], {
        header: false,
        skipEmptyLines: true,
        complete: function (results) {
          let columns = results.data.slice(0, 1);
          let body = results.data.slice(1);
          console.log(columns);
          console.log(body.length);

          if (results.data.length === 0) {
            setformatCsvError(false);
            setEmptyCsvError(true);
            setCSVFile('');
          } else if (columns.length === 1) {
            let desiredcol1 = 'ticker';
            let column1 = columns.toString().split(',').slice(0, 1).toString();

            let desiredcol2 = 'shares';
            let column2 = columns.toString().split(',').slice(1, 2).toString();
            if (desiredcol1 == column1 && desiredcol2 == column2) {
              if (body.length === 0 || body.length === 1) {
                setCSVFile('');
                setEmptyCsvError(true);
                setformatCsvError(false);
              } else {
                setEmptyCsvError(false);
                setCSVFile(JSON.stringify(results.data.slice(1)));
                // console.log(JSON.stringify(results.data));

                setformatCsvError(false);
              }
            } else {
              setCSVFile('');
              setEmptyCsvError(false);
              setformatCsvError(true);
            }
          } else {
            setformatCsvError(false);
            setEmptyCsvError(false);
            setCSVFile(JSON.stringify(results.data.slice(1)));
            console.log(JSON.stringify(results.data));
            console.log('Column names:', results.meta.fields);
            console.log('Errors:', results.errors);
          }
        },
      });
    }
  };

  const createCSVPortfolio = async (redirect) => {
    const valid = await csvPortfolioSchema.isValid({
      portfolioName: csvPortfolioName,
    });
    if (!valid) {
      setCsvError(true);
    } else {
      setCsvError(false);
      setisClick(true);
      if (!emptyCsvError) {
        const portfolioObj = {
          name: csvPortfolioName,
          description: csvPortfolioName,
        };
        const fund = {
          funds: JSON.parse(csvFile),
        };
        const res = await postUserPortfolio(portfolioObj);
        await postCSVPortolioFund(res.result.data.id, fund);
        setCSVFile('');
        setCsvError(false);
        setisClick(false);
        setCsvPortfolioName('');
        showPortfolioCreated().then(() => {
          if (redirect === true) {
            if (
              [
                ROLE_TYPES.ADMIN_COMPLIANCE,
                ROLE_TYPES.ADMIN_ENTERPRISE,
                ROLE_TYPES.ADMIN_ASSET_MANAGER,
              ].includes(roleType)
            ) {
              history.push('/admin/discover?tab=holdings');
            } else {
              history.push('/discover?tab=holdings');
            }
          }
        });
      }
    }
  };
  const handleSaveError = async () => {
    const valid = await csvPortfolioSchema.isValid({
      portfolioName: csvPortfolioName,
    });
    if (!valid) {
      setCsvError(true);
    } else if (valid && emptyCsvError) {
      setCsvError(false);
      setEmptyError(true);
      setTimeout(() => {
        setEmptyError(false);
      }, 2000);
      setCSVFile('');
    } else if (valid && formatCsvError) {
      setFormatError(true);
      setCsvError(false);
      setTimeout(() => {
        setFormatError(false);
      }, 2000);
      setCSVFile('');
    }
  };
  const handleError = async () => {
    const valid = await csvPortfolioSchema.isValid({
      portfolioName: csvPortfolioName,
    });
    if (!valid) {
      setCsvError(true);
    } else if (valid && emptyCsvError) {
      setCsvError(false);
      setEmptyError(true);
      setTimeout(() => {
        setEmptyError(false);
      }, 2000);
      setCSVFile('');
    } else if (valid && formatCsvError) {
      setCsvError(false);
      setFormatError(true);
      setTimeout(() => {
        setFormatError(false);
      }, 2000);
      setCSVFile('');
    }
  };

  const handleNextClick = () => {
    let id = selectedListItem.id;
    getUserPortfolioFund(selectedListItem.id)
      .then((res) => {
        setTableData(res.result.data[`${id}`]);
        setTickerBlock(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleTickerSubmit = async (redirect) => {
    setTickerErrorMessage('');
    setisClick(true);
    const portfolioObj = {
      name: selectedListItem.name,
      description: selectedListItem.name,
    };

    const res = await postUserPortfolio(portfolioObj);
    const data = tableData.map((data) => {
      if (data.security_name != '') {
        data.security_name = data.security_name.replace(/[()]/g, '');
        return [data.ticker, data.security_name];
      } else {
        return [data.ticker];
      }
    });

    const fund = {
      funds: data,
    };
    console.log(fund);
    await postPortfolioFund(res.result.data.id, fund);
    showPortfolioCreated().then(() => {
      setSelectedListItem(null);
      setisClick(false);
      if (redirect === true) {
        if (
          [
            ROLE_TYPES.ADMIN_COMPLIANCE,
            ROLE_TYPES.ADMIN_ENTERPRISE,
            ROLE_TYPES.ADMIN_ASSET_MANAGER,
          ].includes(roleType)
        ) {
          history.push('/admin/discover?tab=holdings');
        } else {
          history.push('/discover?tab=holdings');
        }
      } else {
        setTickerBlock(false);
      }
      setShowNextButton(false);
    });
  };

  const removeTicker = (ticker) => {
    setTickerErrorMessage('');
    if (tableData.length == 1) {
      setTickerErrorMessage('Atleast one fund is required');
      return false;
    }
    let newTableData = tableData.filter((d) => d.ticker !== ticker);
    setTableData(newTableData);
  };

  const content = (
    <>
      {' '}
      <Flex
        width="100%"
        padding="0 25%"
        justifyContent="center"
        mt="60px"
        fontSize="14px"
        style={{ color: '#7dc5d0' }}
      >
        Add your investment portfolios to see content related to the themes and
        underlying stocks.
      </Flex>
      <SelectionBlock screenWidth={window.innerWidth}>
        <Blocks screenWidth={window.innerWidth}>
          <Flex justifyContent="center" width={1}>
            <BlockFirstSpan>
              <Image
                src="assets/images/portfolio-upload.svg"
                alt="Portfolio upload"
              />
            </BlockFirstSpan>
            <BlockSecondSpan>
              <Formik
                initialValues={{ CSVUploaded: '' }}
                onSubmit={onCSVUpload}
                //   validationSchema={loginShema}
              >
                {({
                  handleSubmit,
                  handleChange,
                  setFieldValue,
                  values,
                  errors = {},
                }) => {
                  const fileName = get(values, 'csvUpload[0].name', '');
                  const onFileRemoveClick = (fileName) => {
                    setFieldValue('csvUpload', '');
                  };
                  return (
                    <>
                      <form name="uploadcsvform" onSubmit={handleSubmit}>
                        <Flex width={1} justifyContent="center">
                          <Box width={1}>
                            <FileUploadCSV
                              asLabel
                              name="csvUpload"
                              color="#6351ed"
                              id="csv-portfolio-Upload"
                              onClick={handleSubmit}
                              label="Upload CSV File"
                              onChange={(file) => {
                                setFieldValue('csvUpload', file);
                                onCSVUpload(file);
                              }}
                              accept=".csv"
                            />
                            <DottedInput
                              name="scv-input"
                              type="text"
                              value={csvPortfolioName}
                              onChange={(e) =>
                                setCsvPortfolioName(e.target.value)
                              }
                              placeholder="Enter Portfolio name"
                            />
                            <SeeExampleCSV
                              href="assets/sample/sample.csv"
                              download="sample.csv"
                            >
                              See Example
                            </SeeExampleCSV>
                          </Box>
                        </Flex>
                      </form>
                      {formatCsvError && (
                        <UploadedFile>
                          <Flex width={1} alignItems="center">
                            <Box
                              style={{
                                fontFamily: 'Poppins',
                                color: '#6351ed',
                              }}
                            >
                              File Name : {fileName}
                            </Box>
                            <Box>
                              <DripIconButton
                                icon="cross"
                                style={{ color: '#6351ed' }}
                                onClick={() => {
                                  onFileRemoveClick(fileName);
                                  setformatCsvError(false);
                                  onCSVUpload('');
                                }}
                              />
                            </Box>
                          </Flex>
                        </UploadedFile>
                      )}
                      {emptyCsvError && (
                        <UploadedFile>
                          <Flex width={1} alignItems="center">
                            <Box
                              style={{
                                fontFamily: 'Poppins',
                                color: '#6351ed',
                              }}
                            >
                              File Name : {fileName}
                            </Box>
                            <Box>
                              <DripIconButton
                                icon="cross"
                                onClick={() => {
                                  onFileRemoveClick(fileName);
                                  setEmptyCsvError(false);
                                  onCSVUpload('');
                                }}
                              />
                            </Box>
                          </Flex>
                        </UploadedFile>
                      )}
                      {csvFile && (
                        <UploadedFile>
                          <Flex width={1} alignItems="center">
                            <Box
                              style={{
                                fontFamily: 'Poppins',
                                color: '#6351ed',
                              }}
                            >
                              File Name : {fileName}
                            </Box>
                            <Box>
                              <DripIconButton
                                icon="cross"
                                onClick={() => {
                                  onFileRemoveClick(fileName);
                                  onCSVUpload('');
                                }}
                              />
                            </Box>
                          </Flex>
                        </UploadedFile>
                      )}
                    </>
                  );
                }}
              </Formik>
            </BlockSecondSpan>
          </Flex>
        </Blocks>
        <Blocks
          screenWidth={window.innerWidth}
          onMouseLeave={() => setShowAccordion(false)}
        >
          <Flex
            justifyContent="center"
            width={1}
            onMouseLeave={() => setShowAccordion(false)}
          >
            <BlockFirstSpan>
              <Image
                src="assets/images/model-marketplace.svg"
                alt="model-marketplace"
              />
            </BlockFirstSpan>
            <BlockSecondSpan>
              <Flex
                width={1}
                justifyContent="space-between"
                onClick={toggleAccordion}
              >
                <ModelMarketplaceHeader>
                  <span>Model MarketPlace</span>
                  <FontAwesomeIcon
                    icon={faCaretDown}
                    onMouseEnter={() => setShowAccordion(true)}
                    color="#c95cfc"
                    style={{
                      position: 'relative',
                      top: '4px',
                    }}
                  />
                </ModelMarketplaceHeader>
              </Flex>
              {showAccordion ? (
                <Accordion
                  list={accordionData}
                  handleListItemClick={handleListItemClick}
                />
              ) : null}
              {selectedListItem && (
                <SelectedListItem title={selectedListItem.name}>
                  {selectedListItem.name}
                </SelectedListItem>
              )}
            </BlockSecondSpan>
          </Flex>
        </Blocks>
        <Blocks
          screenWidth={window.innerWidth}
          onClick={() => setShowAccordion(false)}
        >
          <Flex justifyContent="center" width={1}>
            <BlockFirstSpan>
              <Image src="assets/images/specify-fund.svg" alt="specify fund" />
            </BlockFirstSpan>
            <BlockSecondSpan>
              <Formik initialValues={{ specifyFundInput: '' }}>
                {({ handleChange = {}, values }) => {
                  return (
                    <form name="specifyfund">
                      <Flex
                        width={1}
                        flexDirection="column"
                        alignItems="flex-start"
                      >
                        <SpecifyFundHeader>Specify a Fund</SpecifyFundHeader>
                        <SpecifyFundInput
                          type="text"
                          id="specifyFundInput"
                          name="specifyFundInput"
                          value={fundInput}
                          placeholder="Enter here"
                          onChange={(e) => {
                            setFundInput(e.target.value);
                            handleSpecifyInputChange(e);
                          }}
                        />
                      </Flex>
                    </form>
                  );
                }}
              </Formik>
            </BlockSecondSpan>
          </Flex>
        </Blocks>
      </SelectionBlock>
      {showSavePortfolio ? (
        <>
          <Formik
            initialValues={{ portfolioName: '' }}
            onSubmit={addFund}
            validationSchema={csvPortfolioSchema}
          >
            {({ handleSubmit, handleChange, values }) => {
              return (
                <form name="createNewPortfolio">
                  <Flex
                    width={0.2}
                    flexDirection="column"
                    alignItems="center"
                    m="auto"
                  >
                    <SavePortfolioInput
                      value={values.portfolioName}
                      onChange={handleChange}
                      type="text"
                      name="portfolioName"
                      id="portfolioName"
                      placeholder="Enter Portfolio name"
                    />
                    <SaveWrapper cursor={isClick}>
                      <Button
                        disabled={isClick}
                        type="button"
                        onClick={() => addFund(values, true)}
                      >
                        Save
                      </Button>
                    </SaveWrapper>
                    <CreateNewLink onClick={handleSubmit}>
                      {' '}
                      (Save and Create New)
                    </CreateNewLink>
                  </Flex>
                </form>
              );
            }}
          </Formik>
        </>
      ) : null}
      {emptyCsvError ? (
        <Formik
          onSubmit={createCSVPortfolio}
          //   validationSchema={loginShema}
        >
          {({ handleSubmit }) => {
            return (
              <form name="createNewPortfolio">
                <Flex
                  width={0.2}
                  flexDirection="column"
                  alignItems="center"
                  m="auto"
                >
                  <SaveButton type="button" onClick={handleSaveError}>
                    Save
                  </SaveButton>
                  <CreateNewLink onClick={handleError}>
                    {' '}
                    (Save and Create New)
                  </CreateNewLink>
                </Flex>
              </form>
            );
          }}
        </Formik>
      ) : null}
      {formatCsvError ? (
        <Formik
          onSubmit={createCSVPortfolio}
          //   validationSchema={loginShema}
        >
          {({ handleSubmit }) => {
            return (
              <form name="createNewPortfolio">
                <Flex
                  width={0.2}
                  flexDirection="column"
                  alignItems="center"
                  m="auto"
                >
                  <SaveButton type="button" onClick={handleSaveError}>
                    Save
                  </SaveButton>
                  <CreateNewLink onClick={handleError}>
                    {' '}
                    (Save and Create New)
                  </CreateNewLink>
                </Flex>
              </form>
            );
          }}
        </Formik>
      ) : null}
      {goto ? <Redirect to="/discover"></Redirect> : null}
      {emptyError ? <div>No data found in CSV</div> : null}
      {FormatError ? (
        <div>Please provide csv as per the sample file</div>
      ) : null}
      {csvFile !== '' ? (
        <Formik
          onSubmit={createCSVPortfolio}
          //   validationSchema={loginShema}
        >
          {({ handleSubmit }) => {
            return (
              <form name="createNewPortfolio">
                <Flex
                  width={0.2}
                  flexDirection="column"
                  alignItems="center"
                  m="auto"
                >
                  <SaveWrapper cursor={isClick}>
                    <SaveButton
                      cursor={isClick}
                      disabled={isClick}
                      type="button"
                      onClick={() => createCSVPortfolio(true)}
                    >
                      Save
                    </SaveButton>
                  </SaveWrapper>
                  <CreateNewLink onClick={createCSVPortfolio}>
                    {' '}
                    (Save and Create New)
                  </CreateNewLink>
                </Flex>
              </form>
            );
          }}
        </Formik>
      ) : null}
      {showNextButton && (
        <NextButtonDiv>
          <Button
            onClick={() => {
              handleNextClick();
              // setTickerBlock(true)
            }}
          >
            Next
          </Button>
        </NextButtonDiv>
      )}
      {newPortfolioCreated ? (
        <PortfolioCreatedMsg>
          Your Portfolio has been added succesfully
        </PortfolioCreatedMsg>
      ) : null}
      {csvError ? <div>Please provide a portfolio name</div> : null}
    </>
  );

  const tickerContent = (
    <>
      <TickerContainer>
        <TickerDiv>
          <TickerTitle>
            {selectedListItem && (
              <TickerTitleSpan>{selectedListItem.name}</TickerTitleSpan>
            )}
          </TickerTitle>
          <TickerListDiv>
            <TickerTable>
              <TickerThead>
                <TickerTr>
                  <TickerTh>Ticker</TickerTh>
                  <TickerTh>Name</TickerTh>
                  <TickerTh></TickerTh>
                </TickerTr>
              </TickerThead>
              <TickerTbody>
                {tableData.map((fund, index) => (
                  <TickerTr>
                    <TickerTd>{fund.ticker}</TickerTd>
                    <TickerTd>{fund.security_name}</TickerTd>
                    <TickerTd onClick={() => removeTicker(fund.ticker)}>
                      <FontAwesomeIcon icon={faTrash} size="1x" />
                    </TickerTd>
                  </TickerTr>
                ))}
              </TickerTbody>
            </TickerTable>
          </TickerListDiv>
        </TickerDiv>
        {tickerErrorMessage && (
          <ErrorContainer align="center">{tickerErrorMessage}</ErrorContainer>
        )}
        <TickerActions>
          <Formik
            onSubmit={handleTickerSubmit}
            //   validationSchema={loginShema}
          >
            {({ handleSubmit, handleChange, values }) => {
              return (
                <form name="createNewPortfolio">
                  <Flex
                    width={0.2}
                    flexDirection="column"
                    alignItems="center"
                    m="auto"
                  >
                    <SaveWrapper cursor={isClick}>
                      <SaveButton
                        disabled={isClick}
                        type="button"
                        onClick={() => handleTickerSubmit(true)}
                      >
                        Save
                      </SaveButton>
                    </SaveWrapper>
                    <CreateNewLink onClick={handleTickerSubmit}>
                      {' '}
                      (Save and Create New)
                    </CreateNewLink>
                  </Flex>
                </form>
              );
            }}
          </Formik>
        </TickerActions>
        {newPortfolioCreated ? (
          <PortfolioCreatedMsg>
            Your Portfolio has been added succesfully
          </PortfolioCreatedMsg>
        ) : null}
      </TickerContainer>
    </>
  );

  return (
    <PortfolioWrapper>
      <PortfolioBox screenWidth={window.innerWidth}>
        <Header>
          <HeaderLi>
            <HeaderAnchor
              style={{ fontWeight: 'normal' }}
              data-testid="add-holding-page"
            >
              Curate Insights For My Holdings
            </HeaderAnchor>
          </HeaderLi>
        </Header>
        <BlockContainer
          onClick={(e) => {
            // console.log('CLICK LOCATION - ', screen.height);
          }}
          screenWidth={window.innerWidth}
        >
          {tickerBlock ? tickerContent : content}
        </BlockContainer>
      </PortfolioBox>
    </PortfolioWrapper>
  );
};

const mapStateToProps = (state) => ({
  accordionData: state.portfolio.data,
});

const mapDispatchToProps = {
  portfolioRequest: portfolioActions.api.portfolio.request,
};

export default connect(mapStateToProps, mapDispatchToProps)(PortfolioContainer);
