import { Flex } from '@rebass/grid';
import { get } from 'lodash-es';
import React, { useState, useEffect } from 'react';
import {
  Button,
  ErrorContainer,
  FileUpload,
  LoaderWrapper,
  Modal,
} from '../../../components';
import ShareContentContainer from '../../../components/ShareContentContainerV2';
import { themesData, toBase64 } from '../../../utils';
import { FeedInput, FeedWrapper, InputWrapper } from './AddContent.styles';
import ImagePreviewTab from './ImagePreviewTab';
import UploadSuccess from './UploadSuccess';

const ImageOnlyUpload = ({
  showThemes,
  setShowThemes,
  showShare,
  setShowShare,
  showImagePreview,
  setShowImagePreview,
  postCreateContent,
  postUpdateContent,
  channelId,
  themesList,
  showContentOverview,
  setShowContentOverview,
  isAdmin,
  setCuratedTopicType,
  setWdigetTabIndex,
  contentlibRequest,
  setSelectedExploreTabIndex,
  setTabIndex,
  onYesClick,
  setIsShareContent,
  isEnterpriseAdvisor,
  setIsComplete,
  reset,
  updateReset,
}) => {
  const [imgUploaded, setImgUploaded] = useState('');
  const [postTitle, setPostTitle] = useState('');
  const [error, setError] = useState(null);
  const [content_id, setContent_id] = useState('');
  const [secondaryId, setSecondaryId] = useState('');
  const [loader, toggleLoader] = useState(false);
  const [topicSubmitLoader, toggleTopicSubmitLoader] = useState(false);
  const [showContent, setShowContent] = useState(false);
  const [themeMessage, setThemeMessage] = useState({});
  const [userSummary, setUserSummary] = useState('');
  const [fetchedThemes, setFetchedThemes] = useState([]);

  const styles = {
    Button: {
      color: themesData.white,
      background: themesData.purpleishBlue,
      borderRadius: '0px',
      fontSize: '11px',
      fontFamily: 'Poppins',
      border: 'none',
    },
  };

  useEffect(() => {
    if (reset) {
      setImgUploaded('');
      setPostTitle('');
      setError('');
    }
  }, reset);

  const uploadHandler = (file) => {
    console.log('IMAGE FILE - - - - ', file);
    const fileSize = file[0].size / 1024 / 1024;
    if (fileSize > 10)
      return setError('File size should be less than or equal to 10 MB');

    if (showImagePreview) {
      setError('');
      return setImgUploaded(file);
    }
    const fileNameString =
      file && file[0] && file[0].name ? file[0].name.split('.') : null;
    const fileType = fileNameString[fileNameString.length - 1];
    const acceptedTypes = ['png', 'jpeg', 'jpg', 'svg', 'bmp'];
    toBase64(file[0]).then((file) => {
      // console.log('[FILE] - ', file, 'PATH = ', file.path);
      console.log('[FILE] - ', file);
      if (fileType && acceptedTypes.includes(fileType)) {
        if (!postTitle) setError('Please enter a title');
        else setError('');
        setImgUploaded(file);
      } else {
        setImgUploaded('');
        setError('Error uploading file. Please upload a valid file...');
      }
    });
    console.log('FILE UPLOADED - - - - - ', file);
    // console.log('FILE TYPE= = = =', fileType);
  };

  const nextClickHandler = () => {
    toggleLoader(true);
    postCreateContent({
      channelId,
      source: 'img',
      title: postTitle,
      skip_compliance: true,
      message: userSummary || get(themeMessage, 'message', ''),
    })
      .then((res) => {
        console.log('POST CREATE RESPONSE - - --  ', res);
        if (
          res.result.success &&
          res.result.data &&
          res.result.data.content_id
        ) {
          setContent_id(res.result.data.content_id);
          setSecondaryId(res.result.data.id);
          setShowImagePreview(true);
          setShowThemes(true);
          toggleLoader(false);
          updateReset(false);
        }
      })
      .catch((err) => {
        setError('Oops... somenthing went wrong');
        toggleLoader(false);
        console.log('ERROR - - -- - ', err);
      });
    // setShowImagePreview(true);
    // setShowThemes(true);
  };

  const onNextClickImagePreview = () => {
    postUpdateContent({
      contentId: content_id,
      media1: imgUploaded,
      message: userSummary,
    })
      .then((res) => {
        setShowImagePreview(false);
        setShowThemes(true);
      })
      .catch((err) => setError('Oops... somenthing went wrong'));
    // setShowImagePreview(false);
    // setShowThemes(true);
  };

  const onUpdateTheme = (themes, type = '', optionType = '') => {
    console.log('THEMES = - - - ', themes);
    const contentId = content_id;
    toggleTopicSubmitLoader(true);
    const reqObj = {
      contentId,
      themes,
      message: userSummary || get(themeMessage, 'message', ''),
      title: postTitle,
      // user_summary: userSummary,
      media1: imgUploaded,
      skip_compliance: true,
    };
    if (type === 'Content Overview') {
      reqObj.unpublished = true;
    }
    postUpdateContent(reqObj)
      .then((res) => {
        toggleTopicSubmitLoader(false);
        // if(type!=='next')
        //   setUserSummary('');
        if (type === 'Content Overview') handleContentOverviewClick(optionType);
        else setShowContentOverview(true);
      })
      .catch((err) => {
        console.log('ERR - - - -- ', err);
        setError('Oops... somenthing went wrong');
        toggleTopicSubmitLoader(false);
      });
    if (
      type !== 'Content Overview' &&
      type !== 'Internal Use' &&
      type !== 'Share Internal Issue'
    ) {
      setFetchedThemes(themes.add);
    }
  };

  const handleContentOverviewClick = (type) => {
    switch (type) {
      case 'shareNow':
        setShowThemes(false);
        setShowShare(true);
        setIsShareContent(true);
        return;
      case 'viewContent':
        if (isEnterpriseAdvisor) {
          setCuratedTopicType('My Added Content');
        } else {
          contentlibRequest({ type: 'all' });
        }
        setTabIndex(0);
        setWdigetTabIndex(0);
        setSelectedExploreTabIndex(2);
        return;
      case 'addContent':
        resetImageUpload();
        // setShowContent(false);
        // setImgUploaded('');
        // setPostTitle('');
        // setContent_id('');
        // setSecondaryId('');
        // setFetchedThemes([]);
        // onYesClick();
        return;
      default:
        return;
    }
  };

  const onShareSuccess = () => {
    setIsShareContent(false);
    setShowThemes(false);
    setShowShare(false);
    setImgUploaded('');
    setPostTitle('');
    setIsComplete(true);
    setShowImagePreview(false);
    setShowContentOverview(false);
    setFetchedThemes([]);
  };

  const resetImageUpload = () => {
    setShowContent(false);
    setImgUploaded('');
    setPostTitle('');
    setContent_id('');
    setSecondaryId('');
    setFetchedThemes([]);
    onYesClick();
  };

  console.log('FETCHED THEMES - - - - ', fetchedThemes);
  return (
    <FeedWrapper>
      {!showThemes && !showShare && (
        <>
          <InputWrapper>
            <FeedInput
              id="imgTitle"
              name="imgTitle"
              type="text"
              onChange={(e) => {
                setPostTitle(e.target.value);
                if (e.target.value) setError('');
                else setError('Please enter a title');
              }}
              placeholder="Please enter a title for your reference"
              data-testid="ac-image-title"
            />
          </InputWrapper>
          <ErrorContainer align="center">{}</ErrorContainer>
          <div>
            <Flex
              width={1}
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
            >
              <FileUpload
                id="imgUpload"
                name="imgUpload"
                type="button"
                onClick={() => {}}
                onChange={(file) => uploadHandler(file)}
                onError={setError}
                label="Upload"
                accept=".png,.jpeg,.jpg,.svg,.bmp"
                color={themesData.white}
                background={themesData.purpleishBlue}
                borderRadius="0px"
                border="none"
                padding="7px 38px"
                fontSize="11px"
              />
              <ErrorContainer align="center">
                {!error && imgUploaded ? '✓ ***.img' : error}
              </ErrorContainer>
            </Flex>
            <div>
              <LoaderWrapper isLoading={loader}>
                <Button
                  type="button"
                  margin="5px 0px 0px 0px"
                  onClick={() => nextClickHandler()}
                  disabled={
                    imgUploaded === null ||
                    imgUploaded === '' ||
                    postTitle === '' ||
                    error
                  }
                  width="117px"
                  styles={
                    imgUploaded === null ||
                    imgUploaded === '' ||
                    postTitle === '' ||
                    error
                      ? {
                          ...styles.Button,
                          background: '#b7b7b7',
                          color: 'white',
                        }
                      : styles.Button
                  }
                >
                  Next
                </Button>
              </LoaderWrapper>
            </div>
          </div>
        </>
      )}
      {showImagePreview && !showContentOverview && (
        <>
          {!error ? '✓ Image uploaded successfully' : error}
          <ImagePreviewTab
            isFromImageOnly={true}
            imageUploaded={imgUploaded}
            setImage={uploadHandler}
            nextClick={onNextClickImagePreview}
            setShowContentOverview={setShowContentOverview}
            articleData={{
              id: content_id,
              topic: postTitle,
            }}
            userSummary={userSummary}
            setUserSummary={setUserSummary}
            setError={setError}
            error={error}
          />
        </>
      )}
      {showThemes && !showImagePreview && !error && (
        <UploadSuccess
          isFromImageOnly={true}
          fetchedThemes={fetchedThemes}
          themesList={themesList}
          contentCreateError={error}
          onUpdateTheme={onUpdateTheme}
          isUpdating={topicSubmitLoader}
          setShowContentOverview={setShowContentOverview}
          showContentOverview={showContentOverview}
          imageUploaded={imgUploaded}
          articleData={{
            id: content_id,
            topic: postTitle,
          }}
          isAdmin={isAdmin}
          showContent={showContent}
          setTabIndex={setTabIndex}
          setShowContent={setShowContent}
          resetImageUpload={resetImageUpload}
          userSummary={userSummary}
          setError={setError}
          error={error}
        />
      )}
      {showShare && (
        <Modal
          onRequestClose={() => {
            setShowShare(false);
            setIsShareContent(false);
            updateReset(true);
            setUserSummary('');
            localStorage.removeItem('emailData');
          }}
          shouldCloseOnOverlayClick
          isOpen={showShare}
          size="medium68"
        >
          <ShareContentContainer
            fetchedThemes={fetchedThemes}
            editable
            topic={postTitle}
            submitButtonText="Share"
            id={content_id}
            secondaryId={secondaryId}
            isFromAddContent
            handleClose={() => onShareSuccess()}
            pdfImage={imgUploaded}
            source="img"
            isFromImageOnly={true}
            asModal
            description={userSummary}
          />
        </Modal>
      )}
    </FeedWrapper>
  );
};

export default ImageOnlyUpload;
