import { styled } from '@mui/material';

export const Wrapper = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '2rem',
  padding: '2rem 1rem',
}));

export const Header = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '0.38rem',

  h3: {
    fontSize: '1.75rem',
    fontWeight: '500',
    color: '#101828',
    margin: 0,
  },
}));

export const BackAction = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '0.5rem',
  cursor: 'pointer',

  svg: {
    color: '#475467',
    fontSize: '1.5rem',
  },
}));

