import { Flex } from '@rebass/grid';
import styled from 'styled-components';


export const RadioInput = styled.input`
  border: 2px solid white;
  box-shadow: 0 0 0 1px #aaa;
  appearance: none;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  background-color: #fff;
  transition: all ease-in 0.2s;

  &:checked {
    background-color: #6352EC;
    box-shadow: 0 0 0 1px #aaa;
  }
`;



