import { get, isEmpty } from 'lodash';
import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import { DashboardArticle, FreeArticle, Loader } from '../../../components';

import { getExploreArticleData, dashboardSliderSettings, eventHandlers } from '../../../utils';

import { ArticleSliderWrapper } from '../DashboardContainer.styles';

const MyClientsTab = ({
  selectedTopic,
  exploreArticles,
  deleteArticleRequest,
  themeNames,
  exploreArticleDeleting,
  isFreeUser,
  isShareBlocked,
  isDeleteBlocked,
  showMio,
  showTabLoader,
  setShowArticleLoader,
  popularThemes,
  userData,
  exploreArticleRequest,
  onNewsletterArticleClick,
  onRemoveFromNewletterClick,
  newsletterArticleFetching,
  bookmarksFetching,
  exploreNewsletterId,
  bookmarksId,
  selectedDropdownOption,
  selectedCRMContactList,
  setMyClientsLocalArticles,
  toggleCrmLoader,
  clientDetails
}) => {
  console.log('SHARE BLOCKED -  - - - - ', isShareBlocked);
  const [imageCounter, setImageCounter] = useState([]);
  const [localExploreArticles, setLocalExploreArticles] = useState([]);
  const [totalArticles, setTotalArticles] = useState(0);
  const fromMyClients = true;
  // console.log('articles ...... - ', localExploreArticles);

  dashboardSliderSettings.afterChange = (index) => {
    let articles = getExploreArticleData(exploreArticles).slice(index, index + 4);
    let sponsoredArticle = articles.filter(a => a.sponseredContent);
    const userEmail = userData && userData.details && userData.details.user && userData.details.user.email;
    if (!isEmpty(sponsoredArticle)) {
      sponsoredArticle.forEach((article) => {
        eventHandlers(
          'SPONSORED_ARTICLE_DISPLAYED',
          popularThemes,
          article.id,
          'MY_CLIENTS',
          article.channelId,
          article.title,
          article.themeId,
          themeNames[article.themeId],
          article.topic,
          userEmail
        );
      });
    }
    if (!isEmpty(articles)) {
      articles.forEach((article) => {
        eventHandlers(
          'ARTICLE_DISPLAYED',
          popularThemes,
          article.id,
          'MY_CLIENTS',
          article.channelId,
          article.title,
          article.themeId,
          themeNames[article.themeId],
          article.topic,
          userEmail
        );
      });
    }
  }

  // useEffect(() => {
  //   if(selectedDropdownOption === 'financial') {
  //     setShowArticleLoader(true);
  //     toggleCrmLoader(false);
  //     exploreArticleRequest({
  //       params: {
  //         id: 'SECURITY',
  //         source: 'myClients',
  //       },
  //     });
  //   }
  //   else if(selectedDropdownOption === 'crm') {
  //     setShowArticleLoader(true);
  //     toggleCrmLoader(false);
  //     exploreArticleRequest({
  //       params: {
  //           source: 'crmClients',
  //           themesList: selectedCRMContactList.themes.length > 0 ? selectedCRMContactList.themes.map(theme => theme.id_hash) : []
  //       }
  //   });
  //   }
  // }, []);

  useEffect(() => {
    setShowArticleLoader(false);
    if(isEmpty(get(exploreArticles, 'feed', []))) {
      setShowArticleLoader(false);
    }
    let articles = getExploreArticleData(exploreArticles);
    
    if(selectedDropdownOption === 'crm' && selectedCRMContactList) {
      let disabledThemes = selectedCRMContactList.disabledThemes;
      articles = articles.filter(article => !disabledThemes.includes(article.id));
    }
    console.log('articles - ', articles, 'SELECTED CRM CONTACT LIST - ', selectedCRMContactList);
    setLocalExploreArticles(articles);
    setTotalArticles(articles.length);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exploreArticles]);

  useEffect(() => {
    let articles = getExploreArticleData(exploreArticles);
    if(selectedDropdownOption === 'crm') {
      let disabledThemes = selectedCRMContactList.disabledThemes;
      if(articles.length>0)
        articles = articles.filter(article => !disabledThemes.includes(article.themeId));
      // console.log('DISABLED THEMES.....', disabledThemes, 'ARTICLES - ', articles);
      setLocalExploreArticles(articles);
      setMyClientsLocalArticles(articles);
    }
  }, [selectedCRMContactList]);

  useEffect(() => {
    if(!isEmpty(get(exploreArticles, 'feed', []))) {
      let articles = getExploreArticleData(exploreArticles).slice(0, 4);
      const userEmail = userData && userData.details && userData.details.user && userData.details.user.email;
      let sponsoredArticle = articles.filter(a => a.sponseredContent);
      if (!isEmpty(sponsoredArticle)) {
        sponsoredArticle.forEach((article) => {
          eventHandlers(
            'SPONSORED_ARTICLE_DISPLAYED',
            popularThemes,
            article.id,
            'MY_CLIENTS',
            article.channelId,
            article.title,
            article.themeId,
            themeNames[article.themeId],
            article.topic,
            userEmail
          );
        });
      }
      if(!isEmpty(articles)) {
        // console.log('[article]')
        articles.forEach((article) => {
         eventHandlers(
           'ARTICLE_DISPLAYED',
           popularThemes,
           article.id,
           'MY_CLIENTS',
           article.channelId,
           article.title,
           article.themeId,
           themeNames[article.themeId],
           article.topic,
           userEmail
         );
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <React.Fragment>
      <ArticleSliderWrapper>
        <Slider {...dashboardSliderSettings}>
          {isFreeUser && <FreeArticle />}
          {localExploreArticles.map((article, index) => (
            <DashboardArticle
              key={'dashboard_article_'+ index}
              article={article}
              bookmarksFetching={bookmarksFetching}
              bookmarksId={bookmarksId}
              selectedDropdownOption={selectedDropdownOption}
              selectedCRMContactList={selectedCRMContactList}
              {...{
                ...article,
                selectedTopic,
                isFreeUser,
                deleteArticleRequest,
                themeNames,
                exploreArticleDeleting,
                isDeleteBlocked,
                showMio,
                index,
                setShowArticleLoader,
                totalArticles,
                setImageCounter,
                imageCounter,
                fromMyClients,
                isShareBlocked,
                exploreArticleRequest,
                onNewsletterArticleClick,
                onRemoveFromNewletterClick,
                newsletterArticleFetching,
                exploreNewsletterId
              }
              }
            />
          ))}
        </Slider>
      </ArticleSliderWrapper>
    </React.Fragment>
  );
};

export default MyClientsTab;
