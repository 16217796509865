import * as React from 'react';

function UsersThree(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 36 36" fill="none" {...props}>
      <path
        d="M18 25.313a5.625 5.625 0 100-11.25 5.625 5.625 0 000 11.25zM27.563 16.313a8.41 8.41 0 016.75 3.375M1.688 19.688a8.41 8.41 0 016.75-3.375M9.9 30.375a9.013 9.013 0 0116.2 0M8.438 16.313a4.5 4.5 0 114.415-5.344M23.147 10.969a4.5 4.5 0 114.416 5.344"
        stroke="currentColor"
        strokeWidth={2.4}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

const MemoUsersThree = React.memo(UsersThree);
export default MemoUsersThree;
