import React from 'react';
import withAppLayout from '../../hoc/withAppLayout';

import {
  AdminContacts,
  AdvisorContacts
} from '../../containers';

const AdminContactsLayout = () => <AdminContacts />;
const AdvisorContactsLayout = () => <AdvisorContacts />;

export const AdminContactsPage = withAppLayout(AdminContactsLayout);
export const AdvisorContactsPage = withAppLayout(AdvisorContactsLayout);
