/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import {
    PageContainer,
    CardContainer,
} from './styles';
import { cascadeCustomRow, deleteCustomRow, getCustomRows, postDefaultRow } from '../../services/bee-editor';
import TemplateCards from '../../componentsV2/TemplateCards';
import CardSkeleton from './CardSkeleton';
import { ConfirmationModalV2 } from '../../componentsV2';
import CascadeModal from './CascadeModal';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';

const ManageRows = ({
    searchString,
    selectedFilter, 
    showDeleteModal, 
    setShowDeleteModal,
    membersList,
    isAdmin
}) => {
    const [customUserRows, setCustomUserRows] = useState([]);
    const [pageLoader, setPageLoader] = useState(false);
    const [showDefaultModal, setShowDefaultModal] = useState(false);
    const [selectedCard, setSelectedCard] = useState();
    const [modalLoading, setModalLoading] = useState(false);
    const [showShareModalOpen, setShowShareModalOpen] = useState(false);
    const [selectedAdvisors, setSelectedAdvisors] = useState([]);
    const [selectedRow, setSelectedRow] = useState();
    const [shareModalMessage, setShareModalMessage] = useState('');

    useEffect(() => {
        fetchCustomRows(selectedFilter.key);
    }, [selectedFilter]);

    const deleteHandler = async(cardData) => {
        console.log('delete', cardData)
        setModalLoading(true);
        try{
            await deleteCustomRow({id: cardData._id});
            setPageLoader(true);
            fetchCustomRows();
            setModalLoading(false);
            setShowDeleteModal(false);
            setPageLoader(false);
        }catch(err){
            console.log('Delete row error:', err);
            setModalLoading(false);
        }
    }

    const fetchCustomRows = async() => {
        setPageLoader(true);
        try{
            const response = await getCustomRows(selectedFilter)
            if(response && response.data) 
                setCustomUserRows(response.data);
            setPageLoader(false);
        } catch(err){
            console.log('Custom Rows fetch err:', err)
            setPageLoader(false);
        }
    };

    const makeDefaultHandler = async() => {
        setModalLoading(true);
        const id = selectedCard._id;
        const rowCategory = selectedCard.category;

        const payload = {
            category: rowCategory
        };
        try{
            setPageLoader(true);
            await postDefaultRow({ id, payload });
            setModalLoading(false);
            setShowDefaultModal(false);
            await fetchCustomRows();
            setPageLoader(false);
        }catch(err){
            console.log('Make default error:', err);
            setModalLoading(false);
        }
    }

    const handleShare = () => {
        setModalLoading(true);
        setShareModalMessage('');
        const payload = {
            users: {
                cascaded_to: !isEmpty(selectedAdvisors) ? JSON.stringify(selectedAdvisors) : []
            },
            id: selectedRow
        };
        cascadeCustomRow(payload)
        .then(response => {
            console.log('RESPONSE - - - -- ', response);
            setModalLoading(false);
            handleShareModalCloseHandler();
            fetchCustomRows();
        })
        .catch(err => {
            console.log('CASCADE ERROR - - --', err);
            setShareModalMessage('Error cascading! Please try again later.');
            setModalLoading(false);
        });
    }

   

    const handleShareModalCloseHandler = () => {
        setSelectedAdvisors([]);
        setShowShareModalOpen(false);
        setSelectedRow('');
        setShareModalMessage('')
    }

    const handleSelectedAdvisors = (item) => {
        if(item === 'all') {
            if(selectedAdvisors.length === membersList.length) {
                setSelectedAdvisors([]);
            } else {
                setSelectedAdvisors(membersList.map(m => m.id));
            }
        }
        else if(isEmpty(selectedAdvisors)) {
            setSelectedAdvisors([item])
        } else if(!isEmpty(selectedAdvisors.filter(s => s === item))) {
            setSelectedAdvisors(selectedAdvisors.filter(s => s !== item));
        } else {
            setSelectedAdvisors([...selectedAdvisors, item])
        }
    }

    const handleShareModalOpen = (id) => {
        setSelectedAdvisors(customUserRows.filter(c => c._id === id)[0].cascaded_to);
        setShowShareModalOpen(true);
        setSelectedRow(id);
    }

    return(
        <PageContainer>
            <CardContainer>
                {
                   !pageLoader && customUserRows ? customUserRows.filter((item) => item.name.toLowerCase().includes(searchString.toLowerCase())).map((card, index) => {
                        return(
                            <TemplateCards 
                                key={index} 
                                cardData={card} 
                                cardType={'row'} 
                                setShowDefaultModal={()=>setShowDefaultModal(true)}
                                setSelectedCard={setSelectedCard}
                                setShowDeleteModal={setShowDeleteModal}
                                setShowShareModal={handleShareModalOpen}
                                isAdmin={isAdmin}
                            />
                        )
                   }) : <div style={{width: '100%'}}><CardSkeleton /></div>
                }
           </CardContainer>

            {showDefaultModal && (
                <ConfirmationModalV2 
                    isModalOpen={showDefaultModal}
                    title="Are you sure you want to continue?"
                    subTitle="By making this row a default it will automatically be applied to all blank templates during campaign creation."
                    btnOkName="Continue"
                    btnCancelName="Cancel"
                    onClose={()=>setShowDefaultModal(!showDefaultModal)}
                    onSubmit={makeDefaultHandler}
                    loading={modalLoading}
                    modalWidth="35%"
                />
            )} 
            {/* <ModalContainer
                title="Make Default"
                modalStatus={showDefaultModal}
                closeModal={()=>setShowDefaultModal(!showDefaultModal)}
                children={makeDefaultDisplay()}
            /> */}
              {/* <ModalContainer
                title="Confirm Delete"
                modalStatus={showDeleteModal}
                closeModal={()=>setShowDeleteModal(!showDeleteModal)}
                children={deleteDisplay()}
            /> */}

            {showDeleteModal && (
                <ConfirmationModalV2 
                    isModalOpen={showDeleteModal}
                    title="Are you sure you want to delete this row?"
                    btnOkName="Continue"
                    btnCancelName="Cancel"
                    onClose={()=>setShowDeleteModal(!showDeleteModal)}
                    onSubmit={() => deleteHandler(selectedCard)}
                    loading={modalLoading}
                    modalWidth="30%"
                />
            )}

            {showShareModalOpen && (
                <CascadeModal 
                    onClose={handleShareModalCloseHandler}
                    isModalOpen={showShareModalOpen}
                    title="Cascade Managed Rows"
                    subTitle="Select users to share your managed rows with"
                    isEnterpriseAdmin={false}
                    advisorList={membersList}
                    selectedAdvisors={selectedAdvisors}
                    handleSelectedAdvisors={handleSelectedAdvisors}
                    message={shareModalMessage}
                    onSubmit={handleShare}
                    disable={false}
                    loading={modalLoading}
                />
            )}
        </PageContainer>
    )
};

const mapStateToProps = state => {
    return {
        membersList: state.user.membersList ? [
            ...state.user.membersList,
        ] : [],
    };
}

export default connect(mapStateToProps, null)(ManageRows);