import * as React from 'react';

function InfoCircle(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 25" fill="none" {...props}>
      <path
        d="M12 21.5a9 9 0 100-18 9 9 0 000 18z"
        stroke="currentColor"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.25 11.75H12V17h.75"
        stroke="currentColor"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.813 9.5a1.125 1.125 0 100-2.25 1.125 1.125 0 000 2.25z"
        fill="currentColor"
      />
    </svg>
  );
}

const MemoInfoCircle = React.memo(InfoCircle);
export default MemoInfoCircle;
