import React from 'react';

function Cursor() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 33 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.10764 6.06214L12.3826 25.4621C12.4549 25.6531 12.5839 25.8173 12.7523 25.9327C12.9207 26.0482 13.1204 26.1093 13.3245 26.1079C13.5286 26.1066 13.7275 26.0428 13.8943 25.9251C14.0611 25.8074 14.1879 25.6415 14.2576 25.4496L17.1701 17.4371C17.2208 17.2997 17.3007 17.1748 17.4043 17.0713C17.5078 16.9677 17.6327 16.8878 17.7701 16.8371L25.7826 13.9246C25.9745 13.8549 26.1404 13.7281 26.2581 13.5613C26.3758 13.3945 26.4396 13.1956 26.441 12.9915C26.4423 12.7873 26.3812 12.5877 26.2657 12.4193C26.1503 12.2509 25.9861 12.1219 25.7951 12.0496L6.39514 4.77464C6.21536 4.7072 6.01995 4.69295 5.83229 4.73359C5.64462 4.77424 5.47262 4.86805 5.33684 5.00383C5.20106 5.13961 5.10725 5.31161 5.0666 5.49928C5.02596 5.68695 5.04021 5.88235 5.10764 6.06214V6.06214Z"
        stroke="currentColor"
        stroke-width="2.4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M17.4072 17.0742L26.3322 25.9992"
        stroke="currentColor"
        stroke-width="2.4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

export default Cursor;
