import { get, isEmpty } from 'lodash';
import { takeLatest, all, call, put } from 'redux-saga/effects';
import { 
  getBucketArticles, 
  deleteBucketArticle, 
  getNewsletterOutboxData, 
  deleteNewsletterArticle,
  getBucketArticlesCount
} from '../../services/newsletter';
import { moreActions as newsletterMoreActions, actions as newsletterActions } from '../modules/newsletter.module';

function* newsletterBucketRequest() {
  try {
    const response = yield call(getBucketArticles);
    const data = get(response, 'result.data', {});
    yield put(newsletterMoreActions.custom.newsletter.bucketResponse(data)); 
  } catch (error) {
    yield put(newsletterActions.api.newsletter.error(error));
  }
}

function* newsletterBucketDeleteRequest({ payload }) {
  try {
    const params = get(payload, 'params', {});
    const response = yield call(deleteBucketArticle, params);
    if(response === '') {
      yield put(newsletterMoreActions.custom.newsletter.bucketRequest());
      yield put(newsletterMoreActions.custom.newsletter.bucketCountRequest());
    }     
  } catch (error) {
    yield put(newsletterActions.api.newsletter.error(error));
  }
}

function* newsletterOutboxRequest({ payload }) {
  try {
    const params = get(payload, 'params', {});
    const response = yield call(getNewsletterOutboxData, params);
    const data = get(response, 'result.data', {});
    yield put(newsletterActions.api.newsletter.response(data));
  } catch (error) {
    yield put(newsletterActions.api.newsletter.error(error));
  }
}

function* newsletterDeleteRequest({ payload }) {
  try {
    const { id } = get(payload, 'params', {});
    const response = yield call(deleteNewsletterArticle, {id});
    if(response.result.success) {
      yield put(newsletterActions.api.newsletter.request(payload));

    } 
  } catch (error) {
    yield put(newsletterActions.api.newsletter.error(error));
  }
}

function* newsletterBucketCountRequest({ payload }) {
  try {
    const response = yield call(getBucketArticlesCount);
    if (response.result.success && !isEmpty(response.result.data)) {
      yield put(newsletterMoreActions.custom.newsletter.bucketCountResponse(+response.result.data[0]));
    }
  } catch (error) {
    yield put(newsletterActions.api.newsletter.error(error));
  }
}

function* newsletterSaga() {
  yield all([
    takeLatest(newsletterMoreActions.custom.newsletter.bucketRequest, newsletterBucketRequest),
    takeLatest(newsletterMoreActions.custom.newsletter.bucketDelete, newsletterBucketDeleteRequest),
    takeLatest(newsletterActions.api.newsletter.request, newsletterOutboxRequest),
    takeLatest(newsletterActions.api.newsletter.delete, newsletterDeleteRequest),
    takeLatest(newsletterMoreActions.custom.newsletter.bucketCountRequest, newsletterBucketCountRequest)
  ]);
}

export default newsletterSaga; 