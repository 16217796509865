import React from 'react';
import { ToggleContainer, ToggleButtonStyle } from './styles';

const ToggleBar = ({ toggleItems, activeToggle, toggleClick, margin }) => {
    return(
        <ToggleContainer size="small" aria-label="Small sizes" margin={margin}>
            {
                toggleItems.map((toggle, index) => {
                    return(
                        <ToggleButtonStyle
                            key={index}
                            onClick={()=>toggleClick(toggle.key)} 
                            activeToggle={activeToggle === toggle.key}
                        >
                            {toggle.name}
                        </ToggleButtonStyle>
                    )
                })
            }
        </ToggleContainer>
    )
};

export default ToggleBar;