import React, { useEffect, useMemo, useState } from 'react';
import { Select, MenuItem, FormControl, Grid } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useLocation } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';

import useRESTQuery from 'src/hooks/useRESTQuery';
import DialogModal from 'src/componentsV2/DialogModal';
import { ErrorDisplay } from 'src/componentsV2/ErrorDisplay';
import EmailPreview from './EmailPreview';
import SocialPreview from './SocialPreview/index';

import {
  HeaderActions,
  ModalChildren,
  PlatformDropdownContainer,
  PlatformMenuContainer,
  TablistWrapper,
} from './styles';
import {
  dropdownStyles,
  previewPlatforms,
  socialMediaPlatforms,
  transformAutomatedCampaignPreview,
  transformSeriesCampaignPreview,
} from './utils';
import TabList from 'src/componentsV2/Tablist';

function Preview({ open, toggleModal, selectedSubCampaign }) {
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const type = queryParams.get('type');
  const campaignId =
    selectedSubCampaign?.idHash || selectedSubCampaign?.id || '';
  const [selectedPlatform, setSelectedPlatform] = useState(
    previewPlatforms?.[0]?.value
  );
  const [selectedSocialPlatform, setSelectedSocialPlatform] = useState();

  const seriesTypeCampaign = type === '1';

  const { isLoading, data, isError, refetch } = useRESTQuery({
    key: 'campaign-preview',
    api: seriesTypeCampaign ? 'api.clout' : 'api.automatedCampaign',
    endpoint: seriesTypeCampaign
      ? `campaign/${campaignId}`
      : `/campaigns/${selectedSubCampaign?.automatedCampaignId}/blocks/${campaignId}`,
    dependencies: [open, campaignId],
    options: {
      select: (res) => {
        if (seriesTypeCampaign) {
          const result = res?.result?.data ?? {};
          return transformSeriesCampaignPreview(result);
        } else {
          const result = res?.data ?? {};
          return transformAutomatedCampaignPreview(result);
        }
      },
    },
  });

  const toggleModalHandler = () => {
    toggleModal((prev) => !prev);
  };

  const handlePlatformsSelection = (event) => {
    const value = event?.target?.value;
    setSelectedPlatform(value);
  };

  const handleSocialMediaPlatformsSelection = (currentTab) => {
    const platform = activePlatforms?.find((p) => p.value === currentTab);
    setSelectedSocialPlatform(platform);
  };

  const activePlatforms = useMemo(
    () => socialMediaPlatforms(data?.platforms)?.filter((p) => p?.show),
    [data]
  );
  console.log({ activePlatforms });

  useEffect(() => {
    const [email, social] = previewPlatforms;
    const platforms = data?.platforms ?? {};

    if (platforms) {
      if (
        platforms?.is_facebook ||
        platforms?.is_twitter ||
        platforms?.is_linkedin
      ) {
        setSelectedPlatform(social?.value);
        setSelectedSocialPlatform(activePlatforms?.[0]);
      } else {
        setSelectedPlatform(email?.value);
      }
    }
  }, [activePlatforms, data]);

  const isDisabled = (platform) => {
    if (platform?.value === 'EMAIL') return data?.platforms?.is_email;
    else
      return (
        data?.platforms?.is_facebook || data?.is_twitter || data?.is_linkedin
      );
  };

  return (
    <DialogModal
      show={open}
      toggleModalVisibility={toggleModalHandler}
      title="Campaign Preview"
      showTitle
    >
      {isError ? (
        <ErrorDisplay height="400px" onClick={refetch} />
      ) : (
        <ModalChildren>
          {isLoading ? (
            <Skeleton height={400} />
          ) : (
            <>
              <HeaderActions
              >
                <FormControl>
                  <CustomDropdown
                    platforms={previewPlatforms}
                    handleChange={handlePlatformsSelection}
                    isDisabledCheck={isDisabled}
                    value={selectedPlatform}
                  />
                </FormControl>
                {selectedPlatform === 'SOCIAL' && (
                  <PlatformDropdownContainer>
                    <TablistWrapper>
                      <TabList
                        data={activePlatforms}
                        activeTab={selectedSocialPlatform?.value}
                        setActiveTab={(currentTab) =>
                          handleSocialMediaPlatformsSelection(currentTab)
                        }
                        fullWidth
                      />
                    </TablistWrapper>
                  </PlatformDropdownContainer>
                )}
              </HeaderActions>

              {selectedPlatform === 'EMAIL' ? (
                <EmailPreview data={data} />
              ) : (
                <SocialPreview
                  data={data}
                  seriesTypeCampaign={seriesTypeCampaign}
                  selectedPlatform={selectedSocialPlatform}
                />
              )}
            </>
          )}
        </ModalChildren>
      )}
    </DialogModal>
  );
}

export default Preview;

function CustomDropdown({ platforms, handleChange, isDisabledCheck, value }) {
  return (
    <Select
      onChange={handleChange}
      value={value}
      disableUnderline
      IconComponent={ExpandMoreIcon}
      sx={dropdownStyles}
    >
      {platforms.map((platform, index) => (
        <MenuItem
          key={index}
          value={platform?.value}
          disabled={isDisabledCheck && !isDisabledCheck(platform)}
        >
          <PlatformMenuContainer>
            {platform?.icon ?? <></>}
            <p>{platform?.label ?? ''}</p>
          </PlatformMenuContainer>
        </MenuItem>
      ))}
    </Select>
  );
}
