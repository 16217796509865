import styled from 'styled-components';
import { Flex } from '@rebass/grid';

export const AddEmailRecipientsWrapper = styled.div`
  color: #6351ed;
`;

export const EmailListForm = styled(Flex)`
  overflow-y: auto;
`;
