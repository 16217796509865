import React from 'react';
import { Flex } from '@rebass/grid';
import { Button, LoaderWrapper } from '../../../components';

const AddContentButtonV2 = ({
    step,
    updateStep,
    layout,
    handleValidation,
    onResolveHandler,
    loading,
    handleBack
}) => {

    return (
        <Flex justifyContent="center">
            <LoaderWrapper isLoading={loading}>
                <Button
                    disabled={step === 0}
                    margin="20px 5px 20px 20px"
                    onClick={handleBack}
                    borderRadius='4px'
                    width='50%'
                    bgColor='#ffffff'
                    borderColor='#E4E7EC'
                    color='black'
                >
                    Back
                </Button>

                <Button
                    margin="20px 20px 20px 5px"
                    onClick={onResolveHandler}
                    disabled={handleValidation()}
                    borderRadius='4px'
                    width='50%'
                    bgColor='#816EF8'
                >
                    {step === 1 && layout === 'webinar-block' ? "Save" : step !== 2 ? 'Next' : 'Save'}
                </Button>
            </LoaderWrapper>

        </Flex>
    );
};

export default AddContentButtonV2;