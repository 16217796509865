import { Flex } from "@rebass/grid";
import styled from "styled-components";
import { themesData } from "../../utils";

export const TemplateWrapper = styled(Flex).attrs({
    className: 'template-wrapper'
})`
    color: #000;
    padding: 20px 30px 0px;
    font-family: 'Poppins';
`;

export const TemplateCardWrapper = styled.div.attrs({
    className: 'template-card-wrapper'
})`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    padding: 15px 0px;
`;

export const TemplateCard = styled.div.attrs({
    className: 'template-card'
})`
    display: flex;
    flex-direction: column;
    height: 275px;
    width: 23%;
    margin: 10px;
    background-color: ${themesData.white};
    border-radius: 8px;
    border: 1px solid ${themesData.greyWhite};
`

export const FilteredTemplateCard = styled.div.attrs({
  className: 'filtered-template-card',
})`
  display: flex;
  flex-direction: column;
  height: 220px;
  width: 30%;
  margin: 10px;
  background-color: ${themesData.white};
  border-radius: 8px;
  border: ${({ borderColor }) => `1px solid ${borderColor || themesData.greyWhite}`};
`;

export const TemplateTooltip = styled.div.attrs({
    className: 'tooltip',
  })`
    position: relative;
    display: inline-block;
  
    & .tooltiptext {
      visibility: hidden;
      width: 232px;
      background-color: black;
      color: #fff;
      text-align: center;
      border-radius: 6px;
      padding: 5px 0;
      border: 1px solid #4e6780;
      font-size: 10px;
      font-weight: 600;
      /* Position the tooltip */
      position: absolute;
      z-index: 1;
      bottom: 150%;
      left: 26%;
      margin-left: -60px;
      ${'' /* margin-top: 5px; */}
    }
  
    & .tooltiptext::after {
      content: "";
      position: absolute;
      top: 100%;
      left: 50%;
      margin-left: -5px;
      border-width: 5px;
      border-style: solid;
      border-color: black transparent transparent transparent;
    }
  
    &:hover .tooltiptext {
      visibility: visible;
    }
  `;
  
  