import { styled } from '@mui/material';
import ReactSkeleton from 'react-loading-skeleton';

export const Wrapper = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: '1.5rem',
}));

export const Separator = styled('div')(() => ({
  width: '100%',
  height: 1,
  background: '#CABEFF',
}));

export const PillSkeleton = styled(ReactSkeleton)(() => ({
  borderRadius: '0.5rem',
}));
