import styled from 'styled-components';
import { Flex } from '@rebass/grid';
import themes from '../CommonStyles/colors';

export const EditableContentWrapper = styled.div`
  padding: ${({padding}) => padding && '0px 0px'};
  width: 100%;
  background-color: #fff;
  text-align: left;
  overflow-y: auto;
  overflow-x: hidden;
  font-size: 14px;
  ${({ styles }) => styles};
  min-height: 55px;
  height: 90px;
  .content-placeholder {
    text-align: center;
    color: ${themes.color.font.primary};
  }
  :empty:not(:focus):before,
  .placeholder-el:empty:not(:focus):before {
    text-align: center;
    color: #6351ed;
    // content: attr(placeholder);
    display: block;
  }
  .summay-highlight {
    @keyframes blinkingText {
      0% {
        background-color: #e9e2ff;
      }
      25% {
        background-color: #e0d6ff;
      }
      50% {
        background-color: #e9e2ff;
      }
      75% {
        background-color: #e0d6ff;
      }
      100% {
        background-color: #e9e2ff;
      }
    }
    animation: blinkingText 2s infinite;
    cursor: pointer;
    color: #545454;
    background-color: #e4e4e4;
  }
  :empty:before {
    content: attr(placeholder);
    display: block; /* For Firefox */
    color: transparent;
  }
`;

export const EditableContentTextArea = styled.textarea`
  width: 100%;
  background-color: #fff;
  text-align: left;
  overflow-y: auto;
  overflow-x: hidden;
  font-size: 14px;
  border: 0px;
  ${({ styles }) => styles};
  height: ${({ height }) => (height ? height : '25px')};
  border: 0px;
  resize: none;
  ${({ height }) => (!height || height === 'auto') && 'min-height: 40px'};
  .content-placeholder {
    text-align: center;
    color: #6351ed;
  }
  :focus::placeholder {
    color: transparent;
  }
  ::placeholder {
    text-align: center;
  }
`;

export const SummayContent = styled(Flex)`
  background-color: #fff;
  padding: 6px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  line-break: auto;
  border: 1px solid rgba(0, 0, 0, 0.2);
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.42857143;
  border-radius: 5px;
  a {
    font-size: 10px;
    padding: 5px 0px;
    border-bottom: 1px solid #e4e4e4;
    cursor: pointer;
    text-decoration: none;
    width: 230px;
    border-radius: 5px;
    color: #333;
  }
`;
