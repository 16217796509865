/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { LoaderWrapper, ModalBody, ModalHeader, ModalV2 } from '../../../components'
import { Flex } from '@rebass/grid'
import { ColorPallete, Typography, getMediaImage, onImageError } from '../../../utils'
import Chip from '../../../componentsV2/Chip/Chip'
import { EmptyState, ProgressBar } from '../../../componentsV2'
import styled from 'styled-components';
import { AnalyticsTable } from '../AnalyticsTable';
import moment from 'moment';
import { Tooltip } from '@mui/material'
import { getContactEngagedContent, getContactSummary, getContactTags, getListCampaign, getListMostEngagedContact, getListSummary, getListTags, getMostEngagedCampaign, getMostEngagedTopics } from '../../../services/analyticsV2'
import { get, isEmpty } from 'lodash'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import { ErrorDisplay } from 'src/componentsV2/ErrorDisplay'
import ContactDetailsTable from './ContactDetailsTable'
import { deleteFromBookmarks, postAddToBookmarks } from 'src/services/dashboard'
import { SocialMediaLinks } from 'src/pages/Home/TopPerformingCampaigns/styles'
import Mail from 'src/assets/icons/social-media/Mail';
import Facebook from 'src/assets/icons/social-media/Facebook';
import LinkedIn from 'src/assets/icons/social-media/LinkedIn';
import Twitter from 'src/assets/icons/social-media/Twitter';

const ModalWrapper = styled(Flex)`
    height: 540px;
    max-height: 540px;
    overflow-x: hidden;
    overflow-y: auto;
    position: relative;

    &::-webkit-scrollbar {
        width: 5px;
    }

    &::-webkit-scrollbar-track {
        background: #e9f6e6 !important;
    }

    &::-webkit-scrollbar-thumb {
        background: #bccdd4 !important;
    }
`;

const rowType = {
    EMAILLIST: 'email_list',
    SEGMENT: 'segments_list',
    CONTACT: 'contact'
}


const ContactListDetailModal = ({
    isOpen,
    closeHandler,
    row,
    type,
    startDate
}) => {
    const [page, setPage] = useState(1);
    const [maxPage, setMaxPage] = useState(null);
    const [paginationData, setPaginationData] = useState(null);
    const [includedTags, setIncludedTags] = useState([]);
    const [excludedTags, setExcludedTags] = useState([]);
    const [tagsLoading, setTagsLoading] = useState([]);
    const [topicLoading, setTopicLoading] = useState(false);
    const [topicError, setTopicError] = useState(false);
    const [engagingTopics, setEngagingTopics] = useState([]);
    const [engagingCampaign, setEngagingCampaign] = useState([]);
    const [campaignLoading, setCampaignLoading] = useState(false);
    const [campaignError, setCampaignError] = useState(false);
    const [articleData, setArticleData] = useState([]);
    const [articleError, setArticleError] = useState(false);
    const [articleLoading, setArticleLoading] = useState(false);
    const [contactSummary, setContactSummary] = useState();
    const [tagError, setTagError] = useState(false);
    const [summaryLoading, setSummaryLoading] = useState(false);
    const [tableData, setTableData] = useState([]);
    const [bookmarkLoading, setBookmarkLoading] = useState(false);
    const [bookmarkId, setBookMarkId] = useState('');
    const [tableLoading, setTableLoading] = useState(false);
    const [pagePointer, setPagePointer] = useState(0);
    const [tableError, setTableError] = useState(false);


    const headers = [
        {
          name: 'First Name',
          width: '25%'
        },
        {
          name: 'Last Name',
          width: '25%'
        },
        {
          name: 'Email',
          width: '25%'
        },
        {
          name: 'Engagement Score',
          width: '25%'
        }
      ];

    const calculateTableData = (page_no) => {
        setTableLoading(true);
        getListMostEngagedContact(startDate, row?.id, type, page_no).then(res => {
            setTableData(get(res, 'result.data', []));
            setPaginationData(get(res, 'result.pagination', {}))
            setTableLoading(false);
            setTableError(false);
        }).catch((err) => {
            setTableLoading(false);
            setTableError(true);
        })
    }
    const handlePageChange = (type) => {
        let page_no = type === 'prev' ? page - 1 : page + 1;
        setPage(page_no);
        calculateTableData(page_no)
    }

    const handlePageClick = (page_no) => {
        setPage(page_no);
        calculateTableData(page_no);
    }

    const onNextClick = () => {
        setPagePointer(pagePointer + 8);
    }

    const onPreviousClick = () => {
        setPagePointer(pagePointer - 8);
    }

    const fetchTags = () => {
        setTagsLoading(true);
        let serviceMapping = (type === rowType.EMAILLIST || type === rowType.SEGMENT) ? getListTags : getContactTags;
        serviceMapping(startDate,(type === rowType.EMAILLIST || type === rowType.SEGMENT)  ? row?.id : row?.email, (type === rowType.EMAILLIST || type === rowType.SEGMENT) ? type : null).then(res => {
            setIncludedTags(get(res, 'result.included_tags', []));
            setExcludedTags(get(res, 'result.excluded_tags', []));
            setTagsLoading(false);
            setTagError(false);
        }).catch(err => {
            setTagsLoading(false);
            setTagError(true);
        })
    }

    const fetchMostEngagedTopics = () => {
        setTopicLoading(true);
        setTableLoading(true);
        let serviceMapping = (type === rowType.EMAILLIST || type === rowType.SEGMENT) ? getListMostEngagedContact : getMostEngagedTopics;
        serviceMapping(startDate, (type === rowType.EMAILLIST || type === rowType.SEGMENT)  ? row?.id : row?.email, (type === rowType.EMAILLIST || type === rowType.SEGMENT) ? type : null, (type === rowType.EMAILLIST || type === rowType.SEGMENT) ? page : null).then(res => {
            if(type === rowType.SEGMENT || type === rowType.EMAILLIST) {
                setEngagingTopics(get(res, 'result.data', []));
            } else {
                setEngagingTopics(get(res, 'result', []));
            }
            
            if(type === rowType.EMAILLIST || type === rowType.SEGMENT) {
                setMaxPage(get(res, 'result.pagination.total_pages', 0));
                setPaginationData(get(res, 'result.pagination', {}));
                setTableData(get(res, 'result.data', []));
            }
            setTopicLoading(false);
            setTableLoading(false);
            setTableError(false);
            setTopicError(false);
        }).catch(err => {
            setTopicLoading(false);
            setTopicError(true);
            setTableLoading(false);
            setTableError(true);
        })
    }

    const fetchMostEngagedCampaign = () => {
        setCampaignLoading(true);
        let serviceMapping = (type === rowType.EMAILLIST || type === rowType.SEGMENT) ? getListCampaign : getMostEngagedCampaign;
        serviceMapping(startDate,(type === rowType.EMAILLIST || type === rowType.SEGMENT)  ? row?.id : row?.email, (type === rowType.EMAILLIST || type === rowType.SEGMENT) ? type : null).then(res => {
            setCampaignLoading(false);
            setEngagingCampaign(get(res, 'result', []));
            setCampaignError(false);
        }).catch(err => {
            setCampaignLoading(false);
            setCampaignError(true);
        })
    }

    const fetchContactSummary = () => {
        setSummaryLoading(true);
        let serviceMapping = (type === rowType.EMAILLIST || type === rowType.SEGMENT) ? getListSummary : getContactSummary;
        serviceMapping(startDate,(type === rowType.EMAILLIST || type === rowType.SEGMENT)  ? row?.id : row?.email, (type === rowType.EMAILLIST || type === rowType.SEGMENT) ? type : null).then(res => {
            setContactSummary(get(res, 'result', {}));
            setSummaryLoading(false);
        }).catch(err => {
            setSummaryLoading(false);
        })
    }

    const fetchContactEngagedContent = () => {
        setArticleLoading(true);
        getContactEngagedContent(startDate, row?.email).then(res => {
            setArticleData(get(res, 'result', []));
            setArticleError(false);
            setArticleLoading(false);
        }).catch(err => {
            setArticleError(true);
            setArticleLoading(false);
        })
    }

    const onBookmarkRemove = (item) => {
        setBookmarkLoading(true);
        setBookMarkId(item.id);
        deleteFromBookmarks(item.bookmark_id).then(res => {
            setBookmarkLoading(false);
            fetchContactEngagedContent();
        }).catch(err => {
            console.log(err);
            setBookmarkLoading(false);
        })
    }

    const onBookmarkAdd = (item) => {
        setBookmarkLoading(true);
        setBookMarkId(item.id);
        const { id, theme_id_hash } = item;
        postAddToBookmarks(id, theme_id_hash).then(res => {
            setBookmarkLoading(false);
            fetchContactEngagedContent();
        }).catch(err => {
            console.log(err);
            setBookmarkLoading(false);
        })
    }


    useEffect(() => {
        fetchTags();
        fetchMostEngagedCampaign();
        fetchMostEngagedTopics();
        fetchContactSummary();
        if(type === rowType.CONTACT) {
            fetchContactEngagedContent();
        }
    }, [])

    return (
        <ModalV2
            isOpen={isOpen}
            bgColor='#fff'
            width="60%"
            height='auto'
            onRequestClose={closeHandler}
        >
            <ModalHeader fontColor='#000'>
                <Flex width="100%">
                    {type === 'contact' ? (
                        <Flex width="95%">
                            <Flex>
                                <img style={{ height: '48px' }} src="/assets/images/Analytics/Avatar.svg" alt="" />
                            </Flex>
                            <Flex ml="16px" flexDirection="column">
                                <Flex mb="2px" color={ColorPallete.neutral900} fontSize={Typography.TextLarge}>{row?.first_name} {row?.last_name}</Flex>
                                <Flex color={ColorPallete.neutral500} fontSize={Typography.TextMedium}>{row?.email}</Flex>
                            </Flex>  
                        </Flex>
                    ) : (
                        <Flex width="95%" flexDirection="column">
                            <Flex color={ColorPallete.neutral900} fontSize={Typography.TextLarge}>
                                {row?.list_name}
                            </Flex>
                            <Flex style={{ textAlign: 'left' }} color={ColorPallete.neutral500} fontSize={Typography.TextMedium} pt="3px">
                                {row?.contacts_count} Contacts
                            </Flex>
                        </Flex>
                    )}

                    <Flex width="5%">
                        <img
                            src="/assets/images/Analytics/close-button.svg"
                            alt="close"
                            style={{ cursor: 'pointer', width: '40px' }}
                            onClick={closeHandler}
                        />
                    </Flex>
                </Flex>
            </ModalHeader>
            <ModalBody>
                <ModalWrapper width="100%" flexDirection="column">
                    <Flex width="98%" justifyContent="space-between">
                        <Flex 
                            width="65%"
                            flexDirection="column"
                            padding="16px"
                            style={{
                                borderRadius: '8px',
                                border: `1px solid ${ColorPallete.neutral200}`
                            }}
                        >
                            <Flex
                                color={ColorPallete.black}
                                fontSize={Typography.TextMedium}
                            >
                                {(rowType.CONTACT === type) ? 'Implicit Tags:' : rowType.EMAILLIST === type ? 'Unique Implicit Tags:' : 'Included Tags:'}
                            </Flex>
                            {tagsLoading && (
                                    <SkeletonTheme>
                                        <Skeleton
                                            height={45}
                                            baseColor={ColorPallete.neutral100}
                                        />
                                    </SkeletonTheme>
                            )}
                            {!tagsLoading && (
                                <Flex mt="5px">
                                    {!isEmpty(includedTags) ? includedTags.slice(0, 4).map((tag) => (
                                        <Chip
                                            height="24px"
                                            bgColor={ColorPallete.lightBlue}
                                            padding="2px 8px"
                                            color={ColorPallete.darkBlue}
                                            width="auto"
                                            borderRadius="12px"
                                            border={`1px solid ${ColorPallete.lightBlue}`}
                                            fontSize={Typography.TextSmall}
                                            mb="0px"
                                        >
                                            <Tooltip title={tag.length > 11 ? tag : null} arrow>
                                                {tag.length > 11 ? tag.substring(0, 10) + '...' : tag}
                                            </Tooltip>
                                        </Chip>
                                    )) : (
                                        <Flex fontSize={Typography.TextSmall} color={ColorPallete.black}>
                                            No Tags Assigned!
                                        </Flex>
                                    )}
                                    {!isEmpty(includedTags) && includedTags.length > 4 && (
                                        <Tooltip title={includedTags.slice(4).join(',')} arrow>
                                            <Flex
                                                style={{
                                                    border: `1px solid ${ColorPallete.neutral200}`,
                                                    borderRadius: '12px',
                                                }}
                                                padding="4px 8px"
                                                color={ColorPallete.neutral900}
                                                fontSize={Typography.TextSmall}
                                            >
                                                + {includedTags.length - 4}
                                            </Flex>
                                        </Tooltip>

                                    )}
                                </Flex>
                            )}
                            
                            
                            <Flex
                                color={ColorPallete.black}
                                fontSize={Typography.TextMedium}
                                mt="5px"
                            >
                                {(rowType.CONTACT === type) ? 'Explicit Tags:' : rowType.EMAILLIST === type ? 'Unique Explicit Tags:' : 'Excluded Tags:'}
                            </Flex>

                            {tagsLoading && (
                                    <SkeletonTheme>
                                        <Skeleton
                                            height={45}
                                            baseColor={ColorPallete.neutral100}
                                        />
                                    </SkeletonTheme>
                            )}
                            
                            {!tagsLoading && (
                                <Flex mt="5px">
                                    {!isEmpty(excludedTags) ? excludedTags.slice(0, 4).map((tag) => (
                                        <Chip
                                            height="24px"
                                            bgColor={ColorPallete.lightPink}
                                            padding="2px 8px"
                                            color={ColorPallete.darkPink}
                                            width="auto"
                                            borderRadius="12px"
                                            fontSize={Typography.TextSmall}
                                            mb="0px"
                                            borderColor={ColorPallete.lightPink}
                                        >
                                            <Tooltip title={tag.length > 11 ? tag : null} arrow>
                                                {tag.length > 11 ? tag.substring(0, 10) + '...' : tag}
                                            </Tooltip>
                                        </Chip>
                                    )) : (
                                        <Flex fontSize={Typography.TextSmall} color={ColorPallete.black}>
                                            No Tags Assigned!
                                        </Flex>
                                    )}
                                    {!isEmpty(excludedTags) && excludedTags.length > 4 && (
                                        <Tooltip title={excludedTags.slice(4).join(',')} arrow>
                                            <Flex
                                                style={{
                                                    border: `1px solid ${ColorPallete.neutral200}`,
                                                    borderRadius: '12px',
                                                }}
                                                padding="4px 8px"
                                                color={ColorPallete.neutral900}
                                                fontSize={Typography.TextSmall}
                                            >
                                                + {excludedTags.length - 4}
                                            </Flex>
                                        </Tooltip>
                                    )}
                                </Flex>
                            )}
                            
                            <Flex width="100%" mt="20px" mb="20px" style={{ borderTop: `1px solid ${ColorPallete.neutral200}`}}></Flex>
                            <Flex
                                width="100%"
                                justifyContent="space-between"
                                mb="15px"
                            >
                                <Flex
                                    color={ColorPallete.neutral600}
                                    fontSize={Typography.TextMedium}
                                >
                                    Total Email Sent 
                                </Flex>
                                <Flex
                                    color={ColorPallete.neutral900}
                                    fontSize={Typography.TextMedium}
                                >
                                    {summaryLoading ? <Skeleton width={50} height={15} baseColor={ColorPallete.neutral100}/> : contactSummary?.email_campaign_sent}
                                </Flex>
                            </Flex>
                            <Flex
                                width="100%"
                                justifyContent="space-between"
                                mb="15px"
                            >
                                <Flex
                                    color={ColorPallete.neutral600}
                                    fontSize={Typography.TextMedium}
                                >
                                    Average Open Rate
                                </Flex>
                                <Flex
                                    color={ColorPallete.neutral900}
                                    fontSize={Typography.TextMedium}
                                >
                                    {summaryLoading ? <Skeleton width={50} height={15} baseColor={ColorPallete.neutral100}/> : `${contactSummary?.average_open_rate}%`}
                                </Flex>
                            </Flex>
                            <Flex
                                width="100%"
                                justifyContent="space-between"
                                mb="15px"
                            >
                                <Flex
                                    color={ColorPallete.neutral600}
                                    fontSize={Typography.TextMedium}
                                >
                                    Total Clicks
                                </Flex>
                                <Flex
                                    color={ColorPallete.neutral900}
                                    fontSize={Typography.TextMedium}
                                >
                                    {summaryLoading ? <Skeleton width={50} height={15} baseColor={ColorPallete.neutral100}/> : contactSummary?.total_clicks}
                                </Flex>
                            </Flex>
                            <Flex width="100%" mb="20px" style={{ borderTop: `1px solid ${ColorPallete.neutral200}`}}></Flex>
                            <Flex width="100%" margin="15px 0px">
                                <ProgressBar value={row?.engagement_score}/>
                            </Flex>
                        </Flex>
                        <Flex 
                            width="30%" 
                            flexDirection="column" 
                            style={{
                                borderRadius: '8px',
                                border: `1px solid ${ColorPallete.neutral200}`
                            }}
                            padding="16px"
                        >
                            <Flex 
                                color={ColorPallete.black} 
                                fontSize={Typography.TextLarge}
                                mb="10px"
                                width="100%"
                            >
                                {type === 'contact' ? 'Most Engaging Topics' :  'Most Engaging Contacts'}
                            </Flex>
                            {topicLoading && (
                                <SkeletonTheme>
                                    <Skeleton
                                        height={45}
                                        count={5}
                                        baseColor={ColorPallete.neutral100}
                                    />
                                </SkeletonTheme>
                            )}
                            {!topicLoading && topicError && (
                                <ErrorDisplay 
                                    height="240px"
                                    onClick={fetchMostEngagedTopics}
                                />
                            )}
                            {!topicLoading && isEmpty(engagingTopics) && !topicError && (
                                <EmptyState
                                    height="240px"
                                    title="No Analytics Data"
                                    subTitle="Send out campaigns to get insights"
                                />
                            )}
                            {!topicLoading && !topicError && !isEmpty(engagingTopics) && rowType.CONTACT === type && engagingTopics.slice(0, 5).map((item, index) => (
                                <Flex
                                    width="100%"
                                    color={ColorPallete.neutral600}
                                    backgroundColor={ColorPallete.neutral50}
                                    fontSize={Typography.TextMedium}
                                    padding="12px"
                                    mb="8px"
                                    key={index}
                                >
                                    {item}
                                </Flex>

                            ))}
                            {!topicLoading && !topicError && !isEmpty(engagingTopics) && rowType.CONTACT !== type && engagingTopics.slice(0, 5).map((item, index) => (
                                <Tooltip title={item?.email.length > 15 ? item?.email : null} arrow placement="top">
                                    <Flex
                                        width="100%"
                                        color={ColorPallete.neutral600}
                                        backgroundColor={ColorPallete.neutral50}
                                        fontSize={Typography.TextMedium}
                                        padding="12px"
                                        mb="8px"
                                        key={index}
                                    >
                                    {item?.email?.length > 15 ? item?.email?.substring(0, 15) + '...' : item?.email} 
                                </Flex>
                                </Tooltip>
                            ))}
                        </Flex>
                    </Flex>
                    <Flex 
                        width="98%" 
                        mt="24px" 
                        color={ColorPallete.black}
                        fontSize={Typography.TextMedium}
                    >
                        Most Engaged Campaign
                    </Flex>
                    <Flex
                        width="98%"
                        padding="16px"
                        flexDirection={campaignLoading ? "column" : "row" }
                        mt="12px"
                        style={{
                            border: `1px solid ${ColorPallete.neutral200}`,
                            borderRadius: '8px'
                        }}
                    >
                        {campaignLoading && (
                            <SkeletonTheme>
                                <Skeleton
                                    height={90}
                                    baseColor={ColorPallete.neutral100}
                                />
                            </SkeletonTheme>
                        )}
                        {!campaignLoading && campaignError && (
                            <ErrorDisplay
                                height="100px"
                                onClick={fetchMostEngagedCampaign}
                            />
                        )}
                        {!campaignLoading && !isEmpty(engagingCampaign) && !campaignError && engagingCampaign?.success && (
                            <Flex
                                flex={'1 0 auto'}
                                width="85%"
                                height="136px"
                                backgroundColor={ColorPallete.neutral50}
                                mr="16px"
                                style={{
                                    border: `1px solid ${ColorPallete.neutral200}`,
                                    borderRadius: '4px',
                                    cursor: 'default',
                                }}
                            >
                                <Flex width="25%">
                                    <img
                                        style={{
                                            width: '100%',
                                            height: '136px',
                                            objectFit: 'cover',
                                        }}
                                        src={getMediaImage(engagingCampaign.media_url)}
                                        alt=""
                                        onError={onImageError}
                                    />
                                </Flex>
                                <Flex width="75%" padding="12px 16px" flexDirection="column">
                                    <Flex
                                        width="100%"
                                        fontSize={Typography.TextSmall}
                                        color={ColorPallete.neutral500}
                                    >
                                        {engagingCampaign.campaign_type === 'series'
                                            ? 'Series Campaign'
                                            : 'Automated Campaign'}
                                    </Flex>
                                    {engagingCampaign?.campaign_name && engagingCampaign?.campaign_name?.length > 30 ? (
                                        <Tooltip title={engagingCampaign.campaign_name} arrow placement="top">
                                            <Flex
                                                pt="3px"
                                                width="100%"
                                                color={ColorPallete.black}
                                                fontSize={Typography.TextLarge}
                                            >
                                                {`${engagingCampaign?.campaign_name.substring(0, 30)}...`}
                                            </Flex>
                                        </Tooltip>
                                    ) : (
                                        <Flex
                                            pt="3px"
                                            width="100%"
                                            color={ColorPallete.black}
                                            fontSize={Typography.TextLarge}
                                        >
                                            {engagingCampaign?.campaign_name}
                                        </Flex>
                                    )}
                                    <Flex
                                        width="100%"
                                        mt="7px"
                                        mb="10px"
                                        style={{
                                            borderTop: `1px solid ${ColorPallete.neutral200}`,
                                        }}
                                    ></Flex>
                                    <SocialMediaLinks>
                                        {engagingCampaign?.platforms?.is_email && <Mail />}
                                        {engagingCampaign?.platforms?.is_facebook && <Facebook />}
                                        {engagingCampaign?.platforms?.is_linkedin && <LinkedIn />}
                                        {engagingCampaign?.platforms?.is_twitter && <Twitter />}
                                    </SocialMediaLinks>
                                    <Flex pt="10px" width="100%" flexDirection="column">
                                        {engagingCampaign.engagement_score !== 0 && (
                                            <Flex
                                                width="100%"
                                                justifyContent="space-between"
                                                alignItems="center"
                                            >
                                                <Flex
                                                    color={ColorPallete.neutral600}
                                                    fontSize={Typography.TextMedium}
                                                >
                                                    <Flex>Engagement Score</Flex>
                                                    <Tooltip placement="right" title="Email Engagement represents the cumulative 
                                                        engagement across all emails in a campaign. Differing from traditional click-through and 
                                                        open-rate metrics, email engagement cuts through the noise to represent meaningful results 
                                                        by applying weighting to the most common interactions (Open = 10, Click = 60, Lead Captured = 30). 
                                                        The score represented then becomes an average of your total contact engagement for each email communication 
                                                        and the subsequent content provided within. For additional context, consider emails that score above 
                                                        40 as highly engaged." arrow>
                                                        <Flex>
                                                            <img
                                                                style={{
                                                                    width: '16px',
                                                                    margin: '0px 0px 0px 4px',
                                                                }}
                                                                src="/assets/images/Analytics/Grey-Info.svg"
                                                                alt=""
                                                            />
                                                        </Flex>
                                                    </Tooltip>
                                                </Flex>
                                                <Flex
                                                    color={ColorPallete.primaryPurple}
                                                    fontSize={Typography.TextLarge}
                                                >
                                                    {engagingCampaign.engagement_email}
                                                </Flex>
                                            </Flex>
                                        )}
                                    </Flex>
                                </Flex>
                            </Flex>
                        )}
                        {!campaignLoading && !campaignError && !engagingCampaign.success && (
                            <EmptyState
                                height="200px"
                                title="No Analytics Data"
                                subTitle="Send out campaigns to get insights"
                          />
                        )}
                    </Flex>
                    {(type === rowType.EMAILLIST || type === rowType.SEGMENT) && (
                        <Flex
                            width="98%"
                            flexDirection="column"
                            mt="24px"
                            style={{
                                borderRadius: '8px',
                                border: `1px solid ${ColorPallete.neutral200}`,
                                boxShadow: '0px 1px 2px 0px #1018281F'
                            }}>
                            <Flex
                                color={ColorPallete.neutral900}
                                fontSize={Typography.Heading}
                                width="100%"
                                padding="16px"
                            >
                                Contacts
                            </Flex>
                            <AnalyticsTable
                                headers={headers}
                                onPageChange={handlePageChange}
                                page={page}
                                maxPage={maxPage}
                                loading={tableLoading}
                                paginationData={paginationData}
                                onPageClick={handlePageClick}
                                pageIndex={pagePointer}
                                setPageIndex={setPagePointer}
                                handleNextClick={onNextClick}
                                handlePreviousClick={onPreviousClick}
                                left='0%'
                                mr='5%'
                            >
                                <ContactDetailsTable 
                                    data={tableData}
                                    loading={tableLoading}
                                    error={tableError}
                                    handleRetryClick={fetchMostEngagedTopics}
                                />
                            </AnalyticsTable>
                        </Flex>
                    )}
                    {type === rowType.CONTACT && (
                        <Flex
                            mt="24px"
                            width="98%"
                            flexDirection={articleLoading ? "column" : "row" }
                        >
                            {articleLoading && (
                                <SkeletonTheme>
                                    <Skeleton
                                        height={343}
                                        baseColor={ColorPallete.neutral100}
                                    />
                                </SkeletonTheme>
                            )}
                            {!articleLoading && articleError && (
                                <ErrorDisplay
                                    height="200px"
                                    onClick={fetchContactEngagedContent}
                                />
                            )}
                            {!articleLoading && !articleError && !isEmpty(articleData) && articleData.map((item, index) => (
                                <Flex
                                    key={item.id}
                                    width="32%"
                                    height="323px"
                                    flexDirection="column"
                                    backgroundColor={ColorPallete.white}
                                    margin="10px 10px 10px 0px"
                                    style={{
                                        border: `1px solid ${ColorPallete.neutral200}`,
                                        borderRadius: '8px',
                                        position: 'relative'
                                    }}
                                >
                                    <Flex
                                        width="100%"
                                        height="116px"
                                        style={{ borderBottom: `1px solid ${ColorPallete.neutral200}` }}
                                    >
                                        <img src={getMediaImage(item?.media_url)} onError={onImageError} alt="" style={{
                                            width: '100%',
                                            height: '116px',
                                            objectFit: 'cover'
                                        }} />
                                    </Flex>
                                    {!isEmpty(item?.topics) && (
                                        <Flex
                                            padding="4px 8px"
                                            mt="10px"
                                            width="fit-content"
                                            ml="10px"
                                            mr="10px"
                                            color={ColorPallete.darkBlue}
                                            backgroundColor={ColorPallete.lightBlue}
                                            fontSize={Typography.TextSmall}
                                            style={{
                                                border: `1px solid ${ColorPallete.lightBlue}`,
                                                borderRadius: '12px'
                                            }}
                                        >
                                            {item?.topics[0]}
                                        </Flex>
                                    )}
                                    
                                    <Tooltip title={item?.title?.length > 20 ? item?.title : null} arrow>
                                        <Flex ml="10px" mt="4px" mr="10px" color={ColorPallete.neutral900} fontSize={Typography.TextLarge}>
                                            {item?.title?.length > 20 ? item?.title.substring(0, 20) + '...' : item?.title}
                                        </Flex>
                                    </Tooltip>
                                    <Flex ml="10px" mt="4px" mr="10px" color={ColorPallete.neutral400} fontSize={Typography.TextSmall}>
                                        {item.publisher}
                                    </Flex>
                                    <Tooltip title={item?.description?.length > 95 ? item?.description : null} arrow>
                                        <Flex style={{ textAlign: 'left' }} ml="10px" mt="4px" mr="10px" color={ColorPallete.neutral600} fontSize={Typography.TextSmall}>
                                            {item.description?.length > 95 ? item?.description?.substring(0, 95) + '...' : item?.description}
                                        </Flex>
                                    </Tooltip>
                                    <Flex ml="10px" mt="4px" mr="10px" color={ColorPallete.neutral400} fontSize={Typography.TextSmall}>
                                        {moment(item.created_at).format('MMM DD yyyy')}
                                    </Flex>
                                    <Flex style={{ position: 'absolute', bottom: '1px', left: '3px' }}>
                                        <LoaderWrapper isLoading={bookmarkId === item.id && bookmarkLoading}>
                                            <img src={item?.is_bookmarked ? "/assets/images/Analytics/Bookmark-Active.svg" : "/assets/images/Analytics/Bookmark.svg"} alt="Bookmark" style={{ cursor: 'pointer' }} onClick={() => item?.is_bookmarked ? onBookmarkRemove(item) : onBookmarkAdd(item)} />
                                        </LoaderWrapper> 
                                    </Flex>

                                </Flex>
                            ))}
                        </Flex>
                    )}
                </ModalWrapper>
            </ModalBody>

        </ModalV2>
    )
}

export default ContactListDetailModal