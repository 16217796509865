import { styled } from '@mui/material';
import Skeleton from 'react-loading-skeleton';

export const Wrapper = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '2rem',
  padding: '2rem 0',
}));

export const Header = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: '2rem',
}));

export const Heading = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '0.5rem',

  h1: {
    font: '500 2rem/2rem Poppins, sans-serif',
    color: '#101828',
    margin: 0,
  },
  p: {
    font: '400 0.875rem/1rem Poppins, sans-serif',
    color: '#475467',
    margin: 0,
  },
}));

export const Actions = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '1rem',
}));

export const Layout = styled('div')(() => ({
  display: 'flex',
  alignItems: 'flex-start',
  gap: '1.5rem',
}));

export const MainContent = styled('div')(() => ({
  display: 'flex',
  flex: '0.5',
  flexGrow: '1',
  flexDirection: 'column',
  gap: '2rem',
}));

export const SideContent = styled('div')(() => ({
  display: 'flex',
  flex: '0.4',
  flexDirection: 'column',
  gap: '2rem',
}));

export const ZeroStateWrapper = styled('div')(() => ({
  height: '100%',
  width: '100%',
  borderRadius: '0.5rem',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  border: '1px solid #BBBEF7',
  overflow: 'hidden',
}));

export const ZeroStateContainer = styled('div')(({ backgroundUrl }) => ({
  width: '100%',
  height: '100%',
  background: `url(${backgroundUrl})`,
  backgroundSize: '100%',
  backgroundRepeat: 'no-repeat',
}));

export const ZeroStateContent = styled('div')(({ height = '17rem' }) => ({
  height: '100%',
  minHeight: height ?? '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '2rem',
  padding: '1rem',

  '.title': {
    color: '#475467',
    font: '400 1rem Poppins, sans-serif',
    margin: 0,
  },

  '.circled-icon': {
    width: '3rem',
    height: '3rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#F6F2FF',
    borderRadius: '50%',

    svg: {
      fontSize: '2.5rem',
      color: '#816EF8',
    },
  },
}));

export const ContentWrapper = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '0.5rem',
}));

export const Title = styled('h3')(() => ({
  font: '500 1.1875rem/2rem Poppins, sans-serif',
  color: '#101828',
  margin: 0,
}));

export const Link = styled('a')(() => ({
  display: 'flex',
  alignItems: 'center',
  color: '#0B5CD7',
  font: '400 0.75rem Poppins, sans-serif',
  textDecoration: 'none',
}));

export const TitleWithLink = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '0.7rem',
}));

export const PillSkeleton = styled(Skeleton)(() => ({
  borderRadius: '1.5rem',
}));
