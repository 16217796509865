import { Flex } from '@rebass/grid';
import React, { useState } from 'react';
import { ColorPallete, Typography, getMediaImage, onImageError } from '../../../utils';
import styled from 'styled-components';
import { isEmpty } from 'lodash';
import { TopicDataForMapping } from './content-data';
import { TopicChipStyle } from './ContentTab.styles';
import { EmptyState, ProgressBar } from '../../../componentsV2';
import Skeleton from 'react-loading-skeleton';
import ErrorDisplay from '../../../componentsV2/ErrorDisplay/ErrorDisplay';
import { Carousel } from 'react-responsive-carousel';
import { Tooltip } from '@mui/material';

const TopCampaignWrapper = styled(Flex)`
  .slider:before {
    visibility: hidden;
  }

  .slider {
    left: 0px;
    background-color: #fff;
  }

  .slide {
    left: -28.5%;
  }

  .selected {
    left: 0%;
  }

  .carousel.carousel-slider {
    overflow: visible;
  }
`;

const TopPerformingContent = ({
  data,
  loading,
  fetchTopContent,
  topPerformingContentError,
}) => {
  return (
    <TopCampaignWrapper
      width="100%"
      flexDirection="column"
      height="268px"
      padding="16px 0px 16px 16px"
      mt="16px"
      style={{
        border: `1px solid ${ColorPallete.neutral200}`,
        borderRadius: '8px',
        boxShadow: '0px 1px 2px 0px #1018281F',
      }}
    >
      <Flex width="100%" flexDirection="row">
        <Flex
          width="85%"
          paddingBottom="16px"
          color={ColorPallete.black}
          fontSize={Typography.TextLarge}
          justifyContent="start"
        >
          Top Performing Content
        </Flex>
      </Flex>

      {loading && <Skeleton height={192} baseColor={ColorPallete.neutral100} />}

      {!loading && topPerformingContentError && (
        <ErrorDisplay height="192px" onClick={fetchTopContent} />
      )}

      {!loading && !topPerformingContentError && isEmpty(data) && (
        <EmptyState
          height="192px"
          title="No Analytics Data"
          subTitle="Send out campaigns to get insights"
        />
      )}

      {!loading && !topPerformingContentError && !isEmpty(data) && (
        <Carousel
          showThumbs={false}
          showIndicators={false}
          showArrows={false}
          showStatus={false}
          centerMode={false}
          renderArrowNext={(clickHandler, hasNext) => {
            return (
              <Flex
                style={{
                  position: 'absolute',
                  cursor: 'pointer',
                  top: '-38px',
                  right: '5px',
                }}
                onClick={clickHandler}
              >
                <img
                  alt=""
                  src="/assets/images/Analytics/right-arrow.svg"
                  style={{ height: '24px', cursor: 'pointer' }}
                />
              </Flex>
            );
          }}
          renderArrowPrev={(clickHandler, hasPrev) => {
            return (
              <Flex
                style={{
                  position: 'absolute',
                  cursor: 'pointer',
                  top: '-38px',
                  right: '22px',
                }}
                onClick={clickHandler}
              >
                <img
                  alt=""
                  src="/assets/images/Analytics/left-arrow.svg"
                  style={{ height: '24px', cursor: 'pointer' }}
                />
              </Flex>
            );
          }}
        >
          {data.map((item, index, arr) => (
            <Flex
              flex={'1 0 auto'}
              width="70%"
              height="192px"
              backgroundColor={ColorPallete.neutral50}
              mr="5px"
              style={{
                border: `1px solid ${ColorPallete.neutral200}`,
                borderRadius: '8px',
                cursor: 'default',
              }}
            >
              <Flex
                width="22%"
                justifyContent="center"
                height="190px"
                marginRight="12px"
              >
                <img
                  src={getMediaImage(item.media_url)}
                  alt=""
                  onError={onImageError}
                  style={{
                    objectFit: 'cover',
                    borderTopLeftRadius: '8px',
                    borderBottomLeftRadius: '8px',
                  }}
                />
              </Flex>
              <Flex
                width="78%"
                padding="10px 20px 10px 5px"
                height="215px"
                flexDirection="column"
              >
                {item?.title && item?.title?.length > 80 ? (
                  <Tooltip title={item?.title} arrow placement="top">
                    <Flex
                      width="100%"
                      padding="5px 5px 0px"
                      fontSize={Typography.TextLarge}
                      color={ColorPallete.black}
                      style={{ textAlign: 'left', whiteSpace: 'nowrap' }}
                    >
                      {`${item?.title.substring(0, 80)}...`}
                    </Flex>
                  </Tooltip>
                ) : (
                  <Flex
                    width="100%"
                    padding="5px 5px 0px"
                    fontSize={Typography.TextLarge}
                    color={ColorPallete.black}
                    style={{ textAlign: 'left' }}
                  >
                    {item?.title}
                  </Flex>
                )}
                {item?.description && item?.description?.length > 220 ? (
                  <Tooltip title={item?.title} arrow placement="top">
                    <Flex
                      width="100%"
                      height="50px"
                      padding="0px 5px 5px"
                      fontSize={Typography.TextSmall}
                      color={ColorPallete.neutral500}
                      style={{ textAlign: 'left' }}
                    >
                      {`${item?.description.substring(0, 220)}...`}
                    </Flex>
                  </Tooltip>
                ) : (
                  <Flex
                    width="100%"
                    height="50px"
                    padding="0px 5px 5px"
                    fontSize={Typography.TextSmall}
                    color={ColorPallete.neutral500}
                    style={{ textAlign: 'left' }}
                  >
                    {item?.description}
                  </Flex>
                )}

                <Flex
                  width="100%"
                  height="42px"
                  padding="0px 5px 5px 0px"
                  marginBottom="28px"
                  style={{ flexDirection: 'row', flexWrap: 'wrap' }}
                >
                  {!isEmpty(item) &&
                    item?.tags?.slice(0, 4).map((tag, index) => (
                      <TopicChipStyle
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          fontSize: '12px',
                          height: '26px',
                        }}
                        key={index}
                      >
                        {tag?.name}
                      </TopicChipStyle>
                    ))}
                </Flex>
                <Flex>
                  <ProgressBar
                    label="Engagement Score"
                    pb="2px"
                    value={item?.engagement_score}
                    tooltip
                    tooltipText="The content engagement score is calculated by taking average of 
                    the number of clicks and leads captured for a specific piece of content. One click = a score of 70, 
                    and additional lead capture = a score of 30. We will consider each contacts engagement with a 
                    specific piece of content individually and use the average of all contact scores in a given 
                    distribution to display the overall content engagement result."
                  />
                </Flex>
              </Flex>
            </Flex>
          ))}
        </Carousel>
      )}

      {/* <CarouselWrapper
        width="100%"
        flexDirection="row"
        id="carousel"
        style={{
          overflow: 'hidden',
          scrollBehavior: 'smooth',
        }}
      >
        {data.slice(slideIndex).map((item, index, arr) => (
          <CarouselItem
            backgroundColor={ColorPallete.neutral50}
            style={{ border: '1px solid #E4E7EC', borderRadius: '8px' }}
            width="60%"
            height="215px"
            margin="0px 16px 0px 16px"
            flexDirection="row"
            flex={'1 0 auto'}
          >
            <Flex width="30%" justifyContent="center" height="215px">
              <img
                src={item.image}
                alt=""
                style={{
                  height: '213px',
                  width: '230px',
                  objectFit: 'cover',
                  borderTopLeftRadius: '8px',
                  borderBottomLeftRadius: '8px',
                  marginRight: '13px',
                }}
              />
            </Flex>
            <Flex
              width="70%"
              padding="10px 20px 10px 5px"
              height="215px"
              flexDirection="column"
            >
              <Flex
                width="100%"
                padding="5px"
                fontSize={Typography.TextLarge}
                color={ColorPallete.black}
                style={{ textAlign: 'left' }}
              >
                {item.name}
              </Flex>
              <Flex
                width="100%"
                padding="5px"
                fontSize={Typography.TextSmall}
                color={ColorPallete.neutral500}
                style={{ textAlign: 'left' }}
              >
                {item.subText}
              </Flex>
              <Flex
                width="100%"
                padding="5px"
                marginBottom="25px"
                style={{ flexDirection: 'row', flexWrap: 'wrap' }}
              >
                {TopicDataForMapping.map((item, index) => {
                  return (
                    <TopicChipStyle
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        fontSize: '12px',
                      }}
                      key={index}
                    >
                      {item.name}
                    </TopicChipStyle>
                  );
                })}
              </Flex>
              <Flex>
                <ProgressBar value={item.score} />
              </Flex>
            </Flex>
          </CarouselItem>
        ))}
      </CarouselWrapper> */}
    </TopCampaignWrapper>
  );
};

export default TopPerformingContent;
