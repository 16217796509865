import styled from 'styled-components';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';

export const ToggleContainer = styled(ToggleButtonGroup)`
    font-family: Poppins !important;
    margin: ${({ margin }) => margin ? margin : '0 0 20px'};
    width: 100%;
    height: 40px;
`;

export const ToggleButtonStyle = styled(ToggleButton)`
    font-family: Poppins !important;
    text-transform: none !important;
    transition: 0.5s;
    font-size: 14px;
    font-weight: 500;
    padding: 0 12px !important;
    white-space: nowrap;
    background: ${({ activeToggle }) => activeToggle ? '#816EF8 !important' : 'white !important'};
    color: ${({ activeToggle }) => activeToggle ? 'white !important' : '#101828 !important'};
    border: ${({ activeToggle }) => activeToggle ? '1px solid #816EF8 !important' : '1px solid #E4E7EC !important'};
    :hover {
        color: #fff !important;
        background: #5946ea !important;
        border: 1px solid #816EF8 !important;
    };
`;
