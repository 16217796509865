import { Flex } from '@rebass/grid';
import React from 'react';
import { VerticalSeparator } from '../DripDashboardListItem/DripDashboardListItem.styles';
import { isEmpty } from 'lodash';

const CampaignEngagementStatus = ({ engagement_status, engagementDetails }) => {
  console.log('engagementDetails', engagementDetails);

  let data = [
    ...(engagementDetails && engagementDetails.paused
      ? [{ title: 'Paused Campaigns', value: engagementDetails.paused }]
      : []),
    ...(engagementDetails && engagementDetails.cancelled
      ? [{ title: 'Cancelled Campaigns', value: engagementDetails.cancelled }]
      : []),
  ];

  return (
    <Flex
      flexDirection="column"
      justifyContent="center"
      alignItems="flex-start"
      //   marginLeft="15px"
      marginBottom="5px"
      width={'35%'}
      style={{
        paddingRight: '10px',
        wordBreak: 'break-word',
        width: '37%',
      }}
      mt="30px"
    >
      <Flex justifyContent="center" alignItems="center">
        {!isEmpty(data) &&
          data.map((ele, index) => {
            console.log('ele', ele);
            return (
              <>
                <Flex
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="flex-start"
                  width={'140px'}
                >
                  <span
                    style={{
                      color: '#4E6780',
                      fontSize: '12px',
                      marginTop: '',
                    }}
                  >
                    {ele.title}
                  </span>
                  <div
                    style={{
                      flexDirection: 'row',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <span
                      data-testid="campaign-status"
                      style={{
                        color: '#000000',
                        fontSize: '13px',
                        fontWeight: '600',
                        //   marginLeft: ele === 'type' ? '15px' : '',
                      }}
                      id={`campaign-${ele}`}
                    >
                      {ele.value}
                    </span>
                  </div>
                </Flex>
                {index !== data.length - 1 && <VerticalSeparator />}
              </>
            );
          })}
      </Flex>
    </Flex>
  );
};

export default CampaignEngagementStatus;
