import { IconButton } from '@mui/material'
import React from 'react';
import RadioActive from './images/Radio-Active.svg';
import RadioInactive from './images/Radio-Inactive.svg';
import { ColorPallete } from '../../utils';

const RadioButton = ({
    onClick,
    size,
    checked,
    label
}) => {
    return (
        <IconButton sx={{ height: 18, width: 'auto', fontFamily: 'Poppins !important' }} onClick={onClick}>
            <img
                style={size && { height: size, width: size }}
                src={checked ? RadioActive : RadioInactive}
                alt="checkBox"
            />
            <span style={{
                color: ColorPallete.neutral900,
                fontSize: '14px',
                padding: '0px 0px 0px 10px',
            }}>
                {label}
            </span>
            
        </IconButton>
    )
}

export default RadioButton