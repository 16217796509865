import { ColorPallete } from "src/utils";
import styled from "styled-components";

export const BeeInput = styled.input`
    width: 100%;
    padding: 10px;
    height: 40px;
    border-radius: 4px;
    border: 1px solid ${ColorPallete.neutral200};
    box-shadow: 1px 2px 2px 0px #1018281F inset;
    font-family: 'Poppins';
    font-size: 14px;

    ::placeholder {
        font-family: 'Poppins';
        font-size: 14px;
    }
`;