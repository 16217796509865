import React from 'react';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import {
  EmailDataInfo,
  EmailDataInfoContainer,
  EmailDataWrapper,
  EmailHeader,
} from './styles';
import Skeleton from './Skeleton';

function EmailData({ emailData = {}, isLoading = false }) {
  const emailDataItems = [
    {
      label: 'Unique Contacts',
      value: emailData?.contacts ?? '-',
    },
    {
      label: 'Unique Opens',
      value: emailData?.opens ?? '-',
    },
    {
      label: 'Unique Clicks',
      value: emailData?.clicks ?? '-',
    },
    // {
    //   label: 'Unique Bounces',
    //   value: emailData?.bounces ?? '-',
    // },
  ];

  if (isLoading) return <Skeleton />;

  return (
    <EmailDataWrapper>
      <EmailHeader>
        <EmailOutlinedIcon />
        <h2>Email</h2>
      </EmailHeader>
      <EmailDataInfoContainer>
        {emailDataItems.map((emailData) => (
          <EmailDataInfo>
            <p className="label">{emailData?.label ?? ''}</p>
            <p className="value">{emailData?.value ?? '-'}</p>
          </EmailDataInfo>
        ))}
      </EmailDataInfoContainer>
    </EmailDataWrapper>
  );
}

export default EmailData;
