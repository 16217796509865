import styled from 'styled-components';
import { Flex } from '@rebass/grid';

export const LoaderFlex = styled(Flex)`
  @media only screen and (min-width: 1400px) {
    zoom: 0.76;
  }
  @media only screen and (min-width: 1500px) {
    zoom: 0.76;
  }
`;
