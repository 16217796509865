/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Flex } from '@rebass/grid';
import React, { useState, useRef, useEffect } from 'react';
import { Line, WhiteCircle } from '../../../components/StepperV2/Stepper.styles';
import { get, isEmpty } from 'lodash';

import { Button, LoaderWrapper } from '../../../components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faPlus } from '@fortawesome/free-solid-svg-icons';
import { SuggestedBlock } from './CampaignCreateSteps.styles';
import { useHistory } from 'react-router-dom';
import StepsFooterV2 from './StepsFooterV2';
import StepTwoHeadSection from './StepTwoHeadSection';
import BlockCard from './BlockCard';
import { AUTOMATED_CAMPAIGN_ACTIONS } from '../AutomatedCampaignsContainer';
import styled from 'styled-components';
import {
  AutomatedCloneCampaign,
  deleteCampaignBlock,
  getAutomatedCampaignBlocks,
  postAddBlock,
} from '../../../services/automated-campaigns';
import ReactSelect from 'react-select';
import ConfirmationModal from '../../../components/ConfirmationModal/ConfirmationModal';
import { updateCampaignBlock } from '../../../services/automated-campaigns';
import { updateAutomatedCampaign } from '../../../services/automated-campaigns';
import { convertDateToRequiredFormat, timeZoneAbbreviated } from '../../../utils';
import AutomatedCampaignEmailPreviewModal from './AutomatedCampaignEmailPreviewModal';
import AutomatedCampaignSocialPreviewModal from './AutomatedCampaignSocialPreviewModal';
import DatePickerV2 from '../../../components/DatePickerV2/DatePickerV2';


const getSelectStyles = () => ({
  container: (base) => ({
    ...base,
    width: '115px',
    margin: '0px',
  }),
  control: (base) => ({
    ...base,
    height: '40px',
    minHeight: '40px',
    border: '1px solid #8493A6',
  }),
  dropdownIndicator: (base) => ({
    ...base,
    paddingTop: 0,
    paddingBottom: 0,
    color: '#848484',
  }),
});

const DateTimeWrapper = styled(Flex)`
  background-color: hsl(0, 0%, 100%);
  border-color: hsl(0, 0%, 80%);
  border-radius: 4px;
  margin: 0 auto;
  padding: 2px 8px;
`;

const DateTimeText = styled.div`
  width: 85%;
  font-size: 0.9rem;
  display: flex;
  align-items: center;
  justify-content: center;
  &:empty:before {
    content: attr(data-placeholder);
    color: gray;
  }
`;

const CustomizeCampaignV2 = ({
  campaignAction,
  userData,
  automatedCampaignId,
  currentStepIndex,
  setCurrentStepIndex,
  campaignSetState,
  automatedCampaignDetails,
  endCreateFlow,
  automatedCampaignTargetInfo,
}) => {
  const [preConversionBlocks, setPreConversionBlocks] = useState([]);
  const [postConversionBlocks, setPostConversionBlocks] = useState([]);
  const [onConversionBlocks, setOnConversionBlocks] = useState([]);
  const [fetchingBlocks, setFetchingBlocks] = useState(false);
  const [blockLength, setBlockLength] = useState(0);

  const [showDropdown, setShowDropdown] = useState(false);
  const history = useHistory();
  const dropdownRef = useRef(null);
  const [isDatepickerOpen, setIsDatepickerOpen] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openCloneModal, setOpenCloneModal] = useState(false);
  const [requestLoading, setRequestLoading] = useState(false);
  const [currentSelectedBlock, setCurrentSelectedBlock] = useState();
  const [selectedId, setSelectedId] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [showCloseButton, setShowCloseButton] = useState(true);

  const [openPreviewModal, setOpenPreviewModal] = useState(false);
  const [selectedBlock, setSelectedBlock] = useState(false);
  const [openSocialPreviewModal, setOpenSocialPreviewModal] = useState(false);

  const showPreviewModal = () => setOpenPreviewModal(true);
  const closePreviewModal = () => setOpenPreviewModal(false);

  const showSocialPreviewModal = () => setOpenSocialPreviewModal(true);
  const closeSocialPreviewModal = () => setOpenSocialPreviewModal(false);

  const showDeleteModal = () => setOpenDeleteModal(true);
  const closeDeleteModal = () => setOpenDeleteModal(false);

  const showcloneModal = () => setOpenCloneModal(true);
  const closeCloneModal = () => setOpenCloneModal(false);
  const [waitBlockLoader, setWaitBlockLoader] = useState(false);
  const [waitTimeLoader, setWaitTimeLoader] = useState(false); 

  const getAllowedWaitTimes = () => {
    let ARRAY_SIZE_TO_RETURN = 181;

    return Array(ARRAY_SIZE_TO_RETURN)
      .fill('')
      .map((num, i) => ({
        label: `${i} Days`,
        value: `${i} Days`,
      }));
  };

 

  const getTimeBlocks = () => {
    let interval = 15;
    let times = [];
    let tt = 0;
    let ap = ['AM', 'PM'];

    for (let i = 0; tt < 24 * 60; i++) {
      let hour = Math.floor(tt / 60); // getting hours of day in 0-24 format
      let minute = tt % 60; // getting minutes of the hour in 0-55 format
      times[i] = `${
        hour === 0 || hour === 12 ? '12' : (hour % 12).toString().slice(-2)
      }:${('0' + minute).slice(-2)} ${ap[hour < 12 ? 0 : 1]}`;
      tt = tt + interval;
    }

    return times.map((item, i) => ({
      label: item,
      value: item,
    }));
  };

  const getWaitBlockValue = (block) => {
    return {
      label: block.split('wait ')[1],
      value: block.split('wait ')[1],
    };
  };

  const updateWaitBlock = (newValue, item, type) => {
    if(type === 'wait') {
      setWaitBlockLoader(true);
    } else {
      setWaitTimeLoader(true);
    }
    setSelectedId(item.id);
    let payload = {
      ...(type === 'wait' && {
        b_details: `wait ${newValue.value}`,
      }),
      ...(type === 'time' && {
        time_str: newValue.value,
      }),
    };
    updateCampaignBlock({
      campaignId: automatedCampaignId,
      blockId: item.id,
      payload,
    })
      .then((res) => {
        fetchBlocks();
      })
      .catch((err) => {
        setFetchingBlocks(false);
      });
  };

  useEffect(() => {
    setFetchingBlocks(true);
    fetchBlocks();
  }, []);

  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownRef]);

  console.log(selectedBlock);

  const createNewBlocks = (type, platform) => {
    setFetchingBlocks(true);
    postAddBlock({
      id: automatedCampaignId,
      payload: {
        type,
        platform,
      },
    })
      .then((res) => {
        fetchBlocks();
      })
      .catch((err) => {
        setFetchingBlocks(false);
      });
  };

  const fetchBlocks = () => {
    getAutomatedCampaignBlocks(automatedCampaignId)
      .then((res) => {
        setBlockLength(
          res.data.filter((b) => b.b_action === 'send_campaign').length
        );
        setPreConversionBlocks(
          res.data
            .sort((b, a) => b.b_index - a.b_index)
            .filter((b) => b.b_type === 'pre_conversion')
        );
        setOnConversionBlocks(
          res.data
            .sort((b, a) => b.b_index - a.b_index)
            .filter((b) => b.b_type === 'on_conversion')
        );
        setPostConversionBlocks(
          res.data
            .sort((b, a) => b.b_index - a.b_index)
            .filter((b) => b.b_type === 'post_conversion')
        );
        setFetchingBlocks(false);
        setWaitBlockLoader(false);
        setWaitTimeLoader(false);
        setSelectedId('');
      })
      .catch((err) => {
        setFetchingBlocks(false);
        setWaitBlockLoader(false);
        setWaitTimeLoader(false);
        setSelectedId('');
      });
  };

  const changeEventBlockTime = (date, block) => {
    console.log('date and block-', date, block);
    setIsLoading(true);
    let b_details;
    const { targetInfo, campaignId } = automatedCampaignDetails;
    let eventDate = new Date(targetInfo?.event_date);
    let offsetTime = eventDate.getTimezoneOffset() * 60000;
    eventDate.setTime(eventDate.getTime() + offsetTime);
    console.log(eventDate);
    eventDate = new Date(eventDate.getFullYear(), eventDate.getMonth(), eventDate.getDate());
    let scheduledDate = new Date(date);
    scheduledDate = new Date(scheduledDate.getFullYear(), scheduledDate.getMonth(), scheduledDate.getDate());

    console.log(eventDate, scheduledDate);
    if(scheduledDate.getTime() > eventDate.getTime()) {
      let diffTime = Math.abs(scheduledDate - eventDate);
      let diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      b_details = diffDays > 1 ? `${diffDays} days after` : `${diffDays} day after`;
    } else if(scheduledDate.getTime() < eventDate.getTime()) {
      let diffTime = Math.abs(eventDate - scheduledDate);
      let diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      console.log(diffDays + " days");
      b_details = diffDays > 1 ? `${diffDays} days prior` : `${diffDays} day prior`;
    } else {
      b_details = '0 day after'
    }

    updateCampaignBlock({
      campaignId: campaignId,
      blockId: block.id,
      payload: {
        b_details,
        schedule_time: date,
      },
    })
      .then((res) => {
        setFetchingBlocks(true);
        setIsLoading(false);
        setIsDatepickerOpen(false);
        fetchBlocks();
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
        setIsDatepickerOpen(false);
      });
  };

  const getMaxEventDate = () => {
    let maxEventDate;
    if (automatedCampaignTargetInfo.event_date) {
      maxEventDate = new Date(automatedCampaignTargetInfo.event_date);
      maxEventDate = new Date(maxEventDate.getTime() + Math.abs(maxEventDate.getTimezoneOffset()*60000))
      maxEventDate.setDate(maxEventDate.getDate() - 1);
    }

    return maxEventDate;
  };

  const handleSocialEmailBlockEntry = (blockId, platform, item) => {
    console.log(blockId, platform);
    if (platform === 'social') {
      campaignSetState({
        automatedCampaigns: {
          ...automatedCampaignDetails,
          activeStepIndex: 1,
        },
        selectedBlockId: blockId,
      });
      history.push(`/create-social/${blockId}/${automatedCampaignId}`);
    } else {
      campaignSetState({
        automatedCampaigns: {
          ...automatedCampaignDetails,
          activeStepIndex: 1,
        },
        selectedBlockId: blockId,
        fromCreateEmail: true,
        selectedBlock: item,
      });
      history.push(`/create-email/${blockId}/${automatedCampaignId}`);
    }
  };

  const preConversionWelcomeAndNurtureBlocks = () => {
    return (
      <Flex
        margin="15px 0px 0px"
        alignItems="center"
        width="100%"
        style={{ position: 'relative' }}
      >
        <Flex display="flex" flexDirection="column" width="63%">
          {!isEmpty(preConversionBlocks) &&
            preConversionBlocks
              .filter((b) => b.b_action === 'send_campaign')
              .map((item) => (
                <Flex width="100%">
                  <Flex width="10%" flexDirection="column">
                    <WhiteCircle
                      background="#E4E7EC"
                      height="16px"
                      width="16px"
                      style={{ zIndex: 5, border: 'none' }}
                    />
                    <Line style={{ left: '6px', top: '5px' }} />
                  </Flex>
                  <Flex width="85%" flexDirection="column" marginBottom="20px">
                    <BlockCard
                      campaignAction={campaignAction}
                      item={item}
                      currentStepIndex={currentStepIndex}
                      onButtonClick={handleSocialEmailBlockEntry}
                      onDeleteClick={handleOpenDeleteModal}
                      onCloneClick={handleOpenCloneModal}
                      blockLength={blockLength}
                      onPreviewClick={handleOpenPreviewModal}
                      onSocialPreviewClick={handleOpenSocialPreviewModal}
                    />
                  </Flex>
                  <Flex width="3%" alignItems="center">
                    <Line border="1px solid #E4E7EC" style={{ top: '2px' }} />
                  </Flex>
                </Flex>
              ))}
        </Flex>
        <Flex display="flex" flexDirection="column" width="48%">
          {!isEmpty(preConversionBlocks) &&
            preConversionBlocks
              .filter((b) => b.b_action !== 'send_campaign')
              .map((item, index) => (
                <Flex width="100%" marginBottom="20px">
                  <Flex width="100%" flexDirection="column">
                    <Flex>
                      <Flex width="30%" flexDirection="column">
                        <Flex color="#101828" fontSize="14px" width="100%">
                          Set Wait Period
                        </Flex>
                        <Flex
                          padding="10px 0px"
                          color="#101828"
                          fontSize="14px"
                          width="100%"
                          display="flex"
                        >
                          <LoaderWrapper isLoading={selectedId === item.id && waitBlockLoader}>
                            <ReactSelect
                              onChange={(newValue) =>
                                updateWaitBlock(newValue, item, 'wait')
                              }
                              options={getAllowedWaitTimes()}
                              placeholder="days"
                              styles={getSelectStyles()}
                              value={getWaitBlockValue(item?.b_details)}
                            />
                          </LoaderWrapper>

                        </Flex>
                      </Flex>
                      {getWaitBlockValue(item?.b_details) !== 'O Days' && (
                        <Flex width="30%" flexDirection="column">
                          <Flex color="#101828" fontSize="14px" width="100%">
                            Set time
                          </Flex>
                          <Flex
                            padding="10px 0px"
                            color="#101828"
                            fontSize="14px"
                            width="100%"
                            display="flex"
                          >
                            <LoaderWrapper isLoading={selectedId === item.id && waitTimeLoader}>
                              <ReactSelect
                                onChange={(newValue) =>
                                  updateWaitBlock(newValue, item, 'time')
                                }
                                options={getTimeBlocks()}
                                placeholder="hh:mm"
                                styles={getSelectStyles()}
                                value={{
                                  label: item.time_str,
                                  value: item.time_str,
                                }}
                              />
                            </LoaderWrapper>

                          </Flex>
                        </Flex>
                      )}
                      <Flex alignItems="center" mt="12px" color="#101828">
                        {' (' + timeZoneAbbreviated(get(
                                    userData,
                                    'details.user.timezone',
                                    ''
                        )) + ')'}
                      </Flex>
                    
                      {/* <Flex width="25%" flexDirection="column">
                        <Flex color="#101828" fontSize="14px" width="100%">
                          Set time
                        </Flex>
                        <Flex
                          padding="10px 0px"
                          color="#101828"
                          fontSize="14px"
                          width="100%%"
                        >
                          <TimeInput
                            style={{
                              color: '#101828',
                              cursor: 'pointer',
                            }}
                            type="time"
                          />
                        </Flex>
                      </Flex> */}
                    </Flex>
                    <Flex
                      width="100%"
                      fontSize="12px"
                      color="#667085"
                      style={{ textAlign: 'initial' }}
                      mt="20px"
                    >
                      {index === 0 ? 'The wait period above represents the number of days after a new contact enters the campaign.' :  'The wait period above represents the number of days after the previously scheduled email.'}
                    </Flex>
                  </Flex>
                </Flex>
              ))}
        </Flex>
        {/* TODO will come back to this */}
      </Flex>
    );
  };

  const preConversionEventBlocks = () => {
    return (
      <Flex
        margin="15px 0px 0px"
        alignItems="center"
        width="100%"
        style={{ position: 'relative' }}
      >
        <Flex display="flex" flexDirection="column" width="63%">
          {!isEmpty(preConversionBlocks) &&
            preConversionBlocks
              .filter((b) => b.b_action === 'send_campaign')
              .map((item) => (
                <Flex width="100%">
                  <Flex width="10%" flexDirection="column">
                    <WhiteCircle
                      background="#E4E7EC"
                      height="16px"
                      width="16px"
                      style={{ zIndex: 5, border: 'none' }}
                    />
                    <Line style={{ left: '6px', top: '5px' }} />
                  </Flex>
                  <Flex width="85%" flexDirection="column" marginBottom="20px">
                    <BlockCard
                      campaignAction={campaignAction}
                      item={item}
                      currentStepIndex={currentStepIndex}
                      onButtonClick={handleSocialEmailBlockEntry}
                      onDeleteClick={handleOpenDeleteModal}
                      onCloneClick={handleOpenCloneModal}
                      blockLength={blockLength}
                      onPreviewClick={handleOpenPreviewModal}
                      onSocialPreviewClick={handleOpenSocialPreviewModal}
                    />
                  </Flex>
                  <Flex width="3%" alignItems="center">
                    <Line border="1px solid #E4E7EC" style={{ top: '2px' }} />
                  </Flex>
                </Flex>
              ))}
        </Flex>
        <Flex display="flex" flexDirection="column" width="45%">
          {!isEmpty(preConversionBlocks) &&
            preConversionBlocks
              .filter((b) => b.b_action !== 'send_campaign')
              .map((item) => (
                <Flex width="100%" marginBottom="20px" height="140px">
                  <Flex width="100%" flexDirection="column">
                    <Flex>
                      <Flex width="30%" flexDirection="column">
                        <Flex color="#101828" fontSize="14px" width="100%">
                          Select Days Prior
                        </Flex>
                        <Flex
                          padding="10px 0px"
                          color="#101828"
                          fontSize="14px"
                          width="100%"
                          display="flex"
                        >
                          {item?.b_details}
                        </Flex>
                      </Flex>
                      <Box width={'35%'}>
                        {/* <Box width={'49%'}> */}
                        <Box
                          className="field__title"
                          color="#101828"
                          fontSize="14px"
                          alignItems="center"
                        >
                          <Box display="flex">Set Date & Time</Box>
                          <Box
                            width="166%"
                            mt="8px"
                            style={{
                              border: '1px solid #E4E7EC',
                              borderRadius: '4px',
                              height: '35px',
                              boxShadow:
                                '1px 2px 2px 0px rgba(16, 24, 40, 0.12) inset',
                            }}
                          >
                            <DateTimeWrapper
                              style={{
                                padding: '2px 5px',
                                height: '32px',
                              }}
                            >
                              <DateTimeText
                                style={{
                                  width: '86%',
                                  display: 'flex',
                                  justifyContent: 'flex-start',
                                  paddingLeft: '8px',
                                }}
                                contenteditable="true"
                                data-placeholder="MM/DD/YYYY"
                              >
                                {item?.schedule_time &&
                                  convertDateToRequiredFormat({
                                    date: item?.schedule_time,
                                    format: ('MM/DD/YYYY, h:mm a'),
                                  }) + ' (' + timeZoneAbbreviated(get(
                                    userData,
                                    'details.user.timezone',
                                    ''
                                  )) + ')'} 
                            </DateTimeText>
                              <img
                                alt="date"
                                src="/assets/images/Campaign/date&time-picker-icon.svg"
                                style={{ cursor: 'pointer' }}
                                onClick={() => {
                                  console.log('clicked');
                                  setIsDatepickerOpen(true);
                                  setSelectedId(item.id);
                                }}
                              />
                              {isDatepickerOpen && item.id === selectedId && (
                                <DatePickerV2
                                  isOpen={isDatepickerOpen}
                                  customInput={<div />}
                                  minDate={new Date()}
                                  onDateClose={() => {
                                    setIsDatepickerOpen(false);
                                    setSelectedId('');
                                  }}
                                  onSubmit={(date) => {
                                    changeEventBlockTime(date, item);
                                    setSelectedId('');
                                  }}
                                  timeZone={get(
                                    userData,
                                    'details.user.timezone',
                                    ''
                                  )}
                                  scheduleValue={item.schedule_time}
                                  isFromAC
                                  maxDate={getMaxEventDate()}
                                  loading={isLoading}
                                  hourAndMins
                                />
                              )}
                            </DateTimeWrapper>
                          </Box>
                        </Box>
                      </Box>
                    </Flex>

                    <Flex
                      width="100%"
                      fontSize="12px"
                      color="#667085"
                      style={{ textAlign: 'initial' }}
                      mt="20px"
                    >
                      This campaign will be initiated based on the selected wait
                      period after the activation date.
                    </Flex>
                  </Flex>
                </Flex>
              ))}
        </Flex>
        {/* TODO will come back to this */}
      </Flex>
    );
  };

  const handleOpenDeleteModal = (blocksId) => {
    console.log('current block id-->', currentSelectedBlock, blocksId);
    setCurrentSelectedBlock(blocksId);
    showDeleteModal();
  };

  const handleOpenCloneModal = (blocksId) => {
    setCurrentSelectedBlock(blocksId);
    showcloneModal();
  };

  const campaignBlockDeleteHandler = () => {
    const payload = {
      campaignId: automatedCampaignId,
      block_ids: [currentSelectedBlock],
    };
    console.log('payload--->', payload);
    if (currentSelectedBlock) setFetchingBlocks(true);
    deleteCampaignBlock(payload)
      .then((res) => {
        console.log('res--->', res);
        fetchBlocks();
        setOpenDeleteModal(false);
      })
      .catch((err) => {
        setOpenDeleteModal(false);
        setFetchingBlocks(false);
        console.log('err--->', err);
        
      });
  };

  const campaignBlockCloneHandler = () => {
    console.log('clone clicked');
    const payload = {
      campaignId: automatedCampaignId,
      block_ids: currentSelectedBlock,
    };
    console.log('payload--->', payload);
    if (currentSelectedBlock) {
      setFetchingBlocks(true);
      AutomatedCloneCampaign(payload)
        .then((res) => {
          console.log('clone');
          fetchBlocks();
          setOpenCloneModal(false);
        })
        .catch((error) => {
          setOpenCloneModal(false);
          setFetchingBlocks(false);
          console.log('copy error---->', error);
        });
    }
  };

  const handleValidation = () => {
    let isNotValid = false;

    let blocks = [...preConversionBlocks, ...onConversionBlocks, ...postConversionBlocks];

    if (
      campaignAction === AUTOMATED_CAMPAIGN_ACTIONS.WELCOME_CLIENTS ||
      campaignAction === AUTOMATED_CAMPAIGN_ACTIONS.NURTURE_PROSPECTS
    ) {
      if (!automatedCampaignTargetInfo.start_date) {
        if(!isNotValid) {
          isNotValid = true;
        }
        
      }
      
      let sendCampaignBlocks = blocks.filter(
        (b) => b.b_action === 'send_campaign'
      );

      let waitBlocks = blocks.filter((b) => b.b_action === 'wait_for_duration');

      waitBlocks.forEach((item) => {
        if(!item.time_str || isEmpty(item?.time_str)) {
          if(!isNotValid) {
            isNotValid = true;
          }
        }
      })
  
      sendCampaignBlocks.forEach((item) => {
        if (!item.newsletter_id) {
          if(!isNotValid) {
            isNotValid = true;
          }
        }
      });
    }

    if(campaignAction === AUTOMATED_CAMPAIGN_ACTIONS.EVENT) {
      const sendEmailCampaignBlocks = blocks.filter(
        (b) => b.b_action === 'send_campaign' && b.platform === 'email'
      );

      if(!isEmpty(sendEmailCampaignBlocks))
        sendEmailCampaignBlocks.forEach((item) => {
          if (!item.newsletter_id) {
            if(!isNotValid) {
              isNotValid = true;
            }
          }
        });

      const sendSocialCampaignBlocks = blocks.filter(
        (b) => b.b_action === 'send_campaign' && b.platform === 'social'
      );

      if(!isEmpty(sendSocialCampaignBlocks))
        sendSocialCampaignBlocks.forEach((item) => {
          if (!item.articles || isEmpty(item.articles)) {
            if(!isNotValid) {
              isNotValid = true;
            }
          }
        });
      
    }

    return isNotValid;
  };

  const handleNextClick = () => {
    console.log('clicked');
    if (!isEmpty(preConversionBlocks)) {
      setCurrentStepIndex(currentStepIndex + 1);
    }
  };

  const handleStartDate = (date) => {
    let payload = {
      start_date: convertDateToRequiredFormat({
        date,
      }),
    };
    updateAutomatedCampaign({
      campaignId: automatedCampaignId,
      payload,
    });

    campaignSetState({
      automatedCampaigns: {
        ...automatedCampaignDetails,
        targetInfo: {
          ...automatedCampaignTargetInfo,
          start_date: convertDateToRequiredFormat({
            date,
            format: 'MM/DD/YYYY',
          }),
        },
      },
    });
  };

  const handleOpenPreviewModal = (block) => {
    setSelectedBlock(block);
    showPreviewModal();
  };

  const handleOpenSocialPreviewModal = (block) => {
    setSelectedBlock(block);
    showSocialPreviewModal();
  };

  return (
    <LoaderWrapper isLoading={fetchingBlocks}>
      <Flex width="80%" margin="0px auto 100px" flexDirection="column">
        <Flex width="100%" alignItems="center">
          <WhiteCircle height="40px" width="40px" background="#816EF8">
            <span
              style={{
                color: '#fff',
                fontSize: '16px',
                position: 'absolute',
                top: '6px',
                left: '0px',
                width: '100%',
              }}
            >
              2
            </span>
          </WhiteCircle>
          <Flex fontSize="20px" color="#101828" style={{ fontWeight: 500 }}>
            Create Campaign Journey
          </Flex>
        </Flex>
        <hr style={{ width: '100%', border: '1px solid #E4E7EC' }}></hr>
        {/* Pre Conversion Blocks Start */}
        <Flex width="100%">
          <Flex width="100%" flexDirection="column">
            <StepTwoHeadSection
              campaignAction={campaignAction}
              handleStartDate={handleStartDate}
              automatedCampaignTargetInfo={automatedCampaignTargetInfo}
            />
            {(campaignAction === AUTOMATED_CAMPAIGN_ACTIONS.WELCOME_CLIENTS ||
              campaignAction ===
                AUTOMATED_CAMPAIGN_ACTIONS.NURTURE_PROSPECTS) &&
              !isEmpty(preConversionBlocks) &&
              preConversionWelcomeAndNurtureBlocks()}

            {campaignAction === AUTOMATED_CAMPAIGN_ACTIONS.EVENT &&
              !isEmpty(preConversionBlocks) &&
              preConversionEventBlocks()}

            {/* Create Blocks  */}
            {campaignAction === AUTOMATED_CAMPAIGN_ACTIONS.EVENT && (
              <Flex
                pb="100px"
                alignItems="center"
                width="100%"
                style={{ position: 'relative' }}
              >
                <Flex width="6%" flexDirection="column">
                  <WhiteCircle
                    background="#E4E7EC"
                    height="16px"
                    width="16px"
                    style={{ zIndex: 5, border: 'none' }}
                  />
                  <Line
                    border="3px dashed #E4E7EC"
                    style={{ left: '6px', top: '5px' }}
                  />
                </Flex>
                <Flex width="95%" style={{ position: 'relative' }}>
                  <Button
                    borderColor="#E4E7EC"
                    color="#fff"
                    bgColor="#816EF8"
                    margin="20px 0px 0px"
                    onClick={() => setShowDropdown(!showDropdown)}
                  >
                    <Flex
                      justifyContent="space-between"
                      width="100%"
                      alignItems="center"
                    >
                      <FontAwesomeIcon
                        style={{ color: '#fff' }}
                        icon={faPlus}
                      />
                      <Flex ml="5px" mr="5px">
                        Add New Block
                      </Flex>
                      <FontAwesomeIcon
                        style={{ color: '#fff' }}
                        icon={faAngleDown}
                      />
                    </Flex>
                  </Button>
                  {showDropdown && (
                    <Flex
                      width="15.4%"
                      flexDirection="column"
                      backgroundColor="#fff"
                      alignItems="center"
                      ref={dropdownRef}
                      style={{
                        position: 'absolute',
                        top: '111%',
                        border: '1px solid #E4E7EC',
                        boxShadow: '0px 4px 8px 0px #1018281A',
                      }}
                    >
                      {automatedCampaignTargetInfo?.segment_id && (
                        <Flex
                          width="100%"
                          padding="12px"
                          fontSize="14px"
                          style={{ cursor: 'pointer' }}
                          onClick={() => {
                            createNewBlocks('pre_conversion', 'email');
                            setShowDropdown(!showDropdown);
                          }}
                          color="#101828"
                        >
                          Email Block
                        </Flex>
                      )}
                      {automatedCampaignTargetInfo?.social_pages && (
                        <Flex
                          width="100%"
                          padding="0px 12px 12px"
                          fontSize="14px"
                          style={{ cursor: 'pointer' }}
                          onClick={() => {
                            createNewBlocks('pre_conversion', 'social');
                            setShowDropdown(!showDropdown);
                          }}
                          color="#101828"
                        >
                          Social Media Block
                        </Flex>
                      )}
                    </Flex>
                  )}
                </Flex>
              </Flex>
            )}

            {(campaignAction === AUTOMATED_CAMPAIGN_ACTIONS.WELCOME_CLIENTS ||
              campaignAction ===
                AUTOMATED_CAMPAIGN_ACTIONS.NURTURE_PROSPECTS) && (
              <Flex
                pb="100px"
                alignItems="center"
                width="100%"
                style={{ position: 'relative' }}
              >
                <Flex width="5.5%" flexDirection="column">
                  <WhiteCircle
                    background="#E4E7EC"
                    height="16px"
                    width="16px"
                    style={{ zIndex: 5, border: 'none' }}
                  />
                  <Line
                    border="3px dashed #E4E7EC"
                    style={{ left: '6px', top: '5px' }}
                  />
                </Flex>
                <Flex width="95%" style={{ position: 'relative' }}>
                  <Button
                    borderColor="#E4E7EC"
                    color="#101828"
                    bgColor="#fff"
                    margin="20px 0px 0px"
                    onClick={() => createNewBlocks('pre_conversion', 'email')}
                  >
                    <Flex
                      justifyContent="space-between"
                      width="100%"
                      alignItems="center"
                    >
                      <FontAwesomeIcon icon={faPlus} />
                      <Flex ml="5px">Add New Email Block</Flex>
                    </Flex>
                  </Button>
                </Flex>
              </Flex>
            )}

            <Flex
              alignItems="center"
              width="100%"
              style={{ position: 'relative' }}
            >
              <Flex width="5%" flexDirection="column">
                <WhiteCircle
                  background="#E4E7EC"
                  height="16px"
                  width="16px"
                  style={{ zIndex: 2, border: 'none' }}
                />
              </Flex>
              <Flex width="95%" flexDirection="column">
                <Flex
                  fontSize="16px"
                  color="#101828"
                  style={{ fontWeight: 500 }}
                >
                  {campaignAction ===
                    AUTOMATED_CAMPAIGN_ACTIONS.WELCOME_CLIENTS ||
                  campaignAction ===
                    AUTOMATED_CAMPAIGN_ACTIONS.NURTURE_PROSPECTS
                    ? 'Contact Exits Campaign'
                    : 'Event Starts'}
                </Flex>
                {campaignAction ===
                  AUTOMATED_CAMPAIGN_ACTIONS.EVENT && (
                    <Flex
                      fontSize="14px"
                      color="#101828"
                    >
                      {convertDateToRequiredFormat({ date: automatedCampaignTargetInfo?.event_date, format: 'MM-DD-YYYY' })}
                    </Flex>
                  )}

              </Flex>
            </Flex>
          </Flex>
        </Flex>
        {(campaignAction === AUTOMATED_CAMPAIGN_ACTIONS.EVENT ||
          campaignAction === AUTOMATED_CAMPAIGN_ACTIONS.NURTURE_PROSPECTS) && (
          <hr style={{ width: '100%', border: '1px solid #E4E7EC' }}></hr>
        )}

        {campaignAction === AUTOMATED_CAMPAIGN_ACTIONS.NURTURE_PROSPECTS && (
          <Flex width="100%">
            <Flex width="100%" flexDirection="column">
              <Flex
                width="100%"
                alignItems="baseline"
                style={{ position: 'relative' }}
              >
                <Flex width="5%" flexDirection="column">
                  <WhiteCircle
                    background="#E4E7EC"
                    height="16px"
                    width="16px"
                    style={{ border: 'none' }}
                  />
                  <Line style={{ left: '6px', top: '20px' }} />
                </Flex>
                <Flex width="45%" flexDirection="column">
                  <Flex
                    color="#101828"
                    fontSize="16px"
                    style={{ fontWeight: 500 }}
                  >
                    Contact Fills Lead Form
                  </Flex>
                  <Flex
                    fontSize="14px"
                    color="#667085"
                    style={{ textAlign: 'initial' }}
                  >
                    At any time your contact fills out the lead form they will
                    exit the main series above and will recieve the following
                    emails
                  </Flex>
                </Flex>
              </Flex>
              {campaignAction ===
                AUTOMATED_CAMPAIGN_ACTIONS.NURTURE_PROSPECTS &&
                !isEmpty(onConversionBlocks) &&
                onConversionBlocks.map((item) => (
                  <Flex
                    margin="15px 0px 15px"
                    alignItems="center"
                    width="100%"
                    style={{ position: 'relative' }}
                  >
                    <Flex width="5.5%" flexDirection="column">
                      <WhiteCircle
                        background="#E4E7EC"
                        height="16px"
                        width="16px"
                        style={{ zIndex: 5, border: 'none' }}
                      />
                      <Line style={{ left: '6px', top: '5px' }} />
                    </Flex>
                    <Flex width="55%" flexDirection="column">
                      <BlockCard
                        campaignAction={campaignAction}
                        item={item}
                        currentStepIndex={currentStepIndex}
                        onButtonClick={handleSocialEmailBlockEntry}
                        onDeleteClick={handleOpenDeleteModal}
                        onCloneClick={handleOpenCloneModal}
                        blockLength={blockLength}
                        onPreviewClick={handleOpenPreviewModal}
                        onSocialPreviewClick={handleOpenSocialPreviewModal}
                      />
                    </Flex>
                    <Flex width="5%" alignItems="center">
                      <Line border="1px solid #e4e7ec" />
                    </Flex>
                    <Flex width="45%" flexDirection="column">
                      <Flex color="#101828" fontSize="14px">
                        Registration Confirmation Email
                      </Flex>
                      <Flex
                        width="100%"
                        fontSize="12px"
                        color="#667085"
                        style={{ textAlign: 'initial' }}
                        mt="20px"
                      >
                        This is a registration confirmation email that will be
                        sent immeadiately after a contact fills out the lead
                        form.
                      </Flex>
                    </Flex>
                  </Flex>
                ))}

              <Flex
                alignItems="center"
                width="100%"
                style={{ position: 'relative' }}
              >
                <Flex width="5%" flexDirection="column">
                  <WhiteCircle
                    background="#E4E7EC"
                    height="16px"
                    width="16px"
                    style={{ zIndex: 2, border: 'none' }}
                  />
                </Flex>
                <Flex width="95%" flexDirection="column">
                  <Flex
                    fontSize="16px"
                    color="#101828"
                    style={{ fontWeight: 500 }}
                  >
                    {campaignAction ===
                      AUTOMATED_CAMPAIGN_ACTIONS.WELCOME_CLIENTS ||
                    campaignAction ===
                      AUTOMATED_CAMPAIGN_ACTIONS.NURTURE_PROSPECTS
                      ? 'Contact Exits Campaign'
                      : 'Event Starts'}
                  </Flex>
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        )}

        {campaignAction === AUTOMATED_CAMPAIGN_ACTIONS.EVENT && (
          <Flex width="100%">
            <Flex width="100%" flexDirection="column">
              <Flex
                width="100%"
                alignItems="baseline"
                style={{ position: 'relative' }}
              >
                <Flex width="5%" flexDirection="column">
                  <WhiteCircle
                    background="#E4E7EC"
                    height="16px"
                    width="16px"
                    style={{ border: 'none' }}
                  />
                  <Line style={{ left: '6px', top: '20px' }} />
                </Flex>
                <Flex width="45%" flexDirection="column">
                  <Flex
                    color="#101828"
                    fontSize="16px"
                    style={{ fontWeight: 500 }}
                  >
                    Contact Fills Registration Form
                  </Flex>
                  <Flex
                    fontSize="14px"
                    color="#667085"
                    style={{ textAlign: 'initial' }}
                  >
                    At any time your contact fills out the registration form
                    they will exit the main series above and will recieve the
                    following emails
                  </Flex>
                </Flex>
              </Flex>
              {campaignAction === AUTOMATED_CAMPAIGN_ACTIONS.EVENT &&
                !isEmpty(onConversionBlocks) &&
                onConversionBlocks.map((item, index) => (
                  <Flex
                    margin="15px 0px 0px"
                    alignItems="center"
                    width="100%"
                    style={{ position: 'relative' }}
                  >
                    <Flex width="5%" flexDirection="column">
                      <WhiteCircle
                        background="#E4E7EC"
                        height="16px"
                        width="16px"
                        style={{ zIndex: 5, border: 'none' }}
                      />
                      <Line style={{ left: '6px', top: '5px' }} />
                    </Flex>
                    <Flex width="55.5%" flexDirection="column">
                      <BlockCard
                        campaignAction={campaignAction}
                        item={item}
                        currentStepIndex={currentStepIndex}
                        onButtonClick={handleSocialEmailBlockEntry}
                        onDeleteClick={handleOpenDeleteModal}
                        onCloneClick={handleOpenCloneModal}
                        blockLength={blockLength}
                        onPreviewClick={handleOpenPreviewModal}
                        onSocialPreviewClick={handleOpenSocialPreviewModal}
                      />
                    </Flex>
                    <Flex width="5%" alignItems="center">
                      <Line border="1px solid #E4E7EC" />
                    </Flex>
                    <Flex width="45%" flexDirection="column">
                      <Flex color="#101828" fontSize="14px">
                        Registration Confirmation Email
                      </Flex>
                      <Flex
                        width="100%"
                        fontSize="12px"
                        color="#667085"
                        style={{ textAlign: 'initial' }}
                        mt="20px"
                      >
                        This email will be sent on the scheduled date or the
                        days selected prior to the start of the event.
                      </Flex>
                    </Flex>
                  </Flex>
                ))}
              {campaignAction === AUTOMATED_CAMPAIGN_ACTIONS.EVENT && (
                <Flex
                  margin="15px 0px 0px"
                  alignItems="center"
                  width="100%"
                  style={{ position: 'relative' }}
                >
                  <Flex display="flex" flexDirection="column" style={{ width: '63%'}}>
                    {!isEmpty(postConversionBlocks) &&
                      postConversionBlocks
                        .filter((b) => b.b_action === 'send_campaign')
                        .map((item) => (
                          <Flex width="100%">
                            <Flex width="8%" flexDirection="column">
                              <WhiteCircle
                                background="#E4E7EC"
                                height="16px"
                                width="16px"
                                style={{ zIndex: 5, border: 'none' }}
                              />
                              <Line style={{ left: '6px', top: '5px' }} />
                            </Flex>
                            <Flex
                              width="86%"
                              flexDirection="column"
                              marginBottom="20px"
                            >
                              <BlockCard
                                campaignAction={campaignAction}
                                item={item}
                                currentStepIndex={currentStepIndex}
                                onButtonClick={handleSocialEmailBlockEntry}
                                onDeleteClick={handleOpenDeleteModal}
                                onCloneClick={handleOpenCloneModal}
                                blockLength={blockLength}
                                onPreviewClick={handleOpenPreviewModal}
                                onSocialPreviewClick={
                                  handleOpenSocialPreviewModal
                                }
                              />
                            </Flex>
                            <Flex width="3%" alignItems="center">
                              <Line
                                border="1px solid #E4E7EC"
                                style={{ top: '2px' }}
                              />
                            </Flex>
                          </Flex>
                        ))}
                  </Flex>
                  <Flex display="flex" flexDirection="column" width="45%">
                    {!isEmpty(postConversionBlocks) &&
                      postConversionBlocks
                        .filter((b) => b.b_action !== 'send_campaign')
                        .map((item) => (
                          <Flex width="100%" marginBottom="20px" height="140px">
                            <Flex width="100%" flexDirection="column">
                              <Flex>
                                <Flex width="30%" flexDirection="column">
                                  <Flex
                                    color="#101828"
                                    fontSize="14px"
                                    width="100%"
                                  >
                                    Select Days
                                  </Flex>
                                  <Flex
                                    padding="10px 0px"
                                    color="#101828"
                                    fontSize="14px"
                                    width="100%"
                                  >
                                    {item?.b_details}
                                  </Flex>
                                </Flex>
                                <Box width={'35%'}>
                                  {/* <Box width={'49%'}> */}
                                  <Box
                                    className="field__title"
                                    color="#101828"
                                    fontSize="14px"
                                    alignItems="center"
                                  >
                                    <Box display="flex">Set Date & Time</Box>
                                    <Box
                                      width="166%"
                                      mt="8px"
                                      style={{
                                        border: '1px solid #E4E7EC',
                                        borderRadius: '4px',
                                        height: '35px',
                                        boxShadow:
                                          '1px 2px 2px 0px rgba(16, 24, 40, 0.12) inset',
                                      }}
                                    >
                                      <DateTimeWrapper
                                        style={{
                                          padding: '2px 5px',
                                          height: '32px',
                                        }}
                                      >
                                        <DateTimeText
                                          style={{
                                            width: '86%',
                                            display: 'flex',
                                            justifyContent: 'flex-start',
                                            paddingLeft: '8px',
                                          }}
                                          contenteditable="true"
                                          data-placeholder="MM/DD/YYYY"
                                        >
                                          {item?.schedule_time &&
                                            convertDateToRequiredFormat({
                                              date: item?.schedule_time,
                                              format: 'MM/DD/YYYY, h:mm a',
                                            }) + ' (' + timeZoneAbbreviated(get(
                                              userData,
                                              'details.user.timezone',
                                              ''
                                            )) + ')'}
                                        </DateTimeText>
                                        <img
                                          alt="date"
                                          src="/assets/images/Campaign/date&time-picker-icon.svg"
                                          style={{ cursor: 'pointer' }}
                                          onClick={() => {
                                            console.log('clicked');
                                            setIsDatepickerOpen(true);
                                            setSelectedId(item.id);
                                          }}
                                        />
                                        {isDatepickerOpen &&
                                          item.id === selectedId && (
                                            <DatePickerV2
                                              isOpen={isDatepickerOpen}
                                              customInput={<div />}
                                              minDate={new Date()}
                                              onDateClose={() => {
                                                setIsDatepickerOpen(false);
                                                setSelectedId('');
                                              }}
                                              onSubmit={(date) => {
                                                changeEventBlockTime(
                                                  date,
                                                  item
                                                );
                                                setSelectedId('');
                                              }}
                                              timeZone={get(
                                                userData,
                                                'details.user.timezone',
                                                ''
                                              )}
                                              scheduleValue={item.schedule_time}
                                              isFromAC
                                              loading={isLoading}
                                              hourAndMins
                                            />
                                          )}
                                      </DateTimeWrapper>
                                    </Box>
                                  </Box>
                                </Box>
                              </Flex>

                              <Flex
                                width="100%"
                                fontSize="12px"
                                color="#667085"
                                style={{ textAlign: 'initial' }}
                                mt="20px"
                              >
                                This campaign will be initiated based on the
                                selected wait period after the activation date.
                              </Flex>
                            </Flex>
                          </Flex>
                        ))}
                  </Flex>
                  {/* TODO will come back to this */}
                </Flex>
              )}
              <Flex
                alignItems="center"
                width="100%"
                style={{ position: 'relative' }}
              >
                <Flex width="5%" flexDirection="column">
                  <WhiteCircle
                    background="#E4E7EC"
                    height="16px"
                    width="16px"
                    style={{ zIndex: 5, border: 'none' }}
                  />
                </Flex>
                <Flex width="95%">
                  <Button
                    borderColor="#E4E7EC"
                    color="#101828"
                    bgColor="#fff"
                    margin="20px 0px 0px"
                    onClick={() => createNewBlocks('post_conversion', 'email')}
                  >
                    <Flex
                      justifyContent="space-between"
                      width="100%"
                      alignItems="center"
                    >
                      <FontAwesomeIcon icon={faPlus} />
                      <Flex ml="5px">Add New Email Block</Flex>
                    </Flex>
                  </Button>
                </Flex>
              </Flex>
              {showCloseButton && (
                 <Flex width="100%">
                 <Flex width="5%"></Flex>
                 <Flex width="45%">
                   <SuggestedBlock style={{ backgroundColor: '#EFEFFD'}}>
                     <Flex alignItems="center" width="100%">
                       <Flex width="5%">
                         <img
                           src="assets/images/Campaign/Info-Black.svg"
                           alt=""
                         />
                       </Flex>
                       <Flex
                         width="90%"
                         color="#101828"
                         fontSize="14px"
                         ml="10px"
                       >
                         Suggested Email Blocks
                       </Flex>
                       <Flex
                        //  color="#101828"
                        //  fontSize="14px"
                         width="5%"
                         justifyContent="flex-end"
                         onClick={() => setShowCloseButton(!showCloseButton)}
                         style={{ cursor: 'pointer' }}
                       >
                         <img alt="" src="assets/images/Campaign/close-new-icon.svg"/>
                       </Flex>
                     </Flex>
                     <Flex mt="10px" width="100%">
                       <Flex
                         mr="10px"
                         padding="10px"
                         width="50%"
                         backgroundColor="#fff"
                         style={{ borderRadius: '8px' }}
                       >
                         <img
                           src="assets/images/Campaign/Reminder-Icon.svg"
                           alt="Reminder"
                         />
                         <Flex ml="5px" flexDirection="column">
                           <Flex
                             color="#101828"
                             fontSize="14px"
                             style={{ textAlign: 'initial' }}
                           >
                             Reminder Email
                           </Flex>
                           <Flex
                             color="#667085"
                             fontSize="12px"
                             style={{ textAlign: 'initial' }}
                           >
                             Scheduling a reminder email prior to the event can
                             often result in a higher turn out.
                           </Flex>
                         </Flex>
                       </Flex>
                       <Flex
                         width="50%"
                         padding="10px"
                         backgroundColor="#fff"
                         style={{ borderRadius: '8px' }}
                       >
                         <img
                           src="assets/images/Campaign/Thank-Icon.svg"
                           alt="Thank You"
                         />
                         <Flex ml="5px" flexDirection="column">
                           <Flex
                             color="#101828"
                             fontSize="14px"
                             style={{ textAlign: 'initial' }}
                           >
                             Thank You Email
                           </Flex>
                           <Flex
                             color="#667085"
                             fontSize="12px"
                             style={{ textAlign: 'initial' }}
                           >
                             Schedule a thank you email after the event has
                             ended.
                           </Flex>
                         </Flex>
                       </Flex>
                     </Flex>
                   </SuggestedBlock>
                 </Flex>
               </Flex>
              )}
             
            </Flex>
          </Flex>
        )}
      </Flex>
      {openDeleteModal && (
        <ConfirmationModal
          modalTitle="Are you sure you want to delete this campaign?"
          modalWidth="30%"
          clickHandler={campaignBlockDeleteHandler}
          okBtnText="Yes"
          noButton={false}
          onlyShowOk={false}
          noClose
          btnMargin="0px"
          closeHandler={() => closeDeleteModal()}
          mt="35px"
          loader={requestLoading}
        />
      )}
      {openCloneModal && (
        <ConfirmationModal
          modalTitle="Are you sure you want to clone this campaign?"
          modalWidth="30%"
          clickHandler={campaignBlockCloneHandler}
          okBtnText="Yes"
          noButton={false}
          onlyShowOk={false}
          btnMargin="0px"
          noClose
          closeHandler={() => closeCloneModal()}
          mt="35px"
          loader={requestLoading}
        />
      )}
      {/* OPEN SOCIAL PREVIEW MODAL*/}
      {openSocialPreviewModal && (
        <AutomatedCampaignSocialPreviewModal
          isOpen={handleOpenSocialPreviewModal}
          handleClose={() => closeSocialPreviewModal()}
          block_id={selectedBlock?.id}
          media_urls={selectedBlock?.media_urls}
          subject={selectedBlock?.articles?.[0]?.description}
          title={selectedBlock?.article?.[0]?.title}
          campaign_id={selectedBlock?.ac_id}
          is_facebook={selectedBlock?.social_pages?.facebook}
          is_twitter={selectedBlock?.social_pages?.twitter}
          is_linkedin={selectedBlock?.social_pages?.linkedin}
        />
      )}
      {/* OPEN PREVIEW MODAL */}
      {openPreviewModal && selectedBlock.id && (
        <AutomatedCampaignEmailPreviewModal
          isOpen={handleOpenPreviewModal}
          handleClose={() => closePreviewModal()}
          block_id={selectedBlock?.id}
          campaign_id={selectedBlock?.ac_id}
          template_html={selectedBlock?.template_html}
          subject={selectedBlock?.subject}
          name={selectedBlock?.name}
        />
      )}
      <StepsFooterV2
        loader={requestLoading}
        currentStepIndex={currentStepIndex}
        setCurrentStepIndex={setCurrentStepIndex}
        handleNext={handleNextClick}
        disableNext={handleValidation()}
        disableSaveAndClose={false}
        handleSaveAndClose={async () => {}}
        campaignSetState={campaignSetState}
        endCreateFlow={endCreateFlow}
        automatedCampaignDetails={automatedCampaignDetails}
      />
    </LoaderWrapper>
  );
};

export default CustomizeCampaignV2;
