import { Flex } from '@rebass/grid'
import React, { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import enterpriseLogo from 'src/assets/icons/enterpriseLogo.svg';
import { isEmpty } from 'lodash';
import { ArrowIcon, ButtonIcon } from '../styles';
import { ColorPallete, Typography } from 'src/utils';
import downArrowPurple from 'src/assets/icons/downArrowPurple.svg';
import Checkbox from 'src/componentsV2/Checkbox';
import { DropDownWrapper } from '../AnalyticsContainer.styles';
import SearchBar from 'src/componentsV2/SearchBar';
import PurpleButton from 'src/componentsV2/PurpleButton';
import CheckboxTrue from 'src/componentsV2/Checkbox/images/Checkbox.svg'
import CheckBoxMinus from 'src/componentsV2/Checkbox/images/Checkbox-Minus.svg';
import enterpriseLogoInactive from 'src/assets/icons/enterpriseLogo-inactive.svg';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import boltIconActive from 'src/assets/icons/boltIcon-active.svg';
import boltIcon from 'src/assets/icons/boltIcon.svg'
import Bank from 'src/assets/icons/Bank';
import { Action, ActionContent } from './styles';

const UserDropdownAnalytics = ({
    selectedUsers,
    showEnterpriseDropdownSelectedOption,
    setShowEnterpriseDropdown,
    showEnterpriseDropdown,
    handleEnterpriseDropdownChange,
    enterprise,
    searchHandler,
    userList,
    searchString,
    setSearchString,
    handleSelectAll,
    selectedAdvisors,
    selectedDropdownFirms,
    advisorList,
    handleAdvisorSelection,
    handleExpand,
    handleApply
}) => {
    const { pathname } = useLocation();
    const enterpriseDropdownWrapper = useRef(null);

    const handleEnterpriseDropdownOutsideClick = (e) => {
        if (
          enterpriseDropdownWrapper.current &&
          !enterpriseDropdownWrapper.current.contains(e.target)
        ) {
          setShowEnterpriseDropdown(false);
        }
      };

      useEffect(() => {
        document.addEventListener(
          'mousedown',
          handleEnterpriseDropdownOutsideClick
        );
        return () => {
          document.removeEventListener(
            'mousedown',
            handleEnterpriseDropdownOutsideClick
          );
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);

    return (
        <Flex width="100%" flexDirection="column" style={{ position: "relative" }}>
            <Flex
                width="fit-content"
                flexDirection="row"
                justifyContent="flex-start"
                height="40px"
                alignItems="center"
                style={{ position: 'relative' }}
            >
                 {pathname?.includes('/admin/home') ? (
                    <Action onClick={() => setShowEnterpriseDropdown(true)}>
                        <ActionContent>
                            <Bank />
                            <h6> {!isEmpty(selectedUsers) ? `${selectedUsers.length} Advisor(s) selected` : showEnterpriseDropdownSelectedOption}</h6>
                            {showEnterpriseDropdown ? (
                                <KeyboardArrowUpIcon />
                            ) : (
                                <KeyboardArrowDownIcon />
                            )}
                        </ActionContent>
                       
                    </Action>
                ) : (
                    <>
                        <Flex alignItems="center">
                            <img
                                alt=""
                                src={enterpriseLogo}
                                style={{ marginRight: '4px', height: '24px', width: '24px' }}
                            />
                        </Flex>
                        <Flex padding="5px" fontSize="20px" alignItems="center">
                            {!isEmpty(selectedUsers) ? `${selectedUsers.length} Advisor(s) selected` : showEnterpriseDropdownSelectedOption}
                        </Flex>
                        <Flex
                            width="24px"
                            height="24px"
                            style={{
                                borderRadius: '4px',
                                border: '1px solid #E9E0FF',
                                alignItems: 'center',
                                justifyContent: 'center',
                                cursor: 'pointer',
                            }}
                            backgroundColor="#F6F2FF"
                            padding="2px"
                            marginLeft="4px"
                            onClick={() =>
                                setShowEnterpriseDropdown(true)
                            }
                        >
                            <ArrowIcon
                                selected={showEnterpriseDropdown}
                                src={downArrowPurple}
                                alt="down"
                                style={{ margin: '0px', alignItems: 'center' }}
                            />
                        </Flex>
                    </>
                )}
                
                {showEnterpriseDropdown && (
                    <Flex
                        backgroundColor={ColorPallete.white}
                        flexDirection="column"
                        width="100%" 
                        minWidth="200px"                       
                        ref={enterpriseDropdownWrapper}
                        style={{
                            borderRadius: '4px',
                            border: `1px solid ${ColorPallete.neutral200}`,
                            position: 'absolute',
                            top: '45px',
                            boxShadow: '0px 1px 2px 0px #1018281F',
                            zIndex: 99,
                        }}
                    >
                        <Flex
                            padding="10px 16px"
                            color={showEnterpriseDropdownSelectedOption === 'My Activity' && !enterprise ? ColorPallete.primaryPurple : ColorPallete.neutral900}
                            fontSize={Typography.TextMedium}
                            backgroundColor={showEnterpriseDropdownSelectedOption === 'My Activity' && !enterprise ? ColorPallete.neutral100 : ''}
                            alignItems="center"
                            style={{ cursor: 'pointer' }}
                            onClick={() =>
                                handleEnterpriseDropdownChange('My Activity')
                            }
                            width="100%"
                        >
                            <ButtonIcon
                                src={showEnterpriseDropdownSelectedOption === 'My Activity' && !enterprise ? boltIconActive : boltIcon}
                                alt="calendar"
                                style={{ margin: '0px 10px 0px 0px' }}
                            />
                            My Activity
                        </Flex>
                        <Flex
                            padding="10px 16px"
                            color={enterprise ? ColorPallete.primaryPurple : ColorPallete.neutral900}
                            backgroundColor={enterprise ? ColorPallete.neutral100 : ''}
                            fontSize={Typography.TextMedium}
                            alignItems="center"
                            style={{ cursor: 'pointer' }}
                            onClick={() =>
                                handleEnterpriseDropdownChange('My Enterprise')
                            }
                            width="100%"
                        >
                            <ButtonIcon
                                src={enterprise ? enterpriseLogo : enterpriseLogoInactive}
                                alt="calendar"
                                style={{ margin: '0px 10px 0px 0px' }}
                            />
                            My Enterprise
                        </Flex>
                        {enterprise && (
                            <DropDownWrapper
                                flexDirection="column"
                                mt="10px"
                                style={{
                                    maxHeight: '300px',
                                    overflowY: 'auto',
                                    overflowX: 'hidden'
                                }}
                            >
                                <SearchBar
                                    maxWidth="90%"
                                    searchString={searchString}
                                    setSearchString={setSearchString}
                                    margin="8px auto"
                                    searchHandler={searchHandler}
                                    placeholder="Search By Advisors..."
                                />
                                <Flex
                                    padding="10px 16px"
                                    color={ColorPallete.neutral900}
                                    fontSize={Typography.TextMedium}
                                    alignItems="center"
                                >
                                    <Checkbox
                                        custom={!isEmpty(selectedAdvisors) && advisorList.length !== selectedAdvisors.length ? CheckBoxMinus : CheckboxTrue}
                                        onClick={() => handleSelectAll()}
                                        checked={(advisorList.length === selectedAdvisors.length) || !isEmpty(selectedAdvisors)} />
                                    <Flex
                                        pl="8px"
                                        onClick={() => handleSelectAll()}
                                        style={{ cursor: 'pointer' }}
                                    >
                                        Select All
                                    </Flex>
                                </Flex>
                                {!isEmpty(Object.keys(userList)) ? Object.keys(userList).map((item, index) => (
                                    <>
                                        <Flex
                                            padding="10px 16px"
                                            color={ColorPallete.neutral900}
                                            fontSize={Typography.TextMedium}
                                            alignItems="center"
                                        >
                                            <Checkbox
                                                custom={!isEmpty(selectedAdvisors.filter(s => s.firm_name === item)) && selectedAdvisors.filter(s => s.firm_name === item).length !== userList[item].length ? CheckBoxMinus : CheckboxTrue}
                                                onClick={(e) => handleAdvisorSelection(item, null, true)}
                                                checked={(!isEmpty(selectedAdvisors) && selectedAdvisors.filter(s => s.firm_name === item).length === userList[item].length) || !isEmpty(selectedAdvisors.filter(s => s.firm_name === item))} />
                                            <Flex
                                                pl="8px"
                                                onClick={() => handleAdvisorSelection(item, null, true)}
                                                style={{ textAlign: 'left', cursor: 'pointer' }}
                                            >
                                                {item}
                                            </Flex>
                                            <img
                                                src={selectedDropdownFirms.includes(item) ? '/assets/images/Analytics/chevron-top.svg' : '/assets/images/Analytics/chevron-bottom.svg'}
                                                alt="top"
                                                style={{ marginLeft: '10px', cursor: 'pointer' }}
                                                onClick={() => handleExpand(item)}
                                            />
                                        </Flex>
                                        {selectedDropdownFirms.includes(item) && userList[item].map((user, index) => (
                                            <Flex
                                                padding="10px 30px"
                                                color={ColorPallete.neutral900}
                                                fontSize={Typography.TextMedium}
                                                alignItems="center"
                                            >
                                                <Checkbox
                                                    custom={CheckboxTrue}
                                                    onClick={() => handleAdvisorSelection(user, item.name)}
                                                    checked={!isEmpty(selectedAdvisors) && !isEmpty(selectedAdvisors.filter(s => s.id === user.id))} />
                                                <Flex
                                                    pl="8px"
                                                    onClick={() => handleAdvisorSelection(user, item.name)}
                                                    style={{ textAlign: 'left', cursor: 'pointer' }}
                                                >
                                                    {user.name}
                                                </Flex>
                                            </Flex>
                                        ))}
                                    </>
                                )) : (
                                    <Flex
                                        padding="10px 16px"
                                        color={ColorPallete.neutral900}
                                        fontSize={Typography.TextMedium}
                                        alignItems="center"
                                    >
                                        No Records Found!
                                    </Flex>
                                )}
                            </DropDownWrapper>
                        )}
                        {enterprise && (
                            <>
                                <Flex style={{ borderTop: `1px solid ${ColorPallete.neutral100}` }}></Flex>
                                <PurpleButton
                                    width="90%"
                                    height="35px"
                                    margin="10px"
                                    padding="8px 24px"
                                    onClick={handleApply}
                                    disabled={isEmpty(selectedAdvisors)}
                                    style={{
                                        position: 'absolute'
                                    }}
                                >
                                    Apply
                                </PurpleButton>
                            </>
                        )}
                    </Flex>
                )}
            </Flex>
            {/* {enterprise && (
                <hr
                    style={{
                        width: '100%',
                        border: '0.5px solid #E4E7EC',
                        marginTop: '8px',
                        marginBottom: '22px',
                    }}
                />
            )} */}
        </Flex>
    )
}

export default UserDropdownAnalytics