import { styled } from '@mui/material';
import Button from 'src/componentsV2/Button';

export const Container = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  flexGrow: 1,
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '0.4rem',
}));

export const Header = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '0.12rem',

  h3: {
    fontSize: '1.25rem',
    fontWeight: '500',
    color: '#6250ED',
    margin: 0,
    textAlign: 'left',
  },

  p: {
    fontSize: '0.75rem',
    fontWeight: '400',
    color: '#101828',
    margin: 0,
    textAlign: 'left',
  },
}));

export const Content = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: '2rem',
}));

export const Cards = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  gap: '1rem',
}));

export const TopCard = styled('div')(({ background = '' }) => ({
  width: 350,
  height: 200,
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-end',
  padding: '1rem',
  background: !!background
    ? `linear-gradient(0deg, rgba(0, 0, 0, 1), transparent 100%) ,url(${background})`
    : '#ECF2FC',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  borderRadius: '0.25rem',
  overflow: 'hidden',

  '.main-title': {
    color: '#fff',
    fontSize: '1rem',
    fontWeight: '500',
    margin: 0,
    maxWidth: '100%',
    textAlign: 'left',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },

  '&:hover': {
    '.overlay': {
      display: 'flex',
    },
    '.main-title': {
      display: 'none',
    },
  },
}));

export const TopCardOverlay = styled('div')({
  position: 'absolute',
  inset: 0,
  display: 'none',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '0.5rem',
  padding: '1rem',
  background: 'rgba(25, 18, 86, 0.80)',
  cursor: 'pointer',
});

export const Pills = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  gap: '0.25rem',
}));

export const Pill = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '0.25rem 0.5rem',
  fontSize: '0.75rem',
  fontWeight: '500',
  lineHeight: 1,
  color: '#FCFDFD',
  whiteSpace: 'nowrap',
  backgroundColor: '#573FA7',
  border: '1px solid #746AD8',
  borderRadius: '0.75rem',
}));

export const TopCardContent = styled('div')(() => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  gap: '0.25rem',

  h5: {
    color: '#F9FAFB',
    fontSize: '1rem',
    fontWeight: '400',
    margin: 0,
    maxWidth: '100%',
    textAlign: 'left',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    overflow: 'hidden',
    '-webkit-line-clamp': '3',
    '-webkit-box-orient': 'vertical',
  },
}));

export const TopCardDescription = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '0.5rem',

  p: {
    color: '#F9FAFB',
    fontSize: '0.75rem',
    fontWeight: '400',
    margin: 0,
    textAlign: 'left',
  },

  '.separator': {
    width: 4,
    height: 4,
    borderRadius: '50%',
    backgroundColor: '#F9FAFB',
  },
  '& .publisher-name': {
    maxWidth: '30ch',
    textAlign: 'left',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
}));

export const BookmarkContainer = styled(Button)(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '0.25rem',
  padding: '0.5rem',
  background: '#fff',
  border: '1px solid #E4E7EC',
  boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.12)',

  svg: {
    color: '#101828',
    fontSize: '1.5rem',
  },

  h4: {
    fontSize: '0.875rem',
    fontWeight: '500',
    color: '#101828',
    margin: 0,
    textAlign: 'left',
  },
}));

export const Card = styled('div')(() => ({
  width: 350,
  height: 100,
  display: 'flex',
  alignItems: 'flex-start',
  gap: '0.75rem',
  overflow: 'hidden',
  cursor: 'pointer',
}));

export const Image = styled('img')(() => ({
  backgroundColor: '#ECF2FC',
  borderRadius: '0.25rem',
}));

export const CardContentWrapper = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  alignItems: 'flex-start',
  gap: '0.75rem',
}));

export const CardContent = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  flexGrow: 1,
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '0.25rem',

  h5: {
    color: '#101828',
    fontSize: '0.875rem',
    fontWeight: '400',
    margin: 0,
    maxWidth: '20ch',
    textAlign: 'left',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
}));

export const CardDescription = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '0.5rem',

  p: {
    color: '#667085',
    fontSize: '0.75rem',
    fontWeight: '400',
    margin: 0,
    textAlign: 'left',
  },

  '.separator': {
    width: 4,
    height: 4,
    borderRadius: '50%',
    backgroundColor: '#667085',
  },
  '& .publisher-name': {
    maxWidth: '12ch',
    textAlign: 'left',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
}));

export const TopCardsContainer = styled('div')(() => ({
  flexWrap: 'wrap',
  display: 'flex',
  alignItems: 'center',
  gap: '1rem',
}));
