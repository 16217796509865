import styled from 'styled-components';

export const TileCover = styled.div`
  border: 1px solid #e5e5e5;
  display: none;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  background: #fff;
  opacity: 0.8;
`;

export const ListItem = styled.div`
  margin-right: 5px;
  display: inline-block;
  list-style-type: none;
  position: relative;
  width: ${({ width }) => width || '120px'};
  &:hover {
    cursor: pointer;
    ${TileCover} {
      display: ${({ isMember }) => isMember ? 'none' : 'block'};
      /*display: 'block';*/
    }
  }
  @media (max-width: 768px) {
    width: 115px;
  }

  .tooltip-msg {
    position: relative;
    padding-top: 4px;
  }
  
  .tooltip-msg .tooltip-msg-text {
    visibility: hidden;
    width: 169px;
    background-color: #404040;
    color: #fff;
    font-size: 8px;
    text-align: left;
    padding: 6px 6px;
    position: absolute;
    z-index: 1;
    left: -23px;
    margin-top: 67px;
    top: -23px;
  }
 
  .tooltip-display:hover .tooltiptext{
    visibility: visible;
  }
`;

export const CoverList = styled.ul`
  list-style: none;
  padding: 4px;
  clear: both;
  display: flex;
  padding: 0;
  margin: 0;
  height: 100%;
`;

export const CoverLink = styled.a`
  display: flex;
  height: 100%;
  width: 100%;
  color: #337ab7;
  text-decoration: none;
  align-items: center;

  li:first-child {
    width: 45px;
    min-width: 45px;
    text-align: center;
  }
  li:last-child {
    width: 70%;
    text-align: left;
    line-height: 10px;
    padding: 0px 0px 0 0px;
  }
`;

export const CoverListItem = styled.li`
  color: #6351ed;
  vertical-align: middle;
  margin-right: 10px;
  list-style-type: none;
  &:first-child {
    margin: auto;
  }
  &:last-child {
    margin-right: 0px;
  }
`;

export const IconWrapper = styled.span`
  display: inline-block;
  background-position: -133px -30px;
  width: 17px;
  height: 17px;
  font-size: 18px;
  color: #6351ed;
  font-weight: 900;
`;

export const CoverHeader = styled.h6`
  margin: 3px 5px 0px 2px;
  color: #6351ed;
  font-size: 11px;
  word-break: break-word;
  text-align: left;
  display: inline-block;
  line-height: 10px;
  font-weight: 500;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;
