import styled from 'styled-components';

export const Template = styled.div`
  text-align: left;
  border: ${(props) => props.border};
  padding: 10px;
  color: #adadad;
  font-size: 0.5em;
`;

export const TemplateTitle = styled.div`
  font-size: 0.9em;
  color: #525252;
  font-weight: 600;
`;

export const TemplateSubTitle = styled.div`
  font-size: 0.6em;
  color: #525252;
  margin-bottom: 10px;
`;

export const TemplateHeader = styled.div`
  height: 40px;
  background-color: #eeeeee;
`;

export const Salutation = styled.div`
  margin: 5% 0%;
`;

export const Content = styled.div`
  margin: 5% 0%;
  height: ${(props) => props.height};
`;

export const Disclaimer = styled.div`
  font-size: 0.7em;
  margin-top: 5%;
`;
