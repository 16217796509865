import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { get } from 'lodash';
import { connect } from 'react-redux';
import {
  WidgetTile,
  Tabs,
  TabList,
  TabPanel,
  InviteModal,
} from '../../../components';
import { IconDiv, AdminSubTab } from '../DashboardContainer.styles';
import InsightScore from './InsightScore';
import AddMember from './AddMember';
import { getRoleType } from '../../../redux/selector';
import { ROLE_TYPES } from '../../../utils/index'

const Membership = ({
  groupsList,
  inviteRequest,
  advisorGroupsRequest,
  analyticsRequest,
  analyticsData,
  isAnalyticsDataFetching,
  analyticsObject,
  selectedRepType,
  inviteSending,
  inviteError,
  channelId,
  allowInvite,
  setSelectedTabIndex,
  userData,
  isEnterpriseAdvisor,
  isComplianceAdmin,
  isEnterpriseLevelAdmin,
  isFirmLevelAdmin
}) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [membershipTabIndex, setMembershipTabIndex] = useState(0);
  const canAddMembers = get(userData, 'details.user.member_settings.add_members');
  // console.log('canAddMembers Icon: ', canAddMembers)

  const onAddMember = () => {
    setModalOpen(true);
  };

  const onTabSelect = (index) => {
    setMembershipTabIndex(index);
    if (index === 1) {
      analyticsRequest({
        params: {
          repType: 'mio_score',
        },
      });
    }
  };

  return (
    <WidgetTile heading="Membership" theme='light'>
      {((canAddMembers === 'true' || isEnterpriseAdvisor) && !isComplianceAdmin) ? <Link to="/admin/analytics?page=members">
        <IconDiv style={{color: '#6351ed', top: '6px'}} isClickable className="icon dripicons-user-group"></IconDiv>
      </Link>
      : <IconDiv style={{color: '#6351ed'}} disable className="icon dripicons-user-group"></IconDiv>
      }
      <Tabs onSelect={onTabSelect} style={{paddingTop: '3px'}}>
        <TabList>
          <AdminSubTab padding='5px 15px 0px' ><span className={`${membershipTabIndex === 0 ? 'active' : null}`}>Add Member</span></AdminSubTab>
          {(canAddMembers === 'true' || isEnterpriseAdvisor || isComplianceAdmin) &&  (
            <AdminSubTab padding='5px 15px 0px'><span className={`${membershipTabIndex === 1 ? 'active' : null}`}>Member Clout</span></AdminSubTab>)}
        </TabList>

        <TabPanel>
          <AddMember allowInvite={allowInvite} onAddMember={onAddMember} />
          <InviteModal
            isModalOpen={isModalOpen}
            groupsList={groupsList}
            inviteRequest={inviteRequest}
            setModalOpen={setModalOpen}
            advisorGroupsRequest={advisorGroupsRequest}
            inviteSending={inviteSending}
            inviteError={inviteError}
            channelId={channelId} 
            setSelectedTabIndex={setSelectedTabIndex}
            isEnterpriseLevelAdmin={isEnterpriseLevelAdmin}
            isFirmLevelAdmin={isFirmLevelAdmin}
          />
        </TabPanel>
        <TabPanel>
          <InsightScore
            isAnalyticsDataFetching={isAnalyticsDataFetching}
            analyticsObject={analyticsObject}
            selectedRepType={selectedRepType}
          />
        </TabPanel>
      </Tabs>
    </WidgetTile>
  );
};


const mapStateToProps = (state, ownProps) => {
  const roleType = getRoleType(state);
  // console.log('roleTyperoleType: ', roleType)
  const isEnterpriseAdvisor = roleType === ROLE_TYPES.ADMIN_ENTERPRISE;
  const isComplianceAdmin = roleType === ROLE_TYPES.ADMIN_COMPLIANCE;
  return {
    userData: state.user.data,
    isEnterpriseAdvisor,
    isComplianceAdmin
  };
};

export default connect(mapStateToProps, null)(Membership);
