import { Flex } from '@rebass/grid';
import styled from 'styled-components';

export const ModalFooterBtnsWrapper = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 10px;
`;

export const FeedbackInputLabel = styled.div`
  text-align: left;
  margin-bottom: 5px;
  color: black;
`;

export const ReviewHeader = styled.div`
  font-size: 0.9rem;
  font-weight: 700;
  width: 100%;
  text-align: left;
`;

export const ReviewBody = styled.div`
  font-size: ${({ fontSize }) => fontSize || '0.9rem'};
  font-weight: ${({ fontWeight }) => fontWeight};
  margin: ${({ margin }) => margin};
`;

export const PreviewContainer = styled.div`
  border-radius: 5px;
  border: 1px solid #edeff3;
  background-color: #f4f6fa;
  padding: 10px;
  width: 90%;
  min-height: 75%;
  text-align: left;
  position: relative;
`;

export const PaginationInfo = styled(Flex)`
  border: 1px solid #c4c6ca;
  border-radius: 5px;
  padding: 2px 10px;
  height: 25px;
  align-items: center;
  justify-content: center;
  margin: 0px 5px;
  font-size: 0.8rem;
`;

export const PaginationBtn = styled.button`
  border: 1px solid #edeff3;
  border-radius: 50%;
  height: 25px;
  width: 25px;
  align-items: center;
  justify-content: center;
  display: flex;
  color: ${({ disabled }) => (disabled ? '#c1c8d0' : '#55667b')};
  background-color: white;
  font-weight: 700;
  box-shadow: ${({ disabled }) =>
    disabled || '0px 2px 5px -1px rgba(0, 0, 0, 0.2)'};
  cursor: pointer;
`;

export const TemplateContainer = styled.div`
  width: 100%;
  height: 50vh;

  iframe {
    width: inherit;
    height: inherit;
  }
`;
