import React from 'react';
import { TableRowContent } from '../EmailDeliverability/styles';
import { EmailInfo } from '../ContactList/styles';


export const tableColumnStructure = () => {
  return [
    {
      header: {
        key: 'forwardedByEmail',
        title: 'Forwarded By',
        enableSort: false,
      },
      width: '300px',
      render: (row) => (
        <div>
          <TableRowContent>{row?.forwardedByName ?? ''}</TableRowContent>
          <EmailInfo>{row?.forwardedByEmail ?? ''}</EmailInfo>
        </div>
      ),
    },
    {
      header: {
        key: 'forwardedToEmail',
        title: 'Forwarded To',
        enableSort: false,
      },
      width: '300px',
      render: (row) => (
        <div>
          <TableRowContent>{row?.forwardedToName ?? ''}</TableRowContent>
          <EmailInfo>{row?.forwardedToEmail ?? ''}</EmailInfo>
        </div>
      ),
    },
    {
      header: {
        key: 'status',
        title: 'Email Sent',
        enableSort: false,
      },
      width: '200px',
      render: (row) => (
        <div>
          <TableRowContent>{row?.status ?? '-'}</TableRowContent>
        </div>
      ),
    },
  ];
};