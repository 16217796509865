export const reInitializeState = (state) => {
  typeof state === 'function' && state({
    campaignId: '',
    createFlow: true,
    createMode: false,
    curFlow: '',
    selectedIndex: 0,
    setObjectives: {},
    setTopics: {},
    contentWeeks: [],
    planCampaign: {},
    campaignArticles: [],
    scheduleTimings: {},
    selectedWeek: '',
    selectedDays: '',
    selectedArticles: [],
    publishedContent: {},
    defaultTemplates: [],
    setTemplate: '',
    savedTemplate: null,
    scheduleTypes: {},
    isEdit: false,
    isFromAddCampaign: false,
    summaries: null,
    seriesId: '',
    leadGenReferralData: null,
    fromDashboard: false,
    reviewCampaign: {},
    reviewType: '',
    activeIndex: 0,
    selectedTimeBlocks: [],
    acBack: false,
    automatedCampaigns: {},
  });
};

export const navItems = (isAdmin) => {
  const redirectType = window.sessionStorage.getItem('redirect_type');
  return [
    {
      title: 'Home',
      path: isAdmin ? '/admin/home' : '/home',
    },
    {
      title: 'Discover',
      path: isAdmin ? '/admin/discover' : '/discover',
    },
    {
      title: 'Campaigns',
      element: 'CAMPAIGNS',
      path: '/campaignV2',
      children: [
        {
          title: 'Create',
          type: 'CREATE_CAMPAIGN',
        },
        {
          title: 'Dashboard',
          path: '/campaignV2',
        },
        {
          title: 'Templates',
          path: '/templates',
        },
      ],
    },
    {
      title: 'Compliance',
      path: isAdmin ? '/admin/compliance' : '/compliance',
    },
    {
      title: 'Insights',
      element: 'ANALYTICS',
      path: isAdmin ? '/admin/analytics' : '/analytics',
    },
    {
      title: 'Contacts',
      path: isAdmin ? '/admin/contacts' : '/contacts',
    },
  ].filter((item) =>
    redirectType === 'nurture' ? item?.element === 'CAMPAIGNS' : true
  );
};
