import React, { useState, useRef } from 'react';
import AvatarEditor from 'react-avatar-editor';
import Slider from '../Slider/Slider';
import { EditButton } from '../AdvisorProfile/AdvisorProfile';
import { Container } from './ProfileEditor.styles';

const ProfileEditor = ({
  image,
  handleImage,
  width,
  height,
  maxScale,
  background,
}) => {
  const [scale, setScale] = useState(1.2);
  const canvas = useRef(null);

  const onClickSave = () => {
    var imge = '';
    if (canvas) {
      let img = canvas.current.getImage().toDataURL();

      fetch(img)
        .then((res) => res.blob())
        .then((blob) => {
          console.log(blob);
          var file = new File([blob], `profile.${blob.type.split('/')[1]}`, {
            type: blob.type,
            lastModified: Date.now(),
          });
          imge = new Image();
          imge.src = window.URL.createObjectURL(blob);
          handleImage(file);
        });
    }
  };

  const handleChange = (event) => {
    setScale(parseFloat(event.target.value));
  };

  return (
    <Container>
      <AvatarEditor
        ref={canvas}
        image={image}
        width={width}
        height={height}
        border={0}
        borderRadius={0}
        color={background}
        scale={scale}
        rotate={0}
      />
      <Slider
        value={scale}
        step={0.1}
        type="range"
        id="points"
        name="points"
        min="-5"
        max={maxScale}
        handleChange={handleChange}
      />
      <EditButton
        className="icon dripicons-upload"
        onClick={onClickSave}
        type="button"
      >
        &nbsp;Upload
      </EditButton>
    </Container>
  );
};

export default ProfileEditor;
