/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react';
import { ErrorMessage, Formik } from 'formik';
import { Flex } from '@rebass/grid';
import { isEmpty, get } from 'lodash';
import {
  Button,
  FileUpload,
  ErrorContainer,
  LoaderWrapper,
  ShareContentContainer,
  Modal,
} from '../../../components';
import UploadSuccess from './UploadSuccess';
import { FeedInput, FeedWrapper, InputWrapper } from './AddContent.styles';
import { getThemesObjectsFromFetched, themesData } from '../../../utils';
import ImagePreviewTab from './ImagePreviewTab';

const styles = {
  Button: {
    color: themesData.white,
    background: themesData.purpleishBlue,
    borderRadius: '0px',
    fontSize: '11px',
    fontFamily: 'Poppins',
    border: 'none',
  },
};

const DocumetUpload = ({
  postCreateContent,
  postThemeFetch,
  channelId,
  themesList,
  postUpdateContent,
  setTabIndex,
  setIsComplete,
  onTabSelect,
  setIsShareContent,
  showThemes,
  showShare,
  setShowShare,
  setShowThemes,
  onYesClick,
  userData,
  isAdmin,
  contentlibRequest,
  setSelectedExploreTabIndex,
  isEnterpriseAdvisor,
  exploreArticleRequest,
  popularTopicDetails,
  setCuratedTopicType,
  setWdigetTabIndex,
  showImagePreview,
  setShowImagePreview,
  showContentOverview,
  setShowContentOverview,
  reset,
  updateReset,
}) => {
  const [contentId, setContentId] = useState('');
  const [imageUrl, setImageUrl] = useState('');
  const [contentCreateError, setContentCreateError] = useState('');
  const [isSaving, setIsSaving] = useState(false);
  const [isUpdating, setisUpdating] = useState(false);
  const [selectedTheme, setSelectedTheme] = useState([]);
  // const [showThemes, setShowThemes] = useState(false);
  const [uploadedContent, setuploadedContent] = useState({
    pdf: '',
    title: '',
  });
  const [fetchedThemes, setFetchedThemes] = useState([]);
  // const [showShare, setShowShare] = useState(false);
  const [themeMessage, setThemeMessage] = useState({});
  const [userSummary, setUserSummary] = useState('');
  const [showContent, setShowContent] = useState(false);
  const [showInternalUse, setShowInternalUse] = useState(false);
  const [secondaryId, setSecondaryId] = useState('');
  const [errorMessge, setErrorMessage] = useState('');
  const formikRef = useRef();

  useEffect(() => {
    if (!showThemes && !showShare) {
      setIsShareContent(false);
    }
  }, [showThemes, showShare]);

  useEffect(() => {
    if (reset) {
      setErrorMessage('');
      setImageUrl('');
      if (formikRef.current) formikRef.current.resetForm();
    }
  }, [reset]);

  // const toDataURL = (src, callback, outputFormat) => {
  //   var img = new Image();
  //   img.crossOrigin = 'Anonymous';
  //   img.onload = function () {
  //     var canvas = document.createElement('CANVAS');
  //     var ctx = canvas.getContext('2d');
  //     var dataURL;
  //     canvas.height = this.naturalHeight;
  //     canvas.width = this.naturalWidth;
  //     ctx.drawImage(this, 0, 0);
  //     dataURL = canvas.toDataURL(outputFormat);
  //     callback(dataURL);
  //   };
  //   img.onerror = function () {
  //     callback(false);
  //   };
  //   img.src = src;
  //   if (img.complete || img.complete === undefined) {
  //     img.src =
  //       'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///ywAAAAAAQABAAACAUwAOw==';
  //     img.src = src;
  //   }
  // };

  const validate = (values) => {
    const errors = {};
    const { pdfTitle, pdfUpload } = values;
    const fileSize = get(pdfUpload, '[0].size') / 1024 / 1024;
    console.log('UPLOADED PDF = = - - - ', pdfUpload);

    if (fileSize > 20) {
      errors.fileSize = 'File Size should be less than or equal to 20 MB';
      setErrorMessage('File Size should be less than or equal to 20 MB');
      return errors;
    }
    if (pdfUpload && pdfUpload[0].type !== 'application/pdf') {
      errors.pdfUpload =
        'Error uploading file..Please upload a valid pdf file.';
      setErrorMessage('Error uploading file..Please upload a valid pdf file.');
      return errors;
    }
    if (!pdfTitle) {
      errors.pdfTitle = 'Please enter a title';
      setErrorMessage('Please enter a title');
    } else if (pdfTitle) setErrorMessage('');

    if (isEmpty(pdfUpload)) {
      errors.pdfUpload = 'Please select a File';
      setErrorMessage('Please select a File');
    }

    return errors;
  };

  const onPdfSubmit = (values, { resetForm }) => {
    setIsComplete(false);
    const pdfFile = new File([get(values, 'pdfUpload[0]')], 'uploadedfile.pdf', {
      type: 'application/pdf'
    });
    setuploadedContent({ pdf: pdfFile, title: values.pdfTitle });
    setIsSaving(true);
    postCreateContent({
      channelId,
      title: values.pdfTitle,
      link: values.pdfTitle,
      source: 'pdf',
      attachment1: pdfFile,
      skip_compliance: false,
    })
      .then((response) => {
        updateReset(false);
        setContentId(get(response, 'result.data.content_id'));
        setSecondaryId(get(response, 'result.data.id'));
        const randomNumber = Math.floor(Math.random() * 4) + 1;
        let imgSrc = `/assets/images/postDefault${randomNumber}.jpg`;
        setImageUrl(get(response, 'result.data.image', imgSrc));
        let autoImageUrl = get(response, 'result.data.image');
        setThemeMessage({
          ...themeMessage,
          url: autoImageUrl,
          topic: values.pdfTitle,
        });
        setIsSaving(false);
        setShowImagePreview(true);
        setShowThemes(true);
        // return postThemeFetch({ media1: pdfFile });
      })
      // .then((response) => {
      //   const themeResponse = get(response, 'result.data', {});
      //   const themeText = get(themeResponse, 'text', {});
      //   setThemeMessage({
      //     url: get(themeText, 'image'),
      //     topic: values.pdfTitle,
      //   });
      //   setIsSaving(false);
      //   setShowThemes(true);
      //   setFetchedThemes(getThemesObjectsFromFetched(themeResponse));
      // })
      .catch((err) => {
        setIsSaving(false);
        setContentCreateError('Oops... somenthing went wrong');
      });
  };

  const onNextClickImagePreview = (resetForm) => {
    console.log('CLICKED  -- - - - - ', imageUrl);
    postUpdateContent({
      contentId,
      title: uploadedContent.title,
      user_summary: userSummary,
      link: uploadedContent.title,
      message: userSummary || get(themeMessage, 'message', ''),
      source: 'pdf',
      attachment1: uploadedContent.pdf,
      media1:
        imageUrl && (imageUrl.includes('https') || imageUrl.includes('asset'))
          ? ''
          : imageUrl,
      skip_compliance: false,
    })
      .then((response) => postThemeFetch({ media1: uploadedContent.pdf }))
      .then((response) => {
        setShowImagePreview(false);
        const themeResponse = get(response, 'result.data', {});
        const themeText = get(themeResponse, 'text', {});
        setThemeMessage({
          url: get(themeText, 'image') ? get(themeText, 'image') : imageUrl,
          topic: uploadedContent.title,
        });
        setIsSaving(false);
        setShowThemes(true);
        setFetchedThemes(getThemesObjectsFromFetched(themeResponse));
        resetForm({
          pdfTitle: '',
          pdfUpload: null,
        });
      })
      .catch((err) => {
        setIsSaving(false);
        setContentCreateError('Oops... somenthing went wrong');
      });
  };

  const onUpdateTheme = (
    themes,
    type = '',
    optionType = '',
    summary = {},
    skip_compliance
  ) => {
    console.log('CONTENT ID = ', contentId);
    setisUpdating(true);
    let reqObj = {
      contentId,
      themes,
      message: userSummary || get(themeMessage, 'message', ''),
      title: get(themeMessage, 'topic', ''),
      // user_summary: userSummary
    };
    console.log('[REQ-OBJ ON_UPDATE_THEME] - ', reqObj);
    if (type === 'Content Overview') {
      reqObj.unpublished = true;
    }

    if (type === 'Share Internal Issue') {
      reqObj.sharing = true;
      reqObj.internalSharing = true;
      reqObj.skip_compliance = skip_compliance;
      reqObj.summary = summary;
    }

    if (type === 'Internal Use') {
      reqObj.internalUse = true;
    }
    setSelectedTheme(themes.add);
    // toDataURL(imageUrl, function (dataUrl) {
    //   console.log(dataUrl)
    //   if (dataUrl) {
    //    reqObj.media1 = dataUrl;
    //   }
    // })
    reqObj.media1 =
      imageUrl && (imageUrl.includes('https') || imageUrl.includes('asset'))
        ? ''
        : imageUrl;
    postUpdateContent(reqObj)
      .then(() => {
        setisUpdating(false);
        // setShowThemes(false);
        // setShowShare(true);
        // if(type!=='next')
        //   setUserSummary('');
        if (type === 'Content Overview' || type === 'Internal Use') {
          handleContentOverviewClick(optionType);
        } else if (type === 'Share Internal Issue') {
          setShowThemes(false);
          setShowShare(false);
          setIsShareContent(false);
          setShowContent(false);
          setShowInternalUse(false);
          onYesClick();
        } else {
          setShowContentOverview(true);
        }
        if (
          type !== 'Content Overview' &&
          type !== 'Internal Use' &&
          type !== 'Share Internal Issue'
        ) {
          // setIsShareContent(true);
          // setAutoPostVisibilityFlag(true);
          if (fetchedThemes.length === 0) {
            setFetchedThemes(themes.add);
          }
        }
      })
      .catch(() => {
        setContentCreateError('Oops... somenthing went wrong');
        setisUpdating(false);
      });
  };

  const onExitClick = () => {
    onTabSelect(1, 'exitClick');
  };

  const handleContentOverviewClick = (type) => {
    switch (type) {
      case 'shareNow':
        setShowThemes(false);
        setShowShare(true);
        setIsShareContent(true);
        return;
      case 'viewContent':
        if (isEnterpriseAdvisor) {
          setCuratedTopicType('My Added Content');
        } else {
          contentlibRequest({ type: 'all' });
        }
        setTabIndex(0);
        setWdigetTabIndex(0);
        setSelectedExploreTabIndex(2);
        return;
      case 'addContent':
        setShowContent(false);
        onYesClick();
        setFetchedThemes([]);
        return;
      case 'internalUse':
        setShowInternalUse(true);
        return;
      default:
        return;
    }
  };

  const onShareSuccess = (setFieldValue) => {
    setShowThemes(false);
    setShowShare(false);
    setFieldValue('pdfUpload', null);
    setIsComplete(true);
    setShowImagePreview(false);
    setShowContentOverview(false);
    setIsShareContent(false);
    setFetchedThemes([]);
  };

  return (
    <Formik
      initialValues={{
        pdfTitle: '',
        pdfUpload: null,
      }}
      validate={validate}
      onSubmit={onPdfSubmit}
      innerRef={formikRef}
    >
      {({
        handleChange,
        setFieldValue,
        handleSubmit,
        errors,
        values,
        resetForm,
      }) => {
        const feedWrapper = document.getElementById('myId');
        // console.log('VALUES --- ', values);
        if (feedWrapper && [442, '442'].includes(feedWrapper.clientHeight)) {
          document.getElementById('myId').style.margin = '20% auto 0 auto';
        }
        return (
          <FeedWrapper style={{ overflow: 'hidden' }}>
            {!showThemes && !showShare && (
              <>
                <InputWrapper>
                  <FeedInput
                    id="pdfTitle"
                    name="pdfTitle"
                    type="text"
                    onChange={handleChange}
                    placeholder="Please enter a title"
                    data-testid="ac-pdf-title"
                  />
                </InputWrapper>
                <ErrorContainer align="center">
                  {/* {errors.pdfTitle} */}
                  {ErrorMessage}
                </ErrorContainer>
                <div>
                  <Flex
                    width={1}
                    justifyContent="center"
                    alignItems="center"
                    flexDirection="column"
                  >
                    <FileUpload
                      id="pdfUpload"
                      name="pdfUpload"
                      type="button"
                      onClick={() => {}}
                      onChange={(file) => setFieldValue('pdfUpload', file)}
                      label="Upload"
                      accept=".pdf"
                      color={themesData.white}
                      background={themesData.purpleishBlue}
                      borderRadius="0px"
                      border="none"
                      padding="7px 38px"
                      fontSize="11px"
                    />
                    <ErrorContainer align="center">
                      {errorMessge}
                      {!errorMessge &&
                        !isEmpty(values.pdfUpload) &&
                        '✓ ***.pdf'}
                    </ErrorContainer>
                  </Flex>
                  <div>
                    <LoaderWrapper isLoading={isSaving}>
                      <Button
                        type="button"
                        margin="5px 0px 0px 0px"
                        onClick={handleSubmit}
                        disabled={
                          values.pdfUpload === null || values.pdfTitle === ''
                        }
                        width="117px"
                        styles={
                          values.pdfUpload === null || values.pdfTitle === ''
                            ? {
                                ...styles.Button,
                                background: '#b7b7b7',
                                color: 'white',
                              }
                            : styles.Button
                        }
                      >
                        Next
                      </Button>
                    </LoaderWrapper>
                  </div>
                </div>
              </>
            )}
            {showImagePreview && (
              <>
                {!errorMessge && '✓ PDF uploaded successfully'}
                {errorMessge}
                <ImagePreviewTab
                  articleData={{
                    id: contentId,
                    ...themeMessage,
                    link: values.rssurl,
                    isPdf: 'pdf',
                  }}
                  handleContentOverviewClick={handleContentOverviewClick}
                  pdfImage={imageUrl}
                  setPdfImage={setImageUrl}
                  onUpdateTheme={onUpdateTheme}
                  isAdmin={isAdmin}
                  isFromPdf={true}
                  setError={setErrorMessage}
                  error={errorMessge}
                  nextClick={() => onNextClickImagePreview(resetForm)}
                  userSummary={userSummary}
                  setUserSummary={setUserSummary}
                />
              </>
            )}
            {!contentCreateError && showThemes && !showImagePreview && (
              <UploadSuccess
                fetchedThemes={fetchedThemes}
                themesList={themesList}
                onUpdateTheme={onUpdateTheme}
                isUpdating={isUpdating}
                onExitClick={onExitClick}
                contentCreateError={contentCreateError}
                articleData={{
                  id: contentId,
                  ...themeMessage,
                  link: values.rssurl,
                  isPdf: 'pdf',
                }}
                handleContentOverviewClick={handleContentOverviewClick}
                showContent={showContent}
                setTabIndex={setTabIndex}
                setShowContent={setShowContent}
                pdfImage={imageUrl}
                showInternalUse={showInternalUse}
                userData={userData}
                isAdmin={isAdmin}
                selectedTheme={selectedTheme}
                showContentOverview={showContentOverview}
                setShowContentOverview={setShowContentOverview}
                userSummary={userSummary}
                setError={setErrorMessage}
                error={errorMessge}
                // userSummary={userSummary || get(themeMessage, 'message', '')}
              />
            )}
            {showShare && (
              <Modal
                onRequestClose={() => {
                  setShowShare(false);
                  setIsShareContent(false);
                  localStorage.removeItem('emailData');
                  updateReset(true);
                  setUserSummary('');
                }}
                shouldCloseOnOverlayClick
                isOpen={showShare}
                size="medium68"
              >
                <ShareContentContainer
                  fetchedThemes={
                    fetchedThemes && fetchedThemes.length < 1
                      ? selectedTheme
                      : fetchedThemes
                  }
                  editable
                  {...themeMessage}
                  submitButtonText="Share"
                  id={contentId}
                  secondaryId={secondaryId}
                  isFromAddContent
                  handleClose={() => onShareSuccess(setFieldValue)}
                  pdfImage={imageUrl}
                  source="pdf"
                  asModal
                  isPdf="pdf"
                  description={userSummary}
                />
              </Modal>
            )}
            {contentCreateError && (
              <ErrorContainer align="center">
                {contentCreateError}
              </ErrorContainer>
            )}
          </FeedWrapper>
        );
      }}
    </Formik>
  );
};

export default DocumetUpload;
