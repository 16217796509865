import React from 'react';
import { Flex, Box } from '@rebass/grid';
import { AdminScheduler, OthersScheduler } from './Scheduler';
import { values, without } from 'lodash';
import {
  ShareBoxWrapper,
  CheckBoxForm,
  CheckBoxWrapper,
  CheckBoxSpan2,
  CheckBox,
  CheckBoxLabel,
  CheckBoxSpan,

  AutoPostCheckboxes,
  StyledTooltip,
  ShareOnDiv,
  ShareOnLabel,
} from '../ShareContentContainer.styles';
import { ToggleButton } from '../../../containers/ConfigureContainer/screenConfigure.styles';

const AdminShareCheckBox = ({
  title,
  checkboxData,
  connectionType,
  isFromCompliance,
  isAdvisorChecked,
  isChecked,
  getBottomText,
  handleCheckChange,
  isAutoPostChecked,
  isAdmin,
  scheduleValue,
  clearSchedule,
  onClockButtonClick,
  isAdvisorOpen,
  isDateOpen,
  setIsAdvisorOpen,
  setDateOpen,
  onScheduleClick,
  selectedMembers,
  toggleAdvisorModal,
  membersIcons,
  setAutoPostIsChecked,
  isFromScheduledTab,
  rest
}) => {

  const autoPostChangeHandler = (checked) => {
    setAutoPostIsChecked(checked);
  };

  return (
    <>
      <ShareBoxWrapper>
        <div><span style={{ fontSize: 12, fontWeight: 'bold', color: '#49484a' }}>{title}</span></div>
        <CheckBoxForm>
          {(!isFromCompliance || rest.isFromAdmin) && (
            <Flex width={0.8} alignItems="center" style={{ margin: 'auto' }}>
              {checkboxData.map(({ id, platform }, index) => (
                <CheckBoxWrapper
                  style={
                    window.innerWidth > 768
                      ? id === 'Facebook'
                        ? { width: '39%' }
                        : id === 'Twitter'
                          ? { width: '33%' }
                          : id === 'Email'
                            ? { width: '30%' }
                            : id === 'LinkedIn'
                              ? { width: '36%' }
                              : id === 'All'
                                ? { width: '25%' }
                                : null
                      : id === 'LinkedIn'
                        ? { width: '36%' }
                        : null
                  }
                >
                  <CheckBoxSpan2
                    screenWidth={window.innerWidth}
                    checked={connectionType === 'advisor' ? isAdvisorChecked[id] : isChecked.id}
                    className='tooltip'
                    disconnected={rest.isFromAdmin ? null : connectionType === 'advisor' ? false : getBottomText(platform).isDisconnected}
                  >
                    {!rest.isFromAdmin && (connectionType === 'advisor') ? false : getBottomText(platform).isDisconnected && !isChecked[id] ? <StyledTooltip className='tooltiptext' width="170px">Channel not activated</StyledTooltip> : null}
                    <CheckBox
                      id={connectionType === 'advisor' ? `${id}${connectionType}` : id}
                      checked={connectionType === 'advisor' ? isAdvisorChecked[id] : isChecked[id]}
                      onChange={(e) => handleCheckChange(id, platform, connectionType, e)}
                      screenWidth={window.innerWidth}
                      disabled={rest.isFromAdmin ? null : connectionType === 'advisor' ? (id !== 'All' && !isAutoPostChecked) ? true : false : getBottomText(platform).isDisconnected}
                      border='1px solid #c9c9c9'
                    />
                    <CheckBoxLabel
                      screenWidth={window.innerWidth}
                      disconnected={rest.isFromAdmin ? null : connectionType === 'advisor' ? (id !== 'All' && !isAutoPostChecked) ? true : false : getBottomText(platform).isDisconnected}
                      color='#c9c9c9'
                      htmlFor={connectionType === 'advisor' ? `${id}${connectionType}` : id}
                      fb={id === 'Facebook'}
                      ln={id === 'LinkedIn'}
                      style={
                        window.innerWidth > 768
                          ? id === 'LinkedIn'
                            ? { paddingLeft: '18px' }
                            : id === 'Facebook'
                              ? { paddingLeft: '20px' }
                              : null
                          : null
                      }
                    >
                      {id}
                    </CheckBoxLabel>
                  </CheckBoxSpan2>
                </CheckBoxWrapper>
              ))}
            </Flex>
          )}
          {connectionType === 'my' && (
            <AdminScheduler
              scheduleValue={scheduleValue}
              connectionType={connectionType}
              disableScheduleClick={values(isChecked).includes(true)}
              clearSchedule={clearSchedule}
              onClockButtonClick={onClockButtonClick}
              isAdvisorOpen={isAdvisorOpen}
              isDateOpen={isDateOpen}
              setIsAdvisorOpen={setIsAdvisorOpen}
              setDateOpen={setDateOpen}
              onScheduleClick={onScheduleClick}
              isFromScheduledTab={isFromScheduledTab}
            />
          )}
          {connectionType === 'advisor' && (
            <AdminScheduler
              scheduleValue={scheduleValue}
              connectionType={connectionType}
              disableScheduleClick={isAutoPostChecked && values(isAdvisorChecked).includes(true)}
              clearSchedule={clearSchedule}
              onClockButtonClick={onClockButtonClick}
              isAdvisorOpen={isAdvisorOpen}
              isDateOpen={isDateOpen}
              setIsAdvisorOpen={setIsAdvisorOpen}
              setDateOpen={setDateOpen}
              onScheduleClick={onScheduleClick}
            />
          )}
        </CheckBoxForm>
        {isAdmin && connectionType === 'advisor' && !isFromCompliance && values(isAdvisorChecked).includes(true) && (
          <Flex
            width={1}
            alignItems="center"
            justifyContent="flex-start"
            px="4px"
            pt="4px"
            style={{ padding: 0, height: 6, marginBottom: 15 }}
          >
            <AutoPostCheckboxes showAll={isAutoPostChecked}>
              <Box
                mt={2}
                className="autoPostCheckboxesListsContainer"
              >
                <span style={{ marginTop: '13px', fontSize: '10px' }}>Auto post</span> &nbsp;&nbsp;{' '}
                <ToggleButton
                  className="react-switch"
                  onChange={autoPostChangeHandler}
                  checked={isAutoPostChecked}
                  onColor="#6351ed"
                  offColor="#cccccc"
                  offHandleColor="#fff"
                  onHandleColor="#fff"
                  handleDiameter={10}
                  uncheckedIcon=""
                  checkedIcon=""
                  boxShadow=""
                  activeBoxShadow=""
                  type='autoPost'
                />
                <img
                  src={isAutoPostChecked ? "/assets/images/advisor1.svg" : "/assets/images/advisor1-disabled.svg"}
                  alt='' style={{ margin: '10px 0px 0px 5px', cursor: isAutoPostChecked ? "pointer" : "default" }}
                  onClick={() => toggleAdvisorModal()} />
              </Box>
            </AutoPostCheckboxes>
          </Flex>
        )}
      </ShareBoxWrapper>
    </>
  )
}

const MembersCheckBox = ({
  membersList,
  handleAdvisorChange,
  selectedMembers,
  ogSelectedMembers,
  fontSize,
  fromCampaignTemplate
}) => {
  console.log('membersList - - -- ', membersList);
  console.log('ogSelectedMembers - - -- ', ogSelectedMembers);
  console.log('selectedMembers - - -- ', selectedMembers);
  return (
    membersList.map(data =>
      <CheckBoxSpan
        key={data.id}
        screenWidth={window.innerWidth}
        // checked={data.check}
        style={{ 
          border: 'none', 
          background: fromCampaignTemplate ? 'transparent' : '#ececec', 
          marginBottom: '4px', 
          textAlign: 'left',
        }}
      >
        <CheckBox
          id={data.id}
          checked={selectedMembers.includes(data.id)}
          onChange={(e) => handleAdvisorChange(e, data)}
          screenWidth={window.innerWidth}
        />
        <CheckBoxLabel fontSize={fontSize} style={{ paddingLeft: '11%' }} htmlFor={data.id} color='#848484'>
          {data.details.name.email}
        </CheckBoxLabel>
      </CheckBoxSpan>
    )
  )
}

const RepostCheckBox = ({
  shareError,
  handlePlatformChange,
  repostOptions
}) => {
  return (
    shareError.filter(s => s.type === 'SocialShareException').map(data =>
      <CheckBoxSpan
        key={data.platform}
        screenWidth={window.innerWidth}
        // checked={data.check}
        style={{ border: 'none', background: '#ececec', marginBottom: '4px', textAlign: 'left' }}
      >
        <CheckBox
          id={data.platform}
          checked={repostOptions.includes(data.platform)}
          onChange={(e) => handlePlatformChange(e, data)}
          screenWidth={window.innerWidth}
        />
        <CheckBoxLabel style={{ paddingLeft: '11%' }} htmlFor={data.platform} color='#848484'>
          {data.name}
        </CheckBoxLabel>
      </CheckBoxSpan>
    )
  )
}

const OthersCheckBox = ({
  checkBoxVals,
  isChecked,
  getBottomText,
  handleCheckChange,
  onClockButtonClick,
  isDateOpen,
  setDateOpen,
  shareModalMsgs,
  onScheduleClick,
  scheduleValue,
  clearSchedule,
  isFromScheduledTab,
  rest,
}) => {
  return (
    <Flex width={1} alignItems="center">
      <ShareOnDiv>
        <ShareOnLabel>Share on:</ShareOnLabel>
      </ShareOnDiv>
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', width: '100%', border: '1px solid #cacaca', borderRadius: '7px', paddingRight: '7px' }}>
        {checkBoxVals.map(({ id, platform }, index) => (
          <CheckBoxWrapper
            style={
              window.innerWidth > 768
                ? id === 'Facebook'
                  ? { width: '39%' }
                  : id === 'Twitter'
                    ? { width: '33%' }
                    : id === 'Email'
                      ? { width: '30%' }
                      : id === 'LinkedIn'
                        ? { width: '36%' }
                        : null
                : id === 'LinkedIn'
                  ? { width: '36%' }
                  : null
            }
          >
            <CheckBoxSpan
              screenWidth={window.innerWidth}
              checked={isChecked.id}
              className='tooltip'
              disconnected={rest.isFromAdmin ? null : getBottomText(platform).isDisconnected}
            >
              {!rest.isFromAdmin && getBottomText(platform).isDisconnected && !isChecked.id ? <StyledTooltip className='tooltiptext'>Channel not activated</StyledTooltip> : null}

              <CheckBox
                id={id}
                checked={isChecked[id]}
                onChange={() => handleCheckChange(id)}
                screenWidth={window.innerWidth}
                disabled={rest.isFromAdmin ? null : getBottomText(platform).isDisconnected}
              />
              <CheckBoxLabel
                screenWidth={window.innerWidth}
                disconnected={rest.isFromAdmin ? null : getBottomText(platform).isDisconnected}
                htmlFor={id}
                fb={id === 'Facebook'}
                ln={id === 'LinkedIn'}
                style={
                  window.innerWidth > 768
                    ? id === 'LinkedIn'
                      ? { paddingLeft: '21px' }
                      : id === 'Facebook'
                        ? { paddingLeft: '23px' }
                        : null
                    : null
                }
              >
                {id}
              </CheckBoxLabel>
            </CheckBoxSpan>
          </CheckBoxWrapper>
        ))}
        {!rest.isFromAdmin && (
          <OthersScheduler
            onClockButtonClick={onClockButtonClick}
            isDateOpen={isDateOpen}
            setDateOpen={setDateOpen}
            onScheduleClick={onScheduleClick}
            scheduleValue={scheduleValue}
            clearSchedule={clearSchedule}
            isChecked={isChecked}
            shareModalMsgs={shareModalMsgs}
            disableScheduleClick={values(isChecked).includes(true)}
            isFromScheduledTab={isFromScheduledTab}
          />
        )}
      </div>
    </Flex>
  )
}

const ShareCheckBoxes = ({
  checkBoxVals,
  rest,
  isFromCompliance,
  isAdvisorChecked,
  isChecked,
  getBottomText,
  isAutoPostChecked,
  isAdmin,
  isAdvisorOpen,
  isDateOpen,
  setIsAdvisorOpen,
  setDateOpen,
  onScheduleClick,
  selectedMembers,
  toggleAdvisorModal,
  membersIcons,
  setAutoPostIsChecked,
  adminShareOptions,
  setIsAdvisorChecked,
  setIsChecked,
  setWarningMsg,
  advisorScheduleTime,
  setAdvisorScheduleTime,
  scheduleTime,
  setScheduleTime,
  setScheduleState,
  selectedFbPage,
  shareModalMsgs,
  setShareModalMsgs,
  setSelectedFbPage,
  socialNetworkData,
  isFromScheduledTab,
  setSelectedLnPage,
  isAssetAdvisor,
  isEnterpriseAdvisor,
  selectedComplianceCheck,
  userData,
  approvedArticles
}) => {

  const handleCheckChange = (id, platform = null, connectionType = null, e = null) => {
    if (connectionType === 'advisor') {
      if (id === 'All') {
        setIsAdvisorChecked({
          ...isAdvisorChecked,
          Twitter: false,
          LinkedIn: false,
          Email: false,
          All: !isAdvisorChecked.All
        });
      } else {
        setIsAdvisorChecked({ ...isAdvisorChecked, [id]: !isAdvisorChecked[id], All: false });
        console.log('SETTING isAdvisorChecked...');
      }
    } else {
      setIsChecked({ ...isChecked, [id]: !isChecked[id] });
      console.log('isChecked SET...', isChecked);
    }
  };

  const onClockButtonClick = (e, type = '') => {
    // console.log('[SELECTED FB PAGE] - ', selectedFbPage);
    // console.log('[SOCIAL NETWORK DATA] - ', socialNetworkData);
    // console.log()
    e.preventDefault();
    if (!isAdmin && isChecked.Facebook) {
      // setIsChecked({ ...isChecked, Facebook: false });
      if (selectedFbPage == 0) {
        console.log('[SELECTED FB PAGE === 0]');
        setShareModalMsgs([
          'You can not schedule Facebook post on your news feed.'
        ]);
        setDateOpen(false);
      }
      else {
        console.log('[SELECTED FB PAGE]', selectedFbPage);
        setShareModalMsgs([]);
        setDateOpen(true);
      }
      // setTimeout(() => {
      //   setWarningMsg([]);
      // }, 3000);
    }
    else {
      if (type === 'advisor') setIsAdvisorOpen(true);
      else setDateOpen(true);
    }
  };

  const clearSchedule = (type) => {
    console.log('Indside clear schedule');
    if (type === 'advisor') setAdvisorScheduleTime(null)
    if (type === 'my') setScheduleTime(null);
    else setScheduleTime(null);
    setScheduleState({ clicked: false, scheduleIsOpen: false });
    setShareModalMsgs([]);
  }

  return (
    <>
      {!isAdmin && (!isFromCompliance || !rest.isFromAdmin) && (
        <OthersCheckBox
          checkBoxVals={checkBoxVals}
          isChecked={isChecked}
          getBottomText={getBottomText}
          handleCheckChange={handleCheckChange}
          onClockButtonClick={onClockButtonClick}
          isDateOpen={isDateOpen}
          setDateOpen={setDateOpen}
          onScheduleClick={onScheduleClick}
          scheduleValue={scheduleTime}
          clearSchedule={clearSchedule}
          shareModalMsgs={shareModalMsgs}
          isFromScheduledTab={isFromScheduledTab}
          rest={rest}
        />
      )}
      {isAdmin && window.location.pathname.includes('dashboard') && (
        <>
          <div style={{ marginTop: 10 }}>
            <ShareOnLabel style={{ color: '#49484a', fontWeight: 'bold', fontSize: '14px' }}>Share on:</ShareOnLabel>
          </div>
          {adminShareOptions.filter(option => option.isVisible !== false).map(option => (
            <AdminShareCheckBox
              isAdmin={isAdmin}
              title={option.title}
              checkboxData={option.connections}
              connectionType={option.id}
              isFromCompliance={isFromCompliance}
              isAdvisorChecked={isAdvisorChecked}
              getBottomText={getBottomText}
              handleCheckChange={handleCheckChange}
              isAutoPostChecked={isAutoPostChecked}
              scheduleValue={option.scheduleValue}
              clearSchedule={clearSchedule}
              onClockButtonClick={onClockButtonClick}
              isAdvisorOpen={isAdvisorOpen}
              isDateOpen={isDateOpen}
              setIsAdvisorOpen={setIsAdvisorOpen}
              setDateOpen={setDateOpen}
              onScheduleClick={onScheduleClick}
              isChecked={isChecked}
              setAutoPostIsChecked={setAutoPostIsChecked}
              selectedMembers={selectedMembers}
              toggleAdvisorModal={toggleAdvisorModal}
              membersIcons={membersIcons}
              rest={rest}
              isFromScheduledTab={isFromScheduledTab}
            />
          ))}
        </>
      )}
    </>
  )
}

export {
  AdminShareCheckBox,
  MembersCheckBox,
  OthersCheckBox,
  ShareCheckBoxes,
  RepostCheckBox
};