import { handleActions, createActions } from 'redux-actions';
import { apiModuleDefaultState, createApiActions } from './helpers';

export const actions = createApiActions('campaignDetails');

export const moreActions = createActions({
  custom: {
    campaignDetails: {
      bucketArticles: (payload) => payload
    },
  },
});

const reducer = handleActions(
  {
    [actions.api.campaignDetails.request]: () => ({
      isFetching: true,
      isError: false,
    }),
    [actions.api.campaignDetails.response]: (_, { payload }) => ({
      isFetching: false,
      isError: false,
      data: payload,
    }),
    [actions.api.campaignDetails.error]: (_, { payload }) => ({
      isFetching: false,
      isError: true,
      error: payload,
    }),
    [moreActions.custom.campaignDetails.bucketArticles]: (state, { payload }) => {
      return {
        ...state,
        campaignBucket: payload,
      };
    }
  },
  { ...apiModuleDefaultState, data: [], error: null }
);

export default reducer;
