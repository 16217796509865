import { styled } from '@mui/material';

const isCampaignCompleted = (status) => {
  return status.toUpperCase() === 'COMPLETED';
};

export const CampaignSummaryWrapper = styled('section')(() => ({
  margin: '32px 0',
}));

export const CampaignSummaryHeader = styled('header')(() => ({
  display: 'flex',
  gap: '2rem',
  justifyContent: 'space-between',
}));

export const CampaignSummaryTitleInfo = styled('div')(() => ({
  display: 'flex',
  gap: '1.25rem',
  '& .campaign-logo': {
    width: '120px',
    height: '110px',
    objectFit: 'cover',
    borderRadius: '0.25rem',
  },
}));

export const CampaignTitleContainer = styled('div')(() => ({
  display: 'flex',
  gap: '0.75rem',
  flexDirection: 'column',
  h3: {
    font: '500 1.25rem/1rem Poppins,sans-serif',
    color: '#000000',
    margin: 0,
    textTransform: 'capitalize',
  },
}));

export const CampaignTypeContainer = styled('div')(() => ({
  display: 'flex',
  gap: '0.5rem',
  alignItems: 'center',
  '& p': {
    font: '400 0.873rem/1rem Poppins,sans-serif',
    color: '#475467',
    margin: 0,
    textTransform:'capitalize'
  },
  '& img': {
    width: '24px',
    height: '24px',
    objectFit: 'cover',
  },
}));

export const CampaignStatusPill = styled('div')(({ status = 'COMPLETED' }) => {
  const uppercasedStatus = status.toUpperCase();

  return {
    padding: '0.25rem 0.5rem',
    borderRadius: '0.75rem',
    border: `1px solid ${
      isCampaignCompleted(uppercasedStatus) ? '#B3DAC7' : '#B6CFF3'
    }`,
    background: `${
      isCampaignCompleted(uppercasedStatus) ? '#EBF5F0' : '#ECF2FC'
    }`,
    color: `${isCampaignCompleted(uppercasedStatus) ? '#008243' : '#0B5CD7'}`,
    font: '500 0.875rem/1rem Poppins,sans-serif',
    width: 'fit-content',
    textTransform:'capitalize'
  };
});

export const CampaignSummaryOverview = styled('div')(() => ({
  padding: '1.5rem 0',
  borderBottom: '1px solid #E4E7EC',
  display: 'grid',
  gridTemplateColumns: '35% 1fr',
  gap: '2rem',
}));

export const CampaignPurposeContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  textAlign: 'left',
  justifyContent: 'space-between',
  flex: 1,
  '& .label': {
    font: '500 0.875rem/1rem Poppins,sans-serif',
    color: '#101828',
    margin: 0,
    marginBottom: '0.25rem',
  },
  '& .value': {
    font: '400 0.875rem/1rem Poppins,sans-serif',
    color: '#667085',
    margin: 0,
  },
  '& .platform-icon-container': {
    display: 'flex',
    gap: '0.5rem',
    marginBottom: '10px',
    '& svg': {
      fontSize: '1.5rem',
      color: '#667085',
    },
  },
}));

export const CampaignInfoCardsContainer = styled('div')(() => ({
  display: 'flex',
  gap: '1rem',
  flex: 1,
}));
