import React, { useMemo, useState } from 'react';
import { Container } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useInfiniteQuery, useQueryClient } from 'react-query';
import createAPIInstance from 'src/services/api';
import { cloutV2Endpoints } from 'src/services/helpers';
import InfiniteScroll from 'react-infinite-scroll-component';
import Sidebar from '../common/components/Sidebar';
import Article from '../common/components/Article';
import ArticleSkeleton from '../common/components/Article/Skeleton';
import { transformBrandedArticlesData } from './utils';
import { Wrapper, ZeroStateWrapper } from './styles';
import { Articles, ArticlesSkeleton } from '../styles';
import ZeroState from '../common/components/ZeroState';

function Branded({ toggleEditorModal }) {
  const queryClient = useQueryClient();
  const [selectedTopics, setSelectedTopics] = useState([]);
  const [selectedArticleId, setSelectedArticleId] = useState('');
  const { register, watch } = useForm({
    defaultValues: {
      link: 0,
      pdf: 0,
      image: 0,
      topics: '',
    },
  });
  const watchedValues = watch();

  const selectedFormats = useMemo(() => {
    const { link, pdf, image } = watchedValues;
    return [link, pdf, image].filter((value) => value);
  }, [watchedValues]);

  const { data, isLoading, isSuccess, isFetching, hasNextPage, fetchNextPage } =
    useInfiniteQuery(
      ['branded-articles', selectedFormats, selectedTopics],
      async ({ pageParam = 1 }) => {
        const formatThemes = [...selectedTopics]?.map((topics) => topics?.id);

        const response = await createAPIInstance()?.get(
          `${
            cloutV2Endpoints?.baseUrl
          }/discover/branded_articles?page_no=${pageParam}&themes=${JSON.stringify(
            formatThemes
          )}`
        );
        const result = response?.result;
        if (result && Object.keys(result).length > 0) {
          const transformedData = transformBrandedArticlesData(result);
          return transformedData;
        }
      },
      {
        getNextPageParam: (lastPage) => {
          return lastPage?.pagination?.next_page;
        },
      }
    );

  const invalidateQueries = (articleId) => {
    setSelectedArticleId(articleId);
    queryClient.refetchQueries(['branded-articles']);
  };

  const isArticlesEmpty = useMemo(() => {
    if (Array.isArray(data?.pages) && data.pages.length > 0) {
      const isEmpty =
        data?.pages.filter((page) => page?.articles?.length > 0)?.length === 0;

      return isEmpty;
    }
    return false;
  }, [data?.pages]);

  const brandedData =
    isSuccess &&
    data?.pages.map((page) => {
      return page?.articles?.map((article) => {
        if (isFetching && selectedArticleId === article?.id) {
          return <ArticleSkeleton />;
        }
        return (
          <Article
            key={article.id}
            article={article}
            invalidateQueries={invalidateQueries}
          />
        );
      });
    });

  const loader = () => {
    return (
      <ArticlesSkeleton>
        {Array.from(Array(6)?.keys())?.map((article) => (
          <ArticleSkeleton key={article} />
        ))}
      </ArticlesSkeleton>
    );
  };

  return (
    <Container>
      <Wrapper>
        <Sidebar
          register={register}
          selectedTopics={selectedTopics}
          setSelectedTopics={setSelectedTopics}
          section="branded_content"
          toggleEditorModal={toggleEditorModal}
        />

        {isLoading ? (
          loader()
        ) : (
          <>
            {isArticlesEmpty ? (
              <ZeroStateWrapper>
                <ZeroState />
              </ZeroStateWrapper>
            ) : (
              <InfiniteScroll
                dataLength={+data?.pages?.length * 9 || 0}
                next={() => fetchNextPage()}
                hasMore={hasNextPage && !isLoading}
                loader={loader()}
              >
                <Articles>{brandedData}</Articles>
              </InfiniteScroll>
            )}
          </>
        )}
      </Wrapper>
    </Container>
  );
}

export default Branded;
