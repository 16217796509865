import styled from 'styled-components';
import { Flex, Box } from '@rebass/grid';

export const FlexFullWidth = styled(Flex)`
  width: 100%;
`;

export const Table = styled(FlexFullWidth).attrs({
  className: 'mio-table',
})`
  flex-direction: column;
  font-size: 12px;
`;

export const TableWidget = styled(Table).attrs({
  className: 'mio-table-widget',
})`
  flex-direction: row;
  background-color: #6453ec;
  border-radius: 10px 10px 0px 0px;
  height: 34px;
  color: #cdc9f7;
`;

export const TableTitle = styled.div.attrs({
  className: 'mio-table-title',
})`
  width: 100%;
  padding: 10px 0;
  background-color: #e4e4e5;
  color: #6351ed;
  font-weight: 600;
`;

export const TableHeading = styled(FlexFullWidth).attrs({
  className: 'mio-table-head',
})`
  border-bottom: 1px solid white;
  text-align: center;
`;

export const TableBody = styled(FlexFullWidth).attrs({
  className: 'mio-table-body',
})`
  flex-direction: column;
  max-height: ${({ maxHeight }) => maxHeight || '230px'};
  min-height: ${({ minHeight }) => minHeight || '230px'};
  overflow-y: auto;
  overflow-x: hidden;
  border-bottom-left-radius: 8px;
`;

export const Tr = styled(FlexFullWidth).attrs({
  className: 'mio-table-row',
})`
  border-bottom: ${({ border }) => border || '1px solid #e5e5e5'};
  ${({ alternate }) =>
    alternate === false
      ? ''
      : `&:nth-child(odd) {
        background-color: white;
        .table-cell {
          &:nth-child(odd) {
          }
          &:nth-child(even) {
            background-color: white;
          }
        }
      }
      
      &:nth-child(even) {
        background-color: white;
        .table-cell {
          &:nth-child(odd) {
          }
          &:nth-child(even) {
            background-color: white;
          }
        }
      }`}
`;

export const Th = styled(Box).attrs({
  className: 'mio-table-head-cell',
})`
  flex: 1 1 0px;
  padding: 20px 15px;
  background-color: #e4e4e5;
  color: #6351ed;
  font-weight: 600;
  ${({ align }) => align && `text-align:${align};`}
  ${({ width }) => width && `max-width:${width};`}
`;

export const Td = styled(Box).attrs({
  className: 'table-cell mio-table-body-cell',
})`
  padding: ${({ padding }) => padding || '15px'};
  flex: 1 1 0px;
  ${({ flex }) => flex && `flex:${flex};`}
  ${({ align }) => align && `text-align:${align};`}
  ${({ width }) => width && `max-width:${width};`}
  align-self: ${({ alignSelf }) => alignSelf || ''};

  a {
    text-decoration: none;
  }
`;

export const EmailSearch = styled.input.attrs({
  className: 'email-search',
})`
  border: 0px;
  padding: 2px 0px;
  width: 90%;
  font-size: 12px;
  font-family: Poppins;
  color: #7b7b7b;

  ::placeholder {
    text-align: left;
    color: #afafaf;
    font-size: 10px;
  }
`;
