/* eslint-disable react-hooks/exhaustive-deps */
import { get, isEmpty, map } from 'lodash';
import React, { useState, useEffect } from 'react';
import moment from 'moment';
import {
  Button,
  DripIconButton,
  Loader,
  LoaderWrapper,
} from '../../../components';
import { Flex } from '@rebass/grid';
import {
  formatNewsletterDropdownItem,
  getBucketArticleData,
  getDayMonthYear,
  getMediaUrl,
  themesData,
} from '../../../utils';
import { ContactListTooltipWrapper } from '../DraftOutbox/DraftOutbox.styles';
import { DeleteModal } from '../NewsletterModals/NewsletterModals';
import {
  getNewsletterDetails,
  shareNewsletter,
} from '../../../services/newsletter';
import NewsletterOutboxArticle from '../NewsletterOutboxArticle';
import PreviewNewsletterModal from '../NewsletterModals/PreviewNewsletterModal';
import useQuery from '../../../customHooks/useQuery';
import { useHistory } from 'react-router-dom';
import ScheduledNewsletterModal from '../NewsletterModals/ScheduledNewsletterModal';

const ScheduledOutbox = ({
  outBoxData,
  outBoxLoading,
  page,
  setPage,
  newsletterOutboxRequest,
  newsletterSetState,
  newsletterDeleteRequest,
  newsletterId,
  isArticleDeleting,
  setShowTabs,
  setShowOutbox,
  setSelectedTabIndex,
  socialNetworkData,
  contactGroups,
  isFromCompliance,
  queryId,
  isPremiumAdvisor,
  toggleCloneModal,
  setSelectedNewsletter,
  channelId,
  popularThemes,
  userData
}) => {
  let query = useQuery();
  const history = useHistory();
  const [openModal, setOpenModal] = useState(false);
  const [selectedId, setSelectedId] = useState('');
  const [loading, setLoading] = useState(false);
  const [source, setSource] = useState('');
  const [initialIndex, setInitialIndex] = useState(0);
  const [endIndex, setEndIndex] = useState(0);
  const [openPreviewModal, setOpenPreviewModal] = useState(false);
  const [scheduleValue, setScheduleValue] = useState(null);
  const [dateOpen, setDateOpen] = useState(false);
  const [openScheduledModal, setOpenScheduledModal] = useState(false);
  const [selectedTopic, setSelectedTopic] = useState('');
  const [complianceStat, setComplianceStat] = useState(null);

  useEffect(() => {
    if (
      !isEmpty(get(outBoxData, 'newsletters', [])) &&
      outBoxData.pagination &&
      +outBoxData.pagination.total_count > 0
    ) {
      if (+outBoxData.pagination.total_count > 0 && page === 0) {
        setInitialIndex(1);
        setEndIndex(
          +outBoxData.pagination.total_count < 6
            ? +outBoxData.pagination.total_count
            : 6
        );
      } else if (+outBoxData.pagination.total_count > 0 && page > 0) {
        setInitialIndex(page * 6 + 1);
        setEndIndex(
          (page + 1) * 6 >= +outBoxData.pagination.total_count
            ? +outBoxData.pagination.total_count
            : (page + 1) * 6
        );
      }
    } else {
      setInitialIndex(0);
      setEndIndex(0);
    }
  }, [outBoxData, page]);

  useEffect(() => {
    console.log(
      'FROM useeffect scheduleoutbox  -- - - -- - - ',
      isFromCompliance
    );
    if (isFromCompliance) {
      console.log('query id', queryId);
      onEditClick(queryId, 3, 'Edit', '', null);
    }
  }, [isFromCompliance]);

  const onAccept = () => {
    setOpenModal(false);
    setOpenScheduledModal(false);
    let params = {
      id: selectedId,
      filter: 'scheduled',
    };

    newsletterDeleteRequest({
      params,
    });
  };

  const onReject = () => {
    setOpenModal(false);
    newsletterSetState({
      newsletterId: '',
    });
  };

  const handleDelete = (id) => {
    setOpenModal(true);
    setSelectedId(id);
    newsletterSetState({
      newsletterId: id,
    });
  };

  const getComplianceIds = (data) => {
    let ids = map(data, 'compliance_id');
    ids = ids.filter((i) => !!i);
    if (!isEmpty(ids[0])) {
      return ids[0];
    } else {
      return null;
    }
  };

  const getComplianceStatus = (data) => {
    let ids = map(data, 'compliance_status');

    ids = ids.filter((i) => !!i);
    console.log(ids);
    if (!isEmpty(ids[0])) {
      return ids[0];
    } else {
      return null;
    }
  };

  const onEditClick = (id, index, source, time, complianceStatus) => {
    console.log('ID NEWSLETTER- - - -', id);
    setSelectedId(id);
    setLoading(true);
    setSource(source);
    setComplianceStat(complianceStatus);
    query.delete('type');

    history.replace({
      search: query.toString(),
    });

    getNewsletterDetails({ id })
      .then((res) => {
        if (res.result.success) {
          const data = get(res, 'result.data', {});
          console.log('ID - - - - ', id);
          newsletterSetState({
            newsletterName: data && data.name && data.name,
            scheduledTime:
              data &&
              data.engagement_details &&
              data.engagement_details[0].publish_time,
            isEditMode: true,
            tab: 'scheduled',
            newsletterId: id,
            selectedContacts: data && data.contactlists,
            ccContacts: data && data.cc_email_ids ? data.cc_email_ids : [],
            emailTemplateSelected: data && data.template,
            newsletterArticles:
              data && data.articles && getBucketArticleData(data.articles),
            customData: {
              subject:
                data && data.customized_data && data.customized_data.subject,
              salutation:
                data && data.customized_data && data.customized_data.salutation,
              salutation_text:
                data &&
                data.customized_data &&
                data.customized_data.salutation_text
                  ? formatNewsletterDropdownItem(
                      data.customized_data.salutation_text
                    )
                  : '',
              message:
                data && data.customized_data && data.customized_data.message,
              media1:
                data &&
                data.customized_data &&
                data.customized_data.media_urls &&
                !isEmpty(data.customized_data.media_urls) &&
                getMediaUrl(data.customized_data.media_urls, '', 'original'),
              compliance_status:
                isFromCompliance && data.engagement_details
                  ? getComplianceStatus(data.engagement_details)
                  : complianceStatus,
              complianceId:
                data.engagement_details &&
                getComplianceIds(data.engagement_details),
              title: data && data.customized_data && data.customized_data.title,
              toggle: data && data.customized_data && data.customized_data.toggle
            },
            engagement_details: data.engagement_details,
            leadCapture: {
              lead_gen: data && data.lead_gen,
              referral: data && data.referral,
            },
            cloneDetails:
              data && data.original_newsletter_details
                ? data.original_newsletter_details
                : null,
          });
          setSelectedId('');
          setLoading(false);
          setShowTabs(true);
          setShowOutbox(false);
          setSource('');
          setSelectedTabIndex(index);
        } else {
          setSelectedId('');
          setSource('');
          setLoading(false);
        }
      })
      .catch((err) => {
        setSelectedId('');
        setSource('');
        setLoading(false);
      });
  };

  const getDetails = (id) => {
    setSelectedId(id);
    setLoading(true);
    setSource('Preview');

    getNewsletterDetails({ id })
      .then((res) => {
        if (res.result.success) {
          const data = get(res, 'result.data', {});
          console.log(data);
          newsletterSetState({
            newsletterName: data && data.name && data.name,
            isEditMode: true,
            newsletterId: id,
            scheduledTime: '',
            selectedContacts: [],
            emailTemplateSelected: data && data.template,
            tab: '',
            newsletterArticles:
              data && data.articles && getBucketArticleData(data.articles),
            customData: {
              subject:
                data && data.customized_data && data.customized_data.subject,
              salutation:
                data && data.customized_data && data.customized_data.salutation,
              salutation_text:
                data &&
                data.customized_data &&
                data.customized_data.salutation_text
                  ? formatNewsletterDropdownItem(
                      data.customized_data.salutation_text
                    )
                  : '',
              message:
                data && data.customized_data && data.customized_data.message,
              media1:
                data &&
                data.customized_data &&
                data.customized_data.media_urls &&
                !isEmpty(data.customized_data.media_urls) &&
                getMediaUrl(data.customized_data.media_urls, '', 'original'),
              title: data && data.customized_data && data.customized_data.title,
            },
            leadCapture: {
              lead_gen: data && data.lead_gen,
              referral: data && data.referral,
            },
          });
          setSelectedId('');
          setLoading(false);
          setOpenPreviewModal(true);
          setSource('');
        } else {
          setSelectedId('');
          setSource('');
          setLoading(false);
        }
      })
      .catch((err) => {
        setSelectedId('');
        setSource('');
        setLoading(false);
      });
  };

  const changeScheduleTime = (time) => {
    let newTime = new Date(time);
    newTime.setMinutes(newTime.getMinutes() + 5);
    console.log(newTime);
    const [selectedMonth, selectedDay, selectedYear] = getDayMonthYear(newTime);
    const checkHours =
      newTime.getHours().toString().length === 1
        ? `0${newTime.getHours()}`
        : newTime.getHours();
    const checkMin =
      newTime.getMinutes().toString().length === 1
        ? `0${newTime.getMinutes()}`
        : newTime.getMinutes();
    return `${selectedYear}-${selectedMonth}-${selectedDay}T${checkHours}:${checkMin}:00`;
  };

  const getSnetworkActId = () => {
    const emailAccounts = get(socialNetworkData, 'em', []);
    let snetworkId;
    if (emailAccounts.filter((e) => e.snetworkProvider === 'sg').length > 0) {
      snetworkId = emailAccounts.filter((e) => e.snetworkProvider === 'sg')[0]
        .accountId;
    } else {
      snetworkId = emailAccounts[0].accountId;
    }

    return snetworkId;
  };

  const rescheduleNewsletter = (id, datetime) => {
    setSelectedId(id);
    setScheduleValue(changeScheduleTime(datetime));
    setDateOpen(true);
  };

  const handleModalOpen = (id, name, complianceStatus) => {
    setSelectedId(id);
    setSelectedTopic(name);
    setOpenScheduledModal(true);
    setComplianceStat(complianceStatus);
  };

  const handleDateSubmit = (datetime) => {
    setDateOpen(false);
    let params = {
      snetwork_act_id: getSnetworkActId(),
      re_schedule: 'true',
      schedule_time: datetime || scheduleValue,
    };

    shareNewsletter({ id: selectedId, params: params }).then((response) => {
      if (response.result[0].success) {
        newsletterOutboxRequest({
          params: {
            filter: 'scheduled',
          },
        });
      }
    });
  };

  return (
    <LoaderWrapper
      isLoading={outBoxLoading || isFromCompliance}
      styles={{ position: 'absolute', bottom: '5%' }}
    >
      <Flex
        width="100%"
        justifyContent="center"
        padding="15px 35px"
        flexDirection="column"
      >
        {outBoxData && !isEmpty(outBoxData.newsletters) ? (
          <Flex justifyContent="center" width="100%" flexWrap="wrap">
            {outBoxData.newsletters
              .slice(page * 6, (page + 1) * 6)
              .map((item, index) => (
                <NewsletterOutboxArticle
                  {...item}
                  handleDelete={handleDelete}
                  onEditClick={onEditClick}
                  source={source}
                  selectedId={selectedId}
                  loading={loading}
                  isArticleDeleting={isArticleDeleting}
                  newsletterId={newsletterId}
                  tabSource="Scheduled"
                  getPreviewDetails={getDetails}
                  dateOpen={dateOpen}
                  setDateOpen={setDateOpen}
                  scheduleValue={scheduleValue}
                  handleDateSubmit={handleDateSubmit}
                  rescheduleNewsletter={rescheduleNewsletter}
                  handleOpen={handleModalOpen}
                  isPremiumAdvisor={isPremiumAdvisor}
                  toggleCloneModal={toggleCloneModal}
                  newsletter={item}
                  setSelectedNewsletter={setSelectedNewsletter}
                />
              ))}
            {outBoxData.newsletters.slice(page * 6, (page + 1) * 6).length %
              3 !==
              0 &&
              outBoxData.newsletters.slice(page * 6, (page + 1) * 6).length %
                3 ===
                1 && (
                <>
                  <i
                    aria-hidden="true"
                    style={{
                      width: '215px',
                      height: '208px',
                      margin: '14px 10px 35px 8.2px',
                      padding: '0px',
                    }}
                  ></i>
                  <i
                    aria-hidden="true"
                    style={{
                      width: '215px',
                      height: '208px',
                      margin: '14px 10px 35px 8.2px',
                      padding: '0px',
                    }}
                  ></i>
                </>
              )}
            {outBoxData.newsletters.slice(page * 6, (page + 1) * 6).length %
              3 !==
              0 &&
              outBoxData.newsletters.slice(page * 6, (page + 1) * 6).length %
                3 ===
                2 && (
                <i
                  aria-hidden="true"
                  style={{
                    width: '215px',
                    height: '208px',
                    margin: '14px 10px 35px 8.2px',
                    padding: '0px',
                  }}
                ></i>
              )}
          </Flex>
        ) : (
          <div style={{ textAlign: 'center' }}>No Records Found</div>
        )}
        <Flex width="100%" justifyContent="space-between" pt="10px">
          <Button
            padding="5px 20px"
            height="30px"
            margin="0px"
            onClick={() => setPage(page - 1)}
            disabled={page < 1 ? true : false}
          >
            Previous
          </Button>
          <Flex color={themesData.charcoalGrey} fontSize="13px">
            {initialIndex}-{endIndex} of{' '}
            {outBoxData &&
              outBoxData.pagination &&
              +outBoxData.pagination.total_count}
          </Flex>
          <Button
            padding="5px 20px"
            height="30px"
            margin="0px"
            onClick={() => setPage(page + 1)}
            disabled={
              get(outBoxData, 'pagination.total_count', '0') ===
              endIndex.toString()
            }
          >
            Next
          </Button>
        </Flex>
      </Flex>
      {openModal && (
        <DeleteModal
          open={openModal}
          setOpen={setOpenModal}
          onAccept={onAccept}
          onReject={onReject}
          type="Newsletter"
        />
      )}

      {openPreviewModal && (
        <PreviewNewsletterModal
          open={openPreviewModal}
          setOpen={setOpenPreviewModal}
          channelId={channelId}
          popularThemes={popularThemes}
        />
      )}

      {openScheduledModal && (
        <ScheduledNewsletterModal
          open={openScheduledModal}
          setOpen={setOpenScheduledModal}
          selectedId={selectedId}
          setSelectedId={setSelectedId}
          socialNetworkData={socialNetworkData}
          selectedTopic={selectedTopic}
          setSelectedTopic={setSelectedTopic}
          onAccept={onAccept}
          contactGroups={contactGroups}
          complianceStatus={complianceStat}
          isPremiumAdvisor={isPremiumAdvisor}
          userData={userData}
        />
      )}
    </LoaderWrapper>
  );
};

export default ScheduledOutbox;
