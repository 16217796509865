import React, { useState } from 'react';
import { Flex } from '@rebass/grid';
import {
  AlphabetScrollerWrapper,
  PublisherCardWrapper,
  StyledCross,
  StyledTitle,
  StyledTitleDiv,
} from '../ManagePublisher/styles';
import { Chip } from '../../../componentsV2/Chip';
import { LoaderWrapper } from '../../../components';
import { Tooltip } from '@mui/material';

const TopicListView = ({
  allTopics,
  shortenTitle,
  loader,
  setLoader,
  subscribe,
}) => {
  const [selectedChar, setSelectedChar] = useState('A');

  const getButtonsColumn = () => {
    let btns = [];
    for (let i = 65; i <= 90; i++)
      btns.push(
        <div
          style={{
            color:
              String.fromCharCode(i) === selectedChar ? '#101828' : '#98A2B3',
            padding: '0px 1px',
            cursor: 'pointer',
          }}
          onClick={() => setSelectedChar(String.fromCharCode(i))}
        >
          <div
            style={{
              height: '22px',
              width: '22px',
              textAlign: 'center',
              margin: '2px auto -1px',
            }}
          >
            <strong>{String.fromCharCode(i)}</strong>
          </div>
        </div>
      );
    return btns;
  };
  console.log('ALL TOPICS = ', allTopics);

  return (
    <Flex width="100%">
      <Flex width="100%" flexDirection="row" height="300px" maxHeight="300px">
        <Flex
          display="flex"
          width="100%"
          flexDirection="column"
          padding="10px"
          style={{ backgroundColor: '#F9FAFB' }}
        >
          <Flex>
            {getButtonsColumn().map((item, index) => (
              <Flex>{item}</Flex>
            ))}
          </Flex>

          {/* <Flex display="flex" width="100%" padding="5px 0px 0px 7px" style={{ color: '#101828' }}>
            {selectedChar}
          </Flex> */}
          <hr
            style={{
              border: '1px solid #e4e7ec',
              width: '92%',
              marginRight: '20%',
              marginBottom: '20px',
            }}
          />
          <PublisherCardWrapper
            style={{
              flexWrap: 'wrap',
              justifyContent: 'start',
              maxHeight: '300px',
            }}
          >
            {allTopics
              .filter((topic) => topic.name[0] === selectedChar)
              .map((topic) => (
                <Chip
                  padding="10px"
                  justifyContent="space-between"
                  height="45px"
                  width="22%"
                  boxShadow="0px 1px 2px 0px #1018281F"
                >
                  <StyledTitleDiv style={{ width: '85%' }}>
                    <LoaderWrapper isLoading={loader === topic.name}>
                      <StyledTitle style={{ fontSize: '14px' }}>
                        {/* {shortenTitle(topic.name)} */}
                        {topic?.name && topic?.name?.length > 18 ? (
                          <Tooltip
                            arrow
                            placement="top"
                            title={topic.name}
                            componentsProps={{
                              tooltip: {
                                sx: {
                                  fontFamily: 'Poppins',
                                },
                              },
                            }}
                          >
                            {`${topic?.name.substring(0, 18)}...`}
                          </Tooltip>
                        ) : (
                          topic?.name
                        )}
                      </StyledTitle>
                    </LoaderWrapper>
                  </StyledTitleDiv>
                  {!loader ? (
                    <StyledCross
                      style={{ width: '15%', cursor: 'pointer' }}
                      onClick={() => {
                        setLoader(topic.name);
                        subscribe(topic);
                      }}
                    >
                      <img alt="" src="/assets/images/grey-plus-icon.svg" />
                    </StyledCross>
                  ) : null}
                </Chip>
              ))}
          </PublisherCardWrapper>
        </Flex>
        {/* <AlphabetScrollerWrapper
          style={{
            maxHeight: '300px',
            color: '#98A2B3',
            backgroundColor: '#fff',
          }}
        >
          {getButtonsColumn().map((item, index) => (
            <Flex>{item}</Flex>
          ))}
        </AlphabetScrollerWrapper> */}
      </Flex>
    </Flex>
  );
};

export default TopicListView;
