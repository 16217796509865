import React from 'react';
import styled from 'styled-components';
import {
  ResponsiveContainer,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  Legend,
  Text,
} from 'recharts';
import { isEmpty, get, toLower } from 'lodash';
import { Loader } from '../';
import theme from '../CommonStyles/colors';
import { themesData } from '../../utils/constants';

const axisFontStyle = {
  fontSize: window.innerWidth < 768 ? '8px' : '11px',
  stroke: themesData.purpleyGrey,
  strokeWidth: 0.5,
};

const BarChartWrapper = styled.div`
  height: 100%;
  .recharts-legend-item-text {
    padding-top: 4px;
    font-size: 13px;
  }
  .recharts-tooltip-wrapper {
    border-radius: 4px;
    text-align: left;
    font-size: 12px;
    .recharts-default-tooltip {
      background-color: #000 !important;
      border-radius: 4px;
    }
    * {
      color: #fff;
    }
  }
  .recharts-legend-wrapper {
    bottom: -5px !important;
  }
`;

const CustomizedAxisTick = (props) => {
  const { x, y, icons, stroke, payload, fill, textAnchor, style } = props;
  const value = get(payload, 'value');
  if (!isEmpty(icons)) {
    return <g transform={`translate(${x - 7},${y})`}>{get(icons, value)}</g>;
  }

  return (
    <Text
      x={x}
      y={y}
      stroke={stroke}
      fill={fill}
      className="recharts-text recharts-cartesian-axis-tick-value"
      textAnchor={textAnchor}
      style={style}
      verticalAnchor="start"
      width={75}
    >
      {value}
    </Text>
  );
};

const StackedBarChart = ({
  loading,
  data,
  stackColor,
  xAxisIcons,
  yKey,
  tabContentType,
  legends
}) => {
  /*if(data && data.length == 0 && toLower(tabContentType) === 'engagement') {
    data = [{id:1, name:'tw', value:10}]
  }*/
  if (loading) {
    return <Loader />;
  }
  const bar1Name =
    toLower(tabContentType) === 'content'
      ? 'My content'
      : toLower(tabContentType) === 'engagement'
        ? 'Likes'
        : null;
  const bar2Name =
    toLower(tabContentType) === 'content'
      ? 'Other content'
      : toLower(tabContentType) === 'engagement'
        ? 'Retweets'
        : null;
  const bar3Name = toLower(tabContentType) === 'engagement' ? 'Replies' : null;
  return (
    <>
      <BarChartWrapper>
        <ResponsiveContainer width="100%" height="100%" minHeight="180px">
          <BarChart
            data={data}
            margin={{
              left: 10,
              right: 20,
            }}
          >
            <CartesianGrid horizontal vertical={false} />
            <XAxis
              dataKey="name"
              style={axisFontStyle}
              tick={<CustomizedAxisTick icons={xAxisIcons} />}
              interval={0}
              height='45'
            />
            <YAxis
              style={axisFontStyle}
              domain={[0, 'auto']}
              allowDecimals={false}
              label={<Text
                style={{
                  ...axisFontStyle,
                  fontSize: '15px',
                  letterSpacing: '1px',
                }}
                x={0}
                y={0}
                dx={25}
                dy={(yKey === 'Shares on Linkedin' || yKey === 'Shares on Twitter' || yKey === 'Shares on Facebook') ? 280 : 230}
                offset={0}
                angle={-90}
              >{yKey}</Text>}
            />
            {toLower(tabContentType) === 'content' ||
              toLower(tabContentType) === 'engagement' ? (
                <Tooltip cursor={{ fill: 'transparent' }} />
              ) : (
                <Tooltip cursor={{ fill: 'transparent' }} />
              )}
            <Bar
              name={bar1Name}
              dataKey="value"
              stackId="a1"
              maxBarSize={35}
              fill={stackColor[1]}
            />
            {/* <Bar
              name={bar2Name}
              dataKey="total"
              stackId="a1"
              maxBarSize={35}
              fill={stackColor[0]}
            /> */}
            {toLower(tabContentType) === 'engagement' ? (
              <Bar
                name={bar3Name}
                dataKey="total"
                stackId="a1"
                maxBarSize={35}
                fill={stackColor[2]}
              />
            ) : null}
            {toLower(tabContentType) === 'content' ||
              toLower(tabContentType).includes('engagement') ? (
                <Legend
                  style={{
                    fontSize: '14px',
                    paddingTop: '-4px'
                  }}
                  payload={legends}
                />
              ) : null}
          </BarChart>
        </ResponsiveContainer>
      </BarChartWrapper>
    </>
  );
};

StackedBarChart.defaultProps = {
  stackColor: ['#c95cfc', '#7dc4d0', '#8F84E3'],
};

export default StackedBarChart;
