import React from 'react';
import ContentOverview from './ContentOverview';

const ImagePreviewTab = ({ 
    articleData, 
    handleContentOverviewClick, 
    pdfImage, 
    setPdfImage,
    type, 
    onUpdateTheme, 
    isAdmin,
    nextClick,
    isFromPdf,
    isFromRssFeed,
    themeMessage,
    setThemeMessage,
    isFromImageOnly,
    imageUploaded,
    setImage,
    setError,
    error,
    userSummary,
    setUserSummary,
}) => {

    return (
        <>
            <ContentOverview
                {...articleData}
                handleContentOverviewClick={handleContentOverviewClick}
                pdfImage={pdfImage}
                setPdfImage={setPdfImage}
                type={type}
                onUpdateTheme={onUpdateTheme}
                isAdmin={isAdmin}
                fromImagePreviewTab={true}
                nextClick={nextClick}
                isFromPdf={isFromPdf}
                isFromRssFeed={isFromRssFeed}
                themeMessage={themeMessage}
                setThemeMessage={setThemeMessage}
                userSummary={userSummary}
                setUserSummary={setUserSummary}
                isFromImageOnly={isFromImageOnly}
                imageUploaded={imageUploaded}
                setImage={setImage}
                setError={setError}
                error={error}
            />
        </>
    );
}

export default ImagePreviewTab;