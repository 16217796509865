import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Flex } from '@rebass/grid';
import { isEmpty } from 'lodash';
import React, { useState, useEffect } from 'react';
import { themesData } from '../../../utils';
import {
  Button,
  ButtonNoBorderBackground,
  LoaderWrapper,
} from '../../../components';
import TwoColumnTemplate from '../../../components/EmailTemplates/TwoColumnTemplate';
import OneColumnLayout from '../../../components/EmailTemplates/OneColumnTemplate';
import NoContentLayout from '../../../components/EmailTemplates/NoContentTemplate';
import TemplateSelector from '../../../components/TemplateSelector';
import { updateNewsletter } from '../../../services/newsletter';

const SelectTemplate = ({
  outBoxTabSource,
  setSelectedIndex,
  emailTemplateSelected,
  newsletterSetState,
  newsletterId,
}) => {
  const [loading, setLoading] = useState(false);
  const [selectedValue, setSelectedValue] = useState(emailTemplateSelected);
  const handleBackButtonClick = () => {
    setSelectedIndex(0);
  };

  const handleSaveClick = () => {
    //Save Button for API call
    setLoading(true);
    let params = {
      template: JSON.stringify(selectedValue),
    };

    updateNewsletter({ id: newsletterId, params: params })
      .then((res) => {
        if (res.result.success) {
          setLoading(false);
          newsletterSetState({
            emailTemplateSelected: selectedValue,
          });
          if (selectedValue === 'no-content') {
            setSelectedIndex(3);
          } else {
            setSelectedIndex(2);
          }
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const handleNextClick = () => {
    newsletterSetState({
      emailTemplateSelected: selectedValue,
    });
    if (outBoxTabSource === 'sent') {
      setSelectedIndex(4);
    } else if (selectedValue === 'no-content') {
      setSelectedIndex(3);
    } else {
      setSelectedIndex(2);
    }
  };
  return (
    <Flex width="100%" padding="50px 30px 0px" flexDirection="column">
      <Flex
        width="100%"
        fontSize="14px"
        color={themesData.charcoalGrey}
        style={{ fontWeight: 600 }}
        justifyContent="center"
      >
        Choose a template for this newsletter
      </Flex>
      <Flex
        justifyContent="space-between"
        padding="10px"
        backgroundColor="white"
      >
        <TemplateSelector
          selectedValue={selectedValue}
          setSelectedValue={setSelectedValue}
          fromNewsletter={true}
        >
          <NoContentLayout value="no-content" fromNewsletter={true} />
          <OneColumnLayout value="one-column" fromNewsletter={true} />
          <TwoColumnTemplate value="two-columns" fromNewsletter={true} />
        </TemplateSelector>
      </Flex>

      <Flex
        width="100%"
        alignSelf="flex-end"
        justifyContent="space-between"
        pt="10px"
        pb="10px"
      >
        <LoaderWrapper isLoading={loading}>
          <ButtonNoBorderBackground
            padding="5px 0px 0px"
            onClick={handleBackButtonClick}
          >
            <FontAwesomeIcon icon={faAngleLeft} />
            &nbsp; Back
          </ButtonNoBorderBackground>

          <Button
            bgColor={
              isEmpty(selectedValue) || selectedValue === 'default'
                ? '#AFAFAF'
                : ''
            }
            disabled={isEmpty(selectedValue) || selectedValue === 'default'}
            height="36px"
            width="146px"
            margin="10px 0px 0px"
            onClick={handleNextClick}
          >
            Next
          </Button>

          {outBoxTabSource !== 'sent' ? (
            <ButtonNoBorderBackground
              disabled={isEmpty(selectedValue) || selectedValue === 'default'}
              padding="5px 0px 0px"
              onClick={handleSaveClick}
            >
              Save
            </ButtonNoBorderBackground>
          ) : (
            <div style={{ width: '10%' }}></div>
          )}
        </LoaderWrapper>
      </Flex>
    </Flex>
  );
};

export default SelectTemplate;
