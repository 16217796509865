import React from 'react';
import { DripCampaignContainerV2 } from '../../containers';

import withDripCampaignLayout from '../../hoc/withDripCampaignLayout';

const DripCampaignLayoutV2 = (props) => {
  return <DripCampaignContainerV2 {...props} />;
};

export default withDripCampaignLayout(DripCampaignLayoutV2);
