import * as React from "react";

function Mail(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 18 18" fill="none" {...props}>
      <path
        d="M2.25 3.938h13.5V13.5a.562.562 0 01-.563.563H2.813a.563.563 0 01-.563-.563V3.937z"
        stroke="currentColor"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.75 3.938L9 10.125 2.25 3.937"
        stroke="currentColor"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

const MemoMail = React.memo(Mail);
export default MemoMail;
