/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { Box, Flex } from '@rebass/grid'; 
import { get, isEmpty, find, cloneDeep } from 'lodash';
import {
  HeadingSection,
  SubHeaderSection,
  ConfigureContainerStyle,
  SwitchStyle,
  GroupingContainer,
  SubHeading,
  SaveButton,
  TextInput,
  BorderBottomContainer,
  CheckBoxContent
} from './AdminConfigureContainer.styles';
import PurpleButton from '../../componentsV2/PurpleButton';
import ToggleBar from '../../components/ToggleBar';
import {
  SocialLoginButtons,
  EmailSettingsModal,
  LoaderWrapper,
  Button,
  SocialCheckBoxes,
  ErrorContainer,
} from '../../components';
import SkeletonLoader from './SkeletonLoader';
import {
  getContactsGroups,
  redTailLogin,
  updateComplianceOption,
  updateMembersOption,
  updateMemberSettings,
  updateFirmSettings,
} from '../../services/configure';
import PurpleCheckbox from '../../componentsV2/PurpleCheckbox';
import { actions as contactsActions } from '../../redux/modules/contacts.module';
import { actions as socialNetworkActions } from '../../redux/modules/socialNetwork.module';
import { actions as loginActions } from '../../redux/modules/login.module';
import {
  getRoleType,
  getUserDetails,
  getFirmSettings
} from '../../redux/selector/login.selectors';
import {
  ColorPallete,
  getContactListForEmail,
  ROLE_TYPES,
  salesforceSettingsMapper,
  salesforceTogglesMapper,
} from '../../utils';
import { getSocialAccountURLs } from '../../services/dashboard';
import { emailSmtpConnect, verifyDeactivate } from '../../services/configure';
import RedTailModal from './RedTailModal';
import SendgridModal from './SendgridModal/SendgridModal';
import AdminRadio from './AdminRadioButton';
import { getComplianceSettingsAdmin } from '../../services/configure/index';
import MembersTable from './MembersTable/MembersTable';
import Profile from './Profile';
import AdvisorSettings from '../AdvisorSettings/AdvisorSettings';
import BccField from '../../components/BccField';
import SwitchRow from '../../componentsV2/SwitchRow';
import GeneralTable from '../../components/GeneralTable';
import "./MembersTable/MembersTable.css";
import linkedIn from '../../assets/logos/linkedInIcon.svg';
import twitter from '../../assets/logos/twitter.svg';
import facebook from '../../assets/logos/facebook.svg';
import gmail from '../../assets/logos/gmail.svg';
import outlook from '../../assets/logos/outlook.svg';
import zoom from '../../assets/logos/zoom.svg';
import sendgrid from '../../assets/logos/sendgrid.svg';
import blankEmail from '../../assets/logos/blankEmail.svg';
import ModalContainer from '../../componentsV2/ModalContainer';
import ManagePublisher from './ManagePublisher';
import ManageTopics from './ManageTopics/ManageTopics';
import DeactivatePlatformModal from './DeactivatePlatformModal/DeactivatePlatformModal';

const toggleItems = [
  {
    name: 'Profile',
    key: 0
  },
  // {
  //   name: 'Channel Settings',
  //   key: 1
  // },
  {
    name: 'Platforms',
    key: 1
  },
  {
    name: 'Settings',
    key: 2
  },
  {
    name: 'Manage Topics',
    key: 3
  },
  {
    name: 'Manage Publishers',
    key: 4
  },
];

const ConfigureContainer = ({
  contactError,
  contactsRequest,
  userData,
  socialNetworkData,
  socialNetworkDelete,
  socialNetworkDataFetching,
  loginRequest,
  socialNetworkStatusRequest,
  userDetails,
  complianceAdmin,
  disabledPlatforms,
  assetManagerAdmin,
  adminEnterprise,
  roleType,
  userId,
  isEnterpriseLevelAdmin,
  isFirmLevelAdmin,
  firmSettings,
  selectedTabIndex,
  setSelectedTabIndexRequest
}) => {
  const [tabIndex, setTabIndex] = useState(0);

  const complianceOptionMapper = {
    'always_on': 'on',
    'always_off': 'off',
    'optional': 'optional'
  }

  const updateTabIndex = (input) => {
    setTabIndex(input)
  }

  const getComplianceOption = (firmCompliance, companyId) => {
    const choice = get(firmCompliance, `${[companyId]}.compliance_is`, 'optional');
    return complianceOptionMapper[choice];
  }
  const [toggleAll, setToggleAll] = useState({
    add_content: false,
    lead_capture: false,
    share_content: false
  });
  const [socialAccountURL, setSocialAccountURL] = useState(null);
  const [emailModal, setEmailModal] = useState(false);
  const [redTailModal, setRedTailModal] = useState(false);
  const [sendGridModal, setSendGridModal] = useState(false);
  const [redTailError, setRedTailError] = useState(false);
  const [bccList, setBccList] = useState(
    !isEmpty(get(userData, 'bcc_emails', []))
      ? get(userData, 'bcc_emails', []).join()
      : ''
  );
  const [error, setError] = useState('');
  const [toggleHoldings, setToggleHoldings] = useState('true');
  const [toggleEditor, setToggleEditor] = useState('true');
  const [toggleClients, setToggleClients] = useState('true');
  const [toggleCascade, setToggleCascade] = useState('false');
  const [membersToggleAll, setMembersToggleAll] = useState('');
  const[toggleNewsletters, setToggleNewsletters] = useState('true');
  const[toggleEcards, setToggleEcards] = useState('true');
  const[toggleEvents, setToggleEvents] = useState('true');
  const[toggleFirmUpdates, setToggleFirmUpdates] = useState('false');
  const[toggleFinancialNewsletters, setToggleFinancialNewsletters] = useState('true');
  const [toggleGeneralMarketing, setToggleGeneralMarketing] = useState('false');
  const [enterpriseAlias, setEnterpriseAlias] = useState('enterprise');
  const [firmAlias, setFirmAlias] = useState('firm');
  const [firmArray, setFirmArray] = useState([]);
  const [searchString, setSearchString] = useState('');
  const [compliance, setCompliance] = useState((isEnterpriseLevelAdmin || isFirmLevelAdmin) ? getComplianceOption(get(firmSettings, 'firm_compliance', {}), get(userDetails, 'details.company.id', '')) : get(userDetails, 'compliance', 'optional'));
  const [addMember, setAddMember] = useState(
    get(userData, 'member_settings.add_members', '')
  );
  const [loader, setLoader] = useState(false);
  const [advisorsList, setAdvisorsList] = useState([]);
  const [generalSettingsLoading, toggleGeneralSettingsLoading] =
    useState(false);
  const [selectedCheckBoxes, setSelectedCheckBoxes] = useState([
    { platform: 'ln', checked: false, isDisconnected: true },
    { platform: 'tw', checked: false, isDisconnected: true },
    { platform: 'fb', checked: false, isDisconnected: true },
    { platform: 'em', checked: false, isDisconnected: true },
  ]);
  const [emailErrorMessage, setEmailErrorMessage] = useState('');
  const [deactivateModalSettings, setDeactivateModalSettings] = useState({
    isOpen: false,
    accountId: '',
    platform: '',
    no_of_campaigns: ''
  })
  const [enterpriseLevelCompliance, setEnterpriseLevelCompliance] = useState(false);
  const [salesforceSettings, updateSalesforceSettings] = useState(
    !isEmpty(get(userData, 'salesforce_settings'))
      ? userData.salesforce_settings
      : [
          { 
            label: 'Email Sent', 
            Enabled: true, 
            // 'Re-sync': false 
          },
          {
            label: 'Email Opened',
            Enabled: true,
            // 'Re-sync': false,
            'Skip duplicates': false,
          },
          {
            label: 'Email Clicked',
            Enabled: true,
            // 'Re-sync': false,
            'Skip duplicates': false,
          },
          { 
            label: 'Email Unsubscribed', 
            Enabled: true, 
            // 'Re-sync': false 
          },
          {
          label: 'Content Viewed',
            Enabled: true,
            // 'Re-sync': false,
            'Skip duplicates': false,
          },
          { 
            label: 'Lead Generated', 
            Enabled: true, 
            // 'Re-sync': false 
          },
          // { 
          //   label: 'Clout score changed', 
          //   Enabled: true, 
          //   // 'Re-sync': false 
          // },
          { 
            label: 'Webinar Registered', 
            Enabled: true, 
            // 'Re-sync': false 
          },
          { 
            label: 'Webinar Attended', 
            Enabled: true, 
            // 'Re-sync': false 
          },
        ]
  );

  const marketingSwitchData = [
    {
      title: 'Newsletters',
      subTitle: 'Insights and commentary on the markets, economy, financial planning and other relevant topics.',
      switchActive: toggleNewsletters,
      switchAction: setToggleNewsletters
    },
    {
      title: 'Firm Updates',
      subTitle: 'Updates about our business such as personnel changes and office closures.',
      switchActive: toggleFirmUpdates,
      switchAction: setToggleFirmUpdates
    },
    {
      title: 'E-cards',
      subTitle: 'Well-wishes for birthdays, holidays and other special occasions.',
      switchActive: toggleEcards,
      switchAction: setToggleEcards
    },
    {
      title: 'Event Updates',
      subTitle: 'Invitations and related communications for in-person and virtual webcast events.',
      switchActive: toggleEvents,
      switchAction: setToggleEvents
    }
  ];

  const holdingsSwitchData = [
    {
      title: 'My Holdings',
      subTitle: 'Source content based on fund and ticker symbols or holding thematics.',
      switchActive: toggleHoldings,
      switchAction: setToggleHoldings
    },
    {
      title: 'My Editor',
      subTitle: `Source content from Clout's white label library.`,
      switchActive: toggleEditor,
      switchAction: setToggleEditor
    },
    {
      title: 'My Clients',
      subTitle :`Create subgroups across your contacts based on their topical interests.`,
      switchActive: toggleClients,
      switchAction: setToggleClients
    },
    {
      title: 'Firm admins can cascade campaigns',
      subTitle: `Firms can create campaigns, templates, and landing pages and cascade them to their member users`,
      switchActive: toggleCascade,
      switchAction: setToggleCascade
    }
  ]


  const retrieveGroupsList = () => {
    getContactsGroups().then((response) => {
      const data = getContactListForEmail(get(response, 'result.data', []));
      if (!isEmpty(data)) {
        const hasIsDefault = data.filter((d) => d.is_default);
        const firstGroup = isEmpty(hasIsDefault)
          ? get(data, `[0].id`, '')
          : hasIsDefault[0].id;
        contactsRequest({ params: { contactGroup: firstGroup } });
      } else {
        contactsRequest({ params: { contactGroup: '' } });
      }
    });
  };

  useEffect(() => {
    setTabIndex(selectedTabIndex)
  }, [selectedTabIndex])
  

  useEffect(() => {
    const firmCompliance = get(firmSettings, 'firm_compliance', {});
    const firmGeneral = get(firmSettings, 'firm_general', {});
    if(isEnterpriseLevelAdmin) {
      const firms = get(userDetails, 'details.company.firms', []).map(firm => ({
        ...firm,
        complianceIs: get(firmCompliance, `${firm.id}.compliance_is`, 'optional'),
        complianceDone: get(firmCompliance, `${firm.id}.compliance_done`,'enterprise')
      }));
      if(!isEmpty(get(firmGeneral, 'enterprise_alias', '')))
        setEnterpriseAlias(get(firmGeneral, 'enterprise_alias', 'DEFAULT_ENTERPRISE'));
      if(!isEmpty(get(firmGeneral, 'firm_alias', '')))
        setFirmAlias(get(firmGeneral, 'firm_alias', 'DEFAULT_FIRM'));
      if(!isEmpty(get(firmGeneral, 'my_clients', '')))
        setToggleClients(get(firmGeneral, 'my_clients', 'true'));
      if(!isEmpty(get(firmGeneral, 'my_editor', '')))
        setToggleEditor(get(firmGeneral, 'my_editor', 'true'));
      if(!isEmpty(get(firmGeneral, 'my_holding', '')))
        setToggleHoldings(get(firmGeneral, 'my_holding', 'true'));
      if(!isEmpty(get(firmGeneral, 'advisor_cascade_campaign', '')))
        setToggleCascade(get(firmGeneral, 'advisor_cascade_campaign', 'true'));
      setEnterpriseLevelCompliance(get(userDetails, 'compliance', 'optional'));
      setFirmArray(firms);
      if ((get(userDetails, 'details.company.marketing_pref_settings.general_newsletters', ''))) {
        setToggleNewsletters(get(userDetails, 'details.company.marketing_pref_settings.general_newsletters', 'true'));
      }

      if ((get(userDetails, 'details.company.marketing_pref_settings.general_marketing', ''))) {
        setToggleGeneralMarketing(get(userDetails, 'details.company.marketing_pref_settings.general_marketing', 'true'));
      }

      if ((get(userDetails, 'details.company.marketing_pref_settings.ecards', ''))) {
        setToggleEcards(get(userDetails, 'details.company.marketing_pref_settings.ecards', 'true'));
      }

      if ((get(userDetails, 'details.company.marketing_pref_settings.events', ''))) {
        setToggleEvents(get(userDetails, 'details.company.marketing_pref_settings.events', 'true'));
      }

      if ((get(userDetails, 'details.company.marketing_pref_settings.firm_updates', ''))) {
        setToggleFirmUpdates(get(userDetails, 'details.company.marketing_pref_settings.firm_updates', 'false'));
      }

      if ((get(userDetails, 'details.company.marketing_pref_settings.financial_and_economic_newsletters', ''))) {
        setToggleFinancialNewsletters(get(userDetails, 'details.company.marketing_pref_settings.financial_and_economic_newsletters', 'true'));
      }
    }

    if(window.sessionStorage.getItem('platform')) {
      setSelectedTabIndexRequest({
        selectedTabIndex: 1
      }) 
      window.sessionStorage.removeItem('platform')
    } else {
      if (window.sessionStorage.getItem('selectedIndex')) {
      setSelectedTabIndexRequest({
        selectedTabIndex: 1
      }) 
      window.sessionStorage.removeItem('selectedIndex')
    }
    }

    const windowBodyClassList = document.body.classList;
    if (!windowBodyClassList.contains('campaign')) {
      windowBodyClassList.add('campaign');
    }

  }, []);

  useEffect(() => {
    if (tabIndex === 2) {
      toggleGeneralSettingsLoading(true);
      const companyId = get(userData, 'company', '');
      getComplianceSettingsAdmin(companyId)
        .then((response) => {
          setAdvisorsList(response.result.data);
          let advisorsShareList = response.result.data;
          if(!isEmpty(advisorsShareList)) {
            let advisorLength = advisorsShareList.length;
            let addContentLength = advisorsShareList.filter(a => a?.settings?.add_content === "true").length;
            let leadCaptureLength = advisorsShareList.filter(a => a?.settings?.lead_capture === "true").length;
            let shareContentLength = advisorsShareList.filter(a => a?.settings?.share_content === "true").length;
            setToggleAll({
              add_content: advisorLength === addContentLength,
              share_content: advisorLength === shareContentLength,
              lead_capture: advisorLength === leadCaptureLength
            })
          }
          toggleGeneralSettingsLoading(false);
        })
        .catch((err) => {
          console.log('[ERROR] - ', err);
          toggleGeneralSettingsLoading(false);
        });
    }
    getSocialAccountURLs()
      .then((res) => {
        setSocialAccountURL(res.result.data);
      })
      .catch((err) => { });
    retrieveGroupsList();
  }, [tabIndex]);

  useEffect(() => {
    if (disabledPlatforms === '') {
      selectedCheckBoxes.forEach((element) => {
        element.checked = true;
        element.isDisconnected = false;
      });
      setSelectedCheckBoxes([...selectedCheckBoxes]);
    } else if (
      userDetails &&
      userDetails.details &&
      userDetails.details.user &&
      userDetails.details.user.member_settings &&
      userDetails.details.user.member_settings.disabled_social_platforms
    ) {
      selectedCheckBoxes.forEach((element) => {
        if (
          userDetails.details.user.member_settings.disabled_social_platforms.includes(
            element.platform
          )
        ) {
          element.checked = false;
          element.isDisconnected = true;
        } else {
          element.checked = true;
          element.isDisconnected = false;
        }
      });

      setSelectedCheckBoxes([...selectedCheckBoxes]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userDetails]);

  const handleSocialIconClick = (id, network, isDisconnected) => {
    if (isDisconnected) {
      window.sessionStorage.removeItem('platform');
      get(socialNetworkData, id, []).forEach((item) => {
        if (
          (network && network === item.snetworkProvider) ||
          network.length === 0
        ) {
          socialNetworkDelete({
            params: {
              id: `${id}_${item.accountId}`,
            },
          });
          // verifyDeactivate({
          //   "snetwork_uid": item.accountId,
          //   "platform": id === 'em' ? network : id,
          //   "snetwork": id
          // }).then(res => {
          //   if(res.response.can_be_deactivated) {
          //     socialNetworkDelete({
          //       params: {
          //         id: `${id}_${item.accountId}`,
          //       },
          //     });
          //   } else {
          //     setDeactivateModalSettings({
          //       isOpen: true,
          //       accountId: item.accountId,
          //       platform: id === 'em' ? network : id,
          //       no_of_campaigns: res.response.ongoing_campaigns
          //     })
          //   }
            
          // }).catch(err => {
          //   console.log(err);
          // })
        }
      });
    } else {
      if (id === 'ln') {
        window.sessionStorage.setItem('platform', 'ln');
      }
      let link = get(socialAccountURL, `${id}.oauth_url.${network}`, '');
      if (!link) {
        link = get(socialAccountURL, `${id}.oauth_url`, '');
      }

      if (link) {
        //setTabIndex(1)
        window.sessionStorage.setItem('selectedIndex', 1);
        if (link) window.location.href = link;
      } else if (id === 'rt' && !complianceAdmin) {
        setRedTailModal(true);
      }
    }
  };

  const handleRedTailSubmit = ({ username, password }) => {
    redTailLogin(username, password)
      .then((res) => {
        if (res.result.success) {
          socialNetworkStatusRequest();
          setRedTailModal(false);
        }
      })
      .catch((err) => {
        setRedTailError(true);
      });
  };

  const checkComplianceAdmin = get(userDetails, 'compliance_admin', '');

  const getSalesforcePayload = () => {
    const salesforcePayload = {};
    salesforceSettings.forEach((setting, index) => {
      const salesforceToggles = {};
      Object.keys(salesforceSettings[index])
        .slice(1)
        .forEach((toggle) => {
          salesforceToggles[salesforceTogglesMapper[toggle]] =
            salesforceSettings[index][toggle].toString();
        });
      salesforcePayload[salesforceSettingsMapper[setting.label]] =
        salesforceToggles;
    });
    return salesforcePayload;
  };

  const handleEmailModalSubmit = (payload) => {
    const data = {
      encryption: payload.port,
      name: payload.name,
      password: payload.password,
      port: payload.smtpPort,
      snetwork: 'em',
      snetwork_provider: 'smtp',
      url: payload.smtpHost,
      username: payload.email,
    };

    emailSmtpConnect(data)
      .then((res) => {
        if (res && res.result.success) {
          setEmailModal(false);
          socialNetworkStatusRequest();
        }
      })
      .catch((err) => {
        setEmailErrorMessage(err.message);
      });
  };

  const onBottomTextClick = (platform, emailPlaftform) => {
    if (getBottomText(platform).isDisconnected) {
      window.sessionStorage.removeItem('platform');
      get(socialNetworkData, platform, []).forEach((item) => {
        if (
          (emailPlaftform && emailPlaftform === item.snetworkProvider) ||
          !emailPlaftform
        ) {
          socialNetworkDelete({
            params: {
              id: `${platform}_${item.accountId}`,
            },
          });
        }
      });
    } else {
      handleSocialIconClick(
        platform,
        emailPlaftform,
        getBottomText(platform).isDisconnected
      );
    }
  };

  const getBottomText = (platform, emailPlaftform) => {
    let platformDetails = get(socialNetworkData, platform, []);
    if (emailPlaftform) {
      platformDetails = find(platformDetails, {
        snetworkProvider: emailPlaftform,
      });
    }
    const text = isEmpty(platformDetails) ? 'Connect' : 'Disconnect';
    let account = '';
    if (platform !== 'em' && platformDetails && platformDetails.length > 0) {
      if (platform === 'tw') {
        account = platformDetails[0].name.account;
      } else if (platform === 'ln' || platform === 'fb') {
        account =
          platformDetails[0].name.first + ' ' + platformDetails[0].name.last;
      }
    }
    if (
      platform === 'em' &&
      platformDetails &&
      (emailPlaftform === 'google' ||
        emailPlaftform === 'outlook' ||
        emailPlaftform === 'smtp')
    ) {
      account = platformDetails.email;
    }
    if (
      (platform === 'sg' || platform === 'zm') &&
      platformDetails &&
      platformDetails.length > 0
    ) {
      account = platformDetails[0].email;
    }
    return {
      bottomText: text,
      isDisconnected: text === 'Disconnect',
      account,
    };
  };

  const handleComplianceChange = () => {
    setLoader(true);
    const companyId = get(userData, 'company', '');
    const advisorsData = advisorsList.map((advisor) => {
      return {
        uid: advisor.id,
        share_content: advisor.settings.share_content,
        add_content: advisor.settings.add_content,
        lead_capture: advisor.settings.lead_capture,
      };
    });

    const sfSettingsPayload = getSalesforcePayload();
    advisorsData.push({
      uid: userId,
      salesforce_settings: sfSettingsPayload,
      bcc_emails: bccList && !isEmpty(bccList.split(',')) ? bccList.split(',') : []
    });
   
    updateComplianceOption(compliance, advisorsData, companyId)
      .then((res) => {
        if (res.result.success) {
          loginRequest({ noRefresh: true });
          setLoader(false);
        }
      })
      .catch((err) => console.log('ERROR - ', err));
  };

  const firmSettingsSaveHandler = () => {
    setLoader(true);
    const advisorsData = advisorsList.map((advisor) => {
      return {
        uid: advisor.id,
        share_content: advisor.settings.share_content,
        add_content: advisor.settings.add_content,
        lead_capture: advisor.settings.lead_capture,
      };
    });

    const sfSettingsPayload = getSalesforcePayload();
    advisorsData.push({
      uid: userId,
      salesforce_settings: sfSettingsPayload,
    });
   
    const firmSettings = {
      firm_general: {
        enterprise_alias: enterpriseAlias,
        firm_alias: firmAlias,
        my_holding: toggleHoldings,
        my_editor: toggleEditor,
        my_clients: toggleClients,
        advisor_cascade_campaign: toggleCascade
      },
      firm_compliance: {},
    }
    firmArray.forEach(firm => {
      firmSettings["firm_compliance"][firm.id] = {
        "compliance_is": firm.complianceIs,
        "compliance_done": firm.complianceDone,
      }
    })
    const payload = {
     extra_settings: JSON.stringify({firm_settings: firmSettings}),
    }
    let marketing_pref_settings = {
      marketing_pref_settings: JSON.stringify({
        general_newsletters: toggleNewsletters,
        ecards: toggleEcards,
        events: toggleEvents,
        firm_updates: toggleFirmUpdates,
        financial_and_economic_newsletters: toggleFinancialNewsletters,
       })
    }
    const companyId = get(userData, 'company', '');
    const filteredData = selectedCheckBoxes.filter((s) => !s.checked);
    const disabledSocialNetworks = pluck(filteredData, 'platform');

    updateComplianceOption(enterpriseLevelCompliance, advisorsData, companyId, marketing_pref_settings)
    .then(res => {
      return updateFirmSettings({payload, id: userId});
    }).then(res => {
      return updateMemberSettings(disabledSocialNetworks, companyId);
    })
    .then(res => { 
      if (res.result.success) {
        loginRequest({ noRefresh: true });
        setLoader(false);
      }
    })
    .catch(error => {
      setLoader(false);
      setError('Some error occured');
    })
  }


  const handleMembersOption = () => {
    setLoader(true);
    const companyId = get(userData, 'company', '');
    updateMembersOption(addMember, companyId).then((res) => {
      if (res.result.success) {
        loginRequest({ noRefresh: true });
        setLoader(false);
      }
    });
  };

  const handleAttrChange = (checked, index) => {
    const settingsArrayCopy = [...salesforceSettings];
    settingsArrayCopy[index]['Enabled'] = checked;
    updateSalesforceSettings(settingsArrayCopy);
  };

  const onEmailClick = (isDisconnected) => {
    if (!complianceAdmin) {
      if (isDisconnected) {
        get(socialNetworkData, 'em', []).forEach((item) => {
          if ('smtp' === item.snetworkProvider) {
            verifyDeactivate({
              "snetwork_uid": item.accountId,
              "platform": 'em',
              "snetwork": 'em'
            }).then(res => {
              if(res.response.can_be_deactivated) {
                socialNetworkDelete({
                  params: {
                    id: `em_${item.accountId}`,
                  },
                });
              } else {
                setDeactivateModalSettings({
                  isOpen: true,
                  accountId: item.accountId,
                  platform: 'em',
                  no_of_campaigns: res.response.ongoing_campaigns
                })
              }
            }).catch(err => {
              console.log(err);
            })
          }
        });
      } else {
        setEmailModal(true);
      }
    }
  };

  const onSendGridClick = (isDisconnected) => {
    if (isDisconnected) setSendGridModal(true);
  };

  const checkedStatus = (platform) => {
    const platformDetails = selectedCheckBoxes.find(
      (c) => c.platform === platform
    );
    return {
      isDisconnected: platformDetails.isDisconnected,
      checked: platformDetails.checked,
    };
  };

  const onCheckBoxClick = (platform) => {
    selectedCheckBoxes.forEach((item, index) => {
      if (item.platform === platform) {
        item.checked = !item.checked;
        item.isDisconnected = !item.isDisconnected;
      }
    });
    setSelectedCheckBoxes([...selectedCheckBoxes]);
    checkedStatus(platform);
  };

  const pluck = (array, key) => {
    return array.map((o) => o[key]);
  };

  const handleMemberSettings = () => {
    setLoader(true);
    const companyId = get(userData, 'company', '');
    const filteredData = selectedCheckBoxes.filter((s) => !s.checked);
    const disabledSocialNetworks = pluck(filteredData, 'platform');
    updateMemberSettings(disabledSocialNetworks, companyId).then((res) => {
      if (res.result.success) {
        loginRequest({ noRefresh: true });
        setLoader(false);
      }
    });
  };

  const saveSettings = () => {
    setError('');
    let msg;
    const isEmailRegEx =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,3}))$/;
    let valid = true;
    if (valid && !isEmpty(bccList)) {
      valid = bccList
        .split(',')
        .map((em) => em.trim())
        .every((em) => isEmailRegEx.test(em.toLowerCase()));
      if (bccList.split(',').length > 3 && valid) {
        valid = false;
        msg = 'Maximum 3 bcc emails can be added';
      }
    }
    if (!valid) {
      return setError(msg ? msg : 'email is not valid');
    } else if(!msg) {
      handleComplianceChange();
      if (assetManagerAdmin) handleMembersOption();
    }
  };

  const headerDataAdmin =  [
    {
      header: 'Name',
      formatData: (advisor) => (
        <div style={{display: 'table'}}>
          {`${advisor.first_name ?? ''} ${advisor.last_name ?? ''}`}
        </div>
      ),
      key: 'advisor',
    },
    {
      header: 'Add Content',
      formatData: (advisor) => (
        <div style={{display: 'table'}}>
          <SwitchStyle 
            onClick={() => changeHandler(advisor.settings.add_content !== 'true', 'add_content', advisor.id)}
            checked={advisor.settings.add_content === 'true'}
          />
        </div>
      ),
      key: 'add_content',
      formatHeader: () => (
        <div style={{display: 'flex'}}>
          <PurpleCheckbox 
            margin={'4px 2px 0 0'}
            onClick={() => toggleAllHandler('add_content')}
            checked={toggleAll['add_content']}
          />
          <div style={{fontSize: 16, padding: '0', margin: '0 0 0 6px'}}>Add Content</div>
        </div>

      )
    },
    {
      header: 'Share Content',
      formatData: (advisor) => {
        return(
          <div style={{display: 'table'}}>
            <SwitchStyle 
              onClick={() => changeHandler(advisor.settings.share_content !== 'true', 'share_content', advisor.id)}
              checked={advisor.settings.share_content === 'true'}
            />
          </div>
        )
      },
      key: 'share_content',
      formatHeader: () => (
        <div style={{display: 'flex'}}>
          <PurpleCheckbox 
            margin={'4px 2px 0 0'}
            onClick={() => toggleAllHandler('share_content')}
            checked={toggleAll['share_content']}
          />
          <div style={{fontSize: 16, padding: '0', margin: '0 0 0 6px'}}>Share Content</div>
        </div>

      )
    },
    {
      header: 'Lead & Referral Capture',
      formatData: (advisor) => (
        <div style={{display: 'table'}}>
          <SwitchStyle 
            onClick={() => changeHandler(advisor.settings.lead_capture !== 'true', 'lead_capture', advisor.id)}
            checked={advisor.settings.lead_capture === 'true'}
          />
        </div>
      ),
      key: 'lead_capture',
      formatHeader: () => (
        <div style={{display: 'flex'}}>
          <PurpleCheckbox 
            margin={'4px 2px 0 0'}
            onClick={() => toggleAllHandler('lead_capture')}
            checked={toggleAll['lead_capture']}
          />
          <div style={{fontSize: 16, padding: '0px', margin: '0 0 0 6px'}}>Lead & Referral Capture</div>
        </div>

      )
    }
  ];

  const complianceTableDisplay = () => {
    const headerDataLocal = [
      {
        header: 'Name',
        formatData: (row) => (
          <div style={{display: 'table'}}>
            {row.name}
          </div>
        ),
        key: 'name',
      },
      {
        header: 'Always On',
        formatData: (firm, index) => (
          <div style={{display: 'table'}}>
            <CheckBoxContent>
              <div style={{margin: '-1px 12px 0 0'}}>
                <PurpleCheckbox 
                  checked={firm.complianceIs === 'always_on'} 
                  onClick={() => {
                    const firmArrayCopy =
                      cloneDeep(firmArray);
                    firmArrayCopy[index].complianceIs =
                      'always_on';
                    setFirmArray(firmArrayCopy);
                  }}
                />
              </div>
              <span>Always On</span>
            </CheckBoxContent>
          </div>
        ),
        key: 'always_on',
        formatHeader: () => (
          <div style={{display: 'table'}}>
            <CheckBoxContent>
              <div style={{margin: '-1px 12px 0 0'}}>
                <PurpleCheckbox 
                  onClick={() => toggleAllMembers('always_on')}
                  checked={firmArray?.length === firmArray.filter(f => f.complianceIs === 'always_on').length}
                />
              </div>
              <span>Always On</span>
            </CheckBoxContent>
          </div>
        )
      },
      {
        header: 'Always Off',
        formatData: (firm, index) => (
          <div style={{display: 'table'}}>
             <CheckBoxContent>
              <div style={{margin: '-1px 12px 0 0'}}>
                <PurpleCheckbox 
                  checked={firm.complianceIs === 'always_off'} 
                  onClick={() => {
                    const firmArrayCopy =
                      cloneDeep(firmArray);
                    firmArrayCopy[index].complianceIs =
                      'always_off';
                    setFirmArray(firmArrayCopy);
                  }} 
                />
              </div>
              <span>Always Off</span>
            </CheckBoxContent>
          </div>
        ),
        key: 'always_off',
        formatHeader: () => (
          <div style={{display: 'table'}}>
             <CheckBoxContent>
              <div style={{margin: '-1px 12px 0 0'}}>
                <PurpleCheckbox 
                  onClick={() => toggleAllMembers('always_off')}
                  checked={firmArray?.length === firmArray.filter(f => f.complianceIs === 'always_off').length}
                />
              </div>
              <span>Always Off</span>
            </CheckBoxContent>
          </div>
        )
      },
      {
        header: 'Optional',
        formatData: (firm, index) => (
          <div style={{display: 'table'}}>
            <CheckBoxContent>
              <div style={{margin: '-1px 12px 0 0'}}>
                <PurpleCheckbox 
                  checked={firm.complianceIs === 'optional'} 
                  onClick={() => {
                    const firmArrayCopy =
                      cloneDeep(firmArray);
                    firmArrayCopy[index].complianceIs =
                      'optional';
                    setFirmArray(firmArrayCopy);
                  }}
                />
              </div>
              <span>Optional</span>
            </CheckBoxContent>
          </div>
        ),
        key: 'optional',
        formatHeader: () => (
          <div style={{display: 'table'}}>
            <CheckBoxContent>
              <div style={{margin: '-1px 12px 0 0'}}>
                <PurpleCheckbox 
                  onClick={() => toggleAllMembers('optional')}
                  checked={firmArray?.length === firmArray.filter(f => f.complianceIs === 'optional').length}
                />
              </div>
              <span>Optional</span>
            </CheckBoxContent>
          </div>
        )
      },
    ];

    return(
      <div style={{ margin: '14px auto' }}>
        <GeneralTable
          headerData={headerDataLocal}
          tableData={firmArray}
          tableHeader={"Manage Firm Compliance"}
          subTitle={`${firmArray.length} Firms`}
          showPaginate={true}
        />
      </div>
    )
  };

  const changeHandler = (status, row, id) => {
    let users = [...advisorsList];
    users.every((user) => {
      if (user.id === id)
        user.settings[row] = status.toString();
      return user.id !== id;
    });

    let rowlength = users.filter(a => a.settings[row] === 'true');

    setToggleAll({
      ...toggleAll,
      [row]: users?.length === rowlength?.length
    })
    setAdvisorsList(users);
  };

  const toggleAllHandler = (column) => {
    let users = [...advisorsList];
    users.forEach(
      (user) =>
        (user.settings[column] = (!toggleAll[column]).toString())
    );
    setToggleAll({...toggleAll, [column]: !toggleAll[column]})
    setAdvisorsList(users);
  };


  const toggleAllMembers = (input) => {
    let outputArr = cloneDeep(firmArray)
    for(let obj in firmArray){
      outputArr[obj].complianceIs = input;
    };

    setFirmArray(outputArr)
    setMembersToggleAll(input)
  };

  const searchFinder = () => {
    if(searchString.length > 0){
      return advisorsList.filter((item)=>item.first_name.toLowerCase().includes(searchString.toLowerCase()) || item.last_name.toLowerCase().includes(searchString.toLowerCase()))
    } else return advisorsList
  };

  const enterpriseAdminSettingsDisplay = () => {
    return(
      <div style={{paddingBottom: 40}}>
      <BorderBottomContainer>
        <HeadingSection margin="10px 0px 5px">
          Enterprise Compliance Settings
        </HeadingSection>
        <SubHeaderSection>
          Manage the compliance settings for enterprise
        </SubHeaderSection>
        <div style={{display: 'flex', justifyContent: 'start', marginTop: 10}} >
          <CheckBoxContent style={{ width: '160px' }}>
            <div style={{margin: '-1px 12px 0 0'}}>
              <PurpleCheckbox checked={enterpriseLevelCompliance === 'on'} onClick={() => setEnterpriseLevelCompliance('on')} />
            </div>
            <span>Always On</span>
          </CheckBoxContent>
          <CheckBoxContent style={{ width: '160px' }}>
            <div style={{margin: '-1px 12px 0 0'}}>
              <PurpleCheckbox checked={enterpriseLevelCompliance === 'off'} onClick={() => setEnterpriseLevelCompliance('off')} />
            </div>
            <span>Always Off</span>
          </CheckBoxContent>
          <CheckBoxContent style={{ width: '160px' }}>
            <div style={{margin: '-1px 12px 0 0'}}>
              <PurpleCheckbox checked={enterpriseLevelCompliance === 'optional'} onClick={() => setEnterpriseLevelCompliance('optional')} />
            </div>
            <span>Optional</span>
          </CheckBoxContent>
        </div>
      </BorderBottomContainer>

      {(addMember === 'true' || adminEnterprise) && (
          <div style={{width: '100%',}}>
            <HeadingSection margin="10px 0px 5px">Allowed Member Platforms</HeadingSection>
            <div style={{display: 'flex', justifyContent: 'start'}}>
              <SocialCheckBoxes
                onCheckBoxClick={onCheckBoxClick}
                buttons={[
                  {
                    name: 'LinkedIn',
                    icon: linkedIn,
                    bottomText: 'LinkedIn',
                    isDisconnected: checkedStatus('ln').isDisconnected,
                    platform: 'ln',
                    checked: checkedStatus('ln').checked,
                    bgColor: '#c95cfc',
                  },
                  {
                    name: 'Twitter',
                    icon: twitter,
                    bottomText: 'Twitter',
                    isDisconnected: checkedStatus('tw').isDisconnected,
                    platform: 'tw',
                    checked: checkedStatus('tw').checked,
                    bgColor: '#c95cfc',
                  },
                  {
                    name: 'Facebook',
                    icon: facebook,
                    bottomText: 'Facebook',
                    isDisconnected: checkedStatus('fb').isDisconnected,
                    platform: 'fb',
                    checked: checkedStatus('fb').checked,
                    bgColor: '#c95cfc',
                  },
                  {
                    name: 'Email (SMTP)',
                    icon: blankEmail,
                    bottomText: 'Email',
                    iconHeight: 50,
                    isDisconnected: checkedStatus('em').isDisconnected,
                    platform: 'em',
                    checked: checkedStatus('em').checked,
                    bgColor: '#c95cfc',
                  },
                ]}
              />
            </div>
            {/* <Box pt="3%">
              <LoaderWrapper isLoading={loader}>
                <SaveButton onClick={handleMemberSettings} children={'Save'} />
              </LoaderWrapper>
            </Box> */}
          </div>
        )}

        <hr style={{ width: '100%', margin: '15px auto 20px', border: '0.5px solid #e4e7ec'}}/>

        <HeadingSection margin="10px 0px 4px">
            Marketing Preferences
        </HeadingSection>
        <div style={{padding: 0}}>
          <div
            style={{
              display: 'table',
              width: '100%'
            }}
          >  
            {
              marketingSwitchData.map((card, index) => {
                return <SwitchRow key={index} index={index} cardData={card} />
              })
            }
        </div>
        <hr style={{ width: '100%', margin: '10px auto 20px', border: '0.5px solid #e4e7ec'}}/>
      </div>

      {!isEmpty(get(socialNetworkData, 'sf', [])) && (
        <>
          <HeadingSection>
            Salesforce settings
          </HeadingSection>
          <GroupingContainer style={{padding: 0}}>
              <AdvisorSettings
                settingsArray={salesforceSettings}
                changeHandler={handleAttrChange}
              />
          </GroupingContainer>
        </>
      )}
      <div>
        <HeadingSection>
          Firm Level Settings
        </HeadingSection>

        <div style={{display: 'flex', justifyContent: 'space-between', marginTop: 10}}>
          <div style={{display: 'table', width: '49%'}}>
            <SubHeading style={{height: 20, margin: '3px 0px 10px'}}>
              Enterprise Level is referred to as:
            </SubHeading>
            <TextInput
                paddingTop="2px"
                type="text"
                id="name"
                name="enterprise"
                width={'100%'}
                value={enterpriseAlias}
                onChange={(e) => setEnterpriseAlias(e.target.value)}
              />
          </div>
          <div style={{display: 'table', width: '49%'}}>
            <SubHeading style={{height: 20, margin: '3px 0px 10px'}}>
              Firm Level is referred to as:
            </SubHeading>
            <TextInput
              type="text"
              id="name"
              name="Firm"
              width={'100%'}
              value={firmAlias}
              onChange={(e) => setFirmAlias(e.target.value)}
            />
          </div>
        </div>
            
        <div
          style={{
            display: 'flex',
            justifyContent: 'start',
            marginTop: 5
          }}
        >
          <div
            style={{
              display: 'table',
              width: '100%'
            }}
          >  
            {
              holdingsSwitchData.map((card, index) => {
                return <SwitchRow key={index} index={index} cardData={card} />
              })
            }
          </div>
        </div>
      </div>

      <hr style={{ width: '100%', margin: '10px auto 20px', border: '0.5px solid #e4e7ec'}}/>

        {!isEmpty(advisorsList) && addMember === 'true' ? (
          <BorderBottomContainer style={{ padding: '0 0 25px'}}>
            <HeadingSection margin={'0'}>
              Advisor Permissions
            </HeadingSection>
            <SubHeaderSection style={{margin:'0px 0 14px'}}>
              Manage content and lead capture permissions for your advisors.
            </SubHeaderSection>
            <GeneralTable
              headerData={headerDataAdmin}
              tableData={searchFinder()}
              tableHeader={"Financial Advisors"}
              subTitle={`${advisorsList.length} Members`}
              showPaginate
              showSearch
              searchString={searchString}
              setSearchString={setSearchString}
            />
          </BorderBottomContainer>
       ) : null}

      <HeadingSection style={{ margin: '0' }}>
          Firm Compliance Settings:
        </HeadingSection>
        <SubHeaderSection>
          Manage the compliance settings on a firm-by-firm basis.
        </SubHeaderSection>
        {
          complianceTableDisplay()
        }
        <LoaderWrapper isLoading={loader}>
          <SaveButton
            children="Save"
            onClick={firmSettingsSaveHandler}
          />
        </LoaderWrapper>
       
    </div>
    )
  }

  const pageDisplay = () => {
    switch (tabIndex) {
      case 0:
        return (
          <Profile
            userData={userData}
            socialAccountURL={socialAccountURL}
            setSocialAccountURL={setSocialAccountURL}
            roleType={roleType}
          />
        );
      case 1:
        return (
          <>
            <div>
              <HeadingSection margin={'0 0 -5px'}>
                Connect Digital Platforms
              </HeadingSection>
              <div style={{ display: 'flex', justifyContent: 'start' }}>
                {!socialNetworkDataFetching ? (
                  <SocialLoginButtons
                    onBottomTextClick={onBottomTextClick}
                    buttons={[
                      {
                        name: 'LinkedIn',
                        icon: linkedIn,
                        bottomText: getBottomText('ln').bottomText,
                        onClick: () =>
                          handleSocialIconClick(
                            'ln',
                            '',
                            getBottomText('ln').isDisconnected,
                          ),
                        isDisconnected: getBottomText('ln').isDisconnected,
                        platform: 'ln',
                        account: getBottomText('ln').account,
                      },
                      {
                        name: 'Twitter',
                        icon: twitter,
                        bottomText: getBottomText('tw').bottomText,
                        onClick: () =>
                          handleSocialIconClick(
                            'tw',
                            '',
                            getBottomText('tw').isDisconnected
                          ),
                        isDisconnected: getBottomText('tw').isDisconnected,
                        platform: 'tw',
                        account: getBottomText('tw').account,
                      },
                      {
                        name: 'Facebook',
                        icon: facebook,
                        bottomText: getBottomText('fb').bottomText,
                        onClick: () =>
                          handleSocialIconClick(
                            'fb',
                            '',
                            getBottomText('fb').isDisconnected
                          ),
                        isDisconnected: getBottomText('fb').isDisconnected,
                        platform: 'fb',
                        account: getBottomText('fb').account,
                      },
                      {
                        name: 'Google',
                        icon: gmail,
                        bottomText: getBottomText('em', 'google').bottomText,
                        onClick: () =>
                          handleSocialIconClick(
                            'em',
                            'google',
                            getBottomText('em', 'google').isDisconnected
                          ),
                        isDisconnected: getBottomText('em', 'google')
                          .isDisconnected,
                        platform: 'em',
                        emailPlaftform: 'google',
                        account: getBottomText('em', 'google').account,
                      },
                      {
                        name: 'Outlook',
                        icon: outlook,
                        bottomText: getBottomText('em', 'outlook').bottomText,
                        onClick: () =>
                          handleSocialIconClick(
                            'em',
                            'outlook',
                            getBottomText('em', 'outlook').isDisconnected
                          ),
                        isDisconnected: getBottomText('em', 'outlook')
                          .isDisconnected,
                        iconHeight: 50,
                        platform: 'em',
                        emailPlaftform: 'outlook',
                        account: getBottomText('em', 'outlook').account,
                      },

                      {
                        name: 'Zoom',
                        icon: zoom,
                        bottomText: getBottomText('zm').bottomText,
                        onClick: () =>
                          handleSocialIconClick(
                            'zm',
                            '',
                            getBottomText('zm').isDisconnected
                          ),
                        isDisconnected: getBottomText('zm').isDisconnected,
                        iconHeight: 50,
                        iconWidth: 100,
                        platform: 'zm',
                        account: getBottomText('zm').account,
                      },

                      {
                        name: 'Email (SMTP)',
                        icon: blankEmail,
                        bottomText: getBottomText('em', 'smtp').bottomText,
                        onClick: () =>
                          onEmailClick(
                            getBottomText('em', 'smtp').isDisconnected
                          ),
                        isDisconnected: getBottomText('em', 'smtp')
                          .isDisconnected,
                        platform: 'em',
                        emailPlaftform: 'smtp',
                        iconHeight: 50,
                        account: getBottomText('em', 'smtp').account,
                      },
                      {
                        name: 'Sendgrid',
                        icon: sendgrid,
                        bottomText:
                          socialNetworkData &&
                          socialNetworkData['sg'] &&
                          socialNetworkData['sg'].length > 0 &&
                          socialNetworkData['sg'][0].verification_status ===
                            'verified' &&
                          socialNetworkData['sg'][0].status === 'active'
                            ? 'Disconnect'
                            : 'Connect',
                        onClick: () => onSendGridClick(true),
                        isDisconnected:
                          socialNetworkData &&
                          socialNetworkData['sg'] &&
                          socialNetworkData['sg'].length > 0 &&
                          socialNetworkData['sg'][0].verification_status ===
                            'verified' &&
                          socialNetworkData['sg'][0].status === 'active'
                            ? true
                            : false,
                        platform: 'sg',
                        iconHeight: 50,
                        account: getBottomText('sg').account,
                      },
                    ]}
                  />
                ) : (
                  <div
                    style={{
                      width: 'calc(100vw - 240px)',
                      margin: '20px 0 0 -2%',
                      padding: 0,
                    }}
                  >
                    <div style={{ width: '100%', margin: '0', padding: 0 }}>
                      <SkeletonLoader />
                    </div>
                  </div>
                )}
              </div>
            </div>
            <ModalContainer
              headerBorder={true}
              modalStatus={emailModal}
              padding="10px 20px 0"
              closeModal={() => setEmailModal(!emailModal)}
              children={
                <EmailSettingsModal
                  closeModal={() => setEmailModal(!emailModal)}
                  handleModalSubmit={handleEmailModalSubmit}
                  emailErrorMessage={emailErrorMessage}
                />
              }
              title={'Email Settings'}
            />
            <RedTailModal
              open={redTailModal}
              handleClose={() => setRedTailModal(false)}
              handleModalSubmit={handleRedTailSubmit}
              modalError={redTailError}
            />
            <ModalContainer
              headerBorder={true}
              modalStatus={sendGridModal}
              padding="10px 20px 0"
              closeModal={() => setSendGridModal(!sendGridModal)}
              children={
                <SendgridModal
                  adminModal={false}
                  handleClose={() => setSendGridModal(!sendGridModal)}
                />
              }
              title={'Connect with Sendgrid'}
            />
          </>
        );
      case 2:
        return (
          <div style={{ width: '80%', margin: '10px auto' }}>
            {isEnterpriseLevelAdmin ? (
              enterpriseAdminSettingsDisplay()
            ) : (
              <>
                {!complianceAdmin && checkComplianceAdmin && (
                  <>
                    <div>
                      {assetManagerAdmin ? (
                        <>
                          <Box width={1} pt={'4%'}>
                            Member settings
                          </Box>
                          <Box width={1} mx="auto" pt="3%">
                            <Flex width={1} justifyContent="center">
                              <AdminRadio
                                label="Enable"
                                id="members-on"
                                name="assetadmin"
                                value="on"
                                defaultChecked={addMember === 'true'}
                                onChange={() => setAddMember('true')}
                                bgColor="#c95cfc"
                              />
                              <AdminRadio
                                label="Disable"
                                id="members-off"
                                name="assetadmin"
                                value="false"
                                bgColor="#c95cfc"
                                defaultChecked={addMember === 'false'}
                                onChange={() => setAddMember('false')}
                              />
                            </Flex>
                          </Box>
                        </>
                      ) : null}
                      <LoaderWrapper
                        styles={{ marginTop: '2rem' }}
                        isLoading={generalSettingsLoading}
                      >
                        <BorderBottomContainer>
                          <HeadingSection
                            margin={'20px 0 5px'}
                            data-testid="compliance-settings"
                          >
                            Compliance settings
                          </HeadingSection>
                          <SubHeaderSection>
                            Manage the compliance settings across all advisors
                          </SubHeaderSection>
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'start',
                              marginTop: 10,
                            }}
                          >
                            <CheckBoxContent>
                              <div style={{ margin: '-1px 12px 0 0' }}>
                                <PurpleCheckbox
                                  disabled
                                  checked={compliance === 'on'}
                                />
                              </div>
                              <span>Always On</span>
                            </CheckBoxContent>
                            <CheckBoxContent>
                              <div style={{ margin: '-1px 12px 0 0' }}>
                                <PurpleCheckbox
                                  disabled
                                  checked={compliance === 'off'}
                                />
                              </div>
                              <span>Always Off</span>
                            </CheckBoxContent>
                            <CheckBoxContent>
                              <div style={{ margin: '-1px 12px 0 0' }}>
                                <PurpleCheckbox
                                  disabled
                                  checked={compliance === 'optional'}
                                />
                              </div>
                              <span>Optional</span>
                            </CheckBoxContent>
                          </div>
                        </BorderBottomContainer>

                        <BccField value={bccList} setValue={setBccList} />
                        {!isEmpty(advisorsList) && addMember === 'true' ? (
                          <BorderBottomContainer style={{ padding: '0 0 30px'}}>
                            <HeadingSection margin={'0px 0 3px'}>
                              Advisor Permissions
                            </HeadingSection>
                            <SubHeaderSection style={{ margin: '0px 0 15px' }}>
                              Manage content and lead capture permissions for
                              your advisors.
                            </SubHeaderSection>
                            <GeneralTable
                              headerData={headerDataAdmin}
                              tableData={searchFinder()}
                              tableHeader={'Financial Advisors'}
                              subTitle={`${advisorsList.length} Members`}
                              showPaginate
                              showSearch
                              searchString={searchString}
                              setSearchString={setSearchString}
                            />
                          </BorderBottomContainer>
                        ) : null}
                      </LoaderWrapper>
                    </div>
                    <form>
                      {!isEmpty(get(socialNetworkData, 'sf', [])) && (
                        <div style={{ margin: '20px auto 40px' }}>
                          <HeadingSection margin={'10px auto 20px'}>
                            Salesforce settings
                          </HeadingSection>
                          <AdvisorSettings
                            settingsArray={salesforceSettings}
                            changeHandler={handleAttrChange}
                          />
                        </div>
                      )}
                      {error && (
                        <ErrorContainer align="center">{error}</ErrorContainer>
                      )}
                    </form>
                    <LoaderWrapper isLoading={loader}>
                      <PurpleButton
                        margin={'20px auto'}
                        onClick={() => {
                          saveSettings();
                        }}
                        testId="compliance-settings-save"
                      >
                        Save
                      </PurpleButton>
                    </LoaderWrapper>
                  </>
                )}
              </>
            )}
          </div>
        );
      case 3:
        return <ManageTopics />;
      case 4:
        return <ManagePublisher />;
      default:
        break;
    }
  };

  return (
    <>
      <ConfigureContainerStyle>      
        <div style={{margin: '0 0 10px'}}>
          <ToggleBar 
            toggleItems={toggleItems} 
            activeToggle={tabIndex} 
            toggleClick={updateTabIndex} 
          />
        </div>
        {
          pageDisplay()
        } 
        {deactivateModalSettings.isOpen && (
          <DeactivatePlatformModal 
            isOpen={deactivateModalSettings.isOpen}
            onClose={setDeactivateModalSettings}
            socialNetworkDelete={socialNetworkDelete}
            deactivateModalSettings={deactivateModalSettings}
          />
        )}
      </ConfigureContainerStyle>
    </>
  );
};

const mapStateToProps = (state) => {
  const roleType = getRoleType(state);
  const complianceAdmin = ROLE_TYPES.ADMIN_COMPLIANCE === roleType;
  const disabledPlatforms = get(
    state.user,
    'data.details.user.member_settings.disabled_social_platforms'
  );
  const assetManagerAdmin = ROLE_TYPES.ADMIN_ASSET_MANAGER === roleType;
  const adminEnterprise = ROLE_TYPES.ADMIN_ENTERPRISE === roleType;
  const isEnterpriseLevelAdmin = get(state, 'user.data.details.company.enterprise', "false") === "true";
  const isFirmLevelAdmin = get(state, 'user.data.details.company.firm', "false") === "true";
  const firmSettings = getFirmSettings({ isEnterpriseLevelAdmin, isFirmLevelAdmin, state });
  return {
    contactError: state.contacts.error,
    userData: getUserDetails(state),
    socialNetworkData: state.socialNetwork.data,
    socialNetworkDataFetching: state.socialNetwork.isFetching,
    userDetails: state.user.data,
    complianceAdmin,
    disabledPlatforms: disabledPlatforms && JSON.parse(disabledPlatforms),
    assetManagerAdmin,
    adminEnterprise,
    userId: state.user.data && state.user.data.uid,
    isEnterpriseLevelAdmin,
    isFirmLevelAdmin,
    firmSettings: firmSettings,
    selectedTabIndex: state?.contacts?.selectedTabIndex
  };
};

const mapDispatchToProps = {
  contactsRequest: contactsActions.api.contacts.request,
  socialNetworkDelete: socialNetworkActions.api.socialNetwork.delete,
  socialNetworkStatusRequest: socialNetworkActions.api.socialNetwork.request,
  loginRequest: loginActions.api.login.request,
  setSelectedTabIndexRequest: contactsActions.api.contacts.selectedtabindex,
};

export default connect(mapStateToProps, mapDispatchToProps)(ConfigureContainer);
