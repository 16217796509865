import { Flex } from '@rebass/grid';
import styled from 'styled-components';

export const LeadCaptureCardWrapper = styled.article.attrs({
    className: 'lead-capture-card-wrapper'
})`
    width: ${({ width }) => width || '215px'}; 
    height: ${({ height }) => height || '230px'};
    margin: ${({ margin }) => margin || '14px 7px 1px 8.2px'};
    border-radius: 8px;
    background-color: #ececec;
    display: flex;
    flex-direction: column;
`;