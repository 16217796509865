import { Flex } from '@rebass/grid'
import React, { useState } from 'react'
import { ColorPallete, Typography } from '../../utils'
import { isEmpty } from 'lodash';
import styled from 'styled-components';

const CircleFlex = styled(Flex)`
    border-radius: 20px;
    border: 1px solid ${ColorPallete.neutral200};
    background-color: ${ColorPallete.white};
    padding: 8px;
    width: 40px;
    height: 40px;
    align-items: center;
    position: absolute;
    cursor: pointer;
    top: ${({ top }) => top || ''};
    left: ${({ left }) => left || ''};
    right: ${({ right }) => right || ''}; 
`;

const CarouselItem = styled(Flex)`
`;

const CarouselWrapper = styled(Flex)`
      
`;


const Carousel = ({
    data
}) => {

    const [index, setIndex] = useState(0);
    let carouselBox = document.querySelector('#carousel');


    const handleNextClick = () => {
        let carouselWidth = carouselBox.clientWidth;
        console.log(carouselBox.clientWidth, carouselBox.scrollLeft);
        carouselBox.scrollLeft = (carouselBox.scrollLeft + carouselWidth) - 32;
        setIndex(index + 3);
    }

    const handlePreviousClick = () => {
        let carouselWidth = carouselBox.clientWidth;
        carouselBox.scrollLeft = (carouselBox.scrollLeft - carouselWidth) + 32;
        setIndex(index - 3);
    }

    console.log(index);
    return (
        <CarouselWrapper id="carousel" width="100%" style={{ overflow: 'hidden', scrollBehavior: 'smooth'}}>
            {!isEmpty(data) && (index + 3 < data.length) && (
                <CircleFlex top="53%" right="10px" onClick={handleNextClick}>
                    <img src="/assets/images/Analytics/ChevronRight.svg" alt="right"/>
                </CircleFlex>
            )}

            {!isEmpty(data) && (index > 0) && (
                <CircleFlex top="53%" left="10px" onClick={handlePreviousClick}>
                    <img src="/assets/images/Analytics/ChevronLeft.svg" alt="right"/>
                </CircleFlex>
            )}
            {data.map((item, index, arr) => (
                <CarouselItem
                    backgroundColor={ColorPallete.neutral50}
                    width="145px"
                    height="150px"
                    padding="16px"
                    margin="0px 16px 16px"
                    flexDirection="column"
                    flex={index + 1 !== arr.length && "1 0 auto"}
                >
                    <Flex width="100%" justifyContent="center">
                        <img height="63" width="63" src="/assets/images/Analytics/Avatar.svg" alt=""/>
                    </Flex>
                    
                    <Flex 
                        width="100%" 
                        justifyContent="center" 
                        pt="10px" 
                        fontSize={Typography.TextLarge} 
                        color={ColorPallete.black}
                    >
                        {item.name}
                    </Flex>

                    <Flex width="100%" justifyContent="center" pt="5px" color={ColorPallete.neutral500} fontSize={Typography.TextSmall}>
                        {item.email}
                    </Flex>
                </CarouselItem>
            ))}

        </CarouselWrapper>
    )
}

export default Carousel