/* eslint-disable react-hooks/exhaustive-deps */
import { Flex } from '@rebass/grid';
import { isEmpty } from 'lodash';
import { isMoment } from 'moment';
import React, { useState, useEffect } from 'react';
import {
  Button,
  ButtonNoBorderBackground,
  Input,
  LoaderWrapper,
  MioSelect,
  MioRadio,
  MioRadioSpan,
  ModalCloseButton,
  Modal,
  ModalBody,
  ModalHeader,
} from '../../components';
import { AdvisorInputWrapper } from '../../components/AdvisorProfile/AdvisorProfile.styles';
import { leadEventHandlers, themesData } from '../../utils';

const LeadCaptureModal = ({
  open,
  setOpen,
  handleContentPost,
  loading,
  emailId,
  cfnEmailErrorMessage,
  clientName,
  firstName,
  lastName,
  message,
  clientEmailId,
  network,
  clientFirstName,
  getTitleName,
  clientLastName,
  handleSubmit,
  userType,
  amFirmName,
  userId,
  eid,
  provider_name,
  page_tag,
  device_type,
  visitor_type,
  ticker,
  clientAum,
  clientContact,
  isMobile,
  clientFirmName,
  leadForm,
  setLeadForm,
  digital_banner
}) => {
  useEffect(() => {
    leadEventHandlers({
      eventName: 'FORM DISPLAY',
      userId,
      eid,
      form_type: 'lead_capture',
      dnetwork: network,
      email_id: emailId,
      first_name: firstName,
      last_name: lastName,
      client_email: clientEmailId,
      client_first_name: clientFirstName,
      client_last_name: clientLastName,
      am_firm_name: amFirmName,
      user_type: userType,
      provider: provider_name,
      page_tag,
      device_type,
      visitor_type,
      ticker,
    });
  }, []);


  const handleClose = () => {
    leadEventHandlers({
      eventName: 'FORM CLOSED',
      userId,
      eid,
      form_type: 'lead_capture',
      dnetwork: network,
      email_id: emailId,
      first_name: firstName,
      last_name: lastName,
      client_email: clientEmailId,
      client_first_name: clientFirstName,
      client_last_name: clientLastName,
      am_firm_name: amFirmName,
      user_type: userType,
      provider: provider_name,
      page_tag,
      device_type,
      visitor_type,
      ticker,
    });
    setOpen(false);
  };

  const investableAssetOptions =
    userType !== 'am'
      ? [
        'Under $100,000',
        '$100,000 to $250,000',
        '$250,000 to $500,000',
        '$500,000 to $1 million',
        'Over $1 million',
      ]
      : [
        'Under $100,000',
        '$100,000 to $1 million',
        '$1 million to $10 million',
        '$10 million to $50 million',
        '$50 million to $100 million',
        '$100 million to $500 million',
        '$500 million to $1 billion',
        'More than $1 billion',
      ];

  const handleChange = (e) => {
    setLeadForm({ ...leadForm, [e.target.name]: e.target.value });
  };

//   const getTitleName = () => {
//     if (amFirmName && !isEmpty(amFirmName) && amFirmName.charAt(0) !== '?') {
//       if (amFirmName?.includes('LTF')) {
//         return amFirmName.replace('LTF', 'LTG');
//       } else {
//         return amFirmName;
//       }
//     } else {
//       return `${firstName && firstName} ${lastName && lastName}`;
//     }
//   };
  

  //   const handleSubmit = (e) => {
  //     e.preventDefault();
  //     let payload = {
  //       fullname: leadForm.fullname,
  //       email: leadForm.email,
  //       mobile: leadForm.mobile,
  //       investable_assets: leadForm.investable_assets,
  //       type: 'leadgen',
  //     };

  //     if(userType === 'am') {
  //       payload.firm_name = leadForm.firm_name;
  //     }

  //     if (payload.mobile === '' || isEmpty(payload.mobile)) {
  //       delete payload.mobile;
  //     }
  //     leadEventHandlers({
  //       eventName: 'FORM SUBMITTED',
  //       userId,
  //       eid,
  //       form_type: 'lead_capture',
  //       dnetwork: network,
  //       email_id: emailId,
  //       first_name: firstName,
  //       last_name: lastName,
  //       client_email: leadForm.email || clientEmailId,
  //       client_first_name:
  //         (leadForm.fullname && leadForm.fullname.split(' ')[0]) ||
  //         clientFirstName,
  //       client_last_name:
  //         (leadForm.fullname && leadForm.fullname.split(' ')[1]) ||
  //         clientLastName,
  //       am_firm_name: amFirmName,
  //       user_type: userType,
  //       investable_assets: leadForm.investable_assets,
  //       provider: provider_name,
  //       page_tag,
  //       device_type,
  //       visitor_type,
  //       ticker,
  //       firm_name: leadForm.firm_name
  //     });
  //     handleContentPost(payload);
  //   };
  return (
    <Modal
      noBoxShadow
      borderColor={themesData.charcoalGrey}
      bgColor="#fff"
      size="medium-small"
      isOpen={open}
      onRequestClose={handleClose}
    >
    <ModalCloseButton onClick={handleClose} />
      <ModalHeader padding="10px 10px 0px" paddingBottom="0px" fontSize="14px" fontColor={userType !== 'am' ? 'black' : null}>
        {
            (userType === 'am' || isEmpty(digital_banner)) ?
          `Want to hear more from ${getTitleName()}?`
          :
          <Flex justifyContent='center' style={{'fontWeight' : '600'}}>
            {digital_banner}
          </Flex>
        }
      </ModalHeader>
      <ModalBody padding={isMobile ? '5px' : '15px'}>
        {userType === 'am' ? (
          <Flex flexDirection="column">
            <Flex
              width="100%"
              mb={userType === 'am' ? '0px' : '15px'}
              justifyContent="center"
              color={themesData.charcoalGrey}
            >
              Enter your info below.
            </Flex>
            <form onSubmit={handleSubmit}>
              <Flex
                width="100%"
                mb="10px"
                justifyContent="center"
                mt="17px"
                style={{ position: 'relative' }}
              >
                <AdvisorInputWrapper style={{ width: '70%' }}>
                  <Input
                    type="text"
                    name="fullname"
                    value={leadForm.fullname}
                    placeholder="Full Name"
                    onChange={handleChange}
                    required
                    isInputWithFloatingLabel={true}
                  />
                </AdvisorInputWrapper>
              </Flex>
              <Flex width="100%" mb="10px" justifyContent="center">
                <AdvisorInputWrapper style={{ width: '70%' }}>
                  <Input
                    type="email"
                    name="email"
                    value={leadForm.email}
                    placeholder="Email Address"
                    onChange={handleChange}
                    required={leadForm.mobile === ''}
                    isInputWithFloatingLabel={true}
                  />
                </AdvisorInputWrapper>
              </Flex>
              {/* {userType === 'am' && (
              <Flex width="100%" mb="10px" justifyContent="center">
                <AdvisorInputWrapper style={{ width: '70%' }}>
                  <Input
                    type="text"
                    value={leadForm.firm_name}
                    name="firm_name"
                    onChange={handleChange}
                    placeholder="Firm Name"
                    required
                    isInputWithFloatingLabel={true}
                  />
                </AdvisorInputWrapper>
              </Flex>
            )} */}
              <Flex width="100%" mb="10px" justifyContent="center">
                <AdvisorInputWrapper style={{ width: '70%' }}>
                  <Input
                    type="text"
                    value={leadForm.mobile}
                    name="mobile"
                    onChange={handleChange}
                    placeholder="Phone Number (Optional)"
                    isInputWithFloatingLabel={true}
                    required={leadForm.email === ''}
                  />
                </AdvisorInputWrapper>
              </Flex>
              <Flex width="100%" mb="10px" justifyContent="center">
                <MioSelect
                  name="investable_assets"
                  onChange={handleChange}
                  value={leadForm.investable_assets}
                  width="70%"
                  margin="17px 0px 0px"
                >
                  <option value="">
                    {userType === 'am' ? 'AUM (Optional)' : 'Investable Assets (Optional)'}
                  </option>
                  {investableAssetOptions.map((item, index) => (
                    <option value={item}>{item}</option>
                  ))}
                </MioSelect>
              </Flex>


              <LoaderWrapper isLoading={loading}>
                <Flex width="100%" justifyContent="center">
                  <Button
                    type="submit"
                    width="24%"
                    height="28px"
                    padding="4px 20px"
                    disabled={message === 'Submitted Successfully!'}
                  >
                    Sure!
                  </Button>
                </Flex>
              </LoaderWrapper>

              <Flex width="100%" justifyContent="center" pt="10px">
                <ButtonNoBorderBackground
                  bgColor="#fff"
                  onClick={handleClose}
                  color={themesData.purpleishBlue}
                  width="70%"
                >
                  No Thanks
                </ButtonNoBorderBackground>
              </Flex>

              {message && (
                <Flex
                  color={themesData.purpleishBlue}
                  justifyContent="center"
                  fontSize="12px"
                  pt="5px"
                >
                  {message}
                </Flex>
              )}


              <Flex
                width="100%"
                padding="10px 10px 0px"
                justifyContent="center"
                fontSize="10px"
                color={themesData.charcoalGrey}
              >
                By submitting your contact info, you're opting in to{' '}
                {getTitleName()}'s <br />
                communications. You can unsubscribe or adjust your preferences
                at any time.
              </Flex>

            </form>
          </Flex>
        ) : (
          <Flex flexDirection="column">
             <Flex
              width="100%"
              mb={isMobile ? '0px' : '15px'}
              justifyContent="center"
              color={themesData.purpleishBlue}
            >
              {getTitleName()} invites you to a deeper discussion on this topic.
            </Flex>
            <form onSubmit={handleSubmit}>
            <Flex
                width="100%"
                mb="10px"   
                justifyContent="center"
                mt="17px"
                style={{ position: 'relative' }}
              >
                 <AdvisorInputWrapper style={{ width: '70%' }}>
                  <Input
                    type="text"
                    name="fullname"
                    value={leadForm.fullname}
                    placeholder="Full Name"
                    onChange={handleChange}
                    required
                    isInputWithFloatingLabel={true}
                  />
                </AdvisorInputWrapper>
            </Flex>
            <Flex width="100%" mb={isMobile ? "0" : "10px"} justifyContent="center">
                <AdvisorInputWrapper style={{ width: '70%' }}>
                  <Input
                    type="text"
                    name="email"
                    value={leadForm.email}
                    placeholder="Email or Phone Number"
                    onChange={handleChange}
                    required
                    isInputWithFloatingLabel={true}
                  />
                </AdvisorInputWrapper>
              </Flex>
              <Flex width="100%" mb="10px" justifyContent="center">
                <AdvisorInputWrapper style={isMobile ? {width: "100%", textAlign: 'left'} : { width: "90%", textAlign: 'left' }}>
              <Flex margin={isMobile ? "0 0 2% 3%" : "0 0 4% 4%"}>What interests you:</Flex> 

                  {/* <Input
                  type="text"
                  value={leadForm.mobile}
                  name="mobile"
                  onChange={handleChange}
                  placeholder="Phone Number (Optional)"
                  isInputWithFloatingLabel={true}
                  required={leadForm.email === ''}
                /> */}
                  <Flex
                    width={1}
                    alignItems="center"
                    pl={isMobile ? "0" : "20px"}
                    style={{ display: "block" }}
                  >
                    <MioRadioSpan
                      noBorder
                      width="auto"
                      justifyContent="left"
                      height="auto"
                      mr={isMobile ? "0" : null}
                      pr={isMobile ? "0" : null}
                    >
                      <MioRadio
                        id={"leadInterestOption1"}
                        name="lead_interest_id"
                          onClick={handleChange}
                          value={"1"}
                          defaultChecked={leadForm.lead_interest_id === '1'}
                        margin="19% 16% 5%"
                        marginRight="10px"
                        required
                      />
                      <label htmlFor="leadInterestOption1">
                        I already work with a financial advisor but am
                        Interested to discuss this topic in more detail.
                      </label>
                    </MioRadioSpan>
                    <MioRadioSpan
                      noBorder
                      justifyContent="left"
                      height="auto"
                      width="auto"
                      mr={isMobile ? "0" : null}
                      pr={isMobile ? "0" : null}
                    >
                      <MioRadio
                        id={"leadInterestOption2"}
                        name="lead_interest_id"
                        onClick={handleChange}
                          value={"2"}
                          defaultChecked={leadForm?.lead_interest_id === '2'}
                        margin="19% 16% 5%"
                        marginRight="10px"
                      />
                      <label htmlFor="leadInterestOption2">
                        I am not looking for a financial advisor but am
                        Interested to discuss this topic in more detail.
                      </label>
                    </MioRadioSpan>
                    <MioRadioSpan
                      noBorder
                      justifyContent="left"
                      height="auto"
                      width="auto"
                      mr={isMobile ? "0" : null}
                      pr={isMobile ? "0" : null}
                    >
                      <MioRadio
                        id={"leadInterestOption3"}
                        name="lead_interest_id"
                        onClick={handleChange}
                          value={"3"}
                          defaultChecked={leadForm.lead_interest_id === '3'}
                        margin="19% 16% 5%"
                        marginRight="10px"
                      />
                      <label htmlFor="leadInterestOption3">
                        I am looking for a financial advisor and am Interested
                        to discuss this topic in more detail.
                      </label>
                    </MioRadioSpan>
                  </Flex>
                </AdvisorInputWrapper>
              </Flex>

              <LoaderWrapper isLoading={loading}>
              <Flex width="100%" justifyContent="center">
                <Button
                  type="submit"
                  width="32%"
                  height="28px"
                  padding="4px 20px"
                  margin={isMobile ? "5px 0 0 0" : null}
                  disabled={message === 'Submitted Successfully!'}
                >
                  Let's Connect
                </Button>
              </Flex>
              <span style={{'color':'red'}}>{cfnEmailErrorMessage}</span>    
            </LoaderWrapper>
      
            <Flex width="100%" justifyContent="center" pt={isMobile ? "5px" : "10px"}>
              <ButtonNoBorderBackground
                bgColor="#fff"
                onClick={handleClose}
                color={themesData.purpleishBlue}
                width="70%"
              >
                Maybe Later
              </ButtonNoBorderBackground>
            </Flex>
            {message && (
                <Flex
                  color={themesData.purpleishBlue}
                  justifyContent="center"
                  fontSize="12px"
                  pt="5px"
                >
                  {message}
                </Flex>
              )}
            <Flex
                width="100%"
                padding={isMobile ? "5px 0 0" : "10px 10px 0px"}
                justifyContent="center"
                fontSize="10px"
                color={themesData.charcoalGrey}
                style={{'fontStyle' : 'italic', 'fontWeight':'600'}}
              >
                By submitting this form, you agree that we may contact you and send you 
                marketing emails related to our services. To stop receiving emails, you may 
                unsubscribe at any time through the link found at the bottom of our emails
              </Flex>

            </form>

          </Flex>
        )}

      </ModalBody>
    </Modal>
  );
};

export default LeadCaptureModal;
