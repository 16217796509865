import React from 'react';
import LandingPageContainer from '../../containers/LandingPageContainer';
import withDripCampaignLayout from '../../hoc/withDripCampaignLayout';

const LandingPageLayout = () => {
  return (
    <LandingPageContainer />
  )
}

export default withDripCampaignLayout(LandingPageLayout);