import * as React from 'react';

function CloudArrowUp(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" {...props}>
      <path
        d="M9 19.5H6.75a5.25 5.25 0 110-10.5c.44 0 .877.053 1.303.16M7.5 12A7.5 7.5 0 1121 16.5"
        stroke="currentColor"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.072 15.178L14.25 12l3.178 3.178M14.25 19.5V12"
        stroke="currentColor"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

const MemoCloudArrowUp = React.memo(CloudArrowUp);
export default MemoCloudArrowUp;
