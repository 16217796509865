import React from 'react'
import EmailEditorContainer from '../../containers/EmailEditorContainer/EmailEditorContainer';
import withDripCampaignLayout from '../../hoc/withDripCampaignLayout'

const EmailEditorLayout = () => {
    return (
        <EmailEditorContainer />
    )
}

export default withDripCampaignLayout(EmailEditorLayout);
