import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  WidgetTile,
  Tabs,
  TabList,
  Tab,
  TabPanel,
  ResponsiveImg,
  Button,
  SocialCheckBoxes,
  ButtonNoBorderBackground,
  MioDropdown,
} from '../../../components';
import { IconDiv, AdminSubTab } from '../DashboardContainer.styles';
import PopularTopics from './PopularTopics';
import YourInvestments from '../Personalized/YourInvestments';
import { Box, Flex } from '@rebass/grid';
import AdvisorSearch from '../Discover/AdvisorSearch';
import EditorPanel from '../Trending/EditorPanel';
import MyClients from '../../../components/MyClients/MyClients';
import CRMSegment from '../../../components/CRMSegment/CRMSegment';
import { CaretIcon } from '../../ConfigureContainer/EmailList/EmailList.styles';
import { get, isEmpty } from 'lodash';
import NewSocialCheckBoxes from '../../../components/SocialCheckBoxes/NewSocialCheckBoxes';
import { SelectedItemBox } from '../../../components/SearchFilterMenu/SearchFilterMenu.styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { TopToolTipWrapper } from '../../LandingPageContainer/styles';

const Topics = ({
  setTabIndex,
  setPopularTopicDetails,
  onExploreTabClick,
  onInvestmentClick,
  setTrendingTopicDetails,
  trendingTopicDetails,
  isAdmin,
  tickerName,
  topicFromInvestment,
  themesList,
  setInvestmentTopic,
  exploreSetState,
  setSelectedExploreTabIndex,
  setShowArticleLoader,
  onExploreTabSelect,
  onWidgetTabChange,
  widgetTabIndes,
  setWdigetTabIndex,
  currentPortfolio,
  holdingsType,
  themeSetState,
  isComplianceAdmin,
  isPremiumAdvisor,
  curatedTopicType,
  setCuratedTopicType,
  contentlibSetState,
  contentlibRequest,
  onSearchClick,
  setSearchValue,
  channelData,
  onCheckBoxClick,
  checkedStatus,
  isEnterpriseAdmin,
  setThirdPartyThemeId,
  exploreArticleRequest,
  exploreArticles,
  setClearEditorArticles,
  popularTopicDetails,
  thirdPartyThemeId,
  setClientTopics,
  userData,
  searchValue,
  isAssetAdmin,
  showClientDropDown,
  setShowClientDropDown,
  contactList,
  setClientDetails,
  selectedDropdownOption,
  setSelectedDropdownOption,
  setSelectedCRMContactList,
  crmLoader,
  setCrmLoader,
  setExploreTabDetails,
  popularTabIndex,
  firmSettings
}) => {

  const [selectedItems, setSelectedItems] = useState([]);
  const [topicSelected, setTopicSelected] = useState([]);
  const [selectedPublishers, setSelectedPublishers] = useState([]);

  useEffect(() => {
    if (topicFromInvestment) {
      setWdigetTabIndex(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [topicFromInvestment]);

  const paddingStyle =
    isEnterpriseAdmin ||
    isAssetAdmin ||
    (isPremiumAdvisor &&
      userData.details &&
      userData.details.user.turnkey &&
      userData.details.user.turnkey === 'true')
      ? '5px 19px 0px'
      : '5px 25px 0px';

  const handleClick = (type) => {
    setTabIndex(0);
    setSelectedExploreTabIndex(2);
    setCuratedTopicType(type);
    contentlibSetState({ data: {}, filter: type });
    // contentlibRequest({ type: type });
  };

 

  const handleSearchTab = () => {
    setTabIndex(3);
  };

  const adminSearchCheckBoxes = () => {
    if (!isComplianceAdmin) {
      return (
        <Flex
          width="100%"
          alignItems="flex-start"
          justifyContent="center"
          px="0px"
        >
          <NewSocialCheckBoxes
            onCheckBoxClick={onCheckBoxClick}
            small
            buttons={[
              {
                name: 'Approved to Share',
                endText: 'Approved to Share',
                isDisconnected: checkedStatus('approved_to_share').isDisconnected,
                platform: 'approved_to_share',
                checked: checkedStatus('approved_to_share').checked,
                width: '74px',
                padding: '0',
                type: 'discover',
                margin: '0px 3px',
                bgColor: '#816EF8',
              },
              {
                name: 'All',
                endText: 'All Content',
                isDisconnected: checkedStatus('all_content').isDisconnected,
                platform: 'all_content',
                checked: checkedStatus('all_content').checked,
                width: '86px',
                padding: '0',
                type: 'discover',
                margin: '0px 3px',
                bgColor: '#816EF8',
              },
              {
                name: 'My Content',
                endText: 'My Content',
                isDisconnected: checkedStatus('my_content').isDisconnected,
                platform: 'my_content',
                checked: checkedStatus('my_content').checked,
                width: '89px',
                padding: '0',
                type: 'discover',
                margin: '0px 3px',
                bgColor: '#816EF8',
              },
              {
                name: 'Editor',
                endText: 'My Editor',
                isDisconnected: checkedStatus('editor_content').isDisconnected,
                platform: 'editor_content',
                checked: checkedStatus('editor_content').checked,
                width: '74px',
                padding: '0',
                type: 'discover',
                margin: '0px 3px',
                bgColor: '#816EF8',
              },
            ]}
          />
        </Flex>
      );
    }
  };

  const searchCheckBoxes = () => {
    return (
      <Flex
        width={1}
        alignItems="flex-start"
        justifyContent="center"
        pl="30%"
        pr="30%"
      >
        <SocialCheckBoxes
          onCheckBoxClick={onCheckBoxClick}
          buttons={[
            {
              name: 'All',
              bottomText: 'All Content',
              isDisconnected: checkedStatus('all').isDisconnected,
              platform: 'all',
              checked: checkedStatus('all').checked,
              width: '86px',
              padding: '0',
              type: 'discover',
              margin: '0px 5px',
              bgColor: '#c95cfc',
            },
            {
              name: 'My Content',
              bottomText: 'My Content',
              isDisconnected: checkedStatus('my_content').isDisconnected,
              platform: 'my_content',
              checked: checkedStatus('my_content').checked,
              width: '89px',
              padding: '0',
              type: 'discover',
              margin: '0px 5px',
              bgColor: '#c95cfc',
            },
          ]}
        />
      </Flex>
    );
  };

  const goToAddContent = () => {
    contentlibSetState({ data: {} });
    setTabIndex(3);
  };

  const handleDropdownClick = (option) => {
    setShowClientDropDown(false);
    exploreSetState({ data: {} });
    setShowArticleLoader(true);
    setSelectedDropdownOption(option);
    handleTabIndexReset();
  };

  const handleTabIndexReset = () => {
    setTabIndex(0);
    if (isAdmin && !isPremiumAdvisor) setSelectedExploreTabIndex(6);
    else {
      console.log(
        'condition - --- - - - -- ',
        userData.details &&
          userData.details.user.turnkey &&
          userData.details.user.turnkey === 'true'
      );
      if (
        userData.details &&
        userData.details.user.turnkey &&
        userData.details.user.turnkey === 'true'
      )
        setSelectedExploreTabIndex(6);
      else setSelectedExploreTabIndex(5);
    }
  };

  const backHandler = () => {
    setShowClientDropDown(true);
    setSelectedDropdownOption('');
    setSelectedCRMContactList({
      id: '',
      name: '',
      themes: [],
      recommendedThemes: [],
      userThemes: [],
      disabledThemes: [],
    });
    setClientDetails({ id: 'SECURITY', contactName: 'positivly_security' });
  };

 const onUnSelect = (id) => {
  
    const filterChannel = selectedItems.filter((item) => item.id !== id);
    console.log("filterChannel--->", filterChannel);
    setSelectedItems(filterChannel);
    let filterTags = [];
    if(!isEmpty(filterChannel)) {
      filterChannel.forEach((item) => {
        if (item.name.includes('(')) {
          let name = item.name.split(/[()]/);
          filterTags.push(name[0].toUpperCase().trim());
          filterTags.push(name[1].toUpperCase().trim());
        } else {
          filterTags.push(item.name.toUpperCase().trim());
        }
      });
    }

    setSelectedPublishers(filterTags);
    topicSelected &&
      topicSelected.length > 0 &&
      setTopicSelected(topicSelected.filter((item) => item !== id));
  };


  return (
    <WidgetTile heading="Personalized" theme="light">
      <IconDiv>
        <Link to="/theme">
          <ResponsiveImg src="/assets/images/personalized.svg" />
        </Link>
      </IconDiv>
      {isComplianceAdmin ? (
        <Tabs
          selectedIndex={widgetTabIndes}
          onSelect={onWidgetTabChange}
          style={{ paddingTop: '3px' }}
        >
          <TabList>
            <AdminSubTab style={{ display: 'none' }}></AdminSubTab>
            <AdminSubTab
              padding="5px 30px 0px"
              onClick={onExploreTabClick}
              data-testid="my_topics_tab"
            >
              <span className={`${widgetTabIndes === 1 ? 'active' : null}`}>
                My Topics
              </span>
            </AdminSubTab>
            <AdminSubTab
              data-testid="my_holdings_tab"
              padding="5px 30px 0px"
              title="My Holdings"
              hide={
                get(firmSettings, 'firm_general.my_holding', 'true') === 'false'
              }
            >
              <span className={`${widgetTabIndes === 2 ? 'active' : null}`}>
                My Holdings
              </span>
            </AdminSubTab>
            <AdminSubTab padding="5px 30px 0px" data-testid="search_tab">
              <span className={`${widgetTabIndes === 3 ? 'active' : null}`}>
                Search
              </span>
            </AdminSubTab>
          </TabList>
          <TabPanel></TabPanel>
          <TabPanel style={{ overflow: 'visible' }}>
            <PopularTopics
              popularTopicDetails={popularTopicDetails}
              setPopularTopicDetails={setPopularTopicDetails}
              topicFromInvestment={topicFromInvestment}
              setTabIndex={setTabIndex}
              setSelectedExploreTabIndex={setSelectedExploreTabIndex}
              isAdmin={isAdmin}
              themesList={themesList}
              onExploreTabClick={onExploreTabClick}
              themeSetState={themeSetState}
              setExploreTabDetails={setExploreTabDetails}
              popularTabIndex={popularTabIndex}
              exploreSetState={exploreSetState}
            />
          </TabPanel>
          <TabPanel>
            <YourInvestments
              exploreSetState={exploreSetState}
              setShowArticleLoader={setShowArticleLoader}
              setTabIndex={setTabIndex}
              onInvestmentClick={onInvestmentClick}
              setTrendingTopicDetails={setTrendingTopicDetails}
              trendingTopicDetails={trendingTopicDetails}
              tickerName={tickerName}
              currentPortfolio={currentPortfolio && currentPortfolio}
              holdingsType={holdingsType}
            />
          </TabPanel>
          <TabPanel overflow="visible">
            <AdvisorSearch
              onSearchClick={onSearchClick}
              setSearchValue={setSearchValue}
              setTabIndex={handleSearchTab}
              themesList={themesList}
              channelData={channelData}
              exploreSetState={exploreSetState}
              searchValue={searchValue}
              setTopicSelected={setTopicSelected}
              topicSelected={topicSelected}
              setSelectedItems={setSelectedItems}
              selectedItems={selectedItems}
              selectedPublishers={selectedPublishers}
              setSelectedPublishers={setSelectedPublishers}
            />
          </TabPanel>
        </Tabs>
      ) : (
        <Tabs selectedIndex={widgetTabIndes} onSelect={onWidgetTabChange}>
          <TabList>
            <AdminSubTab padding={paddingStyle} data-testid="my_content_tab">
              <span className={`${widgetTabIndes === 0 ? 'active' : null}`}>
                My Content
              </span>
            </AdminSubTab>
            <AdminSubTab padding={paddingStyle} data-testid="my_topics_tab">
              <span className={`${widgetTabIndes === 1 ? 'active' : null}`}>
                My Topics
              </span>
            </AdminSubTab>
            <AdminSubTab
              padding={paddingStyle}
              data-testid="my_holdings_tab"
              title="My Holdings"
              hide={
                get(firmSettings, 'firm_general.my_holding', 'true') === 'false'
              }
            >
              <span className={`${widgetTabIndes === 2 ? 'active' : null}`}>
                My Holdings
              </span>
            </AdminSubTab>

            {isEnterpriseAdmin ||
            isAssetAdmin ||
            (isPremiumAdvisor &&
              userData.details &&
              userData.details.user.turnkey &&
              userData.details.user.turnkey === 'true') ? (
              <AdminSubTab
                data-testid="my_editor_tab"
                padding={paddingStyle}
                hide={
                  get(firmSettings, 'firm_general.my_editor', 'true') ===
                  'false'
                }
              >
                <span className={`${widgetTabIndes === 3 ? 'active' : null}`}>
                  My Editor
                </span>
              </AdminSubTab>
            ) : null}

            {isEnterpriseAdmin ||
            isAssetAdmin ||
            (isPremiumAdvisor &&
              userData.details &&
              userData.details.user.turnkey &&
              userData.details.user.turnkey === 'true') ? (
              <AdminSubTab
                data-testid="my_clients_tab"
                hide={
                  get(firmSettings, 'firm_general.my_clients', 'true') ===
                  'false'
                }
                padding={paddingStyle}
              >
                <span className={`${widgetTabIndes === 4 ? 'active' : null}`}>
                  My Clients
                </span>
              </AdminSubTab>
            ) : (
              <AdminSubTab
                data-testid="my_clients_tab"
                hide={
                  get(firmSettings, 'firm_general.my_clients', 'true') ===
                  'false'
                }
                padding={paddingStyle}
              >
                <span className={`${widgetTabIndes === 3 ? 'active' : null}`}>
                  My Clients
                </span>
              </AdminSubTab>
            )}

            {isEnterpriseAdmin ||
            isAssetAdmin ||
            (isPremiumAdvisor &&
              userData.details &&
              userData.details.user.turnkey &&
              userData.details.user.turnkey === 'true') ? (
              <AdminSubTab padding={paddingStyle} data-testid="search_tab">
                <span className={`${widgetTabIndes === 5 ? 'active' : null}`}>
                  Search
                </span>
              </AdminSubTab>
            ) : (
              <AdminSubTab padding={paddingStyle} data-testid="search_tab">
                <span className={`${widgetTabIndes === 4 ? 'active' : null}`}>
                  Search
                </span>
              </AdminSubTab>
            )}
          </TabList>

          <TabPanel overflow="visible">
            <Flex
              justifyContent="center"
              pt="30px"
              flexDirection="column"
              // display="flex"
              // flexWrap="wrap"
            >
              <Flex justifyContent="center">
                <Button
                  bgColor="#eeeeee"
                  color="#49484a"
                  activeColor="#7dc4d0"
                  styles={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    border: 'none',
                  }}
                  margin={isPremiumAdvisor ? '5px 20px' : '5px auto'}
                  fontSize="12px"
                  width={isPremiumAdvisor ? '33%' : '29%'}
                  className={curatedTopicType === 'all' ? 'active' : null}
                  onClick={() => handleClick('all')}
                  testId="mycontent_all"
                >
                  <img
                    alt=""
                    src={
                      curatedTopicType === 'all'
                        ? '/assets/images/All_Icon_White.svg'
                        : '/assets/images/All_Icon_black1.svg'
                    }
                    style={{ marginRight: '4px' }}
                  />
                  All
                </Button>
                <Button
                  bgColor="#eeeeee"
                  color="#49484a"
                  activeColor="#7dc4d0"
                  styles={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    border: 'none',
                  }}
                  margin={isPremiumAdvisor ? '5px 20px' : '5px auto'}
                  fontSize="12px"
                  width="33%"
                  className={
                    curatedTopicType === 'Approve To Share' ||
                    curatedTopicType === 'link'
                      ? 'active'
                      : null
                  }
                  onClick={() =>
                    handleClick(isPremiumAdvisor ? 'link' : 'Approve To Share')
                  }
                  testId="mycontent_lk_ats"
                >
                  <img
                    alt=""
                    style={{ marginRight: '4px' }}
                    src={
                      isPremiumAdvisor
                        ? curatedTopicType === 'link'
                          ? '/assets/images/Links_Icon_White2.svg'
                          : '/assets/images/Links_Icon_black2.svg'
                        : curatedTopicType === 'Approve To Share'
                        ? '/assets/images/Approved_Icon_White.svg'
                        : '/assets/images/Approved_Icon_black.svg'
                    }
                  />
                  {isPremiumAdvisor ? 'Links' : 'Approved To Share'}
                </Button>
                {!isPremiumAdvisor && (
                  <Button
                    bgColor="#eeeeee"
                    color="#49484a"
                    activeColor="#7dc4d0"
                    styles={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      border: 'none',
                    }}
                    margin={isPremiumAdvisor ? '5px 20px' : '5px auto'}
                    fontSize="12px"
                    width="29%"
                    className={
                      curatedTopicType === 'Internal Use' ||
                      curatedTopicType === 'pdf'
                        ? 'active'
                        : null
                    }
                    onClick={() =>
                      handleClick(isPremiumAdvisor ? 'pdf' : 'Internal Use')
                    }
                    testId="mycontent_pdf_is"
                  >
                    <img
                      alt=""
                      style={{ marginRight: '4px' }}
                      src={
                        isPremiumAdvisor
                          ? curatedTopicType === 'pdf'
                            ? '/assets/images/Links_Icon_White.svg'
                            : '/assets/images/Links_Icon_black.svg'
                          : curatedTopicType === 'Internal Use'
                          ? '/assets/images/Interal_Use_Icon_White.svg'
                          : '/assets/images/Interal_Use_Icon_black.svg'
                      }
                    />
                    {isPremiumAdvisor ? 'PDFs' : 'Internal Use'}
                  </Button>
                )}
              </Flex>
              {isPremiumAdvisor && (
                <Flex justifyContent="center">
                  <Button
                    bgColor="#eeeeee"
                    color="#49484a"
                    activeColor="#7dc4d0"
                    styles={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      border: 'none',
                    }}
                    margin={isPremiumAdvisor ? '5px 20px' : '5px auto'}
                    fontSize="12px"
                    width="33%"
                    className={
                      curatedTopicType === 'Internal Use' ||
                      curatedTopicType === 'pdf'
                        ? 'active'
                        : null
                    }
                    onClick={() =>
                      handleClick(isPremiumAdvisor ? 'pdf' : 'Internal Use')
                    }
                    testId="mycontent_pdf_is"
                  >
                    <img
                      alt=""
                      style={{ marginRight: '4px' }}
                      src={
                        isPremiumAdvisor
                          ? curatedTopicType === 'pdf'
                            ? '/assets/images/Links_Icon_White.svg'
                            : '/assets/images/Links_Icon_black.svg'
                          : curatedTopicType === 'Internal Use'
                          ? '/assets/images/Interal_Use_Icon_White.svg'
                          : '/assets/images/Interal_Use_Icon_black.svg'
                      }
                    />
                    {isPremiumAdvisor ? 'PDFs' : 'Internal Use'}
                  </Button>

                  <Button
                    bgColor="#eeeeee"
                    color="#49484a"
                    activeColor="#7dc4d0"
                    styles={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      border: 'none',
                    }}
                    margin={isPremiumAdvisor ? '5px 20px' : '5px auto'}
                    fontSize="12px"
                    width="33%"
                    className={curatedTopicType === 'img' ? 'active' : null}
                    onClick={() => handleClick('img')}
                    testId="mycontent_images"
                  >
                    <img
                      alt=""
                      style={{ marginRight: '4px' }}
                      src={
                        curatedTopicType === 'img'
                          ? '/assets/images/imagepost.svg'
                          : '/assets/images/imagepost_grey.svg'
                      }
                    />
                    Images
                  </Button>
                </Flex>
              )}
            </Flex>
            <Flex width="1" justifyContent="center" pt="30px">
              <ButtonNoBorderBackground
                onClick={goToAddContent}
                // padding="0px 40px 0px 0px"
                style={{
                  flexDirection: 'row',
                  display: 'flex',
                  justifyContent: 'center',
                }}
                data-testid="add-content-button"
              >
                <ResponsiveImg src="/assets/images/Add.svg" />
                &nbsp;&nbsp;
                <span>Add</span>
              </ButtonNoBorderBackground>
            </Flex>
            {/* </Flex> */}
          </TabPanel>
          <TabPanel overflow="visible">
            <PopularTopics
              popularTopicDetails={popularTopicDetails}
              setPopularTopicDetails={setPopularTopicDetails}
              topicFromInvestment={topicFromInvestment}
              setTabIndex={setTabIndex}
              setSelectedExploreTabIndex={setSelectedExploreTabIndex}
              isAdmin={isAdmin}
              themesList={themesList}
              onExploreTabClick={onExploreTabClick}
              themeSetState={themeSetState}
              setExploreTabDetails={setExploreTabDetails}
              popularTabIndex={popularTabIndex}
              exploreSetState={exploreSetState}
            />
          </TabPanel>
          <TabPanel>
            <YourInvestments
              exploreSetState={exploreSetState}
              setShowArticleLoader={setShowArticleLoader}
              setTabIndex={setTabIndex}
              onInvestmentClick={onInvestmentClick}
              setTrendingTopicDetails={setTrendingTopicDetails}
              trendingTopicDetails={trendingTopicDetails}
              tickerName={tickerName}
              currentPortfolio={currentPortfolio && currentPortfolio}
              holdingsType={holdingsType}
            />
          </TabPanel>
          {(isEnterpriseAdmin ||
            isAssetAdmin ||
            (isPremiumAdvisor &&
              userData.details &&
              userData.details.user.turnkey &&
              userData.details.user.turnkey == 'true')) && (
            <TabPanel overflow="visible">
              {/* {console.log('[THIRD PARTY THEME IT] - ', thirdPartyThemeId)} */}
              <EditorPanel
                setThirdPartyThemeId={setThirdPartyThemeId}
                exploreArticleRequest={exploreArticleRequest}
                popularTopicDetails={popularTopicDetails}
                setClearEditorArticles={setClearEditorArticles}
                exploreSetState={exploreSetState}
                setSelectedExploreTabIndex={setSelectedExploreTabIndex}
                thirdPartyThemeId={thirdPartyThemeId}
                user={userData}
                setShowArticleLoader={setShowArticleLoader}
                exploreArticles={exploreArticles}
              />
            </TabPanel>
          )}
          <TabPanel style={{ overflowY: 'hidden' }}>
            {showClientDropDown ? (
              <Flex alignItems="center" style={{ height: '185px' }}>
                <Flex fontSize="12px" pl="5%" pr="2%" mt="-20px" width="140px">
                  Personalize By :
                </Flex>
                <Flex mt="-20px">
                  <MioDropdown
                    fullWidth
                    background="#EFF0F2"
                    hoverBackground="#D7C3FF"
                    type="emailList"
                    left="0px"
                    width="30.8em"
                    dropdownMenu={
                      <ul>
                        <li
                          value=""
                          onClick={() => handleDropdownClick('financial')}
                          style={{
                            color: '#757575',
                            borderLeft: 'none',
                            borderRight: 'none',
                          }}
                        >
                          Financial Personality{' '}
                          <img
                            src="/assets/images/TIFIN-Personality-Logo.svg"
                            alt="TIFIN-Personality-Logo"
                            style={{
                              position: 'absolute',
                              top: '-4px',
                              left: '140px',
                              width: '100px',
                            }}
                          />
                        </li>
                        <li
                          value=""
                          onClick={() => handleDropdownClick('crm')}
                          style={{
                            color: '#757575',
                            borderLeft: 'none',
                            borderRight: 'none',
                          }}
                        >
                          Client Segments
                        </li>
                        <li
                          value=""
                          style={{
                            color: '#757575',
                            borderLeft: 'none',
                            borderRight: 'none',
                            pointerEvents: 'none',
                          }}
                        >
                          Custom Segments (Coming Soon)
                        </li>
                      </ul>
                    }
                  >
                    <Button
                      bgColor="#eeeeee"
                      color="#6351ed"
                      styles={{ border: 'none' }}
                      margin={`${
                        window.innerWidth < 768
                          ? '3px 0px 10px 0px'
                          : '0px 0px 0px 0px'
                      }`}
                      height="35px"
                      width={`${window.innerWidth < 768 ? '82%' : '370px'}`}
                    >
                      <span
                        style={{
                          whiteSpace: 'nowrap',
                          float: 'left',
                          width: '300px',
                          overflow: 'hidden',
                          color: '#757575',
                        }}
                      >
                        Select from available options
                      </span>
                      <CaretIcon marginTop="3px" />
                    </Button>
                  </MioDropdown>
                </Flex>
              </Flex>
            ) : selectedDropdownOption === 'financial' ? (
              <MyClients
                exploreArticleRequest={exploreArticleRequest}
                setClientTopics={setClientTopics}
                setTabIndex={setTabIndex}
                setSelectedExploreTabIndex={setSelectedExploreTabIndex}
                isPremiumAdvisor={isPremiumAdvisor}
                userData={userData}
                isAdmin={isAdmin}
                setShowArticleLoader={setShowArticleLoader}
                clientContactList={contactList}
                setClientDetails={setClientDetails}
                close={backHandler}
                crmLoader={crmLoader}
                toggleCrmLoader={setCrmLoader}
              />
            ) : selectedDropdownOption === 'crm' ? (
              <CRMSegment
                close={backHandler}
                setSelectedCRMContactList={setSelectedCRMContactList}
                setShowArticleLoader={setShowArticleLoader}
                handleTabIndexReset={handleTabIndexReset}
                loader={crmLoader}
                toggleLoader={setCrmLoader}
              />
            ) : (
              <div></div>
            )}
          </TabPanel>
          <TabPanel overflow="visible">
            <AdvisorSearch
              onSearchClick={onSearchClick}
              setSearchValue={setSearchValue}
              setTabIndex={handleSearchTab}
              themesList={themesList}
              channelData={channelData}
              exploreSetState={exploreSetState}
              searchValue={searchValue}
              isAdmin={isAdmin}
              setTopicSelected={setTopicSelected}
              topicSelected={topicSelected}
              setSelectedItems={setSelectedItems}
              selectedItems={selectedItems}
              selectedPublishers={selectedPublishers}
              setSelectedPublishers={setSelectedPublishers}
            />
            <Flex
              marginBottom={
                selectedItems && selectedItems.length > 0 ? '5px' : '5px'
              }
              style={{
                display: 'flex',
                width: '100%',
                margin: '0px 0px 5px 0px',
                justifyContent: 'flex-start',
                padding: '0px 15px 0px 24px',
              }}
            >
              {[...selectedItems, ...topicSelected].map((item, index) => (
                <SelectedItemBox background="#6351ed">
                  <Flex alignItems="center">
                    <Box pr="5px">
                      <FontAwesomeIcon
                        icon={faTimes}
                        onClick={() => onUnSelect(item.id || item)}
                      />
                    </Box>
                    <Box>
                      <TopToolTipWrapper>
                        {item?.name && item?.name?.length > 6
                          ? `${item.name.substring(0, 6)}...`
                          : item.name || `${item.substring(0, 6)}...`}
                          {item?.name?.length > 6 ? <span className='tooltiptext' style={{left: '-48%', fontSize: '9px', bottom: '137%',width: '200%' }}>{item?.name}</span> : null }
                          {item?.length > 6 ? <span className='tooltiptext' style={{left: '-48%', fontSize: '9px', bottom: '137%',width: '200%' }}>{item}</span> : null }
                      </TopToolTipWrapper>
                    </Box>
                  </Flex>
                </SelectedItemBox>
              ))}
            </Flex>
            <Flex
              display="flex"
              justifyContent="center"
              fontSize="12px"
              color="#667085"
              width="97%"
              margin={
                (topicSelected &&
                topicSelected.length > 0) ||
                (selectedItems &&
                selectedItems.length > 0)
                  ? '0px 8px 7px'
                  : '0px 8px 15px'
              }
            >
              Searching Content Across
            </Flex>
            {isEnterpriseAdmin ||
            isAssetAdmin ||
            (isPremiumAdvisor &&
              userData.details &&
              userData.details.user &&
              userData.details.user.turnkey &&
              userData.details.user.turnkey === 'true')
              ? adminSearchCheckBoxes()
              : searchCheckBoxes()}
          </TabPanel>
        </Tabs>
      )}
    </WidgetTile>
  );
};

export default Topics;
