import { isEmpty } from 'lodash';
import apiInstance from '../api';
import { apiDetails } from '../helpers';

export const createSegment = (payload) => {
  return apiInstance().post(`${apiDetails.baseUrl}segments`, payload);
};

export const getSegments = () => {
  return apiInstance().get(`${apiDetails.baseUrl}segments`);
};

export const getSegment = (id) => {
  return apiInstance().get(`${apiDetails.baseUrl}segments/${id}`);
};

export const updateSegment = (id, payload) => {
  return apiInstance().post(
    `${apiDetails.baseUrl}segments/${id}`,
    payload
  );
};

export const deleteSegment = (payload) => {
  return apiInstance().delete(`${apiDetails.baseUrl}segments/${payload}`);
};

export const getSegmentContactList = (id, searchText) => {
  if(!isEmpty(searchText)) {
    return apiInstance().get(
      `${apiDetails.baseUrl}segments/${id}/contacts?include_unsubscribed=true&email_keyword=${searchText}`
    );
  } else {
    return apiInstance().get(
      `${apiDetails.baseUrl}segments/${id}/contacts?include_unsubscribed=true`
    );
  }
};

export const getSingularSegments = (searchText) => {
  if (!isEmpty(searchText)) {
    return apiInstance().get(
      `${apiDetails.baseUrl}segments?search_term=${searchText}`
    );
  } else {
    return apiInstance().get(`${apiDetails.baseUrl}segments`);
  }
};

export const searchSegmentTags = (payload) => {
  return apiInstance().get(
    `${apiDetails.baseUrl}contact/tags?search_term=${payload}`
  );
};

export const createSegmentContactList = (payload) => {
  return apiInstance().post(
    `${apiDetails.baseUrl}contactlist`,
    payload
  );
}

export const uploadCsvContactList = (id, payload) => {
  return apiInstance().post(
    `${apiDetails.baseUrl}contactlist/${id}/contact/?`,
    payload
  );
};

export const createSegmentAndUploadCsvContactList = (payload) => {
  return apiInstance().post(
    `${apiDetails.baseUrl}contactlist/contact`,
    payload
  );
}

export const listAllTopics = () => {
  return apiInstance().get(`${apiDetails.baseUrl}theme/list_all?custom_tags=true`);
}

export const listAllTags = () => {
  return apiInstance().get(
    `${apiDetails.baseUrl}contact/tags`
  );
};