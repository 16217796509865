import React from 'react';
import { Tooltip } from '@mui/material';
import TrendUp from 'src/assets/icons/TrendUp';
import InfoCircle from 'src/assets/icons/InfoCircle';
import useQueryForKeyInsights from 'src/hooks/useQueryForKeyInsights';
import {
  Card,
  CardHeader,
  CardHeading,
  Pill,
  PillSkeleton,
  Pills,
} from './styles';

function Topics({ email }) {
  const { data, isLoading, isFetching } = useQueryForKeyInsights(email);

  return (
    <div>
      <Card>
        <CardHeader>
          <CardHeading>
            <TrendUp />
            <h4>Trending Topics</h4>
          </CardHeading>
          <Tooltip
            title="TIFIN uses machine learning models to derive insights into trending topics spanning over 500+ thematic categories."
            arrow
          >
            <div className="info-icon">
              <InfoCircle />
            </div>
          </Tooltip>
        </CardHeader>
        {isLoading || isFetching ? (
          <Pills>
            <PillSkeleton width={100} height={24} />
            <PillSkeleton width={120} height={24} />
            <PillSkeleton width={80} height={24} />
          </Pills>
        ) : (
          <Pills>
            {Array.isArray(data?.topics) &&
              data?.topics?.map((topic, index) => (
                <Pill key={index}>{topic}</Pill>
              ))}
          </Pills>
        )}
      </Card>
    </div>
  );
}

export default Topics;
