/* eslint-disable react-hooks/exhaustive-deps */
import { Flex } from '@rebass/grid';
import React, { useEffect, useState } from 'react';
import { ToggleButton } from '../../ConfigureContainer/screenConfigure.styles';
import { get, isEmpty } from 'lodash';
import { convertDateToRequiredFormat, timeZoneAbbreviated } from '../../../utils';

const CampaignDetailsOnetimeSeries = ({
  handleComplianceToggle,
  toogleCompliance,
  handleLeadCaptureToggle,
  toggleLeadCapture,
  setObjectivesTabData,
  complianceSettings,
  blocks,
  summaryDetails,
  reviewType,
  userData,
  isAdvisorApprovalMode,
  advisorApproval,
  handleAdvisorApproval,
  advisorApprovalRequired,
  isadvMode
}) => {

  const [platforms, setPlatforms] = useState([]);

  let emailBlocks = blocks.filter(b => b.is_email === "true");
  let socialBlocks = blocks.filter(b => b.is_email !== "true");

  useEffect(() => {
    getPlatforms()
  }, [])

  const getPlatforms = () => {
    let selectedPlatforms = [];
    if (setObjectivesTabData?.is_email === "true") {
      selectedPlatforms = [...selectedPlatforms, {
        src: '/assets/images/Campaign/email-icon.svg',
        alt: 'email-img'
      }]
    }
    if (setObjectivesTabData?.is_facebook === "true") {
      selectedPlatforms = [...selectedPlatforms, {
        src: '/assets/images/Campaign/fb-icon.svg',
        alt: 'fb-img'
      }]
    }
    if (setObjectivesTabData?.is_twitter === "true") {
      selectedPlatforms = [...selectedPlatforms, {
        src: '/assets/images/Campaign/tw-icon.svg',
        alt: 'tw-img'
      }]
    }
    if (setObjectivesTabData?.is_linkedin === "true") {
      selectedPlatforms = [...selectedPlatforms, {
        src: '/assets/images/Campaign/lnkdin-icon.svg',
        alt: 'ln-img'
      }]
    }
    setPlatforms(selectedPlatforms);

  }
  return (
    <>
      <Flex
        display="flex"
        alignItems="center"
        width="100%"
        flexDirection="column"
      >
        <Flex width="100%" flexDirection="row">
          <Flex
            width="100%"
            padding="3px 5px 5px 10px"
            justifyContent="flex-start"
            flexDirection="column"
            display="flex"
          >
            <Flex
              alignItems="center"
              fontSize="16px"
              style={{ fontWeight: 500 }}
              color="#000000"
              width="100%"
            >
              Campaign Name (Series Campaign)
            </Flex>
            <Flex
              width="100%"
              alignItems="center"
              fontSize="14px"
              color="#667085"
            >
              {setObjectivesTabData?.seriesName}
            </Flex>
          </Flex>

          {/* <Flex width="30%" display="flex" justifyContent="flex-end">
            <Button
              margin="5px 0px 0px"
              borderRadius="4px"
              bgColor="#FFFFFF"
              borderColor="#E4E7EC"
              color="#101828"
            >
              Send Test Emails
            </Button>
          </Flex> */}
        </Flex>
        <Flex
          width="100%"
          display="flex"
          flexDirection="column"
          padding="3px 5px 5px 10px"
        >
          <Flex
            width="100%"
            alignItems="center"
            fontSize="16px"
            style={{ fontWeight: 500 }}
            color="#000000"
            justifyContent="flex-start"
          >
            Purpose
          </Flex>
          <Flex
            width="100%"
            alignItems="center"
            fontSize="14px"
            color="#667085"
            style={{ textAlign: 'justify' }}
          >
            {setObjectivesTabData?.seriesPurpose}
          </Flex>
        </Flex>
        <Flex
          width="100%"
          display="flex"
          flexDirection="column"
          padding="3px 5px 10px 10px"
        >
          <Flex
            width="100%"
            alignItems="center"
            fontSize="16px"
            style={{ fontWeight: 500 }}
            color="#000000"
            justifyContent="flex-start"
          >
            Platforms
          </Flex>
          <Flex width="100%" alignItems="center" justifyContent="flex-start">
            {!isEmpty(platforms) && platforms.map((item, index) => (
              <Flex marginRight="5px" padding="3px 5px 2px 0px">
                <img
                  alt={item.alt}
                  src={item.src}
                />
              </Flex>
            ))}
          </Flex>
        </Flex>
        <hr style={{ width: '100%' }} />
        <Flex width="100%" padding="3px 5px 5px 0px" flexDirection="column">
          <Flex display="flex" width="80%">
            <Flex
              width="50%"
              padding="5px 10px"
              fontSize="14px"
              color="#000000"
              justifyContent="flex-start"
              style={{ fontWeight: 500 }}
            >
              Start Date
            </Flex>
            <Flex
              width="40%"
              justifyContent="flex-start"
              fontSize="14px"
              color="#667085"
              padding="5px"
            >
              {summaryDetails?.start_date && convertDateToRequiredFormat({
                date: summaryDetails?.start_date,
                format: 'MM/DD/YYYY',
              }) + ' (' + timeZoneAbbreviated(get(
                userData,
                'details.user.timezone',
                ''
              )) + ')'}
            </Flex>
          </Flex>
          {(!isEmpty(setObjectivesTabData?.emList) || !isEmpty(setObjectivesTabData?.segmentList)) && (
            <Flex display="flex" width="80%">
              <Flex
                width="50%"
                padding="5px 10px"
                fontSize="14px"
                color="#000000"
                justifyContent="flex-start"
                style={{ fontWeight: 500 }}
              >
                Emails
              </Flex>
              <Flex
                width="30%"
                justifyContent="flex-start"
                fontSize="14px"
                color="#667085"
                padding="5px"
              >
                {!isEmpty(emailBlocks) ? emailBlocks.length : 0} {!isEmpty(emailBlocks) && emailBlocks.length > 1 ? 'Emails' : 'Email'} 
              </Flex>
            </Flex>
          )}
          {(setObjectivesTabData?.is_facebook === "true" || setObjectivesTabData?.is_twitter === "true" || setObjectivesTabData?.is_linkedin === "true") && (
            <Flex display="flex" width="80%">
              <Flex
                width="50%"
                padding="5px 10px"
                fontSize="14px"
                color="#000000"
                justifyContent="flex-start"
                style={{ fontWeight: 500 }}
              >
                Social Media Posts
              </Flex>
              <Flex
                width="30%"
                justifyContent="flex-start"
                fontSize="14px"
                color="#667085"
                padding="5px"
              >
                {!isEmpty(socialBlocks) ? socialBlocks.length : 0} {!isEmpty(socialBlocks) && socialBlocks.length > 1 ? 'Posts' : 'Post'}
              </Flex>
            </Flex>
          )}
          
          <Flex display="flex" width="80%">
            <Flex
              width="50%"
              padding="5px 10px"
              fontSize="14px"
              color="#000000"
              justifyContent="flex-start"
              style={{ fontWeight: 500 }}
            >
              Campaign Duration
            </Flex>
            <Flex
              width="30%"
              justifyContent="flex-start"
              fontSize="14px"
              color="#667085"
              padding="5px"
            >
              {summaryDetails?.campaign_duration || 0} {summaryDetails?.campaign_duration > 1 ? 'Days' : 'Day'}
            </Flex>
          </Flex>
          {setObjectivesTabData?.is_email === "true" && (
            <Flex display="flex" width="80%">
              <Flex
                width="50%"
                padding="5px 10px"
                fontSize="14px"
                color="#000000"
                justifyContent="flex-start"
                style={{ fontWeight: 500 }}
              >
                Total Contacts
              </Flex>
              <Flex
                width="30%"
                justifyContent="flex-start"
                fontSize="14px"
                color="#667085"
                padding="5px"
              >
                {summaryDetails?.total_contacts}
              </Flex>
            </Flex>
          )}

        </Flex>
        <hr style={{ width: '100%' }} />
        <Flex width="100%">
          {complianceSettings === 'optional' && reviewType !== 'start' && setObjectivesTabData?.skip_compliance !== false && !setObjectivesTabData?.cascaded && (
            <Flex width="50%">
              <Flex width="17%" style={{ padding: '10px 15px 0px 8px' }}>
                <ToggleButton
                  className="react-switch"
                  onChange={(checked) => handleComplianceToggle(checked)}
                  checked={toogleCompliance === 'true'}
                  onColor="#816EF8"
                  offColor="#ccc"
                  offHandleColor="#fff"
                  onHandleColor="#fff"
                  handleDiameter={10}
                  uncheckedIcon=""
                  checkedIcon=""
                  boxShadow=""
                  activeBoxShadow=""
                  type="autoPost"
                  left="1px"
                  margin="-3px 5px 4px"
                  position="absolute"
                  top="7px"
                  opacity="0"
                />
              </Flex>
              <Flex
                width="70%"
                justifyContent="flex-start"
                padding="10px 0px"
                color="#101828"
                fontSize="14px"
                style={{ fontWeight: 500 }}
              >
                Compliance required
              </Flex>
            </Flex>
          )}

          <Flex width="50%">
            <Flex width="17%" style={{ padding: '10px 15px 0px 8px' }}>
              <ToggleButton
                className="react-switch"
                onChange={(checked) => handleLeadCaptureToggle(checked)}
                checked={toggleLeadCapture === 'true'}
                onColor="#816EF8"
                offColor="#ccc"
                offHandleColor="#fff"
                onHandleColor="#fff"
                handleDiameter={10}
                uncheckedIcon=""
                checkedIcon=""
                boxShadow=""
                activeBoxShadow=""
                type="autoPost"
                left="1px"
                margin="-3px 5px 4px"
                position="absolute"
                top="7px"
                opacity="0"
              />
            </Flex>
            <Flex
              width="70%"
              justifyContent="flex-start"
              padding="10px 0px"
              color="#101828"
              fontSize="14px"
              style={{ fontWeight: 500 }}
            >
              Enable Lead Capture
            </Flex>
          </Flex>
        </Flex>
        {isAdvisorApprovalMode && isadvMode && (
            <Flex width="100%">
              <Flex width="9%" style={{ padding: '10px 15px 0px 8px' }}>
                <ToggleButton
                  className="react-switch"
                  onChange={(checked) => handleAdvisorApproval(checked)}
                  checked={advisorApproval}
                  disabled={advisorApprovalRequired}
                  onColor="#816EF8"
                  offColor="#ccc"
                  offHandleColor="#fff"
                  onHandleColor="#fff"
                  handleDiameter={10}
                  uncheckedIcon=""
                  checkedIcon=""
                  boxShadow=""
                  activeBoxShadow=""
                  type="autoPost"
                  left="1px"
                  margin="-3px 5px 4px"
                  position="absolute"
                  top="7px"
                  opacity="0"
                />
              </Flex>
              <Flex
                width="70%"
                justifyContent="flex-start"
                padding="10px 0px"
                color="#101828"
                fontSize="14px"
                style={{ fontWeight: 500 }}
              >
                Advisor Approval Required
              </Flex>
            </Flex>
          )}
      </Flex>
    </>
  );
};

export default CampaignDetailsOnetimeSeries;
