import React from 'react';
import { ZeroStateContainer } from './styles';
import PurpleEmptyBox from 'src/assets/icons/PurpleEmptyBox';

function ZeroState({ title = 'No Articles Found' }) {
  return (
    <ZeroStateContainer>
      <PurpleEmptyBox />
      <p>{title ?? ''}</p>
    </ZeroStateContainer>
  );
}

export default ZeroState;
