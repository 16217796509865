import React from 'react';

function PurpleEmptyBox() {
  return (
    <svg width="12.5em" height="12.5em" viewBox="0 0 131 132" fill="none">
      <circle cx={65.499} cy={68.479} r={62.575} fill="#EFEFFD" />
      <path
        d="M18.271 43.685l47.227 23.613v49.588L18.271 93.273V43.685z"
        fill="#A0A3F2"
      />
      <path
        d="M112.725 43.685L65.498 67.298v49.588l47.227-23.613V43.685z"
        fill="#7B73E4"
      />
      <path
        d="M65.498 67.298L18.271 43.685 65.499 20.07l47.227 23.614-47.227 23.613z"
        fill="#D6D7FA"
      />
      <path
        d="M65.498 67.298V20.071L18.271 43.685l47.227 23.613z"
        fill="#A0A3F2"
      />
      <path
        d="M47.79 87.37L65.5 67.297 18.272 43.685.563 63.756 47.788 87.37zM18.273 43.685L.563 23.613 47.79 0 65.5 20.071 18.273 43.685zM112.727 43.685l17.71-20.072L83.21 0 65.5 20.071l47.227 23.614z"
        fill="#BBBEF7"
      />
      <path
        d="M83.209 87.37l-17.71-20.072 47.227-23.613 17.71 20.071L83.209 87.37z"
        fill="#BBBEF7"
      />
    </svg>
  );
}

export default PurpleEmptyBox;
