import { styled } from '@mui/material';

export const Wrapper = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: '2rem',
  overflow: 'hidden',
}));

