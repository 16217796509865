import { cloneDeep, isEmpty, findIndex } from "lodash";
import { getDefaultRows } from "../../services/bee-editor";
import { v4 as uuidv4} from 'uuid';

export const processNoContentTemplate = async (NoContentTemplate) => {
    let template = {}; 
    template = cloneDeep(NoContentTemplate);

    try {
        const res = await getDefaultRows();
    
        const signatureRows = res.data.filter(r => r?.category?.toLowerCase() === 'signature');
        const header = res.data.filter(r =>  r?.category?.toLowerCase() === 'header');
        const disclaimer = res.data.filter(r =>  r?.category?.toLowerCase() === 'disclaimer');
        const footer = res.data.filter(r =>  r?.category?.toLowerCase() === 'footer');

        if(!isEmpty(header)) {
            header[0].row_json.uuid = uuidv4();
            if(!header[0].row_json.metadata) {
                header[0].row_json.metadata = {};
            }
            header[0].row_json.metadata.category = "header";
            header[0].row_json.locked = false;
            header[0].row_json.columns.forEach((col) => {
                col.modules.forEach((mod) => {
                    mod.locked = false;
                    mod.uuid = uuidv4();
                })
            })
            template.page.rows.unshift(header[0].row_json);
        }
        if(!isEmpty(signatureRows)) {
            signatureRows[0].row_json.uuid = uuidv4();
            if(!signatureRows[0].row_json.metadata) {
                signatureRows[0].row_json.metadata = {};
            }
            signatureRows[0].row_json.metadata.category = "signature";
            signatureRows[0].row_json.locked = false;
            signatureRows[0].row_json.columns.forEach((col) => {
                col.modules.forEach((mod) => {
                    mod.locked = false;
                    mod.uuid = uuidv4();
                })
            })
            template.page.rows.splice(template.page.rows.length - 1, 0, signatureRows[0].row_json);
        }
        if(!isEmpty(disclaimer)) {
            disclaimer[0].row_json.uuid = uuidv4();
            if(!disclaimer[0].row_json.metadata) {
                disclaimer[0].row_json.metadata = {};
            }
            disclaimer[0].row_json.locked = false;
            disclaimer[0].row_json.columns.forEach((col) => {
                col.modules.forEach((mod) => {
                    mod.locked = false;
                    mod.uuid = uuidv4();
                })
            })
            disclaimer[0].row_json.metadata.category = "disclaimer";
            template.page.rows.splice(template.page.rows.length - 1, 0, disclaimer[0].row_json);
        }
        if(!isEmpty(footer)) {
            footer[0].row_json.uuid = uuidv4();
            footer[0].row_json.locked = false;
            if(!footer[0].row_json.metadata) {
                footer[0].row_json.metadata = {};
            }
            footer[0].row_json.columns.forEach((col) => {
                col.modules.forEach((mod) => {
                    mod.locked = false;
                    mod.uuid = uuidv4();
                })
            })
            footer[0].row_json.metadata.category = "footer";
            template.page.rows.splice(template.page.rows.length - 1, 0, footer[0].row_json);
        }
        
        template.page.rows.forEach((ele) => {
            ele.locked = false;
        });
        console.log('TEMPALTE - -- - ', template);
        return template
    } catch (error) {
        console.log(error);
    }
   
}

export const getAdvisorRows = async (savedTemplate, defaultRows = null) => {
    let template = {};
    template = cloneDeep(savedTemplate);
    console.log(template);
    try {
        let res;
        if(!defaultRows) {
            res = await getDefaultRows();
        } else {
            res = defaultRows;
        }
        
        const getOriginalRows = template.page.rows;

        const signatureRows = res.data.filter(r =>  r?.category?.toLowerCase() === 'signature');
        const header = res.data.filter(r =>  r?.category?.toLowerCase() === 'header');
        const disclaimer = res.data.filter(r =>  r?.category?.toLowerCase()=== 'disclaimer');
        const footer = res.data.filter(r =>  r?.category?.toLowerCase() === 'footer');

        if(!isEmpty(getOriginalRows)) {
            const originalSignatureRow = getOriginalRows.filter(r => r.metadata && r.metadata?.category?.toLowerCase() === "signature" && !r.locked);
            const originalHeaderRow = getOriginalRows.filter(r => r.metadata && r.metadata?.category?.toLowerCase() === "header" && !r.locked);
            const originalDisclaimerRow = getOriginalRows.filter(r => r.metadata && r.metadata?.category?.toLowerCase() === "disclaimer" && !r.locked);
            const originalFooterRow = getOriginalRows.filter(r => r.metadata && r.metadata?.category?.toLowerCase() === "footer" && !r.locked);

            if(!isEmpty(originalSignatureRow) && !isEmpty(signatureRows)) {
                signatureRows[0].row_json.uuid = uuidv4();
                const originalSignatureRowIndex = findIndex(getOriginalRows, r => r.metadata && r.metadata?.category?.toLowerCase() === "signature" && !r.locked);
                signatureRows[0].row_json.metadata.category = "signature";
                getOriginalRows[originalSignatureRowIndex] = signatureRows[0].row_json;
            }

            if(!isEmpty(originalHeaderRow) && !isEmpty(header)) {
                header[0].row_json.uuid = uuidv4();
                header[0].row_json.metadata.category = "header";
                const originalHeaderRowIndex = findIndex(getOriginalRows, r => r.metadata && r.metadata?.category?.toLowerCase() === "header" && !r.locked);
                getOriginalRows[originalHeaderRowIndex] = header[0].row_json;
            }

            if(!isEmpty(originalDisclaimerRow) && !isEmpty(disclaimer)) {
                disclaimer[0].row_json.uuid = uuidv4();
                disclaimer[0].row_json.metadata.category = "disclaimer";
                const originalDisclaimerRowIndex = findIndex(getOriginalRows, r => r.metadata && r.metadata?.category?.toLowerCase() === "disclaimer" && !r.locked);
                getOriginalRows[originalDisclaimerRowIndex] = disclaimer[0].row_json;
            }

            if(!isEmpty(originalFooterRow) && !isEmpty(footer)) {
                footer[0].row_json.uuid = uuidv4();
                footer[0].row_json.metadata.category = "footer";
                const originalFooterRowIndex = findIndex(getOriginalRows, r => r.metadata && r.metadata?.category?.toLowerCase() === "footer" && !r.locked);
                getOriginalRows[originalFooterRowIndex] = footer[0].row_json;
            }
        }
        return template;
    } 
    catch (error) {
        console.log('ERROR GET ADVISORS ROW - - -', error);
    }
}

export const handlePresetRows = async (savedTemplate) => {
    let template = {};
    template = cloneDeep(savedTemplate);
    console.log(template);
    try {
        const res = await getDefaultRows();
        const getOriginalRows = template.page.rows;

        const signatureRows = res.data.filter(r => r?.category?.toLowerCase() === 'signature');
        const header = res.data.filter(r =>  r?.category?.toLowerCase() === 'header');
        const disclaimer = res.data.filter(r => r?.category?.toLowerCase() === 'disclaimer');
        const footer = res.data.filter(r =>  r?.category?.toLowerCase() === 'footer');

        if(!isEmpty(getOriginalRows)) {
            const originalSignatureRow = getOriginalRows.filter(r => r.metadata && r.metadata?.category?.toLowerCase() === "signature");
            const originalHeaderRow = getOriginalRows.filter(r => r.metadata && r.metadata?.category?.toLowerCase() === "header");
            const originalDisclaimerRow = getOriginalRows.filter(r => r.metadata && r.metadata?.category?.toLowerCase() === "disclaimer");
            const originalFooterRow = getOriginalRows.filter(r => r.metadata && r.metadata?.category?.toLowerCase() === "footer");

            if(!isEmpty(originalSignatureRow) && !isEmpty(signatureRows)) {
                signatureRows[0].row_json.uuid = uuidv4();
                const originalSignatureRowIndex = findIndex(getOriginalRows, r => r.metadata && r.metadata?.category?.toLowerCase() === "signature");
                signatureRows[0].row_json.metadata.category = "signature";
                getOriginalRows[originalSignatureRowIndex] = signatureRows[0].row_json;
            }

            if(!isEmpty(originalHeaderRow) && !isEmpty(header)) {
                header[0].row_json.uuid = uuidv4();
                const originalHeaderRowIndex = findIndex(getOriginalRows, r => r.metadata && r.metadata?.category?.toLowerCase() === "header");
                header[0].row_json.metadata.category = "header";
                getOriginalRows[originalHeaderRowIndex] = header[0].row_json;
            }

            if(!isEmpty(originalDisclaimerRow) && !isEmpty(disclaimer)) {
                disclaimer[0].row_json.uuid = uuidv4();
                const originalDisclaimerRowIndex = findIndex(getOriginalRows, r => r.metadata && r.metadata?.category?.toLowerCase() === "disclaimer");
                disclaimer[0].row_json.metadata.category = "disclaimer";
                getOriginalRows[originalDisclaimerRowIndex] = disclaimer[0].row_json;
            }

            if(!isEmpty(originalFooterRow) && !isEmpty(footer)) {
                footer[0].row_json.uuid = uuidv4();
                const originalFooterRowIndex = findIndex(getOriginalRows, r => r.metadata && r.metadata?.category?.toLowerCase() === "footer");
                footer[0].row_json.metadata.category = "footer";
                getOriginalRows[originalFooterRowIndex] = footer[0].row_json;
            }
        }
        return template;
    } 
    catch (error) {
        console.log('ERROR GET ADVISORS ROW - - -', error);
    }
}