/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { OuterTableContainer } from './EmailList.styles';
import { AddButton } from './EmailList.styles';
import ModalContainer from '../../../componentsV2/ModalContainer';
import { getSegmentContactList, getSingularSegments } from '../../../services/segment';
import { getAllSegmentsCsv } from '../../../services/dashboard';
import { saveFile } from '../../../utils';
import ListTable from './ListTable';
import ContactTable from './ContactTable';
import { deleteSegment } from '../../../services/segment';

const SegmentTable = ({ 
  segmentList,
  headerData,
  renameModal,
  setRenameModal,
  modalDisplay,
  selectedSegmentObj, 
  setSelectedSegmentObj,
  segmentTabActive,
  fetchSegments,
  tableLoading, 
  setTableLoading,
  listLoading, 
  setListLoading,
  setSegmentList,
  emailList
 }) => {
  const [segmentOptionsEnabled, setSegmentOptionsEnabled] = useState(false);
  const [segmentName, setSegmentName] = useState(null);
  const [segmentTableData, setSegmentTableData] = useState();
  const [contactsLoader, isContactsLoading] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchBoxInput, setSearchBoxInput] = useState('');
  const [isAllCheckSelected, setAllCheckSelected] = useState(false);
  const [modalTitle, setModalTitle] = useState('Segment');
  const [searchString, setSearchString] = useState('');
  const [showEmptyDisplay, setShowEmptyDisplay] = useState(false);

  useEffect(() => {
    if (segmentList.length > 0) {
      getSegmentContacts(segmentList[0].id);
      setSegmentName(segmentList[0].name);
      setSegmentOptionsEnabled(true);
    } else {
      setSegmentTableData([]);
      setSegmentName(null);
    }
  },[segmentList]);

  // useEffect(()=>{
  //   if(selectedSegmentObj) getSegmentContacts(selectedSegmentObj.id)
  // },[selectedSegmentObj]);

  const sortSegmentContacts = (input) => {
    let isAscending = input === 'asc';
    isContactsLoading(true);
    var sortedSegmentArr = segmentTableData.sort((a, b) => {
      if (a.email < b.email) {
        return -1;
      }
      if (a.email > b.email) {
        return 1;
      }
      return 0;
    });
    if (!isAscending) {
      sortedSegmentArr.reverse();
    }
    setSegmentTableData(sortedSegmentArr);
    setTimeout(() => isContactsLoading(false), 500);
  };

  const getSegmentContacts = async(segmentId, searchString = null) => {
    isContactsLoading(true);
    try {
      const response = await getSegmentContactList(segmentId, searchString);
      setSegmentTableData(response.result.data || []);
    } catch(err){
      console.log('ERROR::', err);
      setSegmentTableData([]);
    }
  };

  const getSegmentsFromSegmentLists = async(searchText = null) => {
    setListLoading(true)
    try{
      const response = await getSingularSegments(searchText)
      if(response?.result?.data.length < 1) {
        setListLoading(false)
        setSegmentList([]);
        setShowEmptyDisplay(true)
      }
      else {
        setShowEmptyDisplay(false)
        setListLoading(false)
        setSegmentList(response?.result?.data)
      }
    }catch(err){
      console.log('segment list search err:', err)
      setListLoading(false);
    }
  };

  const downloadCsvHandler = (includedTags, excludedTags) => {
    // *** In Progress to add tags to name... might need a BE update
    // let nameAndTags = selectedSegmentObj.name
    // if(includedTags && includedTags[0].length > 1){
    //   nameAndTags += ` including: ${includedTags.toString()}`
    // }
    // if(excludedTags && excludedTags[0].length > 1){
    //   nameAndTags += ` exluding: ${excludedTags.toString()}`
    // }
    getAllSegmentsCsv(selectedSegmentObj.id)
    .then((res) => {
      saveFile(res, selectedSegmentObj.name);
    })
    .catch((error) => console.log('ERROR--',error));
  };

  const handleSearch = (value = null) => {
    setListLoading(true)
    getSegmentsFromSegmentLists(value || searchBoxInput);
  };

  const searchHandler = (value = null) => {
    getSegmentContacts(selectedSegmentObj.id, value || searchString);
  }

  const handleSegmentDelete = async() => {
    try{
      setListLoading(true)
      await deleteSegment(selectedSegmentObj.id)
      setSelectedSegmentObj(segmentList[0])
      setSegmentName(segmentList[0].name);
      setRenameModal(false)
      await fetchSegments()
      setListLoading(false)
    }catch(err){
      console.log('Delete segment error:', err)
    }
  };

  const refreshData = async() => {
    setSearchBoxInput('')
    setSearchString('')
    try{
      setListLoading(true)
      setTableLoading(true)
      await fetchSegments()
      setListLoading(false)
      setTableLoading(false)
    }catch(err){
      console.log('Refresh segment error:', err)
    }
  }

  const editSegmentDisplay = () => {
    switch(modalDisplay){
      case 'edit':
        break;
      case 'delete':
        if(modalTitle !== 'Confirm Delete') setModalTitle('Confirm Delete')
        return(
          <div style={{display: 'table', width: 400, background: 'white'}}>
            <p style={{color: '#475467', margin: '-10px auto 10px', fontSize: 14}}>Are you sure you want to delete this segment? </p>
            <div style={{display: 'flex', justifyContent: 'space-between'}}>
              <AddButton 
                children="Yes" 
                style={{ margin: '20px auto 0', paddingBottom: 10}}
                onClick={()=>handleSegmentDelete()}
              />
              <AddButton 
                children="No" 
                style={{ margin: '20px auto 0', paddingBottom: 10}}
                onClick={()=>setRenameModal(false)}
              />
            </div>
          </div>
        )
      default: 
        return <div />
    }
  }

  return (
    <div style={{ paddingBottom: 80 }}>
      <OuterTableContainer>
          <ListTable 
            tableData={segmentList} 
            searchBoxInput={searchBoxInput} 
            setSearchBoxInput={setSearchBoxInput}
            listCheckAction={(element) => {
              getSegmentContacts(element.id)
              setSegmentOptionsEnabled(true)
              setSegmentName(element.name)
              setSelectedSegmentObj(element)
            }}
            headerData={headerData}
            searchHandler={handleSearch}
            segmentTabActive={segmentTabActive}
            tableLoading={tableLoading}
            listLoading={listLoading}
            setTableLoading={setTableLoading}
            selectedRow={selectedSegmentObj}
            setSelectedRow={setSelectedSegmentObj}
            refreshDataAction={refreshData}
            showEmptyDisplay={showEmptyDisplay}
          />
          {
            <ContactTable 
              tableData={segmentTableData}
              segmentTabActive={true}
              searchString={searchString} 
              setSearchString={setSearchString}
              headerData={headerData}
              onAllCheckboxClick={()=>console.log('on click')}
              isAllCheckSelected={isAllCheckSelected}
              handleEmailSort={sortSegmentContacts}
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setRowsPerPage}
              tableName={segmentName}
              selectedSegmentObj={selectedSegmentObj}
              downloadCSVHandler={downloadCsvHandler}
              tableLoading={tableLoading}
              setTableLoading={setTableLoading}
              handleSearch={searchHandler}
              emailList={segmentList}
              setTableName={setSegmentName}
            />
          }
       </OuterTableContainer>

      <ModalContainer
        modalStatus={renameModal}
        closeModal={() => setRenameModal(!renameModal)}
        title={modalTitle}
        children={editSegmentDisplay()}
      />
    </div>
  );
};

export default SegmentTable;
