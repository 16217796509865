import { Flex } from '@rebass/grid';
import styled from 'styled-components';
import { Modal, ModalButton } from '../../components/Modal/Modal';
import { TabPanel } from '../../components';

export const ChannelWrapper = styled(Flex)`
  font-family: 'Poppins';
  font-size: 12px;
  flex: 1 1 auto;
  width: 100%;
  margin-bottom: 10px !important;
  a {
    text-decoration: none;
  }
  @media (max-width: 768px) {
    margin-top: 60px !important;
  }
`;

export const ChannelContainer = styled.div`
  width: 83%;
  height: 100%;
  margin: auto;
  background: #f5f5f5;
  margin-top: 30px;
  margin-bottom: 10px;
`;

export const ActiveTheme = styled.div``;

export const ChannelsHeader = styled.h4`
  background-color: #e5e5e5 !important;
  color: #6351ed !important;
  font-size: 16px;
  height: auto;
  padding: 6px;
  text-align: center;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  margin: 0;
  font-weight: 500;
`;

export const SelectThemes = styled.div`
background: linear-gradient(to bottom, rgba(252,252,252,1) 0%,rgba(247,247,247,1) 50%,rgba(247,247,247,1) 50%,rgba(227,227,227,1) 100%);
border: 1px solid #e1e1e1 !important;
    border-top: 0 !important;
    margin-top: 0px;
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
    padding: 10px 10px 20px;
`;
export const SearchCategory = styled.div`
  margin-bottom: 0;
  max-height: 170px;
  overflow-y: auto;
  overflow-x: hidden;
`;

export const AddedChannels = styled.ul`
  clear: none;
  display: inline-block;
  width: 100%;
  text-align: center;
  list-style-type: none;
  padding-left: 0;
`;

export const SMP = styled.h4`
  font-size: 16px;
  color: #6351ed;
  margin-top: 10px;
  margin-bottom: 10px;
  font-weight: 500;
  line-height: 1.1;
`;

export const SearchResultsContainer = styled.div``;

export const ChannelsPage = styled.div`
  clear: both;
  margin-bottom: 15px;
`;
export const SearchResults = styled.ul`
  clear: both;
  display: flex;
  padding: 0;
  margin: 0;
`;

export const IP = styled.div`
  padding: 5px 0;
  border-top: 1px solid #dfdfdf;
  margin: 10px 0 10px;
`;

export const IPHeader = styled.h5`
  text-align: center;
  color: #6351ed;
  letter-spacing: 0;
  font-size: 13px;
  margin-bottom: 8px;
  margin-top: 10px;
  font-weight: 500;
  line-height: 1.1;
`;

export const ListWrapper = styled.div`
  max-height: 280px;
  overflow-y: auto;
  overflow-x: hidden;
  clear: both;
  .inactive-partner {
    pointer-events: none;
    opacity: 0.6;
  }
`;

export const List = styled.ul`
  clear: none;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin: 0;
`;
export const PremiumPartners = styled.div`
  padding: 5px 0;
  border-top: 1px solid #dfdfdf;
  margin: 10px 0 10px;
`;
export const PremiumPartnersHeader = styled.h5`
  color: #6351ed;
  letter-spacing: 0;
  font-size: 13px;
  margin-bottom: 8px;
  margin-top: 10px;
  font-weight: 500;
  line-height: 1.1;
`;
export const PremiumPartnersList = styled.ul``;

export const Suggest = styled.div`
  text-align: center;
  width: 100%;
  margin: 0;
`;
export const SuggestHeader = styled.h6`
  color:  #6351ed;
  margin: 0;
  font-size: 12px;
  font-weight: 500;
`;
export const SuggestLink = styled.a`
  background-color: #eee !important;
  border: 1px solid #a0a0a0 !important;
  color: #6351ed !important;
  display: inline-block;
  margin-top: 10px;
  padding: 5px 18px;
  border-radius: 5px;
  margin-left: 8px;
  transition: all 0.3s ease;
  cursor: pointer;
`;

export const CustomModal = styled(Modal)`
  width: 40%;
`;
export const ComponentSection = styled.div`
  width: 70%;
  margin: 0 auto;
`;

export const CustomInput = styled.input`
  margin-bottom: 15px;
  color: #000 !important;
  background-color: #ebebeb;
  border: 1px solid #959595;
  text-align: center;
  display: block;
  width: 100%;
  height: 34px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  background-image: none;
  border-radius: 4px;
  font-family: 'Poppins';
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
`;

export const CustomModalButton = styled(ModalButton)`
  font-size: 12px;
  padding: 5px 25px;
  margin-top: 20px;
  color: white;
  background: #6351ed;
  font-family: 'Poppins';
`;
export const PremiumModalContent = styled.h6`
  font-size: 14px;
  font-weight: bold;
  margin: 0;
`;

export const SuccessMessage = styled.h4`
  font-size: 14px;
  font-weight: bold;
  color: #6351ed;
  text-align: center;
`;

export const StyledTabPanelPublisher = styled(TabPanel)`
  background-color: #fff;
  position: relative;
  border-radius:${({ type }) => type === 'admin' ? '0' : '10px'};
  margin-top: -0px;
  /*border: ${({ type }) => type === 'admin' ? 'none' : '1px solid #d4d4d4'};*/
  border-top: 0;
  &.react-tabs__tab-panel--selected {
    height:  ${({ type }) => type === 'admin-tab' ? '345px' : 'auto'};
    padding: ${({ padding }) => padding || '0 0'};
    border-right: ${({ borderRight }) => borderRight || '0'};
    border-left: ${({ borderLeft }) => borderLeft || '0'};
    border-bottom: ${({ borderBottom }) => borderBottom || '0'};
    border-top-left-radius: ${({ borderTopLeftRadius }) => borderTopLeftRadius || '0'};
    border-top-right-radius: ${({ borderTopRightRadius }) => borderTopRightRadius || '0'};
  }
`;


export const PublisherCheckBox = styled.div.attrs({
  className: 'newsletter-checkbox-div'
})`
position: relative;
padding: 5px 0px;

/* The container */
.container {
  display: block;
  position: relative;
  padding-left: 25px;
  font-size: 12px;
  color:  grey;
  text-align: left;
  font-weight: ${({ fontWeight }) => fontWeight || ''};
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin-top: -20px;
}

/* Hide the browser's default checkbox */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 2px;
  left: 6px;
  height: 13px;
  width: 13px;
  background-color: #eee;
  border: 1px solid #c9c9c9;
  border-radius: 4px;
}

.container input ~ .checkmark {
  background-color: #fff;
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
  background-color: #fff;
}

/* When the checkbox is checked, add a blue background */
.container input:checked ~ .checkmark {
  background-color: #c95cfc;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
  left: 3px;
  top: -1px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
`;
