import { styled } from '@mui/material';

export const PerformanceChartContainer = styled('section')(() => ({
  boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.12)',
  border: '1px solid #E4E7EC',
  background: '#fff',
  borderRadius: '0.5rem',
  margin: '1.5rem 0',
}));

export const PerformanceChartHeader = styled('div')(() => ({
  borderBottom: '1px solid #E4E7EC',

  '& h2': {
    padding: '1.5rem',
    margin: 0,
    color: '#000',
    font: '500 1.25rem/1rem Poppins,sans-serif',
    textAlign: 'left',
  },
}));

export const PerformanceGraphSection = styled('div')(() => ({
  padding: '1.5rem',
  width: '100%',

  '& .legend-container': {
    display: 'flex',
    gap: '2rem',
    marginBottom: '1.5rem',
  },

  '& .legend-info-container': {
    display: 'flex',
    gap: '0.5rem',
  },

  '& .legend-item': {
    color: '#000000',
    font: '400 0.75rem/1rem Poppins,sans-serif',
  },
}));

export const LegendBox = styled('div')(({ bgColor = '' }) => ({
  width: '1rem',
  height: '1rem',
  borderRadius: '2px',
  background: bgColor ?? '',
}));

export const TooltipContainer = styled('div')(() => ({
  boxShadow: '0px 0px 100px rgba(63, 106, 194, 0.15)',
  padding: '0.625rem 1.25rem',
  background: '#fff',
  borderRadius: '0.25rem',
}));

export const TooltipTitle = styled('div')(() => ({
  font: '500 1rem/1rem Poppins,sans-serif',
  color: '#000',
  textAlign: 'left',
  margin: '5px 0',
}));

export const TooltipInfoContainer = styled('div')(() => ({
  display: 'flex',

  '& p': {
    font: '500 0.875rem/1rem Poppins,sans-serif',
    color: '#000',
    textAlign: 'left',
    marginRight: '1rem',
  },
}));
