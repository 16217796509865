/* eslint-disable react-hooks/exhaustive-deps */
import {
  faAngleLeft,
  faEnvelope
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState, useEffect, Suspense } from 'react';
import {
  Tabs,
  StyledTabListLeft,
  VerticalTabs,
  ResponsiveImg,
  LoaderWrapper,
} from '../../../components';
import { DashboardAdminSubTab } from '../../DashboardContainer/DashboardContainer.styles';
import {
  AnalyticsVerticalTabList,
  AnalyticsWebinarVerticalTabList,
  AnalyticsVerticalTabPanel,
  CommonTabPanel,
  EngagementTabPanel,
  TabLabel,
} from '../AnalyticsContainer.styles';
import EngagementShareTab from './EngagementShareTab';
import LeadTab from './LeadTab';
import OverviewTab from './OverviewTab';
import {
  EngagementBackButton,
  Analytics,
} from './EngagementTab.styles';
import { Flex } from '@rebass/grid';
import ShareInsightTable from './ShareInsightTable';
import LeadInsightTable from './LeadInsightTable';
import { getContactsGroups } from '../../../services/configure';
import { isEmpty, map, get, filter } from 'lodash';
import WebinarInsights from './WebinarInsights'
import {
  faFacebook,
  faLinkedin,
  faTwitter,
} from '@fortawesome/free-brands-svg-icons';
import { Button } from '../../../components/Form/Button';
const EngagementWebinarTab = React.lazy(() => import("./EngagementWebinarTab"));

const styles = {
  adminSubTab: { fontSize: '14px', marginBottom: '20px' },
  clickThroughText: { fontSize: '16px', color: 'gray' },
};

const calPerc = (x, total) => {
  let perc = (x * 100) / total;
  perc = perc > 100 ? 100 : perc;
  if (perc % 1 === 0) {
    return Math.round(perc);
  } else if (perc === 0) {
    return 0;
  } else if (isNaN(perc)) {
    return 0;
  } else {
    return Math.round(perc);
  }
};

const EnagagementTab = ({
  selectedDate,
  setSelectedDate,
  selectedRepType,
  analyticsRequest,
  analyticsData,
  isAnalyticsDataFetching,
  analyticsObject,
  setAnalyticsTabIndex,
  isAdmin,
  analyticsSetState,
}) => {
  const [tabIndex, setTabIndex] = useState(0);
  const [tabSocialIndex, setTabSocialIndex] = useState(0);
  const [showBackButton, setShowBackButton] = useState(false);
  const [showLeadInsight, setShowLeadInsight] = useState(false);
  const [showShareInsight, setShowShareInsight] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});
  const [contactGroup, setContactGroup] = useState([]);
  const [selectedContacts, setSelectedContacts] = useState([]);
  const [verticalTabIndex, setVerticalTabIndex] = useState(0);
  const [page, setPage] = useState(0);
  const [paginationToken, setPaginationToken] = useState('');
  const [showWebinarInsight, setShowWebinarInsight] = useState(false);
  const [newsletterTabIndex, setNewsletterTabIndex] = useState(0);
  const [hasArticleCount, setHasArticleCount] = useState(false);
  const [activeShareType, setActiveShareType] = useState('email_share');
  // const [emailColor, setEmailColor ] = useState(true);
  // const [socialMediaColor, setSocialMediaColor ] = useState(false);

  console.log('ANALYTICS DATA - -- ', analyticsData);

  const apiRequest = (repType) => {
    analyticsRequest({
      params: {
        repType,
        selectedEmail: '',
      },
    });
  };

  const handleNewsletterTabChange = (index) => {
    setNewsletterTabIndex(index);
    if (index === 0) {
      analyticsRequest({
        params: {
          repType: selectedRepType,
          type: 'prospects',
          engagement_id: selectedItem.id,
        },
      });
    } else {
      analyticsRequest({
        params: {
          repType: selectedRepType,
          type: 'insights',
          engagement_id: selectedItem.id,
        },
      });
    }
  };

  useEffect(() => {
    getContactsGroups()
      .then((res) => {
        if (res.result.success) {
          let contacts = res.result.data.filter((contact) => {
            return ![
              'GMAIL',
              'HUBSPOT',
              'OUTLOOK',
              'WEALTHBOX',
              'SALESFORCE',
              'REDTAIL',
            ].includes(get(contact, 'name')) && get(contact, 'contacts.count', 0) > 0;
          });
          setContactGroup(contacts);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    if (!isEmpty(selectedContacts)) {
      setPage(0);
      analyticsRequest({
        params: {
          repType: selectedRepType,
          contact_list: selectedContacts,
          engagement_id:
            (showShareInsight || showWebinarInsight) && selectedItem.id,
          type:
            showWebinarInsight && newsletterTabIndex === 0
              ? 'prospects'
              : showWebinarInsight && newsletterTabIndex === 1
                ? 'insights'
                : '',
        },
      });
    } else {
      if (tabIndex === 1) {
        apiRequest('email_share_by_leads');
      } else if (tabIndex === 2) {
        apiRequest('email_share_by_topics');
      } else if (tabIndex === 3) {
        apiRequest('newsletter_share_by_articles_webinar');
      } else {
        apiRequest('email_share_overview');
      }
    }
  }, [selectedContacts]);

  const onTabSelect = (index) => {
    setTabIndex(index);
    setNewsletterTabIndex(0);
    setPage(0);
    setPaginationToken('');
    if (showLeadInsight) {
      setAnalyticsTabIndex({ selectedEmail: '' });
    }
    setSelectedContacts([]);
    setShowLeadInsight(false);
    setShowShareInsight(false);
    setShowWebinarInsight(false);
    setShowBackButton(false);
    analyticsSetState({ data: {} });
    // if (index === 1) {
    //   apiRequest('email_share_by_leads');
    // } else if (index === 2) {
    //   apiRequest('email_share_by_topics');
    // } else if (index === 3) {
    //   apiRequest('newsletter_share_by_articles');
    // } else {
    //   apiRequest('email_share_overview');
    // }
  };

  const onSocialTabSelect = (index) => {
    console.log(index);
    setTabSocialIndex(index);
  };


  const onVerticalTabSelect = (index) => {
    console.log(index);
    setVerticalTabIndex(index);
    if (index === 0) {
      setTabIndex(0);
      apiRequest('email_share_overview');
    } else if (index === 1) {
      setTabSocialIndex(0);
    }
  };


  const handleRowClick = (filter, item, flag) => {
    setSelectedItem(item);
    setShowBackButton(true);
    if (tabIndex === 1) {
      setShowLeadInsight(true);
      let params = {
        repType: selectedRepType,
        email_id: filter,
        lead_source: item.lead_source || 'em',
        lead_type: item.lead_type,
      };

      if (!isEmpty(selectedContacts)) {
        params.contact_list = selectedContacts;
      }

      analyticsRequest({
        params: params,
      });
    } else if (tabIndex === 2) {
      setShowShareInsight(true);
      analyticsRequest({
        params: {
          repType: selectedRepType,
          engagement_id: filter,
        },
      });
    } else if (tabIndex === 3) {
      setShowWebinarInsight(true);
      analyticsRequest({
        params: {
          repType: selectedRepType,
          engagement_id: filter,
          type: 'prospects',
        },
      });
    } else {
      if (flag === 'prospects') {
        setShowLeadInsight(true);
        analyticsRequest({
          params: {
            repType: 'email_share_by_leads',
            email_id: filter,
          },
        });
      } else if (flag === 'insights') {
        setShowShareInsight(true);
        analyticsRequest({
          params: {
            repType: 'email_share_by_topics',
            engagement_id: filter,
          },
        });
      }
    }
  };

  const handleBackButtonClick = () => {
    setAnalyticsTabIndex({ selectedEmail: '' });
    setNewsletterTabIndex(0);
    if (tabIndex === 1) {
      setAnalyticsTabIndex({ repType: 'email_share_by_leads' });
    } else if (tabIndex === 2) {
      setAnalyticsTabIndex({ repType: 'email_share_by_topics' });
    } else if (tabIndex === 3) {
      setAnalyticsTabIndex({ repType: 'newsletter_share_by_articles_webinar' });
    } else {
      setAnalyticsTabIndex({ repType: 'email_share_overview' });
    }
    if (showLeadInsight) {
      let params = {
        selectedDate: analyticsObject.selectedDate,
        fromDate: analyticsObject.startDate,
        toDate: analyticsObject.endDate,
        afterData:
          paginationToken && paginationToken.length > 0 ? paginationToken : '',
        selectedEmail: '',
      };

      if (!isEmpty(selectedContacts)) {
        params.contact_list = selectedContacts;
      }

      setSelectedDate(params);
    } else {
      if (tabIndex === 0) {
        analyticsRequest({
          params: {
            repType: 'email_share_overview',
            selectedEmail: '',
          },
        });
      } else {
        analyticsRequest({
          params: {
            repType: selectedRepType,
            selectedEmail: '',
            afterData:
              paginationToken && paginationToken.length > 0
                ? paginationToken
                : '',
          },
        });
      }
    }
    setShowBackButton(false);
    setShowLeadInsight(false);
    setShowShareInsight(false);
    setShowWebinarInsight(false);
    setSelectedItem({});
  };

  const filterByContactList = (id) => {
    console.log('id', id);

    if (id === 'default') {
      let defaultList = filter(contactGroup, function (o) {
        return o.contact_type === 'default';
      });

      let defaultIDs = defaultList.map((ids) => {
        return ids.id;
      });
      if (defaultIDs.every((ai) => selectedContacts.includes(ai))) {
        let repArray = selectedContacts.filter(
          (el) => !defaultIDs.includes(el)
        );

        setSelectedContacts([...repArray]);
      } else {
        setSelectedContacts([...selectedContacts, ...defaultIDs]);
      }
    } else if (id === 'client') {
      let clientList = filter(contactGroup, function (o) {
        return o.contact_type === 'client';
      });

      let clientIDs = clientList.map((ids) => {
        return ids.id;
      });

      if (clientIDs.every((ai) => selectedContacts.includes(ai))) {
        let repArray = selectedContacts.filter((el) => !clientIDs.includes(el));

        setSelectedContacts([...repArray]);
      } else {
        setSelectedContacts([...selectedContacts, ...clientIDs]);
      }
    } else if (id === 'prospect') {
      let prospectList = filter(contactGroup, function (o) {
        return o.contact_type === 'prospect';
      });

      let prospectIDs = prospectList.map((ids) => {
        return ids.id;
      });

      if (prospectIDs.every((ai) => selectedContacts.includes(ai))) {
        let repArray = selectedContacts.filter(
          (el) => !prospectIDs.includes(el)
        );

        setSelectedContacts([...repArray]);
      } else {
        setSelectedContacts([...selectedContacts, ...prospectIDs]);
      }
    } else if (
      id === 'all' &&
      contactGroup.length === selectedContacts.length
    ) {
      setSelectedContacts([]);
      analyticsRequest({
        params: {
          repType: selectedRepType,
          engagement_id: showShareInsight && selectedItem.id,
        },
      });
    } else if (
      id === 'all' &&
      contactGroup.length !== selectedContacts.length
    ) {
      setSelectedContacts(map(contactGroup, 'id'));
    } else if (!selectedContacts.includes(id)) {
      setSelectedContacts([...selectedContacts, id]);
    } else {
      const filterContacts = selectedContacts.filter((item) => item !== id);
      setSelectedContacts([...filterContacts]);
      if (isEmpty(filterContacts)) {
        analyticsRequest({
          params: {
            repType: selectedRepType,
            engagement_id:
              (showShareInsight || showWebinarInsight) && selectedItem.id,
            type:
              showWebinarInsight && newsletterTabIndex === 0
                ? 'prospects'
                : showWebinarInsight && newsletterTabIndex === 1
                  ? 'insights'
                  : '',
          },
        });
      }
    }
  };


  const onSocialMediaEmailClick = (shareType) => {
    setActiveShareType(shareType);
  };

  return (
    <>
      {!isAnalyticsDataFetching && showBackButton && (
        <EngagementBackButton onClick={handleBackButtonClick}>
          <FontAwesomeIcon icon={faAngleLeft} />
          &nbsp; Back
        </EngagementBackButton>
      )}
      {showLeadInsight && !showShareInsight && !showWebinarInsight && (
        <Flex
          justifyContent="space-between"
          width={1}
          style={{
            height: '570px',
            borderRight: '1px solid rgb(229, 229, 229)',
            borderLeft: '1px solid rgb(229, 229, 229)',
            borderBottom: '1px solid rgb(229, 229, 229)',
          }}
        >
          <Flex width="20%" flexDirection="column">
            {console.log('showLeadInsight', showLeadInsight)}
            <div
              style={{
                flex: '1',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <div>
                {/* <ReachIcon /> */}
                <FontAwesomeIcon icon={faEnvelope} />
                <div style={{ padding: '2px' }}>
                  {console.log('tabIndex', tabIndex)}
                  <TabLabel color="#6351ed" fontSize="16px">
                    Email
                  </TabLabel>
                </div>
                <>
                  {!isAnalyticsDataFetching &&
                    analyticsData.details &&
                    analyticsData.details.total &&
                    !!parseInt(analyticsData.details.total.sent) && (
                      <div style={{ padding: '2px' }}>
                        <div color="#6351ed" fontSize="16px">
                          {analyticsData.details.total.sent || '0'}
                          <span style={{ paddingLeft: '3px' }}>sent</span>
                        </div>
                      </div>
                    )}

                  {!isAnalyticsDataFetching &&
                    analyticsData.details &&
                    analyticsData.details.total &&
                    !!parseInt(analyticsData.details.total.sent) && (
                      <div style={{ padding: '2px' }}>
                        <div color="#6351ed" fontSize="16px">
                          {calPerc(
                            analyticsData.details.total.unique_opens,
                            analyticsData.details.total.sent
                          )}
                          % open
                        </div>
                      </div>
                    )}

                  {!isAnalyticsDataFetching &&
                    analyticsData.details &&
                    analyticsData.details.total &&
                    !!parseInt(analyticsData.details.total.sent) && (
                      <div style={{ padding: '2px', marginRight: '4px' }}>
                        <div color="#6351ed" fontSize="16px">
                          {calPerc(
                            analyticsData.details.total.unique_clicks,
                            (analyticsData.details.total &&
                              analyticsData.details.total
                                .unique_opens_in_non_empty_links_newsletters) ||
                            +analyticsData.details.total.unique_opens
                          )}
                          % click-through
                        </div>
                      </div>
                    )}
                </>
              </div>
            </div>

            <div
              style={{
                flex: '1',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: tabIndex === 0 ? 'unset' : '#ffffff',
              }}
            >
              <div>
                {/* <ReachIcon /> */}
                <ResponsiveImg
                  src="/assets/images/recipients.svg"
                  alt="socialMedia"
                />
                <div style={{ padding: '2px' }}>
                  <TabLabel
                    color="#6351ed"
                    fontSize="16px"
                    style={{
                      color:
                        !get(analyticsData, 'overview.ln') &&
                          !get(analyticsData, 'overview.fb') &&
                          !get(analyticsData, 'overview.tw') &&
                          !get(analyticsData, 'details.total.ln') &&
                          !get(analyticsData, 'details.total.fb') &&
                          !get(analyticsData, 'details.total.tw')
                          ? 'lightgray'
                          : '#6351ed',
                    }}
                  >
                    Social Media Impact
                  </TabLabel>
                  <>
                    <Analytics>
                      <div class="flex-row-container">
                        {!isAnalyticsDataFetching &&
                          analyticsData.details &&
                          analyticsData.details.total &&
                          analyticsData.details.total.ln >= 0 && (
                            <div color="#6351ed" fontSize="16px">
                              <div class="flex-row-item">
                                <FontAwesomeIcon
                                  icon={faLinkedin}
                                  color="#848484"
                                  size="1x"
                                />
                                <span class="flex-row-item"></span>
                                {analyticsData.details.total.ln}
                              </div>
                            </div>
                          )}
                      </div>
                      <div class="flex-row-container">
                        {!isAnalyticsDataFetching &&
                          analyticsData.details &&
                          analyticsData.details.total &&
                          analyticsData.details.total.fb >= 0 && (
                            <div color="#6351ed" fontSize="16px">
                              <div class="flex-row-item">
                                <FontAwesomeIcon
                                  icon={faFacebook}
                                  color="#848484"
                                  size="1x"
                                />
                                <span class="flex-row-item"></span>
                                {analyticsData.details.total.fb}
                              </div>
                            </div>
                          )}
                      </div>
                      <div class="flex-row-container">
                        {!isAnalyticsDataFetching &&
                          analyticsData.details &&
                          analyticsData.details.total &&
                          analyticsData.details.total.tw >= 0 && (
                            <div color="#6351ed" fontSize="16px">
                              <div class="flex-row-item">
                                <FontAwesomeIcon
                                  icon={faTwitter}
                                  color="#848484"
                                  size="1x"
                                />
                                <span class="flex-row-item"></span>
                                {analyticsData.details.total.tw}
                              </div>
                            </div>
                          )}
                      </div>
                    </Analytics>
                  </>
                </div>
              </div>
            </div>
            {/* <ShareBox>
              <FontAwesomeIcon color="#969198" icon={faEnvelope} />
              <Box fontSize="14px" style={{ fontWeight: '600' }}>
                Sent
              </Box>
              <Box fontSize="26px" style={{ fontWeight: '600' }}>
                {!isAnalyticsDataFetching &&
                  analyticsData.details &&
                  analyticsData.details.total &&
                  (analyticsData.details.total.sent || '0')}
              </Box>
            </ShareBox>
            <ShareBox>
              <FontAwesomeIcon color="#7dc5d0" icon={faEnvelopeOpen} />
              <Box fontSize="14px" style={{ fontWeight: '600' }}>
                Unique Opens
              </Box>
              <Box fontSize="26px" style={{ fontWeight: '600' }}>
                {!isAnalyticsDataFetching &&
                  analyticsData.details &&
                  analyticsData.details.total &&
                  (analyticsData.details.total.unique_opens || '0')}
              </Box>
              <Box fontSize="14px">
                {!isAnalyticsDataFetching &&
                  analyticsData.details &&
                  analyticsData.details.total &&
                  calPerc(
                    +analyticsData.details.total.unique_opens,
                    +analyticsData.details.total.sent
                  )}
                %
              </Box>
            </ShareBox>
            <ShareBox>
              <FontAwesomeIcon color="#c85cfc" icon={faMousePointer} />
              <Box fontSize="14px" style={{ fontWeight: '600' }}>
                Unique Clicks
              </Box>
              <Box fontSize="26px" style={{ fontWeight: '600' }}>
                {!isAnalyticsDataFetching &&
                  analyticsData.details &&
                  analyticsData.details.total &&
                  (analyticsData.details.total.unique_clicks || '0')}
              </Box>
              <Box fontSize="14px">
                {!isAnalyticsDataFetching &&
                  analyticsData.details &&
                  analyticsData.details.total &&
                  calPerc(
                    +analyticsData.details.total.unique_clicks,
                    (analyticsData.details.total && analyticsData.details.total.unique_opens_in_non_empty_links_newsletters) || +analyticsData.details.total.unique_opens
                  )}
                %
              </Box>
            </ShareBox> */}
          </Flex>
          <Flex width="72%" mr="25px" flexDirection="column">
            <Tabs
              selectedIndex={tabIndex}
              onSelect={onTabSelect}
              // style={{ marginTop: '18px' }}
            >
              <StyledTabListLeft marginLeft='0' height="55px" screenWidth={window.innerWidth}>
                <DashboardAdminSubTab
                  disabled={isAnalyticsDataFetching}
                  style={styles.adminSubTab}
                  color="#49484a"
                  padding="5px 23px 3px"
                  type="analytics-tab"
                  className={`${tabIndex === 0 ? 'active' : 'inactive'}`}
                >
                  Overview
                </DashboardAdminSubTab>
                <DashboardAdminSubTab
                  disabled={isAnalyticsDataFetching}
                  style={styles.adminSubTab}
                  color="#49484a"
                  padding="5px 23px 3px"
                  type="analytics-tab"
                  className={`${tabIndex === 1 ? 'active' : 'inactive'}`}
                >
                  Contacts
                </DashboardAdminSubTab>
                <DashboardAdminSubTab
                  disabled={isAnalyticsDataFetching}
                  style={styles.adminSubTab}
                  color="#49484a"
                  padding="5px 23px 3px"
                  type="analytics-tab"
                  className={`${tabIndex === 2 ? 'active' : 'inactive'}`}
                >
                  Content
                </DashboardAdminSubTab>
                <DashboardAdminSubTab
                  disabled={isAnalyticsDataFetching}
                  style={styles.adminSubTab}
                  color="#49484a"
                  padding="5px 23px 3px"
                  type="analytics-tab"
                  className={`${tabIndex === 3 ? 'active' : 'inactive'}`}
                >
                  Webinars
                </DashboardAdminSubTab>
              </StyledTabListLeft>
            </Tabs>
            <Flex
              style={{
                width: '746px',
                height: '442px',
                // margin: '16px 0.2px 20px 20px',
                padding: '31px 20.7px 39px 21px',
                border: 'solid 1px #ececec',
                backgroundColor: '#ffffff',
              }}
            >

              <LeadInsightTable
                selectedItem={selectedItem}
                analyticsData={analyticsData}
                isAnalyticsDataFetching={isAnalyticsDataFetching}
                selectedDate={selectedDate}
                setSelectedDate={setSelectedDate}
                analyticsRequest={analyticsRequest}
                repType={selectedRepType}
                analyticsObject={analyticsObject}
                page={page}
                setPage={setPage}
                isAdmin={isAdmin}
              />
            </Flex>
          </Flex>
        </Flex>
      )}
      {showShareInsight && !showLeadInsight && !showWebinarInsight && (
        <Flex
          justifyContent="space-between"
          width={1}
          style={{
            height: '570px',
            borderRight: '1px solid rgb(229, 229, 229)',
            borderLeft: '1px solid rgb(229, 229, 229)',
            borderBottom: '1px solid rgb(229, 229, 229)',
          }}
        >
          <Flex width="20%" flexDirection="column">
            {console.log('showShareInsight', showShareInsight)}

            <div
              style={{
                flex: '1',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <div>
                {/* <ReachIcon /> */}
                <FontAwesomeIcon icon={faEnvelope} />
                <div style={{ padding: '2px' }}>
                  {console.log('tabIndex', tabIndex)}
                  <TabLabel color="#6351ed" fontSize="16px">
                    Email
                  </TabLabel>
                </div>
                <>
                  {!isAnalyticsDataFetching &&
                    analyticsData.details &&
                    analyticsData.details.total &&
                    !!parseInt(analyticsData.details.total.recipients) && (
                      <div style={{ padding: '2px' }}>
                        <div color="#6351ed" fontSize="16px">
                          {analyticsData.details.total.recipients || '0'}
                          <span style={{ paddingLeft: '3px' }}>recipients</span>
                        </div>
                      </div>
                    )}

                  {!isAnalyticsDataFetching &&
                    analyticsData.details &&
                    analyticsData.details.total &&
                    !!parseInt(analyticsData.details.total.recipients) && (
                      <div style={{ padding: '2px' }}>
                        <div color="#6351ed" fontSize="16px">
                          {calPerc(
                            analyticsData.details.total.unique_opens,
                            analyticsData.details.total.recipients
                          )}
                          % open
                        </div>
                      </div>
                    )}

                  {!isAnalyticsDataFetching &&
                    analyticsData.details &&
                    analyticsData.details.total &&
                    !!parseInt(analyticsData.details.total.recipients) && (
                      <div style={{ padding: '2px', marginRight: '4px' }}>
                        <div color="#6351ed" fontSize="16px">
                          {calPerc(
                            analyticsData.details.total.unique_clicks,
                            analyticsData.details.total.unique_opens
                          )}
                          % click-through
                        </div>
                      </div>
                    )}
                </>
              </div>
            </div>

            <div
              style={{
                flex: '1',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                color: '#6351ed',
              }}
            >
              <div>
                {/* <ReachIcon /> */}
                <ResponsiveImg
                  src="/assets/images/recipients.svg"
                  alt="socialMedia"
                />
                <div style={{ padding: '2px' }}>
                  <TabLabel
                    color="#6351ed"
                    fontSize="16px"
                  // style={{
                  //   color: !get(analyticsData, 'overview.ln')
                  //     && !get(analyticsData, 'overview.fb')
                  //     && !get(analyticsData, 'overview.tw')
                  //     && !get(analyticsData, 'details.total.ln')
                  //     && !get(analyticsData, 'details.total.fb')
                  //     && !get(analyticsData, 'details.total.tw')
                  //      ? 'lightgray' : '#6351ed'
                  // }}
                  >
                    Social Media Impact
                  </TabLabel>
                  <>
                    <Analytics>
                      <div class="flex-row-container">
                        {!isAnalyticsDataFetching &&
                          analyticsData.details &&
                          analyticsData.details.total &&
                          analyticsData.details.total.ln >= 0 && (
                            <div color="#6351ed" fontSize="16px">
                              <div class="flex-row-item">
                                <FontAwesomeIcon
                                  icon={faLinkedin}
                                  color="#848484"
                                  size="1x"
                                />
                                <span class="flex-row-item"></span>
                                {analyticsData.details.total.ln}
                              </div>
                            </div>
                          )}
                      </div>
                      <div class="flex-row-container">
                        {!isAnalyticsDataFetching &&
                          analyticsData.details &&
                          analyticsData.details.total &&
                          analyticsData.details.total.fb >= 0 && (
                            <div color="#6351ed" fontSize="16px">
                              <div class="flex-row-item">
                                <FontAwesomeIcon
                                  icon={faFacebook}
                                  color="#848484"
                                  size="1x"
                                />
                                <span class="flex-row-item"></span>
                                {analyticsData.details.total.fb}
                              </div>
                            </div>
                          )}
                      </div>
                      <div class="flex-row-container">
                        {!isAnalyticsDataFetching &&
                          analyticsData.details &&
                          analyticsData.details.total &&
                          analyticsData.details.total.tw >= 0 && (
                            <div color="#6351ed" fontSize="16px">
                              <div class="flex-row-item">
                                <FontAwesomeIcon
                                  icon={faTwitter}
                                  color="#848484"
                                  size="1x"
                                />
                                <span class="flex-row-item"></span>
                                {analyticsData.details.total.tw}
                              </div>
                            </div>
                          )}
                      </div>
                    </Analytics>
                  </>
                </div>
              </div>
            </div>
            {/*<ShareBox>
              <ResponsiveImg
                height="19%"
                src="/assets/images/recipients.svg"
                alt="recipient logo"
              />
              <Box fontSize="14px" style={{ fontWeight: '600' }}>
                Recipients
              </Box>
              <Box fontSize="26px" style={{ fontWeight: '600' }}>
                {!isAnalyticsDataFetching &&
                  analyticsData.details &&
                  analyticsData.details.total &&
                  (analyticsData.details.total.recipients || '0')}
              </Box>
            </ShareBox>
            <ShareBox>
              <FontAwesomeIcon color="#7dc5d0" icon={faEnvelopeOpen} />
              <Box fontSize="14px" style={{ fontWeight: '600' }}>
                Unique Opens
              </Box>
              <Box fontSize="26px" style={{ fontWeight: '600' }}>
                {!isAnalyticsDataFetching &&
                  analyticsData.details &&
                  analyticsData.details.total &&
                  (analyticsData.details.total.unique_opens || '0')}
              </Box>
              <Box fontSize="14px">
                {!isAnalyticsDataFetching &&
                  analyticsData.details &&
                  analyticsData.details.total &&
                  calPerc(
                    +analyticsData.details.total.unique_opens,
                    +analyticsData.details.total.recipients
                  )}
                %
              </Box>
            </ShareBox>
            <ShareBox>
              <FontAwesomeIcon color="#c85cfc" icon={faMousePointer} />
              <Box fontSize="14px" style={{ fontWeight: '600' }}>
                Unique Clicks
              </Box>
              <Box fontSize="26px" style={{ fontWeight: '600' }}>
                {!isAnalyticsDataFetching &&
                  analyticsData.details &&
                  analyticsData.details.total &&
                  (analyticsData.details.total.unique_clicks || '0')}
              </Box>
              <Box fontSize="14px">
                {!isAnalyticsDataFetching &&
                  analyticsData.details &&
                  analyticsData.details.total &&
                  calPerc(
                    +analyticsData.details.total.unique_clicks,
                    +analyticsData.details.total.unique_opens
                  )}
                %
              </Box>
            </ShareBox>*/}
          </Flex>
          <Flex width="72%" mr="25px" flexDirection="column">
            <Tabs
              selectedIndex={tabIndex}
              onSelect={onTabSelect}
              // style={{ marginTop: '18px' }}
            >
              <StyledTabListLeft marginLeft='0' height="55px" screenWidth={window.innerWidth}>
                <DashboardAdminSubTab
                  disabled={isAnalyticsDataFetching}
                  style={styles.adminSubTab}
                  color="#49484a"
                  padding="5px 23px 3px"
                  type="analytics-tab"
                  className={`${tabIndex === 0 ? 'active' : 'inactive'}`}
                >
                  Overview
                </DashboardAdminSubTab>
                <DashboardAdminSubTab
                  disabled={isAnalyticsDataFetching}
                  style={styles.adminSubTab}
                  color="#49484a"
                  padding="5px 23px 3px"
                  type="analytics-tab"
                  className={`${tabIndex === 1 ? 'active' : 'inactive'}`}
                >
                  Contacts
                </DashboardAdminSubTab>
                <DashboardAdminSubTab
                  disabled={isAnalyticsDataFetching}
                  style={styles.adminSubTab}
                  color="#49484a"
                  padding="5px 23px 3px"
                  type="analytics-tab"
                  className={`${tabIndex === 2 ? 'active' : 'inactive'}`}
                >
                  Content
                </DashboardAdminSubTab>
                <DashboardAdminSubTab
                  disabled={isAnalyticsDataFetching}
                  style={styles.adminSubTab}
                  color="#49484a"
                  padding="5px 23px 3px"
                  type="analytics-tab"
                  className={`${tabIndex === 3 ? 'active' : 'inactive'}`}
                >
                  Webinars
                </DashboardAdminSubTab>
              </StyledTabListLeft>
            </Tabs>
            <Flex
              style={{
                width: '746px',
                height: '442px',
                // margin: '16px 0.2px 20px 20px',
                padding: '31px 20.7px 39px 21px',
                border: 'solid 1px #ececec',
                backgroundColor: '#ffffff',
              }}
            >
              {/* <FilterContacts
                isAdmin={isAdmin}
                top={isAdmin ? '17.2%' : '11.2%'}
                left="95%"
                handleClick={filterByContactList}
                contactGroup={contactGroup}
                selectedContacts={selectedContacts}
              /> */}
              <ShareInsightTable
                selectedItem={selectedItem}
                analyticsData={analyticsData}
                isAnalyticsDataFetching={isAnalyticsDataFetching}
                selectedRepType={selectedRepType}
                analyticsObject={analyticsObject}
                analyticsRequest={analyticsRequest}
                page={page}
                setPage={setPage}
                filterByContactList={filterByContactList}
                contactGroup={contactGroup}
                selectedContacts={selectedContacts}
                isAdmin={isAdmin}
              />
            </Flex>
          </Flex>
        </Flex>
      )}
      {!showShareInsight && !showLeadInsight && showWebinarInsight && (
        <Flex
          justifyContent="space-between"
          width={1}
          style={{
            height: '570px',
            borderRight: '1px solid rgb(229, 229, 229)',
            borderLeft: '1px solid rgb(229, 229, 229)',
            borderBottom: '1px solid rgb(229, 229, 229)',
          }}
        >
          <AnalyticsWebinarVerticalTabList>
            <div>Webinar</div>
            {!isAnalyticsDataFetching && selectedItem && (
              <div>
                <div style={{ padding: '2px', marginRight: '4px' }}>
                  <div color="#6351ed" fontSize="16px">
                    <div>{analyticsData?.details?.total?.recipients} invited</div>
                  </div>
                </div>
                <div style={{ padding: '2px', marginRight: '4px' }}>
                  <div color="#6351ed" fontSize="16px">
                    <div>{selectedItem?.clicks} clicks</div>
                  </div>
                </div>
                <div style={{ padding: '2px', marginRight: '4px' }}>
                  <div color="#6351ed" fontSize="16px">
                    <div>{selectedItem?.webinardetails?.webinarRegisteredCount} registered</div>
                  </div>
                </div>
                <div style={{ padding: '2px', marginRight: '4px' }}>
                  <div color="#6351ed" fontSize="16px">
                    <div>{selectedItem?.webinardetails?.webinarAttendeesCount} attended</div>
                  </div>
                </div>
              </div>
            )}
          </AnalyticsWebinarVerticalTabList>
          <Flex width="72%" mr="25px" flexDirection="column">
            <Tabs
              selectedIndex={tabIndex}
              onSelect={onTabSelect}
              // style={{ marginTop: '18px' }}
            >
              <StyledTabListLeft marginLeft='0' height="55px" screenWidth={window.innerWidth}>
                <DashboardAdminSubTab
                  disabled={isAnalyticsDataFetching}
                  style={styles.adminSubTab}
                  color="#49484a"
                  padding="5px 23px 3px"
                  type="analytics-tab"
                  className={`${tabIndex === 0 ? 'active' : 'inactive'}`}
                >
                  Overview
                </DashboardAdminSubTab>
                <DashboardAdminSubTab
                  disabled={isAnalyticsDataFetching}
                  style={styles.adminSubTab}
                  color="#49484a"
                  padding="5px 23px 3px"
                  type="analytics-tab"
                  className={`${tabIndex === 1 ? 'active' : 'inactive'}`}
                >
                  Contacts
                </DashboardAdminSubTab>
                <DashboardAdminSubTab
                  disabled={isAnalyticsDataFetching}
                  style={styles.adminSubTab}
                  color="#49484a"
                  padding="5px 23px 3px"
                  type="analytics-tab"
                  className={`${tabIndex === 2 ? 'active' : 'inactive'}`}
                >
                  Content
                </DashboardAdminSubTab>
                <DashboardAdminSubTab
                  disabled={isAnalyticsDataFetching}
                  style={styles.adminSubTab}
                  color="#49484a"
                  padding="5px 23px 3px"
                  type="analytics-tab"
                  className={`${tabIndex === 3 ? 'active' : 'inactive'}`}
                >
                  Webinars
                </DashboardAdminSubTab>
              </StyledTabListLeft>
            </Tabs>
            <Flex
              style={{
                width: '746px',
                height: '442px',
                // margin: '16px 0.2px 20px 20px',
                padding: '31px 20.7px 39px 21px',
                border: 'solid 1px #ececec',
                backgroundColor: '#ffffff',
              }}
            >
              {/* {newsletterTabIndex === 0 && (
                <FilterContacts
                  isAdmin={isAdmin}
                  top={isAdmin ? '17.2%' : '11.2%'}
                  left="95%"
                  handleClick={filterByContactList}
                  contactGroup={contactGroup}
                  selectedContacts={selectedContacts}
                />
              )} */}

              <WebinarInsights
                selectedItem={selectedItem}
                analyticsData={analyticsData}
                isAnalyticsDataFetching={isAnalyticsDataFetching}
                selectedRepType={selectedRepType}
                analyticsObject={analyticsObject}
                analyticsRequest={analyticsRequest}
                page={page}
                setPage={setPage}
                filterByContactList={filterByContactList}
                contactGroup={contactGroup}
                selectedContacts={selectedContacts}
                isAdmin={isAdmin}
                onTabSelect={handleNewsletterTabChange}
                tabIndex={newsletterTabIndex}
                setHasArticleCount={setHasArticleCount}
              />
            </Flex>
          </Flex>
        </Flex>
      )}
      {!showLeadInsight && !showShareInsight && !showWebinarInsight && (
        <VerticalTabs
          selectedIndex={verticalTabIndex}
          // onSelect={onVerticalTabSelect}
          screenWidth={window.innerWidth}
        >
          {tabIndex === 3 && (
            <AnalyticsWebinarVerticalTabList>
              <div>Webinar</div>
              {/* {!isAnalyticsDataFetching &&
                    analyticsData.details &&
                    analyticsData.details.total &&
                    !!parseInt(analyticsData.details.total.clicks) && (
                      <div style={{ padding: '2px', marginRight: '4px' }}>
                        <div color="#6351ed" fontSize="16px">
                          {calPerc(
                            analyticsData.details.total.clicks,
                            (analyticsData.details.total &&
                              analyticsData.details.total &&
                                analyticsData.details.total.opens
                          ))}
                          % Click Through
                        </div>
                      </div>
                    )} */}
              {
                !isAnalyticsDataFetching && analyticsData && analyticsData.details &&
                  analyticsData.details.total && analyticsData.details.total.clicks &&
                  analyticsData.details.total.opens &&

                  !isNaN(analyticsData.details.total.clicks) ?
                  <div style={{ padding: '2px', marginRight: '4px' }}>
                    <div color="#6351ed" fontSize="16px">
                      {calPerc(
                        analyticsData.details.total.clicks,
                        analyticsData.details.total.opens)}
                      % Click Through
                    </div>
                  </div>
                  : null
              }
              {
                !isAnalyticsDataFetching && analyticsData ?
                  <div style={{ padding: '2px', marginRight: '4px' }}>
                    <div color="#6351ed" fontSize="16px">
                      <div>{analyticsData.webinarRegisteredCountPer}% Registered</div>
                    </div>
                  </div> : null
              }
              {
                !isAnalyticsDataFetching && analyticsData ?
                  <div style={{ padding: '2px', marginRight: '4px' }}>
                    <div color="#6351ed" fontSize="16px">
                      <div>{analyticsData.webinarAttendeesCountPer}% Attended</div>
                    </div>
                  </div> : null
              }
            </AnalyticsWebinarVerticalTabList>
          )}
          {tabIndex !== 3 && (
            <AnalyticsVerticalTabList screenWidth={window.innerWidth}>
              {console.log('showShareInsight', showShareInsight)}
              <div
                className={
                  tabIndex === 2 && activeShareType === 'email_share'
                    ? 'selected'
                    : tabIndex === 2
                      ? 'transparent'
                      : ''
                }
                style={{
                  flex: '1',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <div>
                  {/* <ReachIcon /> */}
                  {tabIndex === 2 ? (
                    <Button
                      type="button"
                      styles={{
                        backgroundColor: 'transparent',
                        color: '#6351ed',
                        border: '0px',
                        fontSize: '16px',
                        marginTop: '-9px',
                      }}
                      onClick={() => onSocialMediaEmailClick('email_share')}
                    >
                      <FontAwesomeIcon icon={faEnvelope} />
                      <div style={{ padding: '2px' }}>
                        {console.log('tabIndex', tabIndex)}
                        <TabLabel color="#6351ed" fontSize="16px">
                          Email
                        </TabLabel>
                      </div>
                    </Button>
                  ) : (
                    <>
                      <FontAwesomeIcon icon={faEnvelope} />
                      <div style={{ padding: '2px' }}>
                        {console.log('tabIndex', tabIndex)}
                        <TabLabel color="#6351ed" fontSize="16px">
                          Email
                        </TabLabel>
                      </div>
                    </>
                  )}
                  {tabIndex === 0 && (
                    <>
                      {!isAnalyticsDataFetching &&
                        analyticsData &&
                        analyticsData.overview &&
                        !!parseInt(analyticsData.overview.sent) && (
                          <div style={{ padding: '2px' }}>
                            <div color="#6351ed" fontSize="16px">
                              {calPerc(
                                analyticsData.overview.opens,
                                analyticsData.overview.sent
                              )}
                              % open
                            </div>
                          </div>
                        )}

                      {!isAnalyticsDataFetching &&
                        analyticsData &&
                        analyticsData.overview &&
                        !!parseInt(analyticsData.overview.sent) && (
                          <div style={{ padding: '2px', marginRight: '4px' }}>
                            <div color="#6351ed" fontSize="16px">
                              {calPerc(
                                analyticsData.overview.clicks,
                                (analyticsData.overview &&
                                  analyticsData.overview
                                    .unique_opens_in_non_empty_links_newsletters) ||
                                analyticsData.overview.opens
                              )}
                              % click-through
                            </div>
                          </div>
                        )}
                    </>
                  )}
                  {tabIndex === 1 && (
                    <>
                      {!isAnalyticsDataFetching &&
                        analyticsData.details &&
                        analyticsData.details.total &&
                        !!parseInt(analyticsData.details.total.sent) && (
                          <div style={{ padding: '2px' }}>
                            <div color="#6351ed" fontSize="16px">
                              {calPerc(
                                analyticsData.details.total.opens,
                                analyticsData.details.total.sent
                              )}
                              % open
                            </div>
                          </div>
                        )}

                      {!isAnalyticsDataFetching &&
                        analyticsData.details &&
                        analyticsData.details.total &&
                        !!parseInt(analyticsData.details.total.sent) && (
                          <div style={{ padding: '2px', marginRight: '4px' }}>
                            <div color="#6351ed" fontSize="16px">
                              {calPerc(
                                analyticsData.details.total.clicks,
                                (analyticsData.details.total &&
                                  analyticsData.details.total
                                    .unique_opens_in_non_empty_links_newsletters) ||
                                analyticsData.details.total.opens
                              )}
                              % click-through
                            </div>
                          </div>
                        )}
                    </>
                  )}
                  {tabIndex === 2 && (
                    <>
                      <Button
                        type="button"
                        styles={{
                          backgroundColor: 'transparent',
                          color: '#6351ed',
                          border: '0px',
                          fontSize: '16px',
                          marginTop: '-9px',
                        }}
                        onClick={() => onSocialMediaEmailClick('email_share')}
                      >
                        {!isAnalyticsDataFetching &&
                          analyticsData.details &&
                          analyticsData.details.total &&
                          !!parseInt(analyticsData.details.total.recipients) && (
                            <div style={{ padding: '2px' }}>
                              <div color="#6351ed" fontSize="16px">
                                {calPerc(
                                  analyticsData.details.total.opens,
                                  analyticsData.details.total.recipients
                                )}
                                % open
                              </div>
                            </div>
                          )}

                        {!isAnalyticsDataFetching &&
                          analyticsData.details &&
                          analyticsData.details.total &&
                          !!parseInt(analyticsData.details.total.recipients) && (
                            <div style={{ padding: '2px', marginRight: '4px' }}>
                              <div color="#6351ed" fontSize="16px">
                                {calPerc(
                                  analyticsData.details.total.clicks,
                                  analyticsData.details.total.opens
                                )}
                                % click-through
                              </div>
                            </div>
                          )}
                      </Button>
                    </>
                  )}
                  {tabIndex === 3 && (
                    <>
                      {!isAnalyticsDataFetching &&
                        analyticsData.details &&
                        analyticsData.details.total &&
                        !!parseInt(analyticsData.details.total.recipients) && (
                          <div style={{ padding: '2px' }}>
                            <div color="#6351ed" fontSize="16px">
                              {calPerc(
                                analyticsData.details.total.opens,
                                analyticsData.details.total.recipients
                              )}
                              % open
                            </div>
                          </div>
                        )}

                      {!isAnalyticsDataFetching &&
                        analyticsData.details &&
                        analyticsData.details.total &&
                        !!parseInt(analyticsData.details.total.recipients) && (
                          <div style={{ padding: '2px', marginRight: '4px' }}>
                            <div color="#6351ed" fontSize="16px">
                              {calPerc(
                                analyticsData.details.total.clicks,
                                analyticsData.details.total.nl_opens_with_articles
                              )}
                              % click-through
                            </div>
                          </div>
                        )}
                    </>
                  )}
                </div>
              </div>

              <div
                id={tabIndex}
                className={
                  tabIndex === 2 && activeShareType === 'social_share'
                    ? 'selected'
                    : tabIndex === 2
                      ? 'transparent'
                      : ''
                }
                style={{
                  flex: '1',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  backgroundColor:
                    tabIndex === 1 || tabIndex === 3 ? '#ffffff' : 'unset',
                }}
              >
                {/* <div  className={tabIndex === 2 && socialMediaColor ? 'selected':'transparent'}> */}
                <div>
                  {/* <ReachIcon /> */}
                  {tabIndex === 2 ? (
                    <Button
                      type="button"
                      styles={{
                        backgroundColor: 'transparent',
                        color: '#6351ed',
                        border: '0px',
                        fontSize: '16px',
                        width: '100%',
                        marginTop: '-6px',
                      }}
                      onClick={() => onSocialMediaEmailClick('social_share')}
                    >
                      <ResponsiveImg
                        src="/assets/images/recipients.svg"
                        alt="socialMedia"
                      />
                      <TabLabel
                        color="#6351ed"
                        fontSize="16px"
                        style={{
                          color:
                            !get(analyticsData, 'overview.ln') &&
                              !get(analyticsData, 'overview.fb') &&
                              !get(analyticsData, 'overview.tw') &&
                              !get(analyticsData, 'details.total.ln') &&
                              !get(analyticsData, 'details.total.fb') &&
                              !get(analyticsData, 'details.total.tw')
                              ? 'lightgray'
                              : '#6351ed',
                        }}
                      >
                        Social Media Impact
                      </TabLabel>
                    </Button>
                  ) : (
                    <>
                      <ResponsiveImg
                        src="/assets/images/recipients.svg"
                        alt="socialMedia"
                      />
                      <TabLabel
                        color="#6351ed"
                        fontSize="16px"
                        style={{
                          color:
                            !get(analyticsData, 'overview.ln') &&
                              !get(analyticsData, 'overview.fb') &&
                              !get(analyticsData, 'overview.tw') &&
                              !get(analyticsData, 'details.total.ln') &&
                              !get(analyticsData, 'details.total.fb') &&
                              !get(analyticsData, 'details.total.tw')
                              ? 'lightgray'
                              : '#6351ed',
                        }}
                      >
                        Social Media Impact
                      </TabLabel>
                    </>
                  )}
                  <div style={{ padding: '2px' }}>
                    {tabIndex === 0 && (
                      <>
                        <Analytics>
                          <div class="flex-row-container">
                            {!isAnalyticsDataFetching &&
                              analyticsData &&
                              analyticsData.overview &&
                              analyticsData.overview.ln >= 0 && (
                                <div color="#6351ed" fontSize="16px">
                                  <div class="flex-row-item">
                                    <FontAwesomeIcon
                                      icon={faLinkedin}
                                      color="#848484"
                                      size="1x"
                                    />
                                    <span class="flex-row-item"></span>
                                    {analyticsData.overview.ln}
                                  </div>
                                </div>
                              )}
                          </div>

                          <div class="flex-row-container">
                            {!isAnalyticsDataFetching &&
                              analyticsData &&
                              analyticsData.overview &&
                              analyticsData.overview.fb >= 0 && (
                                <div color="#6351ed" fontSize="16px">
                                  <div class="flex-row-item">
                                    <FontAwesomeIcon
                                      icon={faFacebook}
                                      color="#848484"
                                      size="1x"
                                    />
                                    <span class="flex-row-item"></span>
                                    {analyticsData.overview.fb}
                                  </div>
                                </div>
                              )}
                          </div>

                          <div class="flex-row-container">
                            {!isAnalyticsDataFetching &&
                              analyticsData &&
                              analyticsData.overview &&
                              analyticsData.overview.tw >= 0 && (
                                <div color="#6351ed" fontSize="16px">
                                  <div class="flex-row-item">
                                    <FontAwesomeIcon
                                      icon={faTwitter}
                                      color="#848484"
                                      size="1x"
                                    />
                                    <span class="flex-row-item"></span>
                                    {analyticsData.overview.tw}
                                  </div>
                                </div>
                              )}
                          </div>
                        </Analytics>
                      </>
                    )}
                    {tabIndex === 1 && (
                      <>
                        <Analytics>
                          <div class="flex-row-container">
                            {!isAnalyticsDataFetching &&
                              analyticsData.details &&
                              analyticsData.details.total &&
                              analyticsData.details.total.ln >= 0 && (
                                <div color="#6351ed" fontSize="16px">
                                  <div class="flex-row-item">
                                    <FontAwesomeIcon
                                      icon={faLinkedin}
                                      color="#848484"
                                      size="1x"
                                    />
                                    <span class="flex-row-item"></span>
                                    {analyticsData.details.total.ln}
                                  </div>
                                </div>
                              )}
                          </div>

                          <div class="flex-row-container">
                            {!isAnalyticsDataFetching &&
                              analyticsData.details &&
                              analyticsData.details.total &&
                              analyticsData.details.total.fb >= 0 && (
                                <div color="#6351ed" fontSize="16px">
                                  <div class="flex-row-item">
                                    <FontAwesomeIcon
                                      icon={faFacebook}
                                      color="#848484"
                                      size="1x"
                                    />
                                    <span class="flex-row-item"></span>
                                    {analyticsData.details.total.fb}
                                  </div>
                                </div>
                              )}
                          </div>

                          <div class="flex-row-container">
                            {!isAnalyticsDataFetching &&
                              analyticsData.details &&
                              analyticsData.details.total &&
                              analyticsData.details.total.tw >= 0 && (
                                <div color="#6351ed" fontSize="16px">
                                  <div class="flex-row-item">
                                    <FontAwesomeIcon
                                      icon={faTwitter}
                                      color="#848484"
                                      size="1x"
                                    />
                                    <span class="flex-row-item"></span>
                                    {analyticsData.details.total.tw}
                                  </div>
                                </div>
                              )}
                          </div>
                        </Analytics>
                      </>
                    )}
                    {tabIndex === 2 && (
                      <>
                        <Button
                          type="button"
                          styles={{
                            backgroundColor: 'transparent',
                            color: '#6351ed',
                            border: '0px',
                            fontSize: '16px',
                            width: '100%',
                            marginTop: '-6px',
                          }}
                          onClick={() => onSocialMediaEmailClick('social_share')}
                        >
                          <Analytics>
                            <div class="flex-row-container">
                              {!isAnalyticsDataFetching &&
                                analyticsData.details &&
                                analyticsData.details.total &&
                                analyticsData.details.total.ln >= 0 && (
                                  <div color="#6351ed" fontSize="16px">
                                    <div class="flex-row-item">
                                      <FontAwesomeIcon
                                        icon={faLinkedin}
                                        color="#848484"
                                        size="1x"
                                      />
                                      <span class="flex-row-item"></span>
                                      {analyticsData.details.total.ln}
                                    </div>
                                  </div>
                                )}
                            </div>

                            <div class="flex-row-container">
                              {!isAnalyticsDataFetching &&
                                analyticsData.details &&
                                analyticsData.details.total &&
                                analyticsData.details.total.fb >= 0 && (
                                  <div color="#6351ed" fontSize="16px">
                                    <div class="flex-row-item">
                                      <FontAwesomeIcon
                                        icon={faFacebook}
                                        color="#848484"
                                        size="1x"
                                      />
                                      <span class="flex-row-item"></span>
                                      {analyticsData.details.total.fb}
                                    </div>
                                  </div>
                                )}
                            </div>

                            <div class="flex-row-container">
                              {!isAnalyticsDataFetching &&
                                analyticsData.details &&
                                analyticsData.details.total &&
                                analyticsData.details.total.tw >= 0 && (
                                  <div color="#6351ed" fontSize="16px">
                                    <div class="flex-row-item">
                                      <FontAwesomeIcon
                                        icon={faTwitter}
                                        color="#848484"
                                        size="1x"
                                      />
                                      <span class="flex-row-item"></span>
                                      {analyticsData.details.total.tw}
                                    </div>
                                  </div>
                                )}
                            </div>
                          </Analytics>
                        </Button>
                      </>
                    )}
                    {tabIndex === 3 && (
                      <>
                        <Analytics>
                          <div class="flex-row-container">
                            {!isAnalyticsDataFetching &&
                              analyticsData.details &&
                              analyticsData.details.total &&
                              analyticsData.details.total.ln >= 0 && (
                                <div
                                  style={{ padding: '2px', marginRight: '4px' }}
                                >
                                  <div color="#6351ed" fontSize="16px">
                                    <FontAwesomeIcon
                                      icon={faLinkedin}
                                      color="#C95CFC"
                                      size="1x"
                                    />
                                    <span class="flex-row-item"></span>
                                    {analyticsData.details.total.ln}
                                  </div>
                                </div>
                              )}
                          </div>

                          <div class="flex-row-container">
                            {!isAnalyticsDataFetching &&
                              analyticsData.details &&
                              analyticsData.details.total &&
                              analyticsData.details.total.fb >= 0 && (
                                <div style={{ padding: '2px' }}>
                                  <div color="#6351ed" fontSize="16px">
                                    <FontAwesomeIcon
                                      icon={faFacebook}
                                      color="#848484"
                                      size="1x"
                                    />
                                    <span class="flex-row-item"></span>
                                    {analyticsData.details.total.fb}
                                  </div>
                                </div>
                              )}
                          </div>

                          <div class="flex-row-container">
                            {!isAnalyticsDataFetching &&
                              analyticsData.details &&
                              analyticsData.details.total &&
                              analyticsData.details.total.tw >= 0 && (
                                <div
                                  style={{ padding: '2px', marginRight: '4px' }}
                                >
                                  <div color="#6351ed" fontSize="16px">
                                    <FontAwesomeIcon
                                      icon={faTwitter}
                                      color="#848484"
                                      size="1x"
                                    />
                                    <span class="flex-row-item"></span>
                                    {analyticsData.details.total.tw}
                                  </div>
                                </div>
                              )}
                          </div>
                        </Analytics>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </AnalyticsVerticalTabList>
          )}

          <AnalyticsVerticalTabPanel
            screenWidth={window.innerWidth}
            style={{ paddingBottom: '40px', overflowX: 'hidden' }}
            paddingTop='0'
          >
            <Tabs selectedIndex={tabIndex} onSelect={onTabSelect}>
              <StyledTabListLeft marginLeft='0' screenWidth={window.innerWidth}>
                <DashboardAdminSubTab
                  disabled={isAnalyticsDataFetching}
                  style={styles.adminSubTab}
                  color="#49484a"
                  padding="5px 23px 3px"
                  type="analytics-tab"
                  className={`${tabIndex === 0 ? 'active' : 'inactive'}`}
                >
                  Overview
                </DashboardAdminSubTab>
                <DashboardAdminSubTab
                  disabled={isAnalyticsDataFetching}
                  style={styles.adminSubTab}
                  color="#49484a"
                  padding="5px 23px 3px"
                  type="analytics-tab"
                  className={`${tabIndex === 1 ? 'active' : 'inactive'}`}
                >
                  Contacts
                </DashboardAdminSubTab>
                <DashboardAdminSubTab
                  disabled={isAnalyticsDataFetching}
                  style={styles.adminSubTab}
                  color="#49484a"
                  padding="5px 23px 3px"
                  type="analytics-tab"
                  className={`${tabIndex === 2 ? 'active' : 'inactive'}`}
                >
                  Content
                </DashboardAdminSubTab>
                <DashboardAdminSubTab
                  disabled={isAnalyticsDataFetching}
                  style={styles.adminSubTab}
                  color="#49484a"
                  padding="5px 23px 3px"
                  type="analytics-tab"
                  className={`${tabIndex === 3 ? 'active' : 'inactive'}`}
                >
                  Webinars
                </DashboardAdminSubTab>
              </StyledTabListLeft>
              <CommonTabPanel height="577px">

                <LoaderWrapper isLoading={isAnalyticsDataFetching}>
                  <OverviewTab
                    analyticsData={analyticsData}
                    selectedRepType={selectedRepType}
                    analyticsObject={analyticsObject}
                    isAnalyticsDataFetching={isAnalyticsDataFetching}
                    handleRowClick={handleRowClick}
                    isAdmin={isAdmin}
                    selectedContacts={selectedContacts}
                  />
                </LoaderWrapper>
              </CommonTabPanel>
              <EngagementTabPanel height="575px">

                {/* <FilterContacts
                  isAdmin={isAdmin}
                  top={isAdmin ? '20%' : '13.5%'}
                  handleClick={filterByContactList}
                  contactGroup={contactGroup}
                  selectedContacts={selectedContacts}
                  setSelectedContacts={setSelectedContacts}
                /> */}
                <LeadTab
                  tableData={analyticsData}
                  isAnalyticsDataFetching={isAnalyticsDataFetching}
                  handleRowClick={handleRowClick}
                  selectedRepType={selectedRepType}
                  analyticsObject={analyticsObject}
                  analyticsRequest={analyticsRequest}
                  page={page}
                  setPage={setPage}
                  selectedContacts={selectedContacts}
                  isAdmin={isAdmin}
                  setPaginationToken={setPaginationToken}
                />
              </EngagementTabPanel>
              <EngagementTabPanel height="615px">


                <EngagementShareTab
                  tableData={analyticsData}
                  isAnalyticsDataFetching={isAnalyticsDataFetching}
                  handleRowClick={handleRowClick}
                  selectedRepType={selectedRepType}
                  analyticsObject={analyticsObject}
                  page={page}
                  setPage={setPage}
                  analyticsRequest={analyticsRequest}
                  isAdmin={isAdmin}
                  setPaginationToken={setPaginationToken}
                  activeShareType={activeShareType}
                />
              </EngagementTabPanel>
              <EngagementTabPanel height="615px">

                {/* <FilterContacts
                  isAdmin={isAdmin}
                  top={isAdmin ? '19%' : '12.8%'}
                  handleClick={filterByContactList}
                  contactGroup={contactGroup}
                  selectedContacts={selectedContacts}
                /> */}
                <Suspense fallback={null}>
                  <EngagementWebinarTab
                    tableData={analyticsData}
                    isAnalyticsDataFetching={isAnalyticsDataFetching}
                    handleRowClick={handleRowClick}
                    selectedRepType={selectedRepType}
                    analyticsObject={analyticsObject}
                    page={page}
                    setPage={setPage}
                    analyticsRequest={analyticsRequest}
                    isAdmin={isAdmin}
                    selectedContacts={selectedContacts}
                    setPaginationToken={setPaginationToken}
                  />
                </Suspense>

              </EngagementTabPanel>
            </Tabs>
          </AnalyticsVerticalTabPanel>

          <AnalyticsVerticalTabPanel
            screenWidth={window.innerWidth}
            style={{ paddingBottom: '40px' }}
            paddingTop='0'
          >
            <Tabs onSelect={onSocialTabSelect}>
              <StyledTabListLeft marginLeft='0' screenWidth={window.innerWidth}>
                <DashboardAdminSubTab
                  style={styles.adminSubTab}
                  color="#49484a"
                  padding="5px 23px 3px"
                  type="analytics-tab"
                  className={`${tabSocialIndex === 0 ? 'active' : 'inactive'}`}
                >
                  Overview
                </DashboardAdminSubTab>
                <DashboardAdminSubTab
                  style={styles.adminSubTab}
                  color="#49484a"
                  padding="5px 23px 3px"
                  type="analytics-tab"
                  className={`${tabSocialIndex === 1 ? 'active' : 'inactive'}`}
                >
                  Contacts
                </DashboardAdminSubTab>
                <DashboardAdminSubTab
                  style={styles.adminSubTab}
                  color="#49484a"
                  padding="5px 23px 3px"
                  type="analytics-tab"
                  className={`${tabSocialIndex === 2 ? 'active' : 'inactive'}`}
                >
                  Content
                </DashboardAdminSubTab>
              </StyledTabListLeft>

              {tabSocialIndex === 0 && (
                <CommonTabPanel
                  height="657px"
                  style={{ display: 'flex', alignItems: 'center' }}
                >
                  <div style={{ margin: 'auto' }}>Coming Soon</div>
                </CommonTabPanel>
              )}

              {tabSocialIndex === 1 && (
                <CommonTabPanel
                  style={{ display: 'flex', alignItems: 'center' }}
                >
                  <div style={{ margin: 'auto' }}>Coming Soon</div>
                </CommonTabPanel>
              )}

              {tabSocialIndex === 2 && (
                <CommonTabPanel
                  style={{ display: 'flex', alignItems: 'center' }}
                >
                  <div style={{ margin: 'auto' }}>Coming Soon</div>
                </CommonTabPanel>
              )}
            </Tabs>
          </AnalyticsVerticalTabPanel>
        </VerticalTabs>
      )}
    </>
  );
};

export default EnagagementTab;
