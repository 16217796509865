/* eslint-disable react-hooks/exhaustive-deps */
import { Flex } from '@rebass/grid';
import { get, isEmpty } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { DripIconButton, LoaderWrapper, Modal, ModalBody, ModalCloseButton, ModalHeader } from '../../../components';
import { getNewsletterDetails } from '../../../services/newsletter';
import { themesData } from '../../../utils';

const SentNewsletterModal = ({
    open,
    setOpen,
    selectedId,
    setSelectedId,
    selectedTopic,
    setSelectedTopic,
    contactGroups,
    isPremiumAdvisor
}) => {

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);

    const fetchDetails = () => {
        setLoading(true);
        getNewsletterDetails({ id: selectedId }).then(res => {
            if(res.result.success) {
                setData(res.result.data);
                setLoading(false);
            } else {
                setLoading(false);
            }
        }).catch(err => {
            setLoading(false);
        })
    }

    useEffect(() => {
        fetchDetails();
    }, [])

    const handleModalClose = () => {
        setOpen(false);
        setSelectedId('');
        setSelectedTopic('');
    }

    const getDestinationName = (item) => {
        const contactLists = get(data, 'contactlists', []);
        const contactName = contactLists.filter(c => c.id === item.contactlist);
        if (!isEmpty(contactLists) && !isEmpty(contactName)) {
            return contactName[0].name;
        } else {
            return '-';
        }   
    }

    const getLeadReferralStatus = (item) => {
        if(item.lead_capture === 'false' && item.referral === 'false') {
            return 'N/A';
        } else if(item.lead_capture === 'true') {
            return 'Leads';
        } else if(item.referral === 'true') {
            return 'Referrals';
        } else {
            return 'N/A';
        }
    }
    return (
        <Modal size="medium65" isOpen={open} onRequestClose={handleModalClose}>
            <ModalHeader>
                <div style={{
                    fontSize: '18px',
                    color: '#030303',
                    fontWeight: 500,
                    padding: '0px 86px'
                }}>{selectedTopic}</div>
                <ModalCloseButton onClick={handleModalClose} />
            </ModalHeader>
            <ModalBody>
                <LoaderWrapper isLoading={loading}>
                    <Flex width="94%" justifyContent='center' style={{ margin: '0px 20px 0px 20px' }}>
                        <Flex width={!isPremiumAdvisor ? "15%" : "25%"} justifyContent='center' fontSize='12px' color='#6351ed'>Destination</Flex>
                        {!isPremiumAdvisor && (
                            <Flex width="20%" justifyContent='center' fontSize='12px' color='#6351ed'>Compliance Review</Flex>
                        )}
                        <Flex width="25%" justifyContent='center' fontSize='12px' color='#6351ed'>Sent Date/Time</Flex>
                        <Flex width="25%" justifyContent='center' fontSize='12px' color='#6351ed'>Lead / Referral Capture?</Flex>
                        <Flex width={!isPremiumAdvisor ? "15%" : "25%"} justifyContent='center' fontSize='12px' color='#6351ed'>Email Sent / Failed</Flex>
                    </Flex>
                    <Flex flexDirection='column' style={{ maxHeight: '300px', overflowX: 'hidden', overflowY: 'auto' }}>
                        {!isEmpty(data) && get(data, 'engagement_details', []).filter(e => e.status === 'completed').map((item, index) => (
                            <Flex width="100%" style={{ minHeight: "54px" }}>
                                <Flex width="95%" justifyContent='center' style={{ backgroundColor: '#fff', border: '1px solid #ececec', margin: '7px 12.3px 7px 20px', height: '40.4px', padding: '7px 12.3px 7px 0' }}>
                                    <Flex width={!isPremiumAdvisor ? "15%" : "25%"} justifyContent='center' fontSize='12px' color={themesData.charcoalGrey}>{getDestinationName(item)}</Flex>
                                    {!isPremiumAdvisor && (
                                        <Flex width="20%" justifyContent='center' fontSize='12px' color={themesData.charcoalGrey}>{item.compliance_status === 'approved' ? 'Yes' : 'N/A'}</Flex>
                                    )}
                                    <Flex width="25%" justifyContent='center' fontSize='12px' color={themesData.charcoalGrey}>{moment(new Date(item.publish_time)).format('Do MMMM YYYY, h:mm a')}</Flex>
                                    <Flex width="25%" justifyContent='center' fontSize='12px' color={themesData.charcoalGrey}>{getLeadReferralStatus(item)}</Flex>
                                    <Flex width={!isPremiumAdvisor ? "15%" : "25%"} justifyContent='center' fontSize='12px' color={themesData.charcoalGrey}>{item.email.success} / {item.email.failed}</Flex>
                                </Flex>
                            </Flex>
                        ))}
                    </Flex>
                </LoaderWrapper>
            </ModalBody>
        </Modal>
    )
}

export default SentNewsletterModal;
