import { isValid, format } from 'date-fns';

export const transformBookmarks = (response) => {
  return {
    articles: Array.isArray(response?.data)
      ? response?.data?.map((article) => {
          return {
            id: article?.id ?? '',
            name: article?.name ?? '',
            description: article?.description ?? '',
            tags: article?.tags,
            bookmark_id: article?.bookmark_id,
            article_bookmark_id: article?.article_bookmark_id,
            is_bookmarked: article?.is_bookmarked,
            created_date: isValid(new Date(article?.created_date))
              ? format(new Date(article?.created_date), 'MMM dd yyyy')
              : '',
            media_url: article?.media_url ?? {},
            content_url: article?.content_url ?? '',
            publisher_name: article?.publisher_name ?? '',
          };
        })
      : [],
    pagination: response?.pagination ?? {},
  };
};
