import styled from 'styled-components';

export const MioAutoCompleteWrapper = styled.div`
  position: relative;
`;

export const MioAutoCompleteDropdown = styled.div`
  position: absolute;
  max-height: 300px;
  width: 100%;
  top: 100%;
  text-align: left;
  margin: 2px 0 0;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  background-color: #fff;
`;

export const MioAutoCompleteInputWrapper = styled.div`
  padding: 4px 8px;
`;

export const MioAutoCompleteMenuItemsWrapper = styled.div`
  ul {
    padding: 0;
    margin: 0;
    max-height: 200px;
    overflow-y: auto;
    li {
      list-style-type: none;
      background-color: #d4d4d4;
      padding: 3px 20px;
      cursor: pointer;
      line-height: 1.42857143;
      &:hover {
        background-color: #6351ed;
        color: #fff;
      }
    }
  }
`;
