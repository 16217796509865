/* eslint-disable react-hooks/exhaustive-deps */
import { Flex, Box } from '@rebass/grid';
import React, { useState, useEffect } from 'react';
import {
  CreateNewWrapper,
  DraftWrapper,
  GetStartedTitle,
  GraphColorCircle,
  NewletterInput,
  OutboxText,
  GetStartedSubTitle,
  TooltipMode,
} from './GetStartedTab.styles';
import { Button, LoaderWrapper, ResponsiveImg } from '../../../components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { themesData } from '../../../utils';
import { isEmpty } from 'lodash';
import { createNewsletter } from '../../../services/newsletter';
import { Pie, PieChart } from 'recharts';

const GetStartedTab = ({
  setSelectedIndex,
  setShowTabs,
  setShowOutbox,
  newsletterSetState,
  userData,
  setOutBoxIndex,
  newsletterOutboxRequest,
  setPage,
  showDashboard,
}) => {
  const [newsletterName, setNewsLetterName] = useState('');
  const [loading, setLoading] = useState(false);
  const [graphData, setGraphData] = useState([]);

  useEffect(() => {
    if (
      userData &&
      userData.newsletter &&
      +userData.newsletter.draft +
        +userData.newsletter.scheduled +
        +userData.newsletter.sent >
        0
    ) {
      let newsletterCount = [
        {
          data: [
            {
              name: 'Draft',
              value: +userData.newsletter.draft,
              fill: '#6351ED',
            },
            {
              name: 'Scheduled',
              value: +userData.newsletter.scheduled,
              fill: '#c95cfc',
            },
            { name: 'Sent', value: +userData.newsletter.sent, fill: '#7dc4d0' },
          ],
        },
      ];
      setGraphData([...newsletterCount]);
    } else if (
      userData &&
      userData.newsletter &&
      +userData.newsletter.draft +
        +userData.newsletter.scheduled +
        +userData.newsletter.sent ===
        0
    ) {
      let newsletterCount = [
        {
          data: [{ name: 'Draft', value: 1, fill: '#ECECEC' }],
        },
      ];
      setGraphData([...newsletterCount]);
    }
  }, [userData]);

  const onCreateNewsletter = () => {
    setLoading(true);
    createNewsletter({ name: newsletterName })
      .then((response) => {
        if (response.result.success) {
          newsletterSetState({
            newsletterName: newsletterName,
            isEditMode: false,
            scheduledTime: '',
            newsletterId: response.result.data.newsletter_id,
            emailTemplateSelected: '',
            selectedContacts: [],
            newsletterArticles: [],
            isFromCompliance: false,
            customData: {
              toggle: true,
              title: newsletterName
            },
            tab: '',
            ccContacts: [],
            leadCapture: {},
          });
          setLoading(false);
          setShowTabs(true);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const goToOutbox = (index) => {
    setShowOutbox(true);
    setOutBoxIndex(index);
    setPage(0);
    if (index === 2) {
      newsletterOutboxRequest({
        params: {
          filter: 'sent',
        },
      });
    } else if (index === 1) {
      newsletterOutboxRequest({
        params: {
          filter: 'scheduled',
        },
      });
    } else {
      newsletterOutboxRequest({
        params: {
          filter: 'draft',
        },
      });
    }
  };

  const handleChange = (e) => {
    setNewsLetterName(e.target.value);
  };

  return (
    <Flex
      width="100%"
      flexDirection="column"
      justifyContent="space-between"
      padding="40px 70px"
      style={{ height: '490px' }}
    >
      {showDashboard ? (
        <>
          <Flex justifyContent="center" style={{ fontWeight: '600' }}>
            <div
              style={{
                borderBottom: `1px solid ${themesData.purpleishBlue}`,
                width: 'fit-content',
              }}
            >
              Newsletter Dashboard
            </div>
          </Flex>
          <Flex
            width="100%"
            justifyContent="space-between"
            style={{ margin: 'auto' }}
          >
            <Flex width="40%" style={{ position: 'relative' }}>
              {!isEmpty(graphData) && (
                <>
                  <div
                    style={{
                      position: 'absolute',
                      color: themesData.charcoalGrey,
                      top: '45%',
                      left: '34%',
                      fontWeight: '600',
                    }}
                  >
                    Total{' '}
                    {userData &&
                      userData.newsletter &&
                      +userData.newsletter.draft +
                        +userData.newsletter.scheduled +
                        +userData.newsletter.sent}
                  </div>
                  <PieChart width={250} height={250}>
                    {graphData.map((item, index) => (
                      <Pie
                        data={item.data}
                        dataKey="value"
                        nameKey="name"
                        cx="50%"
                        cy="50%"
                        innerRadius={70}
                        fill="#8274EF"
                      />
                    ))}
                  </PieChart>
                </>
              )}
            </Flex>
            <Flex width="60%" flexDirection="column" pt="80px">
              <Flex width="100%" justifyContent="space-between">
                <Flex
                  width="50%"
                  flexDirection="column"
                  color={themesData.charcoalGrey}
                  style={{ fontWeight: '600' }}
                >
                  <Flex alignItems="center">
                    <GraphColorCircle bgColor="#7dc4d0"></GraphColorCircle>
                    <div
                      style={{ cursor: 'pointer' }}
                      onClick={() => goToOutbox(2)}
                    >
                      Sent Newsletter
                    </div>
                  </Flex>
                  <Flex pl="20px" alignItems="center">
                    {userData &&
                      userData.newsletter &&
                      userData.newsletter.sent}
                    <FontAwesomeIcon
                      onClick={() => goToOutbox(2)}
                      style={{ marginLeft: '5px', cursor: 'pointer' }}
                      color={themesData.charcoalGrey}
                      icon={faArrowRight}
                    />
                  </Flex>
                </Flex>
                <Flex
                  width="50%"
                  flexDirection="column"
                  color={themesData.charcoalGrey}
                  style={{ fontWeight: '600' }}
                >
                  <Flex alignItems="center">
                    <GraphColorCircle bgColor="#c95cfc"></GraphColorCircle>
                    <div
                      style={{ cursor: 'pointer' }}
                      onClick={() => goToOutbox(1)}
                    >
                      Scheduled Newsletter
                    </div>
                  </Flex>
                  <Flex pl="20px" alignItems="center">
                    {userData &&
                      userData.newsletter &&
                      userData.newsletter.scheduled}
                    <FontAwesomeIcon
                      onClick={() => goToOutbox(1)}
                      style={{ marginLeft: '5px', cursor: 'pointer' }}
                      color={themesData.charcoalGrey}
                      icon={faArrowRight}
                    />
                  </Flex>
                </Flex>
              </Flex>
              <Flex
                width="50%"
                flexDirection="column"
                pt="30px"
                color={themesData.charcoalGrey}
                style={{ fontWeight: '600' }}
              >
                <Flex alignItems="center">
                  <GraphColorCircle bgColor="#6351ED"></GraphColorCircle>
                  <div
                    style={{ cursor: 'pointer' }}
                    onClick={() => goToOutbox(0)}
                  >
                    Draft Newsletter
                  </div>
                </Flex>
                <Flex pl="20px" alignItems="center">
                  {userData && userData.newsletter && userData.newsletter.draft}
                  <FontAwesomeIcon
                    onClick={() => goToOutbox(0)}
                    style={{ marginLeft: '5px', cursor: 'pointer' }}
                    color={themesData.charcoalGrey}
                    icon={faArrowRight}
                  />
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        </>
      ) : (
        <CreateNewWrapper
          width="100%"
          flexDirection="column"
          pt="30px"
          style={{ margin: 'auto' }}
        >
          <Flex width="100%" justifyContent="center">
            <img
              className="newsletter-image"
              src="/assets/images/Group 979.svg"
              alt="new"
            />
          </Flex>
          <Flex width="100%" justifyContent="center" pt="5px">
            <GetStartedTitle>Create a New Newsletter</GetStartedTitle>
          </Flex>
          <Flex
            width="100%"
            justifyContent="center"
            pt="5px"
            flexDirection="row"
          >
            <GetStartedSubTitle>Newsletter Name*</GetStartedSubTitle>
            <TooltipMode>
              <div class="tooltip">
                <ResponsiveImg
                  src="/assets/images/information-grey.svg"
                  // title="This name is how you will refer to your newsletter in Clout."
                  style={{
                    height: '52%',
                    paddingLeft: '2px',
                    paddingBottom: '1px',
                    cursor: 'pointer',
                  }}
                />
                <span class="tooltiptext tooltip-right">
                  This name is how you will refer to your newsletter in Clout.
                </span>
              </div>
            </TooltipMode>
          </Flex>

          <Flex width="100%" justifyContent="center" pt="5px">
            <NewletterInput
              placeholder="Enter a name for the newsletter"
              value={newsletterName}
              onChange={handleChange}
              width="40%"
            />
          </Flex>
          <Flex width="100%" justifyContent="center">
            <LoaderWrapper isLoading={loading}>
              <Button
                margin="10px 0px"
                onClick={onCreateNewsletter}
                disabled={isEmpty(newsletterName)}
                bgColor={isEmpty(newsletterName) ? '#AFAFAF' : ''}
              >
                <FontAwesomeIcon
                  color="#fff"
                  size="1x"
                  fontSize="12px"
                  icon={faArrowRight}
                />
              </Button>
            </LoaderWrapper>
          </Flex>
        </CreateNewWrapper>
      )}

      {/* <DraftWrapper width="50%" flexDirection="column" justifyContent="center">
        <Flex width="100%" justifyContent="center">
          <img className="newsletter-image" src="/assets/images/outbox.svg" alt="draft" />
        </Flex>
        <Flex width="100%" justifyContent="center" pt="5px">
          <GetStartedTitle>Outbox</GetStartedTitle>
        </Flex>
        <Flex
          width="100%"
          justifyContent="center"
          pt="5px"
          color={themesData.charcoalGrey}
          fontSize="12px"
          height="3.5em"
          alignItems="center"
        >
          <OutboxText>Sent, Scheduled, Drafts</OutboxText>
        </Flex>
        <Flex width="100%" justifyContent="center">
          <Button margin="10px 0px" onClick={goToOutbox}>
            <FontAwesomeIcon
              color="#fff"
              size="1x"
              fontSize="12px"
              icon={faArrowRight}
            />
          </Button>
        </Flex>
      </DraftWrapper> */}
      <Flex width="100%" justifyContent="flex-end" fontSize="12px" pb="10px">
        *Required
      </Flex>
    </Flex>
  );
};

export default GetStartedTab;
