import React, { useState } from 'react';
import { Flex } from '@rebass/grid';
import {
  AlphabetScrollerWrapper,
  PublisherCardWrapper,
  StyledCross,
  StyledImgDiv,
  StyledTitle,
  StyledTitleDiv,
} from './styles';
import { Chip } from '../../../componentsV2/Chip';
import { Tooltip, Zoom } from '@mui/material';
import { LoaderWrapper } from '../../../components';

const PublisherListView = ({
  publishers,
  isAdmin,
  postMyPublisherSubscribeChannelClick,
  handlePublisherSubscribeClick,
  premiumModal,
  loader,
  setLoader,
  myPublisherList,
}) => {
  console.log('list view publisher list---->', publishers);
  const [selectedChar, setSelectedChar] = useState('A');

  const getImages = (channel) => {
    if (
      channel.media_urls &&
      channel.media_urls[0] &&
      channel.media_urls[0].path &&
      channel.media_urls[0].name.resized &&
      channel.media_urls[0].ext
    )
      return `${channel.media_urls[0].path}/${channel.media_urls[0].name.resized}.${channel.media_urls[0].ext}`;
    else return '';
  };

  const getButtonsColumn = () => {
    let btns = [];
    for (let i = 65; i <= 90; i++)
      btns.push(
        <div
          style={{
            color:
              String.fromCharCode(i) === selectedChar ? '#101828' : '#98A2B3',
            padding: '0px 1px',
            cursor: 'pointer',
          }}
          onClick={() => {
            console.log('selected char--->', String.fromCharCode(i));
            setSelectedChar(String.fromCharCode(i));
          }}
        >
          <div
            style={{
              height: '22px',
              width: '22px',
              textAlign: 'center',
              margin: '2px auto -1px',
            }}
          >
            <strong>{String.fromCharCode(i)}</strong>
          </div>
        </div>
      );
    return btns;
  };

  const filteredPubslishers = publishers.filter(
    (item) => item.subscription_type === 'free' && item.is_hidden === 'false'
  );

  console.log('filteredPubslishers--->', filteredPubslishers);

  return (
    <Flex width="100%">
      <Flex width="100%" flexDirection="row" height="300px" maxHeight="300px">
        <Flex
          display="flex"
          width="100%"
          flexDirection="column"
          padding="10px"
          style={{ backgroundColor: '#F9FAFB' }}
        >
          <Flex>
            {getButtonsColumn().map((item, index) => (
              <Flex>{item}</Flex>
            ))}
          </Flex>
          {/* <Flex display="flex" width="100%" padding="5px 0px 0px 7px" style={{ color: '#101828' }}>
            {selectedChar}
          </Flex> */}
          <hr
            style={{
              border: '1px solid #e4e7ec',
              width: '92%',
              marginRight: '20%',
              marginBottom: '20px',
            }}
          />
          <PublisherCardWrapper
            style={{
              flexWrap: 'wrap',
              justifyContent: 'start',
              maxHeight: '300px',
            }}
          >
            {filteredPubslishers
              .filter((publisher) => publisher.name[0] === selectedChar)
              .map(
                (publisher, index) =>
                  publisher?.subscription?.status?.application_status ===
                  'approved' ? (
                    <Chip
                      width="23%"
                      height="80px"
                      borderColor={
                        filteredPubslishers.includes(publisher)
                          ? '#A795FF'
                          : '#E4E7EC'
                      }
                      boxShadow="0px 1px 2px 0px #1018281F"
                    >
                      <StyledImgDiv style={{ width: '35%' }}>
                        <img
                          alt=""
                          src={getImages(publisher)}
                          style={{ width: '100%' }}
                        />
                      </StyledImgDiv>
                      <StyledTitleDiv style={{ width: '50%' }}>
                        <StyledTitle
                          style={{
                            padding: '0px 0px 0px 10px',
                            fontSize: '14px',
                          }}
                        >
                          <LoaderWrapper isLoading={loader === publisher.name}>
                            {publisher?.name && publisher?.name?.length > 10 ? (
                              <Tooltip
                                arrow
                                placement="top"
                                title={publisher.name}
                                componentsProps={{
                                  tooltip: {
                                    sx: {
                                      fontFamily: 'Poppins',
                                    },
                                  },
                                }}
                              >
                                {`${publisher?.name.substring(0, 10)}...`}
                              </Tooltip>
                            ) : (
                              publisher?.name
                            )}
                          </LoaderWrapper>
                        </StyledTitle>
                      </StyledTitleDiv>
                      {premiumModal === false && !loader ? (
                        <StyledCross
                          style={{
                            marginRight: '7px',
                            width: '15%',
                            justifyContent: 'center',
                            cursor: 'pointer',
                          }}
                          // onClick={() => {
                          //   console.log('add clicked');
                          //   if (isAdmin) {
                          //     handlePublisherSubscribeClick(publisher.id, index);
                          //   } else {
                          //     postMyPublisherSubscribeChannelClick(
                          //       publisher.id,
                          //       index
                          //     );
                          //   }
                          //   setLoader(publisher.name);
                          // }}
                        >
                          <img
                            alt=""
                            src="/assets/images/color-tick-icon.svg"
                          />
                        </StyledCross>
                      ) : null}
                    </Chip>
                  ) : publisher?.subscription?.status?.application_status ===
                    'applied' ? null : (
                    <Chip width="23%" height="80px" boxShadow="0px 1px 2px 0px #1018281F">
                      <StyledImgDiv style={{ width: '35%' }}>
                        <img
                          alt=""
                          src={getImages(publisher)}
                          style={{ width: '100%' }}
                        />
                      </StyledImgDiv>
                      <StyledTitleDiv style={{ width: '50%' }}>
                        <StyledTitle
                          style={{
                            padding: '0px 0px 0px 10px',
                            fontSize: '14px',
                          }}
                        >
                          <LoaderWrapper isLoading={loader === publisher.name}>
                            {publisher?.name && publisher?.name?.length > 10 ? (
                              <Tooltip
                                arrow
                                placement="top"
                                title={publisher.name}
                                componentsProps={{
                                  tooltip: {
                                    sx: {
                                      fontFamily: 'Poppins',
                                    },
                                  },
                                }}
                              >
                                {`${publisher?.name.substring(0, 10)}...`}
                              </Tooltip>
                            ) : (
                              publisher?.name
                            )}
                          </LoaderWrapper>
                        </StyledTitle>
                      </StyledTitleDiv>
                      {premiumModal === false && !loader ? (
                        <StyledCross
                          style={{
                            marginRight: '7px',
                            width: '15%',
                            justifyContent: 'center',
                            cursor: 'pointer',
                          }}
                          onClick={() => {
                            console.log('add clicked');
                            if (isAdmin) {
                              handlePublisherSubscribeClick(
                                publisher.id,
                                index
                              );
                              setLoader(publisher.name);
                            } else {
                              postMyPublisherSubscribeChannelClick(
                                publisher.id,
                                index
                              );
                            }
                            setLoader(publisher.name);
                          }}
                        >
                          <img alt="" src="/assets/images/plus.svg" />
                        </StyledCross>
                      ) : null}
                    </Chip>
                  )
                // <Chip width="30%" height="80px">
                //   <StyledImgDiv style={{ width: '35%' }}>
                //     <img
                //       alt=""
                //       src={getImages(publisher)}
                //       style={{ width: '100%' }}
                //     />
                //   </StyledImgDiv>
                //   <StyledTitleDiv style={{ width: '50%' }}>
                //     <StyledTitle
                //       style={{
                //         padding: '0px 0px 0px 10px',
                //         fontSize: '14px',
                //       }}
                //     >
                //       <LoaderWrapper isLoading={loader === publisher.name}>
                //         {publisher?.name && publisher?.name?.length > 10 ? (
                //           <Tooltip

                //             arrow
                //             placement="top"
                //             title={publisher.name}
                //             componentsProps={{
                //               tooltip: {
                //                 sx: {
                //                   fontFamily: 'Poppins',
                //                 },
                //               },
                //             }}
                //           >
                //             {`${publisher?.name.substring(0, 10)}...`}
                //           </Tooltip>
                //         ) : (
                //           publisher?.name
                //         )}
                //       </LoaderWrapper>
                //     </StyledTitle>
                //   </StyledTitleDiv>
                //   {premiumModal === false && !loader ? (
                //     <StyledCross
                //       style={{
                //         marginRight: '7px',
                //         width: '15%',
                //         justifyContent: 'center',
                //         cursor: 'pointer',
                //       }}
                //       onClick={() => {
                //         console.log('add clicked');
                //         if (isAdmin) {
                //           handlePublisherSubscribeClick(publisher.id, index);
                //         } else {
                //           postMyPublisherSubscribeChannelClick(
                //             publisher.id,
                //             index
                //           );
                //         }
                //         setLoader(publisher.name);
                //       }}
                //     >
                //       <img alt="" src="/assets/images/plus.svg" />
                //     </StyledCross>
                //   ) : null}
                // </Chip>
              )}
          </PublisherCardWrapper>
        </Flex>
        {/* <AlphabetScrollerWrapper
          style={{
            maxHeight: '300px',
            color: '#98A2B3',
            backgroundColor: '#fff',
          }}
        >
          
        </AlphabetScrollerWrapper> */}
      </Flex>
    </Flex>
  );
};

export default PublisherListView;
