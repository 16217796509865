import React from 'react';
import ReactSkeleton from 'react-loading-skeleton';
import {
  HeaderCol,
  HeaderTitleContainer,
  HeaderWrapper,
  RowContainer,
  TableBodyContainer,
  TableCell,
  TableContainer,
  TableRow,
} from './styles';

function TableSkeleton({ tableRowLength = 3 }) {
  return (
    <TableContainer>
      <TableBodyContainer>
        <HeaderWrapper>
          {Array.from({ length: 4 }, (_, index) => (
            <HeaderCol width="25%" key={index}>
              <div>
                <HeaderTitleContainer>
                  <p className="header-col-title">
                    <ReactSkeleton height={25} width={200} />
                  </p>
                </HeaderTitleContainer>
              </div>
            </HeaderCol>
          ))}
        </HeaderWrapper>
        <RowContainer>
          {Array.from({ length: tableRowLength }, (_, index) => (
            <TableRow>
              {Array.from({ length: 4 }, (_, index) => (
                <TableCell width="25%" key={index}>
                  <div>
                    <ReactSkeleton height={20} width={150} />
                  </div>
                </TableCell>
              ))}
            </TableRow>
          ))}
        </RowContainer>
      </TableBodyContainer>
    </TableContainer>
  );
}

export default TableSkeleton;
