import axios from 'axios';
import apiInstance from '../api';
import { apiDetails, objectToFormData } from '../helpers';

// export const getPRTopics = () => {
//   return axios.get(`${apiDetails.baseUrl.trim()}segmentsresearch/gettopics/`, {
//     headers: {
//       'Auth': 'test-value'
//     }
//   });

// };

let token =
  // 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwczpcL1wvYXBpLm15Y2xvdXQuY29tXC8iLCJpYXQiOjE2MzkzNzAzODgsIm5iZiI6MTYzOTM3MDM4OCwiZXhwIjoxNjM5NzMwMzg4LCJ1aWQiOiJRQTdEQkx4dyJ9.rT7dx6FJ7ity-IXuIf55z4hs8mOAYgy2zkl3EP7rDeU';

  'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwczovL2FwaS5teWNsb3V0LmNvbS8iLCJpYXQiOjE2MzQxMTE4OTcsIm5iZiI6MTYzNDExMTg5NywiZXhwIjoyMjMzNDc4OTk5LCJ1aWQiOiJrM3ZEekRxUSJ9.G2A5GxzzLDfE0C9Gl0kyu2Q6x1McpWwy93Z0cus6YNo';
export const getPRTopics = () => {
  window.sessionStorage.setItem('token', token);
  return apiInstance().get(`/segmentsresearch/gettopics/`);
};

export const savePRTopics = (payload) => {
  // console.log('CLONED NEWSLETTER = = = = ', cloneObject);
  return apiInstance().post(
    `segmentsresearch/savetopics/`,
    objectToFormData(payload)
  );
};

export const getPRArticles = (payload) => {
  return apiInstance().get(
    `/segmentsresearch/articlelist?uuid=${payload.uuid}`
  );
};

export const firePRUserAction = (payload) => {
  // console.log('CLONED NEWSLETTER = = = = ', cloneObject);
  console.log('firePRUserAction', payload);
  return apiInstance().post(
    `segmentsresearch/useraction`,
    objectToFormData(payload)
  );
};

// export const getNewsletterOutboxData = ({ filter, beforeToken, afterToken }) => {
//   let url;
//   if(beforeToken) {
//     url = `newsletter/?filter={"status":"${filter}", "limit": 900}&before=${beforeToken}`;
//   } else if(afterToken) {
//     url = `newsletter/?filter={"status":"${filter}", "limit": 900}&after=${afterToken}`;
//   } else {
//     url = `newsletter/?filter={"status":"${filter}", "limit": 900}`;
//   }

//   return apiInstance().get(url);
// }

// export const getBucketArticlesCount = () => {
//   return apiInstance().get(`newsletter/bucket/count`);
// }

// export const cloneNewsletter = cloneObject => {
//   // console.log('CLONED NEWSLETTER = = = = ', cloneObject);
//   return apiInstance().post(`newsletter/clone/`, objectToFormData(cloneObject));
// }
