import { Flex } from '@rebass/grid';
import React, { useState, useEffect } from 'react';
import {
  Button,
  LoaderWrapper,
  MioTextArea,
  Modal,
  ModalBody,
  ModalHeader,
} from '../../../components';
import { postCampaignReview } from '../../../services/campaign';
import {
  FeedbackInputLabel,
  ModalFooterBtnsWrapper,
} from './ReviewCampaigns.styles';
// import { useHistory } from 'react-router';

const RejectCampaignModal = ({
  isOpen,
  handleClose,
  campaignId,
  setIsApproved,
  platformForReview,
}) => {
  // console.log(campaignId);
  const [feedback, setFeedback] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  // const history = useHistory();
  useEffect(() => {
    setFeedback('');
  }, []);

  const rejectHandler = () => {
    setErrorMsg('');
    if (feedback.length > 0 && feedback.length <= 500) {
      let payload = {
        action: 'rejected',
        message: feedback,
        platform: platformForReview(),
      };
      setIsLoading(true);
      postCampaignReview({ id: campaignId, payload })
        .then((res) => {
          setIsLoading(false);
          if (res.result.success) {
            setFeedback('');
            setIsApproved(res);
            handleClose();
          } else {
            setErrorMsg('Something went wrong!');
          }
        })
        .catch((err) => {
          setIsLoading(false);
          setErrorMsg('Something went wrong!');
          console.log(err);
        });
    }
  };
  return (
    <Modal bgColor="white" height="88%" size="medium" isOpen={isOpen}>
      <ModalHeader id="header" style={{ textAlign: 'left', color: 'black' }}>
        Reject With Feedback
        <hr style={{ width: '100%', marginTop: '10px' }} />
      </ModalHeader>
      <ModalBody>
        <Flex flexDirection="column">
          {/* <div style={{ fontSize: '0.9rem', fontWeight: '700' }}>
            This feedback is for all platforms.
          </div> */}
          <div>
            {feedback.length > 500
              ? 'Feedback should be less than or equal to 500 characters'
              : ''}
            {errorMsg.length > 0 && errorMsg}
          </div>
          <FeedbackInputLabel>
            Please provide your feedback on this content.
          </FeedbackInputLabel>
          <div>
            <MioTextArea
              id="feedback"
              name="feedback"
              onChange={(e) => setFeedback(e.target.value)}
              cols="30"
              rows="10"
              placeholder="Provide your feedback"
              value={feedback}
              style={{
                textAlign: 'left',
                backgroundColor: 'white',
                padding: '10px',
                color: 'black',
              }}
              required
              data-testid="campaign-reject-fb"
            />
          </div>
        </Flex>
        <LoaderWrapper isLoading={isLoading}>
          <ModalFooterBtnsWrapper>
            <hr style={{ width: '100%' }} />
            <Flex justifyContent="flex-end" py="10px">
              <Button
                styles={{ borderRadius: '4px' }}
                color="#fff"
                bgColor="#4E6780"
                margin="0px 10px"
                borderColor="#4E6780"
                onClick={() => {
                  setFeedback('');
                  handleClose();
                }}
                testId="campaign-reject-cancel"
              >
                Cancel
              </Button>
              <Button
                styles={{ borderRadius: '4px' }}
                color="red"
                bgColor="white"
                margin="0px 10px"
                borderColor="red"
                disabled={feedback.length === 0 || feedback.length > 500}
                onClick={rejectHandler}
                testId="campaign-reject-submit"
              >
                Reject & Send Feedback
              </Button>
            </Flex>
          </ModalFooterBtnsWrapper>
        </LoaderWrapper>
      </ModalBody>
    </Modal>
  );
};

export default RejectCampaignModal;
