import React from 'react';
import { AdminListItem, AdminRadioButton, AdminRadioLabel } from './screenConfigure.styles';

const AdminRadio = ({ label, id, name, checked, defaultChecked, onChange, pt, bgColor, disabled }) => {
  return (
    <AdminListItem pt={pt}>
      <AdminRadioButton 
        bgColor={bgColor} 
        id={id} 
        name={name} 
        defaultChecked={defaultChecked} 
        onChange={onChange} 
        disabled={disabled}
      />
      <AdminRadioLabel 
        style={{cursor: disabled ? 'not-allowed' : 'pointer'}} 
        htmlFor={id}
      >
        {label}
      </AdminRadioLabel>
    </AdminListItem>
  );
};

export default AdminRadio;