/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import useQuery from '../../customHooks/useQuery';
import axios from 'axios';
import { apiDetails, objectToFormData } from '../../services/helpers';
import { get, isEmpty, startCase } from 'lodash';
import { Flex } from '@rebass/grid';
import { Button, LoaderWrapper } from '../../components';

const ComplianceApproveAllContainer = () => {
    const query = useQuery();
    const [complianceData, setComplianceData] = useState([]);
    const [selectedCampaigns, setSelectedCampaigns] = useState([]);
    const [passcode, setPasscode] = useState('');
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState('');
    const [dataLoading, setDataLoading] = useState(false);

    const getCampaignData = () => {
        setDataLoading(true);
        axios.get(`${apiDetails.baseUrl}compliance_details?payload=${query.get('payload')}`)
            .then(res => {
                setComplianceData(get(res.data.result, 'data', []));
                setDataLoading(false);
                if(!isEmpty(get(res.data.result, 'data', []).filter(c => c.status === 'pending'))) {
                    setSelectedCampaigns(get(res.data.result, 'data', []).filter(c => c.status === 'pending').map(c => c.compliance_id))
                }
            })
            .catch(err => {
                console.log(err);
                setDataLoading(false);
            });
    }

    useEffect(() => {
        getCampaignData();
    }, []);

    const handleChange = id => {
        if (!isEmpty(selectedCampaigns) && selectedCampaigns.includes(id)) {
            setSelectedCampaigns(selectedCampaigns.filter(s => s !== id));
        } else {
            setSelectedCampaigns([...selectedCampaigns, id])
        }
    }

    const handleApprove = () => {
        setLoading(true);
        const payload = {
            c_action: 'approved',
            passcode,
            compliance_ids: JSON.stringify(selectedCampaigns)
        }
        axios.post(`${apiDetails.baseUrl}process_compliance_all`, objectToFormData(payload))
            .then(res => {
                console.log(res.data);
                setLoading(false);
                if (res.data.result.success) {
                    getCampaignData();
                    setMessage('Approved Successfully!');
                    setSelectedCampaigns([]);
                    setPasscode('');
                } else {
                    setMessage(get(res.data.result, 'message', ''));
                }
            })
            .catch(err => {
                console.log(err);
                setLoading(false);
            });
    }

    const getCampaignType = type => {
        if (type === 'ac_block') {
            return 'Automated Campaign';
        } else if (type === 'campaign') {
            return 'Campaign';
        } else if (type === 'landing_page') {
            return 'Web Page';
        } else {
            return '-';
        }
    }
    return (
        <Flex flexDirection="column" justifyContent="center" margin="0 auto" width="90%">
            <Flex
                fontSize="20px"
                justifyContent="center"
                color="#6351ed"
                width="100%"
                mt="6%"
            >
                Compliance Campaign Approval Portal
            </Flex>

            <Flex mt="3%" padding="10px" width="75%" justifyContent="center" ml="auto" mr="auto" style={{ backgroundColor: '#ececec' }}>
                <Flex
                    width="5%"
                    justifyContent="center"
                    fontSize="12px"
                    color="#6351ed"
                >

                </Flex>
                <Flex
                    width="45%"
                    fontSize="12px"
                    color="#6351ed"
                >
                    Campaign Title
                </Flex>
                <Flex
                    width="25%"
                    fontSize="12px"
                    color="#6351ed"
                >
                    Campaign Type
                </Flex>

                <Flex
                    width="25%"
                    fontSize="12px"
                    color="#6351ed"
                >
                    Campaign Status
                </Flex>
            </Flex>
            <LoaderWrapper isLoading={dataLoading}>
                <Flex flexDirection="column" width="75%" justifyContent="center" ml="auto" mr="auto">
                    {!isEmpty(complianceData) && !isEmpty(complianceData.filter(c => c.status === 'pending')) ? complianceData.filter(c => c.status === 'pending').map(item => (
                        <Flex padding="10px" width="100%">
                            <Flex
                                width="5%"
                                justifyContent="center"
                                fontSize="12px"
                                color="black"
                            >
                                <input
                                    id={`name${item.compliance_id}`}
                                    type="checkbox"
                                    checked={selectedCampaigns.includes(item.compliance_id)}
                                    onChange={() =>
                                        handleChange(
                                            item.compliance_id
                                        )
                                    }
                                />
                            </Flex>
                            <Flex
                                width="45%"
                                fontSize="12px"
                                color="black"
                            >
                                {item?.name}
                            </Flex>
                            <Flex
                                width="25%"
                                fontSize="12px"
                                color="black"
                            >
                                {getCampaignType(item?.record_type)}
                            </Flex>
                            <Flex
                                width="25%"
                                fontSize="12px"
                                color="#6351ed"
                            >
                                {item?.status && startCase(item?.status)}
                            </Flex>
                        </Flex>
                    )) : (
                        <div style={{ color: '#6351ed', fontSize: '12px' }}>No Data to review</div>
                    )}

                </Flex>
            </LoaderWrapper>


            <Flex justifyContent="center">
                <input
                    name="passcode"
                    type="password"
                    placeholder="Passcode"
                    onChange={(e) => setPasscode(e.target.value)}
                    value={passcode}
                    style={{
                        width: '45%',
                        marginTop: '50px',
                        borderRadius: '5px',
                        padding: '10px',
                        height: '40px',
                        border: '1px solid black',
                    }}
                />
            </Flex>

            <Flex width="100%" justifyContent="center" mt="5px">
                <LoaderWrapper isLoading={loading}>
                    <Button
                        width="45%"
                        margin="10px 0px 20px 0px;"
                        onClick={handleApprove}
                        disabled={isEmpty(passcode) || isEmpty(selectedCampaigns)}
                    >
                        Approve Campaign
                    </Button>
                </LoaderWrapper>
            </Flex>
            {message && (
                <div style={{ color: '#6351ed', fontSize: '12px' }}>{message}</div>
            )}
        </Flex>
    )
}

export default ComplianceApproveAllContainer;