import { Flex } from '@rebass/grid'
import React from 'react'
import { Button, ModalBody, ModalHeader, ModalV2 } from 'src/components'
import { ColorPallete, Typography } from 'src/utils'

const DeactivatePlatformModal = ({
    isOpen,
    onClose,
    socialNetworkDelete,
    deactivateModalSettings
}) => {
    const platformMapper = {
        'ln': 'LinkedIn',
        'tw': 'Twitter',
        'fb': 'Facebook',
        'google': 'Google',
        'outlook': 'Outlook',
        'zm': 'Zoom',
        'smtp': 'Email (SMTP)',
        'sg': 'Sendgrid'
    }

    const onDisconnect = () => {
        socialNetworkDelete({
            params: {
                id: `${deactivateModalSettings.platform}_${deactivateModalSettings.accountId}`,
            },
        });
        onClose({
            isOpen: false,
            accountId: '',
            platform: '',
            no_of_campaigns: ''
        })
    }
    return (
        <ModalV2
            isOpen={isOpen}
            bgColor="#fff"
            width="30%"
            shouldCloseOnOverlayClick>
            <ModalHeader fontSize={Typography.Heading} fontColor={ColorPallete.neutral900}>
                <Flex width="100%" alignItems="center" justifyContent="space-between">
                    <Flex>
                        Are you sure?
                    </Flex>
                    <Flex>
                        <img
                            src="/assets/images/Analytics/close-button.svg"
                            alt="close"
                            style={{ cursor: 'pointer', width: '40px' }}
                            onClick={() => onClose({
                                isOpen: false,
                                accountId: '',
                                platform: '',
                                no_of_campaigns: ''
                            })}
                        />
                    </Flex>
                </Flex>
            </ModalHeader>
            <ModalBody padding="10px">
                <Flex flexDirection="column" height="auto">
                    <Flex color={ColorPallete.neutral600} fontSize={Typography.TextMedium} style={{textAlign: 'left'}}>
                        You're about to disconnect with {platformMapper[deactivateModalSettings.platform]}. Please confirm if you're sure to disconnect your account from the selected platform.
                    </Flex>
                    <Flex mt="10px" color={ColorPallete.neutral600} fontSize={Typography.TextMedium} style={{textAlign: 'left'}}>
                        This will cancel all ongoing campaigns scheduled using this platform's token.
                    </Flex>
                    <Flex mt="10px" width="100%" justifyContent="space-between">
                        <Flex width="50%" color={ColorPallete.neutral500} fontSize={Typography.TextSmall}>
                            Ongoing Campaigns
                        </Flex>
                        <Flex width="50%" color={ColorPallete.neutral500} fontSize={Typography.TextSmall}>
                            Selected Platform
                        </Flex>
                    </Flex>
                    <Flex width="100%" justifyContent="space-between">
                        <Flex width="50%" color={ColorPallete.neutral900} fontSize={Typography.Heading}>
                            {deactivateModalSettings?.no_of_campaigns}
                        </Flex>
                        <Flex width="50%" color={ColorPallete.neutral900} fontSize={Typography.Heading}>
                            {platformMapper[deactivateModalSettings.platform]}
                        </Flex>
                    </Flex>
                    <Flex justifyContent="center">
                        <Button
                            borderRadius="4px"
                            width="50%"
                            bgColor="#fff"
                            color={ColorPallete.primaryBlue}
                            borderColor={ColorPallete.pink}
                            margin="20px 10px 0px 0px"
                            onClick={() => onClose({
                                isOpen: false,
                                accountId: '',
                                platform: '',
                                no_of_campaigns: ''
                            })}
                        >
                            Keep Connection
                        </Button>
                        <Button
                            borderRadius="4px"
                            width="50%"
                            bgColor="red"
                            styles={{
                                border: 'none'
                            }}
                            onClick={onDisconnect}
                        >
                            Disconnect
                        </Button>
                    </Flex>
                </Flex>
            </ModalBody>
        </ModalV2>
    )
}

export default DeactivatePlatformModal