import * as React from 'react';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';

const ConfirmationMsg = ({
    severity,
    title
}) => {
  return (
    <Stack sx={{ width: '100%' }} spacing={2} >
      <Alert style={{ justifyContent: 'center', alignItems: 'center', width: '100%' }} severity={severity}>{title}</Alert>
    </Stack>
  );
};

export default ConfirmationMsg;