/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { 
  OverviewContainer,
  LargeContentContainer,
  TopContentContainer,
  SectionTitle,
  SectionSubTitle,
  BodyWrapper,
  ActionContainer,
} from './styles';
import GraphDisplay from '../../../componentsV2/GraphDisplay';
import { 
  campaignPerformanceSummary,
} from './mockData';
import ToggleBar from '../../../components/ToggleBar';
import { getCampaignAnalyticsList, getCampaignPerformance, getCampaignPerformanceScore, getContentGraphData, getRecommendationData, getTopCampaigns } from '../../../services/analyticsV2';
import { get, isEmpty } from 'lodash';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import CampaignCard from './CampaignCard';
import { Flex } from '@rebass/grid';
import InfiniteScroll from 'react-infinite-scroll-component';
import CreateCampaignModal from '../../DripCampaignContainerV2/DripDashboardTable/CreateCampaignModalsContainer/CreateCampaignModal';
import { actions as campaignActions } from '../../../redux/modules/campaign.module';
import { connect } from 'react-redux';
import CampaignRecommendationCard from './CampaignRecommendationCard';
import moment from 'moment';
import EmailAndSocialBox from './EmailAndSocialBox';
import TopPerformingCampaign from './TopPerformingCampaign';
import ErrorDisplay from '../../../componentsV2/ErrorDisplay/ErrorDisplay';
import { EmptyState } from '../../../componentsV2';
import { ColorPallete, nurtureFlag } from '../../../utils';
import useQueryForKeyInsights from 'src/hooks/useQueryForKeyInsights';

const toggleItems = [
  {
    name: 'Completed Campaigns',
    key: 'completed'
  },
  {
    name: 'Ongoing Campaigns',
    key: 'ongoing'

  }
];

const CampaignTab = ({
  startDate,
  campaignSetState,
  userDetails,
  showEnterpriseDropdownSelectedOption,
  selectedUsers
}) => {
  const [campaignStatus, setCampaignStatus] = useState(nurtureFlag === 'nurture' && process.env.REACT_APP_API_ENV === 'stage' ? 'ongoing' : 'completed');
  const [page, setPage] = useState(1);
  const [campaignListData, setCampaignListData] = useState([]);
  const [tableLoading, setTableLoading] = useState(false);
  const [campaignPageData, setCampaignPageData] = useState({});
  const [graphLoading, setGraphLoading] = useState(false);
  const [openCreateCampaignModal, setOpenCreateCampaignModal] = useState(false);
  const [performanceGraphData, setPerformanceGraphData] = useState([]);
  const [errorDisplay, setErrorDisplay] = useState(false);
  const [recommendationError, setRecommendationError] = useState(false);
  const [engagedDataError, setEngagedDataError] = useState(false);
  const [emailAndSocialTab, setEmailAndSocialTab] = useState("email");
  const [topCampaignsLoading, setTopCampaignsLoading] = useState(false);
  const [recommendationLoading, setRecommendationLoading] = useState(false);
  const [engagedDataLoading, setEngagedDataLoading] = useState(false);
  const [topCampaigns, setTopCampaigns] = useState([]);
  const [topCampaignsError, setTopCampaignError] = useState(false);
  const [campaignRecommendationData, setCampaignRecommendationData] = useState([]);
  const [campaignError, setCampaignError] = useState(false);
  const [campaignMostEngagedData, setCampaignMostEngagedData] = useState([]);
  const [totalCampaignCount, setTotalCampaignCount] = useState({
    ongoing:0,
    completed:0
  });
  const [campaignPerformanceScoreData,setCampaignPerformanceScoreData] = useState([]);
  const limit = 10;

  const isEnterprise = showEnterpriseDropdownSelectedOption === 'My Enterprise' ? true : false;
  const { data, isLoading, isFetching, error, refetch } = useQueryForKeyInsights(userDetails?.details?.user?.email);

  const fetchCampaigns = async (pageNo, type) => {
    setTableLoading(true);
    try {
      if (pageNo === 1) {
        let res;
        if(type === 'completed') {
          res = await getCampaignAnalyticsList(pageNo, limit, false, startDate, isEnterprise, selectedUsers);
        } else {
          res = await getCampaignAnalyticsList(pageNo, limit, true, startDate, isEnterprise, selectedUsers);
        }
        setCampaignListData([...get(res, 'result.data', [])]);
        setCampaignPageData(get(res, 'result.pagination', {}));
        setTableLoading(false);
        setCampaignError(false);
        let completedCampaignResponse = type === 'completed' ? res : await getCampaignAnalyticsList(pageNo, limit, false, startDate, isEnterprise, selectedUsers);
        let onGoingCampaignResponse = type !== 'completed' ? res : await getCampaignAnalyticsList(pageNo, limit, true, startDate, isEnterprise, selectedUsers);
        setTotalCampaignCount({
          completed:get(completedCampaignResponse,'result.pagination.total_results', 0),
          ongoing:get(onGoingCampaignResponse,'result.pagination.total_results',0)
        })
      } else {
        let res;
        if(type === 'completed') {
          res = await await getCampaignAnalyticsList(pageNo, limit, false, startDate, isEnterprise, selectedUsers);
        } else {
          res = await getCampaignAnalyticsList(pageNo, limit, true, startDate, isEnterprise, selectedUsers);
        }
        setCampaignListData([...campaignListData,...get(res, 'result.data', [])]);
        setCampaignPageData(get(res, 'result.pagination', {}));
        setTableLoading(false);
        setCampaignError(false);
      }
    } catch (error) {
      setTableLoading(false);
      setCampaignError(true);
    }
  }

  const fetchTopCampaigns = () => {
    setTopCampaignsLoading(true);
    getTopCampaigns(startDate, 5, isEnterprise, selectedUsers).then(res => {
      setTopCampaigns(get(res, 'result', []));
      setTopCampaignsLoading(false);
      setTopCampaignError(false);
    }).catch(err => {
      setTopCampaignsLoading(false);
      setTopCampaignError(true);
    })
  }

  const fetchRecommendationData = () => {
    setRecommendationLoading(true);
    getRecommendationData(emailAndSocialTab, startDate, isEnterprise, selectedUsers)
    .then(recRes => {
      getContentGraphData(startDate, 1, isEnterprise).then(res => {
        setCampaignRecommendationData(get(recRes, 'result', []));
        setRecommendationLoading(false);
        setRecommendationError(false);
      }).catch(err => {
        setRecommendationLoading(false);
        setRecommendationError(true);
      })
    })
    .catch(err => {
      setRecommendationLoading(false);
      setRecommendationError(true);
    })
  };

  const fetchMostEngagedData = (type) => {
    setEngagedDataLoading(true);
    getRecommendationData(type, startDate, isEnterprise, selectedUsers)
    .then(res => {
      setCampaignMostEngagedData(get(res, 'result', []));
      setEngagedDataLoading(false);
      setEngagedDataError(false);
    })
    .catch(err => {
      setEngagedDataLoading(false);
      setEngagedDataError(true);
    })
  };

  const processPerfomanceGraphData = (data) => {
    let graphData = [];
    if(!isEmpty(Object.keys(data))) {
      Object.keys(data).forEach((item, index) => {
        graphData.push({
          name: moment(item).format("DD MMM"),
          'Open Rate': data[item].open_rate,
          'Click Rate': data[item].click_rate
        })
      });
      setPerformanceGraphData(graphData);
    } else {
      setPerformanceGraphData([]);
    }
  }

  const fetchCampaignPerformance = () => {
    setGraphLoading(true);
    getCampaignPerformance(startDate, isEnterprise, selectedUsers).then(res => {
      console.log(res);
      if(res?.result?.campaign_performance) {
        processPerfomanceGraphData(get(res, 'result.campaign_performance', {}))
        setGraphLoading(false);
        setErrorDisplay(false)
      }
    }).catch(err => {
      setGraphLoading(false);
      setErrorDisplay(true);
    })
  }


  const fetchCampaignPerformanceScoreData = () => {
    setRecommendationLoading(true);
    getCampaignPerformanceScore(startDate, isEnterprise, selectedUsers)
      .then((res) => {
        console.log("campaign performnace data--->", res)
        setCampaignPerformanceScoreData(get(res, 'result', []));
        setRecommendationLoading(false);
        setRecommendationError(false);
      })
      .catch((err) => {
        setRecommendationLoading(false);
        setRecommendationError(true);
      });
  };

  const handlePageChange = () => {
    setTimeout(async () => {
      console.log('next call')
      let pageNo = page + 1;
      setPage(pageNo);
      await fetchCampaigns(pageNo, campaignStatus);
    }, 1500);

  }

  const handleRetryClick = () => {
    fetchCampaignPerformance();
  };

  const handleRecommendationRetryClick = () => {
    fetchRecommendationData();
  };

  const handleEngagedDataRetryClick = () => {
    fetchMostEngagedData(emailAndSocialTab);
  }

  const handleCampaignperformanceScoreRetryClick = () => {
    fetchCampaignPerformanceScoreData();
  };

  const handleCampaignToggle = (type) => {
    setCampaignStatus(type);
    setTotalCampaignCount(null);
    setPage(1);
    setCampaignListData([]);
    setCampaignPageData({});
    fetchCampaigns(1, type);
  }

  const handleToggle = (type) => {
    fetchMostEngagedData(type);
    setEmailAndSocialTab(type);
  }

  useEffect(() => {
    if (startDate) {
      if (showEnterpriseDropdownSelectedOption === 'My Enterprise' || !isEmpty(selectedUsers)) {
        fetchCampaignPerformanceScoreData();
      } 
        setPage(1);
        setCampaignListData([]);
        setCampaignPageData({});
        fetchCampaigns(1, campaignStatus);
        fetchCampaignPerformance();
        // fetchTopCampaigns();
        // fetchRecommendationData();
        // fetchMostEngagedData(emailAndSocialTab);
    }
  }, [startDate, showEnterpriseDropdownSelectedOption, selectedUsers]);

  return (
    <OverviewContainer>
      <TopContentContainer>
        <LargeContentContainer>
          <GraphDisplay
            chartData={campaignPerformanceSummary}
            graphData={performanceGraphData}
            showTitle
            showDropdown
            showLegend
            background={'#fff'}
            height={showEnterpriseDropdownSelectedOption === "My Enterprise" || !isEmpty(selectedUsers) ? 306.3 : 315}
            loading={graphLoading}
            handleRetryClick={handleRetryClick}
            errorDisplay={errorDisplay}
            showEnterpriseDropdownSelectedOption={showEnterpriseDropdownSelectedOption}
            selectedFirms={selectedUsers}
            interval={3}
          
          />
        </LargeContentContainer>
        <div style={{ width: showEnterpriseDropdownSelectedOption === "My Enterprise" || !isEmpty(selectedUsers) ? 'calc(27% - 10px)' : 'calc(25% - 10px)', height: 465 }}>
          <CampaignRecommendationCard
            title="Recommendations"
            onClick={() => setOpenCreateCampaignModal(true)}
            campaignRecommendationData={data}
            loading={isLoading}
            recommendationError={recommendationError}
            campaignPerformanceError={recommendationError}
            campaignPerformanceLoading={recommendationLoading}
            handleRetryClick={refetch}
            userDetails={userDetails}
            showEnterpriseDropdownSelectedOption={showEnterpriseDropdownSelectedOption}
            recommendedTopics={data?.topics}
            campaignPerformanceData={campaignPerformanceScoreData}
            handleCampaignperformanceScoreRetryClick={handleCampaignperformanceScoreRetryClick}
            selectedFirms={selectedUsers}
          />
        </div>
        {openCreateCampaignModal && (
          <CreateCampaignModal
            isOpen={openCreateCampaignModal}
            handleClose={() => {
              setOpenCreateCampaignModal(false);
            }}
            campaignSetState={campaignSetState}
          />
        )}
      </TopContentContainer>
      {/* <SectionTitle>Insights</SectionTitle>
      <SectionSubTitle>
        Check out your top performing campaigns, most engaging formats, and most
        optimal times to send before constructing your next campaign
      </SectionSubTitle>
      <Flex justifyContent="space-between" width="100%" mt="20px">
        <Flex width="49%">
          <TopPerformingCampaign
            loading={topCampaignsLoading}
            data={topCampaigns}
            topCampaignsError={topCampaignsError}
            fetchTopCampaigns={fetchTopCampaigns}
          />
        </Flex>

        <Flex width="49%">
          <EmailAndSocialBox
            emailAndSocialTab={emailAndSocialTab}
            handleToggle={(type) => handleToggle(type)}
            campaignMostEngagedData={campaignMostEngagedData}
            loading={engagedDataLoading}
            engagedDataError={engagedDataError}
            handleRetryClick={handleEngagedDataRetryClick}
            userDetails={userDetails}
          />
        </Flex>
      </Flex> */}
      <SectionTitle style={{ marginTop: 20 }}>Campaign Data</SectionTitle>
      <SectionSubTitle>
        Discover how each of your campaigns are performing
      </SectionSubTitle>
      <BodyWrapper>
        <ActionContainer>
          <div style={{ width: '40%' }}>
            <ToggleBar
              toggleItems={toggleItems}
              activeToggle={campaignStatus}
              toggleClick={(type) => handleCampaignToggle(type)}
              margin='10px 0'
              showCount
              totalCount={totalCampaignCount}
            />
          </div>
          {/* <div style={{display: 'flex', justifyContent: 'end', width: '60%'}}>
            <div style={{margin: openSearch && '-8px 0 0'}}>
              <SearchBar 
                setActiveSearch={setOpenSearch} 
                activeSearch={openSearch} 
                searchString={searchString} 
                setSearchString={setSearchString} 
                maxWidth={'250px'}
              />
            </div>
            <FilterButton onClick={()=>setShowFilterModal(true)}>
              <FilterIcon src={filter} alt='filter' />
              <div style={{margin: '8px 0 0'}}>Filter</div>
            </FilterButton>
            <div style={{width: 112}}>
              <ToggleBar 
                toggleItems={gridItems}
                activeToggle={gridDisplay} 
                toggleClick={setGridDisplay} 
                margin={'0px 0px 0px 10px'}
              />
            </div>
          </div> */}
        </ActionContainer>
        <Flex width="100%" flexDirection="column">
          {tableLoading && isEmpty(campaignListData) && (
            <SkeletonTheme>
              <Skeleton height={135} count={10} baseColor={ColorPallete.neutral100} />
            </SkeletonTheme>
          )}
          {!tableLoading && !campaignError && isEmpty(campaignListData) && (
            <EmptyState 
              title="No Analytics Data"
              subTitle="Send out campaigns to get insights"
              height="212px"
            />
          )}
          {!tableLoading && isEmpty(campaignListData) && campaignError && (
            <ErrorDisplay height="212px" onClick={() => fetchCampaigns(1)}/>
          )}
          {!isEmpty(campaignListData) && !isEmpty(campaignPageData) && (
            <InfiniteScroll
              dataLength={campaignListData?.length}
              next={handlePageChange}
              hasMore={
                campaignPageData?.has_next_page && !tableLoading
              }
              loader={
                !campaignError && (
                  <SkeletonTheme>
                  <Skeleton height={135} count={10} baseColor={ColorPallete.neutral100} />
                </SkeletonTheme>
                )
              }
            >
              {!isEmpty(campaignListData) &&
                campaignListData.map((item, index) => (
                  <CampaignCard {...item} />
                ))}
            </InfiniteScroll>
          )}
           {!tableLoading && !isEmpty(campaignListData) && campaignError && (
            <ErrorDisplay height="212px" onClick={() => fetchCampaigns(page)}/>
          )}
        </Flex>  
        
      </BodyWrapper>
    </OverviewContainer>
  );
};

const mapStateToProps = (state) => {
  return {
    userDetails: state.user.data,
  };
};

const mapDispatchToProps = {
  campaignSetState: campaignActions.api.campaign.setstate,
};

export default connect(mapStateToProps,mapDispatchToProps)(CampaignTab); 
