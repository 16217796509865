import { handleActions } from 'redux-actions';
import { apiModuleDefaultState, createApiActions } from './helpers';

export const actions = createApiActions('share');

const reducer = handleActions(
  {
    [actions.api.share.request]: (state) => ({
      ...state,
      isFetching: true,
      isError: false,
      error: [],
    }),
    [actions.api.share.response]: (state, { payload }) => ({
      ...state,
      // isFetching: false,
      isError: false,
      data: {...state.data, ...payload},
    }),
    [actions.api.share.error]: (state, { payload }) => ({
      ...state,
      // isFetching: false,
      isError: true,
      error: [...state.error, payload],
    }),
    [actions.api.share.setstate]: (state, { payload }) => ({
      ...state,
      ...payload
    })
  },
  { ...apiModuleDefaultState, data: [], error: null }
);

export default reducer;
