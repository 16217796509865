/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Flex } from '@rebass/grid';
import {
  Line,
  WhiteCircle,
} from '../../../components/StepperV2/Stepper.styles';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import { StyledAccordianButton } from '../../../components/CreateTopicModal/CreateTopicModal.styles';
import StackedDataCard from '../CustomizeCampaignV3/StackedDataCard';
import DataCard from '../CustomizeCampaignV3/DataCard';
import { get, isEmpty } from 'lodash';
import StepsFooterV2 from '../../AutomatedCampaigns/CampaignCreateSteps/StepsFooterV2';
import { useHistory } from 'react-router-dom';
import CampaignDetailsOnetimeSeries from './CampaignDetailsOneTimeSeries';
import { campaignSummary, getSeriesSubCampaigns, sendTestEmailOneTime, shareSeriesCampaign } from '../../../services/campaign';
import { LoaderWrapper } from '../../../components';
import AutomatedCampaignEmailPreviewModal from '../../AutomatedCampaigns/CampaignCreateSteps/AutomatedCampaignEmailPreviewModal';
import SocialPreviewModal from '../CustomizeCampaignV3/SocialPreviewModal';

import SuccessModal from '../../AutomatedCampaigns/CampaignCreateSteps/SuccessModal';
import SendTestMailModal from '../../AutomatedCampaigns/CampaignCreateSteps/SendTestMailModal';

const CampaignOverviewOnetimeSeries = ({
  step,
  setStep,
  endCreateFlow,
  onSaveAsDraftClick,
  seriesId,
  setObjectivesTabData,
  campaignSetState,
  isFirmLevelAdmin,
  firmSettings,
  companyId,
  compliance,
  reviewType,
  userData,
  isAdvisorApprovalMode,
  advisorApprovalRequired,
  isadvMode,
  blocks
}) => {

  const [accordionExpanded, setAccordionExpanded] = useState(false);
  const [toogleCompliance, setToggleCompliance] = useState('false');
  const [toggleLeadCapture, setToggleLeadCapture] = useState('false');
  const [loading, setLoading] = useState(false);
  const [selectedId, setSelectedId] = useState();
  const [openPreviewModal, setOpenPreviewModal] = useState(false);
  const [openSocialPreviewModal, setOpenSocialPreviewModal] = useState(false);
  const [openSendTestEmailModal, setOpenSendTestEmailModal] = useState(false);
  const [sendTestEmail, setSendTestEmail] = useState('');
  const [requestLoading, setRequestLoading] = useState(false);
  const [sendTestErrorMsg, setSendTestErrorMsg] = useState('');
  const [requiredApproval, setRequiredApproval] = useState(false);
  const [complianceSettings, setComplianceSettings] = useState('off');
  const [successModal, setSuccessModal] = useState(false);
  const [submitLoader, setSubmitLoader] = useState(false);
  const [summaryDetails, setSummaryDetails] = useState();
  const [advisorApproval, setAdvisorApproval] = useState(false);

  const history = useHistory();
  const handlSubmitClick = () => {
    setSubmitLoader(true);
    let payload = {
      lead_gen: toggleLeadCapture,
      skip_compliance: reviewType === "edit" ? 'false' : reviewType === "start" ? 'true' : requiredApproval ? 'false' : 'true',
      ...(isAdvisorApprovalMode && {
        advisor_approval: advisorApproval === true ? 'true' : 'false'
      })
    }
    shareSeriesCampaign(seriesId, payload).then(res => {
      setSuccessModal(true);
      setSubmitLoader(false);
    }).catch(err => {
      console.log(err);
      setSubmitLoader(false);
    })
  };

  const closeConfModal = () => {
    setSelectedId();
    setOpenPreviewModal(false);
    setOpenSocialPreviewModal(false);
    setOpenSendTestEmailModal(false);
    setSendTestEmail('');
  }

  const handleSendTestValidation = () => {
    if (!sendTestEmail) {
      return true;
    }
  };


  const handleSocialPreviewClick = (item) => {
    console.log('item---', item)
    setOpenSocialPreviewModal(true);
    setSelectedId(item);
  }

  const handleSendTestModalOpen = (item) => {
    console.log('item---', item)
    setOpenSendTestEmailModal(true);
    setSelectedId(item);
  }


  const handleComplianceToggle = (checked) => {
    if (checked) {
      setToggleCompliance('true');
      setRequiredApproval(true);
    }
    else {
      setToggleCompliance('false');
      setRequiredApproval(false);
    }
  };

  const handleEmailPreviewClick = (item) => {
    console.log('item---', item)
    setOpenPreviewModal(true);
    setSelectedId(item);
  }

  const handleLeadCaptureToggle = (checked) => {
    if (checked) setToggleLeadCapture('true');
    else {
      setToggleLeadCapture('false');
    }
  };

  const handleAdvisorApproval = (checked) => {
    setAdvisorApproval(checked);
  }

  const handleSendClick = () => {
    console.log('clicked');
    // TO DO HANDLE EMAIL VALIDATION AND COUNT VALIDATION
    setSendTestErrorMsg('');
    let msg = 'Email is not valid';
    const isEmailRegEx =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,3}))$/;
    let valid = true;
    if (valid && !isEmpty(sendTestEmail)) {
      valid = sendTestEmail
        .split(',')
        .map((em) => em.trim())
        .every((em) => isEmailRegEx.test(em.toLowerCase()));
      if (sendTestEmail.split(',').length > 5 && valid) {
        valid = false;
        msg = 'Maximum 5 emails can be added';
      }
    }
    if (!valid) {
      setSendTestErrorMsg(msg);
      return;
    }
    setRequestLoading(true);
    const payload = {
      campaign_id: selectedId.id,
      email_ids: JSON.stringify(sendTestEmail.split(','))
    }
    sendTestEmailOneTime(payload)
      .then((res) => {
        console.log('send');
        if (res?.result?.success) {
          setRequestLoading(false);
          setSendTestErrorMsg('Sent Successfully');
          setTimeout(() => {
            setSendTestErrorMsg('');
            setOpenSendTestEmailModal(false);
          }, 3000);
        } else {
          setRequestLoading(false);
          setSendTestErrorMsg(res?.result?.message);
        }
        setSendTestEmail('');
      })
      .catch((error) => {
        console.log(error);
        setRequestLoading(false);
      });
    setSendTestErrorMsg();
  };


  useEffect(() => {
    if (setObjectivesTabData?.skip_compliance === false) {
      setRequiredApproval(true);
    } else if(setObjectivesTabData?.cascaded && reviewType !== 'start') {
      setRequiredApproval(true);
    } else {
      const complianceOption = isFirmLevelAdmin && !isEmpty(firmSettings) ?
        get(firmSettings, `firm_compliance.${[companyId]}.compliance_is`, 'always_off')
        : compliance;
      setComplianceSettings(complianceOption);
      if (complianceOption === 'on' || complianceOption === 'always_on') {
        setRequiredApproval(true);
      }
    }

    setAdvisorApproval(advisorApprovalRequired);

  }, []);


  useEffect(() => {
    setLoading(true);
    campaignSummary(seriesId).then(res => {
      setSummaryDetails(res?.result?.data);
      setLoading(false);
    }).catch(err => {
      console.log(err);
      setLoading(false);
    })
  }, [])


  return (
    <Flex width="80%" margin="0px auto 100px" flexDirection="column">
      <Flex width="100%" alignItems="center">
        <WhiteCircle height="40px" width="40px" background="#816EF8">
          <span
            style={{
              color: '#fff',
              fontSize: '16px',
              position: 'absolute',
              top: '6px',
              left: '0px',
              width: '100%',
            }}
          >
            3
          </span>
        </WhiteCircle>
        <Flex fontSize="20px" color="#101828" style={{ fontWeight: 500 }}>
          Campaign Overview
        </Flex>
      </Flex>
      <hr style={{ width: '100%', border: '1px solid #E4E7EC' }}></hr>
      <LoaderWrapper isLoading={loading}>
        <Flex display="flex" flexDirection="row" width="100%">
          <Flex
            margin="15px 0px 0px"
            width="45%"
            style={{ position: 'relative' }}
          >
            <Flex width="100%" flexDirection="column">
              <Accordion
                allowZeroExpanded
                onChange={() => setAccordionExpanded(!accordionExpanded)}
              >
                <AccordionItem>
                  <AccordionItemHeading>
                    <StyledAccordianButton
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        border: 'none',
                        backgroundColor: '#F6F9FB',
                        margin: '0px',
                      }}
                    >
                      {!accordionExpanded ? (
                        <Flex
                          width="100%"
                          justifyContent="flex-start"
                          marginBottom="10px"
                          flexDirection="row"
                        >
                          <img
                            alt=""
                            src="/assets/images/Campaign/expand-icon.svg"
                            style={{ marginRight: '10px' }}
                          />
                          <Flex
                            fontSize="14px"
                            color="#101828"
                            style={{ fontWeight: 500 }}
                          >
                            {blocks?.length} Promotional {blocks?.length > 1 ? 'Blocks' : 'Block'}
                          </Flex>
                        </Flex>
                      ) : (
                        <Flex
                          width="100%"
                          justifyContent="flex-start"
                          marginBottom="10px"
                          flexDirection="row"
                        >
                          <img
                            alt=""
                            src="/assets/images/Campaign/expand-more-icon.svg"
                            style={{ marginRight: '5px' }}
                          />
                          <Flex
                            fontSize="14px"
                            color="#101828"
                            style={{ fontWeight: 500, marginTop: '6px' }}
                          >
                            {blocks?.length} Promotional Blocks
                          </Flex>
                        </Flex>
                      )}
                      {!accordionExpanded && (
                        <StackedDataCard
                          item={
                            !isEmpty(blocks) &&
                            blocks[0]
                          }
                        />
                      )}
                    </StyledAccordianButton>
                  </AccordionItemHeading>
                  {!isEmpty(blocks) &&
                    blocks
                      .map((item) => (
                        <AccordionItemPanel style={{ marginBottom: '20px' }}>
                          <DataCard 
                            item={item} 
                            step={step} 
                            onPreviewClick={handleEmailPreviewClick}
                            onSocialPreviewClick={handleSocialPreviewClick}
                            onSendTestModalClick={handleSendTestModalOpen}
                          />
                        </AccordionItemPanel>
                      ))}
                </AccordionItem>
              </Accordion>
            </Flex>
          </Flex>
          <Line
            border="1px solid #E4E7EC"
            style={{ height: '450px', position: 'relative', marginTop: '20px' }}
          />
          <Flex display="flex" width="50%">
            <Flex width="100%" flexDirection="column" padding="25px 5px">
              <CampaignDetailsOnetimeSeries
                handleComplianceToggle={handleComplianceToggle}
                toogleCompliance={toogleCompliance}
                toggleLeadCapture={toggleLeadCapture}
                advisorApproval={advisorApproval}
                handleLeadCaptureToggle={handleLeadCaptureToggle}
                handleAdvisorApproval={handleAdvisorApproval}
                setObjectivesTabData={setObjectivesTabData}
                complianceSettings={complianceSettings}
                blocks={blocks}
                summaryDetails={summaryDetails}
                reviewType={reviewType}
                userData={userData}
                isAdvisorApprovalMode={isAdvisorApprovalMode}
                advisorApprovalRequired={advisorApprovalRequired}
                isadvMode={isadvMode}
              />
            </Flex>
          </Flex>
        </Flex>
      </LoaderWrapper>

      {openPreviewModal && (
        <AutomatedCampaignEmailPreviewModal
          isOpen={openPreviewModal}
          handleClose={() => closeConfModal()}
          campaign_id={selectedId?.id}
          template_html={selectedId?.template?.template_html}
          subject={selectedId?.customized_data?.subject}
          name={selectedId?.name}
          campaignFlow
        />
      )}

      {openSocialPreviewModal && (
        <SocialPreviewModal
          isOpen={openSocialPreviewModal}
          handleClose={() => closeConfModal()}
          campaign_id={selectedId?.id}
          title={''}
          is_facebook={selectedId?.is_facebook}
          is_twitter={selectedId?.is_twitter}
          is_linkedin={selectedId?.is_linkedin}
        />
      )}

      {openSendTestEmailModal && (
        <SendTestMailModal
          handleModalClose={() => closeConfModal()}
          isOpen={openSendTestEmailModal}
          subject={selectedId?.customized_data?.subject}
          handleChange={(e) => setSendTestEmail(e.target.value)}
          value={sendTestEmail}
          modalButtonOnClick={handleSendClick}
          validation={handleSendTestValidation()}
          sendTestErrorMsg={sendTestErrorMsg}
          loading={requestLoading}
        />
      )}

          {successModal && (
            <SuccessModal
              isOpen={successModal}
              handleModalClose={() => history.push('/campaignV2')}
              requiredApproval={requiredApproval}
              campaignFlow
              campaignSetState={campaignSetState}
            />
          )}

      <StepsFooterV2
        loader={submitLoader}
        currentStepIndex={step}
        setCurrentStepIndex={setStep}
        handleNext={() =>
          handlSubmitClick()
        }
        disableNext={loading}
        endCreateFlow={endCreateFlow}
        campaignSetState={campaignSetState}
        campaignFlow
        requiredApproval={requiredApproval}
        reviewType={reviewType}
      />
    </Flex>
  );
};

export default CampaignOverviewOnetimeSeries;
