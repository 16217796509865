import styled from 'styled-components';

export const FlipWrapper = styled.div`
  perspective: 1000px;
`;

export const FlipInner = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  ${({ shouldFlip }) => shouldFlip && 'transform: rotateY(180deg);'}
`;

const commonStyled = `
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
`;

export const FlipFront = styled.div`
  ${commonStyled}
`;

export const FlipBack = styled.div`
  ${commonStyled}
  transform: rotateY(180deg);
`;
