import apiInstance from 'src/services/api';
import { objectToFormData } from 'src/services/helpers';

export const createPreviewContentFromArticleLink = async (request) => {
  const { channelId, payload } = request;

  const contentResponse = await apiInstance().post(
    `/channel/${channelId}/content`,
    objectToFormData({ ...payload })
  );

  if (!contentResponse?.result?.success) {
    throw new Error('Something went wrong while creating a content');
  }

  const response = await apiInstance().post(
    `/theme/fetch`,
    objectToFormData({
      url: payload?.link,
      return_text: true,
    })
  );

  const data = {
    ...response?.result?.data,
    ...contentResponse?.result?.data,
  };

  return {
    ...response,
    result: {
      ...response?.result,
      data,
    },
  };
};

export const createPreviewContentFromPDF = async (request) => {
  const { channelId, payload } = request;

  const contentResponse = await apiInstance().post(
    `/channel/${channelId}/content`,
    objectToFormData({ ...payload })
  );

  if (!contentResponse?.result?.success) {
    throw new Error('Something went wrong while creating a content');
  }

  const contentResponseForNonLinks = await apiInstance().post(
    `/channel/content/${contentResponse?.result?.data?.content_id}`,
    objectToFormData({ ...payload })
  );

  const articleResponse = await apiInstance().post(
    `/theme/fetch`,
    objectToFormData({
      media1: payload?.attachment1,
      return_text: true,
    })
  );

  return {
    ...contentResponseForNonLinks,
    result: {
      ...contentResponseForNonLinks?.result,
      data: {
        content_id: contentResponse?.result?.data?.content_id,
        id: contentResponse?.result?.data?.id,
        text: {
          image: contentResponse?.result?.data?.image,
          description: articleResponse?.result?.data?.text,
        },
        themes: articleResponse?.result?.data?.themes,
      },
    },
  };
};

export const createPreviewContentFromImage = async (request) => {
  const { channelId, payload } = request;

  const contentResponse = await apiInstance().post(
    `/channel/${channelId}/content`,
    objectToFormData({ ...payload })
  );

  if (!contentResponse?.result?.success) {
    throw new Error('Something went wrong while creating a content');
  }

  const contentResponseForNonLinks = await apiInstance().post(
    `/channel/content/${contentResponse?.result?.data?.content_id}`,
    objectToFormData({ ...payload })
  );

  return {
    ...contentResponseForNonLinks,
    result: {
      ...contentResponseForNonLinks?.result,
      data: {
        content_id: contentResponse?.result?.data?.content_id,
        id: contentResponse?.result?.data?.id,
        text: {
          image: contentResponse?.result?.data?.image,
          description: ' ',
        },
        themes: [],
      },
    },
  };
};

export const createContent = async (request) => {
  const { contentId, payload } = request;

  const contentResponse = await apiInstance().post(
    `/channel/content/${contentId}`,
    objectToFormData({ ...payload })
  );

  return contentResponse;
};
