import { Flex } from '@rebass/grid';
import React, { useEffect, useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalCloseButton, Button, Input, LoaderWrapper } from '../../../components';
import { getNewsletterDetails, cloneNewsletter } from '../../../services/newsletter';
import { useHistory } from 'react-router-dom';

const CloneNLModal = ({ show, toggleShow, newsletterId, newsletterName, refresh, isFromRecentTab, toggleIsCloning }) => {

    const [cloneName, setCloneName] = useState({});
    const [isLoading, toggleLoading] = useState(true);
    const [btnLoader, toggleBtnLoader] = useState(false);
    const history = useHistory();

    useEffect(() => {
        // const id = newsletterId;
        // getNewsletterDetails({ id })
        //     .then(res => {
        //         setNewsLetterClone({ ...res.result.data, name: `Copy of <${res.result.data.name}>` });
        //         toggleLoading(false);
        //     })
        //     .catch(err => console.log('ERROR - - - - ', err));
        setCloneName(`Copy of ${newsletterName}`);
    }, []);

    const saveHandler = () => {
        toggleBtnLoader(true);
        // const cloneObject = {
        //     name: newsLetterClone.name,
        //     message: newsLetterClone.customized_data.message,
        //     salutation: newsLetterClone.customized_data.salutation,
        //     salutation_text: newsLetterClone.customized_data.salutation_text,
        //     subject: newsLetterClone.customized_data.subject
        // }
        const cloneObject = {
            newsletter_id: newsletterId,
            name: cloneName
        };
        console.log('CLONE OBJECT - - - - ', cloneObject);
        cloneNewsletter(cloneObject)
        .then(res => {
            console.log('CLONE POST RESPONSE - - - ', res);
            toggleBtnLoader(false);
            toggleShow(false);
            if (isFromRecentTab) {
                history.push('/newsletter?type=dashboard');
                toggleIsCloning(false);
            }
            else
                refresh(0);
        })
        .catch(err => console.log('ERROR ---- ', err));
    }

    return (

        <Modal
            onRequestClose={() => {
                toggleShow(false);
                toggleIsCloning(false);
            }}
            shouldCloseOnOverlayClick
            isOpen={show}
            size="medium-small"
        >
            <ModalHeader>
                Clone Newsletter
                <ModalCloseButton onClick={() => {
                    toggleShow(false);
                    if(isFromRecentTab)
                        toggleIsCloning(false);
                }} />
            </ModalHeader>
            <LoaderWrapper isLoading={isLoading && false}>
                <ModalBody style={{ position: 'relative' }}>
                    <Input
                        isInputWithFloatingLabel={true}
                        placeholder='Newsletter Name:'
                        value={cloneName}
                        onChange={e => setCloneName(e.target.value)}
                        labelStyle={{ top: '10px', left: '10px', color: '#6351ed' }}
                    />
                    <Flex justifyContent='space-between' alignItems='baseline' width='47%' margin='auto' style={{ position: 'relative' }}>
                        <Button 
                            bgColor='#6351ed' 
                            margin='20px 10px 0px 0px' 
                            onClick={() => {
                                toggleShow(false);
                                if(isFromRecentTab)
                                    toggleIsCloning(false);
                            }}
                        >
                            Cancel
                        </Button>
                        <LoaderWrapper styles={{ position: 'absolute', top: '14px', left: '36px' }} isLoading={btnLoader}>
                            <Button bgColor='#6351ed' margin='20px 10px 0px 0px' onClick={saveHandler}>Save</Button>
                        </LoaderWrapper>
                    </Flex>
                </ModalBody>
            </LoaderWrapper>
        </Modal>
    );
}

export default CloneNLModal;