import * as React from 'react';

function Lightbulb(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 57 56" fill="none" {...props}>
      <path
        d="M23.5 41h10"
        stroke="currentColor"
        strokeWidth={2.133}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.338 32.875a9.938 9.938 0 01-3.838-7.812c-.025-5.425 4.338-9.938 9.763-10.063a10 10 0 016.412 17.863A3.024 3.024 0 0033.5 35.25V36a1 1 0 01-1 1h-8a1 1 0 01-1-1v-.75a3.049 3.049 0 00-1.162-2.375v0zM29.513 19.1a5.987 5.987 0 014.9 4.887"
        stroke="currentColor"
        strokeWidth={2.667}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

const MemoLightbulb = React.memo(Lightbulb);
export default MemoLightbulb;
