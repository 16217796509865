import * as React from 'react';

function Image(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 16 16" fill="none" {...props}>
      <path
        d="M13.5 3h-11a.5.5 0 00-.5.5v9a.5.5 0 00.5.5h11a.5.5 0 00.5-.5v-9a.5.5 0 00-.5-.5z"
        stroke="currentColor"
        strokeWidth={1.6}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2 10.5l3.144-3.144a.5.5 0 01.712 0l2.788 2.788a.5.5 0 00.712 0l1.288-1.288a.5.5 0 01.712 0L14 11.5"
        stroke="currentColor"
        strokeWidth={1.6}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M9.75 7a.75.75 0 100-1.5.75.75 0 000 1.5z" fill="currentColor" />
    </svg>
  );
}

const MemoImage = React.memo(Image);
export default MemoImage;
