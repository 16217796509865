import React from 'react';

function InfoIcon() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 14.5C11.3137 14.5 14 11.8137 14 8.5C14 5.18629 11.3137 2.5 8 2.5C4.68629 2.5 2 5.18629 2 8.5C2 11.8137 4.68629 14.5 8 14.5Z"
        stroke="currentColor"
        stroke-width="1.6"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M7.5 8H8V11.5H8.5"
        stroke="currentColor"
        stroke-width="1.6"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M7.875 6.5C8.28921 6.5 8.625 6.16421 8.625 5.75C8.625 5.33579 8.28921 5 7.875 5C7.46079 5 7.125 5.33579 7.125 5.75C7.125 6.16421 7.46079 6.5 7.875 6.5Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default InfoIcon;
