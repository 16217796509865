import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import { DashboardArticle } from '../../../components';

import { getExploreArticleData, dashboardSliderSettings } from '../../../utils';

import { ArticleSliderWrapper } from '../DashboardContainer.styles';

const MyMioTab = ({
  selectedTopic,
  exploreArticles,
  deleteArticleRequest,
  themeNames,
  exploreArticleDeleting,
  isShareBlocked,
  setShowArticleLoader,
  hideDelete
}) => {
  const [imageCounter, setImageCounter] = useState([]);
  const [localExploreArticles, setLocalExploreArticles] = useState([]);
  const [totalArticles, setTotalArticles] = useState(0);


   useEffect(() => {
     let articles = getExploreArticleData(exploreArticles);
     setLocalExploreArticles(articles);
     setTotalArticles(articles.length);
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [exploreArticles]);
  return (
    <ArticleSliderWrapper>
      <Slider {...dashboardSliderSettings}>
        {localExploreArticles.map((article, index) => (
          <DashboardArticle
            {...{
              ...article,
              selectedTopic,
              deleteArticleRequest,
              themeNames,
              exploreArticleDeleting,
              isShareBlocked,
              totalArticles,
              index,
              setShowArticleLoader,
              imageCounter,
              setImageCounter,
              hideDelete

            }}
          />
        ))}
      </Slider>
    </ArticleSliderWrapper>
  );
};

export default MyMioTab;
