import { Flex } from '@rebass/grid';
import { isEmpty, uniqBy } from 'lodash';
import React from 'react'
import { NewsletterCheckBoxDiv } from '../Recipients/Recipients.styles';
import { ListWrapper } from './EmailListAdded.styles';

const EmailListAdded = ({
  contactGroups,
  selectedContactGroups,
  setSelectedContactGroups,
  setHeaderOptions,
  headerOptions
}) => {

  const handleCheckBoxClick = (item) => {
    let contacts = selectedContactGroups.filter(s => s.id !== item.id);
    setSelectedContactGroups([...contacts]);
  }

  const handleHeaderCheckboxClick = (type) => {
    let contacts = selectedContactGroups.filter(s => s.type !== type);
    setSelectedContactGroups(contacts);
    setHeaderOptions({...headerOptions, [type]: false});
  }

  const getCustomList = () => {
    let data = selectedContactGroups.filter(c => c.type === 'custom');
    let customListLength = contactGroups.filter(c => c.type === 'custom').length;

    return (
      !isEmpty(data) ? (
        <Flex width="100%" flexDirection="column">
        <NewsletterCheckBoxDiv fontWeight="bold">
          <label class="container">
            {customListLength === data.length ? (
              <>
                <input
                  type="checkbox"
                  checked={true}
                  onChange={() => handleHeaderCheckboxClick('custom')}
                />
                <span class="checkmark"></span>
              </>
            ) : null}
              My Lists
          </label>
        </NewsletterCheckBoxDiv>
        {!isEmpty(data) && data.map((item, index) => (
          <NewsletterCheckBoxDiv>
            <label class="container">
              <input
                type="checkbox"
                checked={true}
                onChange={() => handleCheckBoxClick(item)}
              />
              <span class="checkmark"></span>
                {item.name}
            </label>
          </NewsletterCheckBoxDiv>
        ))}
      </Flex>
      ) : null
    ) 
  }

  const getHubspotList = () => {
    let data = selectedContactGroups.filter(c => c.type === 'hubspot');
    let hubspotListLength = contactGroups.filter(c => c.type === 'hubspot').length;

    return !isEmpty(data) ? (
      <Flex width="100%" flexDirection="column" pb="10px">
        <NewsletterCheckBoxDiv fontWeight="bold">
          <label class="container">
            {hubspotListLength === data.length ? (
              <>
                <input
                  type="checkbox"
                  checked={true}
                  onChange={() => handleHeaderCheckboxClick('hubspot')}
                />
                <span class="checkmark"></span>
              </>
            ) : null}
            Hubspot
          </label>
        </NewsletterCheckBoxDiv>
        {!isEmpty(data) &&
          data.map((item, index) => (
            <NewsletterCheckBoxDiv>
              <label class="container">
                <input
                  type="checkbox"
                  checked={true}
                  onChange={() => handleCheckBoxClick(item)}
                />
                <span class="checkmark"></span>
                {item.name}
              </label>
            </NewsletterCheckBoxDiv>
          ))}
      </Flex>
    ) : null; 
  }

  const getSalesforceList = () => {
    let data = selectedContactGroups.filter(c => c.type === 'salesforce');
    let salesforceListLength = contactGroups.filter(c => c.type === 'salesforce').length;

    return !isEmpty(data) ? (
      <Flex width="100%" flexDirection="column" pb="10px">
        <NewsletterCheckBoxDiv fontWeight="bold">
          <label class="container">
            {salesforceListLength === data.length ? (
              <>
                <input
                  type="checkbox"
                  checked={true}
                  onChange={() => handleHeaderCheckboxClick('salesforce')}
                />
                <span class="checkmark"></span>
              </>
            ) : null}
            Salesforce
          </label>
        </NewsletterCheckBoxDiv>
        {!isEmpty(data) &&
          data.map((item, index) => (
            <NewsletterCheckBoxDiv>
              <label class="container">
                <input
                  type="checkbox"
                  checked={true}
                  onChange={() => handleCheckBoxClick(item)}
                />
                <span class="checkmark"></span>
                {item.name}
              </label>
            </NewsletterCheckBoxDiv>
          ))}
      </Flex>
    ) : null; 
  }

  const getWealthboxList = () => {
    let data = selectedContactGroups.filter(c => c.type === 'wealthbox');
    let wealthboxListLength = contactGroups.filter(c => c.type === 'wealthbox').length;

    return !isEmpty(data) ? (
      <Flex width="100%" flexDirection="column" pb="10px">
        <NewsletterCheckBoxDiv fontWeight="bold">
          <label class="container">
            {wealthboxListLength === data.length ? (
              <>
                <input
                  type="checkbox"
                  checked={true}
                  onChange={() => handleHeaderCheckboxClick('wealthbox')}
                />
                <span class="checkmark"></span>
              </>
            ) : null}
            Wealthbox
          </label>
        </NewsletterCheckBoxDiv>
        {!isEmpty(data) &&
          data.map((item, index) => (
            <NewsletterCheckBoxDiv>
              <label class="container">
                <input
                  type="checkbox"
                  checked={true}
                  onChange={() => handleCheckBoxClick(item)}
                />
                <span class="checkmark"></span>
                {item.name}
              </label>
            </NewsletterCheckBoxDiv>
          ))}
      </Flex>
    ) : null; 
  }

  const getRedtailList = () => {
    let data = selectedContactGroups.filter(c => c.type === 'redtail');
    let redtailListLength = contactGroups.filter(c => c.type === 'redtail').length;

    return !isEmpty(data) ? (
      <Flex width="100%" flexDirection="column" pb="10px">
        <NewsletterCheckBoxDiv fontWeight="bold">
          <label class="container">
            {redtailListLength === data.length ? (
              <>
                <input
                  type="checkbox"
                  checked={true}
                  onChange={() => handleHeaderCheckboxClick('redtail')}
                />
                <span class="checkmark"></span>
              </>
            ) : null}
            Redtail
          </label>
        </NewsletterCheckBoxDiv>
        {!isEmpty(data) &&
          data.map((item, index) => (
            <NewsletterCheckBoxDiv>
              <label class="container">
                <input
                  type="checkbox"
                  checked={true}
                  onChange={() => handleCheckBoxClick(item)}
                />
                <span class="checkmark"></span>
                {item.name}
              </label>
            </NewsletterCheckBoxDiv>
          ))}
      </Flex>
    ) : null; 
  }

  return (
    <ListWrapper>
      {getCustomList()}
      {getHubspotList()}
      {getSalesforceList()}
      {getWealthboxList()}
      {getRedtailList()}
    </ListWrapper>
  )
}

export default EmailListAdded;
