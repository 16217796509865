import { styled } from '@mui/material';

const ColumnPadding = '10px 20px';

export const TableContainer = styled('div')(({ customStyles = {} }) => ({
  display: 'flex',
  width: '100%',
  overflow: 'hidden',
  background: '#fff',
  border: '1px solid #E4E7EC',
  borderRadius: 'inherit',
  ...customStyles,
}));

export const TableBodyContainer = styled('div')(() => ({
  width: '100%',
  display: 'grid',
  overflow: 'auto',
  flex: 1,
  '&::-webkit-scrollbar': {
    width: '8px',
    height: '8px',
  },
}));

export const HeaderWrapper = styled('header')(({ fixedHeader = false }) => ({
  height: '54px',
  borderBottom: '1px solid #E4E7EC',
  display: 'flex',
  alignItems: 'center',
  flexWrap: 'nowrap',
  background: '#F9FAFB',
  ...(fixedHeader && { position: 'sticky', top: 0 }),
}));

export const HeaderCol = styled('div')(({ minWidth, width, maxWidth }) => ({
  display: 'flex',
  alignItems: 'center',
  minWidth: minWidth ?? 'unset',
  width: width ?? '100%',
  maxWidth: maxWidth ?? 'unset',
  padding: ColumnPadding,

  '& .header-title-container': {
    cursor: 'pointer',
    width: 'fit-content',
    display: 'flex',
    flexDirection: 'column',
  },
}));

export const HeaderTitleContainer = styled('div')(({ titleAlignment }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: titleAlignment ?? 'flex-start',
  flexWrap: 'nowrap',
  '& .header-col-title': {
    font: '500 1rem Poppins,sans-serif',
    margin: 0,
    textAlign: 'left',
    color: '#101828',
  },
}));

export const HeaderSubTitle = styled('div')(({ titleAlignment }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: titleAlignment ?? 'flex-start',
  '& p': {
    margin: 0,
    textAlign: 'left',
    color: '#101828',
  },
}));

export const TableCell = styled('div')(
  ({ minWidth, width, container, maxWidth }) => ({
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    fontWeight: 500,
    lineHeight: '17px',
    color: '#101828',
    padding: ColumnPadding,
    minWidth: minWidth ?? 'unset',
    width: width ?? '100%',
    flexDirection: container ? 'column' : 'row',
    wordBreak: 'break-word',
    maxWidth: maxWidth ?? 'unset',
  })
);

export const TableRow = styled('div')(({ onRowClick, customMinHeight }) => ({
  width: '100%',
  minHeight: customMinHeight ? '90px' : 'unset',
  borderBottom: '1px solid #E4E7EC',
  display: 'flex',
  alignItems: 'center',
  flexWrap: 'nowrap',
  cursor: typeof onRowClick === 'function' ? 'pointer' : 'auto',
  '&:last-child': {
    borderBottom: '1px solid transparent',
  },
  '&:hover': {
    background: 'rgb(249, 250, 251)',
    '.exploreDriverPill': {
      display: 'flex',
    },
  },
}));

export const RowContainer = styled('div')(({ height }) => ({
  width: '100%',
  maxHeight: height || '',
}));

export const NoDataMessage = styled('div')(() => ({
  color: '#101828',
  font: '500 0.875rem/1rem Poppins,sans-serif',
  minHeight: '100px',
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));
