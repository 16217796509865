import React from 'react';

function ProfileIcon() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7 8.875C9.07107 8.875 10.75 7.19607 10.75 5.125C10.75 3.05393 9.07107 1.375 7 1.375C4.92893 1.375 3.25 3.05393 3.25 5.125C3.25 7.19607 4.92893 8.875 7 8.875Z"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M1.59961 12.25C2.09351 11.2386 2.86156 10.3861 3.81628 9.78986C4.77099 9.19359 5.87399 8.87744 6.99961 8.87744C8.12523 8.87744 9.22823 9.19359 10.1829 9.78986C11.1377 10.3861 11.9057 11.2386 12.3996 12.25"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

export default ProfileIcon;
