import { ColorPallete } from "../../../utils";

export const contactGraphData = [
    {
      name: 'List Name',
      pv: 2400,
      fill: '#00B186',
    },
    {
      name: 'List Name',
      pv: 1398,
      fill: '#FFA32C',
    },
    {
      name: 'List Name',
      pv: 9800,
      fill: '#2196F3',
    },
    {
      name: 'List Name',
      pv: 3908,
      fill: '#FDD835'
    },
    {
      name: 'List Name',
      pv: 4800,
      fill: '#7B73E4'
    },
    {
      name: 'List Name',
      pv: 3800,
      fill: '#311B92'
    },
    {
      name: 'List Name',
      pv: 4300,
      fill: '#F21D6B'
    },
    {
      name: 'List Name',
      pv: 4300,
      fill: '#CE93D8'
    },
    {
      name: 'List Name',
      pv: 4300,
      fill: '#19D6DB'
    },
];

export const contactsEngagementData = [
  {
    name: 'High',
    'Engagement Score': 2400,
    fill: '#00B186',
  },
  {
    name: 'Medium',
    'Engagement Score': 2400,
    fill: '#FFA32C',
  },
  {
    name: 'Low',
    'Engagement Score': 2400,
    fill: '#CE93D8',
  },
]

export const leadCardData = [
  {
    name: 'John Doe',
    email: 'johndoe@email.com'
  },
  {
    name: 'John Doe1',
    email: 'johndoe@email.com'
  },
  {
    name: 'John Doe2',
    email: 'johndoe@email.com'
  },
  {
    name: 'John Doe3',
    email: 'johndoe@email.com'
  },
  {
    name: 'John Doe4',
    email: 'johndoe@email.com'
  },
  {
    name: 'John Doe5',
    email: 'johndoe@email.com'
  },
  {
    name: 'John Doe6',
    email: 'johndoe@email.com'
  },
  {
    name: 'John Doe7',
    email: 'johndoe@email.com'
  },
  {
    name: 'John Doe8',
    email: 'johndoe@email.com'
  },
  {
    name: 'John Doe9',
    email: 'johndoe@email.com'
  }
];

export const emailListData = [
  {
    name: 'Email List',
    contacts: '24',
    mom: '10.00%',
    qoq: '10.00%',
    engagement: 30
  },
  {
    name: 'Email List',
    contacts: '24',
    mom: '10.00%',
    qoq: '10.00%',
    engagement: 30
  },
  {
    name: 'Email List',
    contacts: '24',
    mom: '10.00%',
    qoq: '10.00%',
    engagement: 30
  },
  {
    name: 'Email List',
    contacts: '24',
    mom: '10.00%',
    qoq: '10.00%',
    engagement: 30
  },
  {
    name: 'Email List',
    contacts: '24',
    mom: '10.00%',
    qoq: '10.00%',
    engagement: 30
  },
  {
    name: 'Email List',
    contacts: '24',
    mom: '10.00%',
    qoq: '10.00%',
    engagement: 30
  },
  {
    name: 'Email List',
    contacts: '24',
    mom: '10.00%',
    qoq: '10.00%',
    engagement: 30
  },
  {
    name: 'Email List',
    contacts: '24',
    mom: '10.00%',
    qoq: '10.00%',
    engagement: 30
  },
  {
    name: 'Email List',
    contacts: '24',
    mom: '10.00%',
    qoq: '10.00%',
    engagement: 30
  },
  {
    name: 'Email List',
    contacts: '24',
    mom: '10.00%',
    qoq: '10.00%',
    engagement: 30
  }
]

export const graphColorPalette = ['#00B186', '#FFA32C','#2196F3', '#FDD835', '#7B73E4', '#311B92', '#F21D6B', '#CE93D8', '#19D6DB']