import * as React from 'react';

function Alarm(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 16 16" fill="none" {...props}>
      <path
        d="M8 4.5V8h3.5"
        stroke="currentColor"
        strokeWidth={1.6}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 13.5a5.5 5.5 0 100-11 5.5 5.5 0 000 11z"
        stroke="currentColor"
        strokeWidth={1.6}
        strokeMiterlimit={10}
      />
      <path
        d="M12.244 1.637l2.118 2.12M1.638 3.756l2.118-2.119"
        stroke="currentColor"
        strokeWidth={1.6}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

const MemoAlarm = React.memo(Alarm);
export default MemoAlarm;
