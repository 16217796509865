import styled from 'styled-components';

export const MemberTileWrapper = styled.div`
background: linear-gradient(
  to bottom,
  rgba(252, 252, 252, 1) 0%,
  rgba(247, 247, 247, 1) 50%,
  rgba(247, 247, 247, 1) 50%,
  rgba(227, 227, 227, 1) 100%
) !important;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  text-align: center;
  cursor: pointer;
`;

export const MemberTileTitle = styled.div`
  background-color: #e4e4e5;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  text-align: center;
  font-size: 11px;
  padding: 9px 0;
  cursor: pointer;
`;
