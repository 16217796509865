import React from 'react'
import { ScoreWrapper } from '../../containers/AnalyticsContainer/EnagagementTab/EngagementTab.styles';

const DisplayScore = ({ score, height, width, fontSize, borderRadius, border }) => {
  let borCol = score <= 30 && score > 0 ? '#6351ed #c5c5c9 #c5c5c9 #c5c5c9' : (
    score<=60 && score > 0  ? '#6351ed #6351ed #c5c5c9 #c5c5c9' : 
    score === 0 || !score ? '#c5c5c9' : (
      score<=85 && score > 0 ? '#6351ed #6351ed #6351ed #c5c5c9' : '#6351ed'
    )
  );
  console.log(score);
  let style = {
    fontSize: fontSize || '9px',
    border: border || '3px solid',
    borderColor: borCol,
    borderRadius: borderRadius || '13px',
    padding: score < 12 ? '4px 7px 2px' : (score < 100 ? '3px 4px 2px' : '3px 2px 2px'),
    position: 'relative',
    height: height || '28px',
    width: width || '28px',
    display: 'flex', 
    alignItems: 'center',
    justifyContent:'center'
  };

  return <ScoreWrapper style={style}>
    {!score ? '0' : score}
    <span className="tooltip-text">Engagement score is a measure 0-100 based on the percentage of emails opened and clicked</span>
  </ScoreWrapper>
}

export default DisplayScore;
