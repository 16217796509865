import React from 'react';
import './StepOneComponent.css';
import PurpleButton from '../../../../../componentsV2/PurpleButton';
import WhiteButton from '../../../../../componentsV2/WhiteButton';
import CustomDropdown from '../../../../../componentsV2/CustomDropdown';
import { TextInput, TextLabel } from '../../styles';

const stepOneComponent = ({ loginDetails, changeHandler, domainHandler, loginHandler, isAdmin, closeModal, domainList }) => {
    console.log('loginDetails',loginDetails.valid)
    console.log('domainList',domainList)

    return (
        <div style={{ margin: '10px auto', width: '100%', textAlign: 'left' }}>
            <TextLabel>From Name
                <TextInput              
                    name="from_name"
                    id="from_name"
                    type="text"
                    width={'100%'}
                    placeholder="From Name"
                    onChange={changeHandler}
                    value={loginDetails.from_name}
                />
            </TextLabel>
            <TextLabel>Email
                <TextInput              
                    name="email"
                    id="email"
                    type="text"
                    width={'100%'}
                    placeholder="Email"
                    onChange={changeHandler}
                    value={loginDetails.email}
                />
            </TextLabel>

            {!isAdmin ? (
                <CustomDropdown
                    dropdownLabel={'Domain Name'}
                    dropdownItems={domainList ?? []}
                    selectedDropdown={{...loginDetails, ['domain']: loginDetails.domain_name}}
                    setSelectedDropdown={domainHandler}
                    margin={'0 auto 8px'}
                    height={'35px'}
                    customField={'domain'}
                />
            ) : (
                <TextLabel>Username
                    <TextInput              
                        name="domain_name"
                        id="domain_name"
                        type="text"
                        width={'100%'}
                        placeholder={!isAdmin ? loginDetails.domain_name : 'Username'}
                        onChange={changeHandler}
                        value={loginDetails?.domain_name}
                    />
                </TextLabel>
            )}
            
            <div style={{ borderTop: '1px solid #E4E7EC', padding: '10px 0 0', width: '100%', margin: '20px auto', display: 'flex', justifyContent: 'space-between' }}>
                <WhiteButton width={'49%'} children={'Back'} onClick={()=>closeModal()} />
                <PurpleButton width={'49%'} disabled={!loginDetails.valid} onClick={loginHandler}>{isAdmin ? 'Next' : 'Connect'}</PurpleButton>
            </div>
        </div>
    );
}

export default stepOneComponent;