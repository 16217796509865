import { styled } from '@mui/material';

export const Wrapper = styled('div')(() => ({
  width: '100%',
  height: '100%',
  minHeight: '15rem',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  border: '1px solid #BBBEF7',
  borderRadius: '0.5rem',
  overflow: 'hidden',
}));

export const ContentWrapper = styled('div')(() => ({
  width: '100%',
  padding: '1rem',
  background: 'linear-gradient(99deg, #F1ECFF 0%, #FEFEFF 52.6%, #F1ECFF 100%)',
}));

export const Content = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  alignItems: 'stretch',
  justifyContent: 'space-between',
  padding: '1.7rem 2.4rem',
  backgroundColor: '#fff',
  border: '1px solid #C2C4FF',
  borderRadius: '1rem',
}));

export const Count = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',

  h5: {
    font: '500 1.75rem Poppins, sans-serif',
    color: '#6250ED',
    margin: 0,
  },

  p: {
    font: '400 1rem Poppins, sans-serif',
    color: '#6250ED',
    margin: 0,
  },
}));

export const OthersCount = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '0.5rem',

  p: {
    font: '400 1rem Poppins, sans-serif',
    color: '#6250ED',
    margin: 0,
  },

  '.others-info': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    svg: {
      fontSize: '1.5rem',
      color: '#6250ED',
      cursor: 'pointer',
    },
  },
}));

export const VerticalSeparator = styled('div')(() => ({
  width: '1px',
  backgroundColor: '#6250ED',
}));

export const Footer = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyContent: 'flex-start',
  gap: '1rem',
  padding: '1rem',
  backgroundColor: '#fff',
}));

export const InteractionsRates = styled('div')(() => ({
  width: '100%',
  display: 'grid',
  gridTemplateColumns: 'repeat(4, 1fr)',
  gap: '1rem',
}));

export const InteractionRateCard = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyContent: 'flex-start',
  padding: '0.75rem',
  background:
    'linear-gradient(99deg, rgba(241, 236, 255, 0.40) 0%, rgba(254, 254, 255, 0.40) 52.6%, rgba(241, 236, 255, 0.40) 100%)',
  border: '1px solid #BBACFF',
  borderRadius: '0.5rem',

  p: {
    font: '400 0.875rem Poppins, sans-serif',
    color: '#667085',
    margin: 0,
  },
}));

export const InteractionRateCardContent = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: '0.5rem',

  h6: {
    font: '500 1.25rem Poppins, sans-serif',
    color: '#101828',
    margin: 0,
  },
}));

export const InteractionRateChange = styled('div')(
  ({ isPositiveChange = false }) => ({
    display: 'flex',
    alignItems: 'center',

    svg: {
      color: isPositiveChange ? '#008243' : '#E00000',
    },

    h6: {
      font: '500 1rem Poppins, sans-serif',
      color: isPositiveChange ? '#008243' : '#E00000',
      margin: 0,
    },
  })
);

export const FooterActions = styled('div')(() => ({
  width: '100%',
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  gap: '1rem',
}));
