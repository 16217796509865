import { Flex } from '@rebass/grid'
import React from 'react'
import styled from 'styled-components'

const ScrollWrapperStyle = styled(Flex)`
    flex-direction: column;
    height: ${({ height }) => height || ''};
    max-height: ${({ maxHeight }) => maxHeight || ''};
    width: ${({ width }) => width || '100%'};
    overflow-x: hidden;
    overflow-y: auto;

    &::-webkit-scrollbar {
        width: 5px;
    }
    
    &::-webkit-scrollbar-track {
        background: #e9f6e6 !important;
    }
    
    &::-webkit-scrollbar-thumb {
        background: #bccdd4 !important;
    }
`;

const ScrollWrapper = ({
    children,
    height,
    maxHeight,
    width,
    styles
}) => {
  return (
    <ScrollWrapperStyle height={height} maxHeight={maxHeight} width={width} style={styles}>
        {children}
    </ScrollWrapperStyle>
  )
}

export default ScrollWrapper