export const transformCampaignInsightsData = (data) => {
  return {
    id: data?.id ?? '',
    openRate: data?.open_rate ?? '-',
    totalClicks: data?.total_clicks ?? '-',
    emailEngagement: data?.email_engagement ?? '-',
    emailData: data?.email_data ?? {
      contacts: '-',
      opens: '-',
      clicks: '-',
      bounces: '-',
    },
  };
};
