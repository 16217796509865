import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';

import useRESTQuery from 'src/hooks/useRESTQuery';

import Table from 'src/componentsV2/Table';
import Pagination from 'src/componentsV2/Pagination';
import Skeleton from '../EmailDeliverability/Skeleton';
import { tableColumnStructure } from './TableColumnStructure';

import { PaginationContainer, TableHeader, TopLeadsContainer } from './styles';
import { EmptyState } from 'src/componentsV2';

const ITEMS_PER_PAGE = 10;

function TopLeads({ campaignId = '', hasSubCampaignSelected = false }) {
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const type = queryParams.get('type');

  const [currentPage, setCurrentPage] = useState(1);

  const { isLoading, data } = useRESTQuery({
    key: 'top-leads',
    endpoint: `/analytics/detailed/campaign/${campaignId}/leads?campaign_type=${type}&is_block=${hasSubCampaignSelected}`,
    dependencies: [campaignId, type, hasSubCampaignSelected],
    options: {
      select: (res) => {
        if (Array.isArray(res?.result)) return res?.result;
        return [];
      },
    },
  });

  if (isLoading) return <Skeleton />;

  return (
    <TopLeadsContainer>
      <TableHeader>
        <h2>All Leads</h2>
      </TableHeader>

      <Table
        data={data?.slice(
          (currentPage - 1) * ITEMS_PER_PAGE,
          (currentPage - 1 + 1) * ITEMS_PER_PAGE
        )}
        columnStructure={tableColumnStructure()}
        customStyles={{
          borderRadius: 'unset',
        }}
         customEmptyState={<EmptyState height="192px" title="No Analytics Data" />}
      />
      <PaginationContainer>
        <Pagination
          pageNumber={currentPage}
          pageSize={Math.ceil(data?.length / ITEMS_PER_PAGE) ?? 1}
          handlePageChange={(_, pageNumber) => {
            setCurrentPage(pageNumber);
          }}
        />
      </PaginationContainer>
    </TopLeadsContainer>
  );
}

export default TopLeads;
