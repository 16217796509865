/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Flex } from '@rebass/grid';
import React, { useEffect } from 'react';
import { StepperV2 } from '../../components/StepperV2/StepperV2';
import { Line } from '../../components/StepperV2/Stepper.styles';
import { Button, LoaderWrapper } from '../../components';
import { useState } from 'react';
import SelectContentModal from '../DripCampaignContainer/PlanCampaign/SelectContentModal';
import { get, isEmpty } from 'lodash';
import { useHistory, useParams } from 'react-router-dom';
import { ColorPallete, ROLE_TYPES, Typography, getExploreArticleData, getMediaImage, getMyContentArticles, nurtureFlag, onImageError, redirectToLogin, toBase64 } from '../../utils';
import LPUploadImageModal from '../DripCampaignContainerV2/SocialPreview/LPUploadImageModal';
import { getAutomatedBlocksById, getAutomatedCampaignById, updateCampaignBlock } from '../../services/automated-campaigns';
import { getCampaignDetails, lpImageUpload } from '../../services/campaign';
import { dataURLtoFile, getBookmarkedArticles, getRecommendedArticles } from '../../services/dashboard';
import { connect } from 'react-redux';
import { StyledTextInput } from '../AutomatedCampaigns/styles';
import { actions as campaignActions } from '../../redux/modules/campaign.module';
import useQuery from '../../customHooks/useQuery';
import { updateSocialBlock } from '../../services/campaign';
import Openai from 'src/assets/icons/Openai';
import { SuggestionScrollWrapper } from './styles';
import useQueryForSocialKeyInsights from 'src/hooks/useQueryForSocialKeyInsights';
import { cloutV2Endpoints } from 'src/services/helpers';
import { oktaHelper } from '../../utils';
import { ErrorDisplay } from 'src/componentsV2/ErrorDisplay';
import Checkbox from 'src/componentsV2/Checkbox';
import * as DOMPurify from 'dompurify';
import { getChannelId, getRoleType } from 'src/redux/selector';

const CreateSocialContainer = ({
  automatedCampaignTargetInfo,
  automatedFlowFromDashboard,
  automatedCampaignDetails,
  campaignSetState,
  themeNames,
  setObjectivesTabData,
  userData,
  bookmarkArticles,
  recommendedArticles,
  myContentPendingQueue,
  myContentArticles,
  isEnterpriseAdvisor,
  isPremiumAdvisor,
  channelId
}) => {

  const { id, campaignId } = useParams();
  const history = useHistory();

  const query = useQuery();

  const stepListLines = [
    {
      label: 'Select Target'
    },
    {
      label: 'Create Campaign Journey',
    },
    {
      label: 'Campaign Overview'
    },
  ];

  const [selectPopup, setSelectPopup] = useState(false);
  const [acData, setAcData] = useState();
  const [selectedContent, setSelectedContent] = useState([]);
  const [selectedPlatform, setSelectedPlatform] = useState('fb');
  const [isChecked, setIsChecked] = useState(true);
  const [summary, setSummary] = useState({
    fb: '',
    tw: '',
    ln: ''
  });
  const [lpModal, setLpModal] = useState(false);
  const [fileType, setFileType] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const [base64Logo, setBase64Logo] = useState('');
  const [blockData, setBlockData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [imageRequired, setImageRequired] = useState(false);
  const [name, setName] = useState('');
  const [campaignData, setCampaignData] = useState();
  const [platformLength, setPlatformLength] = useState(0);
  const [suggestions, setSuggestions] = useState('');
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [suggestionsLoading, setSuggestionsLoading] = useState(false);
  const [suggestionError, setSuggestionError] = useState(false);
  const [streamLoading, setStreamLoading] = useState(false);

  const { data: socialInsights } = useQueryForSocialKeyInsights(userData?.details?.user?.email);

  useEffect(() => {
    const windowBodyClassList = document.body.classList;
    if (!windowBodyClassList.contains('campaign')) {
      windowBodyClassList.add('campaign');
    }
    return () => {
      windowBodyClassList.remove('campaign');
    };
  }, []);

  useEffect(() => {
    //  setLoading(true);
    if (isEmpty(bookmarkArticles) && nurtureFlag !== 'nurture') {
        getBookmarkedArticles()
            .then((res) => {
                //  setLoading(false);
                if (res.result && res.result.data && res.result.data.feed) {
                    let allBookMarkedArticles = getExploreArticleData(
                        res.result.data,
                        'campaign'
                    );
                    if (allBookMarkedArticles.length > 0) {
                        campaignSetState({ bookmarkArticles: allBookMarkedArticles });
                    }
                }
            })
            .catch((err) => {
                //  setLoading(false);
                console.log(err);
            });
    }

    //  setLoading(true);

    if (isEmpty(recommendedArticles) && nurtureFlag !== 'nurture') {
        getRecommendedArticles()
            .then((res) => {
                //  setLoading(false);
                if (res.result && res.result.data && res.result.data.feed) {
                    let allRecommendedArticles = getExploreArticleData(
                        res.result.data,
                        'campaign'
                    );
                    if (allRecommendedArticles.length > 0) {
                        campaignSetState({ recommendedArticles: allRecommendedArticles });
                    }
                }
            })
            .catch((err) => {
                //  setLoading(false);
                console.log(err);
            });
    }

    if (isEmpty(myContentArticles) && nurtureFlag !== 'nurture') {
        getMyContentArticles({
            payload: {
                userType: isEnterpriseAdvisor
                    ? 'isEnterpriseAdvisor'
                    : isPremiumAdvisor
                        ? 'isPremiumAdvisor'
                        : 'isEnterpriseAdmin',
                channelId: channelId,
                beforeToken: null,
                afterToken: null,
                pendingArticles: myContentPendingQueue,
            },
            localData: {
                currentArticles: [],
            },
            source: 'campaign',
        })
            .then((res) => {
                const newState = get(res, 'newState', {});
                if (!isEmpty(newState)) campaignSetState(newState);
            })
            .catch((err) => {
                console.log(
                    '******* ERROR my-content-articles [CREATE CAMPAIGN] ******'
                );
            });
    }
}, []);

useEffect(() => {
    campaignSetState({
        bookmarkArticles: bookmarkArticles,
        recommendedArticles: recommendedArticles,
        myContentArticles: myContentArticles,
    });
}, [bookmarkArticles, recommendedArticles, myContentArticles]);

  const hasFacebook = ((query.get('type') === 'campaign' && campaignData?.is_facebook === "true") || (blockData?.social_pages?.facebook || blockData?.override_text?.fb));
  const hasLinkedin = ((query.get('type') === 'campaign' && campaignData?.is_linkedin === "true") || (blockData.social_pages?.linkedin || blockData?.override_text?.ln));
  const hasTwitter = ((query.get('type') === 'campaign' && campaignData?.is_twitter === "true") || (blockData.social_pages?.twitter || blockData?.override_text?.tw));

  useEffect(() => {
    if (query.get('type') === 'campaign') {
      getCampaignDetails(campaignId).then(res => {
        let plength = 0;
        if (get(res, 'result.data.is_facebook', 'false') === 'true') {
          setSelectedPlatform('fb');
        } else if (get(res, 'result.data.is_linkedin', 'false') === 'true') {
          setSelectedPlatform('ln');
        } else if (get(res, 'result.data.is_twitter', 'false') === 'true') {
          setSelectedPlatform('tw');
        }
        if (get(res, 'result.data.is_facebook', 'false') === 'true') {
          plength++;
        }
        if (get(res, 'result.data.is_linkedin', 'false') === 'true') {
          plength++;
        }
        if (get(res, 'result.data.is_twitter', 'false') === 'true') {
          plength++;
        }
        setPlatformLength(plength);
        setCampaignData(get(res, 'result.data', {}));
        setName(get(res, 'result.data.name', {}));
        let fetchedArticles = [];
        if (get(res, 'result.data.posts_details.fb', false))
          fetchedArticles.push(
            get(res, 'result.data.posts_details.fb[0].articles.feed[0]', {})
          );
        else if (get(res, 'result.data.posts_details.tw', false))
          fetchedArticles.push(
            get(res, 'result.data.posts_details.tw[0].articles.feed[0]', {})
          );
        else if (get(res, 'result.data.posts_details.ln', false))
          fetchedArticles.push(
            get(res, 'result.data.posts_details.ln[0].articles.feed[0]', {})
          );
        const selectedArticles = !isEmpty(fetchedArticles) ? fetchedArticles.map((article) => ({
          id: article.id.includes('LP__') ? article.id.split('__')[1] : article.id,
          url: getMediaImage(get(article, 'media_urls[0]', [])),
          themeId: get(article, 'ids.theme[0]', ''),
          description: get(article, 'text', ''),
          topic: get(article, 'auxiliary.title', ''),
          place: themeNames && themeNames[get(article, 'ids.theme[0]', '')],
          link: get(article, 'links.social_permalink', ''),
          year: '',
          date: '',
          type: article.id.includes('LP__') ? 'lp' : ''
        })) : [];
        setSelectedContent(selectedArticles)
        let fbSummary = get(res, 'result.data.posts.facebook.override_text', '');
        let lnSummary = get(res, 'result.data.posts.linkedin.override_text', '');
        let twSummary = get(res, 'result.data.posts.twitter.override_text', '');
        setSummary({
          fb: fbSummary,
          ln: lnSummary,
          tw: twSummary
        })
      }).catch(err => {
        console.log(err)
      })
    } else {
      getAutomatedBlocksById(campaignId, id).then(res => {
        let plength = 0;
        setBlockData(res.data);
        if (res?.data?.override_text) {
          setSummary(res.data.override_text);
        }
        if (res?.data?.social_pages?.facebook) {
          setSelectedPlatform('fb');
        } else if (res?.data?.social_pages?.linkedin) {
          setSelectedPlatform('ln');
        } else {
          setSelectedPlatform('tw');
        }

        if (res?.data?.social_pages?.facebook) {
          plength++;
        }
        if (res?.data?.social_pages?.linkedin) {
          plength++;
        }
        if (res?.data?.social_pages?.twitter) {
          plength++;
        }
        setPlatformLength(plength);
        setName(res.data?.name);
        setSelectedContent(
          !isEmpty(res.data.articles) ?
            [{
              url: getMediaImage(res.data.media_urls),
              topic: res.data.articles[0].title,
              id: res.data.articles[0].id,
              description: res.data.articles[0].description,
              link: res.data.articles[0].link,
              themeId: res.data.articles[0].theme_id,
              type: res.data.articles[0].id.includes('LP__') ? "lp" : ""
            }] : [])
      }).catch(err => console.log(err));
      getAutomatedCampaignById({ campaignId }).then(res => {
        setAcData(res.data);
      }).catch(err => console.log(err));
    }
  }, [])


  const handlePopupClose = () => {
    setSelectPopup(false);
  };

  const getSummaryValue = () => {
    if (summary[selectedPlatform]) {
      return summary[selectedPlatform];
    } else {
      return '';
    }
  }

  const handleSummaryChange = (e) => {
    if (isChecked) {
      setSummary({
        ...(hasFacebook && {
          fb: e.target.value
        }),
        ...(hasLinkedin && {
          ln: e.target.value
        }),
        ...(hasTwitter && {
          tw: e.target.value
        }),
      })
    } else {
      setSummary({
        ...summary,
        [selectedPlatform]: e.target.value
      })
    }
  };


  const onFileUploadChange = (logofile) => {
    setFileType(logofile[0].name.split('.').pop());
    setErrorMsg('');
    toBase64(logofile[0]).then((file) => {
      setBase64Logo(file);
      setSelectedContent([{ ...selectedContent[0], url: file }]);
    });
  };

  const handleLpImageUpload = () => {
    setLpModal(true);
  }

  const handleSaveBlock = async () => {
    if (selectedContent[0].type === 'lp' && isEmpty(get(selectedContent[0], 'url', ''))) {
      setImageRequired(true);
      return;
    }
    setLoading(true);
    let articles = !isEmpty(selectedContent)
      ? selectedContent.map((item, index) => {
        return {
          id: item?.type === 'lp' ? `LP__${item?.id}` : item?.id,
          theme_id: typeof item?.themeId === 'string' || Array.isArray(item?.themeId) || typeof item?.themeId === 'number' ?
            item.themeId : item?.themeId?.id,
        };
      })
      : [];

    let twPayload = {
      articles: articles,
      override_text: summary.tw,
    }

    let fbPayload = {
      articles: articles,
      override_text: summary.fb,
    }

    let lnPayload = {
      articles: articles,
      override_text: summary.ln,
    }

    let lpResponse;
    if (articles && articles[0]?.id?.includes('LP__')) {
      let payload = {
        id: articles[0].id.split('__')[1],
        ...(query.get('type') === 'campaign') && {
          campaignId
        },
        ...(query.get('type') !== 'campaign') && {
          ac_block_id: id
        },
        media1: base64Logo !== '' ? dataURLtoFile(
          base64Logo,
          `filename.${fileType}`
        ) : selectedContent[0].url
      }
      lpResponse = await lpImageUpload(payload);
    }

    let posts = {};
    if (query.get('type') === 'campaign') {
      posts = {
        ...(!isEmpty(setObjectivesTabData?.fbList) || hasFacebook) && {
          "facebook": fbPayload
        },
        ...(!isEmpty(setObjectivesTabData?.twList) || hasTwitter) && {
          "twitter": twPayload
        },
        ...(!isEmpty(setObjectivesTabData?.lnList) || hasLinkedin) && {
          "linkedin": lnPayload
        },

      }
    }
    const payload = query.get('type') === 'campaign' ? {
      name,
      posts: JSON.stringify(posts),
      ...(articles && articles[0]?.id?.includes('LP__')) && {
        lp_data: JSON.stringify({
          media_urls: lpResponse?.result?.data[0],
          custom_text: selectedContent[0].topic
        })
      }
    } : {
      content: {
        ...(blockData?.social_pages?.facebook || blockData?.override_text?.fb) && {
          fb: fbPayload,
        },
        ...(blockData?.social_pages?.twitter || blockData?.override_text?.tw) && {
          tw: twPayload,
        },
        ...(blockData?.social_pages?.linkedin || blockData?.override_text?.ln) && {
          ln: lnPayload,
        },
        ...(articles && articles[0]?.id?.includes('LP__')) && {
          lp_data: JSON.stringify({
            media_urls: lpResponse?.result?.data[0],
            custom_text: selectedContent[0].topic
          })
        }
      },
      platform: "social",
      name
    }
    if (query.get('type') === 'campaign') {
      updateSocialBlock(campaignId, payload).then(res => {
        setLoading(false);
        campaignSetState({
          fromSocialBlock: true,
          isEdit: true
        })
        history.push(`/campaignV2?type=create`);
      }).catch(err => {
        console.log(err);
        setLoading(false);
      })
    } else {
      updateCampaignBlock({ campaignId, blockId: id, payload }).then(res => {
        setLoading(false);
        campaignSetState({
          fromSocialBlock: true,
        })
        history.push(`/campaignV2?type=create`);
      }).catch(err => {
        console.log(err);
        setLoading(false);
      })
    }
  }

  const getBody = () => {
    if (!isEmpty(summary.fb)) {
      return summary.fb;
    } else if (!isEmpty(summary.ln)) {
      return summary.ln;
    } else {
      return summary.tw;
    }
  }

  const getSuggestions = async () => {
    try {
      setShowSuggestions(true);
      setSuggestionError(false);
      setStreamLoading(true);
      setSuggestionsLoading(true);
      let body = getBody();
      let payload = {
        title: selectedContent[0].topic,
        custom_text: body && body.slice(0, 500),
        body: selectedContent[0].description,
        insights: {
          "caption sentiment": socialInsights?.result['caption sentiment'],
          "caption length": socialInsights?.result['caption length'],
          "number of hashtags": socialInsights?.result['number of hashtags'],
        },
        type: 'stream_test'
      }

      const response = await fetch(`${cloutV2Endpoints.baseUrl}/recommendation/openai_recommendations`, {
        method: "post",
        headers: {
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
          "Authorization": `Bearer ${window.sessionStorage.getItem('token') || oktaHelper.getAccessToken()}`
        },
        body: JSON.stringify(payload),
      });

      if(response.status === 401) {
        redirectToLogin();
      }

      if (!response.ok || !response.body) {
        throw response.statusText;
      }

      // Here we start prepping for the streaming response
      const reader = response.body.getReader();
      const decoder = new TextDecoder();
      const loopRunner = true;

      let tempData = '';

      while (loopRunner) {
        // Here we start reading the stream, until its done.
        const { value, done } = await reader.read();
        if (done) {
          setStreamLoading(false);
          break;
        }
        let decodedChunk = decoder.decode(value, { stream: true });
        decodedChunk = decodedChunk.replace(/\\/g, '');
        tempData = tempData + decodedChunk;
        setSuggestions(tempData);
        setSuggestionsLoading(false);// update state with new chunk
      }

      // await fetchEventSource(`http://localhost:8000/v1.4/recommendation/openai_recommendations`, {
      //   method: "POST",
      //   headers: { Accept: "text/event-stream", authorization: `Bearer ${window.sessionStorage.getItem('token') || oktaHelper.getAccessToken()}` },
      //   body: JSON.stringify(payload),
      //   onopen(res) {
      //     if (res.ok && res.status === 200) {
      //       console.log("Connection made ", res);
      //     } else if (res.status >= 400 && res.status < 500 && res.status !== 429) {
      //       console.log("Client-side error ", res);
      //     }
      //   },
      //   onmessage(event) {
      //     console.log(event.data);
      //     const parsedData = JSON.parse(event.data);
      //     setSuggestions((data) => [...data, parsedData]); // Important to set the data this way, otherwise old data may be overwritten if the stream is too fast
      //   },
      //   onclose() {
      //     console.log("Connection closed by the server");
      //   },
      //   onerror(err) {
      //     console.log("There was an error from server", err);
      //   },
      // });

      //  fetchOpenAiStreamingSuggestions(payload).then(res => {
      //   console.log(res)
      //   let suggestions = res.replace(/\\/g, '').replace(/\n/, '');
      //   setSuggestions(suggestions);
      //   setSuggestionsLoading(false);
      // }).catch(err => {
      //   console.log(err);
      //   setShowSuggestions(false);
      //   setSuggestionsLoading(false);
      // })


      // fetchOpenAiSuggestions(payload).then(res => {
      //   let suggestions = res?.result?.replace(/\\/g, '').replace(/\n/, '');
      //   setSuggestions(suggestions);
      //   setSuggestionsLoading(false);
      // }).catch(err => {
      //   console.log(err);
      //   setShowSuggestions(false);
      //   setSuggestionsLoading(false);
      // })

    } catch (error) {
      setSuggestionsLoading(false);
      console.log(error);
      setSuggestionError(true);

    }
  }

  const handleArticleChange = (article) => {
    setSelectedContent(article);
    setShowSuggestions(false)
    setSuggestions('')
  }

  const goBack = () => {
    campaignSetState({
      isEdit: true
    })
    if (automatedFlowFromDashboard) {
      history.push('/campaignV2')
    } else {
      history.push('/campaignV2?type=create');
    }
  }

  const handleValidation = () => {
    let valid = false;
    if (isEmpty(selectedContent)) {
      if (!valid) {
        valid = true
      }
    }

    if (isEmpty(name)) {
      if (!valid) {
        valid = true
      }
    }

    if ((blockData?.social_pages?.facebook || blockData?.override_text?.fb || !isEmpty(setObjectivesTabData?.fbList)) && isEmpty(summary.fb)) {
      if (!valid) {
        valid = true
      }
    }

    if ((blockData?.social_pages?.linkedin || blockData?.override_text?.ln || !isEmpty(setObjectivesTabData?.lnList)) && isEmpty(summary.ln)) {
      if (!valid) {
        valid = true
      }
    }

    if ((blockData?.social_pages?.twitter || blockData?.override_text?.tw || !isEmpty(setObjectivesTabData?.twList)) && isEmpty(summary.tw)) {
      if (!valid) {
        valid = true
      }
    }

    return valid;
  }

  return (
    <Box width={1}>
      <StepperV2 stepperList={stepListLines} selectedIndex={1} />

      <Flex width="80%" margin="0px auto" flexDirection="column">
        <Flex width="100%" style={{ position: 'relative' }} alignItems="center">
          <Flex width="2%">
            <img style={{ cursor: 'pointer' }} src="/assets/images/Campaign/left-arrow-icon.svg" onClick={goBack} alt="" />
          </Flex>
          <Flex width="2%">
            <Line style={{ top: '2px' }} />
          </Flex>
          <Flex width="60%" color="#101828" fontSize="20px">
            Customize Social
          </Flex>
          <Flex>
            <LoaderWrapper isLoading={loading}>
              {process.env.REACT_APP_API_ENV !== 'abcd' && (
                <Button
                  margin="0px"
                  padding="8px 16px"
                  height="40px"
                  borderRadius="4px"
                  width="160px"
                  bgColor={ColorPallete.white}
                  color={ColorPallete.primaryBlue}
                  borderColor={ColorPallete.pink}
                  disabled={handleValidation() || streamLoading}
                  styles={{
                    position: 'relative'
                  }}
                  onClick={getSuggestions}
                >
                  <Flex width="100%" justifyContent="space-between" alignItems="center">
                    <Openai />
                    Review with AI
                  </Flex>
                  <Flex
                    style={{
                      position: 'absolute',
                      borderRadius: '12px',
                      border: '1px solid #B6CFF3',
                      bottom: '30px',
                      right: '2px'
                    }}
                    fontSize="10px"
                    backgroundColor="#ECF2FC"
                    padding="4px 8px"
                    color="#0B5CD7"
                  >
                    Beta
                  </Flex>

                </Button>
              )}

              <Button
                color="#fff"
                bgColor="#6351ed"
                borderRadius="4px"
                padding="7px 45px"
                margin="0px 0px 7px 10px"
                onClick={handleSaveBlock}
                testId="select-social-article"
                disabled={handleValidation()}
                height="40px"
              >
                Save & Continue
              </Button>
            </LoaderWrapper>
          </Flex>
        </Flex>
        <hr style={{ width: '100%', border: '1px solid #E4E7EC' }} />
        <Flex
          width="100%"
          backgroundColor="#fff"
          style={{ border: '1px solid #E4E7EC', borderRadius: '8px' }}
          justifyContent="center"
          flexDirection="column"
          padding="15px"
          mt="15px"
          height="200px"
        >
          {isEmpty(selectedContent) ? (
            <Flex flexDirection="column" height="100%" alignItems="center">
              <Flex>
                <img style={{
                  border: imageRequired ? '1px solid red' : ''
                }} src="/assets/images/Campaign/No-Content-Icon.svg" alt="" />
              </Flex>
              <Flex pt="10px" color="#000000" fontSize="14px">
                No Content has been selected
              </Flex>
              <Button
                color="#6351ed"
                bgColor="#fff"
                borderRadius="5px"
                padding="7px 50px"
                margin="10px 0px 7px 0px"
                onClick={() => {
                  setSelectPopup(true);
                }}
                testId="select-social-article"
              >
                Add Content
              </Button>
            </Flex>
          ) : (
            <Flex width="100%" height="100%">
              <Flex width="18%">
                <img style={{ width: "100%", objectFit: "cover", height: "100%", border: imageRequired ? '1px solid red' : '' }} src={!isEmpty(selectedContent[0].url) ? selectedContent[0].url : '/assets/images/Campaign/Default-image.png'} onError={onImageError} alt="" />
              </Flex>
              <Flex width="82%" pl="15px" flexDirection="column" style={{ display: 'inline-block', textAlign: 'left' }}>
                <Flex width="100%" alignItems="baseline">
                  <Flex width={get(selectedContent, '[0].type', '') === 'lp' ? "55%" : "65%"} color="#000000" fontSize="20px" style={{ fontWeight: 500 }}>
                    {!isEmpty(selectedContent[0].topic) && selectedContent[0].topic}
                  </Flex>
                  <Flex width={get(selectedContent, '[0].type', '') === 'lp' ? "67%" : "35%"}>
                    <Button
                      style={{ border: '1px solid #E4E7EC', borderRadius: '8px' }}
                      color="#6351ed"
                      bgColor="#fff"
                      margin="0px 10px"
                      borderRadius="5px"
                      onClick={() => window.open(selectedContent[0].link, '_blank')}
                      padding="7px 19px"
                    >
                      {selectedContent[0].type === 'lp' ? 'View Landing Page' : 'View Article'}
                    </Button>
                    <Button
                      onClick={() => {
                        setSelectPopup(true);
                      }}
                      style={{ border: '1px solid #E4E7EC', borderRadius: '8px' }}
                      color="#6351ed"
                      bgColor="#fff"
                      borderRadius="5px"
                      margin="0px 10px 0px 0px"
                      padding="7px 19px"
                    >
                      Change Article
                    </Button>
                    {selectedContent[0].type === 'lp' && (
                      <Button
                        style={{ border: '1px solid #E4E7EC', borderRadius: '8px' }}
                        color="#6351ed"
                        bgColor="#fff"
                        margin="0px"
                        borderRadius="5px"
                        onClick={handleLpImageUpload}
                        padding="7px 19px"
                      >
                        Upload Image
                      </Button>
                    )}
                  </Flex>
                </Flex>
                <Flex pt="10px" width="50%" color="#000000" fontSize="16px">
                  {!isEmpty(selectedContent[0].description) && selectedContent[0].description.length > 150 ? selectedContent[0].description.substring(0, 150) + '...' : !isEmpty(selectedContent[0].description) && selectedContent[0].description.length < 60 ? selectedContent[0].description : null}
                </Flex>
              </Flex>
            </Flex>
          )}
        </Flex>
        <Flex width="100%" justifyContent="space-between">
          <Flex width="40%" fontSize="14px" margin="10px 0px" flexDirection="column">
            <Box width="100%" className="field__title" color="#000000" style={{ textAlign: 'left' }}>
              Name
            </Box>
            <Box width="100%" pt="8px">
              <StyledTextInput
                maxLength="1000"
                placeholder="Name Social Block"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </Box>
            <Flex margin="10px 0px" color={ColorPallete.black}>
              <Checkbox
                checked={isChecked}
                onClick={() => {
                  if (isChecked) {
                    setIsChecked(false);
                  } else {
                    setIsChecked(true);
                    setSummary({
                      fb: summary[selectedPlatform],
                      tw: summary[selectedPlatform],
                      ln: summary[selectedPlatform]
                    })
                  }
                }}
              />
              &nbsp;
              Use same copy for all platforms
            </Flex>
            <Flex width="100%" mt="10px">
              {hasFacebook && !isChecked && (
                <Flex
                  width="25%"
                  backgroundColor={selectedPlatform === 'fb' ? '#816EF8' : '#fff'}
                  color={selectedPlatform === 'fb' ? '#fff' : '#101828'}
                  padding="10px"
                  style={{
                    borderTopLeftRadius: '8px',
                    borderBottomLeftRadius: '8px',
                    borderTopRightRadius: !hasLinkedin && !hasTwitter ? '8px' : '',
                    borderBottomRightRadius: !hasLinkedin && !hasTwitter ? '8px' : '',
                    border:
                      selectedPlatform === 'fb'
                        ? '1px solid #816EF8'
                        : '1px solid #E4E7EC',
                    cursor: 'pointer',
                  }}
                  onClick={() => setSelectedPlatform('fb')}
                >
                  Facebook
                </Flex>
              )}
              {hasLinkedin && !isChecked && (
                <Flex
                  width="25%"
                  padding="10px"
                  backgroundColor={selectedPlatform === 'ln' ? '#816EF8' : '#fff'}
                  color={selectedPlatform === 'ln' ? '#fff' : '#101828'}
                  style={{
                    borderTopRightRadius: !hasTwitter ? '8px' : '',
                    borderBottomRightRadius: !hasTwitter ? '8px' : '',
                    borderTopLeftRadius: !hasFacebook ? '8px' : '',
                    borderBottomLeftRadius: !hasFacebook ? '8px' : '',
                    border:
                      selectedPlatform === 'ln'
                        ? '1px solid #816EF8'
                        : '1px solid #E4E7EC',
                    cursor: 'pointer',
                  }}
                  onClick={() => setSelectedPlatform('ln')}
                >
                  LinkedIn
                </Flex>
              )}
              {hasTwitter && !isChecked && (
                <Flex
                  width="25%"
                  padding="10px"
                  backgroundColor={selectedPlatform === 'tw' ? '#816EF8' : '#fff'}
                  color={selectedPlatform === 'tw' ? '#fff' : '#101828'}
                  style={{
                    borderTopRightRadius: '8px',
                    borderBottomRightRadius: '8px',
                    borderTopLeftRadius: !hasLinkedin && !hasFacebook ? '8px' : '',
                    borderBottomLeftRadius: !hasLinkedin && !hasFacebook ? '8px' : '',
                    border:
                      selectedPlatform === 'tw'
                        ? '1px solid #816EF8'
                        : '1px solid #E4E7EC',
                    cursor: 'pointer',
                  }}
                  onClick={() => setSelectedPlatform('tw')}
                >
                  Twitter
                </Flex>
              )}

            </Flex>
            <Flex width="100%" justifyContent="space-between">
              <Flex width="100%" mt={isChecked ? "0px" : "15px"} height="275px" flexDirection="column">
                <textarea
                  value={getSummaryValue()}
                  onChange={(e) => handleSummaryChange(e)}
                  style={{
                    width: '100%',
                    height: '100%',
                    borderRadius: '4px',
                    padding: '10px 15px',
                    color: '#4E6780',
                    border: '1px solid #c9d0d8',
                    resize: 'none',
                    maxHeight: '260px',
                    maxWidth: '760px',
                    fontFamily: 'Poppins'
                  }}
                />
              </Flex>

            </Flex>
          </Flex>
          <Flex width="45%" mt="10px">
            {showSuggestions && (
              <Flex
                width="100%"
                flexDirection="column"
                test-id="show-suggestions"
                height="400px"
                style={{
                  border: `1px solid ${ColorPallete.pink}`,
                  borderRadius: '4px',

                }}
                padding="16px"
                bg="#F6F2FF"
              >
                <Flex width="100%" justifyContent="space-between">
                  <Flex width="80%">
                    <Flex width="10%">
                      <Openai height="25px" width="25px" />
                    </Flex>
                    <Flex
                      fontSize={Typography.Heading}
                      style={{
                        fontFamily: 'Poppins-medium'
                      }}
                    >
                      AI Suggestions
                    </Flex>
                  </Flex>
                  <Flex
                    width="10%"
                    style={{
                      borderRadius: '12px',
                      border: '1px solid #B6CFF3',
                    }}
                    fontSize={Typography.TextSmall}
                    backgroundColor="#ECF2FC"
                    padding="4px 8px"
                    color="#0B5CD7"
                  >
                    Beta
                  </Flex>
                </Flex>
                <Flex
                  width="100%"
                  height="90%"
                  backgroundColor={ColorPallete.white}
                  style={{
                    border: '1px solid #A795FF',
                    borderRadius: '4px'
                  }}
                  padding="12px"
                  flexDirection="column"
                  mt="12px"
                >
                  <LoaderWrapper isLoading={suggestionsLoading}>
                    {!suggestionError ? (
                      <SuggestionScrollWrapper
                        fontSize={Typography.TextMedium}
                        color={ColorPallete.neutral600}
                        dangerouslySetInnerHTML={{ __html:  DOMPurify.sanitize(suggestions) }}
                      >
                      </SuggestionScrollWrapper>
                    ) : (
                      <ErrorDisplay
                        onClick={getSuggestions}
                        height="180px"
                      />
                    )}

                  </LoaderWrapper>
                </Flex>
              </Flex>
            )}
          </Flex>
        </Flex>
      </Flex>
      {selectPopup && (
        <SelectContentModal
          showModal={selectPopup}
          toggleModal={setSelectPopup}
          handleClose={handlePopupClose}
          campaignSelectedArticles={[]}
          setSelectedContent={handleArticleChange}
          selectedContent={selectedContent}
          acSelectedLandingPage={acData?.target_page?.id}
          acflow={query.get('type') !== 'campaign'}
          id={id}
          campaignId={campaignId}
        />
      )}
      {lpModal && (
        <LPUploadImageModal
          isOpen={lpModal}
          handleClose={() => setLpModal(false)}
          articleData={
            isEmpty(selectedContent) ? selectedContent : selectedContent[0]
          }
          onFileUploadChange={onFileUploadChange}
          setErrorMsg={setErrorMsg}
          setSelectedContent={setSelectedContent}
          changeTitle={(title) => setSelectedContent([{ ...selectedContent[0], topic: title }])}
        />
      )}
    </Box>
  );
}

const mapStateToProps = state => {
  const roleType = getRoleType(state);
  const isEnterpriseAdvisor = roleType === ROLE_TYPES.ADVISOR_ENTERPRISE;
  const isPremiumAdvisor = roleType === ROLE_TYPES.ADVISOR_PREMIUM;
  return {
    automatedCampaignTargetInfo: get(
      state,
      'campaign.automatedCampaigns.targetInfo',
      0
    ),
    automatedFlowFromDashboard: get(state,
      'campaign.automatedFlowFromDashboard',
      null),
    automatedCampaignDetails: get(state, 'campaign.automatedCampaigns', {}),
    themeNames: state.theme.themeNames,
    setObjectivesTabData: state.campaign.setObjectives,
    userData: state?.user?.data,
    bookmarkArticles: state.campaign.bookmarkArticles,
    recommendedArticles: state.campaign.recommendedArticles,
    myContentArticles: state.campaign.myContentArticles,
    myContentPendingQueue: get(state, 'campaign.pendingArticles', []),
    channelId: getChannelId(state),
    isEnterpriseAdvisor: isEnterpriseAdvisor,
    isPremiumAdvisor: isPremiumAdvisor,
  }
}

const mapDispatchToProps = {
  campaignSetState: campaignActions.api.campaign.setstate,
};
export default connect(mapStateToProps, mapDispatchToProps)(CreateSocialContainer);