import React from 'react'
import { AlertStyles } from './style';

function AlertBox({
    message,
    severity
}) {
  return (
    <AlertStyles severity={severity}>
      {message}
    </AlertStyles>
  )
}

export default AlertBox