/* eslint-disable react-hooks/exhaustive-deps */
import { Flex } from '@rebass/grid';
import { isEmpty, uniq, filter } from 'lodash';
import React, { useEffect, useState } from 'react';

import {
  VerticalSeparator,
  HorizontalSeparator,
  Icon,
  Title,
  ValueSpan,
  HorizontalSeparatorSolid,
  Chips,
  ContentSection,
  ContentPanel,
  ContentPanelSection,
  DayPanel,
  DayPanelContentSection,
  DetailTabs,
  DripDetailsWrapper,
} from './DripDashboardDetails.styles';
import {
  ButtonNoBorderBackground,
  LoaderWrapper,
} from '../../../../components';
import moment from 'moment';
import { getMediaImage, getMediaUrl, onImageError } from '../../../../utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import CampaignCard from '../../PlanCampaign/CampaignCard';
import { SetTopicsHr } from '../../SetTopics/SetTopics.styles';

const DripDashboardDetails = ({
  info,
  contactGroups,
  socialNetwork,
  socialThemes,
  campaignsDetailsData,
  editCampaignHandler,
  editLoader,
  detailsHandler,
  campaignData,
}) => {
  const [emailList, setEmailList] = useState([]);

  const [filteredData, setFilteredData] = useState();
  const [selectedWeek, setSelectedWeek] = useState('');
  const [weekArticlesData, setWeekArticlesData] = useState('');
  const [selectedDays, setSelectedDays] = useState([]);
  const [tab, setTab] = useState('tab0');

  const onWeekClick = (week) => {
    if (selectedWeek === week) setSelectedWeek('');
    else setSelectedWeek(week);

    setWeekArticlesData(filteredData[week]);
  };

  const onDayClick = (day) => {
    if (selectedDays.includes(day))
      setSelectedDays([...selectedDays].filter((item) => item !== day));
    else setSelectedDays(uniq([...selectedDays, day]));
  };

  const detailsLabel = ['Start Date', 'Duration', 'Frequency', 'Target'];
  const details = {
    startDate: info?.data?.start_date
      ? moment(info?.data?.start_date).format('DD MMM YYYY')
      : '-',
    duration: info?.data?.duration ? info?.data?.duration.join(' ') : '-',
    frequency: info?.data?.frequency ? info?.data?.frequency.join(' ') : '-',
    target: info?.data?.target
      ? info?.data?.target.charAt(0).toUpperCase() + info?.data?.target.slice(1)
      : '-',
  };

  useEffect(() => {
    if (info?.data) {
      var uniqueResultOne = contactGroups.filter(function (obj) {
        return info?.data?.email?.some(function (obj2) {
          return obj.id === obj2.destination_id;
        });
      });
      setEmailList(uniqueResultOne);
    }
  }, [info]);

  useEffect(() => {
    setSelectedWeek('');
    setSelectedDays([]);
    if (campaignData?.contentWeeks) {
      let weekWiseData = {};
      setFilteredData(weekWiseData);
      console.log('weekWiseData', weekWiseData);
    }
  }, [campaignData?.contentWeeks]);


  const handleTabs = (mode) => {
    setTab(mode);
    let el = document.getElementById(mode);
    el && el.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };

  return (
    <LoaderWrapper isLoading={info?.isFetching} styles={{ height: 'auto' }}>
      <DripDetailsWrapper
        justifyContent="flex-start"
        display="flex"
        flexDirection="column"
        alignItems="center"
        width="100%"
        style={{
          marginLeft: '5px',
          // border: '1px solid #DBE0E6',
          // borderRadius: '4px',
          backgroundColor: '#F6F9FB',
          // height: '620px',
          height: 'calc(100vh - 80px)',
          overflowX: 'hidden',
          overflowY: 'scroll',
        }}
      >
        <Flex
          justifyContent="flex-start"
          display="flex"
          flexDirection="row"
          padding="30px"
          width="inherit"
          style={{
            backgroundColor: '#fff',
          }}
        >
          <img
            alt=""
            src={getMediaImage(info?.data?.media_urls)}
            width="140px"
            height="100px"
            style={{ borderRadius: '5px' }}
            onError={onImageError}
          />
          <Flex
            flexDirection="column"
            justifyContent="center"
            alignItems="flex-start"
            marginLeft="15px"
            width="inherit"
          >
            <Flex
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
              display="flex"
              width="inherit"
            >
              <Flex>
                <span
                  style={{
                    fontSize: '15px',
                    color: '#000000',
                    fontWeight: '600',
                    textAlign: 'left',
                  }}
                >
                  {info?.data && info?.data?.status
                    ? (
                        info?.data?.status.charAt(0).toUpperCase() +
                        info?.data?.status.slice(1)
                      ).replace('-', ' ')
                    : '-'}{' '}
                  Campaign - {info?.data?.name}
                </span>
              </Flex>
              <Flex>
                <LoaderWrapper
                  isLoading={editLoader}
                  styles={{ paddingRight: '7px', paddingTop: '7px' }}
                >
                  <Icon
                    src="/assets/images/drip/edit.svg"
                    alt=""
                    onmouseover="/assets/images/drip/edit_v.svg"
                    onClick={
                      info?.data && info?.data?.status !== 'completed'
                        ? editCampaignHandler
                        : ''
                    }
                    disabled={info?.data && info?.data?.status === 'completed'}
                  />
                </LoaderWrapper>
                {/* <Icon
                  src="/assets/images/drip/copy.svg"
                  onmouseover="/assets/images/drip/copy_v.svg"
                  alt=""
                />
                <Icon
                  src="/assets/images/drip/delete.svg"
                  onmouseover="/assets/images/drip/delete_v.svg"
                  alt=""
                /> */}
              </Flex>
            </Flex>
            <Flex justifyContent="center" alignItems="center" marginTop="5px">
              {Object.keys(details).map((ele, index) => (
                <>
                  <Flex
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="flex-start"
                    marginRight="25px"
                  >
                    <span style={{ color: '#4E6780', fontSize: '12px' }}>
                      {detailsLabel[index]}
                    </span>
                    <span
                      style={{
                        color: '#000000',
                        fontSize: '13px',
                        fontWeight: '600',
                      }}
                    >
                      {details[ele]}
                    </span>
                  </Flex>
                </>
              ))}
            </Flex>
          </Flex>
        </Flex>
        <Flex
          width="inherit"
          flexDirection="row"
          justifyContent="flex-start"
          alignItems="center"
          display="flex"
          padding="20px"
        >
          {info?.data && info?.data?.status !== 'completed' ? (
            <>
              <DetailTabs
                active
                completed
                onClick={() => {
                  handleTabs('tab0');
                }}
              >
                Objectives
              </DetailTabs>
              <DetailTabs
                // active={tab == 'tab1'}
                // completed={tab == 'tab1'}
                onClick={() => {
                  handleTabs('tab1');
                }}
              >
                Topics
              </DetailTabs>
              <DetailTabs
                // active={tab == 'tab2'}
                // completed={tab == 'tab2'}
                onClick={() => {
                  handleTabs('tab2');
                }}
              >
                Campaign
              </DetailTabs>
            </>
          ) : (
            <DetailTabs
              active
              completed
              onClick={() => {
                handleTabs('tab0');
              }}
            >
              Details
            </DetailTabs>
          )}
          {info?.data &&
            (info?.data?.status === 'in_progress' ||
              info?.data?.status === 'completed') && (
              <DetailTabs
                // active={tab == 'tab2'}
                // completed={tab == 'tab2'}
                // onClick={() => {
                //   handleTabs('tab2');
                // }}
                disabled
              >
                Progress
              </DetailTabs>
            )}
          {/* <Button
            styles={{
              border: '1px solid #708892',
              borderRadius: '5px',
              color: '#708892',
            }}
            color="blue"
            bgColor="#fff"
            margin="0px 15px 0px 0px"
            onClick={() => {}}
          >
            Objectives
          </Button> */}
        </Flex>
        <Flex width="inherit">
          <HorizontalSeparator width="inherit" />
        </Flex>
        <Flex
          id="tab0"
          width="98%"
          flexDirection="column"
          justifyContent="flex-start"
          alignItems="center"
          display="flex"
          margin="10px"
          style={{
            borderRadius: '4px',
            border: '1px solid #DBE0E6',
            backgroundColor: '#fff',
          }}
        >
          <Flex
            flexDirection="row"
            justifyContent="flex-start"
            alignItems="center"
            width="inherit"
            padding="15px"
          >
            <Flex
              flexDirection="column"
              justifyContent="space-between"
              alignItems="flex-start"
              display="flex"
            >
              <Title>Target</Title>
              <ValueSpan>
                {info?.data
                  ? info?.data?.target.charAt(0).toUpperCase() +
                    info?.data?.target.slice(1)
                  : '-'}
              </ValueSpan>
            </Flex>
            <Flex>
              <VerticalSeparator />
            </Flex>
            <Flex
              flexDirection="column"
              justifyContent="space-between"
              alignItems="flex-start"
              display="flex"
            >
              <Title>Purpose</Title>
              <ValueSpan>{info?.data ? info?.data?.purpose : '-'}</ValueSpan>
            </Flex>
          </Flex>
          <Flex width="100%">
            <HorizontalSeparatorSolid width="100%" />
          </Flex>
          <Flex
            flexDirection="column"
            justifyContent="flex-start"
            alignItems="flex-start"
            width="inherit"
            padding="15px"
          >
            <Title>Platform</Title>
            <Flex
              flexDirection="row"
              justifyContent="flex-start"
              alignItems="center"
              width="inherit"
            >
              <Flex width="125px" alignItems="center">
                <img
                  alt=""
                  src="/assets/images/campaign-platform-email.svg"
                  style={{
                    marginRight: '10px',
                  }}
                />
                <ValueSpan fontSize="12px">Email</ValueSpan>
              </Flex>

              <Flex
                flexWrap="wrap"
                justifyContent="space-between"
                alignItems="space-between"
              >
                {!isEmpty(emailList) &&
                  emailList.map((ele, index) => {
                    return <Chips>{ele.name}</Chips>;
                  })}
                {isEmpty(emailList) && <ValueSpan fontSize="12px">-</ValueSpan>}
              </Flex>
            </Flex>
            <Flex width="98%">
              <HorizontalSeparatorSolid width="100%" margin="10px 0px" />
            </Flex>
            <Flex
              flexDirection="row"
              justifyContent="flex-start"
              alignItems="center"
              width="inherit"
            >
              <Flex width="125px" alignItems="center">
                <img
                  alt=""
                  src="/assets/images/campaign-platform-facebook.png"
                  style={{
                    marginRight: '10px',
                  }}
                />
                <ValueSpan fontSize="12px">Facebook</ValueSpan>
              </Flex>
              <Flex
                flexWrap="wrap"
                justifyContent="space-between"
                alignItems="space-between"
              >
                {info?.data &&
                  info?.data?.facebook &&
                  info?.data?.facebook.map((ele, index) => {
                    return (
                      <Chips>
                        {socialNetwork?.data?.fb[0]?.page?.[ele.destination_id]
                          ? socialNetwork?.data?.fb[0]?.page?.[
                              ele.destination_id
                            ].details?.name
                          : '-'}
                      </Chips>
                    );
                  })}
                {info?.data && isEmpty(info?.data?.facebook) && (
                  <ValueSpan fontSize="12px">-</ValueSpan>
                )}
              </Flex>
            </Flex>
            <Flex width="98%">
              <HorizontalSeparatorSolid width="100%" margin="10px 0px" />
            </Flex>
            <Flex
              flexDirection="row"
              justifyContent="flex-start"
              alignItems="center"
              width="inherit"
              paddingBottom="5px"
            >
              <Flex width="125px" alignItems="center">
                <img
                  alt=""
                  src="/assets/images/campaign-platform-linkedIn.png"
                  style={{
                    marginRight: '10px',
                  }}
                />
                <ValueSpan fontSize="12px">Linkedin</ValueSpan>
              </Flex>
              <Flex
                flexWrap="wrap"
                justifyContent="space-between"
                alignItems="space-between"
              >
                {info?.data &&
                  info?.data?.linkedin &&
                  info?.data?.linkedin.map((ele, index) => {
                    if (ele.destination_id && ele.snetwork_act_id) {
                      return (
                        <Chips>
                          {socialNetwork?.data?.ln[0]?.page?.[
                            ele.destination_id
                          ]
                            ? socialNetwork?.data?.ln[0]?.page?.[
                                ele.destination_id
                              ].details?.name
                            : '-'}
                        </Chips>
                      );
                    } else if (!ele.destination_id && ele.snetwork_act_id) {
                      return <Chips>{'On news feed'}</Chips>;
                    }
                  })}
                {info?.data && isEmpty(info?.data?.linkedin) && (
                  <ValueSpan fontSize="12px">-</ValueSpan>
                )}
              </Flex>
            </Flex>
            <Flex width="98%">
              <HorizontalSeparatorSolid width="100%" margin="10px 0px" />
            </Flex>
            <Flex
              flexDirection="row"
              justifyContent="flex-start"
              alignItems="center"
              width="inherit"
              paddingBottom="5px"
            >
              <Flex width="125px" alignItems="center">
                <img
                  alt=""
                  src="/assets/images/campaign-platform-twitter.png"
                  style={{
                    marginRight: '10px',
                  }}
                />
                <ValueSpan fontSize="12px">Twitter</ValueSpan>
              </Flex>
              <Flex
                flexWrap="wrap"
                justifyContent="space-between"
                alignItems="space-between"
              >
                {info?.data && !isEmpty(info?.data?.twitter) && (
                  <Chips>{socialNetwork?.data?.tw[0]?.name?.account}</Chips>
                )}
                {info?.data && isEmpty(info?.data?.twitter) && (
                  <ValueSpan fontSize="12px">-</ValueSpan>
                )}
              </Flex>
            </Flex>
          </Flex>
        </Flex>
        <Flex
          id="tab1"
          width="98%"
          flexDirection="column"
          justifyContent="flex-start"
          alignItems="center"
          display="flex"
          margin="10px"
          style={{
            borderRadius: '4px',
            border: '1px solid #DBE0E6',
            backgroundColor: '#fff',
          }}
        >
          <Flex
            flexDirection="column"
            justifyContent="flex-start"
            alignItems="flex-start"
            width="inherit"
            padding="15px"
          >
            <Title>Topics</Title>

            <Flex
              justifyContent="flex-start"
              alignItems="center"
              flexWrap="wrap"
            >
              {!isEmpty(info?.data?.themes) &&
                !isEmpty(socialThemes) &&
                socialThemes
                  .filter((s) => info?.data?.themes.includes(s.id))
                  .map((topic, index) => {
                    return (
                      <Chips
                        style={{
                          flexDirection: 'row',
                          alignItems: 'center',
                          display: 'flex',
                        }}
                      >
                        <img
                          width="18px"
                          alt={topic.name}
                          src={getMediaUrl(topic.media_urls)}
                          style={{ marginRight: '10px' }}
                        />
                        <span class="tooltiptext">{topic.name}</span>
                      </Chips>
                    );
                  })}
              {!info?.data?.themes && <ValueSpan>-</ValueSpan>}
            </Flex>
          </Flex>
        </Flex>
        <Flex
          id="tab2"
          width="98%"
          flexDirection="column"
          justifyContent="flex-start"
          alignItems="center"
          display="flex"
          margin="10px"
          style={{
            borderRadius: '4px',
            border: '1px solid #DBE0E6',
            backgroundColor: '#fff',
          }}
        >
          <Flex
            flexDirection="row"
            justifyContent="flex-start"
            alignItems="center"
            width="inherit"
            padding="15px"
          >
            <Flex
              flexDirection="column"
              justifyContent="space-between"
              alignItems="flex-start"
              display="flex"
            >
              <Title>Start Date</Title>
              <ValueSpan>{details['startDate']}</ValueSpan>
            </Flex>
            <Flex>
              <VerticalSeparator />
            </Flex>
            <Flex
              flexDirection="column"
              justifyContent="space-between"
              alignItems="flex-start"
              display="flex"
            >
              <Title>Duration</Title>
              <ValueSpan>{details['duration']}</ValueSpan>
            </Flex>
            <Flex>
              <VerticalSeparator />
            </Flex>
            <Flex
              flexDirection="column"
              justifyContent="space-between"
              alignItems="flex-start"
              display="flex"
            >
              <Title>Frequency</Title>
              <ValueSpan>{details['frequency']}</ValueSpan>
            </Flex>
            {/* <Flex>
              <VerticalSeparator />
            </Flex>
            <Flex
              flexDirection="column"
              justifyContent="space-between"
              alignItems="flex-start"
              display="flex"
            >
              <Title>Engagement score opt-out</Title>
              <ValueSpan>50</ValueSpan>
            </Flex> */}
          </Flex>
          <Flex width="100%">
            <HorizontalSeparatorSolid width="100%" />
          </Flex>
          <Flex
            flexDirection="column"
            justifyContent="flex-start"
            alignItems="flex-start"
            width="inherit"
            padding="15px"
          >
            <Title>Schedule</Title>
            <Flex
              flexDirection="row"
              justifyContent="flex-start"
              alignItems="center"
              width="inherit"
            >
              <Flex width="125px" alignItems="center">
                <img
                  alt=""
                  src="/assets/images/campaign-platform-email.svg"
                  style={{
                    marginRight: '10px',
                  }}
                />
                <ValueSpan fontSize="12px">Email</ValueSpan>
              </Flex>

              <Flex
                flexWrap="wrap"
                justifyContent="space-between"
                alignItems="space-between"
              >
                {info?.data &&
                  info?.data?.schedule?.email &&
                  info?.data?.schedule?.email.map((ele, index) => {
                    return <Chips>{ele.schedule_time}</Chips>;
                  })}
                {info?.data && isEmpty(info?.data?.schedule?.email) && (
                  <ValueSpan fontSize="12px">-</ValueSpan>
                )}
              </Flex>
            </Flex>
            <Flex width="98%">
              <HorizontalSeparatorSolid width="100%" margin="10px 0px" />
            </Flex>
            <Flex
              flexDirection="row"
              justifyContent="flex-start"
              alignItems="center"
              width="inherit"
            >
              <Flex width="125px" alignItems="center">
                <img
                  alt=""
                  src="/assets/images/campaign-platform-facebook.png"
                  style={{
                    marginRight: '10px',
                  }}
                />
                <ValueSpan fontSize="12px">Facebook</ValueSpan>
              </Flex>
              <Flex
                flexWrap="wrap"
                justifyContent="space-between"
                alignItems="space-between"
              >
                {info?.data &&
                  info?.data?.schedule?.facebook &&
                  info?.data?.schedule?.facebook.map((ele, index) => {
                    return <Chips>{ele.schedule_time}</Chips>;
                  })}
                {info?.data && isEmpty(info?.data?.schedule?.facebook) && (
                  <ValueSpan fontSize="12px">-</ValueSpan>
                )}
              </Flex>
            </Flex>
            <Flex width="98%">
              <HorizontalSeparatorSolid width="100%" margin="10px 0px" />
            </Flex>
            <Flex
              flexDirection="row"
              justifyContent="flex-start"
              alignItems="center"
              width="inherit"
              paddingBottom="5px"
            >
              <Flex width="125px" alignItems="center">
                <img
                  alt=""
                  src="/assets/images/campaign-platform-linkedIn.png"
                  style={{
                    marginRight: '10px',
                  }}
                />
                <ValueSpan fontSize="12px">Linkedin</ValueSpan>
              </Flex>
              <Flex
                flexWrap="wrap"
                justifyContent="space-between"
                alignItems="space-between"
              >
                {info?.data &&
                  info?.data?.schedule?.linkedin &&
                  info?.data?.schedule?.linkedin.map((ele, index) => {
                    return <Chips>{ele.schedule_time}</Chips>;
                  })}
                {info?.data && isEmpty(info?.data?.schedule?.linkedin) && (
                  <ValueSpan fontSize="12px">-</ValueSpan>
                )}
              </Flex>
            </Flex>
            <Flex width="98%">
              <HorizontalSeparatorSolid width="100%" margin="10px 0px" />
            </Flex>
            <Flex
              flexDirection="row"
              justifyContent="flex-start"
              alignItems="center"
              width="inherit"
              paddingBottom="5px"
            >
              <Flex width="125px" alignItems="center">
                <img
                  alt=""
                  src="/assets/images/campaign-platform-twitter.png"
                  style={{
                    marginRight: '10px',
                  }}
                />
                <ValueSpan fontSize="12px">Twitter</ValueSpan>
              </Flex>
              <Flex
                flexWrap="wrap"
                justifyContent="space-between"
                alignItems="space-between"
              >
                {info?.data &&
                  info?.data?.schedule?.twitter &&
                  info?.data?.schedule?.twitter.map((ele, index) => {
                    return <Chips>{ele.schedule_time}</Chips>;
                  })}
                {info?.data && isEmpty(info?.data?.schedule?.twitter) && (
                  <ValueSpan fontSize="12px">-</ValueSpan>
                )}
              </Flex>
            </Flex>
          </Flex>
          <Flex width="100%">
            <HorizontalSeparatorSolid width="100%" />
          </Flex>
          <Flex
            flexDirection="column"
            justifyContent="flex-start"
            alignItems="flex-start"
            width="inherit"
            padding="15px"
          >
            <Title>Content</Title>
            {isEmpty(campaignData?.contentWeeks) && (
              <ValueSpan fontSize="12px">-</ValueSpan>
            )}
            {campaignData?.contentWeeks && (
              <Flex
                flexDirection="row"
                justifyContent="flex-start"
                alignItems="center"
                width="inherit"
              >
                <ContentSection>
                  {campaignData?.contentWeeks.map((item, index) => (
                    <>
                      <ContentPanel onClick={() => onWeekClick(item)}>
                        <Flex width="80%" justifyContent="flex-start">
                          <Flex color="#000000" fontSize="14px">
                            {item}
                          </Flex>
                            <span 
                              style={{
                                color: '#b4bdba', 
                                fontSize: '11px', 
                                margin: '0 10px 0px 30px', 
                                backgroundColor: '#fff', 
                                borderRadius: '4px', 
                                padding: '3px 4px'
                              }}
                            >
                              Posts: {campaignData?.campaignArticles && campaignData.campaignArticles.filter(c => c.week === item).length}</span>
                            <span 
                              style={{
                                color: '#b4bdba', 
                                fontSize: '11px', 
                                backgroundColor: '#f7f1e1', 
                                borderRadius: '4px', 
                                padding: '3px 4px'
                              }}
                            >
                              Content Required: {campaignData?.campaignArticles && campaignData.campaignArticles.filter(c => c.week === item).filter(ele => !ele.content).length}</span>
                        </Flex>

                        <Flex>
                          <FontAwesomeIcon
                            color="#000000"
                            icon={
                              item === selectedWeek
                                ? faChevronUp
                                : faChevronDown
                            }
                          />
                        </Flex>
                      </ContentPanel>
                      <ContentPanelSection
                        style={{
                          display: item === selectedWeek ? 'flex' : 'none',
                        }}
                      >
                        {!isEmpty(weekArticlesData) &&
                          Object.keys(weekArticlesData).map(
                            (dayItem, index, items) => (
                              <>
                                <DayPanel
                                    onClick={() => onDayClick(dayItem)}
                                    style={{cursor: 'pointer'}}
                                >
                                  <Flex 
                                    color="#000000" 
                                    fontSize="12px" 
                                    justifyContent='flex-start'
                                  >
                                    {dayItem}
                                    <span 
                                      style={{
                                        color: '#b4bdba', 
                                        fontSize: '11px', 
                                        margin: '0 10px 0px 30px', 
                                        backgroundColor: '#fff', 
                                        borderRadius: '4px', 
                                        padding: '3px 4px'
                                      }}
                                    >
                                      Posts: {weekArticlesData && weekArticlesData[dayItem] && weekArticlesData[dayItem].length}</span>
                                    <span 
                                      style={{
                                        color: '#b4bdba', 
                                        fontSize: '11px', 
                                        backgroundColor: '#f7f1e1', 
                                        borderRadius: '4px', 
                                        padding: '3px 4px'
                                      }}
                                    >
                                      Content Required: {weekArticlesData && weekArticlesData[dayItem] && weekArticlesData[dayItem].filter(element => !element.content).length}</span>
                                 </Flex>
                                  <div>
                                    <ButtonNoBorderBackground
                                      textDecoration="underline"
                                    >
                                      {selectedDays.includes(dayItem)
                                        ? 'Collapse'
                                        : 'Expand'}
                                    </ButtonNoBorderBackground>
                                  </div>
                                </DayPanel>
                                <DayPanelContentSection
                                  style={{
                                    display: selectedDays.includes(dayItem)
                                      ? 'flex'
                                      : 'none',
                                    flexWrap: 'wrap',
                                  }}
                                >
                                  {!isEmpty(weekArticlesData) &&
                                    weekArticlesData[dayItem].map(
                                      (ele, index) => <CampaignCard {...ele} />
                                    )}
                                </DayPanelContentSection>
                                {items.length - 1 !== index && <SetTopicsHr />}
                              </>
                            )
                          )}
                      </ContentPanelSection>
                    </>
                  ))}
                </ContentSection>
              </Flex>
            )}
          </Flex>
        </Flex>
      </DripDetailsWrapper>
    </LoaderWrapper>
  );
};

export default DripDashboardDetails;
