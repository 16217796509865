import styled from 'styled-components';
import { Flex } from '@rebass/grid';
import { Tab } from 'react-tabs';
import {
  TabPanel,
  theme,
  ButtonNoBorderBackground,
  ResponsiveImg,
} from '../../components';
import { themesData } from '../../utils/constants';
const { white, greyWhite, purpleishBlue, charcoalGrey } = themesData;

export const IconDiv = styled.div`
  position: absolute;
  cursor: ${({ isClickable }) => (isClickable ? 'pointer' : 'default')};
  top: 9px;
  right: 10px;
  font-size: 22px;
  img {
    height: 22px;
    width: 22px;
    margin-bottom: 2px;
  }
  opacity: ${({ disable }) => (disable ? '0.5' : '')};
`;

export const TrendingIcon = styled(IconDiv)`
  width: 20px;
`;

export const StyledTabPanel = styled(TabPanel)`
  background-color: #fff;
  position: relative;
  border-radius: ${({ type }) => (type === 'admin' ? '0' : '10px')};
  margin-top: -0px;
  border: ${({ type }) => (type === 'admin' ? 'none' : '1px solid #d4d4d4')};
  border-top: 0;
  &.react-tabs__tab-panel--selected {
    height: ${({ type }) => (type === 'admin-tab' ? '345px' : '310px')};
    padding: ${({ padding }) => padding || '15px 5px'};
  }
  overflow-y: hidden;
`;

export const StyledTabPanelTwo = styled(TabPanel)`
  margin-top: 5px;
  border-radius: 10px;
`;

export const BorderWhiteBox = styled.div`
  background-color: #fff;
  position: relative;
  border-radius: ${({ type }) => (type === 'admin' ? '0' : '10px')};
  margin-top: -0px;
  border: ${({ border }) => border || ''};
  border-top: ${({ borderTop }) => (borderTop ? '' : 0)};
  padding-top: 16px;
  &.react-tabs__tab-panel--selected {
    height: ${({ type }) => (type === 'admin-tab' ? '345px' : '310px')};
    padding: ${({ padding }) => padding || '15px 5px'};
  }
`;

export const ArticleSliderWrapper = styled.div`
  width: ${({ width }) => width || ''};
  font-size: 11px;
  ${({ type }) =>
    type === 'engagement'
      ? `
  .slick-track {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  `
      : ''}

  .slick-slider {
    margin: 0px 10px;
  }
  .slick-arrow {
    z-index: 0;
    &:before {
      color: #000;
    }
    &.slick-prev {
      left: ${({ left }) => left || '-18px'};
    }
    &.slick-next {
      right: ${({ right }) => right || '-18px'};
    }
  }
`;

export const ComplianceSliderWrapper = styled.div`
  font-size: 11px;
  .slick-slider {
    margin: 0 10px;
  }
  .slick-arrow {
    z-index: 0;
    &:before {
      color: #000;
    }
    &.slick-prev {
      left: -8px;
    }
    &.slick-next {
      right: -8px;
    }
  }
`;

export const TopInsightsWrapper = styled.div`
  height: 183px;
  overflow-y: auto;
  width: ${({ width }) => width || ''};
  overflow-x: hidden;
  ::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-track {
    border-radius: 10px;
  }
`;

export const StyledSearchInput = styled.input`
  padding-left: 10px;
  margin-bottom: 0;
  border-radius: 8px;
  height: 31px;
  font-size: 11px;
  background-color: #e4e4e4;
  border: 0px solid #d0d0d0;
  box-shadow: none;
  text-align: center;
  padding-right: 25px;
  margin-left: 5px;
  margin-right: 5px;
  font-family: 'Poppins';
  ::placeholder {
    color: #6351ed;
  }
  :focus::placeholder {
    color: transparent;
  }
`;

export const SearchButton = styled(ButtonNoBorderBackground)`
  position: absolute;
  right: 20px;
  top: 8px;
  color: ${theme.color.font.primary};
`;

export const SearchWrapper = styled(Flex)`
  position: relative;
`;

export const AddInvetmentsButton = styled(ResponsiveImg)`
  width: 75px;
  height: 75px;
  border-radius: 10px;
  cursor: pointer;
`;

export const NoContentWrapper = styled(Flex)`
  align-items: center;
  height: 100%;
  font-size: 13px;
  justify-content: center;
  font-family: 'Poppins';
`;

export const ActicleCount = styled.span`
  display: inline-block;
  position: absolute;
  right: 50px;
  top: 5px;
  font-size: 9px;
`;

export const AdminSubTab = styled(Tab)`
  font-size: 10px;
  font-weight: 500;
  padding: ${({ padding }) => padding || '5px 10px'};
  cursor: pointer;
  color: ${({ color }) => color || '#a8a8a8'};
  border-bottom: ${({ border }) => border || ''};
  font-family: 'Poppins';
  display: ${({ hide }) => (hide ? 'none' : '')};

  span {
    &.active {
      border-bottom: 2px solid ${purpleishBlue};
      color: ${purpleishBlue};
    }
    &.inactive {
      border-bottom: 1px solid #dddddd;
    }
  }

  ${({ disabled }) =>
    disabled &&
    `cursor: default;
     color: #a8a8a8; 
    `}
`;

export const DashboardAdminSubTab = styled(Tab)`
  font-size: ${({ fontSize }) => fontSize || '10px'};
  font-weight: 500;
  padding: ${({ padding }) => padding || '5px 10px'};
  margin: ${({ margin }) => margin};
  cursor: pointer;
  color: ${({ color }) => color || '#a8a8a8'};
  border-bottom: ${({ border }) => border || ''};
  font-family: 'Poppins';
  display: ${({ hide }) => (hide ? 'none' : '')};

  &.active {
    border-bottom: 2px solid ${purpleishBlue};
    color: ${purpleishBlue};
  }
  &.inactive {
    border-bottom: 1px solid #dddddd;
  }

  ${({ disabled }) =>
    disabled &&
    `cursor: default;
   color: #a8a8a8; 
  `}
`;
export const AbsoluteLoader = styled.div`
  position: absolute;
  width: 100%;
  background: #ffffff;
  top: 0px;
  bottom: 0px;
  height: 100%;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const ExploreRelativeDiv = styled.div`
  width: 100%;
`;

export const HoldingsWrapper = styled.span`
  width: ${({ width }) => width || '24%'};
  &:hover .tooltiptext {
    visibility: visible;
  }
`;

export const HoldingsIcon = styled.img`
  position: relative;
  top: 1px;
  left: 4px;
`;

export const StyledTooltip = styled.span`
  visibility: hidden;
  width: ${({ width }) => (width ? width : '55px')};
  background-color: white;
  color: #6351ed;
  text-align: center;
  border-radius: 3px;
  padding: 2px 0;
  position: absolute;
  z-index: 10;
  top: ${({ top }) => (top ? top : '60px')};
  right: ${({ right }) => (right ? right : '42%')};
  font-size: 9px;
  box-shadow: -1px 1px 11px -1px #6351ed;
`;
