import { faPlus, faSearch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Flex } from '@rebass/grid'
import { isEmpty } from 'lodash'
import React, { useState } from 'react'
import { getMediaUrl, onImageError } from '../../../utils'
import { CampaignSearchInput, CampaignThemeTiles, SearchTopicsWrapper } from './SetTopics.styles'

const CampaignSearchTopicsTab = ({
    themes,
    onAddTopics
}) => {
    const [searchText, setSearchText] = useState('');
    const [searchedTopicData, setSearchedTopicData] = useState([]);

    const handleChange = (e) => {
        setSearchText(e.target.value);
        if(!isEmpty(e.target.value)) {
            setSearchedTopicData(themes.filter(t => t.name.toUpperCase().includes(e.target.value.toUpperCase())));
        } else {
            setSearchedTopicData([]);
        }
        
    }
    return (
        <SearchTopicsWrapper>
            <Flex width="100%">
                <CampaignSearchInput 
                    placeholder="Please Search Here..."
                    value={searchText} 
                    width="40%"
                    onChange={handleChange} 
                />
                <FontAwesomeIcon 
                    style={{position: 'relative', right: '26px', top: '7px'}} 
                    icon={faSearch} 
                    color="#4E6780"
                />
            </Flex>
            <Flex padding="20px 5px 10px" fontSize="14px" width="100%" color="#000000">
                Available Topics
            </Flex>
            <Flex width="100%">
                {!isEmpty(searchedTopicData) && searchedTopicData.slice(0, 5).map((item, index) => (
                    <CampaignThemeTiles>
                       <img className="tiles-image" height="25" src={getMediaUrl(item.media_urls)} alt="" />
                        <span className="tiles-title">{item.name}</span>
                        <div className="camp-tile-cover" onClick={() => onAddTopics(item)}>
                            <FontAwesomeIcon icon={faPlus} style={{position: 'relative', left: '-2px'}}/>
                            <span className="camp-tile-cover-title">{item.name}</span>
                        </div>
                    </CampaignThemeTiles>
                ))}
            </Flex>
        </SearchTopicsWrapper>
    )
}

export default CampaignSearchTopicsTab
