import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useQueryClient } from 'react-query';
import {
  Modal,
  ModalBody,
  ModalHeader,
  LoaderWrapper,
  ModalCloseButton,
  FileUpload,
  RichTextEditor,
} from '../../../components';
import { Flex } from '@rebass/grid';
import {
  ModalTitle,
  ModalSubTitle,
  StyledMioEditorBoxFour,
} from './MioEditor.styles';
import './MioImageUpload.styles.css';
import { ToggleButton } from '../../ConfigureContainer/screenConfigure.styles';
import { saveEditorSettingsInit } from '../../../redux/actions/editor-settings';
import { actions as loginActions } from '../../../redux/modules/login.module';
import { postEditorSettings } from '../../../services/dashboard/index';
import { toBase64 } from '../../../utils';
import { dataURLtoFile } from '../../../services/dashboard/index';

const MioImageUpload = ({
  openModal,
  onRequestClose,
  userData,
  saveEditorSettingsInit,
  form,
  requestUsers,
}) => {
  const queryClient = useQueryClient();
  const [headerText, setHeaderText] = useState('');
  const [enableHeaderText, setEnableHeaderText] = useState(false);
  const [headerError, setHeaderError] = useState('');
  const [disclosureText, setDisclosureText] = useState('');
  const [enableDisclosureText, setEnableDisclosureText] = useState(true);
  const [disclosureError, setdisclosureError] = useState('');
  const [enablePublicationDate, setEnablePublicationDate] = useState(false);
  const [postSettingsError, setPostSettingsError] = useState('');
  const [base64Logo, setBase64Logo] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const [openPreviewModal, togglePreviewModal] = useState(false);
  const [headerLength, setHeaderLength] = useState('');
  const [disclosureLength, setDisclosureLength] = useState('');
  const [useLogo, toggleUseLogo] = useState(true);
  const [loader, setLoader] = useState(false);
  const [fileType, setFileType] = useState('');

  useEffect(() => {
    const form =
      userData && userData.details
        ? userData.details.user.personalization.corporate_identity
        : null;
    const clientSettings =
      userData &&
        userData.details &&
        userData.details.user &&
        userData.details.user.email_personalization
        ? userData.details.user.email_personalization
        : null;
    // let logo = clientSettings && clientSettings.media_urls ? clientSettings.media_urls[0].path + clientSettings.media_urls[0].name.system + '.' + clientSettings.media_urls[0].ext : '';
    // console.log('[LOGO] - ', logo);
    if (form) {
      // console.log('[PATH] - ', form.logo ? form.logo.main.media_urls[0].path + form.logo.main.media_urls[0].name.system : 'Logo does not exist..');
      setHeaderText(form.signature ? form.signature : '');
      setBase64Logo(
        form.logo
          ? form.logo.main.media_urls[0].path +
          form.logo.main.media_urls[0].name.system +
          '.' +
          form.logo.main.media_urls[0].ext
          : clientSettings && clientSettings.media_urls
            ? clientSettings.media_urls[0].path +
            clientSettings.media_urls[0].name.system +
            '.' +
            clientSettings.media_urls[0].ext
            : ''
      );
      setFileType(form.logo ? form.logo.main.media_urls[0].ext : clientSettings && clientSettings.media_urls ? clientSettings.media_urls[0].ext : '');
      // setBase64Logo(form.logo.main.media_urls[0].path + form.logo.main.media_urls[0].id);
      setDisclosureText(form.footer_text ? form.footer_text : '');
      setEnablePublicationDate(form.publication_date ? true : false);
      if (form.enable) {
        setEnableHeaderText(true);
        setEnableDisclosureText(true);
      }
    } else
      setBase64Logo(
        clientSettings && clientSettings.media_urls
          ? clientSettings.media_urls[0].path +
          clientSettings.media_urls[0].name.system +
          '.' +
          clientSettings.media_urls[0].ext
          : ''
      );
  }, [userData]);

  const commonDivStyles = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  };

  const imageDivStyles = {
    height: '42.5px',
    width: '98px',
    backgroundColor: 'white',
    marginRight: '10px',
    fontSize: '10px',
    padding: '7px 8px',
    textAlign: 'center',
  };

  const onFileUploadChange = (logofile) => {
    console.log('Fired...');
    setFileType(logofile[0].name.split('.')[1]);
    setErrorMsg('');
    toBase64(logofile[0]).then((file) => {
      console.log('[FILE] - ', file);
      setBase64Logo(file);
    });
  };

  const handleDisclosureChange = (event) => {
    console.log(event.target.value)
    setDisclosureText(event.target.value)
  }

  const toDataURL = (src, callback, outputFormat) => {
    var img = new Image();
    img.crossOrigin = 'Anonymous';
    img.onload = function () {
      var canvas = document.createElement('CANVAS');
      var ctx = canvas.getContext('2d');
      var dataURL;
      canvas.height = this.naturalHeight;
      canvas.width = this.naturalWidth;
      ctx.drawImage(this, 0, 0);
      dataURL = canvas.toDataURL(outputFormat);
      callback(dataURL);
    };
    img.onerror = function () {
      callback(false);
    };
    img.src = src;
    if (img.complete || img.complete === undefined) {
      img.src =
        'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///ywAAAAAAQABAAACAUwAOw==';
      img.src = src;
    }
  };

  const getTodaysDate = () => {
    const months = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ];
    const date = new Date();
    const dd = date.getDate();
    const month = months[date.getMonth()];
    const yyyy = date.getFullYear();
    return dd + ' ' + month + ' ' + yyyy;
  };

  const saveHandler = () => {
    setLoader(true);
    let form;
    var newLogo = base64Logo;
    if (newLogo === '') {
      form = {
        media1: '',
        publication_date: enablePublicationDate,
        enable: enableHeaderText,
      };
      if (enableHeaderText && headerText.length > 0)
        form.signature = headerText;
      if (enableDisclosureText && disclosureText.length > 0)
        form.footer_text = disclosureText;
      submitForm(form);
    } else {
      if (newLogo.includes('https://apicdn.myclout.com/s3/')) {
        newLogo = newLogo.replace('https://apicdn.myclout.com/s3/', 'https://mycontent-statics.myclout.com/')
        console.log("NEWURL::", newLogo)
      }
      if (newLogo.includes('https')) {
        toDataURL(newLogo, (url) => {
          form = {
            media1: dataURLtoFile(url, `filename.${fileType}`),
            publication_date: enablePublicationDate,
            enable: enableHeaderText,
          };
          if (enableHeaderText && headerText && headerText.length > 0)
            form.signature = headerText;
          if (enableDisclosureText && disclosureText && disclosureText.length > 0)
            form.footer_text = disclosureText;
          submitForm(form);
        });
      } else {
        form = {
          media1: dataURLtoFile(newLogo, `filename.${fileType}`),
          publication_date: enablePublicationDate,
          enable: enableHeaderText,
        };
        if (enableHeaderText && headerText.length > 0)
          form.signature = headerText;
        if (enableDisclosureText && disclosureText.length > 0)
          form.footer_text = disclosureText;
        submitForm(form);
      }
    }
  };
  const submitForm = (form) => {
    console.log('[FORM] - ', form);
    form.use_logo = useLogo;
    postEditorSettings(form)
      .then((response) => {
        console.log('[RESPONSE] - ', response);
        setPostSettingsError('');
        togglePreviewModal(false);
        sessionStorage.setItem('tab', 'my-editor');
        onRequestClose();
        setLoader(false);
        return requestUsers();
      })
      .then((response) => {
        queryClient.refetchQueries('branded-articles');
        console.log('[USERS RESPONSE] - ', response);
      })
      .catch((err) => {
        setLoader(false);
        togglePreviewModal(false);
        setPostSettingsError(
          'Settings were not saved due to some error. Please try again.'
        );
        console.log('[ERROR] - ', err);
      });
    // saveEditorSettingsInit(form);
  };

  const closeModal = () => {
    setLoader(false);
    console.log('FIRED...', userData);
    const form = userData.details
      ? userData.details.user.personalization.corporate_identity
      : null;
    if (form) {
      if (form.logo.main !==  undefined){
        setBase64Logo(
          form.logo.main.media_urls[0].path +
          form.logo.main.media_urls[0].name.system +
          '.' +
          form.logo.main.media_urls[0].ext
        );
        console.log(
          '[PATH] - ',
          form.logo.main.media_urls[0].path +
          form.logo.main.media_urls[0].name.system
        );
      }
      
      setHeaderText(form.signature);
      
      // setBase64Logo(form.logo.main.media_urls[0].path + form.logo.main.media_urls[0].id);
      setDisclosureText(form.footer_text);
      setEnablePublicationDate(form.publication_date);
      if (form.enable) {
        setEnableHeaderText(true);
        setEnableDisclosureText(true);
      }
    }
    setHeaderError('');
    setdisclosureError('');
    setPostSettingsError('');
    onRequestClose();
  };

  const body = openPreviewModal ? (
    <Modal size="big" isOpen={openPreviewModal} height="auto">
      <ModalHeader style={{ padding: '0px' }}>
        <ModalCloseButton
          onClick={() => {
            setPostSettingsError('');
            togglePreviewModal(false);
          }}
        />
      </ModalHeader>
      <ModalBody
        className="modal-body"
        padding={'15px 15px 40px 15px !important'}
        style={{ fontSize: '0.7rem', paddingTop: '0px' }}
      >
        <Flex
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <StyledMioEditorBoxFour>
            <ModalTitle style={{ fontSize: '1.1rem' }}>Preview</ModalTitle>
          </StyledMioEditorBoxFour>
          <div
            className="preview-container-div"
            style={{
              width: '52rem',
              height: '27.5rem',
              padding: '30px 30px',
              backgroundColor: '#fff',
              textAlign: 'center',
              overflowY: 'scroll',
            }}
          >
            <div
              className="preview-header-div"
              style={{
                ...commonDivStyles,
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <div
                className="preview-logo-div"
                style={{ width: '35%', height: '5rem', textAlign: 'center' }}
              >
                {useLogo && (
                  <img
                    alt="logo"
                    style={{ maxWidth: '100%', maxHeight: '100%' }}
                    src={
                      base64Logo ? base64Logo : '/assets/images/sample-logo.png'
                    }
                  />
                )}
              </div>

            </div>
            <hr style={{ width: '100%' }} />
            <div
              className="preview-body-div"
              style={{ color: 'black', fontSize: '15px' }}
            >
              <h3 style={{ color: 'black', fontSize: '26px' }}>
                Retirement in a Pandemic: Key Factors to Consider
              </h3>
              <p style={{ color: 'black' }}>
                Presidential hopeful Bernie Sanders wants to bring free higher
                education to Americans. Is he for real? One of the most
                significant transitions in a person's later life is exiting a
                longtime career. For this moment, there is no “one size fits
                all” decision. A lot depends on someone's goals (e.g.,
                traveling), and lifestyle decisions (e.g., where to live and
                whether to continue working), as well as available resources
                such as savings, a pension, and employer-subsidized health
                insurance.
              </p>
              <p style={{ color: 'black' }}>
                Other key factors to consider are health status and family
                responsibilities (e.g., caring for aging parents or
                grandchildren).
              </p>
              <p style={{ color: 'black' }}>
                COVID-19 has added to the complexity of later-life financial
                planning. It put many travel plans and experience goals on hold
                and caused some people to reconsider relocating far away from
                family and friends when it is more difficult to get together.
                Other issues that arose during the past year are potential home
                sellers’ reluctance to sell houses, downsize, and move during
                the pandemic as well as some people exiting the workforce sooner
                than planned due to layoffs and health concerns.
              </p>
              <div
                className="italic-div"
                style={{
                  background: '#2D8AC4',
                  color: '#fff',
                  fontStyle: 'italic',
                  padding: '12px',
                }}
              >
                <p
                  style={{
                    color: 'white',
                    fontFamily: 'Times New Roman Times serif !important',
                  }}
                >
                  All of these factors have prompted many older workers to
                  consider whether they should retire or continue to work longer
                  (assuming they have a choice) to save more money and/or
                  because their planned lifestyle (e.g., traveling and/or
                  moving) is currently not in the cards, anyway, due to
                  COVID-19.
                </p>
              </div>
              <p style={{ color: 'black' }}>
                Below are nine general planning tips to consider:
              </p>
              <p style={{ color: 'black' }}>
                Define Your Future Lifestyle Some people can live happily on
                half their pre-retirement income while others require 100
                percent — or more! For many people, 70 percent to 80 percent is
                a realistic income replacement percentage. Inflation will
                increase expenses over time, however, so an inflation rate
                (e.g., 3 percent) should be factored into retirement savings
                calculations.
              </p>
            </div>

            <div
              style={{
                width: '70%',
                marginRight: 'auto',
                textAlign: 'left',
                overflow: 'hidden',
              }}
            >
              {enableHeaderText && (
                <div
                  className="preview-headertext-div"
                  style={{ float: 'left' }}
                  dangerouslySetInnerHTML={{ __html: headerText }}
                ></div>
              )}
            </div>

            <hr style={{ width: '100%' }} />
            {enableDisclosureText && (
              <div
                className="preview-disclosure-div"
                style={{
                  fontSize: '0.5rem',
                  marginTop: '10px',
                  textAlign: 'left',
                }}
                dangerouslySetInnerHTML={{ __html: disclosureText }}
              ></div>
            )}
            {enablePublicationDate ? (
              <div style={{ position: 'relative' }}>
                <strong
                  style={{
                    position: 'absolute',
                    right: '1rem',
                    fontSize: '0.5rem',
                    color: 'black',
                  }}
                >
                  Published on {getTodaysDate()}
                </strong>
              </div>
            ) : null}
          </div>

          <div className="button-div" style={{ marginTop: '20px' }}>
            <LoaderWrapper isLoading={loader}>
              <button
                className="btn save-update"
                style={{ marginRight: '10px' }}
                disabled={
                  base64Logo === '' || headerError !== '' || disclosureError !== ''
                }
                onClick={saveHandler}
              >
                Save
              </button>
            </LoaderWrapper>
            <button
              className="btn"
              style={{ color: '#6351ed', backgroundColor: 'white' }}
              onClick={() => {
                setPostSettingsError('');
                togglePreviewModal(false);
              }}
            >
              Back
            </button>
          </div>
        </Flex>
      </ModalBody>
    </Modal>
  ) : (
    <Modal
      size="medium"
      isOpen={openModal}
      onRequestClose={closeModal}
      height="auto"
    >
      <ModalHeader style={{ padding: '0px' }}>
        <ModalCloseButton onClick={closeModal} />
      </ModalHeader>
      <ModalBody asMioEditor style={{ fontSize: '0.7rem' }}>
        <Flex
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <StyledMioEditorBoxFour>
            <ModalTitle style={{ fontSize: '1.1rem' }}>
              Editor Settings
            </ModalTitle>
            <ModalSubTitle style={{ color: 'black', fontSize: '0.8rem' }}>
              Customize your content
            </ModalSubTitle>
          </StyledMioEditorBoxFour>
          <div
            className="logo-div"
            style={{
              ...commonDivStyles,
              justifyContent: 'space-between',
              width: '650px',
            }}
          >
            <Flex
              flexDirection="column"
              justifyContent="center"
              alignItems="flex-start"
            >
              <div style={{ ...commonDivStyles, position: 'relative' }}>
                <h3 color="#6351ed" style={{ marginRight: '8px' }}>
                  Your Logo
                </h3>
                <div className="tooltip-div tooltip-logo">
                  <img
                    src="/assets/images/information-grey.svg"
                    alt="info tooltip"
                    style={{ width: '11px', height: '11px', cursor: 'pointer' }}
                  />
                  <span className="tooltip-text tooltiptext-logo">
                    Image file must be jpg or png, no larger than 10MB. The
                    logo image will appear on a white background and images with
                    transparent backgrounds may not be displayed properly.
                  </span>
                </div>
              </div>
              {/* <Flex justifyContent='flex-start' alignItems='center'>
									<label className="container">
										<input
										type="checkbox"
										checked={!useLogo}
										onChange={() => toggleUseLogo(!useLogo)}
										/>
										<span className=""></span>
									</label>
									<span style={{marginBottom: '2px'}}>Do not use logo.</span>
								</Flex> */}
            </Flex>
            <div style={commonDivStyles}>
              <div
                className="image-thumbnail"
                style={
                  base64Logo === ''
                    ? imageDivStyles
                    : { ...imageDivStyles, padding: '0px' }
                }
              >
                {base64Logo === '' ? (
                  'No image uploaded'
                ) : (
                  <img
                    className="uploaded-image"
                    src={base64Logo}
                    alt="logo"
                    width="100%"
                    height="100%"
                  />
                )}
              </div>
              <img
                src="/assets/images/delete.svg"
                alt="delete"
                style={{ marginRight: '10px', cursor: 'pointer' }}
                onClick={() => setBase64Logo('')}
              ></img>
              <FileUpload
                asLabel
                margin="0px"
                name="logoUpload"
                id="logoUpload"
                label={<img src="/assets/images/upload.svg" alt="upload" />}
                onChange={(file) => onFileUploadChange(file)}
                style={{ margin: '0px' }}
                onError={(msg) => setErrorMsg(msg)}
                maxSizeMB={10}
                accept=".png,.jpeg,.jpg,.svg,.bmp"
                title="Maximum Image Size : 10MB, Maximum Image Width :165 Pixels"
              />
            </div>
          </div>
          <hr />
          <div className="header-div" style={{ width: '650px' }}>
            <div
              style={{
                ...commonDivStyles,
                justifyContent: 'space-between',
                marginBottom: '-8px',
                paddingRight: '5px',
                alignItems: 'baseline',
              }}
            >
              <div style={{ ...commonDivStyles, position: 'relative' }}>
                <h3 color="#6351ed" style={{ marginRight: '8px' }}>
                  Your Contact Info
                </h3>
                <div className="tooltip-div tooltip-header">
                  <img
                    src="/assets/images/information-grey.svg"
                    alt="info tooltip"
                    style={{ width: '11px', height: '11px', cursor: 'pointer' }}
                  />
                  <span className="tooltip-text tooltiptext-header">
                    This section will appear in the top right of all of your
                    Editor content. Max 200 characters.
                  </span>
                </div>
                <div style={{ paddingLeft: '10px', paddingTop: '2px' }}>
                  {headerLength}
                </div>
              </div>
              <div>
                <ToggleButton
                  className="react-switch"
                  onChange={setEnableHeaderText}
                  checked={enableHeaderText}
                  onColor="#6351ed"
                  offColor="#cccccc"
                  offHandleColor="#fff"
                  onHandleColor="#fff"
                  handleDiameter={10}
                  uncheckedIcon=""
                  checkedIcon=""
                  boxShadow=""
                  activeBoxShadow=""
                  type="autoPost"
                />
              </div>
            </div>
            <div style={{ fontFamily: 'Arial' }}>
              <RichTextEditor
                placeHolder='Add Company Header here'
                name='Company Header'
                value={headerText}
                setValue={setHeaderText}
                isAdmin={userData}
                disabled={!enableHeaderText}
                fromEditor
                setError={setHeaderError}
                charLimit={200}
                setLength={setHeaderLength}
              />

            </div>
          </div>
          <div
            className="disclosure-div"
            style={{ marginTop: '-9px', width: '650px' }}
          >
            <div
              style={{
                ...commonDivStyles,
                justifyContent: 'space-between',
                marginBottom: '-9px',
                alignItems: 'baseline',
              }}
            >
              <div style={{ ...commonDivStyles, position: 'relative' }}>
                <h3 color="#6351ed" style={{ marginRight: '8px' }}>
                  Disclosure
                </h3>
                <div className="tooltip-div tooltip-disclosure">
                  <img
                    src="/assets/images/information-grey.svg"
                    alt="info tooltip"
                    style={{ width: '11px', height: '11px', cursor: 'pointer' }}
                  />
                  <span className="tooltip-text tooltiptext-disclosure">
                    This section will appear at the bottom of your editor
                    content. Max 500 characters.
                  </span>
                </div>
                <div style={{ paddingLeft: '10px', paddingTop: '2px' }}>
                  {disclosureLength}
                </div>
              </div>
              <div>

              </div>
            </div>
            <div style={{ fontFamily: 'Arial' }}>
              <textarea
                placeHolder='Disclosure statement'
                name='Disclosure header'
                value={disclosureText}
                onChange={handleDisclosureChange}
                isAdmin={userData}
                disabled={!enableDisclosureText}
                fromEditor
                setError={setdisclosureError}
                charLimit={500}
                setLength={setDisclosureLength}
                className="disclosure-editor"
              />

            </div>
          </div>
          <button
            className="btn"
            style={{ color: '#6351ed', background: 'none' }}
            onClick={() => togglePreviewModal(true)}
          >
            Preview Changes
          </button>
          <LoaderWrapper isLoading={loader}>
            <button
              className="btn save-update"

              onClick={saveHandler}
              disabled={
                headerError !== '' || disclosureError !== ''
              }
            >
              Save & Update
            </button>
          </LoaderWrapper>
          {headerError !== '' ||
            disclosureError !== '' ||
            postSettingsError !== '' ? (
            <div className="error-div">
              {headerError ? headerError : null}
              {disclosureError ? disclosureError : null}
              {postSettingsError ? postSettingsError : null}
            </div>
          ) : null}
        </Flex>
      </ModalBody>
    </Modal>
  );
  return body;
};

const mapStateToProps = (state) => {
  return {
    form: state.form,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    saveEditorSettingsInit: (formData) =>
      dispatch(saveEditorSettingsInit(formData)),
    requestUsers: () =>
      dispatch(loginActions.api.login.request({ noRefresh: true })),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MioImageUpload);
// export default MioImageUpload;
