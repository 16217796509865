/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom';
import { getAutomatedBlocksById } from '../../services/automated-campaigns';
import { Flex } from '@rebass/grid';
import { Button, LoaderWrapper } from '../../components';
import { StepperV2 } from '../../components/StepperV2/StepperV2';
import SelectEmailTemplate from './SelectEmailTemplate';
import { getEmailTemplates } from '../../services/campaign';
import { connect } from 'react-redux';
import { actions as campaignActions } from '../../redux/modules/campaign.module';
import { get, isEmpty } from 'lodash';
import useQuery from '../../customHooks/useQuery';
import { getCampaignDetails } from '../../services/campaign';
import { ROLE_TYPES, getExploreArticleData, getMyContentArticles, nurtureFlag } from 'src/utils';
import { getBookmarkedArticles, getCRMArticles, getRecommendedArticles } from 'src/services/dashboard';
import { getChannelId, getRoleType } from 'src/redux/selector';
import { getCRMContactList } from 'src/services/configure';

const CreateEmailContainerV2 = ({
    campaignSetState,
    defaultTemplates,
    selectedBlock,
    automatedFlowFromDashboard,
    setObjectivesTabData,
    bookmarkArticles,
    recommendedArticles,
    myContentArticles,
    myContentPendingQueue,
    channelId,
    isEnterpriseAdvisor,
    isPremiumAdvisor
}) => {

    const { id, campaignId } = useParams();
    const query = useQuery();
    const [loading, setLoading] = useState(false);
    const [toggleTemplateBtn, setToggleTemplateBtn] = useState(true);
    const [blockData, setBlockData] = useState();
    const history = useHistory();
    const [campaignData, setCampaignData] = useState();
    const [selectedTemplate, setSelectedTemplate] = useState();


    const stepListLines = [
        {
            label: 'Select Target'
        },
        {
            label: 'Create Campaign Journey',
        },
        {
            label: 'Campaign Overview'
        },
    ];
    
    const getThemeIds = (contactListArray, contacts) => {
        let theme_ids;
        const contactListCopy = [...contactListArray];
        const selectedContactNames = contacts;
        theme_ids = contactListCopy
          .filter((contact) =>
            selectedContactNames.some(({ destination_id }) => destination_id === contact.id)
          )
          .map((item) => item.themes)
          .flat()
          .map((theme) => (theme.id_hash ? theme.id_hash : []));
        return theme_ids;
      };

    const fetchArticles = (contactArray, contacts) => {
        const themesList = contactArray.length > 0 ? getThemeIds(contactArray, contacts) : [];
        getCRMArticles(themesList)
          .then((response) => {
            if (response.result.success) {
              campaignSetState({
                recommendedFetchedData: response.result.data,
              });
            }
          })
          .catch((err) => {
            // console.log(err);
          });
    };
    

    const fetchRecommendedArticles = (contacts) => {
        getCRMContactList()
          .then((response) => {
            const contactList = response.result.data
              ? Object.keys(response.result.data).map((key) => {
                  const recommendedThemesArray = response.result.data[key]
                    .recommended_themes
                    ? Object.keys(
                        response.result.data[key].recommended_themes.themes
                      ).map(
                        (theme) =>
                          response.result.data[key].recommended_themes.themes[theme]
                      )
                    : [];
                  const userThemesArray = response.result.data[key].user_themes
                    ? Object.keys(response.result.data[key].user_themes.themes).map(
                        (theme) =>
                          response.result.data[key].user_themes.themes[theme]
                      )
                    : [];
                  return {
                    id: key,
                    name: response.result.data[key].name,
                    themes:
                      userThemesArray &&
                      userThemesArray.length > 0 &&
                      userThemesArray[0]
                        ? userThemesArray
                        : recommendedThemesArray,
                    recommendedThemes: recommendedThemesArray,
                    userThemes: userThemesArray,
                    disabledThemes: [],
                  };
                })
              : [];
            fetchArticles(contactList, contacts);
          })
          .catch((err) => {
            console.log(err);
          });
      };

    useEffect(() => {
        //  setLoading(true);
        if (isEmpty(bookmarkArticles) && nurtureFlag !== 'nurture') {
            getBookmarkedArticles()
                .then((res) => {
                    //  setLoading(false);
                    if (res.result && res.result.data && res.result.data.feed) {
                        let allBookMarkedArticles = getExploreArticleData(
                            res.result.data,
                            'campaign'
                        );
                        if (allBookMarkedArticles.length > 0) {
                            campaignSetState({ bookmarkArticles: allBookMarkedArticles });
                        }
                    }
                })
                .catch((err) => {
                    //  setLoading(false);
                    console.log(err);
                });
        }

        //  setLoading(true);

        if (isEmpty(recommendedArticles) && nurtureFlag !== 'nurture') {
            getRecommendedArticles()
                .then((res) => {
                    //  setLoading(false);
                    if (res.result && res.result.data && res.result.data.feed) {
                        let allRecommendedArticles = getExploreArticleData(
                            res.result.data,
                            'campaign'
                        );
                        if (allRecommendedArticles.length > 0) {
                            campaignSetState({ recommendedArticles: allRecommendedArticles });
                        }
                    }
                })
                .catch((err) => {
                    //  setLoading(false);
                    console.log(err);
                });
        }

        if (isEmpty(myContentArticles) && nurtureFlag !== 'nurture') {
            getMyContentArticles({
                payload: {
                    userType: isEnterpriseAdvisor
                        ? 'isEnterpriseAdvisor'
                        : isPremiumAdvisor
                            ? 'isPremiumAdvisor'
                            : 'isEnterpriseAdmin',
                    channelId: channelId,
                    beforeToken: null,
                    afterToken: null,
                    pendingArticles: myContentPendingQueue,
                },
                localData: {
                    currentArticles: [],
                },
                source: 'campaign',
            })
                .then((res) => {
                    const newState = get(res, 'newState', {});
                    if (!isEmpty(newState)) campaignSetState(newState);
                })
                .catch((err) => {
                    console.log(
                        '******* ERROR my-content-articles [CREATE CAMPAIGN] ******'
                    );
                });
        }
    }, []);

    useEffect(() => {
        campaignSetState({
            bookmarkArticles: bookmarkArticles,
            recommendedArticles: recommendedArticles,
            myContentArticles: myContentArticles,
        });
    }, [bookmarkArticles, recommendedArticles, myContentArticles]);

    const fetchTemplates = () => {
        const filterArray = ['default', 'cascade'];
        getEmailTemplates(filterArray).then(res => {
            const fetchedTemplates = get(res, 'result.data.templates', []);
            campaignSetState({ defaultTemplates: fetchedTemplates });
            if (query.get('type') === 'campaign') {
                getCampaignDetails(campaignId).then(res => {
                    setCampaignData(get(res, 'result.data', {}));
                    fetchRecommendedArticles(get(res, 'result.data.email', []));
                    if (get(res, 'result.data.template.default_template') === 'no-content') {
                        setSelectedTemplate(get(res, 'result.data.template.default_template'));
                    } else {
                        let checkTemplate = fetchedTemplates.filter(t => t.id === +get(res, 'result.data.template.template_id', null));
                        if(!isEmpty(checkTemplate)) {
                            setSelectedTemplate(get(res, 'result.data.template.template_id', null));
                        } else {
                            setSelectedTemplate(null)
                        }
                        
                    }
                    setLoading(false);
                }).catch(err => {
                    console.log(err)
                })
            } else {
                getAutomatedBlocksById(campaignId, id).then(res => {
                    setBlockData(res?.data);
                    setSelectedTemplate(get(res, 'data.template_id', null))
                    setLoading(false);
                }).catch(err => console.log(err));
            }
        }).catch(err => {
            setLoading(false);
        })
    }

    const goBack = () => {
        campaignSetState({
            isEdit: true
        })
        if (automatedFlowFromDashboard) {
            history.push('/campaignV2');
        } else {
            history.push('/campaignV2?type=create');
        }

    }

    // let payload = {
    //     default_template: item.name,
    //     template_id: item.id,
    //     content: {"articles":[]}
    //     template_json: (binary)
    //     template_html: (binary)
    // }

    const checkTemplate = (item) => {
        if (get(campaignData, 'template.default_template', null) === item.name) {
            return true
        } else {
            return false;
        }
    }

    const handleNext = (item, flag = false) => {
        if (query.get('type') === 'campaign') {
            setSelectedTemplate(item.id);
            if (flag) {
                campaignSetState({
                    campaignId,
                    setTemplate: item.name,
                    template_id: item.id,
                    setBaseTemplate: item.original_template,
                    savedTemplate: checkTemplate(item) ? get(campaignData, 'template.template_json', null) : null,
                    setObjectives: {
                        ...setObjectivesTabData,
                        name: get(campaignData, 'name', null),
                        purpose: get(campaignData, 'purpose', null),
                        subject: get(campaignData, 'newsletter.customized_data.subject', null)
                    }
                })
            }


        } else {
            setSelectedTemplate(item.id);
            if (flag) {
                campaignSetState({
                    setTemplate: item.name,
                    setBaseTemplate: item.original_template,
                    template_id: item.id,
                    forAutomatedCampaigns: true,
                    ...item?.owner && {
                        owner: item.owner
                    },
                    selectedBlock: {
                        ...selectedBlock,
                        template: item.name,
                        template_id: item.id,
                        template_json: (blockData.template_id === 0 && item.id === 1) || (item.id === blockData.template_id) ? blockData.template_json : null
                    }
                })
            }
        }

    }

    const handleSaveAndContinue = () => {
        let item;
        console.log(selectedTemplate);
        if(query.get('type') === 'campaign') {
            campaignSetState({
                seriesId: id
            })
        }
        if ((
            selectedTemplate !== 1 && selectedTemplate !== 0) &&  selectedTemplate !== 'no-content') {
            if (query.get('type') === 'campaign') {
                item = defaultTemplates.filter(t => t.id === +selectedTemplate);
            } else {
                item = defaultTemplates.filter(t => t.id === +selectedTemplate);
            }
            console.log(item);
            handleNext(item[0], true);
        } else {
            handleNext({
                id: 1,
                name: 'no-content',
                original_template: 'no-content',
                owner: 'default'
            }, true)
        }



        history.push('/customize-email');
    }

    useEffect(() => {
        const windowBodyClassList = document.body.classList;
        if (!windowBodyClassList.contains('campaign')) {
            windowBodyClassList.add('campaign');
        }
        return () => {
            windowBodyClassList.remove('campaign');
        };
    }, []);

    useEffect(() => {
        setLoading(true);
        fetchTemplates();
    }, []);

    return (
        <Flex width="100%" flexDirection="column">
            <Flex width="80%" margin="20px auto" justifyContent="space-between">
                <Flex fontSize="28px" color="#000000" style={{ fontWeight: '500' }}>
                    New Campaign
                </Flex>
                <Flex>
                    <Button onClick={() => history.push('/campaignV2')} bgColor="#fff" color="#000000" margin="0px" borderColor="#E4E7EC">
                        Save as Draft
                    </Button>
                </Flex>
            </Flex>

            <StepperV2 stepperList={stepListLines} selectedIndex={1} />
            <Flex width="80%" margin="10px auto" flexDirection="column">
                <LoaderWrapper isLoading={loading}>
                    {!isEmpty(defaultTemplates) ? (
                        <SelectEmailTemplate
                            toggleTemplateBtn={toggleTemplateBtn}
                            setToggleTemplateBtn={setToggleTemplateBtn}
                            templates={defaultTemplates}
                            goBack={goBack}
                            handleNext={handleNext}
                            blockData={blockData}
                            campaignFlow={query.get('type') === 'campaign'}
                            campaignData={campaignData}
                            handleSaveAndContinue={handleSaveAndContinue}
                            selectedTemplate={selectedTemplate}
                        />
                    ) : (
                        <Flex width="100%" justifyContent="center">
                            No Templates Found
                        </Flex>
                    )}

                </LoaderWrapper>

            </Flex>
        </Flex>
    )
}

const mapStateToProps = state => {
    const roleType = getRoleType(state);
    const isEnterpriseAdvisor = roleType === ROLE_TYPES.ADVISOR_ENTERPRISE;
    const isPremiumAdvisor = roleType === ROLE_TYPES.ADVISOR_PREMIUM;
    return {
        defaultTemplates: state?.campaign?.defaultTemplates,
        selectedBlock: state?.campaign?.selectedBlock,
        automatedFlowFromDashboard: get(state,
            'campaign.automatedFlowFromDashboard',
            null),
        setObjectivesTabData: state.campaign.setObjectives,
        setTemplate: state?.campaign?.setTemplate,
        bookmarkArticles: state.campaign.bookmarkArticles,
        recommendedArticles: state.campaign.recommendedArticles,
        myContentArticles: state.campaign.myContentArticles,
        myContentPendingQueue: get(state, 'campaign.pendingArticles', []),
        channelId: getChannelId(state),
        isEnterpriseAdvisor: isEnterpriseAdvisor,
        isPremiumAdvisor: isPremiumAdvisor,
    }
}

const mapDispatchToProps = {
    campaignSetState: campaignActions.api.campaign.setstate,
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateEmailContainerV2);