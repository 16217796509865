import React from 'react';
import { useHistory } from 'react-router-dom';
import Button from 'src/componentsV2/Button';
import ZeroStateBackground from 'src/assets/background/home/top-contacts-zero-state.svg';
import {
  ZeroStateContainer,
  ZeroStateContent,
  ZeroStateWrapper,
} from '../styles';

function ZeroState({ isAdmin = false }) {
  const history = useHistory();

  return (
    <ZeroStateWrapper>
      <ZeroStateContainer backgroundUrl={ZeroStateBackground}>
        <ZeroStateContent>
          <div>
            <h5 className="title">Port your contacts hassle-free!</h5>
            <h5 className="title">Start integrating now.</h5>
          </div>
          <Button
            variant="outline"
            onClick={() =>
              history.push(isAdmin ? '/admin/contacts' : '/contacts')
            }
          >
            Create a Contact list
          </Button>
        </ZeroStateContent>
      </ZeroStateContainer>
    </ZeroStateWrapper>
  );
}

export default ZeroState;
