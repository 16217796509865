import React from 'react';
import { 
    SearchContainer, 
    SearchInput, 
    SearchIcon,
    ActivateSearch
} from './styles';
import searchIcon from './searchIcon.svg';

 const SearchBar = ({ 
    searchString, 
    setSearchString, 
    activeSearch = true, 
    setActiveSearch,
    searchHandler,
    maxWidth,
    margin,
    placeholder
}) => {
    if(!activeSearch) {
        return (
            <ActivateSearch onClick={()=>setActiveSearch(true)}>
                <SearchIcon style={{margin: '0 0px -3px 0'}} src={searchIcon} alt='search' />
            </ActivateSearch>
        )
    }
    return(
        <SearchContainer maxWidth={maxWidth} margin={margin}>
            <SearchIcon src={searchIcon} alt='search' />
            <SearchInput 
                placeholder={placeholder || "Search..."}
                type="text"
                id="search"
                name="search"
                value={searchString}
                onChange={(e) => setSearchString(e.target.value)}
                onKeyUp={(e) => {
                    if(searchHandler){
                        if(e.key === 'Backspace') {
                            searchHandler(e.target.value.substring(0, e.target.value - 1));
                        } else {
                            searchHandler(e.target.value);
                        }
                    }
                }}
            />
        </SearchContainer>
    )
}

export default SearchBar;
