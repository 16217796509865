
import React from 'react';
import { useState } from 'react';
import { automatedCancelCampaign } from '../../../../services/automated-campaigns';
import ConfirmationModal from '../../../../components/ConfirmationModal/ConfirmationModal';


export const AutomatedCampaignCancelModal = ({ 
    closeHandler,  
    campaignId,
    campaignRequest,
   
}) => {
    const [loading, setLoading] = useState(false);

    const clickHandler = () => {
        console.log('cancel');
        const payload = { id: campaignId };
        if (campaignId) {
            setLoading(true);
            automatedCancelCampaign(payload)
            .then((res) => {
                console.log('cancelled');
                setLoading(false);
                campaignRequest({ 
                    type: ['automated'],
                    limit: 10,
                    offset: 1  
                });
            })
            .catch((error) => {
                setLoading(false);
                console.log(error);
            })
        }
    };
    return (
        <ConfirmationModal
            modalTitle='Are you sure you want to cancel this campaign?'
            modalWidth='35%'
            closeHandler={closeHandler}
            clickHandler={clickHandler}
            loader={loading}
            message=''
            okBtnText='Yes'
            onlyShowOk={false}
            btnMargin='0px'
        />
    );
};
