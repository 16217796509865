import { Flex } from '@rebass/grid';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from '../../../components';

const OverlayButtons = ({ 
    page, 
    toggleExportToPdfModal, 
    toggleDownloadCsvModal, 
    handleComplianceEdit,
    toggleDuplicateModal, 
    selectedTab,
    setLpName 
}) => {

    const history = useHistory();
    console.log(page, selectedTab);

    return (
        <Flex
            height='85%'
            width='100%'
            flexDirection='column'
            justifyContent='space-evenly'
            alignItems='center'
            style={{
                position: 'absolute',
                backgroundColor: '#000',
                opacity: '0.8',
                top: '0',
                left: '0'
            }}
        >
                <>
                {selectedTab === 0 && (
                    <Button
                        onClick={() => page.compliance_status === 'Compliance Pending' ? handleComplianceEdit(page) : history.push(`/campaign/landing-pages/${page._id}`)}
                        // onClick={() => history.push(`/campaign/landing-pages/${page._id}`)}
                        bgColor='transparent'
                        color='#fff'
                        borderColor='#fff'
                        width='145px'
                        margin='0'
                    >
                        Edit
                    </Button>
                )}

                {selectedTab === 1 && (
                    <Button
                        onClick={() => history.push(`/campaign/landing-pages/${page._id}`)}
                        // onClick={() => history.push(`/campaign/landing-pages/${page._id}`)}
                        bgColor='transparent'
                        color='#fff'
                        borderColor='#fff'
                        width='auto'
                        margin='0'
                    >
                        Use Campaign Page
                    </Button>
                )}
                    
                    {page.compliance_status !== 'Compliance Rejected' && selectedTab === 0 && (
                        <Button
                            color='#fff'
                            bgColor='transparent'
                            borderColor='#fff'
                            width='145px'
                            margin='0'
                            onClick={() => window.open(page.redirect_page_url || 'https://www.google.com', '_blank')}
                        >
                            Visit
                        </Button>
                    )}
                </>
            {selectedTab === 0 && (
                <Button
                    color='#fff'
                    bgColor='transparent'
                    borderColor='#fff'
                    width='145px'
                    margin='0'
                    onClick={() => toggleDownloadCsvModal({ status: true, id: page._id })}
                >
                    Download CSV
                </Button>
            )}    
           
            {!page.skip_compliance && selectedTab === 0 && (
                <Button
                    onClick={() => toggleExportToPdfModal({ status: true, pageObj: page })}
                    bgColor='transparent'
                    color='#fff'
                    borderColor='#fff'
                    margin='0'
                    width='145px'
                >
                    Export To Pdf
                </Button>
            )}
            {selectedTab === 0 && (
                <Button
                    onClick={() => {
                        setLpName(`Duplicate of ${page?.name}`)
                        toggleDuplicateModal({ status: true, pageObj: page })
                    }}
                    bgColor='transparent'
                    color='#fff'
                    borderColor='#fff'
                    margin='0'
                    width='145px'
                >
                    Duplicate
                </Button>
            )}


        </Flex>
    );
};

export default OverlayButtons;