/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { LoaderWrapper, ModalBody, ModalHeader, ModalV2 } from '../../../components';
import { Flex } from '@rebass/grid';
import { ColorPallete, Typography, getMediaImage, onImageError } from '../../../utils';
import styled from 'styled-components';
import { get, isEmpty } from 'lodash';
import { Tooltip } from '@mui/material';
import { getRelatedArticles } from '../../../services/analyticsV2';
import ErrorDisplay from '../../../componentsV2/ErrorDisplay/ErrorDisplay';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { EmptyState } from '../../../componentsV2';
import moment from 'moment';
import { deleteFromBookmarks, postAddToBookmarks } from '../../../services/dashboard';


const CardWrapper = styled(Flex)`
  height: 540px;
  max-height: 540px;
  overflow-x: hidden;
  overflow-y: auto;
  position: relative;

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background: #e9f6e6 !important;
  }

  &::-webkit-scrollbar-thumb {
    background: #bccdd4 !important;
  }
`;

const RecommendationModal = ({
    isOpen,
    closeHandler,
    startDate,
    data
}) => {
    const [articleData, setArticleData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [bookmarkLoading, setBookmarkLoading] = useState(false);
    const [bookmarkId, setBookMarkId] = useState('')

    const onBookmarkAdd = (item) => {
        setBookmarkLoading(true);
        setBookMarkId(item.id);
        const { id, theme_id_hash } = item;
        postAddToBookmarks(id, theme_id_hash).then(res => {
            setBookmarkLoading(false);
            fetchArticles();
        }).catch(err => {
            console.log(err);
            setBookmarkLoading(false);
        })
    }

    const onBookmarkRemove = (item) => {
        setBookmarkLoading(true);
        setBookMarkId(item.id);
        deleteFromBookmarks(item.bookmark_id).then(res => {
            setBookmarkLoading(false);
            fetchArticles();
        }).catch(err => {
            console.log(err);
            setBookmarkLoading(false);
        })
    }

    useEffect(() => {
        fetchArticles();
    }, []);

    const fetchArticles = () => {
        setLoading(true);
        setBookMarkId('')
        let themeids = data.map(d => d.theme_id);
        getRelatedArticles(startDate, themeids).then(res => {
            setArticleData(get(res, 'result.data', []))
            setLoading(false);
            setError(false);
        }).catch(err => {
            setLoading(false);
            setError(true);
        })
    }

    return (
        <ModalV2
            isOpen={isOpen}
            width="60%"
            height='auto'
            onRequestClose={closeHandler}
            style={{ maxHeight: '90%' }}
            bgColor='#fff'
        >
            <ModalHeader fontColor='#000'>
                <Flex width="100%">
                    <Flex width="95%" flexDirection="column">
                        <Flex color={ColorPallete.neutral900} fontSize={Typography.Heading}>
                            Recommendation
                        </Flex>
                        <Flex style={{ textAlign: 'left' }} color={ColorPallete.neutral600} fontSize={Typography.TextMedium} pt="3px">
                            Use the following recommended articles based on your highly engaged topics to create new campaigns
                        </Flex>
                    </Flex>
                    <Flex width="5%">
                        <img
                            src="/assets/images/Analytics/close-button.svg"
                            alt="close"
                            style={{ cursor: 'pointer', width: '40px' }}
                            onClick={closeHandler}
                        />
                    </Flex>
                </Flex>
            </ModalHeader>
            <ModalBody>
                <Flex width="100%" flexDirection="column">
                    {error && !loading && (
                        <ErrorDisplay
                            height="540px"
                            onClick={fetchArticles}
                        />
                    )}
                    {loading && (
                        <SkeletonTheme>
                            <Skeleton
                                height={530}
                            />
                        </SkeletonTheme>

                    )}
                    {!loading && isEmpty(articleData) && !error && (
                        <EmptyState
                            height="540px"
                            title="No Analytics Data"
                            subTitle="Send out campaigns to get insights"
                        />
                    )}
                    {!isEmpty(articleData) && !loading && !error && (
                        <CardWrapper width="100%" flexWrap="wrap">
                            {!isEmpty(articleData) && !loading && !error && articleData.map((item, index) => (
                                <Flex
                                    width="32%"
                                    height="323px"
                                    flexDirection="column"
                                    backgroundColor={ColorPallete.white}
                                    margin="10px 10px 10px 0px"
                                    style={{
                                        border: `1px solid ${ColorPallete.neutral200}`,
                                        borderRadius: '8px',
                                        position: 'relative'
                                    }}
                                >
                                    <Flex
                                        width="100%"
                                        height="116px"
                                        style={{ borderBottom: `1px solid ${ColorPallete.neutral200}` }}
                                    >
                                        <img src={getMediaImage(item?.media_urls)} onError={onImageError} alt="" style={{
                                            width: '100%',
                                            height: '116px',
                                            objectFit: 'cover'
                                        }} />
                                    </Flex>
                                    <Flex
                                        padding="4px 8px"
                                        mt="10px"
                                        width="fit-content"
                                        ml="10px"
                                        mr="10px"
                                        color={ColorPallete.darkBlue}
                                        backgroundColor={ColorPallete.lightBlue}
                                        fontSize={Typography.TextSmall}
                                        style={{
                                            border: `1px solid ${ColorPallete.lightBlue}`,
                                            borderRadius: '12px'
                                        }}
                                    >
                                        {item.theme_name}
                                    </Flex>
                                    <Tooltip title={item.article_title.length > 20 ? item.article_title : null} arrow>
                                        <Flex ml="10px" mt="4px" mr="10px" color={ColorPallete.neutral900} fontSize={Typography.TextLarge}>
                                            {item?.article_title.length > 20 ? item?.article_title.substring(0, 20) + '...' : item?.article_title}
                                        </Flex>
                                    </Tooltip>
                                    <Flex ml="10px" mt="4px" mr="10px" color={ColorPallete.neutral400} fontSize={Typography.TextSmall}>
                                        {item.publisher_name}
                                    </Flex>
                                    <Tooltip title={item.description.length > 95 ? item.description : null} arrow>
                                        <Flex style={{ textAlign: 'left' }} ml="10px" mt="4px" mr="10px" color={ColorPallete.neutral600} fontSize={Typography.TextSmall}>
                                            {item.description.length > 95 ? item.description.substring(0, 95) + '...' : item.description}
                                        </Flex>
                                    </Tooltip>
                                    <Flex ml="10px" mt="4px" mr="10px" color={ColorPallete.neutral400} fontSize={Typography.TextSmall}>
                                        {moment(item.created_at).format('DD/MM/YY, HH:MM A')}
                                    </Flex>
                                    <Flex style={{ position: 'absolute', bottom: '1px', left: '3px' }}>
                                        <LoaderWrapper isLoading={bookmarkId === item.id && bookmarkLoading}>
                                            <img src={item?.is_bookmarked ? "/assets/images/Analytics/Bookmark-Active.svg" : "/assets/images/Analytics/Bookmark.svg"} alt="Bookmark" style={{ cursor: 'pointer' }} onClick={() => item?.is_bookmarked ? onBookmarkRemove(item) : onBookmarkAdd(item)} />
                                        </LoaderWrapper>

                                    </Flex>

                                </Flex>
                            ))}
                        </CardWrapper>
                    )}

                </Flex>

            </ModalBody>

        </ModalV2>
    )
}

export default RecommendationModal;