import styled from 'styled-components';

export const ListWrapper = styled.div.attrs({
  className: 'list-wrapper'
})`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 210px;
  max-height: 210px;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: #ffffff;
  border: 1px solid #cccccc;
  padding: 5px 10px;

  & > div:not(:first-child) {
    border-top: 2px solid #DCDCDC;
  }
`;