import React from 'react';
import { useLocation } from 'react-router-dom';
import {
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import useRESTQuery from 'src/hooks/useRESTQuery';

import Skeleton from './Skeleton';
import CustomTooltip from './CustomTooltip';

import {
  LegendBox,
  PerformanceChartContainer,
  PerformanceChartHeader,
  PerformanceGraphSection,
} from './styles';
import { formatDateString, transformGraphData } from './utils';
import { EmptyState } from 'src/componentsV2';

const legendItems = [
  {
    title: 'Open Rate',
    bgColor: '#7B73E4',
    key: 'open_rate',
  },
  {
    title: 'Click Rate',
    bgColor: '#F79009',
    key: 'click_rate',
  },
];

function CampaignPerformanceChart({
  campaignId = '',
  hasSubCampaignSelected = false,
}) {
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const type = queryParams.get('type');

  const { isLoading, data } = useRESTQuery({
    key: 'campaign-performance',
    endpoint: `/analytics/detailed/campaign/${campaignId}/performance?campaign_type=${type}&is_block=${hasSubCampaignSelected}`,
    dependencies: [campaignId, type, hasSubCampaignSelected],
    options: {
      select: (res) => {
        if (res?.result) return transformGraphData(res?.result);
        return [];
      },
    },
  });

  if (isLoading) return <Skeleton />;

  return (
    <PerformanceChartContainer>
      <PerformanceChartHeader>
        <h2>Campaign Performance</h2>
      </PerformanceChartHeader>
      {data ? (
        <PerformanceGraphSection>
          <div className="legend-container">
            {legendItems.map((legend) => (
              <div className="legend-info-container" key={legend?.key}>
                <LegendBox bgColor={legend.bgColor} />
                <div className="legend-item">{legend.title}</div>
              </div>
            ))}
          </div>

          <ResponsiveContainer width="100%" height={400}>
            <BarChart data={data} width={400} barSize={50}>
              <CartesianGrid vertical={false} />
              <XAxis
                dataKey="date"
                stroke="#98A2B3"
                fill="#98A2B3"
                fontSize="0.75rem"
                fontWeight="500"
                tickFormatter={(val) => formatDateString(val)}
              />
              <YAxis
                tickLine={false}
                stroke="#98A2B3"
                fill="#98A2B3"
                fontSize="0.75rem"
                fontWeight="500"
                tickCount={7}
              />
              <Tooltip content={<CustomTooltip />} cursorStyle="pointer" />

              {legendItems.map((legend) => (
                <Bar dataKey={legend?.key} fill={legend?.bgColor} />
              ))}
            </BarChart>
          </ResponsiveContainer>
        </PerformanceGraphSection>
      ) : (
        <EmptyState height="192px" title="No Analytics Data" />
      )}
    </PerformanceChartContainer>
  );
}

export default CampaignPerformanceChart;
