import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ModalFooterButton } from '../../Modal/Modal.styles';
import { Button } from '../../Form';
import { Article } from '../../../utils';
import { resubmitArticle } from '../../../services/dashboard';
import { LoaderWrapper } from '../../Loader';
import { get, isEmpty } from 'lodash';

const FooterButtons = ({
  isFromCompliance,
  enableShare,
  isAdmin,
  userData,
  rest,
  handleSubmit,
  summaryData,
  handleFeedback,
  setNoFeedback,
  shareArticle,
  onShareClick,
  onModalClose,
  setWarningMsg,
  internalUse,
  scheduleTime,
  isFromScheduledTab,
  isEnterpriseAdvisor,
  isChecked,
  selectedComplianceCheck,
  isAssetAdvisor,
  approvedArticles,
  isNewsLetter,
  newsLetter_id,
  isContentChanged,
  platform,
  channels,
  emailShareData,
  newsletterSetState,
  isCloning,
  article,
  isOGArticleComplianced,
}) => {
  // console.log('IS CLONING - -- ', isCloning, 'ARTICLE - -  -- ', article)
  // console.log('IS OG ARTICLE COMPLIANCED - -- ', isOGArticleComplianced, 'IS CONTENT CHANGED - - - - ', isContentChanged);
  // console.log('USERDATA compliance - -  -- ', userData.compliance);
  console.log('SUMMARY DATA - -  -- ', summaryData.approveSummary);
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  // console.log('[APPROVED ARTICLES] footer button - ', approvedArticles);
  const handleResubmitArticle = () => {
    setLoading(true);
    const type =
      rest.channel[0] === 'mio'
        ? 'mio'
        : rest.channel.includes('em')
        ? 'email'
        : 'others';
    if (isNewsLetter) {
      newsletterSetState({ isFromCompliance: true });
      history.push(
        `/newsletter?type=dashboard&source=compliance&tab=scheduled&id=${newsLetter_id}`
      );
    } else {
      switch (type) {
        case 'mio':
          const obj = {
            compliance_id: rest.complianceId,
            summary: JSON.stringify({
              short: summaryData.twSummary,
              medium: summaryData.lnSummary,
              long:
                internalUse && internalUse.length > 0 && internalUse === 'true'
                  ? summaryData.itSummary
                  : summaryData.emSummary,
            }),
            email_data: JSON.stringify(emailShareData),
          };
          resubmitArticle(obj).then((res) => {
            if (res.result && !!res.result.success) {
              onModalClose();
              rest.reloadRejectedTab(2);
              setLoading(false);
            } else {
              setWarningMsg(['You cannot reshare']);
              setLoading(false);
              setTimeout(() => {
                onModalClose();
              }, 3000);
            }
          });
          return;
        case 'others':
          const obj2 = {
            compliance_id: rest.complianceId,
            override_text: summaryData[`${rest.channel[0]}Summary`],
          };
          resubmitArticle(obj2).then((res) => {
            if (res.result && !!res.result.success) {
              onModalClose();
              setLoading(false);
              rest.reloadRejectedTab(2);
            } else {
              setWarningMsg(['You cannot reshare']);
              setLoading(false);
              setTimeout(() => {
                onModalClose();
              }, 3000);
            }
          });
          return;
        case 'email':
          const obj3 = {
            compliance_id: rest.complianceId,
            override_text: summaryData[`${rest.channel[0]}Summary`],
            email_data: JSON.stringify(emailShareData),
            destination_snetwork: 'em',
          };
          resubmitArticle(obj3).then((res) => {
            if (res.result && !!res.result.success) {
              onModalClose();
              setLoading(false);
              rest.reloadRejectedTab(2);
            } else {
              setWarningMsg(['You cannot reshare']);
              setLoading(false);
              setTimeout(() => {
                onModalClose();
              }, 3000);
            }
          });
          return;
        default:
          return;
      }
    }
  };

  return (
    <>
      {!isFromCompliance && (
        <Button
          disabled={!enableShare}
          margin="0px"
          onClick={() =>
            isAdmin ? shareArticle() : onShareClick(scheduleTime)
          }
          height="35px"
          padding={'2px 18px;'}
          styles={{
            backgroundColor: enableShare ? '#6351ed' : 'rgb(175, 175, 175)',
            paddingBottom: '0px',
            color: enableShare ? 'rgb(238, 238, 238)' : 'rgb(235, 235, 235)',
            height: '39px',
            width:
              (userData.compliance_admin && userData.compliance === 'on') ||
              userData.compliance === 'on' ||
              (isCloning &&
                ((isOGArticleComplianced && isContentChanged) ||
                  selectedComplianceCheck === 'yes'))
                ? 'auto'
                : userData.compliance === 'optional'
                ? 'auto'
                : '87px',
          }}
        >
          {/* {console.log('IS CLONING - - - ', isCloning, 'IS COMPLIANCED - - - ', article.sharedData[0].is_complianced, 'COMPLIANCE CHECK', selectedComplianceCheck === 'yes')} */}
          {isCloning
            ? (isOGArticleComplianced && isContentChanged) ||
              (!isOGArticleComplianced &&
                (userData.compliance === 'on' ||
                  selectedComplianceCheck === 'yes'))
              ? 'Share After Compliance Approval'
              : 'Share'
            : isAdmin &&
              !isFromScheduledTab &&
              !isCloning &&
              userData.compliance_admin &&
              userData.compliance === 'on'
            ? 'Share After Compliance Approval'
            : (isAssetAdvisor || isEnterpriseAdvisor) && approvedArticles
            ? 'Share'
            : isAdmin &&
              !isFromScheduledTab &&
              userData.compliance_admin &&
              userData.compliance === 'optional'
            ? selectedComplianceCheck === 'yes'
              ? 'Share After Compliance Approval'
              : 'Share'
            : userData.compliance === 'on' && !isFromScheduledTab
            ? 'Share After Compliance Approval'
            : 'Share'}
        </Button>
      )}
      {rest.isFromAdmin && (
        <LoaderWrapper isLoading={loading}>
          <ModalFooterButton
            onClick={() => handleResubmitArticle()}
            style={{ marginRight: '18px' }}
            className={!isNewsLetter && !isContentChanged ? 'disabled' : ''}
          >
            {isNewsLetter
              ? 'Edit NewsLetter to Resubmit to Compliance'
              : 'Resubmit To Compliance For Approval'}
          </ModalFooterButton>
        </LoaderWrapper>
      )}
      {!rest.isFromAdmin &&
        isFromCompliance && [
          !rest.reject && (
            <ModalFooterButton
              onClick={(e) =>
                handleSubmit(e, Article.APPROVE, summaryData.approveSummary)
              }
              style={{
                marginRight: '18px',
                borderRadius: isFromCompliance && '6px',
                fontSize: isFromCompliance && '10px',
                paddingLeft: isFromCompliance && '20px',
                paddingRight: isFromCompliance && '20px',
              }}
            >
              Approve
            </ModalFooterButton>
          ),
          <ModalFooterButton
            onClick={(e) =>
              summaryData.approveSummary
                ? handleFeedback(e, Article.REJECT, summaryData.approveSummary)
                : setNoFeedback(true)
            }
            disabled={isEmpty(get(summaryData, 'approveSummary', ''))}
            style={{
              backgroundColor: isFromCompliance && '#4e6780',
              borderRadius: isFromCompliance && '6px',
              borderColor: isFromCompliance && '#4e6780',
              fontSize: isFromCompliance && '10px',
              paddingLeft: isFromCompliance && '20px',
              paddingRight: isFromCompliance && '20px',
            }}
          >
            {console.log(
              'condition - - -',
              isEmpty(get(summaryData, 'approvedSummary', ''))
            )}
            Send Feedback
          </ModalFooterButton>,
        ]}
    </>
  );
};

export default FooterButtons;
