import styled from 'styled-components';
import { colors } from '../../styles';

export const ButtonStyle = styled('button')`
background: ${({ disabled }) => disabled ? colors.disabled_button : colors.clout_purple};
color: white;
cursor: ${({ disabled }) => disabled ? 'text' : 'pointer'};
font-size: 14px;
font-weight: 500;
font-family: Poppins !important;
margin: ${({ margin }) => margin ? margin : '10px 5px'};
height: ${({ height }) => height ? height : '40px'};
width: ${({ width }) => width ? width : 'fit-content'};
padding: ${({ padding }) => padding ? padding : '12px 24px'} ;
white-space:nowrap;
box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.12);
border-radius: 4px;
transition: 0.5s;
border: 0;
justify-content: center;
text-transform: none;
display: flex;
align-items: center;
:hover {
    background: ${({ disabled }) => disabled ? colors.disabled_button : colors.hover_purple};
  }
`;
