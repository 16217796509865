/* eslint-disable no-loop-func */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import BeePlugin from '@mailupinc/bee-plugin';
import { cloneDeep, get, isEmpty, uniq, uniqBy } from 'lodash';
import { Button, LoaderWrapper, Modal, ModalBody, ModalCloseButton, ModalHeader } from '..';
import { getExploreArticleData,  ROLE_TYPES } from '../../utils';

import { beeAuth, updateCampaign } from '../../services/campaign';
import { Flex } from '@rebass/grid';
import { useHistory } from 'react-router';
import BeeHeader from './BeeHeader';
import { lambdaEndpoints } from '../../services/helpers';
import ArticlePromiseModalV2 from '../../containers/EmailEditorContainer/ArticleModalV2';
import ModalContainer from 'react-modal-promise';
import { NoContentTemplate } from './no-content-base';
import { getAdvisorRows, handlePresetRows, processNoContentTemplate } from './BeeEditorHelper';
import { resubmitCampaignBlock, updateCampaignBlock } from '../../services/automated-campaigns';
import EmailBlockModal from '../../containers/AutomatedCampaigns/CampaignCreateSteps/EmailBlockModal';
import DiscardModal from './DiscardModal';
import useQueryForKeyInsights from 'src/hooks/useQueryForKeyInsights';
import Drawer from '@mui/material/Drawer';
import OpenAIDrawer from './AIDrawer/OpenAIDrawer';
import { forwardFriendRow } from './ForwardFriendRow';


const BeeFreeEmailEditor = ({ 
    userData, 
    selectedArticles, 
    themeNames, 
    setTemplateData, 
    setTemplateBase,
    setTemplateCascade,
    handleNext, 
    handleBack, 
    campaignId,
    campaignSetState,
    campaignRequest,
    savedTemplate,
    defaultTemplates,
    endCreateFlow,
    roleType,
    owner,
    bookmarkArticles,
    recommendedArticles,
    myContentArticles,
    recommendedFetchedData,
    setObjectivesTabData,
    fromDashboard,
    webinarData,
    forAutomatedCampaigns,
    selectedBlock,
    automatedCampaignsData,
    selectedBlockId,
    automatedFlowFromDashboard,
    selectedIndex,
    targetInfo,
    reviewType,
    automatedCampaignId,
    seriesId,
    template_id
}) => {
    const history = useHistory();
    const [editorLoader, toggleEditorLoader] = useState(true);
    const [template, setTemplate] = useState({});
    const [loading, toggleLoading] = useState(false);
    const [beeEditorInstance, setBeeEditorInstance] = useState(false);
    const [confirmationModal, toggleConfirmationModal] = useState(false);
    const [templateName, setTemplateName] = useState('');
    const [error, setError] = useState('');
    const [loadHeader, setLoadHeader] = useState(false);
    const [openEmailModal, setOpenEmailModal] = useState(false);
    const [blockName, setBlockName] = useState('');
    const [subject, setSubject] = useState('');
    const [discardModal, setDiscardModal] = useState(false);
    const [openDrawer, setOpenDrawer] = useState(false);
    const [subjectChecked, setSubjectChecked] = useState(true);
    const [suggestions, setSuggestions] = useState('');

    const { data: insightData } = useQueryForKeyInsights(userData?.details?.user?.email);

    
    useEffect(() => {
        const DEFAULT_CONFIGURATION = {
            uid: get(userData, 'uid', ''),                   
            container: 'bee-plugin-container',
            language: 'en-US',
            autosave: true,
            roleHash: (
                roleType === ROLE_TYPES.ADVISOR_ENTERPRISE && 
                (get(setObjectivesTabData, 'isCascaded', 'false') === 'true' || get(targetInfo, 'is_cascaded', 'false') === 'true')
                ) ? 
                'nopermissions' : 
                'superAdminClout',
            mergeTags: [
                {name: 'First Name', value: '{$first_name}'}, 
                {name: 'Last Name', value: '{$last_name}'},
                {name: 'Full Name', value: '{$full_name}'},
                {name: 'Title', value: '{$title}'},
                {name: 'Email', value: '{$email}'},
            ],
            contentDefaults: {
                text: {
                    styles: {
                        color: '#000'
                    }
                }
            },
            rowsConfiguration: {
                defaultRows: true,
                emptyRows: true,
                externalContentURLs: [
                    {
                        name: 'My Content',
                        value: `${lambdaEndpoints.baseUrl}user-rows?uid=${get(userData, 'uid', '')}&category=content_block&isCascaded=${get(setObjectivesTabData, 'isCascaded', 'false')}`
                    },
                    {
                        name: 'My Disclaimer',
                        value: `${lambdaEndpoints.baseUrl}user-rows?uid=${get(userData, 'uid', '')}&category=disclaimer&isCascaded=${get(setObjectivesTabData, 'isCascaded', 'false')}`
                    },
                    {
                        name: 'My Footers',
                        value: `${lambdaEndpoints.baseUrl}user-rows?uid=${get(userData, 'uid', '')}&category=footer&isCascaded=${get(setObjectivesTabData, 'isCascaded', 'false')}`
                    }, 
                    {
                        name: 'My Headers',
                        value: `${lambdaEndpoints.baseUrl}user-rows?uid=${get(userData, 'uid', '')}&category=header&isCascaded=${get(setObjectivesTabData, 'isCascaded', 'false')}`
                    }, 
                    {
                        name: 'My Signatures',
                        value: `${lambdaEndpoints.baseUrl}user-rows?uid=${get(userData, 'uid', '')}&category=signature&isCascaded=${get(setObjectivesTabData, 'isCascaded', 'false')}`
                    }, 
                ]
            },
            onchange: msg => console.log('ONCHANGE:  ', msg),
            onLoad: () => {
                setLoadHeader(true)
            },
            onerror: msg => console.log('ERROR - - --', msg),
        };
        if(get(targetInfo, 'is_cascaded', 'false') !== 'true')
            DEFAULT_CONFIGURATION.contentDialog = {
                externalContentURLs: {
                    label: 'Search Content',
                    handler: (resolve, reject) => {
                        ArticlePromiseModalV2().then(value => {
                            resolve(value);
                        }).catch(err => {
                            reject('You did not select any Content');
                        })
                    }
                }
            }
        

        const beeEditor = new BeePlugin();
        
        beeAuth().then(async (res) => {
            beeEditor.token = res.result.data;
            let savedJson = {};
            setSubject(forAutomatedCampaigns  ? selectedBlock?.subject : setObjectivesTabData?.subject);
            setBlockName(forAutomatedCampaigns ? selectedBlock?.name : setObjectivesTabData?.name);
            if ((forAutomatedCampaigns || automatedFlowFromDashboard) && selectedBlock && !isEmpty(selectedBlock.template_json)) {
                if (get(targetInfo, 'is_cascaded', 'false') === 'true') {
                    savedJson = await getAdvisorRows(selectedBlock.template_json);
                } else {
                    savedJson = selectedBlock.template_json;
                }
               
            } else if (savedTemplate) {
                if (get(setObjectivesTabData, 'isCascaded', 'false') === 'true') {
                    savedJson = await getAdvisorRows(JSON.parse(savedTemplate));
                } else {
                    savedJson = JSON.parse(savedTemplate);
                }
            }
            else if (setTemplateData === 'no-content') {
                savedJson = await processNoContentTemplate(NoContentTemplate);
            }   
            else {
                if(defaultTemplates) {
                    const templateObj =  defaultTemplates.find(template => template.id === template_id);
                    let templateJson;
                    if(templateObj?.owner === 'clout') {
                        templateJson = defaultTemplates.find(template => template.id === template_id).template_json;
                        savedJson = await handlePresetRows(JSON.parse(templateJson));
                    } else {
                        templateJson = defaultTemplates.find(template => template.id === template_id).template_json;
                        savedJson = unLockRows(JSON.parse(templateJson));
                    }  
                }
                else
                    history.push('/campaignV2');
            }
            
            if(!JSON.stringify(savedJson)?.includes('{$forward_a_friend}')) {
                let template = cloneDeep(savedJson);
                let forwardAFriendRow = forwardFriendRow();
                template.page.rows.push(forwardAFriendRow);
                console.log('template', template);
                savedJson = template;
            }

            // if(JSON.stringify(savedJson).search(/Forward A Friend Icon/) === -1) {
            //     let template = cloneDeep(savedJson);
            //     let forwardFriendIconRow = forwardAFriendIconRow();
            //     template.page.rows.unshift(forwardFriendIconRow);
            //     console.log('template', template);
            //     savedJson = template;
            // }
            setTemplate({json: savedJson, html: ''});
            beeEditor.start({...DEFAULT_CONFIGURATION, onSave, onSend}, savedJson);
            setBeeEditorInstance(beeEditor);
            toggleEditorLoader(false);
        }).catch(err => {
            toggleEditorLoader(false);
        });
        
    }, []);    

    const unLockRows = (savedJson) => {
        let template = cloneDeep(savedJson)

        template.page.rows.forEach((ele) => {
            ele.locked = false;
            ele.columns.forEach((col) => {
                col.modules.forEach((mod) => {
                    mod.locked = false;
                })
            })
        });
        return template;
    }

    const onSend = (savedHtml) => {
        console.log(savedHtml);
        setTemplate({
            html: savedHtml
        })    
        setOpenDrawer(true);
    }

    const handleCheckBoxChange = () => {
        if(subjectChecked) {
            setSubjectChecked(false);
            setBlockName('');
        } else {
            setSubjectChecked(true);
            setBlockName(subject);
        }
    }

    const handleSubjectChange = (value) => {
        if(subjectChecked) {
            setBlockName(value);
        }

        setSubject(value);
    }

    const onSave = (savedJson, savedHtml) => {
        setTemplate({
            json: savedJson,
            html: savedHtml
        });

        if(window.sessionStorage.getItem('reviewClick')) {
            setOpenDrawer(true);
            return;
        }
        
        const parsedJson = JSON.parse(savedJson);
        const rowsArray = parsedJson.page.rows.filter(row => row.metadata && row.metadata.key === 'article_block');
        let selectedArticlesArray = [];
        // let selectedArticlesArray = [];
        if(!isEmpty(rowsArray)) {
            rowsArray.forEach(row => selectedArticlesArray.push(...row.metadata.article_id));
            selectedArticlesArray = uniq(selectedArticlesArray);
            const recommendedData = recommendedFetchedData && !isEmpty(get(recommendedFetchedData, 'data.feed', [])) ? getExploreArticleData(
                get(recommendedFetchedData, 'data', {})
            ) : recommendedArticles;
            const allArticlesArray = uniqBy([...bookmarkArticles, ...recommendedData, ...myContentArticles], 'id');
            selectedArticlesArray = allArticlesArray.filter(article => selectedArticlesArray.includes(article.id));            
        }
        if(!forAutomatedCampaigns) {
            if(!isEmpty(selectedArticlesArray)) 
            campaignSetState({ savedTemplate: savedJson, selectedArticles: selectedArticlesArray });
        else
            campaignSetState({ savedTemplate: savedJson });

        }
        // if(sessionStorage.getItem('saveAsDraft')){
        //     sessionStorage.removeItem('saveAsDraft');
        //     history.push('/campaignV2');
        // }
      
        setOpenEmailModal(true);
        
        
        
    }


    const handleNextClick = (close, createTemplate) => {
        // toggleModalLoader(false);
        // toggleConfirmationModal(false);
        // toggleModalUpdate(false);

        console.log('handleNext');

        var doc = document.createElement("html");
        doc.innerHTML = template.html;
        var links = doc.getElementsByTagName("a")
        let urls = [], newsletterArticles = [];

        for (var i=0; i<links.length; i++) {
            urls.push(links[i].getAttribute("href"));
        }

       
        if (!isEmpty(urls)) {
            // This to take care of nulls, undefined
            urls = urls.filter(u => u);
            if (!isEmpty(urls)) {
                urls = urls.filter(u => u.includes('article_link'));
            }
        }

        if(!isEmpty(urls)) {
            urls.forEach(ele => {
                newsletterArticles.push(ele.split('____')[1]);
            });
        }

        if(webinarData && webinarData.isWebinar && template.json.search(/webinar-block/) === -1) {
            setError('Please Add a Webinar Row from Add Content');
            return; 
        }

        if(!template.json.includes('{$unsubscribe_link}')) {
            setError('Please add {$unsubscribe_link} as a href to your unsubscribe link');
            return; 
        }
        if(automatedCampaignsData && automatedCampaignsData.targetInfo && automatedCampaignsData.targetInfo.target_page_id && selectedBlock && selectedBlock.b_type === 'pre_conversion' && selectedBlock.platform === 'email' && template.json.search(/web_page_url/) === -1) {
            setError('In order to proceed, please return to the editor and insert landing page by selecting “Add Content"');
            return; 
        }
        toggleLoading(true);
        let payloadArticles = [];
        // if(selectedArticles) {
        //     payloadArticles = selectedArticles.map(article => {
        //         return {
        //             article_id: article.id,
        //             theme_id: typeof article.themeId === 'string' || Array.isArray(article.themeId) ? 
        //                 article.themeId : article.themeId.id
        //         }
        //     });
        // }

        if(newsletterArticles) {
            payloadArticles = newsletterArticles.map(a => {
                return {
                    article_id: a
                }
            })
        }
        const payloadObj = {
            articles: payloadArticles,
            default_template: setTemplateData,
            template_id: setTemplateData === 'no-content' ? defaultTemplates.find(template => template.name === 'no_content').id : template_id,
        };

        let webinarID = null;
        if(webinarData?.wuid){
            webinarID = {
                "webinar_id" : webinarData.wuid
            }
        }

        const payload = {
            content: JSON.stringify(payloadObj),
            template_json: new File([template.json], 'template.json'),
            template_html: new File([template.html], 'template.html'),
            name: blockName,
            email_data: JSON.stringify({ subject: subject }),
            ...webinarID
        };

        if(forAutomatedCampaigns) {
            let newBlock = {
                ...selectedBlock, 
                template_json: JSON.parse(template.json), 
                template_html: template.html,
                content: payloadObj,
                name: blockName,
                subject: subject
            }
            campaignSetState({ selectedBlock: newBlock });
            if(selectedBlock.status === 'compliance_rejected' || selectedBlock.status === 'compliance_approved'){
                newBlock.template_json = new File([JSON.stringify(newBlock.template_json)], 'template.json');
                newBlock.content = JSON.stringify(payloadObj);
                newBlock.template_html = new File([newBlock.template_html], 'template.html');
                resubmitCampaignBlock({
                    campaignId: get(automatedCampaignsData, 'campaignId', ''),
                    blockId: selectedBlockId,
                    payload: newBlock
                })
                .then(res => {
                    if(res.result.success) {
                        toggleLoading(false);
                        if(automatedFlowFromDashboard) {
                            campaignSetState({automatedDashboard : true});
                            history.push('/campaignV2');
                        } else {
                            history.push('/campaignV2?type=create');
                        }
                        campaignSetState({selectedIndex: 0, selectedBlock: {}, setTemplate: ''});
                    } else {
                        toggleLoading(false);
                    }
                })
                .catch(err => {
                    toggleLoading(false);
                    setError('Some issue while saving the email block');
                    console.log('ERROR - - -- - - ', err)
                })
            }
            else{
                updateCampaignBlock({
                    campaignId: get(automatedCampaignsData, 'campaignId', ''),
                    blockId: selectedBlockId,
                    payload: newBlock
                })
                .then(res => {
                    if(res.success) {
                        if(automatedFlowFromDashboard) {
                            campaignSetState({automatedDashboard : true});
                            history.push('/campaignV2');
                        } else {
                            history.push('/campaignV2?type=create');
                        }
                        campaignSetState({selectedIndex: 0, selectedBlock: {}, setTemplate: ''});
                    }
                })
                .catch(err => {
                    toggleLoading(false);
                    setError('Some issue while saving the email block');
                    console.log('ERROR - - -- - - ', err)
                })
            }
        }
        else {
            updateCampaign({id: campaignId, payload})
            .then(response => {
                toggleLoading(false);
                if(close || sessionStorage.getItem('saveAsDraft')) {
                    endCreateFlow();
                    history.push('/campaignV2');
                } else {
                    handleNext();
                }
            })
            .catch(err => {
                console.log('ERROR - - - - -', err);
                toggleLoading(false);
            });
        }
    }

    const goBack = () => {
        if(forAutomatedCampaigns) {
            history.push(`/create-email/${selectedBlockId}/${automatedCampaignId}`);
        } else {
            history.push(`/create-email/${seriesId}/${campaignId}?type=campaign`);
        }
        
    }


    const modalCloseHandler = () => {
        setTemplateName('');
        toggleConfirmationModal(false);
        setError('');
    }

    const addContentValidation = () => {
        if(forAutomatedCampaigns) 
            return get(targetInfo, 'is_cascaded', 'false') !== 'true';
            // return get(targetInfo, 'is_cascadedd', 'true') !== 'true';
        else
            return true;
    }

    const onBackClick = () => {
        setDiscardModal(true);
    }


    return (
        <div>
            <LoaderWrapper isLoading={editorLoader}>
                <Drawer
                    anchor="right"
                    open={openDrawer}
                    onClose={() => setOpenDrawer(false)}
                    PaperProps={{ style: { width: '30%' } }}
                >
                    <OpenAIDrawer 
                        handleClose={() => setOpenDrawer(false)}
                        template={template}
                        subject={subject}
                        insightData={insightData}
                        suggestions={suggestions}
                        setSuggestions={setSuggestions}
                    />
                </Drawer>
                <BeeHeader 
                    enable={loadHeader}
                    beeEditorInstance={beeEditorInstance}
                    addArticles={addContentValidation()}
                    backHandler={onBackClick}
                    setTemplateData={setTemplateData}
                    webinarData={webinarData}
                    selectedIndex={selectedIndex}
                    setObjectivesTabData={setObjectivesTabData}
                    forAutomatedCampaigns={forAutomatedCampaigns}
                    reviewType={reviewType}
                    subjectChecked={subjectChecked}
                    setSubjectChecked={handleCheckBoxChange}
                    subject={subject}
                    setSubject={handleSubjectChange}
                />
                <div id='bee-plugin-container' style={{height: '68.5vh', width: '100%'}} />
            </LoaderWrapper>
            <ModalContainer />
            {confirmationModal && (
                <Modal isOpen={confirmationModal} onRequestClose={modalCloseHandler} size='medium-small'>
                    <ModalHeader style={{fontSize: '15px'}}>
                        Do you want to proceed?
                        <ModalCloseButton onClick={modalCloseHandler} />
                    </ModalHeader>
                    <ModalBody padding='0px 15px 15px'>
                        {error && (
                            <div style={{fontSize: '11px', color: '#6351ed'}}>*{error}</div>
                        )}
                        <Flex width='44%' justifyContent='space-between' margin='auto'>
                            <LoaderWrapper isLoading={loading}>
                                <Button 
                                    borderRadius='4px' 
                                    onClick={() => handleNextClick()}
                                    width='80px'
                                    padding='5px 10px'
                                    margin='20px 10px 0px 0px'
                                    height='40px'
                                    id='save_template_btn'
                                >
                                    Yes
                                </Button>
                                <Button padding='0px 10px' width='80px' height='40px' borderRadius='4px' onClick={() => modalCloseHandler()} id="no_save">Cancel</Button>
                            </LoaderWrapper>
                        </Flex>
                    </ModalBody>
                </Modal>
            )}
            {openEmailModal && (
                <EmailBlockModal 
                    isOpen={openEmailModal}
                    handleClose={() => {
                        setOpenEmailModal(false);
                        setError('');
                    }}
                    handleBack={() => {
                        setOpenEmailModal(false);
                        setError('');
                    }}
                    blockName={blockName}
                    setBlockName={setBlockName}
                    subject={subject}
                    setSubject={setSubject}
                    createBlockButtonClick={handleNextClick}
                    loading={loading}
                    message={error}
                    insightData={insightData}
                />
            )}

            {discardModal && <DiscardModal 
                isOpen={discardModal}
                handleClose={() => setDiscardModal(false)}   
                loading={loading}     
                onConfirmClick={goBack} 
            />}
            
        </div>
    );
};
export default BeeFreeEmailEditor;