import React, { useEffect, useState } from 'react';
import { Box, Flex } from '@rebass/grid';
import { StepperV2 } from '../../components/StepperV2/StepperV2';
import SelectTarget from './CampaignCreateSteps/SelectTarget';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { getAutomatedCampaignById } from '../../services/automated-campaigns';
import { getFirmSettings, getRoleType, getUserRole } from '../../redux/selector';
import CustomizeCampaignV2 from './CampaignCreateSteps/CustomizeCampaignV2';
import ActivateCampaignV2 from './CampaignCreateSteps/ActivateCampaignV2';
import { useHistory } from 'react-router-dom';
import { Button } from '../../components';
import { ROLE_TYPES } from '../../utils';

export const AUTOMATED_CAMPAIGN_ACTIONS = {
  WELCOME_CLIENTS: 'welcome_clients',
  NURTURE_PROSPECTS: 'nurture_prospects',
  EVENT: 'event',
  NEWSLETTER: 'newsletter',
};

const stepListLines = [
  {
    label: 'Select Target'
  },
  {
    label: 'Create Campaign Journey',
  },
  {
    label: 'Campaign Overview'
  },
];


const AutomatedCampaignsHome = ({
  currentFlow,
  campaignSetState,
  userData,
  automatedCampaignDetails,
  automatedCampaignTargetInfo,
  automatedCampaignId,
  campaignId,
  userRole,
  compliance,
  isEditMode,
  landingPagesData,
  segmentListData,
  isFirmLevelAdmin,
  firmSettings,
  socialNetworkData,
  setObjectivesTabData,
  endCreateFlow,
  segmentList,
  setSegmentList,
  getAccountId,
  companyId,
  isEnterpriseAdvisor,
  isEnterpriseLevelAdmin,
  fetchSegments,
  segmentsLoading,
  isAdmin
}) => {
  console.log("isEnterpriseAdvisor--->", isEnterpriseAdvisor)
  const [currentStepIndex, setCurrentStepIndex] = useState(get(automatedCampaignDetails, 'activeStepIndex', 0));
  const [campaignSeriesName, setCampaignSeriesName] = useState('');
  const [campaignPurpose, setCampaignPurpose] = useState('');

  const history = useHistory();

  const handleSaveAsDraftClick = () => {
    console.log("clicked");
    campaignSetState({ automatedDashboard: true });
    history.push('/campaignV2');
  };

  console.log('CURRENT STEP INDEX - - -- - ', currentStepIndex);

  const getFormByIndex = (index) => {
    if (index === 0)
      return (
        <SelectTarget
          automatedCampaignDetails={automatedCampaignDetails}
          campaignSetState={campaignSetState}
          targetInfo={automatedCampaignTargetInfo}
          // campaignAction={AUTOMATED_CAMPAIGN_ACTIONS.EVENT}
          campaignAction={currentFlow}
          currentStepIndex={currentStepIndex}
          setCurrentStepIndex={setCurrentStepIndex}
          userData={userData}
          userRole={userRole}
          isEditMode={isEditMode}
          socialNetworkData={socialNetworkData}
          setObjectivesTabData={setObjectivesTabData}
          endCreateFlow={endCreateFlow}
          segmentList={segmentList}
          setSegmentList={setSegmentList}
          getAccountId={getAccountId}
          campaignSeriesName={campaignSeriesName}
          setCampaignSeriesName={setCampaignSeriesName}
          campaignPurpose={campaignPurpose}
          setCampaignPurpose={setCampaignPurpose}
          onSaveAsDraftClick={handleSaveAsDraftClick}
          isFirmLevelAdmin={isFirmLevelAdmin}
          isEnterpriseAdvisor={isEnterpriseAdvisor}
          isEnterpriseLevelAdmin={isEnterpriseLevelAdmin}
          fetchSegments={fetchSegments}
          isAdmin={isAdmin}
          segmentsLoading={segmentsLoading}
        />
      );
    if (index === 1)
      return (
        <CustomizeCampaignV2 
          campaignAction={currentFlow}
          automatedCampaignId={automatedCampaignId}
          userData={userData}
          currentStepIndex={currentStepIndex}
          setCurrentStepIndex={setCurrentStepIndex}
          campaignSetState={campaignSetState}
          endCreateFlow={endCreateFlow}
          automatedCampaignDetails={automatedCampaignDetails}
          automatedCampaignTargetInfo={automatedCampaignTargetInfo}
        />
      );
    if (index === 2) return <ActivateCampaignV2 
      campaignAction={currentFlow} 
      currentStepIndex={currentStepIndex} 
      automatedCampaignId={automatedCampaignId} 
      campaignSeriesName={campaignSeriesName} 
      campaignPurpose={campaignPurpose}
      firmSettings={firmSettings}
      isFirmLevelAdmin={isFirmLevelAdmin} 
      companyId={companyId}
      compliance={compliance}
      campaignSetState={campaignSetState}
      automatedCampaignDetails={automatedCampaignDetails}
      automatedCampaignTargetInfo={automatedCampaignTargetInfo}
      setCurrentStepIndex={setCurrentStepIndex}
      endCreateFlow={endCreateFlow}
    />;
    // if (index === 2)
    //   return (
    //     <ActivateCampaignV2
    //       campaignAction={currentFlow}
    //       currentStepIndex={currentStepIndex}
    //       automatedCampaignId={automatedCampaignId}
    //       campaignSeriesName={campaignSeriesName}
    //       campaignPurpose={campaignPurpose}
    //       setCurrentStepIndex={setCurrentStepIndex}
    //       campaignSetState={campaignSetState}
    //       endCreateFlow={endCreateFlow}
    //     />
    //   );
    // if (index === 3) return <p>Step 4 Form</p>;
    // if (index === 2)
    //   return (
    //     <ActivateCampaign
    //       userRole={userRole}
    //       compliance={compliance}
    //       currentStepIndex={currentStepIndex}
    //       setCurrentStepIndex={setCurrentStepIndex}
    //       userData={userData}
    //       campaignSetState={campaignSetState}
    //       automatedCampaignDetails={automatedCampaignDetails}
    //       landingPagesData={landingPagesData}
    //       segmentListData={segmentListData}
    //       firmSettings={firmSettings}
    //       isFirmLevelAdmin={isFirmLevelAdmin}
    //     />
    //   );
    return <></>;
  };

  const fetchTargetInfoFromDB = async () => {
    try {
      const {
        data: {
          type,
          name,
          purpose,
          target_page,
          segment,
          start_date,
          event_date,
          cc_email_addresses,
          converted_tag,
          is_global_compliance,
          is_cascaded,
          approved_to_share,
          subscription_type,
          social_pages,
          parent_platforms
        },
      } =
        (await getAutomatedCampaignById({
          campaignId: automatedCampaignDetails.campaignId,
        })) || {};

      const targetInfo = {
        type,
        name,
        purpose,
        target_page,
        segment_id: segment?.id,
        start_date,
        event_date,
        cc_email_addresses,
        converted_tag,
        is_global_compliance,
        is_cascaded,
        approved_to_share,
        subscription_type,
        social_pages,
        parent_platforms
      };

      campaignSetState({
        automatedCampaigns: { ...automatedCampaignDetails, targetInfo },
      });
    } catch (error) {
      console.log('Error Fetching target info from DB ', error);
    }
  };

  useEffect(() => {
    console.log(
      'AUTOMATED CAMPAIGN DETAILS - - - -',
      automatedCampaignDetails,
      'ACTIVE STEP INDEX - - - -',
      get(automatedCampaignDetails, 'activeStepIndex', 'NOT FOUND')
    );
    if (get(automatedCampaignDetails, 'activeStepIndex', '') !== '')
      setCurrentStepIndex(get(automatedCampaignDetails, 'activeStepIndex', 0));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [automatedCampaignDetails]);

  // Refetch when currentIndex changes to 0.
  useEffect(() => {
    if (automatedCampaignId && currentStepIndex === 0 && get(automatedCampaignDetails, 'activeStepIndex', 0) === 0) fetchTargetInfoFromDB();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStepIndex]);

  return (
    <>
      <Box width={1}>
        <Flex display="flex" flexDirection="row" justifyContent="space-between" width="80%" margin="14px auto 0px">
        <Flex
          display="flex"
          justifyContent="flex-start"
          padding="10px 10px 0px 0px"
          style={{ color: '#000000', fontSize: '26px', fontWeight: 500 }}
        >
          New Campaign
        </Flex>
          {currentStepIndex !== 0 && (
            <Flex
              display="flex"
              justifyContent="flex-end"
              style={{ cursor: 'pointer' }}
            >
              <Button
                styles={{
                  borderRadius: '4px',
                  border: '1px solid #E4E7EC',
                  boxShadow: '',
                }}
                color="#101828"
                bgColor="#FFFFFF"
                margin="10px auto 0px"
                onClick={handleSaveAsDraftClick}
                boxShadow="0px 1px 2px 0px rgba(16, 24, 40, 0.12)"
              >
                Save As Draft
              </Button>
            </Flex>
          )}
        
        </Flex>
        <StepperV2
          stepperList={stepListLines}
          selectedIndex={currentStepIndex} // TODO: Replace with REDUX state.
        />

        {getFormByIndex(currentStepIndex)}
      </Box>
    </>
  );
};

const mapStateToProps = (state) => {
  const roleType = getRoleType(state);
  const isFirmLevelAdmin = get(state, 'user.data.details.company.firm', "false") === "true";
  const isEnterpriseLevelAdmin = get(state, 'user.data.details.company.enterprise', "false") === "true";
  const firmSettings = getFirmSettings({ isEnterpriseLevelAdmin, isFirmLevelAdmin, state });
  const isEnterpriseAdvisor = roleType === ROLE_TYPES.ADVISOR_ENTERPRISE;
  return {
    automatedCampaignDetails: get(state, 'campaign.automatedCampaigns', {}),
    automatedCampaignId: get(
      state,
      'campaign.automatedCampaigns.campaignId',
      0
    ),
    automatedCampaignTargetInfo: get(
      state,
      'campaign.automatedCampaigns.targetInfo',
      0
    ),
    userRole: getUserRole(state),
    compliance: get(state, 'user.data.compliance', ''),
    isEditMode: get(state, 'campaign.isEdit', false),
    landingPagesData: get(state, 'campaign.landingPages', []),
    segmentListData: get(state, 'campaign.segmentList', []),
    isFirmLevelAdmin,
    isEnterpriseLevelAdmin,
    isEnterpriseAdvisor: isEnterpriseAdvisor,
    firmSettings: firmSettings,
    firmsArray: get(state, 'user.data.details.company.firms', []),
    companyId: state?.user?.data?.details?.company?.id
  };
};

export default connect(mapStateToProps, null)(AutomatedCampaignsHome);
