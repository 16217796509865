import styled from "styled-components";

export const StatusBubble = styled.div`
    height: 10px;
    width: 10px;
    border-radius: 5px;
    padding: 2px;
    border: 0px;
    margin-right: 5px;
    background-color: ${({status}) => status==='Completed' ? '#33B21B' : status === 'Scheduled' ? '#00CCF2' : status === 'In Progress' ? '#326DE6' : '#FFAA00'};
`;

export const StatBubbleWrapper = styled.div`
    border: 1px solid #B8CDD6;
    border-radius: 20px;
    padding: 1px;
    background-color: #EFF2F5;
    margin-top: 8px;
`;

export const StatBubble = styled.div`
    width: 20px;
    height: 20px;
    color: #4E6780;
    font-size: 14px;
`;

export const VerticalSeparator = styled.div`
    border: 0.1px solid #CFD9E4; 
    height: ${({height}) => height || '30px'};
    margin-left: 10px;
    margin-right: 5px;
`;