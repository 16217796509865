import React, { useEffect, useMemo, useState } from 'react';
import { MenuItem, Select, Tab, Tabs } from '@mui/material';

import useRESTQuery from 'src/hooks/useRESTQuery';
import BarGraph from '../common/BarGraph';
import Table from 'src/componentsV2/Table';
import { EmptyState } from 'src/componentsV2';
import Skeleton from './Skeleton';
import {
  tableColumnStructureForContacts,
  tableColumnStructureForEmailAndSegment,
} from './TableColumnStructure';
import ContactListDetailModal from 'src/containers/AnalyticsContainer/ContactsTab/ContactListDetailModal';
import Pagination from 'src/componentsV2/Pagination';
import TableSkeleton from 'src/componentsV2/Table/Skeleton';
import { ErrorDisplay } from 'src/componentsV2/ErrorDisplay';
import ContactsRecommendation from './ContactsRecommendation';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MemoMail from 'src/assets/icons/social-media/Mail';

import {
  ChartContainer,
  ChartHeader,
  ContactsEngagementTableHeader,
  ContactsEngagementTableWrapper,
  ContactsPageWrapper,
  DropdownMenuContainer,
  PaginationContainer,
  dropdownStyles,
} from './styles';

import {
  dropdownOptions,
  transformListsResponse,
  transformGraphResponse,
} from './utils';
import { CONTACTS, EMAIL_LIST, LIMIT, SEGMENT_LIST } from './constants';

function Contacts({
  startDate,
  showEnterpriseDropdownSelectedOption,
  selectedUsers,
  userData,
}) {
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedDropdownOption, setSelectedDropdownOption] = useState(
    dropdownOptions[0].value
  );
  const [openModal, toggleModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});
  const [selectedTab, setSelectedTab] = useState(CONTACTS);
  const [contactsGraphData, setContactsGraphData] = useState([]);
  const isEnterprise =
    showEnterpriseDropdownSelectedOption === 'My Enterprise' ? true : false;

  const {
    data: topEmailListsData,
    isLoading: topEmailListsLoader,
    isError: topEmailListError,
    refetch: topEmailListRefetch,
    isFetching: topEmailListsFetching,
  } = useRESTQuery({
    key: 'email-list-contacts',
    dependencies: [isEnterprise, startDate, selectedUsers?.join(',')],
    endpoint: `/analytics/contacts/emaillists/top?from_date=${startDate}&limit=${LIMIT}&is_enterprise=${isEnterprise}${
      selectedUsers ? `&user_ids=${JSON.stringify(selectedUsers)}` : ''
    }`,
    options: {
      enabled: selectedDropdownOption === EMAIL_LIST,
      select: (res) => transformGraphResponse(res?.result),
    },
  });

  const {
    data: topSegmentListsData,
    isLoading: topSegmentListsLoader,
    isError: topSegmentListsError,
    refetch: topSegmentListsRefetch,
    isFetching: topSegmentListsFetching,
  } = useRESTQuery({
    key: 'segment-list-contacts',
    dependencies: [isEnterprise, startDate, selectedUsers?.join(',')],
    endpoint: `/analytics/contacts/segmentlists/top?from_date=${startDate}&limit=${LIMIT}&is_enterprise=${isEnterprise}${
      selectedUsers ? `&user_ids=${JSON.stringify(selectedUsers)}` : ''
    }`,
    options: {
      enabled: selectedDropdownOption === SEGMENT_LIST,
      select: (res) => transformGraphResponse(res?.result),
    },
  });

  const {
    data: emailListsData,
    isLoading: emailListsLoader,
    isError: emailListsError,
    refetch: emailListsRefetch,
    isFetching: emailListsFetching,
  } = useRESTQuery({
    key: 'email-lists',
    dependencies: [
      isEnterprise,
      startDate,
      selectedUsers?.join(','),
      currentPage,
    ],
    endpoint: `/analytics/contacts/emaillists?page_no=${currentPage}&limit=${LIMIT}&from_date=${startDate}&is_enterprise=${isEnterprise}${
      selectedUsers ? `&user_ids=${JSON.stringify(selectedUsers)}` : ''
    }`,

    options: {
      enabled: selectedTab === EMAIL_LIST,
      select: (res) => transformListsResponse(res?.result ?? {}),
    },
  });

  const {
    data: segmentListsData,
    isLoading: segmentListsLoader,
    isError: segmentListsError,
    refetch: segmentListsRefetch,
    isFetching: segmentListsFetching,
  } = useRESTQuery({
    key: 'segment-lists',
    dependencies: [
      isEnterprise,
      startDate,
      selectedUsers?.join(','),
      currentPage,
    ],
    endpoint: `/analytics/contacts/segmentlists?page_no=${currentPage}&limit=${LIMIT}&from_date=${startDate}&is_enterprise=${isEnterprise}${
      selectedUsers ? `&user_ids=${JSON.stringify(selectedUsers)}` : ''
    }`,

    options: {
      enabled: selectedTab === SEGMENT_LIST,
      select: (res) => transformListsResponse(res?.result ?? {}),
    },
  });

  const {
    data: contactListsData,
    isLoading: contactListsLoader,
    isError: contactListsError,
    refetch: contactListsRefetch,
    isFetching: contactListsFetching,
  } = useRESTQuery({
    key: 'contact-lists',
    dependencies: [
      isEnterprise,
      startDate,
      selectedUsers?.join(','),
      currentPage,
    ],
    endpoint: `/analytics/contacts?page_no=${currentPage}&limit=${LIMIT}&from_date=${startDate}&is_enterprise=${isEnterprise}${
      selectedUsers ? `&user_ids=${JSON.stringify(selectedUsers)}` : ''
    }`,

    options: {
      enabled: selectedTab === CONTACTS,
      select: (res) => transformListsResponse(res?.result ?? {}),
    },
  });

  useEffect(() => {
    setCurrentPage(1)
  }, [isEnterprise, selectedUsers])
  

  useEffect(() => {
    if (selectedDropdownOption === CONTACTS && currentPage === 1) {
      const data = transformGraphResponse(contactListsData?.data?.slice(0, 10));
      setContactsGraphData(data);
    }
  }, [
    contactListsData,
    startDate,
    selectedUsers,
    currentPage,
    selectedDropdownOption,
    isEnterprise,
  ]);

  const getGraphData = () => {
    switch (selectedDropdownOption) {
      case SEGMENT_LIST:
        return {
          data: topSegmentListsData,
          isLoading: topSegmentListsLoader || topSegmentListsFetching,
        };
      case EMAIL_LIST:
        return {
          data: topEmailListsData,
          isLoading: topEmailListsLoader || topEmailListsFetching,
        };

      default:
        return {
          data: contactsGraphData,
          isLoading: contactListsLoader && currentPage === 1,
        };
    }
  };

  const handleDropdownChange = (event) => {
    const value = event?.target?.value;
    setSelectedDropdownOption(value);
  };

  const handleTabSelection = (_, value) => {
    setSelectedTab(value);
    setCurrentPage(1);
  };

  const getUpdatedTable = () => {
    switch (selectedTab) {
      case CONTACTS:
        return {
          tableData: contactListsData,
          tableColumnStructure: tableColumnStructureForContacts(),
          isLoading: contactListsLoader || contactListsFetching,
          isError: contactListsError,
          type: 'contact',
        };
      case SEGMENT_LIST:
        return {
          tableData: segmentListsData,
          tableColumnStructure: tableColumnStructureForEmailAndSegment(true),
          isLoading: segmentListsLoader || segmentListsFetching,
          isError: segmentListsError,
          type: 'segments_list',
        };
      default:
        return {
          tableData: emailListsData,
          tableColumnStructure: tableColumnStructureForEmailAndSegment(),
          isLoading: emailListsLoader || emailListsFetching,
          isError: emailListsError,
          type: 'email_list',
        };
    }
  };

  const refetchQueries = () => {
    switch (true) {
      case selectedDropdownOption === EMAIL_LIST:
        topEmailListRefetch();
        break;
      case selectedDropdownOption === SEGMENT_LIST:
        topSegmentListsRefetch();
        break;
      case selectedTab === EMAIL_LIST:
        emailListsRefetch();
        break;
      case selectedTab === SEGMENT_LIST:
        segmentListsRefetch();
        break;
      case selectedTab === CONTACTS:
        contactListsRefetch();
        break;
      default:
        break;
    }
  };
  const totalRecords = () => {
    const updatedTable = getUpdatedTable();
    return updatedTable?.tableData?.pagination?.total_results ?? '-';
  };

  const handleModalClose = () => {
    toggleModal(false);
    setSelectedRow({});
  };

  const hasError =
    topEmailListError || topSegmentListsError || getUpdatedTable().isError;

  if (hasError) return <ErrorDisplay height="70vh" onClick={refetchQueries} />;

  return (
    <>
      <ContactsPageWrapper>
        {getGraphData()?.isLoading ? (
          <Skeleton />
        ) : (
          <ChartContainer>
            <ChartHeader>
              <MemoMail />
              <Select
                onChange={handleDropdownChange}
                value={selectedDropdownOption}
                disableUnderline
                IconComponent={ExpandMoreIcon}
                sx={dropdownStyles}
              >
                {dropdownOptions.map(({ label, value }, index) => (
                  <MenuItem key={index} value={value}>
                    <DropdownMenuContainer>{label ?? ''}</DropdownMenuContainer>
                  </MenuItem>
                ))}
              </Select>
            </ChartHeader>
            {getGraphData()?.data?.length > 0 ? (
              <BarGraph
                data={getGraphData()?.data}
                valueDataKey="engagement_score"
                width="100%"
                height={300}
                barSize={40}
                noInterval
                margin={{
                  top: 50,
                  bottom: 10,
                  left: 24,
                  right: 24,
                }}
                XAxisDataKey={
                  selectedDropdownOption === CONTACTS ? 'email' : 'name'
                }
              />
            ) : (
              <EmptyState
                height="300px"
                title="No Analytics Data"
                subTitle="Send out campaigns to get insights"
              />
            )}
          </ChartContainer>
        )}
        <ContactsRecommendation
          selectedUsers={selectedUsers}
          showEnterpriseDropdownSelectedOption={
            showEnterpriseDropdownSelectedOption
          }
          startDate={startDate}
          userData={userData}
        />
      </ContactsPageWrapper>
      <ContactsEngagementTableWrapper>
        <ContactsEngagementTableHeader>
          <h2>Contact Engagement</h2>
          <p>
            {totalRecords() <= 1
              ? `${totalRecords()} Record`
              : `${totalRecords()} Records`}
          </p>
        </ContactsEngagementTableHeader>
        <Tabs
          value={selectedTab}
          onChange={handleTabSelection}
          sx={{
            '& .MuiTabs-indicator': {
              backgroundColor: '#101828',
            },
            '& .MuiButtonBase-root.MuiTab-root': {
              textTransform: 'capitalize',
              '&.Mui-selected': {
                color: '#101828',
              },
            },
          }}
        >
          <Tab label="Contacts" value={CONTACTS} />
          <Tab label="Email Lists" value={EMAIL_LIST} />
          <Tab label="Segments" value={SEGMENT_LIST} />
        </Tabs>

        {getUpdatedTable()?.isLoading ? (
          <TableSkeleton tableRowLength={5} />
        ) : (
          <>
            <Table
              data={getUpdatedTable()?.tableData?.data}
              columnStructure={getUpdatedTable().tableColumnStructure}
              height="550px"
              onRowClick={(row) => {
                setSelectedRow(row);
                toggleModal(true);
              }}
              fixedHeader
              customMinHeight={false}
              customEmptyState={
                <EmptyState
                  height="680px"
                  title="No Analytics Data"
                  subTitle="Send out campaigns to get insights"
                />
              }
            />
            <PaginationContainer>
              <Pagination
                pageNumber={currentPage}
                pageSize={
                  getUpdatedTable()?.tableData?.pagination?.total_pages ?? 1
                }
                handlePageChange={(_, pageNumber) => {
                  setCurrentPage(pageNumber);
                }}
              />
            </PaginationContainer>
          </>
        )}
        {openModal && (
          <ContactListDetailModal
            isOpen={openModal}
            closeHandler={handleModalClose}
            row={selectedRow}
            type={getUpdatedTable()?.type ?? ''}
            startDate={startDate}
          />
        )}
      </ContactsEngagementTableWrapper>
    </>
  );
}

export default Contacts;
