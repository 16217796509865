import { createActions, handleActions } from 'redux-actions';
import { apiModuleDefaultState, createApiActions } from './helpers';

export const actions = createApiActions('newsletter');

export const moreActions = createActions({
  custom: {
    newsletter: {
      bucketRequest: (payload) => payload,
      bucketResponse: (payload) => payload,
      bucketDelete: (payload) => payload,
      bucketCountRequest: (payload) => payload,
      bucketCountResponse: (payload) => payload
    },
  },
});

const reducer = handleActions(
  {
    [actions.api.newsletter.request]: (state) => ({
      ...state,
      isFetching: true,
      isError: false,
      isEditMode: false,
      isBucketFetching: false,
      isDeleting: false
    }),
    [actions.api.newsletter.response]: (state, { payload }) => ({
      ...state,
      isFetching: false,
      isError: false,
      data: payload,
      isArticleDeleting: false,
      newsletterId: ''
    }),
    [actions.api.newsletter.error]: (state, { payload }) => ({
      ...state,
      isFetching: false,
      isError: true,
      error: payload,
    }),
    [actions.api.newsletter.setstate]: (state, {payload}) => ({
      ...state,
      ...payload,
    }),
    [moreActions.custom.newsletter.bucketRequest]: (state, { payload }) => ({
      ...state,
      isBucketFetching: true
    }),
    [moreActions.custom.newsletter.bucketResponse]: (state, { payload }) => ({
      ...state,
      isBucketFetching: false,
      bucketData: payload
    }),
    [moreActions.custom.newsletter.bucketDelete]: (state, { payload }) => ({
      ...state,
      isDeleting: true
    }),
    [actions.api.newsletter.delete]: (state, { payload }) => ({
      ...state,
      isArticleDeleting: true
    }),
    [moreActions.custom.newsletter.bucketCountRequest]: (state, { payload }) => ({
      ...state
    }),
    [moreActions.custom.newsletter.bucketCountResponse]: (state, { payload }) => ({
      ...state,
      bucketCount: payload
    }),
  },
  { ...apiModuleDefaultState, data: [], error: null }
);

export default reducer;