import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Flex } from '@rebass/grid';
import { get } from 'lodash';
import React, { useEffect, useState } from 'react'
import { ListItemTile, LoaderWrapper, ResponsiveImg } from '../../../components';
import { getThirdPartyThemes } from '../../../services/dashboard';
import { getMediaUrl } from '../../../utils';
import { TopInsightsWrapper } from '../DashboardContainer.styles';
import MioImageUpload from '../MIOEditor/MioImageUpload';
import { EditorSearchInput, CaretDownButton } from './EditorPanel.styles';

const EditorPanel = ({ setThirdPartyThemeId, exploreArticleRequest, popularTopicDetails, setClearEditorArticles, exploreSetState, setSelectedExploreTabIndex, thirdPartyThemeId, user, setShowArticleLoader, exploreArticles }) => {

  const [thirdPartyThemes, setThirdPartyThemes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [show, setShow] = useState(false);
  const [filterItems, setFilterItems] = useState([]);
  const [topicName, setTopicName] = useState('');
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    // console.log('USE EFFECT FIRED .. .. .. ');
    getThirdPartyThemes().then(res => {
      setLoading(false);
      setThirdPartyThemes(res.result.data);
    }).catch(err => {
      console.log(err);
    });
  }, []);

  useEffect(() => {
    // console.log('from useEffect: [THIRD PARTY THEME ID] - ', thirdPartyThemeId);
    if(!thirdPartyThemeId) {
      setFilterItems([]);
      setShow(false);
    }
  }, [thirdPartyThemeId])

  const handleClick = (item) => {
    console.log('[ITEM ID] - ', item);
    let selectedItem = [];
    selectedItem.push(item);
    setFilterItems(selectedItem);
    setTopicName(item.name);
    setShowArticleLoader(true);
    setThirdPartyThemeId(item.id);
    setClearEditorArticles(true);
    exploreSetState({ isChangeTheme: true, data: {}});
    setSelectedExploreTabIndex(5);
    exploreArticleRequest({
      params: {
        id: popularTopicDetails.id,
        source: 'mioEditorResult',
        date: '',
        mode: '',
        token: '',
        holdingsType: '',
        thirdPartyThemeId: item.id
      },
    });
  }

  const handleToggle = () => {
    if(show) {
      setFilterItems([]);
    }
    setShow(!show);
  }

  const handleFilter = (e) => {
    setTopicName(e.target.value);
    let filterThirdPartyItems = [];
    if (e.target.value.length === 0) {
      filterThirdPartyItems = thirdPartyThemes;
    } else {
      filterThirdPartyItems = thirdPartyThemes.filter(t => t.name.toUpperCase().includes(e.target.value.toUpperCase()));
    }
    setFilterItems(filterThirdPartyItems);
  }

  return (
    <TopInsightsWrapper>
      <LoaderWrapper isLoading={loading}>
        <Flex width='1' justifyContent='center' fontSize='13px' pt='18px' style={{ color: '#7dc4d0', whiteSpace: 'nowrap'}}>
          <span style={{width: '100%', display: 'inline', justifyContent: 'center', alignItems: 'center'}}>
            <ResponsiveImg onClick={() => setOpenModal(true)} style={{cursor: 'pointer'}} src='/assets/images/Icon-feather-trash-3.svg' />
            &nbsp;
            <span style={{position: 'relative', top: '-5px', cursor: 'pointer' }} onClick={() => setOpenModal(true)}>Customize Your Content.</span>
          </span>
        </Flex>
        <Flex width={1} flexDirection="column" justifyContent='center' alignItems='center' mt='9px'>
          <EditorSearchInput
            onClick={show ? '' : handleToggle}
            placeholder={show ? 'Start typing to find a topic' : 'Select from editorial topics'}
            readOnly={show ? false : true}
            onChange={handleFilter}
            style={{ cursor: show ? '' : 'pointer' }}
          />
          <CaretDownButton top={show ? '45.7%' : ''} onClick={handleToggle}>
            <FontAwesomeIcon color='#c95cfc' icon={faCaretDown} />
          </CaretDownButton>
          {show && (
            <Flex style={{ width: '456px', height: '98px', maxHeight: '98px', overflowX: 'hidden', overflowY: 'auto', backgroundColor: '#ffffff', boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)' }}>
              <Flex flexWrap='wrap' justifyContent='center' padding='0px 5px'>
              {filterItems && filterItems.length > 0 ? filterItems.map(item => (
                <ListItemTile
                  handleClick={() => handleClick(item)}
                  id={get(item, 'id')}
                  url={getMediaUrl(get(item, 'media_urls'))}
                  topic={get(item, 'name')}
                  width='140px'
                  type='no-icon'
                  noCover
                  styles={{border: thirdPartyThemeId === get(item, 'id') ? '1px solid #6351ed' : ''}}
                />
              )) : thirdPartyThemes && thirdPartyThemes.length > 0 ? thirdPartyThemes.map(item => (
                <ListItemTile
                  handleClick={() => handleClick(item)}
                  id={get(item, 'id')}
                  url={getMediaUrl(get(item, 'media_urls'))}
                  topic={get(item, 'name')}
                  width='140px'
                  type='no-icon'
                  noCover
                  styles={{border: thirdPartyThemeId === get(item, 'id') ? '1px solid #6351ed' : ''}}
                />
              )) : null}
              </Flex>
            </Flex>
          )}
        </Flex>
      </LoaderWrapper>
      <MioImageUpload 
        openModal={openModal} 
        onRequestClose={setOpenModal} 
        userData={user} 
        
      />
    </TopInsightsWrapper>
  )
}

export default EditorPanel;