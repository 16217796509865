import { Flex } from '@rebass/grid';
import React from 'react';
import { Grid, PublicHeader } from '../components';

const withContentLayout = (Component, flag) => {
  const ComposedComponent = () => {
    return (
      <div>
        {!flag && (
          <PublicHeader />
        )}
        <Flex width="100%" justifyContent="center" alignItems="center">
          <Component />
        </Flex>
      </div>
    );
  };
  return ComposedComponent;
};

export default withContentLayout;
