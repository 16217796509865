import { Flex } from '@rebass/grid';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import {
  Button,
  LoaderWrapper,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalHeader,
} from '../../../../components';
import { renameAutomatedCampaign } from '../../../../services/automated-campaigns';
import { themesData } from '../../../../utils';
import { SetObjectivesInput } from '../../../DripCampaignContainer/SetObjectives/SetObjectives.styles';

export const RenameModal = ({
  isOpen,
  handleClose,
  campaignName, 
  campaignRequest,
  campaignId,
}) => {
  const [newCampaignName, setNewCampaignName] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setNewCampaignName(campaignName);
  }, [campaignName]);

  const handleSubmit = () => {
    if (newCampaignName.length > 0) {
      let payload = {
        "new_name" : newCampaignName
      };
      setLoading(true);
      renameAutomatedCampaign({ id: campaignId, payload})
        .then((res) => {
          if (res.success) {
            setLoading(false);
            campaignRequest({
              type : ['automated'],
              limit: 10,
              offset: 1
            });
          }
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
        });
    }
  };

  return (
    <Modal
      bgColor="#e4e4e4"
      size="small"
      onRequestClose={handleClose}
      shouldCloseOnOverlayClick
      isOpen={isOpen}
    >
      <ModalHeader
        id="header"
        style={{ textAlign: 'left', color: themesData.charcoalGrey }}
      >
        <div>Rename Campaign</div>
        <ModalCloseButton onClick={handleClose} />
      </ModalHeader>
      <ModalBody style={{ textAlign: 'left' }}>
        <label>Campaign Name</label>
        <Flex width="100%" pt="8px" flexDirection="column">
          <SetObjectivesInput
            id="rename_campaign_input"
            maxLength="100"
            placeholder="Campaign Name"
            value={newCampaignName}
            onChange={(e) => setNewCampaignName(e.target.value)}
          />
          <LoaderWrapper isLoading={loading}>
            <Button onClick={handleSubmit} disabled={newCampaignName.length === 0}>
              Submit
            </Button>
          </LoaderWrapper>
        </Flex>
      </ModalBody>
    </Modal>
  );
};
