import styled from "styled-components";
import { themesData } from "../../../utils/constants";

export const DripTabs = styled.div.attrs({
    className: 'drip-tabs'
})`
    display: flex;
    align-items: center;
    justify-content: center;
    border: ${({ active }) => active ? '1px solid #33B21B' : '1px solid #DDE2EA'};
    color:  ${({ active, completed }) => active ? '#fff' : '#8C9CA4'};
    background-color: ${({ completed }) => completed ? '#33B21B' : ''};
    border-radius: 4px;
    font-size: 12px;
    padding: 5px 7px;
    margin-right: ${({ mr }) => mr || ''};
    box-shadow: ${({ active }) => active ? 'rgba(149, 157, 165, 0.2) 0px 8px 24px' : ''};
    position: relative;
    ${({ active }) => active && `
        &::after {
            position: absolute;
            z-index: 1;
            content: '';
            width: 0;
            height: 0;
            top: 102%;
            left: 50%;
            transform: translateX(-50%);
            border-right: solid 6px transparent;
            border-left: solid 6px transparent;
            border-top: solid 6px #33B21B;
        }
    `}
`;

export const DripVerticalLine = styled.div.attrs({
    className: 'drip-vertical-line'
})`
    border-left: 2px solid #CFD9E4;
    height: ${({ height }) => height || '40px'};
`;

export const DripFooterSection = styled.div.attrs({
    className: 'drip-footer-section'
})`
    display: flex;
    background-color: #fff;
    height: 10%;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    align-items: center;
    justify-content: space-between;
    width: 100%;
`;