import { Flex } from '@rebass/grid';
import React, { useState } from 'react';
import AnalyticsFilterPopup from './AnalyticsFilterPopup/AnalyticsFilterPopup';
import { Button } from '../../../components';
import { connect } from 'react-redux';
import { get, isEmpty } from 'lodash';
import AnalyticsFilterButton from './AnalyticsFilterPopup/AnalyticsFilterButton/AnalyticsFilterButton';

const AnalyticsFilter = ({
  setFilters,
  filters,
  analyticsSetState,
  selectedDate,
  selectedRepType,
  analyticsRequest,
  contactGroup,
  isAdmin,
  contactParam,
  campaignsParam,
  isApiRequest,
  leadType,
  campaignData
}) => {
  const [filterModal, toggleFilterModal] = useState(false);
  const filterDisabled = isEmpty(contactParam) && isEmpty(campaignsParam);

  return (
    <Flex
      width="94%"
      // padding="20px 50px"
      justifyContent="space-between"
      marginBottom='10px'
    >

      <AnalyticsFilterButton 
        toggleFilterModal={toggleFilterModal}
        filterDisabled={filterDisabled}
        analyticsSetState={analyticsSetState}
        contactParam={contactParam}
        campaignsParam={campaignsParam}
        selectedRepType={selectedRepType}
        analyticsRequest={analyticsRequest}
        leadType={leadType}
      />
      {filterModal && (
        <AnalyticsFilterPopup
          handleModalClose={() => {
            toggleFilterModal(false);
          }}
          setFilters={setFilters}
          filters={filters}
          analyticsSetState={analyticsSetState}
          selectedDate={selectedDate}
          selectedRepType={selectedRepType}
          analyticsRequest={analyticsRequest}
          contactGroup={contactGroup}
          isAdmin={isAdmin}
          contactParam={contactParam}
          campaignsParam={campaignsParam}
          isApiRequest={isApiRequest}
          leadType={leadType}
          campaignData={campaignData}
        />
      )}
    </Flex>
  );
};

const mapStateToProps = state => {
  return {
    leadType: get(state, 'analytics.leadType', '')
  }
}

export default connect(mapStateToProps)(AnalyticsFilter);
