import styled from 'styled-components';
import { Tabs, Tab, TabList, TabPanel } from 'react-tabs';
import { themesData } from '../../utils/constants';
const { white, greyWhite, purpleishBlue, charcoalGrey, black } = themesData;

export const StyledTabs = styled(Tabs)`
  width: 100%;
  border: ${({ border }) => border || ''};
  border-radius: ${({ borderRadius }) => borderRadius || ''};
  height: ${({ height }) => height || ''};
`;

export const StyledTabList = styled(TabList)`
  display: flex;
  justify-content: ${({ align }) => align || 'center'};
  list-style: none;
  margin: 0px;
  padding: 0px;
  width: 100%;
  height: ${({ height }) => height || ''};
`;

export const StyledTabListLeft = styled(StyledTabList)`
  justify-content: flex-start;
  margin-left: ${({ screenWidth, marginLeft }) => (screenWidth < 768 ? '0px' : marginLeft || ' 21px')};
  height: ${({ height }) => height || ''};
`;

export const StyledTab = styled(Tab)`
  font-size: 11px;
  font-weight: 500;
  position: relative;
  z-index: 0;
  padding: 5px 0px;
  padding-top: ${({ type }) => type === 'personalized' ? '1px' : '6px'};
  margin-right: -30px;
  cursor: pointer;
  overflow: hidden;

  &:after {
    content: '\\73';
    display: block;
    font-family: 'themes-icon';
    font-size: ${({ type }) => type === 'personalized' ? '105px' : '140px'};
    line-height: ${({ type }) => type === 'personalized' ? '3px' : '0'};
    position: relative;
    z-index: -1;
    top: -7px;
    transform: rotate(180deg);
    text-shadow: 0px 0px 0px #d4d4d4;
  }

  &:nth-child(even) {
    &:after {
      color: ${({ theme }) =>
        theme === 'light'
          ? '#f1f1f1'
          : theme === 'dark'
          ? '#c8c8c8'
          : '#d4d4d4'};
    }
  }

  &:nth-child(odd) {
    &:after {
      color: ${({ theme }) =>
        theme === 'light'
          ? '#d4d4d4'
          : theme === 'dark'
          ? '#b8b8b8'
          : '#e6e6e6'};
    }
  }

  &:last-child {
    margin-right: 0px;
  }

  &.react-tabs__tab--selected {
    z-index: 10;
    &:after {
      color: ${({ theme }) =>
        theme === 'light' ? '#e4e4e5' : theme === 'dark' ? '#e4e4e4' : '#fff'};
    }
  }
  @media (max-width: 768px) {
    padding-left: 0px;
    padding-right: 0px;

    &.react-tabs__tab--selected {
      z-index: 0;
    }
  } 
`;

export const TabTypeThree = styled(StyledTab)`
  padding-top: ${({ type }) => type === 'dashboard' ? '6px' : '10px'};
  padding-bottom: 10px;
  margin-right: 0px;
  padding-left: 5px;
  padding-right: 5px;
  left: 0px;
  width: ${({ width }) => width || '140px'};
  font-size: 13px;
  ${({currentTab}) => currentTab ? `
    background: #7dc5d0;
    color: ${white};
    border: 1.3px solid #7dc5d0;
  `: `
    background: ${greyWhite};
    color: ${charcoalGrey};
    border: 1px solid white;
    height: ${({ type }) => type === 'dashboard' ? '35px' : ''};
  `}
  zoom: ${({ type }) => type === 'analytics-tab' ? '1.3' : ''};

  &:last-child {
    left: 0px;
    margin-right: 0px;
    top: ${({ type }) => type === 'members' ? '-3px' :''};
  }
  &:after {
    top: -7px;
    transform: rotate(0deg);
    content: none;
  }
  position: relative;
  @media (max-width: 768px) {
    // margin-right: -30px;   
    font-size:${({ font }) => font <768  ? '7px' :''};
    font-weight:${({ font }) => font <768  ? '500' :''};
  }  
`;

export const TabTypePublisher = styled(StyledTab)`
  padding-top: ${({ type }) => type === 'dashboard' ? '6px' : '10px'};
  border-top-right-radius: ${({ type }) => type !== 'admin' ? '5px' : '0'};
  padding-bottom: 10px;
  margin-right: 0px;
  padding-left: 5px;
  padding-right: 5px;
  left: 0px;
  width: ${({type}) => type !== 'admin'? '100%' : '50%'};
  border: 1px solid lightgray;
  border-top-left-radius: 7px;
  font-size: 16px;
  ${({currentTab}) => currentTab ? `
    background: #7dc5d0;
    color: ${white};
    border: 1.3px solid #7dc5d0;
  `: `
    background: ${greyWhite};
    color: ${black};
    border: 1px solid #ECECEC;
    
    height: ${({ type }) => type === 'dashboard' ? '35px' : ''};
  `}
  zoom: ${({ type }) => type === 'analytics-tab' ? '1.3' : ''};

  &:last-child {
    left: 0px;
    margin-right: 0px;
    top: ${({ type }) => type === 'members' ? '-3px' :''};
  }
  &:after {
    top: -7px;
    transform: rotate(0deg);
    content: none;
  }
  position: relative;
  @media (max-width: 768px) {
    // margin-right: -30px;   
    font-size:${({ font }) => font <768  ? '7px' :''};
    font-weight:${({ font }) => font <768  ? '500' :''};
  }  
`;

export const TabTypeMemberPublisher = styled(StyledTab)`
  padding-top: ${({ type }) => type === 'dashboard' ? '6px' : '10px'};
  border-top-left-radius: ${({ type }) => type !== 'admin' ? '5px' : '0'};
  padding-bottom: 10px;
  margin-right: 0px;
  padding-left: 5px;
  padding-right: 5px;
  left: 0px;
  width: ${({ width }) => width || '100%'};
  border: 1px solid teal;
  border-top-right-radius: 7px;
  font-size: 16px;
  ${({currentTab}) => currentTab ? `
    background: #7dc5d0;
    color: ${white};
    border: 1.3px solid #7dc5d0;
  `: `
    background: ${greyWhite};
    color: ${black};
    border: 1px solid #ECECEC;
    height: ${({ type }) => type === 'dashboard' ? '35px' : ''};
  `}
  zoom: ${({ type }) => type === 'analytics-tab' ? '1.3' : ''};

  &:last-child {
    left: 0px;
    margin-right: 0px;
    top: ${({ type }) => type === 'members' ? '-3px' :''};
  }
  &:after {
    top: -7px;
    transform: rotate(0deg);
    content: none;
  }
  position: relative;
  @media (max-width: 768px) {
    // margin-right: -30px;   
    font-size:${({ font }) => font <768  ? '7px' :''};
    font-weight:${({ font }) => font <768  ? '500' :''};
  }  
`;

export const TabTypeTwo = styled(StyledTab)`
  padding-top: 6px;
  margin-right: -53px;
  padding-left: 5px;
  padding-right: 5px;
  left: 0px;
  zoom: ${({ type }) => type === 'analytics-tab' ? '1.3' : ''};

  &:last-child {
    left: 0px;
    margin-right: 0px;
    top: ${({ type }) => type === 'members' ? '-3px' :''};
  }
  &:after {
    top: -7px;
    transform: rotate(0deg);
  }
  position: relative;
  @media (max-width: 768px) {
    margin-right: -30px;   
    font-size:${({ font }) => font <768  ? '7px' :''};
    font-weight:${({ font }) => font <768  ? '500' :''};
  }  
`;

export const StyledTabPanel = styled(TabPanel)`
  overflow-y: auto;
  overflow-x: hidden;
  ${({ overflow }) => overflow && `overflow: ${overflow};`}
  background: ${({ background }) => background || 'transparent'};
  height: ${({ height }) => height || ''};
`;

export const VerticalTabList = styled(StyledTabList)`
  flex-direction:  ${({ screenWidth }) => (screenWidth < 768 ? 'row' :'column')};
  max-width:  ${({ screenWidth }) => (screenWidth < 768 ? '' :'20%')};
`;

export const VerticalTab = styled(Tab)`
  width: 100%;
  display: flex;
  flex-grow: 1;
  flex-basis: 0;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

export const VerticalTabs = styled(Tabs)`
  display: flex;
  flex-direction:  ${({ screenWidth }) => (screenWidth < 768 ? 'column' :'row')};
  width: 100%;
  height: 100%;
  border-bottom: 1px solid #e4e4e4;
  zoom: ${({ type }) => (type === 'members-tab' ? '1.3' : '')};
`;

export const VerticalTabPanel = styled(TabPanel)`
  flex-grow: 1;
  flex-basis: 0;
  align-items: center;
  display: none;
  ${({ width }) => `width: ${width};`}
  &.react-tabs__tab-panel--selected {
    display: ${({ display }) => display ? display : 'flex'};
    overflow:  ${({ screenWidth }) => (screenWidth < 768 ? '' :'auto')};
    padding-bottom: 10px;
    padding-top: ${({ paddingTop }) => paddingTop || ''};
  }
`;
