import React, { useState } from 'react';
import {
  LoaderWrapper,
  Modal,
  ModalBody,
  ModalCloseButton,
} from '../../components';
import { HoverButton } from '../ConfigureContainer/EmailList/EmailList.styles';
import { Flex } from '@rebass/grid';

export const UnsubscribeModal = ({
  isOpen,
  handleClose,
  clickHandler,
  generalNewsletters,
  firmUpdates,
  events,
  ecards,
}) => {
  const [loading, setLoading] = useState(false);
  return (
    <Modal
      onRequestClose={handleClose}
      shouldCloseOnOverlayClick
      size="small"
      isOpen={isOpen}
    >
      <ModalCloseButton onClick={handleClose} />
      <ModalBody>
        <div
          style={{
            fontWeight: 'bold',
            fontSize: '14px',
            color: '#101828',
            fontFamily: 'Poppins',
            //textAlign: 'left',
          }}
        >
          Are you sure want to unsubscribe from following communication?
          <ul>
            {firmUpdates && (
              <li style={{ width: '100%', textAlign: 'left' }}>Firm Updates</li>
            )}
            {generalNewsletters && (
              <li style={{ width: '100%', textAlign: 'left' }}>Newsletters</li>
            )}
            {ecards && (
              <li style={{ width: '100%', textAlign: 'left' }}>E-Cards</li>
            )}
            {events && (
              <li style={{ width: '100%', textAlign: 'left' }}>
                Event Invitations
              </li>
            )}
          </ul>
        </div>
        <LoaderWrapper isLoading={loading}>
          <Flex display="flex" justifyContent="center" flexDirection="row">
            <HoverButton
              width="160px"
              margin="9px 10px 0px 0px"
              padding="5px 28px"
              bgColor="#fff"
              hoverBackground="#6351ed"
              hoverColor="#fff"
              color="#6351ed"
              borderColor="#fff"
              onClick={() => {
                setLoading(true);
                clickHandler();
              }}
            >
              Yes
            </HoverButton>
            <HoverButton
              width="160px"
              margin="9px 0px 0px 0px"
              padding="5px 28px"
              bgColor="#fff"
              color="#6351ed"
              hoverBackground="#6351ed"
              hoverColor="#fff"
              borderColor="#fff"
              onClick={handleClose}
            >
              No
            </HoverButton>
          </Flex>
        </LoaderWrapper>
      </ModalBody>
    </Modal>
  );
};

export const UnsubscribeFromAllModal = ({
  isOpen,
  handleClose,
  clickHandler,
}) => {
  const [loading, setLoading] = useState(false);
  return (
    <Modal
      onRequestClose={handleClose}
      shouldCloseOnOverlayClick
      size="small"
      isOpen={isOpen}
    >
      <ModalCloseButton onClick={handleClose} />
      <ModalBody>
        <div
          style={{
            fontWeight: 'bold',
            fontSize: '14px',
            color: '#101828',
            fontFamily: 'Poppins',
            //textAlign: 'left',
          }}
        >
          Are you sure want to unsubscribe from all communication?
        </div>
        <LoaderWrapper isLoading={loading}>
          <Flex display="flex" justifyContent="center" flexDirection="row">
            <HoverButton
              width="160px"
              margin="9px 10px 0px 0px"
              padding="5px 28px"
              bgColor="#fff"
              hoverBackground="#6351ed"
              hoverColor="#fff"
              color="#6351ed"
              borderColor="#fff"
              onClick={() => {
                setLoading(true);
                clickHandler();
              }}
            >
              Yes
            </HoverButton>
            <HoverButton
              width="160px"
              margin="9px 0px 0px 0px"
              padding="5px 28px"
              bgColor="#fff"
              color="#6351ed"
              hoverBackground="#6351ed"
              hoverColor="#fff"
              borderColor="#fff"
              onClick={handleClose}
            >
              No
            </HoverButton>
          </Flex>
        </LoaderWrapper>
      </ModalBody>
    </Modal>
  );
};
