import { Flex } from '@rebass/grid';
import styled from 'styled-components';

export const PreviewWrapper = styled.div.attrs({
  className: 'preview-wrapper'
})`
  width: 100%;
  display: flex;
  justify-content: center;
  height: 450px;
  max-height: 450px;
  overflow-x: hidden;
  overflow-y: auto;
`;

export const PreviewBox = styled.div.attrs({
  className: 'preview-box'
})`
  display: flex;
  flex-direction: column;
  border: 1px solid #707070;
  width: 725px;
  background-color: #ffffff;
  overflow: inherit;
`;

export const NewsletterScheduleWrapper = styled.div.attrs({
  className: 'newsletter-schedule-wrapper'
})`
  position: relative;
  top: 14px;
  
  &:hover .tooltiptext {
    visibility: visible;
  }
`;

export const NewsletterScheduledToolTip = styled.span`
  visibility: hidden;
  width: ${({ width }) => width || '100%'};
  max-width: 200px;
  min-width: 150px;
  background-color: #404040;
  color: #fff;
  text-align: left;
  border-radius: 4px;
  padding: 5px 8px;
  position: absolute;
  z-index: 10;
  top: ${({ top }) => top || '-4px'};
  left: 28px;
  font-size: 10px;
  &::after {
    content: "";
    position: absolute;
    top: 50%;
    right: 100%;
    margin-top: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent transparent black;
    transform: rotate(180deg);
    -webkit-transform: rotate(180deg); /* WebKit */
    -moz-transform: rotate(180deg); /* Mozilla */
    -o-transform: rotate(180deg); /* Opera */
    -ms-transform: rotate(180deg); /* Internet Explorer */
}
`;

export const SignatureSection = styled.div.attrs({
  className: 'signature-section'
})`
  text-align: left;
  p {
    margin: 0px !important;
    color: #848484;
  }
`;

export const PreviewButtonWrapper = styled(Flex).attrs({
  className: 'preview-button-wrapper'
})`
  .react-datepicker-popper {
    top: ${({ top }) => top || '-370px !important'};
    left: ${({ left }) => left || '-113px !important'};
  }
`;

export const DateWrapper = styled(Flex).attrs({
  className: 'date-wrapper'
})`
  .react-datepicker-popper {
    left: ${({ left }) => left || '-113px !important'};
  }
`;

export const MessageWrapper = styled.div`
  span {
    white-space: pre-wrap;
  }

  p {
    color: #848484;
  }
  p:empty:before {
    content: ' ';
    white-space: pre;
  }
`;