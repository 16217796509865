/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { get, isEmpty } from 'lodash';
import EmailList from '../ConfigureContainer/EmailList';
import { actions as contactsActions } from '../../redux/modules/contacts.module';
import { actions as loginActions } from '../../redux/modules/login.module';
import { getContactsGroups } from '../../services/configure';
import { getUserDetails } from '../../redux/selector/login.selectors';
import {
  getContactListForEmail,
} from '../../utils';
import { actions as socialNetworkActions } from '../../redux/modules/socialNetwork.module';
import { ConfigureContainerStyle } from './styles';

const AdvisorContacts = ({
  contactError,
  contactsList,
  contactsRequest,
  contactsDelete,
  contactsCreate,
  userData,
  isContactsFetching,
  socialNetworkData,
  contactGroupsList,
  selectedContactGroup,
  loginRequest,
  paginationData,
  navigateAfter,
  socialNetworkStatusRequest,
  selectedContactGroupDefault,
  socialNetworkDataFetching,
  socialNetworkDelete,
  customContactGroupList,
  contactsCount,
  contactGroups,
  userId,
  noRecords
}) => {
  const [isUpdateContact, setIsUpdateContact] = useState(false);
  const [socialAccountURL, setSocialAccountURL] = useState(null);
 
  const retrieveGroupsList = () => {
    contactsRequest({ params: { contactGroup: '' } });
  };

  const contactListChange = (contactGroup, type, networkId, searchTerm) => {
    contactsRequest({ params: { contactGroup, type, networkId, searchTerm } });
  };

  const onContactDelete = (
    emailList,
    shouldDeleteGroup,
    shouldDeleteMultipleContactGroup
  ) => {
    if (shouldDeleteGroup) {
      contactsDelete({
        params: {
          contactGroup: '',
          emailList,
          shouldDeleteGroup,
          selectedGroup: '',
          shouldDeleteMultipleContactGroup,
        },
      });
    } else {
      contactsDelete({
        params: {
          contactGroup: selectedContactGroup,
          emailList,
          shouldDeleteGroup: false,
          selectedGroup: selectedContactGroup,
          shouldDeleteMultipleContactGroup,
        },
      });
    }
  };

  const onContactCreate = (values, isUpdate, isModal) => {
    console.log(values);
    contactsCreate({
      params: {
        ...values,
        contactGroup: isUpdate
          ? values.name
          : contactError &&
            contactError.contactGroup &&
            contactError.name === values.name
          ? contactError.contactGroup
          : '',
        isModal: isModal,
      },
    });
  };

  useEffect(() => {
    const windowBodyClassList = document.body.classList;
    retrieveGroupsList();
    if (!windowBodyClassList.contains('campaign')) {
      windowBodyClassList.add('campaign');
    }
  }, [])

 
  return (
    <ConfigureContainerStyle>
      <EmailList
          contactError={contactError}
          contactsList={contactsList}
          contactGroups={contactGroups}
          contactGroupsList={contactGroupsList}
          contactListChange={contactListChange}
          onContactDelete={onContactDelete}
          contactsCreate={onContactCreate}
          setIsUpdateContact={setIsUpdateContact}
          isContactsFetching={isContactsFetching}
          selectedContactGroup={selectedContactGroup}
          socialAccountURL={socialAccountURL}
          socialNetworkData={socialNetworkData}
          retrieveGroupsList={retrieveGroupsList}
          userData={userData}
          userId={userId}
          loginRequest={loginRequest}
          contactsRequest={contactsRequest}
          paginationData={paginationData}
          navigateAfter={navigateAfter}
          socialNetworkStatusRequest={socialNetworkStatusRequest}
          selectedContactGroupDefault={selectedContactGroupDefault}
          socialNetworkDataFetching={socialNetworkDataFetching}
          socialNetworkDelete={socialNetworkDelete}
          setSocialAccountURL={setSocialAccountURL}
          customContactGroupList={customContactGroupList}
          contactsCount={contactsCount}
          noRecords={noRecords}
        />
    </ConfigureContainerStyle>
  );
};

const mapStateToProps = (state) => {
  return {
    userId: state.user.data && state.user.data.uid,
    contactError: state.contacts.error,
    contactsList: state.contacts.data,
    contactGroups: state.contacts.contactGroups,
    contactGroupsList: state.contacts.groupData,
    selectedContactGroup: state.contacts.selectedContactGroup,
    userData: getUserDetails(state),
    isContactsFetching: state.contacts.isFetching || false,
    socialNetworkData: state.socialNetwork.data,
    paginationData: state.contacts.pagination,
    navigateAfter: state.contacts.afterData,
    selectedContactGroupDefault: state.contacts.selectedContactGroupDefault,
    socialNetworkDataFetching: state.socialNetwork.isFetching,
    customContactGroupList: state.contacts.customContactGroupList,
    contactsCount: state.contacts.totalCount,
    noRecords: state.contacts.noRecords
  };
};

const mapDispatchToProps = {
  contactsRequest: contactsActions.api.contacts.request,
  contactsDelete: contactsActions.api.contacts.delete,
  contactsCreate: contactsActions.api.contacts.create,
  loginRequest: loginActions.api.login.request,
  socialNetworkStatusRequest: socialNetworkActions.api.socialNetwork.request,
  socialNetworkDelete: socialNetworkActions.api.socialNetwork.delete,
};

export default connect(mapStateToProps, mapDispatchToProps)(AdvisorContacts);