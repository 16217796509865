/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { get, isEmpty } from 'lodash';
import { connect } from 'react-redux';
import { Wrapper } from '../components/CommonStyles';
import {
  AdminHeader,
  AdvisorHeader,
  Loader,
  AdvisorSidebar,
} from '../components';
import {
  getChannelId,
  getUserDetails,
  getUserRole,
  getRoleType,
  getFirmSettings,
} from '../redux/selector';
import { actions as loginActions } from '../redux/modules/login.module';
import { actions as channelActions } from '../redux/modules/channel.module';
import { actions as themeActions } from '../redux/modules/theme.module';
import { actions as socialNetworkActions } from '../redux/modules/socialNetwork.module';
import { actions as contactsActions } from '../redux/modules/contacts.module';
import { actions as inviteActions } from '../redux/modules/invite.module';
import { actions as contentlibActions } from '../redux/modules/content-lib.module';
import { moreActions as newsletterMoreActions } from '../redux/modules/newsletter.module';
import { mioEditorUploadImage } from '../services/dashboard';
import { actions as campaignActions } from '../redux/modules/campaign.module';
import MioImageUpload from 'src/containers/DashboardContainer/MIOEditor/MioImageUpload';

const withDiscoverLayout = (Component) => {
  const ComposedComponent = ({
    loginRequest,
    history,
    userName,
    userRole,
    isFetching,
    roleType,
    socialNetworkRequest,
    socialNetworkData,
    contentlibSetState,
    user,
    bucketCount,
    campaignSetState,
    channelId,
    channelRequest,
    channelData,
    firmSettings,
    isFirmLevelAdmin,
    isEnterpriseLevelAdmin,
  }) => {
    const [menuToggle, setMenuToggle] = useState(false);
    const [showEditorModal, setShowEditorModal] = useState(false);
    const [openSuccessModal, setOpenSuccessModal] = useState(false);
    const [imageUpload, setImageUpload] = useState(false);
    const [selectedTabIndex, setSelectedTabIndex] = useState(0);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [firstTime, setFirstTime] = useState(false);

    useEffect(() => {
      if (!userName) {
        loginRequest();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
      contentlibSetState({ data: {} });
      campaignSetState({
        campaignId: '',
        createFlow: true,
        createMode: false,
        curFlow: '',
        selectedIndex: 0,
        setObjectives: {},
        setTopics: {},
        contentWeeks: [],
        planCampaign: {},
        campaignArticles: [],
        scheduleTimings: {},
        selectedWeek: '',
        selectedDays: '',
        selectedArticles: [],
        publishedContent: {},
        defaultTemplates: [],
        setTemplate: '',
        savedTemplate: null,
        scheduleTypes: {},
        isEdit: false,
        isFromAddCampaign: false,
        summaries: null,
        seriesId: '',
        bookmarkArticles: [],
        recommendedArticles: [],
        myContentArticles: [],
        leadGenReferralData: null,
        template_id: ''
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
      if (
        !firstTime &&
        get(user, 'details.user.is_impersonator', '') === 'true' &&
        window.sessionStorage.getItem('login') !== 1 &&
        window.sessionStorage.getItem('token')
      ) {
        setFirstTime(true);
        window.sessionStorage.setItem('login', 1);
        loginRequest({ noRefresh: true });
      }
    }, [firstTime]);

    useEffect(() => {
      window.Intercom('boot', {
        app_id: 'yz57xdx1',
        email: get(user, 'details.user.email', ''),
        user_id: get(user, 'uid', ''),
        created_at: new Date().getTime(),
      });
    }, []);

    useEffect(() => {
      if (isEmpty(socialNetworkData)) {
        socialNetworkRequest();
      }
      if (isEmpty(channelData)) {
        channelRequest();
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userRole]);

    const HeaderComponent = userRole === 'admin' ? AdminHeader : AdvisorHeader;

    const handleMenuToggle = () => {
      setMenuToggle(!menuToggle);
    };

    const toggleEditorModal = () => {
      if (showEditorModal) {
        onRequestImageClose();
      } else {
        handleModalOpen();
      }
    };

    const handleModalOpen = () => {
      setShowEditorModal(true);
    };

    const onRequestImageClose = (fromSave) => {
      setShowEditorModal(false);
    };

    const handleFileUploadChange = (file) => {
      let payload = {
        media1: new File([file[0]], `${file[0].name}`),
        enable: true,
      };
      mioEditorUploadImage(payload).then((res) => {
        if (res && res.result.success) {
          setShowEditorModal(false);
          setOpenSuccessModal(true);
        }
      });
    };

    const onRequestSuccessClose = () => {
      setOpenSuccessModal(false);
      setImageUpload(true);
      window.localStorage.setItem('ImageUploadFirst', true);
      window.localStorage.setItem('ImageUpload', true);
      loginRequest();
    };

    if (isFetching) {
      return <Loader height="100vh" />;
    }

    const setTabIndex = (index) => {
      setSelectedTabIndex(index);
    };
    return (
      <div>
        <HeaderComponent
          userName={userName}
          pathName={history.location.pathname}
          roleType={roleType}
          handleMenuToggle={handleMenuToggle}
          handleModalOpen={handleModalOpen}
          handleTabIndex={setTabIndex}
          userData={user}
          bucketCount={bucketCount}
          setOpenDeleteModal={setOpenDeleteModal}
        />
        <Wrapper style={{ background: '#FCFDFD' }}>
          {window.innerWidth < 768 ? (
            <AdvisorSidebar
              toggle={menuToggle}
              userName={userName}
              roleType={roleType}
            ></AdvisorSidebar>
          ) : null}
          <Component
            userData={user}
            roleType={roleType}
            imageUpload={imageUpload}
            selectedTabIndex={selectedTabIndex}
            showEditorModal={showEditorModal}
            toggleEditorModal={toggleEditorModal}
            onRequestClose={onRequestImageClose}
            campaignSetState={campaignSetState}
            channelId={channelId}
            isFirmLevelAdmin={isFirmLevelAdmin}
            firmSettings={firmSettings}
            isEnterpriseLevelAdmin={isEnterpriseLevelAdmin}
          />
          {/* <Feedback /> */}
        </Wrapper>
        <MioImageUpload
          openModal={showEditorModal}
          onRequestClose={onRequestImageClose}
          onFileUploadChange={handleFileUploadChange}
          userData={user}
        />
      </div>
    );
  };

  const mapStateToProps = (state) => {
    const isEnterpriseLevelAdmin =
      get(state, 'user.data.details.company.enterprise', 'false') === 'true';
    const { first: firstName } = get(getUserDetails(state), 'name', {});
    const isFirmLevelAdmin =
      get(state, 'user.data.details.company.firm', 'false') === 'true';
    const firmSettings = getFirmSettings({
      isEnterpriseLevelAdmin,
      isFirmLevelAdmin,
      state,
    });

    return {
      userName: firstName,
      userRole: getUserRole(state),
      channelData: get(state, 'channel.data', []),
      themesList: state.theme.data || [],
      isFetching: state.user.isFetching,
      roleType: getRoleType(state),
      contactsList: state.contacts.data || [],
      socialNetworkData: state.socialNetwork.data,
      channelId: getChannelId(state),
      inviteSending: state.invite.isFetching,
      user: state.user.data,
      contactsFetching: state.contacts.isFetching,
      bucketCount: get(state, 'newsletter.bucketCount', 0),
      isEnterpriseLevelAdmin,
      isFirmLevelAdmin,
      firmSettings,
    };
  };
  const mapDispatchToProps = {
    loginRequest: loginActions.api.login.request,
    channelRequest: channelActions.api.channel.request,
    themesRequest: themeActions.api.theme.request,
    socialNetworkRequest: socialNetworkActions.api.socialNetwork.request,
    contactsRequest: contactsActions.api.contacts.request,
    inviteRequest: inviteActions.api.invite.request,
    contentlibSetState: contentlibActions.api.contentlib.setstate,
    bucketCountRequest:
      newsletterMoreActions.custom.newsletter.bucketCountRequest,
    campaignSetState: campaignActions.api.campaign.setstate,
  };
  return connect(mapStateToProps, mapDispatchToProps)(ComposedComponent);
};

export default withDiscoverLayout;
