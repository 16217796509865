import React from 'react';
import { EmailInfo, TableRowContent } from './styles';

export const tableColumnStructure = () => {
  return [
    {
      header: {
        key: 'name',
        title: 'Contact Name',
        enableSort: false,
      },
      width: '400px',
      render: (row) => (
        <div>
          <TableRowContent>{row?.name ?? ''}</TableRowContent>
          <EmailInfo>{row?.email ?? ''}</EmailInfo>
        </div>
      ),
    },
    {
      header: {
        key: 'engagementScore',
        title: 'Engagement Score',
        enableSort: false,
      },
      width: '200px',
      render: (row) => (
        <div>
          <TableRowContent>{row?.engagementScore ?? '-'}</TableRowContent>
        </div>
      ),
    },
    {
      header: {
        key: 'openStatus',
        title: 'Open Status',
        enableSort: false,
      },
      width: '200px',
      render: (row) => (
        <div>
          <TableRowContent>{row?.openStatus}</TableRowContent>
        </div>
      ),
    },
  ];
};
