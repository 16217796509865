/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import { ColorPallete, Typography, oktaHelper } from '../../utils';
import { Flex } from '@rebass/grid';
import { Loader } from '../../components';
import useQuery from '../../customHooks/useQuery';


function NutureRedirect() {

    const query = useQuery();

    useEffect(() => {
        window.sessionStorage.setItem('redirect_type', 'nurture');
        window.sessionStorage.setItem('segment_id', query.get('segment_id'));
        window.sessionStorage.setItem('package_name', query.get('package_name'));
        login();
    }, []);
    

     const login = async () => {
        await oktaHelper.signInWithRedirect();
      }

    return (
        <Flex 
            width="100%" 
            justifyContent="center" 
            flexDirection="column"
            fontSize={Typography.TextLarge} 
            color={ColorPallete.primaryPurple}
            style={{
                position: 'absolute',
                top: '45%'
            }}
        >
            <Loader />
            Signing in to Marketing Automation...
        </Flex>
    )
}

export default NutureRedirect