import styled from 'styled-components';
import Modal from 'react-modal';
import { Tab, TabPanel } from 'react-tabs';
import { StyledTabList } from '../Tabs/Tabs.styles';
import { themesData } from '../../utils';

export const ShareModalDiv = styled.div`
  text-align: center;
`;
export const ShareModalList = styled.ul`
  padding: 0;
  margin: 0;
  display: flex;
  ${({ isFromAddContent }) =>
    isFromAddContent && 'flex-direction: row-reverse;'}
  ${({ isFromAddContent }) => isFromAddContent && 'margin-top: 0px;'}
  li:first-child {
    width: ${(props) => (props.isFromAddContent ? '25%' : '30%')};
  }
  li:last-child {
    width: ${(props) =>
      props.screenWidth < 768
        ? '100%'
        : props.isFromAddContent
        ? '72%'
        : '65%'};
    padding-left: ${(props) => (props.isFromAddContent ? '0' : '10px')};
  }
`;
export const ShareModalListItem = styled.li`
  display: ${(props) => (props.screenWidth < 768 ? 'none' : 'inline-block')};
  &:first-child {
  }
  vertical-align: top;
  margin: 0 auto;
  list-style-type: none;
`;

export const ShareContentPost = styled.div`
  width: 200px;
  border-radius: 10px;
  border: 1px solid #cacaca;
`;
export const ShareContentPostImage = styled.img`
  background-size: cover;
  width: 100%;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  object-fit: cover;
  height: 127px;
`;
export const ShareModalContent = styled.div`
  padding: 0 15px;
  text-align: left;
`;
export const ShareModalContentLink = styled.a`
  text-decoration: none;
`;
export const ArticleTitle = styled.h3`
  font-weight: 600;
  text-align: left;
  color: #545454;
  font-size: 13px;
  margin: 10px 0 0;
  word-break: break-word;
  line-height: 1.1;
`;
export const ArticleTime = styled.h5`
  color: #a5a5a5;
  font-size: 11px;
  text-align: left;
  font-weight: 100;
  margin-top: 10px;
  margin-bottom: 10px;
`;
export const ShareSocialDiv = styled.div`
  padding: ${({ isFromAddContent }) =>
    isFromAddContent ? '0px 0px 0px 0px' : '14px 0px 0px 0px'};
  background: white;
  border-radius: 10px;
  ${({ isFromAddContent }) => !isFromAddContent && `overflow-y: auto;`}
  height: ${({ height, isFromAddContent, fromMyClients }) =>
    isFromAddContent ? '175px' : fromMyClients ? '' : height || '246px'};
  display: ${(props) => (props.isFromAddContent ? 'block' : 'flex')};
  ${({ isFromAddContent }) => isFromAddContent && `position: relative;`}
  ${({ isFromAddContent }) => !isFromAddContent && `flex-direction: column;`}
`;
export const PostRejectUl = styled.ul`
  margin-bottom: 0;
  padding: 0px 10px;
  width: ${(props) => (props.isFromAddContent ? '49%' : '100%')};
  ${({ isFromAddContent }) => isFromAddContent && `display: inline-block;`}
  ${({ isFromAddContent }) => isFromAddContent && `margin-top: 5px;`}
  ${({ isFromAddContent }) => !isFromAddContent && `flex-grow: 1;`}
  li:first-child {
    width: 80%;
  }
  li:last-child {
    width: 20%;
  }
`;
export const PostRejectListItem = styled.li`
  width: 100% !important;
  display: block;
  flex-grow: 1;
  position: relative;

  .customizeEmailEditor li:first-child,
  li:last-child {
    width: 100%;
  }
  .customizeEmailEditor li:last-child {
    padding: 0px 5px;
  }
`;
export const SocialBox = styled.div`
  padding: ${({ padding }) => padding || '13px 15px'};

  ${({ height }) => `height: ${height};` || 'min-height: 55px;'}
  border: ${(props) =>
    props.borderColor ? `1px solid ${props.borderColor}` : `1px solid black`};
  border-radius: 7px;
  margin-bottom: ${({ marginBottom }) => marginBottom || '15px'};
  ${({ isFromAddContent }) => isFromAddContent && `min-height: 60px;`}
  position: relative;
  text-align: left;
`;
export const SocialIconSpan = styled.span``;
export const SocialIconI = styled.i`
  background: ${(props) =>
    props.background ? `${props.background}` : `black`};
  border-radius: 50%;
  padding: ${(props) =>
    props.fromMyClients || props.fromCompliance ? '4px' : '7px'};
  position: absolute;
  top: -12px;
  right: 20px;
  color: #fff;
  font-size: 11px;
  width: 25px;
  height: 25px;
  text-align: center;
`;

export const MdlSummary = styled.div`
  text-align: left;
  color: #6351ed;
  white-space: pre-wrap;
`;

export const AutoPostCheckboxes = styled.div`
  width: 100%;
  float: left;
  display: block;
  .autoPostCheckboxesListsContainer {
    display: flex;
    justify-content: center;
  }

  .member-title {
    position: relative;
  }

  .member-tooltip {
    visibility: hidden;
    position: absolute;
    z-index: 1;
    white-space: nowrap;
    background-color: #49484a;
    top: bottom;
    bottom: ${({ isMyChecked }) => (isMyChecked ? '24px' : '48px')};
    left: ${({ isMyChecked }) => (isMyChecked ? '127px' : '148px')};
    color: #ffffff;
    font-size: 10px;
    padding: 2px 3px;
  }

  &.member-title:hover &.member-tooltip {
    visibility: visible;
  }

  .auto-title {
    position: relative;
  }

  .auto-tooltip {
    visibility: hidden;
    position: absolute;
    z-index: 1;
    white-space: nowrap;
    background-color: #49484a;
    top: bottom;
    bottom: ${({ isMyChecked }) => (isMyChecked ? '24px' : '47px')};
    left: ${({ isMyChecked }) => (isMyChecked ? '179px' : '160px')};
    color: #ffffff;
    font-size: 10px;
    padding: 2px 3px;
  }

  .auto-title:hover .auto-tooltip {
    visibility: visible;
  }
`;

export const FeedbackDiv = styled.div`
  border: 1px solid #b7b7b7;
  padding: 12px 0px 0px 0px;
  min-height: 55px;
  border-radius: 7px;
  position: relative;
  text-align: left;
`;
export const TextArea = styled.textarea`
  text-align: left;
  color: #6351ed;
  width: 100%;
  resize: none;
  overflow-y: auto;
  overflow-x: hidden;
  border: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  &::placeholder {
    color: #6351ed;
  }
  :focus::placeholder {
    color: transparent;
  }
`;

export const ModalFooterContainer = styled.div`
  width: 65%;
  margin-left: auto;
  div {
    border-radius: 6px;
  }
`;

export const CheckBoxForm = styled.form`
  display: flex;
  padding: ${({ isFromAddContent }) => (isFromAddContent ? '0 10px' : '0')};
  padding-top: ${({ asModal }) => (asModal ? '20px' : '5px')};
  width: ${({ asModal }) => (asModal ? '65%' : '100%')};
  margin-left: auto;
  margin-right: ${({ asModal }) => (asModal ? '20px' : '10px')};
  flex-direction: column;
`;
export const ShareOnDiv = styled.div`
  width: 21%;
`;
export const ShareOnLabel = styled.label`
  font-weight: 500;
  display: inline-block;
  vertical-align: middle;
  color: #49484a;
  line-height: 15px;
  font-size: 12px;
`;
export const CheckBoxWrapper = styled.div`
  width: ${({ width }) => width || '19%'};
  padding: ${({ padding }) => padding || ''};
  &.mio-calendar {
    width: auto !important;
    z-index: 4;
  }
  display: inline-block;
  &:hover .tooltiptext {
    visibility: visible;
  }
`;
export const CheckBoxSpan = styled.span`
  border-radius: 7px;
  position: relative;
  font-size: 12px;
  width: ${({ screenWidth }) => (screenWidth < 768 ? '95%' : '90%')};
  display: inline-block;
  height: 22px;
  display: inline-block;
  text-align: center;
  border: 0px solid #959595;

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 100%;
    margin-top: -5px;
  }
  &:hover .tooltiptext {
    visibility: visible;
  }
`;
export const StyledTooltip = styled.span`
  visibility: hidden;
  width: 155px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: ${({ isImage }) => (isImage ? '5px' : '5px 0')};
  position: absolute;
  z-index: 10;
  top: ${({ isImage }) => (isImage ? '-6px' : '-2px')};
  right: ${({ isImage }) => (isImage ? '97%' : '85%')};
  font-size: ${({ isImage }) => (isImage ? '8px' : '12px')};

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 100%;
    margin-top: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent transparent black;
  }
`;
export const CheckBox = styled.input.attrs({ type: 'checkbox' })`
  position: absolute;
  left: -9999px;
  box-sizing: border-box;
  padding: 0;
  margin: 4px 0 0;
  line-height: normal;
  max-width: 100%;
  margin-bottom: 5px;
  padding: 0;
  font-size: 10px;
  text-align: left;
  &:not(:checked) + label:before {
    content: '';
    top: 4px;
    width: 12px;
    height: 12px;
    border: ${({ border, disabled }) =>
      border && disabled ? border : '1px solid #959595'};
    background: ${({ disabled }) => (disabled ? '#bcb8be' : 'transparent')};
    position: absolute;
    z-index: 1;
    box-sizing: border-box;
    border-radius: ${({ admin }) => (admin ? '3px' : '4px')};
  }
  &:checked + label:before {
    content: '';
    position: absolute;
    z-index: 1;
    transition: 0.2s;
    box-sizing: border-box;
    top: 4px;
    width: 4px;
    height: 9px;
    border-top: 1px solid transparent;
    border-left: 1px solid transparent;
    border-right: ${({ border, disabled }) =>
      border && disabled ? border : '1px solid #6351ed'};
    border-bottom: ${({ border, disabled }) =>
      border && disabled ? border : '1px solid #6351ed'};
    transform: rotateZ(37deg);
    transform-origin: 100% 100%;
    border-radius: ${({ admin }) => (admin ? '3px' : '4px')};
  }
  &:not(:checked) + label:after {
    top: 4px;
    width: 12px;
    height: 12px;
    border: 1px solid #959595;
    z-index: 0;
    content: '';
    position: absolute;
    transition: 0.2s;
    box-sizing: border-box;
    border-radius: ${({ admin }) => (admin ? '3px' : '4px')};
  }
  &:checked + label:after {
    top: 4px;
    width: 12px;
    height: 12px;
    border: ${({ border, disabled }) =>
      border && disabled ? border : '1px solid #6351ed'};
    z-index: 0;
    content: '';
    color: #6351ed;
    position: absolute;
    transition: 0.2s;
    box-sizing: border-box;
    border-radius: ${({ admin }) => (admin ? '3px' : '4px')};
  }
  &:checked + label {
    color: ${({ border, disabled }) =>
      border && disabled ? '#c9c9c9' : '#6351ed'};
  }
`;

export const CheckBoxLabel = styled.label`
  position: relative;
  overflow: hidden;
  cursor: ${({ disconnected }) => (disconnected ? 'default' : 'pointer')};
  display: inline-block;
  height: 20px;
  line-height: 21px;
  color: ${({ disconnected, color }) =>
    disconnected === true ? (color ? color : '#b3b3b3') : '#49484a'};
  font-weight: ${({ disconnected }) => (disconnected === true ? 0 : 400)};
  border-radius: 7px;
  width: 100%;
  text-align: left;
  &:before,
  &:after {
    left: 8px;
  }
  padding-left: ${({ screenWidth, fb }) =>
    screenWidth < 768 ? '20px' : '25px'};
  font-size: ${({ fontSize }) => fontSize || '10px'};
`;
export const Container = styled.div`
  padding-bottom: 0px;
`;

export const ErrorMsg = styled.div`
  text-align: left;
  position: absolute;
  bottom: 12px;
  color: #fff;
  font-size: 9px;
  bottom: -10px;
`;

export const ScheduleModal = styled(Modal)`
  margin: 0 auto;
  border: none;
  position: relative;
  z-index: 100;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
  border-radius: 7px;
  padding: 7px;
  background-color: #ececec;
  border: 1px solid rgba(0, 0, 0, 0.2);
  width: ${({ width }) => width || '28%'};
  text-align: center;
  padding: ${({ padding }) => padding || '40px'};
  padding-top: 20px;
  padding-bottom: ${({ pb }) => pb || ''};
  font-weight: bold;
  ${({ paddingBottom }) => paddingBottom && `padding-bottom: ${paddingBottom}`}
  @media (max-width: 768px) {
    width: 34%;
  }
`;

export const ShareBoxWrapper = styled.div`
  border: 1px solid #cacaca;
  border-radius: 8px;
  min-height: 65px;
  margin-top: ${({ marginTop }) => marginTop || '10px'};
  padding: ${({ padding }) => padding || '10px'};
  position: relative;
`;

export const ScheduleWrapper = styled.div`
  display: inline-block;
  position: absolute;
  right: 0;
`;

export const ScheduleToolTip = styled.span`
  visibility: hidden;
  width: ${({ width }) => width || '100%'};
  max-width: 200px;
  min-width: 150px;
  background-color: #404040;
  color: #fff;
  text-align: left;
  border-radius: 4px;
  padding: 5px 8px;
  position: absolute;
  z-index: 10;
  top: ${({ top }) => top || '0px'};
  right: ${({ right }) => right || '28%'};
  font-size: 10px;
  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 100%;
    margin-top: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent transparent black;
  }
`;

export const ScheduleSection = styled.div.attrs({
  className: 'schedule-section',
})`
  &:hover .tooltiptext {
    visibility: visible;
  }
`;

export const ScheduleChip = styled.span`
  font-size: 7px;
  border: 1px solid #8369b2;
  border-radius: 5px;
  padding: 1px 4px;
  color: #8369b2;
  font-weight: 600;
  position: relative;
  bottom: 4px;
  cursor: pointer;
  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 100%;
    margin-top: -5px;
  }
  &:hover .tooltiptext {
    visibility: visible;
  }
`;

export const CheckBoxSpan2 = styled.span`
  border-radius: 7px;
  position: relative;
  font-size: 12px;
  width: ${({ screenWidth }) => (screenWidth < 768 ? '95%' : '90%')};
  display: inline-block;
  height: 22px;
  display: inline-block;
  text-align: center;
  // ${({ checked }) => checked && 'border: 1px solid #6351ed;'}

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 100%;
    margin-top: -5px;
  }
  &:hover .tooltiptext {
    visibility: visible;
  }
`;

export const AdvisorTxt = styled.div`
  font-size: 8px;
  color: #848484;
  margin: 1px 0px 0px 0px;
`;

export const CustomButton = styled.button`
  width: ${({ width }) => width || '180px'};
  height: ${({ height }) => height || '35px'};
  padding: ${({ padding }) => padding || '7px 10px'};
  font-size: ${({ fontSize }) => fontSize || '14px'};
  margin: ${({ margin }) => margin || '0px'};
  border: 1px solid ${themesData.purpleishBlue};
  color: ${({ color }) => color || '#757575'};
  cursor: pointer;
  background: ${({ background }) => background || '#eee'};
  option {
    background-color: #fff;
    color: #6351ed;
    padding: 5px 0;
    border-radius: 6px;
  }
`;

export const CustomTextArea = styled.textarea`
  resize: none;
  width: 100%;
  background: #e4e4e4;
  border: none;
  border-radius: 10px;
  padding: ${({ padding }) => (padding ? padding : '10px')};
  color: #848484;

  &::placeholder {
    color: #848484;
  }
`;

export const TextAreaWrapper = styled.div`
  width: 100%;
  background: #e4e4e4;
  border: none;
  border-radius: 10px;
  padding: 10px;
`;

export const CustomInputField = styled.input`
  background: #e4e4e4;
  border-radius: 10px;
  border: none;
  width: ${({ width }) => width || '43px'};
  color: ${({ color }) => color || '#848484'};
`;

export const SnippetWrapper = styled.div.attrs({
  className: 'snippet-wrapper',
})`
  display: flex;
  flex-direction: column;
  padding: 20px 0px 0px;
`;

export const SnippetTitle = styled.div.attrs({
  className: 'snippet-title',
})`
  font-size: 14px;
  text-align: left;
  color: #49484a;
  padding-left: 5px;
`;

export const SnippetTextBox = styled.div.attrs({
  className: 'snippet-text-box',
})`
  width: 100%;
  background-color: #ffffff;
  border: 1px solid #cacaca;
  border-radius: 14px;
  margin-top: 10px;
  color: #49484a;
  padding: 5px;
  display: flex;
  align-items: center;
`;

export const ShareWithWrapper = styled.div.attrs({
  className: 'share-with-wrapper',
})`
  position: relative;
  height: ${({ height }) => height || '200px'};
  width: 100%;
  margin-top: ${({ mt }) => mt || '2%'};
`;

export const ShareWithHeader = styled.div.attrs({
  className: 'share-with-header',
})`
  position: absolute;
  height: ${({ height }) => height || '16%'};
  width: ${({ width }) => width || '100%'};
  z-index: 1;
  display: flex;
  justify-content: space-between;
  top: ${({ top }) => top || ''};
`;

export const ShareWithBody = styled.div.attrs({
  className: 'share-with-body',
})`
  position: absolute;
  height: ${({ height }) => height || '160px'};
  background-color: #ffffff;
  width: 100%;
  top: 14%;
  border: solid 1px #cacaca;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
  padding: 10px;
`;

export const StyledShareWithHeader = styled.div.attrs({
  className: 'styled-share-with-header',
})`
  display: inline-block;
  position: relative;
  width: 125px;
  height: 29px;
  background: #cacaca;
  box-sizing: border-box;
  border-top-left-radius: 8px;
  -webkit-clip-path: polygon(0 0, 81% 0, 100% 100%, 0% 100%);
  clip-path: polygon(0 0, 81% 0, 100% 100%, 0% 100%);
`;

export const StyledShareWithHeaderInside = styled.div.attrs({
  className: 'styled-share-with-header-inside',
})`
  position: absolute;
  background: white;
  width: 124.5px;
  height: 30px;
  border-top-left-radius: 8px;
  border-left-width: 1px;
  border-left-style: solid;
  border-left-color: #cacaca;
  border-top-width: 1px;
  border-top-style: solid;
  border-top-color: #cacaca;
  -webkit-clip-path: polygon(0 0, 80.4% 0, 100% 100%, 0% 100%);
  clip-path: polygon(0 0, 80% 0, 100% 100%, 0% 100%);
`;

export const StyledShareWithHeaderTitle = styled.p.attrs({
  className: 'styled-share-with-header-title',
})`
  color: #49484a;
  position: absolute;
  margin: 0;
  padding: ${({ isFromAddContent, isAdmin }) =>
    isFromAddContent && isAdmin
      ? '5px 0px'
      : isFromAddContent && !isAdmin
      ? '4px 0px'
      : '8px 0px'};
  width: ${({ isFromAddContent }) => (isFromAddContent ? '16%' : '18%')};
`;

export const ShareSummaryTab = styled(Tab).attrs({
  className: 'styled-share-summary',
})`
  font-size: 10px;
  font-weight: 500;
  width: 25% !important;
  padding: ${({ padding }) => padding || '5px 10px'};
  cursor: pointer;
  border-bottom: ${({ border }) => border || ''};
  font-family: 'Poppins';
  display: flex;
  justify-content: center;
  align-items: center;

  &.active {
    background-color: ${({ activeColor }) => activeColor || '#00aeef'};
    color: #ffffff;
  }
  &.inactive {
    background-color: #e4e4e4;
    color: #848484;
  }
`;

export const ShareSummaryTabPanel = styled(TabPanel).attrs({
  className: 'share-summary-tab-panel',
})`
  &.react-tabs__tab-panel--selected {
    height: 115px;
    padding: ${({ padding }) => padding || '15px 5px'};
    border-left: ${({ borderColor }) =>
      `1px solid ${borderColor}` || '1px solid #00aeef'};
    border-right: ${({ borderColor }) =>
      `1px solid ${borderColor}` || '1px solid #00aeef'};
    border-bottom: ${({ borderColor }) =>
      `1px solid ${borderColor}` || '1px solid #00aeef'};
  }
`;

export const ShareSummaryTabList = styled(StyledTabList).attrs({
  className: 'share-summary-tab-list',
})`
  li:first-child {
    border-top-left-radius: 8px;
  }

  li:last-child {
    border-top-right-radius: 8px;
  }
`;
