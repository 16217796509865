import React from 'react';
import { useLocation } from 'react-router-dom';

import useRESTQuery from 'src/hooks/useRESTQuery';

import Table from 'src/componentsV2/Table';
import Skeleton from './Skeleton';
import { tableColumnStructure } from './TableColumnStructure';

import { EmailDeliverabilityContainer, TableHeader } from './styles';
import { transformData } from './utils';
import { EmptyState } from 'src/componentsV2';

function EmailDeliverability({ campaignId = '', hasSubCampaignSelected = false }) {
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const type = queryParams.get('type');

  const { isLoading, data } = useRESTQuery({
    key: 'contact-lists',
    endpoint: `/analytics/detailed/campaign/${campaignId}/contactlists?campaign_type=${type}&is_block=${hasSubCampaignSelected}`,
    dependencies: [campaignId, type],
    options: {
      select: (res) => {
        if (Array.isArray(res?.result)) {
          return res?.result?.map((result) => transformData(result));
        }
        return [];
      },
    },
  });

  if (isLoading) return <Skeleton />;

  return (
    <EmailDeliverabilityContainer>
      <TableHeader>
        <h2>Email Deliverability</h2>
      </TableHeader>
      <Table
        data={data}
        columnStructure={tableColumnStructure()}
        customStyles={{
          borderRadius: '0 0 0.5rem 0.5rem',
        }}
         customEmptyState={<EmptyState height="192px" title="No Analytics Data" />}
      />
    </EmailDeliverabilityContainer>
  );
}

export default EmailDeliverability;
