import { styled } from '@mui/material';

export const TooltipContainer = styled('div')(() => ({
  boxShadow: '0px 0px 100px rgba(63, 106, 194, 0.15)',
  padding: '10px 20px',
  background: '#fff',
  borderRadius: '4px',
}));

export const TooltipValue = styled('div')(({ color }) => ({
  fontWeight: 500,
  fontSize: '0.875rem',
  textAlign: 'left',
  color: color || '#101828',
}));

export const TooltipInfoContainer = styled('div')(() => ({
  display: 'flex',
}));

export const TooltipName = styled('div')(() => ({
  fontWeight: 500,
  fontSize: '0.875rem',
  color: '#000',
  textAlign: 'left',
  margin: '0 6px 0 0',
}));

export const TooltipTitle = styled('div')(() => ({
  fontWeight: 500,
  fontSize: '1rem',
  color: '#000',
  textAlign: 'left',
  margin: '5px 0',
}));
