import styled from 'styled-components';
import { colors } from '../../styles';

export const StatCard = styled('div')`
    background: ${colors.gray_background};
    border-radius: 8px;
    width: 49%;
    padding: 10px 10px;
    margin: 5px 0 15px;
    border: 1px solid ${colors.gray_border};
    display: flex;
`;

export const StatTitle = styled('div')`
    text-align: left;
    font-weight: 400;
    font-size: 14px;
    color: ${colors.light_gray_text};
    padding: 0;
    margin: 2px 0 0;
`;

export const StatNumber = styled('div')`
    font-weight: 500;
    font-size: 24px;
    display: flex;
    justify-content: space-between;
    color: ${colors.black_01};
    padding: 0px;
    margin: 0;
`;

export const GrowthStyle = styled('div')`
    font-weight: 500;
    font-size: 16px;
    display: flex;
    color: ${({ positive }) => positive ? `${colors.green}` : `${colors.red}`};
    margin: 6px 0 0;
`;

export const TriangleDiv = styled('div')`
    width: 0; 
    height: 0; 
    margin: 7px 4px 0 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: ${({ positive }) => positive ? `9px solid ${colors.green}` : `9px solid ${colors.red}`};
    transform:  ${({ positive }) => positive ? 'rotate(0)' : 'rotate(180deg)'};
`;

export const StatIcon = styled('img')`
    height: ${({ height }) => height ? height : '40px'};
    width: ${({ width }) => width ? width : '40px'};
    margin: ${({ margin }) => margin ? margin : '0 10px 0 0'};
`;