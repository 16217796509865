export const ColorPallete = {
    white: '#fff',
    primaryBlue: '#4634D0',
    pink: '#CABEFF',
    black: '#000000',
    neutral500: '#667085',
    neutral900: '#101828',
    neutral200: '#E4E7EC',
    neutral50: '#F9FAFB',
    neutral600: '#475467',
    primaryPurple: '#6250ED',
    neutral400: '#98A2B3',
    green: '#00B186',
    neutral100: '#F2F4F7',
    lightBlue: '#B6CFF3',
    darkBlue: '#0B5CD7',
    darkPink: '#9B51E0',
    lightPink: '#DEC5F5',
    mediumPink: '#E9E0FF',
    neutral700: '#344054'
}

export const Typography = {
    Heading: '20px',
    TextMedium: '14px',
    TextLarge: '16px',
    TextSmall: '12px'
}