import React, { useState } from 'react';
import { Button } from '../';
import { MioSelect } from '../CommonStyles';
import { Box } from '@rebass/grid';
import { Formik } from 'formik';
import { getUserDetails } from '../../redux/selector/login.selectors';
import { connect } from 'react-redux';
import { LoaderWrapper } from '../../components';
import { ResponsiveImg } from '..';
import { useHistory } from 'react-router-dom';
import { sendInvite, postSendInvite } from '../../services/dashboard';
import { parse } from 'json2csv';
import { get, isEmpty } from 'lodash-es';

const InvitePreview = ({
  inviteData,
  groupsList,
  hasInviteSent,
  setHasInviteSent,
  channelId,
  inviteRequest,
  inviteError,
  userDetails,
  fromPositivlyInvite,
  snId,
  uploadedFile,
  userData,
  snData,
  membership,
}) => {
  // console.log('SNDATA - ', snData);
  // console.log('!snData || !snData.data =  -  - - - - ', (!snData || !snData.data));
  // console.log('(!snData.em && !snData.sg ) =  -  - - - - ', (!snData.data.em && !snData.data.sg ));
  // console.log('!snData || !snData.data =  -  - - - - ', (snData.data.em.length===0 && snData.data.sg[0].status!=='connected'));
  const history = useHistory();
  const [clickedLoader, toggleClickedLoader] = useState(false);
  const [successMessage, setSuccessMesage] = useState('');
  const [errorMessage, setErrorMesage] = useState('');
  const [groupName, setGroupName] = useState('');

  // const validate = (inviteData) => {
  // const errors = {};
  // const { groupName } = inviteData;
  //     if (isEmpty(groupName)) {
  //         errors.formError = 'Please select group to invite';
  //     }
  //     return errors;
  // };

  const onInviteClick = (inviteData) => {
    let contactsCsv;
    let validUserContacts = [];
    const form = {};
    console.log('INVITE DATA - - -- - -', inviteData);
    toggleClickedLoader(true);
    setErrorMesage('');
    setSuccessMesage('');

    if (
      membership === 'member' &&
      !inviteData.groupName &&
      !fromPositivlyInvite
    ) {
      toggleClickedLoader(false);
      setErrorMesage('Please select group to invite');
      return;
    }
    if (fromPositivlyInvite) {
      validUserContacts = inviteData.email.filter((contact) => contact.valid);
      // console.log('[VALID CONTACTS] - ', validUserContacts, inviteData);
      form.snetwork_act_id = snId;
      if (validUserContacts.length > 0) {
        contactsCsv = getEmailListCsv(validUserContacts, [
          'first_name',
          'last_name',
          'email',
        ]);
        form.media1 = contactsCsv;
      } else if (uploadedFile !== '')
        form.media1 = new File(uploadedFile, 'contacts.csv');
      //     else if (listId != '-1')
      //         form.contactlist_id = listId

      console.log('[FORM]', form);

      postSendInvite(form)
        .then((response) => {
          console.log('[RESPONSE] - ', response);
          setHasInviteSent(true);
          toggleClickedLoader(false);
          setSuccessMesage('Email Invitations Sent Successfully');
        })
        .catch((err) => {
          console.log('[ERROR] - ', err);
          setHasInviteSent(false);
          toggleClickedLoader(false);
          setErrorMesage('Some error occured...please try again.');
        });
    } else {
      validUserContacts = inviteData.email
        .filter((contact) => contact.valid)
        .map((user) => ({
          ...user,
          role: membership === 'member' ? 'general' : 'admin',
          ...(membership === 'member'
            ? { firm_name: '' }
            : { first_name: '', last_name: '' }),
          mobile: '',
        }));
      if (validUserContacts.length > 0) {
        contactsCsv = getEmailListCsv(validUserContacts, [
          'first_name',
          'last_name',
          'firm_name',
          'email',
          'role',
          'mobile',
        ]);
        form['media1'] = contactsCsv;
      } else if (uploadedFile !== '')
        form.media1 = new File(uploadedFile, 'contacts.csv');

      form['groups_json'] = JSON.stringify([inviteData.groupName]);
      form['channels_json'] = JSON.stringify([channelId]);

      // if (inviteData.name) {
      //     const fileName = get(inviteData, 'inviteUpload[0].name', '');
      //     if (!isEmpty(fileName)) {
      //         formData['media1'] = get(inviteData, 'inviteUpload[0]');
      //     }
      // }
      if (membership === 'firm') form.firm = true;
      console.log('formData - ', form);
      sendInvite(form)
        .then((res) => {
          if (res.result.success) {
            setHasInviteSent(true);
            toggleClickedLoader(false);
            setSuccessMesage('Email Invitations Sent Successfully');
          } else {
            setHasInviteSent(false);
            toggleClickedLoader(false);
            if (!res.result.success && !isEmpty(res.result.data.email))
              setErrorMesage(
                `Invite already sent to email : ${res.result.data.email}`
              );
            else
              setErrorMesage(
                'Oops! Some error occurred. Please try again later.'
              );
          }
        })
        .catch((err) => {
          setHasInviteSent(false);
          toggleClickedLoader(false);
        });
    }
    setErrorMesage();
  };

  const getEmailListCsv = (values, fieldArray) => {
    const csvData = parse(values, {
      fields: fieldArray,
    });
    console.log('[CSV DATA] - ', csvData);
    const csvBlob = new Blob([csvData], { type: 'text/csv' });
    const csvFile = new File([csvBlob], 'email_list.csv', {
      type: 'text/csv',
      lastModified: new Date(),
    });
    return csvFile;
  };

  const initialState = {
    email: inviteData,
    emailList: '',
    inviteUpload: '',
    groupName: '',
  };
  return (
    <>
      <Formik
        initialValues={initialState}
        onSubmit={onInviteClick}
        //validate={validate}
        validateOnChange={false}
      >
        {({
          handleSubmit,
          handleChange,
          setFieldValue,
          errors,
          inviteData,
          validateForm,
          isValid,
        }) => {
          // const fileName = get(inviteData, 'inviteUpload[0].name', '');
          // const onFileRemoveClick = (fileName) => {
          //   setFieldValue('inviteUpload', '');
          //  };

          return (
            <>
              {!hasInviteSent && (
                <div className="invitation-container">
                  {/*<Loader height="25px" />*/}
                  {fromPositivlyInvite ? (
                    <>
                      <p>&nbsp;</p>
                      <p></p>
                      <table
                        style={{ backgroundColor: '#ffffff' }}
                        border="0"
                        width="100%"
                        cellspacing="0"
                        cellpadding="0"
                        align="center"
                      >
                        <tbody>
                          <tr>
                            <td
                              style={{
                                textAlign: 'center',
                                padding: '50px 9px 50px 9px',
                              }}
                            >
                              <img
                                src={
                                  userData &&
                                  userData.email_personalization &&
                                  userData.email_personalization.media_urls &&
                                  userData.email_personalization.media_urls[0]
                                    ? userData.email_personalization
                                        .media_urls[0].path +
                                      userData.email_personalization
                                        .media_urls[0].name.system +
                                      '.' +
                                      userData.email_personalization
                                        .media_urls[0].ext
                                    : ''
                                }
                                alt="logo"
                                width="294px"
                              />
                            </td>
                          </tr>
                          <tr>
                            <td
                              style={{
                                paddingBottom: '35px',
                                textAlign: 'center',
                              }}
                            >
                              <img
                                src="https://apicdn.myclout.com/assets/emailer/positivly_banner.png"
                                alt="Banner"
                                width="100%"
                              />
                            </td>
                          </tr>
                          <tr>
                            <td
                              style={{
                                paddingRight: '9px',
                                paddingLeft: '9px',
                                textAlign: 'left',
                                fontSize: '14px',
                              }}
                              width="637px"
                            >
                              <span style={{ fontFamily: 'Arimo, sans-serif' }}>
                                Dear
                                <span style={{ fontWeight: 'bold' }}>
                                  {' '}
                                  Client,
                                </span>
                              </span>
                            </td>
                          </tr>
                          <tr>
                            <td
                              style={{
                                paddingRight: '9px',
                                paddingLeft: '9px',
                                textAlign: 'left',
                                fontSize: '14px',
                              }}
                              width="637px"
                            >
                              <p style={{ fontFamily: 'Arimo, sans-serif' }}>
                                <span style={{ fontWeight: 'bold' }}>
                                  {userData &&
                                  userData.name &&
                                  userData.name.first &&
                                  userData.name.last
                                    ? userData.name.first +
                                      ' ' +
                                      userData.name.last +
                                      ' '
                                    : ' '}
                                </span>
                                invites you to take a short assessment to
                                discover your financial personality and learn
                                what drives your financial well-being. Take our
                                two-minute test to get started.
                              </p>
                              <p style={{ fontFamily: 'Arimo, sans-serif' }}>
                                Today, the Myers-Briggs personality type
                                indicator is the most popular personality test
                                in the world used to summarize who you are and
                                what you bring to the table.
                              </p>
                              <p style={{ fontFamily: 'Arimo, sans-serif' }}>
                                When it comes to your finances, research has
                                shown that a better understanding of your
                                financial personality leads to better outcomes.
                              </p>
                              <p style={{ fontFamily: 'Arimo, sans-serif' }}>
                                Designed by academics from Harvard and Wharton,
                                our financial personality assessment blends
                                investment science with positive psychology to
                                help you discover what truly matters when it
                                comes to your finances.
                              </p>
                              <p style={{ fontFamily: 'Arimo, sans-serif' }}>
                                Learn how you score on four dimensions: Purpose,
                                Security, Touch, Viewpoints to help us create a
                                financial plan or an investment portfolio that
                                is reflective of what matters to you most.
                              </p>
                            </td>
                          </tr>
                          <tr>
                            <td
                              style={{
                                textAlign: 'center',
                                padding: '50px 9px 50px 9px',
                              }}
                            >
                              <span
                                style={{
                                  width: '268px',
                                  height: '66px',
                                  backgroundColor: '#f9c247',
                                  padding: '14px 16px 12px',
                                  borderRadius: '10px',
                                }}
                              >
                                <a
                                  style={{
                                    textDecoration: 'none',
                                    cursor: 'pointer',
                                    fontSize: '14px',
                                    fontWeight: 'bold',
                                    fontFamily: 'Arimo, sans-serif',
                                    color: '#353952',
                                    pointerEvents: 'none',
                                  }}
                                  href="#"
                                  target=""
                                >
                                  START
                                </a>
                              </span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </>
                  ) : (
                    <>
                      <div
                        className="content-row"
                        style={{ textAlign: 'center' }}
                      >
                        <img
                          alt=""
                          className="clout-img"
                          src={
                            fromPositivlyInvite
                              ? ''
                              : 'https://apicdn.myclout.com/assets/emailer/clout-template.png'
                          }
                        />
                      </div>
                      <div className="invitation-header">
                        An invitation to join Clout!
                      </div>
                      <div className="content-row">
                        You have been invited by {userDetails.name.first} &nbsp;{' '}
                        {userDetails.name.last}to become a member of Clout.
                        Clout is an AI-powered content marketing platform to
                        help you engage clients, convert prospects and grow.
                      </div>
                      <Button type="button">Accept Invitation</Button>
                      <div className="content-row-header">Why Join Clout:</div>
                      <div className="content-row-head">
                        Give your brand a thought leadership edge
                      </div>
                      <div className="content-row">
                        Share content that is highly relevant, insightful and
                        makes your firm stand out
                      </div>
                      <div className="content-row-head">
                        Drive real-time engagement
                      </div>
                      <div className="content-row">
                        Advisors report that market commentary, COVID-19 related
                        articles, and advisors' own original articles shared on
                        social were the most engaging types of content during
                        the pandemic.
                      </div>
                      <div className="content-row-head">
                        {' '}
                        Connect with next-gen clients
                      </div>
                      <div className="content-row">
                        Younger and digtally-savvy clients are looking for more
                        frequent, shorter conversations about their money as
                        well as other aspects of their life.
                      </div>
                      <div className="content-row-head-two">
                        Next steps to help increase your Clout:
                      </div>
                      <div className="content-sub-row">
                        <span>&nbsp;1.</span>&nbsp;Connect your email and social
                        media &nbsp;- connect directly with your clients and{' '}
                      </div>
                      <div className="content-sub-row">
                        <span>&nbsp;</span>prospects
                      </div>
                      <div className="content-sub-row">
                        <span>2.</span> &nbsp;Upload segmented distribution
                        lists - create personalized connections
                      </div>
                      <div className="content-sub-row">
                        <span>3.</span>&nbsp;Create a campaign - share posts on
                        similar themes, develop your voice!
                      </div>
                      <Button type="button">Accept Invitation</Button>
                      <div className="content-row-image">
                        <img
                          alt=""
                          className="content-img"
                          src="https://apicdn.myclout.com/assets/emailer/color-twitter-48.png"
                        />
                        <img
                          alt=""
                          className="content-img"
                          src="https://apicdn.myclout.com/assets/emailer/color-linkedin-48.png"
                        />
                      </div>
                    </>
                  )}
                </div>
              )}
            </>
          );
        }}
      </Formik>
      {!hasInviteSent && (
        <LoaderWrapper isLoading={clickedLoader}>
          <div className="dropdown-section">
            {!fromPositivlyInvite && (
              <MioSelect
                id="groups"
                name="groupName"
                value={groupName}
                onChange={(e) => {
                  setGroupName(e.target.value);
                  if (e.target.value) {
                    setErrorMesage('');
                  } else {
                    setErrorMesage('Please select group to invite');
                  }
                  if (e.target.value === 'create') {
                    window.sessionStorage.removeItem('selectedIndex');
                    history.push('/admin/configure');
                    window.sessionStorage.setItem('selectedIndex', 1);
                  }
                }}
              >
                <option value="">Select Group</option>
                {groupsList.map((group) => (
                  <option value={group.id}>{group.name}</option>
                ))}
                <option value="create">Create Group</option>
              </MioSelect>
            )}
            <Button
              styles={{ position: 'relative', top: '15px' }}
              margin="0px 10px"
              height="35px"
              className="button-send-invitation"
              disabled={
                (!groupName && !fromPositivlyInvite) ||
                (isEmpty(get(snData, 'data.em', [])) &&
                  isEmpty(get(snData, 'data.sg', [])) &&
                  get(snData, 'data.sg[0].status', '') !== 'connected')
              }
              type="button"
              onClick={() =>
                onInviteClick({
                  email: inviteData,
                  emailList: '',
                  inviteUpload: '',
                  groupName: groupName,
                })
              }
            >
              Send Invitation
            </Button>
          </div>
        </LoaderWrapper>
      )}
      {successMessage && (
        <Box>
          <div className="success-message">
            <div class="flex-col img">
              <ResponsiveImg
                src="/assets/images/done.svg"
                style={{
                  height: '23px',
                  width: 'auto',
                  marginTop: '-2px',
                  marginLeft: '-28px',
                  position: 'relative',
                }}
              />
            </div>
            {successMessage}
          </div>
        </Box>
      )}
      {errorMessage && (
        <Box>
          <div className="error-message">{errorMessage}</div>
        </Box>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  userDetails: getUserDetails(state),
});

export default connect(mapStateToProps)(InvitePreview);
