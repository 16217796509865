/* eslint-disable react-hooks/exhaustive-deps */
import { Flex } from '@rebass/grid';
import { isEmpty } from 'lodash';
import React, { useState, useEffect } from 'react';
import CampaignInfo from '../CampaignInfo/CampaignInfo';
import CampaignPlatforms from '../CampaignPlatforms/CampaignPlatforms';
import CampaignPostInfo from '../CampaignPostInfo/CampaignPostInfo';
import CampaignTopics from '../CampaignTopics/CampaignTopics';
import { VerticalSeparator } from './DripDashboardListItem.styles';
import { connect } from 'react-redux';
import { getMediaImage, onImageError } from '../../../../utils';

const DripDashboardListItem = ({
  name,
  status,
  email,
  facebook,
  twitter,
  linkedin,
  themes,
  isFirst,
  start_date,
  duration,
  frequency,
  target,
  socialThemes,
  posts,
  id,
  campaignDetailsView,
  setDetailsView,
  campaignsDetailsData,
  campaignDetailsRequest,
  socialNetworkData,
  detailsHandler,
  media_urls,
}) => {
  const [fetchedDetails, setFetchedDetails] = useState({});

  useEffect(() => {
    if (campaignsDetailsData) {
      // console.log('setFetchedDetails', campaignsDetailsData);
      setFetchedDetails(campaignsDetailsData);
      let el = document.getElementById(campaignsDetailsData?.campaign_id);
      campaignDetailsView &&
        el &&
        el.scrollIntoView({ behavior: 'smooth', block: 'start' });
    } else {
      setFetchedDetails({});
    }
  }, [campaignsDetailsData]);

  const statusColorCodes = {
    completed: '#33B21B',
    scheduled: '#00CCF2',
    draft: '#FFAA00',
    in_progress: '#326DE6',
  };

  const getPlatformData = () => {
    let platforms = [];
    if (email && !isEmpty(email)) {
      platforms.push('email');
    }
    if (facebook && !isEmpty(facebook)) {
      platforms.push('facebook');
    }
    if (twitter && !isEmpty(twitter)) {
      platforms.push('twitter');
    }
    if (linkedin && !isEmpty(linkedin)) {
      platforms.push('linkedin');
    }

    return platforms;
  };

  const postinfo = {
    postsPerWeek: posts.week,
    totalPosts: posts.total,
    scheduled: posts.scheduled,
    needContent: posts.draft,
    completed: posts.completed,
    failed: posts.failed,
    missed: posts.missed,
  };
  return (
    <Flex
      //   justifyContent="flex-start"
      id={id}
      alignItems="center"
      // width={campaignDetailsView ? '38%' : '100%'}
      style={{
        border: '1px solid #DBE0E6',
        borderRadius: '4px',
        borderRight: `6px solid ${statusColorCodes[status]}`,
        margin: isFirst ? '0px 0px 10px' : '10px 0px',
        boxShadow: campaignDetailsView
          ? fetchedDetails?.campaign_id === id
            ? 'rgba(0, 0, 0, 0.24) 0px 3px 8px'
            : ''
          : '',
        backgroundColor: campaignDetailsView
          ? fetchedDetails?.campaign_id === id
            ? '#fff'
            : '#eee'
          : '#fff',
      }}
    >
      <img
        alt=""
        src={getMediaImage(media_urls)}
        width="140px"
        height="100px"
        style={{ borderRadius: '5px' }}
        onError={onImageError}
      />

      <CampaignInfo
        target={target}
        title={name}
        status={status}
        start_date={start_date}
        duration={duration}
        frequency={frequency}
        campaignDetailsView={campaignDetailsView}
        id={id}
        campaignDetailsRequest={campaignDetailsRequest}
        setDetailsView={setDetailsView}
        detailsHandler={detailsHandler}
      />

      {!campaignDetailsView && (
        <>
          <VerticalSeparator height="50px" />
          <CampaignPlatforms platforms={getPlatformData()} />
          <VerticalSeparator height="50px" />
          <CampaignTopics topics={themes} socialThemes={socialThemes} />
          <VerticalSeparator height="50px" />
          <CampaignPostInfo
            postInfo={postinfo}
            id={id}
            campaignDetailsView={campaignDetailsView}
            setDetailsView={setDetailsView}
            socialNetworkData={socialNetworkData}
            detailsHandler={detailsHandler}
          />
        </>
      )}
    </Flex>
  );
};
const mapStateToProps = (state) => {
  return {
    campaignsDetailsData: state.campaignDetails.data,
  };
};

const mapDispatchToProps = {};

// export default DripDashboardListItem;
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DripDashboardListItem);
