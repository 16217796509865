/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Flex } from '@rebass/grid';
import { get, isEmpty } from 'lodash';
import React, { useState, useEffect } from 'react';
import { TabList, Tabs } from '../../components';
import { getContactsGroups } from '../../services/configure';
import { TabConfig } from '../ConfigureContainer/screenConfigure.styles';
import { BorderWhiteBox } from '../DashboardContainer/DashboardContainer.styles';
import { GetStartedTab } from './GetStartedTab';
import { Recipients } from './Recipients';
import { NewsletterTab } from './NewsletterContainer.styles';
import { AddArticleTab } from './AddArticleTab';
import { OutboxTab } from './OutboxTab';
import { CustomizeEmailTab } from './CustomizeEmailTab';
import { connect } from 'react-redux';
import {
  actions as newsletterActions,
  moreActions as newsletterMoreActions,
} from '../../redux/modules/newsletter.module';
import { actions as loginActions } from '../../redux/modules/login.module';
import { PreviewTab } from './PreviewTab';
import { getUserRole, getRoleType, getChannelId } from '../../redux/selector';
import { ROLE_TYPES, USER_ROLE } from '../../utils';
import useQuery from '../../customHooks/useQuery';

import { actions as exploreActions } from '../../redux/modules/explore-tab.module';
import { SelectTemplate } from './SelectTemplate';

const NewsletterContainer = ({
  newsletterSetState,
  isEditMode,
  selectedContacts,
  ccContacts,
  customizeData,
  newsletterBucketRequest,
  bucketData,
  themeNames,
  newsletterBucketDeleteRequest,
  isDeleting,
  isAdmin,
  newsletterArticles,
  userData,
  newsletterId,
  socialNetworkData,
  loginRequest,
  emailTemplateSelected,
  newsletterOutboxRequest,
  outboxData,
  outboxLoading,
  newsletterDeleteRequest,
  isArticleDeleting,
  newsletterScheduledTime,
  outBoxTabSource,
  exploreArticleRequest,
  recommendedData,
  exploreArticleFetching,
  leadCaptureDetails,
  engagement_details,
  isFromCompliance,
  cloneDetails,
  isPremiumAdvisor,
  exploreArticleData,
  newsletterName,
  channelId,
  popularThemes
}) => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [contactGroups, setContactGroups] = useState([]);
  const [showTabs, setShowTabs] = useState(false);
  const [showOutbox, setShowOutbox] = useState(false);
  const [outBoxIndex, setOutBoxIndex] = useState(0);
  const [page, setPage] = useState(0);
  const query = useQuery();
  const [showDashboard, setShowDashboard] = useState(false);
  const [newsLetterTouched, setNewsLetterTouched] = useState(false);

  let params = query.get('type');
  let queryId = query.get('id');
  let indexParams = query.get('tab');
  let source = query.get('source');

  useEffect(() => {
    setNewsLetterTouched(false);
    // loginRequest({ noRefresh: true });
    getContactsGroups()
      .then((res) => {
        if (res.result.success) {
          let data = res.result.data.filter(
            (c) =>
              ![
                'GMAIL',
                'HUBSPOT',
                'OUTLOOK',
                'WEALTHBOX',
                'SALESFORCE',
                'REDTAIL',
              ].includes(get(c, 'name')) && get(c, 'contacts.count', 0) > 0
          );
          setContactGroups(data);
        }
      })
      .catch((err) => {
        console.log(err);
      });

    newsletterSetState({ ccContacts: [] });
    newsletterBucketRequest();

    if (params && params === 'create') {
      setShowDashboard(false);
      setShowTabs(false);
      setShowOutbox(false);
      setSelectedIndex(0);
    } else if (params && params === 'dashboard') {
      setShowDashboard(true);
      setShowTabs(false);
      setShowOutbox(true);
      if (!source) {
        newsletterSetState({ isFromCompliance: false });
      }
      if (!isFromCompliance) {
        if (indexParams && indexParams === 'sent') {
          setOutBoxIndex(2);
          newsletterOutboxRequest({
            params: {
              filter: 'sent',
            },
          });
        } else if (indexParams && indexParams === 'scheduled') {
          setOutBoxIndex(1);
          newsletterOutboxRequest({
            params: {
              filter: 'scheduled',
            },
          });
        } else {
          setOutBoxIndex(0);
          newsletterOutboxRequest({
            params: {
              filter: 'draft',
            },
          });
        }
      } else setOutBoxIndex(1);
    }

    exploreArticleRequest({
      params: {
        source: 'recommended',
        isAdmin: isAdmin,
      },
    });
  }, [params]);

  useEffect(() => {
    if (!showTabs && !showOutbox) {
      loginRequest({ noRefresh: true });
    }
  }, [showTabs, showOutbox]);

  const onTabSelect = (index) => {
    setSelectedIndex(index);
  };
  return (
    <Box width={'105%'} pt="25px" mb="10px">
      {!showTabs && !showOutbox && (
        <BorderWhiteBox type="admin-tab" padding="5px 5px">
          <GetStartedTab
            setShowTabs={setShowTabs}
            setSelectedIndex={setSelectedIndex}
            setShowOutbox={setShowOutbox}
            newsletterSetState={newsletterSetState}
            userData={userData}
            setOutBoxIndex={setOutBoxIndex}
            newsletterOutboxRequest={newsletterOutboxRequest}
            setPage={setPage}
            showDashboard={showDashboard}
          />
        </BorderWhiteBox>
      )}

      {showOutbox && (
        <OutboxTab
          setSelectedIndex={setOutBoxIndex}
          selectedIndex={isFromCompliance ? 1 : outBoxIndex}
          newsletterOutboxRequest={newsletterOutboxRequest}
          outBoxData={outboxData}
          outBoxLoading={outboxLoading}
          page={page}
          setPage={setPage}
          userData={userData}
          newsletterSetState={newsletterSetState}
          newsletterDeleteRequest={newsletterDeleteRequest}
          newsletterId={newsletterId}
          isArticleDeleting={isArticleDeleting}
          setShowTabs={setShowTabs}
          setShowOutbox={setShowOutbox}
          setSelectedTabIndex={setSelectedIndex}
          socialNetworkData={socialNetworkData}
          contactGroups={contactGroups}
          isFromCompliance={isFromCompliance}
          queryId={queryId}
          isPremiumAdvisor={isPremiumAdvisor}
          channelId={channelId}
          popularThemes={popularThemes}
        />
      )}

      {showTabs && (
        <Flex width={1}>
          <Tabs onSelect={onTabSelect} selectedIndex={selectedIndex}>
            <BorderWhiteBox
              border="1px solid #e4e4e4"
              type="admin-tab"
              padding="5px 5px"
            >
              <TabList height="35px">
                <NewsletterTab
                  className={selectedIndex === 0 ? 'active' : ''}
                  disabled
                >
                  <div
                    className={
                      selectedIndex > 0 ? 'line active' : 'line inactive'
                    }
                  ></div>
                  <div className="number-tag" style={{ left: '9px' }}>
                    1
                  </div>
                  <div className="text">
                    Select <br /> Recipients
                  </div>
                </NewsletterTab>
                {outBoxTabSource === 'sent' ? (
                  <NewsletterTab bgColor="#B4AFB6" disabled>
                    <div className={'line'}></div>
                    <div className="number-tag">2</div>
                    <div className="text">
                      Select <br /> Template
                    </div>
                  </NewsletterTab>
                ) : (
                  <NewsletterTab
                    className={selectedIndex === 1 ? 'active' : ''}
                    disabled
                  >
                    <div
                      className={
                        selectedIndex > 1
                          ? 'line active'
                          : selectedIndex < 1
                          ? 'line'
                          : 'line inactive'
                      }
                    ></div>
                    <div className="number-tag">2</div>
                    <div className="text">
                      Select <br /> Template
                    </div>
                  </NewsletterTab>
                )}
                {outBoxTabSource === 'sent' ? (
                  <NewsletterTab bgColor="#B4AFB6" disabled>
                    <div className={'line'}></div>
                    <div className="number-tag">3</div>
                    <div className="text">
                      Add <br /> Articles
                    </div>
                  </NewsletterTab>
                ) : (
                  <NewsletterTab
                    className={selectedIndex === 2 ? 'active' : ''}
                    disabled
                  >
                    <div
                      className={
                        selectedIndex > 2
                          ? 'line active'
                          : selectedIndex < 2
                          ? 'line'
                          : 'line inactive'
                      }
                    ></div>
                    <div className="number-tag">3</div>
                    <div className="text">
                      Add <br /> Articles
                    </div>
                  </NewsletterTab>
                )}

                {outBoxTabSource === 'sent' ? (
                  <NewsletterTab bgColor="#B4AFB6" disabled>
                    <div
                      className={selectedIndex > 3 ? 'line active' : 'line'}
                    ></div>
                    <div className="number-tag">4</div>
                    <div className="text">
                      Customize <br /> Email
                    </div>
                  </NewsletterTab>
                ) : (
                  <NewsletterTab
                    className={selectedIndex === 3 ? 'active' : ''}
                    disabled
                  >
                    <div
                      className={
                        selectedIndex > 3
                          ? 'line active'
                          : selectedIndex < 3
                          ? 'line'
                          : 'line inactive'
                      }
                    ></div>
                    <div className="number-tag">4</div>
                    <div className="text">
                      Customize <br /> Email
                    </div>
                  </NewsletterTab>
                )}

                <NewsletterTab
                  className={selectedIndex === 4 ? 'active' : ''}
                  disabled
                >
                  <div className="number-tag">5</div>
                  <div className="text">
                    Preview & <br /> Send/Schedule
                  </div>
                </NewsletterTab>
              </TabList>
              <TabConfig noBorder minHeight="440px">
                <Recipients
                  setSelectedIndex={setSelectedIndex}
                  contactGroups={contactGroups}
                  setShowTabs={setShowTabs}
                  newsletterSetState={newsletterSetState}
                  isEditMode={isEditMode}
                  selectedContacts={selectedContacts}
                  ccContacts={ccContacts}
                  newsletterId={newsletterId}
                  setShowOutbox={setShowOutbox}
                  newsletterOutboxRequest={newsletterOutboxRequest}
                  outBoxTabSource={outBoxTabSource}
                />
              </TabConfig>
              <TabConfig noBorder minHeight="434px">
                <SelectTemplate
                  outBoxTabSource={outBoxTabSource}
                  setSelectedIndex={setSelectedIndex}
                  emailTemplateSelected={emailTemplateSelected}
                  newsletterSetState={newsletterSetState}
                  newsletterId={newsletterId}
                />
              </TabConfig>
              <TabConfig noBorder minHeight="434px">
                <AddArticleTab
                  setSelectedIndex={setSelectedIndex}
                  newsletterSetState={newsletterSetState}
                  bucketData={bucketData}
                  themeNames={themeNames}
                  newsletterBucketDeleteRequest={newsletterBucketDeleteRequest}
                  isDeleting={isDeleting}
                  isAdmin={isAdmin}
                  selectedNewsletterArticles={newsletterArticles}
                  isEditMode={isEditMode}
                  newsletterId={newsletterId}
                  selectedContacts={selectedContacts}
                  ccContacts={ccContacts}
                  recommendedData={
                    !isEmpty(exploreArticleData)
                      ? exploreArticleData
                      : recommendedData
                  }
                  exploreArticleFetching={exploreArticleFetching}
                  exploreArticleRequest={exploreArticleRequest}
                  setTouched={setNewsLetterTouched}
                  userData={userData}
                  channelId={channelId}
                  popularThemes={popularThemes}
                />
              </TabConfig>
              <TabConfig noBorder minHeight="434px">
                <CustomizeEmailTab
                  setSelectedIndex={setSelectedIndex}
                  newsletterSetState={newsletterSetState}
                  isEditMode={isEditMode}
                  customizeData={customizeData}
                  newsletterId={newsletterId}
                  newsletterArticles={newsletterArticles}
                  selectedContacts={selectedContacts}
                  ccContacts={ccContacts}
                  setTouched={setNewsLetterTouched}
                  cloneDetails={cloneDetails}
                  emailTemplateSelected={emailTemplateSelected}
                  newsletterName={newsletterName}
                />
              </TabConfig>
              <TabConfig noBorder minHeight="520px">
                <PreviewTab
                  setSelectedIndex={setSelectedIndex}
                  newsletterArticles={newsletterArticles}
                  selectedContacts={selectedContacts}
                  customizeData={customizeData}
                  userData={userData}
                  themeNames={themeNames}
                  newsletterId={newsletterId}
                  newsletterSetState={newsletterSetState}
                  setShowTabs={setShowTabs}
                  socialNetworkData={socialNetworkData}
                  newsletterScheduledTime={newsletterScheduledTime}
                  isEditMode={isEditMode}
                  outBoxTabSource={outBoxTabSource}
                  setShowOutbox={setShowOutbox}
                  newsletterOutboxRequest={newsletterOutboxRequest}
                  setOutBoxIndex={setOutBoxIndex}
                  leadCaptureDetails={leadCaptureDetails}
                  engagement_details={engagement_details}
                  ccContacts={ccContacts}
                  isFromCompliance={isFromCompliance}
                  touched={newsLetterTouched}
                  setTouched={setNewsLetterTouched}
                  complianceStatus={customizeData.compliance_status}
                  isPremiumAdvisor={isPremiumAdvisor}
                  cloneDetails={cloneDetails}
                  setPage={setPage}
                  emailTemplateSelected={emailTemplateSelected}
                  channelId={channelId}
                  popularThemes={popularThemes}
                />
              </TabConfig>
            </BorderWhiteBox>
          </Tabs>
        </Flex>
      )}
    </Box>
  );
};

const mapStateToProps = (state) => {
  const isAdmin = getUserRole(state) === USER_ROLE.admin;
  const isPremuimAdvisor = getRoleType(state) === ROLE_TYPES.ADVISOR_PREMIUM;
  // console.log('ENGAGEMENT DETAILS - - - - - - ', state.newsletter);
  return {
    isEditMode: state.newsletter.isEditMode,
    selectedContacts: state.newsletter.selectedContacts,
    ccContacts: state.newsletter.ccContacts,
    emailTemplateSelected: state.newsletter.emailTemplateSelected,
    customizeData: state.newsletter.customData,
    bucketData: state.newsletter.bucketData,
    themeNames: get(state, 'theme.themeNames', {}),
    isDeleting: state.newsletter.isDeleting,
    isAdmin,
    newsletterArticles: state.newsletter.newsletterArticles,
    userData: state.user.data,
    newsletterId: state.newsletter.newsletterId,
    socialNetworkData: state.socialNetwork.data,
    outboxData: state.newsletter.data,
    outboxLoading: state.newsletter.isFetching,
    isArticleDeleting: state.newsletter.isArticleDeleting,
    newsletterScheduledTime: state.newsletter.scheduledTime,
    outBoxTabSource: state.newsletter.tab,
    recommendedData: get(state, 'explore.recommended', {}),
    exploreArticleData: get(state, 'explore.data', {}),
    leadCaptureDetails: state.newsletter.leadCapture,
    exploreArticleFetching: state.explore.isFetching,
    engagement_details: state.newsletter.engagement_details,
    isFromCompliance: state.newsletter.isFromCompliance,
    cloneDetails: state.newsletter.cloneDetails,
    isPremiumAdvisor: isPremuimAdvisor,
    newsletterName: state.newsletter.newsletterName,
    channelId: isPremuimAdvisor ? get(state, 'channel.premiumAdvisorChannelId', '') : getChannelId(state),
    popularThemes: get(state, 'theme.popularThemes', [])  
  };
};

const mapDispatchToProps = {
  newsletterSetState: newsletterActions.api.newsletter.setstate,
  newsletterBucketRequest:
    newsletterMoreActions.custom.newsletter.bucketRequest,
  newsletterBucketDeleteRequest:
    newsletterMoreActions.custom.newsletter.bucketDelete,
  loginRequest: loginActions.api.login.request,
  newsletterOutboxRequest: newsletterActions.api.newsletter.request,
  newsletterDeleteRequest: newsletterActions.api.newsletter.delete,
  exploreArticleRequest: exploreActions.api.explore.request,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NewsletterContainer);
