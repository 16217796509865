import React, { useState, useEffect } from 'react';
import { get, isEmpty, max, find } from 'lodash';
import { BubbleWordCloud, LoaderWrapper } from '../';
import { getTrendWordCloud } from '../../services/dashboard';

const bubbleColors = [
  // '#a8a8a8',
  // '#a8a8a8',
  // '#74d2dd',
  // '#74d2dd',
  // '#b699d1',
  // '#b699d1',
  // '#72a3cb',
  // '#72a3cb',
  // '#6351ed',
  // '#6351ed',
  '#969198',
  '#969198',
  '#7dc5d0',
  '#7dc5d0',
  '#c95cfc',
  '#c95cfc',
  '#6351ed',
  '#6351ed',
  '#969198',
  '#969198',
];

const multiplyArr = [5,5,4,4,3,3,2,2,1,1]

const TrendingBubbleChart = ({ setTrendingTopicDetails, setTabIndex, onTrendingTabClick }) => {
  const [trendingData, setTrendingData] = useState([]);
  const [trendingTopicFetching, setTrendingTopicFetching] = useState(false);
  const [topicsDate, setTopicDate] = useState('');
  useEffect(() => {
    setTrendingTopicFetching(true);
    onTrendingTabClick()
    getTrendWordCloud()
      .then((response) => {
        setTrendingTopicFetching(false);
        const date = get(response, 'result.data.date');
        const orginalTopics = get(response, 'result.data.topics') || [];
        const topics = [];
        let start = 0;
        if (isEmpty(orginalTopics)) {
          return;
        }

        orginalTopics.forEach(({ name, count, id }) => {
          topics.push({
            Name: name,
            Count: count * multiplyArr[start],
            Radius: 50,
            tid: id,
            fillColor: bubbleColors[start],
          });
          start += 1;
          if (start > 9) {
            start = 0;
          }
        });


        const maxCountItem = max(topics, 'Count');
        const maxTrendId = get(maxCountItem, 'tid');
        setTopicDate(date);
        // console.log(maxTrendId, date);
        setTrendingTopicDetails({
          id: maxTrendId,
          date,
          topicName: maxCountItem.Name,
        });
        setTrendingData(topics);
      })
      .catch((err) => {
        setTrendingTopicFetching(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onArticleClick = (id) => {
    setTabIndex();
    setTrendingTopicDetails({
      id,
      date: topicsDate,
      topicName: get(find(trendingData, { tid: id }), 'Name', ''),
    });
  };

  return (
    <LoaderWrapper isLoading={trendingTopicFetching} loaderHeight="220px">
      {!isEmpty(trendingData) && (
        <BubbleWordCloud
          data={{ children: trendingData.slice(0, 10) }}
          getTopicArticle={onArticleClick}
        />
      )}
    </LoaderWrapper>
  );
};

export default TrendingBubbleChart;
