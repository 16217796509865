import React from 'react';
import _, { isEmpty } from 'lodash';
import moment from 'moment';
import { MembersCheckBox, RepostCheckBox } from './ShareCheckBoxes';
import { 
    ScheduleModal,
    AdvisorTxt,
    ShareModalListItem,
    ShareModalList,
    ShareModalDiv,
} from '../ShareContentContainer.styles';
import {
    ModalHeader,
    ModalCloseButton,
    MiniArticle,
    ModalBody,
    Modal
} from '../../';
import { Button } from '../../Form';
import CustomizeEmail from '../customizeEmail';
import { onImageError } from '../../../utils';

const SchedulerModal = ({
    setScheduleState,
    scheduleState,
    scheduleTime,
    advisorScheduleTime,
    isChecked,
    isAdvisorChecked,
    fromMyClients
}) => {
    return(
        <ScheduleModal
            onRequestClose={() => setScheduleState({clicked: false, scheduleIsOpen: false})}
            shouldCloseOnOverlayClick
            isOpen={scheduleState.scheduleIsOpen}
        >
            <ModalHeader style={{ textAlign: 'center' }}>
            <ModalCloseButton onClick={() => setScheduleState({clicked: false, scheduleIsOpen: false})} />
            </ModalHeader>
            <img src='/assets/images/Calender.svg' alt='' />
            <div style={{paddingTop: 10}}>
            This article has been scheduled<br />to be shared on<br />
            {scheduleTime && (fromMyClients || _.values(isChecked).includes(true)) && `(${moment(scheduleTime).format("Do MMM YYYY")} at ${moment(scheduleTime).format("h:mm A")})`}<br />
            {advisorScheduleTime && _.values(isAdvisorChecked).includes(true) && `(${moment(advisorScheduleTime).format("Do MMM YYYY")} at ${moment(advisorScheduleTime).format("h:mm A")})`}
            </div>
        </ScheduleModal>
    );
}

const MembersModal = ({
    toggleAdvisorModal,
    isAdvisorModalOpen,
    setIsAdvisorModalOpen,
    isAdmin,
    membersList,
    selectedMembers,
    setSelectedMembers,
}) => {
    const [, forceUpdate] = React.useReducer(x => x + 1, 0);

    const handleAdvisorChange = (e, selectedMember) => {
        let list = [...selectedMembers];
        console.log(selectedMember);
        if(selectedMember.id === 'selectAll'){
          if(e.target.checked){
            let allMembers = [];
            _.each(membersList, (member) => {
                allMembers.push(member.id);
            });
            setSelectedMembers(allMembers);
          }else{
            setSelectedMembers([]);
          }
        }else{
          if(e.target.checked){
            list.push(selectedMember.id);
            setSelectedMembers(list);
          }else{
            setSelectedMembers(_.without(list, selectedMember.id, 'selectAll'));
          }
        }
        forceUpdate();
    }
    
    return(
        <ScheduleModal
            onRequestClose={() => toggleAdvisorModal()}
            shouldCloseOnOverlayClick
            isOpen={isAdvisorModalOpen}
            paddingBottom='32px'
        >
            <ModalHeader style={{ textAlign: 'center', fontWeight: 'bold', fontSize: '18px', color: '#49484a' }}>
            Autopost On<br />Member Connections
            <ModalCloseButton onClick={() => setIsAdvisorModalOpen(false)} />
            </ModalHeader>

            {isAdmin && membersList && membersList.length > 1 && (
            <MembersCheckBox
                membersList={membersList}
                handleAdvisorChange={handleAdvisorChange}
                selectedMembers={selectedMembers}
            />
            )}

            <Button
            margin="15px 0px 0px 0px"
            onClick={() => setIsAdvisorModalOpen(false)}
            height={'35px'}
            padding={'4px 28px 5px 29px'}
            >
            Add
            </Button>
            <AdvisorTxt>
            ({_.without(selectedMembers, 'selectAll').length} Advisors selected)
            </AdvisorTxt>
        </ScheduleModal>
    )
}

export const RepostModal = ({ 
    open,
    setOpen,
    shareError,
    isAdmin,
    scheduleTime,
    shareArticle,
    onShareClick,
    setRepostOptions,
    repostOptions,
    fromMyClients,
    onClientShareClick,
    shareArticleSetState
}) => {

    const handlePlatformChange = (e, data) => {
        console.log('checking');
        console.log(e, data);
        if(repostOptions.includes(data.platform)) {
           let newArray = repostOptions.filter(s => s !== data.platform);
           setRepostOptions([...newArray]);
        } else {
            setRepostOptions([...repostOptions, data.platform]);
        }   
    }

    const handleClick = () => {
        setOpen(false);
        if(fromMyClients) {
            shareArticleSetState({ data: {}, error: [] });
            onClientShareClick(null, true);
        } else if(isAdmin && !fromMyClients) {
            shareArticleSetState({ data: {}, error: [] });
            shareArticle(true);
        } else {
            shareArticleSetState({ data: {}, error: [] });
            onShareClick(scheduleTime, true)
        } 
    }

    const handleClose = () => {
        setRepostOptions([]);
        setOpen(false);
    }
    return (
        <ScheduleModal
            onRequestClose={handleClose}
            shouldCloseOnOverlayClick
            isOpen={open}
            paddingBottom='32px'
            padding='0px'
            pb='20px'
            width='38%'
        >
            <ModalHeader style={{ textAlign: 'center', fontWeight: 'bold', fontSize: '13px', color: '#49484a' }}>
                <p>You've already shared this article on the following platform(s).<br/>Select the platform(s) you want to repost it on.</p>               
            <ModalCloseButton onClick={handleClose} />
            </ModalHeader>

            {!isEmpty(shareError) && (
            <RepostCheckBox
                shareError={shareError}
                handlePlatformChange={handlePlatformChange}
                repostOptions={repostOptions}
            />
            )}

            <Button
                margin="15px 0px 0px 0px"
                onClick={handleClick}
                height={'35px'}
                bgColor={isEmpty(repostOptions) ? '#AFAFAF' : ''}
                padding={'4px 28px 5px 29px'}
                disabled={isEmpty(repostOptions)}
            >
            Repost
            </Button>
        </ScheduleModal>
    )
}

const CustomizeShareModal = ({
    toggleCustomizeModal,
    setIsCustomizeModalOpen,
    isCustomizeModalOpen,
    source,
    title,
    time,
    imagesrc,
    contentLink,
    pdfImage,
    rest,
    summaryData,
    setEmailTextData,
    isFromRecentTab,
    isFromAddContent,
    selectedItem,
    isFromScheduledTab,
    setIsContentChanged,
    isComplianceAdmin,
    emailTextData,
    isContentChanged,
    setEmailShareData,
    isCloning,
    article,
    complianceCustomisedData,
    approvedArticles,
    approvedCustomizedData
}) => {
    // console.log('rest in Modals', imagesrc, 'SOURCE - - - -', source, 'isCompliance admin - - -- ', isComplianceAdmin);
    // console.log('COMPLIANCE ADMIN (MODAL) - - - -', isComplianceAdmin);
    // console.log('SOURCE (MODAL) - - - -', source);

    return(
        <ScheduleModal
            onRequestClose={(e) => toggleCustomizeModal(e)}
            shouldCloseOnOverlayClick
            isOpen={isCustomizeModalOpen}
            width={'92%'}
            pb='20px'
        >
            <ModalHeader style={{ textAlign: 'center' }}>
                <ModalCloseButton onClick={() => setIsCustomizeModalOpen(false)} />
            </ModalHeader>

            <ShareModalDiv>
            <ShareModalList
                screenWidth={window.innerWidth}
            >
                <ShareModalListItem style={{width: '29%'}} screenWidth={window.innerWidth}>
                    <MiniArticle
                        source={source}
                        topic={title}
                        created={time}
                        image={imagesrc}
                        link={contentLink}
                        pdfImage={pdfImage}
                        {...rest}
                        fromCustomizeShareModal={true}
                    />
                </ShareModalListItem>
                <ShareModalListItem style={{width: '71%'}}>
                <CustomizeEmail
                    backToPreviousScreen={setIsCustomizeModalOpen} 
                    summaryText={summaryData.emSummary} 
                    articleData={{image: rest.url, title: rest.topic}}
                    setEmailTextData={setEmailTextData}
                    articleId={isFromAddContent ? rest.secondaryId : isCloning ? article.articleId : rest.id}
                    pdfImage={pdfImage}
                    isFromRecentTab={isFromRecentTab}
                    isFromScheduledTab={isFromScheduledTab}
                    selectedItem={selectedItem}
                    setIsContentChanged={setIsContentChanged}
                    isComplianceAdmin={isComplianceAdmin}
                    rest={rest}
                    isContentChanged={isContentChanged}
                    setEmailShareData={setEmailShareData}
                    isCloning={isCloning}
                    article={article}
                    complianceCustomisedData={complianceCustomisedData}
                    approvedArticles={approvedArticles}
                    approvedCustomizedData={approvedCustomizedData}
                />
                </ShareModalListItem>
                </ShareModalList>
            </ShareModalDiv>
        </ScheduleModal>
    )
}

const ImagePreviewModal = ({ openImageModal, toggleImageModal, mediaUrl, isFromImageOnly }) => {
    return (
        <Modal size='flexible' height='auto' shouldCloseOnOverlayClick isOpen={openImageModal} onRequestClose={e => toggleImageModal(false)} >
            <ModalHeader>
                <ModalCloseButton onClick={() => toggleImageModal(false)} />
            </ModalHeader>
            <ModalBody>
                <div style={{textAlign: 'center', overflow: 'auto', maxHeight: '500px', maxWidth: '1000px', margin: 'auto'}}>
                    <img onError={onImageError} src={isFromImageOnly ? mediaUrl : mediaUrl && mediaUrl[0].path + mediaUrl[0].name.system + '.' + mediaUrl[0].ext} />
                    {console.log('mediaUrl- -- -- - -', mediaUrl)}
                </div>
            </ModalBody>
        </Modal>
    );
}

export {
    SchedulerModal,
    MembersModal,
    CustomizeShareModal,
    ImagePreviewModal
}