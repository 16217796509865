import styled from 'styled-components';
import { colors } from '../../styles';

export const BorderBottomContainer = styled('div')`
margin: 0 0 20px;
padding: 0 0 20px;
width: 100%;
border-bottom: 1px solid ${colors.gray_border};
display: table;
`;

export const TextLabel = styled('label')`
    font-weight: 500;
    font-size: 14px;
    color: #101828;
    text-align: left;
    width: 100%;
`;

export const SubHeaderSection = styled('div')`
  font-weight: 400;
  font-size: 14px;
  color: ${colors.gray_text};
  text-align: left;
`;

export const TextInput = styled('input')`
  width: ${({ width }) => width || '300px'};
  margin: ${({ margin }) => margin || '5px 0px'};
  border: 1px solid #e3e5e4;
  padding: 10px;
  background: ${colors.white};
  border-radius: 4px;
`;

export const HeadingSection = styled('h4')`
  font-family: 'Poppins' !important;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  text-align: left;
  color: #101828;
  margin: ${({ margin }) => margin || '10px 0'};
`;
