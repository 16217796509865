import React from 'react';
import ReactSkeleton from 'react-loading-skeleton';
import { BreadCrumbWrapper, LeftIconWrapper } from './styles';

function Skeleton(props) {
  return (
    <BreadCrumbWrapper>
      <LeftIconWrapper>
        <ReactSkeleton height={36} width={42} />
      </LeftIconWrapper>
      <BreadCrumbWrapper>
        <ReactSkeleton height={36} width={200} />
      </BreadCrumbWrapper>
    </BreadCrumbWrapper>
  );
}

export default Skeleton;
