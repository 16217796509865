import React from 'react';
import { Header, List, ListItem, Wrapper } from './styles';
import Skeleton from './Skeleton';
import ZeroState from 'src/pages/Discover/common/components/ZeroState';

function Topics({ topics = [], topicsLoader = false }) {
  if (topicsLoader) return <Skeleton />;
  return (
    <Wrapper>
      <Header>
        <h3>Recommended Topics</h3>
        <p>Based on your contacts engagement</p>
      </Header>
      <List>
        {Array.isArray(topics) &&
          (topics?.length ? (
            topics?.map((topic) => (
              <ListItem key={topic}>
                <p>{topic}</p>
              </ListItem>
            ))
          ) : (
            <ZeroState />
          ))}
      </List>
    </Wrapper>
  );
}

export default Topics;
