import { styled } from '@mui/material';
import Button from 'src/componentsV2/Button';

export const Wrapper = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: '1rem',
}));

export const RadioButtonGroup = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  gap: '1rem',
  padding: '1rem',
  backgroundColor: '#F9FAFB',
  border: '1px solid #E4E7EC',
  borderRadius: '0.25rem',
}));

export const RadioButtonWrapper = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '0.5rem',
  cursor: 'pointer',
}));

export const RadioButton = styled('input')(() => ({
  border: '1px solid #816EF8',
  cursor: 'pointer',
  margin: 0,
}));

export const Label = styled('label')(() => ({
  fontSize: '0.875rem',
  fontWeight: '500',
  color: '#101828',
  margin: 0,
  cursor: 'pointer',
}));

export const Articles = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '1.5rem',
}));

export const PrimaryActions = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '1rem',
}));

export const CascadeButton = styled(Button)(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '0.25rem',
  padding: '0.5rem',
  background: '#fff',
  border: '1px solid #E4E7EC',
  boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.12)',

  svg: {
    color: '#101828',
    fontSize: '1.5rem',
  },

  h4: {
    fontSize: '0.875rem',
    fontWeight: '500',
    color: '#101828',
    margin: 0,
    textAlign: 'left',
  },
}));

export const IconButton = styled(Button)(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '0.25rem',
  padding: '0.5rem',
  background: '#fff',
  border: '1px solid #E4E7EC',
  boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.12)',

  svg: {
    color: '#101828',
    fontSize: '1.5rem',
  },
}));

export const EditContent = styled('div')(() => ({
  padding: '1rem',
}));

export const CascadeWrapper = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '1rem',
  padding: '1rem',
}));

export const CascadeCompliance = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '0.5rem',

  h4: {
    fontSize: '0.875rem',
    color: '#101828',
    margin: 0,
  },
}));

export const ModalActions = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  gap: '1rem',
}));

export const DeleteModalWrapper = styled('div')(() => ({
  padding: '1rem',
}));
