import React from 'react';

import { StyledButton, ButtonNoBorderBackground } from '../CommonStyles';

export const Button = ({
  type,
  upperCase,
  onClick,
  children,
  disabled,
  className,
  height,
  width,
  margin,
  padding,
  isSecondary,
  color,
  bgColor,
  fontSize,
  cursor,
  activeColor,
  borderRadius,
  styles,
  borderColor,
  id,
  testId,
  position,
  right
}) => (
  <StyledButton
    type={type || 'button'}
    upperCase={upperCase}
    onClick={onClick}
    disabled={disabled}
    className={className}
    height={height}
    cursor={cursor}
    width={width}
    margin={margin}
    padding={padding}
    isSecondary={isSecondary}
    color={color}
    bgColor={bgColor}
    fontSize={fontSize}
    activeColor={activeColor}
    borderRadius={borderRadius}
    borderColor={borderColor}
    style={styles ? styles : {}}
    id={id}
    data-testid={testId}
    position={position}
    right={right}
  >
    {children}
  </StyledButton>
);

export const DripIconButton = ({
  onClick,
  icon,
  className,
  title,
  styles,
  disable,
  color,
  fontSize,
  ml
}) => (
  <ButtonNoBorderBackground
    type="button"
    onClick={onClick}
    data-btn-type={`icon-${icon}-btn`}
    className={className}
    disabled={disable || false}
    color={color}
    fontSize={fontSize}
    style={{'marginLeft' : ml || 'auto'}}
  >
    <div
      className={`icon dripicons-${icon}`}
      title={title}
      style={styles}
    ></div>
  </ButtonNoBorderBackground>
);
