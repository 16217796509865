import React, { useState } from 'react';
import {
    GraphDisplayContainer,
    CardTitle,
    HeaderContainer,
    GraphContainer,
    LegendContainer,
    LegendKey,
    LegendItem,
    TooltipName,
    TooltipValue,
    TooltipContainer,
    TooltipTitle
} from './styles';

import blackEmail from '../../assets/icons/blackEmail.svg';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import StatCards from '../StatCards';
import { Flex } from '@rebass/grid';
import { ColorPallete, Typography } from '../../utils';
import Skeleton from 'react-loading-skeleton';
import ErrorDisplay from '../ErrorDisplay/ErrorDisplay';
import { isEmpty } from 'lodash';
import { EmptyState } from '../EmptyState';

const CustomTooltip = ({ payload, active }) => {
    if (active && payload && payload.length) {
      return (
        <TooltipContainer>
            <TooltipTitle>{payload[0].payload?.name}</TooltipTitle>
            {
                payload.map((row, index) => {
                    return(
                        <div style={{display: 'flex'}} key={index}>
                            <TooltipName>{row?.name}:</TooltipName>
                            <TooltipValue color={row?.color}>{row?.value}%</TooltipValue>
                        </div>
                    )
                })
            }
        </TooltipContainer>
      );
    }

    return null;
  };

const GraphDisplay = ({
  chartData,
  showTitle,
  showDropdown,
  showStatCards,
  showLegend,
  background,
  height,
  graphData,
  dataKeys,
  loading,
  handleRetryClick,
  errorDisplay,
  showEnterpriseDropdownSelectedOption,
  noInterval=false,
  selectedFirms,
  interval
}) => {
    // const statData = [
    //     {
    //         title: 'Open Rate',
    //         value: chartData['Open Rate'].count,
    //         growth: chartData['Open Rate'].growth,
    //         icon: purpleLightning
    //     },
    //     {
    //         title: 'Click Through Rate',
    //         value: chartData['Click Through Rate'].count,
    //         growth: chartData['Click Through Rate'].growth,
    //         icon: yellowLightning
    //     }
    // ]

    return (
      <GraphDisplayContainer
        background={background}
        style={{
          borderBottomRightRadius:
            showEnterpriseDropdownSelectedOption === 'My Enterprise' || !isEmpty(selectedFirms)
              ? '0px'
              : '8px',
          borderTopRightRadius:
            showEnterpriseDropdownSelectedOption === 'My Enterprise' || !isEmpty(selectedFirms)
              ? '0px'
              : '8px',
        }}
      >
        {showTitle && (
          <HeaderContainer>
            <CardTitle>{chartData.title}</CardTitle>
            <Flex
              padding="0px 20px"
              alignItems="center"
              fontSize={Typography.Heading}
            >
              <img
                src={blackEmail}
                alt=""
                style={{ padding: '0px 7px 0px 0px' }}
                height="15"
              />
              Email
            </Flex>
          </HeaderContainer>
        )}
        {loading && (
          <Skeleton
            height={
              showEnterpriseDropdownSelectedOption === 'My Enterprise' || !isEmpty(selectedFirms)
                ? 340
                : 373
            }
            baseColor={ColorPallete.neutral100}
          />
        )}
        {!loading && !errorDisplay && isEmpty(graphData) && (
          <EmptyState
            height={
              showEnterpriseDropdownSelectedOption === 'My Enterprise' || !isEmpty(selectedFirms)
                ? '344px'
                : '377px'
            }
            title="No Analytics Data"
            subTitle="Send out campaigns to get insights"
          />
        )}
        {!loading && errorDisplay === false && !isEmpty(graphData) && (
          <GraphContainer
            style={{
              height:
                showEnterpriseDropdownSelectedOption === 'My Enterprise' || !isEmpty(selectedFirms)
                  ? '335px'
                  : '377px',
            }}
          >
            {showLegend && (
              <LegendContainer>
                {chartData.keys.map((item, index) => {
                  return (
                    <div
                      key={index}
                      style={{ display: 'flex', margin: '0 20px 0 0' }}
                    >
                      <LegendKey background={item.color} />
                      <LegendItem>{item.title}</LegendItem>
                    </div>
                  );
                })}
              </LegendContainer>
            )}
            <ResponsiveContainer width="99%" height={height ? height : 400}>
              <LineChart data={graphData}>
                <CartesianGrid strokeDasharray="3 3" vertical={false} />
                <XAxis dataKey="name" interval={noInterval ? 'preserveStartEnd' : interval}/>
                <YAxis />
                <Tooltip
                  content={<CustomTooltip />}
                  cursor={{ strokeDasharray: '3 3' }}
                />
                {chartData.keys.map((line, index) => {
                  return (
                    <Line
                      key={index}
                      type="monotone"
                      dataKey={line.title}
                      stroke={line.color}
                    />
                  );
                })}
              </LineChart>
            </ResponsiveContainer>
          </GraphContainer>
        )}

        {!loading && errorDisplay === true && (
          <ErrorDisplay
            height={
              showEnterpriseDropdownSelectedOption === 'My Enterprise' || !isEmpty(selectedFirms)
                ? '344px'
                : '377px'
            }
            onClick={handleRetryClick}
          />
        )}

        {/* {showStatCards && <div style={{display: 'flex', margin: '10px auto', width: '90%', justifyContent: 'space-between'}}>
                {
                    statData.map((stat, index) => {
                        return <StatCards statData={stat} key={index} />
                    })
                }
            </div>} */}
      </GraphDisplayContainer>
    );
};

export default GraphDisplay;