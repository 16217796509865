import { get } from 'lodash';
import { takeLatest, all, call, put } from 'redux-saga/effects';
import { actions as groupActions } from '../modules/group.module';
import {
  getGroupList,
  createGroup,
  updateGroup,
} from '../../services/configure';

function* groupRequest() {
  try {
    const response = yield call(getGroupList);
    const data = get(response, 'result.data', {});
    yield put(groupActions.api.group.response(data));
  } catch (error) {
    yield put(groupActions.api.group.error(error));
  }
}

function* createGroupRequest({ payload }) {
  try {
    yield call(createGroup, get(payload, 'params', {}));
    yield put(groupActions.api.group.request());
  } catch (error) {
    yield put(groupActions.api.group.error(error));
  }
}

function* updateGroupRequest({ payload }) {
  try {
    yield call(updateGroup, get(payload, 'params', {}));
    yield put(groupActions.api.group.request());
  } catch (error) {
    yield put(groupActions.api.group.error(error));
  }
}

function* groupSaga() {
  yield all([
    takeLatest(groupActions.api.group.request, groupRequest),
    takeLatest(groupActions.api.group.create, createGroupRequest),
    takeLatest(groupActions.api.group.update, updateGroupRequest),
  ]);
}

export default groupSaga;
