/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { get, isEmpty } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { EmailPreview } from '..';
import {
  CustomButton,
  PostRejectUl,
  PostRejectListItem,
  SocialBox,
  SocialIconSpan,
  SocialIconI,
  CustomTextArea,
  TextAreaWrapper,
  CustomInputField,
} from './ShareContentContainer.styles';
import CreatableSelect from 'react-select/creatable';
import { getUserDetails } from '../../redux/selector/login.selectors';
import { getMediaUrl, themesData } from '../../utils';
import { getComplianceEmailData, getEmailData } from '../../services/dashboard';
import { Modal, ModalBody } from '../Modal';
import { Flex, Box } from '@rebass/grid';
// import { FrolaRichTextEditor } from '../RichTextEditor/FroalaRichTextEditor';

const CustomizeEmailContainer = ({
  backToPreviousScreen,
  summaryText,
  userData,
  articleData,
  setEmailTextData,
  articleId,
  pdfImage,
  isFromRecentTab,
  isFromScheduledTab,
  selectedItem,
  setIsContentChanged,
  isButtonVisible,
  isComplianceAdmin,
  isFromCompliance,
  engagementId,
  isSummaryFieldPreviewClick,
  setIsSummaryFieldPreviewClick,
  isContentChanged,
  setEmailShareData,
  emailShareData,
  image,
  rest,
  isCloning,
  article,
  complianceCustomisedData,
  approvedArticles,
  approvedCustomizedData,
}) => {
  console.log('approvedCustomizedData', approvedCustomizedData);
  const salutationTextOptions = [
    { label: 'First Name', value: '<first_name>' },
    { label: 'Last Name', value: '<last_name>' },
    { label: 'Full Name', value: '<full_name>' },
  ];
  const [isEmailPreviewOpen, setEmailPreviewOpen] = useState(false);
  const [emailNameText, setEmailNameText] = useState({
    label: 'First Name',
    value: '<first_name>',
  });
  const [emailData, setEmailData] = useState({
    subject: 'Generating subject...',
    message: '',
    salutation: 'Hi',
    placeholderText: 'Generating message...',
  });
  const [base64Logo, setBase64Logo] = useState('');
  const [themeColor, setThemeColor] = useState('#6351ed');
  const [showResubmitApprovalModal, setShowResubmitApprovalModal] =
    useState(false);
  const [isEmailContentChanged, setIsEmailContentChanged] = useState(false);
  const [startEditing, setStartEditing] = useState(false);
  const [useLogo, setUseLogo] = useState(true);
  const previousEmailData = React.useRef('');

  const emailPersonalization = get(userData, 'email_personalization');
  const editorSettingsMedia = get(
    userData,
    'personalization.corporate_identity.logo.main.media_urls'
  );

  const bannerColor = get(emailPersonalization, 'banner_hex_color');
  const signature = get(emailPersonalization, 'signature', '');
  const description = get(emailPersonalization, 'footer_text', '');
  const logoUrl = getMediaUrl(
    get(emailPersonalization, 'media_urls')
      ? get(emailPersonalization, 'media_urls')
      : editorSettingsMedia
      ? editorSettingsMedia[0].path +
        editorSettingsMedia[0].name.system +
        '.' +
        editorSettingsMedia[0].ext
      : ''
  );

  useEffect(() => {
    // console.log('[email personalization] - ',get(emailPersonalization, 'media_urls') ? 'true' : 'false');
    let logo =
      emailPersonalization &&
      emailPersonalization.media_urls &&
      emailPersonalization.media_urls.length > 0
        ? emailPersonalization.media_urls[0].path +
          emailPersonalization.media_urls[0].name.system +
          '.' +
          emailPersonalization.media_urls[0].ext
        : editorSettingsMedia
        ? editorSettingsMedia[0].path +
          editorSettingsMedia[0].name.system +
          '.' +
          editorSettingsMedia[0].ext
        : '';
    // console.log('[logo url] - ',logo);
    setThemeColor(bannerColor);
    setTimeout(() => {
      setBase64Logo(logo ? logo : '/assets/images/fideate-logo.svg');
    }, 1000);
  }, [logoUrl, bannerColor]);

  // const getHtmlParseText = (text) => {
  //     console.log('getHtmlParseText', text);
  //     // if(typeof(text) === 'string' && !text.includes('<')) {
  //     //     return text;
  //     // } else
  //     if(typeof(text) === 'string') {
  //         let message = parseHtml(text);
  //         if(Array.isArray(message)) {
  //           message = message[0].props.children;
  //           getHtmlParseText(message);
  //         } else if(typeof(text) === 'object'){
  //             getHtmlParseText(text.props.children);
  //         }
  //         return text;
  //     } else if(typeof(text) === 'object'){
  //         getHtmlParseText(text.props.children);
  //     } else {
  //         return '';
  //     }

  // }

  // useEffect(() => {
  //       const cloneEmailData = {
  //           ...emailData,
  //           message : emailData?.message ?? ''
  //       }
  //       if(emailData && previousEmailData.current) {
  //         const {message: cloneMessage, ...cloneEmailDataObj} = cloneEmailData
  //         const {message : previousEmailDataMessage, ...previousEmailDataOb} = previousEmailData.current;
  //         //let messageParse = parseHtml(cloneMessage);
  //       //  let  previousEmailDataMessageParse = parseHtml(previousEmailDataMessage)
  //         // if(Array.isArray(messageParse)) {
  //         //     messageParse = messageParse[0].props.children;
  //         // }

  //         // if(Array.isArray(messageParse)) {
  //         //     messageParse = messageParse[0].props.children;
  //         // }

  //         let messageParse = getHtmlParseText(cloneMessage)
  //         let previousEmailDataMessageParse = getHtmlParseText(previousEmailDataMessage);
  //         // if(Array.isArray(previousEmailDataMessageParse)) {
  //         //     previousEmailDataMessageParse = previousEmailDataMessageParse[0].props.children;
  //         // }
  //         console.log('savedEmailData.message', cloneEmailData, previousEmailData.current);
  //         console.log('previousEmailDataMessageParse ', previousEmailDataMessageParse, messageParse);

  //         if(JSON.stringify(cloneEmailDataObj) !== JSON.stringify(previousEmailDataOb)
  //         || previousEmailDataMessageParse !== messageParse) {
  //           setIsContentChanged(true);
  //           setIsEmailContentChanged(true);
  //           console.log('changed')
  //         } else {
  //           console.log('not changed')
  //           setIsContentChanged(false);
  //           setIsEmailContentChanged(false);
  //         }
  //       }

  // }, [emailData]);

  useEffect(() => {
    const savedEmailData = JSON.parse(localStorage.getItem('emailData'));
    setUseLogo(
      get(userData, 'email_personalization.use_logo', 'false') === 'true'
    );
    if (savedEmailData && !savedEmailData.placeholderText) {
      savedEmailData.placeholderText = 'Type your email intro message here...';
    }

    if (savedEmailData && !approvedArticles) {
      const result = salutationTextOptions.filter(
        (object) => object.value === savedEmailData.salutation_text
      );
      const salutation_text = (result.length > 0 && result[0]) ?? '';
      setEmailData({
        ...emailData,
        salutation_text,
        ...savedEmailData,
      });
      previousEmailData.current = {
        ...emailData,
        salutation_text,
        ...savedEmailData,
      };
      setStartEditing(true);
      setEmailNameText(result.length > 0 && result[0]);
    } else if (isFromCompliance) {
      if (!isEmpty(engagementId) && engagementId.length > 0) {
        getComplianceEmailData(engagementId).then((res) => {
          if (res.success) {
            setEmailData({
              ...emailData,
              salutation_text: res.data.salutation_text,
              subject: res.data.subject,
              message: res.data.message,
              salutation: res.data.salutation,
              logo: `${res.data.media_urls[0].path}${get(
                res.data.media_urls[0],
                'name.system'
              )}.${res.data.media_urls[0].ext}`,
              placeholderText: 'Type your email intro message here...',
            });
            setStartEditing(true);
            let salutationData =
              res &&
              res.data &&
              res.data.salutation_text &&
              salutationTextOptions.filter(
                (s) => s.value === res.data.salutation_text
              );
            if (!isEmpty(salutationData)) {
              setEmailNameText(salutationData[0]);
            }
            setEmailShareData({
              salutation_text: res.data.salutation_text,
              subject: res.data.subject,
              message: res.data.message,
              salutation: res.data.salutation,
            });
            previousEmailData.current = {
              ...emailData,
              salutation_text: res.data.salutation_text,
              subject: res.data.subject,
              message: res.data.message,
              placeholderText: 'Type your email intro message here...',
            };
          }
        });
      } else if (complianceCustomisedData) {
        setEmailShareData({
          salutation_text: complianceCustomisedData.salutation_text,
          subject: complianceCustomisedData.subject,
          message: complianceCustomisedData.message,
          salutation: complianceCustomisedData.salutation,
          placeholderText: complianceCustomisedData.placeholderText,
        });
        setStartEditing(true);
        setEmailData({
          ...emailData,
          salutation_text: complianceCustomisedData.salutation_text,
          subject: complianceCustomisedData.subject,
          message: complianceCustomisedData.message,
          salutation: complianceCustomisedData.salutation,
          placeholderText: 'Type your email intro message here...',
        });
      }
    } else if (isCloning) {
      let emailDataObj = {
        salutation_text: '<first_name>',
        subject:
          article && article.auxiliary && article.auxiliary.title
            ? article.auxiliary.title
            : '',
        message: '',
        placeholderText: 'Type your intro message here...',
        salutation: 'Hi',
      };
      const sharedDetailsArray =
        article &&
        article.auxiliary &&
        article.auxiliary.shared &&
        article.auxiliary.shared.details
          ? article.auxiliary.shared.details
          : [];
      let emailCustomizationData = sharedDetailsArray.filter(
        (item) => item.destination && item.destination.network === 'em'
      )[0];
      if (
        emailCustomizationData &&
        emailCustomizationData.email_customization
      ) {
        emailCustomizationData = emailCustomizationData.email_customization;
        emailDataObj = {
          salutation_text: emailCustomizationData.salutation_text,
          subject: emailCustomizationData.subject,
          message: emailCustomizationData.message,
          placeholderText: emailCustomizationData.placeholderText,
          salutation: emailCustomizationData.salutation,
        };
      }
      setEmailData(emailDataObj);
      setStartEditing(true);
    } else if (approvedArticles) {
      if (approvedCustomizedData) {
        setEmailData({
          ...emailData,
          salutation: get(approvedCustomizedData, 'salutation', ''),
          salutation_text: get(approvedCustomizedData, 'salutation_text', ''),
          placeholderText: get(approvedCustomizedData, 'placeholderText', ''),
          subject: get(approvedCustomizedData, 'subject', ''),
          message: get(approvedCustomizedData, 'message', ''),
        });
        setStartEditing(true);
      } else {
        getEmailData(articleId).then((res) => {
          if (res.message && res.subject) {
            setEmailData({
              ...emailData,
              salutation: get(res, 'salutation', ''),
              salutation_text: get(res, 'salutation_text', ''),
              placeholderText: 'Type your email intro message here...',
              subject: get(res, 'subject', ''),
              message: get(res, 'message', ''),
            });
            setStartEditing(true);
          }
        });
      }
    } else {
      getEmailData(articleId).then((res) => {
        if (res.message && res.subject) {
          setEmailData({
            ...emailData,
            salutation_text: res.salutation_text,
            subject: res.subject,
            salutation: res.salutation,
            placeholderText: 'Type your email intro message here...',
          });
          previousEmailData.current = {
            ...emailData,
            salutation_text: res.salutation_text,
            subject: res.subject,
            message: res.message,
            salutation: res.salutation,
            placeholderText: 'Type your email intro message here...',
          };
          setStartEditing(true);
        }
      });
    }
  }, []);

  // useEffect(() => {
  //   if(isSummaryFieldPreviewClick) {
  //     setEmailPreviewOpen(true);
  //   }
  // }, [isSummaryFieldPreviewClick])

  const handleChange = (newValue, actionMeta) => {
    const emailName = newValue ? newValue : { label: '', value: '' };
    if (!isFromScheduledTab) {
      setIsContentChanged(true);
      setIsEmailContentChanged(true);
    }

    setEmailNameText(emailName);

    setEmailData({
      ...emailData,
      salutation_text: newValue.label,
    });
    setEmailShareData({
      ...emailShareData,
      salutation_text: newValue.value,
    });
  };

  const handleTextAreaChange = (e) => {
    if (!isFromScheduledTab) {
      setIsContentChanged(true);
      setIsEmailContentChanged(true);
    }
    const stateChange = e.target.value;
    setEmailShareData({
      ...emailShareData,
      [e.target.name]: stateChange.replace(/&#8232;/g, ' '),
    });

    setEmailData({
      ...emailData,
      [e.target.name]: stateChange.replace(/&#8232;/g, ' '),
    });
  };

  const handleMessageChange = (value) => {
    if (!isFromScheduledTab) {
      setIsContentChanged(true);
      setIsEmailContentChanged(true);
    }

    setEmailData({
      ...emailData,
      message: value,
    });
    setEmailShareData({
      ...emailShareData,
      message: value,
    });
  };

  const handleClick = (type, isResubmit) => {
    switch (type) {
      case 'preview':
        setEmailPreviewOpen(true);
        return;
      case 'save':
        if (
          selectedItem &&
          selectedItem.is_complianced &&
          isFromScheduledTab &&
          !isResubmit
        ) {
          setShowResubmitApprovalModal(true);
        } else {
          const obj = { ...emailData, salutation_text: emailNameText.value };
          setEmailTextData(obj);
          localStorage.setItem('emailData', JSON.stringify(obj));
          setShowResubmitApprovalModal(false);
          setIsContentChanged(true);
          setEmailPreviewOpen(false);
          backToPreviousScreen(false);
        }
        return;
      case 'previousScreen':
        backToPreviousScreen(false);
        return;
      default:
        return;
    }
  };

  const styles = {
    control: (base, state) => ({
      ...base,
      minHeight: 14,
      width: '150px',
      marginLeft: '5px',
      background: 'transparent',
      fontSize: '12px',
      boxShadow: '0',
      borderColor: '#b3b3b3',
      '&:hover': {
        borderColor: '#6351ed',
      },
    }),
    option: (base, state) => ({
      ...base,
      color: state.isSelected ? '#fff' : '#6351ed',
      backgroundColor: state.isSelected ? '#6351ed' : '#fff',
      fontSize: '12px',
      fontWeight: '100',
      fontFamily: 'Poppins',
      '&:hover': {
        backgroundColor: !state.isSelected ? '#2684ff1a' : '',
      },
    }),
    dropdownIndicator: (base) => ({
      ...base,
      paddingTop: 0,
      paddingBottom: 0,
      color: '#848484',
    }),
    clearIndicator: (base) => ({
      ...base,
      paddingTop: 0,
      paddingBottom: 0,
    }),
    menu: (base) => ({
      ...base,
      zIndex: '3000',
    }),
  };

  const buttons = isButtonVisible
    ? [
        // {name: 'Preview', id: 'preview', width: '70px', margin: '25px 0px 15px 41%', background: '#ffffff', color: themesData.purpleishBlue},
      ]
    : isFromRecentTab && !isCloning
    ? [
        {
          name: 'Preview',
          id: 'preview',
          width: '70px',
          background: '#ffffff',
          color: themesData.purpleishBlue,
        },
        {
          name: 'Return to Previous Screen',
          id: 'previousScreen',
          width: '187px',
        },
      ]
    : approvedArticles
    ? [
        {
          name: 'Preview',
          id: 'preview',
          background: '#ffffff',
          color: themesData.purpleishBlue,
        },
        { name: 'Return to Previous Screen', id: 'previousScreen' },
      ]
    : [
        {
          name: 'Preview',
          id: 'preview',
          background: '#ffffff',
          color: themesData.purpleishBlue,
        },
        {
          name: 'Save',
          id: 'save',
          background: themesData.purpleishBlue,
          color: '#ffffff',
        },
        { name: 'Return to Previous Screen', id: 'previousScreen' },
      ];

  const modalStyles = {
    ModalBody: {
      color: '#6351ed',
    },
  };

  const ResubmitApprovalModal = ({ open }) => {
    const submitClick = (type) => {
      if (type === 'cancel') {
        setShowResubmitApprovalModal(false);
      } else {
        handleClick(type, true);
      }
    };
    return (
      <Modal shouldCloseOnOverlayClick isOpen={open} size="medium" width="40%">
        <ModalBody col style={modalStyles.ModalBody}>
          <Flex
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            style={{ color: themesData.purpleishBlue }}
          >
            <Box>Changes you have made require complaince review.</Box>
            <Box>Re-submit to complaince?</Box>
          </Flex>
          <Flex
            justifyContent="center"
            alignItems="center"
            style={{ marginTop: '2rem' }}
          >
            <CustomButton
              id="resubmitSubmit"
              margin={'6px 0 0 15px'}
              padding={'5px 10px'}
              width="auto"
              height="auto"
              background={themesData.purpleishBlue}
              color="#ffffff"
              onClick={() => submitClick('save')}
              style={{ fontFamily: 'Poppins' }}
            >
              Submit
            </CustomButton>
            <CustomButton
              id="resubmitCancel"
              margin={'6px 0 0 15px'}
              padding={'5px 10px'}
              width="auto"
              height="auto"
              background="#ffffff"
              color={themesData.purpleishBlue}
              onClick={() => submitClick('cancel')}
              style={{ fontFamily: 'Poppins' }}
            >
              Cancel
            </CustomButton>
          </Flex>
        </ModalBody>
      </Modal>
    );
  };
  return (
    <>
      <PostRejectUl isFromAddContent={false}>
        <PostRejectListItem>
          <SocialBox
            isFromCompliance
            borderColor={!isFromCompliance ? '#ea5946' : 'white'}
            style={
              isFromCompliance
                ? { background: 'white', marginBottom: '15px' }
                : { background: 'white', marginBottom: '15px' }
            }
            padding={!isFromCompliance ? '10px' : '0px'}
          >
            {!isFromCompliance && (
              <SocialIconSpan>
                <SocialIconI
                  background={'#ea5946'}
                  style={{ padding: '4px 7px' }}
                >
                  <FontAwesomeIcon icon={faEnvelope} />
                </SocialIconI>
              </SocialIconSpan>
            )}
            <div
              style={{
                color: '#6351ed',
                fontSize: '16px',
                fontWeight: 'bold',
                textAlign: 'center',
              }}
            >
              {' '}
              {!isFromCompliance ? 'Customize Email' : ''}
            </div>

            <div style={{ color: '#6351ed', margin: '10px 0px 5px' }}>
              Subject
            </div>
            <CustomTextArea
              //   readOnly={approvedArticles}
              style={{ color: '#000', height: '41px' }}
              id="subject"
              name="subject"
              rows="3"
              onChange={handleTextAreaChange}
              value={emailData.subject}
            />
            {!emailData.subject && (
              <div style={{ color: '#848484', fontSize: '12px' }}>
                Subject cannot be empty!
              </div>
            )}
            <div style={{ color: '#6351ed', margin: '5px 0px' }}>Message</div>
            <TextAreaWrapper
              style={{
                backgroundColor: 'transparent',
                padding: '0px 10px 10px 0px',
              }}
            >
              <div style={{ display: 'flex' }}>
                <CustomInputField
                  width="20%"
                  value={emailData.salutation ? emailData.salutation : 'Hi'}
                  name="salutation"
                  style={{ padding: '0px 10px' }}
                  color="#000"
                  onChange={handleTextAreaChange}
                  //   readOnly={approvedArticles}
                />
                <div>
                  <CreatableSelect
                    // isClearable
                    onChange={handleChange}
                    options={salutationTextOptions}
                    styles={styles}
                    value={emailNameText}
                    // isDisabled={approvedArticles}
                  />
                </div>
              </div>
            </TextAreaWrapper>
            <div
              style={{
                fontFamily: 'Arial',
                fontWeight: '100',
                color: '#000',
                fontSize: '14px',
              }}
            >
              {/* <FrolaRichTextEditor
                content={emailData.message}
                setContent={handleMessageChange}
                placeHolder={emailData.placeholderText}
                disabled={approvedArticles && !startEditing}
                maxHeight={135}
                minHeight={120}
              /> */}
            </div>
          </SocialBox>
        </PostRejectListItem>
      </PostRejectUl>

      {buttons.map((button) => (
        <CustomButton
          id={button.id}
          margin={isFromCompliance ? '0px 0px 15px 41%' : '0px 0px 15px 5px'}
          padding={'5px 10px'}
          width="auto"
          height="auto"
          background={button.background}
          color={button.color}
          onClick={() => handleClick(button.id)}
          style={
            !emailData.subject && button.id === 'save'
              ? { cursor: 'not-allowed', opacity: '0.5', fontFamily: 'Poppins' }
              : { fontFamily: 'Poppins' }
          }
          disabled={!emailData.subject && button.id === 'save'}
        >
          {button.name}
        </CustomButton>
      ))}
      {(isEmailPreviewOpen || isSummaryFieldPreviewClick) && (
        <EmailPreview
          isModalOpen={
            !isFromCompliance ? isEmailPreviewOpen : isSummaryFieldPreviewClick
          }
          setModalOpen={
            !isFromCompliance
              ? setEmailPreviewOpen
              : setIsSummaryFieldPreviewClick
          }
          themeColor={themeColor || emailData.themeColor}
          emailNameText={`${emailData.salutation || 'Hi'} ${
            emailNameText.label ? `${emailNameText.label},` : ''
          }`}
          messageText={emailData.message}
          summaryText={summaryText}
          subjectText={emailData.subject}
          signature={signature}
          disclaimer={description}
          logo={base64Logo || emailData.logo}
          articleData={articleData}
          onSave={() => handleClick('save')}
          fromShareContent={true}
          pdfImage={image}
          isFromRecentTab={isFromRecentTab}
          imageWidth={
            get(emailPersonalization, 'image_width') &&
            get(emailPersonalization, 'image_width') !== '0'
              ? get(emailPersonalization, 'image_width')
              : '150'
          }
          imageHeight={
            get(emailPersonalization, 'image_height') &&
            get(emailPersonalization, 'image_height') !== '0'
              ? get(emailPersonalization, 'image_height')
              : 'auto'
          }
          isFromCompliance={isFromCompliance}
          useLogo={useLogo}
          fromCustomizeEmail={true}
        />
      )}

      <ResubmitApprovalModal
        open={showResubmitApprovalModal}
        setIsContentChanged={setIsContentChanged}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    userData: getUserDetails(state),
  };
};

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomizeEmailContainer);
