import { Flex } from '@rebass/grid';
import styled from 'styled-components';

export const PublisherTopicWrapper = styled(Flex)`
  width: 100%;
  padding: 10px;
  flex-direction: column;
  display: flex;
  position: relative;
  background-color: #fff;
`;

export const PublisherTitle = styled(Flex)`
  display: flex;
  text-align: left;
  align-items: center;
  width: 60%;
  color: #101828;
  padding: 10px;
  margin: 5px auto;
  justify-content: flex-start;
  font-size: 16px;
`;

export const PublisherCardWrapper = styled(Flex)`
  width: 100%;
  max-height: 200px;
  overflow-x: hidden;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 5px;
  }
  &::-webkit-scrollbar-track {
    background: #e9f6e6 !important;
  }
  &::-webkit-scrollbar-thumb {
    background: #bccdd4 !important;
  }
`;

export const AddPublisherCardWrapper = styled(Flex)`
  width: 100%;
  flex-wrap: wrap;
  justify-content: start;
  max-height: 200px;
  overflow-x: hidden;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 5px;
  }
  &::-webkit-scrollbar-track {
    background: #e9f6e6 !important;
  }
  &::-webkit-scrollbar-thumb {
    background: #bccdd4 !important;
  }
`;

export const AlphabetScrollerWrapper = styled(Flex)`
width: 5%;
margin-top: 5px;
flex-direction: column;
background-color: #F9FAFB;
padding: 5px;
max-height: 200px;
align-items: center;
overflow-x: hidden;
overflow-y: auto;
&::-webkit-scrollbar {
  width: 5px;
}
&::-webkit-scrollbar-track {
  background: #e9f6e6 !important;
}
&::-webkit-scrollbar-thumb {
  background: #bccdd4 !important;
}
`;

export const PublisherCard = styled(Flex)`
display: flex;
width: 20%;
flex-direction: row;
height: 60px;
margin-right: 10px;
border: 1px solid #E4E7EC;
box-shadow: 0px 1px 2px 0px #1018281F;
border-radius: 4px;
`;

export const StyledImgDiv = styled(Flex)`
width: 25%;
padding: 7px;

`;

export const StyledTitleDiv = styled(Flex)`
width: 57%;
`;

export const StyledTitle = styled(Flex)`
color: #101828;
padding: 10px 10px 10px 10px;
text-align: left;
align-items: center;
font-size: 12px;
`;

export const StyledCross = styled(Flex)`
width: 18%;
align-items: center;
padding: 5px;
`;

export const NewButton = styled('button')`
  font-family: 'Poppins' !important;
  background: ${({ disabled }) => disabled ? '#F2F4F7' : '#FFF'};
  border: ${({ disabled, border }) => disabled ? '1px solid #F2F4F7' : border ? border : '1px solid #E4E7EC'};
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.12);
  border-radius: 4px;
  cursor: ${({ disabled }) => disabled ? 'text' : 'pointer'};
  width: fit-content;
  display: flex;
  justify-content: flex-start;
  margin: ${({ margin }) => margin ?? '10px 5px'};
  text-transform: none;
  padding: 8px 16px;
  text-align: center;
  color: ${({ color }) => color ?? color};
  transition: 0.5s;
  :hover {
    background: ${({ disabled }) => disabled ? '#F2F4F7' : '#f1f1f1'};
  }
`;