import React from 'react';
import ReactSkeleton from 'react-loading-skeleton';
import { ChartContainer, ChartHeader } from './styles';

function Skeleton() {
  return (
    <ChartContainer>
      <ChartHeader>
        <ReactSkeleton width={138} height={24} />
      </ChartHeader>
      <ReactSkeleton width="90%" height={300} />
    </ChartContainer>
  );
}

export default Skeleton;
