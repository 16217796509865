import React from 'react';
import ZeroStateBackground from 'src/assets/background/home/key-insights-zero-state.svg';
import AirplaneTakeoff from 'src/assets/icons/AirplaneTakeoff';
import {
  ZeroStateContainer,
  ZeroStateContent,
  ZeroStateWrapper,
} from '../styles';

function ZeroState() {
  return (
    <ZeroStateWrapper>
      <ZeroStateContainer backgroundUrl={ZeroStateBackground}>
        <ZeroStateContent>
          <div className="circled-icon">
            <AirplaneTakeoff />
          </div>
          <h5 className="title">
            Start your very first campaign to see your top performing campaigns
          </h5>
        </ZeroStateContent>
      </ZeroStateContainer>
    </ZeroStateWrapper>
  );
}

export default ZeroState;
