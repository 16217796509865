import React, { useState, useEffect } from 'react';
import { get } from 'lodash';
import Slider from 'react-slick';
import { ScheduledArticle } from '../../../components';
import { getContactsGroups } from '../../../services/configure';
import {
  getScheduledArticleData,
  dashboardSliderSettings,
} from '../../../utils';

import { ArticleSliderWrapper } from '../DashboardContainer.styles';

const ScheduledTab = ({
  selectedTopic,
  exploreArticles,
  deleteArticleRequest,
  shareError,
  themeNames,
  exploreArticleDeleting,
  isDeleteBlocked,
  exploreArticleRequest,
  setShowArticleLoader,
  scheduleType,
  isShareBlocked,
  isExportPDF,
  handleArticleSelection,
  exportPDFArticles,
  styles,
}) => {
  const [localExploreArticles, setLocalExploreArticles] = useState([]);
  const [totalArticles, setTotalArticles] = useState(0);
  const [imageCounter, setImageCounter] = useState([]);
  const [contactGroups, setContactGroups] = useState([]);

  useEffect(() => {
    let articles = getScheduledArticleData(exploreArticles);
    setLocalExploreArticles(articles);
    setTotalArticles(articles.length);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exploreArticles]);

  useEffect(() => {
    getContactsGroups()
      .then((res) => {
        if (res.result.success) {
          let data = res.result.data.filter(
            (c) =>
              ![
                'GMAIL',
                'HUBSPOT',
                'OUTLOOK',
                'WEALTHBOX',
                'SALESFORCE',
                'REDTAIL',
              ].includes(get(c, 'name')) && get(c, 'contacts.count', 0) > 0
          );
          setContactGroups(data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  return (
    <ArticleSliderWrapper style={{ clear: 'both', ...styles }}>
      <Slider {...dashboardSliderSettings}>
        {localExploreArticles
          .sort((a, b) => new Date(b.originalDate) - new Date(a.originalDate))
          .map((article, index) => (
            <ScheduledArticle
              key={'article_' + index}
              article={article}
              {...{
                ...article,
                selectedTopic,
                deleteArticleRequest,
                shareError,
                themeNames,
                exploreArticleDeleting,
                isDeleteBlocked,
                exploreArticleRequest,
                index,
                totalArticles,
                setShowArticleLoader,
                imageCounter,
                setImageCounter,
                scheduleType,
                isShareBlocked,
                contactGroups,
                isExportPDF,
                handleArticleSelection,
                exportPDFArticles,
              }}
            />
          ))}
      </Slider>
    </ArticleSliderWrapper>
  );
};

export default ScheduledTab;
