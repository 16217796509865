import { Flex } from '@rebass/grid';
import { get, isEmpty } from 'lodash';
import React from 'react';
import {
  DisplayScore,
  LoaderWrapper,
  StyledTabListLeft,
  Tabs,
} from '../../../components';
import {WebinarInfo } from './EngagementTab.styles';
import moment from 'moment';
import { getMonthDate, onImageError } from '../../../utils';
import { DashboardAdminSubTab } from '../../DashboardContainer/DashboardContainer.styles';
import { EngagementTabPanel } from '../AnalyticsContainer.styles';
import {
  OverviewBadge,
  OverviewShareTitle,
  ProspectFeaturedArticle,
  SponsoredWrapper,
  TopPublisherName,
  TopShareName,
  VerticalLine,
} from './EngagementTab.styles';
import NewsletterInsightsTab from './NewsletterInsightsTab';
import WebinarInsightsTab from './WebinarInsightsTab';
import NewsletterProspectTab from './NewsletterProspectTab';
import { DownloadButtonFlex } from '../AnalyticsContainer.styles';
import { useState, useEffect } from 'react';
import { saveFile, getTabNameFromRepType } from '../../../utils';
import { getAnalyticDataByRepType } from '../../../services/analytics';
import { DownloadButton } from '../../../components';
import { parse } from 'json2csv';

const WebinarInsights = ({
  selectedItem,
  analyticsData,
  isAnalyticsDataFetching,
  selectedRepType,
  analyticsObject,
  analyticsRequest,
  page,
  setPage,
  filterByContactList,
  selectedContacts,
  contactGroup,
  isAdmin,
  tabIndex,
  onTabSelect,
  setHasArticleCount,
}) => {
  const [isShareDataExist, setIsShareDataExist] = useState({});

//   useEffect(() => {
    // const { startDate, endDate } = analyticsObject || {};
    // getAnalyticDataByRepType({
    //   repType: selectedRepType,
    //   engagement_id: selectedItem.id,
    //   limit: analyticsData.pagination.total_count,
    //   type: '',
    //   startDate: startDate,
    //   endDate: endDate,
    //   contact_list: selectedContacts,
    // }).then((res) => {
    //     console.log('vvvvvvvvvvvvvvvvvvvvvvvvv', res);
    //   const resultArr = res.result.data.share;
    //   const resArr = resultArr.map((item) => {
    //     if (selectedItem.id[0] === item.id[0]) {
    //       setIsShareDataExist(item.article_count === '0');
    //       setHasArticleCount(item.article_count === '0');
    //       return;
    //     }
    //   });
    // });
//   }, []);

  const trimmedTitle =
    (selectedItem.title || '').length > 80
      ? `${selectedItem.title.substring(0, 80)}...`
      : selectedItem.title;
  const extractedDate = getMonthDate(selectedItem.date);
  const formatted_date = `${extractedDate[0]} ${extractedDate[1]}`;

  const styles = {
    adminSubTab: { fontSize: '14px', marginBottom: '20px' },
    contentDisabled: {
      cursor: 'default',
      fontSize: '14px',
      marginBottom: '20px',
      color: 'lightgray',
    },
  };

  const getEngagementMediaImage = (media) => {
    if (!isEmpty(media)) {
      return `${media.path}${get(media, 'name.resized')}.${media.ext}`;
    } else {
      return '';
    }
  };

  const [loading, setLoading] = useState(false);

  const onDownloadCSVClick = () => {
    setLoading(true);
    const tabName = getTabNameFromRepType(selectedRepType);
    const { startDate, endDate } = analyticsObject || {};
    const fileName = `${tabName}_${selectedItem.title}_${startDate}_${endDate}`;
    const prospectsFields = [
      'article_count',
      'email',
      'opens',
      'clicked',
      'first_name',
      'last_name',
      'engagement_score',
      'suggested_investments',
    ];
    const insightsFields = [
      'date',
      'name',
      'recipients',
      'opens',
      'clicked',
      'email_clicks',
      'title',
      'link',
      'email_which_clicked',
      'email_which_sent',
      'engagement_score',
      'suggested_investments',
    ];

    getAnalyticDataByRepType({
      repType: selectedRepType,
      engagement_id: selectedItem.id,
      limit: analyticsData.pagination.total_count,
      type: tabIndex === 0 ? 'prospects' : 'insights',
      startDate: startDate,
      endDate: endDate,
      contact_list: selectedContacts,
    })
      .then((res) => {
        const fields = tabIndex === 0 ? prospectsFields : insightsFields;
        const resultArr =
          tabIndex === 0 ? res.result.data.leads : res.result.data.share;
        const csvResultArr = resultArr.map((item) => {
          let obj = {};
          fields.forEach((key) => {
            if (
              key === 'suggested_investments' &&
              item[key] &&
              item[key] !== null &&
              item[key].length !== 0
            ) {
              obj[key] = item[key][0].ticker;
            } else {
              if (item[key] && item[key].length === 0) {
                obj['suggested_investments'] = '';
              } else {
                obj[key] = item[key];
              }
            }
          });
          return obj;
        });
        const csvData = parse(csvResultArr, fields);
        const fileNameNew =
          tabIndex === 0
            ? `${tabName}_${selectedItem.title}_propspects_${startDate}_${endDate}`
            : `${tabName}_${selectedItem.title}_insights_${startDate}_${endDate}`;
        saveFile(csvData, fileNameNew);
        console.log('csvData', csvData);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  return (
    <Flex width={1} flexDirection="column">
      <Flex pl="11px">
        <ProspectFeaturedArticle style={{ marginLeft: '7px' }} width="96.4%">
          <Flex width="66%" justifyContent="space-between">
            <Flex width="20%">
              <img
                src={getEngagementMediaImage(selectedItem.media_urls)}
                alt="article"
                width="80px"
                onError={onImageError}
                style={{ objectFit: 'cover' }}
              />
            </Flex>
            <Flex width="60%" flexDirection="column" padding="0px 10px">
              {/* <Flex alignItems="center">
                <TopPublisherName style={{ lineHeight: '1.4' }}>
                  {formatted_date}
                </TopPublisherName>
              </Flex> */}
              <OverviewShareTitle
                style={{
                  fontSize: '13px',
                  padding: '10px 0px',
                  marginTop: '-4px',
                }}
                title={selectedItem.title}
              >
                {trimmedTitle}
              </OverviewShareTitle>
                <WebinarInfo>{moment(selectedItem?.webinardetails?.start_date).format("D MMMM YYYY")} , {moment(selectedItem?.webinardetails?.start_time, "hh:mm:ss").format("LT")}</WebinarInfo>

            </Flex>
            <Flex width="20%" justifyContent="center" alignItems="center">
              <DisplayScore
                height="40px"
                width="40px"
                fontSize="14px"
                borderRadius="18px"
                border="4px solid"
                score={selectedItem.engagement_score}
              />
            </Flex>
          </Flex>
          {!isEmpty(selectedItem.suggested_investments) && <VerticalLine />}
          {!isEmpty(selectedItem.suggested_investments) &&
            selectedItem.suggested_investments
              .slice(0, 1)
              .map((item, index) => (
                <SponsoredWrapper style={{ height: '57.6px', width: '193px' }}>
                  <Flex
                    width="100%"
                    justifyContent="flex-start"
                    padding="2px 0px"
                    style={{ height: '13px' }}
                  >
                    <img
                      src={item.logo_path}
                      style={{ objectFit: 'contain', objectPosition: 'left' }}
                      alt="Company"
                    />
                  </Flex>
                  <Flex
                    width="100%"
                    fontSize="10px"
                    padding="2px 0px"
                    style={{
                      textAlign: 'left',
                      color: '#49484a',
                      fontWeight: '500',
                      whiteSpace: 'nowrap',
                      height: '15px',
                    }}
                  >
                    <span title={item.name.length > 32 ? item.name : ''}>
                      {item.name.length > 32
                        ? item.name.substring(0, 32) + '...'
                        : item.name}
                    </span>
                  </Flex>
                  <Flex
                    justifyContent="space-between"
                    style={{ paddingTop: '2px' }}
                  >
                    <Flex>
                      <span
                        style={{
                          color: '#49484a',
                          fontWeight: 'bold',
                          fontSize: '10px',
                        }}
                      >
                        {item.ticker}
                      </span>
                      &nbsp;
                      {item.matched_topics &&
                        item.matched_topics.length > 0 &&
                        item.matched_topics.slice(0, 1).map((item, index) => (
                          <span
                            style={{
                              color: '#49484a',
                              fontSize: '8px',
                              fontWeight: 500,
                              border: '1px solid #49484a',
                              borderRadius: '2px',
                              marginRight: '4px',
                              height: '11px',
                              display: 'flex',
                              alignItems: 'center',
                              padding: '0px 2px',
                              marginTop: '2px',
                            }}
                          >
                            {item}
                          </span>
                        ))}
                    </Flex>
                    {item.is_sponsored && (
                      <OverviewBadge style={{ float: 'right' }}>
                        <span>Sponsored</span>
                      </OverviewBadge>
                    )}
                  </Flex>
                </SponsoredWrapper>
              ))}
        </ProspectFeaturedArticle>
      </Flex>
      <Flex>
        <Tabs
          selectedIndex={tabIndex}
          onSelect={onTabSelect}
          style={{ marginTop: '18px' }}
        >
          <EngagementTabPanel height="247px">
            <LoaderWrapper isLoading={isAnalyticsDataFetching}>
              <NewsletterProspectTab
                analyticsData={analyticsData}
                selectedItem={selectedItem}
              />
            </LoaderWrapper>
          </EngagementTabPanel>
          <EngagementTabPanel height="247px">
            <LoaderWrapper isLoading={isAnalyticsDataFetching}>
              {/* <NewsletterInsightsTab analyticsData={analyticsData} /> */}
              <WebinarInsightsTab analyticsData={analyticsData} />

            </LoaderWrapper>
          </EngagementTabPanel>
          <DownloadButtonFlex justifyContent="flex-end">
            <LoaderWrapper isLoading={loading}>
              <DownloadButtonFlex
                justifyContent="flex-end"
                style={{ paddingTop: '6px', marginRight: '-20px' }}
              >
                <DownloadButton onClick={onDownloadCSVClick} fontSize="18px" />
              </DownloadButtonFlex>
            </LoaderWrapper>
          </DownloadButtonFlex>
        </Tabs>
      </Flex>
    </Flex>
  );
};

export default WebinarInsights;
