import styled from 'styled-components';
import { themesData } from '../../../utils';

export const ContactListTooltipWrapper = styled.div.attrs({
  className: 'contact-list-tooltip-wrapper'
})`
  position: relative;
  
  .contact-tooltip {
    visibility: hidden;
    width: 150px;
    background-color: ${themesData.charcoalGrey};
    color: #fff;
    text-align: left;
    border-radius: 6px;
    padding: 5px 9px;
    position: absolute;
    z-index: 1;
    top: -11px;
    left: 99%;
    font-size: 11px;
  }

  .contact-tooltip:after {
    content: "";
    position: absolute;
    top: 50%;
    right: 100%;
    margin-top: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent ${themesData.charcoalGrey} transparent transparent;
  }

  &:hover .contact-tooltip {
    visibility: visible;
  }

  .react-datepicker-popper {
    top: -116px !important;
  }
`;