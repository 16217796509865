import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import { DashboardArticle } from '../../../components';
import { get, union, isEmpty } from 'lodash';
import { getExploreArticleData, dashboardSliderSettings, eventHandlers } from '../../../utils';
import { ArticleSliderWrapper } from '../DashboardContainer.styles';

const InvestmentsTab = ({
  selectedTopic,
  exploreArticles,
  deleteArticleRequest,
  themeNames,
  exploreArticleDeleting,
  isShareBlocked,
  isDeleteBlocked,
  showMio,
  handleTickerChange,
  handleTopicChange,
  isTicker,
  isComplainceAdmin,
  selectedTickers,
  exploreSetState,
  tickerName,
  setShowArticleLoader,
  holdingsType,
  isFilteringStocksByTicker,
  popularThemes,
  userData,
  refresh,
  onNewsletterArticleClick,
  onRemoveFromNewletterClick,
  newsletterArticleFetching,
  bookmarksFetching,
  exploreNewsletterId,
  toggleLoaderBlocked,
  bookmarksId
}) => {
  const [articles, setArticles] = useState({});

  const [localExploreArticles, setLocalExploreArticles] = useState([]);
  const [totalArticles, setTotalArticles] = useState(0);
  const [imageCounter, setImageCounter] = useState([]);

  dashboardSliderSettings.afterChange = (index) => {
    let articles = getExploreArticleData(exploreArticles).slice(index, index + 4);
    let sponsoredArticle = articles.filter(a => a.sponseredContent);
    const userEmail = userData && userData.details && userData.details.user && userData.details.user.email;
    if (!isEmpty(sponsoredArticle)) {
      sponsoredArticle.forEach((article) => {
        eventHandlers(
          'SPONSORED_ARTICLE_DISPLAYED',
          popularThemes,
          article.id,
          'MY_HOLDINGS',
          article.channelId,
          article.title,
          article.themeId,
          themeNames[article.themeId],
          article.topic,
          userEmail
        );
      });
    }
    if (!isEmpty(articles)) {
      articles.forEach((article) => {
        eventHandlers(
          'ARTICLE_DISPLAYED',
          popularThemes,
          article.id,
          'MY_HOLDINGS',
          article.channelId,
          article.title,
          article.themeId,
          themeNames[article.themeId],
          article.topic,
          userEmail
        );
      });
    }
  }

   useEffect(() => {
     let localArticles = getExploreArticleData(articles);
     setLocalExploreArticles(localArticles);
     setTotalArticles(localArticles.length);
     /*if(localArticles.length == 0) {
       setShowArticleLoader(false);
     }*/
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [articles]);
   
  useEffect(() => {
    if(isEmpty(get(exploreArticles, 'feed', []))) {
      setShowArticleLoader(false);
    }
    exploreSetState({ isInvEmpty: false });
    const feeds = get(exploreArticles, 'feed') || [];
    let result = [];
    if(holdingsType && holdingsType === 'stocks' && !isFilteringStocksByTicker){
      setArticles({ ...exploreArticles, feed: feeds });
    }else{
      feeds.forEach((data, idx) =>
        data.tickers.split(', ').forEach((ticker) => {
          if(tickerName){
            tickerName.trim() === ticker.toUpperCase() && result.push(feeds[idx]);
          }else if(selectedTickers.includes(ticker.toUpperCase())) {
            result.push(feeds[idx]);
          }
        })
      );
      setArticles({ ...exploreArticles, feed: union(result) });
      if (union(result).length === 0) {
        exploreSetState({ isInvEmpty: true });
        setShowArticleLoader(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exploreArticles, selectedTickers, exploreSetState, tickerName]);

  useEffect(() => {
    if(!isEmpty(get(exploreArticles, 'feed', []))) {
      let articles = getExploreArticleData(exploreArticles).slice(0, 4);
      let sponsoredArticle = articles.filter(a => a.sponseredContent);
      const userEmail = userData && userData.details && userData.details.user && userData.details.user.email;
      if (!isEmpty(sponsoredArticle)) {
        sponsoredArticle.forEach((article) => {
          eventHandlers(
            'SPONSORED_ARTICLE_DISPLAYED',
            popularThemes,
            article.id,
            'MY_HOLDINGS',
            article.channelId,
            article.title,
            article.themeId,
            themeNames[article.themeId],
            article.topic,
            userEmail
          );
        });
      }
      if(!isEmpty(articles)) {
        articles.forEach((article) => {
         eventHandlers(
           'ARTICLE_DISPLAYED',
           popularThemes,
           article.id,
           'MY_HOLDINGS',
           article.channelId,
           article.title,
           article.themeId,
           themeNames[article.themeId],
           article.topic,
           userEmail
         );
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  return (
    <ArticleSliderWrapper>
      <Slider {...dashboardSliderSettings}>
        {localExploreArticles.map((article, index) => (
          <DashboardArticle
            key={'dashboard_article_'+index}
            article={article}
            bookmarksFetching={bookmarksFetching}
            bookmarksId={bookmarksId}
            {...{
              ...article,
              selectedTopic,
              deleteArticleRequest,
              themeNames,
              exploreArticleDeleting,
              isShareBlocked,
              isDeleteBlocked,
              isInvestmentTab: true,
              showMio,
              handleTickerChange,
              handleTopicChange,
              isTicker,
              index,
              isComplainceAdmin,
              setShowArticleLoader,
              totalArticles,
              imageCounter,
              setImageCounter,
              holdingsType,
              section: 'MY_HOLDINGS',
              onNewsletterArticleClick,
              onRemoveFromNewletterClick,
              newsletterArticleFetching,
              exploreNewsletterId
            }}
          />
        ))}
      </Slider>
    </ArticleSliderWrapper>
  );
};

export default InvestmentsTab;
