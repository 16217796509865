import React from 'react';
import ReactSkeleton from 'react-loading-skeleton';
import {
  SubCampaignListContainer,
} from './styles';

function Skeleton() {
  return (
    <div>
      <div>
        <ReactSkeleton height={10} />
        <ReactSkeleton height={16} />
      </div>
      <SubCampaignListContainer>
        <ReactSkeleton height={200} />
      </SubCampaignListContainer>
    </div>
  );
}

export default Skeleton;
