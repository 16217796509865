import { get } from 'lodash';
import { takeLatest, all, call, put } from 'redux-saga/effects';
import { actions as themeActions } from '../modules/theme.module';
import { getThemeList } from '../../services/configure';

function* themeRequest() {
  try {
    const response = yield call(getThemeList);
    const data = get(response, 'result.data', {});
    yield put(themeActions.api.theme.response(data));
  } catch (error) {
    yield put(themeActions.api.theme.error(error));
  }
}

function* themeSaga() {
  yield all([takeLatest(themeActions.api.theme.request, themeRequest)]);
}

export default themeSaga;
