import React, { useState } from 'react';
import DatePicker from 'react-datepicker';

import { MioDatePickerWrapper } from './MioDatePicker.styles';

const SimpleDatePicker = ({
  customInput,
  minDate,
  maxDate,
  isOpen,
  onDateClose,
  popperPlacement,
  shouldCloseOnSelect,
  openOnDemand,
  handleDateChange,
  placeholder,
  type
}) => {
  const [selectedDate, setStartDate] = useState();

  const onDateChange = (date) => {
    setStartDate(date);
    handleDateChange(date);
  };

  let onDemandProps = {};

  if (openOnDemand) {
    onDemandProps = {
      open: isOpen,
      customInput: customInput,
      onClickOutside: onDateClose,
      onCalendarClose: onDateClose,
      shouldCloseOnSelect,
    };
  }

  return (
    <DatePicker
      {...onDemandProps}
      selected={selectedDate}
      onChange={onDateChange}
      popperPlacement={popperPlacement || 'down'}
      minDate={minDate}
      maxDate={maxDate}
      placeholderText={placeholder}
      calendarContainer={({ children }) => (
        <MioDatePickerWrapper type={type}>
          <div className="date-picker-container">{children}</div>
        </MioDatePickerWrapper>
      )}
      popperModifiers={{
        computeStyle: { gpuAcceleration: false },
        offset: {
          enabled: true,
          offset: '20px, -14px',
        },
      }}
    />
  );
};

export default SimpleDatePicker;
