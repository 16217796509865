import { Flex } from '@rebass/grid';
import React from 'react';
import { Button, Modal, ModalBody, ModalCloseButton, ModalHeader } from '../../../components';

const PublishSuccessModal = ({ modalOpen, modalClose, redirect }) => {
    return (
        <Modal isOpen={modalOpen} onRequestClose={modalClose} bgColor='#fff'>
            <ModalHeader>
                <ModalCloseButton onClick={modalClose} />
            </ModalHeader>
            <ModalBody>
                <span style={{fontSize: '14px', color: '#000', fontWeight: '600'}}>Campaign Published Successfully!</span>
                <Flex justifyContent='center' alignItems='center'>
                    <Button onClick={modalClose} margin='20px 10px 0px 0px' >Close Modal</Button>
                    <Button onClick={redirect}>Go to Dashboard</Button>
                </Flex>
            </ModalBody>
        </Modal>
    );
}

export default PublishSuccessModal;