import React from 'react'
import { ModalBody, ModalV2 } from '../../components/Modal';
import { Flex } from '@rebass/grid';
import { ColorPallete } from '../../utils';
import { Button } from '../../components/Form';
import { LoaderWrapper } from '../../components/Loader';
import { PageNameInput } from '../../containers/LandingPageEditorContainer/LandingPageEditorContainer.styles';
import { isEmpty } from 'lodash';
import { ComplianceReview } from '../ComplianceReview';

const ConfirmationModalV2 = ({
    onClose,
    isModalOpen,
    title,
    subTitle,
    onSubmit,
    btnCancelName,
    btnOkName,
    loading,
    message,
    inputField,
    placeHolder,
    name,
    setName,
    modalWidth,
    disabled=false,
    progressMessage,
    showCompliance,
    handleComplianceToggle,
    toggleCompliance,
    list
}) => {
    return (
        <ModalV2
            onRequestClose={onClose}
            shouldCloseOnOverlayClick
            isOpen={isModalOpen}
            bgColor="white"
            width={modalWidth || "45%"}
        >
            <ModalBody>
                <Flex width="100%" style={{ textAlign: 'left' }} justifyContent="flex-start" fontSize="20px" color={ColorPallete.black}>
                    {title}
                </Flex>
                {subTitle && (
                    <Flex width="100%" style={{ textAlign: 'left' }} justifyContent="flex-start" fontSize="14px" color={ColorPallete.neutral500} mt="5px">
                        {subTitle}
                    </Flex>
                )}

                {inputField && (
                    <Flex mt="10px" width="100%" justifyContent="center">
                        <PageNameInput style={{
                            border: `1px solid ${ColorPallete.neutral200}`,
                            boxShadow: '1px 2px 2px 0px #1018281F inset',
                            borderRadius: '4px',
                            width: '100%'
                        }} value={name} onChange={(e) => setName(e.target.value)} width="70%" placeholder={placeHolder}/>
                    </Flex>
                )}

                {list}

                {showCompliance && (
                    <ComplianceReview 
                        handleComplianceToggle={handleComplianceToggle}
                        toggleCompliance={toggleCompliance}
                    />
                )}

                {loading && progressMessage && (
                    <Flex width="100%" justifyContent="center" fontSize="14px" color={ColorPallete.neutral500} mt="5px">
                        {progressMessage}
                    </Flex>
                )}

                <Flex width="100%" justifyContent="center">
                    <LoaderWrapper isLoading={loading}>
                        <Button
                            margin="15px 20px 10px 0px"
                            bgColor={ColorPallete.white}
                            color={ColorPallete.primaryBlue}
                            borderRadius="4px"
                            borderColor={ColorPallete.pink}
                            onClick={onClose}
                            padding="7px 30px"
                            width="50%"
                        >
                            {btnCancelName}
                        </Button>
                        <Button
                            margin="15px 0px 10px"
                            bgColor={ColorPallete.primaryBlue}
                            color={ColorPallete.white}
                            borderRadius="4px"
                            borderColor={ColorPallete.primaryBlue}
                            onClick={onSubmit}
                            padding="7px 30px"
                            disabled={disabled || (inputField && isEmpty(name))}
                            width="50%"
                        >
                            {btnOkName}
                        </Button>
                    </LoaderWrapper>

                </Flex>

                {message && (
                    <Flex width="100%" justifyContent="center" fontSize="14px" color={ColorPallete.neutral500} mt="5px">
                        {message}
                    </Flex>
                )}

            </ModalBody>
        </ModalV2>
    )
}

export default ConfirmationModalV2;