import React from 'react';
import { Modal, ModalBody } from '../Modal';


const styles = {
    ModalBody: {
        color: '#6351ed'
    }
}
const ApproveModal =({open,type})=>{
    return(
    <Modal
      shouldCloseOnOverlayClick
      isOpen={open}
      size="medium"
       width="65%">
           {type !== '' 
           ?
            <ModalBody col style={styles.ModalBody}><strong>Feedback Shared</strong></ModalBody>
           :
            <ModalBody col style={styles.ModalBody}><strong>Post Approved Successfully</strong></ModalBody>}
        </Modal>
    )
}
export default ApproveModal;