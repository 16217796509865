import { Flex } from '@rebass/grid';
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Papa from 'papaparse';
import StepOneComponent from './StepOneComponent';
import { get, isEmpty, uniq } from 'lodash';
import { Button, ButtonNoBorderBackground } from '../../components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { UploadCSVFooterSection } from './styles';
import StepTwoComponent from './StepTwoComponent';
import ContactListModal from './ContactListModal';
import { listAllTags, listAllTopics, uploadCsvContactList } from '../../services/segment';
import ConfirmationPage from './ConfirmationPage';
import { convertToCsv, createCsvContactGroup } from '../../services/configure';
import { objectToFormData } from '../../services/helpers';
import { parse } from 'json2csv';

const UploadCsvContainer = ({
  roleType
}) => {
  const [file, setFile] = useState([]);
  const [csvHeaders, setCsvHeaders] = useState([]);
  const [step, setStep] = useState(0);
  const [csvData, setCsvData] = useState([]);
  const [emailColumnName, setEmailColumnName] = useState('');
  const [firstNameColumnName, setFirstNameColumnName] = useState('');
  const [lastNameColumnName, setLastNameColumnName] = useState('');
  const [suffixColumnName, setSuffixColumnName] = useState('');
  const [tagsColumnName, setTagsColumnName] = useState('');
  const [loading, setLoading] = useState(false);
  const [emailListValue, setEmailListValue] = useState('');
  const [csvLoading, setCsvLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  // const [listNamePresent, setListNamePresent] = useState(false);
  const [previewData, setPreviewData] = useState([
    {first_name: '', last_name: '', email: '', suffix: '', tags: ''},
    {first_name: '', last_name: '', email: '', suffix: '', tags: ''},
    {first_name: '', last_name: '', email: '', suffix: '', tags: ''},
    {first_name: '', last_name: '', email: '', suffix: '', tags: ''},
    {first_name: '', last_name: '', email: '', suffix: '', tags: ''},
  ])

  const [openContactListModal, setOpenContactListModal] = useState(false);
  const [tagList, setTagList] = useState(false);
  const [contactType, setContactType] = useState('');

  const history = useHistory();

  const hiddenFileInput = React.useRef(null);

  const handleChange = (event) => {
    if (!isEmpty(event.target.files)) {
      setFile(event.target.files);
      let file = event.target.files[0];
      if(file?.type !== 'text/csv') {
        let payload = {
          media1: file
        }
        setCsvLoading(true);
        convertToCsv(objectToFormData(payload)).then(res => {
          console.log(res);
          processCsv(res);
          setCsvLoading(false);
        }).catch(err => {
          setCsvLoading(false);
        });
      } else {
        processCsv(file);
      } 
    }
  };

  const processCsv = (file) => {
    Papa.parse(file, {
      header: true,
      skipEmptyLines: true,
      complete: function (results) {
        console.log('results', results);
        console.log("new results-", Object.keys(results?.data[0]));
        // var newArr = results?.data[0];
        // console.log("latest array-", results?.data[0])
        // console.log("new Array-", newArr.email_list_name);
        // if (includes(Object.keys(results?.data[0]), 'email')) {
        //   if(includes(Object.keys(results?.data[0]), 'email_list_name')) {
        //     setEmailListValue(newArr.email_list_name);
        //     setListNamePresent(true);
        //   } else {
        //     setEmailListValue('');
        //     setListNamePresent(false);
        //   }
        // }
        setCsvData(get(results, 'data', []));
        setCsvHeaders(Object.keys(results?.data[0]));
        setPreviewData([
          {first_name: '', last_name: '', email: '', suffix: '', tags: ''},
          {first_name: '', last_name: '', email: '', suffix: '', tags: ''},
          {first_name: '', last_name: '', email: '', suffix: '', tags: ''},
          {first_name: '', last_name: '', email: '', suffix: '', tags: ''},
          {first_name: '', last_name: '', email: '', suffix: '', tags: ''},
        ]);
        setFirstNameColumnName('');
        setEmailColumnName('');
        setSuffixColumnName('');
        setLastNameColumnName('');
        setErrorMessage('');
        setEmailListValue('');
        setTagsColumnName('')
      }
    });
  }

  const changeColumnName = (setValue, value, type) => {
    if(value) {
      setValue(value);
      if(type === 'email') {
        let newEmailColumnData = csvData.map((item, index) => {
          return {
            'email': item[value.value]
          }
        }).slice(0, 5);
        let newPreviewDataOne = previewData.map((item, index) => {
          return {
            ...item,
            'email': get(newEmailColumnData[index], 'email', ''),
          }
        });
        setPreviewData(newPreviewDataOne)
      }

      if(type === 'firstName') {
        let newFirstNameColumnData = csvData.map((item, index) => {
          return {
            'first_name': item[value.value]
          }
        }).slice(0, 5);
        let newPreviewDataTwo = previewData.map((item, index) => {
          return {
            ...item,
            'first_name': get(newFirstNameColumnData[index], 'first_name', ''),
          }
        });
        setPreviewData(newPreviewDataTwo)
      }

      if(type === 'lastName') {
        let newLastNameColumnData = csvData.map((item, index) => {
          return {
            'last_name': item[value.value]
          }
        }).slice(0, 5);
        let newPreviewDataThree = previewData.map((item, index) => {
          return {
            ...item,
            'last_name': get(newLastNameColumnData[index], 'last_name', ''),
          }
        });
        setPreviewData(newPreviewDataThree)
      }

      if(type === 'suffix') {
        let newSuffixColumnData = csvData.map((item, index) => {
          return {
            'suffix': item[value.value]
          }
        }).slice(0, 5);
        let newPreviewDataFour = previewData.map((item, index) => {
          return {
            ...item,
            'suffix': get(newSuffixColumnData[index], 'suffix', ''),
          }
        });
        setPreviewData(newPreviewDataFour)
      }

      if(type === 'tags') {
        let newTagColumnData = []
        value.forEach((element, index) => {
          console.log(index);
          if (index === 0) {
            newTagColumnData = csvData.map((item, csvIndex) => {
              return {
                'tags': item[element.value]
              }
            }).slice(0, 5);
          } else if(!isEmpty(newTagColumnData)) {
            newTagColumnData = csvData.map((item, csvIndex) => {
              return {
                'tags': item[element.value] && !isEmpty(get(newTagColumnData[csvIndex], 'tags', ''))  ? get(newTagColumnData[csvIndex], 'tags', '') + ',' + item[element.value] : item[element.value] && isEmpty(get(newTagColumnData[csvIndex], 'tags', '')) ? item[element.value] : get(newTagColumnData[csvIndex], 'tags', '')
              }
            }).slice(0, 5);
          }
         
        });

        let newPreviewDataFive = previewData.map((item, index) => {
          return {
            ...item,
            'tags': get(newTagColumnData[index], 'tags', ''),
          }
        });

        setPreviewData(newPreviewDataFive);
      }

    } else {
      setValue(null)
      if(type === 'tags') {
        let clearTags = previewData.map((item, index) => {
          return {
            ...item,
            'tags' : ''
          }
        });
        setPreviewData(clearTags);
      }
      if(type === 'email') {
        let clearEmail = previewData.map((item, index) => {
          return {
            ...item,
            'email' : ''
          }
        });
        setPreviewData(clearEmail);
      }
      if(type === 'firstName') {
        let clearFirstName = previewData.map((item, index) => {
          return {
            ...item,
            'first_name' : ''
          }
        });
        setPreviewData(clearFirstName);
      }
      if(type === 'lastName') {
        let clearLastName = previewData.map((item, index) => {
          return {
            ...item,
            'last_name' : ''
          }
        });
        setPreviewData(clearLastName);
      }
      if(type === 'suffix') {
        let clearSuffix = previewData.map((item, index) => {
          return {
            ...item,
            'suffix' : ''
          }
        });
        setPreviewData(clearSuffix);
      }
    }
   
  }

  const handleNext = () => {
    if (step === 0) {
      setStep(step + 1);
    } else if (step === 1) {
      setOpenContactListModal(true);
    }
  }

  const handleClose = () => {
    setFile([]);
    setCsvHeaders([]);
  };

  const handleModalClose = () => {
    setOpenContactListModal(false);
    setErrorMessage('');
    setEmailListValue('');
  };

  const handleCreateContacts = (tags) => {
    console.log('');
    setErrorMessage('');
    setLoading(true);
    console.log(emailColumnName);
    let tagsColumnData = [];
    if(!isEmpty(tagsColumnName)) {
      tagsColumnName.forEach((element, index) => {
        console.log(index);
        if (index === 0) {
          tagsColumnData = csvData.map((item, csvIndex) => {
            return {
              'tags': item[element.value]
            }
          });
        } else if(!isEmpty(tagsColumnData)) {
          tagsColumnData = csvData.map((item, csvIndex) => {
            return {
              'tags': item[element.value] && !isEmpty(get(tagsColumnData[csvIndex], 'tags', ''))  ? get(tagsColumnData[csvIndex], 'tags', '') + '|' + item[element.value] : item[element.value] && isEmpty(get(tagsColumnData[csvIndex], 'tags', '')) ? item[element.value] : get(tagsColumnData[csvIndex], 'tags', '')
            }
          });
        }
       
      });

    }
    let reformatedCsvData = csvData.map(item => {
      console.log(item[emailColumnName]);
      return {
        'first_name': get(item, `${[firstNameColumnName?.value]}`, ''), 
        'last_name': get(item, `${[lastNameColumnName?.value]}`, ''),
        'email': get(item, `${[emailColumnName?.value]}`, ''), 
        'suffix': get(item, `${[suffixColumnName?.value]}`, ''),
      }
    });

    console.log('tagcolumndata', tagsColumnData)

    if(!isEmpty(tagsColumnData)) {
      reformatedCsvData = reformatedCsvData.map((item, index) => {
        console.log(get(tagsColumnData[index], 'tags', ''));
        return {
          ...item,
          'tags': get(tagsColumnData[index], 'tags', '')
        }
      })
    }
    console.log('reformatedCsvData',reformatedCsvData);
    let finalCsv;
    if(!isEmpty(tagsColumnData)) {
      console.log('Tag Csv');
      finalCsv = parse(reformatedCsvData, {
        fields: ['first_name', 'last_name', 'email', 'suffix', 'tags'],
      });
    } else {
      console.log('normal csv');
      finalCsv = parse(reformatedCsvData, {
        fields: ['first_name', 'last_name', 'email', 'suffix'],
      });
    }

    const csvBlob = new Blob([finalCsv], { type: 'text/csv' });
    const csvFile = new File([csvBlob], 'email_list.csv', {
      type: 'text/csv',
      lastModified: new Date(),
    });
    createCsvContactGroup(emailListValue, contactType).then(res => {
      const contactGroupId = get(res, 'result.data.id', '');
      if(contactGroupId) {
        let payloadTags = [];
        if(!isEmpty(contactType)) {
          payloadTags = [...tags, contactType];
        } else {
          payloadTags = [...tags];
        }    
        console.log('payloadTags', payloadTags)
        let payload = {
          tags: !isEmpty(payloadTags) ? JSON.stringify(payloadTags) : JSON.stringify([]),
          media1: csvFile
        }
        uploadCsvContactList(contactGroupId, objectToFormData(payload)).then(res => {
          if(get(res, 'result.success', false)) {
            setOpenContactListModal(false);
            setStep(step + 1);
            setLoading(false);
          }
        }).catch(err => {
          console.log(err);
          setLoading(false);
          if (err?.code === 3305) {
            setErrorMessage(
              'Invalid CSV, Please select a valid email address column.'
            );
          } else {
            setErrorMessage(err?.message);
          }
        })
      }
    }).catch(err => {
      setLoading(false);
      setErrorMessage(err?.message);
    })
  }

  const handleValidation = () => {
    if(step === 0) {
      console.log(csvHeaders);
      if(file && !isEmpty(csvHeaders)) {
        return false;
      } else {
        return true;
      }
    } else if(step === 1) {
      if(!isEmpty(emailColumnName)) {
        return false;
      } else {
        return true;
      }
    }
  }

  useEffect(() => {
    const windowBodyClassList = document.body.classList;
    console.log('body-', windowBodyClassList);
    if (!windowBodyClassList.contains('campaign')) {
      windowBodyClassList.add('campaign');
    }

    const getTags = async () => {
      const tagOne = await listAllTopics();
      const tagTwo = await listAllTags();
      if(!isEmpty(tagOne?.result?.data) && !isEmpty(tagTwo?.result?.data)) {
        setTagList(uniq([...tagOne?.result?.data, ...tagTwo?.result?.data]));
      }
    }

    getTags();

    return () => {
      windowBodyClassList.remove('campaign');
    };
  }, []);

  const clickHandler = () => {
    if(step === 0) {
      history.push('/configure');
    } else {
      setStep(step - 1)
    }
  };

  
  return (
    <>
      <Flex
        display="flex"
        justifyContent="center"
        width="100%"
        flexDirection="column"
      >
        {step === 0 && (
          <StepOneComponent
            hiddenFileInput={hiddenFileInput}
            handleChange={handleChange}
            handleClose={handleClose}
            file={file}
            csvLoading={csvLoading}
          />
        )}

        {step === 1 && (
          <StepTwoComponent
            file={file}
            hiddenFileInput={hiddenFileInput}
            handleChange={handleChange}
            csvHeaders={csvHeaders}
            csvData={csvData}
            changeColumnName={changeColumnName}
            emailColumnName={emailColumnName}
            firstNameColumnName={firstNameColumnName}
            lastNameColumnName={lastNameColumnName}
            suffixColumnName={suffixColumnName}
            setEmailColumnName={setEmailColumnName}
            setFirstNameColumnName={setFirstNameColumnName}
            setLastNameColumnName={setLastNameColumnName}
            setSuffixColumnName={setSuffixColumnName}
            previewData={previewData}
            csvLoading={csvLoading}
            tagsColumnName={tagsColumnName}
            setTagsColumnName={setTagsColumnName}
          />
        )}

        {step === 2 && <ConfirmationPage 
          csvData={csvData}
          roleType={roleType}
        />}
        {step !== 2 && (
          <UploadCSVFooterSection>
            <Flex display="flex" width="100%" justifyContent="space-between">
              <ButtonNoBorderBackground
                style={{ width: '10%' }}
                color="#000000"
                onClick={() => {
                  clickHandler();
                }}
              >
                <FontAwesomeIcon icon={faChevronLeft} />
                &nbsp; Back &nbsp; &nbsp;
              </ButtonNoBorderBackground>
              <Button
                styles={{ borderRadius: '4px', margin: 'auto 50px' }}
                bgColor="#6351ed"
                color="#fff"
                margin="0px 10px"
                onClick={handleNext}
                disabled={handleValidation()}
              >
                Next
              </Button>
            </Flex>
          </UploadCSVFooterSection>
        )}  

        {openContactListModal && (
          <ContactListModal
            isOpen={openContactListModal}
            handleClose={handleModalClose}
            value={emailListValue}
            setValue={setEmailListValue}
            tagList={tagList}
            handleClick={handleCreateContacts}
            contactType={contactType}
            setContactType={setContactType}
            loading={loading}
            errorMessage={errorMessage}
            //listNamePresent={listNamePresent}
          />
        )}
      </Flex>
    </>
  );
};

export default UploadCsvContainer;
