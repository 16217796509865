import { faCopy } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { Button, LoaderWrapper } from '../../../../../components';
import { themesData } from '../../../../../utils';
import { copyToClipboard } from '../../../../../utils/common.helper'
import './StepTwoComponent.css';
import PurpleButton from '../../../../../componentsV2/PurpleButton';

const StepTwoComponent = ({ list, verifyHandler, loader }) => {
    const [boxChecked, toggleChecked] = useState(false);

    return (
        <div style={{ margin: 'auto', width: '100%', textAlign: 'center' }}>
            {loader ? (
                <div style={{ width: '82%', position: 'absolute', top: '13rem', left: '47px', zIndex: '2' }}>
                    <div style={{ textAlign: 'center' }}>
                        <LoaderWrapper isLoading={loader} />
                        <h3 style={{ marginTop: '5px', marginBottom: '10px' }}>Connection is in progress</h3>
                        <p style={{ marginTop: '2px', fontSize: '10px', color: 'black' }}>This step might take some time. Please check again later</p>
                    </div>
                </div>
            ) : null}
            <div style={{ maxHeight: '10rem', overflowY: 'auto' }}>
                <table className='records-table' cellSpacing='0' cellPadding='0'>
                    <thead>
                        <tr>
                            <th style={{ width: '20%', textAlign: 'center', fontSize: '12px' }}>Type</th>
                            <th style={{ width: '30%', textAlign: 'center', fontSize: '12px', paddingRight: '10px' }}>Host</th>
                            <th style={{ width: '50%', textAlign: 'center', fontSize: '12px', paddingRight: '10px' }}>Points to</th>
                        </tr>
                    </thead>
                    <tbody style={{ backgroundColor: 'white', filter: loader ? `blur(3px)` : '' }}>
                        {list.length !== 0 && list.map((item, index) => (
                            <tr key={index} style={{ lineHeight: '2rem' }}>
                                <td key={'type_' + index} style={{ textAlign: 'center', width: '20%', fontSize: '12px', fontWeight: '600' }}>{item.type}</td>
                                <td key={'host_' + index} style={{ textAlign: 'left', width: '30%' }}>
                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%', paddingRight: '15px', fontSize: '12px', fontWeight: '600' }}>
                                        {item.host}
                                        <input type='text' id={'host_' + index} hidden={true} value={item.host} />
                                        <FontAwesomeIcon color={themesData.purpleishBlue} icon={faCopy} style={{ marginLeft: '5px', cursor: 'pointer' }} onClick={() => copyToClipboard(item.host)} />
                                        {/* <img alt="" onClick={() => copyToClipboard(item.host)}  src='/assets/images/copy-icon12.png' /> */}
                                    </div>
                                </td>
                                <td key={'data_' + index} style={{ textAlign: 'left', width: '50%' }}>
                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%', paddingRight: '15px', fontSize: '12px', fontWeight: '600' }}>
                                        {item.data}
                                        <input type='text' id={'data_' + index} hidden={true} value={item.data} />
                                        <FontAwesomeIcon color={themesData.purpleishBlue} icon={faCopy} style={{ marginLeft: '5px', cursor: 'pointer' }} onClick={() => copyToClipboard(item.data)} />
                                        {/* <img alt="" onClick={() => copyToClipboard(item.data)} style={{ marginLeft: '5px', cursor: 'pointer' }} src='/assets/images/copy-icon12.png' /> */}
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <div style={{ marginTop: '2px', marginRight: '5px' }}>
                    <input className='hidden-input-step2' name='added_check' id='added_check' type='checkbox' defaultChecked={false} onChange={() => toggleChecked(!boxChecked)}></input>
                    <label style={{ cursor: 'pointer' }} htmlFor='added_check' className='checkmark-step2'></label>
                </div>
                <p style={{ fontSize: '10px' }}>I've added these records</p>
            </div>
            <PurpleButton margin='0 auto' disabled={!boxChecked} onClick={verifyHandler}>Verify</PurpleButton>
        </div>
    );
}

export default StepTwoComponent;