import styled from 'styled-components';

export const TileWrapper = styled.div`
  position: relative;
  margin: 5px 0 0;
`;
export const TileContainer = styled.ul`
  background-color: #ececec !important;
  padding: 4px;
  border-radius: 4px;
  vertical-align: middle;
  display: block;
  margin: 0px;
  list-style: none;
  min-height: 43px;
  max-height: 43px;
`;
export const FirstItem = styled.li`
  width: 28%;
  padding-left: 5px;
  padding-right: 0px;
  display: table-cell;
  vertical-align: middle;
`;
export const Image = styled.img`
  width: 35px;
  height: 35px;
  border-radius: 5px;
  float: left;
  object-fit: cover;
  background: ${({ bgColor }) => bgColor || '#6351ed'};
  margin-right: 9px;
  vertical-align: middle;
  font-size: 0px;
`;
export const SecondItem = styled.li`
  width: 73%;
  padding-top: 5px;
  padding-right: 5px;
  text-align: left;
  display: table-cell;
  vertical-align: middle;
  margin-right: 0px;
`;
export const Title = styled.h6`
  line-height: 1.1;
  color: #6351ed;
  margin: 0px 0 0;
  font-size: 11px;
  word-break: break-word;
  text-align: left;
  font-weight: 500;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;
