import { styled } from '@mui/material';

const getCommonButtonStyle = (
  width = undefined,
  height = undefined,
  fullWidth = undefined
) => ({
  width: fullWidth ? '100%' : width ? width : 'fit-content',
  height: height ?? '40px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '0.75rem 1rem',
  font: '500 0.75rem/1rem Poppins, sans-serif',
  borderRadius: '0.25rem',
  boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.12)',
  cursor: 'pointer',
});

export const Wrapper = styled('button')(
  ({ width, height, variant, fullWidth, style }) => {
    switch (variant) {
      case 'outline':
        return {
          ...getCommonButtonStyle(width, height, fullWidth),
          color: '#6B5ECB',
          backgroundColor: '#FFF',
          border: '1px solid #A0A3F2',
          boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.12)',

          '&:hover': {
            backgroundColor: '#F9FAFB',
          },

          '&:focus': {
            backgroundColor: '#F9FAFB',
          },

          '&:disabled': {
            color: '#98A2B3',
            backgroundColor: '#F2F4F7',
            border: '1px solid #E4E7EC',
            cursor: 'not-allowed',
          },
        };

      case 'danger':
        return {
          ...getCommonButtonStyle(width, height, fullWidth),
          color: '#FCFDFD',
          backgroundColor: '#E00000',
          border: '1px solid rgba(224,0,0,0.2)',

          '&:hover': {
            backgroundColor: '#A20000',
          },

          '&:focus': {
            backgroundColor: '#A20000',
            border: '3px solid rgba(224,0,0,0.2)',
          },

          '&:disabled': {
            color: '#98A2B3',
            backgroundColor: '#F2F4F7',
            border: '1px solid #E4E7EC',
            cursor: 'not-allowed',
          },
        };

      case 'text':
        return {
          ...getCommonButtonStyle(width, height, fullWidth),
          color: '#101828',
          background: 'transparent',

          '&:hover': {
            backgroundColor: '#F9FAFB',
          },

          '&:focus': {
            border: '3px solid #CFD1D4',
          },

          '&:disabled': {
            color: '#98A2B3',
            cursor: 'not-allowed',
          },
        };

      case 'plain':
        return {
          ...getCommonButtonStyle(width, height, fullWidth),
          color: '#101828',
          background: 'transparent',
          border: 'none',
          '&:hover': {
            color: '#000000',
          },

          '&:focus': {
            color: '#000000',
          },

          '&:disabled': {
            color: '#000000',
            cursor: 'not-allowed',
          },
          ...style,
        };

      default:
        return {
          ...getCommonButtonStyle(width, height, fullWidth),
          color: '#FCFDFD',
          background: 'linear-gradient(180deg, #7B73E4 0%, #635DB8 100%)',
          border: 'none',

          '&:hover': {
            opacity: 0.9,
          },

          '&:disabled': {
            color: '#98A2B3',
            background: '#F2F4F7',
            border: '1px solid #E4E7EC',
            cursor: 'not-allowed',
          },
        };
    }
  }
);
