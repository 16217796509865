export const Topics = [
  {
    id: '1',
    image_url: 'https://google.com',
    topic: 'Blockchain',
  },
  {
    id: '2',
    image_url: 'https://google.com',
    topic: 'Blockchain 2',
  },
  {
    id: '3',
    image_url: 'https://google.com',
    topic: 'Blockchain 3',
  },
  {
    id: '4',
    image_url: 'https://google.com',
    topic: 'Blockchain 4',
  },
  {
    id: '5',
    image_url: 'https://google.com',
    topic: 'Blockchain 5',
  },
  {
    id: '6',
    image_url: 'https://google.com',
    topic: 'Blockchain 6',
  },
  {
    id: '7',
    image_url: 'https://google.com',
    topic: 'Blockchain 7',
  },
  {
    id: '8',
    image_url: 'https://google.com',
    topic: 'Blockchain 8',
  },
  {
    id: '9',
    image_url: 'https://google.com',
    topic: 'Blockchain 9',
  },
  {
    id: '10',
    image_url: 'https://google.com',
    topic: 'Blockchain 10',
  },
  {
    id: '11',
    image_url: 'https://google.com',
    topic: 'Blockchain 11',
  },
  {
    id: '12',
    image_url: 'https://google.com',
    topic: 'Blockchain 12',
  },
  {
    id: '13',
    image_url: 'https://google.com',
    topic: 'Blockchain',
  },
  {
    id: '14',
    image_url: 'https://google.com',
    topic: 'Blockchain 2',
  },
  {
    id: '15',
    image_url: 'https://google.com',
    topic: 'Blockchain 3',
  },
  {
    id: '16',
    image_url: 'https://google.com',
    topic: 'Blockchain 4',
  },
  {
    id: '17',
    image_url: 'https://google.com',
    topic: 'Blockchain 5',
  },
  {
    id: '18',
    image_url: 'https://google.com',
    topic: 'Blockchain 6',
  },
  {
    id: '19',
    image_url: 'https://google.com',
    topic: 'Blockchain 7',
  },
  {
    id: '20',
    image_url: 'https://google.com',
    topic: 'Blockchain 8',
  },
  {
    id: '21',
    image_url: 'https://google.com',
    topic: 'Blockchain 9',
  },
  {
    id: '22',
    image_url: 'https://google.com',
    topic: 'Blockchain 10',
  },
  {
    id: '23',
    image_url: 'https://google.com',
    topic: 'Blockchain 11',
  },
  // {
  //   id: '24',
  //   image_url: 'https://google.com',
  //   topic: 'Blockchain 12',
  // },
];

export const ArticleData = [
  {
    id: '10',
    topic: 'Blockchain 10',
    title: 'A new thing 10 here in ua dola er',
    description: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever
      since the 1500s, when an unknown printer took a galley of type and
      scrambled it to make a type specimen book. It has survived not only
      five centuries, but also the leap into electronic typesetting,
      remaining essentially unchanged. It was popularised in the 1960s with
      the release of Letraset sheets containing Lorem Ipsum passages, and
      more recently with desktop publishing software like Aldus PageMaker
      including versions of Lorem Ipsum. Contrary to popular belief, Lorem
      Ipsum is not simply random text. It has roots in a piece of classical
      Latin literature from 45 BC, making it over 2000 years old. Richard
      McClintock, a Latin professor at Hampden-Sydney College in Virginia,
      looked up one of the more obscure Latin words, consectetur, from a
      Lorem Ipsum passage, and going through the cites of the word in
      classical literature, discovered the undoubtable source. Lorem Ipsum
      comes from sections 1.10.32 and 1.10.33 of "de Finibus Bonorum et
      Malorum" (The Extremes of Good and Evil) by Cicero, written in 45 BC.
      This book is a treatise on the theory of ethics, very popular during
      the Renaissance. The first line of Lorem Ipsum.`,
  },
  {
    id: '11',
    topic: 'Blockchain 11',
    title:
      'A new thing after 20 minutes have passed click at the bottom of the page',
    description: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever
      since the 1500s, when an unknown printer took a galley of type and
      scrambled it to make a type specimen book. It has survived not only
      five centuries, but also the leap into electronic typesetting,
      remaining essentially unchanged. It was popularised in the 1960s with
      the release of Letraset sheets containing Lorem Ipsum passages, and
      more recently with desktop publishing software like Aldus PageMaker
      including versions of Lorem Ipsum. Contrary to popular belief, Lorem
      Ipsum is not simply random text. It has roots in a piece of classical
      Latin literature from 45 BC, making it over 2000 years old. Richard
      McClintock, a Latin professor at Hampden-Sydney College in Virginia,
      looked up one of the more obscure Latin words, consectetur, from a
      Lorem Ipsum passage, and going through the cites of the word in
      classical literature, discovered the undoubtable source. Lorem Ipsum
      comes from sections 1.10.32 and 1.10.33 of "de Finibus Bonorum et
      Malorum" (The Extremes of Good and Evil) by Cicero, written in 45 BC.
      This book is a treatise on the theory of ethics, very popular during
      the Renaissance. The first line of Lorem Ipsum.`,
  },
  {
    id: '12',
    topic: 'Blockchain 12',
    title:
      'A new thing after 20 minutes have passed click at the bottom of the page',
    description: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever
      since the 1500s, when an unknown printer took a galley of type and
      scrambled it to make a type specimen book. It has survived not only
      five centuries, but also the leap into electronic typesetting,
      remaining essentially unchanged. It was popularised in the 1960s with
      the release of Letraset sheets containing Lorem Ipsum passages, and
      more recently with desktop publishing software like Aldus PageMaker
      including versions of Lorem Ipsum. Contrary to popular belief, Lorem
      Ipsum is not simply random text. It has roots in a piece of classical
      Latin literature from 45 BC, making it over 2000 years old. Richard
      McClintock, a Latin professor at Hampden-Sydney College in Virginia,
      looked up one of the more obscure Latin words, consectetur, from a
      Lorem Ipsum passage, and going through the cites of the word in
      classical literature, discovered the undoubtable source. Lorem Ipsum
      comes from sections 1.10.32 and 1.10.33 of "de Finibus Bonorum et
      Malorum" (The Extremes of Good and Evil) by Cicero, written in 45 BC.
      This book is a treatise on the theory of ethics, very popular during
      the Renaissance. The first line of Lorem Ipsum.`,
  },
  {
    id: '13',
    topic: 'Blockchain 13',
    title:
      'A new thing after 20 minutes have passed click at the bottom of the page',
    description: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever
      since the 1500s, when an unknown printer took a galley of type and
      scrambled it to make a type specimen book. It has survived not only
      five centuries, but also the leap into electronic typesetting,
      remaining essentially unchanged. It was popularised in the 1960s with
      the release of Letraset sheets containing Lorem Ipsum passages, and
      more recently with desktop publishing software like Aldus PageMaker
      including versions of Lorem Ipsum. Contrary to popular belief, Lorem
      Ipsum is not simply random text. It has roots in a piece of classical
      Latin literature from 45 BC, making it over 2000 years old. Richard
      McClintock, a Latin professor at Hampden-Sydney College in Virginia,
      looked up one of the more obscure Latin words, consectetur, from a
      Lorem Ipsum passage, and going through the cites of the word in
      classical literature, discovered the undoubtable source. Lorem Ipsum
      comes from sections 1.10.32 and 1.10.33 of "de Finibus Bonorum et
      Malorum" (The Extremes of Good and Evil) by Cicero, written in 45 BC.
      This book is a treatise on the theory of ethics, very popular during
      the Renaissance. The first line of Lorem Ipsum.`,
  },
  {
    id: '1',
    topic: 'Blockchain',
    title:
      'A new thing after 20 minutes have passed click at the bottom of the page',
    description:
      'Any time after 20 minutes have passed, you can click the I’m Done! at the bottom of the page',
  },
  {
    id: '2',
    topic: 'Blockchain 2',
    title: 'A new thing',
    description:
      'Any time after 20 minutes have passed, you can click the I’m Done! at the bottom of the page',
  },
  {
    id: '3',
    topic: 'Blockchain 3',
    title: 'A new thing',
    description:
      'Any time after 20 minutes have passed, you can click the I’m Done! at the bottom of the page',
  },
  {
    id: '4',
    topic: 'Blockchain 4',
    title: 'A new thing',
    description:
      'Any time after 20 minutes have passed, you can click the I’m Done! at the bottom of the page',
  },
  {
    id: '5',
    topic: 'Blockchain 5',
    title: 'A new thing',
    description:
      'Any time after 20 minutes have passed, you can click the I’m Done! at the bottom of the page',
  },
  {
    id: '6',
    topic: 'Blockchain 6',
    title: 'A new thing',
    description:
      'Any time after 20 minutes have passed, you can click the I’m Done! at the bottom of the page',
  },
  {
    id: '7',
    topic: 'Blockchain 7',
    title: 'A new thing',
    description:
      'Any time after 20 minutes have passed, you can click the I’m Done! at the bottom of the page',
  },
  {
    id: '8',
    topic: 'Blockchain 8',
    title: 'A new thing',
    description:
      'Any time after 20 minutes have passed, you can click the I’m Done! at the bottom of the page',
  },
  {
    id: '9',
    topic: 'Blockchain 9',
    title: 'A new thing 9 here in ua dola er',
    description: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever
      since the 1500s, when an unknown printer took a galley of type and
      scrambled it to make a type specimen book. It has survived not only
      five centuries, but also the leap into electronic typesetting,
      remaining essentially unchanged. It was popularised in the 1960s with
      the release of Letraset sheets containing Lorem Ipsum passages, and
      more recently with desktop publishing software like Aldus PageMaker
      including versions of Lorem Ipsum. Contrary to popular belief, Lorem
      Ipsum is not simply random text. It has roots in a piece of classical
      Latin literature from 45 BC, making it over 2000 years old. Richard
      McClintock, a Latin professor at Hampden-Sydney College in Virginia,
      looked up one of the more obscure Latin words, consectetur, from a
      Lorem Ipsum passage, and going through the cites of the word in
      classical literature, discovered the undoubtable source. Lorem Ipsum
      comes from sections 1.10.32 and 1.10.33 of "de Finibus Bonorum et
      Malorum" (The Extremes of Good and Evil) by Cicero, written in 45 BC.
      This book is a treatise on the theory of ethics, very popular during
      the Renaissance. The first line of Lorem Ipsum.`,
  },
  {
    id: '9',
    topic: 'Blockchain 9',
    title: 'A new thing 9 here in ua dola er',
    description: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever
      since the 1500s, when an unknown printer took a galley of type and
      scrambled it to make a type specimen book. It has survived not only
      five centuries, but also the leap into electronic typesetting,
      remaining essentially unchanged. It was popularised in the 1960s with
      the release of Letraset sheets containing Lorem Ipsum passages, and
      more recently with desktop publishing software like Aldus PageMaker
      including versions of Lorem Ipsum. Contrary to popular belief, Lorem
      Ipsum is not simply random text. It has roots in a piece of classical
      Latin literature from 45 BC, making it over 2000 years old. Richard
      McClintock, a Latin professor at Hampden-Sydney College in Virginia,
      looked up one of the more obscure Latin words, consectetur, from a
      Lorem Ipsum passage, and going through the cites of the word in
      classical literature, discovered the undoubtable source. Lorem Ipsum
      comes from sections 1.10.32 and 1.10.33 of "de Finibus Bonorum et
      Malorum" (The Extremes of Good and Evil) by Cicero, written in 45 BC.
      This book is a treatise on the theory of ethics, very popular during
      the Renaissance. The first line of Lorem Ipsum.`,
  },
  {
    id: '9',
    topic: 'Blockchain 9',
    title: 'A new thing 9 here in ua dola er',
    description: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever
      since the 1500s, when an unknown printer took a galley of type and
      scrambled it to make a type specimen book. It has survived not only
      five centuries, but also the leap into electronic typesetting,
      remaining essentially unchanged. It was popularised in the 1960s with
      the release of Letraset sheets containing Lorem Ipsum passages, and
      more recently with desktop publishing software like Aldus PageMaker
      including versions of Lorem Ipsum. Contrary to popular belief, Lorem
      Ipsum is not simply random text. It has roots in a piece of classical
      Latin literature from 45 BC, making it over 2000 years old. Richard
      McClintock, a Latin professor at Hampden-Sydney College in Virginia,
      looked up one of the more obscure Latin words, consectetur, from a
      Lorem Ipsum passage, and going through the cites of the word in
      classical literature, discovered the undoubtable source. Lorem Ipsum
      comes from sections 1.10.32 and 1.10.33 of "de Finibus Bonorum et
      Malorum" (The Extremes of Good and Evil) by Cicero, written in 45 BC.
      This book is a treatise on the theory of ethics, very popular during
      the Renaissance. The first line of Lorem Ipsum.`,
  },
  {
    id: '9',
    topic: 'Blockchain 9',
    title: 'A new thing 9 here in ua dola er',
    description: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever
      since the 1500s, when an unknown printer took a galley of type and
      scrambled it to make a type specimen book. It has survived not only
      five centuries, but also the leap into electronic typesetting,
      remaining essentially unchanged. It was popularised in the 1960s with
      the release of Letraset sheets containing Lorem Ipsum passages, and
      more recently with desktop publishing software like Aldus PageMaker
      including versions of Lorem Ipsum. Contrary to popular belief, Lorem
      Ipsum is not simply random text. It has roots in a piece of classical
      Latin literature from 45 BC, making it over 2000 years old. Richard
      McClintock, a Latin professor at Hampden-Sydney College in Virginia,
      looked up one of the more obscure Latin words, consectetur, from a
      Lorem Ipsum passage, and going through the cites of the word in
      classical literature, discovered the undoubtable source. Lorem Ipsum
      comes from sections 1.10.32 and 1.10.33 of "de Finibus Bonorum et
      Malorum" (The Extremes of Good and Evil) by Cicero, written in 45 BC.
      This book is a treatise on the theory of ethics, very popular during
      the Renaissance. The first line of Lorem Ipsum.`,
  },
  {
    id: '9',
    topic: 'Blockchain 9',
    title: 'A new thing 9 here in ua dola er',
    description: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever
      since the 1500s, when an unknown printer took a galley of type and
      scrambled it to make a type specimen book. It has survived not only
      five centuries, but also the leap into electronic typesetting,
      remaining essentially unchanged. It was popularised in the 1960s with
      the release of Letraset sheets containing Lorem Ipsum passages, and
      more recently with desktop publishing software like Aldus PageMaker
      including versions of Lorem Ipsum. Contrary to popular belief, Lorem
      Ipsum is not simply random text. It has roots in a piece of classical
      Latin literature from 45 BC, making it over 2000 years old. Richard
      McClintock, a Latin professor at Hampden-Sydney College in Virginia,
      looked up one of the more obscure Latin words, consectetur, from a
      Lorem Ipsum passage, and going through the cites of the word in
      classical literature, discovered the undoubtable source. Lorem Ipsum
      comes from sections 1.10.32 and 1.10.33 of "de Finibus Bonorum et
      Malorum" (The Extremes of Good and Evil) by Cicero, written in 45 BC.
      This book is a treatise on the theory of ethics, very popular during
      the Renaissance. The first line of Lorem Ipsum.`,
  },
  {
    id: '9',
    topic: 'Blockchain 9',
    title: 'A new thing 9 here in ua dola er',
    description: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever
      since the 1500s, when an unknown printer took a galley of type and
      scrambled it to make a type specimen book. It has survived not only
      five centuries, but also the leap into electronic typesetting,
      remaining essentially unchanged. It was popularised in the 1960s with
      the release of Letraset sheets containing Lorem Ipsum passages, and
      more recently with desktop publishing software like Aldus PageMaker
      including versions of Lorem Ipsum. Contrary to popular belief, Lorem
      Ipsum is not simply random text. It has roots in a piece of classical
      Latin literature from 45 BC, making it over 2000 years old. Richard
      McClintock, a Latin professor at Hampden-Sydney College in Virginia,
      looked up one of the more obscure Latin words, consectetur, from a
      Lorem Ipsum passage, and going through the cites of the word in
      classical literature, discovered the undoubtable source. Lorem Ipsum
      comes from sections 1.10.32 and 1.10.33 of "de Finibus Bonorum et
      Malorum" (The Extremes of Good and Evil) by Cicero, written in 45 BC.
      This book is a treatise on the theory of ethics, very popular during
      the Renaissance. The first line of Lorem Ipsum.`,
  },
];
