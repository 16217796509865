import React from 'react';
import { Flex } from '@rebass/grid';
import { Button, LoaderWrapper } from '../../components';
import { useState } from 'react';
import { LoginLogo } from '../LoginContainer/LoginContainer.styles';
import useQuery from '../../customHooks/useQuery';
import axios from 'axios';
import { cloutV2Endpoints, fafToken } from '../../services/helpers';
import { ColorPallete } from '../../utils';
import { isEmpty } from 'lodash';
import ThankYou from './ForwardAFriendConfirmation';
import { ForwardInput } from './style';
import MemoRightArrow from 'src/assets/icons/RightArrow';
import Turnstile from 'react-turnstile';
import { v4 as uuidv4} from 'uuid';
import { ScrollWrapper } from 'src/componentsV2';
import { DeleteIcon } from '../ConfigureContainer/EmailList/EmailList.styles';
import trashIcon from '../ConfigureContainer/EmailList/trashIcon.svg';

const ForwardAFriendContainer = () => {
  const [loading, setLoading] = useState(false);
  const [openConfirmationPage, setOpenConfirmationPage] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [recipients, setRecipients] = useState([
    {
      id: uuidv4(),
      email: '',
      name: ''
    }
  ])
  const [status, setStatus] = useState('fail');
  const [name, setName] = useState('')
  
  const query = useQuery();
  const fromEmail = query.get('from_email');
  const newsletterId = query.get('newsletter_id');
  const uid = query.get('uid');


  // const handleSendClick = () => {
  //   setOpenConfirmationPage(true);
  // };

  const handleValidation = () => {
    let isInValid = false;
    const isEmailRegEx =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,3}))$/;

      if(isEmpty(recipients) || isEmpty(name)) {
        isInValid = true;
        setErrorMsg('Fields cannot be Empty!');
      }

      if(!isEmpty(recipients)) {
        recipients.forEach(element => {
          if(isEmpty(element.email) || isEmpty(element.name)) {
            isInValid = true;
            setErrorMsg('Fields cannot be Empty!');
          }
          let checkEmail = isEmailRegEx.test(element.email.toLowerCase());
          if(!checkEmail) {
            isInValid = true;
            setErrorMsg('Invalid Email Ids!');
          }
        });
      }

      if (status === 'fail') {
        setErrorMsg('Captcha Verification Failed')
        isInValid = true;
      } 
    
    return isInValid;
    
  };


  const handleChange = (value, id, type) => {
    let temp = recipients
    let getIndex = temp.findIndex(r => r.id === id);
    if(type === 'email') {
      temp[getIndex].email = value;
    } else {
      temp[getIndex].name = value;
    }
    setRecipients([...temp]);
  }
  const handleSubmit = () => {
    let validation = handleValidation();
    if (!validation) {
      setLoading(true);
      setErrorMsg('');
      const payload = {
        uid: uid,
        newsletter_id: newsletterId,
        recipients: recipients,
        your_email: fromEmail,
        your_name: name
      };
      axios
        .post(`${cloutV2Endpoints.baseUrl}/forward_friend/email`, payload, {
          headers: {
            Authorization:
              `Bearer ${fafToken}`,
              'Content-Type': 'application/json',
          },
        })
        .then((res) => {
          setOpenConfirmationPage(true);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
          setErrorMsg('Something Went Wrong');
        });
    }
  };

  const handleAddRow = () => {
    let row = {
      id: uuidv4(),
      name: '',
      email: ''
    }

    setRecipients([...recipients, row])
  }

  const handleDelete = (id) => {
    let updatedRows = recipients.filter(r => r.id !== id);
    setRecipients(updatedRows);
  }

  const resetForm = () => {
    setErrorMsg('');
    setRecipients([{
      id: uuidv4(),
      email: '',
      name: ''
    }])
    setOpenConfirmationPage(false);
    setStatus('fail');
    setName('');
  }
  return (
    <Flex
      display="flex"
      justifyContent="center"
      flexDirection="column"
      width="100%"
    >
      <Flex
        width="100%"
        justifyContent="center"
        style={{
          boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.12)',
          borderBottom: `1px solid ${ColorPallete.neutral200}`,
        }}
      >
        <LoginLogo
          style={{ width: '200px', height: '100px', textAlign: 'left' }}
          src="/assets/images/clout-logos/MA-logo.png"
          alt="mio-logo"
        />
      </Flex>
      <Flex
        width="100%"
        backgroundColor="#F9FAFB"
        justifyContent="center"
        alignItems="center"
        style={{
          height: 'calc(100vh - 120px)',
        }}
      >
        {!openConfirmationPage && (
          <Flex
            flexDirection="column"
            padding="24px"
            backgroundColor="#fff"
            width="702px"
            style={{
              gap: '24px',
              boxShadow: '0px 4px 8px 0px rgba(16, 24, 40, 0.10)',
              border: `1px solid ${ColorPallete.neutral200}`,
              borderRadius: '8px',
            }}
          >
            <Flex
              color={ColorPallete.neutral900}
              fontSize="20px"
              style={{
                fontWeight: 500,
              }}
            >
              Forward Campaign
            </Flex>
            <ScrollWrapper maxHeight="250px" width="100%" styles={{
              gap: '8px'
            }}>
              {!isEmpty(recipients) &&
                recipients.map((item, index) => (
                  <Flex
                    width="100%"
                    justifyContent="space-between"
                    alignItems="center"
                    style={{ gap: '24px' }}
                  >
                    <Flex
                      width={recipients.length === 1 ? '50%' : '45%'}
                      flexDirection="column"
                      style={{ gap: '4px' }}
                    >
                      <Flex fontSize="14px" color={ColorPallete.neutral900}>
                        Recipient Email
                        <span style={{ color: '#E00000' }}>*</span>
                      </Flex>
                      <Flex width="100%">
                        <ForwardInput
                          placeholder="Recipient Email"
                          value={item.email}
                          onChange={(e) =>
                            handleChange(e?.target?.value, item.id, 'email')
                          }
                        />
                      </Flex>
                    </Flex>
                    <Flex
                      width={recipients.length === 1 ? '50%' : '45%'}
                      flexDirection="column"
                      style={{ gap: '4px' }}
                    >
                      <Flex fontSize="14px" color={ColorPallete.neutral900}>
                        Recipient Name
                        <span style={{ color: '#E00000' }}>*</span>
                      </Flex>
                      <Flex width="100%">
                        <ForwardInput
                          placeholder="Recipient Name"
                          value={item.name}
                          onChange={(e) =>
                            handleChange(e?.target?.value, item.id, 'name')
                          }
                        />
                      </Flex>
                    </Flex>
                    {recipients.length > 1 && (
                      <DeleteIcon 
                        src={trashIcon} 
                        alt='delete'  
                        style={{
                          margin: '30px 0px 0px'
                        }} 
                        onClick={() => handleDelete(item.id)}                    
                      />
                    )}
                  </Flex>
                ))}
            </ScrollWrapper>

            <Flex justifyContent="center" width="100%">
              <Button
                disabled={recipients.length > 20}
                color={ColorPallete.neutral900}
                bgColor={ColorPallete.white}
                onClick={handleAddRow}
                borderRadius="4px"
                margin="0px"
              >
                Add More
              </Button>
            </Flex>

            <Flex width="100%" flexDirection="column" style={{ gap: '4px' }}>
              <Flex width="100%" flexDirection="column">
                <Flex fontSize="14px" color={ColorPallete.neutral900}>
                  Your Name <span style={{ color: '#E00000' }}>*</span>
                </Flex>
                <Flex fontSize="12px" color={ColorPallete.neutral900}>
                  Include your full name to help recipients identify your
                  campaigns for best chance at engagement.
                </Flex>
              </Flex>
              <Flex width="100%">
                <ForwardInput
                  placeholder="Your Name"
                  value={name}
                  onChange={(e) => setName(e?.target?.value)}
                />
              </Flex>
            </Flex>
            <Flex>
              <Turnstile
                sitekey="0x4AAAAAAAWoSiZt7olrnkKq"
                onVerify={(token) => {
                  setStatus('success');
                }}
                onError={() => {
                  setStatus('fail');
                }}
              />
            </Flex>
            <Flex width="100%">
              <LoaderWrapper isLoading={loading}>
                <Button
                  margin="0px"
                  width="100%"
                  borderRadius="4px"
                  styles={{
                    boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.12)',
                  }}
                  onClick={handleSubmit}
                >
                  <Flex justifyContent="space-between" alignItems="center">
                    <Flex fontSize="14px" style={{ fontWeight: 500 }}>
                      Forward
                    </Flex>
                    <MemoRightArrow height="24px" width="24px" />
                  </Flex>
                </Button>
              </LoaderWrapper>
            </Flex>
            {errorMsg && (
              <Flex
                width="100%"
                justifyContent="center"
                fontSize="14px"
                color={ColorPallete.primaryBlue}
              >
                *{errorMsg}
              </Flex>
            )}
          </Flex>
        )}
        {openConfirmationPage && <ThankYou resetForm={resetForm} />}
      </Flex>
    </Flex>
  );
};

export default ForwardAFriendContainer;
