import React from 'react';
import { Modal } from '../Modal';

import { ShareContentContainer } from '../';

const ShareContentModal = ({
  open,
  handleClose,
  fromMyClients,
  selectedDropdownOption,
  selectedCRMContactList,
  contactId,
  approvedToShare,
  approvedArticles,
  fromContentLibTab,
  isAssetAdvisor,
  isEnterpriseAdvisor,
  isFromCompliance,
  isNewsLetter,
  complianceTab,
  newsLetter_id,
  engagementId,
  isFromImageOnly,
  toggleIsCloning,
  ...rest
}) => {
  return (
    <Modal
      onRequestClose={() => {
        handleClose();
        localStorage.removeItem('emailData');
        if (toggleIsCloning) toggleIsCloning(false);
      }}
      shouldCloseOnOverlayClick
      isOpen={open}
      size={
        isFromCompliance
          ? 'big'
          : fromMyClients
          ? isNewsLetter
            ? 'newsletter-compliance'
            : 'medium77'
          : window.innerWidth < 768
          ? 'large'
          : 'medium68'
      }
      bgColor={isFromCompliance && '#f5f7fb'}
    >
      <ShareContentContainer
        fromMyClients={fromMyClients}
        selectedDropdownOption={selectedDropdownOption}
        selectedCRMContactList={selectedCRMContactList}
        contactId={contactId}
        isFromCompliance={isFromCompliance}
        approvedArticles={approvedArticles}
        fromContentLibTab={fromContentLibTab}
        approvedToShare={approvedToShare}
        isAssetAdvisor={isAssetAdvisor}
        isEnterpriseAdvisor={isEnterpriseAdvisor}
        asModal
        handleClose={handleClose}
        isNewsLetter={isNewsLetter}
        newsLetter_id={newsLetter_id}
        complianceTab={complianceTab}
        engagementId={engagementId}
        isFromImageOnly={isFromImageOnly}
        {...rest}
      />
    </Modal>
  );
};

export default ShareContentModal;
