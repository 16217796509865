import React from "react";
import withAppLayout from "../../hoc/withAppLayout";

import { ComplianceContainer } from "../../containers";
import ComplianceContainerAdvisor from '../../containers/ComplianceContainerAdvisor/ComplianceContainerAdvisor';
import { useLocation } from "react-router-dom";

const Compliance = props => {
    const location = useLocation();
    // console.log('[LOCATION] - ', location.pathname);
    if(location.pathname == '/advisor/compliance')
        return <ComplianceContainerAdvisor />
    return <ComplianceContainer />
};

export default withAppLayout(Compliance);
