import React from 'react';

import { MioSelect } from '../CommonStyles';

const Select = ({
  name,
  id,
  onChange,
  value,
  height,
  width,
  children,
  background,
  borderRadius,
  padding,
  fontSize,
}) => {
  return (
    <MioSelect
      {...{
        name,
        id,
        onChange,
        value,
        height,
        width,
        background,
        borderRadius,
        padding,
        fontSize,
      }}
    >
      {children}
    </MioSelect>
  );
};

export default Select;
