/* eslint-disable react-hooks/exhaustive-deps */
import { Flex } from '@rebass/grid';
import React, { useState, useEffect } from 'react';
import { LoaderWrapper, DownloadButton, DisplayScore, Button, ResponsiveImg } from '../../../components';
import { TopLeadWrapper, TopShareName, TopLeadEmail, SponsoredWrapper, OverviewBadge } from './EngagementTab.styles';
import { get, isEmpty } from 'lodash';
import { DownloadButtonFlex } from '../AnalyticsContainer.styles';
import { saveFile, getTabNameFromRepType, onImageError } from '../../../utils';
import { getDownloadCsvData } from '../../../services/analytics';
import { connect } from 'react-redux';

const LeadTab = ({
  tableData,
  isAnalyticsDataFetching,
  handleRowClick,
  selectedRepType,
  analyticsObject,
  analyticsRequest,
  page,
  setPage,
  selectedContacts,
  isAdmin,
  setPaginationToken,
}) => {
  console.log('RENDERING PROSPECTS TAB...');
  const [startIndex, setStartIndex] = useState(0);
  const [endIndex, setEndIndex] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    console.log("ANALYTICSOBJ::", analyticsObject)
    console.log('tableData', tableData)
    if (tableData.pagination && tableData.pagination.total_count) {
      if (page === 0) {
        setStartIndex(tableData.pagination.total_count === "0" ? 0 : 1);
        setEndIndex(get(tableData, 'leads', []).length < 6 ? tableData.pagination.total_count : 6);
      } else {
        setStartIndex((page * 6) + 1);
        setEndIndex(((page + 1) * 6) >= tableData.pagination.total_count ? tableData.pagination.total_count : (page + 1) * 6);
      }
    }
  }, [page, tableData])

  const onDownloadCSVClick = () => {
    setLoading(true);
    const tabName = getTabNameFromRepType(selectedRepType);
    const { startDate, endDate } = analyticsObject || {};
    const fileName = `${tabName}-${startDate}_${endDate}`;
    
    getDownloadCsvData({repType: selectedRepType, startDate, endDate, contact_list: selectedContacts, filters: analyticsObject.filterParams }).then(res => {
      saveFile(res, fileName);
      setLoading(false);
    }).catch(err => {
      setLoading(false);
    });   
  };

  const onPaginationClick = (type) => {
    if (type === 'before') {
      console.log(tableData.pagination.before);
      setPaginationToken(tableData.pagination.before);
      analyticsRequest({
        params: {
          repType: selectedRepType,
          contact_list: selectedContacts,
          beforeData: tableData.pagination && tableData.pagination.before
        }
      })
    } else {
      console.log(tableData.pagination.after);
      setPaginationToken(tableData.pagination.after);
      analyticsRequest({
        params: {
          repType: selectedRepType,
          contact_list: selectedContacts,
          afterData: tableData.pagination && tableData.pagination.after
        }
      })
    }
    setPage(type === 'before' ? page === 0 ? 0 : page - 1 : page + 1);
  }
  return (
    <LoaderWrapper isLoading={isAnalyticsDataFetching}>
      <Flex width="746px" className="lead-tab" style={{ height: '469px' }} pt='15px' pl='10px' pr='10px' flexWrap='wrap'>
        {get(tableData, 'leads', []) && get(tableData, 'leads', []).length > 0 ?
          get(tableData, 'leads', []).slice(page*6, (page+1)*6).map((item, index) => {
            console.log('item', item)
            const trimName = ((item.first_name || '') + ' ' + (item.last_name || '')).length > 15 ? ((item.first_name || '') + ' ' + (item.last_name || '')).substring(0, 15) + '...' : ((item.first_name || '') + ' ' + (item.last_name || ''));
            const trimEmail = item.email && item.email.length > 20 ? item.email.substring(0, 20) + '...' : item.email;
            return (
              <TopLeadWrapper key={'item_'+index} width="213px" height='212px' margin="14px 10px 0px 8.2px" padding='0px' style={{cursor: 'pointer'}} onClick={() => handleRowClick(item.email, item)}>
                <Flex style={{ height: '99px', width: '100%' }}>
                  <img alt='share' loading="eager" src={item.suggested_investments && item.suggested_investments.length > 0 ? item.suggested_investments[0].theme_image_path : ''} width='212px' onError={onImageError} style={{ borderTopLeftRadius: '8px', borderTopRightRadius: '8px', objectFit: 'cover' }} />
                </Flex>
                <Flex flexDirection='column' padding='8px 8px 10px 8px'>
                  <Flex width='100%' justifyContent='space-between' padding='0px 6px'>
                    <Flex flexDirection='column' justifyContent='flex-start'>
                      <TopShareName>{trimName}</TopShareName>
                      <TopLeadEmail title={item.email && item.email.length > 20 ? item.email : ''}>{trimEmail}</TopLeadEmail>
                    </Flex>
                    <Flex>
                      {
                        item.lead === "true" && item.lead_type === 'leadgen' && 
                        <div className='tooltip-div tooltip-disclosure'>
                          <ResponsiveImg src="/assets/images/lead.svg" style={{ height: '23px', width: 'auto', marginTop: '-2px', marginLeft: '-22px', position: 'absolute' }} />
                          <span className='tooltip-text tooltiptext-disclosure lead-referral'>Lead captured</span>
                        </div>
                      }

                      {
                        item.lead === "true" && item.lead_type === 'referral' && 
                        <div className='tooltip-div tooltip-disclosure'>
                            <ResponsiveImg src="/assets/images/referral.svg" style={{ height: '23px', width: 'auto', marginTop: '-2px', marginLeft: '-22px', position: 'absolute' }} />
                            <span className='tooltip-text tooltiptext-disclosure lead-referral'>Referral captured</span>
                        </div>
                      }

                      {
                        item.engagement_score !== 'na' && item.lead_type !== 'referral' &&
                        <DisplayScore score={item.engagement_score === '0' ? '': item.engagement_score} /> 
                      }
                    </Flex>
                  </Flex>
                  {!isEmpty(item.suggested_investments) && item.lead_type !== 'referral' ? (
                    item.suggested_investments.slice(0, 1).map((item, index) => (
                      <SponsoredWrapper mt='10px'>
                        <Flex width='100%' justifyContent='flex-start' padding='2px 0px' style={{ height: '13px' }}>
                          <img src={item.logo_path} style={{objectFit:'contain', objectPosition:'left'}} alt='Company' />
                        </Flex>
                        <Flex width='100%' fontSize='10px' padding='2px 0px' style={{ textAlign: 'left', color: '#49484a', fontWeight: '500', whiteSpace: 'nowrap', height: '15px' }}>
                          <span style={{marginTop: '-10px'}}>
                            <div className='tooltip-div tooltip-disclosure' style={{paddingTop: 'unset', position: 'absolute'}}>
                              <p style={{cursor: item.name.length > 28 ? 'pointer': 'default'}}>{item.name.length > 28 ? item.name.substring(0, 28) + '...' : item.name}</p>
                                {item.name.length > 28 && 
                              <span className='tooltip-text tooltiptext-disclosure' style={{left: '170px', top: '8px', whiteSpace: 'nowrap', width: 'initial', backgroundColor: '#404040', color: '#ffffff'}}>{item.name}</span>}
                            </div>
                          </span>
                        </Flex>
                        <Flex justifyContent="space-between" style={{paddingTop: '12px'}}>
                          <Flex>
                            <span style={{ color: '#49484a', fontWeight: 'bold', fontSize: '10px' }}>{item.ticker}</span>
                            &nbsp;
                            {item.matched_topics && item.matched_topics.length > 0 && item.matched_topics.slice(0, 1).map((item, index) => (
                              <span style={{ color: '#49484a', fontSize: '8px', fontWeight: 500, border: '1px solid #49484a', borderRadius: '2px', marginRight: '4px', height: '11px', display: 'flex', alignItems: 'center', padding: '0px 2px', marginTop: '2px' }}>
                                 <div className='tooltip-div tooltip-disclosure' style={{paddingTop: 'unset'}}>
                                  <p style={{cursor: item && item.length > 10 ? 'pointer': 'default'}}>{item && item.length > 10 ? item && item.substring(0, 10) + '...' : item}</p>
                                    {item && item.length > 10 && 
                                    <span className='tooltip-text tooltiptext-disclosure' style={{left: '63px', top: '2px', whiteSpace: 'nowrap', width: 'initial', backgroundColor: '#404040', color: '#ffffff'}}>
                                      {item}
                                    </span>}
                                  </div>
                              </span>
                            ))}
                          </Flex>
                          {item.is_sponsored && (
                            <OverviewBadge><span>Sponsored</span></OverviewBadge>
                          )}
                        </Flex>
                      </SponsoredWrapper>
                    ))
                  ) : null}
                
                {!isEmpty(item.suggested_investments) && item.lead_type === 'referral' &&
                  (<>
                    <Flex flexDirection="column" style={{width: '100%'}}>
                      <div style={{width: '195px', fontWeight: '500', fontSize: '10px', paddingLeft: '6px', textAlign: 'left', marginTop: '5px', marginBottom: '-10px'}}>
                        Referred By -
                        <span style={{width: '150px', color: 'black', fontWeight: '500', fontSize: '10px', textAlign: 'left', paddingLeft: '4px'}}>
                          {item.referrer_fullname && item.referrer_fullname.length > 15 ? item.referrer_fullname.substring(0, 15) + '...' : item.referrer_fullname}
                        </span>
                      </div>
                    </Flex>
                    {
                      item.suggested_investments.slice(0, 1).map((item, index) => (
                        <SponsoredWrapper mt='10px'>
                          <Flex width='100%' justifyContent='flex-start' padding='2px 0px' style={{ height: '13px' }}>
                            <img src={item.logo_path} style={{objectFit:'contain', objectPosition:'left'}} alt='Company' />
                          </Flex>
                          <Flex width='100%' fontSize='10px' padding='2px 0px' style={{ textAlign: 'left', color: '#49484a', fontWeight: '500', whiteSpace: 'nowrap', height: '15px' }}>
                            <span style={{marginTop: '-10px'}}>
                            <div className='tooltip-div tooltip-disclosure' style={{paddingTop: 'unset'}}>
                              <p style={{cursor: item.name.length > 28 ? 'pointer': 'default'}}>{item.name.length > 28 ? item.name.substring(0, 28) + '...' : item.name}</p>
                                {item.name.length > 28 && 
                              <span className='tooltip-text tooltiptext-disclosure' style={{left: '180px', top: '-4px', whiteSpace: 'nowrap', width: 'initial', backgroundColor: '#404040', color: '#ffffff'}}>{item.name}</span>}
                            </div>
                          </span>
                          </Flex>
                          <Flex justifyContent="space-between" style={{paddingTop: '2px'}}>
                            <Flex>
                              <span style={{ color: '#49484a', fontWeight: 'bold', fontSize: '10px' }}>{item.ticker}</span>
                              &nbsp;
                              {item.matched_topics && item.matched_topics.length > 0 && item.matched_topics.slice(0, 1).map((item, index) => (
                                <span style={{ color: '#49484a', fontSize: '8px', fontWeight: 500, border: '1px solid #49484a', borderRadius: '2px', marginRight: '4px', height: '11px', display: 'flex', alignItems: 'center', padding: '0px 2px', marginTop: '2px' }}>
                                  <div className='tooltip-div tooltip-disclosure' style={{paddingTop: 'unset'}}>
                                  <p style={{cursor: item.length > 10 ? 'pointer': 'default'}}>{item.length > 10 ? item.substring(0, 10) + '...' : item}</p>
                                    {item.length > 10 && <span className='tooltip-text tooltiptext-disclosure' style={{left: '63px', top: '2px', whiteSpace: 'nowrap', width: 'initial', backgroundColor: '#404040', color: '#ffffff'}}>{item}</span>}
                                  </div>
                                </span>
                              ))}
                            </Flex>
                            {item.is_sponsored && (
                              <OverviewBadge><span>Sponsored</span></OverviewBadge>
                            )}
                          </Flex>
                        </SponsoredWrapper>
                      ))
                    }
                  </>
                  )
                }
                </Flex>
              </TopLeadWrapper>
            )
          })
          :
          <div style={{ margin: 'auto', fontSize: '12px' }}>No Records Found</div>
        }
      </Flex>
      <Flex width='100%' mt='-18px' padding='0px 18px 0px 24px' height="88px">
        <Flex width='67%' style={{ position: 'relative', top: '41px'}}>
          {tableData.pagination && tableData.pagination.total_count && !isAnalyticsDataFetching && <span style={{ color: '#49484a', marginTop: '23px', fontSize: '14px', fontWeight: 'bold', marginLeft: '6px'}}>
            {startIndex}-{endIndex} of {tableData.pagination.total_count}
          </span>}
        </Flex>
        <Flex width='33%' justifyContent='space-between' alignItems='flex-end'>
          <Button margin="10px 0px 0px 0px" type="button" bgColor={page < 1 ? '#AFAFAF' : '#6351ed'} width='106px' onClick={() => setPage(page-1)} disabled={page < 1 ? true : false}>Previous</Button>
          {/* <Button margin="10px 0px 0px 0px" type="button" bgColor={page < 1 ? '#AFAFAF' : '#6351ed'} width='106px' onClick={() => onPaginationClick('before')} disabled={page < 1 ? true : false}>Previous</Button> */}
          <Button margin="10px 0px 0px 0px" width='106px' bgColor={get(tableData, 'pagination.total_count', '0') === endIndex ? '#AFAFAF' : '#6351ed'} onClick={() => setPage(page+1)} type="button" disabled={get(tableData, 'pagination.total_count', '0') === endIndex}>Next</Button>
          {/* <Button margin="10px 0px 0px 0px" width='106px' bgColor={get(tableData, 'pagination.total_count', '0') === endIndex.toString() ? '#AFAFAF' : '#6351ed'} onClick={() => onPaginationClick('after')} type="button" disabled={get(tableData, 'pagination.total_count', '0') === endIndex.toString()}>Next</Button> */}
        </Flex>
      </Flex>
      <DownloadButtonFlex justifyContent="flex-end" style={{paddingTop: '42px'}}>
        <LoaderWrapper isLoading={loading}>
          <DownloadButton disabled={get(tableData, 'leads', []).length === 0} onClick={onDownloadCSVClick} fontSize='18px' />
        </LoaderWrapper>
      </DownloadButtonFlex>
    </LoaderWrapper>
  )
}

const mapStateToProps = (state) => ({
  
})

export default connect(mapStateToProps, null)(LeadTab);


