import { Flex } from '@rebass/grid';
import React from 'react';

const FeedbackItem = ({ feedback, title }) => {
  return (
    <Flex
      flexDirection="column"
      justifyContent="flex-start"
      alignItems="flex-start"
      pb="15px"
    >
      <label style={{ paddingBottom: '5px' }}>{title || ''}</label>
      <div
        style={{
          backgroundColor: '#E4E8EB',
          borderRadius: '5px',
          border: '1px solid rgb(219, 224, 230)',
          padding: '20px',
          fontSize: '12px',
          textAlign: 'left',
          fontWeight: '400',
          width: '100%',
        }}
      >
        {feedback || ''}
      </div>
    </Flex>
  );
};

export default FeedbackItem;
