import React from 'react';
import ReactSkeleton from 'react-loading-skeleton';
import {
  Content,
  ContentWrapper,
  Footer,
  Header,
  TopEngagement,
  TopEngagements,
  Wrapper,
} from './styles';

function Skeleton() {
  return (
    <Wrapper>
      <Header>
        <h4>Content Source Engagement</h4>
      </Header>
      <ContentWrapper>
        <Content>
          <ReactSkeleton width={200} height={200} />
          <TopEngagements>
            <TopEngagement>
              <ReactSkeleton width={50} height={15} />
              <ReactSkeleton width={100} height={15} />
            </TopEngagement>
            <TopEngagement>
              <ReactSkeleton width={50} height={15} />
              <ReactSkeleton width={100} height={15} />
            </TopEngagement>
            <TopEngagement>
              <ReactSkeleton width={50} height={15} />
              <ReactSkeleton width={100} height={15} />
            </TopEngagement>
          </TopEngagements>
        </Content>
      </ContentWrapper>
      <Footer>
        <p>
          We recommend using more content from high performing sources to boost
          your engagement.
        </p>
      </Footer>
    </Wrapper>
  );
}

export default Skeleton;
