import React, { useState } from 'react';
import { useMutation } from 'react-query';
import { useForm } from 'react-hook-form';
import { CircularProgress } from '@mui/material';
import SnackbarComponent from 'src/components/Snackbar';
import Button from 'src/componentsV2/Button';
import { createPreviewContentFromArticleLink } from './mutations';
import { ErrorText, Form, Input, InputWithLabel, Label } from './styles';

function AddContentFromArticle({ channelId, setStep, setContent }) {
  const {
    watch,
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();
  const watchValues = watch();
  const [showSnackbar, toggleSnackbar] = useState(false);
  const [error, setError] = useState('');

  const { mutate, isLoading } = useMutation(
    createPreviewContentFromArticleLink
  );

  const handlePreview = (formData) => {
    mutate(
      {
        channelId,
        payload: {
          link: formData?.articleLink,
          message: ' ',
          source: 'link',
          skip_compliance: false,
        },
      },
      {
        onSuccess: (res) => {
          if (res?.result?.success) {
            setContent(res?.result?.data ?? {});
            setStep(1);
          } else {
            setError(res?.result?.error?.message ?? 'Something went wrong');
            toggleSnackbar(true);
          }
        },
        onError: (err) => {
          setError(err?.user_message || 'Something went wrong');
          toggleSnackbar(true);
        },
      }
    );
  };

  return (
    <Form onSubmit={handleSubmit(handlePreview)}>
      <InputWithLabel>
        <Label>
          URL <span className="required">*</span>
        </Label>
        <Input
          placeholder="Paste website link here"
          {...register('articleLink', {
            required: {
              value: true,
              message: 'Website link is required',
            },
            pattern: {
              value:
                /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i,
              message: 'Please enter a valid URL',
            },
          })}
        />
        {errors && errors?.articleLink && (
          <ErrorText>{errors?.articleLink?.message ?? ''}</ErrorText>
        )}
      </InputWithLabel>
      <Button
        type="submit"
        disabled={!!!watchValues?.articleLink || isLoading}
        fullWidth
      >
        {isLoading ? <CircularProgress size={15} /> : 'Preview Content'}
      </Button>
      <SnackbarComponent
        open={showSnackbar}
        variant="error"
        handleClose={() => toggleSnackbar(false)}
        content={error}
      />
    </Form>
  );
}

export default AddContentFromArticle;
