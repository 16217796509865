import React from 'react';
import { Flex } from '@rebass/grid';
import { isEmpty } from 'lodash';
import {
  AdminCheckBox,
  GrayCheckBox,
  NewAdminCheckBox,
  NewGrayCheckBox,
} from './SocialCheckBoxes.styles';

const NewSocialCheckBoxes = ({ buttons, onCheckBoxClick }) => {
  const handleCheckBoxClick = (e, platform) => {
    e.stopPropagation();
    onCheckBoxClick(platform);
  };

  if (!isEmpty(buttons)) {
    return (
      <Flex width="100%" justifyContent="space-evenly" alignItems="center">
        {buttons.map((button) => {
          return (
            <Flex
              display="flex"
              width={button.name === "Approved to Share" ? "25%" : "18%"}
              alignItems="center"
              height="30px"
              style={{
                border: '1px solid rgba(228, 231, 236, 1)',
                boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.12',
              }}
            >
              {button.endText && (
                <Flex display="flex" width="100%">
                  <NewGrayCheckBox
                    type={button.type}
                    bgColor={button.bgColor}
                    checked={button.checked}
                    style={{ width: '100%', display: 'flex', flexDirection: 'row', height: '25px', }}
                  >
                    <AdminCheckBox
                      id={button.name}
                      type="checkbox"
                      className={button.checked ? 'checked' : ''}
                      checked={button.checked}
                      onChange={(e) => {
                        handleCheckBoxClick(e, button.platform);
                      }}
                      bgColor={button.bgColor}
                      style={{ cursor: 'pointer', width: '20px' }}
                      disabled={
                        button.type === 'discover'
                          ? button.isDisconnected
                          : false
                      }
                    />
                    <label
                      style={{
                        fontFamily: 'Poppins',
                        fontSize: button.type === 'discover' ? '10px' : '',
                        color: button.checked ? '#6351ed' : '#707070',
                        display: 'flex',
                        alignItems: 'center',
                        marginLeft: '2px',
                      }}
                    >
                      {button.endText}
                    </label>
                  </NewGrayCheckBox>
                </Flex>
              )}
            </Flex>
          );
        })}
      </Flex>
    );
  }
  return null;
};

export default NewSocialCheckBoxes;
