import { Flex } from '@rebass/grid';
import React from 'react';
import {
  WidgetTile,
  TrendingBubbleChart,
  TabList,
  Tab,
  Tabs,
  TabPanel,
  ResponsiveImg,
  SocialCheckBoxes,
} from '../../../components';
import { TrendingIcon, AdminSubTab } from '../DashboardContainer.styles';
import AdvisorSearch from '../Discover/AdvisorSearch';
import TopInsights from '../Insights/TopInsights';
import PopularTopics from '../Topics/PopularTopics';
import EditorPanel from './EditorPanel';

const Trending = ({
  setTrendingTopicDetails,
  setTabIndex,
  setSelectedExploreTabIndex,
  isAdmin,
  isComplianceAdmin,
  isEnterpriseAdmin,
  discoverTabIndex,
  onDiscoverTabChange,
  onTrendingTabClick,
  isShareBlocked,
  roleType,
  setPopularTopicDetails,
  onExploreTabClick,
  shareArticleSetState,
  onNewsletterArticleClick,
  onRemoveFromNewletterClick,
  bucketCountRequest,
  exploreSetState
}) => {
//   console.log('discoverTabIndex');
// console.log(discoverTabIndex);
  const onTrendingTabChange = () => {
    if(isAdmin) {
      setTabIndex(0);
      setSelectedExploreTabIndex(1);
    } else {
      setTabIndex(1);
    }
  }

  const onSearchTabChange = () => {
    if(isEnterpriseAdmin) {
      setTabIndex(4);
    } else if(isComplianceAdmin){
      setTabIndex(3);
    } else {
      setTabIndex(6);
    }
  }


  return (
    <WidgetTile heading="Popular" theme='light'>
      <TrendingIcon>
        <ResponsiveImg src="/assets/images/popular.svg" />
      </TrendingIcon>
      <Tabs selectedIndex={discoverTabIndex} onSelect={onDiscoverTabChange} forceRenderTabPanel style={{paddingTop: '3px'}}>
        <TabList>
          <AdminSubTab data-testid='trending_tab' padding='5px 10px 0px' onClick={onTrendingTabClick}>
            <span className={`${discoverTabIndex === 0 ? 'active' : null}`}>Trending</span>
          </AdminSubTab> 
          <AdminSubTab data-testid='most_shared_tab' padding='5px 10px 0px'>
            <span className={`${discoverTabIndex === 1 ? 'active' : null}`}>Most Shared</span>
          </AdminSubTab> 
          <AdminSubTab data-testid='most_subscribed_tab' padding='5px 10px 0px'>
            <span className={`${discoverTabIndex === 2 ? 'active' : null}`}>Most Subscribed</span>
          </AdminSubTab>
        </TabList>
        {discoverTabIndex===0 && (
          <TabPanel>
            <TrendingBubbleChart
              setTrendingTopicDetails={setTrendingTopicDetails}
              setTabIndex={onTrendingTabChange} 
              onTrendingTabClick={onTrendingTabClick}
            />
          </TabPanel>
        )}
        
        {discoverTabIndex===1 && (
          <TabPanel>
           <TopInsights 
            isShareBlocked={isShareBlocked} 
            shareArticleSetState={shareArticleSetState}
            bucketCountRequest={bucketCountRequest}  
          />
          </TabPanel>
        )}

        {discoverTabIndex===2 && (
          <TabPanel>
            <PopularTopics 
              setTabIndex={setTabIndex}
              setSelectedExploreTabIndex={setSelectedExploreTabIndex}
              setPopularTopicDetails={setPopularTopicDetails}
              roleType={roleType} 
              onExploreTabClick={onExploreTabClick}
              type='most-subscribed'
              exploreSetState={exploreSetState}
            />
          </TabPanel>
        )}
      </Tabs>
    </WidgetTile>
  );
};

export default Trending;
