import styled from 'styled-components';
import { colors } from '../../styles';

export const DropdownContainer = styled('div')`
    width: 100%;
    display: table;
    position: relative;
    margin: ${({ margin }) => margin || '10px 0 0'};
`;

export const OptionsContainer = styled('div')`
  box-shadow: 0px 4px 8px rgba(16, 24, 40, 0.1);
  position: absolute;
  top: 65px;
  transition: 1s;
  width: 100%;
  z-index: 99999;
  display: table;
  cursor: pointer;
  background: ${colors.white};
  border-radius: 4px;
  left: ${({ left }) => left || ''};
`;

export const OptionItem = styled('div')`
  padding: 6px 16px 6px 12px;
  text-align: left;
  font-size: 14px;
  cursor: pointer;
  background: ${colors.white};
  transition: 0.5s;
  color: ${colors.gray_text};
  :hover {
    background: ${colors.gray_background};
  }
`;

export const DropdownLabel = styled('div')`
    font-weight: 400;
    font-size: 14px;
    color: ${colors.black_01};
    text-align: left;
`;

export const SelectedDropdownStyle = styled('div')`
    font-weight: 500;
    font-size: 14px;
    display: flex;
    justify-content: space-between;
    margin: 6px 0 0;
    padding: 6px 12px;
    cursor: pointer;
    color: ${colors.black_01};
    background: ${colors.white};
    border: 1px solid ${colors.gray_border};
    border-radius: 4px;
    height: ${({ height }) => height && height};
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.12);
`;

export const DropdownArrow = styled('img')`
    height: 12px;
    width: 12px; 
    transition: 1s;
    margin: 4px 0 0;
    transform: ${({ selected }) => selected ? 'rotate(180deg)' : 0};
`;


export const ErrorMessage = styled('div')`
    color: ${colors.red};
    font-size: 10px;
    margin: 4px 0 0;
    text-align: left;
`;