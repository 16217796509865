import React from 'react';
import { Flex } from '@rebass/grid';
import { UpGreenIcon, DownRedIcon } from '../';

const NumbersWithArrows = ({ value, suffix }) => {
  const ArrowIcon = value <= 0 ? DownRedIcon : UpGreenIcon;
  console.log('here value', value)
  return (
    <Flex width={1} alignItems="center" justifyContent="center">
      <ArrowIcon />
      &nbsp;&nbsp;
      <div>
        {Math.abs(value)}
        {Math.abs(value) !== 0 ? suffix : null}
      </div>
    </Flex>
  );
};

export default NumbersWithArrows;
