import { get } from 'lodash';
import { takeLatest, all, call, put } from 'redux-saga/effects';
import { actions as membersActions } from '../modules/members.module';
import { getMembers } from '../../services/configure';

function* membersRequest() {
  try {
    const response = yield call(getMembers);
    const data = get(response, 'result.data', {});
    console.log('MEMBERS DATA = ', data);
    yield put(membersActions.api.members.response(data));
  } catch (error) {
    yield put(membersActions.api.members.error(error));
  }
}

function* membersSaga() {
  yield all([takeLatest(membersActions.api.members.request, membersRequest)]);
}

export default membersSaga;
