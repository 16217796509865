import { Box, Flex } from '@rebass/grid';
import { get, isEmpty } from 'lodash';
import React from 'react'
import { ColorPallete, Typography, convertDateToRequiredFormat, timeZoneAbbreviated } from 'src/utils';
import { RadioOnetimeAndSeriesInput } from '../SetObjectivesV2/SetObjectivesV2.styles';
import DatePickerV2 from 'src/components/DatePickerV2/DatePickerV2';
import styled from 'styled-components';

const DateTimeWrapper = styled(Flex)`
  background-color: hsl(0, 0%, 100%);
  border-color: hsl(0, 0%, 80%);
  border-radius: 4px;
  margin: 0 auto;
  padding: 2px 8px;
`;

const DateTimeText = styled.div`
  width: 85%;
  font-size: 0.9rem;
  display: flex;
  align-items: center;
  justify-content: center;
  &:empty:before {
    content: attr(data-placeholder);
    color: gray;
  }
`;

const SocialDateBlock = ({
    item,
    timeBlocks,
    changeTimeSlots,
    userData,
    isDatepickerOpen,
    selectedTimeBlock,
    setIsDatepickerOpen,
    setSelectedTimeBlock,
    updateSpecificeTimeBlock,
    socialInsights
}) => {
    return (
        <Flex
            width="45%"
            flexDirection="column"
            style={{ height: !isEmpty(timeBlocks) && timeBlocks.filter(t => t.id === item.id)[0]?.schedule === 'specific_time' ? '180px' : '140px', borderRadius: '4px', border: `4px solid ${ColorPallete.mediumPink}` }}
        >
            <Flex 
                width="100%" 
                padding="10px 10px 0px"
                fontSize={Typography.TextSmall}
                style={{ fontFamily: "Poppins-semibold"}}
                color={ColorPallete.primaryBlue} 
                backgroundColor={ColorPallete.mediumPink}
            >   
                Did you Know?
            </Flex>
            <Flex 
                width="100%" 
                padding="5px 10px 10px" 
                fontSize={Typography.TextSmall} 
                color={ColorPallete.primaryBlue} 
                backgroundColor={ColorPallete.mediumPink}
            >
                Optimal time for social posts is on <span style={{ fontFamily: 'Poppins-semibold', padding: '0px 3px' }}>{socialInsights?.most_popular_day}s</span> at <span style={{ fontFamily: 'Poppins-semibold', padding: '0px 3px' }}>{socialInsights?.most_popular_time}</span>
            </Flex>
            <Flex
                width="100%"
                padding="5px 5px 5px"
                color="#101828"
                style={{ fontSize: '13px' }}
            >
                <RadioOnetimeAndSeriesInput
                    type="radio"
                    name={`send_immediate_${item.id}`}
                    id={`send_immediate_${item.id}`}
                    style={{ cursor: 'pointer' }}
                    checked={!isEmpty(timeBlocks) && timeBlocks.filter(t => t.id === item.id)[0]?.schedule === 'immediate'}
                    onClick={() => changeTimeSlots(item, 'immediate')}
                    disabled={item?.status !== 'draft' && item?.status !== 'compliance_rejected'}
                />
                <label
                    style={{
                        cursor: 'pointer',
                        margin: '0px 4px',
                        letterSpacing: '0.35px',
                    }}
                    onClick={() => (item?.status !== 'draft' && item?.status !== 'compliance_rejected') ? {} : changeTimeSlots(item, 'immediate')}
                >
                    Send Immediately
                </label>
            </Flex>
            <Flex width="100%" flexDirection="column" padding="5px 5px 3px">
                <Flex
                    width="100%"
                    color="#101828"
                    style={{ fontSize: '13px' }}
                >
                    <RadioOnetimeAndSeriesInput
                        type="radio"
                        name={`send_specific_time_${item.id}`}
                        id={`send_specific_time_${item.id}`}
                        style={{ cursor: 'pointer' }}
                        onClick={() => changeTimeSlots(item, 'specific_time')}
                        checked={!isEmpty(timeBlocks) && timeBlocks.filter(t => t.id === item.id)[0]?.schedule === 'specific_time'}
                        disabled={item?.status !== 'draft' && item?.status !== 'compliance_rejected'}
                    />
                    <label
                        style={{
                            cursor: 'pointer',
                            margin: '0px 4px',
                            letterSpacing: '0.35px',
                        }}
                        onClick={() => (item?.status !== 'draft' && item?.status !== 'compliance_rejected') ? {} : changeTimeSlots(item, 'specific_time')}
                    >
                        Schedule Date & Time
                    </label>
                </Flex>
                {!isEmpty(timeBlocks) && !isEmpty(timeBlocks.filter(t => t.id === item.id && t.schedule === "specific_time")) && (
                    <Box
                        width="56%"
                        mt="4px"
                        ml="5px"
                        style={{
                            border: '1px solid #E4E7EC',
                            borderRadius: '4px',
                            height: '35px',
                            boxShadow:
                                '1px 2px 2px 0px rgba(16, 24, 40, 0.12) inset',
                        }}
                    >
                        <DateTimeWrapper
                            style={{
                                padding: '2px 5px',
                                height: '32px',
                            }}
                        >
                            <DateTimeText
                                style={{
                                    width: '86%',
                                    display: 'flex',
                                    justifyContent: 'flex-start',
                                    paddingLeft: '8px',
                                }}
                                contenteditable="true"
                                data-placeholder="MM/DD/YYYY, 00:00"
                            >
                                {!isEmpty(timeBlocks) && !isEmpty(timeBlocks.filter(t => t.id === item.id && t.schedule === "specific_time")) && timeBlocks.filter(t => t.id === item.id)[0]?.schedule_time && (convertDateToRequiredFormat({
                                    date: !isEmpty(timeBlocks) && !isEmpty(timeBlocks.filter(t => t.id === item.id && t.schedule === "specific_time")) && timeBlocks.filter(t => t.id === item.id)[0]?.schedule_time,
                                    format: 'MM/DD/YYYY, h:mm a',
                                }) + ' (' + timeZoneAbbreviated(get(
                                    userData,
                                    'details.user.timezone',
                                    ''
                                )) + ')')}
                            </DateTimeText>
                            <img
                                alt="date"
                                src="/assets/images/Campaign/date&time-picker-icon.svg"
                                style={{ cursor: 'pointer' }}
                                onClick={() => {
                                    if (!isEmpty(timeBlocks) && timeBlocks.filter(t => t.id === item.id)[0]?.schedule === 'specific_time' && (item?.status === 'draft' || item?.status === 'compliance_rejected')) {
                                        setIsDatepickerOpen(true);
                                        setSelectedTimeBlock(item.id)
                                    }
                                }}
                            />
                            {isDatepickerOpen && selectedTimeBlock === item.id && (
                                <DatePickerV2
                                    isOpen={isDatepickerOpen}
                                    customInput={<div />}
                                    minDate={new Date()}
                                    onDateClose={() => {
                                        setIsDatepickerOpen(false);
                                        setSelectedTimeBlock();
                                    }}
                                    onSubmit={(date) => {
                                        updateSpecificeTimeBlock(date, item.id)
                                    }}
                                    timeZone={get(
                                        userData,
                                        'details.user.timezone',
                                        ''
                                    )}
                                    scheduleValue={!isEmpty(timeBlocks) && timeBlocks.find(t => t.id === item.id)?.schedule_time}
                                    //isFromAC
                                    //loading={isLoading}
                                    hourAndMins
                                />
                            )}
                        </DateTimeWrapper>
                    </Box>
                )}
            </Flex>

        </Flex>
    )
}

export default SocialDateBlock;