import React from 'react';
import {
  ListContainer,
  ListItem,
  ImageBox,
  Image,
  ListContent,
} from './ArticleList.styles';

const ArticleList = ({ imgsrc, title, handleClick, showDefault, showCursor, width }) => {
  console.log(imgsrc);
  return (
    <ListContainer title={title} onClick={handleClick} showDefault={showDefault} showCursor={showCursor} width={width}>
      <ListItem>
        <ImageBox>
          <Image src={imgsrc} alt={title} />
        </ImageBox>
        <ListContent>{title}</ListContent>
      </ListItem>
    </ListContainer>
  );
};

export default ArticleList;
