import apiInstance from 'src/services/api';
import { objectToFormData } from 'src/services/helpers';

export const cascade = async ({ article, skipCompliance = false }) => {
  const payload = {
    title: article?.name,
    skip_compliance: skipCompliance,
    // summary: {"short":"This is the introduction for Bond Market","medium":"This is the introduction for Bond Market","long":"This is the introduction for Bond Market"}
    message: article?.description,
    publish_status: 'published',
    internal_content: false,
    approved_to_share: true,
  };

  const contentResponse = await apiInstance().post(
    `/channel/content/${article?.id}`,
    objectToFormData({ ...payload })
  );
  return contentResponse;
};

export const deleteArticle = async (articleId) => {
  const response = await apiInstance().delete(`/channel/content/${articleId}`);
  return response;
};
