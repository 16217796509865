import styled from 'styled-components';
import { colors } from '../../styles';

export const ButtonIcon = styled('img')`
    height: 20px;
    width: 20px;
    margin: 2px 4px 0 0;
`;

export const ArrowIcon = styled('img')`
    height: 12px;
    width: 12px;
    margin: 7px 0 0 12px;
    transition: 0.75s;
    transform: ${({ selected }) => selected ? 'rotate(180deg)' : 0};
`;

export const ButtonText = styled('div')`
    margin: 2px 0 0;
    padding: 0 4px;
`;

export const DropdownContainer = styled('div')`
    border: 1px solid #E4E7EC;
    box-shadow: 0px 4px 8px rgba(16, 24, 40, 0.1);
    border-radius: 8px;
    position: absolute;
    top: 155px;
    background: white;
    transition: 0.5s;
    display: table;
    z-index: 99999;
    width: fit-content;
    padding: 0px 0px 10px;
    right: 7%;
    color: ${colors.black_01};
`;

export const CalendarContainer = styled('div')`
    width: 320px;
    height: 320px;
    margin: 0px 0 10px;
    overflow: hidden;
    border-bottom: 1px solid ${colors.gray_border};
    border-right: ${({ showRightBorder }) => showRightBorder ? `1px solid ${colors.gray_border}` : 0};
`;

export const TextInput = styled('input')`
    width: 160px;
    height: 40px;
    background: #FCFDFD;
    padding: 0 10px;
    border: 1px solid #E4E7EC;
    box-shadow: inset 1px 2px 2px rgba(16, 24, 40, 0.12);
    border-radius: 4px;
    color: #667085;
    margin: 0 0 4px;
`;

