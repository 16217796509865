import React from 'react';
import { Flex } from '@rebass/grid';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { useHistory } from 'react-router-dom'
import { getChannelId, getUserRole, getRoleType } from '../../../redux/selector';

import { ButtonNoBorderBackground, StyledHeading } from '../../../components';
import { AddMemberFlex } from './Membership.styles';
import { Button } from '../../../components/index';

import { ROLE_TYPES, roleType } from '../../../utils/index'


const AddMember = ({ onAddMember, allowInvite, userData, isEnterpriseAdvisor, isComplianceAdmin }) => {
  const canAddMembers = get(userData, 'details.user.member_settings.add_members');
  
const history = useHistory();
const goToProfilePage = (index) => {
  window.sessionStorage.removeItem('selectedIndex');
    history.push('/admin/configure');
  window.sessionStorage.setItem('selectedIndex', index);

  
}
  // console.log('canAddMembers', canAddMembers);
  const onAddMemberClick = () => {
    if (allowInvite) {
      onAddMember();
    }
  };
  return (
    <AddMemberFlex
      width={1}
      flexDirection="column"
      height="160px"
      alignItems="center"
      justifyContent="center"
    >
      {(canAddMembers === 'true' || isEnterpriseAdvisor || isComplianceAdmin) ?
      (<><ButtonNoBorderBackground disabled={isComplianceAdmin} title={(canAddMembers==='true' || isEnterpriseAdvisor === true) ? 'Add Members' : 'Can\'t add members'} onClick={onAddMemberClick}>
        <img
          src="/assets/images/Member.svg"
          alt="Add member button"
          height="75px"
          width="75px"
        />
      </ButtonNoBorderBackground>
      {/* <StyledHeading fontSize='10px'>Add Members</StyledHeading> */}
      </>)
      :
      <Button onClick={() => {goToProfilePage(4)}}>Enable Membership</Button>
    }
    
      
    </AddMemberFlex>
  );
};


const mapStateToProps = (state, ownProps) => {
  const roleType = getRoleType(state);
  const isEnterpriseAdvisor = roleType === ROLE_TYPES.ADMIN_ENTERPRISE;
  const isComplianceAdmin = roleType === ROLE_TYPES.ADMIN_COMPLIANCE;

  // console.log('isComplianceAdmin: ', isComplianceAdmin)
  return {
    userData: state.user.data,
    isEnterpriseAdvisor,
    isComplianceAdmin
  };
};

export default connect(mapStateToProps, null)(AddMember);
