import { Flex, Box } from '@rebass/grid';
import { isEmpty } from 'lodash';
import React from 'react';
import CampaignInfoBlock from '../CampaignInfo/CampaignInfoBlock';
import CampaignPlatforms from '../CampaignPlatforms/CampaignPlatforms';
import CampaignPostInfo from '../CampaignPostInfo/CampaignPostInfo';
import CampaignTopics from '../CampaignTopics/CampaignTopics';
import {
  VerticalSeparator,
  HorizontalSeparator,
} from './DripDashboardBlockItem.styles';
import { getMediaImage, onImageError } from '../../../../utils';

const DripDashboardBlockItem = ({
  name,
  status,
  email,
  facebook,
  twitter,
  linkedin,
  themes,
  isFirst,
  start_date,
  duration,
  frequency,
  target,
  socialThemes,
  posts,
  id,
  setSelectedViewTab,
  setDetailsView,
  campaignDetailsRequest,
  socialNetworkData,
  detailsHandler,
  media_urls,
}) => {
  const statusColorCodes = {
    completed: '#33B21B',
    scheduled: '#00CCF2',
    draft: '#FFAA00',
    in_progress: '#326DE6',
  };
  // console.log('posts', posts);
  const getPlatformData = () => {
    let platforms = [];
    if (email && !isEmpty(email)) {
      platforms.push('email');
    }
    if (facebook && !isEmpty(facebook)) {
      platforms.push('facebook');
    }
    if (twitter && !isEmpty(twitter)) {
      platforms.push('twitter');
    }
    if (linkedin && !isEmpty(linkedin)) {
      platforms.push('linkedin');
    }

    return platforms;
  };

  const postinfo = {
    postsPerWeek: posts.week,
    totalPosts: posts.total,
    scheduled: posts.scheduled,
    needContent: posts.draft,
    completed: posts.completed,
    failed: posts.failed,
    missed: posts.missed,
  };

  return (
    <Flex
      justifyContent="flex-start"
      display="flex"
      flexDirection="column"
      alignItems="center"
      width="100%"
      style={{
        border: '1px solid #DBE0E6',
        borderRadius: '4px',
        // borderRight: `6px solid ${statusColorCodes[status]}`,
        // margin: isFirst ? '0px 0px 10px' : '10px 0px',
        backgroundColor: '#fff',
        cursor: 'pointer',
      }}
      onClick={() => {
        setSelectedViewTab(1);
        campaignDetailsRequest({ id: id });
        setDetailsView(true);
        detailsHandler(id);
      }}
    >
      <img
        alt=""
        src={getMediaImage(media_urls)}
        onError={onImageError}
        width="100%"
        height="150px"
        style={{ position: 'relative' }}
      />
      <Flex
        style={{
          // border: '1px solid #DBE0E6',
          borderRadius: '4px',
          padding: '10px',
          marginTop: '3px',
          // margin: isFirst ? '0px 0px 10px' : '10px 0px',
          backgroundColor: 'rgb(0,0,0,0.45)',
          position: 'absolute',
          color: '#fff',
          width: 'calc(18% - 5px)',
          textAlign: 'left',
          fontSize: '12px',
          wordBreak: 'break-word',
        }}
      >
        {/* {(status.charAt(0).toUpperCase() + status.slice(1)).replace('-', ' ')}{' '}
        Campaign - */}
        {name}
      </Flex>
      <Flex
        style={{
          borderBottom: `6px solid ${statusColorCodes[status]}`,
          boxShadow: '0px 2px 14px -6px black',
          borderRadius: '4px',
          padding: '10px',
          marginTop: '100px',
          position: 'absolute',
          backgroundColor: 'rgb(255,255,255)',
          color: '#fff',
          width: 'calc(16% - 5px)',
          fontSize: '12px',
        }}
      >
        <CampaignInfoBlock
          target={target}
          title={name}
          status={status}
          start_date={start_date}
          duration={duration}
          frequency={frequency}
        />
      </Flex>
      <Flex
        style={{
          marginTop: '120px',
        }}
      />
      <Flex
        style={{
          justifyContent: 'space-between',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'flex-start',
          marginBottom: '15px',
        }}
      >
        <CampaignPlatforms platforms={getPlatformData()} displayMode="block" />
        <VerticalSeparator height="50px" />
        <CampaignTopics
          topics={themes}
          socialThemes={socialThemes}
          displayMode="block"
        />
      </Flex>

      <HorizontalSeparator width="calc(90% - 5px)" />
      <Flex
        //   justifyContent="flex-start"
        alignItems="center"
        width="100%"
        marginBottom="15px"
        marginTop="15px"
        paddingX="5px"
      >
        <CampaignPostInfo postInfo={postinfo} id={id} displayMode="block" />
      </Flex>
    </Flex>
  );
};

export default DripDashboardBlockItem;
