import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { textBoxes } from '../constants';
import { get, isEmpty } from 'lodash';
import { getQuotedText, replaceSpanWithQuate } from '../../../utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { EditableContent } from '../../';
import {
  ShareSocialDiv,
  PostRejectUl,
  PostRejectListItem,
  SocialBox,
  SocialIconSpan,
  SocialIconI,
  MdlSummary,
  FeedbackDiv,
  TextArea,
  ErrorMsg,
} from '../ShareContentContainer.styles';
import { ResponsiveImg } from '../../CommonStyles';
import { getNewsletterDetails } from '../../../services/newsletter/index';
import { actions as newsLetterActions } from '../../../redux/modules/newsletter.module';
import {
  formatNewsletterDropdownItem,
  getBucketArticleData,
  getMediaUrl,
  themesData,
} from '../../../utils';
import ComplianceReviewContainer from './ComplianceReviewContainer/ComplianceReviewContainer';
import PreviewNewsletterModal from '../../../containers/NewsletterContainer/NewsletterModals/PreviewNewsletterModal';
import EmailSummary from './EmailSummary';
import CustomizeEmail from '../customizeEmail';
import { Flex } from '@rebass/grid';

const SummaryFields = ({
  channels,
  rest,
  editable,
  allSumarries,
  getPlaceHolder,
  placeholerText,
  summaryData,
  setSummaryData,
  isFetchingSummary,
  isFromCompliance,
  hasFeedBack,
  platformObj,
  isFromAddContent,
  fromMyClients,
  isNewsLetter,
  newsLetter_id,
  complianceTab,
  newsletterSetState,
  emailTextData,
  articleData,
  image,
  isComplianceAdmin,
  backToPreviousScreen,
  summaryText,
  userData,
  setEmailTextData,
  articleId,
  pdfImage,
  setIsContentChanged,
  engagementId,
  isContentChanged,
  setEmailShareData,
  emailShareData,
  selectedItem,
  complianceCustomisedData,
}) => {
  const div1 = [];
  const div2 = [];

  useEffect(() => {
    let id = newsLetter_id;
    if (isNewsLetter) {
      getNewsletterDetails({ id })
        .then((res) => {
          if (res.result.success) {
            const data = get(res, 'result.data', {});
            // console.log('CUSTOMIZED DATA - - - - -', data.customized_data);
            newsletterSetState({
              newsletterName: data && data.name && data.name,
              isEditMode: true,
              newsletterId: newsLetter_id,
              scheduledTime: '',
              selectedContacts: [],
              tab: '',
              emailTemplateSelected: data && data.template,
              newsletterArticles:
                data && data.articles && getBucketArticleData(data.articles),
              customData: {
                subject:
                  data && data.customized_data && data.customized_data.subject,
                salutation:
                  data &&
                  data.customized_data &&
                  data.customized_data.salutation,
                salutation_text:
                  data &&
                  data.customized_data &&
                  formatNewsletterDropdownItem(
                    data.customized_data.salutation_text
                  ),
                message:
                  data && data.customized_data && data.customized_data.message,
                media1:
                  data &&
                  data.customized_data &&
                  data.customized_data.media_urls &&
                  !isEmpty(data.customized_data.media_urls) &&
                  getMediaUrl(data.customized_data.media_urls, '', 'original'),
                engagementId:
                  data &&
                  data.engagement_details &&
                  data.engagement_details[0] &&
                  data.engagement_details[0].id,
                title:
                  data && data.customized_data && data.customized_data.title,
              },
            });
          }
        })
        .catch((err) => console.log('SUMMARY FIELDS ERROR - ', err));
    }
  }, []);
  console.log('[CHANNELS] - ', channels);
  // console.log('[NEWSLETTER ID] - ', newsLetter_id);
  channels && channels.length > 0 && channels[0] && channels.map((platform, index) => {
    // console.log(platform);
    // console.log(textBoxes[platform].image);
    if (index < 2) {
      div1.push(
        <PostRejectUl isFromAddContent={true} key={platform}>
          <PostRejectListItem>
            <SocialBox
              borderColor={get(textBoxes[platform], 'color', '')}
              style={{
                height: '73px',
                width: '260px',
                padding: '6px 3px 1px 10px',
              }}
            >
              <SocialIconSpan>
                <SocialIconI background={get(textBoxes[platform], 'color', '')}>
                  {platform === 'it' ? (
                    <ResponsiveImg
                      src={get(textBoxes[platform], 'image', '')}
                    />
                  ) : (
                    <FontAwesomeIcon
                      icon={get(textBoxes[platform], 'icon', '')}
                    />
                  )}
                </SocialIconI>
              </SocialIconSpan>
              <MdlSummary>
                {rest.isFromAdmin || editable ? (
                  <EditableContent
                    maxLength={textBoxes[platform].editableContent.maxLength}
                    height="60px"
                    sumarryOptions={getQuotedText(
                      get(
                        allSumarries,
                        textBoxes[platform].editableContent.summaryOption,
                        []
                      )
                    )}
                    placeholder={getPlaceHolder(
                      platform,
                      placeholerText,
                      fromMyClients
                    )}
                    content={summaryData[`${platform}Summary`]}
                    isComplianceSummary={rest.isFromAdmin}
                    onChange={(html, text) =>
                      setSummaryData({
                        ...summaryData,
                        [`${platform}Summary`]: replaceSpanWithQuate(
                          html,
                          platform
                        ),
                        [`${platform}Length`]: text.length,
                      })
                    }
                    onBlur={(html, text) =>
                      setSummaryData({
                        ...summaryData,
                        [`${platform}Summary`]: replaceSpanWithQuate(
                          html,
                          platform
                        ),
                        [`${platform}Length`]: text.length,
                      })
                    }
                    shortSummary={summaryData[`${platform}Summary`]}
                  />
                ) : isFetchingSummary ? (
                  <center>{placeholerText}</center>
                ) : (
                  summaryData[`${platform}Summary`]
                )}
              </MdlSummary>
            </SocialBox>
            {summaryData[`${platform}Length`] ===
              textBoxes[platform].editableContent.maxLength && (
              <ErrorMsg>
                Character limit for {platformObj[platform]} reached
              </ErrorMsg>
            )}
          </PostRejectListItem>
        </PostRejectUl>
      );
    } else {
      div2.push(
        <PostRejectUl isFromAddContent={true} key={platform}>
          <PostRejectListItem>
            <SocialBox
              borderColor={textBoxes[platform].color}
              style={{
                height: '73px',
                width: '260px',
                padding: '6px 3px 1px 10px',
              }}
            >
              <SocialIconSpan>
                <SocialIconI background={textBoxes[platform].color}>
                  {platform === 'it' ? (
                    <ResponsiveImg src={textBoxes[platform].image} />
                  ) : (
                    <FontAwesomeIcon icon={textBoxes[platform].icon} />
                  )}
                </SocialIconI>
              </SocialIconSpan>
              <MdlSummary>
                {rest.isFromAdmin || editable ? (
                  <EditableContent
                    maxLength={textBoxes[platform].editableContent.maxLength}
                    height="60px"
                    sumarryOptions={getQuotedText(
                      get(
                        allSumarries,
                        textBoxes[platform].editableContent.summaryOption,
                        []
                      )
                    )}
                    placeholder={getPlaceHolder(platform, placeholerText)}
                    content={summaryData[`${platform}Summary`]}
                    isComplianceSummary={rest.isFromAdmin}
                    onChange={(html, text) =>
                      setSummaryData({
                        ...summaryData,
                        [`${platform}Summary`]: replaceSpanWithQuate(
                          html,
                          platform
                        ),
                        [`${platform}Length`]: text.length,
                      })
                    }
                    onBlur={(html, text) =>
                      setSummaryData({
                        ...summaryData,
                        [`${platform}Summary`]: replaceSpanWithQuate(
                          html,
                          platform
                        ),
                        [`${platform}Length`]: text.length,
                      })
                    }
                    shortSummary={summaryData[`${platform}Summary`]}
                  />
                ) : isFetchingSummary ? (
                  <center>{placeholerText}</center>
                ) : (
                  summaryData[`${platform}Summary`]
                )}
              </MdlSummary>
            </SocialBox>
            {summaryData[`${platform}Length`] ===
              textBoxes[platform].editableContent.maxLength && (
              <ErrorMsg>
                Character limit for {platformObj[platform]} reached
              </ErrorMsg>
            )}
          </PostRejectListItem>
        </PostRejectUl>
      );
    }
  });

  const [isSummaryFieldPreviewClick, setIsSummaryFieldPreviewClick] =
    React.useState(false);
  const summaryFieldPreviewClick = () => {
    setIsSummaryFieldPreviewClick(true);
  };

  const handleSummary = (newValue) => {
    setIsContentChanged(true);
    setSummaryData({
      ...summaryData,
      emSummary: newValue,
    });
  };

  const handleSocialSummaryChange = (html, text, platform) => {
    setIsContentChanged(true);
    setSummaryData({
      ...summaryData,
      [`${platform}Summary`]: replaceSpanWithQuate(html, platform),
      [`${platform}Length`]: text.length,
    });
  };

  // console.log('[COMPLIANCE TAB] - ', complianceTab);
  return (
    <ShareSocialDiv
      isFromAddContent={isFromAddContent}
      fromMyClients={fromMyClients}
      height={
        isFromCompliance
          ? isNewsLetter
            ? '390px'
            : '410px'
          : isNewsLetter
          ? '367px'
          : null
      }
    >
      {/* {console.log('here3', {rest, summaryData, allSumarries, isFromAddContent})} */}
      {isFromAddContent ? (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {div1}
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {div2}
          </div>
        </div>
      ) : (
        channels.map((platform) => (
          <PostRejectUl isFromAddContent={false} key={platform}>
            <PostRejectListItem>
              <SocialBox borderColor={platform ? textBoxes[platform].color : '#000'}>
                <SocialIconSpan>
                  <SocialIconI
                    background={platform ? textBoxes[platform].color : '#fff'}
                    fromMyClients={fromMyClients}
                    fromCompliance={isFromCompliance}
                  >
                    {platform === 'it' ? (
                      <ResponsiveImg src={platform ? textBoxes[platform].image : ''} />
                    ) : platform && (
                      <FontAwesomeIcon icon={textBoxes[platform].icon} />
                    )}
                  </SocialIconI>
                </SocialIconSpan>
                {isNewsLetter ? (
                  <ComplianceReviewContainer complianceTab={complianceTab} />
                ) : isFromCompliance &&
                  isComplianceAdmin &&
                  platform === 'em' ? (
                  <div>
                    <EmailSummary
                      summaryData={summaryData}
                      emailTextData={emailTextData}
                      articleData={articleData}
                      image={image}
                      engagementId={engagementId}
                      isFromCompliance={isFromCompliance}
                      isSummaryFieldPreviewClick={isSummaryFieldPreviewClick}
                      summaryText={summaryData.emSummary}
                      setIsSummaryFieldPreviewClick={
                        setIsSummaryFieldPreviewClick
                      }
                      isComplianceAdmin={isComplianceAdmin}
                      complianceCustomisedData={complianceCustomisedData}
                    />
                    <div
                      style={{
                        margin: '0px 20px 6px',
                        color: '#6351ed',
                        marginTop: '10px',
                      }}
                    >
                      Summary
                    </div>
                    <div
                      style={{
                        border: '0px solid gray',
                        borderRadius: '10px',
                        paddingTop: '8px',
                        backgroundColor: '#e4e4e4',
                        marginLeft: '20px',
                        marginRight: '10px',
                        paddingLeft: '10px',
                        height: '100px',
                        overflowY: 'auto',
                        marginBottom: '10px',
                        paddingBottom: '8px',
                      }}
                    >
                      {summaryData.emSummary}
                    </div>

                    <Flex justifyContent="center">
                      <button
                        style={{
                          width: 'auto',
                          height: 'auto',
                          padding: '8px 15px',
                          border: '1px solid #6351ed',
                          cursor: 'pointer',
                          margin: '12px 0 12px 0',
                          background: '#ffffff',
                          color: themesData.purpleishBlue,
                        }}
                        onClick={summaryFieldPreviewClick}
                      >
                        Preview
                      </button>
                    </Flex>
                  </div>
                ) : isFromCompliance &&
                  !isComplianceAdmin &&
                  platform === 'em' ? (
                  <div>
                    <CustomizeEmail
                      backToPreviousScreen={backToPreviousScreen}
                      summaryText={summaryData.emSummary}
                      userData={userData}
                      articleData={articleData}
                      setEmailTextData={setEmailTextData}
                      articleId={articleId}
                      pdfImage={pdfImage}
                      setIsContentChanged={setIsContentChanged}
                      isButtonVisible={true}
                      isFromCompliance={isFromCompliance}
                      engagementId={engagementId}
                      isSummaryFieldPreviewClick={isSummaryFieldPreviewClick}
                      setIsSummaryFieldPreviewClick={
                        setIsSummaryFieldPreviewClick
                      }
                      isComplianceAdmin={isComplianceAdmin}
                      isContentChanged={isContentChanged}
                      setEmailShareData={setEmailShareData}
                      emailShareData={emailShareData}
                      image={image}
                      selectedItem={selectedItem}
                      complianceCustomisedData={complianceCustomisedData}
                    />
                    <div
                      style={{
                        margin: '0px 20px 6px',
                        color: '#6351ed',
                        marginTop: '-19px',
                      }}
                    >
                      Summary
                    </div>
                    <TextArea
                      style={{
                        border: '1px solid lightgray',
                        borderRadius: '10px',
                        paddingTop: '8px',
                        backgroundColor: '#ffffff',
                        marginLeft: '20px',
                        marginRight: '10px',
                        paddingLeft: '15px',
                        height: '100px',
                        overflowY: 'auto',
                        marginBottom: '10px',
                        paddingBottom: '8px',
                        width: '93%',
                      }}
                      defaultValue={summaryData.emSummary}
                      onChange={(e) => handleSummary(e.target.value)}
                    ></TextArea>

                    <Flex justifyContent="center">
                      <button
                        style={{
                          width: 'auto',
                          height: 'auto',
                          padding: '8px 15px',
                          border: '1px solid #6351ed',
                          cursor: 'pointer',
                          margin: '12px 0 12px 0',
                          background: '#ffffff',
                          color: themesData.purpleishBlue,
                        }}
                        onClick={summaryFieldPreviewClick}
                      >
                        Preview
                      </button>
                    </Flex>
                  </div>
                ) : (
                  <MdlSummary>
                    {rest.isFromAdmin || editable ? (
                      <EditableContent
                        isFetchingSummary={isFetchingSummary}
                        maxLength={
                          platform ? textBoxes[platform].editableContent.maxLength : 500
                        }
                        height="auto"
                        sumarryOptions={getQuotedText(
                          platform ? get(
                            allSumarries,
                            textBoxes[platform].editableContent.summaryOption,
                            []
                          ) : []
                        )}
                        placeholder={platform ? getPlaceHolder(
                          platform,
                          placeholerText,
                          fromMyClients
                        ) : ''}
                        content={platform ? summaryData[`${platform}Summary`] : ''}
                        isComplianceSummary={rest.isFromAdmin}
                        onChange={(html, text) =>
                          handleSocialSummaryChange(html, text, platform)
                        }
                        onBlur={(html, text) =>
                          handleSocialSummaryChange(html, text, platform)
                        }
                        shortSummary={platform ? summaryData[`${platform}Summary`] : ''}
                        setIsContentChanged={setIsContentChanged}
                      />
                    ) : isFetchingSummary ? (
                      <center>{placeholerText}</center>
                    ) : (
                      platform ? summaryData[`${platform}Summary`] : ''
                    )}
                  </MdlSummary>
                )}
              </SocialBox>
              {/* {isFromCompliance && (!isComplianceAdmin || isComplianceAdmin) && */}
              {platform && summaryData[`${platform}Length`] ===
                textBoxes[platform].editableContent.maxLength && (
                <ErrorMsg>
                  Character limit for {platformObj[platform]} reached
                </ErrorMsg>
              )}
            </PostRejectListItem>
          </PostRejectUl>
        ))
      )}
      {isFromCompliance && isComplianceAdmin && (
        <>
          <PostRejectUl>
            <PostRejectListItem>
              <SocialBox borderColor="#b7b7b7">
                <MdlSummary>
                  <EditableContent
                    height="auto"
                    placeholder="Please share your feedback on the content"
                    content=""
                    onChange={(e, text) => {
                      console.log('TEXT = = -=  = ', text);
                      setSummaryData({
                        ...summaryData,
                        approveSummary: text,
                      });
                    }}
                  />
                </MdlSummary>
              </SocialBox>
            </PostRejectListItem>
          </PostRejectUl>
        </>
      )}

      {hasFeedBack && (
        <PostRejectUl>
          <PostRejectListItem>
            <FeedbackDiv>
              <TextArea placeholder="Please share your feedback on the content." />
            </FeedbackDiv>
          </PostRejectListItem>
        </PostRejectUl>
      )}
    </ShareSocialDiv>
  );
};

const mapDispatchToProps = {
  newsletterSetState: newsLetterActions.api.newsletter.setstate,
};

export default connect(null, mapDispatchToProps)(SummaryFields);
