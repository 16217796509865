import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Flex } from '@rebass/grid';
import { get } from 'lodash';
import { connect } from 'react-redux';
import { getChannelId, getUserRole, getRoleType } from '../../../redux/selector';
import { DonutChart } from '../../../components';
import { transformDonutData } from '../../../utils';
import { ROLE_TYPES, roleType } from '../../../utils/index'

const ScoreBucket = styled.div`
  font-size: 12px;
  color: #6351ed;
`;

const InsightScore = ({
  analyticsData,
  isAnalyticsDataFetching,
  analyticsObject,
  selectedRepType,
  isComplianceAdmin
}) => {
  const [hoveredSectionName, setHoveredSectionName] = useState('');
  const onSectionHover = (data) => {
    setHoveredSectionName(get(data, 'name'));
  };

  useEffect(() => {
    setHoveredSectionName(get(analyticsData, '[0].name'));
  }, [analyticsData]);

  const disabledStyle = {
    opacity: .5,
    cursor: "not-allowed !important",
  }

  return (
    <Flex height="180px" width={1} flexDirection="column" title={isComplianceAdmin ? "Not Allowed" : ""} style={{ opacity: isComplianceAdmin ? 0.5 : 1}}>
      <DonutChart
        loading={isAnalyticsDataFetching}
        data={transformDonutData(
          get(analyticsObject, `${selectedRepType}.data`, [])
        )}
        colors={[
          '#6351ED',
          '#c95cfc',
          '#6699FF',
          '#7dc4d0',
          '#E2DFFB',
        ]}
        onSectionHover={onSectionHover} 
      />
      {!isAnalyticsDataFetching && (
        <ScoreBucket>Score Bucket: {hoveredSectionName}</ScoreBucket>
      )}
    </Flex>
  );
};


const mapStateToProps = (state, ownProps) => {
  const roleType = getRoleType(state);
  const isEnterpriseAdvisor = roleType === ROLE_TYPES.ADMIN_ENTERPRISE;
  const isComplianceAdmin = roleType === ROLE_TYPES.ADMIN_COMPLIANCE;

  console.log('isComplianceAdmin 2: ', isComplianceAdmin)
  return {
    userData: state.user.data,
    isEnterpriseAdvisor,
    isComplianceAdmin
  };
};

export default connect(mapStateToProps, null)(InsightScore);
