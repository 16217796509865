import styled from 'styled-components';

export const ArticleBox = styled.div`
  padding: 20px;
  padding-top: 0px;
  background: #403b4a;
  background: -webkit-linear-gradient(to bottom, #ffffff, #e3e3e3);
  background: linear-gradient(to bottom, #ffffff, #e3e3e3);
`;
export const ArticleBoxHeader = styled.div`
  background-color: #d8d8d8;
  color: #6351ed;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  font-size: 13px;
  margin: 0;
  padding: 6px 0 5px;
  font-weight: 600;
`;
export const ArticleBoxContent = styled.div`
  // border-bottom-left-radius: 5px;
  // border-bottom-right-radius: 5px;
  border-radius: 10px;
  margin-top: 20px;
  padding-bottom: 0px;
  max-height: 145px;
  vertical-align: middle;
  background-color: #fff;
  color: #6351ed;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 5px 0;
  margin-bottom: 20px;
  display: inline-block;
  justify-content: center;
  align-items: center;
  width: 100%;
  border: 1px solid #e4e4e4;
  // border-top: 0px;
  img {
    filter: grayscale(1);
  }
`;
