/* eslint-disable no-useless-computed-key */
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { ROLE_TYPES } from '../../utils';
import { actions as contactActions } from '../../redux/modules/contacts.module';
import { getRoleType } from '../../redux/selector/login.selectors';
import { get } from 'lodash';
import SwitchProfileModal from './SwitchProfileModal';
import { actions as loginActions } from '../../redux/modules/login.module';
import { actions as campaignActions } from '../../redux/modules/campaign.module';
import { useOktaAuth } from '@okta/okta-react';
import Navbar from 'src/componentsV2/Navbar';

const AdminHeader = ({
  pathName,
  type,
  roleType,
  setSelectedTabIndex,
  userDetails,
  complianceAdmin,
  loginRequest,
  campaignSetState,
}) => {
  const [showSwitchProfile, setShowSwitchProfile] = useState(false);

  const { oktaAuth } = useOktaAuth();
  const history = useHistory();


  // const fetchCloutScore = async () => {
  //   const currentDate = new Date().toISOString().split('T')[0];
  //   var prevDate = new Date();
  //   prevDate.setDate(prevDate.getDate() - 6);
  //   prevDate = prevDate.toISOString().split('T')[0];

  //   try {
  //     const response = await getMioScore(currentDate, prevDate);
  //     const scoreArray =
  //       response && response.result && response.result.data
  //         ? Object.keys(response.result.data.analytics.score)
  //         : [];
  //     let score =
  //       scoreArray.length > 0
  //         ? response.result.data.analytics.score[
  //             scoreArray[scoreArray.length - 1]
  //           ].mine
  //         : 0;
  //     let totalSharedArticles =
  //       response && response.result && response.result.data
  //         ? response.result.data.analytics.total_shares
  //         : 0;
  //     setCloutScore({ score: score, totalSharedArticles: totalSharedArticles });
  //   } catch (err) {
  //     console.log('clout score error:', err);
  //   }
  // };

  const goToProfilePage = (index) => {
    window.sessionStorage.removeItem('selectedIndex');
    setSelectedTabIndex({ selectedTabIndex: index });
    history.push('/admin/configure');
  };

  const switchBackToAdmin = () => {
    let ogToken = window.sessionStorage.getItem('ogToken');
    window.sessionStorage.setItem('token', ogToken);
    window.sessionStorage.removeItem('ogToken');
    window.sessionStorage.removeItem('switch-user');
    loginRequest();
    window.open('/admin/home', '_self');
  };


  let profileItem = {
    title: 'JL',
    children: [
      {
        title: 'Profile and Settings',
        action: () => goToProfilePage(0),
      },
      // {
      //   name: 'Topics',
      //   action: () => history.push('/theme')
      // },
      // {
      //   name: 'Publishers',
      //   action: ()=> history.push('/channel')
      // },
      // {
      //   name: 'Platforms',
      //   action: () => goToProfilePage(1)
      // }
    ],
  };

  // if(!complianceAdmin && checkComplianceAdmin){
  //   const settingsItem = {
  //     name: 'Settings',
  //     action: ()=> goToProfilePage(2)
  //   }
  //   profileItem = {...profileItem, ['children']: [...profileItem.children, settingsItem]}
  // }

  if (!window.sessionStorage.getItem('switch-user')) {
    const manageUserItem = {
      title: 'Manage Users',
      action: () => history.push('/manage-users'),
    };
    profileItem = {
      ...profileItem,
      ['children']: [...profileItem.children, manageUserItem],
    };
  }

  if(get(userDetails, 'details.user.is_impersonator', 'false') ===
  'true' && !window.sessionStorage.getItem('switch-user')){
    const switchProfileItem = {
      title: 'Switch Users',
      action: ()=> setShowSwitchProfile(true)
    }
    profileItem = {...profileItem, ['children']: [...profileItem.children, switchProfileItem]}
  }

  const logoutItem = {
    title: window.sessionStorage.getItem('switch-user')
      ? 'Return to Admin'
      : 'Logout',
    action: async () => {
      if (window.sessionStorage.getItem('switch-user')) {
        switchBackToAdmin();
      } else {
        await oktaAuth.signOut({
          revokeAccessToken: true,
          revokeRefreshToken: true,
          postLogoutRedirectUri: window.location.origin,
        });
        await oktaAuth.closeSession();
        localStorage.clear();
        sessionStorage.clear();
      }
    },
  };

  profileItem = {
    ...profileItem,
    ['children']: [...profileItem.children, logoutItem],
  };

  return (
    <>
    <Navbar
      customLogoUrl={get(userDetails, 'company.extra_settings.logo', '')}
      userRole="ADMIN"
      profileItems={profileItem}
    />

     {showSwitchProfile ? (
        <SwitchProfileModal
          handleModalClose={() => {
            setShowSwitchProfile(false);
          }}
          userData={userDetails}
          loginRequest={loginRequest}
          roleType={roleType}
        />
      ) : null}
    </>
  );
};

const mapStateToProps = (state) => {
  const roleType = getRoleType(state);
  const complianceAdmin = ROLE_TYPES.ADMIN_COMPLIANCE === roleType;
  const assetManagerAdmin = ROLE_TYPES.ADMIN_ASSET_MANAGER === roleType;
  return {
    userDetails: state.user.data,
    complianceAdmin,
    assetManagerAdmin,
    roleType,
  };
};
const mapDispatchToProps = {
  setSelectedTabIndex: contactActions.api.contacts.selectedtabindex,
  loginRequest: loginActions.api.login.request,
  campaignSetState: campaignActions.api.campaign.setstate,
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminHeader);
