import { Flex } from '@rebass/grid';
import React, { useEffect, useState } from 'react';
import { SearchContainer } from '../EmailList/EmailList.styles';
import SearchBar from '../SearchBar';
import { Chip } from '../../../componentsV2/Chip';
import {
  AlphabetScrollerWrapper,
  PublisherCardWrapper,
  StyledCross,
  StyledTitle,
  StyledTitleDiv,
} from '../ManagePublisher/styles';
import { TopicChip } from '../../../componentsV2/TopicChip';
import { get, isEmpty } from 'lodash';
import {
  geYourTheme,
  getPopularTheme,
  subscribeTheme,
  unsubscribeTheme,
} from '../../../services/dashboard';
import { LoaderWrapper } from '../../../components';
import TopicSearch from './TopicSearch';
import TopicListView from './TopicListView';
import MostSubscribedTopic from './MostSubscribedTopic';
import { Tooltip, Zoom } from '@mui/material';

const ManageTopics = () => {
  const [searchInput, setSearchInput] = useState('');
  const [topicAlphabet, setTopicAlphabet] = useState([]);
  const [allTopics, setAllTopics] = useState([]);
  const [myTopics, setMyTopics] = useState([]);
  const [popularTopics, setPopularTopics] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [topicLoader, toggleLoader] = useState('');
  const [searchTopicLoader, toggleSearchTopicLoader] = useState('');
  const [popularTopicLoader, togglePopularTopicLoader] = useState('');
  const [browseTopicLoader, toggleBrowseTopicLoader] = useState('');

  // useEffect(() => {
  //   let alphabeticOrder = [];
  //   let i;
  //   console.log('Alphabets form (A-Z) are:');

  //   // Using for loop for (A-Z):
  //   for (i = 65; i <= 90; i++) {
  //     console.log(String.fromCharCode(i));
  //     alphabeticOrder.push(String.fromCharCode(i));
  //   }
  //   setTopicAlphabet(alphabeticOrder);
  // }, []);

  useEffect(() => {
    let allTopics = [];
    geYourTheme()
      .then((response) => {
        allTopics = get(response, 'result.data', []);
        setAllTopics(allTopics);
        const yourThemeResponse = allTopics.filter(
          (theme) =>
            get(theme, 'subscription.status.application_status') === 'approved'
        );
        if (yourThemeResponse.length > 0) {
          // if (newThemeList.length > 15) {
          //     newThemeList.pop();
          // }
          setMyTopics(yourThemeResponse);
        } 
        return getPopularTheme();
      })
      .then((response) => {
        setPopularTopics(get(response, 'result.data', []));
        //return getTrendingTopics()
      })
      // .then(response => {
      //     let trendingTopics = get(response, 'result.data.topics', []);
      //     if(trendingTopics.length>0)
      //         trendingTopics = trendingTopics.map(topic => allTopics.find(ele => ele.id===topic.id))
      //     setTrendingTopics(trendingTopics);
      // })
      .catch((err) => console.log(err));
  }, []);

  const shortenTitle = (title) => {
    if (title.length > 11) return title.substr(0, 11) + '...';
    else return title;
  };

  const shortenListViewTitle = (title) => {
    if (title.length > 25) return title.substr(0, 25) + '...';
    else return title;
  };

  const unsubscribeHandler = (topic) => {
    setErrorMessage('');
    toggleLoader(topic.name);
    unsubscribeTheme(topic.id)
      .then((response) => {
        toggleLoader('');
        let topics = myTopics.filter((top) => top.id !== topic.id);
        setMyTopics(topics);
      })
      .catch((err) => {
        toggleLoader('');
        setErrorMessage('*An error Occurred...please try again.');
      });
  };

  const subscribeHandler = (topic) => {
    setErrorMessage('');
    let newTopicList = myTopics.filter(
      (topic) => topic.name !== 'Featured Topics'
    );
    if (newTopicList.length <= 15) {
      let duplicateTopic = !myTopics.every((top) => top.id !== topic.id);
      if (!duplicateTopic) {
        subscribeTheme(topic.id)
          .then((response) => {
            toggleLoader('');
            toggleBrowseTopicLoader('');
            togglePopularTopicLoader('');
            toggleSearchTopicLoader('');
            let topics = [...myTopics];
            let addedTopic = allTopics.find((ele) => ele.name === topic.name);
            let insertIndex = -1;
            topics.every((top, index) => {
              if (top.name > addedTopic.name) insertIndex = index;
              return top.name < addedTopic.name;
            });
            topics.splice(insertIndex, 0, addedTopic);
            setMyTopics(topics);
          })
          .catch((err) => {
            toggleLoader('');
            toggleBrowseTopicLoader('');
            togglePopularTopicLoader('');
            toggleSearchTopicLoader('');
            setErrorMessage('An error Occurred...please try again.');
          });
      } else {
        setErrorMessage('*You are already subscribed to this topic.');
        togglePopularTopicLoader('');
        toggleBrowseTopicLoader('');
        toggleSearchTopicLoader('');
      }
    } else {
      togglePopularTopicLoader('');
      toggleBrowseTopicLoader('');
      toggleSearchTopicLoader('');
      setErrorMessage(
        '*You have subscribed to maximum number of topics allowed(15).'
      );
    }
  };

  const handleTopicSearch = () => {
    console.log('clicked');
  };
  return (
    <Flex
      display="flex"
      width="100%"
      alignItems="center"
      flexDirection="column"
    >
      <Flex display="flex" width="100%" flexDirection="row" marginBottom="20px">
        <Flex
          display="flex"
          width="70%"
          height="240px"
          flexDirection="column"
          style={{
            border: '1px solid #e4e7ec',
            boxShadow: '0px 1px 2px 0px #1018281F',
            borderRadius: '4px',
            marginRight: '20px',
          }}
        >
          <Flex
            display="flex"
            justifyContent="flex-start"
            padding="10px 15px"
            style={{ color: '#101828', fontSize: '20px' }}
          >
            My Topics
          </Flex>
          <Flex height="220px">
            <PublisherCardWrapper
              style={{
                flexWrap: 'wrap',
                justifyContent: 'start',
                padding: '3px 15px',
                maxHeight: '135px',
                alignContent: 'flex-start',
              }}
            >
              {myTopics.length > 0
                ? myTopics.map((topic, index) => (
                    <TopicChip height="30px" boxShadow="none">
                      <Flex
                        display="flex"
                        padding="5px"
                        style={{
                          color: '#0B5CD7',
                          fontSize: '12px',
                          marginRight: '5px',
                          alignItems: 'center',
                        }}
                      >
                        <LoaderWrapper isLoading={topicLoader === topic.name}>
                          {topic?.name && topic?.name?.length > 11 ? (
                            <Tooltip
                            TransitionComponent={Zoom}
                            arrow
                            placement="top"
                            title={topic.name}
                            componentsProps={{
                              tooltip: {
                                sx: {
                                  fontFamily: 'Poppins',
                                },
                              },
                            }}
                          >
                            <p style={{ color: '#0B5CD7'}}>{shortenTitle(topic.name)}</p>
                          </Tooltip>
                          ) : (
                            topic?.name
                          )}
                        
                        </LoaderWrapper>
                      </Flex>
                      {!topicLoader ? (
                        <Flex
                          style={{ cursor: 'pointer' }}
                          onClick={() => {
                            if (topic.name !== 'Featured Topics') {
                              unsubscribeHandler(topic);
                            }
                          }}
                        >
                          {topic.name !== 'Featured Topics' && (
                            <img
                              alt=""
                              src="/assets/images/color-cross-icon.svg"
                            />
                          )}
                        </Flex>
                      ) : null}
                    </TopicChip>
                  ))
                : null}
            </PublisherCardWrapper>
          </Flex>
          <Flex width="100%" flexDirection="row" padding="10px">
            <Flex
              padding="10px"
              width="20%"
              style={{
                fontSize: '14px',
                color: '#667085',
              }}
            >
              {`${myTopics.length}/15 Selected`}
            </Flex>
            {errorMessage ? (
              <Flex
                width="80%"
                justifyContent="center"
                marginRight="90px"
                style={{ alignItems: 'center', fontSize: '14px' }}
              >
                {errorMessage}
              </Flex>
            ) : null}
          </Flex>
        </Flex>
        <MostSubscribedTopic
          popularTopics={popularTopics}
          loader={popularTopicLoader}
          setLoader={togglePopularTopicLoader}
          subscribe={subscribeHandler}
        />
        {/* <Flex
          display="flex"
          width="30%"
          height="230px"
          flexDirection="column"
          style={{
            border: '1px solid #e4e7ec',
            boxShadow: '0px 1px 2px 0px #1018281F',
            borderRadius: '4px',
          }}
        >
          <Flex
            display="flex"
            justifyContent="flex-start"
            padding="10px"
            style={{ color: '#101828', fontSize: '16px', alignItems: 'center' }}
          >
            <img
              alt=""
              src="/assets/images/most-subs-icon.svg"
              style={{
                alignItems: 'center',
                padding: '3px',
                marginRight: '5px',
              }}
            />
            Most Subscribed
          </Flex>
          <PublisherCardWrapper
            style={{
              flexWrap: 'wrap',
              justifyContent: 'start',
              padding: '3px 15px',
              maxHeight: '250px',
            }}
          >
            <Flex width="100%" padding="10px" height="45px">
              <StyledTitleDiv
                style={{
                  width: '80%',
                  alignItems: 'center',
                  fontSize: '14px',
                  padding: '5px 27px',
                }}
              >
                <StyledTitle style={{ color: '#000000', fontSize: '16px' }}>
                  Accounting
                </StyledTitle>
              </StyledTitleDiv>
              <StyledCross style={{ width: '20%', justifyContent: 'center' }}>
                <img alt="" src="/assets/images/grey-plus-icon.svg" />
              </StyledCross>
            </Flex>
            <hr
              style={{
                border: '0.5px solid #e4e7ec',
                width: '85%',
                marginRight: '7%',
                marginTop: '0px',
                marginBottom: '0px',
              }}
            />
            <Flex width="100%" padding="10px" height="45px">
              <StyledTitleDiv
                style={{
                  width: '80%',
                  alignItems: 'center',
                  fontSize: '14px',
                  padding: '5px 27px',
                }}
              >
                <StyledTitle style={{ color: '#000000', fontSize: '16px' }}>
                  Accounting
                </StyledTitle>
              </StyledTitleDiv>
              <StyledCross style={{ width: '20%', justifyContent: 'center' }}>
                <img alt="" src="/assets/images/grey-plus-icon.svg" />
              </StyledCross>
            </Flex>
            <hr
              style={{
                border: '0.5px solid #e4e7ec',
                width: '85%',
                marginRight: '7%',
                marginTop: '0px',
              }}
            />
            <Flex width="100%" padding="10px" height="45px">
              <StyledTitleDiv
                style={{
                  width: '80%',
                  alignItems: 'center',
                  fontSize: '14px',
                  padding: '5px 27px',
                }}
              >
                <StyledTitle style={{ color: '#000000', fontSize: '16px' }}>
                  Accounting
                </StyledTitle>
              </StyledTitleDiv>
              <StyledCross style={{ width: '20%', justifyContent: 'center' }}>
                <img alt="" src="/assets/images/grey-plus-icon.svg" />
              </StyledCross>
            </Flex>
            <hr
              style={{
                border: '0.5px solid #e4e7ec',
                width: '85%',
                marginRight: '7%',
                marginTop: '0px',
              }}
            />
            <Flex width="100%" padding="10px" height="45px">
              <StyledTitleDiv
                style={{
                  width: '80%',
                  alignItems: 'center',
                  fontSize: '14px',
                  padding: '5px 27px',
                }}
              >
                <StyledTitle style={{ color: '#000000', fontSize: '16px' }}>
                  Accounting
                </StyledTitle>
              </StyledTitleDiv>
              <StyledCross style={{ width: '20%', justifyContent: 'center' }}>
                <img alt="" src="/assets/images/grey-plus-icon.svg" />
              </StyledCross>
            </Flex>
            <hr
              style={{
                border: '0.5px solid #e4e7ec',
                width: '85%',
                marginRight: '7%',
                marginTop: '0px',
              }}
            />
            <Flex width="100%" padding="10px" height="45px">
              <StyledTitleDiv
                style={{
                  width: '80%',
                  alignItems: 'center',
                  fontSize: '14px',
                  padding: '5px 27px',
                }}
              >
                <StyledTitle style={{ color: '#000000', fontSize: '16px' }}>
                  Accounting
                </StyledTitle>
              </StyledTitleDiv>
              <StyledCross style={{ width: '20%', justifyContent: 'center' }}>
                <img alt="" src="/assets/images/grey-plus-icon.svg" />
              </StyledCross>
            </Flex>
            <hr
              style={{
                border: '0.5px solid #e4e7ec',
                width: '85%',
                marginRight: '7%',
                marginTop: '0px',
              }}
            />
          </PublisherCardWrapper>
        </Flex> */}
      </Flex>
      <Flex
        display="flex"
        width="100%"
        style={{
          textAlign: 'left',
          alignItems: 'center',
          color: '#101828',
          fontSize: '20px',
        }}
      >
        Add Topics
      </Flex>

      {/* SEARCH GOES HERE */}
      <TopicSearch
        allTopics={allTopics}
        loader={searchTopicLoader}
        setLoader={toggleSearchTopicLoader}
        subscribe={subscribeHandler}
        searchInput={searchInput}
        setSearchInput={setSearchInput}
      />

      {/* {isEmpty(searchInput) ? (
        <Flex width="100%" flexDirection="row" height="300px" maxHeight="300px">
          <Flex
            display="flex"
            width="100%"
            flexDirection="column"
            padding="10px"
            style={{ backgroundColor: '#F9FAFB' }}
          >
            <Flex display="flex" width="100%" style={{ color: '#101828' }}>
              A
            </Flex>
            <hr
              style={{
                border: '1px solid #e4e7ec',
                width: '92%',
                marginRight: '20%',
                marginBottom: '20px',
              }}
            />
            <PublisherCardWrapper
              style={{
                flexWrap: 'wrap',
                justifyContent: 'start',
                maxHeight: '300px',
              }}
            >
              <Chip
                padding="10px"
                justifyContent="space-between"
                height="45px"
                width="22%"
              >
                <StyledTitleDiv style={{ width: '85%' }}>
                  <StyledTitle style={{ fontSize: '14px' }}>
                    Accounting
                  </StyledTitle>
                </StyledTitleDiv>
                <StyledCross style={{ width: '15%' }}>
                  <img alt="" src="/assets/images/grey-plus-icon.svg" />
                </StyledCross>
              </Chip>
              <Chip
                padding="10px"
                justifyContent="space-between"
                height="45px"
                width="22%"
              >
                <StyledTitleDiv style={{ width: '85%' }}>
                  <StyledTitle style={{ fontSize: '14px' }}>
                    Accounting
                  </StyledTitle>
                </StyledTitleDiv>
                <StyledCross style={{ width: '15%' }}>
                  <img alt="" src="/assets/images/grey-plus-icon.svg" />
                </StyledCross>
              </Chip>
              <Chip
                padding="10px"
                justifyContent="space-between"
                height="45px"
                width="22%"
              >
                <StyledTitleDiv style={{ width: '85%' }}>
                  <StyledTitle style={{ fontSize: '14px' }}>
                    Accounting
                  </StyledTitle>
                </StyledTitleDiv>
                <StyledCross style={{ width: '15%' }}>
                  <img alt="" src="/assets/images/grey-plus-icon.svg" />
                </StyledCross>
              </Chip>
              <Chip
                padding="10px"
                justifyContent="space-between"
                height="45px"
                width="22%"
              >
                <StyledTitleDiv style={{ width: '85%' }}>
                  <StyledTitle style={{ fontSize: '14px' }}>
                    Accounting
                  </StyledTitle>
                </StyledTitleDiv>
                <StyledCross style={{ width: '15%' }}>
                  <img alt="" src="/assets/images/grey-plus-icon.svg" />
                </StyledCross>
              </Chip>
              <Chip
                padding="10px"
                justifyContent="space-between"
                height="45px"
                width="22%"
              >
                <StyledTitleDiv style={{ width: '85%' }}>
                  <StyledTitle style={{ fontSize: '14px' }}>
                    Accounting
                  </StyledTitle>
                </StyledTitleDiv>
                <StyledCross style={{ width: '15%' }}>
                  <img alt="" src="/assets/images/grey-plus-icon.svg" />
                </StyledCross>
              </Chip>
              <Chip
                padding="10px"
                justifyContent="space-between"
                height="45px"
                width="22%"
              >
                <StyledTitleDiv style={{ width: '85%' }}>
                  <StyledTitle style={{ fontSize: '14px' }}>
                    Accounting
                  </StyledTitle>
                </StyledTitleDiv>
                <StyledCross style={{ width: '15%' }}>
                  <img alt="" src="/assets/images/grey-plus-icon.svg" />
                </StyledCross>
              </Chip>
              <Chip
                padding="10px"
                justifyContent="space-between"
                height="45px"
                width="22%"
              >
                <StyledTitleDiv style={{ width: '85%' }}>
                  <StyledTitle style={{ fontSize: '14px' }}>
                    Accounting
                  </StyledTitle>
                </StyledTitleDiv>
                <StyledCross style={{ width: '15%' }}>
                  <img alt="" src="/assets/images/grey-plus-icon.svg" />
                </StyledCross>
              </Chip>
              <Chip
                padding="10px"
                justifyContent="space-between"
                height="45px"
                width="22%"
              >
                <StyledTitleDiv style={{ width: '85%' }}>
                  <StyledTitle style={{ fontSize: '14px' }}>
                    Accounting
                  </StyledTitle>
                </StyledTitleDiv>
                <StyledCross style={{ width: '15%' }}>
                  <img alt="" src="/assets/images/grey-plus-icon.svg" />
                </StyledCross>
              </Chip>
              <Chip
                padding="10px"
                justifyContent="space-between"
                height="45px"
                width="22%"
              >
                <StyledTitleDiv style={{ width: '85%' }}>
                  <StyledTitle style={{ fontSize: '14px' }}>
                    Accounting
                  </StyledTitle>
                </StyledTitleDiv>
                <StyledCross style={{ width: '15%' }}>
                  <img alt="" src="/assets/images/grey-plus-icon.svg" />
                </StyledCross>
              </Chip>
              <Chip
                padding="10px"
                justifyContent="space-between"
                height="45px"
                width="22%"
              >
                <StyledTitleDiv style={{ width: '85%' }}>
                  <StyledTitle style={{ fontSize: '14px' }}>
                    Accounting
                  </StyledTitle>
                </StyledTitleDiv>
                <StyledCross style={{ width: '15%' }}>
                  <img alt="" src="/assets/images/grey-plus-icon.svg" />
                </StyledCross>
              </Chip>
              <Chip
                padding="10px"
                justifyContent="space-between"
                height="45px"
                width="22%"
              >
                <StyledTitleDiv style={{ width: '85%' }}>
                  <StyledTitle style={{ fontSize: '14px' }}>
                    Accounting
                  </StyledTitle>
                </StyledTitleDiv>
                <StyledCross style={{ width: '15%' }}>
                  <img alt="" src="/assets/images/grey-plus-icon.svg" />
                </StyledCross>
              </Chip>
              <Chip
                padding="10px"
                justifyContent="space-between"
                height="45px"
                width="22%"
              >
                <StyledTitleDiv style={{ width: '85%' }}>
                  <StyledTitle style={{ fontSize: '14px' }}>
                    Accounting
                  </StyledTitle>
                </StyledTitleDiv>
                <StyledCross style={{ width: '15%' }}>
                  <img alt="" src="/assets/images/grey-plus-icon.svg" />
                </StyledCross>
              </Chip>
              <Chip
                padding="10px"
                justifyContent="space-between"
                height="45px"
                width="22%"
              >
                <StyledTitleDiv style={{ width: '85%' }}>
                  <StyledTitle style={{ fontSize: '14px' }}>
                    Accounting
                  </StyledTitle>
                </StyledTitleDiv>
                <StyledCross style={{ width: '15%' }}>
                  <img alt="" src="/assets/images/grey-plus-icon.svg" />
                </StyledCross>
              </Chip>
              <Chip
                padding="10px"
                justifyContent="space-between"
                height="45px"
                width="22%"
              >
                <StyledTitleDiv style={{ width: '85%' }}>
                  <StyledTitle style={{ fontSize: '14px' }}>
                    Accounting
                  </StyledTitle>
                </StyledTitleDiv>
                <StyledCross style={{ width: '15%' }}>
                  <img alt="" src="/assets/images/grey-plus-icon.svg" />
                </StyledCross>
              </Chip>
              <Chip
                padding="10px"
                justifyContent="space-between"
                height="45px"
                width="22%"
              >
                <StyledTitleDiv style={{ width: '85%' }}>
                  <StyledTitle style={{ fontSize: '14px' }}>
                    Accounting
                  </StyledTitle>
                </StyledTitleDiv>
                <StyledCross style={{ width: '15%' }}>
                  <img alt="" src="/assets/images/grey-plus-icon.svg" />
                </StyledCross>
              </Chip>
            </PublisherCardWrapper>
          </Flex>
          <AlphabetScrollerWrapper
            style={{
              maxHeight: '300px',
              color: '#98A2B3',
              backgroundColor: '#fff',
            }}
          >
            {topicAlphabet.map((item, index) => (
              <Flex>{item}</Flex>
            ))}
          </AlphabetScrollerWrapper>
        </Flex>
      ) : (
        <Flex
          width="100%"
          flexDirection="column"
          justifyContent="flex-start"
          padding="0px 10px 10px 10px"
          height="210px"
          maxHeight="210px"
        >
          <Flex
            width="100%"
            justifyContent="flex-start"
            padding="0px 0px 10px 0px"
            marginBottom="5px"
            style={{
              fontSize: '16px',
              color: '#000000',
              alignItems: 'center',
            }}
          >
            {`Showing results for "${searchInput}"`}
          </Flex>
          <PublisherCardWrapper
            style={{ flexWrap: 'wrap', justifyContent: 'start' }}
          >
            <Chip
              padding="10px"
              justifyContent="space-between"
              height="45px"
              width="22%"
            >
              <StyledTitleDiv style={{ width: '85%' }}>
                <StyledTitle style={{ fontSize: '14px' }}>
                  Accounting
                </StyledTitle>
              </StyledTitleDiv>
              <StyledCross style={{ width: '15%' }}>
                <img alt="" src="/assets/images/grey-plus-icon.svg" />
              </StyledCross>
            </Chip>
          </PublisherCardWrapper>
        </Flex>
      )} */}

      {/* ALPHABET LIST STARTS HERE */}
      {isEmpty(searchInput) && (
        <TopicListView
          allTopics={allTopics}
          loader={browseTopicLoader}
          setLoader={toggleBrowseTopicLoader}
          subscribe={subscribeHandler}
        />
      )}
    </Flex>
  );
};

export default ManageTopics;
