import React from 'react';
import {
    ThemeStyle,
} from './styles';

const ThemeBubbles = ({
  cardData
}) => {
    return (
        <ThemeStyle 
            color={cardData?.color} 
            background={cardData?.background}
            border={cardData?.border}
        >
            {cardData?.title}
        </ThemeStyle>
    );
};

export default ThemeBubbles;