/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Flex } from '@rebass/grid';
import { Button, LoaderWrapper } from '../../components';
import ThankyouPage from '../UnsubscribeContainer/ThankyouPage';
import { LoginLogo } from '../LoginContainer/LoginContainer.styles';
import useQuery from '../../customHooks/useQuery';
import axios from 'axios';
import { apiDetails, objectToFormData } from '../../services/helpers';
import './UnsubscribeContainer.styles';
import { UnsubscribeCheckbox } from './UnsubscribeContainer.styles';
import UnsubscribeConfirmation from './UnsubscribeConfirmation';
import { LineBreaker } from '../../../src/containers/AutomatedCampaigns/styles';
import { UnsubscribeFromAllModal, UnsubscribeModal } from './UnsubscribeModal';

const UnsubscribeContainer = ({ onSaveClick, email }) => {
  const [openThankyouPage, setOpenThankyouPage] = useState(false);
  const [openConfirmationPage, setOpenConfirmationPage] = useState(false);
  const [postPayload, setPostPayload] = useState({
    cloutFeature: '',
    payload: '',
  });
  //const [loader, setLoader] = useState(false);
  const query = useQuery();
  const [generalNewsletters, setGeneralNewsletters] = useState(false);
  const [ecards, setEcards] = useState(false);
  const [firmUpdates, setFirmUpdates] = useState(false);
  const [events, setEvents] = useState(false);
  // const [financialAndEconomicNewsletters, setFinancialAndEconomicNewsletters] = useState(false);
  const [digitalMarketing, setDigitalMarketing] = useState(false);
  const [valid, setValid] = useState(true);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [openUnsubscribeModal, setOpenUnsubscribeModal] = useState(false);
  const [openUnsubscribeFromAllModal, setOpenUnsubscribeFromAllModal] =
    useState(false);

  console.log(window.innerWidth);
  useEffect(() => {
    let cloutFeature = query.get('clout_feature');
    let payload = query.get('payload');
    let preferences = decodeURIComponent(query.get('preferences'));
    console.log(JSON.parse(atob(preferences)));
    let {
      general_newsletters,
      ecards,
      firm_updates,
      events,
      general_marketing,
    } = JSON.parse(atob(preferences));
    setGeneralNewsletters(general_newsletters === 'true' ? true : false);
    setEcards(ecards === 'true' ? true : false);
    setFirmUpdates(firm_updates === 'true' ? true : false);
    setEvents(events === 'true' ? true : false);
    setDigitalMarketing(general_marketing === 'true' ? true : false);
    setPostPayload({
      cloutFeature,
      payload,
    });
  }, []);

  useEffect(() => {
    if (generalNewsletters && ecards && firmUpdates && events) {
      setDigitalMarketing(true);
    } else if (!generalNewsletters || !ecards || !firmUpdates || !events) {
      setDigitalMarketing(false);
    }
  }, [generalNewsletters, ecards, firmUpdates, events]);

  const handleModalOpen = () => {
    if (
      generalNewsletters &&
      ecards &&
      firmUpdates &&
      events &&
      digitalMarketing
    ) {
      setOpenUnsubscribeFromAllModal(true);
      setOpenUnsubscribeModal(false);
    } else if (
      !generalNewsletters &&
      !ecards &&
      !firmUpdates &&
      !events &&
      !digitalMarketing
    ) {
      setOpenUnsubscribeModal(false);
      setOpenUnsubscribeFromAllModal(false);
      handleSubmit();
    } else {
      setOpenUnsubscribeModal(true);
      setOpenUnsubscribeFromAllModal(false);
    }
  };

  const handleSubmit = () => {
    setLoading(true);
    axios
      .post(
        `${apiDetails.baseUrl}callback`,
        objectToFormData({
          payload: postPayload.payload,
          clout_feature: postPayload.cloutFeature,
          marketing_pref_settings: JSON.stringify({
            general_newsletters: generalNewsletters ? 'true' : 'false',
            ecards: ecards ? 'true' : 'false',
            firm_updates: firmUpdates ? 'true' : 'false',
            events: events ? 'true' : 'false',
            // financial_and_economic_newsletters: financialAndEconomicNewsletters ? 'true' : 'false',
            general_marketing: digitalMarketing ? 'true' : 'false',
          }),
        }),
        {
          headers: {
            'Content-type': 'multipart/form-data',
          },
        }
      )
      .then((res) => {
        // setOpenThankyouPage(true);
        setLoading(false);
        setValid(true);
        setOpenUnsubscribeModal(false);
        setOpenUnsubscribeFromAllModal(false);
        if (
          generalNewsletters &&
          ecards &&
          firmUpdates &&
          events &&
          digitalMarketing
        ) {
          console.log(res);
          //setMessage('Your mail preferences have been saved successfully!');
          setOpenConfirmationPage(true);
        } else if (
          !generalNewsletters &&
          !ecards &&
          !firmUpdates &&
          !events &&
          !digitalMarketing
        ) {
        } else {
          setOpenThankyouPage(true);
          //setMessage('');
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const handleDigitalMarketing = (checked) => {
    console.log('checked', checked);
    setValid(false);
    if (checked) {
      setGeneralNewsletters(true);
      setEcards(true);
      setFirmUpdates(true);
      setEvents(true);
      // setFinancialAndEconomicNewsletters(true);
      setDigitalMarketing(true);
    } else {
      setGeneralNewsletters(false);
      setEcards(false);
      setFirmUpdates(false);
      setEvents(false);
      // setFinancialAndEconomicNewsletters(false);
      setDigitalMarketing(false);
    }
  };

  const goBack = () => {
    setOpenConfirmationPage(false);
    setOpenThankyouPage(false);
    setValid(true);
  };

  return (
    <Flex
      style={{
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        position: 'relative',
        //minHeight: '100vh',
        width: '100%',
      }}
    >
      <Flex width="100%" justifyContent="center" fontSize={window.innerWidth < 600 ? '27px' : '30px'}
            color= '#101828'
            padding= '20px 0px'>
          Email Preference Center
      </Flex>
      <hr
        style={{
          margin: '0',
          padding: '0',
          width: '100%',
          border: '1px solid #E4E7EC',
        }}
      />
      {!openConfirmationPage && !openThankyouPage && (
        <>
          <div
            style={{
              color: '#101828',
              fontSize: window.innerWidth < 600 ? '20px' : '28px',
              fontWeight: '500',
              marginTop: '10px',
              padding: window.innerWidth < 600 ? '10px' : '10px 0px 10px 0px',
              lineHeight: '34px',
              width: '100%',
              height: '56px',
              textAlign: 'center',
            }}
          >
            Manage Your Email Preferences
          </div>

          <hr
            style={{
              border: '1px solid #E4E7EC',
              width: '100%',
            }}
          />
          {window.innerWidth < 600 ? (
            <Flex
              color="#101828"
              mt="15px"
              fontSize="12px"
              justifyContent="center"
              width="100%"
              //pl="50px"
              flexDirection="column"
            >
              <Flex display="flex" justifyContent="center">
                {' '}
                Your Email Address: &nbsp;{' '}
              </Flex>
              <Flex marginRight="auto" marginLeft="auto">
                <a href={`mailto:${email}`}>{query.get('email')}</a>
              </Flex>
            </Flex>
          ) : (
            <Flex
              color="#101828"
              fontSize="12px"
              justifyContent="center"
              width="100%"
              padding="10px 0px"
            >
              Your Email Address: &nbsp;{' '}
              <a href={`mailto:${email}`}>{query.get('email')}</a>
            </Flex>
          )}
          {/* <Flex
            color="#101828"
            mt="15px"
            fontSize="12px"
            justifyContent="center"
            width="100%"
            pl="50px"
            flexDirection="column"
          >
           <Flex display="flex" justifyContent="center" > Your Email Address: &nbsp;{' '} </Flex>
            <Flex><a href={`mailto:${email}`}>{query.get('email')}</a></Flex>
          </Flex> */}
          {!openThankyouPage && (
            <>
              {/* <div
                style={{
                  width: '733px',
                  height: '50px',
                  border: '1px solid #6250ED',
                  borderRadius: '8px',
                  marginLeft: '310px',
                  marginTop: '20px',
                  boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.12)',
                  background: 'rgb(88, 80, 237, 0.1)',
                  fontSize: '14px',
                  color: '#101828',
                  padding: '13px 10px 10px 10px',
                }}
              >
                The communication that you have selected to unsubscribe from is
                categorized as: <strong>E-Cards</strong>
              </div> */}
              <div
                style={{
                  width: window.innerWidth < 600 ? '102%' : '100%',
                  position: 'relative',
                  height: '50px',
                  fontWeight: '400',
                  fontSize: window.innerWidth < 600 ? '14px' : '16px',
                  lineHeight: window.innerWidth < 600 ? '18px' : '19px',
                  textAlign: 'center',
                  color: '#475467',
                  padding:
                    window.innerWidth < 600 ? '5px 15px' : '15px 0px',
                  justifyContent: 'center',
                  marginTop: window.innerWidth < 600 ? '10px' : '10px',
                  marginBottom: window.innerWidth < 600 ? '15px' : '10px',
                  marginLeft: window.innerWidth < 600 ? 'auto' : '0px',
                  marginRight: window.innerWidth < 600 ? 'auto' : '0px',
                }}
              >
                Select to unsubscribe from the following communications
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  flexDirection: 'row',
                  width: '100%',
                }}
              >
                {window.innerWidth > 600 && <Flex width="23%"></Flex>}

                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: window.innerWidth < 600 ? '95%' : '60%',
                    color: '#101828',
                    margin: window.innerWidth < 600 ? '0 auto' : '',
                  }}
                >
                  {/* <UnsubscribeCheckbox>
                <label className='container' style={{ padding: '10px 20px', marginLeft: '205px' }}>
                  <input
                    style={{ marginRight: '8px' }}
                    type="checkbox"
                    checked={financialAndEconomicNewsletters}
                    onChange={() => setFinancialAndEconomicNewsletters(!financialAndEconomicNewsletters)}
                  />
                  <span style={{ marginTop: "12px" }} className="checkmark"></span>
                  <label style={{ padding: '12px', }}>Financial  and Economic Insight Newsletters</label>
                </label>
              </UnsubscribeCheckbox> */}
                  <UnsubscribeCheckbox>
                    <label
                      className="container"
                      style={{ padding: '10px 20px', textAlign: 'left' }}
                    >
                      <input
                        style={{ marginRight: '8px' }}
                        type="checkbox"
                        checked={firmUpdates}
                        onChange={(e) => {
                          setFirmUpdates(!firmUpdates);
                          setValid(false);
                        }}
                      />
                      <span
                        style={{ marginTop: '12px' }}
                        className="checkmark"
                      ></span>
                      <label style={{ padding: '12px 3px 0px 12px' }}>
                        Firm Updates
                      </label>
                      <br />
                      <label
                        style={{
                          fontSize: '12px',
                          paddingLeft: window.innerWidth < 600 ? '0px' : '12px',
                          color: '#475467',
                        }}
                      >
                        Updates about our business such as personnel changes and
                        office closures.
                      </label>
                      {/* <label style={{ fontSize: '12px', paddingLeft: '12px' }}>office closures.</label> */}
                    </label>
                  </UnsubscribeCheckbox>
                  <UnsubscribeCheckbox>
                    <label
                      className="container"
                      style={{ padding: '10px 20px', textAlign: 'left' }}
                    >
                      <input
                        type="checkbox"
                        checked={generalNewsletters}
                        onChange={(e) => {
                          setGeneralNewsletters(!generalNewsletters);
                          setValid(false);
                        }}
                      />
                      <span
                        style={{ marginTop: '12px' }}
                        className="checkmark"
                      ></span>
                      <label style={{ padding: '12px 3px 0px 12px' }}>
                        Newsletters{' '}
                      </label>
                      <br />
                      <label
                        style={{
                          fontSize: '12px',
                          paddingLeft: window.innerWidth < 600 ? '0px' : '12px',
                          color: '#475467',
                        }}
                      >
                        Insights and commentary on the markets, economy,
                        financial planning and other relevant topics.
                      </label>
                      {/* <label style={{ fontSize: '12px', paddingLeft: '12px' }}>planning and other relevant topics.</label> */}
                    </label>
                  </UnsubscribeCheckbox>
                  <UnsubscribeCheckbox>
                    <label
                      className="container"
                      style={{ padding: '10px 20px', textAlign: 'left' }}
                    >
                      <input
                        style={{ marginRight: '8px' }}
                        type="checkbox"
                        checked={ecards}
                        onChange={(e) => {
                          setEcards(!ecards);
                          setValid(false);
                        }}
                      />
                      <span
                        style={{ marginTop: '12px' }}
                        className="checkmark"
                      ></span>
                      <label style={{ padding: '12px 3px 0px 12px' }}>
                        E-cards
                      </label>
                      <br />
                      <label
                        style={{
                          fontSize: '12px',
                          paddingLeft: window.innerWidth < 600 ? '0px' : '12px',
                          color: '#475467',
                        }}
                      >
                        Well-wishes for birthdays, holidays and other special
                        occasions.
                      </label>
                    </label>
                  </UnsubscribeCheckbox>
                  <UnsubscribeCheckbox>
                    <label
                      className="container"
                      style={{ padding: '10px 20px', textAlign: 'left' }}
                    >
                      <input
                        style={{ marginRight: '8px' }}
                        type="checkbox"
                        checked={events}
                        onChange={(e) => {
                          setEvents(!events);
                          setValid(false);
                        }}
                      />
                      <span
                        style={{ marginTop: '12px' }}
                        className="checkmark"
                      ></span>
                      <label style={{ padding: '12px 3px 0px 12px' }}>
                        Event Invitations
                      </label>
                      <br />
                      <label
                        style={{
                          fontSize: '12px',
                          paddingLeft: window.innerWidth < 600 ? '0px' : '12px',
                          color: '#475467',
                        }}
                      >
                        Invitations and related communications for in-person and
                        virtual webcast events
                      </label>
                      {/* <label style={{ fontSize: '12px', paddingLeft: '12px' }}>virtual webcast events</label> */}
                    </label>
                  </UnsubscribeCheckbox>
                  <LineBreaker
                    style={{
                      width: '96%',
                      border: '1px solid #E4E7EC',
                      marginLeft: window.innerWidth < 600 ? '0px' : '-40px',
                    }}
                  />
                  <UnsubscribeCheckbox>
                    <label
                      className="container"
                      style={{ padding: '10px 20px', textAlign: 'left' }}
                    >
                      <input
                        style={{ marginRight: '8px' }}
                        type="checkbox"
                        checked={digitalMarketing}
                        onChange={(e) => {
                          handleDigitalMarketing(!digitalMarketing);
                          if (!e.target.checked) {
                            setDigitalMarketing(false);
                          }
                        }}
                      />
                      <span
                        style={{ marginTop: '12px' }}
                        className="checkmark"
                      ></span>
                      <label style={{ padding: '12px 3px 0px 12px' }}>
                        Unsubscribe from all communications
                      </label>
                      {/* <br />
                      <label
                        style={{
                          fontSize: '12px',
                          paddingLeft: window.innerWidth < 600 ? '0px' : '12px',
                          color: '#475467',
                        }}
                      >
                        Unsubscribe from all future communications
                      </label> */}
                    </label>
                  </UnsubscribeCheckbox>
                </div>
              </div>
              <Flex width="20%"></Flex>
              <div
                style={{
                  justifyContent: 'center',
                  padding: '8px 15pxpx 0px 0px',
                  width: '100%',
                }}
              >
                {/* <LoaderWrapper isLoading={loader}></LoaderWrapper> */}
                <LoaderWrapper isLoading={loading}>
                  <Button
                    styles={{ borderRadius: '4px' }}
                    type="button"
                    margin="25px 40px 25px 40px"
                    onClick={() => {
                      handleModalOpen();
                    }}
                    disabled={valid}
                  >
                    Save Preferences
                  </Button>
                </LoaderWrapper>
              </div>

              {message && (
                <Flex width="100%" justifyContent="center">
                  {message}
                </Flex>
              )}
            </>
          )}
        </>
      )}

      <Flex
        width="100%"
        justifyContent="center"
        display="flex"
        flexDirection="column"
        style={{
          bottom: '0',
          position: 'absolute',
          bottom: '0',
          left: '0',
          right: '0',
          top: '105%',
        }}
      >
        <span
          style={{
            fontSize: '12px',
            color: '#101828',
            paddingTop: '40px',
            marginBottom: '-30px',
          }}
        >
          Powered by
        </span>
        <LoginLogo
          style={{
            width: '200px',
            textAlign: 'left',
            marginLeft: 'auto',
            marginRight: 'auto',
            height: '100px',
            justifyContent: 'center',
            alignItems: 'center',
          }}
          src="/assets/images/TIFIN-Clout-Logo-Color.png"
          alt="mio-logo"
        />
      </Flex>
      {openUnsubscribeModal && (
        <UnsubscribeModal
          isOpen={openUnsubscribeModal}
          handleClose={() => setOpenUnsubscribeModal(false)}
          generalNewsletters={generalNewsletters}
          firmUpdates={firmUpdates}
          events={events}
          ecards={ecards}
          clickHandler={() => {
            handleSubmit();
          }}
        />
      )}
      {openUnsubscribeFromAllModal && (
        <UnsubscribeFromAllModal
          isOpen={openUnsubscribeFromAllModal}
          handleClose={() => setOpenUnsubscribeFromAllModal(false)}
          clickHandler={() => {
            handleSubmit();
          }}
        />
      )}

      {openConfirmationPage && (
        <UnsubscribeConfirmation
          email={query.get('email')}
          // generalNewsletters={generalNewsletters}
          // ecards={ecards}
          // firmUpdates={firmUpdates}
          // events={events}
          handleClick={goBack}
          // digitalMarketing={digitalMarketing}
        />
      )}
      {openThankyouPage && (
        <ThankyouPage
          handleClick={goBack}
          email={query.get('email')}
          generalNewsletters={generalNewsletters}
          ecards={ecards}
          firmUpdates={firmUpdates}
          events={events}
        />
      )}
    </Flex>
  );
};

export default UnsubscribeContainer;
