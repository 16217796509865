import { Flex } from '@rebass/grid';
import { isEmpty } from 'lodash';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import {
  Button,
  LoaderWrapper,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalHeader,
} from '../../../../components';
import { updateCampaign } from '../../../../services/campaign';
import { themesData } from '../../../../utils';
import { SetObjectivesInput } from '../../../DripCampaignContainer/SetObjectives/SetObjectives.styles';

export const RenameCampaignModal = ({
  open,
  handleClose,
  campName,
  campaignRequest,
  campaignId,
  seriesId,
  filters,
  type,
  mode,
  clearSeriesSubcampaign
}) => {
  const [campaignName, setCampaignName] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setCampaignName(campName);
  }, [campName]);

  const handleSubmit = () => {
    if (campaignName.length > 0) {
      let payload = {};
      if(type === 'one_time' || mode) {
        payload.type = 'campaign';
        payload.name = campaignName;
      }
      else {
        payload.type = 'series';
        payload.series_name = campaignName;
      }
      setLoading(true);
      updateCampaign({ id: campaignId || seriesId, payload})
        .then((res) => {
          if (res.result.success) {
            setLoading(false);
            if(seriesId)
              clearSeriesSubcampaign();
            if (isEmpty(filters)) 
              campaignRequest();
            else 
              campaignRequest({ ...filters });
          }
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
        });
    }
  };
  return (
    <Modal
      bgColor="#e4e4e4"
      size="small"
      onRequestClose={handleClose}
      shouldCloseOnOverlayClick
      isOpen={open}
    >
      <ModalHeader
        id="header"
        style={{ textAlign: 'left', color: themesData.charcoalGrey }}
      >
        <div>Rename Campaign</div>
        <ModalCloseButton onClick={handleClose} />
      </ModalHeader>
      <ModalBody style={{ textAlign: 'left' }}>
        <label>Campaign Name</label>
        <Flex width="100%" pt="8px" flexDirection="column">
          <SetObjectivesInput
            id="rename_campaign_input"
            maxLength="100"
            placeholder="Campaign Name"
            value={campaignName}
            onChange={(e) => setCampaignName(e.target.value)}
          />
          <LoaderWrapper isLoading={loading}>
            <Button onClick={handleSubmit} disabled={campaignName.length === 0}>
              Submit
            </Button>
          </LoaderWrapper>
        </Flex>
      </ModalBody>
    </Modal>
  );
};
