import Axios from 'axios';
import { oktaHelper } from '../../utils';
import apiInstance from '../api';
import { automatedCampaignLambdaEndpoints, objectToFormData } from '../helpers';

export const createAutomatedCampaign = async ({ payload }) =>
  apiInstance({ contentType: 'application/json' }).post(
    `${automatedCampaignLambdaEndpoints.baseUrl}/campaigns`,
    payload
  );

export const renameAutomatedCampaign = async ({ id, payload }) => {
  return apiInstance({ contentType: 'application/json' }).put(
    `${automatedCampaignLambdaEndpoints.baseUrl}/campaigns/${id}/rename`,
    payload
  );
};

export const getAutomatedCampaignById = async ({ campaignId }) =>
  apiInstance().get(
    `${automatedCampaignLambdaEndpoints.baseUrl}/campaigns/${campaignId}`
  );

export const updateAutomatedCampaign = async ({ campaignId, payload }) =>
  apiInstance({ contentType: 'application/json' }).put(
    `${automatedCampaignLambdaEndpoints.baseUrl}/campaigns/${campaignId}`,
    payload
  );

export const getAutomatedCampaignBlocks = async (campaignId) =>
  apiInstance().get(
    `${automatedCampaignLambdaEndpoints.baseUrl}/campaigns/${campaignId}/blocks`
  );

export const updateCampaignBlock = async ({ campaignId, blockId, payload }) =>
  apiInstance({ contentType: 'application/json' }).put(
    `${automatedCampaignLambdaEndpoints.baseUrl}/campaigns/${campaignId}/blocks/${blockId}`,
    payload
  );

export const resubmitCampaignBlock = async ({ campaignId, blockId, payload }) =>
  apiInstance().post(
    `automated_campaign/${campaignId}/blocks/${blockId}`,
  objectToFormData(payload)
);

// TODO: Find a better way to send body with DELETE using APIInstance.
export const deleteCampaignBlock = async ({ campaignId, block_ids }) => 
  Axios({
    method: 'delete',
    url: `${automatedCampaignLambdaEndpoints.baseUrl}/campaigns/${campaignId}/blocks`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${window.sessionStorage.getItem('token') || oktaHelper.getAccessToken()}`,
    },
    data: {
      block_ids,
    },
  });

export const getAutomatedCampaigns = ({ type, limit, offset }) => {
  console.log(type, limit, offset);
  return apiInstance().get(`${automatedCampaignLambdaEndpoints.baseUrl}/campaigns?limit=${limit}&page=${offset}`);
}
  

export const getAutomatedBlocksById = async (campaignId, blockId) =>
  apiInstance().get(
    `${automatedCampaignLambdaEndpoints.baseUrl}/campaigns/${campaignId}/blocks/${blockId}`
  );

export const activateCampaign = async (campaignId) => {
  return apiInstance().post(`/automated_campaign/${campaignId}/activate`);
};

export const complianceSendCampaign = async (campaignId) => {
    return apiInstance().post(`/automated_campaign/${campaignId}/send_for_compliance`);
  };

// TODO: Remove later.
export const getTargetSegments = async () => apiInstance().get('/segments');

export const AutomatedDeleteCampaign = ({ id }) => {
  return apiInstance().delete(`${automatedCampaignLambdaEndpoints.baseUrl}/campaigns/${id}`)
};

export const AutomatedDuplicateCampaign = ({ id }) => {
  return apiInstance().post(`/automated_campaign/${id}/clone`)
};

export const AutomatedCloneCampaign = ({ campaignId, block_ids }) => {
  return apiInstance().put(`${automatedCampaignLambdaEndpoints.baseUrl}/campaigns/${campaignId}/blocks/${block_ids}/clone`)
};


export const automatedCancelCampaign = ({ id }) => {
  return apiInstance().put(`${automatedCampaignLambdaEndpoints.baseUrl}/campaigns/${id}/cancel`)
};

export const exportToPdfAc = (payload) => {
  console.log(payload)
  return apiInstance().post(`/automated_campaign/export`, objectToFormData(payload));
};

export const automatedCampaignSendTestModal = (payload) => {
  return apiInstance({ contentType: 'application/json' }).post(`${automatedCampaignLambdaEndpoints.baseUrl}/campaigns/send_test_email`, payload);
};

export const getApprovedToShareAC = () => {
  return apiInstance().get(`automated_campaign/cascaded`);
}

export const getCascadeInfoAC = ({ id }) => {
  return apiInstance().get(`automated_campaign/firm_cascaded/${id}`)
}

export const postCascadeToFirmAC = ({ payload, id }) => {
  return apiInstance().post(`automated_campaign/cascade_to_firm/${id}`, objectToFormData(payload));
}

export const postCascadeToAdvisorsAC = ({ payload, id }) => {
  return apiInstance().post(`automated_campaign/${id}/cascade`, objectToFormData(payload))
}

export const postStartCampaignAC = ({ id }) => {
  return apiInstance().post(`automated_campaign/process_cascaded/${id}`);
}

export const postIgnoreCampaignAC = ({ id }) => {
  return apiInstance().post(`automated_campaign/ignore_cascaded/${id}`);
}

export const postAddBlock = ({ id, payload }) => {
  return apiInstance({ contentType: 'application/json' }).post(`${automatedCampaignLambdaEndpoints.baseUrl}/campaigns/${id}/add_blocks`, payload)
}

export const getViewInfo = (id) => {
  return apiInstance().get(`${automatedCampaignLambdaEndpoints.baseUrl}/campaigns/${id}/view_info`)
}

export const getActivationDetails = (id) => {
  return apiInstance().get(`${automatedCampaignLambdaEndpoints.baseUrl}/campaigns/${id}/details`)
}
