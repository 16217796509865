import { Flex } from '@rebass/grid';
import { isEmpty } from 'lodash';
import React, { useState } from 'react';
import { getMediaUrl } from '../../../../utils';
import { Tooltip } from './CampaignTopics.styles';

const CampaignTopics = ({ topics, socialThemes, displayMode }) => {
  // console.log('topics', topics);
  // console.log('socialThemes', socialThemes);
  let topicsTitle = [];
  return (
    <Flex
      flexDirection="column"
      justifyContent="center"
      alignItems="flex-start"
      marginLeft={displayMode === 'block' ? '' : '10px'}
      width={displayMode === 'block' ? '' : '10%'}
    >
      <span style={{ color: '#4e6780', fontSize: '12px' }}>Topics</span>
      <Flex justifyContent="center" alignItems="center" marginTop="12px">
        {!isEmpty(topics) &&
          !isEmpty(socialThemes) &&
          socialThemes
            .filter((s) => topics.includes(s.id))

            .map((topic, index) => {
              if (index <= (displayMode === 'block' ? 1 : 2)) {
                return (
                  <Tooltip>
                    <img
                      width="20px"
                      alt={topic.name}
                      src={getMediaUrl(topic.media_urls)}
                      style={{ marginRight: '10px' }}
                    />
                    <span className="tooltiptext">{topic.name}</span>
                  </Tooltip>
                );
              } else {
                topicsTitle.push(topic.name);
              }
            })}
        {!isEmpty(topicsTitle) && (
          <Tooltip>
            <div
              style={{
                backgroundColor: 'rgb(98,80,237,0.2)',
                fontSize: '10px',
                fontWeight: '600',
                position: 'relative',
                top: '-3px',
                height: '26px',
                width: '26px',
                display: 'table-cell',
                textAlign: 'center',
                verticalAlign: 'middle',
                borderRadius: '50%',
              }}
            >
              {'+' + topicsTitle.length}
            </div>
            <span className="tooltiptext">{topicsTitle.join(', ')}</span>
          </Tooltip>
        )}
      </Flex>
    </Flex>
  );
};

export default CampaignTopics;
