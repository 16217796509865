import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { get } from 'lodash';
import { Flex } from '@rebass/grid';
import React, { useEffect, useState } from 'react';
import { Button } from '../../../components';
import { CampaignModal } from '../PlanCampaign/PlanCampaign.styles';

export const TwitterModal = ({
    isOpen,
    handleModalClose,
    article,
    saveHandler,
    pageList,
    socialNetworkData
}) => {
    
    const [pages, setPages] = useState(pageList ? pageList : []);
    const [summary, setSummary] = useState('');

    useEffect(() => {
        if(pageList) {
            const updatedPages = pageList.map((page, index) => {
                return {
                    id: page,
                    name: page!==0 ? get(socialNetworkData, `tw[0].page[${page}].details.name`, `Twitter Page${index}`) : 'News Feed'
                };
            });
            setPages(updatedPages);
        }
        setSummary(article.override_text);
    }, []);

    return (
        <CampaignModal
            isOpen={isOpen}
            shouldCloseOnOverlayClick
            onRequestClose={handleModalClose}
            padding="0px"
            bgColor="#fff"
            width="60%"
        >
            <Flex width="100%" flexDirection="column">
                <Flex padding="20px" width="100%" justifyContent="space-between" style={{borderBottom: '1px solid #CFD9E4'}}>
                    <Flex color="#000000" fontSize="14px">
                        Twitter | Customize Summary | 10:30 AM
                    </Flex> 
                    <Flex>
                        <FontAwesomeIcon color="#000000" icon={faTimes} cursor='pointer' onClick={handleModalClose} />
                    </Flex>
                </Flex>
                <Flex width="100%" padding="20px" color="#000000" fontSize="18px">
                    Selected Page
                </Flex>
                <Flex width="100%" padding="0px 20px">
                    {pages.map(page => (
                       <Flex 
                            color="#000000" 
                            fontSize="10px" 
                            backgroundColor="#fff"
                            padding="6px"
                            mr="5px" 
                            style={{border: '1px solid #BCCDD4', borderRadius: '4px'}}
                        > 
                            {page.name}
                        </Flex>
                    ))}
                </Flex>
                <Flex width="95%" mt="15px" ml="2%" justifyContent="center" style={{borderTop: '1px dashed #BCCDD4'}}></Flex>
                <Flex width="100%" fontSize="18px" color="#000000" marginTop="10px" padding="10px 20px">
                    Message
                </Flex>
                <Flex width="100%" padding="5px 20px">
                    <textarea
                        placeholder='Enter here'
                        style={{
                            width: '100%',
                            height: '140px',
                            borderRadius: '4px',
                            padding: '10px 15px',
                            color: '#4E6780',
                            border: '1px solid #c9d0d8',
                            resize: 'none'
                        }}
                        defaultValue={summary}
                        onChange={e => setSummary(e.target.value)}
                    />
                </Flex>
                <Flex mt="15px" padding="20px" justifyContent="flex-end" style={{borderTop: '1px solid #CFD9E4'}}>
                    <Button margin="0px" color="#fff" bgColor="#6250ED" styles={{borderRadius: '4px'}} onClick={() => saveHandler(summary)}>
                        Use This Summary                    
                    </Button>
                </Flex>
            </Flex>
        </CampaignModal>
    )
}

export default TwitterModal;