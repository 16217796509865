import { styled } from '@mui/material';

export const Wrapper = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: '1em',
}));

export const SearchContainer = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  padding: '0.75em',
  background: 'transparent',
  border: '1px solid #E4E7EC',
  borderRadius: '0.25em',
  boxShadow: '1px 2px 2px 0px rgba(16, 24, 40, 0.12) inset',

  '.search-icon': {
    margin: '0 0.25em',
    color: '#667085',
    fontSize: '1.5em',
  },

  input: {
    width: '100%',
    minWidth: '150px',
    height: '1.3125em',
    border: 'none',
    fontSize: '0.875em',
    fontWeight: '400',
    color: '#667085',
    background: 'transparent',
    fontFamily: 'Poppins,sans-serif',
  },
}));

export const Pills = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '1em',
  flexWrap: 'wrap',
  padding: '1em 1.5em',
  backgroundColor: '#F9FAFB',
  border: '1px solid #E4E7EC',
  borderRadius: '0.25em',
}));

export const Pill = styled('div')(({ selected = false }) => ({
  width: 'fit-content',
  display: 'flex',
  alignItems: 'center',
  gap: '0.25em',
  padding: '0.38em 0.75em',
  color: selected ? '#0B5CD7' : '#101828',
  backgroundColor: selected ? '#ECF2FC' : '#fff',
  border: `1px solid ${selected ? '#B6CFF3' : '#E4E7EC'}`,
  borderRadius: '1.25em',
  fontSize: '0.875em',
  fontWeight: '500',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  cursor: 'pointer',
  userSelect: 'none',

  svg: {
    fontSize: '1em',
    color: '#101828',
  },

  span: {
    width: '100%',
    fontSize: '0.875em',
    fontWeight: '500',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));

export const SearchResultsPillsWrapper = styled('div')(() => ({
  maxHeight: '200px',
  width: '100%',
  overflow: 'auto',
}));
