import * as React from "react";

function Link(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 16 16" fill="none" {...props}>
      <path
        d="M5.881 10.119l4.238-4.244M9.062 11.181L7.294 12.95A3.001 3.001 0 013.05 8.706l1.769-1.768M11.181 9.062l1.769-1.768A3 3 0 108.706 3.05L6.938 4.819"
        stroke="currentColor"
        strokeWidth={1.6}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

const MemoLink = React.memo(Link);
export default MemoLink;
