
import React from 'react';
import styled from 'styled-components';
import { FormControlLabel, Radio } from '@mui/material';

export const TextInput = styled('input')`
    width: ${({ width }) => (width ? width : '80%')};
    display: flex;
    background: #FFFFFF;
    border: 1px solid #E4E7EC;
    box-shadow: inset 1px 2px 2px rgba(16, 24, 40, 0.12);
    border-radius: 4px;
    padding: 8px 4px;
    margin: 8px 0;
`;

export const TextLabel = styled('label')`
    font-weight: 500;
    font-size: 14px;
    color: #101828;
    text-align: left;
    width: 100%;
`;

export const RadioLabel = styled(FormControlLabel)`
  font-weight: 500;
  font-size: 14px;
  color: #101828;
`;

export const RadioStyle = styled(Radio)`
  color: #816EF8 !important;  
`;

export const PurpleButton = styled('button')`
  background: #816EF8;
  color: white;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  margin: 20px auto 10px;
  height: 40px;
  padding: 12px 24px;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.12);
  border-radius: 4px;
  transition: 0.5s;
  border: 0;
  text-transform: none;
  :hover{
    background: #6351ed;
  }
`;

export const ErrorMessage = styled('p')`
  font-weight: 400;
  font-size: 10px;
  color: #E00000;
  text-align: left;
  margin: -5px 0 5px;
`;