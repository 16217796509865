import { Grid, Popover, styled } from '@mui/material';

export const HeaderContainer = styled(Grid)(() => ({
  background: '#fff',
  width: '100%',
  borderBottom: '1px solid #e1e1e1',
  '& .logo': {
    height: '65px',
    width: '200px',
    objectFit: 'cover',
  },
  position: 'sticky',
  top: 0,
  zIndex: 99,
}));

export const MenuContainer = styled('div')(({ isActive = false }) => ({
  background: isActive ? '#6250ED' : '#fff',
  color: isActive ? '#FCFDFD' : '#475467',
  transition: '0.4s',
  padding: '8px 12px',
  cursor: 'pointer',
  borderRadius: '8px',
  '&:hover': {
    background: '#f2f4f7',
    color: '#3C4A59',
  },
}));

export const PopoverItem = styled(Grid)(() => ({
  padding: '12px',
  fontSize: '14px',
  fontWeight: 400,
  cursor: 'pointer',
  '&:hover': {
    background: '#f2f4f7',
    color: '#475467',
  },
}));

export const Profile = styled(Grid)(() => ({
  borderRadius: '20px',
  background: '#dbf2fc',
  color: '#457f8f',
  height: '40px',
  width: '40px',
  padding: '8px 0 0',
  textAlign: 'center',
  fontWeight: 500,
  cursor: 'pointer',
  transition: '0.4s',
}));

export const StyledPopover = styled(Popover)(() => ({
  cursor: 'pointer',
  '.MuiPopover-paper': {
    background: '#fff',
    border: '1px solid #E4E7EC',
    boxShadow: '0px 4px 8px 0px rgba(16, 24, 40, 0.10)',
    borderRadius: '8px',
  },
}));
