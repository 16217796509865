import styled from 'styled-components';

export const Container = styled.div`
  width:  ${(props) => (props.screenWidth < 768 ? '264px' : '')};
  margin: 50px auto 0 auto;
  color: #6351ed;
  position: absolute;
  top: 50px;
  z-index: 30;
`;

export const UL = styled.ul`
  margin: 0;
  padding: 0;
  text-align: left;
`;
export const SecondaryUL = styled.ul`
  height: 100%;
  max-height: 0;
  overflow: hidden;
  padding: 0;
`;
export const TertiaryUL = styled.ul`
  height: 100%;
  max-height: 0;
  padding: 0;
  height: 85px;
  overflow-y: auto;
`;

export const PrimaryItem = styled.li`
  list-style-type: none;
  display: block;
  width: 100%;
  cursor: pointer;
`;
export const PrimaryHeader = styled.h3`
  margin: 0;
  font-size: 24px;
`;
export const Link = styled.a`
  font-weight: 600;
  padding: 0;
  padding-left: 5px;
  font-size: 12px;
  line-height: 34px;
  display: flex;
  justify-content: space-between;
  padding-right: 10px;
  align-items: center;
  color: #6351ed;
  text-decoration: none;
  background-color: #d5d5d5;
  margin-bottom: 0px;
  border: solid #ccc;
  border-width: 0;
`;

export const Input = styled.input`
  visibility: hidden;
  width: 0;
  display: none;
  &:checked + label + ul {
    max-height: 1000px;
  }
`;
export const Label = styled.label`
  width: 100%;
`;

export const SecondaryItem = styled.li`
  background-color: #f5f5f5;
  font-weight: 600;
  list-style-type: none;
  display: block;
  width: 100%;
  margin-right: 0;
  color: #6351ed;
  cursor: pointer;
`;

export const TertiaryItem = styled.li`
  background-color: #ffffff;
  font-weight: 500;
  white-space: nowrap;
  list-style-type: none;
  display: block;
  width: 100%;
  cursor: pointer;
`;

export const SecondaryLink = styled.a`
  color: #6351ed;
  text-decoration: none;
  font-size: 11px;
  line-height: 27px;
  display: block;
  padding: 0 15px;
  transition: all 0.15s;
  position: relative;
  margin-bottom: 0px;
  border: solid #ccc;
  border-width: 0;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  padding-right: 10px;
  align-items: center;
  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 5px;
    background: #6351ed;
    display: none;
  }
  &:hover {
    background: #e4e4e5;
  }
  &:hover:before {
    display: block;
  }
`;

export const TertiaryLink = styled.a`
  color: #6351ed;
  text-decoration: none;
  font-size: 11px;
  line-height: 27px;
  display: block;
  padding: 0 15px;
  transition: all 0.15s;
  position: relative;
`;
