import React from 'react';
import { ButtonStyle } from './styles';

const WhiteButton = ({children, onClick, width, height, margin, disabled}) => {
    return(
        <ButtonStyle onClick={onClick} width={width} height={height} margin={margin} disabled={disabled}>
            {children}
        </ButtonStyle>
    )
};

export default WhiteButton;