import { styled } from '@mui/material';

export const Action = styled('div')(() => ({
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: '1rem',
  padding: '0.5rem 1rem',
  color: '#101828',
  backgroundColor: '#FFF',
  border: '1px solid #E4E7EC',
  borderRadius: '0.5rem',
  boxShadow: '0px 0.25rem 0.5rem rgba(16, 24, 40, 0.1)',
  cursor: 'pointer',
}));

export const ActionContent = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '0.25rem',

  svg: {
    fontSize: '1.5rem',
    color: '#101828',
  },

  h6: {
    font: '500 0.875rem Poppins, sans-serif',
    color: '#101828',
    margin: 0,
  },
}));
