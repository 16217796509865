import { Box, Flex } from '@rebass/grid';
import styled from 'styled-components';
import { FlexFullWidth, Th } from '../../../components';

export const OverviewBox = styled(Box).attrs({
  className: 'overview-box',
})`
  width: 249px;
  height: 137px;
  border: 1px solid #ececec;
  background-color: #ffffff;
  padding: ${({ padding }) => padding || '28px 0px 28px 0px'};
  margin: 0px 0px 30px 0px;
`;

export const OverviewBoxIconDiv = styled.div.attrs({
  className: 'overview-box-icon-div',
})`
  align-self: center;
`;

export const OverviewBoxCount = styled.div.attrs({
  className: 'overview-box-count',
})`
  align-self: center;
  font-size: 26px;
  font-weight: 600;
`;

export const OverviewBoxPercentage = styled.div.attrs({
  className: 'overview-box-percentage',
})`
  align-self: center;
  font-size: 14px;
`;

export const OverviewBoxTitle = styled.div.attrs({
  className: 'overview-box-title',
})`
  align-self: center;
  color: #49484a;
  font-size: 16px;
  width: 100%;
`;

export const OverviewTabHeading = styled.div.attrs({
  className: 'overview-tab-heading',
})`
  color: #49484a;
  font-size: 15px;
  font-weight: 600;
  padding-left: 10px;
`;

export const OverviewTableBox = styled(Box).attrs({
  className: 'overview-table-box',
})`
  width: 100%;
  height: 204.4px;
  margin: 0px 2px 18.6px 0px;
  padding: 0px;
  border: solid 1px #ececec;
  background-color: #ffffff;

  ul {
    padding: 0;
    margin: 0;
    width: 100%;
    li {
      font-size: 12px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      height: 48.4px;
      margin: 0;
      padding: 0px 10px;
      line-height: 1.5;
      background-color: #ffffff;
    }
  }
`;

export const EngagementTr = styled(FlexFullWidth).attrs({
  className: 'engagement-tr',
})`
  border-bottom: 1px solid #ececec;
`;

export const EngagementTh = styled(Th).attrs({
  className: 'engagement-th',
})`
  padding: ${({ padding }) => padding || '8px 8px'};
  color: #49484a;
  line-height: 1.42857143;
  font-weight: 600;
  font-size: 13px;
  align-self: center;
  background-color: ${({ bgColor }) => bgColor || '#ececec'};
  text-align: ${({ textAlign }) => textAlign || ''};

  .tooltiptext {
    visibility: hidden;
    width: 194.4px;
    background-color: #404040;
    color: #cccccc;
    text-align: left;
    border-radius: 6px;
    padding: 5px 5px;
    position: absolute;
    z-index: 10;
    top: ${({ top }) => top || '30.5%'};
    left: ${({ left }) => left || '75.3%'};
    font-size: 10px;
    display: flex;
    align-items: center;
  }

  .tooltiptext::after {
    content: '';
    position: absolute;
    top: 50%;
    right: 100%;
    margin-top: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent #404040 transparent transparent;
  }

  &:hover .tooltiptext {
    visibility: visible;
  }
`;

export const LeadTableBody = styled(FlexFullWidth).attrs({
  className: 'lead-table-body',
})`
  flex-direction: column;
  max-height: 290px;
  overflow-y: auto;
`;

export const LeadTableBodyTr = styled(FlexFullWidth).attrs({
  className: 'lead-table-body-tr',
})`
  background-color: #ffffff;
  &:not(:last-child) {
    border-bottom: 1px solid #efefef;
  }
  ${({ isHoverable }) =>
    isHoverable &&
    `
    &:hover {
      background-color: #dfdbff;
      cursor: pointer;
    }
  `}
`;

export const TotalCountSection = styled.div.attrs({
  className: 'total-count-section',
})`
  display: flex;
  height: 41.6px;
  width: 100%;
  padding: ${({ padding }) => padding || '11px 16px 11.6px 35px'};
  background-color: #5f5f5f;
`;

export const EngagementBackButton = styled.div.attrs({
  className: 'engagement-back-button',
})`
  position: absolute;
  top: 95%;
  left: 22%;
  cursor: pointer;
  font-size: 14px;
`;

export const ShareBox = styled(Box).attrs({
  className: 'share-box',
})`
  width: 100%;
  height: ${({ height }) => height || '190px'};
  border: 1px solid #ececec;
  background-color: #ffffff;
  padding: 45.8px 42px 56.3px 42px;
`;

export const FilterIconDiv = styled.div.attrs({
  className: 'filter-icon-div',
})`
  position: absolute;
  top: ${({ top }) => top || '14.5%'};
  left: ${({ left }) => left || '95%'};

  &:hover {
    cursor: pointer;
    // ul.engagement-filter-ul {
    //   visibility: visible;
    // }
  }

  ul.engagement-filter-ul {
    display: none;
    // visibility: hidden;
    position: absolute;
    top: 0%;
    border-radius: 4px;
    right: ${({ dropdownRight }) => dropdownRight || '111%'};
    left: ${({ dropdownLeft }) => dropdownLeft || ''};
    max-height: 200px;
    overflow-x: hidden;
    overflow-y: auto;
    z-index: 5;
    list-style: none;
    ${'' /* background-color: #6351ed; */}
    color: #ffffff;
    font-size: 9px;
    width: 300px;
    padding: 0;
    margin: 0;

    li {
      text-align: left;
      &:hover {
        background-color: #f8f8f8;
        cursor: pointer;
      }
    }
    &::-webkit-scrollbar {
      width: 5px;
    }

    &::-webkit-scrollbar-track {
      background: #e9f6e6 !important;
    }

    &::-webkit-scrollbar-thumb {
      background: #bccdd4 !important;
    }
  }
`;

export const EngagementCheckboxWrapper = styled(Flex).attrs({
  className: 'engagement-checkbox-wrapper',
})`
  positon: relative;
  input[type="checkbox"] {
    visibility: hidden;
  }
  input[type="checkbox"] + label:before {
    border: 1px solid #333;
    content: "\00a0";
    display: inline-block;
    font: 16px/1em sans-serif;
    height: 16px;
    margin: 0 .25em 0 0;
    padding: 0;
    vertical-align: top;
    width: 16px;
  }

  input[type="checkbox"]:checked + label:before {
    background: #fff;
    color: #333;
    content: "\2713";
    text-align: center;
  }
`;

export const TopLeadWrapper = styled.article.attrs({
  className: 'top-lead-wrapper',
})`
  width: ${({ width }) => width || '215px'};
  height: ${({ height }) => height || '208px'};
  margin: ${({ margin }) => margin || '14px 10px 35px 8.2px'};
  padding: ${({ padding }) => padding || '8px 8px 10px 8px'};
  border-radius: 8px;
  border: solid 1px #ececec;
  background-color: #ececec;
`;

export const TopLeadName = styled.div.attrs({
  className: 'top-lead-name',
})`
  font-size: 13px;
  font-weight: 500;
  line-height: 1.54;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const TopLeadEmail = styled.div.attrs({
  className: 'top-lead-email',
})`
  font-size: 10px;
  line-height: 1.6px;
  padding-top: 4px;
  text-align: left;
`;

export const SponsoredWrapper = styled(Flex).attrs({
  className: 'sponsored-wrapper',
})`
  background-color: #cde2e7;
  flex-direction: column;
  height: 53px;
  width: 100%;
  border-radius: 8px;
  padding: 4px 6px 4px;
`;

export const TopShareWrapper = styled.article.attrs({
  className: 'top-share-wrapper',
})`
  width: 215px;
  height: 245px;
  margin: 14px 10px 35px 8.2px;
  border-radius: 8px;
  border: solid 1px #ececec;
  background-color: #ececec;
`;

export const OverviewBadge = styled.div.attrs({
  className: 'overview-badge',
})`
  background-color: #49484a;
  width: 77px;
  height: 13px;
  border-radius: 2px;
  color: #ffffff;
  font-size: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const OverviewShareTitle = styled.div.attrs({
  className: 'overview-share-title',
})`
  font-size: 10px;
  padding: ${({ padding }) => padding || '10px 6px 0px'};
  text-align: left;
  color: #030303;
  font-weight: bold;
  line-height: 1.4;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;
  height: ${({ height }) => height || '35px'};
`;

export const TopShareName = styled.div.attrs({
  className: 'top-share-name',
})`
  font-size: 10px;
  font-weight: 600;
  line-height: 1.4;
  text-align: left;

  &.newsletter-title {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
  }
`;

export const TopPublisherName = styled.div.attrs({
  className: 'top-share-name',
})`
  font-size: 10px;
  line-height: 1.6;
  text-align: left;
  color: #49484a;
  font-weight: ${({ fontWeight }) => fontWeight || ''};

  &.newsletter-title {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
  }
`;

export const ScoreWrapper = styled.div.attrs({
  className: 'score-wrapper',
})`
  .tooltip-text {
    visibility: hidden;
    width: 141.4px;
    background-color: #49484a;
    color: #cccccc;
    text-align: center;
    border-radius: 6px;
    padding: 5px 5px;
    font-size: 10px;
    text-align: left;
    /* Position the tooltip */
    position: absolute;
    z-index: 1;
    top: 124%;
    right: -37%;
    margin-left: -60px;
    display: flex;
    align-items: center;
  }

  &:hover .tooltip-text {
    visibility: visible;
  }
`;

export const ProspectFeaturedArticle = styled.div.attrs({
  className: 'prospect-feature-article',
})`
  width: ${({ width }) => width || '551px'};
  height: 79px;
  border: 1px solid #ececec;
  border-radius: 8px;
  background-color: #ececec;
  padding: 10.3px 11px 10px 10px;
  display: flex;
  justify-content: space-between;
  margin-left: 17px;
`;

export const VerticalLine = styled.div.attrs({
  className: 'vertical-line',
})`
  width: 0;
  height: 58px;
  margin: 0.2px 0 0.5px;
  opacity: 0.2;
  border: solid 1px #707070;
`;

export const Analytics = styled.div.attrs({
  className: 'analytics',
})`
  .flex-row-container {
    margin-left: 38%;
    display: flex;
    flex-wrap: wrap;
    /* align-items: right;
  justify-content: right; */
  }
  .flex-row-container > .flex-row-item {
    flex: 1 2 50%; /*grow | shrink | basis */
    height: 100px;
  }

  .flex-row-item {
    width: 100%;
    padding-right: 10px;
  }
`;

export const FilterLi = styled.li.attrs({
  className: 'filterLi',
})`
  background-color: ${({ bgColor }) => bgColor || '#cfd9e4'};
  padding: 10px 6px;
  font-size: 14px;
  font-weight: 800;
  color: #000;
`;

export const WebinarInfo = styled.div`
    font-size: 10px;
    padding: '10px 6px 0px';
    text-align: left;
    color: #030303;
    margin: 3px 0;
    font-weight: bold;
    line-height: 1.4;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    -webkit-box-orient: vertical;
    height: '35px';
  `;
  