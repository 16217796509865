import React, { useState } from 'react';
import { ResponsiveContainer, PieChart, Pie, Cell, Sector } from 'recharts';
import { Loader } from '../';

const BasicBarChart = ({ loading, data, colors, onSectionHover }) => {
  const [activeStateIndex, setActiveState] = useState(0);

  if (loading) {
    return <Loader />;
  }

  const renderActiveShape = (props) => {
    const {
      cx,
      cy,
      innerRadius,
      outerRadius,
      startAngle,
      endAngle,
      fill,
      value,
    } = props;
    return (
      <g>
        <text x={cx} y={cy} dy={8} textAnchor="middle" fill="#6351ed">
          {value === 3 ? 0 : value}%
        </text>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
        />
        <Sector
          cx={cx}
          cy={cy}
          startAngle={startAngle}
          endAngle={endAngle}
          innerRadius={outerRadius - 1}
          outerRadius={outerRadius + 10}
          fill={fill}
        />
      </g>
    );
  };

  return (
    <ResponsiveContainer width="99%" height="100%" minHeight="150px">
      <PieChart
        margin={{
          top: 10,
        }}
      >
        <Pie
          isAnimationActive={false}
          activeIndex={activeStateIndex}
          activeShape={renderActiveShape}
          data={data}
          innerRadius="60%"
          outerRadius="75%"
          fill="#8884d8"
          paddingAngle={0}
          dataKey="value"
          onMouseEnter={(data, index) => {
            onSectionHover(data);
            setActiveState(index);
          }}
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
          ))}
        </Pie>
      </PieChart>
    </ResponsiveContainer>
  );
};

BasicBarChart.defaultProps = {
  onSectionHover: () => {},
};

export default BasicBarChart;
