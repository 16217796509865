import React, { useState, useEffect } from 'react';
import { isEmpty } from 'lodash';

import { InsightsArticle, LoaderWrapper } from '../../../components';
import { getTopInsights, postArticlesToNewsletterBucket } from '../../../services/dashboard';
import { getExploreArticleData } from '../../../utils';
import {
  TopInsightsWrapper,
  NoContentWrapper,
} from '../DashboardContainer.styles';
import { deleteBucketArticle } from '../../../services/newsletter';

const TopInsights = ({ shareArticleRequest, shareError, isShareBlocked, shareArticleSetState, bucketCountRequest }) => {
  const [popularThemesFetching, setTopInsightsFetching] = useState(false);
  const [insights, setInsights] = useState([]);

  useEffect(() => {
    setTopInsightsFetching(true);
    getTopInsights()
      .then((response) => {
        setTopInsightsFetching(false);
        setInsights(getExploreArticleData(response.result.data));
      })
      .catch((err) => {
        setTopInsightsFetching(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const addToNewsletterBucket = (articleId, themeId) => {
    postArticlesToNewsletterBucket({ articleId, themeId }).then((response) => {
      if (response.result.success) {
        setTopInsightsFetching(true);
        getTopInsights().then(res => {
          setTopInsightsFetching(false);
          setInsights(getExploreArticleData(res.result.data));
        }).catch(err => {
          setTopInsightsFetching(false);
        });
        bucketCountRequest()
      }
    }).catch(err => {
      console.log(err)
    })
  }

  const removeToNewsletterBucket = (bucketId) => {
    deleteBucketArticle({ id: bucketId }).then((response) => {
      if (response === '') {
        setTopInsightsFetching(true);
        getTopInsights().then(res => {
          setTopInsightsFetching(false);
          setInsights(getExploreArticleData(res.result.data));
        }).catch(err => {
          setTopInsightsFetching(false);
        });
        bucketCountRequest();
      }
    })
  }

  return (
    <TopInsightsWrapper data-testid='most_shared_articles'>
      <LoaderWrapper isLoading={popularThemesFetching}>
        {isEmpty(insights) && (
          <NoContentWrapper style={{ color: '#6351ed' }}>No insights found</NoContentWrapper>
        )}
        {insights.map((insight, index) => (
          <InsightsArticle
            key={index}
            {...insight}
            isShareBlocked={isShareBlocked}
            shareArticleSetState={shareArticleSetState}
            addToNewsletterBucket={addToNewsletterBucket}
            removeToNewsletterBucket={removeToNewsletterBucket}
            article={insight}
          />
        ))}
      </LoaderWrapper>
    </TopInsightsWrapper>
  );
};

export default TopInsights;
