import { toLower, get, compact, replace, isEmpty, cloneDeep } from 'lodash';
import { getMediaImage } from '.';
import {
  getApproveToShareArticles,
  getContentLibrary,
} from '../services/dashboard';
import { getMediaUrl, getMonthDate } from './common.helper';

export const getSurveyArticleData = (articles, sourceRoute) => {
  const feed = get(articles, 'feed') || [];
  const accounts = get(articles, 'details.account', {});
  const themes = get(articles, 'details.theme', {});
  return feed.map((item) => {
    const article_order_num = get(item, 'article_order_num', {});
    const id = get(item, 'id');
    const extractedDate = getMonthDate(get(item, 'updated_time', ''));
    const ids = get(item, 'ids');
    const accountId = get(ids, 'account');
    const url =
      sourceRoute === 'campaign'
        ? getMediaImage(
            get(item.media_urls, '[0][0]', get(item.media_urls, '[0]', ''))
          )
        : getMediaUrl(item.media_urls);
    const auxiliary = get(item, 'auxiliary');
    const account = get(accounts, `${accountId}`, '');
    const date = `${extractedDate[0]} ${extractedDate[1]}`;
    const title = get(account, `title`, '') || get(item, `title`, '');
    const description = get(item, `text`, '');
    const fdDirty1 = get(item, `full_article_text`, '');
    let fullDescription = replace(
      fdDirty1,
      new RegExp('\\\\/|/\\\\', 'g'),
      '/'
    ).slice(0, -2);

    // let fullDescription = get(item, `full_article_text`, '');

    const link = get(item, 'links.social_permalink', '');
    const channelId = get(ids, 'channel[0]');
    const themeIds = get(ids, 'theme', []);
    const themeId = get(ids, 'theme[0]');

    let themeObj = themes[themeId];
    const topic = get(themeObj, 'name', '-');

    return {
      article_order_num,
      id,
      url,
      topic,
      title,
      description,
      fullDescription,
      date,
      link: link,
      year: extractedDate[2],
      channelId,
      themeId,
      auxiliary,
      themeIds,
      created_time: get(item, 'created_time'),
      updated_time: get(item, 'updated_time', ''),
    };
  });
};

export const getExploreArticleData = (articles, sourceRoute) => {
  const feed = get(articles, 'feed') || [];
  const accounts = get(articles, 'details.account', {});
  return feed.map((item) => {
    const id = get(item, 'id');
    const extractedDate = getMonthDate(get(item, 'updated_time', ''));
    const ids = get(item, 'ids');
    const accountId = get(ids, 'account');
    const url =
      sourceRoute === 'campaign'
        ? getMediaImage(
            get(item.media_urls, '[0][0]', get(item.media_urls, '[0]', ''))
          )
        : getMediaUrl(item.media_urls);
    const auxiliary = get(item, 'auxiliary');
    const isNewsletter = get(auxiliary, 'in_newsletter_bucket');
    const bucketId = get(auxiliary, 'bucket_article_id', '');
    const topic = get(auxiliary, 'title');
    const approveToShare = get(auxiliary, 'approved_to_share', false);
    const isBookmark = get(auxiliary, 'in_bookmark');
    const bookmarkId = get(auxiliary, 'bookmark_article_id', '');
    const auxiliarySharing = get(auxiliary, 'sharing.allowed');
    const account = get(accounts, `${accountId}`, '');
    const auxiliaryPlace = get(
      accounts,
      `${get(auxiliary, 'original_content.ids.account[0].id')}.title`,
      ''
    );
    const internalContent = get(auxiliary, 'internal_content', '');
    const sponseredContent = get(auxiliary, 'sponsored_content', false);
    const place = auxiliaryPlace || get(account, `title`, '');
    const date = `${extractedDate[0]} ${extractedDate[1]}`;
    const title = get(account, `title`, '');
    const description = get(item, `text`, '');
    const link = get(item, 'links.social_permalink', '');
    const channelId = get(ids, 'channel[0]');
    let themeIds = get(ids, 'theme', []);
    const themeId = get(ids, 'theme[0]');
    let themeIDArray = get(ids, 'theme');
    if (sourceRoute === 'editor') {
      themeIds =
        themeIDArray.length > 0 &&
        themeIDArray.map((data, index) => {
          return data.id;
        });
    }
    const sharedChannels = [];
    const recentSharedChannel = [];
    const tickers = get(item, 'tickers', '').split(',');
    const exportPdfId = get(item, 'auxiliary.scheduled.details[0].id', '');
    get(auxiliary, 'shared.details', []).forEach((detail) => {
      const networkId = get(detail, 'destination.network');
      recentSharedChannel.push(networkId);
    });
    const recentReachCount = get(
      auxiliary,
      'shared.details[0].engagement.network.reach',
      0
    );
    const recentLinkCount = get(
      auxiliary,
      'shared.details[0].engagement.network.link_click',
      0
    );
    const sponsoredFunds = JSON.parse(get(item, 'sponsored_funds', null));
    const isGivingCompass =
      toLower(description).indexOf('giving compass') !== -1;
    let networkDetails = {};
    get(item, 'engagement.users.current.details', []).forEach((engagement) => {
      const destinationId = get(engagement, 'destination.network');
      networkDetails = get(engagement, 'engagement.network', {});
      sharedChannels.push(destinationId);
    });
    return {
      id,
      url,
      topic,
      title,
      description,
      place,
      date,
      link: link,
      year: extractedDate[2],
      channelId,
      themeId,
      sharedChannels,
      networkDetails,
      tickers,
      isGivingCompass,
      auxiliary,
      auxiliarySharing,
      themeIds,
      recentSharedChannel,
      recentReachCount,
      recentLinkCount,
      created_time: get(item, 'created_time'),
      sponseredContent,
      internalContent,
      sponsoredFunds,
      isNewsletter,
      bucketId,
      approveToShare,
      isBookmark,
      bookmarkId,
      exportPdfId,
      updated_time: get(item, 'updated_time', ''),
    };
  });
};

export const myMioArticlesData = (articles) => {
  const feed = get(articles, 'feed') || [];
  const accounts = get(articles, 'details.account', {});
  return feed.map((item) => {
    const id = get(item, 'id');
    const extractedDate = getMonthDate(get(item, 'updated_time', ''));
    const ids = get(item, 'ids');
    const accountId = get(ids, 'account');
    const url = getMediaUrl(item.media_urls);
    const auxiliary = get(item, 'auxiliary');
    const bucketId = get(auxiliary, 'bucket_article_id', '');
    const isNewsletter = get(auxiliary, 'in_newsletter_bucket');
    const topic = get(auxiliary, 'title');
    const auxiliarySharing = get(auxiliary, 'sharing.allowed');
    const account = get(accounts, `${accountId}`, '');
    const isBookmark = get(auxiliary, 'in_bookmark');
    const bookmarkId = get(auxiliary, 'bookmark_article_id', '');
    const auxiliaryPlace = get(
      accounts,
      `${get(auxiliary, 'original_content.ids.account[0].id')}.title`,
      ''
    );
    const internalContent = get(auxiliary, 'internal_content', '');
    const sponseredContent = get(auxiliary, 'sponsored_content', '');
    const place = auxiliaryPlace || get(account, `title`, '');
    const date = `${extractedDate[0]} ${extractedDate[1]}, ${extractedDate[2]}`;
    const title = get(account, `title`, '');
    const description = get(item, `text`, '');
    const link = get(item, 'links.social_permalink', '');
    const channelId = get(ids, 'channel[0]');
    const themeIds = get(ids, 'theme', []);
    const themeId = get(ids, 'theme[0]');
    const sharedChannels = [];
    const recentSharedChannel = [];
    const tickers = get(item, 'tickers', '').split(',');
    get(auxiliary, 'shared.details', []).forEach((detail) => {
      const networkId = get(detail, 'destination.network');
      recentSharedChannel.push(networkId);
    });
    const recentReachCount = get(
      auxiliary,
      'shared.details[0].engagement.network.reach',
      0
    );
    const recentLinkCount = get(
      auxiliary,
      'shared.details[0].engagement.network.link_click',
      0
    );
    const isGivingCompass =
      toLower(description).indexOf('giving compass') !== -1;
    let networkDetails = {};
    get(item, 'engagement.users.current.details', []).forEach((engagement) => {
      const destinationId = get(engagement, 'destination.network');
      networkDetails = get(engagement, 'engagement.network', {});
      sharedChannels.push(destinationId);
    });
    return {
      id,
      url,
      topic,
      title,
      description,
      place,
      date,
      link: link,
      year: extractedDate[2],
      channelId,
      themeId,
      sharedChannels,
      networkDetails,
      tickers,
      isGivingCompass,
      auxiliarySharing,
      auxiliary,
      themeIds,
      recentSharedChannel,
      recentReachCount,
      recentLinkCount,
      created_time: get(item, 'created_time'),
      sponseredContent,
      internalContent,
      myClout: true,
      bucketId,
      isBookmark,
      bookmarkId,
      isNewsletter,
      updated_time: get(item, 'updated_time', ''),
    };
  });
};

export const approvedToShareArticlesData = (articles) => {
  const feed = get(articles, 'feed') || [];
  const accounts = get(articles, 'details.account', {});
  return feed.map((item) => {
    const id = get(item, 'id');
    const extractedDate = getMonthDate(get(item, 'updated_time', ''));
    const ids = get(item, 'ids');
    const accountId = get(ids, 'account');
    const url = getMediaUrl(item.media_urls);
    const auxiliary = get(item, 'auxiliary');
    const topic = get(auxiliary, 'title');
    const auxiliarySharing = get(auxiliary, 'sharing.allowed');
    const account = get(accounts, `${accountId}`, '');
    const isBookmark = get(auxiliary, 'in_bookmark');
    const bookmarkId = get(auxiliary, 'bookmark_article_id', '');
    const bucketId = get(auxiliary, 'bucket_article_id', '');
    const isNewsletter = get(auxiliary, 'in_newsletter_bucket');
    const auxiliaryPlace = get(
      accounts,
      `${get(auxiliary, 'original_content.ids.account[0].id')}.title`,
      ''
    );
    const approvedCustomizedData = get(auxiliary, 'customized_data', {});
    const internalContent = get(auxiliary, 'internal_content', '');
    const sponseredContent = get(auxiliary, 'sponsored_content', '');
    const place = auxiliaryPlace || get(account, `title`, '');
    const date = `${extractedDate[0]} ${extractedDate[1]}, ${extractedDate[2]}`;
    const title = get(account, `title`, '');
    const description = get(item, `text`, '');
    const link = get(item, 'links.social_permalink', '');
    const channelId = get(ids, 'channel[0]');
    const themeIds = get(ids, 'theme', []);
    const themeId = get(ids, 'theme[0]');
    const sharedChannels = [];
    const recentSharedChannel = [];
    const tickers = get(item, 'tickers', '').split(',');
    get(auxiliary, 'shared.details', []).forEach((detail) => {
      const networkId = get(detail, 'destination.network');
      recentSharedChannel.push(networkId);
    });
    const recentReachCount = get(
      auxiliary,
      'shared.details[0].engagement.network.reach',
      0
    );
    const recentLinkCount = get(
      auxiliary,
      'shared.details[0].engagement.network.link_click',
      0
    );
    const isGivingCompass =
      toLower(description).indexOf('giving compass') !== -1;
    let networkDetails = {};
    get(item, 'engagement.users.current.details', []).forEach((engagement) => {
      const destinationId = get(engagement, 'destination.network');
      networkDetails = get(engagement, 'engagement.network', {});
      sharedChannels.push(destinationId);
    });
    return {
      id,
      url,
      topic,
      title,
      description,
      place,
      date,
      link: link,
      year: extractedDate[2],
      channelId,
      themeId,
      sharedChannels,
      networkDetails,
      tickers,
      isGivingCompass,
      auxiliarySharing,
      auxiliary,
      themeIds,
      recentSharedChannel,
      recentReachCount,
      recentLinkCount,
      created_time: get(item, 'created_time'),
      sponseredContent,
      internalContent,
      approvedArticles: true,
      bucketId,
      isBookmark,
      bookmarkId,
      isNewsletter,
      approvedCustomizedData,
      updated_time: get(item, 'updated_time', ''),
    };
  });
};

export const sponsoredArticleData = (articles) => {
  const feed = get(articles, 'feed') || [];
  const accounts = get(articles, 'details.account', {});
  return feed.map((item) => {
    const id = get(item, 'id');
    const extractedDate = getMonthDate(get(item, 'updated_time', ''));
    const ids = get(item, 'ids');
    const accountId = get(ids, 'account');
    const url = getMediaUrl(item.media_urls);
    const auxiliary = get(item, 'auxiliary');
    const isNewsletter = get(auxiliary, 'in_newsletter_bucket');
    const bucketId = get(auxiliary, 'bucket_article_id', '');
    const isBookmark = get(auxiliary, 'in_bookmark');
    const bookmarkId = get(auxiliary, 'bookmark_article_id', '');
    const topic = get(auxiliary, 'title');
    const auxiliarySharing = get(auxiliary, 'sharing.allowed');
    const account = get(accounts, `${accountId}`, '');
    const auxiliaryPlace = get(
      accounts,
      `${get(auxiliary, 'original_content.ids.account[0].id')}.title`,
      ''
    );
    const internalContent = get(auxiliary, 'internal_content', '');
    const sponseredContent = get(auxiliary, 'sponsored_content', '');
    const place = auxiliaryPlace || get(account, `title`, '');
    const date = `${extractedDate[0]} ${extractedDate[1]}`;
    const title = get(account, `title`, '');
    const description = get(item, `text`, '');
    const link = get(item, 'links.social_permalink');
    const channelId = get(ids, 'channel[0]');
    const themeIds = get(ids, 'theme', []);
    const themeId = get(ids, 'theme[0]');
    const sharedChannels = [];
    const recentSharedChannel = [];
    const tickers = get(item, 'tickers', '').split(',');
    get(auxiliary, 'shared.details', []).forEach((detail) => {
      const networkId = get(detail, 'destination.network');
      recentSharedChannel.push(networkId);
    });
    const recentReachCount = get(
      auxiliary,
      'shared.details[0].engagement.network.reach',
      0
    );
    const recentLinkCount = get(
      auxiliary,
      'shared.details[0].engagement.network.link_click',
      0
    );
    const sponsoredFunds = JSON.parse(get(item, 'sponsored_funds', null));
    const isGivingCompass =
      toLower(description).indexOf('giving compass') !== -1;
    let networkDetails = {};
    get(item, 'engagement.users.current.details', []).forEach((engagement) => {
      const destinationId = get(engagement, 'destination.network');
      networkDetails = get(engagement, 'engagement.network', {});
      sharedChannels.push(destinationId);
    });
    return {
      id,
      url,
      topic,
      title,
      description,
      place,
      date,
      link: link,
      year: extractedDate[2],
      channelId,
      themeId,
      sharedChannels,
      networkDetails,
      tickers,
      isGivingCompass,
      auxiliarySharing,
      auxiliary,
      themeIds,
      recentSharedChannel,
      recentReachCount,
      recentLinkCount,
      created_time: get(item, 'created_time'),
      sponseredContent,
      internalContent,
      sponsoredFunds,
      bucketId,
      isNewsletter,
      isBookmark,
      bookmarkId,
      updated_time: get(item, 'updated_time', ''),
    };
  });
};

export const getContentLibArticleData = (contentLibArticles, sourceRoute) => {
  const feed =
    sourceRoute === 'ats'
      ? get(contentLibArticles, 'feed', [])
      : get(contentLibArticles, 'channel_content', []);
  const channel = get(contentLibArticles, 'details.channel', {});
  if (isEmpty(feed)) return [];
  return feed.map((item) => {
    const id = get(item, 'id');
    const updatedTime = get(item, 'updated_time');
    const isCascade = get(item, 'is_cascaded');
    const extractedDate = getMonthDate(get(item, 'updated_time', ''));
    const ids = get(item, 'ids');
    const auxiliary = get(item, 'auxiliary');
    const bucketId = get(auxiliary, 'bucket_article_id', '');
    const topic = get(auxiliary, 'title');
    const accountId = get(ids, 'account.id', '');
    const url = getMediaImage(
      get(item.media_urls, '[0][0]', get(item.media_urls, '[0]', ''))
    );
    // const url =
    // sourceRoute === 'campaign'
    //   ? getMediaImage(
    //       get(item.media_urls, '[0][0]', get(item.media_urls, '[0]', ''))
    //     )
    //   : getMediaUrl(item.media_urls, source);
    const mediaUrl =
      item.media_urls && item.media_urls[0] ? item.media_urls[0] : [];
    const auxiliarySharing = get(auxiliary, 'sharing.allowed');
    const isNewsletter = get(auxiliary, 'in_newsletter_bucket', false);
    const isBookmark = get(auxiliary, 'in_bookmark');
    const bookmarkId = get(auxiliary, 'bookmark_article_id', '');
    const account = get(channel, `${accountId}`, '');
    const auxiliaryPlace = get(
      channel,
      `${get(auxiliary, 'original_content.ids.account[0].id')}.title`,
      ''
    );
    const internalContent = get(auxiliary, 'internal_content', '');
    const place = auxiliaryPlace || get(account, `title`, '');
    const date = `${extractedDate[0]} ${extractedDate[1]}, ${extractedDate[2]}`;
    const title = get(account, `title`, '');
    const description = get(item, `text`, '');
    const link = get(item, 'links.social_permalink');
    const channelId = get(ids, 'channel.id', '');
    let themeIDArray = get(ids, 'theme');
    let themeIds =
      themeIDArray.length > 0 &&
      themeIDArray.map((data, index) => {
        return data.id;
      });
    const themeId = get(ids, 'theme[0].id');
    const isGivingCompass =
      toLower(description).indexOf('giving compass') !== -1;
    return {
      id,
      url,
      mediaUrl,
      topic,
      title,
      description,
      place,
      date,
      updatedTime,
      link: link,
      year: extractedDate[2],
      channelId,
      themeIds,
      themeId,
      isGivingCompass,
      isCascade,
      auxiliarySharing,
      auxiliary,
      internalContent,
      created_time: get(item, 'updated_time'),
      isNewsletter,
      bucketId,
      isBookmark,
      bookmarkId,
      updated_time: get(item, 'updated_time', ''),
    };
  });
};

export const getScheduledArticleData = (articles) => {
  const feed = get(articles, 'feed') || [];
  const accounts = get(articles, 'details.account', {});
  return feed.map((item) => {
    const id = get(item, 'id');
    const scheduledData = get(item, 'auxiliary.scheduled.details', []);
    const data = scheduledData[0];
    const extractedDate = get(item, 'publish_time', '')
      ? getMonthDate(get(item, 'publish_time', ''))
      : getMonthDate(get(data, 'date.scheduled', ''));
    const ids = get(item, 'ids');
    const accountId = get(ids, 'account');
    const accountIds = get(ids, 'account.id');
    const url = getMediaUrl(item.media_urls);
    const mediaUrl =
      item.media_urls && item.media_urls[0] ? item.media_urls[0] : [];
    const auxiliary = get(item, 'auxiliary');
    const topic = get(auxiliary, 'title');
    const auxiliarySharing = get(auxiliary, 'sharing.allowed');
    const account = get(accounts, `${accountId}`, '');
    const secAccount = get(accounts, `${accountIds}`, '');
    const source = get(auxiliary, 'source');
    const sponsor = get(auxiliary, 'is_sponsored');
    const auxiliaryPlace = get(
      accounts,
      `${get(auxiliary, 'original_content.ids.account[0].id')}.title`,
      ''
    );
    const originalDate = get(item, 'publish_time', '')
      ? get(item, 'publish_time', '')
      : get(data, 'date.scheduled', '');
    const place =
      auxiliaryPlace ||
      get(account, `title`, '') ||
      get(secAccount, 'title', '');
    const date = `${extractedDate[0]} ${extractedDate[1]}, ${extractedDate[2]}`;
    const title = get(account, `title`, '');
    const description = get(item, `text`, '');
    const link = get(item, 'links.social_permalink');
    const channelId = get(ids, 'channel[0]');
    const themeId = get(ids, 'theme[0]');
    const sharedChannels = [];
    const isGivingCompass =
      toLower(description).indexOf('giving compass') !== -1;
    let scheduledDateTime = null;
    const scheduleDetails = get(item, 'auxiliary.scheduled.details', []);
    get(item, 'auxiliary.scheduled.details', []).forEach((engagement) => {
      const destinationId = get(engagement, 'destination.network');
      scheduledDateTime = get(engagement, 'date.scheduled');
      sharedChannels.push(destinationId);
    });
    const scheduledIds = [];
    get(auxiliary, 'scheduled.details', []).forEach((schedule) => {
      const scheduleId = get(schedule, 'id');
      scheduledIds.push(scheduleId);
    });
    const summaries = [];
    get(auxiliary, 'scheduled.details', []).forEach((schedule) => {
      summaries.push({
        platform: schedule.destination.network,
        text: schedule.text,
        isCompliance: schedule.is_complianced,
      });
    });
    let contactList = '';
    get(auxiliary, 'scheduled.details', []).forEach((schedule) => {
      if (schedule.destination.network === 'em') {
        contactList =
          schedule.destination.ids && schedule.destination.ids.length > 0
            ? schedule.destination.ids[0].id
            : '';
      }
    });
    return {
      id,
      url,
      mediaUrl,
      topic,
      title,
      description,
      place,
      date,
      link: link,
      year: extractedDate[2],
      channelId,
      themeId,
      sharedChannels,
      scheduledDateTime,
      isGivingCompass,
      auxiliarySharing,
      auxiliary,
      scheduledIds,
      summaries,
      contactList,
      scheduleDetails,
      source,
      originalDate,
      sponsor,
      updated_time: get(item, 'updated_time', ''),
    };
  });
};

export const getRecentArticleData = (articles) => {
  const feed = get(articles, 'feed') || [];
  const accounts = get(articles, 'details.account', {});
  return feed.map((item) => {
    const sharedData = get(item, 'auxiliary.shared.details', []);
    const articleId = get(item, 'id');
    const data = sharedData[0];
    const id = get(data, 'id');
    const extractedDate = getMonthDate(get(data, 'date.published', ''));
    const ids = get(data, 'ids');
    const externalIds = get(item, 'ids');
    const externalChannelId = get(externalIds, 'channel', '');
    const externalChannel =
      typeof externalChannelId === 'object'
        ? get(accounts, `${externalChannelId.id}`, '')
        : get(accounts, `${externalChannelId}`, '');
    const accountId = get(ids, 'channel');
    const url = getMediaUrl(item.media_urls);
    const mediaUrl =
      item.media_urls && item.media_urls[0] ? item.media_urls[0] : [];
    const auxiliary = get(item, 'auxiliary');
    const topic = get(auxiliary, 'title');
    const auxiliarySharing = get(auxiliary, 'sharing.allowed');
    const account = get(accounts, `${accountId}`, '');
    const source = get(auxiliary, 'source');
    const sponsor = get(auxiliary, 'is_sponsored');
    const auxiliaryPlace = get(
      accounts,
      `${get(auxiliary, 'original_content.ids.account[0].id')}.title`,
      ''
    );
    const place =
      source && source === 'newsletter'
        ? get(externalChannel, 'title', '')
        : auxiliaryPlace || get(account, `title`, '');
    const date = `${extractedDate[0]} ${extractedDate[1]}, ${extractedDate[2]}`;
    const title = get(account, `title`, '');
    const description = get(data, `text`, '');
    const link = get(item, 'links.social_permalink');
    const channelId = get(ids, 'channel');
    // const themeIds = get(ids, 'theme', []);
    const themeId = get(ids, 'theme');
    const sharedChannels = [];
    const recentSharedChannel = [];
    const tickers = get(item, 'tickers', '').split(',');
    get(auxiliary, 'shared.details', []).forEach((detail) => {
      const networkId = get(detail, 'destination.network');
      recentSharedChannel.push(networkId);
    });
    const recentReachCount = get(
      auxiliary,
      'shared.details[0].engagement.network.reach',
      0
    );
    const recentLinkCount = get(
      auxiliary,
      'shared.details[0].engagement.network.link_click',
      0
    );
    const isGivingCompass =
      toLower(description).indexOf('giving compass') !== -1;
    let networkDetails = {};
    networkDetails.reach = 0;
    networkDetails.engagement = 0;
    get(auxiliary, 'shared.details', []).forEach((item) => {
      networkDetails.reach += item.engagement.network.reach;
      Object.keys(item.engagement.network).map((ele) => {
        if (ele !== 'reach')
          networkDetails.engagement += item.engagement.network[ele];
      });
    });
    get(item, 'engagement.users.current.details', []).forEach((engagement) => {
      const destinationId = get(engagement, 'destination.network');
      // networkDetails = get(engagement, 'engagement.network', {});
      sharedChannels.push(destinationId);
    });
    let recentSharedIds = [];
    get(auxiliary, 'shared.details', []).forEach((shared) => {
      const sharedId = get(shared, 'id');
      recentSharedIds.push(sharedId);
    });

    return {
      id,
      url,
      mediaUrl,
      topic,
      title,
      description,
      place,
      date,
      link: link,
      year: extractedDate[2],
      channelId,
      themeId,
      sharedChannels,
      networkDetails,
      tickers,
      isGivingCompass,
      auxiliarySharing,
      auxiliary,
      // themeIds,
      recentSharedChannel,
      recentReachCount,
      recentLinkCount,
      created_time: get(item, 'created_time'),
      sharedData,
      source,
      sponsor,
      articleId,
      recentSharedIds,
      updated_time: get(item, 'updated_time', ''),
    };
  });
};

export const getScheduledArticleDataTypeWise = (payload) => {
  const data = get(payload, 'data', []);
  const type = get(payload, 'type', '');
  const feeds = get(data, 'feed', []);
  let newData;
  if (type) {
    let filteredArticle = feeds.map((item) => {
      const details = get(item, 'auxiliary.scheduled.details', []);
      const filterDetails = details.filter((d) => d.scheduled_by === type);
      if (filterDetails && filterDetails.length > 0) {
        item.auxiliary.scheduled.details = filterDetails;
        return item;
      } else {
        return null;
      }
    });

    filteredArticle = compact(filteredArticle);

    if (filteredArticle && filteredArticle.length > 0) {
      newData = {
        details: get(payload, 'data.details', {}),
        feed: filteredArticle,
        pagination: get(payload, 'data.pagination', {}),
      };
    } else {
      newData = [];
    }
    return newData;
  } else {
    return data;
  }
};

export const getRecentArticlesTypeWise = (payload) => {
  const data = get(payload, 'data', []);
  const type = get(payload, 'type', '');
  const feeds = get(data, 'feed', []);
  let newData;
  // let currentMonth = new Date().getMonth();
  // let currentYear = new Date().getFullYear();
  let endDate = new Date();
  let startDate = new Date(endDate.getTime() - 7 * 24 * 60 * 60 * 1000);
  // let endDate = lastDayOfPreviousMonth(currentYear,currentMonth);
  if (type) {
    let filteredArticle = feeds.map((item) => {
      const details = get(item, 'auxiliary.shared.details', []);
      const filterDetails = details.filter(
        (d) =>
          new Date(d.date.published) < endDate &&
          new Date(d.date.published) >= startDate
      );
      if (filterDetails && filterDetails.length > 0) {
        item.auxiliary.shared.details = filterDetails;
        return item;
      } else {
        return null;
      }
    });
    filteredArticle = compact(filteredArticle);

    if (filteredArticle && filteredArticle.length > 0) {
      newData = {
        details: get(payload, 'data.details', {}),
        feed: filteredArticle,
        pagination: get(payload, 'data.pagination', {}),
      };
    } else {
      newData = [];
    }
    return newData;
  } else {
    return data;
  }
};

export const getBucketArticleData = (articles) => {
  const feed = get(articles, 'feed') || [];
  const accounts = get(articles, 'details.account', {});
  return feed.map((item) => {
    const id = get(item, 'id');
    const extractedDate = getMonthDate(get(item, 'updated_time', ''));
    const ids = get(item, 'ids');
    const accountId = get(ids, 'account');
    const url = getMediaUrl(item.media_urls);
    const auxiliary = get(item, 'auxiliary');
    const bucketId =
      get(auxiliary, 'bucket_article_id', '').length > 0
        ? get(auxiliary, 'bucket_article_id', '')
        : id;
    const topic = get(auxiliary, 'title');
    const auxiliarySharing = get(auxiliary, 'sharing.allowed');
    const account = get(accounts, `${accountId}`, '');
    const auxiliaryPlace = get(
      accounts,
      `${get(auxiliary, 'original_content.ids.account[0].id')}.title`,
      ''
    );
    const internalContent = get(auxiliary, 'internal_content', '');
    const sponseredContent = get(auxiliary, 'sponsored_content', false);
    const place = auxiliaryPlace || get(account, `title`, '');
    const date = `${extractedDate[0]} ${extractedDate[1]}`;
    const title = get(account, `title`, '');
    const description = get(item, `text`, '');
    const link = get(item, 'links.social_permalink', '');
    const channelId = get(ids, 'channel[0]');
    const themeIds = get(ids, 'theme', []);
    const themeId = get(ids, 'theme[0]');
    const sharedChannels = [];
    const recentSharedChannel = [];
    const tickers = get(item, 'tickers', '').split(',');
    get(auxiliary, 'shared.details', []).forEach((detail) => {
      const networkId = get(detail, 'destination.network');
      recentSharedChannel.push(networkId);
    });
    const recentReachCount = get(
      auxiliary,
      'shared.details[0].engagement.network.reach',
      0
    );
    const recentLinkCount = get(
      auxiliary,
      'shared.details[0].engagement.network.link_click',
      0
    );
    const sponsoredFunds = get(auxiliary, 'sponsored_funds', null);
    const isGivingCompass =
      toLower(description).indexOf('giving compass') !== -1;
    let networkDetails = {};
    get(item, 'engagement.users.current.details', []).forEach((engagement) => {
      const destinationId = get(engagement, 'destination.network');
      networkDetails = get(engagement, 'engagement.network', {});
      sharedChannels.push(destinationId);
    });
    return {
      id,
      url,
      topic,
      title,
      description,
      place,
      date,
      link: link,
      year: extractedDate[2],
      channelId,
      themeId,
      sharedChannels,
      networkDetails,
      tickers,
      isGivingCompass,
      auxiliarySharing,
      themeIds,
      recentSharedChannel,
      recentReachCount,
      recentLinkCount,
      created_time: get(item, 'created_time'),
      sponseredContent,
      internalContent,
      sponsoredFunds,
      bucketId,
    };
  });
};

const fetchMyContentArticles = async ({
  userType,
  channelId,
  beforeToken,
  afterToken,
  pendingArticles,
  filter,
}) => {
  const mode = beforeToken ? 'before' : afterToken ? 'after' : null;
  const token = beforeToken || afterToken;
  let pendingQueue =
    userType === 'isEnterpriseAdvisor' && mode ? pendingArticles : [];

  // always get next 8 myContent data with getContentLib() api -> for all users
  const contentLibRes = await getContentLibrary(channelId, mode, token, filter);
  let articlesArray = getContentLibArticleData(
    get(contentLibRes, 'result.data', [])
  );
  const paginationData = get(contentLibRes, 'result.data.pagination', {});

  if (userType === 'isEnterpriseAdvisor') {
    // getApproveToShareArticles() call only on component load
    if (!mode) {
      const atsArticlesRes = await getApproveToShareArticles({
        source: filter || 'all',
      });

      // pending queue = getApproveToShare() response -> on component load
      pendingQueue = getContentLibArticleData(
        get(atsArticlesRes, 'result.data', []),
        'ats'
      );
    }

    // if articlesArray = [] -> i.e. no more myContent data in getContentLibArtData() response
    if (!isEmpty(articlesArray)) {
      const dateWindow = {
        head: new Date(get(articlesArray[0], 'created_time', '')),
        tail: new Date(
          get(articlesArray[articlesArray.length - 1], 'created_time', '')
        ),
      };
      const idsArray = [];

      // articles present in pendingQueue within the date window -> pushed in articlesArray
      const atsSlice = !isEmpty(pendingQueue)
        ? pendingQueue.filter((article) => {
            const articleDate = new Date(article.created_time);
            if (!paginationData.after) {
              // if paginationData.after = null -> append pendingQueue data at the end of articlesArray
              idsArray.push(article.id);
              return true;
            } else {
              // articles more recent than last article in getContentLibrary() response -> pushed in articlesArray
              const compare = articleDate > dateWindow.tail;
              if (compare) idsArray.push(article.id);
              return compare;
            }
          })
        : [];

      // pendingQueue = pendingQueue - articles within the date window
   
      // if articles found in pendingQueue within date window -> push them in articlesArray
      if (!isEmpty(atsSlice)) {
        // pendingQueue = pendingQueue - articles within the date window
        pendingQueue = pendingQueue.filter(
          (article) => !idsArray.includes(article.id)
        );
        articlesArray = articlesArray.concat(atsSlice);
        // final sorting of article data (articlesArray) -> by date
        articlesArray.sort(
          (a, b) => new Date(b.created_time) - new Date(a.created_time)
        );
      }
    }

    // if getContentLibData() api response = [] -> push all articles left in pendingQueue to articlesArray and set pendingQueue = []
    else {
      articlesArray = pendingQueue;
      articlesArray.sort(
        (a, b) => new Date(b.created_time) - new Date(a.created_time)
      );
      pendingQueue = [];
    }
  }
  return {
    articles: articlesArray,
    pagination: paginationData,
    ...(userType === 'isEnterpriseAdvisor'
      ? { pendingArticlesQueue: pendingQueue }
      : {}),
  };
};

export const getMyContentArticles = ({ payload, localData, source }) => {
  return fetchMyContentArticles(payload)
    .then((res) => {
      const myContentArticlesCopy = cloneDeep(
        get(localData, 'currentArticles', [])
      );
      const updatedArticlesList = payload.afterToken
        ? myContentArticlesCopy.concat(get(res, 'articles', []))
        : get(res, 'articles', []);
      updatedArticlesList.sort(
        (a, b) => new Date(b.created_time) - new Date(a.created_time)
      );
      const newState =
        source === 'campaign'
          ? {
              myContentArticles: updatedArticlesList,
              myContentPaginationData: get(res, 'pagination', {
                before: null,
                after: null,
              }),
            }
          : {};
      const newLocalData =
        source === 'dashboard'
          ? {
              updatedArticles: updatedArticlesList,
              updatedPaginationData: get(res, 'pagination', {
                before: null,
                after: null,
              }),
            }
          : {};

      if (payload.userType === 'isEnterpriseAdvisor')
        newState.pendingArticles = get(
          res,
          'pendingArticlesQueue',
          payload.pendingArticles
        );
      return {
        newState,
        ...(source === 'dashboard' ? { newLocalData: newLocalData } : {}),
      };
    })
    .catch((err) => {
      console.log('ERROR IN CONTENT LIB USE EFFECT', err);
    });
};
