import * as React from 'react';

function BookmarkSimple(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" {...props}>
      <path
        d="M18 21l-6-3.75L6 21V4.5a.75.75 0 01.75-.75h10.5a.75.75 0 01.75.75V21z"
        stroke="currentColor"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

const MemoBookmarkSimple = React.memo(BookmarkSimple);
export default MemoBookmarkSimple;
