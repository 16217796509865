import React, { Component } from 'react';
import {
  ResponsiveContainer,
  LineChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Line,
} from 'recharts';
import theme from '../CommonStyles/colors';
import styled from 'styled-components';
import DefaultTooltipContent from 'recharts/lib/component/DefaultTooltipContent';
import { themesData } from '../../utils';

const axisFontStyle = {
  fontSize: '10px',
  stroke: themesData.purpleyGrey,
  strokeWidth: 0.5,
};

const LineChartWrapper = styled.div`
  height: 100%;
  .recharts-legend-item-text {
    font-size: 12px;
  }
  .recharts-tooltip-wrapper {
    border-radius: 4px;
    text-align: left;
    font-size: 12px;
    .recharts-default-tooltip {
      background-color: #000 !important;
      border-radius: 4px;
    }
    * {
      color: #fff;
    }
  }
`;
const CustomTooltip = (props) => {
  if (props.payload[0] != null) {
    const newPayload = [
      {
        value: props.payload[0].payload.date,
      },
      {
        name: 'score',
        value: props.payload[0].payload.mine,
        ...props.payload,
      },
    ];
    return <DefaultTooltipContent {...props} payload={newPayload} />;
  }
  return <DefaultTooltipContent {...props} />;
};

const BasicLineChart = ({ data, location }) => (
  <LineChartWrapper>
    <ResponsiveContainer width="100%" minHeight="180px">
      <LineChart
        data={data}
        margin={{
          left: -20,
          right: 20,
          top: 30,
        }}
      >
        <CartesianGrid horizontal vertical={false} strokeDasharray="3 3" />
        <XAxis tick={false} />
        <YAxis
          domain={[0, 100]}
          ticks={[0, 20, 40, 60, 80, 100]}
          tick={{ fontSize: '12px' }}
        />
        {location === 'insightscore' ? (
          <Tooltip
            cursor={false}
            content={<CustomTooltip />}
            labelFormatter={() => undefined}
          />
        ) : (
          <Tooltip cursor={false} />
        )}
        <Line
          type="monotone"
          dataKey="mine"
          stroke="#6351ed"
          strokeWidth="3"
          activeDot={{ stroke: '#000', strokeWidth: 1, r: 4 }}
          dot={{ fill: '#6351ed', strokeWidth: 2 }}
        />
      </LineChart>
    </ResponsiveContainer>
  </LineChartWrapper>
);

export default BasicLineChart;
