import { Flex } from '@rebass/grid';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { Button, ErrorContainer, LoaderWrapper, ModalBody, ModalCloseButton, ModalHeader, ModalV2 } from '../../../../components';

const CascadeCampaignModal = ({ 
    close, 
    okHandler, 
    reviewHandler,
    useCascadedCampaign, 
    loader, 
    success, 
    error,
    blockCreate,
    recordType
}) => {

    return (
        <ModalV2 bgColor='#fff' width='40%' isOpen={true} onRequestClose={close}>
            <ModalHeader>
                {success ? 'Success' : 'Confirm'}
                <ModalCloseButton onClick={close} />
            </ModalHeader>
            <ModalBody>
                {success ? (
                    <>
                        <div style={{color: '#49484a'}}>This campaign has been successfully created as draft. You can continue to schedule and edit by clicking on "Review Campaign" or return to the Campaign Dashboard by clicking "Go To Dashboard".</div>
                        <LoaderWrapper isLoading={loader}>
                            <Flex width='70%' justifyContent='space-around' margin='auto'>
                               <Button onClick={reviewHandler}>Review Campaign</Button>
                                <Button onClick={okHandler}>Go To Dashboard</Button>
                            </Flex>
                        </LoaderWrapper>
                    </>
                ) : (
                    <>
                        <div>Are you sure you want to use this campaign?</div>
                        {error && (
                            <div style={{fontSize: '10px', color: '#6351ed'}}>{error}</div>
                        )}
                        <LoaderWrapper isLoading={loader}>
                            <Flex 
                                justifyContent='space-around' 
                                width='33%' 
                                margin='auto' 
                                style={{position: 'relative', alignItems: 'center'}}
                            >
                                    <Button disabled={blockCreate} onClick={useCascadedCampaign}>
                                        Yes
                                    </Button>
                                    <Button disabled={blockCreate} onClick={close}>No</Button>
                                </Flex>
                        </LoaderWrapper>
                        {blockCreate && (
                            <ErrorContainer style={{marginTop: '10px'}}>Create personalised email (signature is mandatory) custom rows from manage rows.</ErrorContainer>
                        )}
                    </>
                )}
            </ModalBody>
        </ModalV2>
    )
}

export default CascadeCampaignModal;