import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { getChannelId } from '../../redux/selector';
import { getImpactDetails } from '../../services/analytics';
import {
  BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, PieChart, Pie, Sector
} from 'recharts';
import {
  PdfWrapper,
  Container,
  Title,
  DateRange,
  CardContainer,
  Card,
  CardBody,
  CardCount,
  CardTitle,
  PageSection,
  PdfHeader,
  OtherPageSection,
  OtherPageTitle,
  GraphWrapper,
  GraphTitle,
  SubTitle
} from './ImpactSummary.styles';
import { logo } from '../../utils/constants';

const format = (inputDate) => {
  let date = new Date(inputDate);
  if (!isNaN(date.getTime())) {
      let day = date.getDate().toString();
      let month = (date.getMonth() + 1).toString();

      return (month[1] ? month : '0' + month[0]) + '/' +
         (day[1] ? day : '0' + day[0]) + '/' + 
         date.getFullYear();
  }
}



const ImpactSummary = (props) => {

  const [impactSummary, setImpactSummary] = useState({});
  const [totalShares, setTotalShares] = useState([]);
  const [shareTopicsData, setShareTopicsData] = useState([]);
  const [sharePlatformData, setSharePlatformData] = useState([]);
  const [totalClickData, setTotalClickData] = useState([]);
  const [clickTopicData, setClickTopicData] = useState([]);
  const [clickPlatformData, setClickPlatformData] = useState([]);
  const [totalViewData, setTotalViewData] = useState([]);
  const [viewTopicData, setViewTopicData] = useState([]);
  const [viewPlatformData, setViewPlatformData] = useState([]);
  const [uniqueMembers, setUniqueMembers] = useState([]);
  const [insightScoreData, setInsightScoreData] = useState([]);

  useEffect(() => {
    const { channelId, analyticsObject } = props;
    const { startDate, endDate } = analyticsObject; 
    getImpactDetails({
      channelId,
      startDate,
      endDate,
    }).then((response) => {
      setImpactSummary(response);
      let tshares = [], shareTopics = [], sharePlatforms = [], tClicks = [], clickTopic = [], clickPlatform = [], tViews = [], viewTopic = [], 
      viewPlatform = [], uniMembers = [], insScore = [];
      const totalShares = response.result.data.analytics.type.shares.by.date_range;
      const shareByTopics = response.result.data.analytics.type.shares.by.topics;
      const shareByPlatforms = response.result.data.analytics.type.shares.by.platforms;
      const totalClicks = response.result.data.analytics.type.clicks.by.date_range;
      const clickByTopic = response.result.data.analytics.type.clicks.by.topics;
      const clickByPlatforms = response.result.data.analytics.type.clicks.by.platforms;
      const totalViews = response.result.data.analytics.type.reach.by.date_range;
      const viewByTopic = response.result.data.analytics.type.reach.by.topics;
      const viewByPlatforms = response.result.data.analytics.type.reach.by.platforms;
      const uniqueMembers =  response.result.data.unique_members;
      const insightScore = response.result.data.insight_score.current;
      const theme = response.result.data.details.theme;
      console.log(theme);
      Object.keys(totalShares).forEach(key => {
          tshares.push({
            name: key,
            mine: totalShares[key].mine,
            other: totalShares[key].others
          })
      })
      setTotalShares(tshares);
      Object.keys(shareByTopics).forEach(key => {
        shareTopics.push({
          name: theme[key].name,
          mine: shareByTopics[key].mine,
          other: shareByTopics[key].others
        })
      })
      setShareTopicsData(shareTopics);
      Object.keys(shareByPlatforms).forEach(key => {
        sharePlatforms.push({
          name: key,
          mine: shareByPlatforms[key].mine,
          other: shareByPlatforms[key].others
        })
      })
      setSharePlatformData(sharePlatforms);
      Object.keys(totalClicks).forEach(key => {
        tClicks.push({
          name: key,
          mine: totalClicks[key].mine,
          other: totalClicks[key].others
        })
    })
    setTotalClickData(tClicks);
    Object.keys(clickByTopic).forEach(key => {
      clickTopic.push({
        name: theme[key].name,
        mine: clickByTopic[key].mine,
        other: clickByTopic[key].others
      })
    })
    setClickTopicData(clickTopic);
    Object.keys(clickByPlatforms).forEach(key => {
      clickPlatform.push({
        name: key,
        mine: shareByPlatforms[key].mine,
        other: shareByPlatforms[key].others
      })
    })
    setClickPlatformData(clickPlatform);
    Object.keys(totalViews).forEach(key => {
      tViews.push({
        name: key,
        mine: totalViews[key].mine,
        other: totalViews[key].others
      })
  })
  setTotalViewData(tViews);
  Object.keys(viewByTopic).forEach(key => {
    viewTopic.push({
      name: theme[key].name,
      mine: viewByTopic[key].mine,
      other: viewByTopic[key].others
    })
  })
  setViewTopicData(viewTopic);
  Object.keys(viewByPlatforms).forEach(key => {
    viewPlatform.push({
      name: key,
      mine: viewByPlatforms[key].mine,
      other: viewByPlatforms[key].others
    })
  })
  setViewPlatformData(viewPlatform);
  Object.keys(uniqueMembers).forEach(key => {
    uniMembers.push({
      name: key,
      mine: uniqueMembers[key],
    })
  })
  setUniqueMembers(uniMembers);
  Object.keys(insightScore).forEach(key => {
    insScore.push({
      name: key,
      value: insightScore[key],
    })
  })
  setInsightScoreData(insScore);
    });
  }, []);

  if (Object.keys(impactSummary).length) {
  return (
    <PdfWrapper>
      <PdfHeader>
        <img src={logo['01']} style={{width: '95px', height: '100%', marginRight: '60px'}} alt='Logo' />
      </PdfHeader>
      <Container>
        <Title>
          Executive Summary
        </Title>
        <DateRange>
          Date {format(props.analyticsObject.startDate)} to {format(props.analyticsObject.endDate)} 
        </DateRange>
        <CardContainer>
          <Card>
            <CardBody>
              <CardCount>{impactSummary.result.data.analytics.type.total.members.all}</CardCount>
              <CardTitle>Total Members</CardTitle>
            </CardBody>
          </Card>
          <Card>
            <CardBody>
              <CardCount>{impactSummary.result.data.analytics.type.total.members.all}</CardCount>
              <CardTitle>Total Shares</CardTitle>
            </CardBody>
          </Card>
        </CardContainer>
        <CardContainer>
          <Card>
            <CardBody>
              <CardCount>{impactSummary.result.data.analytics.type.total.insights_uploaded}</CardCount>
              <CardTitle>Insights Uploaded</CardTitle>
            </CardBody>
          </Card>
          <Card>
            <CardBody>
              <CardCount>{impactSummary.result.data.analytics.type.total.members.all}</CardCount>
              <CardTitle>Total Clicks</CardTitle>
            </CardBody>
          </Card>
        </CardContainer>
        <CardContainer>
          <Card>
            <CardBody>
              <CardCount>{impactSummary.result.data.analytics.type.total.leads}</CardCount>
              <CardTitle>Total Leads</CardTitle>
            </CardBody>
          </Card>
          <Card>
            <CardBody>
              <CardCount>{impactSummary.result.data.analytics.type.total.members.invited}</CardCount>
              <CardTitle>Invited Members</CardTitle>
            </CardBody>
          </Card>
        </CardContainer>
        <CardContainer>
          <Card>
            <CardBody>
              <CardCount>{impactSummary.result.data.analytics.type.total.members.new}</CardCount>
              <CardTitle>New Members</CardTitle>
            </CardBody>
          </Card>
        </CardContainer>
        <PageSection>

        </PageSection>

      </Container>
      <PdfHeader>
        <img src={logo['01']} style={{width: '95px', height: '100%', marginRight: '60px'}} alt='Logo' />
      </PdfHeader>
      <Container>
      <OtherPageSection>
        <OtherPageTitle>
          Impact Report
        </OtherPageTitle>
        <DateRange>
          Date {format(props.analyticsObject.startDate)} to {format(props.analyticsObject.endDate)}
        </DateRange>
      </OtherPageSection>
      <GraphTitle>Total Shares</GraphTitle>  
      <GraphWrapper>
      {totalShares.length > 0 ?  
      <BarChart
        width={500}
        height={250}
        data={totalShares}
        margin={{
          top: 5, right: 30, left: 20, bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar dataKey="mine" name='My Content' fill="#e4e4e4" />
        <Bar dataKey="other" name='Other Content' fill="#d5d5d5" />
      </BarChart>
      : null
      }
      </GraphWrapper>
      <GraphTitle>Share By Topics</GraphTitle>  
      <GraphWrapper>
      {shareTopicsData.length > 0 ?  
      <BarChart
        width={500}
        height={300}
        data={shareTopicsData}
        margin={{
          top: 5, right: 30, left: 20, bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar dataKey="mine" name='My Content' fill="#e4e4e4" />
        <Bar dataKey="other" name='Other Content' fill="#d5d5d5" />
      </BarChart>
      : null
      }
      </GraphWrapper>
      <GraphTitle>Share By Platforms</GraphTitle>  
      <GraphWrapper>
      {sharePlatformData.length > 0 ?  
      <BarChart
        width={500}
        height={300}
        data={sharePlatformData}
        margin={{
          top: 5, right: 30, left: 20, bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar dataKey="mine" name='My Content' fill="#e4e4e4" />
        <Bar dataKey="other" name='Other Content' fill="#d5d5d5" />
      </BarChart>
      : null
      }
      </GraphWrapper>
      </Container>
      <PdfHeader>
        <img src={logo['01']} style={{width: '95px', height: '100%', marginRight: '60px'}} alt='Logo' />
      </PdfHeader>
      <Container>
      <OtherPageSection>
        <OtherPageTitle>
          Impact Report
        </OtherPageTitle>
        <DateRange>
          Date {format(props.analyticsObject.startDate)} to {format(props.analyticsObject.endDate)}
        </DateRange>
      </OtherPageSection>
      <GraphTitle>Total Clicks</GraphTitle>  
      <GraphWrapper>
      {totalClickData.length > 0 ?  
      <BarChart
        width={500}
        height={250}
        data={totalClickData}
        margin={{
          top: 5, right: 30, left: 20, bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar dataKey="mine" name='My Content' fill="#e4e4e4" />
        <Bar dataKey="other" name='Other Content' fill="#d5d5d5" />
      </BarChart>
      : null
      }
      </GraphWrapper>
      <GraphTitle>Clicks By Topics</GraphTitle>  
      <GraphWrapper>
      {clickTopicData.length > 0 ?  
      <BarChart
        width={500}
        height={300}
        data={clickTopicData}
        margin={{
          top: 5, right: 30, left: 20, bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar dataKey="mine" name='My Content' fill="#e4e4e4" />
        <Bar dataKey="other" name='Other Content' fill="#d5d5d5" />
      </BarChart>
      : null
      }
      </GraphWrapper>
      <GraphTitle>Clicks By Platforms</GraphTitle>  
      <GraphWrapper>
      {clickPlatformData.length > 0 ?  
      <BarChart
        width={500}
        height={300}
        data={clickPlatformData}
        margin={{
          top: 5, right: 30, left: 20, bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar dataKey="mine" name='My Content' fill="#e4e4e4" />
        <Bar dataKey="other" name='Other Content' fill="#d5d5d5" />
      </BarChart>
      : null
      }
      </GraphWrapper>
      </Container>
      <PdfHeader>
        <img src={logo['01']} style={{width: '95px', height: '100%', marginRight: '60px'}} alt='Logo' />
      </PdfHeader>
      <Container>
      <OtherPageSection>
        <OtherPageTitle>
          Impact Report
        </OtherPageTitle>
        <DateRange>
          Date {format(props.analyticsObject.startDate)} to {format(props.analyticsObject.endDate)}
        </DateRange>
      </OtherPageSection>
      <GraphTitle>Total Views</GraphTitle>  
      <GraphWrapper>
      {totalViewData.length > 0 ?  
      <BarChart
        width={500}
        height={250}
        data={totalViewData}
        margin={{
          top: 5, right: 30, left: 20, bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar dataKey="mine" name='My Content' fill="#e4e4e4" />
        <Bar dataKey="other" name='Other Content' fill="#d5d5d5" />
      </BarChart>
      : null
      }
      </GraphWrapper>
      <GraphTitle>Views By Topics</GraphTitle>  
      <GraphWrapper>
      {viewTopicData.length > 0 ?  
      <BarChart
        width={500}
        height={300}
        data={viewTopicData}
        margin={{
          top: 5, right: 30, left: 20, bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar dataKey="mine" name='My Content' fill="#e4e4e4" />
        <Bar dataKey="other" name='Other Content' fill="#d5d5d5" />
      </BarChart>
      : null
      }
      </GraphWrapper>
      <GraphTitle>Views By Platforms</GraphTitle>  
      <GraphWrapper>
      {viewPlatformData.length > 0 ?  
      <BarChart
        width={500}
        height={300}
        data={viewPlatformData}
        margin={{
          top: 5, right: 30, left: 20, bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar dataKey="mine" name='My Content' fill="#e4e4e4" />
        <Bar dataKey="other" name='Other Content' fill="#d5d5d5" />
      </BarChart>
      : null
      }
      </GraphWrapper>
      </Container>
      <PdfHeader>
        <img src={logo['01']} style={{width: '95px', height: '100%', marginRight: '60px'}} alt='Logo' />
      </PdfHeader>
      <Container>
      <OtherPageSection>
        <OtherPageTitle>
          Members Overview
        </OtherPageTitle>
        <DateRange>
          Date {format(props.analyticsObject.startDate)} to {format(props.analyticsObject.endDate)}
        </DateRange>
      </OtherPageSection>
      <GraphTitle>Unique Members</GraphTitle>  
      <GraphWrapper>
      {uniqueMembers.length > 0 ?  
      <BarChart
        width={500}
        height={250}
        data={uniqueMembers}
        margin={{
          top: 5, right: 30, left: 20, bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar dataKey="mine" name='Data' fill="#e4e4e4" />
      </BarChart>
      : null
      }
      </GraphWrapper>
      <Title>Clout</Title>
      <SubTitle>Clout is between 0-100 and it depends on how much content the members engage with, their network size and their network engagement.</SubTitle>
      <GraphWrapper>
        {insightScoreData.length > 0 ? 
       <PieChart width={400} height={400}>
       <Pie data={insightScoreData} dataKey="value" cx={200} cy={200} innerRadius={70} outerRadius={90} fill="#e4e4e4" label />
     </PieChart>
      : null
      }
      </GraphWrapper>
      </Container>
    </PdfWrapper>
  )
} else {
  return null;
}
} 
const mapStateToProps = (state) => ({
  channelId: getChannelId(state),
  analyticsObject: state.analytics,
});

export default connect(mapStateToProps)(ImpactSummary);
