import React, { useState, useEffect, Suspense } from 'react';

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { Flex } from '@rebass/grid';
import { useParams, useHistory } from 'react-router-dom';
import { Button } from '../../components';
import { connect } from 'react-redux';
import { get } from 'lodash';
const CampaignBasicDetailsComponent = React.lazy(() => import('./BasicDetails'));
const CampaignSendDataComponent = React.lazy(() => import('./EmailSendData'));
const CampaignEngagementDataComponent = React.lazy(() => import('./EmailEngagementData'));
const CampaignSocialSendDataComponent = React.lazy(() => import('./SocialSendData'));



const CampaignViewContainer = ({
  userData
}) => {

  const history = useHistory();

  const redirectToDashboard = () => {
    history.push("/campaignV2")
  };
  const { id } = useParams();

  const [selectedTab, setSelectedTab] = useState();
  const toggleTab = (event, newValue) => {
    setSelectedTab(newValue);
  };


  useEffect(() => {
    const windowBodyClassList = document.body.classList;
    if (!windowBodyClassList.contains('campaign'))
      windowBodyClassList.add('campaign');
    return () => {
      windowBodyClassList.remove('campaign');
    };
  }, []);

  return (

    <Flex
      margin="20px auto"
      flexDirection="column"
    >
      <Flex>
        <Button margin="0px" onClick={redirectToDashboard}>Back</Button>
      </Flex>
      <Tabs value={selectedTab}>

        <TabList onClick={toggleTab}>
          <Tab value="1">Basic Details</Tab>
          <Tab value="2">Email Send Data</Tab>
          <Tab value="3">Email Engagement Data</Tab>
          <Tab value="4">Social Send Data</Tab>
        </TabList>

        <TabPanel value="1">
          <Suspense fallback={null}>
            <CampaignBasicDetailsComponent id={id} 
            timezone={get(
              userData,
              'details.user.timezone',
              ''
            )}
            />
          </Suspense>
        </TabPanel>
        <TabPanel value="2">
          <Suspense fallback={null}>
            <CampaignSendDataComponent id={id}
            timezone={get(
              userData,
              'details.user.timezone',
              ''
            )}
            />
          </Suspense>
        </TabPanel>
        <TabPanel value="3">
          <Suspense fallback={null}>
            <CampaignEngagementDataComponent id={id} />
          </Suspense>
        </TabPanel>
        <TabPanel value="4">
          <Suspense fallback={null}>
            <CampaignSocialSendDataComponent id={id}
            timezone={get(
              userData,
              'details.user.timezone',
              ''
            )}
            />
          </Suspense>
        </TabPanel>
      </Tabs>
    </Flex>

  )
}

const mapStateToProps = (state) => {
  return {
    userData: state.user.data,
  }
}
export default connect(mapStateToProps, null)(CampaignViewContainer)