/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { get, isEmpty, uniqBy } from 'lodash';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { ButtonText, ButtonIcon, ArrowIcon } from './styles';
import {
  actions as analyticsActions,
  moreActions as analyticsMoreActions,
} from '../../redux/modules/analytics.module';
import { getRoleType, getUserRole } from '../../redux/selector';
import {
  ColorPallete,
  ROLE_TYPES,
  Typography,
  USER_ROLE,
  formatMomentIsoDate,
  groupBy,
} from '../../utils';
import Content from 'src/pages/Insights/Content';
import CampaignTab from './CampaignTab';
import {
  AnalyticsContainer,
  DropDownWrapper,
} from './AnalyticsContainer.styles';
import ToggleBar from '../../components/ToggleBar';
import PurpleButton from '../../componentsV2/PurpleButton';
import WhiteButton from '../../componentsV2/WhiteButton';
import moment from 'moment';

//icons
import downArrowBlack from '../../assets/icons/downArrowBlack.svg';
import calendarIcon from '../../assets/icons/calendarIcon.svg';
import whiteDownload from '../../assets/icons/whiteDownload.svg';
import { ContactsTab } from './ContactsTab';
import { Flex } from '@rebass/grid';
import { ContentTab } from './ContentTab';
import ExportToCsv from './EnagagementTab/ExportToCsv';
import { getAnalyticsUsers } from '../../services/analyticsV2';
import UserDropdownAnalytics from './UserDropdownAnalytics/UserDropdownAnalytics';
import { actions as enterpriseActions } from 'src/redux/modules/enterprise.module';
import Contacts from 'src/pages/Insights/Contacts';

export const formatDate = (timestamp) => {
  const newDate = new Date(timestamp);
  const dateOptions = {
    weekday: 'long',
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
  };
  return newDate.toLocaleDateString('en-US', dateOptions);
};

const toggleItems = [
  // {
  //   name: 'Overview',
  //   key: 0
  // },
  {
    name: 'Campaigns',
    key: 0,
  },
  {
    name: 'Email Contacts',
    key: 1,
  },
  {
    name: 'Email Content',
    key: 2,
  },
  // {
  //   name: 'Platforms',
  //   key: 4
  // },
];

const AdvisorAnalyticsContainer = ({
  timeZone,
  userData,
  isAdmin,
  isEnterpriseLevelAdmin,
  firmsList,
  setEnterpriseStatus,
  enterpriseData,
  setAnalyticsDate,
}) => {
  const { search } = useLocation();
  const [tabIndex, setTabIndex] = useState(0);
  const [showCalendarDropdown, setShowCalendarDropdown] = useState(false);
  const [showCSVDownloadDropdown, setShowCSVDownloadDropdown] = useState(false);
  const [startDate, setStartDate] = useState();
  const [selectedOption, setSelectedOption] = useState('Previous 90 Days');
  const [exportToCsvModal, setExportToCsvModal] = useState(false);
  const [showEnterpriseDropdown, setShowEnterpriseDropdown] = useState(false);
  const [
    showEnterpriseDropdownSelectedOption,
    setShowEnterpriseDropdownSelectedOption,
  ] = useState('My Activity');
  const [userList, setUserList] = useState([]);
  const [selectedDropdownFirms, setSelectedDropdownFirms] = useState([]);
  const [selectedAdvisors, setSelectedAdvisors] = useState([]);
  const [advisorList, setAdvisorList] = useState([]);
  const [enterprise, setEnterprise] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [searchString, setSearchString] = useState('');

  const dropdownWrapper = useRef(null);
  const endDate = formatMomentIsoDate(moment());

  useEffect(() => {
    if (!!search) {
      const parsedSearch = queryString.parse(search);
      if (parsedSearch?.section === 'contacts') {
        setTabIndex(1);
      } else if (parsedSearch?.section === 'content') {
        setTabIndex(2);
      } else {
        setTabIndex(0);
      }
    }
  }, [search]);

  useEffect(() => {
    if (!isEmpty(get(enterpriseData, 'advisors', []))) {
      setShowEnterpriseDropdownSelectedOption('My Enterprise');
      setEnterprise(true);
    }
    if (get(enterpriseData, 'isEnterprise', false) === true) {
      setShowEnterpriseDropdownSelectedOption('My Enterprise');
      setEnterprise(true);
      setSelectedUsers([]);
    } else {
      setSelectedUsers(get(enterpriseData, 'advisors', []).map((s) => s.id));
    }
    setSelectedOption(get(enterpriseData, 'date', 'Previous 90 Days'));
    setSelectedAdvisors(get(enterpriseData, 'advisors', []));
  }, []);

  const handleClickOutside = (e) => {
    if (
      dropdownWrapper.current &&
      !dropdownWrapper.current.contains(e.target)
    ) {
      setShowCalendarDropdown(false);
    }
  };

  const handleApply = () => {
    if (selectedAdvisors.length === advisorList.length) {
      setShowEnterpriseDropdownSelectedOption('My Enterprise');
      setSelectedUsers([]);
      setEnterpriseStatus(true, selectedAdvisors);
    } else {
      setSelectedUsers(selectedAdvisors.map((s) => s.id));
      setShowEnterpriseDropdownSelectedOption('');
      setEnterpriseStatus(false, selectedAdvisors);
    }
    setShowEnterpriseDropdown(false);
    setSelectedDropdownFirms([]);
  };

  const calculateStartDate = (type) => {
    const currentDate = moment();
    switch (type) {
      case 'Previous 180 Days':
        setStartDate(formatMomentIsoDate(currentDate.subtract(179, 'd')));
        break;

      case 'Previous 90 Days':
        setStartDate(formatMomentIsoDate(currentDate.subtract(89, 'd')));
        break;

      case 'Previous 30 Days':
        setStartDate(formatMomentIsoDate(currentDate.subtract(29, 'd')));
        break;

      case 'Previous 7 Days':
        setStartDate(formatMomentIsoDate(currentDate.subtract(6, 'd')));
        break;
      default:
        setStartDate(formatMomentIsoDate(currentDate.subtract(29, 'd')));
        break;
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    const windowBodyClassList = document.body.classList;
    if (!windowBodyClassList.contains('campaign')) {
      windowBodyClassList.add('campaign');
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      windowBodyClassList.remove('campaign');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const windowBodyClassList = document.body.classList;
    if (!windowBodyClassList.contains('campaign')) {
      windowBodyClassList.add('campaign');
    }
    return () => {
      windowBodyClassList.remove('campaign');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getAnalyticsUsers()
      .then((res) => {
        const userData = get(res, 'result', []);
        let allUsers = [];
        userData.forEach((u) => {
          let users = u.users.map((e) => {
            return {
              ...e,
              firm_name: u.name,
              firm_id: u.id,
            };
          });
          allUsers = [...allUsers, ...users];
        });
        setAdvisorList(allUsers);
        setUserList(groupBy(allUsers, 'firm_name'));
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    calculateStartDate('Previous 90 Days');
  }, []);

  const handleDateDropdownChange = (type) => {
    setSelectedOption(type);
    setAnalyticsDate(type);
    setShowCalendarDropdown(false);
    calculateStartDate(type);
  };

  const searchHandler = () => {
    if (!isEmpty(searchString)) {
      let searchedAdvisors = advisorList.filter((f) =>
        f.name.toLowerCase().includes(searchString.toLowerCase())
      );
      console.log(searchedAdvisors);
      setUserList(groupBy(searchedAdvisors, 'firm_name'));
    } else {
      setUserList(groupBy(advisorList, 'firm_name'));
    }
  };

  const handleEnterpriseDropdownChange = (type) => {
    if (type === 'My Activity') {
      setShowEnterpriseDropdown(false);
      setShowEnterpriseDropdownSelectedOption(type);
      setEnterprise(false);
      setSelectedUsers([]);
      setSelectedAdvisors([]);
      setEnterpriseStatus(false);
    } else {
      setEnterprise(true);
    }
  };

  const handleSelectAll = () => {
    if (selectedAdvisors.length < advisorList.length) {
      setSelectedAdvisors(advisorList);
    } else {
      setSelectedAdvisors([]);
    }
  };

  const pageDisplay = () => {
    switch (tabIndex) {
      // case 0:
      //   return <OverviewTab />
      case 0:
        return (
          <CampaignTab
            startDate={startDate}
            selectedUsers={selectedUsers}
            showEnterpriseDropdownSelectedOption={
              showEnterpriseDropdownSelectedOption
            }
          />
        );
      case 1:
        return (
          <Contacts
            startDate={startDate}
            selectedUsers={selectedUsers}
            showEnterpriseDropdownSelectedOption={
              showEnterpriseDropdownSelectedOption
            }
            userData={userData}
          />
        );
      case 2:
        return (
          <Content
            startDate={startDate}
            selectedUsers={selectedUsers}
            showEnterpriseDropdownSelectedOption={
              showEnterpriseDropdownSelectedOption
            }
          />
        );

      default:
        return (
          <CampaignTab
            startDate={startDate}
            selectedUsers={selectedUsers}
            showEnterpriseDropdownSelectedOption={
              showEnterpriseDropdownSelectedOption
            }
          />
        );
    }
  };

  const handleExpand = (item) => {
    if (isEmpty(selectedDropdownFirms)) {
      setSelectedDropdownFirms([item]);
    } else if (isEmpty(selectedDropdownFirms.filter((s) => s === item))) {
      setSelectedDropdownFirms([...selectedDropdownFirms, item]);
    } else {
      let removeItems = selectedDropdownFirms.filter((s) => s !== item);
      setSelectedDropdownFirms(removeItems);
    }
  };

  const handleAdvisorSelection = (item, firmName = null, topLevel = false) => {
    if (!topLevel) {
      if (isEmpty(selectedAdvisors)) {
        setSelectedAdvisors([item]);
      } else if (isEmpty(selectedAdvisors.filter((s) => s.id === item.id))) {
        setSelectedAdvisors([...selectedAdvisors, item]);
      } else {
        let removeItems = selectedAdvisors.filter((s) => s.id !== item.id);
        setSelectedAdvisors(removeItems);
      }
    } else {
      let selectedFirmUsers = userList[item];
      console.log(selectedFirmUsers);
      if (
        selectedFirmUsers.length ===
        selectedAdvisors.filter((s) => s.firm_name === item).length
      ) {
        let removeItems = selectedAdvisors.filter((s) => s.firm_name !== item);
        setSelectedAdvisors(removeItems);
      } else {
        setSelectedAdvisors(
          uniqBy([...selectedFirmUsers, ...selectedAdvisors], 'id')
        );
      }
    }
  };

  return (
    <AnalyticsContainer>
      <Flex
        flexDirection="column"
        width="100%"
        margin="0 0 10px"
        alignItems="center"
      >
        {/* ENTER DROPDOWN START FROM HERE */}

        {isAdmin && (
          <UserDropdownAnalytics
            searchHandler={searchHandler}
            searchString={searchString}
            selectedAdvisors={selectedAdvisors}
            selectedDropdownFirms={selectedDropdownFirms}
            selectedUsers={selectedUsers}
            setSearchString={setSearchString}
            setShowEnterpriseDropdown={setShowEnterpriseDropdown}
            showEnterpriseDropdown={showEnterpriseDropdown}
            showEnterpriseDropdownSelectedOption={
              showEnterpriseDropdownSelectedOption
            }
            handleEnterpriseDropdownChange={handleEnterpriseDropdownChange}
            enterprise={enterprise}
            userList={userList}
            handleSelectAll={handleSelectAll}
            advisorList={advisorList}
            handleAdvisorSelection={handleAdvisorSelection}
            handleExpand={handleExpand}
            handleApply={handleApply}
          />
        )}

        {isAdmin && (
          <hr
            style={{
              width: '100%',
              border: `0.5px solid ${ColorPallete.neutral200}`,
              margin: '8px 0px 22px',
            }}
          />
        )}

        {/* ENTERPRISE DROPDOWN ENDS HERE */}

        <Flex width="100%" alignItems="center" justifyContent="space-between">
          <ToggleBar
            toggleItems={toggleItems}
            activeToggle={tabIndex}
            toggleClick={setTabIndex}
          />
          <Flex
            mb="20px"
            style={{ position: 'relative' }}
            ref={dropdownWrapper}
          >
            <WhiteButton
              onClick={() => setShowCalendarDropdown(!showCalendarDropdown)}
              margin="0px"
            >
              <ButtonIcon src={calendarIcon} alt="calendar" />
              <ButtonText>{selectedOption}</ButtonText>
              <ArrowIcon
                selected={showCalendarDropdown}
                src={downArrowBlack}
                alt="down"
              />
            </WhiteButton>
            {showCalendarDropdown && (
              <Flex
                backgroundColor={ColorPallete.white}
                flexDirection="column"
                width={
                  tabIndex === 0 &&
                  showEnterpriseDropdownSelectedOption === 'My Activity' &&
                  isEmpty(selectedUsers)
                    ? '51%'
                    : '100%'
                }
                style={{
                  borderRadius: '4px',
                  border: `1px solid ${ColorPallete.neutral200}`,
                  position: 'absolute',
                  top: '117%',
                  boxShadow: '0px 1px 2px 0px #1018281F',
                  zIndex: 1,
                }}
              >
                <Flex
                  padding="10px 16px"
                  color={ColorPallete.neutral900}
                  fontSize={Typography.TextMedium}
                  alignItems="center"
                  style={{ cursor: 'pointer' }}
                  onClick={() => handleDateDropdownChange('Previous 7 Days')}
                >
                  <ButtonIcon
                    src={calendarIcon}
                    alt="calendar"
                    style={{ margin: '0px 10px 0px 0px' }}
                  />
                  Previous 7 Days
                </Flex>
                <Flex
                  padding="10px 16px"
                  color={ColorPallete.neutral900}
                  fontSize={Typography.TextMedium}
                  alignItems="center"
                  style={{ cursor: 'pointer' }}
                  onClick={() => handleDateDropdownChange('Previous 30 Days')}
                >
                  <ButtonIcon
                    src={calendarIcon}
                    alt="calendar"
                    style={{ margin: '0px 10px 0px 0px' }}
                  />
                  Previous 30 Days
                </Flex>

                <Flex
                  padding="10px 16px"
                  color={ColorPallete.neutral900}
                  fontSize={Typography.TextMedium}
                  alignItems="center"
                  style={{ cursor: 'pointer' }}
                  onClick={() => handleDateDropdownChange('Previous 90 Days')}
                >
                  <ButtonIcon
                    src={calendarIcon}
                    alt="calendar"
                    style={{ margin: '0px 10px 0px 0px' }}
                  />
                  Previous 90 Days
                </Flex>

                {process.env.REACT_APP_API_ENV !== 'x' && (
                  <Flex
                    padding="10px 16px"
                    color={ColorPallete.neutral900}
                    fontSize={Typography.TextMedium}
                    alignItems="center"
                    style={{ cursor: 'pointer' }}
                    onClick={() =>
                      handleDateDropdownChange('Previous 180 Days')
                    }
                  >
                    <ButtonIcon
                      src={calendarIcon}
                      alt="calendar"
                      style={{ margin: '0px 10px 0px 0px' }}
                    />
                    Previous 180 Days
                  </Flex>
                )}
              </Flex>
            )}
            {tabIndex === 0 &&
              showEnterpriseDropdownSelectedOption === 'My Activity' &&
              isEmpty(selectedUsers) && (
                <PurpleButton
                  onClick={() => setExportToCsvModal(true)}
                  padding="12px"
                  margin="0px 5px"
                >
                  <ButtonIcon
                    style={{ margin: '-2px 4px 0 0' }}
                    src={whiteDownload}
                    alt="download"
                  />
                  <ButtonText>Generate Report</ButtonText>
                  {/* <ArrowIcon selected={showCSVDownloadDropdown} onClick={()=>setShowCSVDownloadDropdown(!showCSVDownloadDropdown)} src={downArrow} alt='down' style={{ margin: '2px 0px 0px 5px' }} /> */}
                </PurpleButton>
              )}
            {showCSVDownloadDropdown && (
              <Flex
                backgroundColor={ColorPallete.white}
                flexDirection="column"
                width="47.5%"
                style={{
                  borderRadius: '4px',
                  border: `1px solid ${ColorPallete.neutral200}`,
                  position: 'absolute',
                  top: '117%',
                  left: '51.5%',
                  boxShadow: '0px 1px 2px 0px #1018281F',
                }}
              >
                <Flex
                  padding="10px 16px"
                  color={ColorPallete.neutral900}
                  fontSize={Typography.TextMedium}
                  alignItems="center"
                  style={{ cursor: 'pointer' }}
                  onClick={() => handleDateDropdownChange('Previous 90 Days')}
                >
                  <ButtonIcon
                    src={calendarIcon}
                    alt="calendar"
                    style={{ margin: '0px 10px 0px 0px' }}
                  />
                  Previous 90 Days
                </Flex>
                <Flex
                  padding="10px 16px"
                  color={ColorPallete.neutral900}
                  fontSize={Typography.TextMedium}
                  alignItems="center"
                  style={{ cursor: 'pointer' }}
                  onClick={() => handleDateDropdownChange('Previous 30 Days')}
                >
                  <ButtonIcon
                    src={calendarIcon}
                    alt="calendar"
                    style={{ margin: '0px 10px 0px 0px' }}
                  />
                  Previous 30 Days
                </Flex>
                <Flex
                  padding="10px 16px"
                  color={ColorPallete.neutral900}
                  fontSize={Typography.TextMedium}
                  alignItems="center"
                  style={{ cursor: 'pointer' }}
                  onClick={() => handleDateDropdownChange('Previous 7 Days')}
                >
                  <ButtonIcon
                    src={calendarIcon}
                    alt="calendar"
                    style={{ margin: '0px 10px 0px 0px' }}
                  />
                  Previous 7 Days
                </Flex>
              </Flex>
            )}
          </Flex>
        </Flex>
        {pageDisplay()}
      </Flex>

      {exportToCsvModal && (
        <ExportToCsv
          isOpen={exportToCsvModal}
          handleClose={() => setExportToCsvModal(false)}
          title="Export CSV"
          selectedRepType="email_share_overview"
          startDate={startDate}
          endDate={endDate}
          selectedContacts={[]}
          analyticsObject={{}}
        />
      )}
    </AnalyticsContainer>
  );
};

const mapStateToProps = (state) => {
  const analyticsData = state.analytics.data || [];
  const roleType = getRoleType(state);
  const isAssetAdvisor = roleType === ROLE_TYPES.ADVISOR_ASSET_MANAGER;
  var contactArr = [];
  if (state.analytics.filterParams) {
    if (state.analytics.filterParams.contactParam)
      contactArr = state.analytics.filterParams.contactParam;
  }
  const isEnterpriseLevelAdmin =
    get(state, 'user.data.details.company.enterprise', 'false') === 'true';
  const isAdmin = getUserRole(state) === USER_ROLE.admin;

  return {
    selectedDate: state.analytics.selectedDate,
    analyticsData,
    isAnalyticsDataFetching: state.analytics.isFetching,
    analyticsObject: state.analytics,
    selectedRepType: state.analytics.repType,
    selectedAnalyticsTabIndex: state.analytics.selectedTabIndex,
    userData: state.user.data,
    isAssetAdvisor,
    contactParam: contactArr,
    timeZone: get(state, 'user.data.details.user.timezone', ''),
    campaignsParam: get(state, 'analytics.filterParams.campaignsParam', []),
    isEnterpriseLevelAdmin: isEnterpriseLevelAdmin && isAdmin,
    isAdmin,
    firmsList: get(state, 'user.data.details.company.firms', []),
    enterpriseData: state?.enterprise,
  };
};

const mapDispatchToProps = {
  setSelectedDate: analyticsMoreActions.custom.analytics.selectDate,
  analyticsRequest: analyticsActions.api.analytics.request,
  setAnalyticsTabIndex: analyticsActions.api.analytics.selectedtabindex,
  analyticsSetState: analyticsActions.api.analytics.setstate,
  setEnterpriseStatus: enterpriseActions.setEnterpriseStatus,
  setAnalyticsDate: enterpriseActions.setAnalyticsDate,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdvisorAnalyticsContainer);
