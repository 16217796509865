import styled, { css } from 'styled-components';
import { TabPanel } from '..';

export const Container = styled.div`
  background: #e8e8e8;
  border-radius: 10px;
`;

export const editableContentStyles = css`
  height: 190px;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 10px;
  color: #555;
`;

export const HeaderSummaryIcon = styled.div`
  margin: 0 auto;
  border-radius: 6px;
  padding: 7px 12px;
  margin-bottom: 10px;
  background: #bcbcbc;
  width: 44px;
  cursor: pointer;
`;

export const StyledTabPanel = styled(TabPanel)`
  margin-top: -1px;
`;

export const ErrorContainer = styled.div`
  padding: 10px 0px;
  text-align: center;
`;
