import React, { useState } from 'react';
import { submitTopic } from '../../services/configure';
import { 
  TextInput, 
  TextLabel, 
  RadioLabel, 
  RadioStyle,
  PurpleButton,
  ErrorMessage
} from './styles';
import SearchContainer from '../../componentsV2/SearchContainer';

const SubmitTopicModal = ({ closeModal, themesList }) => {
  const [newTopic, setNewTopic] = useState("");
  const [selectedParentTopic, setSelectedParentTopic] = useState();
  const [topicType, setTopicType] = useState("parent");
  const [searchString, setSearchString] = useState("");
  const [error, setError] = useState();
  const [secondaryError, setSecondaryError] = useState();

  const parseThemesList = () => {
    let outputArr = themesList.map(({name}) => name)
    return outputArr
  }

  const validate = () => {
    const nameRegex = /^[a-zA-Z\s]*$/;
    if (!newTopic) {
      return setError('Please enter topic name');
    }
    if (!nameRegex.test(newTopic)) {
      return setError('Please enter only alphabets for topic name');
    }

    if(topicType === 'child' && !selectedParentTopic){
      return setSecondaryError('Please select a Parent Topic');
    };

    topicSubmit()
  };


  const topicSubmit = async () => {
    try{
      await submitTopic({
        suggestedTopic: newTopic,
        parentTopic: selectedParentTopic,
      })
      closeModal();
    } catch(err){
      console.log('submit topic error:', err)
    }
  };

  return (
   <div style={{width: 500}}>
      <TextLabel>Topic Name*
        <TextInput
          id="newTopic"
          name="newTopic"
          placeholder="Topic Name"
          value={newTopic}
          onChange={(e)=>setNewTopic(e.target.value)}
        />
        {error && <ErrorMessage>{error}</ErrorMessage>}
      </TextLabel>

      <div style={{display: 'flex', justifyContent: 'start'}} >
        <RadioLabel value="Parent" control={<RadioStyle 
          onClick={() => setTopicType('parent')} 
          checked={topicType === 'parent'} 
          id={`topic_type_parent`}
        />} label="Parent" />
        <RadioLabel value="Child" control={<RadioStyle 
          id={`topic_type_child`}
          checked={topicType === 'child'}
          onClick={() => setTopicType('child')}
        />} label="Child" />
      </div>
      {topicType === 'child' && (
         <SearchContainer 
            selectedTags={selectedParentTopic}
            searchString={searchString}
            setSearchString={setSearchString}
            setSelectedTags={setSelectedParentTopic}
            searchResults={parseThemesList()}
            title={'Select a Parent Topic'}
            singleTopic={true}
            chipType={'Parent Topic'}
        />
      )}
      {secondaryError && <ErrorMessage style={{margin: '10px 0 0'}}>{secondaryError}</ErrorMessage>}
      <PurpleButton onClick={()=>validate()}>Submit</PurpleButton>
    </div>
  );
};

export default SubmitTopicModal;
