import { styled } from '@mui/material';

export const Wrapper = styled('div')(() => ({
  display: 'flex',
  alignItems: 'flex-start',
  gap: '1rem',
}));

export const Pills = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  flexGrow: 1,
  alignItems: 'center',
  gap: '1rem',
  flexWrap: 'wrap',
  padding: '1rem 1.5rem',
  backgroundColor: '#F9FAFB',
  border: '1px solid #E4E7EC',
  borderRadius: '0.25rem',
}));

export const Pill = styled('div')(({ selected = false }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '0.25rem',
  padding: '0.38rem 0.75rem',
  color: selected ? '#0B5CD7' : '#101828',
  backgroundColor: selected ? '#ECF2FC' : '#fff',
  border: `1px solid ${selected ? '#B6CFF3' : '#E4E7EC'}`,
  borderRadius: '1.25rem',
  whiteSpace: 'nowrap',
  fontSize: '0.875rem',
  fontWeight: '500',
  cursor: 'pointer',
  userSelect: 'none',

  svg: {
    fontSize: '1.5rem',
  },
}));

export const DarkerPill = styled('div')(({ selected = false }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '0.25rem',
  padding: '0.38rem 0.75rem',
  color: selected ? '#0B5CD7' : '#101828',
  backgroundColor: selected ? '#ECF2FC' : '#fff',
  border: `1px solid ${selected ? '#B6CFF3' : '#98A2B3'}`,
  borderRadius: '1.25rem',
  whiteSpace: 'nowrap',
  fontSize: '0.875rem',
  fontWeight: '500',
  cursor: 'pointer',
  userSelect: 'none',

  svg: {
    fontSize: '1.5rem',
  },
}));

export const Link = styled('span')(() => ({
  color: '#6250ED',
  fontSize: '0.875rem',
  fontWeight: '500',
  whiteSpace: 'nowrap',
  padding: '0.5rem 1rem',
  cursor: 'pointer',
}));

export const ModalWrapper = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: '2rem',
  padding: '1rem',
  paddingTop: '0.5rem',
}));

export const MyTopics = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '0.5rem',
}));

export const MyTopicsHeader = styled('div')(({ isLimitReached = false }) => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',

  h3: {
    fontSize: '1rem',
    fontWeight: '500',
    margin: 0,
    color: '#101828',
    textAlign: 'left',
  },

  h6: {
    fontSize: '1rem',
    fontWeight: '400',
    margin: 0,
    textAlign: 'left',
    color: isLimitReached ? '#e00' : '#475467',
  },
}));

export const MyTopicsPills = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  flexWrap: 'wrap',
  gap: '0.5rem',
}));

export const MyTopicsPill = styled('div')(() => ({
  width: 'fit-content',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: '0.25rem',
  padding: '0.38rem 0.75rem',
  border: '1px solid #E4E7EC',
  borderRadius: '1.25rem',
  userSelect: 'none',

  span: {
    fontSize: '0.875rem',
    fontWeight: '500',
    color: '#101828',
    margin: 0,
    textAlign: 'left',
    whiteSpace: 'nowrap',
  },

  svg: {
    color: '#101828',
    fontSize: '1rem',
  },
}));

export const AddTopicsContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '1rem',
  padding: '1rem',
  fontSize: '1rem',
  border: '1px solid #E4E7EC',
  backgroundColor: '#F9FAFB',
  borderRadius: '0.5rem',

  h3: {
    fontSize: '1rem',
    fontWeight: '500',
    margin: 0,
    color: '#101828',
    textAlign: 'left',
  },
}));

export const ModalFooter = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  gap: '0.75rem',
}));
