/* eslint-disable react-hooks/exhaustive-deps */
import { Flex } from '@rebass/grid';
import { isEmpty } from 'lodash';
import React, { useState, useEffect } from 'react'
import { Button, ButtonNoBorderBackground, Input, LoaderWrapper, MioSelect, Modal, ModalBody, ModalHeader } from '../../components';
import { AdvisorInputWrapper } from '../../components/AdvisorProfile/AdvisorProfile.styles';
import { leadEventHandlers, themesData } from '../../utils';

const TifinWidgetFormModal = ({
    open,
    setOpen,
    handleContentPost,
    loading,
    emailId,
    clientName,
    firstName,
    lastName,
    message,
    clientEmailId,
    network,
    clientFirstName,
    clientLastName,
    userType,
    amFirmName,
    userId,
    eid,
    page_tag,
    device_type,
    visitor_type,
    ticker,
    provider_name
}) => {

    useEffect(() => {
        leadEventHandlers({
            eventName: 'FORM DISPLAY',
            userId,
            eid,
            form_type: 'lead_capture',
            dnetwork: network,
            email_id: emailId,
            first_name: firstName,
            last_name: lastName,
            client_email: clientEmailId,
            client_first_name: clientFirstName,
            client_last_name: clientLastName,
            am_firm_name: amFirmName,
            user_type: userType,
            page_tag,
            device_type,
            visitor_type,
            ticker,
            provider: provider_name
        })
    }, []);

    const investableAssetOptions = userType !== 'am' ? [
        'Under $100,000',
        '$100,000 to $250,000',
        '$250,000 to $500,000',
        '$500,000 to $1 million', 
        'Over $1 million'
    ] : [
        'Under $100,000',
        '$100,000 to $1 million', 
        '$1 million to $10 million', 
        '$10 million to $50 million', 
        '$50 million to $100 million', 
        '$100 million to $500 million',
        '$500 million to $1 billion',
        'More than $1 billion' 
    ];

    const [leadForm, setLeadForm] = useState({
        first_name: '',
        last_name: '',
        email: '',
        mobile: '',
        zip: '',
        investable_assets: ''
    });

    const handleChange = (e) => {
        setLeadForm({...leadForm, [e.target.name]: e.target.value});
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        let payload = {
            fullname: leadForm.first_name + ' ' + leadForm.last_name,
            email: leadForm.email,
            mobile: leadForm.mobile,
            investable_assets: leadForm.investable_assets,
            type: 'leadgen',
            zip: leadForm.zip
        };

        if(payload.mobile === '' || isEmpty(payload.mobile)) {
            delete payload.mobile;
        }
        leadEventHandlers({
            eventName: 'FORM SUBMITTED',
            userId,
            eid,
            form_type: 'lead_capture',
            dnetwork: network,
            email_id: emailId,
            first_name: firstName,
            last_name: lastName,
            client_email: leadForm.email || clientEmailId,
            client_first_name: (leadForm.fullname && leadForm.fullname.split(' ')[0]) || clientFirstName,
            client_last_name: (leadForm.fullname && leadForm.fullname.split(' ')[1]) || clientLastName,
            am_firm_name: amFirmName,
            user_type: userType,
            investable_assets: leadForm.investable_assets,
            page_tag,
            device_type,
            visitor_type,
            ticker,
            provider: provider_name
        })
        handleContentPost(payload);
    }

    const handleClose = () => {
        leadEventHandlers({
            eventName: 'FORM CLOSED',
            userId,
            eid,
            form_type: 'lead_capture',
            dnetwork: network,
            email_id: emailId,
            first_name: firstName,
            last_name: lastName,
            client_email: clientEmailId,
            client_first_name: clientFirstName,
            client_last_name: clientLastName,
            am_firm_name: amFirmName,
            user_type: userType,
            page_tag,
            device_type,
            visitor_type,
            ticker,
            provider: provider_name
        })
        setOpen(false);
    }

    return (
        <Modal noBoxShadow borderColor={themesData.charcoalGrey} bgColor='#fff' size='medium-small' isOpen={open} onRequestClose={handleClose}>
            <ModalHeader fontSize="14px">
                Would you be interested in speaking with a financial  <br /> advisor who specializes in this topic? 
            </ModalHeader>
            <ModalBody>
                <Flex flexDirection="column">
                    <Flex width="100%" mb="15px" justifyContent="center" color={themesData.charcoalGrey}>
                        Tell us a little more about you.
                    </Flex>
                    <form onSubmit={handleSubmit}>
                        <Flex width="100%" padding="0px 20px" mb="10px" justifyContent="space-between" mt="17px" style={{position: 'relative'}}>
                            <AdvisorInputWrapper style={{width: '70%', marginTop: '0px', marginRight: '5px'}}>
                                <Input 
                                    type="text" 
                                    name="first_name"
                                    value={leadForm.first_name} 
                                    placeholder="First Name" 
                                    onChange={handleChange} 
                                    required
                                    isInputWithFloatingLabel={true} 
                                />
                            </AdvisorInputWrapper>
                            <AdvisorInputWrapper style={{ width: '70%', marginTop: '0px' }}>
                                <Input
                                    type="text"
                                    name="last_name"
                                    value={leadForm.last_name}
                                    placeholder="Last Name"
                                    onChange={handleChange}
                                    required
                                    isInputWithFloatingLabel={true} 
                                />
                            </AdvisorInputWrapper>
                        </Flex>
                        <Flex width="100%" justifyContent="center">
                            <AdvisorInputWrapper style={{ width: '90%', marginTop: '10px' }}>
                                <Input
                                    type="email"
                                    name="email"
                                    value={leadForm.email}
                                    placeholder="Email Address"
                                    onChange={handleChange}
                                    required
                                    isInputWithFloatingLabel={true}
                                />
                            </AdvisorInputWrapper>
                        </Flex>
                        <Flex width="100%" padding="0px 20px" mb="10px" justifyContent="space-between">
                            <AdvisorInputWrapper style={{ width: '70%', marginTop: '20px', marginRight: '5px' }}>
                                <Input
                                    type="text"
                                    value={leadForm.mobile}
                                    name="mobile"
                                    onChange={handleChange}
                                    placeholder="Phone Number"
                                    isInputWithFloatingLabel={true}
                                />
                            </AdvisorInputWrapper>
                            <AdvisorInputWrapper style={{ width: '70%', marginTop: '20px' }}>
                                <Input
                                    type="text"
                                    value={leadForm.zip}
                                    name="zip"
                                    onChange={handleChange}
                                    placeholder="Zip Code"
                                    isInputWithFloatingLabel={true}
                                    required
                                />
                            </AdvisorInputWrapper>
                        </Flex>
                        <Flex width="100%" mt="20px" mb="10px" justifyContent="center">
                            <MioSelect
                                name="investable_assets"
                                required={true}
                                onChange={handleChange}
                                value={leadForm.investable_assets}
                                width="90%"
                            >
                                <option value="">{userType === 'am' ? 'AUM' : 'Investable Assets'}</option>
                                {investableAssetOptions.map((item, index) => (
                                    <option value={item}>{item}</option>
                                ))}
                            </MioSelect>
                        </Flex>
                        
                        <LoaderWrapper isLoading={loading}>
                            <Flex width="100%" justifyContent="center">
                                <Button type="submit" width="24%" height="28px" padding="4px 20px">
                                    Sure!
                                </Button>
                            </Flex>
                        </LoaderWrapper>

                        <Flex width="100%" justifyContent="center" pt="10px">
                            <ButtonNoBorderBackground bgColor="#fff" onClick={handleClose} color={themesData.purpleishBlue} width="70%">
                                No Thanks
                            </ButtonNoBorderBackground>
                        </Flex>

                        {message && (
                            <Flex color={themesData.purpleishBlue} justifyContent="center" fontSize="12px" pt="5px">
                                {message}
                            </Flex>
                        )}

                        <Flex justifyContent="center" width="100%" fontSize="10px" color={themesData.charcoalGrey}>
                                By submitting your contact info, you're opting in to Clout's <br />
                                communications. You can unsubscribe or adjust your preferences at any time.    
                        </Flex>
                    </form>
                </Flex>
            </ModalBody>
        </Modal>
    )
}

export default TifinWidgetFormModal;
