import React from 'react';
import { Tooltip } from '@mui/material';
import EngagementScoreTooltip from '../common/EngagementScoreTooltip';
import {
  EngagementScoreColumn,
  TableBody,
  TooltipStyles,
  TruncatedEmailText,
  TruncatedTableBody,
} from './styles';

const engagementScoreClassName = (score) => {
  if (score > 50) return 'above-fifty';
  else return 'below-fifty';
};

export const tableColumnStructureForEmailAndSegment = (isSegment = false) => {
  return [
    {
      header: {
        key: 'engagement_score',
        title: <EngagementScoreTooltip selectedTab="CONTACTS" />,
      },
      maxWidth: '300px',
      render: (row) => {
        const score = row?.engagement_score ?? 0;
        return (
          <EngagementScoreColumn className={engagementScoreClassName(score)}>
            {score ?? '-'}%
          </EngagementScoreColumn>
        );
      },
    },
    {
      header: {
        key: 'email_list',
        title: isSegment ? 'Segments' : 'Email List',
      },
      maxWidth: '500px',
      render: (row) => (
        <Tooltip
          title={row?.name ?? '-'}
          PopperProps={{
            sx: TooltipStyles,
          }}
        >
          <TruncatedTableBody cursor="pointer">
            {row?.name ?? '-'}
          </TruncatedTableBody>
        </Tooltip>
      ),
    },
    {
      header: {
        key: 'contacts_count',
        title: 'Contacts',
      },
      maxWidth: '250px',
      render: (row) => <TableBody>{row?.contacts_count ?? '-'}</TableBody>,
    },
    {
      header: {
        key: 'block_count',
        title: 'No of Emails',
      },
      maxWidth: '250px',
      render: (row) => <TableBody>{row?.block_count ?? '-'}</TableBody>,
    },
  ];
};

export const tableColumnStructureForContacts = () => {
  return [
    {
      header: {
        key: 'engagementScore',
        title: <EngagementScoreTooltip selectedTab="CONTACTS" />,
      },
      minWidth: '300px',
      width: '400px',
      render: (row) => {
        const score = row?.engagement_score ?? 0;
        return (
          <EngagementScoreColumn className={engagementScoreClassName(score)}>
            {score ?? '-'}%
          </EngagementScoreColumn>
        );
      },
    },
    {
      header: {
        key: 'email',
        title: 'Email',
      },
      minWidth: '370px',
      render: (row) => (
        <Tooltip
          title={row?.email || ''}
          PopperProps={{
            sx: TooltipStyles,
          }}
        >
          <TruncatedEmailText cursor="pointer">
            {row?.email || '-'}
          </TruncatedEmailText>
        </Tooltip>
      ),
    },
    {
      header: {
        key: 'first_name',
        title: 'First Name',
      },
      minWidth: '300px',
      render: (row) => <TableBody>{row?.first_name || '-'}</TableBody>,
    },
    {
      header: {
        key: 'last_name',
        title: 'Last Name',
      },
      minWidth: '250px',
      render: (row) => <TableBody>{row?.last_name || '-'}</TableBody>,
    },
    {
      header: {
        key: 'total_blocks',
        title: 'No of Emails',
      },
      maxWidth: '250px',
      render: (row) => <TableBody>{row?.total_blocks ?? '-'}</TableBody>,
    },
  ];
};
