import { Tab, TabPanel } from "react-tabs";
import styled from "styled-components";

export const SetTopicsWrapper = styled.div.attrs({
    className: 'set-topics-wrapper'
})`
    height: 90%;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    padding: 0px 30px;
`;

export const SelectedTopicsWrapper = styled.div.attrs({
    className: 'selected-topics-wrapper'
})`
    display: flex;
    height: 30%;
    border: 1px solid #CFD9E4;
    margin: 20px 0px 10px;
    padding: 10px 15px;
    flex-direction: column;
    background-color: #FBFBFB;
    border-radius: 4px;
`;

export const SetTopicsHr = styled.hr.attrs({
    className: 'set-topics-hr'
})`
    width: 100%;
    border: 1px solid #CFD9E4;
`;

export const CampaignThemesWrapper = styled.div.attrs({
    className: 'campaign-themes-wrapper'
})`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    height: 80%;
    max-height: 80%;
    overflow-x: hidden;
    overflow-y: auto;

    &::-webkit-scrollbar {
        width: 5px;
    }

    &::-webkit-scrollbar-track {
        background: #E9F6E6 !important;
    }

    &::-webkit-scrollbar-thumb {
        background: #BCCDD4 !important;
    }

`;

export const CampaignThemeTiles = styled.div.attrs({
    className: 'campaign-theme-tiles'
})`
    display: flex;
    padding: 5px;
    border: 1px solid #CFD9E4;
    justify-content: space-between;
    align-items: center;
    background-color: #ffffff;
    color: #000000;
    font-size: 11px;
    margin: 5px;
    border-radius: 4px;
    height: 45px;
    position: relative;

    .camp-tile-cover {
        display: none;
    }

    .tiles-title {
        padding-left: 5px; 
    }

    &:hover {
        cursor: pointer;
        .tiles-image {
            opacity: 0.3;
        }

        .tiles-title {
            opacity: 0.1;
        }

        .camp-tile-cover {
            position: absolute;
            height: 100%;
            width: 100%;
            opacity: 1;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 6px 10px;
        }

        .camp-tile-cover-title {
           
        }
    }
`;

export const SetTopicsTabWrapper = styled.div.attrs({
    className: 'set-topics-tab-wrapper'
})`
    display: flex;
    height: 54%;
    border: 1px solid #CFD9E4;
    margin: 20px 0px 10px;
    padding: 10px 15px;
    flex-direction: column;
    background-color: #FBFBFB;
    border-radius: 4px;
`;

export const CampaignTab = styled(Tab)`
  font-size: 14px;
  font-weight: 500;
  padding: ${({padding}) => padding || '10px 10px'};
  cursor: pointer;
  color: ${({color}) => color || '#000000'};
  border-bottom: ${({border}) => border || ''};
  font-family: 'Poppins';
  width: ${({ width }) => width || ''};

  &.active {
    border-bottom: 1px solid #6250ED;
    color: #6250ED;
  }
  &.inactive{
    border-bottom: 1px solid #CFD9E4;
  }

  ${({ disabled }) => disabled && 
  `cursor: default;
   color: #a8a8a8; 
  `
  }
`;

export const CampaignTabPanel = styled(TabPanel)`
  width: 100%;
  padding: 10px 15px;
  height: 75%;
`;

export const RecommendedTopicsWrapper = styled.div.attrs({
    className: 'recommended-topics-wrapper'
})`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    max-height: 100%;
    overflow-x: hidden;
    overflow-y: auto;

    &::-webkit-scrollbar {
        width: 5px;
    }

    &::-webkit-scrollbar-track {
        background: #E9F6E6 !important;
    }

    &::-webkit-scrollbar-thumb {
        background: #BCCDD4 !important;
    }
`;

export const CampaignBrowserWrapper = styled.div.attrs({
    className: 'campaign-browser-wrapper'
})`
    display: flex;
    flex-direction: column;
    padding: 10px 0px;
`;

export const AlphaTiles = styled.div.attrs({
    className: 'alpha-tiles'
})`
    border: 1px solid #6250ED;
    border-radius: 4px;
    color: #6250ED;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px 10px;
    margin: 0px 7px 0px 0px;
    cursor: pointer;
    width: 35px;

    &.active {
        background-color: #6250ED;
        color: #fff;
    }
`;

export const PopularTopicsWrapper = styled.div.attrs({
    className: 'popular-topics-wrapper'
})`
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 100%;
`;

export const SearchTopicsWrapper = styled.div.attrs({
    className: 'search-topics-wrapper'
})`
    display: flex;
    width: 100%;
    flex-direction: column;
    padding: 10px 0px;
`;

export const CampaignSearchInput = styled.input.attrs({
    className: 'campaign-search-input'
})`
    width: ${({ width }) => width || 'auto'};
    border: 1px solid #8493A6;
    border-radius: 4px;
    color: #000000;
    padding: 5px 30px 5px 10px; 
    height: 30px;
    font-family: 'Poppins';
`;