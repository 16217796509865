import React from 'react';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import styled from 'styled-components';
import { colors } from '../../styles';

export const Container = styled(({ className, ...props }) => (
    <Tooltip {...props} tooltipPlacementLeft placement="right" classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#FFFFFF',
      boxShadow: '0px 4px 8px rgba(16, 24, 40, 0.2)',
      padding: 0,
      borderRadius: '8px !important',
      overflow: 'hidden !important'
    },
  }));


export const TooltipRow = styled('div')`
    min-width: 130px;
    padding: 10px;
    cursor: pointer;
    font-weight: 400;
    font-family: Poppins !important;
    font-size: 14px;
    background: #fff;
    transition: 0.5s;
    color: #101828;
    border-top: ${({ borderTop }) => borderTop && `1px solid ${colors.gray_border}`};
    :hover {
      background:  ${colors.hover_background};
    }
`;

export const InfoIcon = styled('img')`
    height: 28px;
    margin-top: 2px;
    border-radius: 4px;
    transition: 0.75s;
    :hover {
      background:  ${({ hover }) => hover === 'black' && colors.hover_background};
    }
`;

export const RowIcon = styled('img')`
    height: 16px;
    width: 16px;
    margin: 3px 10px -3px 0;
`;

export const InfoButton = styled('div')`
    width: 32px;
    height: 32px;
    background: ${colors.clout_purple};
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.12);
    border-radius: 4px;
    cursor: pointer;
    margin: 6px 0 0;
    transition: 0.5s;
    :hover {
        background: #a69bea;
    }
`;