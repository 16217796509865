import styled from 'styled-components';

export const OutboxCardWrapper = styled.article.attrs({
  className: 'outbox-card-wrapper'
})`
  width: 215px;
  height: 208px;
  margin: 14px 10px 35px 8.2px;
  padding: 0px;
  border-radius: 8px;
  background-color: #ececec;
  display: flex;
  flex-direction: column;

  .post-desc {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    word-break: break-word;
    -webkit-box-orient: vertical;
    white-space: pre-wrap;
    text-overflow: ellipsis;
    font-size: 13px;
    text-align: left;
    height: 55px;
    line-height: 20px;
  }
`;