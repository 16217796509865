import React, { useEffect, useState } from 'react';
import useRESTQuery from 'src/hooks/useRESTQuery';
import ContactsRecommendationCard from 'src/containers/AnalyticsContainer/ContactsTab/ContactsRecommendationCard';
import { saveFile } from 'src/utils';
import { ContactsRecommendationWrapper } from './styles';

const engagedGraphMapping = {
  high_count: {
    label: 'High',
    color: '#00B186',
  },
  low_count: {
    label: 'Low',
    color: '#CE93D8',
  },
  medium_count: {
    label: 'Medium',
    color: '#FFA32C',
  },
};

function ContactsRecommendation({
  startDate,
  selectedUsers,
  showEnterpriseDropdownSelectedOption,
  userData,
}) {
  const isEnterprise =
    showEnterpriseDropdownSelectedOption === 'My Enterprise' ? true : false;
  const [isDownloadButtonClicked, toggleDownloadButton] = useState(false);

  const {
    data: contactsEngagement,
    isLoading: contactsEngagementLoader,
    isError: contactsEngagementError,
    isSuccess: contactsEngagementSuccess,
    refetch: contactsEngagementRefetch,
  } = useRESTQuery({
    key: 'contact-engagement',
    dependencies: [isEnterprise, startDate, selectedUsers?.join(',')],
    endpoint: `/analytics/contacts/engagement?from_date=${startDate}&is_enterprise=${isEnterprise}${
      selectedUsers ? `&user_ids=${JSON.stringify(selectedUsers)}` : ''
    }`,

    options: {
      select: (res) => {
        if (res?.result && Object.keys(res?.result)?.length > 0) {
          const data = res.result;
          const transformedData = Object.keys(data).map((item) => {
            return {
              name: engagedGraphMapping?.[item]?.label ?? '',
              'Engaged Contacts': data?.[item],
              fill: engagedGraphMapping?.[item]?.color ?? '',
            };
          });
          return transformedData;
        }
      },
    },
  });

  const {
    data: contactsRecommendation,
    refetch: contactsRecommendationRefetch,
  } = useRESTQuery({
    key: 'contacts-recommendations',
    endpoint: `/analytics/contacts/recommended_name?from_date=${startDate}`,
    dependencies: [startDate],
    options: {
      select: (res) => {
        if (res && res?.result) {
          return res?.result ?? {};
        }
      },
    },
  });

  const {
    data: highlyEngagedContacts,
    isLoading: highlyEngagedContactsLoader,
    isError: highlyEngagedContactsError,
    refetch: highlyEngagedContactsRefetch,
  } = useRESTQuery({
    key: 'highly-engaged-contacts',
    dependencies: [isEnterprise, startDate, selectedUsers?.join(',')],
    endpoint: `/analytics/contacts/high_engaged/advisors?from_date=${startDate}&is_enterprise=${isEnterprise}${
      selectedUsers ? `&user_ids=${JSON.stringify(selectedUsers)}` : ''
    }`,

    options: {
      select: (res) => {
        if (Array.isArray(res?.result?.data) && res?.result?.data?.length > 0) {
          return res?.result?.data ?? [];
        }
      },
    },
  });

  const {
    isLoading: recommendedContactsLoader,
    isError: recommendedContactsError,
  } = useRESTQuery({
    key: 'recommended-contacts',
    endpoint: `/analytics/contacts/recommendation?from_date=${startDate}`,
    dependencies: [startDate],
    options: {
      enabled: isDownloadButtonClicked,
      onSuccess: (res) => {
        saveFile(res, 'Recommended_Contacts.csv');
      },
    },
  });

  useEffect(() => {
    if (
      showEnterpriseDropdownSelectedOption === 'My Activity' &&
      contactsEngagementSuccess
    ) {
      contactsRecommendationRefetch();
    }
    if (
      showEnterpriseDropdownSelectedOption === 'My Enterprise' ||
      (selectedUsers && selectedUsers.length > 0)
    ) {
      highlyEngagedContactsRefetch();
    }
  }, [
    contactsEngagementSuccess,
    contactsRecommendationRefetch,
    highlyEngagedContactsRefetch,
    selectedUsers,
    showEnterpriseDropdownSelectedOption,
  ]);

  const onDownloadHandler = () => {
    toggleDownloadButton(true);
  };

  return (
    <ContactsRecommendationWrapper>
      <ContactsRecommendationCard
        title="Recommendations"
        graphData={contactsEngagement}
        recommendationData={contactsRecommendation}
        loading={contactsEngagementLoader}
        error={contactsEngagementError}
        onRetryClick={contactsEngagementRefetch}
        csvLoading={recommendedContactsLoader}
        onDownload={onDownloadHandler}
        csvError={
          recommendedContactsError
            ? 'A Problem Occured, we could not fetch your data'
            : ''
        }
        showEnterpriseDropdownSelectedOption={
          showEnterpriseDropdownSelectedOption
        }
        selectedFirms={selectedUsers}
        highlyEngagedEntContacts={highlyEngagedContacts}
        entContactsLoading={highlyEngagedContactsLoader}
        handleHighlyEngagedContactsRetryClick={highlyEngagedContactsRefetch}
        highlyEngagedEntContactsError={highlyEngagedContactsError}
        userData={userData?.details?.user ?? {}}
      />
    </ContactsRecommendationWrapper>
  );
}

export default ContactsRecommendation;
