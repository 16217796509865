import * as React from "react";

function LinkedIn(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 18 18" fill="none" {...props}>
      <path
        d="M14.906 2.531H3.094a.563.563 0 00-.563.563v11.812c0 .31.252.563.563.563h11.812c.31 0 .563-.252.563-.563V3.094a.563.563 0 00-.563-.563zM8.438 7.875v4.5M6.188 7.875v4.5"
        stroke="currentColor"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.438 9.844a1.969 1.969 0 013.937 0v2.531"
        stroke="currentColor"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.188 6.469a.844.844 0 100-1.688.844.844 0 000 1.688z"
        fill="currentColor"
      />
    </svg>
  );
}

const MemoLinkedIn = React.memo(LinkedIn);
export default MemoLinkedIn;
