/* eslint-disable react-hooks/exhaustive-deps */
import { get, isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import BeePlugin from '@mailupinc/bee-plugin';
import axios from 'axios';
import { LoaderWrapper } from '../../components';
import { Flex } from '@rebass/grid';
import { thumbnailEndpoints } from '../../services/helpers';
import { complianceLandingPageCreate, complianceLandingPageResubmit, createLandingPage, getLandingPageById, updateLandingPage } from '../../services/bee-editor'
import SelectFormPromiseModal from '../../components/SelectFormModal/SelectFormModal';
import ModalContainer from 'react-modal-promise';

import { getFirmSettings, getUserRole } from '../../redux/selector';
import { ROLE_TYPES } from '../../utils';
import { beeAuth, getWebinarList } from '../../services/campaign';
import { RadioInput, RadioInputLabel } from '../DripCampaignContainerV2/DripCampaignContainerV2.styles';
import { WebinarWrapper, RecipientsListWrapper, RecipientsListBody } from '../DripCampaignContainer/SetObjectives/SetObjectives.styles';
import { lambdaEndpoints } from '../../services/helpers';
import { TemplateFooter } from '../TemplateContainer';
import { ConfirmationModalV2 } from '../../componentsV2';
import { ScriptJson } from './forms/ScriptJson';

const LandingPageContainer = ({
    userData,
    isAdmin,
    roleType,
    isPremiumAdvisor,
    setObjectivesTabData,
    isFirmLevelAdmin,
    firmSettings,
    companyId,
    acBack
}) => {

    const history = useHistory();
    const { pageId } = useParams();
    const [beeInstance, setBeeInstance] = useState({});
    const [loadHeader, setLoadHeader] = useState(false);
    const [savedPage, setSavedPage] = useState({ json: '', html: '' });
    const [savePageModal, toggleSavePageModal] = useState(false);
    const [loadedPage, setLoadedPage] = useState({});
    const [pageName, setPageName] = useState('');
    const [saveLoader, toggleSaveLoader] = useState(false);
    const [pageLoader, togglePageLoader] = useState(true);
    const [progressMessage, setProgressMessage] = useState('');
    const [selectedComplianceCheck, setSelectedComplianceCheck] = useState(false);
    const [alertModal, toggleAlertModal] = useState(false);
    const [showWebinar, setShowWebinar] = useState(false);
    const [webinarList, setWebinarList] = useState([]);
    const [selectedWebinar, setSelectedWebinar] = useState('');
    // const [showCompliance, setShowCompliance] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const DEFAULT_CONFIGURATION = {
        uid: get(userData, 'uid', ''),// Needed for identify resources of the user.
        container: 'bee-plugin-container', // Identifies the id of div element that contains BEE Plugin.
        language: 'en-US',
        autosave: true,
        roleHash: 'superAdminClout',
        onLoad: () => {
            setLoadHeader(true)
        },
        rowsConfiguration: {
            defaultRows: true,
            emptyRows: true,
            externalContentURLs: [
                {
                    name: 'My Content',
                    value: `${lambdaEndpoints.baseUrl}user-rows?uid=${get(userData, 'uid', '')}&category=content_block&isCascaded=false`
                },
                {
                    name: 'My Disclaimer',
                    value: `${lambdaEndpoints.baseUrl}user-rows?uid=${get(userData, 'uid', '')}&category=disclaimer&isCascaded=false`
                },
                {
                    name: 'My Footers',
                    value: `${lambdaEndpoints.baseUrl}user-rows?uid=${get(userData, 'uid', '')}&category=footer&isCascaded=false`
                }, 
                {
                    name: 'My Headers',
                    value: `${lambdaEndpoints.baseUrl}user-rows?uid=${get(userData, 'uid', '')}&category=header&isCascaded=false`
                }, 
                {
                    name: 'My Signatures',
                    value: `${lambdaEndpoints.baseUrl}user-rows?uid=${get(userData, 'uid', '')}&category=signature&isCascaded=false`
                }, 
            ]
        },
        // defaultForm: {},
        contentDialog: {
            manageForm: {
                label: 'Select Template Form',
                handler: (resolve, reject, args) => {
                    SelectFormPromiseModal()
                        .then(value => {
                            console.log('FORM MODAL - - - - ', value);
                            resolve(value);
                        }).catch(err => {
                            reject('You did not select a form');
                        })
                }
            }
        }    
    };

    // useEffect(() => {
    //     let show = true;
    //     if(show) {
    //       if((isFirmLevelAdmin) && !isEmpty(firmSettings)) {
    //         show = get(firmSettings, `firm_compliance.${[companyId]}.compliance_is`, '') === 'optional';
    //       } 
    //        else {
    //         show = get(userData, 'compliance', '') === 'optional';
    //        }
    //     }
    //     setShowCompliance(show);
    // }, []);

    useEffect(() => {
        const windowBodyClassList = document.body.classList;
        if (!windowBodyClassList.contains('campaign')) {
            windowBodyClassList.add('campaign');
        }


        return () => {
            windowBodyClassList.remove('campaign');
        };
    }, []);

    useEffect(() => {
        if(pageId !== 'new') {
            getLandingPageById({ id: pageId })
            .then(res => {
                console.log('RES - - - - - - ', res);
                if(get(res, 'data.page_json', '')) {
                    loadEditor(res.data.page_json);
                    setLoadedPage(res.data);
                }

                if(res && res.data && res.data.webinar_id) {
                    setSelectedWebinar(res.data.webinar_id);
                    getWebinarList().then(res => {
                        setWebinarList(get(res, 'result.data', []));
                    }).catch(err => {
            
                    })
                } else {
                    getWebinarList().then(res => {
                        setWebinarList(get(res, 'result.data', []));
                        if(!isEmpty(get(res, 'result.data', []))) {
                            setSelectedWebinar(res.result.data[0].id);
                        }
                    }).catch(err => {
            
                    })
                }
            })
            .catch(err => console.log('ERROR - - - - -', err));
        }
        else {
            loadEditor({});
            getWebinarList().then(res => {
                setWebinarList(get(res, 'result.data', []));
                if(!isEmpty(get(res, 'result.data', []))) {
                    setSelectedWebinar(res.result.data[0].id);
                }
            }).catch(err => {
    
            })
        }
            
    }, []);

    const handleComplianceToggle = (checked) => {
        setSelectedComplianceCheck(checked);
    }

    const onSave = (templateJson, templateHtml) => {
        console.log(templateJson, templateHtml);
        const parsedJson = JSON.parse(templateJson);
        // console.log('PARSED JASON - - -- ', parsedJson);    
        const isPageEmpty = parsedJson.page.rows.every(row => row.columns.every(column => column.modules.length === 0))
        if(isPageEmpty){
            toggleAlertModal(true);
            setErrorMessage('Please add Content to the Web Page before Saving!');
        }
        else 
            toggleAlertModal(false);    
        const hasForm = templateJson.includes('mailup-bee-newsletter-modules-form');
        if(templateJson.includes('webinar_form')) {
            setShowWebinar(true);
        } else {
            setShowWebinar(false);
        }
        const scriptRow = parsedJson.page.rows.filter(row => row.metadata && row.metadata.name === 'script');
        if(hasForm && isEmpty(scriptRow))
            parsedJson.page.rows.push(ScriptJson);
        toggleSavePageModal(true);
        console.log('after json - - - - - -', JSON.stringify(parsedJson));
        console.log(templateHtml);
        setSavedPage({ json: JSON.stringify(parsedJson), html: templateHtml });
    };

    const loadEditor = template => {
        // console.log('TEMPLATE - - - -', template);
        const beeEditor = new BeePlugin();
        let type = 'page';
        beeAuth(type)
        .then(res => {
            togglePageLoader(false);
            beeEditor.token = res.result.data;
            beeEditor.start({ ...DEFAULT_CONFIGURATION, onSave }, template);
            setBeeInstance(beeEditor);
        })
        .catch(err => console.log('ERROR - - -- - ', err));
    }

    const closeModalHandler = () => {
        if(!saveLoader) {
            setSavedPage({json: '', html: ''});
            toggleSavePageModal(false);
            setProgressMessage('');
            toggleSaveLoader(false);
            setErrorMessage('');
            toggleAlertModal(false);
        }
    }

    const computeSkipCompliance = () => {
        // REMOVING COMPLIANCE
        // const complianceOption = isFirmLevelAdmin && !isEmpty(firmSettings) ?
        //     get(firmSettings, `firm_compliance.${[companyId]}.compliance_is`, 'always_off')
        //     : get(userData, 'compliance', '');

        // if((complianceOption === 'always_on' || complianceOption === 'on' || selectedComplianceCheck || loadedPage?.compliance_status)) {
        //     return false;
        // } else {
        //     return true;
        // }
        return true;
    }

    const createUpdateHandler = () => {
        if(showWebinar && isEmpty(selectedWebinar)) {
            setErrorMessage('Select a Webinar');
            return;
        }
        toggleSaveLoader(true);
        setProgressMessage('Saving Thumbnail...');
        const payload = {
            page_json: savedPage.json,
            page_html: savedPage.html,
            thumbnail_url: '',
            is_global_compliance: loadedPage ? loadedPage.is_global_compliance : false,
            skip_compliance: computeSkipCompliance(loadedPage)
        }
        if(
            pageId.includes('new') || 
            (!isAdmin && loadedPage.is_cascade)
        ) {
            payload.name = pageName;
            payload.uid = get(userData, 'uid', '');
            payload.cid = get(userData, 'details.user.company', '');
        }

        if(showWebinar && !isEmpty(selectedWebinar)) {
            payload.webinar_id = selectedWebinar;
        }
        axios.post(`${thumbnailEndpoints.baseUrl}/image`, {
            html: savedPage.html
        })
        .then(res => {
            if(res.status && res.status === 200)
                payload.thumbnail_url = get(res, 'data.url', '');
            setProgressMessage('Saving Page...');
            if(
                pageId.includes('new') || 
                (!isAdmin && loadedPage.is_cascade)
            )
                return createLandingPage({ payload })
            else
                return updateLandingPage({ payload, id: pageId })
        }).then(res => {
            console.log(payload);
            // Sync with mysql db
                if(
                    (pageId.includes('new') || 
                    (!isAdmin && loadedPage.is_cascade)) 
                ) {
                    return complianceLandingPageCreate({
                        name: res.data.name,
                        record_id: res.data._id,
                        page_html: res.data.page_html,
                        s3_url: res.data.template_url,
                        skip_compliance: computeSkipCompliance(loadedPage) ? 'true' : 'false',
                        thumbnail_url: res.data.thumbnail_url
                    })
                } else if(!pageId.includes('new')) {
                    return complianceLandingPageResubmit({ 
                        id: res.data._id, 
                        payload: {
                            name: res.data.name,
                            page_html: res.data.page_html,
                            s3_url: res.data.template_url,
                            skip_compliance: computeSkipCompliance(loadedPage) ? 'true' : 'false',
                            thumbnail_url: res.data.thumbnail_url
                        }
                    })
                }
            }).then(res => {
                toggleSaveLoader(false);
                setProgressMessage('');
                toggleSavePageModal(false);
                if(acBack) {
                    history.push('/campaignV2?type=create');
                } else {
                    history.push('/templates?tab=pages');
                }
                
        }).catch(err => {
            setErrorMessage('Oops! Some error occurred. Please try again later...');
            toggleSaveLoader(false);
        });
    }

    return (
        <>
            <LoaderWrapper isLoading={pageLoader}>
                <Flex flexDirection='column' width="100%">
                    {/* <BeeHeader
                        beeEditorInstance={beeInstance}
                        enable={true}
                        oldView
                        backHandler={() => history.push('/campaign/landing-pages')}
                    /> */}
                    <div id='bee-plugin-container' style={{width: '100%', height: '80vh', margin: '15px 0px'}} />
                    <TemplateFooter 
                        beeInstance={beeInstance}
                        backHandler={() => {
                            if(acBack) {
                                history.push('/campaignV2?type=create');
                            } else {
                                history.push('/templates?tab=pages');
                            }
                        }}
                        enable={loadHeader}
                    />
                </Flex>
                <ModalContainer />
                {savePageModal && (
                    <ConfirmationModalV2 
                        isModalOpen={savePageModal}
                        onClose={closeModalHandler}
                        btnCancelName="Cancel"
                        btnOkName={computeSkipCompliance() ? "Save" : "Send To Compliance"}
                        modalWidth="35%"
                        inputField={(pageId.includes('new') || (!isAdmin && loadedPage.is_cascade))}
                        name={pageName}
                        setName={setPageName}
                        placeHolder="Add a Landing Page Name"
                        progressMessage={progressMessage}
                        disabled={alertModal}
                        loading={saveLoader}
                        message={errorMessage}
                        showCompliance={false}
                        onSubmit={createUpdateHandler}
                        title={(pageId.includes('new') || (!isAdmin && loadedPage.is_cascade)) ? 'Create Landing Page' : 'Do you want to update current Landing Page?'}
                        handleComplianceToggle={handleComplianceToggle}
                        toggleCompliance={selectedComplianceCheck}
                        list={
                            showWebinar && !isEmpty(webinarList) ? (
                                <RecipientsListWrapper padding="0px">
                                    <RecipientsListBody height="168px">
                                        {webinarList.map((item) => (
                                            <Flex
                                              alignItems="center"
                                              justifyContent="flex-start"
                                              mt="5px"
                                              padding="0px 20px"
                                              // ml="25px"
                                            > 
                                            
                                              <RadioInput
                                                type="radio"
                                                name={`webinar${item.id}`}
                                                id={`webinar${item.id}`}
                                                onClick={() => setSelectedWebinar(item.id)}
                                                checked={item.id === selectedWebinar}
                                                style={{ cursor: 'pointer' }}
                                              />
                                              <RadioInputLabel
                                                htmlFor={`webinar${item.id}`}
                                                style={{ cursor: 'pointer', color: 'gray' }}
                                              >
                                              <WebinarWrapper style={{'position' : 'relative'}}>
                                                {item.topic.length > 20
                                                  ? item.topic.slice(0, 20) + '...'
                                                  : item.topic}
                                                <span className="tooltip-text">{item.topic}</span>
                                              </WebinarWrapper>
                                              </RadioInputLabel>
                                              
                                            </Flex>
                                          ))}
                                    </RecipientsListBody>
                                </RecipientsListWrapper>
                            ) : null}
                        
                    />
                )}
            </LoaderWrapper>
        </>
    )
}

const mapStateToProps = (state, ownProps) => {
    const isPremiumAdvisor = get(ownProps, 'roleType') === ROLE_TYPES.ADVISOR_PREMIUM;
    const isEnterpriseLevelAdmin = get(state, 'user.data.details.company.enterprise', "false") === "true";
    const isFirmLevelAdmin = get(state, 'user.data.details.company.firm', "false") === "true";
    const firmSettings = getFirmSettings({ isEnterpriseLevelAdmin, isFirmLevelAdmin, state });
    return {
        userData: state.user.data,
        isAdmin: getUserRole(state) === 'admin',
        isPremiumAdvisor,
        firmSettings,
        isFirmLevelAdmin,
        isEnterpriseLevelAdmin,
        companyId: get(state.user.data, 'details.company.id', ''),
        acBack: get(state, 'campaign.acBack', false),

    }
}

export default connect(mapStateToProps)(LandingPageContainer);