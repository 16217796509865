import styled from 'styled-components';
import { ResponsiveImg } from '..';

export const BlockScreenTitle = styled.h3`
  font-weight: 600;
  color: #6351ed;
  text-align: center;
  font-size: 24px;
  margin-bottom: 10px;
`;

export const BlockScreenDescription = styled.p`
  font-weight: 600;
  font-size: 15px !important;
  padding: 0 40px;
  font-size: 13px;
  margin: 0 0 30px;
`;

export const ContentImg = styled(ResponsiveImg)`
  width: 80%;
`;

export const ContentTitle = styled.h4`
  color: #6351ed;
  font-weight: 600;
  font-size: 15px;
`;
export const ContentDesc = styled.p`
  font-weight: 500;
  padding: 0 40px;
  font-size: 13px;
`;

export const SubscriptionCost = styled.p`
  font-size: 11px;
  padding: 5px 0;
  font-weight: 600;
  color: #6351ed;
`;
