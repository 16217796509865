import { Flex } from '@rebass/grid'
import React from 'react'
import { ColorPallete, Typography } from '../../../utils'
import { BarGraph } from '../../../componentsV2/BarChart'
import { isEmpty } from 'lodash'
import Skeleton from 'react-loading-skeleton'
import { EmptyState } from '../../../componentsV2'
import ErrorDisplay from '../../../componentsV2/ErrorDisplay/ErrorDisplay'


const ContactEngagementCard = ({
  graphData, 
  loading,
  error,
  onRetryClick
}) => {
  return (
    <Flex 
        width="100%" 
        height="250px"
        flexDirection="column"
        style={{
            border: `1px solid ${ColorPallete.neutral200}`,
            borderRadius: '8px',
            boxShadow: '0px 1px 2px 0px #1018281F'
        }}
    >
      <Flex padding="18px" color={ColorPallete.black} fontSize={Typography.TextLarge}>
        Contact Engagement
      </Flex>

      {loading && <Skeleton height={185} baseColor={ColorPallete.neutral100} />}

      {!loading && !error && isEmpty(graphData) && (
        <EmptyState 
          height="185px"
          title="No Analytics Data"
          subTitle="Send out campaigns to get insights"
        />
      )}

      {!loading && error && (
        <ErrorDisplay 
          height="185px"
          onClick={onRetryClick}
        />
      )}

      {!loading && !isEmpty(graphData) && !error && (
        <Flex width="100%" style={{ borderTop: `1px solid ${ColorPallete.neutral200}` }}>
          <Flex width="100%">
            <Flex width="35%" padding="10px" fontSize={Typography.TextMedium}>
              <p style={{ textAlign: 'left' }}>
                <span style={{ color: ColorPallete.neutral600 }}>You have</span>
                <span style={{ color: ColorPallete.neutral900 }}> {graphData.filter(g => g.name === 'High')[0]['Engaged Contacts']} </span>
                <span style={{ color: ColorPallete.neutral600 }}>highly engaged contacts. We suggest that you </span>
                <span style={{ color: ColorPallete.neutral900 }}>create a new segment </span>
                <span style={{ color: ColorPallete.neutral600 }}>for more targeted outreach to this group.</span>
              </p>
            </Flex>
            <Flex width="65%" height="185px" backgroundColor={ColorPallete.neutral50} style={{ borderLeft: `1px solid ${ColorPallete.neutral200}` }}>
              <BarGraph tickRequired={false} dataKey={["name", "Engaged Contacts"]} data={graphData} height={600} width={1500} />
            </Flex>
          </Flex>
        </Flex>
      )}

    </Flex>
  )
}

export default ContactEngagementCard