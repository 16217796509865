import React, { useState } from 'react';
import { Flex } from '@rebass/grid';
import { Button, LoaderWrapper } from '../../components';
import axios from 'axios';
import { apiDetails, objectToFormData } from '../../services/helpers'
import useQuery from '../../customHooks/useQuery';
import { get, isEmpty } from 'lodash';

const ComplianceRejectContainer = () => {

    const query = useQuery();
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);

    const [passcode, setPasscode] = useState('');
    const [comments, setComments] = useState('');

    const handleReject = (e) => {
        e.preventDefault();
        setLoading(true);
        const payload = {
            content_id: query.get('content_id'),
            c_action: "rejected",
            passcode: passcode,
            message: comments,
        }

        axios.post(`${apiDetails.baseUrl}process_compliance`, objectToFormData(payload))
            .then((response) => {
                const res = response.data;
                if (res.result.success) {
                    if (!isEmpty(get(res, 'result.message', ''))) {
                        setMessage(get(res, 'result.message', ''));
                    } else {
                        setMessage("Campaign Rejected Successfully");
                    }
                    setLoading(false);
                  
                } else {
                    if (!isEmpty(get(res, 'result.message', ''))) {
                        setMessage(get(res, 'result.message', ''));
                    } else {
                        setMessage("Passcode incorrect");
                    }
                    setLoading(false);
                }

            })
            .catch((error) => {
                if (error?.response?.status === 500) {
                    setMessage('Oops! Something went wrong');
                } else {
                    setMessage('Passcode incorrect');
                }

                setLoading(false);
            });
        setPasscode("");
        setComments("");
    }

    return (

        <Flex
            justifyContent='center'
            width="100%"
            flexDirection="column"
            mt='100px'

            style={{ padding: '40px 40px', marginTop: '120px' }}
        >
            <Flex fontSize='20px' justifyContent='center' color='#6351ed' width="100%">
                Compliance Campaign Reject Portal
            </Flex>
            <Flex
                justifyContent='center'
            >
                <input
                    name="passcode"
                    type="password"
                    placeholder='Passcode'
                    value={passcode}
                    onChange={(e) => setPasscode(e.target.value)}
                    style={{ width: '46%', marginTop: '20px', borderRadius: "5px", padding: '10px', height: '40px', border: '1px solid black' }}
                />
            </Flex>
            <form>
                <textarea
                    justifyContent='center'
                    rows="5"
                    cols="60"
                    name="comments"
                    placeholder="Enter Comments"
                    value={comments}
                    onChange={(e) => setComments(e.target.value)}
                    style={{ width: '46%', borderRadius: "5px", marginTop: '15px', padding: '10px', border: '1px solid black', color: '#333333', fontSize: '13px', resize: 'none' }}
                />
                <br />
                <Flex
                    width='100%'
                    justifyContent='center'
                    mt='5px'
                >
                    <LoaderWrapper isLoading={loading}>
                        <Button
                            width='46%'
                            margin="10px"
                            onClick={handleReject}
                            disabled={(isEmpty(passcode) || isEmpty(comments))}
                        >
                            Reject Campaign
                        </Button>
                    </LoaderWrapper>
                </Flex>
                {message && (
                    <div style={{ color: '#6351ed', fontSize: '12px' }}>{message}</div>
                )}
            </form>

        </Flex>
    )
}

export default ComplianceRejectContainer;