import { Tooltip } from '@mui/material'
import { Flex } from '@rebass/grid'
import { isEmpty } from 'lodash'
import React from 'react'
import { EmptyState } from 'src/componentsV2'
import { ErrorDisplay } from 'src/componentsV2/ErrorDisplay'
import { ColorPallete, Typography } from 'src/utils'

const ContactDetailsTable = ({
    loading,
    error,
    data,
    handleRetryClick
}) => {
    return (
        <>
            {!loading && !error && isEmpty(data) && (
                <EmptyState
                    height="680px"
                    title="No Analytics Data"
                    subTitle="Send out campaigns to get insights"
                />
            )}
            {!isEmpty(data) && !loading && !error && data.map((item, index) => (
                <Flex
                    width="100%"
                    alignItems="center"
                    backgroundColor={ColorPallete.white}
                    style={{
                        borderTop: index !== 0 && `1px solid ${ColorPallete.neutral200}`,
                        borderBottom: index !== 9 && `1px solid ${ColorPallete.neutral200}`,
                    }}
                >
                    <Flex
                        width="25%"
                        padding="16px"
                        fontSize={Typography.TextMedium}
                    >
                        <Tooltip title={item?.first_name?.length > 20 ? item?.first_name : null} arrow placement="bottom">
                            <span style={{
                                color: ColorPallete.neutral900,
                                overflow: 'hidden',
                                whiteSpace: 'nowrap',
                                textOverflow: 'ellipsis',
                                width: '100%',
                                textAlign: 'left'
                            }}>
                                {item?.first_name?.length > 20 ? `${item?.first_name?.substring(0, 20)}...` : item?.first_name || '-'}
                            </span>
                        </Tooltip>
                    </Flex>

                    <Flex
                        width="25%"
                        padding="16px"
                        fontSize={Typography.TextMedium}
                    >
                        <Tooltip title={item?.last_name?.length > 20 ? item?.last_name : null} arrow placement="bottom">
                            <span style={{
                                color: ColorPallete.neutral900,
                                overflow: 'hidden',
                                whiteSpace: 'nowrap',
                                textOverflow: 'ellipsis',
                                width: '100%',
                                textAlign: 'left'
                            }}>
                                {item?.last_name?.length > 20 ? item?.last_name?.substring(0, 20) + '...' : item?.last_name || '-'}
                            </span>
                        </Tooltip>
                    </Flex>

                    <Flex
                        width="25%"
                        padding="16px"
                        fontSize={Typography.TextMedium}
                    >
                        <Tooltip title={item?.email?.length > 20 ? item?.email : null} arrow placement="bottom">
                            <Flex style={{
                                color: ColorPallete.neutral900,
                                overflow: 'hidden',
                                whiteSpace: 'nowrap',
                                textOverflow: 'ellipsis',
                                width: 'auto',
                                textAlign: 'left'
                            }}>
                                {item?.email?.length > 20 ? item?.email?.substring(0, 20) + '...' : item?.email || '-'}
                            </Flex>
                        </Tooltip>
                    </Flex>



                    <Flex width="25%" padding="16px" fontSize={Typography.TextMedium} color={ColorPallete.neutral900}>
                        {item.engagement_score}
                    </Flex>
                </Flex>
            ))}
            {!loading && error && (
                <ErrorDisplay height="680px" onClick={handleRetryClick} />
            )}
        </>
    )
}



export default ContactDetailsTable