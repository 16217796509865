/* eslint-disable react-hooks/exhaustive-deps */
import { Flex } from '@rebass/grid';
import React, { useState, useEffect } from 'react';
import {
  LoaderWrapper,
  DownloadButton,
  DisplayScore,
} from '../../../components';
import {
  TopLeadWrapper,
  TopShareName,
  TopPublisherName,
  OverviewShareTitle,
  SponsoredWrapper,
  OverviewBadge,
  OverviewTabHeading,
  TopLeadEmail,
  VerticalLine,
  TopLeadName,
  ProspectFeaturedArticle,
} from './EngagementTab.styles';
import { get, isEmpty, uniqBy } from 'lodash';
import { DownloadButtonFlex } from '../AnalyticsContainer.styles';
import {
  saveFile,
  getTabNameFromRepType,
  onImageError,
  getMonthDate,
  overviewTabSliderSettings,
} from '../../../utils';
import { ArticleSliderWrapper } from '../../DashboardContainer/DashboardContainer.styles';
import Slider from 'react-slick';
import { getDownloadCsvData } from '../../../services/analytics';

const ShareInsightTable = ({
  selectedItem,
  analyticsData,
  isAnalyticsDataFetching,
  selectedRepType,
  analyticsObject,
  analyticsRequest,
  page,
  setPage,
  filterByContactList,
  selectedContacts,
  contactGroup,
  isAdmin,
}) => {
  const [sliderIndex, setSliderIndex] = useState(0);
  const [allArticles, setAllArticles] = useState([]);
  const [loading, setLoading] = useState(false);

  const getEngagementMediaImage = (media) => {
    const mediaUrl = Array.isArray(media) ? get(media, '[0]', {}) : media;
    if (!isEmpty(mediaUrl)) {
      console.log(
        `${mediaUrl.path}${get(mediaUrl, 'name.resized')}.${mediaUrl.ext}`
      );
      return `${mediaUrl.path}${get(mediaUrl, 'name.resized')}.${mediaUrl.ext}`;
    } else {
      return '';
    }
  };

  const trimmedPublisherName =
    (selectedItem.publisher.name || '').length > 15
      ? `${selectedItem.publisher.name.substring(0, 15)}...`
      : selectedItem.publisher.name;
  const trimmedTitle =
    (selectedItem.title || '').length > 80
      ? `${selectedItem.title.substring(0, 80)}...`
      : selectedItem.title;
  const extractedDate = getMonthDate(selectedItem.date);
  const formatted_date = `${extractedDate[0]} ${extractedDate[1]}`;

  useEffect(() => {
    if (!isEmpty(selectedContacts)) {
      setAllArticles([]);
      setSliderIndex(0);
    }
  }, [selectedContacts]);

  const onDownloadCSVClick = () => {
    setLoading(true);
    const tabName = getTabNameFromRepType(selectedRepType);
    const { startDate, endDate } = analyticsObject || {};
    const fileName = `${tabName}-${startDate}_${endDate}`;

    getDownloadCsvData({
      repType: selectedRepType,
      startDate,
      endDate,
      engagement_id: selectedItem.id,
      contact_list: selectedContacts,
      filters: analyticsObject.filterParams
    })
      .then((res) => {
        saveFile(res, fileName);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  return (
    <LoaderWrapper isLoading={isAnalyticsDataFetching}>
      <Flex width={1} flexDirection="column">
        <Flex pl="11px">
          <ProspectFeaturedArticle style={{ marginLeft: '7px' }} width="96.4%">
            <Flex width="66%" justifyContent="space-between">
              <Flex width="20%">
                <img
                  src={getEngagementMediaImage(selectedItem.media_urls)}
                  alt="article"
                  width="80px"
                  onError={onImageError}
                  style={{ objectFit: 'cover' }}
                />
              </Flex>
              <Flex width="60%" flexDirection="column" padding="0px 10px">
                <Flex alignItems="center">
                  <TopShareName>{selectedItem.topic.name}</TopShareName>
                  &nbsp;
                  <TopPublisherName
                    style={{ lineHeight: '1.4' }}
                    title={
                      (selectedItem.publisher.name || '').length > 15
                        ? selectedItem.publisher.name
                        : ''
                    }
                  >
                    {trimmedPublisherName}{selectedItem.date ? `, ${formatted_date}` : ''}
                  </TopPublisherName>
                </Flex>
                <OverviewShareTitle
                  style={{
                    fontSize: '13px',
                    padding: '0px',
                    marginTop: '-4px',
                  }}
                  title={selectedItem.title}
                >
                  {trimmedTitle}
                </OverviewShareTitle>
              </Flex>
              <Flex width="20%" justifyContent="center" alignItems="center">
                <DisplayScore
                  height="40px"
                  width="40px"
                  fontSize="14px"
                  borderRadius="18px"
                  border="4px solid"
                  score={selectedItem.engagement_score > 0 ? selectedItem.engagement_score : selectedItem.social_engagement_score}
                />
              </Flex>
            </Flex>
            {!isEmpty(selectedItem.suggested_investments) && <VerticalLine />}
            {!isEmpty(selectedItem.suggested_investments) &&
              selectedItem.suggested_investments.slice(0, 1)[0] &&
              selectedItem.suggested_investments.slice(0, 1).map((item, index) => item && (
                  <SponsoredWrapper
                    style={{ height: '57.6px', width: '193px' }}
                  >
                    <Flex
                      width="100%"
                      justifyContent="flex-start"
                      padding="2px 0px"
                      style={{ height: '13px' }}
                    >
                      <img
                        src={item.logo_path}
                        style={{ objectFit: 'contain', objectPosition: 'left' }}
                        alt="Company"
                      />
                    </Flex>
                    <Flex
                      width="100%"
                      fontSize="10px"
                      padding="2px 0px"
                      style={{
                        textAlign: 'left',
                        color: '#49484a',
                        fontWeight: '500',
                        whiteSpace: 'nowrap',
                        height: '15px',
                      }}
                    >
                      <span style={{ marginTop: '-10px' }}>
                        <div
                          className="tooltip-div tooltip-disclosure"
                          style={{ paddingTop: 'unset' }}
                        >
                          <p
                            style={{
                              cursor:
                                item.name.length > 32 ? 'pointer' : 'default',
                            }}
                          >
                            {item.name.length > 32
                              ? item.name.substring(0, 32) + '...'
                              : item.name}
                          </p>
                          {item.name.length > 32 && (
                            <span
                              className="tooltip-text tooltiptext-disclosure"
                              style={{
                                left: '185px',
                                top: '-27px',
                                whiteSpace: 'nowrap',
                                width: 'initial',
                                backgroundColor: '#404040',
                                color: '#ffffff',
                                position: 'relative',
                              }}
                            >
                              {item.name}
                            </span>
                          )}
                        </div>
                      </span>
                    </Flex>
                    <Flex
                      justifyContent="space-between"
                      style={{ paddingTop: '2px' }}
                    >
                      <Flex>
                        <span
                          style={{
                            color: '#49484a',
                            fontWeight: 'bold',
                            fontSize: '10px',
                          }}
                        >
                          {item.ticker}
                        </span>
                        &nbsp;
                        {item.matched_topics &&
                          item.matched_topics.length > 0 &&
                          item.matched_topics.slice(0, 1)[0] &&
                          item.matched_topics.slice(0, 1).map((item, index) => item && (
                            <span
                              style={{
                                color: '#49484a',
                                fontSize: '8px',
                                fontWeight: 500,
                                border: '1px solid #49484a',
                                borderRadius: '2px',
                                marginRight: '4px',
                                height: '11px',
                                display: 'flex',
                                alignItems: 'center',
                                padding: '0px 2px',
                                marginTop: '2px',
                              }}
                            >
                              <div
                                className="tooltip-div tooltip-disclosure"
                                style={{ paddingTop: 'unset' }}
                              >
                                <p
                                  style={{
                                    cursor:
                                      item.length > 6 ? 'pointer' : 'default',
                                  }}
                                >
                                  {item.length > 6
                                    ? item.substring(0, 6) + '...'
                                    : item}
                                </p>
                                {item.length > 6 && (
                                  <span
                                    className="tooltip-text tooltiptext-disclosure"
                                    style={{
                                      left: '39px',
                                      top: '1px',
                                      whiteSpace: 'nowrap',
                                      width: 'initial',
                                      backgroundColor: '#404040',
                                      color: '#ffffff',
                                    }}
                                  >
                                    {item}
                                  </span>
                                )}
                              </div>
                            </span>
                          ))}
                      </Flex>
                      {item.is_sponsored && (
                        <OverviewBadge style={{ float: 'right' }}>
                          <span>Sponsored</span>
                        </OverviewBadge>
                      )}
                    </Flex>
                  </SponsoredWrapper>
                ))}
          </ProspectFeaturedArticle>
        </Flex>
        <Flex pt="10px" pb="10px" pl="11px">
          <OverviewTabHeading style={{ position: 'relative', top: '6px' }}>
            Clients/Prospects Who Found This Content Engaging
          </OverviewTabHeading>
        </Flex>
        <Flex>
          {isEmpty(get(analyticsData, 'leads', [])) ? (
            <div
              style={{
                fontSize: '12px',
                height: '230px',
                display: 'flex',
                margin: 'auto',
              }}
            >
              <div style={{ margin: 'auto' }}>No Records Found</div>
            </div>
          ) : (
            <ArticleSliderWrapper type="engagement" width="100%">
              <Slider {...overviewTabSliderSettings}>
                {get(analyticsData, 'leads', [])[0] && 
                  get(analyticsData, 'leads', []).map((item, index) => {
                  const trimName =
                    (item.first_name + ' ' + item.last_name).length > 15
                      ? (item.first_name + ' ' + item.last_name).substring(
                          0,
                          15
                        ) + '...'
                      : item.first_name + ' ' + item.last_name;
                  const trimEmail =
                    item.email && item.email.length > 20
                      ? item.email.substring(0, 20) + '...'
                      : item.email;
                  return (
                    <TopLeadWrapper
                      width="213px !important"
                      height="212px"
                      margin="14px 10px 0px 8.2px"
                      padding="0px"
                    >
                      <Flex style={{ height: '99px', width: '100%' }}>
                        <img
                          alt="share"
                          src={
                            !isEmpty(item.suggested_investments)
                              ? item.suggested_investments[0].theme_image_path
                              : ''
                          }
                          width="212px"
                          onError={onImageError}
                          style={{
                            borderTopLeftRadius: '8px',
                            borderTopRightRadius: '8px',
                            objectFit: 'cover',
                          }}
                        />
                      </Flex>
                      <Flex flexDirection="column" padding="8px 8px 10px 8px">
                        <Flex
                          width="100%"
                          justifyContent="space-between"
                          padding="0px 6px"
                        >
                          <Flex
                            flexDirection="column"
                            justifyContent="flex-start"
                          >
                            <TopShareName>{trimName}</TopShareName>
                            <TopLeadEmail
                              title={
                                item.email && item.email.length > 20
                                  ? item.email
                                  : ''
                              }
                            >
                              {trimEmail}
                            </TopLeadEmail>
                          </Flex>
                          <Flex>
                            <DisplayScore score={item.engagement_score > 0 ? item.engagement_score : item.social_engagement_score} />
                          </Flex>
                        </Flex>
                        <Flex justifyContent="space-between" padding="10px 8px 0px">
                          <span>Clicks - {item.clicks}</span>
                          <span>Opens - {item.opens}</span>
                        </Flex>
                        {!isEmpty(item.suggested_investments)
                          ? item.suggested_investments
                              .slice(0, 1)[0] &&
                            item.suggested_investments
                              .slice(0, 1).map((item, index) => item && (
                                <SponsoredWrapper mt="10px">
                                  <Flex
                                    width="100%"
                                    justifyContent="flex-start"
                                    padding="2px 0px"
                                    style={{ height: '13px' }}
                                  >
                                    <img
                                      src={item.logo_path}
                                      style={{
                                        objectFit: 'contain',
                                        objectPosition: 'left',
                                      }}
                                      alt="Company"
                                    />
                                  </Flex>
                                  <Flex
                                    width="100%"
                                    fontSize="10px"
                                    padding="2px 0px"
                                    style={{
                                      textAlign: 'left',
                                      color: '#49484a',
                                      fontWeight: '500',
                                      whiteSpace: 'nowrap',
                                      height: '15px',
                                    }}
                                  >
                                    <span style={{ marginTop: '-10px' }}>
                                      <div
                                        className="tooltip-div tooltip-disclosure"
                                        style={{ paddingTop: 'unset' }}
                                      >
                                        <p
                                          style={{
                                            cursor:
                                              item.name.length > 28
                                                ? 'pointer'
                                                : 'default',
                                          }}
                                        >
                                          {item.name.length > 28
                                            ? item.name.substring(0, 28) + '...'
                                            : item.name}
                                        </p>
                                        {item.name.length > 28 && (
                                          <span
                                            className="tooltip-text tooltiptext-disclosure"
                                            style={{
                                              left: '167px',
                                              top: '-25px',
                                              whiteSpace: 'nowrap',
                                              width: 'initial',
                                              backgroundColor: '#404040',
                                              color: '#ffffff',
                                              position: 'relative',
                                            }}
                                          >
                                            {item.name}
                                          </span>
                                        )}
                                      </div>
                                    </span>
                                  </Flex>
                                  <Flex
                                    justifyContent="space-between"
                                    style={{ paddingTop: '2px' }}
                                  >
                                    <Flex>
                                      <span
                                        style={{
                                          color: '#49484a',
                                          fontWeight: 'bold',
                                          fontSize: '10px',
                                        }}
                                      >
                                        {item.ticker}
                                      </span>
                                      &nbsp;
                                      {item.matched_topics &&
                                        item.matched_topics.length > 0 &&
                                        item.matched_topics
                                          .slice(0, 1)[0] && 
                                        item.matched_topics
                                          .slice(0, 1).map((item, index) => item && (
                                            <span
                                              style={{
                                                color: '#49484a',
                                                fontSize: '8px',
                                                fontWeight: 500,
                                                border: '1px solid #49484a',
                                                borderRadius: '2px',
                                                marginRight: '4px',
                                                height: '11px',
                                                display: 'flex',
                                                alignItems: 'center',
                                                padding: '0px 2px',
                                                marginTop: '2px',
                                              }}
                                            >
                                              <div
                                                className="tooltip-div tooltip-disclosure"
                                                style={{ paddingTop: 'unset' }}
                                              >
                                                <p
                                                  style={{
                                                    cursor:
                                                      item.length > 6
                                                        ? 'pointer'
                                                        : 'default',
                                                  }}
                                                >
                                                  {item.length > 6
                                                    ? item.substring(0, 6) +
                                                      '...'
                                                    : item}
                                                </p>
                                                {item.length > 6 && (
                                                  <span
                                                    className="tooltip-text tooltiptext-disclosure"
                                                    style={{
                                                      left: '39px',
                                                      top: '1px',
                                                      whiteSpace: 'nowrap',
                                                      width: 'initial',
                                                      backgroundColor:
                                                        '#404040',
                                                      color: '#ffffff',
                                                    }}
                                                  >
                                                    {item}
                                                  </span>
                                                )}
                                              </div>
                                            </span>
                                          ))}
                                    </Flex>
                                    {item.is_sponsored && (
                                      <OverviewBadge style={{ float: 'right' }}>
                                        <span>Sponsored</span>
                                      </OverviewBadge>
                                    )}
                                  </Flex>
                                </SponsoredWrapper>
                              ))
                          : null}
                      </Flex>
                    </TopLeadWrapper>
                  );
                })}
              </Slider>
            </ArticleSliderWrapper>
          )}
        </Flex>
        <DownloadButtonFlex
          justifyContent="flex-end"
          style={{ paddingTop: '66px' }}
        >
          <LoaderWrapper isLoading={loading}>
            <DownloadButton disabled={get(analyticsData, 'leads', []) == null || get(analyticsData, 'leads', []).length === 0} onClick={onDownloadCSVClick} fontSize='18px' />
          </LoaderWrapper>
        </DownloadButtonFlex>
      </Flex>
    </LoaderWrapper>
  );
};

export default ShareInsightTable;
