import { Flex } from '@rebass/grid';
import React from 'react';

import { intersectionWith, isEmpty, isEqual } from 'lodash';
import {
  Button,
  Modal,
  ModalCloseButton,
  ModalBody,
  ModalHeader,
} from '../../../../components';
import {
  HorizontalSeparatorSolid,
  FilterRow,
  FilterRowTitle,
  FilterRowChips,
} from './DripDashboardFilterPopup.styles';

export default function DripDashboardFilterPopup({
  handleModalClose,
  setFilters,
  filters,
  campaignRequest,
  setSelectedCampaignTab,
}) {
  let statusArray = ['draft', 'scheduled', 'in_progress', 'completed'];
  let durationArray = ['["4","weeks"]', '["8","weeks"]'];
  let frequencyArray = ['["every","week"]', '["other","week"]'];
  let targetArray = ['clients', 'prospects'];
  let platformArray = ['email', 'facebook', 'twitter', 'linkedin'];

  console.log('filters', filters);

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.target);

    var object = [];
    data.forEach((value, key) => object.push(key));

    let status = intersectionWith(statusArray, object, isEqual);

    let duration = intersectionWith(durationArray, object, isEqual);

    // let durationMold = duration.map((value, key) => {
    //   return JSON.parse(value);
    // });

    let frequency = intersectionWith(frequencyArray, object, isEqual);

    let target = intersectionWith(targetArray, object, isEqual);

    let platform = intersectionWith(platformArray, object, isEqual);
    if (
      isEmpty(status) &&
      isEmpty(duration) &&
      isEmpty(frequency) &&
      isEmpty(target) &&
      isEmpty(platform)
    ) {
      setFilters();
      campaignRequest();
      setSelectedCampaignTab(0);
    } else {
      setFilters({
        status: status,
        duration: duration,
        frequency: frequency,
        target: target,
        platform: platform,
      });
    }

    handleModalClose();
  };

  return (
    <Modal
      // onRequestClose={handleModalClose}
      shouldCloseOnOverlayClick
      isOpen
      size="flexible"
      bgColor="#fff"
      padding="0px"
    >
      <form onSubmit={handleSubmit}>
        <ModalHeader fontSize="16px" fontColor="#000">
          <Flex
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Flex>
              <span>Filter</span>
            </Flex>
            <Flex>
              <ModalCloseButton
                onClick={handleModalClose}
                color="#000"
                size={'17px'}
              />
            </Flex>
          </Flex>
        </ModalHeader>
        <HorizontalSeparatorSolid />
        <ModalBody>
          <Flex display="flex" flexDirection="column">
            <FilterRow
              display="flex"
              flexDirection="row"
              justifyContent="flex-start"
            >
              <FilterRowTitle>State</FilterRowTitle>

              <FilterRowChips>
                <label className="checkbox-button">
                  <input
                    type="checkbox"
                    className="checkbox-button__input"
                    name="draft"
                    value="draft"
                    defaultChecked={filters && filters.status.includes('draft')}
                  />

                  <span className="checkbox-button__control"></span>
                  <span className="checkbox-button__label">Drafts</span>
                </label>
              </FilterRowChips>
              <FilterRowChips>
                <label className="checkbox-button">
                  <input
                    type="checkbox"
                    className="checkbox-button__input"
                    name="scheduled"
                    value="scheduled"
                    defaultChecked={
                      filters && filters.status.includes('scheduled')
                    }
                  />
                  <span className="checkbox-button__control"></span>
                  <span className="checkbox-button__label">Scheduled</span>
                </label>
              </FilterRowChips>
              <FilterRowChips>
                <label className="checkbox-button">
                  <input
                    type="checkbox"
                    className="checkbox-button__input"
                    id="in_progress"
                    name="in_progress"
                    defaultChecked={
                      filters && filters.status.includes('in_progress')
                    }
                  />
                  <span className="checkbox-button__control"></span>
                  <span className="checkbox-button__label">In Progress</span>
                </label>
              </FilterRowChips>
              <FilterRowChips>
                <label className="checkbox-button">
                  <input
                    type="checkbox"
                    className="checkbox-button__input"
                    id="completed"
                    name="completed"
                    defaultChecked={
                      filters && filters.status.includes('completed')
                    }
                  />
                  <span className="checkbox-button__control"></span>
                  <span className="checkbox-button__label">Completed</span>
                </label>
              </FilterRowChips>
            </FilterRow>
            <FilterRow display="flex" flexDirection="row">
              <FilterRowTitle> Duration</FilterRowTitle>
              <FilterRowChips>
                <label className="checkbox-button">
                  <input
                    type="checkbox"
                    className="checkbox-button__input"
                    id='["4","weeks"]'
                    name='["4","weeks"]'
                    defaultChecked={
                      filters && filters.duration.includes('["4","weeks"]')
                    }
                  />
                  <span className="checkbox-button__control"></span>
                  <span className="checkbox-button__label">4 Weeks</span>
                </label>
              </FilterRowChips>
              <FilterRowChips>
                <label className="checkbox-button">
                  <input
                    type="checkbox"
                    className="checkbox-button__input"
                    id='["8","weeks"]'
                    name='["8","weeks"]'
                    defaultChecked={
                      filters && filters.duration.includes('["8","weeks"]')
                    }
                  />
                  <span className="checkbox-button__control"></span>
                  <span className="checkbox-button__label">8 Weeks</span>
                </label>
              </FilterRowChips>
            </FilterRow>
            <FilterRow display="flex" flexDirection="row">
              <FilterRowTitle>Frequency</FilterRowTitle>
              <FilterRowChips>
                <label className="checkbox-button">
                  <input
                    type="checkbox"
                    className="checkbox-button__input"
                    id='["every","week"]'
                    name='["every","week"]'
                    defaultChecked={
                      filters && filters.frequency.includes('["every","week"]')
                    }
                  />
                  <span className="checkbox-button__control"></span>
                  <span className="checkbox-button__label">Every Week</span>
                </label>
              </FilterRowChips>
              <FilterRowChips>
                <label className="checkbox-button">
                  <input
                    type="checkbox"
                    className="checkbox-button__input"
                    id='["other","week"]'
                    name='["other","week"]'
                    defaultChecked={
                      filters && filters.frequency.includes('["other","week"]')
                    }
                  />
                  <span className="checkbox-button__control"></span>
                  <span className="checkbox-button__label">
                    Every Other Week
                  </span>
                </label>
              </FilterRowChips>
            </FilterRow>
            <FilterRow display="flex" flexDirection="row">
              <FilterRowTitle>Target</FilterRowTitle>
              <FilterRowChips>
                <label className="checkbox-button">
                  <input
                    type="checkbox"
                    className="checkbox-button__input"
                    name="clients"
                    value="clients"
                    defaultChecked={
                      filters && filters.target.includes('clients')
                    }
                  />
                  <span className="checkbox-button__control"></span>
                  <span className="checkbox-button__label">Clients</span>
                </label>
              </FilterRowChips>
              <FilterRowChips>
                <label className="checkbox-button">
                  <input
                    type="checkbox"
                    className="checkbox-button__input"
                    name="prospects"
                    value="prospects"
                    defaultChecked={
                      filters && filters.target.includes('prospects')
                    }
                  />
                  <span className="checkbox-button__control"></span>
                  <span className="checkbox-button__label">Prospects</span>
                </label>
              </FilterRowChips>
            </FilterRow>
            <FilterRow display="flex" flexDirection="row">
              <FilterRowTitle>Platforms</FilterRowTitle>
              <FilterRowChips>
                <label className="checkbox-button">
                  <input
                    type="checkbox"
                    className="checkbox-button__input"
                    name="email"
                    value="email"
                    defaultChecked={
                      filters && filters.platform.includes('email')
                    }
                  />
                  <span className="checkbox-button__control"></span>
                  <span className="checkbox-button__label">Email</span>
                </label>
              </FilterRowChips>
              <FilterRowChips>
                <label className="checkbox-button">
                  <input
                    type="checkbox"
                    className="checkbox-button__input"
                    name="facebook"
                    value="facebook"
                    defaultChecked={
                      filters && filters.platform.includes('facebook')
                    }
                  />
                  <span className="checkbox-button__control"></span>
                  <span className="checkbox-button__label">Facebook</span>
                </label>
              </FilterRowChips>
              <FilterRowChips>
                <label className="checkbox-button">
                  <input
                    type="checkbox"
                    className="checkbox-button__input"
                    name="linkedin"
                    value="linkedin"
                    defaultChecked={
                      filters && filters.platform.includes('linkedin')
                    }
                  />
                  <span className="checkbox-button__control"></span>
                  <span className="checkbox-button__label">LinkedIn</span>
                </label>
              </FilterRowChips>
              <FilterRowChips>
                <label className="checkbox-button">
                  <input
                    type="checkbox"
                    className="checkbox-button__input"
                    name="twitter"
                    value="twitter"
                    defaultChecked={
                      filters && filters.platform.includes('twitter')
                    }
                  />
                  <span className="checkbox-button__control"></span>
                  <span className="checkbox-button__label">Twitter</span>
                </label>
              </FilterRowChips>
            </FilterRow>
          </Flex>
        </ModalBody>
        <HorizontalSeparatorSolid />
        <Flex
          display="flex"
          flexDirection="row"
          justifyContent="flex-end"
          alignItems="center"
        >
          <Button
            onClick={handleModalClose}
            margin="10px"
            borderRadius="5px"
            bgColor="#fff"
            color="#708892"
            borderColor="#708892"
          >
            <div
              style={{
                alignItems: 'center',
                flexDirection: 'row',
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <span style={{ marginRight: '8px' }}>Cancel</span>
              <img src="/assets/images/drip/close_grey.svg" alt="" />
            </div>
          </Button>
          <Button margin="10px" borderRadius="5px" type="submit">
            <div
              style={{
                alignItems: 'center',
                flexDirection: 'row',
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <img
                src="/assets/images/drip/done_white.svg"
                alt=""
                style={{ marginRight: '8px' }}
              />
              <span>Apply</span>
            </div>
          </Button>
        </Flex>
      </form>
    </Modal>
  );
}
