import React from 'react';
import Button from 'src/componentsV2/Button';
import CampaignCountZeroState from 'src/assets/background/home/campaigns-count-zero-state.svg';
import {
  ZeroStateContainer,
  ZeroStateWrapper,
  ZeroStateContent,
} from '../styles';

function ZeroState({ handleClick }) {
  return (
    <ZeroStateWrapper>
      <ZeroStateContainer backgroundUrl={CampaignCountZeroState}>
        <ZeroStateContent>
          <h5 className="title">
            Start your very first campaign to start sourcing new contacts!
          </h5>
          <Button onClick={handleClick}>Start New Campaign</Button>
        </ZeroStateContent>
      </ZeroStateContainer>
    </ZeroStateWrapper>
  );
}

export default ZeroState;
