import React from 'react';
import ReactSkeleton from 'react-loading-skeleton';
import LightbulbFilament from 'src/assets/icons/LightbulbFilament';
import { PillSkeleton } from '../styles';
import {
  Card,
  CardContent,
  Content,
  Header,
  Cards,
  Pills,
} from './styles';

function Skeleton() {
  return (
    <Content>
      <Header>
        <LightbulbFilament />
        <h3>Key Insights</h3>
      </Header>
      <Cards>
        <Card>
          <CardContent>
            <ReactSkeleton width={50} height={20} />
            <ReactSkeleton width={150} height={20} />
            <ReactSkeleton width={100} height={20} />
            <Pills>
              <PillSkeleton width={100} height={24} />
            </Pills>
          </CardContent>
        </Card>
        <Card>
          <CardContent>
            <ReactSkeleton width={50} height={20} />
            <ReactSkeleton width={150} height={20} />
            <ReactSkeleton width={100} height={20} />
            <Pills>
              <PillSkeleton width={100} height={24} />
              <PillSkeleton width={100} height={24} />
            </Pills>
          </CardContent>
        </Card>
      </Cards>
    </Content>
  );
}

export default Skeleton;
