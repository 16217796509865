import React, { useState } from 'react';
import { format, isValid } from 'date-fns';
import { useQueryClient } from 'react-query';
import RecommendedContent from './RecommendedContent';
import Article from '../common/components/Article';
import ArticleSkeleton from '../common/components/Article/Skeleton';
import useRESTQuery from 'src/hooks/useRESTQuery';
import useQueryForKeyInsights from 'src/hooks/useQueryForKeyInsights';
import { Separator, Wrapper } from './styles';
import { Articles } from '../styles';
import ZeroState from '../common/components/ZeroState';

function AllContent({ email = '' }) {
  const queryClient = useQueryClient();
  const [selectedArticleId, setSelectedArticleId] = useState('');
  const {
    data: topicsData,
    isLoading: topicsLoader,
    isFetching,
  } = useQueryForKeyInsights(email);

  const {
    isLoading: recommendationsLoader,
    data: recommendationsData,
    isFetching: recommendationsFetching,
  } = useRESTQuery({
    key: 'discover-recommended-articles',
    endpoint: `/discover/recommendation_articles?themes=${JSON.stringify(
      topicsData?.topics ?? []
    )}`,
    options: {
      select: (res) => {
        const transformedResponse = Array.isArray(res?.result?.data)
          ? res?.result?.data?.map((article) => {
              return {
                id: article?.id ?? '',
                name: article?.name ?? '',
                description: article?.description ?? '',
                created_date: isValid(new Date(article?.created_date))
                  ? format(new Date(article?.created_date), 'MMM dd yyyy')
                  : '',
                publisher_name: article?.publisher_name ?? '',
                media_url: article?.media_url ?? {},
                tags: article?.tags,
                format: article?.format,
                bookmark_id: article?.bookmark_id ?? '',
                is_bookmarked: article?.is_bookmarked ?? false,
                content_url: article?.content_url ?? '',
                article_bookmark_id: article?.article_bookmark_id ?? '',
              };
            })
          : [];

        return {
          top4: [...transformedResponse]?.splice(0, 3),
          articles: [...transformedResponse]?.splice(
            3,
            transformedResponse?.length
          ),
        };
      },
      enabled: !!Array.isArray(topicsData?.topics),
      refetchOnMount: true,
    },
  });

  const invalidateQueries = (articleId) => {
    setSelectedArticleId(articleId);
    queryClient.refetchQueries(['discover-recommended-articles']);
  };

  return (
    <Wrapper>
      <RecommendedContent
        recommendationsData={recommendationsData?.top4}
        topics={topicsData?.topics}
        topicsLoader={topicsLoader || isFetching}
        recommendationsLoader={recommendationsLoader}
        recommendationsFetching={recommendationsFetching}
        setSelectedArticleId={setSelectedArticleId}
      />
      <Separator />
      <Articles>
        {recommendationsLoader ? (
          <>
            <ArticleSkeleton />
            <ArticleSkeleton />
            <ArticleSkeleton />
          </>
        ) : (
          <>
            {recommendationsData?.articles?.length === 0 ? (
              <ZeroState />
            ) : (
              recommendationsData?.articles?.map((article) => (
                <>
                  {recommendationsFetching &&
                  selectedArticleId === article?.id ? (
                    <ArticleSkeleton />
                  ) : (
                    <Article
                      article={article}
                      key={article?.id}
                      invalidateQueries={invalidateQueries}
                    />
                  )}
                </>
              ))
            )}
          </>
        )}
      </Articles>
    </Wrapper>
  );
}

export default AllContent;
