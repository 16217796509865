import React, { useEffect, useRef, useState } from 'react';
import { Flex, Box } from '@rebass/grid';
import { Formik } from 'formik';
import { isEmpty, get } from 'lodash';

import {
  Modal,
  ModalHeader,
  ModalBody,
  FileUpload,
  Button,
  DripIconButton,
  ModalCloseButton,
  MioTextArea,
  DownloadSampleDiv,
  Loader,
} from '../';
import { createCsvFileFromJson } from '../../utils';
import { getSocialAccountURLs } from '../../services/dashboard';
import {
  UploadBox,
  UploadFlex,
  GroupBox,
} from '../InviteModal/InviteModal.styles';

const validate = (values) => {
  const errors = {};
  const { emailList, inviteUpload } = values;
  if (isEmpty(emailList) && isEmpty(inviteUpload)) {
    errors.formError =
      'Please add email address or upload CSV or import from hubspot to invite';
  }
  return errors;
};

const CreateAdminModal = ({
  isModalOpen,
  inviteRequest,
  closeModal,
  inviteSending,
  inviteError,
  inviteSetState,
}) => {
  const [successMessage, setSuccessMesage] = useState('');
  const [errorMessage, setErrorMesage] = useState('');
  const [socialAccountURL, setSocialAccountURL] = useState({});
  const didMountRef = useRef(null);
  useEffect(() => {
    if (!didMountRef.current) {
      didMountRef.current = true;
      getSocialAccountURLs().then((res) => {
        setSocialAccountURL(res.result.data);
      });
    } 
    else {
      console.log('ELSE BLOCK CreateAdminModal useEffect()');
      if (!inviteSending) {
        if (inviteError) 
          setErrorMesage('Please upload as per sample file');
        else {
          setSuccessMesage('✓ Invitation Sent Successfully');
          window.setTimeout(() => {
            closeModal();
            setSuccessMesage('');
            setErrorMesage('');
          }, 2000);
        }
      }
      else
        setErrorMesage('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inviteSending]);
  const onInviteClick = (values) => {
    const formData = new FormData();
    let textContent = '';
    if (values.emailList) {
      textContent = createCsvFileFromJson(values.emailList, 'admin');
      formData.append('media1', textContent);
    }
    const fileName = get(values, 'inviteUpload[0].name', '');
    if (!isEmpty(fileName)) {
      formData.append('media1', get(values, 'inviteUpload[0]'));
    }
    console.log('FILE NAME - - -', fileName, 'MEDIA 1 - - -', get(values, 'inviteUpload[0]'));
    console.log('FORM DATA - - -- - -', formData);

    inviteRequest({
      params: formData,
    });
  };

  const onHubspotClick = () => {
    const hsLink = get(socialAccountURL, 'hs.oauth_url');
    window.location.href = hsLink;
  };

  const initialState = { emailList: '', inviteUpload: '' };
  return (
    <Modal
      onRequestClose={closeModal}
      shouldCloseOnOverlayClick
      isOpen={isModalOpen}
      size="medium"
    >
      <ModalHeader>Create Admin</ModalHeader>
      <ModalCloseButton onClick={closeModal} />
      <ModalBody>
        <Formik
          initialValues={initialState}
          onSubmit={onInviteClick}
          validate={validate}
        >
          {({ handleSubmit, handleChange, setFieldValue, errors, values }) => {
            const fileName = get(values, 'inviteUpload[0].name', '');
            const onFileRemoveClick = (fileName) => {
              setFieldValue('inviteUpload', '');
            };
            return (
              <Flex width={1} flexDirection="column">
                <Box width={1}>
                  <MioTextArea
                    id="emailList"
                    name="emailList"
                    onChange={handleChange}
                    cols="30"
                    rows="5"
                    placeholder="Add Member emails (separated with a comma)"
                  ></MioTextArea>
                </Box>
                <br />
                <b>or</b>
                <br />
                <UploadFlex width={1} justifyContent="space-between" pb="20px">
                  <UploadBox width={5.2 / 10} mx="auto">
                    <Flex width={1} alignItems="center" justifyContent="center">
                      <Box pr="10px">
                        <FileUpload
                          name="inviteUpload"
                          id="inviteUpload"
                          onClick={() => {}}
                          label="Upload CSV File"
                          onChange={(file) =>
                            setFieldValue('inviteUpload', file)
                          }
                          accept=".csv"
                        />
                      </Box>
                      <GroupBox pl="10px">
                        <Button
                          type="button"
                          margin="0px"
                          disabled={
                            !isEmpty(values.emailList) ||
                            !isEmpty(values.inviteUpload)
                          }
                          onClick={onHubspotClick}
                        >
                          Import from Hubspot
                        </Button>
                      </GroupBox>
                    </Flex>
                    {fileName && (
                      <Flex width={1} alignItems="center" pl="20px">
                        <Box>{fileName}</Box>
                        <Box>
                          <DripIconButton
                            icon="cross"
                            onClick={() => {
                              onFileRemoveClick(fileName);
                              inviteSetState({isError: false, error: ''});
                            }}
                          />
                        </Box>
                      </Flex>
                    )}
                    <DownloadSampleDiv
                      download
                      href="/assets/sample/admin_sample.csv"
                    >
                      <i className="icon dripicons-download"></i> Download
                      sample file
                    </DownloadSampleDiv>
                  </UploadBox>
                </UploadFlex>
                <Flex py="5px" justifyContent="center">
                  <Box>
                    <Button type="button" onClick={handleSubmit}>
                      Send Invite
                    </Button>
                  </Box>
                </Flex>
                {inviteSending && <Loader height="25px" />}
              </Flex>
            );
          }}
        </Formik>
        {inviteError && (
          <div>Please upload as per sample file</div>
        )}
        {successMessage && (
          <div>{successMessage}</div>
        )}
      </ModalBody>
    </Modal>
  );
};

export default CreateAdminModal;
