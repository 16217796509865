import { faFacebook, faLinkedin, faTwitter } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Flex } from '@rebass/grid';
import { get, isEmpty } from 'lodash';
import moment from 'moment';
import React from 'react'
import { getMediaUrl, onImageError, themesData } from '../../../utils';
import { LeadCaptureCardWrapper } from './LeadCaptureTab.styles';

const LeadCaptureCard = ({
    type,
    lead_source,
    fullname,
    email,
    mobile,
    zip,
    investable_assets,
    lead_interest,
    created_time,
    media_urls,
    referrer_fullname,
    referrer_email,
    isAssetAdmin,
    isAssetAdvisor,
    firm_name,
    userData 
}) => {
    const icons = {
        'tw': faTwitter,
        'ln': faLinkedin,
        'fb': faFacebook,
        'em': faEnvelope
    }
    return (
        <LeadCaptureCardWrapper height="280px">
            <Flex height="99px" width="100%">
                <img
                    alt="share"
                    src={!isEmpty(media_urls) && getMediaUrl(media_urls)}
                    width="100%"
                    onError={onImageError}
                    style={{
                        borderTopLeftRadius: '8px',
                        borderTopRightRadius: '8px',
                        objectFit: 'cover',
                    }}
                />
            </Flex>
            <Flex width="100%" justifyContent="space-between" padding="5px 5px 0px" flex="0 0 auto">
                <Flex flexDirection="column">
                    <Flex fontSize="12px">
                        {fullname}
                    </Flex>
                    <Flex color={themesData.charcoalGrey} fontSize="11px" title={email && email.length > 20 ? email : ''}>
                        {email && email.length > 20 ? email.substring(0, 20) + '...' : email}   
                    </Flex>
                </Flex>
                <Flex>
                    <FontAwesomeIcon color={themesData.charcoalGrey} icon={icons[lead_source]}/>    
                </Flex>       
            </Flex>
            {type === 'leadgen' && !isEmpty(firm_name) && (isAssetAdmin || get(userData, 'details.user.billing.subscription_type', '') === 'asset_manager_sponsored') && (
                <Flex width="100%" flexDirection="column">
                    <Flex fontSize="12px" padding="1px 5px 0px">
                        Firm Name
                    </Flex>
                    <Flex color={themesData.charcoalGrey} padding="1px 5px 0px" fontSize="10px">
                        {firm_name}
                    </Flex>
                </Flex>
            )}
            <Flex></Flex>
            <Flex 
                width="100%" 
                justifyContent="space-between" 
                flexDirection="column"
                padding={
                    type === 'leadgen' 
                    && (isAssetAdmin || 
                    get(userData, 'details.user.billing.subscription_type', '') === 'asset_manager_sponsored') 
                    ? "2px 5px 0px" : "5px 5px 0px"
                } 
                flex="0 0 auto"
                >   
                <Flex flexDirection="column">
                    <Flex fontSize="11px" color="#49484a" style={{'textAlign' : 'left'}}>
                        {type === 'leadgen' ? 
                        (isAssetAdmin || get(userData, 'details.user.billing.subscription_type', '') === 'asset_manager_sponsored') ?
                         'AUM' : investable_assets && investable_assets.length > 0 ? investable_assets : lead_interest 
                         : 'Referred By'}
                    </Flex>
                    <Flex color={themesData.charcoalGrey} fontSize="10px">
                        {type === 'leadgen' ? investable_assets : referrer_fullname}   
                    </Flex>
                </Flex>
                {mobile && (
                    <div style={{borderLeft: '1px solid #DADADA', height: '12px'}}></div>
                )}
                {mobile && (
                    <Flex flexDirection="column">
                        <Flex fontSize="12px">
                            Phone
                        </Flex>
                        <Flex color={themesData.charcoalGrey} fontSize="11px">
                            {mobile}   
                        </Flex>
                    </Flex>
                )}
            </Flex>
            <Flex alignItems="center" width="100%" justifyContent="space-between" padding={(type === 'leadgen' && (isAssetAdmin || get(userData, 'details.user.billing.subscription_type', '') === 'asset_manager_sponsored')) ? '1px 5px 0px 5px':'12px 5px 10px 5px'} flex="0 0 auto">
                <Flex flexDirection="column">
                    <Flex color={themesData.charcoalGrey} fontSize="10px">
                        {moment(created_time).format('MMMM Do, YYYY')}  
                    </Flex>
                </Flex>  
            </Flex>
        </LeadCaptureCardWrapper>
    )
}

export default LeadCaptureCard;
