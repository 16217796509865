import React from 'react';

import useRESTQuery from 'src/hooks/useRESTQuery';
import { ErrorDisplay } from 'src/componentsV2/ErrorDisplay';
import { EmptyState } from 'src/componentsV2';

import SourceEngagement from './SourceEngagement';
import BarGraph from '../common/BarGraph';
import Skeleton from './Skeleton';

import { ChartContainer, ChartHeader, ContentPageWrapper } from './styles';

import { transformGraphResponse } from './utils';
import { LIMIT } from './constants';
import TopicsEngagementTable from './TopicsEngagementTable';

function Content({
  startDate,
  showEnterpriseDropdownSelectedOption,
  selectedUsers,
}) {
  const isEnterprise =
    showEnterpriseDropdownSelectedOption === 'My Enterprise' ? true : false;

  const {
    data: topTopicsData,
    isLoading: topTopicsLoader,
    isError: topTopicsError,
    refetch: topTopicsDataRefetch,
    isFetching: topTopicsFetching,
  } = useRESTQuery({
    key: 'topics-content',
    dependencies: [isEnterprise, startDate, selectedUsers?.join(',')],
    endpoint: `/analytics/content/topics?ongoing=false&from_date=${startDate}&limit=${LIMIT}&is_enterprise=${isEnterprise}${
      selectedUsers ? `&user_ids=${JSON.stringify(selectedUsers)}` : ''
    }`,

    options: {
      select: (res) => transformGraphResponse(res?.result ?? []),
    },
  });

  if (topTopicsError)
    return <ErrorDisplay height="70vh" onClick={topTopicsDataRefetch()} />;

  return (
    <>
      <ContentPageWrapper>
        {topTopicsLoader || topTopicsFetching ? (
          <Skeleton />
        ) : (
          <ChartContainer>
            <ChartHeader>Top 10 Topics</ChartHeader>
            {topTopicsData?.length > 0 ? (
              <BarGraph
                data={topTopicsData}
                valueDataKey="engagement_score"
                width="100%"
                height={300}
                barSize={40}
                noInterval
                margin={{
                  top: 50,
                  bottom: 10,
                  left: 24,
                  right: 24,
                }}
              />
            ) : (
              <EmptyState
                height="300px"
                title="No Analytics Data"
                subTitle="Send out campaigns to get insights"
              />
            )}
          </ChartContainer>
        )}
        <SourceEngagement
          isEnterprise={
            showEnterpriseDropdownSelectedOption === 'My Enterprise'
          }
          startDate={startDate}
          selectedUsers={selectedUsers}
        />
        <TopicsEngagementTable
          selectedUsers={selectedUsers}
          startDate={startDate}
          isEnterprise={isEnterprise}
        />
      </ContentPageWrapper>
    </>
  );
}

export default Content;
