import React from 'react';
import { 
  StyledFileUploadWrapper, 
  UploadHeader, 
  BrowseFiles ,
  Underlined,
  WhiteButton,
  ButtonIcon
} from './FileUpload.styles';

const FileUpload = ({
  id,
  name,
  onChange,
  label,
  accept,
  asLabel,
  asButton,
  icon,
  asMioEditorButton,
  maxSizeMB,
  onError,
  title,
  border,
  color,
  background,
  borderRadius,
  fontSize,
  padding,
  fromInviteClients,
  margin,
  fromMioEditor,
  height,
  width,
  styles,
  wrapperStyles,
  fromImagePreviewTab,
  className,
  customStyle,
  customImage
}) => {
  const ButtonStyle = customStyle === 'whiteButton' ? WhiteButton : BrowseFiles;

  return (
    <StyledFileUploadWrapper 
      borderRadius={borderRadius}
      border={border} 
      background={background} 
      color={color} 
      title={title} 
      asLabel={asLabel} 
      asButton={asButton} 
      asMioEditorButton={asMioEditorButton}
      fontSize={fontSize}
      padding={padding}
      fromInviteClients={fromInviteClients}
      margin={margin}
      height={height}
      width={width}
      className={className}
      style={fromImagePreviewTab ? wrapperStyles : {}}
    >
      {fromMioEditor ? (
        <button style={{color: 'white', backgroundColor: '#6351ed', padding: '10px', border: '0px'}} onClick={onChange}>Upload Firm Logo</button>
      ) : label === 'Edit Image' ? (
          <div>
            <Underlined htmlFor="upload-file-button">
                {label}
            </Underlined>
            <input
              type="file"
              id="upload-file-button"
              accept={accept}
              hidden
              onChange={(e)=>onChange(e.target.files)}
              onError={onError}
            />
          </div>
      ) : (
        <UploadHeader>
          <ButtonStyle style={styles} htmlFor="upload-file-button">
              {customImage && <ButtonIcon src={customImage} alt='custom' />}
              {label}
          </ButtonStyle>
          <input
            type="file"
            id="upload-file-button"
            accept={accept}
            hidden
            onChange={(e)=>onChange(e.target.files)}
            onError={onError}
          />
        </UploadHeader>
      )}
    </StyledFileUploadWrapper>
  );
};

export default FileUpload;