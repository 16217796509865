import { styled } from '@mui/material';

export const Wrapper = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  alignItems: 'flex-start',
  gap: '1rem',
  background: '#EFEFFD80',
  padding: '1rem',
}));
