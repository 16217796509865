import styled from 'styled-components';
import { Flex } from '@rebass/grid';

export const TopicListWrapper = styled(Flex)`
  max-height: 150px;
  overflow-y: auto;
  .slick-slider {
    width: 100%;
    overflow: hidden;
    padding-left: 2.5%;
    padding-right: 2.5%;
    .slick-arrow {
      z-index: 10;
      &:before {
        color: #000;
      }
      &.slick-prev {
        left: -5px;
      }
      &.slick-next {
        right: -5px;
      }
    }
  }
`;
