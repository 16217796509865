import React from 'react';

function BookArticle() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" y="0.5" width="79" height="79" rx="7.5" fill="#F2F4F7" />
      <rect
        x="22.5352"
        y="20"
        width="31.9559"
        height="37.0269"
        fill="#D0D5DD"
      />
      <rect
        x="25.5078"
        y="22.842"
        width="31.9559"
        height="37.158"
        fill="#E4E7EC"
      />
      <rect
        x="28.4804"
        y="25.8146"
        width="10.4917"
        height="2.79778"
        fill="white"
      />
      <rect
        x="28.4804"
        y="30.0988"
        width="25.8794"
        height="2.79778"
        fill="white"
      />
      <rect
        x="28.4804"
        y="34.3828"
        width="25.8794"
        height="2.79778"
        fill="white"
      />
      <rect
        x="28.4804"
        y="38.6669"
        width="25.8794"
        height="2.79778"
        fill="white"
      />
      <rect x="0.5" y="0.5" width="79" height="79" rx="7.5" stroke="#E4E7EC" />
    </svg>
  );
}

export default BookArticle;
