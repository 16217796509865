import React from 'react';
import { Flex } from '@rebass/grid';
import {
  Button,
  LoaderWrapper,
  ModalBody,
  ModalCloseButton,
  ModalHeader,
  ModalV2,
} from '../../../components';
import { EmailBlockStyledInput, EmailTextArea } from './EmailBlock.styles';
import useQueryForKeyInsights from 'src/hooks/useQueryForKeyInsights';
import { ColorPallete } from 'src/utils';

const EmailBlockModal = ({
  isOpen,
  handleClose,
  loading,
  createBlockButtonClick,
  validation,
  handleBack,
  blockName,
  setBlockName,
  subject,
  setSubject,
  message,
  insightData
}) => {

  return (
    <ModalV2
      isOpen={isOpen}
      onRequestClose={handleClose}
      width="40%"
      style={{ padding: '20px' }}
      bgColor="#fff"
      padding="0px"
    >
      <ModalHeader justifyContent={'center'} padding="17px">
        <Flex
          width="100%"
          justifyContent="center"
          style={{
            color: '#101828',
            fontSize: '20px',
            display: 'flex',
            justifyContent: 'flex-start',
          }}
        >
          Email Subject
        </Flex>
        <ModalCloseButton onClick={handleClose} />
      </ModalHeader>
      <ModalBody padding="0px">
        <Flex width="100%" backgroundColor={ColorPallete.mediumPink} flexDirection="column">
          <Flex color={ColorPallete.primaryBlue} fontSize="12px" style={{ fontFamily: 'Poppins-semibold', textAlign: 'left' }} padding="10px 17px 0px">
            To foster more engagement and encourage more opens and clicks, based on your campaigns we suggest crafting subjects using 
          </Flex>
          <Flex color={ColorPallete.primaryBlue} fontSize="12px">
            <ul style={{ padding: '10px', margin: '0px 25px' }}>
              <li style={{textAlign: 'left'}}>{insightData?.subject_length} Subject length</li>
              <li style={{textAlign: 'left'}}>{insightData?.suggested_subject} subject lines</li>
            </ul>
          </Flex>
        </Flex>
        <Flex
          display="flex"
          flexDirection="column"
          justifyContent="flex-start"
          alignItems="center"
          width="100%"
          padding="17px"
        >
          <Flex display="flex" fontSize="14px" color="#101828" width="100%">
            Email Block Name
          </Flex>
          <EmailBlockStyledInput
            placeholder="Please enter Email Block Name (eg: November Newsletter)"
            spellCheck={false}
            onChange={(e) => setBlockName(e.target.value)}
            value={blockName}
          />
        </Flex>
        <Flex
          display="flex"
          flexDirection="column"
          justifyContent="flex-start"
          alignItems="center"
          width="100%"
          padding="17px"
        >
          <Flex display="flex" fontSize="14px" color="#101828" width="100%">
            Subject
          </Flex>
          <EmailTextArea
            placeholder="Please enter Subject"
            spellCheck={false}
            onChange={(e) => setSubject(e.target.value)}
            value={subject}
            maxLength="100"
          />
        </Flex>
        <hr style={{ width: '100%', border: '1px solid #e4e7ec', marginTop: '25px' }} />
        <LoaderWrapper isLoading={loading}>
          <Flex
            alignItems={'center'}
            justifyContent="space-between"
            style={{ margin: '10px 0' }}
            display="flex"
            width="100%"
            padding="17px"
          >
            <Button
              borderRadius="4px"
              onClick={() => {
                handleBack();
              }}
              disabled={validation}
              width="50%"
              padding="5px 10px"
              margin="20px 10px 0px 0px"
              height="40px"
              bgColor="#fff"
              color="#101828"
              borderColor="#e4e7ec"
            >
              Back
            </Button>
            <Button
              borderRadius="4px"
              onClick={() => {console.log("create button clicked"); createBlockButtonClick()}}
              disabled={!blockName || !subject}
              width="50%"
              padding="5px 10px"
              margin="20px 0px 0px 0px"
              height="40px"
              bgColor="#816EF8"
              color="#fff"
            >
              Save & Continue
            </Button>
          </Flex>
        </LoaderWrapper>
        {message && (
          <Flex display="flex" fontSize="12px" justifyContent="center" color="#6351EC" width="100%">
            {message}
          </Flex>
        )}

      </ModalBody>
    </ModalV2>
  );
};


export default EmailBlockModal;
