import React from 'react'

function Eye(props) {
    return (
        <svg 
            width="32" 
            height="32" 
            viewBox="0 0 32 32" 
            fill="none" 
            xmlns="http://www.w3.org/2000/svg" 
            {...props}
        >
            <path 
                d="M16 9.25C8.5 9.25 5.5 16 5.5 16C5.5 16 8.5 22.75 16 22.75C23.5 22.75 26.5 16 26.5 16C26.5 16 23.5 9.25 16 9.25Z" 
                stroke="currentColor" 
                stroke-width="2" 
                stroke-linecap="round" 
                stroke-linejoin="round" 
            />
            <path 
                d="M16 19.75C18.0711 19.75 19.75 18.0711 19.75 16C19.75 13.9289 18.0711 12.25 16 12.25C13.9289 12.25 12.25 13.9289 12.25 16C12.25 18.0711 13.9289 19.75 16 19.75Z" 
                stroke="currentColor" 
                stroke-width="2" 
                stroke-linecap="round" 
                stroke-linejoin="round" 
            />
        </svg>
    )
}

export default Eye;