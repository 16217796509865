/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useOktaAuth } from '@okta/okta-react';
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import OktaLoginWidget from './OktaLoginWidget';
import { actions as loginActions } from '../../redux/modules/login.module';
import { getRoleType, getUserRole } from '../../redux/selector';
import { ROLE_TYPES, USER_ROLE } from '../../utils';
import useQuery from '../../customHooks/useQuery';
import { get, isEqual } from 'lodash';
import { useHistory } from 'react-router-dom';
import {
  LoginFooter,
  LoginLogoStyle,
  LoginContent,
  LoginPageStyle,
  LoginSplash,
  LoginHeader,
  IconDisplay,
  IconStyle,
  IconName
} from './LoginContainer.styles';
import { ErrorContainer } from '../../components';
import loginLogo from '../../assets/logos/loginLogo.svg';
import explore from '../../assets/login/explore.svg';
import automate from '../../assets/login/automate.svg';
import nurture from '../../assets/login/nurture.svg';
import optimize from '../../assets/login/optimize.svg';
import { actions as campaignActions } from '../../redux/modules/campaign.module';
import { AUTOMATED_CAMPAIGN_ACTIONS } from '../AutomatedCampaigns/AutomatedCampaignsContainer';

const splashData = [
  {
    title: 'Explore',
    icon: explore
  },
  {
    title: 'Nurture',
    icon: nurture
  },
  {
    title: 'Automate',
    icon: automate
  },
  {
    title: 'Optimize',
    icon: optimize
  }
];

const LoginContainerV3 = ({
  loginRequest,
  userRole,
  roleType,
  loginSetState,
  isLoginError,
  userData,
  campaignSetState
}) => {
  const { oktaAuth, authState } = useOktaAuth();
  const loginQuery = useQuery();
  const advisorNotify = loginQuery.get('advisor_notification');
  const history = useHistory();
  const [tokenData, setTokenData] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (window.sessionStorage.getItem('redirect_type') === 'nurture' && authState.isAuthenticated) {
      campaignSetState({
        selectedIndex: 0,
        createMode: 'automated',
        curFlow: process.env.REACT_APP_API_ENV === 'stage' ? AUTOMATED_CAMPAIGN_ACTIONS.EVENT : AUTOMATED_CAMPAIGN_ACTIONS.NURTURE_PROSPECTS,
        subscriptionType: '',
        campaignId: '',
        createFlow: true,
        setObjectives: {},
        setTopics: {},
        contentWeeks: [],
        planCampaign: {},
        campaignArticles: [],
        scheduleTimings: {},
        selectedWeek: '',
        selectedDays: '',
        selectedArticles: [],
        publishedContent: {},
        defaultTemplates: [],
        setTemplate: '',
        savedTemplate: null,
        scheduleTypes: {},
        isEdit: false,
        isFromAddCampaign: false,
        summaries: null,
        seriesId: '',
        leadGenReferralData: null,
        fromDashboard: false,
        reviewCampaign: {},
        reviewType: '',
        acBack: false,
        selectedTimeBlocks: [],
        template_id: '',
        automatedCampaigns: {
          activeStepIndex: process.env.REACT_APP_API_ENV === 'stage' ? 2 : 0
        }
      });
      history.push('/campaignV2?type=create');
    }
  }, [])


  const handleSuccess = (tokens) => {
    console.log(tokens);
    setTokenData(tokens);
    setLoading(true);
    loginRequest({ noRefresh: true, token: tokens?.accessToken?.accessToken });
  }

  const handleError = (error) => {
    console.log(error)
  }

  const goToDashboard = () => {
    if (isEqual(userRole, USER_ROLE.admin)) {
      history.push('/admin/home');
    } else {
      history.push('/home');
    }
  };

  const goToCampaignDashboard = () => {
    history.push('/campaignV2?advisor_notification=true');
  };

  const goToCompliance = () => {
    if (isEqual(userRole, USER_ROLE.admin)) {
      history.push('/admin/compliance');
    }
  };

  useEffect(() => {
    const windowBodyClassList = document.body.classList;
    if (!windowBodyClassList.contains('login')) {
      windowBodyClassList.add('login');
    }
    return () => {
      windowBodyClassList.remove('login');
    };
  }, []);

  useEffect(() => {
    if (userRole) {
      console.log(userData)
      window.Intercom('boot', {
        app_id: 'yz57xdx1',
        email: get(userData, 'details.user.email', ''),
        user_id: get(userData, 'uid', ''),
        created_at: new Date().getTime(),
      });
      oktaAuth.handleLoginRedirect(tokenData);
      if (roleType === ROLE_TYPES.ADMIN_COMPLIANCE) {
        goToCompliance();
      } else if (advisorNotify) {
        goToCampaignDashboard();
      } else {
        goToDashboard();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userRole]);

  if (loading && !isLoginError && tokenData) {
    return (<div style={{
      position: 'fixed',
      top: '40%',
      left: '40%'
    }}>
      Redirecting to home page ...
    </div>)
  }

  return (
    <>
      {(window.sessionStorage.getItem('redirect_type') !== 'nurture') ? (
        <LoginPageStyle>
          <LoginSplash>
            <LoginHeader>Personalization Meets Automation</LoginHeader>
            <IconDisplay>
              {
                splashData.map((column, index) => {
                  return (
                    <div style={{ display: 'table' }} key={index}>
                      <IconStyle src={column.icon} alt='icon' />
                      <IconName>{column.title}</IconName>
                    </div>
                  )
                })
              }
            </IconDisplay>
          </LoginSplash>
          <LoginContent>
            <LoginLogoStyle
              src="/assets/images/clout-logos/tifin-marketing-logo-dark.png"
              alt="logo"
            />
            {isLoginError && (
              <ErrorContainer align="center">
                Invalid Email Id or Password
              </ErrorContainer>
            )}
            <OktaLoginWidget
              onSuccess={handleSuccess}
              onError={handleError}
            />
            <LoginFooter>
              <a style={{ textDecoration: 'none', cursor: 'pointer', color: '#816EF8' }} href="/terms.html" target="_blank">
                Terms
              </a>
              <div style={{ height: 16, width: 1, margin: '1px 6px 0', borderLeft: '1px solid #816EF8' }} />
              <a style={{ textDecoration: 'none', cursor: 'pointer', color: '#816EF8' }} href="/privacypolicy.html" target="_blank">
                Privacy
              </a>
            </LoginFooter>
          </LoginContent>
        </LoginPageStyle>) : (
        <div></div>
      )}

    </>


  )
}

const mapStateToProps = (state) => ({
  userRole: getUserRole(state),
  isLoginError: state.user.isError,
  roleType: getRoleType(state),
  userData: state?.user?.data
});

const mapDispatchToProps = {
  loginRequest: loginActions.api.login.request,
  loginSetState: loginActions.api.login.setstate,
  campaignSetState: campaignActions.api.campaign.setstate,
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginContainerV3);