import React from 'react';

import { Flex, Box } from '@rebass/grid';

import {
  CreateContainer,
  TitleSpan,
  CardText,
  CardTitle,
  CardSubtitle,
  TopicsParent,
} from './Survey.styles';
import { Button } from '..';

const InstructionsPage = ({ nextHandler }) => {
  return (
    <Flex justifyContent="center" alignItems="center">
      <CreateContainer height="600px">
        <TopicsParent height="430px" width="950px">
          <Flex
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            flexWrap="wrap"
            width="775px"
            // margin="20px"
          >
            <TitleSpan marginTop="40px">What You'll Be Doing</TitleSpan>
            <CardSubtitle fontSize="14px">
              Spend the next 20-30 minutes browsing through articles on
              financial and investment related topics, and click to read any
              that seem interesting to you. After reading an article, if you
              found it particularly interesting, helpful, educational, or you
              simply enjoyed reading it, then click the star icon “Yes, I liked
              it!” that will be at the bottom of the article page. If not, you
              can close that article and move on to another one.
            </CardSubtitle>
            <CardSubtitle fontSize="14px">
              Any time after 20 minutes have passed, you can click the “I’m
              Done!” at the bottom of the page to indicate you are finished, and
              complete the task.
            </CardSubtitle>
            <Flex style={{ marginTop: '60px' }}>
              <Button
                borderRadius="5px"
                onClick={nextHandler}
                padding="7px 85px"
                testId="instruction_next"
                // width="180px"
                // height="40px"
              >
                Begin
              </Button>
            </Flex>
          </Flex>
        </TopicsParent>
      </CreateContainer>
    </Flex>
  );
};

export default InstructionsPage;
