import styled from 'styled-components';
import { Flex } from '@rebass/grid';
import { Modal } from '../../components/';

export const ThemeWrapper = styled(Flex)`
  font-family: 'Poppins';
  font-size: 12px;
  flex: 1 1 auto;
  width: 100%;
  margin-bottom: 10px !important;
  a {
    text-decoration: none;
  }
`;

export const ThemeContainer = styled.div`
  width: 83%;
  height: 100%;
  margin: auto;
  background: #f5f5f5;
  margin-top: 30px;
  margin-bottom: 10px;
`;

export const ActiveTheme = styled.div``;

export const TopicsHeader = styled.h4`
  background-color: #e5e5e5 !important;
  color: #6351ed !important;
  font-size: 16px;
  height: auto;
  padding: 6px;
  text-align: center;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  margin: 0;
  font-weight: 500;
`;

export const SelectThemes = styled.div`
background: linear-gradient(to bottom, rgba(252,252,252,1) 0%,rgba(247,247,247,1) 50%,rgba(247,247,247,1) 50%,rgba(227,227,227,1) 100%);
border: 1px solid #e1e1e1 !important;
    border-top: 0 !important;
    margin-top: 0px;
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
    padding: 10px 10px 20px;
}
`;
export const SearchCategory = styled.div`
  margin-bottom: 0;
  max-height: 170px;
  overflow-y: auto;
  overflow-x: hidden;
  ::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-track {
    border-radius: 10px;
  }
`;

export const AddedChannels = styled.ul`
  clear: none;
  display: inline-block;
  width: 100%;
  text-align: center;
  list-style-type: none;
`;

export const SMP = styled.h4`
  font-size: 16px;
  color: #6351ed;
  margin-top: 10px;
  margin-bottom: 10px;
  font-weight: 500;
  line-height: 1.1;
`;

export const SearchResultsContainer = styled.div``;

export const ChannelsPage = styled.div`
  clear: both;
  margin-bottom: 15px;
`;
export const SearchResults = styled.ul`
  ul {
    padding-bottom: 15px;
  }
  clear: both;
  display: flex;
  padding: 0;
  margin: 0;
`;

export const TileCover = styled.div`
  border: 1px solid #e5e5e5;
  display: none;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  background: #fff;
  opacity: 0.8;
`;

export const ListItem = styled.li`
  width: 120px;
  margin-right: 5px;
  display: inline-block;
  list-style-type: none;
  float: left;
  position: relative;
  &:hover {
    cursor: pointer;
    ${TileCover} {
      display: block;
    }
  }
`;

export const CoverHeader = styled.h6`
  margin: 3px 5px 0px 2px;
  color: #6351ed;
  font-size: 11px;
  word-break: break-word;
  text-align: left;
  display: inline-block;
  line-height: 18px;
  font-weight: 500;
`;

export const CoverList = styled.ul`
  list-style: none;
  clear: both;
  display: flex;
  padding: 0;
  margin: 0;
`;

export const CoverLink = styled.a`
  display: inline-table;
  vertical-align: middle;
  width: 100%;
  height: 49px;
  color: #337ab7;
  text-decoration: none;

  li:first-child {
    width: 45px;
    min-width: 45px;
    text-align: center;
  }
  li:last-child {
    width: 70%;
    text-align: left;
    line-height: 10px;
    padding: 0px 0px 0 0px;
  }
`;

export const CoverListItem = styled.li`
  display: table-cell;
  color: #6351ed;
  vertical-align: middle;
  margin-right: 20px;
  list-style-type: none;
`;

export const IconWrapper = styled.span`
  display: inline-block;
  background-position: -133px -30px;
  width: 17px;
  height: 17px;
  font-size: 18px;
  color: #6351ed;
  font-weight: 900;
`;

export const SearchInspirationDiv = styled.div``;

export const SearchInspiration = styled.div`
  padding: 5px 0;
  border-top: 1px solid #dfdfdf;
`;

export const SearchInspirationHeader = styled.h5`
  text-align: left;
  color: #6351ed;
  letter-spacing: 0;
  font-size: 13px;
  margin-bottom: 8px;
  margin-top: 10px;
  font-weight: 500;
  line-height: 1.1;
`;

export const ListWrapper = styled.div`
  max-height: 280px;
  overflow-y: auto;
  overflow-x: hidden;
  clear: both;
`;

export const List = styled.ul`
  clear: none;
  width: 100%;
  display: inline-block;
  text-align: center;
  padding: 0;
  margin: 0;
`;
export const Popular = styled.div`
  padding: 5px 0;
`;
export const PopularHeader = styled.h5`
  text-align: left;
  color: #6351ed;
  font-family: 'Poppins';
  letter-spacing: 0;
  font-size: 13px;
  margin-bottom: 8px;
  margin-top: 10px;
  font-weight: 500;
  line-height: 1.1;
`;
export const PopularList = styled.ul``;

export const PopularModalContent = styled.h6`
  font-size: 14px;
  font-weight: bold;
  margin: 0;
`;

export const SearchInput = styled.input`
  border: 0px;
  font-size: 12px;
  background-color: transparent;
  font-family: 'Poppins';
  margin-right: 10px;
  width: 82%;
  
  ::placeholder {
    text-align: center;
    color: #6351ed;
    font-family: 'Poppins';
  }
`;
