import React, { useState } from 'react';
import { Flex } from '@rebass/grid';
import { Button, LoaderWrapper } from '../../components';
import axios from 'axios';
import { apiDetails, objectToFormData } from '../../services/helpers'
import useQuery from '../../customHooks/useQuery';
import { isEmpty } from 'lodash';


const ComplianceApproveContainer = () => {

    const query = useQuery();
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);
    
    const [isGlobalApprove, setIsGlobalApprove] = useState('false');
    const [passcode, setPasscode] = useState('');

   

    const handleApprove = (e) => {
        e.preventDefault();
        setLoading(true);
        console.log('passcode', passcode);
        
        const payload = {
            content_id: query.get('content_id'),
            c_action: "approved",
            passcode: passcode,
            is_global_compliance: isGlobalApprove
        }
        axios.post(`${apiDetails.baseUrl}process_compliance`, objectToFormData(payload))
        .then((response) => {
            const res = response.data;
            if (res.result.success) {
                if (res.result?.message) {
                    setMessage(res.result?.message);
                } else {
                    setMessage("Campaign Approved Successfully");
                }
                setPasscode("");
                setLoading(false);
            } else {
                if (res.result?.message) {
                    setMessage(res.result?.message);
                } else {
                    setMessage("Passcode incorrect");
                }
                setLoading(false);
            }
         
          })
          .catch((error) => {
              if (error?.response?.status === 500) {
                  setMessage('Oops! Something went wrong');
              } else {
                  setMessage('Passcode incorrect');
              }
              setPasscode("");
              setLoading(false);
          });
         
    };

    const handleApprovalToggle = (is_global_compliance) => {
        console.log('APPROVE');
      if (is_global_compliance === 'true')
        setIsGlobalApprove('true');
      else
        setIsGlobalApprove('false');
    };
    
    return (
      <Flex
        justifyContent="center"
        width="100%"
        flexDirection="column"
        mt="100px"
        style={{ padding: '40px 40px', marginTop: '200px' }}
      >
        <Flex
          fontSize="20px"
          justifyContent="center"
          color="#6351ed"
          width="100%"
        >
          Compliance Campaign Approval Portal
        </Flex>
        <Flex justifyContent="center">
          <input
            name="passcode"
            type="password"
            placeholder="Passcode"
            onChange={(e) => setPasscode(e.target.value)}
            value={passcode}
            style={{
              width: '45%',
              marginTop: '20px',
              borderRadius: '5px',
              padding: '10px',
              height: '40px',
              border: '1px solid black',
            }}
          />
        </Flex>
        <Flex
          width="100%"
          display="flex"
          flexDirection="row"
          justifyContent="center"
          mt="5px"
        >
              <Button
                width="20%"
                bgColor={isGlobalApprove === 'false' ? '#6351ed' : '#FFFFFF' }
                color={isGlobalApprove === 'false' ? '#FFFFFF' : 'black'}
                //border= {isGlobalApprove === 'false' ? '1px solid #6351ed' : '1px solid black'}
                margin="20px 0px 10px 0px;"
                onClick={() => handleApprovalToggle('false')}
                //disabled={isEmpty(passcode)}
              >
                Compliance Approval
              </Button>
              <Button
                width="20%"
                bgColor={isGlobalApprove === 'false' ? '#FFFFFF' :'#6351ed' }
                color={isGlobalApprove === 'false' ? 'black' : '#FFFFFF'}
                //border= {isGlobalApprove === 'false' ? '1px solid #black' : '1px solid #6351ed' }
                margin="20px 0px 10px 0px;"
                onClick={() => handleApprovalToggle('true')}
                //disabled={isEmpty(passcode)}
              >
                Global Approval
              </Button>
        </Flex>
        <Flex width="100%" justifyContent="center" mt="5px">
          <LoaderWrapper isLoading={loading}>
            <Button
              width="45%"
              margin="10px 0px 20px 0px;"
              onClick={handleApprove}
              disabled={isEmpty(passcode)}
            >
              Approve Campaign
            </Button>
          </LoaderWrapper>
        </Flex>
        {message && (
          <div style={{ color: '#6351ed', fontSize: '12px' }}>{message}</div>
        )}
      </Flex>
    );
}

export default ComplianceApproveContainer;