import styled from 'styled-components';
import { Input } from '../../../components'

export const RedTailResponsiveImg = styled.img`
  width: 402px;
  height: 34px;
  padding-right: 10px;
`;

export const RedTailSubTitle = styled.h2`
  color: #959595;
  font-size: 13px;
  text-align: center;
  padding: 0px 35px;
`;

export const RedTailInputWrapper = styled.div`
  .validation-error {
    text-align: left;
    margin-left: 5px;
    margin-right: 5px;
    padding-bottom: 2px;
  }
`;