import { Flex } from '@rebass/grid';
import styled from 'styled-components';

export const CreateContainer = styled(Flex)`
  ${'' /* border: 1px solid #cfd9e4; */}
  border-radius: 4px;
  justify-content: ${({ justifyContent }) => justifyContent || 'center'};
  align-items: ${({ alignItems }) => alignItems || 'center'};
  width: ${({ width }) => width || '1070px'};
  padding: ${({ padding }) => padding || '10px'};
  height: ${({ height }) => height || ''};
  margin-top: ${({ marginTop }) => marginTop || '10px'};
  margin-bottom: ${({ marginBottom }) => marginBottom || '10px'};
  background-color: #f6f9fb;
  display: flex;
  flex-direction: ${({ flexDirection }) => flexDirection || 'column'};
  overflow-x: hidden;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background: #e9f6e6 !important;
  }

  &::-webkit-scrollbar-thumb {
    background: #bccdd4 !important;
  }
  ${'' /* margin-bottom: ${({ mb }) => mb || '25px'}; */}
`;

export const TopicsParent = styled(Flex)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  border-radius: 4px;
  border: 1px solid #e1e8ee;
  width: ${({ width }) => width || '1015px'};
  height: ${({ height }) => height || ''};
  padding: 20px;
  ${'' /* height: 100px; */}
  margin: 10px;
  background-color: #fff;
  box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px,
    rgba(17, 17, 26, 0.1) 0px 0px 8px;
`;

export const CreateContainerCards = styled(Flex)`
  background-image: ${({ bgImage, selected }) =>
    bgImage && !selected ? `url(${bgImage})` : ''};
  background-repeat: no-repeat;
  background-size: contain;
  border: 1px solid #c6c1f5;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
  width: 115px;
  padding: 5px;
  height: 115px;
  margin: 10px;
  background-color: ${({ selected }) => (selected ? '#6250ED' : '#f7f6fd')};
  box-shadow: ${({ selected }) =>
    selected ? '' : 'inset 0 0 0 2000px rgb(0 0 0 / 60%)'};
  display: flex;
  flex-direction: column;
  position: relative;
`;

//   box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px,
// rgba(17, 17, 26, 0.1) 0px 0px 8px;

export const CardText = styled(Flex)`
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
`;

export const TitleSpan = styled(Flex)`
  color: #033d51;
  font-size: 20px;
  margin-bottom: 15px;
  margin-top: ${({ marginTop }) => marginTop || '10px'};
  font-weight: 800;
`;

export const CardTitle = styled(Flex)`
  ${'' /* color: ${({ selected }) => (selected ? '#fff' : '#000')}; */}
  color: #fff;
  font-size: 12px;
  font-weight: 500;
  margin-top: 5px;
  ${'' /* word-break: break-word; */}
`;

export const CardSubtitle = styled(Flex)`
  color: #75899d;
  font-size: ${({ fontSize }) => fontSize || '12px'};
  font-weight: 400;
  ${'' /* margin-top: 10px; */}
  margin-bottom: 10px;
`;

export const DripFooterSection = styled.div.attrs({
  className: 'drip-footer-section',
})`
  display: flex;
  background-color: #fff;
  height: 10%;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const ArticleCard = styled(Flex)`
  display: flex;
  flex-direction: column;
  ${'' /* justify-content: center; */}
  ${'' /* align-items: center; */}
  ${'' /* flex-wrap: wrap; */}
  border:1px solid #ddd;
  background-color: #f1f4f6;
  border-radius: 5px;
  width: 220px;
  height: 250px;
  margin: 10px;
  box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px,
    rgba(17, 17, 26, 0.1) 0px 0px 8px;
`;

export const ArticleCardTopic = styled(Flex)`
  ${'' /* color: #708892; */}
  background-color:#E2E3F5;
  border-radius: 20px;
  font-size: 10px;
  font-weight: 400;
  ${'' /* margin-top: 2px; */}
  padding: 3px 15px;
  width: fit-content;
  ${'' /* text-align: left; */}
`;

export const ArticleCardTitle = styled(Flex)`
  color: #000;
  font-size: 12px;
  font-weight: 800;
  margin-top: 5px;
  text-align: left;
`;

export const ArticleCardDescription = styled(Flex)`
  color: #94a1b5;
  font-size: 10px;
  font-weight: 400;
  margin-top: 5px;
  text-align: left;
`;

export const ArticleModalFooter = styled.div`
  position: absolute;
  bottom: -35px;
  left: 0;
  right: 0;
  padding: 10px;
  background-color: #f1f4f6;
  border: 1px solid #ddd;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  box-shadow: 0 10px 15px rgb(0 0 0 / 50%);
`;
