import apiInstance from '../api';
import { lambdaEndpoints, landingPagesEndpoints, objectToFormData } from '../helpers';

export const getCustomRows = filter => {
    if(!filter || filter === 'all')
        return apiInstance().get(`${lambdaEndpoints.baseUrl}get-row-templates`);
    else 
        return apiInstance().get(`${lambdaEndpoints.baseUrl}get-row-templates?category=${filter}`)
}

export const getDefaultRows = () => {
    return apiInstance().get(`${lambdaEndpoints.baseUrl}fetch-default-rows`);
};

export const getCustomRowTemplate = ({ id }) => {
    return apiInstance().get(`${lambdaEndpoints.baseUrl}get-row-template/${id}`);
}

export const getDefaultRowTemplate = ({ rowType, layout }) => {
    if(rowType === 'article_block')
        return apiInstance().get(`${lambdaEndpoints.baseUrl}base-templates?category=${rowType}&tags=${layout}`);
    else
        return apiInstance().get(`${lambdaEndpoints.baseUrl}base-templates?category=${rowType}`);
}

export const getRowThumbnails = ({ layout, category }) => {
    return apiInstance().get(`${lambdaEndpoints.baseUrl}article-thumbnails?tag=${layout}&category=${category}`);
}

export const createCustomRow = ({ payload }) => {
    return apiInstance().post(`${lambdaEndpoints.baseUrl}create-rows`, payload);
}

export const updateCustomRow = ({ id, payload }) => {
    return apiInstance().post(`${lambdaEndpoints.baseUrl}update-rows/${id}`, payload);
}

export const deleteCustomRow = ({ id }) => {
    return apiInstance().delete(`${lambdaEndpoints.baseUrl}delete-custom-rows/${id}`);
}

export const cascadeCustomRow = ({ id, users }) => {
    return apiInstance({contentType: 'application/json'}).post(`${lambdaEndpoints.baseUrl}cascade-rows/${id}`, users);
}

export const postDefaultRow = ({ id, payload }) => {
    return apiInstance({contentType: 'application/json'}).post(`${lambdaEndpoints.baseUrl}default-custom-rows/${id}`, payload);
}

export const getLandingPages = (type) => {
    if(type === 'campaign') {
        return apiInstance().get(`${landingPagesEndpoints.baseUrl}/pages?type=campaign`);
    } 
    return apiInstance().get(`${landingPagesEndpoints.baseUrl}/pages`);
}

export const getApproveToSharePages = () => {
    return apiInstance().get(`${landingPagesEndpoints.baseUrl}/approve-to-share`);
}

export const getLandingPageById = ({ id }) => {
    return apiInstance().get(`${landingPagesEndpoints.baseUrl}/page/${id}`);
}

export const createLandingPage = ({ payload }) => {
    return apiInstance({contentType: 'application/json'}).post(`${landingPagesEndpoints.baseUrl}/page`, payload);
}

export const updateLandingPage = ({ id, payload }) => {
    return apiInstance({contentType: 'application/json'}).post(`${landingPagesEndpoints.baseUrl}/page/${id}`, payload);
}

export const deleteLandingPage = ({ id }) => {
    return apiInstance().delete(`${landingPagesEndpoints.baseUrl}/page/${id}`)
}

export const cascadeLandingPage = ({ id, payload }) => {
    return apiInstance({contentType: 'application/json'}).post(`${landingPagesEndpoints.baseUrl}/cascade-page/${id}`, payload)
}

export const complianceStatusUpdate = ({ id, payload }) => {
    return apiInstance({contentType: 'application/json'}).post(`${landingPagesEndpoints.baseUrl}/update-compliance-status/${id}`, payload);
}

export const exportLandingPageReport = ({ id, payload }) => {
    // return apiInstance().post(`http://apidev2.myclout.com/v1.3/landing_pages/export/124ohjk1h24rfdddd`)
    return apiInstance().post(`/landing_pages/export/${id}`, objectToFormData(payload))
}

export const complianceLandingPageCreate = (payload) => {
    return apiInstance().post(`landing_pages/`, objectToFormData(payload));
}

export const complianceLandingPageResubmit = ({id, payload}) => {
    return apiInstance().post(`/landing_pages/${id}`, objectToFormData(payload));
}

export const deleteComplianceLandingPages = ({ id }) => {
    return apiInstance().delete(`/landing_pages/${id}`);
}

export const downloadFormDataCSV = ({ id }) => {
    return apiInstance().get(`${landingPagesEndpoints.baseUrl}/download/csv/${id}`);
}

export const cascadeEnterpriseUsers = ({ id, payload }) => {
    return apiInstance({ contentType: 'application/json' }).post(`${landingPagesEndpoints.baseUrl}/enterprise-cascade-page/${id}`, payload);
}

export const rejectComplianceLandingPage = ({ id, payload }) => {
    return apiInstance().post(`/landing_pages/compliance/${id}`, objectToFormData(payload));
}

export const cloneLandingPage = ({ id, name }) => {
    return apiInstance({contentType: 'application/json'}).post(`${landingPagesEndpoints.baseUrl}/clone/${id}`, {
        name
    });
}

export const getLandingPageSettings = ({ pageId, campaignId }) => {
    return apiInstance().get(`${landingPagesEndpoints.baseUrl}/page-settings/${campaignId}`);
}

export const createUpdatePageSettings = (id, payload) => {
    return apiInstance({contentType: 'application/json'}).post(`${landingPagesEndpoints.baseUrl}/create-settings/${id}`, payload);
}

export const stopRegistrationLp = (id) => {
    return apiInstance({ contentType: 'application/json' }).post(`${landingPagesEndpoints.baseUrl}/turn-off-registration/${id}`);
}
