import React, { useState } from 'react';
import { Container as MUIContainer } from '@mui/material';
import Button from 'src/componentsV2/Button';
import DialogModal from 'src/componentsV2/DialogModal';
import AddContent from './AddContent';
import SearchIcon from 'src/assets/icons/Search';
import Plus from 'src/assets/icons/PlusIcon';
import Multiply from 'src/assets/icons/Multiply';
import BookmarkIcon from 'src/assets/icons/BookmarkSimple';
import {
  AddContentButton,
  Bookmark,
  Container,
  Content,
  Header,
  SearchContainer,
  SearchWrapper,
  Wrapper,
} from './styles';

function HeroSection({ channelId = '', setSearchString, setShowBookmarks }) {
  const [input, setInput] = useState('');
  const [showAddContentModal, setShowAddContentModal] = useState(false);

  return (
    <>
      <Wrapper>
        <MUIContainer>
          <Container>
            <Header>
              <p>Tailored content, just for you.</p>
              <h3>Start your content journey below.</h3>
            </Header>
            <Content>
              <SearchWrapper>
                <SearchContainer>
                  <SearchIcon className="search-icon" />
                  <input
                    value={input}
                    onChange={(e) => setInput(e?.target?.value?.toString())}
                    placeholder="Search for articles"
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        setSearchString(input);
                      }
                    }}
                  />
                  {!!input && (
                    <Multiply
                      className="clear-icon"
                      onClick={() => {
                        setInput('');
                        setSearchString('');
                      }}
                    />
                  )}
                </SearchContainer>
                <Button onClick={() => setSearchString(input)}>Search</Button>
              </SearchWrapper>
              <AddContentButton
                variant="outline"
                width="fit-content"
                onClick={() => setShowAddContentModal(true)}
              >
                <Plus />
                Add Content
              </AddContentButton>
              <Bookmark
                variant="outline"
                width="fit-content"
                onClick={() => setShowBookmarks(true)}
              >
                <BookmarkIcon />
              </Bookmark>
            </Content>
          </Container>
        </MUIContainer>
      </Wrapper>

      <DialogModal
        show={showAddContentModal}
        toggleModalVisibility={setShowAddContentModal}
        maxWidth="xs"
        title="Add Content"
        showTitle
        showCloseButton
      >
        <AddContent
          channelId={channelId}
          setShowAddContentModal={setShowAddContentModal}
        />
      </DialogModal>
    </>
  );
}

export default HeroSection;
