import styled from "styled-components";


export const CustomCheckboxLabel = styled.label.attrs({
    className: 'container-label'
})`
    display: block;
    position: relative;
    padding-left: 20px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
`

export const CustomCheckboxInput = styled.input.attrs({
    type: 'checkbox',
    className: 'checkbox-input'
})`
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

    :checked ~ .checkmark-span {
        background-color: #C95CFC;
    }

    :checked ~ .checkmark-span:after {
        display: block;
    }
`;


export const CustomCheckboxSpan = styled.span.attrs({
    className: 'checkmark-span'
})`
    position: absolute;
    top: 2px;
    left: 0;
    height: 15px;
    width: 15px;
    background-color: #eee;

    :after {
        content: "";
        position: absolute;
        display: none;
    }
`;


export const CustomCheckmarkIndication = styled.label`
/* Style the checkmark/indicator */
    .container .checkmark:after {
        left: 9px;
        top: 5px;
        width: 5px;
        height: 10px;
        border: solid white;
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
    }
`;

