import { styled } from '@mui/material';

export const Wrapper = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '0.5rem',
  padding: '1rem',
}));

export const Label = styled('h5')(() => ({
  font: '500 0.875rem Poppins, sans-serif',
  color: '#101828',
  margin: 0,
}));

export const Description = styled('p')(() => ({
  font: '400 0.75rem Poppins, sans-serif',
  color: '#475467',
  margin: 0,
}));

export const Separator = styled('div')(() => ({
  width: '100%',
  height: '1px',
  backgroundColor: 'rgb(228, 231, 236)',
}));

export const Form = styled('form')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '1rem',
}));

export const FormFields = styled('div')(() => ({
  maxHeight: '500px',
  display: 'flex',
  flexDirection: 'column',
  gap: '1rem',
  overflow: 'auto',
}));

export const FormField = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '0.25rem',
}));

export const InputField = styled('input')(() => ({
  padding: '0.625rem',
  font: '0.875rem/1rem Inter, sans-serif',
  color: '#101828',
  backgroundColor: '#fff',
  borderRadius: '0.25rem',
  boxShadow: 'inset 1px 2px 2px rgba(16, 24, 40, 0.12)',
  border: '1px solid #E4E7EC',

  '&:disabled': {
    color: '#98A2B3',
    backgroundColor: '#F2F4F7',
    border: '1px solid #E4E7EC',
    cursor: 'not-allowed',
  },
}));

export const RadioButtonGroup = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '0.5rem',
}));

export const RadioButtonWrapper = styled('div')(() => ({
  display: 'flex',
  alignItems: 'flex-start',
  gap: '0.25rem',
}));

export const RadioButton = styled('input')(() => ({
  border: '1px solid #816EF8',
  cursor: 'pointer',
}));

export const FooterActions = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '1rem',
}));
