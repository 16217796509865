import styled from 'styled-components';
import { themesData } from '../../../utils';
import { Flex } from '@rebass/grid';

export const CustomizeWrapper = styled.div.attrs({
  className: 'customize-wrapper',
})`
  display: flex;
  flex-direction: column;
  //height: 400px;

  label {
    color: ${themesData.charcoalGrey};
    font-size: 14px;
    text-align: left;
    font-weight: 600;
    padding: 10px 0px;
  }
`;

export const ImagePreviewWrapper = styled.div.attrs({
  className: 'image-preview-wrapper',
})`
  border: 1px solid ${themesData.purpleishBlue};
  background-color: #ffffff;
  height: ${({ height }) => height || '39px'};
  width: ${({ width }) => width || '80px'};
  display: flex;
  color: ${themesData.purpleishBlue};
  font-size: 10px;
  padding: 2px;
  justify-content: center;
  align-items: center;
`;

export const HeroImgError = styled.div`
  width: 50%;
  padding: 5px 0px;
  text-align: center;
`;

export const TooltipMode = styled(Flex).attrs({
  className: 'tooltip',
})`
  /* Tooltip container */
  .tooltip {
    position: relative;
    display: inline-block;
    color: #006080;
    z-index: 99999;
  }
  /* Tooltip text */
  .tooltip .tooltiptext {
    visibility: hidden;
    position: absolute;
    width: ${({ width }) => width || '305px'};
    ${
      '' /* min-width: 200px;
    max-width: 300px; */
    }
    background-color: #555;
    color: #fff;
    text-align: left;
    padding: 8px 8px;
    border-radius: 6px;
    z-index: 1;
    opacity: 0;
    transition: opacity 0.6s;
    font-size: 10px;
    font-family: 'Poppins';
  }
  /* Show the tooltip text when you mouse over the tooltip container */
  .tooltip:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
  }
  .tooltip-right {
    top: ${({ center }) => (center ? '-15px' : '-5px')};
    left: 150%;
  }
  .tooltip-right::after {
    content: '';
    position: absolute;
    top: ${({ center }) => (center ? '25px' : '15px')};
    right: 100%;
    margin-top: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent #555 transparent transparent;
  }
`;
