import React from 'react';

import { Flex } from '@rebass/grid';

import {
  CreateContainer,
  TitleSpan,
  CardSubtitle,
  TopicsParent,
} from './Survey.styles';
import { Button } from '..';

const Thankyou = ({ nextHandler, redirectUrl }) => {
  const onClickURL = () => {
    window.location.href = redirectUrl;
  };
  return (
    <Flex justifyContent="center" alignItems="center">
      <CreateContainer height="600px">
        <TopicsParent width="750px">
          <Flex
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            flexWrap="wrap"
            width="550px"
            margin="20px"
            mt="80px"
          >
            <img
              alt="Thank You"
              src="/assets/images/noun_success.svg"
              width="25px"
            />
            <TitleSpan>Thank You!</TitleSpan>
            <CardSubtitle fontSize="14px">
              Thanks For Your Time And Completing This Survey.
            </CardSubtitle>
            <Flex style={{ marginBottom: '15px', marginTop: '50px' }}>
              <Button
                borderRadius="5px"
                onClick={() => {
                  onClickURL();
                }}
                padding="7px 75px"
              >
                Exit
              </Button>
            </Flex>
          </Flex>
        </TopicsParent>
      </CreateContainer>
    </Flex>
  );
};

export default Thankyou;
