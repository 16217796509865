import React from 'react';
import {
    CardContainer,
    BodyContainer,
    IconStyle,
    ArticleImage,
    HeaderGroup,
    IconActions,
    MappedIcons,
    DarkText,
    BodyText,
    DefaultStyle,
} from './styles';
import ThemesBubble from '../ThemeBubbles';
import CustomTooltip from '../CustomTooltip';
import { colors } from '../../styles';
import { useHistory } from 'react-router-dom';
//icons
import editPen from '../../assets/icons/editPen.svg';
import editPenBlack from '../../assets/icons/editPenBlack.svg';
import viewIcon from '../../assets/icons/viewIcon.svg';
import purpleCheck from '../../assets/icons/purpleCheck.svg';
import blackCheck from '../../assets/icons/blackCheck.svg';
import download from '../../assets/icons/download.svg';
import downloadColor from '../../assets/icons/downloadColor.svg';
import duplicate from '../../assets/icons/duplicate.svg';
import deleteIcon from '../../assets/icons/deleteIcon.svg';
import colorShareIcon from '../../assets/icons/colorShareIcon.svg';
import blackWhiteShareIcon from '../../assets/icons/blackWhiteShareIcon.svg';
import { isEmpty } from 'lodash';

const complianceStyles = {
    'Compliance Pending': {color: colors.black_01, background: colors.gray_background,  border: `1px solid ${colors.gray_border}`},
    'Compliance Approved': {color: colors.green, background: colors.light_green, border: `1px solid ${colors.light_green}`},
    'Compliance Rejected': {color: colors.red, background: colors.light_red, border: `1px solid ${colors.light_red}`},
};

const TemplateCards = ({
  cardData,
  hideBorder = false,
  gridDisplay = true,
  cardType = 'email',
  setShowDeleteModal,
  showDeleteModal,
  setSelectedCard,
  shareModalAction,
  setSelectedTemplate,
  setShowShareModal,
  setShowDefaultModal,
  toggleDownloadCsvModal,
  toggleDuplicateModal,
  setName,
  isAdmin,
  tab
}) => {
  const history = useHistory();

  const rowCategory = {
    signature: 'Signature',
    article_block: 'Article Block',
    disclaimer: 'Disclaimer',
    header: 'Header',
    footer: 'Footer',
    landing_page: 'Landing Page',
    webinar_block: 'Webinar Block',
    content_block: 'Content Block',
    disclosure: 'Disclosure',
  };

  const cardActionIcons = [
    {
      title: 'Edit',
      key: 'edit',
      icon: editPen,
      action: () => {
        cardClick();
      },
    },
  ];

  if (isAdmin && cardType !== 'pages') {
    cardActionIcons.push({
      title: 'Share',
      key: 'share',
      icon: colorShareIcon,
      action: (e, row) => {
        e.stopPropagation();
        if (cardType === 'row') {
          setShowShareModal(row._id);
        } else {
          shareModalAction(e, row);
        }
      },
    });
  }

  if (
    isAdmin &&
    cardType === 'pages' &&
    ((cardData?.compliance_status &&
        cardData?.compliance_status === 'Compliance Approved') || !cardData?.compliance_status)
  ) {
    cardActionIcons.push({
      title: 'Share',
      key: 'share',
      icon: colorShareIcon,
      action: (e, row) => {
        e.stopPropagation();
        if (cardType === 'row') {
          setShowShareModal(row._id);
        } else {
          shareModalAction(e, row);
        }
      },
    });
  }

  if (cardType === 'pages' && tab === 0) {
    cardActionIcons.push({
      title: 'Download CSV',
      key: 'download',
      icon: downloadColor,
      action: (e) => {
        e.stopPropagation();
        toggleDownloadCsvModal({ status: true, id: cardData?._id });
      },
    });
  }

  const cardClick = (input) => {
    switch (cardType) {
      case 'email':
        return history.push(`/campaign/template/${cardData.id}`);
      case 'row':
        return history.push(
          `/campaign/custom-row/${cardData._id}?category=${cardData.category}&layout=${cardData.tag}`
        );
      case 'pages':
        return history.push(`/campaign/landing-pages/${cardData._id}`);
      default:
        break;
    }
  };

  const editTemplateOptions = {
    email: [
      {
        name: 'Edit',
        key: 'edit',
        icon: editPenBlack,
        action: () => cardClick(),
      },
      {
        name: 'Delete',
        borderTop: true,
        key: 'delete',
        icon: deleteIcon,
        action: (e) => {
          e.stopPropagation();
          setSelectedCard(cardData);
          setShowDeleteModal(true);
        },
      },
    ],
    row: [
      {
        name: 'Edit',
        key: 'edit',
        icon: editPenBlack,
        action: () => cardClick(),
      },
    ],
    pages: [
      {
        name: 'Edit',
        key: 'edit',
        icon: editPenBlack,
        action: () => cardClick(),
      },
    ],
  };

  if (cardType === 'pages' && tab === 0) {
    editTemplateOptions.pages.push(
      {
        name: 'View',
        key: 'view',
        icon: viewIcon,
        action: () => {
          window.open(cardData?.redirect_page_url, '_blank');
        },
      },
      {
        name: 'Download CSV',
        key: 'download',
        icon: download,
        action: (e) => {
          e.stopPropagation();
          toggleDownloadCsvModal({ status: true, id: cardData?._id });
        },
      },
      {
        name: 'Duplicate',
        key: 'duplicate',
        icon: duplicate,
        action: (e) => {
          e.stopPropagation();
          setName(`Duplicate of ${cardData?.name}`);
          toggleDuplicateModal({ status: true, pageObj: cardData });
        },
      },
      {
        name: 'Delete',
        borderTop: true,
        key: 'delete',
        icon: deleteIcon,
        action: (e) => {
          e.stopPropagation();
          setShowDeleteModal({ status: true, id: cardData?._id });
        },
      }
    );
  }

  if (isAdmin) {
    editTemplateOptions.email.push({
      name: 'Share',
      key: 'share',
      icon: blackWhiteShareIcon,
      action: (e) => {
        e.stopPropagation();
        setSelectedTemplate(cardData);
        setShowShareModal(true);
      },
    });

    editTemplateOptions.row.push({
      name: 'Share',
      key: 'share',
      icon: blackWhiteShareIcon,
      action: (e) => {
        e.stopPropagation();
        setShowShareModal(cardData._id);
      },
    });

    if (
      ((cardData?.compliance_status &&
        cardData?.compliance_status === 'Compliance Approved') || !cardData?.compliance_status)
    ) {
      editTemplateOptions.pages.push({
        name: 'Share',
        key: 'share',
        icon: blackWhiteShareIcon,
        action: (e) => shareModalAction(e, cardData),
      });
    }
  }

  if (cardType === 'row' && !cardData.is_default) {
    editTemplateOptions.row.push({
      name: 'Make Default',
      key: 'default',
      icon: blackCheck,
      action: (e) => {
        e.stopPropagation();
        setSelectedCard(cardData);
        setShowDefaultModal(true);
      },
    });

    editTemplateOptions.row.push({
      name: 'Delete',
      borderTop: true,
      key: 'delete',
      icon: deleteIcon,
      action: (e) => {
        e.stopPropagation();
        setSelectedCard(cardData);
        setShowDeleteModal(true);
      },
    });
  }

  if (cardType === 'email') {
    editTemplateOptions.email.push({
      name: 'Duplicate',
      key: 'duplicate',
      icon: duplicate,
      action: (e) => {
        e.stopPropagation();
        setSelectedTemplate(cardData);
        setName(`Duplicate of ${cardData?.name}`);
        toggleDuplicateModal(true);
      },
    })
  }

  return (
    <CardContainer
      border={hideBorder}
      gridDisplay={gridDisplay}
      width={'calc(33% - 20px)'}
      minWidth={'280px'}
    >
      <HeaderGroup>
        <ArticleImage src={cardData?.thumbnail_url} alt="article" />
        <div style={{ display: 'table', width: '100%' }}>
          <BodyContainer>
            {cardData?.category && (
              <div style={{ display: 'flex' }}>
                <BodyText>{rowCategory[cardData.category]}</BodyText>
                {cardData.is_default && (
                  <DefaultStyle>
                    <img src={purpleCheck} alt="yes" />
                    <span style={{ margin: '2px 0 0 4px' }}>Default</span>
                  </DefaultStyle>
                )}
                {cardType === 'row' && !isAdmin && !isEmpty(cardData.cascaded_to) && (
                  <DefaultStyle>
                    <img src={purpleCheck} alt="yes" />
                    <span style={{ margin: '2px 0 0 4px' }}>Cascaded</span>
                  </DefaultStyle>
                )}
              </div>
            )}
            <DarkText>{cardData.name}</DarkText>
            <div style={{ height: 40 }}>
              {cardData?.compliance_status && (
                <ThemesBubble
                  cardData={{
                    title: cardData.compliance_status,
                    color: cardData.compliance_status
                      ? complianceStyles[cardData.compliance_status].color
                      : complianceStyles['pending'].color,
                    background: cardData.compliance_status
                      ? complianceStyles[cardData.compliance_status].background
                      : complianceStyles['pending'].background,
                    border: cardData.compliance_status
                      ? complianceStyles[cardData.compliance_status].border
                      : complianceStyles['pending'].border,
                  }}
                />
              )}
            </div>
            <IconActions>
              <MappedIcons>
                {cardActionIcons.map((icon, index) => {
                  return (
                    <IconStyle
                      key={index}
                      src={icon.icon}
                      alt={icon.key}
                      onClick={(e) => icon.action(e, cardData)}
                    />
                  );
                })}
              </MappedIcons>
              <CustomTooltip
                color={'black'}
                inputRows={editTemplateOptions[cardType]}
              />
            </IconActions>
          </BodyContainer>
        </div>
      </HeaderGroup>
    </CardContainer>
  );
};

export default TemplateCards;