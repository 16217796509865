import styled from 'styled-components';

export const EmailBlockStyledInput = styled.input.attrs({
    className: 'email-input',
  })`
    border: 1px solid #E4E7EC;
    height: 40px;
    padding: 7px;
    width: 100%;
    font-size: 14px;
    font-family: Poppins;
    color: #7b7b7b;
    box-shadow: 1px 2px 2px 0px #1018281F inset;
  
    ::placeholder {
      text-align: left;
      color: #667085;
      font-size: 14px;
    }
  `;

  export const EmailTextArea = styled.textarea`
  border: 1px solid #E4E7EC;
  height: 200px;
  padding: 10px;
  text-align: left;
  color: #7b7b7b;
  width: 100%;
  resize: none;
  overflow-y: auto;
  overflow-x: hidden;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  box-shadow: 1px 2px 2px 0px #1018281F inset;
  &::placeholder {
    text-align: left;
      color: #667085;
      font-size: 14px;
  }
`;
  