import React, { useState } from 'react';
import { Flex } from '@rebass/grid';
import { isEmpty } from 'lodash';
import { ColorPallete } from 'src/utils';
import { Button } from 'src/components';

const ForwardAFriendConfirmation = ({ resetForm }) => {
  const [imgLoaded, setImgLoaded] = useState(false);
  return (
    <Flex 
      backgroundColor="#fff" 
      padding="24px" 
      justifyContent="center" 
      flexDirection="column" 
      width="400px"
      style={{ 
        gap: '24px', 
        boxShadow: '0px 4px 8px 0px rgba(16, 24, 40, 0.10)',
        border: `1px solid ${ColorPallete.neutral200}`,
        borderRadius: '8px'
        }}
      >
      <Flex justifyContent="center" color={ColorPallete.neutral900} fontSize="20px" style={{ fontWeight: 500 }}>
        Your message has been sent!
      </Flex>
      <Flex justifyContent="center">
        <img
          alt=""
          src="/assets/images/Success.svg"
          onLoad={() => setImgLoaded(true)}
        />
      </Flex>
      {imgLoaded && (
        <Flex
          display="flex"
          justifyContent="center"
          color={ColorPallete.neutral600}
          fontSize="14px"
        >
          Your campaign has been forwarded successfully. If you wish to send again, please click below.
        </Flex>
      )}
      <Flex width="100%">
        <Button 
          width="100%" 
          bgColor="#fff" 
          borderColor="#CABEFF" 
          color="#4634D0" 
          borderRadius="4px" 
          styles={{
            boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.12)'
          }}
          onClick={resetForm}
        >
          Send to another recipient
        </Button>
      </Flex>
    </Flex>
  );
};

export default ForwardAFriendConfirmation;
