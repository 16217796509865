import React from 'react';
import { useHistory } from 'react-router-dom';
import Button from 'src/componentsV2/Button';
import ZeroStateBackground from 'src/assets/background/home/key-insights-zero-state.svg';
import {
  ZeroStateContainer,
  ZeroStateContent,
  ZeroStateWrapper,
} from '../styles';

function ZeroState({ isAdmin = false }) {
  const history = useHistory();

  return (
    <ZeroStateWrapper>
      <ZeroStateContainer backgroundUrl={ZeroStateBackground}>
        <ZeroStateContent>
          <h5 className="title">Explore rich content & plan your campaigns!</h5>
          <Button
            onClick={() =>
              history.push(isAdmin ? '/admin/discover' : '/discover')
            }
          >
            Discover Now
          </Button>
        </ZeroStateContent>
      </ZeroStateContainer>
    </ZeroStateWrapper>
  );
}

export default ZeroState;
