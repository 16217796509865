import React, { useEffect } from 'react';
import { get } from 'lodash';
import * as d3 from 'd3';

const BubbleWordCloud = ({ data, getTopicArticle }) => {
  const ref = React.createRef();

  const renderBubble = () => {
    const diameter = 250;
    // radius will vary between 22 and 12 multiplied by Math.random

    const bubble = d3.pack(data).size([diameter, 185]).padding(1.4);

    const svg = d3
      .select(ref.current)
      .append('svg')
      .attr('width', diameter)
      .attr('height', 185)
      .attr('class', 'bubble');

    const nodes = d3.hierarchy(data).sum(function (d) {
      return d.Count;
    });

    d3.selection.prototype.moveToFront = function () {
      return this.each(function () {
        this.parentNode.appendChild(this);
      });
    };

    const node = svg
      .selectAll('.node')
      .data(bubble(nodes).descendants())
      .enter()
      .filter(function (d) {
        return !d.children;
      })
      .append('g')
      .attr('class', 'node')
      .attr('transform', function (d) {
        return 'translate(' + d.x + ',' + d.y + ')';
      });
    /* transparent circle with border */
    node
      .append('circle')
      .attr('r', function (d) {
        return d.r;
        //return d.data.Radius;
      })
      .style('cursor', 'pointer')
      .style('fill', function (d, i) {
        return d.data.fillColor;
      })
      .on('click', function (d) {
        getTopicArticle(d.data.tid);
      });

    node.append('text').each(function (d) {
      var arr = get(d, 'data.Name', '');
      if (arr.length > 14) {
        arr = arr.substring(0, 12) + '...';
      }
      var y_axis = 0
      if(arr.length <= 8){
        y_axis = 3
      }
      arr = arr.match(/.{1,8}/g);
      for (let i = 0; i < arr.length; i++) {
        if (arr[i].length > 8) {
          arr[i] = arr[i].substring(0, 8) + '...';
        }
        else 

        d3.select(this)
          .append('tspan')
          .text(arr[i])
          .style('cursor', 'pointer')
          .attr('dy', i ? '1.2em' : 0)
          .attr('x', 0)
          .attr('y', y_axis)
          .attr('text-anchor', 'middle')
          .attr('class', 'tspan' + i)
          .attr('fill', 'white')
          .attr('font-size', function (d) {
            return d.r / 4;
            //return 9;
          })
          .on('click', function (d) {
            getTopicArticle(d.data.tid);
          })
          .append('svg:title')
          .text(function (d) {
            const topicName = get(d, 'data.Name', '').split(' ');
            return topicName[0].length > 10 ? topicName : '';
          });
      }

    });

    d3.selectAll('.node')
      .on('mouseover', function (d) {
        //d3.select(this).select('text').attr("font-size", "2em")
        d3.select(this).moveToFront();
        const circle = d3.select(this).select('circle');
        circle
          .transition()
          .duration(500)
          .attr('r', (d) => d.r * 2.7);
        const text = d3.select(this).selectAll('tspan');
        text
          .transition()
          .duration(500)
          .attr('text-anchor', 'middle')
          .attr('font-size', function (d) {
            return d.r;
          });
      })
      .on('mouseout', function (d) {
        const circle = d3.select(this).select('circle');
        circle
          .transition()
          .duration(500)
          .attr('r', (d) => d.r);
        const text = d3.select(this).selectAll('tspan');
        text
          .transition()
          .duration(500)
          .attr('font-size', function (d) {
            return d.r / 3;
          });
      });
  };

  useEffect(() => {
    renderBubble();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <div style={{ marginTop: '-4px' }} ref={ref}></div>;
};

export default BubbleWordCloud;
