import * as React from "react";

function UserFocus(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 36 36" fill="none" {...props}>
      <path
        d="M25.313 5.625h3.937a1.125 1.125 0 011.125 1.125v3.938M25.313 30.375h3.937a1.125 1.125 0 001.125-1.125v-3.938M10.688 30.375H6.75a1.125 1.125 0 01-1.125-1.125v-3.938M10.688 5.625H6.75A1.125 1.125 0 005.625 6.75v3.938M18 20.25a4.5 4.5 0 100-9 4.5 4.5 0 000 9zM10.533 24.75a8.45 8.45 0 0114.934 0"
        stroke="currentColor"
        strokeWidth={2.4}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

const MemoUserFocus = React.memo(UserFocus);
export default MemoUserFocus;
