import React from 'react';
import { 
    SearchContainer, 
    SearchInput, 
    SearchIcon,
    ActivateSearch
} from './styles';
import searchIcon from './searchIcon.svg';

const AutoPostSearchBar = ({ 
    search,
    searchBox,
    searchResult, 
    setSearchBox,
    activeSearch = true, 
    setActiveSearch,
    setSearch,
    setSearchResult,
    searchHandler,
    toggleTabs
}) => {
    if(!activeSearch) {
        return (
            <ActivateSearch onClick={()=>setActiveSearch(true)}>
                <SearchIcon style={{margin: '0 0px -3px 0'}} src={searchIcon} alt='search' />
            </ActivateSearch>
        )
    }
    return(
        <SearchContainer>
            <SearchIcon src={searchIcon} alt='search' onClick={() => searchHandler()} style={{cursor: 'pointer'}}/>
            <SearchInput 
                placeholder="Search..."
                type="text"
                id="search"
                name="search"
                autoComplete='off'
                spellCheck={false}
                value={toggleTabs === 'advisor' ? searchBox : toggleTabs === 'admin' ? searchResult : toggleTabs === 'firm' ? search : null}
                onChange={(e) => toggleTabs === 'advisor' ? setSearchBox(e.target.value) : toggleTabs === 'admin' ? setSearchResult(e.target.value) : toggleTabs === 'firm' ? setSearch(e.target.value) : null}
                //onKeyDown={(e) => (e.key === 'Enter' ? searchHandler() : {})}
                // onKeyUp={(e) => {
                //     if(searchHandler){
                //         if(e.key === 'Backspace') {
                //             console.log("Backspace")
                //             searchHandler(e.target.value.substring(0, e.target.value - 1));
                //         } else {
                //             searchHandler(e.target.value);
                //         }
                //     }
                // }}
            />
        </SearchContainer>
    )
}

export default AutoPostSearchBar;