import { Flex } from '@rebass/grid';
import React from 'react';
import { TopLeadName, TopLeadWrapper, TopLeadEmail, SponsoredWrapper, OverviewBadge, ScoreWrapper } from './EngagementTab.styles';
import { isEmpty } from 'lodash';
import { DisplayScore } from '../../../components';
import { onImageError } from '../../../utils';

const TopLeadArticle = ({
  clicks,
  email,
  engagement_score,
  first_name,
  last_name,
  name,
  opens,
  sent,
  suggested_investments,
  handleRowClick
}) => {
  const trimName = (first_name + ' ' + last_name).length > 15 ? (first_name + ' ' + last_name).substring(0, 15) + '...' : (first_name + ' ' + last_name); 
  const trimEmail = email && email.length > 20 ? email.substring(0, 20) + '...' : email;

  return (
    <TopLeadWrapper padding='0px' style={{cursor: 'pointer'}} onClick={() => handleRowClick(email, {
      clicks,
      email,
      engagement_score,
      first_name,
      last_name,
      name,
      opens,
      sent,
      suggested_investments
      }, 'prospects')}>
      <Flex style={{height: '99px', width: '100%'}}>
        <img alt='share' width='213px' src={!isEmpty(suggested_investments) ? suggested_investments[0].theme_image_path : ''} onError={onImageError} style={{borderTopLeftRadius: '8px', borderTopRightRadius: '8px', objectFit: 'cover'}}/>
      </Flex>
      <Flex width='100%' flexDirection='column' padding='8px 8px 10px 8px'>
        <Flex width='100%' justifyContent='space-between' padding='0px 6px'> 
          <Flex flexDirection='column' justifyContent='flex-start'>
            <TopLeadName title={(first_name + ' ' + last_name).length > 15 ? (first_name + ' ' + last_name) : ''}>{trimName}</TopLeadName>
            <TopLeadEmail title={email && email.length > 20 ? email : ''}>{trimEmail}</TopLeadEmail>
          </Flex>
          <Flex>
            <DisplayScore score={engagement_score} />
          </Flex>
        </Flex>
        {!isEmpty(suggested_investments) ? (
          suggested_investments.map((item, index) => (
            <SponsoredWrapper mt='10px'>
              <Flex width='100%' justifyContent='flex-start' padding='2px 0px' style={{height: '13px'}}>
                <img src={item.logo_path} style={{objectFit:'contain', objectPosition:'left'}} alt='Company'/>
              </Flex>
              <Flex width='100%' fontSize='10px' padding='2px 0px' style={{ textAlign: 'left', color: '#49484a', fontWeight: '500', whiteSpace: 'nowrap', height: '15px'}}>
              <span style={{marginTop: '-10px'}}>
                  <div className='tooltip-div tooltip-disclosure' style={{paddingTop: 'unset'}}>
                      <p style={{cursor: item.name.length > 32 ? 'pointer': 'default'}}>{item.name.length > 32 ? item.name.substring(0, 32) + '...' : item.name}</p>
                        {item.name.length > 32 && 
                      <span className='tooltip-text tooltiptext-disclosure' style={{left: '186px', top: '-26px', whiteSpace: 'normal', width: 'initial', backgroundColor: '#404040', color: '#ffffff'}}>{item.name}</span>}
                    </div>
                </span>
              </Flex>
              <Flex justifyContent="space-between" style={{paddingTop: '2px'}}>
                <Flex>
                <span style={{ color: '#49484a', fontWeight: 'bold', fontSize: '10px' }}>{item.ticker}</span>
                  &nbsp;
                  {!isEmpty(item.matched_topics) && item.matched_topics.length > 0 && item.matched_topics.slice(0, 1).map((item, index) => (
                   <span key={'item_span_'+ index} style={{ color: '#49484a', fontSize: '8px', fontWeight: 500, border: '1px solid #49484a', borderRadius: '2px', marginRight: '4px', height: '11px', display: 'flex', alignItems: 'center', padding: '0px 2px', marginTop: '2px' }}>
                      
                    <div className='tooltip-div tooltip-disclosure' style={{paddingTop: 'unset'}}>
                      <p style={{cursor: item && item.length > 10 ? 'pointer': 'default'}}>{item && item.length > 10 ? item.substring(0, 10) + '...' : item}</p>
                        {item && item.length > 10 && 
                      <span className='tooltip-text tooltiptext-disclosure' style={{left: '58px', top: '2px', whiteSpace: 'nowrap', width: 'initial', backgroundColor: '#404040', color: '#ffffff'}}>{item}</span>}
                    </div>
                </span>
          
                  ))}
                </Flex>
                {item.is_sponsored && (
                  <OverviewBadge><span>Sponsored</span></OverviewBadge>
                )}
              </Flex>
            </SponsoredWrapper>
          ))
        ) : null}
      </Flex>
    </TopLeadWrapper>
  )
}

export default TopLeadArticle;

