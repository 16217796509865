import { Flex } from '@rebass/grid';
import React from 'react';
import { Button } from '..';
import { StepperV2 } from '../StepperV2/StepperV2';
import { Line } from '../StepperV2/Stepper.styles';
import { ColorPallete, Typography } from 'src/utils';
import Openai from 'src/assets/icons/Openai';
import Eye from 'src/assets/icons/eye';
import Plus from 'src/assets/icons/PlusIcon';
import { BeeInput } from './styles';
import Checkbox from 'src/componentsV2/Checkbox';
import CheckboxTrue from 'src/componentsV2/Checkbox/images/Checkbox.svg'

const BeeHeader = ({
  beeEditorInstance,
  enable,
  addArticles,
  backHandler,
  setTemplateData,
  webinarData,
  selectedIndex,
  setObjectivesTabData,
  forAutomatedCampaigns,
  oldView,
  reviewType,
  goBack,
  subjectChecked,
  setSubjectChecked,
  subject,
  setSubject
}) => {

  const handleSaveAsDraftClick = () => {
    sessionStorage.setItem('saveAsDraft', true);
    handleSaveClick();
  };
  const handleSaveClick = () => {
    beeEditorInstance.save();
  };

  const handlePreview = () => {
    beeEditorInstance.preview();
  };

  const handleArticleInsert = () => {
    console.log(beeEditorInstance.instance);
    beeEditorInstance.instance.loadRows();
  };

  const handleReviewClick = () => {
    sessionStorage.setItem('reviewClick', true);
    beeEditorInstance.save();
  }


  const stepList = [
    <span>
      Select
      <br />
      Recipients
    </span>,
    <span>
      Select
      <br />
      Template
    </span>,
    <span>
      Customize
      <br />
      Email
    </span>,
    <span>
      Preview &
      <br />
      Send/Schedule
    </span>,
  ];

  const stepListATS = reviewType === 'start' ? [
    <span>
      Select
      <br />
      Recipients
    </span>,
    <span>
      Preview &
      <br />
      Send/Schedule
    </span>,
  ] : [
    <span>
      Select
      <br />
      Recipients
    </span>,
    <span>
      Personalize
      <br />
      Email
    </span>,
    <span>
      Preview &
      <br />
      Send/Schedule
    </span>,
  ];


  const stepListLines = [
    {
      label: 'Select Target'
    },
    {
      label: 'Create Campaign Journey',
    },
    {
      label: 'Campaign Overview'
    },
  ];

  return (
    <div>
      {oldView ? (

        <Flex width="100%" flexDirection="column">
          <div
            style={{ height: '9vh', display: 'flex', justifyContent: 'center' }}
          >
            <Button
              id="bee-preview"
              margin="20px 10px"
              height="35px"
              onClick={backHandler}
            >
              Back
            </Button>
            <Button
              id="bee-preview"
              margin="20px 10px"
              disabled={!enable}
              height="35px"
              onClick={handlePreview}
            >
              Preview
            </Button>
            <Button
              id="bee-save"
              disabled={!enable}
              height="35px"
              onClick={handleSaveClick}
            >
              Save
            </Button>
            {addArticles && (
              <Button
                id="add-content"
                margin="20px 10px"
                disabled={!enable}
                height="35px"
                onClick={handleArticleInsert}
              >
                Add Content
              </Button>
            )}
          </div>
        </Flex>
      ) : (
        <Flex className='zommed-view' width="100%" flexDirection="column">
          <StepperV2 stepperList={stepListLines} selectedIndex={1} />
          <Flex width="80%" margin="0px auto" justifyContent="space-between">
            <Flex fontSize="20px" color="#000000" style={{ fontWeight: '500' }}>
              New Campaign
            </Flex>
            <Flex>
              <Button disabled={!enable} onClick={handleSaveAsDraftClick} bgColor="#fff" color="#000000" margin="0px" borderColor="#E4E7EC">
                Save Block as Draft
              </Button>
              <Button
                disabled={!enable}
                onClick={handleSaveClick}
                margin="0px 10px"
                bgColor="#6351ed"
                color="#fff"
                borderRadius="4px"
                styles={{ border: '1px solid #E4E7EC', boxShadow: '0px 1px 2px 0px #1018281F' }}>
                Save & Continue
              </Button>
            </Flex>
          </Flex>
          <hr style={{ width: '80%', border: '1px solid #E4E7EC', margin: "10px auto" }} />
          <Flex width="80%" margin="10px auto" style={{ position: 'relative' }} alignItems="center">
            <Flex width="2%">
              <img style={{ cursor: 'pointer' }} onClick={backHandler} src="/assets/images/Campaign/left-arrow-icon.svg" alt="" />
            </Flex>
            <Flex width="2%">
              <Line style={{ top: '2px' }} />
            </Flex>
            <Flex width="60%" color="#101828" fontSize="18px">
              Customize Email Template
            </Flex>
            <Flex>
              <Button
                disabled={!enable}
                onClick={handlePreview}
                margin="0px 10px"
                padding="5px 16px"
                height="40px"
                bgColor="#fff"
                color="#6351ed"
                borderRadius="4px"
                width="114px"
                styles={{
                  border: 'none'
                }}>
                <Flex width="100%" justifyContent="space-between" alignItems="center">
                  <Eye />
                  Preview
                </Flex>
              </Button>
              <Button
                disabled={!enable}
                onClick={handleArticleInsert}
                padding="5px 16px"
                height="40px"
                margin="0px 10px"
                bgColor="#fff"
                color="#6351ed"
                width="150px"
                borderRadius="4px"
                styles={{ border: 'none' }}>
                <Flex width="100%" justifyContent="space-between" alignItems="center">
                  <Plus width="24" height="24"/>
                  Add Content
                </Flex>
              </Button>

              <Flex>
                {process.env.REACT_APP_API_ENV !== 'x' && (
                  <Button
                    margin="0px"
                    padding="8px 16px"
                    height="40px"
                    borderRadius="4px"
                    width="160px"
                    bgColor={ColorPallete.white}
                    color={ColorPallete.primaryBlue}
                    borderColor={ColorPallete.pink}
                    disabled={!enable}
                    styles={{
                      position: 'relative'
                    }}
                    onClick={handleReviewClick}
                  >

                    <Flex width="100%" justifyContent="space-between" alignItems="center">
                      <Openai />
                      Review with AI
                    </Flex>
                    <Flex
                      style={{
                        position: 'absolute',
                        borderRadius: '12px',
                        border: '1px solid #B6CFF3',
                        bottom: '30px',
                        right: '2px'
                      }}
                      fontSize="10px"
                      backgroundColor="#ECF2FC"
                      padding="4px 8px"
                      color="#0B5CD7"
                    >
                      Beta
                    </Flex>

                  </Button>
                )}
              </Flex>
            </Flex>
          </Flex>
          <Flex
            width="80%"
            margin="10px auto"
            justifyContent="space-between"
            alignItems="center"
          >
            <Flex width="70%">
              <BeeInput placeholder='Subject Line' value={subject} onChange={(e) => setSubject(e.target.value)} />
            </Flex>
            <Flex
              width="28%"
              backgroundColor={ColorPallete.neutral100}
              padding="10px"
              height="40px"
              style={{
                borderRadius: '4px'
              }}
              fontSize={Typography.TextMedium}
              color={ColorPallete.neutral900}
            >
              <Checkbox checked={subjectChecked} onClick={() => setSubjectChecked(!subjectChecked)} custom={CheckboxTrue} />
              <Flex pl="5px">
                Use Subject as email name
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      )

      }

    </div>
  );
};

export default BeeHeader;
