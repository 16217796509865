import styled from 'styled-components';
import { Flex, Box } from '@rebass/grid';
import { ResponsiveImg, FileUpload, Button } from '../../components';

export const PortfolioWrapper = styled(Flex)`
  font-family: 'Poppins';
  font-size: 12px;
  flex: 1 1 auto;
  width: 100%;
  margin-bottom: 10px !important;
  a {
    text-decoration: none;
  }
`;

export const PortfolioContainer = styled.div`
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  width: 100%;
  height: 100%;
  margin: auto;
  background: #fff;
  margin-top:${(props) => (props.screenWidth < 768 ? '120px' : '30px')};
  margin-bottom: 10px;
`;

export const Header = styled.ul`
  display: block;
  background: #e4e4e5;
  width: 100%;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  list-style: none;
  padding-top: 0px;
  border-bottom: transparent;
  margin: 1px auto;
  padding: 7px 0;
  margin-top: 0;
`;

export const HeaderLi = styled.li`
  position: relative;
  display: block;
`;

export const HeaderAnchor = styled.a`
  border: 0;
  border-color: transparent;
  background-color: transparent;
  text-align: center;
  height: 35px;
  line-height: 28px;
  font-size: 16px;
  font-weight: 700;
  display: block;
  z-index: 1;
  padding: 3px 0px;
`;

export const BlockContainer = styled.div`

  width: 95%;
  padding: 20px 0px 0px;
  margin: -6px auto 0 !important;
  height: ${(props) => (props.screenWidth < 768 ? '650px' : '459px')};
`;
export const SelectionBlock = styled(Flex)`
display: flex;
flex-direction:${(props) => (props.screenWidth < 768 ? 'column' : 'row')};
margin: 6% 0px 25px;
`;

export const Blocks = styled.div`
 
  position: relative;
  background-color: #ececec;
  border-radius: 5px;
  padding: 70px 10px;
  height: 195px;
  display:inline-block;
  width: ${(props) => (props.screenWidth < 768 ? '100%' : '32%')};
  margin-right: 15px;
  margin-bottom: ${(props) => (props.screenWidth < 768 ? '20px' : '0px')};
  vertical-align: top;
`;

export const BlockFirstSpan = styled.span`
  width: 25%;
  display: inline-block;
  margin-right: 0;
  color: #6351ed;
  vertical-align: top;
`;

export const Image = styled.img`
  width: 50px;
  height: 50px;
  padding: 2px;
  border-radius: 5px;
  object-fit: cover;
`;

export const BlockSecondSpan = styled.span`
  width: 75%;
  display: inline-block;
  margin-right: 0;
  vertical-align: middle;
  color: #6351ed;
`;

export const FileUploadCSV = styled(FileUpload)`
  &:hover {
    color: #6351ed !important;
  }
`;
export const DottedInput = styled.input`
  border: 0;
  border-bottom: 1px solid #49484a;
  font-size: 12px;
  width: 100%;
  background: transparent;
  margin: 0px;
  color: #6351ed;
  font-family: 'Poppins';
  &::placeholder {
    color: #969198;
  }
  :focus::placeholder {
    color: transparent;
  }
`;
export const SeeExampleCSV = styled.a`
  display: inline-block;
  vertical-align: bottom;
  float: right;
  color: #6351ed;
  margin-bottom: 5px;
  font-size: 10px;
  margin-left: 8px;
  cursor: pointer;
`;

export const UploadedFile = styled.div`
  position: absolute;
  top: 100%;
  left: 0px;
`;

export const ModelMarketplaceHeader = styled.a`
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 0 !important;
  color: #6351ed;
  font-size: 15px;
  font-weight: 600;
  text-align: left;
  margin-top: 10px;
  cursor: pointer;
`;

export const SpecifyFundHeader = styled.h5`
  margin: 0;
  margin-top: 10px;
  font-size: 13px;
  font-size: 15px;
  font-weight: 600;
`;
export const SpecifyFundInput = styled.input`
  width: 100%;
  border: 0;
  background: transparent;
  border-bottom: 1px solid #49484a;
  margin: 0px 0 6px;
  font-size: 12px;
  font-family: 'Poppins';
  color: #6351ed;
  &::placeholder {
    color: #969198;
  }
`;

export const SavePortfolioContainer = styled.div`
  text-align: center;
  margin: 40px 0px 0px;
`;
export const SavePortfolioInput = styled(DottedInput)`
  text-align: center;
  color: #6351ed;
  &::placeholder {
    color: gray;
  }
  :focus::placeholder {
    color: transparent;
  }
`;

export const SaveButton = styled(Button)`
  background: ${({disabled}) => disabled ? '#afafaf' : '#6351ed'};
  color: ${({disabled}) => disabled ? '#ebebeb' : 'white'};
  border: none;
  border-radius: 0px;
  margin: 10px 20px 0;
  padding: 6px 12px;
  
`;
export const SaveWrapper = styled.div`
cursor: ${({cursor})=> cursor? 'not-allowed' :'pointer'};

`;

export const CreateNewLink = styled.a`
  color: #6351ed;
  margin: 10px 0px;
  font-size: 12px;
  display: block;
  cursor: pointer;
  text-decoration: underline !important;
`;

export const SelectedListItem = styled.h6`
  font-weight: 500;
  margin: 0;
  margin-top: 5px;
  line-height: 17px;
  display: block;
  font-size: 12px;
  text-align: left;
  max-width: 80%;
  text-overflow: ellipsis;
  max-height: 80px;
  overflow: hidden;
  white-space: nowrap;
`;

export const NextButtonDiv = styled.div`
  text-align: center;
  margin: 40px 0px 0px;
`;
export const NextButton = styled.button`
  background-color: #e4e4e5;
  color: #6351ed;
  border: 0px solid #000;
  margin: 0 20px;
  display: inline-block;
  padding: 6px 12px;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.42857143;
  text-align: center;
  border-radius: 4px;
  cursor: pointer;
`;

export const TickerContainer = styled.div``;
export const TickerDiv = styled.div`
  width: 95%;
  margin: auto;
  padding: 20px 0 0;
`;
export const TickerTitle = styled.h5`
  margin-bottom: 30px;
  margin-top: 30px;
  text-align: center;
  text-transform: uppercase;
  font-size: 14px;
  color: #6351ed;
  position: relative;
  z-index: 1;
  font-weight: 500;
  &::before {
    border-top: 1px solid #b7b7b7;
    content: '';
    margin: 0 auto;
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    z-index: -1;
  }
`;

export const TickerTitleSpan = styled.span`
  background: #fff;
  padding: 0 15px;
`;
export const TickerListDiv = styled.div`
  border: 1px solid #d4d4d4;
  border-radius: 5px;
`;
export const TickerTable = styled.table`
  border-bottom: none;
  display: block;
  background-color: #e4e4e5;
  text-align: center;
  max-height: 350px;
  margin-bottom: 0;
  width: 100%;
  margin: 0 auto;
  clear: both;
  border-collapse: separate;
  border-spacing: 0;
`;

export const TickerThead = styled.thead`
  display: block;
`;
export const TickerTr = styled.tr`
  display: flex;
  background-color: #e4e4e5;
  clear: both;
  border-top: 0px solid #d5d5d5;
  border-bottom: 0px solid #d5d5d5;
  border-spacing: 5px;
  font-size: 14px;
  td {
    padding: 8px 10px;
  }
  td:nth-child(1) {
    width: 21% !important;
    font-size: 13px !important;
    text-transform: uppercase;
    color: #6351ed;
  }
  td:nth-child(2) {
    width: 50% !important;
    font-size: 13px !important;
    text-transform: uppercase;
    color: #6351ed;
  }
  td:nth-child(3) {
    width: 29% !important;
    padding: 6px 3px 6px;
  }
`;
export const TickerTh = styled.th`
  padding: 8px 15px;
  text-align: center;
  background-color: #e4e4e5;
  color: #6351ed;
  &:nth-child(1) {
    width: 21% !important;
  }
  &:nth-child(2) {
    width: 50% !important;
  }
  &:nth-child(3) {
    width: 29% !important;
  }
`;

export const TickerTbody = styled.tbody`
  height: 195px;
  display: block;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  background-color: #fff;
  overflow-y: auto;
  tr {
    background-color: #fff;
  }
`;
export const TickerTd = styled.td`
  border-bottom: 1px solid #dfdfdf;
  svg {
    cursor: pointer;
  }
`;

export const TickerActions = styled.div`
  margin-top: 30px;
`;

export const PortfolioCreatedMsg = styled.div`
  font-size: 12px;
`;
