import React from 'react';
import MemoFacebook from 'src/assets/icons/social-media/Facebook';
import MemoLinkedIn from 'src/assets/icons/social-media/LinkedIn';
import MemoMail from 'src/assets/icons/social-media/Mail';
import MemoTwitter from 'src/assets/icons/social-media/Twitter';

const platformComponents = {
  is_facebook: MemoFacebook,
  is_linkedin: MemoLinkedIn,
  is_twitter: MemoTwitter,
  is_email: MemoMail,
};

export const renderPlatforms = (platformData) => {
  return (
    platformData &&
    Object.keys(platformData).length > 0 &&
    Object.keys(platformComponents).map((key, index) => {
      if (platformData?.[key]) {
        const Component = platformComponents[key];
        return <Component key={key + index} />;
      }
      return <></>;
    })
  );
};

export const generateBreadcrumbItems = (path, campaignName) => {
  const breadCrumbItems = [];

  if (path.includes('analytics')) {
    breadCrumbItems.push({
      title: 'Analytics',
      path: '/analytics',
    });
  }
  if (path.includes('home')) {
    breadCrumbItems.push({
      title: 'Home',
      path: '/home',
    });
  }
  if (campaignName) {
    breadCrumbItems.push({
      title: campaignName ?? '',
    });
  }
  return breadCrumbItems;
};
