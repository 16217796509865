/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo, useState } from 'react';
import HeroSection from './HeroSection';
import { Container } from '@mui/material';
import withDiscoverLayout from 'src/hoc/withDiscoverLayout';
import TabList from 'src/componentsV2/Tablist';
import MyContent from './MyContent';
import NewsFeed from './NewsFeed';
import Branded from './Branded';
import Recommended from './Recommended';
import Bookmarks from './Bookmarks';
import SearchResults from './SearchResults';
import { TABS } from './constants';
import { ContentWrapper, TablistWrapper } from './styles';

function Discover({
  userData,
  channelId,
  toggleEditorModal,
  isEnterpriseLevelAdmin,
  isFirmLevelAdmin,
  firmSettings,
}) {
  const [showBookmarks, setShowBookmarks] = useState(false);
  const [searchString, setSearchString] = useState('');
  const [selectedTab, setSelectedTab] = useState('recommended');

  const userRole = useMemo(
    () => userData?.details?.user?.role ?? 'default',
    [userData?.details?.user?.role]
  );

  if (showBookmarks) return <Bookmarks setShowBookmarks={setShowBookmarks} />;
  return (
    <>
      <HeroSection
        channelId={channelId}
        setSearchString={setSearchString}
        setShowBookmarks={setShowBookmarks}
      />
      <Container sx={{ padding: '1rem' }}>
        {!!searchString ? (
          <ContentWrapper>
            <SearchResults searchString={searchString} />
          </ContentWrapper>
        ) : (
          <ContentWrapper>
            <TablistWrapper>
              <TabList
                data={TABS}
                activeTab={selectedTab}
                setActiveTab={(currentTab) => setSelectedTab(currentTab)}
                variant="outline"
                fullWidth
              />
            </TablistWrapper>

            {(() => {
              switch (selectedTab) {
                case 'my_content':
                  return (
                    <MyContent
                      isAdmin={userRole === 'admin'}
                      channelId={channelId}
                      userData={userData}
                      isFirmLevelAdmin={isFirmLevelAdmin}
                      firmSettings={firmSettings}
                      isEnterpriseLevelAdmin={isEnterpriseLevelAdmin}
                    />
                  );
                case 'news_feed':
                  return <NewsFeed />;
                case 'branded':
                  return <Branded toggleEditorModal={toggleEditorModal} />;
                default:
                  return <Recommended email={userData?.details?.user?.email} />;
              }
            })()}
          </ContentWrapper>
        )}
      </Container>
    </>
  );
}

export default withDiscoverLayout(Discover);
