import styled from 'styled-components';

export const TextInput = styled('input')`
    width: ${({ width }) => width || '300px'};
    margin: ${({ margin }) => margin || '5px 0px'};
    border: 1px solid #e3e5e4;
    padding: 10px;
    border-radius: 4px;
    font-family: Poppins !important;
`;

export const TextLabel = styled('label')`
  font-weight: 500;
  font-size: 14px;
  color: #101828;
  text-align: left;
  width: ${({ width }) => width || '49%'};
`;
