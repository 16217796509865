import React from 'react'
import withContentLayout from '../../hoc/withContentLayout';
import UnsubscribeContainer from '../../containers/UnsubscribeContainer/UnsubscribeContainer';

const Unsubscribe = () => {
  return (
    <UnsubscribeContainer />
  )
}

export default withContentLayout(Unsubscribe);