import React, { useState, useEffect } from 'react';
import { isEmpty, get, map } from 'lodash';
import { Flex, Box } from '@rebass/grid';

import SelectThemes from './SelectThemes';
import ContentOverview from './ContentOverview';
import { Button, ListItemTile, LoaderWrapper } from '../../../components';
import { getMediaUrl, themesData } from '../../../utils';
import InternalUse from './InternalUse';
const imgUrl = 'https://s.marketwatch.com/public/resources/images/MW-ID053_gold_b_ZG_20200325131812.jpg';

const styles = {
  Button: {
    color: themesData.white,
    background: themesData.purpleishBlue,
    borderRadius: '0px',
    fontSize: '11px',
    fontFamily: 'Poppins',
    border: 'none'
  },
  ButtonTwo: {
    color: '#1B0983',
    background: '#ececec',
  }
}

const UploadSuccess = ({
  themesList,
  fetchedThemes,
  onExitClick,
  onUpdateTheme,
  isUpdating,
  isFromRssFeed,
  contentCreateError,
  hasTopicSelected,
  articleData,
  handleContentOverviewClick,
  showContent,
  setShowContent,
  pdfImage,
  type,
  showInternalUse,
  userData,
  isAdmin,
  showContentOverview,
  setShowContentOverview,
  isFromImageOnly,
  imageUploaded,
  resetImageUpload,
  userSummary,
  setUserSummary
}) => {
  const [showSelectThemes, setShowSelectThemes] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  
  console.log('USER SUMMARY - - - -', userSummary);
  
  useEffect(() => {
    localStorage.setItem('preSelectedTopicsView', true);
    if (isFromRssFeed) {
      setSuccessMessage('✓ HTML Link Successfully Submitted');
    } 
    else if(isFromImageOnly) {
      setSuccessMessage('✓ Image Successfully Uploaded');
      setShowSelectThemes(true);
    }
    else {
      setSuccessMessage('✓ PDF uploaded successfully');
    }
    window.setTimeout(() => {
      setSuccessMessage('');
    }, 5000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const onAddClick = () => {
    setShowSelectThemes(true);
  };

  const onNextClick = () => {
    const themes = map(fetchedThemes, 'id');
    onUpdateTheme({add: themes, remove: []}, 'next');
  };

  const emptyText = isFromRssFeed ? 'HTML Link' : isFromImageOnly ? 'Image' : 'PDF';
  const withThemesText = isFromRssFeed
    ? 'Submit Topics:'
    : 'Uploaded PDF belongs to following topics:';

  if(!!document.getElementById('Img1')){
    var c = document.createElement('canvas');
    var img = document.getElementById('Img1');
    c.height = img.naturalHeight;
    c.width = img.naturalWidth;
    var ctx = c.getContext('2d');
    
    ctx.drawImage(img, 0, 0, c.width, c.height);
    if(!!c){
      var base64String = c.toDataURL();
    }
  }

  
  return (
    <>
      {(!showSelectThemes && !showContentOverview && !showInternalUse && !isFromImageOnly) && (
        <Flex width={1} flexDirection="column">
          <Box>{successMessage}</Box>
          {isEmpty(fetchedThemes) && (
            <Box>
              No matching topics found in your {emptyText}
              <br />
              Would you like to add some topics?
            </Box>
          )}
          {!isEmpty(fetchedThemes) && <Box>{withThemesText}</Box>}
          <Box>
            <Flex width={1} flexWrap="wrap" justifyContent="center">
              {fetchedThemes.map((topic, index) => (
                <Box key={'box_'+index} width={1 / 5} height="45px" mb="5px">
                  <ListItemTile
                    id={get(topic, 'id')}
                    url={getMediaUrl(get(topic, 'media_urls'))}
                    topic={get(topic, 'name')}
                    noCover
                  />
                </Box>
              ))}
            </Flex>
          </Box>
          <Box style={isUpdating ? {display: 'flex', justifyContent: 'center'} : {}}>
            <Button styles={styles.Button} onClick={onAddClick}>Add / Remove Topic</Button>&nbsp;&nbsp;
            <LoaderWrapper isLoading={isUpdating} styles={{width: 'auto', marginTop: '31px', padding: '0 10px'}}>
              <Button styles={styles.Button} onClick={onNextClick} disabled={isEmpty(fetchedThemes)}>Next</Button>
            </LoaderWrapper>&nbsp;&nbsp;
            <Button styles={{...styles.Button, ...styles.ButtonTwo}} onClick={onExitClick}>Exit</Button>
          </Box>
        </Flex>
      )}
      {(showSelectThemes && !showContentOverview && !showInternalUse) && (
        <SelectThemes
          hasTopicSelected={hasTopicSelected}
          fetchedThemes={fetchedThemes}
          themesList={themesList}
          onUpdateTheme={onUpdateTheme}
          isUpdating={isUpdating}
          contentCreateError={contentCreateError}
          setShowContentOverview={setShowContentOverview}
          resetImageUpload={resetImageUpload}
          isFromImageOnly={isFromImageOnly}
        />
      )}
      {showContentOverview && !showInternalUse && (
        <ContentOverview
          {...articleData}
          handleContentOverviewClick={handleContentOverviewClick}
          setShowContentOverview={setShowContentOverview}
          setShowSelectThemes={setShowSelectThemes}
          setShowContent={setShowContent}
          pdfImage={pdfImage}
          isFromImageOnly={isFromImageOnly}
          imageUploaded={imageUploaded}
          type={type}
          onUpdateTheme={onUpdateTheme}
          isAdmin={isAdmin}
          userSummary={userSummary}
          setUserSummary={setUserSummary}
          fromUploadSuccess={true}
        />
      )}
      {isAdmin && showInternalUse && (
        <InternalUse 
          {...articleData}
          pdfImage={pdfImage}
          type={type}
          userData={userData}
          isAdmin={isAdmin}
          onUpdateTheme={onUpdateTheme}
        />
      )}
    </>
  );
};

export default UploadSuccess;
