import * as React from "react";

function LightbulbFilament(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 32 32" fill="none" {...props}>
      <path
        d="M11 29h10"
        stroke="currentColor"
        strokeWidth={2.133}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16 25v-7M12 14l4 4 4-4"
        stroke="currentColor"
        strokeWidth={2.667}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.838 20.875A9.937 9.937 0 016 13.063C5.975 7.638 10.338 3.125 15.763 3a10 10 0 016.412 17.863A3.024 3.024 0 0021 23.25V24a1 1 0 01-1 1h-8a1 1 0 01-1-1v-.75a3.049 3.049 0 00-1.162-2.375v0z"
        stroke="currentColor"
        strokeWidth={2.667}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

const MemoLightbulbFilament = React.memo(LightbulbFilament);
export default MemoLightbulbFilament;
