import React, { useState, useEffect } from 'react';
import {
  VerticalTabs,
  VerticalTabList,
  VerticalTabPanel,
  DripIconButton,
  AddContentIncompleteModal,
} from '../../../components';
import {
  postCreateContent,
  getHtmlText,
  postThemeFetch,
  postUpdateContent,
} from '../../../services/dashboard';

import RssFeed from './RssFeed';
import DocumetUpload from './DocumetUpload';
import ImageOnlyUpload from './ImageOnlyUpload';

import { AddContentTab } from './AddContent.styles';

const AddContent = ({
  channelId,
  themesList,
  setTabIndex,
  contentlibRequest,
  contentLibArticles,
  showMio,
  userData,
  isAdmin,
  setSelectedExploreTabIndex,
  isEnterpriseAdvisor,
  exploreArticleRequest,
  popularTopicDetails,
  setCuratedTopicType,
  setWdigetTabIndex,
}) => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [inCompleteModalOpen, setInCompleteModalOpen] = useState(false);
  const [isComplete, setIsComplete] = useState(true);
  const [topicSelected, hasTopicSelected] = useState(false);
  const [isShareContent, setIsShareContent] = useState(false);
  const [showThemes, setShowThemes] = useState(false);
  const [showShare, setShowShare] = useState(false);
  const [upcomingIndex, setUpcomingIndex] = useState(0);
  const [showImagePreview, setShowImagePreview] = useState(false);
  const [showContentOverview, setShowContentOverview] = useState(false);
  const [reset, updateReset] = useState(false);

  useEffect(() => {
    localStorage.removeItem('hideTopicMsg');
    localStorage.removeItem('shared');
    localStorage.removeItem('preSelectedTopicsView');
  }, []);

  const resetTabs = () => {
    setShowShare(false);
    setShowThemes(false);
  };

  const onTabSelect = (index, type = '') => {
    const isShared = JSON.parse(localStorage.getItem('shared'));
    setUpcomingIndex(index);
    if (isComplete || isShared) {
      setSelectedIndex(index);
      if (isShared) {
        localStorage.removeItem('shared');
        setIsComplete(true);
      }
      localStorage.removeItem('hideTopicMsg');
      resetTabs();
      setShowImagePreview(false);
      setShowContentOverview(false);
    } else {
      setInCompleteModalOpen(true);
    }
    setIsShareContent(false);
    updateReset(true);
  };

  const onYesClick = () => {
    resetTabs();
    updateReset(true);
    localStorage.removeItem('hideTopicMsg');
    setIsShareContent(false);
    setIsComplete(true);
    setShowThemes(false);
    setInCompleteModalOpen(false);
    // setSelectedIndex(selectedIndex === 0 ? 1 : 0);
    setSelectedIndex(upcomingIndex);
    setShowImagePreview(false);
    setShowContentOverview(false);
  };

  const onNoClick = () => {
    setInCompleteModalOpen(false);
  };
  console.log('IS SHARE CONTENT', isShareContent);
  return (
    <VerticalTabs
      style={{ border: 'none' }}
      onSelect={(index) => onTabSelect(index)}
      selectedIndex={selectedIndex}
    >
      <VerticalTabList>
        <AddContentTab theme="light" data-testid="link-to-website">
          <DripIconButton
            icon="feed"
            ml='0'
            styles={{
              color: selectedIndex === 0 ? 'white' : '#49484a',
              position: 'relative',
              top: '3px',
            }}
          />{' '}
          Link to website
        </AddContentTab>
        <AddContentTab
          data-testid="ac-upload-pdf"
          theme="light"
          style={{
            borderTop: selectedIndex === 2 ? '1px solid #d4d4d4' : '0px',
          }}
        >
          <DripIconButton
            icon="upload"
            ml='0'
            styles={{
              color: selectedIndex === 1 ? 'white' : '#49484a',
              position: 'relative',
              top: '-9px',
            }}
          />{' '}
          Upload Document <br></br> (pdf)
        </AddContentTab>
        <AddContentTab
          data-testid="ac-upload-image"
          theme="light"
          style={{
            borderTop: selectedIndex === 2 ? '0px' : '1px solid #d4d4d4',
          }}
        >
          <DripIconButton
            icon="upload"
            ml='0'
            styles={{
              color: selectedIndex === 2 ? 'white' : '#49484a',
              position: 'relative',
              top: '-9px',
            }}
          />{' '}
          Upload Image <br></br> (png, jpeg)
        </AddContentTab>
      </VerticalTabList>
      <VerticalTabPanel
        width="80%"
        display={isShareContent ? 'block' : 'flex'}
        style={{ overflow: 'hidden' }}
      >
        <RssFeed
          postCreateContent={postCreateContent}
          getHtmlText={getHtmlText}
          postThemeFetch={postThemeFetch}
          channelId={channelId}
          themesList={themesList}
          postUpdateContent={postUpdateContent}
          setTabIndex={setTabIndex}
          contentlibRequest={contentlibRequest}
          contentLibArticles={contentLibArticles}
          setIsComplete={setIsComplete}
          onTabSelect={onTabSelect}
          hasTopicSelected={hasTopicSelected}
          setIsShareContent={setIsShareContent}
          showThemes={showThemes}
          setShowThemes={setShowThemes}
          setShowShare={setShowShare}
          showShare={showShare}
          onYesClick={onYesClick}
          userData={userData}
          isAdmin={isAdmin}
          setSelectedExploreTabIndex={setSelectedExploreTabIndex}
          isEnterpriseAdvisor={isEnterpriseAdvisor}
          exploreArticleRequest={exploreArticleRequest}
          popularTopicDetails={popularTopicDetails}
          setCuratedTopicType={setCuratedTopicType}
          setWdigetTabIndex={setWdigetTabIndex}
          showImagePreview={showImagePreview}
          setShowImagePreview={setShowImagePreview}
          showContentOverview={showContentOverview}
          setShowContentOverview={setShowContentOverview}
          reset={reset}
          updateReset={updateReset}
        />
      </VerticalTabPanel>
      <VerticalTabPanel width="80%" display={isShareContent ? 'block' : 'flex'}>
        <DocumetUpload
          postCreateContent={postCreateContent}
          postThemeFetch={postThemeFetch}
          channelId={channelId}
          themesList={themesList}
          postUpdateContent={postUpdateContent}
          setTabIndex={setTabIndex}
          contentlibRequest={contentlibRequest}
          contentLibArticles={contentLibArticles}
          setIsComplete={setIsComplete}
          onTabSelect={onTabSelect}
          setIsShareContent={setIsShareContent}
          showThemes={showThemes}
          setShowThemes={setShowThemes}
          setShowShare={setShowShare}
          showShare={showShare}
          onYesClick={onYesClick}
          userData={userData}
          isAdmin={isAdmin}
          setSelectedExploreTabIndex={setSelectedExploreTabIndex}
          isEnterpriseAdvisor={isEnterpriseAdvisor}
          exploreArticleRequest={exploreArticleRequest}
          popularTopicDetails={popularTopicDetails}
          setCuratedTopicType={setCuratedTopicType}
          setWdigetTabIndex={setWdigetTabIndex}
          showImagePreview={showImagePreview}
          setShowImagePreview={setShowImagePreview}
          showContentOverview={showContentOverview}
          setShowContentOverview={setShowContentOverview}
          reset={reset}
          updateReset={updateReset}
        />
      </VerticalTabPanel>
      <VerticalTabPanel width="80%" display={isShareContent ? 'block' : 'flex'}>
        <ImageOnlyUpload
          showThemes={showThemes}
          setShowThemes={setShowThemes}
          showShare={showShare}
          setShowShare={setShowShare}
          setIsShareContent={setIsShareContent}
          showImagePreview={showImagePreview}
          setShowImagePreview={setShowImagePreview}
          postCreateContent={postCreateContent}
          postUpdateContent={postUpdateContent}
          channelId={channelId}
          themesList={themesList}
          showContentOverview={showContentOverview}
          setShowContentOverview={setShowContentOverview}
          isAdmin={isAdmin}
          setCuratedTopicType={setCuratedTopicType}
          setWdigetTabIndex={setWdigetTabIndex}
          contentlibRequest={contentlibRequest}
          setSelectedExploreTabIndex={setSelectedExploreTabIndex}
          setTabIndex={setTabIndex}
          onYesClick={onYesClick}
          isEnterpriseAdvisor={isEnterpriseAdvisor}
          setIsComplete={setIsComplete}
          reset={reset}
          updateReset={updateReset}
        />
      </VerticalTabPanel>
      <AddContentIncompleteModal
        topicSelected={topicSelected}
        isModalOpen={inCompleteModalOpen}
        setModalOpen={setInCompleteModalOpen}
        showThemes={showThemes}
        onYesClick={onYesClick}
        onNoClick={onNoClick}
      />
    </VerticalTabs>
  );
};

export default AddContent;
