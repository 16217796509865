import { get, values, map } from 'lodash';
import { takeLatest, all, call, put } from 'redux-saga/effects';
import { actions as channelActions } from '../modules/channel.module';
import { getChannelList } from '../../services/dashboard';

function* channelRequest() {
  try {
    const response = yield call(getChannelList);
    const data = get(response, 'result.data', {});
    yield put(
      channelActions.api.channel.response(map(values(data), (item) => item[0]))
    );
  } catch (error) {
    yield put(channelActions.api.channel.error(error));
  }
}

function* channelSaga() {
  yield all([takeLatest(channelActions.api.channel.request, channelRequest)]);
}

export default channelSaga;
