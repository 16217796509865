/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { Flex } from '@rebass/grid';
import InvitePreview from './InvitePreview';
import { isEmpty, get } from 'lodash';
import { ResponsiveImg } from '..';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { Modal, ModalHeader, ModalBody, ModalCloseButton } from '..';
import { TabList, Tabs } from '../Tabs';
import { TabConfig } from '../../containers/ConfigureContainer/screenConfigure.styles';
import { BorderWhiteBox } from '../../containers/DashboardContainer/DashboardContainer.styles';
import { InviteModalTab } from './InviteModal.styles';
import { Button, ButtonNoBorderBackground, LoaderWrapper } from '..';
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import InviteEmailAddress from './InviteEmailAddress'
import InviteSelectEmailList from './InviteSelectEmailList'
import InviteUploadCSVFile from './InviteUploadCSVFile'
import { connect } from 'react-redux';
import './InviteModals.css'
import { getContactList } from '../../services/dashboard';

const EmailInvitationType = {
  ENTEREMAILADDRESS: 'enterEmailAddress',
  SELECTEMAILLIST: 'selectEmailList',
  UPLOADACSVFILE: 'uploadACSVFile'
}

const InviteModal = ({
  isModalOpen,
  setModalOpen,
  groupsList,
  advisorGroupsRequest,
  inviteRequest,
  channelId,
  isInviteModalOpen,
  history,
  SNdata,
  userData,
  fromPositivlyInvite,
  snId,
  isEnterpriseLevelAdmin,
  isFirmLevelAdmin
}) => {
  // console.log('GROUPS LIST -= ', groupsList);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [emailInvitationSelectedType, setEmailInvitationSelectedType] = useState(EmailInvitationType.ENTEREMAILADDRESS);
  const [loading, setLoading] = useState(false);
  const [inviteData, setInviteData] = useState([]);
  const [uploadedFile, setUploadedFile] = useState('');
  const [contactList, setContactList] = useState([]);
  const [hasInviteSent, setHasInviteSent] = useState(false);
  const [membership, setMembership] = useState('member');
  const [membersList, setMembersList] = useState([]);

  const setDefaultMembersList = () => {
    if(membership === 'member')
          setMembersList([
              { email: '', first_name: '', last_name: '', valid: false },
              { email: '', first_name: '', last_name: '', valid: false },
              { email: '', first_name: '', last_name: '', valid: false }
          ]);
      else
          setMembersList([
              { email: '', firm_name: '', valid: false },
              { email: '', firm_name: '', valid: false },
              { email: '', firm_name: '', valid: false }
          ]);
  }

  useEffect(() => {
      setDefaultMembersList();
  }, []);
  
  
  useEffect(() => {
    if (isEmpty(groupsList)) {
      advisorGroupsRequest();
    }
    getContactList()
      .then(response => {
        // console.log('[CONTACT LIST] - ', response.result.data.filter(contact => !['GMAIL', 'HUBSPOT', 'OUTLOOK', 'WEALTHBOX', 'REDTAIL', 'SALESFORCE'].includes(contact.name)));
        setContactList(response.result.data.filter(contact => !['GMAIL', 'HUBSPOT', 'OUTLOOK', 'WEALTHBOX', 'REDTAIL', 'SALESFORCE'].includes(contact.name)));
      })
      .catch(err => console.log('[ERROR] - ', err));
  }, []);

  useEffect(() => {
   if(hasInviteSent) {
     setTimeout(() => {
      handleModalClose();
     }, 3 * 1000);
   }
  }, [hasInviteSent])

  const onTabSelect = (index) => {
    setSelectedIndex(index);
  }

  const renderEnterDataTabContent = () => {
    if (emailInvitationSelectedType === EmailInvitationType.ENTEREMAILADDRESS) 
      return (
        <InviteEmailAddress 
          setSelectedIndex={onTabSelect} 
          setInviteData={setInviteData} 
          membership={membership}
          isConnected={SNdata.data && SNdata.data.em && SNdata.data.em.length > 0} 
          membersList={membersList}
          setMembersList={setMembersList}
        />
      );
    else if (emailInvitationSelectedType === EmailInvitationType.SELECTEMAILLIST) 
      return (
        <InviteSelectEmailList 
          fromPositivlyInvite={fromPositivlyInvite} 
          contactList={contactList} 
          setSelectedIndex={onTabSelect} 
          setInviteData={setInviteData} 
          isConnected={SNdata.data && SNdata.data.em && SNdata.data.em.length > 0} 
        />
      );
    else 
      return (
        <InviteUploadCSVFile 
          setSelectedIndex={onTabSelect} 
          setInviteData={setInviteData} 
          isConnected={SNdata.data && SNdata.data.em && SNdata.data.em.length > 0} 
          setUploadedFile={setUploadedFile}
          fromPositivlyInvite={fromPositivlyInvite}  
        />
      );
  }

  const backHandler = () => {
    if(selectedIndex === 1)
      setDefaultMembersList();
    setSelectedIndex(selectedIndex > 0 ? selectedIndex - 1 : 0)
  }

  const handleModalClose = () => {
    setModalOpen(false); 
    setSelectedIndex(0);
    setHasInviteSent(false);
    setDefaultMembersList();
  }

  return (
    isModalOpen && (
      <Modal
        onRequestClose={handleModalClose}
        shouldCloseOnOverlayClick
        isOpen
        size="medium"
        maxHeight='90%'
        overflowY='auto' 
      >
        <ModalHeader></ModalHeader>
        <ModalCloseButton onClick={handleModalClose} />
        <ModalBody>
          <Flex width={1}>
            <Tabs onSelect={onTabSelect} selectedIndex={selectedIndex}>
              <BorderWhiteBox border="0px" type="admin-tab" padding="5px 5px">
              <div className="invitation-header" style={{marginLeft: '10px'}}>Send Email Invitation</div>
                <TabList height="35px" style={{marginBottom: !isEnterpriseLevelAdmin ? '60px' : ''}}>
                  <InviteModalTab
                    className={selectedIndex === 0 ? 'active' : ''}
                    disabled
                  >
                    <div className={selectedIndex > 0 ? 'line active' : 'line inactive'}></div>
                    <div className="number-tag" style={{ left: '9px' }}>1</div>
                    <div className="text">Select Type</div>
                  </InviteModalTab>
                  <InviteModalTab
                    className={selectedIndex === 1 ? 'active' : ''}
                    disabled
                  >
                    <div className={selectedIndex > 1 ? 'line active' : selectedIndex < 1 ? 'line' : 'line inactive'}></div>
                    <div className="number-tag">2</div>
                    <div className="text">Enter Data</div>
                  </InviteModalTab>
                  <InviteModalTab
                    className={selectedIndex === 2 ? 'active' : ''}
                    disabled
                  >
                    <div className="number-tag">3</div>
                    <div className="text">Complete</div>
                  </InviteModalTab>
                </TabList> 
                <TabConfig noBorder minHeight="240px">
                  {isEnterpriseLevelAdmin && (
                    <div width="40%">
                      <p
                        style={{
                          fontSize: '15px', color: '#6351ed',
                          textAlign: 'center',
                          paddingTop: '30px',
                          marginTop: '15px',
                        }}
                      >
                        Select the type of Membership
                      </p>
                      <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '40px', marginTop: '-15px', }}>
                        <Button
                          style={{ borderTopLeftRadius: '3px,', borderBottomLeftRadius: '3px', }}
                          bgColor={membership  === 'member' ? '#6351ed' : '#fff'}
                          color={membership === 'firm' ? '#6351ed' : '#fff' }
                          borderTopLeftRadius='3px'
                          onClick={() => setMembership('member')}
                        >
                          Member
                        </Button>
                          <Button 
                            width='86px'
                            bgColor={membership  === 'firm' ? '#6351ed' : '#fff'}
                            color={membership === 'member' ? '#6351ed' : '#fff' }
                            onClick={() => setMembership('firm')}
                          >
                            Firm
                          </Button>
                      </div>
                    </div>
                  )}
                  {/*<div className="select-type">Select Type</div>*/}
                  <Flex justifyContent='space-evenly' width='100%' alignItems='flex-start'>
                    <Flex 
                      height='150px' 
                      flexDirection='column' 
                      justifyContent='space-evenly' 
                      alignItems='center'
                    >
                      <ResponsiveImg 
                        src="/assets/images/enteremail.svg" 
                        style={{ height: '23px', width: 'auto'}} 
                      />
                      <div>Enter Email Address</div>
                      <Button
                        type="button"
                        margin="10px auto"
                        padding='3px 13px'
                        onClick={() => { 
                          setSelectedIndex(1); 
                          setEmailInvitationSelectedType(EmailInvitationType.ENTEREMAILADDRESS) 
                        }}
                      > 
                        <FontAwesomeIcon
                          color="#fff"
                          size="1x"
                          fontSize="12px"
                          icon={faArrowRight}
                        />
                      </Button>
                    </Flex>
                    <Flex
                      height='150px' 
                      flexDirection='column' 
                      justifyContent='space-evenly' 
                      alignItems='center'
                    >
                      <ResponsiveImg 
                        src="/assets/images/selectemail.svg" 
                        style={{ height: '23px', width: 'auto' }} 
                      />
                      <div>Select Email List</div>
                      <Button
                        type="button"
                        margin="10px auto"
                        padding='3px 13px'
                        onClick={() => { 
                          setSelectedIndex(1); 
                          setEmailInvitationSelectedType(EmailInvitationType.SELECTEMAILLIST) 
                        }}
                      >
                        <FontAwesomeIcon
                          color="#fff"
                          size="1x"
                          fontSize="12px"
                          icon={faArrowRight}
                        />
                      </Button>
                    </Flex>
                    <Flex
                      height='150px' 
                      flexDirection='column' 
                      justifyContent='space-evenly' 
                      alignItems='center'
                    > 
                      <ResponsiveImg 
                        src="/assets/images/uploadcsv.svg" 
                        style={{ height: '23px', width: 'auto' }} 
                      />
                      <div>Upload a CSV file</div>
                      <Button
                        type="button"
                        margin="10px auto"
                        padding='3px 13px'
                        onClick={() => { 
                          setSelectedIndex(1); 
                          setEmailInvitationSelectedType(EmailInvitationType.UPLOADACSVFILE) 
                        }}
                      >
                        <FontAwesomeIcon
                          color="#fff"
                          size="1x"
                          fontSize="12px"
                          icon={faArrowRight}
                        />
                      </Button>
                    </Flex>
                  </Flex>
                </TabConfig>
                <TabConfig noBorder minHeight="auto" paddingBottom="100px">
                  {renderEnterDataTabContent()}
                </TabConfig>
                <TabConfig noBorder minHeight="auto" paddingBottom="100px">
                  <InvitePreview 
                    hasInviteSent={hasInviteSent} 
                    setHasInviteSent={setHasInviteSent} 
                    inviteData={inviteData} 
                    groupsList={groupsList} 
                    inviteRequest={inviteRequest} 
                    channelId={channelId} 
                    isModalOpen={isInviteModalOpen}
                    fromPositivlyInvite={fromPositivlyInvite} 
                    snId={snId}
                    uploadedFile={uploadedFile}
                    userData={userData}
                    snData={SNdata}
                    membership={membership}
                  />
                </TabConfig>
              </BorderWhiteBox>
            </Tabs>
          </Flex>
          {
            selectedIndex > 0 && !hasInviteSent && <LoaderWrapper isLoading={loading}>
              <Flex width="10%">
                <ButtonNoBorderBackground
                  padding="5px 0px 0px"
                  onClick={backHandler}
                >
                  <FontAwesomeIcon icon={faAngleLeft} />
              &nbsp; Back
            </ButtonNoBorderBackground>
              </Flex>
            </LoaderWrapper>
          }
        </ModalBody>
      </Modal>
    )
  );
};

//export default withRouter(InviteModal);

const mapStateToProps = state => {
  // console.log('STATE - ', state);
  return {
      SNdata: state.socialNetwork,
      userData: get(state, 'user.data.details.user', {})
  }
}

export default connect(mapStateToProps)(InviteModal);