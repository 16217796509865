/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { parse } from 'json2csv';
import { isEmpty, uniqBy, map, differenceBy, get, filter } from 'lodash';
import { deleteMultipleContacts } from '../../../services/configure/index';
import { getContactsList } from '../../../services/configure/index';
import {
  postUpdateContactList,
  getAllContactsCSV
} from '../../../services/dashboard/index';
import { 
  TextInput, 
  TextLabel,
  AddButton,
  RadioLabel,
  RadioStyle,
  ErrorMessage
 } from './EmailList.styles';
import { saveFile } from '../../../utils/common.helper';
import { OuterTableContainer } from './EmailList.styles';
import ListTable from './ListTable';
import ContactTable from './ContactTable';
import ModalContainer from '../../../componentsV2/ModalContainer';
import { connect } from 'react-redux';

const EmailListTable = ({
  data,
  onContactDelete,
  isContactsFetching,
  setSelectedEmailListItem,
  contactsRequest,
  selectedContactGroup,
  page,
  setPage,
  paginationData,
  sortFlag,
  selectedEmailListItem,
  contactGroupsList,
  handleEmailSort,
  handleContactListChange,
  headerData,
  contactGroups,
  socialNetworkData,
  userData,
  segmentTabActive,
  emailTableData,
  setEmailTableData,
  gmContact,
  currentlyEditingContactList, 
  setCurrentlyEditingContactList,
  renameModal, 
  setRenameModal,
  modalDisplay,
  setModalDisplay,
  tableLoading, 
  setTableLoading,
  editListModal,
  selectedEmailList, 
  setSelectedEmailList,
  listLoading, 
  setListLoading,
  fetchContacts,
  addContactAction,
  contactsCreate,
  setGmContact,
  refreshData,
  emailList,
  setEmailList,
  newContacts,
  setNewContacts,
  noRecords,
  clearSearch
}) => {
  
  
  const [isAllCheckSelected, setAllCheckSelected] = useState(false);
  const [idsToDelete, setIdsToDelete] = useState([]);
  const [startIndex, setStartIndex] = useState(0);
  const [endIndex, setEndIndex] = useState(0);
  const [emailListCheckedCount, setEmailListCheckedCount] = useState(0);
  const [isEmailListDefaultExist, setIsEmailListDefaultExist] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(250);
  const [deleteListType, setDeleteListType] = useState('');
  const [isAllContactClicked, setIsAllContactClicked] = useState(false);
  const [searchString, setSearchString] = useState('');
  const [searchBoxInput, setSearchBoxInput] = useState('');
  let ct = get(currentlyEditingContactList, 'item.contact_type', 'prospect');
  const [listType, setListType] = useState(ct === 'default' ? 'prospect' : ct);
  const [modalTitle, setModalTitle] = useState('List');
  const [deletedItem, setDeletedItem] = useState(false);

  const selectedContact = emailList?.filter((list) => list.id === selectedContactGroup)
  const contactsCount = selectedContact ? selectedContact[0]?.contacts?.count : 0


  const sortAlphaNum = (a, b) => {
    var reA = /[^a-zA-Z]/g;
    var reN = /[^0-9]/g;
    var AInt = parseInt(a, 10);
    var BInt = parseInt(b, 10);

    if (isNaN(AInt) && isNaN(BInt)) {
      var aA = a && a.name.replace(reA, '');
      var bA = b && b.name.replace(reA, '');
      if (aA === bA) {
        var aN = parseInt(a.name.replace(reN, ''), 10);
        var bN = parseInt(b.name.replace(reN, ''), 10);
        return aN === bN ? 0 : aN > bN ? 1 : -1;
      } else {
        return aA > bA ? 1 : -1;
      }
    } else if (isNaN(AInt)) {
      //A is not an Int
      return 1; //to make alphanumeric sort first return -1 here
    } else if (isNaN(BInt)) {
      //B is not an Int
      return -1; //to make alphanumeric sort first return 1 here
    } else {
      return AInt > BInt ? 1 : -1;
    }
  };

  useEffect(() => {
    setIdsToDelete([]);
    setAllCheckSelected(false);
  }, [data]);

  useEffect(() => {
    if(deletedItem){
      setTableLoading(false);
      setDeletedItem(false)
    }
  }, [emailTableData]);

  useEffect(() => {
    if(deletedItem){
      setListLoading(false);
      setDeletedItem(false)
    }
  }, [emailList]);

  useEffect(() => {
    let customContactsCopy = [];

    let defaultList = filter(contactGroupsList, function (o) {
      return o.contact_type === 'default';
    });
  
    if (!isEmpty(defaultList)) {
      // defaultList.unshift({
      //   description: 'group_header',
      //   external_uuid: '-1',
      //   id: '-1',
      //   name: 'Others',
      //   type: 'default',
      //   checked: defaultList.every((item) => item.checked),
      // });

      customContactsCopy = customContactsCopy.concat(defaultList);
    }

    let prospectList = filter(contactGroupsList, function (o) {
      return o.contact_type === 'prospect';
    });

    if (!isEmpty(prospectList)) {
      if(isEmpty(searchBoxInput)) {
        prospectList.unshift({
          description: 'group_header',
          external_uuid: '-1',
          id: '-1',
          name: 'Prospects',
          type: 'prospect',
          checked: prospectList.every((item) => item.checked),
        });
      }
     
      customContactsCopy = customContactsCopy.concat(prospectList);
    }

    // if (!gmContact || gmContact.length < 1) {
    //   let gmailContact;
    //   console.log('inside gmail presence')
    //   gmailContact = {
    //     description: 'all_contacts',
    //     external_uuid: '-1',
    //     id:
    //       contactGroups &&
    //       contactGroups.find((c) => c.name === 'GMAIL') &&
    //       contactGroups.find((c) => c.name === 'GMAIL').id,
    //     name: 'Gmail All Contacts',
    //     type: 'gmail',
    //     checked: false,
    //   }
    //   if (
    //     socialNetworkData &&
    //     socialNetworkData['em'] &&
    //     socialNetworkData['em'].length > 0 &&
    //     socialNetworkData['em'].filter((s) => s.snetworkProvider === 'google')
    //       .length > 0
    //   ) {
    //     console.log('inside gmail presence', gmailContact)

    //     customContactsCopy = customContactsCopy.concat(gmailContact);
    //     console.log("custom copy--->",  customContactsCopy = customContactsCopy.concat(gmailContact))
    //     setGmContact(gmailContact)
    //     console.log("gm contact--->", gmContact);
    //   }
    // };
 

    let gmailPresenceCheck = filter(contactGroupsList, function (o) {
      return o.type === 'gmail';
    });
    if (gmailPresenceCheck.length === 0 && !noRecords) {
      gmContact.unshift({
        description: 'all_contacts',
        external_uuid: '-1',
        id:
          contactGroups &&
          contactGroups.find((c) => c.name === 'GMAIL') &&
          contactGroups.find((c) => c.name === 'GMAIL').id,
        name: 'Gmail All Contacts',
        type: 'gmail',
        checked: false,
      });
      if (
        socialNetworkData &&
        socialNetworkData['em'] &&
        socialNetworkData['em'].length > 0 &&
        socialNetworkData['em'].filter((s) => s.snetworkProvider === 'google')
          .length > 0 && isEmpty(searchBoxInput)
      ) {
        console.log('inside gmail presence', gmContact[0])
        customContactsCopy = customContactsCopy.concat(gmContact[0]);
      }
    }

    let clientList = filter(contactGroupsList, function (o) {
      return o.contact_type === 'client';
    });

    if (!isEmpty(clientList)) {
      clientList.unshift({
        description: 'group_header',
        external_uuid: '-1',
        id: '-1',
        name: 'Clients',
        type: 'client',
        checked: clientList.every((item) => item.checked),
      });

      customContactsCopy = customContactsCopy.concat(clientList);
    }

    if (
      socialNetworkData &&
      socialNetworkData['em'] &&
      socialNetworkData['em'].length > 0 &&
      socialNetworkData['em'].filter((s) => s.snetworkProvider === 'google')
        .length > 0
    ) {
    } else {
      customContactsCopy = filter(customContactsCopy, function (o) {
        return o.type !== 'gmail';
      });
    }
    customContactsCopy = customContactsCopy.sort((a, b) => sortAlphaNum(a, b));
    setEmailList(customContactsCopy.filter((item) => item));
    setEmailListCheckedCount(
      customContactsCopy.filter((item) => item.checked).length
    );
    if (selectedContactGroup) {
      setSelectedEmailList(
        customContactsCopy.find(
          (item) => item && item.id === selectedContactGroup
        )
      )
    } else {
      setSelectedEmailList(customContactsCopy[0])
    }
  }, [
    selectedContactGroup,
    socialNetworkData,
    contactGroupsList,
    contactGroups,
    noRecords
  ]);

  useEffect(()=>{
    if(!segmentTabActive){
      let defaultSelected;
      defaultSelected = contactGroupsList.find(
        (item) => item && item.is_default
      )
      if(defaultSelected) setSelectedEmailList(defaultSelected)
      else !newContacts && setSelectedEmailList(contactGroupsList[0])
    }
  },[segmentTabActive])

  useEffect(() => {
    if (emailTableData.length < 250 && page === 0) {
      setPage(0);
    }
    if (page === 0) {
      setStartIndex(contactsCount === 0 ? 0 : 1);
      setEndIndex(
        contactsCount === 0
          ? 0
          : emailTableData.length < 250
            ? contactsCount
            : 250
      );
    } else {
      setStartIndex(page * 250 + 1);
      setEndIndex(
        (page + 1) * 250 >= contactsCount ? contactsCount : (page + 1) * 250
      );
    }


    let defaultContactId =
      !isEmpty(contactGroupsList) &&
      contactGroupsList.filter((e) => e.is_default);
    let crmIds =
      contactGroups &&
      contactGroups.filter((c) =>
        [
          'GMAIL',
          'HUBSPOT',
          'OUTLOOK',
          'WEALTHBOX',
          'SALESFORCE',
          'REDTAIL',
        ].includes(c.name)
      );
    crmIds = map(crmIds, 'id');
    if (!isEmpty(defaultContactId)) {
      setIsEmailListDefaultExist(
        defaultContactId[0].id === selectedContactGroup ||
        crmIds.includes(selectedContactGroup)
      );
    } else if (!isEmpty(crmIds)) {
      setIsEmailListDefaultExist(crmIds.includes(selectedContactGroup));
    } else {
      setIsEmailListDefaultExist(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emailTableData, selectedContactGroup, page]);

  const createListFromSelections = async({ listName, contactType, isDefault = false }) => {
    const selectedOutputList = [];
    selectedEmailListItem.forEach((item) => {
      if (item.checked) {
        selectedOutputList.push({
          first_name:
            get(item, 'first.name', '').length > 0
              ? get(item, 'first.name')
              : get(item, 'name.first'),
          last_name:
            get(item, 'last.name', '').length > 0
              ? get(item, 'last.name')
              : get(item, 'name.last'),
          email: get(item, 'email'),
        });
      }
    });

    const csvFile = getEmailListCsv(selectedOutputList.reverse());

    contactsCreate({
      name: listName,
      contactType: contactType,
      isDefault: isDefault,
      media1: [csvFile],
    });
    setSelectedEmailListItem([]);
    setRenameModal(false);
  };


  const onPaginationClick = (type, currentPage, rowsPerPage) => {
    contactsRequest({
      params: {
        contactGroup: selectedContactGroup,
        paginationData: paginationData[type],
        navigateTo: type,
        onPageClick: true,
        sync: '',
        endIndex: currentPage*rowsPerPage + 1,
        sortOptions: sortFlag,
        searchTerm: searchBoxInput
      },
    });
    // setPageCount(type === 'before' ? pageCount === 0 ? 0 : pageCount - 1 : pageCount + 1);
    setPage(type === 'before' ? (currentPage === 0 ? 0 : currentPage - 1) : currentPage + 1);
  };

  const getEmailListCsv = (values) => {
    const csvData = parse(values, {
      fields: ['first_name', 'last_name', 'email'],
    });
    const csvBlob = new Blob([csvData], { type: 'text/csv' });
    const csvFile = new File([csvBlob], 'email_list.csv', {
      type: 'text/csv',
      lastModified: new Date(),
    });
    return csvFile;
  };

  const onAllCheckboxClick = () => {
    const isChecked = !isAllCheckSelected;
    let removeDuplicates;
    setAllCheckSelected(!isAllCheckSelected);
    const allIds = [];
    const transformedData = emailTableData.map((list, index) => {
      allIds.push(list.email);
      return {
        ...list,
        checked: !isAllCheckSelected,
      };
    });
    setIdsToDelete(isChecked ? allIds : []);
    setEmailTableData(transformedData);
    let filteredData = transformedData.filter(({ checked }) => checked) || [];
    if (!isChecked) {
      removeDuplicates = differenceBy(
        selectedEmailListItem,
        emailTableData,
        'email'
      );
      setSelectedEmailListItem(removeDuplicates);
    } else {
      setSelectedEmailListItem(
        uniqBy([...selectedEmailListItem, ...filteredData], 'email')
      );
    }
  };

  const onIndividualCheckboxClick = (itemIndex, email) => {
    let checkCnt = 0;
    let selectEmailList = selectedEmailListItem;
    let removeDuplicates, transformedData;

    if (selectEmailList.filter((s) => s.email === email).length > 0) {
      removeDuplicates = selectEmailList.filter((s) => s.email !== email);
      transformedData = emailTableData.map((list, index) => {
        const checked = index === itemIndex ? !list.checked : list.checked;
        if (checked) {
          setIdsToDelete([...idsToDelete, list.email]);
          checkCnt++;
        } else checkCnt--;
        return {
          ...list,
          checked,
        };
      });
      setAllCheckSelected(checkCnt === removeDuplicates.length);
    } else {
      transformedData = emailTableData.map((list, index) => {
        const checked = index === itemIndex ? !list.checked : list.checked;
        if (checked) {
          setIdsToDelete([...idsToDelete, list.email]);
          checkCnt++;
        } else checkCnt--;
        return {
          ...list,
          checked,
        };
      });
      setAllCheckSelected(checkCnt === transformedData.length);
      let filteredData = transformedData.filter(({ checked }) => checked) || [];
      removeDuplicates = uniqBy([...selectEmailList, ...filteredData], 'email');
    }
    setSelectedEmailListItem(removeDuplicates);
    setEmailTableData(transformedData);
  };

  const onEmailContractRowClick = (item) => {
    if (item.type === 'gmail' && item.description === 'all_contacts') {
      setIsAllContactClicked(true);
    }
    setCurrentlyEditingContactList({});
    setSelectedEmailList(item);
    handleContactListChange(item.id, searchBoxInput);
  };

  const handleListDelete = async(inputList) => {
    try{
      let emailList = [inputList.id];
      setListLoading(true)
      setRenameModal(false);
      await deleteMultipleContacts({ emailList });
      await fetchContacts()
      setDeletedItem(true)  
      setSearchString("");    
    } catch (err){
        console.log('Delete error:', err);
        setRenameModal(false);
      }
  };

  const searchHandler = (value = null) => {
    contactsRequest({
      params: {
        contactGroup: selectedContactGroup,
        searchString: value || searchString,
      },
    });
    if (page > 0) {
      setPage(0);
    }
  };

  const handleEmailDelete = () => {
    // if(emailTableData.length === selectedEmailListItem.length){
    if (
      !isEmpty(searchString)
        ? emailTableData.length === contactsCount
          ? contactsCount === selectedEmailListItem.length
          : null
        : emailTableData.length === selectedEmailListItem.length
    ) {
      setModalDisplay('deleteAllContacts');
      setRenameModal(true);
    } else {
      setModalDisplay('deleteContacts');
      setRenameModal(true);
    }
  }; 

  const handleEmailDeleteEvent = async(event) => {
    setTableLoading(true)
    let selectedId = [],
      deleteContactGroup = false;
      try {
        const response = await getContactsList({
          contactGroup: selectedContactGroup,
        })
        const allEmails = response.result.data.contacts[selectedContactGroup];
        if (deleteListType === 'email') {
          let deleteContactGroups = emailList.filter(
            (c) =>
              c.description !== 'group_header' &&
              c.description !== 'all_contacts'
          );
          deleteContactGroups = deleteContactGroups.filter((c) => c.checked);
          selectedId = map(deleteContactGroups, 'id');
        } else {
          selectedId = map(
            emailTableData.filter((item) => item.checked),
            'email'
          );
          deleteContactGroup = selectedId.length === allEmails.length;
          if (deleteContactGroup) {
            // selectedId = [selectedContactGroup];
            return;
          }
        }
        setSelectedEmailListItem([]);
        await onContactDelete(
          selectedId,
          deleteListType === 'email' ? true : deleteContactGroup,
          null,
          deleteListType === 'email' ? selectedId : emailTableData,
          deleteListType === 'email'
        );
        setRenameModal(false)
        setDeletedItem(true)
        setSearchString("");
      } catch(err) {
        console.log('Contact delete error:', err);
      }
  };

  const createNewListFromSelections = () => {
    setModalDisplay('create')
    setRenameModal(true)
  }

  const updateEmailListHandler = (data) => {
    let payload = {
      name: data.updatedName,
      contact_type: data.contactType,
    };
    postUpdateContactList(currentlyEditingContactList.item.id, payload)
      .then((response) => {
        setCurrentlyEditingContactList({});
        contactsRequest({ params: { contactGroup: selectedContactGroup } });
        setRenameModal(false);
      })
      .catch((err) => {
        console.log('ERROR - ', err);
        setRenameModal(false);
      });
  };

  const downloadCSVHandler = async() => {
    try{
      const response = await getAllContactsCSV(selectedEmailList.id)
      await saveFile(response, selectedEmailList.name);
    } catch(err){
      console.log('download csv err:', err)
    }
  };

  const handleSearch = (value = null) => {
    contactsRequest({
      params: {
        searchTerm: value || searchBoxInput,
        contactGroup: '',
        shouldDeleteGroup: true
      }
    })
  };

  const editNameDisplay = () => {
    switch(modalDisplay){
      case 'edit':
        if(modalTitle !== 'Edit List Name') setModalTitle('Edit List Name')
        return(
        <div style={{display: 'table', width: 400, background: 'white'}}>
          <div style={{margin: '10px 0'}}>
            <TextLabel>
              <div>List Name:</div>
              <TextInput
                type="text"
                name="listName"
                id="listName"
                disabled={currentlyEditingContactList?.item?.type !== 'custom'}
                onChange={(e)=>setCurrentlyEditingContactList({...currentlyEditingContactList, "value": e.target.value})}
                value={currentlyEditingContactList.value}
              />
            </TextLabel>
            { currentlyEditingContactList?.item?.type !== 'custom' && <ErrorMessage>You cannot edit names of imported lists.</ErrorMessage>}
          </div>
          <div style={{display: 'flex', justifyContent: 'start', margin: '10px auto'}} >
            <RadioLabel value="Prospect" control={<RadioStyle 
              onClick={() => setListType('prospect')} 
              checked={listType === 'prospect'} 
              id={`enterprise_radio_on`}
            />} label="Prospect" />
            <RadioLabel value="Client" control={<RadioStyle 
              id={`enterprise_radio_off`}
              checked={listType === 'client'}
              onClick={() => setListType('client')}
            />} label="Client" />
          </div>
          <AddButton 
            children="Submit" 
            style={{ margin: '20px auto 0', paddingBottom: 10}}
            onClick={()=>updateEmailListHandler({
              updatedName: currentlyEditingContactList.value,
              contactType: listType,
            })
          }
          />
        </div>
      )
      case 'create':
        if(modalTitle !== 'Save New List') setModalTitle('Save New List')
        return(
        <div style={{display: 'table', width: 400, background: 'white'}}>
          <div style={{margin: '10px 0'}}>
            <TextLabel>
              <div>List Name:</div>
              <TextInput
                type="text"
                name="listName"
                id="listName"
                onChange={(e)=>setCurrentlyEditingContactList({...currentlyEditingContactList, "value": e.target.value})}
                value={currentlyEditingContactList.value}
              />
            </TextLabel>
          </div>
          <div style={{display: 'flex', justifyContent: 'start', margin: '10px auto'}} >
            <RadioLabel value="Prospect" control={<RadioStyle 
              onClick={() => setListType('prospect')} 
              checked={listType === 'prospect'} 
              id={`enterprise_radio_on`}
            />} label="Prospect" />
            <RadioLabel value="Client" control={<RadioStyle 
              id={`enterprise_radio_off`}
              checked={listType === 'client'}
              onClick={() => setListType('client')}
            />} label="Client" />
          </div>
          <AddButton 
            children="Submit" 
            style={{ margin: '20px auto 0', paddingBottom: 10}}
            onClick={()=>createListFromSelections({
              listName: currentlyEditingContactList.value, 
              contactType: listType, 
              isDefault: false
            })
          }
          />
        </div>
      )
      case 'delete':
        if(modalTitle !== 'Confirm Delete') setModalTitle('Confirm Delete')
        return(
          <div style={{display: 'table', width: 400, background: 'white'}}>
            <p style={{color: '#475467', margin: '-10px auto 10px', fontSize: 14}}>Are you sure you want to delete this list? </p>
            <div style={{display: 'flex', justifyContent: 'space-between'}}>
              <AddButton 
                children="Yes" 
                style={{ margin: '20px auto 0', paddingBottom: 10}}
                onClick={()=>handleListDelete(currentlyEditingContactList.item)}
              />
              <AddButton 
                children="No" 
                style={{ margin: '20px auto 0', paddingBottom: 10}}
                onClick={()=>setRenameModal(false)}
              />
            </div>
          </div>
        )
      case 'deleteContacts':
        if(modalTitle !== 'Confirm Delete') setModalTitle('Confirm Delete')
        return(
          <div style={{display: 'table', width: 400, background: 'white'}}>
            <p style={{color: '#475467', margin: '-10px auto 10px', fontSize: 14}}>Are you sure you want to delete these contacts? </p>
            <div style={{display: 'flex', justifyContent: 'space-between'}}>
              <AddButton 
                children="Yes" 
                style={{ margin: '20px auto 0', paddingBottom: 10}}
                onClick={(e)=>handleEmailDeleteEvent(e)}
              />
              <AddButton 
                children="No" 
                style={{ margin: '20px auto 0', paddingBottom: 10}}
                onClick={()=>setRenameModal(false)}
              />
            </div>
          </div>
        )
      case 'deleteAllContacts':
        if(modalTitle !== 'Confirm Delete') setModalTitle('Confirm Delete')
        return(
          <div style={{display: 'table', width: 400, background: 'white'}}>
            <p style={{color: '#475467', margin: '-10px auto 10px', fontSize: 14}}>You have selected all of the contacts from this list. Are you sure you want to delete this list? </p>
            <div style={{display: 'flex', justifyContent: 'space-between'}}>
              <AddButton 
                children="Yes" 
                style={{ margin: '20px auto 0', paddingBottom: 10}}
                onClick={(e)=>handleListDelete(selectedEmailList)}
              />
              <AddButton 
                children="No" 
                style={{ margin: '20px auto 0', paddingBottom: 10}}
                onClick={()=>setRenameModal(false)}
              />
            </div>
          </div>
        )
      default: 
        return <div />
    }
  }

  const refreshDataAction = () => {
    setSearchBoxInput('')
    setSearchString('')
    refreshData()
  }

  return (
    <div style={{paddingBottom: 20}}>
      <OuterTableContainer>
        {
          <ListTable 
            tableData={emailList}
            searchBoxInput={searchBoxInput}
            setSearchBoxInput={setSearchBoxInput}
            listCheckAction={onEmailContractRowClick}
            headerData={headerData}
            searchHandler={handleSearch}
            selectedRow={selectedEmailList}
            listLoading={listLoading}
            tableLoading={tableLoading}
            setTableLoading={setTableLoading}
            userData={userData}
            refreshDataAction={refreshDataAction}
          />
        }
        {
          <ContactTable 
            tableData={emailTableData}
            segmentTabActive={segmentTabActive}
            searchString={searchString} 
            setSearchString={setSearchString}
            headerData={headerData}
            onAllCheckboxClick={onAllCheckboxClick}
            isAllCheckSelected={isAllCheckSelected}
            handleEmailSort={handleEmailSort}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            onIndividualCheckboxClick={onIndividualCheckboxClick}
            data={data}
            tableName={selectedEmailList?.name}
            selectedEmailList={selectedEmailList}
            searchHandler={searchHandler}
            isLoading={isContactsFetching}
            downloadCSVHandler={downloadCSVHandler}
            selectedContactGroup={selectedEmailListItem}
            contactsCount={contactsCount}
            handleEmailDelete={handleEmailDelete}
            tableLoading={tableLoading}
            setTableLoading={setTableLoading}
            editListModal={editListModal}
            emailList={emailList}
            addContactAction={addContactAction}
            createNewListFromSelections={createNewListFromSelections}
            onPaginationClick={onPaginationClick}
            newContacts={newContacts}
            setNewContacts={setNewContacts}
          />
        }
      </OuterTableContainer>
      <ModalContainer
        modalStatus={renameModal}
        closeModal={()=>setRenameModal(!renameModal)}
        title={modalTitle}
        children={editNameDisplay()}
      />
    </div>
  );
};

const mapStateToProps = state => {
  return {
    clearSearch: state?.contacts?.clearSearch
  }
}

export default connect(mapStateToProps, null)(EmailListTable);
