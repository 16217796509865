import React from 'react'
import { ModalBody, ModalHeader, ModalV2 } from '../Modal'
import { Flex } from '@rebass/grid'
import { Button } from '../Form'
import { LoaderWrapper } from '../Loader'

const DiscardModal = ({
    isOpen,
    handleClose,
    loading,
    onConfirmClick,
    message
}) => {
    return (
        <ModalV2
            isOpen={isOpen}
            onRequestClose={handleClose}
            width="40%"
            style={{ padding: '20px' }}
            bgColor="#fff"
        >
            <ModalHeader justifyContent={'center'}>
                <Flex
                    width="100%"
                    justifyContent="center"
                    style={{
                        color: '#101828',
                        fontSize: '20px',
                    }}
                >
                    Discard Unsaved Settings
                </Flex>
            </ModalHeader>
            <ModalBody>
                <Flex width="100%" justifyContent="center">
                    <img alt="" src="/assets/images/Campaign/Discard.svg" />
                </Flex>
                <Flex color="#475467" fontSize="14px" mt="5px">
                    Are you sure you want to go back? This will discard any unsaved settings.
                </Flex>
                <LoaderWrapper isLoading={loading}>
                    <Flex
                        alignItems={'center'}
                        justifyContent="space-between"
                        style={{ margin: '10px 0' }}
                        display="flex"
                        width="100%"
                    >
                        <Button
                            borderRadius="4px"
                            onClick={() => {
                                handleClose()
                            }}
                            width="50%"
                            padding="5px 10px"
                            margin="20px 10px 0px 0px"
                            height="40px"
                            bgColor="#fff"
                            color="#101828"
                            borderColor="#e4e7ec"
                        >
                            Cancel
                        </Button>
                        <Button
                            borderRadius="4px"
                            onClick={onConfirmClick}
                            width="50%"
                            padding="5px 10px"
                            margin="20px 0px 0px 0px"
                            height="40px"
                            bgColor="#816EF8"
                            color="#fff"
                        >
                            Confirm
                        </Button>
                    </Flex>
                </LoaderWrapper>
                {message && (
                    <Flex display="flex" fontSize="12px" justifyContent="center" color="#6351EC" width="100%">
                        {message}
                    </Flex>
                )}
            </ModalBody>

        </ModalV2>
    )
}

export default DiscardModal