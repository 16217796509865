import { createActions, handleActions } from 'redux-actions';
import { apiModuleDefaultState, createApiActions } from './helpers';

export const actions = createApiActions('contentlib');

export const moreActions = createActions({
  custom: {
    contentlib: {
      addToNewsletter: (payload) => payload,
      addToNewsletterResponse: (payload) => payload,
      removeFromNewsletter: (payload) => payload,
      removeFromNewsletterResponse: (payload) => payload,
      addToBookmarks: payload => payload,
      addToBookmarksResponse: payload => payload,
      removeFromBookmarks: payload => payload,
      removeFromBookmarksResponse: payload => payload,
      deleteResponse: payload => payload
    },
  },
});

const reducer = handleActions(
  {
    [actions.api.contentlib.request]: (state) => ({
      ...state,
      isFetching: true,
      isError: false,
      isNewsletterFetching: false
    }),
    [actions.api.contentlib.delete]: (state) => ({
      ...state,
      isDeleting: true,
    }),
    [moreActions.custom.contentlib.deleteResponse]: (state) => ({
      ...state,
      isDeleting: false,
    }),
    [actions.api.contentlib.response]: (state, { payload }) => ({
      ...state,
      isFetching: false,
      isError: false,
      data: payload,
      isDeleting: false,
      isBookmarksFetching: false,
      bookmarkArticleId: ''
    }),
    [actions.api.contentlib.error]: (state, { payload }) => ({
      ...state,
      isFetching: false,
      isError: true,
      error: payload,
      data: {},
    }),
    [actions.api.contentlib.setstate]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
    [moreActions.custom.contentlib.addToNewsletter]: (state) => ({
      ...state,
      isNewsletterFetching: true
    }),
    [moreActions.custom.contentlib.addToNewsletterResponse]: (state, { payload }) => ({
      ...state,
      isNewsletterFetching: false,
      bucketId: payload.bucketId,
      inNewsletter: payload.inNewsletter
    }),
    [moreActions.custom.contentlib.removeFromNewsletter]: (state) => ({
      ...state,
      isNewsletterFetching: true
    }),
    [moreActions.custom.contentlib.removeFromNewsletterResponse]: (state, { payload }) => ({
      ...state,
      isNewsletterFetching: false,
      bucketId: payload.bucketId,
      inNewsletter: payload.inNewsletter
    }),
    [moreActions.custom.contentlib.addToBookmarks]: (state, {payload}) => ({
      ...state,
      isBookmarksFetching: true,
      bookmarkArticleId: payload.params.articleId
    }),
    [moreActions.custom.contentlib.addToBookmarksResponse]: (state, {payload}) => ({
      ...state,
      isBookmarksFetching: false,
      bookmark_article_id: payload.bookmarkArticleId,
      isBookMark: payload.isBookMark
    }),
    [moreActions.custom.contentlib.removeFromBookmarks]: (state, {payload}) => ({
      ...state,
      isBookmarksFetching: true,
      bookmarkArticleId: payload.params.bookmarksId
    }),
    [moreActions.custom.contentlib.removeFromBookmarksResponse]: (state, {payload}) => ({
      ...state,
      isBookmarksFetching: false,
      bookmark_article_id: payload.bookmarkArticleId,
      isBookMark: payload.isBookMark
    }),
  },
  { ...apiModuleDefaultState, data: {}, error: null }
);

export default reducer;
