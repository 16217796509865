import styled from 'styled-components';

export const SponsoredArticlesWrapper = styled.div`
  display: flex;
  max-height: 92%;
  overflow-x: hidden;
  overflow-y: auto;
  flex-wrap: wrap;
  padding: 0px 20px;
  justify-content: center;
  flex-flow: row wrap;

  i {
    width: 22%;
    margin: 0px auto 10px auto;
  }
`;