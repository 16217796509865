import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { CircularProgress } from '@mui/material';
import { useMutation, useQueryClient } from 'react-query';
import SnackbarComponent from 'src/components/Snackbar';
import Button from 'src/componentsV2/Button';
import Topics from './Topics';
import Preview from './Preview';
import { createContent } from './mutations';
import { getThemesObjectsFromFetched } from 'src/utils';
import {
  ErrorText,
  Form,
  Input,
  InputWithLabel,
  Label,
  TextArea,
} from './styles';

function FinalStep({
  content,
  setShowAddContentModal,
  type = 'any',
  isEdit = false,
}) {
  const queryClient = useQueryClient();
  const [allSelectedTopics, setAllSelectedTopics] = useState([]);
  const [selectedTopics, setSelectedTopics] = useState([]);
  const [image, setImage] = useState(null);
  const [showTopicsEdit, setShowTopicsEdit] = useState(false);
  const [previewImage, setPreviewImage] = useState(null);
  const [showSnackbar, toggleSnackbar] = useState(false);
  const [snackbarContent, setSnackbarContent] = useState(null);
  const {
    register,
    formState: { errors },
    setValue,
    handleSubmit,
  } = useForm();

  const { mutate, isLoading } = useMutation(createContent, {
    onSuccess: () => {
      toggleSnackbar(true);
      setTimeout(() => {
        setShowAddContentModal(false);
      }, [2000]);
      queryClient.refetchQueries('discover-my-content');
    },
  });

  useEffect(() => {
    if (content?.text) {
      setValue('title', content?.text?.title ?? '');
      if (type === 'file') {
        setValue('description', ' ');
      } else {
        setValue('description', content?.text?.description ?? '');
      }
      setPreviewImage(content?.text?.image ?? null);
    }

    if (content?.themes) {
      const themes = [
        ...(Array.isArray(content?.themes)
          ? content?.themes
          : getThemesObjectsFromFetched(content) ?? []),
      ]?.map((theme) => ({ ...theme, theme_name: theme?.name }));
      setAllSelectedTopics(themes);
      setSelectedTopics(themes);
    }
  }, [content, setValue, type]);

  const onSubmit = (formData) => {
    if (selectedTopics?.length > 0) {
      mutate({
        contentId: content?.content_id,
        payload: {
          title: formData?.title ?? '',
          skip_compliance: true,
          message: formData?.description ?? ' ',
          publish_status: 'unpublished',
          internal_content: false,
          approved_to_share: true,
          themes: JSON.stringify({
            add: [...selectedTopics?.map((topic) => topic?.id)],
            remove: [
              ...allSelectedTopics
                ?.filter(
                  (topic) => !selectedTopics?.some((t) => t?.id === topic?.id)
                )
                ?.map((topic) => topic?.id),
            ],
          }),
          ...(!!image && { media1: image }),
        },
      });
      setSnackbarContent(null);
    } else {
      toggleSnackbar(true);
      setSnackbarContent({
        message: 'You need to select at least one topic to add a content',
        type: 'warning',
      });
    }
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <InputWithLabel>
        <Label>
          Title <span className="required">*</span>
        </Label>
        <Input
          {...register('title', {
            required: { value: true, message: 'Title is required' },
          })}
        />
        {errors && errors?.title && (
          <ErrorText>{errors?.title?.message}</ErrorText>
        )}
      </InputWithLabel>
      <InputWithLabel>
        <Label>Summary</Label>
        <TextArea {...register('description')} />
      </InputWithLabel>
      <Topics
        showTopicsEdit={showTopicsEdit}
        setShowTopicsEdit={setShowTopicsEdit}
        selectedTopics={selectedTopics}
        setSelectedTopics={setSelectedTopics}
      />
      <Preview
        content={content}
        selectedTopics={selectedTopics}
        setImage={setImage}
        previewImage={previewImage}
        setPreviewImage={setPreviewImage}
      />
      <Button type="submit" disabled={showTopicsEdit} fullWidth>
        {isLoading ? (
          <CircularProgress size={15} />
        ) : (
          <>{isEdit ? 'Save' : 'Add Content'}</>
        )}
      </Button>

      <SnackbarComponent
        open={showSnackbar}
        variant={snackbarContent?.type ?? 'success'}
        handleClose={() => toggleSnackbar(false)}
        content={
          snackbarContent?.message ??
          `Content has been ${isEdit ? 'updated' : 'added'} successfully`
        }
      />
    </Form>
  );
}

export default FinalStep;
