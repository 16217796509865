import { Flex } from '@rebass/grid';
import React, { useState } from 'react';

const CampaignPurpose = ({ purpose, type }) => {

    const [ showTooltip, toggleTooltip ] = useState(false);

    return (
        <Flex
            flexDirection="column"
            justifyContent="center"
            alignItems="flex-start"
            marginLeft={type === 'series' ? '2px' : '3px'}
            width={type !== 'series' ? '150px' : '200px'}
            style={{ 
                position: 'relative', 
                cursor: purpose && purpose.length > 10 ? 'pointer' : 'default' ,
                width: '15%'
            }}
            onMouseEnter={() => toggleTooltip(true)}
            onMouseLeave={() => toggleTooltip(false)}
        >
            <span
                style={{ 
                    color: '#4e6780', 
                    fontSize: '12px', 
                    marginBottom: type !== 'series' && '10px',
                    //marginLeft: type === 'series' && '4px'
                }}
            >
                Purpose
            </span>
            <span 
                style={{ color: '#4e6780', fontSize: '12px', marginTop: '10px', width: '125px', textAlign: 'left'}}
                // style={{ color: '#4e6780', fontSize: '12px', marginTop: '10px', width: '130px', marginLeft: type !== 'series' && '-14px'}}
            >
                {purpose ? (purpose.length > 20 ? purpose.substring(0, 20).trim() + '...' : purpose) : '-'}
            </span>
            {purpose && purpose.length > 20 && showTooltip && (
                <Flex 
                    flexDirection='column' 
                    justifyContent='flex-start'
                    style={{ position: 'absolute', top: type === 'series' ? '50px' : '60px' }} 
                >
                    <div 
                        style={{
                            width: 0,
                            height: 0, 
                            borderLeft: '5px solid transparent',
                            borderRight: '5px solid transparent',
                            borderBottom: '5px solid #49484a',
                            marginLeft: '8px'
                        }}
                    >    
                    </div>
                    <div 
                        style={{
                            fontSize: '12px', 
                            color: '#fff', 
                            backgroundColor: '#49484a',
                            borderRadius: '4px',
                            width: '200px',
                            textAlign: 'left',
                            padding: '2px 6px'
                        }}
                    >
                        {purpose}
                    </div>
                </Flex>
            )}
      </Flex>
    );
};

export default CampaignPurpose;