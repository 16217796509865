import { Flex } from '@rebass/grid';
import React from 'react'
import { ToggleButton } from '../../containers/ConfigureContainer/screenConfigure.styles';

const ComplianceReview = ({
    handleComplianceToggle,
    toggleCompliance
}) => {
    return (
        <Flex width="100%">
            <Flex width="11%" style={{ padding: '10px 15px 0px 0px' }}>
                <ToggleButton
                    className="react-switch"
                    onChange={(checked) => handleComplianceToggle(checked)}
                    checked={toggleCompliance === true}
                    onColor="#816EF8"
                    offColor="#ccc"
                    offHandleColor="#fff"
                    onHandleColor="#fff"
                    handleDiameter={10}
                    uncheckedIcon=""
                    checkedIcon=""
                    boxShadow=""
                    activeBoxShadow=""
                    type="autoPost"
                    left="1px"
                    margin="-3px 5px 4px"
                    position="absolute"
                    top="7px"
                    opacity="0"
                />
            </Flex>
            <Flex
                width="70%"
                justifyContent="flex-start"
                padding="10px 0px"
                color="#101828"
                fontSize="14px"
                style={{ fontWeight: 500 }}
            >
                Compliance required
            </Flex>
        </Flex>
    )
}

export default ComplianceReview;