import styled from 'styled-components';

export const UploadCSVFooterSection = styled.div`
  display: flex;
  background-color: #fff;
  height: 7%;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  position: fixed;
  bottom: 0;
  left: 0;
`;

export const ContactListInput = styled.input`
  margin-bottom: 5px;
  color: #000 !important;
  background-color: #ffffff;
  border: 1px solid rgb(228, 231, 236);;
  text-align: start;
  display: block;
  width: 100%;
  height: 34px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  background-image: none;
  border-radius: 4px;
  font-family: 'Poppins';
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
`;

export const ContactSearchInput = styled.input.attrs({
  className: 'email-search',
})`
  border: 0px;
  padding: 2px 0px;
  width: 100%;
  font-size: 12px;
  font-family: Poppins;
  color: #7b7b7b;

  ::placeholder {
    text-align: left;
    color: #667085;
    font-size: 12px;
  }
`;