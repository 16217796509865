import styled from 'styled-components';
import { colors } from '../../styles';

export const CardContainer = styled('div')`
    background: ${colors.light_purple};
    border-radius: 8px;
    opacity: 1;
    width: 100%;
    margin: ${({ margin }) => margin || '0px 0 15px'};
    border: 1px solid ${colors.gray_border};
    display: table;
    height: ${({ height }) => height || ''};
    overflow-y: auto;
`;

export const HeaderGroup = styled('div')`
    display: flex;
    width: 100%;
    margin: 15px 0px 5px;
    padding: 0 20px 15px;
    border-bottom: 1px solid ${colors.gray_border};
`;

export const BottomGroup = styled('div')`
    display: table;
    width: 90%;
    color: ${colors.black_01};
    margin: 0px auto 10px;
    padding: 10px 0;
    border-bottom: 1px solid ${colors.gray_border};
`;

export const CardTitle = styled('div')`
    font-weight: 500;
    font-size: 20px;
    color: ${colors.black};
    text-align: left;
    margin: 10px 0;
`;

export const BodyText = styled('div')`
    font-weight: 500;
    font-size: 14px;
    color: ${colors.light_gray_text};
    text-align: left;
    margin: 15px 0;
`;

export const DarkText = styled('div')`
    font-weight: 500;
    font-size: 14px;
    color: ${colors.black};
    text-align: left;
    margin: 15px 0;
`;

export const DataColumns = styled('div')`
    display: table;
    width: 90%;
    margin: 5px auto;
`;

export const DataRow = styled('div')`
    display: flex;
    justify-content: space-between;
    margin: 5px auto;
    height: 35px;
`;

export const DataTitle = styled('div')`
    font-weight: 400;
    font-size: 14px;
    padding: 5px 0 0;
    color: ${colors.gray_text};
`;

export const DataValue = styled('div')`
    font-weight: 500;
    font-size: 14px;
    padding: 5px 0 0;
    margin-right: 5px;
    color: ${colors.black_01};
`;

export const IconStyle = styled('img')`
   width: 20px;
   height: 30px;
   margin: 10px 5px 0 0;
`;