import React from 'react';
import { useState } from 'react';
import {
  LoaderWrapper,
  Modal,
  ModalBody,
  ModalCloseButton,
} from '../../../../components';
import { AutomatedDeleteCampaign } from '../../../../services/automated-campaigns';
import { HoverButton } from '../../../ConfigureContainer/EmailList/EmailList.styles';

export const AutomatedCampaignDeleteModal = ({
  open,
  handleClose,
  campaignId,
  campaignRequest,
  
}) => {
  const [loading, setLoading] = useState(false);

  const handleSubmit = () => {
    const payload = { id: campaignId };
    if (campaignId) {
      setLoading(true);
      AutomatedDeleteCampaign(payload)
        .then((res) => {
          console.log('deleted');
          setLoading(false);
          campaignRequest({ 
            type: ['automated'],
            limit: 10,
            offset: 1
          });
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
        })
    }
  };
  return (
    <Modal
      onRequestClose={handleClose}
      shouldCloseOnOverlayClick
      isOpen={open}
      size="smail"
    >
      <ModalCloseButton onClick={handleClose} />
      <ModalBody>
        <div
          style={{
            fontWeight: 'bold',
            fontSize: '16px',
            color: '#6351ed',
            fontFamily: 'Poppins',
            wordBreak: 'break-all',
          }}
        >
          Are you sure you want to delete ?
        </div>
        <LoaderWrapper isLoading={loading}>
          <HoverButton
            margin="9px 10px 0px 0px"
            padding="5px 28px"
            bgColor="#fff"
            hoverBackground="#6351ed"
            hoverColor="#fff"
            color="#6351ed"
            borderColor="#fff"
            onClick={handleSubmit}
          >
            Yes
          </HoverButton>
          <HoverButton
            margin="9px 0px 0px 0px"
            padding="5px 28px"
            bgColor="#fff"
            color="#6351ed"
            hoverBackground="#6351ed"
            hoverColor="#fff"
            borderColor="#fff"
            onClick={handleClose}
          >
            No
          </HoverButton>
        </LoaderWrapper>
      </ModalBody>
    </Modal>
  );
};