import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import { RecentArticle } from '../../../components';
import { get } from 'lodash';

import {
  getExploreArticleData,
  dashboardSliderSettings,
  getRecentArticleData,
} from '../../../utils';

import { ArticleSliderWrapper } from '../DashboardContainer.styles';
import { getContactsGroups } from '../../../services/configure';

const RecentSharedTab = ({
  selectedTopic,
  exploreArticles,
  shareError,
  themeNames,
  isAdmin,
  setShowArticleLoader,
  isPremiumAdvisor,
  isExportPDF,
  handleArticleSelection,
  exportPDFArticles,
  styles,
}) => {
  const [localExploreArticles, setLocalExploreArticles] = useState([]);
  const [totalArticles, setTotalArticles] = useState(0);
  const [imageCounter, setImageCounter] = useState([]);
  const [contactGroups, setContactGroups] = useState([]);

  useEffect(() => {
    let articles = getRecentArticleData(exploreArticles);
    setLocalExploreArticles(articles);
    setTotalArticles(articles.length);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exploreArticles]);

  useEffect(() => {
    getContactsGroups()
      .then((res) => {
        if (res.result.success) {
          let data = res.result.data.filter(
            (c) =>
              ![
                'GMAIL',
                'HUBSPOT',
                'OUTLOOK',
                'WEALTHBOX',
                'SALESFORCE',
                'REDTAIL',
              ].includes(get(c, 'name')) && get(c, 'contacts.count', 0) > 0
          );
          setContactGroups(data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  console.log('RECENT ARTICLES - - - - - ', localExploreArticles);
  return (
    <ArticleSliderWrapper style={{ clear: 'both', ...styles }}>
      <Slider {...dashboardSliderSettings}>
        {localExploreArticles.map((article, index) => (
          <RecentArticle
            article={article}
            {...{
              ...article,
              selectedTopic,
              shareError,
              themeNames,
              isAdmin,
              index,
              totalArticles,
              setShowArticleLoader,
              imageCounter,
              setImageCounter,
              contactGroups,
              isPremiumAdvisor,
              isExportPDF,
              handleArticleSelection,
              exportPDFArticles,
            }}
          />
        ))}
      </Slider>
    </ArticleSliderWrapper>
  );
};

export default RecentSharedTab;
