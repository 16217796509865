import { Flex } from '@rebass/grid';
import styled from 'styled-components';

export const EmailList = styled(Flex)`
  overflow: auto;
  flex-direction: column;
  height: 323px;
  padding-right: 10px;
  overflow-x: hidden;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background: #e9f6e6 !important;
  }

  &::-webkit-scrollbar-thumb {
    background: #bccdd4 !important;
  }
`;

export const DateTimeText = styled.div`
  width: 85%;
  font-size: 0.9rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const DateTimeWrapper = styled(Flex)`
  border: ${({ isPastDate }) =>
    isPastDate ? '1px solid #990000' : '1px solid #6351ed'};
  border-radius: 5px;
  color: ${({ isPastDate }) => (isPastDate ? '#990000' : '#6351ed')};
`;

export const ApprovalCard = styled(Flex)`
  border-radius: 4px;
  align-items: flex-start;
  width: 400px;
  padding: 20px;
  margin: 10px 0px 15px 0px;
  background-color: #fff;
  box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px,
    rgba(17, 17, 26, 0.1) 0px 0px 8px;
  display: flex;
  flex-direction: row;
`;
