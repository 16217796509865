
import { get, isEmpty } from 'lodash';
import React,{useState,useEffect} from 'react';
import Slider from 'react-slick';
import { DashboardArticle, FreeArticle, LoaderWrapper } from '../../../components';

import { getExploreArticleData, dashboardSliderSettings, eventHandlers } from '../../../utils';

import { ArticleSliderWrapper } from '../DashboardContainer.styles';

const MioEditorTab = ({
  selectedTopic,
  exploreArticles,
  deleteArticleRequest,
  themeNames,
  exploreArticleDeleting,
  exploreArticlesFetching,
  isFreeUser,
  isShareBlocked,
  isDeleteBlocked,
  showMio,
  exploreArticleRequest,
  setMioContent,
  id,
  imageUpload,
  setShowArticleLoader,
  thirdPartyThemeId,
  clearEditorArticles,
  setClearEditorArticles,
  isChangeTheme,
  popularThemes,
  userData,
  popularTopicDetails,
  refresh,
  newsletterArticleFetching,
  bookmarksFetching,
  addToBucketRequest,
  removeFromNewsletterRequest,
  exploreNewsletterId,
  addToBookmarksRequest,
  removeFromBookmarksRequest,
  bookmarksId
}) => {
  const [firstTime, setFirstTime] = useState(false);
  const [sliderIndex, setSliderIndex] = useState(0);
  const [mioTab,setMioTab] = useState(true);
  const [imageCounter, setImageCounter] = useState([]);
  // console.log('[SHOW ARTICLE LOADER] - ')
  dashboardSliderSettings.afterChange = (index) => {
    let articles = allArticles.slice(index, index + 4);
    const userEmail = userData && userData.details && userData.details.user && userData.details.user.email;
    articles.forEach(item => {
      eventHandlers(
        'ARTICLE_DISPLAYED', 
        popularThemes, 
        item.id, 
        'MY_EDITOR', 
        item.channelId, 
        item.title, 
        item.themeId, 
        themeNames[item.themeId], 
        item.topic,
        userEmail
      )
    })
    if(index > sliderIndex) {
      setSliderIndex(index);
    //   if(!exploreArticlesFetching && exploreArticles && exploreArticles.pagination && exploreArticles.pagination.after)
    //     exploreArticleRequest({
    //       params: {
    //         id:'',
    //         source: 'mioEditorResult',
    //         date:'',
    //         mode: 'after',
    //         token: exploreArticles.pagination.after,
    //         thirdPartyThemeId: thirdPartyThemeId
    //       },
    //     })
    }
  }
  
  const [allArticles, setAllArticles] = useState([]);
  const [deleteId, setDeleteId] = useState();
  console.log(exploreArticles);

  useEffect(() => {
    if(thirdPartyThemeId) {
      setFirstTime(false);
    }
  }, [thirdPartyThemeId])

  useEffect(() => {
    exploreArticleRequest({
      params: {
        id:'',
        source: 'mioEditorResult',
        date:'',
        mode: '',
        token: '',
        holdingsType: '',
        thirdPartyThemeId: ''
      },
    })
  }, [])

  useEffect(() => {
    console.log(exploreArticles);
    if(isEmpty(get(exploreArticles, 'feed', [])) && isEmpty(allArticles)) {
      setShowArticleLoader(false);
      setAllArticles([]);
    }
    else if(clearEditorArticles) {
      console.log(exploreArticles);
      setAllArticles(getExploreArticleData(exploreArticles, 'editor'));
    } else if(exploreArticles && allArticles.length < 4) {
      if(popularThemes && popularThemes.length > 0 && allArticles.length > 0) {
        console.log(exploreArticles.feed);
      }
      if(exploreArticles && exploreArticles.pagination && exploreArticles.pagination.after)
      {
        setAllArticles(getExploreArticleData(exploreArticles, 'editor'));
        // setShowArticleLoader(false);
        // exploreArticleRequest({
        //   params: {
        //     id:'',
        //     source: 'mioEditorResult',
        //     date:'',
        //     mode: 'after',
        //     token: exploreArticles.pagination.after,
        //     holdingsType: '',
        //     thirdPartyThemeId: thirdPartyThemeId
        //   },
        // })
      }
      if(exploreArticles && allArticles.length === 0) {
        setAllArticles([...getExploreArticleData(exploreArticles, 'editor')]);
      }
    } 
    else if(deleteId && deleteId.length > 0) {
      setAllArticles([...getExploreArticleData(exploreArticles, 'editor')]);
      setDeleteId('');
      setSliderIndex(0);
      if(exploreArticles && exploreArticles.pagination && exploreArticles.pagination.after) {
        console.log('check');
        // exploreArticleRequest({
        //   params: {
        //     id:'',
        //     source: 'mioEditorResult',
        //     date:'',
        //     mode: 'after',
        //     token: exploreArticles.pagination.after && exploreArticles.pagination.after,
        //     holdingsType: '',
        //     thirdPartyThemeId: thirdPartyThemeId
        //   },
        // })
      }
    } else if(exploreNewsletterId && exploreNewsletterId.length > 0) {
      setAllArticles([...getExploreArticleData(exploreArticles, 'editor')]);
      setSliderIndex(0);
      if(exploreArticles && exploreArticles.pagination && exploreArticles.pagination.after) {
        console.log('check');
        // exploreArticleRequest({
        //   params: {
        //     id:'',
        //     source: 'mioEditorResult',
        //     date:'',
        //     mode: 'after',
        //     token: exploreArticles.pagination.after && exploreArticles.pagination.after,
        //     holdingsType: '',
        //     thirdPartyThemeId: thirdPartyThemeId
        //   },
        // })
      }
    } else if(isEmpty(exploreArticles)) {
      exploreArticleRequest({
        params: {
          id: id,
          source: 'mioEditorResult',
          date:'',
          mode: '',
          token: '',
          holdingsType: '',
          thirdPartyThemeId: thirdPartyThemeId
        },
      });
    }
     else {
      if(exploreArticles && exploreArticles.pagination && exploreArticles.pagination.after);
          setAllArticles([...allArticles, ...getExploreArticleData(exploreArticles, 'editor')]);
          const userEmail = userData && userData.details && userData.details.user && userData.details.user.email;
          if(!firstTime && allArticles.length > 4) {
            allArticles.slice(0,4).forEach(item => {
              eventHandlers(
                'ARTICLE_DISPLAYED', 
                popularThemes, 
                item.id, 
                'MY_EDITOR', 
                item.channelId, 
                item.title, 
                item.themeId, 
                themeNames[item.themeId], 
                item.topic,
                userEmail
              )
            })
            setFirstTime(true);
          }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exploreArticles, thirdPartyThemeId, clearEditorArticles]);

  useEffect(() => {
    setMioContent(allArticles);
    setShowArticleLoader(false);
  }, [allArticles]);

  // console.log('articles [from contentLibTab] - ', allArticles);

  return (
    <LoaderWrapper
      isLoading={isEmpty(allArticles) && exploreArticlesFetching}
      isDelay={false}
      imageUpload={imageUpload}
    >
    <ArticleSliderWrapper>
      <Slider {...dashboardSliderSettings}>
        {isFreeUser && <FreeArticle />}
        {allArticles.map((article,index) => (
          <DashboardArticle
            isFromEditorTab={true}
            article={article}
            refresh={refresh}
            popularTopicDetails={popularTopicDetails}
            exploreArticleRequest={exploreArticleRequest}
            bookmarksFetching={bookmarksFetching}
            bookmarksId={bookmarksId}
            {...{
              ...article,
              selectedTopic,
              isFreeUser,
              deleteArticleRequest:  (id) => {
                deleteArticleRequest({
                  params: { deleteId: id, source: 'mioEditorResult'},
                })
                setTimeout(() => {
                  setDeleteId(id);
                }, 300);  
              },
              onNewsletterArticleClick: (articleId, themeId, source) => {
                if(source === 'newsLetter') {
                  addToBucketRequest({
                    params: { articleId: articleId, themeId: themeId, source: 'mioEditorResult', newsletterId: articleId}
                  })
                  setTimeout(() => {
                    setDeleteId(articleId);
                  }, 300);  
                }
                else {
                  addToBookmarksRequest({
                    params: { articleId: articleId, themeId: themeId, source: 'mioEditorResult'}
                  })
                  setTimeout(() => {
                    setDeleteId(articleId);
                  }, 300);  
                }
              },
              onRemoveFromNewletterClick: (bucketId, id, source) => {
                if(source === 'newsLetter') {
                  removeFromNewsletterRequest({
                    params: { articleId: bucketId, source: 'mioEditorResult', newsletterId: id },
                  })
                  setTimeout(() => {
                    setDeleteId(id);
                  }, 300);  
                }
                else {
                  removeFromBookmarksRequest({
                    params: { articleId: bucketId, source: 'mioEditorResult', newsletterId: id }
                  });
                  setTimeout(() => {
                    setDeleteId(id);
                  }, 300);  
                }
              },
              themeNames,
              exploreArticleDeleting,
              isShareBlocked,
              isDeleteBlocked,
              showMio,
              index,
              mioTab,
              totalArticles : allArticles.length,
              setShowArticleLoader,
              imageCounter,
              setImageCounter,
              section: 'MY_EDITOR',
              newsletterArticleFetching,
              exploreNewsletterId
            }}
          />
        ))}
      </Slider>
    </ArticleSliderWrapper>
    </LoaderWrapper>
  );
};

export default MioEditorTab;
