import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Flex } from '@rebass/grid'
import { isEmpty } from 'lodash'
import React from 'react'
import { getMediaUrl, onImageError } from '../../../utils'
import { DripVerticalLine } from '../CreateCampaign/CreateCampaign.styles'
import { CampaignThemeTiles, PopularTopicsWrapper } from './SetTopics.styles'

const CampaignPopularTopicsTab = ({
    themes,
    trendingTopics,
    mostSubscribedTopics,
    onAddTopics
}) => {
    return (
        <PopularTopicsWrapper>
            <Flex width="48%" flexDirection="column">
                <Flex fontSize="12px" width="100%" color="#000000">
                    Trending Topics
                </Flex>
                <Flex width="100%" flexWrap="wrap">
                    {!isEmpty(trendingTopics) && trendingTopics.slice(0, 10).map((item, index) => (
                        <CampaignThemeTiles>
                            <img className="tiles-image" height="25" src={getMediaUrl(item.media_urls)} alt="" />
                            <span className="tiles-title">{item.name}</span>
                            <div className="camp-tile-cover" onClick={() => onAddTopics(item)}>
                                <FontAwesomeIcon icon={faPlus} style={{position: 'relative', left: '-2px'}}/>
                                <span className="camp-tile-cover-title">{item.name}</span>
                            </div>
                        </CampaignThemeTiles>
                    ))}
                </Flex>
            </Flex>
            <DripVerticalLine height="100%" />
            <Flex width="48%" flexDirection="column">
                <Flex width="100%" fontSize="12px" color="#000000">
                    Most Subscribed Topics
                </Flex>
                <Flex width="100%" flexWrap="wrap">
                    {!isEmpty(mostSubscribedTopics) && mostSubscribedTopics.map((item, index) => (
                        <CampaignThemeTiles>
                            <img className="tiles-image" height="25" src={getMediaUrl(item.media_urls)} alt="" />
                            <span className="tiles-title">{item.name}</span>
                            <div className="camp-tile-cover" onClick={() => onAddTopics(item)}>
                                <FontAwesomeIcon icon={faPlus} />
                                <span className="camp-tile-cover-title">{item.name}</span>
                            </div>
                        </CampaignThemeTiles>
                    ))}
                </Flex>
            </Flex>
        </PopularTopicsWrapper>
    )
}

export default CampaignPopularTopicsTab
