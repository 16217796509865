import React from 'react';
import {
  Grid,
  Dialog,
  DialogContent as MuiDialogContent,
  useMediaQuery,
} from '@mui/material';
import { useTheme, styled } from '@mui/material/styles';
import DialogTitle from './DialogTitle';
import { HeaderWrapper } from './styles';

const DialogContent = styled(MuiDialogContent)(({ theme }) => ({
  padding: 0,
  backgroundColor: '#fff',
}));

function DialogModal({
  show,
  toggleModalVisibility,
  title = '',
  subTitle = '',
  maxWidth = 'md',
  fullWidth = true,
  disableBackdropClick = false,
  disableEscapeKeyDown = false,
  showCloseButton = true,
  children,
  showTitle = false,
}) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const handleClose = (_, reason) => {
    if (reason === 'backdropClick') toggleModalVisibility(disableBackdropClick);
    else toggleModalVisibility(false);
  };

  return (
    <Dialog
      fullScreen={fullScreen}
      open={show}
      maxWidth={maxWidth}
      fullWidth={fullWidth}
      onClose={handleClose}
      disableEscapeKeyDown={disableEscapeKeyDown}
      aria-labelledby={`${title}__dialog-title`}
    >
      {(showTitle || showCloseButton) && (
        <DialogTitle
          showTitle={showTitle}
          showCloseButton={showCloseButton}
          onClose={() => toggleModalVisibility(false)}
          id={`${title}__dialog-title`}
        >
          <HeaderWrapper container>
            <Grid item>
              <p className="title"> {title}</p>
            </Grid>
            {subTitle && (
              <Grid item xs={12}>
                <p className="subTitle">{subTitle}</p>
              </Grid>
            )}
          </HeaderWrapper>
        </DialogTitle>
      )}
      <DialogContent>{children}</DialogContent>
    </Dialog>
  );
}

export default DialogModal;
