import styled from 'styled-components';
import { Tab, TabList, Tabs } from 'react-tabs';
import { themesData } from '../../utils';
import { Flex } from '@rebass/grid';

export const StyledTabsTwo = styled(Tabs)`
  margin: 0px 0px 20px;
  display: inline-block;
  width: 100%;
  position: relative;
`;

export const StyledTabListTwo = styled(TabList)`
  list-style: none;
  margin: 0px;
  padding: 0px;
  width: 100%;
  margin: 0 auto;
  display: inline-flex;
  clear: both;
  padding: 2% 5% 0% 5%;
`;

export const StyledTabTwo = styled(Tab)`
  font-size: 10px;
  font-weight: 500;
  position: relative;
  z-index: 0;
  padding: 5px 0px;
  left: 30px;
  cursor: pointer;
  border-bottom-left-radius: 8px;
  list-style-type: none;
  display: inline-block;
  vertical-align: top;
  overflow: hidden;
  margin: 0;
  text-align: center;
  box-shadow: none !important;
  z-index: 9;

  &:nth-child(2) {
    left: 0;
    z-index: 1;
  }
  &:nth-child(2).react-tabs__tab--selected {
    z-index: 9;
  }

  &:nth-child(2):after {
    color: #e6e6e6;
  }

  &:after {
    content: '\\73';
    display: block;
    font-family: 'themes-icon';
    font-size: 145px;
    line-height: 0;
    position: relative;
    color: #d4d4d4;
    z-index: -1;
    top: -7px;
    transform: rotate(360deg);
  }
  &:last-child {
    left: ${(props) => (props.twoTabs ? '0px' : '-30px')};
    z-index: 0;
  }
  &:last-child.react-tabs__tab--selected {
    z-index: 9;
  }
  &:last-child:after {
    color: #d4d4d4;
  }

  &.react-tabs__tab--selected:after {
    color: ${({ theme }) => (theme === 'light' ? '#e4e4e5' : '#fff')};
  }
`;

export const ComplianceTooltip = styled.div.attrs({
  className: 'compliance-tooltip',
})`
  position: relative;

  .compliance-tooltip-text {
    visibility: hidden;
    width: 239px;
    background-color: #49484a;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    position: absolute;
    z-index: 1;
    top: 121%;
    left: 16%;
    margin-left: -60px;
    padding: 5px 2px;
    font-size: 10px;
  }

  &:hover .compliance-tooltip-text {
    visibility: visible;
  }
`;

export const DownloadPDFContainer = styled.div`
  position: absolute;
  left: 0;
  margin-top: 10px;
  font-size: 14px;
`;

export const ExportPDFButton = styled(Flex)`
  padding: 0px 25px;
  padding-bottom: 5px;
  justify-content: center;
  align-items: center;
  width: max-content;
  float: right;
  font-size: 0.7rem;
  &:hover {
    cursor: ${({ disabled }) => (disabled ? 'pointer' : '')};
  }
  opacity: ${({ disabled }) => (disabled ? '100%' : '50%')};
`;
