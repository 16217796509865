import { Flex } from '@rebass/grid';
import React from 'react';

const AddContentLayoutV2 = ({ layout, setLayout, themesData, webinarData, selectedLandingPage, acFlow }) => {
  return (
    <Flex width="100%" alignItems="center" justifyContent="center">
      <Flex
        alignItems="center"
        width="100%"
        style={{
          //   maxHeight: '497px',
          overflow: 'auto',
          alignItems: 'start',
          //   height: '475px',
        }}
      >
        {/* <p style={{fontSize: '14px'}}>Select Row Type: </p> */}
        {/* <select
                    style={{
                        color: '#6351ed',
                        border: '1px solid #adadad',
                        fontSize: '14px',
                        padding: '4px',
                        width: '65%'
                    }}
                    name="row-type"
                    id="row_type"
                    value={layout}
                    defaultValue=''
                    onChange={e => setLayout(e.target.value)}
                >
                    <option value="none" disabled hidden>Select an Option</option>
                    <option value="one-column">One Column Article Block</option>
                    <option value='two-columns'>Two Column Article Block</option>
                    <option value="one-column-reverse">One Column Reverse Article Block</option>
                    <option value="landing-pages">Landing Pages</option>
                    {webinarData && webinarData.isWebinar && (
                        <option value="webinar-block">Webinar Block</option>
                    )}
                </select> */}
        <Flex width="100%" flexWrap="wrap" justifyContent="start" marginLeft='12px'>
          <Flex
            flexDirection="row"
            alignItems="center"
            width="100%"
            margin="10px"
            padding="3px"
            style={{
              border:
                layout === 'one-column'
                  ? '2px solid #6351ed'
                  : '1px solid #E4E7EC',
              borderRadius: '4px'
            }}
            onClick={() => setLayout('one-column')}

          >
            <img src='/assets/images/my_content_flow.png' alt='' width='40px' height='40px' />
            <Flex flex={'1'} padding='8px 4px' fontSize='16px' color='#000000' style={{ textAlign: 'center' }}>
              One column article block
            </Flex>
          </Flex>

          <Flex
            flexDirection="row"
            alignItems="center"
            width="100%"
            margin="10px"
            padding="3px"
            style={{
              border:
                layout === 'two-columns'
                  ? '2px solid #6351ed'
                  : '1px solid #E4E7EC',
              borderRadius: '4px'
            }}
            onClick={() => setLayout('two-columns')}

          >
            <img src='/assets/images/my_content_flow_1.png' alt='' width='40px' height='40px' />
            <Flex flex={'1'} padding='8px 8px' fontSize='16px' color='#000000' style={{ textAlign: 'left' }}>
              Two column article block
            </Flex>
          </Flex>

          <Flex
            flexDirection="row"
            alignItems="center"
            width="100%"
            margin="10px"
            padding="3px"
            style={{
              border:
                layout === 'one-column-reverse'
                  ? '2px solid #6351ed'
                  : '1px solid #E4E7EC',
              borderRadius: '4px'
            }}
            onClick={() => setLayout('one-column-reverse')}

          >
            <img src='/assets/images/my_content_flow_2.png' alt='' width='40px' height='40px' />
            <Flex flex={'1'} padding='8px 8px' fontSize='16px' color='#000000' style={{ textAlign: 'left' }}>
              One column reverse article block
            </Flex>
          </Flex>

          {((acFlow && selectedLandingPage) || !acFlow) && (
            <Flex
              flexDirection="row"
              alignItems="center"
              width="100%"
              margin="10px"
              padding="3px"
              style={{
                border:
                  layout === 'landing-pages'
                    ? '2px solid #6351ed'
                    : '1px solid #E4E7EC',
                borderRadius: '4px'
              }}
              onClick={() => setLayout('landing-pages')}

            >
              <img src='/assets/images/my_content_flow_3.png' alt='' width='40px' height='40px' />
              <Flex flex={'1'} padding='8px 8px' fontSize='16px' color='#000000' style={{ textAlign: 'left' }}>Landing Pages</Flex>
            </Flex>
          )}


          {webinarData && webinarData.isWebinar && (
            <Flex
              flexDirection="row"
              alignItems="center"
              width="100%"
              margin="10px"
              padding='3px'
              style={{
                border:
                  layout === 'webinar-block'
                    ? '2px solid #6351ed'
                    : '1px solid #E4E7EC',
              }}
              onClick={() => setLayout('webinar-block')}
            >
              <img src='/assets/images/my_content_flow_3.png' alt='' width='40px' height='40px' />
              <Flex flex={'1'} padding='8px 8px' fontSize='16px' color='#000000' style={{ textAlign: 'left' }}>Webinar</Flex>
            </Flex>
          )}
        </Flex>
      </Flex>
    </Flex>
  );
};

export default AddContentLayoutV2;
