import React from 'react';

import { StyledInput, StyledInputLarge, StyledFloatInput } from '../';
import styled from 'styled-components';

const InputLabel = styled.label`
  color: ${({ color }) => color || '#7b7b7b'};
  font-size: 14px;
  position: absolute;
  left: 0%;
  top: 50%;
  transform: translateY(-50%);
  padding: 0 0.3rem;
  margin: 0 0.5rem;
  transition: 0.1s ease-out;
  transform-origin: left top;
  pointer-events: none;
`;

const Input = ({
  type,
  name,
  id,
  value,
  placeholder,
  error,
  onChange,
  largeInput,
  disabled,
  onKeyDown,
  width,
  bgColor,
  marginBottom,
  isInputWithFloatingLabel,
  color,
  placeholderColor,
  placeholderAlignment,
  placeholderSize,
  outerWidth,
  marginRight,
  fontSize,
  padding,
  height,
  margin,
  borderRadius,
  border,
  onFocus,
  required,
  onBlur,
  pr,
  max,
  labelStyle,
  fontColor,
  inputStyles
}) => (
  <div
    style={{
      width: outerWidth ? outerWidth : '',
      margin: margin ? margin : '',
      marginRight: marginRight ? marginRight : '',
    }}
  >
    {largeInput ? (
      <StyledInputLarge
        type={type}
        name={name}
        fontColor={fontColor}
        id={id}
        value={value}
        border={border}
        placeholder={placeholder}
        onChange={onChange}
        disabled={disabled}
        onKeyDown={onKeyDown}
        onBlur={onBlur}
        onFocus={onFocus}
        width={width}
        bgColor={bgColor}
        placeholderColor={placeholderColor}
        placeholderAlignment={placeholderAlignment}
        placeholderSize={placeholderSize}
        marginBottom={marginBottom}
        required={required}
        fontSize={fontSize}
        padding={padding}
        height={height}
        borderRadius={borderRadius}
      />
    ) : (
      <div>
        {isInputWithFloatingLabel ? (
          <div>
            <StyledFloatInput
              type={type}
              name={name}
              id={id}
              value={value}
              placeholder=" "
              onChange={onChange}
              disabled={disabled}
              onKeyDown={onKeyDown}
              color={color}
              onBlur={onBlur}
              onFocus={onFocus}
              required={required}
              pr={pr}
              max={max}
            />
            <InputLabel color={color} style={labelStyle}>
              {placeholder}
            </InputLabel>
          </div>
        ) : (
          <StyledInput
            type={type}
            name={name}
            id={id}
            value={value}
            placeholder={placeholder}
            onChange={onChange}
            disabled={disabled}
            onKeyDown={onKeyDown}
            color={color}
            required={required}
            onBlur={onBlur}
            placeholderColor={placeholderColor}
            style={inputStyles}
          />
        )}
      </div>
    )}

    {error && <div className="validation-error">{error}</div>}
  </div>
);

export default Input;
