import styled from 'styled-components';
import { Flex, Box } from '@rebass/grid';

export const InvestmentListWrapper = styled.div`
  box-sizing: border-box;
  // background-color: #f2f2f2;
  border-radius: 8px;
  padding: 4px 6px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  margin: 8px;
  max-height: 75%;
  min-height: 75%;
  overflow-x: hidden;
  overflow-y: auto;
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) and (stroke-color: transparent) {
      // background-color: #f2f2f2;
      border-radius: 8px;
      padding: 4px 10px;
      overflow-y: auto;
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px;
    }
  }
`;

export const InvestmentItem = styled(Flex)`
  margin: 5px;
  background-color: #e2e2e2;
  cursor: pointer;
  font-size: 11px;
  width: 100%;
  padding: 4px 5px;
  ${({ selected }) =>
    selected &&
    `
    background-color:#6351ed;
    color: #fff;
    img {
      filter: brightness(3);
    }
    svg {
      color: #fff;
    }
  `}
`;

export const TextContent = styled(Box)`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  text-align: left;
  padding-left: 5px;
  ${({ bold }) => bold && 'font-weight: bold;'};
  font-family: 'Poppins' !important;
  font-weight: 600;
`;

export const EditWrapper = styled.div`
  position: sticky;
  right: 0;
  left: 0;
  bottom: -5px;
  color: #6351ed;
  display: block;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  padding: 2px 60px 0px 2px; ;
`;

export const ButtonIcon = styled.button`
  border: 0px;
  background: transparent;
  color: #6351ed;
  cursor: ${({ disabled }) => (disabled ? '' : 'pointer')};
  ${({ selected }) =>
    selected &&
    `
    color: #fff;
    img {
      filter: brightness(3);
    }
  `}
`;
