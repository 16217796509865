import styled from 'styled-components';
import { ModalBody } from '../../components';

export const ReviewContainer = styled(ModalBody)`
  overflow-x: hidden;
  overflow-y: auto;
  height: 85%;
  padding: 0px 30px;
  &::-webkit-scrollbar {
    width: 5px;
  }
  &::-webkit-scrollbar-track {
    background: #e9f6e6 !important;
  }
  &::-webkit-scrollbar-thumb {
    background: #bccdd4 !important;
  }
`;

export const CampaignFeedbackContainer = styled.textarea`
  text-align: left;
  color: #6351ed;
  width: 100%;
  resize: none;
  overflow-y: auto;
  overflow-x: hidden;
  border: 1px solid #bebebe;
  border-radius: 6px;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  min-height: 55px;
  height: 90px;
  padding: 5px;

  &::placeholder {
    color: #6351ed;
    text-align: center;
  }
  :focus::placeholder {
    color: transparent;
  }
  &::-webkit-scrollbar {
    width: 5px;
  }
  &::-webkit-scrollbar-track {
    background: #e9f6e6 !important;
  }
  &::-webkit-scrollbar-thumb {
    background: #bccdd4 !important;
  }
`;
