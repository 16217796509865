/* eslint-disable react-hooks/exhaustive-deps */
import { Flex } from '@rebass/grid';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Button, LoaderWrapper, Password } from '../../components';
import useQuery from '../../customHooks/useQuery';
import { apiDetails, objectToFormData } from '../../services/helpers';
import { encryptPassword } from '../../utils';
import { FormWrapper } from './ResetPasswordContainer.styles';

export const ResetPasswordContainer = () => {
  const [passwords, setPasswords] = useState({
    newPassword: '',
    newPasswordCon: '',
  });

  const [postPayload, setPostPayload] = useState({
    cloutModule: '',
    payload: '',
  });

  const [isLoading, setIsLoading] = useState(false);

  const [errorMsg, setErrorMsg] = useState('');

  const query = useQuery();

  useEffect(() => {
    let cloutModule = query.get('clout_module');
    let payload = query.get('payload');
    setPostPayload({
      cloutModule,
      payload,
    });
    console.log(cloutModule, payload);
  }, []);

  const handleChange = (e) => {
    setPasswords({ ...passwords, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setErrorMsg('');

    if (passwords.newPassword.length < 8 || passwords.newPassword.length > 15) {
      setErrorMsg('New password must be between 8-15 characters');
    } else if (passwords.newPassword !== passwords.newPasswordCon) {
      setErrorMsg('New password and confirm password should match');
    } else {
      setErrorMsg('');
      setIsLoading(true);
      let payload = {
        new_password: encryptPassword(
          encodeURIComponent(passwords.newPassword).replace(/'/g, '%27')
        ),
        clout_module: postPayload.cloutModule,
        payload: encodeURIComponent(postPayload.payload).replace(/'/g, '%27'),
      };

      axios
        .post(`${apiDetails.baseUrl}callback`, objectToFormData(payload))
        .then((res) => {
          setIsLoading(false);
          if (res.data.result.success) {
            setErrorMsg('New password saved successfully');
            setTimeout(() => {
              window.open('/', '_self');
            }, 2000);
          } else {
            setErrorMsg('Unable to reset the password');
          }
        })
        .catch((err) => {
          setIsLoading(false);
          setErrorMsg('Unable to reset the password');
          console.log(err);
        });
    }
  };

  return (
    <FormWrapper>
      <Flex flexDirection={'column'} width="15vw" margin={'0px auto'}>
        <Flex justifyContent={'center'} margin="10px">
          Reset Password
        </Flex>
        <div style={{ margin: '17px 0px' }}>
          <Password
            placeholder="New Password"
            name="newPassword"
            id="newPassword"
            onChange={handleChange}
            isInputWithFloatingLabel={true}
            value={passwords.newPassword}
          />
        </div>
        <div>
          <Password
            placeholder="Confirm Password"
            name="newPasswordCon"
            id="newPasswordCon"
            onChange={handleChange}
            isInputWithFloatingLabel={true}
            value={passwords.newPasswordCon}
            style={{ width: '15vw' }}
          />
        </div>
        <LoaderWrapper isLoading={isLoading}>
          <Button onClick={handleSubmit} margin="20px 0px">
            Save
          </Button>
        </LoaderWrapper>
      </Flex>
      <Flex justifyContent={'center'} fontSize={'13px'}>
        {errorMsg}
      </Flex>
    </FormWrapper>
  );
};
