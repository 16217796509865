import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Flex } from '@rebass/grid'
import React from 'react'
import { themesData } from '../../../utils'
import { DripTabs, DripVerticalLine } from '../CreateCampaign/CreateCampaign.styles'
import PlanCampaign from '../PlanCampaign/PlanCampaign'
import SetObjectives from '../SetObjectives/SetObjectives'
import SetTopics from '../SetTopics/SetTopics'

const CreatePlanCampaign = ({
    setCreateFlow,
    createFlow,
    createTabs,
    getDescription,
    contactGroups,
    setCreateTabs,
    socialNetworkData,
    campaignSetState,
    campaignCreateFlow,
    setObjectivesTabData,
    selectedThemes,
    themes,
    campaignId,
    trendingTopics,
    mostSubscribedTopics,
    setTopicsTabData,
    getAccountId,
    setCreateScreenIndex,
    createScreenIndex,
    campaignArticles,
    contentWeeks,
    campaignScheduleTimings,
    planCampaignData,
    publishedContent,
    endCreateFlow
}) => {
    return (
        <>
            <Flex 
                width="100%" 
                justifyContent="space-between" 
                alignItems="center"
                backgroundColor="#fff"
                padding="15px 20px"
                style={{ height: '8%', zIndex: 1, borderBottom: '1px solid #6250ED' }}
            >
                <Flex color={themesData.charcoalGrey} fontSize="14px">
                    Create Campaign
                </Flex>
                <Flex>
                    <FontAwesomeIcon 
                        icon={faTimes} 
                        color={themesData.charcoalGrey} 
                        style={{cursor: 'pointer'}}
                        onClick={endCreateFlow}
                    />
                </Flex>
            </Flex>
            <Flex width="100%" height="9%" flexDirection="column">
                <Flex
                    padding="0px 30px"
                    backgroundColor="#fff"
                    height="100%"
                    alignItems="center"
                    width="100%"
                >
                    <Flex
                        width="100%"
                        pb="10px"
                        alignItems="center"
                        style={{ borderBottom: '1px solid #CFD9E4' }}
                    >
                        <Flex justifyContent="space-between" width="25%">
                            <DripTabs active={createTabs === 0} completed={createTabs === 0}>
                                Set Objectives
                            </DripTabs>
                            <DripTabs active={createTabs === 1} completed={createTabs === 1}>
                                Select Topics
                            </DripTabs>
                            <DripTabs active={createTabs === 2} completed={createTabs === 2}>
                                Plan Campaign
                            </DripTabs>
                        </Flex>
                        <Flex width="75%" pl="20px" alignItems="center">
                            <DripVerticalLine />
                            <Flex pl="20px">
                                <span style={{ color: themesData.charcoalGrey, fontSize:"12px" }}>Instruction:</span>
                                <span style={{ color: '#4E6780', paddingLeft: '4px', textAlign: 'left', fontSize: '10px' }}>
                                    {getDescription()}
                                </span>
                            </Flex>
                        </Flex>
                    </Flex>
                </Flex>
            </Flex>
            {createTabs === 0 && (
                <SetObjectives
                    contactGroups={contactGroups}
                    setCreateFlow={setCreateFlow}
                    setCreateTabs={setCreateTabs}
                    socialNetworkData={socialNetworkData}
                    campaignSetState={campaignSetState}
                    campaignCreateFlow={campaignCreateFlow}
                    setObjectivesTabData={setObjectivesTabData}
                    getAccountId={getAccountId}
                    campaignId={campaignId}
                    endCreateFlow={endCreateFlow}
                />
            )}
            {createTabs === 1 && (
                <SetTopics 
                    selectedThemes={selectedThemes}
                    themes={themes}
                    setCreateFlow={setCreateFlow}
                    setCreateTabs={setCreateTabs}
                    campaignId={campaignId}
                    campaignCreateFlow={campaignCreateFlow}
                    campaignSetState={campaignSetState}
                    trendingTopics={trendingTopics}
                    mostSubscribedTopics={mostSubscribedTopics}
                    setTopicsTabData={setTopicsTabData}
                    endCreateFlow={endCreateFlow}
                    setObjectivesTabData={setObjectivesTabData}
                />
            )}
            {createTabs === 2 && (
                <PlanCampaign 
                    setCreateFlow={setCreateFlow}
                    setCreateTabs={setCreateTabs}
                    setObjectivesTabData={setObjectivesTabData}
                    setCreateScreenIndex={setCreateScreenIndex}
                    createScreenIndex={createScreenIndex}
                    campaignSetState={campaignSetState}
                    campaignArticles={campaignArticles}
                    campaignId={campaignId}
                    contentWeeks={contentWeeks}
                    getAccountId={getAccountId}
                    campaignScheduleTimings={campaignScheduleTimings}
                    planCampaignData={planCampaignData}
                    publishedContent={publishedContent}
                    endCreateFlow={endCreateFlow}
                />
            )}
        </>
    )
}

export default CreatePlanCampaign
