/* eslint-disable react-hooks/exhaustive-deps */
import { Flex } from '@rebass/grid';
import React, { useEffect, useRef, useState } from 'react';
import { Button } from '../../../components';
import { postSelectedArticles } from '../../../services/campaign';
import CampaignFooterButtons from '../CampaignFooterButtons/CampaignFooterButtons';
import {
  ContentArticlesContainer,
  SuggestedArticlesTab,
  SummaryInstructionDiv,
} from '../DripCampaignContainer.styles';
import CampaignCard from '../PlanCampaign/CampaignCard';
import { LineHorizontalSeparator } from '../PlanCampaign/PlanCampaign.styles';
import { SelectedArticlesContainer } from '../SelectArticles/SelectArticles.styles';
import { WriteSummaryWrapper } from './WriteSummary.styles';

import { getArticleSummary } from '../../../services/dashboard';
import { get, isEmpty } from 'lodash';

const WriteSummary = ({
  campaignSelectedArticles,
  toggleEnableNext,
  enableNext,
  onAssignContentTabChange,
  campaignSetState,
  campaignId,
  endCreateFlow,
  setAssignContentTab,
  setCreateScreenIndex,
  setObjectivesTabData
}) => {
  const [clickedArticle, setClickedArticle] = useState('');
  const [articleSummaries, setArticleSummaries] = useState([]);
  const [activePlatformTab, setActivePlatformTab] = useState('EMAIL');
  const [useForAll, toggleUseForAll] = useState(false);
  const [showIncomplete, toggleShowIncomplete] = useState(false);
  const [showComplete, toggleShowComplete] = useState(false);
  const [loading, toggleLoading] = useState(false);
  const [bubbleClicked, toggleBubbleClicked] = useState(null);
  const textAreaRef = useRef(null);
  const [summaryDataBlock, setSummaryDataBlock] = useState('');
  const platformListMapper = {
    EMAIL: 'emList',
    FACEBOOK: 'fbList',
    TWITTER: 'twList',
    LINKEDIN: 'lnList'
  }

  useEffect(() => {
    let isComplete = true;
    const articleSummaries = campaignSelectedArticles.map((article) => {
      if (!article.summaries) isComplete = false;
      return {
        ...article,
        summaries: article.summaries
          ? article.summaries
          : {
              EMAIL: '',
              FACEBOOK: '',
              LINKEDIN: '',
              TWITTER: '',
            },
        notNull: article.summaries ? true : false,
      };
    });
    toggleShowComplete(isComplete);
    console.log('ARTICLE SUMMARIES - -- - ', articleSummaries);
    setArticleSummaries(articleSummaries);
    setClickedArticle(0);
    getSummary(0);
  }, []);

  useEffect(() => {
    console.log('BUBBLE CLICKED - - -- ', bubbleClicked);
    if (bubbleClicked && !isEmpty(get(setObjectivesTabData, platformListMapper[bubbleClicked.toUpperCase()], []))) {
      setActivePlatformTab(bubbleClicked.toUpperCase());
      toggleBubbleClicked(null);
    } else setActivePlatformTab('EMAIL');
    toggleEnableNext(clickedArticle === '');
  }, [clickedArticle]);

  useEffect(() => {
    if (clickedArticle !== '') {
      textAreaRef.current.focus();
      // console.log('FOCUS INSIDE IF - - - -');
    }
  }, [activePlatformTab, clickedArticle]);

  useEffect(() => {
    // console.log('BUBBLE CLICKED - - - -', bubbleClicked);
    if (bubbleClicked && !isEmpty(get(setObjectivesTabData, platformListMapper[bubbleClicked.toUpperCase()], []))) {
      setActivePlatformTab(bubbleClicked.toUpperCase());
      toggleBubbleClicked(null);
    }
  }, [bubbleClicked]);

  const articleClickHandler = (index) => {
    setClickedArticle(index);
    toggleShowIncomplete(false);
    getSummary(index);
  };

  const getSummary = (index) => {
    if (campaignSelectedArticles) {
      let id = campaignSelectedArticles[index]?.id;
      let themeId = campaignSelectedArticles[index]?.theme_id;
      setSummaryDataBlock('Loading...');
      getArticleSummary({ articleId: id, themeId })
        .then((response) => {
          const resultData = get(response, 'result.data.summaries');
          const longSummary = get(resultData, 'long[0].summary', '');
          setSummaryDataBlock(longSummary);
        })
        .catch((error) => {
          setSummaryDataBlock('');
        });
    }
  };

  const summaryChangeHandler = (e) => {
    const articleSummariesCopy = [...articleSummaries];
    if (useForAll) {
      let updatedSummaries = {};
      Object.keys(articleSummariesCopy[clickedArticle].summaries).forEach(
        (platform) => {
          updatedSummaries[platform] = e.target.value;
        }
      );
      articleSummariesCopy[clickedArticle].summaries = updatedSummaries;
    } else
      articleSummariesCopy[clickedArticle].summaries[activePlatformTab] =
        e.target.value;

    if (e.target.value) articleSummariesCopy[clickedArticle].notNull = true;
    else {
      const isEmpty = Object.keys(
        articleSummariesCopy[clickedArticle].summaries
      ).every(
        (summary) => !articleSummariesCopy[clickedArticle].summaries[summary]
      );
      articleSummariesCopy[clickedArticle].notNull = !isEmpty;
    }
    console.log('ARTICLE SUMMARIES - - - ', articleSummariesCopy);
    setArticleSummaries(articleSummariesCopy);
  };

  const OKClickHandler = () => {
    console.log('ARTICLE SUMMARIES - - - ', articleSummaries);
    const isComplete = articleSummaries.every((item) => item.notNull);
    if (isComplete) toggleShowComplete(true);
    else toggleShowComplete(false);
    setClickedArticle('');
    toggleEnableNext(true);
  };

  const cancelClickHandler = () => {
    const articleSummariesCopy = [...articleSummaries];
    articleSummariesCopy[clickedArticle].summaries[activePlatformTab] = '';
    const isEmpty = Object.keys(
      articleSummariesCopy[clickedArticle].summaries
    ).every(
      (summary) => !articleSummariesCopy[clickedArticle].summaries[summary]
    );
    articleSummariesCopy[clickedArticle].notNull = !isEmpty;
    const isComplete = articleSummaries.every((item) => item.notNull);
    if (isComplete) toggleShowComplete(true);
    else toggleShowComplete(false);
    setClickedArticle('');
    toggleEnableNext(true);
  };

  const backHandler = () => {
    onAssignContentTabChange('back');
    toggleEnableNext(true);
  };

  const getPayloadObject = () => {
    const bucketArticles = articleSummaries.map((article) => {
      return {
        article_id: article.id,
        theme_id: article.theme_id,
        summary: article.summaries,
      };
    });
    const payloadObj = {
      articles: JSON.stringify(bucketArticles),
      campaign_id: campaignId,
    };
    return payloadObj;
  };

  const nextHandler = (save) => {
    const isComplete = articleSummaries.every((item) => item.notNull);
    if (!isComplete) toggleShowIncomplete(true);
    else {
      toggleLoading(true);
      const payloadObj = getPayloadObject();
      campaignSetState({
        selectedArticles: articleSummaries,
      });

      postSelectedArticles({ payload: payloadObj })
        .then((res) => {
          toggleLoading(false);
          if (save) {
            setAssignContentTab(0);
            endCreateFlow();
          } else {
            toggleEnableNext(false);
            onAssignContentTabChange('next');
          }
        })
        .catch((err) => toggleLoading(false));

      // toggleLoading(false);
      // onAssignContentTabChange('next');
      // toggleEnableNext(false);
    }
  };

  const checkToggleHandler = (checked) => {
    console.log('CHECKED - - - - ', checked);
    if (checked) {
      const articleSummariesCopy = [...articleSummaries];
      let updatedSummaries = {};
      Object.keys(articleSummariesCopy[clickedArticle].summaries).forEach(
        (platform) => {
          updatedSummaries[platform] =
            articleSummariesCopy[clickedArticle].summaries[activePlatformTab];
        }
      );
      articleSummariesCopy[clickedArticle].summaries = updatedSummaries;
      setArticleSummaries(articleSummariesCopy);
    }
    toggleUseForAll(checked);
  };

  const saveAndCloseHandler = () => {
    toggleLoading(true);
    const payloadObj = getPayloadObject();
  };

  return (
    <>
      <WriteSummaryWrapper>
        <ContentArticlesContainer className="selected-articles">
          <span
            style={{
              color: '#000000',
              width: '100%',
              textAlign: 'left',
              fontSize: '14px',
            }}
          >
            <strong>SELECTED CONTENT</strong>
          </span>
          <LineHorizontalSeparator style={{ margin: '10px 0px' }} />
          <Flex justifyContent="center" alignItems="center" width="95%">
            <SelectedArticlesContainer scrollHeight='10px'>
              {articleSummaries.map((article, index) => (
                <CampaignCard
                  {...article}
                  articleClicked={clickedArticle === index}
                  clickHandler={() => articleClickHandler(index)}
                  fromWriteSummary={true}
                  showPlatformBubbles={true}
                  bubbleClickHandler={toggleBubbleClicked}
                  index={index}
                  showIncomplete={showIncomplete && !article.notNull}
                  isClickable={true}
                  content={true}
                  setObjectivesTabData={setObjectivesTabData}
                  platformListMapper={platformListMapper}
                />
              ))}
            </SelectedArticlesContainer>
          </Flex>
        </ContentArticlesContainer>
        <ContentArticlesContainer
          className="platform-summary"
          width="100%"
          style={{
            flexDirection: 'row',
            alignItems: 'flex-start',
            paddingRight: '1px',
            position: 'relative',
            minHeight: '360px',
          }}
        >
          <Flex
            flexDirection="column"
            justifyContent="flex-start"
            width="65%"
            alignItems="flex-start"
          >
            <Flex justifyContent="flex-start" alignItems="center" width="100%">
              {articleSummaries[0] &&
                Object.keys(articleSummaries[0].summaries).map((label) => (
                  <SuggestedArticlesTab
                    onClick={() => setActivePlatformTab(label)}
                    active={activePlatformTab === label}
                    disabled={isEmpty(get(setObjectivesTabData, platformListMapper[label], []))}
                    style={{
                      padding: '2px 35px',
                      fontSize: '15px',
                      height: '35px',
                      pointerEvents: isEmpty(get(setObjectivesTabData, platformListMapper[label], [])) ? 'none' : 'auto'
                    }}
                  >
                    {label}
                  </SuggestedArticlesTab>
                ))}
            </Flex>
            {clickedArticle !== '' && (
              <Flex
                flexDirection="column"
                justifyContent="flex-start"
                alignItems="flex-start"
                width="100%"
                padding="30px 20px"
              >
                <Flex
                  justifyContent="space-between"
                  alignItems="center"
                  width="100%"
                  marginBottom="10px"
                >
                  <span style={{ color: '#000000', fontSize: '14px' }}>
                    <strong>Share Copy</strong>
                  </span>
                  <Flex justifyContent="center" alignItems="stretch">
                    <input
                      id="all"
                      type="checkbox"
                      name="all-platforms-summary"
                      defaultChecked={useForAll}
                      onChange={(e) => checkToggleHandler(e.target.checked)}
                    />
                    <label
                      htmlFor="all"
                      style={{
                        fontSize: '12px',
                        color: '#4E6780',
                        marginRight: '6px',
                      }}
                    >
                      Use for all platforms
                    </label>
                  </Flex>
                </Flex>
                <textarea
                  placeholder="Enter here"
                  style={{
                    width: '100%',
                    height: '120px',
                    borderRadius: '4px',
                    padding: '10px 15px',
                    color: '#4E6780',
                    border: '1px solid #c9d0d8',
                    maxHeight: '190px',
                    maxWidth: '790px',
                  }}
                  onChange={(e) => summaryChangeHandler(e)}
                  value={
                    articleSummaries[clickedArticle].summaries[
                      activePlatformTab
                    ]
                  }
                  ref={textAreaRef}
                  onFocus={(e) =>
                    e.currentTarget.setSelectionRange(
                      e.currentTarget.value.length,
                      e.currentTarget.value.length
                    )
                  }
                />
                <Flex
                  justifyContent="flex-end"
                  alignItems="center"
                  width="100%"
                >
                  <Button
                    bgColor="transparent"
                    color="#4E6780"
                    padding="4px"
                    fontSize="12px"
                    styles={{ border: '0px', marginRight: '10px' }}
                    onClick={cancelClickHandler}
                  >
                    Cancel
                  </Button>
                  <Button
                    bgColor="#4E6780"
                    color="#fff"
                    padding="4px 14px"
                    fontSize="12px"
                    styles={{ borderRadius: '4px', border: '0px' }}
                    onClick={() => OKClickHandler()}
                  >
                    OK
                  </Button>
                </Flex>
              </Flex>
            )}
          </Flex>
          {clickedArticle !== '' && (
            <Flex
              flexDirection="column"
              justifyContent="center"
              alignItems="flex-start"
              width="35%"
              padding="10px 15px 11px"
              marginTop="-10px"
              style={{ borderLeft: '1px solid #CFD9E4' }}
              backgroundColor="#FBFBFB"
            >
              <Flex
                flexDirection="column"
                justifyContent="center"
                alignItems="flex-start"
                className="Extractive Summary"
                marginBottom="10px"
                width="100%"
              >
                <span style={{ color: '#000000', fontSize: '12px' }}>
                  <strong>Summary</strong>
                </span>
                <SummaryInstructionDiv>
                  {summaryDataBlock}
                </SummaryInstructionDiv>
              </Flex>
              {/* <Flex
                flexDirection="column"
                justifyContent="center"
                alignItems="flex-start"
                className="Abstractive Summary"
                width="100%"
              >
                <span style={{ color: '#000000', fontSize: '12px' }}>
                  <strong>Abstractive Summary</strong>
                </span>
                <SummaryInstructionDiv></SummaryInstructionDiv>
              </Flex> */}
            </Flex>
          )}
          {clickedArticle === '' && (
            <span
              style={{
                color: showIncomplete
                  ? '#f55449'
                  : showComplete
                  ? '#4dbc39'
                  : '#BCCDD4',
                fontSize: '12px',
                position: 'absolute',
                top: '49%',
                left: showComplete ? '45%' : '42%',
              }}
            >
              {showIncomplete
                ? 'Some Content Still Needs A Share Copy'
                : showComplete
                ? 'All Content has Share Copy'
                : 'Select a piece of content to write share copy'}
            </span>
          )}
        </ContentArticlesContainer>
      </WriteSummaryWrapper>
      <CampaignFooterButtons
        saveAndCloseHandler={() => nextHandler(true)}
        loading={loading}
        nextHandler={() => nextHandler()}
        enableNext={enableNext}
        backHandler={backHandler}
      />
    </>
  );
};

export default WriteSummary;
