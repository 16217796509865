import styled from 'styled-components';
import { colors } from '../../../styles';

export const OverviewContainer = styled('div')`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

export const PreviewContainer = styled('div')`
  display: table;
  width: calc(100% - 40px);
  margin: 20px;
`;

export const TopContentContainer = styled('div')`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

export const LargeContentContainer = styled('div')`
  width: calc(75% - 10px);
`;

export const GraphContainer = styled('div')`
  width: 100%;
  height: 400px;
  background: ${colors.white};
  border: 1px solid ${colors.gray_border};
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.12);
  border-radius: 8px;
`;

export const StackedChartContainer = styled('div')`
  width: calc(25% - 10px);
  display: table;
  height: 400px;
`;

export const BodyText = styled('p')`
  font-weight: 400;
  font-size: 14px;
  color: ${colors.gray_text};
  width: 95%;
  margin: 10px auto;
  text-align: left;
`;

export const DarkText = styled('p')`
  font-weight: 400;
  font-size: 14px;
  color: ${colors.black_01};
  width: 95%;
  margin: 10px auto;
  text-align: left;
`;

export const SectionTitle = styled('div')`
  margin: 20px 0 0;
  font-weight: 500;
  font-size: 20px;
  text-align: left;
  color: ${colors.black};
`;

export const SectionSubTitle = styled('div')`
  font-weight: 400;
  font-size: 14px;
  text-align: left;
  color: ${colors.black};
  margin: 0px;
`;

export const BodyWrapper = styled('div')`
  display: flex;
  width: 101%;
  text-align: left;
  margin: 5px 0;
  flex-flow: rows;
  padding: 0;
  flex-wrap: wrap;
`;

export const ActionContainer = styled('div')`
  display: flex;
  width: 98.5%;
  justify-content: space-between;
`;

export const FilterIcon = styled('img')`
  height: 24px;
  width: 24px;
  margin: 8px 5px 0 0;
`;

export const FilterButton = styled('div')`
  height: 40px;
  display: flex;
  color: ${colors.black_01};
  background: #ffffff;
  border: 1px solid #e4e7ec;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.12);
  border-radius: 4px;
  margin: 0 0 0 10px;
  cursor: pointer;
  padding: 0 10px;
  transition: 0.5s;
  :hover {
    background: ${colors.hover_background};
  }
`;

export const ContentContainer = styled('div')`
  display: flex;
  justify-content: space-between;
  margin: 25px 10px;
`;

export const ContentBlock = styled('div')`
  display: table;
  width: 49%;
  background: ${colors.gray_background};
  border-radius: 4px;
  padding: 10px;
`;

export const TooltipBody = styled('p')(() => ({
  color: '#D0D5DD',
  font: '400 0.75rem/1rem Poppins,sans-serif',
  margin: 0,
  textAlign: 'left',
}));

export const TooltipStyles = {
  '& .MuiTooltip-tooltip': {
    backgroundColor: '#13095F',
    borderRadius: '0.5rem',
    padding: '0.75rem',
    display: 'flex',
    flexDirection: 'column',
    gap: '4px',
  },
  '& .MuiTooltip-arrow': {
    color: '#13095F',
  },
};
