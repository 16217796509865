import { Flex } from '@rebass/grid';
import React, { useState } from 'react';
import {
  DripIconButton,
  LoaderWrapper,
  MioDatePicker,
} from '../../../components';
import { getMediaUrl, onImageError, themesData } from '../../../utils';
import { OutboxCardWrapper } from './NewsletterOutboxArticle.styles';
import moment from 'moment';
import { isEmpty } from 'lodash';
import { ContactListTooltipWrapper } from '../DraftOutbox/DraftOutbox.styles';

const NewsletterOutboxArticle = ({
  name,
  handleDelete,
  id,
  compliance_status,
  onEditClick,
  isArticleDeleting,
  newsletterId,
  selectedId,
  loading,
  source,
  tabSource,
  time,
  media_urls,
  total_articles,
  getPreviewDetails,
  dateOpen,
  setDateOpen,
  scheduleValue,
  handleDateSubmit,
  rescheduleNewsletter,
  handleOpen,
  isPremiumAdvisor,
  toggleCloneModal,
  newsletter,
  setSelectedNewsletter,
}) => {
  // console.log('COMPLIANCE STATUS - - - - -', compliance_status);
  const [showTooltip, toggleTooltip] = useState(false);

  const cloneBtnHandler = () => {
    setSelectedNewsletter(newsletter);
    toggleCloneModal(true);
  };

  const getName = (name) => {
    if (name) {
      let shortenName = name.length > 22 ? name.substring(0, 22) + '...' : name;
      return shortenName;
    } else {
      return '';
    }
  };

  console.log('IS PREM ADV - - - ', isPremiumAdvisor);

  return (
    <OutboxCardWrapper>
      <Flex style={{ height: '99px', width: '100%' }}>
        <img
          alt="share"
          src={!isEmpty(media_urls) ? getMediaUrl(media_urls) : ''}
          width="100%"
          onError={onImageError}
          style={{
            borderTopLeftRadius: '8px',
            borderTopRightRadius: '8px',
            objectFit: 'cover',
          }}
        />
      </Flex>
      <Flex
        justifyContent="space-between"
        color={themesData.charcoalGrey}
        fontSize="11px"
        padding="5px 5px 1px"
      >
        <Flex>{total_articles} Articles</Flex>
        <Flex style={{ position: 'relative' }} alignItems="baseline">
          {tabSource !== 'Draft' && (
            <DripIconButton
              icon="information"
              className="info-scheduled"
              fontSize="13px"
              onClick={() => handleOpen(id, name, compliance_status)}
            />
          )}
          <img
            src={
              compliance_status === 'rejected' ||
              compliance_status === 'pending'
                ? '/assets/images/clone-solid-disabled.svg'
                : '/assets/images/clone-solid.svg'
            }
            style={{
              width: '12px',
              height: '12px',
              cursor:
                compliance_status === 'rejected' ||
                compliance_status === 'pending'
                  ? 'default'
                  : 'pointer',
              marginRight: '2px',
            }}
            onClick={
              compliance_status === 'rejected' ||
              compliance_status === 'pending'
                ? () => {}
                : () => cloneBtnHandler()
            }
            onMouseEnter={() => {
              if (
                compliance_status === 'rejected' ||
                compliance_status === 'pending'
              )
                toggleTooltip(true);
            }}
            onMouseLeave={() => toggleTooltip(false)}
          />
          {showTooltip && (
            <span
              style={{
                position: 'absolute',
                top: '18px',
                right: '-4px',
                width: '245px',
                padding: '3px 5px',
                borderRadius: '2px',
                backgroundColor: '#49484a',
                color: '#fff',
                fontSize: '10px',
              }}
            >
              You cannot make a clone if compliance review is pending or
              rejected.
            </span>
          )}
        </Flex>
      </Flex>
      <Flex
        padding="0px 5px"
        className="post-desc"
        title={name.length > 22 ? name : ''}
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'baseline',
          height: tabSource === 'Scheduled' ? 'auto' : '',
          maxHeight: tabSource === 'Scheduled' ? '30px' : '',
          minHeight: tabSource === 'Scheduled' ? '20px' : '',
        }}
      >
        {getName(name)}
      </Flex>
      {tabSource === 'Scheduled' && (
        <>
          <Flex
            fontSize="11px"
            style={{ height: '36px' }}
            padding="0px 5px"
            color={themesData.charcoalGrey}
          >
            {moment(time).format('MMMM Do, YYYY')}
          </Flex>
          {!isPremiumAdvisor && (
            <Flex
              fontSize="11px"
              style={{
                height: '36px',
                color: !compliance_status
                  ? '#000'
                  : compliance_status === 'approved'
                  ? '#0097a7'
                  : compliance_status === 'rejected'
                  ? '#ff0000'
                  : '#b86917',
              }}
              padding="0px 5px"
            >
              {!compliance_status
                ? 'N/A'
                : compliance_status === 'approved'
                ? 'Compliance Approved'
                : compliance_status === 'rejected'
                ? 'Compliance Rejected'
                : 'Compliance Pending'}
            </Flex>
          )}
        </>
      )}
      {tabSource === 'Sent' ? (
        <>
          {!isPremiumAdvisor && (
            <Flex
              fontSize="11px"
              style={{
                height: '36px',
                color: !compliance_status ? '#000' : '#0097a7',
              }}
              padding="0px 5px"
            >
              {!compliance_status ? 'N/A' : 'Compliance Approved'}
            </Flex>
          )}
          <Flex
            alignItems="baseline"
            width="100%"
            justifyContent="space-between"
            padding="5px"
          >
            <Flex width="70%" fontSize="9px" color={themesData.charcoalGrey}>
              {moment(time).format('MMMM Do YYYY | h:mm A')}
            </Flex>
            <Flex
              width="30%"
              justifyContent="space-between"
              alignItems="baseline"
            >
              <Flex width="50%" justifyContent="center">
                <LoaderWrapper
                  isLoading={selectedId === id && loading && source === ''}
                  styles={{ position: 'relative', top: '9px' }}
                >
                  <img
                    src="/assets/images/Icon-eye.svg"
                    style={{
                      cursor: 'pointer',
                      position: 'relative',
                      top: '3px',
                      height: '15px',
                    }}
                    alt=""
                    onClick={() => getPreviewDetails(id)}
                  />
                </LoaderWrapper>
              </Flex>
              <Flex width="50%" justifyContent="center">
                <LoaderWrapper
                  isLoading={selectedId === id && loading && source === 'edit'}
                  styles={{ position: 'relative', top: '9px' }}
                >
                  <img
                    src="/assets/images/Sent_Icon.svg"
                    style={{
                      cursor: 'pointer',
                      position: 'relative',
                      top: '3px',
                      height: '15px',
                    }}
                    alt=""
                    onClick={() =>
                      onEditClick(id, null, null, null, compliance_status)
                    }
                  />
                </LoaderWrapper>
              </Flex>
            </Flex>
          </Flex>
        </>
      ) : (
        <Flex
          alignItems="center"
          width="100%"
          justifyContent="space-between"
          padding="5px"
          style={{ maxHeight: '27px' }}
        >
          <Flex width={tabSource === 'Scheduled' ? '75%' : '80%'}>
            <LoaderWrapper
              isLoading={isArticleDeleting && newsletterId === id}
              styles={{
                justifyContent: 'flex-start',
                position: 'relative',
                top: '3px',
              }}
            >
              <div
                className={`icon dripicons-trash`}
                style={{
                  cursor: 'pointer',
                  height: '17px',
                  position: 'relative',
                  top: tabSource === 'Scheduled' ? '-2px' : '',
                }}
                onClick={() => handleDelete(id)}
              ></div>
            </LoaderWrapper>
          </Flex>
          <Flex
            width={tabSource === 'Scheduled' ? '25%' : '20%'}
            justifyContent="space-between"
            paddingRight={tabSource === 'Scheduled' ? '5px' : '0px'}
          >
            {tabSource === 'Scheduled' && (
              <LoaderWrapper
                isLoading={source === 'Preview' && selectedId === id && loading}
                styles={{
                  justifyContent: 'flex-start',
                  position: 'relative',
                  top: '3px',
                }}
              >
                <img
                  src="/assets/images/Icon-eye.svg"
                  style={{ cursor: 'pointer' }}
                  alt=""
                  onClick={() => getPreviewDetails(id)}
                />
              </LoaderWrapper>
            )}
            <LoaderWrapper
              isLoading={source === 'Edit' && selectedId === id && loading}
              styles={{ position: 'relative', top: '3px' }}
            >
              <img
                alt=""
                src={
                  compliance_status === 'pending'
                    ? '/assets/images/edit_Icon-disabled.svg'
                    : '/assets/images/edit_Icon.svg'
                }
                style={{
                  cursor:
                    compliance_status === 'pending' ? 'not-allowed' : 'pointer',
                  position: 'relative',
                  pointerEvents:
                    compliance_status === 'pending' ? 'none' : 'auto',
                }}
                onClick={() =>
                  onEditClick(
                    id,
                    tabSource === 'Scheduled' ? 4 : 0,
                    'Edit',
                    time,
                    compliance_status
                  )
                }
              />
            </LoaderWrapper>
            {tabSource !== 'Scheduled' && (
              <LoaderWrapper
                isLoading={source === 'Send' && selectedId === id && loading}
                styles={{
                  position: 'relative',
                  top: '3px',
                  justifyContent: 'flex-end',
                }}
              >
                <img
                  alt=""
                  src={
                    compliance_status === 'pending'
                      ? '/assets/images/Sent_Icon-disabled.svg'
                      : '/assets/images/Sent_Icon.svg'
                  }
                  style={{
                    pointerEvents:
                      compliance_status === 'pending' ? 'none' : 'auto',
                    cursor:
                      compliance_status === 'pending'
                        ? 'not-allowed'
                        : 'pointer',
                  }}
                  onClick={() => onEditClick(id, 4, 'Send', time, 'drafts')}
                />
              </LoaderWrapper>
            )}
          </Flex>
        </Flex>
      )}
    </OutboxCardWrapper>
  );
};

export default NewsletterOutboxArticle;
