import { Flex } from '@rebass/grid'
import React from 'react'
import { ColorPallete, Typography } from '../../../utils'
import { Button } from '../../../components';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import MemoLeftArrow from 'src/assets/icons/LeftArrow';
import MemoRightArrow from 'src/assets/icons/RightArrow';


const AnalyticsTable = ({
    headers,
    data,
    onPageChange,
    maxPage,
    loading,
    paginationData,
    page,
    onPageClick,
    children,
    pageIndex,
    setPageIndex,
    handleNextClick,
    handlePreviousClick,
    left,
    mr
}) => {
    return (
        <Flex width="100%" flexDirection="column">
            <Flex
                width="100%"
                alignItems="center"
                style={{
                    borderTop: `1px solid ${ColorPallete.neutral200}`,
                    borderBottom: `1px solid ${ColorPallete.neutral200}`
                }}
                backgroundColor={ColorPallete.neutral50}
            >
                {headers.map((item, index) => (
                    <Flex padding="16px" fontSize={Typography.TextLarge} color={ColorPallete.neutral900} width={item.width}>
                        {item.name}
                    </Flex>
                ))}
            </Flex>
            {loading && (
                <SkeletonTheme>
                    <Skeleton height={49} count={10} baseColor={ColorPallete.neutral100} />
                </SkeletonTheme>
            )}
           
            
           {children}


            <Flex
                width="100%"
                justifyContent="space-between"
                padding="16px"
                alignItems="center"
                style={{
                    border: `1px solid ${ColorPallete.neutral200}`
                }}
            >
                <Button
                    margin="0px"
                    bgColor={ColorPallete.white}
                    color={ColorPallete.neutral900}
                    borderRadius="4px"
                    borderColor={ColorPallete.neutral200}
                    onClick={() => onPageChange('prev')}
                    disabled={loading || !paginationData?.has_previous_page}
                    styles={{
                        display: 'flex',
                        alignItems: 'center',
                        boxShadow: '0px 1px 2px 0px #1018281F'
                    }}
                >
                    <MemoLeftArrow height="24px" width="24px" style={{
                        margin: '0px 4px 0px 0px'
                    }}/>
                    Previous
                </Button>
                <Flex width="33%" alignItems="center" style={{ position: 'relative', left: left || '10%' }}>
                    {loading && <Skeleton width={200} height={21} baseColor={ColorPallete.neutral100}/>}
                    <Flex width="5%" style={{ position: 'relative', right: '5%'}}>
                        {pageIndex > 0 && !loading && (
                            <img src="/assets/images/Analytics/ChevronLeft.svg" alt="" style={{ cursor: 'pointer' }} onClick={handlePreviousClick}/>
                        )}
                    </Flex>
                    
                    <Flex width="90%" justifyContent="center" marginRight={mr || "55%"}>
                        {maxPage > 0 && !loading && Array.from({ length: maxPage }, (_, i) => i + 1).slice(pageIndex, pageIndex + 8).map((item, index) => (
                            <Flex width="10px" onClick={() => onPageClick(item)} color={item === page ? ColorPallete.neutral900 : ColorPallete.neutral600} fontSize={Typography.TextMedium} padding="0px 16px" style={{ cursor: 'pointer' }}>
                                {item}
                            </Flex>
                        ))}
                    </Flex>
                    <Flex width="5%">
                        {pageIndex < maxPage && maxPage > 8 && !loading && (
                            <img src="/assets/images/Analytics/ChevronRight.svg" alt="" style={{ cursor: 'pointer' }} onClick={handleNextClick} />
                        )}
                    </Flex>
                </Flex>

                <Button
                    margin="0px"
                    bgColor={ColorPallete.white}
                    color={ColorPallete.neutral900}
                    borderRadius="4px"
                    borderColor={ColorPallete.neutral200}
                    disabled={loading || !paginationData?.has_next_page}
                    styles={{
                        display: 'flex',
                        alignItems: 'center',
                        boxShadow: '0px 1px 2px 0px #1018281F'
                    }}
                    onClick={() => onPageChange('next')}
                >
                    Next
                    <MemoRightArrow height="24px" width="24px" style={{ margin: '0px 0px 0px 4px' }}/>
                </Button>
            </Flex>

        </Flex>
    )
}

export default AnalyticsTable