import React from 'react';
import { ToggleContainer, ToggleButtonStyle } from './styles';
import { Flex } from '@rebass/grid';

const ToggleBar = ({
  toggleItems,
  activeToggle,
  toggleClick,
  margin,
  showCount,
  totalCount,
}) => {
  return (
    <ToggleContainer size="small" aria-label="Small sizes" margin={margin}>
      {toggleItems.map((toggle, index) => {
        return (
          <ToggleButtonStyle
            key={index}
            onClick={() => toggleClick(toggle.key)}
            activeToggle={activeToggle === toggle.key}
          >
            {toggle.name}
            {showCount && (totalCount || totalCount === 0) && (
              <Flex
                margin="0px 0px 0px 10px"
                justifyContent="center"
                style={{
                  borderRadius: '20px',
                  border: '1px solid #816EF8',
                  backgroundColor: '#816EF8',
                  color: '#ffffff',
                  height: '34px',
                  width: '34px',
                  alignItems: 'center',
                }}
              >
                {totalCount?.[toggle?.key] <= 99 ? totalCount?.[toggle?.key] : '99+'}
              </Flex>
            )}
          </ToggleButtonStyle>
        );
      })}
    </ToggleContainer>
  );
};

export default ToggleBar;
