import React from 'react';
import { Link } from 'react-router-dom';
import { get } from 'lodash';
import { Flex, Box } from '@rebass/grid';

import { Button, LayoutInitialWrapper } from '../';
import {
  BlockScreenTitle,
  BlockScreenDescription,
  ContentImg,
  ContentTitle,
  SubscriptionCost,
  ContentDesc,
} from './BlockScreen.styles';
import { subscriptionScreenContent } from '../../utils';

const BlockSreen = ({ screen = 'impact' }) => {
  const title = get(subscriptionScreenContent, `${screen}.title`);
  const descLine1 = get(
    subscriptionScreenContent,
    `${screen}.descriptionLine1`
  );
  const descLine2 = get(
    subscriptionScreenContent,
    `${screen}.descriptionLine2`
  );
  const content = get(subscriptionScreenContent, `${screen}.content`, []);
  return (
    <LayoutInitialWrapper>
      <Flex
        width={8 / 10}
        justifyContent="center"
        alignItems="center"
        mx="auto"
      >
        <Box width={1}>
          <BlockScreenTitle>{title}</BlockScreenTitle>
          <BlockScreenDescription>
            {descLine1 && descLine1}
            {descLine2 && (
              <>
                <br />
                {descLine2}
              </>
            )}
          </BlockScreenDescription>
          <Flex width={1}>
            {content.map((item) => {
              return (
                <Box width={1 / 2}>
                  <div>
                    <ContentImg src={item.imgUrl} />
                  </div>
                  {item.title && <ContentTitle>{item.title}</ContentTitle>}
                  {item.description && (
                    <ContentDesc>{item.description}</ContentDesc>
                  )}
                </Box>
              );
            })}
          </Flex>

          <Box>
            <Link to="/subscription">
              <Button isSecondary>Subscribe</Button>
            </Link>
            <SubscriptionCost>$100 per month.</SubscriptionCost>
          </Box>
        </Box>
      </Flex>
    </LayoutInitialWrapper>
  );
};

export default BlockSreen;
