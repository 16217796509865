import { Flex } from '@rebass/grid';
import React from 'react';
import { VerticalSeparator } from '../DripDashboardListItem/DripDashboardListItem.styles';
import moment from 'moment';

const CampaignInfo = ({
  title,
  start_date,
  duration,
  frequency,
  target,
  status,
  campaignDetailsView,
  campaignDetailsRequest,
  setDetailsView,
  id,
  detailsHandler,
}) => {
  const detailsLabel = ['Start Date', 'Duration', 'Frequency', 'Target'];
  const details = {
    startDate: start_date ? moment(start_date).format('DD MMM YYYY') : '-',
    duration: duration ? duration.join(' ') : '-',
    frequency: frequency ? frequency.join(' ') : '-',
    target: target ? target.charAt(0).toUpperCase() + target.slice(1) : '-',
  };

  return (
    <Flex
      flexDirection="column"
      justifyContent="center"
      alignItems="flex-start"
      marginLeft="15px"
      marginBottom="5px"
      width={campaignDetailsView ? '100%' : '27%'}
      onClick={() => {
        campaignDetailsRequest({ id: id });
        setDetailsView(true);
        detailsHandler(id);
      }}
      style={{
        cursor: 'pointer',
        paddingRight: '10px',
        wordBreak: 'break-word',
      }}
    >
      <span
        style={{
          fontSize: '18px',
          color: '#000000',
          fontWeight: '600',
          textAlign: 'left',
          wordWrap: 'break-word',
          width: '-webkit-fill-available',
        }}
      >
        {/* {(status.charAt(0).toUpperCase() + status.slice(1)).replace('-', ' ')}{' '}
        Campaign -  */}
        {title}
      </span>
      <Flex justifyContent="center" alignItems="center" marginTop="5px">
        {Object.keys(details).map((ele, index) => (
          <React.Fragment key={index}>
            <Flex
              flexDirection="column"
              justifyContent="center"
              alignItems="flex-start"
            >
              <span
                style={{
                  color: '#4E6780',
                  fontSize: '12px',
                }}
              >
                {detailsLabel[index]}
              </span>
              <span
                style={{
                  color: '#000000',
                  fontSize: '13px',
                  fontWeight: '600',
                }}
              >
                {details[ele]}
              </span>
            </Flex>
            {index !== detailsLabel.length - 1 && <VerticalSeparator />}
          </React.Fragment>
        ))}
      </Flex>
    </Flex>
  );
};

export default CampaignInfo;
