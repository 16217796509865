import styled from 'styled-components';
import ArticleList from '../ArticleList/ArticleList';

export const TableWrapper = styled.div`
  max-height: 47vh;
  overflow-x: hidden;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 5px;
  }
  &::-webkit-scrollbar-track {
    background: #e9f6e6 !important;
  }
  &::-webkit-scrollbar-thumb {
    background: #bccdd4 !important;
  }
`;

export const TableContainer = styled.table`
  width: 100%;
  max-width: 100%;
  margin-bottom: 0;
  border-spacing: 0;
  border-style: hidden;
  border: 1px solid #d9d9d9;
`;

export const thead = styled.thead`
  color: #49484a !important;
  font-weight: normal;
  background-color: #ececec;
  border-bottom: 1px solid #e1e1e1;
`;
export const smallTh = styled.th`
  text-align: center !important;
  vertical-align: bottom;
  padding: 8px;
  line-height: 1.42857143;
  position: static;
  display: table-cell;
  float: none;
  width: ${(props) => (props.width ? props.width : '8.33333333%')};
  min-height: 1px;
  font-weight: bold;
  font-size: 12px;
  text-align: center;
  padding-left: ${(props) => props.paddingLeft && props.paddingLeft};
`;
export const bigTh = styled.th`
  text-align: center !important;
  vertical-align: bottom;
  padding: 8px;
  line-height: 1.42857143;
  position: static;
  display: table-cell;
  float: none;
  margin-left: 8.33333333%;
  width: 10%;
  min-height: 1px;
  font-weight: bold;
  font-size: 12px;
  text-align: center;
`;
export const equalTh = styled.th`
  text-align: center !important;
  vertical-align: bottom;
  padding: 8px;
  line-height: 1.42857143;
  position: static;
  display: table-cell;
  float: none;
  margin-left: 16.66666667%;
  width: 25%;
  min-height: 1px;
  font-weight: bold;
  font-size: 12px;
  text-align: center;
`;
export const tds = styled.td`
  text-align: center;
  font-size: 11px;
  padding: 5px 2px;
  vertical-align: middle;
`;

export const trow = styled.tr`
  &:nth-child(even) {
    background-color: #f7f7f7;
  }
  &:nth-child(odd) {
    background-color: #ffffff;
  }
  img {
    filter: grayscale(1);
  }
`;

export const IconDiv = styled.div`
  cursor: pointer;
  top: 10px;
  right: 10px;
  font-size: 15px;
  font-weight: 800;
  display: inline;
  color: #737373;
  padding: 0px 5px;
`;
export const TrendingIcon = styled(IconDiv)`
  width: 20px;
`;

export const paginator = styled.div`
  margin-top: 7px;
`;
export const paginatorPage = styled.div`
  display: inline-block;
  color: #757575;
  padding: 5px 8px;
  cursor: pointer;
  font-size: 14px;
  line-height: 1.42857143;

  &.active {
    background-color: #6351ed;
    color: #fff !important;
    border-radius: 5px;
    margin-right: 5px;
  }
`;
export const pagibutton = styled.button`
  text-decoration: none;
  padding: 3px 10px;
  border-radius: 5px;
  background-color: #eee !important;
  border: 1px solid #a0a0a0 !important;
  color: #6351ed !important;
  font-size: 12px;
  float: right;
`;

export const ArticleListComp = styled(ArticleList)`
  width: 70%;
`;

export const SelectContainer = styled.div`
  z-index: 20;
`;

export const SelectBox = styled.div`
  z-index: 20;
  position: absolute;
  // margin-top: 10px;
  right: 0;
  // margin-right: 20px;
  display: flex;
`;

export const CalendarIconContainer = styled.div`
  width: 25px;
  height: 23px;
  background: #6351ed;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const HeaderContainer = styled.div`
  display: flex;
  justiy-content: center;
  align-items: center;
`;

export const HeaderSpan = styled.span`
  margin-right: 4px;
`;

export const LoaderContainer = styled.div`
  width: 100%;
  text-align: center;
`;

export const ShowAllButtonContainer = styled.div`
  width: 100%;
  display: inline-block;
`;
export const ShowAllButton = styled.button`
  width: 40px;
  background-color: #ffffff;
  padding: 4px 10px;
  border-radius: 5px;
  color: #6351ed;
  border: 1px solid #929292;
  cursor: pointer;
`;

export const NoArticlesMsg = styled.div`
  width: 100%;
  font-size: 13px;
  color: #6351ed;
`;
