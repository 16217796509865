import styled from 'styled-components';

export const FilterChips = styled.div.attrs({
  className: 'chip',
})`
  display: inline-block;
  padding: 0px 10px;
  /* height: 50px; */
  font-size: 12px;
  line-height: 30px;
  border-radius: 25px;
  background-color: ${({ bgColor }) => bgColor || '#fff'};
  color: ${({ color }) => color || ''};
  border: 1px solid #6250ed;
  margin: 5px;

  .chip img {
    float: left;
    margin: 0 10px 0 -25px;
    height: 50px;
    width: 50px;
    border-radius: 50%;
  }

  .closebtn {
    /* padding-left: 10px; */
    color: red;
    font-weight: bold;
    /* float: right; */
    font-size: 12px;
    cursor: pointer;
    border: 1px solid #6250ed;
    border-radius: 72px;
    padding: 0px 4px;
    margin: 3px;
    /* margin-top: 9px; */
    /* height: 69px;*/
  }
`;

export const RadioInput = styled.input`
  border: 2px solid white;
  box-shadow: 0 0 0 1px #aaa;
  appearance: none;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  background-color: #fff;
  transition: all ease-in 0.2s;

  &:checked {
    background-color: #6351ed;
    box-shadow: 0 0 0 1px #6351ed;
  }
`;

export const RadioInputLabel = styled.label`
  color: #000;
  font-size: 14px;
  margin-top: 5px;
  margin-left: 5px;
`;
