import React, { useState, useEffect } from 'react';

import { Flex, Box } from '@rebass/grid';

import {
  CreateContainer,
  CreateContainerCards,
  TitleSpan,
  CardText,
  CardTitle,
  CardSubtitle,
  TopicsParent,
} from './Survey.styles';
import { Button, LoaderWrapper } from '..';
// import { Topics } from './dummyData';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { find, isEmpty, remove, get } from 'lodash';
import CustomTopicPopup from './CustomTopicPopup';
import { getMediaUrl } from '../../utils';

const SurveyTopics = ({
  nextHandler,
  setSelectedTopics,
  selectedTopics,
  topics,
  customTopicName,
  setCustomTopicName,
  setButtonLoader,
  buttonLoader,
}) => {
  const [customTopic, setCustomTopic] = useState(false);

  const TopicClick = (item) => {
    let newTopicsArray;
    if (find(selectedTopics, ['id', item.id])) {
      let tmpArray = remove(selectedTopics, (data) => data.id == item.id);
      // console.log('r', tmpArray);
      // console.log('selectedTopicsrm', selectedTopics);
      if (item.id == 'custom') setCustomTopicName('');
      newTopicsArray = [...selectedTopics];
    } else {
      newTopicsArray = [...selectedTopics, item];
    }

    // console.log('newTopicsArray', newTopicsArray);
    setSelectedTopics(newTopicsArray);
  };

  return (
    <Flex justifyContent="center" alignItems="center">
      <CreateContainer>
        <TitleSpan>
          When Thinking About Your Financial Well-Being And <br />
          Investments, What Do you Care About?
        </TitleSpan>
        <CardSubtitle>Select Up To 5</CardSubtitle>
        <TopicsParent>
          <Flex
            display="flex"
            flexDirection="row"
            justifyContent="center"
            alignItems="center"
            flexWrap="wrap"
            width="960px"
            margin="20px"
            data-testid="pr_topics"
          >
            {topics.map((item, index) => {
              return (
                <CreateContainerCards
                  data-testid={'pr_topics_' + index}
                  bgImage={getMediaUrl(get(item, 'media_urls'))}
                  onClick={
                    (selectedTopics && selectedTopics.length < 5) ||
                    find(selectedTopics, ['id', item.id])
                      ? () => {
                          TopicClick(item);
                        }
                      : () => {}
                  }
                  style={{
                    cursor:
                      (selectedTopics && selectedTopics.length < 5) ||
                      find(selectedTopics, ['id', item.id])
                        ? 'pointer'
                        : '',
                  }}
                  selected={find(selectedTopics, ['id', item.id])}
                >
                  {find(selectedTopics, ['id', item.id]) && (
                    <img
                      alt="tick"
                      src="/assets/images/feather-check-circle.svg"
                      height="19px"
                      style={{
                        position: 'absolute',
                        top: '8px',
                        right: '8px',
                      }}
                    />
                  )}

                  <CardText>
                    <CardTitle selected={find(selectedTopics, ['id', item.id])}>
                      {item.name && item.name.length > 20 ? (
                        <div title={item.name}>
                          {item.name.substring(0, 20)}
                          ...
                        </div>
                      ) : (
                        item.name
                      )}
                      {/* {item.name} */}
                    </CardTitle>
                  </CardText>
                </CreateContainerCards>
              );
            })}
            {/* <CreateContainerCards
              onClick={
                (selectedTopics && selectedTopics.length < 5) ||
                find(selectedTopics, ['id', 'custom'])
                  ? () => {
                      TopicClick({
                        id: 'custom',
                        topic: 'Custom',
                      });
                      if (customTopicName == '') setCustomTopic(true);
                    }
                  : () => {}
              }
              style={{
                cursor:
                  (selectedTopics && selectedTopics.length < 5) ||
                  find(selectedTopics, ['id', 'custom'])
                    ? 'pointer'
                    : '',
              }}
              selected={find(selectedTopics, ['id', 'custom'])}
            >
              <CardText>
                <CardTitle selected={find(selectedTopics, ['id', 'custom'])}>
                  {customTopicName != '' ? (
                    customTopicName && customTopicName.length > 20 ? (
                      <div title={customTopicName}>
                        {customTopicName.substring(0, 20)}
                        ...
                      </div>
                    ) : (
                      customTopicName
                    )
                  ) : (
                    'Other'
                  )}
                </CardTitle>
              </CardText>
            </CreateContainerCards> */}
            <CreateContainerCards
              data-testid={'pr_topics_custom'}
              onClick={
                customTopicName == ''
                  ? () => {
                      setCustomTopic(true);
                    }
                  : () => {
                      setCustomTopicName('');
                    }
              }
              style={{
                cursor: 'pointer',
              }}
              selected={customTopicName != ''}
            >
              <CardText>
                {customTopicName != '' && (
                  <img
                    alt="tick"
                    src="/assets/images/feather-check-circle.svg"
                    height="19px"
                    style={{
                      position: 'absolute',
                      top: '8px',
                      right: '8px',
                    }}
                  />
                )}
                <CardTitle selected={customTopicName != ''}>
                  {customTopicName != '' ? (
                    customTopicName && customTopicName.length > 20 ? (
                      <div title={customTopicName}>
                        {customTopicName.substring(0, 20)}
                        ...
                      </div>
                    ) : (
                      customTopicName
                    )
                  ) : (
                    'Other'
                  )}
                </CardTitle>
              </CardText>
            </CreateContainerCards>
          </Flex>
        </TopicsParent>
        <Flex style={{ marginBottom: '15px' }}>
          <LoaderWrapper isLoading={buttonLoader}>
            <Button
              borderRadius="5px"
              onClick={nextHandler}
              disabled={isEmpty(selectedTopics)}
              testId="topics_next"
              bgColor="#6250ED"
              borderColor="#6250ED"
              width="126px"
              height="40px"
              fontSize="14px"
            >
              Next
              <FontAwesomeIcon
                icon={faChevronRight}
                size="0.5x"
                style={{ marginLeft: '7px', marginTop: '3px' }}
              />
            </Button>
          </LoaderWrapper>
        </Flex>
      </CreateContainer>
      {customTopic && (
        <CustomTopicPopup
          handleClose={() => {
            setCustomTopic(false);
          }}
          setCustomTopicName={setCustomTopicName}
          customTopicName={customTopicName}
          // TopicClick={() => {
          //   TopicClick({
          //     id: 'custom',
          //     topic: 'Custom',
          //   });
          // }}
        />
      )}
    </Flex>
  );
};

export default SurveyTopics;
