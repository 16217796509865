import * as React from 'react';

function Pencil(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 16 17" fill="none" {...props}>
      <path
        d="M5.794 14H3a.5.5 0 01-.5-.5v-2.794a.494.494 0 01.144-.35l7.5-7.5a.5.5 0 01.712 0l2.788 2.788a.5.5 0 010 .712l-7.5 7.5a.492.492 0 01-.35.144v0zM8.5 4.5L12 8"
        stroke="currentColor"
        strokeWidth={1.4}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

const MemoPencil = React.memo(Pencil);
export default MemoPencil;
