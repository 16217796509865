import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Flex } from '@rebass/grid';
import { get } from 'lodash';
import React, { useState } from 'react'
import { connect } from 'react-redux';
import { Button, LoaderWrapper, Modal, ModalBody, ModalCloseButton, ModalHeader } from '../../../components';
import { exportToCsv } from '../../../services/analytics';
import { themesData } from '../../../utils';
import { BottomBtns } from '../../ExportPDFContainer/ExportPDF.styles';
import { CCInput } from '../../NewsletterContainer/Recipients/Recipients.styles';

const ExportToCsv = ({
    handleClose,
    isOpen,
    title,
    userData,
    selectedRepType,
    startDate,
    endDate,
    selectedContacts,
    analyticsObject
}) => {
    const [loading, setLoading] = useState(false);
    const [invalidEmailMsg, setInvalidEmailMsg] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const [emailList, setEmailList] = useState([]);
    const [isSubmit, setIsSubmit] = useState(false);

    const validateEmails = (value) => {
        setEmailList(value);
        const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,3}))$/;
        const isRecipientsValid = value
            .split(',')
            .map((email) => email.trim())
            .every((em) => pattern.test(em.toLowerCase()));
        // const validRecipients = recipients.filter((recipient) =>
        //   pattern.test(recipient)
        // );
        if (value.length === 0) {
            setErrorMsg('');
            setInvalidEmailMsg(false);
        } else if (!isRecipientsValid) {
            setInvalidEmailMsg(true);
            setErrorMsg('Invalid Email Address');
        } else {
            setInvalidEmailMsg(false);
            setErrorMsg('');
        }
    };

    const handleSubmit = () => {
        setLoading(true);
        setErrorMsg('');
        setInvalidEmailMsg(false);
        exportToCsv({
            repType: selectedRepType, 
            startDate, 
            endDate, 
            contact_list: selectedContacts, 
            filters: analyticsObject.filterParams,
            user_emailids: JSON.stringify(
                emailList.length > 0
                  ? emailList.split(',').map((email) => email.trim())
                  : []
              ), 
        }).then((res) => {
            setLoading(false);
            setIsSubmit(true);
            setTimeout(() => {
              handleClose();
            }, 3000);
        }).catch(err => {
            setLoading(false);
            setInvalidEmailMsg(true);
            setErrorMsg('Something went wrong!');
            console.log(err);
        })
    }

    return (
        <Modal
            bgColor="white"
            size="medium"
            onRequestClose={handleClose}
            shouldCloseOnOverlayClick
            isOpen={isOpen}
        >
            <ModalHeader
                id="header"
                style={{ textAlign: 'left', color: themesData.charcoalGrey }}
            >
                <div data-testid="overview-exportToCsv-modal">Campaign Analytics</div>
                <ModalCloseButton onClick={handleClose} />
                <hr style={{ width: '100%' }} />
            </ModalHeader>
            <ModalBody
                style={{
                    height: '480px',
                    overflowX: 'hidden',
                    overflowY: 'auto',
                    color: 'black',
                }}
            >
                {isSubmit ? (
                    <Flex
                        alignItems="center"
                        justifyContent="center"
                        width="100%"
                        marginTop="18%"
                    >
                        <div>
                            <FontAwesomeIcon icon={faCheckCircle} color="#6351ed" size="3x" />
                        </div>
                        <div style={{ marginLeft: '2%', color: '#6351ed' }}>
                            Success! Your report will be sent shortly.
                        </div>
                    </Flex>
                ) : (
                    <div>
                        <Flex flexDirection="column">
                            <div
                                style={{
                                    fontSize: '1.8rem',
                                    color: '#757e83',
                                    marginBottom: '10px',
                                }}
                            >
                                {title}
                            </div>
                            <div style={{ color: '#9da4a9', marginBottom: '20px' }}>
                                Excel CSV report detailing campaign metrics
                            </div>
                            <Flex
                                flexDirection="column"
                                p="15px"
                                backgroundColor="#f5f6fa"
                                style={{ border: '1px solid #e2e4e7', borderRadius: '5px' }}
                            >
                                <div
                                    style={{
                                        margin: '10px auto',
                                        fontSize: '0.8rem',
                                        padding: '5px',
                                        color: '#9da4a9',
                                    }}
                                >
                                    The report will be sent to{' '}
                                    {get(userData, 'details.user.email', 'advisor_email_address')}
                                    . List any additional email addresses to send it to.
                                </div>
                                <Flex
                                    width="100%"
                                    flexDirection="column"
                                    fontSize="14px"
                                    alignItems="center"
                                    style={{ fontWeight: 600 }}
                                    color={themesData.charcoalGrey}
                                >
                                    <div
                                        style={{
                                            color: '#6351ed',
                                            fontSize: '0.7rem',
                                            fontWeight: '500',
                                        }}
                                    >
                                        {errorMsg}
                                    </div>
                                    <CCInput
                                        value={emailList}
                                        spellCheck="false"
                                        id="email_field"
                                        name="email_field"
                                        onChange={(e) => validateEmails(e.target.value)}
                                        placeholder="Type in email address(es), separated by commas"
                                        style={{
                                            width: '80%',
                                            backgroundColor: 'white',
                                            color: 'black',
                                        }}
                                    />
                                </Flex>
                                <div style={{ marginTop: '25px', fontSize: '1rem' }}>
                                    Please allow up to 10 minutes for report generation
                                </div>
                            </Flex>
                        </Flex>
                        <Flex justifyContent="center">
                            <BottomBtns>
                                <LoaderWrapper isLoading={loading}>
                                    <Button
                                        testId="campaign-sendReport-pdf"
                                        disabled={invalidEmailMsg}
                                        borderColor={themesData.purpleishBlue}
                                        bgColor="white"
                                        color={themesData.purpleishBlue}
                                        borderRadius="5px"
                                        onClick={handleSubmit}
                                    >
                                        Send Report
                                    </Button>
                                </LoaderWrapper>
                            </BottomBtns>
                        </Flex>
                    </div>
                )}
            </ModalBody>
        </Modal>
    )
}

const mapStateToProps = (state) => ({
    userData: state.user.data,
});

export default connect(mapStateToProps, null)(ExportToCsv);