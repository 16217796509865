import React from 'react';
import ReactSkeleton from 'react-loading-skeleton';
import { ContentWrapper, PillSkeleton } from '../styles';
import {
  Article,
  ArticleContainer,
  ArticleEngagementContainer,
  ArticleIcon,
  ArticleInfoContainer,
  ContentContainer,
  EngagementScoreContainer,
  Pills,
} from './styles';

function Skeleton() {
  return (
    <ContentWrapper>
      {Array.from(Array(4).keys()).map((item) => (
        <ContentContainer key={item}>
          <ArticleContainer>
            <Article>
              <ArticleIcon>
                <ReactSkeleton width={80} height={80} />
              </ArticleIcon>
              <ArticleInfoContainer>
                <ArticleEngagementContainer>
                  <ReactSkeleton height={10} width={200} />
                  <EngagementScoreContainer>
                    <ReactSkeleton height={10} width={200} />
                  </EngagementScoreContainer>
                </ArticleEngagementContainer>
                <Pills>
                  <PillSkeleton width={80} height={24} />
                  <PillSkeleton width={80} height={24} />
                  <PillSkeleton width={80} height={24} />
                  <PillSkeleton width={80} height={24} />
                </Pills>
              </ArticleInfoContainer>
            </Article>
          </ArticleContainer>
        </ContentContainer>
      ))}
    </ContentWrapper>
  );
}

export default Skeleton;
