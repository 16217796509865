import React, { useEffect, useState } from 'react';
import TopicsSearch from 'src/componentsV2/complex/TopicsSearch';
import Pencil from 'src/assets/icons/Pencil';
import Check from 'src/assets/icons/Check';
import Multiply from 'src/assets/icons/Multiply';
import useRESTQuery from 'src/hooks/useRESTQuery';
import { PillSkeleton } from 'src/pages/Discover/common/components/Article/styles';
import { Label } from '../styles';
import {
  EditIcon,
  Header,
  Pill,
  Pills,
  TopicsSearchWrapper,
  Wrapper,
} from './styles';
import SnackbarComponent from 'src/components/Snackbar';

function Topics({
  showTopicsEdit,
  setShowTopicsEdit,
  selectedTopics,
  setSelectedTopics,
}) {
  const [tempSelectedTopics, setTempSelectedTopics] = useState([]);
  const [showSnackbar, toggleSnackbar] = useState(false);

  const { isLoading: topicsLoader, data: topics } = useRESTQuery({
    key: 'topics',
    api: 'api.clout',
    endpoint:
      '/theme/?fields=theme_id,name,status,description,media_urls,created_time,updated_time&third_party_themes_only=true',
    options: {
      select: (res) => {
        const result = res?.result?.data;
        if (Array.isArray(result) && result.length > 0) {
          return result?.map((topic) => ({
            ...topic,
            theme_name: topic?.name,
          }));
        }
      },
    },
  });

  useEffect(() => {
    if (Array.isArray(selectedTopics)) {
      setTempSelectedTopics(selectedTopics);
    }
  }, [selectedTopics]);

  const handleSave = () => {
    if (!tempSelectedTopics.length) {
      toggleSnackbar(true);
    } else {
      setSelectedTopics([...tempSelectedTopics]);
      setShowTopicsEdit(false);
    }
  };

  const handleClose = () => {
    toggleSnackbar(false);
  };

  const loader = () => {
    return (
      <Pills>
        <PillSkeleton width="100" height="20" />
        <PillSkeleton width="100" height="20" />
        <PillSkeleton width="100" height="20" />
      </Pills>
    );
  };

  return (
    <Wrapper>
      <Header>
        <Label>Topics</Label>
        {showTopicsEdit ? (
          <EditIcon onClick={handleSave}>
            <Check />
            <span>Save</span>
          </EditIcon>
        ) : (
          <EditIcon onClick={() => setShowTopicsEdit(true)}>
            <Pencil />
            <span>Edit</span>
          </EditIcon>
        )}
      </Header>
      {showTopicsEdit ? (
        <>
          <Pills>
            {topicsLoader
              ? loader
              : tempSelectedTopics?.map((topic) => (
                  <Pill
                    key={topic}
                    onClick={() =>
                      setTempSelectedTopics([
                        ...tempSelectedTopics?.filter(
                          (t) => t?.theme_name !== topic?.theme_name
                        ),
                      ])
                    }
                  >
                    <span>{topic?.theme_name}</span> <Multiply />
                  </Pill>
                ))}
          </Pills>
          <TopicsSearchWrapper>
            <TopicsSearch
              topics={topics}
              selectedTopics={tempSelectedTopics}
              setSelectedTopics={setTempSelectedTopics}
              showSelectedTopics={false}
              topicSelectionLimit={4}
            />
          </TopicsSearchWrapper>
        </>
      ) : (
        <Pills>
          {topicsLoader
            ? loader
            : selectedTopics?.map((topic) => (
                <Pill key={topic}>
                  <span>{topic?.theme_name}</span>
                </Pill>
              ))}
        </Pills>
      )}
      <SnackbarComponent
        variant="error"
        handleClose={handleClose}
        open={showSnackbar}
        content="Please select at least 1 topic"
      />
    </Wrapper>
  );
}

export default Topics;
