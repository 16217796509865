import { Flex } from '@rebass/grid';
import styled from 'styled-components';
import Chip from '@mui/material/Chip';

export const ModalArticlesContainer = styled(Flex)`
  border: ${({ border }) => border || '1px solid #cfd9e4'};
  border-radius: 4px;
  justify-content: flex-start;
  align-items: ${({ alignItems }) => alignItems || 'center'};
  ${'' /* width: 80%; */}
  padding: ${({ padding }) => padding || '9px'};
  height: 40%;
  margin-top: 10px;
  margin-bottom: 10px;
  background-color: ${({ bgColor }) => bgColor || '#f6f9fb'};
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  max-height: 300px;
  overflow-x: hidden;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 5px;
    height: 6px;
  }

  &::-webkit-scrollbar-track {
    background: #e9f6e6 !important;
  }

  &::-webkit-scrollbar-thumb {
    background: #bccdd4 !important;
  }
  ${'' /* margin-bottom: ${({ mb }) => mb || '25px'}; */}
`;

export const AddContentButton = styled('button')`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #101828;
  background: #FFFFFF;
  border: 1px solid #E4E7EC;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.12);
  border-radius: 4px;
  padding: 8px 16px;
  cursor: pointer;
  :hover {
    opacity: 0.7
  }
`;

export const TabContainer = styled('div')`
  display: flex;
  overflow: hidden;
  justify-content: start;
  border: 1px solid #e4e7ec;
  border-radius: 10;
`;

export const ContentSelect = styled('div')`
  display: flex;
  justifyContent: start;
  padding: 20px;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.12);
  width: 400px;
  font-weight: 500;
  font-size: 20px;
  color: #101828;
  border-radius: 10px;
  margin: 10px auto;
  cursor: pointer;
  transition: 0.5s;
  :hover {
    opacity: 0.7; 
  }
`;

export const ContentContainer = styled('div')`
  font-family: 'Poppins';
  margin: 20px 0;
  min-height: 150px;
`;

export const ButtonContainer = styled('div')`
  font-family: 'Poppins';
  display: flex;
  justify-content: space-between;
  margin: 30px 0px -10px;
`;

export const ContentButton = styled('button')`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: white;
  width: 49%;
  background-color: ${({ disabled }) => (!disabled ? '#816EF8' : '#ccc')};
  border: 1px solid #E4E7EC;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.12);
  border-radius: 4px;
  padding: 8px 16px;
  cursor: ${({ disabled }) => (!disabled ? 'pointer' : 'text')};
  :hover {
    opacity: ${({ disabled }) => (!disabled ? 0.7 : 1)};
  }`
;

export const NavButton = styled('button')`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: white;
  width: 95%;
  height: 55px;
  line-height: 1;
  padding: 6px 2px;
  background: #816EF8;
  border: 1px solid #E4E7EC;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.12);
  border-radius: 4px;
  cursor: pointer;
  display: table-cell;
  :hover {
    opacity: 0.7
  }`;


export const ButtonText = styled('div')`
  width: 100%;
  color: white;
`;

export const OuterBox = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #EFF6F5;
  border-radius: 4px;
  padding: 10px 10px;
  font-family: 'Poppins;
`;

export const FileUploadBox = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 300px;
  background-color: #EFF6F5;
  border: 1px dashed #758790;
  border-radius: 4px;
  padding: 4px;
  cursor: pointer;
  border: 1px dashed #75879;
`;

export const BrowseFiles = styled('label')`
  font-family: 'Poppins';
  display: flex;
  cursor: pointer;
  justify-content: space-between;
  margin: 0px 4px 0px 0px;
  color: #0B5CD7;
`;

export const UploadSubheader = styled('div')`
  font-family: 'Poppins';
  font-size: 12px;
  margin-top: -7px;
  color: #758790;
`;

export const UploadHeader = styled('p')`
  font-family: 'Poppins';
  font-size: 14px;
  color: #404B50;
  display: flex;
`;

export const TitleInput = styled('input')`
  font-family: 'Poppins';
  width: 100%;
  height: 40px;
  background: #FCFDFD;
  padding: 0 10px;
  border: 1px solid #E4E7EC;
  box-shadow: inset 1px 2px 2px rgba(16, 24, 40, 0.12);
  border-radius: 4px;
  color: #667085;
  margin: 0 0 4px;
`;

export const SearchBox = styled('input')`
  font-family: 'Poppins';
  width: 100%;
  height: 40px;
  background: #FCFDFD;
  padding: 0 10px;
  border: 1px solid #E4E7EC;
  box-shadow: inset 1px 2px 2px rgba(16, 24, 40, 0.12);
  border-radius: 4px;
  color: #667085;
`;

export const DescriptionInput = styled('textarea')`
  font-family: 'Poppins';
  width: 100%;
  height: 64px;
  background: #FCFDFD;
  padding: 10px;
  border: 1px solid #E4E7EC;
  box-shadow: inset 1px 2px 2px rgba(16, 24, 40, 0.12);
  border-radius: 4px;
  color: #667085;
`;

export const InputTitle = styled('h4')`
  font-family: 'Poppins';
  font-weight: 400;
  font-size: 14px;
  color: #101828;
  text-align: left;
  margin: 0px 0 2px;
`;

export const PreviewContainer = styled('div')`
  width: 100%;
  margin: 10px auto;
  display: table;
`;

export const TopicOuterContainer = styled('div')`
  width: 100%;
`;

export const PreviewTopContent = styled('div')`
  width: 100%;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #E4E7EC;
  padding: 0 0 10px;
`;

export const PreviewImage = styled('img')`
  height: 160px;
  width: 160px;
  margin-right: 20px;
  object-fit: cover;
  border-radius: 4px;
`;

export const EditImage = styled('img')`
  height: 20;
  width: 20;
  position: absolute;
  top: 10px;
  right: 28px;
  background: rgb(241, 241, 241, 57%);
  border-radius: 5px;
  cursor: pointer;
  transition: 0.5s;
  :hover {
    background: #f1f1f1;
  }
`;

export const TopicStyle = styled('h4')`
  font-family: 'Poppins';
  font-size: 20px;
  font-weight: 500;
  color: #101828;
  margin: 10px 0 5px;
  text-align: left;
`;

export const SearchTitle = styled('h4')`
  font-family: 'Poppins';
  font-size: 14px;
  font-weight: 400;
  color: #101828;
  margin: 10px 0 5px;
  text-align: left;
`;

export const TopicsSelected = styled('h4')`
  font-family: 'Poppins';
  font-size: 12px;
  font-weight: 400;
  color: #475467;
  margin: 5px 0;
  text-align: left;
`;

export const TopicContainer = styled('div')`
  font-family: 'Poppins';
  width: 100%;
  display: flex;
  justify-content: start;
  padding: 10px;
  margin: 5px auto;
  background: #F9FAFB;
`;

export const ChipStyle = styled(Chip)`
  font-family: 'Poppins';
  margin: auto 5px auto 0px;
  border: 1px solid #B6CFF3 !important;
  background: #ECF2FC !important;
  color: #0B5CD7;
  display: inline;
  .MuiChip-root {
    color: #404B50;
    opacity: 1;
  };
  .MuiChip-outlined {
    color: #B6CFF3;
  };
  .MuiChip-deleteIcon {
    color: #0B5CD7;
    opacity: 1;
    margin: 0;
  };
`;

export const AddChipStyle = styled(Chip)`
  margin: 5px 5px 5px 0px !important;
  border: 1px solid #E4E7EC !important;
  background: #FFF !important;
  color: #101828;
  display: inline;
  .MuiChip-root {
    color: #101828;
    opacity: 1;
  };
  .MuiChip-deleteIcon {
    color: #101828;
    opacity: 1;
    padding: 0 6px 0 0;
  };
`;

export const SearchResults = styled('div')`
  width: 100%;
  display: flex;
  justify-content: start;
  padding: 5px;
  margin: 0px auto;
  flex-flow: rows;
  flex-wrap: wrap;
`;

export const FileStyle = styled('h4')`
  font-size: 11px;
  font-weight: 400;
  color: #B6CFF3;
  text-align: left;
`;
