import React from 'react';
import ReactSkeleton from 'react-loading-skeleton';
import {
  PerformanceChartContainer,
  PerformanceChartHeader,
  PerformanceGraphSection,
} from './styles';

function Skeleton() {
  return (
    <PerformanceChartContainer>
      <PerformanceChartHeader>
        <h2>
          <ReactSkeleton width={90} height={16} />
        </h2>
      </PerformanceChartHeader>
      <PerformanceGraphSection>
        <div className="legend-container">
          <ReactSkeleton width={84} height={16} />
          <ReactSkeleton width={84} height={16} />
        </div>
      </PerformanceGraphSection>
      <ReactSkeleton width={555} height={400} />
    </PerformanceChartContainer>
  );
}

export default Skeleton;
