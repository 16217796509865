import styled from 'styled-components';
import { Tab } from 'react-tabs';
import { themesData } from '../../utils';

export const NewsletterTab = styled(Tab).attrs({
  className: 'newsletter-tab'
})`
  width: 1.5em;
  height: 1.5em;
  text-align: center;
  line-height: 2em;
  border-radius: 1em;
  background-color: ${({bgColor}) => bgColor || `${themesData.purpleishBlue}`};
  margin: 0 3em;
  display: inline-block;
  color: white;
  position: relative;


&:first-child::before {
  display: none;
}

&.active {
  background-color: ${themesData.purpleishBlue};
  .text {
    font-weight: bold;
  }
}

&.active ~ li {
  background-color: #B4AFB6;
}

&.active ~ li::before {
  background-color: #B4AFB6;
}

.line {
  position: absolute;
  background-color: #BBB6BD;
  height: 0.09em;
  width: 6em;
  top: 0.7em;
  left: 1.5em;

  &.active {
    width: 6em;
    background-color: ${themesData.purpleishBlue};
  }

  &.inactive {
    width: 3em;
    background-color: ${themesData.purpleishBlue};
  }

  &.inactive:after {
    content: "";
    width: 3em;
    background-color: #BBB6BD;
    height: 0.09em;
    position: absolute;
    left: 3em;
  }
}

.number-tag {
  position: absolute;
  color: ${themesData.white};
  top: -3px;
  left: 8px;
  font-size: 14px;
}

.text {
  position: absolute;
  color: ${themesData.purpleishBlue};
  font-size: 12px;
  top: 3em;
  left: -5.5em;
  width: 13em;
  line-height: 1.5em;
}
`;