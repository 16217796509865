import styled from 'styled-components';
import { Flex } from '@rebass/grid';

import themes from '../CommonStyles/colors';
import { themesData } from '../../utils/constants';
const { purpleishBlue } = themesData;

const lightBackground = `
background: rgb(255,255,255);
background: -moz-linear-gradient(top, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 48%, rgba(228,228,228,1) 100%);
background: -webkit-linear-gradient(top, rgba(255,255,255,1) 0%,rgba(255,255,255,1) 48%,rgba(228,228,228,1) 100%);
background: linear-gradient(to bottom, rgba(255,255,255,1) 0%,rgba(255,255,255,1) 48%,rgba(228,228,228,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#e4e4e4',GradientType=0 );
border-bottom-right-radius: 10px;
border-bottom-left-radius: 10px;
border: 1px solid ${themes.color.border.secondary};`;

const inverseBackground = `
background: rgb(241,229,252);
background: -moz-linear-gradient(top, rgba(241,229,252,1) 0%, rgba(255,255,255,1) 48%, rgba(255,255,255,1) 100%);
background: -webkit-linear-gradient(top, rgba(241,229,252,1) 0%,rgba(255,255,255,1) 48%,rgba(255,255,255,1) 100%);
background: linear-gradient(to bottom, rgba(241,229,252,1) 0%,rgba(255,255,255,1) 48%,rgba(255,255,255,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f1e5fc', endColorstr='#ffffff',GradientType=0 );
border-bottom-right-radius: 10px;
border-bottom-left-radius: 10px;
border: 1px solid ${themes.color.border.primary};`;

export const WidgetWrapper = styled.div`
  position: relative;
  border-radius: 10px;
  min-height: 230px;
  width: ${(props) => (props.screenWidth < 768 ? '100%' : props.heading === 'Personalized' ? '49%' : '23%')};
  margin-top: ${(props) => (props.screenWidth < 768 && props.heading === 'Personalized' ? '55px' : props.screenWidth < 768 && props.heading !== 'Personalized' ? '70px' : '0px')};
  text-align: center;
  border: 1px solid #e5e5e5;
  ${({ theme }) => (theme === 'light' ? lightBackground : inverseBackground)}
  color: ${themes.color.font.primary};
  border: none;
`;

export const WidgetHeader = styled(Flex)`
  background-color: ${({ theme }) => (theme === 'light' ? '#e4e4e5' : '#fff')};
  font-weight: 600;
  height: 40px;
  align-items: center;
  justify-content: center;
  border-top-left-radius: 9px;
  border-top-right-radius: 9px;
  h4 {
    font-size: 13px;
    font-weight: 500;
    line-height: 1.1;
    color: ${purpleishBlue};
  }
`;

export const WidgetBody = styled.div`
  height: 206px;
`;
