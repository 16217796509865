import React, { useState } from 'react';
import { 
    OuterTableContainer, 
    InnerTableContainer,
    TableHeader,
    TableTitle,
    EmptyStyle,
    DefaultRow,
    SubTitle,
    SearchContainer
} from './styles';
import {
    Table,
    TableBody,
    TableCell,
    TableRow,
    TablePagination,
    TableHead
} from '@mui/material';
import { tableCellClasses } from '@mui/material/TableCell';
import Checkbox from '../../componentsV2/Checkbox';
import SearchBar from '../../componentsV2/SearchBar';

const GeneralTable = ({ 
    tableData, 
    rowClick,
    headerData,
    tableHeader,
    checkBoxClick,
    subTitle,
    showPaginate,
    searchString, 
    setSearchString,
    showSearch
}) => {
    const [hoveredIndex, setHoveredIndex] = useState(null);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [currentPage, setCurrentPage] = useState(0);
    const [openSearch, setOpenSearch] = useState(false);

    const emptyDisplay = () => {
        return(
            <TableBody style={{ display: 'flex', justifyContent: 'center'}}>
                <EmptyStyle>No Results Found</EmptyStyle>
            </TableBody>
        )
    };

    const tableDisplay = () => {
        return(
            <TableBody>
                {tableData.map((contact, index) => {
                    return (
                        <TableRow
                            key={index}
                            onClick={()=>rowClick && rowClick(contact)}
                            id={`selection-row-${index}`}
                            onMouseOver={()=>setHoveredIndex(index)}
                            sx={{
                                padding: '2px auto 2px auto',
                                height: 20,
                                cursor: 'pointer',
                                background: hoveredIndex === index ? '#F9FAFB' : 'white',
                            }}
                        >
                        {headerData.map((headerInfo, headerIndex) => {
                            if(headerInfo.formatData){
                                return <TableCell key={headerIndex}>{headerInfo.formatData(contact, index)}</TableCell>;
                            } else if (headerInfo.type === 'checkbox') {
                                return (
                                    <TableCell style={{ width: '20px' }} key={headerIndex}>
                                        <div style={{ padding: '0px 0px 2px 0px', marginLeft: '12px' }}>
                                            <Checkbox
                                                onClick={()=>checkBoxClick && checkBoxClick(index, contact)}
                                                checked={contact.checked}
                                                inputProps={{ 'aria-label': 'secondary checkbox' }}
                                                id={`select-${index}`}
                                            />
                                        </div>
                                    </TableCell>
                                );
                            } else {
                            return (
                                    <TableCell key={headerIndex}>
                                        <DefaultRow>{contact[headerInfo.key]}</DefaultRow>
                                    </TableCell>
                                )
                            }
                        })}
                        </TableRow>
                    );
                    })
                }
            </TableBody>
        )
    };

    const headerDisplay = () => {
        return(
            <TableHead onMouseOver={()=>setHoveredIndex(null)}>
                <TableRow
                    sx={{
                        [`& .${tableCellClasses.root}`]: {
                            padding: '0px auto',
                            color: '101828',
                            fontFamily: 'Poppins !important',
                        },
                    }}
                    >
                    {headerData.map((headerInfo, index) => {
                        if(headerInfo.formatHeader){
                            return <TableCell key={index}>{headerInfo.formatHeader()}</TableCell>;
                        } else {
                            return (
                                <TableCell key={index}>
                                    <div style={{ display: 'flex' }}>
                                        <h4 style={{color: '#101828', fontSize: 16, fontWeight: 500}}>{headerInfo.header}</h4>
                                    </div>
                                </TableCell>
                            );
                        }
                    })}
                </TableRow>
            </TableHead>
        )
    }

    return(
      <OuterTableContainer style={{ borderRadius: '8px'}}>
        {tableHeader && (
            <TableHeader>
                <div style={{dispaly: 'table'}}>
                    <TableTitle style={{margin: subTitle ? '5px 15px 0px' : '10px 15px'}}>{tableHeader}</TableTitle>
                    {subTitle && <SubTitle>{subTitle}</SubTitle>}
                </div>
                {showSearch && setSearchString && (
                    <SearchContainer style={{width: '35%', display: 'flex', justifyContent: 'end', marginRight: 15}} searchActive={openSearch} >
                        <SearchBar 
                            setActiveSearch={setOpenSearch} 
                            activeSearch={openSearch} 
                            searchString={searchString} 
                            setSearchString={setSearchString} 
                        />
                    </SearchContainer>
                )}
            </TableHeader>
        )}
        <InnerTableContainer>
          <Table
            stickyHeader
            size="small"
            sx={{
              [`& .${tableCellClasses.root}`]: {
                padding: '2px auto 2px auto',
                height: 20,
                fontFamily: 'Poppins !important'
              },
            }}
          >
            {
                headerDisplay()
            }
            {
                tableData.length > 0 ? tableDisplay() : emptyDisplay()
            }
          </Table>
          { showPaginate && 
            <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                onRowsPerPageChange={(e) => setRowsPerPage(e.target.value)}
                component="div"
                count={tableData?.length ?? 0}
                rowsPerPage={rowsPerPage}
                sx={{ fontFamily: 'Poppins', color: '#758790' }}
                page={currentPage}
                onPageChange={(e, page) => setCurrentPage(page)}
            />}
        </InnerTableContainer>
      </OuterTableContainer>
    )
  };

  export default GeneralTable;