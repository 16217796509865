import React from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { Flex, Box } from '@rebass/grid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock } from '@fortawesome/free-solid-svg-icons';
import { LayoutInitialWrapper } from '../';
import StripeComponent from './StripeComponent';

import {
  SubscribeScreenTitle,
  SubscribeScreenSecureText,
  StyledUl,
} from './SubscribeSreen.styles';

const stripePromise = loadStripe('pk_live_acAuz7FR2dKZuq3hvLUXPa1l00IlWZZTrK');
// const stripePromise = loadStripe('pk_test_TYooMQauvdEDq54NiTphI7jx');

const SubscribeSreen = () => {
  return (
    <LayoutInitialWrapper>
      <Flex
        width={8 / 10}
        mx="auto"
        justifyContent="center"
        flexDirection="column"
      >
        <Box width={1}>
          <SubscribeScreenTitle>
            Subscribe to the premium plan!
          </SubscribeScreenTitle>
        </Box>
        <Box width={1}>
          <Flex width={1}>
            <Box width={4 / 5}>
              <StyledUl>
                <li>
                  Unlock access to 1000s of curated articles from over 60
                  publishers.
                </li>
                <li>
                  Personalize Topics selection and receive 1000s of curated
                  insights.
                </li>
                <li>Upload Your Investments and receive curated content.</li>
              </StyledUl>
            </Box>
            <Box width={4 / 5}>
              <StyledUl>
                <li>
                  See the Impact created with downloadable analytics reports
                </li>
                <li>Search through the entire content library on MIO.</li>
              </StyledUl>
            </Box>
          </Flex>
        </Box>
        <Box width={1}>
          <SubscribeScreenSecureText>
            Enter your payment details securely&nbsp;&nbsp;&nbsp;
            <FontAwesomeIcon icon={faLock} />
          </SubscribeScreenSecureText>
        </Box>
        <Box width={[1, 3 / 4]} mx="auto" pb="20px">
          <Elements stripe={stripePromise}>
            <StripeComponent />
          </Elements>
        </Box>
      </Flex>
    </LayoutInitialWrapper>
  );
};

export default SubscribeSreen;
