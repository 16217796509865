/* eslint-disable react-hooks/exhaustive-deps */
import { Flex } from '@rebass/grid';
import React, { useEffect } from 'react';
import { SendTestMailModal } from './SendTestMailModal';
import { Button } from '../../../components';
import { ToggleButton } from '../../ConfigureContainer/screenConfigure.styles';
import { useState } from 'react';
import { isEmpty } from 'lodash';
import { AUTOMATED_CAMPAIGN_ACTIONS } from '../AutomatedCampaignsContainer';
import { nurtureFlag } from '../../../utils';
import moment from 'moment';

const CampaignDetails = ({ 
  onlyEmailNumber, 
  onlySocialNumber, 
  campaignPurpose, 
  campaignSeriesName, 
  campaignAction, 
  complianceSettings, 
  handleComplianceToggle, 
  toogleCompliance, 
  automatedCampaignTargetInfo,
  campaignDetails  
}) => {
  
  const [platforms, setPlatforms] = useState([]);

  useEffect(() => {
    getPlatforms();
  }, [])
  const getPlatforms = () => {
    if(nurtureFlag !== 'nurture' || (process.env.REACT_APP_API_ENV !== 'stage' && nurtureFlag === 'nurture')) {
      if(campaignAction !== AUTOMATED_CAMPAIGN_ACTIONS.EVENT) {
        setPlatforms([{
          src: '/assets/images/Campaign/email-icon.svg',
          alt: 'email-img'
        }])
      } else {
        let selectedPlatforms = [];
        if(automatedCampaignTargetInfo?.segment_id) {
          selectedPlatforms = [...selectedPlatforms, {
            src: '/assets/images/Campaign/email-icon.svg',
            alt: 'email-img'
          }]
        } 
        if(automatedCampaignTargetInfo?.social_pages?.facebook) {
          selectedPlatforms = [...selectedPlatforms, {
            src: '/assets/images/Campaign/fb-icon.svg',
            alt: 'fb-img'
          }]
        } 
        if(automatedCampaignTargetInfo?.social_pages?.twitter) {
          selectedPlatforms = [...selectedPlatforms, {
            src: '/assets/images/Campaign/tw-icon.svg',
            alt: 'tw-img'
          }]
        } 
        if(automatedCampaignTargetInfo?.social_pages?.linkedin) {
          selectedPlatforms = [...selectedPlatforms, {
            src: '/assets/images/Campaign/lnkdin-icon.svg',
            alt: 'ln-img'
          }]
        }
        setPlatforms(selectedPlatforms);
      }
    } else {
      setPlatforms([
        {
          src: '/assets/images/Campaign/email-icon.svg',
          alt: 'email-img'
        },
        {
          src: '/assets/images/Campaign/fb-icon.svg',
          alt: 'fb-img'
        },
        {
          src: '/assets/images/Campaign/tw-icon.svg',
          alt: 'tw-img'
        },
        {
          src: '/assets/images/Campaign/lnkdin-icon.svg',
          alt: 'ln-img'
        }

      ])
    }
    
  }
  return (
    <>
      <Flex
        display="flex"
        alignItems="center"
        width="100%"
        flexDirection="column"
      >
        <Flex width="100%" flexDirection="row">
          <Flex
            width="70%"
            padding="3px 5px 5px 10px"
            justifyContent="flex-start"
            flexDirection="column"
            display="flex"
          >
            <Flex
              alignItems="center"
              fontSize="16px"
              style={{ fontWeight: 500 }}
              color="#000000"
              width="70%"
            >
              New Campaign Name
            </Flex>
            <Flex
              width="70%"
              alignItems="center"
              fontSize="14px"
              color="#667085"
              style={{textAlign: 'left'}}
            >
              {nurtureFlag === 'nurture' && process.env.REACT_APP_API_ENV === 'stage' ? `Prioritise Prospect ${moment().format('MM/DD/YYYY')}` : automatedCampaignTargetInfo?.name}
            </Flex>
          </Flex>

          {/* <Flex width="30%" display="flex" justifyContent="flex-end">
            <Button
              margin="5px 0px 0px"
              borderRadius="4px"
              bgColor="#FFFFFF"
              borderColor="#E4E7EC"
              color="#101828"
            >
              Send Test Emails
            </Button>
          </Flex> */}
        </Flex>
        <Flex
          width="100%"
          display="flex"
          flexDirection="column"
          padding="3px 5px 5px 10px"
        >
          <Flex
            width="100%"
            alignItems="center"
            fontSize="16px"
            style={{ fontWeight: 500 }}
            color="#000000"
            justifyContent="flex-start"
          >
            Purpose
          </Flex>
          <Flex
            width="100%"
            alignItems="center"
            fontSize="14px"
            color="#667085"
            style={{ textAlign: 'justify' }}
          >
            {nurtureFlag === 'nurture' && process.env.REACT_APP_API_ENV === 'stage' ? 'Nurture my prospects' : automatedCampaignTargetInfo?.purpose}
          </Flex>
        </Flex>
        <Flex
          width="100%"
          display="flex"
          flexDirection="column"
          padding="3px 5px 10px 10px"
        >
          <Flex
            width="100%"
            alignItems="center"
            fontSize="16px"
            style={{ fontWeight: 500 }}
            color="#000000"
            justifyContent="flex-start"
          >
            Platforms
          </Flex>
          <Flex width="100%" alignItems="center" justifyContent="flex-start">
            {!isEmpty(platforms) && platforms.map((item, index) => (
              <Flex marginRight="5px" padding="3px 5px 2px 0px">
                <img
                  alt={item.alt}
                  src={item.src}
                />
              </Flex>
            ))}
          </Flex>
        </Flex>
        <hr style={{ width: '100%' }} />
        <Flex width="100%" padding="3px 5px 5px 0px" flexDirection="column">
          {(nurtureFlag !== 'nurture' || (nurtureFlag === 'nurture' && process.env.REACT_APP_API_ENV !== 'stage')) && (
            <Flex display="flex" width="80%">
              <Flex
                width="50%"
                padding="5px 10px"
                fontSize="14px"
                color="#000000"
                justifyContent="flex-start"
                style={{ fontWeight: 500 }}
              >
                {campaignAction !== AUTOMATED_CAMPAIGN_ACTIONS.EVENT ? 'Start Date' : 'Event Date'}
              </Flex>
              <Flex
                width="30%"
                justifyContent="flex-end"
                fontSize="14px"
                color="#667085"
                padding="5px 10px"
              >
                {campaignAction !== AUTOMATED_CAMPAIGN_ACTIONS.EVENT ? automatedCampaignTargetInfo?.start_date : automatedCampaignTargetInfo?.event_date}
              </Flex>
            </Flex>
          )}
          
          <Flex display="flex" width="80%">
            <Flex
              width="50%"
              padding="5px 10px"
              fontSize="14px"
              color="#000000"
              justifyContent="flex-start"
              style={{ fontWeight: 500 }}
            >
              Emails
            </Flex>
            <Flex
              width="30%"
              justifyContent="flex-end"
              fontSize="14px"
              color="#667085"
              padding="5px 10px"
            >
              {`${onlyEmailNumber} Emails`}
            </Flex>
          </Flex>
          {campaignAction === AUTOMATED_CAMPAIGN_ACTIONS.EVENT && (
            <Flex display="flex" width="80%">
              <Flex
                width="50%"
                padding="5px 10px"
                fontSize="14px"
                color="#000000"
                justifyContent="flex-start"
                style={{ fontWeight: 500 }}
              >
                Social Media Posts
              </Flex>
              <Flex
                width="30%"
                justifyContent="flex-end"
                fontSize="14px"
                color="#667085"
                padding="5px 10px"
              >
                {`${onlySocialNumber} Posts`}
              </Flex>
            </Flex>
          )}
          
          <Flex display="flex" width="80%">
            <Flex
              width="50%"
              padding="5px 10px"
              fontSize="14px"
              color="#000000"
              justifyContent="flex-start"
              style={{ fontWeight: 500 }}
            >
              Campaign Duration
            </Flex>
            <Flex
              width="30%"
              justifyContent="flex-end"
              fontSize="14px"
              color="#667085"
              padding="5px 10px"
            >
              {nurtureFlag === 'nurture' && process.env.REACT_APP_API_ENV === 'stage' ? 2 : campaignDetails?.campaign_duration} Days
            </Flex>
          </Flex> 
          {automatedCampaignTargetInfo?.segment_id && (
            <Flex display="flex" width="80%">
              <Flex
                width="50%"
                padding="5px 10px"
                fontSize="14px"
                color="#000000"
                justifyContent="flex-start"
                style={{ fontWeight: 500 }}
              >
                Total Contacts
              </Flex>
              <Flex
                width="30%"
                justifyContent="flex-end"
                fontSize="14px"
                color="#667085"
                padding="5px 10px"
              >
                {campaignDetails?.total_contacts}
              </Flex>
            </Flex>
          )}

          {nurtureFlag === 'nurture' && process.env.REACT_APP_API_ENV === 'stage' && (
            <Flex display="flex" width="80%">
              <Flex
                width="50%"
                padding="5px 10px"
                fontSize="14px"
                color="#000000"
                justifyContent="flex-start"
                style={{ fontWeight: 500 }}
              >
                Total Contacts
              </Flex>
              <Flex
                width="30%"
                justifyContent="flex-end"
                fontSize="14px"
                color="#667085"
                padding="5px 10px"
              >
                100
              </Flex>
            </Flex>
          )}
          
        </Flex>
        <hr style={{ width: '100%' }} />
        {complianceSettings === 'optional' && (
          <Flex width="100%" flexDirection="row">
            <Flex width="8%" style={{ padding: '10px 15px 0px 8px' }}>
              <ToggleButton
                className="react-switch"
                onChange={(checked) => handleComplianceToggle(checked)}
                checked={toogleCompliance === 'true'}
                onColor="#816EF8"
                offColor="#ccc"
                offHandleColor="#fff"
                onHandleColor="#fff"
                handleDiameter={10}
                uncheckedIcon=""
                checkedIcon=""
                boxShadow=""
                activeBoxShadow=""
                type="autoPost"
                left="1px"
                margin="-3px 5px 4px"
                position="absolute"
                top="7px"
                opacity="0"
              />
            </Flex>
            <Flex
              width="70%"
              justifyContent="flex-start"
              padding="10px 0px"
              color="#101828"
              fontSize="14px"
              style={{ fontWeight: 500 }}
            >
              Compliance required
            </Flex>
          </Flex>
        )}

      </Flex>
    </>
  );
};

export default CampaignDetails;
