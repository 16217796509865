import React from 'react';
import ReactSkeleton from 'react-loading-skeleton';
import {
  CampaignInfoCardsContainer,
  CampaignPurposeContainer,
  CampaignSummaryHeader,
  CampaignSummaryOverview,
  CampaignSummaryTitleInfo,
  CampaignSummaryWrapper,
  CampaignTitleContainer,
  CampaignTypeContainer,
} from './CampaignSummary/styles';
import BreadCrumbsSkeleton from './common/BreadCrumbs/Skeleton';
import { CampaignOverviewWrapper } from './styles';

function Skeleton() {
  return (
    <CampaignOverviewWrapper>
      <div className="campaign-detail-section">
        <BreadCrumbsSkeleton />
        <CampaignSummaryWrapper>
          <CampaignSummaryHeader>
            <CampaignSummaryTitleInfo>
              <ReactSkeleton height={110} width={120} />
              <CampaignTitleContainer>
                <ReactSkeleton height={24} width={143} />
                <CampaignTypeContainer>
                  <ReactSkeleton height={16} width={30} />
                  <ReactSkeleton height={16} width={70} />
                </CampaignTypeContainer>
              </CampaignTitleContainer>
            </CampaignSummaryTitleInfo>
          </CampaignSummaryHeader>
          <CampaignSummaryOverview>
            <CampaignPurposeContainer>
              <div>
                <ReactSkeleton height={20} width={100} />
                <ReactSkeleton height={20} width={250} />
              </div>
              <div>
                <ReactSkeleton height={20} width={100} />
                <ReactSkeleton height={20} width={250} />
              </div>
            </CampaignPurposeContainer>
            <CampaignInfoCardsContainer>
              <ReactSkeleton height={148} width={205} />
              <ReactSkeleton height={148} width={205} />
              <ReactSkeleton height={148} width={205} />
            </CampaignInfoCardsContainer>
          </CampaignSummaryOverview>
        </CampaignSummaryWrapper>
      </div>
    </CampaignOverviewWrapper>
  );
}

export default Skeleton;
