/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { 
    ContentSelect, 
    ContentContainer, 
    ButtonContainer, 
    ContentButton,
    TitleInput,
    InputTitle,
    DescriptionInput,
    PreviewContainer,
    PreviewImage,
    PreviewTopContent,
    TopicStyle,
    TopicsSelected,
    TopicContainer,
    ChipStyle,
    AddChipStyle,
    SearchResults,
    SearchTitle,
    SearchBox,
    TopicOuterContainer,
    ButtonText,
    NavButton,
    EditImage
} from '../ArticleModal.styles';
import { useHistory } from 'react-router-dom';
import pdfIcon from './pdfIcon.svg';
import htmlIcon from './htmlIcon.svg';
import previewImg from './previewImg.svg';
import {
    postCreateContent,
    postThemeFetch,
    postUpdateContent
  } from '../../../services/dashboard';
import AddIcon from '@mui/icons-material/Add';
import { Loader } from '../../../components';
import edit from './edit.svg';
import UploadFile from '../../../components/UploadFile';
import { isEmpty } from 'lodash';

const displayViews = [
    {
        title: 'Upload Content (PDF)',
        key: 'uploadPdf', 
        icon: pdfIcon
    },
    {
        title: 'Link to a Website',
        key: 'linkWebsite', 
        icon: htmlIcon
    }
];

const UploadContentLayout = ({ 
    updateStep, 
    setModalTitle, 
    channelId, 
    setIsOpen, 
    themesList, 
    onResolveHandler,
    onRefreshMyContentData
}) => {
    const [selectedContent, setSelectedContent] = useState(displayViews[0].key);
    const [pageDisplay, setPageDisplay] = useState('select');
    const [emphasis, setEmphasis] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [websiteLink, setWebsiteLink] = useState('');
    const [loading, setLoading] = useState(false);
    const [previewTitle, setPreviewTitle] = useState('');
    const [previewDescription, setPreviewDescription] = useState('');
    const [previewImage, setPreviewImage] = useState(previewImg);
    const [previewThemes, setPreviewThemes] = useState([]);
    const [contentId, setContentId] = useState(null);
    const [searchString, setSearchString] = useState('');
    const [selectedTopics, setSelectedTopics] = useState({});
    const [removeIds, setRemoveIds] = useState({});
    const [customImage, setCustomImage] = useState(false);

    const history = useHistory();

    useEffect(()=>{
        if(selectedFile && previewImage !== selectedFile){
            let url = window.URL.createObjectURL(selectedFile)
            setPreviewImage(url)
            if(pageDisplay === 'previewPage') setCustomImage(true)
        }
    },[selectedFile]);

    const pageSelector = () => {
        switch(pageDisplay){
            case 'linkWebsite':
                return websiteView();
            case 'uploadPdf':
                setModalTitle('Upload PDF')
                return (
                <UploadFile 
                    selectedFile={selectedFile} 
                    setSelectedFile={setSelectedFile}
                    allowDrop={allowDrop}
                    drop={drop}
                    setPreviewTitle={setPreviewTitle}
                    format={{name: 'PDF', types: '.pdf'}}
                />
                )
            case 'previewPage':
                return previewDisplay();
            case 'successPage':
                return successDisplay();
            default:
                return selectView();
        }
    };


    const finalAction = async(type) => {
        switch(type){
            case 'internalOnly':
                const outputObj = {
                    contentId: contentId.content_id,
                    message: previewDescription,
                    title: previewTitle,
                    internalUse: true,
                    skip_compliance: true
                }
                await postUpdateContent(outputObj)
                setIsOpen('approved')
                break;
            case 'SubmitAndGo':
                return setIsOpen('approved'), history.push('/admin/discover')
            case 'SubmitAndMore':
                return setPageDisplay("select")
            default:
                break;
        }
    }

    const themeFinder = () => {
        let addArr = [];
        let addKeys = Object.keys(selectedTopics)
        for(let i = 0; i < addKeys.length; i++){
            if(addKeys[i] && selectedTopics[addKeys[i]] && selectedTopics[addKeys[i]].id) addArr.push(selectedTopics[addKeys[i]].id)
        }
        return {add: addArr, remove: Object.keys(removeIds)}
    }

    const allowDrop = (event) => {
        setEmphasis(!emphasis);
        event.preventDefault();
    };

    const drop = (event) => {
        event.preventDefault();
        const data = event.dataTransfer.files[0];
        setSelectedFile(data);
        setEmphasis(false);
    };

    const deleteHandler = (item, obj) => {
        let updateIds = {...removeIds}
        updateIds[obj.id] = item
        let outputObj = {...selectedTopics}
        delete outputObj[item]
        setRemoveIds(updateIds)
        setSelectedTopics(outputObj)
    }

    const addTopic = (item, index, themes) => {
        let updateRemoveIds = {...removeIds};
        let outputObj = {...selectedTopics}
        if(Object.keys(selectedTopics).length < 4){
            outputObj[item.name] = themes[index]
            delete updateRemoveIds[item.id]
        }
        setRemoveIds({...updateRemoveIds})
        setSelectedTopics(outputObj)
    }

    const goBack = () =>{
        if(pageDisplay === "select") {
            updateStep(2)
        }
        else if(pageDisplay === 'previewPage'){
            if(websiteLink){
                setPageDisplay("linkWebsite")
                setWebsiteLink('')
            }
            else {
                setPageDisplay("uploadPdf")
                setSelectedFile(null)
            }
            setPreviewTitle('')
        }
        else {
            setPageDisplay("select")
            setWebsiteLink('')
            setSelectedFile(null)
            setPreviewTitle('')
        }
    }

    const nextPage = async() => {
        if(pageDisplay === 'select' && selectedContent === 'uploadPdf'){
            setPageDisplay('uploadPdf')
        } else if(pageDisplay === 'select' && selectedContent === 'linkWebsite'){
            setPageDisplay('linkWebsite');
        } else if(pageDisplay === 'uploadPdf'){
            setLoading(true)
            try{
                const response = await postCreateContent({
                    channelId,
                    skip_compliance: false,
                    message: '',
                    source: 'pdf',
                    link: previewTitle,
                    title: previewTitle,
                    attachment1: selectedFile,
                })
                setContentId(response.result.data)
                setPreviewImage(response.result.data?.image);
                
                const fetchThemeResponse = await postThemeFetch({
                    media1: selectedFile
                });
                
                if(fetchThemeResponse?.result?.data?.themes?.vertical) {
                    const { trend } = fetchThemeResponse?.result?.data?.themes?.vertical;
                    setPreviewThemes(trend)
                    let selectedThemes = {}
                    let trendKeys = Object.keys(trend)
                    for(let i = 0; i < 3; i++){
                        if(trendKeys[i] && trend[trendKeys[i]]) selectedThemes[trendKeys[i]] = trend[trendKeys[i]]
                    }
                    setSelectedTopics(selectedThemes)
                }
                setLoading(false)
                if(selectedFile && selectedFile.name && previewTitle.length > 0) {
                    setModalTitle('PDF Preview')
                    setPageDisplay('previewPage')
                }
            }catch(err){
                console.log('PDF upload error:', err)
            }
            
        } else if(pageDisplay === 'linkWebsite'){
            setLoading(true)
            try{

                const idResponse = await postCreateContent({
                    channelId,
                    skip_compliance: false,
                    message: '',
                    link: websiteLink,
                    source: 'link',
                })
                setContentId(idResponse.result.data)
                const response = await postThemeFetch({
                    url: websiteLink
                });
                const { title, description, image } = response.result.data.text;
                let trend = {};
                if(!isEmpty(response.result.data.themes)) {
                    trend = response?.result?.data?.themes?.vertical?.trend;
                }   
                setPreviewTitle(title)
                setPreviewDescription(description)
                setPreviewImage(image)
                setPreviewThemes(trend)
                let selectedThemes = {}
                if(trend && Object.keys(trend).length > 0) {
                    let trendKeys = Object.keys(trend);
                    for(let i = 0; i < 3; i++){
                        selectedThemes[trendKeys[i]] = trend[trendKeys[i]]
                    }
                }
                setSelectedTopics(selectedThemes)
                setLoading(false)

                const outputObj = {
                    approved_to_share: true,
                    internal_content: false,
                    message: description,
                    skip_compliance: false,
                    title: title,
                    media1: '',
                    contentId: idResponse.result.data.content_id,
                    link: websiteLink,
                    source: 'link',
                };

                if(websiteLink.length) {
                    setModalTitle('URL Preview')
                    setPageDisplay('previewPage')
                    await postUpdateContent(outputObj)
                }
            } catch(err) {
                console.log('error with website link', err)
            }
        } else if(pageDisplay === 'previewPage'){
            let contentIdArr = [contentId.id]
            try{
                if(Object.keys(selectedTopics).length > 0){
                    setLoading(true)
                    setModalTitle(`Creating your content...`)
                    const outputObj = {
                        contentId: contentId.content_id,
                        message: previewDescription,
                        unpublished: true,
                        themes: themeFinder(),
                        title: previewTitle,
                        mediaFile: customImage ? selectedFile : ''
                    }
                    const response = await postUpdateContent(outputObj);
                    if(response.result.success){     
                        onRefreshMyContentData();                   
                        onResolveHandler(contentIdArr)
                    }
                }
            } catch (err){
                console.log('err in creation', err)
            }
        }
    };


    const selectView = () => {
        setModalTitle('Upload New Content')
        return(
            <>
                {
                    displayViews.map((item, index) => {
                        return(
                            <ContentSelect 
                                onClick={()=>setSelectedContent(item.key)} 
                                style={{border: selectedContent === item.key ? '1px solid #816EF8' : '1px solid #E4E7EC'}} 
                                key={index}
                            >
                                <img alt='icon' src={item.icon} style={{height: 40, width: 40, margin: '16px 20px 0 0'}} />
                                <p>{item.title}</p>
                            </ContentSelect>
                        )
                    })
                }
            </>
        )
    };

    const websiteView = () => {
        setModalTitle('Upload Link to a Website')
        return(
            <div style={{margin: '60px 0 60px 20px', width: '90%'}}>
                <InputTitle>Enter URL</InputTitle>
                <TitleInput onChange={(e)=>setWebsiteLink(e.target.value)}></TitleInput>
            </div>
        )
    };

    const successDisplay = () => {
        return(
            <div>
                <div style={{display: 'flex'}}>
                    <PreviewImage src={previewImage} alt='preview' />
                    <div style={{display:'table'}}>
                        <h4 style={{textAlign:'left'}}>{previewTitle}</h4>
                        <p style={{textAlign:'left'}}>{previewDescription}</p>
                    </div>
                </div>
                <ButtonContainer>
                    <NavButton
                        style={{ verticalAlign: 'middle'}}
                        onClick={()=> finalAction('internalOnly')}
                    >
                        <ButtonText>Make Internal Use Only</ButtonText> 
                    </NavButton>
                    <NavButton
                        style={{margin: '0 4px', verticalAlign: 'middle'}}
                        children={"Submit and go to My Content"}
                        onClick={()=> finalAction('SubmitAndGo')}
                    >
                        <ButtonText>Submit and go to My Content</ButtonText> 
                    </NavButton>
                    <NavButton
                        style={{ verticalAlign: 'middle'}}
                        children={"Submit and Add More Content"}
                        onClick={()=> finalAction('SubmitAndMore')}
                    ><ButtonText>Submit and Add More Content</ButtonText> 
                    </NavButton>
                </ButtonContainer>
            </div>
        )
    }

    const previewDisplay = () => {
        return(
            <PreviewContainer>
                <TopicOuterContainer>
                    <PreviewTopContent>
                        <div style={{position: 'relative'}}>
                            <PreviewImage src={previewImage} alt='preview' />
                            <label htmlFor="upload-file-button" >
                                <EditImage src={edit} alt='edit' />
                            </label>
                            <input
                                type="file"
                                id="upload-file-button"
                                accept=".png,.jpeg,.jpg,.gif"
                                hidden
                                onChange={(e) => setSelectedFile(e.target.files[0])}
                            />
                        </div>
                        <div style={{display: 'table', width: 'calc(99% - 160px)'}}>
                            <InputTitle>Article Title</InputTitle>
                            <TitleInput type="text" value={previewTitle} onChange={(e)=>setPreviewTitle(e.target.value)}></TitleInput>
                            <InputTitle>Description</InputTitle>
                            <DescriptionInput 
                                type="text" 
                                value={previewDescription} 
                                onChange={(e)=>setPreviewDescription(e.target.value)}
                            ></DescriptionInput>
                        </div>
                    </PreviewTopContent>
                    <TopicStyle>Your Topics</TopicStyle>
                    <TopicsSelected>{Object.keys(selectedTopics).length}/4 Topics selected</TopicsSelected>
                    <TopicContainer>
                        {
                            Object.keys(selectedTopics).length > 0 ? Object.keys(selectedTopics).map((item, index) =>{
                                return <ChipStyle variant="outlined" key={index} label={item} onDelete={()=>deleteHandler(item, selectedTopics[item])} />
                            }) : <p>Please select at least one topic</p>
                        }
                    </TopicContainer>
                    <SearchTitle>Add Topics</SearchTitle>
                    <SearchBox value={searchString} placeholder={"Search for a topic"} onChange={(e)=>setSearchString(e.target.value)}></SearchBox>
                    {
                        searchString.length > 0 && <>
                            <SearchTitle>Showing results for "{searchString}"</SearchTitle>
                            <SearchResults>
                                {
                                    themesList.filter((item) => item.name.toLowerCase().startsWith(searchString.toLowerCase())).map((theme, index, array) => {
                                        if(index < 8){
                                            return (
                                                <AddChipStyle 
                                                    key={index} 
                                                    label={theme.name} 
                                                    deleteIcon={<AddIcon />} 
                                                    onClick={()=>addTopic(theme, index, array)} 
                                                    onDelete={()=>addTopic(theme, index, array)} 
                                                />
                                            )
                                        }
                                    })
                                }
                            </SearchResults>
                        </>
                    }
                </TopicOuterContainer>
            </PreviewContainer>
        )
    }

    const findDisabled = () => {
        if(pageDisplay === 'previewPage' && (Object.keys(selectedTopics).length < 1 || previewTitle.length < 1)){
            return true
        } else if(pageDisplay === 'linkWebsite' && websiteLink.length < 1){
            return true
        }  else if(pageDisplay === 'uploadPdf' && (previewTitle.length < 1 || selectedFile == null)){
            return true
        } else return false
    }

    return (
        <ContentContainer>
            {
               loading ? <Loader /> : pageSelector()
            }
            {pageDisplay !== 'successPage' && !loading && <ButtonContainer>
                <ContentButton 
                    style={{background: "#F2F4F7", color: "#98A2B3"}} 
                    onClick={()=>goBack()}
                    children="Back"
                />
                <ContentButton
                    children={pageDisplay !== "previewPage" ? "Next" : "Upload"}
                    disabled={findDisabled()}
                    onClick={()=> nextPage()}
                />
            </ButtonContainer>}
        </ContentContainer>
    );
};

export default UploadContentLayout;