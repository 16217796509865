import React from 'react';
import ReactSkeleton from 'react-loading-skeleton';
import {
  Actions,
  Card,
  Content,
  ContentWrapper,
  Description,
  ImageContainer,
  PillSkeleton,
  Pills,
  PrimaryActions,
  Section,
} from './styles';

function ArticleSkeleton() {
  return (
    <Card>
      <ImageContainer>
        <ReactSkeleton height={200} width={200} />
      </ImageContainer>
      <Section>
        <ContentWrapper>
          <Pills>
            <PillSkeleton width={80} height={24} />
            <PillSkeleton width={80} height={24} />
          </Pills>

          <Content>
            <ReactSkeleton height={10} width={500} />
            <ReactSkeleton height={10} width={500} />
            <Description>
              <ReactSkeleton height={10} width={100} />
              <div className="separator"></div>
              <ReactSkeleton height={10} width={100} />
            </Description>
          </Content>
        </ContentWrapper>
        <Actions>
          <PrimaryActions>
            <ReactSkeleton height={40} width={100} />
          </PrimaryActions>
        </Actions>
      </Section>
    </Card>
  );
}

export default ArticleSkeleton;
