import React, { useState, useEffect } from 'react';
import { Flex } from '@rebass/grid';
import {
  LoaderWrapper,
  DownloadButton,
  DisplayScore,
  ResponsiveImg,
} from '../../../components';
import {
  ProspectFeaturedArticle,
  VerticalLine,
  SponsoredWrapper,
  OverviewBadge,
  TopLeadEmail,
  TopLeadName,
  OverviewTabHeading,
  OverviewShareTitle,
  TopPublisherName,
  TopShareName,
  TopLeadWrapper,
  WebinarInfo
} from './EngagementTab.styles';
import moment from 'moment';
import { get, isEmpty, toLower, uniqBy } from 'lodash';
import { DownloadButtonFlex } from '../AnalyticsContainer.styles';
import {
  saveFile,
  getTabNameFromRepType,
  formatMomentIsoDate,
  onImageError,
  overviewTabSliderSettings,
  getMonthDate,
} from '../../../utils';
import Slider from 'react-slick';
import { ArticleSliderWrapper } from '../../DashboardContainer/DashboardContainer.styles';
import { getDownloadCsvData } from '../../../services/analytics';

const LeadInsightTable = ({
  selectedItem,
  analyticsData,
  isAnalyticsDataFetching,
  selectedDate,
  setSelectedDate,
  analyticsRequest,
  repType,
  analyticsObject,
  page,
  setPage,
  isAdmin,
}) => {
  const [allArticles, setAllArticles] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setAllArticles([]);
  }, [selectedDate]);

  const getEngagementMediaImage = (media) => {
    if (!isEmpty(media)) {
      console.log(`${media.path}${get(media, 'name.resized')}.${media.ext}`);
      return `${media.path}${get(media, 'name.resized')}.${media.ext}`;
    } else {
      return '';
    }
  };

  const handleLeadDateChange = ({ selectedDate, fromDate, toDate }) => {
    setPage(0);
    const currentFormattedDate = formatMomentIsoDate(moment());
    const currentMomentDate = moment();
    if (toLower(selectedDate) === 'last 30 days') {
      fromDate = formatMomentIsoDate(currentMomentDate.subtract(29, 'd'));
      toDate = currentFormattedDate;
    } else if (toLower(selectedDate) === 'last 7 days') {
      fromDate = formatMomentIsoDate(currentMomentDate.subtract(6, 'd'));
      toDate = currentFormattedDate;
    }
    analyticsRequest({
      params: {
        repType: repType,
        email_id: selectedItem.email,
        selectedDate: selectedDate,
        startDate: fromDate,
        endDate: toDate,
      },
    });
  };

  const onPaginationClick = (type) => {
    if (type === 'before') {
      analyticsRequest({
        params: {
          repType: repType,
          email_id: selectedItem.email,
          beforeData:
            analyticsData.pagination && analyticsData.pagination.before,
        },
      });
    } else {
      analyticsRequest({
        params: {
          repType: repType,
          email_id: selectedItem.email,
          afterData: analyticsData.pagination && analyticsData.pagination.after,
        },
      });
    }
    // setPageCount(type === 'before' ? pageCount === 0 ? 0 : pageCount - 1 : pageCount + 1);
    setPage(type === 'before' ? (page === 0 ? 0 : page - 1) : page + 1);
  };

  const onDownloadCSVClick = () => {
    setLoading(true);
    const tabName = getTabNameFromRepType(repType);
    const { startDate, endDate } = analyticsObject || {};
    const fileName = `${tabName}-${startDate}_${endDate}`;

    getDownloadCsvData({ repType, startDate, endDate, email_id: selectedItem.email, filters: analyticsObject.filterParams }).then(res => {
      saveFile(res, fileName);
      setLoading(false);
    }).catch(err => {
      setLoading(false);
    });
  };

  const trimName =
    (selectedItem.first_name + ' ' + selectedItem.last_name).length > 15
      ? (selectedItem.first_name + ' ' + selectedItem.last_name).substring(
          0,
          15
        ) + '...'
      : selectedItem.first_name + ' ' + selectedItem.last_name;
  return (
    <LoaderWrapper isLoading={isAnalyticsDataFetching}>
      <Flex width={1} flexDirection="column" className="lead-tab">
        <Flex width={1}>
          <ProspectFeaturedArticle>
            <Flex width="100%">
              <Flex width="28%">
                <img
                  src={
                    !isEmpty(selectedItem.suggested_investments)
                      ? selectedItem.suggested_investments[0].theme_image_path
                      : ''
                  }
                  alt="article"
                  width="80px"
                  onError={onImageError}
                  style={{ objectFit: 'cover' }}
                />
              </Flex>
              <Flex
                width="57%"
                flexDirection="column"
                padding={
                  selectedItem.lead_type === 'referral'
                    ? '10px 10px'
                    : '10px 0px'
                }
              >
                <TopLeadName style={{ color: '#695bfd', fontWeight: '500' }}>
                  {/* {selectedItem.first_name + ' ' + selectedItem.last_name} */}
                  {/* {`${selectedItem.first_name || ''} ${selectedItem.last_name || ''}`} */}
                  {trimName}
                </TopLeadName>
                <TopLeadEmail style={{ color: '#695bfd' }}>
                  {selectedItem.email && selectedItem.email.length > 20
                    ? selectedItem.email.substring(0, 20) + '...'
                    : selectedItem.email}
                </TopLeadEmail>
                {selectedItem.lead_type === 'referral' && (
                  <>
                    <Flex
                      flexDirection="column"
                      style={{
                        width: '100%',
                        borderLeft: '1px solid lightgray',
                        paddingLeft: '0px',
                        marginTop: '-30px',
                        marginLeft: '127px',
                      }}
                    >
                      <div
                        style={{
                          width: '91px',
                          fontWeight: '500',
                          fontSize: '13px',
                          paddingLeft: '9px',
                          textAlign: 'left',
                          marginTop: '5px',
                          marginLeft: '0',
                        }}
                      >
                        Referred By
                      </div>
                      <div
                        style={{
                          color: 'black',
                          fontWeight: '500',
                          fontSize: '10px',
                          textAlign: 'left',
                          paddingLeft: '10px',
                          marginLeft: '0',
                        }}
                      >
                        {selectedItem.referrer_fullname &&
                        selectedItem.referrer_fullname.length > 15
                          ? selectedItem.referrer_fullname.substring(0, 15) +
                            '...'
                          : selectedItem.referrer_fullname}
                      </div>
                    </Flex>
                  </>
                )}
              </Flex>
              <Flex width="15%" alignItems="center">
                {selectedItem.lead === 'true' &&
                  selectedItem.lead_type === 'leadgen' && (
                    <div className="tooltip-div tooltip-disclosure">
                      <ResponsiveImg
                        src="/assets/images/lead.svg"
                        style={{
                          height: '23px',
                          width: 'auto',
                          marginTop: '-13px',
                          marginLeft: '-22px',
                          position: 'absolute',
                        }}
                      />
                      <span className="tooltip-text tooltiptext-disclosure lead-referral">
                        Lead captured
                      </span>
                    </div>
                  )}

                {selectedItem.lead === 'true' &&
                  selectedItem.lead_type === 'referral' && (
                    <div className="tooltip-div tooltip-disclosure">
                      <ResponsiveImg
                        src="/assets/images/referral.svg"
                        style={{
                          height: '23px',
                          width: 'auto',
                          marginTop: '-13px',
                          marginLeft: '-10px',
                          position: 'absolute',
                        }}
                      />
                      <span className="tooltip-text tooltiptext-disclosure lead-referral-inside">
                        Referral captured
                      </span>
                    </div>
                  )}

                {selectedItem &&
                  selectedItem.engagement_score !== 'na' &&
                  selectedItem.lead_type !== 'referral' && (
                    <DisplayScore
                      height="40px"
                      width="40px"
                      fontSize="14px"
                      borderRadius="18px"
                      border="4px solid"
                      score={selectedItem.engagement_score}
                    />
                  )}
              </Flex>
              {!isEmpty(selectedItem.suggested_investments) &&
                selectedItem.lead_type === 'referral' && (
                  <VerticalLine
                    style={{
                      margin:
                        selectedItem.lead_type === 'referral' ? '0 0' : '0 5px',
                    }}
                  />
                )}
              {selectedItem.lead_type === 'referral' &&
                selectedItem.suggested_investments
                  .slice(0, 1)[0] && 
                selectedItem.suggested_investments
                  .slice(0, 1)
                  .map((item, index) => item && (
                    <SponsoredWrapper
                      mt="10px"
                      style={{
                        marginLeft: '5px',
                        marginTop: 'auto',
                      }}
                    >
                      <Flex
                        width="100%"
                        justifyContent="flex-start"
                        padding="2px 0px"
                        style={{ height: '13px' }}
                      >
                        <img
                          src={item.logo_path}
                          style={{
                            objectFit: 'contain',
                            objectPosition: 'left',
                          }}
                          alt="Company"
                        />
                      </Flex>
                      <Flex
                        width="100%"
                        fontSize="10px"
                        padding="2px 0px"
                        style={{
                          textAlign: 'left',
                          color: '#49484a',
                          fontWeight: '500',
                          whiteSpace: 'nowrap',
                          height: '15px',
                        }}
                      >
                        <span style={{ marginTop: '-10px' }}>
                          <div
                            className="tooltip-div tooltip-disclosure"
                            style={{ paddingTop: 'unset' }}
                          >
                            <p
                              style={{
                                cursor:
                                  item.name.length > 25 ? 'pointer' : 'default',
                              }}
                            >
                              {item.name.length > 25
                                ? item.name.substring(0, 25) + '...'
                                : item.name}
                            </p>
                            {item.name.length > 25 && (
                              <span
                                className="tooltip-text tooltiptext-disclosure"
                                style={{
                                  left: '159px',
                                  top: '-3px',
                                  whiteSpace: 'nowrap',
                                  width: 'initial',
                                  backgroundColor: '#404040',
                                  color: '#ffffff',
                                }}
                              >
                                {item.name}
                              </span>
                            )}
                          </div>
                        </span>
                      </Flex>
                      <Flex
                        justifyContent="space-between"
                        style={{ paddingTop: '2px' }}
                      >
                        <Flex>
                          <span
                            style={{
                              color: '#49484a',
                              fontWeight: 'bold',
                              fontSize: '10px',
                            }}
                          >
                            {item.ticker}
                          </span>
                          &nbsp;
                          {item.matched_topics &&
                            item.matched_topics.length > 0 &&
                            item.matched_topics
                              .slice(0, 1)[0] &&
                            item.matched_topics
                              .slice(0, 1)
                              .map((item, index) => item && (
                                <span
                                  style={{
                                    color: '#49484a',
                                    fontSize: '8px',
                                    fontWeight: 500,
                                    border: '1px solid #49484a',
                                    borderRadius: '2px',
                                    marginRight: '4px',
                                    height: '11px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    padding: '0px 2px',
                                    marginTop: '2px',
                                  }}
                                >
                                  <div
                                    className="tooltip-div tooltip-disclosure"
                                    style={{ paddingTop: 'unset' }}
                                  >
                                    <p
                                      style={{
                                        cursor:
                                          item.length > 7 ? 'pointer' : '',
                                      }}
                                    >
                                      {item.length > 7
                                        ? item.substring(0, 7) + '...'
                                        : item}
                                    </p>
                                    {item.length > 7 && (
                                      <span
                                        className="tooltip-text tooltiptext-disclosure"
                                        style={{
                                          left: '46px',
                                          top: '2px',
                                          whiteSpace: 'nowrap',
                                          width: 'initial',
                                          backgroundColor: '#404040',
                                          color: '#ffffff',
                                        }}
                                      >
                                        {item}
                                      </span>
                                    )}
                                  </div>
                                </span>
                              ))}
                        </Flex>
                        {item.is_sponsored && (
                          <OverviewBadge>
                            <span>Sponsored</span>
                          </OverviewBadge>
                        )}
                      </Flex>
                    </SponsoredWrapper>
                  ))}
            </Flex>
            {!isEmpty(selectedItem.suggested_investments) &&
              selectedItem.lead_type !== 'referral' && (
                <VerticalLine
                  style={{
                    margin:
                      selectedItem.lead_type === 'referral' ? '0 0' : '0 5px',
                  }}
                />
              )}
            {!isEmpty(selectedItem.suggested_investments) &&
              selectedItem.lead_type !== 'referral' &&
              selectedItem.suggested_investments
                .slice(0, 1)[0] &&
              selectedItem.suggested_investments
                .slice(0, 1)
                .map((item, index) => item && (
                  <SponsoredWrapper
                    style={{ height: '57.6px', width: '193px' }}
                  >
                    <Flex
                      width="100%"
                      justifyContent="flex-start"
                      padding="2px 0px"
                      style={{ height: '13px' }}
                    >
                      <img
                        src={item.logo_path}
                        style={{ objectFit: 'contain', objectPosition: 'left' }}
                        alt="Company"
                      />
                    </Flex>
                    <Flex
                      width="100%"
                      fontSize="10px"
                      padding="2px 0px"
                      style={{
                        textAlign: 'left',
                        color: '#49484a',
                        fontWeight: '500',
                        whiteSpace: 'nowrap',
                        height: '15px',
                      }}
                    >
                      <span style={{ marginTop: '-10px' }}>
                        <div
                          className="tooltip-div tooltip-disclosure"
                          style={{ paddingTop: 'unset' }}
                        >
                          <p
                            style={{
                              cursor:
                                item.name.length > 28 ? 'pointer' : 'default',
                            }}
                          >
                            {item.name.length > 28
                              ? item.name.substring(0, 28) + '...'
                              : item.name}
                          </p>
                          {item.name.length > 28 && (
                            <span
                              className="tooltip-text tooltiptext-disclosure"
                              style={{
                                left: '168px',
                                top: '-27px',
                                whiteSpace: 'nowrap',
                                width: 'initial',
                                backgroundColor: '#404040',
                                color: '#ffffff',
                                position: 'relative',
                              }}
                            >
                              {item.name}
                            </span>
                          )}
                        </div>
                      </span>
                    </Flex>
                    <Flex
                      justifyContent="space-between"
                      style={{ paddingTop: '2px' }}
                    >
                      <Flex>
                        <span
                          style={{
                            color: '#49484a',
                            fontWeight: 'bold',
                            fontSize: '10px',
                          }}
                        >
                          {item.ticker}
                        </span>
                        &nbsp;
                        { item &&
                          item.matched_topics &&
                          item.matched_topics.length > 0 &&
                          item.matched_topics.slice(0, 1)[0] &&
                          item.matched_topics.slice(0, 1).map((item, index) => item && (
                            <span
                              style={{
                                color: '#49484a',
                                fontSize: '8px',
                                fontWeight: 500,
                                border: '1px solid #49484a',
                                borderRadius: '2px',
                                marginRight: '4px',
                                height: '11px',
                                display: 'flex',
                                alignItems: 'center',
                                padding: '0px 2px',
                                marginTop: '2px',
                              }}
                            >
                              <div
                                className="tooltip-div tooltip-disclosure"
                                style={{ paddingTop: 'unset' }}
                              >
                                <p
                                  style={{
                                    cursor:
                                      item.length > 6 ? 'pointer' : 'default',
                                  }}
                                >
                                  {item.length > 6
                                    ? item.substring(0, 6) + '...'
                                    : item}
                                </p>
                                {item.length > 6 && (
                                  <span
                                    className="tooltip-text tooltiptext-disclosure"
                                    style={{
                                      left: '39px',
                                      top: '2px',
                                      whiteSpace: 'nowrap',
                                      width: 'initial',
                                      backgroundColor: '#404040',
                                      color: '#ffffff',
                                    }}
                                  >
                                    {item}
                                  </span>
                                )}
                              </div>
                            </span>
                          ))}
                      </Flex>
                      {item.is_sponsored && (
                        <OverviewBadge style={{ float: 'right' }}>
                          <span>Sponsored</span>
                        </OverviewBadge>
                      )}
                    </Flex>
                  </SponsoredWrapper>
                ))}
          </ProspectFeaturedArticle>
        </Flex>
        <Flex pt="10px" pb="10px" pl="14px">
          <OverviewTabHeading style={{ position: 'relative', top: '6px' }}>
            Content{' '}
            {selectedItem.lead_type === 'referral'
              ? selectedItem.referrer_fullname
              : selectedItem.first_name}{' '}
            {selectedItem.lead_type === 'referral'
              ? 'was Referred Through'
              : 'Finds Engaging'}
          </OverviewTabHeading>
        </Flex>
        <Flex>
          {isEmpty(get(analyticsData, 'shares', [])) ? (
            <div
              style={{
                fontSize: '12px',
                height: '260px',
                display: 'flex',
                margin: 'auto',
              }}
            >
              <div style={{ margin: 'auto' }}>No Records Found</div>
            </div>
          ) : (
            <ArticleSliderWrapper type="engagement" width="100%">
              <Slider {...overviewTabSliderSettings}>
                {get(analyticsData, 'shares', [])[0] && get(analyticsData, 'shares', []).map((item, index) => {
                  const trimmedPublisherName =
                    ((item && item.publisher && item.publisher.name) || '')
                      .length > 15
                      ? `${item.publisher.name.substring(0, 15)}...`
                      : item && item.publisher && item.publisher.name;
                  const trimmedTitle =
                    (item.title || '').length > 80
                      ? `${item.title.substring(0, 80)}...`
                      : item.title;
                  const extractedDate = getMonthDate(item.date);
                  const formatted_date = `${extractedDate[0]} ${extractedDate[1]}`;
                  return (
                    <TopLeadWrapper
                      width="213px !important"
                      height="246px"
                      margin="14px 10px 0px 8.2px"
                      padding="0px"
                    >
                      <Flex style={{ height: '99px', width: '100%' }}>
                        <img
                          alt="share"
                          src={getEngagementMediaImage(item.media_urls)}
                          width="212px"
                          onError={onImageError}
                          style={{
                            borderTopLeftRadius: '8px',
                            borderTopRightRadius: '8px',
                            objectFit: 'cover',
                          }}
                        />
                      </Flex>
                      <Flex flexDirection="column" padding="8px 8px 10px 8px">
                        <Flex
                          width="100%"
                          justifyContent="space-between"
                          padding="0px 6px"
                        >
                          <Flex
                            flexDirection="column"
                            justifyContent="flex-start"
                          >
                            {item.webinar_id ? (
                              <TopShareName>{item.webinar_name}</TopShareName>
                            ) : (
                              <TopShareName>
                                {item && item.topic && item.topic.name}
                              </TopShareName>
                            )}
                            {item.webinar_id ? (
                              <WebinarInfo>
                                {moment(
                                  item?.webinardetails?.start_date
                                ).format('D MMMM YYYY')}{' '}
                                ,{' '}
                                {moment(
                                  item?.webinardetails?.start_time,
                                  'hh:mm:ss'
                                ).format('LT')}
                              </WebinarInfo>
                            ) : (
                              <TopPublisherName
                                title={
                                  (
                                    (item &&
                                      item.publisher &&
                                      item.publisher.name) ||
                                    ''
                                  ).length > 15
                                    ? item &&
                                      item.publisher &&
                                      item.publisher.name
                                    : ''
                                }
                              >
                                {trimmedPublisherName}{item.date ? `, ${formatted_date}` : ''}
                              </TopPublisherName>
                            )}
                          </Flex>
                          {!item.webinar_id && (
                            <Flex>
                              {item.lead === 'true' &&
                                item.lead_type &&
                                item.lead_type.split(',')[0] === 'leadgen' && (
                                  <div className="tooltip-div tooltip-disclosure">
                                    <ResponsiveImg
                                      src="/assets/images/lead.svg"
                                      style={{
                                        height: '23px',
                                        width: 'auto',
                                        marginTop: '-2px',
                                        marginLeft: '-22px',
                                        position: 'absolute',
                                      }}
                                    />
                                    <span className="tooltip-text tooltiptext-disclosure">
                                      Lead captured
                                    </span>
                                  </div>
                                )}

                              {item.lead === 'true' &&
                                item.lead_type &&
                                item.lead_type.split(',')[0] === 'referral' && (
                                  <div className="tooltip-div tooltip-disclosure">
                                    <ResponsiveImg
                                      src="/assets/images/referral.svg"
                                      style={{
                                        height: '23px',
                                        width: 'auto',
                                        marginTop: '-2px',
                                        marginLeft: '-22px',
                                        position: 'absolute',
                                      }}
                                    />
                                    <span className="tooltip-text tooltiptext-disclosure">
                                      Referral captured
                                    </span>
                                  </div>
                                )}

                              {item &&
                                item.engagement_score !== 'na' &&
                                ((item.lead_type &&
                                  item.lead_type.split(',')[0] !==
                                    'referral') ||
                                  !item.lead_type) && (
                                  <DisplayScore
                                    score={+item.engagement_score}
                                  />
                                )}
                            </Flex>
                          )}
                        </Flex>
                        <OverviewShareTitle title={item.title}>
                          {trimmedTitle}
                        </OverviewShareTitle>
                        <Flex justifyContent="space-between" padding="10px 8px 0px">
                          <span>Clicks - {item.clicks}</span>
                          <span>Opens - {item.opens}</span>
                        </Flex>
                        {!isEmpty(item.suggested_investments) && item.suggested_investments.slice(0, 1)[0]
                          ? item.suggested_investments
                              .slice(0, 1)
                              .map((item, index) => item && (
                                <SponsoredWrapper mt="10px">
                                  <Flex
                                    width="100%"
                                    justifyContent="flex-start"
                                    padding="2px 0px"
                                    style={{ height: '13px' }}
                                  >
                                    <img
                                      src={item.logo_path}
                                      style={{
                                        objectFit: 'contain',
                                        objectPosition: 'left',
                                      }}
                                      alt="Company"
                                    />
                                  </Flex>
                                  <Flex
                                    width="100%"
                                    fontSize="10px"
                                    padding="2px 0px"
                                    style={{
                                      textAlign: 'left',
                                      color: '#49484a',
                                      fontWeight: '500',
                                      whiteSpace: 'nowrap',
                                      height: '15px',
                                    }}
                                  >
                                    <span style={{ marginTop: '-10px' }}>
                                      <div
                                        className="tooltip-div tooltip-disclosure"
                                        style={{ paddingTop: 'unset' }}
                                      >
                                        <p
                                          style={{
                                            cursor:
                                              item.name.length > 22
                                                ? 'pointer'
                                                : 'default',
                                          }}
                                        >
                                          {item.name.length > 22
                                            ? item.name.substring(0, 22) + '...'
                                            : item.name}
                                        </p>
                                        {item.name.length > 22 && (
                                          <span
                                            className="tooltip-text tooltiptext-disclosure"
                                            style={{
                                              left: '142px',
                                              top: '-27px',
                                              whiteSpace: 'normal',
                                              width: 'auto',
                                              backgroundColor: '#404040',
                                              color: '#ffffff',
                                            }}
                                          >
                                            {item.name}
                                          </span>
                                        )}
                                      </div>
                                    </span>
                                  </Flex>
                                  <Flex
                                    justifyContent="space-between"
                                    style={{ paddingTop: '2px' }}
                                  >
                                    <Flex>
                                      <span
                                        style={{
                                          color: '#49484a',
                                          fontWeight: 'bold',
                                          fontSize: '10px',
                                        }}
                                      >
                                        {item.ticker}
                                      </span>
                                      &nbsp;
                                      {item.matched_topics.length > 0 &&
                                        item.matched_topics
                                          .slice(0, 1)[0] && 
                                        item.matched_topics
                                          .slice(0, 1)
                                          .map((item, index) => item && (
                                            <span
                                              style={{
                                                color: '#49484a',
                                                fontSize: '8px',
                                                fontWeight: 500,
                                                border: '1px solid #49484a',
                                                borderRadius: '2px',
                                                marginRight: '4px',
                                                height: '11px',
                                                display: 'flex',
                                                alignItems: 'center',
                                                padding: '0px 2px',
                                                marginTop: '2px',
                                              }}
                                            >
                                              <div
                                                className="tooltip-div tooltip-disclosure"
                                                style={{ paddingTop: 'unset' }}
                                              >
                                                <p
                                                  style={{
                                                    cursor:
                                                      item.length > 10
                                                        ? 'pointer'
                                                        : 'default',
                                                  }}
                                                >
                                                  {item.length > 10
                                                    ? item.substring(0, 10) +
                                                      '...'
                                                    : item}
                                                </p>
                                                {item.length > 10 && (
                                                  <span
                                                    className="tooltip-text tooltiptext-disclosure"
                                                    style={{
                                                      left: '63px',
                                                      top: '2px',
                                                      whiteSpace: 'nowrap',
                                                      width: 'initial',
                                                      backgroundColor:
                                                        '#404040',
                                                      color: '#ffffff',
                                                    }}
                                                  >
                                                    {item}
                                                  </span>
                                                )}
                                              </div>
                                            </span>
                                          ))}
                                    </Flex>
                                    {item.is_sponsored && (
                                      <OverviewBadge style={{ float: 'right' }}>
                                        <span>Sponsored</span>
                                      </OverviewBadge>
                                    )}
                                  </Flex>
                                </SponsoredWrapper>
                              ))
                          : null}
                      </Flex>
                    </TopLeadWrapper>
                  );
                })}
              </Slider>
            </ArticleSliderWrapper>
          )}
        </Flex>
        <DownloadButtonFlex
          justifyContent="flex-end"
          style={{ paddingTop: '35px' }}
        >
          <LoaderWrapper isLoading={loading}>
            <DownloadButton
              disabled={get(analyticsData, 'shares', []) == null || get(analyticsData, 'shares', []).length === 0}
              onClick={onDownloadCSVClick}
              fontSize="18px"
            />
          </LoaderWrapper>
        </DownloadButtonFlex>
      </Flex>
    </LoaderWrapper>
  );
};

export default LeadInsightTable;
