import React from 'react';
import {
  HeaderCol,
  HeaderSubTitle,
  HeaderTitleContainer,
  HeaderWrapper,
  NoDataMessage,
  RowContainer,
  TableBodyContainer,
  TableCell,
  TableContainer,
  TableRow,
} from './styles';

function Table({
  data = [],
  onRowClick,
  columnStructure = [],
  customStyles = {},
  customEmptyState,
  height,
  fixedHeader = false,
  customMinHeight = true,
}) {
  const handleHeaderClick = (header) => {
    if (header?.enableSort && typeof header?.sortAction === 'function') {
      header.sortAction();
    }
  };

  const handleRowClick = (event, item) => {
    event.stopPropagation();
    if (typeof onRowClick === 'function') {
      onRowClick(item);
    }
  };

  return (
    <>
      <TableContainer customStyles={customStyles}>
        {data?.length ? (
          <TableBodyContainer>
            {/* Column Headers */}
            <HeaderWrapper fixedHeader={fixedHeader}>
              {Array.isArray(columnStructure) &&
                columnStructure.length > 0 &&
                columnStructure.map(
                  ({ header, minWidth, width, maxWidth }, columnIndex) => (
                    <HeaderCol
                      key={columnIndex}
                      minWidth={minWidth}
                      width={width}
                      maxWidth={maxWidth}
                    >
                      <div
                        className="header-title-container"
                        onClick={() => handleHeaderClick(header)}
                      >
                        <HeaderTitleContainer
                          titleAlignment={header?.titleAlignment}
                        >
                          <p className="header-col-title">
                            {header?.title ?? ''}
                          </p>
                          {header.enableSort && header.sortElement}
                        </HeaderTitleContainer>
                        {header?.subTitle && (
                          <HeaderSubTitle
                            titleAlignment={header?.titleAlignment}
                          >
                            <p>{header.subTitle}</p>
                          </HeaderSubTitle>
                        )}
                      </div>
                    </HeaderCol>
                  )
                )}
            </HeaderWrapper>

            {/* Rows */}
            <RowContainer height={height}>
              {Array.isArray(data) &&
                data.map((row, index) => (
                  <TableRow
                    key={index}
                    onClick={(event) => handleRowClick(event, row)}
                    onRowClick={onRowClick}
                    customMinHeight={customMinHeight}
                  >
                    {columnStructure.map(
                      (
                        { container, minWidth, width, render, maxWidth },
                        columnIndex
                      ) => (
                        <TableCell
                          key={columnIndex}
                          minWidth={minWidth}
                          width={width}
                          maxWidth={maxWidth}
                          container={container}
                        >
                          {render(row)}
                        </TableCell>
                      )
                    )}
                  </TableRow>
                ))}
            </RowContainer>
          </TableBodyContainer>
        ) : (
          <>
            {customEmptyState || (
              <NoDataMessage>No Data Available</NoDataMessage>
            )}
          </>
        )}
      </TableContainer>
    </>
  );
}

export default Table;
