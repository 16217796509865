import React from 'react';
import { Container } from '@mui/material';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useInfiniteQuery, useQueryClient } from 'react-query';
import Article from '../common/components/Article';
import ArticleSkeleton from '../common/components/Article/Skeleton';
import ChevronLeft from 'src/assets/icons/ChevronLeft';
import { cloutV2Endpoints } from 'src/services/helpers';
import apiInstance from 'src/services/api';
import { BackAction, Header, Wrapper } from './styles';
import { Articles, ArticlesSkeleton } from '../styles';
import { transformBookmarks } from './utils';

function Bookmarks({ setShowBookmarks }) {
  const queryClient = useQueryClient();
  const LIMIT = 10;

  const { data, isLoading, isFetching, isSuccess, hasNextPage, fetchNextPage } =
    useInfiniteQuery(
      'discover-bookmarks',
      async ({ pageParam = 1 }) => {
        const response = await apiInstance()?.get(
          `${cloutV2Endpoints?.baseUrl}/discover/bookmarks?page_no=${pageParam}&limit=${LIMIT}`
        );
        return transformBookmarks(response?.result);
      },
      {
        getNextPageParam: (lastPage) => {
          return lastPage?.pagination?.next_page;
        },
        refetchOnMount: true,
      }
    );

  const invalidateQueries = () => {
    queryClient.refetchQueries(['discover-bookmarks']);
    queryClient.refetchQueries(['discover-my-content']);
    queryClient.refetchQueries(['news-feed']);
    queryClient.refetchQueries(['branded-articles']);
    queryClient.refetchQueries(['discover-recommended-articles']);
    queryClient.refetchQueries(['discover-search-results']);
  };

  const bookmarksData =
    isSuccess &&
    data?.pages.map((page) =>
      page?.articles?.map((article) => (
        <Article
          key={article.id}
          article={article}
          invalidateQueries={invalidateQueries}
        />
      ))
    );

  const loader = () => {
    return (
      <ArticlesSkeleton>
        {Array.from(Array(6)?.keys())?.map((article) => (
          <ArticleSkeleton key={article} />
        ))}
      </ArticlesSkeleton>
    );
  };

  return (
    <Container>
      <Wrapper>
        <Header>
          <BackAction onClick={() => setShowBookmarks(false)}>
            <ChevronLeft />
          </BackAction>
          <h3>Bookmarks</h3>
        </Header>

        {isLoading || isFetching ? (
          loader()
        ) : (
          <InfiniteScroll
            dataLength={+data?.pages?.length * 9 || 0}
            next={() => fetchNextPage()}
            hasMore={hasNextPage && !isLoading}
            loader={loader()}
          >
            <Articles>{bookmarksData}</Articles>
          </InfiniteScroll>
        )}
      </Wrapper>
    </Container>
  );
}

export default Bookmarks;
