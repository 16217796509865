import { faFillDrip } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useRef, useState, useEffect } from 'react';
import { ChromePicker } from 'react-color';
import { ColorPick, ColorPickBox } from './ColorPicker.styles';

function ColorPicker({ onColorChange, deafultColor }) {
  const menuWrapper = useRef(null);
  const [isColorPickerOpen, setColorPickerOpen] = useState(false);
  const [color, setColor] = useState(deafultColor || '#6351ed');

  const handleClickOutside = (e) => {
    if (menuWrapper.current && !menuWrapper.current.contains(e.target)) {
      setColorPickerOpen(false);
    }
  };

  const handleColorChange = (color) => {
    setColor(color);
    onColorChange(color);
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ColorPick
      ref={menuWrapper}
      onClick={() => setColorPickerOpen(!isColorPickerOpen)}
    >
      <FontAwesomeIcon icon={faFillDrip} size='1x'/>
      {isColorPickerOpen && (
        <ColorPickBox onClick={(e) => e.stopPropagation()}>
          <ChromePicker
            disableAlpha
            onChange={({ hex }) => handleColorChange(hex)}
            color={color}
          />
        </ColorPickBox>
      )}
    </ColorPick>
  );
}

export default ColorPicker;
