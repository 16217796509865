import React from 'react'
import { ComplianceRejectAllContainer } from '../../containers';
import withContentLayout from '../../hoc/withContentLayout';

const ComplianceRejectAllLayout = () => {
  return (
    <ComplianceRejectAllContainer />
  )
}

export default withContentLayout(ComplianceRejectAllLayout);