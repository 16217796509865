import React, { useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { CircularProgress } from '@mui/material';
import Button from 'src/componentsV2/Button';
import { cascade } from './mutation';
import {
  ModalActions,
  CascadeCompliance,
  CascadeWrapper,
  Label,
  RadioButton,
  RadioButtonGroup,
  RadioButtonWrapper,
} from './styles';

function Cascade({
  content,
  setShowCascadeModal,
  refetch,
  firmSettings,
  isFirmLevelAdmin,
  userData,
}) {
  const [selectedTab, setSelectedTab] = useState('no');
  const [showComplianceOption, setShowComplianceOption] = useState(false);
  const { mutate, isLoading } = useMutation(cascade, {
    onSuccess: () => {
      refetch();
    },
  });

  useEffect(() => {
    if (
      isFirmLevelAdmin &&
      firmSettings &&
      Object.keys(firmSettings)?.length > 0
    ) {
      const complianceOption =
        firmSettings?.firm_compliance?.[userData?.details?.company?.id]
          ?.compliance_is;

      if (complianceOption === 'always_on' || complianceOption === 'on') {
        setSelectedTab('yes');
      } else if (
        complianceOption === 'always_off' ||
        complianceOption === 'off'
      ) {
        setSelectedTab('no');
      }

      setShowComplianceOption(complianceOption === 'optional');
    } else {
      setShowComplianceOption(userData?.compliance === 'optional');
    }
  }, [
    firmSettings,
    isFirmLevelAdmin,
    userData.compliance,
    userData.details.company.id,
  ]);

  const cascadeArticle = () => {
    try {
      mutate(
        {
          article: content,
          skipCompliance: selectedTab === 'no' ? true : false,
        },
        {
          onSuccess: () => {
            setShowCascadeModal(false);
          },
        }
      );
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <CascadeWrapper>
      {showComplianceOption && (
        <CascadeCompliance>
          <h4>Require compliance</h4>
          <RadioButtonGroup>
            <RadioButtonWrapper onClick={() => setSelectedTab('yes')}>
              <RadioButton
                type="radio"
                checked={selectedTab === 'yes'}
                value={selectedTab}
                onChange={() => setSelectedTab('yes')}
              />
              <Label>Yes</Label>
            </RadioButtonWrapper>
            <RadioButtonWrapper onClick={() => setSelectedTab('no')}>
              <RadioButton
                type="radio"
                checked={selectedTab === 'no'}
                value={selectedTab}
                onChange={() => setSelectedTab('no')}
              />
              <Label>No</Label>
            </RadioButtonWrapper>
          </RadioButtonGroup>
        </CascadeCompliance>
      )}
      <ModalActions>
        <Button
          variant="outline"
          fullWidth
          onClick={() => setShowCascadeModal(false)}
        >
          Cancel
        </Button>
        <Button fullWidth onClick={cascadeArticle} disabled={isLoading}>
          {isLoading ? <CircularProgress size={15} /> : 'Cascade'}
        </Button>
      </ModalActions>
    </CascadeWrapper>
  );
}

export default Cascade;
