import { styled } from '@mui/material';

export const Container = styled('div')(() => ({
  height: '100%',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '1rem',
  padding: '1rem',
  borderRadius: '0.5rem',
  border: '1px solid #BBBEF7',
  background: '#fff',
}));

export const Header = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: '1rem',
}));

export const Title = styled('h3')(() => ({
  font: '500 1rem Poppins, sans-serif',
  color: '#000',
  margin: 0,
}));

export const Content = styled('div')(() => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyContent: 'space-between',
  gap: '1rem',
}));

export const Contacts = styled('div')(() => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '1rem',

  h5: {
    font: '600 2.25rem Poppins, sans-serif',
    color: '#000',
    margin: '0 0.5rem',
  },
}));

export const Message = styled('div')(({ bgColor, borderColor }) => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  position: 'relative',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '1rem',
  padding: '1rem',
  backgroundColor: bgColor,
  borderRadius: '0.5rem',
  border: `1px solid ${borderColor}`,

  h5: {
    font: '500 0.875rem/1.5rem Poppins, sans-serif',
    color: '#475467',
    margin: 0,
    textAlign: 'left',
  },

  strong: {
    color: '#101828'
  },

  p: {
    font: '400 0.75rem Poppins, sans-serif',
    color: '#475467',
    margin: 0,
    textAlign: 'left',
  },

  svg: {
    color: borderColor,
    fontSize: '2.25rem',
  },
}));

export const TriangleShape = styled('div')(({ borderColor }) => ({
  width: 10,
  height: 10,
  position: 'absolute',
  top: -20,
  left: 10,
  border: '10px solid transparent',
  borderBottom: `10px solid ${borderColor}`,
}));

export const TriangleShape2 = styled('div')(({ borderColor }) => ({
  width: 10,
  height: 10,
  position: 'absolute',
  top: -18,
  left: 11,
  border: '9px solid transparent',
  borderBottom: `9px solid ${borderColor}`,
}));

export const ContactsDescription = styled('div')(() => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
}));
