/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import { Flex, Box } from '@rebass/grid';

import { MiniArticle, Button, LoaderWrapper, FileUpload } from '../../../components';
import { themesData, toBase64 } from '../../../utils';
import EditTitleModal from './EditTitleModal';
import { } from './ContentOverview.styles';
import EditSummaryModal from './EditSummaryModal';

const styles = {
    Button: {
      color: themesData.white,
      background: themesData.purpleishBlue,
      borderRadius: '0px',
      fontSize: '11px',
      fontFamily: 'Poppins'
    }
}

const ContentOverview = ({
    handleContentOverviewClick,
    setShowContentOverview,
    setShowSelectThemes,
    setShowContent,
    pdfImage,
    setPdfImage,
    isPdf,
    onUpdateTheme,
    isAdmin,
    fromImagePreviewTab,
    nextClick,
    isFromPdf,
    isFromRssFeed,
    themeMessage,
    setThemeMessage,
    userSummary,
    fromUploadSuccess,
    setUserSummary,
    isFromImageOnly,
    imageUploaded,
    setImage,
    setError,
    error,
    ...rest
}) => {

    const [loading, setLoading] = useState(false);
    const [showEditTitleModal, toggleEditTitleModal] = useState(false);
    const [showEditSummaryModal, toggleEditSummaryModal] = useState(false);

    useEffect(() => {
        localStorage.removeItem('preSelectedTopicsView');
        localStorage.setItem('hideTopicMsg', true);
    }, []);

    const handleClick = (type) => {
        console.log(type);
        // setError('');
        setLoading(true);
        if(type === 'addContent') {
            onUpdateTheme([], 'Content Overview', type);
        } else if(type === 'shareNow' || type === 'viewContent') {
            onUpdateTheme([], 'Content Overview', type);
        } else {
            onUpdateTheme([], 'Internal Use', type);
        }
    }

    const editImageHandler = thumbnailImage => {
        console.log('FILE = ', thumbnailImage);
        const fileSize = thumbnailImage[0].size / 1024 / 1024;
        // if(fileSize > 10) {
        //     console.log('ERROR- - - - ');
        //     return setError('File size should be less than or equal to 10 MB');
        // }
        toBase64(thumbnailImage[0]).then(file => {
            setError('');
			// console.log('[FILE] - ', file, 'PATH = ', file.path);
            if(isFromPdf) 
                setPdfImage(file);
            else if(isFromImageOnly) 
                setImage(file);
            else
                setThemeMessage({...themeMessage, url: file});
		});
    }

    const nextClickHandler = () => {
        setLoading(true);
        nextClick();
    }

    const editTitleHandler = newTitle => {
        setThemeMessage({...themeMessage, topic: newTitle});
        toggleEditTitleModal(false)
        if(!newTitle)
            setError('Title cannot be empty!');
        else
            setError('');
    }

    const summarySaveHandler = newSummary => {
        setUserSummary(newSummary);
        toggleEditSummaryModal(false);
    }

    const errorHandler = err => {
        console.log('ERROR - - -- - - -', err);
        setError(err);
    }

    console.log('ERROR - - - - ', error);
    return (
        <div>
            <Flex width={1} flexDirection="column" style={{overflow:"hidden"}}>
                {!fromImagePreviewTab && (
                    <Box style={{fontSize: '16px', paddingBottom: '5px'}}>You're Almost Finished...</Box>
                )}
                <Flex flexDirection='column'>
                {/* <Flex flexDirection={fromImagePreviewTab ? 'column' : 'row'}> */}
                    <Box style={{width: '24%', margin: 'auto'}}>
                    {/* <Box style={{width: '24%', margin: 'auto', marginRight: fromImagePreviewTab ? 'auto' : '10px'}}> */}
                        <MiniArticle
                            {...rest}
                            pdfImage={isFromImageOnly ? imageUploaded : pdfImage}
                            isPdf={isPdf}
                            isFromAddContent={true}
                            userSummary={userSummary}
                            fromUploadSuccess={fromUploadSuccess}
                            fromImagePreviewTab={fromImagePreviewTab}
                        />
                    </Box>
                    {!fromImagePreviewTab ? (
                        (isAdmin && !isFromImageOnly) ? (
                            <Box 
                                style={{ 
                                    margin: 'auto'
                                }}>
                            {/* <Box style={{ width: '62%', margin: 'auto', display: 'flex', flexDirection: 'column' }}> */}
                                
                                <LoaderWrapper isLoading={loading}>
                                     
                                    {/* <Flex width={1} flexDirection='column' justifyContent='flex-start' alignItems='flex-start'> */}
                                        <Button 
                                            styles={styles.Button}
                                            margin="20px 10px"
                                            onClick={() => handleClick('internalUse')}
                                        >
                                            Make Internal Use Only
                                        </Button>
                                        <Button 
                                            styles={styles.Button}
                                            margin="20px 10px"
                                            onClick={() => handleClick('viewContent')}
                                        >
                                            Submit and Go to My Content
                                        </Button>
                                        <Button 
                                            styles={styles.Button}
                                            margin="20px 10px"
                                            onClick={() => handleClick('addContent')}
                                        >
                                            Submit and Add More Content
                                        </Button>
                                    
                                </LoaderWrapper>
                            </Box>
        
                        ) : (
                                <Box>
                                    <LoaderWrapper isLoading={loading}>
                                        {/* <Button styles={styles.Button} onClick={() => handleClick('shareNow')}>Share Now</Button>&nbsp;&nbsp; */}
                                        <Button styles={styles.Button} onClick={() => handleClick('viewContent')}>View In Content Library</Button>&nbsp;&nbsp;
                                        <Button styles={styles.Button} onClick={() => handleClick('addContent')}>Add More Content</Button>
                                    </LoaderWrapper>
                                </Box>
                            )
                    ) : (
                        <Box>
                            {showEditTitleModal ? (
                                <EditTitleModal
                                    close={() => toggleEditTitleModal(false)}
                                    isOpen={showEditTitleModal}
                                    save={newTitle => editTitleHandler(newTitle)}
                                    defaultTitle={themeMessage.topic} 
                                />
                            ) : null}
                            {showEditSummaryModal && (
                                <EditSummaryModal
                                    close={() => toggleEditSummaryModal(false)}
                                    isOpen={showEditSummaryModal}
                                    userSummary={userSummary}
                                    saveHandler={summarySaveHandler}
                                />
                            )}
                            {error && (
                                <div style={{fontSize: '10px', color: '#6351ed', marginTop: '10px'}}>
                                    {error}
                                </div>
                            )}
                            <LoaderWrapper isLoading={loading}>
                                <Button 
                                    styles={{
                                        ...styles.Button, 
                                        padding: '4px 38px', 
                                    }} 
                                    // disabled={error} 
                                    // onClick={!error ? nextClickHandler : () => {}}
                                    onClick={nextClickHandler}
                                >
                                    Next
                                </Button>
                                <Flex 
                                    justifyContent='space-evenly'
                                    // justifyContent={isFromPdf || isFromImageOnly ? 'center' : 'space-evenly'} 
                                    alignItems='flex-end' 
                                    // alignItems={isFromPdf || isFromImageOnly ? 'center' : 'flex-end'} 
                                    style={{width: '28%', margin: 'auto'}}
                                    // style={isFromRssFeed ? {width: '28%', margin: 'auto'} : {}}
                                >
                                    {isFromRssFeed ? (
                                        <a href='#' style={{fontSize: '10px', fontWeight: 'bold'}} onClick={() => toggleEditTitleModal(true)}>Edit Title</a>
                                    ) : null}
                                    <FileUpload
                                        id="pdfUpload"
                                        name="pdfUpload"
                                        type="button"
                                        onClick={() => {}}
                                        onChange={file => editImageHandler(file)}
                                        asLabel={true}
                                        label="Edit Image"
                                        color='#6351ed'
                                        maxSizeMB={10}
                                        borderRadius='0px'
                                        border='none'
                                        padding='7px 28px'
                                        fontSize='11px'
                                        styles={{
                                            textDecoration: 'underline', 
                                            background: 'transparent', 
                                            fontSize: '10px'
                                        }}
                                        wrapperStyles={{ display: 'flex', justifyContent: 'center' }}
                                        fromImagePreviewTab={true}
                                        accept=".png,.jpeg,.jpg,.svg,.bmp"
                                        onError={err => errorHandler(err)}
                                    />
                                    <a 
                                        href='#' style={{fontSize: '10px', fontWeight: 'bold'}} 
                                        onClick={() => toggleEditSummaryModal(true)}
                                    >
                                        Edit Summary
                                    </a>
                                </Flex>
                            </LoaderWrapper>
                        </Box>
                    )}
                </Flex>
            </Flex>
        </div>
    )
}

export default ContentOverview;