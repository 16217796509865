import styled from 'styled-components';
import HomeBackground from 'src/assets/background/home/home.svg';
import { Flex } from '@rebass/grid';
import theme from './colors';

import { TabPanel } from '../Tabs';
import { themesData } from '../../utils';

export const Wrapper = styled('div')`
  width: 100%;
  height: 100%;
  min-height: 60vh;
  background: ${(props) =>
    props.showBackground ? `url(${HomeBackground})` : '#fff'};
  background-size: 100%;
  background-repeat: no-repeat;
`;

export const ResponsiveImg = styled.img`
  height: ${({ height, small, asMioSuccessModal }) =>
    height || small ? '16px' : asMioSuccessModal ? '76px' : '100%'};
  width: ${({ width, small, asMioSuccessModal }) =>
    width || small ? '16px' : asMioSuccessModal ? '109px' : 'auto'};
  margin-left: ${(props) => (props.asMioEditor ? '15px' : '0')};
`;

export const Grid = styled(Flex)`
  width: 100%;
  padding: 0;
  margin: 0 auto;
`;

export const DripGrid = styled(Flex)`
  width: 100%;
`;

export const ButtonNoBorderBackground = styled.button`
  border: 0px;
  background-color: ${({ background }) => background || 'transparent'};
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  color: ${({ color }) => color || '#6351ed'} !important;
  opacity: ${({ disabled }) => (disabled ? '0.4' : '')};
  font-size: ${({ fontSize }) => fontSize || '12px'};
  font-family: 'Poppins';
  width: ${({ width }) => width || ''};
  padding: ${({ padding }) => padding || ''};
  margin: ${({ margin }) => margin || ''};
  text-decoration: ${({ textDecoration }) => textDecoration || ''};
  font-weight: ${({ fontWeight }) => fontWeight || ''};

  .tool-tip {
    position: relative;
    &:hover {
      .tooltip-text {
        visibility: visible;
      }
    }
  }
  .tooltip-text {
    visibility: hidden;
    width: auto;
    background-color: #49484a;
    color: #cccccc;
    text-align: center;
    border-radius: 6px;
    padding: 5px 5px;
    font-size: 10px;
    text-align: left;
    /* Position the tooltip */
    position: absolute;
    z-index: 1;
    top: 100%;
    right: 0%;
    margin-left: -60px;
    display: flex;
    align-items: center;
  }
`;

export const StyledHeading = styled.p`
  margin: ${({ isFromMyClients }) => (isFromMyClients ? '5px 0 0 0' : '5px 0')};

  font-size: ${({ fontSize }) => fontSize || '11px'};
  font-weight: 600;
  width: ${({ width }) => width || '75px'};
  height: ${({ height }) => height || '24px'};
  overflow: hidden;
  display: -webkit-box;
  word-break: break-word;
  -webkit-box-orient: vertical;
  text-transform: capitalize;
  -webkit-line-clamp: 2;
  word-spacing: initial;
`;

export const StyledFloatInput = styled.input`
  padding-left: 10px;
  margin-bottom: 0;
  border-radius: 8px;
  height: ${({ height }) => height || '35px'};
  font-size: 14px;
  background-color: #e4e4e4;
  box-shadow: none;
  padding-right: ${({ pr }) => pr || '25px'};
  font-family: 'Poppins';
  width: 100%;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  border: 1px solid #ccc;
  color: ${({ color, name, value }) =>
    name === 'email' && value ? themesData.purpleyGrey : color || '#7b7b7b'};
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  :focus {
    border-color: #6351ed;
  }
  :focus::placeholder {
    color: transparent;
  }
  ::placeholder {
    color: ${themesData.purpleishBlue};
  }
  :focus + label {
    color: ${({ color }) => color || '#6200EE'};
    top: 0;
    transform: translateY(-90%) scale(0.9);
    left: 0;
    margin-left: 0px;
    font-size: 14px;
  }
  :not(:placeholder-shown) + label {
    top: 0;
    transform: translateY(-90%) scale(0.9);
    left: 0;
    margin-left: 0px;
    font-size: 14px;
  }
  ::-webkit-inner-spin-button,
  ::-webkit-outer-spin-button {
    opacity: 1;
  }
`;

export const StyledInput = styled.input`
  padding-left: 10px;
  margin-bottom: 0;
  border-radius: 8px;
  height: ${({ height }) => height || '35px'};
  font-size: 14px;
  background-color: #e4e4e4;
  box-shadow: none;
  padding-right: 25px;
  font-family: 'Poppins';
  width: 100%;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  border: 1px solid #ccc;
  color: ${({ color }) => color || '#7b7b7b'};
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  :focus {
    border-color: #6351ed;
  }
  :focus::placeholder {
    color: transparent;
  }
  ::placeholder {
    color: ${({ placeholderColor }) =>
      placeholderColor || themesData.purpleishBlue};
  }
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export const StyledInputLarge = styled.input`
  margin-bottom: ${({ marginBottom }) => marginBottom || '15px'};
  box-shadow: none;
  background-color: ${({ bgColor }) => bgColor || '#e4e4e4'};
  border: ${({ border }) => border || '1px solid #a5a5a5'};
  display: block;
  width: ${({ width }) => width || '100%'};
  height: ${({ height }) => height || '34px'};
  padding: ${({ padding }) => padding || '6px 12px'};
  font-size: ${({ fontSize }) => fontSize || '14px'};
  line-height: 1.42857143;
  font-family: 'Poppins';
  color: ${({ fontColor }) => fontColor || '#555'};
  background-image: none;
  border-radius: ${({ borderRadius }) => borderRadius || '4px'};
  :focus {
    border-color: #6351ed;
  }
  ::placeholder {
    color: ${({ placeholderColor }) => placeholderColor || '#6351ed'};
    text-align: ${({ placeholderAlignment }) => placeholderAlignment || 'left'};
    font-size: ${({ placeholderSize }) => placeholderSize || ''};
  }
  :focus::placeholder {
    color: transparent;
  }
  @media (max-width: 768px) {
    font-size: 12px;
  }
`;

export const StyledButton = styled.button`
  ${({ upperCase }) => (upperCase ? 'text-transform:uppercase' : '')};
  font-family: Poppins;
  font-size: ${({ fontSize }) => fontSize || '13px'};
  padding: ${({ padding }) => padding || '7px 20px;'};
  background-color: ${({ bgColor }) => bgColor || '#6351ed'};
  color: ${({ color }) => color || '#fff'};
  border: ${({ borderColor }) => {
    return borderColor ? `1px solid ${borderColor}` : '1px solid #6351ed';
  }};
  position: ${({ position }) => position || ''};
  right: ${({ right }) => right || ''};
  border-radius: ${({ borderRadius }) => borderRadius || '0px'};
  margin: ${({ margin }) => margin || '20px 0px 0px 0px'};
  cursor: ${({ cursor }) => cursor || 'pointer'};
  height: ${({ height }) => height || 'auto'};
  width: ${({ width }) => width || 'auto'};
  ${({ disabled }) =>
    disabled && 'opacity: 0.5;pointer-events:none;border: none;'}
  ${({ isSecondary }) =>
    isSecondary &&
    `
    background-color: #6351ed;
    border: 1px solid #6351ed;
    color: #fff;
  `}
  &.active {
    background-color: ${({ activeColor }) => activeColor || '#6351ed'};
    color: #fff;
  }
  :disabled {
    background-color: #adadad;
    color: #fff;
  }
`;

export const TwitterButton = styled(ButtonNoBorderBackground)`
  background-color: #00aced;
  color: #fff;
  padding: 2px 8px;
  border-radius: 4px;
`;

export const LinkedInButton = styled(ButtonNoBorderBackground)`
  background-color: #0077b5;
  color: #fff;
  padding: 2px 8px;
  border-radius: 4px;
`;

export const ErrorContainer = styled.div`
  padding: ${({ padding }) => padding || '3px'};
  line-height: 16px;
  font-size: 12px;
  text-align: ${({ align }) => align || 'left'};
  color: #6351ed;
`;

const SpriteIcons = styled.div`
  background: url(/assets/images/mio-sprite.png) no-repeat;
  display: inline-block;
`;

export const ReachIcon = styled(SpriteIcons)`
  background-position: 0px -100px;
  width: 35px;
  height: 20px;
`;

export const UpGreenIcon = styled(SpriteIcons)`
  background-position: -52px -48px;
  width: 10px;
  height: 9px;
`;

export const DownRedIcon = styled(SpriteIcons)`
  background-position: -62px -48px;
  width: 10px;
  height: 9px;
`;

export const TopLevelTabPanel = styled(TabPanel)`
  background: linear-gradient(
    to bottom,
    rgba(252, 252, 252, 1) 0%,
    rgba(242, 242, 242, 1) 52%,
    rgba(242, 242, 242, 1) 52%,
    rgba(242, 242, 242, 1) 52%,
    rgba(227, 227, 227, 1) 100%
  );
`;

export const MioSelect = styled.select`
  font-family: 'Poppins';
  height: ${({ height }) => height || '35px'};
  padding: ${({ padding }) => padding || '7px 10px'};
  font-size: ${({ fontSize }) => fontSize || '14px'};
  width: ${({ width }) => width || ''};
  margin: ${({ margin }) => margin || '0px'};
  border: ${({ border }) => border || `1px solid ${themesData.purpleishBlue}`};
  color: ${themesData.purpleishBlue};
  cursor: ${({ disabled }) => (disabled ? '' : 'pointer')};
  border-radius: ${({ borderRadius }) => borderRadius || ''};
  background: ${({ background }) => background || '#eee'};
  text-align: ${({ textAlign }) => textAlign || 'center'};
  option {
    background-color: #fff;
    color: ${themesData.purpleishBlue};
    padding: 5px 0;
    border-radius: 6px;
    font-family: 'Poppins';
  }
`;

export const MioTextArea = styled.textarea`
  padding: 6px 35px;
  border-radius: 8px;
  height: auto;
  resize: none;
  margin-bottom: 15px;
  width: 100%;
  font-size: 13px;
  text-align: center;
  background-color: #eee;
`;

export const DownloadSampleDiv = styled.a`
  text-align: left;
  padding-left: 20px;
  padding-top: 30px;
  display: block;
  text-decoration: none;
  ${({ padding }) => `padding: ${padding};`};
  z-index: 10;
`;

export const CommonZoomStyles = `
 
`;

export const PremiumModalContent = styled.h6`
  font-size: 14px;
  font-weight: bold;
  margin: 0;
`;

export const LayoutInitialWrapper = styled.div`
  background: linear-gradient(
    to bottom,
    rgba(252, 252, 252, 1) 0%,
    rgba(242, 242, 242, 1) 52%,
    rgba(242, 242, 242, 1) 52%,
    rgba(242, 242, 242, 1) 52%,
    rgba(227, 227, 227, 1) 100%
  );
  height: 100%;
  width: 100%;
  margin-top: 30px;
  border: 1px solid #e6e6e6;
  border-radius: 6px;
`;

export const RightToolTipWrapper = styled.div.attrs({
  className: 'right-tooltip-wrapper',
})`
  position: relative;
  .tooltiptext {
    visibility: hidden;
    width: 200px;
    background-color: #404040;
    color: #cccccc;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    top: -6px;
    right: 27px;
  }

  .tooltiptext::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 100%;
    margin-top: -5px;
    border-width: 5px;
    border-style: solid;
    transform: rotate(180deg);
    border-color: transparent #404040 transparent transparent;
  }
  &:hover .tooltiptext {
    visibility: visible;
  }
`;
