import React from 'react';
import { Button, LoaderWrapper, Modal, ModalBody, ModalCloseButton, ModalHeader } from '../../../components';
import { ScheduleModal } from '../../../components/ShareContentContainerV2/ShareContentContainer.styles';
import moment from 'moment';
import { themesData } from '../../../utils';
import { HoverButton } from '../../ConfigureContainer/EmailList/EmailList.styles';
import { Flex } from '@rebass/grid';

const SuccessScheduleModal = ({
  open,
  setOpen,
  scheduleValue,
  setShowTabs,
  setSelectedIndex,
  showComplianceMessage
}) => {
  console.log('SHOW COMPLIANCE MESSAGE SUCCESS MODAL - -  ', showComplianceMessage);
  const handleClose = () => {
    setOpen(false);
    setShowTabs(false);
    setSelectedIndex(0);
  }
  
  return (
    <ScheduleModal
      onRequestClose={handleClose}
      shouldCloseOnOverlayClick
      isOpen={open}
    >
      <ModalHeader style={{ textAlign: 'center' }}>
        <ModalCloseButton onClick={handleClose} />
      </ModalHeader>
      <img src="/assets/images/Calender.svg" alt="" />
      <div style={{ paddingTop: 10, color: themesData.charcoalGrey }}>
        {showComplianceMessage ? 'Newsletter Sent to Compliance for approval' : 'Newsletter has been Scheduled To Be Sent On'}
        <br />
        {!showComplianceMessage && moment(scheduleValue).format('Do MMM YYYY  |  h:mm A')}
        <br />
      </div>
    </ScheduleModal>
  );
}

const SuccessModal = ({
  open,
  setOpen,
  setShowTabs,
  setSelectedIndex,
  showComplianceMessage
}) => {
  console.log('SHOW COMPLIANCE MESSAGE SCHEDULE MODAL - -  ', showComplianceMessage);
  const handleClose = () => {
    setOpen(false);
    setShowTabs(false);
    setSelectedIndex(0);
  }
  return (
    <ScheduleModal
      onRequestClose={handleClose}
      shouldCloseOnOverlayClick
      isOpen={open}
    >
      <ModalHeader style={{ textAlign: 'center' }}>
        <ModalCloseButton onClick={handleClose} />
      </ModalHeader>
      <img src="/assets/images/newsletter-check.svg" alt="" />
      <div style={{ paddingTop: 10, color: themesData.charcoalGrey }}>
        {showComplianceMessage ? 'Newsletter Sent to Compliance for approval' : 'Newsletter Sent Successfully'}
      </div>
    </ScheduleModal>
  );
}

const DeleteModal = ({
  open,
  setOpen,
  onAccept,
  onReject,
  type
}) => {

  const handleClose = () => {
    setOpen(false);
  }
  return (
    <ScheduleModal
      onRequestClose={handleClose}
      shouldCloseOnOverlayClick
      isOpen={open}
    >
      <ModalHeader style={{ textAlign: 'center' }}>
        <ModalCloseButton onClick={handleClose} />
      </ModalHeader>
      <img src="/assets/images/Newsletter_Delete_Icon.svg" style={{width: '82px'}} alt="" />
      <div style={{ paddingTop: 10, color: themesData.charcoalGrey }}>
        Are You Sure Want To <br />
        Delete This {type}?
      </div>
      <div>
        <HoverButton margin='9px 10px 0px 0px' padding='5px 28px' bgColor='#fff' hoverBackground='#6351ed' hoverColor='#fff' color='#6351ed' borderColor='#fff' onClick={onAccept}>
          Yes
        </HoverButton>
        <HoverButton margin='9px 0px 0px 0px' padding='5px 28px' bgColor='#fff' color='#6351ed' hoverBackground='#6351ed' hoverColor='#fff' borderColor='#fff' onClick={onReject}>
          No
        </HoverButton>
      </div>
    </ScheduleModal>
  )
}

const DeleteBucketArticlesModal = ({
  open,
  setOpen,
  onAccept,
  onReject,
  deleteLoading
}) => {

  const handleClose = () => {
    setOpen(false);
  }
  return (
    <ScheduleModal
      onRequestClose={handleClose}
      shouldCloseOnOverlayClick
      isOpen={open}
      width="35%"
    >
      <ModalHeader style={{ textAlign: 'center' }}>
        <ModalCloseButton onClick={handleClose} />
      </ModalHeader>
      <img src="/assets/images/Newsletter_Delete_Icon.svg" style={{width: '82px'}} alt="" />
      <div style={{ paddingTop: 10, color: themesData.charcoalGrey }}>
        Are you sure you want to clear all articles <br />
        available for your newsletters?
      </div>
      <LoaderWrapper isLoading={deleteLoading}>
        <HoverButton margin='9px 10px 0px 0px' padding='5px 28px' bgColor='#fff' hoverBackground='#6351ed' hoverColor='#fff' color='#6351ed' borderColor='#fff' onClick={onAccept}>
          Yes
        </HoverButton>
        <HoverButton margin='9px 0px 0px 0px' padding='5px 28px' bgColor='#fff' color='#6351ed' hoverBackground='#6351ed' hoverColor='#fff' borderColor='#fff' onClick={onReject}>
          No
        </HoverButton>
      </LoaderWrapper>
    </ScheduleModal>
  )
}


const CloneComplianceModal = ({ show, toggleShow, click }) => {

  const clickHandler = () => {
    toggleShow(false);
    click();
  }

  return (
    <Modal size='medium-small' isOpen={show} onRequestClose={() => toggleShow(false)} >
      <ModalHeader>
        <ModalCloseButton onClick={() => toggleShow(false)} />
      </ModalHeader>
      <ModalBody>
        <h3>Changes you have made require compliance review. Re-submit to Compliance?</h3>
        <Flex justifyContent='center' alignItems='baseline'>
          <Button margin="20px 10px 0px 0px" bgColor='#6351ed' width='auto' onClick={clickHandler}>Yes</Button>
          <Button bgColor='#6351ed' width='auto' onClick={clickHandler}>No</Button>
          {/* <button style={{color: 'white', border: '0', backgroundColor: '#6351ed', width: '40px', padding: '8px 10px', marginRight: '10px'}} onClick={clickHandler}>Yes</button>
          <button style={{color: 'white', border: '0', backgroundColor: '#6351ed', width: '40px', padding: '8px 10px', marginRight: '10px'}} onClick={() => toggleShow(false)}>No</button> */}
        </Flex>
      </ModalBody>
    </Modal>
  );
}


export { 
  SuccessScheduleModal,
  SuccessModal,
  DeleteModal,
  DeleteBucketArticlesModal,
  CloneComplianceModal
}