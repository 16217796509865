/* eslint-disable react-hooks/exhaustive-deps */
import { Flex } from '@rebass/grid';
import React, { useState, useEffect } from 'react';
import { TabList, Tabs } from '../../../components';
import { themesData } from '../../../utils';
import { TabConfig } from '../../ConfigureContainer/screenConfigure.styles';
import {
  BorderWhiteBox,
  DashboardAdminSubTab,
} from '../../DashboardContainer/DashboardContainer.styles';
import { DraftOutbox } from '../DraftOutbox';
import { ScheduledOutbox } from '../ScheduledOutbox';
import { SentOutbox } from '../SentOutbox';
import CloneNLModal from '../CloneNLModal/CloneNLModal';

const OutboxTab = ({
  setSelectedIndex,
  selectedIndex,
  newsletterOutboxRequest,
  outBoxData,
  outBoxLoading,
  page,
  setPage,
  userData,
  newsletterSetState,
  newsletterDeleteRequest,
  newsletterId,
  isArticleDeleting,
  setShowTabs,
  setShowOutbox,
  setSelectedTabIndex,
  socialNetworkData,
  contactGroups,
  isFromCompliance,
  queryId,
  isPremiumAdvisor,
  channelId,
  popularThemes
}) => {
  const [showCloneModal, toggleCloneModal] = useState(false);
  const [selectedNewsletter, setSelectedNewsletter] = useState({});

  useEffect(() => {
    newsletterSetState({
      newsletterName: '',
      isEditMode: false,
      scheduledTime: '',
      newsletterId: '',
      emailTemplateSelected: '',
      selectedContacts: [],
      newsletterArticles: [],
      isFromCompliance: false,
      customData: {},
      tab: '',
      ccContacts: [],
      leadCapture: {},
    });
  }, []);

  const onTabSelect = (index) => {
    setSelectedIndex(index);
    newsletterSetState({ data: {} });
    setPage(0);
    if (index === 2) {
      newsletterOutboxRequest({
        params: {
          filter: 'sent',
        },
      });
    } else if (index === 1) {
      newsletterOutboxRequest({
        params: {
          filter: 'scheduled',
        },
      });
    } else {
      newsletterOutboxRequest({
        params: {
          filter: 'draft',
        },
      });
    }
  };


  return (
    <Flex width={1} flexDirection="column">
      <Tabs
        border="1px solid #e4e4e4"
        borderRadius="8px"
        onSelect={onTabSelect}
        selectedIndex={selectedIndex}
      >
        <BorderWhiteBox type="admin-tab" padding="5px 5px">
          <Flex width="100%" justifyContent="center" padding="0px 38px 10px">
            {/* <div onClick={goToNewsletterDashboard} style={{display: 'flex', justifySelf: 'flex-start', fontSize: '13px', alignSelf: 'center', cursor: 'pointer'}}>
              <FontAwesomeIcon style={{position: 'relative', top: '3px'}} icon={faAngleLeft} />
              &nbsp; Back</div> */}
            <div
              style={{
                borderBottom: `1px solid ${themesData.purpleishBlue}`,
                justifySelf: 'center',
                width: 'fit-content',
                fontWeight: '600',
              }}
            >
              Newsletter Dashboard
            </div>
          </Flex>
          <TabList>
            <DashboardAdminSubTab
              fontSize="12px"
              color={themesData.charcoalGrey}
              className={`${selectedIndex === 0 ? 'active' : 'inactive'}`}
            >
              Drafts
            </DashboardAdminSubTab>
            <DashboardAdminSubTab
              fontSize="12px"
              color={themesData.charcoalGrey}
              className={`${selectedIndex === 1 ? 'active' : 'inactive'}`}
            >
              Scheduled
            </DashboardAdminSubTab>
            <DashboardAdminSubTab
              fontSize="12px"
              color={themesData.charcoalGrey}
              className={`${selectedIndex === 2 ? 'active' : 'inactive'}`}
            >
              Sent
            </DashboardAdminSubTab>
          </TabList>
          <TabConfig noBorder background="#ffffff">
            <DraftOutbox
              outBoxData={outBoxData}
              outBoxLoading={outBoxLoading}
              page={page}
              setPage={setPage}
              newsletterOutboxRequest={newsletterOutboxRequest}
              newsletterSetState={newsletterSetState}
              newsletterDeleteRequest={newsletterDeleteRequest}
              newsletterId={newsletterId}
              isArticleDeleting={isArticleDeleting}
              setShowTabs={setShowTabs}
              setShowOutbox={setShowOutbox}
              setSelectedTabIndex={setSelectedTabIndex}
              toggleCloneModal={toggleCloneModal}
              setSelectedNewsletter={setSelectedNewsletter}
              isPremiumAdvisor={isPremiumAdvisor}
            />
          </TabConfig>
          <TabConfig noBorder background="#ffffff">
            <ScheduledOutbox
              outBoxData={outBoxData}
              outBoxLoading={outBoxLoading}
              page={page}
              setPage={setPage}
              newsletterOutboxRequest={newsletterOutboxRequest}
              newsletterSetState={newsletterSetState}
              newsletterDeleteRequest={newsletterDeleteRequest}
              newsletterId={newsletterId}
              isArticleDeleting={isArticleDeleting}
              setShowTabs={setShowTabs}
              setShowOutbox={setShowOutbox}
              setSelectedTabIndex={setSelectedTabIndex}
              socialNetworkData={socialNetworkData}
              contactGroups={contactGroups}
              isFromCompliance={isFromCompliance}
              queryId={queryId}
              isPremiumAdvisor={isPremiumAdvisor}
              toggleCloneModal={toggleCloneModal}
              setSelectedNewsletter={setSelectedNewsletter}
              channelId={channelId}
              popularThemes={popularThemes}
              userData={userData}
            />
          </TabConfig>
          <TabConfig noBorder background="#ffffff">
            <SentOutbox
              outBoxData={outBoxData}
              outBoxLoading={outBoxLoading}
              page={page}
              setPage={setPage}
              newsletterOutboxRequest={newsletterOutboxRequest}
              newsletterSetState={newsletterSetState}
              setShowTabs={setShowTabs}
              setShowOutbox={setShowOutbox}
              setSelectedTabIndex={setSelectedTabIndex}
              contactGroups={contactGroups}
              toggleCloneModal={toggleCloneModal}
              setSelectedNewsletter={setSelectedNewsletter}
              isPremiumAdvisor={isPremiumAdvisor}
              channelId={channelId}
              popularThemes={popularThemes}
            />
          </TabConfig>
        </BorderWhiteBox>
      </Tabs>
      {showCloneModal && (
        <CloneNLModal
          show={showCloneModal}
          toggleShow={toggleCloneModal}
          newsletterId={selectedNewsletter.id}
          newsletterName={selectedNewsletter.name}
          refresh={onTabSelect}
        />
      )}
    </Flex>
  );
};

export default OutboxTab;
