import React from 'react';
import {
  WidgetTile,
  Tabs,
  TabList,
  Tab,
  TabPanel,
  ResponsiveImg,
} from '../../../components';

import TopInsights from '../Insights/TopInsights';
import PopularTopics from '../Topics/PopularTopics';
import { IconDiv, AdminSubTab } from '../DashboardContainer.styles';
import { set } from 'lodash';

const Popular = ({ 
  setTabIndex, 
  setPopularTopicDetails, 
  isShareBlocked, 
  setSelectedExploreTabIndex, 
  roleType, 
  onExploreTabClick, 
  popularTabIndex, 
  onPopularTabChange
}) => { 
  return(
  <WidgetTile heading="Popular" theme="light">
    <IconDiv>
      <ResponsiveImg src="/assets/images/popular.svg" />
    </IconDiv>
    <Tabs selectedIndex={popularTabIndex} onSelect={onPopularTabChange} style={{paddingTop: '3px'}}>
      <TabList>
        <AdminSubTab padding='5px 15px 0px'><span className={`${popularTabIndex === 0 ? 'active' : null}`}>Most Shared</span></AdminSubTab> 
        <AdminSubTab padding='5px 15px 0px' onClick={onExploreTabClick}><span className={`${popularTabIndex === 1 ? 'active' : null}`}>Most Subscribed</span></AdminSubTab> 
      </TabList>

      <TabPanel>
        <TopInsights isShareBlocked={isShareBlocked} />
      </TabPanel>
      <TabPanel>
        <PopularTopics
          setTabIndex={setTabIndex}
          setSelectedExploreTabIndex={setSelectedExploreTabIndex}
          setPopularTopicDetails={setPopularTopicDetails}
          roleType={roleType} 
          onExploreTabClick={onExploreTabClick}
        />
      </TabPanel>
    </Tabs>
  </WidgetTile>
);
}

export default Popular;
