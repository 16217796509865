import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  WidgetTile,
  Tabs,
  TabList,
  TabPanel,
  ResponsiveImg,
  Button,
  SocialCheckBoxes,
  ButtonNoBorderBackground,
  MioDropdown,
} from '../../../components';
import Topics from './Topics';
import YourInvestments from './YourInvestments';
import MyClients from '../../../components/MyClients/MyClients';
import CRMSegment from '../../../components/CRMSegment/CRMSegment';
import { IconDiv, AdminSubTab } from '../DashboardContainer.styles';
import { ROLE_TYPES } from '../../../utils';
import { getRoleType } from '../../../redux/selector';
import { Box, Flex } from '@rebass/grid';
import AdvisorSearch from '../Discover/AdvisorSearch';
import { get, isEmpty } from 'lodash';
import { CaretIcon } from '../../ConfigureContainer/EmailList/EmailList.styles';
import EditorPanel from '../Trending/EditorPanel';
import NewSocialCheckBoxes from '../../../components/SocialCheckBoxes/NewSocialCheckBoxes';
import { SelectedItemBox } from '../../../components/SearchFilterMenu/SearchFilterMenu.styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { TopToolTipWrapper } from '../../LandingPageContainer/styles';

const Personalized = ({
  setTabIndex,
  setPopularTopicDetails,
  themesList,
  sponsoredThemeList,
  onInvestmentClick,
  setTrendingTopicDetails,
  trendingTopicDetails,
  tickerName,
  topicFromInvestment,
  setInvestmentTopic,
  exploreSetState,
  setSelectedExploreTabIndex,
  roleType,
  themeSetState,
  yourTopics,
  onWidgetTabChange,
  widgetTabIndes,
  setWdigetTabIndex,
  setShowArticleLoader,
  currentPortfolio,
  holdingsType,
  isPremuimAdvisor,
  curatedTopicType,
  setCuratedTopicType,
  popularTopicDetails,
  exploreArticleRequest,
  onSearchClick,
  setSearchValue,
  channelData,
  onCheckBoxClick,
  checkedStatus,
  searchValue,
  setClientTopics,
  isPremiumAdvisor,
  isAdmin,
  contentlibSetState,
  userData,
  isEnterpriseAdvisor,
  isAssetAdvisor,
  showClientDropDown,
  setShowClientDropDown,
  contactList,
  setThirdPartyThemeId,
  setClearEditorArticles,
  thirdPartyThemeId,
  exploreArticles,
  setClientDetails,
  selectedDropdownOption,
  setSelectedDropdownOption,
  setSelectedCRMContactList,
  crmLoader,
  setCrmLoader,
  personalized_tabs,
  onExploreTabClick,
  popularTabIndex,
  firmSettings
}) => {

  const [selectedItems, setSelectedItems] = useState([]);
  const [topicSelected, setTopicSelected] = useState([]);
  const [selectedPublishers, setSelectedPublishers] = useState([]);

  const [updatedThemeList, setUpdatedThemeList] = useState([]);

  const paddingStyle =
    userData.details &&
    userData.details.user.turnkey &&
    userData.details.user.turnkey === 'true'
      ? '5px 19px 0px'
      : '5px 25px 0px';

  useEffect(() => {
    if (topicFromInvestment) {
      setWdigetTabIndex(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [topicFromInvestment]);

  useEffect(() => {
    const featuredIds =  sponsoredThemeList && sponsoredThemeList.reduce((acc, curr, i) => {
      acc += i === 0 ? curr.id + '%22,' : (i === sponsoredThemeList.length-1 && curr.id ? '%22' + curr.id : '%22' + curr.id + '%22,');
      return acc;
    }, '');
    if(themesList?.length > 0) {
      const midIndex = Math.floor((themesList.length) / 2);
      const midTopic = themesList[midIndex];
      let newThemeList;
      if(themesList.length === 1) {
        newThemeList = [
          ...themesList.slice(midIndex),
          {...midTopic, 
            name: 'Featured Topics', description: 'Featured Topics', id: featuredIds,
            media_urls: [{...midTopic?.media_urls[0], path: '/assets/images/', id: 'Featured Topics 2', ext: 'svg'}]
          },
          ];
      } else {
        newThemeList = [...themesList.slice(0, midIndex),
          {...midTopic, 
            name: 'Featured Topics', description: 'Featured Topics', id: featuredIds,
            media_urls: [{...midTopic?.media_urls[0], path: '/assets/images/', id: 'Featured Topics 2', ext: 'svg'}]
          },
          ...themesList.slice(midIndex)];
      }
      // if (newThemeList.length > 15) {
      //     newThemeList.pop();
      // }
      setUpdatedThemeList(newThemeList);
    } else {

      setUpdatedThemeList([{
        name: 'Featured Topics', 
        description: 'Featured Topics', 
        id: featuredIds,
        subscription: {
          status: {
            application_status: 'approved'
          }
        },
        media_urls: [{path: '/assets/images/', id: 'Featured Topics 2', ext: 'svg'}]
      }])
    }
  }, [themesList, sponsoredThemeList])

  const handleTabIndex = () => {    
    if (roleType && roleType === 'ADVISOR_ENTERPRISE') {
      setTabIndex(0);
      setSelectedExploreTabIndex(3);
    } else if (isPremuimAdvisor) {
      setTabIndex(0);
      setSelectedExploreTabIndex(1);
    } else {
      setTabIndex(0);
      setSelectedExploreTabIndex(1);
    }
  };

  const handleClick = (type) => {
    setTabIndex(0);
    setShowArticleLoader(true);
    setSelectedExploreTabIndex(2);
    setCuratedTopicType(type);
    contentlibSetState({ data: {}, filter: type });
    // exploreArticleRequest({
    //   params: {
    //     id: popularTopicDetails.id,
    //     source: 'approveToShare',
    //     date: type,
    //   },
    // });
  };

  const adminSearchCheckBoxes = () => {
      return (
        <Flex
          width="100%"
          alignItems="flex-start"
          justifyContent="center"
          px="0px"
        >
          <NewSocialCheckBoxes
            onCheckBoxClick={onCheckBoxClick}
            small
            buttons={[
              {
                name: 'Approved to Share',
                endText: 'Approved to Share',
                isDisconnected: checkedStatus('approved_to_share').isDisconnected,
                platform: 'approved_to_share',
                checked: checkedStatus('approved_to_share').checked,
                width: '74px',
                padding: '0',
                type: 'discover',
                margin: '0px 3px',
                bgColor: '#816EF8',
              },
              {
                name: 'All',
                endText: 'All Content',
                isDisconnected: checkedStatus('all_content').isDisconnected,
                platform: 'all_content',
                checked: checkedStatus('all_content').checked,
                width: '86px',
                padding: '0',
                type: 'discover',
                margin: '0px 3px',
                bgColor: '#816EF8',
              },
              {
                name: 'My Content',
                endText: 'My Content',
                isDisconnected: checkedStatus('my_content').isDisconnected,
                platform: 'my_content',
                checked: checkedStatus('my_content').checked,
                width: '89px',
                padding: '0',
                type: 'discover',
                margin: '0px 3px',
                bgColor: '#816EF8',
              },
              {
                name: 'Editor',
                endText: 'My Editor',
                isDisconnected: checkedStatus('editor_content').isDisconnected,
                platform: 'editor_content',
                checked: checkedStatus('editor_content').checked,
                width: '74px',
                padding: '0',
                type: 'discover',
                margin: '0px 3px',
                bgColor: '#816EF8',
              },
            ]}
          />
        </Flex>
      );
    }


  const searchCheckBoxes = () => {
    return (
      <Flex
        width={1}
        alignItems="flex-start"
        justifyContent="center"
        pl="30%"
        pr="30%"
      >
        <SocialCheckBoxes
          onCheckBoxClick={onCheckBoxClick}
          buttons={[
            {
              name: 'All',
              bottomText: 'All Content',
              isDisconnected: checkedStatus && checkedStatus('all').isDisconnected,
              platform: 'all',
              checked: checkedStatus && checkedStatus('all').checked,
              width: '86px',
              padding: '0',
              type: 'discover',
              margin: '0px 5px',
              bgColor: '#c95cfc',
            },
            {
              name: 'My Content',
              bottomText: 'My Content',
              isDisconnected: checkedStatus && checkedStatus('my_content').isDisconnected,
              platform: 'my_content',
              checked: checkedStatus && checkedStatus('my_content').checked,
              width: '89px',
              padding: '0',
              type: 'discover',
              margin: '0px 5px',
              bgColor: '#c95cfc',
            },
          ]}
        />
      </Flex>
    );
  };

  const handleSearchTab = () => {
    setTabIndex(3);
  };

  const goToAddContent = () => {
    contentlibSetState({ data: {} });
    setTabIndex(3);
  };

  const handleDropdownClick = (option) => {
    // console.log('inside handledropdown...');
    exploreSetState({ data: {} });
    setShowArticleLoader(true);
    setSelectedDropdownOption(option);
    setShowClientDropDown(false);
    handleTabIndexReset();
  };

  const handleTabIndexReset = () => {
    setTabIndex(0);
    if (
      userData.details &&
      userData.details.user.turnkey &&
      userData.details.user.turnkey === 'true'
    )
      setSelectedExploreTabIndex(6);
    else setSelectedExploreTabIndex(5);
  };

  const backHandler = () => {
    setShowClientDropDown(true);
    setSelectedDropdownOption('');
    setSelectedCRMContactList({
      id: '',
      name: '',
      themes: [],
      recommendedThemes: [],
      userThemes: [],
      disabledThemes: [],
    });
    setClientDetails({ id: 'SECURITY', contactName: 'positivly_security' });
  };

  const onUnSelect = (id) => {
    const filterChannel = selectedItems.filter((item) => item.id !== id);
    setSelectedItems(filterChannel);
    let filterTags = [];
    if(!isEmpty(filterChannel)) {
      filterChannel.forEach((item) => {
        if (item.name.includes('(')) {
          let name = item.name.split(/[()]/);
          filterTags.push(name[0].toUpperCase().trim());
          filterTags.push(name[1].toUpperCase().trim());
        } else {
          filterTags.push(item.name.toUpperCase().trim());
        }
      });
    }

    setSelectedPublishers(filterTags);
   
    topicSelected &&
      topicSelected.length > 0 &&
      setTopicSelected(topicSelected.filter((item) => item !== id));
  };

  return (
    <WidgetTile heading="Personalized" theme="light">
      <IconDiv>
        <Link to="/theme">
          <ResponsiveImg src="/assets/images/personalized.svg" />
        </Link>
      </IconDiv>
      <Tabs
        selectedIndex={widgetTabIndes}
        onSelect={onWidgetTabChange}
        style={{ paddingTop: '3px' }}
      >
        <TabList>
          <AdminSubTab padding={paddingStyle}>
            <span className={`${widgetTabIndes === 0 ? 'active' : null}`}>
              My Content
            </span>
          </AdminSubTab>
          <AdminSubTab padding={paddingStyle}>
            <span className={`${widgetTabIndes === 1 ? 'active' : null}`}>
              My Topics
            </span>
          </AdminSubTab>
          <AdminSubTab
            hide={
              get(firmSettings, 'firm_general.my_holding', 'true') === 'false'
            }
            padding={paddingStyle}
          >
            <span className={`${widgetTabIndes === 2 ? 'active' : null}`}>
              My Holdings
            </span>
          </AdminSubTab>
          {userData.details &&
          userData.details.user.turnkey &&
          userData.details.user.turnkey === 'true' ? (
            <AdminSubTab
              padding={paddingStyle}
              hide={
                get(firmSettings, 'firm_general.my_editor', 'true') === 'false'
              }
            >
              <span className={`${widgetTabIndes === 3 ? 'active' : null}`}>
                My Editor
              </span>
            </AdminSubTab>
          ) : null}

          {userData.details &&
          userData.details.user.turnkey &&
          userData.details.user.turnkey === 'true' ? (
            <AdminSubTab
              hide={
                get(firmSettings, 'firm_general.my_clients', 'true') === 'false'
              }
              padding={paddingStyle}
            >
              <span className={`${widgetTabIndes === 4 ? 'active' : null}`}>
                My Clients
              </span>
            </AdminSubTab>
          ) : (
            <AdminSubTab
              hide={
                get(firmSettings, 'firm_general.my_clients', 'true') === 'false'
              }
              padding={paddingStyle}
            >
              <span className={`${widgetTabIndes === 3 ? 'active' : null}`}>
                My Clients
              </span>
            </AdminSubTab>
          )}

          {userData.details &&
          userData.details.user.turnkey &&
          userData.details.user.turnkey === 'true' ? (
            <AdminSubTab padding={paddingStyle}>
              <span className={`${widgetTabIndes === 5 ? 'active' : null}`}>
                Search
              </span>
            </AdminSubTab>
          ) : (
            <AdminSubTab padding={paddingStyle}>
              <span className={`${widgetTabIndes === 4 ? 'active' : null}`}>
                Search
              </span>
            </AdminSubTab>
          )}
        </TabList>

        <TabPanel overflow="visible">
          <>
            {get(userData.details.user.extra_settings, 'add_content', '') ===
            'true' ? (
              <Flex flexDirection="column" justifyContent="center" pt="30px">
                <Flex justifyContent="center">
                  <Button
                    bgColor="#eeeeee"
                    color="#49484a"
                    activeColor="#7dc4d0"
                    margin="5px 20px"
                    styles={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      border: 'none',
                    }}
                    fontSize="12px"
                    width="33%"
                    className={curatedTopicType === 'all' ? 'active' : null}
                    onClick={() => handleClick('all')}
                  >
                    <img
                      alt=""
                      style={{ marginRight: '4px' }}
                      src={
                        curatedTopicType === 'all'
                          ? '/assets/images/All_Icon_White.svg'
                          : '/assets/images/All_Icon_black1.svg'
                      }
                    />
                    All
                  </Button>
                  <Button
                    bgColor="#eeeeee"
                    color="#49484a"
                    activeColor="#7dc4d0"
                    margin="5px 20px"
                    styles={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      border: 'none',
                    }}
                    fontSize="12px"
                    width="33%"
                    className={
                      curatedTopicType === 'Approve To Share' ? 'active' : null
                    }
                    onClick={() => handleClick('Approve To Share')}
                  >
                    <img
                      alt=""
                      style={{ marginRight: '4px' }}
                      src={
                        curatedTopicType === 'Approve To Share'
                          ? '/assets/images/Approved_Icon_White.svg'
                          : '/assets/images/Approved_Icon_black.svg'
                      }
                    />
                    Approved To Share
                  </Button>
                </Flex>
                <Flex justifyContent="center">
                  <Button
                    bgColor="#eeeeee"
                    color="#49484a"
                    activeColor="#7dc4d0"
                    margin="5px 20px"
                    styles={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      border: 'none',
                    }}
                    fontSize="12px"
                    width="33%"
                    className={
                      curatedTopicType === 'Internal Use' ? 'active' : null
                    }
                    onClick={() => handleClick('Internal Use')}
                  >
                    <img
                      alt=""
                      style={{ marginRight: '4px' }}
                      src={
                        curatedTopicType === 'Internal Use'
                          ? '/assets/images/Interal_Use_Icon_White.svg'
                          : '/assets/images/Interal_Use_Icon_black.svg'
                      }
                    />
                    Internal Use
                  </Button>
                  <Button
                    bgColor="#eeeeee"
                    color="#49484a"
                    activeColor="#7dc4d0"
                    margin="5px 20px"
                    styles={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      border: 'none',
                    }}
                    fontSize="12px"
                    width="33%"
                    className={
                      curatedTopicType === 'My Added Content' ? 'active' : null
                    }
                    onClick={() => handleClick('My Added Content')}
                  >
                    <img
                      alt=""
                      style={{ marginRight: '4px' }}
                      src={
                        curatedTopicType === 'My Added Content'
                          ? '/assets/images/My_Library_Icon_White.svg'
                          : '/assets/images/My_Library_Icon_black.svg'
                      }
                    />
                    My Library
                  </Button>
                </Flex>
              </Flex>
            ) : (
              <Flex justifyContent="center" pt="30px">
                <Button
                  bgColor="#eeeeee"
                  color="#49484a"
                  activeColor="#7dc4d0"
                  margin="5px auto"
                  styles={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    border: 'none',
                  }}
                  fontSize="12px"
                  width="29%"
                  className={curatedTopicType === 'all' ? 'active' : null}
                  onClick={() => handleClick('all')}
                >
                  <img
                    alt=""
                    style={{ marginRight: '4px' }}
                    src={
                      curatedTopicType === 'all'
                        ? '/assets/images/All_Icon_White.svg'
                        : '/assets/images/All_Icon_black1.svg'
                    }
                  />
                  All
                </Button>
                <Button
                  bgColor="#eeeeee"
                  color="#49484a"
                  activeColor="#7dc4d0"
                  margin="5px auto"
                  styles={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    border: 'none',
                  }}
                  fontSize="12px"
                  width="33%"
                  className={
                    curatedTopicType === 'Approve To Share' ? 'active' : null
                  }
                  onClick={() => handleClick('Approve To Share')}
                >
                  <img
                    alt=""
                    style={{ marginRight: '4px' }}
                    src={
                      curatedTopicType === 'Approve To Share'
                        ? '/assets/images/Approved_Icon_White.svg'
                        : '/assets/images/Approved_Icon_black.svg'
                    }
                  />
                  Approved To Share
                </Button>
                <Button
                  bgColor="#eeeeee"
                  color="#49484a"
                  activeColor="#7dc4d0"
                  margin="5px auto"
                  styles={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    border: 'none',
                  }}
                  fontSize="12px"
                  width="29%"
                  className={
                    curatedTopicType === 'Internal Use' ? 'active' : null
                  }
                  onClick={() => handleClick('Internal Use')}
                >
                  <img
                    alt=""
                    style={{ marginRight: '4px' }}
                    src={
                      curatedTopicType === 'Internal Use'
                        ? '/assets/images/Interal_Use_Icon_White.svg'
                        : '/assets/images/Interal_Use_Icon_black.svg'
                    }
                  />
                  Internal Use
                </Button>
              </Flex>
            )}
            {get(userData.details.user.extra_settings, 'add_content', '') ===
              'true' && (
              <Flex width="1" justifyContent="center" pt={'30px'}>
                <ButtonNoBorderBackground
                  onClick={goToAddContent}
                  // padding="0px 40px 0px 0px"
                  style={{
                    flexDirection: 'row',
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <ResponsiveImg src="/assets/images/Add.svg" />
                  &nbsp;&nbsp;
                  <span>Add</span>
                </ButtonNoBorderBackground>
              </Flex>
            )}
          </>
        </TabPanel>
        <TabPanel style={{ overflow: 'visible' }}>
          <Topics
            themesList={updatedThemeList}
            setTabIndex={handleTabIndex}
            setPopularTopicDetails={setPopularTopicDetails}
            popularTopicDetails={popularTopicDetails}
            topicFromInvestment={topicFromInvestment}
            setWdigetTabIndex={setWdigetTabIndex}
            themeSetState={themeSetState}
            yourTopics={yourTopics}
            onExploreTabClick={onExploreTabClick}
            popularTabIndex={popularTabIndex}
            setSelectedExploreTabIndex={setSelectedExploreTabIndex}
          />
        </TabPanel>
        <TabPanel>
          <YourInvestments
            exploreSetState={exploreSetState}
            setTabIndex={setTabIndex}
            onInvestmentClick={onInvestmentClick}
            setTrendingTopicDetails={setTrendingTopicDetails}
            trendingTopicDetails={trendingTopicDetails}
            tickerName={tickerName}
            setShowArticleLoader={setShowArticleLoader}
            currentPortfolio={currentPortfolio && currentPortfolio}
            holdingsType={holdingsType}
          />
        </TabPanel>
        {userData.details &&
          userData.details.user.turnkey &&
          userData.details.user.turnkey === 'true' && (
            <TabPanel overflow="visible">
              {/* {console.log('[THIRD PARTY THEME IT] - ', thirdPartyThemeId)} */}
              <EditorPanel
                setThirdPartyThemeId={setThirdPartyThemeId}
                exploreArticleRequest={exploreArticleRequest}
                popularTopicDetails={popularTopicDetails}
                setClearEditorArticles={setClearEditorArticles}
                exploreSetState={exploreSetState}
                setSelectedExploreTabIndex={setSelectedExploreTabIndex}
                thirdPartyThemeId={thirdPartyThemeId}
                user={userData}
                setShowArticleLoader={setShowArticleLoader}
                exploreArticles={exploreArticles}
              />
            </TabPanel>
          )}
        <TabPanel style={{ overflowY: 'hidden' }}>
          {showClientDropDown ? (
            <Flex alignItems="center" style={{ height: '185px' }}>
              <Flex fontSize="12px" pl="5%" pr="2%" mt="-20px" width="140px">
                Personalize By :
              </Flex>
              <Flex mt="-20px">
                <MioDropdown
                  fullWidth
                  background="#EFF0F2"
                  hoverBackground="#D7C3FF"
                  type="emailList"
                  left="0px"
                  width="30.8em"
                  dropdownMenu={
                    <ul>
                      <li
                        value=""
                        onClick={() => handleDropdownClick('financial')}
                        style={{
                          color: '#757575',
                          borderLeft: 'none',
                          borderRight: 'none',
                        }}
                      >
                        Financial Personality{' '}
                        <img
                          src="/assets/images/TIFIN-Personality-Logo.svg"
                          alt="TIFIN-Personality-Logo"
                          style={{
                            position: 'absolute',
                            top: '-4px',
                            left: '140px',
                            width: '100px',
                          }}
                        />
                      </li>
                      <li
                        value=""
                        onClick={() => handleDropdownClick('crm')}
                        style={{
                          color: '#757575',
                          borderLeft: 'none',
                          borderRight: 'none',
                        }}
                      >
                        Client Segments
                      </li>
                      <li
                        value=""
                        style={{
                          color: '#757575',
                          borderLeft: 'none',
                          borderRight: 'none',
                          pointerEvents: 'none',
                        }}
                      >
                        Custom Segments (Coming Soon)
                      </li>
                    </ul>
                  }
                >
                  <Button
                    bgColor="#eeeeee"
                    color="#6351ed"
                    styles={{ border: 'none' }}
                    margin={`${
                      window.innerWidth < 768
                        ? '3px 0px 10px 0px'
                        : '0px 0px 0px 0px'
                    }`}
                    height="35px"
                    width={`${window.innerWidth < 768 ? '82%' : '370px'}`}
                  >
                    <span
                      style={{
                        whiteSpace: 'nowrap',
                        float: 'left',
                        width: '300px',
                        overflow: 'hidden',
                        color: '#757575',
                      }}
                    >
                      Select from available options
                    </span>
                    <CaretIcon marginTop="3px" />
                  </Button>
                </MioDropdown>
              </Flex>
            </Flex>
          ) : selectedDropdownOption === 'financial' ? (
            <MyClients
              exploreArticleRequest={exploreArticleRequest}
              setClientTopics={setClientTopics}
              setTabIndex={setTabIndex}
              setSelectedExploreTabIndex={setSelectedExploreTabIndex}
              isPremiumAdvisor={isPremiumAdvisor}
              userData={userData}
              isAdmin={isAdmin}
              setShowArticleLoader={setShowArticleLoader}
              clientContactList={contactList}
              isEnterpriseAdvisor
              close={backHandler}
              setClientDetails={setClientDetails}
              crmLoader={crmLoader}
              toggleCrmLoader={setCrmLoader}
            />
          ) : selectedDropdownOption === 'crm' ? (
            <CRMSegment
              close={backHandler}
              setSelectedCRMContactList={setSelectedCRMContactList}
              setShowArticleLoader={setShowArticleLoader}
              handleTabIndexReset={handleTabIndexReset}
              loader={crmLoader}
              toggleLoader={setCrmLoader}
            />
          ) : (
            <div></div>
          )}
        </TabPanel>
        <TabPanel overflow="visible">
          <AdvisorSearch
            onSearchClick={onSearchClick}
            setSearchValue={setSearchValue}
            setTabIndex={handleSearchTab}
            themesList={themesList}
            channelData={channelData}
            exploreSetState={exploreSetState}
            searchValue={searchValue}
            setTopicSelected={setTopicSelected}
            topicSelected={topicSelected}
            setSelectedItems={setSelectedItems}
            selectedItems={selectedItems}
            selectedPublishers={selectedPublishers}
            setSelectedPublishers={setSelectedPublishers}
          />
           <Flex
              marginBottom={
                selectedItems && selectedItems.length > 0 ? '5px' : '5px'
              }
              style={{
                display: 'flex',
                width: '100%',
                margin: '0px 0px 5px 0px',
                justifyContent: 'flex-start',
                padding: '0px 15px 0px 24px',
              }}
            >
              {[...selectedItems, ...topicSelected].map((item, index) => (
                <SelectedItemBox background="#6351ed">
                  <Flex alignItems="center">
                    <Box pr="5px">
                      <FontAwesomeIcon
                        icon={faTimes}
                        onClick={() => onUnSelect(item.id || item)}
                      />
                    </Box>
                    <Box>
                    <TopToolTipWrapper>
                        {item?.name && item?.name?.length > 6
                          ? `${item.name.substring(0, 6)}...`
                          : item.name || `${item.substring(0, 6)}...`}
                          {item?.name?.length > 6 ? <span className='tooltiptext' style={{left: '-48%', fontSize: '9px', bottom: '137%',width: '200%' }}>{item?.name}</span> : null }
                          {item?.length > 6 ? <span className='tooltiptext' style={{left: '-48%', fontSize: '9px', bottom: '137%',width: '200%' }}>{item}</span> : null }
                      </TopToolTipWrapper>
                    </Box>
                  </Flex>
                </SelectedItemBox>
              ))}
            </Flex>
          <Flex
            display="flex"
            justifyContent="center"
            fontSize="12px"
            color="#667085"
            width="97%"
            margin={
              (topicSelected &&
              topicSelected.length > 0) ||
              (selectedItems &&
              selectedItems.length > 0)
                ? '0px 8px 7px'
                : '0px 8px 15px'
            }
          >
            Searching Content Across
          </Flex>
          {userData.details &&
          userData.details.user &&
          userData.details.user.turnkey &&
          userData.details.user.turnkey === 'true'
            ? adminSearchCheckBoxes()
            : searchCheckBoxes()}
        </TabPanel>
      </Tabs>
    </WidgetTile>
  );
};

const mapStateToProps = (state, ownProps) => {
  const roleType = getRoleType(state);
  const isPremuimAdvisor = roleType === ROLE_TYPES.ADVISOR_PREMIUM;
  // console.log('getRoleType: ', roleType)
  return {
    userData: state.user.data,
    isPremuimAdvisor,
  };
};

export default connect(mapStateToProps, null)(Personalized);
