import React, { useEffect, useState } from 'react';
import { useInfiniteQuery } from 'react-query';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Loader } from 'src/components';
import ArticleSkeleton from '../common/components/Article/Skeleton';
import Article from '../common/components/Article';
import Topics from './Topics';
import useRESTQuery from 'src/hooks/useRESTQuery';
import { transformNewsFeedData } from './utils';
import { cloutV2Endpoints } from 'src/services/helpers';
import apiInstance from 'src/services/api';
import { Articles, ArticlesSkeleton } from '../styles';
import { Wrapper } from './styles';
import ZeroState from '../common/components/ZeroState';

function NewsFeed() {
  const LIMIT = 10;
  const [selectedTopics, setSelectedTopics] = useState([]);
  const [selectedArticleId, setSelectedArticleId] = useState('');

  const {
    data: subscribedTopics,
    isLoading: topicsLoader,
    isFetching: topicsFetching,
    refetch: refetchSubscribedTopics,
  } = useRESTQuery({
    key: 'news-feed-subscribed-topics',
    api: 'api.clout',
    useAdvisorToken: true,
    endpoint:
      'theme/?fields=theme_id,name,status,description,media_urls,created_time,updated_time',
    options: {
      select: (res) => {
        const result = res?.result?.data;
        if (Array.isArray(result) && result.length > 0) {
          return result
            ?.filter(
              (topic) =>
                topic?.subscription?.status?.application_status === 'approved'
            )
            ?.map((topic) => ({
              ...topic,
              theme_name: topic?.name,
            }));
        }

        return [];
      },
    },
  });

  const refetchQueries = () => {
    refetchSubscribedTopics();
    setSelectedArticleId('');
    refetch();
  };

  useEffect(() => {
    if (Array.isArray(subscribedTopics) && subscribedTopics?.length > 0) {
      setSelectedTopics([subscribedTopics[0].id]);
    }
  }, [subscribedTopics]);

  const {
    data,
    isLoading,
    isFetching,
    isSuccess,
    hasNextPage,
    fetchNextPage,
    refetch,
  } = useInfiniteQuery(
    ['news-feed', selectedTopics?.join(',')],
    async ({ pageParam = 1 }) => {
      const response = await apiInstance()?.get(
        `${
          cloutV2Endpoints?.baseUrl
        }/discover/blog_articles?page_no=${pageParam}&limit=${LIMIT}&themes=${JSON.stringify(
          selectedTopics
        )}`
      );
      const transformedData = transformNewsFeedData(response?.result);
      return transformedData;
    },
    {
      getNextPageParam: (lastPage) => {
        return lastPage?.pagination?.next_page;
      },
    }
  );

  const invalidateQueries = (articleId) => {
    setSelectedArticleId(articleId);
    refetch();
  };

  const newsFeedArticles =
    isSuccess &&
    data?.pages.map((page) => {
      if (page?.articles?.length === 0) return <ZeroState />;
      return page?.articles?.map((article) => {
        if (isFetching && selectedArticleId === article?.id) {
          return <ArticleSkeleton />;
        }
        return (
          <Article
            key={article.id}
            article={article}
            invalidateQueries={invalidateQueries}
          />
        );
      });
    });

  const loader = () => {
    return (
      <ArticlesSkeleton>
        {Array.from(Array(6)?.keys())?.map((article) => (
          <ArticleSkeleton key={article} />
        ))}
      </ArticlesSkeleton>
    );
  };

  if (isLoading)
    return (
      <Articles>
        {Array.from(Array(3)?.keys())?.map((article) => (
          <ArticleSkeleton key={article} />
        ))}
      </Articles>
    );

  return (
    <Wrapper>
      {topicsLoader || topicsFetching ? (
        <Loader />
      ) : (
        <Topics
          selectedTopics={selectedTopics}
          setSelectedTopics={setSelectedTopics}
          subscribedTopics={subscribedTopics}
          refetchSubscribedTopics={refetchQueries}
        />
      )}

      {isLoading || (isFetching && !selectedArticleId) ? (
        loader()
      ) : (
        <InfiniteScroll
          dataLength={+data?.pages?.length * 9 || 0}
          next={() => fetchNextPage()}
          hasMore={hasNextPage && !isLoading}
          loader={loader()}
        >
          <Articles>{newsFeedArticles}</Articles>
        </InfiniteScroll>
      )}
    </Wrapper>
  );
}

export default NewsFeed;
