import React from 'react'
import CustomRowsContainer from '../../containers/CustomRowsContainer/CustomRowsContainer';
import withDripCampaignLayout from '../../hoc/withDripCampaignLayout';

const CustomRowsLayout = (props) => {
    return (
        <CustomRowsContainer {...props} />
    )
}

export default withDripCampaignLayout(CustomRowsLayout);
