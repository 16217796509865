import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faTimes } from '@fortawesome/free-solid-svg-icons';

import { ButtonNoBorderBackground, Input } from '../';

import { SearchInputWrapper } from './SearchInput.styles';

const SearchInput = ({
  name,
  id,
  value,
  placeholder,
  error,
  onChange,
  largeInput,
  onSearchClick,
  setSearchText,
  onBlur,
  noBlur
}) => {
  const [textValue, setTextValue] = useState(value);

  const onSeachInputChange = (e) => {
    const value = e.target.value;
    setSearchText && setSearchText(value);
    setTextValue(value);
    if (onChange) {
      onChange(value);
    }
  };

  const onSeachInputBlur = (e) => {
    if(!noBlur) {
      const value = e.target.value;
      setSearchText && setSearchText('');
      setTextValue('');
      if (onBlur) {
        onBlur(value);
      }
    }
  };

  const clearClick = () => {
    setTextValue('');
    onSearchClick('');
    setSearchText && setSearchText('');
  };

  return (
    <SearchInputWrapper>
      <Input
        type="text"
        name={name}
        id={id}
        value={textValue}
        placeholder={placeholder}
        onChange={onSeachInputChange}
        error={error}
        largeInput={largeInput}
        onBlur={onSeachInputBlur}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            onSearchClick(textValue);
          }
        }}
      />
      <ButtonNoBorderBackground className="btn-clear" onClick={clearClick}>
        <FontAwesomeIcon icon={faTimes} />
      </ButtonNoBorderBackground>
      <ButtonNoBorderBackground
        className="btn-search"
        onClick={() => onSearchClick(textValue)}
      >
        <FontAwesomeIcon icon={faSearch} />
      </ButtonNoBorderBackground>
    </SearchInputWrapper>
  );
};

export default SearchInput;
