import { get, isEmpty } from 'lodash';
import React from 'react';
import {
  BorderBottomContainer,
  HeadingSection,
  SubHeaderSection,
  TextInput,
  TextLabel
} from './styles';

const BccField = ({ 
  isAdvisorEnterprise, 
  value, 
  setValue, 
  userData
}) => {
  

  const handleChange = (e) => {
    setValue(e.target.value);
  };

  return (
    <BorderBottomContainer>
      <HeadingSection margin="5px 0 5px">
        BCC for all emails
      </HeadingSection>
      <SubHeaderSection>
          All emails sent from Clout will be BCC to this email address(es) for compliance archival
      </SubHeaderSection>
        <div style={{display: 'table', margin: '15px 0 0px', width: '70%', textAlign: 'left'}}>
        {isAdvisorEnterprise && (
          <TextInput
            disabled
            margin={'2px 0 0'}
            type="text"
            value={!isEmpty(get(userData, 'firm_bcc_emails', [])) ? get(userData, 'firm_bcc_emails', []).join() : ''}
            placeholder="Firm Bcc Emails"
          />
          )}
          <TextLabel>Enter Email Address
            <TextInput
              margin={'10px 0'}
              type="text"
              id="bcc"
              width={'100%'}
              onChange={handleChange}
              value={value}
              placeholder={`Type in email address(es) to be bcc'd, separated by commas (Max 3)`}
            />
          </TextLabel>
        </div>
    </BorderBottomContainer>
  );
};
export default BccField;
