import React, { useState } from 'react';
import {
    DropdownContainer,
    OptionsContainer,
    OptionItem,
    DropdownLabel,
    SelectedDropdownStyle,
    DropdownArrow,
    ErrorMessage
} from './styles';
import downArrowBlack from '../../assets/icons/downArrowBlack.svg';

const CustomDropdown = ({
    dropdownItems,
    dropdownLabel,
    selectedDropdown,
    setSelectedDropdown,
    margin,
    height,
    customField = false,
}) => {
    const [showOptions, setShowOptions] = useState(false);
    const [noOptionsFound, setNoOptionsFound] = useState(false);

    return(
        <DropdownContainer margin={margin}>
            {dropdownLabel && <DropdownLabel>{dropdownLabel}</DropdownLabel>}
            <SelectedDropdownStyle style={{ margin: '3px 0 0'}} height={height} onClick={()=> dropdownItems.length > 0 ? setShowOptions(!showOptions) : setNoOptionsFound(true)}>
                <span>{
                    customField ? selectedDropdown[customField] : (
                        selectedDropdown && selectedDropdown.zone
                    ) ? (selectedDropdown.zone + (selectedDropdown.name ? (': ' + selectedDropdown.name) : '')
                    ) : selectedDropdown?.name ? selectedDropdown.name : ''
                }</span>
                <DropdownArrow src={downArrowBlack} alt='select' selected={showOptions} />
            </SelectedDropdownStyle>
            {noOptionsFound && <ErrorMessage>No options found</ErrorMessage>}
            <OptionsContainer>
                {
                    showOptions && dropdownItems.map((option, index) => {
                        return (
                            <OptionItem 
                                key={index} 
                                onClick={() => {
                                    setSelectedDropdown(option)
                                    setShowOptions(false)
                            }}>
                                {customField ? option[customField] : (option.name && option.zone) ? (option.zone + ': ' + option.name) : option.name ? option.name : ''}
                            </OptionItem>
                        )
                    })
                }
            </OptionsContainer>
        </DropdownContainer>
    )
};

export default CustomDropdown;