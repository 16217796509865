import React from "react";

import { WidgetWrapper, WidgetBody, WidgetHeader } from "./WidgetTile.styles";

const WidgetTile = ({ isLoading, heading, children, theme }) => {
  return (
    <WidgetWrapper screenWidth={window.innerWidth} heading={heading} theme={theme}>
      {isLoading && <span>loading...</span>}
      <WidgetHeader theme={theme}>
        <h4>{heading}</h4>
      </WidgetHeader>
      <WidgetBody theme={theme}>
        <article>{children}</article>
      </WidgetBody>
    </WidgetWrapper>
  );
};

export default WidgetTile;
