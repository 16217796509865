import styled from 'styled-components';
import { Flex, Box } from '@rebass/grid';

import { Tab } from 'react-tabs';
import { themesData } from '../../utils';

export const UploadFlex = styled(Flex)`
  border-bottom: 1px solid #a9adb0;
`;

export const DragAndDropContainer = styled.div`
  width: 100%;
  display:flex;
  border: 2px dashed #a9adb0;
  height: 150px;
  padding: 40px;

  .file-input {
    display:none;
  }

`;


export const UploadBox = styled(Box)`
  b {
    top: -8px;
    background-color: #e4e4e4;
    position: relative;
    padding: 0px 20px;
  }
`;

export const GroupBox = styled(Box)`
  border-left: 1px solid #a9adb0;
`;

export const InviteModalTab = styled(Tab).attrs({
  className: 'newsletter-tab'
})`
  width: 1.7em;
  height: 1.7em;
  text-align: center;
  line-height: 2em;
  border-radius: 1em;
  background-color: ${themesData.purpleishBlue};
  margin: 0 4.5em;
  display: inline-block;
  color: white;
  position: relative;


&:first-child::before {
  display: none;
}

&.active {
  background-color: ${themesData.purpleishBlue};
  .text {
    font-weight: bold;
  }
}

&.active ~ li {
  background-color: #B4AFB6;
}

&.active ~ li::before {
  background-color: #B4AFB6;
}

.line {
  position: absolute;
  background-color: #BBB6BD;
  height: 0.10em;
  width: 10em;
  top: 0.7em;
  left: 1.5em;

  &.active {
    width: 10em;
    background-color: ${themesData.purpleishBlue};
    height: 0.10em;
  }

  &.inactive {
    width: 3em;
    background-color: ${themesData.purpleishBlue};
    height: 0.10em;
  }

  &.inactive:after {
    content: "";
    width: 7em;
    background-color: #BBB6BD;
    height: 0.10em;
    position: absolute;
    left: 3em;
  }
}

.number-tag {
  position: absolute;
  color: ${themesData.white};
  top: -1px;
  left: 7px;
  font-size: 14px;
}

.text {
  position: absolute;
  color: ${themesData.purpleishBlue};
  font-size: 12px;
  top: 3em;
  left: -5.5em;
  width: 13em;
  line-height: 1.5em;
}


`;

export const InviteEmailAddressTab = styled(Tab).attrs({
  className: 'newsletter-tab'
})`


.flex-container {    
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.email-list-div ul {
  list-style: decimal;
  margin-left: 0px;
}

.input-fields {
  border: 1.2px solid #cacaca;
  border-radius: 8px;
  padding: 8px;
  margin: 0 10px;
  background-color: #f0f0f0;
}

.line-div {
  border: 1px solid #a1a1a1;
  border-bottom: 0px;
  width: 44%;
  height: 0;
}

.invite-select {
  border: 1px solid #a1a1a1;
  border-radius: 8px;
  color: #6351ed;
  margin-right: 20px;
  padding: 4px;
  width: 12rem;
  font-family: 'Poppins';
  font-size: inherit;
}

.uploaded-file-div {
  font-size: 0.6rem;
  color: #6351ed;
  background-color: transparent;
  border: 0px;
  text-align: center;
  margin-top: 10px;
}

.send-invite {
  border: 0px;
  background-color: #7dc5d0;
  color: white;
  font-weight: bold;
  padding: 10px;
  border-radius: 6px;
  margin-top: 10px;
  cursor: pointer
}

.send-invite:disabled {
  cursor: not-allowed;
  background-color: lightgray;
  color: #7dc5d0;
}
`;

export const InviteUploadCSVFile = styled(Tab).attrs({
  className: 'newsletter-tab'
})`

.square {
  background: red;
}
.flex-container {    
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.email-list-div ul {
  list-style: decimal;
  margin-left: 0px;
}

.input-fields {
  border: 1.2px solid #cacaca;
  border-radius: 8px;
  padding: 8px;
  margin: 0 10px;
  background-color: #f0f0f0;
}

.line-div {
  border: 1px solid #a1a1a1;
  border-bottom: 0px;
  width: 44%;
  height: 0;
}

.invite-select {
  border: 1px solid #a1a1a1;
  border-radius: 8px;
  color: #6351ed;
  margin-right: 20px;
  padding: 4px;
  width: 12rem;
  font-family: 'Poppins';
  font-size: inherit;
}

.uploaded-file-div {
  font-size: 0.6rem;
  color: #6351ed;
  background-color: transparent;
  border: 0px;
  text-align: center;
  margin-top: 10px;
}

.send-invite {
  border: 0px;
  background-color: #7dc5d0;
  color: white;
  font-weight: bold;
  padding: 10px;
  border-radius: 6px;
  margin-top: 10px;
  cursor: pointer
}

.send-invite:disabled {
  cursor: not-allowed;
  background-color: lightgray;
  color: #7dc5d0;
}
`;


