import React from 'react';
import { Route } from 'react-router-dom';
import Home from './Home';

function Main({ match }) {
  return (
    <>
      <Route path={`${match?.path}`} component={Home} />
    </>
  );
}

export default Main;
