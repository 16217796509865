import { Flex } from '@rebass/grid';
import { isEmpty } from 'lodash';
import React from 'react';
import { Button, LoaderWrapper, ModalBody, ModalCloseButton, ModalHeader, ModalV2 } from '../../components';
import { PageNameInput } from '../../containers/LandingPageEditorContainer/LandingPageEditorContainer.styles';

const ConfirmationModal = ({ 
    modalTitle, 
    modalWidth, 
    closeHandler, 
    clickHandler, 
    loader,
    message,
    okBtnText,
    onlyShowOk,
    noClose,
    btnMargin,
    noButton,
    mt,
    nameField,
    name,
    setName 
}) => {
    return (
        <ModalV2 bgColor='#fff' isOpen={true} width={modalWidth} onRequestClose={!noClose ? closeHandler : {}}>
            <ModalHeader fontSize='16px' style={{marginTop: mt || '', wordBreak: 'normal'}}>
                <Flex
                    width="100%"
                    justifyContent="center"
                    style={{
                        color: '#101828',
                        fontSize: '17px',
                    }}
                >
                    {modalTitle}
                </Flex>
                
                {!noClose && (
                    <ModalCloseButton color='#6351ed' onClick={closeHandler} />
                )}
            </ModalHeader>
            <ModalBody>
                {message && (
                    <div style={{fontSize: '12px', color: '#49484a'}}>*{message}</div>
                )}
                {nameField && (
                    <PageNameInput
                        id='row_name'
                        placeholder='Enter Landing Page Name'
                        name='row'
                        value={name}
                        onChange={e => setName(e.target.value)}
                    />
                )}
                {!noButton && (
                    <LoaderWrapper isLoading={loader}>
                        <Flex width='100%' margin='10px 0px' justifyContent='space-between' alignItems='center'>
                        {!onlyShowOk && (
                                <Button  width="45%"
                                padding="5px 10px"
                                height="40px"
                                bgColor="#fff"
                                color="#101828"
                                borderColor="#e4e7ec"  margin={btnMargin} onClick={closeHandler}>No</Button>
                            )}
                        
                            <Button  width="45%"
                            padding="5px 10px"
                            height="40px"
                            bgColor="#816EF8"
                            color="#fff"
                            borderColor="#e4e7ec"  
                            disabled={nameField && isEmpty(name)} 
                            margin={btnMargin} 
                            onClick={clickHandler}>
                                {okBtnText || 'Yes'}
                            </Button>
                        </Flex>
                    </LoaderWrapper>
                )}

            </ModalBody>
        </ModalV2>
    );
}

export default ConfirmationModal;