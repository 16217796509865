import styled from 'styled-components';
import { Tab } from 'react-tabs';
import { themesData } from '../../utils';

export const CampaignTab = styled(Tab).attrs({
  className: 'campaign-tab',
})`
  width: 1.5em;
  height: 1.5em;
  text-align: center;
  line-height: 2em;
  border-radius: 1em;
  background-color: #d9e1e9;
  margin: 0 3em;
  display: inline-block;
  position: relative;
  border: none;

  &:first-child::before {
    display: none;
  }

  &.active {
    border: ${({ bgColor }) =>
      bgColor || `2px solid ${themesData.purpleishBlue}`};
    background-color: transparent;
    .text {
      color: ${themesData.purpleishBlue};
      font-weight: bold;
    }
  }

  &.inactive {
    color: ${themesData.purpleishBlue};
    border: none;
    background-color: ${themesData.purpleishBlue};
  }

  &.active ~ li {
    background-color: #d9e1e9;
  }

  &.active ~ li::before {
    background-color: #d9e1e9;
  }

  .line {
    position: absolute;
    background-color: #d9e1e9;
    height: 0.09em;
    width: 6em;
    top: 0.7em;
    left: 1.5em;

    &.active {
      width: 6em;
      background-color: ${themesData.purpleishBlue};
    }

    &.inactive {
      width: 3em;
      top: 0.6em;
      left: 1.4em;
      background-color: ${themesData.purpleishBlue};
    }

    &.inactive:after {
      content: '';
      width: 3em;
      background-color: #d9e1e9;
      height: 0.09em;
      position: absolute;
      left: 3em;
    }
  }

  .number-tag {
    position: absolute;
    color: white;
    top: -3px;
    left: 8px;
    right: 8px;
    font-size: 12px;
    &.active {
      color: ${themesData.purpleishBlue};
      left: 6px;
      top: -5px;
      right: 6px;
    }
  }

  .text {
    position: absolute;
    color: #a1a8ac;
    font-size: 11px;
    top: 2em;
    left: -5.5em;
    width: 13em;
    line-height: 1.5em;
    margin-top: 5px;
  }
`;

export const WhiteCircle = styled.span`
  height: ${({ height }) => height || "25px"};
  width: ${({ width }) => width || "25px"};
  border-radius: 50%;
  border: 1px solid #816EF8;
  background-color: ${({ background }) => background || '#fff'};
  margin-right: 10px;
  position: relative;
`;

export const Line = styled.div`
  position: absolute;
  height: 100%;
  border-left: ${({ border }) => border || '3px solid #E4E7EC'};

`;
