import React from 'react';
import { Flex, Box } from '@rebass/grid';

import { HeaderDiv } from './Header.styles';
import { Grid } from '../CommonStyles';
import { HeaderLogo } from './Header.styles';
import { logo } from '../../utils/constants';

const PublicHeader = ({ logoUrl, type }) => (
  <HeaderDiv type={type} screenWidth={window.innerWidth}>
    <Grid dashboard>
    </Grid>
  </HeaderDiv>
);

export default PublicHeader;