/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react'
import { ContactGraphDisplayContainer, HeaderContainer } from '../../../componentsV2/GraphDisplay/styles';
import { Flex } from '@rebass/grid';

import { CardTitle } from '../../../componentsV2/BarChartDisplay/styles';
import { BarGraph } from '../../../componentsV2/BarChart';
import { graphColorPalette } from './contacts-data';
import ContactsRecommendationCard from './ContactsRecommendationCard';
import { ColorPallete, Typography, saveFile } from '../../../utils';
import ContactEngagementCard from './ContactEngagementCard';
import LeadCard from './LeadCard';
import { AnalyticsTable } from '../AnalyticsTable';
import { getContactsEngagement, getContactsList, getContactsRecommendationData, getEmailList, getHighlyEngagedContacts, getRecommendedContacts, getSegmentAnalyticsList, getTopEmailLists, getTopSegmentLists } from '../../../services/analyticsV2';
import { get, isEmpty } from 'lodash';
import { EmptyState, ProgressBar } from '../../../componentsV2';
import { Tooltip } from '@mui/material';
import ErrorDisplay from '../../../componentsV2/ErrorDisplay/ErrorDisplay';
import Skeleton from 'react-loading-skeleton';
import { ArrowIcon, ButtonText } from '../styles';
import downArrowBlack from '../../../assets/icons/downArrowBlack.svg';
import { getUserDetails } from '../../../redux/selector';
import { connect } from 'react-redux';
import ContactListDetailModal from './ContactListDetailModal';

const EmailListHeaders = [
  {
    name: 'Email List',
    width: '15%'
  },
  {
    name: 'Contacts',
    width: '15%'
  },
  {
    name: 'No of Emails',
    width: '30%'
  },
  {
    name: 'Engagement Score',
    width: '40%'
  }
];

const SegmentListHeaders = [
  {
    name: 'Segments',
    width: '15%'
  },
  {
    name: 'Contacts',
    width: '15%'
  },
  {
    name: 'No of Emails',
    width: '30%'
  },
  {
    name: 'Engagement Score',
    width: '40%'
  }
]

const ContactsListHeaders = [
  {
    name: 'Email',
    width: '15%'
  },
  {
    name: 'First Name',
    width: '15%'
  },
  {
    name: 'Last Name',
    width: '15%'
  },
  {
    name: 'No of Emails',
    width: '20%'
  },
  // {
  //   name: 'MoM',
  //   width: '10%'
  // },
  // {
  //   name: 'QoQ',
  //   width: '10%'
  // },
  {
    name: 'Engagement Score',
    width: '35%'
  }
]


const ContactsTab = ({
  startDate,
  showEnterpriseDropdownSelectedOption,
  userDetails,
  userData,
  selectedUsers
}) => {
  const [leadTab, setLeadTab] = useState(0);
  const [contactsTab, setContactsTab] = useState(0);
  const [page, setPage] = useState(1);
  const [tableLoading, setTableLoading] = useState(false);
  const [paginationData, setPaginationData] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [maxPage, setMaxPage] = useState(null);
  const [pageIndex, setPageIndex] = useState(0);
  const [errorDisplay, setErrorDisplay] = useState(false);
  const [topEngagedData, setEngagedData] = useState([]);
  const [engagedLoading, setEngagedLoading] = useState(false);
  const [engagedError, setEngagedError] = useState(false);
  const [graphLoading, setGraphLoading] = useState(false);
  const [graphError, setGraphError] = useState(false);
  const [performanceGraphData, setPerformanceGraphData] = useState([]);
  const [segmentPerformanceGraphData, setSegmentPerformanceGraphData] = useState([]);
  const [selectedDropdownOption, setSelectedDropdownOption] = useState('Email List');
  const [showOptionsDropdown, setShowOptionsDropdown] = useState(false);
  const [engagementLoading, setEngagementLoading] = useState(false);
  const [engagementGraphData, setEngagementGraphData] = useState([]);
  const [engagedGraphError, setEngagedGraphError] = useState(false);
  const [csvLoading, setCsvLoading] = useState(false);
  const [csvError, setCsvError] = useState('');
  const [recommendationData, setRecommendationData] = useState([]);
  const [highlyEngagedContactsLoading, setHighlyEngagedContactsLoading] = useState(false);
  const [highlyEngagedContactsErrorDisplay, setHighlyEngagedContactsErrorDisplay] = useState(false);
  const [highlyEngagedEntAdvisorContacts, setHighlyEngagedEntAdvisorContacts] = useState([]);
  const [emailListModalOpen, setEmailListModalOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState();
  const [type, setType] = useState('');
  const limit = 10;
  const dropdownWrapper = useRef(null);

  const isEnterprise = showEnterpriseDropdownSelectedOption === 'My Enterprise' ? true : false;

  const engagedGraphMapping = {
    high_count: 'High',
    low_count: 'Low',
    medium_count: 'Medium'
  }

  const fetchEmailLists = (pageNo) => {
    setTableLoading(true);
    getEmailList(pageNo, limit, startDate, isEnterprise, selectedUsers).then(res => {
      setTableData(get(res, 'result.data', []));
      setPaginationData(get(res, 'result.pagination', {}))
      setMaxPage(Math.ceil(get(res, 'result.pagination.total_results', 0)/limit))
      setTableLoading(false);
      setErrorDisplay(false);
    }).catch(err => {
      setTableLoading(false);
      setErrorDisplay(true);
      console.log(err)
    });
  }

  const fetchSegmentLists = (pageNo) => {
    setTableLoading(true);
    getSegmentAnalyticsList(pageNo, limit, startDate, isEnterprise, selectedUsers).then(res => {
      setTableData(get(res, 'result.data', []));
      setPaginationData(get(res, 'result.pagination', {}))
      setMaxPage(Math.ceil(get(res, 'result.pagination.total_results', 0)/limit))
      setTableLoading(false);
      setErrorDisplay(false);
    }).catch(err => {
      setTableLoading(false);
      setErrorDisplay(true);
      console.log(err)
    });
  }

  const fetchRecommendedContacts = () => {
    setCsvLoading(true);
    setCsvError('');
    getRecommendedContacts(startDate).then(res => {
      setCsvLoading(false);
      saveFile(res, 'Recommended_Contacts.csv');
    }).catch(err => {
      setCsvLoading(false);
      setCsvError('A Problem Occured, we could not fetch your data');
    })
  }

  const fetchContactsList = (pageNo) => {
    setTableLoading(true);
    getContactsList(startDate, limit, pageNo, isEnterprise, selectedUsers).then(res => {
      setTableData(get(res, 'result.data', []));
      setPaginationData(get(res, 'result.pagination', {}))
      setMaxPage(Math.ceil(get(res, 'result.pagination.total_results', 0)/limit))
      setTableLoading(false);
      setErrorDisplay(false);
    }).catch(err => {
      setTableLoading(false);
      setErrorDisplay(true);
      console.log(err)
    });
  }

  const fetchEngagementGraphData = () => {
    setEngagementLoading(true);
    getContactsEngagement(startDate, isEnterprise, selectedUsers).then(res => {
      console.log('res', res);
      let engagementBarData = [];
      if(!isEmpty(Object.keys(get(res, 'result', {})))) {
        Object.keys(get(res, 'result', {})).forEach((item, index) => {
          engagementBarData.push({
            name: engagedGraphMapping[item],
            'Engaged Contacts': get(res, 'result', {})[item],
            fill: item === 'high_count' ? '#00B186' : item === 'medium_count' ? '#FFA32C' : '#CE93D8'
          })
        });
        setEngagementGraphData(engagementBarData);
      }
      if(showEnterpriseDropdownSelectedOption === 'My Activity') {
        fetchContactRecommendationData();
      } else {
        setEngagementLoading(false);
      }
      setEngagedGraphError(false);
    }).catch(err => {
      console.log(err);
      setEngagementLoading(false);
      setEngagedGraphError(true);
    })
  }

  const fetchEngagedData = (tab) => {
    setEngagedLoading(true);
    setLeadTab(tab);
    console.log(tab)
    let serviceMapping = {
      0: getContactsList,
      1: getTopEmailLists,
      2: getTopSegmentLists
    }

    let selectedService = serviceMapping[tab];
    selectedService(startDate, 5, tab === 0 ? 1 : isEnterprise, tab === 0 ? isEnterprise : selectedUsers, selectedUsers).then(res => {
      setEngagedData(tab === 0 ? get(res, 'result.data', []) : get(res, 'result', []));
      setEngagedLoading(false);
      setEngagedError(false);
    }).catch(err => {
      console.log(err);
      setEngagedLoading(false);
      setEngagedError(true);
    })
  }

  const processEmailPerformanceGraphData = (data) => {
    let emailBarData = [];
    setSegmentPerformanceGraphData([]);
    if (!isEmpty(Object.keys(data))) {
      Object.keys(data).forEach((item, index) => {
        emailBarData.push({
          name: data[item].list_name,
          'Engagement Score': data[item].engagement_score,
          fill: graphColorPalette[index],
        });
      });
      setPerformanceGraphData(emailBarData); 
    } else {
      setPerformanceGraphData([]);
    }
  };

  const processSegmentPerformanceGraphData = (data) => {
    let segmentBarData = [];
    setPerformanceGraphData([]);
    if (!isEmpty(Object.keys(data))) {
      Object.keys(data).forEach((item, index) => {
        segmentBarData.push({
          name: data[item].list_name,
          'Engagement Score': data[item].engagement_score,
          fill: graphColorPalette[index],
        });
      });
      setSegmentPerformanceGraphData(segmentBarData);
    } else {
      setSegmentPerformanceGraphData([]);
    }
  };

  const fetchEmailPerformance = () => {
    setGraphLoading(true);
    getTopEmailLists(startDate, 9, isEnterprise, selectedUsers)
      .then((res) => {
        console.log('performance 1 data--->', res);
        processEmailPerformanceGraphData(get(res, 'result', []));
        setGraphLoading(false);
        setGraphError(false);
      })
      .catch((err) => {
        setGraphLoading(false);
        setGraphError(true);
      });
  };

  const fetchSegmentPerformance = () => {
    setGraphLoading(true);
    getTopSegmentLists(startDate, 9, isEnterprise, selectedUsers)
      .then((res) => {
        console.log('segment list response graph---->', res);
        processSegmentPerformanceGraphData(get(res, 'result', []));
        setGraphLoading(false);
        setGraphError(false);
      })
      .catch((err) => {
        setGraphLoading(false);
        setGraphError(true);
      });
  };

  const fetchHighlyEngagedEntContacts = () => {
    setHighlyEngagedContactsLoading(true);
    getHighlyEngagedContacts(startDate, isEnterprise, selectedUsers).then((res) => {
      console.log("ent engaged contacts--->", res);
      setHighlyEngagedEntAdvisorContacts(get(res, 'result.data', []))
      setHighlyEngagedContactsLoading(false);
      setHighlyEngagedContactsErrorDisplay(false)
    }).catch((err) => {
      console.log("ERROR", err);
      setHighlyEngagedContactsLoading(false);
      setHighlyEngagedContactsErrorDisplay(true)
    })
  };

  const handleDropdown = (type) => {
    setSelectedDropdownOption(type);
    setShowOptionsDropdown(false);
    if (type === 'Email List') {
      fetchEmailPerformance();
    } else {
      fetchSegmentPerformance();
    }
  };

 
  const loadData = (tab, pageNo) => {
    switch (tab) {
      case 0:
        fetchEmailLists(pageNo);
        break;
      case 1:
        fetchSegmentLists(pageNo);
        break;
      case 2: 
        fetchContactsList(pageNo);
        break;
      default:
        fetchEmailLists(pageNo);
        break;
    }

  }

  const handlePageChange = (type) => {
    let pageNo;
    if(type === 'next') {
      pageNo = page + 1
      setPage(pageNo);
    } else {
      pageNo = page - 1;
      setPage(pageNo);
    }

    loadData(contactsTab, pageNo);

  }

  const handlePageClick = (pageNo) => {
    setPage(pageNo);

    loadData(contactsTab, pageNo);
  }

  const handleContactsTabChange = (tab) => {
    setContactsTab(tab);
    setPage(1);
    setMaxPage(null);
    setPageIndex(0);
    setErrorDisplay(false);
    setPaginationData(null);

    loadData(tab, 1);
  }

  const handleRetryClick = () => {
    if (contactsTab === 0) {
      fetchEmailLists();
    } else if (contactsTab === 1) {
      fetchSegmentLists()
    } else {
      fetchContactsList();
    }
  };

  const handleDropdownRetryClick = () => {
    console.log('drop down value--->', selectedDropdownOption);
    if (selectedDropdownOption === 'Email List') {
      fetchEmailPerformance();
    } else {
      fetchSegmentPerformance();
    }
  };

  const fetchContactRecommendationData = () => {
    getContactsRecommendationData(startDate).then(res => {
      console.log("recommendation data--->", res)
      setRecommendationData(get(res, 'result', []))
      setEngagementLoading(false);
      setEngagedGraphError(false);
    }).catch(err => {
      console.log("ERROR--->", err);
      setEngagementLoading(false);
      setEngagedGraphError(true);
    })
  };

  const onRowClick = (row, rowType) => {
    setEmailListModalOpen(true);
    setSelectedRow(row);
    setType(rowType);
  }

  const handleContactListModalClose = () => {
    setEmailListModalOpen(false);
    setSelectedRow('');
    setType('');
  }

  const tableViewForEmailAndSegmentList = () => {
    return (
      <>
        {!tableLoading && errorDisplay === false && isEmpty(tableData) && (
          <EmptyState
            height="680px"
            title="No Analytics Data"
            subTitle="Send out campaigns to get insights"
          />
        )}
        {!isEmpty(tableData) &&
          !tableLoading &&
          errorDisplay === false &&
          tableData.map((item, index) => (
            <Flex
              width="100%"
              alignItems="center"
              backgroundColor={ColorPallete.white}
              style={{
                borderTop:
                  index !== 0 && `1px solid ${ColorPallete.neutral200}`,
                borderBottom:
                  index !== 9 && `1px solid ${ColorPallete.neutral200}`,
                cursor: 'pointer'  
              }}
              onClick={() => onRowClick(item, contactsTab === 0 ? 'email_list' : 'segments_list')}
            >
              {item?.list_name && (
                <Tooltip title={item.list_name.length > 15 ? item.list_name : null} arrow>
                  <Flex
                    width="15%"
                    padding="16px"
                    color={ColorPallete.neutral900}
                    fontSize={Typography.TextMedium}
                    style={{
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    {item?.list_name.length > 15 ? item?.list_name.substring(0, 15) + '...' : item?.list_name}
                  </Flex>
                </Tooltip>
              )}

              {(item?.contacts_count || item?.contacts_count === 0) && (
                <Flex
                  width="15%"
                  padding="16px"
                  color={ColorPallete.neutral900}
                  fontSize={Typography.TextMedium}
                >
                  {item.contacts_count}
                </Flex>
              )}

              <Flex width="30%" padding="16px" fontSize={Typography.TextMedium}>
                <Tooltip title={item?.campaign_name} arrow placement="bottom">
                  <Flex
                    style={{
                      color: ColorPallete.neutral900,
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                      width: '100%',
                      textAlign: 'left',
                    }}
                  >
                    {item?.block_count || '-'}
                  </Flex>
                </Tooltip>
              </Flex>

              {/* {item?.mom_value && (
                <Flex
                  width="15%"
                  padding="16px"
                  color={ColorPallete.green}
                  fontSize={Typography.TextMedium}
                >
                  <img
                    src="/assets/images/Analytics/analytics-polygon.svg"
                    alt=""
                    style={{ margin: '0px 2px 0px 0px' }}
                  />{' '}
                  {item.mom_value}.00%
                </Flex>
              )}

              {item?.qoq_value && (
                <Flex
                  width="15%"
                  padding="16px"
                  color={ColorPallete.green}
                  fontSize={Typography.TextMedium}
                >
                  <img
                    src="/assets/images/Analytics/analytics-polygon.svg"
                    alt=""
                    style={{ margin: '0px 2px 0px 0px' }}
                  />{' '}
                  {item.qoq_value}.00%
                </Flex>
              )} */}

              <Flex width="40%" padding="16px">
                <ProgressBar value={item.engagement_score} />
              </Flex>
            </Flex>
          ))}
        {!tableLoading && errorDisplay === true && (
          <ErrorDisplay height="680px" onClick={handleRetryClick} />
        )}
      </>
    );
  }

  const tableViewForContactsList = () => {
    return (
      <>
        {!tableLoading && errorDisplay === false && isEmpty(tableData) && (
          <EmptyState
            height="680px"
            title="No Analytics Data"
            subTitle="Send out campaigns to get insights"
          />
        )}
        {!isEmpty(tableData) && !tableLoading && errorDisplay === false && tableData.map((item, index) => (
          <Flex
            width="100%"
            alignItems="center"
            backgroundColor={ColorPallete.white}
            style={{
              borderTop: index !== 0 && `1px solid ${ColorPallete.neutral200}`,
              borderBottom: index !== 9 && `1px solid ${ColorPallete.neutral200}`,
              cursor: 'pointer'
            }}
            onClick={() => onRowClick(item, 'contact')}
          >
            <Flex
              width="15%"
              padding="16px"
              fontSize={Typography.TextMedium}
            >
              <Tooltip title={item?.email?.length > 15 ? item?.email : null} arrow placement="bottom">
                <span style={{
                  color: ColorPallete.neutral900,
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                  width: '100%',
                  textAlign: 'left'
                }}>
                  {item?.email?.length > 15 ? item?.email?.substring(0, 15) + '...' : item?.email || '-'}
                </span>
              </Tooltip>
            </Flex>

            <Flex
              width="15%"
              padding="16px"
              fontSize={Typography.TextMedium}
            >
              <Tooltip title={item?.first_name?.length > 15 ? item?.first_name : null} arrow placement="bottom">
                <span style={{
                  color: ColorPallete.neutral900,
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                  width: '100%',
                  textAlign: 'left'
                }}>
                  {item?.first_name?.length > 15 ? item?.first_name?.substring(0, 15) + '...' : item?.first_name || '-'}
                </span>
              </Tooltip>
            </Flex>

            <Flex
              width="15%"
              padding="16px"
              fontSize={Typography.TextMedium}
            >
              <Tooltip title={item?.last_name?.length > 15 ? item?.last_name : null} arrow placement="bottom">
                <Flex style={{
                  color: ColorPallete.neutral900,
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                  width: 'auto',
                  textAlign: 'left'
                }}>
                  {item?.last_name?.length > 15 ? item?.last_name?.substring(0, 15) + '...' : item?.last_name || '-'}
                </Flex>
              </Tooltip>
            </Flex>
           

              <Flex
                width={contactsTab === 1 ? "30%" : '20%'} 
                padding="16px"
                fontSize={Typography.TextMedium}
              >
                {/* <Tooltip title={item?.campaign_name} arrow placement="bottom"> */}
                  <span style={{
                    color: ColorPallete.neutral900,
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    width: 'auto',
                    textAlign: 'left'
                  }}>
                    {item?.total_blocks || '-'}
                  </span>
                {/* </Tooltip> */}
              </Flex>
            

            <Flex width="35%" padding="16px">
              <ProgressBar value={item.engagement_score} />
            </Flex>
          </Flex>
        ))}
        {!tableLoading && errorDisplay === true && (
          <ErrorDisplay height="680px" onClick={handleRetryClick} />
        )}
      </>
    )
  }

  const onNextClick = () => {
    setPageIndex(pageIndex + 8);
  }

  const onPreviousClick = () => {
    setPageIndex(pageIndex - 8);
  }


  useEffect(() => {
    if (startDate) {
      loadData(contactsTab, 1);
      fetchEngagedData(0);
      if (selectedDropdownOption === 'Email List') {
        fetchEmailPerformance();
      } else {
        fetchSegmentPerformance();
      }
      // fetchSegmentPerformance();
      fetchEngagementGraphData();
      // fetchContactRecommendationData();
      if (showEnterpriseDropdownSelectedOption === "My Enterprise" || !isEmpty(selectedUsers)) {
        fetchHighlyEngagedEntContacts();
      }
    }
  }, [startDate, selectedUsers, showEnterpriseDropdownSelectedOption]);

  
console.log("performance data--->", performanceGraphData)
  return (
    <Flex width="100%" flexDirection="column">
      <Flex width="100%" justifyContent="space-between">
        <Flex
          borderRadius='8px'
          width={
            showEnterpriseDropdownSelectedOption === 'My Enterprise' || !isEmpty(selectedUsers)
              ? '75%'
              : '73%'
          }
          flexDirection="column"
          style={{
            border: `1px solid ${ColorPallete.neutral200}`,
            borderTopRightRadius:
              showEnterpriseDropdownSelectedOption === 'My Enterprise' || !isEmpty(selectedUsers)
                ? '0px'
                : '8px',
            borderBottomRightRadius:
              showEnterpriseDropdownSelectedOption === 'My Enterprise' || !isEmpty(selectedUsers)
                ? '0px'
                : '8px',
            
            boxShadow: '0px 1px 2px 0px #1018281F',
          }}
        >
          <HeaderContainer ref={dropdownWrapper}>
            <CardTitle
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
              onClick={() => setShowOptionsDropdown(!showOptionsDropdown)}
            >
              <img
                src="/assets/images/Analytics/Email-Icon.svg"
                alt=""
                style={{ margin: '0px 4px 0px 0px' }}
              />
              <ButtonText>{selectedDropdownOption}</ButtonText>
              <ArrowIcon
                style={{ margin: '7px 0 3px 6px' }}
                selected={showOptionsDropdown}
                src={downArrowBlack}
                alt="down"
              />
              {showOptionsDropdown && (
                <Flex
                  backgroundColor={ColorPallete.white}
                  flexDirection="column"
                  width="10.5%"
                  style={{
                    borderRadius: '4px',
                    border: `1px solid ${ColorPallete.neutral200}`,
                    position: 'absolute',
                    top: '27%',
                    zIndex: '1',
                    boxShadow: '0px 1px 2px 0px #1018281F',
                  }}
                >
                  <Flex
                    padding="10px 16px"
                    color={ColorPallete.neutral900}
                    fontSize={Typography.TextLarge}
                    alignItems="center"
                    style={{ cursor: 'pointer', justifyContent: 'center' }}
                    onClick={() => handleDropdown('Email List')}
                  >
                    Email List
                  </Flex>
                  <Flex
                    padding="10px 16px"
                    color={ColorPallete.neutral900}
                    fontSize={Typography.TextLarge}
                    alignItems="center"
                    style={{ cursor: 'pointer', justifyContent: 'center' }}
                    onClick={() => handleDropdown('Segment List')}
                  >
                    Segment List
                  </Flex>
                </Flex>
              )}
            </CardTitle>
            {/* <Button
              bgColor="#fff"
              color="#101828"
              fontSize="14px"
              border="1px solid #E4E7EC"
              borderRadius="4px"
              margin="0px 10px 0px 0px"
              padding="8px 16px"
              boxShadow="0px 1px 2px 0px #1018281F"
              width="11%"
            >
              <Flex justifyContent="space-between" alignItems="center">
                <img alt="filter" src="/assets/images/Analytics/Filter-icon.svg" />
                <Flex>
                  Filter
                </Flex>
              </Flex>
            </Button> */}
          </HeaderContainer>
          <ContactGraphDisplayContainer>
            {graphLoading && <Skeleton height={350} baseColor={ColorPallete.neutral100} />}
            {!graphLoading && graphError === false && (!isEmpty(performanceGraphData) || !isEmpty(segmentPerformanceGraphData)) && (
              <BarGraph
                data={
                  selectedDropdownOption === 'Email List'
                    ? performanceGraphData
                    : segmentPerformanceGraphData
                }
                dataKey={['name', 'Engagement Score']}
                noInterval
              />
            )}
            {!graphLoading && graphError === true && (
              <ErrorDisplay height={350} onClick={handleDropdownRetryClick} />
            )}
            {!graphLoading && !graphError && selectedDropdownOption === 'Email List' && isEmpty(performanceGraphData) && (
              <EmptyState 
                title="No Analytics Data"
                subTitle="Send out campaigns to get insights"
                height="350px"
              />
            )}
            {!graphLoading && !graphError && selectedDropdownOption === 'Segment List' && isEmpty(segmentPerformanceGraphData) && (
              <EmptyState 
                title="No Analytics Data"
                subTitle="Send out campaigns to get insights"
                height="350px"
              />
            )}
          </ContactGraphDisplayContainer>
        </Flex>
        <Flex width="25%">
          <ContactsRecommendationCard
            title="Recommendations"
            graphData={engagementGraphData}
            recommendationData={recommendationData}
            loading={engagementLoading}
            error={engagedGraphError}
            onRetryClick={fetchEngagementGraphData}
            csvLoading={csvLoading}
            onDownload={fetchRecommendedContacts}
            csvError={csvError}
            showEnterpriseDropdownSelectedOption={
              showEnterpriseDropdownSelectedOption
            }
            selectedFirms={selectedUsers}
            highlyEngagedEntContacts={highlyEngagedEntAdvisorContacts}
            entContactsLoading={highlyEngagedContactsLoading}
            handleHighlyEngagedContactsRetryClick={fetchHighlyEngagedEntContacts}
            highlyEngagedEntContactsError={highlyEngagedContactsErrorDisplay}
            userData={userData}
          />
        </Flex>
      </Flex>
      <Flex flexDirection="column" width="100%" mt="20px">
        <Flex color={ColorPallete.black} fontSize={Typography.Heading}>
          Insights
        </Flex>
        <Flex
          color={ColorPallete.neutral600}
          fontSize={Typography.TextMedium}
          pt="3px"
        >
          Discover your most engaged contacts and capitalize on opportunities
          through targeted segmentation.
        </Flex>
      </Flex>

      <Flex width="100%" justifyContent="space-between" mt="15px">
        <Flex width="47%">
          <ContactEngagementCard
            graphData={engagementGraphData}
            loading={engagementLoading}
            error={engagedGraphError}
            onRetryClick={fetchEngagementGraphData}
          />
        </Flex>
        <Flex width="47%">
          <LeadCard
            leadTab={leadTab}
            setLeadTab={setLeadTab}
            handleTabChange={fetchEngagedData}
            data={topEngagedData}
            loading={engagedLoading}
            engagedError={engagedError}
          />
        </Flex>
      </Flex>

      <Flex flexDirection="column" width="100%" mt="20px">
        <Flex color={ColorPallete.black} fontSize={Typography.Heading}>
          Contacts Data
        </Flex>
        <Flex
          color={ColorPallete.neutral600}
          fontSize={Typography.TextMedium}
          pt="3px"
        >
          Explore the engagement trends of your contacts, segments and lists{' '}
        </Flex>
      </Flex>

      <Flex
        flexDirection="column"
        width="100%"
        mt="20px"
        style={{
          border: `1px solid ${ColorPallete.neutral200}`,
          boxShadow: '0px 1px 2px 0px #1018281F',
          borderRadius: '8px',
        }}
      >
        <Flex
          padding={!isEmpty(paginationData) ? '16px 16px 5px' : '16px'}
          color={ColorPallete.neutral900}
          fontSize={Typography.Heading}
        >
          Contacts Engagement
        </Flex>
        <Flex>
          {tableLoading && (
            <Skeleton width={100} baseColor={ColorPallete.neutral100} />
          )}
          {!isEmpty(paginationData) && !tableLoading && (
            <Flex
              padding="0px 16px 16px"
              color={ColorPallete.neutral600}
              fontSize={Typography.TextMedium}
              pt="3px"
            >
              {paginationData?.total_results} {paginationData?.total_results > 1 ? 'Records' : 'Record'}
            </Flex>
          )}
        </Flex>
        <Flex
          width="100%"
          style={{ borderTop: `1px solid ${ColorPallete.neutral200}` }}
        >
          <Flex
            padding="16px"
            style={{
              cursor: 'pointer',
              borderBottom:
                contactsTab === 0 && `3px solid ${ColorPallete.neutral900}`,
            }}
            fontSize={Typography.TextLarge}
            color={
              contactsTab === 0
                ? ColorPallete.neutral900
                : ColorPallete.neutral600
            }
            onClick={() => handleContactsTabChange(0)}
          >
            Email Lists
          </Flex>
          <Flex
            padding="16px"
            style={{
              cursor: 'pointer',
              borderBottom:
                contactsTab === 1 && `3px solid ${ColorPallete.neutral900}`,
            }}
            fontSize={Typography.TextLarge}
            color={
              contactsTab === 1
                ? ColorPallete.neutral900
                : ColorPallete.neutral600
            }
            onClick={() => handleContactsTabChange(1)}
          >
            Segments
          </Flex>
          <Flex
            padding="16px"
            style={{
              cursor: 'pointer',
              borderBottom:
                contactsTab === 2 && `3px solid ${ColorPallete.neutral900}`,
            }}
            fontSize={Typography.TextLarge}
            color={
              contactsTab === 2
                ? ColorPallete.neutral900
                : ColorPallete.neutral600
            }
            onClick={() => handleContactsTabChange(2)}
          >
            Contacts
          </Flex>
        </Flex>
        <AnalyticsTable
          headers={
            contactsTab === 0
              ? EmailListHeaders
              : contactsTab === 1
              ? SegmentListHeaders
              : ContactsListHeaders
          }
          data={tableData}
          onPageChange={handlePageChange}
          page={page}
          maxPage={maxPage}
          loading={tableLoading}
          paginationData={paginationData}
          onPageClick={handlePageClick}
          pageIndex={pageIndex}
          setPageIndex={setPageIndex}
          handleNextClick={onNextClick}
          handlePreviousClick={onPreviousClick}
        >
          {contactsTab === 0 || contactsTab === 1
            ? tableViewForEmailAndSegmentList()
            : tableViewForContactsList()}
        </AnalyticsTable>
      </Flex>

      {emailListModalOpen && (
        <ContactListDetailModal 
          isOpen={emailListModalOpen}
          closeHandler={handleContactListModalClose}
          row={selectedRow}
          type={type}
          startDate={startDate}
        />
      )}
    </Flex>
  );
};

const mapStateToProps = (state) => {
  return {
    userData: getUserDetails(state),
  };
};

export default connect(mapStateToProps)(ContactsTab);