import { FormControl, styled } from '@mui/material';

export const ModalChildren = styled('div')(() => ({
  padding: '0 1.5rem',
}));

export const EmailPreviewContainer = styled('div')(() => ({
  border: '1px solid #E4E7EC',
  borderRadius: '0.6875rem',
  padding: '0.625rem',
  marginTop: '0.625rem',

  '& .template-container': {
    width: '100%',
    height: '400px',
  },

  iframe: {
    border: 'none',
    width: 'inherit',
    height: 'inherit',
  },

  '& h2': {
    font: '400 0.875rem/1rem Poppins,sans-serif',
    color: '#000',
  },
}));

export const PlatformMenuContainer = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '0.5rem',
  '& svg': {
    color: '#101828',
    fontSize: '1rem',
  },

  '& p': {
    margin: 0,
    color: '#101828',
    font: '500 0.875rem/1rem Poppins,sans-serif',
  },
}));

export const PlatformDropdownContainer = styled(FormControl)(() => ({
  display: 'inline-block',
}));

export const TablistWrapper = styled('div')(() => ({
  width: '100%',
}));

export const HeaderActions=styled('div')(() => ({
  display:'flex',
  justifyContent:'space-between',
  alignItems:'center',
  gap: '0.5rem',
  width:'100%'
}))
