import React, { useState, useEffect } from 'react';
import { isEmpty, get, difference } from 'lodash';
import { getMembersList } from '../../services/configure';
import SearchContainer from '../../componentsV2/SearchContainer';
import {
  TextInput, 
  TextLabel,
  DisclaimerInput,
  ErrorMessage,
  PurpleButton
} from './CreateTopicModal.styles';

const CreateTopicModal = ({
  createGroupRequest,
  updateGroupRequest,
  editGroupDetails,
  onModalClose,
  membersList,
  themesList,
  modalStatus
}) => {
  const defaultThemeIds = get(editGroupDetails, 'default_themes', []);
  const [selectedMembers, setSelectedMembers] = useState([]);
  const [setSelectedThemes, setSelectedThemesName] = useState([]);
  const [groupName, setGroupName] = useState(editGroupDetails?.name);
  const [groupDisclaimerText, setGroupDisclaimerText] = useState(editGroupDetails?.disclaimer);
  const [memberSearchString, setMemberSearchString] = useState("");
  const [searchString, setSearchString] = useState("");
  const [existingIds, setExistingIds] = useState([]);
  const [existingThemes, setExistingThemes] = useState(defaultThemeIds);
  const [error, setError] = useState();
  const [disclaimerError, setDisclaimerError] = useState();

  const isEditFlow = !isEmpty(editGroupDetails);

  useEffect(() => {
    if (isEditFlow) {
      getMembersList({ groupId: editGroupDetails.id }).then((response) => {
        const isSuccess = get(response, 'result.success', false);
        if (isSuccess) {
          let selectedMembers = []
          const uids = get(response, 'result.data', []).map(({ uid }) => uid);
          setExistingIds(uids)
          membersList.filter((item)=>{
            if(uids.includes(item.id)){
              selectedMembers.push(item.details.name.first + ' ' + item.details.name.last)
            }
          })
          setSelectedMembers(selectedMembers);
          if(defaultThemeIds){
            let filteredThemes = themesList.filter((item) => defaultThemeIds.includes(item.id))
            setSelectedThemesName(filteredThemes.map(({name})=> name))
          }
        }
      });
    } else {
      setSelectedMembers([]);
      setSelectedThemesName([]);
    }
  }, []);

  const onCreateGroupSubmit = (values) => {
    if (!isEditFlow) {
      createGroupRequest({
        params: {
          name: values.groupName,
          disclaimer: values.groupDisclaimerText,
          addUids: values.selectedMemberIds,
          default_themes: JSON.stringify({
            add: values.selectedThemeIds,
            remove: [],
          }),
        },
      });
    } else {
      let removeIds = existingIds.filter((value) => !values.selectedMemberIds.includes(value))
      let removeThemes = existingThemes.filter((value) => !values.selectedThemeIds.includes(value))

      updateGroupRequest({
        params: {
          groupId: editGroupDetails.id,
          name: values.groupName,
          disclaimer: values.groupDisclaimerText,
          addUids: values.selectedMemberIds,
          removeUids: removeIds,
          default_themes: JSON.stringify({
            add: values.selectedThemeIds,
            remove: removeThemes,
          }),
        },
      });
    }
    onModalClose();
  };


  const validate = (values) => {
    if (!groupName) {
      return setError('Please enter group name');
    }

    if (!groupDisclaimerText) {
      return setDisclaimerError('Please enter disclaimer text' );
    }

    let outputMemberIds = []
    for(let obj in selectedMembers){
      let filtered = membersList.filter((item)=> item.details.name.first + ' ' + item.details.name.last === selectedMembers[obj])
      outputMemberIds.push(filtered[0].id)
    }

    let outputThemeIds = []
    for(let obj in setSelectedThemes){
      let filtered = themesList.filter((item)=> item.name === setSelectedThemes[obj])
      outputThemeIds.push(filtered[0].id)
    }

    const output = {
      groupName,
      groupDisclaimerText,
      selectedMemberIds: outputMemberIds,
      selectedThemeIds: outputThemeIds
    }

    onCreateGroupSubmit(output)
  };

  const parseMemberNames = () => {
    let outputArr = membersList.map(({details}) => {
      let name = details.name.first + ' ' + details.name.last
      return name
    })
    return outputArr
  };

  const parseThemesList = () => {
    let outputArr = themesList.map(({name}) => name)
    return outputArr
  };

  return (
    <div style={{width: 600}}>
      <div>
            <div>
              <div>
                <TextLabel>Topic Name*
                  <TextInput
                    id="groupName"
                    name="groupName"
                    placeholder="Topic Name"
                    value={groupName}
                    onChange={(e)=>setGroupName(e.target.value)}
                  />
                  {error && <ErrorMessage>{error}</ErrorMessage>}
                </TextLabel>
                <div style={{display: 'table', width: '100%', margin: '10px 0'}}>
                    <div style={{color: '#101828', fontSize: 14}}>Disclaimer*</div>            
                    <DisclaimerInput 
                      type="text" 
                      id="text"
                      name="text"
                      placeholder="Disclaimer..."
                      value={groupDisclaimerText} 
                      onChange={(e)=>setGroupDisclaimerText(e.target.value)}
                    ></DisclaimerInput>
                    {disclaimerError && <ErrorMessage>{disclaimerError}</ErrorMessage>}
                </div>
              </div>
              <div>
                <SearchContainer
                  chipType={'Group Member'}
                  searchResults={parseMemberNames()}
                  selectedTags={selectedMembers}
                  setSelectedTags={setSelectedMembers}
                  setSearchString={setMemberSearchString}
                  searchString={memberSearchString}
                  title={'Select Group Members'}
                />
                <SearchContainer 
                  selectedTags={setSelectedThemes}
                  searchString={searchString}
                  setSearchString={setSearchString}
                  setSelectedTags={setSelectedThemesName}
                  searchResults={parseThemesList()}
                  title={`Select a Topic ${setSelectedThemes.length}/3`}
                  chipType={'topic'}
                  limit={3}
                />
              </div>
              <div>
                <PurpleButton onClick={()=>validate()}>Submit</PurpleButton>
              </div>
            </div>
      </div>
    </div>
  );
};

export default CreateTopicModal;
