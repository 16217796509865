import React from 'react';
import {
  WidgetTile,
  Tabs,
  TabList,
  Tab,
  TabPanel,
  ResponsiveImg,
  TrendingBubbleChart
} from '../../../components';

import EditorPanel from'../Trending/EditorPanel';

import { connect } from 'react-redux';

import { IconDiv, AdminSubTab } from '../DashboardContainer.styles';

import { ROLE_TYPES, roleType } from '../../../utils';
import { getChannelId, getUserRole, getRoleType } from '../../../redux/selector';
import TopInsights from '../Insights/TopInsights';
import PopularTopics from '../Topics/PopularTopics';

const Discover = ({
  setTabIndex,
  setTrendingTopicDetails,
  setSelectedExploreTabIndex,
  roleType,
  discoverTabIndex,
  onDiscoverTabChange,
  onTrendingTabClick,
  isPremuimAdvisor,
  userData,
  onCheckBoxClick,
  checkedStatus,
  setThirdPartyThemeId,
  exploreArticleRequest,
  popularTopicDetails,
  setClearEditorArticles,
  isShareBlocked,
  setPopularTopicDetails,
  exploreSetState,
  onExploreTabClick,
  shareArticleSetState,
  bucketCountRequest
}) => {
  // console.log('DISCOVER');
  // console.log(discoverTabIndex)
  const handleTabIndex = () => {
    setTabIndex(0);
    setSelectedExploreTabIndex(1);
  }


  return(
    <WidgetTile heading="Popular" theme='light'>
      <IconDiv>
        <ResponsiveImg src="/assets/images/popular.svg" />
      </IconDiv>
      <Tabs selectedIndex={discoverTabIndex} onSelect={onDiscoverTabChange} forceRenderTabPanel style={{paddingTop: '3px'}}>
        <TabList>
          <AdminSubTab padding='5px 10px 0px' onClick={onTrendingTabClick}><span className={`${discoverTabIndex === 0 ? 'active' : null}`}>Trending</span></AdminSubTab>
          <AdminSubTab padding='5px 10px 0px'><span className={`${discoverTabIndex === 1 ? 'active' : null}`}>Most Shared</span></AdminSubTab>
          <AdminSubTab padding='5px 10px 0px' onClick={onExploreTabClick}><span className={`${discoverTabIndex === 2 ? 'active' : null}`}>Most Subscribed</span></AdminSubTab>
        </TabList>

        {discoverTabIndex===0 && (<TabPanel>
          <TrendingBubbleChart
            setTrendingTopicDetails={setTrendingTopicDetails}
            setTabIndex={handleTabIndex} 
            onTrendingTabClick={onTrendingTabClick}
          />
        </TabPanel>)}
        {discoverTabIndex===1 && (<TabPanel>
          <TopInsights isShareBlocked={isShareBlocked} shareArticleSetState={shareArticleSetState} bucketCountRequest={bucketCountRequest} />
        </TabPanel>)}
        {discoverTabIndex === 2 && (<TabPanel>
          <PopularTopics
            setTabIndex={setTabIndex}
            setSelectedExploreTabIndex={setSelectedExploreTabIndex}
            setPopularTopicDetails={setPopularTopicDetails}
            roleType={roleType} 
            onExploreTabClick={onExploreTabClick}
            type='most-subscribed'
            exploreSetState={exploreSetState}
          />
        </TabPanel>)}
      </Tabs>
    </WidgetTile>
  )
}

const mapStateToProps = (state, ownProps) => {
  const roleType = getRoleType(state);
  const isPremuimAdvisor = roleType === ROLE_TYPES.ADVISOR_PREMIUM;
  // console.log('getRoleType: ', roleType)
  return {
    userData: state.user.data,
    isPremuimAdvisor
  };
};

export default connect(mapStateToProps, null)(Discover);
