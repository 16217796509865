import { get } from 'lodash';
import { takeLatest, all, call, put, spawn } from 'redux-saga/effects';
import { actions as shareActions } from '../modules/share.module';
import { postShareArticle, postUpdateContent, postCreateContent, postShareAnalytic, resheduleArticle } from '../../services/dashboard';

function* shareRequest({ payload }) {
  if(payload.mio){
    try{
      const mioRes = yield call(payload.mio.channelId ? postCreateContent : postUpdateContent, get(payload, 'mio', {}));
      yield put(shareActions.api.share.response({mio: {data: get(mioRes, 'result.data', {})}}));
    }catch(error){
      yield put(shareActions.api.share.error({name: 'MIO', platform: 'mio', type: error.type, error}));
    }
  }

  if(payload.fb){
    try{
      const fbRes = yield call(get(payload, 'fb.isScheduled') === false ? postShareArticle : resheduleArticle, get(payload, 'fb', {}));
      if(fbRes && fbRes.result && fbRes.result.data && fbRes.result.data.id && fbRes.result.data.id !== null && fbRes.result.data.id !== 'undefined' && !payload.fb.destination_id){
        yield call(postShareAnalytic, {
          id: get(fbRes, 'result.data.id'),
          data: {destination_id: get(payload, 'fb.destination_id', -1), result: 'pass'}
        });
      }
      yield put(shareActions.api.share.response({fb: {data: get(fbRes, 'result.data', {}), summary: get(payload, 'fb.override_text', {})}}));
    }catch(error){
      if(error && error.result && error.result.debug && error.result.debug.id !== null && error.result.debug.id !== 'undefined'){
        yield call(postShareAnalytic, {
          id: get(error, 'result.debug.id'),
          data: {destination_id: get(payload, 'fb.destination_id', -1), result: 'fail'}
        });
      }
      yield put(shareActions.api.share.error({name: 'Facebook', platform: 'fb', type: error.type, error}));
    }
  }
  if(payload.ln){
    try{
      const lnRes = yield call(get(payload, 'ln.isScheduled') === false ? postShareArticle : resheduleArticle, get(payload, 'ln', {}));
      yield put(shareActions.api.share.response({ln: {data: get(lnRes, 'result.data', {})}}));
    }catch(error){
      yield put(shareActions.api.share.error({name: 'LinkedIn', platform: 'ln', type: error.type, error}));
    }
  }

  if(payload.tw){
    try{
      const twRes = yield call(!payload.tw.isScheduled ? postShareArticle : resheduleArticle, get(payload, 'tw', {}));
      yield put(shareActions.api.share.response({tw: {data: get(twRes, 'result.data', {})}}));
    }catch(error){
      console.log(error)
      yield put(shareActions.api.share.error({name: 'Twitter', platform: 'tw', type: error.type, error}));
    }
  }

  if(payload.em){
    try{
      const emRes = yield call(get(payload, 'em.isScheduled') === false ? postShareArticle : resheduleArticle, get(payload, 'em', {}));
      yield put(shareActions.api.share.response({em: {data: get(emRes, 'result.data', {})}}));
    }catch(error){
      yield put(shareActions.api.share.error({name: 'Email', platform: 'em', type: error.type, error}));
    }
  }

  yield put(shareActions.api.share.setstate({isFetching: false}));
    // yield put(shareActions.api.share.response(data));
    // yield put(shareActions.api.share.error(errors));
  
  // try {
  //   const [fb, ln, tw, em] = yield all([
  //     payload.fb ? call(postShareArticle, get(payload, 'fb', {})) : [],
  //     payload.ln ? call(postShareArticle, get(payload, 'ln', {})) : [],
  //     payload.tw ? call(postShareArticle, get(payload, 'tw', {})) : [],
  //     payload.em ? call(postShareArticle, get(payload, 'em', {})) : [],
  //   ]);
  //   // const data = {
  //   //   fb: {data: get(fb, 'result.data', {}), summary: get(payload, 'fb.override_text', {})},
  //   //   ln: {data: get(ln, 'result.data', {})},
  //   //   tw: {data: get(tw, 'result.data', {})},
  //   //   em: {data: get(em, 'result.data', {})},
  //   // }
  //   yield put(shareActions.api.share.response(data));
  // } catch (error) {
  //   console.log(error);
  //   yield put(shareActions.api.share.error(error));
  // }
}

function* shareSaga() {
  yield all([takeLatest(shareActions.api.share.request, shareRequest)]);
}

export default shareSaga;
