import React from 'react';
import ContentLoader from 'react-content-loader';
import { Container, Grid } from '@mui/material';

function TableLoader(props) {
  return (
    <Container>
      <Grid width="100%" height="600px" margin="0px auto 20px">
        <ContentLoader
          viewBox="0 0 100% 100%"
          height="100%"
          width="100%"
          margin={'10px auto 20px'}
          {...props}
        >
          <rect x="0%" y="2%" rx="4" ry="4" width="98%" height="40" />
          <rect x="0%" y="12%" rx="4" ry="4" width="98%" height="40" />
          <rect x="0%" y="22%" rx="4" ry="4" width="98%" height="40" />
          <rect x="0%" y="32%" rx="4" ry="4" width="98%" height="40" />
          <rect x="0%" y="42%" rx="4" ry="4" width="98%" height="40" />
          <rect x="0%" y="52%" rx="4" ry="4" width="98%" height="40" />
          <rect x="0%" y="62%" rx="4" ry="4" width="98%" height="40" />
          <rect x="0%" y="72%" rx="4" ry="4" width="98%" height="40" />
          <rect x="0%" y="82%" rx="4" ry="4" width="98%" height="40" />
        </ContentLoader>
      </Grid>
    </Container>
  );
}

export default TableLoader;
