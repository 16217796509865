import React, { useEffect, useState } from 'react';
import { LoaderWrapper, } from '../../components';
import { Flex } from '@rebass/grid';
import { ResponsiveImg } from '..';
import { ErrorContainer } from '../';
import './InviteModals.css';
import { Button } from '../Form';
import { isEmpty } from 'lodash';

const InviteEmailAddress = ({
    setSelectedIndex, 
    setInviteData, 
    isConnected, 
    membership,
    membersList,
    setMembersList,
}) => {
    
    const [file, setFile] = useState('');
    const [listId, setListId] = useState('-1');
    const [clickedLoader, toggleClickedLoader] = useState(false);

    const changeHandler = (source, index) => {
        console.log('[SOURCE] - ',index, ' ', source.target.name, '...', source.target.value);
        let membersListCopy = [...membersList];
        let contact = membersListCopy[index];
        contact[source.target.name] = source.target.value;
        if (source.target.name == 'email') {
            let pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
            if (pattern.test(contact.email)) {
                if(membership === 'firm') {
                    contact.valid = !isEmpty(contact['firm_name']);
                    contact.hasError = false;
                }
                else {
                    contact.valid = true;
                    contact.hasError = false;
                }
            }    
            else 
                contact.valid = false;
        }
        else if(source.target.name === 'firm_name') {
            let pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
            contact.valid = !isEmpty(contact['firm_name']) && pattern.test(contact.email);
        }
        console.log('[CONTACT LIST] - ', membersListCopy);
        setMembersList(membersListCopy);
    }

    const inputBlurHandler = (source, index) => {
        console.log('blur [source] - ', source.target.name, '---', index, '...', source.target.value);
        const membersListCopy = [...membersList];
        let contact = membersListCopy[index];
        console.log(contact);
        if (source.target.name === 'email') {
            if(contact.email && !contact.valid) {
                if (membership === 'firm') {
                    let pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
                    contact.hasError = !pattern.test(contact.email);
                }
                else 
                    contact.hasError = true;
            }
            else 
                contact.hasError = false;
        }
        setMembersList(membersListCopy);
    }

    const sendInviteHandler = () => {
        setSelectedIndex(2);
        setInviteData(membersList.filter(contact => contact.valid));
    }

    const handleDelete = (index) => {
        let membersListCopy = [...membersList];
        membersListCopy.splice(index, 1);
        // console.log('[USER CONTACT LIST] - ', userContactListCopy);
        setMembersList(membersListCopy);
    }

    return (
        <Flex>
            <div className='invite-div' style={{paddingLeft: '10px'}}>
                <div  className="email-address">Enter Email Address</div>
                <ul style={{ maxHeight: '10rem', margin: 'auto', width: '80%', overflowY: 'auto', marginBottom: '4px', paddingLeft: '0px' }}>
                    {membersList.map((ele, index) => (
                        <>
                            <li
                                key={index}
                                className='flex-container'
                                style={{ 
                                    color: '#6351ed', 
                                    justifyContent: 'flex-start', 
                                    marginLeft: '0px',
                                    width: '100%' 
                                }}
                            >
                                <input
                                    type='email'
                                    spellCheck='false'
                                    name='email'
                                    placeholder='Email'
                                    style={{ 
                                        width: membership === 'member' ? '40%' : '58%', 
                                        fontFamily: 
                                        'Poppins', 
                                        border: '0px' 
                                    }}
                                    className='input-fields'
                                    value={ele.email}
                                    onChange={e => changeHandler(e, index)}
                                    onBlur={e => inputBlurHandler(e, index)}
                                />
                                {membership === 'member' ? (
                                    <>
                                        <input
                                            name='first_name'
                                            spellCheck='false'
                                            placeholder='First Name'
                                            style={{ width: '23%', fontFamily: 'Poppins', border: '0px' }}
                                            className='input-fields'
                                            value={ele.first_name}
                                            onChange={e => changeHandler(e, index)}
                                            onBlur={e => inputBlurHandler(e, index)}
                                        />
                                        <input
                                            name='last_name'
                                            spellCheck='false'
                                            placeholder='Last Name'
                                            style={{ width: '23%', fontFamily: 'Poppins', border: '0px', marginRight: '5px' }}
                                            className='input-fields'
                                            value={ele.last_name}
                                            onChange={e => changeHandler(e, index)}
                                            onBlur={e => inputBlurHandler(e, index)}
                                        />
                                    </>
                                ) : (
                                    <input
                                        name='firm_name'
                                        spellCheck='false'
                                        placeholder='Firm Name'
                                        style={{ 
                                            width: '38%', 
                                            fontFamily: 
                                            'Poppins', 
                                            border: '0px', 
                                        }}
                                        className='input-fields'
                                        value={ele.firm_name}
                                        onChange={e => changeHandler(e, index)}
                                        onBlur={e => inputBlurHandler(e, index)}
                                    />
                                )}
                                <div
                                    style={
                                        {
                                            border: index === 0 ? '' : '1px solid #6351ed',
                                            borderRadius: '12px',
                                            padding: '2px 5px 0px 4.3px',
                                            backgroundColor: index === 0 ? '' : 'transparent',
                                            cursor: index === 0 ? '' : 'pointer',
                                            width: '4%'
                                        }
                                    }>
                                    {index !== 0 && (
                                        <img
                                            alt=""
                                            src='/assets/images/emailList-delete.svg'
                                            onClick={() => handleDelete(index)}
                                        />
                                    )}

                                </div>
                            </li>
                            {
                                ele.hasError && <div className="invite-has-error">
                                    Please enter a valid email address
                                </div>
                            }
                        </>
                    ))}
                </ul>

                <button 
                    style={{
                        border: '0px',
                        color: '#6351ed',
                        fontSize: '1.6rem',
                        padding: '0',
                        cursor: 'pointer',
                        background: 'transparent',
                        display: 'block',
                        margin: 'auto',
                        marginTop: '8px',
                        marginBottom: '8px'
                    }} 
                    onClick={() => {
                        let userContactListCopy = [...membersList];
                        userContactListCopy.push({ email: '', first_name: '', last_name: '', valid: false });
                        // console.log('[USER CONTACT LIST] - ', userContactListCopy);
                        setMembersList(userContactListCopy);
                    }}
                >
                    <ResponsiveImg src="/assets/images/Add.svg" style={{ height: '23px', width: 'auto', marginTop: '-2px'}} />
                </button>
                <LoaderWrapper isLoading={clickedLoader}>
                    <Button
                        padding="7px 30px"
                        margin="0px 0px 7px"
                        disabled={(membersList.filter(contact => contact.valid).length == 0 && 
                        file == '' && listId == '-1') || !isConnected} onClick={sendInviteHandler}>Next</Button>
                </LoaderWrapper>
                {!isConnected && (
                    <ErrorContainer style={{fontSize: '11px', marginBottom: '10px'}} align='center'>*Please Connect to Email Platform</ErrorContainer>
                )}
            </div>
        </Flex>
    );
}

export default InviteEmailAddress;