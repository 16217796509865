import styled from 'styled-components';

import { Table } from '../';
import { themesData } from '../../utils';

export const EditPortfolioTable = styled(Table)`
  border-radius: 10px;
  .mio-table-title {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    font-weight: 600;
    color: #6351ed;
    background: #bcb8be;
  }
  .mio-table-body {
    height: 150px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    background: #ececec;
  }

  .mio-table-row {
    background-color: #fff;
    border-bottom: 1px solid #e4e4e4;
  }

  .mio-table-body-cell {
    color: #6351ed;
    padding: 5px 15px;
    padding-left: 30px;
    background-color: #fff !important;
    input {
      width: auto;
      height: 25px;
    }
  }
`;

export const TickerErrorMessage = styled.span`
  color: #6351ed;
  font-family: Poppins;
`;

export const DeleteTickerMessage = styled.div`
  color: ${themesData.purpleishBlue};
  padding-top: 5px;
`;