import styled from 'styled-components';
import { colors } from '../../styles';

export const ThemeStyle = styled('div')`
    color: ${({ color }) => color ? color : '#0B5CD7'};
    background: ${({ background }) => background ? background : `${colors.light_blue}`};
    border: ${({ color, border }) => color && !border ? `1px solid ${color}` : border ? border : `${colors.blue_link_text}`};
    padding: 5px 8px; 
    border-radius: 20px;
    white-space: nowrap;
    width: fit-content;
    font-size: 12px;
    font-weight: 400;
    margin: 4px;
    height: 30px;
`;


