/* eslint-disable react-hooks/exhaustive-deps */
import { Flex } from '@rebass/grid';
import React, { useEffect, useState } from 'react';
import { isEqual } from 'lodash';
import { connect } from 'react-redux';
import { actions as campaignActions } from '../../redux/modules/campaign.module';
import { actions as loginActions} from '../../redux/modules/login.module';
import { TemplateCard, TemplateCardWrapper, TemplateWrapper } from './CampaignEmailTemplateContainer.styles';
import { themesData } from '../../utils';
import { useHistory } from 'react-router-dom';
import { cascadedEmailTemplate, deleteEmailTemplate, getCascadeEmailTemplate, getEmailTemplates, postCascadeEmailTemplate } from '../../services/campaign';
import { get, isEmpty } from 'lodash';
import { Button, LoaderWrapper, Modal, ModalBody, ModalCloseButton, ModalHeader, ModalV2 } from '../../components';
import NoContentTemplate from '../../components/EmailTemplates/NoContentTemplate';
import TemplateSelector from '../../components/TemplateSelector';
import { MembersCheckBox } from '../../components/ShareContentContainerV2/Elements/ShareCheckBoxes';
import { getUsersList } from '../../services/analytics';
import { getUserRole } from '../../redux/selector';
import { CustomCloutScrollList, RadioInput, ScrollWrapper } from '../DripCampaignContainerV2/DripCampaignContainerV2.styles';
import { FilterRowChips } from '../DripCampaignContainer/DripDashboardFilter/DripDashboardFilterPopup/DripDashboardFilterPopup.styles';
import CampaignCreateEmailTemplateModal from './CampaignCreateEmailTemplateModal';

const CampaignEmailTemplateContainer = ({ 
    campaignSetState, 
    membersList, 
    emailData, 
    loginSetState,
    isAdmin,
    firmsArray,
    isEnterpriseLevelAdmin,
}) => {
    const [userTemplates, setUserTemplates] = useState([]);
    const [baseTemplateModal, toggleBaseTemplateModal] = useState(false);
    const [shareModal, toggleShareModal] = useState(false);
    const [deleteModal, toggleDeleteModal] = useState({id: '', status: false, isCascade: false});
    const [modalMessage, setModalMessage] = useState('');
    const [deleteError, setDeleteError] = useState('');
    const [baseTemplates, setBaseTemplates] = useState([]);
    const [selectedAdvisors, setSelectedAdvisors] = useState([]);
    const [OGSelectedAdvisors, setOGSelectedAdvisors] = useState([]);
    const [selectedTemplate, setSelectedTemplate] = useState('');
    const [templateLoader, setTemplateLoader] = useState(false);
    const [shareDisabled, toggleShareDisabled] = useState(false);
    const [showTooltip, toggleTooltip] = useState('');
    const [OGCascadeOption, setOGCascadeOption] = useState('');
    const [cascadeOption, setCascadeOption] = useState('advisors');
    const [selectedFirmAdvisors, setSelectedFirmAdvisors] = useState([]);
    const [selectedFirms, setSelectedFirms] = useState([]);
    const [OGSelectedFirms, setOGSelectedFirms] = useState([]);
    const [OGSelectedFirmAdvisors, setOGSelectedFirmAdvisors] = useState([]);
    const [modalLoader, toggleModalLoader] = useState(false);
    const [cascadeModalOpen, setCascadeModalOpen] = useState({status: false, id: ''});
    const [shareLoader, toggleShareLoader] = useState({status: false, id: ''});
    const [openCreateEmailTemplateModal, setOpenCreateEmailTemplateModal] = useState(false);

    const history = useHistory();

    useEffect(() => {
        const windowBodyClassList = document.body.classList;
            if (!windowBodyClassList.contains('campaign')) 
                windowBodyClassList.add('campaign');
        return () => {
            windowBodyClassList.remove('campaign');
        };
    }, []);


    useEffect(() => {
        getUsersList('all')
        .then((data) => {
            const membersList = get(data, 'result.data.users', []);
            loginSetState({ membersList });
        })
        .catch(err => console.log('ERR- - - - - -', err));
    }, []);
    

    useEffect(() => {
        getTemplates();
        campaignSetState({
            campaignId: '',
            createFlow: false,
            createMode: false,
            curFlow: '',
            selectedIndex: 0,
            setObjectives: {},
            setTopics: {},
            contentWeeks: [],
            planCampaign: {},
            campaignArticles: [],
            scheduleTimings: {},
            selectedWeek: '',
            selectedDays: '',
            selectedArticles: [],
            publishedContent: {},
            defaultTemplates: [],
            setTemplate: '',
            savedTemplate: null,
            scheduleTypes: {},
            isEdit: false,
            seriesId: '',
            isFromAddCampaign: false,
            reviewCampaign:{}
        });
    }, []);


    const newBlankTemplateHandler = value => {
        history.push(`/campaign/template/new`);
    }

    const getTemplates = () => {
        setTemplateLoader(true);
        getEmailTemplates(['default', 'cascade'])
        .then(res => {
            const templates = get(res, 'result.data.templates', [])
                .filter(c => c.created_by === "user")
                .sort((a, b) => new Date(b.created_time) - new Date(a.created_time));
            setUserTemplates(templates); 
            const baseTemplates = get(res, 'result.data.templates', []).filter(c => c.created_by !== "user");
            setBaseTemplates(baseTemplates);
            setTemplateLoader(false);
        })
        .catch(err => {
            setTemplateLoader(false);
            console.log('ERROR - - - - ', err)
        });
    }


    const selectMembersHandler = (e, selectedMember) => {
        let updatedAdvisorsList = [...selectedAdvisors];
        if(selectedMember.id === 'selectAll') {
            if(e.target.checked)
                updatedAdvisorsList = membersList.map(member => member.id);
            else
                updatedAdvisorsList = [];
            // else
            //     updatedAdvisorsList = [...OGSelectedAdvisors];
        }
        else if(selectedAdvisors.includes(selectedMember.id))
            updatedAdvisorsList = [...selectedAdvisors].filter(advisorId => advisorId!==selectedMember.id && advisorId!=='selectAll');
        else {
            updatedAdvisorsList = [...selectedAdvisors, selectedMember.id];
            if(updatedAdvisorsList.length === membersList.length-1)
                updatedAdvisorsList.push(membersList[0].id);
        }
        setSelectedAdvisors(updatedAdvisorsList);
    }


    const cascadeClickHandler = async id => {
        toggleShareLoader({status: true, id: id});
        let advisorsList = [];
        const template = userTemplates.find(temp => temp.id === id);
        advisorsList = [...get(template, 'cascaded_to', [])];
        if(isEnterpriseLevelAdmin) {
          const cascadeInfoResponse = await getCascadeEmailTemplate(id);
          const cascadeToAdvisors = get(cascadeInfoResponse, 'result.data.cascade_to_advisors', 'false');
          const cascadedFirmsAdmin = get(cascadeInfoResponse, 'result.data.firm_ids_admin', []);
          const cascadedFirmsAdvisor = get(cascadeInfoResponse, 'result.data.firm_ids_advisor', []);
          setOGCascadeOption(cascadeToAdvisors === 'true' ? 'advisors' : 'admins');
          setCascadeOption(cascadeToAdvisors === 'true' ? 'advisors' : 'admins');
          setOGSelectedFirms(cascadedFirmsAdmin);
          setSelectedFirms(cascadedFirmsAdmin);
          setOGSelectedFirmAdvisors(cascadedFirmsAdvisor);
          setSelectedFirmAdvisors(cascadedFirmsAdvisor);
        }
        else {
          setOGCascadeOption('onlyAdvisors');
          setCascadeOption('onlyAdvisors');
        }
        setSelectedTemplate(id);
        if(advisorsList && (membersList.length - 1 === advisorsList.length)) {
          setSelectedAdvisors([...advisorsList, 'selectAll']);
        } else {
          setSelectedAdvisors(advisorsList);
        }
        setOGSelectedAdvisors(advisorsList);
        toggleShareLoader({status: false, id: ''});
        toggleShareModal(true);
    }


    const shareTemplateHandler = () => {
        toggleModalLoader(true);
        let advisorsArray = [...selectedAdvisors];
        if(advisorsArray.includes('selectAll'))
          advisorsArray = advisorsArray.filter(a => a !== 'selectAll');
        const payload = {
            users: JSON.stringify(advisorsArray),
            template_id: selectedTemplate
        };
        postCascadeEmailTemplate(payload)
        .then(response => {
            if(get(response, 'result.success', '')){
                toggleModalLoader(false);
                setModalMessage('Template Cascaded Successfully!');
                setTimeout(() => {
                    closeModalHandler();
                    getTemplates();
                }, 1500);
                toggleShareDisabled(true);
            }
            else {
                toggleModalLoader(false);
                setModalMessage('Template not cascaded. Check for any errors.');
            }
        })
        .catch(err => {
            console.log('ERROR - - - -', err);
            setModalMessage('Oops..some error occurred. Please try again.')
            toggleModalLoader(false);
        });
    }

    
    const deleteTemplateHandler = () => {
        toggleModalLoader(true);
        setDeleteError('');
        deleteEmailTemplate({ id: deleteModal.id })
        .then(response => {
            toggleModalLoader(false);
            toggleDeleteModal({id: '', status: false, isCascade: false});
            getTemplates();
        })
        .catch(err => {
            toggleModalLoader(false);
            setDeleteError(get(err, 'response.data.result.error.message', 'ERROR DELETING TEMPALTE'));
        })
    }

    const closeModalHandler = () => {
        setSelectedAdvisors([]);
        setOGSelectedAdvisors([]);
        setModalMessage('');
        toggleShareModal(false);
        toggleShareDisabled(false);
    }

    const shareValidationCheck = () => {
        let disableShare = true;
        if(selectedAdvisors.includes('selectAll')) 
            disableShare = selectedAdvisors.filter(adv => adv!=='selectAll').length === OGSelectedAdvisors.length;
        else if(selectedAdvisors.length !== OGSelectedAdvisors.length)
            disableShare = false;
        else
            disableShare = selectedAdvisors.every(adv => OGSelectedAdvisors.includes(adv));
        return disableShare;
    }

    const handleCheckboxChange = (e) => {
        const { value, checked } = e.target;
        const setUpdatedFirms = cascadeOption === 'admins' ? setSelectedFirms : setSelectedFirmAdvisors;
        const oldFirms = cascadeOption === 'admins' ? selectedFirms : selectedFirmAdvisors;
        if (checked) {
          if (value === 'selectAll')
            setUpdatedFirms(firmsArray.map((firm) => firm.id));
          else setUpdatedFirms([...oldFirms, value]);
        } else {
          if (value === 'selectAll') setUpdatedFirms([]);
          else setUpdatedFirms(oldFirms.filter((id) => id !== value));
        }
      };

      const handleAdvisorCheckboxChange = (e) => {
        const { value, checked } = e.target;
        if (checked) {
          if (value === 'selectAll')
          setSelectedFirmAdvisors(firmsArray.map((firm) => firm.id));
          else setSelectedFirmAdvisors([...selectedFirmAdvisors, value]);
        } else {
          if (value === 'selectAll') setSelectedFirmAdvisors([]);
          else setSelectedFirmAdvisors(selectedFirmAdvisors.filter((id) => id !== value));
        }
      }

      const handleEnterpriseLevelAdminShare = () => {
        toggleModalLoader(true);
        setModalMessage('');
        if (cascadeOption === 'onlyAdvisors') {
          shareTemplateHandler();
        } else {

            const payload = {
                firms: cascadeOption === 'admins' ? JSON.stringify([...selectedFirms].filter(f => f !== 'selectAll')) : JSON.stringify([...selectedFirmAdvisors].filter(f => f !== 'selectAll')),
                cascade_to_advisors: cascadeOption === 'admins' ? 'false' : 'true',
            };
            cascadedEmailTemplate({
                payload,
                id: selectedTemplate
            })
                .then((res) => {
                    toggleModalLoader(false);
                    setModalMessage('Cascaded Successfully!');
                    setTimeout(() => {
                        setCascadeModalOpen({status: false, id: ''});
                        toggleShareModal(false);
                        setModalMessage('');
                    }, 3000);
                })
                .catch((err) => {
                    console.log('ERROR - - -- ', err);
                    setModalMessage('Oops..Some error occurred! Please try again later.');
                    toggleModalLoader(false);
                    toggleShareModal(false);
                });
        }
    }


    

  const handleValidation = () => {
    if(cascadeOption === 'onlyAdvisors')
      return shareValidationCheck();
    else if(cascadeOption === 'admins')
      return isEqual(selectedFirms, OGSelectedFirms);
    else
      return isEqual(selectedFirmAdvisors, OGSelectedFirmAdvisors);
  }



    return (
      <TemplateWrapper width="100%" flexDirection="column">
        <Flex
          display="flex"
          width="100%"
          justifyContent="space-between"
          flexDirection="row"
        >
          <Flex
            padding="14px 15px 0px 15px"
            fontSize="20px"
            color={themesData.black}
            style={{ fontWeight: '600' }}
          >
            Email Templates
          </Flex>
          <Button
            margin="8px 42px 0px 0px"
            borderRadius="4px"
            onClick={() => {setOpenCreateEmailTemplateModal(true);}}
          >
            <Flex display="flex" flexDirection="row" alignItems="center">
              <span style={{ display: 'flex', margin: '0px 10px 0px -12px' }}>
                <img alt=" " src="/assets/images/plus-icon.svg" />
              </span>
              <span style={{ color: '#fff'}}>Create Email Template</span>
            </Flex>
          </Button>
        </Flex>
        {/* <Flex padding="10px 15px" fontSize="14px">
          Choose a template or start a template from scratch
        </Flex> */}
        <hr
          style={{
            width: '100%',
            border: '1px solid #E4E7EC',
          }}
        />
        {templateLoader ? (
          <LoaderWrapper
            isLoading={templateLoader}
            styles={{ position: 'relative', top: '100%', right: '0%' }}
          ></LoaderWrapper>
        ) : (
          <TemplateCardWrapper>
            {/* <TemplateCard
              onClick={() => toggleBaseTemplateModal(true)}
              style={{ cursor: 'pointer' }}
            >
              <Flex height="75%" alignItems="center" justifyContent="center">
                <FontAwesomeIcon
                  icon={faPlus}
                  size="3x"
                  color={themesData.black}
                />
              </Flex>
              <Flex justifyContent="center">Create New Template</Flex>
            </TemplateCard> */}
            {userTemplates.map((item, index) => (
              <TemplateCard style={{ cursor: 'pointer' }}>
                <img
                  height="75%"
                  alt=""
                  style={{
                    borderTopLeftRadius: '3%',
                    borderTopRightRadius: '3%',
                    objectFit: 'cover',
                    objectPosition: '0px 0px',
                  }}
                  src={item.thumbnail_url || '/assets/images/postDefault4.jpg'}
                  onClick={() => history.push(`/campaign/template/${item.id}`)}
                />
                <Flex
                  justifyContent="center"
                  // justifyContent="space-evenly"
                  alignItems="center"
                  style={{ position: 'relative', padding: '20px 0px 0px 10px' }}
                  onMouseEnter={() =>
                    item.name && item.name.length > 21
                      ? toggleTooltip(index)
                      : {}
                  }
                  onMouseLeave={() => toggleTooltip('')}
                >
                  <div
                    onClick={() =>
                      history.push(`/campaign/template/${item.id}`)
                    }
                    style={{ width: '75%' }}
                  >
                    {item.name && item.name.length > 21
                      ? `${item.name.substring(0, 21)}...`
                      : item.name}
                  </div>
                  {showTooltip === index && (
                    <div
                      style={{
                        color: '#fff',
                        background: '#49484a',
                        borderRadius: '4px',
                        position: 'absolute',
                        top: '100%',
                        left: '60%',
                        fontSize: '11px',
                        padding: '2px 4px',
                      }}
                    >
                      {item.name}
                    </div>
                  )}
                  <Flex
                    justifyContent="space-evenly"
                    alignItems="center"
                    width="25%"
                  >
                    {(isAdmin || !item.is_cascade) && (
                      <img
                        alt=""
                        src="/assets/images/delete.svg"
                        style={{
                          cursor: 'pointer',
                          width: '20px',
                          height: '24px',
                        }}
                        onClick={() => {
                          toggleDeleteModal({
                            id: item.id,
                            status: true,
                            isCascade: item.is_cascade,
                          });
                          setDeleteError('');
                        }}
                      />
                    )}
                    {/* <img 
                                        alt=""
                                        src='/assets/images/delete.svg' 
                                        style={{cursor: 'pointer', width: '20px', height: '24px'}} 
                                        onClick={() => {
                                            toggleDeleteModal({id: item.id, status: true, isCascade: item.is_cascade});
                                            setDeleteError('');
                                        }} 
                                    /> */}

                    {(isEnterpriseLevelAdmin || isAdmin) && (
                      <LoaderWrapper isLoading={shareLoader.id === item.id}>
                        <img
                          src="/assets/images/forward.svg"
                          alt=""
                          onClick={() => cascadeClickHandler(item.id)}
                          onMouseEnter={() => toggleTooltip('')}
                          style={{
                            cursor: 'pointer',
                            height: '30px',
                            width: '30px',
                          }}
                        />
                      </LoaderWrapper>
                    )}
                  </Flex>
                </Flex>
              </TemplateCard>
            ))}
          </TemplateCardWrapper>
        )}
        {baseTemplateModal && (
          <Modal
            isOpen={baseTemplateModal}
            onRequestClose={() => toggleBaseTemplateModal(false)}
            size="medium"
          >
            <ModalHeader>
              Select a basic template
              <ModalCloseButton
                onClick={() => toggleBaseTemplateModal(false)}
              />
            </ModalHeader>
            <ModalBody padding="15px 15px 15px 0px">
              <TemplateSelector
                selectedValue={{ name: '' }}
                setSelectedValue={newBlankTemplateHandler}
                styles={{ justifyContent: 'space-evenly' }}
              >
                <NoContentTemplate
                  value={{
                    name:
                      !isEmpty(baseTemplates) &&
                      baseTemplates.filter((b) => b.name === 'no_content')[0]
                        .id + '-no-content',
                  }}
                  isCampaign={true}
                  flag={baseTemplateModal}
                />
                {/* <OneColumnTemplate 
                                value={{name: !isEmpty(baseTemplates) && baseTemplates.filter(b => b.name === "content")[0].id + '-one-column'}} 
                                isCampaign={true} 
                            />
                            <TwoColumnTemplate 
                                value={{name: !isEmpty(baseTemplates) && baseTemplates.filter(b => b.name === "content")[0].id + '-two-columns'}} 
                                isCampaign={true} 
                            /> */}
              </TemplateSelector>
            </ModalBody>
          </Modal>
        )}
        {shareModal && isEnterpriseLevelAdmin && (
          <ModalV2
            isOpen={shareModal}
            width="65%"
            height="auto"
            onRequestClose={closeModalHandler}
            style={{ maxHeight: '90%' }}
            bgColor="#fff"
          >
            <ModalHeader>
              <ModalCloseButton onClick={closeModalHandler} />
            </ModalHeader>
            <ModalBody>
              <LoaderWrapper>
                <Flex
                  flexDirection="column"
                  justifyContent="flex-start"
                  alignItems="center"
                  width="90%"
                  margin="30px auto 16px"
                  paddingBottom="12px"
                  // height='350px'
                  style={{
                    border: '1px solid #cacaca',
                    borderRadius: '5px',
                    // paddingBottom: '12px',
                    // position: 'relative'
                  }}
                >
                  <div
                    style={{
                      // justifyContent: 'center',
                      marginBottom: '24px',
                      color: '#000',
                      fontWeight: '500',
                      fontSize: '18px',
                      padding: '10px',
                      textAlign: 'center',
                      // paddingTop: '9px',
                      // paddingBottom: '10px',
                      width: '100%',
                      // display: 'flex',
                      backgroundColor: '#F5F5F5',
                    }}
                  >
                    Share Email Template with the following advisors
                  </div>
                  <Flex
                    width="100%"
                    justifyContent="space-between"
                    alignItems="center"
                    style={{
                      fontSize: '13px',
                      letterSpacing: '0.35px',
                      marginBottom: '20px',
                    }}
                  >
                    <Flex
                      justifyContent="center"
                      alignItems="center"
                      width="49%"
                      paddingLeft="12px"
                    >
                      <RadioInput
                        type="radio"
                        name="cascade-option"
                        id="radio_on"
                        checked={cascadeOption === 'advisors'}
                        onClick={() => {
                          setCascadeOption('advisors');
                        }}
                        style={{
                          cursor: 'pointer',
                          marginTop: '0px',
                          marginRight: '8px',
                        }}
                      />
                      <label
                        style={{
                          paddingRight: '2px',
                          color: '#49484a',
                          cursor: 'pointer',
                        }}
                        htmlFor="radio_on"
                      >
                        To All Advisors of some/all firms
                      </label>
                    </Flex>
                    <Flex
                      justifyContent="center"
                      alignItems="center"
                      width="49%"
                    >
                      <RadioInput
                        type="radio"
                        name="cascade-option"
                        id="radio_off"
                        checked={cascadeOption === 'admins'}
                        onClick={() => {
                          setCascadeOption('admins');
                        }}
                        style={{
                          cursor: 'pointer',
                          marginTop: '0px',
                          marginRight: '8px',
                        }}
                      />
                      <label
                        style={{
                          paddingRight: '2px',
                          color: '#49484a',
                          cursor: 'pointer',
                        }}
                        htmlFor="radio_off"
                      >
                        To Admins of some/all firms
                      </label>
                    </Flex>
                    <Flex
                      justifyContent="center"
                      alignItems="center"
                      width="49%"
                    >
                      <RadioInput
                        type="radio"
                        name="cascade-option"
                        id="radio_only_advisors"
                        checked={cascadeOption === 'onlyAdvisors'}
                        onClick={() => {
                          setCascadeOption('onlyAdvisors');
                        }}
                        style={{
                          cursor: 'pointer',
                          marginTop: '0px',
                          marginRight: '8px',
                        }}
                      />
                      <label
                        style={{
                          paddingRight: '2px',
                          color: '#49484a',
                          cursor: 'pointer',
                        }}
                        htmlFor="radio_only_advisors"
                      >
                        To some/all of my Advisors
                      </label>
                    </Flex>
                  </Flex>
                  <ScrollWrapper
                    position="relative"
                    maxHeight="150px"
                    width="94%"
                    alignItems="center"
                  >
                    <CustomCloutScrollList paddingLeft="27%" width="80%">
                      {cascadeOption === 'admins' ? (
                        <div>
                          <li>
                            <FilterRowChips
                              style={{
                                padding: '8px',
                                justifyContent: 'flex-start',
                                alignItems: 'center',
                                width: '100%',
                              }}
                            >
                              <label className="checkbox-button">
                                <input
                                  type="checkbox"
                                  className="checkbox-button__input"
                                  id="selectAll"
                                  name="selectAll"
                                  value="selectAll"
                                  checked={
                                    selectedFirms.length === firmsArray.length
                                  }
                                  onChange={handleCheckboxChange}
                                />
                                <span
                                  className="checkbox-button__control"
                                  style={{ marginRight: '8px' }}
                                ></span>
                                <label
                                  htmlFor="selectAll"
                                  style={{
                                    fontSize: '12px',
                                    color: '#6352EC',
                                    cursor: 'pointer',
                                  }}
                                >
                                  Select All
                                </label>
                              </label>
                            </FilterRowChips>
                          </li>
                          {firmsArray.map((firm, index) => (
                            <li>
                              <FilterRowChips style={{ padding: '8px' }}>
                                <label className="checkbox-button">
                                  <input
                                    type="checkbox"
                                    className="checkbox-button__input"
                                    id={firm.id}
                                    value={firm.id}
                                    name={firm.name}
                                    onChange={handleCheckboxChange}
                                    checked={selectedFirms.includes(firm.id)}
                                  />
                                  <span
                                    style={{ marginRight: '8px' }}
                                    className="checkbox-button__control"
                                  ></span>
                                  <label
                                    htmlFor={firm.id}
                                    style={{
                                      fontSize: '12px',
                                      color: '#6352EC',
                                      cursor: 'pointer',
                                    }}
                                  >
                                    {firm.name}
                                  </label>
                                </label>
                              </FilterRowChips>
                            </li>
                          ))}
                        </div>
                      ) : cascadeOption === 'advisors' ? (
                        <div>
                          <li>
                            <FilterRowChips
                              style={{
                                padding: '8px',
                                justifyContent: 'flex-start',
                                alignItems: 'center',
                                width: '100%',
                              }}
                            >
                              <label className="checkbox-button">
                                <input
                                  type="checkbox"
                                  className="checkbox-button__input"
                                  id="selectAll"
                                  name="selectAll"
                                  value="selectAll"
                                  checked={
                                    selectedFirmAdvisors.length ===
                                    firmsArray.length
                                  }
                                  onChange={handleAdvisorCheckboxChange}
                                />
                                <span
                                  className="checkbox-button__control"
                                  style={{ marginRight: '8px' }}
                                ></span>
                                <label
                                  htmlFor="selectAll"
                                  style={{
                                    fontSize: '12px',
                                    color: '#6352EC',
                                    cursor: 'pointer',
                                  }}
                                >
                                  Select All
                                </label>
                              </label>
                            </FilterRowChips>
                          </li>
                          {firmsArray.map((firm, index) => (
                            <li>
                              <FilterRowChips style={{ padding: '8px' }}>
                                <label className="checkbox-button">
                                  <input
                                    type="checkbox"
                                    className="checkbox-button__input"
                                    id={firm.id}
                                    value={firm.id}
                                    name={firm.name}
                                    onChange={handleAdvisorCheckboxChange}
                                    checked={selectedFirmAdvisors.includes(
                                      firm.id
                                    )}
                                  />
                                  <span
                                    style={{ marginRight: '8px' }}
                                    className="checkbox-button__control"
                                  ></span>
                                  <label
                                    htmlFor={firm.id}
                                    style={{
                                      fontSize: '12px',
                                      color: '#6352EC',
                                      cursor: 'pointer',
                                    }}
                                  >
                                    {firm.name}
                                  </label>
                                </label>
                              </FilterRowChips>
                            </li>
                          ))}
                        </div>
                      ) : (
                        <ScrollWrapper maxHeight="200px" height="200px">
                          {!isEmpty(membersList) && (
                            <MembersCheckBox
                              membersList={membersList}
                              handleAdvisorChange={selectMembersHandler}
                              selectedMembers={selectedAdvisors}
                              ogSelectedMembers={OGSelectedAdvisors}
                              fontSize="12px"
                              fromCampaignTemplate={true}
                            />
                          )}
                        </ScrollWrapper>
                      )}
                    </CustomCloutScrollList>
                  </ScrollWrapper>
                  <LoaderWrapper isLoading={modalLoader}>
                    <Button
                      // styles={{position: 'absolute', bottom: '20px'}}
                      onClick={handleEnterpriseLevelAdminShare}
                      disabled={handleValidation()}
                    >
                      Cascade
                    </Button>
                  </LoaderWrapper>
                  {modalMessage && (
                    <div style={{ fontSize: '12px', color: '#6351ed' }}>
                      *{modalMessage}
                    </div>
                  )}
                </Flex>
              </LoaderWrapper>
            </ModalBody>
          </ModalV2>
        )}
        {shareModal && !isEnterpriseLevelAdmin && (
          <ModalV2
            isOpen={shareModal}
            width="27%"
            height="auto"
            onRequestClose={closeModalHandler}
            style={{ maxHeight: '90%' }}
            bgColor="#fff"
          >
            <ModalHeader fontColor="#000">
              Share Email Template with the following advisors
              <ModalCloseButton onClick={closeModalHandler} />
            </ModalHeader>
            <ModalBody>
              {!isEmpty(membersList) && (
                <MembersCheckBox
                  membersList={membersList}
                  handleAdvisorChange={selectMembersHandler}
                  selectedMembers={selectedAdvisors}
                  ogSelectedMembers={OGSelectedAdvisors}
                  fontSize="12px"
                  fromCampaignTemplate={true}
                />
              )}
              {modalMessage && (
                <div
                  style={{
                    color: '#6351ed',
                    fontSize: '12px',
                    paddingTop: '10px',
                  }}
                >
                  *{modalMessage}
                </div>
              )}
              <LoaderWrapper isLoading={modalLoader}>
                <Button
                  disabled={shareDisabled || shareValidationCheck()}
                  onClick={shareTemplateHandler}
                >
                  Share
                </Button>
              </LoaderWrapper>
            </ModalBody>
          </ModalV2>
        )}

        {deleteModal.status && (
          <ModalV2
            isOpen={deleteModal.status}
            width="51%"
            height="auto"
            onRequestClose={() =>
              toggleDeleteModal({ id: '', status: false, isCascade: false })
            }
            style={{ maxHeight: '90%' }}
            bgColor="#fff"
          >
            <ModalHeader style={{ wordBreak: 'normal' }}>
              {isAdmin && deleteModal.isCascade
                ? 'Are you sure you want to delete this campaign? If any members have scheduled this campaign to go out, it will not be sent.'
                : 'Are you sure you want to delete this template? If any campaigns are scheduled to go that use this template, they will not be sent.'}
              <ModalCloseButton
                onClick={() =>
                  toggleDeleteModal({ id: '', status: false, isCascade: false })
                }
              />
            </ModalHeader>
            <ModalBody>
              {deleteError && (
                <div style={{ color: '#6351ed', fontSize: '12px' }}>
                  {deleteError}. Cannot be deleted!
                </div>
              )}
              <Flex justifyContent="center" alignItems="baseline">
                <LoaderWrapper isLoading={modalLoader}>
                  <Button
                    margin="10px 10px 0px 10px"
                    onClick={deleteTemplateHandler}
                  >
                    Yes
                  </Button>
                  <Button
                    onClick={() =>
                      toggleDeleteModal({
                        id: '',
                        status: false,
                        isCascade: false,
                      })
                    }
                  >
                    No
                  </Button>
                </LoaderWrapper>
              </Flex>
            </ModalBody>
          </ModalV2>
        )}
        {openCreateEmailTemplateModal && (
          <CampaignCreateEmailTemplateModal 
          isModalOpen={openCreateEmailTemplateModal}
          handleClose={() => setOpenCreateEmailTemplateModal(false)}
          />
        )}
      </TemplateWrapper>
    );
}

const mapStateToProps = state => {
    const isAdmin = getUserRole(state) === 'admin'
    const isEnterpriseLevelAdmin = isAdmin && get(state, 'user.data.details.company.enterprise', "false") === "true";
    const isFirmLevelAdmin = isAdmin && get(state, 'user.data.details.company.firm', "false") === "true";
    return {
        isAdmin: getUserRole(state) === 'admin',
        membersList: state.user.membersList ? [
            {
              id: 'selectAll',
              details: {
                name: { email: 'Select All', first: 'Select', last: 'All' },
              },
            },
            ...state.user.membersList,
        ] : [],
        isEnterpriseLevelAdmin,
        isFirmLevelAdmin,
        firmsArray: get(state, 'user.data.details.company.firms', []),
    };
}

const mapDispatchToProps = {
    campaignSetState: campaignActions.api.campaign.setstate,
    loginSetState: loginActions.api.login.setstate,
}

export default connect(mapStateToProps, mapDispatchToProps)(CampaignEmailTemplateContainer);