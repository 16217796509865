/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-script-url */
import React, { useState } from 'react';
import { Box } from '@rebass/grid';

import { ResponsiveImg, ButtonNoBorderBackground } from '../';
import { ArticleWrapper } from './InsightsArticle.styles';
import ShareContentModal from '../ShareContentModal';
import { eventHandlers, onImageError, USER_ROLE } from '../../utils';
import { isEmpty } from 'lodash';
import { getPdfLink } from '../../services/dashboard';
import { connect } from 'react-redux';
import { getChannelId, getUserRole } from '../../redux/selector';
import { actions as campaignActions } from '../../redux/modules/campaign.module';
import MemberApproveShareModal from '../DashboardArticle/MemberApproveShareModal';
import { useHistory } from 'react-router-dom';

const InsightsArticle = ({
  id,
  link,
  url,
  topic,
  place,
  date,
  year,
  channelId,
  themeId,
  isShareBlocked,
  showMio,
  shareArticleSetState,
  themeNames,
  popularThemes,
  title,
  isNewsletter,
  bucketId,
  addToNewsletterBucket,
  removeToNewsletterBucket,
  userData,
  isSponsored,
  sponsoredFunds,
  description,
  article,
  auxiliary,
  isAdmin,
  adminChannelId,
  campaignSetState
}) => {
  const [isShareModalOpen, setShareModalOpen] = useState(false);
  const [memberModal, setMemberModal] = useState(false);
  const history = useHistory();

  const onArticleShare = (value, source, link) => {
    const selectedArticlesFromDashboard = [];
    selectedArticlesFromDashboard.push(article);
    const userEmail =
      userData &&
      userData.details &&
      userData.details.user &&
      userData.details.user.email;
    if (source) {
      const urlObj =
        value.mediaUrl && value.mediaUrl[0] ? value.mediaUrl[0] : '';
      if (urlObj) {
        const imgUrl = urlObj.path + urlObj.name.system + '.' + urlObj.ext;
        window.open(imgUrl, '_blank');
      }
    } else if ((place && place === 'Third party articles')) {
      if (id.split('_')[0] === 'CC') {
        campaignSetState({ selectedArticles: selectedArticlesFromDashboard, fromDashboard: true})
        history.push("/campaignV2?type=create");
      } else {
        getPdfLink(value)
          .then((response) => {
            eventHandlers(
              'ARTICLE_CLICKED',
              popularThemes,
              userEmail,
              article
            );
            campaignSetState({ selectedArticles: selectedArticlesFromDashboard, fromDashboard: true})
            history.push("/campaignV2?type=create");
          })
          .catch((err) => console.log('[ERROR] - ', err));
      }
    } else {
      if (
        value.includes('https://') ||
        value.includes('http://') ||
        value.includes('https')
      ) {
        eventHandlers(
          'ARTICLE_CLICKED',
          popularThemes,
          userEmail,
          article
        );

        campaignSetState({ selectedArticles: selectedArticlesFromDashboard, fromDashboard: true})
        history.push("/campaignV2?type=create");
        // window.open(value, '_blank');
      } else {
        eventHandlers(
          'ARTICLE_CLICKED',
          popularThemes,
          userEmail,
          article
        );
        campaignSetState({ selectedArticles: selectedArticlesFromDashboard, fromDashboard: true})
        history.push("/campaignV2?type=create");
      }
    }
  };

  const redirectToUrl = () => {
    const userEmail =
      userData &&
      userData.details &&
      userData.details.user &&
      userData.details.user.email;
    if (isSponsored) {
      eventHandlers(
        'SPONSORED_ARTICLE_CLICKED',
        popularThemes,
        id,
        'SPONSORED_BOX',
        channelId,
        title,
        themeId,
        //(themeNames || {} )[themeId],
        !isEmpty(themeNames) ? themeNames[themeId] : '',
        topic,
        userEmail
      )
    }

    window.open(link, '_blank');
  }
  const trimmedPlace = place.split('(');

  const handleMemberPostModal = () => {
    setMemberModal(true);
  }

  const handleMemberModalClose = () => {
    setMemberModal(false);
  }
  return (
    <ArticleWrapper background="#ffffff" className='top_shared_article'>
      {/* <ArticleWrapper background="#ffffff" data-testid='top_shared_article'> */}
      <Box height="100%">
        <ResponsiveImg src={url} onError={onImageError} />
      </Box>
      <Box pl="10px" flex="1 1 auto">
        <a href="#" onClick={redirectToUrl}>
          <div
            className="selected-topic"
            title={topic && topic.length > 40 ? topic : ''}
          >
            {topic}
          </div>
        </a>
        <div className="place-date">
          {trimmedPlace[0].trim()}, {date}
        </div>
      </Box>
      {/* {!isShareBlocked && (
        <Box>
         
          <ButtonNoBorderBackground
            className="share-article"
            onClick={auxiliary && auxiliary.source === 'img'
              ? () => onArticleShare(article, 'img')
              : (place && place === 'Third party articles')
                ? () => onArticleShare(id, '', link)
                : () => onArticleShare(link)}
            style={{ margin: '5px 0px 0px' }}
          >
            <ResponsiveImg src="/assets/images/forward.svg" />
          </ButtonNoBorderBackground>
        </Box>
      )} */}
      {isShareModalOpen && (
        <ShareContentModal
          editable
          handleClose={() => setShareModalOpen(false)}
          open={isShareModalOpen}
          {...{ id, link, url, topic, place, date, year, channelId, themeId, sponsoredFunds, description }}
        />
      )}
       {memberModal && 
        <MemberApproveShareModal 
          isOpen={memberModal}
          onRequestClose={handleMemberModalClose}
          userData={userData}
          id={id}
          description={description}
          url={url}
          title={topic}
          adminChannelId={adminChannelId}
          themeId={themeId}
          link={link}
          sponsoredFunds={sponsoredFunds}
        />
      }
    </ArticleWrapper>
  );
};

const mapStateToProps = (state) => {
  const isAdmin = getUserRole(state) === USER_ROLE.admin;

  return {
    isAdmin,
    adminChannelId: getChannelId(state),
  }
}

const mapDispatchToProps = {
  campaignSetState: campaignActions.api.campaign.setstate,
}

export default connect(mapStateToProps, mapDispatchToProps)(InsightsArticle);
