import {
  faFacebookF,
  faLinkedin,
  faTwitter,
} from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Flex } from '@rebass/grid';
import React, { useState } from 'react';
import { Button } from '../../../components';
import {
  CampaignCardTooltip,
  CampaignCardWrapper,
} from './PlanCampaign.styles';
import { onImageError } from '../../../utils';
import { get, isEmpty } from 'lodash';

const CampaignCard = ({
  platform,
  time,
  content,
  id,
  title,
  imgUrl,
  description,
  channel_id,
  theme_id,
  count,
  click,
  ref_id,
  fromContentModal,
  dragHandler,
  setDraggedArticle,
  clickHandler,
  fromAssignArticle,
  fromWriteSummary,
  showPlatformBubbles,
  articleClicked,
  summaries,
  index,
  bubbleClickHandler,
  isDraggable,
  showIncomplete,
  isClickable,
  handleBtnClick,
  fromErrorModal,
  setObjectivesTabData,
  platformListMapper
}) => {
  const [titleTooltip, toggleTitleTooltip] = useState(false);
  const [descTooltip, toggleDescTooltip] = useState(false);
  const platformIcons = {
    Email: faEnvelope,
    Facebook: faFacebookF,
    Linkedin: faLinkedin,
    Twitter: faTwitter,
  };

  const dragStartHandler = () => {
    if (isDraggable)
      setDraggedArticle({
        title,
        imgUrl,
        id,
        description,
        channel_id,
        theme_id,
        override_text: summaries,
      });
  };

  const dragEndHandler = (e) => {
    e.preventDefault();
    // console.log('E - - -- ', e);
    if (fromAssignArticle && (time || count)) dragHandler(ref_id);
  };

  return (
    <CampaignCardWrapper
      isClickable={isClickable}
      onDragStart={fromAssignArticle ? dragStartHandler : () => {}}
      draggable={isDraggable}
      onDragOver={(e) => e.preventDefault()}
      onDrop={(e) => dragEndHandler(e)}
      onClick={clickHandler ? clickHandler : () => {}}
      style={
        fromWriteSummary && articleClicked
          ? { border: '2px solid #6351ed' }
          : fromWriteSummary && showIncomplete
          ? { border: '2px solid #f55449' }
          : fromAssignArticle && !isDraggable && content
          ? { border: '2px solid #4dbc39' }
          : {}
      }
    >
      {(time || count || fromErrorModal) && (
        <Flex
          // height="20%"
          width="100%"
          padding="10px"
          justifyContent={fromErrorModal ? 'flex-start' : 'space-between'}
          alignItems="center"
        >
          <Flex width="10%">
            <FontAwesomeIcon color="#326DE6" icon={platformIcons[platform]} />
          </Flex>
          <Flex width="30%" fontSize="14px" color="#000000" pl="10px">
            {platform}
          </Flex>
          {count ? (
            <Flex
              width="65%"
              justifyContent="flex-end"
              fontSize="12px"
              color="#697e93"
            >
              {count}
            </Flex>
          ) : time ? (
            <>
              <Flex width="55%"></Flex>
              <Flex width="30%" color="#000000" fontSize="12px">
                {time}
              </Flex>
            </>
          ) : null}
        </Flex>
      )}
      {!content ? (
        <Flex
          height="50%"
          justifyContent="center"
          backgroundColor="#D9E0E7"
          alignItems="center"
        >
          <Button
            onClick={handleBtnClick ? handleBtnClick : () => {}}
            styles={{
              border: '1px dotted #46494B',
              borderRadius: '5px',
              boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px',
            }}
            bgColor="#FFFFFF"
            color="#46494B"
          >
            {fromAssignArticle ? 'Drop Article' : 'Select Content'}
          </Button>
        </Flex>
      ) : (
        <Flex height="50%" justifyContent="center" alignItems="center">
          <img
            alt=""
            onDragStart={fromAssignArticle ? dragStartHandler : () => {}}
            draggable={true}
            onDragOver={(e) => e.preventDefault()}
            onDrop={(e) => dragEndHandler(e)}
            style={{ height: '100%', width: '100%' }}
            src={imgUrl}
            onError={onImageError}
          />
        </Flex>
      )}
      {!content ? (
        <Flex
          height="10%"
          margin="10px"
          width="70%"
          backgroundColor="#D9E0E7"
        ></Flex>
      ) : (
        <Flex
          height="10%"
          style={{
            position: 'relative',
            margin: !time ? '12px 0px 5px' : '5px 0px 5px',
          }}
          onMouseEnter={() => toggleTitleTooltip(true)}
          onMouseLeave={() => toggleTitleTooltip(false)}
        >
          <span
            style={{
              color: '#000000',
              fontSize: '14px',
              fontWeight: '600',
              textAlign: 'left',
            }}
          >
            {title && title.length > 45
              ? title.substring(0, 45) + '...'
              : title}
          </span>
          {titleTooltip && title && title.length > 20 && (
            <CampaignCardTooltip>{title}</CampaignCardTooltip>
          )}
        </Flex>
      )}
      {!content ? (
        <Flex
          width="80%"
          margin="10px"
          height="20%"
          backgroundColor="#D9E0E7"
        ></Flex>
      ) : (
        <Flex
          style={{ position: 'relative' }}
          onMouseEnter={() => toggleDescTooltip(true)}
          onMouseLeave={() => toggleDescTooltip(false)}
        >
          <span
            style={{
              color: '#000000',
              fontSize: '12px',
              textAlign: 'left',
              marginTop: isClickable ? '15px' : '10px',
            }}
          >
            {description && description.length > 105
              ? description.substring(0, 105) + '...'
              : description}
          </span>
          {/* <span style={{color: '#000000', fontSize: '12px', textAlign: 'left'}}>{content.description}</span> */}
          {descTooltip && description.length > 30 && (
            <CampaignCardTooltip>{description}</CampaignCardTooltip>
          )}
        </Flex>
      )}
      {showPlatformBubbles && (
        <Flex
          justifyContent="space-evenly"
          alignItems="center"
          style={{ position: 'absolute', top: '5%', left: '0', width: '100%' }}
        >
          {Object.keys(platformIcons).map((key) => (
            <div
              style={{
                padding:
                  key === 'Twitter'
                    ? '1px 3px 1px 4px'
                    : key === 'Facebook'
                    ? '2px 6px 1px 6px'
                    : key === 'Linkedin'
                    ? articleClicked
                      ? '2px 5px'
                      : '2px 5px 1px'
                    : articleClicked
                    ? '2px 4px'
                    : '2px 5px',
                borderRadius: '11px',
                border: '1px solid #6351ed',
                backgroundColor: '#fff',
                opacity: !(isEmpty(get(setObjectivesTabData, platformListMapper[key.toUpperCase()], []))) && summaries[key.toUpperCase()] ? '1' : '0.5',
                cursor: 'pointer',
                fontSize: '11px',
              }}
              onClick={
                !fromAssignArticle ? () => bubbleClickHandler(key) : () => {}
              }
            >
              <FontAwesomeIcon icon={platformIcons[key]} />
            </div>
          ))}
        </Flex>
      )}
    </CampaignCardWrapper>
  );
};

export default CampaignCard;
