import styled from 'styled-components';

import theme from '../CommonStyles/colors';
import { ResponsiveImg } from '../CommonStyles';
import { themesData } from '../../utils/constants';
import { Flex } from '@rebass/grid';

const { purpleishBlue } = themesData;

export const HeaderDiv = styled.div`
  min-height: 70px;
  background-color: #fff;
  width: 100%;
  border-bottom: 1px solid ${theme.color.border.primary};
  margin: 0 auto;
  position: fixed;
  top: 0;
  z-index: 11;
`;

export const HeaderUl = styled.ul`
  display: flex;
  list-style: none;
  margin: 0px;
  height: 100%;
`;

export const HeaderLI = styled.li`
  height: 100%;
  margin: ${({ type }) => (type === 'sidebar' ? '1rem 1.5rem' : ' 0 1.5em')};
  a {
    height: 100%;
    display: flex;
    align-items: flex-end;
    padding-bottom: 5px;
    text-decoration: none;
    border-bottom: 2px solid transparent;
    ${({ selected }) =>
      selected ? `border-bottom: 2px solid ${purpleishBlue};` : ''}
  }
  i {
    display: block;
    width: 50px;
    font-size: 16px;
    img {
      width: 18px;
      height: 18px;
    }
  }
`;

export const HeaderLogo = styled(ResponsiveImg)`
  width: 111px;
`;

export const HeaderLogoNew = styled(ResponsiveImg)`
  margin: 5px 0 0;
  width: auto;
  cursor: pointer;
  width: 140px;
`;

export const IconTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
`;

export const ImpactTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
`;

export const ImpactTooltip = styled.span.attrs({
  className: 'Impact-tooltip',
})`
  width: 140px;
  background-color: #49484a;
  color: white;
  text-align: center;
  border-radius: 3px;
  padding: 2px 4px;
  z-index: 10;
  position: absolute;
  top: 49px;
  font-size: 9px;
  box-shadow: -1px 1px 11px -1px #6351ed;
`;

export const SwitchContainer = styled(Flex)`
  border: 1px solid #cfd9e4;
  border-radius: 4px;
  justify-content: ${({ justifyContent }) => justifyContent || 'center'};
  align-items: ${({ alignItems }) => alignItems || 'center'};
  width: 80%;
  ${'' /* padding: ${({ padding }) => padding || '9px'}; */}
  height: ${({ height }) => height || '470px'};
  ${'' /* margin-top: ${({ marginTop }) => marginTop || '35px'}; */}
  background-color: #f6f9fb;
  display: flex;
  flex-direction: ${({ flexDirection }) => flexDirection || 'column'};
  overflow-x: hidden;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background: #e9f6e6 !important;
  }

  &::-webkit-scrollbar-thumb {
    background: #bccdd4 !important;
  }
  ${'' /* margin-bottom: ${({ mb }) => mb || '25px'}; */}
`;

export const SwitchContainerCards = styled(Flex)`
  border: ${({ border }) => border || ''};  
  border-radius: 4px;
  align-items: center;
  justify-content: space-between;
  width: 235px;
  padding: 20px;
  height: 280px;
  margin: 25px;
  background-color: #fff;
  box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px,
    rgba(17, 17, 26, 0.1) 0px 0px 8px;
  display: flex;
  flex-direction: column;
`;

export const CardText = styled(Flex)`
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
`;

export const TitleSpan = styled(Flex)`
  color: #4e6780;
  font-size: 20px;
  margin-bottom: 15px;
`;

export const CardTitle = styled(Flex)`
  color: #000;
  font-size: 16px;
  font-weight: 800;
  margin-top: 5px;
`;

export const CardSubtitle = styled(Flex)`
  color: #708892;
  font-size: 11px;
  font-weight: 400;
  margin-top: 5px;
`;

export const DripFooterSection = styled.div.attrs({
  className: 'drip-footer-section',
})`
  display: flex;
  background-color: #fff;
  height: 10%;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;


export const NavBarItems = styled('div')`
  width: 100%;
  margin: 10px auto 0px;
  display: flex;
  justify-content: space-between;
`;

export const Profile = styled('div')`
  border-radius: 20px;
  background: #E0F1F4;
  color: #6250ED;
  margin: 12px 0 0px;
  height: 40px;
  width: 40px;
  padding: 8px 0 0;
  text-align: center;
  margin: 4px 0 0;
  font-weight: 500;
  cursor: pointer;
  transition: 0.4s;
  :hover {
    opacity: 0.7
  }
`;

export const MenuContainer = styled('div')`
  width: 80%;
  margin: -4px auto 0;
  height: 50px;
  display: flex;
  padding: 0;
  justify-content: space-evenly;
`;

export const MenuItem = styled('div')`
  display: flex;
  margin: -4px auto;
  padding: 0px 12px;
  cursor: pointer;
  transition: 0.4s;
  border-radius: 4px;
  background: ${props => props.selected ? '#F2F4F7' : 'white'};
  :hover {
    background: #F2F4F7;
  }
`;

export const MenuStyle = styled('p')`
  color: #101828;
  font-size: 14px;
  font-weight: 500;
  white-space: nowrap;
  padding-right: 5px;
`;

export const MenuIcon = styled('img')`
  width: 19px;
  height: 19px;
  margin: 14px 4px 0 0;
`;

export const DropdownContainer = styled('div')`
  box-shadow: 0px 4px 8px rgba(16, 24, 40, 0.1);
  position: absolute;
  top: 50px;
  transition: 0.5s;
  z-index: 99999;
  left: ${({ left }) => left || ''};
  width: ${({ width }) => width || ''};
`;

export const DropdownItem = styled('div')`
  padding: 6px 16px 6px 12px;
  text-align: center;
  font-size: 12px;
  cursor: pointer;
  background: #F2F4F7;
  transition: 0.5s;
  :hover {
    background: #6250ED;
    color: #fff;
  }
`;

export const CloutScoreCircle = styled('div')`
  margin: 12px 6px 0;
  font-size: 12px; 
  border: ${({ selected }) => selected ? '1px solid #816EF8' : '1px solid #101828'}; 
  border-radius: 20px;
  height: 24px;
  width: 24px;
  padding: 3px;
`;

export const HeaderContainer = styled('div')`
  display: flex;
  width: 100%;
  margin: 10px auto;
  padding: 0 2rem;
`;


export const ItemContainer = styled('div')`
  width: fit-content;
`;
