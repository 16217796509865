/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { isEmpty, get, map } from 'lodash';
import { Flex, Box } from '@rebass/grid';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import {
  Tabs,
  TabList,
  TabTypeThree,
  LoaderWrapper,
  Loader,
} from '../../components';
import Topics from './Topics';
import Membership from './Membership';
import Trending from './Trending';
import ExploreTab from './ExploreTab';
import TrendingTab from './TrendingTab';
import ContentLibraryTab from './ContentLibraryTab';
import AddContent from './AddContent';
import SearchResultsTab from './SearchResultsTab';
import InvestmentsTab from './InvestmentsTab';
import RecentSharedTab from './RecentSharedTab';
import ScheduledTab from './ScheduledTab';
import BookmarksTab from './BookmarksTab/BookmarksTab';


import {
  getPopularTheme,
  geYourTheme,
} from '../../services/dashboard';
import { getUsersList } from '../../services/analytics';

import {
  emptyMessages,
  USER_ROLE,
  ROLE_TYPES,
  company,
  getSpecificThemeItem,
} from '../../utils';
import { actions as inviteActions } from '../../redux/modules/invite.module';
import { actions as exploreActions } from '../../redux/modules/explore-tab.module';
import { moreActions as exploreMoreActions } from '../../redux/modules/explore-tab.module';
import { actions as advisorGroupsActions } from '../../redux/modules/advisor-group.module';
import {
  actions as contentlibActions,
  moreActions as contentlibMoreActions,
} from '../../redux/modules/content-lib.module';
import { actions as analyticsActions } from '../../redux/modules/analytics.module';
import { actions as loginActions } from '../../redux/modules/login.module';
import { actions as themeActions } from '../../redux/modules/theme.module';
import { getChannelId, getFirmSettings, getUserRole } from '../../redux/selector';
import { actions as shareActions } from '../../redux/modules/share.module';
import { moreActions as newsletterMoreActions } from '../../redux/modules/newsletter.module';

import {
  StyledTabPanel,
  StyledTabPanelTwo,
  NoContentWrapper,
  DashboardAdminSubTab,
  AbsoluteLoader,
  ExploreRelativeDiv,
  StyledTooltip,
  HoldingsIcon,
  HoldingsWrapper,
  BorderWhiteBox,
} from './DashboardContainer.styles';
import MioEditorTab from './MIOEditor/MioEditorTab';
import MyClientsTab from './MyClientsTab/MyClientsTab';
import useQuery from '../../customHooks/useQuery';
import { getContactsGroups } from '../../services/configure';
import RecommendedTab from './RecommendedTab';
import _ from 'lodash';
import ExportPDF from '../ExportPDFContainer/ExportPDF';
import { ExportPDFButton } from '../ExportPDFContainer/ExportPDF.styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const AdminDashboardContainer = ({
  exploreArticleRequest,
  advisorGroupsRequest,
  groupsList,
  exploreArticles,
  deleteArticleRequest,
  inviteRequest,
  contentlibRequest,
  contentlibDeleteRequest,
  contentLibArticles,
  analyticsRequest,
  analyticsData,
  isAnalyticsDataFetching,
  exploreArticlesFetching,
  contentLibArticlesFetching,
  analyticsObject,
  selectedRepType,
  searchResults,
  themeNames,
  exploreArticleDeleting,
  contentLibArticleDeleting,
  inviteSending,
  inviteError,
  channelId,
  themesList,
  investmentResults,
  isShareBlocked,
  isDeleteBlocked,
  isAdmin,
  tickerName,
  isTicker,
  exploreSetState,
  isInvEmpty,
  selectedTickers,
  contentlibSetState,
  userData,
  imageUpload,
  roleType,
  themeSetState,
  yourTopics,
  isComplianceAdmin,
  isEnterpriseAdmin,
  myMioData,
  currentPortfolio,
  holdingsType,
  loginSetState,
  isChangeTheme,
  searchFilters,
  handleModalOpen,
  isAssetAdmin,
  exploreArticleError,
  shareArticleSetState,
  addToBucketRequest,
  removeFromNewsletterRequest,
  addToBookmarksRequest,
  removeFromBookmarksRequest,
  contentLibAddToBucketRequest,
  contentLibRemoveFromBucketRequest,
  newsletterArticleFetching,
  bookmarksFetching,
  contentLibNewsletterFetching,
  exploreNewsletterId,
  bookmarksId,
  contentLibAddToBookmarksRequest,
  contentLibRemoveFromBookmarksRequest,
  contentLibBookmarksFetching,
  contentLibBookmarksId,
  bucketCountRequest,
  contentLibBucketId,
  contentLibInNewsletter,
  contentLibBookMarkId,
  contentLibIsBookmark,
  contentLibATSQueue,
  topicDetails,
  isEnterpriseLevelAdmin,
  isFirmLevelAdmin,
  firmSettings,
  contentLibFilter,
}) => {
  const [showInvestmentTab, setShowInvestmentTab] = useState(false);
  const [localContent, setLocalContent] = useState([]);
  const [mioContent, setMioContent] = useState([]);
  const [showSearchTab, setShowSearchTab] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [filteredSearchEmpty, setFilteredSearchEmpty] = useState(false);
  const [selectedTabIndex, setTabIndex] = useState(0);
  const [trendingTopicDetails, setTrendingTopicDetails] = useState({});
  const [popularTopicDetails, setPopularTopicDetails] = useState({});
  const [investmentTopic, setInvestmentTopic] = useState('');
  const [selectedExploreTabIndex, setSelectedExploreTabIndex] = useState(3);
  const [selectedRecentTabIndex, setSelectedRecentTabIndex] = useState(0);
  const [selectedScheduledTabIndex, setSelectedScheduledTabIndex] = useState(0);
  const [selectedContentTabIndex, setSelectedContentTabIndex] = useState(0);
  const [isSearchFilters, setIsSearchFilters] = useState(true);
  const [mostSubscribed, setMostSubscribed] = useState([]);
  const [showArticleLoader, setShowArticleLoader] = useState(true);
  const [widgetTabIndes, setWdigetTabIndex] = useState(1);
  const [discoverTabIndex, setDiscoverTabIndex] = useState(0);
  const [isFilteringStocksByTicker, setIsFilteringStocksByTicker] =
    useState(false);
  const [curatedTopicType, setCuratedTopicType] = useState('all');
  const [thirdPartyThemeId, setThirdPartyThemeId] = useState('');
  const [clearEditorArticles, setClearEditorArticles] = useState(false);
  const [clientShareBlocked, setClientShareBlocked] = useState(false);
  const [showClientDropDown, setShowClientDropDown] = useState(true);
  const [selectedMyClientsDropdownOption, setSelectedMyClientsDropdownOption] =
    useState('');
  const [selectedCRMContactList, setSelectedCRMContactList] = useState({
    id: '',
    name: '',
    themes: [],
    recommendedThemes: [],
    userThemes: [],
    disabledThemes: [],
  });
  const [crmLoader, setCrmLoader] = useState(false);
  const [myClientsLocalArticles, setMyClientsLocalArticles] = useState([]);
  const [clientDetails, setClientDetails] = useState({
    id: 'SECURITY',
    contactName: 'positivly_security',
  });
  const [searchOptions, setSearchOptions] = useState([
    { platform: 'all_content', checked: true, isDisconnected: false },
    { platform: 'my_content', checked: true, isDisconnected: false },
    { platform: 'editor_content', checked: true, isDisconnected: false },
    { platform: 'approved_to_share', checked: true, isDisconnected: false },
  ]);
  const [popularThemes, setPopularThemes] = useState([]);
  const [channelData, setChannelData] = useState([]);
  const [mostSubscribedThemes, setMostSubscribedThemes] = useState([]);
  const [contactList, setContactList] = useState([]);
  const query = useQuery();
  const themeFromInvestment = investmentTopic
    ? getSpecificThemeItem(themesList, investmentTopic)
    : [];
  const [exploreTabDetails, setExploreTabDetails] = useState({});
  const [isExportPdfOpen, setIsExportPdfOpen] = useState({
    isSharedArticle: false,
    isRecentArticle: false,
  });

  const exportPdfHandler = (name, isOpen) => {
    setIsExportPdfOpen({ ...isExportPdfOpen, [name]: isOpen });
  };

  useEffect(() => {
    if (
      selectedTabIndex === 0 &&
      selectedExploreTabIndex === 3 &&
      !_.isEmpty(exploreArticles) &&
      !_.isEmpty(exploreArticles.details)
    ) {
      let exploreData = {};
      exploreArticles?.details?.theme &&
        Object.keys(exploreArticles?.details?.theme).forEach((themeId) => {
          exploreArticles.feed.forEach((feed) => {
            const topicName = exploreArticles?.details.theme[themeId].name;
            if (feed.ids?.theme[0] === themeId) {
              if (exploreData[topicName]) {
                exploreData[topicName].feed.push(feed);
              } else {
                exploreData[topicName] = {
                  details: exploreArticles.details,
                  feed: [feed],
                };
              }
            }
          });
        });

      setExploreTabDetails(exploreData);
      setShowArticleLoader(false);
    }
  }, [exploreArticles]);

  const onDiscoverTabChange = (index) => {
    if (index === 0) {
      setTabIndex(0);
      setSelectedExploreTabIndex(1);
    } else if (index === 1) {
    } else if (index === 2 && discoverTabIndex !== index) {
      setTabIndex(0);
      setPopularTopicDetails({});
      setSelectedExploreTabIndex(1);
      exploreArticleRequest({
        params: {
          id: popularTopicDetails.id,
          source: 'explore',
        },
      });
    } else if (discoverTabIndex === index && !isEmpty(mostSubscribedThemes)) {
      setTabIndex(0);
      setSelectedExploreTabIndex(1);
      const initialIndex = Math.floor(mostSubscribedThemes.length / 2);
      const { id, description } = get(
        mostSubscribedThemes,
        `[${initialIndex}]`,
        {}
      );
      setPopularTopicDetails({
        id,
        topicName: description,
      });
      exploreArticleRequest({
        params: {
          id: id,
          source: 'explore',
        },
      });

      setSelectedExploreTabIndex(1);
    }
    setDiscoverTabIndex(index);
    // setWdigetTabIndex(index);
  };

  const checkedStatus = (platform) => {
    const platformDetails = searchOptions.find((c) => c.platform === platform);
    return {
      isDisconnected: platformDetails.isDisconnected,
      checked: platformDetails.checked,
    };
  };

  const onCheckBoxClick = (platform) => {
     let searchOptionsCopy = [...searchOptions];
    // if (platform === 'all') {
    //   if (!searchOptionsCopy[0].checked)
    //     searchOptionsCopy.forEach((option) => (option.checked = true));
    //   else searchOptionsCopy.forEach((option) => (option.checked = false));
    // } else {
      searchOptionsCopy.every((option) => {
        if (option.platform === platform) {
          if (option.checked) searchOptionsCopy.checked = false;
          option.checked = !option.checked;
        }
        return option.platform !== platform;
      });
    setSearchOptions(searchOptionsCopy);
    checkedStatus(platform);
  };

  const myMioFeed = get(myMioData, 'feed', []);
  const onMyMioTabClick = () => {
    // console.log('onMyMioTabClick');
    setShowArticleLoader(true);
    exploreArticleRequest({
      params: {
        id: popularTopicDetails.id,
        source: 'mymio',
      },
    });
  };

  // console.log(showArticleLoader);

  useEffect(() => {
    if (!isEmpty(exploreArticleError)) {
      setShowArticleLoader(false);
    }
  }, [exploreArticleError]);

  useEffect(() => {
    const excludeEmailList = [
      'GMAIL',
      'HUBSPOT',
      'REDTAIL',
      'OUTLOOK',
      'WEALTHBOX',
      'SALESFORCE',
    ];
    if (query.get('tab') && query.get('tab') === 'holdings') {
      onWidgetTabChange(2);
    }
    if (
      sessionStorage.getItem('tab') &&
      sessionStorage.getItem('tab') === 'my-editor'
    ) {
      onWidgetTabChange(3);
    }
    exploreSetState({ holdingsType: 'themes' });
    geYourTheme().then((response) => {
      const yourThemeResponse = get(response, 'result.data', []).filter(
        (theme) =>
          get(theme, 'subscription.status.application_status') === 'approved'
      );
      setPopularThemes(yourThemeResponse);
      themeSetState({ popularThemes: yourThemeResponse });
    });
    getPopularTheme().then((response) => {
      setMostSubscribedThemes(get(response, 'result.data', []));
      let themeResponse = get(response, 'result.data', []);
      themeResponse = themeResponse.map(({ name }) => name);
      setMostSubscribed(themeResponse);
    });
    getContactsGroups()
      .then((res) => {
        // console.log('[CONTACT LIST] - ', res.result.data);
        const contact_list = res.result.data.filter(
          (contact) => !excludeEmailList.includes(contact.name)
        );
        setContactList(contact_list);
        let count = res.result.data.filter((contact) =>
          contact.name.includes('positivly_')
        );
        if (count.length === 0) {
          setClientShareBlocked(true);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    if (popularThemes?.length === 0) {
      onExploreTabClick(1);
    }
  }, [popularThemes]);

  useEffect(() => {
    if ([...popularThemes, ...themeFromInvestment].length > 0) {
      const themes = map([...popularThemes, ...themeFromInvestment], 'name');
      themeSetState({ yourTopics: themes.map((theme) => theme.toUpperCase()) });
    }
  }, [popularThemes, investmentTopic]);

  useEffect(() => {
    if (
      !isEmpty(popularTopicDetails) &&
      !imageUpload &&
      isComplianceAdmin &&
      discoverTabIndex !== 2
    ) {
      setShowArticleLoader(true);
      onExploreTabClick();
    }
    if (!popularTopicDetails.id) {
      setExploreTabDetails({});
    }
  }, [popularTopicDetails]);

  useEffect(() => {
    if (selectedTabIndex === 0 && selectedExploreTabIndex === 1) {
      if (!isEmpty(trendingTopicDetails)) {
        setShowArticleLoader(true);
        onTrendingTabClick();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trendingTopicDetails]);  

  useEffect(() => {
    if (isAdmin) {
      getUsersList('all').then((data) => {
        const membersList = get(data, 'result.data.users', []);
        loginSetState({ membersList });
      });
    }
  }, [isAdmin]);

  const onSearchClick = () => {
    setShowArticleLoader(true);
    setTabIndex(0);
    setSelectedExploreTabIndex(7);
    exploreArticleRequest({
      params: {
        id: searchValue,
        source: 'search',
        date: searchOptions,
      },
    });
    setShowSearchTab(true);
  };

  const onExploreTabClick = (topic) => {
    setShowArticleLoader(true);
    const initialIndex = Math.floor(popularThemes.length / 2);
    const { id, description } = get(popularThemes, `[${initialIndex}]`, {});

    exploreArticleRequest({
      params: {
        id: topic && topic.length > 0 ? topic : popularTopicDetails.id || id,
        source: 'explore',
      },
    });
  };

  const onTrendingTabClick = () => {
    setShowArticleLoader(true);
    exploreArticleRequest({
      params: {
        id: trendingTopicDetails.id,
        date: trendingTopicDetails.date,
        source: 'trending',
      },
    });
  };

  const onDeleteArticleClick = (id) => {
    let params = {};
    setShowArticleLoader(true);
    if (selectedTabIndex === 0 && selectedExploreTabIndex === 3) {
      params = {
        deleteId: id,
        id: popularTopicDetails.id,
        source: 'explore',
      };
    } else if (selectedTabIndex === 0 && selectedExploreTabIndex === 0) {
      params = {
        source: 'recommended',
      };
    } else if (
      selectedTabIndex === 0 &&
      selectedExploreTabIndex === 1 &&
      discoverTabIndex === 0
    ) {
      params = {
        deleteId: id,
        id: trendingTopicDetails.id,
        source: 'trending',
        date: trendingTopicDetails.date,
      };
    } else if (
      selectedTabIndex === 0 &&
      selectedExploreTabIndex === 1 &&
      discoverTabIndex === 2
    ) {
      params = {
        deleteId: id,
        id: popularTopicDetails.id,
        source: 'explore',
      };
    } else if (selectedTabIndex === 2 && selectedScheduledTabIndex === 2) {
      params = {
        deleteId: id,
        id: trendingTopicDetails.id,
        source: 'scheduled',
        date: trendingTopicDetails.date,
        type: 'recommendation_engine',
      };
    } else if (selectedTabIndex === 2 && selectedScheduledTabIndex === 1) {
      params = {
        deleteId: id,
        id: trendingTopicDetails.id,
        source: 'scheduled',
        date: trendingTopicDetails.date,
        type: 'admin',
      };
    } else if (selectedTabIndex === 2 && selectedScheduledTabIndex === 0) {
      params = {
        deleteId: id,
        id: trendingTopicDetails.id,
        source: 'scheduled',
        date: trendingTopicDetails.date,
        type: 'user',
      };
    } else if (selectedTabIndex === 0 && selectedExploreTabIndex === 7) {
      params = {
        deleteId: id,
        id: searchValue,
        source: 'search',
        date: searchOptions,
      };
    } else if (selectedTabIndex === 0 && selectedExploreTabIndex === 4) {
      params = {
        deleteId: id,
        id: currentPortfolio,
        source: 'investments',
        date: trendingTopicDetails.date,
      };
    } else if (selectedTabIndex === 0 && selectedExploreTabIndex === 6) {
      params = {
        deleteId: id,
        id: clientDetails.id,
        source: 'myClients',
        contactName: clientDetails.contactName,
      };
    }
    deleteArticleRequest({ params });
  };

  const onNewsletterArticleClick = (id, themeId, source) => {
    let params = {};
    console.log(id);
    if (selectedTabIndex === 0 && selectedExploreTabIndex === 3) {
      params = {
        articleId: id,
        themeId: themeId,
        newsletterId: id,
        id: popularTopicDetails.id,
        source: 'explore',
      };
    } else if (selectedTabIndex === 0 && selectedExploreTabIndex === 0) {
      params = {
        source: 'recommended',
        articleId: id,
        themeId: themeId,
        newsletterId: id,
      };
    } else if (
      selectedTabIndex === 0 &&
      selectedExploreTabIndex === 1 &&
      discoverTabIndex === 0
    ) {
      params = {
        articleId: id,
        themeId: themeId,
        id: trendingTopicDetails.id,
        newsletterId: id,
        source: 'trending',
        date: trendingTopicDetails.date,
      };
    } else if (
      selectedTabIndex === 0 &&
      selectedExploreTabIndex === 1 &&
      discoverTabIndex === 2
    ) {
      params = {
        articleId: id,
        themeId: themeId,
        newsletterId: id,
        id: popularTopicDetails.id,
        source: 'explore',
      };
    } else if (selectedTabIndex === 0 && selectedExploreTabIndex === 7) {
      params = {
        articleId: id,
        themeId: themeId,
        id: searchValue,
        newsletterId: id,
        source: 'search',
        date: searchOptions,
      };
    } else if (selectedTabIndex === 0 && selectedExploreTabIndex === 4) {
      params = {
        articleId: id,
        themeId: themeId,
        id: currentPortfolio,
        newsletterId: id,
        source: 'investments',
        date: trendingTopicDetails.date,
        holdingsType: holdingsType,
      };
    } else if (selectedTabIndex === 0 && selectedExploreTabIndex === 6) {
      if (selectedMyClientsDropdownOption === 'financial') {
        params = {
          articleId: id,
          themeId: themeId,
          id: clientDetails.id,
          newsletterId: id,
          source: 'myClients',
          contactName: clientDetails.contactName,
        };
      } else {
        params = {
          articleId: id,
          themeId: themeId,
          id: clientDetails.id,
          newsletterId: id,
          source: 'crmClients',
          themesList: getCRMThemeIds(),
        };
      }
    } else if (selectedTabIndex === 0 && selectedExploreTabIndex === 8) {
      params = {
        source: 'bookmarks',
        themeId: themeId,
        articleId: id,
        newsletterId: id,
      };
    }

    if (source === 'newsLetter') {
      console.log('SOURCE NEWSLETTER');
      addToBucketRequest({ params });
    } else {
      console.log('SOURCE BOOKMARKS');
      addToBookmarksRequest({ params });
    }
  };

  const onRemoveFromNewletterClick = (id, articleId, source) => {
    let params = {};
    if (selectedTabIndex === 0 && selectedExploreTabIndex === 3) {
      params = {
        articleId: id,
        id: popularTopicDetails.id,
        newsletterId: articleId,
        source: 'explore',
      };
    } else if (selectedTabIndex === 0 && selectedExploreTabIndex === 0) {
      params = {
        source: 'recommended',
        articleId: id,
        newsletterId: articleId,
      };
    } else if (
      selectedTabIndex === 0 &&
      selectedExploreTabIndex === 1 &&
      discoverTabIndex === 0
    ) {
      params = {
        articleId: id,
        id: trendingTopicDetails.id,
        source: 'trending',
        newsletterId: articleId,
        date: trendingTopicDetails.date,
      };
    } else if (
      selectedTabIndex === 0 &&
      selectedExploreTabIndex === 1 &&
      discoverTabIndex === 2
    ) {
      params = {
        articleId: id,
        id: popularTopicDetails.id,
        newsletterId: articleId,
        source: 'explore',
      };
    } else if (selectedTabIndex === 0 && selectedExploreTabIndex === 7) {
      params = {
        articleId: id,
        id: searchValue,
        source: 'search',
        newsletterId: articleId,
        date: searchOptions,
      };
    } else if (selectedTabIndex === 0 && selectedExploreTabIndex === 4) {
      params = {
        articleId: id,
        id: currentPortfolio,
        source: 'investments',
        newsletterId: articleId,
        date: trendingTopicDetails.date,
        holdingsType: holdingsType,
      };
    } else if (selectedTabIndex === 0 && selectedExploreTabIndex === 6) {
      if (selectedMyClientsDropdownOption === 'financial') {
        params = {
          articleId: id,
          id: clientDetails.id,
          source: 'myClients',
          newsletterId: articleId,
          contactName: clientDetails.contactName,
        };
      } else {
        params = {
          articleId: id,
          id: clientDetails.id,
          source: 'crmClients',
          newsletterId: articleId,
          themesList: getCRMThemeIds(),
        };
      }
    } else if (selectedTabIndex === 0 && selectedExploreTabIndex === 8) {
      params = {
        source: 'bookmarks',
        articleId: id,
        newsletterId: articleId,
      };
    }
    if (source === 'newsLetter') removeFromNewsletterRequest({ params });
    else removeFromBookmarksRequest({ params });
  };

  const onRecentTabClick = () => {
    setShowArticleLoader(true);
    exploreArticleRequest({
      params: {
        id: popularTopicDetails.id,
        source: 'recent',
      },
    });
  };

  const onRecentLastmonthTabClick = () => {
    setShowArticleLoader(true);
    exploreArticleRequest({
      params: {
        id: popularTopicDetails.id,
        source: 'recent',
        type: 'last_month',
      },
    });
  };

  const onScheduledTabClick = () => {
    console.log('onScheduledTabClick');
    setShowArticleLoader(true);
    setSelectedScheduledTabIndex(2);
    exploreArticleRequest({
      params: {
        id: popularTopicDetails.id,
        source: 'scheduled',
        type: 'recommendation_engine',
      },
    });
  };

  const onScheduledByCompanyTabClick = () => {
    console.log('onScheduledByCompanyTabClick');
    setSelectedScheduledTabIndex(1);
    setShowArticleLoader(true);
    exploreArticleRequest({
      params: {
        id: popularTopicDetails.id,
        source: 'scheduled',
        type: 'admin',
      },
    });
  };

  const onScheduledBySelfTabClick = () => {
    console.log('onScheduledBySelfTabClick');
    setSelectedScheduledTabIndex(0);
    setShowArticleLoader(true);
    exploreArticleRequest({
      params: {
        id: popularTopicDetails.id,
        source: 'scheduled',
        type: 'user',
      },
    });
  };

  const onTabSelect = (index) => {
    setShowArticleLoader(true);
    contentlibSetState({ data: {} });
    setTabIndex(index);
    if (index === 3) {
      setCuratedTopicType('all');
      contentlibSetState({ data: {} });
    }
  };

  const onExploreTabSelect = (index, topic) => {
    setShowArticleLoader(true);

    if (index === 2) {
      // contentlibRequest({ channelId, type: curatedTopicType });
      setWdigetTabIndex(0);
    } 
    else if (index === 3) {
      onWidgetTabChange(1);
      onExploreTabClick(topic);
    } 
    else if (index === 0) {
      exploreArticleRequest({
        params: {
          source: 'recommended',
        },
      });
    } else if (index === 4) {
      onInvestmentClick();
    } else if (index === 5) {
      // console.log('[popularTopicDetailsId] - ', popularTopicDetails.id);
      setWdigetTabIndex(3);
      if (
        userData.details.user.personalization.corporate_identity &&
        userData.details.user.personalization.corporate_identity.enable
      ) {
        setThirdPartyThemeId('');
        exploreSetState({data: {}});
        exploreArticleRequest({
          params: {
            id: popularTopicDetails.id,
            source: 'mioEditorResult',
            date: '',
            mode: '',
            token: '',
            holdingsType: '',
            thirdPartyThemeId: '',
          },
        });
        sessionStorage.removeItem('tab');
      }
    } else if (index === 1) {
      onDiscoverTabChange(0);
      exploreArticleRequest({
        params: {
          id: trendingTopicDetails.id,
          date: trendingTopicDetails.date,
          source: 'trending',
        },
      });
    } else if (index === 6) {
      if (
        selectedExploreTabIndex !== 0 &&
        selectedExploreTabIndex !== 1 &&
        selectedExploreTabIndex !== 8
      ) {
        setShowArticleLoader(false);
        setSelectedMyClientsDropdownOption('');
        console.log(
          'SELECTED EXPLORE TAB INDEX -=-== ',
          selectedExploreTabIndex
        );
        setShowClientDropDown(true);
      } else {
        // console.log('SELECTED dropdown -=-== ', selectedMyClientsDropdownOption);
        if (selectedMyClientsDropdownOption === 'financial') {
          // setShowArticleLoader(false);
          setCrmLoader(false);
          exploreArticleRequest({
            params: {
              source: 'myClients',
              id: clientDetails.id,
            },
          });
        } else if (selectedMyClientsDropdownOption === 'crm') {
          // setShowArticleLoader(false);
          setCrmLoader(false);
          exploreArticleRequest({
            params: {
              source: 'crmClients',
              themesList:
                selectedCRMContactList.themes.length > 0
                  ? selectedCRMContactList.themes.map((theme) => theme.id_hash)
                  : [],
            },
          });
        } else {
          setShowArticleLoader(false);
        }
      }
      setWdigetTabIndex(4);
    } else if (index === 7) {
      setSearchOptions([
        { platform: 'all_content', checked: true, isDisconnected: false },
        { platform: 'my_content', checked: true, isDisconnected: false },
        { platform: 'editor_content', checked: true, isDisconnected: false },
        { platform: 'approved_to_share', checked: true, isDisconnected: false },
      ]);
      if (isComplianceAdmin) {
        setWdigetTabIndex(3);
      } else {
        setWdigetTabIndex(5);
      }
    } else if (index === 8) {
      console.log('index 7');
      exploreArticleRequest({
        params: {
          source: 'bookmarks',
        },
      });
    }
    setSelectedExploreTabIndex(index);
  };

  const onRecentTabSelect = (index) => {
    console.log('onRecentTabSelect');
    setShowArticleLoader(true);
    setSelectedRecentTabIndex(index);
  };

  const onScheduledTabSelect = (index) => {
    console.log('onScheduledTabSelect', index);
    setShowArticleLoader(true);
    setSelectedScheduledTabIndex(index);
  };

  const onContentTabSelect = (index) => {
    setShowArticleLoader(true);

    if (selectedExploreTabIndex === 1) {
      contentlibSetState({ data: {} });
    } else if (selectedExploreTabIndex === 2) {
      exploreArticleRequest({
        params: {
          id: popularTopicDetails.id,
          source: 'explore',
        },
      });
    } else if (selectedExploreTabIndex === 2) {
      onInvestmentClick();
      if (isComplianceAdmin) {
        onWidgetTabChange(2);
      } else {
        onWidgetTabChange(2);
      }
    } else if (selectedExploreTabIndex === 3) {
      exploreArticleRequest({
        params: {
          id: trendingTopicDetails.id,
          date: trendingTopicDetails.date,
          source: 'trending',
        },
      });
    } else if (selectedExploreTabIndex === 5) {
      setWdigetTabIndex(4);
    } else if (selectedExploreTabIndex === 4) {
      if (
        userData.details.user.personalization.corporate_identity &&
        userData.details.user.personalization.corporate_identity.enable
      ) {
        exploreSetState({data: {}});
        exploreArticleRequest({
          params: {
            id: popularTopicDetails.id,
            source: 'mioEditorResult',
            date: '',
            mode: '',
            token: '',
            holdingsType: '',
            thirdPartyThemeId: thirdPartyThemeId,
          },
        });
      }
    } else if (selectedExploreTabIndex === 6) {
      onMyMioTabClick();
    }
  };

  const onInvestmentClick = (id, holdingsType = '') => {
    setTabIndex(0);
    onWidgetTabChange(2);
    setSelectedExploreTabIndex(4);
    setIsFilteringStocksByTicker(false);
    if (id && id.length > 0) {
      exploreArticleRequest({
        params: {
          id,
          source: 'investments',
          holdingsType,
        },
      });
    }
    setShowInvestmentTab(true);
  };

  const handleTickerChange = (ticker) => {
    console.log('handleTickerChange');
    setIsFilteringStocksByTicker(holdingsType === 'stocks' ? true : false);
    exploreSetState({ ticker }); //filtering from list based on selected tickerName
  };

  const handleTopicChange = (topic) => {
    console.log('handleTopicChange');
    setShowArticleLoader(true);
    setTabIndex(0);
    setInvestmentTopic(topic);
    onExploreTabClick(topic);
  };

  const onWidgetTabChange = (index) => {
    contentlibSetState({ data: {} });
    setTabIndex(0);
    setThirdPartyThemeId('');
    if (isComplianceAdmin) {
      if (index === 1 && discoverTabIndex !== 2) {
        setSelectedExploreTabIndex(3);
        onExploreTabClick();
      } else if (
        index === 1 &&
        discoverTabIndex === 2 &&
        !isEmpty(popularThemes)
      ) {
        setSelectedExploreTabIndex(3);
        const initialIndex = Math.floor(popularThemes.length / 2);
        const { id, description } = get(popularThemes, `[${initialIndex}]`, {});
        setPopularTopicDetails({
          id,
          topicName: description,
        });
        exploreArticleRequest({
          params: {
            id: id,
            source: 'explore',
          },
        });
      } else if (index === 3) {
        setSearchOptions([
          { platform: 'all_content', checked: true, isDisconnected: false },
          { platform: 'my_content', checked: true, isDisconnected: false },
          { platform: 'editor_content', checked: true, isDisconnected: false },
          { platform: 'approved_to_share', checked: true, isDisconnected: false },
        ]);
        setSearchValue('');
        exploreArticleRequest({
          params: {
            source: 'search',
          },
        });
        setSelectedExploreTabIndex(7);
      } else if (index === 2) {
        setInvestmentTopic('');
        setSelectedExploreTabIndex(4);
      }
    } else {
      if (index !== 4 && !showClientDropDown) {
        setShowClientDropDown(true);
      }
      if (index === 1 && discoverTabIndex !== 2) {
        setSelectedExploreTabIndex(3);
        onExploreTabClick();
      } else if (
        index === 1 &&
        discoverTabIndex === 2 &&
        !isEmpty(popularThemes)
      ) {
        setSelectedExploreTabIndex(3);
        const initialIndex = Math.floor(popularThemes.length / 2);
        const { id, description } = get(popularThemes, `[${initialIndex}]`, {});
        setPopularTopicDetails({
          id,
          topicName: description,
        });
        exploreArticleRequest({
          params: {
            id: id,
            source: 'explore',
          },
        });
      } else if (
        index === 1 &&
        discoverTabIndex === 2 &&
        isEmpty(popularThemes)
      ) {
        setShowArticleLoader(true);
        exploreArticleRequest({
          params: {
            id: topicDetails.id,
            source: 'explore',
          },
        });
        setSelectedExploreTabIndex(3);
      } else if (index === 0) {
        setCuratedTopicType('all');
        // contentlibRequest({ type: 'all' });
        setSelectedExploreTabIndex(2);
      } else if (index === 3) {
        onExploreTabSelect(5);
      } else if (index === 4) {
        if (widgetTabIndes !== 4) {
          setShowArticleLoader(false);
          setSelectedMyClientsDropdownOption('');
          setShowClientDropDown(true);
        } else {
          if (selectedMyClientsDropdownOption === 'financial') {
            // setShowArticleLoader(false);
            setCrmLoader(false);
            exploreArticleRequest({
              params: {
                source: 'myClients',
                id: clientDetails.id,
              },
            });
          } else if (selectedMyClientsDropdownOption === 'crm') {
            // setShowArticleLoader(false);
            setCrmLoader(false);
            exploreArticleRequest({
              params: {
                source: 'crmClients',
                themesList:
                  selectedCRMContactList.themes.length > 0
                    ? selectedCRMContactList.themes.map(
                        (theme) => theme.id_hash
                      )
                    : [],
              },
            });
          } else {
            setShowArticleLoader(false);
          }
        }
        setSelectedExploreTabIndex(6);
      } else if (index === 5) {
        setSearchOptions([
          { platform: 'all_content', checked: true, isDisconnected: false },
          { platform: 'my_content', checked: true, isDisconnected: false },
          { platform: 'editor_content', checked: true, isDisconnected: false },
          { platform: 'approved_to_share', checked: true, isDisconnected: false },
        ]);
        setSearchValue('');
        exploreArticleRequest({
          params: {
            source: 'search',
          },
        });
        setSelectedExploreTabIndex(7);
      } else {
        setInvestmentTopic('');
        setSelectedExploreTabIndex(4);
      }
    }
    setWdigetTabIndex(index);
  };

  const getCRMThemeIds = () => {
    let theme_ids;
    if (selectedCRMContactList.disabledThemes.length > 0)
      theme_ids = selectedCRMContactList.themes
        .filter(
          (o) => selectedCRMContactList.disabledThemes.indexOf(o.id_hash) === -1
        )
        .map((theme) => theme.id_hash);
    else
      theme_ids = selectedCRMContactList.themes.map((theme) => theme.id_hash);
    return theme_ids;
  };

  const exploreArticleFeed = get(exploreArticles, 'feed', []);
  const showTabLoader =
    !exploreArticleDeleting &&
    exploreArticlesFetching &&
    !newsletterArticleFetching &&
    !bookmarksFetching;

  const holdingsHtmlElement = document.querySelectorAll(
    '[title="Investment Holdings"]'
  );

  if (holdingsHtmlElement && holdingsHtmlElement.length > 1) {
    holdingsHtmlElement[1].setAttribute('title', '');
  }
  // console.log('[ADMIN DASHBOARD] rendering...', searchOptions);
  // console.log('[EXPLORE ARTICLES] - ', exploreArticles);
  return (
    <Box width={1}>
      <Flex
        flexDirection={`${window.innerWidth < 768 ? 'column' : 'row'}`}
        justifyContent="space-between"
        width={1}
        my="25px"
      >
        <Trending
          exploreArticleRequest={exploreArticleRequest}
          setTrendingTopicDetails={setTrendingTopicDetails}
          setTabIndex={setTabIndex}
          setSelectedExploreTabIndex={setSelectedExploreTabIndex}
          isAdmin
          isComplianceAdmin={isComplianceAdmin}
          isEnterpriseAdmin={isEnterpriseAdmin}
          discoverTabIndex={discoverTabIndex}
          onDiscoverTabChange={onDiscoverTabChange}
          onTrendingTabClick={onTrendingTabClick}
          setThirdPartyThemeId={setThirdPartyThemeId}
          popularTopicDetails={popularTopicDetails}
          setClearEditorArticles={setClearEditorArticles}
          exploreSetState={exploreSetState}
          isShareBlocked={isShareBlocked}
          roleType={roleType}
          setPopularTopicDetails={setPopularTopicDetails}
          onExploreTabClick={onExploreTabClick}
          shareArticleSetState={shareArticleSetState}
          bucketCountRequest={bucketCountRequest}
        />
        <Topics
          exploreSetState={exploreSetState}
          onExploreTabSelect={onExploreTabSelect}
          onExploreTabClick={onExploreTabClick}
          setShowArticleLoader={setShowArticleLoader}
          setPopularTopicDetails={setPopularTopicDetails}
          popularTopicDetails={popularTopicDetails}
          setExploreTabDetails={setExploreTabDetails}
          setTabIndex={setTabIndex}
          setTrendingTopicDetails={setTrendingTopicDetails}
          trendingTopicDetails={trendingTopicDetails}
          topicFromInvestment={investmentTopic}
          onInvestmentClick={onInvestmentClick}
          tickerName={tickerName}
          themesList={themesList}
          setInvestmentTopic={setInvestmentTopic}
          setSelectedExploreTabIndex={setSelectedExploreTabIndex}
          isAdmin
          themeSetState={themeSetState}
          yourTopics={yourTopics}
          onWidgetTabChange={onWidgetTabChange}
          widgetTabIndes={widgetTabIndes}
          setWdigetTabIndex={setWdigetTabIndex}
          currentPortfolio={currentPortfolio}
          holdingsType={holdingsType}
          isComplianceAdmin={isComplianceAdmin}
          curatedTopicType={curatedTopicType}
          setCuratedTopicType={setCuratedTopicType}
          contentlibSetState={contentlibSetState}
          contentlibRequest={contentlibRequest}
          onSearchClick={onSearchClick}
          setSearchValue={setSearchValue}
          channelData={channelData}
          onCheckBoxClick={onCheckBoxClick}
          checkedStatus={checkedStatus}
          isEnterpriseAdmin={isEnterpriseAdmin}
          exploreArticleRequest={exploreArticleRequest}
          exploreArticles={exploreArticles}
          setThirdPartyThemeId={setThirdPartyThemeId}
          setClearEditorArticles={setClearEditorArticles}
          thirdPartyThemeId={thirdPartyThemeId}
          searchValue={searchValue}
          isAssetAdmin={isAssetAdmin}
          userData={userData}
          showClientDropDown={showClientDropDown}
          setShowClientDropDown={setShowClientDropDown}
          contactList={contactList}
          setClientDetails={setClientDetails}
          selectedDropdownOption={selectedMyClientsDropdownOption}
          setSelectedDropdownOption={setSelectedMyClientsDropdownOption}
          setSelectedCRMContactList={setSelectedCRMContactList}
          crmLoader={crmLoader}
          setCrmLoader={setCrmLoader}
          popularTabIndex={discoverTabIndex}
          firmSettings={firmSettings}
        />
        <Membership
          advisorGroupsRequest={advisorGroupsRequest}
          groupsList={groupsList}
          inviteRequest={inviteRequest}
          analyticsRequest={analyticsRequest}
          analyticsData={analyticsData}
          isAnalyticsDataFetching={isAnalyticsDataFetching}
          analyticsObject={analyticsObject}
          selectedRepType={selectedRepType}
          inviteSending={inviteSending}
          inviteError={inviteError}
          channelId={channelId}
          allowInvite={!isShareBlocked}
          isEnterpriseLevelAdmin={isEnterpriseLevelAdmin}
          isFirmLevelAdmin={isFirmLevelAdmin}
        />
      </Flex>
      <Flex width={1}>
        <Tabs
          onSelect={onTabSelect}
          selectedIndex={selectedTabIndex}
          forceRenderTabPanel
        >
          <BorderWhiteBox type="admin-tab" padding="5px 5px">
            <TabList height="31px" data-testid="articles_widget_tabsList">
              <TabTypeThree
                type="dashboard"
                onClick={() => onExploreTabSelect(selectedExploreTabIndex)}
                currentTab={selectedTabIndex === 0}
                data-testid="explore_tab"
              >
                Explore
              </TabTypeThree>
              <TabTypeThree
                type="dashboard"
                onClick={onRecentTabClick}
                currentTab={selectedTabIndex === 1}
                data-testid="recent_tab"
              >
                Recent
              </TabTypeThree>
              <TabTypeThree
                type="dashboard"
                onClick={onScheduledBySelfTabClick}
                currentTab={selectedTabIndex === 2}
                data-testid="scheduled_tab"
              >
                Scheduled
              </TabTypeThree>
              {!isComplianceAdmin ? (
                <TabTypeThree
                  type="dashboard"
                  currentTab={selectedTabIndex === 3}
                  data-testid="add_content_tab"
                >
                  Add Content
                </TabTypeThree>
              ) : (
                ''
              )}
            </TabList>

            {selectedTabIndex === 0 && (
              <StyledTabPanelTwo>
                <Tabs onSelect={onExploreTabSelect} forceRenderTabPanel>
                  <TabList style={{ marginBottom: '10px' }}>
                    {!isComplianceAdmin ? (
                      <DashboardAdminSubTab
                        color="#49484a"
                        padding="5px 23px 3px"
                        className={`${
                          selectedExploreTabIndex === 0 ? 'active' : 'inactive'
                        }`}
                        data-testid="recommended_subtab"
                      >
                        Recommended
                      </DashboardAdminSubTab>
                    ) : (
                      <DashboardAdminSubTab
                        style={{ display: 'none' }}
                      ></DashboardAdminSubTab>
                    )}
                    <DashboardAdminSubTab
                      color="#49484a"
                      padding="5px 23px 3px"
                      className={`${
                        selectedExploreTabIndex === 1 ? 'active' : 'inactive'
                      }`}
                      data-testid="popular_subtab"
                    >
                      Popular
                    </DashboardAdminSubTab>
                    {!isComplianceAdmin ? (
                      <DashboardAdminSubTab
                        color="#49484a"
                        padding="5px 23px 3px"
                        className={`${
                          selectedExploreTabIndex === 2 ? 'active' : 'inactive'
                        }`}
                        data-testid="my_content_subtab"
                      >
                        My Content
                      </DashboardAdminSubTab>
                    ) : (
                      <DashboardAdminSubTab
                        style={{ display: 'none' }}
                      ></DashboardAdminSubTab>
                    )}
                    <DashboardAdminSubTab
                      color="#49484a"
                      padding="5px 23px 3px"
                      className={`${
                        selectedExploreTabIndex === 3 ? 'active' : 'inactive'
                      }`}
                      data-testid="my_topics_subtab"
                    >
                      My Topics
                    </DashboardAdminSubTab>
                   
                    <DashboardAdminSubTab
                      hide={
                        get(firmSettings, 'firm_general.my_holding', 'true') === 'false'
                      }
                      color="#49484a"
                      padding="5px 23px 3px"
                      className={`${
                        selectedExploreTabIndex === 4 ? 'active' : 'inactive'
                      }`}
                      title="Investment Holdings"
                      data-testid="my_holdings_subtab"
                    >
                      My Holdings{' '}
                      {holdingsType && (
                        <HoldingsWrapper className="tooltip">
                          <HoldingsIcon
                            style={{
                              ...(holdingsType === 'stocks' && {
                                width: '9px',
                              }),
                            }}
                            src={`/assets/images/${holdingsType}.png`}
                            data-testid={`holding_img_${holdingsType}`}
                          />
                          <StyledTooltip
                            right={holdingsType === 'stocks' ? '41%' : '43%'}
                            className="tooltiptext"
                            width={holdingsType === 'stocks' ? '80px' : null}
                          >
                            {holdingsType === 'stocks'
                              ? 'STOCK SPECIFIC'
                              : 'THEMATIC'}
                          </StyledTooltip>
                        </HoldingsWrapper>
                      )}
                    </DashboardAdminSubTab>
                    
                  
                    {/* onClick={onInvestmentClick}  */}

                    {(!isComplianceAdmin) ? (
                      <DashboardAdminSubTab
                        color="#49484a"
                        padding="5px 23px 3px"
                        className={`${
                          selectedExploreTabIndex === 5 ? 'active' : 'inactive'
                        }`}
                        hide={
                          get(firmSettings, 'firm_general.my_editor', 'true') === 'false'
                        }
                        data-testid="my_editor_subtab"
                      >
                        My Editor
                      </DashboardAdminSubTab>
                    ) : (
                      <DashboardAdminSubTab
                        style={{ display: 'none' }}
                      ></DashboardAdminSubTab>
                    )}
                    {(!isComplianceAdmin) ? (
                      <DashboardAdminSubTab
                        hide={
                          get(firmSettings, 'firm_general.my_clients', 'true') === 'false'
                        }
                        color="#49484a"
                        padding="5px 23px 3px"
                        className={`${
                          selectedExploreTabIndex === 6 ? 'active' : 'inactive'
                        }`}
                        data-testid="my_clients_subtab"
                      >
                        My Clients
                      </DashboardAdminSubTab>
                    ) : (
                      <DashboardAdminSubTab
                        style={{ display: 'none' }}
                      ></DashboardAdminSubTab>
                    )}
                    <DashboardAdminSubTab
                      color="#49484a"
                      padding="5px 23px 3px"
                      className={`${
                        selectedExploreTabIndex === 7 ? 'active' : 'inactive'
                      }`}
                      data-testid="search_subtab"
                    >
                      Search
                    </DashboardAdminSubTab>
                    {!isComplianceAdmin ? (
                      <DashboardAdminSubTab
                        color="#49484a"
                        padding="5px 23px 3px"
                        className={`${
                          selectedExploreTabIndex === 8 ? 'active' : 'inactive'
                        }`}
                        data-testid="bookmarked_subtab"
                      >
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          Bookmarked{' '}
                          <img
                            alt=""
                            style={{ marginLeft: '2px' }}
                            src="/assets/images/bookmark-filled.svg"
                          />
                        </div>
                      </DashboardAdminSubTab>
                    ) : (
                      <DashboardAdminSubTab
                        style={{ display: 'none' }}
                      ></DashboardAdminSubTab>
                    )}
                  </TabList>
                  {!isComplianceAdmin && selectedExploreTabIndex === 0 && (
                    <StyledTabPanel
                      type="admin"
                      className="react-tabs__tab-panel--selected"
                      padding="5px 5px"
                    >
                      {!exploreArticlesFetching &&
                        isEmpty(get(exploreArticles, 'feed', [])) && (
                          <NoContentWrapper data-testid="no-content-message">
                            {emptyMessages.mioEditorTab}
                          </NoContentWrapper>
                        )}

                      {showArticleLoader && (
                        <ExploreRelativeDiv>
                          <AbsoluteLoader>
                            <Loader height="25px" />
                          </AbsoluteLoader>
                        </ExploreRelativeDiv>
                      )}

                      {!showTabLoader && (
                        <RecommendedTab
                          selectedTopic={popularTopicDetails.topicName}
                          exploreArticles={exploreArticles}
                          deleteArticleRequest={onDeleteArticleClick}
                          themeNames={themeNames}
                          exploreArticleDeleting={exploreArticleDeleting}
                          isShareBlocked={isShareBlocked}
                          isDeleteBlocked
                          showTabLoader={showTabLoader}
                          setShowArticleLoader={setShowArticleLoader}
                          popularThemes={popularThemes}
                          userData={userData}
                          exploreArticleRequest={exploreArticleRequest}
                          popularTopicDetails={popularTopicDetails}
                          onNewsletterArticleClick={onNewsletterArticleClick}
                          onRemoveFromNewletterClick={
                            onRemoveFromNewletterClick
                          }
                          newsletterArticleFetching={newsletterArticleFetching}
                          exploreNewsletterId={exploreNewsletterId}
                          bookmarksFetching={bookmarksFetching}
                          bookmarksId={bookmarksId}
                          hideDelete
                        />
                      )}
                    </StyledTabPanel>
                  )}

                  {selectedExploreTabIndex === 3 && (
                    <StyledTabPanel
                      type="admin"
                      className="react-tabs__tab-panel--selected"
                      padding="5px 5px"
                    >
                      {!exploreArticlesFetching &&
                        isEmpty(exploreArticleFeed) && (
                          <NoContentWrapper data-testid="no-content-message">
                            {emptyMessages.default}
                          </NoContentWrapper>
                        )}

                      {(showArticleLoader || exploreArticlesFetching) && (
                        <ExploreRelativeDiv>
                          <AbsoluteLoader>
                            <Loader height="25px" />
                          </AbsoluteLoader>
                        </ExploreRelativeDiv>
                      )}

                      {!showTabLoader && !exploreArticlesFetching && (
                        <>
                          {!_.isEmpty(exploreTabDetails) && (
                            <ExploreTab
                              selectedTopic={popularTopicDetails.topicName}
                              exploreArticles={exploreArticles}
                              deleteArticleRequest={onDeleteArticleClick}
                              themeNames={themeNames}
                              exploreArticleDeleting={exploreArticleDeleting}
                              isShareBlocked={isShareBlocked}
                              isDeleteBlocked={isDeleteBlocked}
                              showTabLoader={showTabLoader}
                              setShowArticleLoader={setShowArticleLoader}
                              popularThemes={popularThemes}
                              userData={userData}
                              exploreArticleRequest={exploreArticleRequest}
                              popularTopicDetails={popularTopicDetails}
                              onNewsletterArticleClick={
                                onNewsletterArticleClick
                              }
                              onRemoveFromNewletterClick={
                                onRemoveFromNewletterClick
                              }
                              newsletterArticleFetching={
                                newsletterArticleFetching
                              }
                              exploreNewsletterId={exploreNewsletterId}
                              bookmarksFetching={bookmarksFetching}
                              bookmarksId={bookmarksId}
                            />
                          )}
                          {_.isEmpty(exploreTabDetails) && (
                            <NoContentWrapper style={{ cursor: 'pointer' }}>
                              {emptyMessages.exploreTab}
                            </NoContentWrapper>
                          )}
                        </>
                      )}
                    </StyledTabPanel>
                  )}
                  {selectedExploreTabIndex === 1 && (
                    <StyledTabPanel
                      type="admin"
                      className="react-tabs__tab-panel--selected"
                      padding="5px 5px"
                    >
                      {!exploreArticlesFetching && isEmpty(exploreArticles) && (
                        <NoContentWrapper data-testid="no-content-message">
                          {emptyMessages.default}
                        </NoContentWrapper>
                      )}

                      {!isEmpty(trendingTopicDetails) &&
                        (exploreArticlesFetching || showArticleLoader) && (
                          <ExploreRelativeDiv>
                            <AbsoluteLoader>
                              <Loader height="25px" />
                            </AbsoluteLoader>
                          </ExploreRelativeDiv>
                        )}
                      {!showTabLoader && !exploreArticlesFetching && (
                        <TrendingTab
                          selectedTopic={trendingTopicDetails.topicName}
                          exploreArticles={exploreArticles}
                          deleteArticleRequest={onDeleteArticleClick}
                          themeNames={themeNames}
                          exploreArticleDeleting={exploreArticleDeleting}
                          isShareBlocked={isShareBlocked}
                          isDeleteBlocked={isDeleteBlocked}
                          setShowArticleLoader={setShowArticleLoader}
                          popularThemes={popularThemes}
                          userData={userData}
                          onNewsletterArticleClick={onNewsletterArticleClick}
                          onRemoveFromNewletterClick={
                            onRemoveFromNewletterClick
                          }
                          newsletterArticleFetching={newsletterArticleFetching}
                          exploreNewsletterId={exploreNewsletterId}
                          bookmarksFetching={bookmarksFetching}
                          bookmarksId={bookmarksId}
                        />
                      )}
                    </StyledTabPanel>
                  )}
                  {!isComplianceAdmin && selectedExploreTabIndex === 5 && (
                    <StyledTabPanel
                      type="admin"
                      className="react-tabs__tab-panel--selected"
                      padding="5px 5px"
                    >

                      {!showArticleLoader &&
                        !exploreArticlesFetching &&
                        isEmpty(get(exploreArticles, 'feed', [])) && isEmpty(mioContent) && (
                          <NoContentWrapper data-testid="no-content-message">
                            {emptyMessages.mioEditorTab}
                          </NoContentWrapper>
                        )}

                      {showArticleLoader && (
                        <ExploreRelativeDiv>
                          <AbsoluteLoader>
                            <Loader height="25px" />
                          </AbsoluteLoader>
                        </ExploreRelativeDiv>
                      )}

                    
                        <MioEditorTab
                          selectedTopic={popularTopicDetails.topicName}
                          exploreArticles={exploreArticles}
                          deleteArticleRequest={deleteArticleRequest}
                          exploreArticlesFetching={exploreArticlesFetching}
                          themeNames={themeNames}
                          exploreArticleDeleting={exploreArticleDeleting}
                          isShareBlocked={isShareBlocked}
                          isDeleteBlocked={isDeleteBlocked}
                          exploreArticleRequest={exploreArticleRequest}
                          setMioContent={setMioContent}
                          imageUpload={window.localStorage.getItem(
                            'ImageUpload'
                          )}
                          setShowArticleLoader={setShowArticleLoader}
                          thirdPartyThemeId={thirdPartyThemeId}
                          clearEditorArticles={clearEditorArticles}
                          setClearEditorArticles={setClearEditorArticles}
                          isChangeTheme={isChangeTheme}
                          popularThemes={popularThemes}
                          userData={userData}
                          popularTopicDetails={popularTopicDetails}
                          newsletterArticleFetching={
                            newsletterArticleFetching
                          }
                          addToBucketRequest={addToBucketRequest}
                          removeFromNewsletterRequest={
                            removeFromNewsletterRequest
                          }
                          exploreNewsletterId={exploreNewsletterId}
                          addToBookmarksRequest={addToBookmarksRequest}
                          removeFromBookmarksRequest={
                            removeFromBookmarksRequest
                          }
                          bookmarksFetching={bookmarksFetching}
                          bookmarksId={bookmarksId}
                        />
                    
                      

                    </StyledTabPanel>
                  )}
                  {!isComplianceAdmin && selectedExploreTabIndex === 2 && (
                    <StyledTabPanel
                      type="admin"
                      className="react-tabs__tab-panel--selected"
                      padding="5px 5px"
                    >
                      <ContentLibraryTab
                        setLocalContent={setLocalContent}
                        selectedTopic={popularTopicDetails.topicName}
                        contentlibRequest={contentlibRequest}
                        contentlibDeleteRequest={contentlibDeleteRequest}
                        contentlibSetState={contentlibSetState}
                        contentLibArticles={contentLibArticles}
                        contentLibArticlesFetching={contentLibArticlesFetching}
                        themeNames={themeNames}
                        contentLibArticleDeleting={contentLibArticleDeleting}
                        isShareBlocked={isShareBlocked}
                        isDeleteBlocked={isDeleteBlocked}
                        showTabLoader={showTabLoader}
                        setShowArticleLoader={setShowArticleLoader}
                        isEnterpriseAdmin={isEnterpriseAdmin}
                        curatedTopicType={curatedTopicType}
                        popularThemes={popularThemes}
                        userData={userData}
                        contentLibAddToBucketRequest={
                          contentLibAddToBucketRequest
                        }
                        contentLibRemoveFromBucketRequest={
                          contentLibRemoveFromBucketRequest
                        }
                        newsletterArticleFetching={contentLibNewsletterFetching}
                        bookmarksFetching={contentLibBookmarksFetching}
                        bookmarksId={contentLibBookmarksId}
                        contentLibAddToBookmarksRequest={
                          contentLibAddToBookmarksRequest
                        }
                        contentLibRemoveFromBookmarksRequest={
                          contentLibRemoveFromBookmarksRequest
                        }
                        contentLibBucketId={contentLibBucketId}
                        contentLibInNewsletter={contentLibInNewsletter}
                        contentLibBookMarkId={contentLibBookMarkId}
                        contentLibIsBookmark={contentLibIsBookmark}
                        channelId={channelId}
                        contentLibATSQueue={contentLibATSQueue}
                        contentLibFilter={contentLibFilter}
                      />
                    </StyledTabPanel>
                  )}

                  {isComplianceAdmin && selectedExploreTabIndex === 0 && (
                    <StyledTabPanel
                      type="admin"
                      className="react-tabs__tab-panel--selected"
                    ></StyledTabPanel>
                  )}

                  {isComplianceAdmin && selectedExploreTabIndex === 2 && (
                    <StyledTabPanel
                      type="admin"
                      className="react-tabs__tab-panel--selected"
                    ></StyledTabPanel>
                  )}

                  {selectedExploreTabIndex === 4 &&
                    showInvestmentTab &&
                    isComplianceAdmin && (
                      <StyledTabPanel
                        type="admin"
                        className="react-tabs__tab-panel--selected"
                        padding="5px 5px"
                      >
                        {showArticleLoader && (
                          <ExploreRelativeDiv>
                            <AbsoluteLoader>
                              <Loader height="25px" />
                            </AbsoluteLoader>
                          </ExploreRelativeDiv>
                        )}
                        {!exploreArticlesFetching &&
                          (isInvEmpty ||
                            isEmpty(get(investmentResults, 'feed'))) && (
                            <NoContentWrapper>
                              <Flex
                                width={1}
                                justifyContent="center"
                                alignItems="center"
                                data-testid="no-content-message"
                              >
                                No articles found
                                {/* <Box>
                              <AddInvetmentsButton
                                src="/assets/images/briefcase.png"
                                alt="add invetments"
                                onClick={() => history.push('/portfolio')}
                              />
                              <StyledHeading onClick={() => history.push('/portfolio')}>
                                Add Your Investments
                              </StyledHeading>
                            </Box> */}
                              </Flex>
                            </NoContentWrapper>
                          )}
                        <LoaderWrapper isLoading={showTabLoader}>
                          {selectedTickers && selectedTickers.length > 0 && (
                            <InvestmentsTab
                              exploreSetState={exploreSetState}
                              exploreArticleDeleting={exploreArticleDeleting}
                              selectedTopic={trendingTopicDetails.topicName}
                              exploreArticles={investmentResults}
                              deleteArticleRequest={onDeleteArticleClick}
                              themeNames={themeNames}
                              isShareBlocked={isShareBlocked}
                              isDeleteBlocked={isDeleteBlocked}
                              handleTickerChange={handleTickerChange}
                              handleTopicChange={handleTopicChange}
                              isTicker={isTicker}
                              selectedTickers={selectedTickers}
                              isComplianceAdmin
                              tickerName={tickerName}
                              setShowArticleLoader={setShowArticleLoader}
                              popularThemes={popularThemes}
                              userData={userData}
                              onNewsletterArticleClick={
                                onNewsletterArticleClick
                              }
                              onRemoveFromNewletterClick={
                                onRemoveFromNewletterClick
                              }
                              newsletterArticleFetching={
                                newsletterArticleFetching
                              }
                              exploreNewsletterId={exploreNewsletterId}
                              bookmarksFetching={bookmarksFetching}
                              bookmarksId={bookmarksId}
                            />
                          )}
                        </LoaderWrapper>
                      </StyledTabPanel>
                    )}
                  {selectedExploreTabIndex === 4 &&
                    ((showInvestmentTab && !isComplianceAdmin) ||
                      ([4].includes(selectedTabIndex) &&
                        !isComplianceAdmin)) && (
                      <StyledTabPanel
                        type="admin"
                        className="react-tabs__tab-panel--selected"
                        padding="5px 5px"
                      >
                        {showArticleLoader && (
                          <ExploreRelativeDiv>
                            <AbsoluteLoader>
                              <Loader height="25px" />
                            </AbsoluteLoader>
                          </ExploreRelativeDiv>
                        )}
                        {!exploreArticlesFetching &&
                          !showTabLoader &&
                          (isInvEmpty ||
                            isEmpty(get(investmentResults, 'feed'))) && (
                            <NoContentWrapper>
                              <Flex
                                width={1}
                                justifyContent="center"
                                alignItems="center"
                                data-testid="no-content-message"
                              >
                                No articles found
                              </Flex>
                            </NoContentWrapper>
                          )}

                        <LoaderWrapper isLoading={showTabLoader}>
                          {selectedTickers && selectedTickers.length > 0 && (
                            <InvestmentsTab
                              exploreSetState={exploreSetState}
                              exploreArticleDeleting={exploreArticleDeleting}
                              selectedTopic={trendingTopicDetails.topicName}
                              exploreArticles={investmentResults}
                              deleteArticleRequest={onDeleteArticleClick}
                              themeNames={themeNames}
                              isShareBlocked={isShareBlocked}
                              isDeleteBlocked={isDeleteBlocked}
                              handleTickerChange={handleTickerChange}
                              handleTopicChange={handleTopicChange}
                              isTicker={isTicker}
                              selectedTickers={selectedTickers}
                              isComplianceAdmin
                              tickerName={tickerName}
                              setShowArticleLoader={setShowArticleLoader}
                              holdingsType={holdingsType}
                              isFilteringStocksByTicker={
                                isFilteringStocksByTicker
                              }
                              popularThemes={popularThemes}
                              userData={userData}
                              onNewsletterArticleClick={
                                onNewsletterArticleClick
                              }
                              onRemoveFromNewletterClick={
                                onRemoveFromNewletterClick
                              }
                              newsletterArticleFetching={
                                newsletterArticleFetching
                              }
                              exploreNewsletterId={exploreNewsletterId}
                              bookmarksFetching={bookmarksFetching}
                              bookmarksId={bookmarksId}
                            />
                          )}
                        </LoaderWrapper>
                      </StyledTabPanel>
                    )}

                  {!isComplianceAdmin && selectedExploreTabIndex === 6 && (
                    <StyledTabPanel
                      type="admin"
                      className="react-tabs__tab-panel--selected"
                      padding="5px 5px"
                    >
                      {((!exploreArticlesFetching &&
                        !crmLoader &&
                        isEmpty(get(exploreArticles, 'feed', []))) ||
                        selectedMyClientsDropdownOption === '') && (
                        // <NoContentWrapper>{selectedMyClientsDropdownOption === 'financial' || selectedCRMContactList.id ? emptyMessages.exploreTab : 'Please connect your CRM accounts to populate content'}</NoContentWrapper>
                        <NoContentWrapper>
                          {selectedMyClientsDropdownOption === ''
                            ? 'Select from available options'
                            : selectedMyClientsDropdownOption === 'financial' ||
                              (selectedCRMContactList.id &&
                                selectedCRMContactList.id.length > 0)
                            ? emptyMessages.exploreTab
                            : 'Please connect your CRM accounts to populate content'}
                        </NoContentWrapper>
                      )}

                      {!exploreArticlesFetching &&
                        selectedMyClientsDropdownOption === 'crm' &&
                        isEmpty(myClientsLocalArticles) &&
                        !isEmpty(get(exploreArticles, 'feed', [])) && (
                          <NoContentWrapper>
                            Please enable atleast one topic
                          </NoContentWrapper>
                        )}

                      {(showArticleLoader || exploreArticlesFetching) && (
                        <ExploreRelativeDiv>
                          <AbsoluteLoader>
                            <Loader height="25px" />
                          </AbsoluteLoader>
                        </ExploreRelativeDiv>
                      )}
                      {!showTabLoader &&
                        selectedMyClientsDropdownOption !== '' && (
                          // {!showTabLoader &&
                          <MyClientsTab
                            setLocalContent={setLocalContent}
                            selectedTopic={popularTopicDetails.topicName}
                            contentlibRequest={contentlibRequest}
                            contentlibDeleteRequest={contentlibDeleteRequest}
                            contentLibArticles={contentLibArticles}
                            contentLibArticlesFetching={
                              contentLibArticlesFetching
                            }
                            themeNames={themeNames}
                            contentLibArticleDeleting={
                              contentLibArticleDeleting
                            }
                            isShareBlocked={
                              selectedMyClientsDropdownOption === 'financial'
                                ? clientShareBlocked
                                : !selectedCRMContactList.id
                            }
                            isDeleteBlocked={isDeleteBlocked}
                            showTabLoader={showTabLoader}
                            setShowArticleLoader={setShowArticleLoader}
                            curatedTopicType={curatedTopicType}
                            exploreArticles={exploreArticles}
                            exploreArticleRequest={exploreArticleRequest}
                            popularThemes={popularThemes}
                            userData={userData}
                            onNewsletterArticleClick={onNewsletterArticleClick}
                            onRemoveFromNewletterClick={
                              onRemoveFromNewletterClick
                            }
                            exploreArticleDeleting={exploreArticleDeleting}
                            deleteArticleRequest={onDeleteArticleClick}
                            newsletterArticleFetching={
                              newsletterArticleFetching
                            }
                            exploreNewsletterId={exploreNewsletterId}
                            bookmarksFetching={bookmarksFetching}
                            bookmarksId={bookmarksId}
                            selectedDropdownOption={
                              selectedMyClientsDropdownOption
                            }
                            selectedCRMContactList={selectedCRMContactList}
                            setMyClientsLocalArticles={
                              setMyClientsLocalArticles
                            }
                            toggleCrmLoader={setCrmLoader}
                            clientDetails={clientDetails}
                          />
                        )}
                    </StyledTabPanel>
                  )}

                  {!isComplianceAdmin && selectedExploreTabIndex === 7 && (
                    <StyledTabPanel
                      type="admin"
                      className="react-tabs__tab-panel--selected"
                      padding="5px 5px"
                    >
                      {!exploreArticlesFetching &&
                        (isEmpty(get(searchResults, 'feed')) ||
                          filteredSearchEmpty ||
                          Object.keys(searchResults).length === 0) && (
                          <NoContentWrapper>
                            {isSearchFilters ? (
                              <div>Search Results</div>
                            ) : (
                              <div data-testid="no-content-message">
                                No articles found
                              </div>
                            )}
                          </NoContentWrapper>
                        )}
                      {showArticleLoader && (
                        <ExploreRelativeDiv>
                          <AbsoluteLoader>
                            <Loader height="25px" />
                          </AbsoluteLoader>
                        </ExploreRelativeDiv>
                      )}

                      {!showTabLoader && (
                        <SearchResultsTab
                          selectedTopic={trendingTopicDetails.topicName}
                          exploreArticles={searchResults}
                          deleteArticleRequest={onDeleteArticleClick}
                          themeNames={themeNames}
                          exploreArticleDeleting={exploreArticleDeleting}
                          isShareBlocked={isShareBlocked}
                          isDeleteBlocked={isDeleteBlocked}
                          setIsSearchFilters={setIsSearchFilters}
                          mostSubscribed={mostSubscribed}
                          yourTopics={yourTopics}
                          setShowArticleLoader={setShowArticleLoader}
                          searchFilters={searchFilters}
                          setFilteredSearchEmpty={setFilteredSearchEmpty}
                          popularThemes={popularThemes}
                          userData={userData}
                          onNewsletterArticleClick={onNewsletterArticleClick}
                          onRemoveFromNewletterClick={
                            onRemoveFromNewletterClick
                          }
                          newsletterArticleFetching={newsletterArticleFetching}
                          exploreNewsletterId={exploreNewsletterId}
                          bookmarksFetching={bookmarksFetching}
                          bookmarksId={bookmarksId}
                        />
                      )}
                    </StyledTabPanel>
                  )}

                  {isComplianceAdmin && selectedExploreTabIndex === 7 && (
                    <StyledTabPanel
                      type="admin"
                      className="react-tabs__tab-panel--selected"
                      padding="5px 5px"
                    >
                      {(isEmpty(get(searchResults, 'feed')) ||
                        filteredSearchEmpty ||
                        Object.keys(searchResults).length === 0) && (
                        <NoContentWrapper>
                          {isSearchFilters ? (
                            <div>Search Results</div>
                          ) : (
                            <div data-testid="no-content-message">
                              No articles found
                            </div>
                          )}
                        </NoContentWrapper>
                      )}

                      {showArticleLoader && (
                        <ExploreRelativeDiv>
                          <AbsoluteLoader>
                            <Loader height="25px" />
                          </AbsoluteLoader>
                        </ExploreRelativeDiv>
                      )}

                      {!showTabLoader && (
                        <LoaderWrapper>
                          <SearchResultsTab
                            selectedTopic={trendingTopicDetails.topicName}
                            exploreArticles={searchResults}
                            deleteArticleRequest={onDeleteArticleClick}
                            themeNames={themeNames}
                            exploreArticleDeleting={exploreArticleDeleting}
                            isShareBlocked={isShareBlocked}
                            isDeleteBlocked={isDeleteBlocked}
                            setIsSearchFilters={setIsSearchFilters}
                            mostSubscribed={mostSubscribed}
                            yourTopics={yourTopics}
                            setShowArticleLoader={setShowArticleLoader}
                            searchFilters={searchFilters}
                            setFilteredSearchEmpty={setFilteredSearchEmpty}
                            popularThemes={popularThemes}
                            userData={userData}
                            onNewsletterArticleClick={onNewsletterArticleClick}
                            onRemoveFromNewletterClick={
                              onRemoveFromNewletterClick
                            }
                            newsletterArticleFetching={
                              newsletterArticleFetching
                            }
                            exploreNewsletterId={exploreNewsletterId}
                            bookmarksFetching={bookmarksFetching}
                            bookmarksId={bookmarksId}
                          />
                        </LoaderWrapper>
                      )}
                    </StyledTabPanel>
                  )}
                  {!isComplianceAdmin && selectedExploreTabIndex === 8 ? (
                    <StyledTabPanel
                      type="admin"
                      className="react-tabs__tab-panel--selected"
                      style={{ padding: '5px 5px' }}
                    >
                      {!exploreArticlesFetching &&
                        isEmpty(get(exploreArticles, 'feed', [])) && (
                          <NoContentWrapper data-testid="no-content-message">
                            {emptyMessages.mioEditorTab}
                          </NoContentWrapper>
                        )}

                      {exploreArticlesFetching && (
                        <ExploreRelativeDiv>
                          <AbsoluteLoader>
                            <Loader height="25px" />
                          </AbsoluteLoader>
                        </ExploreRelativeDiv>
                      )}
                      {!showTabLoader && (
                        <BookmarksTab
                          setLocalContent={setLocalContent}
                          selectedTopic={popularTopicDetails.topicName}
                          contentlibRequest={contentlibRequest}
                          contentlibDeleteRequest={contentlibDeleteRequest}
                          contentLibArticles={contentLibArticles}
                          contentLibArticlesFetching={
                            contentLibArticlesFetching
                          }
                          themeNames={themeNames}
                          contentLibArticleDeleting={contentLibArticleDeleting}
                          isShareBlocked={isShareBlocked}
                          isDeleteBlocked={isDeleteBlocked}
                          showTabLoader={showTabLoader}
                          setShowArticleLoader={setShowArticleLoader}
                          curatedTopicType={curatedTopicType}
                          exploreArticles={exploreArticles}
                          popularThemes={popularThemes}
                          userData={userData}
                          deleteArticleRequest={deleteArticleRequest}
                          onNewsletterArticleClick={onNewsletterArticleClick}
                          onRemoveFromNewletterClick={
                            onRemoveFromNewletterClick
                          }
                          newsletterArticleFetching={newsletterArticleFetching}
                          bookmarksFetching={bookmarksFetching}
                          exploreNewsletterId={exploreNewsletterId}
                          bookmarksId={bookmarksId}
                        />
                      )}
                    </StyledTabPanel>
                  ) : null}
                </Tabs>
              </StyledTabPanelTwo>
            )}
            {selectedTabIndex === 1 && (
              <StyledTabPanelTwo>
                <Tabs onSelect={onRecentTabSelect} forceRenderTabPanel>
                  <TabList style={{ marginBottom: '10px' }}>
                    <DashboardAdminSubTab
                      color="#49484a"
                      padding="5px 23px 3px"
                      className={`${
                        selectedRecentTabIndex === 0 ? 'active' : 'inactive'
                      }`}
                      onClick={onRecentTabClick}
                      data-testid="all_posts_recent"
                    >
                      All posts
                    </DashboardAdminSubTab>
                    <DashboardAdminSubTab
                      color="#49484a"
                      padding="5px 23px 3px"
                      className={`${
                        selectedRecentTabIndex === 1 ? 'active' : 'inactive'
                      }`}
                      onClick={onRecentLastmonthTabClick}
                      data-testid="this_week_recent"
                    >
                      This Week
                    </DashboardAdminSubTab>
                  </TabList>

                  {selectedRecentTabIndex === 0 && (
                    <StyledTabPanel
                      type="admin"
                      className="react-tabs__tab-panel--selected"
                      padding="5px 5px"
                    >
                      {!exploreArticlesFetching &&
                        isEmpty(exploreArticleFeed) && (
                          <NoContentWrapper>
                            {emptyMessages.recentTab}
                          </NoContentWrapper>
                        )}
                      {exploreArticlesFetching && showArticleLoader && (
                        <ExploreRelativeDiv>
                          <AbsoluteLoader>
                            <Loader height="25px" />
                          </AbsoluteLoader>
                        </ExploreRelativeDiv>
                      )}
                      {!showTabLoader && (
                        <div>
                          <ExportPDFButton
                            onClick={() =>
                              exportPdfHandler('isRecentArticle', true)
                            }
                          >
                            <FontAwesomeIcon
                              icon={faDownload}
                              color="#6351ed"
                              size="1x"
                            />
                            <div style={{ marginLeft: '5px' }}>
                              Export To PDF
                            </div>
                          </ExportPDFButton>
                          <RecentSharedTab
                            selectedTopic={popularTopicDetails.topicName}
                            exploreArticles={exploreArticles}
                            deleteArticleRequest={onDeleteArticleClick}
                            themeNames={themeNames}
                            isShareBlocked={isShareBlocked}
                            isDeleteBlocked={isDeleteBlocked}
                            isAdmin={isAdmin}
                            showTabLoader={showTabLoader}
                            setShowArticleLoader={setShowArticleLoader}
                          />
                          {isExportPdfOpen.isRecentArticle && (
                            <ExportPDF
                              open={isExportPdfOpen.isRecentArticle}
                              setOpen={setIsExportPdfOpen}
                              selectedTopic={popularTopicDetails.topicName}
                              deleteArticleRequest={onDeleteArticleClick}
                              themeNames={themeNames}
                              isShareBlocked={isShareBlocked}
                              isDeleteBlocked={isDeleteBlocked}
                              isAdmin={isAdmin}
                              showTabLoader={showTabLoader}
                              setShowArticleLoader={setShowArticleLoader}
                              currentPopUp={'isRecentArticle'}
                            />
                          )}
                        </div>
                      )}
                    </StyledTabPanel>
                  )}
                  {selectedRecentTabIndex === 1 && (
                    <StyledTabPanel
                      type="admin"
                      className="react-tabs__tab-panel--selected"
                      padding="5px 5px"
                    >
                      {!exploreArticlesFetching &&
                        isEmpty(exploreArticleFeed) && (
                          <NoContentWrapper>
                            {emptyMessages.recentTab}
                          </NoContentWrapper>
                        )}
                      {exploreArticlesFetching && showArticleLoader && (
                        <ExploreRelativeDiv>
                          <AbsoluteLoader>
                            <Loader height="25px" />
                          </AbsoluteLoader>
                        </ExploreRelativeDiv>
                      )}
                      {!showTabLoader && (
                        <div>
                          <ExportPDFButton
                            onClick={() =>
                              exportPdfHandler('isRecentArticle', true)
                            }
                          >
                            <FontAwesomeIcon
                              icon={faDownload}
                              color="#6351ed"
                              size="1x"
                            />
                            <div style={{ marginLeft: '5px' }}>
                              Export To PDF
                            </div>
                          </ExportPDFButton>
                          <RecentSharedTab
                            selectedTopic={popularTopicDetails.topicName}
                            exploreArticles={exploreArticles}
                            deleteArticleRequest={onDeleteArticleClick}
                            themeNames={themeNames}
                            isShareBlocked={isShareBlocked}
                            isDeleteBlocked={isDeleteBlocked}
                            isAdmin={isAdmin}
                            setShowArticleLoader={setShowArticleLoader}
                          />
                          {isExportPdfOpen.isRecentArticle && (
                            <ExportPDF
                              open={isExportPdfOpen.isRecentArticle}
                              setOpen={setIsExportPdfOpen}
                              selectedTopic={popularTopicDetails.topicName}
                              deleteArticleRequest={onDeleteArticleClick}
                              themeNames={themeNames}
                              isShareBlocked={isShareBlocked}
                              isDeleteBlocked={isDeleteBlocked}
                              isAdmin={isAdmin}
                              setShowArticleLoader={setShowArticleLoader}
                              currentPopUp={'isRecentArticle'}
                            />
                          )}
                        </div>
                      )}
                    </StyledTabPanel>
                  )}
                </Tabs>
              </StyledTabPanelTwo>
            )}
            {selectedTabIndex === 2 && (
              <StyledTabPanelTwo>
                <Tabs onSelect={onScheduledTabSelect} forceRenderTabPanel>
                  <TabList style={{ marginBottom: '10px' }}>
                    <DashboardAdminSubTab
                      color="#49484a"
                      padding="5px 23px 3px"
                      className={`${
                        selectedScheduledTabIndex === 0 ? 'active' : 'inactive'
                      }`}
                      onClick={onScheduledBySelfTabClick}
                      data-testid="my_scheduled_content"
                    >
                      My Scheduled Content
                    </DashboardAdminSubTab>
                    <DashboardAdminSubTab
                      color="#49484a"
                      padding="5px 23px 3px"
                      className={`${
                        selectedScheduledTabIndex === 1 ? 'active' : 'inactive'
                      }`}
                      onClick={onScheduledByCompanyTabClick}
                    >
                      By Company
                    </DashboardAdminSubTab>
                    <DashboardAdminSubTab
                      color="#49484a"
                      padding="5px 23px 3px"
                      className={`${
                        selectedScheduledTabIndex === 2 ? 'active' : 'inactive'
                      }`}
                      onClick={onScheduledTabClick}
                      data-testid="recommended_by_company"
                    >
                      Recommended by {company.name}
                    </DashboardAdminSubTab>
                  </TabList>

                  {selectedScheduledTabIndex === 2 && (
                    <StyledTabPanel
                      type="admin"
                      className="react-tabs__tab-panel--selected"
                      padding="5px 5px"
                    >
                      {!exploreArticlesFetching &&
                        isEmpty(exploreArticleFeed) && (
                          <NoContentWrapper>
                            {emptyMessages.scheduledByMioTab}
                          </NoContentWrapper>
                        )}
                      {exploreArticlesFetching && showArticleLoader && (
                        <ExploreRelativeDiv>
                          <AbsoluteLoader>
                            <Loader height="25px" />
                          </AbsoluteLoader>
                        </ExploreRelativeDiv>
                      )}
                      {!showTabLoader && (
                        <div>
                          <ExportPDFButton
                            onClick={() =>
                              exportPdfHandler('isSharedArticle', true)
                            }
                          >
                            <FontAwesomeIcon
                              icon={faDownload}
                              color="#6351ed"
                              size="1x"
                            />
                            <div style={{ marginLeft: '5px' }}>
                              Export To PDF
                            </div>
                          </ExportPDFButton>
                          <ScheduledTab
                            exploreArticleDeleting={exploreArticleDeleting}
                            selectedTopic={popularTopicDetails.topicName}
                            exploreArticles={exploreArticles}
                            deleteArticleRequest={onDeleteArticleClick}
                            themeNames={themeNames}
                            isShareBlocked={isShareBlocked}
                            isDeleteBlocked={isDeleteBlocked}
                            exploreArticleRequest={exploreArticleRequest}
                            setShowArticleLoader={setShowArticleLoader}
                            scheduleType={'recommendation_engine'}
                          />
                          {isExportPdfOpen.isSharedArticle && (
                            <ExportPDF
                              open={isExportPdfOpen.isSharedArticle}
                              setOpen={setIsExportPdfOpen}
                              exploreArticleDeleting={exploreArticleDeleting}
                              selectedTopic={popularTopicDetails.topicName}
                              deleteArticleRequest={onDeleteArticleClick}
                              themeNames={themeNames}
                              isShareBlocked={isShareBlocked}
                              isDeleteBlocked={isDeleteBlocked}
                              exploreArticleRequest={exploreArticleRequest}
                              setShowArticleLoader={setShowArticleLoader}
                              scheduleType={'recommendation_engine'}
                              currentPopUp={'isSharedArticle'}
                            />
                          )}
                        </div>
                      )}
                    </StyledTabPanel>
                  )}

                  {selectedScheduledTabIndex === 1 && (
                    <StyledTabPanel
                      type="admin"
                      className="react-tabs__tab-panel--selected"
                      padding="5px 5px"
                    >
                      {!exploreArticlesFetching &&
                        isEmpty(exploreArticleFeed) && (
                          <NoContentWrapper>
                            {emptyMessages.scheduledByCompanyTab}
                          </NoContentWrapper>
                        )}
                      {exploreArticlesFetching && showArticleLoader && (
                        <ExploreRelativeDiv>
                          <AbsoluteLoader>
                            <Loader height="25px" />
                          </AbsoluteLoader>
                        </ExploreRelativeDiv>
                      )}
                      {!showTabLoader && (
                        <div>
                          <ExportPDFButton
                            onClick={() =>
                              exportPdfHandler('isSharedArticle', true)
                            }
                          >
                            <FontAwesomeIcon
                              icon={faDownload}
                              color="#6351ed"
                              size="1x"
                            />
                            <div style={{ marginLeft: '5px' }}>
                              Export To PDF
                            </div>
                          </ExportPDFButton>
                          <ScheduledTab
                            exploreArticleDeleting={exploreArticleDeleting}
                            selectedTopic={popularTopicDetails.topicName}
                            exploreArticles={exploreArticles}
                            deleteArticleRequest={onDeleteArticleClick}
                            themeNames={themeNames}
                            isShareBlocked={isShareBlocked}
                            isDeleteBlocked={isDeleteBlocked}
                            exploreArticleRequest={exploreArticleRequest}
                            setShowArticleLoader={setShowArticleLoader}
                            scheduleType={'admin'}
                          />
                          {isExportPdfOpen.isSharedArticle && (
                            <ExportPDF
                              open={isExportPdfOpen.isSharedArticle}
                              setOpen={setIsExportPdfOpen}
                              exploreArticleDeleting={exploreArticleDeleting}
                              selectedTopic={popularTopicDetails.topicName}
                              deleteArticleRequest={onDeleteArticleClick}
                              themeNames={themeNames}
                              isShareBlocked={isShareBlocked}
                              isDeleteBlocked={isDeleteBlocked}
                              exploreArticleRequest={exploreArticleRequest}
                              setShowArticleLoader={setShowArticleLoader}
                              scheduleType={'admin'}
                              currentPopUp={'isSharedArticle'}
                            />
                          )}
                        </div>
                      )}
                    </StyledTabPanel>
                  )}

                  {selectedScheduledTabIndex === 0 && (
                    <StyledTabPanel
                      type="admin"
                      className="react-tabs__tab-panel--selected"
                      padding="5px 5px"
                    >
                      {!exploreArticlesFetching &&
                        isEmpty(exploreArticleFeed) && (
                          <NoContentWrapper>
                            {emptyMessages.scheduledTab}
                          </NoContentWrapper>
                        )}
                      {exploreArticlesFetching && showArticleLoader && (
                        <ExploreRelativeDiv>
                          <AbsoluteLoader>
                            <Loader height="25px" />
                          </AbsoluteLoader>
                        </ExploreRelativeDiv>
                      )}
                      {!showTabLoader && (
                        <div>
                          <ExportPDFButton
                            onClick={() =>
                              exportPdfHandler('isSharedArticle', true)
                            }
                          >
                            <FontAwesomeIcon
                              icon={faDownload}
                              color="#6351ed"
                              size="1x"
                            />
                            <div style={{ marginLeft: '5px' }}>
                              Export To PDF
                            </div>
                          </ExportPDFButton>
                          <ScheduledTab
                            exploreArticleDeleting={exploreArticleDeleting}
                            selectedTopic={popularTopicDetails.topicName}
                            exploreArticles={exploreArticles}
                            deleteArticleRequest={onDeleteArticleClick}
                            themeNames={themeNames}
                            isShareBlocked={isShareBlocked}
                            isDeleteBlocked={isDeleteBlocked}
                            exploreArticleRequest={exploreArticleRequest}
                            setShowArticleLoader={setShowArticleLoader}
                            scheduleType={'user'}
                          />
                          {isExportPdfOpen.isSharedArticle && (
                            <ExportPDF
                              open={isExportPdfOpen.isSharedArticle}
                              setOpen={setIsExportPdfOpen}
                              exploreArticleDeleting={exploreArticleDeleting}
                              selectedTopic={popularTopicDetails.topicName}
                              deleteArticleRequest={onDeleteArticleClick}
                              themeNames={themeNames}
                              isShareBlocked={isShareBlocked}
                              isDeleteBlocked={isDeleteBlocked}
                              exploreArticleRequest={exploreArticleRequest}
                              setShowArticleLoader={setShowArticleLoader}
                              scheduleType={'user'}
                              currentPopUp={'isSharedArticle'}
                            />
                          )}
                        </div>
                      )}
                    </StyledTabPanel>
                  )}
                </Tabs>
              </StyledTabPanelTwo>
            )}
            {selectedTabIndex === 3 && !isComplianceAdmin && (
              <StyledTabPanelTwo>
                <Tabs onSelect={onContentTabSelect} forceRenderTabPanel>
                  <TabList style={{ marginBottom: '10px' }}>
                    {/* <DashboardAdminSubTab className={`${selectedContentTabIndex === 0 ? 'active' : null}`}>Your Content</DashboardAdminSubTab> */}
                    <DashboardAdminSubTab
                      style={{ border: 'none' }}
                      disabled={isShareBlocked}
                    >
                      &nbsp;
                    </DashboardAdminSubTab>
                  </TabList>

                  {selectedContentTabIndex === 0 && (
                    <StyledTabPanel
                      className="react-tabs__tab-panel--selected"
                      padding="0px"
                      type="admin"
                    >
                      <AddContent
                        channelId={channelId}
                        themesList={themesList}
                        setTabIndex={setTabIndex}
                        contentlibRequest={contentlibRequest}
                        contentLibArticles={contentLibArticles}
                        userData={userData}
                        isAdmin={isAdmin}
                        setSelectedExploreTabIndex={setSelectedExploreTabIndex}
                        setWdigetTabIndex={setWdigetTabIndex}
                      />
                    </StyledTabPanel>
                  )}
                </Tabs>
              </StyledTabPanelTwo>
            )}
          </BorderWhiteBox>
        </Tabs>
      </Flex>
    </Box>
  );
};

const mapStateToProps = (state, ownProps) => {
  const isComplianceAdmin =
    get(ownProps, 'roleType') === ROLE_TYPES.ADMIN_COMPLIANCE;
  const isEnterpriseAdmin =
    get(ownProps, 'roleType') === ROLE_TYPES.ADMIN_ENTERPRISE;
  const isAssetAdmin =
    get(ownProps, 'roleType') === ROLE_TYPES.ADMIN_ASSET_MANAGER;
  const isAdmin = getUserRole(state) === USER_ROLE.admin;
  const isEnterpriseLevelAdmin = get(state, 'user.data.details.company.enterprise', 'false') === 'true';
  const isFirmLevelAdmin = get(state, 'user.data.details.company.firm', 'false') === 'true';
  const firmSettings = getFirmSettings({ isEnterpriseLevelAdmin, isFirmLevelAdmin, state });
  return {
    groupsList: state.advisorGroups.data,
    groupsListFetching: state.advisorGroups.isFetching,
    exploreArticles: state.explore.data,
    exploreArticlesFetching: state.explore.isFetching,
    exploreArticleDeleting: state.explore.isDeleting,
    contentLibArticles: state.contentlib.data,
    contentLibArticlesFetching: state.contentlib.isFetching,
    contentLibArticleDeleting: state.contentlib.isDeleting,
    isAnalyticsDataFetching: state.analytics.isFetching,
    analyticsObject: state.analytics,
    selectedRepType: state.analytics.repType,
    searchResults: get(state, 'explore.search', {}),
    themeNames: get(state, 'theme.themeNames', {}),
    inviteSending: state.invite.isFetching,
    inviteError: state.invite.isError,
    channelId: getChannelId(state),
    themesList: state.theme.data || [],
    investmentResults: get(state, 'explore.investments', {}),
    showMio: isAdmin,
    isShareBlocked: isComplianceAdmin,
    isDeleteBlocked: isComplianceAdmin,
    isAdmin,
    tickerName: state.explore.ticker,
    isInvEmpty: state.explore.isInvEmpty,
    isTicker: state.explore.isTicker,
    selectedTickers: state.explore.selectedTickers,
    userData: state.user.data,
    portfolioId: state.explore.currentPortfolio,
    yourTopics: state.theme.yourTopics,
    isComplianceAdmin,
    isEnterpriseAdmin,
    myMioData: get(state, 'explore.mymio', {}),
    currentPortfolio: state.explore.currentPortfolio,
    holdingsType: state.explore.holdingsType,
    isChangeTheme: state.explore.isChangeTheme,
    searchFilters: state.explore.searchFilters,
    isAssetAdmin: isAssetAdmin,
    exploreArticleError: state.explore.error,
    newsletterArticleFetching: state.explore.isNewsletterFetching,
    bookmarksFetching: state.explore.isBookmarksFetching,
    contentLibNewsletterFetching: state.contentlib.isNewsletterFetching,
    exploreNewsletterId: state.explore.newsletterId,
    bookmarksId: state.explore.bookmarkArticleId,
    contentLibBookmarksFetching: state.contentlib.isBookmarksFetching,
    contentLibBookmarksId: state.contentlib.bookmarkArticleId,
    contentLibBucketId: get(state, 'contentlib.bucketId', ''),
    contentLibInNewsletter: get(state, 'contentlib.inNewsletter', null),
    contentLibBookMarkId: get(state, 'contentlib.bookmark_article_id', ''),
    contentLibIsBookmark: get(state, 'contentlib.isBookMark', null),
    contentLibATSQueue: get(state, 'contentlib.pendingArticles', []),
    contentLibFilter: get(state, 'contentlib.filter', 'all'),
    topicDetails: get(state, 'explore.topicDetails', ''),
    isEnterpriseLevelAdmin,
    isFirmLevelAdmin,
    firmSettings: firmSettings,
  };
};

const mapDispatchToProps = {
  exploreArticleRequest: exploreActions.api.explore.request,
  advisorGroupsRequest: advisorGroupsActions.api.groups.request,
  deleteArticleRequest: exploreActions.api.explore.delete,
  inviteRequest: inviteActions.api.invite.request,
  contentlibRequest: contentlibActions.api.contentlib.request,
  contentlibDeleteRequest: contentlibActions.api.contentlib.delete,
  analyticsRequest: analyticsActions.api.analytics.request,
  exploreSetState: exploreActions.api.explore.setstate,
  contentlibSetState: contentlibActions.api.contentlib.setstate,
  themeSetState: themeActions.api.theme.setstate,
  loginSetState: loginActions.api.login.setstate,
  shareArticleSetState: shareActions.api.share.setstate,
  addToBucketRequest: exploreMoreActions.custom.explore.addToNewsletter,
  removeFromNewsletterRequest:
    exploreMoreActions.custom.explore.removeFromNewsletter,
  addToBookmarksRequest: exploreMoreActions.custom.explore.addToBookmarks,
  removeFromBookmarksRequest:
    exploreMoreActions.custom.explore.removeFromBookmarks,
  contentLibAddToBucketRequest:
    contentlibMoreActions.custom.contentlib.addToNewsletter,
  contentLibRemoveFromBucketRequest:
    contentlibMoreActions.custom.contentlib.removeFromNewsletter,
  contentLibAddToBookmarksRequest:
    contentlibMoreActions.custom.contentlib.addToBookmarks,
  contentLibRemoveFromBookmarksRequest:
    contentlibMoreActions.custom.contentlib.removeFromBookmarks,
  bucketCountRequest:
    newsletterMoreActions.custom.newsletter.bucketCountRequest,
};

AdminDashboardContainer.defaultProps = {
  groupsList: [],
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminDashboardContainer);
