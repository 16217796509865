import * as React from "react";

function LeftArrow(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" {...props}>
      <path
        d="M20.25 12H3.75M10.5 5.25L3.75 12l6.75 6.75"
        stroke="currentColor"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

const MemoLeftArrow = React.memo(LeftArrow);
export default MemoLeftArrow;
