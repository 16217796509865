import apiInstance from 'src/services/api';
import { cloutV2Endpoints } from 'src/services/helpers';

export const subscribeTopics = async ({ addThemes, removeThemes }) => {
  try {
    const response = await apiInstance()?.post(
      `${cloutV2Endpoints.baseUrl}/topic/theme/subscription?add_themes=${addThemes}&remove_themes=${removeThemes}`
    );

    return response;
  } catch (error) {
    console.error(error);
    return null;
  }
};
