import React from 'react';
import ContentLoader from 'react-content-loader';
import { Grid } from '@mui/material';

function SkeletonLoader(props) {
  return (
      <Grid width="100%" height="800px" margin="0px 0 20px" padding={'0px'}>
        <ContentLoader
          viewBox="0 0 100% 100%"
          height="100%"
          width="100%"
          margin={'10px 0 20px'}
          padding={'0px'}
        >
          <rect x="0" y="2%" rx="10" ry="4" width="32%" height="170px" />
          <rect x="35%" y="2%" rx="10" ry="4" width="32%" height="170px" />
          <rect x="69%" y="2%" rx="10" ry="4" width="32%" height="170px" />
          <rect x="0" y="200px" rx="10" ry="4" width="32%" height="170px" />
          <rect x="35%" y="200px" rx="10" ry="4" width="32%" height="170px" />
          <rect x="69%" y="200px" rx="10" ry="4" width="32%" height="170px" />
          <rect x="0" y="385px" rx="10" ry="4" width="32%" height="170px" />
          <rect x="35%" y="385px" rx="10" ry="4" width="32%" height="170px" />
          <rect x="69%" y="385px" rx="10" ry="4" width="32%" height="170px" />
        </ContentLoader>
      </Grid>
  );
}

export default SkeletonLoader;
