import { Flex } from '@rebass/grid';
import styled from 'styled-components';

export const VerticalSeparator = styled.div`
  border-left: 0.1px solid #cfd9e4;
  height: ${({ height }) => height || '40px'};
  margin-left: 15px;
  margin-right: 15px;
`;

export const HorizontalSeparator = styled.div`
  border: 0.1px dashed #cfd9e4;
  width: ${({ width }) => width || '30px'};
  margin-left: 12px;
  margin-right: 12px;
`;

export const HorizontalSeparatorSolid = styled.div`
  border-top: 0.05px solid #cfd9e4;
  width: ${({ width }) => width || '30px'};
  margin: ${({ margin }) => margin || ''};
  ${
    '' /* margin-left: 12px;
  margin-right: 12px; */
  }
`;

export const Icon = styled.img`
  cursor: pointer;
  margin-right: 5px;
  opacity: ${({ disabled }) => (disabled ? '30%' : '100%')};

  &:hover {
    content: ${({ onmouseover, disabled }) =>
      (disabled ? '' : `url(${onmouseover})`) || ''};
  }
`;

export const Title = styled.span`
  font-size: 12px;
  color: #4e6780;
  font-weight: 600;
  margin-bottom: 5px;
`;

export const ValueSpan = styled.span`
  font-size: ${({ fontSize }) => fontSize || '14px'};
  color: #000;
  font-weight: 800;
`;

export const Chips = styled.div.attrs({
  className: 'chips',
})`
  position: relative;
  display: inline-block;
  padding: 5px;
  font-size: 12px;
  line-height: 18px;
  border-radius: 5px;
  border: 1px solid #cfd9e4;
  background-color: #f6f9fb;
  color: #000000;
  font-weight: 900;
  margin: 5px;
`;

export const ContentSection = styled.div.attrs({
  className: 'content-section',
})`
  dipslay: flex;
  flex-direction: column;
  margin-top: 10px;
  width: 100%;
`;

export const ContentPanel = styled.div.attrs({
  className: 'content-panel',
})`
  display: flex;
  align-items: center;
  background-color: #f6f9fb;
  height: 50px;
  padding: 10px;
  margin-bottom: 5px;
  cursor: pointer;
  border: 1px solid #cfd9e4;
  border-radius: 4px;
  justify-content: space-between;
`;

export const ContentPanelSection = styled.div.attrs({
  className: 'content-panel-section',
})`
  background-color: #fff;
  flex-direction: column;
`;

export const DayPanel = styled.div.attrs({
  className: 'day-panel',
})`
  display: flex;
  width: 100%;
  padding: 10px;
  justify-content: space-between;
`;

export const DayPanelContentSection = styled.div.attrs({
  className: 'day-panel-content-section',
})`
  display: none;
`;

export const DetailTabs = styled.div.attrs({
  className: 'drip-tabs',
})`
  display: flex;
  align-items: center;
  justify-content: center;
  border: ${({ active }) =>
    active ? '1px solid #33B21B' : '1px solid #708892'};
  color: ${({ active, completed }) => (active ? '#fff' : '#708892')};
  background-color: ${({ completed }) => (completed ? '#33B21B' : '#fff')};
  border-radius: 4px;
  font-size: 13px;
  padding: 8px 20px;
  margin-right: ${({ mr }) => mr || '15px'};
  box-shadow: ${({ active }) =>
    active ? 'rgba(149, 157, 165, 0.2) 0px 8px 24px' : ''};
  position: relative;
  cursor: pointer;
  opacity: ${({ disabled }) => (disabled ? '30%' : '100%')};
  ${({ active }) =>
    active &&
    `
      &::after {
          position: absolute;
          z-index: 1;
          content: '';
          width: 0;
          height: 0;
          top: 102%;
          left: 50%;
          transform: translateX(-50%);
          border-right: solid 6px transparent;
          border-left: solid 6px transparent;
          border-top: solid 6px #33B21B;
      }
  `};
`;

export const DripDetailsWrapper = styled(Flex).attrs({
  className: 'drip-details-wrapper'
})`
  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background: #E9F6E6 !important;
  }

  &::-webkit-scrollbar-thumb {
    background: #BCCDD4 !important;
  }
`;
