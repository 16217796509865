import React from 'react';
import { isEmpty } from 'lodash';
import { 
  IconContainer,
  IntegrationIcon,
  LongIcon,
} from './styles';

import PurpleCheckbox from '../../componentsV2/PurpleCheckbox';

const SocialCheckBoxes = ({ buttons, onCheckBoxClick }) => {
  const handleCheckBoxClick = (e, platform) => {
    e.stopPropagation();
    onCheckBoxClick(platform);
  };

  if (!isEmpty(buttons)) {
    return (
      <IconContainer style={{width: '100%', margin: 0, justifyContent: 'space-between', flexFlow: 'rows', flexWrap: 'wrap'}}>
        {buttons.map((button, index) => {
          return (
            <LongIcon style={{ margin: '10px -2px 12px'}} key={index}>
                <div style={{display: 'flex'}}>
                  {button.icon && (
                      <IntegrationIcon
                        src={button.icon}
                        alt={button.id}
                        style={{margin: '0px 10px'}}
                      />
                  )}
                  {button.name && <p style={{
                    margin: '10px 0 0 6px', 
                    color: '#101828'
                  }}>{button.name}</p>}
                </div>
                {button.bottomText && (
                    <PurpleCheckbox 
                      checked={button.checked}
                      onClick={(e) => {
                        handleCheckBoxClick(e, button.platform)
                      }}
                      margin={'22px 0 0'}
                    />
                 )}
            </LongIcon>
          );
        })}
      </IconContainer>
    );
  }
  return null;
};

export default SocialCheckBoxes;