import React, { useState } from 'react';
import { Flex } from '@rebass/grid';
import { SearchContainer } from '../EmailList/EmailList.styles';
import SearchBar from '../SearchBar';
import {
  PublisherCardWrapper,
  StyledCross,
  StyledTitle,
  StyledTitleDiv,
} from '../ManagePublisher/styles';
import { Chip } from '../../../componentsV2/Chip';
import { LoaderWrapper } from '../../../components';
import { isEmpty } from 'lodash';
import { Tooltip, Zoom } from '@mui/material';

const TopicSearch = ({
  allTopics,
  loader,
  setLoader,
  subscribe,
  searchInput,
  setSearchInput
}) => {
  const [showSearchResults, toggleSearchResults] = useState(false);
  const [filteredTopics, setFilteredTopics] = useState([]);

  const searchHandler = () => {
    let topics = allTopics.filter((topic) =>
      topic.name.toLowerCase().includes(searchInput.toLowerCase())
    );
    setFilteredTopics(topics);
    toggleSearchResults(true);
  };

  return (
    <div style={{ width: '100%', textAlign: 'center' }}>
      <SearchContainer
        style={{ width: '35%', marginBottom: '25px', alignSelf: 'flex-start' }}
      >
        <SearchBar
          searchString={searchInput}
          setSearchString={setSearchInput}
          searchHandler={searchHandler}
          placeholder="Search For Topics"
        />
      </SearchContainer>
      {!isEmpty(searchInput) && showSearchResults ? (
        <Flex width="100%" flexDirection="column" height={filteredTopics.length === 0 ? "100px" : "300px"} maxHeight="300px">
          <Flex
              width="100%"
              justifyContent="flex-start"
              padding="0px 0px 10px 0px"
              marginBottom="5px"
              style={{
                fontSize: '16px',
                color: '#000000',
                alignItems: 'center',
              }}
            >
              {`Showing results for "${searchInput}"`}
            </Flex>
          {filteredTopics.length > 0 ? (
            <PublisherCardWrapper
              style={{
                flexWrap: 'wrap',
                justifyContent: 'start',
                maxHeight: '300px',
                marginBottom: '30px'
              }}
            >
              {filteredTopics.map((topic) => (
                <Chip
                  padding="10px"
                  justifyContent="space-between"
                  height="45px"
                  width="22%"
                  boxShadow="0px 1px 2px 0px #1018281F"
                >
                  <StyledTitleDiv style={{ width: '85%' }}>
                  <LoaderWrapper isLoading={loader === topic.name}>
                    <StyledTitle style={{ fontSize: '16px' }}>
                    {topic?.name && topic?.name?.length > 18 ? (
                          <Tooltip
                            TransitionComponent={Zoom}
                            disableInteractive={true}
                            arrow
                            placement="top"
                            title={topic.name}
                            componentsProps={{
                              tooltip: {
                                sx: {
                                  fontFamily: 'Poppins',
                                },
                              },
                            }}
                          >
                            {`${topic?.name.substring(0, 18)}...`}
                          </Tooltip>
                        ) : (
                          topic?.name
                        )}
                    </StyledTitle>
                    </LoaderWrapper>
                  </StyledTitleDiv>
                  {!loader ? (
                    <StyledCross
                      style={{ width: '15%' }}
                      onClick={() => {
                        setLoader(topic.name);
                        subscribe(topic);
                      }}
                    >
                      <img alt="" src="/assets/images/grey-plus-icon.svg" />
                    </StyledCross>
                  ) : null}
                </Chip>
              ))}
            </PublisherCardWrapper>
          ) : (
            <Flex justifyContent="center" width="100%" marginTop="100px">
            No Topics found
            </Flex>
          )}
        </Flex>
      ) : null}
    </div>
  );
};

export default TopicSearch;
