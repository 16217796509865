import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { getCRMContactList, getThemeList } from '../../services/configure/index';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { LoaderWrapper } from '../Loader';
import './CRMSegment.css';
import EditContactTopics from './EditContactTopics/EditContactTopics';
import { actions as exploreActions } from '../../redux/modules/explore-tab.module';

const CRMSegment = ({ loader, toggleLoader, close, setSelectedCRMContactList, exploreArticleRequest, setShowArticleLoader, handleTabIndexReset }) => {
    const [themeList, updateThemeList] = useState([]);
    const [contactList, updateContactList] = useState([]);
    const [selectedContactList, setSelectedContactList] = useState(null);
    const [showEditModal, toggleEditModal] = useState(false);

    useEffect(() => {
        toggleLoader(true);
        getThemeList()
        .then(response => {
            updateThemeList(response.result.data);
            fetchData(0);
        })
        .catch(err => console.log('ERROR - ', err));
    }, []);

    const fetchData = index => {
        getCRMContactList()
        .then(response => {
            const contactList = response.result.data ? 
                Object.keys(response.result.data).map(key => {
                    // console.log('recommended themes ---- ', response.result.data[key].recommended_themes)
                    const recommendedThemesArray = response.result.data[key].recommended_themes ? Object.keys(response.result.data[key].recommended_themes.themes).map(theme => response.result.data[key].recommended_themes.themes[theme]) : [];
                    const userThemesArray = response.result.data[key].user_themes ? Object.keys(response.result.data[key].user_themes.themes).map(theme => response.result.data[key].user_themes.themes[theme]) : [];
                    return {
                        id: key,
                        name: response.result.data[key].name,
                        themes: userThemesArray && userThemesArray.length>0 && userThemesArray[0] ? userThemesArray : recommendedThemesArray,
                        recommendedThemes: recommendedThemesArray,
                        userThemes: userThemesArray,
                        disabledThemes: []
                    };
                }) : [];
            console.log('CONTACT LIST = ', contactList);
            updateContactList(contactList);
            setSelectedContactList(index);
            setSelectedCRMContactList(contactList.length>0 ? contactList[index] : {
                id: '',
                name: '',
                themes: [],
                recommendedThemes: [],
                userThemes: [],
                disabledThemes: []
              });
            toggleLoader(false);
            fetchArticles(index, contactList);
        })
        .catch(err => console.log('ERROR - ', err));
    }

    const fetchArticles = (index, contactArray) => {
        setShowArticleLoader(true);
        exploreArticleRequest({
            params: {
                source: 'crmClients',
                themesList: contactArray.length>0 ? getThemeIds(index, contactArray) : []
            }
        });
    }

    const themeButtonClickHandler = (contactIndex, id) => {
        const contactListCopy = [...contactList];
        if(contactListCopy[contactIndex].disabledThemes.includes(id))
            contactListCopy[contactIndex].disabledThemes = contactListCopy[contactIndex].disabledThemes.filter(theme => theme!==id);
        else
            contactListCopy[contactIndex].disabledThemes.push(id);
        updateContactList(contactListCopy);
        setSelectedCRMContactList({...contactListCopy[contactIndex]});
        // fetchArticles(contactIndex, contactListCopy);
    }

    const contactListClickHandler = contactIndex => {
        setSelectedContactList(contactIndex);
        setSelectedCRMContactList(contactList[contactIndex]);
        handleTabIndexReset();
        fetchArticles(contactIndex, contactList)
    }
    
    const closeHandler = index => {
        toggleLoader(true);
        fetchData(index);
        toggleEditModal(false);
    }

    const getThemeIds = (contactIndex, contactListArray) => {
        let theme_ids;
        const contactListCopy = [...contactListArray];
        theme_ids = contactListCopy[contactIndex].themes.map(theme => theme.id_hash);
        // if(contactListCopy[contactIndex].disabledThemes.length>0)
        //     theme_ids = contactListCopy[contactIndex].themes.filter(o => contactListCopy[contactIndex].disabledThemes.indexOf(o.id_hash) === -1).map(theme => theme.id_hash);
        // else
        //     theme_ids = contactListCopy[contactIndex].themes.map(theme => theme.id_hash);
        return theme_ids;
    }

    // console.log('show modal', showEditModal && contactList.length>0 && selectedContactList!==null);
    return (
        <div style={{paddingRight: '9px'}}>
            <h5 style={{marginTop: '6px', marginBottom: '10px'}}>My Client Segments</h5>
            <FontAwesomeIcon 
                style={{ cursor: 'pointer', position: 'absolute', left: '10px', top: '67px' }} 
                onClick={close} 
                icon={faArrowLeft} 
            />
            {showEditModal && contactList.length>0 && selectedContactList!==null ? (
                <EditContactTopics
                    selectedContactList={contactList[selectedContactList]}
                    isModalOpen={true}
                    close={index => closeHandler(index)}
                    closeModal={() => toggleEditModal(false)}
                    themes={themeList}
                    index={selectedContactList} 
                />
            ) : null}
            <LoaderWrapper isLoading={loader} styles={{marginTop: '55px'}}>
                <ul className='contact_list_crm'>
                    {contactList.length>0 ? contactList.map((contact, contactIndex) => (
                        <li 
                            className='contact_list_item_crm' 
                            key={'contact_list_'+contactIndex} 
                            style={{
                                backgroundColor: selectedContactList === contactIndex ? 'white' : '#ececec', 
                                border: selectedContactList === contactIndex ? '1px solid #6351ed' : '0px', 
                                color: selectedContactList === contactIndex ? 'black' : '#757575'
                            }}
                        >
                            <div 
                                style={{cursor: 'pointer', width: '64%', textAlign: 'left'}} 
                                onClick={() => contactListClickHandler(contactIndex)}
                            >
                                {contact.name}
                            </div>
                            <div className='crm_btns_div'>
                                {contact.themes.length > 0 ? contact.themes.map((theme, themeIndex) => (
                                    <div 
                                        key={'theme_icon_'+themeIndex} 
                                        className='crm_theme_icon tooltip-div-crm-topic' 
                                        style={{
                                            cursor: selectedContactList === contactIndex ? 'pointer' : 'default', 
                                            position: 'relative',
                                            pointerEvents: selectedContactList === contactIndex ? 'auto' : 'none'
                                        }} 
                                        onClick={selectedContactList === contactIndex ? () => themeButtonClickHandler(contactIndex, theme.id_hash) : () => {}}
                                    >
                                        <img 
                                            style={{height: '25px', width: '25px', borderRadius: '3px'}} 
                                            className='crm_icon_image' src={theme.media_urls[0].path + theme.media_urls[0].name.system + '.' + theme.media_urls[0].ext} 
                                        />
                                        <div style={{position: 'relative', visibility: 'hidden'}}>
                                            <span className="tooltiptext-crm-topic" >{theme.name}</span>
                                        </div>
                                        <div 
                                            style={{
                                                position: 'absolute',  
                                                borderRadius: '3px', 
                                                height: '25px', 
                                                width: '25px', 
                                                top: '0', 
                                                left: '0', 
                                                backgroundColor: contact.disabledThemes.includes(theme.id_hash) ? '#a4a4a4b3' : selectedContactList === contactIndex ? 'transparent' : '#ffffff4d'
                                            }}
                                        >
                                        </div>
                                    </div>
                                )) : <div style={{width: '92%', textAlign: 'center'}}>No matched topics</div>}
                                <div 
                                    style={{color: '#6351ed', cursor: selectedContactList === contactIndex ? 'pointer' : 'default'}} 
                                    onClick={selectedContactList === contactIndex ? () => toggleEditModal(true) : () => {}} 
                                >
                                    <img style={{ marginRight: '-7px' }} src='/assets/images/Icon-metro-pencil8.svg' />_
                                </div>
                                
                            </div>
                        </li>
                    )) : <li style={{marginTop: '50px', fontSize: '12px'}}>No Contact lists available</li>}
                </ul>
            </LoaderWrapper>
        </div>
    );
}

const mapDispatchToProps = {
    exploreArticleRequest: exploreActions.api.explore.request
}

export default connect(null, mapDispatchToProps)(CRMSegment);