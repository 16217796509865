import React from 'react'
import { Link } from 'react-router-dom';
import { SidebarWrapper, SidebarUL, SidebarLI, SidebarSubMenu } from './Sidebar.styles';
import { ResponsiveImg, MioDropdown } from '../';
import { clearSeassionStorage, ROLE_TYPES } from '../../utils';

const AdvisorSidebar = ({ toggle, userName, roleType }) => {

	const [showUserMenu, setUsermenu] = React.useState(false);
	const [showSettingMenu, setSettingMenu] = React.useState(false);
	return (
		<>
		{toggle ? 
			<SidebarWrapper>
				<SidebarUL>
					<SidebarLI> 
						<Link to="/discover">

              <i className="icon dripicons-home" />
							<span>Home</span>
            </Link>
					</SidebarLI>
					<SidebarLI>
					<Link to="/portfolio">
              <i>
                <ResponsiveImg
                  src="/assets/images/portfolio-menu-icon.svg"
                  alt="portfolio"
                />
              </i>
							<span>Portfolio</span>
            </Link>
					</SidebarLI>
					<SidebarLI onClick={() => setSettingMenu(!showSettingMenu)}>
            <a href="#">
              <i className="icon dripicons-gear" />
							<span>Settings</span>
            </a>
          </SidebarLI>	
						{showSettingMenu ?
							<SidebarSubMenu>
								<li>
									<Link to="/analytics/summary">My Clout</Link>
								</li>
								<li>
									<Link to="/analytics">Impact</Link>
								</li>
								<li>
									<Link to="/channel">Partners</Link>
								</li>
							</SidebarSubMenu>
							:
							null 
						}
				  <SidebarLI onClick={() => setUsermenu(!showUserMenu)}>
            <a href="#">
              <i className="icon icon dripicons-user" />
							<span>{userName}</span>
            </a>
          </SidebarLI>
						{showUserMenu ? 
							<SidebarSubMenu>
              <li>
                <Link to="/configure">Configure</Link>
              </li>
              {ROLE_TYPES.ADVISOR_FREE === roleType && (
                <li>
                  <Link to="/subscription">Subscribe</Link>
                </li>
              )}
              <li>
                <a href="#" onClick={clearSeassionStorage}>
                  Logout
                </a>
              </li>
            </SidebarSubMenu>
						:
						null
						}
				</SidebarUL>
			</SidebarWrapper>
			:
			null
		}
		</>
  )
}

export default AdvisorSidebar;

