/* eslint-disable react-hooks/exhaustive-deps */
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { get, isEmpty, map } from 'lodash';
import React, { useEffect, useState } from 'react'
import { LoaderWrapper } from '../../../components';
import { getCRMContactList } from '../../../services/configure';
import { getMediaUrl } from '../../../utils';
import { CampaignThemeTiles, RecommendedTopicsWrapper } from './SetTopics.styles';

const CampaignRecommendTopicsTab = ({
    setObjectivesTabData,
    onAddTopics
}) => {
    const [themeData, setThemeData] = useState([]);
    const [loading, setLoading] = useState(false);
    
    useEffect(() => {
        fetchThemes();
    }, []);

    const fetchThemes = () => {
        setLoading(true);
        const selectedContactIds = !isEmpty(get(setObjectivesTabData, 'emList', [])) ? map(get(setObjectivesTabData, 'emList', []), 'id') : [];
        if(isEmpty(selectedContactIds)) {
            setLoading(false);
        } else {
            getCRMContactList().then(res => {
                const data = get(res, 'result.data', []);
                if(res.result.success && !isEmpty(data)) {
                    selectedContactIds.forEach(element => {
                        if(!isEmpty(get(data[element], 'recommended_themes', []))) {
                            setThemeData([...themeData, ...Object.values(data[element].recommended_themes.themes)]);
                        }
                    });
                    
                }
                setLoading(false);
            }).catch(err => {
                setLoading(false);
            })
        }
        
    }

    const handleAddTopic = (item) => {
        item.id = item.id_hash;
        onAddTopics(item);
    }

    return (
        <RecommendedTopicsWrapper>
            <LoaderWrapper isLoading={loading} styles={{marginTop: '7%'}}>
                {!isEmpty(themeData) ? themeData.map((item, index) => (
                    <CampaignThemeTiles>
                       <img className="tiles-image" height="25" src={getMediaUrl(item.media_urls)} alt="" />
                        <span className="tiles-title">{item.name}</span>
                        <div className="camp-tile-cover" onClick={() => handleAddTopic(item)}>
                            <FontAwesomeIcon icon={faPlus} style={{position: 'relative', left: '-2px'}}/>
                            <span className="camp-tile-cover-title">{item.name}</span>
                        </div>
                    </CampaignThemeTiles>
                )) : <div style={{textAlign: 'center', fontFamily: 'Poppins', fontSize: '13px', width: '100%', marginTop: '7%'}}>
                    No Recommended Topics for selected Email List    
                </div>}
            </LoaderWrapper>
        </RecommendedTopicsWrapper>
    )
}

export default CampaignRecommendTopicsTab;
