import { Flex } from '@rebass/grid';
import React, { useEffect, useState } from 'react';
import { Button, Input } from '../../Form';
import { Modal, ModalBody, ModalCloseButton, ModalHeader } from '../../Modal';
import { ListItemTile } from '../../';
import { updateUserThemesList } from '../../../services/configure/index'
import { LoaderWrapper } from '../../Loader';

const EditContactTopics = ({ selectedContactList, close, isModalOpen, themes, closeModal, index }) => {
    const [enabledTopics, updateEnabledTopics] = useState([]);
    const [originalTopics, setOriginalTopics] = useState([]);
    const [searchString, updateSearchString] = useState('');
    const [filteredTopics, updateFilteredTopics] = useState([]);
    const [errorMessage, setErrorMessage] = useState(false);
    const [btnLoader, toggleBtnLoader] = useState(false);

    useEffect(() => {
        updateEnabledTopics(selectedContactList.themes);
        setOriginalTopics(selectedContactList.themes);
        if(selectedContactList.themes.length === 0)
            setErrorMessage(true);
    }, []);

    const changeHandler = source => {
        console.log('VALUE - ', source.target.value);
        updateSearchString(source.target.value);
        const filteredArray = [...themes].filter(topic => topic.name.toLowerCase().includes(source.target.value.trim().toLowerCase()));
        updateFilteredTopics(filteredArray);
    }

    const addTopicHandler = topic => {
        console.log('ADDED TOPIC - ', topic);
        const topicToAdd = {...topic, id_hash: topic.id};
        delete topicToAdd.id;
        const enabledTopicsCopy = [...enabledTopics];
        const duplicateTopic = !enabledTopicsCopy.every(top => top.id_hash !== topicToAdd.id_hash);
        if(enabledTopicsCopy.length<5 && !duplicateTopic) {
            enabledTopicsCopy.push(topicToAdd);
            setErrorMessage(false);
            // console.log('COPY BEFORE UPDATE - ', enabledTopicsCopy);
            updateEnabledTopics(enabledTopicsCopy);
        }
    }

    const removeTopicHandler = index => {
        const enabledTopicsCopy = [...enabledTopics];
        enabledTopicsCopy.splice(index, 1);
        if(enabledTopicsCopy.length === 0)
            setErrorMessage(true); 
        updateEnabledTopics(enabledTopicsCopy);
    }

    const saveHandler = () => {
        const updatedThemesList = enabledTopics.map(item => item.id_hash);
        console.log('SAVE ----- ', selectedContactList, index);
        toggleBtnLoader(true);
        updateUserThemesList(updatedThemesList, selectedContactList.id)
        .then(res => {
            toggleBtnLoader(false);
            close(index);
        })
        .catch(err => console.log('ERROR --- ', err));
    }

    const isButtonDisabled = () => {
        console.log('btn disabled fired..');
        if(enabledTopics.length!==originalTopics.length)
            return false;
        let disabled = enabledTopics.every(topic => originalTopics.includes(topic));
        return disabled;
    }

    return (
        <Modal
            onRequestClose={closeModal}
            size="medium68"
            isOpen={isModalOpen}
        >
            <ModalHeader>
                <strong>{selectedContactList.name}</strong>
                <ModalCloseButton onClick={closeModal} />
            </ModalHeader>
            <ModalBody>
                <div style={{
                    backgroundColor: 'white',
                    borderTopLeftRadius: '6px',
                    borderTopRightRadius: '6px',
                    width: '90%',
                    margin: 'auto'
                }}>
                    <div style={{
                        backgroundColor: '#e4e4e5',
                        borderTopLeftRadius: '6px',
                        borderTopRightRadius: '6px',
                        padding: '10px',
                        fontSize: '12px'
                    }}>
                        <h3 style={{ color: '#43484a', margin: '0px' }}>Topics</h3>
                    </div>
                    <Flex
                        justifyContent='center'
                        padding='23px 0px'
                        alignItems='center'
                    >
                        {enabledTopics.length > 0 ? enabledTopics.map((topic, index) => (
                            <ListItemTile
                                key={topic.id}
                                id={topic.id}
                                url={topic.media_urls[0].path + topic.media_urls[0].name.system + '.' + topic.media_urls[0].ext}
                                topic={topic.name}
                                type="minus"
                                handleClick={() => removeTopicHandler(index)}
                                fontSize='9px'
                                styles={{width: '120px'}}
                            />
                        )) : null}
                    </Flex>
                </div>
                <p style={{ color: '#757575', fontSize: '11px' }}>A maximum of 5 Topics can be assigned to the list</p>
                <h3>Add More Topics</h3>
                <Input
                    placeholder="Search for a topic ('e.g.':'Technology')"
                    placeholderColor='#6351ed'
                    placeholderAlignment='center'
                    placeholderSize='12px'
                    largeInput={true}
                    onChange={e => changeHandler(e)}
                    outerWidth='90%'
                    margin='auto'
                    marginRight='auto'
                    marginBottom='0px'
                />
                <div 
                    style={{ 
                        height: '128px', 
                        width: '90%', 
                        margin: 'auto', 
                        display: 'flex', 
                        justifyContent: filteredTopics.length>0 ? 'left' : 'center', 
                        alignItems: 'center', 
                        maxWidth: '90%', 
                        overflowX: 'auto',
                        backgroundColor: searchString.length>0 ? '#fff' : '',
                        padding: '0px 8px' 
                    }}
                >
                    {searchString.length > 0 ?
                        filteredTopics.length > 0 ? (
                            <>
                                {filteredTopics.map((topic, index) => (
                                    <ListItemTile
                                        key={topic.id}
                                        id={topic.id}
                                        url={topic.media_urls[0].path + topic.media_urls[0].name.system + '.' + topic.media_urls[0].ext}
                                        topic={topic.name}
                                        type="add"
                                        handleClick={() => addTopicHandler(topic)}
                                        fontSize='9px'
                                        styles={{width: '120px'}}
                                    />
                                ))}
                            </>
                        ) : (
                            <div style={{color: '#6351ed'}}>No matching topics found...</div>
                        ) : null}
                </div>
                {errorMessage ? (
                    <div className='error-div' style={{fontSize: '10px', color: '#6351ed'}}>Choose atleast one topic to see content related to it</div>
                ) : null}
                <Flex justifyContent='center' alignItems='center' marginTop='20px'>
                    <Button 
                        padding='3px 30px' 
                        fontSize='10px' 
                        bgColor='#ececec' 
                        color='#6351ed' 
                        margin='0px 10px' 
                        styles={{ border: '1px solid #6351ed' }} 
                        onClick={closeModal}
                    >
                        Back
                    </Button>
                    <LoaderWrapper isLoading={btnLoader} styles={{}}>
                        <Button 
                            padding='4px 30px' 
                            fontSize='10px' 
                            bgColor={isButtonDisabled() || errorMessage ? '#cacaca' : '#6351ed'} 
                            color='white' 
                            margin='0px 10px' 
                            styles={{ border: '0px' }} 
                            disabled={isButtonDisabled() || errorMessage} 
                            onClick={isButtonDisabled() || errorMessage ? () => {} : saveHandler}
                        >
                            Save
                        </Button>
                    </LoaderWrapper>
                </Flex>
            </ModalBody>
        </Modal>
    );
}

export default EditContactTopics;