import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';

import Tile from '../Tile';
import {
  TileCover,
  ListItem,
  CoverList,
  CoverLink,
  CoverListItem,
  IconWrapper,
  CoverHeader,
} from './ListItemTile.styles';

const shortenTitle = (title) => {
  if (title.includes(' ')) {
    let arr = title.split(' ');
    if (arr[1].length >= 10) {
      return arr[0] + ' ' + arr[1].substr(0, 6) + '...';
    } else {
      return title;
    }
  } else {
    if (title.length >= 10) return title.substr(0, 6) + '...';
    else return title;
  }
};

const ListItemTile = ({ handleClick, url, topic, type, noCover, width, background, fontSize, styles, isMember, imageStyle }) => {
  const icon = type === 'add' ? faPlus : faMinus;
  const onClickProps = noCover
    ? {
      onClick: handleClick,
    }
    : {};
  return (
    <ListItem width={width} title={!isMember ? topic : ''} {...onClickProps} isMember={isMember}>
      {/*<ListItem width={width} title={topic} {...onClickProps}></ListItem>*/}
      <div className={isMember ? 'tooltip-msg tooltip-display' : ''} >
        <Tile imageStyle={imageStyle} src={url} alt={topic} title={shortenTitle(topic)} styles={styles} className={isMember ? 'inactive-partner' : ''} />
        {noCover ? null : (
          <TileCover onClick={handleClick}>
            <CoverList>
              <CoverLink>
                <CoverListItem>
                  {type !== 'no-icon' &&
                    <IconWrapper>
                      <FontAwesomeIcon icon={icon} />
                    </IconWrapper>
                  }
                </CoverListItem>
                <CoverListItem>
                  <CoverHeader style={{ fontSize: fontSize ? fontSize : '' }}>{shortenTitle(topic)}</CoverHeader>
                </CoverListItem>
              </CoverLink>
            </CoverList>
          </TileCover>
        )}
        {isMember && <span className='tooltip-msg-text tooltiptext'>Your admin has removed this publisher so you cannot subscribe to it.</span>
        }
      </div>
    </ListItem>
  );
};

export default ListItemTile;
