import React, { useEffect } from 'react';
import { ErrorContainer, LoaderWrapper } from '../../../components';
import { useState } from 'react';
import { updateAdvApproval } from '../../../services/configure';
import { Tooltip } from '@mui/material';
import AdvisorSettings from '../../AdvisorSettings/AdvisorSettings';
import {
  salesforceSettingsMapper,
  salesforceTogglesMapper,
} from '../../../utils';
import {
  HeadingSection,
  RadioLabel,
  BorderBottomContainer,
  RadioStyle,
  SubHeaderSection,
  TextLabel,
  SaveButton,
  TextInput,
  InfoIcon,
  ErrorMessage,
  CheckBoxContent
} from '../AdminConfigureContainer.styles';
import { get, isEmpty } from 'lodash-es';
import infoIcon from '../../../assets/icons/infoIcon.svg';
import PurpleCheckbox from '../../../componentsV2/PurpleCheckbox';
import { getUserDetail } from 'src/services/login';

export const GeneralSettings = ({
  userId,
  companyId,
  loginRequest,
  advisorApproval,
  turnkey,
  salesforceSettings,
  updateSalesforceSettings,
  socialNetworkData,
  advPartnerEmail,
  isPremiumAdvisor,
  isAdvisorEnterprise,
  bccList,
  setBccList,
  loginSetState
}) => {
  const [isRequired, setIsRequired] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [advSuccessEmail, setAdvSuccessEmail] = useState('');
  const [error, setError] = useState('');

  useEffect(() => {
    if (advisorApproval) {
      setIsRequired(true);
    }
    if (advPartnerEmail) {
      setAdvSuccessEmail(advPartnerEmail);
    }
  }, [advisorApproval, advPartnerEmail]);

  const getSalesforcePayload = () => {
    const salesforcePayload = {};
    salesforceSettings.forEach((setting, index) => {
      const salesforceToggles = {};
      Object.keys(salesforceSettings[index])
        .slice(1)
        .forEach((toggle) => {
          salesforceToggles[salesforceTogglesMapper[toggle]] =
            salesforceSettings[index][toggle].toString();
        });
      salesforcePayload[salesforceSettingsMapper[setting.label]] =
        salesforceToggles;
    });
    return salesforcePayload;
  };

  const saveHandler = () => {
    setError('');
    let msg = 'email is not valid';
    const isEmailRegEx =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,3}))$/;
    let valid = true;

    if (turnkey === 'true') {
      if (!advSuccessEmail.toLowerCase() === '') {
        valid = isEmailRegEx.test(advSuccessEmail.toLowerCase());
      }
    }

    if (valid && !isEmpty(bccList)) {
      valid = bccList
        .split(',')
        .map((em) => em.trim())
        .every((em) => isEmailRegEx.test(em.toLowerCase()));

      if (bccList.split(',').length > 3 && valid) {
        valid = false;
        msg = 'Maximum 3 bcc emails can be added';
      }
    }
    if (!valid) {
      return setError(msg);
    } else {
      const sfSettingsPayload = getSalesforcePayload();
      let payload = {
        user: JSON.stringify([
          {
            uid: userId,
            salesforce_settings: sfSettingsPayload,
            ...((turnkey === 'true' || isAdvisorEnterprise)
              ? {
                advisor_approval: isRequired,
                as_partner_email: advSuccessEmail,
                bcc_emails: bccList && !isEmpty(bccList.split(',')) ?  bccList.split(',') : []
              }
              : {})
          },
        ]),
      };
      setIsLoading(true);
      updateAdvApproval(companyId, payload)
        .then((res) => {
          if (res.result.success) {
            getUserDetail().then(res => {
              loginSetState({ data: res?.result?.data });
              setIsLoading(false);
            }).catch(err => {
              console.log(err);
            })
            
          }
        })
        .catch((err) => {
          setIsLoading(false);
          console.log(err);
        });
    }
  };

  const handleAttrChange = (checked, attr, index) => {
    const settingsArrayCopy = [...salesforceSettings];
    settingsArrayCopy[index][attr] = checked;
    updateSalesforceSettings(settingsArrayCopy);
  };

  return (
      <div style={{width: '80%', margin: '10px auto'}}>
        {turnkey === 'true' && (
            <BorderBottomContainer style={{ padding: '0 0 25px' }}>
              <HeadingSection margin="5px 0 5px">
                Advisor Approval Settings
                <Tooltip title={
                  `Is advisor approval required? If set to "Required," you will have the option
                  to require advisor approval for every piece of content. If set to "Not Required,"
                  all content will go out without advisor approval.`
                }>
                  <InfoIcon src={infoIcon} alt='info' />
                </Tooltip>
              </HeadingSection>
              <SubHeaderSection>
                View the compliance settings across all enterprise firms and advisors
              </SubHeaderSection>
            <div style={{display: 'flex', justifyContent: 'start'}} >
              <CheckBoxContent style={{ margin: '25px 22px 10px 0', width: '175px', justifyContent: 'center' }}>
                <div style={{margin: '-1px 12px 0 0'}}>
                  <PurpleCheckbox checked={isRequired} onClick={() => setIsRequired(true)} />
                </div>
                <span>Required</span>
              </CheckBoxContent>
              <CheckBoxContent style={{ margin: '25px 22px 10px 0', width: '175px', justifyContent: 'center' }}>
                <div style={{margin: '-1px 12px 0 0'}}>
                  <PurpleCheckbox checked={!isRequired} onClick={() => setIsRequired(false)} />
                </div>
                <span>Not Required</span>
              </CheckBoxContent>
            </div>
            <div style={{display: 'table', textAlign: 'left', width: '70%', marginTop: 5}}>
              <TextLabel>
                Advisor Success Partner’s Email
                <TextInput
                  paddingTop="2px"
                  type="text"
                  width="100%"
                  id="name"
                  placeholder="Email"
                  name="enterprise"
                  value={advSuccessEmail}
                  onChange={(e) => setAdvSuccessEmail(e.target.value)}
                />
              </TextLabel>
            </div>
            </BorderBottomContainer>
        )}

        {!isEmpty(get(socialNetworkData, 'sf', [])) && (
          <>
            <div style={{ marginBottom: '20px' }}>Salesforce Settings</div>
            <AdvisorSettings
              settingsArray={salesforceSettings}
              changeHandler={handleAttrChange}
            />
          </>
        )}


      <BorderBottomContainer style={{ padding: '0 0 10px'}}>
        <HeadingSection margin="5px 0 5px">BCC for all emails</HeadingSection>
        <SubHeaderSection>
          All emails sent from Clout will be BCC to this email address(es) for compliance archival
        </SubHeaderSection>
        {(turnkey === "true" || isAdvisorEnterprise) && (
          <div style={{display: 'table', margin: '15px 0', width: '70%', textAlign: 'left'}}>
            <TextLabel>Enter Email Address
              <TextInput
                paddingTop="2px"
                type="text"
                id="name"
                name="enterprise"
                value={bccList}
                width="100%"
                margin="2px 0"
                placeholder={`Type in email address(es) to be bcc'd, separated by commas (Max 3)`}
                onChange={(e) => setBccList(e.target.value)}
              />
              </TextLabel>
            {error && <ErrorMessage style={{margin: '2px 6px'}}>{error}</ErrorMessage>}
          </div>
        )}
        </BorderBottomContainer>

        <div>
          <LoaderWrapper isLoading={isLoading}>
            <SaveButton
              style={{marginTop: 40}}
              onClick={saveHandler}
              testId={'gen-settings-save'}
            >
              Save
            </SaveButton>
          </LoaderWrapper>
        </div>
      </div>
  );
};
