/* eslint-disable react-hooks/exhaustive-deps */
import { Flex } from '@rebass/grid';
import React, { useEffect } from 'react'
import { Line } from '../../components/StepperV2/Stepper.styles';
import { Button } from '../../components';
import { isEmpty } from 'lodash';
import { onImageError } from '../../utils';
import { Salutation } from '../../components/EmailTemplates/EmailTemplates.styles';
import { Content } from '../../components/EmailTemplates/EmailTemplates.styles';
import { Disclaimer } from '../../components/EmailTemplates/EmailTemplates.styles';

const SelectEmailTemplate = ({
    setToggleTemplateBtn,
    toggleTemplateBtn,
    templates,
    goBack,
    handleNext,
    blockData,
    campaignFlow,
    campaignData,
    handleSaveAndContinue,
    selectedTemplate
}) => {


    useEffect(() => {
        if (campaignFlow) {
            const templateType = templates.filter(t => t.name === campaignData?.template?.default_template);
            if(campaignData?.template?.default_template === 'no-content' || campaignData?.template?.default_template === 'no_content') {
                setToggleTemplateBtn(false);
            } else if (isEmpty(templateType) || (!isEmpty(templateType) && templateType[0]?.created_by === 'user')) {
                setToggleTemplateBtn(true);
            } else {
                setToggleTemplateBtn(false);
            }
        } else {
            const templateType = templates.filter(t => t.id === blockData?.template_id);
            if (blockData?.template_id === 1 || blockData?.template_id === 0) {
                setToggleTemplateBtn(false);
            } else if (isEmpty(templateType) || (!isEmpty(templateType) && templateType[0]?.created_by === 'user')) {
                setToggleTemplateBtn(true);
            } else {
                setToggleTemplateBtn(false);
            }
        }
    }, [campaignData])
    
    
    const handleTemplatesToggle = () => {
        setToggleTemplateBtn(!toggleTemplateBtn);
    };
    return (
        <>
            <Flex width="100%" style={{ position: 'relative' }} alignItems="center">
                <Flex width="2%">
                    <img style={{ cursor: 'pointer' }} onClick={goBack} src="/assets/images/Campaign/left-arrow-icon.svg" alt="" />
                </Flex>
                <Flex width="2%">
                    <Line style={{ top: '2px' }} />
                </Flex>
                <Flex width="78%" color="#101828" fontSize="20px">
                    Select Email Template
                </Flex>
                <Flex width="20%">
                    <Button
                        color="#fff"
                        bgColor="#6351ed"
                        borderRadius="5px"
                        padding="7px 45px"
                        margin="0px 0px 7px 10px"
                        onClick={handleSaveAndContinue}
                        testId="select-social-article"
                        disabled={!selectedTemplate && selectedTemplate !== 0}
                    >
                        Next
                    </Button>
                </Flex>
            </Flex>
            <hr style={{ width: '100%', border: '1px solid #E4E7EC' }} />
            <Flex width="100%" display="flex" flexDirection="row" m="10px 0">
                <Flex
                    display="flex"
                    justifyContent="left"
                    alignItems="center"
                    Width="28%"
                    padding="0px 0px 0px 23px"
                >
                    <Button
                        margin="0px"
                        onClick={() => {
                            console.log('clicked');
                            handleTemplatesToggle(false);
                        }}
                        bgColor={toggleTemplateBtn ? '#6453ec' : '#fff'}
                        color={toggleTemplateBtn ? '#fff' : '#000'}
                        borderColor={toggleTemplateBtn ? '#6453ec' : '#E4E7EC'}
                    >
                        My Templates
                    </Button>
                    <Button
                        margin="0px"
                        onClick={() => {
                            console.log('pressed');
                            handleTemplatesToggle(true);
                        }}
                        bgColor={!toggleTemplateBtn ? '#6453ec' : '#fff'}
                        color={!toggleTemplateBtn ? '#fff' : '#000'}
                        borderColor={!toggleTemplateBtn ? '#6453ec' : '#E4E7EC'}
                    >
                        Preset Templates
                    </Button>
                </Flex>
            </Flex>
            {toggleTemplateBtn && (
                <Flex width="100%" flexWrap="wrap">
                    {!isEmpty(templates.filter(temp => temp.name !== 'no_content' &&
                        temp.name !== 'content' &&
                        temp.owner !== 'clout')) ? templates
                            .filter(
                                (temp) =>
                                    temp.name !== 'no_content' &&
                                    temp.name !== 'content' &&
                                    temp.owner !== 'clout'
                            )
                            .sort(
                                (a, b) => new Date(b.created_time) - new Date(a.created_time)
                            ).map((temp, index) => (
                                <Flex
                                    width="19%"
                                    backgroundColor="#fff"
                                    style={{
                                        border: +selectedTemplate === temp.id ? "1px solid #6351ed" : "1px solid #E4E7EC",
                                        boxShadow: "0px 1px 2px 0px #1018281F",
                                        borderRadius: '8px',
                                        margin: '10px 10px 10px 0px',
                                        height: '240px',
                                        cursor: 'pointer'
                                    }}
                                    flexDirection="column"
                                    onClick={() => handleNext(temp)}
                                >
                                    <img loading="lazy" width="100%" height="191" src={temp.thumbnail_url} alt="" onError={onImageError} style={{ objectFit: 'contain', padding: '10px' }} />
                                    <Flex title={temp.name.length > 20 ? temp.name : ''} width="100%" padding="10px" fontSize="14px" color="#000000">
                                        {temp.name.length > 20 ? `${temp.name.substring(0, 20)}...` : temp.name}
                                    </Flex>
                                </Flex>
                            )) : (
                        <Flex width="100%" justifyContent="center">
                            No Templates Found
                        </Flex>
                    )}
                </Flex>
            )}

            {!toggleTemplateBtn && (
                <Flex width="100%" flexWrap="wrap">
                    <Flex
                        width="19%"
                        backgroundColor="#fff"
                        color="#000000"
                        fontSize="0.5rem"
                        padding="10px"
                        style={{
                            border: (campaignFlow && selectedTemplate === 'no-content') ?  "1px solid #6351ed"  : (selectedTemplate === 1 || selectedTemplate === 0) ? "1px solid #6351ed" : "1px solid #E4E7EC",
                            boxShadow: "0px 1px 2px 0px #1018281F",
                            borderRadius: '8px',
                            margin: '10px 10px 10px 0px',
                            height: '240px',
                            cursor: 'pointer'
                        }}
                        flexDirection="column"
                        onClick={() => handleNext({
                            id: 1,
                            name: 'no-content',
                            original_template: 'no-content',
                            owner: 'default'
                        })}
                    >
                        <Salutation style={{ textAlign: 'left' }}>Hi First_name,</Salutation>
                        <div style={{ textAlign: 'left' }}>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                            tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
                            veniam, quis nostrud
                        </div>
                        <Content height="80px" style={{ textAlign: 'left' }}>
                            Exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                            Duis aute irure dolor in reprehenderit in voluptate velit esse cillum
                            dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non
                            proident, sunt in culpa qui officia deserunt mollit anim id est laborum
                        </Content>
                        <div style={{ textAlign: 'left' }}>Signature</div>
                        <Disclaimer style={{ textAlign: 'left' }}>Disclaimer</Disclaimer>

                    </Flex>
                    {templates.filter(temp => temp.owner === 'clout').map((temp) => (
                        <Flex
                            width="19%"
                            backgroundColor="#fff"
                            style={{
                                border: +selectedTemplate === temp.id ? "1px solid #6351ed" : "1px solid #E4E7EC",
                                boxShadow: "0px 1px 2px 0px #1018281F",
                                borderRadius: '8px',
                                margin: '10px 10px 10px 0px',
                                height: '240px',
                                cursor: 'pointer'
                            }}
                            flexDirection="column"
                            onClick={() => handleNext(temp)}
                        >
                            <img loading="lazy" width="100%" height="191" src={temp.thumbnail_url} alt="" onError={onImageError} style={{ objectFit: 'contain', padding: '10px' }} />
                            <Flex title={temp.name.length > 20 ? temp.name : ''} width="100%" padding="10px" fontSize="14px" color="#000000">
                                {temp.name.length > 20 ? `${temp.name.substring(0, 20)}...` : temp.name}
                            </Flex>
                        </Flex>
                    ))}
                </Flex>
            )}
            
        </>
    )
}

export default SelectEmailTemplate;