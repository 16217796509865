import React from 'react';
import { withRouter, Link } from 'react-router-dom';
import { ResponsiveImg, Button } from '../';

import { StyledArticle } from './DashboardArticle.styles';

const FreeArticle = () => {
  return (
    <StyledArticle>
      <Link to="/subscription">
        <div>
          <ResponsiveImg src="/assets/images/upgrade.jpg" />
        </div>
        <h4 className="free-article-text">
          Use Your Topics feature to curate content according to personalized
          preferences.
        </h4>
        <div className="free-article-buttonwrapper">
          <Button isSecondary>Subscribe</Button>
        </div>
      </Link>
    </StyledArticle>
  );
};

export default withRouter(FreeArticle);
