import { Flex } from '@rebass/grid';
import styled from 'styled-components';

export const CongratsContainer = styled(Flex)`
  border: 1px solid #cfd9e4;
  border-radius: 4px;
  justify-content: ${({ justifyContent }) => justifyContent || 'center'};
  align-items: ${({ alignItems }) => alignItems || 'center'};
  ${'' /* width: 80%; */}
  padding: ${({ padding }) => padding || '9px'};
  height: ${({ height }) => height || '470px'};
  margin-top: ${({ marginTop }) => marginTop || '35px'};
  margin-bottom: ${({ marginBottom }) => marginBottom || '35px'};
  background-color: #f6f9fb;
  display: flex;
  flex-direction: ${({ flexDirection }) => flexDirection || 'column'};
  overflow-x: hidden;
  overflow-y: auto;
  height: 50%;
  padding-top: 40px;
  width: 65%;
  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background: #e9f6e6 !important;
  }

  &::-webkit-scrollbar-thumb {
    background: #bccdd4 !important;
  }
  ${'' /* margin-bottom: ${({ mb }) => mb || '25px'}; */}
`;

export const ContainerSubtitle = styled(Flex)`
  color: black;
  font-size: 16px;
  padding-bottom: 40px;
`;