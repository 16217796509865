import * as React from "react";

function AirplaneTakeoff(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 57 56" fill="none" {...props}>
      <path
        d="M15.5 39h18"
        stroke="currentColor"
        strokeWidth={2.133}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.613 29.55l4.712 4.412a4 4 0 004.75.538L43 23.5l-2.325-2.85a4 4 0 00-5.125-.912L30 23l-7.5-2.5-2.062.887a1 1 0 00-.275 1.663L24 26.5l-3.5 2L17 27l-2.1.9a1 1 0 00-.287 1.65v0z"
        stroke="currentColor"
        strokeWidth={2.667}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

const MemoAirplaneTakeoff = React.memo(AirplaneTakeoff);
export default MemoAirplaneTakeoff;
