import { Flex } from '@rebass/grid';
import { isEmpty } from 'lodash';
import React from 'react';
import { Button } from '../../../../../components';
import { FilterChips } from '../../../../DripCampaignContainer/DripDashboardTable/DripDashboardTable.styles';

const AnalyticsFilterButton = ({
    toggleFilterModal,
    filterDisabled,
    analyticsSetState,
    contactParam,
    campaignsParam,
    selectedRepType,
    analyticsRequest,
    leadType
}) => {

    const handleFilterDeselect = option => {
        const repType = selectedRepType
        analyticsSetState({
            filterParams: { 
                contactParam: option === 'email' || option === 'clear' ? [] : contactParam, 
                campaignsParam: option === 'campaign' || option === 'clear' ? [] : campaignsParam 
            }
        })
        analyticsRequest({
        params: {
            repType,
            ...(repType === 'leadgen_data' && {leadType: leadType})
        },
        });
    }

    return (
        <Flex
            width='100%'
            justifyContent='flex-end'
            alignItems='center'
            marginTop='14px'
        >
            {!filterDisabled && (
                <Flex justifyContent='center' alignItems='center'>
                    {/* {[1, 2].map(ele => {
                        if (!isEmpty(contactParam) && ele === 1) {
                            return (
                                <FilterChips>
                                    Email
                                    <span
                                        class="closebtn"
                                        onClick={() => handleFilterDeselect('email')}
                                    >
                                        &times;
                                    </span>
                                </FilterChips>
                            )
                        }
                        else if (!isEmpty(campaignsParam) && ele === 2) {
                            return (
                                <FilterChips>
                                    Campaign
                                    <span
                                        class="closebtn"
                                        onClick={() => handleFilterDeselect('campaign')}
                                    >
                                        &times;
                                    </span>
                                </FilterChips>
                            )
                        }
                    })} */}
                    <FilterChips
                        onClick={() => handleFilterDeselect('clear')}
                        bgColor="#6250ed"
                        color="#fff"
                        style={{ cursor: 'pointer' }}
                    >
                        Clear All
                    </FilterChips>
                </Flex>
            )}
            <Button
                onClick={() => {
                    toggleFilterModal(true);
                }}
                borderRadius="5px"
                bgColor="#fff"
                color="#708892"
                borderColor={filterDisabled ? "#708892" : '#6351ed'}
                margin='0px 5px'
            // position="absolute !important"
            // right="7% !important"
            >
                <div
                    style={{
                        alignItems: 'center',
                        flexDirection: 'row',
                        display: 'flex',
                        justifyContent: 'space-between',
                        paddingLeft: '40px',
                        paddingRight: '40px'
                    }}
                >
                    <img
                        src={`/assets/images/${filterDisabled ? 'campaign-filter-disabled' : 'campaign-filter'}.svg`}
                        alt=""
                        style={{ marginRight: '10px' }}
                    />
                    <span style={{color: filterDisabled ? '#708892' : '#6351ed'}}>Filter</span>
                </div>
            </Button>
        </Flex>
    )
}

export default AnalyticsFilterButton;