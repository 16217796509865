export const transformData = (data) => {
  const image = data?.media_url?.[0];
  return {
    id: data?.id ?? '',
    platforms: data?.platforms ?? {
      is_email: false,
      is_facebook: false,
      is_linkedin: false,
      is_twitter: false,
    },
    campaignName: data?.name ?? '-',
    createdDate: data?.created_date ?? '-',
    mediaURL: image ? image?.path + image?.name?.system + '.' + image?.ext : '',
    idHash: data?.id_hash ?? '',
    automatedCampaignId: data?.ac_id ?? null,
  };
};
