/* eslint-disable react-hooks/exhaustive-deps */
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Flex, Box, Grid } from '@rebass/grid';
import { isEmpty, uniqBy, map } from 'lodash';
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Button,
  ButtonNoBorderBackground,
  Input,
  LoaderWrapper,
  ResponsiveImg,
} from '../../../components';
import { updateNewsletter } from '../../../services/newsletter';
import { themesData } from '../../../utils';
import { CaretIcon } from '../../ConfigureContainer/EmailList/EmailList.styles';
import EmailListAdded from '../EmailListAdded/EmailListAdded';
import {
  CaretUpIcon,
  CCInput,
  DropdownSection,
  NewsletterCheckBoxDiv,
  TooltipMode,
} from './Recipients.styles';

const Recipients = ({
  setSelectedIndex,
  contactGroups,
  setShowTabs,
  newsletterSetState,
  isEditMode,
  selectedContacts,
  ccContacts,
  newsletterId,
  setShowOutbox,
  newsletterOutboxRequest,
  outBoxTabSource,
}) => {
  const [showList, setShowList] = useState(false);
  const [showSelectedList, setShowSelectedList] = useState(true);
  const [selectedContactGroups, setSelectedContactGroups] = useState([]);
  const [headerOptions, setHeaderOptions] = useState({
    custom: false,
    hubspot: false,
    salesforce: false,
    wealthbox: false,
    redtail: false,
  });
  const [loading, setLoading] = useState(false);
  const [ccList, setCCList] = useState('');
  const history = useHistory();

  useEffect(() => {
    if (!isEditMode && !isEmpty(selectedContacts)) {
      setSelectedContactGroups([...selectedContacts]);
    } else if (isEditMode && !isEmpty(selectedContacts)) {
      setSelectedContactGroups([...selectedContacts]);
    }
  }, []);

  useEffect(() => {
    if (ccContacts.length > 0) {
      console.log('inside else if-----');
      setCCList(ccContacts.join(', '));
    }
  }, [ccContacts]);

  const handleDropdownClick = () => {
    if(!isEmpty(contactGroups)) {
      setShowList(!showList);
      setShowSelectedList(!showSelectedList);
    }
  };

  const handleBackButtonClick = () => {
    newsletterSetState({ ccContacts: [] });
    if (outBoxTabSource) {
      history.push(`/newsletter?type=dashboard&tab=${outBoxTabSource}`);
    } else {
      history.push('/newsletter?type=create');
    }
  };

  const handleCheckBoxClick = (item, type) => {
    let tempContacts = selectedContactGroups;

    if (isEmpty(selectedContactGroups)) {
      setSelectedContactGroups([...selectedContactGroups, item]);
    } else if (
      selectedContactGroups.filter((s) => s.id === item.id).length > 0
    ) {
      let contacts = selectedContactGroups.filter((s) => s.id !== item.id);
      setSelectedContactGroups([...contacts]);
    } else {
      setSelectedContactGroups([...selectedContactGroups, item]);
    }

    if (
      tempContacts.filter((t) => t.id === item.id).length === 0 &&
      tempContacts.filter((t) => t.type === type).length + 1 ===
        contactGroups.filter((c) => c.type === type).length
    ) {
      setHeaderOptions({ ...headerOptions, [type]: true });
    } else {
      setHeaderOptions({ ...headerOptions, [type]: false });
    }
  };

  const handleHeaderCheckboxClick = (type) => {
    if (headerOptions[type]) {
      let contacts = selectedContactGroups.filter((s) => s.type !== type);
      setSelectedContactGroups([...contacts]);
      setHeaderOptions({ ...headerOptions, [type]: false });
    } else {
      let contacts = contactGroups.filter((s) => s.type === type);
      setSelectedContactGroups(
        uniqBy([...selectedContactGroups, ...contacts], 'id')
      );
      setHeaderOptions({ ...headerOptions, [type]: true });
    }
  };

  const handleSaveClick = () => {
    setLoading(true);
    let formattedSelectedContactGroups = map(selectedContactGroups, 'id');
    const ccRecipients = ccList.split(',').map((email) => email.trim());
    let pattern = new RegExp(
      /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
    );
    const validCCRecipients = ccRecipients.filter((recipient) =>
      pattern.test(recipient)
    );
    let params = {
      contactlists: JSON.stringify(formattedSelectedContactGroups),
    };
    if (validCCRecipients.length > 0)
      params.cc_email_ids = JSON.stringify(validCCRecipients);

    updateNewsletter({ id: newsletterId, params: params })
      .then((res) => {
        if (res.result.success) {
          setLoading(false);
          newsletterSetState({
            selectedContacts: selectedContactGroups,
            ccContacts: validCCRecipients,
          });
          setSelectedIndex(1);
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const handleNextClick = () => {
    const ccRecipients = ccList.split(',').map((email) => email.trim());
    let pattern = new RegExp(
      /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
    );
    const validCCRecipients = ccRecipients.filter((recipient) =>
      pattern.test(recipient)
    );

    newsletterSetState({
      selectedContacts: selectedContactGroups,
      ccContacts: validCCRecipients,
    });

    if (outBoxTabSource === 'sent') {
      setSelectedIndex(4);
    } else {
      setSelectedIndex(1);
    }
  };

  const getCustomList = () => {
    let data = contactGroups.filter((c) => c.type === 'custom');

    return (
      !isEmpty(data) && (
        <Flex width="100%" flexDirection="column" padding="10px 0px">
          <NewsletterCheckBoxDiv fontWeight="bold">
            <label class="containerhead">
              {/* <input
                type="checkbox"
                checked={headerOptions.custom}
                onChange={() => handleHeaderCheckboxClick('custom')}
              />
              <span class="checkmark"></span> */}
              My Lists
            </label>
          </NewsletterCheckBoxDiv>
          {!isEmpty(data) &&
            data.map((item, index) => (
              <NewsletterCheckBoxDiv>
                <label class="container">
                  <input
                    type="checkbox"
                    checked={
                      selectedContactGroups.filter((s) => s.id === item.id)
                        .length > 0
                    }
                    onChange={() => handleCheckBoxClick(item, 'custom')}
                  />
                  <span class="checkmark"></span>
                  {item.name}
                </label>
              </NewsletterCheckBoxDiv>
            ))}
        </Flex>
      )
    );
  };

  const getHubspotList = () => {
    let data = contactGroups.filter((c) => c.type === 'hubspot');

    return (
      !isEmpty(data) && (
        <Flex width="100%" flexDirection="column" padding="10px 0px">
          <NewsletterCheckBoxDiv fontWeight="bold">
            <label class="containerhead">
              {/* <input
                type="checkbox"
                checked={headerOptions.hubspot}
                onChange={() => handleHeaderCheckboxClick('hubspot')}
              />
              <span class="checkmark"></span> */}
              Hubspot
            </label>
          </NewsletterCheckBoxDiv>
          {!isEmpty(data) &&
            data.map((item, index) => (
              <NewsletterCheckBoxDiv>
                <label class="container">
                  <input
                    type="checkbox"
                    checked={
                      selectedContactGroups.filter((s) => s.id === item.id)
                        .length > 0
                    }
                    onChange={() => handleCheckBoxClick(item, 'hubspot')}
                  />
                  <span class="checkmark"></span>
                  {item.name}
                </label>
              </NewsletterCheckBoxDiv>
            ))}
        </Flex>
      )
    );
  };

  const getSalesforceList = () => {
    let data = contactGroups.filter((c) => c.type === 'salesforce');

    return (
      !isEmpty(data) && (
        <Flex width="100%" flexDirection="column" padding="10px 0px">
          <NewsletterCheckBoxDiv fontWeight="bold">
            <label class="containerhead">
              {/* <input
                type="checkbox"
                checked={headerOptions.salesforce}
                onChange={() => handleHeaderCheckboxClick('salesforce')}
              />
              <span class="checkmark"></span> */}
              Salesforce
            </label>
          </NewsletterCheckBoxDiv>
          {!isEmpty(data) &&
            data.map((item, index) => (
              <NewsletterCheckBoxDiv>
                <label class="container">
                  <input
                    type="checkbox"
                    checked={
                      selectedContactGroups.filter((s) => s.id === item.id)
                        .length > 0
                    }
                    onChange={() => handleCheckBoxClick(item, 'salesforce')}
                  />
                  <span class="checkmark"></span>
                  {item.name}
                </label>
              </NewsletterCheckBoxDiv>
            ))}
        </Flex>
      )
    );
  };

  const getWealthboxList = () => {
    let data = contactGroups.filter((c) => c.type === 'wealthbox');

    return (
      !isEmpty(data) && (
        <Flex width="100%" flexDirection="column" padding="10px 0px">
          <NewsletterCheckBoxDiv fontWeight="bold">
            <label class="containerhead">
              {/* <input
                type="checkbox"
                checked={headerOptions.wealthbox}
                onChange={() => handleHeaderCheckboxClick('wealthbox')}
              />
              <span class="checkmark"></span> */}
              Wealthbox
            </label>
          </NewsletterCheckBoxDiv>
          {!isEmpty(data) &&
            data.map((item, index) => (
              <NewsletterCheckBoxDiv>
                <label class="container">
                  <input
                    type="checkbox"
                    checked={
                      selectedContactGroups.filter((s) => s.id === item.id)
                        .length > 0
                    }
                    onChange={() => handleCheckBoxClick(item, 'wealthbox')}
                  />
                  <span class="checkmark"></span>
                  {item.name}
                </label>
              </NewsletterCheckBoxDiv>
            ))}
        </Flex>
      )
    );
  };

  const getRedtailList = () => {
    let data = contactGroups.filter((c) => c.type === 'redtail');

    return (
      !isEmpty(data) && (
        <Flex width="100%" flexDirection="column" padding="10px 0px">
          <NewsletterCheckBoxDiv fontWeight="bold">
            <label class="containerhead">
              {/* <input
                type="checkbox"
                checked={headerOptions.redtail}
                onChange={() => handleHeaderCheckboxClick('redtail')}
              />
              <span class="checkmark"></span> */}
              Redtail
            </label>
          </NewsletterCheckBoxDiv>
          {!isEmpty(data) &&
            data.map((item, index) => (
              <NewsletterCheckBoxDiv>
                <label class="container">
                  <input
                    type="checkbox"
                    checked={
                      selectedContactGroups.filter((s) => s.id === item.id)
                        .length > 0
                    }
                    onChange={() => handleCheckBoxClick(item, 'redtail')}
                  />
                  <span class="checkmark"></span>
                  {item.name}
                </label>
              </NewsletterCheckBoxDiv>
            ))}
        </Flex>
      )
    );
  };

  return (
    <Flex width="100%" padding="50px 30px 0px" flexDirection="column">
      <Flex
        width="100%"
        justifyContent="flex-start"
        pt="5px"
        flexDirection="row"
      >
        <Flex
          // width="100%"
          fontSize="14px"
          color={themesData.charcoalGrey}
          style={{ fontWeight: 600 }}
        >
          Select Recipients*
        </Flex>
        <TooltipMode width="550px" center>
          <div class="tooltip">
            <ResponsiveImg
              src="/assets/images/information-grey.svg"
              // title="Choose which of your contact list(s) to send the newsletter to. The list(s) you select will help Clout generate “Recommended Contents” to send to them in the next step. "
              style={{
                height: '57%',
                paddingLeft: '2px',
                paddingBottom: '1px',
              }}
            />
            <span class="tooltiptext tooltip-right">
              Choose which of your contact list(s) to send the newsletter to.
              The list(s) you select will help Clout generate “Recommended
              Content” to send to them in the next step.
            </span>
          </div>
        </TooltipMode>
      </Flex>
      <Flex width="100%">
        <Button
          bgColor="#eeeeee"
          color="#757575"
          styles={{ border: 'none', textAlign: 'left' }}
          margin="10px 0px 0px 0px"
          height="39px"
          width="100%"
          onClick={handleDropdownClick}
        >
          <span>{!isEmpty(contactGroups) ? 'Select Email List' : 'No Contact List'}</span>
          {showList ? (
            <CaretUpIcon marginTop="7px" />
          ) : (
            <CaretIcon marginTop="3px" />
          )}
        </Button>
      </Flex>
      {showList && (
        <DropdownSection>
          <Flex flexWrap="wrap" mx={-2}>
            {getCustomList() && (
              <Box px={2} py={2} width={1 / 3}>
                {getCustomList()}
              </Box>
            )}
            {getHubspotList() && (
              <Box px={2} py={2} width={1 / 3}>
                {getHubspotList()}
              </Box>
            )}
            {getSalesforceList() && (
              <Box px={2} py={2} width={1 / 3}>
                {getSalesforceList()}
              </Box>
            )}
            {getWealthboxList() && (
              <Box px={2} py={2} width={1 / 3}>
                {getWealthboxList()}
              </Box>
            )}
            {getRedtailList() && (
              <Box px={2} py={2} width={1 / 3}>
                {getRedtailList()}
              </Box>
            )}
          </Flex>
        </DropdownSection>
      )}

      <Flex
        width="100%"
        justifyContent="flex-start"
        pt="25px"
        flexDirection="row"
      >
        <Flex
          // width="100%"
          fontSize="14px"
          color={themesData.charcoalGrey}
          style={{ fontWeight: 600 }}
        >
          Cc (optional)
        </Flex>
        <TooltipMode width="315px">
          <div class="tooltip">
            <ResponsiveImg
              src="/assets/images/information-grey.svg"
              // title="Choose which of your contact list(s) to send the newsletter to. The list(s) you select will help Clout generate “Recommended Contents” to send to them in the next step. "
              style={{
                height: '57%',
                paddingLeft: '2px',
                paddingBottom: '1px',
              }}
            />
            <span class="tooltiptext tooltip-right">
              You can cc additional email address(es) on the newsletter.
            </span>
          </div>
        </TooltipMode>
      </Flex>
      <Flex
        width="100%"
        flexDirection="column"
        fontSize="14px"
        style={{ fontWeight: 600 }}
        color={themesData.charcoalGrey}
      >
        {console.log('ccList - ', ccList)}
        <CCInput
          value={ccList}
          spellCheck="false"
          id="cc_field"
          name="cc_field"
          onChange={(e) => setCCList(e.target.value)}
          placeholder="Type in email address(es) to be cc'ed, separated by commas"
        />
      </Flex>

      {showSelectedList && isEmpty(selectedContactGroups) && (
        <div style={{ height: '255px' }}></div>
      )}

      {showSelectedList && !isEmpty(selectedContactGroups) && (
        <Flex
          width="100%"
          pt="25px"
          flexDirection="column"
          fontSize="14px"
          style={{ fontWeight: 600 }}
          color={themesData.charcoalGrey}
        >
          <div style={{ textAlign: 'left' }}>Email Lists Added</div>

          <EmailListAdded
            contactGroups={contactGroups}
            selectedContactGroups={selectedContactGroups}
            setSelectedContactGroups={setSelectedContactGroups}
            setHeaderOptions={setHeaderOptions}
            headerOptions={headerOptions}
          />
        </Flex>
      )}

      <Flex width="100%" justifyContent="flex-end" fontSize="12px" pt="10px">
        *Required
      </Flex>

      <Flex
        width="100%"
        alignSelf="flex-end"
        justifyContent="space-between"
        pt="10px"
        pb="10px"
      >
        <LoaderWrapper isLoading={loading}>
          <ButtonNoBorderBackground
            padding="5px 0px 0px"
            onClick={handleBackButtonClick}
          >
            <FontAwesomeIcon icon={faAngleLeft} />
            &nbsp; Back
          </ButtonNoBorderBackground>

          <Button
            bgColor={isEmpty(selectedContactGroups) ? '#AFAFAF' : ''}
            disabled={isEmpty(selectedContactGroups)}
            height="36px"
            width="146px"
            margin="10px 0px 0px"
            onClick={handleNextClick}
          >
            Next
          </Button>

          {outBoxTabSource !== 'sent' ? (
            <ButtonNoBorderBackground
              disabled={isEmpty(selectedContactGroups)}
              padding="5px 0px 0px"
              onClick={handleSaveClick}
            >
              Save
            </ButtonNoBorderBackground>
          ) : (
            <div style={{ width: '10%' }}></div>
          )}
        </LoaderWrapper>
      </Flex>
    </Flex>
  );
};

export default Recipients;
