import React from 'react'
import ComplianceRejectContainer from '../../containers/ComplianceRejectContainer/ComplianceRejectContainer';
import withContentLayout from '../../hoc/withContentLayout';

const ComplianceRejectContainerLayout = () => {
    return (
        <ComplianceRejectContainer />
    )
}

export default withContentLayout(ComplianceRejectContainerLayout);
