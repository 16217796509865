/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import { Button, LoaderWrapper } from '../';
import { getDayMonthYear } from '../../utils';
import { isEmpty } from 'lodash';

import { MioDatePickerWrapper } from './MioDatePicker.styles';
import { Flex } from '@rebass/grid';

const generateNumerArray = (start = 0, end) => {
  const arr = [];
  for (let i = start; i < end; i++) {
    arr.push(i);
  }
  return arr;
};

const newStyle = {
  backgroundColor: '#ffffff',
  color: '#6351ed',
};

const MioDatePicker = ({
  customInput,
  minDate,
  isOpen,
  onDateClose,
  onSubmit,
  type,
  popperModifiers,
  popperPlacement,
  fromMyClients,
  scheduleValue,
  isFromScheduledTab,
  isFromNewsletter,
  isFromCampaign,
  excludeDates,
  filterDate,
  timeZone,
  maxDate,
  loading,
  isFromAC
}) => {
  console.log('timeZone', timeZone);
  const currentDate = !isEmpty(timeZone)
    ? new Date(new Date().toLocaleString('en-US', { timeZone: timeZone }))
    : new Date();
  const currentHour = currentDate.getHours();
  console.log(currentHour);
  const currentMin = currentDate.getMinutes();
  const [selectedDate, setStartDate] = useState(isFromAC && scheduleValue ? new Date( scheduleValue.getTime() + Math.abs(scheduleValue.getTimezoneOffset()*60000)) : currentDate);
  const [startHours, setStartHour] = useState(currentHour);
  const [startMins, setStartMinute] = useState(currentMin);
  const [hours, setHour] = useState(currentHour);
  const [mins, setMinute] = useState(currentMin);
  // console.log(popperPlacement);
  // console.log(type);
  const onScheduleClick = () => {
    const [selectedMonth, selectedDay, selectedYear] =
      getDayMonthYear(selectedDate);
    const checkHours = hours.toString().length === 1 ? `0${hours}` : hours;
    const checkMin = mins.toString().length === 1 ? `0${mins}` : mins;
    onSubmit(
      `${selectedYear}-${selectedMonth}-${selectedDay}T${checkHours}:${checkMin}:00`
    );
  };

  const onCampaignSubmit = () => {
    onSubmit(selectedDate);
  };

  const onDateChange = (date) => {
    console.log(date);
    setStartDate(date);
  };

  const onHourChange = (e) => {
    const value = e.target.value;
    setHour(value);
  };

  const onMinChange = (e) => {
    const value = e.target.value;
    setMinute(value);
  };

  useEffect(() => {
    if ((isFromScheduledTab || isFromNewsletter || isFromAC) && !isEmpty(scheduleValue)) {
      console.log('scheduleValue', scheduleValue);
      console.log(new Date(scheduleValue));
      setStartDate(new Date(scheduleValue));
      setStartHour(new Date(scheduleValue).getHours());
      setMinute(new Date(scheduleValue).getMinutes());
      setStartMinute(new Date(scheduleValue).getMinutes());
      setHour(new Date(scheduleValue).getHours());
    }
  }, [scheduleValue]);

  useEffect(() => {
    const [currentMonth, currentDay, currentYear] =
      getDayMonthYear(currentDate);
    const [selectedMonth, selectedDay, selectedYear] =
      getDayMonthYear(selectedDate);
    if (
      new Date(`${currentYear}-${currentMonth}-${currentDay}`) <
      new Date(`${selectedYear}-${selectedMonth}-${selectedDay}`)
    ) {
      setStartHour(0);
      setStartMinute(0);
    } else if (
      new Date(`${currentYear}-${currentMonth}-${currentDay}`).getTime() ===
      new Date(`${selectedYear}-${selectedMonth}-${selectedDay}`).getTime()
    ) {
      if (currentHour < hours) {
        setStartMinute(0);
        setStartHour(currentHour);
      } else {
        setStartHour(currentHour);
        setStartMinute(currentMin);
        setMinute(currentMin);
        setHour(currentHour);
      }
    } else {
      setStartHour(currentHour);
      setStartMinute(currentMin);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDate, hours]);
  console.log('currentDate', currentDate);
  console.log('selectedDate', selectedDate)
  return (
    <DatePicker
      open={isOpen}
      selected={selectedDate}
      onChange={onDateChange}
      shouldCloseOnSelect={false}
      onClickOutside={onDateClose}
      onCalendarClose={onDateClose}
      customInput={customInput}
      showMonthDropdown={true}
      showYearDropdown={true}
      dropdownMode="select"
      filterDate={filterDate || null}
      popperPlacement={popperPlacement || 'top'}
      minDate={
        !isEmpty(timeZone)
          ? new Date(new Date().toLocaleString('en-US', { timeZone: timeZone }))
          : new Date()
      }
      maxDate={maxDate || null}
      // adjustDateOnChange
      excludeDates={excludeDates}
      popperModifiers={
        type === 'resheduled'
          ? {
              preventOverflow: {
                enabled: false,
              },
            }
          : {
              ...popperModifiers,
              computeStyle: { gpuAcceleration: false },
            }
      }
      calendarContainer={({ children }) => (
        <MioDatePickerWrapper type={type} fromMyClients={fromMyClients}>
          <div>
            <div className="date-picker-container">{children}</div>
            {!isFromCampaign && (
              <div>
                <select id="time-hr" onChange={onHourChange} value={hours}>
                  {generateNumerArray(startHours, 24).map((hour) => (
                    <option value={hour} selected={hours === hour}>
                      {hour}
                    </option>
                  ))}
                </select>
                <select id="time-min" onChange={onMinChange} value={mins}>
                  {generateNumerArray(startMins, 60).map((min) => (
                    <option value={min} selected={mins === min}>
                      {min}
                    </option>
                  ))}
                </select>
              </div>
            )}
            {!isFromCampaign && (
              <div
                style={{
                  display: 'flex',
                  justifyContent:
                    type === 'resheduled' ? 'space-around' : 'center',
                  padding: type === 'resheduled' ? '0px 35px' : '',
                }}
              >
                {type === 'resheduled' && (
                  <Button
                    width="80px"
                    styles={newStyle}
                    margin={type === 'resheduled' ? '5px 10px 0 0' : ''}
                    onClick={onDateClose}
                    className="schedule-button"
                  >
                    Cancel
                  </Button>
                )}
                <LoaderWrapper isLoading={loading}>
                  <Button
                    width="80px"
                    color="#6351ed"
                    margin={
                      type === 'resheduled'
                        ? '5px 0 0 0'
                        : type === 'add-content'
                          ? '5px 0 0 0'
                          : ''
                    }
                    onClick={onScheduleClick}
                    className="schedule-button"
                  >
                    Schedule
                  </Button>
                </LoaderWrapper>

              </div>
            )}
            {isFromCampaign && (
              <Flex justifyContent="center" width="100%">
                <Button
                  width="80px"
                  margin="5px 0px 0px"
                  onClick={onCampaignSubmit}
                >
                  Save
                </Button>
              </Flex>
            )}
          </div>
        </MioDatePickerWrapper>
      )}
    />
  );
};

export default MioDatePicker;
