/* eslint-disable react-hooks/exhaustive-deps */
import { Flex } from '@rebass/grid';
import { isEmpty, get } from 'lodash';
import React, { useState, useEffect } from 'react';
import { NewsletterArticle } from '../../NewsletterContainer/NewsletterArticle';
import {
  ArticlesContainer,
  LandingPageCard,
  LandingPageCardWrapper,
  LandingPageContentCard,
  LandingPageTooltip,
} from '../../DripCampaignContainerV2/AddContent/AddContent.styles';
import {
  Modal,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Button,
  LoaderWrapper,
  ResponsiveImg,
  DripIconButton,
  MioDatePicker,
} from '../../../components';
import CampaignCard from './CampaignCard';
import {
  ContentModalTab,
  DashedHorizontalSeparator,
  LineHorizontalSeparator,
} from './PlanCampaign.styles';
import { connect } from 'react-redux';
import { NewsletterArticleWrapper } from '../../NewsletterContainer/NewsletterArticle/NewsletterArticle.styles';
import { getChannelId, getRoleType } from '../../../redux/selector';
import {
  ColorPallete,
  convertDateToRequiredFormat,
  getMyContentArticles,
  ROLE_TYPES,
  themesData,
  timeZoneAbbreviated,
  Typography,
} from '../../../utils';
import { actions as campaignActions } from '../../../redux/modules/campaign.module';
import {
  createUpdatePageSettings,
  getLandingPageSettings,
  getLandingPages,
} from '../../../services/bee-editor';
import Checkbox from 'src/componentsV2/Checkbox';
import {
  DateTimeText,
  DateTimeWrapper,
} from 'src/containers/DripCampaignContainerV2/Preview&Schedule/PreviewAndSchedule.styles';
import moment from 'moment';
import { StyledTextInput } from 'src/containers/AutomatedCampaigns/styles';

const SelectContentModal = ({
  dayArticles,
  suggestedArticles,
  showModal,
  toggleModal,
  week,
  day,
  dragHandler,
  setDraggedArticle,
  bookmarkArticles,
  recommendedArticles,
  myContentArticles,
  selectedContent,
  setSelectedContent,
  themeNames,
  isEnterpriseAdvisor,
  isEnterpriseAdmin,
  isPremiumAdvisor,
  myContentPaginationData,
  myContentPendingQueue,
  campaignSetState,
  channelId,
  acSelectedLandingPage,
  acflow,
  userData,
  id,
  campaignId,
}) => {
  const [selectedContentTab, setSelectedContentTab] = useState(0);
  const [newsletterArticles, setNewsletterArticles] = useState([]);
  const [isSelectContent, setIsSelectContent] = useState(false);
  const [selectedArticles, setSelectedArticles] = useState(
    selectedContent || []
  );
  const [curFlow, setCurFlow] = useState('socialOnly');
  const emptyCardArr = ['0', '1'];
  const [loadMoreLoader, toggleLoadMoreLoader] = useState(false);
  const [landingPageContent, setLandingPageContent] = useState([]);
  const [selectedLandingPage, setSelectedLandingPage] = useState();
  const [landingPageLoader, setLandingPageLoader] = useState(false);
  const [showSettings, setShowSettings] = useState(false);
  const [showTime, setShowTime] = useState(false);
  const [showCount, setShowCount] = useState(false);
  const [count, setCount] = useState(0);
  const [eventDate, setEventDate] = useState();
  const [settingsLoader, setSettingsLoader] = useState(false);
  const [isDatepickerOpen, setIsDatepickerOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  console.log('selected articles ----', selectedArticles);

  useEffect(() => {
    if (selectedContentTab === 0) {
      // setNewsletterArticles([]);
      setNewsletterArticles(bookmarkArticles);
    } else if (selectedContentTab === 1) {
      setNewsletterArticles(recommendedArticles);
    } else if (selectedContentTab === 2) {
      setNewsletterArticles(myContentArticles);
    }
  }, [selectedContentTab, myContentArticles]);

  useEffect(() => {
    console.log(acflow);
    if (acflow) {
      fetchLandingPages();
    }
  }, []);

  const handleValidation = () => {
    if (!showTime && !showCount) {
      return false;
    }
    if (showTime) {
      if (eventDate) {
        return false;
      } else {
        return true;
      }
    }

    if (showCount) {
      if (count && count > 0 && count < 5001) {
        return false;
      } else {
        return true;
      }
    }
  };

  const handleBucketArticleClick = (item) => {
    console.log('BUCKET ARTICLE CLICKED -', item);
    if (isEmpty(selectedArticles)) {
      item.order = 1;
      setSelectedArticles([item]);
      setIsSelectContent(true);
    } else if (selectedArticles[0].id === item.id) {
      setSelectedArticles([]);
      setIsSelectContent(false);
    } else {
      item.order = 1;
      setSelectedArticles([item]);
      setIsSelectContent(true);
    }
  };

  const loadMoreMyContent = () => {
    toggleLoadMoreLoader(true);
    getMyContentArticles({
      payload: {
        userType: isEnterpriseAdvisor
          ? 'isEnterpriseAdvisor'
          : isPremiumAdvisor
          ? 'isPremiumAdvisor'
          : 'isEnterpriseAdmin',
        channelId: channelId,
        beforeToken: null,
        afterToken: myContentPaginationData.after,
        pendingArticles: myContentPendingQueue,
      },
      localData: {
        currentArticles: myContentArticles,
      },
      source: 'campaign',
    })
      .then((res) => {
        const newState = get(res, 'newState', {});
        if (!isEmpty(newState)) campaignSetState(newState);
        toggleLoadMoreLoader(false);
      })
      .catch((err) => {
        console.log('******* ERROR my-content-articles [ARTICLE MODAL] ******');
        toggleLoadMoreLoader(false);
      });
  };

  const fetchLandingPages = () => {
    setSelectedContentTab(3);
    if (isEmpty(landingPageContent)) {
      setLandingPageLoader(true);
      getLandingPages('campaign')
        .then((response) => {
          let newLandingPage = get(response, 'data', []);
          console.log('response ----> ', response);
          if (acSelectedLandingPage) {
            newLandingPage = newLandingPage.filter(
              (l) => l._id === acSelectedLandingPage
            );
          }
          setLandingPageContent(newLandingPage);
          setLandingPageLoader(false);
        })
        .catch((err) => {
          setLandingPageLoader(false);
          console.log('err---> ', err);
        });
    }
  };

  const handleLandingPageSelection = (item) => {
    setSelectedLandingPage(item);
    setSelectedArticles([
      {
        id: item._id,
        url: '',
        topic: item.name,
        description: '',
        themeId: 0,
        link: item.redirect_page_url,
        type: 'lp',
      },
    ]);
  };

  return (
    <Modal
      height="auto"
      style={{ overflowY: 'auto' }}
      size="medium68"
      shouldCloseOnOverlayClick
      isOpen={showModal}
      onRequestClose={() => toggleModal(false)}
      bgColor="#fff"
    >
      <ModalHeader
        style={{ borderBottom: '1px solid #CFD9E4', paddingTop: '3px' }}
      >
        <Flex justifyContent="flex-start" alignItems="center">
          <span
            style={{
              color: '#000000',
              fontWeight: '600',
              fontSize: '12px',
              marginRight: '12px',
            }}
          >
            {showSettings ? 'Registration Settings' : 'Select Content'}
          </span>
        </Flex>
        <ModalCloseButton onClick={() => toggleModal(false)} />
      </ModalHeader>
      <ModalBody padding="10px 0px 0px">
        {showSettings ? (
          <Flex
            flexDirection="column"
            padding="0px 10px"
            height={settingsLoader ? '200px' : 'auto'}
          >
            <LoaderWrapper isLoading={settingsLoader}>
              <Flex
                margin="10px 0px"
                width="100%"
                alignItems="center"
                color={ColorPallete.neutral900}
                fontSize={Typography.TextMedium}
              >
                <Checkbox
                  checked={showTime}
                  onClick={() => setShowTime(!showTime)}
                />
                <Flex pl="9px">Close Registration after closing date</Flex>
              </Flex>
              {showTime && (
                <DateTimeWrapper
                  width="35%"
                  color={ColorPallete.black}
                  style={{
                    border: `1px solid #8493a6`,
                  }}
                >
                  <DateTimeText>
                    {eventDate &&
                      convertDateToRequiredFormat({
                        date: eventDate,
                        format: 'MM/DD/YYYY h:mm a',
                      }) +
                        ' (' +
                        timeZoneAbbreviated(
                          get(userData, 'details.user.timezone', '')
                        ) +
                        ')'}
                  </DateTimeText>
                  <DripIconButton
                    onClick={() => setIsDatepickerOpen(true)}
                    icon="clock"
                    color="#8493A6"
                    styles={{ marginTop: '5px' }}
                    forAutomatedCampaigns={true}
                  />
                  <MioDatePicker
                    isOpen={isDatepickerOpen}
                    customInput={<div />}
                    minDate={new Date()}
                    onDateClose={() => setIsDatepickerOpen(false)}
                    onSubmit={(date) => {
                      setEventDate(moment(date).format('YYYY/MM/DD'));
                      setIsDatepickerOpen(false);
                    }}
                    timeZone={get(userData, 'details.user.timezone', '')}
                    scheduleValue={eventDate ? new Date(eventDate) : ''}
                    isFromCampaign
                    isFromAC
                  />
                </DateTimeWrapper>
              )}
              <Flex
                margin="10px 0px"
                width="100%"
                alignItems="center"
                color={ColorPallete.neutral900}
                fontSize={Typography.TextMedium}
              >
                <Checkbox
                  checked={showCount}
                  onClick={() => setShowCount(!showCount)}
                />
                <Flex pl="9px">Restrict Number of Registrants</Flex>
              </Flex>
              {showCount && (
                <StyledTextInput
                  style={{
                    width: '35%',
                  }}
                  type="number"
                  placeholder="Registrants Count"
                  value={count}
                  onChange={(e) => setCount(e.target.value)}
                  name="registrants_count"
                />
              )}
              {showCount && (
                <Flex pt="3px">You can set the limit up to 5000</Flex>
              )}
              <LineHorizontalSeparator style={{ margin: '5px 0px 0px' }} />
              <Flex width="100%" justifyContent="flex-end">
                <LoaderWrapper isLoading={loading}>
                  <Button
                    bgColor="#6351ed"
                    margin="15px 2px 10px 0px"
                    color="#fff"
                    fontSize="12px"
                    padding="5px 15px"
                    styles={{ borderRadius: '5px' }}
                    disabled={handleValidation()}
                    onClick={() => {
                      setLoading(true);
                      let selectedCampaignId = acflow ? id : campaignId;
                      let payload = {
                        consider_count: showCount,
                        consider_date: showTime,
                        time_zone: get(userData, 'details.user.timezone', ''),
                        page_id: get(selectedLandingPage, '_id', ''),
                      };
                      if (showCount) {
                        payload.no_of_registrants = count;
                      }
                      if (showTime) {
                        payload.event_date = eventDate;
                      }
                      createUpdatePageSettings(selectedCampaignId, payload)
                        .then((res) => {
                          setLoading(false);
                          toggleModal(false);
                        })
                        .catch((err) => {
                          setLoading(false);
                        });
                    }}
                  >
                    {'Next >'}{' '}
                  </Button>
                </LoaderWrapper>
              </Flex>
            </LoaderWrapper>
          </Flex>
        ) : (
          <Flex
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            width="100%"
          >
            <Flex
              justifyContent="center"
              alignItems="center"
              width={acflow ? '20%' : '68%'}
              style={{
                border: '1px solid #6351ed',
                borderRadius: '4px',
                padding: '2px 0px',
                marginBottom: '10px',
              }}
            >
              {!acflow && (
                <ContentModalTab
                  active={selectedContentTab === 0}
                  onClick={() => setSelectedContentTab(0)}
                >
                  BOOKMARKED
                </ContentModalTab>
              )}

              {!acflow && (
                <ContentModalTab
                  active={selectedContentTab === 1}
                  onClick={() => setSelectedContentTab(1)}
                >
                  RECOMMENDED
                </ContentModalTab>
              )}

              {!acflow && (
                <ContentModalTab
                  active={selectedContentTab === 2}
                  onClick={() => setSelectedContentTab(2)}
                >
                  MY CONTENT
                </ContentModalTab>
              )}

              <ContentModalTab
                active={selectedContentTab === 3}
                onClick={() => fetchLandingPages()}
                width={acflow ? '100%' : ''}
              >
                LANDING PAGES
              </ContentModalTab>
            </Flex>
            {selectedContentTab !== 3 && (
              <Flex
                width="100%"
                justifyContent="center"
                alignItems="center"
                className="suggested-articles"
                style={{ overflowX: 'auto' }}
              >
                {newsletterArticles && newsletterArticles.length !== 0 ? (
                  <ArticlesContainer
                    width="100%"
                    justifyContent="center"
                    flexWrap="wrap"
                    mt="40px"
                    style={{ position: 'relative' }}
                  >
                    {newsletterArticles.map((item, index) => (
                      <NewsletterArticle
                        key={index}
                        articleIndex={index}
                        margin="10px 23px 10px 0px"
                        {...item}
                        themeNames={themeNames}
                        draggable={false}
                        isFromCampaign={true}
                        article={item}
                        handleBucketArticleClick={handleBucketArticleClick}
                        newsletterArticles={selectedArticles}
                        deleteBlocked={true}
                        isCampaign={true}
                        oneSelectMode={
                          curFlow === 'socialOnly' &&
                          selectedArticles.length === 1
                        }
                        selectedContentTab={selectedContentTab}
                      />
                    ))}
                    {newsletterArticles.length > 0 &&
                      newsletterArticles.length % 3 !== 0 &&
                      emptyCardArr
                        .slice(0, 3 - (newsletterArticles.length % 3))
                        .map((ele) => (
                          <NewsletterArticleWrapper
                            style={{ backgroundColor: '#fff' }}
                            width="230px"
                            margin="10px 23px 10px 0px"
                          />
                        ))}
                  </ArticlesContainer>
                ) : (
                  <Flex
                    style={{
                      width: '100%',
                      height: '388px',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    No Content to show
                  </Flex>
                )}
              </Flex>
            )}
            {selectedContentTab === 3 && (
              <Flex
                width="100%"
                justifyContent="center"
                alignItems="center"
                style={{ overflowX: 'auto' }}
              >
                <LandingPageCardWrapper
                  style={{
                    height: '348px',
                    maxHeight: '348px',
                    overflowY: 'auto',
                    padding: '0px 15px',
                    position: 'relative',
                    margin: '40px 0px 0px 50px',
                  }}
                >
                  <LoaderWrapper isLoading={landingPageLoader}>
                    {!isEmpty(landingPageContent) ? (
                      landingPageContent.map((item, index) => (
                        <LandingPageCard
                          key={index}
                          borderColor={
                            selectedLandingPage &&
                            selectedLandingPage._id === item._id
                              ? themesData.purpleishBlue
                              : ''
                          }
                          style={{ cursor: 'pointer' }}
                          onClick={() => {
                            handleLandingPageSelection(item);
                          }}
                        >
                          <Flex
                            display="flex"
                            justifyContent="center"
                            padding="5px"
                            width="100%"
                            height="84%"
                            style={{
                              alignItems: 'center',
                              borderBottom: '1px solid #E4E7EC',
                            }}
                          >
                            <img
                              height="100%"
                              width="100%"
                              alt=""
                              style={{ objectFit: 'contain' }}
                              src={
                                item.thumbnail_url ||
                                '/assets/images/postDefault4.jpg'
                              }
                            />
                          </Flex>
                          <Flex
                            justifyContent="center"
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              padding: '8px',
                              fontSize: '12px',
                              height: '36px',
                              justifyContent: 'left',
                            }}
                          >
                            <LandingPageTooltip>
                              <div
                                style={{
                                  width: '100%',
                                  display: 'flex',
                                  justifyContent: 'left',
                                  color: '#101828',
                                }}
                              >
                                {item.name && item.name.length > 33
                                  ? `${item.name.substring(0, 33)}...`
                                  : item.name}
                              </div>
                              {item.name && item.name.length > 33 && (
                                <span className="tooltiptext">{item.name}</span>
                              )}
                            </LandingPageTooltip>
                          </Flex>
                        </LandingPageCard>
                      ))
                    ) : (
                      <Flex
                        style={{
                          width: '100%',
                          height: '388px',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        No Content to show
                      </Flex>
                    )}
                  </LoaderWrapper>
                </LandingPageCardWrapper>
              </Flex>
            )}

            <DashedHorizontalSeparator style={{ margin: '5px 0px 15px' }} />

            <Flex justifyContent="center" alignItems="center">
              {!isEmpty(dayArticles) &&
                dayArticles.map((ele, index) => (
                  <CampaignCard
                    {...ele}
                    fromContentModal={true}
                    dragHandler={dragHandler}
                  />
                ))}
            </Flex>
            {selectedContentTab === 2 && (
              <LoaderWrapper isLoading={loadMoreLoader}>
                <Button
                  margin="20px auto 0px"
                  width="110px"
                  borderRadius="4px"
                  bgColor="#ffffff"
                  borderColor="#E4E7EC"
                  color="black"
                  onClick={loadMoreMyContent}
                  disabled={!myContentPaginationData.after}
                >
                  Load More
                </Button>
              </LoaderWrapper>
            )}
            <LineHorizontalSeparator style={{ margin: '5px 0px 0px' }} />
            <Flex width="100%" justifyContent="flex-end">
              <Button
                bgColor="#6351ed"
                margin="15px 2px 10px 0px"
                color="#fff"
                fontSize="12px"
                padding="5px 15px"
                styles={{ borderRadius: '5px' }}
                disabled={isEmpty(selectedArticles)}
                onClick={() => {
                  setSelectedContent(selectedArticles);
                  if (selectedContentTab === 3) {
                    setSettingsLoader(true);
                    let selectedCampaignId = campaignId;
                    getLandingPageSettings({
                      pageId: get(selectedLandingPage, '_id', ''),
                      campaignId: selectedCampaignId,
                    })
                      .then((res) => {
                        setShowCount(get(res, 'data.consider_count', false));
                        setShowTime(get(res, 'data.consider_date', false));
                        setCount(get(res, 'data.number_of_registrants', 0));
                        setEventDate(
                          get(res, 'data.event_date', '').split('.')[0]
                        );
                        setSettingsLoader(false);
                      })
                      .catch((err) => {
                        setSettingsLoader(false);
                      });
                    setShowSettings(true);
                  } else {
                    toggleModal(false);
                  }
                }}
              >
                {'Next >'}{' '}
              </Button>
            </Flex>
          </Flex>
        )}
      </ModalBody>
    </Modal>
  );
};

const mapStateToProps = (state) => {
  const roleType = getRoleType(state);
  const isPremiumAdvisor = roleType === ROLE_TYPES.ADVISOR_PREMIUM;
  const isEnterpriseAdvisor = roleType === ROLE_TYPES.ADVISOR_ENTERPRISE;
  const isEnterpriseAdmin = roleType === ROLE_TYPES.ADMIN_ENTERPRISE;

  return {
    themeNames: state.theme.themeNames,
    contentLibArticles: state.contentlib.data,
    bookmarkArticles: state.campaign.bookmarkArticles,
    recommendedArticles: state.campaign.recommendedArticles,
    myContentArticles: state.campaign.myContentArticles,
    myContentPaginationData: get(state, 'campaign.myContentPaginationData', {
      before: null,
      after: null,
    }),
    myContentPendingQueue: get(state, 'campaign.pendingArticles', []),
    channelId: getChannelId(state),
    recommendedFetchedData: state.campaign.recommendedFetchedData,
    isEnterpriseAdmin,
    isEnterpriseAdvisor,
    isPremiumAdvisor,
    userData: state?.user?.data,
  };
};

const mapDispatchToProps = {
  campaignSetState: campaignActions.api.campaign.setstate,
};

export default connect(mapStateToProps, mapDispatchToProps)(SelectContentModal);
