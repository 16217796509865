import React from 'react';
import { IconButton } from '@mui/material';
import checkbox_true from './images/checkbox_true.svg';
import checkbox_false from './images/checkbox_false.svg';

const Checkbox = ({ checked, onClick, size, custom }) => {
  let checkedTrue = custom || checkbox_true;
  return (
    <IconButton sx={{height: 18, width: 18}} onClick={onClick}>
      <img
        style={size && { height: size, width: size }}
        src={checked ? checkedTrue : checkbox_false}
        alt="checkBox"
      />
    </IconButton>
  );
};

export default Checkbox;
