import { styled } from '@mui/material';

export const TableTooltip = styled('div')(() => ({
  display: 'flex',
  gap: '4px',
  alignItems: 'center',

  '& p': {
    font: '500 1rem Poppins,sans-serif',
    color: '#101828',
    margin: 0,
    textAlign: 'left',
  },
  '& svg': {
    color: '#667085',
    fontSize: '1rem',
  },
}));

export const TooltipBody = styled('div')(() => ({
  '& h3': {
    color: '#FCFDFD',
    font: '500 0.875rem Poppins,sans-serif',
    margin: 0,
    textAlign: 'left',
  },
  '& p': {
    color: '#D0D5DD',
    font: '400 0.75rem/1rem Poppins,sans-serif',
    margin: 0,
    textAlign: 'left',
  },
}));

export const TooltipStyles = {
  '& .MuiTooltip-tooltip': {
    backgroundColor: '#13095F',
    borderRadius: '0.5rem',
    padding: '0.75rem',
    display: 'flex',
    flexDirection: 'column',
    gap: '4px',
  },
  '& .MuiTooltip-arrow': {
    color: '#13095F',
  },
};