import React, { useState } from 'react';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import {
  TooltipContainer,
  TooltipInfoContainer,
  TooltipName,
  TooltipTitle,
  TooltipValue,
} from './styles';

const CustomTooltip = ({ payload, active, show, valueDataKey }) => {
  if (active && Array.isArray(payload) && payload.length && show) {
    const tooltipData = payload?.[0]?.payload ?? {};
    const emailAddress = tooltipData?.email ?? '';

    return (
      <TooltipContainer>
        <TooltipTitle>{tooltipData?.name ?? ''}</TooltipTitle>

        {emailAddress && (
          <TooltipInfoContainer>
            <TooltipName>Email:</TooltipName>
            <TooltipValue>{emailAddress ?? '-'}</TooltipValue>
          </TooltipInfoContainer>
        )}

        <TooltipInfoContainer>
          <TooltipName>{tooltipData?.label ?? ''}:</TooltipName>
          <TooltipValue color={tooltipData?.color}>
            {tooltipData?.[valueDataKey] ?? '-'}%
          </TooltipValue>
        </TooltipInfoContainer>
      </TooltipContainer>
    );
  }

  return null;
};

function BarGraph({
  data,
  width,
  height,
  valueDataKey,
  margin,
  noInterval = false,
  barSize,
  XAxisDataKey = 'name',
}) {
  const [barHovered, setBarHovered] = useState(false);

  const truncateLabel = (label) => {
    const maxLength = 16;
    if (label.length > maxLength) {
      return label.substring(0, maxLength) + '...';
    }
    return label;
  };

  if (!data) return <></>;

  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart
        width={width}
        height={height}
        data={data}
        margin={
          margin || {
            top: 5,
            right: 0,
            left: 0,
            bottom: 5,
          }
        }
      >
        <CartesianGrid stroke="#98A2B3" vertical={false} />
        <XAxis
          tickLine={false}
          dataKey={XAxisDataKey}
          tickCount={data?.length ?? 'auto'}
          stroke="#98A2B3"
          fontSize="12px"
          fontWeight={500}
          interval={noInterval ? 'equidistantPreserveStart' : 0}
          tickFormatter={truncateLabel}
        />
        <YAxis
          axisLine={false}
          allowDecimals={false}
          stroke="#98A2B3"
          fontSize="12px"
          fontWeight={500}
        />
        <Tooltip
          content={
            <CustomTooltip show={barHovered} valueDataKey={valueDataKey} />
          }
          cursor={{ fill: 'transparent' }}
          isAnimationActive={false}
        />
        <Bar
          dataKey={valueDataKey ?? ''}
          barSize={barSize}
          onMouseEnter={() => setBarHovered(true)}
          onMouseLeave={() => setBarHovered(false)}
        >
          {Array.isArray(data) &&
            data?.length &&
            data.map((item, index) => (
              <Cell key={`cell-${index}`} fill={item.fill || '#8884d8'} />
            ))}
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
}

export default BarGraph;
