/* eslint-disable react-hooks/exhaustive-deps */
import { Flex } from '@rebass/grid'
import * as DOMPurify from 'dompurify';
import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react'
import Openai from 'src/assets/icons/Openai'
import { LoaderWrapper } from 'src/components/Loader'
import { ErrorDisplay } from 'src/componentsV2/ErrorDisplay'
import { cloutV2Endpoints } from 'src/services/helpers'
import { ColorPallete, Typography, oktaHelper, redirectToLogin } from 'src/utils'
import { RefreshIconStyle, SuggestionEmailScrollWrapper } from './styles';

const OpenAIDrawer = ({
    handleClose,
    template,
    insightData,
    subject,
    suggestions,
    setSuggestions
}) => {

    const [suggestionsLoading, setSuggestionsLoading] = useState(false);
    const [suggestionError, setSuggestionError] = useState(false);
    const [streamLoading, setStreamLoading] = useState(false);

    async function getStreamingSuggestions() {
        try {

            setSuggestionError(false);
            let parser = new DOMParser();
            let text = parser.parseFromString(template?.html, 'text/html')?.body?.firstChild?.textContent;
            setSuggestionsLoading(true);
            setStreamLoading(true);
            let content = {
                title: subject,
                insights: {
                    "subject_no_of_words": insightData?.result?.subject_no_of_words,
                    "subject_sentiment": insightData?.result?.subject_sentiment,
                    "sentiment_body": insightData?.result?.sentiment_body,
                    "length_of_body": insightData?.result?.length_of_body,
                },
                template: text.slice(0, 2000)
            }

            const response = await fetch(`${cloutV2Endpoints.baseUrl}/recommendation/email-suggestions`, {
                method: "post",
                headers: {
                    "Authorization": `Bearer ${window.sessionStorage.getItem('token') || oktaHelper.getAccessToken()}`
                },
                body: JSON.stringify(content),
            });

            if(response.status === 401) {
                redirectToLogin();
            }

            if (!response.ok || !response.body) {
                throw response.statusText;
            }
            // Here we start prepping for the streaming response
            const reader = response.body.getReader();
            const decoder = new TextDecoder();
            const loopRunner = true;

            let tempData = '';

            while (loopRunner) {
                // Here we start reading the stream, until its done.
                const { value, done } = await reader.read();
                if (done) {
                    setStreamLoading(false);
                    break;
                }
                let decodedChunk = decoder.decode(value, { stream: true });
                decodedChunk = decodedChunk.replace(/\\/g, '');
                tempData = tempData + decodedChunk;
                setSuggestions(tempData);
                setSuggestionsLoading(false);// update state with new chunk
            }


        } catch (error) {
            setSuggestionsLoading(false);
            console.log('error---',error,);
            setSuggestionError(true);
        }

    }

    useEffect(() => {
        window.sessionStorage.removeItem('reviewClick');
        if(isEmpty(suggestions)) {
            getStreamingSuggestions();
        }
    }, []);



    return (
        <Flex width="100%" height="100%" flexDirection="column" padding="20px" bg="#F6F2FF">
            <Flex width="100%" justifyContent="space-between" alignItems="center">
                <Flex width="80%">
                    <Flex width="10%">
                        <Openai height="25px" width="25px" />
                    </Flex>
                    <Flex
                        fontSize={Typography.Heading}
                        style={{
                            fontFamily: 'Poppins-medium'
                        }}
                    >
                        AI Suggestions
                    </Flex>
                </Flex>
                <Flex
                    width="auto"
                    style={{
                        borderRadius: '12px',
                        border: '1px solid #B6CFF3',
                    }}
                    fontSize={Typography.TextSmall}
                    backgroundColor="#ECF2FC"
                    padding="4px 8px"
                    color="#0B5CD7"
                >
                    Beta
                </Flex>
                <Flex alignItems="center" margin="0px 0px 0px 10px">
                    <RefreshIconStyle onClick={() => !streamLoading ? getStreamingSuggestions() : {}} style={{cursor: 'pointer'}}/>
                    <img
                        src="/assets/images/Analytics/close-button.svg"
                        alt=""
                        style={{ cursor: 'pointer' }}
                        onClick={handleClose}
                    />
                </Flex>
            </Flex>
            <Flex
                width="100%"

                backgroundColor={ColorPallete.white}
                style={{
                    border: '1px solid #A795FF',
                    borderRadius: '4px'
                }}
                padding="12px"
                flexDirection="column"
                mt="12px"
            >
                <LoaderWrapper isLoading={suggestionsLoading}>
                    {!suggestionError ? (
                        <SuggestionEmailScrollWrapper
                            fontSize={Typography.TextMedium}
                            color={ColorPallete.neutral600}
                            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(suggestions) }}
                        >
                        </SuggestionEmailScrollWrapper>
                    ) : (
                        <ErrorDisplay
                            onClick={getStreamingSuggestions}
                            height="180px"
                        />
                    )}

                </LoaderWrapper>
            </Flex>
        </Flex>
    )
}

export default OpenAIDrawer