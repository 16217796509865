import styled from 'styled-components';

export const Chips = styled.div.attrs({
  className: 'chips',
})`
  .chip {
    position: relative;
    display: inline-block;
    padding: 4px 3px 0px 13px;
    height: 26px;
    font-size: 15px;
    line-height: 18px;
    border-radius: 5px;
    background-color: #f1f1f1;
    color: #000000;
    font-weight: 900;
    opacity: ${({ mode }) => mode || '100%'};
  }

  .chip .tooltiptext {
    visibility: hidden;
    width: 100px;
    background-color: #ffffff;
    color: #4e6780;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    border: 1px solid #4e6780;
    font-size: 10px;
    font-weight: 600;
    /* Position the tooltip */
    position: absolute;
    z-index: 1;
    top: 100%;
    left: 50%;
    margin-left: -50px;
    margin-top: 5px;
  }

  .chip:hover {
    opacity: 100% !important;
  }

  .chip:hover .tooltiptext {
    visibility: visible;
    opacity: 100% !important;
  }

  .chip.completed {
    background-color: rgba(51, 178, 27, 0.05);
    border: 1px solid rgba(51, 178, 27, 0.1);
  }

  .chip.failed {
    background-color: rgba(244, 67, 54, 0.05);
    border: 1px solid rgba(244, 67, 54, 0.1);
  }

  .chip.missed {
    background-color: rgba(112, 136, 146, 0.05);
    border: 1px solid rgba(112, 136, 146, 0.1);
  }

  .chip.scheduled {
    background-color: rgba(50, 109, 230, 0.05);
    border: 1px solid rgba(50, 109, 230, 0.1);
  }

  .chip.content {
    background-color: rgba(255, 170, 0, 0.05);
    border: 1px solid rgba(255, 170, 0, 0.1);
  }

  .chip img {
    float: left;
    margin: ${({ displayMode }) =>
      displayMode && displayMode === 'block'
        ? '-2px 5px 0 -10px'
        : '-2px 17px 0 -10px'};
    height: 20px;
    width: 20px;
    border-radius: 50%;
  }
`;

export const ViewButton = styled.button.attrs({
  className: 'view',
})`
  color: blue;
  background-color: #ffffff;
  border: 1px solid blue;
  border-radius: 5px;
  padding: 0px 25px;
  height: 40px;
`;
