import styled from "styled-components";

export const CampaignsDropdownList = styled.ul`
    list-style-type: none;
    max-height: 135px;
    overflow-y: auto;
    margin: 0;
    padding: 0;

    &::-webkit-scrollbar {
        width: 5px;
    }

    &::-webkit-scrollbar-track {
        background: #e9f6e6 !important;
    }

    &::-webkit-scrollbar-thumb {
        background: #bccdd4 !important;
    }
`;