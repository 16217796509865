import React, { useState } from 'react';
import {
  Button,
  LoaderWrapper,
  ModalBody,
  ModalCloseButton,
  ModalHeader,
  ModalV2,
} from '../../../components';
import { Flex } from '@rebass/grid';
import {
  AddPublisherCardWrapper,
  StyledCross,
  StyledImgDiv,
  StyledTitle,
  StyledTitleDiv,
} from './styles';
import { Chip } from '../../../componentsV2/Chip';
import _, { get, isEmpty } from 'lodash';
import PublisherSearch from './PublisherSearch';
import {
  getAdminChannelData,
  getChannelList,
  postMyPublisherSubscribeChannel,
  postSubscribeChannel,
} from '../../../services/dashboard';
import { PremiumModalContent } from '../../ChannelContainer/Channel.styles';
import { CustomModal } from '../../../components/Modal/Modal.styles';
import { Tooltip, Zoom } from '@mui/material';
import PublisherListView from './PublisherListView';

const APPROVED = 'approved';
const APPLIED = 'applied';


const AddPublisherModal = ({
  isOpen,
  closeModalHandler,
  myPublisherList,
  loader,
  isAdmin,
  setPublishers,
  publishers,
  premiumPublisherList,
  setLoader,
}) => {
  const [searchString, setSearchString] = useState('');
  const [premiumModal, setPremiumModal] = useState(false);

  const [updateList, setUpdatedList] = useState(false);

  const getImages = (channel) => {
    if (
      channel.media_urls &&
      channel.media_urls[0] &&
      channel.media_urls[0].path &&
      channel.media_urls[0].name.resized &&
      channel.media_urls[0].ext
    )
      return `${channel.media_urls[0].path}/${channel.media_urls[0].name.resized}.${channel.media_urls[0].ext}`;
    else return '';
  };

  const togglePremiumModal = () => {
    setPremiumModal(!premiumModal);
    setTimeout(() => {
      setPremiumModal(false);
    }, 2000);
  };

  const handlePublisherSubscribeClick = (id, index) => {
    postSubscribeChannel(id)
      .then((res) => {
        if (isAdmin) {
          getAdminChannelData().then((res) => {
            // console.log('CHANNEL API RESULT - ', res);
            setLoader('');
            setPublishers(_.map(_.values(res.result.data), (item) => item[0]));
          });
        } else {
          getChannelList().then((res) => {
            setPublishers(_.map(_.values(res.result.data), (item) => item[0]));
            setLoader('');
          });
        }
      })
      .catch((err) => {
        togglePremiumModal(get(err, 'result.error.message'));
        setLoader('');
      });
  };

  const postMyPublisherSubscribeChannelClick = (id, index) => {
    postMyPublisherSubscribeChannel(id).then((res) => {
      if (isAdmin) {
        getAdminChannelData().then((res) => {
          console.log('subscriberesponse', res);
          setPublishers(_.map(_.values(res.result.data), (item) => item[0]));
          setLoader('')
        });
      } else {
        getChannelList().then((res) => {
          setPublishers(_.map(_.values(res.result.data), (item) => item[0]));
          setLoader('');
        });
      }
     
    });
  };

  const handlePremiumPublisherSubscribeClick = (channel, index) => {
    if (!get(channel, 'subscription.status')) {
      postSubscribeChannel(channel.id)
        .then((res) => {
          setUpdatedList(!updateList);
          togglePremiumModal('Requested access to this partner.');
        })
        .catch((err) => {});
    } else if (
      get(channel, 'subscription.status.application_status') === APPLIED
    ) {
      togglePremiumModal('You have already requested access to this partner.');
    } else if (
      get(channel, 'subscription.status.application_status') === APPROVED
    ) {
      togglePremiumModal('You have already subscribed to this channel.');
    }
  };

  return (
    <ModalV2
      onRequestClose={closeModalHandler}
      shouldCloseOnOverlayClick
      isOpen={isOpen}
      bgColor="#fff"
      width="60%"
    >
      <ModalHeader
        fontColor="#101828"
        paddingBottom="0px"
        padding="10px 10px 5px 15px"
        style={{
          fontSize: '18px',
          display: 'flex',
          justifyContent: 'flex-start',
          marginBottom: '15px',
        }}
      >
        <Flex width="100%" justifyContent="space-between" alignItems="center" flexDirection="column">
          <Flex width="100%" padding="10px">
            Add Publishers
          </Flex>
          <PublisherSearch
            publishers={publishers}
            searchInput={searchString}
            setSearchInput={setSearchString}
            myPublisherList={myPublisherList}
            postMyPublisherSubscribeChannelClick={
              postMyPublisherSubscribeChannelClick
            }
            isAdmin={isAdmin}
            handlePublisherSubscribeClick={handlePublisherSubscribeClick}
          />
        </Flex>
        
        
      </ModalHeader>
      <ModalCloseButton onClick={closeModalHandler} />
      <ModalBody padding="0px 15px">
        {/* <Flex
          display="flex"
          padding="10px"
          width="100%"
          style={{ backgroundColor: '#F9FAFB', marginBottom: '25px' }}
        >
          <Flex
            display="flex"
            flexDirection="column"
            width="100%"
            height="220px"
          >
            <Flex
              display="flex"
              justifyContent="start"
              alignItems="center"
              marginBottom="15px"
            >
              <img alt="" src="/assets/images/pp-icon.svg" />
              <Flex
                ml="11px"
                mr="5px"
                style={{ fontSize: '14px', color: '#475467' }}
              >
                Premium Publishers
              </Flex>
            </Flex>
            <Flex width="100%" height="300px">
              <AddPublisherCardWrapper>
                {premiumPublisherList ? (
                  premiumPublisherList.map((channel, index) => {
                    if (channel.is_hidden === 'true') {
                      return null;
                    }
                    return (
                      <LoaderWrapper isLoading={loader === getImages(channel)}>
                        <Chip width="30%" height="80px">
                          <StyledImgDiv style={{ width: '35%' }}>
                            <img
                              alt=""
                              src={getImages(channel)}
                              style={{ width: '100%' }}
                            />
                          </StyledImgDiv>
                          <StyledTitleDiv style={{ width: '50%' }}>
                            <StyledTitle
                              style={{
                                padding: '0px 0px 0px 10px',
                                fontSize: '14px',
                              }}
                            >
                              {channel?.name && channel?.name?.length > 10 ? (
                                <Tooltip
                                  arrow
                                  placement="top"
                                  title={channel.name}
                                  componentsProps={{
                                    tooltip: {
                                      sx: {
                                        fontFamily: 'Poppins',
                                      },
                                    },
                                  }}
                                >
                                  {`${channel?.name.substring(0, 10)}...`}
                                </Tooltip>
                              ) : (
                                channel?.name
                              )}
                            </StyledTitle>
                          </StyledTitleDiv>

                          <StyledCross
                            style={{
                              marginRight: '7px',
                              width: '15%',
                              justifyContent: 'center',
                              cursor: 'pointer',
                            }}
                            onClick={() => {
                              handlePremiumPublisherSubscribeClick(
                                channel,
                                index
                              );
                            }}
                          >
                            <img alt="" src="/assets/images/plus.svg" />
                          </StyledCross>
                        </Chip>
                      </LoaderWrapper>
                    );
                  })
                ) : (
                  <Flex justifyContent="center" width="100%">
                    <img src="/assets/images/loaderV.gif" alt="loader" />
                  </Flex>
                )}
                <CustomModal
                  onRequestClose={() => setPremiumModal(false)}
                  shouldCloseOnOverlayClick
                  isOpen={premiumModal}
                  bgColor="#fff"
                  size="medium45"
                >
                  <ModalHeader>
                    <PremiumModalContent style={{ color: '#101828' }}>
                      You have already requested access to this publisher.
                    </PremiumModalContent>
                  </ModalHeader>
                </CustomModal>
              </AddPublisherCardWrapper>
            </Flex>
          </Flex>
        </Flex> */}
        
        <CustomModal
          onRequestClose={() => setPremiumModal(false)}
          shouldCloseOnOverlayClick
          isOpen={premiumModal}
          bgColor="#fff"
          size="medium45"
        >
          <ModalHeader>
            <PremiumModalContent style={{ color: '#101828' }}>
              You have already requested access to this publisher.
            </PremiumModalContent>
          </ModalHeader>
        </CustomModal>
        {isEmpty(searchString) && (
          <>
            <PublisherListView
              publishers={publishers}
              isAdmin={isAdmin}
              postMyPublisherSubscribeChannelClick={
                postMyPublisherSubscribeChannelClick
              }
              handlePublisherSubscribeClick={handlePublisherSubscribeClick}
              premiumModal={premiumModal}
              loader={loader}
              setLoader={setLoader}
              myPublisherList={myPublisherList}
            />
            <CustomModal
              onRequestClose={() => setPremiumModal(false)}
              shouldCloseOnOverlayClick
              isOpen={premiumModal}
              bgColor="#fff"
              size="medium45"
            >
              <ModalHeader>
                <PremiumModalContent style={{ color: '#101828' }}>
                  You have already requested access to this publisher.
                </PremiumModalContent>
              </ModalHeader>
            </CustomModal>
          </>
        )}

        <Flex
          display="flex"
          justifyContent="center"
          flexDirection="row"
          style={{ marginBottom: '15px', marginTop: '15px' }}
        >
          {/* <Button
            style={{ borderRadius: '4px' }}
            height="40px"
            width="375px"
            margin="9px 10px 0px 15px"
            padding="5px 28px"
            bgColor="#fff"
            color="#101010"
            borderColor="#E4E7EC"
            borderRadius="4px"
            onClick={closeModalHandler}
          >
            Cancel
          </Button>
          <Button
            style={{ borderRadius: '4px' }}
            height="40px"
            width="375px"
            margin="9px 12px 0px 0px"
            padding="5px 28px"
            bgColor="#816EF8"
            color="#fff"
            borderColor="#816EF8"
            borderRadius="4px"
            onClick={closeModalHandler}
          >
            Save
          </Button> */}
           <Button
            style={{ borderRadius: '4px' }}
            height="40px"
            width="100px"
            margin="9px 12px 0px 0px"
            padding="5px 28px"
            bgColor="#816EF8"
            color="#fff"
            borderColor="#816EF8"
            borderRadius="4px"
            onClick={closeModalHandler}
          >
            Done
          </Button>
        </Flex>
      </ModalBody>
    </ModalV2>
  );
};

export default AddPublisherModal;
