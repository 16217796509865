import React from 'react';
import { ACBlock } from './CampaignCreateSteps.styles';
import { Flex } from '@rebass/grid';
import { isEmpty } from 'lodash';
import { Button } from '../../../components';
import { getMediaImage, nurtureFlag } from '../../../utils';
import { Tooltip } from '@mui/material';

const BlockCard = ({
  item,
  campaignAction,
  currentStepIndex,
  onButtonClick,
  onCloneClick,
  onDeleteClick,
  onPreviewClick,
  onSendTestModalClick,
  onSocialPreviewClick,
  blockLength,
}) => {

  const getImage = () => {
    if(nurtureFlag === 'nurture' && process.env.REACT_APP_API_ENV === 'stage') {
      return item.media_urls;
    } else {
      if(item.platform === 'email') {
        if(!isEmpty(item.newsletter_id)) {
          if(!isEmpty(item?.media_urls)) {
            return getMediaImage(item.media_urls);
          } else {
            const randomNumber = Math.floor(Math.random() * 4) + 1;
            return `/assets/images/postDefault${randomNumber}.jpg`;
          } 
        } else {
          return 'assets/images/Campaign/Default-image.png';
        }
      } else {
        if(!isEmpty(item?.media_urls)) {
          return getMediaImage(item.media_urls);
        } else {
          return 'assets/images/Campaign/Default-image.png';
        }
      }
    }
  }
  return (
    <ACBlock style={{ marginLeft: currentStepIndex === 2 ? '18px' : '0px' }}>
      <Flex width="25%">
        <img
          style={{
            width: '100%',
            objectFit: 'contain',
          }}
          src={
            getImage()
          }
          alt=""
        />
      </Flex>
      <Flex pl="10px" flexDirection="column" width="100%">
        <Flex flexDirection="row" justifyContent="space-between">
          <Flex color="#101828" fontSize="16px" style={{ fontWeight: '500', display: 'inline-block', textAlign: 'left', }}>
            {item.name}
          </Flex>
          {currentStepIndex === 1 && (
            <Flex alignItems="center" flexDirection="row">
              {/* <Flex>
                <img alt="" src="assets/images/Campaign/edit-icon.svg" />
              </Flex> */}
              {item.b_type !== 'on_conversion' && blockLength > 1 && (
                <Flex>
                  <Tooltip title="Delete Block" arrow>
                    <img
                      alt=""
                      src="assets/images/Campaign/delete-icon.svg"
                      onClick={() => {
                        console.log('item id-->', item.id);
                        onDeleteClick(item.id);
                      }}
                      style={{ cursor: 'pointer' }}
                    />
                  </Tooltip>
                </Flex>
              )}

              {item.b_type !== 'on_conversion' && (
                <Flex>
                  <Tooltip title="Duplicate Block" arrow>
                    <img
                      alt=""
                      src="assets/images/Campaign/copy-icon.svg"
                      onClick={() => onCloneClick(item.id)}
                      style={{ cursor: 'pointer' }}
                    />
                  </Tooltip>
                </Flex>
              )}
              {item.platform === 'email' && (
                <Flex>
                  <Tooltip title="Preview" arrow>
                    <img
                      alt=""
                      src="assets/images/Campaign/eye-icon.svg"
                      onClick={() => onPreviewClick(item)}
                      style={{ cursor: 'pointer ' }}
                    />
                  </Tooltip>
                </Flex>
              )}
              {item.platform === 'social' && (
                <Flex>
                  <Tooltip title='Preview' arrow>
                    <img
                      alt=""
                      src="assets/images/Campaign/eye-icon.svg"
                      onClick={() => onSocialPreviewClick(item)}
                      style={{ cursor: 'pointer ' }}
                    />
                  </Tooltip>
                </Flex>
              )}
            </Flex>
          )}
          {currentStepIndex === 2 && item.platform === 'email' ? (
            <Flex alignItems="center" flexDirection="row">
              <Flex>
                <Tooltip title='Send Test Email' arrow>
                  <img
                    alt=""
                    src="assets/images/Campaign/Send-Test-Email.svg"
                    onClick={() => {
                      console.log('preview clicked');
                      onSendTestModalClick(item);
                    }}
                    style={{ cursor: 'pointer' }}
                  />
                </Tooltip>
              </Flex>
              <Flex>
                <Tooltip title='Preview' arrow>
                  <img
                    alt=""
                    src="assets/images/Campaign/eye-icon.svg"
                    onClick={() => {
                      console.log('preview clicked');
                      onPreviewClick(item);
                    }}
                    style={{ cursor: 'pointer' }}
                  />
                </Tooltip>
              </Flex>
            </Flex>
          ) : currentStepIndex === 2 && item.platform === 'social' ? (
            <Flex alignItems="center" flexDirection="row">
                <Flex>
                  <Tooltip title='Preview' arrow>
                    <img
                      alt=""

                      src="assets/images/Campaign/eye-icon.svg"
                      onClick={() => {
                        console.log('social preview clicked');
                        onSocialPreviewClick(item);
                      }}
                      style={{ cursor: 'pointer' }}
                    />
                  </Tooltip>
              </Flex>
            </Flex>
          ) : null}
        </Flex>
        <Flex
          color="#101828"
          padding="10px 0px 0px"
          style={{ fontWeight: '500' }}
          fontSize="14px"
          display="inline-block"
          textAlign="left"
        >
          {item.platform === 'email'
            ? item?.subject
            : item?.override_text && item?.override_text[Object.keys(item?.override_text)[0]]}
        </Flex>
        {currentStepIndex === 2 ? (
          <Flex
            color="#667085"
            padding="10px 0px 10px"
            fontSize="12px"
            style={{ textAlign: 'justify' }}
          >
            {item?.description}
          </Flex>
        ) : null}
        {currentStepIndex !== 2 ? (
          <Flex>
            <Button
              borderColor="#E4E7EC"
              color="#101828"
              bgColor="#fff"
              margin="10px 0px 0px"
              onClick={() => onButtonClick(item.id, item.platform, item)}
            >
              <Flex
                justifyContent="space-between"
                width="100%"
                alignItems="center"
              >
                <img
                  src="assets/images/Campaign/Pencil-Icon.svg"
                  alt="Pencil"
                />
                <Flex ml="5px">
                  {item.platform === 'social'
                    ? 'Customize Social Block'
                    : 'Customize Email'}
                </Flex>
              </Flex>
            </Button>
          </Flex>
        ) : null}
      </Flex>
    </ACBlock>
  );
};

export default BlockCard;
