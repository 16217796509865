import React from 'react';
import ReactSkeleton from 'react-loading-skeleton';

import TableSkeleton from 'src/componentsV2/Table/Skeleton';
import { TableHeader } from '../EmailDeliverability/styles';
import { ForwardFriendWrapper } from './styles';

function Skeleton() {
  return (
    <ForwardFriendWrapper>
      <TableHeader>
        <h2>
          <ReactSkeleton height={16} width={100} />
        </h2>
      </TableHeader>
      <TableSkeleton />
    </ForwardFriendWrapper>
  );
}

export default Skeleton;