import { styled } from '@mui/material';

export const CampaignContentWrapper = styled('section')(() => ({
  border: '1px solid #E4E7EC',
  background: '#fff',
  borderRadius: '0.5rem',
  margin: '1.5rem 0',

  '& .MuiAccordion-root ': {
    backgroundColor: 'transparent',
  },

  '& .MuiAccordionSummary-root': {
    padding: '1rem',
  },
  '& .MuiAccordionDetails-root': {
    overflowY: 'auto',
  },
  '& .MuiCollapse-entered': {
    border: '1px solid #D0D5DD',
    borderRadius: '0 0 0.25rem 0.25rem',
    background: '#F2F4F7',
  },
}));

export const CampaignContentHeader = styled('div')(() => ({
  '& h2, p': {
    margin: 0,
    color: '#000',
    font: '500 1.25rem/1rem Poppins,sans-serif',
    textAlign: 'left',
  },
  '& p': {
    color: '#667085',
    font: '400 0.875rem/1rem Poppins,sans-serif',
    marginTop: '1rem',
  },
}));

export const ContentContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '1rem',
  width: '100%',
}));

export const ArticleContainer = styled('article')(() => ({
  background: '#fff',
  borderRadius: '0.5rem',
  display: 'flex',
  alignItems: 'center',
  border: '1px solid #E4E7EC',
  padding: '1rem',
  marginBottom: '1rem',
}));

export const Article = styled('div')(() => ({
  display: 'flex',
  alignItems: 'flex-start',
  gap: '1rem',
  width: '100%',
}));

export const ArticleInfoContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  rowGap: '1rem',
}));

export const ArticleAndFormatContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  rowGap: '4px',
}));

export const ArticleFormat = styled('div')(() => ({
  '& p': {
    color: '#667085',
    font: '500 0.875rem/1rem Poppins, sans-serif',
    margin: 0,
  },
}));

export const ArticleTitle = styled('h2')(() => ({
  font: '500 1rem/1.4rem Poppins, sans-serif',
  textAlign: 'left',
  margin: 0,
  color: '#101828',
}));

export const ArticleIcon = styled('div')(() => ({
  '& svg': {
    height: '80px',
    width: '80px',
  },
  '& img': {
    objectFit: 'cover',
    borderRadius: '0.5rem',
  },
}));

export const Pills = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '0.5rem',
  flexWrap: 'wrap',
}));

export const Pill = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '0.625rem',
  padding: '0.25rem 0.5rem',
  border: `1px solid #B6CFF3`,
  backgroundColor: `#ECF2FC`,
  borderRadius: '1.5rem',
  font: '500 0.75rem Poppins, sans-serif',
  color: '#0B5CD7',
  textAlign: 'left',
  margin: 0,

  svg: {
    fontSize: '1rem',
    color: '#0B5CD7',
  },
}));
