import React from 'react'

function Openai(props) {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path d="M13.0035 16.4535L11.2035 21.3378C11.1502 21.4814 11.0543 21.6052 10.9286 21.6926C10.8029 21.78 10.6534 21.8269 10.5003 21.8269C10.3472 21.8269 10.1978 21.78 10.0721 21.6926C9.94642 21.6052 9.85049 21.4814 9.79721 21.3378L7.99721 16.4535C7.95923 16.3504 7.89932 16.2567 7.82163 16.1791C7.74394 16.1014 7.65031 16.0415 7.54721 16.0035L2.66284 14.2035C2.51931 14.1502 2.39552 14.0543 2.3081 13.9286C2.22068 13.8029 2.17383 13.6534 2.17383 13.5003C2.17383 13.3472 2.22068 13.1978 2.3081 13.0721C2.39552 12.9464 2.51931 12.8505 2.66284 12.7972L7.54721 10.9972C7.65031 10.9592 7.74394 10.8993 7.82163 10.8216C7.89932 10.7439 7.95923 10.6503 7.99721 10.5472L9.79721 5.66284C9.85049 5.51931 9.94642 5.39552 10.0721 5.3081C10.1978 5.22068 10.3472 5.17383 10.5003 5.17383C10.6534 5.17383 10.8029 5.22068 10.9286 5.3081C11.0543 5.39552 11.1502 5.51931 11.2035 5.66284L13.0035 10.5472C13.0415 10.6503 13.1014 10.7439 13.1791 10.8216C13.2567 10.8993 13.3504 10.9592 13.4535 10.9972L18.3378 12.7972C18.4814 12.8505 18.6052 12.9464 18.6926 13.0721C18.78 13.1978 18.8269 13.3472 18.8269 13.5003C18.8269 13.6534 18.78 13.8029 18.6926 13.9286C18.6052 14.0543 18.4814 14.1502 18.3378 14.2035L13.4535 16.0035C13.3504 16.0415 13.2567 16.1014 13.1791 16.1791C13.1014 16.2567 13.0415 16.3504 13.0035 16.4535V16.4535Z" stroke="currentColor" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M16.5 1.5V6" stroke="currentColor" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M18.75 3.75H14.25" stroke="currentColor" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M21 6.75V9.75" stroke="currentColor" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M22.5 8.25H19.5" stroke="currentColor" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    )
}

export default Openai