import { Flex } from '@rebass/grid';
import { get, isEmpty } from 'lodash';
import React from 'react'
import Slider from 'react-slick';
import { DisplayScore } from '../../../components';
import { onImageError, overviewTabSliderSettings } from '../../../utils';
import { ArticleSliderWrapper } from '../../DashboardContainer/DashboardContainer.styles';
import { OverviewBadge, SponsoredWrapper, TopLeadEmail, TopLeadWrapper, TopShareName } from './EngagementTab.styles';


const NewsletterProspectTab = ({
  analyticsData,
  selectedItem
}) => {

  return (
    <Flex>
      {isEmpty(get(analyticsData, 'leads', [])) ? (
        <div
          style={{
            fontSize: '12px',
            height: '260px',
            display: 'flex',
            margin: 'auto',
          }}
        >
          <div style={{ margin: 'auto' }}>No Records Found</div>
        </div>
      ) : (
        <ArticleSliderWrapper type="engagement" width="100%">
        <Slider {...overviewTabSliderSettings}>
          {get(analyticsData, 'leads', []).map((item, index) => {
            const trimName = (item.first_name + ' ' + item.last_name).length > 15 ? (item.first_name + ' ' + item.last_name).substring(0, 15) + '...' : (item.first_name + ' ' + item.last_name);
            const trimEmail = item.email && item.email.length > 20 ? item.email.substring(0, 20) + '...' : item.email;
            return (
              <TopLeadWrapper width="213px !important" height='212px' margin='14px 10px 0px 8.2px' padding='0px'>
                <Flex style={{ height: '99px', width: '100%' }}>
                  <img alt='share' src={!isEmpty(item.suggested_investments) ? item.suggested_investments[0].theme_image_path : ''} width='212px' onError={onImageError} style={{ borderTopLeftRadius: '8px', borderTopRightRadius: '8px', objectFit: 'cover' }} />
                </Flex>
                <Flex flexDirection='column' padding='8px 8px 10px 8px'>
                  <Flex width='100%' justifyContent='space-between' padding='0px 6px'>
                    <Flex flexDirection='column' justifyContent='flex-start'>
                      <TopShareName>{trimName}</TopShareName>
                      <TopLeadEmail title={item.email && item.email.length > 20 ? item.email : ''}>{trimEmail}</TopLeadEmail>
                    </Flex>
                    <Flex>
                      <DisplayScore score={item.engagement_score} />
                    </Flex>
                  </Flex>
                  {!isEmpty(item.suggested_investments) ? (
                    item.suggested_investments.slice(0, 1).map((item, index) => (
                      <SponsoredWrapper mt='10px'>
                        <Flex width='100%' justifyContent='flex-start' padding='2px 0px' style={{ height: '13px' }}>
                          <img src={item.logo_path} style={{objectFit:'contain', objectPosition:'left'}} alt='Company'/>
                        </Flex>
                        <Flex width='100%' fontSize='10px' padding='2px 0px' style={{ textAlign: 'left', color: '#49484a', fontWeight: '500', whiteSpace: 'nowrap', height: '15px' }}>
                          <span title={item.name.length > 32 ? item.name : ''}>{item.name.length > 32 ? item.name.substring(0, 32) + '...' : item.name}</span>
                        </Flex>
                        <Flex justifyContent='space-between' style={{ paddingTop: '2px' }}>
                          <Flex>
                            <span style={{ color: '#49484a', fontWeight: 'bold', fontSize: '10px' }}>{item.ticker}</span>
                            &nbsp;
                            {item.matched_topics && item.matched_topics.length > 0 && item.matched_topics.slice(0, 1).map((item, index) => (
                              <span style={{ color: '#49484a', fontSize: '8px', fontWeight: 500, border: '1px solid #49484a', borderRadius: '2px', marginRight: '4px', height: '11px', display: 'flex', alignItems: 'center', padding: '0px 2px', marginTop: '2px' }}>
                                {item}
                              </span>
                            ))}
                          </Flex>
                          {item.is_sponsored && (
                            <OverviewBadge style={{ float: 'right' }}><span>Sponsored</span></OverviewBadge>
                          )}
                        </Flex>
                      </SponsoredWrapper>
                    ))
                  ) : null}
                </Flex>
              </TopLeadWrapper>
            )
          })}
        </Slider>
      </ArticleSliderWrapper>
      )}
    </Flex>
  );
}

export default NewsletterProspectTab;
