import styled from 'styled-components';
import { themesData } from '../../utils';

export const ConnectMeWrapper = styled.div.attrs({
  className: 'connect-me-wrapper',
})`
  position: ${({ position }) => position || ''};
  top: ${({ top }) => top || ''};
  right: ${({ right }) => right || ''};
  bottom: ${({ bottom }) => bottom || ''};
  height: ${({ height }) => height || '300px'};
  padding: ${({ padding }) => padding || '15px'};
  width: ${({ width }) => width || '235px'};
  background-color: #fff;
  border: ${({ border }) => border || `1px solid ${themesData.charcoalGrey}`};

  .img-wrapper {
    height: 40%;
    width: 100%;
  }

  @media only screen and (min-width: 561px) and (max-width: 768px) {
    position: static;
    margin-bottom: 10px;
    margin-top: 5px;
  }
  .documentFrame{
    overflow-x: hidden
  }

  @media only screen and (max-width: 560px) {
    position: fixed;
    z-index: 1;
    width: 100%!important;
    height: fit-content;
    bottom: 0;
    right: 0;
    padding: 2%;
    border-top: 2px solid #6351ed 
  }
`;
