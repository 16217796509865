import axios from 'axios';
import { get, isEmpty } from 'lodash';
import qs from 'query-string';
import { apiDetails } from './helpers';
import { oktaHelper, redirectToLogin, setToken } from '../utils';

//pass asAdvisor as true during admin login to get acccess to advisor related APIs
function createAPIInstance(options) {
  // Set config defaults when creating the instance

  const isReactLogin = get(options, 'isReactLogin', false);
  const oktaToken = oktaHelper.getAccessToken();
  let authToken;
  if(!isEmpty(window.sessionStorage.getItem('token')) && window.sessionStorage.getItem('token') !== 'null') {
    authToken = window.sessionStorage.getItem('token');
  } else if (!isEmpty(oktaToken)) {
    authToken = oktaToken;
  } else if(!isEmpty(get(options, 'token', '')) && get(options, 'token', '') !== 'null') {
    authToken = get(options, 'token', '');
  }
  if (!authToken && !isReactLogin) {
    // setting token from cookie
    setToken();
  }

  const pdfAuth = get(qs.parse(qs.extract(window.location.href)), 'pdfauth');
  const userToken = get(
    qs.parse(qs.extract(window.location.href)),
    'userToken'
  );

  if (pdfAuth) {
    authToken = pdfAuth;
  }


  if (!isReactLogin && !authToken && !userToken) {
    window.location.href = window.location.origin;
    return;
  }

  if(!authToken && userToken) {
    authToken = setToken()
  }
  

  const advisorAuthToken = window.sessionStorage.getItem('advisorToken');
  const asAdvisor = get(options, 'asAdvisor', false);
  const extraHeader = get(options, 'extraHeader', {});

  const tokenHeader = isEmpty(authToken)
    ? {}
    : asAdvisor
    ? !!advisorAuthToken
      ? {
          Authorization: `Bearer ${advisorAuthToken}`,
          ...extraHeader,
        }
      : {
          Authorization: `Bearer ${authToken}`,
          ...extraHeader,
        }
    : {
        Authorization: `Bearer ${authToken}`,
        ...extraHeader,
      };
  const api = axios.create({
    baseURL: apiDetails.baseUrl.trim(),
    ...(get(options, 'timeout', {}) && {
      timeout: get(options, 'timeout', {})
    }),
    headers: {
      'Content-Type': options && options.contentType ? options.contentType : 'multipart/form-data',
      ...tokenHeader,
    },
  });

  api.interceptors.response.use(
    (response) => {
      const errorResponse = get(response, 'data.result.error', {});
      if (!isEmpty(errorResponse)) {
        throw errorResponse;
      }
      return response.data;
    },
    (error) => {
      if (error && error.response && error.response.status === 401) {
        redirectToLogin();
      }
      return Promise.reject(error);
    }
  );
  return api;
}

export default createAPIInstance;
