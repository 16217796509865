import React, { useState } from 'react';
import { Tab, Tabs } from '@mui/material';

import useRESTQuery from 'src/hooks/useRESTQuery';
import TableSkeleton from 'src/componentsV2/Table/Skeleton';
import Table from 'src/componentsV2/Table';
import Pagination from 'src/componentsV2/Pagination';
import { ErrorDisplay } from 'src/componentsV2/ErrorDisplay';
import { EmptyState } from 'src/componentsV2';
import {
  tableColumnStructureForContent,
  tableColumnStructureForLinks,
  tableColumnStructureForTopics,
} from './TableColumnStructure';

import {
  PaginationContainer,
  TopicsEngagementTableHeader,
  TopicsEngagementTableWrapper,
} from './styles';
import { transformListsResponse, transformTopicsListResponse } from './utils';
import { CONTENT, LINKS, TOPICS } from './constants';
import { LIMIT } from '../constants';

function TopicsEngagementTable({ selectedUsers, startDate, isEnterprise }) {
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedTab, setSelectedTab] = useState(CONTENT);

  const {
    data: topicsData,
    isLoading: topicsLoader,
    isError: topicsError,
    refetch: topicsRefetch,
    isFetching: topicsFetching,
  } = useRESTQuery({
    key: 'content-topics',
    dependencies: [
      isEnterprise,
      startDate,
      selectedUsers?.join(','),
      currentPage,
    ],
    endpoint: `/analytics/content/topics/list?ongoing=false&from_date=${startDate}&limit=${LIMIT}&page_no=${currentPage}&is_enterprise=${isEnterprise}${
      selectedUsers ? `&user_ids=${JSON.stringify(selectedUsers)}` : ''
    }`,
    options: {
      enabled: selectedTab === TOPICS,
      select: (res) => transformTopicsListResponse(res?.result ?? {}),
    },
  });

  const {
    data: contentData,
    isLoading: contentLoader,
    isError: contentError,
    refetch: contentRefetch,
    isFetching: contentFetching,
  } = useRESTQuery({
    key: 'content-list',
    dependencies: [
      isEnterprise,
      startDate,
      selectedUsers?.join(','),
      currentPage,
    ],
    endpoint: `/analytics/content?from_date=${startDate}&limit=${LIMIT}&page_no=${currentPage}&is_enterprise=${isEnterprise}${
      selectedUsers ? `&user_ids=${JSON.stringify(selectedUsers)}` : ''
    }`,
    options: {
      enabled: selectedTab === CONTENT,
      select: (res) => transformListsResponse(res?.result ?? {}, 'data'),
    },
  });

  const {
    data: linksData,
    isLoading: linksLoader,
    isError: linksError,
    refetch: linksRefetch,
    isFetching: linksFetching,
  } = useRESTQuery({
    key: 'content-links',
    dependencies: [
      isEnterprise,
      startDate,
      selectedUsers?.join(','),
      currentPage,
    ],
    endpoint: `/analytics/content/hyperlinks/list?from_date=${startDate}&limit=${LIMIT}&page_no=${currentPage}&is_enterprise=${isEnterprise}${
      selectedUsers ? `&user_ids=${JSON.stringify(selectedUsers)}` : ''
    }`,
    options: {
      enabled: selectedTab === LINKS,
      select: (res) => transformListsResponse(res?.result ?? {}, 'data'),
    },
  });

  const getUpdatedTable = () => {
    switch (selectedTab) {
      case CONTENT:
        return {
          tableData: contentData,
          tableColumnStructure: tableColumnStructureForContent(),
          isLoading: contentLoader || contentFetching,
          isError: contentError,
        };
      case LINKS:
        return {
          tableData: linksData,
          tableColumnStructure: tableColumnStructureForLinks(),
          isLoading: linksLoader || linksFetching,
          isError: linksError,
        };
      default:
        return {
          tableData: topicsData,
          tableColumnStructure: tableColumnStructureForTopics(),
          isLoading: topicsLoader || topicsFetching,
          isError: topicsError,
        };
    }
  };

  const handleTabSelection = (_, value) => {
    setSelectedTab(value);
    setCurrentPage(1);
  };

  const totalRecords = () => {
    const updatedTable = getUpdatedTable();
    return updatedTable?.tableData?.pagination?.total_results ?? '-';
  };

  const refetchQueries = () => {
    switch (true) {
      case selectedTab === TOPICS:
        topicsRefetch();
        break;
      case selectedTab === LINKS:
        linksRefetch();
        break;
      default:
        contentRefetch();
        break;
    }
  };

  if (getUpdatedTable()?.isError)
    return <ErrorDisplay height="70vh" onClick={refetchQueries()} />;

  return (
    <TopicsEngagementTableWrapper>
      <TopicsEngagementTableHeader>
        <h2>Content Engagement</h2>
        <p>
          {totalRecords() <= 1
            ? `${totalRecords()} Record`
            : `${totalRecords()} Records`}
        </p>
      </TopicsEngagementTableHeader>
      <Tabs
        value={selectedTab}
        onChange={handleTabSelection}
        sx={{
          '& .MuiTabs-indicator': {
            backgroundColor: '#101828',
          },
          '& .MuiButtonBase-root.MuiTab-root': {
            textTransform: 'capitalize',
            '&.Mui-selected': {
              color: '#101828',
            },
          },
        }}
      >
        <Tab label="Content" value={CONTENT} />
        <Tab label="Topics" value={TOPICS} />
        <Tab label="Links" value={LINKS} />
      </Tabs>
      {getUpdatedTable()?.isLoading ? (
        <TableSkeleton tableRowLength={5} />
      ) : (
        <>
          <Table
            data={getUpdatedTable()?.tableData?.data}
            columnStructure={getUpdatedTable().tableColumnStructure}
            height="550px"
            fixedHeader
            customMinHeight={false}
            customEmptyState={
              <EmptyState
                height="680px"
                title="No Analytics Data"
                subTitle="Send out campaigns to get insights"
              />
            }
          />
          <PaginationContainer>
            <Pagination
              pageNumber={currentPage}
              pageSize={
                getUpdatedTable()?.tableData?.pagination?.total_pages ?? 1
              }
              handlePageChange={(_, pageNumber) => {
                setCurrentPage(pageNumber);
              }}
            />
          </PaginationContainer>
        </>
      )}
    </TopicsEngagementTableWrapper>
  );
}

export default TopicsEngagementTable;
