import React, { useState, useEffect, useRef } from 'react';
import { withRouter } from 'react-router-dom';
import { get, isEmpty } from 'lodash';
import { Flex, Box } from '@rebass/grid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTimes,
} from '@fortawesome/free-solid-svg-icons';

import {
  StyledHeading,
  ButtonNoBorderBackground,
  ResponsiveImg,
  EditPortfolioModal,
  Loader,
  Modal,
  ModalCloseButton,
  ModalBody,
} from '../../../components';
import {
  getInvetmentsTabList,
  deleteInvetmentsTabList,
  getFundForInvestment,
} from '../../../services/dashboard';
import {
  AddInvetmentsButton,
  TopInsightsWrapper,
  HoldingsWrapper,
} from '../DashboardContainer.styles';
import {
  InvestmentListWrapper,
  InvestmentItem,
  TextContent,
  EditWrapper,
  ButtonIcon,
} from './YourInvestments.styles';
import { HoverButton } from '../../ConfigureContainer/EmailList/EmailList.styles';

const YourInvestments = ({
  history,
  onInvestmentClick,
  setTrendingTopicDetails,
  setTabIndex,
  trendingTopicDetails,
  tickerName,
  exploreSetState,
  setShowArticleLoader,
  currentPortfolio,
  holdingsType,
}) => {
  const [isInvestmentFetching, setInvestmentFetching] = useState(true);
  const [isEditInvestmentOpen, setEditInvestmentOpen] = useState(false);
  const [portFolioList, setPortfolioList] = useState([]);
  const [invetmentDetails, setInvetmentDetails] = useState({});
  const topInsightsWrapperRef = useRef(null);
  const [openModal, setOpenModal] = useState(false);
  const [selectedInvestmentItem, setSelectedInvestmentItem] = useState({});

  const getInvestmentDetails = () => {
    setInvestmentFetching(true);
    setShowArticleLoader(true);
    return getInvetmentsTabList()
      .then((response) => {
        setInvestmentFetching(false);
        const data = get(response, 'result.data', []);
        const id = get(data, '[0].id', '');
        exploreSetState({ currentPortfolio: id });
        //setTrendingTopicDetails({ id });
        onInvestmentClick(id);
        getFundForInvestment(id)
          .then((response) => {
            let tickers = [];
            get(response, `result.data.${id}`).forEach((data) =>
              tickers.push(data.ticker)
            );
            exploreSetState({ selectedTickers: tickers });
          })
          .catch((err) => {
            exploreSetState({ error: err });
          });
        setPortfolioList(data);
      })
      .catch((err) => {
        setInvestmentFetching(false);
      });
  };

  useEffect(() => {
    getInvestmentDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //watch tickername and autoscroll to top if updated/changed
  useEffect(() => {
    if (topInsightsWrapperRef.current && tickerName) {
      topInsightsWrapperRef.current.scrollTop = 0;
    }
  }, [tickerName]);

  const onInvestmentEdit = (invetmentItem) => {
    setEditInvestmentOpen(true);
    setInvetmentDetails(invetmentItem);
  };

  const onInvestmentDelete = () => {
    setInvestmentFetching(true);
    deleteInvetmentsTabList(selectedInvestmentItem.id)
      .then((respose) => {
        setOpenModal(false);
        if (portFolioList.length === 1) {
          exploreSetState({ investments: {} });
        }
        setSelectedInvestmentItem({});
        return getInvestmentDetails();
      })
      .catch((err) => {
        setInvestmentFetching(false);
      });
  };

  const onInvestmentItemClick = (id, holdingsType = '') => {
    exploreSetState({
      currentPortfolio: id,
      holdingsType,
      tickerName: '',
      ticker: '',
    });
    setShowArticleLoader(true);
    // setTrendingTopicDetails({ id });
    onInvestmentClick(id, holdingsType);
    getFundForInvestment(id)
      .then((response) => {
        let tickers = [];
        get(response, `result.data.${id}`).forEach((data) =>
          tickers.push(data.ticker)
        );
        exploreSetState({ selectedTickers: tickers });
      })
      .catch((err) => {
        exploreSetState({ error: err });
      });
  };

  if (isInvestmentFetching) {
    return <Loader height="180px" />;
  }
  const trendingTopicDetailsId = get(trendingTopicDetails, 'id');

  const goToPortfolioPage = () => {
    history.push('/portfolio');
  };

  const handleModalOpen = (item) => {
    setSelectedInvestmentItem(item);
    setOpenModal(true);
  };

  const handleModalClose = () => {
    setSelectedInvestmentItem({});
    setOpenModal(false);
  };

  return (
    <Flex width={1} flexDirection="column">
      {!isInvestmentFetching && isEmpty(portFolioList) && (
        <Flex width={1} justifyContent="center" alignItems="center" pt="5%">
          <Box>
            <AddInvetmentsButton
              src="/assets/images/Suitcase.png"
              alt="add invetments"
              onClick={() => history.push('/portfolio')}
            />
            <StyledHeading
              height="38px"
              onClick={() => history.push('/portfolio')}
            >
              Add Holdings
            </StyledHeading>
          </Box>
        </Flex>
      )}

      {!isInvestmentFetching && !isEmpty(portFolioList) && (
        <TopInsightsWrapper ref={topInsightsWrapperRef}>
          <InvestmentListWrapper>
            {tickerName && tickerName.length > 0 ? (
              <ButtonNoBorderBackground
                key={tickerName}
                style={{ 'min-height': '30px', width: '100%' }}
              >
                <InvestmentItem
                  width={1}
                  alignItems="center"
                  justifyContent="flex-start"
                  selected
                  style={{ margin: '0px' }}
                >
                  <Box width={1 / 10}>
                    <ResponsiveImg
                      height="20px"
                      width
                      src={`/assets/images/${holdingsType}-white.png`}
                    />
                  </Box>
                  <TextContent title={tickerName} width={7 / 10}>
                    {tickerName}
                  </TextContent>
                  <Box width={1 / 10} />
                  <Box width={0.14}>
                    <ButtonIcon
                      onClick={() =>
                        onInvestmentItemClick(currentPortfolio, holdingsType)
                      }
                    >
                      <FontAwesomeIcon icon={faTimes} />
                    </ButtonIcon>
                  </Box>
                </InvestmentItem>
              </ButtonNoBorderBackground>
            ) : null}
            {portFolioList.map((item, idx) => {
              const { id, name, funds } = item;
              return (
                <ButtonNoBorderBackground
                  key={id}
                  style={{
                    minHeight: '30px',
                    width: '100%',
                    cursor: 'default',
                    position: 'relative',
                  }}
                >
                  <InvestmentItem
                    width={1}
                    alignItems="center"
                    justifyContent="flex-start"
                    selected={
                      tickerName && tickerName.length > 0
                        ? false
                        : trendingTopicDetailsId === id
                    }
                    style={{
                      margin: '0px',
                      ...(currentPortfolio === id && {
                        border: '1px solid #6351ed',
                      }),
                    }}
                    data-testid={`holding_block${idx}`}
                  >
                    <div
                      style={{
                        width: '100%',
                        display: 'flex',
                        maxWidth: '50%',
                      }}
                      onClick={() => onInvestmentItemClick(id, 'themes')}
                      data-testid={`holding${idx}`}
                    >
                      <Box width={1 / 10}>
                        <ButtonIcon
                          style={{ marginTop: '2px' }}
                          onClick={() => handleModalOpen(item)}
                        >
                          <ResponsiveImg src="/assets/images/Icon-feather-trash-2.svg" />
                        </ButtonIcon>
                      </Box>
                      <Box width={1 / 10}>
                        <ButtonIcon
                          style={{ marginTop: '2px' }}
                          onClick={() => onInvestmentEdit(item)}
                        >
                          <ResponsiveImg src="/assets/images/Icon-feather-trash-1.svg" />
                        </ButtonIcon>
                      </Box>

                      <TextContent
                        style={{ paddingTop: '4px', display: 'flex' }}
                        bold={currentPortfolio === id && true}
                        width={1}
                      >
                        <TextContent
                          title={name && name.length > 15 ? name : ''}
                        >
                          {name && name.length > 15
                            ? `${name.slice(0, 15)}...`
                            : name}
                          &nbsp;&nbsp;
                        </TextContent>
                        {funds && funds.details && funds.details.length > 2 ? (
                          <>
                            <span
                              style={{ color: '#A8A8A8', fontWeight: 'normal' }}
                            >
                              [
                            </span>
                            {funds.details.slice(0, 2).map((item, index) => (
                              <span
                                style={{
                                  color: '#A8A8A8',
                                  fontWeight: 'normal',
                                }}
                              >
                                {item.ticker?.length > 10 ? (
                                  <span title={item.ticker}>
                                    {`${item.ticker?.slice(0, 10)}...`},
                                  </span>
                                ) : (
                                  `${item.ticker},`
                                )}
                              </span>
                            ))}

                            <span
                              style={{
                                color: '#A8A8A8',
                                fontWeight: 'normal',
                              }}
                              title={funds.details.map((item) => item.ticker)}
                            >
                              ..]
                            </span>
                          </>
                        ) : (
                          funds &&
                          funds.details &&
                          funds.details.length <= 2 &&
                          funds.details.length > 0 && (
                            <>
                              <span
                                style={{
                                  color: '#A8A8A8',
                                  fontWeight: 'normal',
                                }}
                              >
                                [
                              </span>
                              {funds.details.map((item, index, fund) => (
                                <span
                                  key={'span_element_' + index}
                                  style={{
                                    color: '#A8A8A8',
                                    fontWeight: 'normal',
                                  }}
                                >
                                  {item.ticker?.length > 10 ? (
                                    <span title={item.ticker}>
                                      {`${item.ticker?.slice(0, 10)}...`}
                                    </span>
                                  ) : (
                                    `${item.ticker}`
                                  )}
                                  {index + 1 !== fund.length ? ', ' : ''}
                                </span>
                              ))}
                              <span
                                style={{
                                  color: '#A8A8A8',
                                  fontWeight: 'normal',
                                }}
                              >
                                ]
                              </span>
                            </>
                          )
                        )}
                      </TextContent>
                    </div>
                    <>
                      <HoldingsWrapper
                        onClick={() => onInvestmentItemClick(id, 'stocks')}
                        style={{
                          backgroundColor: '#c95cfc',
                          borderRadius: '8px',
                          cursor: 'pointer',
                        }}
                        data-testid={`holding_ss${idx}`}
                      >
                        <img
                          src="/assets/images/stocks-white.png"
                          style={{ padding: '5px 4px 2px 4px', width: '18px' }}
                          alt=""
                        />
                        <span
                          style={{
                            color: '#ffffff',
                            position: 'relative',
                            top: '-2px',
                          }}
                        >
                          STOCK SPECIFIC
                        </span>
                      </HoldingsWrapper>
                      &nbsp;&nbsp;
                      <HoldingsWrapper
                        width="24%"
                        onClick={() => onInvestmentItemClick(id, 'themes')}
                        style={{
                          backgroundColor: '#7dc5d0',
                          borderRadius: '8px',
                          cursor: 'pointer',
                        }}
                        data-testid={`holding_tm${idx}`}
                      >
                        <img
                          src="/assets/images/themes-white.png"
                          style={{
                            padding: '3px 3px',
                            marginTop: '2px',
                            position: 'relative',
                            top: '1px',
                          }}
                          alt=""
                        />
                        <span
                          style={{
                            color: '#ffffff',
                            position: 'relative',
                            top: '-2px',
                          }}
                        >
                          THEMATIC
                        </span>
                      </HoldingsWrapper>
                    </>
                    <></>
                  </InvestmentItem>
                </ButtonNoBorderBackground>
              );
            })}
          </InvestmentListWrapper>
          <EditWrapper mx="auto" height="100px" justifyContent="center">
            <ButtonIcon onClick={goToPortfolioPage} data-testid="add-holding">
              <ResponsiveImg src="/assets/images/Add.svg" />
              &nbsp;&nbsp;
              <span style={{ position: 'absolute' }}>Add Holdings</span>
            </ButtonIcon>
          </EditWrapper>
        </TopInsightsWrapper>
      )}
      {isEditInvestmentOpen && (
        <EditPortfolioModal
          exploreSetState={exploreSetState}
          setModalOpen={setEditInvestmentOpen}
          isModalOpen={isEditInvestmentOpen}
          invetmentDetails={invetmentDetails}
          onInvestmentClick={onInvestmentClick}
        />
      )}
      <Modal
        onRequestClose={handleModalClose}
        shouldCloseOnOverlayClick
        isOpen={openModal}
        size="smail"
      >
        <ModalCloseButton onClick={handleModalClose} />
        <ModalBody>
          <div
            style={{
              fontWeight: 'bold',
              fontSize: '16px',
              color: '#6351ed',
              fontFamily: 'Poppins',
              wordBreak: 'break-all',
            }}
          >
            Are you sure you want to delete this?
          </div>
          {/* {selectedItem && (
            <div style={{color: '#6351ed',marginTop: '8px'}}>{selectedItem.name ? selectedItem.name : contactGroupsList.find(c => c.id === selectedContactGroup) && contactGroupsList.find(c => c.id === selectedContactGroup).name}</div>
          )} */}
          <HoverButton
            margin="9px 10px 0px 0px"
            padding="5px 28px"
            bgColor="#fff"
            hoverBackground="#6351ed"
            hoverColor="#fff"
            color="#6351ed"
            borderColor="#fff"
            onClick={onInvestmentDelete}
          >
            Yes
          </HoverButton>
          <HoverButton
            margin="9px 0px 0px 0px"
            padding="5px 28px"
            bgColor="#fff"
            color="#6351ed"
            hoverBackground="#6351ed"
            hoverColor="#fff"
            borderColor="#fff"
            onClick={handleModalClose}
          >
            No
          </HoverButton>
        </ModalBody>
      </Modal>
    </Flex>
  );
};

export default withRouter(YourInvestments);
