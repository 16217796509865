import React from 'react';
import ReactSkeleton from 'react-loading-skeleton';
import {
  Card,
  CardContent,
  CardContentWrapper,
  CardDescription,
  Cards,
  Container,
  Content,
  Header,
} from './styles';

function Skeleton() {
  return (
    <Container>
      <Header>
        <h3>Top Recommended</h3>
        <p>
          Based on engagement, consider using this content for your upcoming
          campaigns
        </p>
      </Header>
      <Content>
        <Cards>
          <ReactSkeleton width={350} height={150} />
          <ReactSkeleton width={350} height={150} />
        </Cards>
        <Cards>
          <Card>
            <ReactSkeleton width={100} height={90} />
            <CardContentWrapper>
              <CardContent>
                <ReactSkeleton width={150} height={20} />{' '}
                <CardDescription>
                  <ReactSkeleton width={50} height={20} />
                  <div className="separator"></div>
                  <ReactSkeleton width={50} height={20} />
                </CardDescription>
              </CardContent>
              <ReactSkeleton width={30} height={30} />
            </CardContentWrapper>
          </Card>
          <Card>
            <ReactSkeleton width={100} height={90} />
            <CardContentWrapper>
              <CardContent>
                <ReactSkeleton width={150} height={20} />{' '}
                <CardDescription>
                  <ReactSkeleton width={50} height={20} />
                  <div className="separator"></div>
                  <ReactSkeleton width={50} height={20} />
                </CardDescription>
              </CardContent>
              <ReactSkeleton width={30} height={30} />
            </CardContentWrapper>
          </Card>
        </Cards>
      </Content>
    </Container>
  );
}

export default Skeleton;
