/* eslint-disable react-hooks/exhaustive-deps */
import { Flex } from '@rebass/grid';
import React, { useEffect, useState } from 'react'
import { Modal, ModalBody, ModalCloseButton, ModalHeader } from '../Modal';
import { isEmpty, upperFirst } from 'lodash';
import { platforms, ROLE_TYPES } from '../../utils';
import moment from 'moment';
import { connect } from 'react-redux';
import { actions as articleDetailsActions } from '../../redux/modules/article-detail.module';
import Loader, { LoaderWrapper } from '../Loader';
import { RightToolTipWrapper } from '../../components';
import { getRoleType } from '../../redux/selector';
import { DripIconButton } from '../Form';
import { deleteScheduledArticles } from '../../services/dashboard';

const RescheduledDetailsModal = ({
  open,
  setOpen,
  topic,
  scheduleDetails,
  socialData,
  onSelectItem,
  id,
  articleDetailRequest,
  articleFetching,
  article,
  scheduleType,
  isPremiumAdvisor,
  exploreArticleRequest,
  setShowArticleLoader
}) => {

  const [loader, setLoader] = useState(false);
  const [deleteId, setDeleteId] = useState('');
  const [firstTime, setFirstTime] = useState(false);

  useEffect(() => {
    articleDetailRequest({id});
    setFirstTime(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  },[]);

  useEffect(() => {
    if(firstTime && !articleFetching && isEmpty(article.feed)) {
      setShowArticleLoader(true);
      exploreArticleRequest({
        params: {
          source: 'scheduled',
          type: scheduleType
        },
      });
      handleModalClose();
    }
  },[articleFetching, article]);
  
  const getDestination = (item) => {
    if(item.type === 'contactlist') {
      return item.name;
    } else if(item.type === 'TWITTER_FEED') {
      return 'News Feed';
    } else if(item.type === 'FACEBOOK_Pages') {
      let page = isEmpty(socialData['fb'])  ? 'Connect Facebook' : !isEmpty(socialData['fb'][0].page) && socialData['fb'][0].page[item.id] ? socialData['fb'][0].page[item.id].details.name : '-';
      return page;
    } else if(item.type === 'LINKEDIN_Pages' && item.id.length > 1) {
      let page = isEmpty(socialData['ln']) ? 'Connect Linkedin' : !isEmpty(socialData['ln'][0].page) && socialData['ln'][0].page[item.id] ?  socialData['ln'][0].page[item.id].details.name : '-';
      return page; 
    } else if(item.type === 'LINKEDIN_Pages' && item.id.length === 1) {
      return 'News Feed';
    } else if(item.type === 'FACEBOOK_Pages' && item.id.length === 1) {
      return 'News Feed';
    }
  }
  
  const getStatus = (status, platform, scheduleTime) => {
    if(platform === 'em' && status === 'in_progress') {
      return 'In Progress';
    } else {
      return upperFirst(status.replace('compliance_', ''));
    }
  }

  const onDeleteButtonClick = (item, arr) => {
    setDeleteId(item.id);
    setLoader(true);
    deleteScheduledArticles(item.id).then(res => {
      if(res === '') {
        if(arr.length === 1) {
          exploreArticleRequest({
            params: {
              source: 'scheduled',
              type: scheduleType
            },
          });
        } else {
          articleDetailRequest({id});
        }
        setLoader(false);
      }
    }).catch(err => {
      console.log(err);
      setLoader(false);
      setDeleteId('');
    })
  }

  const handleModalClose = () => {
    setOpen(false);
    setDeleteId('');
  }

  const getLeadReferralStatus = (destination) => {
    if(destination) {
      if(isEmpty(destination.lead_capture) && isEmpty(destination.referral)) {
        return 'None';
      } else if(destination.lead_capture && destination.lead_capture === "false" && destination.referral && destination.referral === "false") {
        return 'None';
      } else if(destination.lead_capture === "true") {
        return 'Leads';
      } else if(destination.referral === "true") {
        return 'Referrals';
      }
    }
  }

  return (
    <Modal size="medium75" isOpen={open} onRequestClose={handleModalClose}>
      <ModalHeader>
        <div style={{ 
          fontSize: '18px',
          color: '#030303',
          fontWeight: 500,
          padding: '0px 86px'
        }}>{topic}</div>
        <ModalCloseButton onClick={handleModalClose} />
      </ModalHeader>
      <ModalBody>
        <LoaderWrapper isLoading={articleFetching}>
          <Flex width="90%" justifyContent='center' style={{margin: '0px 12.3px 0px 20px'}}>
            <Flex width={isPremiumAdvisor ? '20%' : "15%"} justifyContent='center' fontSize='12px' color='#6351ed'>Platforms</Flex>
            <Flex width={isPremiumAdvisor ? '20%' : "15%"} justifyContent='center' fontSize='12px' color='#6351ed'>Destination</Flex>
            {!isPremiumAdvisor && (
              <Flex width="20%" justifyContent='center' fontSize='12px' color='#6351ed'>Compliance Review</Flex>
            )}
            <Flex width={isPremiumAdvisor ? '25%' : "25%"} justifyContent='center' fontSize='12px' color='#6351ed'>Schedule Date/Time</Flex>
            <Flex width={isPremiumAdvisor ? '20%' : "25%"} justifyContent='center' fontSize='12px' color='#6351ed'>Lead/Referral Capture?</Flex>
            <Flex width={isPremiumAdvisor ? '15%' : "10%"} pr={!isEmpty(article) && !isEmpty(article.feed) && !isEmpty(article.feed[0].auxiliary.scheduled.details) && article.feed[0].auxiliary.scheduled.details.filter(s => s.scheduled_by === scheduleType).length > 5 ? '28px' : ''} justifyContent='center' fontSize='12px' color='#6351ed'>Status</Flex>
          </Flex>
          <Flex flexDirection='column' style={{maxHeight: '300px', overflowX: 'hidden', overflowY: 'auto'}}>
            {console.log('article', article)}
            {!isEmpty(article) && !isEmpty(article.feed) && !isEmpty(article.feed[0].auxiliary.scheduled.details) && article.feed[0].auxiliary.scheduled.details.filter(s => s.scheduled_by === scheduleType).map((item, index, arr) => (
              <Flex width="100%" key={index} style={{minHeight: "54px"}}>
                <Flex width="95%" justifyContent='center' style={{ backgroundColor: '#fff', border: '1px solid #ececec', margin: '7px 12.3px 7px 20px', height: '40.4px', padding: '7px 12.3px 7px 0' }}>
                  <Flex width={isPremiumAdvisor ? '20%' : "15%"} alignItems="center" justifyContent='center' fontSize='12px' color='#030303' style={{ fontWeight: 600 }}>{platforms[item.destination.network]}</Flex>
                  <Flex width={isPremiumAdvisor ? '20%' : "15%"} alignItems="center" justifyContent='center' fontSize='12px' color='#030303'>{getDestination(item.destination.ids[0])}</Flex>
                  {!isPremiumAdvisor && (
                    <Flex width="20%" justifyContent='center' alignItems="center" fontSize='12px' color='#030303'>{item.is_complianced ? 'Yes' : 'No'}</Flex>
                  )}
                  <Flex width={isPremiumAdvisor ? '25%' : "25%"} alignItems="center" justifyContent='center' fontSize='12px' color='#030303'>{moment(item.date.scheduled).format('Do MMMM YYYY, h:mm a')}</Flex>
                  <Flex width={isPremiumAdvisor ? '20%' : "25%"} alignItems="center" justifyContent='center' fontSize='12px' color='#030303'>{getLeadReferralStatus(item.destination)}</Flex>
                  <Flex width={isPremiumAdvisor ? '15%' : "10%"} alignItems="center" justifyContent='center' fontSize='12px' color='#030303' pl='5px'>{getStatus(item.status, item.destination.network, item.date.scheduled)}</Flex>
                </Flex>
                {item.scheduled_by !== 'admin' && (
                  <Flex width="2%" alignItems="center">
                   {isEmpty(socialData[item.destination.network]) ?
                     <RightToolTipWrapper>
                       <img alt='calender' src="/assets/images/calender-disable.svg" height='14.2px' width='14.2px' style={{ position: 'relative', top: '2px' }} />
                       <span className='tooltiptext'>{`Please Connect to ${platforms[item.destination.network]}`}</span>
                     </RightToolTipWrapper>
                     :
                     <img alt='calender' src="/assets/images/calender-default.svg" height='14.2px' width='14.2px' style={{ cursor: 'pointer' }} onClick={() => onSelectItem(item)} />
                   }
                 </Flex>
                )}
                <Flex width="3%" alignItems="center">
                  {!loader && (
                    <DripIconButton
                      icon="trash"
                      styles={{ position: 'relative', top: '2px' }}
                      onClick={() => onDeleteButtonClick(item, arr)}
                    />
                  )}
                  {loader && deleteId === item.id && <Loader styles={{ position: 'relative', top: '4px', left: '10px' }} />}
                </Flex>
              </Flex>
            ))}
            </Flex>
        </LoaderWrapper>
      </ModalBody>
    </Modal>
  )
}

const mapStateToProps = state => {
  const roleType = getRoleType(state);
  const isPremiumAdvisor = roleType === ROLE_TYPES.ADVISOR_PREMIUM;
  return { 
    article: state.articleDetail.data,
    articleFetching: state.articleDetail.isFetching,
    isPremiumAdvisor
  }
}

const mapDispatchToProps = {
  articleDetailRequest: articleDetailsActions.api.articleDetails.request
}

export default connect(mapStateToProps, mapDispatchToProps)(RescheduledDetailsModal);
