/* eslint-disable react-hooks/exhaustive-deps */
import { Flex } from '@rebass/grid';
import React, { useState, useEffect } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import LeadCaptureModal from './LeadCaptureModal';
import useQuery from '../../customHooks/useQuery';
import ReferralModal from './ReferralModal';
import { ModalBody, ModalCloseButton, ModalV2 } from '../../components/Modal/Modal'
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { apiDetails, cloutV2Endpoints, fafToken, objectToFormData, pdfGeneratorLambdaEndpoints } from '../../services/helpers';
import { Button, LoaderWrapper, MioSelect } from '../../components';
import { faDownload, faPrint } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './ContentContainer.styles.css';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import TifinWidgetFormModal from './TifinWidgetFormModal';
import { get, isEmpty } from 'lodash';
import { ConnectMeWrapper } from './ContentContainer.styles';
import { themesData, leadEventHandlers } from '../../utils';
import DigitalCard from './DigitalCard';
import { MobilePDFReader } from 'clout-pdf-mobile-reader';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const ContentContainer = () => {
  const [numPages, setNumPages] = useState(null);
  const [leadCaptureOpen, setLeadCaptureOpen] = useState(false);
  const [referralOpen, setReferralOpen] = useState(false);
  const [pdfUrl, setPdfUrl] = useState('');
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [leadGenWidgetOpen, setLeadGenWidgetOpen] = useState(false);
  const [topFormOpen, setTopFormOpen] = useState(false);
  const [cardLoaded, setCardLoaded] = useState(false);
  const [cardData, setCardData] = useState();
  const [cfnEmailErrorMessage, setCfnEmailErrorMessage] = useState('');
  const [selectedScale, setSelectedScale] = useState(100);
  const [pdfWidth, setPdfWidth] = useState('');
  const [pdfHeight, setPdfHeight] = useState(0);
  const [isPdfLoading, setIsPdfLoading] = useState(false);
  const [pdfLoaded, setPdfLoaded] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  const query = useQuery();
  const { companyAccountId, articleId, userId } = useParams();
  const digitalCardPlatforms = ['em', 'tw', 'ln', 'fb'];

  const [leadForm, setLeadForm] = useState({
    fullname:
      (query.get('dnetwork') === 'em' || query.get('dnetwork') === 'pt') &&
        (query.get('client_first_name') ||
        query.get('client_last_name')) 
        ? `${query.get('client_first_name')} ${query.get('client_last_name')}`
        : '',
    email:
      (query.get('dnetwork') === 'em' || query.get('dnetwork') === 'pt') 
        ? query.get('client_email')
        : '',
    mobile: query.get('dnetwork') === 'pt' && query.get('client_contact') && query.get('user_type') === 'am' ? query.get('client_contact') : '',
    zip: '',
    investable_assets: query.get('dnetwork') === 'pt' && query.get('client_aum') ? query.get('client_aum') : '',
    firm_name: query.get('client_firm_name') && query.get('dnetwork') === 'pt' ? query.get('client_firm_name') : '',
});


  useEffect(() => {
    let companyId = companyAccountId.split('_');
    axios
      .get(
        `${apiDetails.baseUrl.trim()}profile_preview/company/${companyId[0]
        }/user/${query.get('uid') || userId}/`
      )

      .then((res) => {
        if (res.data.result.success) {
          setCardData(res.data.result.data.details.user);
          setCardLoaded(true);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const connectMeHandler = () => {
      if(query.get('dnetwork') === 'pt' || (cardData && cardData.lead_capture === 'smart' && query.get('dnetwork') === 'em')){
        handleSubmit();
      }
      else{
        setLeadCaptureOpen(true);
      }
  }

  const digitalCardHandler = () => {
    if(cardData && cardData.lead_capture === 'smart' && query.get('dnetwork') === 'em'){
        handleSubmit();
      }
      else{
        setLeadCaptureOpen(true);
      }
  }

  const onSuccessModalRequestClose = () => {
    setIsSuccessModalOpen(false);
  }

  const handleSubmit = (e) => {
    if (e)
      e.preventDefault();

      if(query.get('user_type') !== 'am' && cardData && cardData.lead_capture !== 'smart'){
        const val = leadForm.email;
        const emailRegExp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        leadForm.email = emailRegExp.test(val) ? val : '';
        const numRegExp = /\d/;
        leadForm.mobile = numRegExp.test(val) ? val : '';
        if(leadForm.email === '' && leadForm.mobile === ''){
            setCfnEmailErrorMessage('Please enter a valid Email Address or Phone Number.');
            return;
        }
        else{
            setCfnEmailErrorMessage('');
        }
    }
    
    let payload = {
      fullname: leadForm.fullname,
      email: leadForm.email,
      mobile: leadForm.mobile,
      type: 'leadgen',
    };

    if (!isEmpty(leadForm.lead_interest_id)){
        payload.lead_interest_id = leadForm.lead_interest_id;
    }

    if (query.get('user_type') === 'am'){
        payload.form_flag = "AM";
    }
    else{
        payload.form_flag = "CFN";
    }



    if (query.get('user_type') === 'am' && !isEmpty(leadForm.firm_name)) {
      payload.firm_name = leadForm.firm_name;
    }

    if (!isEmpty(leadForm.investable_assets)) {
      payload.investable_assets = leadForm.investable_assets;
    }

    if (payload.mobile === '' || isEmpty(payload.mobile)) {
      delete payload.mobile;
    }

    if (payload.email === '' || isEmpty(payload.email)) {
      delete payload.email;
    }

    if(query.get('faf')) {
      payload.faf = true
    }

    leadEventHandlers({
      eventName: 'FORM SUBMITTED',
      userId: query.get('uid') || userId,
      eid: query.get('eid'),
      form_type: 'lead_capture',
      dnetwork: query.get('dnetwork'),
      email_id: query.get('email'),
      first_name: query.get('first_name'),
      last_name: query.get('last_name'),
      client_email: leadForm.email || query.get('client_email'),
      client_first_name:
        (leadForm.fullname && leadForm.fullname.split(' ')[0]) ||
        query.get('first_name'),
      client_last_name:
        (leadForm.fullname && leadForm.fullname.split(' ')[1]) ||
        query.get('last_name'),
      ...(query.get('am_firm_name') !== '' ? { am_firm_name: query.get('am_firm_name') } : {}),
      user_type: query.get('user_type'),
      investable_assets: leadForm.investable_assets,
      provider: query.get('provider_name'),
      page_tag: query.get('page_tag'),
      device_type: query.get('device_type'),
      visitor_type: query.get('visitor_type'),
      ticker: query.get('ticker'),
      firm_name: leadForm.firm_name
    });
    handleContentPost(payload);
  };

  const scaleOptions = [
    { label: '50%', value: 50 },
    { label: '100%', value: 100 },
    { label: '150%', value: 150 },
  ];

  const handleContentPost = (payload) => {
    setLoading(true);
    let finalPayload = {
      ...payload,
      uid: query.get('uid') || userId,
      eid: query.get('eid'),
      lead_source: query.get('dnetwork'),
      article_id: articleId,
    };

    if (finalPayload.lead_source === 'twd') {
      delete finalPayload.eid;
    }

    if (!isEmpty(query.get('provider_name'))) {
      finalPayload.provider = query.get('provider_name');
    }

    axios
      .post(
        `${cloutV2Endpoints.baseUrl}/lead/generate`,
        finalPayload,
        {
          headers: {
            Authorization:
            `Bearer ${fafToken}`,
            'Content-Type': 'application/json'
          }
        }
      )
      .then((res) => {
        if (res.data.result.success) {
          setLoading(false);
          setMessage('Submitted Successfully!');
          if (
            finalPayload.type === 'leadgen' &&
            query.get('dnetwork') !== 'twd'
          ) {
            setTimeout(() => {
              setLeadCaptureOpen(false);
            }, 1000 * 2);
          } else if (finalPayload.type === 'referral') {
            setTimeout(() => {
              setReferralOpen(false);
            }, 1000 * 2);
          } else if (
            finalPayload.type === 'leadgen' &&
            query.get('dnetwork') === 'twd'
          ) {
            setTimeout(() => {
              setLeadGenWidgetOpen(false);
            }, 1000 * 2);
          }
          if (query.get('dnetwork') === 'pt' || query.get('dnetwork') === 'em') {
            setTimeout(() => {
                setIsSuccessModalOpen(true);
              }, 1000 * 2);
          }
        }
      })
      .catch((err) => {
        setLoading(false);
        setMessage('Oops! Something Went Wrong');
        console.log(err);
      });
  };

  useEffect(() => {
    const windowWidth = window.innerWidth;
    let pdfWidth = windowWidth - 250

    if (windowWidth < 1400 && windowWidth >= 751) {
      pdfWidth = windowWidth - 10;
    }
    if (windowWidth < 750 && windowWidth >= 560) {
      pdfWidth = windowWidth;
    }
    else if (windowWidth < 560) {
      pdfWidth = windowWidth
    }
    setPdfWidth(pdfWidth)

    // if (query.get('article_link')) {
    //   setPdfUrl(query.get('article_link').concat('#toolbar=0&zoom=' + selectedScale));
    // }


    // axios.post('https://7z4ua9x4z2.execute-api.us-east-2.amazonaws.com/dev/pdf-generator', {
    if (query.get('article_link').includes('https')) {
      axios.post(pdfGeneratorLambdaEndpoints.baseUrl, {
        url: query.get('article_link'),
        userId: query.get('user_id') || userId,
        articleId: articleId
      }).then(res => {
        setIsPdfLoading(false);
        console.log(res.data.url.length)
        if (!isEmpty(res.data.url)) {
          setPdfUrl((res.data.url).concat('#toolbar=0&zoom=' + selectedScale))
        } else {
          if (query.get('article_link')?.includes('https://apicdn.myclout.com/s3/')) {
            setPdfUrl(query.get('article_link').replace('https://apicdn.myclout.com/s3/', 'https://mycontent-statics.myclout.com/').replace(/(https?:\/\/)|(\/)+/g, "$1$2").concat('#toolbar=0&zoom=' + selectedScale));
          } else {
            setPdfUrl(query.get('article_link').concat('#toolbar=0&zoom=' + selectedScale));
          }
        }
      }).catch(err => {
        setIsPdfLoading(false);
        if (query.get('article_link')?.includes('https://apicdn.myclout.com/s3/')) {
          setPdfUrl(query.get('article_link').replace('https://apicdn.myclout.com/s3/', 'https://mycontent-statics.myclout.com/').replace(/(https?:\/\/)|(\/)+/g, "$1$2").concat('#toolbar=0&zoom=' + selectedScale));
        } else {
          setPdfUrl(query.get('article_link').concat('#toolbar=0&zoom=' + selectedScale));
        }
      })
    }

    // if (windowWidth < 750) {
    //   setSelectedScale(50);
    //   setPdfUrl(pdfUrl.concat('#toolbar=0&zoom=50'))
    // }

    if (windowWidth < 560) {
      // if (
      //   !topFormOpen &&
      //   query.get('lead_gen') === "true" &&
      //   query.get('dnetwork') !== 'wd' &&
      //   window.scrollY >= Math.floor((pdfHeight * 20) / 100)
      // ) {
      //   setTopFormOpen(true);
      //   if (query.get('dnetwork') === 'twd') {
      //     setLeadGenWidgetOpen(true);
      //   } else {
      //     setLeadCaptureOpen(true);
      //   }
      // }
      // if (query.get('referral_gen') && query.get('dnetwork') === 'em') {
      //   setReferralOpen(true);
      // }

      // if (query.get('lead_gen') === "true" && query.get('dnetwork') === 'wd') {
      //   setLeadCaptureOpen(true);
      // }

      setIsMobile(true)
    }

    // Keeping the timeouts as commented, if need be can be used in future
    if (query.get('referral_gen') && query.get('dnetwork') === 'em') {
      setTimeout(() => {
        setReferralOpen(true);
      }, 1000 * 10);
    }

    if (query.get('lead_gen') === "true" && query.get('dnetwork') === 'wd') {
      setTimeout(() => {
        setLeadCaptureOpen(true);
      }, 1000 * 10);
    }

  }, []);



  window.onscroll = (e) => {
    if (
      !topFormOpen &&
      query.get('lead_gen') === "true" &&
      query.get('dnetwork') !== 'wd' &&
      query.get('dnetwork') !== 'pt' &&
      query.get('dnetwork') !== 'em' &&
      window.scrollY >= Math.floor((pdfHeight * 20) / 100)
      && window.innerWidth > 560
    ) {
      setTopFormOpen(true);
      if (query.get('dnetwork') === 'twd') {
        setLeadGenWidgetOpen(true);
      } else {
        setLeadCaptureOpen(true);
      }
    }
  };


  const getTitleName = () => {
    if (query.get('am_firm_name') && !isEmpty(query.get('am_firm_name'))) {
      if (query.get('am_firm_name')?.includes('LTF')) {
        return query.get('am_firm_name').replace('LTF', 'LTG');
      }
      else {
        return query.get('am_firm_name');
      }
    }
    else if (cardData && cardData.firm_name && cardData.firm_name.length > 0) {
      return cardData.firm_name
    }
    else {
      return `${query.get('first_name') && query.get('first_name')} ${query.get('last_name') && query.get('last_name')}`;
    }
  };

  function onPageLoadSuccess({ originalHeight }) {
    var pdfheightNumber = originalHeight * numPages * 1.125 * ((selectedScale) / 100)
    if (selectedScale <= 50) {
      pdfheightNumber = originalHeight * numPages * 1.125 * ((selectedScale + 10) / 100)
    }
    if (pdfheightNumber < 4000) {
      setPdfHeight(pdfheightNumber);
    }
    else {
      setPdfHeight(4000)
    }
  }

  function onDocumentLoadSuccess({ numPages }) {
    setPdfLoaded(true);
    setNumPages(numPages);
  }

  const handlePrint = () => {
    var printUrl = decodeURIComponent(pdfUrl);
    printUrl = printUrl.split('#toolbar=0&zoom')[0]
    window.open(printUrl, '_blank');
  };

  const handleDownload = () => {
    var downloadUrl = decodeURIComponent(pdfUrl);
    downloadUrl = downloadUrl.split('#toolbar=0&zoom')[0]
    window.open(downloadUrl, '_blank');
  };

  const scaleChangeHandler = (source) => {
    setSelectedScale(Number(source.target.value));
    let oldUrl = pdfUrl;
    let baseUrl = oldUrl.split('#toolbar=0')[0];
    setPdfUrl(baseUrl.concat('#toolbar=0&zoom=' + source.target.value));
  };

  const mobileHandler = () => {

    // return (
    //   <div style={{ marginTop: "25%", marginBottom: "25%" }}>
    //     <Button onClick={() => handleMobileOpen()} style={{ cursor: 'pointer', overflowY: "hidden" }}>
    //       View your PDF here
    //     </Button>
    //   </div>
    // )
    return (
      <>
        <Button
          className="mobileDownloadHandler"
          onClick={() => handleDownload()}
        >
          <FontAwesomeIcon
            style={{ cursor: 'pointer' }}
            icon={faDownload}
          />
        </Button>
        {pdfUrl && (
          <MobilePDFReader
            url={pdfUrl.substring(0, pdfUrl.indexOf('#'))}
            isShowHeader={false}
          />
        )}
      </>
    )
  }

  const desktopHandler = () => {
    return (
      <>
        <div id="pdf_container">
          <iframe
            title="documentFrame"
            key={pdfUrl}
            src={pdfUrl}
            width={pdfWidth}
            height={pdfHeight}
            frameborder="0"
            scrolling="no" />
        </div>
      </>
    )

  }



  return (
    <Flex alignItems="center" width="100%" flexDirection="column" style={{ overflowX: "hidden" }}>

      {!isMobile ?
        <Flex
          className="control-btns-container"
          width="95%"
          justifyContent="space-between"
          style={{ fontSize: '12px', color: '#49484a' }}
        >
          <Flex width="30%" alignItems="center">
            {pdfLoaded && (
              <>
                <FontAwesomeIcon
                  onClick={() => handlePrint()}
                  style={{ cursor: 'pointer' }}
                  icon={faPrint}
                />
                &nbsp;
                <span onClick={() => handlePrint()} style={{ cursor: 'pointer' }}>
                  Print
                </span>
              </>
            )}
          </Flex>

          <Flex width="40%" alignItems="center" justifyContent="center">
            <span>Zoom</span>
            &nbsp;
            <MioSelect
              height="30px"
              style={{
                fontSize: '12px',
                border: '1px solid #49484a',
                color: '#49484a',
              }}
              value={selectedScale}
              onChange={(e) => scaleChangeHandler(e)}
            >
              {scaleOptions.map((item, index) => (
                <option value={item.value}>{item.label}</option>
              ))}
            </MioSelect>
          </Flex>
          <Flex width="30%" alignItems="center" justifyContent="flex-end">
            {pdfLoaded && (
              <>
                <FontAwesomeIcon
                  style={{ cursor: 'pointer' }}
                  onClick={() => handleDownload()}
                  icon={faDownload}
                />
                &nbsp;
                <span style={{ cursor: 'pointer' }} onClick={() => handleDownload()}>
                  Download
                </span>
              </>
            )}
          </Flex>
        </Flex>
        : ''

      }


      <LoaderWrapper isLoading={isPdfLoading}>
        {!isMobile ? <Document
          file={{ url: pdfUrl }}
          onLoadSuccess={onDocumentLoadSuccess}
          onLoadError={(error) => console.log("Inside Error", error)}
          error={isMobile ? "" : "Failed to Load PDF"}
          loading={isMobile || pdfLoaded ? "" : "Loading PDF"}
        >
          <Page pageNumber={1} className="page-wrapper" onLoadSuccess={onPageLoadSuccess} />
        </Document> : ''}
        {isMobile ?
          mobileHandler()
          : desktopHandler()
        }
      </LoaderWrapper>


      {query.get('lead_gen') === "true" &&
        ((digitalCardPlatforms.includes(query.get('dnetwork')) && isMobile) || (query.get('dnetwork') === 'pt' || query.get('dnetwork') === 'wd'
          || query.get('dnetwork') === 'fs_nl' || query.get('dnetwork') === 'fa' || query.get('dnetwork') === 'ims_bn' || query.get('dnetwork') === 'tfn_w'))
        && (
        <ConnectMeWrapper
          position="fixed"
          top={isMobile ? '' : "25%"}
          bottom={isMobile ? '0' : ''}
          right="1.5%"
          width="190px"
          padding="0px 10px 10px"
          height="250px"
          border="none"
          >
            {!isMobile ? <img
              width="100%"  
              height="50%"
              className="img-wrapper"
              style={{ objectFit: 'contain' }}
              src={query.get('am_firm_name')?.includes('Swan') ? 'https://apicdn.myclout.com/widget/swan_logo_black_horizontal.svg' : (query.get('logo') || "/assets/images/tifin-girl.png")}
              alt=""
            /> : ''}
            <div
              style={{
                fontSize: '13px',
                fontWeight: '600',
                fontFamily: 'Poppins',
                color: themesData.charcoalGrey,
                padding: '10px 5px 0px',
              }}
            >
              {
                cardData && cardData.digital_banner && cardData.digital_banner.length > 0 ?
                  cardData.digital_banner :
                  `Want to hear more from ${getTitleName()}?`
              }

            </div>
            <Button onClick={connectMeHandler} className="mobilePdfFormButton">Yes, I'm interested</Button>
          </ConnectMeWrapper>
        )}

      {query.get('lead_gen') === "true" &&
        ((digitalCardPlatforms.includes(query.get('dnetwork')) && !isMobile) || (query.get('dnetwork') === 'pt' || query.get('dnetwork') === 'wd'
          || query.get('dnetwork') === 'fs_nl' || query.get('dnetwork') === 'fa' || query.get('dnetwork') === 'ims_bn' || query.get('dnetwork') === 'tfn_w'))
        && (
        <Flex
          width="100%"
          flexDirection="column"
          backgroundColor="#fff"
          style={{
            position: 'fixed',
            bottom: 0,
            borderTop: "4px solid #6351ed",
            zIndex: 1,
            paddingBottom: '10px'
          }}
        >
            <div
              style={{
                fontSize: '13px',
                fontWeight: '600',
                fontFamily: 'Poppins',
                color: themesData.charcoalGrey,
                padding: '10px 5px 0px',
              }}
            >
              {
                cardData && cardData.digital_banner && cardData.digital_banner.length > 0 ?
                  cardData.digital_banner :
                  `Want to hear more from ${getTitleName()}?`
              }

            </div>
            <Flex justifyContent="center">
              <Button width="15%" onClick={connectMeHandler}>Yes, I'm interested</Button>
            </Flex>
            
        </Flex>
      )}  

      {query.get('lead_gen') === "true" && query.get('dnetwork') === 'twd' && (pdfLoaded || isMobile) && (
        <ConnectMeWrapper
          position="fixed"
          top={isMobile ? '' : "25%"}
          bottom={isMobile ? '0' : ''}
          right="0%"
          width="190px"
          padding="0px"
        >
          {!isMobile ? <img
            width="100%"
            height="50%"
            className="img-wrapper"
            src="/assets/images/tifin-girl.png"
            alt=""
          /> : ''}
          <div
            style={{
              fontSize: '13px',
              fontWeight: '600',
              fontFamily: 'Poppins',
              color: themesData.charcoalGrey,
              padding: '10px 5px 0px',
            }}
          >
            Get connected with an expert on this topic
          </div>
          <Button onClick={() => setLeadGenWidgetOpen(true)}>Connect Me</Button>
        </ConnectMeWrapper>
      )}

      {cardLoaded &&
        (pdfLoaded) &&
        query.get('lead_gen') === "true" &&
        (query.get('dnetwork') !== 'pt' && query.get('dnetwork') !== 'wd' &&
          query.get('dnetwork') !== 'fs_nl' && query.get('dnetwork') !== 'fa' && query.get('dnetwork') !== 'ims_bn' && query.get('dnetwork') !== 'tfn_w') && !isMobile && (
          <DigitalCard
            handleButtonClick={digitalCardHandler}
            position="fixed"
            top={isMobile ? '' : "18%"}
            bottom={isMobile ? '0' : ''}

            right="0%"
            mode="lead"
            name={
              cardData ? cardData.name.first + ' ' + cardData.name.last : ''
            }
            is_mobile={isMobile}
            firm_name={cardData ? cardData.firm_name : ''}
            mobile={cardData ? cardData.mobile : ''}
            email={get(cardData, 'extra_settings.contact_email', '')}
            about_me={cardData ? cardData.about_me : ''}
            digital_banner={cardData ? cardData.digital_banner : ''}
            logo={cardData ? cardData.logo_url : ''}
            linkedIn={cardData ? cardData.snetwork_urls.ln : null}
            facebook={cardData ? cardData.snetwork_urls.fb : null}
            twitter={cardData ? cardData.snetwork_urls.tw : null}
          />
        )}

      <ModalV2
        bgColor="#fff"
        isOpen={isSuccessModalOpen}
        onRequestClose={onSuccessModalRequestClose}
        shouldCloseOnOverlayClick
        width="75%"
      >
        <ModalCloseButton onClick={onSuccessModalRequestClose} />
        <ModalBody>
          <Flex
            flexDirection='column'
            margin='20px'
          >
            <Flex
              color='#6351ed'
              marginX='auto'
              fontSize='18px'
            >
              Thank you, {(leadForm.fullname && leadForm.fullname.split(' ')[0]) ||
        query.get('client_first_name')}!
            </Flex>
            <Flex
              marginTop='15px'
              marginX='auto'
            >
              By submitting your contact info, you're opting in to {cardData?.firm_name} communications. You can unsubscribe or adjust your preferences at any time.
            </Flex>
          </Flex>

        </ModalBody>
      </ModalV2>

      {leadCaptureOpen && (
        <LeadCaptureModal
          open={leadCaptureOpen}
          setOpen={setLeadCaptureOpen}
          handleContentPost={handleContentPost}
          loading={loading}
          emailId={query.get('email')}
          handleSubmit={handleSubmit}
          firstName={query.get('first_name')}
          cfnEmailErrorMessage={cfnEmailErrorMessage}
          lastName={query.get('last_name')}
          message={message}
          network={query.get('dnetwork')}
          getTitleName={getTitleName}
          clientEmailId={query.get('client_email')}
          clientFirstName={query.get('client_first_name')}
          clientLastName={query.get('client_last_name')}
          amFirmName={query.get('am_firm_name')}
          userType={query.get('user_type')}
          userId={query.get('uid') || userId}
          eid={query.get('eid')}
          provider_name={query.get('provider_name')}
          ticker={query.get('ticker')}
          page_tag={query.get('page_tag')}
          device_type={query.get('device_type')}
          visitor_type={query.get('visitor_type')}
          clientAum={query.get('client_aum')}
          isMobile={isMobile}
          clientContact={query.get('client_contact')}
          clientFirmName={query.get('client_firm_name')}
          setLeadForm={setLeadForm}
          leadForm={leadForm}
          digital_banner={cardData ? cardData.digital_banner : ''}
        />
      )}

      {referralOpen && (
        <ReferralModal
          open={referralOpen}
          setOpen={setReferralOpen}
          handleContentPost={handleContentPost}
          loading={loading}
          clientEmailId={query.get('client_email')}
          emailId={query.get('email')}
          clientFirstName={query.get('client_first_name')}
          clientLastName={query.get('client_last_name')}
          firstName={query.get('first_name')}
          lastName={query.get('last_name')}
          message={message}
          amFirmName={query.get('am_firm_name')}
          userType={query.get('user_type')}
          network={query.getsetPdfLoaded('dnetwork')}
          userId={query.get('uid') || userId}
          eid={query.get('eid')}
        />
      )}

      {leadGenWidgetOpen && (
        <TifinWidgetFormModal
          open={leadGenWidgetOpen}
          setOpen={setLeadGenWidgetOpen}
          handleContentPost={handleContentPost}
          loading={loading}
          emailId={query.get('email')}
          firstName={query.get('first_name')}
          lastName={query.get('last_name')}
          message={message}
          network={query.get('dnetwork')}
          clientEmailId={query.get('client_email')}
          clientFirstName={query.get('client_first_name')}
          clientLastName={query.get('client_last_name')}
          amFirmName={query.get('am_firm_name')}
          userType={query.get('user_type')}
          userId={query.get('uid') || userId}
          eid={query.get('eid')}
          ticker={query.get('ticker')}
          page_tag={query.get('psetLeadCaptureOpenage_tag')}
          device_type={query.get('device_type')}
          visitor_type={query.get('visitor_type')}
          provider_name={query.get('provider_name')}
        />
      )}
    </Flex>
  );
};

export default ContentContainer;
