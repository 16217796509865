import { Flex } from '@rebass/grid';
import { Tab } from 'react-tabs';
import styled from 'styled-components';
import { themesData } from '../../../utils/constants';
import React from 'react';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
const {purpleishBlue} = themesData;


export const EmailPlatformContainer = styled(Flex)`
  margin: 4px 0;
  display: table;
  border-radius: 4px;
  max-height: 300px;
  width: 20%;
`;
export const Header = styled('div')`
  display: flex;
  text-align: left;
  color: #101828;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  padding: 12px 8px;
  background: #fff;
`;
export const TabContainer = styled('div')`
  display: flex;
  border-top: 1px solid #E4E7EC;
  border-bottom: 1px solid #E4E7EC;
  background: #fff;
  justify-content: start;
`;

export const TabItem = styled('div')`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  text-align: center;
  color: #475467;
  background: #fff;
  cursor: pointer;
  padding: 8px;
  margin-right: 10px;
  :hover {
    opacity: 0.7
  }
`;

export const SocialTabItem = styled.div.attrs({
  className: 'chips',
})`
  .chip {
    position: relative;
    display: flex;
    padding: 7px 10px 7px 9px;
    height: 40px;
    font-size: 12px;
    background-color: #fff;
    color: ${({ active }) => (active ? '#000000' : '#aaa')};
    font-weight: 600;
    align-items: center;
    cursor: pointer;
    border-bottom: ${({ active }) =>
      active ? '1px solid #101828' : '1px solid transparent'};
  }

  .chip img {
    float: left;
    margin: 0px 10px 0px 0px;
    height: 28px;
    width: 28px;
    opacity: ${({ active }) => (active ? '1' : '0.7')};
  }
`;

export const AdvisorListsBoxScrollWrapper = styled(Flex)`
  max-height: ${({ maxHeight }) => maxHeight || ''};
  height:  ${({ height }) => height || ''};
  overflow-x: hidden;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 5px;
  } 
  &::-webkit-scrollbar-track {
    background: #e9f6e6 !important;
  }
  &::-webkit-scrollbar-thumb {
    background: #bccdd4 !important;
  }
`;
export const RadioOnetimeAndSeriesInput = styled.input`
  border: 2px solid white;
  box-shadow: 0 0 0 1px #aaa;
  appearance: none;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  background-color: #fff;
  transition: all ease-in 0.2s;

  &:checked {
    background-color: #816EF8;
    box-shadow: 0 0 0 1px #aaa;
  }
`;

export const NewTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props}  classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#FFFFFF',
    boxShadow: '0px 4px 8px rgba(16, 24, 40, 0.2)',
    padding: 0,
    borderRadius: '8px !important',
  },
}));