import React from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumbs } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

import {
  BreadCrumbWrapper,
  BreadCrumbsItemWrapper,
  LeftIconWrapper,
} from './styles';
import { useHistory } from 'react-router-dom';

function BreadCrumbs({ breadCrumbItems = [] }) {
  const history = useHistory();

  return (
    <BreadCrumbWrapper>
      <LeftIconWrapper>
        <ChevronLeftIcon onClick={() => history.goBack()} />
      </LeftIconWrapper>
      <BreadCrumbsItemWrapper>
        <Breadcrumbs aria-label="breadcrumb">
          {Array.isArray(breadCrumbItems) &&
            breadCrumbItems?.length > 0 &&
            breadCrumbItems?.map((item, index) => {
              if (item?.path) {
                return (
                  <Link
                    key={index}
                    to={item.path}
                    className="previous-route-link"
                  >
                    {item?.title ?? ''}
                  </Link>
                );
              } else {
                return (
                  <span key={index} className="active-route-link">
                    {item?.title ?? ''}
                  </span>
                );
              }
            })}
        </Breadcrumbs>
      </BreadCrumbsItemWrapper>
    </BreadCrumbWrapper>
  );
}

export default BreadCrumbs;
