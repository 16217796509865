import { get } from 'lodash';
import { takeLatest, all, call, put, select } from 'redux-saga/effects';
import { actions as contentlibActions, moreActions as contentlibMoreActions } from '../modules/content-lib.module';
import { moreActions as newsletterMoreActions } from '../modules/newsletter.module';
import {
  getContentLibrary,
  deleteLibraryArticle,
  postArticlesToNewsletterBucket,
  postAddToBookmarks,
  deleteFromBookmarks
} from '../../services/dashboard';
import { getChannelId } from '../selector';
import { deleteBucketArticle } from '../../services/newsletter';

function* contentlibRequest({ payload }) {
  try {
    const mode = get(payload, 'mode', undefined);
    const channelId = get(payload, 'channelId', false);
    const pagination_token = get(payload, 'pagination_token', undefined);
    const type = get(payload, 'type', undefined);
    const channel = channelId ? channelId : yield select(getChannelId);
    // console.log('This is channel ', channel);
    const response = yield call(getContentLibrary, channel, mode, pagination_token, type);
    const data = get(response, 'result.data', {});
    yield put(contentlibActions.api.contentlib.response(data));
  } catch (error) {
    yield put(contentlibActions.api.contentlib.error(error));
  }
}

function* deleteArticeRequest({ payload }) {
  try {
    const { deleteId, myClout } = get(payload, 'params', {});
    yield call(deleteLibraryArticle, deleteId, myClout);
    yield put(contentlibMoreActions.custom.contentlib.deleteResponse());
  } catch (error) {
    yield put(contentlibActions.api.contentlib.error(error));
  }
}

function* addToNewsletterRequest({ payload }) {
  try {
    const {articleId, themeId, type} = get(payload, 'params', {});
    const apiCall = postArticlesToNewsletterBucket;
    const response = yield call(apiCall, {articleId, themeId});
    if(response && response.result && response.result.success) {
      yield put(
      contentlibMoreActions.custom.contentlib.addToNewsletterResponse({
          bucketId: response.result.data && response.result.data.bucket_article_id,
          inNewsletter: true
      }));
      yield put(newsletterMoreActions.custom.newsletter.bucketCountRequest());
    }
  } catch (error) {
    yield put(contentlibActions.api.contentlib.error(error));
  }
}

function* removeFromNewsletterRequest({ payload }) {
  try {
    const { articleId, type } = get(payload, 'params', {});
    const apiCall = deleteBucketArticle;
    const response = yield call(apiCall, { id: articleId });
    if(response === '') {
      yield put(
        contentlibMoreActions.custom.contentlib.removeFromNewsletterResponse({
          bucketId: '',
          inNewsletter: false
        }));
      yield put(newsletterMoreActions.custom.newsletter.bucketCountRequest());
    } 
  } catch (error) {
    yield put(contentlibActions.api.contentlib.error(error));
  }
}

function* addToBookmarksRequest({ payload }) {
  try {
    const {articleId, themeId, type} = get(payload, 'params', {});
    const apiCall = postAddToBookmarks;
    const response = yield call(apiCall, articleId, themeId);
    console.log('RESPONSE - ', response);
    if(response && response.result && response.result.success) {
      yield put(contentlibMoreActions.custom.contentlib.addToBookmarksResponse({ 
        bookmarkArticleId: response.result.data && response.result.data.bookmark_article_id,
        isBookMark: true
      })) 
    }
  } catch (error) {
    yield put(contentlibActions.api.contentlib.error(error));
  }
}

function* removeFromBookmarksRequest({ payload }) {
  try {
    const { articleId, type } = get(payload, 'params', {});
    const apiCall = deleteFromBookmarks;
    const response = yield call(apiCall, articleId);
    if(response === '') {
      yield put(contentlibMoreActions.custom.contentlib.removeFromBookmarksResponse({ 
        bookmarkArticleId: '',
        isBookMark: false
      })) 
    } 
  } catch (error) {
    yield put(contentlibActions.api.contentlib.error(error));
  }
}

function* contentlibSaga() {
  yield all([
    takeLatest(contentlibActions.api.contentlib.request, contentlibRequest),
    takeLatest(contentlibActions.api.contentlib.delete, deleteArticeRequest),
    takeLatest(contentlibMoreActions.custom.contentlib.addToNewsletter, addToNewsletterRequest),
    takeLatest(contentlibMoreActions.custom.contentlib.removeFromNewsletter, removeFromNewsletterRequest),
    takeLatest(contentlibMoreActions.custom.contentlib.addToBookmarks, addToBookmarksRequest),
    takeLatest(contentlibMoreActions.custom.contentlib.removeFromBookmarks, removeFromBookmarksRequest),
  ]);
}

export default contentlibSaga;
