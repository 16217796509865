import { get } from 'lodash';
import { takeLatest, all, call, put } from 'redux-saga/effects';
import { actions as exploreActions } from '../modules/advisor-group.module';
import { getGroupsList } from '../../services/dashboard';

function* advisorGroupsRequest({ payload }) {
  try {
    const response = yield call(getGroupsList);
    const data = get(response, 'result.data', {});
    yield put(exploreActions.api.groups.response(data));
  } catch (error) {
    yield put(exploreActions.api.groups.error(error));
  }
}

function* advisorGroupsSaga() {
  yield all([
    takeLatest(exploreActions.api.groups.request, advisorGroupsRequest),
  ]);
}

export default advisorGroupsSaga;
