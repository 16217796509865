import { styled } from '@mui/material';

export const Wrapper = styled('div')(() => ({
  width: '100%',
  maxWidth: '330px',
  minWidth: '200px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '1rem',
  paddingRight: '0.375rem',
}));

export const Header = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: '0.13rem',

  h3: {
    fontSize: '1.25rem',
    fontWeight: '500',
    color: '#6250ED',
    margin: 0,
    textAlign: 'left',
  },

  p: {
    fontSize: '0.75rem',
    fontWeight: '400',
    color: '#101828',
    margin: 0,
    textAlign: 'left',
  },
}));

export const List = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '0.5rem',
}));

export const ListItem = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  padding: '1rem',
  background: 'rgba(255, 255, 255, 0.70)',
  borderRadius: '0.5rem',

  p: {
    width: '100%',
    fontSize: '0.875rem',
    fontWeight: '500',
    color: '#101828',
    margin: 0,
    textAlign: 'left',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
}));
