import React, { useState } from 'react';
import { Flex, Box } from '@rebass/grid';
import { Formik } from 'formik';
import * as Yup from 'yup';

import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Input,
  Button,
} from '../';

import { ForgotPasswordModalContent } from './ForgotPasswordModal.styles';
import axios from 'axios';
import { apiDetails, objectToFormData } from '../../services/helpers';
import { getAuthToken } from '../../services/login';
import { LoaderWrapper } from '../Loader';
import { themesData } from '../../utils';

const forgotPasswordScehma = Yup.object().shape({
  email: Yup.string()
    .email('Invalid Email Id')
    .required('Please enter Email Id'),
});

const ForgotPasswordModal = ({ isModalOpen, setModalOpen, roleType }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const onSendClick = (values) => {
    let authToken = getAuthToken({ email: values.email });
    let payload = {
      email: values.email,
      auth_code: authToken,
      action: 'forgot_password',
      role_type: roleType ? roleType : 'general',
    };
    setIsLoading(true);
    setErrorMsg('');

    axios
      .post(
        `${apiDetails.baseUrl}forgot_password/send`,
        objectToFormData(payload),
        {
          headers: { Authorization: `Bearer ${authToken}` },
        }
      )
      .then((res) => {
        if (res.data.result.success) {
          setErrorMsg(
            'Unique verification code sent successfully to your email.'
          );
          setTimeout(() => {
            setModalOpen(false);
          }, 3000);
        }
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  const initialState = { email: '' };
  return (
    isModalOpen && (
      <Modal
        onRequestClose={() => setModalOpen(false)}
        shouldCloseOnOverlayClick
        isOpen
        size="small"
      >
        <ModalHeader>Forgot Password</ModalHeader>
        <ModalCloseButton onClick={() => setModalOpen(false)} />
        <ModalBody>
          <Formik
            initialValues={initialState}
            onSubmit={onSendClick}
            validationSchema={forgotPasswordScehma}
          >
            {({ handleSubmit, handleChange, errors }) => {
              return (
                <Flex width={1} flexDirection="column">
                  <ForgotPasswordModalContent width={1}>
                    <div className="body-content">
                      Enter your registered Email Address to receive a
                      Verification Code
                    </div>
                    <div className="input-wrapper">
                      <Input
                        id="email"
                        name="email"
                        placeholder="Email Address"
                        onChange={handleChange}
                        error={errors.email}
                      />
                    </div>
                  </ForgotPasswordModalContent>
                  <Box width={1} pt="40px">
                    <LoaderWrapper isLoading={isLoading}>
                      {errorMsg.length > 0 ? (
                        <Flex color={themesData.purpleishBlue}>{errorMsg}</Flex>
                      ) : (
                        <Button onClick={handleSubmit}>SEND CODE</Button>
                      )}
                    </LoaderWrapper>
                  </Box>
                </Flex>
              );
            }}
          </Formik>
        </ModalBody>
      </Modal>
    )
  );
};

export default ForgotPasswordModal;
