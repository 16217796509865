import { handleActions } from 'redux-actions';
import { apiModuleDefaultState, createApiActions } from './helpers';

export const actions = createApiActions('channel');

const reducer = handleActions(
  {
    [actions.api.channel.request]: () => ({
      isFetching: true,
      isError: false,
    }),
    [actions.api.channel.response]: (_, { payload }) => ({
      isFetching: false,
      isError: false,
      data: payload,
    }),
    [actions.api.channel.error]: (_, { payload }) => ({
      isFetching: false,
      isError: true,
      error: payload,
    }),
    [actions.api.channel.setstate]: (state, {payload}) => ({
      ...state,
      ...payload,
    })
  },
  { ...apiModuleDefaultState, data: [], error: null }
);

export default reducer;
