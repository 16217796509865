import { styled } from '@mui/material';

export const ContentWrapper = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '1.5rem',
  padding: '1rem',
}));

export const TablistWrapper = styled('div')(() => ({
  width: '100%',
}));

export const Articles = styled('div')(() => ({
  width: '100%',
  display: 'grid',
  gridTemplateColumns: '1fr',
  gap: '2rem',
  overflow: 'hidden',
}));

export const ArticleLoader = styled(Articles)(() => ({
  marginTop: '2rem',
}));

export const ArticlesSkeleton = styled(Articles)(() => ({
  margin: '2rem 0',
}));
