import { Flex } from '@rebass/grid';
import styled from 'styled-components';

export const ContentArticlesContainer = styled(Flex)`
  flex-direction: column;
  border: 1px solid #cfd9e4;
  border-radius: 4px;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding: 10px;
  height: 460px;
  margin-bottom: ${({ mb }) => mb || '25px'};
`;

export const SuggestedArticlesTab = styled.div`
  border: 0px;
  border-bottom: ${({ active }) => (active ? '3px solid #6351ed' : '0')};
  color: ${({ active, disabled }) =>
    active ? '#6351ed' : disabled ? '#adadad' : '#000000'};
  font-weight: 600;
  font-size: 14px;
  margin-right: 35px;
  cursor: pointer;
  height: 30px;
`;

export const SummaryInstructionDiv = styled.div`
  height: 299px;
  overflow-y: auto;
  text-align: left;
  color: #4e6780;
  font-size: 12px;
  border: 1px solid #cfd9e4;
  margin-top: 10px;
  background-color: #fff;
  border-radius: 4px;
  width: 100%;
  padding: 5px;
`;

export const FooterBtnsWrapper = styled(Flex)`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999999;
`;

export const RadioInput = styled.input`
  border: 2px solid white;
  box-shadow: 0 0 0 1px #aaa;
  appearance: none;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  background-color: #fff;
  transition: all ease-in 0.2s;

  &:checked {
    background-color: #6351ed;
    box-shadow: 0 0 0 1px #aaa;
  }
`;

export const RadioInputLabel = styled.label`
  color: #000;
  font-size: 14px;
  margin-top: 5px;
  margin-left: 5px;
`;

export const ZoomContainer = styled.div`
  width: ${({ width }) => width || '500px'};
  height: 150px;
  border-radius: 4px;
  text-align: left;
  padding: 0px 10px;
  color: black;
  border: 1px solid #c9d0d8;
  max-height: 150px;
  max-width: 750px;
  background-color: #fff;
  overflow: auto;
  line-height: 1.8;
  
  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background: #e9f6e6 !important;
  }

  &::-webkit-scrollbar-thumb {
    background: #bccdd4 !important;
  }
`;
export const ScrollWrapper = styled(Flex)`
  flex-direction: column;
  max-height: ${({ maxHeight }) => maxHeight || ''};
  height:  ${({ height }) => height || ''};
  position:  ${({ position }) => position || ''};
  overflow-x: hidden;
  overflow-y: auto;
  
  &::-webkit-scrollbar {
    width: 5px;
  } 
  &::-webkit-scrollbar-track {
    background: #e9f6e6 !important;
  }
  &::-webkit-scrollbar-thumb {
    background: #bccdd4 !important;
  }
`;

export const CustomCloutScrollList = styled.ul`
  list-style-type: none; 
  padding-left: ${({paddingLeft}) => paddingLeft || ''};
  width: ${({width}) => width || ''};
`;
