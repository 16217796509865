import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Flex } from '@rebass/grid';
import React, { useState } from 'react';
import { Button } from '../../../components';
import { themesData } from '../../../utils';
import AssignArticles from '../AssignArticles/AssignArticles';
import { DripTabs, DripVerticalLine } from '../CreateCampaign/CreateCampaign.styles';
import { LineHorizontalSeparator } from '../PlanCampaign/PlanCampaign.styles';
import SelectArticles from '../SelectArticles/SelectArticles';
import WriteSummary from '../WriteSummary/WriteSummary';

const AssignContent = ({ 
    createFlow, 
    setCreateFlow, 
    setCreateScreenIndex, 
    suggestedArticles, 
    getMyContentArticles,
    fetchBookmarkedArticles,
    fetchRecommendedArticles,
    getAccountId,
    campaignSetState,
    campaignSelectedArticles,
    campaignSelectedWeek,
    campaignSelectedDays,
    campaignArticles,
    campaignId,
    publishedContent,
    setObjectivesTabData,
    socialNetworkData,
    endCreateFlow,
    isPremiumAdvisor,
    channelId,
    suggestedArticlesLoader,
    toggleSuggestedArticlesLoader,
 }) => {

    const [assignContentTab, setAssignContentTab] = useState(0);
    const [enableNext, toggleEnableNext] = useState(false);

    const getDescription = () => {
        let description;
        switch (assignContentTab) {
            case 0:
                description = `Select the articles that will be shared by emails and/or social media posts. 
                    Our recommended articles are based on the selected email lists and topics, and are most likely to 
                    get good engagement. You can also add any articles that you have added to Clout or bookmarked.`
                break;
            case 1:
                description = `Write the share copy for articles. For Facebook/LinkedIn/Twitter posts, 
                it is the content of the posts. For emails, it is a brief summary of the article that will be included 
                along the hero image of the article, in addition to the main email message.`
                break;
            case 2:
                description = `Assign articles to various emails and social media posts. You can assign multiple articles 
                to emails, but only one article to social media posts. Each selected article tiles show the platforms that 
                it is assigned to. Each planned post tile shows the article(s) assigned to it.`
                break;
            default:
                description = `Select the articles that will be shared by emails and/or social media posts. 
                Our recommended articles are based on the selected email lists and topics, and are most likely to 
                get good engagement. You can also add any articles that you have added to Clout or bookmarked.`
                break;
        }
        return description;
    }

    const onAssignContentTabChange = type => {
        if(type === 'next')
            setAssignContentTab(assignContentTab + 1);
        else
            setAssignContentTab(assignContentTab - 1);
    }

    return (
        <Flex width="100%" height="100%" flexDirection="column">
            <Flex 
                width="100%" 
                justifyContent="space-between" 
                alignItems="center"
                backgroundColor="#fff"
                padding="15px 20px"
                style={{ height: '8%', zIndex: 1, borderBottom: '1px solid #6250ED' }}
            >
                <Flex color={themesData.charcoalGrey} fontSize="12px" fontWeight='600'>
                    Assign Content | {campaignSelectedWeek || 'Week 1'} | Lead Capture | {campaignSelectedDays || 'Mon-Tue-Fri'}
                </Flex>
                <Flex>
                    <FontAwesomeIcon 
                        icon={faTimes} 
                        color={themesData.charcoalGrey} 
                        style={{cursor: 'pointer'}}
                        onClick={() => setCreateScreenIndex(0)}
                    />
                </Flex>
            </Flex>
            <Flex width="100%" height="9%" flexDirection="column">
                <Flex
                    padding="15px 30px"
                    backgroundColor="#fff"
                    height="100%"
                    alignItems="center"
                    width="100%"
                >
                    <Flex
                        width="100%"
                        pb="10px"
                        alignItems="center"
                        style={{ borderBottom: '1px solid #CFD9E4' }}
                    >
                        <Flex justifyContent="space-between" width="30%">
                            <DripTabs active={assignContentTab === 0} completed={assignContentTab === 0}>
                                Select Content
                            </DripTabs>
                            <DripTabs active={assignContentTab === 1} completed={assignContentTab === 1}>
                                Write Share Copy
                            </DripTabs>
                            <DripTabs active={assignContentTab === 2} completed={assignContentTab === 2}>
                                Assign Content
                            </DripTabs>
                        </Flex>
                        <Flex width="70%" pl="20px" alignItems="center">
                            <DripVerticalLine />
                            <Flex pl="20px">
                                <span style={{ color: themesData.charcoalGrey, fontSize:"12px" }}>Instruction:</span>
                                <span style={{ color: '#4E6780', paddingLeft: '4px', textAlign: 'left', fontSize: '10px' }}>
                                    {getDescription()}
                                </span>
                            </Flex>
                        </Flex>
                    </Flex>
                </Flex>
            </Flex>
            {assignContentTab === 0 ? (
                <SelectArticles 
                    campaignSelectedArticles={campaignSelectedArticles} 
                    suggestedArticles={suggestedArticles}
                    getMyContentArticles={getMyContentArticles}
                    fetchBookmarkedArticles={fetchBookmarkedArticles}
                    fetchRecommendedArticles={fetchRecommendedArticles} 
                    toggleEnableNext={toggleEnableNext} 
                    enableNext={enableNext}
                    setCreateScreenIndex={setCreateScreenIndex}
                    campaignSetState={campaignSetState}
                    campaignSelectedWeek={campaignSelectedWeek} 
                    campaignId={campaignId}
                    onAssignContentTabChange={onAssignContentTabChange}
                    endCreateFlow={endCreateFlow}
                    setAssignContentTab={setAssignContentTab}
                    isPremiumAdvisor={isPremiumAdvisor}
                    channelId={channelId}
                    suggestedArticlesLoader={suggestedArticlesLoader}
                    toggleSuggestedArticlesLoader={toggleSuggestedArticlesLoader}
                />
            ) : assignContentTab === 1 ? (
                <WriteSummary 
                    campaignSelectedArticles={campaignSelectedArticles} 
                    toggleEnableNext={toggleEnableNext} 
                    enableNext={enableNext} 
                    onAssignContentTabChange={onAssignContentTabChange}
                    campaignSetState={campaignSetState} 
                    campaignId={campaignId}
                    endCreateFlow={endCreateFlow}
                    setAssignContentTab={setAssignContentTab}
                    setCreateScreenIndex={setCreateScreenIndex}
                    setObjectivesTabData={setObjectivesTabData}
                />
            ) : (
                <AssignArticles 
                    campaignSelectedArticles={campaignSelectedArticles} 
                    campaignSelectedWeek={campaignSelectedWeek} 
                    campaignArticles={campaignArticles}
                    getAccountId={getAccountId}
                    enableNext={enableNext}
                    toggleEnableNext={toggleEnableNext} 
                    onAssignContentTabChange={onAssignContentTabChange}
                    campaignSetState={campaignSetState}
                    setCreateScreenIndex={setCreateScreenIndex}
                    setAssignContentTab={setAssignContentTab}
                    campaignId={campaignId}
                    publishedContent={publishedContent}
                    setObjectivesTabData={setObjectivesTabData}
                    socialNetworkData={socialNetworkData}
                    endCreateFlow={endCreateFlow}
                />
            )}
        </Flex>
    );
}

export default AssignContent;