import React from 'react';
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer, CartesianGrid } from 'recharts';

const BarGraph = ({
  data,
  showLegend=false,
  height,
  width,
  tickRequired=true,
  errorDisplay,
  loading,
  handleRetryClick,
  dataKey,
  noInterval=false
}) => {
    return ( 
      <ResponsiveContainer width="100%" height="100%">
          <BarChart
            width={width || 500}
            height={height || 300}
            data={data}
            margin={{
              top: 5,
              right: 0,
              left: 0,
              bottom: 5,
            }}
          >
            <CartesianGrid stroke="#CCCCCC" strokeDasharray="3 3"/>
            <XAxis dataKey={dataKey[0]} tickCount={data.length} interval={noInterval ? 'equidistantPreserveStart' : 0} />
            <YAxis tick={tickRequired} allowDecimals={false}/>
            <Tooltip />
            {showLegend && (
              <Legend
                wrapperStyle={{
                  fontSize: '10px',
                  fontFamily: 'Poppins',
                }}
              />
            )}
            <Bar dataKey={dataKey[1]} barSize={20} fill="#8884d8" />
          </BarChart> 
      </ResponsiveContainer>
    );
  }

export default BarGraph;
