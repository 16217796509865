import { Flex } from '@rebass/grid';
import React from 'react';
import {
  StatBubble,
  StatBubbleWrapper,
} from '../DripDashboardListItem/DripDashboardListItem.styles';
import { Chips } from './CampaignPostInfo.styles';
import { Button } from '../../../../components';
import { connect } from 'react-redux';
import { actions as campaignActions } from '../../../../redux/modules/campaignDetails.module';
import { get, isEmpty } from 'lodash';

const CampaignPostInfo = ({
  postInfo,
  id,
  displayMode,
  campaignDetailsRequest,
  campaignsDetailsData,
  campaignDetailsView,
  setDetailsView,
  socialNetworkData,
  detailsHandler
}) => {
  const infoFieldsMapper = {
    postsPerWeek: 'Posts Per Week',
    totalPosts: 'TotalPosts',
    scheduled: 'Scheduled',
    needContent: 'Need Content',
    completed: 'Completed',
    failed: 'Failed',
    missed: 'Missed',
  };
  // console.log('id', id);
  // console.log('campaignsDetailsData', campaignsDetailsData);
  return (
    <>
      <Flex
        flexDirection="row"
        display="flex"
        flex="1"
        marginLeft="5px"
        justifyContent="center"
        alignItems="center"
      >
        <Flex flexDirection="column" display="flex" flex="1">
          <Flex
            flexDirection="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            display="flex"
            flex="1"
            marginBottom="5px"
          >
            <span
              style={{
                color: '#4E6780',
                fontSize: displayMode === 'block' ? '10px' : '12px',
              }}
            >
              {displayMode === 'block' ? 'Progress | ' : ''}Posts Per Week:{' '}
              {isEmpty(get(postInfo, 'postsPerWeek', [])) ? 0 : Object.values(postInfo.postsPerWeek)[0]} | Total Posts: {postInfo?.totalPosts}
            </span>
          </Flex>
          <Flex
            flexDirection="row"
            justifyContent="space-between"
            alignItems="space-between"
            marginRight="5px"
            display="flex"
            flex="1"
          >
            <Chips
              mode={postInfo?.completed ? '100%' : '30%'}
              displayMode={displayMode}
            >
              <div className="chip completed">
                <img src="/assets/images/drip/check_circle.svg" alt="" />
                {postInfo?.completed ? postInfo.completed : '-'}
                <span className="tooltiptext">Completed</span>
              </div>
            </Chips>
            <Chips
              mode={postInfo?.failed ? '100%' : '30%'}
              displayMode={displayMode}
            >
              <div className="chip failed">
                <img src="/assets/images/drip/cancel.svg" alt="" />
                {postInfo?.failed ? postInfo.failed : '-'}
                <span className="tooltiptext">Failed</span>
              </div>
            </Chips>
            <Chips
              mode={postInfo?.missed ? '100%' : '30%'}
              displayMode={displayMode}
            >
              <div className="chip missed">
                <img src="/assets/images/drip/not_interested.svg" alt="" />
                {postInfo?.missed ? postInfo.missed : '-'}
                <span className="tooltiptext">Missed</span>
              </div>
            </Chips>
            <Chips
              mode={postInfo?.scheduled ? '100%' : '30%'}
              displayMode={displayMode}
            >
              <div className="chip scheduled">
                <img src="/assets/images/drip/schedule.svg" alt="" />
                {postInfo?.scheduled ? postInfo.scheduled : '-'}
                <span className="tooltiptext">Scheduled</span>
              </div>
            </Chips>
            <Chips
              mode={postInfo?.needContent ? '100%' : '30%'}
              displayMode={displayMode}
            >
              <div className="chip content">
                <img src="/assets/images/drip/radar.svg" alt="" />
                {postInfo?.needContent ? postInfo.needContent : '-'}
                <span className="tooltiptext">Need Content</span>
              </div>
            </Chips>
          </Flex>
        </Flex>
        {displayMode !== 'block' && (
          <Button
            styles={{ border: '1px solid blue', borderRadius: '5px' }}
            color="blue"
            bgColor="#ffffff"
            margin="0px 5px"
            onClick={() => {
              campaignDetailsRequest({ id: id });
              setDetailsView(true);
              detailsHandler(id);
            }}
          >
            View
          </Button>
        )}
      </Flex>
    </>
  );
};

const mapStateToProps = (state) => {
  // console.log('state', state);
  return {
    campaignsDetailsData: state.campaignDetails.data,
  };
};

const mapDispatchToProps = {
  campaignDetailsRequest: campaignActions.api.campaignDetails.request,
};

// export default CampaignPostInfo;
export default connect(mapStateToProps, mapDispatchToProps)(CampaignPostInfo);
