import { styled } from '@mui/material';
import Button from 'src/componentsV2/Button';

export const Wrapper = styled('div')(() => ({
  width: '100%',
  background: 'rgba(247, 245, 255, 0.70)',
  backdropFilter: 'blur(60px)',
}));

export const Container = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '1.5rem',
  padding: '3rem 1rem',
}));

export const Header = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyContent: 'center',
  gap: '0.5rem',

  p: {
    fontSize: '1.25rem',
    fontWeight: '500',
    color: '#667085',
    margin: 0,
  },

  h3: {
    fontSize: '2.25rem',
    fontWeight: '600',
    color: '#101828',
    margin: 0,
  },
}));

export const Content = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  alignItems: 'stretch',
  gap: '1rem',
}));

export const SearchWrapper = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  alignItems: 'stretch',

  button: {
    height: '100%',
    borderRadius: '0 0.25rem 0.25rem 0',
    boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.12)',
  },
}));

export const SearchContainer = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  padding: '0.75rem',
  background: '#fff',
  border: '1px solid #E4E7EC',
  borderRadius: '0.25rem 0 0 0.25rem',
  boxShadow: '1px 2px 2px 0px rgba(16, 24, 40, 0.12) inset',

  '.search-icon, .clear-icon': {
    margin: '0 0.25rem',
    color: '#667085',
    fontSize: '1.5rem',
  },

  '.clear-icon': {
    cursor: 'pointer',
  },

  input: {
    width: '100%',
    minWidth: '150px',
    height: '1.3125rem',
    border: 'none',
    fontSize: '0.875rem',
    fontWeight: '400',
    fontFamily: 'Poppins',
    color: '#667085',
  },
}));

export const AddContentButton = styled(Button)(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '0.5rem',
  whiteSpace: 'nowrap',
  height: '100%',

  svg: {
    color: '#4634D0',
    fontSize: '1.5rem',
  },
}));

export const Bookmark = styled(Button)(() => ({
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',

  svg: {
    fontSize: '1.5rem',
    color: '#6250ED',
  },
}));
