/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo, useState } from 'react';
import { Container } from '@mui/material';
import withHomeLayout from 'src/hoc/withHomeLayout';
import CampaignCount from './CampaignCount';
import TopPerformingCampaigns from './TopPerformingCampaigns';
import TopPerformingContent from './TopPerformingContent';
import TopContacts from './TopContacts';
import KeyInsights from './KeyInsights';
import TimePeriod from './TimePeriod';
import UserDropdown from './UserDropdown';
import Topics from './Topics';
import {
  Actions,
  Header,
  Heading,
  Layout,
  MainContent,
  SideContent,
  Wrapper,
} from './styles';

function Home({ userData, campaignSetState }) {
  const [enterprise, setEnterprise] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [timePeriod, setTimePeriod] = useState({
    label: 'Past 90 days',
    value: 89,
  });

  const userName = useMemo(() => {
    const name = userData?.details?.user?.name ?? {};

    return `${name?.first ?? ''} ${name?.last ?? ''}`;
  }, [userData?.details?.user?.name]);

  const userRole = useMemo(
    () => userData?.details?.user?.role ?? 'default',
    [userData?.details?.user?.role]
  );

  const additionalQueryParams = useMemo(() => {
    if (Array.isArray(selectedUsers) && selectedUsers?.length > 0) {
      return `is_enterprise=false&user_ids=${JSON.stringify(selectedUsers)}`;
    } else {
      return `is_enterprise=${enterprise}`;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedUsers]);

  return (
    <Container sx={{ padding: '1rem' }}>
      <Wrapper>
        <Header>
          <Heading>
            <h1>Welcome back, {userName}!</h1>
            <p>
              Here’s an snapshot of your campaigns, top content and top leads!
            </p>
          </Heading>
          <Actions>
            {userRole === 'admin' && (
              <UserDropdown
                enterprise={enterprise}
                setEnterprise={setEnterprise}
                selectedUsers={selectedUsers}
                setSelectedUsers={setSelectedUsers}
              />
            )}
            <TimePeriod timePeriod={timePeriod} setTimePeriod={setTimePeriod} />
          </Actions>
        </Header>

        <Layout>
          <MainContent>
            <CampaignCount
              timePeriod={timePeriod?.value || 30}
              campaignSetState={campaignSetState}
              additionalQueryParams={additionalQueryParams}
            />
            <TopPerformingCampaigns
              timePeriod={timePeriod?.value || 30}
              additionalQueryParams={additionalQueryParams}
              isAdmin={userRole === 'admin'}
            />
            <TopPerformingContent
              timePeriod={timePeriod?.value || 30}
              additionalQueryParams={additionalQueryParams}
              isAdmin={userRole === 'admin'}
            />
          </MainContent>
          <SideContent>
            <Topics email={userData?.details?.user?.email} />
            <TopContacts
              timePeriod={timePeriod?.value || 30}
              additionalQueryParams={additionalQueryParams}
              isAdmin={userRole === 'admin'}
            />
            <KeyInsights email={userData?.details?.user?.email} />
          </SideContent>
        </Layout>
      </Wrapper>
    </Container>
  );
}

export default withHomeLayout(Home);
