import * as React from 'react';

function Share(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" {...props}>
      <path
        d="M16.5 14.25l4.5-4.5-4.5-4.5M18 20.25H3.75A.75.75 0 013 19.5V8.25"
        stroke="currentColor"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.031 16.5a9.01 9.01 0 018.719-6.75H21"
        stroke="currentColor"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

const MemoShare = React.memo(Share);
export default MemoShare;
