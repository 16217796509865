import styled from "styled-components";

export const PageNameInput = styled.input`
    width: 250px;
    height: 32px;
    padding: 0px 6px;
    font-family: Poppins;

    &::placeholder {
        font-family: Poppins;
        font-size: 12px;
    }
`