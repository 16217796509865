import { Flex } from '@rebass/grid';
import styled from 'styled-components';

export const CardSpan = styled(Flex)`
  color: #4e6780;
  font-size: 14px;
  margin-bottom: 5px;
`;

export const ArticleTitle = styled(Flex)`
  color: #4e6780;
  font-size: 14px;
  margin-bottom: 5px;
  font-weight: 600;
`;

export const ArticleDescription = styled(Flex)`
  color: #4e6780;
  font-size: 12px;
  margin-bottom: 5px;
  text-align: left;
`;

export const ArticleLink = styled(Flex)`
  color: #4e6780;
  font-size: 12px;
  margin-bottom: 5px;
  cursor: pointer;
`;

export const TimingCard = styled(Flex)`
  border-radius: 4px;
  align-items: flex-start;
  ${'' /* justify-content: space-between; */}
  width: 290px;
  padding: 20px;
  height: 300px;
  margin: ${({ margin }) => margin || '10px 25px 25px 25px'};
  background-color: #fff;
  box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px,
    rgba(17, 17, 26, 0.1) 0px 0px 8px;
  display: flex;
  flex-direction: column;
`;

export const ShareCard = styled(Flex)`
  border-radius: 4px;
  align-items: flex-start;
  ${'' /* justify-content: space-between; */}
  width:800px;
  padding: 20px;
  height: ${({ height }) => height || '300px'};
  margin: 10px 0px 25px 25px;
  background-color: #fff;
  box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px,
    rgba(17, 17, 26, 0.1) 0px 0px 8px;
  display: flex;
  flex-direction: column;
`;

export const Input = styled.input`
  width: ${({ width }) => width || '250px'};
  height: 35px;
  border-radius: 5px;
  border: 1px solid #aaa;
  color: #6351ed;
  padding: 5px;
  margin-top: 5px;
`;

export const InputLabel = styled.span`
  color: ${({ color }) => color || '#000'};
  font-size: ${({ fontSize }) => fontSize || '12px'};
  font-weight: ${({ fontWeight }) => fontWeight || '600'};
  margin-left: ${({ marginLeft }) => marginLeft || '0px'};
`;

export const RadioInputLabel = styled.label`
  color: #000;
  font-size: 14px;
  margin-top: 5px;
  margin-left: 5px;
`;

export const RadioInput = styled.input`
  border: 2px solid white;
  box-shadow: 0 0 0 1px #aaa;
  appearance: none;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  background-color: #fff;
  transition: all ease-in 0.2s;

  &:checked {
    background-color: #6351ed;
    box-shadow: 0 0 0 1px #6351ed;
  }
`;

export const CheckboxInput = styled.input`
  box-shadow: 0 0 0 1px #aaa;
  appearance: none;
  width: 12px;
  height: 12px;
  background-color: #fff;
  transition: all ease-in 0.2s;

  &:checked {
    background-color: #1ee448;
    box-shadow: 0 0 0 1px #1ee448;
    background: url(/assets/images/drip/done_green.svg) center/100%;
  }
`;

export const Chips = styled.div.attrs({
  className: 'chips',
})`
  .chip {
    position: relative;
    display: inline-block;
    padding: 7px 30px 8px 25px;
    height: 40px;
    font-size: 12px;
    line-height: 25px;
    border-radius: 5px;
    background-color: ${({ active }) => (active ? ' #fff' : '#eee')};
    color: ${({ active }) => (active ? '#000000' : '#aaa')};
    font-weight: 600;
    opacity: ${({ mode }) => mode || '100%'};
    ${'' /* margin-right: 10px; */}
    cursor: pointer;
    border: ${({ active }) =>
      active ? '1px solid #6351ed' : '1px solid transparent'};
  }

  .chip img {
    float: left;
    margin: ${({ displayMode }) =>
      displayMode && displayMode === 'block'
        ? '-2px 5px 0 -10px'
        : '-2px 17px 0 -10px'};
    height: 28px;
    width: 28px;
    border-radius: 50%;
  }
`;

export const ChangeLabel = styled.div`
  font-size: 0.7rem;
  text-decoration: underline;
  margin-top: 5px;
  margin-left: 25px;
  &:hover {
    cursor: pointer;
  }
`;

export const ApprovalCard = styled(Flex)`
  border-radius: 4px;
  align-items: flex-start;
  justify-content: ${({justifyContent}) => (justifyContent || '')};
  margin: ${({margin}) => (margin || '10px 0px 25px 25px')};
  width:800px;
  max-width: 100%;
  padding: 20px;
  ${'' /* height: 300px; */}
  background-color: #fff;
  box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px,
    rgba(17, 17, 26, 0.1) 0px 0px 8px;
  display: flex;
  flex-direction: row;
`;
