import * as React from "react";

function Star(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 32 32" fill="none" {...props}>
      <path
        d="M16.549 23.837l6.3 4c.813.513 1.813-.25 1.575-1.187l-1.825-7.175a1.087 1.087 0 01.363-1.1l5.65-4.713c.737-.612.362-1.85-.6-1.912l-7.375-.475a1.037 1.037 0 01-.913-.675l-2.75-6.925a1.038 1.038 0 00-1.95 0l-2.75 6.925a1.037 1.037 0 01-.912.675l-7.375.475c-.963.062-1.338 1.3-.6 1.912l5.65 4.713a1.088 1.088 0 01.362 1.1l-1.687 6.65c-.288 1.125.912 2.037 1.875 1.425l5.862-3.713a1.025 1.025 0 011.1 0v0z"
        stroke="currentColor"
        strokeWidth={2.8}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

const MemoStar = React.memo(Star);
export default MemoStar;
