/* eslint-disable react-hooks/exhaustive-deps */
import { Flex } from '@rebass/grid';
import React, { useState, useEffect } from 'react';
import { Button } from '../../../components';
import { useHistory } from 'react-router-dom';
import '../../DashboardContainer/MIOEditor/MioImageUpload.styles.css';
import {
  CreateContainer,
  CreateContainerCards,
  TitleSpan,
  CardTitle,
  CardSubtitle,
  CardText,
} from './CreateCampaign.styles';
import { FooterBtnsWrapper } from '../DripCampaignContainerV2.styles';
import CreateCampaignFooter from './CreateCampaignFooter';
import { getExploreArticleData, getMyContentArticles, nurtureFlag } from '../../../utils';
import {
  getBookmarkedArticles,
  getRecommendedArticles,
} from '../../../services/dashboard';
import { connect } from 'react-redux';
import { isEmpty, get } from 'lodash';
import { getFirmSettings, getUserRole } from '../../../redux/selector';
import { getCustomRows } from '../../../services/bee-editor';
import CampaignNextCongrats from '../CampaignNextCongrats/CampaignNextCongrats';
import CampaignCompleteCongrats from '../CampaignCompleteCongrats/CampaignCompleteCongrats';
import AutomatedCampaignsHome, {
  AUTOMATED_CAMPAIGN_ACTIONS,
} from '../../AutomatedCampaigns/AutomatedCampaignsContainer';

import { actions as loginActions } from '../../../redux/modules/login.module';
import { getUserDetails } from '../../../services/login';
import EmailAndSocialContainer from '../EmailAndSocialContainer/EmailAndSocialContainer';


const CreateCampaign = ({
  socialNetworkData,
  contactGroups,
  getAccountId,
  campaignId,
  setCreateFlow,
  setCreateTabs,
  campaignSetState,
  campaignCreateFlow,
  setObjectivesTabData,
  endCreateFlow,
  isPremiumAdvisor,
  userData,
  campaignSelectedArticles,
  setTemplateData,
  setTemplateBase,
  campaignScheduleTimings,
  themeNames,
  cmode,
  savedTemplate,
  editMode,
  createSelectedIndex,
  createMode,
  curFlow,
  bookmarkArticles,
  recommendedArticles,
  myContentArticles,
  isEdit,
  campaignSummaries,
  campaignScheduleTypes,
  isFromAddCampaign,
  series,
  seriesId,
  defaultTemplates,
  isadvMode,
  isAdvisorApprovalMode,
  campaignStatus,
  userRole,
  channelId,
  campaignLeadReferralData,
  checkboxStatus,
  socialSelectedCampId,
  isEnterpriseAdvisor,
  complianceApplicable,
  complianceApproved,
  emAdvApproval,
  socialAdvApproval,
  fromDashboard,
  reviewCampaign,
  detailsHandler,
  fromCreateEmail,
  automatedCampaigns,
  isEnterpriseLevelAdmin,
  isFirmLevelAdmin,
  firmSettings,
  firmsArray,
  isAdmin,
  loginRequest,
  segmentList,
  setSegmentList,
  myContentPendingQueue,
  isGlobalCompliance,
  companyId,
  reviewType,
  membersList,
  fromSocialBlock,
  activeIndex,
  subscriptionType,
  compliance,
  fetchSegments,
  contactsLoading,
  segmentsLoading,
  advisorApprovalRequired
}) => {
  useEffect(() => {
    if (createSelectedIndex === 0 && !fromDashboard) {
      if (!isEdit) {
        campaignSetState({
          campaignId: '',
          createFlow: true,
          curFlow: curFlow,
          setTemplate: '',
          setObjectives: isFromAddCampaign ? { campaignType: 'series' } : {},
          setTopics: {},
          contentWeeks: [],
          planCampaign: {},
          campaignArticles: [],
          scheduleTimings: {},
          selectedWeek: '',
          selectedDays: '',
          selectedArticles: [],
          publishedContent: {},
          selectedIndex: 0,
          leadGenReferralData: null,
          automatedCampaigns: (fromCreateEmail || fromSocialBlock || window.sessionStorage.getItem('redirect_type') === 'nurture') ? automatedCampaigns : {},
          selectedBlock: {},
          selectedBlockId: '',
          forAutomatedCampaigns: false,
          automatedFlowFromDashboard: false,
        });
      } else {
        if (editMode && editMode.type && editMode.curFlow) {
          campaignSetState({
            selectedIndex: 0,
            createMode: editMode.type,
            curFlow: editMode.curFlow,
          });
        }
      }
    } else if (createSelectedIndex === 0 && fromDashboard) {
      if (!isEdit) {
        campaignSetState({
          campaignId: '',
          createFlow: true,
          curFlow: '',
          setTemplate: '',
          setObjectives: {},
          setTopics: {},
          contentWeeks: [],
          planCampaign: {},
          campaignArticles: [],
          scheduleTimings: {},
          selectedWeek: '',
          selectedDays: '',
          publishedContent: {},
          selectedIndex: 0,
          leadGenReferralData: null,
          createMode: 'dashboard',
        });
      }
    }
  }, [editMode]);

  useEffect(() => {
    if (!createMode) {
      history.push('/campaignV2');
    }
  }, []);

  const [fetchedUserData, setFetchedUserData] = useState(userData);
  const [bookmarkArticlesLoader, setBookmarkArticlesLoader] = useState(false);
  const [recommendedArticlesLoader, setRecommendedArticlesLoader] =
    useState(false);
  const [conListRecArticlesLoader, setConListRecArticlesLoader] =
    useState(false);
  const [myArticlesLoader, setMyArticlesLoader] = useState(false);
  const history = useHistory();
  const curFlowHandler = (flow) => {
    if (flow === '') {
      endCreateFlow();
      history.push('/campaignV2');
    } else {
      campaignSetState({ curFlow: flow, subscriptionType: '' });
    }
  };

  const [isSeries, setIsSeries] = useState(false);
  const [blockCreate, toggleBlockCreate] = useState(true);

  const redirectionCampaign = () => {
    // setCreateMode(false);
    setIsSeries(true);
    campaignSetState({
      campaignId: '',
      createFlow: true,
      curFlow: '',
      setTemplate: '',
      setObjectives: {
        approvedToShare: get(setObjectivesTabData, 'approvedToShare', 'false'),
        selectedAdvisors: get(setObjectivesTabData, 'selectedAdvisors', []),
        campaignSeriesStatus: 'completed',
      },
      setTopics: {},
      contentWeeks: [],
      planCampaign: {},
      campaignArticles: [],
      scheduleTimings: {},
      selectedWeek: '',
      selectedDays: '',
      selectedArticles: [],
      publishedContent: {},
      createMode: 'series',
      selectedIndex: 0,
      leadGenReferralData: null,
    });
  };

  const redirectCascadeAdvisor = () => {
    let newReviewCampaign = {
      ...reviewCampaign,
      campaigns: reviewCampaign.campaigns.filter((c) => c !== campaignId),
    };
    campaignSetState({
      campaignId: '',
      createFlow: true,
      curFlow: '',
      setTemplate: '',
      setObjectives: {},
      setTopics: {},
      contentWeeks: [],
      planCampaign: {},
      campaignArticles: [],
      scheduleTimings: {},
      selectedWeek: '',
      selectedDays: '',
      selectedArticles: [],
      publishedContent: {},
      createMode: '',
      selectedIndex: 0,
      leadGenReferralData: null,
      reviewCampaign: newReviewCampaign,
    });
  };

  useEffect(() => {
    //  setLoading(true);
    if (isEmpty(bookmarkArticles) && nurtureFlag !== 'nurture') {
      setBookmarkArticlesLoader(true);
      getBookmarkedArticles()
        .then((res) => {
          //  setLoading(false);
          setBookmarkArticlesLoader(false);
          if (res.result && res.result.data && res.result.data.feed) {
            let allBookMarkedArticles = getExploreArticleData(
              res.result.data,
              'campaign'
            );
            if (allBookMarkedArticles.length > 0) {
              campaignSetState({ bookmarkArticles: allBookMarkedArticles });
            }
          }
        })
        .catch((err) => {
          //  setLoading(false);
          setBookmarkArticlesLoader(false);
          console.log(err);
        });
    }

    //  setLoading(true);

    if (isEmpty(recommendedArticles) && nurtureFlag !== 'nurture') {
      setRecommendedArticlesLoader(true);
      getRecommendedArticles()
        .then((res) => {
          //  setLoading(false);
          setRecommendedArticlesLoader(false);
          if (res.result && res.result.data && res.result.data.feed) {
            let allRecommendedArticles = getExploreArticleData(
              res.result.data,
              'campaign'
            );
            if (allRecommendedArticles.length > 0) {
              campaignSetState({ recommendedArticles: allRecommendedArticles });
            }
          }
        })
        .catch((err) => {
          //  setLoading(false);
          setRecommendedArticlesLoader(false);
          console.log(err);
        });
    }

    if (isEmpty(myContentArticles) && nurtureFlag !== 'nurture') {
      setMyArticlesLoader(true);
      getMyContentArticles({
        payload: {
          userType: isEnterpriseAdvisor
            ? 'isEnterpriseAdvisor'
            : isPremiumAdvisor
            ? 'isPremiumAdvisor'
            : 'isEnterpriseAdmin',
          channelId: channelId,
          beforeToken: null,
          afterToken: null,
          pendingArticles: myContentPendingQueue,
        },
        localData: {
          currentArticles: [],
        },
        source: 'campaign',
      })
        .then((res) => {
          setMyArticlesLoader(false);
          const newState = get(res, 'newState', {});
          if (!isEmpty(newState)) campaignSetState(newState);
        })
        .catch((err) => {
          setMyArticlesLoader(false);
          console.log(
            '******* ERROR my-content-articles [CREATE CAMPAIGN] ******'
          );
        });
    }
  }, []);

  useEffect(() => {
    if(nurtureFlag !== 'nurture') {
      getUserDetails()
      .then((res) => {
        if (res?.result?.data) setFetchedUserData(res.result.data);
      })
      .catch((err) => console.log('ERROR - - - -- ', err));
    }
    
  }, [createMode]);

  const commonDivStyles = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  };

  useEffect(() => {
    // For automated campaigns.
    if (
      automatedCampaigns &&
      Object.keys(automatedCampaigns).length > 0 &&
      automatedCampaigns.type
    ) {
      curFlowHandler(automatedCampaigns.type);
      platformMapper(curFlow);
    }
    if(nurtureFlag !== 'nurture') {
      getCustomRows('signature')
      .then((response) => {
        if (get(response, 'data', []).length > 0) toggleBlockCreate(false);
      })
      .catch((err) => console.log('ERR- - -- - - ', err));
    }
    
  }, []);

  useEffect(() => {
    campaignSetState({
      bookmarkArticles: bookmarkArticles,
      recommendedArticles: recommendedArticles,
      myContentArticles: myContentArticles,
    });
  }, [bookmarkArticles, recommendedArticles, myContentArticles]);

  const resetAutomatedCampaignState = () =>
    campaignSetState({
      automatedCampaigns: {},
      selectedBlock: {},
      selectedBlockId: '',
      forAutomatedCampaigns: false,
      automatedFlowFromDashboard: false,
      landingPages: [],
      segmentList: [],
    });

  const platformMapper = (curflow) => {
    if (curFlow === 'emailAndSocial') {
      return (
        <EmailAndSocialContainer
          socialNetworkData={socialNetworkData}
          segmentList={segmentList}
          curFlow={curflow}
          contactGroups={contactGroups}
          campaignSetState={campaignSetState}
          endCreateFlow={endCreateFlow}
          membersList={membersList}
          setObjectivesTabData={setObjectivesTabData}
          setConListRecArticlesLoader={setConListRecArticlesLoader}
          campaignId={campaignId}
          isAdmin={isAdmin}
          isEnterpriseLevelAdmin={isEnterpriseLevelAdmin}
          campaignStatus={campaignStatus}
          userRole={userRole}
          curFlowHandler={curFlowHandler}
          reviewType={reviewType}
          savedTemplate={savedTemplate}
          createMode={createMode}
          campaignCreateFlow={campaignCreateFlow}
          seriesId={seriesId}
          activeIndex={activeIndex}
          subscriptionType={subscriptionType}
          getAccountId={getAccountId}
          isFirmLevelAdmin={isFirmLevelAdmin}
          companyId={companyId}
          firmSettings={firmSettings}
          firmsArray={firmsArray}
          compliance={compliance}
          isEnterpriseAdvisor={isEnterpriseAdvisor}
          userData={userData}
          contactsLoading={contactsLoading}
          segmentsLoading={segmentsLoading}
          isAdvisorApprovalMode={isAdvisorApprovalMode}
          advisorApprovalRequired={advisorApprovalRequired}
          isadvMode={isadvMode}
        />
      );
    } else if (Object.values(AUTOMATED_CAMPAIGN_ACTIONS).includes(curFlow)) {
      return (
        <AutomatedCampaignsHome
          currentFlow={curFlow}
          campaignSetState={campaignSetState}
          userData={userData}
          // campaignId={campaignId}
          // setTemplateData={setTemplateData}
          // setTemplateBase={setTemplateBase}
          // selectedEmailCreationIndex={createSelectedIndex}
          // defaultTemplates={defaultTemplates}
          socialNetworkData={socialNetworkData}
          setObjectivesTabData={setObjectivesTabData}
          endCreateFlow={endCreateFlow}
          segmentList={segmentList}
          setSegmentList={setSegmentList}
          getAccountId={getAccountId}
          fetchSegments={fetchSegments}
          segmentsLoading={segmentsLoading}
          isAdmin={isAdmin}
        />
      );
    }
  };
console.log("SERIES ID-->", seriesId)
  return (
    <>
      {createMode && (
        <Flex justifyContent="center" alignItems="center">
          {platformMapper(curFlow)}
        </Flex>
      )}

      
    </>
  );
};

const mapStateToProps = (state) => {
  return {
  themeNames: state.theme.themeNames,
  contentLibArticles: state.contentlib.data,
  createSelectedIndex: state.campaign.selectedIndex,
  createMode: state.campaign.createMode,
  curFlow: state.campaign.curFlow,
  bookmarkArticles: state.campaign.bookmarkArticles,
  recommendedArticles: state.campaign.recommendedArticles,
  myContentArticles: state.campaign.myContentArticles,
  myContentPendingQueue: get(state, 'campaign.pendingArticles', []),
  isEdit: state.campaign.isEdit,
  campaignSummaries: state.campaign.summaries,
  campaignScheduleTimings: state.campaign.scheduleTimings,
  campaignScheduleTypes: state.campaign.scheduleTypes,
  series: state.campaign.series,
  seriesId: state.campaign.seriesId,
  userRole: getUserRole(state),
  campaign: state.campaign,
  campaignLeadReferralData: state.campaign.leadGenReferralData,
  checkboxStatus: state.campaign.checkbox_status,
  complianceApplicable: state.campaign.complianceApplicable,
  complianceApproved: state.campaign.complianceApproved,
  emAdvApproval: state.campaign.emAdvApproval,
  socialAdvApproval: state.campaign.socialAdvApproval,
  fromDashboard: get(state, 'campaign.fromDashboard', false),
  reviewCampaign: get(state, 'campaign.reviewCampaign', {}),
  fromCreateEmail: state.campaign.fromCreateEmail,
  automatedCampaigns: state.campaign.automatedCampaigns,
  isGlobalCompliance: get(state, 'campaign.is_global_compliance', 'false'),
  reviewType: get(state, 'campaign.reviewType', ''),
  fromSocialBlock: get(state, 'campaign.fromSocialBlock', ''),
  activeIndex: get(state, 'campaign.activeIndex', ''),
  subscriptionType: state?.campaign?.subscriptionType,
  compliance: get(state, 'user.data.compliance', ''),
  advisorApprovalRequired: get(state, 'campaign.advisorApprovalRequired', false)
}};

const mapDispatchToProps = {
  loginRequest: loginActions.api.login.request,
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateCampaign);
