import React from 'react';
import { Grid } from '@mui/material';
import { TablistWrapper, TablistWrapperButton } from './styles';

const TabList = ({
  data,
  setActiveTab,
  activeTab,
  variant = 'standard',
  fullWidth = false,
  tabFullWidth = false,
  id = null,
  customStyles = {},
}) => {
  return (
    <Grid
      item
      overflow="hidden"
      id={id ?? 'custom-tab'}
      style={customStyles}
      width={fullWidth ? '100%' : 'fit-content'}
    >
      <TablistWrapper
        item
        container
        variant={variant}
        xs={12}
        flexWrap="nowrap"
        width={fullWidth ? '100%' : 'fit-content'}
      >
        {data.map((tab, index) => (
          <TablistWrapperButton
            key={index}
            item
            container
            justifyContent="center"
            alignItems="center"
            xs={tabFullWidth && 12 / data.length}
            variant={variant}
            isActive={activeTab === tab.value}
            onClick={() => {
              if (tab?.disabled !== true) {
                setActiveTab(tab?.value);
              }
            }}
            minWidth="fit-content"
            singleTabMode={data?.length === 1}
            disabled={tab?.disabled ?? false}
          >
            {tab.label}
          </TablistWrapperButton>
        ))}
      </TablistWrapper>
    </Grid>
  );
};

export default TabList;
