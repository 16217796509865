import styled from 'styled-components';

export const HorizontalSeparatorSolid = styled.div`
  border-top: 0.05px solid #cfd9e4;
  width: ${({ width }) => width || 'auto'};
  margin: ${({ margin }) => margin || ''};
  ${'' /* margin-left: 12px;
  margin-right: 12px; */
  }
`;

export const FilterRow = styled.div`
  margin: 5px;
  background-color: #fbfbfb;
  border-radius: 5px;
  width: 100%;
  display: ${({ display }) => display || ''};
  flex-direction: ${({ flexDirection }) => flexDirection || ''};
  justify-content: ${({ justifyContent }) => justifyContent || ''};
`;

export const FilterRowTitle = styled.div`
  padding: 15px;
  background-color: #eff2f5;
  border-radius: 5px;
  width: ${({ width }) => width || '100px'};
  text-align: left;
  font-weight: 900;
  color: #000;
  word-wrap: break-word;
`;

/*
 * Hide the inputs.
 */
export const FilterRowChips = styled.div`
  padding: 15px;
  text-align: left;
  font-weight: 900;
  color: #000;
  display: flex;
  flex-direction: row;
  align-items: inherit;
  position: relative;
  width: ${({ width }) => width || ''};

  .checkbox-button {
    cursor: pointer;
  }

  input[type='checkbox'] {
    box-sizing: border-box;
    padding: 0;
  }

  input {
    font-size: 1rem;
    line-height: 1.5;
    padding: 11px 23px;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 0;
    outline: 0;
    background-color: transparent;
  }

  .checkbox-button__input {
    opacity: 0;
    position: absolute;
  }

  .checkbox-button__control {
    position: relative;
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-right: 12px;
    vertical-align: middle;
    background-color: inherit;
    color: #017b5f;
    border: 2px solid #666;
  }

  .checkbox-button__input:checked + .checkbox-button__control:after {
    content: '';
    display: block;
    position: absolute;
    top: 1px;
    left: 0.5px;
    width: 14px;
    height: 14px;
    background-color: #6250ed;
  }

  .checkbox-button__input:checked + .checkbox-button__control {
    border-color: #6250ed;
  }

  .checkbox-button__control {
    transform: scale(0.75);
  }

  .Popover-below {
    top: 56% !important;
  } 
`;
export const FilterIconDiv = styled.div.attrs({
  className: 'filter-icon-div',
})`
  position: absolute;
  bottom: ${({ bottom }) => bottom || '30%'};
  left: ${({ left }) => left || '0%'};

  &:hover {
    cursor: pointer;
    // ul.engagement-filter-ul {
    //   visibility: visible;
    // }
  }

  ul.engagement-filter-ul {
    display: none;
    // visibility: hidden;
    position: absolute;
    top: 22%;
    border-radius: 4px;
    left: -10px;
    max-height: 200px;
    overflow-x: hidden;
    overflow-y: auto;
    z-index: 5;
    list-style: none;
    ${'' /* background-color: #6351ed; */}
    color: #ffffff;
    font-size: 9px;
    width: 300px;
    padding: 0;

    li {
      text-align: left;
      &:hover {
        background-color: #f8f8f8;
        cursor: pointer;
      }
    }
    &::-webkit-scrollbar {
      width: 5px;
    }

    &::-webkit-scrollbar-track {
      background: #e9f6e6 !important;
    }

    &::-webkit-scrollbar-thumb {
      background: #bccdd4 !important;
    }
  }
`;

export const FilterLi = styled.li.attrs({
  className: 'filterLi',
})`
  background-color: ${({ bgColor }) => bgColor || '#cfd9e4'};
  padding: 10px 6px;
  font-size: 14px;
  font-weight: 800;
  color: #000;
`;