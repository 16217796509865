export const transformTopicsListResponse = (res) => {
  let transformedData = [];
  if (Array.isArray(res?.result) && res?.result?.length > 0) {
    transformedData = res?.result.map((data) => ({
      ...data,
      topic: data?.theme_name ?? '',
    }));
  }
  return {
    data: transformedData,
    pagination: res?.pagination ?? {},
  };
};

export const transformListsResponse = (res, dataKey) => {
  if (Array.isArray(res?.[dataKey])) {
    return {
      data: res?.[dataKey],
      pagination: res?.pagination ?? {},
    };
  }
};
