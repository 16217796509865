/* eslint-disable react-hooks/exhaustive-deps */
import { Flex } from '@rebass/grid';
import React, { useEffect } from 'react';
import {
  Button,
  FileUpload,
  ModalBody,
  ModalCloseButton,
  ModalV2,
} from '../../../components';
import { SearchInput } from '../../ConfigureContainer/EmailList/EmailList.styles';
import { isEmpty } from 'lodash';

const LPUploadImageModal = ({
  isOpen,
  handleClose,
  articleData,
  setErrorMsg,
  onFileUploadChange,
  changeTitle,
}) => {
  return (
    <ModalV2
      onRequestClose={() => handleClose()}
      shouldCloseOnOverlayClick
      isOpen={isOpen}
      bgColor="white"
      width="60%"
    >
      <ModalCloseButton onClick={() => handleClose()} />
      <ModalBody>
        <Flex
          display="flex"
          justifyContent="flex-start"
          color="#101828"
          fontSize="20px"
          width="100%"
        >
          {' '}
          Upload Image
        </Flex>
        <Flex style={{ fontSize: '14px', color: '#667085' }}>
          edit your image
        </Flex>
        <hr
          style={{
            width: '100%',
            border: '1px solid #E4E7EC',
          }}
        />
        <Flex
          display="flex"
          width="100%"
          flexDirection="row"
          alignItems="center"
        >
          <Flex
            width="40%"
            display="flex"
            justifyContent="flex-start"
            color="#101828"
            fontSize="20px"
          >
            Article Image
          </Flex>
          <Flex display="flex" width="60%" justifyContent="flex-start">
            <Flex
              display="flex"
              width="200px"
              height="120px"
              justifyContent="center"
              alignItems="center"
              style={{
                border: '1px solid #E7E4EC',
                borderRadius: '8px',
                padding: '0px 0px 5px 0px',
              }}
            >
              <img
                alt=""
                src={
                  isEmpty(articleData?.url)
                    ? '/assets/images/Image-default.svg'
                    : articleData?.url
                }
                width="135px"
                height="110px"
                style={{
                  position: 'relative',
                  marginRight: '10px',
                  marginLeft: '10px',
                  borderRadius: '5px',
                  objectFit: 'cover',
                }}
              />
            </Flex>
            <Flex display="flex" flexDirection="column">
              <Flex
                display="flex"
                alignItems="center"
                flexDirection="row"
                style={{
                  height: '50px',
                  border: '1px solid rgb(228, 231, 236)',
                  boxShadow: 'rgba(16, 24, 40, 0.12) 0px 1px 2px',
                  borderRadius: '4px',
                  cursor: 'pointer',
                  fontSize: '14px',
                  marginLeft: '50px',
                  width: '65%',
                  position: 'relative'
                }}
              >
                <img
                  alt=""
                  src="/assets/images/Arrows.svg"
                  style={{
                    height: '21px',
                    width: 'auto',
                    marginTop: '0px',
                    position: 'absolute',
                    top: '12px',
                    left: '8px'
                  }}
                />
                <FileUpload
                  asLabel
                  margin="0px"
                  name="imageUpload"
                  id="imageUpload"
                  label={'Replace Image'}
                  onChange={(file) => onFileUploadChange(file)}
                  styles={{
                    margin: '0px',
                    backgroundColor: '#fff',
                    color: '#101828',
                    border: '1px solid rgb(228, 231, 236)',
                    width: '100%'
                  }}
                  onError={(msg) => setErrorMsg(msg)}
                  maxSizeMB={10}
                  accept=".png,.jpeg,.jpg"
                  title="Maximum Image Size : 10MB, Maximum Image Width :165 Pixels"
                />
              </Flex>
              <Flex display="flex" flexDirection="column" alignItems="center">
                <Flex display="flex" fontSize="12px" color="#101828" padding="10px">
                  Maximum Image Size : 10MB
                </Flex>
                <Flex display="flex" fontSize="12px" color="#101828" paddingLeft="14px">
                  Supported Formats: .png,.jpeg,.jpg
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
        <hr
          style={{
            width: '100%',
            border: '1px solid #E4E7EC',
          }}
        />
        <Flex display="flex" width="100%" flexDirection="row" height="90px">
          <Flex
            width="40%"
            display="flex"
            justifyContent="flex-start"
            color="#101828"
            fontSize="20px"
            alignItems="center"
          >
            Article Title
          </Flex>
          <Flex
            width="60%"
            mt="26px"
            justifyContent="center"
            fontSize="16px"
            alignItems="center"
            style={{
              display: 'flex',
              alignItems: 'center',
              backgroundColor: '#FFFFFF',
              border: '1px solid #E4E7EC',
              borderRadius: '4px',
              padding: '0px 5px',
              height: '40px',
            }}
          >
            <SearchInput
              style={{ width: '100%', height: '30px', fontSize: '14px' }}
              outerWidth="100%"
              name="imageName"
              placeholder="Edit Image Name"
              color="#000"
              value={articleData?.topic}
              onChange={(e) => changeTitle(e.target.value)}
            />
          </Flex>
        </Flex>
        <hr
          style={{
            width: '100%',
            border: '1px solid #E4E7EC',
          }}
        />
        <Flex display="flex" justifyContent="space-between">
          <Button
            width="45%"
            height="40px"
            bgColor="#fff"
            color="#101010"
            borderColor="#E4E7EC"
            borderRadius="4px"
            margin="20px 0px 0px 10px"
            onClick={() => handleClose()}
          >
            Cancel
          </Button>
          <Button
            width="45%"
            borderRadius="4px"
            margin="20px 10px 0px 0px"
            onClick={() => handleClose()}
          >
            Continue
          </Button>
        </Flex>
      </ModalBody>
    </ModalV2>
  );
};

export default LPUploadImageModal;
