import * as React from "react";

function Facebook(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 18 18" fill="none" {...props}>
      <path
        d="M9 15.75a6.75 6.75 0 100-13.5 6.75 6.75 0 000 13.5z"
        stroke="currentColor"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.813 6.188h-1.126A1.68 1.68 0 009 7.875v7.875M6.75 10.125h4.5"
        stroke="currentColor"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

const MemoFacebook = React.memo(Facebook);
export default MemoFacebook;
