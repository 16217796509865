import styled from 'styled-components';

export const StyledFlipster = styled.div`
  overflow: hidden;
  padding: ${({ isFromMyClients }) => isFromMyClients ? '2.5rem 0 2.5em 0' : '2.5em 0px'};
  img {
    max-width: 100%;
    border-radius: 8px;
    width: ${({ type }) => type === 'most-subscribed' ? '75px' : '85px'};
  }
  .flipster__item--past,
  .flipster__item--future {
    p {
      visibility: hidden;
    }
  }

  .flipster__container,
  .flipster__item,
  .flipster__item__content {
    margin-right: -14px;
    transition: ${({ type, checktransition }) => (checktransition && type !== 'most-subscribed') ? 'visibility 0s, opacity 0.5s linear' : 'all 350ms ease-in-out'};
    transition-timing-function: cubic-bezier(.56,.12,.12,.98);
  }

  ${({ type }) => type === 'my-clients' && `
  .flipster__item--past-3,
  .flipster__item--future-3 {
      opacity: 0;
  }
    .flipster__item--past .flipster__item__content { transform: translateY(-2%) translateX(100%) rotateY(-20deg) scale(0.5);   }
    .flipster__item--past-2 .flipster__item__content { transform: translateY(-8.4%) translateX(25%) rotateY(40deg) scale(0.65);  }
    .flipster__item--past-1 .flipster__item__content { transform:  translateY(-4.4%) rotateY(45deg) scale(0.8); }
    .flipster__item--future .flipster__item__content { transform: translateY(-2%) translateX(-100%) rotateY(20deg) scale(0.5); }
    .flipster__item--future-2 .flipster__item__content { transform: translateY(-8.4%) translateX(-25%) rotateY(-40deg) scale(0.65); }
    .flipster__item--future-1 .flipster__item__content { transform:  translateY(-4.4%) rotateY(-45deg) scale(0.8); }  
  `}

  ${({ type }) => (type !== 'most-subscribed' && type !== 'my-clients') && `
 
  .flipster__item--past-4,
  .flipster__item--future-4 {
      opacity: 0.2;
      transition-delay: 120ms;
  }
  .flipster__item--past-3,
  .flipster__item--future-3 {
      opacity: 0.4;
      transition-delay: 90ms;
  }
    .flipster__item--past .flipster__item__content { transform: translateY(-2%) translateX(100%) rotateY(-20deg) scale(0.5);   }
    .flipster__item--past-2 .flipster__item__content { transform: translateY(-8.4%) translateX(25%) rotateY(40deg) scale(0.65); }
    .flipster__item--past-1 .flipster__item__content { transform:  translateY(-4.4%) rotateY(45deg) scale(0.8); }
    .flipster__item--past-3 .flipster__item__content { transform: translateY(-12%) translateX(60%) rotateY(35deg) scale(0.55); }
    .flipster__item--past-4 .flipster__item__content { transform:  translateY(-14%) translateX(95%) rotateY(40deg) scale(0.45); }

    .flipster__item--future .flipster__item__content { transform: translateY(-2%) translateX(-100%) rotateY(20deg) scale(0.5); }
    .flipster__item--future-2 .flipster__item__content { transform: translateY(-8.4%) translateX(-25%) rotateY(-40deg) scale(0.65); }
    .flipster__item--future-1 .flipster__item__content { transform:  translateY(-4.4%) rotateY(-45deg) scale(0.8); }
    .flipster__item--future-3 .flipster__item__content { transform: translateY(-12%) translateX(-60%) rotateY(-40deg) scale(0.55); }
    .flipster__item--future-4 .flipster__item__content { transform: translateY(-14%) translateX(-95%) rotateY(-35deg) scale(0.45); }
  
  &.no-rotate {
    .flipster__item--past-3 .flipster__item__content { transform: translateX(50%) scale(0.65); }
    .flipster__item--future-3 .flipster__item__content { transform: translateX(-50%) scale(0.65); }
  }
  ` 
}

`;
