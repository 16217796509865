import React, { useState, useEffect } from 'react';
import { Flex, Box } from '@rebass/grid';
import { get, isEmpty } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitter, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import {
  Modal,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Tabs,
  TabList,
  TabTypeTwo,
  EditableContent,
  MiniArticle,
  TwitterButton,
  LinkedInButton,
  DripIconButton,
  MioDatePicker,
} from '..';

import { getArticleSummary } from '../../services/dashboard';

import {
  editableContentStyles,
  HeaderSummaryIcon,
  StyledTabPanel,
  ErrorContainer,
  Container,
} from './ShareArticleContainer.styles';

const getErrorMessage = (tabIndex, { type }) => {
  const socialMedia = tabIndex === 0 ? 'Twitter' : 'LinkedIn';
  if (type === 'SocialTokenException') {
    return `Please Connect your ${socialMedia} account in order to share`;
  }

  return 'Something went wrong...';
};

const ShareArticleContainer = ({
  // isModalOpen,
  // onModalClose,
  shareArticleRequest,
  shareError,
  ...rest
}) => {
  const maxLength = {
    0: 250,
    1: 700,
  };
  const [selectedIndex, setTabIndex] = useState(0);
  const [summayData, setSummayData] = useState({
    shortSummary: '',
    mediumSummary: '',
  });
  const [placeholerText, setPlaceholerText] = useState('');
  const maxContentLength = maxLength[selectedIndex];
  const [contentLength, setContentLength] = useState(maxContentLength);
  const [textContent, setTextContent] = useState('');
  const [isDateOpen, setDateOpen] = useState(false);

  useEffect(() => {
    const { id, themeId } = rest;
    const defaultEditableContentPlaceholder =
      'Share your thoughts about this article...';
    setPlaceholerText('Generating Summary...');
    getArticleSummary({ articleId: id, themeId })
      .then((response) => {
        const resultData = get(response, 'result.data.summaries');
        const shortSummary = get(resultData, 'short[0].summary', '');
        const mediumSummary = get(resultData, 'medium[0].summary', '');
        setSummayData({
          shortSummary,
          mediumSummary,
        });
        setTextContent(shortSummary);
        setContentLength(maxContentLength - shortSummary.length);
        setPlaceholerText(defaultEditableContentPlaceholder);
      })
      .catch((error) => {
        setPlaceholerText(defaultEditableContentPlaceholder);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onTabClick = (index) => {
    setTabIndex(index);
    const contentText =
      index === 0 ? summayData.shortSummary : summayData.mediumSummary;
    setTextContent(summayData.shortSummary);
    setContentLength(maxContentLength - contentText.length);
  };

  const onContentChange = (contentHtml, contentText) => {
    const balanceText = maxContentLength - contentText.length;

    setContentLength(balanceText);
    if (balanceText > 0) {
      setTextContent(contentHtml);
    }
  };

  const getDefaulParamsForApi = () => {
    const { id, themeId, channelId } = rest;
    return {
      channel_id: channelId,
      destination_snetwork: selectedIndex === 0 ? 'tw' : 'ln',
      engagement_type: 'share',
      id,
      override_text: textContent,
      source_snetwork: 'bl',
      theme_id: themeId,
      snetwork_act_id: '4919661073',
    };
  };
  const onShareClick = () => {
    shareArticleRequest({ params: getDefaulParamsForApi() });
  };

  const onClockButtonClick = () => {
    setDateOpen(true);
  };

  const onScheduleClick = (dateTime) => {
    setDateOpen(false);
    shareArticleRequest({
      params: {
        ...getDefaulParamsForApi(),
        schedule_time: dateTime,
      },
    });
  };

  return (
    <Container>
      <Flex alignItems="flex-end" justifyContent="space-between" px="20px">
        <Box width={3.5 / 10}>
          <MiniArticle {...rest} />
        </Box>
        <Box width={6 / 10}>
          <Tabs onSelect={onTabClick}>
            <TabList>
              <TabTypeTwo>Twitter</TabTypeTwo>
              <TabTypeTwo>LinkedIn</TabTypeTwo>
            </TabList>
            <StyledTabPanel>
              <EditableContent
                style={editableContentStyles}
                content={summayData.shortSummary}
                onChange={onContentChange}
                placeholer={placeholerText}
              />
            </StyledTabPanel>
            <StyledTabPanel>
              <EditableContent
                style={editableContentStyles}
                content={summayData.mediumSummary}
                onChange={onContentChange}
                placeholer={placeholerText}
              />
            </StyledTabPanel>
          </Tabs>
        </Box>
      </Flex>
      <Flex
        alignItems="flex-end"
        justifyContent="space-between"
        px="20px"
        mt="10px"
      >
        <Box width={3.5 / 10}>&nbsp;</Box>
        <Flex width={6 / 10} justifyContent="space-between">
          <Box>
            {contentLength}/{maxLength[selectedIndex]} characters left
          </Box>
          <Box>
            {selectedIndex === 0 && (
              <TwitterButton onClick={onShareClick}>
                <FontAwesomeIcon icon={faTwitter} />
                Share
              </TwitterButton>
            )}
            {selectedIndex === 1 && (
              <LinkedInButton onClick={onShareClick}>
                <FontAwesomeIcon icon={faLinkedin} />
                Share
              </LinkedInButton>
            )}

            <DripIconButton onClick={onClockButtonClick} icon="clock" />
            <MioDatePicker
              isOpen={isDateOpen}
              customInput={<div />}
              minDate={new Date()}
              onDateClose={() => setDateOpen(false)}
              onSubmit={onScheduleClick}
            />
          </Box>
        </Flex>
      </Flex>
      <ErrorContainer>
        {!isEmpty(shareError) && getErrorMessage(selectedIndex, shareError)}
      </ErrorContainer>
    </Container>
  );
};

export default ShareArticleContainer;
