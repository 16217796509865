import { v4 as uuidv4} from 'uuid';
export const forwardFriendRow = () => {
    return {
        "columns": [
          {
            "grid-columns": 12,
            "modules": [
              {
                "descriptor": {
                  "computedStyle": {
                    "hideContentOnMobile": false
                  },
                  "style": {
                    "padding-bottom": "10px",
                    "padding-left": "10px",
                    "padding-right": "10px",
                    "padding-top": "10px"
                  },
                  "text": {
                    "computedStyle": {
                      "linkColor": "#0068A5"
                    },
                    "html": "<div class=\"txtTinyMce-wrapper\" style=\"font-size: 12px; line-height: 14px; font-family: inherit;\" data-mce-style=\"font-size: 12px; line-height: 14px; font-family: inherit;\"><p style=\"font-size: 12px; line-height: 14px; word-break: break-word;\" data-mce-style=\"font-size: 12px; line-height: 14px; word-break: break-word;\"><span style=\"font-size: 14px; line-height: 16px;\" data-mce-style=\"font-size: 14px; line-height: 16px;\"><a href=\"{$forward_a_friend}\" target=\"_blank\" style=\"text-decoration: underline;\" rel=\"noopener\">Forward Email Campaign</a></span></p></div>",
                    "style": {
                      "color": "#000",
                      "font-family": "inherit",
                      "line-height": "120%"
                    }
                  }
                },
                "locked": false,
                "type": "mailup-bee-newsletter-modules-text",
                "uuid": uuidv4()
              }
            ],
            "style": {
              "background-color": "transparent",
              "border-bottom": "0 solid transparent",
              "border-left": "0 solid transparent",
              "border-right": "0 solid transparent",
              "border-top": "0 solid transparent",
              "padding-bottom": "5px",
              "padding-left": "0px",
              "padding-right": "0px",
              "padding-top": "5px"
            },
            "uuid": uuidv4()
          }
        ],
        "container": {
          "style": {
            "background-color": "transparent",
            "background-image": "none",
            "background-position": "top left",
            "background-repeat": "no-repeat"
          }
        },
        "content": {
          "computedStyle": {
            "hideContentOnDesktop": false,
            "hideContentOnMobile": false,
            "rowColStackOnMobile": true,
            "rowReverseColStackOnMobile": false,
            "verticalAlign": "top"
          },
          "style": {
            "background-color": "transparent",
            "background-image": "none",
            "background-position": "top left",
            "background-repeat": "no-repeat",
            "color": "#000000",
            "width": "500px"
          }
        },
        "empty": false,
        "locked": false,
        "synced": false,
        "type": "one-column-empty",
        "uuid": uuidv4(),
        "metadata": {
          "name": "Forward A Friend Row"
        }
      }
}

export const forwardAFriendIconRow = () => {
    return {
        "type": "one-column-empty",
        "container": {
            "style": {
                "background-color": "transparent",
                "background-image": "none",
                "background-repeat": "no-repeat",
                "background-position": "top left"
            }
        },
        "content": {
            "style": {
                "background-color": "transparent",
                "color": "#000000",
                "width": "500px",
                "background-image": "none",
                "background-repeat": "no-repeat",
                "background-position": "top left"
            },
            "computedStyle": {
                "rowColStackOnMobile": true,
                "rowReverseColStackOnMobile": false
            }
        },
        "columns": [{
            "grid-columns": 12,
            "modules": [{
                "type": "mailup-bee-newsletter-modules-icons",
                "descriptor": {
                    "iconsList": {
                        "icons": [{
                            "image": "https://d15k2d11r6t6rl.cloudfront.net/public/users/Integrators/f70d53fc-ac51-490a-9877-f6d8e3bf2318/QW0WzLek/Button.png",
                            "textPosition": "right",
                            "text": "",
                            "alt": "",
                            "title": "Forward a Friend",
                            "href": "{$forward_a_friend}",
                            "target": "_blank",
                            "width": "19px",
                            "height": "19px"
                        }]
                    },
                    "style": {
                        "color": "#000000",
                        "text-align": "right",
                        "padding-top": "0px",
                        "padding-right": "0px",
                        "padding-bottom": "0px",
                        "padding-left": "0px",
                        "font-family": "inherit",
                        "font-size": "14px"
                    },
                    "mobileStyle": {

                    },
                    "computedStyle": {
                        "hideContentOnMobile": false,
                        "hideContentOnDesktop": false,
                        "itemSpacing": "0px",
                        "iconSpacing": {
                            "padding-top": "5px",
                            "padding-right": "5px",
                            "padding-bottom": "5px",
                            "padding-left": "5px"
                        },
                        "iconHeight": "32px"
                    }
                },
                "uuid": uuidv4()
            }],
            "style": {
                "background-color": "transparent",
                "padding-top": "5px",
                "padding-right": "0px",
                "padding-bottom": "5px",
                "padding-left": "0px",
                "border-top": "0px solid transparent",
                "border-right": "0px solid transparent",
                "border-bottom": "0px solid transparent",
                "border-left": "0px solid transparent"
            },
            "uuid": uuidv4()
        }],
        "uuid": uuidv4(),
        "metadata": {
            "name": "Forward A Friend Icon"
        }
    }
}
