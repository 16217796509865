import styled from 'styled-components';
import { FileUpload } from '../../../components';
import { Switch } from '@mui/material';
import { colors } from '../../../styles';

export const CustomFileUpload = styled(FileUpload)`
  margin-top: 12px;
  button {
    background-color: #eee !important;
    border: 1px solid #a0a0a0 !important;
    color: #6351ed !important;
    padding: 5px 10px;
    font-size: 14px;
    width: 130px;
    margin-top: 10px;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    cursor: pointer;
    border-radius: 4px;
  }
`;

export const SuccessDisplay = styled('h4')`
  color: #00B186;
  width: 90%;
  text-align: center;
  margin: 30px auto 60px;
  font-weight: 500;
`;

export const ErrorMessage = styled('p')`
  font-weight: 400;
  font-size: 10px;
  color: #E00000;
  text-align: left;
  margin: -5px 0 5px;
`;

export const ConnectMeWrapper = styled.div.attrs({
    className: 'connect-me-wrapper',
  })`
    position: ${({ position }) => position || ''};
    top: ${({ top }) => top || ''};
    right: ${({ right }) => right || ''};
    bottom: ${({ bottom }) => bottom || ''};
    height: ${({ height }) => height || '300px'};
    padding: ${({ padding }) => padding || '15px'};
    width: ${({ width }) => width || '235px'};
    background-color: #fff;
    border: ${({ border }) => border || `1px solid black`};
    .img-wrapper {
      height: 40%;
      width: 100%;
    }
      z-index: 1;
      width: 100%!important;
      height: fit-content;
      bottom: 0;
      right: 0;
      padding: 2%;
      border-top: 2px solid #6351ed ;

      position: static;
      margin-bottom: 10px;
      margin-top: 5px;
  `;
  
  export const ToggleButton = styled(Switch)`
  margin: ${({ margin }) => margin || '10px 0 0'};
  top: ${({ top }) => top || '4px'};
  left: ${({ left }) => left || '5px'};
  .react-switch-bg {
    width: ${({ type }) => type === 'autoPost' ? '24px !important' : '43px !important'};
    height: ${({ type }) => type === 'autoPost'? '15px !important' : '15px !important'};
    top: ${({ type }) => type === 'configure' ? '3px' : '0px'};
  }
  .react-switch-handle {
    top: ${({ type, switchHandleTop }) => switchHandleTop ? switchHandleTop : type === 'autoPost' ? '2.4px !important' : type === 'configure' ? '5px !important' : '1px !important'};
    left: ${({ type }) => type === 'autoPost' ? '-10px' : '-6px'}; 
    transform: ${({ type, checked }) => type === 'autoPost' ? checked ? 'translateX(21px) !important' : 'translateX(12px) !important' : ''};
  }
`;

export const TextInput = styled('input')`
  width: ${({ width }) => width || '80%'};
  display: flex;
  background: ${({ disabled }) => disabled ? colors.disabled_button : colors.white};
  border: 1px solid #E4E7EC;
  box-shadow: ${({ disabled }) => disabled ? 0 : 'inset 1px 2px 2px rgba(16, 24, 40, 0.12)'};
  border-radius: 4px;
  padding: 8px 9px;
  margin: 8px 0;
  font-family: Poppins !important;
`;

export const ProfileContainer = styled('div')`
  width: 80%;
  margin: 20px auto;
  display: table;
`;

export const GroupingContainer = styled('div')`
  width: 100%;
  margin: 20px auto;
  border: 1px solid #ECF2FC;
  padding: 20px;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.12);
  border-radius: 8px;
  display: table;
`;

export const TextLabel = styled('label')`
  font-weight: 500;
  font-size: 14px;
  color: #101828;
  text-align: left;
`;

export const InfoIcon = styled('img')`
  height: 14px;
  width: 14px;
  cursor: pointer;
  margin: 2px 6px;
  :hover { 
    opacity: 0.7;
  }
`;

export const ButtonIcon = styled('img')`
  height: 20px;
  width: 20px;
  margin: 0px 10px 0 0;
`;

export const Heading = styled('h4')`
  font-family: 'Poppins' !important;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  text-align: left;
  color: #101828;
  margin: 0px;
`;


export const TextArea = styled('textarea')`
  width: 80%;
  height: 80px;
  display: flex;
  background: ${colors.white};
  border: 1px solid ${colors.gray_border};
  box-shadow: inset 1px 2px 2px rgba(16, 24, 40, 0.12);
  border-radius: 4px;
  color: ${colors.gray_text};
  padding: 8px 10px;
  margin: 8px 0 20px;
`;

export const SwitchStyle = styled(Switch)`
  & .MuiSwitch-thumb {
    boxShadow: 0px 0px 25px 0px rgba(63, 106, 194, 0.15);
    color: #fff;
    border: 1px solid #6351ed;
  };
  & .MuiSwitch-switchBase {
    &.Mui-checked {
      & + .MuiSwitch-track {
        opacity: 1;
        background-color: #816EF8 !important;  
      }
    }
  };
  & .MuiSwitch-track {
    background-color: #797d83,
  };
`;

export const ButtonContainer = styled('div')`
  display: flex;
  justify-content: start;
  width: fit-content;
  margin: 30px 0;
`;

export const HeadingSmall = styled('div')`
  font-weight: 400;
  font-size: 14px;
  color: ${colors.light_gray_text};
  text-align: left;
  margin: 10px 0 0;
`;

export const HeadingLarge = styled('div')`
  font-weight: 500;
  font-size: 16px;
  text-align: left;
  color: ${colors.black};
`;

export const EditRow = styled('div')`
  display: flex;
  justify-content: space-between;
  margin: 5px 0 12px;
  width: 100%;
`;

export const EditColumn = styled('div')`
  margin: 0;
  width: calc(50% - 10px);
`;

export const InvertedButton = styled('div')`
  font-family: 'Poppins' !important;
  background: ${({ disabled }) => disabled ? '#F2F4F7' : colors.white};
  border: ${({ disabled }) => disabled ? `1px solid ${colors.hover_background}` : `1px solid ${colors.purple_border_light}`};
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.12);
  border-radius: 4px;
  cursor: ${({ disabled }) => disabled ? 'text' : 'pointer'};
  color: ${({ disabled }) => disabled ? '#98A2B3' : colors.header_purple};
  display: flex;
  justify-content: space-between;
  margin: 0px auto 20px;
  text-transform: none;
  font-size: 14px;
  font-weight: 500;
  padding: 10px 14px 5px;
  white-space: nowrap;
  height: 40px;
  text-align: center;
  transition: 0.5s;
  :hover {
    background: ${({ disabled }) => disabled ? colors.hover_background : colors.off_white_background};
  }
`;

export const LeadRow = styled('div')`
  display: flex;
  justify-content: space-between;
  width: 100%;
  background: ${colors.white};
  border: 1px solid ${colors.gray_border};
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.12);
  border-radius: 8px;
  padding: 12px 18px;
  margin: 15px 0;
`;

export const LeadRowTitle = styled('div')`
  font-weight: 500;
  text-align: left;
  font-size: 16px;
  color: ${colors.black};
`;

export const LeadRowText = styled('div')`
  font-weight: 400;
  text-align: left;
  font-size: 14px;
  color: ${colors.gray_text};
`;

export const BottomButtonContainer = styled('div')`
  display: flex;
  width: 100%;
  padding: 20px 10px 10px;
  justify-content: space-between;
  position: absolute;
  bottom: 0;
  background: ${colors.white};
  border-width: 1px 1px 0px 1px;
  border-style: solid;
  border-color: #E4E7EC;
  backdrop-filter: blur(2px);
`;