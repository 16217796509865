import * as React from "react";

function CustomMail(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 60 60" fill="none" {...props}>
      <rect
        x={0.375}
        y={0.375}
        width={59.25}
        height={59.25}
        rx={5.625}
        fill="#EFEFFD"
      />
      <path
        d="M44.117 25.588L29.999 18.53l-14.117 7.06V45h28.235V25.588z"
        fill="#D6D7FA"
      />
      <path
        d="M20.293 28.897V15h19.412v13.897l-9.706 7.28-9.706-7.28z"
        fill="#BBBEF7"
      />
      <path
        fill="#EFEFFD"
        d="M22.057 16.765h6.618v1.765h-6.618zM22.057 19.412H38.38v1.765H22.057zM22.057 22.059H38.38v1.765H22.057zM22.057 24.706H38.38v1.765H22.057z"
      />
      <rect
        x={0.375}
        y={0.375}
        width={59.25}
        height={59.25}
        rx={5.625}
        stroke="#E4E7EC"
        strokeWidth={0.75}
      />
    </svg>
  );
}

const MemoCustomMail = React.memo(CustomMail);
export default MemoCustomMail;
