import moment from 'moment';
import { get, toLower } from 'lodash';
import { createActions, handleActions } from 'redux-actions';
import { apiModuleDefaultState, createApiActions } from './helpers';
import { formatMomentIsoDate, parseAnalyticsDataForCharts } from '../../utils';

export const getDefaultStartDate = (selectedDate, fromDate, toDate) => {
  const currentMomentDate = moment();
  const currentFormattedDate = formatMomentIsoDate(moment());
  let startDate = fromDate;
  let endDate = toDate;

  if (toLower(selectedDate) === 'last 30 days') {
    startDate = formatMomentIsoDate(currentMomentDate.subtract(29, 'd'));
    endDate = currentFormattedDate;
  } else if (toLower(selectedDate) === 'last 7 days') {
    startDate = formatMomentIsoDate(currentMomentDate.subtract(6, 'd'));
    endDate = currentFormattedDate;
  } else if (toLower(selectedDate) === 'next 7 days') {
    endDate = formatMomentIsoDate(currentMomentDate.add(6, 'd'));
    startDate = currentFormattedDate;
  } else if (toLower(selectedDate) === 'next 30 days') {
    endDate = formatMomentIsoDate(currentMomentDate.add(29, 'd'));
    startDate = currentFormattedDate;
  }
  return {
    startDate,
    endDate,
  };
};

export const actions = createApiActions('analytics');

export const moreActions = createActions({
  custom: {
    analytics: {
      selectDate: (payload) => payload,
    },
  },
});

const reducer = handleActions(
  {
    [actions.api.analytics.request]: (state, { payload }) => ({
      ...state,
      isFetching: true,
      isError: false,
      error: null,
      repType: get(payload, 'params.repType'),
      leadType: get(payload, 'params.leadType'),
    }),
    [actions.api.analytics.response]: (state, { payload }) => {
      const data = get(payload, 'data', {});
      const repType = get(payload, 'repType', {});
      return {
        ...state,
        isFetching: false,
        isError: false,
        // data: parseAnalyticsDataForCharts(payload),
        error: null,
        [repType]: parseAnalyticsDataForCharts(data, repType),
        data: data,
        startDate: payload.email_id
          ? payload.params.startDate
          : state.startDate,
        endDate: payload.email_id ? payload.params.endDate : state.endDate,
        selectedDate: payload.email_id
          ? payload.params.selectedDate
          : state.selectedDate,
        contactList: payload.params.contact_list || [],
        selectedEmail: payload.params.email_id || '',
      };
    },
    [actions.api.analytics.error]: (state, { payload }) => ({
      ...state,
      isFetching: false,
      isError: true,
      error: payload,
    }),
    [actions.api.analytics.selectedtabindex]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
    [actions.api.analytics.setstate]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
    [moreActions.custom.analytics.selectDate]: (state, { payload }) => {
      const { fromDate, toDate, selectedDate, afterData } = payload;
      return {
        ...state,
        selectedDate,
        ...getDefaultStartDate(selectedDate, fromDate, toDate),
        afterData,
        error: null,
      };
    },
  },
  {
    ...apiModuleDefaultState,
    data: [],
    error: null,
    selectedDate: 'Last 30 days',
    ...getDefaultStartDate('Last 30 days'),
    repType: '',
    selectedTabIndex: 0,
  }
);

export default reducer;
