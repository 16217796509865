import { Flex } from '@rebass/grid';
import React from 'react'
import { ColorPallete, Typography } from '../../../utils';
import { leadCardData } from './contacts-data';
import Skeleton from 'react-loading-skeleton';
import { isEmpty } from 'lodash';
import Carousel from 'react-elastic-carousel'
import styled from 'styled-components';
import { EmptyState } from '../../../componentsV2';
import ErrorDisplay from '../../../componentsV2/ErrorDisplay/ErrorDisplay';
import { Tooltip } from '@mui/material';

const LeadCard = ({
  leadTab,
  setLeadTab,
  data,
  loading,
  handleTabChange,
  engagedError
}) => {

  const CircleFlex = styled(Flex)`
    border-radius: 20px;
    border: 1px solid ${ColorPallete.neutral200};
    background-color: ${ColorPallete.white};
    padding: 8px;
    width: 40px;
    height: 40px;
    align-items: center;
    position: absolute;
    cursor: pointer;
    top: ${({ top }) => top || ''};
    left: ${({ left }) => left || ''};
    right: ${({ right }) => right || ''}; 
    z-index: 5;
`;

  const renderCarouselArrow = ({ type, onClick, isEdge }) => {
    let pointer;
    if (type === 'PREV') {
      if (!isEdge) {
        pointer =
          <CircleFlex top="53%" left="10px" onClick={onClick}>
            <img src="/assets/images/Analytics/ChevronLeft.svg" alt="right" />
          </CircleFlex>
      } else {
        pointer = <></>
      }
    }

    if (type === 'NEXT') {
      if (!isEdge) {
        pointer =
          <CircleFlex top="53%" right="10px" onClick={onClick}>
            <img src="/assets/images/Analytics/ChevronRight.svg" alt="right" />
          </CircleFlex>
      } else {
        pointer = <></>
      }

    }

    return pointer;
  }
  return (
    <Flex
      width="100%"
      height="250px"
      flexDirection="column"
      style={{
        border: `1px solid ${ColorPallete.neutral200}`,
        borderRadius: '8px',
        boxShadow: '0px 1px 2px 0px #1018281F',
        position: 'relative'
      }}
    >
      <Flex width="100%" padding="12px 0px 0px 16px" color={ColorPallete.black} fontSize={Typography.TextLarge}>
        Most Engaged
      </Flex>
      <Flex width="100%" padding="7px 5px 13px 16px">
        <Flex
          pr="16px"
          style={{ cursor: 'pointer' }}
          fontSize={Typography.TextLarge}
          color={leadTab === 0 ? ColorPallete.primaryPurple : ColorPallete.neutral400}
          onClick={() => handleTabChange(0)}
        >
          Contacts
        </Flex>
        <Flex
          pr="16px"
          style={{ cursor: 'pointer' }}
          fontSize={Typography.TextLarge}
          color={leadTab === 1 ? ColorPallete.primaryPurple : ColorPallete.neutral400}
          onClick={() => handleTabChange(1)}
        >
          Email Lists
        </Flex>
        <Flex
          pr="16px"
          style={{ cursor: 'pointer' }}
          fontSize={Typography.TextLarge}
          color={leadTab === 2 ? ColorPallete.primaryPurple : ColorPallete.neutral400}
          onClick={() => handleTabChange(2)}
        >
          Segments
        </Flex>
      </Flex>

      {loading && <Skeleton height={155} width="90%" baseColor={ColorPallete.neutral100} />}
      {!loading && !engagedError && isEmpty(data) && <EmptyState 
        height="155px"
        title="No Analytics Data"
        subTitle="Send out campaigns to get insights"
      />}

      {!loading && engagedError && (
        <ErrorDisplay 
          height="155px"
          onClick={() => handleTabChange(leadTab)}
        />
      )}

      {!loading && !engagedError && !isEmpty(data) && (
        <Carousel 
          itemsToShow={3.2}
          showArrows={true}
          pagination={false}
          itemsToScroll={3}
          renderArrow={renderCarouselArrow}
          itemPosition={data.length === 1 ? 'START' : ''}
        >
          {data.map((item, index, arr) => (
            <Flex
              backgroundColor={ColorPallete.neutral50}
              width="145px"
              height="150px"
              padding="16px"
              margin="0px 16px 16px"
              flexDirection="column"
            >
              <Flex width="100%" justifyContent="center">
                <img height="63" width="63" src="/assets/images/Analytics/Avatar.svg" alt="" />
              </Flex>

              <Flex
                width="100%"
                justifyContent="center"
                pt="10px"
                fontSize={Typography.TextLarge}
                color={ColorPallete.black}
                style={{
                  wordBreak: 'break-all'
                }}
              >
                {item?.list_name}
              </Flex>
              <Tooltip title={item?.email?.length > 25 ? item?.email : ''} arrow>
                <Flex width="100%" justifyContent="center" pt="5px" color={ColorPallete.neutral500} fontSize={Typography.TextSmall} style={{ wordBreak: 'break-all', padding: '5px' }}>
                  {item?.email?.length > 25 ? item?.email.substring(0, 20) + '...' : item?.email}
                </Flex>
              </Tooltip>

            </Flex>
          ))}

        </Carousel>
      )}


    </Flex>
  )
}

export default LeadCard;