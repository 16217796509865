import { BAR_GRAPH_COLORS } from './constants';

export const transformGraphResponse = (res) => {
  if (Array.isArray(res) && res?.length > 0) {
    return res.map((data, index) => ({
      ...data,
      name: data?.theme_name ?? '',
      label: 'Engagement Score',
      fill: BAR_GRAPH_COLORS[index] || '#CDE3E9',
    }));
  }
  return [];
};
