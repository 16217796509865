import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux'
import './MyClients.css';
import { StyledHeading, LoaderWrapper } from '../../components';
import { Flex } from '@rebass/grid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import InviteModal from '../InviteModal/InviteModal';
import ClientResults from './ClientResults/ClientResults';
import { getChannelId } from '../../redux/selector';
import { getPSTVScore, getContactList, getFilteredPSTVScore } from '../../services/dashboard/index';
import { actions as advisorGroupsActions } from '../../redux/modules/advisor-group.module';

const MyClients = ({
    exploreArticleRequest, 
    setClientTopics,
    setTabIndex,
    setSelectedExploreTabIndex,
    isPremiumAdvisor,
    userData,
    isAdmin,
    setShowArticleLoader,
    clientContactList,
    isEnterpriseAdvisor,
    close,
    setClientDetails,
    SNdata,
    crmLoader, 
    toggleCrmLoader,
    groupsList,
    channelId,
    advisorGroupsRequest
}) => {
    const [showClientsModal, toggleClientsModal] = useState(false);
    const [showClientResults, toggleClientResults] = useState(false);
    const [showLoader, toggleLoader] = useState(false);
    const [clientResults, setClientResults] = useState([]);
    const [contactList, setContactList] = useState([]);
    const [clientsFilter, setClientsFilter] = useState('all');
    const [selectedClients, setSelectedClients] = useState('Security')
    const securityOptions = {
        Purpose: { id: 'PURPOSE', path: '/assets/images/Purpose.svg', contactName: 'positivly_purpose' },
        Security: { id: 'SECURITY', path: '/assets/images/Security.svg', contactName: 'positivly_security' },
        Touch: { id: 'TOUCH', path: '/assets/images/Touch.svg', contactName: 'positivly_touch' },
        Viewpoints: { id: 'VIEWS', path: '/assets/images/Viewpoints.svg', contactName: 'positivly_viewpoint' }
    };

    useEffect(() => {
        toggleCrmLoader(true);
        getPSTVScore()
            .then(response => {
                // console.log('[PSTV SCORE] - ', response);
                toggleLoader(false);
                setClientResults(response.result.data.scores);
                setShowArticleLoader(true);
                toggleCrmLoader(false);
                exploreArticleRequest({
                    params: {
                      id: 'SECURITY',
                      source: 'myClients',
                    },
                });
            })
            .catch(err => console.log('[ERROR] - ', err));
    }, []);


    const fetchClientResults = () => {
        toggleClientResults(true);
        toggleLoader(true);
        getPSTVScore()
            .then(response => {
                // console.log('[PSTV SCORE] - ', response);
                toggleLoader(false);
                setClientResults(response.result.data.scores);
            })
            .catch(err => console.log('[ERROR] - ', err));
    }


    const filterClientResults = flag => {
        toggleLoader(true);
        setClientsFilter(flag);
        getFilteredPSTVScore(flag)
            .then(response => {
                // console.log('[PSTV SCORE] - ', response);
                toggleLoader(false);
                setClientResults(response.result.data.scores);
            })
            .catch(err => {
                console.log('[ERROR] - ', err);
                toggleLoader(false);
            });
    }

    const fetchArticlesByTopic = (id, contactName) => {
        // console.log('ID = ', id.charAt(0) + id.toLowerCase().slice(1));
        setClientDetails({id: id, contactName: contactName});
        if (id == 'VIEWS')
            setSelectedClients('Viewpoints');
        else
            setSelectedClients(id.charAt(0) + id.toLowerCase().slice(1));
        setShowArticleLoader(true);
        exploreArticleRequest({
            params: {
                id: id,
                source: 'myClients',
                contactName: contactName
            },
        });
        setTabIndex(0);

        if (isPremiumAdvisor || isEnterpriseAdvisor) {
            if (userData.details && userData.details.user.turnkey && userData.details.user.turnkey === 'true')
                setSelectedExploreTabIndex(6);
            else
                setSelectedExploreTabIndex(5)
        }
        else if (isAdmin)
            setSelectedExploreTabIndex(6);
        else
            setSelectedExploreTabIndex(5);
    }

    const inviteModalHandler = () => {
        toggleLoader(true);
        getContactList()
        .then(response => {
            // console.log('[CONTACT LIST] - ', response.result.data.filter(contact => !['GMAIL', 'HUBSPOT', 'OUTLOOK', 'WEALTHBOX', 'REDTAIL', 'SALESFORCE'].includes(contact.name)));
            setContactList(response.result.data.filter(contact => !['GMAIL', 'HUBSPOT', 'OUTLOOK', 'WEALTHBOX', 'REDTAIL', 'SALESFORCE'].includes(contact.name)));
            toggleLoader(false);
            toggleClientsModal(true);
        })
        .catch(err => console.log('[ERROR] - ', err));
    }

    return (
        <div>
            {!showClientResults ? (
                <FontAwesomeIcon style={{ cursor: 'pointer', position: 'absolute', left: '10px', top: '67px' }} onClick={close} icon={faArrowLeft} />
            ) : null}
            {showClientsModal ? (
                <InviteModal
                    setModalOpen={toggleClientsModal}
                    isModalOpen={showClientsModal}
                    groupsList={groupsList}
                    advisorGroupsRequest={advisorGroupsRequest}
                    channelId={channelId}
                    isConnected={SNdata.data && SNdata.data.em && SNdata.data.em.length > 0}
                    SNdata={SNdata}
                    fromPositivlyInvite={true}
                    snId={SNdata.data && SNdata.data.em && SNdata.data.em[0] ? SNdata.data.em[0].accountId : ''} />
            ) : null}
            <div style={{ display: 'flex' }}>
            <div className='invite-pstv' style={showClientResults ? showLoader ? { marginTop: '4.5rem' } : { marginTop: '0.4rem' } : {}}>
                    {showClientResults ? (
                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', position: 'relative' }}>
                            <LoaderWrapper isLoading={showLoader}>
                                <ClientResults
                                    results={clientResults.slice(0, 4)}
                                    close={() => {
                                        toggleClientResults(false);
                                        setClientsFilter('all');
                                    }}
                                    contactList={clientContactList}
                                    filter={filterClientResults}
                                    filterFlag={clientsFilter}
                                    setFilterFlag={setClientsFilter}
                                    snId={(SNdata.data && SNdata.data.em && SNdata.data.em[0]) ? SNdata.data.em[0].accountId : ''} />
                            </LoaderWrapper>
                        </div>
                    ) : (
                        <div className='relative-div flex-container'>
                            {clientResults.length > 0 ? (
                                <div className='my-client-icon-wrapper client-results'>
                                    <div className='more-clients'>
                                        <img onClick={inviteModalHandler} src='/assets/images/Member-MyClients-20.svg' />
                                        <p onClick={inviteModalHandler}>Invite More Clients</p>
                                    </div>
                                    <div onClick={fetchClientResults}>
                                        <LoaderWrapper isLoading={showLoader} styles={{ position: 'absolute', bottom: '3rem', left: '0' }}>
                                            <div className='client-icon-div client-results-icon-div pstv-score'>
                                                <img src='/assets/images/PSTV_Score.svg' />
                                            </div>
                                        </LoaderWrapper>
                                        <h6 className='pstv'>Client Results</h6>
                                    </div>
                                    <div style={{position: 'absolute'}}></div>
                                </div>
                            ) : (
                                <div className='my-client-icon-wrapper invite-clients' onClick={inviteModalHandler}>
                                    <LoaderWrapper isLoading={showLoader} styles={{ position: 'absolute', bottom: '3rem', left: '0' }}>
                                        <div className='client-icon-div my-client-icon-div invite-clients'>
                                            <img src='/assets/images/Invite_Clients.svg' />
                                        </div>
                                    </LoaderWrapper>
                                    <h6 className='invite'>Invite Clients</h6>
                                </div>
                            )}
                        </div>
                    )}
                </div>
                
                <div className='vertical-separator'></div>
                <div style={{ width: '50%', marginTop: '12px' }}>
                    <div>
                        <div className='flex-container' style={{ marginTop: '10px' }}>
                            {Object.keys(securityOptions).slice(0, 2).map((option, index) => (
                                <Flex
                                    key={'security_options_'+index}
                                    flexDirection="column"
                                    justifyContent="center"
                                    alignItems='center'
                                    width="fit-content"
                                    margin='0'
                                    >
                                    <a
                                        href="#"
                                        onClick={() => fetchArticlesByTopic(securityOptions[option].id, securityOptions[option].contactName)}
                                        style={{
                                            height: '3.5rem',
                                            width: '3.9rem',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            backgroundColor: selectedClients === option ? '#6351ed' : '#6351ed66',
                                            borderRadius: '8px'
                                        }}
                                    >
                                        <img src={securityOptions[option].path} title={option} alt={option} style={{ height: '58%' }} />
                                    </a>
                                    <StyledHeading style={{ color: 'black' }} width='75px' title={option.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())} fontSize='9px' height='19px' isFromMyClients={true}>{option}</StyledHeading>
                                </Flex>
                            ))}
                        </div>
                        <div className='flex-container'>
                            {Object.keys(securityOptions).slice(2).map((option, index) => (
                                <Flex
                                    key={'security_options_'+index}
                                    flexDirection="column"
                                    justifyContent="center"
                                    alignItems='center'
                                    width="fit-content"
                                    margin='0'
                                >
                                    <a
                                        href="#"
                                        onClick={() => fetchArticlesByTopic(securityOptions[option].id, securityOptions[option].contactName)}
                                        style={{
                                            height: '3.5rem',
                                            width: '3.9rem',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            backgroundColor: selectedClients === option ? '#6351ed' : '#6351ed66',
                                            borderRadius: '8px'
                                        }}
                                    >
                                        <img src={securityOptions[option].path} title={option} alt={option} style={{ height: '58%' }} />
                                    </a>
                                    <StyledHeading style={{ color: 'black' }} width='75px' title={option.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())} fontSize='9px' height='19px' isFromMyClients={true}>{option}</StyledHeading>

                                </Flex>

                            ))}
                        </div>
                    </div>
                </div>
                
            </div>
            <div className='footer flex-container'>
                <h6></h6>
                <img src='/assets/images/TIFIN-Personality-Logo.svg' alt="TIFIN-Personality-Logo" style={{ width: '130px', position: 'relative', top: '-13px' }} />
            </div>
        </div >
    );
}

const mapStateToProps = state => {
    return {
        SNdata: state.socialNetwork,
        groupsList: state.advisorGroups.data,
        channelId: getChannelId(state)
    }
}

const mapDispatchToProps = {
    advisorGroupsRequest: advisorGroupsActions.api.groups.request,
  };

export default connect(mapStateToProps, mapDispatchToProps)(MyClients);


