import { Box } from '@rebass/grid';
import React from 'react';
import {
  Content,
  Disclaimer,
  Salutation,
  Template,
  TemplateHeader,
  TemplateSubTitle,
  TemplateTitle,
} from '../EmailTemplates.styles';
import {
  BoxTitle,
  ContentBox,
  ContentContainer,
} from './OneColumnTemplate.styles';

const OneColumnLayout = ({
  value,
  selectedValue,
  onChange,
  isCampaign,
  fromNewsletter,
  id,
}) => (
  <Box
    width={fromNewsletter ? '30%' : '216px'}
    marginBottom={!fromNewsletter ? '22px' : ''}
    marginLeft={!fromNewsletter ? '22px' : ''}
    // style={fromNewsletter ? {maxWidth: ''} : {}}
  >
    <div>
      <TemplateTitle>One-Column</TemplateTitle>
      <TemplateSubTitle>
        Selected content is displayed in one column with share copy for each
        piece of content.
      </TemplateSubTitle>
    </div>
    <Template
      onClick={() => onChange(value)}
      border={
        (fromNewsletter && value === selectedValue) ||
        (!fromNewsletter && value.name === selectedValue.name)
          ? '2px solid #6351ed'
          : '2px solid #adadad'
      }
      style={{
        backgroundColor: 'white',
        boxShadow:
          !fromNewsletter && value.name === selectedValue.name
            ? '2px 3px 10px rgb(0,0,0,0.2)'
            : 'none',
        borderRadius: '5px',
      }}
      id={id}
    >
      <TemplateHeader />
      <Salutation>Hi First_name,</Salutation>
      <div>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
        tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
        veniam, quis nostrud
      </div>
      <Content>
        <ContentContainer>
          <ContentBox />
          <BoxTitle>
            <div>Content Title</div>
            <div style={{ fontSize: '0.7em' }}>Content share copy</div>
          </BoxTitle>
        </ContentContainer>
        <ContentContainer style={{ marginTop: '5px' }}>
          <ContentBox />
          <BoxTitle>
            <div>Content Title</div>
            <div style={{ fontSize: '0.7em' }}>Content share copy</div>
          </BoxTitle>
        </ContentContainer>
      </Content>
      <div>Signature</div>
      <Disclaimer>Disclaimer</Disclaimer>
    </Template>
  </Box>
);

export default OneColumnLayout;
