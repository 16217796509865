import React from 'react'
import { Button, ModalBody, ModalHeader, ModalV2 } from '../../../components'
import { Flex } from '@rebass/grid'
import { useHistory } from 'react-router-dom'
import CreateCampaignModal from '../../DripCampaignContainerV2/DripDashboardTable/CreateCampaignModalsContainer/CreateCampaignModal'
import { useState } from 'react'
import { nurtureFlag } from '../../../utils'

const SuccessModal = ({
    isOpen,
    handleModalClose,
    requiredApproval,
    campaignSetState
}) => {

  const history = useHistory();
  const [openCreateCampaignModal, setOpenCreateCampaignModal] = useState(false);  
  return (
    <ModalV2
        isOpen={isOpen}
        onRequestClose={handleModalClose}
        bgColor="#fff"
        style={{
        content: {
            height: 'fit-content',
            padding: '15px 35px',
            width: '45%',
            top: '20%',
            left: '25%',
            transform: 'translate(-50%, -50%)',
        },
    }}
        ariaHideApp={false}
    >
        <ModalHeader style={{
            color: "#101828",
            fontSize: "20px"
        }}>
            {requiredApproval ? 'Campaign Ready for Compliance Review' : 'Campaign Created Successfully'}
        </ModalHeader>

        <ModalBody>
            <img alt="" src="/assets/images/Campaign/Success.svg" />
            <Flex margin="10px 0px" color="#475467" fontSize="14px" justifyContent="center">
                {!requiredApproval ? 'You can view your ongoing campaigns on the campaign dashboard.' : 'Your campaign will be shared once gone through your enterprise-specific compliance process.'}
            </Flex>

            <Flex width="100%" justifyContent="space-between">
                  <Button width="45%" bgColor="#816EF8" onClick={() => {
                    if(nurtureFlag === 'nurture') {
                        history.push('/analytics');
                    } else {
                        setOpenCreateCampaignModal(true);
                    }
         
                  }}>
                    {nurtureFlag === 'nurture' ? 'View Performance' : 'Create Another'}
                </Button>
                <Button width="45%" bgColor="#FFF" color="#101828" borderColor="#e4e7ec"  onClick={() => history.push('/campaignV2')}>
                    View Campaigns
                </Button>
            </Flex>
            
        </ModalBody>

          {openCreateCampaignModal && (
              <CreateCampaignModal
                  isOpen={openCreateCampaignModal}
                  handleClose={() => setOpenCreateCampaignModal(false)}
                  campaignSetState={campaignSetState}
              />
          )}

    </ModalV2>
  )
}

export default SuccessModal