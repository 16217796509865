/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import { get } from 'lodash';
import moment from 'moment';
import {
  SortTable,
  TableHeader,
  ThSmall,
  THEqual,
  THBig,
  TableData,
  TableRow,
  IconDiv
} from './SortTable';

import { postComplianceRequest } from '../../services/dashboard';
import { HeaderSpan, LoaderContainer, NoArticlesMsg } from './SortTable.styles';
import ShareContentModal from '../ShareContentModal';
import SocialCompRevModal from '../SocialComplianceReviewModal/SocialCompRevModal';
import { Article, getMediaUrl } from '../../utils';
import { ApproveMessageModal } from '../ApproveModal';
import ConfirmationModal from './ConfirmationModal/ConfirmationModal';
import './ComplianceSortTable.css';
import { HistoryModal } from './HistoryModal/HistoryModal';
import { FooterBtnsWrapper } from '../../containers/DripCampaignContainerV2/DripCampaignContainerV2.styles';
import CampaignFooterButtons from '../../containers/DripCampaignContainerV2/CampaignFooterButtons/CampaignFooterButtons';
import { complianceStatusUpdate } from '../../services/bee-editor';

const MODAL_IMAGE = 'modal_image';
const socialPlatforms = {
  fb: 'Facebook',
  tw: 'Twitter',
  ln: 'LinkedIn',
  em: 'Email',
  mio: 'Clout',
};

export default function ComplianceSortTable({
  data,
  handlePageChange,
  hasActions,
  hasAdmin,
  showMio,
  handleActions,
  tabType,
  hasAdminPermission,
  openArticle,
  showComplianceActions,
  currentTab,
  date,
  setDetails,
  complianceTrailRequest,
  complianceTrailDetails,
}) {
  console.log('DATA = = = = = = = = ', data);
  console.log(
    'complianceTrailDetails = = = = = = = = ',
    complianceTrailDetails
  );
  const [isSocialRevModal, setIsSocialRevModal] = useState(false);
  const [cardOpen, setCardOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalArticle, setModalArticle] = useState(null);
  const [modalMessage, setModalMessage] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [feedMessage, setfeedMessage] = useState(false);
  const [feedbackText, setFeedbackText] = useState({
    message: '',
    fromTop: '146px',
  });
  const [reject, setReject] = useState(false);
  const [channel, setChannel] = useState([]);
  const [confirmationModal, toggleConfirmationModal] = useState(false);

  const [articleToBeApproved, setArticleToBeApproved] = useState({});
  const [historyModal, setHistoryModal] = useState(false);
  const [historyDetails, setHistoryDetails] = useState({});
  const [curCampaign, setCurCampaign] = useState({});

  let currentArticles = { ...data };

  const platformMapper = {
    email: 'Email',
    social: 'Social',
    clout: 'Clout',
    web_page: 'WebPage'
  };


  useEffect(() => {
    setHistoryDetails({ ...historyDetails, trail: complianceTrailDetails });
  }, [complianceTrailDetails]);

  useEffect(() => {
    if (!modalOpen && !isSocialRevModal) {
      setChannel([]);
      setReject(false);
    }
  }, [modalOpen, isSocialRevModal, reject]);

  useEffect(() => {
    setCurrentPage(1);
  }, [date]);

  const handleArticleClick = (article) => {
    console.log('modal article =  ', article);
    let platformChannel = [];
    const channelName = get(article, 'share_details.destination.snetwork', '');
    if (get(article, 'campaign', false) || get(article, 'share_details.platform', '')) {
      setCurCampaign(article);
      setIsSocialRevModal(true);
    } else {
      platformChannel.push(channelName);
      setChannel(article.share_details ? platformChannel : ['mio']);
      setModalArticle(article);
      setModalOpen(true);
    }
  };

  const handleMoreClick = (message, index) => {
    setFeedbackText({ message, fromTop: `${146 + index * 30}px` });
    setCardOpen(true);
  };

  const handleArticleApproveRequest = (id, reqType, message, platform, webPageId) => {
 
    const reqObj =
      reqType === Article.APPROVE
        ? { action: 'approved' }
        : { action: 'rejected' };
    postComplianceRequest(id, { ...reqObj, message }).then((res) => {
      handleActions(0);
      toggleConfirmationModal(false);
      // if(get(res, 'result.success', false) && platform === 'web_page') {
      //   let payload = {
      //     type: Article.APPROVE ? 'approved' : 'rejected'
      //   }
      //   complianceStatusUpdate({id: webPageId, payload});
      // }
    }).catch(err => console.log(err));
    setModalMessage(true);
    setModalOpen(false);
    setArticleToBeApproved({});
    setTimeout(() => {
      setModalMessage(false);
      toggleConfirmationModal(false);
      handleActions(0)
    }, 2000);
  };

  const handleArticleRequest = (e, id, reqType, message) => {
    e.stopPropagation();
    const reqObj =
      reqType === Article.APPROVE
        ? { action: 'approved' }
        : { action: 'rejected' };
    postComplianceRequest(id, { ...reqObj, message }).then((res) => {
      handleActions(0);
    });

    setfeedMessage(true);
    setModalOpen(false);
    setTimeout(() => {
      setfeedMessage(false);
    }, 2000);
  };

  const getImage = (obj, image) => {
    const mediaUrl = getMediaUrl(get(obj, 'media_urls'));
    if (image === MODAL_IMAGE) return mediaUrl;
    return mediaUrl;
  };

  // const getPublisherImage = (id) => {
  //   const reqAccount = get(data, `details.account[${id}]`);
  //   return getImage(reqAccount);
  // };

  // const getPublisherTitle = (id) => {
  //   return get(data, `details.account.${id}.title`);
  // };

  // const getTopicImage = (theme) => {
  //   const id = theme
  //     ? theme.length > 0 && theme[0].id
  //       ? theme[0].id
  //       : theme[0]
  //     : undefined;
  //   if (id !== undefined) {
  //     const reqAccount = get(data, `details.theme[${id}]`);
  //     return getImage(reqAccount);
  //   } else {
  //     return '';
  //   }
  // };
  // const getTopicTitle = (theme) => {
  //   const id = theme
  //     ? theme.length > 0 && theme[0].id
  //       ? theme[0].id
  //       : theme[0]
  //     : undefined;
  //   if (id !== undefined) {
  //     return get(data, `details.theme.${id}.name`);
  //   } else {
  //     return '';
  //   }
  // };

  const getPaginatedData = (beforeId, afterId) => {
    let page = beforeId ? beforeId : afterId;
    setCurrentPage(beforeId ? currentPage - 1 : currentPage + 1);
    console.log(
      '[BEFORE] - ',
      beforeId,
      '[AFTER] - ',
      afterId,
      '[PAGE] - ',
      page
    );
    setDetails(currentTab, page, date);
  };

  const confirmClickHandler = (e, id, reqType, item) => {
    e.stopPropagation();
    toggleConfirmationModal(true);
    setArticleToBeApproved({ id: id, reqType: reqType, item });
  };

  const ApproveModal = modalMessage && (
    <ApproveMessageModal open={modalMessage} type=""></ApproveMessageModal>
  );

  const FeedbackModal = feedMessage && (
    <ApproveMessageModal
      open={feedMessage}
      type="feedback"
    ></ApproveMessageModal>
  );

  const ModalContent = modalArticle && (
    <ShareContentModal
      open={modalOpen}
      header="Content Review"
      handleClose={() => setModalOpen(false)}
      imagesrc={getImage(modalArticle, MODAL_IMAGE)}
      contentLink={
        (
          modalArticle.newsletter || 
          get(modalArticle, 'share_details.platform', '') === 'web_page'
        ) ? 
          '' : modalArticle.links.social_permalink
      }
      title={
        get(modalArticle, 'share_details.platform', '') === 'web_page' ? 
        get(modalArticle, 'id', '#000') : get(modalArticle, 'auxiliary.title', '')}
      time={modalArticle.created_time}
      id={get(modalArticle, 'ids.content.id', '')}
      complianceId={get(modalArticle, 'id', '')}
      themeId={get(modalArticle, 'ids.theme[0].id', '')}
      handleFeedback={(e, reqType, message) =>
        handleArticleRequest(e, modalArticle.id, reqType, message)
      }
      handleSubmit={(e, reqType, message) =>
        handleArticleApproveRequest(modalArticle.id, reqType, message, get(modalArticle, 'share_details.platform', ''), get(modalArticle, 'ids.content.id', ''))
      }
      reloadRejectedTab={handleActions}
      summaryDetails={modalArticle.summary}
      summaryCheck={modalArticle.summary_available}
      shareDetails={modalArticle.share_details}
      isFromAdmin={hasAdminPermission}
      reject={reject}
      channel={channel}
      isFromCompliance
      isNewsLetter={modalArticle.newsletter}
      newsLetter_id={
        modalArticle.ids &&
        modalArticle.ids.content &&
        modalArticle.ids.content.id
          ? modalArticle.ids.content.id
          : ''
      }
      complianceTab={currentTab}
      internalUse={get(modalArticle.auxiliary, 'internal_content', '')}
      engagementId={get(modalArticle, 'ids.engagement_id', '')}
      source={modalArticle.auxiliary ? modalArticle.auxiliary.source : ''}
      mediaUrl={get(modalArticle, 'media_urls[0]', '')}
      complianceCustomisedData={get(
        modalArticle,
        'auxiliary.customized_data',
        ''
      )}
    />
  );

  return (
    <div
      style={{
        borderRadius: '5px',
        marginTop: '5%',
      }}
    >
      <SortTable>
        <TableHeader
          style={{ borderRadius: '30px', backgroundColor: '#cfd9e4' }}
          data-testid="compliance-review-table-header"
        >
          <tr>
            <ThSmall width={tabType === 'rejected' ? '16.5%' : '5%'}>
             
              <HeaderSpan>Submitted On</HeaderSpan>
             
            </ThSmall>
            {showComplianceActions ? (
              <THEqual>
                <HeaderSpan>Submitted By</HeaderSpan>
              </THEqual>
            ) : null}
            <ThSmall width={tabType === 'rejected' ? '15%' : '12%'}>
              <HeaderSpan>Start Date</HeaderSpan>
            </ThSmall>
            {tabType && tabType === 'rejected' ? (
              <THEqual>
              
                <HeaderSpan>Campaign</HeaderSpan>
               
              </THEqual>
            ) : (
              <THBig style={{ textAlign: 'left' }}>
          
                <HeaderSpan>Campaign</HeaderSpan>
                
              </THBig>
            )}
            <ThSmall width="5%">
              <HeaderSpan>Platform</HeaderSpan>
            </ThSmall>
           
            {tabType && tabType === 'rejected' && <ThSmall width="2.333333%" />}
            
            {hasActions && (
              <THEqual>
               
                <HeaderSpan>Review</HeaderSpan>
               
              </THEqual>
            )}
            {currentTab === 'rejected' && (
              <THEqual style={{ padding: '8px 22px' }}>
               
                <HeaderSpan>Feedback</HeaderSpan>
               
              </THEqual>
            )}
           
            <ThSmall width="5%">
              <HeaderSpan>History</HeaderSpan>
            </ThSmall>
           
          </tr>
        </TableHeader>
        <tbody>
          {!!data ? (
            data.length !== 0 ? (
              currentArticles.compliance_content.length !== 0 ? (
                currentArticles.compliance_content.map((article, index) => (
                  <TableRow
                    style={{ cursor: 'pointer' }}
                    key={article.id}
                    data-testid="compliance-review-item"
                  >
                    <TableData
                      width="15%"
                      onClick={
                        hasActions || hasAdminPermission
                          ? () => handleArticleClick(article)
                          : () => {}
                      }
                    
                      style={{
                        cursor:
                          hasActions || hasAdminPermission
                            ? 'pointer'
                            : 'default',
                      }}
                      data-testid="compliance-review-date"
                    >
                      {!article.newsletter &&
                      (!article.auxiliary ||
                        article.auxiliary.source !== 'img') &&
                        !article.landing_page &&
                      openArticle ? (
                        <a
                          style={{ textDecoration: 'none' }}
                          href={
                            !get(article, 'campaign', false) &&
                            get(article, 'links.social_permalink', '')
                          }
                          rel="noopener noreferrer"
                          target="_blank"
                        >
                          {moment(article.compliance.application.date).format(
                            'MMM DD YYYY'
                          )}
                        </a>
                      ) : (
                        moment(article.compliance.application.date).format(
                          'MMM DD YYYY'
                        )
                      )}
                     
                    </TableData>
                    {showComplianceActions ? (
                      <TableData
                        width="15%"
                        onClick={
                          hasActions || hasAdminPermission
                            ? () => handleArticleClick(article)
                            : () => {}
                        }
                       
                        style={{
                          cursor:
                            hasActions || hasAdminPermission
                              ? 'pointer'
                              : 'default',
                        }}
                        data-testid="compliance-review-submitBy"
                      >
                        {!article.newsletter &&
                        (!article.auxiliary ||
                          article.auxiliary.source !== 'img')&&
                          !article.landing_page &&
                        openArticle ? (
                          <a
                            style={{ textDecoration: 'none' }}
                            href={
                              !get(article, 'campaign', false) &&
                              get(article, 'links.social_permalink', '')
                            }
                            rel="noopener noreferrer"
                            target="_blank"
                          >
                            {article.compliance.application.first_name +
                              ' ' +
                              article.compliance.application.last_name}
                          </a>
                        ) : (
                          article.compliance.application.first_name +
                          ' ' +
                          article.compliance.application.last_name
                        )}
                       
                      </TableData>
                    ) : null}
                    <TableData
                      onClick={
                        hasActions || hasAdminPermission
                          ? () => handleArticleClick(article)
                          : () => {}
                      }
                     
                      style={{
                        cursor:
                          hasActions || hasAdminPermission
                            ? 'pointer'
                            : 'default',
                      }}
                      data-testid="compliance-review-startDate"
                    >
                      <a
                        style={{ 
                          textDecoration: 'none', 
                          pointerEvents: article.landing_page ? 'none' : 'auto' 
                        }}
                        href={
                          !get(article, 'campaign', false) &&
                          get(article, 'links.social_permalink', '')
                        }
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        {get(article, 'start_date', null)
                          ? moment(article.start_date).format('MMM DD YYYY')
                          : '-'}
                      </a>
                    </TableData>
                    <TableData
                      style={{
                        textAlign: 'center',
                        cursor:
                          hasActions || hasAdminPermission
                            ? 'pointer'
                            : 'default',
                      }}
                      width={tabType && tabType === 'rejected' ? '35%' : '30%'}
                      onClick={
                        hasActions || hasAdminPermission
                          ? () => handleArticleClick(article)
                          : () => {}
                      }
                      data-testid="compliance-review-title"
                     
                    >
                      {(get(article, 'campaign', false) || get(article, 'acb', false)) ? (
                        get(article, 'name', '')
                      ) : get(article, 'share_details.platform') === 'web_page' ? get(article, 'name', '')  : article.newsletter ? (
                        'Newsletter: ' + article?.auxiliary?.title
                      ) : article.auxiliary &&
                        article.auxiliary.source === 'img'&&
                        !article.landing_page ? (
                        'Image: ' + article?.auxiliary?.title
                      ) : openArticle ? (
                        <a
                          style={{ textDecoration: 'none' }}
                          href={get(article, 'links.social_permalink', '')}
                          rel="noopener noreferrer"
                          target="_blank"
                        >
                          {article?.auxiliary?.title}
                        </a>
                      ) : (
                        article?.auxiliary?.title
                      )}
                      {article.auxiliary &&
                        article.auxiliary &&
                        article.auxiliary.internal_content &&
                        article.auxiliary.internal_content === 'true' && (
                          <div
                            style={{
                              backgroundColor: '#555555',
                              color: '#d5d5d5',
                              fontSize: '10px',
                              borderRadius: '3px',
                              padding: '0px 4px',
                              textTransform: 'none',
                              fontWeight: '500',
                              height: '15px',
                              width: 'fit-content',
                            }}
                          >
                            Internal Use
                          </div>
                        )}
                    </TableData>
                    <TableData
                      onClick={
                        hasActions || hasAdminPermission
                          ? () => handleArticleClick(article)
                          : () => {}
                      }
                    
                      style={{
                        cursor:
                          hasActions || hasAdminPermission
                            ? 'pointer'
                            : 'default',
                      }}
                      data-testid="compliance-review-platform"
                    >
                      {get(article, 'campaign', false) ? (
                        platformMapper[get(article, 'share_details.platform')]
                      ) : get(article, 'acb', false) ? platformMapper[get(article, 'share_details.platform')] : get(article, 'share_details.platform') === 'web_page' ? platformMapper[get(article, 'share_details.platform')] : !article.newsletter &&
                        (!article.auxiliary ||
                          article.auxiliary.source !== 'img')&&
                          !article.landing_page &&
                        openArticle ? (
                        <a
                          style={{ textDecoration: 'none' }}
                          href={get(article, 'links.social_permalink', '')}
                          rel="noopener noreferrer"
                          target="_blank"
                        >
                          {article.share_details
                            ? socialPlatforms[
                                get(
                                  article,
                                  'share_details.destination.snetwork',
                                  ''
                                )
                              ]
                            : socialPlatforms.mio}
                        </a>
                      ) : article.share_details ? (
                        socialPlatforms[
                          get(article, 'share_details.destination.snetwork', '')
                        ]
                      ) : (
                        socialPlatforms.mio
                      )}
                     
                    </TableData>
                    
                    {tabType && tabType === 'rejected' && (
                      <TableData style={{ cursor: 'default' }} />
                    )}

                   
                    {hasActions && (
                      <TableData>
                        <IconDiv
                          onClick={(e) =>
                            confirmClickHandler(e, article.id, Article.APPROVE, article)
                          }
                          className="icon dripicons-checkmark"
                          style={{ color: '#487ce8' }}
                          data-testid="compliance-review-approve"
                        ></IconDiv>
                        <IconDiv
                          onClick={() => {
                            if (hasActions) {
                              handleArticleClick(article);
                              setReject(true);
                            }
                          }}
                          key={article.id}
                          className="icon dripicons-cross"
                          style={{ color: '#487ce8' }}
                          data-testid="compliance-review-reject"
                        ></IconDiv>
                      </TableData>
                    )}
                    {currentTab === 'rejected' &&
                      article.compliance &&
                      article.compliance.approver &&
                      article.compliance.approver.message && (
                        <TableData style={{ cursor: 'default' }}>
                          <div
                            style={{
                              fontSize: '11px',
                              wordBreak: 'break-word',
                              color: '#808080',
                              textAlign: 'center',
                            }}
                            data-testid="compliance-review-feedback"
                          >
                            {article.compliance.approver.message.length > 30 ? (
                              <>
                                {article.compliance.approver.message.substring(
                                  0,
                                  30
                                )}
                                ...
                                <a
                                  onClick={() =>
                                    handleMoreClick(
                                      article.compliance.approver.message,
                                      index
                                    )
                                  }
                                  style={{ cursor: 'pointer' }}
                                >
                                  more
                                </a>
                              </>
                            ) : article.compliance.approver.message ===
                                'undefined' ||
                              article.compliance.approver.message ===
                                undefined ? (
                              '-'
                            ) : (
                              article.compliance.approver.message
                            )}
                          </div>
                        </TableData>
                      )}
                   
                    <TableData
                      onClick={() => {
                        complianceTrailRequest({
                          compliance_id: article?.id,
                        });
                        setHistoryModal(true);
                        setHistoryDetails(
                          get(article, 'campaign', false)
                            ? {
                                insight: get(article, 'name', ''),
                                channel: get(article, 'share_details.platform'),
                                mailingLists: get(),
                                trail: complianceTrailDetails,
                              }
                            : {
                                insight: get(article, 'auxiliary.title', '') || get(article, 'name', ''),
                                channel: article.share_details
                                  ? socialPlatforms[
                                      get(
                                        article,
                                        'share_details.destination.snetwork',
                                        ''
                                      )
                                    ]
                                  : socialPlatforms.mio,
                                trail: complianceTrailDetails,
                              }
                        );
                      }}
                      style={{ color: '#487ce8' }}
                      data-testid="compliance-review-history"
                    >
                      View
                    </TableData>
                   
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableData colSpan={hasActions ? '8' : '8'}>
                    <LoaderContainer>
                      <NoArticlesMsg>No Articles</NoArticlesMsg>
                    </LoaderContainer>
                  </TableData>
                </TableRow>
              )
            ) : (
              <TableRow>
                <TableData colSpan={hasActions ? '8' : '8'}>
                  <LoaderContainer>
                    <NoArticlesMsg>No Articles</NoArticlesMsg>
                  </LoaderContainer>
                </TableData>
              </TableRow>
            )
          ) : (
            <TableRow>
              <TableData colSpan={hasActions ? '8' : '9'}>
                <LoaderContainer>
                  <img src="/assets/images/loaderV.gif" alt="loader" />
                </LoaderContainer>
              </TableData>
            </TableRow>
          )}
          {confirmationModal ? (
            <ConfirmationModal
              click={() =>
                handleArticleApproveRequest(
                  articleToBeApproved.id,
                  articleToBeApproved.reqType,
                  'Approved',
                  get(articleToBeApproved, 'item.share_details.platform', ''), 
                  get(articleToBeApproved, 'item.ids.content.id', '')
                )
              }
              handleModalClose={() => {
                toggleConfirmationModal(false);
                setArticleToBeApproved({});
              }}
            />
          ) : null}
          {cardOpen ? (
            <div
              className="alert"
              style={{
                textAlign: 'left',
                lineHeight: '10px',
                top: feedbackText.fromTop,
                right: '17px',
                width: '250px',
                height: 'auto',
                position: 'absolute',
                padding: '15px',
                backgroundColor: '#ededed',
                color: '#808080',
                marginBottom: '15px',
                wordBreak: 'break-word',
                zIndex: '5',
              }}
            >
              <span
                className="closebtn"
                style={{
                  marginLeft: '15px',
                  color: '#545454',
                  float: 'right',
                  cursor: 'pointer',
                }}
                onClick={() => setCardOpen(false)}
              >
                &times;
              </span>
              <span style={{ fontSize: 10, marginTop: '-10px' }}>
                {feedbackText.message}
              </span>
            </div>
          ) : null}
        </tbody>
        <tfoot style={{ borderCollapse: 'inherit' }}></tfoot>
      </SortTable>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '16px 0px 0px 5px',
        }}
      >
        <div>
          {currentArticles &&
          currentArticles.dataCount &&
          parseInt(currentArticles.dataCount) > 0 ? (
            <p style={{ fontSize: '11px', color: '#49484a' }}>
              {(currentPage - 1) * 5 +
                1 +
                '-' +
                (currentPage * 5 < parseInt(currentArticles.dataCount)
                  ? currentPage * 5
                  : currentArticles.dataCount)}{' '}
              of {currentArticles.dataCount}
            </p>
          ) : null}
        </div>
      </div>
      <FooterBtnsWrapper style={{ zIndex: 1 }}>
        <CampaignFooterButtons
          enableNext={
            currentArticles &&
            currentArticles.pagination &&
            currentArticles.pagination.after
              ? true
              : false
          }
          enableBack={
            currentArticles &&
            currentArticles.pagination &&
            currentArticles.pagination.before
              ? true
              : false
          }
          nextHandler={() =>
            getPaginatedData(null, currentArticles.pagination.after)
          }
          backHandler={() =>
            getPaginatedData(currentArticles.pagination.before, null)
          }
          complianceStyle={{ padding: '5px 0px', justifyContent: 'flex-end' }}
          isCompliance={true}
        />
      </FooterBtnsWrapper>
      {FeedbackModal}
      {ApproveModal}
      {modalArticle && ModalContent}
      {historyModal && (
        <HistoryModal
          handleModalClose={() => {
            setHistoryModal(false);
          }}
          historyDetails={historyDetails}
          complianceTrailRequest={complianceTrailRequest}
          platformMapper={platformMapper}
        />
      )}
      {isSocialRevModal && (
        <SocialCompRevModal
          isOpen={isSocialRevModal}
          handleClose={() => setIsSocialRevModal(false)}
          curCampaign={curCampaign}
          setDetails={setDetails}
          curTab={currentTab}
          curDate={date}
          rejectCampaign={reject}
        />
      )}
    </div>
  );
}
