import React, { useEffect } from 'react';

import { Switch, Route, useHistory } from 'react-router-dom';
import {
  AdminAnalytics,
  AdvisorAnalytics,
  AdminContactsPage,
  AdvisorContactsPage,
  AnalyticsSummary,
  Channel,
  Compliance,
  DashboardLayout,
  Login,
  Portfolio,
  AdvisorConfigure,
  AdminConfigure,
  Theme,
  Subscription,
  ImpactSummary,
  Newsletter,
  Content,
  DripCampaignLayoutV2,
  ProfessionalResearch,
  CampaignEmailTemplateLayout,
  EmailEditorLayout,
  CampaignContainerViewLayout,
  LandingPageEditorLayout,
  ComplianceApproveAllLayout,
  ManageUserLayout,
  CreateSocialLayout,
  CreateEmailBlockLayout,
  TemplatesLayout,
  PageNotFound,
} from './layouts';
import BeeFreeEditorWrapper from './containers/DripCampaignContainerV2/BeeFreeEditorWrapper/BeeFreeEditorWrapper';
import AdvisorSettings from './containers/AdvisorSettings/AdvisorSettings';
import CustomRowsLayout from './layouts/CustomRowsLayout';
import CustomRowsEditorLayout from './layouts/CustomRowsEditorLayout';
import ResetPassword from './layouts/ResetPassword/ResetPassword';
import ComplianceApproveContainerLayout from './layouts/ComplianceApproveContainerLayout';
import ComplianceRejectContainerLayout from './layouts/ComplianceRejectContainerLayout';
import LandingPageLayout from './layouts/LandingPageLayout';

import { LoginCallback, Security } from '@okta/okta-react';
import { oktaHelper } from './utils';
import Unsubscribe from './layouts/Unsubscribe';
import ComplianceRejectAllLayout from './layouts/ComplianceRejectAllLayout';
import ForwardAFriendLayout from './layouts/ForwardAFriend/ForwardAFriendLayout';
import UploadCsv from './layouts/UploadCsvContainer/UploadCsv';
import NutureRedirect from './layouts/NutureRedirect/NutureRedirect';
import { toRelativeUrl } from '@okta/okta-auth-js';
import Protected from './layouts/Protected/Protected';
import Main from './pages';
import Discover from './pages/Discover';
import CampaignOverview from './containers/AnalyticsContainer/CampaignTab/CampaignOverview';

const Routes = () => {

  const history = useHistory();
  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    history.replace(toRelativeUrl(originalUri || '/', window.location.origin));
  };


  const customAuthHandler = (oktaAuth) => {
    // Redirect to the /login page that has a CustomLoginComponent
    // This example is specific to React-Router
    history.push('/login');
  };



  return (
    <div style={{ minHeight: '100vh', background: 'white' }}>
      <Security oktaAuth={oktaHelper} restoreOriginalUri={restoreOriginalUri}>
        <Switch>
          <Route exact path={['/','/admin/login', '/login']} component={Login} />
           <Route exact path={["/home",'/admin/home']} component={Main} />
          <Route exact path="/admin/discover" component={Discover} />
          <Route exact path="/discover" component={Discover} />
          {/* <Route exact path="/admin/dashboard" component={DashboardLayout} />
          <Route exact path="/dashboard" component={DashboardLayout} /> */}
          <Route exact path="/portfolio" component={Portfolio} />
          <Route exact path="/admin/analytics" component={AdminAnalytics} />
          <Route exact path="/analytics/summary" component={AnalyticsSummary} />
          <Route exact path="/channel" component={Channel} />
          <Route exact path="/analytics" component={AdvisorAnalytics} />
          <Route exact path="/compliance" component={Compliance} />
          <Route exact path="/admin/compliance" component={Compliance} />
          <Route exact path="/admin/contacts" component={AdminContactsPage} />
          <Route exact path="/contacts" component={AdvisorContactsPage} />
          <Route exact path="/templates" component={TemplatesLayout} />
          <Route
            exact
            path="/advisor/compliance"
            render={(props) => <Compliance {...props} />}
          />
          <Route exact path="/admin/configure" component={AdminConfigure} />
          <Route exact path="/configure" component={AdvisorConfigure} />
          <Route exact path="/theme" component={Theme} />
          <Route exact path="/subscription" component={Subscription} />
          <Route exact path="/impact-summary-pdf" component={ImpactSummary} />
          <Route exact path="/newsletter" component={Newsletter} />
          <Route
            exact
            path="/content/:companyAccountId/:userId/:articleId"
            component={Content}
          />
          <Route exact path="/callback" component={ResetPassword} />
          <Route exact path="/campaignV2" component={DripCampaignLayoutV2} />
          <Route
            exact
            path="/customize-email"
            component={BeeFreeEditorWrapper}
          />
          <Route
            exact
            path="/create-email/:id/:campaignId"
            component={CreateEmailBlockLayout}
          />
          <Route
            exact
            path="/create-social/:id/:campaignId"
            component={CreateSocialLayout}
          />
          <Route
            exact
            path="/campaign/template"
            component={CampaignEmailTemplateLayout}
          />
          <Route
            exact
            path="/campaign/template/:id"
            component={EmailEditorLayout}
          />
          <Route
            exact
            path="/campaign/custom-row"
            component={CustomRowsLayout}
          />
          <Route
            exact
            path="/campaign/custom-row/:id"
            component={CustomRowsEditorLayout}
          />
          <Route exact path="/advisor-settings" component={AdvisorSettings} />
          <Route exact path="/survey" component={ProfessionalResearch} />
          <Route
            exact
            path="/campaign/view/:id"
            component={CampaignContainerViewLayout}
          />
          <Route
            exact
            path="/compliance/approve"
            component={ComplianceApproveContainerLayout}
          />
          <Route
            exact
            path="/compliance/approve_all"
            component={ComplianceApproveAllLayout}
          />
          <Route
            exact
            path="/compliance/reject"
            component={ComplianceRejectContainerLayout}
          />
          <Route
            exact
            path="/compliance/reject_all"
            component={ComplianceRejectAllLayout}
          />
          <Route
            exact
            path="/campaign/landing-pages"
            component={LandingPageLayout}
          />
          <Route
            exact
            path="/campaign/landing-pages/:pageId"
            component={LandingPageEditorLayout}
          />
          <Route path="/login/callback" component={LoginCallback} />

          <Route exact path="/unsubscribe" component={Unsubscribe} />
          <Route exact path="/forward_email" component={ForwardAFriendLayout} />
          <Route exact path="/manage-users" component={ManageUserLayout} />
          <Route exact path="/upload-csv" component={UploadCsv} />
          <Route exact path="/nurture-redirect" component={NutureRedirect} />
          <Route exact path="/analytics/campaign/:id" component={CampaignOverview} />
          <Route path="*" component={PageNotFound} />
          {/* <Route exact path="/email-sent" component={ConfirmationPageContainer} /> */}
        </Switch>
      </Security>
    </div>
  );
};

export default Routes;
