import React from 'react'
import { Footer } from '../../componentsV2';
import { Flex } from '@rebass/grid';
import { Button } from '../../components';
import { ColorPallete } from '../../utils';


const TemplateFooter = ({
    beeInstance,
    backHandler,
    enable
}) => {
    const handleSaveClick = () => {
        beeInstance.save();
    };

    const handlePreview = () => {
        beeInstance.preview();
    };
    return (
        <Footer>
            <Flex width="84%">
                <Button
                    bgColor={ColorPallete.white}
                    color={ColorPallete.primaryBlue}
                    borderRadius="4px"
                    margin="10px 0px 10px 35px"
                    borderColor={ColorPallete.pink}
                    onClick={backHandler}
                    disabled={!enable}
                >
                    Back
                </Button>
            </Flex>
            <Flex width="16%">
                <Button
                    bgColor={ColorPallete.white}
                    color={ColorPallete.primaryBlue}
                    borderRadius="4px"
                    margin="10px 15px 10px 0px"
                    borderColor={ColorPallete.pink}
                    disabled={!enable}
                    onClick={handlePreview}
                >
                    Preview
                </Button>
                <Button
                    bgColor={ColorPallete.primaryBlue}
                    color={ColorPallete.white}
                    borderRadius="4px"
                    margin="10px 0px 10px 0px"
                    borderColor={ColorPallete.primaryBlue}
                    disabled={!enable}
                    onClick={handleSaveClick}
                >
                    Create
                </Button>
            </Flex>
        </Footer>
    )
}

export default TemplateFooter;