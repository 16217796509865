/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { get, isEmpty } from 'lodash';
import {
  ConfigureContainerStyle,
} from './styles';
import {
  getContactsGroups,
} from '../../services/configure';
import { actions as contactsActions } from '../../redux/modules/contacts.module';
import { actions as socialNetworkActions } from '../../redux/modules/socialNetwork.module';
import { actions as loginActions } from '../../redux/modules/login.module';
import {
  getUserDetails,
  getFirmSettings
} from '../../redux/selector/login.selectors';
import {
  getContactListForEmail,
  salesforceSettingsMapper,
  salesforceTogglesMapper,
} from '../../utils';
import { getSocialAccountURLs } from '../../services/dashboard';
import EmailList from '../ConfigureContainer/EmailList';
import { getComplianceSettingsAdmin } from '../../services/configure/index';

const AdminContacts = ({
  contactError,
  contactsList,
  contactsRequest,
  contactsDelete,
  contactsCreate,
  userData,
  isContactsFetching,
  socialNetworkData,
  socialNetworkDelete,
  socialNetworkDataFetching,
  loginRequest,
  contactGroupsList,
  selectedContactGroup,
  paginationData,
  navigateAfter,
  socialNetworkStatusRequest,
  selectedContactGroupDefault,
  userDetails,
  selectedTabIndex,
  disabledPlatforms,
  customContactGroupList,
  contactsCount,
  contactGroups,
  userId,
  noRecords,
  isEnterpriseLevelAdmin
}) => {
  const [socialAccountURL, setSocialAccountURL] = useState(null);
  const [isUpdateContact, setIsUpdateContact] = useState(false);
    const [selectedConfigureIndex, setSelectedConfigureIndex] =
    useState(selectedTabIndex);
  const [advisorsList, setAdvisorsList] = useState([]);
  const [generalSettingsLoading, toggleGeneralSettingsLoading] =
    useState(false);
  const [selectedCheckBoxes, setSelectedCheckBoxes] = useState([
    { platform: 'ln', checked: false, isDisconnected: true },
    { platform: 'tw', checked: false, isDisconnected: true },
    { platform: 'fb', checked: false, isDisconnected: true },
    { platform: 'em', checked: false, isDisconnected: true },
  ]);


  const retrieveGroupsList = () => {
    contactsRequest({ params: { contactGroup: '' } });
      // const data = getContactListForEmail(get(response, 'result.data', []));
      // if (!isEmpty(data)) {
      //   const hasIsDefault = data.filter((d) => d.is_default);
      //   const firstGroup = isEmpty(hasIsDefault)
      //     ? get(data, `[0].id`, '')
      //     : hasIsDefault[0].id;
      //   contactsRequest({ params: { contactGroup: firstGroup } });
      // } else {
  };

  useEffect(() => {
    if (selectedTabIndex === 4) {
      toggleGeneralSettingsLoading(true);
      const companyId = get(userData, 'company', '');
      getComplianceSettingsAdmin(companyId)
        .then((response) => {
          setAdvisorsList(response.result.data);
          toggleGeneralSettingsLoading(false);
        })
        .catch((err) => {
          console.log('[ERROR] - ', err);
          toggleGeneralSettingsLoading(false);
        });
    }
    getSocialAccountURLs()
      .then((res) => {
        setSocialAccountURL(res.result.data);
      })
      .catch((err) => { });
    if (window.sessionStorage.getItem('selectedIndex')) {
      setSelectedConfigureIndex(
        +window.sessionStorage.getItem('selectedIndex')
      );
    } else {
      setSelectedConfigureIndex(selectedTabIndex);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTabIndex]);

  useEffect(() => {
    retrieveGroupsList();
    const windowBodyClassList = document.body.classList;
    if (!windowBodyClassList.contains('campaign')) {
      windowBodyClassList.add('campaign');
    }
  }, [])
  

  useEffect(() => {
    if (disabledPlatforms === '') {
      selectedCheckBoxes.forEach((element) => {
        element.checked = true;
        element.isDisconnected = false;
      });
      setSelectedCheckBoxes([...selectedCheckBoxes]);
    } else if (
      userDetails &&
      userDetails.details &&
      userDetails.details.user &&
      userDetails.details.user.member_settings &&
      userDetails.details.user.member_settings.disabled_social_platforms
    ) {
      selectedCheckBoxes.forEach((element) => {
        if (
          userDetails.details.user.member_settings.disabled_social_platforms.includes(
            element.platform
          )
        ) {
          element.checked = false;
          element.isDisconnected = true;
        } else {
          element.checked = true;
          element.isDisconnected = false;
        }
      });

      setSelectedCheckBoxes([...selectedCheckBoxes]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userDetails]);

  const contactListChange = (contactGroup, searchTerm) => {
    contactsRequest({ params: { contactGroup, searchTerm } });
  };

  const onContactDelete = (
    emailList,
    shouldDeleteGroup,
    data,
    shouldDeleteMultipleContactGroup
  ) => {
    if (shouldDeleteGroup) {
      contactsDelete({
        params: {
          contactGroup: '',
          emailList,
          shouldDeleteGroup,
          shouldDeleteMultipleContactGroup,
          selectedGroup: '',
        },
      });
    } else {
      contactsDelete({
        params: {
          contactGroup: selectedContactGroup,
          emailList,
          shouldDeleteGroup: false,
          selectedGroup: selectedContactGroup,
          shouldDeleteMultipleContactGroup,
        },
      });
    }
  };

  const onContactCreate = (values, isUpdate, isModal) => {
    contactsCreate({
      params: {
        ...values,
        contactGroup: isUpdate
          ? values.name
          : contactError &&
            contactError.contactGroup &&
            contactError.name === values.name
            ? contactError.contactGroup
            : '',
        isModal: isModal,
        // contactGroup: isUpdate ? selectedContactGroup : '',
      },
    });
  };

  return (
    <ConfigureContainerStyle>      
      <EmailList
        contactError={contactError}
        contactsList={contactsList}
        contactGroups={contactGroups}
        contactGroupsList={contactGroupsList}
        contactListChange={contactListChange}
        onContactDelete={onContactDelete}
        contactsCreate={onContactCreate}
        setIsUpdateContact={setIsUpdateContact}
        isContactsFetching={isContactsFetching}
        selectedContactGroup={selectedContactGroup}
        socialAccountURL={socialAccountURL}
        socialNetworkData={socialNetworkData}
        userData={userData}
        userId={userId}
        loginRequest={loginRequest}
        retrieveGroupsList={retrieveGroupsList}
        contactsRequest={contactsRequest}
        paginationData={paginationData}
        navigateAfter={navigateAfter}
        socialNetworkStatusRequest={socialNetworkStatusRequest}
        selectedContactGroupDefault={selectedContactGroupDefault}
        socialNetworkDataFetching={socialNetworkDataFetching}
        socialNetworkDelete={socialNetworkDelete}
        setSocialAccountURL={setSocialAccountURL}
        isAdmin
        customContactGroupList={customContactGroupList}
        contactsCount={contactsCount}
        noRecords={noRecords}
        isEnterpriseLevelAdmin={isEnterpriseLevelAdmin}
      />
    </ConfigureContainerStyle>
  );
};

const mapStateToProps = (state) => {
  const disabledPlatforms = get(
    state.user,
    'data.details.user.member_settings.disabled_social_platforms'
  );
  const isEnterpriseLevelAdmin = get(state, 'user.data.details.company.enterprise', "false") === "true";
  const isFirmLevelAdmin = get(state, 'user.data.details.company.firm', "false") === "true";
  const firmSettings = getFirmSettings({ isEnterpriseLevelAdmin, isFirmLevelAdmin, state });
  return {
    contactError: state.contacts.error,
    contactsList: state.contacts.data,
    contactGroupsList: state.contacts.groupData,
    contactGroups: state.contacts.contactGroups,
    noRecords: state.contacts.noRecords,
    selectedContactGroup: state.contacts.selectedContactGroup,
    userData: getUserDetails(state),
    isContactsFetching: state.contacts.isFetching || false,
    socialNetworkData: state.socialNetwork.data,
    socialNetworkDataFetching: state.socialNetwork.isFetching,
    paginationData: state.contacts.pagination,
    navigateAfter: state.contacts.afterData,
    selectedContactGroupDefault: state.contacts.selectedContactGroupDefault,
    userDetails: state.user.data,
    selectedTabIndex: state.contacts.selectedTabIndex,
    disabledPlatforms: disabledPlatforms && JSON.parse(disabledPlatforms),
    customContactGroupList: state.contacts.customContactGroupList,
    contactsCount: state.contacts.totalCount,
    userId: state.user.data && state.user.data.uid,
    isEnterpriseLevelAdmin,
    isFirmLevelAdmin,
    firmSettings: firmSettings
  };
};

const mapDispatchToProps = {
  contactsRequest: contactsActions.api.contacts.request,
  contactsDelete: contactsActions.api.contacts.delete,
  contactsCreate: contactsActions.api.contacts.create,
  socialNetworkDelete: socialNetworkActions.api.socialNetwork.delete,
  socialNetworkStatusRequest: socialNetworkActions.api.socialNetwork.request,
  loginRequest: loginActions.api.login.request,
  setSelectedTabIndexRequest: contactsActions.api.contacts.selectedtabindex,
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminContacts);
