import styled from 'styled-components';
import { ButtonNoBorderBackground } from '../../../components';

export const EditorSearchInput = styled.input.attrs({
  className: 'editor-search-input'
})`
  width: 465px;
  background-color: #e4e4e4;
  outline: none;
  border: none;
  height: 35px;
  border-radius: 8px;
  padding: 5px 35px 5px 10px;
  font-family: Poppins;
  &::placeholder {
    font-family: Poppins;
  }
`;

export const CaretDownButton = styled(ButtonNoBorderBackground).attrs({
  className: 'caret-down-button'
})
`
  position: absolute;
  right: 10%;
  font-size: 14px;
  top: ${({ top }) => top || ''};
`;