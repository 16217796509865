import styled from 'styled-components';
import { colors } from '../../styles';

export const GraphDisplayContainer = styled('div')`
    display: table;
    width: 100%;
    color: ${colors.black_01};
    background: ${({ background }) => background ? background : '#F9FAFB'};
`;

export const GraphContainer = styled('div')`
    display: table;
    width: 95%;
`;

export const HeaderContainer = styled('div')`
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin: 20px auto 10px;
    padding: 0 0 20px;
    border-bottom: 1px solid ${colors.gray_background};
`;

export const CardTitle = styled('div')`
    font-weight: 500;
    font-size: 20px;
    padding: 0 20px;
    color: ${colors.black_01};
    margin-top: 5px;
`;

export const LegendContainer = styled('div')`
    margin: 10px 0 10px 40px;
    display: flex;
    justify-content: start;
`;


export const LegendKey = styled('div')`
    height: ${({ height }) => height ? height : '15px'};
    width: ${({ width }) => width ? width : '15px'};
    margin: ${({ margin }) => margin ? margin : '1px 8px 0 0'};
    border-radius: 4px;
    background-color: ${({ background }) => background ? background : `${colors.black_01}`};
`;

export const LegendItem = styled('div')`
    font-weight: 400;
    font-size: 12px;
    color: ${colors.black};
`;

export const TooltipContainer = styled('div')`
    box-shadow:  0px 0px 100px rgba(63, 106, 194, 0.15);
    padding: 10px 20px;
    background: ${colors.white};
    border-radius: 4px;
`;

export const TooltipValue = styled('div')`
    font-weight: 500;
    font-size: 14px;
    text-align: left;
    color: ${({ color }) => color ? color : `${colors.black_01}`};
`;

export const TooltipName = styled('div')`
    font-weight: 500;
    font-size: 14px;
    color: ${colors.black};
    text-align: left;
    margin: 0 6px 0 0;
`;

export const TooltipTitle = styled('div')`
    font-weight: 500;
    font-size: 16px;
    color: ${colors.black};
    text-align: left;
    margin: 5px 0;
`;
