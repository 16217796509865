/* eslint-disable react-hooks/exhaustive-deps */
import { faChevronLeft, faMinus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Flex } from '@rebass/grid';
import { get, isEmpty, map, uniqBy } from 'lodash';
import React, { useState, useEffect } from 'react'
import { Button, ButtonNoBorderBackground, LoaderWrapper, TabList, Tabs } from '../../../components';
import { updateCampaign } from '../../../services/campaign';
import { getMediaUrl } from '../../../utils';
import CampaignFooterButtons from '../CampaignFooterButtons/CampaignFooterButtons';
import { DripFooterSection } from '../CreateCampaign/CreateCampaign.styles';
import CampaignBrowseTopicsTab from './CampaignBrowseTopicsTab';
import CampaignPopularTopicsTab from './CampaignPopularTopicsTab';
import CampaignRecommendTopicsTab from './CampaignRecommendTopicsTab';
import CampaignSearchTopicsTab from './CampaignSearchTopicsTab';
import { CampaignTab, CampaignTabPanel, CampaignThemesWrapper, CampaignThemeTiles, SelectedTopicsWrapper, SetTopicsHr, SetTopicsTabWrapper, SetTopicsWrapper } from './SetTopics.styles';

const SetTopics = ({
    selectedThemes,
    themes,
    setCreateFlow,
    setCreateTabs,
    campaignId,
    campaignCreateFlow,
    campaignSetState,
    trendingTopics,
    mostSubscribedTopics,
    setTopicsTabData,
    endCreateFlow,
    setObjectivesTabData
}) => {
    
    const [selectedIndex, setSelectedIndex] = useState(1);
    const [selectedTopics, setSelectedTopics] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        // console.log('SET TOPICS TAB DATA - - -- ', setTopicsTabData);
        if(campaignCreateFlow) {
            setSelectedTopics(get(setTopicsTabData, 'selectedTopics', []));
        }
    }, [])

    const handleTabChange = (index) => {
        setSelectedIndex(index);
    }

    const handleBackButtonClick = () => {
        setCreateTabs(0);
    }

    const handleNextClick = () => {
        setLoading(true);
        const topicIds = map(selectedTopics, 'id');
        if(!isEmpty(topicIds)) {
            updateCampaign({ id: campaignId, payload: {topics: JSON.stringify(topicIds)} }).then(res => {
                setLoading(false);
                if(res.result.success) {
                    campaignSetState({
                        setTopics: {
                            selectedTopics: selectedTopics
                        }
                    })
                    setCreateTabs(2);
                }  
            }).catch(err => setLoading(false));
            
        }
        
    }

    const handleAddTopics = (topic) => {
        if(!isEmpty(topic)) {
            console.log(topic);
            console.log(selectedTopics);
            setSelectedTopics(uniqBy([...selectedTopics, topic], 'id'));
        }
    }

    const handleRemoveTopics = (topic) => {
        if(!isEmpty(selectedTopics) && !isEmpty(topic)) {
            let removedTopics = selectedTopics.filter(s => s.id !== topic.id);
            setSelectedTopics([...removedTopics]);
        }
    }

    const handleSaveAndClose = () => {
        setLoading(true);
        const topicIds = map(selectedTopics, 'id');
        if(!isEmpty(topicIds)) {
            updateCampaign({ id: campaignId, payload: {topics: JSON.stringify(topicIds)} }).then(res => {
                setLoading(false);
                if(res.result.success) {
                    endCreateFlow();
                }
            }).catch(err => {
                setLoading(false);
            });
        }
    }
    
    return (
        <div style={{height: '83%'}}>
            <SetTopicsWrapper>
                <SelectedTopicsWrapper>
                    <Flex width="100%" fontSize="14px" color="#000000">
                        SELECTED TOPICS
                    </Flex>
                    <SetTopicsHr />
                    <CampaignThemesWrapper>
                        {!isEmpty(selectedTopics) && selectedTopics.map((item, index) => (
                            <CampaignThemeTiles>
                                <img className="tiles-image" height="30" src={getMediaUrl(item.media_urls)}  alt="" />
                                <span className="tiles-title">{item.name}</span>
                                <div className="camp-tile-cover" onClick={() => handleRemoveTopics(item)}>
                                    <FontAwesomeIcon icon={faMinus} />
                                    <span className="camp-tile-cover-title">{item.name}</span>
                                </div>
                            </CampaignThemeTiles>
                        ))}
                    </CampaignThemesWrapper>
                </SelectedTopicsWrapper>
                <SetTopicsTabWrapper>
                    <Tabs height="100%" onSelect={handleTabChange} forceRenderTabPanel>
                        <TabList align="flex-start">
                            <CampaignTab
                                color='#49484a'
                                padding='5px 20px 6px'
                                className={selectedIndex === 0 ? "active" : "inactive"}
                                width="20%"
                            >
                                RECOMMENDED TOPICS
                            </CampaignTab>
                            <CampaignTab 
                                color='#49484a' 
                                padding='5px 20px 6px'
                                className={selectedIndex === 1 ? "active" : "inactive"}
                                width="20%"
                            >
                                SEARCH TOPICS
                            </CampaignTab>
                            <CampaignTab 
                                color='#49484a' 
                                padding='5px 20px 6px'
                                className={selectedIndex === 2 ? "active" : "inactive"}
                                width="20%"
                            >
                                POPULAR TOPICS
                            </CampaignTab>
                            <CampaignTab 
                                color='#49484a' 
                                padding='5px 20px 6px'
                                className={selectedIndex === 3 ? "active" : "inactive"}
                                width="20%"
                            >
                                BROWSE TOPICS
                            </CampaignTab>
                            <CampaignTab 
                                color='#49484a' 
                                padding='5px 20px 3px'
                                className={selectedIndex === 4 ? "active" : "inactive"}
                                width="40%"
                                disabled
                            >
                                
                            </CampaignTab>
                        </TabList>
                        {selectedIndex === 0 && (
                            <CampaignTabPanel>
                                <CampaignRecommendTopicsTab
                                    setObjectivesTabData={setObjectivesTabData}
                                    onAddTopics={handleAddTopics}
                                />
                            </CampaignTabPanel>
                        )}
                        {selectedIndex === 1 && (
                            <CampaignTabPanel>
                                <CampaignSearchTopicsTab 
                                    themes={themes}
                                    onAddTopics={handleAddTopics}
                                />
                            </CampaignTabPanel>
                        )}
                        {selectedIndex === 2 && (
                            <CampaignTabPanel>
                                <CampaignPopularTopicsTab 
                                    themes={themes}
                                    trendingTopics={trendingTopics}
                                    mostSubscribedTopics={mostSubscribedTopics}
                                    onAddTopics={handleAddTopics}
                                />
                            </CampaignTabPanel>
                        )}
                        {selectedIndex === 3 && (
                            <CampaignTabPanel>
                                <CampaignBrowseTopicsTab 
                                    themes={themes}
                                    onAddTopics={handleAddTopics}
                                />
                            </CampaignTabPanel>
                        )}
                    </Tabs>
                </SetTopicsTabWrapper>
            </SetTopicsWrapper>
            <CampaignFooterButtons
                enableNext={!isEmpty(selectedTopics)} 
                nextHandler={handleNextClick} 
                backHandler={handleBackButtonClick}
                loading={loading}
                saveAndCloseHandler={handleSaveAndClose} 
            />
        </div>
    )
}

export default SetTopics;
