/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import {
  EditorState,
  convertToRaw,
  ContentState,
} from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import './RichTextEditor.styles.css';
import draftToHtml from 'draftjs-to-html';
import { getMediaUrl, oktaHelper } from '../../utils';
import { get } from 'lodash';
import { apiDetails } from '../../services/helpers';
import htmlToDraft from 'html-to-draftjs';
import { useEffect } from 'react';

function uploadImageCallBack(file) {
  console.log('check');
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    console.log('checking');
    xhr.open('POST', `${apiDetails.baseUrl.trim()}/user/email/image`);
    console.log(`${apiDetails.baseUrl.trim()}/user/email/image`);
    xhr.setRequestHeader(
      'Authorization',
      `Bearer ${window.sessionStorage.getItem('token') || oktaHelper.getAccessToken()}`
    );
    const data = new FormData();
    data.append('media', file);
    xhr.send(data);
    xhr.addEventListener('load', () => {
      const response = JSON.parse(xhr.responseText);
      console.log(response);
      const image = getMediaUrl(
        get(response.result.data, 'media_urls'),
        '',
        'original'
      );
      console.log(image);
      setTimeout(() => {
        resolve({
          data: {
            link: image,
          },
        });
      }, 1000);
    });
    xhr.addEventListener('error', () => {
      const error = JSON.parse(xhr.responseText);
      console.log(error);
      reject(error);
    });
  });
}

// function uploadImageCallBack(file) {
//   return new Promise(
//     (resolve, reject) => {
//       const xhr = new XMLHttpRequest();
//       xhr.open('POST', 'https://api.imgur.com/3/image');
//       xhr.setRequestHeader('Authorization', 'Client-ID ##clientid###');
//       const data = new FormData();
//       data.append('image', file);
//       xhr.send(data);
//       xhr.addEventListener('load', () => {
//         const response = JSON.parse(xhr.responseText);
//         console.log(response)
//         resolve(response);
//       });
//       xhr.addEventListener('error', () => {
//         const error = JSON.parse(xhr.responseText);
//         console.log(error)
//         reject(error);
//       });
//     }
//   );
// }

// function uploadImageCallBack(file) {
//   console.log(file);
//   return new Promise(
//     (resolve,reject) => {
//       const fileData = new File(file, 'Random Image');
//       uploadImageInRichTextEdior(fileData).then(res => {
//         if(res.result.success) {
//           const image = getMediaUrl((get(res.result.data,'media_urls')));
//           resolve(image);
//         }
//       }).catch(err => reject(err));
//     }
//   )
// }



const RichTextEditor = ({
  placeHolder,
  name,
  value,
  setValue,
  isAdmin,
  disabled,
  fromEditor,
  charLimit,
  setError,
  fromNewsletter,
  fromCustomizeEmail,
  fromClientSettings,
  setLength,
  styles,
  fromNewsletterTitle,
}) => {
  // console.log('[TYPE OF VALUE] - ', value);
  // console.log('PLACEHOLDER.......', placeHolder);
  const contentBlock = value ? htmlToDraft(value) : htmlToDraft('<p></p>');
  const contentState = ContentState.createFromBlockArray(
    contentBlock.contentBlocks
  );
  const [editorState, setEditorState] = useState(
    EditorState.createWithContent(contentState)
  );
  const optionsArray = [
    'inline',
    'fontSize',
    'fontFamily',
    'textAlign',
    'link',
    'colorPicker',
    'image',
  ];
  let fontSizeObj = {};
  let linkObj = {},
    imageObj;
  if (fromEditor) {
    fontSizeObj = {
      options: [10, 12, 14, 16, 18, 20],
    };
    linkObj = {
      defaultTargetOption: '_blank',
    };
    optionsArray.pop();
    // if (name === 'Company Header')
    //   optionsArray.splice(optionsArray.indexOf('textAlign'), 1);
  }

  if (fromNewsletter) {
    linkObj = {
      defaultTargetOption: '_blank',
    };
    imageObj = {
      alignmentEnabled: true,
    };
  } else if (fromCustomizeEmail) {
    if (name === 'message')
      optionsArray.splice(optionsArray.indexOf('image'), 1);
  } else {
    imageObj = {
      alignmentEnabled: false,
    };
  }

  useEffect(() => {
    if (fromCustomizeEmail) {
      const contentBlock = value ? htmlToDraft(value) : htmlToDraft('<p></p>');
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
      );
      setEditorState(EditorState.createWithContent(contentState));
    }
  }, [placeHolder, fromCustomizeEmail]);

  const onEditorStateChange = (editorState) => {
    const raw = convertToRaw(editorState.getCurrentContent());
    console.log(raw);
    let atomicIndex = raw.blocks.findIndex((r) => r.type === 'atomic');
    if (
      atomicIndex &&
      atomicIndex !== -1 &&
      raw.blocks[atomicIndex - 1].text.length === 0
    ) {
      raw.blocks.splice(atomicIndex - 1, 1);
    }
    // if(raw && raw.entityMap && !isEmpty(raw.entityMap)) {
    //   console.log('checking');
    //   Object.keys(raw.entityMap).forEach(ele => {
    //     if(raw.entityMap[ele].type === 'IMAGE') {
    //       let alignment = raw.entityMap[ele].data && raw.entityMap[ele].data.alignment === 'none' ? 'center' : !raw.entityMap[ele].data.alignment ? 'center' : raw.entityMap[ele].data.alignment;
    //       console.log(alignment);
    //       raw.entityMap[ele].data.alignment = alignment;
    //     }
    //   })
    // }
    if (raw.blocks) {
      raw.blocks.forEach((element) => {
        if (element.type === 'unstyled' && element.text.length >= 0) {
          let style = {};
          style['margin-block'] = '0em';
          if (element.text.length === 0) {
            style['height'] = '0px';
            style['padding'] = '7px 0px';
          }

          style['color'] = '#000000';
          if (fromEditor) {
            style['font-size'] = '10px';
            if (element.inlineStyleRanges.length !== 0) {
              let fontSet = !element.inlineStyleRanges.every(
                (inlineStyle) => !inlineStyle.style.includes('fontsize')
              );
              if (fontSet) delete style['font-size'];
            }
          }

          element.data = { ...element.data, ...style };
        }
      });
    }
    if (charLimit) {
      const length = editorState.getCurrentContent().getPlainText('').length;
      setLength(length);
      if (length > charLimit) setError('Max character limit reached');
      else setError('');

      let isEmpty = raw.blocks.every((block) => block.text.trim().length === 0);
      if (raw.entityMap && raw.entityMap[0] && raw.entityMap[0].data)
        isEmpty = false;

      if (isEmpty) setValue('');
      else setValue(draftToHtml(raw));
    } else
      setValue(
        draftToHtml(raw, {}, false, (entity, text) => {
          if (entity.type === 'IMAGE') {
            var textAlign = 'none';
            if (entity.data.alignment) {
              //entity.data.alignment is for float using the LCR options on the image
              //'none' means the user clicked center
              if (entity.data.alignment === 'none') {
                textAlign = 'center';
              } else {
                textAlign = entity.data.alignment;
              }
            } else {
              textAlign = 'center';
            }
            return (
              '<p style="text-align:' +
              textAlign +
              ';"><img src="' +
              entity.data.src +
              '" alt="' +
              entity.data.alt +
              '" style="height: ' +
              entity.data.height +
              ';width: ' +
              entity.data.width +
              '"/></p>'
            );
          }
        })
      );

    setEditorState(editorState);
  };

  return (
    <div
      className={
        fromCustomizeEmail
          ? 'editor customizeEmailEditor'
          : fromEditor || fromClientSettings
          ? 'editor settingsEditor'
          : fromNewsletter
          ? 'editor newsLetterEditor'
          : 'editor'
      }
      style={
        name === 'Company Header' || name === 'Disclosure header'
          ? { color: 'black' }
          :  fromCustomizeEmail
          ? { height: '165px' }
          : styles
      }
    >
      <Editor
        placeholder={placeHolder}
        name={name}
        editorState={editorState}
        onEditorStateChange={onEditorStateChange}
        stripPastedStyles={true}
        readOnly={disabled}
        wrapperStyle={{
          height: fromNewsletter ? '200px' : fromNewsletterTitle ? '80px' : '',
        }}
        editorStyle={{
          height: fromNewsletter ? '169px' : fromNewsletterTitle ? '50px' : '',
          maxHeight: fromNewsletter
            ? '169px'
            : fromNewsletterTitle
            ? '50px'
            : '',
        }}
        fontFamily={{
          options: [
            'Arial',
            'Georgia',
            'Impact',
            'Tahoma',
            'Times New Roman',
            'Verdana',
          ],
        }}
        toolbar={{
          options: optionsArray,
          inline: {
            inDropdown: false,
            options: ['bold', 'italic', 'underline'],
          },
          fontSize: { ...fontSizeObj },
          list: { inDropdown: true },
          textAlign: { inDropdown: true },
          link: { inDropdown: true, ...linkObj },
          image: {
            uploadCallback: uploadImageCallBack,
            inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg',
            urlEnabled: false,
            defaultSize: 'auto',
            previewImage: true,
            ...imageObj,
          },
          colorPicker: {
            icon: isAdmin
              ? '/assets/images/underline.png'
              : 'assets/images/underline.png',
            className: 'colorPicker',
            component: undefined,
            popupClassName: undefined,
            colors: [
              'rgb(97,189,109)',
              'rgb(26,188,156)',
              'rgb(84,172,210)',
              'rgb(44,130,201)',
              'rgb(147,101,184)',
              'rgb(71,85,119)',
              'rgb(204,204,204)',
              'rgb(65,168,95)',
              'rgb(0,168,133)',
              'rgb(61,142,185)',
              'rgb(41,105,176)',
              'rgb(85,57,130)',
              'rgb(40,50,78)',
              'rgb(0,0,0)',
              'rgb(247,218,100)',
              'rgb(251,160,38)',
              'rgb(235,107,86)',
              'rgb(226,80,65)',
              'rgb(163,143,132)',
              'rgb(239,239,239)',
              'rgb(255,255,255)',
              'rgb(250,197,28)',
              'rgb(243,121,52)',
              'rgb(209,72,65)',
              'rgb(184,49,47)',
              'rgb(124,112,107)',
              'rgb(209,213,216)',
              'rgb(97,189,109)',
              'rgb(26,188,156)',
              'rgb(84,172,210)',
              'rgb(44,130,201)',
            ],
          },
        }}
      />
    </div>
  );
};

export default RichTextEditor;
