import styled from 'styled-components';
import { TableHeading, Th, Tr, Td } from '..';
import { ButtonNoBorderBackground } from '.';

export const EmailListTableHeading = styled(TableHeading)`
  background-color: ${({ color }) => color || '#d5d5d5'};
  border: 0px;
`;

export const EmailListTh = styled(Th)`
  padding: ${({ padding }) => padding || '8px 9px'};
  background-color: ${({ bgColor }) => bgColor || ''};
  color: ${({ color }) => color || '#7b7b7b'};
  line-height: 1.42857143;
  font-weight: bold;
  position: relative;

  /* The container */
  .container {
    display: block;
    position: relative;
    padding-left: 20px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  /* Hide the browser's default checkbox */
  .container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: 2px;
    left: 6px;
    height: 14px;
    width: 14px;
    background-color: #eee;
    border: 1px solid #c9c9c9;
    border-radius: 4px;
  }

  .container input ~ .checkmark {
    background-color: #fff;
  }
  /* On mouse-over, add a grey background color */
  .container:hover input ~ .checkmark {
    background-color: #fff;
  }

  /* When the checkbox is checked, add a blue background */
  .container input:checked ~ .checkmark {
    background-color: #c95cfc;
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: '';
    position: absolute;
    display: none;
  }

  /* Show the checkmark when checked */
  .container input:checked ~ .checkmark:after {
    display: block;
  }

  /* Style the checkmark/indicator */
  .container .checkmark:after {
    left: 3px;
    top: -1px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`;

export const EmailListTableBodyTr = styled(Tr)`
  border-bottom: 1px solid #e5e5e5;
  background-color: ${({ bgColor }) => bgColor || ''};
`;

export const EmailListTableBodyTd = styled(Td)`
  word-wrap: break-word;
  background-color: white;
  padding: ${({ padding }) => padding || '8px 10px'};
  color: #7b7b7b;
  /* The container */
  .container {
    display: block;
    position: relative;
    padding-left: 20px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  /* Hide the browser's default checkbox */
  .container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: 2px;
    left: 6px;
    height: 13px;
    width: 13px;
    background-color: #eee;
    border: 1px solid #c9c9c9;
    border-radius: 4px;
  }

  .container input ~ .checkmark {
    background-color: #fff;
  }
  /* On mouse-over, add a grey background color */
  .container:hover input ~ .checkmark {
    background-color: #fff;
  }

  /* When the checkbox is checked, add a blue background */
  .container input:checked ~ .checkmark {
    background-color: #c95cfc;
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: '';
    position: absolute;
    display: none;
  }

  /* Show the checkmark when checked */
  .container input:checked ~ .checkmark:after {
    display: block;
  }

  /* Style the checkmark/indicator */
  .container .checkmark:after {
    left: 3px;
    top: 0px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`;

export const DeleteEmailButtons = styled(ButtonNoBorderBackground)`
  position: absolute;
  top: 10px;
  color: #6351ed;
`;

export const TableHeaderButtons = styled(ButtonNoBorderBackground)`
  color: #6351ed;
`;

export const MioRadioSpan = styled.span`
  border-radius: ${({ noBorder }) => (noBorder ? '' : '7px')};
  position: relative;
  font-size: ${({ fontSize }) => fontSize || '12px'};
  border: ${({ noBorder }) => (noBorder ? '' : '1px solid #959595')};
  height: ${({ height }) => height || '22px'};
  width: ${({ width }) => width || '60px'};
  display: inline-block;
  padding-left: 0px;
  padding-right: ${({ pr }) => pr || '5px'}; 
  margin-right: ${({ mr }) => mr || '5px'};
  display: flex;
  align-items: center;
  justify-content: ${({ justifyContent }) => justifyContent || 'center'};
  cursor: pointer;
  ${({ checked }) => checked && 'border: 1px solid #6351ed;'}
  label {
    cursor: inherit;
    padding-left: ${({ labelpl }) => labelpl || ''};
  }
`;

export const MioRadio = styled.input.attrs({ type: 'radio' })`
  -webkit-appearance: none;
  min-width: 13px;
  height: 13px;
  border-radius: 50%;
  outline: none;
  border: 1px solid #959595;
  cursor: pointer;
  margin: 0px;

  margin-right:  ${({ marginRight }) => marginRight || '5px'};
  &:before {
    content: '';
    display: block;
    width: 64%;
    height: 64%;
    margin: ${({ margin }) => margin || '20% auto 20%'};
    border-radius: 50%;
  }
  &:checked {
    border-color: #6351ed;
    &:before {
      background: #6351ed;
    }
  }
  + label {
    cursor: pointer;
  }
`;
