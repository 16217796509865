import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Flex } from '@rebass/grid'
import { isEmpty } from 'lodash';
import React, { useState } from 'react'
import { getMediaUrl, onImageError } from '../../../utils';
import { AlphaTiles, CampaignBrowserWrapper, CampaignThemeTiles } from './SetTopics.styles'

const CampaignBrowseTopicsTab = ({
    themes,
    onAddTopics
}) => {
    const [selectedAlpha, setSelectedAlpha] = useState('');

    const alpha = Array.from(Array(26)).map((e, i) => i + 65);
    const alphabet = alpha.map((x) => String.fromCharCode(x));

    return (
        <CampaignBrowserWrapper>
            <Flex width="100%">
                {alphabet && alphabet.map((item, index) => (
                   <AlphaTiles className={selectedAlpha === item ? 'active' : ''} onClick={() => setSelectedAlpha(item)}>
                       {item} 
                   </AlphaTiles> 
                ))}
            </Flex>
            <Flex width="100%" padding="15px 5px 0px" fontSize="14px" color="#000000">
                Available Topics
            </Flex>
            <Flex width="100%" height="80px" flexWrap="wrap">
                {!isEmpty(themes) && !isEmpty(selectedAlpha) && themes.filter(t => t.name.includes(selectedAlpha)).slice(0, 12).map((item, index) => (
                    <CampaignThemeTiles>
                        <img className="tiles-image" height="25" src={getMediaUrl(item.media_urls)} alt="" />
                        <span className="tiles-title">{item.name}</span>
                        <div className="camp-tile-cover" onClick={() => onAddTopics(item)}>
                            <FontAwesomeIcon icon={faPlus} style={{position: 'relative', left: '-2px'}}/>
                            <span className="camp-tile-cover-title">{item.name}</span>
                        </div>
                    </CampaignThemeTiles>
                ))}
            </Flex>
        </CampaignBrowserWrapper>
    )
}

export default CampaignBrowseTopicsTab
