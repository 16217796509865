import apiInstance from '../api';
import { objectToFormData } from '../helpers';

export const getBucketArticles = () => {
  return apiInstance().get(`/newsletter/bucket/`);
}

export const deleteBucketArticle = ({ id }) => {
  return apiInstance().delete(`/newsletter/bucket/${id}`);
}

export const createNewsletter = ({ name }) => {
  const payload = { name };
  return apiInstance().post(`/newsletter/`, objectToFormData(payload));
}

export const updateNewsletter = ({ id, params, media1 }) => {
  let payload = objectToFormData(params);
  if(media1) {
    payload.append('media1', media1);
  }
  return apiInstance().post(`/newsletter/${id}`, payload);
}

export const shareNewsletter = ({ id, params }) => {
  return apiInstance().post(`/newsletter/${id}/share`, objectToFormData(params));
}

export const getNewsletterOutboxData = ({ filter, beforeToken, afterToken }) => {
  let url;
  if(beforeToken) {
    url = `newsletter/?filter={"status":"${filter}", "limit": 900}&before=${beforeToken}`;
  } else if(afterToken) {
    url = `newsletter/?filter={"status":"${filter}", "limit": 900}&after=${afterToken}`;
  } else {
    url = `newsletter/?filter={"status":"${filter}", "limit": 900}`;
  }

  return apiInstance().get(url);
}

export const getNewsletterDetails = ({ id }) => {
  return apiInstance().get(`newsletter/${id}`);
}

export const deleteNewsletterArticle = ({ id }) => {
  return apiInstance().delete(`newsletter/${id}`);
}

export const getBucketArticlesCount = () => {
  return apiInstance().get(`newsletter/bucket/count`);
}

export const cloneNewsletter = cloneObject => {
  // console.log('CLONED NEWSLETTER = = = = ', cloneObject);
  return apiInstance().post(`newsletter/clone/`, objectToFormData(cloneObject));
}