/* eslint-disable react-hooks/exhaustive-deps */
import { get, isEmpty } from 'lodash';
import React, { useState, useEffect } from 'react';
import {
  getContactList,
  getPopularTheme,
  getTrendingTopics,
} from '../../services/dashboard';
import CreateCampaign from './CreateCampaign/CreateCampaign';
import DripCampaignDashboard from './DripCampaignDashboard/DripCampaignDashboard';
import { connect } from 'react-redux';
import { actions as campaignActions } from '../../redux/modules/campaign.module';
import { actions as campaignDetailsActions } from '../../redux/modules/campaignDetails.module';
import { getChannelId, getRoleType, getFirmSettings, getUserRole } from '../../redux/selector';
import { ROLE_TYPES, getMediaImage, USER_ROLE } from '../../utils';
import {
  getCampaignDetails, getSeriesDetails
} from '../../services/campaign/index';
import useQuery from '../../customHooks/useQuery';
import { useHistory } from 'react-router-dom';
import ReviewCampaigns from './ReviewCampaign/ReviewCampaigns';
import { getSegments } from '../../services/segment';

const DripCampaignContainerV2 = ({
  socialNetworkData,
  selectedThemes,
  themes,
  themeNames,
  campaignSetState,
  campaignCreateFlow,
  setObjectivesTabData,
  campaignRequest,
  campaignsData,
  seriesSubcampaigns,
  campaignScheduleTimings,
  campaignSelectedArticles,
  campaignSelectedWeek,
  campaignSelectedDays,
  automatedDashboard,
  campaignArticles,
  campaignFetching,
  campaignId,
  channelId,
  setTopicsTabData,
  isPremiumAdvisor,
  contentWeeks,
  planCampaignData,
  publishedContent,
  campaignDetailsResponse,
  userData,
  setTemplateData,
  setTemplateBase,
  savedTemplate,
  defaultTemplates,
  editMode,
  isEdit,
  isFromAddCampaign,
  isadvMode,
  isAdvisorApprovalMode,
  campaignStatus,
  socialSelectedCampId,
  isEnterpriseAdvisor,
  fromCreateEmail,
  curFlow,
  isEnterpriseLevelAdmin,
  isFirmLevelAdmin,
  firmSettings,
  firmsArray,
  memberAdvisorsArray,
  isAdmin,
  companyId
}) => {
  const history = useHistory();
  const query = useQuery();
  let params = query.get('type');
  let cmode = query.get('mode');
  const [createFlow, setCreateFlow] = useState(
    params === 'create' ? true : false
  );
  const [createTabs, setCreateTabs] = useState(0);
  const [contactGroups, setContactGroups] = useState([]);
  const [trendingTopics, setTrendingTopics] = useState([]);
  const [mostSubscribedTopics, setMostSubscribedTopics] = useState([]);
  const [createScreenIndex, setCreateScreenIndex] = useState(0);
  const [editLoader, toggleEditLoader] = useState(false);
  const [category, setCategory] = useState('');
  const [suggestedArticlesLoader, toggleSuggestedArticlesLoader] =
    useState(false);
  const [segmentList, setSegmentList] = useState([]);
  const [contactsLoading, setContactsLoading] = useState(false);
  const [segmentsLoading, setSegmentsLoading] = useState(false);

  useEffect(() => {
    setCreateFlow(params === 'create' ? true : false);
    if (params !== 'create') changeCreateFlow();
    // else
    //   changeCreateFlow();
  }, [params]);

  useEffect(() => {
    if (isEmpty(trendingTopics) && (window.sessionStorage.getItem('redirect_type') === 'nurture' || (window.sessionStorage.getItem('redirect_type') !== 'nurture' && process.env.REACT_APP_API_ENV !== 'stage'))) {
      getTrendingTopics()
        .then((response) => {
          if (response?.result?.data?.topics) {
            let trendingTopics = get(response, 'result.data.topics', []);
            if (trendingTopics.length > 0 && !isEmpty(themes))
              trendingTopics = trendingTopics.map((topic) =>
                themes.find((ele) => ele.id === topic.id)
              );
            setTrendingTopics(trendingTopics);
          }
        })
        .catch((err) => console.log(err));
    }
  }, []);

  useEffect(() => {
    if (isEmpty(mostSubscribedTopics) && (window.sessionStorage.getItem('redirect_type') === 'nurture' || (window.sessionStorage.getItem('redirect_type') !== 'nurture' && process.env.REACT_APP_API_ENV !== 'stage'))) {
      getPopularTheme()
        .then((res) => {
          setMostSubscribedTopics(get(res, 'result.data', []));
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, []);

  useEffect(() => {
    if(window.sessionStorage.getItem('redirect_type') !== 'nurture' || (window.sessionStorage.getItem('redirect_type') === 'nurture' && process.env.REACT_APP_API_ENV !== 'stage')) {
      fetchSegments();
    }
  }, [])

  const fetchSegments = () => {
    setSegmentsLoading(true);
    getSegments().then(res => {
      if(res.result.success) {
        setSegmentList(res.result.data);
        
      }
      setSegmentsLoading(false);
    }).catch(err => {
      console.log(err);
      setSegmentsLoading(false);
    });
  }

  useEffect(() => {
    if (window.sessionStorage.getItem('redirect_type') !== 'nurture') {
      setContactsLoading(true);
      getContactList()
        .then((res) => {
          const data = get(res, 'result.data', []).filter(
            (c) =>
              ![
                'GMAIL',
                'HUBSPOT',
                'OUTLOOK',
                'WEALTHBOX',
                'SALESFORCE',
                'REDTAIL',
              ].includes(get(c, 'name')) && get(c, 'contacts.count', 0) > 0
          );
          setContactGroups(data);
          setContactsLoading(false);
        })
        .catch((err) => {
          setContactGroups([]);
          setContactsLoading(false);
        });
    }

    const windowBodyClassList = document.body.classList;
    if (!windowBodyClassList.contains('campaign')) {
      windowBodyClassList.add('campaign');
    }

    if(window.sessionStorage.getItem('redirect_type') !== 'nurture') {
      if(!automatedDashboard) {
        campaignRequest();
      }
      else{
          setCategory('automated');
          campaignRequest({
            type : ['automated'],
            limit: 10,
            offset: 1 
          });
          campaignSetState({ automatedDashboard: false })
      }
    }
    
    return () => {
      windowBodyClassList.remove('campaign');
    };
  }, []);

  const getAccountId = (platform, pageId) => {
    let selectedAcountId = '';

    if (!selectedAcountId) {
      if (platform === 'em') {
        if (
          !isEmpty(get(socialNetworkData, `${platform}`, [])) &&
          get(socialNetworkData, `${platform}`, []).length > 1 &&
          get(socialNetworkData, `${platform}`, []).filter(
            (s) => s.snetworkProvider === 'sg'
          ).length > 0
        ) {
          selectedAcountId = get(socialNetworkData, `${platform}`, []).filter(
            (s) => s.snetworkProvider === 'sg'
          )[0].accountId;
        } else {
          selectedAcountId = get(socialNetworkData, `${platform}[0].accountId`);
        }
      } else {
        selectedAcountId = get(socialNetworkData, `${platform}[0].accountId`);
      }
    }
    return selectedAcountId;
  };

  const detailsHandler = (campaignId, type) => {
    toggleEditLoader(true);
    let resObj = {};
    getCampaignDetails(campaignId)
      .then((res) => {
        resObj = get(res, 'result.data', {});
        const campaignName = get(resObj, 'name', 'Untitled');
        const campaignPurpose = get(resObj, 'purpose', '');
        const emList = get(resObj, 'email', []).map((page) => ({
          ...contactGroups.find(
            (contact) => contact.id === page.destination_id
          ),
        }));
        const selectedSegmentList = !isEmpty(get(resObj, 'segment', [])) && get(resObj, 'segment', []).map((segment) => ({
          ...segmentList.find(
            (contact) => contact.id === segment.destination_id
          ),
        }));
        const fbPages = get(resObj, 'facebook', []).map(
          (page) => page.destination_id
        );
        const twPages = resObj && resObj.twitter.length > 0 ? [0] : [];

        const lnPages = get(resObj, 'linkedin', []).map(
          (page) => page.destination_id
        );
        const emailSubject = get(
          resObj,
          'newsletter.customized_data.subject',
          ''
        );

        const leadGenReferralData = {
          em: {
            leadGen: get(resObj, 'posts.email.lead_gen', 'false') === 'true',
            referral: get(resObj, 'posts.email.referral', 'false') === 'true',
          },
          fb: {
            leadGen: get(resObj, 'posts.facebook.lead_gen', 'false') === 'true',
            referral: false,
          },
          tw: {
            leadGen: get(resObj, 'posts.twitter.lead_gen', 'false') === 'true',
            referral: false,
          },
          ln: {
            leadGen: get(resObj, 'posts.linkedin.lead_gen', 'false') === 'true',
            referral: false,
          },
          segment: {
            leadGen: get(resObj, 'posts.segment.lead_gen', 'false') === 'true',
            referral: false,
          }
        };

        
      

        const campaignId = get(resObj, 'campaign_id', '');
        const seriesId = get(resObj, 'series_id', null);
        const selectedTemplate = get(resObj, 'template.default_template', '');
        const selectedTemplateBase = get(
          resObj,
          'template.original_template',
          ''
        );
        const isCascadeTemplate = get(resObj, 'template.is_cascade', false);
        const templateJson = get(resObj, 'template.template_json', null);
        const ccListArray = get(resObj, 'newsletter.cc_email_ids', []);
        const subscriptionType = get(resObj, 'subscription_type', '');
        let fetchedArticles = [];
        if (get(resObj, 'posts_details.fb', false))
          fetchedArticles.push(
            get(resObj, 'posts_details.fb[0].articles.feed[0]', {})
          );
        else if (get(resObj, 'posts_details.tw', false))
          fetchedArticles.push(
            get(resObj, 'posts_details.tw[0].articles.feed[0]', {})
          );
        else if (get(resObj, 'posts_details.ln', false))
          fetchedArticles.push(
            get(resObj, 'posts_details.ln[0].articles.feed[0]', {})
          );
        else fetchedArticles = get(resObj, 'newsletter.articles.feed', []);

        let socialArticleId = '';

        if (get(resObj, 'posts.facebook.articles[0].id', ''))
          socialArticleId = get(resObj, 'posts.facebook.articles[0].id', '');
        else if (get(resObj, 'posts.twitter.articles[0].id', false))
          socialArticleId = get(resObj, 'posts.twitter.articles[0].id', '');
        else if (get(resObj, 'posts.linkedin.articles[0].id', false))
          socialArticleId = get(resObj, 'posts.linkedin.articles[0].id', '');

        const selectedArticles = fetchedArticles.map((article) => ({
          id: article.id.includes('LP__') ? article.id.split('__')[1] : article.id,
          url: getMediaImage(get(article, 'media_urls[0]', [])),
          themeId: get(article, 'ids.theme[0]', ''),
          description: get(article, 'text', ''),
          topic: get(article, 'auxiliary.title', ''),
          place: themeNames[get(article, 'ids.theme[0]', '')],
          link: get(article, 'links.social_permalink', ''),
          year: '',
          date: '',
          type: article.id.includes('LP__') ? 'lp' : ''
        }));

        const complianceApproved = get(resObj, 'compliance_approved', false);

        // const approvedToShare = get(resObj, "approved_to_share", "false");
        const is_email = get(resObj, "is_email", "false");
        const is_facebook = get(resObj, "is_facebook", "false");
        const is_linkedin = get(resObj, "is_linkedin", "false");
        const is_twitter = get(resObj, "is_twitter", "false");

        let curflowMode = "emailAndSocial";

        const newStateObject = {
          createMode: 'oneTime',
          createFlow: true,
          // curFlow: emList.length === 0 ? 'socialOnly' : 'emailOnly',
          curFlow: curflowMode,
          selectedIndex: 0,
          campaignId: campaignId,
          seriesId: seriesId,
          fromDashboard: false,
          isEdit: true,
          subscriptionType: subscriptionType,
          setObjectives: {
            name: campaignName,
            campaignType: type,
            purpose: campaignPurpose,
            subject: emailSubject,
            ccList: ccListArray,
            emList: emList,
            fbList: fbPages,
            lnList: lnPages,
            twList: twPages,
            is_email: is_email,
            is_facebook: is_facebook,
            is_linkedin: is_linkedin,
            is_twitter: is_twitter,
            segmentList: !selectedSegmentList ? [] : selectedSegmentList,
            // selectedSegmentList: selectedSegmentList,
            // segmentList: segmentList,
            approvedToShare: get(resObj, 'approved_to_share', 'false'),
            selectedAdvisors: get(resObj, 'cascaded_advisors', []),
            status: get(resObj, 'status', ''),
            webinar: {
                wuid : resObj?.webinar_id,
                url: get(resObj, 'webinar_custom_image', '')
            },
            isCascaded: get(resObj, 'is_cascaded', 'false'),
          },
          selectedArticles: selectedArticles,
          summaries: {
            fb: get(resObj, 'posts.facebook.override_text', ''),
            tw: get(resObj, 'posts.twitter.override_text', ''),
            ln: get(resObj, 'posts.linkedin.override_text', ''),
          },
          scheduleTimings: {
            fb: get(resObj, 'posts.facebook.schedule_time[0].time', ''),
            tw: get(resObj, 'posts.twitter.schedule_time[0].time', ''),
            ln: get(resObj, 'posts.linkedin.schedule_time[0].time', ''),
            em: get(resObj, 'posts.email.schedule_time', []),
            segment: get(resObj, 'posts.segment.schedule_time', [])
          },
          scheduleTypes: {
            fb: get(resObj, 'posts.facebook.schedule', 'immediate'),
            tw: get(resObj, 'posts.twitter.schedule', 'immediate'),
            ln: get(resObj, 'posts.linkedin.schedule', 'immediate'),
            em: get(resObj, 'posts.email.schedule', 'immediate'),
            segment: get(resObj, 'posts.segment.schedule', 'immediate')
          },
          leadGenReferralData: leadGenReferralData,
          setTemplate: selectedTemplate,
          setBaseTemplate: selectedTemplateBase,
          savedTemplate: templateJson,
          campaignStatus: get(resObj, 'status', ''),
          setIsCascade: isCascadeTemplate,
          checkbox_status: get(resObj, 'checkbox_status', null),
          socialSelectedCampId: socialArticleId,
          complianceApplicable:
            get(resObj, 'compliance_applicable', '') === 'true' ? true : false,
          complianceApproved: complianceApproved,
          emAdvApproval: get(resObj, 'feedback.email.action', false)
            ? true
            : false,
          socialAdvApproval: get(resObj, 'feedback.social.action', false)
            ? true
            : false,
          is_global_compliance: get(resObj, 'is_global_compliance', 'false')

        };
        campaignSetState(newStateObject);
        setCreateFlow(true);
        toggleEditLoader(false);
        history.push('/campaignV2?type=create');
      })
      .catch((err) => {
        console.log('ERR - -- - - ', err);
        campaignDetailsResponse(resObj);
        toggleEditLoader(false);
      });
  };

  const editSeriesLevel = (id) => {
    toggleEditLoader(true);
    let resObj = {};
    getSeriesDetails(id).then(res => {
      resObj = get(res, 'result.data', {});
      const emList = !isEmpty(get(resObj, 'email', [])) ? get(resObj, 'email', []).map((page) => ({
        ...contactGroups.find(
          (contact) => contact.id === page.destination_id
        ),
      })) : [];
      const subscriptionType = get(resObj, 'subscription_type', '');
      const selectedSegmentList = !isEmpty(get(resObj, 'segment', [])) && get(resObj, 'segment', []).map((segment) => ({
        ...segmentList.find(
          (contact) => contact.id === segment.destination_id
        ),
      }));
      const fbPages = !isEmpty(get(resObj, 'facebook', [])) ? get(resObj, 'facebook', []).map(
        (page) => page.destination_id
      ) : [];
      const twPages = resObj && resObj?.twitter?.length > 0 ? [0] : [];

      const lnPages = !isEmpty(get(resObj, 'linkedin', [])) ? get(resObj, 'linkedin', []).map(
        (page) => page.destination_id
      ) : [];

      const autoPostToggle = get(resObj, 'is_auto_post', 'false');

        const advisorData = !isEmpty(get(resObj, 'autoPost.advisor', [])) ? get(resObj, 'autoPost.advisor', []).map((item) => {
          return {
            id: item
          }
        }) : [];

        const adminData = !isEmpty(get(resObj, 'autoPost.admin', [])) ? 
        get(resObj, 'autoPost.admin', []).map((item) => {
          return {
            id: item
          }
        }) : [];

        const firmData = !isEmpty(get(resObj, 'autoPost.firm', [])) ? get(resObj, 'autoPost.firm', []).map((item) => {
          return {
            id: item
          }
        }) : [];

        const autoPost = {
          advisorData,
          adminData,
          firmData
        };

      console.log("auto pist toiggle ---->", autoPostToggle, autoPost);
      let curflowMode = "emailAndSocial";
      const seriesId = get(resObj, 'id', null);
      const is_email = get(resObj, "platforms.is_email", "false");
      const is_facebook = get(resObj, "platforms.is_facebook", "false");
      const is_linkedin = get(resObj, "platforms.is_linkedin", "false");
      const is_twitter = get(resObj, "platforms.is_twitter", "false");

      campaignSetState({
        createMode: 'series',
        createFlow: true,
        activeIndex: 0,
        seriesId: seriesId,
        isEdit: true,
        curFlow: curflowMode,
        advisorApprovalRequired: get(resObj, 'advisor_approval', false) === true ? true : false,
        subscriptionType,
        setObjectives: {
          seriesName: get(resObj, 'name', null),
          seriesPurpose: get(resObj, 'purpose', null),
          is_email: is_email,
          is_facebook: is_facebook,
          is_linkedin: is_linkedin,
          is_twitter: is_twitter,
          emList: emList,
          fbList: fbPages,
          lnList: lnPages,
          twList: twPages,
          autoPost,
          autoPostToggle,
          cascaded: get(resObj, 'cascaded', false),
          platforms: get(resObj, 'platforms', {}),
          segmentList: !selectedSegmentList ? [] : selectedSegmentList,
          skip_compliance: get(resObj, "skip_compliance", false) === null ? true : get(resObj, "skip_compliance", true)
        }
      });
      setCreateFlow(true);
      toggleEditLoader(false);
      history.push('/campaignV2?type=create');
    }).catch(err => {
      toggleEditLoader(false);
      console.log('ERR - -- - - ', err);
    })
  }

  const addCampaignFlow = (seriesId) => {
    campaignSetState({
      createMode: 'oneTime',
      createFlow: true,
      series: {
        id: seriesId,
      },
      setObjectives: {
        campaignType: 'series'
      },
      isFromAddCampaign: true,
    });

    history.push('/campaignV2?type=create');
  };

  const changeCreateFlow = (create) => {
    setCreateFlow(create ? true : false);
    setCreateScreenIndex(0);
    setCreateTabs(0);
    campaignSetState({
      campaignId: '',
      createFlow: create ? true : false,
      createMode: false,
      curFlow: fromCreateEmail ? curFlow : '',
      selectedIndex: 0,
      setObjectives: {},
      setTopics: {},
      contentWeeks: [],
      planCampaign: {},
      campaignArticles: [],
      scheduleTimings: {},
      selectedWeek: '',
      selectedDays: '',
      selectedArticles: [],
      publishedContent: {},
      defaultTemplates: [],
      setTemplate: '',
      savedTemplate: null,
      scheduleTypes: {},
      isEdit: false,
      seriesId: '',
      isFromAddCampaign: false,
      leadGenReferralData: null,
      type: '',
      subCampaigns: [],
      campaignStatus: '',
      summaries: {},
      checkbox_status: null,
      socialSelectedCampId: '',
      complianceApplicable: '',
      complianceApproved: false,
      emAdvApproval: false,
      socialAdvApproval: false,
      recommendedFetchedData: {},
      reviewCampaign: {},
      automatedCampaigns: {},
      selectedBlock: {},
      selectedBlockId: '',
      // forAutomatedCampaigns: false,
      automatedFlowFromDashboard: false,
      landingPages: [],
      segmentList: [],
      subscriptionType: '',
      is_global_compliance: '',
      reviewType: '',
      activeIndex: 0,
      selectedTimeBlocks: [],
      acBack: false
    });
    sessionStorage.removeItem('saveAsDraft');
    if (!create) {
      if (!automatedDashboard) {
        campaignRequest();
        setCategory('')
      }
      else {
        setCategory('automated');
        campaignRequest({ 
          type: ['automated'],
          limit: 10,
          offset: 1
        });
      }
    }
  };

  return (
    <div style={{ minHeight: '500px', width: '100%' }}>
      {!createFlow && params !== 'review' && (
        <DripCampaignDashboard
          createFlow={createFlow}
          setCreateFlow={setCreateFlow}
          campaignSetState={campaignSetState}
          campaignFetching={campaignFetching}
          campaignsData={campaignsData}
          socialThemes={themes}
          editCampaignHandler={() => setCreateFlow(true)}
          editLoader={editLoader}
          campaignRequest={campaignRequest}
          setCategory={setCategory}
          category={category}
          automatedDashboard={automatedDashboard}
          detailsHandler={detailsHandler}
          changeCreateFlow={changeCreateFlow}
          addCampaignFlow={addCampaignFlow}
          isadvMode={isadvMode}
          userData={userData}
          isEnterpriseAdvisor={isEnterpriseAdvisor}
          isEnterpriseLevelAdmin={isEnterpriseLevelAdmin}
          isFirmLevelAdmin={isFirmLevelAdmin}
          firmSettings={firmSettings}
          firmsArray={firmsArray}
          memberAdvisorsArray={memberAdvisorsArray}
          segmentList={segmentList}
          seriesSubcampaigns={seriesSubcampaigns}
          editSeriesLevel={editSeriesLevel}
        />
      )}
      {createFlow && (
        <CreateCampaign
          createFlow={createFlow}
          setCreateFlow={setCreateFlow}
          createTabs={createTabs}
          setCreateTabs={setCreateTabs}
          contactGroups={contactGroups}
          socialNetworkData={socialNetworkData}
          selectedThemes={selectedThemes}
          themes={themes}
          campaignSetState={campaignSetState}
          campaignCreateFlow={campaignCreateFlow}
          setObjectivesTabData={setObjectivesTabData}
          campaignId={campaignId}
          trendingTopics={trendingTopics}
          mostSubscribedTopics={mostSubscribedTopics}
          setTopicsTabData={setTopicsTabData}
          socialSelectedCampId={socialSelectedCampId}
          createScreenIndex={createScreenIndex}
          setCreateScreenIndex={setCreateScreenIndex}
          getAccountId={getAccountId}
          campaignScheduleTimings={campaignScheduleTimings}
          campaignSelectedArticles={campaignSelectedArticles}
          campaignSelectedWeek={campaignSelectedWeek}
          campaignSelectedDays={campaignSelectedDays}
          setTemplateData={setTemplateData}
          setTemplateBase={setTemplateBase}
          contentWeeks={contentWeeks}
          planCampaignData={planCampaignData}
          publishedContent={publishedContent}
          endCreateFlow={() => changeCreateFlow()}
          isPremiumAdvisor={isPremiumAdvisor}
          channelId={channelId}
          suggestedArticlesLoader={suggestedArticlesLoader}
          toggleSuggestedArticlesLoader={toggleSuggestedArticlesLoader}
          userData={userData}
          themeNames={themeNames}
          cmode={cmode}
          savedTemplate={savedTemplate}
          defaultTemplates={defaultTemplates}
          editMode={editMode}
          isEdit={isEdit}
          isFromAddCampaign={isFromAddCampaign}
          isadvMode={isadvMode}
          isAdvisorApprovalMode={isAdvisorApprovalMode}
          campaignStatus={campaignStatus}
          isEnterpriseAdvisor={isEnterpriseAdvisor}
          detailsHandler={detailsHandler}
          isEnterpriseLevelAdmin={isEnterpriseLevelAdmin}
          isFirmLevelAdmin={isFirmLevelAdmin}
          firmSettings={firmSettings}
          firmsArray={firmsArray}
          isAdmin={isAdmin}
          segmentList={segmentList}
          setSegmentList={setSegmentList}
          companyId={companyId}
          fetchSegments={fetchSegments}
          contactsLoading={contactsLoading}
          segmentsLoading={segmentsLoading}
        />
      )}
      {params === 'review' && (
        <ReviewCampaigns campaignId={campaignId} segmentList={segmentList}/>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  const roleType = getRoleType(state);
  const isAdmin = getUserRole(state) === USER_ROLE.admin;
  const isPremiumAdvisor = roleType === ROLE_TYPES.ADVISOR_PREMIUM;
  // const isAdminEnterprise = roleType === ROLE_TYPES.ADMIN_ENTERPRISE;
  const isEnterpriseAdvisor = roleType === ROLE_TYPES.ADVISOR_ENTERPRISE;
  const isTurnkey =
    state.user.data.details &&
    state.user.data.details.user.turnkey &&
    state.user.data.details.user.turnkey === 'true';
  let advMode = false;

  //add this when available

  let isAdvisorApprovalMode =
    (state.user.data.details &&
      state.user.data.details.user.extra_settings &&
      state.user.data.details.user.extra_settings.advisor_approval &&
      state.user.data.details.user.extra_settings.advisor_approval === true) ||
    false;

  if ((isPremiumAdvisor || isEnterpriseAdvisor) && isTurnkey) advMode = true;

  const isEnterpriseLevelAdmin = get(state, 'user.data.details.company.enterprise', "false") === "true";
  const isFirmLevelAdmin = get(state, 'user.data.details.company.firm', "false") === "true";
  const firmSettings = getFirmSettings({ isEnterpriseLevelAdmin, isFirmLevelAdmin, state });

  return {
    socialNetworkData: state.socialNetwork.data,
    selectedThemes: state.theme.yourTopics,
    themes: state.theme.data,
    themeNames: state.theme.themeNames,
    automatedDashboard: get(state, 'campaign.automatedDashboard', false),
    campaignCreateFlow: state.campaign.createFlow,
    setObjectivesTabData: state.campaign.setObjectives,
    campaignId: state.campaign.campaignId,
    campaignsData: get(state, 'campaign.data', []),
    seriesSubcampaigns: get(state, 'campaign.seriesSubcampaigns', {}),
    campaignScheduleTimings: state.campaign.scheduleTimings,
    campaignSelectedArticles: state.campaign.selectedArticles,
    campaignSelectedWeek: state.campaign.selectedWeek,
    campaignSelectedDays: state.campaign.selectedDays,
    campaignArticles: state.campaign.campaignArticles,
    campaignFetching: state.campaign.isFetching,
    campaignDetailsData: get(state, 'campaignDetails.data', {}),
    campaignBucketData: get(state, 'campaignDetails.campaignBucket', {}),
    channelId: getChannelId(state),
    setTopicsTabData: state.campaign.setTopics,
    isPremiumAdvisor: isPremiumAdvisor,
    contentWeeks: state.campaign.contentWeeks,
    planCampaignData: state.campaign.planCampaign,
    publishedContent: state.campaign.publishedContent,
    userData: state.user.data,
    setTemplateData: state.campaign.setTemplate,
    setTemplateBase: state.campaign.setBaseTemplate,
    savedTemplate: state.campaign.savedTemplate,
    defaultTemplates: state.campaign.defaultTemplates,
    editMode: state.campaign.editMode,
    isEdit: state.campaign.isEdit,
    isFromAddCampaign: state.campaign.isFromAddCampaign,
    isadvMode: advMode,
    isAdvisorApprovalMode: isAdvisorApprovalMode,
    campaignStatus: state.campaign.campaignStatus,
    socialSelectedCampId: state.campaign.socialSelectedCampId,
    isEnterpriseAdvisor: isEnterpriseAdvisor,
    fromCreateEmail: state.campaign.fromCreateEmail,
    curFlow: state.campaign.curFlow,
    isEnterpriseLevelAdmin,
    isFirmLevelAdmin,
    firmSettings: firmSettings, 
    firmsArray: get(state, 'user.data.details.company.firms', []),
    memberAdvisorsArray: get(state, 'user.membersList', []),
    isAdmin,
    companyId: state?.user?.data?.details?.company?.id
  };
};

const mapDispatchToProps = {
  campaignSetState: campaignActions.api.campaign.setstate,
  campaignRequest: campaignActions.api.campaign.request,
  campaignDetailsResponse: campaignDetailsActions.api.campaignDetails.response,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DripCampaignContainerV2);
