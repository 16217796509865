import styled from 'styled-components';
import { Flex } from '@rebass/grid';

export const ArticleWrapper = styled(Flex)`
  background-color: ${({background}) => background || '#e5e5e5'};
  border-radius: 10px;
  border: 1px solid #dbdbdb;
  margin: 8px;
  align-items: center;
  height: 60px;
  img {
    // border: 1px solid #adadad;
    background-size: cover;
    width: 65px;
    filter: unset;
    object-fit: cover;
    height: 100%;
    border-radius: 10px 0px 0px 10px;
    display: block;
  }
  a {
    text-decoration: none;
  }
  .share-icon {
    cursor: pointer;
    margin-right: 5px;
    font-size: 14px;
  }
  .selected-topic {
    font-size: 10px;
    text-align: left;
    font-weight: bold;

    height: 30px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    word-break: break-word;
    -webkit-box-orient: vertical;
  }
  .place-date {
    color: #7f7f7f;
    text-transform: uppercase;
    font-size: 8px;
    margin-top: 5px;
    text-align: left;
    font-weight: bold;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    word-break: break-word;
    -webkit-box-orient: vertical;
  }
  .share-article {
    float: right;
    font-size: 14px;
    margin-top: -3px;
    cursor: pointer;
    img {
      border: 0px;
      height: 16px;
      width: auto;
    }
  }
`;
