export const transformData = (data) => {
    return {
      id: data?.id ?? '',
      forwardedByEmail: data?.forwarded_by_email ?? '',
      forwardedToEmail: data?.forwarded_to_email ?? '',
      status: data?.status === 'completed' ? 'Yes' : 'No',
      forwardedByName: data?.forwarded_by_name ?? '',
      forwardedToName: data?.forwarded_to_name ?? '',
      createdAt: data?.created_time ?? '',
      updatedAt: data?.updated_time ?? ''
    };
  };