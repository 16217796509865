/* eslint-disable react-hooks/exhaustive-deps */
import { isEmpty } from 'lodash-es';
import React, { useEffect, useState } from 'react';
import { Button, LoaderWrapper, ModalBody, ModalCloseButton, ModalHeader, ModalV2 } from '..';
import { AdvisorsListWrapper } from '../../containers/CustomRowsContainer/CustomRowsContainer.styles';
import { MembersCheckBox } from '../ShareContentContainerV2/Elements/ShareCheckBoxes';

const CascadeToAdvisorsModal = ({ 
    isOpen, 
    closeHandler, 
    membersList, 
    modalTitle, 
    OGSelectedAdvisors,
    modalMessage,
    loader,
    disableShare,
    shareHandler,
    modalWidth
}) => {

    const [selectedAdvisors, setSelectedAdvisors] = useState([]);

    useEffect(() => {
        console.log('OG ADVISORS - - - -', OGSelectedAdvisors)
        if(OGSelectedAdvisors.length === membersList.length - 1)
            setSelectedAdvisors([...OGSelectedAdvisors, 'selectAll'])
        else
            setSelectedAdvisors(OGSelectedAdvisors);
    }, []);

    const shareValidationCheck = () => {
        // console.log('IS SAME = = = =', selectedAdvisors.filter(adv => adv!=='selectAll').length === OGSelectedAdvisors.length)
        let disableShare = true;
        if(selectedAdvisors.includes('selectAll')) 
            disableShare = selectedAdvisors.filter(adv => adv!=='selectAll').length === OGSelectedAdvisors.length;
        else if(selectedAdvisors.length !== OGSelectedAdvisors.length)
            disableShare = false;
        else
            disableShare = selectedAdvisors.every(adv => OGSelectedAdvisors.includes(adv));
        return disableShare;
    }

    const selectMembersHandler = (e, selectedMember) => {
        console.log('SELECTED MEMBER - - - -', selectedMember, '  ', e.target.checked);
        let updatedAdvisorsList = [...selectedAdvisors];
        if(selectedMember.id === 'selectAll') {
            if(e.target.checked)
                updatedAdvisorsList = membersList.map(member => member.id);
            else
                updatedAdvisorsList = [];
            // else
            //     updatedAdvisorsList = [...OGSelectedAdvisors];
        }
        else if(selectedAdvisors.includes(selectedMember.id))
            updatedAdvisorsList = [...selectedAdvisors].filter(advisorId => advisorId!==selectedMember.id && advisorId!=='selectAll');
        else {
            updatedAdvisorsList = [...selectedAdvisors, selectedMember.id];
            if(updatedAdvisorsList.length === membersList.length-1) 
                updatedAdvisorsList.push(membersList[0].id); // push 'Select All' into advisorsList to check selectAll checkbox
        }
        setSelectedAdvisors(updatedAdvisorsList);
    }

    return (
        <ModalV2
            isOpen={isOpen}
            width={modalWidth || '27%'}
            height='auto'
            onRequestClose={closeHandler}
            style={{ maxHeight: '90%' }}
            bgColor='#fff'
        >
            <ModalHeader fontColor='#000'>
                {/* Share Landing Page Template with the following advisors */}
                {modalTitle}
                <ModalCloseButton onClick={closeHandler} />
            </ModalHeader>
            <ModalBody>
                <AdvisorsListWrapper>
                    {!isEmpty(membersList) && (
                        <MembersCheckBox
                            membersList={membersList}
                            handleAdvisorChange={selectMembersHandler}
                            selectedMembers={selectedAdvisors}
                            ogSelectedMembers={OGSelectedAdvisors}
                            fromCampaignTemplate={true}
                            fontSize='12px'
                            maxHeight='400px'
                            overflowY='auto'
                        />
                    )}
                </AdvisorsListWrapper>
                {modalMessage && (
                    <div style={{ color: '#6351ed', fontSize: '12px', paddingTop: '10px' }}>
                        *{modalMessage}
                    </div>
                )}
                <LoaderWrapper isLoading={loader}>
                    <Button 
                        disabled={disableShare || shareValidationCheck()} 
                        onClick={() => shareHandler(selectedAdvisors)}
                    >
                        Share
                    </Button>
                </LoaderWrapper>
            </ModalBody>
        </ModalV2>
    );
}

export default CascadeToAdvisorsModal;