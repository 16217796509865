import React from 'react';
import { StackedACBlock } from './CampaignCreateSteps.styles';
import { Flex } from '@rebass/grid';
import { isEmpty } from 'lodash';
import { Button } from '../../../components';
import { AUTOMATED_CAMPAIGN_ACTIONS } from '../AutomatedCampaignsContainer';
import { getMediaImage, nurtureFlag } from '../../../utils';

const StackedBlockCard = ({ item, campaignAction, currentStepIndex }) => {
  console.log("item in stacked card--->", item)

  const getImage = () => {
    if(nurtureFlag === 'nurture' && process.env.REACT_APP_API_ENV === 'stage') {
      return item.media_urls;
    } else {
      if(item.platform === 'email') {
        if(!isEmpty(item.newsletter_id)) {
          if(!isEmpty(item?.media_urls)) {
            return getMediaImage(item.media_urls);
          } else {
            const randomNumber = Math.floor(Math.random() * 4) + 1;
            return `/assets/images/postDefault${randomNumber}.jpg`;
          } 
        } else {
          return 'assets/images/Campaign/Default-image.png';
        }
      } else {
        if(!isEmpty(item?.media_urls)) {
          return getMediaImage(item.media_urls);
        } else {
          return 'assets/images/Campaign/Default-image.png';
        }
      }
    }
  }
  
  return (
    <StackedACBlock style={{ marginLeft: currentStepIndex === 2 ? '18px' : '0px', }}>
      <Flex height="150px">
        <img
          style={{ height: '100%'}}
          src={
            getImage()
          }
          alt=""
        />
      </Flex>
      <Flex pl="10px" flexDirection="column">
        <Flex color="#101828" fontSize="16px" style={{ fontWeight: '500' }}>
          {item.name}
        </Flex>
        {currentStepIndex === 2 && (
          <Flex color="#101828" padding="10px 0px 0px" style={{ fontWeight: '500' }} fontSize="14px">
            {item?.platform === 'email' ? item?.subject : item.platform === 'social' ? item.override_text[Object.keys(item.override_text)[0]] : null}
          </Flex>
        )}
        {currentStepIndex === 2 ? (
          <Flex color="#667085" padding="10px 0px 10px" fontSize="12px" style={{ textAlign: 'justify'}}>
            {item.description}
          </Flex>
        ) : null}
        {currentStepIndex !== 2 ? (
          <Flex>
            <Button
              borderColor="#E4E7EC"
              color="#101828"
              bgColor="#fff"
              margin="10px 0px 0px"
            >
              <Flex
                justifyContent="space-between"
                width="100%"
                alignItems="center"
              >
                <img
                  src="assets/images/Campaign/Pencil-Icon.svg"
                  alt="Pencil"
                />
                <Flex ml="5px">
                  {campaignAction === AUTOMATED_CAMPAIGN_ACTIONS.EVENT
                    ? 'Customize Block'
                    : 'Customize Email'}
                </Flex>
              </Flex>
            </Button>
          </Flex>
        ) : null}
      </Flex>
    </StackedACBlock>
  );
};

export default StackedBlockCard;
