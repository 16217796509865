import React from 'react';
import ContentLoader from 'react-content-loader';
import { Container, Grid } from '@mui/material';

function Skeleton(props) {
  return (
    <Container>
      <Grid width="100%" height="130px" margin="0px auto 20px">
        <ContentLoader
          viewBox="0 0 100% 100%"
          height="100%"
          width="100%"
          margin={'10px auto 20px'}
          {...props}
        >
          <rect x="0%" y="2%" rx="4" ry="4" width="19%" height="130" />
          <rect x="20%" y="2%" rx="4" ry="4" width="19%" height="130" />
          <rect x="40%" y="2%" rx="4" ry="4" width="19%" height="130" />
          <rect x="60%" y="2%" rx="4" ry="4" width="19%" height="130" />
          <rect x="80%" y="2%" rx="4" ry="4" width="19%" height="130" />
        </ContentLoader>
      </Grid>
    </Container>
  );
}

export default Skeleton;
