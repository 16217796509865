import styled from 'styled-components';
import { Box } from '@rebass/grid';

import { TableBody, ResponsiveImg } from '../../../components';

export const WeekInfoWrapper = styled.div`
  .heading-text {
    font-size: 11px;
    margin-bottom: 20px;
  }
  .data-wrapper {
    font-size: 10px;
    background: #d4d4d4;
    border-radius: 6px;
    padding: 5px 0px;
    margin-bottom: 10px;
    text-align: center;
    .data-type {
      margin-bottom: 5px;
    }
    .data-value {
      font-weight: bold;
      width: 100%;
    }
  }
`;

export const LegendBox = styled(Box)`
  .legend-color {
    background-color: ${({ legendColor }) => legendColor};
    border-radius: 50%;
    height: 20px;
    width: 20px;
    display: inline-block;
  }
  .range-label {
    color: #49484a;
    display: inline-block;
    font-size: 11px;
    font-weight: 600;
  }
  .range-value {
    font-size: 15px;
    text-align: left;
    padding-left: 29px;
  }
`;

export const MemberTableBody = styled(TableBody)`
  height: 160px;
  overflow-y: auto;
  overflow-x: hidden;
`;

export const MemberInviteImg = styled(ResponsiveImg)`
  padding: 38px 0;
  width: 50px;
`;

export const TopMemberTableBody = styled(TableBody)`
  max-height: 413px;
  overflow-y: auto;
`;