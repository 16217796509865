/* eslint-disable react-hooks/exhaustive-deps */
import { Flex } from '@rebass/grid';
import { isEmpty } from 'lodash';
import React, { useState, useEffect } from 'react'
import { Button, ButtonNoBorderBackground, Input, LoaderWrapper, Modal, ModalBody, ModalCloseButton, ModalHeader } from '../../components';
import { leadEventHandlers, themesData } from '../../utils';
import { AdvisorInputWrapper } from '../../components/AdvisorProfile/AdvisorProfile.styles';

const ReferralModal = ({
    open,
    setOpen,
    handleContentPost,
    loading,
    emailId,
    clientFirstName,
    clientLastName,
    firstName,
    lastName,
    message,
    amFirmName,
    userType,
    userId,
    eid,
    network,
    clientEmailId
}) => {
    const [leadForm, setLeadForm] = useState({
        fullname: '',
        email: '',
        mobile: '',
        zip: ''
    })

    useEffect(() => {
        leadEventHandlers({
            eventName: 'FORM DISPLAY',
            userId,
            eid,
            form_type: 'referral',
            dnetwork: network,
            email_id: emailId,
            first_name: firstName,
            last_name: lastName,
            client_email: clientEmailId,
            client_first_name: clientFirstName,
            client_last_name: clientLastName,
            am_firm_name: amFirmName,
            user_type: userType
        })
    }, [])

    const handleClose = () => {
        leadEventHandlers({
            eventName: 'FORM CLOSED',
            userId,
            eid,
            form_type: 'referral',
            dnetwork: network,
            email_id: emailId,
            first_name: firstName,
            last_name: lastName,
            client_email: clientEmailId,
            client_first_name: clientFirstName,
            client_last_name: clientLastName,
            am_firm_name: amFirmName,
            user_type: userType
        })
        setOpen(false);
    }

    const handleChange = (e) => {
        setLeadForm({...leadForm, [e.target.name]: e.target.value});
    }

    const getTitleName = () => {
        if (amFirmName && !isEmpty(amFirmName) && amFirmName.charAt(0) !== '?') {
            if(amFirmName.includes('LTF')) {
                return amFirmName.replace('LTF', 'LTG');
            } else {
                return amFirmName;
            }
        } else {
            return `${firstName && firstName} ${lastName && lastName}`;
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        let payload = {
            fullname: leadForm.fullname,
            email: leadForm.email,
            mobile: leadForm.mobile,
            type: 'referral',
            referrer_fullname: (clientFirstName && clientLastName) && `${clientFirstName} ${clientLastName}`, 
            referrer_email: clientEmailId
        };

        if(payload.mobile === '' || isEmpty(payload.mobile)) {
            delete payload.mobile;
        }
        leadEventHandlers({
            eventName: 'FORM SUBMITTED',
            userId,
            eid,
            form_type: 'referral',
            dnetwork: network,
            email_id: emailId,
            first_name: firstName,
            last_name: lastName,
            client_email: leadForm.email || clientEmailId,
            client_first_name: (leadForm.fullname && leadForm.fullname.split(' ')[0]) || clientFirstName,
            client_last_name: (leadForm.fullname && leadForm.fullname.split(' ')[1]) || clientLastName,
            am_firm_name: amFirmName,
            user_type: userType
        })
        handleContentPost(payload);
    }

    return (
        <Modal noBoxShadow borderColor={themesData.charcoalGrey} bgColor='#fff' size='medium-small' isOpen={open} onRequestClose={handleClose}>
            <ModalHeader fontSize="14px">
                {userType === 'am' ? 'Would a colleague be interested in' : 'Would a friend or family member be'} <br /> {userType === 'am' ? 'speaking with' : `interested in speaking with`} <br /> {getTitleName()} on this topic?  
            </ModalHeader>
            <ModalBody>
                <Flex flexDirection="column">
                    <Flex width="100%" mb="15px" justifyContent="center" color={themesData.charcoalGrey}>
                        Tell us a little more about them.
                    </Flex>
                    <form onSubmit={handleSubmit}>
                        <Flex width="100%" mb="10px" justifyContent="center" mt="17px" style={{position: 'relative'}}>
                            <AdvisorInputWrapper style={{width: '70%'}}>
                                <Input 
                                    type="text" 
                                    name="fullname"
                                    value={leadForm.fullname}
                                    placeholder="Full Name" 
                                    onChange={handleChange} 
                                    required 
                                    isInputWithFloatingLabel={true}
                                />
                            </AdvisorInputWrapper>
                        </Flex>
                        <Flex width="100%" mb="10px" justifyContent="center">
                            <AdvisorInputWrapper style={{width: '70%'}}>
                                <Input 
                                    type="email" 
                                    name="email"
                                    value={leadForm.email}
                                    placeholder="Email Address"
                                    onChange={handleChange}
                                    required
                                    isInputWithFloatingLabel={true}
                                />
                            </AdvisorInputWrapper>
                        </Flex>
                        <Flex width="100%" mb="10px" justifyContent="center">
                            <AdvisorInputWrapper style={{width: '70%'}}>
                                <Input 
                                    type="text"
                                    value={leadForm.mobile} 
                                    name="mobile"
                                    onChange={handleChange}
                                    placeholder="Phone Number (Optional)"
                                    isInputWithFloatingLabel={true}
                                />
                            </AdvisorInputWrapper>
                        </Flex>
                        
                        <LoaderWrapper isLoading={loading}>
                            <Flex width="100%" justifyContent="center">
                                <Button type="submit" width="24%" height="28px" padding="4px 20px">
                                    Sure!
                                </Button>
                            </Flex>
                        </LoaderWrapper>

                        <Flex width="100%" justifyContent="center" pt="10px">
                            <ButtonNoBorderBackground bgColor="#fff" onClick={handleClose} color={themesData.purpleishBlue} width="70%">
                                No Thanks
                            </ButtonNoBorderBackground>
                        </Flex>

                        {message && (
                            <Flex justifyContent="center" color={themesData.purpleishBlue} fontSize="12px" pt="5px">
                                {message}
                            </Flex>
                        )}
                    </form>
                </Flex>
            </ModalBody>
        </Modal>
    )
}

export default ReferralModal;
