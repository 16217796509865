import { get } from 'lodash';
import { takeLatest, all, call, put } from 'redux-saga/effects';
import { actions as inviteActions } from '../modules/invite.module';
import { sendInvite } from '../../services/dashboard';

function* inviteRequest({ payload }) {
  try {
    const response = yield call(sendInvite, get(payload, 'params', {}));
    const data = get(response, 'result.data', {});
    yield put(inviteActions.api.invite.response(data));
  } catch (error) {
    yield put(inviteActions.api.invite.error(error));
  }
}

function* inviteSaga() {
  yield all([takeLatest(inviteActions.api.invite.request, inviteRequest)]);
}

export default inviteSaga;
