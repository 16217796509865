import React from 'react';
import { Flex, Box } from '@rebass/grid';
import { Button, Modal, ModalBody, ModalCloseButton, ModalHeader } from '../';
import { themesData } from '../../utils';

const styles = {
  ButtonOne: {
    color: themesData.purpleishBlue,
    background: themesData.white,
    borderRadius: '0px',
    fontSize: '11px',
    fontFamily: 'Poppins',
    border: 'none',
    width: '88px',
    height: '26px',
    paddingTop: '4px'
  },
  ButtonTwo: {
    color: themesData.white,
    background: themesData.purpleishBlue,
    borderRadius: '0px',
    fontSize: '11px',
    fontFamily: 'Poppins',
    border: 'none',
    width: '88px',
    height: '26px',
    paddingTop: '4px'
  },
  ModalBody: {
    width: '27%'
  },
  strong: {
    fontSize: '18px',
    fontWeight: 'normal',
    color: themesData.purpleishBlue,
    fontFamily: 'Poppins'
  }
}

const AddContentIncompleteModal = ({
  isModalOpen,
  setModalOpen,
  onYesClick,
  onNoClick,
  topicSelected,
  showThemes,
}) => {
  const preSelectedTopicsView = JSON.parse(localStorage.getItem('preSelectedTopicsView'));

  return (
    isModalOpen && (
      <Modal
        onRequestClose={() => setModalOpen(false)}
        shouldCloseOnOverlayClick
        isOpen={isModalOpen}
        size="medium"
        width='27%'
        padding='7px 7px 24px'
      >
        <ModalHeader>
          <ModalCloseButton onClick={() => setModalOpen(false)} />
        </ModalHeader>
        <ModalBody>
          <strong style={styles.strong}>
            {!JSON.parse(localStorage.getItem('hideTopicMsg')) && (<>
              {topicSelected ? 
                "Your content will not get added as you have not submitted the summary." : 
                preSelectedTopicsView ? "Your content will not be displayed." :
                "Your content will not be displayed since no topics have been selected."
              }
              <br />
            </>)}
            Are you sure you want to exit?
          </strong>
          <Flex alignItems="center" justifyContent="center">
            <Box>
              <Button styles={styles.ButtonOne} type="button" onClick={onYesClick}>
                Yes
              </Button>
            </Box>
            &nbsp;&nbsp;
            <Box>
              <Button styles={styles.ButtonTwo} type="button" onClick={onNoClick}>
                No
              </Button>
            </Box>
          </Flex>
        </ModalBody>
      </Modal>
    )
  );
}

export default AddContentIncompleteModal;
