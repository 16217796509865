/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import {
    Table,
    TableHead,
    TableBody,
    TableCell,
    TableRow,
    TablePagination,
    Tooltip,
    Zoom
} from '@mui/material';
import Checkbox from '../../../componentsV2/Checkbox';
import { tableCellClasses } from '@mui/material/TableCell';
import { faArrowDown, faArrowUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    EmailAddressContainer,
    TableHeader,
    TableTitle,
    TableSubTitle,
    TableActionContainer,
    TableBodyContainer,
    InnerActionContainer,
    SearchContainer,
    WhiteButton,
    EditButton,
    EmptyStyle,
    TagRow,
    TagTitle,
    TagStyle,
    OrangeTagStyle,
    ButtonContainer
} from './EmailList.styles';
import './EmailList.styles.css';
import editIconDots from './editIconDots.svg';
import downloadIcon from './downloadIcon.svg';
import trashIcon from './trashIcon.svg';
import trashIconGray from './trashIconGray.svg';
import SearchBar from '../../../componentsV2/SearchBar';
import TableLoader from './TableLoader';
import EditIcon from '@mui/icons-material/Edit';
import { isEmpty } from 'lodash';

const formatNumber = (amount) => {
    if (amount === 0) {
        return '0';
    } else if (amount && !isNaN(amount))
        return new Intl.NumberFormat('en-US', { maximumFractionDigits: 0 }).format(+amount);
    return '0';
};

const ContactTable = ({
    tableData,
    segmentTabActive,
    searchString,
    setSearchString,
    headerData,
    onAllCheckboxClick,
    isAllCheckSelected,
    handleEmailSort,
    rowsPerPage,
    setRowsPerPage,
    onIndividualCheckboxClick,
    tableName,
    searchHandler,
    handleEmailDelete,
    downloadCSVHandler,
    selectedContactGroup,
    selectedSegmentObj,
    tableLoading,
    setTableLoading,
    editListModal,
    emailList,
    addContactAction,
    createNewListFromSelections,
    searchBoxInput,
    handleSearch,
    selectedEmailList,
    onPaginationClick,
    contactsCount,
    newContacts,
    setNewContacts,
    setSegmentName
}) => {
    const [openSearch, setOpenSearch] = useState(false);
    const [hoveredIndex, setHoveredIndex] = useState(null);
    const [currentPage, setCurrentPage] = useState(0);
    const [displayPage, setDisplayPage] = useState(0);
    const [includedTags, setIncludedTags] = useState([]);
    const [excludedTags, setExcludedTags] = useState([]);
    const [paginationChange, setPaginationChange] = useState(false);

    useEffect(() => {
        setSearchString('')
        setOpenSearch(false)
    }, []);

    useEffect(() => {
        setRowsPerPage(250)
        setCurrentPage(0);
        setDisplayPage(0);
        if (selectedSegmentObj) {
            parseAllTags()
        }
    }, [selectedContactGroup, selectedSegmentObj]);

    useEffect(() => {
        if (setTableLoading) {
            setTableLoading(false)
        }
        if (paginationChange) {
            setTableLoading(false)
            setPaginationChange(false)
        }
        if (newContacts) {
            setTableLoading(false)
            setNewContacts(false)
        }
        // if (isEmpty(tableData)) {
        //     setExcludedTags([]);
        //     setIncludedTags([]);
        // }
    }, [tableData]);

    const parseAllTags = () => {
        let excludedAny = selectedSegmentObj?.search_rule?.exclude?.tags?.any;
        let includedAny = selectedSegmentObj?.search_rule?.include?.tags?.any;
        let excludedAll = selectedSegmentObj?.search_rule?.exclude?.tags?.all;
        let includedAll = selectedSegmentObj?.search_rule?.include?.tags?.all;

        let parseIncluded = [];
        let parseExcluded = [];

        for (let obj in includedAny) {
            parseIncluded.push(includedAny[obj])
        }

        for (let obj in excludedAny) {
            parseExcluded.push(excludedAny[obj])
        }

        for (let obj in includedAll) {
            parseIncluded.push(includedAll[obj])
        }

        for (let obj in excludedAll) {
            parseExcluded.push(excludedAll[obj])
        }
        setIncludedTags([...parseIncluded])
        setExcludedTags([...parseExcluded])
    };

    const emptyDisplay = () => {
        return (
            <TableBody style={{ display: 'flex', justifyContent: 'center' }}>
                <EmptyStyle>No Records Found</EmptyStyle>
            </TableBody>
        )
    };

    const emailListsDisplay = () => {
        return (
            <>
                {!isEmpty(tableData) && (
                    <SearchContainer style={{ width: '35%' }} searchActive={openSearch} >
                        <SearchBar
                            setActiveSearch={setOpenSearch}
                            activeSearch={openSearch}
                            searchString={searchString}
                            setSearchString={setSearchString}
                            searchHandler={searchHandler}
                        />
                    </SearchContainer>
                )}

                <div style={{ display: 'flex', justifyContent: 'end', width: '65%' }}>
                    <WhiteButton disabled={isEmpty(tableData)} onClick={() => downloadCSVHandler()}>
                        <img style={{ margin: '0px 8px 0 0', height: 20, width: 16 }} src={downloadIcon} alt='download' />
                        <div style={{ margin: '3px 0 0' }}>Download CSV</div>
                        {/* <img style={{margin: '8px 0 0px 6px', width: 12, height: 6}} src={downArrow} alt='down' /> */}
                    </WhiteButton>
                    <WhiteButton disabled={selectedEmailList?.type !== 'custom'} onClick={() => addContactAction(true)}>
                        <div style={{ margin: '3px 0 0' }}>Add Contact</div>
                    </WhiteButton>
                    <WhiteButton disabled={selectedContactGroup.length < 1} onClick={() => createNewListFromSelections()}>
                        <div style={{ margin: '3px 0 0' }}>Create List</div>
                    </WhiteButton>
                    <WhiteButton
                        style={{ padding: '8px 0px 8px 8px' }}
                        disabled={selectedContactGroup.length < 1 || selectedEmailList?.type !== 'custom'}
                        onClick={() => handleEmailDelete()}
                    >
                        <Tooltip title="Delete Contact(s)">
                            <img
                                style={{ margin: '0px 8px 0 0', height: 20, width: 16 }}
                                src={selectedContactGroup.length < 1 || selectedEmailList?.type !== 'custom' ? trashIconGray : trashIcon}
                                alt='delete'
                            />
                        </Tooltip>
                    </WhiteButton>
                    <EditButton
                        onClick={() => editListModal(selectedContactGroup)}
                        disabled={selectedContactGroup.length !== 1 || selectedEmailList?.type !== 'custom'}
                    >
                        <Tooltip title="Edit Contact"><EditIcon /></Tooltip>
                    </EditButton>
                </div>
            </>
        )
    };

    const segmentDisplay = () => {
        return (
            <div style={{ margin: '10px 0', fontFamily: 'Poppins !important' }}>
                <TagRow>
                    <TagTitle>Included Tags: </TagTitle>
                    {
                        includedTags.map((tagName, index) => {
                            return <TagStyle style={{ padding: '6px 16px 0' }} key={index}>{tagName}</TagStyle>
                        })
                    }
                </TagRow>
                <TagRow>
                    <TagTitle>Excluded Tags: </TagTitle>
                    {
                        excludedTags.map((tagName, index) => {
                            return <OrangeTagStyle style={{ padding: '6px 16px 0' }} key={index}>{tagName}</OrangeTagStyle>
                        })
                    }
                </TagRow>
            </div>
        )
    };

    const tableBodyDisplay = () => {
        return (
            <TableBody
                classes={{
                    ['& .MuiTableBody-root']: {
                        height: '370px !important',
                        overflowY: 'auto !important',
                        justifyContent: 'space-between',
                        fontFamily: 'Poppins !important'
                    }
                }}
            >
                {tableData.slice(currentPage * rowsPerPage, (currentPage + 1) * rowsPerPage)
                    .map((contact, index) => {
                        return (
                            <TableRow
                                key={index}
                                onMouseOver={() => setHoveredIndex(index)}
                                onClick={() => !segmentTabActive ? onIndividualCheckboxClick(index, contact.email) : {}}
                                id={`selection-row-${index}`}
                                sx={{
                                    background: hoveredIndex === index ? '#F9FAFB' : 'white',
                                    cursor: 'pointer',
                                    borderBottom: index < rowsPerPage ? '1px solid #E4E7EC' : 0,
                                    height: 45,
                                }}
                            >
                                {headerData.emailAddresses.map((headerInfo, headerIndex) => {
                                    if (headerInfo.type === 'checkbox') {
                                        return (
                                            <TableCell style={{ width: '20px' }} key={headerIndex}>
                                                <div style={{ padding: '0px 0px 2px 0px', marginLeft: '12px' }}>
                                                    <Checkbox
                                                        onClick={() => onIndividualCheckboxClick && onIndividualCheckboxClick(index, contact.email)}
                                                        checked={contact.checked}
                                                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                                                        id={`select-fund-${index}`}
                                                    />
                                                </div>
                                            </TableCell>
                                        );
                                    } else if (headerInfo.formatData) {
                                        if (headerInfo.key === 'tags') {
                                            return (
                                                <TableCell
                                                    key={headerIndex} sx={{ padding: '8px 0' }}>
                                                    {headerInfo.formatData(contact)}
                                                </TableCell>
                                            )
                                        }
                                        return <TableCell key={headerIndex}>{headerInfo.formatData(contact)}</TableCell>;
                                    }
                                    else {
                                        return (
                                            <TableCell key={headerIndex}>
                                                <p style={{ color: '#758790', margin: 0 }}>{contact[headerInfo.key]}</p>
                                            </TableCell>
                                        );
                                    }
                                })}
                            </TableRow>
                        );
                    })}
            </TableBody>
        )
    };

    const tableOutput = () => {
        return (
            <Table
                stickyHeader
                size="small"
                sx={{
                    [`& .${tableCellClasses.root}`]: {
                        padding: '4px auto',
                        fontFamily: 'Poppins !important',
                    },
                }}
            >
                <TableHead sx={{ height: '10px !important' }} onMouseOver={() => setHoveredIndex(null)}>
                    <TableRow
                        sx={{
                            [`& .${tableCellClasses.root}`]: {
                                padding: '0px auto',
                                color: '101828',
                            },
                        }}
                    >
                        {headerData.emailAddresses.map((e, index) => {
                            if (e.type === 'checkbox') {
                                return (
                                    <TableCell key={index}>
                                        <div style={{ margin: '5px 0 5px 12px' }}>
                                            <Checkbox onClick={() => onAllCheckboxClick()} checked={isAllCheckSelected} />
                                        </div>
                                    </TableCell>
                                );
                            } else if (e.header === 'Email') {
                                return (
                                    <TableCell
                                        key={index}
                                        sx={{
                                            cursor: 'pointer',
                                            color: '#101828',
                                        }}
                                    >
                                        <div style={{ display: 'flex', margin: '5px 0 5px' }}>
                                            <div style={{ color: '#101828', fontSize: 16, fontWeight: 500 }}>{e.header}</div>
                                            <span style={{ margin: '0px 0 0px 8px' }}>
                                                <span
                                                    style={{ cursor: 'pointer' }}
                                                    onClick={() => handleEmailSort('asc')}
                                                >
                                                    <FontAwesomeIcon icon={faArrowUp} />
                                                </span>
                                                &nbsp;
                                                <span
                                                    style={{ cursor: 'pointer' }}
                                                    onClick={() => handleEmailSort('desc')}
                                                >
                                                    <FontAwesomeIcon icon={faArrowDown} />
                                                </span>
                                            </span>
                                        </div>
                                    </TableCell>
                                );
                            } else {
                                return (
                                    <TableCell key={index}>
                                        <div style={{ display: 'flex', margin: '5px 0 5px', }}>
                                            <div style={{
                                                color: '#101828',
                                                fontSize: 16,
                                                fontWeight: 500,
                                            }}>{e.header}</div>
                                        </div>
                                    </TableCell>
                                );
                            }
                        })}
                    </TableRow>
                </TableHead>
                {
                    tableBodyDisplay()
                }
            </Table>
        )
    };

    const tableDisplayLogic = () => {
        if (emailList && emailList.length < 1) {
            return emptyDisplay()
        } else if (tableData?.length && (tableData[0]?.email || tableData[0]?.cid) && !tableLoading) {
            return tableOutput()
        } else if ((tableData && tableData.length === 0) && !tableData[0]?.cid && segmentTabActive) {
            return emptyDisplay()
        } else if (searchString?.length > 1 && Object.keys(tableData).length < 1) {
            return emptyDisplay()
        }
        else return <TableLoader />
    }

    return (
      <EmailAddressContainer>
        <TableHeader>
          <div style={{ display: 'table', width: '30%' }}>
            <TableTitle>{tableName}</TableTitle>
            {!isEmpty(emailList) && (
              <TableSubTitle>
                {!tableLoading &&
                  tableData &&
                  (segmentTabActive || searchString.length > 0
                    ? `${formatNumber(tableData?.length)} Contacts`
                    : contactsCount >= 0 &&
                      `${formatNumber(contactsCount)} Contacts`)}
              </TableSubTitle>
            )}
          </div>
          {segmentTabActive && (
            <div
              style={{ display: 'flex', justifyContent: 'end', width: '65%' }}
            >
              <SearchContainer
                style={{
                  margin: openSearch ? '9px 8px' : '17px 8px 0',
                  justifyContent: 'end',
                }}
                searchActive={openSearch}
              >
                <SearchBar
                  setActiveSearch={() => {
                    isEmpty(emailList)
                      ? setOpenSearch(false)
                      : setOpenSearch(true);
                  }}
                  activeSearch={openSearch}
                  searchString={searchBoxInput}
                  setSearchString={setSearchString}
                  searchHandler={handleSearch}
                />
              </SearchContainer>
              {console.log('table data--->new--->', tableData)}
              <WhiteButton
                style={{
                  padding: '8px 0px 8px 8px',
                  margin: '16px 14px 16px 5px',
                  alignItems: 'center',
                }}
                disabled={true}
              >
                <Tooltip
                  TransitionComponent={Zoom}
                  arrow
                  placement="top"
                  title="Manage contacts in segments using CRM"
                  componentsProps={{
                    tooltip: {
                      sx: {
                        fontFamily: 'Poppins',
                      },
                    },
                  }}
                >
                  <img
                    style={{ margin: '0px 8px 0 0', height: 20, width: 16 }}
                    src={trashIconGray}
                    alt="delete"
                  />
                </Tooltip>
              </WhiteButton>
              <WhiteButton
                style={{ height: 40, margin: '17px 15px 17px 0' }}
                onClick={() => downloadCSVHandler(includedTags, excludedTags)}
                disabled={isEmpty(emailList)}
              >
                <img
                  style={{ margin: '0px 8px 0 0', height: 20, width: 16 }}
                  src={downloadIcon}
                  alt="download"
                />
                <div style={{ margin: '3px 0 0' }}>Download CSV</div>
              </WhiteButton>
            </div>
          )}
        </TableHeader>
        <TableActionContainer>
          <InnerActionContainer>
            {segmentTabActive ? segmentDisplay() : emailListsDisplay()}
          </InnerActionContainer>
        </TableActionContainer>
        <TableBodyContainer>{tableDisplayLogic()}</TableBodyContainer>
        {segmentTabActive ? (
          <TablePagination
            rowsPerPageOptions={[250, 500, 1000]}
            onRowsPerPageChange={(e) => setRowsPerPage(e.target.value)}
            component="div"
            count={tableData?.length ?? 0}
            rowsPerPage={rowsPerPage}
            sx={{ fontFamily: 'Poppins', color: '#758790' }}
            page={currentPage}
            onPageChange={(e, page) => setCurrentPage(page)}
          />
        ) : (
          <ButtonContainer
            style={{
              margin: '0',
              justifyContent: 'space-between',
              padding: 0,
              borderTop: '1px solid #E4E7EC',
            }}
          >
            <WhiteButton
              style={{ height: 40, padding: '10px 18px', marginLeft: 10 }}
              disabled={displayPage === 0 || tableLoading === true}
              children={'Previous'}
              onClick={() => {
                onPaginationClick('before', currentPage);
                setDisplayPage(displayPage - 1);
                setTableLoading(true);
                setPaginationChange(true);
              }}
            />
            <div style={{ marginTop: 20 }}>
              {searchString.length > 0
                ? `${formatNumber(displayPage * rowsPerPage + 1)} - ${
                    (displayPage + 1) * rowsPerPage > tableData.length
                      ? formatNumber(tableData.length)
                      : formatNumber((displayPage + 1) * rowsPerPage)
                  } of ${formatNumber(tableData.length)}`
                : `${formatNumber(displayPage * rowsPerPage + 1)} - ${
                    (displayPage + 1) * rowsPerPage > contactsCount
                      ? formatNumber(contactsCount)
                      : formatNumber((displayPage + 1) * rowsPerPage)
                  } of ${formatNumber(contactsCount)}`}
            </div>
            <WhiteButton
              style={{ height: 40, padding: '10px 18px', marginRight: 10 }}
              children={'Next'}
              onClick={() => {
                onPaginationClick('after', currentPage);
                setDisplayPage(displayPage + 1);
                setTableLoading(true);
                setPaginationChange(true);
              }}
              disabled={
                (displayPage + 1) * rowsPerPage >= contactsCount ||
                (searchString.length > 1 &&
                  (displayPage + 1) * rowsPerPage >= tableData.length)
              }
            />
          </ButtonContainer>
        )}
      </EmailAddressContainer>
    );
};

export default ContactTable;