import React from 'react';
import { ChevronRight } from '@mui/icons-material';
import { format, subDays } from 'date-fns';
import useRESTQuery from 'src/hooks/useRESTQuery';
import ZeroState from './ZeroState';
import Skeleton from './Skeleton';
import BookArticle from 'src/assets/icons/BookArticleIcon';
import { getMediaImage } from 'src/utils';
import { ContentWrapper, Title } from '../styles';
import {
  Article,
  ArticleContainer,
  ArticleEngagementContainer,
  ArticleIcon,
  ArticleInfoContainer,
  ArticleTitle,
  ContentContainer,
  Divider,
  EngagementScoreContainer,
  Header,
  Link,
  Pill,
  Pills,
} from './styles';

function TopPerformingContent({
  timePeriod,
  additionalQueryParams,
  isAdmin = false,
}) {
  const { data: topPerformanceContent, isLoading, isFetching } = useRESTQuery({
    key: 'top-performing-content',
    dependencies: [timePeriod, additionalQueryParams],
    endpoint: `/analytics/content/performance/top?from_date=${format(
      subDays(new Date(), timePeriod),
      'yyyy-MM-dd'
    )}&limit=5${!!additionalQueryParams ? `&${additionalQueryParams}` : ''}`,
    options: {
      select: (res) => res?.result?.data ?? [],
      refetchOnMount: true,
    },
  });

  const getFormat = (type) => {
    if (type === 'Pdf') {
      return 'PDF';
    } else {
      return type;
    }
  };

  return (
    <ContentWrapper>
      <Header>
        <Title>Top Performing Content</Title>
        <Link href={isAdmin ? '/admin/discover' : '/discover'}>
          <span>Recommended Content</span>
          <ChevronRight />
        </Link>
      </Header>
      {isLoading || isFetching ? (
        <Skeleton />
      ) : (
        <>
          {Array.isArray(topPerformanceContent) &&
          topPerformanceContent?.length > 0 ? (
            <ContentContainer>
              {topPerformanceContent.map((content) => {
                const mediaURL = getMediaImage(content?.media_url);
                const totalTags = content?.tags?.length ?? 0;
                const minimumTags = content?.tags?.slice(0, 5);
                const remainingTags = totalTags - minimumTags?.length;

                return (
                  <ArticleContainer key={content?.id}>
                    <Article>
                      <ArticleIcon>
                        {!!mediaURL ? (
                          <img
                            src={mediaURL}
                            alt="article-img"
                            width="80"
                            height="80"
                          />
                        ) : (
                          <BookArticle />
                        )}
                      </ArticleIcon>
                      <ArticleInfoContainer>
                        <ArticleEngagementContainer>
                          <ArticleTitle>{content?.title ?? ''}</ArticleTitle>
                          <EngagementScoreContainer>
                            <p>
                              Engagement Score :{' '}
                              {content?.engagement_score ?? 0}
                            </p>
                            {content?.format && (
                              <>
                                <Divider />
                                <p>{getFormat(content?.format) ?? ''}</p>
                              </>
                            )}
                          </EngagementScoreContainer>
                        </ArticleEngagementContainer>
                        <Pills>
                          {minimumTags?.map((tag) => (
                            <Pill key={tag?.id}>{tag?.name ?? ''}</Pill>
                          ))}
                          {remainingTags ? (
                            <Pill>+{remainingTags}</Pill>
                          ) : (
                            <></>
                          )}
                        </Pills>
                      </ArticleInfoContainer>
                    </Article>
                  </ArticleContainer>
                );
              })}
            </ContentContainer>
          ) : (
            <ZeroState isAdmin={isAdmin} />
          )}
        </>
      )}
    </ContentWrapper>
  );
}

export default TopPerformingContent;
