import styled from 'styled-components';

export const TemplateFormCard = styled.div`
    height: 50px;
    width: 200px;
    padding: 15px 20px;
    font-size: 12px;
    border: 1px solid #adadad;
    border-radius: 4px;
    margin-bottom: 20px;
    margin-right: 20px;
    cursor: pointer;
    color: #49484a;

    :hover {
        color: #fff;
        background-color: #6351ed;
    }
`;