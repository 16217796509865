import { Flex } from '@rebass/grid'
import React from 'react'
import { ColorPallete, Typography, getMediaImage, onImageError } from '../../../utils';
import Skeleton from 'react-loading-skeleton';
import { isEmpty } from 'lodash';
import styled from 'styled-components';
import { Tooltip } from '@mui/material';
import { Carousel } from 'react-responsive-carousel';
import ErrorDisplay from '../../../componentsV2/ErrorDisplay/ErrorDisplay';
import { EmptyState } from '../../../componentsV2';

const TopCampaignWrapper = styled(Flex)`
    .slider:before {
        visibility: hidden;
    }

    .slider {
        left: 0px;
        background-color: #fff;
    }


    .slide {
        left: -8%;
    }

    .selected {
        left: 0%;
    }

    .carousel.carousel-slider {
        overflow: visible;
    }
`;

const TopPerformingCampaign = ({
  loading,
  data,
  topCampaignsError,
  fetchTopCampaigns
}) => {
  return (
    <TopCampaignWrapper
      width="100%"
      flexDirection="column"
      height="212px"
      padding="16px"
      style={{
        border: `1px solid ${ColorPallete.neutral200}`,
        borderRadius: '8px',
        boxShadow: '0px 1px 2px 0px #1018281F',
      }}
    >
      <Flex width="100%" justifyContent="space-between" mb="15px">
        <Flex fontSize={Typography.TextLarge} color={ColorPallete.black}>
          Top Performing Campaigns
        </Flex>
        {/* <Flex>
                    <img src="/assets/images/Analytics/left-arrow.svg" alt="" onClick={handlePrevClick}/>
                    <img src="/assets/images/Analytics/right-arrow.svg" alt="" onClick={handleNextClick}/>
                </Flex> */}
      </Flex>

      {loading && <Skeleton height={136} baseColor={ColorPallete.neutral100} />}

      {!loading && topCampaignsError && (
        <ErrorDisplay height="212px" onClick={fetchTopCampaigns} />
      )}

      {!loading && !topCampaignsError && isEmpty(data) && (
        <EmptyState
          height="212px"
          title="No Analytics Data"
          subTitle="Send out campaigns to get insights"
        />
      )}

      {!loading && !topCampaignsError && !isEmpty(data) && (
        <Carousel
          showThumbs={false}
          showIndicators={false}
          showArrows={false}
          showStatus={false}
          centerMode={false}
          renderArrowNext={(clickHandler, hasNext) => {
            return (
              <Flex
                style={{
                  position: 'absolute',
                  cursor: 'pointer',
                  top: '-38px',
                  right: '5px',
                }}
                onClick={clickHandler}
              >
                <img src="/assets/images/Analytics/right-arrow.svg" alt="" />
              </Flex>
            );
          }}
          renderArrowPrev={(clickHandler, hasPrev) => {
            return (
              <Flex
                style={{
                  position: 'absolute',
                  cursor: 'pointer',
                  top: '-38px',
                  right: '22px',
                }}
                onClick={clickHandler}
              >
                <img src="/assets/images/Analytics/left-arrow.svg" alt="" />
              </Flex>
            );
          }}
        >
          {data.map((item, index, arr) => (
            <Flex
              flex={'1 0 auto'}
              width="85%"
              height="136px"
              backgroundColor={ColorPallete.neutral50}
              mr="16px"
              style={{
                border: `1px solid ${ColorPallete.neutral200}`,
                borderRadius: '4px',
                cursor: 'default',
              }}
            >
              <Flex width="25%">
                <img
                  style={{
                    width: '100%',
                    height: '136px',
                    objectFit: 'cover',
                  }}
                  src={getMediaImage(item.media_url)}
                  alt=""
                  onError={onImageError}
                />
              </Flex>
              <Flex width="75%" padding="12px 16px" flexDirection="column">
                <Flex
                  width="100%"
                  fontSize={Typography.TextSmall}
                  color={ColorPallete.neutral500}
                >
                  {item.type === 'series'
                    ? 'Series Campaign'
                    : 'Automated Campaign'}
                </Flex>
                {item?.name && item?.name?.length > 30 ? (
                  <Tooltip title={item.name} arrow placement="top">
                    <Flex
                      pt="3px"
                      width="100%"
                      color={ColorPallete.black}
                      fontSize={Typography.TextLarge}
                    >
                      {`${item?.name.substring(0, 30)}...`}
                    </Flex>
                  </Tooltip>
                ) : (
                  <Flex
                    pt="3px"
                    width="100%"
                    color={ColorPallete.black}
                    fontSize={Typography.TextLarge}
                  >
                    {item?.name}
                  </Flex>
                )}
                <Flex
                  width="100%"
                  mt="7px"
                  style={{
                    borderTop: `1px solid ${ColorPallete.neutral200}`,
                  }}
                ></Flex>
                <Flex pt="15px" width="100%" flexDirection="column">
                  {item.engagement_social !== 0 && (
                    <Flex
                      width="100%"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Flex
                        color={ColorPallete.neutral600}
                        fontSize={Typography.TextMedium}
                      >
                        <Flex>Social Engagement</Flex>
                        <Tooltip
                          placement="right"
                          title="Social Engagement represents the weighted totals of various interactions 
                          with a particular post(s). Though options vary slightly across platforms, the scoring 
                          methodology applies numeric point values to interactions that can be summarized as follows: Content Clicks = 0.5, 
                          Likes (Loves, Celebrates, etc.) = 1, Comments = 1.5, Shares/Reposts/Retweets = 1.5, and Bookmarks = 1.5. 
                          Consider a social campaign to be highly engaged if scored above 50 (total social engagement may surpass 100, 
                            but the display will be limited to 100 signaling a very highly engaged post)."
                          arrow
                        >
                          <Flex>
                            <img
                              style={{
                                width: '16px',
                                margin: '0px 0px 0px 4px',
                              }}
                              src="/assets/images/Analytics/Grey-Info.svg"
                              alt=""
                            />
                          </Flex>
                        </Tooltip>
                      </Flex>
                      <Flex
                        color={ColorPallete.neutral400}
                        fontSize={Typography.TextMedium}
                      >
                        Coming Soon
                      </Flex>
                    </Flex>
                  )}

                  {item.engagement_email !== 0 && (
                    <Flex
                      width="100%"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Flex
                        color={ColorPallete.neutral600}
                        fontSize={Typography.TextMedium}
                      >
                        <Flex>Email Engagement</Flex>
                        <Tooltip placement="right" title="Email Engagement represents the cumulative 
                        engagement across all emails in a campaign. Differing from traditional click-through and 
                        open-rate metrics, email engagement cuts through the noise to represent meaningful results 
                        by applying weighting to the most common interactions (Open = 10, Click = 60, Lead Captured = 30). 
                        The score represented then becomes an average of your total contact engagement for each email communication 
                        and the subsequent content provided within. For additional context, consider emails that score above 
                        40 as highly engaged." arrow>
                          <Flex>
                            <img
                              style={{
                                width: '16px',
                                margin: '0px 0px 0px 4px',
                              }}
                              src="/assets/images/Analytics/Grey-Info.svg"
                              alt=""
                            />
                          </Flex>
                        </Tooltip>
                      </Flex>
                      <Flex
                        color={ColorPallete.primaryPurple}
                        fontSize={Typography.TextLarge}
                      >
                        {item.engagement_email}
                      </Flex>
                    </Flex>
                  )}
                </Flex>
              </Flex>
            </Flex>
          ))}
        </Carousel>
      )}
    </TopCampaignWrapper>
  );
}

export default TopPerformingCampaign