import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SyncIcon from '@mui/icons-material/Sync';

export const IconBlock = styled('div')`
  font-family: Poppins !important;
  background: #FFFFFF;
  border: 1px solid #E4E7EC;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.12);
  border-radius: 8px;
  position: relative;
`;

export const IconContainer = styled('div')`
  margin: 0px auto 20px;
  display: flex;
  justify-content: space-between;
  font-family: Poppins !important;
`;

export const IntegrationIcon = styled('img')`
  font-family: Poppins !important;
  height: 50px;
  width: 50px;
  margin: 30px auto 20px;
  border-radius: 50%;
  padding: 8px;
  background: #816EF8;
`;

export const ImportedIcon = styled(FontAwesomeIcon)`
  font-family: Poppins !important;
  width: 50px !important;
  height: 50px;
  margin: 30px auto 20px;
  border-radius: 50%;
  background: #816EF8;
  color: white;
  padding: 10px;
`;

export const ConnectButton = styled('button')`
  font-family: Poppins !important;
  background: #FFFFFF;
  border: 1px solid #E4E7EC;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.12);
  border-radius: 4px;
  cursor: pointer;
  width: 90%;
  display: flex;
  justify-content: center;
  margin: 10px auto;
  text-transform: none;
  padding: 8px 0;
  text-align: center;
  transition: 0.5s;
  :hover {
    opacity: 0.7;
  }
`;

export const SyncIconStyle = styled(SyncIcon)`
  font-family: Poppins !important;
  position: absolute !important;
  top: 5px !important;
  right: 5px !important;
  cursor: pointer;
  padding: 3px !important; 
  border-radius: 4px;
  height: 30px !important;
  width: 30px !important;
  transition: 0.5s !important;
  :hover {
    background: #E4E7EC;
  }
`;
