export const BAR_GRAPH_COLORS = [
  '#5C297C',
  '#7C3DA4',
  '#9C50CC',
  '#BC63F4',
  '#C982F6',
  '#D7A1F8',
  '#E4C1FB',
];

export const EMAIL_LIST = 'EMAIL_LIST';
export const SEGMENT_LIST = 'SEGMENT_LIST';
export const CONTACTS = 'CONTACTS';
export const LIMIT = 10;
