import React from 'react';
import {
    Container,
    TooltipRow,
    InfoIcon,
    RowIcon
} from './styles';
import infoDots from '../../assets/icons/navIcons/infoDots.svg';
import infoDotsBlack from '../../assets/icons/navIcons/infoDotsBlack.svg';

const CustomTooltip = ({ 
    inputRows,
    color
}) => {
   
    const customRows = () => {
        return(
            <>
                {
                    inputRows.map((row, index) => {
                        return(
                            <TooltipRow 
                                borderTop={row.borderTop}
                                key={index}
                                onClick={(e)=>row.action(e, row)}
                            >
                                {row.icon && <RowIcon src={row.icon} alt='icon' />}
                                <span style={{margin: '0 0 2px'}}>{row.name}</span>
                            </TooltipRow>
                        )
                    })
                }
            </>
        )
    };

    return(            
        <Container 
            title={customRows()}
        >
            <InfoIcon 
                hover={color}
                src={color === 'black' ? infoDotsBlack : infoDots} 
                alt='info' 
            />
        </Container>
               
    )
};

export default CustomTooltip;
